// @ts-nocheck
import { module } from 'angular';
import { DateTimeConverter } from '../../../../utils/date-time-converter';
import { PaginationOld } from '../../../../utils/pagination-old';
export class KpiFactory {
    EawResource;
    static get $inject() {
        return [ 'EawResource' ];
    }

    constructor(EawResource) {
        this.EawResource = EawResource;
    }

    /**
     * Get KPI for KPI page
     * Use business dates for from and to
     */
    getAll(params) {
        const args = params || {};
        return this.EawResource.create('/customers/:customer/kpis').get({
            customer: args.customer_id,
            'with[]': args.with ? args.with : undefined,
            from: DateTimeConverter.convertMomentToBusinessDate(args.from),
            to: DateTimeConverter.convertMomentToBusinessDate(args.to),
            ...PaginationOld.getParams(args),
        });
    }

    get(params) {
        const args = params || {};
        return this.EawResource.create('/customers/:customer/kpis/:business_date/:kpi_type').get({
            customer: args.customer_id,
            business_date: args.business_date.format('YYYY-MM-DD'),
            kpi_type: args.kpi_type,
        });
    }

    /**
     * Get KPI overview for KPI widget on dashboard
     * Do not use business dates for from and to
     */
    getOverview(args) {
        return this.EawResource.create('/customers/:customer/kpis/overview').get({
            customer: (Object.values(args.customers) || []).join(','),
            from: args.from.format('YYYY-MM-DD'),
            to: args.to.format('YYYY-MM-DD'),
            'kpi_types[]': args.kpi_types,
        });
    }

    getTypes(args) {
        return this.EawResource.create('/customers/:customer/kpi_types').get({
            customer: args?.customer_id || 'u',
            ...PaginationOld.getParams(args),
        });
    }

    getType(id, customerId) {
        return this.EawResource.create('/customers/:customer/kpi_types/:type_id').get({
            customer: customerId,
            type_id: id,
        });
    }
}
module('eaw.misc').service('KpiFactory', KpiFactory);
