import { User, UserResponse } from '../../shared/models/user';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { DateTime } from 'luxon';
import { TodoStatus, TodoStatusResponse } from './todo-status';
import { Attachment, AttachmentResponse } from '../../shared/models/attachment';
import { Comment, CommentResponse } from '../../shared/models/comment';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface TodoResponse extends ApiResponse {
    created_at: string;
    creator_id: number;
    customer_id: number;
    description?: string | null;
    due?: string | null;
    duehack: string;
    // From when should this item be visible
    from?: string | null;
    id: number;
    notify: 0 | 1;
    object_id?: number | null;
    object_type?: string | null;
    responsible_id?: number | null;
    // We don't know, ask Jim
    status_at?: string | null;
    status_id: number;
    time_spent?: null;
    title?: string | null;
    updated_at: string;
    attachments_count?: number;
    comments_count?: number;
    responsible?: UserResponse;
    status?: TodoStatusResponse;
    attachments?: AttachmentResponse<TodoResponse>[];
    comments?: CommentResponse[];
}

export class Todo {
    creatorId: number;
    customerId: number;
    description?: string | null;
    due?: DateTime | null;
    duehack: string;
    from?: DateTime | null;
    id: number;
    notify: boolean;
    objectId?: number | null;
    objectType?: string | null;
    responsibleId?: number | null;
    statusAt?: DateTime | null;
    statusId: number;
    timeSpent?: null;
    title?: string | null;
    createdAt: DateTime;
    updatedAt: DateTime;
    attachmentsCount?: number;
    commentsCount?: number;
    responsible?: User;
    status?: TodoStatus;
    isExpired: boolean;
    comments?: Comment[];
    attachments?: Attachment<TodoResponse>[];

    constructor(data: TodoResponse) {
        this.commentsCount = data.comments_count;
        this.attachmentsCount = data.attachments_count;
        this.title = data.title;
        this.timeSpent = data.time_spent;
        this.statusId = data.status_id;
        this.statusAt = data.status_at ? stringToDateTime(data.status_at) : null;
        this.objectType = data.object_type;
        this.objectId = data.object_id;
        this.notify = !!data.notify;
        this.id = data.id;
        this.responsibleId = data.responsible_id;
        this.from = data.from ? stringToDateTime(data.from) : null;
        this.duehack = data.duehack;
        this.due = data.due ? stringToDateTime(data.due) : null;
        this.description = data.description;
        this.customerId = data.customer_id;
        this.creatorId = data.creator_id;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.isExpired = this.expiresSoon(0);

        if (data.responsible) {
            this.responsible = new User(data.responsible);
        }

        if (data.status) {
            this.status = new TodoStatus(data.status);
        }

        if (data.comments) {
            this.comments = data.comments.map((c) => new Comment(c));
        }

        if (data.attachments) {
            this.attachments = data.attachments.map((a) => new Attachment<TodoResponse>(a));
        }
    }

    expiresSoon(time = 3600) {
        return (this.due?.diffNow().as('seconds') || time) < time;
    }
}
