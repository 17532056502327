// @ts-nocheck
import { filter } from 'lodash-es';
import { module } from 'angular';

module('eaw.checklists').controller('eawNumberAdvancedCtrl', [ '$mdDialog', function($mdDialog) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.cancel = $mdDialog.cancel;
        ctrl.item.options = !Object.keys(ctrl.item.options || []).length ? {} : ctrl.item.options;
        ctrl.initialOptions = JSON.stringify(ctrl.item.options);

        ctrl.optionChanged();
    };

    ctrl.optionChanged = () => {
        ctrl.item.options = Object.entries(ctrl.item.options || {}).reduce((obj, entry) => {
            const [ key, value ] = entry;
            if (value == null) {
                return obj;
            }
            obj[key] = value;
            return obj;
        }, {});

        // Stringify and check if is same
        ctrl.changedOptions = JSON.stringify(ctrl.item.options);
        ctrl.sameOptions = ctrl.changedOptions === ctrl.initialOptions;

        // What to show
        ctrl.showSoftMin = typeof ctrl.item?.options?.soft_minimum === 'number';
        ctrl.showSoftMax = typeof ctrl.item?.options?.soft_maximum === 'number';
        ctrl.softValuesEqual = ctrl.showSoftMin && ctrl.showSoftMax && ctrl.item.options.soft_minimum === ctrl.item.options.soft_maximum;

        // Input min / max
        ctrl.softMinMin = Math.max(
            ...filter([ ctrl.item.options.minimum ], (x) => x != null),
            Number.MIN_SAFE_INTEGER,
        );

        ctrl.softMinMax = Math.min(
            ...filter([ ctrl.item.options.maximum, ctrl.item.options.soft_maximum ], (x) => x != null),
            Number.MAX_SAFE_INTEGER,
        );

        ctrl.softMaxMin = Math.max(
            ...filter([ ctrl.item.options.minimum, ctrl.item.options.soft_maximum ], (x) => x != null),
            Number.MIN_SAFE_INTEGER,
        );

        ctrl.softMaxMax = Math.min(
            ...filter([ ctrl.item.options.maximum ], (x) => x != null),
            Number.MAX_SAFE_INTEGER,
        );
    };

    ctrl.submit = () => {
        $mdDialog.hide(ctrl.item);
    };
} ]);
