import { inject, Injectable } from '@angular/core';
import { ApiModelClass } from '../enums/api-model';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Signable, SignableResponse } from '../models/signable';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { PaginationOptions } from '../interfaces/pagination-options';
import { HttpClient, HttpContext } from '@angular/common/http';
import { IGNORE_ERROR } from './http-contexts';
import { SignatureProviderType } from '../../hr/enums/SignatureProviderType';

@Injectable({
    providedIn: 'root',
})
export class SignablesService {
    private readonly http = inject(HttpClient);

    create(customerId: number, filter: Record<string, string | number>, model: ApiModelClass, provider: SignatureProviderType) {
        return this.http.post<SignableResponse>(`customers/${customerId}/signables`, { model, filter, provider }).pipe(classifyItem(Signable));
    }

    getAll(customerId: number, model: ApiModelClass, options?: PaginationOptions): Observable<ArrayPaginatedResponse<Signable>> {
        return this.http.get<ArrayPaginatedResponse<SignableResponse>>(`customers/${customerId}/signables`, {
            params: {
                ...options,
                model,
            },
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(classifyArrayPaginatedResponse(Signable));
    }

    update(customerId: number, signableId: number, filter: Record<string, string | number>, provider: SignatureProviderType) {
        return this.http.put<SignableResponse>(`customers/${customerId}/signables/${signableId}`, { filter, provider }).pipe(classifyItem(Signable));
    }

    delete(customerId: number, signableId: number) {
        return this.http.delete<undefined>(`customers/${customerId}/signables/${signableId}`);
    }
}
