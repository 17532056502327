import { Inject, Injectable } from '@angular/core';
import { Autocomplete } from './autocomplete';
import { TranslateService } from '../services/translate.service';
import { of } from 'rxjs';
import { CustomerGroup } from '../models/customer-group';
import { CustomerGroupService } from '../http/customer-group.service';

@Injectable({
    providedIn: 'root',
})
export class CustomerGroupAutocompleteService extends Autocomplete<CustomerGroup> {

    constructor(
        @Inject(CustomerGroupService) protected customerGroupService: CustomerGroupService,
        @Inject(TranslateService) protected translateService: TranslateService,
    ) {
        super({
            label: translateService.t('CUSTOMER_GROUP'),
            optionTemplate: 'default',
            filterRequirement: 3,
            display: 'name',
            trackByKey: 'id',
        });
    }

    setter() {
        return (item: unknown) => {
            if (item instanceof CustomerGroup) {
                return of(item);
            }
            if (typeof item !== 'number') {
                return of(undefined);
            }

            return this.customerGroupService.get(item);
        };
    }

    getter() {
        return () => this.customerGroupService.getAll({
            per_page: 10,
            direction: 'asc',
            order_by: 'name',
            'count[]': [ 'activeMemberships', 'memberships' ],
        });
    }
}
