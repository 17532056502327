// @ts-nocheck
import { debounce } from 'lodash-es';
import { module } from 'angular';
const template1 = `<div id="budget-information-sidebar">
    <md-content layout="column" layout-align="space-around" layout-padding>
        <div ng-if="!$pcCtrl.summary.$resolved" layout="row" layout-align="center center">
            <md-progress-circular md-mode="indeterminate"></md-progress-circular>
        </div>
        <position-cost-day day="$pcCtrl.summary.sums" ng-show="$pcCtrl.summary.$resolved"></position-cost-day>
    </md-content>

    <div layout="column" layout-padding>
        <div layout="row" layout-align="space-between center">
            <div>
                <h4 ng-i18next="[i18next]({number: $pcCtrl.sidebar.day.week})WEEK_N"></h4>
                <h5 ng-show="!$pcCtrl.loadingTotal">
                    <span ng-bind="$pcCtrl.totals[$pcCtrl.sidebar.day.weekNr].from.format('LL')"></span>
                    -
                    <span ng-bind="$pcCtrl.totals[$pcCtrl.sidebar.day.weekNr].to.format('LL')"></span>
                </h5>
            </div>

            <md-button ng-show="!$pcCtrl.loadingTotal"
                       class="md-icon-button"
                       ng-class="{'md-warn': $pcCtrl.totals[$pcCtrl.sidebar.day.weekNr].stale}"
                       ng-click="$pcCtrl.onRefreshWeek()">
                <md-icon ng-bind="'refresh'"></md-icon>
                <md-tooltip>
                    <span ng-if="$pcCtrl.totals[$pcCtrl.sidebar.day.weekNr].stale"
                          ng-i18next="[i18next]({number: $pcCtrl.sidebar.day.weekNr})scheduling:RECALCULATE_FOR_WEEK"></span>
                    <span ng-if="!$pcCtrl.totals[$pcCtrl.sidebar.day.weekNr].stale"
                          ng-i18next="[i18next]({number: $pcCtrl.sidebar.day.weekNr})scheduling:CALCULATE_FOR_WEEK">
                    </span>
                </md-tooltip>
            </md-button>
        </div>
        <md-content layout="column"
                    layout-align="space-around"
                    layout-padding
                    ng-if="$pcCtrl.totals[$pcCtrl.sidebar.day.weekNr].sums">
            <position-cost-day ng-show="$pcCtrl.totals[$pcCtrl.sidebar.day.weekNr].$resolved"
                        day="$pcCtrl.summary.sums">
            </position-cost-day>
        </md-content>
    </div>

    <div ng-if="$pcCtrl.loadingTotal" layout="row" layout-align="center center">
        <md-progress-circular md-mode="indeterminate"></md-progress-circular>
    </div>

</div>

`;
module('eaw.scheduling').component('sidebarPositionCost', {
    template: template1,
    controllerAs: '$pcCtrl',
    bindings: {
        days: '<',
        schedule: '<',
    },
    require: {
        sidebar: '^scheduleSidebar',
    },
    controller: [ 'BackOfficeSync', '$scope', 'ScheduleTotal', 'shiftEvents', function(BackOfficeSync, $scope, ScheduleTotal, shiftEvents) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.getDayBudget();
            shiftEvents.register.onChange($scope, ctrl.onShiftChange);
            BackOfficeSync.subscribe($scope, ctrl.getDayBudget);
            ctrl.calcTotals();
            ctrl.onRefreshWeek = debounce(ctrl.getTotal, 1000);
            $scope.$on('sidebarchildren:dayChanged', () => {
                ctrl.getDayBudget();
            });
        };
        ctrl.calcTotals = () => {
            ctrl.totals = {};
            const date = ctrl.schedule.getFrom();
            while (date.isSameOrBefore(ctrl.schedule.to, 'w')) {
                ctrl.totals[date.format('w')] = {
                    from: ctrl.getFrom(date),
                    to: ctrl.getTo(date),
                };
                date.add(1, 'w');
            }
        };
        ctrl.onShiftChange = () => {
            ctrl.getDayBudget();
            if (ctrl.totals[ctrl.sidebar.day.moment.format('w')].sums) {
                // we don't know when the shift was, so set everything to stale.
                ctrl.totals.forEach((week) => {
                    if (Object.keys(week || []).length) { // can't have stale data if there is NO data.
                        week.stale = true;
                    }
                });
            }
        };
        ctrl.getDayBudget = () => {
            ctrl.businessDate = ctrl.sidebar.day.moment.format('YYYY-MM-DD');
            ctrl.summary = ScheduleTotal.get(ctrl.schedule.customer_id, ctrl.schedule.id, { business_date: ctrl.businessDate }, true);
        };
        ctrl.getTotal = () => {
            ctrl.loadingTotal = true;
            const from = ctrl.getFrom(ctrl.sidebar.day.moment);
            const to = ctrl.getTo(ctrl.sidebar.day.moment);
            const w = from.format('w');
            ctrl.totals[w] = ScheduleTotal.get(ctrl.schedule.customer_id, ctrl.schedule.id, {
                from,
                to,
                _businessDates: [ 'from', 'to' ],
            }, true);
            ctrl.totals[w].$promise.then(() => {
                ctrl.totals[w].from = from;
                ctrl.totals[w].to = to;
            }).finally(() => ctrl.loadingTotal = false);
        };
        ctrl.getFrom = (date) => {
            const from = date.clone().startOf('w');
            return from.isBefore(ctrl.schedule.from) ? ctrl.schedule.getFrom() : from;
        };
        ctrl.getTo = (date) => {
            const to = date.clone().endOf('w');
            return to.isAfter(ctrl.schedule.to) ? ctrl.schedule.getTo() : to;
        };
        /**
         * Debounce the function so that it doesn't get a million totals if clicking arrows a lot
         * @param {object|moment} day
         * @param {boolean} [daySelector]
         */
        ctrl.onChangeDay = debounce(ctrl.getDayBudget, 500);
    } ],
});
