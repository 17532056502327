// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';

module('eaw.newsletter').component('eawIssuePreview', {
    bindings: {
        src: '<?',
        issue: '<?',
        languageCode: '<?',
        customerId: '<?',
        rootSelector: '@?',
    },
    controllerAs: '$widget',
    controller: [ 'Newsletter', '$element', 'componentCreatorService', function(Newsletter, $element, componentCreatorService) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.el = $element[0];
            ctrl.el.style.borderRadius = '4px';
            if (ctrl.issue) {
                ctrl.getIssuePreview();
            } else {
                ctrl.srcPreview();
            }
        };
        ctrl.srcPreview = () => {
            const el = document.createElement('div');
            el.classList.add('preview-text', 'md-body-2');
            el.style.color = 'white';
            el.innerText = t('newsletters.PREVIEW');
            ctrl.el.appendChild(el);
        };
        ctrl.getIssuePreview = () => {
            ctrl.spinner = componentCreatorService.create({
                name: 'mdProgressCircular',
                bindings: {
                    mdDiameter: [ 30, 'string' ],
                },
            });
            ctrl.el.classList.add('has-issue');
            ctrl.el.appendChild(ctrl.spinner.element);
            ctrl.observer = new IntersectionObserver(ctrl.onIntersect, {
                root: ctrl.el.closest(ctrl.rootSelector),
                rootMargin: '0px',
                threshold: 0.5,
            });
            ctrl.observer.observe(ctrl.el);
        };
        ctrl.$onChanges = (changes) => {
            ctrl.setImage(changes.src?.currentValue);
        };
        ctrl.onIntersect = (ev, observer) => {
            if (ev[0].isIntersecting) {
                ctrl.getPreviewImage();
                observer.disconnect();
            }
        };
        ctrl.getPreviewImage = () => {
            const languageCode = ctrl.languageCode || ctrl.issue.viewLanguage.code;
            Newsletter.getIssuePreview(ctrl.issue, ctrl.customerId, languageCode, true).then((url) => ctrl.setImage(url, true)).catch(() => {
                Array.from(ctrl.el.children).forEach((c) => c.remove());
                ctrl.spinner?.element.remove();
            });
            Newsletter.getIssuePreview(ctrl.issue, ctrl.customerId, languageCode).then((url) => ctrl.setImage(url)).catch(() => {
                Array.from(ctrl.el.children).forEach((c) => c.remove());
                ctrl.spinner?.element.remove();
            });
        };
        ctrl.setImage = (url, lazy) => {
            if (!url) {
                return;
            }
            if (lazy && ctrl.hasImage) {
                return;
            }
            Array.from(ctrl.el.children).forEach((c) => c.remove());
            ctrl.spinner?.element.remove();
            ctrl.hasImage = true;
            ctrl.el.classList.add('has-preview');
            ctrl.el.style.backgroundImage = `url(${url})`;
        };
    } ],
});
