import { ApiResponse } from '../interfaces/api-response';
import { Property, PropertyResponse } from './property';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { Namespace, NamespaceFile } from '../enums/namespace';

type PayTypeLinkLaborEffect = 'negative' | 'positive';

type PayTypeLinkReturnUnitType = 'quantity' | 'currency' | 'seconds' | 'hours' | 'days' | 'weeks';

interface PayTypeLinkReturnUnit {
    type: PayTypeLinkReturnUnitType;
    // Name of the unit
    name: {
        translationKey: string;
        translationNs: NamespaceFile;
    },
    // Label that you would use for example for an input field
    label: {
        translationKey: string;
        translationNs: NamespaceFile;
    },
    useQuantityInput: boolean;
    useRateInput: boolean;
    defaultQuantity?: number;
    defaultRate?: number;
}

export interface PayTypeLinkResponse extends ApiResponse {
    calculator_id: number | null;
    code: string;
    created_at: string;
    deleted_at: string | null;
    effect_on_labor: PayTypeLinkLaborEffect;
    employee_rate_ratio: number;
    from: string;
    id: number;
    include_in_labor_cost: boolean;
    include_in_vacation: boolean;
    name: string;
    owner_id: number;
    owner_type: string;
    pay_type_id: number;
    properties?: PropertyResponse[];
    tariff_id: number | null;
    to: string | null;
    updated_at: string;
    use_employee_rate: boolean;
    return_unit: PayTypeLinkReturnUnitType;
}

export class PayTypeLink {
    calculatorId: number | null;
    code: string;
    effectOnLabor: PayTypeLinkLaborEffect;
    employeeRateRatio: number;
    from: DateTime;
    to: DateTime | null;
    id: number;
    includeInLaborCost: boolean;
    includeInVacation: boolean;
    name: string;
    ownerId: number;
    ownerType: string;
    payTypeId: number;
    properties?: Property[];
    tariffId: number | null;
    useEmployeeRate: boolean;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;
    returnUnit: PayTypeLinkReturnUnit;

    constructor(data: PayTypeLinkResponse) {
        this.calculatorId = data.calculator_id;
        this.code = data.code;
        this.effectOnLabor = data.effect_on_labor;
        this.employeeRateRatio = data.employee_rate_ratio;
        this.from = stringToDateTime(data.from);
        this.to = data.to ? stringToDateTime(data.to) : null;
        this.id = data.id;
        this.includeInLaborCost = data.include_in_labor_cost;
        this.includeInVacation = data.include_in_vacation;
        this.name = data.name;
        this.ownerId = data.owner_id;
        this.ownerType = data.owner_type;
        this.payTypeId = data.pay_type_id;
        this.properties = data.properties?.map((p) => new Property(p));
        this.tariffId = data.tariff_id;
        this.useEmployeeRate = data.use_employee_rate;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.returnUnit = this.getReturnUnit(data.return_unit);
    }

    private getReturnUnit(type: PayTypeLinkReturnUnitType): PayTypeLinkReturnUnit {
        switch (type) {
            case 'currency': {
                return {
                    type,
                    name: { translationKey: 'PAY_TYPE_LINK_RETURN_CURRENCY', translationNs: Namespace.Payroll },
                    label: { translationKey: 'PAY_TYPE_LINK_RETURN_CURRENCY_LABEL', translationNs: Namespace.Payroll },
                    useQuantityInput: false,
                    useRateInput: true,
                    defaultQuantity: 1,
                };
            }
            case 'quantity': {
                return {
                    type,
                    name: { translationKey: 'QUANTITY', translationNs: Namespace.Payroll },
                    label: { translationKey: 'QUANTITY', translationNs: Namespace.Payroll },
                    useQuantityInput: true,
                    useRateInput: false,
                    defaultRate: 1,
                };
            }
            case 'seconds': {
                return {
                    type,
                    name: { translationKey: 'SECOND_plural', translationNs: Namespace.General },
                    label: { translationKey: 'SECOND_plural', translationNs: Namespace.General },
                    useQuantityInput: true,
                    useRateInput: false,
                    defaultRate: 1,
                };
            }
            case 'hours': {
                return {
                    type,
                    name: { translationKey: 'HOUR_plural', translationNs: Namespace.General },
                    label: { translationKey: 'HOUR_plural', translationNs: Namespace.General },
                    useQuantityInput: true,
                    useRateInput: false,
                    defaultRate: 1,
                };
            }
            case 'days': {
                return {
                    type,
                    name: { translationKey: 'DAY_plural', translationNs: Namespace.General },
                    label: { translationKey: 'DAY_plural', translationNs: Namespace.General },
                    useQuantityInput: true,
                    useRateInput: false,
                    defaultRate: 1,
                };
            }
            case 'weeks': {
                return {
                    type,
                    name: { translationKey: 'WEEK_plural', translationNs: Namespace.General },
                    label: { translationKey: 'WEEK_plural', translationNs: Namespace.General },
                    useQuantityInput: true,
                    useRateInput: false,
                    defaultRate: 1,
                };
            }
            default: {
                return {
                    type: 'quantity',
                    name: { translationKey: 'UNKNOWN_UNIT', translationNs: Namespace.General },
                    label: { translationKey: 'QUANTITY', translationNs: Namespace.Payroll },
                    useQuantityInput: true,
                    useRateInput: false,
                    defaultRate: 1,
                };
            }
        }
    }
}
