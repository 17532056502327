// @ts-nocheck
import { module } from 'angular';

module('eaw.admin').component('adminCustomerProductsTab', {
    template: `<md-card>
    <md-card-content>
        <md-input-container layout="row" flex="100">
            <label ng-i18next="FILTER"></label>
            <input id="filter-input" ng-model="$ctrl.filter" uniq-form-name ng-change="$ctrl.applyFilter()">
        </md-input-container>
    </md-card-content>

    <md-card-content id="products-content" class="tw-p-0">
        <md-list class="tw-p-0">
            <md-list-item ng-repeat="product in $ctrl.products track by $index" md-colors="{'background': product.linked ? 'blue-50' : 'white'}"
                          ng-click="$ctrl.toggleProduct(product, false)"
                          ng-hide="product.hide">
                <md-icon ng-bind="'link'" eaw-invisible="{{!product.linked}}" ng-click="$ctrl.goToLink(product)"></md-icon>
                <p md-highlight-text="$ctrl.filter" md-highlight-flags="gi">{{product.name}}</p>
                <md-switch class="md-secondary"
                           stop-propagate
                           ng-model="product.enabled"
                           ng-change="$ctrl.toggleProduct(product, true)"
                           ng-disabled="product.updating || product.linked">
                </md-switch>
                <md-divider ng-if="!$last"></md-divider>
            </md-list-item>
        </md-list>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
        customerProducts: '<',
        allProducts: '<',
    },
    controller: [ '$state', '$element', 'CustomerService', 'ToastService', function($state, $element, CustomerService, ToastService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.products = ctrl.allProducts;

            const defaultProducts = [ 'core', 'billing', 'messaging', 'weather' ]; // Must be lower case
            ctrl.products = ctrl.products.reduce((arr, prod) => {
                if (defaultProducts.includes(prod.name.toLowerCase())) {
                    return arr;
                }

                return arr.concat(prod);
            }, []);

            ctrl.products.forEach((p) => {
                const customerProduct = ctrl.customerProducts.find((cp) => cp.name === p.name);

                if (customerProduct) {
                    p.enabled = true;

                    const belongsToCustomer = customerProduct?.pivot?.subscriber_id === ctrl.customer.id && customerProduct?.pivot?.subscriber_type === 'customer';
                    if (!belongsToCustomer) {
                        p.linked = true;
                        p.subscriberId = customerProduct.pivot.subscriber_id;
                    }
                }
            });
        };

        ctrl.$postLink = () => {
            $element[0].querySelector('#filter-input')?.focus();
        };

        ctrl.goToLink = (product) => {
            $state.go(`eaw/app/admin/customer_groups/view`, { id: product.subscriberId });
        };

        ctrl.applyFilter = () => {
            ctrl.products.forEach((p) => {
                p.hide = !p.name.toLowerCase().includes(ctrl.filter?.toLowerCase());
            });
        };

        ctrl.toggleProduct = async (product, isSwitch) => {
            if (product.updating || product.linked) {
                return;
            }
            product.updating = true;
            product.enabled = isSwitch ? !product.enabled : product.enabled;

            try {
                if (product.enabled) {
                    await CustomerService.removeProduct(ctrl.customer.id, product).$promise;
                } else {
                    await CustomerService.addProduct(ctrl.customer.id, product.name).$promise;
                }

                product.enabled = !product.enabled;
                ToastService.toast(`${product.name} ${product.enabled ? 'activated' : 'deactivated'}`);
            } catch (e) {
                console.error(e);
            }

            delete product.updating;
        };
    } ],
});
