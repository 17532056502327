import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { Widget } from '../../classes/widget';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { DashboardStoreService } from '../../services/dashboard-store.service';
import { MiniKpiWidgetSettings } from './mini-kpi-settings-dialog/mini-kpi-settings-dialog.component';
import { catchError, forkJoin, of } from 'rxjs';
import { DateTime } from 'luxon';
import { TranslateService } from '../../../shared/services/translate.service';
import { NamespaceFile } from '../../../shared/enums/namespace';
import { CurrentService } from '../../../shared/services/current.service';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';

@Component({
    selector: 'eaw-mini-kpi-widget',
    standalone: true,
    imports: [
        MiniWidgetContentComponent,
    ],
    templateUrl: './mini-kpi-widget.component.html',
    styleUrl: './mini-kpi-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniKpiWidgetComponent extends WidgetComponent implements OnInit {
    private readonly currentService = inject(CurrentService);
    private readonly translateService = inject(TranslateService);
    private readonly dashboardStoreService = inject(DashboardStoreService);
    private readonly numberFormatterService = inject(NumberFormatterService);

    widget = input.required<Widget<MiniKpiWidgetSettings>>();

    text = signal('');
    subtext = signal(Promise.resolve(''));

    ngOnInit() {
        const type = this.widget().getSetting('kpi_type');
        if (!type) {
            this.setError('settings');
            return;
        }

        const yesterday = DateTime.now().minus({ days: 1 });

        forkJoin([
            this.dashboardStoreService.getCustomerKpiTypes(this.widget().info.customer.id, type).pipe(
                catchError(() => {
                    this.setError('data');
                    return of(undefined);
                }),
            ),
            this.dashboardStoreService.getCustomerKpis(this.widget().info.customer.id, yesterday.startOf('day'), yesterday.endOf('day'), type).pipe(
                catchError(() => {
                    this.setError('data');
                    return of(undefined);
                }),
            ),
        ]).subscribe(([ kpiType, kpi ]) => {
            if (!kpiType || !kpi) {
                this.setError('data');
                return;
            }

            this.text.set(kpiType.valueFormatter(kpi.reduce((sum, kpi) => sum + kpi.value, 0), this.currentService.languageTag, this.numberFormatterService));
            this.subtext.set(this.translateService.t(kpiType.i18name.key, kpiType.i18name.ns as NamespaceFile));
            this.setLoading(false);
        });
    }
}
