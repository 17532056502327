import { Injectable } from '@angular/core';

export type JsonCountry = {
    id: number;
    name: string,
    Dial: number,
} & Record<string, any>

@Injectable({
    providedIn: 'root',
})
export class CountriesService {
    private countries?: JsonCountry[];

    async get() {
        if (!this.countries) {
            const result = (await import(/* webpackChunkName: "countries-json" */ 'src/assets/json/countries.json')).default;
            this.countries = result.map((r, i) => {
                return {
                    id: i,
                    ...r,
                } as JsonCountry;
            });
        }

        return this.countries;
    }
}
