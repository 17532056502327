import { module } from 'angular';
import { Model } from '../../../shared/angularjs/model';
import { HandleSomethingCtrl } from './dialogs/handle-something/handle-something-dialog.ctrl';
import { ShiftTooltipCtrl } from './segment-tooltip/segment-tooltip.ctrl';
import { t } from 'i18next';

const template2 = `<div class="header">
    <strong ng-bind="::ctrl.segment.employeePaidTime.employee.name"></strong>
</div>

<div class="shifts segment" ng-if="ctrl.shifts.length">
    <small bo-i18next="scheduling:SHIFT_plural"></small>

    <div layout="row" layout-align="start center" ng-repeat="shift in ::ctrl.shifts">
        <span ng-i18next="[i18next]({from: shift.from.format('LT'), to: shift.to.format('LT')})FROM_TO"></span>
        <small class="tw-pl-4">({{shift.net_length | eawDuration}})</small>
    </div>
</div>

<div class="timepunches segment" ng-if="ctrl.timepunches.length">
    <small bo-i18next="payroll:TIMEPUNCH_plural"></small>

    <div layout="row" layout-align="start center" ng-repeat="tp in ::ctrl.timepunches">
        <span ng-i18next="[i18next]({from: tp.from.format('LT'), to: tp.to.format('LT')})FROM_TO"></span>
        <small class="tw-pl-4">({{tp.to.diff(tp.from, 's') | eawDuration}})</small>
    </div>
</div>

<div class="duration">
    <span ng-bind="ctrl.segment.length | eawDuration:'short':'hms'"></span>
    <small ng-bind="ctrl.businessDateText"></small>
    <small ng-bind="ctrl.segmentFromTo"></small>
</div>
`;
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="$ctrl.segment.typeKey.translatedName"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <md-radio-group ng-model="$ctrl.component" ng-disabled="$ctrl.handling" ng-change="$ctrl.componentChange()">
                <md-radio-button ng-repeat="component in $ctrl.dialog.components" ng-value="component">
                    <span ng-bind="::component.action"></span>
                </md-radio-button>
            </md-radio-group>

            <form id="somethingForm" name="somethingForm">
                <eaw-date-time-interval
                        layout="row"
                        ng-model="$ctrl.interval"
                        required
                        ng-disabled="$ctrl.handling || $ctrl.readonly"
                        min-date="$ctrl.minDate"
                        max-date="$ctrl.maxDate">
                </eaw-date-time-interval>

                <md-input-container ng-if="$ctrl.component.hasBusinessDate">
                    <eaw-date-picker ng-model="$ctrl.businessDate" label="BUSINESS_DATE" required ng-disabled="$ctrl.handling || $ctrl.readonly"></eaw-date-picker>
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="DURATION"></label>
                    <input value="{{$ctrl.calcDuration() | eawDuration:'long':'hms'}}" readonly>
                </md-input-container>

                <div id="component-container"></div>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>

        <md-button class="md-accent md-raised"
                   form="somethingForm"
                   ng-disabled="$ctrl.handling || ($ctrl.component.careAboutForm && somethingForm.$invalid)"
                   ng-class="$ctrl.component.button.classes"
                   ng-click="$ctrl.handle()">
            <span ng-bind="$ctrl.component.button.text"></span>
        </md-button>
    </md-dialog-actions>
</md-dialog>
`;
export class EmployeePaidTimeSegment {
    id;
    type;
    marker1;
    marker2;
    customer;
    businessDate: any;
    differentBusinessDate = false;
    differentShownDate = false;
    length;
    invalid;
    paidTime: any;
    shift: any;
    leaveShift: any;
    absence: any;
    timepunch: any;
    suggestedPaidTime: any;
    handledAbsence: any;
    unhandledAbsence: any;
    pseudoHandledAbsence: any;
    unapprovedExtraTime: any;
    ComplexTooltip;
    ToastService;
    $rootScope;
    $mdDialog;

    constructor(args: any) {
        this.ComplexTooltip = args.ComplexTooltip;
        this.ToastService = args.ToastService;
        this.$rootScope = args.$rootScope;
        this.$mdDialog = args.$mdDialog;
        this.employeePaidTime = args.employeePaidTime;
        this.type = args.type;
        this.marker1 = args.marker1;
        this.marker2 = args.marker2;
        let customer = this.marker1?.customer || this.marker2?.customer;
        // If we are given a two-piece segment we created for constructing
        // Else it's an object provided by backend
        if (args.marker2) {
            this.id = `${this.type.key}-${+this.marker1.time}-${+this.marker2.time}`;
            this.from = args.marker1.time.clone();
            this.to = args.marker2.time.clone();
        } else {
            // Using Model due to it setting and handling Custom Fields
            args.marker1.morph_class = args.type.morphClass;
            // @ts-ignore
            this[args.type.key] = new Model(args.marker1);
            const item = this.item;
            this.id = item.id;
            this.from = (item.from || item.in);
            this.to = (item.to || item.out);
            this.businessDate = item.business_date;
            this.differentBusinessDate = !this.businessDate?.isSame(this.from, 'd');
            this.differentShownDate = this.businessDate && !args.shownDate?.isSame(this.businessDate, 'd');
            customer = args.marker1.customer?.id || args.marker1.customer_id || args.employeePaidTime.customer.id;
        }
        // Customer can be an object sometimes
        this.customer = customer?.id || customer;
        // if marker1 is an absence, we can't use its customer id, as external employees have customer ids of their original employer for all absences
        if (this.marker1?.type?.key === 'absence') {
            this.customer = args.employeePaidTime.customer.id;
        }
        this.length = this.to.diff(this.from, 's');
        this.invalid = this.length <= 0;
    }

    get item() {
        // @ts-ignore
        return this[this.type.key];
    }

    openDialog(dialog: any) {
        return this.$mdDialog.show({
            controller: HandleSomethingCtrl,
            template: template1,
            controllerAs: '$ctrl',
            bindToController: true,
            locals: {
                segment: this,
                dialog,
            },
        });
    }

    /**
     * Create a segment based on the given date
     * @param {Moment} date
     * @param {boolean} restricted -  wether or not user interaction is restricted
     * @returns {HTMLDivElement}
     */
    createElement(date: any, restricted: any) {
        const div = document.createElement('div');
        // Make sure offset stays at 0 or higher
        // and that the length is adjusted accordingly
        let offset = this.from.diff(date, 's');
        let length = this.length;
        if (offset < 0) {
            length += offset;
            offset = 0;
        }
        if (length <= 0) {
            return div;
        }
        // Identifier(s) for highlighting
        div.classList.add('segment', this.typeCssClass());
        div.dataset['id'] = this.id;
        div.dataset['type'] = this.type.key;
        div.style.setProperty('--offset', String(offset));
        div.style.setProperty('--length', String(length));
        div.style.setProperty('--background', this.type.color);
        div.style.setProperty('--z-index', this.type.zIndex ?? '2');
        div.style.setProperty('--top', this.type.top ?? '50%');
        div.style.setProperty('--height', this.type.height || '17.5px');
        div.style.setProperty('--transform', this.type.transform || 'translateY(-50%)');
        // Red border if not same business date
        if (this.differentBusinessDate) {
            div.style.border = '1px solid red';
        }
        if (this.differentShownDate) {
            div.style.opacity = '0.6';
        }
        // Only show start / end borders if they are on the same date as we are showing
        if (this.from.isSame(date, 'd')) {
            div.style.setProperty('--border-left', this.type.borderLeft || 'none');
        }
        if (this.to.isSame(date, 'd')) {
            div.style.setProperty('--border-right', this.type.borderRight || 'none');
        }
        if (this.type.dialog) {
            div.classList.add('hoverable');
            // external employees have absences with customer id of their original employer, so we need to ignore customer check
            if (!restricted && (this.type.key === 'handledAbsence' || this.type.key === 'unhandledAbsence' || this.customer === this.employeePaidTime.customer.id)) {
                div.addEventListener('click', () => {
                    if (this.employeePaidTime.hasOngoingTimepunch()) {
                        return this.ToastService.t('CANT_WHILE_ACTIVE_TP', 'payroll');
                    }
                    this.openDialog(this.type.dialog).then(() => {
                        this.$rootScope.$broadcast('paidtime:segment:change', {
                            segment: this,
                            element: div,
                        });
                    });
                });
            }
            div.addEventListener('mouseenter', (e) => {
                // Highlight all the same segment
                this.getSameSegments().forEach((el) => el.classList.add('hover'));
                this.ComplexTooltip(e, div, {
                    controller: ShiftTooltipCtrl,
                    template: template2,
                    panelClasses: [ 'segment-tooltip' ],
                    locals: {
                        segment: this,
                        customerId: this.customer,
                    },
                });
            });
            div.addEventListener('mouseleave', () => {
                // Remove highlight of all the same segment
                this.getSameSegments().forEach((el) => el.classList.remove('hover'));
            });
        }
        return div;
    }

    getSameSegments() {
        return Array.from(document.querySelectorAll(`[data-type="${this.type.key}"][data-seg-id="${this.id}"]`));
    }

    isType(type: any) {
        return this.type.key === type.key;
    }

    typeCssClass() {
        return this.type.name.toLowerCase().split(' ').join('-');
    }

    employeePaidTime;
    from;
    to;
}
export class PaidTimeSegmentFactory {
    $mdColorPalette;
    ComplexTooltip;
    ToastService;
    $rootScope;
    $mdDialog;
    static get $inject() {
        return [ '$mdColorPalette', 'ComplexTooltip', 'ToastService', '$rootScope', '$mdDialog' ];
    }

    constructor($mdColorPalette: any, ComplexTooltip: any, ToastService: any, $rootScope: any, $mdDialog: any) {
        this.$mdColorPalette = $mdColorPalette;
        this.ComplexTooltip = ComplexTooltip;
        this.ToastService = ToastService;
        this.$rootScope = $rootScope;
        this.$mdDialog = $mdDialog;
    }

    newSegment(type: any, employeePaidTime: any, marker1: any, marker2: any, shownDate: any) {
        return new EmployeePaidTimeSegment({
            type,
            employeePaidTime,
            marker1,
            marker2,
            shownDate,
            $mdDialog: this.$mdDialog,
            ComplexTooltip: this.ComplexTooltip,
            ToastService: this.ToastService,
            $rootScope: this.$rootScope,
        });
    }

    get types() {
        const paidTimeColor = this.$mdColorPalette.green[400].hex;
        const handledAbsenceColor = this.$mdColorPalette.purple[400].hex;
        const removePaidTimeComponent = {
            action: t('payroll:REMOVE_PAID_TIME'),
            name: 'ptRemovePaidTime',
            hasBusinessDate: true,
            button: {
                text: t('REMOVE'),
                classes: [ 'md-warn' ],
            },
        };
        const createPaidTimeComponent = {
            action: t('payroll:ADD_PAID_TIME'),
            name: 'ptCreatePaidTime',
            hasBusinessDate: true,
            button: { text: t('ADD') },
            careAboutForm: true,
        };
        const updatePaidTimeComponent = {
            action: t('payroll:UPDATE_PAID_TIME'),
            name: 'ptUpdatePaidTime',
            hasBusinessDate: true,
            button: { text: t('UPDATE') },
            careAboutForm: true,
        };
        const removeAbsenceComponent = {
            action: t('payroll:REMOVE_ABSENCE'),
            name: 'ptRemoveAbsence',
            button: {
                classes: [ 'md-warn' ],
                text: t('REMOVE'),
            },
        };
        const registerAbsenceComponent = {
            action: t('payroll:ADD_ABSENCE'),
            name: 'ptRegisterAbsence',
            button: { text: t('ADD') },
            careAboutForm: true,
        };
        const commentExtraTimeComponent = {
            action: t('COMMENT_ON', { on: '$t(payroll:UNAPPROVED_EXTRA_TIME)' }),
            name: 'ptCommentExtraTime',
            button: { text: t('COMMENT') },
            careAboutForm: true,
        };
        return {
            suggested: {
                key: 'suggestedPaidTime',
                name: 'Suggested paid time',
                translatedName: t('payroll:SUGGESTED_PAID_TIME'),
                color: `white repeating-linear-gradient(45deg, ${paidTimeColor}, ${paidTimeColor} 8px, rgba(0, 0, 0, 0.1) 8px, rgba(0, 0, 0, 0.1) 14px)`,
                dialog: {
                    components: [
                        createPaidTimeComponent,
                        registerAbsenceComponent,
                    ],
                },
            },
            absence: {
                key: 'absence',
                name: 'Absence',
                translatedName: '',
            },
            handled_absence: {
                key: 'handledAbsence',
                name: 'Handled absence',
                translatedName: t('payroll:ABSENCE_HOURS'),
                color: handledAbsenceColor,
                dialog: {
                    components: [
                        removeAbsenceComponent,
                        registerAbsenceComponent,
                    ],
                },
            },
            pseudo_handled_absence: {
                key: 'pseudoHandledAbsence',
                name: 'Pseudo handled absence',
                translatedName: t('payroll:HANDLED_ABSENCE'),
                color: handledAbsenceColor,
                dialog: {
                    components: [
                        createPaidTimeComponent,
                        registerAbsenceComponent,
                    ],
                },
            },
            unhandled_absence: {
                key: 'unhandledAbsence',
                name: 'Unhandled absence',
                translatedName: t('payroll:UNHANDLED_ABSENCE'),
                color: this.$mdColorPalette.yellow[400].hex,
                dialog: {
                    components: [
                        registerAbsenceComponent,
                        createPaidTimeComponent,
                    ],
                },
            },
            extra: {
                key: 'unapprovedExtraTime',
                name: 'Unapproved extra time',
                translatedName: t('payroll:UNAPPROVED_EXTRA_TIME'),
                color: this.$mdColorPalette.orange[400].hex,
                dialog: {
                    components: [
                        createPaidTimeComponent,
                        registerAbsenceComponent,
                        commentExtraTimeComponent,
                    ],
                },
            },
            shift: {
                key: 'shift',
                name: 'Shift',
                translatedName: t('scheduling:SHIFT'),
                color: 'rgba(0, 0, 0, 0.1)',
                zIndex: '1',
                height: '33px',
                borderLeft: '2px solid black',
                borderRight: '2px solid black',
            },
            leave_shift: {
                key: 'leave_shift',
                name: 'Leave shift',
                translatedName: t('leaveshifts:LEAVE_SHIFT_plural'),
                color: this.$mdColorPalette.brown[400].hex,
                morphClass: 'leave_shift',
                dialog: {},
            },
            paid_time: {
                key: 'paidTime',
                name: 'Paid time',
                translatedName: t('payroll:PAID_TIME'),
                color: paidTimeColor,
                morphClass: 'paid_time',
                dialog: {
                    components: [
                        updatePaidTimeComponent,
                        removePaidTimeComponent,
                    ],
                },
            },
            paid_time_flex: {
                key: 'paidTimeFlex',
                name: 'Paid time flex',
                translatedName: t('payroll:PAID_TIME_FLEX'),
                color: this.$mdColorPalette.blue[500].hex,
                morphClass: 'paid_time',
                dialog: {
                    components: [
                        updatePaidTimeComponent,
                        removePaidTimeComponent,
                    ],
                },
            },
            timepunch: {
                key: 'timepunch',
                name: 'Timepunch',
                translatedName: t('payroll:TIMEPUNCH'),
                color: 'rgba(0, 0, 0, 0.1)',
                zIndex: '0',
                height: '100%',
                top: '0',
                transform: 'none',
                borderLeft: '2px solid green',
                borderRight: '2px solid red',
            },
        };
    }

    generateLegend() {
        const items = [
            {
                translatedName: t('payroll:BELONGS_DIFF_DATE'),
                border: '1px solid red',
            },
            this.types.suggested,
            this.types.handled_absence,
            this.types.unhandled_absence,
            this.types.extra,
            this.types.paid_time,
            this.types.paid_time_flex,
        ];
        return items.reduce((arr: any, type: any) => {
            return arr.concat({
                type,
                color: type.color,
                border: type.border,
                text: type.translatedName,
            });
        }, []);
    }
}
module('eaw.payroll.paid-time').service('EmployeePaidTimeSegment', PaidTimeSegmentFactory);
