<eaw-page-header>
    <span title>{{ 'PLUS_HOURS_MONITORING' | translate:'analytics' | async }}</span>

    <span subtitle class="tw-flex tw-flex-row tw-items-center tw-gap-8">
            <eaw-date-interval-selector interval="month"
                                        disableCustomSelect
                                        singleDate
                                        (intervalChange)="intervalChange($event)"
                                        [from]="from"
                                        [to]="to"/>
        </span>

    <form smallFilter class="header-filter">
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>{{'FILTER' | translate | async}}</mat-label>
            <input matInput type="text" [placeholder]="('EMPLOYEE' | translate | async)!" [formControl]="employeeFilter">
        </mat-form-field>
    </form>
</eaw-page-header>

<mat-card id="table-card" [class.error]="hasError" [class.getting]="gettingData">
    @if (gettingData) {
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    } @else if (hasError) {
        <div class="tw-flex tw-flex-col tw-items-center tw-gap-8">
            <mat-icon eawMaterialColor="red-500" eawSize="48px">error</mat-icon>
            <span class="mat-subtitle-1">{{ 'UNEXPECTED_ERROR' | translate:'payroll_overview' | async }}</span>
        </div>
    } @else {
        <mat-card-header class="tw-pb-16">
            <mat-card-title>{{ date | DateTime:null:'MMMM yyyy' }}</mat-card-title>
        </mat-card-header>

        <table mat-table matSort matSortDisableClear [dataSource]="filteredDataSource" (matSortChange)="sort($event)" fixedLayout>
            @for (category of categories;track category.columnDef) {
                <ng-container [matColumnDef]="category.columnDef">
                    <th mat-header-cell class="category category-line" *matHeaderCellDef [colSpan]="category.columns.length"> {{ category.label | async }}</th>
                </ng-container>

                @for (column of category.columns;track column.columnDef) {
                    <ng-container [matColumnDef]="column.columnDef" [sticky]="column.sticky || false">
                        @if (column.sortArrow) {
                            <th [ngClass]="column.classes" [mat-sort-header]="column.columnDef" [arrowPosition]="column.sortArrow" mat-header-cell [class.category-line]="$first" *matHeaderCellDef>{{ column.label | async }}</th>
                        } @else {
                            <th [ngClass]="column.classes" mat-header-cell [class.category-line]="$first" *matHeaderCellDef>{{ column.label | async }}</th>
                        }

                        <td mat-cell [class.category-line]="$first" *matCellDef="let item">
                            @if (category.month !== undefined) {
                                <span [ngClass]="column.classes">{{ item.months?.[category.month]?.[column.itemKey] | eawNumber }}</span>
                            } @else {
                                <span [ngClass]="column.classes"> {{ item[column.itemKey] }}</span>
                            }
                        </td>
                    </ng-container>
                }
            }

            <tr mat-header-row *matHeaderRowDef="categoriesDef; sticky: true"></tr>
            <tr mat-header-row *matHeaderRowDef="columnsDef; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsDef;"></tr>
        </table>
    }
</mat-card>
