// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
const template1 = `<md-card>
    <md-card-content>
        <md-input-containter>
            <label ng-i18next="company:USER_GROUP_OVERVIEW_MODE"></label>
            <md-select ng-model="quickGroups.mode" ng-change="quickGroups.load()" ng-disabled="quickGroups.loading">
                <md-option ng-value="1">
                    <span ng-i18next="company:USER_GROUP_OVERVIEW_WITH"></span>
                </md-option>
                <md-option ng-value="0">
                    <span ng-i18next="company:USER_GROUP_OVERVIEW_WITHOUT"></span>
                </md-option>
                <md-option ng-value="undefined">
                    <span ng-i18next="company:USER_GROUP_OVERVIEW_ALL"></span>
                </md-option>
            </md-select>
        </md-input-containter>
    </md-card-content>
</md-card>

<md-card id="quick-card">
    <md-card-content ng-if="quickGroups.loading" layout="row" layout-align="center center" layout-margin>
        <md-progress-circular></md-progress-circular>
    </md-card-content>

    <quick-grid ng-if="quickGroups.loaded" people="quickGroups.users" items="quickGroups.groups" toggle="quickGroups.toggleGroup">
        <eaw-quick-header>
            <md-card-header-text>
                <span class="md-title" ng-i18next="navigation:USER_GROUPS"></span>
            </md-card-header-text>
        </eaw-quick-header>
    </quick-grid>
</md-card>
`;
module('eaw.company').component('quickUserGroups', {
    template: template1,
    controllerAs: 'quickGroups',
    bindings: {
        customer: '<',
    },
    controller: [ 'UserGroupService', 'Pagination', 'CustomerService', '$location', function quickUserGroups(UserGroupService, Pagination, CustomerService, $location) {
        this.$onInit = () => {
            this.customerId = this.customer.id;
            this.mode = $location.search().mode;
            this.load();
        };
        this.load = () => {
            this.loaded = false;
            this.loading = true;
            Promise.all([
                this.loadGroups(),
                this.loadUsers(),
            ]).then(([ groups, users ]) => {
                this.loaded = true;
                users.forEach((user) => {
                    user.items = groups.reduce((items, group) => {
                        items[group.id] = group.active_memberships.find((m) => m.member_id == user.id);
                        return items;
                    }, {});
                });
                this.users = users;
                this.groups = groups;
            }).finally(() => delete this.loading);
        };
        this.loadUsers = async () => {
            if (!this.users) {
                this.users = await Pagination.getData(new Pagination({
                    order_by: 'first_name',
                    customer: { id: this.customerId },
                }), (p) => CustomerService.getUsers(p).$promise);
            }
            return this.users;
        };
        this.loadGroups = async () => {
            const pagination = new Pagination({
                order_by: 'name',
                with: [ 'activeMemberships' ],
            });
            pagination.permissions = this.mode;
            $location.search('mode', this.mode);
            return Pagination.getData(pagination, (p) => UserGroupService.getAllGroupsForCustomer(this.customerId, p).$promise);
        };
        this.toggleGroup = (group, user) => {
            const g = user.items[group.id];
            if (g) {
                if (g.last_day) {
                    return;
                }
                UserGroupService.removeMember(this.customerId, group.id, user.id, g.id).$promise.then(() => user.items[group.id] = null);
            } else {
                UserGroupService.addMember(this.customerId, group.id, user.id, moment()).$promise.then((g) => {
                    user.items[group.id] = g.pivot;
                });
            }
        };
    } ],
});
