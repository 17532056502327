// @ts-nocheck
import { module } from 'angular';

const template1 = `<div class="tw-p-20" ng-if="!employees.employees.length" layout="row" layout-align="center center">
    <span ng-i18next="NO_INFO"></span>
</div>

<md-list class="tw-p-0 md-dense" ng-if="employees.employees.length">
    <md-list-item class="md-2-line" ng-repeat="employee in employees.employees">
        <eaw-profile-picture-old class="md-avatar" user="employee.user"></eaw-profile-picture-old>

        <div class="md-list-item-text">
            <h3 ng-bind="employee.name"></h3>
            <h3>{{employee.phone}}</h3>
            <p ng-if="!employee.working && !employee.hangingOut" md-colors="{color: 'red'}" ng-i18next="MISSING"></p>
            <p ng-if="employee.hangingOut" md-colors="{color: 'blue'}" ng-i18next="scheduling:OUTSIDE_SHIFT"></p>
        </div>

        <md-button class="md-secondary md-icon-button" aria-label="call" ng-if="employee.phone" href="tel:{{employee.phone}}">
            <md-icon ng-bind="'call'"></md-icon>
        </md-button>

        <md-divider ng-if="!$last"></md-divider>
    </md-list-item>
</md-list>
`;

module('eaw.dashboard').component('workingEmployeeList', {
    template: template1,
    controllerAs: 'employees',
    bindings: {
        employees: '<',
    },
});
