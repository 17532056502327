<eaw-dialog-header>
    <span title>{{'RUN_EXPORT' | translate | async}}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col">
    <eaw-subheader>
        <span>{{'INCLUDED_COLUMNS' | translate | async}}</span>
    </eaw-subheader>

    <mat-selection-list class="tw-mb-8">
        <mat-list-option *ngFor="let item of data.items" [(selected)]="item.included">{{item.header | async}}</mat-list-option>
    </mat-selection-list>

    <eaw-subheader>
        <span>{{'EXPORT_NAME' | translate | async}}</span>
    </eaw-subheader>

    <mat-form-field class="tw-mt-8">
        <mat-label>{{'NAME' | translate | async}}</mat-label>
        <input [formControl]="name" matInput placeholder="export">
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="export()">{{'EXPORT' | translate | async}}</button>
</mat-dialog-actions>
