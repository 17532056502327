// @ts-nocheck
import { module } from 'angular';
export class CreatePermissionDialog {
    $mdDialog;
    static get $inject() {
        return [ '$mdDialog' ];
    }

    static show = {
        template: `<md-dialog>
    <eaw-dialog-header-old heading="'admin:ADD_PERMISSION' | i18next"></eaw-dialog-header-old>

    <md-dialog-content>
        <md-tabs md-selected="cpCtrl.selectedTab" md-dynamic-height md-stretch-tabs="always">
            <md-tab label="{{'admin:CUSTOM_PERMISSION' | i18next}}">
                <md-content class="md-padding">
                    <form name="customForm" layout="column">
                        <md-input-container>
                            <label>{{'admin:PERMISSION' | i18next}}</label>
                            <input ng-model="cpCtrl.custom.permission" required minlength="1" maxlength="256" md-maxlength="256" uniq-form-name ng-keyup="cpCtrl.setCustomAllowed()" eaw-on-enter="cpCtrl.addCustomPermission()">
                        </md-input-container>

                        <md-radio-group ng-model="cpCtrl.custom.allow">
                            <md-radio-button ng-value="true">{{'admin:ALLOW' | i18next}}</md-radio-button>
                            <md-radio-button ng-value="false">{{'admin:DONT_ALLOW' | i18next}}</md-radio-button>
                        </md-radio-group>
                    </form>
                </md-content>
            </md-tab>
            <md-tab label="{{'admin:EXISTING_PERMISSION' | i18next}}">
                <md-dialog-content ng-show="cpCtrl.loadingPermissions">
                    <md-progress-circular class="md-progress-center tw-mt-20 tw-mb-20"></md-progress-circular>
                </md-dialog-content>
                <md-content class="md-padding" ng-show="!cpCtrl.loadingPermissions">
                    <form name="existingForm" layout="column">
                        <md-autocomplete
                                md-floating-label="{{'admin:PERMISSION' | i18next}}"
                                md-no-cache="true"
                                md-clear-button="true"
                                md-selected-item-change="cpCtrl.insertFields(cpCtrl.existing.permission)"
                                md-selected-item="cpCtrl.existing.permission"
                                md-search-text="filter"
                                md-require-match="true"
                                md-delay="300"
                                md-items="item in cpCtrl.getPermissions(filter)"
                                md-item-text="item.name"
                                required
                                md-input-name="permissionAutocomplete"
                                md-min-length="0">
                            <md-item-template>
                                <span md-highlight-text="filter" md-highlight-flags="gi">{{item.name}}</span>
                            </md-item-template>
                            <md-not-found>
                                <span ng-i18next="NO_MATCH_FOUND"></span>
                            </md-not-found>
                        </md-autocomplete>

                        <md-input-container ng-if="cpCtrl.existing.permission">
                            <label ng-i18next="DESCRIPTION"></label>
                            <input ng-model="cpCtrl.existing.permission.description" disabled>
                        </md-input-container>

                        <md-input-container ng-if="cpCtrl.existing.permission">
                            <label ng-i18next="NODE"></label>
                            <input ng-model="cpCtrl.existing.permission.value" disabled>
                        </md-input-container>

                        <md-input-container ng-repeat="field in cpCtrl.existingPermissionFields track by $index">
                            <label>{{field}}</label>
                            <input ng-model="cpCtrl.existing.fields[field]" uniq-form-name required minlength="1" eaw-on-enter="cpCtrl.addExistingPermission(existingForm)">
                        </md-input-container>

                        <md-radio-group ng-model="cpCtrl.existing.allow">
                            <md-radio-button ng-value="true">{{'admin:ALLOW' | i18next}}</md-radio-button>
                            <md-radio-button ng-value="false">{{'admin:DONT_ALLOW' | i18next}}</md-radio-button>
                        </md-radio-group>
                    </form>
                </md-content>
            </md-tab>
        </md-tabs>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>

        <md-button ng-if="cpCtrl.selectedTab == 0" ng-click="cpCtrl.addCustomPermission()" form="customForm" ng-disabled="customForm.$invalid" ng-i18next="SUBMIT"></md-button>
        <md-button ng-if="cpCtrl.selectedTab == 1" ng-click="cpCtrl.addExistingPermission(existingForm)" form="existingForm" ng-disabled="existingForm.$invalid" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`,
        controller: 'CreatePermissionDialogCtrl',
        controllerAs: 'cpCtrl',
    };

    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    open() {
        return this.$mdDialog.show(CreatePermissionDialog.show);
    }
}
module('eaw.dialogs').service('CreatePermissionDialog', CreatePermissionDialog);
