import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { WidgetPropertySettings } from '../../../classes/widget';
import { WidgetSettingsDialog } from '../../../classes/widget-settings-dialog';
import { KpiTypeService } from '../../../../kpi/http/kpi-type.service';
import { KpiType } from '../../../../kpi/models/kpi-type';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../../shared/services/current.service';
import { AsyncPipe } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { WidgetSettingsDialogComponent } from '../../widget-settings-dialog/widget-settings-dialog.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateService } from '../../../../shared/services/translate.service';
import { NamespaceFile } from '../../../../shared/enums/namespace';
import { InfoLoadingComponent } from '../../../../shared/components/info-loading/info-loading.component';

export interface MiniKpiWidgetSettings extends WidgetPropertySettings {
    /**
     * ID of the chosen KPI type
     */
    kpi_type: number;
}

@Component({
    selector: 'eaw-mini-kpi-settings-dialog',
    standalone: true,
    imports: [
        AsyncPipe,
        MatFormField,
        MatLabel,
        MatOption,
        MatSelect,
        TranslatePipe,
        WidgetSettingsDialogComponent,
        ReactiveFormsModule,
        InfoLoadingComponent,
    ],
    templateUrl: './mini-kpi-settings-dialog.component.html',
    styleUrl: './mini-kpi-settings-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniKpiSettingsDialogComponent extends WidgetSettingsDialog<MiniKpiWidgetSettings> implements OnInit {
    private readonly kpiTypeService = inject(KpiTypeService);
    private readonly currentService = inject(CurrentService);
    private readonly translateService = inject(TranslateService);

    protected loading = signal(true);
    protected kpiTypes = signal<{ name: string, value: KpiType }[]>([]);
    protected selectedType = new FormControl<KpiType | null>(null, Validators.required);

    constructor() {
        super();
    }

    ngOnInit() {
        this.kpiTypeService.getAll(this.data.widgetInfo.customer.id).subscribe(async (kpiTypes) => {
            for await (const kpiType of kpiTypes.data) {
                const { ns, key } = kpiType.i18name;
                const type = { name: await this.translateService.t(key, ns as NamespaceFile), value: kpiType };

                this.kpiTypes.update((types) => [ type, ...types ]);
            }

            this.kpiTypes.update((types) => sort(types, this.currentService.languageTag, [ (x) => x.name ]));
            this.loading.set(false);
        });
    }

    submitted() {
        const type = this.selectedType.value;
        if (!type) {
            return;
        }

        this.dialogRef.close({
            kpi_type: type.id,
        });
    }
}
