import { Inject, Injectable } from '@angular/core';
import { ConfirmDialogService } from '../dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from './translate.service';
import { DialogSize } from '../dialogs/dialog-component';

@Injectable({
    providedIn: 'root',
})
export class UnsavedChangesService {
    private message?: string | Promise<string>;
    private hasUnsavedCheckFunction?: () => boolean;

    constructor(
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(TranslateService) private translateService: TranslateService,
    ) {
    }

    init(fn: UnsavedChangesService['hasUnsavedCheckFunction'], message?: string | Promise<string>) {
        this.hasUnsavedCheckFunction = fn;
        this.message = message;

        window.addEventListener('beforeunload', this.beforeUnload);
    }

    hasUnsaved() {
        return this.hasUnsavedCheckFunction?.() ?? false;
    }

    showMessage() {
        const message = typeof this.message === 'string' ? Promise.resolve(this.message) : (this.message ? this.message : undefined);

        return this.confirmDialogService.delete({
            size: DialogSize.Medium,
            title: this.translateService.t('UNSAVED_CHANGES'),
            text: message || this.translateService.t('UNSAVED_CHANGES_TEXT'),
            confirmText: this.translateService.t('LEAVE_PAGE'),
            cancelText: this.translateService.t('CANCEL'),
        });
    }

    reset() {
        delete this.hasUnsavedCheckFunction;
        delete this.message;

        window.removeEventListener('beforeunload', this.beforeUnload);
    }

    private beforeUnload(e: BeforeUnloadEvent) {
        if (!this.hasUnsavedCheckFunction?.()) {
            return;
        }

        // Cancel according to html spec
        e.preventDefault();

        // Included for legacy support, e.g. Chrome/Edge < 119
        e.returnValue = true;

        return e.returnValue;
    }
}
