// @ts-nocheck
import { module } from 'angular';

/**
 * @ngdoc component
 * @name cardBtnFloat
 * @deprecated
 */
module('eaw').component('cardBtnFloat', {
    template: `<md-button ng-if="!$ctrl.state" ng-disabled="$ctrl.disabled" class="md-raised md-accent md-fab md-mini" ng-click="$ctrl.onClick()">
    <md-icon class="s18" ng-bind="$ctrl.mdiIcon"></md-icon>
    <md-tooltip ng-if="$ctrl.tooltip" ng-i18next="{{$ctrl.tooltip}}"></md-tooltip>
</md-button>

<a ng-if="$ctrl.state" ui-sref="{{$ctrl.state}}">
    <md-button ng-disabled="$ctrl.disabled" class="md-raised md-accent md-fab md-mini" ng-click="$ctrl.onClick()">
        <md-icon class="s18" ng-bind="$ctrl.mdiIcon"></md-icon>
        <md-tooltip ng-if="$ctrl.tooltip" ng-i18next="{{$ctrl.tooltip}}"></md-tooltip>
    </md-button>
</a>

`,
    bindings: {
        onClick: '&?',
        mdiIcon: '@?',
        tooltip: '<?',
        state: '@?',
    },
    controller: [ '$attrs', '$element', function($attrs, $element) {
        const ctrl = this;

        ctrl.$onInit = () => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            ctrl.onClick = ctrl.onClick || function() {};
            ctrl.mdiIcon = ctrl.mdiIcon || 'add';

            $element[0].classList.add('card-button-float');

            $attrs.$observe('disabled', (value) => {
                ctrl.disabled = value;
            });
        };
    } ],
});
