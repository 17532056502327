import { ChangeDetectionStrategy, Component, computed, effect, inject, signal, viewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatFormField, MatLabel } from '@angular/material/form-field';

import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTablePagination } from '../../../data-table/types/data-table';
import { ReportService } from '../../../reports/http/report.service';
import { debounceTime, map } from 'rxjs';
import { ArrayPaginatedResponse, AssocPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { ReportDescriptor, ReportDescriptorResponse } from '../../../reports/models/report-descriptor';
import { Namespace } from '../../../shared/enums/namespace';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { QueryParamsService } from '../../../shared/services/query-params.service';

@Component({
    selector: 'eaw-admin-reports-list',
    standalone: true,
    imports: [
        PageHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        MatCard,
        DataTableComponent,
        FormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        MatCardContent,
    ],
    templateUrl: './admin-reports-list.component.html',
    styleUrl: './admin-reports-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminReportsListComponent {
    private reportService = inject(ReportService);
    private translateService = inject(TranslateService);
    private searchParams = inject(QueryParamsService);

    columns: DataTableColumnType<ReportDescriptor>[] = [
        new DataTableTextColumn({
            header: new DataTableHeader({
                i18n: 'CLASS',
                sortBy: 'class',
                ns: Namespace.Admin,
            }),
            value: 'class',
        }),
        new DataTableTextColumn({
            value: 'module',
            header: new DataTableHeader({
                i18n: ' MODULE',
                ns: Namespace.Admin,
            }),
        }),
        new DataTableTextColumn({
            value: (cell) => this.translateService.t(cell.item?.name || '', Namespace.Reports),
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableTextColumn({
            value: (cell) => this.translateService.t(cell.item?.description || '', Namespace.Reports),
            header: new DataTableHeader({
                i18n: 'DESCRIPTION',
                sortBy: 'description',
            }),
        }),
    ];

    table = viewChild.required(DataTableComponent);

    search = signal<string>('');
    filter = toSignal(toObservable(this.search).pipe(debounceTime(1000)), { initialValue: '' });

    getData = computed(() => {
        const filter = this.filter() || '';
        return (pagination: Partial<DataTablePagination>) => this.reportService.getAllReports(
            { ...pagination, filter })
            .pipe(map((resp) => this.mapResponse(resp)));
    });

    mapResponse = (resp: AssocPaginatedResponse<ReportDescriptorResponse>) => ({
        ...resp,
        data: Object.entries(resp.data).map(([ itemClass, itemInfo ]: [ string, ReportDescriptorResponse ]) => {
            const split = itemClass.split('\\');
            return {
                ...itemInfo,
                class: split[split.length - 1],
                module: split[1],
            };
        }),
    }) as ArrayPaginatedResponse<ReportDescriptor>;

    constructor() {
        effect(() => this.searchParams.set([ { key: 'filter', value: this.filter() } ]));
    }
}
