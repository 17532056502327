import { AfterViewInit, ChangeDetectionStrategy, Component, effect, inject, input, signal, viewChild } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { ShiftService } from '../../../scheduling/http/shift.service';
import { CurrentService } from '../../../shared/services/current.service';
import { BasicDataSource } from '../../../shared/utils/basic-data-source';
import { Shift } from '../../../scheduling/models/shift';
import { PaginationOptions } from '../../../shared/interfaces/pagination-options';
import { DateTime } from 'luxon';
import { Widget } from '../../classes/widget';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { map } from 'rxjs';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProfilePictureComponent } from '../../../shared/components/profile-picture/profile-picture.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { ShiftPeriodListDialogComponent, ShiftPeriodListDialogData } from '../../../scheduling/dialogs/shift-period-list-dialog/shift-period-list-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent, CommentDialogData } from '../../../shared/dialogs/comments-dialog/comment-dialog.component';

interface ListItem {
    shift: Shift;
    differentBusinessDate: boolean;
    unproductivePercent?: number;
}

@Component({
    selector: 'eaw-all-shifts-widget',
    templateUrl: './all-shifts-widget.component.html',
    styleUrl: './all-shifts-widget.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        NgIf,
        ProfilePictureComponent,
        MatButtonModule,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class AllShiftsWidgetComponent extends WidgetComponent implements AfterViewInit {
    private matDialog = inject(MatDialog);
    private current = inject(CurrentService);
    private shiftService = inject(ShiftService);

    virtualScrollViewport = viewChild.required(CdkVirtualScrollViewport);

    widget = input.required<Widget>();

    protected readonly shiftHeight = 70;
    viewportHeight = signal(0);
    dataSource = signal<BasicDataSource<ListItem> | undefined>(undefined);

    constructor() {
        super();

        effect(() => {
            this.elementRef.nativeElement.style.setProperty('--viewport-height', `${this.viewportHeight()}px`);
            this.virtualScrollViewport().checkViewportSize();
        });
    }

    ngAfterViewInit() {
        this.elementRef.nativeElement.style.setProperty('--shift-height', `${this.shiftHeight}px`);

        this.createResizeObserver();
        this.createDataSource();
    }

    createResizeObserver() {
        const resizeObserver = new ResizeObserver((entries) => {
            const entry = entries[0];
            if (entry) {
                this.viewportHeight.set(entry.contentRect.height);
            }
        });

        resizeObserver.observe(this.elementRef.nativeElement);
    }

    openComments(shift: Shift) {
        const commentsObservable = this.shiftService.get(this.current.getCustomer().id, shift.id, {
            with: [ 'comments' ],
        }).pipe(map((res) => res.comments || []));

        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                comments: commentsObservable,
            },
        });
    }

    openPeriods(shift: Shift) {
        this.matDialog.open<ShiftPeriodListDialogComponent, ShiftPeriodListDialogData>(ShiftPeriodListDialogComponent, {
            data: {
                customerId: this.current.getCustomer().id,
                scheduleId: shift.scheduleId,
                shiftId: shift.id,
            },
        });
    }

    createDataSource() {
        const perPage = 10;
        const from = DateTime.now().startOf('day');
        const pagination: PaginationOptions = {
            page: 1,
            per_page: perPage,
            order_by: 'from',
            direction: 'asc',
            'with[]': [ 'employee', 'periods' ],
            'count[]': [ 'periods', 'comments' ],
        };

        this.shiftService.getAll(this.current.getCustomer().id, { ...pagination, from }).subscribe((res) => {
            const source = new BasicDataSource(res.total, perPage, this.toListItem(res.data), (page) => {
                return this.shiftService.getAll(this.current.getCustomer().id, {
                    ...pagination,
                    from,
                    page,
                }).pipe(map((res) => this.toListItem(res.data)));
            });

            this.dataSource.set(source);
            this.setLoading(false);
        });
    }

    toListItem(shifts: Shift[]): ListItem[] {
        return shifts.map((shift) => {
            return {
                shift,
                differentBusinessDate: !shift.businessDate?.dateTime.hasSame(shift.from, 'day'),
                unproductivePercent: Math.round(shift.periods.reduce((acc, currentValue) => {
                    return acc + (currentValue.unproductive ? currentValue.length : 0);
                }, 0) / shift.length * 100),
            };
        });
    }
}
