<h1 mat-dialog-title>{{data.title | async}}</h1>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-24">
    <div class="tw-whitespace-pre-wrap tw-break-words">{{data.text | async}}</div>

    <mat-form-field *ngIf="data.comment && data.comment.include">
        <mat-label>{{'COMMENT' | translate | async}}</mat-label>
        <textarea [formControl]="comment" matInput></textarea>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="undefined">{{cancelText | async}}</button>

    @if (data.confirmColor) {
        <button mat-raised-button [eawMaterialColor]="data.confirmColor" colorProperty="backgroundColor" [disabled]="comment.invalid" (click)="close()">{{confirmText | async}}</button>
    } @else {
        <eaw-action-button [loading]="false" [color]="data.confirmButtonColor || 'accent'" [disabled]="comment.invalid" (click)="close()">{{confirmText | async}}</eaw-action-button>
    }
</mat-dialog-actions>
