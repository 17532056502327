import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { Employee } from '../../../shared/models/employee';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { User } from '../../../shared/models/user';
import { EmployeeService } from '../../../shared/http/employee.service';
import { of, switchMap } from 'rxjs';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { CustomerUserAutocompleteComponent } from '../../../shared/components/customer-user-autocomplete/customer-user-autocomplete.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { TranslateService } from '../../../shared/services/translate.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { InfoBoxComponent } from '../../../shared/components/info-card/info-box.component';

export interface SwitchEmployeeUserDialogData extends DialogData {
    employee: Employee;
}

@Component({
    selector: 'eaw-switch-user-dialog',
    standalone: true,
    imports: [
        CommonModule,
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        CustomerUserAutocompleteComponent,
        MatDialogActions,
        MatDialogClose,
        TranslatePipe,
        MatButtonModule,
        ActionButtonComponent,
        MaterialColorDirective,
        InfoBoxComponent,
    ],
    templateUrl: './switch-employee-user-dialog.component.html',
    styleUrl: './switch-employee-user-dialog.component.scss',
})
export class SwitchEmployeeUserDialogComponent extends DialogComponent<SwitchEmployeeUserDialogData, number | undefined> {

    employee: Employee;
    form: FormGroup<{ user: FormControl<User | number | null> }>;
    loading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: SwitchEmployeeUserDialogData,
        @Inject(MatDialogRef) ref: MatDialogRef<SwitchEmployeeUserDialogComponent>,
        @Inject(EmployeeService) protected readonly employeeService: EmployeeService,
        @Inject(TranslateService) protected readonly translateService: TranslateService,
        @Inject(ConfirmDialogService) protected readonly confirmDialogService: ConfirmDialogService,
    ) {
        data.size = DialogSize.Medium;

        super(ref, data);

        this.employee = data.employee;

        const userCtrl: FormControl<User | number | null> = new FormControl<User | number | null>(this.employee.userId || null);

        userCtrl.disable();

        this.form = new FormGroup({
            user: userCtrl,
        });
    }

    save() {
        const user = this.form.controls.user.value as User | null;

        if (!user || this.employee.userId === user.id) {
            // no actual update
            return;
        }
        this.loading = true;

        this.confirmDialogService
            .open({
                title: this.translateService.t('CONNECT_USER', 'company'),
                text: this.translateService.t('CONNECT_NEW_USER_QUESTION', 'company', {
                    new: user.name,
                    name: this.employee.name,
                    old: this.employee.user?.name,
                }),
                confirmText: this.translateService.t('YES', 'company'),
            })
            .afterClosed()
            .pipe(switchMap((result) => {
                if (result?.ok) {
                    return this.employeeService.update(this.employee.customerId, this.employee.id, { user_id: user.id });
                }

                return of(undefined);
            }))
            .subscribe({
                error: () => {
                    this.loading = false;
                },
                next: (result) => {
                    this.loading = false;

                    if (result) {
                        this.dialogRef.close(user.id);
                    }
                },
            });
    }
}
