import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { BusinessUnit } from '../../models/business-unit';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { BusinessUnitService } from '../../http/business-unit.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Qualification } from '../../../shared/models/qualification';
import { CurrentService } from '../../../shared/services/current.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { QualificationAutocompleteComponent } from '../../../company/qualifications/autocomplete/qualification-autocomplete.component';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ColorPickerComponent } from '../../../shared/components/color-picker/color-picker.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

export interface EditBusinessUnitData extends DialogData {
    customerId: number;
    unit?: BusinessUnit,
    units: BusinessUnit[],
}

export interface EditBusinessUnitResult {
    id?: number,
    name?: string,
    code?: string,
    type?: string,
    default?: boolean,
    is_productive: boolean,
    color?: string,
    parent_id: number | null,
    qualifications?: number[],
    reverse_inherit_qualifications?: boolean,
}

@Component({
    selector: 'eaw-edit-business-unit',
    templateUrl: './edit-business-unit.component.html',
    styleUrl: './edit-business-unit.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        ColorPickerComponent,
        MatAutocompleteModule,
        MatOptionModule,
        NgFor,
        NgIf,
        QualificationAutocompleteComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class EditBusinessUnitComponent extends DialogComponent<EditBusinessUnitData, EditBusinessUnitResult, EditBusinessUnitComponent> implements OnInit {
    canGetQualifications = false;
    unit?: BusinessUnit;
    filteredUnits: BusinessUnit[];
    customerId: number;
    title = '';
    unitForm = new FormGroup({
        name: new FormControl('', {
            nonNullable: true,
            validators: [ Validators.required ],
        }),
        code: new FormControl(''),
        type: new FormControl(''),
        default: new FormControl<boolean>(false, { nonNullable: true }),
        isProductive: new FormControl<boolean>(true, { nonNullable: true }),
        color: new FormControl('#888', {
            nonNullable: true,
            validators: [ Validators.required ],
        }),
        parent: new FormControl<BusinessUnit | string | undefined>(undefined),
        qualifications: new FormControl<Qualification[]>([], { nonNullable: true }),
        reverseInheritQualifications: new FormControl<boolean>(false),
    });

    constructor(
        @Inject(BusinessUnitService) protected unitService: BusinessUnitService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(CurrentService) protected current: CurrentService,
        @Inject(MAT_DIALOG_DATA) data: EditBusinessUnitData,
        @Inject(MatDialogRef<EditBusinessUnitComponent, EditBusinessUnitResult>) dialogRef: MatDialogRef<EditBusinessUnitComponent, EditBusinessUnitResult>,
    ) {
        data.size = DialogSize.Medium;
        data.units = data.units.filter((u) => {
            const hasId = !!u.id;
            const hasNoParent = !u.parentId;
            const isNotSelf = u.id !== data.unit?.id;
            return hasId && hasNoParent && isNotSelf;
        });

        super(dialogRef, data);

        this.unit = data.unit;
        this.customerId = data.customerId;

        this.filteredUnits = data.unit?.parentId ? data.units.filter((u) => u.id === data.unit?.parentId) : data.units;

        this.unitForm.controls.parent.valueChanges.subscribe((filter) => {
            if (typeof filter !== 'string') {
                return data.units;
            }
            const search = filter.toLowerCase();
            this.filteredUnits = data.units.filter((u) => u.name.toLowerCase().includes(search));
        });

        if (data.unit) {
            this.unitForm.setValue({
                name: data.unit.name,
                code: data.unit.code,
                type: data.unit.type,
                default: data.unit.default,
                isProductive: data.unit.isProductive,
                color: data.unit.color.toHexString(),
                parent: data.units.find((u) => u.id === data.unit?.parentId) ?? '',
                qualifications: data.unit.qualifications || [],
                reverseInheritQualifications: data.unit.reverseInheritQualifications,
            });
        }
    }

    ngOnInit(): void {
        this.title = this.unit ? 'EDIT_UNIT' : 'ADD_UNIT';

        this.permissionCheckService.isAllowed(`customers.${this.customerId}.qualifications.*.get`).subscribe((canGetQualifications) => {
            this.canGetQualifications = canGetQualifications;
        });
    }

    displayWith(item?: BusinessUnit) {
        return item?.name || '';
    }

    submit() {
        const form = this.unitForm.value;

        const dialogResult: EditBusinessUnitResult = {
            id: this.unit?.id,
            parent_id: form.parent instanceof BusinessUnit ? form.parent.id : null,
            name: form.name,
            code: form.code ?? undefined,
            default: form.default,
            is_productive: form.isProductive !== false,
            type: form.type ?? undefined,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            color: form.color!,
            qualifications: form.qualifications?.map((q) => q.id),
            reverse_inherit_qualifications: form.reverseInheritQualifications || false,
        };

        this.dialogRef.close(dialogResult);
    }
}
