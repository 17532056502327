// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
module('eaw.misc').factory('PayTypeLinkFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const factory = this;
    const resource = EawResource.create('/customers/:customer_id/pay_type_links/:pay_type_link_id');
    factory.get = (args) => resource.get({
        pay_type_link_id: args.pay_type_link ? args.pay_type_link.id : args.pay_type_link_id,
        customer_id: args.customer_id,
        'with[]': args.with,
    });
    factory.getAll = (args) => resource.get(Pagination.getParams(args, [ 'active', 'manual', 'from', 'to', 'customer_id' ]));
    factory.add = (options) => resource.save({
        customer_id: options.customer_id,
    }, Object.assign({
        pay_type_id: options.pay_type.id,
        calculator_id: options.calculator ? options.calculator.id : undefined,
    }, pick(options, [ 'name', 'code', 'from', 'to', 'calculator_id', 'include_in_labor_cost', 'effect_on_labor', 'contract_types', 'pay_type_id', 'tariff_id', 'use_employee_rate', 'employee_rate_ratio', 'return_unit', 'absence_types', 'include_in_vacation' ])));
    factory.update = (options) => resource.update({
        pay_type_link_id: options.id,
        customer_id: options.customer_id,
        'with[]': options.with,
    }, Object.assign({ calculator_id: options.calculator ? options.calculator.id : null }, pick(options, [ 'name', 'code', 'from', 'to', 'calculator_id', 'include_in_labor_cost', 'effect_on_labor', 'contract_types', 'with', 'tariff_id', 'use_employee_rate', 'employee_rate_ratio', 'absence_types', 'include_in_vacation' ])));
    factory.delete = (options) => resource.delete({
        pay_type_link_id: options.pay_type_link ? options.pay_type_link.id : options.pay_type_link_id,
        customer_id: options.customer_id,
    });
    return factory;
} ]);
