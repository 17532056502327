import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BusinessDateString } from '../../shared/types/business-date';
import { BusinessDate } from '../../shared/utils/business-date';

export interface KpiResponse extends ApiResponse {
    business_date: BusinessDateString | null;
    customer_id: number;
    id: number;
    kpi_type_id: number;
    value: number;
    created_at?: string | null;
    updated_at?: string | null;
}

export class Kpi {
    businessDate: BusinessDate | null;
    customerId: number;
    id: number;
    kpiTypeId: number;
    value: number;
    createdAt: DateTime | null;
    updatedAt: DateTime | null;

    constructor(data: KpiResponse) {
        this.businessDate = data.business_date ? new BusinessDate(data.business_date) : null;
        this.customerId = data.customer_id;
        this.id = data.id;
        this.kpiTypeId = data.kpi_type_id;
        this.value = data.value;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
    }
}
