// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { EawResourceFactory } from '../../../shared/angularjs/modules/resource/resource.service';

export class HrEmployeeFileComponent {
    EawResource;

    static get $inject() {
        return [ 'EawResource' ];
    }

    file; // HrFile;
    signature;
    signer;
    user;
    customer;

    constructor(EawResource: EawResourceFactory) {
        this.EawResource = EawResource;
    }

    async $onInit() {
        this.signature = this.file.signatures?.[0];
        if (this.signature) {
            this.signer = await this.EawResource.create(`customers/${this.customer.id}/users/${this.signature?.user_id}`).get().$promise;
        }
    }

    async download() {
        await this.file.download();
        this.file.logs.unshift({
            id: +Date.now(),
            created_at: moment(),
            user: this.user,
        });
    }
}

module('eaw.hr').component('hrEmployeeFile', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="hr:FILE_INFO"></span>
        </md-card-header-text>

        <md-button class="md-raised md-accent" ng-if="!$hr.file.isSigned && $hr.file.canSign()" ng-click="$hr.file.goToSign()" ng-i18next="hr:SIGN"></md-button>
        <md-button class="md-raised" ng-if="$hr.file.showDownloadButton" ng-click="$hr.download()" ng-i18next="DOWNLOAD"></md-button>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <table class="table table-bordered table-condensed table-vmiddle">
            <tr>
                <td>{{'NAME' | i18next}}</td>
                <td>{{::$hr.file.name}}</td>
            </tr>
            <tr>
                <td>{{'hr:FILENAME' | i18next}}</td>
                <td>{{::$hr.file.attachments[0].name}}</td>
            </tr>
            <tr>
                <td>{{'TYPE' | i18next}}</td>
                <td>{{::$hr.file.type.name}}</td>
            </tr>
            <tr>
                <td>{{'EMPLOYEE' | i18next}}</td>
                <td>{{::$hr.file.employee.name}}</td>
            </tr>
            <tr>
                <td>{{'hr:UPLOAD_DATE' | i18next}}</td>
                <td>{{::$hr.file.created_at | moment}}</td>
            </tr>
            <tr>
                <td>{{'hr:UPLOADED_BY' | i18next}}</td>
                <td>{{::$hr.file.attachments[0].user.name}}</td>
            </tr>
            <tr>
                <td>{{'hr:SIGNED_BY' | i18next}}</td>
                <td>{{$hr.signer.name}}</td>
            </tr>
            <tr>
                <td>{{'hr:SIGNED' | i18next}}</td>
                <td>{{$hr.signature.created_at | moment}}</td>
            </tr>
        </table>
    </md-card-content>
</md-card>

<md-card id="hr-file-info-card">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="hr:DOWNLOAD_LOG"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <md-list>
            <md-list-item class="md-3-line dl-log-item" ng-repeat="log in $hr.file.logs track by log.id">
                <eaw-profile-picture-old class="tw-pr-16" size="53px" user="log.user"></eaw-profile-picture-old>
                <div class="md-list-item-text" layout="column">
                    <h3 ng-bind="log.user.name"></h3>
                    <h4 ng-bind="log.created_at | moment:'LLLL'"></h4>
                    <p>
                        <eaw-time time="log.created_at"></eaw-time>
                    </p>
                </div>
                <md-divider ng-if="!$last"></md-divider>
            </md-list-item>
        </md-list>
    </md-card-content>
</md-card>
`,
    bindings: {
        file: '<',
        user: '<',
        customer: '<',
    },
    controllerAs: '$hr',
    controller: HrEmployeeFileComponent,
});
