<div id="chart" #chart></div>

<div id="bottom">
    <button mat-mini-fab (click)="changeWeek(-1)" [disabled]="changing">
        <mat-icon>arrow_back</mat-icon>
    </button>

    <div class="sum">
        <span class="title">{{'PLANNED' | translate:'widgets' | async}}</span>
        <span class="number">{{plannedSecondsSum()}}</span>
    </div>

    <div class="sum">
        <span class="title">{{'PUNCHED' | translate:'widgets' | async}}</span>
        <span class="number">{{punchedSecondsSum()}}</span>
    </div>

    <button mat-mini-fab (click)="changeWeek(1)" [disabled]="changing">
        <mat-icon>arrow_forward</mat-icon>
    </button>
</div>
