import { inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { UIRouter } from '@uirouter/core';

export type SearchParamType = 'string' | 'number' | 'date' | 'datetime' | 'array' | 'boolean';

@Injectable({
    providedIn: 'root',
})
export class QueryParamsService {
    uiRouter = inject(UIRouter);

    get(key: string, type: 'array'): string[] | undefined;
    get(key: string, type: 'date' | 'datetime'): DateTime | undefined;
    get<Return = string>(key: string, type: 'string'): Return | undefined;
    get(key: string, type: 'number'): number | undefined;
    get(key: string, type: SearchParamType): string | number | DateTime | string[] | undefined {
        const value = this.getAll().get(key);

        if (!value) {
            return;
        }

        if (type === 'string') {
            return value;
        }

        if (type === 'number') {
            const number = parseFloat(value);
            return Number.isFinite(number) ? number : undefined;
        }

        if (type === 'date' || type === 'datetime') {
            const date = DateTime.fromSQL(value);
            return date.isValid ? date : undefined;
        }

        if (type === 'array') {
            return value?.length ? value.split(',') : [];
        }
    }

    getAll() {
        const params = new Map<string, string>(Object.entries(this.uiRouter.globals.params));
        params.delete('#');

        return params;
    }

    set(items: { key: string, value: string | number | boolean | DateTime | (string | number)[] | undefined | null, type?: 'date' | 'datetime' }[]): void {
        const params: Record<string, string> = {};

        items.forEach((item) => {
            const isNil = item.value == null;
            const emptyString = typeof item.value === 'string' && !item.value.length;
            const emptyArray = Array.isArray(item.value) && !item.value.length;
            const invalidNumber = typeof item.value === 'number' && !Number.isFinite(item.value);
            const invalidDateTime = item.value instanceof DateTime && !item.value.isValid;

            if (isNil || emptyString || emptyArray || invalidNumber || invalidDateTime) {
                params[item.key] = '';
                return;
            }

            if (typeof item.value === 'string') {
                params[item.key] = item.value;
                return;
            }

            if (typeof item.value === 'boolean') {
                params[item.key] = item.value ? '1' : '0';
                return;
            }

            if (typeof item.value === 'number' && Number.isFinite(item.value)) {
                params[item.key] = item.value.toString();
                return;
            }

            if (item.value instanceof DateTime) {
                const value = item.type === 'date' ? item.value.toSQLDate() : item.value.toSQL()?.substring(0, 19);
                if (value) {
                    params[item.key] = value;
                    return;
                }

                throw new Error('Param of type DateTime must have a type specified (date or datetime)');
            }

            if (Array.isArray(item.value)) {
                params[item.key] = item.value.map((v) => v.toString()).join(',');
                return;
            }
        });

        this.uiRouter.stateService.go('.', params, { location: 'replace' });
    }
}
