<eaw-page-header [useBigFilter]="!loading()" [bigFilterToggled]="!loading()" [buttons]="headerButtons">
    <span title>{{ 'SEE_ABSENCES' | translate:'navigation' | async }}</span>

    <div bigFilter>
        <form [formGroup]="formGroup" class="tw-grid tw-gap-8 tw-grid-cols-12" id="absenceFilter">
            <mat-form-field class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 lg:tw-col-span-3">
                <mat-label>{{ 'MODE' | translate:'absences' | async }}</mat-label>
                <mat-select formControlName="mode">
                    @for (mode of modes; track mode.id) {
                        <mat-option [value]="mode.id">{{ mode.label | translateSync }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field eawDatePickerOptions class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 lg:tw-col-span-3">
                <mat-label>{{ 'ENTER_DATE_RANGE' | translate | async }}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
                    <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async" autocomplete="off">
                    <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async" autocomplete="off">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <eaw-autocomplete formControlName="employee"
                              class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 lg:tw-col-span-3"
                              [options]="employeeAutocompleteService.options"
                              [getter]="employeeAutocompleteService.getter({
                                  customerId: customerId,
                                  from: formGroup.value.dateRange?.from || null,
                                  to: formGroup.value.dateRange?.to || null
                              })"
                              [setter]="employeeAutocompleteService.setter({customerId: customerId})">
            </eaw-autocomplete>

            @if (showSubordinates()) {
                <mat-form-field class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 lg:tw-col-span-3">
                    <mat-label>{{ 'ONLY_MY_EMPLOYEES' | translate | async }}</mat-label>
                    <mat-select formControlName="onlyMyEmployees">
                        <mat-option [value]="true">{{ 'YES' | translateSync }}</mat-option>
                        <mat-option [value]="false">{{ 'NO' | translateSync }}</mat-option>
                    </mat-select>
                </mat-form-field>
            }

            <mat-form-field class="tw-col-span-full">
                <mat-label>{{ 'ABSENCE_TYPE_plural' | translate | async }}</mat-label>
                <mat-chip-grid #chipGrid>
                    @for (absenceType of selectedAbsenceTypes() | keyvalue; track absenceType.key) {
                        <mat-chip-row (removed)="removeAbsenceType(absenceType.key)">
                            {{ absenceType.value.name | translate:'absence_types' | async }}

                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    }
                </mat-chip-grid>

                <input [placeholder]="('TYPE_TO_SEARCH' | translate | async) || ''"
                       #absenceTypeInput
                       formControlName="absenceType"
                       [matChipInputFor]="chipGrid"
                       [matAutocomplete]="absenceTypesAutocomplete"
                       [matChipInputSeparatorKeyCodes]="absenceTypesSeparatorKeysCodes"
                       (matChipInputTokenEnd)="addAbsenceType($event)"/>

                <mat-autocomplete #absenceTypesAutocomplete="matAutocomplete" (optionSelected)="selectedAbsenceType($event)">
                    @for (absenceType of filteredAbsenceTypes | async; track absenceType.id) {
                        <mat-option [value]="absenceType">{{ absenceType.translatedName }}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        </form>

        <div class="tw-flex tw-gap-8 tw-col-span-full">
            <eaw-action-button [loading]="false" formId="absenceFilter" (click)="update()">{{ 'UPDATE' | translate | async }}</eaw-action-button>
            <button mat-raised-button (click)="reset()">{{ 'RESET' | translate | async }}</button>
        </div>
    </div>
</eaw-page-header>

@if (loading()) {
    <eaw-info-loading size="lg"></eaw-info-loading>
} @else {
    <mat-card>
        <mat-card-content>
            <eaw-data-table
                    [columns]="columns()"
                    initialSortBy="from"
                    [getData]="getData()"
                    [goTo]="{state: 'eaw/app/absence/list/tabs/edit', params: [{stateKey: 'employee_id', itemKey: 'employeeId'}, {stateKey: 'id', itemKey: 'id'}]}">
            </eaw-data-table>
        </mat-card-content>
    </mat-card>
}
