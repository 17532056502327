// @ts-nocheck
import { module } from 'angular';
module('eaw.hr').factory('DefaultHrFileFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/default_hr_files/:file');
    fac.getAll = {
        /**
         * @param {int} customerId
         * @returns {string}
         */
        permission(customerId) {
            return `customers.${customerId}.default_hr_files.*.get`;
        },
        /**
         * @param {object} args
         * @param {int} args.customerId
         * @returns {*}
         */
        query(args) {
            return res.get({
                customer: args.customerId,
                ...Pagination.getParams(args),
            });
        },
    };
    fac.get = {
        /**
         * @param {int} customerId
         * @param {int} fileId
         * @returns {string}
         */
        permission(customerId, fileId) {
            return `customers.${customerId}.default_hr_files.${fileId}.get`;
        },
        /**
         * @param {int} customerId
         * @param {int} fileId
         * @returns {*}
         */
        query(customerId, fileId) {
            return res.get({
                customer: customerId,
                file: fileId,
            });
        },
    };
    return fac;
} ]);
