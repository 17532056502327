// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

module('eaw.time').filter('momentString', function momentStringFilter() {
    return function momentString(string, format, newFormat) {
        const date = moment(string, format);
        return date.isValid() ? date.format(newFormat) : 'N/A';
    };
});
