// @ts-nocheck
import { isObject } from 'lodash-es';
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.calendar').service('AbsenceDataSource', [ 'Absence', 'CalendarEvent', 'DataSource', function AbsenceDataSourceClass(Absence, CalendarEvent, DataSource) {
    class AbsenceDataSource extends DataSource {
        constructor(customer) {
            super(customer);
            this.name = 'absences';
            this.i18n = AbsenceDataSource.getI18nName();
            this.useOnEmployee = true;
            this.active = false;
            this.noItemsText = t('calendar:NO_ABSENCE_THIS_DAY');
            this.canUseSource = CurrentOld.can(`customers.${this.customer.id}.absences.*.get`);
            this.contextMenu = [];
        }

        /**
         * @param {moment.Moment} from
         * @param {moment.Moment} to
         * @param {Object} [employee]
         * @returns {Promise}
         */
        get(from, to, employee) {
            let promise = Absence.getForCustomer({
                customerId: this.customer.id,
                from,
                to,
                per_page: 999,
                with: [ 'type', 'employee', 'approval' ],
            }).$promise;
            if (isObject(employee)) {
                promise = Absence.getForEmployee({
                    customerId: this.customer.id,
                    employeeId: employee.id,
                    from,
                    to,
                    per_page: 32,
                    with: [ 'type', 'employee', 'approval' ],
                }).$promise;
            }
            return promise.then((resp) => {
                // Make data into events
                resp.data.forEach((d) => {
                    if (d.approval && d.approval.approved === false) {
                        return;
                    }
                    AbsenceDataSource.dataToCalendar(d).broadcastAdded();
                });
            });
        }

        static getI18nName() {
            return t('calendar:ABSENCE_plural');
        }

        static dataToCalendar(data) {
            data.dataSource = 'absences';
            data.color = data.type?.color;
            data.name = `${data.employee?.name} - ${data.type?.name}`;
            data.absenceType = data.type;
            return new CalendarEvent(data);
        }
    }
    return AbsenceDataSource;
} ]);
