import { EmployeeTask, EmployeeTaskResponse } from '../../shared/models/employee-task';
import { EawUrl } from '../../shared/angularjs/modules/resource/url.service';

export interface UploadDocumentTaskResponse extends EmployeeTaskResponse {
    data: {
        workflow_id: string,
        run_id: string,
        current_run_step_id: string,
        customer_id: number,
        employee_id: number,
        file_type_id: number,
        workflow_revision: number,
    };
}

export class UploadDocumentTask extends EmployeeTask {
    currentRunStepId: string;
    customerId: number;
    employeeId: number;
    runId: string;
    workflowId: string;
    fileTypeId: number;
    revision: number;

    constructor(response: UploadDocumentTaskResponse) {
        super(response);

        this.currentRunStepId = response.data.current_run_step_id;
        this.customerId = response.data.customer_id;
        this.employeeId = response.data.employee_id;
        this.runId = response.data.run_id;
        this.workflowId = response.data.workflow_id;
        this.fileTypeId = +response.data.file_type_id;
        this.revision = +response.data.workflow_revision;
    }

    /**
     * Url to upload the document
     */
    get url() {
        if (!this.workflowId) {
            return null;
        }

        return EawUrl.getUrl(`/document-service/1.0.0/customers/${this.customerId}/employees/${this.employeeId}/workflows/${this.workflowId}/runs/${this.runId}/steps/${this.currentRunStepId}`);
    }
}
