@if (loadingData()) {
    <eaw-info-loading class="tw-my-24" size="lg"/>
} @else {
    <table id="availability-table">
        <thead>
        <tr>
            <th>{{ 'EMPLOYEE' | translate | async }}</th>
            <th>{{ 'WEEK' | translate | async }}</th>

            @for (day of headerDays(); track day) {
                <th>
                    @if (day.todayDiff >= -1 && day.todayDiff <= 1) {
                        <small>{{ day.relative }}</small>
                    }

                    <div>{{ day.weekday }}</div>
                </th>
            }

            @if (includePhone()) {
                <th>{{ 'PHONE' | translate | async }}</th>
            }
        </tr>
        </thead>

        @if (!overview().length) {
            <tbody>
            <tr>
                <td class="cell-content" colspan="9999">
                    <span>{{ 'NO_EMPS_ON_LOCATION' | translate:'reports' | async }}</span>
                </td>
            </tr>
            </tbody>
        }

        @for (item of filteredOverview(); track item.employee.id) {
            <tbody>
                @for (week of item.intervals; track $index; let weekIndex = $index) {
                    <tr>
                        @if (weekIndex === 0) {
                            <td class="cell-content employee" [class.separator-line]="weekIndex === 0 && item.intervals.length > 1" [rowSpan]="item.intervals.length">{{ item.employee.first_name }} {{ item.employee.last_name }}</td>
                        }

                        <td class="cell-content" [class.separator-line]="weekIndex === 0 && item.intervals.length > 1">{{ tableWeeks().get(weekIndex) }}</td>

                        @for (day of week; track day; let dayIndex = $index) {
                            <td [ngClass]="getAvailabilityClass(day, workingEmployees().has(getWorkingEmployeesKey(item.employee.id, weekIndex, dayIndex)))"
                                [class.separator-line]="weekIndex === 0 && item.intervals.length > 1">

                                <div class="cell-content tw-flex tw-flex-col">
                                    @if (workingEmployees().has(getWorkingEmployeesKey(item.employee.id, weekIndex, dayIndex))) {
                                        <span class="cell-status">Working</span>
                                    } @else {
                                        @if (day.length) {
                                            @for (interval of day; track interval) {
                                                <div class="tw-flex tw-justify-between">
                                                    @if (interval.unavailable) {
                                                        <span class="cell-status">{{ 'NOT_AVAILABLE' | translate | async }}</span>
                                                    } @else if (interval.wholeDay) {
                                                        <span class="cell-status">{{ 'AVAILABLE' | translate | async }}</span>
                                                    } @else {
                                                        <div class="cell-status tw-flex tw-gap-8">
                                                            <span>{{ interval.from | DateTime:'TIME_SIMPLE' }} - {{ interval.to | DateTime:'TIME_SIMPLE' }}</span>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        } @else {
                                            <span class="cell-status">{{ 'NOT_AVAILABLE' | translate | async }}</span>
                                        }
                                    }

                                    <span class="date">{{ tableDates().get(weekIndex)?.get(dayIndex) }}</span>
                                </div>
                            </td>
                        }

                        @if (includePhone()) {
                            <td class="cell-content" [class.separator-line]="weekIndex === 0 && item.intervals.length > 1">
                                @if (item.employee.phone) {
                                    <a [href]="'tel:' + item.employee.phone">
                                        <button mat-raised-button>
                                            <mat-icon class="tw-mr-4">phone</mat-icon>
                                            {{ item.employee.phone }}
                                        </button>
                                    </a>
                                }
                            </td>
                        }
                    </tr>
                }
            </tbody>
        }
    </table>
}
