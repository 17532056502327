import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AggregateTimepunchInfoService } from '../../../services/aggregate-timepunch-info.service';
import { DurationPipe } from '../../../../shared/pipes/duration.pipe';
import { NumberPipe } from '../../../../shared/pipes/number.pipe';
import { ColumnHeader } from '../../../types/column-header';
import { AggregateRow } from '../../../types/aggregate-row';
import { AggregateTimepunchValues } from '../../../types/aggregate-timepunch-values';
import { firstValueFrom } from 'rxjs';
import { Products } from '../../../../shared/enums/products';
import { CustomerProductService } from '../../../../shared/http/customer-product.service';

@Component({
    selector: 'eaw-cell-total',
    template: '<strong>{{value}}</strong>',
    providers: [ DurationPipe, NumberPipe ],
    standalone: true,
})
export class CellTotalComponent implements OnChanges {
    private customerProductService = inject(CustomerProductService);
    private aggregateTimepunchInfoService = inject(AggregateTimepunchInfoService);
    private durationPipe = inject(DurationPipe);
    private numberPipe = inject(NumberPipe);

    @Input({ required: true }) column!: ColumnHeader;
    @Input({ required: true }) rows!: AggregateRow[];
    @Input({ required: true }) customerId!: number;
    @Input() aggregated?: boolean;

    value: string | number = '';

    ngOnChanges(changes: SimpleChanges) {
        if (Array.isArray(changes['rows']?.currentValue)) {
            void this.setTotals();
        }
    }

    async setTotals() {
        let value: string | number;
        const key = this.column.key as AggregateTimepunchValues;
        const rows = this.rows.filter((r) => this.aggregated ? r.aggregated : true);
        const hasScheduling = await firstValueFrom(this.customerProductService.hasProducts(this.customerId, [ Products.Scheduling ]));

        if (!this.aggregateTimepunchInfoService.getItems(hasScheduling)[key]?.canTotal) {
            return;
        }

        switch (this.column.key) {
            case 'infractions': {
                value = rows.reduce((sum, row) => {
                    const v = row.data.values['infractions']?.value;
                    return sum + (Array.isArray(v) ? v.length : 0);
                }, 0);
                break;
            }
            case 'count': {
                value = rows.reduce((sum, row) => sum + (row.data.ids.length || 0), 0);
                break;
            }
            default: {
                value = rows.reduce((sum, row) => {
                    const v = row.data.values[key]?.value;
                    return sum + (typeof v === 'number' ? v : 0);
                }, 0);
            }
        }

        switch (this.column.type) {
            case 'integer': {
                this.value = this.transformNumber(value as number, false);
                return;
            }
            case 'decimal': {
                this.value = this.transformNumber(value as number, true);
                return;
            }
            case 'hours': {
                this.value = this.durationPipe.transform(value, [ 'hours', 'minutes' ], { unitDisplay: 'narrow' });
                return;
            }
            case 'days': {
                this.value = this.durationPipe.transform(value, [ 'days' ], { unitDisplay: 'narrow' });
                return;
            }
            default: {
                this.value = value;
            }
        }
    }

    transformNumber(value: number, decimal: boolean) {
        return this.numberPipe.transform(value, decimal ? 2 : 0);
    }
}
