import { pick } from 'lodash-es';
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';
import { EawResourceFactory } from '../../shared/angularjs/modules/resource/resource.service';
import { PaginationOld } from '../../shared/utils/pagination-old';

module('eaw.todo').factory('TodoFactory', [ 'EawResource', function(EawResource: EawResourceFactory) {
    // @ts-ignore
    const todoFactory = this;
    /**
     * Get all items for a user
     * @param {object}      args
     * @param {object}      [args.customer]
     * @param {object}      [args.user]
     * @param {string[]}    [args.fields]
     * @param {int[]}       [args.status_ids]
     * @param {moment}      [args.due]
     * @param {moment}      [args.due_end]
     * @param {bool}        [args.done]       - true gets all done, false get all except done, undefined gets both
     */
    todoFactory.getAllForUser = (args: any) => {
        return EawResource.create('/customers/:customer/users/:user/todo_items').get({
            ...PaginationOld.getParams(args, [ 'source', 'customer_id', 'due', 'due_end', 'done', 'include_future', 'from' ]),
            customer: args.customer ? args.customer.id : CurrentOld.customer['id'],
            user: args.user ? args.user.id : CurrentOld.user['id'],
            'with[]': [ 'responsible', 'status' ],
            'status_ids[]': args.status_ids,
            'status_type[]': args.status_types,
        });
    };

    /**
     * Update an item
     */
    todoFactory.update = (customerId: number, itemId: number, updateFields: any) => {
        const fields = pick(updateFields, [ 'responsible', 'responsible_id', 'status_id', 'title', 'description', 'notify', 'due', 'from' ]);
        // Check title
        fields.title = !fields.title?.trim?.().length ? undefined : fields.title;
        // Set id
        if (fields.responsible) {
            fields.responsible_id = fields.responsible.id;
            delete fields.responsible;
        }
        // Dates
        fields.from = fields.from || undefined;
        fields.due = fields.due || undefined;

        return EawResource.create('/customers/:customer/todo_items/:item').update({
            customer: customerId,
            item: itemId,
            'with[]': [ 'responsible', 'status', 'creator', 'attachments.user' ],
        }, fields);
    };

    return todoFactory;
} ]);
