<eaw-dialog-header>
    <span title>{{ 'ADD_OWNERSHIP_TRANSFER' | translate:'admin' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-16">
        <eaw-autocomplete formControlName="customer"
                          [disabled]="loading()"
                          [options]="customerAutocompleteService.options"
                          [disableItem]="transferCustomerFilter"
                          [setter]="customerAutocompleteService.setter()"
                          [getter]="customerAutocompleteService.getter()">
        </eaw-autocomplete>

        <eaw-autocomplete formControlName="transferCustomer"
                          [disabled]="loading()"
                          [options]="transferCustomerOption"
                          [disableItem]="customerFilter"
                          [setter]="customerAutocompleteService.setter()"
                          [getter]="customerAutocompleteService.getter()">
        </eaw-autocomplete>

        <mat-form-field eawDatePickerOptions>
            <mat-label>{{ 'TRANSFER_EFFECTIVE_DATE' | translate: 'admin' | async }}</mat-label>
            <input matInput
                   [disabled]="loading()"
                   autocomplete="off"
                   formControlName="effectiveDate"
                   [matDatepicker]="$any(picker)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </form>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [loading]="loading()" [disabled]="form.invalid"
                       (click)="submit()">{{ 'SUBMIT' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
