// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
const template2 = `<md-dialog>
    <form role="form" autocomplete="off" name="addTariffForm" ng-submit="ctrl.create()">
        <eaw-dialog-header-old heading=" 'NEW_X' | i18next: {name: '$t(TARIFF)'} "></eaw-dialog-header-old>
        <md-dialog-content>
            <div class="md-dialog-content">
                <md-input-container>
                    <label ng-i18next="NAME"></label>
                    <input ng-model="ctrl.tariff.name" required>
                </md-input-container>

                <md-input-container flex>
                    <label ng-i18next="TYPE"></label>
                    <md-select ng-model="ctrl.tariff.type" required>
                        <md-option ng-repeat="type in ctrl.types" ng-value="type.value">
                            <span ng-bind="type.label"></span>
                        </md-option>
                    </md-select>
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button type="submit" ng-i18next="SUBMIT" ng-disabled="addTariffForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.chain-ops').component('chainOpsTariffList', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="TARIFF_plural"></span>
        </md-card-header-text>

        <card-btn-float on-click="$chainTariffListCtrl.new()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="$chainTariffListCtrl.table.columns"
                cells="$chainTariffListCtrl.table.cells"
                get-data="$chainTariffListCtrl.getAll(pagination)"
                reload-table="$chainTariffListCtrl.reloadTable"
                go-to="eaw/app/chain_ops/tariff/rates|settings_group_id=setting_group_id|tariff_id=id"
        >
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    bindings: {
        settingGroup: '<',
    },
    controllerAs: '$chainTariffListCtrl',
    controller: [ 'TariffFactory', 'deleteItemAlert', '$mdDialog', 'ToastService', function(TariffFactory, deleteItemAlert, $mdDialog, ToastService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.currentCustomer = CurrentOld.customer;

            ctrl.table = {
                columns: [
                    {
                        title: t('ID'),
                        sortable: 'id',
                    },
                    {
                        title: t('NAME'),
                        sortable: 'name',
                    },
                    {
                        title: t('TYPE'),
                        sortable: 'type',
                    },
                    {
                        title: t('AMOUNT'),
                        sortable: 'rates_count',
                        class: 'text-right',
                    },
                    { title: '' },
                ],
                cells: [
                    { template: 'item.id' },
                    { template: 'item.name' },
                    { template: 'item.type' },
                    {
                        template: 'item.rates_count',
                        class: 'text-right',
                    },
                    {
                        buttons: [
                            {
                                icon: 'edit',
                                click: ctrl.update,
                            },
                            {
                                icon: 'delete',
                                type: 'warn',
                                click: ctrl.delete,
                                invisible: (tariff) => tariff.rates_count > 0,
                            },
                        ],
                    },
                ],
            };
        };

        ctrl.getAll = (pagination) => TariffFactory.getAll({
            setting_group: ctrl.settingGroup,
            ...pagination.toWithPaginatorArguments(),
        });

        ctrl.new = (tariff) => {
            $mdDialog.show({
                template: template2,
                controllerAs: 'ctrl',
                controller: function() {
                    const dctrl = this;

                    dctrl.$onInit = () => {
                        dctrl.tariff = { ...tariff };
                        dctrl.types = [
                            {
                                label: t('HOUR'),
                                value: 'hourly',
                            },
                            {
                                label: t('MONTH'),
                                value: 'monthly',
                            },
                            {
                                label: t('EXTRA'),
                                value: 'extra',
                            },
                        ];

                        dctrl.cancel = $mdDialog.cancel;
                    };

                    dctrl.create = () => {
                        $mdDialog.hide();

                        TariffFactory.create({
                            setting_group: ctrl.settingGroup,
                            name: dctrl.tariff.name,
                            type: dctrl.tariff.type,
                        }).$promise.then(() => {
                            ToastService.toast(t('CREATED'));
                            ctrl.reloadTable = true;
                        });
                    };
                },
            });
        };

        ctrl.update = (tariff) => {
            const tar = { ...tariff };

            $mdDialog.show(
                $mdDialog.prompt()
                    .title(t('EDIT'))
                    .placeholder(tar.name)
                    .initialValue(tar.name)
                    .required(true)
                    .ok(t('UPDATE'))
                    .cancel(t('CANCEL')),
            ).then((newName) => {
                tar.name = newName;

                TariffFactory.update({
                    setting_group: ctrl.settingGroup,
                    tariff: tar,
                }).$promise.then(() => {
                    ToastService.toast(t('UPDATED'));
                    ctrl.reloadTable = true;
                });
            });
        };

        ctrl.delete = (tariff) => {
            deleteItemAlert.alertSimple().then(() => {
                TariffFactory.delete(tariff).$promise.then(() => {
                    ToastService.toast(t('DELETED'));
                    ctrl.reloadTable = true;
                });
            });
        };
    } ],
});
