// @ts-nocheck
import { module } from 'angular';
import { SignatureProviderType } from '../../../enums/SignatureProviderType';

module('eaw.hr.sign').factory('fileSignatureFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const res = EawResource.create('/customers/:customerId/employees/:employeeId/hr_files/:fileId/signatures/:signatureId');
    const res2 = EawResource.create('/customers/:customerId/employees/:employeeId/hr_files/:fileId/signatures');
    return {
        getAll: {
            permission(customer, employee, file) {
                return `customers.${customer}.employees.${employee}.hr_files.${file}.signatures.*.get`;
            },
            query(customerId, employeeId, fileId, pagination) {
                return res.get({
                    customerId,
                    employeeId,
                    fileId,
                    ...Pagination.getParams(pagination),
                });
            },
        },
        get: {
            permission(customer, employee, file, signature) {
                return `customers.${customer}.employees.${employee}.hr_files.${file}.signatures.${signature}.get`;
            },
            query(customerId, employeeId, fileId, signatureId) {
                return res.get({
                    customerId,
                    employeeId,
                    fileId,
                    signatureId,
                });
            },
        },
        create: {
            permission(customer, employee, file) {
                return `customers.${customer}.employees.${employee}.hr_files.${file}.signatures.create`;
            },
            checkbox: {
                query(customerId, employeeId, fileId) {
                    return res.save({
                        customerId,
                        employeeId,
                        fileId,
                    }, {
                        sign: true,
                        provider: SignatureProviderType.Checkbox,
                    });
                },
            },
            documentService: {
                query(customerId, employeeId, fileId) {
                    return res2.save({
                        customerId,
                        employeeId,
                        fileId,
                    }, {
                        sign: 1,
                        provider: SignatureProviderType.DocumentService,
                    });
                },
            },
        },
    };
} ]);
