// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../current.factory';

module('eaw.misc').filter('eawCurrency', function eawCurrency() {
    return function(number, decimals = 2, sign) {
        if (!Number.isFinite(number)) {
            return t('NA');
        }

        // Default to undefined, because null will fuck it up
        const currency = CurrentOld.customer.currency || undefined;

        return new Intl.NumberFormat(CurrentOld.languageTag, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
            // @ts-ignore
            signDisplay: sign ? 'exceptZero' : 'auto',
            style: currency ? 'currency' : undefined,
            currency,
            currencyDisplay: 'symbol',
        }).format(number);
    };
});
