import { module } from 'angular';
import { EawResourceFactory } from '../../shared/angularjs/modules/resource/resource.service';
import { PaginationOld } from '../../shared/utils/pagination-old';

module('eaw.todo').factory('TodoStatusFactory', [ 'EawResource', function(EawResource: EawResourceFactory) {
    // @ts-ignore
    const factory = this;

    /**
     * Get all statuses a customer has
     * @param   {object}  args
     * @param   {int}     args.customerId
     * @returns {$promise}
     */
    factory.getAll = (args: any) => {
      return EawResource.create('/customers/:customer_id/todo_statuses/:status_id').get({
            customer_id: args.customerId,
            ...PaginationOld.getParams(args),
        });
    };

    return factory;
} ]);
