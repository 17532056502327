import { Component, Inject, Input, OnInit } from '@angular/core';
import { BasicDataSource } from '../../../../shared/utils/basic-data-source';
import { Shift } from '../../../models/shift';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DateTime } from 'luxon';
import { map, Observable } from 'rxjs';
import { ShiftService } from '../../../http/shift.service';
import { ShiftSwap } from '../../../models/shift-swap';
import { DurationPipe } from '../../../../shared/pipes/duration.pipe';
import { DateTimePipe } from '../../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';

interface EmployeeShiftsDataSourceItem {
    shift: Shift,
    processing: boolean,
    canCreateSwap: Observable<boolean>,
    canWithdrawSwap: Observable<boolean>,
}

@Component({
    selector: 'eaw-employee-shifts-list',
    templateUrl: './employee-shifts-list.component.html',
    styleUrl: './employee-shifts-list.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinnerModule,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        DurationPipe,
    ],
})
export class EmployeeShiftsListComponent implements OnInit {
    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) employeeId!: number;
    @Input({ required: true }) requestSwap!: (customerId: number, shiftId: number) => Observable<ShiftSwap | undefined>;
    @Input({ required: true }) withdrawSwap!: (customerId: number, swapId: number, employeeId?: number) => Observable<undefined>;
    @Input({ required: true }) canCreateSwap!: (shift: Shift) => Observable<boolean>;
    @Input({ required: true }) canWithdrawSwap!: (shift: Shift) => Observable<boolean>;
    @Input({ required: true }) showComments!: (shift: Shift) => void;

    dataSource?: BasicDataSource<EmployeeShiftsDataSourceItem>;
    useSmallScroll = false;

    constructor(
        @Inject(BreakpointObserver) private breakpointObserver: BreakpointObserver,
        @Inject(ShiftService) private shiftService: ShiftService,
    ) {
    }

    ngOnInit(): void {
        this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
        ]).subscribe((res) => {
            this.useSmallScroll = res.matches;
        });

        this.createDataSource();
    }

    createDataSource() {
        const perPage = 10;
        const options = {
            from: DateTime.now().startOf('day'),
            order_by: 'from',
            direction: 'asc' as const,
            per_page: perPage,
            'with[]': [ 'unprocessedSwaps', 'schedule', 'comments', 'periods.businessUnit' ],
        };

        this.shiftService.getAllForEmployee(this.customerId, this.employeeId, options).subscribe((res) => {
            this.dataSource = new BasicDataSource(res.total, perPage, this.toDataSourceItem(res.data), (page) => {
                return this.shiftService.getAllForEmployee(this.customerId, this.employeeId, {
                    ...options,
                    page,
                }).pipe(
                    map((res) => this.toDataSourceItem(res.data)),
                );
            });
        });
    }

    toDataSourceItem(shifts: Shift[]): EmployeeShiftsDataSourceItem[] {
        return shifts.map((s) => {
            return {
                shift: s,
                processing: false,
                canCreateSwap: this.canCreateSwap(s),
                canWithdrawSwap: this.canWithdrawSwap(s),
            };
        });
    }

    withdraw(dataSourceItem: EmployeeShiftsDataSourceItem) {
        const swap = dataSourceItem.shift.unprocessedSwaps?.find((s) => !s.approved);
        if (!swap) {
            return;
        }

        dataSourceItem.processing = true;
        this.withdrawSwap(swap.customerId, swap.id, this.employeeId).subscribe(() => {
            this.dataSource?.refresh().subscribe(() => {
                dataSourceItem.processing = false;
            });
        });
    }

    swap(dataSourceItem: EmployeeShiftsDataSourceItem) {
        const customerId = dataSourceItem.shift.schedule?.customerId;
        if (!customerId) {
            return;
        }

        this.requestSwap(customerId, dataSourceItem.shift.id).subscribe((swap) => {
            if (swap) {
                dataSourceItem.processing = true;
                this.dataSource?.refresh().subscribe(() => {
                    dataSourceItem.processing = false;
                });
            }
        });
    }
}
