// @ts-nocheck
import { pick } from 'lodash-es';
import { isObject } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { Model } from '../../shared/angularjs/model';
import { TinyColor } from '@ctrl/tinycolor';
module('eaw.scheduling.shifts').factory('ShiftPeriod', [ 'colorpickerService', 'shiftEvents', 'EawResource', function ShiftPeriodClass(colorpickerService, shiftEvents, EawResource) {
    const url = '/customers/:customer/schedules/:schedule/shifts/:shift/periods/:period';
    class ShiftPeriod extends Model {
        backgroundColor;
        textColor;
        color;
        business_unit_id;
        name;
        business_unit;
        shift;
        shift_id;
        offset;
        length;
        from;
        to;
        qualifications;
        constructor(data) {
            super(data);
            // Handle colors of periods in case they have hex values
            this.backgroundColor = colorpickerService.getHex(this.color);
            this.textColor = new TinyColor(this.backgroundColor).isDark() ? 'white' : 'black';
            this.setOriginal(this);
        }

        _get(obj, prop) {
            if (!Object.prototype.hasOwnProperty.call(obj, prop) && obj.shift) {
                switch (prop) {
                    case 'business_unit_id':
                        if (obj.business_unit) {
                            return obj.business_unit.id;
                        } else if (!this.isModified('business_unit')) {
                            return undefined;
                        }
                        break;
                    case 'from':
                        if (obj.offset <= 0) {
                            // @ts-ignore
                            this._set('from', obj.shift.from.copy().add(obj.offset, 's'));
                        }
                        break;
                    case 'to': {
                        const from = this._get(obj, 'from');
                        if (obj.length >= 0 && moment.isMoment(from)) {
                            this._set(obj, 'to', from.clone().add(obj.length, 's'));
                        }
                        break;
                    }
                }
            }
            return super._get(obj, prop);
        }

        _set(obj, prop, value) {
            switch (prop) {
                case 'qualifications':
                    if (isObject(obj?.qualifications?.[0])) {
                        // @ts-ignore
                        return super._set(value.map((q) => q.id));
                    }
                    break;
                case 'business_unit':
                    super._set(obj, 'business_unit_id', value?.id ?? null);
                    if (value) {
                        super._set(obj, 'color', value.color);
                    }
                    break;
                case 'shift':
                    super._set(obj, 'shift_id', value?.id ?? null);
                    break;
                case 'shift_id':
                    if (obj.shift && obj.shift.id != value) {
                        super._set(obj, 'shift', null);
                    }
                    break;
                case 'unproductive':
                case 'break':
                    // These should always be a bool
                    return super._set(obj, prop, !!value);
                // Stole from and to from Shift.
                // TODO: Use a mixin to set both of these to avoid duplicate code.
                case 'from':
                    if (value == null) {
                        super._set(obj, 'offset', undefined);
                        super._set(obj, 'length', undefined);
                    } else if (moment.isMoment(value)) {
                        if (obj.shift) {
                            super._set(obj, 'offset', value.diff(obj.shift.from, 's'));
                        }
                        if (obj.to) {
                            super._set(obj, 'length', obj.to.diff(value, 's'));
                        }
                    }
                    break;
                case 'to':
                    if (value == null) {
                        super._set(obj, 'length', undefined);
                    } else if (moment.isMoment(value)) {
                        if (obj.from) {
                            super._set(obj, 'length', obj.from.diff(value, 's'));
                        }
                    }
                    break;
            }
            return super._set(obj, prop, value);
        }

        save(customerId = null, scheduleId = null) {
            const w = [ 'properties' ];
            if (this.business_unit_id) {
                delete this.color;
                delete this.name;
                delete this.business_unit;
                w.push('businessUnit');
            }
            this.offset = this.from.diff(this.shift.from, 's');
            this.length = this.to.diff(this.from, 's');
            const period = this.getModified();
            period.with = w;
            shiftEvents.trigger.updating(this.shift);
            return EawResource.create(url)[this.id ? 'update' : 'save']({
                customer: this.shift?.schedule?.customer_id ?? customerId,
                schedule: this.shift.schedule_id ?? scheduleId,
                shift: this.shift_id,
                period: this.id,
            }, {
                ...pick(period, [ 'length', 'offset', 'unproductive', 'color', 'business_unit_id', 'break', 'description', 'with' ]),
                qualifications: this.qualifications.map((q) => q.id),
            });
        }

        static override getMorphClass() {
            return 'shift_period';
        }
    }
    return ShiftPeriod;
} ]);
