import { Component, Inject, Input } from '@angular/core';
import { CurrentService } from '../../../../shared/services/current.service';
import { DateTime } from 'luxon';
import { Employee, EmployeeGender } from '../../../../shared/models/employee';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import type { MeCustomer } from '../../../../shared/models/me';
import { catchError, EMPTY, Observable, of, switchMap, tap } from 'rxjs';
import { Country } from '../../../../shared/models/country';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { EmployeeService } from '../../../../shared/http/employee.service';
import { CountryRegion } from '../../../../shared/models/country-region';
import { CountryAutocompleteService } from '../../../../shared/autocompletes/country-autocomplete.service';
import { TranslateService } from '../../../../shared/services/translate.service';
import { ObjectDifferenceExtractor } from '../../../../shared/utils/object-difference-extractor';
import { CountryRegionAutocompleteService } from '../../../../shared/autocompletes/country-region-autocomplete.service';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { AutocompleteComponent } from '../../../../shared/components/autocomplete/autocomplete.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, AsyncPipe } from '@angular/common';
import { TranslateSyncPipe } from '../../../../shared/pipes/translate-sync.pipe';

@Component({
    selector: 'eaw-profile-info-employee',
    templateUrl: './profile-info-employee.component.html',
    styleUrl: './profile-info-employee.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        AutocompleteComponent,
        MatIconModule,
        MatSelectModule,
        MatOptionModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class ProfileInfoEmployeeComponent {
    @Input({ required: true }) customer!: MeCustomer;

    employee?: Employee;
    message?: Promise<string>;
    loading = true;
    form = new FormGroup({
        firstName: new FormControl<string | null>(null),
        lastName: new FormControl<string | null>(null),
        address1: new FormControl<string | null>(null),
        address2: new FormControl<string | null>(null),
        postalCode: new FormControl<string | null>(null),
        city: new FormControl<string | null>(null),
        countryKey: new FormControl<Country | string | null>(null),
        region: new FormControl<CountryRegion | number | null>(null),
        nationality: new FormControl<Country | string | null>(null),
        birthDate: new FormControl<DateTime | null>(null),
        gender: new FormControl<EmployeeGender | ''>('', { nonNullable: true }),
    });

    diffExtractor: ObjectDifferenceExtractor = new ObjectDifferenceExtractor();
    regionTrigger: Observable<unknown> = EMPTY;

    constructor(
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(CurrentService) private currentService: CurrentService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(CountryAutocompleteService) protected countryAutocompleteService: CountryAutocompleteService,
        @Inject(CountryRegionAutocompleteService) protected countryRegionAutocompleteService: CountryRegionAutocompleteService,
        @Inject(TranslateService) private translate: TranslateService,
    ) {
    }

    getData() {
        this.loading = true;

        this.employeeService.getAll(this.customer.id, {
            user_id: this.currentService.getUser().id,
            active: DateTime.now(),
            include_custom_fields: false,
            include_external: true,
        }).pipe(catchError(() => EMPTY)).subscribe((res) => {
            switch (true) {
                case res.data.length === 0: {
                    this.message = Promise.resolve(this.translate.t('NO_EMPLOYEE_FOUND_AT_THIS_LOCATION', 'profile'));
                    break;
                }
                case res.data.length > 1: {
                    this.message = Promise.resolve(this.translate.t('FOUND_EMPLOYEES_AT_THIS_LOCATION ', 'profile', { count: res.data.length }));
                    break;
                }
                default: {
                    this.employee = res.data[0];
                    this.setFormValue();
                }
            }

            this.loading = false;
            this.form.enable();
        });
    }

    getDiffMappings() {
        return {
            first_name: 'firstName',
            last_name: 'lastName',
            postal_code: 'postalCode',
            birth_date: 'birthDate',
            country_key: 'countryKey',
            region_id: 'region',
        };
    }

    setRegionTrigger() {

        this.regionTrigger = this.form.controls.countryKey.valueChanges.pipe(switchMap((country) => {
            const region = this.form.controls.region.value;

            if (region instanceof CountryRegion && (region.countryCode === country || country instanceof Country && region.countryCode === country.code)) {
                return EMPTY;
            }

            return of(country);
        }));
    }

    setFormValue() {
        if (!this.employee) {
            return;
        }

        this.form.setValue({
            firstName: this.employee.firstName || null,
            lastName: this.employee.lastName || null,
            address1: this.employee.address1 || null,
            address2: this.employee.address2 || null,
            postalCode: this.employee.postalCode || null,
            city: this.employee.city || null,
            countryKey: this.employee.countryKey || null,
            region: this.employee.regionId || null,
            nationality: this.employee.nationality || null,
            birthDate: this.employee.birthDate?.dateTime || null,
            gender: this.employee.gender || '',
        });

        this.setRegionTrigger();

        this.diffExtractor = new ObjectDifferenceExtractor();
        this.diffExtractor.setOriginal({
            ...this.form.value,
            region: this.form.controls.region?.value || null,
        });
    }

    getUpdatedNationality(): string | null {
        return this.form.value.nationality instanceof Country ? this.form.value.nationality.code : (this.form.value.nationality || null);
    }

    getUpdatedCountryKey(): string | null {
        return this.form.value.countryKey instanceof Country ? this.form.value.countryKey.code : (this.form.value.countryKey || null);
    }

    getUpdatedRegionId(): number | null {
        const countryKey = this.getUpdatedCountryKey();
        const region = this.form.value.region;

        return region instanceof CountryRegion ?
            region.countryCode?.toLowerCase() === countryKey?.toLowerCase() ?
                (region?.id || null)
                : null
            : region ? region : null;
    }

    getFormValue() {
        const value = this.form.value;

        return {
            first_name: value.firstName,
            last_name: value.lastName,
            address1: value.address1,
            address2: value.address2,
            postal_code: value.postalCode,
            city: value.city,
            birth_date: value.birthDate,
            gender: value.gender,
            nationality: this.getUpdatedNationality(),
            country_key: this.getUpdatedCountryKey(),
            region_id: this.getUpdatedRegionId(),
        };
    }

    update() {
        const values = this.getFormValue();

        const updates = this.diffExtractor.getChanged(values, this.getDiffMappings());

        if (!this.employee?.id || !this.diffExtractor?.hasChanges(values, this.getDiffMappings())) {
            return;
        }

        this.form.disable();
        this.employeeService.update(this.employee.customerId, this.employee.id, {
            first_name: updates.first_name,
            last_name: updates.last_name,
            address1: updates.address1,
            address2: updates.address2,
            postal_code: updates.postal_code,
            city: updates.city,
            country_key: updates.country_key,
            region_id: updates.region_id,
            nationality: updates.nationality,
            birth_date: updates.birth_date,
            gender: updates.gender || undefined,
        }).pipe(
            catchError(() => {
                this.form.enable();
                return EMPTY;
            }),
            tap(() => {
                void this.snackBarService.t('EMP_UPDATED', 'profile', { location: this.customer.name });
                this.getData();
            }),
        ).subscribe();
    }
}
