<svg [class.unknown]="!internalUser()" [attr.viewBox]="'0 0 ' + viewBox() + ' ' + viewBox()" xmlns="http://www.w3.org/2000/svg">
    @if (url()) {
        <image [attr.href]="url()" [attr.height]="viewBox()" [attr.width]="viewBox()"></image>
    } @else {
        <rect [attr.width]="viewBox()" [attr.height]="viewBox()" [attr.fill]="color()"/>
        <text class="letters" x="50%" y="50%" text-anchor="middle" [attr.fill]="textColor()" dominant-baseline="central" alignment-baseline="central">
            {{ letters() }}
        </text>
    }
</svg>

@if (!internalUser()) {
    <mat-icon [style.font-size]="internalSize() + 'px'">person</mat-icon>
}
