import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface FlexitimeOverviewResponse extends ApiResponse {
    id: number;
    customer_id: number;
    user_id: number;
    name: string;
    from: string;
    to: string | null;
    created_at: string;
    updated_at: string;
    flexitime_balance: number;
}

export class FlexitimeOverview {
    customerId: number;
    userId: number;
    id: number;
    from: DateTime | null;
    to: DateTime | null;
    name: string;
    balance: number;
    createdAt?: DateTime | null;
    updatedAt?: DateTime | null;

    constructor(data: FlexitimeOverviewResponse) {
        this.customerId = data.customer_id;
        this.id = data.id;
        this.userId = data.user_id;
        this.from = data.from ? stringToDateTime(data.from) : null;
        this.to = data.to ? stringToDateTime(data.to) : null;
        this.name = data.name;
        this.balance = data.flexitime_balance;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
    }

    get isActive() {
        if (!this.from) {
            return false;
        }

        const now = DateTime.now();
        const to = this.to || now.plus({ year: 1 });
        return now >= this.from && now <= to;
    }
}
