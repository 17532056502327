import { Transition } from '@uirouter/angularjs';
import { Resolvable } from '../types/resolvable';

/**
 * Resolve a single param from the state, by default binds the token name to be the same as the
 * param name, but can be overwritten.
 * @param param - Name of the transition param
 * @param token - Optional rename of token to pass along to @Input or similar
 * @constructor
 */
export function paramResolver<T = any>(param: string, token?: string, parser?: (value: unknown) => T): Resolvable {
    return {
        token: token || param,
        deps: [ '$transition$' ],
        resolveFn: function($transition$: Transition) {
            const value = $transition$.params()[param];
            return parser ? parser(value) : value;
        },
    };
}
