import { inject, Injectable, Injector } from '@angular/core';
import { forkJoin, map, mergeMap, Observable, of, reduce } from 'rxjs';
import { PermissionCheckService, PermissionOptions } from '../../shared/services/permission-check.service';
import { StateProviderDataDataPermissionCheck } from '../../shared/utils/create-state';

export type PermissionsInputValueTuple = [ string, PermissionOptions ];
export type PermissionsInputValue = (PermissionsInputValueTuple | string)[];
export type PermissionChildrenFilterOutInput = [ string, string, string ];
export type PermissionChildrenFilterInInput = [ string, string ];

@Injectable({
    providedIn: 'root',
})
export class ElementPermissionService {
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly injector = inject(Injector);

    /**
     * Checks if the element should be shown or not.
     * Priority is as follows:
     * 1. If we get a match for the filter, we hide the element, even if we have a match for somePermissions/permissions or include.
     * 2. If we get a match for somePermissions/permissions, we show the element.
     * 3. If we get a match for include, we show the element, even if we don't have a match for somePermissions/permissions.
     */
    shouldShow(permissions?: PermissionsInputValue, somePermissions?: PermissionsInputValue, filter?: PermissionChildrenFilterOutInput, include?: PermissionChildrenFilterInInput, permissionCheck?: StateProviderDataDataPermissionCheck): Observable<boolean> {
        return forkJoin([
            permissionCheck?.(this.permissionCheckService, this.injector) || of(true),
            filter ? this.permissionCheckService.permissionChildrenSingle(filter[0], filter[1], false).pipe(
                map((children) => {
                    return !children.find((child) => child == filter[2]);
                }),
            ) : of(true),
            of(...(permissions || [])).pipe(
                mergeMap((val) => {
                    const [ node, options ] = Array.isArray(val) ? val : [ val ];

                    return this.permissionCheckService.isAllowed(node, options);
                }),
                reduce((acc, val) => acc && val, true),
            ),
            somePermissions?.length ? of(...(somePermissions || [])).pipe(
                mergeMap((val) => {
                    const [ node, options ] = Array.isArray(val) ? val : [ val ];

                    return this.permissionCheckService.isAllowed(node, options);
                }),
                reduce((acc, val) => acc || val, false),
            ) : of(true),
            include ? this.permissionCheckService.permissionChildrenSingle(include[0], include[1], true).pipe(map((children) => !!children.length)) : of(false),
        ]).pipe(
            map(([ permissionCheck, excludeChildren, hasAllPermissions, hasSomePermissions, includeChildren ]) => {
                return (excludeChildren && (hasAllPermissions && hasSomePermissions || includeChildren)) && permissionCheck;
            }),
        );
    }

    insertPlaceholderNode(element: HTMLElement, permissions?: PermissionsInputValue, somePermissions?: PermissionsInputValue, filter?: PermissionChildrenFilterOutInput, include?: PermissionChildrenFilterInInput): HTMLDivElement {
        const node = document.createElement('div');
        node.style.display = 'none';
        node.tabIndex = -1;
        node.dataset['allowed'] = 'false';

        this.setOrRemoveDataOnElement(node, permissions, somePermissions, filter, include);
        element.replaceWith(node);

        return node;
    }

    /**
     * @returns if the data was added or removed
     */
    setOrRemoveDataOnElement(element: HTMLElement, permissions?: PermissionsInputValue, somePermissions?: PermissionsInputValue, filter?: PermissionChildrenFilterOutInput, include?: PermissionChildrenFilterInInput): boolean {
        element.dataset['eawPermission'] = '';

        if (!permissions?.length && !filter && !somePermissions?.length && !include) {
            element.removeAttribute('data-permissions');
            element.removeAttribute('data-permissionChildrenExclude');
            element.removeAttribute('data-somePermissions');
            element.removeAttribute('data-permissionChildrenInclude');

            return false;
        }

        if (permissions) {
            element.dataset['permissions'] = JSON.stringify(permissions);
        } else {
            element.removeAttribute('data-permissions');
        }

        if (filter) {
            element.dataset['permissionChildrenExclude'] = JSON.stringify(filter);
        } else {
            element.removeAttribute('data-permissionChildrenExclude');
        }

        if (include) {
            element.dataset['permissionChildrenInclude'] = JSON.stringify(filter);
        } else {
            element.removeAttribute('data-permissionChildrenInclude');
        }

        if (somePermissions) {
            element.dataset['somePermissions'] = JSON.stringify(somePermissions);
        } else {
            element.removeAttribute('data-somePermissions');
        }

        return true;
    }

    getPermissionFilterFromState(customerId: number, state: string): PermissionChildrenFilterOutInput {
        const stateSegments = state.replace('eaw/app/', '').split('/');

        return [
            `ui.customers.${customerId}.${(stateSegments.slice(0, stateSegments.length - 1).join('_'))}`,
            'visible',
            stateSegments[stateSegments.length - 1] || '',
        ];
    }
}
