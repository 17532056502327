import { ApiResponse } from '../../shared/interfaces/api-response';

export type EmployeeStat = {
    employee_id?: number,
    week_number?: number,
    week_year?: number,
    hours_planned_at_location?: number,
    hours_planned_outside_location?: number,
    hours_per_week_from_contract?: number
    hours_difference_with_contract?: number,
    accumulated_hours_planned_at_location?: number
    accumulated_hours_planned_outside_location?: number,
    accumulated_hours_per_week_from_contract?: number,
    accumulated_hours_difference_with_contract?: number,
    worked_last_sunday?: boolean,
    average_days_worked?: number
}
export type WeekStatistic = {
    year: number,
    week_number: number,
    total_week_hours: number,
    total_accumulated: number,
    difference_total: number,
    employees_stats: EmployeeStat[]
}

export interface ScheduleStatisticsResponse extends ApiResponse {
    weekly_statistics: WeekStatistic[];
}

export class ScheduleStatistics {
    weeklyStatistics: WeekStatistic[];

    constructor(statistics: ScheduleStatisticsResponse) {
        this.weeklyStatistics = statistics.weekly_statistics;
    }
}

