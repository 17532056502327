import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MeCustomer } from '../../../../../shared/models/me';
import { catchError, of } from 'rxjs';
import { UserPropertyService } from '../../../../../shared/http/user-property.service';
import { CurrentService } from '../../../../../shared/services/current.service';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { Property } from '../../../../../shared/models/property';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { BasicAutocompleteComponent } from '../../../../../shared/components/basic-autocomplete/basic-autocomplete.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'eaw-default-customer-setting',
    templateUrl: './default-customer-setting.component.html',
    styleUrl: './default-customer-setting.component.scss',
    standalone: true,
    imports: [
        MatExpansionModule,
        NgIf,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        BasicAutocompleteComponent,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class DefaultCustomerSettingComponent implements OnInit {
    customerName?: string;
    updating = true;
    form = new FormGroup({
        defaultLocation: new FormControl<MeCustomer | null>(null),
    });

    constructor(
        @Inject(UserPropertyService) private userPropertyService: UserPropertyService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
    ) {
    }

    ngOnInit(): void {
        this.userPropertyService.get(this.current.getUser().id, 'default_customer').pipe(
            catchError(() => {
                return of(null);
            }),
        ).subscribe((property) => {
            this.setValue(property);
        });
    }

    setValue(property: Property | null) {
        this.updating = false;

        const customer = this.current.getCustomer(property?.value.asInteger() || 0);
        this.customerName = customer?.name;

        if (customer) {
            this.form.controls.defaultLocation.setValue(customer);
        } else {
            this.form.controls.defaultLocation.setValue(null);
        }
    }

    getAvailableLocations = (filter?: string) => {
        const locations = Object.values(this.current.getMe().customers).filter((c) => c.name.toLowerCase().includes(filter?.toLowerCase() || ''));

        return of({
            data: locations,
            per_page: 1,
            current_page: 1,
            last_page: 1,
            total: locations.length,
            from: 1,
            to: 1,
        });
    };

    updateDefaultCustomer() {
        const location = this.form.controls.defaultLocation.value?.id;
        if (!location) {
            return;
        }

        this.updating = true;
        this.userPropertyService.update(this.current.getUser().id, 'default_customer', String(location)).subscribe((property) => {
            void this.snackBarService.t('UPDATED');
            this.updating = false;
            this.setValue(property);
        });
    }
}
