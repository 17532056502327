// @ts-nocheck
import { debounce } from 'lodash-es';

import moment from 'moment-timezone';

import { module } from 'angular';

import { groupBy } from 'lodash-es';

const template1 = `<md-button class="md-icon-button" ng-click="$ctrl.updateIndex(-1)" ng-class="{'invisible': !$ctrl.index}">
    <md-icon ng-bind="'arrow_back'"></md-icon>
</md-button>

<div layout="column">
    <h5 ng-if="!$ctrl.hideWeek" ng-bind="$ctrl.week[0].weekYear"></h5>
    <h3 ng-if="!$ctrl.hideWeek" ng-i18next="[i18next]({number: $ctrl.week[0].weekNr})WEEK_N"></h3>
    <h3 ng-if="$ctrl.hideWeek" ng-i18next="[i18next]({number: $ctrl.index + 1})WEEK_N"></h3>
</div>

<md-button class="md-icon-button" ng-click="$ctrl.updateIndex(1)" ng-class="{'invisible': $ctrl.index == $ctrl.lastIndex}">
    <md-icon ng-bind="'arrow_forward'"></md-icon>
</md-button>
`;

module('eaw.scheduling').component('weekSelector', {
    template: template1,
    bindings: {
        days: '<',
        hideWeek: '<',
    },
    require: {
        ngModel: 'ngModel',
    },
    controller: [ '$rootScope', '$scope', 'scheduleTable', function($rootScope, $scope, scheduleTable) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.weeksObj = groupBy(ctrl.days, (d) => `${d.weekYear}-${d.weekNr}`);
            ctrl.weeksArr = Object.keys(ctrl.weeksObj);
            ctrl.lastIndex = ctrl.weeksArr.length - 1;
            ctrl.ngModel.$formatters.unshift(ctrl.formatter);
            ctrl.debouncedUpdate = debounce(() => ctrl.update(), 500);

            // Find the week we are currently on in the table
            ctrl.findWeek(scheduleTable.getCurrentDate());

            // Listen for changes
            $scope.$on('schedule:dayChanged', ctrl.dayChangedEvent);

            ctrl.updateIndex();
        };

        ctrl.dayChangedEvent = (event, date) => {
            if (moment.isMoment(date)) {
                if (date.week() !== ctrl.week?.[0]?.weekNr) {
                    ctrl.findWeek(date);
                    ctrl.broadcast();
                }
            }
        };

        /**
         * @param {moment} date
         */
        ctrl.findWeek = (date) => {
            const key = `${date.weekYear()}-${date.week()}`;
            let weekIndex = ctrl.weeksArr.indexOf(key);

            // If we didn't find it in the array
            if (weekIndex === -1) {
                weekIndex = 0; // Set to 0 as default as "before" schedule start

                // If date is after schedule end set to the last index
                if (date.isSameOrAfter(scheduleTable.getSchedule().getTo(), 'd')) {
                    weekIndex = ctrl.weeksArr.length - 1;
                }
            }

            // Set index to 0 to that we can add the new index
            ctrl.index = 0;

            // Add the new index
            ctrl.updateIndex(weekIndex);
        };

        ctrl.formatter = (val) => {
            if (val) {
                ctrl.day = val;
                ctrl.index = val.index;
            }

            return val;
        };

        ctrl.broadcast = () => {
            $rootScope.$broadcast('sidebarchildren:weekChanged', ctrl.week);
        };

        ctrl.updateIndex = (add = 0) => {
            // Add new value to index
            const index = (ctrl.index || 0) + add;

            if (add === -1) {
                ctrl.index = Math.max(0, index);
            } else {
                ctrl.index = Math.min(ctrl.weeksArr.length, index);
            }

            ctrl.week = ctrl.weeksObj[ctrl.weeksArr[ctrl.index]];

            ctrl.debouncedUpdate();
        };

        ctrl.update = () => {
            ctrl.ngModel.setViewValue(ctrl.week);
            ctrl.broadcast();
        };
    } ],
});
