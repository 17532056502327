<eaw-page-header [fabButton]="fabButton" [buttons]="pageButtons">
    <div title>{{'USER_plural' | translate:'company' | async}}</div>

    <form smallFilter [formGroup]="form" class="tw-flex tw-flex-row tw-justify-center tw-gap-8">
        <mat-form-field class="standalone">
            <mat-label>{{'FILTER' | translate | async}}</mat-label>
            <input matInput formControlName="filter" autocomplete="off">
        </mat-form-field>
    </form>
</eaw-page-header>

<mat-card class="mat-compact-card">
    <mat-card-content class="tw-flex tw-flex-col">
        <eaw-data-table [columns]="columns"
                        [request]="request"
                        [rowClasses]="rowClasses"
                        [goTo]="{state: 'eaw/app/company/users/view', params: [{stateKey: 'id', itemKey: 'userId'}, {stateKey: 'when', itemKey: 'from'}]}"
                        initialSortBy="from"
                        initialSortDirection="desc"
                        (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
