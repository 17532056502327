import { Inject, Injectable } from '@angular/core';
import { CustomField, CustomFieldResponse } from '../models/custom-field';
import { HttpClient } from '@angular/common/http';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { CustomFieldType } from '../typings/custom-field-type';

interface GetAllOptions extends PaginationOptions {
    'types[]'?: string[];
}

export interface CreateUpdateCustomField {
    key: string;
    name: string;
    type: CustomFieldType;
}

@Injectable({
    providedIn: 'root',
})
export class CustomFieldsService {

    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {
    }

    /**
     * Gets all custom fields in the database
     */
    getAll(options?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<CustomFieldResponse>>(`/custom_fields`, {
            params: formatHttpParams(options),
        }).pipe(
            classifyArrayPaginatedResponse(CustomField),
        );
    }

    removeCustomField(fieldId: number) {
        return this.http.delete<null>(`/custom_fields/${fieldId}`);
    }

    updateCustomField(fieldId: number, body: CreateUpdateCustomField) {
        return this.http.put<CustomFieldResponse>(`/custom_fields/${fieldId}`, body)
            .pipe(classifyItem(CustomField));
    }

    addCustomField(body: CreateUpdateCustomField) {
        return this.http.post<CustomFieldResponse>(`/custom_fields`, body)
            .pipe(classifyItem(CustomField));
    }
}
