// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<div layout="column" layout-padding ng-if="$periods.gettingShifts">
    <md-progress-circular class="md-progress-center"></md-progress-circular>
</div>

<period-list ng-show="!$periods.gettingShifts" periods="$periods.periods" display-name="true"></period-list>
`;

module('eaw.scheduling').component('sidebarPeriods', {
    template: template1,
    controllerAs: '$periods',
    require: {
        sidebar: '^scheduleSidebar',
    },
    controller: [ '$scope', 'shiftEvents', function($scope, shiftEvents) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.changeDay(ctrl.sidebar.day);

            $scope.$on('sidebarchildren:dayChanged', ctrl.changeDay);
            shiftEvents.register.onChange($scope, ctrl.changeDay);
        };

        ctrl.changeDay = () => {
            const shifts = ctrl.sidebar.schedule.getShiftsByDate(ctrl.sidebar.day.moment);

            ctrl.periods = shifts.map((s) => s.periods.map((p) => {
                return {
                    ...p,
                    employee_name: s.employee?.name,
                };
            })).flat();
        };
    } ],
});
