<h1 mat-dialog-title>{{'UPLOAD_PAYSLIP_FILE' | translate: 'payslip_import'| async}}</h1>

<mat-dialog-content>
    <div class="tw-flex tw-flex-row tw-items-center tw-justify-center">
        <mat-progress-spinner *ngIf="processing" mode="indeterminate" diameter="40"
                              strokeWidth="4"></mat-progress-spinner>
        <form [formGroup]="formGroup" class="tw-flex tw-flex-col tw-w-full" *ngIf="!processing">
            <mat-form-field *ngIf="!processing && !error">
                <eaw-file-selector [placeholder]="('CHOOSE_FILE' | translate | async) || ''" formControlName="file"
                                   [fileFormats]="formats"></eaw-file-selector>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'CHOOSE_MONTH' | translate| async}}</mat-label>
                <input matInput [matDatepicker]="datepicker" formControlName="month">
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker
                                startView="multi-year"
                                (monthSelected)="setDate($event, $any(datepicker))"
                                panelClass="payslip-import-month-picker">
                </mat-datepicker>
            </mat-form-field>

            <mat-checkbox eawCheckboxHelper formControlName="replace" class="tw-my-12">
                {{'REPLACE_OLD_FILES' | translate: 'payslip_import' | async }}
            </mat-checkbox>
        </form>
    </div>
    <span *ngIf="error" class="error">
        {{ 'NO_FILE_TYPE_FOUND' | translate: 'payslip_import' | async }}
    </span>

    <!-- Dry run results -->
    <div class="results" *ngIf="!processing && result">
        <div *ngIf="result.foundEmployees.size">
            <h3>
                {{ 'MATCHING_EMPLOYEE' | translate: 'payslip_import': {count: result.foundEmployees.size} | async}}
            </h3>
            <mat-list>
                <mat-list-item *ngFor="let employee of result.foundEmployees! | keyvalue">
                    #{{ employee.key }} {{ employee.value.name }}
                </mat-list-item>
            </mat-list>
        </div>

        <div *ngIf="result.missingStatus.size">
            <h3>
                {{ 'MISMATCHED_EMPLOYEE' | translate: 'payslip_import': {count: result.missingStatus.size} | async }}
            </h3>
            <mat-list>
                <mat-list-item *ngFor="let status of result.missingStatus | keyvalue">
                    <div matListItemTitle>
                        #{{ status.key }}
                        <span *ngIf="status.value.info">
                        {{ status.value.info }}
                        </span>
                    </div>
                    <span matListItemLine>
                        {{ status.value.i18n.key | translate: status.value.i18n.ns | async }}
                    </span>
                </mat-list-item>
            </mat-list>
        </div>

        <eaw-info-box color="red" icon="warning">
            <p infoText>
                {{ 'MISMATCHED_EMPLOYEE_DESCRIPTION' | translate: 'payslip_import' | async }}
            </p>
        </eaw-info-box>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close="undefined">{{'CANCEL' | translate | async}}</button>
    <button mat-raised-button color="primary" [disabled]="processing || !hrFileTypeId || formGroup.invalid" (click)="submitFile(true)">
        {{'DRY_RUN' | translate: 'payslip_import'| async}}
    </button>
    <button mat-raised-button color="accent" [disabled]="processing || !hrFileTypeId || formGroup.invalid" (click)="submitFile()">
        {{'UPLOAD' | translate: 'hr'| async}}
    </button>
</mat-dialog-actions>
