<mat-card>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="getOverview()">
            <eaw-autocomplete formControlName="employee"
                              [options]="employeeAutocompleteService.options"
                              [getter]="employeeAutocompleteService.getter({
                                  customerId: customerId,
                                  from: form.controls.range.controls.from.value,
                                  to: form.controls.range.controls.to.value
                              })"
                              [setter]="employeeAutocompleteService.setter({customerId: customerId})">
            </eaw-autocomplete>

            <mat-form-field eawDatePickerOptions class="tw-w-full">
                <mat-label>{{'ENTER_DATE_RANGE' | translate | async}}</mat-label>
                <mat-date-range-input formGroupName="range" [rangePicker]="picker">
                    <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async" autocomplete="off">
                    <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async" autocomplete="off">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <div class="buttons-container">
                <button mat-raised-button color="accent" [disabled]="form.invalid" (click)="getOverview()">
                    {{'SUBMIT' | translate | async}}
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>

<mat-card-content class="spinner" *ngIf="getting">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-card-content>

<mat-card *ngIf="noStatsFound">
    <mat-card-content>
        <span>{{noStatsFound}}</span>
    </mat-card-content>
</mat-card>

<div class="absence-stat-container" *ngIf="resp && (!noStatsFound || !getting)">
    <mat-card *ngFor="let stats of resp.statistics | keyvalue; index as i;">
        <eaw-absence-stat-card
                [stats]="stats.value"
                [absenceType]="resp.absence_types[i]!">
        </eaw-absence-stat-card>
    </mat-card>
</div>
