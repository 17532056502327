import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { Comment } from '../../../shared/models/comment';
import { TranslateService } from '../../../shared/services/translate.service';
import { ShiftSwap } from '../../models/shift-swap';
import { DateTime } from 'luxon';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { CommentListComponent } from '../../../shared/components/comment-list/comment-list.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface ApplyToSwapData extends DialogData {
    swap: ShiftSwap;
}

@Component({
    selector: 'eaw-shift-swap-comments',
    templateUrl: './apply-for-swap-dialog.component.html',
    styleUrls: [
        './apply-for-swap-dialog.component.scss',
    ],
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        CommentListComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ApplyForSwapDialogComponent extends DialogComponent<ApplyToSwapData> implements OnInit {
    shiftComments: Comment[];
    shiftTranslation!: string;
    date: string | undefined;
    location: string | undefined;

    private swap: ShiftSwap;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: ApplyToSwapData,
        @Inject(MatDialogRef) ref: MatDialogRef<ApplyForSwapDialogComponent>,
        @Inject(TranslateService) protected translate: TranslateService,
    ) {
        data.size = DialogSize.Medium;
        super(ref, data);

        this.swap = data.swap;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.shiftComments = data.swap.shift!.comments ?? [];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.date = this.swap.shift!.from?.toLocaleString(DateTime.DATE_MED);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.location = this.swap.shift!.schedule?.customer?.name || '';
    }

    async ngOnInit(): Promise<void> {
        this.shiftTranslation = await this.translate.t('SHIFT', 'scheduling');
    }
}
