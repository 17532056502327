<eaw-dialog-header>
    <span title>{{customField.translationKey | translate:'custom_fields' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-16" (submit)="submit()">
        <eaw-custom-field-input
                booleanDisplay="list"
                [selectDisplay]="customField.selectOptions.length > 5 ? 'select' : 'list'"
                [customField]="customField"
                [formGroup]="form.controls.customFields"/>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="tw-flex tw-gap-4">
    <button mat-button (click)="delete()" color="warn" *ngIf="customField.deletable">{{'DELETE' | translate | async}}</button>

    <span class="tw-flex-1"></span>

    <button mat-button mat-dialog-close="">{{'CANCEL' | translate | async}}</button>

    <eaw-action-button [loading]="processing" [disabled]="form.invalid || form.disabled || !hasValue" type="submit" (click)="submit()">
        @if (data.mode === 'add') {
            {{'ADD' | translate | async}}
        } @else {
            {{'UPDATE' | translate | async}}
        }
    </eaw-action-button>
</mat-dialog-actions>
