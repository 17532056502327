import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Customer } from '../../../../../shared/models/customer';
import { CustomerUserService } from '../../../../../shared/http/customer-user.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { DatePickerOptionsDirective } from '../../../../../shared/directives/date-picker-options.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DateTimeInputComponent } from '../../../../../shared/components/date-time/date-time-input/date-time-input.component';
import { catchError, EMPTY, startWith } from 'rxjs';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { DateTime } from 'luxon';
import { User } from '../../../../../shared/models/user';
import { UserAccessService } from '../../../../http/user-access.service';
import { UserAccess } from '../../../../models/user-access';
import { DateTimePipe } from '../../../../../shared/pipes/date-time.pipe';
import { InfoLoadingComponent } from '../../../../../shared/components/info-loading/info-loading.component';
import { NewEmployeeNextStepComponent } from '../../components/new-employee-next-step/new-employee-next-step.component';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';
import { InfoBoxComponent } from '../../../../../shared/components/info-card/info-box.component';
import { CustomerUser } from '../../../../../shared/models/customer-user';
import { TranslateSyncPipe } from '../../../../../shared/pipes/translate-sync.pipe';

type NoAccessForm = {
    useFromDate: FormControl<boolean>;
    from: FormControl<DateTime | null>;
}

type FutureAccessForm = {
    addAccessNow: FormControl<boolean>;
}

@Component({
    selector: 'eaw-new-employee-location-access',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        TranslatePipe,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatInputModule,
        DateTimeInputComponent,
        ActionButtonComponent,
        InfoLoadingComponent,
        DateTimePipe,
        NewEmployeeNextStepComponent,
        InfoBoxComponent,
        TranslateSyncPipe,
    ],
    templateUrl: './new-employee-location-access.component.html',
    styleUrl: './new-employee-location-access.component.scss',
})
export class NewEmployeeLocationAccessComponent extends NewEmployeeStepComponent implements OnInit {
    private readonly customerUserService = inject(CustomerUserService);
    private readonly userAccessService = inject(UserAccessService);

    @Input({ required: true }) customer!: Customer;
    @Input({ required: true }) user!: User;
    @Input({ required: true }) existingUser!: boolean;

    @Output() customerUserChange = new EventEmitter<CustomerUser>();

    loadingData = false;
    processing = false;
    activeAccess?: UserAccess;
    futureAccess?: UserAccess;
    noAccessForm: FormGroup<NoAccessForm>;
    futureAccessForm: FormGroup<FutureAccessForm>;

    constructor() {
        super();

        this.noAccessForm = new FormGroup({
            useFromDate: new FormControl<boolean>(false, { nonNullable: true }),
            from: new FormControl<DateTime | null>(null),
        });

        this.futureAccessForm = new FormGroup({
            addAccessNow: new FormControl<boolean>(false, { nonNullable: true }),
        });
    }

    override ngOnInit() {
        super.ngOnInit();

        if (this.existingUser) {
            this.loadingData = true;
            this.userAccessService.getAllForUser(this.customer.id, this.user.id).subscribe((accesses) => {
                if (accesses.data.length) {
                    this.activeAccess = accesses.data.find((access) => access.isActive);
                    this.futureAccess = accesses.data.find((access) => access.isFuture);

                    if (this.activeAccess) {
                        this.step.completed = true;
                    }
                }

                this.loadingData = false;
            });
        }

        this.noAccessForm.controls.useFromDate.valueChanges.pipe(
            startWith(this.noAccessForm.controls.useFromDate.value),
        ).subscribe((value) => {
            if (value) {
                this.noAccessForm.controls.from.enable();
                this.noAccessForm.controls.from.addValidators(Validators.required);
            } else {
                this.noAccessForm.controls.from.disable();
                this.noAccessForm.controls.from.clearValidators();
            }
        });
    }

    update() {
        if (this.futureAccessForm.controls.addAccessNow.value) {
            this.customerUserService.create(this.customer.id, this.user.id, DateTime.now(), this.futureAccess?.from.minus({ second: 1 })).pipe(
                catchError(() => {
                    this.futureAccessForm.enable();
                    this.processing = false;
                    return EMPTY;
                }),
            ).subscribe((customerUser) => {
                this.step.completed = true;
                this.processing = false;
                this.customerUserChange.emit(customerUser);
                this.stepper.next();
            });
        } else {
            this.step.completed = true;
            this.stepper.next();
        }

        this.futureAccessForm.disable();
    }

    create() {
        this.processing = true;
        const from = this.noAccessForm.controls.from.value;
        this.noAccessForm.disable();
        this.customerUserService.create(this.customer.id, this.user.id, from ?? undefined).pipe(
            catchError(() => {
                this.noAccessForm.enable();
                this.processing = false;
                return EMPTY;
            }),
        ).subscribe((customerUser) => {
            this.step.completed = true;
            this.processing = false;
            this.customerUserChange.emit(customerUser);
            this.stepper.next();
        });
    }
}
