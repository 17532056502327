import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { ConnectRotationDialogData, ConnectRotationDialogReturn } from './connect-rotation-dialog.service';
import { DialogComponent } from '../../../../shared/dialogs/dialog-component';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { EmployeeService } from '../../../../shared/http/employee.service';
import { Employee } from '../../../../shared/models/employee';
import { MatSelectionListChange, MatListModule } from '@angular/material/list';
import { EmployeeRotationService } from '../../http/employee-rotation.service';
import { catchError, EMPTY } from 'rxjs';
import { DateTime } from 'luxon';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ProfilePictureComponent } from '../../../../shared/components/profile-picture/profile-picture.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';

interface ConnectEmployee {
    id: number;
    name: string;
    status: 'pending' | 'processing' | 'success' | 'error';
    icon: 'hourglass_empty' | 'done' | 'close';
}

@Component({
    selector: 'eaw-connect-hour-distribution-dialog',
    templateUrl: './connect-rotation-dialog.component.html',
    styleUrl: './connect-rotation-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        ReactiveFormsModule,
        NgIf,
        MatProgressSpinnerModule,
        MatListModule,
        NgFor,
        ProfilePictureComponent,
        MatIconModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ConnectRotationDialogComponent extends DialogComponent implements OnInit {
    employees: Employee[] = [];
    selectedEmployees: Employee[] = [];
    connecting = false;
    connectEmployees: ConnectEmployee[] = [];
    dateRange = new FormGroup({
        from: new FormControl<DateTime | null>(null, Validators.required),
        to: new FormControl<DateTime | null>(null),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ConnectRotationDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ConnectRotationDialogComponent, ConnectRotationDialogReturn>,
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(EmployeeRotationService) private employeeRotationService: EmployeeRotationService,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.employeeService.getAll(this.data.customerId, {
            per_page: 9999,
            order_by: 'name',
            direction: 'asc',
            'with[]': [ 'user' ],
        }).subscribe((response) => {
            this.employees = response.data;
        });
    }

    employeesSelectionChange(event: MatSelectionListChange) {
        this.selectedEmployees = event.source.selectedOptions.selected.map((o) => o.value);
    }

    prepareForConnect() {
        this.connecting = true;
        this.dialogRef.disableClose = true;

        this.connectEmployees = this.selectedEmployees.map((e) => {
            return {
                id: e.id,
                name: e.name || '',
                status: 'pending',
                icon: 'hourglass_empty',
            };
        });

        this.connect();
    }

    connect(index = 0) {
        if (this.dateRange.value.from == null) {
            return;
        }

        const employee = this.connectEmployees[index];
        if (!employee) {
            return;
        }

        employee.status = 'processing';

        this.employeeRotationService.create(
            this.data.customerId,
            employee.id,
            this.data.rotation.id,
            this.dateRange.value.from,
            this.dateRange.value.to || undefined,
        ).pipe(
            catchError(() => {
                employee.icon = 'close';
                employee.status = 'error';
                this.connecting = false;

                return EMPTY;
            }),
        ).subscribe(() => {
            employee.icon = 'done';
            employee.status = 'success';

            if (this.selectedEmployees[index + 1]) {
                this.connect(index + 1);
            } else {
                this.dialogRef.close(this.connectEmployees.length);
            }
        });
    }
}
