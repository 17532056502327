export enum GridWidth {
    Mini = 'mini',
    Normal = 'normal',
    Wide = 'wide',
    Full = 'full',
}

export enum GridHeight {
    Mini = 'mini',
    Normal = 'normal',
    Tall = 'tall',
}

export interface GridSize {
    width: GridWidth,
    height: GridHeight,
    colspan: Partial<{xs: number, sm: number, md: number, lg: number, xl: number}>,
    rowspan: Partial<{xs: number, sm: number, md: number, lg: number, xl: number}>,
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class WidgetGrid {
    // Exclude mini because that's only for mini widgets
    static widths = [ GridWidth.Normal, GridWidth.Wide, GridWidth.Full ];
    static heights = [ GridHeight.Normal, GridHeight.Tall ];

    static rowHeightMini = 45;
    static rowHeightNormal = 68;
    static columns = 60; // Divisible by 1, 2, 3, 4, 5 and 6
    static gutter = 25;

    private static calcColumns(columns: number) {
        return this.columns / columns;
    }

    static setSize(width: GridWidth = GridWidth.Normal, height: GridHeight = GridHeight.Normal): GridSize {
        const grid: GridSize = {
            width,
            height,
            colspan: {},
            rowspan: {},
        };

        switch (width) {
            case GridWidth.Full: {
                grid.colspan = {
                    xs: this.calcColumns(1),
                    sm: this.calcColumns(1),
                    md: this.calcColumns(1),
                    lg: this.calcColumns(1),
                    xl: this.calcColumns(1),
                };

                break;
            }
            case GridWidth.Mini: {
                grid.colspan = {
                    xs: this.calcColumns(1),
                    sm: this.calcColumns(2),
                    md: this.calcColumns(3),
                    lg: this.calcColumns(4),
                    xl: this.calcColumns(5),
                };

                break;
            }
            case GridWidth.Wide: {
                grid.colspan = {
                    xs: this.calcColumns(1),
                    sm: this.calcColumns(1),
                    md: this.calcColumns(2),
                    lg: this.calcColumns(2),
                    xl: this.calcColumns(2),
                };

                break;
            }
            case GridWidth.Normal: {
                grid.colspan = {
                    xs: this.calcColumns(1),
                    sm: this.calcColumns(2),
                    md: this.calcColumns(2),
                    lg: this.calcColumns(3),
                    xl: this.calcColumns(4),
                };

                break;
            }
        }

        switch (height) {
            case GridHeight.Tall: {
                grid.rowspan = {
                    xs: 8,
                    sm: 8,
                    md: 8,
                    lg: 8,
                    xl: 8,
                };

                break;
            }
            case GridHeight.Mini: {
                grid.rowspan = {
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                };

                break;
            }
            case GridHeight.Normal: {
                grid.rowspan = {
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4,
                };

                break;
            }
        }

        return grid;
    }
}
