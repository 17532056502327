// @ts-nocheck
import { t } from 'i18next';
import { orderBy } from 'lodash-es';
import { debounce } from 'lodash-es';
import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.scheduling').component('standbyList', {
    template: `<day-selector ng-model="$standbyList.day" days="$standbyList.days" ng-change="$standbyList.changeDay()"></day-selector>

<div class="tw-mt-12" layout="row" layout-align="center center" ng-if="$standbyList.employee.id">
    <md-button class="md-raised md-primary" ng-i18next="scheduling:SET_ME_STANDBY" ng-click="$standbyList.addStandby()" ng-if="$standbyList.employee && !$standbyList.myStandbyExist && !$standbyList.fetchingData"></md-button>
</div>

<md-progress-circular class="md-progress-center tw-mt-16" ng-if="$standbyList.fetchingData"></md-progress-circular>

<md-list class="md-dense" flex ng-if="!$standbyList.fetchingData && $standbyList.standbys.length">
    <md-list-item class="md-3-line" ng-repeat="standby in $standbyList.standbys track by standby.id">
        <div class="md-list-item-text" ng-class="{'deleted': standby.deleted_at}">
            <h3 ng-bind="standby.employee.name"></h3>
            <h4 ng-i18next="[i18next]({from: standby.from.format('LT'), to: standby.to.format('LT')})FROM_TO"></h4>
            <p ng-bind="standby.textDuration"></p>
        </div>

        <md-button class="md-secondary md-icon-button" ng-click="$standbyList.updateStandby(standby)" ng-if="standby.editable">
            <md-tooltip md-direction="left" ng-i18next="scheduling:CHANGE_STANDBY"></md-tooltip>
            <md-icon ng-bind="'edit'"></md-icon>
        </md-button>

        <md-button class="md-secondary md-icon-button" ng-click="$standbyList.deleteStandby(standby)" ng-if="standby.deletable">
            <md-tooltip md-direction="left" ng-i18next="scheduling:REMOVE_STANDBY"></md-tooltip>
            <md-icon ng-bind="'delete'" md-colors="{color: 'red'}"></md-icon>
        </md-button>

        <md-button class="md-secondary md-icon-button" ng-click="$standbyList.sendMessage(standby)" ng-if="standby.messageable">
            <md-tooltip md-direction="left" ng-i18next="scheduling:STANDBY_REQUEST"></md-tooltip>
            <md-icon ng-bind="'message'"></md-icon>
        </md-button>

        <div class="md-secondary" ng-if="standby.deleted_at && !$standbyList.smallDevice" layout="column">
            <label ng-i18next="REMOVED"></label>
            <span ng-bind="standby.deleted_at | moment:'lll'"></span>
        </div>

        <md-divider ng-if="!$last"></md-divider>
    </md-list-item>
</md-list>
`,
    bindings: {
        employee: '<', // Object
        days: '<',
        day: '<',
        customerId: '<',
        readOnly: '<?',
        maxDate: '<?',
        widget: '<?',
    },
    controllerAs: '$standbyList',
    controller: [ 'Message', 'StandbyFactory', 'ToastService', '$filter', '$mdDialog', 'timeIntervalDialog', function(Message, StandbyFactory, ToastService, $filter, $mdDialog, timeIntervalDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.canManage = CurrentOld.can(`customers.${ctrl.customerId}.employees.*.standbys.*.get`);

            if (!ctrl.day) {
                ctrl.day = ctrl.days[0];
            }

            ctrl.changeDay();
        };

        ctrl.$onDestroy = () => {
            ctrl.custRes?.$cancelRequest?.();
            ctrl.empRes?.$cancelRequest?.();
        };

        ctrl.changeDay = debounce(() => {
            ctrl.fetchingData = true;
            ctrl.custRes?.$cancelRequest?.();
            ctrl.empRes?.$cancelRequest?.();

            const from = ctrl.day.moment.clone().startOf('d');
            const to = ctrl.day.moment.clone().endOf('d');

            if (ctrl.canManage) {
                ctrl.custRes = StandbyFactory.getForCustomer({
                    customer: ctrl.customerId,
                    from,
                    to,
                    with: [ 'employee' ],
                });

                ctrl.custRes.$promise.then((resp) => {
                    ctrl.formatStandbys(resp.data);
                }).finally(() => delete ctrl.fetchingData);
            } else if (ctrl.employee) {
                ctrl.empRes = StandbyFactory.getForEmployee({
                    customer: ctrl.customerId,
                    employee: ctrl.employee.id,
                    from,
                    to,
                    with: [ 'employee' ],
                });

                ctrl.empRes.$promise.then((resp) => {
                    ctrl.formatStandbys(resp.data);
                }).finally(() => delete ctrl.fetchingData);
            }
        }, 500);

        ctrl.formatStandbys = (standbys) => {
            standbys.forEach((sb) => {
                sb.mine = sb.employee_id === ctrl.employee?.id && !sb.deleted_at;
                sb.unix = sb.from.unix();
                sb.timeDuration = ctrl.availabilityDuration(sb);
                sb.textDuration = $filter('eawDuration')(sb.timeDuration, 'long');
                sb.editable = sb.mine;
                sb.deletable = sb.mine;
                sb.messageable = ctrl.canManage && !sb.deleted_at && !sb.mine && !ctrl.readOnly;
            });

            ctrl.myStandbyExist = standbys.filter((sb) => sb.mine).length;
            ctrl.standbys = orderBy(standbys, [ 'unix', 'timeDuration' ], [ 'asc', 'desc' ]);
        };

        ctrl.availabilityDuration = (standby) => standby.to.diff(standby.from, 's');

        ctrl.sendMessage = (standby) => {
            timeIntervalDialog.open({
                baseDate: ctrl.day.moment.clone(),
                title: t('scheduling:STANDBY_REQUEST'),
                text: t('scheduling:STANDBY_REQUEST_TEXT', {
                    name: standby.employee.name,
                }),
                fromRequired: true,
                toRequired: true,
            }).then((result) => {
                const messageText = t('scheduling:STANDBY_REQUEST_MESSAGE', {
                    name: standby.employee.name,
                    from: result.from.format('LLLL'),
                    to: result.to.format('LLLL'),
                });

                Message.sendMessage(t('scheduling:STANDBY_REQUEST'), messageText, { recipients: [ standby.employee.user_id ] }).$promise.then(() => {
                    ToastService.toast(t('scheduling:STANDBY_REQUEST_SENT'));
                });
            });
        };

        ctrl.addStandby = () => {
            timeIntervalDialog.open({
                minDate: getStandbyMinDate(),
                maxDate: getStandbyMaxDate(),
                baseDate: ctrl.day.moment.clone(),
                title: t('scheduling:STANDBY'),
                fromRequired: true,
                toRequired: true,
            }).then((result) => {
                StandbyFactory.create({
                    customer: ctrl.customerId,
                    employee: ctrl.employee?.id,
                    from: result.from,
                    to: result.to,
                }).$promise.then(() => {
                    ToastService.toast(t('scheduling:CREATED_STANDBY'));
                    ctrl.changeDay();
                });
            });
        };

        ctrl.updateStandby = (standby) => {
            timeIntervalDialog.open({
                from: standby.from,
                to: standby.to,
                baseDate: ctrl.day.moment.clone(),
                minDate: getStandbyMinDate(),
                maxDate: getStandbyMaxDate(),
                title: t('scheduling:CHANGE_STANDBY'),
                fromRequired: true,
                toRequired: true,
            }).then((result) => {
                StandbyFactory.update({
                    customer: ctrl.customerId,
                    employee: ctrl.employee?.id,
                    standby: standby.id,
                    from: result.from,
                    to: result.to,
                }).$promise.then(() => {
                    ToastService.toast(t('scheduling:UPDATED_STANDBY'));
                    ctrl.changeDay();
                });
            });
        };

        ctrl.deleteStandby = (standby) => {
            $mdDialog.show(
                $mdDialog.confirm()
                    .theme('delete')
                    .title(t('scheduling:REMOVE_STANDBY'))
                    .textContent(t('scheduling:REMOVE_STANDBY_TEXT'))
                    .cancel(t('CANCEL'))
                    .ok(t('REMOVE'))
                    .multiple(true),
            ).then(() => {
                StandbyFactory.delete(ctrl.customerId, standby.employee.id, standby.id).$promise.then(ctrl.changeDay);
            });
        };

        function getStandbyMinDate() {
            return ctrl.day.moment.clone().startOf('d'); // Start of selected day
        }

        function getStandbyMaxDate() {
            const to = ctrl.day.moment.clone().endOf('d'); // End of selected day
            return to.isAfter(ctrl.maxDate) ? ctrl.maxDate : to; // Make sure we don't pass max
        }
    } ],
});
