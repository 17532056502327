import { module } from 'angular';
import { ElementPermissionService, PermissionChildrenFilterInInput, PermissionChildrenFilterOutInput, PermissionsInputValue } from '../services/element-permission.service';
import { Subscription, tap } from 'rxjs';

class EawPermissionDirectiveController implements angular.IController {

    // Bindings
    permissions?: PermissionsInputValue;
    somePermissions?: PermissionsInputValue;
    permissionChildrenFilter?: PermissionChildrenFilterOutInput;
    permissionChildrenInclude?: PermissionChildrenFilterInInput;

    private displayStyle: string | null = null;
    private subscription?: Subscription;

    constructor(
        protected $scope: angular.IScope,
        protected $element: angular.IAugmentedJQuery,
        protected $attrs: angular.IAttributes,
        protected ElementPermissionServiceDowngraded: ElementPermissionService,
    ) {}

    $onChanges(onChangesObj:angular.IOnChangesObject) {
        const element = this.$element[0] as HTMLElement;
        const permissions = onChangesObj['permissions']?.currentValue;
        const negativeChildren = onChangesObj['permissionChildrenFilter']?.currentValue;
        const somePermissions = onChangesObj['somePermissions']?.currentValue;
        const positiveChildren = onChangesObj['permissionChildrenInclude']?.currentValue;

        if (!this.ElementPermissionServiceDowngraded.setOrRemoveDataOnElement(element, permissions, somePermissions, negativeChildren, positiveChildren)) {
            return;
        }

        this.subscription?.unsubscribe();
        this.setDisplayStyle(element, false);
        this.subscription = this.ElementPermissionServiceDowngraded.shouldShow(permissions, somePermissions, negativeChildren, positiveChildren)
            .pipe(tap((result) => {
                if (result) {
                    this.setDisplayStyle(element, true);
                } else {
                    this.ElementPermissionServiceDowngraded.insertPlaceholderNode(element, permissions, somePermissions, negativeChildren, positiveChildren);
                }
            }))
            .subscribe();
    }

    $onDestroy() {
        this.subscription?.unsubscribe();
    }

    private setDisplayStyle(element: HTMLElement, hasPermission: boolean) {
        element.dataset['allowed'] = hasPermission.toString();
        const currentDisplay = element.style.display;

        if (this.displayStyle === null && currentDisplay !== 'none') {
            this.displayStyle = currentDisplay;
        }

        if (!hasPermission) {
            element.style.display = 'none';
        } else if (this.displayStyle !== null && currentDisplay === 'none') {
            element.style.display = this.displayStyle;
        }
    }
}

module('eaw').directive('eawPermission', function() {
    return {
        restrict: 'A',
        scope: {
            permissions: '<',
            somePermissions: '<',
            permissionChildrenFilter: '<',
        },
        bindToController: true,
        controller: [ '$scope', '$element', '$attrs', 'ElementPermissionServiceDowngraded', EawPermissionDirectiveController ],
    };
});
