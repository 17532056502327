import { Component, Inject } from '@angular/core';
import { DialogComponent } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { AssignVacationDaysDialogData } from './assign-vacation-days-dialog.service';
import { VacationDay } from '../../models/vacation-day';
import { VacationDaysService } from '../../http/vacation-days.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { catchError, EMPTY } from 'rxjs';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-assign-vacation-days',
    templateUrl: './assign-vacation-days-dialog.component.html',
    styleUrl: './assign-vacation-days-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AssignVacationDaysDialogComponent extends DialogComponent {
    minDate = DateTime.now();
    form = new FormGroup({
        days: new FormControl<number | null>(null, Validators.required),
        date: new FormControl<DateTime | null>(null, Validators.required),
        comment: new FormControl<string | null>(null, Validators.required),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AssignVacationDaysDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AssignVacationDaysDialogComponent, VacationDay>,
        @Inject(VacationDaysService) public vacationDaysService: VacationDaysService,
    ) {
        super(dialogRef, data);
    }

    submit() {
        const form = this.form.value;
        const multiplier = this.data.mode === 'add' ? 1 : -1;
        this.form.disable();

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.vacationDaysService.create(this.data.customerId, this.data.employeeId, form.days! * multiplier, form.comment!, form.date!)
            .pipe(
                catchError(() => {
                    this.form.enable();
                    return EMPTY;
                }),
            )
            .subscribe((result) => {
                this.dialogRef.close(result);
            });
    }
}
