<h1 mat-dialog-title>{{ data.title | async }}</h1>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-24">
    <div class="text">{{ data.text | async }}</div>

    <mat-form-field>
        <mat-label>{{ data.label | async }}</mat-label>
        <textarea *ngIf="data.type === 'textarea'" [formControl]="data.formControl" matInput autocomplete="off" rows="8"></textarea>
        <input *ngIf="data.type === 'text'" [formControl]="data.formControl" matInput type="text" autocomplete="off" (keydown.enter)="close()">
        <input *ngIf="data.type === 'integer' || data.type === 'decimal'"
               [formControl]="data.formControl"
               [step]="numberOptions.step || 'any'"
               [min]="numberOptions.min ?? null"
               [max]="numberOptions.max ?? null"
               matInput
               type="number"
               (keydown.enter)="close()">

        <mat-error><eaw-field-error [control]="data.formControl"/></mat-error>

        <mat-hint align="end" *ngIf="maxLength">{{ data.formControl.value?.length || 0 }} / {{ maxLength }}</mat-hint>
        <mat-hint align="end" *ngIf="minLength">{{ 'MIN_LENGTH_CHARS' | translate:'general':{count: minLength} | async }}</mat-hint>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="">{{ data.cancelText || ('CANCEL' | translate) | async }}</button>
    <eaw-action-button [loading]="false" (click)="close()" [disabled]="data.formControl.invalid || data.formControl.pristine">{{ data.confirmText || ('CONFIRM' | translate) | async }}</eaw-action-button>
</mat-dialog-actions>
