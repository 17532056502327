// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
import { KpiType } from '../../../../kpi/angularjs/types/kpi-type';
import { Kpi } from '../../../../kpi/angularjs/types/kpi';
import { EawChart } from '../../../../shared/angularjs/modules/misc/services/eaw-chart';
import { TinyColor } from '@ctrl/tinycolor';
module('eaw.dashboard').component('kpiWidget', {
    template: `<!-- Range subheader -->
<md-subheader ng-show="$ctrl.eawWidget.widget.settings.range == 'yesterday'">{{'widgets:YESTERDAY' | i18next}}</md-subheader>
<md-subheader ng-show="$ctrl.eawWidget.widget.settings.range == 'this_month'">{{'widgets:THIS_MONTH' | i18next}}</md-subheader>
<md-subheader ng-show="$ctrl.eawWidget.widget.settings.range == 'week'">{{'widgets:THIS_WEEK' | i18next}}</md-subheader>
<md-subheader ng-show="$ctrl.eawWidget.widget.settings.range == 'last_week'">{{'widgets:LAST_WEEK' | i18next}}</md-subheader>
<md-subheader ng-show="$ctrl.eawWidget.widget.settings.range == 'last_month'">{{'widgets:LAST_MONTH' | i18next}}</md-subheader>

<!-- CHART -->
<div ng-show="$ctrl.data && $ctrl.eawWidget.widget.settings.mode == 'chart'" id="kpiWidgetChartContainer"></div>

<!-- TABLE -->
<table id="kpi-widget-table"
       ng-show="$ctrl.data && $ctrl.eawWidget.widget.settings.mode == 'table'"
       class="sticky-header sticky-left table table-condensed table-bordered table-vmiddle">
    <thead>
    <tr>
        <th class="text-ws-nowrap" ng-i18next="widgets:LOCATION"></th>
        <th class="text-ws-nowrap" ng-repeat="(key, type) in $ctrl.data.kpi_types track by type.id">
            <div class="kpi-title-container">
                <span>{{type.name | i18next}}</span>
            </div>

            <md-tooltip>{{type.name | i18next}}</md-tooltip>
        </th>
    </tr>
    </thead>
    <tbody ng-if="$ctrl.numbers">
    <tr ng-repeat="(id, data) in $ctrl.numbers track by id">
        <td class="text-ws-nowrap">
            <span ng-bind="::data.customer.name"></span>
        </td>
        <td ng-repeat="(key, type) in $ctrl.data.kpi_types track by type.id">
            <div ng-if="data.kpis[type.id]">
                {{type.prefix + ' ' + (data.kpis[type.id] | eawNumber: $ctrl.getKpiDecimals(type)) + ' ' + type.suffix}}
            </div>
        </td>
    </tr>
    </tbody>
</table>
`,
    require: {
        eawWidget: '^eawWidget',
    },
    controller: [ 'KpiFactory', '$filter', 'colorpickerService', function(KpiFactory, $filter, colorpickerService) {
        // Aliasing this here to use it in a callback where we also use this of the callback
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const ctrl = this;
        this.$onInit = () => {
            this.chartContainer = 'kpiWidgetChartContainer';

            this.eawWidget.widgetSettingsChange = (settings) => {
                this.headerColor = colorpickerService.getHex(settings.headerColor);
                this.headerTextColor = new TinyColor(ctrl.headerColor).isDark() ? 'white' : 'black';
                this.eawWidget.widget.settings = settings;
                this.getOverview();
            };

            this.getOverview();
        };

        this.$onDestroy = () => {
            this.overviewRes?.$cancelRequest?.();
        };

        this.getOverview = () => {
            this.eawWidget.setLoading(true);
            const from = moment();
            let to;
            switch (this.eawWidget.widget.settings.range) {
                case 'this_month':
                    from.startOf('M');
                    to = moment().endOf('M');
                    break;
                case 'week':
                    from.startOf('w');
                    to = moment().endOf('w');
                    break;
                case 'last_week':
                    from.subtract(1, 'w').startOf('w');
                    to = from.clone().endOf('w');
                    break;
                case 'last_month':
                    from.subtract(1, 'M').startOf('M');
                    to = from.clone().endOf('M');
                    break;
                case 'yesterday':
                default:
                    from.subtract(1, 'd').startOf('d');
                    to = from.clone().endOf('d');
                    break;
            }
            this.data = undefined;
            this.overviewRes?.$cancelRequest?.();
            this.overviewRes = KpiFactory.getOverview({
                from,
                to,
                kpi_types: this.eawWidget.widget.settings.kpiTypes,
                customers: this.eawWidget.widget.settings.customers || 'u',
            });
            this.overviewRes.$promise.then((resp) => {
                // We can't generate any data if either customers or ts are empty
                if ([ resp.customers, resp.kpi_types, resp.kpi_data ].some((x) => !Object.keys(x).length)) {
                    this.eawWidget.setEmpty(true);
                    return;
                }
                const kpiTypes = resp.kpi_types.map((type) => {
                    const sums = resp.provider_sums.filter((p) => p && p.kpi_type_id == type.id);
                    return new KpiType(type, sums);
                });
                this.data = {
                    customers: resp.customers,
                    kpi_data: resp.kpi_data.map((kpi) => new Kpi(kpi, kpiTypes.find((type) => type.id == kpi.kpi_type_id))),
                    kpi_types: kpiTypes,
                    provider_sums: resp.provider_sums,
                };
                this.numbers = {};
                this.data.customers.forEach((customer) => {
                    this.numbers[customer.id] = {
                        customer,
                        kpis: this.data.kpi_types.reduce((acc, type) => {
                            return {
                                ...acc,
                                [type.id]: this.findData(customer, type),
                            };
                        }, {}),
                    };
                });
                if (this.eawWidget.widget.settings.range == 'yesterday') {
                    this.basicColumnChart();
                } else {
                    this.lineChart();
                }
            }).finally(() => this.eawWidget.setLoading(false));
        };
        this.findData = (customer, type) => {
            return type.getSum(this.data.kpi_data, customer.id);
        };
        this.getKpiDecimals = (type) => type.type === 'integer' ? 0 : 2;
        this.basicColumnChart = () => {
            // Create categories
            let xAxisCategories = Object.keys(this.data.kpi_types).reduce((array, key) => {
                const type = this.data.kpi_types[key];
                return array.concat({
                    id: type.id,
                    name: t(type.name),
                });
            }, []);
            // Sort
            xAxisCategories = xAxisCategories.sort((a, b) => a.id - b.id);
            // Create data
            const seriesData = this.data.customers.reduce((data, customer) => {
                const customerData = this.data.kpi_data.filter((k) => k.customer_id === customer.id);
                // Check if any ts are missing from the customer, and if so add it
                this.data.kpi_types.forEach((type) => {
                    // If it's a type we want to include, but it's not found
                    if (!customerData.find((c) => c.kpi_type_id === type.id)) {
                        customerData.push({
                            value: null,
                            kpi_type_id: type.id,
                        });
                    }
                });
                return data.concat([ {
                    name: customer.name,
                    data: customerData.sort((a, b) => a.kpi_type_id - b.kpi_type_id),
                } ]);
            }, []);
            // Remove what we don't want
            seriesData.forEach((location) => {
                location.data = location.data.filter((data) => xAxisCategories.map((x) => x.id).includes(data.kpi_type_id)).map((data) => data.value);
            });
            this.columnChartOptions = {
                chart: {
                    type: 'column',
                },
                title: {
                    text: null,
                },
                xAxis: {
                    categories: xAxisCategories.map((x) => x.name),
                    labels: {
                        enabled: false,
                    },
                    crosshair: true,
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                },
                tooltip: {
                    headerFormat: `<span style="font-size:10px">{point.key}</span><table>`,
                    pointFormatter() {
                        const point = this;
                        let decimals;
                        Object.keys(ctrl.data.kpi_types).forEach((key) => {
                            if (point(ctrl.data.kpi_types[key].name) === point.category) {
                                decimals = ctrl.data.kpi_types[key].type === 'integer' ? 0 : 2;
                            }
                        });
                        return `
                                <tr>
                                    <td style="color:{series.color};padding:0">${point.series.name}: </td>
                                    <td style="padding:0; text-align: right"><strong>${$filter('eawNumber')(point.y, decimals)}</strong></td>
                                </tr>
                               `;
                    },
                    footerFormat: `</table>`,
                    shared: true,
                    useHTML: true,
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },
                series: seriesData,
            };
            const chartInstance = new EawChart(this.chartContainer, this.columnChartOptions);
            chartInstance.getChart().then((chart) => {
                this.columnChart = chart;
            });
        };
        this.lineChart = () => {
            const series = this.data.kpi_types.map((type) => {
                return this.data.customers.map((customer) => {
                    let name = t(type.name);
                    name = this.data.customers.length > 1 ? `${name}: ${customer.name}` : name;
                    return {
                        kpi_type: type,
                        name,
                        data: this.data.kpi_data
                            .filter((x) => x.kpi_type_id === type.id && x.customer_id === customer.id)
                            .map((kpi) => [ kpi.business_date.valueOf(), kpi.value ]),
                    };
                });
            });
            const options = {
                title: {
                    text: undefined,
                },
                chart: {
                    type: 'line',
                },
                xAxis: {
                    type: 'datetime',
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                    },
                },
                tooltip: {
                    headerFormat: `<span style="font-size:10px">{point.key}</span><table>`,
                    pointFormatter() {
                        const point = this;
                        const decimals = ctrl.getKpiDecimals(point.series.userOptions.kpi_type);
                        return `
                                <tr>
                                    <td style="color:{series.color};padding:0">${point.series.name}: </td>
                                    <td style="padding:0; text-align: right"><strong>${$filter('eawNumber')(point.y, decimals)}</strong></td>
                                </tr>
                               `;
                    },
                    footerFormat: `</table>`,
                    shared: true,
                    useHTML: true,
                },
                series: series.flat(),
            };
            const chartInstance = new EawChart(this.chartContainer, options);
            chartInstance.getChart().then((chart) => {
                this.columnChart = chart;
            });
        };
    } ],
});
