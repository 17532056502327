import { Component, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule, MatDateRangePicker } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DateTime, Info } from 'luxon';
import { Employee } from '../../../shared/models/employee';
import { NightHoursReportService, WeeklyNightHoursResponse } from '../../http/night-hours-report.service';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BusinessDate } from '../../../shared/utils/business-date';
import { catchError, of } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { MAT_DATE_FORMATS } from '@angular/material/core';

type WeekObject = { year: number; week: number; key: string };

type MonthObject = { name: string; span: number };

@Component({
    selector: 'eaw-weekly-night-work-report',
    standalone: true,
    imports: [
        CommonModule, AutocompleteComponent, DatePickerOptionsDirective, MatButtonModule, MatCardModule, MatDatepickerModule, MatFormFieldModule,
        MatInputModule, PageHeaderComponent, ReactiveFormsModule, TranslatePipe, DateTimePipe, MatProgressBarModule, MatIconModule, NumberPipe,
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                display: { dateInput: 'MMM y', monthYearLabel: 'MMM y', dateA11yLabel: 'MMMM y', monthYearA11yLabel: 'MMMM y' },
            },
        },
    ],
    templateUrl: './weekly-night-work-report.component.html',
    styleUrls: [ './weekly-night-work-report.component.scss', '../annual-night-hours-report/annual-night-hours-report.component.scss' ],
})
export class WeeklyNightWorkReportComponent implements OnInit {
    @Input() customerId!: number;
    data?: WeeklyNightHoursResponse;
    weeks?: WeekObject[];
    months?: MonthObject[];
    recompenseWeeks: Record<string, string[]> = {};
    loading = false;

    form = new FormGroup({
        from: new FormControl(DateTime.now().minus({ months: 1 }).startOf('month'), Validators.required),
        to: new FormControl(DateTime.now().endOf('month'), Validators.required),
        employees: new FormControl<Employee | null>(null),
    });

    constructor(
        @Inject(NightHoursReportService) private nightHoursReportService: NightHoursReportService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
    ) {}

    ngOnInit() {
        this.submit();
    }

    submit() {
        this.loading = true;
        const values = this.form.value;

        this.nightHoursReportService.getWeeklyNightHours(this.customerId, {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            from: values.from!.startOf('week'),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            to: values.to!.endOf('week'),
            'employees[]': values.employees ? [ values.employees.id ] : undefined,
        }).pipe(
            catchError(() => of(undefined)),
        ).subscribe((data) => {
            this.loading = false;
            if (!data) {
                return;
            }

            this.handleData(data);
        });
    }

    handleData(data: WeeklyNightHoursResponse) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const firstMonth = this.form.controls.from.value!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const lastMonth = this.form.controls.to.value!;
        const start = firstMonth.startOf('week');
        const end = lastMonth.endOf('week');

        this.months = [];
        for (let i = 0; i < firstMonth.until(lastMonth).count('months'); i++) {
            const monthStart = firstMonth.plus({ months: i }).startOf('month');
            let weekStart = monthStart.startOf('week');
            if (i && !monthStart.hasSame(weekStart, 'month')) {
                weekStart = monthStart.plus({ weeks: 1 }).startOf('week');
            }

            const span = weekStart.until(monthStart.endOf('month')).count('weeks');
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const name = Info.months()[monthStart.month - 1]!;

            this.months.push({ name, span });
        }

        this.weeks = [];
        let weekIndex = start;
        while (weekIndex <= end) {
            this.weeks.push({ year: weekIndex.weekYear, week: weekIndex.weekNumber, key: `${weekIndex.weekYear}-${weekIndex.weekNumber}` });
            weekIndex = weekIndex.plus({ weeks: 1 });
        }

        for (const emp in data) {
            this.recompenseWeeks[emp] = [];

            let weeksInRow = 0;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            start.minus({ months: 3, weeks: data[emp]!.max_consecutive_weeks }).startOf('week').until(end).splitBy({ weeks: 1 })
                .forEach((week) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    if (data[emp]!.weeks_with_night_work[`${week.start!.weekYear}-${week.start!.weekNumber}`]) {
                        weeksInRow++;
                    } else {
                        weeksInRow = 0;
                    }

                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    if (weeksInRow >= data[emp]!.max_consecutive_weeks) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const recompenseEnd = week.start!.plus({ months: 3 });
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        let wStart = week.start!;
                        while (wStart <= recompenseEnd) {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            if (this.weeks!.some((w) => w.key === `${wStart.weekYear}-${wStart.weekNumber}`)) {
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                this.recompenseWeeks[emp]!.push(`${wStart.weekYear}-${wStart.weekNumber}`);
                            }
                            wStart = wStart.plus({ weeks: 1 });
                        }
                    }
                });
        }

        this.data = data;
    }

    chooseMonths(date: DateTime, picker: MatDateRangePicker<DateTime>) {
        if (this.form.value.to) {
            this.form.controls.to.setValue(null);
            this.form.controls.from.setValue(date);
            picker.close();
            picker.open();
        } else {
            this.form.controls.to.setValue(date.endOf('month'));
            picker.close();
        }
    }

    getCompensatedHours(id: string, employee: WeeklyNightHoursResponse[number], week: string) {
        if (!this.recompenseWeeks[id]?.includes(week)) {
            return null;
        }

        const compensationRate = employee.compensation_rate / 100;
        const amount = employee.hours_per_week[week] || 0;
        return amount * compensationRate;
    }

    formatEffectiveDate(date: string | null) {
        return date ? new BusinessDate(date) : null;
    }
}
