import { Inject, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from './translate.service';

@Injectable({
    providedIn: 'root',
})
export class CustomMatPaginatorIntlService extends MatPaginatorIntl {

    constructor(@Inject(TranslateService) private translate: TranslateService) {
        super();

        void this.setTranslations();
    }

    async setTranslations() {
        this.itemsPerPageLabel = await this.translate.t('ITEMS_PER_PAGE');
    }
}
