import { Inject, Injectable, Injector } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { PermissionCheckService } from '../services/permission-check.service';
import { SnackBarService } from '../services/snack-bar.service';
import { StateProviderDataData } from '../utils/create-state';
import { forkJoin, Observable, of } from 'rxjs';
import { Transition } from '@uirouter/angularjs';
import { CurrentService } from '../services/current.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionsHookService {

    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(CurrentService) private currentService: CurrentService,
        @Inject(Injector) private injector: Injector,
    ) {
        this.uiRouter.transitionService.onBefore({
            to(state) {
                return state?.data?.permissions?.length || state?.data?.somePermissions?.length || state?.data?.permissionCheck;
            },
        }, this.checkPermissions.bind(this));
    }

    checkPermissions(transition: Transition): Promise<boolean> {
        console.debug('🪝', 'PermissionsHookService.onBefore', transition);

        const data = transition.to().data;
        const permissions = data.permissions as StateProviderDataData['permissions'];
        const somePermissions = data.somePermissions as StateProviderDataData['somePermissions'];
        const permissionCheck = data.permissionCheck as StateProviderDataData['permissionCheck'];

        const options = {
            replace: transition.params(),
        };

        const permissionsObservable: Observable<boolean> = Array.isArray(permissions) ? this.permissionCheckService.isAllowedMany(permissions, [], options) : permissions || of(true);
        const somePermissionsObservable: Observable<boolean> = Array.isArray(somePermissions) ? this.permissionCheckService.isAllowedMany([], somePermissions, options) : somePermissions || of(true);

        return new Promise((resolve, reject) => {
            forkJoin([
                permissionsObservable,
                somePermissionsObservable,
                permissionCheck?.(this.permissionCheckService, this.injector) || of(true),
            ]).subscribe(([ permissionsAllowed, somePermissionsAllowed, checkAllowed ]) => {
                if (permissionsAllowed && somePermissionsAllowed && checkAllowed) {
                    resolve(true);
                } else {
                    void this.snackBarService.t('ROUTE_INSUFFICIENT_PERMISSIONS');

                    console.groupCollapsed('⛔ Missing state permissions');
                    console.error('Transition', transition);

                    if (Array.isArray(permissions)) {
                        console.table(this.permissionCheckService.getDisallowedPermissions(permissions, []));
                    }

                    if (Array.isArray(somePermissions)) {
                        console.table(this.permissionCheckService.getDisallowedPermissions([], somePermissions));
                    }
                    console.groupEnd();

                    reject();
                }
            });
        });
    }
}
