// @ts-nocheck
import { forEach } from 'lodash-es';
import { filter } from 'lodash-es';

import { module } from 'angular';
import { t } from 'i18next';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="$ctrl.heading"></eaw-dialog-header-old>

    <md-dialog-content>
        <form class="md-dialog-content" layout="column" id="autoStartForm" name="autoStartForm" ng-submit="$ctrl.submit()">
            <md-radio-group ng-model="$ctrl.type" ng-change="$ctrl.typeChanged()">
                <md-radio-button ng-value="'weekly'">
                    <span ng-i18next="WEEKLY"></span>
                </md-radio-button>
                <md-radio-button ng-value="'monthly'">
                    <span ng-i18next="MONTHLY"></span>
                </md-radio-button>
            </md-radio-group>

            <div ng-if="$ctrl.type == 'monthly'" layout="row">
                <md-content layout="column" flex class="mh-500">
                    <!-- ng-repeat here, need to be able to add days and times for those days-->
                    <auto-start-month ng-model="$ctrl.autoStart" ng-change="$ctrl.monthChanged($ctrl.autoStart)" layout="row"></auto-start-month>
                </md-content>
            </div>
            <div ng-if="$ctrl.type == 'weekly'">
                <md-input-container>
                    <label for="noWeeks" ng-i18next="REPEAT"></label>
                    <input id="noWeeks" type="number" max="4" min="0" ng-model="$ctrl.weeks">
                </md-input-container>
                <!-- Separate the weeks so it's styles confusing -->

                <md-tabs>
                    <md-tab ng-repeat="week in $ctrl.autoStart track by $index" ng-if="$index < $ctrl.weeks">
                        <md-tab-label>
                            <span ng-i18next="[i18next]({number: $index + 1})WEEK_N"></span>
                        </md-tab-label>
                        <md-tab-body class="mh-500">
                            <md-content>
                                <auto-start-week ng-model="week" ng-change="$ctrl.weekChanged(week, $index)"></auto-start-week>
                            </md-content>
                        </md-tab-body>
                    </md-tab>
                </md-tabs>
            </div>

        </form>
    </md-dialog-content>
    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button type="submit" form="autoStartForm" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.time').factory('autoStartDialog', [ '$mdDialog', function($mdDialog) {
    return function() {
        return $mdDialog.show({
            controller: 'AutoStartDialogCtrl',
            controllerAs: '$ctrl',
            bindToController: true,
            locals: {
                cancel: $mdDialog.cancel,
                hide: $mdDialog.hide,
            },
            template: template1,
        });
    };
} ]);

module('eaw.time').controller('AutoStartDialogCtrl', function AutoStartDialogController() {
    const ctrl = this;
    const _weeks = new Array(4).fill(new Array(7).fill(null));
    let _month = new Array(31).fill(null);

    ctrl.weeks = 1; // 1-4
    ctrl.type = 'weekly'; // 'monthly' is the other possible value
    ctrl.heading = t('CUSTOM');

    ctrl.setAutoStart = () => {
        ctrl.autoStart = ctrl.type == 'weekly' ? _weeks : _month;
    };

    ctrl.setAutoStart();

    ctrl.weekChanged = (week, index) => {
        _weeks[index] = week;
        ctrl.setAutoStart();
    };

    ctrl.monthChanged = (month) => {
        _month = month;
        ctrl.setAutoStart();
    };

    ctrl.submit = () => {
        const autoStart = {};
        const setTime = (time, index) => {
            if (time) {
                autoStart[index] = time;
            }
        };
        if (ctrl.type == 'weekly') {
            const weeks = filter(_weeks, (week, i) => i < ctrl.weeks);
            forEach(weeks, (week, wIndex) => {
                forEach(week, (time, dIndex) => {
                    const isoDay = dIndex + (7 * wIndex) + 1;
                    setTime(time, isoDay);
                });
            });
        } else {
            forEach(_month, (time, day) => {
                setTime(time, day + 1);
            });
        }
        ctrl.hide({
            data: autoStart,
            type: ctrl.type,
        });
    };
});
