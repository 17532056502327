import { module } from 'angular';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { PeriodLocksFactory } from './period-locks.factory';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { PeriodLockResponse } from '../models/period-lock';
import { t } from 'i18next';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.period-locks').component('periodLock', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="payroll:PERIOD_LOCK_plural"></span>
        </md-card-header-text>
        <card-btn-float ng-if="$ctrl.canCreate" on-click="$ctrl.openAddDialog()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="$ctrl.columns"
                cells="$ctrl.cells"
                get-data="$ctrl.getData(pagination)"
                reload-table="$ctrl.reload">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
    },
    controller: [ 'ToastService', 'PeriodLocksFactory', '$mdDialog', 'addPeriodLock', function(ToastService: SnackBarService, PeriodLocksFactory: PeriodLocksFactory, $mdDialog, addPeriodLock) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.canCreate = CurrentOld.can(`customers.${ctrl.customer.id}.period_locks.create`);
            ctrl.columns = [
                { title: t('TO') },
                { title: t('payroll:LOCKED_BY') },
                { title: t('payroll:WHEN') },
                { title: '' },
            ];

            ctrl.cells = [
                { template: `item.to | moment:'LL'` },
                { template: 'item.user.name' },
                { template: `item.updated_at | moment:'LL'` },
                {
                    buttons: [
                        {
                            displayNone: (item: PeriodLockResponse) => {
                                return !CurrentOld.can(`customers.${ctrl.customer.id}.period_locks.${item.id}.delete`);
                            },
                            icon: 'lock-open',
                            type: 'warn',
                            tooltip: 'payroll:REMOVE_PERIOD_LOCK',
                            click: ctrl.openDeleteDialog,
                        },
                    ],
                },
            ];
        };

        ctrl.getData = (pagination: PaginationOptions) => {
            return PeriodLocksFactory.getLocks(ctrl.customer.id, {
                ...pagination,
            }).$promise;
        };

        ctrl.update = () => {
            ctrl.reload = true;
        };

        ctrl.openAddDialog = () => {
            addPeriodLock.open(ctrl.update, ctrl.customer);
        };

        ctrl.openDeleteDialog = (lock: PeriodLockResponse) => {
            $mdDialog.show(
                $mdDialog.confirm()
                    .theme('delete')
                    .title(t('payroll:REMOVE_PERIOD_LOCK_TITLE'))
                    .textContent(t('payroll:REMOVE_PERIOD_LOCK_TEXT'))
                    .ok(t('DELETE'))
                    .cancel(t('CANCEL')),
            ).then(() => {
                PeriodLocksFactory.delete(ctrl.customer.id, lock['id']).$promise.then(() => {
                    void ToastService.t('PERIOD_LOCK_REMOVED', 'payroll');
                    ctrl.update();
                });
            });
        };
    } ],
});
