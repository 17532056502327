import { Component, inject, Input, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablePagination, type DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { Observable, of } from 'rxjs';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { SettingGroupPropertyService } from '../../../shared/http/setting-group-property.service';
import { Property } from '../../../shared/models/property';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { TranslateService } from '../../../shared/services/translate.service';
import { AddEditPropertyDialogComponent, AddEditPropertyDialogData } from '../../dialogs/add-edit-property-dialog/add-edit-property-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { CustomerPropertyService } from '../../../shared/http/customer-property.service';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';
import { Namespace } from '../../../shared/enums/namespace';

@Component({
    selector: 'eaw-setting-group-settings',
    standalone: true,
    imports: [ CommonModule, PageHeaderComponent, TranslatePipe, MatCardModule, DataTableComponent ],
    templateUrl: './setting-group-settings.component.html',
    styleUrl: './setting-group-settings.component.scss',
})
export class SettingGroupSettingsComponent implements EawDataTable<Property> {
    private readonly settingGroupPropertyService = inject(SettingGroupPropertyService);
    private readonly customerPropertyService = inject(CustomerPropertyService);
    private readonly translateService = inject(TranslateService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly matDialog = inject(MatDialog);
    private readonly confirmDialogService = inject(ConfirmDialogService);

    table = viewChild.required(DataTableComponent);

    @Input() settingGroup?: { id: number };
    @Input() customerId?: number;

    fabButton: HeaderFabButton;

    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    headerButtons: PageHeaderButton[];

    columns: DataTableColumnType<Property>[];

    constructor() {
        this.columns = [
            new DataTableTextColumn({
                value: 'id',
                header: new DataTableHeader({
                    text: 'Id',
                    sortBy: 'id',
                }),
            }),
            new DataTableTextColumn({
                value: 'key',
                header: new DataTableHeader({
                    i18n: 'KEY',
                    sortBy: 'key',
                }),
            }),
            new DataTableTextColumn({
                value: 'value',
                header: new DataTableHeader({
                    i18n: 'VALUE',
                    sortBy: 'value',
                }),
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        icon: 'edit',
                        click: this.editProperty.bind(this),
                        show: this.getUpdatePermission.bind(this),
                        tooltip: { key: 'EDIT' },
                    },
                    {
                        icon: 'delete',
                        type: 'warn',
                        click: this.deleteProperty.bind(this),
                        show: this.getDeletePermission.bind(this),
                        tooltip: { key: 'DELETE' },
                    },
                ],
            }),
        ];
        this.headerButtons = [
            new PageHeaderButton({
                icon: 'cleaning_services',
                click: this.flush.bind(this),
                menuText: signal(this.translateService.t('FLUSH', Namespace.Admin)),
            }),
        ];
        this.fabButton = {
            click: this.addProperty.bind(this),
            hasPermission: this.getCreatePermission.bind(this),
        };
    }

    getUpdatePermission(property: Property) {
        if (this.settingGroup?.id) {
            return this.permissionCheckService.isAllowed(`setting_groups.[${ApiModel.Group}].properties.${property.id}.update`, {
                models: [
                    { id: this.settingGroup.id, type: ApiModelClass.SettingGroup },
                ],
            });
        }

        if (this.customerId) {
            return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].properties.${property.id}.update`, {
                models: [
                    { id: this.customerId, type: ApiModel.Customer },
                ],
            });
        }

        return this.permissionCheckService.isAllowed('*');
    }

    getDeletePermission(property: Property) {
        if (this.settingGroup?.id) {
            return this.permissionCheckService.isAllowed(`setting_groups.[${ApiModel.Group}].properties.${property.id}.delete`, {
                models: [
                    { id: this.settingGroup.id, type: ApiModelClass.SettingGroup },
                ],
            });
        }

        if (this.customerId) {
            return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].properties.${property.id}.delete`, {
                models: [
                    { id: this.customerId, type: ApiModel.Customer },
                ],
            });
        }

        return this.permissionCheckService.isAllowed('*');
    }

    getCreatePermission() {
        if (this.settingGroup?.id) {
            return this.permissionCheckService.isAllowed(`setting_groups.[${ApiModel.Group}].properties.create`, {
                models: [
                    { id: this.settingGroup.id, type: ApiModelClass.SettingGroup },
                ],
            });
        }

        if (this.customerId) {
            return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].properties.create`, {
                models: [
                    { id: this.customerId, type: ApiModel.Customer },
                ],
            });
        }

        return this.permissionCheckService.isAllowed('*');
    }

    addProperty() {
        this.matDialog.open<AddEditPropertyDialogComponent, AddEditPropertyDialogData, Property>(AddEditPropertyDialogComponent, {
            data: {
                settingGroupId: this.settingGroup?.id,
                customerId: this.customerId,
            },
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.flush();
        });
    }

    editProperty(cell: DataTableButtonCell<Property>) {
        this.matDialog.open<AddEditPropertyDialogComponent, AddEditPropertyDialogData, Property>(AddEditPropertyDialogComponent, {
            data: {
                property: of(cell.item),
                settingGroupId: this.settingGroup?.id,
                customerId: this.customerId,
            },
        }).afterClosed().subscribe((result) => {
            if (!result) {
                cell.disabled.set(false);
                return;
            }

            this.flush();
        });
    }

    deleteProperty(cell: DataTableButtonCell<Property>) {
        this.confirmDialogService.delete({
            title: this.translateService.t('DELETE_PROPERTY'),
            name: cell.item.key,
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            if (this.settingGroup) {
                this.settingGroupPropertyService.delete(this.settingGroup.id, cell.item.key).subscribe(() => {
                    this.flush();
                });
            } else if (this.customerId) {
                this.customerPropertyService.delete(this.customerId, cell.item.key).subscribe(() => {
                    this.flush();
                });
            }
        });
    }

    flush() {
        let observable: Observable<void> | undefined;
        if (this.settingGroup) {
            observable = this.settingGroupPropertyService.flush(this.settingGroup.id);
        } else if (this.customerId) {
            observable = this.customerPropertyService.flush(this.customerId);
        }

        observable?.subscribe(() => {
            this.table().refresh();
        });
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        if (this.settingGroup) {
            this.request = this.settingGroupPropertyService.getAll(this.settingGroup.id, pagination);
        } else if (this.customerId) {
            this.request = this.customerPropertyService.getAll(this.customerId, pagination);
        }
    }
}
