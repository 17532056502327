// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';
import { EawChart } from '../../../../../shared/angularjs/modules/misc/services/eaw-chart';

module('eaw.payroll').component('timepunchXrange', {
    template: `<div id="{{::xrange.id}}"></div>`,
    controllerAs: 'xrange',
    bindings: {
        id: '@chartId',
        categories: '<',
        data: '<',
        yTitle: '<',
        date: '<',
        reversedY: '<',
        loading: '=',
    },
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {
            if (ctrl.categories && ctrl.data && ctrl.date) {
                ctrl.update();
            }
        };

        ctrl.$onChanges = (changes) => {
            if (!ctrl.categories || !ctrl.data || !ctrl.date) {
                return;
            }

            if (changes.categories || changes.data) {
                ctrl.update();
            }
        };

        ctrl.update = () => {
            ctrl.loading = true;
            if (ctrl.chart) {
                ctrl.updateChart();
            } else {
                ctrl.chart = ctrl.createChart();
            }
        };

        ctrl.stopLoading = () => ctrl.loading = false;

        ctrl.createChart = () => {
            const [ from, to, hours ] = ctrl.getFromToHours();

            return new EawChart(ctrl.id, {
                chart: {
                    type: 'xrange',
                    height: ctrl.height(),
                    scrollablePlotArea: {
                        minWidth: 1000,
                    },
                    events: {
                        load: ctrl.stopLoading,
                        redraw: ctrl.stopLoading,
                    },
                },
                title: {
                    text: null,
                },
                xAxis: {
                    gridLineWidth: 1,
                    maxRange: to.diff(from),
                    opposite: true,
                    type: 'datetime',
                    labels: {
                        style: {
                            textOverflow: 'none',
                            whiteSpace: 'nowrap',
                        },
                        formatter() {
                            return moment(this.value).format('LT');
                        },
                    },
                    tickPositions: hours,
                },
                yAxis: {
                    title: {
                        // on top
                        align: 'high',
                        text: ctrl.yTitle,
                        offset: 32,
                        rotation: 0,
                        y: -10,
                    },
                    categories: ctrl.categories,
                    reversed: ctrl.reversedY,
                },
                tooltip: {
                    useHTML: true,
                    formatter() {
                        const fromTo = t('FROM_TO', {
                            from: this.point.options.timepunch.in.format('L LT'),
                            to: this.point.options.timepunch.out.format('L LT'),
                        });

                        return `<strong style="font-size: 12px">${this.point.yCategory}</strong><br/>${fromTo}<br/>`;
                    },
                },
                series: [
                    {
                        name: 'timepunches',
                        pointWidth: 20,
                        showInLegend: false,
                        data: ctrl.data,
                    },
                ],
            });
        };

        ctrl.updateChart = () => {
            const [ from, to, hours ] = ctrl.getFromToHours();
            return ctrl.chart.getChart().then((chart) => {
                chart.update({
                    chart: {
                        height: ctrl.height(),
                    },
                    xAxis: {
                        tickPositions: hours,
                        minRange: from.diff(to),
                    },
                    yAxis: {
                        categories: ctrl.categories,
                    },
                    series: {
                        data: ctrl.data,
                    },
                });
                // Something seems to go wrong on update on small screens (categories are hidden), rendering again fixes it
                chart.render();

                return chart;
            });
        };

        ctrl.height = () => ctrl.categories.length * 50 + 65;

        ctrl.getFromToHours = () => {
            const from = ctrl.date.clone().startOf('d');
            const to = from.clone().add(1, 'd');

            return [ from, to, ctrl.getHours(from, to) ];
        };

        ctrl.getHours = (from, to) => {
            const hours = [];
            const date = from.clone();

            while (date.isSameOrBefore(to, 'h')) {
                hours.push(date.valueOf());
                date.add(1, 'h');
            }

            return hours;
        };
    },
});
