import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';

@Directive({
    selector: 'mat-icon[eawSize]',
    standalone: true,
})
export class MatIconSizeDirective implements OnInit {
    // Any css size value
    @Input({ required: true }) eawSize!: string;

    constructor(@Inject(ElementRef) private el: ElementRef) {
    }

    ngOnInit(): void {
        this.el.nativeElement.style.fontSize = this.eawSize;
        this.el.nativeElement.style.height = this.eawSize;
        this.el.nativeElement.style.width = this.eawSize;
        this.el.nativeElement.style.minWidth = this.eawSize;
    }
}
