import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { HrFile, HrFileResponse } from '../models/hr-file';
import { DateTime } from 'luxon';
import { FileUploadService } from '../../shared/services/file-upload.service';
import { HttpClient } from '@angular/common/http';
import { FileDownloadService } from '../../shared/services/file-download.service';
import { SignatureProviderType } from '../enums/SignatureProviderType';

interface BaseCreateOptions {
    notify?: boolean;
    warn_days?: number;
    expires_at?: DateTime;
    responsible_id?: number;
    set_existing_as_expired?: boolean;
}

interface FileCreateOptions extends BaseCreateOptions {
    type_id: number;
    name: string;
    file: File;
    notify: boolean;
}

interface DefaultFileCreateOptions extends BaseCreateOptions {
    default_file_id: number;
}

interface CreateManyOnOneOptions extends BaseCreateOptions {
    default_file_ids: number[];
}

type CreateManyOptions = (FileCreateOptions | DefaultFileCreateOptions) & {
    employee_ids: number[];
};

@Injectable({
    providedIn: 'root',
})
export class HrFileService {

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(FileUploadService) private fileUploadService: FileUploadService,
        @Inject(FileDownloadService) private fileDownload: FileDownloadService,
    ) {
    }

    getAll(customerId: number, employeeId: number, options?: PaginationOptions): Observable<ArrayPaginatedResponse<HrFile>> {
        return this.http.get<ArrayPaginatedResponse<HrFileResponse>>(`/customers/${customerId}/employees/${employeeId}/hr_files`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(HrFile));
    }

    get(customerId: number, employeeId: number, fileId: number): Observable<HrFile> {
        return this.http.get<HrFileResponse>(`/customers/${customerId}/employees/${employeeId}/hr_files/${fileId}`).pipe(classifyItem(HrFile));
    }

    createFile(customerId: number, employeeId: number, options: FileCreateOptions): Observable<HrFile> {
        return this.fileUploadService.upload<HrFileResponse>(`/customers/${customerId}/employees/${employeeId}/hr_files`, options as Record<string, any>).pipe(
            classifyItem(HrFile),
        );
    }

    createDefaultFile(customerId: number, employeeId: number, options: DefaultFileCreateOptions): Observable<HrFile> {
        return this.http.post<HrFileResponse>(`/customers/${customerId}/employees/${employeeId}/hr_files`, {
            ...options,
        }).pipe(classifyItem(HrFile));
    }

    createManyOnOne(customerId: number, employeeId: number, options: CreateManyOnOneOptions) {
        return this.http.post(`/customers/${customerId}/employees/${employeeId}/hr_files`, {
            ...options,
        });
    }

    createMany(customerId: number, options: CreateManyOptions) {
        return this.fileUploadService.upload(`/customers/${customerId}/hr_files`, options as Record<string, any>);
    }

    update(customerId: number, employeeId: number, fileId: number, options: BaseCreateOptions) {
        return this.http.put<HrFileResponse>(`customers/${customerId}/employees/${employeeId}/hr_files/${fileId}`, options).pipe(classifyItem(HrFile));
    }

    delete(customerId: number, employeeId: number, fileId: number): Observable<null> {
        return this.http.delete<null>(`/customers/${customerId}/employees/${employeeId}/hr_files/${fileId}`);
    }

    download(customerId: number, employeeId: number, fileId: number, fileName: string): Observable<Blob> {
        return this.fileDownload.download(`/customers/${customerId}/employees/${employeeId}/hr_files/${fileId}/download`, fileName);
    }

    saveFormFields(customerId: number, employeeId: number, fileId: number, data: Record<string, any>) {
        return this.http.post(`/customers/${customerId}/employees/${employeeId}/hr_files/${fileId}/form_fields`, data);
    }

    sign(customerId: number, employeeId: number, fileId: number) {
        return this.http.post(`/customers/${customerId}/employees/${employeeId}/hr_files/${fileId}/signatures`, {
            sign: 1,
            provider: SignatureProviderType.DocumentService,
        });
    }

    getRaw(customerId: number, employeeId: number, id: number): Observable<string> {
        return this.http.request('GET', `/customers/${customerId}/employees/${employeeId}/hr_files/${id}/raw`, {
            observe: 'body',
            responseType: 'text',
        });
    }
}
