import { isObject, keyBy } from 'lodash-es';
import WidgetGrid, { GridHeight, GridSize, GridWidth } from './widget-grid.factory';
import { Namespace } from '../../shared/enums/namespace';
import { Products } from '../../shared/enums/products';
import { PredefinedSettings } from '../../shared/enums/predefined-settings';

export interface WidgetInfoObject {
    name: string;
    icon: string;
    component: string;
    i18name: string;
    products: string[];
    namespaces: string[];
    requiresEmployee: boolean;
    requiredSettings?: Record<string, string>;
    permissions: string[];
    somePermissions?: string[];
    mini?: boolean;
    default?: boolean;
    disabled?: boolean;
    grid?: GridSize;
    empty?: { icon: string, text: string };
    settingsDialog?: string;
    multiple?: boolean;
    settings?: Record<string, any>;
    excludeFromSetup?: boolean;
    hasRequirements?: boolean;
    fullSize?: boolean;
    route?: string;
    noLoading?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class WidgetInfo {
    /*
    *****************************************
    **  NAME IS THE KEY/ID DONT TOUCH!!! :))
    **  https://www.youtube.com/watch?v=otCpCn0l4Wo
    *****************************************
     */
    static minis: WidgetInfoObject[] = [
        {
            name: 'vacation_days',
            icon: 'flight_takeoff',
            multiple: false,
            component: 'vacation-days-mini-widget',
            i18name: 'widgets:VACATION_DAYS',
            products: [ Products.Vacation ],
            namespaces: [
                Namespace.Vacation,
            ],
            requiresEmployee: true,
            permissions: [
                'customers.{customer}.employees.{employee}.vacation_days.*.get',
            ],
        },
        {
            name: 'working_employees',
            icon: 'group',
            multiple: true,
            component: 'mini-working-employees-widget',
            i18name: 'widgets:WORKING_EMPLOYEES_WIDGET',
            settingsDialog: 'workingEmployeesSettings',
            products: [ 'Scheduling', 'Payroll' ],
            namespaces: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Availabilities,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Company,
                Namespace.Payroll,
                Namespace.Absences,
            ],
            requiresEmployee: false,
            permissions: [
                'customers.{customer}.schedules.*.shifts.*.get',
                'customers.{customer}.employees.*.timepunches.*.get',
            ],
        },
        {
            name: 'employee_alerts',
            component: 'employee-alerts-widget',
            icon: 'notifications',
            i18name: 'widgets:EMPLOYEE_ALERTS',
            noLoading: true,
            excludeFromSetup: true,
            products: [ 'payroll' ],
            namespaces: [ Namespace.Payroll, Namespace.Absences ],
            requiresEmployee: false,
            permissions: [ 'customers.{customer}.alerts.*.get' ],
        },
        {
            name: 'balances',
            icon: 'balance',
            multiple: true,
            component: 'balances-widget',
            i18name: 'widgets:BALANCES',
            settingsDialog: 'miniBalancesSettings',
            products: [ Products.Payroll, Products.Switzerland ],
            namespaces: [ Namespace.Payroll ],
            requiresEmployee: true,
            permissions: [
                'customers.{customer}.employees.{employee}.balances.*.*.get',
                'customers.{customer}.balance_types.*.get',
            ],
            settings: {
                type: '',
                time: 'year',
            },
        },
        {
            name: 'hours_worked',
            icon: 'schedule',
            multiple: true,
            component: 'mini-hours-worked-widget',
            i18name: 'widgets:HOURS_WORKED_WIDGET',
            settingsDialog: 'miniHoursWorkedSettings',
            products: [ 'Payroll' ],
            namespaces: [ Namespace.Payroll, Namespace.Absences ],
            requiresEmployee: true,
            permissions: [ 'customers.{customer}.employees.{employee}.timepunches.*.get' ],
            settings: {
                month: 'this',
                // Default to paid time, so it works as it used to if the customer has the product.
                basis: null,
            },
        },
        {
            name: 'mini_kpi',
            icon: 'insights',
            multiple: true,
            component: 'mini-kpi-widget',
            i18name: 'widgets:KPI_WIDGET',
            excludeFromSetup: true,
            settingsDialog: 'miniKpiSettings',
            products: [ 'key performance indicators' ],
            namespaces: [ Namespace.KPI, Namespace.KPITypes ],
            requiresEmployee: false,
            permissions: [
                'customers.{customer}.kpis.*.get',
                'customers.{customer}.kpi_types.*.get',
            ],
        },
        {
            name: 'weather',
            icon: 'sunny',
            component: 'weather-widget',
            i18name: 'widgets:WEATHER_WIDGET',
            settingsDialog: 'weatherSettings',
            excludeFromSetup: true,
            products: [ Products.Weather ],
            permissions: [],
            namespaces: [],
            requiresEmployee: false,
            fullSize: true,
            settings: {
                useFahrenheit: false,
            },
        },
        {
            name: 'vacant_shifts',
            icon: 'person_search',
            component: 'vacant-shifts-mini-widget',
            i18name: 'widgets:VACANT_SHIFTS',
            products: [ 'scheduling' ],
            namespaces: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Availabilities,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Company,
            ],
            multiple: true,
            permissions: [ 'customers.{customer}.schedules.*.shifts.*.get' ],
            requiresEmployee: false,
            settingsDialog: 'vacantShftSettings',
            settings: {
                day_offset: 1, // offset to add to current day in the widget.
            },
        },
        {
            name: 'upcoming_event',
            component: 'mini-event-widget',
            i18name: 'widgets:UPCOMING_EVENT',
            icon: 'celebration',
            products: [ 'calendar' ],
            namespaces: [ Namespace.Calendar ],
            requiresEmployee: false,
            permissions: [ 'customers.{customer}.calendar_events.*.get' ],
        },
        {
            name: 'next_shift',
            component: 'next-shift-widget',
            i18name: 'widgets:NEXT_SHIFT',
            icon: 'badge',
            products: [ 'scheduling' ],
            namespaces: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Availabilities,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Company,
            ],
            requiresEmployee: true,
            permissions: [ 'customers.{customer}.employees.{employee}.shifts.*.get' ],
        },
        {
            name: 'shift_sum',
            component: 'shift-sum-mini-widget',
            i18name: 'widgets:SHIFT_SUM',
            icon: 'schedule',
            products: [ 'scheduling' ],
            namespaces: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Availabilities,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Company,
            ],
            requiresEmployee: true,
            permissions: [ 'customers.{customer}.employees.{employee}.shifts.*.get' ],
        },
        {
            name: 'punched_today',
            component: 'punchedToday',
            i18name: 'widgets:PUNCHED_TODAY',
            icon: 'punch_clock',
            products: [ 'scheduling', 'payroll' ],
            namespaces: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Availabilities,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Company,
                Namespace.Payroll,
                Namespace.Absences,
            ],
            requiresEmployee: true,
            permissions: [
                'customers.{customer}.employees.{employee}.shifts.*.get',
                'customers.{customer}.employees.{employee}.timepunches.*.get',
            ],
        },
        {
            name: 'period_lock',
            icon: 'lock',
            component: 'period-lock-widget',
            i18name: 'widgets:PERIOD_LOCK',
            products: [ 'period locks' ],
            namespaces: [],
            requiresEmployee: false,
            permissions: [ 'customers.{customer}.period_locks.*.get' ],
        },
        {
            name: 'mini_messages',
            icon: 'mail',
            component: 'mini-message-widget',
            i18name: 'navigation:MESSAGES',
            products: [],
            namespaces: [ Namespace.Messages ],
            requiresEmployee: false,
            permissions: [],
        },
        {
            name: 'mini_absence',
            multiple: true,
            icon: 'hotel',
            component: 'mini-absence-widget',
            products: [ 'absence' ],
            namespaces: [ Namespace.Absences, Namespace.Vacation, Namespace.AbsenceTypes ],
            requiresEmployee: false,
            permissions: [ 'customers.{customer}.employees.*.absences.*.get' ],
            i18name: 'widgets:ABSENT_EMPLOYEES',
            settingsDialog: 'miniAbsenceSettings',
            settings: {
                day_offset: 1,
            },
        },
        {
            name: 'france_night_worker',
            icon: 'bedtime',
            component: 'nightWorkerWidgetContainer',
            i18name: 'NIGHT_WORKER_plural',
            requiresEmployee: false,
            permissions: [
                'customers.{customer}.employees.*.timepunches.*.get',
                'customers.{customer}.employees.*.get',
                'customers.{customer}.alerts.*.get',
            ],
            products: [ Products.France ],
            namespaces: [],
        },
        {
            name: 'contract_requalification',
            icon: 'description',
            mini: true,
            component: 'contractRequalificationWidgetContainer',
            products: [ Products.France ],
            requiresEmployee: false,
            i18name: 'widgets:CONTRACT_REQUALIFICATION',
            permissions: [ 'customers.{customer}.employees.*.contracts.create', 'customers.{customer}.alerts.*.get' ],
            namespaces: [],
        },
    ];

    static normals: WidgetInfoObject[] = [
        {
            name: 'scheduling_hour_limit',
            component: 'schedulingHourLimitWidget',
            icon: 'schedule',
            i18name: 'widgets:SCHEDULING_HOUR_LIMIT_STATS',
            products: [ 'Scheduling', 'Payroll', 'Scheduling Limits' ],
            namespaces: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Availabilities,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Company,
                Namespace.Payroll,
                Namespace.Absences,
            ],
            requiresEmployee: false,
            permissions: [ 'customers.{customer}.scheduling_limits.*.get' ],
            empty: {
                icon: 'search',
                text: 'widgets:EMPTY_HOUR_LIMITS',
            },
        },
        {
            name: 'timepunch',
            route: 'payroll/my',
            component: 'timepunch-widget',
            icon: 'schedule',
            i18name: 'widgets:TIMEPUNCH_WIDGET',
            products: [ 'payroll' ],
            namespaces: [ Namespace.Payroll, Namespace.Absences ],
            requiresEmployee: true,
            permissions: [ 'customers.{customer}.employees.{employee}.timepunches.*.get' ],
        },
        {
            name: 'punch',
            route: 'payroll/punch_clock',
            icon: 'punch_clock',
            component: 'punch-clock-widget',
            i18name: 'navigation:PUNCH_CLOCK',
            products: [ 'payroll' ],
            empty: {
                text: 'widgets:PUNCH_CLOCK_EMPTY',
                icon: 'person',
            },
            namespaces: [
                Namespace.Payroll,
                Namespace.Absences,
                Namespace.Admin,
                Namespace.Company,
                Namespace.SwissRiskAndCare,
                Namespace.ChainOps,
                Namespace.Digisign,
            ],
            requiresEmployee: false,
            requiredSettings: {
                [PredefinedSettings.PayrollPersonalTpApp]: '1',
            },
            permissions: [
                'customers.{customer}.employees.{employee}.timepunches.*.get',
                'customers.{customer}.employees.{employee}.timepunches.punch',
            ],
        },
        {
            name: 'shifts',
            route: 'schedules/shifts/my-list',
            icon: 'calendar_month',
            component: 'shifts-widget',
            i18name: 'widgets:SHIFTS_WIDGET',
            products: [ 'scheduling' ],
            empty: {
                icon: 'event_available',
                text: 'widgets:NO_UPCOMING_SHIFTS',
            },
            namespaces: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Company,
                Namespace.Absences,
                Namespace.Vacation,
                Namespace.AbsenceTypes,
                Namespace.Availabilities,
            ],
            requiresEmployee: true,
            permissions: [ 'customers.{customer}.employees.{employee}.shifts.*.get' ],
            grid: WidgetGrid.setSize(GridWidth.Normal, GridHeight.Tall),
        },
        {
            name: 'kpi',
            route: 'kpi',
            icon: 'insights',
            component: 'kpi-widget',
            i18name: 'widgets:KPI_WIDGET',
            settingsDialog: 'defaultKpiSettings',
            products: [ 'key performance indicators' ],
            namespaces: [ Namespace.KPI, Namespace.KPITypes ],
            requiresEmployee: false,
            settings: {
                mode: 'table',
                range: 'yesterday',
            },
            empty: {
                icon: 'analytics',
                text: 'widgets:NO_KPI_DATA',
            },
            permissions: [],
            somePermissions: [
                'customers.{customer}.kpis.*.get',
                'customers.{customer}.widgets.kpi',
            ],
            grid: WidgetGrid.setSize(GridWidth.Full, GridHeight.Normal),
        },
        {
            name: 'all_shifts',
            route: 'schedules/shifts/shifts',
            icon: 'calendar_month',
            component: 'all-shifts-widget',
            i18name: 'widgets:ALL_SHIFTS_WIDGET',
            empty: {
                icon: 'event_available',
                text: 'widgets:NO_UPCOMING_SHIFTS',
            },
            products: [ 'scheduling' ],
            namespaces: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Availabilities,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Company,
            ],
            requiresEmployee: false,
            permissions: [ 'customers.{customer}.schedules.*.shifts.*.get' ],
            grid: WidgetGrid.setSize(GridWidth.Normal, GridHeight.Normal),
        },
        {
            name: 'all_shifts_calendar',
            route: 'schedules/shifts/shifts',
            icon: 'calendar_month',
            component: 'all-shifts-calendar-widget',
            i18name: 'widgets:ALL_SHIFTS_WIDGET_CALENDAR',
            products: [ 'scheduling' ],
            namespaces: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Availabilities,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Company,
            ],
            requiresEmployee: false,
            permissions: [ 'customers.{customer}.schedules.*.shifts.*.get' ],
            grid: WidgetGrid.setSize(GridWidth.Normal, GridHeight.Normal),
        },
        {
            name: 'available_shifts',
            route: 'schedules/shifts/available_shifts',
            icon: 'free_cancellation',
            component: 'available-shifts-widget',
            i18name: 'widgets:AVAILABLE_SHIFTS',
            empty: {
                icon: 'event_available',
                text: 'scheduling:NO_AVAILABLE_SHIFTS',
            },
            products: [ 'scheduling' ],
            namespaces: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Availabilities,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Company,
            ],
            requiresEmployee: true,
            permissions: [ 'customers.{customer}.shift_swaps.*.get' ],
            grid: WidgetGrid.setSize(GridWidth.Normal, GridHeight.Normal),
        },
        {
            name: 'postits',
            icon: 'sticky_note_2',
            component: 'postits-widget',
            i18name: 'widgets:POST_IT_WIDGET',
            products: [ 'postits' ],
            namespaces: [],
            empty: {
                icon: 'draw',
                text: 'widgets:EMPTY_POSTIT_BOARD',
            },
            permissions: [ 'customers.{customer}.postits.*.get' ],
            requiresEmployee: false,
            grid: WidgetGrid.setSize(GridWidth.Normal, GridHeight.Normal),
        },
        {
            name: 'flexitime',
            route: 'payroll/my/flexitime',
            icon: 'more_time',
            component: 'flexitime-widget',
            i18name: 'widgets:FLEXITIME_WIDGET',
            empty: {
                icon: 'punch_clock',
                text: 'widgets:NO_FLEXI_BALANCE',
            },
            products: [ 'flexitime' ],
            namespaces: [ Namespace.Payroll, Namespace.Absences ],
            requiresEmployee: true,
            permissions: [ 'customers.{customer}.employees.{employee}.flexitime.*.get' ],
        },
        {
            name: 'todos',
            route: 'todo/my',
            icon: 'assignment_add',
            component: 'todo-widget',
            i18name: 'widgets:TODO_WIDGET',
            empty: {
                icon: 'event_available',
                text: 'widgets:NO_TODOS',
            },
            requiresEmployee: false,
            permissions: [
                'customers.{customer}.users.{user}.todo_items.*.get',
                'customers.{customer}.todo_statuses.*.get',
            ],
            products: [ 'todo' ],
            namespaces: [ Namespace.Todo ],
        },
        {
            name: 'standby',
            icon: 'person_search',
            component: 'standby-widget',
            i18name: 'widgets:STANDBY',
            requiresEmployee: false,
            permissions: [ 'customers.{customer}.employees.{employee}.standbys.*.get' ],
            products: [ 'scheduling' ],
            namespaces: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Availabilities,
                Namespace.Admin,
                Namespace.Company,
                Namespace.SwissRiskAndCare,
                Namespace.ChainOps,
                Namespace.Digisign,
                Namespace.Messages,
            ],
        },
        {
            name: 'newsletter',
            route: 'newsletter',
            icon: 'feed',
            component: 'newsletter-widget',
            i18name: 'newsletter:NEWSLETTER_plural',
            // settingsDialog: 'defaultNewsletterSettings', TODO in the future
            // settings: {
            //     newsletter_id: 0,
            // },
            empty: {
                icon: 'newspaper',
                text: 'newsletter:NO_NEWSLETTER',
            },
            requiresEmployee: false,
            permissions: [ 'customers.{customer}.newsletters.*.issues.*.get' ],
            products: [ 'Newsletter' ],
            namespaces: [ Namespace.Newsletter ],
        },
        {
            name: 'upcoming_birthdays',
            icon: 'cake',
            component: 'upcomingBirthdaysWidgetContainer',
            i18name: 'widgets:UPCOMING_BIRTHDAYS',
            settingsDialog: 'upcomingBirthdaysWidgetSettingsDowngrade',
            products: [],
            namespaces: [],
            requiresEmployee: false,
            permissions: [],
            somePermissions: [ 'customers.{customer}.employees.*.get', 'customers.{customer}.links.*.get' ],
        },
    ];

    static getAll(): Record<string, WidgetInfoObject> {
        const minis = this.minis.map((w) => {
            w.settings = {
                backgroundColor: `#fff`,
                ...w.settings,
            };

            return {
                ...w,
                mini: true,
                grid: WidgetGrid.setSize(GridWidth.Mini, GridHeight.Mini),
            };
        });

        const normals = this.normals.map((w) => {
            w.grid = !isObject(w.grid) ? WidgetGrid.setSize() : w.grid;
            w.settings = {
                backgroundColor: '#fff',
                headerColor: '#5a626a',
                ...w.settings,
            };

            return w;
        });

        return {
            ...keyBy(minis, (w) => w.name),
            ...keyBy(normals, (w) => w.name),
        };
    }

    static get(name: string): undefined | WidgetInfoObject {
        return this.getAll()[name];
    }
}
