// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

const template1 = `<md-slider-container ng-show="$manage.split">
    <span>%</span>
    <md-slider min="0" max="100" step="5" ng-model="$manage.percentage" ng-change="$manage.updateModel()"></md-slider>
    <span ng-bind="$manage.percentage"></span>
</md-slider-container>

<md-radio-group ng-model="$manage.action" required ng-change="$manage.updateModel()">
    <md-radio-button ng-repeat="(key, action) in $manage.actions" ng-value="action">
        <span ng-bind="action.text"></span>
    </md-radio-button>
</md-radio-group>

<employee-autocomplete ng-if="$manage.action.requiresEmp"
                       name="employee"
                       ng-model="$manage.employee"
                       required
                       include-external="true"
                       ng-change="$manage.onEmployeeChange()"
                       from="$manage.availableInterval.from"
                       to="$manage.availableInterval.to">
</employee-autocomplete>
`;

module('eaw.scheduling').component('splitShiftsManage', {
    template: template1,
    controllerAs: '$manage',
    bindings: {
        split: '<',
        availableInterval: '<',
    },
    require: {
        splitShifts: '^splitShifts',
        ngModel: 'ngModel',
    },
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.actions = {
                keep: {
                    name: 'keep',
                    text: t('scheduling:KEEP'),
                },
                reassign: {
                    name: 'give_away',
                    text: t('scheduling:REASSIGN'),
                    requiresEmp: true,
                },
                unassign: {
                    name: 'give_away',
                    text: t('scheduling:UNASSIGN'),
                },
                delete: {
                    name: 'delete',
                    text: t('scheduling:DELETE'),
                },
            };

            ctrl.ngModel.$formatters.unshift(ctrl.formatter);

            ctrl.onSplitChange();
            ctrl.updateModel();
        };

        ctrl.$onDestroy = () => {
            ctrl.ngModel.$setViewValue(null);
        };

        ctrl.$onChanges = (changes) => {
            if (changes.split) {
                ctrl.split = changes.split.currentValue;
                ctrl.onSplitChange();
            }

            if (changes.availableInterval) {
                delete ctrl.employee;
                ctrl.availableInterval = changes.availableInterval.currentValue;
            }

            ctrl.updateModel();
        };

        ctrl.onEmployeeChange = () => {
            ctrl.actions.reassign.text = t('scheduling:REASSIGN');

            if (ctrl.employee) {
                ctrl.actions.reassign.text = t('scheduling:REASSIGN_TO', { name: ctrl.employee.name });
            }

            ctrl.updateModel();
        };

        ctrl.onSplitChange = () => {
            ctrl.percentage = ctrl.split ? 50 : 100;
        };

        ctrl.formatter = (val) => {
            ctrl.percentage = val?.percentage ?? ctrl.percentage;
            return val;
        };

        ctrl.updateModel = () => {
            ctrl.ngModel.$setViewValue({
                percentage: ctrl.percentage,
                employee: ctrl.employee,
                action: ctrl.action,
            });
        };
    },
});
