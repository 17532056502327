// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-list>
    <md-list-item class="md-3-line" ng-repeat="shift in ::$ctrl.shifts track by shift.id" ng-click="$ctrl.go(shift)">
        <div class="md-list-item-text">
            <h3 ng-bind="shift.next"></h3>
            <h4 ng-bind="shift.fromTo"></h4>
            <p ng-bind="shift.schedule.customer.name"></p>
        </div>

        <md-button class="md-secondary md-icon-button">
            <md-icon ng-bind="'open_in_new'"></md-icon>
        </md-button>
    </md-list-item>
</md-list>
`;
module('eaw.dashboard').component('nextShiftWidgetList', {
    template: template1,
    bindings: {
        shifts: '<',
    },
    controller: [ '$state', '$mdDialog', function($state, $mdDialog) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.shifts?.forEach((s) => {
                s.next = s.from.fromNow();
                s.fromTo = `${s.from.format('LLLL')} - ${s.to.format('LT')}`;
            });
        };
        ctrl.go = (shift) => {
            const route = 'eaw/app/scheduling/shifts/shift';
            $state.transitionTo(route, { id: shift.id, customer_id: shift.schedule?.customer_id });
            $mdDialog.hide();
        };
    } ],
});
