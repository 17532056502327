// @ts-nocheck
import { module } from 'angular';
module('eaw.theme', [ 'ngMaterial' ]).config([ '$mdThemingProvider', ($mdThemingProvider) => {
    const properties = [ 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 'A100', 'A200', 'A400', 'A700' ];
    function getColors(palette) {
        const colors = {};
        return properties.reduce((obj, prop) => {
            const color = getComputedStyle(document.body).getPropertyValue(`--eaw-${palette}-${prop}`).trim().replace('#', '');
            if (color) {
                obj[prop] = color;
            } else {
                console.warn(`Couldn't find palette color`, palette, prop);
                obj[prop] = '#000';
            }
            return obj;
        }, colors);
    }
    function getContrastColors(palette, color) {
        return properties.map((prop) => {
            // The color property in our styles can be default black or white, or minified to a hex
            const styleProperty = getComputedStyle(document.body).getPropertyValue(`--eaw-${palette}-${prop}-contrast`).trim().toLowerCase();
            let contrastColor;
            // If property is minified then convert back to black or white or leave it be
            switch (styleProperty) {
                case '#000':
                    contrastColor = 'black';
                    break;
                case '#fff':
                    contrastColor = 'white';
                    break;
                default:
                    contrastColor = styleProperty;
            }
            return {
                property: prop,
                contrastColor,
            };
        }).filter((prop) => prop.contrastColor === color).map((prop) => prop.property.toString());
    }
    // Palettes from LESS file(s)
    // https://material.angularjs.org/latest/Theming/03_configuring_a_theme
    [ 'primary', 'accent' ].forEach((palette) => {
        const defaultContrast = getContrastColors(palette, 'black').length > Math.floor(properties.length / 2) ? 'dark' : 'light';
        const paletteMap = {
            ...getColors(palette),
            contrastDefaultColor: defaultContrast,
            contrastDarkColors: getContrastColors(palette, 'black'),
            contrastLightColors: getContrastColors(palette, 'white'),
        };
        $mdThemingProvider.definePalette(palette, paletteMap);
    });
    // Black
    const blackPalette = {};
    $mdThemingProvider.definePalette('black', {
        ...properties.reduce((obj, p) => {
            obj[p] = '000';
            return obj;
        }, blackPalette),
        contrastDefaultColor: 'light',
    });
    // White
    const whitePalette = {};
    $mdThemingProvider.definePalette('white', {
        ...properties.reduce((obj, p) => {
            obj[p] = 'fff';
            return obj;
        }, whitePalette),
        contrastDefaultColor: 'dark',
    });
    $mdThemingProvider.definePalette('eawRed', $mdThemingProvider.extendPalette('red', {
        contrastDarkColors: [ 50, 100, 200, 300, 400, 'A100', 'A200', 'A400' ],
        contrastLightColors: [ 500, 600, 700, 800, 900, 'A700' ],
    }));
    // App colors
    $mdThemingProvider.theme('default')
        .primaryPalette('primary')
        .accentPalette('accent')
        .warnPalette('eawRed');
    $mdThemingProvider.theme('delete')
        .primaryPalette('eawRed');
    // Enable mobile browser color
    $mdThemingProvider.enableBrowserColor({
        theme: 'default',
        palette: 'primary',
        hue: '500',
    });
} ]);
