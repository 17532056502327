import { Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'eaw-show-html-form',
    standalone: true,
    imports: [],
    template: '<div [innerHTML]="trustedHtml"></div>',
    styleUrl: './show-html-form.component.scss',
})
export class ShowHtmlFormComponent implements OnInit, OnDestroy {
    private readonly domSanitizer = inject(DomSanitizer);
    private readonly elementRef = inject(ElementRef);

    @Input({ required: true }) html!: string;

    @Output() formFilled = new EventEmitter<Record<string, any> | null>();
    @Output() formSubmitted = new EventEmitter<void>();

    protected trustedHtml?: SafeHtml;

    private form?: HTMLFormElement;
    private formContainer!: HTMLElement;
    private mutationObserver?: MutationObserver;
    private eventListeners?: string[];

    ngOnInit() {
        this.formContainer = (this.elementRef.nativeElement as HTMLElement).querySelectorAll('div')[0] as HTMLDivElement;
        this.mutationObserver = new MutationObserver(() => {
            const forms = Array.from(this.formContainer.querySelectorAll('form'));

            if (forms.length === 1) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.form = forms[0]!;
                this.addEventListeners();
            } else {
                console.error(`Found ${forms.length} forms!`);
            }
        });

        this.mutationObserver.observe(this.formContainer, { childList: true });
        this.trustedHtml = this.domSanitizer.bypassSecurityTrustHtml(this.html);
    }

    ngOnDestroy() {
        this.mutationObserver?.disconnect();
        this.eventListeners?.forEach((event: string) => {
            this.formContainer?.removeEventListener(event, this.onChange);
        });
    }

    addEventListeners() {
        this.eventListeners = [
            'keydown',
            'keyup',
            'click',
            'blur',
            'focus',
        ];

        this.eventListeners.forEach((event: string) => {
            this.formContainer?.addEventListener(event, this.onChange.bind(this));
        });

        this.formContainer?.addEventListener('submit', () => {
            this.onChange();
            this.formSubmitted.emit();
        });
    }

    onChange() {
        if (this.form?.checkValidity()) {
            const formData = new FormData(this.form);
            const data: Record<string, FormDataEntryValue> = {};

            // @ts-ignore
            Array.from(formData.entries()).forEach(([ key, value ]) => {
                data[key] = value;
            });

            this.formFilled.emit(data);
        } else {
            this.formFilled.emit(null);
        }
    }
}
