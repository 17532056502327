import { module } from 'angular';
import { CompanyUsersComponent } from '../../pages/users/company-users/company-users.component';
import { CustomerUserComponent } from '../../pages/users/view/company-user/customer-user.component';
import { currentResolver } from '../../../shared/resolvers/current.resolver';
import { createState } from '../../../shared/utils/create-state';
import { DataTableQueryParams } from '../../../data-table/data-table.component';
import { paramResolver } from '../../../shared/resolvers/param.resolver';

module('eaw.company.users', [
    'eaw.resource',
    'eaw.alerts',
    'eaw.login',
    'eaw.customers',
    'eaw.user-groups',
]).config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: `eaw/app/company/users`,
        parent: `eaw/app/company`,
        url: '/users',
        views: {
            'content@': {
                component: CompanyUsersComponent,
            },
        },
        data: {
            queryParams: [ ...DataTableQueryParams, 'filter' ],
            permissions: [ 'customers.{customer}.users.*.get' ],
            breadcrumb: { key: 'USERS', ns: 'navigation' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/company/users/view`,
        parent: `eaw/app/company/users`,
        url: '/:id?:when',
        views: {
            'content@': {
                component: CustomerUserComponent,
            },
        },
        data: {
            permissions: [ 'customers.{customer}.users.{id}.get' ],
            breadcrumb: { key: 'USER' },
        },
        resolve: [
            currentResolver('customer', 'customer'),
            paramResolver('id', 'userId'),
        ],
    });
} ]);
