import { Component, DestroyRef, inject, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ScheduleSummaryService } from '../../http/schedule-summary.service';
import { DateTime } from 'luxon';
import { ScheduleSummary, ScheduleSummaryDay, ScheduleSummarySums } from '../../models/schedule-summary';
import { TranslateService } from '../../../shared/services/translate.service';
import { BackOfficeSyncService } from '../../angularjs/backoffice/sync/back-office-sync-class';
import { SettingService } from '../../../shared/http/setting.service';
import { forkJoin, map, Observable, shareReplay, Subscription } from 'rxjs';
import { WebsocketService } from '../../../shared/services/websocket.service';
import { Moment } from 'moment-timezone';
import { momentToDateTime } from '../../../shared/angularjs/moment-to-date-time';
import { RoundToFractionPipe } from '../../../shared/pipes/round-to-quarter.pipe';
import { PercentPipe } from '../../../shared/pipes/percent.pipe';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { AsyncPipe, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { Namespace } from '../../../shared/enums/namespace';
import { CurrentService } from '../../../shared/services/current.service';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { Products } from '../../../shared/enums/products';

type TotalDay = ScheduleSummarySums & {
    business_date?: string,
    week?: string,
    year?: number,
    groupByKey?: string,
    dateTime?: DateTime,
    shifts: number,
    labor_ratio: number,
    work_hours?: number,
    projected_units?: number,
    projected_transactions?: number,
    // @deprecated
    moment?: any,
};

@Component({
    selector: 'eaw-schedule-total-tab',
    templateUrl: './schedule-total-tab.component.html',
    styleUrl: './schedule-total-tab.component.scss',
    providers: [
        {
            provide: 'scheduleDays',
            useFactory: ($injector: any) => $injector.get('scheduleDays'),
            deps: [ '$injector' ],
        },
        {
            provide: 'BackOfficeSync',
            useFactory: ($injector: any) => $injector.get('BackOfficeSync'),
            deps: [ '$injector' ],
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        NgFor,
        AsyncPipe,
        KeyValuePipe,
        TranslatePipe,
        DateTimePipe,
        NumberPipe,
        PercentPipe,
        RoundToFractionPipe,
    ],
})
export class ScheduleTotalTabComponent implements OnInit, OnDestroy {
    private readonly destroyRef = inject(DestroyRef);
    private readonly translate = inject(TranslateService);
    private readonly currentService = inject(CurrentService);
    private readonly settingService = inject(SettingService);
    private readonly websocketService = inject(WebsocketService);
    private readonly scheduleSummaryService = inject(ScheduleSummaryService);
    private readonly customerProductService = inject(CustomerProductService);

    @Input() schedule!: { id: number, customer_id: number };

    protected subscriptions: Subscription[] = [];

    private efficiencyFactor = 1;
    private gettingSetting!: Observable<number>;
    private getNeedSetting!: Observable<boolean>;

    isFrance = false;
    showNeed?: boolean;
    loading = false;
    weeks?: Record<string, TotalDay[]>;
    waitForJob = false;
    summary?: ScheduleSummary;
    lastUpdated?: string;
    lastDispatch?: string;

    constructor(
        @Inject('scheduleDays') private scheduleDays: any,
        @Inject('BackOfficeSync') private BackOfficeSync: BackOfficeSyncService,
    ) {
    }

    ngOnInit(): void {
        this.websocketService.listenSchedule(this.schedule.customer_id, this.schedule.id, 'summary_calculated', (event) => {
            if (event.business_date) {
                return;
            }

            this.getSummary();
        }, this.destroyRef);

        this.subscriptions.push(this.BackOfficeSync.subject.subscribe(this.getSummary.bind(this)));
        this.gettingSetting = this.settingService.getValue(
            [ 'customers', this.schedule.customer_id ],
            'scheduling.budget_efficiency_factor',
            1,
        ).pipe(shareReplay(1));
        this.getNeedSetting = this.settingService.getValue(
            [ 'customers', this.schedule.customer_id ],
            'scheduling.update_schedule_summary.should_calculate_needs',
            true,
        ).pipe(shareReplay(1));

        this.subscriptions.push(this.gettingSetting.subscribe((value) => {
            this.efficiencyFactor = value;
        }));

        this.getSummary();
        this.setIsFrance();
    }

    ngOnDestroy() {
        this.subscriptions.map((s?: Subscription) => s?.unsubscribe());
    }

    setIsFrance() {
        const customer = this.currentService.getCustomer();

        forkJoin([
            this.customerProductService.hasProducts(customer.id, [ Products.France ]),
            this.settingService.getValue([ 'customers', this.schedule.customer_id ], 'scheduling.display_projections', false),
        ]).subscribe(([ hasFrance, setting ]) => {
            this.isFrance = customer.countryCode?.toLowerCase() === 'fr' && hasFrance && setting;
        });
    }

    getSummary() {
        // Reset, show spinner
        this.weeks = undefined;
        this.loading = true;

        const subscription = forkJoin([
            this.scheduleSummaryService.get(this.schedule.customer_id, this.schedule.id),
            this.getNeedSetting,
        ]).pipe(
            map(([ summary, showNeed ]) => {
                this.showNeed = showNeed;

                return summary;
            }),
        ).subscribe(this.onSummary.bind(this));
        this.subscriptions.push(subscription);
    }

    private momentToDateTime(m: Moment) {
        return momentToDateTime(m);
    }

    onSummary(summary: ScheduleSummary) {
        this.summary = summary;
        this.loading = false;
        this.waitForJob = summary.sums == null;

        void this.updateTimestamps();

        if (this.waitForJob) {
            return;
        }

        const subscription = this.gettingSetting.subscribe(() => {
            const days: TotalDay[] = this.scheduleDays.getAll().map((day: TotalDay) => {
                const businessDate = day.moment.format('YYYY-MM-DD');
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const summaryDay: ScheduleSummaryDay = summary.days!.get(businessDate)!;

                return {
                    business_date: businessDate,
                    week: day.moment.format('WW'),
                    year: day.moment.weekYear(),
                    groupByKey: day.moment.format('GGGG-WW'),
                    dateTime: this.momentToDateTime(day.moment),
                    // overwrite shifts from this object, but nothing else
                    ...summaryDay,
                    shifts: summaryDay?.shifts?.length || 0,
                } as TotalDay;
            });
            const weeks: Record<string, TotalDay[]> = {};

            days.map((d) => {
                const key = d.groupByKey || '';

                weeks[key] ||= [];
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                weeks[key]!.push(d);
            });
            Object.values(weeks).map((week) => this.createWeekTotal(week));

            //  total row at the end
            weeks['9999-99'] = [ {
                shifts: 0,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...summary.sums!,
            } ];

            this.weeks = weeks;
        });

        this.subscriptions.push(subscription);
    }

    createWeekTotal(week: TotalDay[]) {
        if (!Array.isArray(week)) {
            return;
        }

        const newLength = week.push({
            budget: week.reduce((sum, x) => sum + (x.budget ?? 0), 0),
            efficiency: 0, // Calc later
            labor_cost: week.reduce((sum, x) => sum + x.labor_cost, 0),
            labor_ratio: 0, // Calc later
            work_time: week.reduce((sum, x) => sum + x.work_time, 0),
            need: week.reduce((sum, x) => sum + x.need, 0),
            shifts: week.reduce((sum, x) => sum + x.shifts, 0),
            need_diff: week.reduce((sum, x) => sum + x.need_diff, 0),
            units_sold_per_employee: 0, // Calc later
            transactions_per_employee: 0, // Calc later
            projected_units: week.reduce((sum, x) => sum + (x.projected_units || 0), 0),
            projected_transactions: week.reduce((sum, x) => sum + (x.projected_transactions || 0), 0),
            work_hours: week.reduce((sum, x) => sum + (x.work_hours || 0), 0),
        });

        const lastWeek = week[newLength - 1];
        if (!lastWeek) {
            return;
        }

        // Don't multiply with 100 since we are using percent filter in table
        lastWeek.labor_ratio = lastWeek.budget ? lastWeek.labor_cost / lastWeek.budget : 0;
        // Efficiency is budget / work time
        // Work time has to be converted to hours
        lastWeek.efficiency = (lastWeek.budget ? lastWeek.budget / (lastWeek.work_time / 3600) : 0) * this.efficiencyFactor;
        // For Unit sold per employee = sum of projected units over the week / worked hours over the week
        lastWeek.units_sold_per_employee = (lastWeek.projected_units && lastWeek.work_hours) ? lastWeek.projected_units / lastWeek.work_hours : 0;
        // For Transaction per employee = sum of projected transactions over the week / worked hours over the week
        lastWeek.transactions_per_employee = (lastWeek.projected_transactions && lastWeek.work_hours) ? lastWeek.projected_transactions / lastWeek.work_hours : 0;
    }

    async updateTimestamps() {
        this.lastDispatch = undefined;
        this.lastUpdated = undefined;

        if (this.summary?.lastDispatch && (!this.summary?.createdAt || this.summary.createdAt < this.summary.lastDispatch)) {
            this.lastDispatch = await this.translate.t('GENERATING_TOTAL_PLEASE_WAIT', Namespace.Scheduling, {
                time: this.summary.lastDispatch.toRelative({
                    padding: 500,
                    round: true,
                }),
            });
        }

        if (this.summary?.createdAt) {
            this.lastUpdated = await this.translate.t('SCHEDULE_SUMMARY_CALCULATED_AT', Namespace.Scheduling, {
                time: this.summary.createdAt.toRelative({
                    padding: 500,
                    round: true,
                }),
            });
        }
    }
}
