// @ts-nocheck
import { set } from 'lodash-es';
import { module } from 'angular';
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="('fixed_labor_cost:NEW_FIXED_LABOR_COST' | i18next)"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="laborCostForm" id="laborCostForm" ng-cloak ng-submit="$dialogCtrl.submit()" autocomplete="off">
                <md-input-container>
                    <label for="name" ng-i18next="NAME"></label>
                    <input type="text" id="name" name="name" ng-model="$dialogCtrl.cost.name" required>
                </md-input-container>

                <md-input-container>
                    <label for="value" ng-i18next="fixed_labor_cost:VALUE"></label>
                    <input type="number" id="value" name="value" ng-model="$dialogCtrl.cost.value" required>
                </md-input-container>

                <md-input-container>
                    <label for="period" ng-i18next="fixed_labor_cost:PERIOD"></label>
                <md-select id="period" ng-model="$dialogCtrl.cost.period" required>
                    <md-option ng-repeat="period in $dialogCtrl.periods track by $index" ng-value="period">
                        <span ng-bind="::period.toUpperCase() | i18next"></span>
                    </md-option>
                </md-select>
                </md-input-container>

                <eaw-date-interval layout="row" ng-model="$dialogCtrl.interval" from-required="true"></eaw-date-interval>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button form="laborCostForm" type="submit" ng-i18next="SUBMIT" ng-disabled="laborCostForm.$invalid"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
/**
 * @ngdoc service
 * @name fixedLaborDialog
 */
module('eaw.fixedLabor').factory('fixedLaborDialog', [ '$mdDialog', 'FixedLabor', 'deleteItemAlert', function fixedLaborDialog($mdDialog, FixedLabor, deleteItemAlert) {
    function open(cost) {
        return $mdDialog.show({
            template: template1,
            bindToController: true,
            locals: {
                cancel: $mdDialog.cancel,
            },
            controllerAs: '$dialogCtrl',
            controller: function() {
                const ctrl = this;
                ctrl.$onInit = () => {
                    ctrl.periods = [ 'year', 'month', 'week', 'day' ];
                    ctrl.cost = { ...cost };
                    ctrl.interval = {
                        from: cost?.from?.clone(),
                        to: cost?.to?.clone(),
                    };
                };
                ctrl.submit = () => {
                    if (ctrl.interval?.from) {
                        set(ctrl, 'cost.from', ctrl.interval.from.startOf('d'));
                    }
                    if (ctrl.interval?.to) {
                        set(ctrl, 'cost.to', ctrl.interval.to.endOf('d'));
                    }
                    $mdDialog.hide(ctrl.cost);
                };
            },
        });
    }
    return {
        /**
         * @memberOf fixedLaborDialog
         * @param {int} customerId
         * @returns {*}
         */
        add(customerId) {
            return new Promise((resolve, reject) => {
                open().then((result) => {
                    FixedLabor.create.query(customerId, result).$promise.then(resolve, reject);
                }, reject);
            });
        },
        /**
         * @memberOf fixedLaborDialog
         * @param {int}  customerId
         * @param {Object} cost
         * @returns {*}
         */
        edit(customerId, cost) {
            return new Promise((resolve, reject) => {
                open(cost).then((result) => {
                    FixedLabor.update.query(customerId, cost.id, result).$promise.then(resolve, reject);
                }, reject);
            });
        },
        /**
         * @memberOf fixedLaborDialog
         * @param {int} customerId
         * @param {int} fixedLaborCostId
         * @returns {*}
         */
        delete(customerId, fixedLaborCostId) {
            return new Promise((resolve, reject) => deleteItemAlert.alertSimple().then(() => {
                FixedLabor.delete.query(customerId, fixedLaborCostId).$promise.then(resolve, reject);
            }, reject));
        },
    };
} ]);
