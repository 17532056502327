// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';
module('eaw.resource').controller('eawErrorDialogCtrl', [ '$mdDialog', 'clipboardService', function($mdDialog, clipboardService) {
    const ctrl = this;
    ctrl.$onInit = () => {
        ctrl.errors = [];
        ctrl.hashes = Object.keys(ctrl.response?.data?.error || []);
        ctrl.title = ctrl.response?.statusText || t('ERROR');
        // Handle based on status code, or else just default
        // Must handle before setting resendable
        const statusFunc = ctrl[`handle${ctrl.response?.status}`];
        if (typeof statusFunc === 'function') {
            statusFunc();
        } else {
            ctrl.handleDefault(t('errors:SOMETHING_WENT_WRONG'));
        }
        // Whether or not this request contains errors that can be ignored or not and therefore can be resent
        ctrl.resendable = ctrl.errors.length && ctrl.errors.every((e) => e.ignorable);
    };
    ctrl.everythingIgnored = () => ctrl.errors.every((e) => e.ignored);
    ctrl.copy = () => {
        const fullError = [
            { message: `Title - ${ctrl.title}` },
            { message: `Status - ${ctrl.response.status}` },
            { message: `Method - ${ctrl.response.config.method}` },
            ...ctrl.errors,
        ].reduce((msg, err) => `${msg}${err?.message}\r\n`, '');
        clipboardService.copy(fullError);
    };
    ctrl.addErrorMessage = (msg, ignorable) => {
        ctrl.errors.push({
            message: msg,
            ignorable: !!ignorable,
        });
    };
    ctrl.handleDefault = (defaultText) => {
        const data = ctrl.response?.data;
        // In order of priority (most important first)
        ctrl.addErrorMessage(data?.message || data?.error || defaultText);
    };
    ctrl.handle403 = () => {
        ctrl.handleDefault(t('errors:403_ERROR'));
    };
    ctrl.handle422 = () => {
        if (ctrl.response?.data?.type?.toLowerCase?.() === 'multiple') {
            ctrl.handleMultiple422();
        } else if (ctrl.response?.data?.message || ctrl.response?.data?.error) {
            ctrl.handleDefault();
        } else {
            ctrl.handleNormal422();
        }
    };
    ctrl.handleMultiple422 = () => {
        ctrl.hashes.forEach((hash) => {
            ctrl.response?.data?.error?.[hash]?.forEach((e) => {
                ctrl.addErrorMessage(e.message, e.ignorable);
            });
        });
    };
    ctrl.handleNormal422 = () => {
        Object.values(ctrl.response?.data || {}).forEach((msgs) => {
            msgs?.forEach?.((msg) => ctrl.addErrorMessage(msg));
        });
    };
    ctrl.ignore = () => {
        $mdDialog.hide(ctrl.hashes);
    };
} ]);
