// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-fab-speed-dial md-direction="up" class="md-scale" ng-cloak>
    <md-fab-trigger>
        <md-button class="md-primary md-fab" aria-label="menu">
            <md-icon ng-bind="'menu'"></md-icon>
        </md-button>
    </md-fab-trigger>

    <md-fab-actions>
        <div ng-repeat="bar in ::$mobSidebar.sidebar.sidebars track by $index" ng-if="bar.show">
            <md-button 
                class="md-fab md-raised md-mini" 
                ng-click="$mobSidebar.open(bar)"
                eaw-permission
                permission-children-filter="bar.permissionChildrenFilter">
                <md-icon ng-bind="bar.icon" md-colors="{color: bar.color}"></md-icon>
            </md-button>
        </div>

        <div ng-repeat="button in ::$mobSidebar.sidebar.buttons track by $index" ng-if="button.show">
            <md-button 
                class="md-fab md-raised md-mini" 
                ng-click="button.callback()"
                eaw-permission
                permission-children-filter="button.permissionChildrenFilter">
                <md-icon ng-bind="button.icon" md-colors="{color: button.color}"></md-icon>
            </md-button>
        </div>
    </md-fab-actions>
</md-fab-speed-dial>
`;

module('eaw.scheduling').component('mobileScheduleSidebar', {
    template: template1,
    require: {
        scheduleView: '^scheduleView',
    },
    controllerAs: '$mobSidebar',
    controller: [ 'ScheduleSidebar', 'scheduleMode', function(ScheduleSidebar, scheduleMode) {
        const ctrl = this;

        ctrl.$postLink = () => {
            ctrl.sidebar = new ScheduleSidebar(ctrl.scheduleView.schedule, scheduleMode.getMode() !== 'read-only');
        };

        ctrl.open = (sidebar) => {
            ctrl.scheduleView.openSmallSidenav(sidebar);
        };
    } ],
});
