import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogData, DialogSize } from '../dialog-component';
import { PdfRendererDialogComponent } from './pdf-renderer-dialog.component';
import { Observable } from 'rxjs';

export interface PdfRendererDialogData extends DialogData {
    url: Observable<string>;
}

@Injectable({
    providedIn: 'root',
})
export class PdfRendererDialogService {

    constructor(@Inject(MatDialog) private dialog: MatDialog) {
    }

    open(options: PdfRendererDialogData): MatDialogRef<PdfRendererDialogComponent> {
        return this.dialog.open<PdfRendererDialogComponent, PdfRendererDialogData>(PdfRendererDialogComponent, {
            data: {
                size: DialogSize.XLarge,
                ...options,
            },
        });
    }
}
