// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.checklists').service('startChecklistDialog', [ '$mdDialog', 'ChecklistFactory', '$state', function($mdDialog, ChecklistFactory, $state) {
    return {
        /**
         *
         * @param {object}      template
         * @returns {Promise}               - Returns the checklist that's created
         */
        open(template) {
            return new Promise((resolve, reject) => { // Use a Q so we know when it's all done
                const duplicatedItem = Object.assign({}, template);

                const createPrompt = $mdDialog.prompt()
                    .title(t('checklists:CREATE_CHECKLIST_QUESTION'))
                    .textContent(t('checklists:CREATE_CHECKLIST_TEXT', { name: duplicatedItem.title }))
                    .placeholder(t('NAME'))
                    .ariaLabel(t('NAME'))
                    .initialValue(duplicatedItem.title)
                    .required(true)
                    .ok(t('CREATE'))
                    .cancel(t('CANCEL'))
                    .multiple(true);

                $mdDialog.show(createPrompt).then((result) => {
                    duplicatedItem.template_id = duplicatedItem.id; // Set template as this item's id
                    duplicatedItem.is_template = undefined; // Not a template
                    duplicatedItem.title = result; // Title

                    ChecklistFactory.create(CurrentOld.customer, duplicatedItem).$promise.then((data) => {
                        resolve(undefined); // Created checklist, so we can now resolve

                        const goDialog = $mdDialog.confirm()
                            .title(t('checklists:GO_QUESTION'))
                            .textContent(t('checklists:CHECKLIST_FROM_TEMPLATE', {
                                name: data.title,
                                template: template.title,
                            }))
                            .ok(t('GO'))
                            .cancel(t('STAY'));

                        $mdDialog.show(goDialog).then(() => {
                            $state.go(`eaw/app/checklists/view`, { id: data.id });
                        });
                    }).catch(() => reject());
                }).catch(() => reject());
            });
        },
    };
} ]);
