// @ts-nocheck
import { pick } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { Model } from '../shared/angularjs/model';
module('eaw.tbs').factory('PunchProposal', [ 'EawResource', 'Pagination', function PunchProposalFactory(EawResource, Pagination) {
    const url = 'customers/:customer/employee/:employee/punch_proposals/:proposal';
    class PunchProposal extends Model {
        static contexts;
        date;
        in;
        out;
        context;
        employee_id;
        customer_id;
        constructor(data) {
            super(data);
            this.date = this.in?.clone() ?? this.out?.clone();
        }

        get contextName() {
            return PunchProposal.contexts[this.context];
        }

        get long() {
            return this.in && this.out && this.out.diff(this.in, 'd') >= 1;
        }

        isOverlapping(proposal) {
            if (proposal.id == this.id) {
                return false;
            } // can't overlap self ^^
            const start = (this.in ?? this.out).valueOf();
            const end = (this.out ?? this.in).valueOf();
            const otherStart = (proposal.in ?? proposal.out).valueOf();
            const otherEnd = (proposal.out ?? proposal.in).valueOf();
            // Allow tangential punchproposals
            return otherStart == start && otherEnd == end || otherStart < start && otherEnd > start || otherStart < end && otherEnd > end ||
                start < otherStart && end > otherStart || start < otherEnd && end > otherEnd;
        }

        _set(obj, prop, value) {
            switch (prop) {
                case 'in':
                    if (!moment.isMoment(value) || !moment.isMoment(obj.out)) {
                        break;
                    }
                    if (value.isAfter(obj.out)) {
                        super._set(obj, 'out', null);
                    }
                    if (Math.abs(value.diff(obj.business_date, 'm')) > 24 * 60) {
                        super._set(obj, 'business_date', value.clone());
                    }
                    super._set(obj, 'length', obj.out?.diff(value, 's') ?? 0);
                    break;
                case 'out':
                    if (!moment.isMoment(value) || !moment.isMoment(obj.in)) {
                        break;
                    }
                    if (value.isBefore(obj.in)) {
                        value.add(1, 'd');
                    }
                    super._set(obj, 'length', value.diff(obj.in, 's'));
                    break;
                case 'business_date':
                    if (!moment.isMoment(value) || !moment.isMoment(obj.in)) {
                        break;
                    }
                    if (Math.abs(obj.in.diff(value, 'm')) > 24 * 60) {
                        return super._set(obj, 'business_date', obj.in.clone());
                    }
                    break;
            }
            return super._set(obj, prop, value);
        }

        createTimepunch() {
            return PunchProposal.createTimepunch(Object.assign({
                employee_id: this.employee_id,
                customer_id: this.customer_id,
                proposal_id: this.id,
            }, this));
        }

        delete() {
            return PunchProposal.delete({
                employee_id: this.employee_id,
                customer_id: this.customer_id,
                proposal_id: this.id,
            });
        }

        static getAll(options) {
            return EawResource.create(options.employee_id ? url : 'customers/:customer/punch_proposals').get({
                employee: options.employee_id,
                customer: options.customer_id,
                ...Pagination.getParams(options, [ 'from', 'to', 'include_timepunches' ]),
            });
        }

        static createTimepunch(options) {
            return EawResource.create(`${url}/timepunch`).save({
                employee: options.employee_id,
                customer: options.customer_id,
                proposal: options.proposal_id,
            }, pick(options, [ 'in', 'out', 'context', 'business_date' ])); // approve?
        }

        static delete(options) {
            return EawResource.create(url).delete({
                employee: options.employee_id,
                customer: options.customer_id,
                proposal: options.proposal_id,
            });
        }

        static override getMorphClass() {
            return 'punch_proposal';
        }
    }
    Object.defineProperty(PunchProposal, 'contexts', {
        enumerable: true,
        value: {
            100: 'WORK',
            150: 'BREAK',
            151: 'TRIP',
            152: 'MEETING',
            153: 'EDUCATION',
            158: 'DOCTOR',
            200: 'OFF_DUTY',
            250: 'BREAK_UNPAID',
        },
    });
    return PunchProposal;
} ]);
