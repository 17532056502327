import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { WeatherForecast } from '../classes/weather-forecast';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { WeatherForecastResponse } from '../interfaces/weather-forecast';
import { IGNORE_ERROR } from '../../shared/http/http-contexts';

@Injectable({
    providedIn: 'root',
})
export class WeatherService {
    private readonly http = inject(HttpClient);

    getForecast(customerId: number) {
        return this.http.get<WeatherForecastResponse>(`/customers/${customerId}/weather`, { context: (new HttpContext()).set(IGNORE_ERROR, true) }).pipe(classifyItem(WeatherForecast));
    }
}
