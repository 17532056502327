import { module } from 'angular';
import { CustomFieldOld } from '../../../../../../shared/angularjs/custom-field-old';
import { overlaps } from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { BusinessUnitService } from '../../../../../../business-units/http/business-unit.service';
import { Products } from '../../../../../../shared/enums/products';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';

module('eaw.payroll.paid-time').component('ptCreatePaidTime', {
    template: `<md-input-container ng-if="$ctrl.hasBusinessUnits">
    <label ng-i18next="BUSINESS_UNIT_plural"></label>
    <md-select ng-model="$ctrl.businessUnitId" ng-disabled="!$ctrl.businessUnits">
        <md-option ng-value="null">
            {{ 'NONE' | i18next }}
        </md-option>
        <md-option ng-if="$ctrl.businessUnits" ng-repeat="unit in $ctrl.businessUnits" ng-value="unit.id">
           {{ unit.name }}
        </md-option>
    </md-select>
</md-input-container>


<eaw-custom-fields ng-model="$ctrl.customFields" ng-disabled="$ctrl.parent.handling"></eaw-custom-fields>

<md-input-container class="md-block">
    <label ng-i18next="COMMENT" for="comment"></label>
    <textarea id="comment" ng-model="$ctrl.comment" rows="1" ng-required="$ctrl.commentRequired" ng-disabled="$ctrl.parent.handling"></textarea>
</md-input-container>


`,
    bindings: {
        segment: '<',
        parent: '<',
    },
    controller: [ '$scope', 'PaidTimeSettings', 'PaidTimeFactory', 'BusinessUnitDowngraded', function($scope: angular.IScope, PaidTimeSettings, PaidTimeFactory, BusinessUnitDowngraded: BusinessUnitService) {
        // @ts-ignore
        const ctrl = this ;
        ctrl.$onInit = () => {
            ctrl.customFields = CustomFieldOld.getModelFields('paid_time');
            ctrl.commentRequired = PaidTimeSettings.get('paidtime.comment.required');

            ctrl.setBusinessDate();
            $scope.$on('HandleSomethingCtrl:handle', ctrl.addPaidTime.bind(ctrl));

            ctrl.hasBusinessUnits = CurrentOld.hasProduct(Products.BusinessUnits);

            if (ctrl.hasBusinessUnits) {
                ctrl.getBusinessUnits();
            }
        };

        ctrl.setBusinessDate = () => {
            const shift = ctrl.segment.employeePaidTime.shifts.find((s: any) => overlaps(s.from, s.to, ctrl.segment.from, ctrl.segment.to));
            ctrl.parent.businessDate = shift?.business_date?.clone?.() || ctrl.segment.from.clone();
        };

        ctrl.getBusinessUnits = () => {
            const customerId = ctrl.segment.employeePaidTime.customer.id;
            ctrl.businessUnitId = ctrl.segment.marker1?.item.business_unit_id || ctrl.segment.marker2?.item.business_unit_id || null;
            ctrl.businessUnits = [];
            BusinessUnitDowngraded.getAllPages(customerId, undefined, false).subscribe((businessUnits) => {
                ctrl.businessUnits = businessUnits;
            });
        };

        ctrl.addPaidTime = () => {
            const from = ctrl.parent.interval.from.clone();
            const to = ctrl.parent.interval.to.clone();

            return PaidTimeFactory.create({
                customer_id: ctrl.segment.employeePaidTime.customer.id,
                employee_id: ctrl.segment.employeePaidTime.employee.id,
                business_date: ctrl.parent.businessDate,
                business_unit_id: ctrl.businessUnitId ?? undefined,
                from,
                to,
                comment: ctrl.comment || undefined,
                customFields: ctrl.customFields,
            }).$promise.then(() => {
                return ctrl.parent.close();
            });
        };
    } ],
});
