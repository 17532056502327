import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { ApiResponse } from '../interfaces/api-response';

export interface QualificationEmployeeResponse extends ApiResponse {
    id: number;
    user_id: number;
    number: number;
    name: string;
    pivot: {
        from: string;
        to: string | null;
        rate: number;
    }
}

export class QualificationEmployee {
    id: number;
    userId: number;
    name: string;
    rate: number;
    from: DateTime;
    to?: DateTime;

    constructor(data: QualificationEmployeeResponse) {
        this.id = data.id;
        this.userId = data.user_id;
        this.name = data.name;
        this.rate = data.pivot.rate;
        this.from = stringToDateTime(data.pivot.from, false, 'yyyy-MM-dd');
        this.to = data.pivot.to ? stringToDateTime(data.pivot.to, false, 'yyyy-MM-dd') : undefined;

    }
}
