// @ts-nocheck
import { module } from 'angular';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

const template1 = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="TARIFF_plural"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <md-progress-circular ng-if="$ctrl.loading" class="md-progress-center-absolute"></md-progress-circular>

        <eaw-accordion multi-toggle="true">
            <eaw-accordion-panel ng-repeat="tariff in $ctrl.tariffs track by tariff.id">
                <panel-header class="tw-flex tw-gap-8">
                    <span ng-bind="tariff.name"></span>
                    <span flex></span>
                    <span ng-i18next="[i18next]({count: tariff.links.length})company:X_PAY_TYPES"></span>
                </panel-header>
                <panel-content class="tw-p-0">
                    <table class="table table-bordered-bottom">
                        <thead>
                        <tr>
                            <th ng-i18next="NAME"></th>
                            <th ng-i18next="FROM"></th>
                            <th ng-i18next="TO"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr ng-repeat="link in tariff.links track by link.id">
                            <td ng-bind="link.name"></td>
                            <td ng-bind="link.from | moment:'LL'"></td>
                            <td ng-bind="link.to | moment:'LL'"></td>
                        </tr>
                        </tbody>
                    </table>
                </panel-content>
            </eaw-accordion-panel>
        </eaw-accordion>
    </md-card-content>
</md-card>
`;

module('eaw.company').component('companySettingsTariffs', {
    template: template1,
    bindings: {
        customer: '<',
    },
    controller: [ 'TariffFactory', function(TariffFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.includeInactive = true;
            ctrl.loading = true;

            TariffFactory.getAll({
                setting_group_id: ctrl.customer.setting_group_id,
                per_page: 250,
                with: ctrl.includeInactive ? [ 'payTypeLinks' ] : [ 'activePayTypeLinks' ],
            }).$promise.then((resp) => {
                ctrl.tariffs = resp.data.map((tariff) => {
                    tariff.links = ctrl.includeInactive ? tariff.pay_type_links : tariff.active_pay_type_links;
                    return tariff;
                });

                ctrl.tariffs = sort(ctrl.tariffs, CurrentOld.languageTag, [ (x) => x.name ], [ 'asc' ]);
            }).finally(() => delete ctrl.loading);
        };
    } ],
});
