import { Component, Inject, Input, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../data-table/types/data-table';
import { DataTableColumnType } from '../../data-table/interfaces/data-table-columns';
import { Rotation } from '../shared/models/rotation';
import { DataTableNumberColumn } from '../../data-table/types/data-table-number-column';
import { DataTableHeader } from '../../data-table/types/data-table-header';
import { RotationService } from '../shared/http/rotation.service';
import { HeaderFabButton, PageHeaderComponent } from '../../shared/components/page-header/page-header.component';
import { DataTableTextColumn } from '../../data-table/types/data-table-text-column';
import { RotationDialogService } from '../shared/dialogs/rotation-dialog/rotation-dialog.service';
import { DataTableComponent } from '../../data-table/data-table.component';
import { DataTableButtonColumn } from '../../data-table/types/data-table-button-column';
import { DataTableCell } from '../../data-table/interfaces/data-table-cell';
import { ConnectRotationDialogService } from '../shared/dialogs/connect-rotation-dialog/connect-rotation-dialog.service';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { PermissionCheckService } from '../../shared/services/permission-check.service';
import { of } from 'rxjs';
import { DataTableDateTimeColumn } from '../../data-table/types/data-table-date-time-column';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from '../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '../../shared/services/translate.service';
import { Namespace } from '../../shared/enums/namespace';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { mockArrayPaginatedResponse } from '../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-rotation-list',
    templateUrl: './rotation-list.component.html',
    styleUrl: './rotation-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class RotationListComponent implements EawDataTable {
    @ViewChild('table') table?: DataTableComponent<Rotation>;

    @Input({ required: true }) customerId!: number;

    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    fabButton: HeaderFabButton = {
        click: this.openCreateDialog.bind(this),
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.rotations.create`),
    };

    columns: DataTableColumnType<Rotation>[] = [
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({ i18n: 'NAME', sortBy: 'name' }),
        }),
        new DataTableNumberColumn({
            value: 'days',
            header: new DataTableHeader({ i18n: 'DAY_plural', sortBy: 'days' }),
        }),
        new DataTableDateTimeColumn({
            value: 'startDayDateTime',
            format: 'cccc',
            header: new DataTableHeader({ i18n: 'START_DAY', ns: 'rotation', sortBy: 'start_day' }),
        }),
        new DataTableNumberColumn({
            value: 'employeesCount',
            header: new DataTableHeader({ i18n: 'EMPLOYEE_plural' }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'link',
                    click: this.connect.bind(this),
                    show: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.*.rotations.create`),
                    tooltip: { key: 'CONNECT_EMPLOYEE', ns: 'rotation' },
                    nonBlocking: true,
                },
                {
                    icon: 'edit',
                    click: this.update.bind(this),
                    show: (rotation) => this.permissionCheckService.isAllowedMany([
                        `customers.${this.customerId}.rotations.${rotation.id}.update`,
                        `customers.${this.customerId}.rotations.${rotation.id}.delete`,
                    ]),
                    tooltip: { key: 'UPDATE' },
                },
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.delete.bind(this),
                    hide: (rotation) => of(rotation.employeesCount == null || rotation.employeesCount > 0),
                    show: (rotation) => this.permissionCheckService.isAllowed(`customers.${this.customerId}.rotations.${rotation.id}.delete`),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    constructor(
        @Inject(RotationService) private rotationService: RotationService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(RotationDialogService) private rotationDialogService: RotationDialogService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(SnackBarService) private snackbar: SnackBarService,
        @Inject(TranslateService) private translateService: TranslateService,
        @Inject(ConnectRotationDialogService) private connectRotationDialogService: ConnectRotationDialogService,
    ) {
    }

    delete(cell: DataTableCell<DataTableButtonColumn<Rotation>, Rotation>) {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translateService.t('DELETE_ROTATION', Namespace.Rotation),
                text: this.translateService.t('DELETE_ROTATION_TEXT', Namespace.Rotation),
                confirmText: this.translateService.t('DELETE'),
            },
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                return;
            }

            this.rotationService.delete(this.customerId, cell.item.id).subscribe(() => {
                this.table?.refresh();
            });
        });
    }

    update(cell: DataTableCell<DataTableButtonColumn<Rotation>, Rotation>) {
        this.rotationDialogService.update(cell.item).afterClosed().subscribe(() => {
            this.table?.refresh();;
        });
    }

    connect(cell: DataTableCell<DataTableButtonColumn<Rotation>, Rotation>) {
        this.connectRotationDialogService.connect(this.customerId, cell.item).afterClosed().subscribe((connections) => {
            if (!connections) {
                return;
            }

            void this.snackbar.t('EMPLOYEES_CONNECTED', 'rotation', { count: connections });
            this.table?.refresh();
        });
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.rotationService.getAll(this.customerId, {
            ...pagination,
            'count[]': [ 'employees' ],
            'with[]': [ 'intervals' ],
        });
    }

    openCreateDialog() {
        this.rotationDialogService.create(this.customerId).afterClosed().subscribe((resp) => {
            if (resp instanceof Rotation) {
                this.table?.refresh();
            }
        });
    }
}
