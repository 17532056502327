// @ts-nocheck
import { module } from 'angular';
module('eaw.ai-budgeting').factory('mlVariableFactory', [ 'EawResource', 'Pagination', function mlVariableFactory(EawResource, Pagination) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/ml_buckets/:bucket/variables/:variable');
    fac.get = {
        permission(customer, bucket, variable) {
            return `customers.${customer}.ml_buckets.${bucket}.variables.${variable}.get`;
        },
        query(customer, bucket, variable) {
            return res.get({
                customer,
                bucket,
                variable,
            }).$promise;
        },
    };
    fac.getAll = {
        permission(customer, bucket) {
            return `customers.${customer}.ml_buckets.${bucket}.variables.*.get`;
        },
        query(customer, bucket, pagination) {
            return res.get({
                customer,
                bucket,
                ...Pagination.getParams(pagination),
            }).$promise;
        },
    };
    fac.create = {
        permission(customer, bucket) {
            return `customers.${customer}.ml_buckets.${bucket}.variables.create`;
        },
        /**
         * @param {Number} customer
         * @param {Number} bucket
         * @param {Object} args
         * @param {String} args.name
         * @param {String} [args.description]
         * @param {String} [args.code]
         * @returns {Promise}
         */
        query(customer, bucket, args) {
            return res.save({
                customer,
                bucket,
            }, {
                name: args.name,
                description: args.description,
                code: args.code,
            }).$promise;
        },
    };
    return fac;
} ]);
