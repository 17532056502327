<eaw-dialog-header>
    <span title>{{ 'DOWNLOAD_CONTRACT' | translate: 'new_employee' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="loading" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content *ngIf="!loading">
    <form [formGroup]="form" class="tw-grid tw-grid-cols-2 tw-gap-8">
        <mat-form-field class="tw-mt-20">
            <mat-label>{{ 'DOWNLOAD_CONTRACT' | translate: 'new_employee' | async }}</mat-label>
            <mat-select formControlName="contractTemplates">
                <mat-option *ngFor="let template of contractTemplates" [value]="template">{{ template.toUpperCase() | translateSync:'new_employee' }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>

    <eaw-action-button [loading]="false" (click)="downloadContract()" [disabled]="!form.controls.contractTemplates.value">
        {{ 'DOWNLOAD' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
