<div class="tw-flex tw-flex-row tw-gap-4">
    @for (button of cell.column.buttons; track button) {
        <button mat-icon-button
                class="table-button"
                [eawMaterialColor]="button.color"
                colorProperty="backgroundColor"
                [matTooltip]="isDisabled() ? '' : (button.tooltip.key | translate: button.tooltip.ns | async) || ''"
                [ngClass]="button.type || ''"
                [class.skeleton]="!hasCheckedHiding()"
                [class.hidden]="hasCheckedHiding() ? hideMap[button.id || 0]?.() : ($index > 0)"
                [disabled]="isDisabled()"
                [attr.aria-label]="button.ariaLabel || 'Data table button'"
                (click)="click(button, cell, $event)"
                eawPermission
                [permissions]="permissions.get($index)?.()">
            <mat-icon *ngIf="hasCheckedHiding()">{{ iconMap[button.id || 0]?.() }}</mat-icon>
        </button>
    }
</div>
