import { createInstance } from 'localforage';
import { environment } from '../../../environments/environment';

/**
 * Interacts with the IndexedDB database.
 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Cache {
    private static readonly instance = createInstance({
        name: 'Cache',
    });

    static readonly getItem = this.instance.getItem.bind(this.instance);
    static readonly setItem = this.instance.setItem.bind(this.instance);
    static readonly clear = this.instance.clear.bind(this.instance);

    static async clearAll() {
        let databases: Array<IDBDatabaseInfo> = [];

        try {
            databases = await indexedDB.databases();
        } catch (_) {
            /**
             * Since IndexedDB is, as of now, still in a draft state, some browsers don't yet have a working or full
             * implementation of the IDB specifications (https://w3c.github.io/IndexedDB/).
             * Specifically for Firefox, the lack of the implementation of indexedDB.databases() has been described
             * in this bug report: https://bugzilla.mozilla.org/show_bug.cgi?id=934640
             * Therefore, the header Clear-Site-Data has been added in the response of the /logout route as
             * recommended in this comment https://bugzilla.mozilla.org/show_bug.cgi?id=934640#c20
             * You can find more info about this header on https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Clear-Site-Data
             */
            console.warn('Failed to retrieve the IndexedDB databases. Nevertheless, the state (storage, cache, cookies, ...) should be clean.');
        }

        for (const db of databases) {
            if (!db.name) {
                continue;
            }

            try {
                indexedDB.deleteDatabase(db.name);
            } catch (e) {
                if (!environment.isTesting) {
                    console.error(e);
                }
            }
        }
    }
}
