<eaw-dialog-header>
    <span title>{{(data.title || ('ATTACHMENT_plural' | translate)) | async}}</span>
    <span subtitle *ngIf="data.subtitle">{{data.subtitle | async}}</span>
</eaw-dialog-header>

<mat-dialog-content id="loading" *ngIf="!urls.length">
    <mat-progress-spinner mode="indeterminate" diameter="50" strokeWidth="5"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content id="attachments-content" *ngIf="urls.length">
    <eaw-image-attachment [dialogRef]="dialogRef" *ngFor="let url of urls" [url]="url"></eaw-image-attachment>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-raised-button [mat-dialog-close]>{{'CLOSE' | translate | async}}</button>
</mat-dialog-actions>
