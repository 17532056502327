import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from 'src/app/shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from 'src/app/shared/interfaces/paginated-response';
import { EawUrl } from 'src/app/shared/angularjs/modules/resource/url.service';
import { formatHttpParams } from 'src/app/shared/utils/format-http-params';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { map } from 'rxjs';
import { CustomerUser, CustomerUserResponse } from '../../shared/models/customer-user';

interface GetAllOptions extends PaginationOptions {
    include_inactive?: boolean
}
@Injectable({
    providedIn: 'root',
})
export class CompanyUserService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, options?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<CustomerUserResponse>>(EawUrl.getUrl(`/customers/${customerId}/user_access`), {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(CustomerUser));
    }

    get(customerId: number, userId: number, customerUserId: number) {
        return this.http.get<CustomerUserResponse | object>(EawUrl.getUrl(`/customers/${customerId}/users/${userId}/access/${customerUserId}`), {}).pipe(
            map((response) => {
                return 'id' in response ? new CustomerUser(response) : null;
            }),
        );
    }

    create(customerId: number, userId: number, customerUserId: number, name: string) {
        return this.http.post<CustomerUserResponse>(EawUrl.getUrl(`/customers/${customerId}/users/${userId}/access/${customerUserId}`), {
            name,
        }).pipe(classifyItem(CustomerUser));
    }

    update(customerId: number, userId: number, customerUserId: number, name: string) {
        return this.http.put<CustomerUserResponse>(EawUrl.getUrl(`/customers/${customerId}/users/${userId}/access/${customerUserId}`), {
            name,
        }).pipe(classifyItem(CustomerUser));
    }

    delete(customerId: number, userId: number, customerUserId: number) {
        return this.http.delete<undefined>(EawUrl.getUrl(`/customers/${customerId}/users/${userId}/access/${customerUserId}`));
    }
}
