import { pick } from 'lodash-es';
import { HttpParams } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { expand } from 'rxjs/operators';

interface PaginationProperties {
    order_by?: string | Record<string, string>

    [key: string]: any,
}

/**
 * @deprecated
 * @see Pagination
 */
export class PaginationOld {
    [x: string]: any;

    total?: number;
    last_page?: number;
    data?: any[];
    from?: number;
    to?: number;
    with?: string[];
    filter?: string;
    fields?: string[];
    current_page: number;
    per_page: number;
    order_by: string | string[] = 'created_at';
    direction = 'desc';
    countArr?: string[];

    constructor(props: PaginationProperties | PaginationOld = {}) {
        Object.assign(this, pick(props, [ 'total', 'last_page', 'data', 'from', 'to', 'with', 'filter', 'fields' ]));

        this.current_page = props.current_page || props['page'] || 1;
        this.per_page = props.per_page || 25;

        if (typeof props.order_by == 'string') {
            this.order_by = props.order_by || 'created_at';
            this.direction = props.direction || 'desc';
        } else if (props.order_by) {
            this.order_by = Object.keys(props.order_by)[0] || 'created_at';
            this.direction = Object.values(props.order_by)[0] || 'desc';
        }

        if (props.count) {
            this.countArr = props.count;
        }

        Object.defineProperty(this, 'page', {
            enumerable: true,
            get() {
                return this.current_page;
            },
            set(page) {
                this.current_page = page;
            },
        });

        Object.defineProperty(this, 'sorting', {
            enumerable: true,
            get() {
                if (this.order_by instanceof Array) {
                    return this.order_by.reduce((carry: Record<string, string>, k: string) => {
                        carry[k] = this.direction;

                        return carry;
                    }, {});
                }
                return { [this.order_by]: this.direction };
            },
        });

        Object.defineProperty(this, 'with[]', {
            enumerable: true,
            get() {
                return this.with;
            },
        });

        Object.defineProperty(this, 'count[]', {
            enumerable: true,
            get() {
                return this.countArr;
            },
            set(arr) {
                this.countArr = arr;
            },
        });

        Object.defineProperty(this, 'order_by[]', {
            enumerable: true,
            get() {
                return this.order_by instanceof Array ? this.order_by : (this.order_by ? [ this.order_by ] : undefined);
            },
        });

        Object.defineProperty(this, 'fields[]', {
            enumerable: true,
            get() {
                return this.fields;
            },
        });
    }

    get count() {
        return this.countArr;
    }

    set count(count) {
        this.countArr = count;
    }

    toNgTableParams() {
        const params: { count?: number, sorting: string, page: number } = pick(this, [ 'sorting', 'page' ]);
        params.count = this.per_page;

        return params;
    }

    appendHttpParams(params: HttpParams): HttpParams {
        let p = params.appendAll({
            per_page: this.per_page,
            page: this.current_page,
        });

        if (this.filter?.length) {
            p = p.set('filter', this.filter);
        }

        if (this['_businessDates']) {
            p = p.set('_businessDates', this['_businessDates']);
        }

        if (this.fields) {
            p = p.appendAll({ 'fields[]': this.fields });
        }

        if (this.count) {
            p = p.appendAll({ 'count[]': this.count });
        }

        if (this.with) {
            p = p.appendAll({ 'with[]': this.with });
        }

        if (this.order_by) {
            if (this.order_by instanceof Array) {
                p = p.appendAll({ 'order_by[]': this.order_by });
            } else {
                p = p.set('order_by', this.order_by);
            }

            if (this.direction) {
                p = p.set('direction', this.direction);
            }
        }

        return p;
    }

    toWithPaginatorArguments(additionalFields: string[] = []) {
        const fields = [ 'per_page', 'page', 'direction', 'filter', 'fields[]', 'count[]', 'with[]', '_businessDates' ];

        if (this.order_by instanceof Array) {
            fields.push('order_by[]');
        } else {
            fields.push('order_by');
        }

        // We don't want to include a filter if the filter isn't actually anything
        if (!this.filter?.length) {
            delete this.filter;
        }

        const obj: any = pick(this, fields.concat(additionalFields));
        Object.keys(obj).forEach((key) => {
            if (obj[key] === undefined) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete obj[key];
            }
        });

        return obj;
    }

    /**
     * @deprecated
     * @see expandPages
     */
    static expand<T>(pagination: PaginationOld, cb: (pagination: PaginationOld) => Observable<ArrayPaginatedResponse<T>>): Observable<ArrayPaginatedResponse<T>> {
        const pages: number[] = [];
        const firstPage = pagination.current_page;

        return cb(pagination).pipe(
            expand((response, index) => {
                const page: number = firstPage + index;

                if (pages.indexOf(page) > 0 || response.last_page <= page) {
                    return EMPTY;
                }

                pages.push(page);

                return cb(new PaginationOld(Object.assign({}, pagination, { current_page: page + 1 })));
            }, 4),
        );
    }

    /**
     *
     * @param {PaginationOld} pagination
     * @param {Function<Promise<Object>>} cb
     * @return {Promise<*[]>}
     */
    static async getData<T>(pagination: PaginationOld, cb: (pagination: PaginationOld) => Promise<ArrayPaginatedResponse<T>>) {
        let i = 0;
        const maxIterations = 100;

        let data: T[] = [];

        const promises: any = {};

        const next = (resp: ArrayPaginatedResponse<T>) => {
            const pageRequests: number[] = Object.keys(promises).map((n) => parseInt(n));
            if (i > maxIterations || !pageRequests.length) {
                return;
            }

            i++;

            let current: number = Math.max(...pageRequests);
            let nextPage: number = current + 1;
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete promises[resp.current_page];

            while (pageRequests.length < 5 && current < resp.last_page) {
                promises[nextPage] = cb(new PaginationOld(Object.assign({}, pagination, {
                    current_page: nextPage,
                    order_by: pagination['sorting'],
                }))).then(next);
                nextPage++;
                current = nextPage;
                pageRequests.push(nextPage);
            }

            data = data.concat(resp.data as any);

            return Promise.all(Object.values(promises));
        };

        promises[1] = cb(pagination).then(next);
        await promises[1];

        return data;
    }

    static fromNgTableParams(params: any, defaultSorting: string): PaginationOld {
        return new PaginationOld({
            page: params.page(),
            per_page: params.count(),
            order_by: Object.keys(params.sorting())[0] || 'created_at',
            direction: Object.values(params.sorting())[0] || defaultSorting,
        });
    }

    static getParams(args: any = {}, picks: string[] = []) {
        if (args instanceof PaginationOld) {
            return args.toWithPaginatorArguments(picks);
        }

        // in case the object already was converted
        const params = pick(args, picks.concat([ 'with[]', 'order_by[]', 'count[]', 'fields[]', 'direction', 'filter', 'page', 'per_page', '_businessDates' ]));

        if (Array.isArray(args.with)) {
            params['with[]'] = params['with[]'] || args.with;
        }

        if (Array.isArray(args.order_by)) {
            params['order_by[]'] = params['order_by[]'] || args.order_by;
        } else if (args.order_by) {
            params['order_by'] = args.order_by;
        }

        if (Array.isArray(args.count)) {
            params['count[]'] = params['count[]'] || args.count;
        } else if (typeof args.count === 'string') {
            params['count'] = args.count;
        }

        // check that filter isn't empty
        if (params['filter']?.length) {
            // Only add fields if we have filter
            params['fields[]'] = params['fields[]'] || args.fields;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete params['filter'];
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete params['fields'];
            delete params['fields[]'];
        }

        Object.keys(params).forEach((key) => {
            if (params[key] === undefined) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete params[key];
            }
        });

        return params;
    }
}
