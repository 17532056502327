import { module } from 'angular';
import CustomerOld from '../../../../shared/angularjs/customer-old';
import { Products } from '../../../../shared/enums/products';
import { Namespace } from '../../../../shared/enums/namespace';
import { CustomerRoleListComponent } from '../../../pages/customer-role-list/customer-role-list.component';
import { AdminRoleRoleAssignmentListComponent } from '../../../pages/admin-role-role-assignment-list/admin-role-role-assignment-list.component';
import { HrDefaultFilesComponent } from '../../../../hr/pages/hr-default-files/hr-default-files.component';
import { EmployeeGroupsListComponent } from '../../../../company/pages/employee-groups-list/employee-groups-list.component';
import { createNavTabsState, createState } from '../../../../shared/utils/create-state';
import { staticResolver } from '../../../../shared/resolvers/static.resolver';
import { NavTabsComponent } from '../../../../shared/components/nav-tabs/nav-tabs.component';
import { paramResolver } from '../../../../shared/resolvers/param.resolver';
import { navTabsTabsResolver } from '../../../../shared/resolvers/nav-tabs-tabs.resolver';
import { EmployeeGroupInfoComponent } from '../../../../company/pages/employee-group-info/employee-group-info.component';
import { ManageCustomerComponent } from '../../../pages/manage-customer/manage-customer.component';
import { CustomerProductService } from '../../../../shared/http/customer-product.service';
import { SettingGroupSettingsComponent } from '../../../pages/setting-group-settings/setting-group-settings.component';
import { DataTableQueryParams } from '../../../../data-table/data-table.component';
import { StateProvider } from '@uirouter/angularjs';
import { EawNavBarTab } from '../../../../shared/angularjs/components/navbar/navbar.component';
import { PermissionCheckService } from '../../../../shared/services/permission-check.service';
import { ApiModel } from '../../../../shared/enums/api-model';
import { CustomerReportListComponent } from '../../../pages/customer-report-list/customer-report-list.component';
import { resolver } from '../../../../shared/resolvers/resolver';
import { lastValueFrom, map } from 'rxjs';
import { dependencyResolver } from '../../../../shared/resolvers/dependency.resolver';
import { inject } from '@angular/core';
import { CustomerService } from '../../../../shared/http/customer.service';
import { CustomerGroupsListComponent } from '../../../pages/customer-groups-list/customer-groups-list.component';
import { AdminEmployeesListComponent } from '../../../pages/admin-employees-list/admin-employees-list.component';

const usersTabTemplate = `<md-card>
    <md-card-content layout="column">
        <md-input-container>
            <input ng-model="acutCtrl.searchValue" ng-i18next="[placeholder]SEARCH" ng-change="acutCtrl.debouncedUpdate()">
        </md-input-container>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="USER_plural"></span>
            <span class="md-subtitle" ng-bind="acutCtrl.userCount"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="acutCtrl.display_inactive_users"
                        icon="history"
                        ng-change="acutCtrl.debouncedUpdate()"
                        i18n-tooltip="INCLUDE_INACTIVE_USERS">
        </eaw-toggle-btn>

        <card-btn-float on-click="acutCtrl.addUserToCustomer()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="acutCtrl.table.columns"
                cells="acutCtrl.table.cells"
                get-data="acutCtrl.getData(pagination)"
                reload-table="acutCtrl.reloadTable"
                go-to="eaw/app/admin/users/view/info"
                mark-inactive="true">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;
const userGroupsTemplate = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="USER_GROUP_plural"></span>
        </md-card-header-text>

        <md-button class="md-icon-button" ng-click="acugtCtrl.importGroup()">
            <md-icon ng-bind="'storage'">
                <md-tooltip ng-i18next="admin:USER_GROUP_IMPORT_TOOL_TIP"></md-tooltip>
            </md-icon>
        </md-button>
        <card-btn-float on-click="acugtCtrl.addGroup()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="acugtCtrl.table.columns"
                cells="acugtCtrl.table.cells"
                factory="UserGroupService"
                factory-function="getAllPaginated"
                factory-function-arguments="{
                                customerId: acugtCtrl.customer.id
                            }"
                reload-table="acugtCtrl.updateList"
                go-to="eaw/app/admin/customers/view/user_groups/view/nav/users|id=owner_id|group_id=id">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>

`;
const relationsTemplate = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="RELATION_plural"></span>
        </md-card-header-text>

        <card-btn-float on-click="custCtrl.addEditLink()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="custCtrl.table.columns"
                cells="custCtrl.table.cells"
                get-data="custCtrl.getData(pagination)"
                go-to="eaw/app/admin/customers/view/info|id=relationId"
                reload-table="custCtrl.reloadTable">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;

module('eaw.admin').config([ '$stateProvider', function($stateProvider: StateProvider) {
    const customers = new Map<number, CustomerOld>();

    const oldCustomerResolver = dependencyResolver('customer', [ 'CustomerService', 'user', 'customerId' ], function(CustomerService: any, user: any, customerId: number) {
        const storedCustomer = customers.get(customerId);
        if (storedCustomer) {
            return storedCustomer;
        }

        return CustomerService.get({
            id: customerId,
            with: [ 'settingGroup', 'billingCustomer', 'stack', 'region', 'products', 'customerGroups.products' ],
        }).$promise.then((cust: any) => {
            const customer = new CustomerOld(cust, user);
            customers.set(customerId, customer);
            return customer;
        });
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view`,
        abstract: true,
        parent: `eaw/app/admin/customers/list`,
        url: '/:id',
        views: {
            'content@': 'eawNavBar',
        },
        resolve: [
            staticResolver('navbarRoute', 'eaw/app/admin/customers/view'),
            staticResolver('viewName', 'adminCustomerTabView'),
            paramResolver('id', 'customerId'),
            dependencyResolver('tabs', [ 'customerId', 'PermissionCheckServiceDowngrade' ], async function(customerId: number, PermissionChecker: PermissionCheckService): Promise<EawNavBarTab[]> {
                return [
                    {
                        label: 'INFORMATION',
                        src: `eaw/app/admin/customers/view/info`,
                    },
                    {
                        label: 'USER_plural',
                        src: `eaw/app/admin/customers/view/users`,
                    },
                    {
                        label: 'USER_GROUP_plural',
                        src: `eaw/app/admin/customers/view/user_groups`,
                    },
                    {
                        label: 'EMPLOYEES',
                        src: `eaw/app/admin/customers/view/employees`,
                    },
                    {
                        label: 'company:EMPLOYEE_GROUP_plural',
                        src: `eaw/app/admin/customers/view/employee_groups`,
                    },
                    {
                        label: 'CUSTOMER_GROUP_plural',
                        src: `eaw/app/admin/customers/view/customer_groups`,
                    },
                    {
                        label: 'admin:PRODUCT_plural',
                        src: `eaw/app/admin/customers/view/products`,
                    },
                    {
                        label: 'admin:SIGNABLE_plural',
                        src: `eaw/app/admin/customers/view/signables`,
                    },
                    {
                        label: 'admin:RELATION_plural',
                        src: `eaw/app/admin/customers/view/relations`,
                    },
                    {
                        label: 'admin:PAY_TYPE_plural',
                        src: `eaw/app/admin/customers/view/pay_type_links`,
                    },
                    {
                        label: 'admin:REPORT_plural',
                        src: `eaw/app/admin/customers/view/reports`,
                        show: await lastValueFrom(PermissionChecker.isAllowed(`customers.[${ApiModel.Customer}].reports.*.get`, {
                            models: [ {
                                id: customerId,
                                type: ApiModel.Customer,
                            } ],
                        })),
                    },
                    {
                        label: 'SETTING_plural',
                        src: `eaw/app/admin/customers/view/settings`,
                    },
                    {
                        label: 'ML Buckets',
                        src: `eaw/app/admin/customers/view/buckets/list`,
                    },
                    {
                        label: 'company:ROLE_plural',
                        src: `eaw/app/admin/customers/view/roles`,
                    },
                    {
                        label: 'navigation:DEFAULT_HR_FILES',
                        src: 'eaw/app/admin/customers/view/default_files',
                    },
                ];
            }),
        ],
        data: {
            breadcrumb: (transition) => {
                return inject(CustomerService).get(transition.params()['id']).pipe(
                    map((customer) => customer.name),
                );
            },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view/info`,
        url: '/info',
        parent: `eaw/app/admin/customers/view`,
        views: {
            adminCustomerTabView: {
                component: ManageCustomerComponent,
            },
        },
        data: {
            breadcrumb: { key: 'Info' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view/users`,
        parent: `eaw/app/admin/customers/view`,
        url: '/users',
        views: {
            adminCustomerTabView: {
                template: usersTabTemplate,
                controller: 'adminCustomerUsersTabCtrl',
                controllerAs: 'acutCtrl',
            },
        },
        resolve: [ oldCustomerResolver ],
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view/user_groups`,
        parent: `eaw/app/admin/customers/view`,
        url: '/user_groups',
        data: {
            breadcrumb: { key: 'USER_GROUP_plural', ns: 'admin' },
        },
        views: {
            adminCustomerTabView: {
                template: userGroupsTemplate,
                controller: 'adminCustomerUserGroupsTabCtrl',
                controllerAs: 'acugtCtrl',
            },
        },
        resolve: [ oldCustomerResolver ],
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view/employees`,
        parent: `eaw/app/admin/customers/view`,
        url: '/employees',
        views: {
            adminCustomerTabView: {
                component: AdminEmployeesListComponent
            },
        },
        resolve: [ oldCustomerResolver ],
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view/employee_groups`,
        parent: `eaw/app/admin/customers/view`,
        url: '/employee_groups',
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'EMPLOYEE_GROUP_plural', ns: 'company' },
        },
        views: {
            adminCustomerTabView: {
                component: EmployeeGroupsListComponent,
            },
        },
        resolve: [
            staticResolver('infoRoute', 'eaw/app/admin/customers/view/employee_groups/info/members'),
        ],
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view/customer_groups`,
        parent: `eaw/app/admin/customers/view`,
        url: '/customer_groups',
        views: {
            adminCustomerTabView: {
                component: CustomerGroupsListComponent
            },
        },
        resolve: [ oldCustomerResolver ],
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view/products`,
        parent: `eaw/app/admin/customers/view`,
        url: '/products',
        views: {
            adminCustomerTabView: {
                component: 'adminCustomerProductsTab',
            },
        },
        resolve: [
            oldCustomerResolver,
            dependencyResolver('customerProducts', [ 'customer', 'CustomerService' ], function(customer: any, CustomerService: any) {
                return CustomerService.getAllProducts({
                    customer,
                    per_page: 1000,
                }).$promise.then((data: any) => data.data);
            }),
            dependencyResolver('allProducts', [ 'ProductService' ], function(ProductService: any) {
                return ProductService.getAll({
                    per_page: 1000,
                    order_by: 'name',
                    direction: 'asc',
                }).$promise.then((data: any) => data.data);
            }),
        ],
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view/signables`,
        parent: `eaw/app/admin/customers/view`,
        url: '/signables',
        views: {
            adminCustomerTabView: 'adminSignables',
        },
        data: {
            products: [ Products.Reports ],
            breadcrumb: { key: 'SIGNABLE_plural', ns: 'admin' },
        },
        resolve: [ oldCustomerResolver ],
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view/relations`,
        parent: `eaw/app/admin/customers/view`,
        url: '/relations',
        views: {
            adminCustomerTabView: {
                template: relationsTemplate,
                controller: 'adminCustomerRelationsTabCtrl',
                controllerAs: 'custCtrl',
            },
        },
        resolve: [ oldCustomerResolver ],
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view/pay_type_links`,
        parent: `eaw/app/admin/customers/view`,
        url: '/pay_types',
        views: {
            adminCustomerTabView: 'customerPayTypesTab',
        },
        data: {
            i18nextNs: [ Namespace.Absences, Namespace.Vacation, Namespace.AbsenceTypes ],
            breadcrumb: { key: 'PAY_TYPE_plural', ns: 'admin' },
        },
        resolve: [ oldCustomerResolver ],
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customers/view/reports`,
        parent: `eaw/app/admin/customers/view`,
        url: '/reports',
        views: {
            adminCustomerTabView: {
                component: CustomerReportListComponent,
            },
        },
        data: {
            products: [ Products.Reports ],
            breadcrumb: { key: 'REPORT_plural', ns: 'admin' },
        },
        resolve: [
            resolver('customerId', (transition) => transition.params()['id']),
        ],
    });

    createState($stateProvider, {
        name: 'eaw/app/admin/customers/view/settings',
        parent: `eaw/app/admin/customers/view`,
        url: '/settings',
        views: {
            adminCustomerTabView: {
                component: SettingGroupSettingsComponent,
            },
        },
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'SETTING_plural', ns: 'admin' },
        },
    });

    $stateProvider.state(`eaw/app/admin/customers/view/roles`, {
        parent: `eaw/app/admin/customers/view`,
        url: '/roles',
        resolve: {
            goTo() {
                return {
                    state: `eaw/app/admin/customers/view/roles/assignments`,
                    params: [ {
                        stateKey: 'roleId',
                        itemKey: 'id',
                    } ],
                };
            },
        },
        data: {
            breadcrumb: { key: 'ROLE_plural', ns: 'company' },
        },
        views: {
            adminCustomerTabView: {
                component: CustomerRoleListComponent,
            },
        },
    });

    $stateProvider.state(`eaw/app/admin/customers/view/roles/assignments`, {
        parent: `eaw/app/admin/customers/view/roles`,
        url: '/:roleId/assignments',
        views: {
            'adminCustomerTabView@eaw/app/admin/customers/view': {
                component: AdminRoleRoleAssignmentListComponent,
            },
        },
        data: {
            breadcrumb: { key: 'ROLE_ASSIGNMENT_plural', ns: 'admin' },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/admin/customers/view/default_files',
        parent: 'eaw/app/admin/customers/view',
        url: '/default_files',
        views: {
            adminCustomerTabView: {
                component: HrDefaultFilesComponent,
            },
        },
        data: {
            queryParams: [ ...DataTableQueryParams, 'search', 'filetypeFilter' ],
            breadcrumb: { key: 'DEFAULT_FILES', ns: 'company' },
            products: [ Products.HumanResources, Products.Onboarding ],
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/admin/customers/view/employee_groups/info',
        parent: `eaw/app/admin/customers/view/employee_groups`,
        url: '/:group_id',
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        data: {
            breadcrumb: { key: 'EMPLOYEE_GROUP', ns: 'company' },
            permissions: [ 'customers.{customer}.employee_groups.{group_id}.get' ],
        },
        resolve: [
            paramResolver('group_id', 'employeeGroupId'),
            navTabsTabsResolver(function() {
                return Promise.resolve([
                    {
                        state: 'eaw/app/admin/customers/view/employee_groups/info/members',
                        label: { key: 'MEMBER_plural' },
                    },
                    {
                        state: 'eaw/app/admin/customers/view/employee_groups/info/social_rules',
                        label: { key: 'SOCIAL_RULE_plural', ns: Namespace.FranceSocialRules },
                    },
                ]);
            }),
        ],
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/admin/customers/view/employee_groups/info/members`,
        url: '/members',
        parent: `eaw/app/admin/customers/view/employee_groups/info`,
        component: EmployeeGroupInfoComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'MEMBER_plural' },
        },
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/admin/customers/view/employee_groups/info/social_rules`,
        url: '/social_rules',
        parent: `eaw/app/admin/customers/view/employee_groups/info`,
        component: EmployeeGroupInfoComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'SOCIAL_RULE_plural', ns: Namespace.FranceSocialRules },
        },
    });
} ]);
