<eaw-page-header [fabButton]="fabButton">
    <span title>{{ 'PERMISSIONSET_plural' | translate | async }}</span>
    <div class="tw-flex tw-flex-row tw-justify-center" smallFilter>
        <mat-form-field class="standalone" >
            <mat-label>{{ 'SEARCH' | translate | async }}</mat-label>
            <input matInput type="text" autofocus autocomplete="off" [formControl]="filter">
        </mat-form-field>
    </div>
</eaw-page-header>
<eaw-data-table
        [columns]="columns"
        [request]="request"
        [goTo]="goTo"
        (paginationChange)="updateTable($event)"/>
