import { Component, Inject, ViewChild } from '@angular/core';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, of, tap } from 'rxjs';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { CustomerGroup } from '../../../shared/models/customer-group';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';

@Component({
    selector: 'eaw-admin-customer-groups-list',
    templateUrl: './admin-customer-groups-list.component.html',
    styleUrl: './admin-customer-groups-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
    ],
})
export class AdminCustomerGroupsListComponent implements EawDataTable<CustomerGroup> {
    @ViewChild('table') table?: DataTableComponent<CustomerGroup>;

    fabButton: HeaderFabButton = {
        click: this.createGroup.bind(this),
        hasPermission: () => this.permissionCheckService.isAllowed('customer_groups.create'),
    };

    total = 0;
    filter = new FormControl(this.searchParams.get('filter', 'string') || '');
    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<CustomerGroup>[] = [
        new DataTableNumberColumn({
            value: 'id',
            header: new DataTableHeader({
                text: 'Id',
                sortBy: 'id',
            }),
        }),
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableTextColumn({
            value: (cell) => {
                return cell.item.membershipsCount != null && cell.item.activeMembershipsCount != null ? `${cell.item.activeMembershipsCount} / ${cell.item.membershipsCount}` : '-';
            },
            header: new DataTableHeader({
                i18n: 'MEMBER_plural',
            }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'edit',
                    click: this.updateGroup.bind(this),
                    show: (group) => this.permissionCheckService.isAllowed(`customer_groups.[${ApiModel.Group}].update`, {
                        models: [
                            { id: group.id, type: ApiModelClass.CustomerGroup },
                        ],
                    }),
                    tooltip: { key: 'UPDATE' },
                },
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.deleteGroup.bind(this),
                    show: (group) => this.permissionCheckService.isAllowed(`customer_groups.[${ApiModel.Group}].delete`, {
                        models: [
                            { id: group.id, type: ApiModelClass.CustomerGroup },
                        ],
                    }),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    constructor(
        @Inject(CustomerGroupService) private customerGroupService: CustomerGroupService,
        @Inject(QueryParamsService) private searchParams: QueryParamsService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(PromptDialogService) private promptDialogService: PromptDialogService,
    ) {
        this.filter.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
        ).subscribe(() => {
            this.table?.refresh();
        });
    }

    createGroup() {
        this.promptDialogService.open('text', {
            formControl: new FormControl<string>('', { validators: Validators.required }),
            title: this.translate.t('CREATE_CUSTOMER_GROUP', 'admin'),
            label: this.translate.t('NAME'),
            confirmText: this.translate.t('CREATE'),
        }).afterClosed().subscribe((name) => {
            if (!name) {
                return;
            }

            this.customerGroupService.create(name).subscribe(() => {
                this.table?.refresh();
            });
        });
    }

    updateGroup(cell: DataTableButtonCell<CustomerGroup>) {
        this.promptDialogService.open('text', {
            formControl: new FormControl<string>(cell.item.name, { validators: Validators.required }),
            title: this.translate.t('UPDATE_CUSTOMER_GROUP', 'admin'),
            label: this.translate.t('NAME'),
            confirmText: this.translate.t('UPDATE'),
        }).afterClosed().pipe(tap((name) => {
            if (!name) {
                cell.disabled.set(false);
                return;
            }

            this.customerGroupService.update(cell.item.id, name).subscribe(() => {
                this.table?.refresh();
            });
        })).subscribe();
    }

    deleteGroup(cell: DataTableButtonCell<CustomerGroup>) {
        this.confirmDialogService.delete({
            title: this.translate.t('DELETE_GROUP', 'admin'),
            text: this.translate.t('DELETE_GROUP_TEXT', 'admin', { name: cell.item.name }),
            confirmText: this.translate.t('DELETE'),
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.customerGroupService.delete(cell.item.id).subscribe(() => {
                this.table?.refresh();
            });
        });
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        this.searchParams.set([ {
            key: 'filter',
            value: this.filter.value,
        } ]);

        this.request = this.customerGroupService.getAll({
            ...pagination,
            filter: this.filter.value || undefined,
            'count[]': [ 'activeMemberships', 'memberships' ],
        }).pipe(
            tap((result) => (this.total = result.total)),
        );
    }
}
