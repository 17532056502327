import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable, tap } from 'rxjs';
import { EawUrl } from '../angularjs/modules/resource/url.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    // This interceptor checks that the url belongs to us
    // And if it doesn't, then we don't propagate it further down the chain
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const url = EawUrl.isApiUrl(request.url) ? request.url : EawUrl.getUrl(request.url);
        if (!EawUrl.isApiUrl(url)) {
            return EMPTY;
        }

        return next.handle(request.clone({ url })).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    const headerApiUrl = event.headers.get('x-api-url') || '';

                    if (EawUrl.isApiUrl(headerApiUrl)) {
                        EawUrl.set(headerApiUrl);
                    }
                }
            }),
        );
    }
}
