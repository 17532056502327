// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';
module('eaw.misc').filter('roundquarter', () => function(number) {
    const num = parseFloat(number);
    if (!Number.isFinite(num)) {
        console.warn('Not a finite number', num);
        return t('NA');
    }
    return (Math.round(num * 4) / 4).toFixed(2);
});
