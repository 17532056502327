import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse, AssocPaginatedResponse } from '../../../../shared/interfaces/paginated-response';
import { SimplePermission } from '../../../../shared/interfaces/simple-permission';
import { PaginationOptions } from '../../../../shared/interfaces/pagination-options';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Permission } from '../../../../shared/models/permission';
import { IGNORE_ERROR } from '../../../../shared/http/http-contexts';

export interface ExistingPermissionResponse {
    name: string;
    description: string;
}

type GetAllOptions = { pagination?: PaginationOptions, ignoreStatus?: number[] };

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    private readonly http = inject(HttpClient);

    getAll(options: PaginationOptions = {}): Observable<AssocPaginatedResponse<ExistingPermissionResponse>> {
        return this.http.get<AssocPaginatedResponse<ExistingPermissionResponse>>('/permissions', { params: { ...options } });
    }

    getAllForUserGroup(userGroupId: number, options: GetAllOptions = {}): Observable<ArrayPaginatedResponse<Permission>> {
        const context = new HttpContext();
        if (options.ignoreStatus) {
            context.set(IGNORE_ERROR, options.ignoreStatus);
        }
        return this.http.get<ArrayPaginatedResponse<Permission>>(`/user_groups/${userGroupId}/permissions`, { params: { ...options.pagination } });
    }

    createForUserGroup(userGroup: number, permission: SimplePermission): Observable<Permission> {
        return this.http.post<Permission>(`/user_groups/${userGroup}/permissions`, permission);
    }

    updateForUserGroup(userGroup: number, permission: string, value: boolean): Observable<Permission> {
        return this.http.put<Permission>(`/user_groups/${userGroup}/permissions/${permission}`, { value });
    }

    deleteForUserGroup(userGroup: number, permission: string): Observable<undefined> {
        return this.http.delete<undefined>(`/user_groups/${userGroup}/permissions/${permission}`);
    }

    // For leader role
    getAllForRole(customerId: number, roleId: number, options: PaginationOptions = {}): Observable<ArrayPaginatedResponse<SimplePermission>> {
        return this.http.get<ArrayPaginatedResponse<SimplePermission>>(`/customers/${customerId}/roles/${roleId}/permissions`, { params: { ...options } });
    }

    // For leader role
    getForRole(customerId: number, roleId: number, permissionId: number): Observable<SimplePermission> {
        return this.http.get<SimplePermission>(`/customers/${customerId}/roles/${roleId}/permissions/${permissionId}`);
    }

    // For leader roles
    createForRole(customerId: number, roleId: number, permission: SimplePermission): Observable<SimplePermission> {
        return this.http.post<SimplePermission>(`/customers/${customerId}/roles/${roleId}/permissions`, permission);
    }

    // For leader roles
    updateForRole(customerId: number, roleId: number, permissionId: number | string, value: boolean): Observable<SimplePermission> {
        return this.http.put<SimplePermission>(`/customers/${customerId}/roles/${roleId}/permissions/${permissionId}`, { value });
    }

    // For leader roles
    deleteForRole(customerId: number, roleId: number, permissionId: number | string): Observable<undefined> {
        return this.http.delete<undefined>(`/customers/${customerId}/roles/${roleId}/permissions/${permissionId}`);
    }

    // For permission sets
    createForSet(permissionSetId: number, permission: SimplePermission): Observable<SimplePermission> {
        return this.http.post<SimplePermission>(`/permission_sets/${permissionSetId}/permissions`, permission);
    }

    // For permission sets
    updateForSet(permissionSetId: number, permissionId: number | string, value: boolean): Observable<SimplePermission> {
        return this.http.put<SimplePermission>(`/permission_sets/${permissionSetId}/permissions/${permissionId}`, { value });
    }

    // For permission sets
    deleteForSet(permissionSetId: number, permissionId: number | string): Observable<undefined> {
        return this.http.delete<undefined>(`/permission_sets/${permissionSetId}/permissions/${permissionId}`);
    }
}
