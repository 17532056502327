<eaw-page-header>
    <span title>{{ 'STATUS_plural' | translate:'todo' | async }}</span>
</eaw-page-header>

<div id="cards-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'DEFAULT_STATUS' | translate:'todo' | async }}</mat-card-title>
            <mat-card-subtitle>{{ 'DEFAULT_STATUS_DESCRIPTION' | translate:'todo' | async }}</mat-card-subtitle>

            <div>
                <button class="tw-mr-16" mat-icon-button
                        (click)="showHistoricalDefaults.set(!showHistoricalDefaults())">
                    <mat-icon>history</mat-icon>
                </button>

                    <button mat-mini-fab
                            color="accent"
                            eawPermission
                            [permissions]="canCreate"
                            (click)="create('Default')">
                        <mat-icon>add</mat-icon>
                    </button>
            </div>
        </mat-card-header>

        <mat-card-content>
            <mat-list>
                @for (status of getStatusesFor('Default'); track status.id) {
                    <mat-list-item>
                        <div matListItemLine>{{ status.name }}</div>
                        <div matListItemLine class="count-line">{{ status.itemsCount }}</div>

                        <div matListItemMeta>
                            <button mat-icon-button color="primary" (click)="edit(status)">
                                <mat-icon>edit</mat-icon>
                            </button>

                            <button style="visibility: hidden" mat-icon-button>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </mat-list-item>
                }
                @for (status of getStatusesFor('Initial'); track status.id) {
                    <mat-list-item>
                        <div matListItemLine style="text-decoration: line-through">{{ status.name }}</div>
                        <div matListItemLine class="count-line">{{ status.itemsCount }}</div>

                        <div matListItemMeta>
                            <button mat-icon-button color="primary" (click)="edit(status)"
                                    eawPermission
                                    [permissions]="canUpdate(status.id)">
                                <mat-icon>edit</mat-icon>
                            </button>

                                <button eawMaterialColor="red-500"
                                        eawPermission
                                        [permissions]="canDelete(status.id)"
                                        (click)="delete(status)"
                                        [style.visibility]="status.itemsCount ? 'hidden' : 'visible'"
                                        mat-icon-button>
                                    <mat-icon>delete</mat-icon>
                                </button>
                        </div>
                    </mat-list-item>
                }
            </mat-list>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'IN_PROGRESS_TYPE' | translate:'todo' | async }}</mat-card-title>
            <mat-card-subtitle>{{ 'IN_PROGRESS_DESCRIPTION' | translate:'todo' | async }}</mat-card-subtitle>

            <button mat-mini-fab
                    color="accent"
                    eawPermission
                    [permissions]="canCreate"
                    (click)="create('InProgress')">
                <mat-icon>add</mat-icon>
            </button>
        </mat-card-header>

        <mat-card-content>
            <mat-list>
                @for (status of getStatusesFor('InProgress'); track status.id) {
                    <mat-list-item>
                        <div matListItemLine>{{ status.name }}</div>
                        <div matListItemLine class="count-line">{{ status.itemsCount }}</div>

                        <div matListItemMeta>
                            <button mat-icon-button color="primary"
                                    (click)="edit(status)"
                                    eawPermission
                                    [permissions]="canUpdate(status.id)"
                                    [style.visibility]="canUpdate(status.id) ? 'visible' : 'hidden'">
                                <mat-icon>edit</mat-icon>
                            </button>

                            <button eawMaterialColor="red-500"
                                    eawPermission
                                    [permissions]="canDelete(status.id)"
                                    [style.visibility]="status.itemsCount ? 'hidden' : 'visible'"
                                    (click)="delete(status)"
                                    mat-icon-button>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </mat-list-item>
                }
            </mat-list>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'DONE_TYPE' | translate:'todo' | async }}</mat-card-title>
            <mat-card-subtitle>{{ 'DONE_TYPE_DESCRIPTION' | translate:'todo' | async }}</mat-card-subtitle>
            <button mat-mini-fab color="accent"
                    eawPermission
                    [permissions]="canCreate"
                    (click)="create('Done')">
                <mat-icon>add</mat-icon>
            </button>
        </mat-card-header>

        <mat-card-content>
            <mat-list>
                @for (status of getStatusesFor('Done'); track status.id) {
                    <mat-list-item>
                        <div matListItemLine>{{ status.name }}</div>
                        <div matListItemLine class="count-line">{{ status.itemsCount }}</div>

                        <div matListItemMeta>
                            <button mat-icon-button color="primary" (click)="edit(status)"
                                    [style.visibility]="canUpdate(status.id) ? 'visible' : 'hidden'">
                                <mat-icon>edit</mat-icon>
                            </button>

                            <button eawMaterialColor="red-500"
                                    eawPermission
                                    [permissions]="canDelete(status.id)"
                                    (click)="delete(status)"
                                    [style.visibility]="status.itemsCount ? 'hidden' : 'visible'"
                                    mat-icon-button>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </mat-list-item>
                }
            </mat-list>
        </mat-card-content>
    </mat-card>
</div>
