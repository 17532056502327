import { ApiResponse } from '../interfaces/api-response';
import { stringToDateTime } from '../utils/string-to-date-time';
import { DateTime } from 'luxon';

export interface StackResponse extends ApiResponse {
    id: number;
    region: string;
    name: string;
    provisioned: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export class Stack {
    public readonly id: number;
    public readonly region: string;
    public readonly name: string;
    public readonly provisioned: boolean;
    public readonly createdAt: DateTime;
    public readonly updatedAt: DateTime;
    public readonly deletedAt: DateTime | undefined;

    constructor(data: StackResponse) {
        this.id = data.id;
        this.region = data.region;
        this.name = data.name;
        this.provisioned = data.provisioned;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : undefined;
    }
}
