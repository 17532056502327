import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { VacationDay } from '../../models/vacation-day';
import { AssignVacationDaysDialogComponent } from './assign-vacation-days-dialog.component';

export interface AssignVacationDaysDialogData extends DialogData {
    customerId: number;
    employeeId: number;
    mode: 'add' | 'deduct';
}

@Injectable({
    providedIn: 'root',
})
export class AssignVacationDaysDialogService {

    constructor(@Inject(MatDialog) private dialog: MatDialog) {
    }

    open(mode: 'add' | 'deduct', customerId: number, employeeId: number) {
        return this.dialog.open<AssignVacationDaysDialogComponent, AssignVacationDaysDialogData, VacationDay>(AssignVacationDaysDialogComponent, {
            data: {
                size: DialogSize.Medium,
                customerId,
                employeeId,
                mode,
            },
        });
    }
}
