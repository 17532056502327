<eaw-paid-time-overview-employee [employee]="employee()" [overview]="employeeOverview"></eaw-paid-time-overview-employee>

<ng-container *ngFor="let dayAbsence of dayAbsences">
    <eaw-paid-time-overview-segment [segment]="dayAbsence.segment"
                                    mat-ripple
                                    (segmentUpdated)="updateEmployee()"
                                    [style.grid-column-start]="dayAbsence.columnStart"
                                    [style.grid-column-end]="dayAbsence.columnEnd">
    </eaw-paid-time-overview-segment>
</ng-container>

<ng-container *ngFor="let vacation of vacations">
    <eaw-paid-time-overview-segment [segment]="vacation.segment"
                                    mat-ripple
                                    (segmentUpdated)="updateEmployee()"
                                    [style.grid-column-start]="vacation.columnStart"
                                    [style.grid-column-end]="vacation.columnEnd">
    </eaw-paid-time-overview-segment>
</ng-container>

<ng-container class="segments-container" *ngFor="let item of dateOverviews">
    <ng-container *ngFor="let s of item.filteredSegments">
        <eaw-paid-time-overview-segment *ngIf="s.type.key !== 'dayAbsence' && s.type.key !== 'vacation'"
                                        [style.grid-column-start]="item.column"
                                        [segment]="s" mat-ripple
                                        (segmentUpdated)="updateEmployee()">
        </eaw-paid-time-overview-segment>
    </ng-container>
</ng-container>
