import { t } from 'i18next';
import { orderBy } from 'lodash-es';
import { module } from 'angular';
import { groupBy } from 'lodash-es';
import { Storage } from '../../../shared/utils/storage';
import { Products } from '../../../shared/enums/products';
import type { WebsocketService } from '../../../shared/services/websocket.service';
import { UploadHrFileDialogService } from '../../dialogs/upload-hr-file-dialog/upload-hr-file-dialog.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { HrFileType } from '../../models/hr-file-type';
import { OldClassAsResponse } from '../../../shared/utils/old-class-as-response';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
import { AngularJsDestroyRef } from '../../../shared/angularjs/angularjs-destroy-ref.class';

export default class HrEmployeeFilesController {
    $state;
    WebsocketDowngrade;
    CustomerProductServiceDowngrade: CustomerProductService;
    UploadHrFileDialogServiceDowngrade: UploadHrFileDialogService;
    HrFactory;
    HrFile;
    HrFileType;
    $mdDialog;
    hrEmp: any;
    customer: any;
    signables: any;
    hasDigitalSigning: any;
    lsKey: any;
    include_expired: any;
    buttons: any;
    types: any;
    files: any;
    loaded: any;
    filesInProgress = 0;
    ToastService: SnackBarService;
    canCreate = false;

    destroyRef = new AngularJsDestroyRef();

    static get $inject() {
        return [ 'CustomerProductServiceDowngrade', 'ToastService', '$state', 'WebsocketDowngrade', 'UploadHrFileDialogServiceDowngrade', 'HrFactory', 'HrFile', 'HrFileType', '$mdDialog' ];
    }

    constructor(CustomerProductServiceDowngrade: CustomerProductService, ToastService: SnackBarService, $state: any, WebsocketDowngrade: WebsocketService, UploadHrFileDialogServiceDowngrade: any, HrFactory: any, HrFile: any, HrFileType: any, $mdDialog: any) {
        this.ToastService = ToastService;
        this.CustomerProductServiceDowngrade = CustomerProductServiceDowngrade;
        this.$state = $state;
        this.WebsocketDowngrade = WebsocketDowngrade;
        this.UploadHrFileDialogServiceDowngrade = UploadHrFileDialogServiceDowngrade;
        this.HrFactory = HrFactory;
        this.HrFile = HrFile;
        this.HrFileType = HrFileType;
        this.$mdDialog = $mdDialog;
    }

    async $onInit() {
        this.CustomerProductServiceDowngrade.hasProducts(this.customer.id, [ Products.DigitalSigning ]).subscribe((has) => {
            this.hasDigitalSigning = has;
        });

        this.lsKey = Storage.prefix('hr_employee_ls');
        this.include_expired = (await Storage.getItem(this.lsKey)) || false;
        this.canCreate = CurrentOld.can(`customers.${this.customer.id}.employees.${this.hrEmp.id}.hr_files.create`);

        this.buttons = [
            {
                icon: 'information',
                tooltip: 'INFORMATION',
                disabled: false,
                invisible(file: any) {
                    return file.missing;
                },
                click(file: any) {
                    const res = file.goToFile();
                    if (res) {
                        this.disabled = true;
                    }
                },
            },
            {
                icon: 'add',
                invisible(file: any) {
                    return !file.missing || !this.canCreate;
                },
                tooltip: 'hr:ADD_FILE',
                click: this.addFile.bind(this),
            },
            {
                icon: 'border_color',
                tooltip: 'SIGN',
                invisible(file: any) {
                    return !file.signable || !file.canSign();
                },
                type: 'accent',
                click(file: any) {
                    file.goToSign();
                },
            },
            {
                icon: 'download',
                tooltip: 'DOWNLOAD',
                invisible(file: any) {
                    return !file.showDownloadButton;
                },
                type: 'accent',
                click(file: any) {
                    file.download();
                },
            },
            {
                icon: 'delete',
                type: 'warn',
                invisible(file: any) {
                    return !file.canDelete();
                },
                tooltip: 'DELETE',
                click: this.delete.bind(this),
            },
        ];

        this.WebsocketDowngrade.listenEmployeeHrFiles(this.customer.id, this.hrEmp.id, 'created', (file) => {
            void this.getOverview();
            void this.ToastService.t('FINISHED_PROCESSING_FILE', 'hr', { name: file.name });
            this.filesInProgress = Math.max(0, this.filesInProgress - 1);
        }, this.destroyRef);

        this.getOverview();
    }

    $onDelete() {
        this.destroyRef.destroy();
    }

    delete(file: any) {
        this.$mdDialog.show(this.$mdDialog.confirm()
            .theme('delete')
            .title(t('DELETE'))
            .textContent(t('hr:DELETE_FILE_TEXT'))
            .ok(t('DELETE'))
            .cancel(t('CANCEL'))).then(() => {
            file.delete().then(() => {
                this.getOverview();
            });
        });
    }

    getOverview() {
        void Storage.setItem(this.lsKey, this.include_expired);

        return this.HrFactory.getEmployeeOverview({
            customer: this.customer,
            employee: this.hrEmp,
            include_expired: this.include_expired,
            with: [ 'attachments' ],
        }).$promise.then((resp: any) => {
            this.onGotOverview(resp.types, resp.files);
            return resp;
        });
    }

    onGotOverview(types: any, files: any) {
        const fileTypes = Object.values(types).map((t) => new this.HrFileType(t));

        const list = Object.values(files).map((f: any) => new this.HrFile({
            ...f,
            type: fileTypes.find((t) => t.id === f.type_id),
            missing: false,
        }, this.customer));

        this.types = fileTypes;

        this.addMissingFileTypes(list);

        // Grouping and sorting
        const groupedList = groupBy(list, (f) => f.type_id);
        const orderedList = orderBy(groupedList, (arr) => arr[0].type?.name.toLowerCase(), 'asc');

        this.files = orderedList.reduce((acc, files) => {
            const orderedFiles = files.sort((fileA, fileB) => +fileB.created_at - +fileA.created_at);

            return acc.concat([ {
                type: orderedFiles[0].type,
                typeId: orderedFiles[0].type_id,
                files: orderedFiles,
                missingCount: files.filter((f) => f.missing).length,
                unsigned: files.filter((f) => !f.missing && !f.isExpired() && f.type?.isSignable() && !f.isSigned).length,
                expired: files.filter((f) => f.isExpired()).length == files.length,
            } ]);
        }, []);

        this.loaded = true;
    }

    addMissingFileTypes(files: any) {
        // Add missing ts FIRST
        this.types?.forEach((type: any) => {
            const hasType = files.find((f: any) => f.type_id === type.id);
            if (!hasType) {
                files.push(new this.HrFile({
                    missing: true,
                    type_id: type.id,
                    type,
                }, this.customer));
            }
        });
    }

    addFile(fileType: any) {
        const type = fileType ? new HrFileType(OldClassAsResponse(fileType)) : undefined;
        this.UploadHrFileDialogServiceDowngrade.open({
            customerId: this.customer.id,
            employees: [ this.hrEmp.id ],
            type,
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            if (result === 'Custom') {
                this.getOverview();
            } else {
                this.filesInProgress += 1;
            }
            if (result === 'Reload') {
                setTimeout(() => window.location.reload(), 5000);
            }
        });
    }
}

module('eaw.hr').component('hrEmployeeFiles', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-bind="hefCtrl.hrEmp.name"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="hefCtrl.include_expired"
                        ng-change="hefCtrl.getOverview()"
                        icon="history"
                        i18n-tooltip="hr:INCLUDE_EXPIRED">
        </eaw-toggle-btn>

        <card-btn-float ng-if="hefCtrl.canCreate" on-click="hefCtrl.addFile()"></card-btn-float>
    </md-card-header>
</md-card>

<md-card ng-if="hefCtrl.filesInProgress" md-colors="{background: 'blue-100'}">
    <md-card-content style="display: flex; align-items: center; gap: 16px">
        <md-progress-circular md-diameter="24"></md-progress-circular>
        <span ng-i18next="hr:PROCESSING_FILES"></span>
    </md-card-content>
</md-card>

<md-card ng-if="hefCtrl.signables.status" md-colors="{background: 'amber-300'}">
    <md-card-content ng-if="hefCtrl.signables.status == 403">
        <span ng-i18next="hr:SIGNABLES_403"></span>
    </md-card-content>
    <md-card-content ng-if="hefCtrl.signables.status == 404">
        <span ng-i18next="hr:SIGNABLES_404"></span>
    </md-card-content>
</md-card>

<md-progress-circular ng-if="!hefCtrl.loaded" class="md-progress-center tw-mt-20" md-diameter="40"></md-progress-circular>

<eaw-accordion multi-toggle="true">
    <eaw-accordion-panel ng-repeat="typeFiles in hefCtrl.files track by $index" toggled="typeFiles.toggled">
        <panel-header layout="row">
            <md-button eaw-invisible="{{!typeFiles.expiredCount}}"
                       class="md-icon-button md-accent md-raised md-normal-size"
                       stop-propagate
                       ng-click="hefCtrl.addFile(typeFiles.files[0].type)">
                <md-icon ng-bind="'add'"></md-icon>
            </md-button>

            <span ng-if="typeFiles.type" ng-bind="typeFiles.type.name" flex="100"></span>
            <span ng-if="!typeFiles.type" flex="100" ng-i18next="[i18next]({id: typeFiles.typeId})hr:MISSING_FILE_TYPE"></span>

            <div layout="column" class="text-ws-nowrap" md-colors="{color: 'red'}">
                <small ng-if="typeFiles.missingCount" ng-i18next="[i18next]({count: typeFiles.missingCount})hr:MISSING_FILE"></small>
                <small ng-if="typeFiles.expired" ng-i18next="[i18next]({count: typeFiles.expiredCount})hr:EXPIRED_FILE"></small>
                <small ng-if="typeFiles.unsigned" ng-i18next="[i18next]({count: typeFiles.signCount})hr:MISSING_SIGNATURE"></small>
            </div>
        </panel-header>

        <panel-content id="small-panel" class="tw-p-0" hide-gt-xs>
            <md-card class="tw-m-0" ng-repeat="file in typeFiles.files track by file.id" ng-if="file.id">
                <md-card-header md-colors="{background: file.isExpired() ? 'yellow-100' : 'white'}">
                    <md-card-header-text>
                        <span class="md-title" ng-bind="file.name"></span>
                    </md-card-header-text>

                    <md-button class="md-icon-button" ng-click="file.goToFile()">
                        <md-icon ng-bind="'info'"></md-icon>
                    </md-button>
                </md-card-header>

                <md-card-content class="tw-p-0">
                    <md-list class="tw-p-0 md-dense" md-colors="{background: file.isExpired() ? 'yellow-50' : 'white'}">
                        <!-- signable and CAN sign -->
                        <md-list-item class="md-2-line" ng-if="file.signable && file.canSign()" ng-click="file.goToSign()">
                            <md-icon ng-bind="'verified_user'" md-colors="{color: 'red'}"></md-icon>

                            <div class="md-list-item-text">
                                <h3 ng-if="!file.employee_signed_count" ng-i18next="hr:UNSIGNED"></h3>
                            </div>

                            <md-button class="md-secondary md-icon-button" ng-click="file.goToSign()">
                                <md-icon ng-bind="'edit'"></md-icon>
                            </md-button>

                            <md-divider></md-divider>
                        </md-list-item>

                        <!-- signable and ALREADY signed -->
                        <md-list-item class="md-2-line" ng-if="file.employee_signed_count">
                            <md-icon ng-bind="'verified_user'" md-colors="{color: 'green'}"></md-icon>

                            <div class="md-list-item-text">
                                <h3 ng-bind="file.getSignedWith()"></h3>
                            </div>

                            <md-divider></md-divider>
                        </md-list-item>

                        <!-- shows created date and handles deletable -->
                        <md-list-item ng-if="file.created_at && file.canDelete()" class="md-2-line" ng-click="hefCtrl.delete(file)">
                            <md-icon ng-bind="'schedule'"></md-icon>

                            <div class="md-list-item-text">
                                <h3 ng-i18next="CREATED"></h3>
                                <eaw-time time="file.created_at"></eaw-time>
                            </div>

                            <md-button class="md-secondary md-icon-button" ng-click="hefCtrl.delete(file)">
                                <md-icon ng-bind="'delete'"></md-icon>
                            </md-button>

                            <md-divider></md-divider>
                        </md-list-item>

                        <!-- shows created date and not deletable -->
                        <md-list-item ng-if="file.created_at && !file.canDelete()" class="md-2-line">
                            <md-icon ng-bind="'schedule'"></md-icon>

                            <div class="md-list-item-text">
                                <h3 ng-i18next="CREATED"></h3>
                                <eaw-time time="file.created_at"></eaw-time>
                            </div>

                            <md-divider></md-divider>
                        </md-list-item>

                        <!-- shows expiration date -->
                        <md-list-item ng-if="file.expires_at" class="md-2-line">
                            <md-icon ng-bind="'schedule'"></md-icon>

                            <div class="md-list-item-text">
                                <h3 ng-i18next="hr:EXPIRY_DATE"></h3>
                                <eaw-time time="file.expires_at" type="calendar"></eaw-time>
                            </div>

                            <md-divider></md-divider>
                        </md-list-item>
                    </md-list>
                </md-card-content>
            </md-card>
        </panel-content>

        <panel-content id="big-panel" class="tw-p-0" hide-xs>
            <table class="table table-vmiddle table-bordered-bottom">
                <thead>
                <tr>
                    <th ng-i18next="hr:FILENAME"></th>
                    <th ng-i18next="hr:UPLOADED"></th>
                    <th ng-i18next="hr:EXPIRY_DATE"></th>
                    <th ng-if="hefCtrl.hasDigitalSigning" ng-i18next="hr:SIGNED" class="text-center"></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr ng-repeat="file in typeFiles.files track by file.id" ng-if="file.id" md-colors="{background: file.isExpired() ? 'yellow-50' : 'white'}">
                    <td ng-bind="file.name"></td>
                    <td>
                        <div layout="column" ng-if="file.created_at">
                            <eaw-time time="file.created_at"></eaw-time>
                            <small ng-bind="file.created_at | moment:'LLLL'"></small>
                        </div>
                    </td>
                    <td>
                        <div layout="column" ng-if="file.expires_at">
                            <eaw-time time="file.expires_at" type="calendar"></eaw-time>
                        </div>
                    </td>
                    <td ng-if="hefCtrl.hasDigitalSigning" class="text-center">
                        <div ng-if="file.signable" layout-align="center center" layout="column">
                            <md-icon ng-if="file.employee_signed_count" ng-bind="'verified_user'" md-colors="{color: 'green'}"></md-icon>
                            <md-icon ng-if="!file.employee_signed_count" ng-bind="'verified_user'" md-colors="{color: 'red'}"></md-icon>
                            <small class="tw-pt-4" ng-bind="file.getSignedWith()"></small>
                        </div>

                        <span ng-if="!file.signable" class="text-center" ng-i18next="hr:NOT_SIGNABLE"></span>
                    </td>
                    <td>
                        <div>
                            <eaw-icon-button
                                    stop-propagate
                                    ng-repeat="button in hefCtrl.buttons track by $index"
                                    icon="{{button.icon}}"
                                    type="{{button.type}}"
                                    ng-click="button.click(file)"
                                    ng-disabled="button.disabled"
                                    eaw-invisible="{{button.invisible(file)}}">
                            </eaw-icon-button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </panel-content>

        <panel-content ng-if="typeFiles.missingCount && hefCtrl.canCreate" md-ink-ripple="#000">
            <div class="missing-upload" ng-click="hefCtrl.addFile(typeFiles.files[0].type)">
                <md-icon ng-bind="'upload_file'" md-colors="{color: 'green'}"></md-icon>
                <span ng-i18next="hr:CLICK_ADD_MISSING"></span>
            </div>
        </panel-content>

        <panel-content ng-if="typeFiles.missingCount && !hefCtrl.canCreate" class="tw-text-center">
            <md-icon ng-bind="'priority_high'" md-colors="{color: 'red'}"></md-icon>
            <span ng-i18next="hr:MISSING_FILE" md-colors="{color: 'red'}"></span>
        </panel-content>
    </eaw-accordion-panel>
</eaw-accordion>
`,
    controllerAs: 'hefCtrl',
    bindings: {
        hrEmp: '<',
        customer: '<',
        signables: '<',
    },
    controller: HrEmployeeFilesController,
});
