// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

module('eaw.company').component('employeeExternal', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="company:EXTERNAL_AT"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="$empExt.showInactive"
                        ng-disabled="$empExt.gettingData"
                        icon="history"
                        ng-change="$empExt.getExternal()"
                        i18n-tooltip="HISTORY">
        </eaw-toggle-btn>
    </md-card-header>
    <md-card-content>
        <eaw-accordion multi-toggle="true">
            <eaw-accordion-panel toggled="true">
                <panel-title>
                    <span bo-i18next="company:CURRENTLY_EXTERNAL"></span>
                </panel-title>
                <panel-content class="tw-p-0">
                    <md-progress-circular  class="md-progress-center tw-mt-16 tw-mb-16" ng-if="$empExt.gettingData"></md-progress-circular>

                    <div class="md-padding" layout="column" layout-align="center center" ng-if="!$empExt.gettingData && !$empExt.active.length">
                        <md-icon class="s36" ng-bind="'person'"></md-icon>
                        <span class="tw-pt-12" ng-i18next="[i18next]({name: $empExt.employee.name})company:NO_LOAN_ATM"></span>
                    </div>

                    <md-list class="md-dense" ng-if="!$empExt.gettingData && $empExt.active.length">
                        <md-list-item class="md-3-line" ng-repeat="active in $empExt.active">
                            <div class="md-list-item-text">
                                <h3 ng-bind="active.customer.name"></h3>
                                <h4 ng-if="active.number">#{{active.number}}</h4>
                                <p ng-i18next="[i18next]({from: active.from.format('LLL'), to: active.to.format('LLL')})FROM_TO"></p>
                            </div>

                            <md-button class="md-secondary md-icon-button" ng-click="$empExt.update(active)">
                                <md-icon ng-bind="'edit'"></md-icon>
                            </md-button>

                            <md-divider ng-if="!$last"></md-divider>
                        </md-list-item>
                    </md-list>
                </panel-content>
            </eaw-accordion-panel>

            <eaw-accordion-panel>
                <panel-title>
                    <span bo-i18next="company:HISTORY_EXTERNAL"></span>
                </panel-title>
                <panel-content class="tw-p-0">

                    <div class="md-padding" layout="column" layout-align="center center" ng-if="!$empExt.gettingData && !$empExt.inactive.length">
                        <md-icon class="s36" ng-bind="'person'"></md-icon>
                        <span class="tw-pt-12" ng-i18next="company:NO_HISTORY_EXTERNAL"></span>
                    </div>

                    <md-list class="md-dense">
                        <md-list-item class="md-3-line" ng-repeat="inactive in $empExt.inactive">
                            <div class="md-list-item-text">
                                <h3 ng-bind="inactive.customer.name"></h3>
                                <h4 ng-if="inactive.number">#{{inactive.number}}</h4>
                                <p ng-i18next="[i18next]({from: inactive.from.format('LLL'), to: inactive.to.format('LLL')})FROM_TO"></p>
                            </div>
                            <md-divider ng-if="!$last"></md-divider>
                        </md-list-item>
                    </md-list>
                </panel-content>
            </eaw-accordion-panel>
        </eaw-accordion>
    </md-card-content>
</md-card>
`,
    controllerAs: '$empExt',
    bindings: {
        customer: '<',
        employee: '<',
    },
    controller: [ 'EmployeeService', 'editExternalEmployeeDialog', 'ExternalEmployeeFactory', 'ToastService', function(EmployeeService, editExternalEmployeeDialog, ExternalEmployeeFactory, ToastService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.gettingData = true;
            ctrl.active = [];
            ctrl.inactive = [];

            ctrl.getExternal();
        };

        ctrl.update = async (external) => {
            const result = await editExternalEmployeeDialog.open(external, external.customer);
            const resp = await ExternalEmployeeFactory.update(external.customer_id, external.id, result).$promise;

            // Remove old
            ctrl.active = ctrl.active.filter((ex) => ex.id !== external.id);

            ctrl.assign(resp);
            ctrl.sort();
            ToastService.tToast('UPDATED');
        };

        ctrl.sort = () => {
            const sortFunc = (a, b) => a.from - b.from;
            ctrl.active = ctrl.active.sort(sortFunc);
            ctrl.inactive = ctrl.inactive.sort(sortFunc);
        };

        ctrl.assign = (external) => {
            const activity = external.to == null || external.to?.isAfter(moment()) ? ctrl.active : ctrl.inactive;

            if (activity.find((a) => a.id === external.id)) {
                return;
            }

            activity.push(external);
        };

        ctrl.getExternal = () => {
            ctrl.gettingData = true;

            return EmployeeService.getLoansForEmployee({
                customerId: ctrl.customer.id,
                employeeId: ctrl.employee.id,
                include_inactive: ctrl.showInactive,
                per_page: 999,
            }).then((resp) => {
                resp.data.forEach(ctrl.assign);
                ctrl.sort();
                ctrl.gettingData = false;
            });
        };
    } ],
});
