import { Transition } from '@uirouter/angularjs';

export function getTransitionCustomerId(transition: Transition) {
    const params = transition.params();
    const name = transition.to().name;

    switch (true) {
        case name?.includes('chain_ops') && 'location' in params:
            return parseInt(params?.['location']);
        case name?.includes('customers/view/reports') && 'id' in params:
            return parseInt(params?.['id']);
        case name?.includes('customers/view/signables') && 'id' in params:
            return parseInt(params?.['id']);
        case name?.includes('customers/view/default_files') && 'id' in params:
            return parseInt(params?.['id']);
        default:
            return undefined;
    }
}
