import { module } from 'angular';
import { Products } from '../../../shared/enums/products';
import { Pagination } from '../../../shared/utils/pagination';
import { NavTabsComponent } from '../../../shared/components/nav-tabs/nav-tabs.component';
import { ManageTimepunchesOverviewComponent } from '../../pages/manage-timepunches-overview/manage-timepunches-overview.component';
import { employeeTimepunchStatusTabsUpgradeComponent } from './show/employee-timepunch-status-tab.upgrade';
import { ManageTimepunchesComponent } from '../../pages/manage-timepunches/manage-timepunches.component';
import { CurrentService } from '../../../shared/services/current.service';
import { createNavTabsState, createState } from '../../../shared/utils/create-state';
import { navTabsTabsResolver } from '../../../shared/resolvers/nav-tabs-tabs.resolver';
import { lastValueFrom, of } from 'rxjs';
import { ReportVerificationComponent } from '../../pages/report-verification/report-verification.component';
import { staticResolver } from '../../../shared/resolvers/static.resolver';
import { currentResolver } from '../../../shared/resolvers/current.resolver';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
import { Namespace } from '../../../shared/enums/namespace';

module('eaw.payroll.timepunches', []).config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state(`eaw/app/payroll/my/timepunches`, {
        parent: 'eaw/app/payroll/my',
        url: '/timepunches',
        views: {
            myPayrollView: 'myTimepunches',
        },
        data: {
            breadcrumb: { key: 'MY_TIMEPUNCHES', ns: 'navigation' },
            permissions: [
                `customers.{customer}.employees.{employee}.timepunches.*.get`,
            ],
            requiresEmployee: true,
            allowExternal: true,
        },
        resolve: {
            businessUnits: [ 'BusinessUnitDowngraded', 'customer', function(BusinessUnitDowngraded: any, customer: any) {
                if (!customer.hasProduct(Products.BusinessUnits) || !CurrentOld.can(`customers.${customer.id}.business_units.*.get`)) {
                    return [];
                }

                return lastValueFrom(BusinessUnitDowngraded.getAllPages(customer.id, new Pagination({ per_page: 100 })));
            } ],
        },
    });

    $stateProvider.state(`eaw/app/payroll/employee_status`, {
        parent: `eaw/app/payroll/timepunches/all`,
        url: '/employee_status?:tab',
        data: {
            breadcrumb: { key: 'EMPLOYEE_STATUS', ns: 'navigation' },
        },
        component: 'employeeTimepunchStatusTabs',
    });
    createState($stateProvider, {
        name: 'eaw/app/payroll/timepunches/all',
        parent: `eaw/app/payroll`,
        url: '/timepunches',
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        resolve: [
            navTabsTabsResolver(function($transition$) {
                const current = $transition$.injector().get(CurrentService) as CurrentService;
                const customerProductService = $transition$.injector().get(CustomerProductService) as CustomerProductService;

                return Promise.resolve([
                    {
                        state: 'eaw/app/payroll/timepunches/all/manage',
                        label: { key: 'MANAGE' },
                    },
                    {
                        state: 'eaw/app/payroll/timepunches/all/overview',
                        label: { key: 'OVERVIEW' },
                        show: customerProductService.hasProducts(current.getCustomer().id, [ Products.Scheduling ]),
                    },
                    {
                        state: 'eaw/app/payroll/timepunches/all/employee_status',
                        label: {
                            key: 'EMPLOYEE_STATUS',
                            ns: Namespace.Navigation,
                        },
                    },
                ]);
            }),
        ],
        data: {
            breadcrumb: { key: 'ALL_TIMEPUNCHES', ns: 'navigation' },
        },
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/payroll/timepunches/all/manage`,
        url: '/manage',
        parent: `eaw/app/payroll/timepunches/all`,
        component: ManageTimepunchesComponent,
        data: {
            breadcrumb: { key: 'MANAGE' },
            somePermissions: [
                `customers.{customer}.timepunches.*.get`,
                `customers.{customer}.timepunches.manage`,
            ],
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('userId', 'user', 'id'),
        ],
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/payroll/timepunches/all/overview`,
        url: '/overview',
        parent: `eaw/app/payroll/timepunches/all`,
        component: ManageTimepunchesOverviewComponent,
        data: {
            breadcrumb: { key: 'MANAGE' },
        },
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/payroll/timepunches/all/employee_status`,
        url: '/employee_status',
        parent: `eaw/app/payroll/timepunches/all`,
        component: employeeTimepunchStatusTabsUpgradeComponent,
        data: {
            breadcrumb: { key: 'EMPLOYEE_STATUS', ns: 'navigation' },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/payroll/timepunch_validation',
        parent: `eaw/app/payroll`,
        url: '/timepunch_validation',
        views: {
            'content@': {
                component: ReportVerificationComponent,
            },
        },
        resolve: [
            staticResolver('contentType', 'timepunchValidation'),
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            breadcrumb: { key: 'TIMEPUNCH_VALIDATION', ns: 'reports' },
            requiresEmployee: false,
            permissions: [`customers.{customer}.employees.*.timepunches.*.get`],
            settings: {
                'payroll.timepunch_verification': (val) => val?.asBoolean() === true,
            },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/payroll/monthly_balance_sheet',
        parent: `eaw/app/payroll`,
        url: '/monthly_balance_sheet',
        views: {
            'content@': {
                component: ReportVerificationComponent,
            },
        },
        resolve: [
            staticResolver('contentType', 'monthlyBalance'),
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            breadcrumb: { key: 'CCNT_MONTHLY_BALANCE_SHEET', ns: Namespace.Reports },
            requiresEmployee: false,
            permissions: [`customers.{customer}.employees.{employee}.balances.*.*.get`],
            settings: {
                'payroll.ccnt_monthly_balance_sheet': (val) => val?.asBoolean() === true,
            },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/payroll/monthly_paid_time_sheet',
        parent: `eaw/app/payroll`,
        url: '/monthly_paid_time_sheet',
        views: {
            'content@': {
                component: ReportVerificationComponent,
            },
        },
        resolve: [
            staticResolver('contentType', 'monthlyPaidTime'),
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            breadcrumb: { key: 'CCNT_MONTHLY_BALANCE_SHEET', ns: 'reports' },
            requiresEmployee: false,
            permissions: [`customers.{customer}.employees.{employee}.balances.*.*.get`],
            settings: {
                'switzerland.monthly_paid_time_sheet': (val) => val?.asBoolean() === true,
            },
        },
    });
} ]);
