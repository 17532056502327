import { Component, Inject, OnInit, signal, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../../data-table/types/data-table-header';
import { CurrentService } from '../../../../shared/services/current.service';
import { CompanyUserService } from '../../../http/company-user.service';
import { DateTime } from 'luxon';
import { DataTableButtonColumn } from '../../../../data-table/types/data-table-button-column';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, forkJoin, map, of } from 'rxjs';
import { DataTableComponent } from '../../../../data-table/data-table.component';
import { QueryParamsService } from '../../../../shared/services/query-params.service';
import { HeaderFabButton, PageHeaderComponent } from '../../../../shared/components/page-header/page-header.component';
import { PageHeaderButton } from '../../../../shared/components/page-header/classes/page-header-button';
import { UserAccessDialogService } from '../dialog/user-access-dialog.service';
import { TranslateService } from '../../../../shared/services/translate.service';
import { DataTableDateTimeColumn } from '../../../../data-table/types/data-table-date-time-column';
import { DataTableCell } from '../../../../data-table/interfaces/data-table-cell';
import { User } from '../../../../shared/models/user';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { CustomerUser } from '../../../../shared/models/customer-user';
import { UserAccess } from '../../../models/user-access';
import { DateTimeConverter } from '../../../../shared/utils/date-time-converter';
import { PermissionCheckService } from '../../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { mockArrayPaginatedResponse } from '../../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-company-users',
    templateUrl: './company-users.component.html',
    styleUrl: './company-users.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class CompanyUsersComponent implements OnInit, EawDataTable {
    @ViewChild(DataTableComponent) dataTable?: DataTableComponent<CustomerUser>;
    request: DataTableRequest = of(mockArrayPaginatedResponse());
    form = new FormGroup({
        filter: new FormControl<string | null>(null),
    });

    fabButton: HeaderFabButton = {
        click: this.addUser.bind(this),
        hasPermission: () => {
            return forkJoin([
                this.permissionCheckService.isAllowed(`users.create`),
                this.permissionCheckService.isAllowed(`customers.${this.current.getCustomer().id}.users.create`),
            ]).pipe(map(([ global, customer ]) => global && customer));
        },
    };

    pageButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            click: () => this.toggleInactive(),
            active: () => this.includeInactive,
            icon: () => 'history',
            menuText: signal(this.translateService.t('SHOW_INACTIVE')),
        }),
    ];

    includeInactive: boolean = false;
    columns: DataTableColumnType<CustomerUser>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item.user?.name || '',
            header: new DataTableHeader({
                i18n: 'USER',
                sortBy: 'first_name',
            }),
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.user?.email || '',
            header: new DataTableHeader({
                i18n: 'EMAIL',
                sortBy: 'email',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            format: DateTime.DATETIME_MED,
            header: new DataTableHeader({
                i18n: 'FROM',
                sortBy: 'from',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            format: DateTime.DATETIME_MED,
            header: new DataTableHeader({
                i18n: 'TO',
                sortBy: 'to',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'lastActive',
            format: DateTime.DATETIME_MED,
            header: new DataTableHeader({
                i18n: 'LAST_ACTIVE',
                sortBy: 'last_active',
            }),
        }),
        new DataTableButtonColumn<CustomerUser>({
            buttons: [
                {
                    icon: 'edit',
                    click: this.editUser.bind(this),
                    show: this.canEdit.bind(this),
                    tooltip: { key: 'EDIT' },
                },
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.deleteUser.bind(this),
                    show: this.canDelete.bind(this),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    constructor(
        @Inject(CompanyUserService) private service: CompanyUserService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(CurrentService) public current: CurrentService,
        @Inject(QueryParamsService) public searchParams: QueryParamsService,
        @Inject(UserAccessDialogService) public dialog: UserAccessDialogService,
        @Inject(TranslateService) private translateService: TranslateService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
    ) {
    }

    ngOnInit(): void {
        this.form.controls.filter.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
            this.dataTable?.refresh();
        });

        const filter = this.searchParams.get('filter', 'string');
        if (filter) {
            this.form.controls.filter.setValue(filter);
        }
    }

    updateTable(pagination: DataTablePagination) {
        this.searchParams.set([
            {
                key: 'filter',
                value: this.form.controls.filter.value,
            },
        ]);
        this.request = this.service.getAll(this.current.getCustomer().id, {
            ...pagination,
            filter: this.form.controls.filter.value || undefined,
            include_inactive: this.includeInactive,
        });
    }

    toggleInactive() {
        this.includeInactive = !this.includeInactive;
        this.dataTable?.refresh();
    }

    addUser() {
        this.dialog.createAccess(this.current.getCustomer())
            .afterClosed()
            .subscribe((update) => update ? this.dataTable?.refresh() : null);
    }

    editUser(cell: DataTableCell<DataTableButtonColumn<CustomerUser>, CustomerUser>) {
        if (cell.item && cell.item.user) {

            const userAccess = new UserAccess({
                user_id: cell.item.userId,
                customer_id: cell.item.customerId,
                deleted_at: undefined,
                from: DateTimeConverter.convertDateTimeToUtcString(cell.item.from),
                id: cell.item.id,
                to: DateTimeConverter.convertDateTimeToUtcString(cell.item.to),
                user: {
                    email: cell.item.user.email,
                    first_name: cell.item.user.firstName,
                    id: cell.item.user.id,
                    language_code: cell.item.user.languageCode,
                    last_name: cell.item.user.lastName,
                    name: cell.item.user.name,
                },
            });

            this.dialog.updateAccess(this.current.getCustomer(), userAccess)
                .afterClosed()
                .subscribe((update) => update ? this.dataTable?.refresh() : (cell.disabled.set(false)));
        }
    }

    deleteUser(cell: DataTableCell<DataTableButtonColumn<CustomerUser>, CustomerUser>) {
        if (cell.item && cell.item.user) {
            const user = new User({
                email: cell.item.user.email,
                first_name: cell.item.user.firstName,
                id: cell.item.userId,
                language_code: cell.item.user.languageCode,
                last_name: cell.item.user.lastName,
                name: cell.item.user.name,
            });

            this.dialog.deleteAccess(this.current.getCustomer(), user).afterClosed().subscribe(async (confirm) => {
                if (confirm) {
                    this.dataTable?.refresh();
                    void this.snackBarService.t('REMOVED_ACCESS_FOR', 'admin', { user: user.name });
                }

                cell.disabled.set(false);
            });
        }
    }

    canEdit(user: CustomerUser) {
        return this.permissionCheckService.isAllowed(`customers.${this.current.getCustomer().id}.users.${user.userId}.update`);
    }

    canDelete(user: CustomerUser) {
        return this.permissionCheckService.isAllowed(`customers.${this.current.getCustomer().id}.users.${user.userId}.delete`);
    }

    rowClasses(row: CustomerUser) {
        const classes: string[] = [];
        if (!row.isActive) {
            classes.push('inactive');
        }
        return classes;
    }
}
