// @ts-nocheck
import { orderBy } from 'lodash-es';
import { module } from 'angular';
module('eaw.calendar').controller('eawYearDayPanelCtrl', [ '$scope', 'mdPanelRef', function eawYearDayPanelCtrl($scope, mdPanelRef) {
    // @ts-ignore
    const ctrl = this;
    ctrl.$onInit = () => {
        ctrl.events = ctrl.events || [];
        ctrl.hasEvents = ctrl.events?.length;
        // We want whole days at the top
        // Then sort the rest by their from
        ctrl.eventWhole = orderBy(ctrl.events.filter((e) => e.wholeDay), (e) => e.from.unix());
        ctrl.eventNotWhole = orderBy(ctrl.events.filter((e) => !e.wholeDay), (e) => e.from.unix());
        ctrl.events = ctrl.eventWhole.concat(ctrl.eventNotWhole);
        $scope.$on('$mdMenuClose', ctrl.onMenuClose);
    };
    ctrl.onMenuClose = () => {
        Array.from(document.getElementsByClassName('active-event')).forEach((el) => {
            el.classList.remove('active-event');
        });
    };
    /**
     * @param {Object} menu
     * @param {MouseEvent} event
     * @param {CalendarEvent} calendarEvent
     */
    ctrl.openMenu = (menu, event, calendarEvent) => {
        // Get the context menu for the event we want to open
        ctrl.contextMenu = ctrl.dataSources.find((d) => d.name === calendarEvent.dataSource)?.contextMenu || [];
        // Filter out what we can't do
        ctrl.contextMenu = ctrl.contextMenu.filter((cm) => cm.hasPermission);
        // Check if we actually have context
        if (ctrl.contextMenu.length) {
            // Show that the element is active
            event.currentTarget?.classList?.add('active-event');
            // Open 🤷‍♂️
            menu.open();
        }
    };
    ctrl.contextMenuClick = (event, item) => {
        mdPanelRef.close();
        item.onClick(ctrl.date, event);
    };
    ctrl.createBtnClick = () => {
        mdPanelRef.close();
        ctrl.createSource.button.onClick(ctrl.date, ctrl.employee);
    };
} ]);
