import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Role } from '../shared/types/role';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RoleService } from '../shared/http/role.service';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RoleAutocompleteComponent } from '../shared/components/role-autocomplete/role-autocomplete.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../shared/dialogs/dialog-header/dialog-header.component';

export interface RoleDialogData extends DialogData {
    customerId: number,
    role: Role,
}

@Component({
    selector: 'eaw-update-role-dialog',
    templateUrl: './role-dialog.component.html',
    styleUrl: './role-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        RoleAutocompleteComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class RoleDialogComponent extends DialogComponent implements OnInit {
    customerId: number;
    updating: boolean = false;
    form: FormGroup;
    confirmText!: string;

    protected role: Role;

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<RoleDialogComponent>,
        @Inject(RoleService) protected service: RoleService,
        @Inject(MAT_DIALOG_DATA) data: RoleDialogData,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        this.role = data.role;
        this.customerId = data.customerId;

        this.form = new FormGroup({
            name: new FormControl(this.role.name),
            parent: new FormControl(this.role.parent),
        });
    }

    ngOnInit(): void {
        this.confirmText = this.role.id ? 'UPDATE' : 'CREATE';
    }

    close() {
        const value = this.form.value;

        this.dialogRef.close({
            parent_id: value.parent?.id,
            name: value.name,
        });
    }
}
