// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="leaveshifts:LEAVE_SHIFT_plural"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <form ng-submit="$leaveShifts.update()" layout="row" layout-wrap>
            <eaw-date-interval layout flex="100" flex-gt-sm="50" input-flex="50" ng-model="$leaveShifts.interval"></eaw-date-interval>

            <employee-autocomplete flex="100" flex-gt-sm="50" ng-model="$leaveShifts.employee"></employee-autocomplete>
        </form>
    </md-card-content>
</md-card>

<md-card>
    <md-card-content class="tw-p-0">
        <leave-shift-list
                customer="$leaveShifts.customer"
                employee="$leaveShifts.employee"
                from="$leaveShifts.interval.from"
                to="$leaveShifts.interval.to">
        </leave-shift-list>
    </md-card-content>
</md-card>
`;

module('eaw.leave-shifts').component('customerLeaveShifts', {
    template: template1,
    bindings: {
        employee: '<',
        customer: '<',
    },
    controllerAs: '$leaveShifts',
    controller: 'leaveShiftController',
});
