import { ApiResponse } from '../../shared/interfaces/api-response';
import { BaseApiModel } from '../../shared/models/base-api-model';
import { BusinessDateString } from '../../shared/types/business-date';
import { BusinessDate } from '../../shared/utils/business-date';
import { Comment, CommentResponse } from '../../shared/models/comment';

export interface VariablePaymentResponse extends ApiResponse {
    id: number;
    business_date: BusinessDateString;
    pay_type_link_id: number;
    employee_id: number;
    quantity: number;
    rate: number;
    comments: CommentResponse[];
}

export class VariablePayment extends BaseApiModel<VariablePaymentResponse, VariablePayment> {
    id: number;
    payTypeLinkId: number;
    employeeId: number;
    quantity: number;
    rate: number;
    businessDate: BusinessDate;
    comments: Comment[];
    editable: boolean;

    constructor(data: VariablePaymentResponse) {
        super(data, undefined);
        this.id = data.id;
        this.payTypeLinkId = data.pay_type_link_id;
        this.employeeId = data.employee_id;
        this.quantity = data.quantity;
        this.rate = data.rate;
        this.businessDate = new BusinessDate(data.business_date);
        this.comments = data.comments?.map((p) => new Comment(p)) || [];
        this.editable = false;
    }

    /**
     * Returns the total amount of the payment.
     */
    get sum() {
        return this.quantity * this.rate;
    }
}
