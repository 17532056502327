import { inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { Observable } from 'rxjs';
import { Report, ReportResponse } from '../models/report';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse, AssocPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ReportDescriptorResponse } from '../models/report-descriptor';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    private readonly http = inject(HttpClient);

    create(customerId: number, klass: string): Observable<Report> {
        return this.http.post<ReportResponse>(`/customers/${customerId}/reports`, { class: klass }).pipe(classifyItem(Report));
    }

    getAllReports(pagination: PaginationOptions): Observable<AssocPaginatedResponse<ReportDescriptorResponse>> {
        return this.http.get<AssocPaginatedResponse<ReportDescriptorResponse>>('/reports', { params: formatHttpParams(pagination) });
    }

    /**
     * All reports are already checked in regards to permissions, so we only get what the user can get
     */
    getAll(customerId: number, options?: PaginationOptions): Observable<ArrayPaginatedResponse<Report>> {
        return this.http.get<ArrayPaginatedResponse<ReportResponse>>(`/customers/${customerId}/reports`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(Report));
    }

    get(customerId: number, reportId: number, options: { from: DateTime, to: DateTime }): Observable<Report> {
        return this.http.get<ReportResponse>(`/customers/${customerId}/reports/${reportId}`, {
            params: formatHttpParams(options),
        }).pipe(classifyItem(Report));
    }

    delete(customerId: number, id: number) {
        return this.http.delete(`/customers/${customerId}/reports/${id}`);
    }
}
