import { inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { PermissionSet, PermissionSetResponse } from '../models/permission-set';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../utils/format-http-params';
import { ProductName } from '../enums/products';

export interface PermissionSetData {
    name: string;
    description: string;
    product_name: ProductName;
}

interface AttachToManyGroups {
    set_ids: number[];
}

interface AttachToManyGroupsWithIds extends AttachToManyGroups {
    group_ids: number[];
}

interface AttachToManyGroupsWithNames extends AttachToManyGroups {
    group_names: string[];
    customer_ids: number[];
}

@Injectable({
    providedIn: 'root',
})
export class PermissionSetService {
    private readonly http = inject(HttpClient);

    create(data: PermissionSetData) {
        return this.http.post<PermissionSetResponse>('/permission_sets', data).pipe(classifyItem(PermissionSet));
    }

    update(permissionSetId: number, data: PermissionSetData) {
        return this.http.put<PermissionSetResponse>(`/permission_sets/${permissionSetId}`, data).pipe(classifyItem(PermissionSet));
    }

    delete(permissionSetId: number) {
        return this.http.delete(`/permission_sets/${permissionSetId}`);
    }

    getAll(options: { pagination: PaginationOptions }) {
        return this.http.get<ArrayPaginatedResponse<PermissionSetResponse>>('/permission_sets', {
            params: { ...options.pagination },
        }).pipe(classifyArrayPaginatedResponse(PermissionSet));
    }

    get(permissionSetId: number, options?: {with?: string[], count?: string[]|string}) {
        return this.http.get<PermissionSetResponse>(`/permission_sets/${permissionSetId}`, {
            params: formatHttpParams({ 'with[]': options?.with, 'count[]': options?.count }),
        }).pipe(classifyItem(PermissionSet));
    }

    attachToManyUserGroups(body: AttachToManyGroupsWithIds | AttachToManyGroupsWithNames) {
        return this.http.post(`/permission_sets/assign_to_many_user_groups`, body);
    }

    // For leader roles
    getAllForRole(customerId: number, roleId: number, options: PaginationOptions = {}): Observable<ArrayPaginatedResponse<PermissionSet>> {
        return this.http.get<ArrayPaginatedResponse<PermissionSetResponse>>(`/customers/${customerId}/roles/${roleId}/permission_sets`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(PermissionSet));
    }

    // For leader roles
    attachToRole(customerId: number, roleId: number, setId: number) {
        return this.http.post(`/customers/${customerId}/roles/${roleId}/permission_sets`, { set_id: setId });
    }

    // For leader roles
    detachFromRole(customerId: number, roleId: number, setId: number) {
        return this.http.delete(`/customers/${customerId}/roles/${roleId}/permission_sets/${setId}`);
    }

    // For nested permission sets
    attachToSet(parentPermissionSetId: number, childPermissionSetId: number): Observable<PermissionSet> {
        return this.http.post<PermissionSetResponse>(`/permission_sets/${parentPermissionSetId}/permission_sets`, { set_id: childPermissionSetId }).pipe(classifyItem(PermissionSet));
    }

    // For nested permission sets
    detachFromSet(parentPermissionSetId: number, childPermissionSetId: number) {
        return this.http.delete(`/permission_sets/${parentPermissionSetId}/permission_sets/${childPermissionSetId}`);
    }

    // User groups

    attachToUserGroup(customerId: number, userGroupId: number, setId: number): Observable<PermissionSet> {
        return this.http.post<PermissionSetResponse>(`/customers/${customerId}/user_groups/${userGroupId}/permission_sets`, { set_id: setId }).pipe(classifyItem(PermissionSet));
    }

    getForUserGroup(userGroupId: number, permissionSetId: number, options?: {'with[]': string[]}): Observable<PermissionSet> {
        return this.http.get<PermissionSetResponse>(`/user_groups/${userGroupId}/permission_sets/${permissionSetId}`, {
            params: formatHttpParams(options),
        }).pipe(classifyItem(PermissionSet));
    }

    getAllForUserGroup(userGroupId: number, options: PaginationOptions = {}): Observable<ArrayPaginatedResponse<PermissionSet>> {
        return this.http.get<ArrayPaginatedResponse<PermissionSetResponse>>(`/user_groups/${userGroupId}/permission_sets`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(PermissionSet));
    }

    detachFromUserGroup(userGroupId: number, setId: number) {
        return this.http.delete(`/user_groups/${userGroupId}/permission_sets/${setId}`);
    }
}
