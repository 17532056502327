import { Component, HostBinding, Inject, ViewChild } from '@angular/core';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { catchError, debounceTime, distinctUntilChanged, EMPTY, of } from 'rxjs';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { SettingGroupService } from '../../../shared/http/setting-group.service';
import { SettingGroup } from '../../../shared/models/setting-group';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { FormControl, UntypedFormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-setting-group-list',
    templateUrl: './setting-group-list.component.html',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class SettingGroupListComponent implements EawDataTable {
    @HostBinding('class') classes = 'flex-contained';
    @ViewChild('dataTable') dataTable!: DataTableComponent<SettingGroup>;

    filter = new UntypedFormControl(this.searchParams.get('filter', 'string'));
    fabButton: HeaderFabButton = {
        click: this.createNewGroup.bind(this),
        hasPermission: () => of(true),
    };

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<SettingGroup>[] = [
        new DataTableNumberColumn({
            value: 'id',
            header: new DataTableHeader({
                text: 'Id',
                sortBy: 'id',
            }),
        }),
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),

        new DataTableNumberColumn({
            value: 'membersCount',
            header: new DataTableHeader({
                text: 'Members',
                sortBy: 'members_count',
            }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'edit',
                    click: this.editGroupName.bind(this),
                    show: () => of(true),
                    tooltip: { key: 'EDIT' },
                },
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.deleteGroup.bind(this),
                    hide: (item) => of(item.membersCount > 0),
                    show: () => of(true),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    constructor(
        @Inject(SettingGroupService) private settingGroupService: SettingGroupService,
        @Inject(PromptDialogService) private promptDialog: PromptDialogService,
        @Inject(QueryParamsService) private searchParams: QueryParamsService,
    ) {
        this.filter.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
        ).subscribe(() => {
            this.updateTable(this.dataTable.getPagination({ page: 1 }));
        });
    }

    createNewGroup() {
        this.promptDialog.open('text', {
            title: Promise.resolve('Create setting group'),
            label: Promise.resolve('Group name'),
            formControl: new FormControl('', [ Validators.required, Validators.max(50), Validators.maxLength(50) ]),
            options: {
                maxLength: 50,
            },
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.settingGroupService.create(result).subscribe(() => {
                this.updateTable(this.dataTable.getPagination({ page: 1 }));
            });
        });
    }

    editGroupName(cell: DataTableCell<DataTableButtonColumn<SettingGroup>, SettingGroup>) {
        this.promptDialog.open('text', {
            title: Promise.resolve('Edit setting group name'),
            label: Promise.resolve('Group name'),
            formControl: new FormControl(cell.item.name, [ Validators.required, Validators.max(50), Validators.maxLength(50) ]),
            options: {
                maxLength: 50,
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.settingGroupService.update(cell.item.id, result).subscribe(() => {
                    this.updateTable(this.dataTable.getPagination({ page: 1 }));
                });
            } else {
                cell.disabled.set(false);
            }
        });
    }

    deleteGroup(cell: DataTableCell<DataTableButtonColumn<SettingGroup>, SettingGroup>) {
        this.settingGroupService.delete(cell.item.id).pipe(
            catchError(() => {
                cell.disabled.set(false);
                return EMPTY;
            }),
        ).subscribe(() => {
            this.updateTable(this.dataTable.getPagination({ page: 1 }));
        });
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.searchParams.set([ {
            key: 'filter',
            value: this.filter.value,
        } ]);

        this.request = this.settingGroupService.getAll({
            ...pagination,
            'count[]': [ 'members' ],
            filter: this.filter.value || undefined,
        });
    }
}
