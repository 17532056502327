import moment, { Moment } from 'moment-timezone';
import { Nullable } from '../types/nullable';
import Diff = moment.unitOfTime.Diff;

/**
 * @deprecated
 */
export interface IntervalLike {
    from?: Nullable<Moment | string>
    to?: Nullable<Moment | string>
}

type MaybeMoment = Nullable<Moment>;

/**
 * @deprecated
 */
export class IntervalOld {
    from: Moment | undefined;
    to: Moment | undefined;

    constructor(object?: IntervalLike)
    constructor(from?: MaybeMoment, to?: MaybeMoment)
    constructor(arg1?: Nullable<Moment | IntervalLike>, arg2?: MaybeMoment) {
        if (moment.isMoment(arg1)) {
            this.from = arg1.clone();
        }

        if (moment.isMoment(arg2)) {
            this.to = arg2.clone();
        }

        if (arg1?.from) {
            if (moment.isMoment(arg1?.from)) {
                this.from = arg1?.from.clone();
            }

            if (typeof arg1?.from === 'string') {
                this.from = moment(arg1.from);
            }
        }

        if (arg1?.to) {
            if (moment.isMoment(arg1?.to)) {
                this.to = arg1.to.clone();
            }

            if (typeof arg1?.to === 'string') {
                this.to = moment(arg1.to);
            }
        }
    }

    diff(unit: Diff = 's'): undefined | number {
        return moment.isMoment(this.from) && moment.isMoment(this.to) ? this.to.diff(this.from, unit) : undefined;
    }

    get fromInvalid() {
        return this.from == null && this.to != null;
    }

    get toInvalid() {
        return this.from != null && this.to == null;
    }
}
