import { Inject, Injectable } from '@angular/core';
import { classifyArrayPaginatedResponse } from '../utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';
import { Alert, AlertsResponse } from '../models/alert';
import { PaginationOptions } from '../interfaces/pagination-options';
import { formatHttpParams } from '../utils/format-http-params';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';

export interface GetAlertsOptions extends PaginationOptions { category?: string }

@Injectable({
    providedIn: 'root',
})
export class AlertsService {

    constructor(
      @Inject(HttpClient) private http: HttpClient,
    ) { }

    getAlerts(customerId: number, options?: GetAlertsOptions): Observable<ArrayPaginatedResponse<Alert>> {
        return this.http.get<ArrayPaginatedResponse<AlertsResponse>>(`/customers/${customerId}/triggered_alerts`, {
            params: formatHttpParams({
                ...options,
            }),
        }).pipe(classifyArrayPaginatedResponse(Alert));
    };

    deleteAlert(customerId: number, alertId: number) {
        return this.http.delete(`/customers/${customerId}/triggered_alerts/${alertId}`);
    }
}
