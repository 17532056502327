import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';

@Directive({
    selector: '[eawDebounceClick]',
    standalone: true,
})
export class DebounceClickDirective implements OnDestroy {
    @HostListener('click', [ '$event' ])
    clickEvent(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.clickerSubject.next(event);
    }

    @Input() debounceClickTime = 500;
    @Output() eawDebounceClick = new EventEmitter<Event>();

    private clickerSubject = new Subject<Event>();
    private subscription: Subscription;

    constructor() {
        this.subscription = this.clickerSubject
            .pipe(debounceTime(this.debounceClickTime))
            .subscribe((e) => this.eawDebounceClick.emit(e));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
