// @ts-nocheck
import { orderBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.projections').controller('generateBudgetsCtrl', [ 'date', 'projectionsFactory', '$mdDialog', 'errorService', function generateBudgetsCtrl(date, projectionsFactory, $mdDialog, errorService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.date = date;
        ctrl.dates = [];
        ctrl.maxDate = moment().subtract(1, 'd').startOf('d');
    };

    ctrl.dateChanged = (date) => {
        const dateAlreadyAdded = ctrl.dates.some((d) => d.isSame(date, 'd'));

        if (!dateAlreadyAdded) {
            ctrl.dates.push(date);
            ctrl.dates = orderBy(ctrl.dates, (d) => moment(d).format('YYYYMMDD'), [ 'desc' ]);
        }

        ctrl.checkRequirements();
    };

    ctrl.removeDate = (date) => {
        ctrl.dates = ctrl.dates.filter((d) => !d.isSame(date, 'd'));

        ctrl.checkRequirements();
    };

    ctrl.checkRequirements = () => {
        const gotBudget = ctrl.budget != null;
        const gotAtLeastOneDate = ctrl.dates?.length > 0 && ctrl.dates.every((d) => d?.isValid?.());

        ctrl.gotWhatsRequired = [ gotBudget, gotAtLeastOneDate ].every((x) => x);
    };

    ctrl.submit = () => {
        ctrl.submitting = true;
        projectionsFactory.set.query(CurrentOld.customer.id, ctrl.date.format('YYYY-MM-DD'), {
            day_sales: ctrl.budget,
            dates: [ ...ctrl.dates ].map((d) => d.format('YYYY-MM-DD')),
        }).$promise.then((data) => {
            $mdDialog.hide(data); // Data is projections
        }).catch(errorService.displayXhrError).finally(() => ctrl.submitting = false);
    };
} ]);
