// @ts-nocheck
import { pick } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import CustomerOld from '../../customer-old';
import { PaginationOld } from '../../../utils/pagination-old';
import { CurrentOld } from '../../current.factory';

module('eaw.customers').factory('CustomerService', [ 'EawResource', function(EawResource) {
    // @ts-ignore
    const service = this;
    const res = EawResource.create('/customers/:id/:query/:query_id');
    service.avoidUserFromBeingInThePast = (from) => {
        from = moment.isMoment(from) ? from.startOf('d') : null; // Start of day of selected day
        from = moment.isMoment(from) && from.isBefore(moment()) ? moment() : null; // If selected day is today, set to now
        return from;
    };
    service.getAll = (args) => res.get({
        ...PaginationOld.getParams(args),
        with_trashed: args.withTrashed,
    }).$promise.then((resp) => {
        resp.data = resp.data.map((cust) => new CustomerOld(cust, CurrentOld.getUser()));
        return resp;
    });
    /**
     * Get one customer
     * @param args
     * @param args.id int|string the id of the customer to get.
     * @param args.with array the relationship to load before returning from server.
     */
    service.get = (args) => res.get({
        id: args.id,
        'with[]': args.with,
    });
    /**
     * Get a specific user for a customer
     */
    service.getUserById = (customerId, userId, withRelations, when) => {
        return res.get({
            id: customerId,
            query: 'users',
            query_id: userId,
            'with[]': withRelations,
            when: when || moment(),
        });
    };
    /**
     * @param {Object} args
     */
    service.getUsers = (args) => res.get({
        ...PaginationOld.getParams(args, [ 'employed', 'display_inactive_users' ]),
        id: args.customer ? args.customer.id : CurrentOld.getCustomer().id,
        query: 'users',
    });
    service.removeUser = (id, userId) => res.delete({
        id,
        query: 'users',
        query_id: userId,
    });
    service.addUser = (customer, userId, from, to) => res.save({
        id: customer.id,
        query: 'users',
    }, {
        from: service.avoidUserFromBeingInThePast(from),
        to: moment.isMoment(to) ? to.endOf('d') : null,
        user: userId,
    });
    service.updateUser = (customer, user, from, to) => {
        const data = pick(user, [ 'first_name', 'last_name', 'email', 'country_code', 'phone', 'language_code', 'password', 'password_confirmation' ]);
        if (moment.isMoment(from)) {
            data.from = service.avoidUserFromBeingInThePast(from);
        }
        if (to === null) {
            data.to = to;
        } else if (moment.isMoment(to)) {
            data.to = to.clone().endOf('d');
        }
        return res.update({
            id: customer.id,
            query: 'users',
            query_id: user.id,
        }, data);
    };
    service.updateById = (id, customer, withRelations) => {
        // Select all we need from the customer object
        const put = pick(customer, [
            'name',
            'billing_contact',
            'billing_customer_id',
            'setting_group_id',
            'number',
            'address1',
            'address2',
            'postal_code',
            'city',
            'country_code',
            'locale_code',
            'time_zone',
            'stack_id',
            'type',
            'organization_number',
            'latitude',
            'longitude',
            'active',
            'region_id',
            'currency',
        ]);
        put.with = withRelations || [ 'settingGroup', 'billingCustomer' ];
        return res.update({ id }, put);
    };
    service.deleteById = (id) => res.delete({ id });
    service.create = (customer) => res.save({}, Object.assign(pick(customer, [
        'name',
        'billing_contact',
        'number',
        'address1',
        'postal_code',
        'city',
        'country_code',
        'locale_code',
        'time_zone',
        'type',
        'latitude',
        'longitude',
        'region_id',
        'currency',
    ]), {
        address2: !Object.keys(customer.address2 || []).length ? undefined : customer.address2,
        active: customer.active,
        stack_id: customer?.stack?.id,
        billing_customer: customer?.billing_customer?.id,
        setting_group_id: customer?.setting_group?.id,
        organization_number: customer?.organization_number?.toString(),
    })).$promise;
    // Products
    service.getAllProducts = (args) => res.get(Object.assign(pick(args, [ 'per_page', 'filter', 'order_by', 'page' ]), {
        id: args.customer.id,
        query: 'products',
    }));
    service.addProduct = (customerId, productName) => res.save({
        id: customerId,
        query: 'products',
    }, {
        product_name: productName,
    });
    service.removeProduct = (customerId, product) => res.delete({
        id: customerId,
        query: 'products',
        query_id: product.name,
    });
    function getLinks(id, query, filter, query_id, relations, type, pagination) {
        return res.get({
            id,
            query,
            query_id,
            filter,
            'with[]': relations,
            type,
            ...PaginationOld.getParams(pagination),
        });
    }
    service.getLink = (customerId, withRelations, child_id) => getLinks(customerId, 'links', undefined, child_id, withRelations);
    /**
     * @param {object} args
     * @param {int} args.id
     * @returns {*}
     */
    service.getAllLinks = (args) => getLinks(args.id, 'links', undefined, undefined, args.with, undefined, args);
    service.getChildLinks = (args) => getLinks(args.customer_id, 'children', args.filter, undefined, undefined, args.type, args.pagination);
    service.getParentLinks = (args) => getLinks(args.customer_id, 'parents', args.filter, undefined, undefined, args.type);
    service.addLink = (customer, child, type, from, to) => res.save({
        id: customer.id,
        query: 'links',
    }, {
        child: child.id,
        type,
        from,
        to,
    });
    service.removeLink = (customer, link) => res.delete({
        id: customer.id,
        query: 'links',
        query_id: link.child.id,
    });
    service.updateLink = (parent, child, from, to, type) => res.update({
        id: parent.id,
        query: 'links',
        query_id: child.id,
    }, {
        from,
        to,
        type,
    });
    service.getAllUserGroups = (args) => res.get({
        id: args.customer_id || CurrentOld.getCustomer().id,
        query: 'user_groups',
        ...PaginationOld.getParams(args, [ 'user_id' ]),
    });
    /**
     * @param {object}  args
     * @param {int}     args.id         - the customer id
     * @param {moment}  args.from
     * @param {moment}  args.to
     * @param {boolean}    args.changed
     * @param {array}   args.changes
     * @param {array}   args.with
     * @param {object}  args.employee
     */
    service.getAllShiftsPaginated = (args) => res.get({
        id: args.id,
        query: 'shifts',
        from: args.from,
        to: args.to,
        changed: args.changed,
        'changes[]': args.changes,
        'with[]': args.with,
        employee_id: args.employee ? args.employee.id : undefined,
        ...PaginationOld.getParams(args),
    });
    service.getAllSchedulesPaginated = (args) => res.get({
        ...args,
        ...PaginationOld.getParams(args),
    });
    return service;
} ]);
