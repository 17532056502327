import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Employee } from '../../../shared/models/employee';
import { HandleOffTimeService } from '../../dialogs/handle-offtime-dialog/handle-off-time.service';
import { OffTimeService } from '../../http/off-time.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { catchError, debounceTime, distinctUntilChanged, EMPTY, take } from 'rxjs';
import { OffTime } from '../../models/off-time';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { Comment } from '../../../shared/models/comment';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { DateTime } from 'luxon';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DateTimeInputComponent } from '../../../shared/components/date-time/date-time-input/date-time-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateTimeRangeInputComponent } from '../../../shared/components/date-time/date-time-range-input/date-time-range-input.component';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

@Component({
    selector: 'eaw-override-off-time',
    templateUrl: './override-off-time.component.html',
    styleUrl: './override-off-time.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        ReactiveFormsModule,
        AutocompleteComponent,
        DateTimeRangeInputComponent,
        MatFormFieldModule,
        DateTimeInputComponent,
        NgIf,
        MatProgressSpinnerModule,
        MatListModule,
        NgFor,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class OverrideOffTimeComponent implements OnInit {
    @Input({ required: true }) customerId!: number;

    readonly defaultToTime = DateTime.now().endOf('day');
    readonly defaultFrom = DateTime.now().plus({ day: 1 }).startOf('day');
    readonly defaultTo = this.defaultFrom.endOf('day');

    loading = false;
    offtimes: OffTime[] = [];
    form = new FormGroup({
        employee: new FormControl<Employee | null>(null, Validators.required),
        from: new FormControl<DateTime | null>(this.defaultFrom, Validators.required),
        to: new FormControl<DateTime | null>(this.defaultTo, Validators.required),
    });

    constructor(
        @Inject(HandleOffTimeService) private handleOfftimeService: HandleOffTimeService,
        @Inject(OffTimeService) private offTimeService: OffTimeService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
    ) {
    }

    ngOnInit(): void {
        this.form.valueChanges.pipe(
            debounceTime(16),
            distinctUntilChanged((prev, curr) => {
                return `${prev.employee?.id}${prev?.from?.toISODate()}${prev?.to?.toISODate()}` === `${curr.employee?.id}${curr?.from?.toISODate()}${curr?.to?.toISODate()}`;
            }),
        ).subscribe(() => {
            const employeeId = this.form.controls.employee.value?.id;
            const from = this.getFrom();
            const to = this.getTo();

            if (employeeId && from && to && from < to) {
                this.offtimes = [];
                this.check(employeeId, from, to);
            }
        });
    }

    getFrom() {
        return this.form.controls.from.value;
    }

    getTo() {
        return this.form.controls.to.value;
    }

    check(employeeId: number, from: DateTime, to: DateTime) {
        this.loading = true;
        this.form.disable({
            emitEvent: false,
            onlySelf: true,
        });

        expandAllPages<OffTime, NonNullable<Parameters<OffTimeService['getAll']>[2]>>((options) => this.offTimeService.getAll(this.customerId, employeeId, options), {
            from,
            to,
            per_page: 50,
            'order_by[]': [ 'from' ],
            direction: 'asc',
            'with[]': [ 'approval' ],
        }).pipe(
            take(1),
            catchError(() => {
                this.form.enable({ emitEvent: false });
                this.loading = false;
                return EMPTY;
            }),
        ).subscribe((offTimes) => {
            this.offtimes = offTimes;
            this.form.enable({ emitEvent: false });
            this.loading = false;
        });
    }

    decline(offTime: OffTime) {
        this.handleOfftimeService.decline(this.customerId, offTime.employeeId, offTime.id).subscribe((res) => {
            if (!res) {
                return;
            }
            this.offtimes.splice(this.offtimes.findIndex((o) => o.id === offTime.id), 1, res);
        });
    }

    delete(offTime: OffTime) {
        this.confirmDialogService.delete({
            comment: {
                include: true,
                required: true,
            },
        }).afterClosed().subscribe((res) => {
            if (!res?.ok) {
                return;
            }

            this.offTimeService.delete(this.customerId, offTime.employeeId, offTime.id).subscribe(() => {
                offTime.deletedAt = DateTime.now();
                offTime.comments = [
                    new Comment({
                        body: res.comment || '',
                        commentable_id: 0,
                        commentable_type: '',
                        created_at: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'),
                        id: 0,
                        user_id: 0,
                        user_name: '',
                    }),
                ];
            });
        });
    }

    override() {
        const empId = this.form.controls.employee.value?.id;
        const from = this.getFrom();
        const to = this.getTo();
        if (!(empId && from && to)) {
            return;
        }

        this.handleOfftimeService.approve(this.customerId, empId, {
            mode: 'create',
            from,
            to,
        }).afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }

            this.offtimes = [];
            this.form.reset({
                from: this.defaultFrom,
                to: this.defaultTo,
            });

            this.form.markAsPristine();
            this.form.markAsUntouched();
        });
    }

    isHandled(offTime: OffTime): boolean {
        return (offTime.approval && !offTime.approval.approved) || !!offTime.deletedAt;
    }

    get allHandled() {
        return this.offtimes.every((o) => this.isHandled(o));
    }
}
