import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'fiscal-info-upgraded',
    standalone: true
})
export class fiscalInfoUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;
    @Input() employee!: any;
    @Input() user!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('fiscalInfo', elementRef, injector);
    }
}

@Component({
    template: '<fiscal-info-upgraded [customer]="customer" [employee]="employee" [user]="user"></fiscal-info-upgraded>',
    standalone: true,
    imports: [fiscalInfoUpgradedDirective],
})
export class fiscalInfoUpgradedComponent {
    @Input() customer!: any;
    @Input() employee!: any;
    @Input() user!: any;
}

@NgModule({
    imports: [fiscalInfoUpgradedDirective,
        fiscalInfoUpgradedComponent],
})
export class fiscalInfoUpgradeModule {
}
