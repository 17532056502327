var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj)) throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var _mediaController;
import { MediaUIAttributes, MediaUIEvents, MediaStateReceiverAttributes, PointerTypes } from "./constants.js";
import { closestComposedNode, getBooleanAttr, setBooleanAttr } from "./utils/element-utils.js";
import { globalThis, document } from "./utils/server-safe-globals.js";
const template = document.createElement("template");
template.innerHTML = `
<style>
  :host {
    display: var(--media-control-display, var(--media-gesture-receiver-display, inline-block));
    box-sizing: border-box;
  }
</style>
`;
class MediaGestureReceiver extends globalThis.HTMLElement {
  constructor(options = {}) {
    super();
    __privateAdd(this, _mediaController, void 0);
    if (!this.shadowRoot) {
      const shadow = this.attachShadow({
        mode: "open"
      });
      const buttonHTML = template.content.cloneNode(true);
      this.nativeEl = buttonHTML;
      let slotTemplate = options.slotTemplate;
      if (!slotTemplate) {
        slotTemplate = document.createElement("template");
        slotTemplate.innerHTML = `<slot>${options.defaultContent || ""}</slot>`;
      }
      this.nativeEl.appendChild(slotTemplate.content.cloneNode(true));
      shadow.appendChild(buttonHTML);
    }
  }
  static get observedAttributes() {
    return [MediaStateReceiverAttributes.MEDIA_CONTROLLER, MediaUIAttributes.MEDIA_PAUSED];
  }
  attributeChangedCallback(attrName, oldValue, newValue) {
    var _a, _b, _c, _d, _e;
    if (attrName === MediaStateReceiverAttributes.MEDIA_CONTROLLER) {
      if (oldValue) {
        (_b = (_a = __privateGet(this, _mediaController)) == null ? void 0 : _a.unassociateElement) == null ? void 0 : _b.call(_a, this);
        __privateSet(this, _mediaController, null);
      }
      if (newValue && this.isConnected) {
        __privateSet(this, _mediaController, (_c = this.getRootNode()) == null ? void 0 : _c.getElementById(newValue));
        (_e = (_d = __privateGet(this, _mediaController)) == null ? void 0 : _d.associateElement) == null ? void 0 : _e.call(_d, this);
      }
    }
  }
  connectedCallback() {
    var _a, _b, _c, _d;
    this.tabIndex = -1;
    this.setAttribute("aria-hidden", "true");
    __privateSet(this, _mediaController, getMediaControllerEl(this));
    if (this.getAttribute(MediaStateReceiverAttributes.MEDIA_CONTROLLER)) {
      (_b = (_a = __privateGet(this, _mediaController)) == null ? void 0 : _a.associateElement) == null ? void 0 : _b.call(_a, this);
    }
    (_c = __privateGet(this, _mediaController)) == null ? void 0 : _c.addEventListener("pointerdown", this);
    (_d = __privateGet(this, _mediaController)) == null ? void 0 : _d.addEventListener("click", this);
  }
  disconnectedCallback() {
    var _a, _b, _c, _d;
    if (this.getAttribute(MediaStateReceiverAttributes.MEDIA_CONTROLLER)) {
      (_b = (_a = __privateGet(this, _mediaController)) == null ? void 0 : _a.unassociateElement) == null ? void 0 : _b.call(_a, this);
    }
    (_c = __privateGet(this, _mediaController)) == null ? void 0 : _c.removeEventListener("pointerdown", this);
    (_d = __privateGet(this, _mediaController)) == null ? void 0 : _d.removeEventListener("click", this);
    __privateSet(this, _mediaController, null);
  }
  handleEvent(event) {
    var _a;
    const composedTarget = (_a = event.composedPath()) == null ? void 0 : _a[0];
    const allowList = ["video", "media-controller"];
    if (!allowList.includes(composedTarget == null ? void 0 : composedTarget.localName)) return;
    if (event.type === "pointerdown") {
      this._pointerType = event.pointerType;
    } else if (event.type === "click") {
      const {
        clientX,
        clientY
      } = event;
      const {
        left,
        top,
        width,
        height
      } = this.getBoundingClientRect();
      const x = clientX - left;
      const y = clientY - top;
      if (x < 0 || y < 0 || x > width || y > height || width === 0 && height === 0) {
        return;
      }
      const {
        pointerType = this._pointerType
      } = event;
      this._pointerType = void 0;
      if (pointerType === PointerTypes.TOUCH) {
        this.handleTap(event);
        return;
      } else if (pointerType === PointerTypes.MOUSE) {
        this.handleMouseClick(event);
        return;
      }
    }
  }
  get mediaPaused() {
    return getBooleanAttr(this, MediaUIAttributes.MEDIA_PAUSED);
  }
  set mediaPaused(value) {
    setBooleanAttr(this, MediaUIAttributes.MEDIA_PAUSED, value);
  }
  handleTap(e) {}
  handleMouseClick(e) {
    const eventName = this.mediaPaused ? MediaUIEvents.MEDIA_PLAY_REQUEST : MediaUIEvents.MEDIA_PAUSE_REQUEST;
    this.dispatchEvent(new globalThis.CustomEvent(eventName, {
      composed: true,
      bubbles: true
    }));
  }
}
_mediaController = new WeakMap();
function getMediaControllerEl(controlEl) {
  var _a;
  const mediaControllerId = controlEl.getAttribute(MediaStateReceiverAttributes.MEDIA_CONTROLLER);
  if (mediaControllerId) {
    return (_a = controlEl.getRootNode()) == null ? void 0 : _a.getElementById(mediaControllerId);
  }
  return closestComposedNode(controlEl, "media-controller");
}
if (!globalThis.customElements.get("media-gesture-receiver")) {
  globalThis.customElements.define("media-gesture-receiver", MediaGestureReceiver);
}
var media_gesture_receiver_default = MediaGestureReceiver;
export { media_gesture_receiver_default as default };