// @ts-nocheck
import { module } from 'angular';
import { environment } from '../../../../../environments/environment';
module('eaw.resource', [
    'ngResource',
    'eaw.time',
    'eaw.login',
]).config([ '$httpProvider', '$resourceProvider', '$sceDelegateProvider', function($httpProvider, $resourceProvider, $sceDelegateProvider) {
    $sceDelegateProvider.trustedResourceUrlList([
        'self',
        environment.api,
        'https://maps.googleapis.com/**',
    ]);
    $resourceProvider.defaults.cancellable = true;
    $resourceProvider.defaults.actions.update = {
        method: 'PUT',
    };
    // Interceptors
    // PUSH so that they run AFTER other possible interceptors
    // Ex. websocket adds header that we do not always want
    $httpProvider.interceptors.push('requestInterceptor');
    $httpProvider.interceptors.push('responseInterceptor');
    $httpProvider.defaults.useXDomain = true;
    // From docs:
    // This can result in significant performance improvement for bigger applications that make many HTTP
    // requests concurrently (common during application bootstrap).
    $httpProvider.useApplyAsync(true);
    // Added https://github.com/easyatworkas/easyatwork-2.0-ui/commit/1ba28c45f72107e4ec4c328d90c529082b773034#diff-21dd18cfaff74d169c3f22a59f7e9e85R70-R71
    delete $httpProvider.defaults.headers.common['X-Requested-With'];
} ]);
