// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';

const template1 = `<md-dialog>
    <form role="form" autocomplete="off" name="rateForm" ng-submit="ctrl.submit()">
        <eaw-dialog-header-old ng-if="ctrl.editing" heading="'admin:EDIT_TARIFF_RATE' | i18next"></eaw-dialog-header-old>
        <eaw-dialog-header-old ng-if="!ctrl.editing" heading="'admin:ADD_TARIFF_RATE' | i18next"></eaw-dialog-header-old>
        <md-dialog-content>
            <div class="md-dialog-content">
                <eaw-date-interval
                        ng-model="ctrl.interval"
                        from-required="true"
                        from-disabled="ctrl.disableFrom"
                        to-disabled="ctrl.disableTo">
                </eaw-date-interval>

                <md-input-container>
                    <label ng-i18next="RATE"></label>
                    <input ng-model="ctrl.rate.rate"
                           pattern="^[0-9]+([\,|\.][0-9]+)?$"
                           step="any"
                           type="number"
                           min="0"
                           required>
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button type="submit" ng-i18next="SUBMIT" ng-disabled="rateForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.admin').controller('tariffRatesCtrl', [ 'TariffRate', 'tariff', 'deleteItemAlert', '$mdDialog', function(TariffRate, tariff, deleteItemAlert, $mdDialog) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.tariff = { ...tariff };
        ctrl.tableArgs = {
            tariff: ctrl.tariff,
            customer: CurrentOld.customer,
        };

        ctrl.table = {
            columns: [
                {
                    title: t('ID'),
                    sortable: 'id',
                },
                {
                    title: t('FROM'),
                    sortable: 'from',
                },
                {
                    title: t('TO'),
                    sortable: 'to',
                },
                {
                    title: t('RATE'),
                    sortable: 'rate',
                    class: 'text-right',
                },
                {
                    title: t('TYPE'),
                    sortable: 'type',
                },
                { title: '' },
            ],
            cells: [
                { template: 'item.id' },
                { template: 'item.from | moment:"LL"' },
                { template: 'item.to | moment:"LL"' },
                {
                    template: 'item.rate | eawCurrency',
                    class: 'text-right',
                },
                { template: 'item.type | uppercase | i18next' },
                {
                    buttons: [
                        {
                            icon: 'edit',
                            click: ctrl.rateDialog,
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            click: ctrl.delete,
                        },
                    ],
                },
            ],
        };
    };

    ctrl.rateDialog = (rate) => {
        $mdDialog.show({
            template: template1,
            controllerAs: 'ctrl',
            controller: function() {
                const dctrl = this;

                dctrl.$onInit = () => {
                    dctrl.editing = !!rate;
                    dctrl.rate = { ...rate };
                    dctrl.cancel = $mdDialog.cancel;
                    dctrl.interval = {
                        from: dctrl.rate?.from?.clone(),
                        to: dctrl.rate?.to?.clone(),
                    };

                    dctrl.disableFrom = moment().isAfter(dctrl.interval.from);
                    dctrl.disableTo = moment().isAfter(dctrl.interval.to);
                };

                dctrl.submit = () => {
                    $mdDialog.hide();

                    dctrl.rate.tariff_id = ctrl.tariff.id;
                    dctrl.rate.from = dctrl.interval?.from?.clone();
                    dctrl.rate.to = dctrl.interval?.to?.clone();

                    TariffRate[dctrl.editing ? 'update' : 'create'](dctrl.rate).$promise.then(() => {
                        ctrl.reloadTable = true;
                    });
                };
            },
        });
    };

    ctrl.delete = (rate) => {
        deleteItemAlert.alertSimple().then(() => {
            TariffRate.delete(rate).$promise.then(() => ctrl.reloadTable = true);
        });
    };
} ]);
