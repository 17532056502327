// @ts-nocheck
import { orderBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
export default class EawWeekCalendarController {
    $element;
    $scope;
    HandleEventDialog;
    EventInfoPanel;
    static get $inject() {
        return [ '$element', '$scope', 'HandleEventDialog', 'EventInfoPanel' ];
    }

    calendar;
    element;
    eventSideSpacing;
    hours;
    hourHeight;
    nowMarkerTimeout;
    nowMarker;
    constructor($element, $scope, HandleEventDialog, EventInfoPanel) {
        this.$element = $element;
        this.$scope = $scope;
        this.HandleEventDialog = HandleEventDialog;
        this.EventInfoPanel = EventInfoPanel;
    }

    $onInit = () => {
        this.element = this.$element[0];
        this.hours = getComputedStyle(this.element).getPropertyValue('--hours');
        this.eventSideSpacing = getComputedStyle(this.element).getPropertyValue('--event-side-spacing');
        this.hourHeight = parseInt(getComputedStyle(this.element).getPropertyValue('--hour-height').replace('px', ''));
    };

    $postLink = () => {
        this.createGridCells();
        this.createDays();
        this.buildEvents();
        this.calendar.onEventsChange(this.$scope, this.buildEvents.bind(this));
        this.calendar.onIntervalChange(this.$scope, () => {
            this.createDays();
            this.buildEvents();
            this.createNowMarker();
        });
    };

    createGridCells = () => {
        const eventsGridEl = this.element.querySelector('.events-grid');
        const gridCell = document.createElement('div');
        gridCell.classList.add('events-grid-cell');
        for (let row = 1; row <= 24; row++) {
            for (let column = 1; column <= 8; column++) {
                const cell = gridCell.cloneNode();
                cell.style.gridRow = String(row);
                cell.style.gridColumn = String(column);
                if (column === 1 && row > 1) {
                    const time = document.createElement('span');
                    time.classList.add('time-marker');
                    time.innerText = moment().startOf('d').add(row - 1, 'h').format('LT');
                    cell.appendChild(time);
                }
                if (column > 1) {
                    cell.ariaColIndex = String(column - 1);
                    cell.ariaRowIndex = String(row);
                }
                eventsGridEl.appendChild(cell);
            }
        }
    };

    onEventDeleted(_, event) {
        Array.from(document.querySelectorAll(`[data-event-key="${event.key}"]`)).forEach((el) => el.remove());
    }

    createDays = () => {
        const datesGridEl = this.element.querySelector('.dates-grid');
        Array.from(this.element.getElementsByClassName('date-container')).forEach((el) => el.remove());
        new Array(7).fill(1).forEach((_, i) => {
            const date = (this.calendar.from || moment()).clone().startOf('w').add(i, 'd');
            const isToday = date.isSame(moment(), 'd');
            const dateContainer = document.createElement('div');
            const dateEl = document.createElement('div');
            const nameEl = document.createElement('div');
            const column = i + 1;
            dateEl.classList.add('date');
            dateEl.innerText = date.format('DD');
            dateEl.ariaColIndex = String(column);
            if (isToday) {
                dateEl.classList.add('today');
            }
            nameEl.classList.add('date-name');
            nameEl.innerText = date.format('ddd');
            nameEl.ariaColIndex = String(column);
            dateContainer.classList.add('date-container');
            dateContainer.style.gridColumn = column.toString();
            dateContainer.append(nameEl, dateEl);
            dateContainer.ariaColIndex = String(column);
            datesGridEl.append(dateContainer);
        });
    };

    createNowMarker = () => {
        const eventsGrid = this.element.querySelector('.events-grid');
        clearTimeout(this.nowMarkerTimeout);
        this.nowMarker?.remove?.();
        const from = +this.calendar.from;
        const to = +this.calendar.to;
        const now = +moment();
        const inside = now >= from && now <= to;
        if (!inside) {
            return;
        }
        const startDiff = ((+moment()) - (+this.calendar.from)) / (86400 * 1000);
        const weekday = Math.floor(startDiff);
        const dayHours = (startDiff - weekday) * 24;
        const column = weekday + 2; // 1 for time column, 1 because weekday starts at 0
        this.nowMarker = document.createElement('div');
        this.nowMarker.classList.add('now-marker');
        this.nowMarker.style.gridColumn = `${column}/${column + 1}`;
        this.nowMarker.style.setProperty('--day-hours', String(dayHours));
        eventsGrid.appendChild(this.nowMarker);
        // @ts-ignore
        this.nowMarkerTimeout = setTimeout(this.createNowMarker, 60000);
    };

    /**
     * @param {Calendar2Event[]} events
     * @return {object}
     */
    groupByOverlapping(events) {
        let groups = [];
        events.filter((ev) => !ev.wholeDay).forEach((event) => {
            groups.push(events.filter((ev) => {
                const overlapping = !(+ev.end < +event.start || +ev.start > +event.end);
                return !ev.wholeDay && overlapping;
            }));
        });
        // Smallest first
        groups = orderBy(groups, (g) => g.length, 'asc');
        const groups2 = [];
        groups.forEach((group) => {
            const events = [];
            group.forEach((event) => {
                if (!event.__added) {
                    events.push(event);
                    event.__added = true;
                }
            });
            groups2.push(events);
        });
        // Clean
        events.forEach((event) => delete event.__added);
        return groups2;
    }

    buildEventGroup = (group) => {
        const eventsGrid = this.element.querySelector('.events-grid');
        group.forEach((event, i) => {
            const colStart = event.start.weekday() + 2;
            const evDiv = document.createElement('div');
            const evTitle = document.createElement('strong');
            const evTime = document.createElement('span');
            const items = group.length;
            const left = 100 / items * i;
            evDiv.classList.add('day-event', event.participant?.status?.value || 'default');
            if (event.isOld) {
                evDiv.classList.add('old-event');
            }
            evDiv.style.setProperty('--ev-color', event.color);
            evDiv.style.setProperty('--ev-text-color', event.textColor);
            evDiv.style.height = `${event.hours * this.hourHeight}px`;
            evDiv.style.top = `${event.start.minute() / 60 * this.hourHeight}px`;
            evDiv.style.width = `calc(${100 - left}% - ${this.eventSideSpacing})`;
            evDiv.style.left = `${left}%`;
            evDiv.style.zIndex = String(i + 1);
            evDiv.style.gridColumnStart = colStart;
            evDiv.style.gridColumnEnd = colStart + 1;
            evDiv.style.gridRow = event.start.hour() + 1;
            evDiv.dataset.eventKey = event.key;
            evDiv.addEventListener('click', (e) => {
                this.onEventClick(event, evDiv, e);
            });
            evTitle.innerText = event.title;
            evTitle.classList.add('event-title');
            evTime.innerText = event.fromToText;
            evTime.classList.add('event-time');
            evDiv.append(evTitle, evTime);
            eventsGrid.append(evDiv);
        });
    };

    buildWholeDayEvents = (events) => {
        const eventsDayGrid = this.element.querySelector('.events-day-grid-items');
        const length = (e) => e.eventDays;
        const name = (e) => e.title.toLowerCase();
        // Sort events so that the longest ones are at the top
        const sortedEvents = orderBy(events, [ length, name ], [ 'desc', 'asc' ]);
        sortedEvents.forEach((event) => {
            const beforeInterval = event.start.isBefore(this.calendar.from, 'd');
            const afterInterval = event.end.isAfter(this.calendar.to, 'd');
            const evDiv = document.createElement('div');
            const start = beforeInterval ? 1 : event.start.weekday() + 1;
            let end = start + event.eventDays;
            if (afterInterval) {
                end = -1;
            }
            evDiv.classList.add('whole-day-event', event.participant?.status?.value || 'default');
            evDiv.style.setProperty('--ev-color', event.color);
            evDiv.style.setProperty('--ev-text-color', event.textColor);
            evDiv.style.gridColumnStart = start;
            evDiv.style.gridColumnEnd = end;
            evDiv.innerText = event.title;
            evDiv.dataset.eventKey = event.key;
            evDiv.addEventListener('click', (e) => {
                this.onEventClick(event, evDiv, e);
            });
            eventsDayGrid.append(evDiv);
        });
    };

    buildEvents() {
        // Remove all day events before building
        Array.from(this.element.querySelectorAll('.day-event')).forEach((el) => el.remove());
        Array.from(this.element.querySelectorAll('.whole-day-event')).forEach((el) => el.remove());
        if (!this.calendar.events.length) {
            return;
        }
        // All events
        const events = this.calendar.getEventsInInterval(this.calendar.from, this.calendar.to);
        // Events that are not whole day
        const overlappingEvents = this.groupByOverlapping(events);
        Object.values(overlappingEvents).forEach(this.buildEventGroup);
        // Whole dayy
        this.buildWholeDayEvents(events.filter((e) => e.wholeDay));
    }

    /**
     * @param {Calendar2Event} event
     * @param {HTMLElement} element
     * @param {MouseEvent} e
     */
    onEventClick = (event, element, e) => {
        e?.stopPropagation();
        e?.preventDefault();
        this.EventInfoPanel.open(event, element, e);
    };

    addEvent = (event, wholeDay, index) => {
        const target = event.target;
        let column = parseInt(target.ariaColIndex) - 1;
        const hours = parseInt(target.ariaRowIndex) - 1 || 0;
        if (wholeDay) {
            column = index ?? column;
        }
        const date = this.calendar.from.clone().add(column, 'd');
        const start = date.clone().startOf('d').add(hours, 'h');
        const end = start.clone().add(1, 'h');
        this.HandleEventDialog.open({
            date,
            start,
            end,
            wholeDay,
            user: this.calendar.user,
            calendars: this.calendar.calendars,
        });
    };
}
module('eaw.calendar2').component('eawWeekCalendar', {
    template: `<div class="side-grid"></div>
<div class="dates-grid" ng-click="$we.addEvent($event, true)"></div>

<div class="events-day-grid">
    <div class="events-day-grid-items"></div>

    <div class="grid-border" style="grid-area: 1 / 1 / -1 / 2" ng-click="$we.addEvent($event, true, 0)"></div>
    <div class="grid-border" style="grid-area: 1 / 2 / -1 / 3" ng-click="$we.addEvent($event, true, 1)"></div>
    <div class="grid-border" style="grid-area: 1 / 3 / -1 / 4" ng-click="$we.addEvent($event, true, 2)"></div>
    <div class="grid-border" style="grid-area: 1 / 4 / -1 / 5" ng-click="$we.addEvent($event, true, 3)"></div>
    <div class="grid-border" style="grid-area: 1 / 5 / -1 / 6" ng-click="$we.addEvent($event, true, 4)"></div>
    <div class="grid-border" style="grid-area: 1 / 6 / -1 / 7" ng-click="$we.addEvent($event, true, 5)"></div>
    <div class="grid-border" style="grid-area: 1 / 7 / -1 / 8" ng-click="$we.addEvent($event, true, 6)"></div>
</div>

<div class="events-grid" ng-click="$we.addEvent($event)"></div>
`,
    controllerAs: '$we',
    bindings: {
        year: '<',
        week: '<',
    },
    require: {
        calendar: '?^eawCalendar2',
    },
    controller: EawWeekCalendarController,
});
