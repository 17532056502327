import { Inject, Injectable } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CurrentService } from './current.service';

export type Location = {
    lat: number,
    lng: number,
}

type GeocoderParams = {
    address?: string,
    latLng?: string,
}

@Injectable({
    providedIn: 'root',
})
export class GeocodingService {

    constructor(
        @Inject(MapGeocoder) protected geocoder: MapGeocoder,
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(HttpBackend) protected httpBackend: HttpBackend,
        @Inject(CurrentService) protected current: CurrentService,
    ) {}

    // use direct call instead of geocoder as it doesn't require maps library to be loaded
    get(params: GeocoderParams): Observable<any> {
        const types = [ 'street_address', 'point_of_interest', 'establishment' ].join('|');

        return new HttpClient(this.httpBackend).get('https://maps.googleapis.com/maps/api/geocode/json', {
            responseType: 'json',
            params: {
                result_type: types,
                key: environment.googleAPIKey,
                language: this.current.getUser().languageCode,
                ...params,
            },
        });
    }

    getAddress(lat: number, lng: number) {
        return new Promise<string>((resolve, reject) => {
            this.geocoder.geocode({
                location: new google.maps.LatLng(lat, lng, true),
            }).subscribe(({ results, status }) => {
                if (status === 'OK') {
                    const result = results[0];

                    if (status === 'OK' && result) {
                        resolve(result.formatted_address);
                    } else {
                        reject();
                    }
                } else {
                    reject();
                }
            });
        });
    }

    getCoordinates(address: string) {
        return new Promise<Location>((resolve, reject) => {
            this.get({ address }).subscribe((result) => {
                if (result && result.results.length > 0) {
                    resolve(result.results[0].geometry?.location);
                } else {
                    reject();
                }
            });
        });
    }
}
