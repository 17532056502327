// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { ShiftListDialogComponent, ShiftListDialogData } from '../../../../scheduling/dialogs/shift-list-dialog/shift-list-dialog.component';
import { signal } from '@angular/core';
import { DateTime } from 'luxon';
import { momentToDateTime } from '../../../../shared/angularjs/moment-to-date-time';

module('eaw.dashboard').component('allShiftsCalendarWidget', {
    template: `<md-calendar ng-model="$shiftsCal.date"
             md-min-date="$shiftsCal.minDateAsDate"
             md-max-date="$shiftsCal.maxDateAsDate"
             md-mode="day">
</md-calendar>
`,
    require: {
        eawWidget: '^eawWidget',
    },
    controllerAs: '$shiftsCal',
    controller: [ 'ShiftFactory', '$element', 'MatDialogDowngrade', function(ShiftFactory, $element, MatDialogDowngrade) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.minDate = moment().startOf('d');
            ctrl.minDateAsDate = ctrl.minDate.toDate();
            ctrl.maxDate = moment().endOf('d').add(2, 'M');
            ctrl.maxDateAsDate = ctrl.maxDate.toDate();

            $element[0].addEventListener('click', ctrl.click);

            ctrl.eawWidget.setLoading(true);

            return ctrl.getShifts(ctrl.minDate, ctrl.maxDate, ctrl.eawWidget.widget.employee?.id).then((resp) => {
                ctrl.eawWidget.setLoading(false);
                resp.data.forEach((shift) => {
                    $element[0].querySelector(`[id$="${shift.from.year()}-${shift.from.month()}-${shift.from.date()}"]`)?.classList.add('has-shift');
                });
            });
        };

        ctrl.$onDestroy = () => {
            $element[0].removeEventListener('click', ctrl.click);
            ctrl.shiftsRes?.$cancelRequest?.();
        };

        ctrl.click = (e) => {
            const td = e.target.closest(`.md-calendar-date`);

            if (!td) {
                return;
            }
            if (td.classList.contains('md-calendar-date-disabled')) {
                return;
            }

            ctrl.showShifts(moment(parseInt(td.dataset.timestamp)));
        };

        ctrl.showShifts = (date) => {
            const from = momentToDateTime(date.clone())?.startOf('day');
            const to = momentToDateTime(date.clone())?.endOf('day');

            MatDialogDowngrade.open<ShiftListDialogComponent, ShiftListDialogData>(ShiftListDialogComponent, {
                data: {
                    customerId: ctrl.eawWidget.widget.customer,
                    subtitle: signal(Promise.resolve(from.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY))),
                    from,
                    to,
                },
            });
        };

        ctrl.getShifts = (from, to, employeeId) => {
            ctrl.shiftsRes = ShiftFactory.getAll({
                customer_id: ctrl.eawWidget.widget.customer,
                employee_id: employeeId,
                from,
                to,
                per_page: 240, // There better not be more than 240 shifts for one employee in a month (that's about 4 a day)
            });

            return ctrl.shiftsRes.$promise;
        };
    } ],
});
