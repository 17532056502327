import { ChangeDetectorRef, ElementRef, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { WidgetHeaderButton } from '../components/widget-host/widget-host.component';

/**
 * Type of error that a widget can have
 *
 * **custom** is a custom error provided by the developer
 *
 * **data** means that the widget failed to load data
 *
 * **settings** means that the widget has invalid settings
 */
export type WidgetErrorType = 'custom' | 'data' | 'settings';

/**
 * Base that all widgets should extend and provides relevant functionality
 */
export abstract class WidgetComponent {
    protected elementRef = inject(ElementRef<HTMLElement>);
    protected changeDetectorRef = inject(ChangeDetectorRef);

    private normalHeaderButtonsSubject = new BehaviorSubject<WidgetHeaderButton[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private errorSubject = new Subject<{type: WidgetErrorType, error?: Promise<string>}>();
    private extendedInfoClosedSubject = new Subject<void>();

    constructor() {
        this.elementRef.nativeElement.classList.add('rendered-widget');

        // All widgets should start loading on init, as 99% need to get data.
        // In cases where it's not needed, setLoading(false) can be called in the widget's ngOnInit
        this.setLoading(true);
    }

    onDestroy() {
        this.normalHeaderButtonsSubject.complete();
        this.loadingSubject.complete();
        this.errorSubject.complete();
        this.extendedInfoClosedSubject.complete();
    }

    /**
     * When a widget gets an error, it should stop loading and display the error
     */
    setError(type: 'settings' | 'data', error?: never): void;
    setError(type: 'custom', error: Promise<string>): void;
    setError(type: WidgetErrorType, error?: Promise<string>) {
        this.errorSubject.next({ type, error });
        this.setLoading(false);
    }

    onError() {
        return this.errorSubject.asObservable();
    }

    setLoading(loading: boolean): void {
        this.loadingSubject.next(loading);
        this.changeDetectorRef.markForCheck();
    }

    onLoading(): Observable<boolean> {
        return this.loadingSubject.asObservable();
    }

    updateNormalHeaderButtons(buttons: WidgetHeaderButton[]): void {
        this.normalHeaderButtonsSubject.next(buttons);
    }

    onNormalHeaderButtons(): Observable<WidgetHeaderButton[]> {
        return this.normalHeaderButtonsSubject.asObservable();
    }

    setExtendedInfoClosed(): void {
        this.extendedInfoClosedSubject.next();
    }

    onExtendedInfoClosed(): Observable<void> {
        return this.extendedInfoClosedSubject.asObservable();
    }
}
