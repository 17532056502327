import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { ContractType, ContractTypeResponse } from '../models/contract-type';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { PaginationOptions } from '../interfaces/pagination-options';

@Injectable({
    providedIn: 'root',
})
export class ContractTypeService {

    constructor(@Inject(HttpClient) private http: HttpClient) { }

    getAllForCustomer(customer: number, pagination: PaginationOptions = {}) {
        return this.http.get<ArrayPaginatedResponse<ContractTypeResponse>>(`/customers/${customer}/contract_types`, { params: { ...pagination } }).pipe(classifyArrayPaginatedResponse(ContractType));
    }

    getAllForSettingGroup(settingGroup: number, pagination: PaginationOptions = {}) {
        return this.http.get<ArrayPaginatedResponse<ContractTypeResponse>>(`/setting_groups/${settingGroup}/contract_types`, { params: { ...pagination } }).pipe(classifyArrayPaginatedResponse(ContractType));
    }

    getForCustomer(customer: number, contractType: number) {
        return this.http.get<ContractTypeResponse>(`/customers/${customer}/contract_types/${contractType}`).pipe(classifyItem(ContractType));
    }
}
