// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-card class="no-shadow">
    <md-card-content class="tw-p-4">
        <form name="dayForm" layout="row">
            <md-input-container flex="100">
                <eaw-date-picker ng-model="status.business_date" ng-change="status.changeDay(0)" required label="BUSINESS_DATE"></eaw-date-picker>
            </md-input-container>
        </form>
        <md-card-actions>
            <md-button class="md-primary" ng-disabled="dayForm.$invalid || status.loading" ng-click="status.changeDay(-1)">
                <span bo-i18next="PREVIOUS"></span>
            </md-button>
            <md-button class="md-primary" ng-disabled="dayForm.$invalid || status.loading || status.disableNext()" ng-click="status.changeDay(1)">
                <span bo-i18next="NEXT"></span>
            </md-button>
        </md-card-actions>
    </md-card-content>
</md-card>
<timepunch-xrange
        class="tw-pt-16"
        ng-show="!status.loading"
        loading="status.loading"
        chart-id="day-range-chart"
        y-title="status.chart.title"
        categories="status.chart.categories"
        data="status.chart.data"
        date="status.chart.date"
        reversed-y="true">
</timepunch-xrange>

<div class="tw-p-20" ng-if="status.loading" layout="column" layout-align="center center">
    <md-progress-circular class="md-progress-center"></md-progress-circular>
</div>
`;

module('eaw.payroll').component('dayTimepunchStatus', {
    template: template1,
    controllerAs: 'status',
    bindings: {
        colors: '<',
        customer: '<',
        business_date: '<?',
    },
    controller: [ 'TimepunchFactory', 'EmployeeService', function(TimepunchFactory, EmployeeService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            if (!ctrl.business_date) {
                ctrl.business_date = moment();
            }

            ctrl.getData();
        };

        ctrl.$onDestroy = () => {
            ctrl.tpRes?.$cancelRequest?.();
            ctrl.empRes?.$cancelRequest?.();
        };

        ctrl.changeDay = (change) => {
            if (!ctrl.business_date) {
                return;
            }

            // ng-model doesn't react unless we do this
            ctrl.business_date = ctrl.business_date.clone().add(change, 'd');
            ctrl.getData();
        };

        ctrl.disableNext = () => ctrl.business_date?.isSameOrAfter(moment(), 'd');

        ctrl.getData = () => {
            ctrl.tpRes?.$cancelRequest?.();
            ctrl.empRes?.$cancelRequest?.();

            // Two-way bound, set to false in child component
            ctrl.loading = true;
            const from = ctrl.business_date.clone().subtract(1, 'd').startOf('d');

            ctrl.tpRes = TimepunchFactory.getAll({
                customer_id: ctrl.customer.id,
                from,
                to: ctrl.business_date.clone().add(1, 'd').endOf('d'),
                per_page: 999,
            });

            ctrl.empRes = EmployeeService.getAll({
                customer: ctrl.customer,
                active: from,
                include_external: true,
                with: [],
                per_page: 999,
                order_by: 'name',
                direction: 'asc',
            });

            Promise.all([
                ctrl.tpRes.$promise.then((resp) => {
                    resp.data.forEach((tp) => {
                        if (!tp.out) {
                            tp.out = moment();
                        }
                    });

                    return resp;
                }),
                ctrl.empRes.$promise,
            ]).then(ctrl.then, console.error);
        };

        ctrl.then = (responses) => {
            const employees = responses[1].data;
            const categories = new Map();
            const from = ctrl.business_date.clone().startOf('d');
            const to = ctrl.business_date.clone().endOf('d');
            const title = t('EMPLOYEE');

            if (!employees.length) {
                ctrl.chart = {
                    yTitle: title,
                    data: [],
                    categories: [],
                };
            }

            // Filter out the ones we don't want
            let timepunches = responses[0].data.filter((tp) => tp.in.isSame(ctrl.business_date, 'd') || tp.out.isSame(ctrl.business_date, 'd'));
            timepunches = ctrl.splitTimepunches(timepunches).filter((tp) => tp.from.isSame(ctrl.business_date, 'd'));

            // employees without punches get fake ones \o/
            const noTimepunches = employees.filter((e) => !timepunches.find((tp) => tp.employee_id === e.id));
            const hasTimepunches = employees.filter((e) => timepunches.find((tp) => tp.employee_id === e.id));

            let index = 0;
            const addEmployee = (employee) => {
                categories.set(employee.id, {
                    label: `${employee.name}, #${employee.number}`,
                    index,
                });

                index++;
            };

            hasTimepunches.forEach(addEmployee);
            noTimepunches.forEach(addEmployee);

            const dummies = noTimepunches.map((employee) => {
                return {
                    business_date: from.clone(),
                    in: from.clone(),
                    out: to.clone(),
                    from,
                    to,
                    employee_id: employee.id,
                };
            });

            ctrl.chart = {
                yTitle: title,
                data: ctrl.formatSeries(categories, timepunches).concat(ctrl.formatSeries(categories, dummies)),
                categories: [ ...categories.values() ].map((v) => v.label),
                date: ctrl.business_date.clone(),
            };
        };

        ctrl.formatSeries = (categories, data) => {
            const collection = data.map((timepunch) => {
                const cat = categories.get(timepunch.employee_id);

                if (!cat) {
                    return null;
                }

                return {
                    timepunch,
                    x: +timepunch.from,
                    x2: +timepunch.to,
                    y: cat.index,
                    color: timepunch.id ? ctrl.colors.present : ctrl.colors.notPresent,
                };
            });
            return collection.filter((c) => c);
        };

        ctrl.splitTimepunches = (data) => {
            const timepunches = [];
            data.forEach((timepunch) => {
                if (timepunch.in.date() != timepunch.out.date()) {
                    timepunches.push({
                        ...timepunch,
                        from: timepunch.in,
                        to: timepunch.in.clone().endOf('d'),
                        business_date: timepunch.in.clone().startOf('d'),
                    });

                    timepunches.push({
                        ...timepunch,
                        from: timepunch.out.clone().startOf('d'),
                        to: timepunch.out,
                        business_date: timepunch.out.clone().startOf('d'),
                    });
                } else {
                    timepunches.push({
                        ...timepunch,
                        from: timepunch.in,
                        to: timepunch.out,
                    });
                }
            });

            return timepunches;
        };
    } ],
});
