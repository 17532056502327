import { pick } from 'lodash-es';

import { extend, module } from 'angular';

/**
 * @ngdoc controller
 * @name eaw\.dialogs.controller.dialogCtrl
 */
module('eaw.dialogs').controller('dialogCtrl', [ '$mdDialog', function($mdDialog) {
    // @ts-ignore
    extend(this, pick($mdDialog, [ 'hide', 'cancel' ]));
} ]);
