import { Inject, Injectable } from '@angular/core';
import { classifyItem } from '../utils/rxjs/classify';
import { Warning, WarningResponse } from '../models/warning';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class WarningService {

    constructor(
      @Inject(HttpClient) private http: HttpClient,
    ) { }

    get(customerId: number, warningId: number, withArgs: string[] = []) {
        return this.http.get<WarningResponse>(`/customers/${customerId}/warnings/${warningId}`, {
            params: { 'with[]': withArgs },
        }).pipe(classifyItem(Warning));
    };
}
