import { Component, EventEmitter, inject, Input, numberAttribute, OnInit, Output } from '@angular/core';
import { PermissionSetService } from '../../http/permission-set.service';
import { Permission } from '../../models/permission';
import { PermissionSet } from '../../models/permission-set';
import { catchError, EMPTY, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { SimplePermission } from '../../interfaces/simple-permission';
import { PermissionService } from '../../../admin/angularjs/shared/services/permission.service';
import { ConfirmDialogService } from '../../dialogs/confirm-dialog/confirm-dialog.service';
import { DateTimePipe } from '../../pipes/date-time.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import { AddPermissionDialogComponent } from '../../../admin/dialogs/add-permission-dialog/add-permission-dialog.component';
import { DialogData } from '../../dialogs/dialog-component';
import { MatDialog } from '@angular/material/dialog';
import { AddPermissionSetDialogComponent } from '../../../admin/dialogs/add-permission-set-dialog/add-permission-set-dialog.component';
import { PermissionNodeComponent } from '../permission-node/permission-node.component';
import { PermissionCheckService } from '../../services/permission-check.service';
import { ApiModel, ApiModelClass } from '../../enums/api-model';
import { TranslateService } from '../../services/translate.service';

export type PermissionTreeNode<V = Permission | PermissionSet | SimplePermission> = {
    changingValue?: boolean;
    parent?: PermissionSet
    value: V,
    expanded: boolean;
    loaded: boolean;
    count?: number | undefined;
    loadingCount?: boolean;
    disabled?: boolean;
};

@Component({
    selector: 'eaw-permission-tree',
    templateUrl: './permission-tree.component.html',
    styleUrl: './permission-tree.component.scss',
    standalone: true,
    imports: [
        NgStyle,
        NgFor,
        MatCardModule,
        NgIf,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatMenuModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        PermissionNodeComponent,
    ],
})
export class PermissionTreeComponent implements OnInit {
    private readonly dialog = inject(MatDialog);
    private readonly translate = inject(TranslateService);
    private readonly confirmDialog = inject(ConfirmDialogService);
    private readonly permissionSetService = inject(PermissionSetService);
    private readonly permissionService = inject(PermissionService);
    private readonly permissionCheckService = inject(PermissionCheckService);

    @Input({ required: true, transform: numberAttribute }) permissionSetId!: number;
    @Input() permissionSet?: PermissionSet;
    @Input() permissionSetParent?: PermissionSet;
    @Input() deletePermissionSet?: (set: PermissionSet) => Observable<unknown>;

    @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

    protected permissionSets?: PermissionTreeNode<PermissionSet>[];
    protected permissions?: PermissionTreeNode<Permission>[];
    protected rootNode?: PermissionTreeNode<PermissionSet>;
    protected removing: boolean = false;

    protected canAddPermissionSet?: Observable<boolean>;
    protected canDetachFromParent?: Observable<boolean>;
    protected canAddPermission?: Observable<boolean>;
    protected canDeletePermissions?: Observable<boolean>;
    protected canUpdatePermissions?: Observable<boolean>;

    ngOnInit(): void {
        if (this.permissionSet) {
            this.onLoaded(this.permissionSet);
        } else {
            this.loadData();
        }

        this.canDetachFromParent = this.permissionSetParent ? this.permissionCheckService.isAllowed(`permission_sets.[${ApiModel.PermissionSet}].permission_sets.*.delete`, {
            models: [ { id: this.permissionSetParent.id, type: ApiModelClass.PermissionSet } ],
        }) : of(false);

        const models = {
            models: [ { id: this.permissionSetId, type: ApiModelClass.PermissionSet } ],
        };
        this.canAddPermissionSet = this.permissionCheckService.isAllowed(`permission_sets.[${ApiModel.PermissionSet}].permission_sets.*.create`, models);
        this.canAddPermission = this.permissionCheckService.isAllowed(`permission_sets.[${ApiModel.PermissionSet}].permissions.*.create`, models);
        this.canDeletePermissions = this.permissionCheckService.isAllowed(`permission_sets.[${ApiModel.PermissionSet}].permissions.*.delete`, models);
        this.canUpdatePermissions = this.permissionCheckService.isAllowed(`permission_sets.[${ApiModel.PermissionSet}].permissions.*.update`, models);
    }

    expand() {
        if (!this.rootNode) {
            return;
        }

        this.rootNode.expanded = !this.rootNode.expanded;

        if (!this.rootNode.loaded && this.rootNode.expanded) {
            this.loadData();
        }
    }

    addPermission() {
        if (!this.rootNode) {
            return;
        }

        const node = this.rootNode;

        this.dialog.open<AddPermissionDialogComponent, DialogData, SimplePermission[]>(AddPermissionDialogComponent)
            .afterClosed()
            .pipe(switchMap((perm) => {
                return of(...(perm || [])).pipe(
                    mergeMap((i) => {
                        return this.permissionService.createForSet(node.value.id as number, i);
                    }, 2),
                    catchError((permission) => {
                        console.error('The following permission did not save:', permission.node);

                        return EMPTY;
                    }),
                    tap((permission) => {
                        this.addedChild(permission);
                    }),
                );
            }))
            .subscribe();
    }

    addPermissionSet() {
        if (!this.rootNode) {
            return;
        }

        const node = this.rootNode;

        this.dialog.open<AddPermissionSetDialogComponent, DialogData, PermissionSet>(AddPermissionSetDialogComponent, {})
            .afterClosed()
            .pipe(
                switchMap((child): Observable<PermissionSet> => {
                    if (!child) {
                        return EMPTY;
                    }

                    return this.permissionSetService.attachToSet(node.value.id as number, child.id);
                }),
                tap((value) => {
                    this.addedChild(value);
                }),
            ).subscribe();
    }

    deleteSet() {
        this.deletePermissionSet?.(this.rootNode?.value as PermissionSet).subscribe();
    }

    protected loadData() {
        const args = {
            with: [ 'permissions', 'permissionSets' ],
            count: [ 'permissionSetObjects' ],
        };

        this.permissionSetService.get(this.permissionSetId, args)
            .pipe(tap((permissionSet) => {
                this.permissionSet = permissionSet;
                this.onLoaded(permissionSet);
            }))
            .subscribe();
    }

    protected removeFromParent() {
        if (!this.rootNode) {
            return;
        }

        const node = this.rootNode;
        this.removing = true;

        this.confirmDialog.delete({
            title: this.translate.t('REMOVE_FROM_SET', 'permissions', { x: node.value.name }),
            text: this.translate.t('DETACH_PERMISSION_SET_CONFIRMATION', 'permissions'),
            confirmText: this.translate.t('REMOVE'),
        })
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (result?.ok) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        return this.permissionSetService.detachFromSet(node.parent!.id, node.value.id as number);
                    }
                    this.removing = false;
                    return EMPTY;
                }),
                catchError((e) => {
                    console.error(e);
                    this.removing = false;

                    return EMPTY;
                }),
                tap(() => {
                    this.deleted.emit();
                }),
            )
            .subscribe();
    }

    protected onRemovedPermissionSet(node: PermissionTreeNode) {
        this.permissionSets = this.permissionSets?.filter((item) => item.value.id !== node.value.id);
    }

    protected deletePermission(node: PermissionTreeNode) {
        const value = node.value as Permission;
        node.disabled = true;

        this.confirmDialog.delete({ name: value.node }).afterClosed()
            .pipe(
                switchMap((result) => {
                    if (!result?.ok) {
                        return EMPTY;
                    }

                    return this.permissionService.deleteForSet(this.permissionSetId, value.node);
                }),
                catchError((err) => {
                    console.error(err);
                    return EMPTY;
                }),
                tap(() => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const index = this.permissions!.findIndex((item) => item === node);
                    if (index >= 0) {
                        this.permissions?.splice(index, 1);
                    }
                }),
            ).subscribe({
                complete: () => node.disabled = false,
            });
    }

    protected changePermissionValue(permission: Permission, value: boolean) {
        console.log('changePermissionValue', permission);
        return this.permissionService.updateForSet(this.permissionSetId, permission.node, value)
            .pipe(
                map((perm) => {
                    console.log(perm);
                    permission.value = perm.value;

                    return perm.value;
                }),
            );
    }

    private onLoaded(permissionSet: PermissionSet) {
        const loadedChildren = !!(permissionSet.permissions && permissionSet.permissionSets);
        this.rootNode = {
            parent: this.permissionSetParent,
            value: permissionSet,
            expanded: this.rootNode?.expanded ?? loadedChildren,
            loaded: loadedChildren,
            count: permissionSet.countParents,
            loadingCount: false,
        };
        const permissionSets = [];
        const permissions = [];

        for (const set of permissionSet.permissionSets || []) {
            permissionSets.push({ value: set, expanded: false, loaded: false });
        }

        for (const perm of permissionSet.permissions || []) {
            permissions.push({ value: perm, expanded: false, loaded: false });
        }

        this.permissionSets = permissionSets;
        this.permissions = permissions;
    }

    private addedChild(permissionOrSet: PermissionTreeNode['value']) {
        if (this.permissionSets) {
            const node = {
                expanded: false,
                loaded: false,
                value: permissionOrSet,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                parent: this.rootNode!.value,
            };

            if (this.permissionSetId !== node.parent?.id) {
                return;
            }

            if (node.value instanceof PermissionSet) {
                this.permissionSets?.push({ value: node.value, expanded: false, loaded: false });
            } else {
                this.permissions?.push({ value: node.value as Permission, expanded: false, loaded: false });
            }
        }
    }
}
