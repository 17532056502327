import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, ElementRef, HostBinding, inject, Injector, input, OnDestroy, output, signal } from '@angular/core';
import { Shift } from '../../../../../../models/shift';
import { ScheduleComponent } from '../../../../schedule.component';
import { AsyncPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MiniShiftDialogComponent, MiniShiftDialogComponentData } from '../../../../dialogs/mini-shift-dialog/mini-shift-dialog.component';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate.pipe';
import { BusinessDate } from 'src/app/shared/utils/business-date';
import { InfoLoadingComponent } from '../../../../../../../shared/components/info-loading/info-loading.component';
import { DurationPipe } from '../../../../../../../shared/pipes/duration.pipe';
import { ScheduleTabCreateShiftRowComponent } from '../schedule-tab-create-shift-row/schedule-tab-create-shift-row.component';
import { TempShift } from '../../../../classes/temp-shift';
import { catchError, distinctUntilChanged, EMPTY, filter, finalize, of, Subject, take, takeUntil, timer } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SHIFT_TOOLTIP_DATA, ShiftTooltipComponent, ShiftTooltipData } from '../../../shift-tooltip/shift-tooltip.component';
import { ProfilePictureComponent, ProfilePictureUser } from '../../../../../../../shared/components/profile-picture/profile-picture.component';
import { DateTimePipe } from '../../../../../../../shared/pipes/date-time.pipe';
import { ShiftPeriod } from '../../../../../../models/shift-period';
import { DragDrop, DragRef, Point } from '@angular/cdk/drag-drop';
import { ScheduleTabComponent } from '../../schedule-tab.component';
import { ShiftService } from '../../../../../../http/shift.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface PeriodItem {
    period: ShiftPeriod;
    index: number;
    isDark: boolean;
    isLight: boolean;
    backgroundColor: string;
    width: string;
    left: string;
}

@Component({
    selector: 'eaw-shift-line',
    standalone: true,
    imports: [
        AsyncPipe,
        TranslatePipe,
        InfoLoadingComponent,
        DurationPipe,
        ProfilePictureComponent,
        DateTimePipe,
    ],
    templateUrl: './shift-line.component.html',
    styleUrl: './shift-line.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftLineComponent implements OnDestroy {
    private elementRef = inject(ElementRef) as ElementRef<HTMLElement>;
    private shiftService = inject(ShiftService);
    private destroyRef = inject(DestroyRef);
    private matDialog = inject(MatDialog);
    private dragDrop = inject(DragDrop);
    private injector = inject(Injector);
    private overlay = inject(Overlay);

    @HostBinding('class.saving') get savingClass() {
        return this.saving();
    }

    @HostBinding('class.updating') get updatingClass() {
        return this.updating();
    }

    @HostBinding('class.deleting') get deletingClass() {
        return this.deleting();
    }

    @HostBinding('class.expanded') get expandedClass() {
        return this.expanded();
    }

    shift = input.required<Shift>();
    stackId = input.required<number>();
    customerId = input.required<number>();
    scheduleId = input.required<number>();
    pixelsPerSecond = input.required<number>();

    dragging = output<boolean>();

    private destroyController = new AbortController();
    private dragRef?: DragRef<HTMLElement>;

    element = this.elementRef.nativeElement;
    saving = computed(() => this.shift() instanceof TempShift);
    tooltipOverlayRef = signal<OverlayRef | undefined>(undefined);
    warningsCount = computed(() => ScheduleComponent.warnings().get(this.shift().id)?.size);
    commentsCount = computed(() => ScheduleComponent.shifts().get(this.shift().id)?.comments.length);
    periodItems = computed<PeriodItem[]>(this.computePeriodItems.bind(this));
    expanded = computed(() => ScheduleComponent.properties.scheduleTab.expandedShifts.value());
    updating = signal(false);
    deleting = signal(false);
    showNicknames = computed(() => ScheduleComponent.properties.scheduleTab.showNicknames.value());
    employee = computed(this.computeEmployee.bind(this));
    user = computed(this.computeUser.bind(this));
    displayedName = computed(() => this.showNicknames() ? (this.employee()?.nickname || this.employee()?.name) : this.employee()?.name);

    constructor() {
        // Delay the initialization a little in case the user is simply scrolling by and don't intend to interact with the shift.
        timer(100).pipe(
            takeUntilDestroyed(this.destroyRef),
            take(1),
        ).subscribe(() => {
            ScheduleComponent.listenForShiftEvent(this.shift().id, 'updating', this.destroyRef).subscribe((data) => this.updating.set(data));
            ScheduleComponent.listenForShiftEvent(this.shift().id, 'deleting', this.destroyRef).subscribe((data) => this.deleting.set(data));

            this.listenNewShiftLineDragging();
            this.createDrag();
            this.createTooltipListeners();
            this.createContextMenuListener();
            this.createDialogListeners();

            this.elementRef.nativeElement.classList.add('initialized');
        });

        effect(() => {
            this.elementRef.nativeElement.style.setProperty('--offset', `${this.shift().offset * this.pixelsPerSecond()}px`);
            this.elementRef.nativeElement.style.setProperty('--width', `${(this.shift().length * this.pixelsPerSecond()) - 1}px`);
        });
    }

    ngOnDestroy() {
        this.destroyController.abort();
        this.dragRef?.dispose();
    }

    private createDrag() {
        this.dragRef = this.dragDrop.createDrag<HTMLElement>(this.elementRef, {
            dragStartThreshold: 50,
            pointerDirectionChangeThreshold: 0,
        });

        this.dragRef.lockAxis = 'x';
        this.dragRef.withBoundaryElement(this.elementRef.nativeElement.parentElement);
        this.dragRef.started.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onDragStart.bind(this));
        this.dragRef.ended.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => this.onDragEnd(event.distance));
    }

    private onDragStart() {
        this.tooltipOverlayRef()?.dispose();
        this.dragging.emit(true);
    }

    private onDragEnd(distance: Point) {
        this.dragging.emit(false);

        const secondsDiff = distance.x / this.pixelsPerSecond();
        const preciseOffset = Math.max(0, this.shift().offset + secondsDiff);
        const newOffset = ScheduleTabComponent.findClosestInterval(preciseOffset, { useHalf: true });

        if (this.shift().offset === newOffset) {
            this.dragRef?.reset();
            return;
        }

        ScheduleComponent.broadcastEvent(this.shift().id, 'updating', true);
        this.shiftService.update(this.customerId(), this.scheduleId(), this.shift().id, {
            offset: newOffset,
        }).pipe(
            catchError(() => {
                return EMPTY;
            }),
            finalize(() => {
                this.dragRef?.reset();
                ScheduleComponent.broadcastEvent(this.shift().id, 'updating', false);
            }),
        ).subscribe((response) => {
            ScheduleComponent.broadcastEvent(this.shift().id, 'updated', response);
            ScheduleComponent.setShift(response);
        });
    }

    private createDialogListeners() {
        this.elementRef.nativeElement.addEventListener('mousedown', (downEvent) => {
            if (downEvent.button !== 0) {
                return;
            }

            const mouseLeaveController = new AbortController();
            this.elementRef.nativeElement.addEventListener('mouseleave', () => {
                mouseLeaveController.abort();
            }, { once: true });

            this.elementRef.nativeElement.addEventListener('mouseup', (upEvent) => {
                const xMovement = Math.abs(downEvent.clientX - upEvent.clientX);
                const yMovement = Math.abs(downEvent.clientY - upEvent.clientY);

                if (xMovement <= 5 && yMovement <= 5) {
                    this.openMiniShiftDialog();
                }
            }, { signal: mouseLeaveController.signal, once: true });
        }, { signal: this.destroyController.signal });
    }

    private createContextMenuListener() {
        this.elementRef.nativeElement.addEventListener('contextmenu', (e) => {
            e.preventDefault();
            e.stopPropagation();
        }, { signal: this.destroyController.signal });
    }

    private createTooltipListeners() {
        this.elementRef.nativeElement.addEventListener('mouseenter', () => {
            const abort = new Subject<boolean>();

            timer(300).pipe(
                takeUntilDestroyed(this.destroyRef),
                takeUntil(abort),
            ).subscribe(this.showTooltip.bind(this));

            this.elementRef.nativeElement.addEventListener('mouseleave', () => {
                abort.next(true);
                abort.complete();
                this.tooltipOverlayRef()?.dispose();
            }, { once: true, signal: this.destroyController.signal });
        }, { signal: this.destroyController.signal });
    }

    private computePeriodItems(): PeriodItem[] {
        return (ScheduleComponent.shifts().get(this.shift().id)?.periods || []).sort((a, b) => b.length - a.length).map((period, index) => {
            return {
                period,
                index,
                isDark: period.color.isDark(),
                isLight: period.color.isLight(),
                backgroundColor: period.color.toHexString(),
                width: `${period.length * this.pixelsPerSecond()}px`,
                left: `${period.offset * this.pixelsPerSecond()}px`,
            };
        });
    }

    private computeEmployee() {
        const employeeId = this.shift().employeeId;
        return employeeId ? ScheduleComponent.employees().get(employeeId) : undefined;
    }

    private computeUser() {
        const employee = this.employee();
        if (!employee) {
            return undefined;
        }

        return {
            id: employee.userId,
            name: employee.name,
        } satisfies ProfilePictureUser;
    }

    listenNewShiftLineDragging() {
        ScheduleTabCreateShiftRowComponent.dragging(this.destroyRef).subscribe((dragging) => {
            if (dragging) {
                this.element.classList.add('dragging');
            } else {
                this.element.classList.remove('dragging');
            }
        });
    }

    showTooltip() {
        const shiftTooltipPortal = new ComponentPortal(ShiftTooltipComponent, null, Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: SHIFT_TOOLTIP_DATA,
                    useValue: {
                        shift: this.shift(),
                        customerId: this.customerId(),
                    } satisfies ShiftTooltipData,
                },
            ],
        }));

        const positionStrategy = this.overlay.position()
            .flexibleConnectedTo(this.elementRef)
            .withFlexibleDimensions(true)
            .withGrowAfterOpen(true)
            .withViewportMargin(40)
            .withPositions([
                { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: -8 },
                { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetY: 8 },
            ]);

        this.tooltipOverlayRef.set(this.overlay.create({
            disposeOnNavigation: true,
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy,
        }));

        const componentRef = this.tooltipOverlayRef()?.attach(shiftTooltipPortal);

        componentRef?.instance.heightChange().pipe(
            distinctUntilChanged(),
            filter((height) => height > 0),
        ).subscribe((height) => {
            this.tooltipOverlayRef()?.updateSize({ height });
            this.tooltipOverlayRef()?.updatePosition();
        });
    }

    openMiniShiftDialog() {
        const shift = this.shift();
        const schedule = ScheduleComponent.schedule();
        if (!schedule) {
            return;
        }

        const { periods, comments, warnings, qualifications } = shift;
        const dialogRef = this.matDialog.open<MiniShiftDialogComponent, MiniShiftDialogComponentData>(MiniShiftDialogComponent, {
            backdropClass: 'mini-shift-dialog-backdrop',
            data: {
                stackId: this.stackId(),
                customerId: schedule.customerId,
                scheduleId: shift.scheduleId,
                shiftId: shift.id,
                shift: of({
                    shift,
                    periods,
                    comments,
                    warnings,
                    qualifications,
                    schedule,
                }),
                isTemplate: schedule.isTemplate,
                date: shift.from,
                businessDate: shift.businessDate || new BusinessDate(shift.from),
                employee: this.employee(),
            },
        });

        dialogRef.afterOpened().subscribe(() => {
            this.element.classList.add('has-open-dialog');
        });

        dialogRef.afterClosed().subscribe(() => {
            this.element.classList.remove('has-open-dialog');
        });
    }
}
