// @ts-nocheck
import { module } from 'angular';
const template1 = `<md-dialog class="error-dialog mini-dialog" data-status="{{errCtrl.response.status}}">
    <md-toolbar class="default-toolbar" md-colors="{background: errCtrl.resendable ? 'amber' : 'red'}">
        <div class="md-toolbar-tools">
            <h2 ng-if="!errCtrl.resendable" ng-bind="errCtrl.title" flex></h2>
            <h2 ng-if="errCtrl.resendable" bo-i18next="errors:PLEASE_CONFIRM" flex></h2>

            <md-button class="md-icon-button" ng-click="errCtrl.copy()">
                <md-icon ng-bind="'content_copy'"></md-icon>
                <md-tooltip ng-i18next="errors:COPY_MSG"></md-tooltip>
            </md-button>
        </div>
    </md-toolbar>

    <md-dialog-content style="min-height: 100px">
        <md-list class="md-dense">
            <md-list-item class="error-item" ng-repeat="err in errCtrl.errors">
                <p ng-class="{'deleted': err.ignored}" ng-bind="err.message"></p>

                <md-button ng-if="errCtrl.resendable" class="md-secondary" ng-click="err.ignored = true" ng-disabled="err.ignored">
                    <span ng-i18next="errors:IGNORE"></span>
                </md-button>

                <md-divider ng-if="errCtrl.resendable && !$last"></md-divider>
            </md-list-item>
        </md-list>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <small ng-if="errCtrl.response.status" md-colors="{color: 'grey'}">
            <span ng-bind="errCtrl.response.status"></span>
            <span> - </span>
            <span ng-bind="errCtrl.response.config.method"></span>
        </small>

        <span flex></span>

        <md-button close-dialog ng-i18next="CLOSE"></md-button>
        <md-button ng-click="errCtrl.ignore()" ng-i18next="errors:RESEND" ng-if="errCtrl.resendable" ng-disabled="!errCtrl.everythingIgnored()"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.resource').factory('errorService', [ '$injector', '$state', function errorService($injector, $state) {
    // @ts-ignore
    const es = this;
    es.showError = function showError(response) {
        console.error(`response`, response);
        // TODO Remove when we rework "Add new employee"
        const isNewEmployee = $state.$current.name === 'eaw/app/company/employees/new';
        const isPOST = response.config?.method === 'POST';
        const isGroupMember = response.config && response.config.url.endsWith('members');
        if (isNewEmployee && isPOST && isGroupMember) {
            return;
        }
        // Don't spam with unauthenticated errors, one is enough
        if (response.status === 401 && document.querySelector('.error-dialog[data-status="401"]')) {
            return;
        }
        const dialog = $injector.get('$mdDialog');
        return dialog.show({
            template: template1,
            bindToController: true,
            multiple: true,
            locals: {
                response,
            },
            controllerAs: 'errCtrl',
            controller: 'eawErrorDialogCtrl',
        });
    };
    es.displayXhrError = (response) => {
        if (response.isCached || response.xhrStatus === 'abort' || response?.data?.type === 'Authentication exception') {
            return Promise.reject();
        }
        console.error(response);
        // Success and error callbacks cannot have different response ts, so we sometimes get blobs here.
        if (response.data instanceof Blob) {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onload = (e) => {
                    // @ts-ignore
                    response.data = JSON.parse(e.target.result);
                    es.showError(response).then(resolve, reject);
                };
                reader.readAsText(response.data);
            });
        }
        return es.showError(response);
    };
    return es;
} ]);
