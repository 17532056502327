// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';
import { EawChart } from '../../../shared/angularjs/modules/misc/services/eaw-chart';

const template1 = `<eaw-card id="comparison-form">
    <eaw-card-body class="card-padding">
        <form name="comparisonForm">
            <div layout="row" layout-wrap>
                <eaw-date-interval layout="row"
                                   ng-model="$ctrl.dateInterval"
                                   required
                                   flex="100"
                                   flex-md="50"
                                   flex-gt-md="50">
                </eaw-date-interval>

                <md-input-container flex="100" flex-sm="50" flex-gt-sm="25">
                    <label ng-i18next="ai_budgeting:VARIABLE"></label>
                    <md-select ng-model="$ctrl.variable" required>
                        <md-option ng-repeat="variable in $ctrl.bucket.variables track by variable.uuid" ng-value="variable">
                            <span ng-bind="variable.name"></span>
                        </md-option>
                    </md-select>
                </md-input-container>

                <md-input-container flex="100" flex-sm="50" flex-gt-sm="25">
                    <label ng-i18next="INTERVAL"></label>
                    <md-select ng-model="$ctrl.timeInterval" required>
                        <md-option ng-repeat="(interval, text) in $ctrl.constants.intervals track by $index" ng-value="interval">
                            <span ng-bind="text | eawTranslate:'ai_budgeting'"></span>
                        </md-option>
                    </md-select>
                </md-input-container>
            </div>

            <md-button ng-i18next="SUBMIT" class="md-raised md-accent" ng-disabled="comparisonForm.$invalid || $ctrl.gettingData" ng-click="$ctrl.getComparison()"></md-button>
        </form>
    </eaw-card-body>
</eaw-card>

<md-progress-circular class="md-progress-center tw-mt-20" ng-show="$ctrl.gettingData && !$ctrl.chart"></md-progress-circular>

<div id="comparisons-chart" ng-show="$ctrl.chart"></div>

`;

module('eaw.ai-budgeting').component('aiBudgetComparison', {
    template: template1,
    bindings: {
        bucket: '<',
        constants: '<',
        customer: '<',
    },
    controller: [ 'mlComparisonFactory', '$element', function(mlComparisonFactory, $element) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.dateInterval = {
                from: moment().subtract(1, 'w').subtract(1, 'd').startOf('d'),
                to: moment().subtract(1, 'd').endOf('d'),
            };
        };

        ctrl.getComparison = () => {
            ctrl.gettingData = true;
            mlComparisonFactory.getForecastAndActual.query(
                ctrl.customer.id,
                ctrl.bucket.id,
                ctrl.variable.id,
                ctrl.dateInterval.from,
                ctrl.dateInterval.to,
                ctrl.timeInterval,
            ).then(ctrl.createChart).finally(() => delete ctrl.gettingData);
        };

        ctrl.createChart = (resp) => {
            const actual = resp.actual;
            const forecast = resp.forecast;

            const chartOptions = {
                title: {
                    text: undefined,
                },
                chart: {
                    type: 'line',
                },
                xAxis: {
                    categories: ctrl.setCategories(actual),
                },
                series: [
                    {
                        name: t('ai_budgeting:ACTUAL'),
                        data: ctrl.setSeriesData(actual),
                    },
                    {
                        name: t('ai_budgeting:FORECAST'),
                        data: ctrl.setSeriesData(forecast),
                    },
                ],
            };

            if (ctrl.chart) {
                ctrl.chart.getChart().then((chart) => {
                    chart.series[0].update({
                        data: ctrl.setSeriesData(actual),
                    });
                    chart.series[1].update({
                        data: ctrl.setSeriesData(forecast),
                    });
                    chart.xAxis[0].setCategories(ctrl.setCategories(actual));
                });
            } else {
                ctrl.chart = new EawChart($element[0].querySelector('#comparisons-chart'), chartOptions);
            }
        };

        ctrl.setCategories = (data) => {
            const format = ctrl.timeInterval === 86400 ? 'll' : 'lll';
            return data.map((d) => moment(d.time, 'YYYY-MM-DD HH:mm:ss').format(format));
        };

        ctrl.setSeriesData = (data) => data.map((d) => d.value);
    } ],
});
