// @ts-nocheck
import { module } from 'angular';
module('eaw.staffing').factory('staffingTemplateFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/vs_templates/:template');
    fac.getAll = {
        permission(customerId) {
            return `customers.${customerId}.vs_templates.*.get`;
        },
        query(customerId, args) {
            return res.get({
                ...Pagination.getParams(args),
                customer: customerId,
            });
        },
    };
    fac.get = {
        permission(customerId, templateId) {
            return `customers.${customerId}.vs_templates.${templateId}.get`;
        },
        query(customerId, templateId, withs) {
            return res.get({
                customer: customerId,
                template: templateId,
                'with[]': withs,
            });
        },
    };
    fac.create = {
        permission(customerId) {
            return `customers.${customerId}.vs_templates.create`;
        },
        query(customerId, name, input, output, otherArgs) {
            otherArgs = otherArgs || {}; // Have to be an object cause we can't spread undefined
            return res.save({
                customer: customerId,
            }, {
                name,
                input,
                output,
                ...otherArgs,
            });
        },
    };
    fac.update = {
        permission(customerId, templateId) {
            return `customers.${customerId}.vs_templates.${templateId}.update`;
        },
        query(customerId, templateId, updates) {
            return res.update({
                customer: customerId,
                template: templateId,
            }, updates);
        },
    };
    fac.delete = {
        permission(customerId, templateId) {
            return `customers.${customerId}.vs_templates.${templateId}.delete`;
        },
        query(customerId, templateId) {
            return res.delete({
                customer: customerId,
                template: templateId,
            });
        },
    };
    return fac;
} ]);
