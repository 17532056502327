<eaw-page-header [fabButton]="fabButton">
    <span title>{{ 'CUSTOMER_plural' | translate: 'admin' | async }}</span>
</eaw-page-header>

<mat-card>
    <eaw-data-table
        #table
        [columns]="columns"
        [request]="request"
        initialSortBy="created_at"
        [goTo]="{state: 'eaw/app/admin/customers/view/info', params: [{stateKey: 'id', itemKey: 'id'}]}"
        (paginationChange)="updateTable($event)">
    </eaw-data-table>
</mat-card>
