<mat-card class="mat-compact-card mat-card-contained tw-m-8">
    <mat-card-header class="tw-p-8 tw-flex tw-flex-row tw-justify-between tw-items-center">
        <mat-card-title class="tw-m-0">{{'USER_ACCESS'|translate: 'company'|async}}</mat-card-title>
        <button mat-mini-fab color="accent" (click)="addAccess()" [disabled]="!user">
            <mat-icon>add</mat-icon>
        </button>
    </mat-card-header>

    <mat-card-content>
        <eaw-data-table [columns]="columns" [request]="request" (paginationChange)="updateTable($event)" skipQueryParams [initialSortBy]="'from'"></eaw-data-table>
    </mat-card-content>
</mat-card>
