import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiModel } from '../enums/api-model';
import { map } from 'rxjs';
import { ModelCustomFieldResponse, ModelCustomField } from '../../custom-fields/models/model-custom-field';
import { BaseApiModel } from '../models/base-api-model';
import { ApiResponse } from '../interfaces/api-response';
import { sort } from '../angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../services/current.service';

@Injectable({
    providedIn: 'root',
})
export class CustomerCustomFieldService {
    http = inject(HttpClient);
    currentService = inject(CurrentService);

    getAll(customerId: number) {
        return this.http.get<Record<string, ModelCustomFieldResponse>>(`/customers/${customerId}/custom_fields/all`).pipe(
            map((response) => {
                return Object.values(response).map((value) => new ModelCustomField(value));
            }),
        );
    }

    /**
     * Returns all custom fields for a given model
     *
     * Values are sorted by priority
     *
     * @param customerId
     * @param apiModel
     * @param model - If a model is provided, we will use its attached custom fields and then add any extra custom fields that are not attached
     */
    getForModel(customerId: number, apiModel: ApiModel, model?: BaseApiModel<ApiResponse, unknown> | null) {
        return this.http.get<ModelCustomFieldResponse[]>(`/customers/${customerId}/custom_fields/${apiModel}`).pipe(
            map((response) => {
                const fields: ModelCustomField[] = model?.attachedCustomFields ?? [];

                response.forEach((r) => {
                    if (fields.find((f) => f.key === r.key)) {
                        return;
                    }

                    fields.push(new ModelCustomField(r));
                });

                return sort(fields, this.currentService.languageTag, [ (f) => f.priority ], [ 'asc' ]);
            }),
        );
    }
}
