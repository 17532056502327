<mat-card-content class="content">
    <mat-card-header class="stat-card-header"
                     [style.background-color]="absenceType.color"
                     [style.color]="absenceType.textColor"
                     [style.font-weight]="absenceType.textColor === 'black'? 'bold' : '500'">
        <span>{{absenceType.name | translate: 'absence_types' | async}}</span>
    </mat-card-header>

    <table class="table table-condensed">
        <tr>
            <td><strong>{{ 'COUNT' | translate: 'absences' | async }}</strong></td>
            <td class="text-right">{{stats.count}}</td>
        </tr>
        <tr>
            <td>
                <strong *ngIf="!absenceType.span">{{'TOTAL' | translate: 'absences' | async }}</strong>
                <strong *ngIf="absenceType.span === 'hour'">{{'TOTAL_HOURS' | translate: 'absences' | async }}</strong>
                <strong *ngIf="absenceType.span === 'day'">{{'TOTAL_DAYS' | translate: 'absences' | async }}</strong>
            </td>
            <td class="text-right">{{ stats.length | eawNumber:decimals }}</td>
        </tr>
    </table>
</mat-card-content>