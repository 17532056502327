import { Component, DestroyRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { ScheduleDay } from '../../angularjs/types/schedule-day';
import { ScheduleSummary } from '../../models/schedule-summary';
import { ScheduleSummaryService } from '../../http/schedule-summary.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { debounce } from 'lodash-es';
import type angular from 'angular';
import { Moment } from 'moment-timezone';
import { forEach } from 'lodash-es';
import { DateTime } from 'luxon';
import { BackOfficeSyncService } from '../../angularjs/backoffice/sync/back-office-sync-class';
import { filter, Subscription, throttleTime } from 'rxjs';
import { ShiftEvent } from '../../types/shift-event';
import { WebsocketService } from '../../../shared/services/websocket.service';
import { BusinessDateString } from '../../../shared/types/business-date';
import { BusinessDate } from '../../../shared/utils/business-date';
import { momentToDateTime } from '../../../shared/angularjs/moment-to-date-time';
import { RoundToFractionPipe } from '../../../shared/pipes/round-to-quarter.pipe';
import { PercentPipe } from '../../../shared/pipes/percent.pipe';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
    selector: 'eaw-budget-sidebar',
    templateUrl: './budget-sidebar.component.html',
    styleUrl: './budget-sidebar.component.scss',
    providers: [
        {
            provide: 'shiftEvents',
            useFactory: ($injector: any) => $injector.get('shiftEvents'),
            deps: [ '$injector' ],
        },
        {
            provide: 'scheduleDays',
            useFactory: ($injector: any) => $injector.get('scheduleDays'),
            deps: [ '$injector' ],
        },
        {
            provide: 'BackOfficeSync',
            useFactory: ($injector: any) => $injector.get('BackOfficeSync'),
            deps: [ '$injector' ],
        },
        {
            provide: 'scheduleTable',
            useFactory: ($injector: any) => $injector.get('scheduleTable'),
            deps: [ '$injector' ],
        },
        {
            provide: '$rootScope',
            useFactory: ($injector: any) => $injector.get('$rootScope'),
            deps: [ '$injector' ],
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
        NgTemplateOutlet,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        NumberPipe,
        PercentPipe,
        RoundToFractionPipe,
    ],
})
export class BudgetSidebarComponent implements OnInit, OnDestroy {
    summary?: ScheduleSummary;
    totals: Record<string, { summary?: ScheduleSummary, from: DateTime, to: DateTime, stale?: boolean }> = {};
    schedule!: any;
    loading = false;
    businessDate!: BusinessDateString;
    weekNr!: number;
    week!: number;
    day?: ScheduleDay;

    onChangeDay!: () => void;

    onRefreshWeek!: () => void;
    private dayChangeListener!: () => void;
    /** Indexed on business date */
    pleaseWaitSummary: Record<string, string> = {};
    /** Indexed on business date */
    lastUpdatedSummary: Record<string, string> = {};
    /** Indexed on weekNr */
    pleaseWaitTotal: Record<string, string> = {};
    /** Indexed on weekNr */
    lastUpdatedTotal: Record<string, string> = {};
    loadingDay: boolean = true;

    private changeSubscription?: Subscription;
    private backOfficeSubscription?: Subscription;
    private lastCreatedAt?: DateTime;

    constructor(
        @Inject('scheduleDays') protected scheduleDays: any,
        @Inject('scheduleTable') protected scheduleTable: any,
        @Inject('BackOfficeSync') private BackOfficeSync: BackOfficeSyncService,
        @Inject('shiftEvents') private shiftEvents: any,
        @Inject('$rootScope') protected rootScope: angular.IRootScopeService,
        @Inject(WebsocketService) protected websocket: WebsocketService,
        @Inject(DestroyRef) protected destroyRef: DestroyRef,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(ScheduleSummaryService) private scheduleSummaryService: ScheduleSummaryService,
    ) { }

    ngOnInit(): void {
        this.schedule = this.scheduleTable.getSchedule();
        this.updateDays();

        this.changeSubscription = this.shiftEvents.getChangedSubject().pipe(
            // If receiving several values, keep the last one within the second
            filter((shiftEvent: ShiftEvent) => {
                return [ 'created', 'deleted', 'updated' ].findIndex((event) => shiftEvent.event == event) >= 0;
            }),
            throttleTime(1000, undefined, {
                leading: false,
                trailing: true,
            }),
        ).subscribe(this.onShiftChange.bind(this));
        this.backOfficeSubscription = this.BackOfficeSync.subject.subscribe(() => this.updateDays(undefined, this.getDay(), true));
        this.dayChangeListener = this.rootScope.$on('sidebarchildren:dayChanged', (_, day) => {
            this.day = day;
            this.updateDays(undefined, day);
        });

        this.calcTotals();
        this.onRefreshWeek = debounce(this.getTotal.bind(this), 1000);

        this.websocket.listenSchedule(this.schedule.customer_id, this.schedule.id, 'summary_calculated', (summary: any) => {
            if (summary.business_date) {
                this.updateDays();
            } else {
                this.getTotal();
            }
        }, this.destroyRef);

        /**
         * Debounce the function so that it doesn't get a million totals if clicking arrows a lot
         * @param {object|moment} day
         * @param {boolean} [daySelector]
         */
        this.onChangeDay = debounce(() => {
            delete this.lastCreatedAt;
            this.updateDays();
        }, 500);
    }

    ngOnDestroy(): void {
        this.changeSubscription?.unsubscribe();
        this.backOfficeSubscription?.unsubscribe();
        this.dayChangeListener?.();
    }

    updateDays(_?: ShiftEvent, day = this.getDay(), force = false) {
        this.businessDate = day.moment.format('YYYY-MM-DD');
        this.weekNr = day.weekNr;
        this.week = day.week;

        this.getBudget(force);
    }

    getBudget(force = false) {
        this.loadingDay = true;
        if (this.summary) {
            void this.setDayStrings(this.businessDate, this.summary);
            delete this.summary;
        }

        this.scheduleSummaryService.get(this.schedule.customer_id, this.schedule.id, {
            business_date: new BusinessDate(this.businessDate).dateTime,
            force,
        }).subscribe(this.onSummary.bind(this));
    }

    protected onSummary(summary: ScheduleSummary): void {
        if (!summary.createdAt) {
            this.summary = summary;
        } else if (!this.summary || !this.lastCreatedAt || this.lastCreatedAt < summary.createdAt && summary.days?.get(this.businessDate)) {
            this.summary = summary;
        }

        this.loadingDay = false;
        void this.setDayStrings(this.businessDate, summary);
    }

    onShiftChange(shiftEvent: ShiftEvent) {
        this.updateDays(shiftEvent, this.getDay(), true);

        if (this.totals[this.getDay().moment.format('w')]?.summary?.sums) {
            // we don't know when the shift was, so set everything to stale.
            forEach(this.totals, (week) => {
                if (!Object.keys(week || []).length) { // can't have stale data if there is NO data.
                    week.stale = true;
                }
            });
        }
    }

    calcTotals() {
        this.totals = {};

        const date: Moment = this.schedule.getFrom();
        while (date.isSameOrBefore(this.schedule.to, 'w')) {
            this.totals[date.format('w')] = {
                from: this.getFrom(date),
                to: this.getTo(date),
            };
            date.add(1, 'w');
        }
    }

    getTotal() {
        this.loading = true;

        const day: ScheduleDay = this.getDay();
        const from = this.getFrom(day.moment);
        const to = this.getTo(day.moment);

        this.scheduleSummaryService.get(this.schedule.customer_id, this.schedule.id, {
            from,
            to,
        }).subscribe(this.onTotal.bind(this));
    }

    onTotal(summary: ScheduleSummary) {
        const day: ScheduleDay = this.getDay();
        const from = this.getFrom(day.moment);
        const to = this.getTo(day.moment);

        this.totals[day.weekNr] = {
            summary,
            from,
            to,
        };
        this.loading = false;

        void this.setWeekStrings(day.weekNr, summary);
    }

    getFrom(date: Moment): DateTime {
        const from = date.clone().startOf('w');

        return this.momentToDateTime(from.isBefore(this.schedule.from) ? this.schedule.getFrom() : from);
    }

    getTo(date: Moment): DateTime {
        const to = date.clone().endOf('w');

        return this.momentToDateTime(to.isAfter(this.schedule.to) ? this.schedule.getTo() : to);
    }

    private momentToDateTime(m: Moment) {
        return momentToDateTime(m);
    }

    getDay(): ScheduleDay {
        return this.day || this.scheduleTable.getCurrentDay();
    }

    async getLastUpdated(summary: ScheduleSummary) {
        if (!summary?.createdAt) {
            return '';
        }

        return await this.translate.t('SCHEDULE_SUMMARY_CALCULATED_AT', 'scheduling', {
            time: summary.createdAt.toRelative({
                padding: 500,
                round: true,
            }),
        });
    }

    async getPleaseWait(summary: ScheduleSummary) {
        if (!summary?.lastDispatch) {
            return '';
        }

        return await this.translate.t('GENERATING_TOTAL_PLEASE_WAIT', 'scheduling', {
            time: summary.lastDispatch.toRelative({
                padding: 500,
                round: true,
            }),
        });
    }

    private async setWeekStrings(w: number, summary: ScheduleSummary) {
        this.lastUpdatedTotal[w] = await this.getLastUpdated(summary);
        this.pleaseWaitTotal[w] = await this.getPleaseWait(summary);
    }

    private async setDayStrings(bd: string, summary: ScheduleSummary) {
        this.lastUpdatedSummary[bd] = await this.getLastUpdated(summary);
        this.pleaseWaitSummary[bd] = await this.getPleaseWait(summary);
    }
}
