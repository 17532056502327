// @ts-nocheck
import { module } from 'angular';
module('eaw.projections').factory('projectionsFactory', [ 'EawResource', function projectionsFactory(EawResource) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/sales_projections/:date', {}, {
        get: {
            method: 'GET',
            isArray: true,
        },
        updateArray: {
            method: 'PUT',
            isArray: true,
        },
    });
    fac.get = {
        permission(customer, date) {
            return `customers.${customer}.sales_projections.${date}.get`;
        },
        query(customer, date) {
            return res.get({
                customer,
                date,
            });
        },
    };
    fac.update = {
        permission(customer, date) {
            return `customers.${customer}.sales_projections.${date}.get`;
        },
        query(customer, date, projections) {
            // @ts-ignore
            return res.updateArray({
                customer,
                date,
            }, { projections });
        },
    };
    fac.set = {
        permission(customer, date) {
            return `customers.${customer}.sales_projections.${date}.update`;
        },
        query(customer, date, args) {
            // @ts-ignore
            return res.updateArray({
                customer,
                date,
            }, {
                day_sales: args.day_sales,
                dates: args.dates,
                projections: args.projections,
            });
        },
    };
    return fac;
} ]);
