import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData } from '../../../shared/dialogs/dialog-component';
import { Contract, ContractAmountType } from '../../../shared/models/contract';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ContractType } from '../../../shared/models/contract-type';
import { ContractService } from '../../../shared/http/contract.service';
import { DateTime } from 'luxon';
import { Storage } from '../../../shared/utils/storage';
import { CustomFieldsGroup, CustomFieldValueWithInterval } from '../../../shared/utils/custom-fields-group';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { ContractTypeAutocompleteService } from '../../../shared/autocompletes/contract-type-autocomplete.service';
import { SettingService } from '../../../shared/http/setting.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { forkJoin, Observable, of } from 'rxjs';
import { CustomerCustomFieldService } from '../../../shared/http/customer-custom-field.service';
import { ModelCustomField } from '../../../custom-fields/models/model-custom-field';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { CustomFieldInputComponent } from '../../../custom-fields/components/custom-field-input/custom-field-input.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { Position } from '../../../shared/models/position';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

export interface ContractDialogFormData {
    type_id: number,
    contract_type?: ContractType,
    from: DateTime,
    to: DateTime | null,
    amount: number,
    amount_type: ContractAmountType,
    title?: string | null;
    custom_fields?: Record<string, CustomFieldValueWithInterval>;
}

export type ContractDialogReturn = Contract | ContractDialogFormData;

export interface ContractDialogData extends DialogData {
    customerId: number;
    employeeId: number;
    contract?: Contract;
    // Only return the submitted form data without sending any requests
    returnFormData?: boolean;
    // Override and set "from" and "to" regardless of the contract
    fromTo?: {
        editable?: boolean,
        from?: DateTime | null,
        to?: DateTime | null
    };
    positions?: Observable<ArrayPaginatedResponse<Position>>;
    customFields?: Record<string, CustomFieldValueWithInterval>;
}

@Component({
    selector: 'eaw-contract-dialog',
    templateUrl: './contract-dialog.component.html',
    styleUrl: './contract-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        AutocompleteComponent,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        NgSwitch,
        NgSwitchCase,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        CustomFieldInputComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class ContractDialogComponent extends DialogComponent<ContractDialogData> implements OnInit {
    loading = true;
    contract = this.data.contract;
    customerId = this.data.customerId;
    employeeId = this.data.employeeId;
    form = new FormGroup({
        contractType: new FormControl<ContractType | null>(null, Validators.required),
        from: new FormControl<DateTime | null>(null, Validators.required),
        to: new FormControl<DateTime | null>(null),
        amount: new FormControl<number | null>(null, [ Validators.required, this.amountValidator.bind(this) ]),
        amountType: new FormControl<ContractAmountType>('percent', { nonNullable: true, validators: Validators.required }),
        position: new FormControl<Position | null>(null, Validators.required),
        customFields: new CustomFieldsGroup(),
    });

    customFields: ModelCustomField[] = [];
    amountTypes: ContractAmountType[] = [];
    hasConfigurableAmountTypes = false;
    positions?: Position[];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ContractDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ContractDialogComponent, ContractDialogReturn>,
        @Inject(ContractService) private contractService: ContractService,
        @Inject(ContractTypeAutocompleteService) public contractTypeAutocomplete: ContractTypeAutocompleteService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
        @Inject(CustomerCustomFieldService) private customerCustomFieldService: CustomerCustomFieldService,
        @Inject(SettingService) private settingService: SettingService,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        forkJoin([
            this.settingService.getValue<ContractAmountType[]>([ 'customers', this.customerId ], 'allowed_contract_amount_types', [ 'week', 'month', 'year', 'percent' ]),
            this.customerCustomFieldService.getForModel(this.customerId, ApiModel.Contract, this.data.contract),
            this.data.positions ? this.data.positions : of(mockArrayPaginatedResponse<Position>([])),
        ]).subscribe(async ([ types, customFields, positions ]) => {
            this.amountTypes = types;
            this.positions = positions.data;
            if (!this.positions?.length) {
                this.form.controls.position.setValidators(null);
            }
            this.hasConfigurableAmountTypes = this.amountTypes.length > 1;
            this.customFields = customFields;

            if (this.hasConfigurableAmountTypes) {
                this.form.controls.amountType.valueChanges.subscribe(() => this.form.controls.amount.updateValueAndValidity());
            } else {
                this.form.controls.amountType.patchValue(this.amountTypes[0] || 'percent');
            }

            this.form.controls.position.valueChanges.subscribe(this.updateCustomFields.bind(this));

            await this.patchForm();
            this.enableForm();

            this.loading = false;
        });
    }

    /**
     * Helper that enables what is relevant for the current dialog state
     */
    enableForm() {
        this.form.enable();

        if (this.data.fromTo && !this.data.fromTo.editable) {
            this.form.controls.from.disable();
            this.form.controls.to.disable();
        }

        if (this.data.contract) {
            this.form.controls.from.disable();
        }

        if (!this.hasConfigurableAmountTypes) {
            this.form.controls.amountType.disable();
        }
    }

    async patchForm() {
        const contract = this.data.contract;

        this.customFields.forEach((field) => (field.value = this.data.customFields?.[field.key]?.value || field.value));

        this.form.patchValue({
            amountType: (await Storage.getItem('contract:default')) || this.form.controls.amountType.value,
        });

        if (this.data.fromTo) {
            this.form.patchValue({
                from: this.data.fromTo.from,
                to: this.data.fromTo.to,
            });
        }

        if (contract) {
            this.form.patchValue({
                contractType: contract.type,
                from: contract.from,
                to: contract.to,
                amount: contract.amount,
                amountType: contract.amountType,
                position: this.positions?.find((p) => p.name === contract.title),
            });
        }
    }

    updateCustomFields(position: Position | null) {
        const values = position?.properties?.find((property) => property.key === 'contracts.cf_default_values')?.value.asKeyValue();
        if (!values) {
            return;
        }

        Object.entries(values || {}).forEach(([ key, value ]) => {
            const cfKey = key.startsWith('cf_') ? key : `cf_${key}`;
            this.form.controls.customFields.get(cfKey)?.patchValue({
                value,
                from: null,
                to: null,
            });
        });
    }

    private amountValidator(control: AbstractControl): ValidationErrors | null {
        if (!this.form) {
            return null;
        }

        let maxNumber = 100;
        switch (this.form.controls.amountType.value) {
            case 'week':
                maxNumber = 7 * 24;
                break;
            case 'month':
                maxNumber = 31 * 24;
                break;
            case 'year':
                maxNumber = 365 * 24;
                break;
            case 'percent':
                maxNumber = 100;
                break;
        }

        return control.value > maxNumber ? { tooLarge: 'Number too large' } : null;
    }

    submit() {
        const from = this.form.controls.from.value;
        const to = this.form.controls.to.value;
        const contractType = this.form.controls.contractType.value;
        const amount = this.form.controls.amount.value;
        const amountType = this.form.controls.amountType.value;
        const customFields = this.form.controls.customFields.getValues();

        if (contractType?.id == null || from == null || amount == null) {
            return;
        }

        const values: ContractDialogFormData = {
            contract_type: contractType,
            type_id: contractType.id,
            from: from.startOf('day'),
            to: to?.endOf('day') || null,
            amount,
            amount_type: amountType,
            title: this.form.controls.position.value?.name,
        };

        if (this.data.returnFormData) {
            values.custom_fields = this.form.controls.customFields.getValuesWithInterval(true);
            this.dialogRef.close(values);
            return;
        }

        this.form.disable();
        if (this.contract) {
            this.contractService.update(this.customerId, this.employeeId, this.contract.id, values, customFields).subscribe({
                next: (contract) => {
                    void this.snackbar.t('CONTRACT_UPDATED', 'company');
                    this.dialogRef.close(contract);
                },
                error: () => this.enableForm(),
            });
        } else {
            this.contractService.create(this.customerId, this.employeeId, values, customFields).subscribe({
                next: (contract) => {
                    void this.snackbar.t('CONTRACT_ADDED', 'company');
                    this.dialogRef.close(contract);
                },
                error: () => this.enableForm(),
            });
        }
    }
}
