import { Component, Inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { OffTime } from '../../models/off-time';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableComponent, DataTableGoTo } from '../../../data-table/data-table.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { Employee } from '../../../shared/models/employee';
import { DateRangeForm } from '../../../shared/types/date-range-form';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { OffTimeService } from '../../http/off-time.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { SettingService } from '../../../shared/http/setting.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { catchError, distinctUntilChanged, EMPTY, map, of, shareReplay, switchMap } from 'rxjs';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DataTableIconColumn } from '../../../data-table/types/data-table-icon-column';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { AlertDialogComponent } from '../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../../shared/models/user';
import { HandleOffTimeService } from '../../dialogs/handle-offtime-dialog/handle-off-time.service';
import { RoleAssignment } from '../../../leader-roles/shared/types/role-assignment';
import { RoleAssignmentService } from '../../../leader-roles/shared/http/role-assignment.service';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

type OffTimeApprovalMode = {
    id: 'unprocessed' | 'processed' | 'approved' | 'declined' | 'all',
    label: string,
    handled: 1 | 0 | null,
    approved: 1 | 0 | null,
}

type FilterForm = {
    approvalMode: FormControl<OffTimeApprovalMode>;
    interval: FormGroup<DateRangeForm>;
    employee: FormControl<Employee | number | null | undefined>;
    onlyMyEmployees: FormControl<boolean>;
}

@Component({
    selector: 'eaw-handle-vacation-list',
    templateUrl: './handle-vacation-list.component.html',
    styleUrl: './handle-vacation-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        AutocompleteComponent,
        NgIf,
        MatCheckboxModule,
        CheckboxHelperDirective,
        MatButtonModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class HandleVacationListComponent implements OnInit, EawDataTable<OffTime> {
    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) userId!: number;
    @Input({ required: true }) stackId!: number;

    @ViewChild('dataTableComponent') dataTable!: DataTableComponent<OffTime>;

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<OffTime>[] = [];
    formGroup!: FormGroup<FilterForm>;
    goTo: DataTableGoTo = {
        state: 'eaw/app/vacations/view/edit',
        params: [ {
            stateKey: 'employeeId',
            itemKey: 'employeeId',
        }, {
            stateKey: 'id',
            itemKey: 'id',
        } ],
    };

    showSubordinates = false;
    canDeleteApproved = false;
    modes = [
        {
            id: 'unprocessed',
            label: 'UNPROCESSED',
            handled: 0,
            approved: null,
        },
        {
            id: 'processed',
            label: 'PROCESSED',
            handled: 1,
            approved: null,
        },
        {
            id: 'approved',
            label: 'APPROVED',
            handled: null,
            approved: 1,
        },
        {
            id: 'declined',
            label: 'DECLINED',
            handled: null,
            approved: 0,
        },
        {
            id: 'all',
            label: 'ALL',
            handled: null,
            approved: null,
        },
    ] as const;

    constructor(
        @Inject(QueryParamsService) protected searchParams: QueryParamsService,
        @Inject(OffTimeService) protected offTimeService: OffTimeService,
        @Inject(SnackBarService) protected snackBarService: SnackBarService,
        @Inject(SettingService) protected settingService: SettingService,
        @Inject(ConfirmDialogService) protected confirmDialog: ConfirmDialogService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(TranslateService) protected translate: TranslateService,
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(HandleOffTimeService) protected handleOfftimeService: HandleOffTimeService,
        @Inject(RoleAssignmentService) protected roleAssignmentService: RoleAssignmentService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
    ) {
    }

    ngOnInit() {
        this.permissionCheckService.isAllowed(`customers.${this.customerId}.users.${this.userId}.get`).subscribe((hasPermission) => {
            if (hasPermission) {
                this.roleAssignmentService.getAllForUser(this.customerId, this.userId, { pagination: { 'with[]': [ 'role' ] } }).pipe(
                    catchError(() => of(mockArrayPaginatedResponse<RoleAssignment>())),
                    map((response) => response.data),
                ).subscribe((roleAssignments) => {
                    this.showSubordinates = User.hasLeaderRole(roleAssignments, this.customerId);
                });
            }
        });

        this.columns = [
            new DataTableTextColumn({
                header: new DataTableHeader({
                    i18n: 'NAME',
                }),
                value: function(cell) {
                    return cell.item.employee?.name || '';
                },
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({
                    i18n: 'FROM',
                    sortBy: 'from',
                }),
                value: 'from',
                format: (cell) => this.getTableDateTimeFormat(cell.item),
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({
                    i18n: 'TO',
                }),
                value: 'to',
                format: (cell) => this.getTableDateTimeFormat(cell.item),
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({
                    i18n: 'POSITIONS',
                    ns: 'navigation',
                }),
                value: function(cell) {
                    return cell.item.employee?.positions?.map((p) => p.name).join(', ') || '';
                },
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({
                    i18n: 'AVAILABLE',
                    ns: 'vacation',
                    classes: new Set([ 'text-right' ]),
                }),
                classes: new Set([ 'text-right' ]),
                value: function(cell) {
                    if (!cell.item.availability) {
                        return '';
                    }
                    return cell.item.availability[0] + '/' + cell.item.availability[1];
                },
            }),
            new DataTableIconColumn({
                header: new DataTableHeader({
                    i18n: 'APPROVED',
                    ns: 'vacation',
                }),
                icon: (cell) => {
                    switch (cell.item.approval?.approved) {
                        case true:
                            return 'check';
                        case false:
                            return 'close';
                        default:
                            return 'remove';
                    }
                },
                color: (cell) => {
                    switch (cell.item.approval?.approved) {
                        case true:
                            return 'green-500';
                        case false:
                            return 'red-500';
                        default:
                            return 'yellow-500';
                    }
                },
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({
                    i18n: 'SENT',
                    sortBy: 'created_at',
                }),
                value: (cell) => cell.item.createdAt,
                format: (cell) => this.getTableDateTimeFormat(cell.item),
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        icon: 'check',
                        type: 'ok',
                        hide: (item) => of(!!item.approval && !!item.approval?.approved),
                        show: this.canHandle.bind(this),
                        tooltip: {
                            key: 'APPROVE',
                        },
                        click: this.approve.bind(this),
                    },
                    {
                        icon: 'close',
                        type: 'warn',
                        hide: (item) => of(!!item.approval && !item.approval?.approved),
                        show: this.canHandle.bind(this),
                        tooltip: {
                            key: 'DECLINE',
                        },
                        click: this.decline.bind(this),
                    },
                    {
                        icon: 'none',
                        click: () => null,
                        hide: () => of(true),
                        show: () => of(true),
                        tooltip: {
                            key: '',
                        },
                    },
                    {
                        icon: 'delete',
                        type: 'warn',
                        hide: (item) => of(!!item.deletedAt),
                        show: this.canDelete.bind(this),
                        tooltip: {
                            key: 'DELETE',
                        },
                        click: this.deleteRequest.bind(this),
                    },
                ],
            }),
        ];

        const from = this.searchParams.get('from', 'date') ?? null;
        const to = this.searchParams.get('to', 'date') ?? null;
        const employeeId = this.searchParams.get('e', 'number');
        const oMyEmployees = this.searchParams.get('o', 'number') ?? 0;

        const employeeCtrl = new FormControl<Employee | number | null | undefined>(employeeId ?? null);
        const onlyMyEmployees: FormControl<boolean> = new FormControl<boolean>(Boolean(oMyEmployees), { nonNullable: true });
        this.formGroup = new FormGroup<FilterForm>({
            approvalMode: new FormControl<OffTimeApprovalMode>(this.modes[0], {
                nonNullable: true,
                validators: Validators.required,
            }),
            interval: new FormGroup<DateRangeForm>({
                from: new FormControl<DateTime | null>(from),
                to: new FormControl<DateTime | null>(to),
            }),
            employee: employeeCtrl,
            onlyMyEmployees,
        });

        this.settingService.getValue([ 'customers', this.customerId ], 'absences.allow_employee_to_delete_approved', 0)
            .subscribe((value) => {
                this.canDeleteApproved = !!value;
            });

        onlyMyEmployees.valueChanges.pipe(
            distinctUntilChanged(),
            switchMap((val) => val ? of(val) : EMPTY),
        ).subscribe(() => {
            if (employeeCtrl.value) {
                employeeCtrl.setValue(null, {
                    emitEvent: false,
                    onlySelf: true,
                });
            }
        });
        employeeCtrl.valueChanges.pipe(
            distinctUntilChanged(),
            switchMap((val) => val ? of(val instanceof Employee ? val.id : val) : EMPTY),
        ).subscribe(() => {
            if (onlyMyEmployees.value) {
                onlyMyEmployees.setValue(false, {
                    emitEvent: false,
                    onlySelf: true,
                });
            }
        });
    }

    approve(cell: DataTableCell<unknown, OffTime>) {
        if (!cell.item.employee?.customer) {
            return;
        }

        this.handleOfftimeService.approve(cell.item.employee?.customer.id, cell.item.employeeId, {
            mode: 'update',
            offTimeId: cell.item.id,
            from: cell.item.from,
            to: cell.item.to,
        }).afterClosed().subscribe((res) => {
            cell.disabled.set(false);

            if (res) {
                this.update();
            }
        });
    }

    decline(cell: DataTableCell<unknown, OffTime>) {
        if (!cell.item.employee?.customer) {
            return;
        }

        this.handleOfftimeService.decline(cell.item.employee?.customer.id, cell.item.employeeId, cell.item.id).subscribe((res) => {
            cell.disabled.set(false);

            if (res) {
                this.update();
            }
        });
    }

    deleteRequest(cell: DataTableCell<unknown, OffTime>) {
        if (cell.item.approval?.approved && !this.canDeleteApproved) {
            return this.matDialog.open(AlertDialogComponent, {
                data: {
                    title: signal(this.translate.t('DELETE_REQUEST', 'vacation')),
                    text: signal(this.translate.t('CANT_DELETE_APPROVED', 'vacation')),
                },
            });
        }

        this.confirmDialog.delete().afterClosed().pipe(
            catchError(() => {
                cell.disabled.set(false);
                return EMPTY;
            }),
        ).subscribe((res) => {
            if (!cell.item.employee?.customer) {
                return;
            }

            if (res?.ok) {
                this.offTimeService.delete(cell.item.employee?.customer.id, cell.item.employeeId, cell.item.id).subscribe(() => {
                    this.snackBarService.deleted();
                    this.update();
                });
            } else {
                cell.disabled.set(false);
            }
        });
    }

    /**
     * Can handle by approving or declining
     */
    canHandle(item: OffTime) {
        if (item.approval?.approved === false) {
            return of(false);
        }

        if (!item.employee) {
            return of(false);
        }

        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.[${ApiModel.Employee}].off_times.[${ApiModel.OffTime}].update`, {
            stackId: this.stackId,
            models: [
                { type: ApiModel.OffTime, id: item.id },
                { type: ApiModel.Employee, id: item.employeeId },
                { type: ApiModel.Customer, id: this.customerId },
            ],
        });
    }

    canDelete(item: OffTime) {
        if (!item.employee) {
            return of(false);
        }

        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.[${ApiModel.Employee}].off_times.[${ApiModel.OffTime}].delete`, {
            stackId: this.stackId,
            models: [
                { type: ApiModel.OffTime, id: item.id },
                { type: ApiModel.Employee, id: item.employeeId },
                { type: ApiModel.Customer, id: this.customerId },
            ],
        });
    }

    getTableDateTimeFormat(ot: OffTime) {
        return ot.from.endOf('day').equals(ot.from.startOf('day')) && ot.to.endOf('minute').equals(ot.to.endOf('day')) ? DateTime.DATE_MED : DateTime.DATETIME_MED;
    }

    getHandledApproved() {
        const mode = this.formGroup.controls.approvalMode.value;
        const options: Partial<{ handled: 1 | 0, approved: 1 | 0 }> = {};

        if (mode.handled != null) {
            options.handled = mode.handled;
        }

        if (mode.approved != null) {
            options.approved = mode.approved;
        }

        return options;
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        const form = this.formGroup.controls;
        const employeeId = form.employee.value instanceof Employee ? form.employee.value.id : form.employee.value;

        const options = {
            ...pagination,
            only_direct_subordinates: form.onlyMyEmployees.value,
            from: form.interval.controls.from.value?.startOf('day'),
            to: form.interval.controls.to.value?.plus({ day: 1 }).startOf('day'),
            'with[]': [ 'approval', 'employee.positions', 'employee.customer', 'availability' ],
            ...this.getHandledApproved(),
        };

        let request = this.offTimeService.getAllForCustomer(this.customerId, options);
        if (employeeId) {
            request = this.offTimeService.getAll(this.customerId, employeeId, options);
        }

        this.request = request.pipe(shareReplay(1));
        this.request.subscribe({
            complete: () => this.formGroup.enable(),
            error: () => this.formGroup.enable(),
        });
    }

    update(page = 1, disableForm = false): void {
        const value = this.formGroup.getRawValue();

        this.searchParams.set([
            {
                key: 'e',
                value: value.employee instanceof Employee ? value.employee.id : value.employee,
            },
            {
                key: 'from',
                value: value.interval.from,
                type: 'date',
            },
            {
                key: 'to',
                value: value.interval.to,
                type: 'date',
            },
            {
                key: 'o',
                value: +value.onlyMyEmployees,
            },
        ]);

        if (disableForm) {
            this.formGroup.disable();
        }

        this.updateTable({
            ...this.dataTable.getPagination(),
            page,
        });
    }

    modeComparer(a: OffTimeApprovalMode, b: OffTimeApprovalMode) {
        return a.id === b.id;
    }
}
