// @ts-nocheck
import { module } from 'angular';

module('eaw.dashboard').component('defaultMiniWidgetOptions', {
    template: `<md-list layout="column">
    <md-list-item ng-repeat="option in options.options track by $index" ng-click="option.click()">
        <p ng-bind="option.text"></p>
    </md-list-item>
</md-list>
`,
    controllerAs: 'options',
    bindings: {
        widget: '<',
        options: '<',
    },
});
