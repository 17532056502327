import { ApiResponse } from '../interfaces/api-response';

export interface AttachmentResponse<OwnerResponse extends ApiResponse> extends ApiResponse {
    created_at: string;
    id: number;
    mime: string;
    name: string;
    owner: OwnerResponse,
    owner_id: number;
    owner_type: string;
    size: number;
    updated_at: string;
    user_id: number;
}

export class Attachment<OwnerResponse extends ApiResponse> {
    createdAt: string;
    id: number;
    mime: string;
    name: string;
    owner: OwnerResponse;
    ownerId: number;
    ownerType: string;
    size: number;
    updatedAt: string;
    userId: number;

    constructor(data: AttachmentResponse<OwnerResponse>) {
        this.id = data.id;
        this.mime = data.mime;
        this.name = data.name;
        this.owner = data.owner;
        this.ownerId = data.owner_id;
        this.ownerType = data.owner_type;
        this.size = data.size;
        this.userId = data.user_id;
        this.createdAt = data.created_at;
        this.updatedAt = data.updated_at;
    }
}
