import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Property, PropertyResponse } from '../../shared/models/property';
import { IGNORE_ERROR } from '../../shared/http/http-contexts';
import { classifyItem } from '../../shared/utils/rxjs/classify';

@Injectable({
    providedIn: 'root',
})
export class SchedulePropertyService {
    private http = inject(HttpClient);

    get(customerId: number, scheduleId: number, key: string) {
        return this.http.get<PropertyResponse>(`/customers/${customerId}/schedules/${scheduleId}/properties/${key}`, {
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(classifyItem(Property));
    }
}
