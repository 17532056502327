import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { ShiftService } from '../../../scheduling/http/shift.service';
import { DateTime } from 'luxon';
import { CurrentService } from '../../../shared/services/current.service';
import { take } from 'rxjs';
import { TranslateService } from '../../../shared/services/translate.service';
import { Widget } from '../../classes/widget';
import { VacantShiftsWidgetSettings } from './vacant-shifts-widget-settings-dialog/vacant-shifts-widget-settings-dialog.component';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';

@Component({
    selector: 'eaw-vacant-shifts',
    templateUrl: './vacant-shifts-widget.component.html',
    styleUrl: './vacant-shifts-widget.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ MiniWidgetContentComponent ],
})
export class VacantShiftsWidgetComponent extends WidgetComponent implements OnInit {
    private current = inject(CurrentService);
    private translate = inject(TranslateService);
    private shiftService = inject(ShiftService);

    widget = input.required<Widget<VacantShiftsWidgetSettings>>();

    text = signal(Promise.resolve(''));
    subtext = signal(Promise.resolve(''));

    ngOnInit() {
        const offset = this.widget().getSetting('day_offset');
        if (offset == null) {
            return this.setError('settings');
        }

        const date = DateTime.now().plus({ days: offset });

        this.shiftService.getAll(this.current.getCustomer().id, {
            vacant: true,
            from: date.startOf('day'),
            to: date.endOf('day'),
            per_page: 1,
        }).pipe(take(1)).subscribe((res) => {
            this.text.set(this.translate.t('X_SHIFTS', 'scheduling', { count: res.total }));
            this.subtext.set(offset === 0 ? this.translate.t('TODAY') : this.translate.t('TOMORROW'));
            this.setLoading(false);
        });
    }
}
