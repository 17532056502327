import moment from 'moment-timezone';
import { module } from 'angular';

module('eaw.company').factory('OpeningHoursFactory', [ 'EawResource', function(EawResource: any) {
    // @ts-ignore
    const fac = this;

    fac.getAll = (customerId: number) => {
        return EawResource.create(`/customers/${customerId}/opening_hours`).query().$promise.then((resp: any) => {
            return resp.map((day: any) => {
                day.open_from = moment(day.open_from, 'HH:mm');
                day.open_to = moment(day.open_to, 'HH:mm');
                return day;
            });
        });
    };

    return fac;
} ]);
