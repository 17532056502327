// @ts-nocheck
import { module } from 'angular';
const template2 = `<md-dialog>
    <eaw-dialog-header-old heading="'company:NEW_FAMILY_MEMBER' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <form name="memberForm" class="md-dialog-content" layout="column">
            <md-input-container>
                <label ng-i18next="custom_fields:NAME_PREFIX"></label>
                <md-select ng-model="dialog.member.prefix" required>
                    <md-option ng-value="'Mrs'">{{::'custom_fields:MRS' | i18next}}</md-option>
                    <md-option ng-value="'Mr'">{{::'custom_fields:MR' | i18next}}</md-option>
                </md-select>
            </md-input-container>

            <md-input-container>
                <label ng-i18next="FIRST_NAME"></label>
                <input ng-model="dialog.member.first_name" required uniq-form-name>
            </md-input-container>

            <md-input-container>
                <label ng-i18next="LAST_NAME"></label>
                <input ng-model="dialog.member.last_name" required uniq-form-name>
            </md-input-container>

            <md-input-container>
                <label ng-i18next="BIRTH_DATE"></label>
                <eaw-date-picker no-label="true" ng-model="dialog.member.birth_date" business-date="true" required></eaw-date-picker>
            </md-input-container>

            <md-input-container>
                <label ng-i18next="RELATIONSHIP"></label>
                <md-select ng-model="dialog.member.relationship" required>
                    <md-option ng-value="'child'">{{'CHILD' | i18next}}</md-option>
                    <md-option ng-value="'spouse'">{{'SPOUSE' | i18next}}</md-option>
                </md-select>
            </md-input-container>

            <md-input-container>
                <label ng-i18next="SEX" ></label>
                <md-select ng-model="dialog.member.sex" required>
                    <md-option ng-value="'F'">{{'FEMALE' | i18next}}</md-option>
                    <md-option ng-value="'M'">{{'MALE' | i18next}}</md-option>
                </md-select>
            </md-input-container>

            <md-input-container>
                <label ng-i18next="company:FAMILY_ALLOWANCE"></label>
                <md-select ng-model="dialog.member.family_allowance">
                    <md-option ng-value="true">{{'YES' | i18next}}</md-option>
                    <md-option ng-value="false">{{'NO' | i18next}}</md-option>
                </md-select>
            </md-input-container>
        </form>
    </md-dialog-content>
    <md-dialog-actions>
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button class="md-accent md-raised" close-dialog resolve="true" ng-disabled="memberForm.$invalid" reason="dialog.member" ng-i18next="SAVE"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

import { Model } from '../../../../shared/angularjs/model';
import { CurrentService } from '../../../../shared/services/current.service';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.company.src').component('familyMembers', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="company:FAMILY_MEMBER_plural"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="$fams.include_deleted"
                        icon="group"
                        ng-change="$fams.getFamilyMembers()"
                        ng-disabled="$fams.loading"
                        i18n-tooltip="INCLUDE_DELETED">
        </eaw-toggle-btn>

        <card-btn-float ng-if="$fams.button.show" on-click="$fams.button.click()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <md-progress-circular class="md-progress-center" ng-if="$fams.loading"></md-progress-circular>

        <div class="tw-mt-24" ng-if="!$fams.loading && !$fams.members.length" layout="row" layout-align="center center">
            <span ng-i18next="src:NO_ACTIVE_MEMBERS" class="md-subhead"></span>
        </div>

        <md-card ng-if="!$fams.loading && $fams.members.length" ng-repeat="member in $fams.members">
            <md-card-header>
                <md-card-header-text>
                    <span class="md-title" ng-bind="member.name"></span>
                    <span class="md-subhead" md-colors="{color: 'red'}" ng-if="member.deleted_at" ng-i18next="DELETED"></span>
                </md-card-header-text>
            </md-card-header>
            <md-card-content>
                <form name="memberForm" flex ng-submit="$fams.updateMember(member)" layout="column">
                    <div layout="row" layout-wrap>
                        <md-input-container flex-gt-sm="25" flex="100">
                            <label ng-i18next="custom_fields:NAME_PREFIX"></label>
                            <md-select ng-model="member.prefix" ng-disabled="member.deleted_at">
                                <md-option ng-value="'Mrs'">{{::'custom_fields:MRS' | i18next}}</md-option>
                                <md-option ng-value="'Mr'">{{::'custom_fields:MR' | i18next}}</md-option>
                            </md-select>
                        </md-input-container>

                        <md-input-container flex-gt-sm="25" flex="100">
                            <label ng-i18next="FIRST_NAME"></label>
                            <input ng-model="member.first_name" ng-disabled="member.deleted_at">
                        </md-input-container>

                        <md-input-container flex-gt-sm="25" flex="100">
                            <label ng-i18next="LAST_NAME"></label>
                            <input ng-model="member.last_name" ng-disabled="member.deleted_at">
                        </md-input-container>
                    </div>

                    <div layout="row" layout-wrap>
                        <md-input-container flex-gt-sm="25" flex="100">
                            <label ng-i18next="BIRTH_DATE"></label>
                            <eaw-date-picker no-label="true" ng-model="member.birth_date" ng-disabled="member.deleted_at" business-date="true"></eaw-date-picker>
                        </md-input-container>

                        <md-input-container flex-gt-sm="25" flex="100">
                            <label ng-i18next="RELATIONSHIP"></label>
                            <md-select ng-model="member.relationship" ng-disabled="member.deleted_at">
                                <md-option ng-value="'child'">{{'CHILD' | i18next}}</md-option>
                                <md-option ng-value="'spouse'">{{'SPOUSE' | i18next}}</md-option>
                            </md-select>
                        </md-input-container>

                        <md-input-container flex-gt-sm="25" flex="100">
                            <label ng-i18next="SEX"></label>
                            <md-select ng-model="member.sex" ng-disabled="member.deleted_at">
                                <md-option ng-value="'F'">{{'FEMALE' | i18next}}</md-option>
                                <md-option ng-value="'M'">{{'MALE' | i18next}}</md-option>
                            </md-select>
                        </md-input-container>

                        <md-input-container flex-gt-sm="25" flex="100">
                            <label ng-i18next="company:FAMILY_ALLOWANCE"></label>
                            <md-select ng-model="member.family_allowance" ng-disabled="member.deleted_at">
                                <md-option ng-value="true">{{'YES' | i18next}}</md-option>
                                <md-option ng-value="false">{{'NO' | i18next}}</md-option>
                            </md-select>
                        </md-input-container>
                    </div>

                    <div layout="row" ng-if="!member.deleted_at">
                        <eaw-submit-btn form="member.form" ng-if="$fams.canUpdate(member)" text="UPDATE"></eaw-submit-btn>
                        <md-button class="md-warn md-raised" ng-if="$fams.canDelete(member)" ng-disabled="member.updating" ng-click="$fams.deleteMember(member)" ng-i18next="DELETE"></md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </md-card-content>
</md-card>
`,
    controllerAs: '$fams',
    bindings: {
        user: '<',
        employee: '<',
        customer: '<',
    },
    controller: [ 'familyMemberFactory', 'ToastService', '$mdDialog', function(familyMemberFactory, ToastService, $mdDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.button = {
                show: CurrentOld.can(`customers.${ctrl.customer.id}.employees.${ctrl.employee.id}.family_members.create`),
                click() {
                    $mdDialog.show({
                        template: template2,
                        controllerAs: 'dialog',
                        controller: function() {
                        },
                    }).then((member) => familyMemberFactory.create(ctrl.employee, member).$promise).then(() => {
                        ToastService.created();
                        ctrl.getFamilyMembers();
                    }).catch();
                },
            };

            ctrl.getFamilyMembers();
        };

        ctrl.canUpdate = (member) => CurrentOld.can(`customers.${ctrl.customer.id}.employees.${ctrl.employee.id}.family_members.${member.id}.update`);

        ctrl.canDelete = (member) => CurrentOld.can(`customers.${ctrl.customer.id}.employees.${ctrl.employee.id}.family_members.${member.id}.delete`);

        ctrl.getFamilyMembers = () => {
            ctrl.loading = true;

            return familyMemberFactory.getAll(ctrl.employee, {
                per_page: 100,
                order_by: [ 'first_name', 'last_name', 'birth_date' ],
                direction: 'desc',
                include_deleted: ctrl.include_deleted,
            }).$promise.then((resp) => {
                ctrl.loading = false;
                ctrl.members = resp.data.map((m) => new Model(m));
            });
        };

        ctrl.updateMember = (familyMember) => {
            const updated = familyMember.getModified();
            updated.id = familyMember.id;

            familyMember.updating = true;
            familyMemberFactory.update(ctrl.employee, updated).$promise.then((member) => {
                familyMember.name = member.first_name + ' ' + member.last_name;
                ToastService.updated();
            }).finally(() => {
                familyMember.form.unsubmit();
            });
        };

        ctrl.deleteMember = (member) => {
            member.updating = true;
            familyMemberFactory.delete(ctrl.employee, member.id).$promise.then(() => {
                ToastService.deleted();
                ctrl.getFamilyMembers();
            }).catch(() => delete member.updating);
        };
    } ],
});
