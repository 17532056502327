<mat-form-field [class.standalone]="standalone">
    <mat-label>{{label || ('TIME' | translate) | async}}</mat-label>
    <input [formControl]="timeInput"
           type="text"
           matInput
           (blur)="onBlur()"
           autocomplete="off"
           (focus)="onFocus($event)"
           (keyup.enter)="onEnterKey($event)">
</mat-form-field>

<div></div>
