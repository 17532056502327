import { Inject, Injectable } from '@angular/core';
import { DateTimeConverter } from '../../shared/utils/date-time-converter';
import { DateTime } from 'luxon';
import { NeedProviderGranularity } from '../types/need-provider-granularity';
import { HttpClient } from '@angular/common/http';

interface GenerateBody {
    from?: DateTime;
    to?: DateTime;
    granularity?: NeedProviderGranularity;
    merge_shifts?: boolean;
    should_clear_period?: boolean;
    recurring_template_id?: number;
    template_id?: number;
    week_setup_id?: number;
    dates?: DateTime[];
}

interface PopulateBody {
    from?: DateTime;
    to?: DateTime;
    dates?: DateTime[];
    allow_over_contract?: boolean;
    allow_unqualified?: boolean;
    prefer_multiple_shifts?: boolean;
}

interface VlhBody {
    from: DateTime;
    to: DateTime;
    merge_shifts?: boolean;
    granularity: NeedProviderGranularity;
    adjust_aktiv_cash: number;
    adjust_gel: number;
    adjust_sat: number;
}

@Injectable({
    providedIn: 'root',
})
export class AutoschedulingService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    generate(customerId: number, body: GenerateBody) {
        return this.http.post(`customers/${customerId}/autoscheduling_jobs`, {
            type: 'generate',
            generate_method: 'need_hours',
            from: body.from || undefined,
            to: body.to || undefined,
            granularity: body.granularity,
            merge_shifts: body.merge_shifts ?? undefined,
            should_clear_period: body.should_clear_period ?? undefined,
            recurring_template_id: body.recurring_template_id || undefined,
            template_id: body.template_id || undefined,
            week_setup_id: body.week_setup_id || undefined,
            dates: body.dates?.map((date) => DateTimeConverter.convertDateTimeToBusinessDate(date)) || undefined,
        });
    }

    populate(customerId: number, body: PopulateBody) {
        return this.http.post(`customers/${customerId}/autoscheduling_jobs`, {
            type: 'populate',
            allow_over_contract: body.allow_over_contract ?? undefined,
            allow_unqualified: body.allow_unqualified ?? undefined,
            prefer_multiple_shifts: body.prefer_multiple_shifts ?? undefined,
            from: body.from || undefined,
            to: body.to || undefined,
            dates: body.dates?.map((date) => DateTimeConverter.convertDateTimeToBusinessDate(date)) || undefined,
        });
    }

    vlh(customerId: number, body: VlhBody) {
        return this.http.post(`customers/${customerId}/vlh_schedules`, {
            from: DateTimeConverter.convertDateTimeToBusinessDate(body.from),
            to: DateTimeConverter.convertDateTimeToBusinessDate(body.to),
            merge_shifts: body.merge_shifts ?? undefined,
            adjust_aktiv_cash: body.adjust_aktiv_cash,
            adjust_gel: body.adjust_gel,
            adjust_sat: body.adjust_sat,
            granularity: body.granularity,
        });
    }
}
