<div class="tw-flex tw-flex-col tw-gap-8 tw-p-16">
    <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{ 'QUALIFICATION_plural' | translate | async }}</mat-label>
        <mat-select [formControl]="qualificationsControl" multiple>
            @for (qualification of qualifications(); track qualification.id) {
                <mat-option [value]="qualification.id">{{ qualification.name }}</mat-option>
            }
        </mat-select>
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{ 'NAME' | translate | async }}</mat-label>
        <input matInput [formControl]="nameControl">
    </mat-form-field>
</div>

@if (loading()) {
    <eaw-info-loading class="tw-my-24" size="md"/>
} @else {
    <div class="tw-flex tw-flex-col">
        @for (employee of employeeAvailabilities(); track employee.employee.id) {
            <div class="employee-row" [class.selected]="employee.employee.id === selectedEmployeeId()" matRipple (click)="employeeClick(employee.employee)">
                <eaw-profile-picture size="32" [user]="{id: employee.employee.userId, name: employee.employee.name}"/>

                <mat-icon eawSize="32px">check</mat-icon>

                <div class="tw-flex tw-flex-col tw-gap-4 tw-flex-1">
                    <span>{{ showNicknames() ? (employee.employee.nickname || employee.employee.name) : employee.employee.name }}</span>

                    @for (overlap of employee.overlaps; track $index) {
                        <div class="overlap tw-flex tw-gap-4">
                            <small>{{ overlap.from | DateTime:'DATETIME_SHORT' }} - {{ overlap.to | DateTime:'TIME_SIMPLE' }}</small>

                            @if (overlap.location) {
                                <small>({{ overlap.location }})</small>
                            }
                        </div>
                    }
                </div>

                <div class="availability" [class.fetching]="fetchingAvailability()" [class.unavailable]="!employee.available">
                    @if (employee.hasAvailability) {
                        @if (employee.availability_generated_by) {
                            <span>{{ employee.availability_generated_by | uppercase | translate:'scheduling' | async }}</span>
                        } @else {
                            @if (employee.days.length) {
                                @for (day of employee.days; track day) {
                                    @if (day.wholeDay) {
                                        <span>{{ 'ALL_DAY' | translate:'scheduling' | async }}</span>
                                    } @else if (day.unavailable) {
                                        <span>{{ 'NOT_AVAILABLE' | translate:'scheduling' | async }}</span>
                                    } @else {
                                        <div class="tw-flex tw-gap-4">
                                            <span>{{ day.from | DateTime:'TIME_SIMPLE' }} - {{ day.to | DateTime:'TIME_SIMPLE' }}</span>
                                        </div>
                                    }
                                }
                            } @else {
                                @if (employee.available) {
                                    <span>{{ 'AVAILABLE' | translate:'scheduling' | async }}</span>
                                } @else {
                                    <span>{{ 'NOT_AVAILABLE' | translate:'scheduling' | async }}</span>
                                }
                            }
                        }
                    }
                </div>
            </div>
        }
    </div>
}
