import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit, signal, WritableSignal } from '@angular/core';
import { ExtendedWidgetInfo } from '../../../classes/extended-widget-info';
import { MatActionList, MatListItem, MatListItemIcon, MatListItemLine, MatListItemMeta, MatListItemTitle } from '@angular/material/list';
import { UIRouter } from '@uirouter/core';
import { MatIcon } from '@angular/material/icon';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { SortDirection } from '@angular/material/sort';
import { tap } from 'rxjs';
import { ShiftService } from '../../../../scheduling/http/shift.service';
import { CurrentService } from '../../../../shared/services/current.service';
import { InfoLoadingComponent } from '../../../../shared/components/info-loading/info-loading.component';
import { MatDivider } from '@angular/material/divider';
import { DateTime } from 'luxon';
import { Widget } from '../../../classes/widget';
import { Shift } from '../../../../scheduling/models/shift';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface FullShiftData {
    shift: Shift;
    inDays: number;
    fromTo: string;
}

@Component({
    selector: 'eaw-mini-next-shift-widget-extended-info',
    standalone: true,
    imports: [
        MatActionList,
        MatListItem,
        MatIcon,
        TranslatePipe,
        AsyncPipe,
        MatListItemIcon,
        InfoLoadingComponent,
        MatDivider,
        MatListItemLine,
        MatListItemMeta,
        MatListItemTitle,
    ],
    templateUrl: './mini-next-shift-widget-extended-info.component.html',
    styleUrl: './mini-next-shift-widget-extended-info.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniNextShiftWidgetExtendedInfoComponent extends ExtendedWidgetInfo implements OnInit {
    protected uiRouter = inject(UIRouter);
    private shiftService = inject(ShiftService);
    private current = inject(CurrentService);
    private destroyedRef = inject(DestroyRef);

    options = {
        from: DateTime.now(),
        order_by: 'from',
        direction: 'asc' as SortDirection,
        'with[]': [ 'schedule.customer' ],
    };

    widget = input.required<Widget>();

    shifts: WritableSignal<FullShiftData[]> = signal<FullShiftData[]>([]);

    constructor() {
        super();
        this.setLoading(true);
    }

    ngOnInit() {
        const employee = this.widget().info.employee?.id;
        if (!employee) {
            this.setLoading(false);
            return;
        }

        this.shiftService.getAllForEmployee(this.current.getCustomer().id, employee, this.options).pipe(
            takeUntilDestroyed(this.destroyedRef),
            tap(() => this.setLoading(false)),
        ).subscribe((resp) => {
            this.shifts.set(resp.data.map((shift) => ({
                shift,
                inDays: this.daysUntil(shift.from),
                fromTo: `${shift.from.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)} - ${shift.to.toLocaleString(DateTime.TIME_SIMPLE)}`,
            })));
        });
    }

    daysUntil(targetDate: DateTime): number {
        return Math.ceil(targetDate.diffNow('day').days);
    }

    navigateToShift(params: { id: number, customer_id: number | undefined }): void {
        this.uiRouter.stateService.go('eaw/app/scheduling/shifts/shift', {
            id: params.id,
            customer_id: params.customer_id,
        });
    }
}
