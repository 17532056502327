// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.checklists').component('eawChecklistTemplate', {
    template: `<md-tabs md-dynamic-height>
    <md-tab label="{{'checklists:TEMPLATE' | i18next}}">
        <md-card id="checklist-view-card">
            <md-card-header>
                <md-card-header-text>
                    <span class="md-title" ng-bind="$ctrl.checklist.title"></span>
                </md-card-header-text>
            </md-card-header>
            <md-card-content class="tw-p-0">
                <eaw-checklist-children-list parent="$ctrl.checklist" children="$ctrl.checklist.children"></eaw-checklist-children-list>
            </md-card-content>
        </md-card>
    </md-tab>

    <md-tab label="{{'AUTOSTART' | i18next}}" ng-if="$ctrl.canEditAutostart">
        <md-card>
            <md-card-header>
                <md-card-header-text>
                    <span class="md-title" bo-i18next="AUTOSTART"></span>
                </md-card-header-text>
            </md-card-header>
            <md-card-content>
                <auto-start-settings has-responsible="true" on-submit="$ctrl.submitAutoStart(autoStart)"></auto-start-settings>
            </md-card-content>
        </md-card>

        <md-card ng-if="$ctrl.autoStart.type">
            <md-card-header>
                <md-card-header-text>
                    <span class="md-title" bo-i18next="AUTOSTART_ROTATION"></span>
                </md-card-header-text>

                <md-button class="md-icon-button" ng-click="$ctrl.deleteAutoStart()" ng-if="$ctrl.canEditAutostart">
                    <md-icon ng-bind="'delete'">
                        <md-tooltip ng-i18next="DELETE" md-direction="left"></md-tooltip>
                    </md-icon>
                </md-button>
            </md-card-header>
            <md-card-content class="tw-p-0">
                <auto-start-preview customer-id="$ctrl.customer.id" auto-start="$ctrl.autoStart"></auto-start-preview>
            </md-card-content>
        </md-card>
    </md-tab>
</md-tabs>
`,
    bindings: {
        checklist: '<',
        autoStartService: '<',
        autoStart: '<',
    },
    controller: [ '$mdDialog', '$element', 'ChecklistsService', 'ChecklistItemFactory', 'ChecklistCategoryFactory', 'ToastService', function($mdDialog, $element, ChecklistsService, ChecklistItemFactory, ChecklistCategoryFactory, ToastService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.ChecklistsService = ChecklistsService;
            ctrl.customer = CurrentOld.customer;
            ctrl.openCategories = [];
            ctrl.canEditAutostart = CurrentOld.can(`customers.${ctrl.customer.id}.checklists.${ctrl.checklist.id}.update`);
        };

        ctrl.deleteAutoStart = () => {
            ctrl.autoStartService.delete().then(() => {
                ctrl.autoStart = null;
            });
        };

        ctrl.submitAutoStart = (autoStart) => {
            ctrl.autoStartService.set(autoStart).then((resp) => {
                ctrl.autoStart = resp;
                ToastService.updated();
            });
        };

        /**
         * @param {object} item         - item we want to remove
         * @param {array} [children]    - children array
         * @returns {*}
         */
        ctrl.removeItem = (item, children) => {
            // Assign child list
            children = children || ctrl.checklist.children;

            return children.map((c) => {
                // We have to at least one child, if empty there is nothing to remove
                if (c?.children?.length) {
                    return {
                        ...c,
                        children: ctrl.removeItem(item, c.children),
                    };
                }

                return c;
            }).filter((c) => c.id !== item.id);
        };

        /**
         * @param {object}  item         - item we want to remove
         * @param {array}   [children]    - children array
         * @param {integer} [parentId]    - id of parent of current list
         * @returns {*}
         */
        ctrl.addItem = (item, children, parentId) => {
            // Assign child list
            children = children || ctrl.checklist.children;
            parentId = parentId || ctrl.checklist.id;

            // If the parent id matches the parent id we sent in
            // Or if we don't have a parent id on the item and the current parent id matches the checklist id
            if (parentId === item.parent_id || (item.parent_id == null && parentId === ctrl.checklist.id)) {
                children.push(item);
            }

            // Map for recursion
            return children.map((c) => {
                // We can add event if the array is empty, it just has to be an array
                if (Array.isArray(c.children)) {
                    return {
                        ...c,
                        children: ctrl.addItem(item, c.children, c.id),
                    };
                }

                return c;
            });
        };

        ctrl.delete = (item, el) => {
            ctrl.beforeUpdate(el);

            if (ChecklistsService.isCategory(item)) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .theme('delete')
                        .title(t('checklists:DELETE_CATEGORY'))
                        .textContent(t('checklists:DELETE_CATEGORY_WARNING', {
                            category: item.title,
                        }))
                        .ok(t('DELETE'))
                        .cancel(t('CANCEL')),
                ).then(() => {
                    ChecklistCategoryFactory.delete(ctrl.customer.id, ctrl.checklist.id, item.id).$promise.then(() => {
                        ctrl.remove(item);
                    }).catch(() => {
                        ctrl.updateFail(el);
                    }).finally(ctrl.afterUpdate);
                });
            } else {
                ChecklistItemFactory.delete(ctrl.customer, ctrl.checklist, item.id).$promise.then(() => {
                    ctrl.remove(item);
                }).catch(() => {
                    ctrl.updateFail(el);
                }).finally(ctrl.afterUpdate);
            }
        };

        ctrl.update = (item, el) => new Promise((resolve, reject) => {
            ctrl.beforeUpdate(el);

            if (ChecklistsService.isCategory(item)) {
                ChecklistCategoryFactory.update(ctrl.customer, ctrl.checklist, item).$promise.then((data) => {
                    ctrl.replace(data);
                    resolve(undefined);
                }).catch(() => {
                    ctrl.updateFail(el);
                    reject();
                }).finally(ctrl.afterUpdate);
            } else {
                ChecklistItemFactory.update(ctrl.customer, ctrl.checklist, item).$promise.then((data) => {
                    ctrl.replace(data);
                    resolve(undefined);
                }).catch(() => {
                    ctrl.updateFail(el);
                    reject();
                }).finally(ctrl.afterUpdate);
            }
        });

        ctrl.add = (item) => {
            ctrl.afterUpdate();
            ctrl.checklist.children = ctrl.addItem(item);
        };

        ctrl.remove = (item) => {
            ctrl.afterUpdate();
            ctrl.checklist.children = ctrl.removeItem(item);
        };

        ctrl.replace = (item) => {
            ctrl.remove(item);
            ctrl.add(item);
        };

        ctrl.beforeUpdate = (childElement) => {
            $element[0].classList.add('checklist-template-updating');

            if (childElement instanceof HTMLElement) {
                childElement.classList.add('updating');
            }
        };

        ctrl.afterUpdate = () => {
            $element[0].classList.remove('checklist-template-updating');
        };

        ctrl.updateFail = (childElement) => {
            childElement.classList.remove('updating');
        };
    } ],
});
