// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
import { EawUrl } from '../../shared/angularjs/modules/resource/url.service';
import { PaginationOld } from '../../shared/utils/pagination-old';
import { CurrentOld } from '../../shared/angularjs/current.factory';
module('eaw.checklists').factory('ChecklistItemFactory', [ 'EawResource', 'FileService', function(EawResource, FileService) {
    const url = '/customers/:customer/checklists/:list/items/:item/:comments';
    /**
     * Add an item to a checklist
     * @param {object}  customer
     * @param {object}  list
     * @param {object}  item
     * @param {string}  item.title
     * @param {string}  item.type
     * @param {string}  [item.description]
     * @param {int}     [item.weight]
     * @param {int}     [item.parent_id]
     * @param {object}  [item.data]
     */
    this.create = (customer, list, item) => {
        const itemPick = pick(item, [ 'title', 'type', 'description', 'weight', 'parent_id', 'data', 'options' ]);
        return EawResource.create(url).save({
            customer: customer.id,
            list: list.id,
        }, itemPick);
    };
    /**
     * Update an existing item
     * @param {object}          customer
     * @param {object}          list
     * @param {object}          item
     * @param {int}             item.id
     * @param {string}          [item.title]
     * @param {string|object}   [item.type]
     * @param {string}          [item.description]
     * @param {int}             [item.weight]
     * @param {int}             [item.parent_id]
     * @param {object}          [item.data]
     */
    this.update = (customer, list, item) => {
        const itemPick = pick(item, [ 'title', 'type', 'description', 'weight', 'data', 'options', 'deviates', 'parent_id' ]);
        itemPick.description = itemPick.description === null || typeof itemPick.description === 'string' && itemPick.description.length ? itemPick.description : undefined;
        itemPick.parent_id = itemPick.parent_id === list.id ? null : itemPick.parent_id;
        itemPick.type = itemPick.type?.type || itemPick.type;
        if (typeof itemPick.data === 'string') {
            itemPick.data = itemPick?.data?.length ? itemPick.data : null;
        } else {
            itemPick.data = itemPick.data == null ? null : itemPick.data;
        }
        return EawResource.create(url).update({
            customer: customer.id,
            list: list.id,
            item: item.id,
        }, itemPick);
    };
    this.get = (listId, itemId, withRelationships) => EawResource.create(url).get({
        customer: CurrentOld.getCustomer().id,
        list: listId,
        item: itemId,
        'with[]': withRelationships,
    }).$promise;
    /**
     * Delete an existing item
     * @param {object}  customer
     * @param {object}  list
     * @param {int}     itemId
     */
    this.delete = (customer, list, itemId) => EawResource.create(url).delete({
        customer: customer.id,
        list: list.id,
        item: itemId,
    });
    this.comment = (listId, itemId, body) => EawResource.create(url).save({
        customer: CurrentOld.getCustomer().id,
        list: listId,
        item: itemId,
        comments: 'comments',
    }, { body }).$promise;
    this.getImage = (listId, itemId, attachmentId) => FileService.request(EawUrl.url + '/customers/' + CurrentOld.getCustomer().id + '/checklists/' + listId + '/items/' + itemId + '/attachments/' + attachmentId, 'get');
    this.uploadImage = (listId, itemId, file) => FileService.upload(`${EawUrl.url}/customers/${CurrentOld.getCustomer().id}/checklists/${listId}/items/${itemId}/attachments`, file, 'attachment', { 'with[]': [ 'user' ] });
    this.getAttachments = (listId, itemId) => EawResource.create(url).get({
        customer: CurrentOld.getCustomer().id,
        list: listId,
        item: itemId,
        comments: 'attachments',
        'with[]': [ 'user' ],
    }).$promise;
    this.getComments = (options) => EawResource.create(url).get({
        customer: CurrentOld.getCustomer().id,
        list: options.list_id,
        item: options.item_id,
        comments: 'comments',
        'with[]': options.with,
        ...PaginationOld.getParams(options),
    }).$promise;
    return this;
} ]);
