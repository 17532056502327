// @ts-nocheck
import { module } from 'angular';

/**
 * @ngdoc component
 * @name linkableEmployeeAutocomplete
 *
 * @param {ExternalEmployeeFactory} externalEmployeeFactory
 */
module('eaw.company').component('linkableEmployeeAutocomplete', {
    template: `<md-autocomplete
        flex="100"
        md-clear-button="true"
        md-selected-item="$autocomplete.employee"
        md-search-text="filter"
        placeholder="{{'SEARCH_EMPLOYEES' | i18next}}"
        md-selected-item-change="$autocomplete.onSelect()"
        md-require-match="true"
        md-input-name="linkedAutocomplete"
        ng-required="$autocomplete.required"
        md-items="item in $autocomplete.getExternalEmployees(filter)"
        md-item-text="item.name"
        md-menu-class="autocomplete-custom-template"
        md-delay="300"
        md-min-length="0">
    <md-item-template>
        <span class="autocomplete-item-title" md-highlight-text="filter" md-highlight-flags="gi">{{item.name}} ({{item.number}})</span>
        <span class="autocomplete-item-metadata" ng-bind="item.customer.name"></span>
    </md-item-template>
    <md-not-found>
        <span ng-i18next="NO_MATCH_FOUND"></span>
    </md-not-found>
    <ng-messages for="$autocomplete.ngForm.linkedAutocomplete.$error">
        <ng-message when="required">
            <span ng-i18next="errors:FORM_FIELD_REQUIRED"></span>
        </ng-message>
    </ng-messages>
</md-autocomplete>
`,
    bindings: {
        customer: '<',
    },
    require: {
        ngModel: 'ngModel',
        ngForm: '^?form',
    },
    controllerAs: '$autocomplete',
    controller: [ '$element', '$attrs', 'ExternalEmployeeFactory', function($element, $attrs, ExternalEmployeeFactory) {
        const ctrl = this;

        ctrl.$postLink = () => {
            ctrl.name = $element[0].getAttribute('name');

            $attrs.$observe('required', (value) => {
                ctrl.required = value;
            });
        };

        ctrl.getExternalEmployees = (filter) => ExternalEmployeeFactory.linkable(ctrl.customer.id, {
            filter,
            fields: [ 'name', 'first_name', 'last_name' ],
            per_page: 20,
            with: [ 'user', 'customer' ],
            order_by: 'name',
            direction: 'asc',
        }).$promise.then((resp) => resp.data);

        ctrl.onSelect = () => {
            ctrl.ngModel.setViewValue(ctrl.employee);
        };
    } ],
});
