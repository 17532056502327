// @ts-nocheck
import { module } from 'angular';

module('eaw.filesystem').controller('fsBsMenuCtrl', [ '$mdBottomSheet', function($mdBottomSheet) {
    const ctrl = this;

    ctrl.menuItemClick = (menuItem) => {
        $mdBottomSheet.hide();
        menuItem.click(ctrl.item);
    };
} ]);
