// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-virtual-repeat-container md-item-size="60" md-autshrink-min="10">
    <md-list >
        <md-list-item md-virtual-repeat="shift in leaveShifts.list" ng-show="leaveShifts.list.loaded" md-on-demand>
            <div class="md-list-item-content tw-mb-12" ng-show="shift">
                <div>
                    <md-icon class="s18 status-icon tw-pr-12 tw-pb-12" md-colors="{color: shift.icon.color}" md-svg-src="{{shift.icon.name}}"></md-icon>
                    <span ng-bind="shift.employee.name"></span>
                </div>

                <span ng-i18next="[i18next]({from: shift.fromFormatted, to: shift.toFormatted})FROM_TO"></span>

                <span class="md-secondary" ng-bind="shift.type"></span>
            </div>
            <md-divider></md-divider>
        </md-list-item>
        <md-progress-circular ng-if="leaveShifts.list.isLoading()" class="tw-mt-16 md-progress-center"></md-progress-circular>
    </md-list>
</md-virtual-repeat-container>
`;

module('eaw.scheduling').component('sidebarLeaveShifts', {
    template: template1,
    controllerAs: 'leaveShifts',
    bindings: {
        customer: '<',
        schedule: '<',
        days: '<',
    },
    require: {
        sidebar: '^scheduleSidebar',
    },
    controller: [ 'LeaveShiftFactory', 'EndlessList', '$scope', function sidebarLeaveShifts(LeaveShiftFactory, EndlessList, $scope) {
        const ctrl = this;

        ctrl.$onInit = () => {
            $scope.$on('sidebarchildren:dayChanged', ctrl.dayChange);
            ctrl.list = {};
            ctrl.dayChange();
        };

        ctrl.dayChange = () => {
            const from = ctrl.sidebar.day.moment.clone().startOf('d');
            const date = from.format('YY-MM-DD');

            if (!ctrl.list[date]) {
                ctrl.list[date] = new EndlessList(ctrl.getLeaveShifts, {
                    per_page: 10,
                    with: [ 'leave.approval', 'employee' ],
                    order_by: 'from',
                    direction: 'asc',
                    customer_id: ctrl.customer.id,
                    from,
                    to: ctrl.sidebar.day.moment.clone().endOf('d'),
                });
            }
            ctrl.list = ctrl.list[date];
            ctrl.list.loadPage(1);
        };

        ctrl.getLeaveShifts = (pagination) => LeaveShiftFactory.getAllForCustomer(pagination).$promise.then((resp) => {
            resp.data = resp.data.map((shift) => {
                if (shift.leave.approval) {
                    shift.icon = shift.leave.approval.approved ? {
                        name: 'check',
                        color: 'green',
                    } : {
                        name: 'close',
                        color: 'red',
                    };
                } else {
                    shift.icon = {
                        name: 'help-box',
                        color: 'grey',
                    };
                }

                shift.type = t(shift.leave_type.toUpperCase());

                shift.fromFormatted = shift.from.format('lll');
                shift.toFormatted = shift.to.format('LT');

                return shift;
            });

            return resp;
        });
    } ],
});
