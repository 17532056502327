<eaw-page-header class="tw-mb-16">
    <span title>{{'CUSTOM_FIELD_plural' | translate | async}}</span>
</eaw-page-header>

<form class="mat-elevation-z4 tw-w-full">
    <div *ngIf="loading" class="tw-flex tw-py-16 tw-w-full tw-items-center tw-justify-center">
        <mat-progress-spinner  mode="indeterminate"></mat-progress-spinner>
    </div>

    <div *ngIf="!loading && !rows.length" class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-16 tw-p-24">
        <mat-icon eawSize="50px">search</mat-icon>
        <span class="mat-headline-6">{{'NO_CUSTOM_FIELDS_FOUND' | translate:'company' | async}}</span>
    </div>

    <table class="table table-striped" *ngIf="!loading && rows.length">
        <thead>
        <tr>
            <th>{{'NAME' | translate | async}}</th>
            <th>{{'VALUE' | translate | async}}</th>
            <th>{{'FROM' | translate | async}}</th>
            <th>{{'TO' | translate | async}}</th>
            <th></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let row of rows">
            <td>
                <div>{{row.field.translationKey | translate:'custom_fields' | async}}</div>
                <small *ngIf="row.field.required" eawMaterialColor="red-500">{{'REQUIRED' | translate | async}}</small>
            </td>
            <td><eaw-custom-field-value-display [customField]="row.field"/></td>
            <td>{{row.from | DateTime:'DATE_MED'}}</td>
            <td>{{row.to | DateTime:'DATE_MED'}}</td>
            <td>
                <div class="tw-flex tw-gap-8">
                    <button class="table-button"
                            mat-icon-button
                            [disabled]="!!row.disabled"
                            (click)="manage('add', row)"
                            eawMaterialColor="grey-700"
                            colorProperty="backgroundColor"
                            [class.tw-invisible]="!row.creatable || !canUpdate">
                        <mat-icon>add</mat-icon>
                    </button>

                    <button class="table-button"
                            mat-icon-button
                            [disabled]="!!row.disabled"
                            (click)="manage('edit', row)"
                            [class.tw-invisible]="!row.editable || !canUpdate">
                        <mat-icon>edit</mat-icon>
                    </button>

                    <button class="table-button"
                            mat-icon-button
                            [disabled]="!!row.disabled"
                            (click)="history(row)"
                            [class.tw-invisible]="!row.field.hasInterval && !canUpdate">
                        <mat-icon>history</mat-icon>
                    </button>

                    <button class="table-button"
                            mat-icon-button
                            [disabled]="!!row.disabled"
                            (click)="delete(row)"
                            eawMaterialColor="red-500"
                            colorProperty="backgroundColor"
                            *ngIf="row.deletable && canUpdate">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</form>
