<eaw-dialog-header [showCloseButton]="false" class="tw-pb-0">
    <span title class="tw-flex tw-flex-row tw-items-center">
        <div>{{todo.title || 'No title'}}</div>
        <button mat-icon-button (click)="changeTitle()">
            <mat-icon>edit</mat-icon>
        </button>
    </span>

    <span subtitle class="tw-flex tw-flex-row tw-items-center">
        <span>{{'PUBLISH_DATE' | translate: 'todo' | async}}: {{todo.from || todo.createdAt | DateTime: 'DATETIME_SHORT'}}</span>
        <button mat-icon-button (click)="changeFrom()">
            <mat-icon>edit</mat-icon>
        </button>
    </span>

    <div right>
        <button mat-icon-button (click)="openComments()">
            <mat-icon>add_comment</mat-icon>
        </button>

        <button mat-icon-button (click)="addImage()">
            <mat-icon>add_photo_alternate</mat-icon>
        </button>

        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</eaw-dialog-header>

<mat-dialog-content class="tw-pt-0">
    <mat-list class="tw-pt-0">
        <mat-list-item>
            <mat-icon matListItemIcon>person</mat-icon>
            <form matListItemLine [formGroup]="formGroup">
                <eaw-customer-user-autocomplete [label]="'RESPONSIBLE' | translate: 'todo'"
                                                [customerId]="todo.customerId"
                                                controlName="responsible">
                </eaw-customer-user-autocomplete>
            </form>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item *ngIf="!todo.due">
            <mat-icon class="due" matListItemIcon>hourglass_disabled</mat-icon>
            <span matListItemLine>{{'NO_DUE_DATE' | translate:'todo' | async}}</span>

            <span matListItemMeta>
                <button mat-flat-button (click)="changeDue()">{{'EDIT' | translate | async}}</button>
            </span>
        </mat-list-item>

        <mat-list-item *ngIf="todo.due">
            <mat-icon *ngIf="!todo.isExpired" class="due rotate" matListItemIcon>hourglass_full</mat-icon>
            <mat-icon *ngIf="todo.isExpired" class="due" matListItemIcon>hourglass_empty</mat-icon>
            <span matListItemLine>{{todo.due | DateTime:'DATETIME_MED_WITH_WEEKDAY'}}</span>
            <span matListItemMeta>
                <button mat-flat-button (click)="changeDue()">{{'EDIT' | translate | async}}</button>
            </span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <mat-icon matListItemIcon>{{todo.status?.icon}}</mat-icon>
            <span matListItemLine>
                <mat-form-field class="standalone tw-w-full">
                    <mat-select [formControl]="statusControl" (selectionChange)="changeStatus()">
                        <mat-optgroup [label]="('DEFAULT_STATUS' | translate:'todo' | async) || 'Status'">
                            <mat-option *ngFor="let status of statuses.get('Default')" [value]="status">{{status.name}}</mat-option>
                        </mat-optgroup>

                        <mat-optgroup [label]="('IN_PROGRESS_TYPE' | translate:'todo' | async) || 'Status'">
                            <mat-option *ngFor="let status of statuses.get('InProgress')" [value]="status">{{status.name}}</mat-option>
                        </mat-optgroup>

                        <mat-optgroup [label]="('DONE_TYPE' | translate:'todo' | async) || 'Status'">
                            <mat-option *ngFor="let status of statuses.get('Done')" [value]="status">{{status.name}}</mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <mat-icon matListItemIcon>text_fields</mat-icon>

            <span matListItemLine>
                 <mat-form-field class="standalone tw-w-full">
                     <textarea [formControl]="descriptionControl" matInput rows="5"></textarea>
                 </mat-form-field>
            </span>
        </mat-list-item>
    </mat-list>

    <div id="attachments-section" *ngIf="todo.attachments?.length">
        <eaw-image-attachment [dialogRef]="dialogRef" *ngFor="let attachment of todo.attachments" [url]="'/customers/' + todo.customerId + '/todo_items/' + todo.id + '/attachments/' + attachment.id"></eaw-image-attachment>
    </div>

    <eaw-comment-list id="comments-section" *ngIf="todo.comments?.length" [comments]="todo.comments || []"></eaw-comment-list>
</mat-dialog-content>
