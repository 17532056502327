import { Component, Inject, Input, numberAttribute, OnInit, signal } from '@angular/core';
import { EmployeeService } from '../../../shared/http/employee.service';
import { Employee } from '../../../shared/models/employee';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime, Duration } from 'luxon';
import { Country } from '../../../shared/models/country';
import { CountryRegion } from '../../../shared/models/country-region';
import { UIRouter } from '@uirouter/core';
import { catchError, debounceTime, EMPTY, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';
import { TranslateService } from '../../../shared/services/translate.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { Role } from '../../../leader-roles/shared/types/role';
import { ObjectDifferenceExtractor } from '../../../shared/utils/object-difference-extractor';
import { HandleEmployeeTerminationService } from '../../dialogs/handle-employee-termination/handle-employee-termination.service';
import { CountryAutocompleteService } from '../../../shared/autocompletes/country-autocomplete.service';
import { CountryRegionAutocompleteService } from '../../../shared/autocompletes/country-region-autocomplete.service';
import { EawValidators } from '../../../shared/validators/eaw-validators';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { SettingService } from '../../../shared/http/setting.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { CustomerService } from '../../../shared/http/customer.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { UnitPipe } from '../../../shared/pipes/unit.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { RoleAutocompleteComponent } from '../../../leader-roles/shared/components/role-autocomplete/role-autocomplete.component';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { PermissionPipe } from '../../../shared/pipes/permission.pipe';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';
import { PermissionsInputValue } from '../../../permissions/services/element-permission.service';

interface InformationForm {
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    nickname: FormControl<string | null>;
    email: FormControl<string | null>;
    phone: FormControl<string | null>;
    address1: FormControl<string | null>;
    address2: FormControl<string | null>;
    postalCode: FormControl<string | null>;
    city: FormControl<string | null>;
    employeeNumber: FormControl<string>;
    extraSeniority: FormControl<number | null>;
    gender: FormControl<string>;
    birthDate: FormControl<DateTime | null>;
    employmentFrom: FormControl<DateTime | null>,
    employmentTo: FormControl<DateTime | null>,
    countryKey: FormControl<Country | string | null>,
    nationality: FormControl<Country | string | null>,
    region: FormControl<CountryRegion | null>;
    role: FormControl<Role | number | null>;
}

@Component({
    selector: 'eaw-employee-information-tab',
    templateUrl: './employee-information-tab.component.html',
    styleUrl: './employee-information-tab.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatProgressSpinnerModule,
        NgFor,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MaterialColorDirective,
        MatSelectModule,
        MatOptionModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        AutocompleteComponent,
        RoleAutocompleteComponent,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
        UnitPipe,
        PermissionPipe,
        TranslateSyncPipe,
        PermissionDirective,
    ],
})
export class EmployeeInformationTabComponent implements OnInit {
    @Input({ required: true }) employeeId!: number;
    @Input({ required: true }) customerId!: number;
    @Input({ transform: numberAttribute }) customerStackId?: number;

    private objectDiff = new ObjectDifferenceExtractor();

    warnings: Promise<string>[] = [];
    fetchedEmployee?: Employee;
    pinCodesEnabled = false;
    age?: number;
    extraSeniorityYears?: number;
    infoForm = new FormGroup<Partial<InformationForm>>({});
    regionTrigger: Observable<unknown> = EMPTY;

    updateEmployeePermission?: string;
    updatePinCodePermission?: string;

    signablePermissions = signal<PermissionsInputValue | undefined>(undefined);
    constructor(
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(UIRouter) private router: UIRouter,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(SettingService) public settingService: SettingService,
        @Inject(HandleEmployeeTerminationService) private dialog: HandleEmployeeTerminationService,
        @Inject(CountryAutocompleteService) protected countryAutocompleteService: CountryAutocompleteService,
        @Inject(CountryRegionAutocompleteService) protected countryRegionAutocompleteService: CountryRegionAutocompleteService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(CustomerService) private customerService: CustomerService,
    ) {
        this.infoForm.valueChanges.pipe(debounceTime(100)).subscribe(() => {
            const hasChanges = this.objectDiff.hasChanges(this.getFormValue(), this.getDiffMappings());

            if (hasChanges) {
                this.infoForm.markAsTouched();
                this.infoForm.markAsDirty();
            } else {
                this.infoForm.markAsUntouched();
                this.infoForm.markAsPristine();
            }

            // Touch all controls with errors so they get highlighted in the form
            for (const control of Object.values(this.infoForm.controls)) {
                if (Object.values(control.errors || {}).length) {
                    control.markAsTouched();
                }
            }
        });
    }

    ngOnInit(): void {
        this.signablePermissions.set([ [ `customers.[${ApiModel.Customer}].roles.*.get`, { models: [ { type: ApiModel.Customer, id: this.customerId } ] } ] ]);
        this.settingService.getValue([ 'customers', this.customerId ], 'pin_code.enabled', false).subscribe((setting) => {
            this.pinCodesEnabled = setting;
        });

        this.getEmployee();

        this.updateEmployeePermission = `customers.${this.customerId}.employees.${this.employeeId}.update`;
        this.updatePinCodePermission = `customers.${this.customerId}.employees.${this.employeeId}.pin_code.update`;
    }

    getStackId() {
        return of(this.customerStackId).pipe(
            switchMap((customerStackId) => {
                if (customerStackId) {
                    return of(customerStackId);
                }

                return this.customerService.get(this.customerId).pipe(map((customer) => customer.stackId));
            }),
        );
    }

    getEmployeeReadableFields() {
        return this.getStackId().pipe(
            switchMap((stackId) => {
                return this.permissionCheckService.getReadableFields(stackId, `customers.${this.customerId}.employees`, { type: ApiModel.Employee, id: this.employeeId });
            }),
            map((response) => {
                return response.attributes;
            }),
            catchError(() => {
                return of([] as string[]);
            }),
        );
    }

    getEmployee() {
        forkJoin([
            this.getEmployeeReadableFields(),
            this.employeeService.get(this.customerId, this.employeeId, { 'with[]': [ 'warnings', 'region', 'role' ] }),
        ]).subscribe(([ readableFields, employee ]) => {
            this.createForm(employee, readableFields);

            this.warnings = employee.warnings?.map((w) => this.translate.t(w.message, 'warnings', w.messageParameters)) || [];
            this.fetchedEmployee = employee;
            this.objectDiff.setOriginal({
                ...this.infoForm.value,
                region: this.infoForm.controls.region?.value?.id || null,
            });
        });
    }

    setBasicControl<T extends keyof InformationForm>(name: T, value: InformationForm[T], readable: string[], readableField: string) {
        if (!readable.includes(readableField)) {
            return;
        }
        this.infoForm.setControl(name, value);
    }

    setName(employee: Employee, readable: string[]) {
        if (!readable.includes('first_name') || !readable.includes('last_name')) {
            return;
        }
        this.infoForm.setControl('firstName', new FormControl(employee.firstName || '', this.fetchedEmployee?.userId ? null : Validators.required));
        this.infoForm.setControl('lastName', new FormControl(employee.lastName || '', this.fetchedEmployee?.userId ? null : Validators.required));
    }

    setNumber(employee: Employee, readable: string[]) {
        if (!readable.includes('number')) {
            return;
        }
        this.infoForm.setControl('employeeNumber', new FormControl(employee.number || '', {
            nonNullable: true,
            validators: Validators.required,
        }));
    }

    setExtraSeniority(employee: Employee, readable: string[]) {
        if (!readable.includes('extra_seniority')) {
            return;
        }

        this.infoForm.setControl('extraSeniority', new FormControl(null));

        // Update extra seniority on value change
        this.infoForm.controls.extraSeniority?.valueChanges.subscribe((val) => {
            this.extraSeniorityYears = val ? Duration.fromObject({ months: val }).as('years') : undefined;
        });

        this.infoForm.controls.extraSeniority?.setValue(employee.extraSeniority || null);
    }

    setFromTo(employee: Employee, readable: string[]) {
        if (!(readable.includes('from') && readable.includes('from'))) {
            return;
        }

        this.infoForm.setControl('dateRange', new FormGroup({
            from: new FormControl<DateTime | null>(employee.from || null),
            to: new FormControl<DateTime | null>(employee.to || null),
        }));
    }

    setGender(employee: Employee, readable: string[]) {
        if (!readable.includes('gender')) {
            return;
        }
        this.infoForm.setControl('gender', new FormControl(employee.gender, { nonNullable: true }));
    }

    setCountryAndRegion(employee: Employee, readable: string[]) {
        if (!readable.includes('country_key')) {
            return;
        }

        // Just initialize with no value
        const countryCtrl: FormControl<Country | string | null> = new FormControl<Country | string | null>(employee.countryKey || null);

        this.infoForm.setControl('countryKey', countryCtrl);

        // Region is related to country, so country is also required
        if (readable.includes('region_id')) {
            const regionCtrl: FormControl<CountryRegion | string | null> = new FormControl<CountryRegion | string | null>(employee.region || null);

            this.infoForm.setControl('region', regionCtrl);
            this.regionTrigger = countryCtrl.valueChanges.pipe(switchMap((country) => {
                const region = regionCtrl.value;

                if (region instanceof CountryRegion && (region.countryCode === country || country instanceof Country && region.countryCode === country.code)) {
                    return EMPTY;
                }

                return of(country);
            }));
        }
    }

    setEmail(employee: Employee, readable: string[]) {
        if (!readable.includes('email')) {
            return;
        }
        this.infoForm.setControl('email', new FormControl(employee.email || null, [ EawValidators.email() ]));
    }

    setBirthdate(employee: Employee, readable: string[]) {
        if (!readable.includes('birth_date')) {
            return;
        }

        // Just add the control here
        this.infoForm.setControl('birthDate', new FormControl(null));

        // Update age on value change
        this.infoForm.controls.birthDate?.valueChanges.subscribe((val) => {
            this.age = val ? Math.floor(DateTime.now().diff(val).as('years')) : undefined;
        });

        // Trigger value change
        this.infoForm.controls.birthDate?.setValue(employee.birthDate?.dateTime || null);
    }

    createForm(employee: Employee, readableFields: string[]) {
        this.setBasicControl('role', new FormControl(employee.roleId || null), readableFields, 'role_id');
        this.setBasicControl('city', new FormControl(employee.city || ''), readableFields, 'city');
        this.setBasicControl('nationality', new FormControl(employee.nationality || ''), readableFields, 'nationality');
        this.setBasicControl('phone', new FormControl(employee.phone || ''), readableFields, 'phone');
        this.setBasicControl('postalCode', new FormControl(employee.postalCode || ''), readableFields, 'postal_code');
        this.setBasicControl('address1', new FormControl(employee.address1 || ''), readableFields, 'address1');
        this.setBasicControl('address2', new FormControl(employee.address2 || ''), readableFields, 'address2');
        this.setBasicControl('employmentFrom', new FormControl(employee.from ?? null), readableFields, 'from');
        this.setBasicControl('employmentTo', new FormControl(employee.to ?? null), readableFields, 'to');
        this.setBasicControl('nickname', new FormControl(employee.nickname ?? null), readableFields, 'nickname');

        this.setName(employee, readableFields);
        this.setExtraSeniority(employee, readableFields);
        this.setNumber(employee, readableFields);
        this.setFromTo(employee, readableFields);
        this.setGender(employee, readableFields);
        this.setCountryAndRegion(employee, readableFields);
        this.setEmail(employee, readableFields);
        this.setBirthdate(employee, readableFields);
    }

    getUpdatedNationality(): string | null {
        return this.infoForm.value.nationality instanceof Country ? this.infoForm.value.nationality.code : (this.infoForm.value.nationality || null);
    }

    getUpdatedCountryKey(): string | null {
        return this.infoForm.value.countryKey instanceof Country ? this.infoForm.value.countryKey.code : (this.infoForm.value.countryKey || null);
    }

    getUpdatedRegionId(): number | null {
        const countryKey = this.getUpdatedCountryKey();
        const region = this.infoForm.value.region;

        return region?.countryCode?.toLowerCase() === countryKey?.toLowerCase() ? (region?.id || null) : null;
    }

    getUpdatedRoleId(): number | null {
        const role = this.infoForm.controls.role?.value;
        if (role instanceof Role) {
            return role.id;
        }
        if (Number.isInteger(role)) {
            return role as number;
        }

        return null;
    }

    getFormValue() {
        const value = this.infoForm.value;

        return {
            first_name: value.firstName?.length ? value.firstName : null,
            last_name: value.lastName?.length ? value.lastName : null,
            nickname: value.nickname?.length ? value.nickname : null,
            email: value.email,
            phone: value.phone,
            address1: value.address1,
            address2: value.address2,
            postal_code: value.postalCode,
            city: value.city,
            number: value.employeeNumber,
            birth_date: value.birthDate,
            extra_seniority: value.extraSeniority,
            gender: value.gender,
            from: value.employmentFrom,
            to: value.employmentTo,
            nationality: this.getUpdatedNationality(),
            country_key: this.getUpdatedCountryKey(),
            region_id: this.getUpdatedRegionId(),
            role_id: this.getUpdatedRoleId(),
        };
    }

    getDiffMappings() {
        return {
            first_name: 'firstName',
            last_name: 'lastName',
            postal_code: 'postalCode',
            birth_date: 'birthDate',
            extra_seniority: 'extraSeniority',
            country_key: 'countryKey',
            region_id: 'region',
            role_id: 'role',
            number: 'employeeNumber',
            from: 'employmentFrom',
            to: 'employmentTo',
        };
    }

    submit() {
        const fields = this.objectDiff.getChanged(this.getFormValue(), this.getDiffMappings());

        if (fields.to) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.dialog.open(this.fetchedEmployee!, fields.to.endOf('day')).beforeClosed().subscribe((handle) => {
                if (handle) {
                    this.updateEmployee({
                        ...fields,
                        resolve_termination: handle,
                    });
                }
            });
        } else {
            this.updateEmployee(fields);
        }
    }

    private updateEmployee(fields: Record<string, unknown>) {
        this.employeeService.update(this.customerId, this.employeeId, fields)
            .pipe(
                tap(() => {
                    this.infoForm.disable();
                }),
                catchError(() => {
                    this.infoForm.enable();
                    return EMPTY;
                }),
            ).subscribe(() => {
                void this.snackBarService.t('EMPLOYEE_UPDATED', 'company');
                void this.router.stateService.reload();
            });
    }

    resetPinCode() {
        this.confirmDialogService.open({
            confirmText: this.translate.t('RESET_PIN_CODE', 'company'),
            text: this.translate.t('RESET_PIN_CODE_TEXT', 'company'),
            title: this.translate.t('RESET_PIN_CODE', 'company'),
        }).afterClosed().subscribe((result) => {
            if (result?.ok) {
                this.employeeService.setPinCode(this.customerId, this.employeeId).subscribe(() => {
                    this.snackBarService.t('PIN_CODE_WAS_RESET', 'company');
                });
            }
        });
    }
}
