import { Component, OnInit, signal } from '@angular/core';
import { DataTablePortalComponent } from '../../types/data-table-portal-component';
import { Approval } from '../../../shared/models/approval';
import { DataTableApprovalColumn } from '../../types/data-table-approval-column';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';

@Component({
    selector: 'eaw-approval-portal',
    templateUrl: './approval-portal.component.html',
    styleUrl: './approval-portal.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatIconModule,
        DateTimePipe,
        MatTooltip,
        TranslatePipe,
        AsyncPipe,
    ],
})
export class ApprovalPortalComponent<Item> extends DataTablePortalComponent<Item, void, DataTableApprovalColumn<Item>> implements OnInit {
    approval = signal<Approval|undefined|null>(undefined);

    constructor() {
        super();
    }

    async ngOnInit() {
        this.approval.set(await this.cell.column.approval(this.cell));
    }
}
