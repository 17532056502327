<eaw-dialog-header>
    <span title>{{(data.property ? 'EDIT_PROPERTY' : 'ADD_PROPERTY') | translate:'admin' | async}}</span>
</eaw-dialog-header>

@if (loading()) {
    <mat-dialog-content class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </mat-dialog-content>
} @else {
    <mat-dialog-content>
        <form class="tw-flex tw-flex-col tw-gap-16" [formGroup]="form">
            <mat-form-field>
                <mat-label>{{ 'KEY' | translate: 'admin' | async }}</mat-label>
                <input matInput formControlName="key">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'VALUE' | translate | async }}</mat-label>
                <textarea id="value-area" matInput formControlName="value" cdkTextareaAutosize></textarea>
            </mat-form-field>
        </form>
    </mat-dialog-content>
}

<mat-dialog-actions>
    @if (!data.property) {
        <button mat-button (click)="openPredefinedDialog()">
            <mat-icon>auto_fix_high</mat-icon>
            {{'PREDEFINED_SETTING_plural' | translate:'admin' | async}}
        </button>
    }

    <button mat-button [disabled]="!isValidJSON()" (click)="formatJSON()">
        @if (isValidJSON()) {
            <span>{{'FORMAT_JSON' | translate:'admin' | async}}</span>
        } @else {
            <span>{{'INVALID_JSON' | translate:'admin' | async}}</span>
        }
    </button>

    <span class="tw-flex-1"></span>

    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>

    @if (data.property) {
        <eaw-action-button [loading]="form.disabled" [disabled]="form.invalid || form.disabled" (click)="update()">{{'UPDATE' | translate | async}}</eaw-action-button>
    } @else {
        <eaw-action-button [loading]="form.disabled" [disabled]="form.invalid || form.disabled" (click)="add()">{{'ADD' | translate | async}}</eaw-action-button>
    }
</mat-dialog-actions>
