import { Component, inject, Inject, OnInit } from '@angular/core';
import { DialogComponent } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CreateOfftimeRequestDialogData } from './create-offtime-request-dialog.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { OffTimeService } from '../../http/off-time.service';
import { catchError, EMPTY, of, switchMap, tap } from 'rxjs';
import { OffTime } from '../../models/off-time';
import { VacationDaysService } from '../../http/vacation-days.service';
import { CurrentService } from '../../../shared/services/current.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { DateTimeInputComponent } from '../../../shared/components/date-time/date-time-input/date-time-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateTimeRangeInputComponent } from '../../../shared/components/date-time/date-time-range-input/date-time-range-input.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { HttpContext } from '@angular/common/http';
import { IGNORE_ERROR } from '../../../shared/http/http-contexts';

@Component({
    selector: 'eaw-create-offtime-request-dialog',
    templateUrl: './create-offtime-request-dialog.component.html',
    styleUrl: './create-offtime-request-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        ReactiveFormsModule,
        DateTimeRangeInputComponent,
        MatFormFieldModule,
        DateTimeInputComponent,
        MatInputModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class CreateOfftimeRequestDialogComponent extends DialogComponent implements OnInit {
    protected readonly offTimeService = inject(OffTimeService);
    protected readonly vacationDaysService = inject(VacationDaysService);
    protected readonly current = inject(CurrentService);
    protected readonly permissionCheckService = inject(PermissionCheckService);
    protected readonly confirmDialog = inject(ConfirmDialogService);
    private readonly translate = inject(TranslateService);

    readonly defaultToTime = DateTime.now().endOf('day');

    loadingDays = false;
    canGetStats = true;
    year = DateTime.now().year;
    vacationDays = 0;
    receivedVacationDays = 0;
    form = new FormGroup({
        from: new FormControl<DateTime | null>(null),
        to: new FormControl<DateTime | null>(null),
        comment: new FormControl<string>(''),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateOfftimeRequestDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<CreateOfftimeRequestDialogComponent, OffTime | undefined>,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.form.controls.from.setValue(DateTime.now().startOf('day'));
        this.form.controls.to.setValue(DateTime.now().endOf('day'));
        this.loadingDays = true;

        this.permissionCheckService.isAllowed(`customers.${this.data.customerId}.employees.${this.data.employeeId}.vacation_days.*.get`).pipe(
            catchError(() => of(false)),
            switchMap((canGetStats) => {
                if (!canGetStats) {
                    this.loadingDays = false;
                    this.canGetStats = false;
                    return EMPTY;
                }

                return this.vacationDaysService.getStats(this.data.customerId, this.data.employeeId, this.year).pipe(
                    tap((result) => {
                        this.vacationDays = result.days;
                        this.receivedVacationDays = result.received;
                        this.loadingDays = false;
                    }),
                );
            }),
        ).subscribe();
    }

    submit() {
        this.offTimeService.create(
            this.data.customerId,
            this.data.employeeId,
            {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                from: this.form.value.from!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                to: this.form.value.to!,
                comment: this.form.value.comment || undefined,
                schedule_published_warning_accepted: false,
            },
            new HttpContext().set(IGNORE_ERROR, (error) => error.error?.type === 'schedule_already_published'),
        ).pipe(
            catchError((error) => {
                if (error.status !== 422 || error.error?.type !== 'schedule_already_published') {
                    throw error;
                }

                return this.confirmDialog.open({
                    title: this.translate.t('WARNING'),
                    text: Promise.resolve(error.error.error),
                    confirmText: this.translate.t('CONTINUE'),
                    confirmButtonColor: 'primary',
                }).beforeClosed().pipe(
                    switchMap((r) => {
                        if (r?.ok) {
                            return this.offTimeService.create(this.data.customerId, this.data.employeeId, {
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                from: this.form.value.from!,
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                to: this.form.value.to!,
                                comment: this.form.value.comment || undefined,
                            });
                        }
                        throw error;
                    }),
                );
            }),
            catchError(() => {
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe((result) => {
            this.dialogRef.close(result);
        });
        this.form.disable();
    }
}
