import { DateTime, Info } from 'luxon';
import hasDST = Info.hasDST;

export function overlapsDst(from: DateTime, to: DateTime): boolean {
    return (from.isInDST && !to.isInDST) || (!from.isInDST && to.isInDST);
}

export function getNextDstChange(from: DateTime): DateTime | null {
    if (!hasDST(from.zone)) {
        return null;
    }

    const originalDst = from.isInDST;
    let date = from.startOf('day');

    // Go forward until we pass DST
    while (date.isInDST === originalDst) {
        date = date.plus({ month: 1 });
    }

    // Go backwards until we pass DST
    while (date.isInDST !== originalDst) {
        date = date.minus({ day: 1 });
    }

    // Go forward until we pass DST
    while (date.isInDST === originalDst) {
        date = date.plus({ hour: 1 });
    }

    return date;
}
