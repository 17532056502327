import { ButtonsPortalComponent } from '../portals/buttons-portal/buttons-portal.component';
import { DataTableColumn } from './data-table-column';
import { DataTableHeader } from './data-table-header';
import { DataTableCell } from '../interfaces/data-table-cell';
import { NamespaceFile } from '../../shared/enums/namespace';
import { EawMaterialColorHue } from '../../shared/services/material-color.service';
import { Observable } from 'rxjs';
import { uniqueId } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { PermissionsInputValue } from '../../permissions/services/element-permission.service';

export type DataTableButtonShowFn<Item> = (item: Item) => Observable<boolean>;

export interface DataTableButton<Item> {
    id?: number;
    icon: string | ((cell: DataTableButtonCell<Item>) => string)
    permissions?: (cell: DataTableButtonCell<Item>) => PermissionsInputValue;
    ariaLabel?: string;
        click: (cell: DataTableButtonCell<Item>, event: MouseEvent) => any,
    /**
     * Function returning an observable that emits a boolean, if it emits true, the button will be hidden (same as 'show' but inverted)
     * @deprecated
     * @see show
     */
    hide?: DataTableButtonShowFn<Item>,
    /** Default behaviour is to disable the button on click, if this property is set to true, the button will not be disabled automatically */
    nonBlocking?: boolean,
    /**
     * Function returning an observable that emits a boolean, if it emits false, the button will be hidden (same as 'hide' but inverted)
     */
    show: DataTableButtonShowFn<Item>,
    tooltip: { key: string, ns?: NamespaceFile },
    type?: 'warn' | 'alert' | 'ok',
    color?: EawMaterialColorHue,
}

interface IDataTableButtonColumn<Item> {
    header?: DataTableHeader;
    buttons: DataTableButton<Item>[];
}

export class DataTableButtonColumn<Item> extends DataTableColumn<DataTableButtonColumn<Item>, Item, void> {
    override loading?: never;
    component = ButtonsPortalComponent<Item>;
    buttons: DataTableButton<Item>[];

    constructor(column: IDataTableButtonColumn<Item>) {
        super({
            header: column.header || new DataTableHeader({ text: '' }),
            exportable: false,
        });

        this.buttons = column.buttons.map((button) => {
            if (!button.id) {
                button.id = uniqueId();
            }

            return button;
        });
    }
}

export type DataTableButtonCell<Item> = DataTableCell<DataTableButtonColumn<Item>, Item>;
