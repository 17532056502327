import { PeopleKpiLevelResponse } from '../people-kpi.service';

export class PeopleKpiLevel {
    identifier: string;
    name: string;

    constructor(data: PeopleKpiLevelResponse) {
        this.identifier = data.identifier;
        this.name = data.name;
    }
}
