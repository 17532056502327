import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '../../../../shared/services/translate.service';
import { DialogComponent, DialogData, DialogSize } from '../../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Products } from '../../../../shared/enums/products';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { catchError, EMPTY, forkJoin, Observable, of, startWith, Subscription, take } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OffTimeService } from '../../../http/off-time.service';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import type { OffTime } from '../../../models/off-time';
import { DateTime } from 'luxon';
import { ShiftService } from '../../../../scheduling/http/shift.service';
import { CustomerProductService } from '../../../../shared/http/customer-product.service';
import { Shift } from '../../../../scheduling/models/shift';
import { ArrayPaginatedResponse } from '../../../../shared/interfaces/paginated-response';
import { ActionButtonComponent } from '../../../../shared/components/action-button/action-button.component';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { SettingService } from '../../../../shared/http/setting.service';
import { TranslateSyncPipe } from '../../../../shared/pipes/translate-sync.pipe';

export interface ApproveOfftimeDialogData extends DialogData {
    customerId: number;
    employeeId: number;
    offTimeId?: number;
    mode: 'create' | 'update';
    from?: DateTime | null;
    to?: DateTime | null;
}

export type ApproveOfftimeDialogReturn = OffTime;

@Component({
    selector: 'eaw-approve-offtime-dialog',
    templateUrl: './approve-offtime-dialog.component.html',
    styleUrl: './approve-offtime-dialog.component.scss' ,
    standalone: true,
    imports: [
        DialogHeaderComponent,
        AsyncPipe,
        MatDialogModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        NgForOf,
        NgIf,
        MatButtonModule,
        MatProgressSpinnerModule,
        ActionButtonComponent,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class ApproveOfftimeDialogComponent extends DialogComponent implements OnInit, OnDestroy {
    private readonly settingService = inject(SettingService);

    form = new FormGroup({
        vacation: new FormControl<'auto' | 'manual' | 'no'>('auto', { nonNullable: true }),
        action: new FormControl<'keep' | 'release' | 'delete'>('keep', { nonNullable: true }),
        days: new FormControl<number | null>(null, Validators.required),
        comment: new FormControl<string | null>(null, Validators.maxLength(255)),
    });

    actions: { text: Promise<string>; value: 'keep' | 'release' | 'delete' }[] = [
        {
            text: this.translate.t('APPROVE_ACTION_KEEP', 'absences'),
            value: 'keep',
        },
        {
            text: this.translate.t('APPROVE_ACTION_RELEASE', 'absences'),
            value: 'release',
        },
        {
            text: this.translate.t('APPROVE_ACTION_DELETE', 'absences'),
            value: 'delete',
        },
    ];

    action: 'keep' | 'release' | 'delete' = 'keep';
    maxDays: number | null = null;
    hasSwitzerland = false;
    hasScheduling = false;
    loading = true;
    shifts = 0;
    displayVacationQuestion = false;

    private subscription?: Subscription;

    constructor(
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) override data: ApproveOfftimeDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ApproveOfftimeDialogComponent, ApproveOfftimeDialogReturn>,
        @Inject(OffTimeService) private offTimeService: OffTimeService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(ShiftService) private shiftService: ShiftService,
        @Inject(CustomerProductService) private customerProductService: CustomerProductService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        this.form.controls.vacation.valueChanges.pipe(
            startWith(this.form.controls.vacation.value),
        ).subscribe((vacation) => {
            if (vacation === 'manual') {
                this.form.controls.days.setValidators(Validators.required);
            } else {
                this.form.controls.days.removeValidators(Validators.required);
            }

            this.form.controls.days.setValue(null);
            this.form.controls.days.updateValueAndValidity();
        });
    }

    ngOnInit(): void {
        const productsObservable = this.customerProductService.hasProductsDetailed(this.data.customerId, [ Products.Switzerland, Products.Scheduling ]);
        const offTimeObservable = this.data.offTimeId ? this.offTimeService.get(this.data.customerId, this.data.employeeId, this.data.offTimeId).pipe(take(1)) : of(null);
        let shiftsObservable: Observable<ArrayPaginatedResponse<Shift> | null>;

        if (this.data.from && this.data.to) {
            shiftsObservable = this.shiftService.getAllForEmployee(this.data.customerId, this.data.employeeId, {
                ignoreErrors: [ 404 ],
                from: this.data.from,
                to: this.data.to,
                per_page: 1,
            }).pipe(
                take(1),
                catchError(() => of(null)),
            );
        } else {
            shiftsObservable = of(null);
        }

        forkJoin([ productsObservable, offTimeObservable, shiftsObservable ]).subscribe(([ products, offTime, shifts ]) => {
            this.hasSwitzerland = !!products.products[Products.Switzerland];
            this.hasScheduling = !!products.products[Products.Scheduling];
            this.maxDays = offTime ? Math.ceil(offTime.to.diff(offTime.from).as('days')) : null;
            this.shifts = shifts?.total || 0;
            this.loading = false;

            if (this.hasSwitzerland) {
                this.settingService.getValue([ 'customers', this.data.customerId ], 'switzerland.enable_vacation_day_deduction', false).subscribe((value) => {
                    this.displayVacationQuestion = value;
                });
            }
        });
    }

    get isVacation() {
        return this.form.value.vacation !== 'no';
    }

    getDays() {
        if (!this.isVacation) {
            return undefined;
        }
        if (this.form.value.days == null) {
            return undefined;
        }

        return this.form.value.days > 0 ? this.form.value.days * -1 : 0;
    }

    submit() {
        const options = {
            vacation: this.isVacation,
            days: this.getDays(),
            comment: this.form.value.comment || undefined,
            action: this.form.value.action || 'keep',
            approved: true,
        };

        this.form.disable();
        let request: Observable<OffTime> = EMPTY;

        if (this.data.mode === 'create' && this.data.from && this.data.to) {
            request = this.offTimeService.create(this.data.customerId, this.data.employeeId, {
                ...options,
                from: this.data.from,
                to: this.data.to,
            });
        }

        if (this.data.mode === 'update' && this.data.offTimeId) {
            request = this.offTimeService.update(this.data.customerId, this.data.employeeId, this.data.offTimeId, options);
        }

        this.subscription = request.subscribe((offTime) => {
            void this.snackBarService.t('REQUEST_APPROVED', 'vacation');
            this.dialogRef.close(offTime);
        });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
