import { t } from 'i18next';
import { orderBy } from 'lodash-es';
import { Fullscreen } from '../../../../../../shared/angularjs/modules/misc/services/fullscreen.service';
import { module } from 'angular';
import { smallDevice } from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CreateNewShiftDialogComponent, CreateNewShiftDialogData } from '../../../../../dialogs/create-new-shift-dialog/create-new-shift-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserPropertyService } from '../../../../../../shared/http/user-property.service';
import { Moment } from 'moment-timezone';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import { Property } from '../../../../../../shared/models/property';
import { Shift } from '../../../../../models/shift';
import { PermissionCheckService } from '../../../../../../shared/services/permission-check.service';

module('eaw.scheduling').component('scheduleToolbar', {
    template: `<md-toolbar class="md-menu-toolbar">
    <div class="md-toolbar-tools">
        <md-menu-bar>
            <md-menu>
                <button ng-click="$mdMenu.open()">{{'scheduling:VIEW' | i18next}}</button>
                <md-menu-content>
                    <md-menu-item class="md-indent" ng-if="!$toolbar.readOnly && !$toolbar.schedule.original">
                        <md-menu>
                            <md-button ng-click="$mdMenu.open()">{{'scheduling:MODE' | i18next}}</md-button>
                            <md-menu-content width="3">
                                <md-menu-item ng-repeat="mode in ::$toolbar.modes track by mode.value"
                                              type="radio"
                                              ng-model="$toolbar.schedule.settings.mode"
                                              ng-change="$toolbar.updateSetting('mode')"
                                              value="{{mode.value}}">{{mode.text}}
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </md-menu-item>
                    <md-menu-item class="md-indent">
                        <md-menu>
                            <md-button ng-click="$mdMenu.open()">{{'scheduling:GROUPING' | i18next}}</md-button>
                            <md-menu-content width="3">
                                <md-menu-item ng-repeat="grouping in ::$toolbar.groupings track by grouping.value"
                                              type="radio"
                                              ng-model="$toolbar.schedule.settings.shiftGrouping"
                                              ng-change="$toolbar.updateSetting('shiftGrouping')"
                                              value="{{grouping.value}}">{{grouping.text}}
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </md-menu-item>
                    <md-menu-item class="md-indent" ng-if="!$toolbar.readOnly && $toolbar.permissions.sort && !$toolbar.smallDevice">
                        <md-menu>
                            <md-button ng-click="$mdMenu.open()">{{'scheduling:SORTING' | i18next}}</md-button>
                            <md-menu-content width="3">
                                <md-menu-item ng-repeat="sort in ::$toolbar.sorting track by sort.value"
                                              type="radio"
                                              ng-change="$toolbar.updateSetting('sorting')"
                                              ng-model="$toolbar.schedule.settings.sorting"
                                              value="{{sort.value}}">{{sort.text}}
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </md-menu-item>
                    <md-menu-item class="md-indent">
                        <md-menu>
                            <md-button ng-click="$mdMenu.open()">{{'INTERVAL' | i18next}}</md-button>
                            <md-menu-content width="3">
                                <md-menu-item ng-repeat="interval in ::$toolbar.intervals track by interval.value"
                                              type="radio"
                                              ng-change="$toolbar.updateSetting('interval')"
                                              ng-model="$toolbar.schedule.settings.interval"
                                              value="{{interval.value}}">{{interval.text}}
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </md-menu-item>
                    <md-menu-divider></md-menu-divider>
                    <md-menu-item type="checkbox"
                                  ng-if="$toolbar.canSeeUnits && !$toolbar.schedule.original"
                                  ng-model="$toolbar.schedule.settings.filterUnits"
                                  ng-change="$toolbar.updateSetting('filterUnits')">{{'scheduling:UNIT_plural' | i18next}}
                    </md-menu-item>
                    <md-menu-item type="checkbox"
                                  md-prevent-menu-close
                                  ng-model="$toolbar.schedule.settings.verticalLines"
                                  ng-change="$toolbar.updateSetting('verticalLines')">{{'scheduling:VERTICAL_LINES' | i18next}}
                    </md-menu-item>
                    <md-menu-item type="checkbox"
                                  md-prevent-menu-close
                                  ng-model="$toolbar.schedule.settings.expandedShifts"
                                  ng-change="$toolbar.updateSetting('expandedShifts')">{{'scheduling:EXPANDED_SHIFTS' | i18next}}
                    </md-menu-item>
                    <md-menu-item type="checkbox"
                                  md-prevent-menu-close
                                  ng-model="$toolbar.schedule.settings.onlyUnassigned"
                                  ng-change="$toolbar.updateSetting('onlyUnassigned')">{{'scheduling:SHOW_ONLY_UNASSIGNED' | i18next}}
                    </md-menu-item>
                    <md-menu-item type="checkbox"
                                  md-prevent-menu-close
                                  ng-model="$toolbar.schedule.settings.showNicknames"
                                  ng-change="$toolbar.updateSetting('showNicknames')">{{'scheduling:SHOW_NICKNAMES' | i18next}}
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
            <md-menu ng-if="$toolbar.canSeeStats && !$toolbar.schedule.original">
                <button ng-click="$mdMenu.open()">{{'STATISTIC_plural' | i18next}}</button>
                <md-menu-content>
                    <md-menu-item>
                        <md-button ng-show="$toolbar.schedule.settings.topStatsEnabled" ng-click="$toolbar.toggleTopStats()">
                            {{'HIDE' | i18next}}
                        </md-button>
                        <md-button ng-show="!$toolbar.schedule.settings.topStatsEnabled" ng-click="$toolbar.toggleTopStats()">
                            {{'SHOW' | i18next}}
                        </md-button>
                    </md-menu-item>
                    <md-menu-item ng-if="$toolbar.scheduleView.mlBucket.variables.length">
                        <md-menu>
                            <md-button ng-click="$mdMenu.open()">
                                {{'scheduling:PROJECTION_VARIABLE_plural' | i18next}}
                            </md-button>
                            <md-menu-content>
                                <md-menu-item type="checkbox"
                                              md-prevent-menu-close
                                              ng-repeat="variable in $toolbar.scheduleView.mlBucket.variables"
                                              ng-model="variable.showInStats"
                                              ng-change="$toolbar.bucketVariableChange()"
                                              ng-value="variable.name">{{variable.name}}
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </md-menu-item>
                    <md-menu-divider></md-menu-divider>
                    <md-menu-item>
                        <md-button ng-click="$toolbar.refreshTopStats()">
                            {{'REFRESH' | i18next}}
                        </md-button>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        </md-menu-bar>
       
        <md-button class="md-icon-button toolbar-button" ng-click="$toolbar.openCreateShiftDialog()" ng-if="$toolbar.permissions.createShift && !$toolbar.readOnly && !$toolbar.schedule.original">
            <md-tooltip>{{'scheduling:NEW_SHIFT' | i18next}}</md-tooltip>
            <md-icon ng-bind="'add'"></md-icon>
        </md-button>

        <md-button class="md-icon-button toolbar-button" ng-if="$toolbar.showOriginal" ng-click="$toolbar.goToOriginal()">
            <md-tooltip>{{'ORIGINAL' | i18next}}</md-tooltip>
            <md-icon ng-bind="'history'"></md-icon>
        </md-button>

        <md-button class="md-icon-button toolbar-button" ng-click="$toolbar.syncBackOffice()" ng-if="$toolbar.permissions.boSync &&  !$toolbar.readOnly && !$toolbar.schedule.original">
            <md-tooltip>{{'scheduling:SYNC_BACKOFFICE' | i18next}}</md-tooltip>
            <md-icon ng-bind="'sync'"></md-icon>
        </md-button>

        <md-button class="md-icon-button toolbar-button" ng-click="$toolbar.fullscreen.toggle()" ng-if="!$toolbar.fullscreen.isFullscreen()">
            <md-tooltip>{{'FULLSCREEN' | i18next}}</md-tooltip>
            <md-icon ng-bind="'fullscreen'"></md-icon>
        </md-button>

        <md-button class="md-icon-button toolbar-button" ng-click="$toolbar.fullscreen.toggle()" ng-if="$toolbar.fullscreen.isFullscreen()">
            <md-tooltip>{{'CANCEL_FULLSCREEN' | i18next}}</md-tooltip>
            <md-icon ng-bind="'fullscreen_exit'"></md-icon>
        </md-button>

        <scheduling-go-to-day layout="row" load-shifts="!$toolbar.readOnly"></scheduling-go-to-day>

        <md-button ng-if="!$toolbar.readOnly && $toolbar.canNotify" class="md-icon-button toolbar-button" ng-click="$toolbar.publisher.notifyAuditors()">
            <md-icon ng-bind="'notifications'"></md-icon>
            <md-tooltip ng-i18next="scheduling:NOTIFY_AUDITORS"></md-tooltip>
        </md-button>

        <md-button class="md-icon-button toolbar-button md-warn" ng-if="!$toolbar.readOnly && $toolbar.canDecline" ng-click="$toolbar.declineSchedule()">
            <md-icon ng-bind="'cancel'"></md-icon>
            <md-tooltip ng-i18next="scheduling:DECLINE_SCHEDULE"></md-tooltip>
        </md-button>

        <md-button ng-if="!$toolbar.readOnly && $toolbar.canPublish" ng-click="$toolbar.publisher.publish()">
            <span ng-i18next="scheduling:PUBLISH"></span>
        </md-button>
    </div>
</md-toolbar>
`,
    controllerAs: '$toolbar',
    bindings: {
        readOnly: '<?',
        schedule: '<',
    },
    require: {
        scheduleTab: '^scheduleTab',
        scheduleView: '^^scheduleView',
    },
    controller: [ 'PermissionCheckServiceDowngrade', 'Shift', 'UserPropertyDowngraded', 'MatDialogDowngrade', 'SchedulePublish', 'scheduleTable', 'UnitFilter', 'scheduleMode', '$state', 'BackOffice', 'BackOfficeSync', 'scheduleBudget', 'ToastService', '$mdDialog', function(PermissionCheckServiceDowngrade: PermissionCheckService, OldShiftClass, UserPropertyDowngraded: UserPropertyService, MatDialogDowngrade: MatDialog, SchedulePublish, scheduleTable, UnitFilter, scheduleMode, $state, BackOffice, BackOfficeSync, scheduleBudget, ToastService, $mdDialog) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = async () => {

            const p = `customers.${ctrl.schedule.customer_id}.schedules`;

            ctrl.smallDevice = smallDevice();
            ctrl.fullscreen = Fullscreen;
            ctrl.publisher = new SchedulePublish(ctrl.schedule);
            ctrl.canPublish = ctrl.publisher.canPublish();
            ctrl.canDecline = ctrl.publisher.canDecline();
            ctrl.canNotify = ctrl.publisher.canNotify();
            ctrl.showOriginal = ctrl.schedule.isPublished() && !ctrl.schedule.original;

            await ctrl.checkCanSeeStats();
            ctrl.canSeeUnits = ctrl.checkCanSeeUnits();

            const user = ctrl.scheduleView.user;
            const cust = ctrl.scheduleView.customer;
            ctrl.permissions = {
                sort: user.can(`${p}.${ctrl.schedule.id}.update`),
                createShift: user.can(`${p}.${ctrl.schedule.id}.shifts.create`),
                createSchedule: user.can(`${p}.create`),
                publish: user.can(`${p}.${ctrl.schedule.id}.publish`),
                force_publish: user.can(`${p}.${ctrl.schedule.id}.force_publish`),
                update: user.can(`${p}.${ctrl.schedule.id}.update`),
                boSync: cust.hasProduct('backoffice') && ctrl.scheduleView.user.can(BackOffice.sync.permission(ctrl.schedule.customer_id, ctrl.schedule.id)),
                france: cust.hasProduct('France'),
            };

            ctrl.setGroupings();
            ctrl.setSortings();
            ctrl.setModes();
            ctrl.setIntervals();
            void ctrl.setVlhVariables();
        };

        ctrl.bucketVariableChange = () => {
            const selected = ctrl.scheduleView.mlBucket.variables.filter((v: any) => v.showInStats).map((v: any) => v.code).join(';');
            UserPropertyDowngraded.update(ctrl.scheduleView.user.id, `schedule:top_stats_variables`, selected).subscribe();
            ctrl.scheduleView.recalcTopStats();
        };

        ctrl.setVlhVariables = async () => {
            if (!ctrl.scheduleView.mlBucket) {
                return;
            }

            let saved: any = [];
            try {
                const value = await firstValueFrom(UserPropertyDowngraded.get(ctrl.scheduleView.user.id, `schedule:top_stats_variables`)) as Property;
                saved = value.value.asString().split(';');
            } catch (e) {
                console.error(`e`, e);
            }

            ctrl.scheduleView.mlBucket.variables?.forEach((variable: any) => {
                // @ts-ignore
                variable.showInStats = saved.length ? saved.includes(variable.code) : true;
            });

            ctrl.scheduleView.mlBucket.variables = orderBy(ctrl.scheduleView.mlBucket.variables, 'name', 'asc');
        };

        ctrl.setGroupings = () => {
            ctrl.groupings = [
                {
                    value: 'ungrouped',
                    text: t('scheduling:NO_GROUPING'),
                },
                {
                    value: 'employee',
                    text: t('EMPLOYEE'),
                },
            ];

            if (ctrl.canSeeUnits) {
                ctrl.groupings.push({
                    value: 'businessUnits',
                    text: t('BUSINESS_UNIT_plural'),
                });
            }
        };

        ctrl.setSortings = () => {
            ctrl.sorting = [
                {
                    value: 'offset,asc',
                    text: t('scheduling:OFFSET_ASC'),
                },
                {
                    value: 'offset,desc',
                    text: t('scheduling:OFFSET_DESC'),
                },
                {
                    value: 'employee.name,asc',
                    text: t('scheduling:NAME_ASC'),
                },
                {
                    value: 'employee.name,desc',
                    text: t('scheduling:NAME_DESC'),
                },
            ];
        };

        ctrl.setModes = () => {
            ctrl.modes = [
                {
                    value: 'select',
                    text: t('scheduling:EDIT_SHIFT_plural'),
                },
                {
                    value: 'periodDrag',
                    text: t('scheduling:DRAG_PERIODS'),
                },
            ];
        };

        ctrl.setIntervals = () => {
            ctrl.intervals = [
                {
                    value: 600,
                    text: t('X_MINUTES', { count: 10 }),
                },
                {
                    value: 900,
                    text: t('X_MINUTES', { count: 15 }),
                },
                {
                    value: 1800,
                    text: t('X_MINUTES', { count: 30 }),
                },
                {
                    value: 3600,
                    text: t('X_HOURS', { count: 1 }),
                },
                {
                    value: 7200,
                    text: t('X_HOURS', { count: 2 }),
                },
                {
                    value: 14400,
                    text: t('X_HOURS', { count: 4 }),
                },
            ];
        };

        ctrl.checkCanSeeUnits = () => {
            if (!ctrl.scheduleView.customer.hasProduct('BusinessUnits')) {
                return;
            }
            if (!ctrl.scheduleView.user.can(`customers.${ctrl.schedule.customer_id}.business_units.*.get`)) {
                return;
            }

            return true;
        };

        ctrl.updateSetting = (name: any) => {
            // Get value
            let value = ctrl.schedule.settings[name];
            // Boolean values need to be stored as strings in backend
            if (typeof value === 'boolean') {
                value = value.toString();
            }
            // Save value
            UserPropertyDowngraded.update(ctrl.scheduleView.user.id, `schedule:setting:${name}`, value).subscribe();

            // Handle the update
            switch (name) {
                case 'interval':
                    scheduleTable.render();
                    scheduleTable.renderShifts();
                    ctrl.scheduleView.recalcTopStats();
                    break;
                case 'mode':
                    scheduleMode.setMode(value);
                    scheduleTable.renderShifts();
                    break;
                case 'verticalLines':
                    scheduleTable.renderOpeningHours();
                    break;
                case 'filterUnits':
                    UnitFilter.open(ctrl.schedule).then(() => {
                        scheduleTable.renderShifts();
                    });
                    break;
                case 'sorting':
                    scheduleMode.setSorting(value.split(',')[0], value.split(',')[1]);
                    scheduleTable.renderShifts();
                    break;
                case 'shiftGrouping':
                case 'expandedShifts':
                case 'onlyUnassigned':
                    scheduleTable.renderShifts();
                    break;
                case 'showNicknames':
                    scheduleTable.renderShifts();
                    break;
            }
        };

        ctrl.toggleTopStats = () => {
            UserPropertyDowngraded.update(ctrl.scheduleView.user.id, `schedule:setting:topStatsEnabled`, (!ctrl.schedule.settings.topStatsEnabled).toString()).subscribe();
            ctrl.schedule.settings.topStatsEnabled = !ctrl.schedule.settings.topStatsEnabled;
        };

        ctrl.refreshTopStats = () => {
            ctrl.scheduleView.recalcTopStats();
        };

        ctrl.syncBackOffice = () => {
            BackOfficeSync.start(ctrl.schedule.customer_id, ctrl.schedule.id);
        };

        ctrl.openCreateShiftDialog = () => {
            const date = scheduleTable.getCurrentDate() as Moment;
            MatDialogDowngrade.open<CreateNewShiftDialogComponent, CreateNewShiftDialogData>(CreateNewShiftDialogComponent, {
                data: {
                    customerId: ctrl.schedule.customer_id,
                    scheduleId: ctrl.schedule.id,
                    date: DateTime.fromObject({
                        year: date.year(),
                        month: date.month() + 1,
                        day: date.date(),
                    }),
                    withs: [
                        'periods',
                        'periods.businessUnit',
                        'periods.qualifications',
                        'employee',
                        'qualifications',
                        'properties',
                        'shiftGroup',
                        'comments',
                    ],
                },
            }).afterClosed().subscribe((shift) => {
                if (shift instanceof Shift) {
                    ctrl.schedule.insertShift(new OldShiftClass(shift._response));
                    scheduleTable.renderShifts();
                }
            });
        };

        ctrl.checkCanSeeStats = async () => {
            if (ctrl.readOnly) {
                return;
            }
            if (scheduleBudget.getInterval() >= 86400) {
                return;
            }

            const bucketProp = ctrl.schedule.customer.getProperty('ai-budgeting-ml-bucket');
            if (bucketProp && ctrl.schedule.customer.hasProduct('ai budgeting')) {
                try {
                    ctrl.canSeeStats = await firstValueFrom(PermissionCheckServiceDowngrade.isAllowedMany([
                        `customers.${ctrl.schedule.customer.id}.ml_buckets.${bucketProp}.get`,
                        `customers.${ctrl.schedule.customer.id}.ml_buckets.${bucketProp}.variables.*.get`,
                        `customers.${ctrl.schedule.customer.id}.ml_buckets.${bucketProp}.time_series.*.get`,
                    ]));
                } catch (e) {
                    console.error(e);
                }
            } else {
                ctrl.canSeeStats = true;
            }
        };

        ctrl.goToOriginal = () => {
            $state.go(`eaw/app/scheduling/schedules/list/view/original`, { id: ctrl.schedule.id });
        };

        ctrl.declineSchedule = () => {
            $mdDialog.show(
                $mdDialog.confirm()
                    .title(t('scheduling:DECLINE_SCHEDULE'))
                    .textContent(t('scheduling:DECLINE_SCHEDULE_QUESTION'))
                    .ok(t('DECLINE'))
                    .cancel(t('CANCEL')),
            ).then(() => {
                ctrl.schedule.decline().then(() => {
                    ToastService.tToast('scheduling:DECLINED_SCHEDULE');
                });
            });
        };
    } ],
});
