<eaw-page-header class="tw-mb-16">
    <span title>{{ 'INFORMATION' | translate | async }}</span>
</eaw-page-header>

@if (!fetchedEmployee) {
    <div class="tw-mt-20 tw-flex tw-flex-col tw-justify-center tw-items-center">
        <mat-progress-spinner mode="indeterminate" diameter="88" strokeWidth="8"></mat-progress-spinner>
    </div>
}

@if (warnings.length) {
    <div id="warnings" class="mat-elevation-z3 tw-p-16 tw-mb-16 tw-flex tw-flex-col tw-gap-8">
        @for (warning of warnings; track warning) {
            <div class="tw-flex tw-flex-row tw-gap-8 tw-justify-start tw-items-center">
                <mat-icon>warning</mat-icon>
                <span>{{ warning | async }}</span>
            </div>
        }
    </div>
}

@if (fetchedEmployee) {
    <form class="tw-mb-12 tw-flex tw-flex-col" [formGroup]="infoForm">
        <h2> {{ 'PERSONAL' | translate | async }} </h2>
        <fieldset class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-12">
            @if (infoForm.controls.email) {
                <mat-form-field class="tw-col-span-full">
                    <mat-label>{{ 'EMAIL' | translate | async }}</mat-label>
                    <mat-icon matPrefix>mail</mat-icon>
                    <input formControlName="email" matInput [maxlength]="255">

                    @if (infoForm.controls.email.hasError('email')) {
                        <mat-hint align="start" eawMaterialColor="red-500">{{ 'FORM_INVALID_EMAIL' | translate:'errors' | async }}</mat-hint>
                    } @else {
                        <mat-hint align="end">{{ infoForm.controls.email.value?.length }} / 255</mat-hint>
                    }
                </mat-form-field>
            }
            @if (infoForm.controls.email?.dirty) {
                <div id="dirty-email" class="mat-elevation-z3 tw-p-16 tw-mb-8 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-8 tw-col-span-full">
                    <mat-icon matPrefix>tips_and_updates</mat-icon>
                    <span>{{ 'UPDATE_EMPLOYEE_EMAIL_INFO' | translate:'company' | async }}</span>
                </div>
            }
            @if (infoForm.controls.firstName) {
                <mat-form-field>
                    <mat-icon matPrefix>badge</mat-icon>
                    <mat-label>{{ 'FIRST_NAME' | translate | async }}</mat-label>
                    <input formControlName="firstName" matInput [maxLength]="50">
                    <mat-hint align="end">{{ infoForm.controls.firstName.value?.length }} / 50</mat-hint>
                </mat-form-field>
            }
            @if (infoForm.controls.lastName) {
                <mat-form-field>
                    <mat-icon matPrefix>badge</mat-icon>
                    <mat-label>{{ 'LAST_NAME' | translate | async }}</mat-label>
                    <input formControlName="lastName" matInput [maxLength]="50">
                    <mat-hint align="end">{{ infoForm.controls.lastName.value?.length }} / 50</mat-hint>
                </mat-form-field>
            }
            @if (infoForm.controls.nickname) {
            <mat-form-field>
                <mat-icon matPrefix>badge</mat-icon>
                <mat-label>{{ 'NICKNAME' | translate | async }}</mat-label>
                <input formControlName="nickname" matInput [maxLength]="50">
                <mat-hint align="end">{{ infoForm.controls.nickname.value?.length }} / 50</mat-hint>
            </mat-form-field>
            }
            @if (infoForm.controls.phone) {
                <mat-form-field>
                    <mat-icon matPrefix>call</mat-icon>
                    <mat-label>{{ 'PHONE' | translate | async }}</mat-label>
                    <input formControlName="phone" matInput [maxLength]="255" type="tel">
                    <mat-hint align="end">{{ infoForm.controls.phone.value?.length }} / 255</mat-hint>
                </mat-form-field>
            }
            @if (infoForm.controls.gender) {
                <mat-form-field>
                    <mat-label>{{ 'GENDER' | translate | async }}</mat-label>
                    @if (infoForm.controls.gender.value === 'male') {
                        <mat-icon matPrefix>male</mat-icon>
                    }
                    @if (infoForm.controls.gender.value === 'female') {
                        <mat-icon matPrefix>female</mat-icon>
                    }
                    @if (infoForm.controls.gender.value === 'other') {
                        <mat-icon matPrefix>person</mat-icon>
                    }
                    <mat-select formControlName="gender">
                        <mat-option [value]="'female'">{{ 'FEMALE' | translateSync }}</mat-option>
                        <mat-option [value]="'male'">{{ 'MALE' | translateSync }}</mat-option>
                        <mat-option [value]="'other'">{{ 'OTHER' | translateSync }}</mat-option>
                    </mat-select>
                </mat-form-field>
            }
            @if (infoForm.controls.birthDate) {
                <mat-form-field eawDatePickerOptions>
                    <mat-label>{{ 'BIRTH_DATE' | translate:'company' | async }}</mat-label>
                    <mat-icon matPrefix>cake</mat-icon>
                    <input formControlName="birthDate" matInput [matDatepicker]="$any(birthDatePicker)">
                    <mat-hint>{{ age | unit:'year':'long':0 }}</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #birthDatePicker startView="multi-year"></mat-datepicker>
                </mat-form-field>
            }
            <eaw-autocomplete formControlName="nationality"
                              prefix="public"
                              [label]="'NATIONALITY' | translate"
                              [options]="countryAutocompleteService.options"
                              [setter]="countryAutocompleteService.setter()"
                              [getter]="countryAutocompleteService.getter()">
            </eaw-autocomplete>
            @if (infoForm.controls.address1) {
                <mat-form-field>
                    <mat-icon matPrefix>house</mat-icon>
                    <mat-label>{{ 'ADDRESS_1' | translate | async }}</mat-label>
                    <input formControlName="address1" matInput maxlength="100">
                    <mat-hint align="end">{{ infoForm.controls.address1.value?.length }} / 100</mat-hint>
                </mat-form-field>
            }
            @if (infoForm.controls.address2) {
                <mat-form-field>
                    <mat-icon matPrefix>house</mat-icon>
                    <mat-label>{{ 'ADDRESS_2' | translate | async }}</mat-label>
                    <input formControlName="address2" matInput maxlength="100">
                    <mat-hint align="end">{{ infoForm.controls.address2.value?.length }} / 100</mat-hint>
                </mat-form-field>
            }
            @if (infoForm.controls.postalCode) {
                <mat-form-field>
                    <mat-icon matPrefix>location_city</mat-icon>
                    <mat-label>{{ 'ZIP' | translate | async }}</mat-label>
                    <input formControlName="postalCode" matInput maxlength="10">
                    <mat-hint align="end">{{ infoForm.controls.postalCode.value?.length }} / 10</mat-hint>
                </mat-form-field>
            }
            @if (infoForm.controls.city) {
                <mat-form-field>
                    <mat-icon matPrefix>location_city</mat-icon>
                    <mat-label>{{ 'CITY' | translate | async }}</mat-label>
                    <input formControlName="city" matInput maxlength="50">
                    <mat-hint align="end">{{ infoForm.controls.city.value?.length }} / 50</mat-hint>
                </mat-form-field>
            }
            <!-- If only country and no region -->
            @if (infoForm.controls.countryKey && !infoForm.controls.region) {
                <ng-container>
                    <eaw-autocomplete formControlName="countryKey"
                                      prefix="public"
                                      [options]="countryAutocompleteService.options"
                                      [setter]="countryAutocompleteService.setter()"
                                      [getter]="countryAutocompleteService.getter()">
                    </eaw-autocomplete>
                </ng-container>
            }
            <!-- If country and region -->
            @if (infoForm.controls.countryKey && infoForm.controls.region) {
                <ng-container>
                    <eaw-autocomplete formControlName="countryKey"
                                      prefix="public"
                                      [options]="countryAutocompleteService.options"
                                      [setter]="countryAutocompleteService.setter()"
                                      [getter]="countryAutocompleteService.getter()">
                    </eaw-autocomplete>
                    @if (!infoForm.controls.countryKey.value) {
                        <mat-form-field>
                            <mat-label>{{ 'REGION' | translate:'company' | async }}</mat-label>

                            <div matPrefix class="tw-mx-12">
                                <mat-icon>pin_drop</mat-icon>
                            </div>

                            <input matInput disabled>
                        </mat-form-field>
                    }
                    @if (infoForm.controls.countryKey.value) {
                        <eaw-autocomplete formControlName="region"
                                          prefix="pin_drop"
                                          [options]="countryRegionAutocompleteService.options"
                                          [setter]="countryRegionAutocompleteService.setter({ countryCode: infoForm.controls.countryKey.value })"
                                          [getter]="countryRegionAutocompleteService.getter({ countryCode: infoForm.controls.countryKey.value })"
                                          [triggers]="[regionTrigger]">
                        </eaw-autocomplete>
                    }
                </ng-container>
            }
        </fieldset>
        <h2> {{ 'EMPLOYMENT' | translate | async }} </h2>
        <fieldset class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-12">
            @if (infoForm.controls.employeeNumber) {
                <mat-form-field>
                    <mat-label>{{ 'EMPLOYEE_NUMBER' | translate:'company' | async }}</mat-label>
                    <mat-icon matPrefix>tag</mat-icon>
                    <input formControlName="employeeNumber" matInput type="number" min="0" max="1e10">
                </mat-form-field>
            }
            @if (infoForm.controls.role) {
                <eaw-role-autocomplete eawPermission [permissions]="signablePermissions()" [label]="'EMPLOYEE_MANAGER' | translate:'company'" controlName="role" [customerId]="customerId"></eaw-role-autocomplete>
            }
            @if (infoForm.controls.employmentFrom) {
                <mat-form-field eawDatePickerOptions>
                    <mat-label>{{ 'EMPLOYMENT_FROM' | translate:'company' | async }}</mat-label>
                    <mat-icon matPrefix>login</mat-icon>
                    <input formControlName="employmentFrom" matInput [matDatepicker]="$any(employmentFromPicker)">
                    <mat-datepicker-toggle matSuffix [for]="employmentFromPicker"></mat-datepicker-toggle>
                    <mat-datepicker #employmentFromPicker startView="multi-year"></mat-datepicker>
                </mat-form-field>
            }
            @if (infoForm.controls.employmentTo) {
                <mat-form-field eawDatePickerOptions>
                    <mat-label>{{ 'EMPLOYMENT_TO' | translate:'company' | async }}</mat-label>
                    <mat-icon matPrefix>logout</mat-icon>
                    <input formControlName="employmentTo" matInput [matDatepicker]="$any(employmentToPicker)">
                    <mat-datepicker-toggle matSuffix [for]="employmentToPicker"></mat-datepicker-toggle>
                    <mat-datepicker #employmentToPicker startView="multi-year"></mat-datepicker>
                </mat-form-field>
            }
            @if (infoForm.controls.extraSeniority) {
                <mat-form-field>
                    <mat-label>{{ 'EXTRA_SENIORITY' | translate:'company' | async }}</mat-label>
                    <mat-icon matPrefix>more_time</mat-icon>
                    <input formControlName="extraSeniority" matInput type="number" min="0">
                    <mat-hint>{{ extraSeniorityYears | unit:'year':'long':2 }}</mat-hint>
                </mat-form-field>
            }
        </fieldset>
    </form>
}

@if (fetchedEmployee && updateEmployeePermission && (updateEmployeePermission | permission | async)) {
    <button mat-raised-button (click)="submit()" [disabled]="infoForm.disabled || infoForm.pristine || infoForm.invalid" color="accent">{{ 'UPDATE_EMPLOYEE' | translate:'company' | async }}</button>
}
@if (pinCodesEnabled && updatePinCodePermission && (updatePinCodePermission | permission | async)) {
    <button mat-raised-button class="tw-ml-4" (click)="resetPinCode()">{{ 'RESET_PIN_CODE' | translate:'company' | async }}</button>
}
