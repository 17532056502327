import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { ReorderDialogItem, ReorderItemsDialogData } from './reorder-items-dialog.service';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { Observable, of } from 'rxjs';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CheckboxHelperDirective } from '../../directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-reorder-items-dialog',
    templateUrl: './reorder-items-dialog.component.html',
    styleUrl: './reorder-items-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        CdkDropList,
        NgFor,
        CdkDrag,
        MatRippleModule,
        MatIconModule,
        CdkDragHandle,
        MatCheckboxModule,
        CheckboxHelperDirective,
        ReactiveFormsModule,
        FormsModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ReorderItemsDialogComponent<Item> extends DialogComponent implements OnInit {
    loading = true;
    items: ReorderDialogItem<Item>[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ReorderItemsDialogData<Item>,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ReorderItemsDialogComponent<Item>, ReorderDialogItem<Item>[]>,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        (this.data.items instanceof Observable ? this.data.items : of(this.data.items)).subscribe((res) => {
            this.items = res;
            this.loading = false;
        });
    }

    close() {
        this.dialogRef.close(this.items);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    }
}
