import { t } from 'i18next';
import { module } from 'angular';
import { Mobile } from '../../../../../shared/utils/eaw-mobile';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
import { MatDialog } from '@angular/material/dialog';
import { WysiwygDialogComponent, WysiwygDialogData, WysiwygDialogReturn } from '../../../../../wysiwyg/dialogs/wysiwyg-dialog/wysiwyg-dialog.component';
import { Bulletin } from '../../../../../shared/models/bulletin';
import { EMPTY, from, tap } from 'rxjs';

const template1 = `<md-card class="postit">
    <md-card-header flex="none" md-colors="{'background': 'yellow-200'}">
        <eaw-profile-picture-old user="$ctrl.postit.user" size="35"></eaw-profile-picture-old>

        <md-card-header-text>
            <span class="md-title overflow-ellipsis">{{$ctrl.postit.user.name}}</span>
            <span class="md-subhead overflow-ellipsis">
                <eaw-time time="$ctrl.postit.created_at"></eaw-time>
            </span>
        </md-card-header-text>

        <md-menu ng-if="$ctrl.canEdit || $ctrl.canDelete">
            <md-button class="md-icon-button" ng-click="$mdMenu.open()">
                <md-icon ng-bind="'more_vert'"></md-icon>
            </md-button>

            <md-menu-content width="4">
                <md-menu-item ng-if="$ctrl.canEdit">
                    <md-button ng-click="$ctrl.updatePostit($ctrl.postit)">{{'widgets:EDIT_POSTIT' | i18next}}</md-button>
                </md-menu-item>
                <md-menu-item ng-if="$ctrl.canDelete">
                    <md-button ng-click="$ctrl.deletePostit($ctrl.postit)">{{'DELETE' | i18next}}</md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </md-card-header>

    <md-card-content class="pretty-scroll" flex md-colors="{'background': 'yellow-100'}">
        <markdown-downgrade class="postit-text" [text]="$ctrl.postit.body"></markdown-downgrade>
    </md-card-content>
</md-card>
`;

module('eaw.dashboard').component('postit', {
    template: template1,
    require: {
        parent: '^postitsWidget',
    },
    bindings: {
        postit: '<',
    },
    controller: [ 'MatDialogDowngrade', 'PostitFactory', '$mdDialog', '$element', 'ImageGallery', function(MatDialogDowngrade: MatDialog, PostitFactory, $mdDialog, $element, ImageGallery) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = () => {
            const customerId = ctrl.parent.eawWidget.customer.id;
            ctrl.canEdit = CurrentOld.can(`customers.${customerId}.postits.${ctrl.postit.id}.update`);
            ctrl.canDelete = CurrentOld.can(`customers.${customerId}.postits.${ctrl.postit.id}.delete`);
        };

        ctrl.$postLink = () => {
            $element[0].addEventListener('click', ctrl.onClick);
        };

        ctrl.$onDestroy = () => {
            clearInterval(ctrl.interval);
            $element[0].removeEventListener('click', ctrl.onClick);
        };

        ctrl.onClick = (e: any) => {
            if (!e) {
                return;
            }

            const tag = e.target.tagName.toLowerCase();

            if (Mobile.isMobile && tag === 'a') {
                e.preventDefault();
                Mobile.openBrowser(e.target.href);
            }

            if (tag === 'img') {
                ImageGallery.open({
                    items: [ {
                        source: e.target.src,
                    } ],
                });
            }
        };

        ctrl.updatePostit = (postit: any) => {
            MatDialogDowngrade.open<WysiwygDialogComponent<Bulletin>, WysiwygDialogData<Bulletin>, WysiwygDialogReturn<Bulletin>>(WysiwygDialogComponent, {
                data: {
                    content: postit.body,
                    title: Promise.resolve(t('widgets:UPDATE_POSTIT')),
                    submitText: Promise.resolve(t('widgets:PUBLISH_POSTIT')),
                    submitFn: (result) => {
                        if (!result) {
                            return EMPTY;
                        }

                        return from(PostitFactory.update(ctrl.postit.id, result, postit.customer_id, [ 'user' ]).$promise as Promise<any>).pipe(
                            tap((updatedPostit) => {
                                ctrl.parent.updatePostit(updatedPostit);
                            }),
                        );
                    },
                },
            });
        };

        ctrl.deletePostit = (postit: any) => {
            $mdDialog.show(
                $mdDialog.confirm()
                    .theme('delete')
                    .title(t('DELETE'))
                    .textContent(t('widgets:DELETE_POSTIT'))
                    .ok(t('DELETE'))
                    .cancel(t('widgets:KEEP_IT')),
            ).then(() => {
                PostitFactory.delete(postit.id, CurrentOld.customer['id']).$promise.then(() => {
                    ctrl.parent.removePostit(postit);
                });
            });
        };
    } ],
});
