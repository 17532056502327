import { Component, Inject, Input, OnInit } from '@angular/core';
import { BalanceService } from '../../../balances/http/balance.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { catchError, filter, forkJoin, map, of } from 'rxjs';
import { DateTime } from 'luxon';
import { BalanceType } from '../../../balances/models/balance-type';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { CurrentService } from '../../../shared/services/current.service';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { MatDialog } from '@angular/material/dialog';
import { BalanceHistoryDialogComponent, BalanceHistoryDialogData } from '../../../balances/dialogs/balance-history-dialog/balance-history-dialog.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Sort, MatSortModule } from '@angular/material/sort';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { EmployeeService } from '../../../shared/http/employee.service';
import { Contract } from '../../../shared/models/contract';
import { ContractType } from '../../../shared/models/contract-type';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { NgFor, NgIf, AsyncPipe, KeyValuePipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateIntervalSelectorComponent } from '../../../shared/components/date-interval-selector/date-interval-selector.component';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

interface EmployeeItem {
    name: string,
    id: number,
    balances: Record<string, number | undefined>
    contract: Contract | undefined,
}

@Component({
    selector: 'eaw-company-balances',
    templateUrl: './company-balances.component.html',
    styleUrl: './company-balances.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        DateIntervalSelectorComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatInputModule,
        MatCardModule,
        NgIf,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        AsyncPipe,
        KeyValuePipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class CompanyBalancesComponent implements OnInit {
    @Input({ required: true }) customerId!: number;

    private lastSort: Sort = {
        active: 'employee',
        direction: 'asc',
    };

    from = DateTime.now();
    to = DateTime.now().endOf('week');
    employees: EmployeeItem[] = [];
    filteredEmployees: EmployeeItem[] = [];
    balanceTypes: BalanceType[] = [];
    displayColumns: string[] = [];
    totals: Record<string, string> = {};
    fetching = false;
    contractTypes = new Map<number, ContractType>();
    form = new FormGroup({
        interval: new FormControl<'day'| 'week'>('day', { nonNullable: true }),
        filter: new FormControl<string | null>(null),
        contractType: new FormControl<ContractType | null>(null),
    });

    constructor(
        @Inject(BalanceService) private balanceService: BalanceService,
        @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(EmployeeService) private employeeService: EmployeeService,
    ) {
    }

    ngOnInit() {
        this.getData();

        this.form.valueChanges.subscribe(this.filter.bind(this));
    }

    filter() {
        const { filter, contractType } = this.form.getRawValue();

        this.filteredEmployees = this.employees.filter((employee) => {
            const hasContractType = contractType ? employee.contract?.type?.id === contractType.id : true;
            const matchesFilter = !filter || employee.name.toLowerCase().includes(filter.toLowerCase());

            return hasContractType && matchesFilter;
        });

        this.updateBalanceSums();
    }

    updateInterval(event: { from: DateTime, to: DateTime }) {
        this.from = event.from;
        this.to = event.to;
        this.getData();
    }

    onIntervalTypeChanged() {
        if (this.form.controls.interval.value === 'week') {
            this.to = this.from.endOf('week');
            this.from = this.from.startOf('week');
        }
        this.getData();
    }

    sort(event: Sort) {
        let result: EmployeeItem[] = [];
        this.lastSort = event;

        if (event.active === 'employee') {
            result = sort(this.filteredEmployees, this.current.languageTag, [ (e) => e.name ], [ event.direction || 'asc' ]);
        } else {
            result = this.filteredEmployees.sort((a, b) => {
                if (event.direction === 'asc') {
                    return (a.balances[event.active] || 0) - (b.balances[event.active] || 0);
                } else {
                    return (b.balances[event.active] || 0) - (a.balances[event.active] || 0);
                }
            });
        }

        // Spread it to trigger change detection
        this.filteredEmployees = [ ...result ];
    }

    getData() {
        const time = this.form.controls.interval.value === 'day' ? { date: this.from } : { from: this.from, to: this.to };
        const balances = expandAllPages((pagination) => this.balanceService.getEmployeesOverview(this.customerId, { ...time, ...pagination }), { per_page: 40 });
        const types = this.getTypes();
        const employeesWithContracts = expandAllPages((pagination) => this.employeeService.getAll(this.customerId, pagination), { per_page: 40, 'with[]': [ 'contracts.type' ] });

        this.fetching = true;
        forkJoin([ balances, types, employeesWithContracts ]).subscribe(([ balances, types, employeesWithContracts ]) => {
            this.balanceTypes = types;
            this.employees = [];

            balances.forEach((balance) => {
                const activeContract = employeesWithContracts.find((employee) => employee.id === balance.id)?.getActiveContract(this.from);

                this.employees.push({
                    name: balance.name,
                    id: balance.id,
                    contract: activeContract,
                    balances: balance.balance_changes.reduce((acc, value) => {
                        acc[value.type] = parseFloat(String(value.delta));
                        return acc;
                    }, {} as Record<string, number>),
                });

                if (activeContract?.type) {
                    this.contractTypes.set(activeContract.type.id, activeContract.type);
                }
            });

            this.employees = sort(this.employees, this.current.languageTag, [ (e) => e.name ], [ 'asc' ]);
            this.displayColumns = [ 'name', 'contract', ...this.balanceTypes.map((type) => type.balanceCode) ];
            this.filteredEmployees = this.employees;

            // Re-apply sorting and filter
            this.sort(this.lastSort);

            this.updateBalanceSums();
            this.fetching = false;
        });
    }

    updateBalanceSums() {
        this.balanceTypes.forEach((type) => {
            this.totals[type.balanceCode] = this.getBalanceSum(type);
        });
    }

    getBalanceSum(type: BalanceType) {
        const value = this.filteredEmployees.reduce((acc, employee) => acc + (employee.balances[type.balanceCode] || 0), 0) / type.factor;

        return this.numberFormatterService.formatDecimal(value, 2, this.current.languageTag, {
            signDisplay: 'exceptZero',
        });
    }

    openBalanceDetails(employee: EmployeeItem, type: BalanceType) {
        this.matDialog.open<BalanceHistoryDialogComponent, BalanceHistoryDialogData, boolean>(BalanceHistoryDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: employee.id,
                balanceType: of(type),
                allowChanges: true,
            },
        }).afterClosed().pipe(filter((balanceChanged) => !!balanceChanged)).subscribe(() => this.getData());
    }

    getBalance(employee: EmployeeItem, type: BalanceType) {
        const value = (employee.balances[type.balanceCode] || 0) / type.factor;

        return this.numberFormatterService.formatDecimal(value, 2, this.current.languageTag, {
            signDisplay: 'exceptZero',
        });
    }

    getTypes() {
        if (this.balanceTypes.length) {
            return of(this.balanceTypes);
        }

        return expandAllPages((pagination) => this.balanceService.getTypes(this.customerId, pagination).pipe(
            catchError((e) => {
                console.error(e);
                return of(mockArrayPaginatedResponse([]));
            }),
            map((response) => {
                return {
                    ...response,
                    data: Object.values(response.data),
                };
            }),
        ), {
            per_page: 40,
        });
    }
}
