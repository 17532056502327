// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="$settings.widget.i18name | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <md-input-container>
                <label ng-i18next="DAY"></label>
                <md-select ng-model="$settings.settings.day_offset">
                    <md-option ng-repeat="option in $settings.options track by $index" ng-value="option.value">
                        {{option.text}}
                    </md-option>
                </md-select>
            </md-input-container>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-click="$settings.submit()" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.dashboard').factory('vacantShftSettings', [ '$mdDialog', function($mdDialog) {
    return function miniAbsenceSettings(widget, settings) {
        return $mdDialog.show({
            template: template1,
            controllerAs: '$settings',
            multiple: true,
            controller: function() {
                const ctrl = this;

                ctrl.$onInit = () => {
                    ctrl.cancel = $mdDialog.cancel;
                    ctrl.widget = widget;
                    ctrl.options = [
                        {
                            text: t('TODAY'),
                            value: 0,
                        },
                        {
                            text: t('TOMORROW'),
                            value: 1,
                        },
                    ];
                    ctrl.settings = {
                        day_offset: 0,
                        ...settings,
                    };
                };

                ctrl.submit = () => {
                    $mdDialog.hide(ctrl.settings);
                };
            },
        });
    };
} ]);
