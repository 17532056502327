import { Inject, Injectable } from '@angular/core';
import { Employee } from '../models/employee';
import { DateTime } from 'luxon';
import { EmployeeService } from '../http/employee.service';
import { TranslateService } from '../services/translate.service';
import { of } from 'rxjs';
import { Autocomplete } from './autocomplete';

interface GetEmployeesArgs {
    customerId: number;
    includeExternal?: boolean,
    includeFuture?: boolean
    includeInactive?: boolean;
    /**
     * If set, only employees who are active are shown.
     * Cannot be used in addition to 'from' and 'to'
     * */
    active?: DateTime | null;
    /**
     * If set, only employees who are active in the interval 'from' - 'to' or 'from' - now are shown.
     * Cannot be used in addition to 'active'
     *  */
    from?: DateTime | null;
    /**
     * If set, only employees who are active within the interval 'from' - 'to' are shown.
     * The API defaults to now if 'to' is not set, but 'from' is.
     * Cannot be used in addition to 'active'.
     * */
    to?: DateTime | null;
    /** relations to include, always includes user */
    with?: string[];
}

interface SetEmployeeArgs {
    customerId: number;
}

@Injectable({
    providedIn: 'root',
})
export class EmployeeAutocompleteService extends Autocomplete<Employee> {
    constructor(
        @Inject(EmployeeService) protected employeeService: EmployeeService,
        @Inject(TranslateService) protected translateService: TranslateService,
    ) {
        super({
            label: translateService.t('EMPLOYEE'),
            optionTemplate: 'employee',
            filterRequirement: 3,
            display: 'name',
            trackByKey: 'id',
        });
    }

    setter(args: SetEmployeeArgs) {
        return (item: unknown) => {
            if (item instanceof Employee) {
                return of(item);
            }
            if (typeof item !== 'number') {
                return of(undefined);
            }

            return this.employeeService.get(args.customerId, item, { 'with[]': [ 'user' ] });
        };
    }

    getter(args: GetEmployeesArgs) {
        return (filter?: string) => {
            const relations: string[] = args.with ? [ ...args.with, 'user' ] : [ 'user' ];

            if (args.includeExternal) {
                relations.push('customer');
            }

            return this.employeeService.getAll(args.customerId, {
                include_custom_fields: false,
                order_by: 'name',
                direction: 'asc',
                'fields[]': [ 'name', 'email' ],
                'with[]': relations,
                filter: filter?.trim() || undefined,
                per_page: 20,
                active: args.active || undefined,
                from: args.from || undefined,
                to: args.to || undefined,
                include_external: args.includeExternal,
                include_future: args.includeFuture ?? true,
                include_inactive: args.includeInactive,
            });
        };
    };
}
