<eaw-page-header [fabButton]="headerFabButton" class="tw-mb-16">
    <span title>{{ 'CUSTOM_FIELD_plural' | translate | async }}</span>
</eaw-page-header>


<mat-accordion>
    @for (model of models | keyvalue; track model) {
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>{{ model.key }}</mat-panel-title>
                <mat-panel-description>{{ model.value.length }}</mat-panel-description>
            </mat-expansion-panel-header>

            <mat-accordion>
                @for (cf of model.value; track cf.id) {
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>{{ cf.customField?.key }}</mat-panel-title>
                            <mat-panel-description>{{ cf.customField?.translationKey | translate:'custom_fields' | async }}</mat-panel-description>
                        </mat-expansion-panel-header>

                        <table class="info-table">
                            <tbody>
                            <tr>
                                <td>{{ 'CUSTOM_FIELD_ID' | translate:'admin' | async }}</td>
                                <td>{{ cf.id }}</td>
                            </tr>
                            <tr>
                                <td>{{ 'KEY' | translate | async }}</td>
                                <td>{{ cf.customField?.key }}</td>
                            </tr>
                            <tr>
                                <td>{{ 'NAME' | translate | async }}</td>
                                <td>{{ cf.customField?.name }}</td>
                            </tr>
                            <tr>
                                <td>{{ 'TRANSLATED_NAME' | translate:'admin' | async }}</td>
                                <td>{{ cf.customField?.translationKey | translate:'custom_fields' | async }}</td>
                            </tr>
                            <tr>
                                <td>{{ 'REQUIRED' | translate | async }}</td>
                                <td>
                                    <mat-slide-toggle [(ngModel)]="cf.required" (ngModelChange)="updateToggle('required', cf, $event)"></mat-slide-toggle>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'CF_HAS_INTERVAL' | translate:'admin' | async }}</td>
                                <td>
                                    <mat-slide-toggle [(ngModel)]="cf.hasInterval" (ngModelChange)="updateToggle('has_interval', cf, $event)"></mat-slide-toggle>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'TYPE' | translate | async }}</td>
                                <td>{{ cf.customField?.type }}</td>
                            </tr>
                            <tr>
                                <td>{{ 'MODEL' | translate:'admin' | async }}</td>
                                <td>{{ cf.model }}</td>
                            </tr>
                            <tr>
                                <td>{{ 'METADATA' | translate:'admin' | async }}</td>
                                <td><code>{{ cf.stringifiedMetadata }}</code></td>
                            </tr>

                                @if (cf.priority) {
                                    <tr *ngIf="cf.priority">
                                        <td>{{ 'CUSTOM_FIELD_PRIORITY' | translate: 'admin' | async }}</td>
                                        <td>{{ cf.priority }}</td>
                                    </tr>
                                }

                                @if (cf.selectOptions.length) {
                                    <tr>
                                        <td>{{ 'OPTION_plural' | translate | async }}</td>
                                        <td>
                                            <table>
                                                <tbody>
                                                <tr *ngFor="let option of cf.selectOptions">
                                                    <td>{{ option.value }}</td>
                                                    <td>→</td>
                                                    <td>{{ option.translationKey | translate:'custom_fields' | async }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                }

                            <tr>
                                <td>{{ 'VALIDATOR' | translate:'admin' | async }}</td>
                                <td>{{ cf.validator }}</td>
                            </tr>
                            <tr>
                                <td>{{ 'CREATED_AT' | translate | async }}</td>
                                <td>{{ cf.createdAt | DateTime }}</td>
                            </tr>
                            <tr>
                                <td>{{ 'UPDATED_AT' | translate | async }}</td>
                                <td>{{ cf.updatedAt | DateTime }}</td>
                            </tr>
                            </tbody>
                        </table>

                        <mat-action-row>
                            <button mat-raised-button color="primary" (click)="edit(cf)">{{ 'EDIT' | translate | async }}</button>
                            <button mat-raised-button color="warn" (click)="delete(cf)">{{ 'DELETE' | translate | async }}</button>
                        </mat-action-row>
                    </mat-expansion-panel>
                }
            </mat-accordion>
        </mat-expansion-panel>
    }
</mat-accordion>


