// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="'ABSENCE_TYPE' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="absenceTypeForm" id="absenceTypeForm" ng-cloak ng-submit="$dialogCtrl.submit()" layout="column">

                <md-input-container>
                    <label for="name" ng-i18next="NAME"></label>
                    <input id="name" type="text" required name="name" ng-model="$dialogCtrl.absenceType.name">
                </md-input-container>

                <md-input-container>
                    <label for="description" ng-i18next="DESCRIPTION"></label>
                    <input id="description" placeholder="Insert WTI key added to 'absence_types' file/namespace" type="text" ng-model="$dialogCtrl.absenceType.description">
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="CODE"></label>
                    <input type="text" ng-model="$dialogCtrl.absenceType.code">
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="absence_types:APPROVAL_DEADLINE_DAYS"></label>
                    <input type="number" min="1" max="10000" ng-model="$dialogCtrl.absenceType.approval_deadline_days">
                </md-input-container>

                <md-input-container class="tw-w-full">
                    <label ng-i18next="admin:SPAN"></label>
                    <md-select ng-model="$dialogCtrl.absenceType.span" required>
                        <md-option ng-repeat="s in $dialogCtrl.span track by $index" ng-value="s" ng-bind="s.toUpperCase() + '_plural' | i18next"></md-option>
                    </md-select>
                </md-input-container>

                <eaw-date-time-interval ng-model="$dialogCtrl.interval" from-required="true"></eaw-date-time-interval>

                <md-checkbox ng-model="$dialogCtrl.absenceType.paid">
                    <span ng-i18next="admin:PAID"></span>
                </md-checkbox>

                <md-checkbox ng-model="$dialogCtrl.absenceType.gradable">
                    <span ng-i18next="absences:GRADABLE"></span>
                </md-checkbox>

                <eaw-colorpicker ng-model="$dialogCtrl.absenceType.color" required="true" hex="true"></eaw-colorpicker>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button type="submit" form="absenceTypeForm" ng-i18next="SUBMIT" ng-disabled="absenceTypeForm.$invalid"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.admin').service('AbsenceTypeDialog', [ '$mdDialog', 'AbsenceTypeFactory', 'deleteItemAlert', 'colorpickerService', 'ToastService', function($mdDialog, AbsenceTypeFactory, deleteItemAlert, colorpickerService, ToastService) {
    const service = this;
    service.add = async (settingGroupId) => {
        const result = await open();
        return AbsenceTypeFactory.add(settingGroupId, result).$promise.then((data) => {
            ToastService.toast(`Added absence type ${data.name}`);
        });
    };
    service.edit = async (type) => {
        const result = await open(type);
        result.id = type.id;
        return AbsenceTypeFactory.update(type.setting_group_id, result).$promise.then((resp) => {
            ToastService.toast(`${resp.name} edited`);
        });
    };
    service.delete = async (type) => {
        await deleteItemAlert.alert('DELETE', { var: '$t(ABSENCE_TYPE)' }, 'THIS_WILL_DELETE', {
            var: '$t(ABSENCE_TYPE)',
            name: type.name,
        });
        return AbsenceTypeFactory.delete(type.setting_group_id, type.id).$promise.then(() => {
            ToastService.toast('Absence type deleted');
        });
    };
    function open(type) {
        return $mdDialog.show({
            template: template1,
            controllerAs: '$dialogCtrl',
            bindToController: true,
            locals: {
                absenceType: { ...type },
                cancel: $mdDialog.cancel,
            },
            controller: function() {
                const ctrl = this;
                ctrl.$onInit = () => {
                    ctrl.span = [ 'hour', 'day' ];
                    ctrl.interval = {
                        from: ctrl.absenceType.from?.clone() || moment(),
                        to: ctrl.absenceType.to?.clone(),
                    };
                    if (!Object.keys(ctrl.absenceType || []).length) {
                        ctrl.absenceType = {
                            span: ctrl.span[0],
                            color: colorpickerService.getRandomHexColor(),
                        };
                    }
                };
                ctrl.submit = () => {
                    ctrl.absenceType.from = ctrl.interval?.from;
                    ctrl.absenceType.to = ctrl.interval?.to;
                    $mdDialog.hide(ctrl.absenceType);
                };
            },
        });
    }
    return service;
} ]);
