import { Component, Inject } from '@angular/core';
import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { HrFileType } from '../../models/hr-file-type';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { RequestHrFileDialogData, RequestHrFileDialogReturn } from './request-hr-file-dialog.service';
import { DialogComponent } from '../../../shared/dialogs/dialog-component';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { DocumentServiceService } from '../../../shared/http/document-service.service';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { catchError, forkJoin, of } from 'rxjs';
import { Namespace } from '../../../shared/enums/namespace';
import { SnackBarService } from '../../../shared/services/snack-bar.service';

@Component({
    selector: 'eaw-request-hr-file-dialog',
    standalone: true,
    imports: [
        CommonModule,
        DialogHeaderComponent,
        TranslatePipe,
        MatDialogContent,
        MatDialogActions,
        ActionButtonComponent,
        MatButtonModule,
        NgIf,
        MatProgressSpinnerModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
    templateUrl: './request-hr-file-dialog.component.html',
    styleUrl: './request-hr-file-dialog.component.scss',
})
export class RequestHrFileDialogComponent extends DialogComponent {
    customerId: number;
    employees: number[];
    loading = false;
    filetype: HrFileType;
    multiSelect = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: RequestHrFileDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<RequestHrFileDialogData, RequestHrFileDialogReturn>,
        @Inject(DocumentServiceService) private documentService: DocumentServiceService,
        @Inject(SnackBarService) public snackbar: SnackBarService,
    ) {
        super(dialogRef, data);

        this.employees = data.employees;
        this.filetype = data.type;
        this.customerId = data.customerId;
        this.multiSelect = data.multiSelect;
    }

    submit() {
        const sources = this.employees.map((employeeId) => this.documentService.requestDocument(this.customerId, employeeId, this.filetype.id).pipe(
            catchError(() => of()),
        ));
        this.loading = true;

        const returnMode = this.multiSelect ? 'Multiple' : this.employees.length === 1 ? 'Single' : 'All';

        forkJoin(sources)
            .subscribe({
                next: () => {
                    void this.snackbar.t('DOCUMENT_REQUESTED', Namespace.Hr);
                    this.dialogRef.close(returnMode);
                },
                error: () => {
                    this.loading = false;
                },
            });
    }
}
