// @ts-nocheck
import { t } from 'i18next';
import { Moment } from 'moment-timezone';
import { DateTimeConverter } from '../utils/date-time-converter';
import { CustomFieldOld } from './custom-field-old';
import { isEqual } from 'lodash-es';
import { keyBy } from 'lodash-es';
import { uniq } from 'lodash-es';
import { Nullable } from '../types/nullable';
import { Warning } from './warning';
import { ModelData } from './model-data';

/**
 * @deprecated
 */
export class BaseModel implements ModelData {
    [key: string]: any

    _business_dates?: string[];
    _dates?: string[];
    created_at: Nullable<Moment> = null;
    deleted_at: Nullable<Moment> = null;
    updated_at: Nullable<Moment> = null;
    properties: Record<string, any>[] = [];
    protected morph_class!: string;

    constructor(data: any | null = null) {
        if (data) {
            Object.assign(this, data);

            this._business_dates = data._business_dates || [];
            this._dates = data._dates || [];

            // Make sure we don't have doubles
            this._dates = this._dates.filter((d) => !this._business_dates?.find((bd) => d == bd));

            this._dates.forEach((field) => {
                const datum = data[field];
                this[field] = datum && typeof datum == 'string' ? DateTimeConverter.convertStringToMoment(datum) : datum;
            });

            this._business_dates.forEach((field) => {
                const datum = data[field];
                this[field] = datum && typeof datum == 'string' ? DateTimeConverter.convertStringToMoment(datum, true) : datum;
            });
        }

        if (!this.morph_class) {
            const prototype = Object.getPrototypeOf(this);
            Object.defineProperty(this, 'morph_class', {
                // @ts-ignore
                get: prototype.constructor.getMorphClass,
                enumerable: false,
            });
        }
    }

    static override getMorphClass() {
        return 'model';
    }
}

/**
 * @deprecated
 */
export class Model extends BaseModel {
    customFields!: CustomFieldOld[];
    private _original!: ModelData;

    constructor(data: any = null) {
        super(data);

        // Set original
        this.setOriginal(this);
        this.setCustomFields();

        return new Proxy<this>(this, {
            set: this._set,
            get: this._get,
        });
    }

    valueOf() {
        return this.id ? this.id : 0;
    }

    setOriginal(data: ModelData) {
        const original: ModelData = {};

        Object.entries(data).forEach(([ prop, val ]) => {
            const valOf = val?.valueOf?.();

            if (Array.isArray(valOf)) {
                original[prop] = [ ...val ];
            } else if (typeof valOf == 'object') {
                original[prop] = { ...val };
            } else {
                original[prop] = val;
            }
        });

        // Set original on the model after entries so that
        // original is not included as an entry
        this._original = original;
    }

    setCustomFields() {
        // Get all fields on this
        const thisFields = Object.entries(this).reduce((fields: CustomFieldOld[], [ key, value ]) => {
            if (!key.startsWith('cf_')) {
                return fields;
            }
            return fields.concat(new CustomFieldOld(value));
        }, []);

        // If we find fields on this, then assign and don't do more
        if (thisFields.length) {
            this.customFields = thisFields;
            return;
        }

        // Get all the fields for the model
        const modelFields = keyBy(CustomFieldOld.getModelFields(this.morph_class), 'key');
        Object.keys(modelFields).forEach((key) => {
            const cf = this?.properties?.find((p) => p.key === key) || this?.[key];
            if (!cf) {
                return;
            }

            modelFields[key].value = cf.value;
            modelFields[key].from = cf.from;
            modelFields[key].to = cf.to;
        });

        this.customFields = Object.values(modelFields);
    }

    /**
     * Put the fields on the object
     */
    assignCustomFields() {
        Object.entries(CustomFieldOld.getFieldValues(this)).forEach(([ key, value ]) => {
            if (value == null) {
                return;
            }
            if (typeof value === 'string' && !value.length) {
                return;
            }

            this[key] = value;
        });
    }

    _set(obj, prop, value) {
        obj[prop] = value;
        return true;
    }

    _get(obj, prop) {
        return obj[prop];
    }

    getOriginal() {
        return this._original;
    }

    reset(props) {
        const original = this.getOriginal();
        props.forEach((prop) => {
            this[prop] = original[prop];
        });
    }

    /**
     * @param {string} key
     * @returns {boolean}
     */
    isModified(key) {
        return Object.prototype.hasOwnProperty.call(this.getModified(), key);
    }

    /**
     * Get modified properties of the model
     * @param {String} [property]
     * @return {Object}
     */
    getModified(property = undefined) {
        // Where to put modified
        const modified = {};

        // Properties we ignore
        const ignorable = [ '_original', 'customFields' ];

        // Combined properties
        const props = uniq([ ...Object.keys(this._original), ...Object.keys(this) ]);

        // Reusable func to get valueOf of something that's not nil
        function valOf(val) {
            return val?.valueOf?.() ?? val;
        }

        props.forEach((prop) => {
            // Ignorable properties, skip angular properties like $promise
            if (prop.startsWith('$') || ignorable.includes(prop)) {
                return;
            }

            // Get valueOf both things we want to check
            const original = valOf(this._original[prop]);
            const current = valOf(this[prop]);

            // If valueOf returns an array or object then do the deep equal
            // And if not equal set modified to the model's current value
            if (!isEqual(original, current)) {
                if (original == null && current == null) {
                    return;
                } // Nil value to nil value is not a modification

                // Backend doesn't have undefined, so pass in null instead
                modified[prop] = current === undefined ? null : this[prop];
            }
        });

        return property?.length ? modified[property] : modified;
    }

    /**
     * @param {Array} [keys]
     * @return {*}
     */
    getProperties(keys = []) {
        if (!keys?.length) {
            return this.properties;
        }

        return this.properties.filter((p) => keys.includes(p.key));
    }

    getProperty(key, defaultVal) {
        return this.getProperties()?.find?.((p) => p.key === key)?.value ?? (defaultVal ?? null);
    }

    setProperty(property) {
        const prop = this.getProperties()?.find?.((p) => p.key === property.key);

        if (prop) {
            prop.value = property.value;
        } else {
            this.properties.push(property);
        }
    }

    static setWarningTranslations(item: { warnings: Warning[] }) {
        item.warnings = item.warnings || [];
        item.warnings.forEach((w) => {
            w.i18n = w.message_parameters ? t(`warnings:${w.message}`, w.message_parameters) : t(`warnings:${w.message}`);
        });
    }
}
