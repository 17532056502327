import { hasLoadedNamespace, loadNamespaces, t } from 'i18next';
import { module } from 'angular';
import { sort } from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { Products } from '../../../../../../shared/enums/products';
import { debounce } from 'lodash-es';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';
import { TopStatsDivisionOperation, ScheduleStatisticsService, ScheduleStatisticsTopStatsResponse, TopStatsOperationDataType } from '../../../../../http/schedule-statistics.service';
import { momentToDateTime } from '../../../../../../shared/angularjs/moment-to-date-time';
import { firstValueFrom } from 'rxjs';

export interface ScheduleTopStatsDefaultData {
    budget: string;
    efficiency: string;
    estimatedHours: string;
    worked_hours: string;
    hoursDiff: string;
    color: string;
    pure: {
        budget: number;
        efficiency: number;
        estimatedHours: number;
        worked_hours: number;
        hoursDiff: number;
    };
}

function createRow(el: HTMLTableElement, title: any, extraRow = true) {
    const rowEl = document.createElement('tr');
    rowEl.title = t(title);

    if (extraRow) {
        const rowName = document.createElement('tr');
        const rowNameTd = document.createElement('td');
        const rowNameDiv = document.createElement('div');

        rowNameDiv.textContent = t(title);
        rowNameDiv.style.position = 'sticky';
        rowNameDiv.style.left = '0';
        rowNameDiv.style.display = 'inline-block';
        rowNameDiv.style.padding = '0 5px';
        rowNameDiv.style.fontWeight = '500';

        rowNameTd.colSpan = 99999;
        rowNameTd.style.textAlign = 'left';
        rowNameTd.style.height = '0';

        rowNameTd.appendChild(rowNameDiv);
        rowName.appendChild(rowNameTd);
        el.appendChild(rowName);
    }

    return rowEl;
}

module('eaw.scheduling').directive('scheduleTopStats', [ 'ScheduleStatisticsServiceDowngrade', 'shiftEvents', 'scheduleDays', 'scheduleEfficiency', 'scheduleBudget', function(scheduleStatisticsService: ScheduleStatisticsService, shiftEvents, scheduleDays, scheduleEfficiency, scheduleBudget) {
    return {
        restrict: 'A',
        require: {
            scheduleTab: '^^scheduleTab',
            scheduleView: '^^scheduleView',
        },
        link(scope: any, element: any, _: any, ctrl: any) {
            let loaded = false;
            let intervals: any;
            let generating = false;
            let hasWaitingGeneration = false;
            const el = element[0] as HTMLTableElement;
            const schedule = ctrl.scheduleTab.schedule;
            const scheduleView = ctrl.scheduleView;
            const formatter0 = new Intl.NumberFormat(CurrentOld.languageTag, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
            const formatter2 = new Intl.NumberFormat(CurrentOld.languageTag, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            const formatterPercent = new Intl.NumberFormat(CurrentOld.languageTag, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: 'percent',
            });

            shiftEvents.register.onLoaded(scope, () => {
                loaded = true;
                generate();
            });

            shiftEvents.register.onChange(scope, debounce(generate, 3000));
            scope.$on('topstats:recalc', debounce(generate, 3000));

            void generate(true);

            // / /// End initialization
            function showLoading() {
                const existingRow = el?.querySelector('.top-stats-status');
                if (existingRow) {
                    existingRow.remove();
                }

                const loadingRow = document.createElement('tr');
                const loadingTd = document.createElement('td');
                const loadingSpan = document.createElement('span');
                loadingRow.classList.add('top-stats-status');
                loadingTd.colSpan = 99999;
                loadingTd.classList.add('top-stats-loading');
                loadingSpan.textContent = t('scheduling:LOADING_STATS');
                loadingTd.appendChild(loadingSpan);
                loadingRow.appendChild(loadingTd);
                el?.appendChild(loadingRow);
            }

            function onError() {
                const existingRow = el?.querySelector('.top-stats-status');
                if (existingRow) {
                    existingRow.remove();
                }

                const errorRow = document.createElement('tr');
                const errorTd = document.createElement('td');
                const errorSpan = document.createElement('span');
                errorRow.classList.add('top-stats-status');
                errorTd.colSpan = 99999;
                errorTd.classList.add('top-stats-error');
                errorSpan.textContent = t('ERROR_GET_DATA');
                errorTd.appendChild(errorSpan);
                errorRow.appendChild(errorTd);
                el?.appendChild(errorRow);
            }

            async function generate(loading = false) {
                if (!loaded) {
                    return;
                }

                if (generating) {
                    hasWaitingGeneration = true;
                    return;
                }
                generating = true;
                intervals = scheduleDays.getAllTimesDoubleRender();
                if (loading) {
                    showLoading();
                }
                try {
                    if (CurrentOld.hasProduct(Products.AIBudgeting)) {
                        await generateVlh();
                    } else {
                        await generateDefault();
                    }
                } catch (e) {
                    console.error(e);
                    onError();
                }

                generating = false;
                if (hasWaitingGeneration) {
                    hasWaitingGeneration = false;
                    await generate();
                }
            }

            async function generateVlh() {
                const bucket = schedule.customer.getProperty('ai-budgeting-ml-bucket');
                const from = momentToDateTime(schedule.getFrom());
                const to = momentToDateTime(schedule.getTo().subtract(schedule.settings.interval, 's'));

                if (!from || !to) {
                    return;
                }

                return Promise.all([
                    createDefaultData(),
                    firstValueFrom(scheduleStatisticsService.getTopStats(schedule.customer_id, bucket, schedule.settings.interval, from, to)),
                ]).catch((e) => {
                    console.error(e);
                    onError();
                    return [];
                }).then((response) => {
                    const [ defaultData, stats ] = response;

                    clean();
                    createVlhElements(stats, defaultData);
                });
            }

            async function generateDefault() {
                const resp = await createDefaultData();
                clean();
                createDefaultElements(resp, []);
            }

            /**
             * Join the indexes of multiple arrays in a specified way
             */
            function joinArrays(type: 'sum' | 'multiply', arrays: number[][]) {
                const longestArray = arrays.reduce((max, a) => Math.max(max, a.length), 0);

                return Array.from({ length: longestArray }).map((_, i) => {
                    const values = arrays.map((a) => a[i] || 0);
                    return type === 'sum' ? values.reduce((sum, v) => sum + v, 0) : values.reduce((product, v) => product * v, 1);
                });
            }

            function getSingleVariableType(variables: string[], statsDefinitionData: Record<string, number[]>) {
                const dividendName = variables[0] || '';

                if (!(dividendName in statsDefinitionData)) {
                    throw new Error(`Missing data for ${dividendName}`);
                }

                return statsDefinitionData[dividendName];
            }

            function getSumVariableType(variables: string[], statsDefinitionData: Record<string, number[]>) {
                const variableArrays = variables.map((v) => statsDefinitionData[v] || []);
                return joinArrays('sum', variableArrays);
            }

            function getProductVariableType(variables: string[], statsDefinitionData: Record<string, number[]>) {
                const variableArrays = variables.map((v) => statsDefinitionData[v] || []);
                return joinArrays('multiply', variableArrays);
            }

            function getDefinitionNumbers(type: TopStatsOperationDataType, variables: string[], statsDefinitionData: Record<string, number[]>) {
                switch (type) {
                    case 'single':
                        return getSingleVariableType(variables, statsDefinitionData);
                    case 'sum':
                        return getSumVariableType(variables, statsDefinitionData);
                    case 'product':
                        return getProductVariableType(variables, statsDefinitionData);
                }
            }

            function createDivisionDefinition(name: string, calculation: TopStatsDivisionOperation, statsDefinitionData: Record<string, number[]>) {
                const dividends = getDefinitionNumbers(calculation.dividend.type, calculation.dividend.variables, statsDefinitionData);
                const divisors = getDefinitionNumbers(calculation.divisor.type, calculation.divisor.variables, statsDefinitionData);

                if (!dividends || !divisors) {
                    return;
                }

                const stats = dividends?.map((dividend, index) => {
                    const divisor = divisors?.[index];
                    return divisor ? dividend / divisor : 0;
                }) || [];

                const rowEl = createRow(el, name);
                stats.forEach((value) => {
                    switch (true) {
                        case calculation.result_type === 'percent':
                            rowEl.appendChild(createCell(formatterPercent.format(value)));
                            break;
                        case calculation.decimals === 2:
                            rowEl.appendChild(createCell(formatter2.format(value)));
                            break;
                        default:
                            rowEl.appendChild(createCell(formatter0.format(value)));
                    }
                });

                el.appendChild(rowEl);
            }

            async function createVlhElements(stats: ScheduleStatisticsTopStatsResponse, defaultData: ScheduleTopStatsDefaultData[]) {
                const itemsToShow = new Set<string>(scheduleView.mlBucket?.variables?.filter((v: any) => v.showInStats).map((v: any) => v.code) || []);

                sort(Object.values(stats.variables), CurrentOld.languageTag, [ (x) => x?.name ], [ 'asc' ]).forEach((variable) => {
                    if (!variable || !itemsToShow.has(variable.code)) {
                        return;
                    }

                    const rowEl = createRow(el, variable.name);

                    stats.series?.[variable.uuid]?.forEach((value: any) => {
                        rowEl.appendChild(createCell(formatter0.format(value)));
                    });

                    el.appendChild(rowEl);
                });

                // Construct stats definition data
                const statsDefinitionData: Record<string, number[]> = {};

                // Add API stats to data
                Object.keys(defaultData[0]?.pure || {}).forEach((key) => {
                    statsDefinitionData[`ui|${key}`] = defaultData.map((d) => {
                        if (key in d.pure) {
                            return d.pure[key as keyof ScheduleTopStatsDefaultData['pure']];
                        }

                        return 0;
                    });
                });

                // Add UI stats to data
                Object.values(stats.variables).forEach((variable) => {
                    if (variable?.code) {
                        statsDefinitionData[`stats|${variable.code}`] = stats.series[variable.uuid] || [];
                    }
                });

                // Add "special" API stats to data
                const longestStats = Math.max(...Object.values(statsDefinitionData).map((v) => v.length));
                Object.entries(stats.additional_variables).forEach(([ key, value ]) => {
                    statsDefinitionData[`stats|${key}`] = Array.from({ length: longestStats }).map(() => value);
                });

                for await (const stat of stats.stats_definitions) {
                    if (!itemsToShow.has(stat.id)) {
                        continue;
                    }

                    let name = stat.wtiKey;
                    const [ ns, key ] = stat.wtiKey.split('.');
                    if (ns && key) {
                        if (!hasLoadedNamespace(ns)) {
                            await loadNamespaces(ns);
                        }

                        name = t(`${ns}:${key}`);
                    }

                    if (stat.calculation.operation === 'division') {
                        createDivisionDefinition(name, stat.calculation, statsDefinitionData);
                    }
                }

                // Create custom efficiency for VLH
                vlhEfficiency(stats, defaultData);

                // Create working row|
                const rowEl = createRow(el, 'scheduling:WORKING');
                intervals.forEach((interval: any) => {
                    const from = interval.offset;
                    const to = from + parseInt(interval.interval);

                    rowEl.appendChild(createCell(formatter2.format(calcworked_hours(schedule.shifts, from, to))));
                });

                el.appendChild(rowEl);
            }

            function vlhEfficiency(varResp: ScheduleStatisticsTopStatsResponse, defaultData: ScheduleTopStatsDefaultData[]) {
                const salesVar = Object.values(varResp.variables).find((v) => v?.code === 'sales.suisse.mcd');

                if (salesVar) {
                    const rowEl = createRow(el, 'scheduling:EFFICIENCY');
                    const effs: number[] = [];

                    varResp.series[salesVar.uuid]?.forEach((sale: any, index: number) => {
                        const working = defaultData?.[index]?.pure.worked_hours;
                        effs.push(working ? sale / working : 0);
                    });

                    const highestEff = Math.max(...effs);

                    effs.forEach((e) => {
                        const percentage = e / highestEff;
                        const val = 255 - Math.round(255 * percentage);
                        rowEl.appendChild(createCell(formatter0.format(e), `rgb(${val},255,${val})`));
                    });

                    el.appendChild(rowEl);
                }
            }

            function clean() {
                Array.from(el.children).forEach((c: any) => c.remove());
            }

            function createDefaultElements(data: any, rows: unknown[]) {
                const rowEls = [
                    {
                        id: 'budget',
                        title: 'scheduling:BUDGET',
                        args: [ 'budget' ],
                    },
                    {
                        id: 'efficiency',
                        title: 'scheduling:EFFICIENCY',
                        args: [ 'efficiency' ],
                    },
                    {
                        id: 'estimated',
                        title: 'scheduling:ESTIMATED_NEED',
                        args: [ 'estimatedHours' ],
                    },
                    {
                        id: 'working',
                        title: 'scheduling:WORKING',
                        args: [ 'worked_hours' ],
                    },
                    {
                        id: 'diff',
                        title: 'DIFFERENCE',
                        args: [ 'hoursDiff', 'color' ],
                    },
                ].filter((row) => {
                    if (!rows?.length) {
                        return true;
                    }
                    return rows.includes(row.id);
                }).map((row) => {
                    return {
                        el: createRow(el, row.title, false),
                        ...row,
                    };
                });
                data.forEach((d: any) => {
                    rowEls.forEach((row) => {
                        // @ts-ignore
                        row.el.appendChild(createCell(...row.args.map((a) => d[a])));
                    });
                });
                rowEls.forEach((row) => {
                    el.appendChild(row.el);
                });
            }

            function createCell(value: any, color = 'white') {
                const cellEl = document.createElement('td');
                cellEl.colSpan = scheduleDays.baseSpan;
                cellEl.textContent = value;
                cellEl.style.backgroundColor = color;
                return cellEl;
            }

            function createDefaultData() {
                const data: ScheduleTopStatsDefaultData[] = [];
                const promises = [];
                promises.push(scheduleEfficiency.init());
                promises.push(scheduleBudget.init());

                return Promise.all(promises).then(() => {
                    intervals.forEach((interval: any, index: number) => {
                        const startOffset = interval.offset;
                        const endOffset = startOffset + parseInt(interval.interval);
                        const budget = scheduleBudget.showBudget() ? scheduleBudget.getBudgetAtIndex(index) : 0;
                        const roundedBudget = Math.round(budget);
                        const efficiency = scheduleEfficiency.showEfficiency() ? scheduleEfficiency.findEfficiencyFromBudget(budget) : 0;
                        const estimatedHours = calcEstimatedHours(budget, efficiency);
                        const worked_hours = calcworked_hours(schedule.shifts, startOffset, endOffset);
                        const hoursDiff = roundToHalf(worked_hours - estimatedHours);
                        // Color
                        const colorVal = Math.min(1, estimatedHours ? Math.abs(hoursDiff / estimatedHours) : 0);
                        const hue = ((1 - colorVal) * 120).toString(10);
                        const color = `hsl(${hue},100%,87.5%)`;

                        data.push({
                            budget: formatter0.format(roundedBudget),
                            efficiency: Number.isFinite(efficiency) ? formatter0.format(efficiency) : '-',
                            estimatedHours: Number.isFinite(estimatedHours) ? formatter2.format(estimatedHours) : '-',
                            worked_hours: Number.isFinite(worked_hours) ? formatter2.format(worked_hours) : '-',
                            hoursDiff: hoursDiff > 0 ? `+${formatter2.format(hoursDiff)}` : formatter2.format(hoursDiff),
                            color,
                            pure: {
                                budget: roundedBudget,
                                efficiency,
                                estimatedHours,
                                worked_hours,
                                hoursDiff,
                            },
                        });
                    });

                    return data;
                }).catch((e) => {
                    console.error(e);
                    return [] as ScheduleTopStatsDefaultData[];
                });
            }

            function overlapTime(t1Start: any, t1End: any, t2Start: any, t2End: any) {
                const res = Math.min(t1End, t2End) - Math.max(t1Start, t2Start);
                return res > 0 ? res : 0;
            }

            function calcworked_hours(shifts: any, startOffset: any, endOffset: any) {
                return shifts.reduce((sum: any, s: any) => {
                    // Return sum if not overlapping
                    if (s.offset > endOffset || s.offset + s.length < startOffset) {
                        return sum;
                    }
                    // Add seconds shift overlap the interval
                    sum += overlapTime(startOffset, endOffset, s.offset, s.offset + s.length);
                    // Remove seconds where unproductive periods overlap interval
                    s.periods?.filter((p: any) => p.unproductive).forEach((p: any) => {
                        sum -= overlapTime(startOffset, endOffset, s.offset + p.offset, s.offset + p.offset + p.length);
                    });
                    return sum;
                }, 0) / 3600;
            }

            function calcEstimatedHours(budget: any, efficiency: any) {
                return Number.isFinite(budget / efficiency) ? roundToHalf(budget / efficiency) : 0;
            }

            function roundToHalf(num: any) {
                return (Math.round((num) * 2) / 2);
            }
        },
    };
} ]);
