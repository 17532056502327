import { Inject, Injectable } from '@angular/core';
import { ArrayPaginatedResponse, PaginatedResponse } from '../../shared/interfaces/paginated-response';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { VacationDay, VacationDayResponse } from '../models/vacation-day';
import { map, Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { HttpClient, HttpContext } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { BUSINESS_DATES } from '../../shared/http/http-contexts';

interface GetAllOptions extends PaginationOptions {
    from?: DateTime;
    to?: DateTime;
}

export type VacationDaysGetAllResponse = ArrayPaginatedResponse<VacationDayResponse> & {employee: EmployeeResponse};
export type VacationDaysGetAllReturn = PaginatedResponse<VacationDay> & {employee: Employee};

@Injectable({
    providedIn: 'root',
})
export class VacationDaysService {

    constructor(@Inject(HttpClient) private http: HttpClient) {}

    getAll(customerId: number, employeeId: number, options: GetAllOptions = {}): Observable<VacationDaysGetAllReturn> {
        return this.http.get<VacationDaysGetAllResponse>(`customers/${customerId}/employees/${employeeId}/vacation_days`, {
            params: formatHttpParams(options, [ 'from', 'to' ]),
        }).pipe(
            map((resp) => {
                return {
                    ...resp,
                    employee: new Employee(resp.employee),
                    data: resp.data.map((d) => new VacationDay(d)),
                };
            }),
        );
    }

    create(customerId: number, employeeId: number, delta: number, description: string, effective: DateTime) {
        return this.http.post<VacationDayResponse>(`customers/${customerId}/employees/${employeeId}/vacation_days`, {
            delta,
            description,
            effective,
        }, { context: new HttpContext().set(BUSINESS_DATES, [ 'effective' ]) }).pipe(classifyItem(VacationDay));
    }

    getStats(customerId: number, employeeId: number, year: number) {
        return this.http.get<{vacation_days: number, vacation_days_deducted: number, vacation_days_received: number, vacation_days_carried_over: number}>(`customers/${customerId}/employees/${employeeId}/vacation_days_stats/${year}`)
            .pipe(
                map((response) => {
                    return {
                        days: response.vacation_days,
                        deducted: response.vacation_days_deducted,
                        received: response.vacation_days_received,
                        carriedOver: response.vacation_days_carried_over,
                    };
                }),
            );
    }
}
