import { t } from 'i18next';
import { remove } from 'lodash-es';
import { module } from 'angular';
import { DigitalSignatureService } from '../../../../../digital_signing/digital-signature.service';
import { lastValueFrom } from 'rxjs';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="sdCtrl.header"></eaw-dialog-header-old>

    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="signableForm" id="signableForm" ng-cloak ng-submit="sdCtrl.submit(sdCtrl.model, sdCtrl.provider.class)" autocomplete="off">
                <md-autocomplete
                        md-input-id="model"
                        ng-required="true"
                        ng-disabled="sdCtrl.editing"
                        md-floating-label="{{'MODEL' | i18next}}"
                        md-clear-button="true"
                        md-selected-item="sdCtrl.model"
                        md-require-match="true"
                        md-search-text="modelSearchStr"
                        md-delay="300"
                        md-items="model in sdCtrl.modelSearch(modelSearchStr)"
                        md-item-text="model"
                        md-input-name="modelAutocomplete"
                        md-min-length="0">
                    <md-item-template>
                        <span md-highlight-text="modelSearchStr" md-highlight-flags="gi">{{model}}</span>
                    </md-item-template>
                    <md-not-found>
                        <span ng-i18next="NO_MATCH_FOUND"></span>
                    </md-not-found>
                </md-autocomplete>

                <label for="filters">Filters</label>
                <div id="filters" layout="column">
                    <div layout="row">
                        <md-input-container flex>
                            <label for="filterKeyInput{{$id}}" ng-i18next="KEY"></label>
                            <input type="text" id="filterKeyInput{{$id}}" ng-model="sdCtrl.filterKey">
                        </md-input-container>
                        <md-input-container flex>
                            <label for="filterValInput{{$id}}" ng-i18next="VALUE"></label>
                            <input id="filterValInput{{$id}}" type="text" ng-model="sdCtrl.filterVal">
                        </md-input-container>
                        <md-button class="md-icon-button md-accent" ng-click="sdCtrl.addFilter()">
                            <md-icon ng-bind="'add'"></md-icon>
                        </md-button>
                    </div>

                    <div layout="row" ng-repeat="filter in sdCtrl.filter track by $index">
                        <md-input-container flex>
                            <label for="key{{$id}}" ng-i18next="KEY"></label>
                            <input type="text" id="key{{$id}}" value="{{filter.key}}" disabled>
                        </md-input-container>
                        <md-input-container flex>
                            <label for="val{{$id}}" ng-i18next="VALUE"></label>
                            <input id="val{{$id}}" type="text" value="{{filter.val}}" disabled>
                        </md-input-container>
                        <md-button class="md-icon-button md-warn" ng-click="sdCtrl.removeFilter(filter.key)">
                            <md-icon ng-bind="'remove'"></md-icon>
                        </md-button>
                    </div>
                </div>

                <md-autocomplete
                        md-input-id="provider"
                        ng-required="true"
                        md-floating-label="{{'PROVIDER' | i18next}}"
                        md-clear-button="true"
                        md-selected-item="sdCtrl.provider"
                        md-require-match="true"
                        md-search-text="providerSearchStr"
                        md-delay="300"
                        md-items="provider in sdCtrl.providerSearch(providerSearchStr)"
                        md-item-text="provider.name"
                        md-input-name="providerAutocomplete"
                        md-min-length="0">
                    <md-item-template>
                        <span md-highlight-text="providerSearchStr" md-highlight-flags="gi">{{provider.name}}</span>
                    </md-item-template>
                    <md-not-found>
                        <span ng-i18next="NO_MATCH_FOUND"></span>
                    </md-not-found>
                </md-autocomplete>

                <div layout="column" layout-align="space-around" ng-if="sdCtrl.provider.class">
                    <label for="description" ng-i18next="DESCRIPTION"></label>
                    <p id="description" ng-bind="sdCtrl.provider.description"></p>

                    <label for="class" ng-i18next="CLASS"></label>
                    <p id="class" ng-bind="sdCtrl.provider.class"></p>
                </div>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button form="signableForm" ng-disabled="signableForm.$invalid" type="submit" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>

</md-dialog>
`;
module('eaw.digisign').factory('signableDialog', [ '$mdDialog', 'DigitalSignatureServiceDowngrade', 'signableService', function signableDialog($mdDialog: any, DigitalSignatureServiceDowngrade: DigitalSignatureService, signableService: any) {
    const deletePreset = $mdDialog.confirm()
        .theme('delete')
        .textContent(t('DELETE_QUESTION'))
        .ok(t('DELETE'))
        .cancel(t('CANCEL'));
    function add(customerId: number) {
        return new Promise((resolve, reject) => {
            show(customerId).then((result: any) => {
                signableService.create.query(customerId, result.filter, result.provider, result.model).$promise.then(resolve, reject);
            }, (_: any) => reject('cancel'));
        });
    }
    function update(customerId: number, signable: any) {
        return new Promise((resolve, reject) => {
            show(customerId, signable).then((result: any) => {
                signableService.update.query(customerId, signable.id, result.filter, result.provider).$promise.then(resolve, reject);
            }, (_: any) => reject('cancel'));
        });
    }
    function show(customerId: number, signable?: any) {
        return $mdDialog.show({
            template: template1,
            controllerAs: 'sdCtrl',
            controller: function() {
                const ctrl = this;
                ctrl.$onInit = () => {
                    ctrl.header = signable ? 'Update signable' : 'Add signable';
                    ctrl.cancel = $mdDialog.cancel;
                    ctrl.editing = !!signable;
                    ctrl.model = signable?.model;
                    ctrl.provider = signable?.provider;
                    ctrl.filter = (signable?.filter || []).reduce((arr: any[], [ key, val ]: any) => arr.concat({
                        val,
                        key,
                    }), []);
                };
                ctrl.submit = () => {
                    // Objectify
                    ctrl.filter = ctrl.filter.reduce((obj: any, filter: any) => {
                        obj[filter.key] = filter.val;
                        return obj;
                    }, {});
                    $mdDialog.hide({
                        provider: ctrl.provider.class,
                        filter: ctrl.filter,
                        model: ctrl.model,
                    });
                };
                ctrl.modelSearch = (str: string) => signableService.models.query({
                    filter: str,
                    per_page: 20,
                }).$promise.then((resp: any) => resp.data);

                ctrl.providerSearch = (str: string) => {
                    return lastValueFrom(DigitalSignatureServiceDowngrade.getAvailable(customerId, str));
                };

                ctrl.addFilter = () => {
                    ctrl.filter.push({
                        key: ctrl.filterKey,
                        val: ctrl.filterVal,
                    });
                    ctrl.filter = [ ...ctrl.filter ];
                    ctrl.filterKey = undefined;
                    ctrl.filterVal = undefined;
                };

                ctrl.removeFilter = (key: string) => {
                    remove(ctrl.filter, (f: any) => f.key === key);
                };
            },
        });
    }
    function deleteSignable(customerId: number, signable: any) {
        return new Promise((resolve, reject) => {
            $mdDialog.show(deletePreset).then(() => {
                signableService.delete.query(customerId, signable.id).$promise.then(resolve, reject);
            }, reject);
        });
    }
    return {
        add,
        update,
        delete: deleteSignable,
    };
} ]);
