// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
import { EawChart } from '../../../../shared/angularjs/modules/misc/services/eaw-chart';
module('eaw.dashboard').component('timepunchWidget', {
    template: `<div id="timepunch-widget-chart"></div>

<div id="timepunch-widget-footer" layout="row" layout-align="space-around center">
    <md-button ng-disabled="$ctrl.changing" class="md-fab md-mini" ng-click="$ctrl.change(-1)" md-colors="{'background': $ctrl.widget.headerColor}">
        <md-icon ng-bind="'arrow_back'"></md-icon>
    </md-button>

    <div class="middle" layout="row" layout-align="space-around center">
        <div class="tw-mr-16" layout="column" layout-align="center center">
            <p class="tw-m-0" ng-i18next="widgets:PLANNED"></p>
            <h3 class="tw-m-0">{{$ctrl.planSum | eawNumber:2}}</h3>
        </div>
        <div layout="column" layout-align="center center">
            <p class="tw-m-0" ng-i18next="widgets:PUNCHED"></p>
            <h3 class="tw-m-0">{{$ctrl.punchSum | eawNumber:2}}</h3>
        </div>
    </div>

    <md-button ng-disabled="$ctrl.changing" class="md-fab md-mini" ng-click="$ctrl.change(1)" md-colors="{'background': $ctrl.widget.headerColor}">
        <md-icon ng-bind="'arrow_forward'"></md-icon>
    </md-button>
</div>
`,
    require: {
        eawWidget: '^eawWidget',
    },
    controller: [ 'EawResource', '$filter', function(EawResource, $filter) {
        const ctrl = this;
        ctrl.$postLink = () => {
            // From and to are UTC in this component because fuck you
            ctrl.from = moment().tz('UTC').startOf('w');
            ctrl.to = moment().tz('UTC').endOf('w');
            ctrl.createChart();
        };
        ctrl.$onDestroy = () => {
            ctrl.getSummaryRequest?.$cancelRequest?.();
        };
        ctrl.getChartTitle = () => t('WEEK_AND_YEAR', {
            week: ctrl.from.week(),
            year: ctrl.from.weekYear(),
        });
        ctrl.getSummary = (from, to) => {
            ctrl.getSummaryRequest?.$cancelRequest?.();
            ctrl.eawWidget.setLoading(true);
            ctrl.getSummaryRequest = EawResource.create('/customers/:customer_id/employees/:employee_id/timepunches/summary').get({
                customer_id: ctrl.eawWidget.widget.customer,
                employee_id: ctrl.eawWidget.widget.employee.id,
                from,
                to,
                _businessDates: [ 'from', 'to' ],
            });
            return ctrl.getSummaryRequest.$promise.then((resp) => {
                const [ punched, planned ] = ctrl.makeSeries(resp);
                ctrl.chart.setTitle({ text: ctrl.getChartTitle() });
                ctrl.chart.series[0].setData(planned);
                ctrl.chart.series[1].setData(punched);
            }).finally(() => {
                ctrl.eawWidget.setLoading(false);
            });
        };
        ctrl.createChart = async () => {
            const chartOptions = {
                chart: {
                    type: 'column',
                },
                legend: {
                    enabled: true,
                },
                plotOptions: {
                    column: {
                        grouping: true,
                        pointWidth: 11,
                    },
                },
                tooltip: {
                    shared: true,
                    formatter() {
                        let text = `<strong>${moment(this.x).format('LL')}</strong>`;
                        this.points.forEach((p) => {
                            text += `<br/>${p.series.name}: ${$filter('eawDuration')(p.y * 3600)}`;
                        });
                        return text;
                    },
                },
                xAxis: {
                    type: 'datetime',
                },
                yAxis: {
                    title: 'hours',
                },
                title: {
                    text: ctrl.getChartTitle(),
                },
                series: [ {
                    name: t('widgets:PLANNED'),
                    data: [],
                }, {
                    name: t('widgets:PUNCHED'),
                    data: [],
                } ],
            };
            const chartInstance = new EawChart('timepunch-widget-chart', chartOptions);
            ctrl.chart = await chartInstance.getChart();
            ctrl.getSummary(ctrl.from, ctrl.to);
        };
        ctrl.addTime = (arr, resp, time, type) => {
            arr.push([ time, (resp.data[time / 1000]?.[type] || 0) / 3600 ]);
            return arr;
        };
        ctrl.makeSeries = (resp) => {
            const times = new Array(7).fill(0).map((_, i) => +ctrl.from.clone().add(i, 'd'));
            // @ts-ignore
            ctrl.punchSum = Object.values(resp.data).reduce((sum, val) => sum + val.punched, 0) / 3600;
            // @ts-ignore
            ctrl.planSum = Object.values(resp.data).reduce((sum, val) => sum + val.planned, 0) / 3600;
            return [
                times.reduce((arr, time) => ctrl.addTime(arr, resp, time, 'punched'), []),
                times.reduce((arr, time) => ctrl.addTime(arr, resp, time, 'planned'), []),
            ];
        };
        ctrl.change = (add) => {
            const originalFrom = ctrl.from.clone(); // Original for comparison
            const from = ctrl.from.clone().add(add, 'w').startOf('w'); // Start of next/prev week
            // Check that it's not the same week
            if (from.isSame(originalFrom, 'w')) {
                return;
            }
            ctrl.changing = true;
            ctrl.from = from.clone();
            ctrl.to = from.clone().endOf('w');
            ctrl.getSummary(ctrl.from, ctrl.to).finally(() => ctrl.changing = false);
        };
    } ],
});
