// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { Comment } from '../../../../../../shared/models/comment';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';
module('eaw.payroll.paid-time').component('ptRemoveAbsence', {
    template: `<md-input-container flex="100">
    <label ng-i18next="ABSENCE_TYPE"></label> <input ng-model="$ctrl.typeName" required disabled>
</md-input-container>

<md-subheader ng-if="$ctrl.segment.handledAbsence.comments.length">
    <span ng-i18next="COMMENT_plural"></span>
</md-subheader>
<eaw-comment-list [angularjscomments]="$ctrl.segment.handledAbsence.comments"></eaw-comment-list>

<div ng-if="$ctrl.approved && !$ctrl.canApprove" class="layout layout-align-start-center" md-colors="{color: 'warn'}">
    <md-icon class="tw-ml-0 tw-mr-4">warning</md-icon>
    <span>{{'absences:DELETE_APPROVED_ABSENCE_WARNING' | i18next}}</span>
</div>
`,
    bindings: {
        segment: '<',
        parent: '<',
    },
    controller: [ '$scope', 'Absence', 'ToastService', '$mdDialog', function($scope, Absence, ToastService, $mdDialog) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.canApprove = CurrentOld.can(`customers.${ctrl.segment.employeePaidTime.customer.id}.absences.${ctrl.segment.handledAbsence.id}.approve`);
            ctrl.typeName = ctrl.getAbsenceTypeName(ctrl.segment.handledAbsence.type);
            Absence.get({
                customerId: ctrl.segment.employeePaidTime.customer.id,
                employeeId: ctrl.segment.employeePaidTime.employee.id,
                absenceId: ctrl.segment.handledAbsence.id,
                with: [ 'approval' ],
            }).$promise.then((abs) => (ctrl.approved = abs.approval.approved));
            ctrl.segment.handledAbsence.comments = ctrl.segment.handledAbsence.comments.map((c) => new Comment(c));
            $scope.$on('HandleSomethingCtrl:handle', ctrl.removeAbsence);
        };
        ctrl.getAbsenceTypeName = (absenceType) => {
            const name = absenceType?.name;
            if (!name) {
                return '';
            }

            if (name.toUpperCase() === name) {
                return t('absence_types:' + name);
            }

            return name;
        };
        ctrl.removeAbsence = async () => {
            try {
                await Absence.delete({
                    customerId: ctrl.segment.employeePaidTime.customer.id,
                    employeeId: ctrl.segment.employeePaidTime.employee.id,
                    id: ctrl.segment.handledAbsence.id,
                }).$promise;
                ToastService.toast(t('payroll:ABSENCE_REMOVED'));
                $mdDialog.hide();
            } catch (e) {
                console.error(e);
                ctrl.parent.handling = false;
            }
        };
    } ],
});
