import { HttpContextToken, HttpErrorResponse } from '@angular/common/http';

/**
 * Tells the {@link WalkInterceptor} which properties are should be converted to a business date string for the API.
 */
export const BUSINESS_DATES = new HttpContextToken<string[]>(() => []);

/**
 * Tells the {@link ErrorInterceptor} if some or all errors should be ignored. Not opening an error dialog.
 */
export const IGNORE_ERROR = new HttpContextToken<((error: HttpErrorResponse) => boolean) | number[] | true>(() => []);
