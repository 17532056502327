<eaw-dialog-header class="dialog-header">
    <div title>
        <div class="eaw-dialog-header-title bold-text">
            {{directory.directory_id ? directory.name : ("ROOT" | translate:"filesystem" | async)}}
        </div>
    </div>
    <div subtitle>
        <div class="eaw-dialog-header-subtitle tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-[5px]">
            <span *ngIf="selectedDirectory" [innerHTML]="'MOVING_ITEM_TO' | translate: 'filesystem': { interpolation: { escapeValue: false }, item: getItemName(item), directory: getItemName(selectedDirectory) } | async"></span>
            <span *ngIf="!selectedDirectory" [innerHTML]="'MOVING_ITEM' | translate: 'filesystem': { interpolation: { escapeValue: false }, item: getItemName(item)  } | async"></span>
        </div>
    </div>
</eaw-dialog-header>
<!--        <eaw-mime-icon-upgraded class="tw-mr-12" [size]="24" *ngIf="item.type === 'file'" [type]="item.type"></eaw-mime-icon-upgraded>-->
<!--        <eaw-mime-icon-upgraded class="tw-mr-12" [size]="24" *ngIf="item.type === 'directory'" [type]="item.type"></eaw-mime-icon-upgraded>-->
<div mat-dialog-content class="tw-flex tw-flex-col">
    <mat-spinner *ngIf="gettingDirectory"></mat-spinner>
    <mat-list dense class="directory-element directory pretty-scroll" *ngIf="!gettingDirectory">
        <!--                <eaw-mime-icon-upgraded class="tw-mr-12" [size]="24" [type]="'folder'"></eaw-mime-icon-upgraded>-->
        <mat-list-item
                *ngFor="let dir of directory?.directories; trackBy:trackByFn"
                [class.selected]="selectedDirectory?.id === dir.id"
                [class.disabled-item]="isMoveItem(dir)"
                [class.cursor-pointer]="!isMoveItem(dir)"
                [ngClass]="{'disabled': isMoveItem(dir)}"
                (click)="selectDirectory($any(dir), $event)"
                (dblclick)="getDirectory(dir!.id)">
            <div class="tw-flex tw-flex-row tw-gap-8">
                <mat-icon>folder</mat-icon>
                <span class="list-item-text-size">{{dir.name}}</span>
            </div>
            <span class="tw-flex tw-basis-full"></span>
            <mat-icon
                    *ngIf="!isMoveItem(dir)"
                    (click)="getDirectory(dir.id)">
                chevron_right
            </mat-icon>
        </mat-list-item>
        <ng-container *ngIf="!gettingDirectory">
            <mat-list-item *ngFor="let file of directory?.files; trackBy:trackByFn">
                <div class="list-item-text-size disabled-item" style="margin: 4px; padding: 4px;">
                    <span>{{file.name}}</span>
                </div>
            </mat-list-item>
        </ng-container>
    </mat-list>
</div>
<div mat-dialog-actions class="tw-flex tw-flex-row">
    <button mat-icon-button *ngIf="!gettingDirectory && directory.directory_id"
            (click)="getDirectory(directory.directory_id)">
        <mat-icon>arrow_back</mat-icon>
        <span></span>
    </button>
    <span class="tw-flex tw-basis-full"></span>
    <button mat-raised-button [mat-dialog-close]="true">
        {{"CANCEL" | translate | async}}
    </button>

    <button mat-button color="accent"
            *ngIf="!selectedDirectory"
            [disabled]="isStartDirectory || !selectedDirectory"
            [class.mat-raised-button]="!isStartDirectory && selectedDirectory">
        {{"MOVE" | translate:"filesystem" | async}}
    </button>

    <button mat-raised-button color="accent"
            *ngIf="selectedDirectory"
            (click)="move()">
        {{"MOVE" | translate:"filesystem" | async}}
    </button>
</div>
