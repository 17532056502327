// @ts-nocheck
import { module } from 'angular';
module('eaw.company').factory('PositionsFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const custRes = EawResource.create('/customers/:customer/positions/:position');
    const empRes = EawResource.create('/customers/:customer/employees/:employee/positions/:position');
    /**
     *
     * @param {object}  args
     * @param {int}     args.customer
     */
    fac.getAllForCustomer = (args) => custRes.get({
        customer: args.customer,
        ...Pagination.getParams(args),
    });
    /**
     * Add a new position for customer
     * @param {int}     customer
     * @param {string}  position
     */
    fac.createForCustomer = (customer, position) => custRes.save({
        customer,
    }, {
        name: position,
    });
    /**
     *
     * @param {int}     customer
     * @param {int}     position
     * @param {string}  positionName
     */
    fac.updateForCustomer = (customer, position, positionName) => custRes.update({
        customer,
        position,
    }, {
        name: positionName,
    });
    /**
     *
     * @param {int} customer
     * @param {int} position
     */
    fac.deleteForCustomer = (customer, position) => custRes.delete({
        customer,
        position,
    });
    /**
     *
     * @param {object}  args
     * @param {int}     args.customer
     * @param {int}     args.employee
     */
    fac.getAllForEmployee = (args) => empRes.get({
        customer: args.customer,
        employee: args.employee,
        ...Pagination.getParams(args),
    });
    /**
     *
     * @param {int} customer
     * @param {int} employee
     * @param {int} position
     */
    fac.createForEmployee = (customer, employee, position) => empRes.save({
        customer,
        employee,
    }, {
        position_id: position,
    });
    return fac;
} ]);
