import { ThemePalette } from '@angular/material/core';
import type { HeaderFabButtonMenuItem } from '../page-header.component';
import { Observable } from 'rxjs';
import { Signal } from '@angular/core';

interface HeaderButtonData {
    hasPermission?: (() => Observable<boolean>);
    click?: () => void
    icon: string | (() => string),
    menuText: Signal<Promise<string>>,
    type?: ThemePalette,
    ariaLabel?: string;
    hide?: () => boolean;
    // Marks the button as active, or "toggled on"
    active?: () => boolean;
    disabled?: () => boolean;
    // How much to rotate the icon (degrees)
    rotation?: number;
    menu?: HeaderFabButtonMenuItem[];
}

interface HeaderButtonClickData extends HeaderButtonData {
    click: () => void;
    menu?: never;
}

interface HeaderButtonMenuData extends HeaderButtonData {
    menu: HeaderFabButtonMenuItem[];
    click?: never;
}

export class PageHeaderButtonBase {
    click?: () => void;
    icon: () => string;
    menuText: Signal<Promise<string>>;
    type?: ThemePalette;
    ariaLabel?: string;
    hide?: () => boolean;
    active?: () => boolean;
    disabled?: () => boolean;
    rotation: string;
    menu?: HeaderFabButtonMenuItem[];
    hasPermission?: () => Observable<boolean>;

    constructor(data: HeaderButtonData) {
        this.icon = typeof data.icon === 'string' ? () => data.icon as string : data.icon;
        this.menuText = data.menuText;
        this.type = data.type;
        this.ariaLabel = data.ariaLabel;
        this.hide = data.hide;
        this.active = data.active;
        this.disabled = data.disabled;
        this.rotation = `rotate(${data.rotation || 0}deg)`;
        this.hasPermission = data.hasPermission;
    }
}

export class PageHeaderButton extends PageHeaderButtonBase {
    override menu?: never;
    override click: () => void;

    constructor(data: HeaderButtonClickData) {
        super(data);
        this.click = data.click;
    }
}

export class PageHeaderButtonMenu extends PageHeaderButtonBase {
    override menu: HeaderFabButtonMenuItem[];

    constructor(data: HeaderButtonMenuData) {
        super(data);
        this.menu = data.menu;
    }
}
