import type { FsNodeResponse } from '../interfaces/fs-node-response';
import { DateTime } from 'luxon';
import { Customer } from '../../shared/models/customer';
import { User } from '../../shared/models/user';
import { Directory } from './directory';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export class FsNode {
    id: number;
    type: string;
    name: string;
    customer_id: number;
    user_id?: number | null;
    directory_id?: number;
    updated_at: DateTime | null;
    created_at: DateTime | null;
    deleted_at?: DateTime | null;
    user?: User;
    customer?: Customer;
    directory?: Directory;

    constructor(data: FsNodeResponse) {
        this.id = data.id;
        this.type = data.type;
        this.name = data.name;
        this.customer_id = data.customer_id;
        this.user_id = data.user_id;
        this.directory_id = data.directory_id;
        this.updated_at = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.created_at = data.created_at ? stringToDateTime(data.created_at) : null;
        this.deleted_at = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.user = data.user ? new User(data.user) : undefined;
        this.customer = data.customer ? new Customer(data.customer) : undefined;
        this.directory = data.directory ? new Directory(data.directory) : undefined;
    }
}
