import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AsyncPipe, DecimalPipe, NgClass, NgFor, PercentPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { DataTableRequest } from '../../../data-table/types/data-table';
import { delay, forkJoin, of, switchMap } from 'rxjs';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CurrentService } from '../../../shared/services/current.service';
import { MatDialog, MatDialogContent } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { ProjectionDay, ProjectionDayUpdate } from '../../models/projection-day';
import { DailyDetailsDialogComponent, DailyDetailsDialogData } from '../../dialogs/daily-details-dialog/daily-details-dialog.component';
import { ReferenceDaysDialogComponent, ReferenceDaysDialogData } from '../../dialogs/reference-days-dialog/reference-days-dialog.component';
import { SettingService } from '../../../shared/http/setting.service';
import { ProjectionsService } from '../../http/projections.service';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

type ProjectionDayFormGroupLine = FormGroup<{
    gcScheduleProjection: FormControl<number>,
    atScheduleProjection: FormControl<number>,
    tag: FormControl<string>,
}>;

interface ProjectionDayExtended extends ProjectionDay {
    form?: ProjectionDayFormGroupLine,
    blocked?: boolean;
    holiday?: 'public' | 'school' | 'both',
    holidayTooltip?: string,
    loading?: boolean;
}

@Component({
    selector: 'eaw-mcd-projections',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgFor,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
        ReactiveFormsModule,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        PercentPipe,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        ActionButtonComponent,
        DateTimePipe,
        MatDialogContent,
        MatProgressSpinnerModule,
        DecimalPipe,
        MatTooltipModule,
        NgClass,
        TranslateSyncPipe,
    ],
    templateUrl: './mcd-projections.component.html',
    styleUrl: './mcd-projections.component.scss',
})
export class McdProjectionsComponent implements OnInit {
    @ViewChild('table') table?: DataTableComponent<ProjectionDay>;

    @Input({ required: true }) customerId!: number;

    loading = true;
    fetching = false;
    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<ProjectionDay>[] = [];
    form = new FormGroup({
        dateRange: new FormGroup({
            from: new FormControl<DateTime | null>(null, Validators.required),
            to: new FormControl<DateTime | null>(null, Validators.required),
        }),
    });

    durationRange: { from?: DateTime | null, to?: DateTime | null } = {
        from: null,
        to: null,
    };

    displayedColumns: string[] = [
        'date',
        'dailyDetails',
        'gcAiProjection',
        'gcScheduleProjection',
        'gcDiffYear',
        'atAiProjection',
        'atScheduleProjection',
        'atDiffYear',
        'salesProjection',
        'salesDiffYear',
        'tags',
        'referenceDays',
        'commentsLastYear',
    ];

    dataSource: ProjectionDayExtended[] = [];
    tags: string[] = [];
    today: DateTime = DateTime.now();
    totals = {
        gcAiProjections: 0,
        gcScheduleProjections: 0,
        gcLastYear: 0,
        gcDiffYear: 0,
        salesAiProjections: 0,
        salesScheduleProjections: 0,
        salesLastYear: 0,
        salesDiffYear: 0,
        atDiffYear: 0,
    };

    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(SettingService) private settingService: SettingService,
        @Inject(ProjectionsService) private projectionsService: ProjectionsService,
    ) {
    }

    ngOnInit() {
        this.form.valueChanges.subscribe(this.setDurationRange.bind(this));
        this.getTags();
        this.loading = false;
    }

    setDurationRange() {
        const value = this.form.value;
        const from = value.dateRange?.from;
        const to = value.dateRange?.to;

        if (from == null || to == null) {
            this.durationRange = {};
            return;
        }
        this.durationRange = {
            from,
            to,
        };
    }

    updateTable() {
        if (this.durationRange.from && this.durationRange.to) {
            this.fetching = true;
            const from = this.durationRange.from.toFormat('yyyy-MM-dd');
            const to = this.durationRange.to.toFormat('yyyy-MM-dd');
            const tagsObservable = this.projectionsService.getTags(this.current.getCustomer().id, {
                start: from,
                end: to,
            });
            const commentsObservable = this.projectionsService.getComments(this.current.getCustomer().id, {
                start: from,
                end: to,
            });
            const projectionsObservable = this.projectionsService.getProjections(this.current.getCustomer().id, {
                start: from,
                end: to,
            });
            const holidaysObservable = this.projectionsService.getHolidays(this.current.getCustomer().id, {
                start: from,
                end: to,
            });

            forkJoin([ tagsObservable, commentsObservable, projectionsObservable, holidaysObservable ]).subscribe(([ tagSet, commentsSet, projectionSet, holidaySet ]) => {
                const tags = tagSet.tags;
                const comments = commentsSet.comments;
                const projections: ProjectionDayExtended[] = projectionSet.projections;
                const holidays = holidaySet.holidays || [];
                this.dataSource = projections.map((day) => {
                    day.blocked = day.date.startOf('day') < this.today.startOf('day');
                    const dayHolidays = holidays.filter((holidayDay) => day.date.toFormat('yyyy-MM-dd') == holidayDay.date);
                    if (dayHolidays) {
                        const result = dayHolidays.reduce((acc: 'public' | 'school' | 'both' | null, currentValue) => {
                            if (acc === 'both') {
                                return 'both';
                            }
                            if (acc === null) {
                                return currentValue.type;
                            }
                            if (currentValue.type !== acc) {
                                return 'both';
                            }
                            return acc;
                        }, null);
                        if (result) {
                            day.holiday = result;
                            day.holidayTooltip = result.toUpperCase()+'_HOLIDAY_TOOLTIP';
                        }
                    }
                    const tag = tags.find((tag) => tag.date?.hasSame(day.date, 'day')) || undefined;
                    const comment = comments.find((comment) => comment.date?.hasSame(day.date, 'day')) || undefined;
                    if (tag) {
                        day.tag = tag.name;
                    }
                    if (comment) {
                        day.comment = comment.comment;
                    }
                    day.projections.atGenerated = {
                        value: day.projections.sales?.value && day.projections.transactions?.value
                            ? +(Number(day.projections.sales.value / day.projections.transactions.value).toFixed(2))
                            : 0,
                        percent: 0,
                        time: day.date,
                    };
                    if (day.projections.atEdited) {
                        const transactions = day.projections.transactionsEdited?.value || day.projections.transactions?.value || 0;
                        day.projections.salesGenerated = {
                            value: day.projections.atEdited.value * transactions,
                            percent: 0,
                            time: day.date,
                        };
                    }
                    day.form = this.getFormGroupLine(day);

                    return day;
                });
                this.calculateTotals();
                this.fetching = false;
            });
        }
    }

    updateRow(day: ProjectionDay) {
        const dt = this.dataSource.find((projectionDay) => projectionDay.date?.hasSame(day.date, 'day')) || undefined;
        const projectionsObservable = of(null).pipe(
            delay(2000),
            switchMap(() => this.projectionsService.getProjections(this.current.getCustomer().id, {
                start: day.date.toFormat('yyyy-MM-dd'),
                end: day.date.toFormat('yyyy-MM-dd'),
            })),
        );
        if (dt) {
            dt.loading = true;
            projectionsObservable.subscribe((res) => {
                if (res.projections.length) {
                    res.projections.map((d) => {
                        if (d.date?.hasSame(dt.date, 'day')) {
                            dt.projections = d.projections;
                            dt.percentageVsPrevYear = d.percentageVsPrevYear;
                            dt.chosenReferenceDays = d.chosenReferenceDays;
                            dt.defaultReferenceDays = d.defaultReferenceDays;
                        }
                    });
                    this.calculateTotals();
                }
                dt.loading = false;
            });
        }
    }

    calculateTotals() {
        this.totals = {
            gcAiProjections: 0,
            gcScheduleProjections: 0,
            salesAiProjections: 0,
            salesScheduleProjections: 0,
            gcLastYear: 0,
            salesLastYear: 0,
            gcDiffYear: 0,
            salesDiffYear: 0,
            atDiffYear: 0,
        };
        this.dataSource.map((d) => {
            this.totals.gcAiProjections+= d.projections.transactions?.value || 0;
            this.totals.gcScheduleProjections += d.projections.transactionsEdited?.value || d.projections.transactions?.value || 0;
            this.totals.salesAiProjections += d.projections.sales?.value || 0;
            this.totals.salesScheduleProjections += d.projections.salesGenerated?.value || 0;
            this.totals.gcLastYear += d.projections.transactionsPrevYear?.value || 0;
            this.totals.salesLastYear += d.projections.salesPrevYear?.value || 0;
        });
        this.totals.gcDiffYear = this.totals.gcLastYear > 0 ? this.totals.gcScheduleProjections / this.totals.gcLastYear - 1 : 0;
        this.totals.salesDiffYear = this.totals.salesLastYear > 0 ? this.totals.salesScheduleProjections / this.totals.salesLastYear - 1 : 0;
        this.totals.atDiffYear = this.totals.gcLastYear > 0 && this.totals.gcScheduleProjections > 0
            ? ((this.totals.salesAiProjections / this.totals.gcAiProjections) / (this.totals.salesLastYear / this.totals.gcLastYear)) - 1
            : 0;
    }

    openDetailsDialog(day: ProjectionDay) {
        this.matDialog.open<DailyDetailsDialogComponent, DailyDetailsDialogData>(DailyDetailsDialogComponent, {
            data: {
                customerId: this.current.getCustomer().id,
                projectionDay: day,
            },
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.updateRow(day);
        });
    }

    openReferenceDaysDialog(day: ProjectionDay) {
        this.matDialog.open<ReferenceDaysDialogComponent, ReferenceDaysDialogData>(ReferenceDaysDialogComponent, {
            data: {
                customerId: this.current.getCustomer().id,
                projectionDay: day,
                tags: this.tags,
            },
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.updateRow(day);
        });
    }

    tagChange(date: string, event: MatSelectChange) {
        const day: ProjectionDayExtended | undefined = this.dataSource.find((projectionDay) => projectionDay.date.toFormat('YYYY-MM-DD') == date) || undefined;

        if (event.value) {
            this.projectionsService.setDayTag(this.current.getCustomer().id, date, { tag: event.value }).subscribe(() => {
                if (day) {
                    this.updateRow(day);
                }
            });
        } else {
            this.projectionsService.deleteTag(this.current.getCustomer().id, date).subscribe(() => {
                if (day) {
                    this.updateRow(day);
                }
            });
        }
    }

    getFormGroupLine(config: ProjectionDay): ProjectionDayFormGroupLine {
        return new FormGroup({
            gcScheduleProjection: new FormControl(config.projections.transactionsEdited?.value || config.projections.transactions?.value || 0, {
                validators: [ Validators.required ],
                nonNullable: true,
            }),
            atScheduleProjection: new FormControl(+(config.projections.atEdited?.value || config.projections.atGenerated?.value || 0).toFixed(2), {
                validators: [ Validators.required ],
                nonNullable: true,
            }),
            tag: new FormControl(config.tag || '', {
                validators: [ Validators.required ],
                nonNullable: true,
            }),
        });
    }

    gcScheduleProjectionChange(day: ProjectionDayExtended) {
        this.preparePayload(day);
    }

    atScheduleProjectionChange(day: ProjectionDayExtended) {
        this.preparePayload(day);
    }

    private getTags() {
        this.settingService.getValue<string[] | undefined>([ 'customers', this.current.getCustomer().id ], 'ai-projections.available_tags', [])
            .subscribe((res) => {
                this.tags = res?.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())) ?? [];
            });
    }

    preparePayload(day: ProjectionDayExtended) {
        const response: ProjectionDayUpdate = {
            reference_days: [],
            projections: {},
        };
        if (day.form) {
            // save entered Gc Projection for schedule value
            if (day.form.value.gcScheduleProjection) {
                response.projections['transactions'] = [ {
                    time: day.projections.transactions?.time.toFormat('yyyy-MM-dd HH:mm:ss') || '',
                    value: +day.form.value.gcScheduleProjection,
                    percent: 0,
                } ];
            }

            // if any of 'Projection for schedule' values for Gc or AT changed, generate salesGenerated value...
            const salesGenerated = (day.form.value?.atScheduleProjection || 0) * (day.form.value?.gcScheduleProjection || 0);

            if (day.form.value.atScheduleProjection !== day.projections.atGenerated?.value || day.form.value.gcScheduleProjection !== day.projections.transactions?.value) {
                day.projections.salesGenerated = {
                    time: day.projections.sales?.time || DateTime.now(),
                    value: +(salesGenerated.toFixed(0)),
                    percent: 0,
                };
            }
            // save generated sales value if at scheduled projection changed
            if (day.projections.salesGenerated) {
                response.projections['sales.net'] = [ {
                    time: day.projections.salesGenerated.time.toFormat('yyyy-MM-dd HH:mm:ss'),
                    value: +(salesGenerated.toFixed(0)),
                } ];
            }

            this.projectionsService.updateProjection(this.current.getCustomer().id, day.date.toFormat('yyyy-MM-dd'), response).subscribe(() => {
                this.updateRow(day);
            });
        }
    }

}
