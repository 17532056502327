import { DateTime } from 'luxon';
import { WeatherForecastTime } from './weather-forecast-time';
import { WeatherForecastResponse, WeatherForecastTimeSeries, WeatherForecastUnits } from '../interfaces/weather-forecast';

// Definitions for the weather API, based on https://api.met.no/weatherapi/locationforecast/2.0/swagger

export class WeatherForecast {
    readonly units: WeatherForecastUnits;
    readonly updatedAt: DateTime;
    /** Map date string to symbol and temperature */
    protected readonly times: Map<string, WeatherForecastTime> = new Map();
    protected readonly timeseries: Map<string, WeatherForecastTimeSeries>;

    constructor(forecast: WeatherForecastResponse) {
        const timeseries = forecast.properties.timeseries.filter((x) => x.data.next_6_hours || x.data.next_12_hours || x.data.next_1_hours);
        this.timeseries = new Map(timeseries.map((x): [ string, WeatherForecastTimeSeries ] => [ x.time, x as WeatherForecastTimeSeries ]));
        this.units = forecast.properties.meta.units;
        this.updatedAt = DateTime.fromISO(forecast.properties.meta.updated_at);
        this.times = new Map(timeseries.map((x): [ string, WeatherForecastTime ] => {
            return [ x.time, new WeatherForecastTime(x) ];
        }));
    }

    private getTimeKey(dt: DateTime): string {
        const key = dt.toUTC().startOf('hour').toISO({ suppressMilliseconds: true });
        // Key is null in case of an invalid DateTime
        return key ||'';
    }

    getClosestEntry(dt: DateTime): WeatherForecastTimeSeries | undefined {
        const key = this.getTimeKey(dt);
        if (this.timeseries.has(key)) {
            return this.timeseries.get(key);
        }
        const dateTime = dt.toUTC().startOf('hour');
        const hour = dateTime.hour;

        // Find the closest entry, we have hour 0, 6, 12, 18 for all days
        if (hour <= 3) {
            return this.timeseries.get(this.getTimeKey(dateTime.set({ hour: 0 })));
        }

        if (hour <= 9) {
            return this.timeseries.get(this.getTimeKey(dateTime.set({ hour: 6 })));
        }

        if (hour <= 15) {
            return this.timeseries.get(this.getTimeKey(dateTime.set({ hour: 12 })));
        }

        if (hour <= 21) {
            return this.timeseries.get(this.getTimeKey(dateTime.set({ hour: 18 })));
        }

        return this.timeseries.get(this.getTimeKey(dateTime.set({ hour: 0 }).plus({ days: 1 })));
    }

    forTime(dt?: DateTime, fahrenheit = false): WeatherForecastTime | undefined {
        const time = this.times.get(this.getTimeKey(dt || DateTime.now()));

        // Making the assumption that the response will always be in Celsius
        if (time && fahrenheit) {
            time.convertToFahrenheit();
        }

        return time;
    }
}
