import { ApiResponse } from '../interfaces/api-response';
import { Moment, isMoment } from 'moment-timezone';

/**
 * Convert an old class to an API response, for use when converting AngularJS classes to Angular classes
 * @param oldClass
 * @param businessDates
 */
export function OldClassAsResponse<T extends ApiResponse>(oldClass: object, businessDates: string[] = []): T {
    const resp: Record<string | number, unknown> = { ...oldClass };

    for (const obj in resp) {
        if (isMoment(resp[obj])) {
            resp[obj] = (resp[obj] as Moment).format(businessDates.includes(obj) ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss');
        }
    }

    return resp as T;
}
