import { ArrayPaginatedResponse } from '../app/shared/interfaces/paginated-response';

export function mockArrayPaginatedResponse<T>(data?: T[], paginatedResponse?: Partial<ArrayPaginatedResponse<T>>): ArrayPaginatedResponse<T> {
    return {
        data: data || paginatedResponse?.data || [],
        from: paginatedResponse?.from || 1,
        to: paginatedResponse?.to || 1,
        total: paginatedResponse?.total || 0,
        last_page: paginatedResponse?.last_page || 1,
        current_page: paginatedResponse?.current_page || 1,
        per_page: paginatedResponse?.per_page || '10',
    };
}
