// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { DateTimeConverter } from '../../../../utils/date-time-converter';
module('eaw.calendar').factory('HolidaysFactory', [ 'EawResource', function HolidaysFactory(EawResource) {
    const fac = this;
    /**
     * Get holidays for a customer in a given period of time
     * @param   {Customer}          customer
     * @param   {moment.Moment}     from
     * @param   {moment.Moment}     to
     * @returns {Promise}
     */
    fac.getHolidays = (customer, from, to) => {
        return EawResource.create(`/customers/${customer.id}/holidays`).get({
            from: moment.isMoment(from) ? from.startOf('d') : null,
            to: moment.isMoment(to) ? to.endOf('d') : null,
        }).$promise.then((resp) => {
            resp.data.forEach((holiday) => {
                holiday.date = DateTimeConverter.convertStringToMoment(holiday.date, true);
            });
            return resp;
        });
    };
    /**
     * Get holidays for a customer for a given year
     * @param   {Customer}    customer
     * @param   {int}       [year]
     * @returns {Promise}
     */
    fac.getHolidaysForYear = (customer, year) => {
        const mom = moment(year || new Date().getFullYear(), 'YYYY');
        return fac.getHolidays(customer, mom, mom.clone().endOf('y'));
    };
    return fac;
} ]);
