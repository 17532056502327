// @ts-nocheck
import { module } from 'angular';

module('eaw.admin').controller('payTypeLinkDialogCtrl', [ 'AbsenceTypeFactory', 'ContractTypeFactory', 'TariffFactory', 'PayTypeFactory', '$mdDialog', 'CalculatorFactory', function payTypeLinkDialogCtrl(AbsenceTypeFactory, ContractTypeFactory, TariffFactory, PayTypeFactory, $mdDialog, CalculatorFactory) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.hideCalculator = false;
        ctrl.contract_types = ctrl.pay_type_link.contract_types;
        ctrl.absence_types = ctrl.pay_type_link.absence_types || [];
        ctrl.selectedTariff = ctrl.pay_type_link.tariff;

        ctrl.returnUnits = {
            currency: 'admin:RETURN_UNIT_CURRENCY',
            quantity: 'payroll:QUANTITY',
            seconds: 'SECOND_plural',
            hours: 'HOUR_plural',
            days: 'DAY_plural',
            weeks: 'WEEK_plural',
        };

        if (!ctrl.isUpdating) {
            ctrl.returnUnit = 'currency';
        } else if (ctrl.pay_type_link.returnUnit) {
            ctrl.hideCalculator = true;
        }

        ctrl.paytypeLinkInterval = {
            from: ctrl.pay_type_link?.from,
            to: ctrl.pay_type_link?.to,
        };
    };

    ctrl.getPaytypes = (filter) => PayTypeFactory.getAll({ filter }).$promise.then((resp) => resp.data);

    ctrl.getAbsenceTypes = (filter) => AbsenceTypeFactory.getAll({
        entityId: ctrl.customer.id,
        filter,
        order_by: 'name',
        direction: 'ascending',
    }).then((resp) => resp.data);

    ctrl.getContractTypes = (filter) => ContractTypeFactory.getAllForCustomer({
        filter,
        customer_id: ctrl.customer.id,
    }).$promise.then((resp) => resp.data);

    // tariff
    ctrl.onChangedTariff = (itemId) => {
        ctrl.pay_type_link.tariff_id = itemId;
    };

    ctrl.getTariffs = (filter) => TariffFactory.getAllForCustomer({
        filter,
        fields: [ 'name' ],
        customer_id: ctrl.customer.id,
    }).$promise.then((resp) => resp.data);

    ctrl.onChangedCalculator = (id) => {
        ctrl.pay_type_link.calculator_id = id;
    };

    ctrl.getCalculators = (filter) => CalculatorFactory.getAll({
        filter,
        per_page: 50,
    }).$promise.then((resp) => resp.data);

    ctrl.submit = () => {
        ctrl.pay_type_link.contract_types = ctrl.contract_types?.map((x) => x.id) || [];
        ctrl.pay_type_link.absence_types = ctrl.absence_types?.map((x) => x.id) || [];

        if (!ctrl.pay_type_link.use_employee_rate || ctrl.pay_type_link.employee_rate_ratio == 0) {
            ctrl.pay_type_link.employee_rate_ratio = undefined;
        }

        ctrl.pay_type_link.from = ctrl.paytypeLinkInterval.from;
        ctrl.pay_type_link.to = ctrl.paytypeLinkInterval.to;

        if (ctrl.hideCalculator) {
            ctrl.pay_type_link.calculator_id = undefined;
            ctrl.pay_type_link.return_unit = ctrl.returnUnit;
        } else {
            ctrl.pay_type_link.return_unit = undefined;
        }

        $mdDialog.hide(ctrl.pay_type_link);
    };
} ]);
