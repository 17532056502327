import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Customer } from '../../../../../shared/models/customer';
import { catchError, EMPTY, of, switchMap } from 'rxjs';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { EmployeeService } from '../../../../../shared/http/employee.service';
import { User } from '../../../../../shared/models/user';
import { Employee } from '../../../../../shared/models/employee';
import { SettingService } from '../../../../../shared/http/setting.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { DateTime } from 'luxon';
import { MatDividerModule } from '@angular/material/divider';
import { DatePickerOptionsDirective } from '../../../../../shared/directives/date-picker-options.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EawValidators } from '../../../../../shared/validators/eaw-validators';
import { NewEmployeeNextStepComponent } from '../../components/new-employee-next-step/new-employee-next-step.component';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';
import { CustomerUser } from '../../../../../shared/models/customer-user';
import { FieldErrorComponent } from '../../../../../shared/components/field-error/field-error.component';

type BadgeNumberGeneration = 'automatic' | 'manual';

type EmployeeForm = {
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    from: FormControl<DateTime | null>;
    to: FormControl<DateTime | null>;
    generateAutomatically: FormControl<boolean>;
    customNumber: FormControl<number | null>;
}

@Component({
    selector: 'eaw-new-employee-create-employee',
    standalone: true,
    imports: [ CommonModule, ReactiveFormsModule, MatFormFieldModule, MatOptionModule, MatSelectModule, TranslatePipe, MatRadioModule, MatInputModule, ActionButtonComponent, MatDividerModule, DatePickerOptionsDirective, MatDatepickerModule, NewEmployeeNextStepComponent, FieldErrorComponent ],
    templateUrl: './new-employee-create-employee.component.html',
    styleUrl: './new-employee-create-employee.component.scss',
})
export class NewEmployeeCreateEmployeeComponent extends NewEmployeeStepComponent implements OnInit {
    employeeService = inject(EmployeeService);
    settingService = inject(SettingService);

    @Input({ required: true }) customer!: Customer;
    @Input() user?: User;
    @Input() customerUser?: CustomerUser;

    @Output() employeeChange = new EventEmitter<Employee>();

    loading = true;
    processing = false;
    minBadgeNumber = 1;
    maxBadgeNumber = 0;
    form: FormGroup<EmployeeForm>;

    constructor() {
        super();

        this.form = new FormGroup<EmployeeForm>({
            firstName: new FormControl(null, [ Validators.required ]),
            lastName: new FormControl(null, [ Validators.required ]),
            from: new FormControl(null, [ Validators.required ]),
            to: new FormControl(null),
            generateAutomatically: new FormControl(true, { nonNullable: true }),
            customNumber: new FormControl(null),
        });
    }

    override ngOnInit() {
        super.ngOnInit();

        this.form.patchValue({
            firstName: this.user?.firstName,
            lastName: this.user?.lastName,
            from: this.customerUser?.from,
            to: this.customerUser?.to,
        });

        this.form.controls.customNumber.setAsyncValidators(EawValidators.asyncBadgeNumber(this.customer.id, this.employeeService));
        this.form.controls.customNumber.markAsTouched();

        this.form.controls.generateAutomatically.valueChanges.subscribe((generateAutomatically) => {
            const customNumber = this.form.controls.customNumber;

            if (generateAutomatically) {
                customNumber.disable();
                customNumber.removeValidators(Validators.required);
            } else {
                customNumber.enable();
                customNumber.addValidators(Validators.required);
            }
        });

        this.settingService.getSome([ 'customers', this.customer.id ], {
            'settings[]': [ 'badge_number_limit', 'newemployee.default_badge_generation' ],
        }).pipe(
            catchError(() => EMPTY),
        ).subscribe((settings) => {
            const numberLimit = settings.find((setting) => setting.key === 'badge_number_limit')?.value?.asInteger() ?? 10;
            const generation = settings.find((setting) => setting.key === 'newemployee.default_badge_generation')?.value?.asString<BadgeNumberGeneration>();

            this.maxBadgeNumber = parseInt('9'.repeat(numberLimit));
            this.form.controls.customNumber.addValidators([
                Validators.min(1),
                Validators.max(this.maxBadgeNumber),
            ]);

            switch (generation) {
                case 'manual':
                    this.form.controls.generateAutomatically.setValue(false);
                    break;
                case 'automatic':
                default:
                    this.form.controls.generateAutomatically.setValue(true);
                    break;
            }

            this.loading = false;
        });
    }

    create() {
        const form = this.form.value;

        this.form.disable();
        this.processing = true;

        this.getNumber().pipe(
            switchMap((number) => {
                return this.employeeService.create(this.customer.id, {
                    number,
                    user_id: this.user?.id,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    first_name: form.firstName!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    last_name: form.lastName!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    from: form.from!,
                    to: form.to,
                }).pipe(
                    catchError(() => {
                        this.processing = false;
                        this.form.enable();
                        return EMPTY;
                    }),
                );
            }),
        ).subscribe((employee) => {
            this.employeeChange.emit(employee);
            this.processing = false;
            this.step.completed = true;
            this.stepper.next();
        });
    }

    private getNumber() {
        if (this.form.value.generateAutomatically) {
            return this.employeeService.getNextAvailableNumber(this.customer.id).pipe(
                catchError(() => {
                    this.processing = false;
                    this.form.enable();
                    return EMPTY;
                }),
            );
        }

        return of(this.form.getRawValue().customNumber || 0);
    }
}
