// @ts-nocheck
import { uniqueId } from '../../modules/misc/services/easy-funcs.service';
import { module } from 'angular';
const template1 = `<input id="{{$upload.id}}"
       class="display-none"
       type="file"
       accept="image/*"
       ng-disabled="$upload.disabled"
       ngf-multiple="$upload.multiple"
       ngf-select="$upload.attach()"
       ng-model="$upload.images">

<md-button class="md-icon-button" ng-disabled="$upload.disabled" ng-click="$upload.click()">
    <md-icon ng-bind="'image'"></md-icon>
    <md-tooltip>{{$upload.toolTip | i18next}}</md-tooltip>
</md-button>
`;

module('eaw.components').component('uploadImage', {
    template: template1,
    bindings: {
        attachTo: '<?',
        toolTip: '@?',
        multiple: '<?',
        onAttach: '&', // onAttach(image, attachTo);
    },
    controllerAs: '$upload',
    controller: [ '$element', '$attrs', function($element, $attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            $attrs.$observe('disabled', (value) => {
                ctrl.disabled = value;
            });
            ctrl.el = $element[0];
            ctrl.multiple = !!ctrl.multiple;
            ctrl.id = uniqueId('upload-image-');
            ctrl.toolTip = ctrl.toolTip || 'UPLOAD_IMAGE';
            ctrl.classes = ctrl.classes || 'hover-item md-button c-blue-grey';
        };

        ctrl.click = () => {
            ctrl.el.querySelector(`#${ctrl.id}`)?.click();
        };

        ctrl.attach = () => {
            if (ctrl.images instanceof File) {
                ctrl.images = [ ctrl.images ];
            }

            ctrl.images?.forEach?.((img) => {
                ctrl.onAttach?.({
                    image: img,
                    attachTo: ctrl.attachTo,
                });
            });
        };
    } ],
});
