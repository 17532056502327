// @ts-nocheck
import { module } from 'angular';

const template1 = `<div layout="column" layout-align="start stretch">
    <eaw-custom-field
            ng-repeat="cf in $ctrl.customFields track by cf.key"
            ng-model="cf"
            ng-change="$ctrl.change()"
            ng-disabled="$ctrl.disabled"
            ng-required="cf.required">
    </eaw-custom-field>
</div>`;

module('eaw.components').component('eawCustomFields', {
    template: template1,
    require: {
        ngModel: 'ngModel', // customFields array
    },
    controller: [ '$attrs', function($attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.ngModel.$formatters.unshift(ctrl.formatter);

            $attrs.$observe('disabled', (value) => {
                ctrl.disabled = value;
            });
        };

        ctrl.formatter = (val) => {
            ctrl.customFields = val || [];
            return ctrl.customFields;
        };

        ctrl.change = () => {
            ctrl.ngModel.setViewValue(ctrl.customFields);
        };
    } ],
});
