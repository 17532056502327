// @ts-nocheck
import { forEach } from 'lodash-es';

import moment from 'moment-timezone';

import { module } from 'angular';
import { t } from 'i18next';

const template1 = `<div layout="column" layout-padding flex>
    <auto-start-day flex="70" ng-model="day.time" ng-change="$amCtrl.onChange(day, $index)" label="{{day.name}}" ng-repeat="day in $amCtrl.month track by $index"></auto-start-day>
</div>
`;

module('eaw.time').component('autoStartMonth', {
    controller: 'AutoStartMonthCtrl',
    controllerAs: '$amCtrl',
    template: template1,
    require: {
        'ngModel': 'ngModel',
    },
});
// TODO: merge this and auto-start week into "auto start days", since they're almost exactly the same except for length of arrays
module('eaw.time').controller('AutoStartMonthCtrl', function AutoStartMonthCtrl() {
    const ctrl = this;
    const _month = new Array(31).fill(null); // zero will be made one

    ctrl.$onInit = () => {
        const defaultTime = moment().hours(6).minutes(0);
        const monthStart = moment().startOf('y');

        ctrl.month = [];

        for (let i = 0; i < 31; i++) {
            ctrl.month.push({
                time: defaultTime.clone(),
                name: t('DAY_OF_MONTH', { day: monthStart.format('Do') }),
                start: !i,
                moment: monthStart.clone(),
            });
            monthStart.add(1, 'd');
        }

        ctrl.ngModel.$formatters.unshift((modelValue) => {
            if (!Array.isArray(modelValue)) {
                return _month;
            }

            const len = _month.length - modelValue.length;
            if (len > 0) {
                return modelValue.slice(0, len - Math.min(len, modelValue.length));
            }

            if (len < 0) { // shorter array passed in
                return [].concat(modelValue, new Array(Math.abs(len)).fill(null));
            }

            return modelValue;
        });

        ctrl.ngModel.$formatters.push((modelValue) => {
            if (!Array.isArray(modelValue)) {
                return _month;
            }

            forEach(ctrl.month, (day, k) => {
                if (typeof modelValue[k] === 'string') {
                    day.time = moment(modelValue[k], 'HH:mm');
                }
            });

        });
    };

    ctrl.showChanged = (day, index) => {
        ctrl.onChange(day.show ? day : {}, index);
    };

    ctrl.onChange = (day, index) => {
        _month[index] = day.time ? day.time.format('HH:mm') : null;
        ctrl.ngModel.setViewValue(_month);
    };
});
