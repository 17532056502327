<div *ngIf="tos" [innerHTML]="tos.text"></div>

<div class="tw-flex tw-items-center tw-justify-center tw-my-20">
    <mat-progress-spinner mode="indeterminate" *ngIf="!tos"></mat-progress-spinner>
</div>

<eaw-info-box *ngIf="hasAccepted" color="green" icon="done">
    <span infoText>{{'TOS_ACCEPTED_TEXT' | translate:'tasks' | async}}</span>
</eaw-info-box>

<div class="tw-flex tw-gap-8 tw-mt-16" *ngIf="tos && !hasAccepted">
    <button mat-button (click)="declineTos()" [disabled]="accepting || hasAccepted">
        {{ 'DECLINE_TOS' | translate: 'tasks' | async }}
    </button>
    <eaw-action-button [disabled]="hasAccepted" [loading]="accepting" (click)="acceptTos()">
        {{ 'ACCEPT_TOS' | translate: 'tasks' | async }}
    </eaw-action-button>
</div>
