import { Component, inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { PermissionSetService } from '../../../shared/http/permission-set.service';
import { PermissionSet } from '../../../shared/models/permission-set';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AutocompleteDeprecatedComponent } from '../../../shared/components/autocomplete-deprecated/autocomplete-deprecated.component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-add-permission-set-dialog',
    templateUrl: './add-permission-set-dialog.component.html',
    styleUrl: './add-permission-set-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        AutocompleteDeprecatedComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AddPermissionSetDialogComponent extends DialogComponent<DialogData, PermissionSet, AddPermissionSetDialogComponent> {
    private readonly service = inject(PermissionSetService);

    /**
     * Permission set control
     */
    permissionSet = new FormControl(null, Validators.required);
    /**
     * Form group for use with auto-complete
     */
    formGroup = new FormGroup({ permissionSet: this.permissionSet });
    /**
     * Data for autocomplete
     */
    permissionData = this.service.getAll({ pagination: { per_page: 50 } });

    constructor() {
        super(undefined, { size: DialogSize.Medium });
    }

    // Called when user types, update filter search for auto-complete
    onSearch(filter?: string) {
        this.permissionData = this.service.getAll({
            pagination: {
                filter,
                per_page: 50,
            },
        });
    }

    /**
     * Called when user clicks submit, closes dialog with chosen permission set
     */
    submit() {
        if (this.permissionSet.value) {
            this.dialogRef.close(this.permissionSet.value);
        }
    }
}
