// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="'calendar:SUBSCRIBE' | i18next"></eaw-dialog-header-old>

    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="subscribeForm">
                <md-autocomplete
                        md-selected-item="ctrl.calendar"
                        md-search-text="filter"
                        md-min-length="0"
                        md-delay="300"
                        md-no-cache="true"
                        md-floating-label="{{'calendar:CALENDAR' | i18next}}"
                        required
                        md-menu-class="autocomplete-custom-template"
                        md-items="calendar in ctrl.getCalendars(filter)"
                        md-item-text="calendar.name">
                    <md-item-template>
                        <span class="autocomplete-item-title" md-highlight-text="filter" md-highlight-flags="gi">{{calendar.name}}</span>
                        <span class="autocomplete-item-metadata" md-highlight-text="filter" md-highlight-flags="gi">{{calendar.description}}</span>
                    </md-item-template>
                    <md-not-found>
                        <span ng-i18next="NO_MATCH_FOUND"></span>
                    </md-not-found>
                </md-autocomplete>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
        <md-button ng-disabled="subscribeForm.$invalid"
                   form="newCalendarForm"
                   ng-click="ctrl.subscribe()">
            <span ng-i18next="calendar:SUBSCRIBE"></span>
        </md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.calendar2').service('SubscribeCalendarDialog', [ '$mdDialog', function CreateCalendarDialog($mdDialog) {
    const service = this;

    /**
     * @param {number} customer
     * @return {*}
     */
    service.open = (customer) => $mdDialog.show({
        template: template1,
        controller: 'subscribeCalendarDialogCtrl',
        controllerAs: 'ctrl',
        bindToController: true,
        locals: {
            customer,
        },
    });

    return service;
} ]);
