<eaw-dialog-header>
    <span title>{{ form.name }}</span>
    <span subtitle>
        @if (submission.anonymous) {
            {{ 'ANONYMOUS' | translate |async }}
        } @else {
            {{ submission.user?.name }}
        }
        {{ '@' }} {{ submission.createdAt | DateTime: 'DATETIME_MED' }}
    </span>
</eaw-dialog-header>
<mat-dialog-content>
    @for (node of form.children; track node.id) {
        <eaw-show-form-node [node]="node" [submission]="submission"></eaw-show-form-node>
    }
</mat-dialog-content>
<mat-dialog-actions class="tw-flex tw-flex-row" [class.tw-justify-between]="canAnonymize">
    @if (canAnonymize) {
        <eaw-action-button warnType (click)="anonymize()" [loading]="anonymizing">
            {{ 'ANONYMIZE' | translate | async }}
        </eaw-action-button>
    }

    <button mat-raised-button type="button" [mat-dialog-close]="{anonymize: false}">
        {{ 'CLOSE' | translate | async }}
    </button>
</mat-dialog-actions>
