import { ChangeDetectionStrategy, Component, Inject, input, OnInit, signal } from '@angular/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { ExtendedWidgetInfo } from '../../../classes/extended-widget-info';
import { Widget } from '../../../classes/widget';
import { Alert } from '../../../../shared/models/alert';
import { CurrentService } from '../../../../shared/services/current.service';
import { TranslateService } from '../../../../shared/services/translate.service';
import { AlertsService } from '../../../../shared/http/alerts.service';
import { DateTimePipe } from '../../../../shared/pipes/date-time.pipe';
import { expandAllPages } from '../../../../shared/utils/rxjs/expand-all-pages';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'eaw-mini-employee-alerts-widget-extended-info',
    standalone: true,
    imports: [
        NgFor,
        MatListModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        NgIf,
        MatButtonModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
    ],
    templateUrl: './mini-employee-alerts-widget-extended-info.component.html',
    styleUrl: './mini-employee-alerts-widget-extended-info.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniEmployeeAlertsWidgetExtendedInfoComponent extends ExtendedWidgetInfo implements OnInit {
    widget = input.required<Widget>();
    alerts = signal<Alert[]>([]);

    constructor(
        @Inject(AlertsService) protected alertsService: AlertsService,
        @Inject(CurrentService) protected current: CurrentService,
        @Inject(TranslateService) protected translate: TranslateService,
    ) {
        super();
        this.setLoading(true);
    }

    ngOnInit() {
        this.getAlerts();
    }

    getAlerts() {
        this.setLoading(true);
        expandAllPages((pagination) => this.alertsService.getAlerts(this.current.getCustomer().id, pagination), {
            per_page: 50,
        }).subscribe((alerts) => {
            this.setLoading(false);
            this.alerts.set(alerts);
            this.setLoading(false);
        });
    }

    deleteAlert(alert_id: number) {
        this.alertsService.deleteAlert(this.current.getCustomer().id, alert_id).subscribe(() => {
            this.getAlerts();
        });
    }
}
