import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { OffTime, OffTimeResponse } from '../models/off-time';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { DateTimeConverter } from '../../shared/utils/date-time-converter';
import { HttpClient, HttpContext } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';

interface GetAllOptions extends PaginationOptions {
    from?: DateTime;
    to?: DateTime;
    handled?: 0 | 1 | null;
    approved?: 0 | 1 | null;
    exclude_declined?: boolean;
    only_direct_subordinates?: boolean;
}

interface CreateOptions {
    from: DateTime;
    to: DateTime;
    approved?: boolean;
    vacation?: boolean;
    days?: number;
    comment?: string | null;
    action?: 'keep' | 'release' | 'delete';
    schedule_published_warning_accepted?: boolean;
}

interface UpdateOptions {
    from?: DateTime;
    to?: DateTime;
    days?: number;
    vacation?: boolean;
    approved?: boolean;
    action?: 'keep' | 'release' | 'delete';
    exclude_declined?: boolean;
    comment?: string;
    with?: string[];
    schedule_published_warning_accepted?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class OffTimeService {

    constructor(@Inject(HttpClient) private http: HttpClient) {}

    get(customerId: number, employeeId: number, offTimeId: number, withs?: string[]): Observable<OffTime> {
        return this.http.get<OffTimeResponse>(`customers/${customerId}/employees/${employeeId}/off_times/${offTimeId}`, {
            params: formatHttpParams({
                'with[]': withs,
            }),
        }).pipe(classifyItem(OffTime));
    }

    getAll(customerId: number, employeeId: number, options: GetAllOptions = {}): Observable<ArrayPaginatedResponse<OffTime>> {
        return this.http.get<ArrayPaginatedResponse<OffTimeResponse>>(`customers/${customerId}/employees/${employeeId}/off_times`, {
            params: formatHttpParams({
                ...options,
                from: DateTimeConverter.convertDateTimeToUtcString(options.from),
                to: DateTimeConverter.convertDateTimeToUtcString(options.to),
            }),
        }).pipe(classifyArrayPaginatedResponse(OffTime));
    }

    getAllForCustomer(customerId: number, options: GetAllOptions = {}): Observable<ArrayPaginatedResponse<OffTime>> {
        return this.http.get<ArrayPaginatedResponse<OffTimeResponse>>(`customers/${customerId}/off_times`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(OffTime));
    }

    create(customerId: number, employeeId: number, options: CreateOptions, context?: HttpContext): Observable<OffTime> {
        return this.http.post<OffTimeResponse>(
            `customers/${customerId}/employees/${employeeId}/off_times`,
            {
                schedule_published_warning_accepted: true,
                ...options,
                from: DateTimeConverter.convertDateTimeToUtcString(options.from),
                to: DateTimeConverter.convertDateTimeToUtcString(options.to),
            },
            { context },
        ).pipe(classifyItem(OffTime));
    }

    update(customerId: number, employeeId: number, offTimeId: number, options: UpdateOptions, context?: HttpContext) {
        return this.http.put<OffTimeResponse>(
            `customers/${customerId}/employees/${employeeId}/off_times/${offTimeId}`,
            {
                schedule_published_warning_accepted: true,
                ...options,
                from: DateTimeConverter.convertDateTimeToUtcString(options.from),
                to: DateTimeConverter.convertDateTimeToUtcString(options.to),
            },
            { context },
        ).pipe(classifyItem(OffTime));
    }

    delete(customerId: number, employeeId: number, offtimeId: number): Observable<undefined> {
        return this.http.delete<undefined>(`customers/${customerId}/employees/${employeeId}/off_times/${offtimeId}`);
    }
}
