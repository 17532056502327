// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-input-container flex="{{$ctrl.inputFlex}}">
    <eaw-time-picker-old
            base-date="$ctrl.baseDate"
            ng-required="$ctrl.fromRequired"
            label="$ctrl.fromLabel"
            is-disabled="$ctrl.fromDisabled"
            uniq-form-name
            min-time="$ctrl.minDate"
            max-time="$ctrl.maxDate"
            ng-change="$ctrl.update()"
            ng-model="$ctrl.from">
    </eaw-time-picker-old>
</md-input-container>

<md-input-container flex="{{$ctrl.inputFlex}}">
    <eaw-time-picker-old
            use-parent="true"
            base-date="$ctrl.baseDate"
            ng-required="$ctrl.toRequired"
            label="$ctrl.toLabel"
            is-disabled="$ctrl.toDisabled"
            uniq-form-name
            min-time="$ctrl.minDate"
            max-time="$ctrl.maxDate"
            ng-change="$ctrl.update()"
            ng-model="$ctrl.to">
    </eaw-time-picker-old>
</md-input-container>
`;

module('eaw.time').component('eawTimeInterval', {
    template: template1,
    bindings: {
        baseDate: '<?', // This just passes through to the time pickers
        fromLabel: '<?', // i18n string
        toLabel: '<?', // i18n string
        fromDisabled: '<?',
        toDisabled: '<?',
        fromRequired: '<?',
        toRequired: '<?',
        inputFlex: '<?',
        minDate: '<?',
        maxDate: '<?',
    },
    require: {
        ngModel: 'ngModel',
    },
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {
            // Don't use i18next in this component cause it is used in the timepicker component
            ctrl.inputFlex = ctrl.inputFlex || 100;
            ctrl.fromLabel = ctrl.fromLabel || 'FROM';
            ctrl.toLabel = ctrl.toLabel || 'TO';

            ctrl.ngModel.$formatters.unshift(ctrl.formatter);
        };

        ctrl.setFromTo = (model) => {
            ctrl.from = model?.from?.clone();
            ctrl.to = model?.to?.clone();
        };

        ctrl.formatter = (model) => {
            ctrl.setFromTo(model);
            return model;
        };

        ctrl.update = () => {
            if (moment.isMoment(ctrl.from) && moment.isMoment(ctrl.to) && ctrl.to.isBefore(ctrl.from)) {
                ctrl.to.add(1, 'd');
            }

            ctrl.ngModel.setViewValue({
                from: ctrl.from,
                to: ctrl.to,
            });
        };
    },
});
