// @ts-nocheck
import { module } from 'angular';
module('eaw.staffing').factory('staffingTemplatePropertiesFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    fac.res = EawResource.create('/customers/:customer/vs_templates/:template/properties/:property');
    fac.getAll = {
        permission(customerId, templateId) {
            return `customers.${customerId}.vs_templates.${templateId}.properties.*.get`;
        },
        query(customerId, templateId, args) {
            return fac.res.get({
                ...Pagination.getParams(args),
                customer: customerId,
                template: templateId,
            });
        },
    };
    fac.get = {
        permission(customerId, templateId, propertyKey) {
            return `customers.${customerId}.vs_templates.${templateId}.properties.${propertyKey}.get`;
        },
        query(customerId, templateId, propertyKey) {
            return fac.res.get({
                customer: customerId,
                template: templateId,
                property: propertyKey,
            });
        },
    };
    fac.update = {
        permission(customerId, templateId, propertyKey) {
            return `customers.${customerId}.vs_templates.${templateId}.properties.${propertyKey}.update`;
        },
        query(customerId, templateId, propertyKey, value) {
            return fac.res.update({
                customer: customerId,
                template: templateId,
                property: propertyKey,
            }, {
                value,
            });
        },
    };
    fac.delete = {
        permission(customerId, templateId, propertyKey) {
            return `customers.${customerId}.vs_templates.${templateId}.properties.${propertyKey}.delete`;
        },
        query(customerId, templateId, propertyKey) {
            return fac.res.delete({
                customer: customerId,
                template: templateId,
                property: propertyKey,
            });
        },
    };
    return fac;
} ]);
