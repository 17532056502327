<table mat-table matSort (matSortChange)="sortColumn($event)" [trackBy]="trackBy" recycleRows [dataSource]="rows">
    <!-- Checkbox header, cell, footer -->
    <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
                    *ngIf="filter?.approval !== 1 && hasApprovable"
                    eawCheckboxHelper
                    (ngModelChange)="masterCheckboxChange()"
                    [checked]="isAllChecked()"
                    [(ngModel)]="masterCheckboxSelected"
                    [indeterminate]="isIndeterminate()">
            </mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let item">
            <div class="checkbox-cell">
                <ng-container *ngIf="item.data.approvable">
                    <!-- Checkbox for "parent" when at least one child can be approved -->
                    <mat-checkbox *ngIf="item.aggregated"
                                  eawCheckboxHelper
                                  (helperChange)="parentCheckboxChange(item, $event)"
                                  [checked]="parentCheckboxChecked(item)"
                                  [indeterminate]="parentCheckboxIndeterminate(item)">
                    </mat-checkbox>

                    <!-- Child checkbox -->
                    <mat-checkbox *ngIf="!item.aggregated"
                                  class="tw-ml-8"
                                  [(ngModel)]="item.checked"
                                  eawCheckboxHelper>
                    </mat-checkbox>
                </ng-container>

                <mat-spinner mode="indeterminate" diameter="20" strokeWidth="2" *ngIf="item.loading"></mat-spinner>

                <mat-icon [class.tw-ml-20]="item.aggregated && !item.data.approvable" *ngIf="!item.loading && item.aggregated && isAggregatedView && item.data.ids.length">
                    {{item.expanded ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </div>
        </td>

        <td mat-footer-cell *matFooterCellDef>
            <!-- No content for checkbox footer -->
        </td>
    </ng-container>

    <!-- All other header, cell, footer -->
    <ng-container *ngFor="let column of columnsData" [matColumnDef]="column.key">
        <th mat-header-cell mat-sort-header *matHeaderCellDef [class]="column.type" [arrowPosition]="column.type === 'integer' || column.type === 'decimal' || column.type === 'hours' || column.type === 'days' ? 'before' : 'after'">
            <span> {{column.i18n | translate:column.ns | async}} </span>
        </th>

        <td mat-cell *matCellDef="let item" [class]="column.type" class="mat-body-1">
            <eaw-cell-value [column]="column" [row]="item" [settings]="settings" (buttonClick)="handleCellButtonClick($event)"></eaw-cell-value>
        </td>

        <td mat-footer-cell *matFooterCellDef [class]="column.type">
            <eaw-cell-total [customerId]="customerId" [column]="column" [rows]="rows" [aggregated]="isAggregatedView"></eaw-cell-total>
        </td>
    </ng-container>

    <!-- Balances header, cell, footer -->
    <ng-container *ngFor="let balance of balanceData" [matColumnDef]="balance.key">
        <th mat-header-cell *matHeaderCellDef class="hours" mat-sort-header arrowPosition="before">
            <span> {{balance.i18n | translate: balance.ns | async}} </span>
        </th>

        <td mat-cell *matCellDef="let item" class="hours mat-body-1">
            <span *ngIf="item.data.balances?.[balance.key] !== undefined">{{item.data.balances?.[balance.key] | duration:['hours', 'minutes']}}</span>
        </td>

        <td mat-footer-cell *matFooterCellDef class="hours">
            <strong>
                <span *ngIf="totals[balance.key] !== undefined">{{totals[balance.key] | duration:['hours', 'minutes']}}</span>
            </strong>
        </td>
    </ng-container>

    <!-- Buttons header, cell, footer -->
    <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef>
            <div class="tw-flex tw-flex-row tw-justify-center tw-items-center">
                <button id="columns-btn" mat-raised-button color="primary" (click)="adjustColumnsClick.emit()">
                    <mat-icon [eawSize]="'20px'">edit</mat-icon>
                    <span>{{'COLUMNS' | translate | async}}</span>
                </button>
            </div>
        </th>

        <td mat-cell *matCellDef="let item">
            <ng-container *ngIf="(!item.aggregated || !isAggregatedView) && item.data.ids[0]">
                <div class="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-8">
                    <button mat-icon-button
                            [matTooltip]="('INFORMATION' | translate | async) || ''"
                            (click)="showHistory(item.data.employeeId, item.data.ids[0])">
                        <mat-icon>info</mat-icon>
                    </button>

                    <button mat-icon-button
                            [disabled]="!item.data.canUpdate || item.data.deleted"
                            [matTooltip]="('EDIT' | translate | async) || ''"
                            (click)="editTimepunch(item.data.employeeId, item.data.ids[0])">
                        <mat-icon>edit</mat-icon>
                    </button>

                    <button mat-icon-button
                            color="warn"
                            [disabled]="!item.data.canDelete || item.data.deleted"
                            [matTooltip]="('DELETE' | translate | async) || ''"
                            (click)="deleteTimepunch(item.data.ids[0])">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </ng-container>
        </td>

        <td mat-footer-cell *matFooterCellDef>
            <!-- No content for buttons footer -->
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsKeys; sticky: true"></tr>
    <tr mat-row
        *matRowDef="let item; columns: columnsKeys;"
        [class.deleted-item]="item.data.deleted"
        [class.aggregated]="item.aggregated && isAggregatedView"
        [class.expanded]="item.aggregated && item.expanded && isAggregatedView"
        [class.display-none]="!(item.aggregated || item.expanded)"
        (click)="rowClick.emit(item)">
    </tr>
    <tr mat-footer-row *matFooterRowDef="columnsKeys; sticky: true"></tr>
</table>
