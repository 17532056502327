import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SystemAlertResponse } from '../models/system-alert';

@Injectable({
    providedIn: 'root',
})
/**
 * Used for internal alert for the user instead of system alerts
 */
export class UserAlertService {
    private readonly subject = new Subject<SystemAlertResponse>();

    addAlert(alert: SystemAlertResponse) {
        this.subject.next(alert);
    }

    onAlert() {
        return this.subject.asObservable();
    }
}
