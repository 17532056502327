import { ApiResponse } from '../../shared/interfaces/api-response';
import { BusinessDateString } from '../../shared/types/business-date';
import { BusinessDate } from '../../shared/utils/business-date';
import { BaseApiModel } from '../../shared/models/base-api-model';

export interface TimepunchBlockResponse extends ApiResponse {
    display_reason: boolean,
    employee_id: number,
    end_date: BusinessDateString | null,
    id: number,
    reason: string | null,
    start_date: BusinessDateString,
}

export class TimepunchBlock extends BaseApiModel<TimepunchBlockResponse, TimepunchBlock> {
    displayReason: boolean;
    employeeId: number;
    endDate: BusinessDate | null;
    id: number;
    reason: string | null;
    startDate: BusinessDate;

    constructor(data: TimepunchBlockResponse) {
        super(data, undefined);

        this.displayReason = data.display_reason;
        this.employeeId = data.employee_id;
        this.endDate = data.end_date ? new BusinessDate(data.end_date) : null;
        this.id = data.id;
        this.reason = data.reason;
        this.startDate = new BusinessDate(data.start_date);
    }
}
