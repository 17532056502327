import { Component, Inject, OnInit } from '@angular/core';
import { EawMaterialColorHue, MaterialColor, MaterialColorHue, MaterialColorService } from '../../services/material-color.service';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { TinyColor } from '@ctrl/tinycolor';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '../../services/translate.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { MaterialColorDirective } from '../../directives/material-color.directive';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

export type ColorpickerDialogResult = TinyColor | null;

export interface ColorpickerDialogData extends DialogData {
    color?: TinyColor | null;
    title?: Promise<string>;
}

@Component({
    selector: 'eaw-colorpicker-dialog',
    templateUrl: './colorpicker-dialog.component.html',
    styleUrl: './colorpicker-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MaterialColorDirective,
        MatDialogContent,
        MatSliderModule,
        ReactiveFormsModule,
        NgFor,
        MatRippleModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ColorpickerDialogComponent extends DialogComponent implements OnInit {
    slider = new FormControl<number>(0, { nonNullable: true });
    steps: number;
    colors: MaterialColor[] = [];
    selectedColor?: MaterialColor;
    selectedColorHue?: MaterialColorHue;
    title: Promise<string>;

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ColorpickerDialogComponent, ColorpickerDialogResult>,
        @Inject(MAT_DIALOG_DATA) override data: ColorpickerDialogData,
        @Inject(MaterialColorService) private materialColorService: MaterialColorService,
        @Inject(TranslateService) private translate: TranslateService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        this.steps = this.materialColorService.getHueKeys().length - 1;
        this.title = data.title || this.translate.t('COLOR');
    }

    ngOnInit() {
        void this.initColors();

        this.slider.valueChanges.subscribe(async () => {
            const fallbackColor = (await this.materialColorService.getAll()).find((c) => c.name === 'white');

            if (this.selectedColor) {
                this.changeColor(this.selectedColor);
            } else if (fallbackColor) {
                this.changeColor(fallbackColor);
            }
        });
    }

    get sliderHueKey() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.materialColorService.getHueKeys()[this.slider.value]!;
    }

    getDisplayColor(color?: MaterialColor | MaterialColorHue): EawMaterialColorHue {
        if (color == null) {
            return 'white-50';
        }

        if ('hue' in color) {
            return color.fullName;
        }
        if ('hues' in color) {
            return `${color.name}-${this.sliderHueKey}`;
        }

        return 'white-50';
    }

    async initColors() {
        this.colors = await this.materialColorService.getAll();
        this.selectedColorHue = await this.materialColorService.get(this.data.color);
        this.selectedColor = this.colors.find((c) => c.name === this.selectedColorHue?.name) || this.colors.find((c) => c.name === 'white');

        this.slider.patchValue(this.selectedColorHue?.index || 3);
    }

    colorEnter(event: Event) {
        event.target?.dispatchEvent(new Event('click'));
    }

    changeColor(color: MaterialColor) {
        const hue = this.sliderHueKey;
        this.selectedColor = color;
        this.selectedColorHue = color.hues.find((h) => h.hue === hue);
    }

    formatSliderLabel = (value: number) => {
        return `${this.materialColorService.getHueKeys()[value]}`;
    };

    choose() {
        this.dialogRef.close(this.selectedColorHue?.tiny);
    }
}
