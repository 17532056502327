import { DestroyRef } from '@angular/core';

export class AngularJsDestroyRef extends DestroyRef {
    private callback = () => {
    };

    destroy() {
        this.callback();
    }

    override onDestroy(callback: () => void) {
        this.callback = callback;
        return () => {
        };
    }
}
