// @ts-nocheck
import { module } from 'angular';
/**
 * @name chainScheduleOverviewFactory
 */
module('eaw.chain-ops').factory('chainScheduleOverviewFactory', [ 'EawResource', function(EawResource) {
    const res = EawResource.create('/customer_groups/:group/schedules/overview');
    /**
     * @memberOf chainScheduleOverviewFactory
     * @param groupId
     * @param {string} interval
     * @param {moment} from
     * @param {moment} to
     * @param page
     * @param per_page
     * @return {*}
     */
    this.getOverview = (groupId, interval, from, to, page, per_page) => res.get({
        group: groupId,
        interval,
        from,
        to,
        page,
        per_page,
    });
    return this;
} ]);
