import { PermissionCheckService } from '../../shared/services/permission-check.service';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

export function myBalancesAccessPermissionCheck(permissionCheckService: PermissionCheckService, customerId: number, employeeId?: number): Observable<boolean> {
    return permissionCheckService.isAllowedMany([
        `customers.${customerId}.balance_types.*.get`,
        `customers.${customerId}.employees.${employeeId}.balances.*.*.get`,
    ]).pipe(
        switchMap((getAll) => {
            if (getAll) {
                return of(true);
            }

            return forkJoin([
                permissionCheckService.permissionChildrenSingle(`customers.${customerId}.employees.${employeeId}.balances`, '*.get', true),
                permissionCheckService.permissionChildrenSingle(`customers.${customerId}.balance_types`, 'get', true),
            ]).pipe(
                map(([ children1, children2 ]) => {
                    return !!children1?.length && children1.every((c) => c.length) && !!children2?.length && Object.values(children2).every((c) => c.length);
                }),
            );
        }),
    );
}
