import { module } from 'angular';
import { Products } from '../../shared/enums/products';
import { ReportsPageComponent } from '../pages/reports-page/reports-page.component';
import { createState } from '../../shared/utils/create-state';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { forkJoin, map } from 'rxjs';
import { LearningModuleModule } from '../../learning-module/types/modules';
import { DataTableQueryParams } from '../../data-table/data-table.component';
import { CurrentService } from '../../shared/services/current.service';

module('eaw.reports', [ 'eaw.resource', 'eaw.misc' ]).config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: 'eaw/app/reports',
        parent: 'eaw/app',
        url: '/reports',
        views: {
            'content@': {
                component: ReportsPageComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('stackId', 'customer', 'stackId'),
        ],
        data: {
            queryParams: [ ...DataTableQueryParams, 'report' ],
            products: [ Products.Reports ],
            learningModuleModules: [ LearningModuleModule.Reports ],
            permissionCheck: (permissionCheckService, injector) => {
                const customerId = injector.get(CurrentService).getCustomer().id;

                return forkJoin([
                    permissionCheckService.permissionChildrenSingle(`customers.${customerId}.reports`, 'get', true).pipe(map((permissions) => !!permissions?.length)),
                    permissionCheckService.isAllowed(`customers.${customerId}.reports.*.get`),
                ]).pipe(
                    map(([ children, all ]) => children || all),
                );
            },
            breadcrumb: { key: 'REPORTS', ns: 'navigation' },
        },
    });
} ]);
