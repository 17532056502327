import { EnvironmentInjector } from '@angular/core';
import { EmployeeSidenavService } from '../services/employee-sidenav.service';
import { EmployeeSidenavHeaderComponent } from '../components/employee-sidenav-header/employee-sidenav-header.component';
import { paramResolver } from '../resolvers/param.resolver';
import { sidenavHeaderInputsResolver } from '../resolvers/sidenav-header-inputs.resolver';

export function addAdminEmployeeInfoSidenav(injector: EnvironmentInjector) {
    injector.get(EmployeeSidenavService).init({
        sidenavParentState: `eaw/app/admin/customers/view/employees`,
        sidenavState: `eaw/app/admin/customers/view/employees/view`,
        url: '/:emp',
        headerComponent: EmployeeSidenavHeaderComponent,
        data: {
            breadcrumb: { key: 'EMPLOYEE' },
            permissions: [ 'customers.{customer}.employees.{emp}.get' ],
        },
        resolve: [
            paramResolver('id', 'customerId'),
            paramResolver('emp', 'employeeId'),
            sidenavHeaderInputsResolver([ 'employeeId', 'customerId' ], function(employeeId: any, customerId: any) {
                return [
                    { key: 'employeeId', value: parseInt(employeeId) },
                    { key: 'customerId', value: parseInt(customerId) },
                ];
            }),
        ],
    }, 'customer', 'emp');
}
