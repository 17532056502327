// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
/**
 * @param {object[]} cells contains a list of objects with template (an angular expression) or a templateUrl
 * @param {object[]} [columns] contains a list of columns with name, title, class and sortable (which field it sorts the data by).
 * @param {function} [tableRowClick] (item) a function called when a row in the table is clicked.
 * @param {string} [factory] the name of the factory to inject @deprecated
 * @param {string} [factoryFunction] the name of the function to use from the factory @deprecated
 * @param {object}[factoryFunctionArguments={}] @deprecated
 * @param {object} [pagination={}] defaults for pagination, like page, per_page, order_by, direction
 * @param {function} [getData=undefined] custom getData function, returns a promise, getData(params, pagination)
 * @param {string} [searchText=undefined] @deprecated
 * @param {boolean} [reloadTable=false] if the table should be reloaded.
 * @param {function} [tableRowClick]with parameter 'item'.
 * @param {function} [rowClass]with parameter 'item'. // Return array of classes
 * @param {int} [openBtnIndex=columns.length] where the "open" button goes in the row
 * @param {string} [templateUrl='shared/directives/eaw-dynamic-ngtable.ajs.html'].
 * @param {object} [scopeVars={}] pass in additional variables or functions for use in templates.
 */
module('eaw.table').component('eawDynamicNgTable', {
    template: `<script type="text/ng-template" id="dynamicNgTableHeader">
    <tr>
        <th title="{{$column.headerTitle(this)}}"
            ng-repeat="$column in $columns track by $index"
            ng-class="{'sortable': $column.sortable(this)}"
            ng-click=" $column.sortable && $column.sortable() ? params.sorting($column.sortable(), params.isSortBy($column.sortable(), 'asc') ? 'desc' : 'asc') : angular.noop()"
            ng-if="$column.show(this)"
            ng-init="template = $column.headerTemplateURL(this)"
            class="header {{$column.class(this)}}">
            <div ng-if="!template" class="ng-table-header">
                <span>{{$column.title(this)}}</span>
                <md-icon ng-if="$column.sortable(this) && params.sorting()[$column.sortable(this)] == 'asc'" ng-bind="'sort'"></md-icon>
                <md-icon ng-if="$column.sortable(this) && params.sorting()[$column.sortable(this)] == 'desc'" ng-bind="'sort'"></md-icon>
            </div>
            <div ng-if="template" ng-include="template"></div>
        </th>
    </tr>
</script>

<script type="text/ng-template" id="dynamicNgTablePagination">
    <div class="ng-table-pager" layout="column" layout-align="center center" layout-gt-xs="row" layout-align-gt-xs="space-between center">
        <div ng-class="{'invisible': !pages.length}" class="ng-table-pagination">
            <md-button ng-repeat="page in pages track by $index"
                       ng-switch="page.type"
                       class="pagination-button"
                       ng-class="{'disabled': !page.active && !page.current}"
                       ng-click="params.page(page.number)"
                       md-colors="{background: page.current ? 'primary' : 'white'}">
                        <span ng-switch-when="prev|more|next" ng-switch-when-separator="|" href="">
                            <md-icon ng-if="page.type == 'prev'" ng-bind="'chevron_left'"></md-icon>
                            <md-icon ng-if="page.type == 'more'" ng-bind="'more_horiz'"></md-icon>
                            <md-icon ng-if="page.type == 'next'" ng-bind="'chevron_right'"></md-icon>
                        </span>
                <span ng-switch-when="page|first|last" ng-switch-when-separator="|" href="" ng-bind="page.number"></span>
            </md-button>
        </div>

        <div ng-show="params.settings().counts.length">
            <md-button class="pagination-button"
                       aria-label="{{::count}}"
                       ng-repeat="count in params.settings().counts track by $index"
                       ng-click="params.count(count)"
                       md-colors="{background: params.count() == count ? 'primary' : 'white'}">
                <span ng-bind="count"></span>
            </md-button>
        </div>
    </div>
</script>

<table class="table table-vmiddle table-condensed table-striped"
       ng-table-dynamic="eawTable.tableParams with eawTable.columns"
       template-pagination="dynamicNgTablePagination"
       template-header="dynamicNgTableHeader">
    <!-- ONE tbody and ONE tr is the MINIMUM markup required for ng-table to work -->
    <tbody>
    <tr></tr>
    </tbody>
</table>

<div id="no-data" ng-if="eawTable.noData">
    <md-icon ng-bind="'storage'"></md-icon>
    <span class="md-subhead" bo-i18next="NO_DATA"></span>
</div>
`,
    controllerAs: 'eawTable',
    bindings: {
        // passed to ng-table-dynamic
        columns: '<',
        cells: '<',
        rowClass: '&?',
        // @deprecated
        factory: '@?',
        markInactive: '<?',
        markDeleted: '<?',
        // @deprecated
        factoryFunction: '@?',
        getData: '&?',
        // @deprecated
        factoryFunctionArguments: '=?',
        pagination: '<?',
        searchText: '=?',
        reloadTable: '=?',
        reRender: '=?',
        skipCard: '<?',
        goTo: '@?',
        tableRowClick: '&?',
        scopeVars: '<?', // pass in additional variables or functions for use in templates.
    },
    controller: [ '$scope', '$parse', '$element', 'componentCreatorService', 'IconElement', 'colorpickerService', 'NgTableParams', 'Pagination', '$state', '$injector', function($scope, $parse, $element, componentCreatorService, IconElement, colorpickerService, NgTableParams, Pagination, $state, $injector) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.el = $element[0];
            $scope.scopeVars = ctrl.scopeVars;
            if (!(ctrl.pagination instanceof Pagination)) {
                ctrl.pagination = new Pagination(ctrl.pagination);
            }
            // Add helpful class to parent(s)
            ctrl.el.parentElement?.classList.add('dynamic-ng-table-container');
            if (!ctrl.skipCard) {
                ctrl.el.closest('md-card')?.classList.add('table-card');
            }
            ctrl.tableParams = new NgTableParams(ctrl.pagination.toNgTableParams(), { getData });
            $scope.$watch('eawTable.reloadTable', (val) => {
                if (!val) {
                    return;
                }
                ctrl.reloadTable = false;
                ctrl.tableParams.page('1');
                ctrl.tableParams.reload();
            });
            $scope.$watch('eawTable.reRender', (val) => {
                if (!val) {
                    return;
                }
                ctrl.reRender = false;
                ctrl.applyData();
            });
            ctrl.updateCellsAndColumns();
        };
        ctrl.$onChanges = (changes) => {
            $scope.scopeVars = changes.scopeVars?.currentValue || $scope.scopeVars;
            if (changes.columns || changes.cells) {
                ctrl.updateCellsAndColumns();
            }
        };
        ctrl.$onDestroy = () => {
            ctrl.tableResource?.$cancelRequest?.();
        };
        ctrl.updateCellsAndColumns = () => {
            // Cell classes
            ctrl.cells?.forEach(ctrl.setCellClasses);
            // Save "normal" cells
            ctrl.bigCells = { ...ctrl.cells };
            // Check if show
            ctrl.columns?.forEach((c) => {
                if (!c) {
                    return;
                }
                c.show = c.show ?? true;
            });
            ctrl.applyData();
        };
        ctrl.setCellClasses = (cell) => {
            cell.classes = cell.class ? cell.class.split(' ') : cell.classes;
        };
        // Add classes from input and default hover class
        ctrl.tableRowClasses = (item, rowEl) => {
            const now = moment();
            let tableRowClasses = [].concat(ctrl.rowClass?.({ item })).toString().trim().split(' ');
            // Check if table should mark inactive entries.
            // Mark inactive if now is after to or before from
            if (ctrl.markInactive && (now.isAfter(item.to, 's') || now.isAfter(item?.pivot?.to, 's') || now.isBefore(item.from, 's') || now.isBefore(item?.pivot?.from, 's'))) {
                tableRowClasses.push('inactive');
            }
            if (ctrl.markDeleted && item.deleted_at) {
                tableRowClasses.push('deleted');
            }
            // Remove empty
            tableRowClasses = tableRowClasses.filter((c) => c.length);
            if (tableRowClasses.length) {
                rowEl.classList.add(...tableRowClasses);
            }
        };
        function resolveFactoryFunction(ctrl, $injector, pagination) {
            if (ctrl.factory) {
                const factory = $injector.get(ctrl.factory);
                const facFunc = factory[ctrl.factoryFunction];
                // Check if it's an object with a query function, or just a normal function
                if (Object.prototype.hasOwnProperty.call(facFunc, 'query')) {
                    // Call the query method if it's an object
                    // "query" is the default used and must not be changed
                    return facFunc.query(pagination);
                }
                // Use function normally
                return facFunc.bind(factory)(pagination);
            }
            return null;
        }
        ctrl.showLoading = () => {
            delete ctrl.noData;
            ctrl.el.appendChild(componentCreatorService.create({
                name: 'mdProgressCircular',
                scope: $scope,
            }).element);
        };
        ctrl.hideLoading = () => {
            Array.from(ctrl.el.querySelectorAll('md-progress-circular')).forEach((el) => el.remove());
        };
        function getDataFrom(resp) {
            if (resp == null) {
                console.error('Did you forget to return the response?', resp);
                return;
            }
            if (Array.isArray(resp)) {
                return resp;
            }
            // If resp.data is an object then make it an array
            if (typeof resp.data === 'object' && resp.data != null) {
                return Object.values(resp.data);
            }
            return resp?.data || [];
        }
        function getData(ngTableParams) {
            ctrl.tableResource?.$cancelRequest?.();
            ctrl.showLoading();
            const pagination = Pagination.fromNgTableParams(ngTableParams);
            pagination.filter = ctrl.searchText;
            // Add additional parameters that's required in order to get the data
            if (ctrl.factoryFunctionArguments) {
                Object.assign(pagination, ctrl.factoryFunctionArguments);
            }
            let result = resolveFactoryFunction(ctrl, $injector, pagination);
            if (!result && ctrl.getData) {
                result = ctrl.getData({
                    params: ngTableParams,
                    pagination,
                });
            }
            // If the result is a resource, keep it and use it to cancel later if possible
            if (result?.$promise) {
                ctrl.tableResource = result;
            }
            return (result?.$promise || result)?.then((resp) => {
                ctrl.hideLoading();
                ctrl.data = getDataFrom(resp);
                if (resp.total) {
                    ngTableParams.total(resp.total);
                }
                ctrl.noData = !ctrl.data?.length;
                ctrl.applyData();
            }).catch(console.error);
        }
        ctrl.applyData = () => {
            ctrl.cleanupRows();
            ctrl.data?.forEach((item, index) => {
                ctrl.generateRow(item, index, ctrl.data);
            });
        };
        ctrl.checkTableRowClick = (tr, item) => {
            if (typeof ctrl.tableRowClick === 'function') {
                ctrl.el.querySelector('table')?.classList.add('table-hover');
                tr.classList.add('hover-item');
                tr.addEventListener('click', (e) => {
                    ctrl.tableRowClick({ item }, e);
                });
            }
        };
        ctrl.generateGoToUrlParams = (item) => {
            const pieces = ctrl.goTo.split('|');
            const url = item.route || pieces[0];
            let params = pieces.slice(1, pieces.length);
            if (params.length) {
                params = params.reduce((obj, param) => {
                    const [ routeKey, itemKey ] = param.split('=');
                    // @ts-ignore
                    obj[routeKey] = item[itemKey || routeKey];
                    return obj;
                }, {});
            } else {
                params = item;
            }
            return [ url, params ];
        };
        ctrl.goToClick = (e, url, params) => {
            if (e.ctrlKey) {
                window.open($state.href(url, params, '_blank'));
            } else {
                $state.transitionTo(url, params);
            }
        };
        ctrl.goToAuxclick = (e, url, params) => {
            if (e.button === 1) { // Middle button
                window.open($state.href(url, params, '_blank'));
            }
        };
        ctrl.goToMousedown = (e) => {
            // Stop default if clicking middle mouse in a table row
            if (e.button === 1) {
                e.preventDefault();
            }
        };
        ctrl.checkGoTo = (tr, item) => {
            if (!ctrl.goTo?.length) {
                return;
            }
            ctrl.el.querySelector('table')?.classList.add('table-hover');
            const [ url, params ] = ctrl.generateGoToUrlParams(item);
            tr.classList.add('hover-item');
            tr.addEventListener('click', (e) => ctrl.goToClick(e, url, params));
            tr.addEventListener('auxclick', (e) => ctrl.goToAuxclick(e, url, params));
            tr.addEventListener('mousedown', ctrl.goToMousedown);
        };
        ctrl.cleanupRows = () => {
            if (!ctrl.el) {
                return;
            }
            Array.from(ctrl.el.querySelector('tbody').children).forEach((c) => c.remove());
        };
        ctrl.generateRow = async (item, index, data) => {
            const tr = document.createElement('tr');
            tr.dataset.gaItem = 'Dynamic table row';
            ctrl.checkTableRowClick(tr, item);
            ctrl.checkGoTo(tr, item);
            ctrl.tableRowClasses(item, tr);
            let i = 0;
            for await (const cell of ctrl.cells) {
                if (!ctrl.columns[i].show) {
                    continue;
                }
                let td = document.createElement('td');
                if (cell.classes?.length) {
                    td.classList.add(...cell.classes);
                }
                // Apply styles
                cell.styles?.forEach(([ key, value ]) => {
                    td.style[key] = value;
                });
                if (cell.text) {
                    td.textContent = cell.text(item, index, data) ?? '';
                }
                if (cell.template) {
                    td.textContent = $parse(cell.template)({ item }) ?? '';
                }
                if (cell.templateUrl) {
                    td = ctrl.genTemplateUrlCell(td, cell, item);
                }
                if (cell.buttons) {
                    td = ctrl.genButtonsCell(td, cell, item);
                }
                if (cell.approval) {
                    td = await ctrl.genApprovalCell(td, item);
                }
                if (cell.boolean) {
                    td = await ctrl.genBooleanCell(td, cell, item);
                }
                tr.appendChild(td);
                i++;
            }
            ctrl.el.querySelector('tbody').appendChild(tr);
        };
        ctrl.genTemplateUrlCell = (el, cell, item) => {
            const scope = $scope.$new();
            scope.item = item;
            const comp = componentCreatorService.create({
                name: 'ngInclude',
                scope,
                bindings: {
                    src: [ `'${cell.templateUrl}'`, 'string' ],
                },
                keepScope: true,
            });
            el.appendChild(comp.element);
            return el;
        };
        ctrl.genButtonsCell = (el, cell, item) => {
            const div = document.createElement('div');
            div.classList.add('buttons-container');
            cell.buttons.forEach((b) => {
                if (b.displayNone?.(item)) {
                    return;
                }
                const comp = componentCreatorService.create({
                    name: 'eawIconButton',
                    scope: $scope,
                    bindings: {
                        text: b.text?.(item),
                        tooltip: b.tooltip,
                        icon: [ b.icon, 'string' ],
                        type: [ b.type, 'string' ],
                        ngDisabled: b.disabled,
                    },
                });
                comp.element.dataset.gaItem = `Dynamic table button (${b.icon})`;
                if (b.invisible?.(item)) {
                    comp.element.classList.add('invisible');
                }
                if (typeof b.click === 'function') {
                    comp.element.addEventListener('click', (e) => {
                        e.stopPropagation();
                        b.click(item, e);
                    });
                }
                div.appendChild(comp.element);
            });
            el.appendChild(div);
            return el;
        };
        ctrl.genApprovalCell = async (el, item) => {
            let color = 'orange';
            let icon = 'remove';
            if (item.approved || item.approval?.approved) {
                color = 'green';
                icon = 'done';
            } else if (item.approval && !item.approval.approved) {
                color = 'red';
                icon = 'clear';
            }
            const element = await IconElement.get(icon);
            element.style.fill = colorpickerService.getHex(`${color}-500`);
            el.classList.add('text-center');
            el.appendChild(element);
            return el;
        };
        ctrl.genBooleanCell = async (el, cell, item) => {
            const bool = item[cell.boolean];
            let color = 'red';
            let icon = 'close';
            if (bool) {
                color = 'green';
                icon = 'done';
            }
            const element = await IconElement.get(icon);
            element.style.fill = colorpickerService.getHex(`${color}-500`);
            el.appendChild(element);
            return el;
        };
    } ],
});
