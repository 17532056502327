// @ts-nocheck
import { module } from 'angular';
module('eaw.scheduling').component('periodList', {
    bindings: {
        periods: '<',
        displayName: '<', // boolean
    },
    template: `<md-list class="md-dense">
    <md-list-item ng-repeat="period in $ctrl.periods track by period.id" ng-class="{'md-3-line': period.description, 'md-2-line': !period.description}">
        <div class="md-list-item-text" layout="column" flex>
            <h4 ng-if="$ctrl.displayName" class="f-500">
                {{period.employee_name ? period.employee_name : ('scheduling:UNASSIGNED' | i18next)}}
            </h4>
            <h4 ng-i18next="[i18next]({from: period.from.format('LT'), to: period.to.format('LT')})FROM_TO"></h4>
            <p ng-if="period.description" ng-bind="period.description"></p>
        </div>
        <div class="md-secondary period-color" ng-style="{'background-color': period.color}" ng-class="{'background-stripes': period.unproductive}" layout layout-padding></div>
        <md-divider></md-divider>
    </md-list-item>
</md-list>
`,
    controller: function() {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.periods = ctrl.periods.sort((a, b) => a.from.valueOf() - b.from.valueOf());
        };
    },
});
