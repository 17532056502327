<eaw-dialog-header [showCloseButton]="false">
    <span title>{{data.customField.translationKey | translate:'custom_fields' | async}}</span>
    <span subtitle>{{'HISTORY' | translate | async}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="loading">
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
        <mat-spinner></mat-spinner>
    </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="!loading">
    <div *ngIf="!customFields.length" class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-16">
        <mat-icon eawSize="50px">search</mat-icon>
        <span class="mat-headline-6">{{'CF_NO_HISTORY' | translate:'company' | async}}</span>
    </div>

    <div id="list">
        <div class="list-item" *ngFor="let field of customFields; index as index">
            <mat-icon [class.tw-invisible]="!field.active"
                      [matTooltip]="((field.active ? 'ACTIVE' : 'INACTIVE') | translate | async) || ''"
                      eawMaterialColor="green-500">
                check_circle
            </mat-icon>

            <div class="text" [class.active]="field.active">
                <eaw-custom-field-value-display class="title" [customField]="field"></eaw-custom-field-value-display>
                <span class="subtitle" *ngIf="field.to">{{field.from | DateTime:'DATE_MED'}} - {{field.to | DateTime:'DATE_MED'}}</span>
                <span class="subtitle" *ngIf="!field.to">{{field.from | DateTime:'DATE_MED'}} ➡️</span>
            </div>

            <div class="buttons" *ngIf="canUpdate">
                <button mat-icon-button (click)="edit(field)" eawMaterialColor="grey-700">
                    <mat-icon>edit</mat-icon>
                </button>

                <button mat-icon-button (click)="delete(field, index)" eawMaterialColor="red-400">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="close()">{{'CLOSE' | translate | async}}</button>
</mat-dialog-actions>
