import { CELL_DATA, DataTableCell } from '../interfaces/data-table-cell';
import { ElementRef, inject } from '@angular/core';
import { DataTableColumn } from './data-table-column';

export abstract class DataTablePortalComponent<Item = any, Value= any, Column extends DataTableColumn<Column, Item, Value> = DataTableColumn<any, any, any>> {
    cell: DataTableCell<Column, Item> = inject(CELL_DATA) as DataTableCell<Column, Item>;
    private el = inject(ElementRef) as ElementRef<HTMLElement>;

    constructor() {
        void this.setClasses(this.el);
        void this.setStyles(this.el);

        if (this.cell.column.nowrap) {
            this.el.nativeElement.style.whiteSpace = 'nowrap';
        }
    }

    async getValue<ExpectedReturn = Value>(): Promise<ExpectedReturn | undefined> {
        return this.cell.column.value?.(this.cell) as ExpectedReturn | undefined;
    }

    private async setClasses(el: ElementRef<HTMLElement>) {
        const classes = typeof this.cell.column.classes === 'function' ? await this.cell.column.classes(this.cell) : this.cell.column.classes;
        el.nativeElement.classList.add(...(classes || []));
    }

    private async setStyles(el: ElementRef<HTMLElement>) {
        const styles = typeof this.cell.column.styles === 'function' ? await this.cell.column.styles(this.cell) : this.cell.column.styles;

        for (const [ key, value ] of Object.entries(styles || {})) {
            if (value == null) {
                continue;
            }
            el.nativeElement.style[key as any] = value;
        }
    }
}
