<eaw-page-header class="header" [fabButton]="fabButton">
    <span title>{{'PERMISSION_plural' | translate: 'general' | async}}</span>
</eaw-page-header>
@for(permissionSet of permissionSets; track permissionSet.id) {
    <eaw-permission-tree
            [deletePermissionSet]="(getDeletePermissionSet(permissionSet) | async) || undefined"
            [permissionSetId]="permissionSet.id"
            [permissionSet]="permissionSet">
    </eaw-permission-tree>
}
@for (permission of permissions; track permission.node) {
    <eaw-permission-node
            [canDelete]="canCreateUpdateOrDeletePermissions"
            [canUpdate]="canCreateUpdateOrDeletePermissions"
            [permission]="permission"
            (deletePermission)="deletePermission(permission)"
            [changeValue]="changePermissionValue.bind(this)">
    </eaw-permission-node>
}
