import { module } from 'angular';
import { Comment, CommentResponse } from '../../../shared/models/comment';
import { t } from 'i18next';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
import { Moment } from 'moment-timezone';
import { EawUrl } from '../../../shared/angularjs/modules/resource/url.service';
import { CustomerSettings as CustomerSettingService } from '../../../shared/angularjs/modules/customers/customer-settings.factory';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { Products } from '../../../shared/enums/products';
import { PdfRendererDialogService } from '../../../shared/dialogs/pdf-renderer-dialog/pdf-renderer-dialog.service';
import { of } from 'rxjs';

module('eaw.absence').component('editAbsence', {
    template: `<md-card>
    <md-card-content>
        <form class="form" autocomplete="off" ng-submit="$edit.save()" layout="column">
            <md-input-container ng-if="$edit.canApprove">
                <label ng-i18next="EMPLOYEE"></label>
                <input ng-model="$edit.absence.employee.name" disabled>
            </md-input-container>

            <md-autocomplete
                    md-floating-label="{{'absences:ABSENCE_TYPE' | i18next}}"
                    md-no-cache="true"
                    md-clear-button="true"
                    md-selected-item="$edit.absence.type"
                    md-selected-item-change="$edit.showStats()"
                    md-search-text="typeFilter"
                    md-require-match="true"
                    md-items="item in $edit.searchAbsenceTypes(typeFilter)"
                    md-item-text="item.name | eawTranslate:'absence_types'"
                    md-delay="300"
                    required
                    md-input-name="typeAutocomplete"
                    md-min-length="0">
                <md-item-template>
                    <span md-highlight-text="typeFilter" md-highlight-flags="gi">{{item.name | eawTranslate:'absence_types'}}</span>
                </md-item-template>
                <span class="hint">{{$edit.absence.type.description | eawTranslate:'absence_types'}}</span>
                <md-not-found>
                    <span ng-i18next="NO_MATCH_FOUND"></span>
                </md-not-found>
            </md-autocomplete>

            <eaw-absence-stats-card
                    ng-if="$edit.canApprove"
                    class="tw-mb-20"
                    absence-type="$edit.absence.type"
                    stats="$edit.statsData.statistics[$edit.absence.type_id]">
            </eaw-absence-stats-card>

            <md-input-container ng-if="$edit.absence.type.gradable">
                <label ng-i18next="absences:GRADE"></label>
                <input type="number" min="1" max="100" ng-model="$edit.absence.percent_grade">
            </md-input-container>

            <eaw-date-interval
                    layout="row"
                    ng-if="$edit.absence.type.span == 'day'"
                    required
                    ng-model="$edit.interval">
            </eaw-date-interval>

            <eaw-date-time-interval
                    layout="row"
                    ng-if="$edit.absence.type.span == 'hour'"
                    required
                    ng-model="$edit.interval">
            </eaw-date-time-interval>

            <md-input-container ng-if="!$edit.hasLeaveShifts">
                <label for="length" class="text-capitalize" ng-i18next="LENGTH"></label>
                <p id="length" ng-bind="$edit.absence.length | eawDuration"></p>
            </md-input-container>

            <md-input-container ng-if="!$edit.hasLeaveShifts">
                <label for="new-length" ng-i18next="absences:LENGTH_LABEL"></label>
                <input type="number"
                       id="new-length"
                       step="any"
                       pattern="^[0-9]+([\,|\.][0-9]+)?$"
                       min="0"
                       ng-model="$edit.absence.hour_length">
            </md-input-container>

            <md-card class="tw-m-0" ng-if="$edit.approvalDeadlineEnabled && $edit.absence.approval_deadline">
                <md-card-content>
                    <div class="tw-flex tw-gap-4 tw-items-center">
                        <md-icon class="tw-m-0" ng-bind="'priority_high'"></md-icon>
                        <h4 class="tw-m-0" ng-i18next="absences:APPROVAL_DEADLINE"></h4>
                    </div>
                    <p style="margin-left: 28px; margin-right: 28px"
                       ng-i18next="[i18next]({date: ($edit.absence.approval_deadline | moment: 'date')})absences:DATE_FOR_ABSENCE_APPROVAL_INFO"></p>
                </md-card-content>
            </md-card>

            <md-switch ng-model="$edit.absence.approved" ng-if="$edit.canApprove">
                {{$edit.absence.approved ? 'APPROVED' : 'APPROVE' | i18next}}
            </md-switch>

            <md-input-container class="md-block">
                <label ng-i18next="COMMENT"></label>
                <textarea ng-model="$edit.absence.comment" rows="2"></textarea>
            </md-input-container>

            <eaw-custom-fields ng-model="$edit.absence.customFields" ng-change="$edit.changedCustomFields()"></eaw-custom-fields>
            
            <h4 ng-i18next="ATTACHMENT_plural"></h4>
            <table class="table table-striped table-condensed table-bordered-bottom" ng-show="$edit.attachments.length>0">
                <thead ng-show="$edit.attachments.length">
                <tr>
                    <th ng-i18next="NAME"></th>
                    <th ng-i18next="ACTION"></th>
                </tr>
                </thead>
                <tbody>
                <tr ng-show="!$edit.attachments.length">
                    <td colspan="99" ng-i18next="NO_DATA"></td>
                </tr>
                <tr ng-show="$edit.attachments.length" ng-repeat="attachment in $edit.attachments track by attachment.id">
                    <td ng-bind="attachment.name"></td>
                    <td>
                        <eaw-icon-button ng-click="$edit.downloadAbsenceAttachment(attachment.id)" icon="download"></eaw-icon-button>
                        <eaw-icon-button ng-click="$edit.previewAbsenceAttachment(attachment.id)" icon="preview"></eaw-icon-button>
                    </td>
                </tr>
                </tbody>
            </table>
            <div>
                <input id="absence-attachment"
                    class="display-none"
                    type="file"
                    accept="application/pdf, image/*"
                    ngf-select="$edit.attach()"
                    ng-model="$edit.images">
                <md-button class="md-raised" ng-click="$edit.click()" ng-i18next="ADD_ATTACHMENT"></md-button>
            </div>

            <md-card-actions layout="row">
                <md-button class="md-raised md-accent" type="submit" ng-disabled="$edit.disabled || !$edit.customFieldsOk" ng-i18next="absences:UPDATE_ABSENCE"></md-button>
                <md-button class="md-raised md-warn" type="button" ng-disabled="$edit.disabled" ng-if="$edit.canDelete && ($edit.allowDeleteApproved || !$edit.absence.approved)" ng-click="$edit.deleteAbsence()" ng-i18next="absences:DELETE_ABSENCE"></md-button>
                <md-button ng-click="$edit.back()" ng-i18next="CANCEL"></md-button>
            </md-card-actions>
        </form>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="COMMENT_plural"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <eaw-comment-list [angularjscomments]="$edit.absence.comments"></eaw-comment-list>
    </md-card-content>
</md-card>
`,
    bindings: {
        absence: '<',
        customer: '<',
        hasLeaveShifts: '<',
        isMine: '<',
    },
    controllerAs: '$edit',
    controller: [ '$element', 'AbsenceFactory', 'AbsenceTypeFactory', 'ConfirmDialogDowngraded', 'PdfRendererDialogDowngraded', 'approveAbsenceDialog', 'CustomerSettings', '$state', 'ToastService', 'Absence', 'FileService', '$mdDialog', 'errorService', function($element, AbsenceFactory, AbsenceTypeFactory, ConfirmDialogDowngraded: ConfirmDialogService, PdfRendererDialogDowngraded: PdfRendererDialogService, approveAbsenceDialog, CustomerSettings: CustomerSettingService, $state, ToastService: SnackBarService, Absence, FileService, $mdDialog, errorService) {
        // @ts-ignore
        const ctrl = this;

        ctrl.el = $element[0];

        ctrl.$onInit = async () => {
            ctrl.settings = await CustomerSettings.getSome(ctrl.customer.id, [ 'absences.allow_employee_to_delete_approved', 'absence.approval_deadline.is_enabled', 'absence_schedule_published_warning' ]);
            ctrl.allowDeleteApproved = ctrl.settings['absences.allow_employee_to_delete_approved'].resolved_value;
            ctrl.approvalDeadlineEnabled = ctrl.settings['absence.approval_deadline.is_enabled'].resolved_value;
            ctrl.schedulePublishedWarning = CurrentOld.hasProduct(Products.Scheduling) && ctrl.settings['absence_schedule_published_warning'].resolved_value;
            ctrl.canDelete = CurrentOld.user.can(`customers.${ctrl.customer.id}.employees.${ctrl.absence.employee_id}.absences.${ctrl.absence.id}.delete`, false, {
                absence: ctrl.absence,
                employee: ctrl.absence.employee,
            });
            ctrl.canApprove = CurrentOld.user.can(`customers.${ctrl.customer.id}.employees.${ctrl.absence.employee_id}.absences.${ctrl.absence.id}.approve`, false, {
                absence: ctrl.absence,
                employee: ctrl.absence.employee,
            });
            ctrl.absence.comments = ctrl.absence.comments?.map((c: CommentResponse) => new Comment(c));
            ctrl.interval = {
                from: ctrl.absence.from.clone(),
                to: ctrl.absence.to.clone(),
            };
            ctrl.attachments = [];
            ctrl.showStats();
            ctrl.changedCustomFields();
            ctrl.getAbsenceAttachments();
        };
        ctrl.back = () => {
            if (!ctrl.isMine) {
                $state.go('eaw/app/absence/list');
            } else {
                $state.go('eaw/app/absence/my');
            }
        };
        ctrl.showStats = () => {
            ctrl.statsData = null;
            ctrl.canGetStats = AbsenceFactory.getStats.permission(ctrl.customer.id, ctrl.absence.employee, ctrl.customer.setting_group_id);
            if (ctrl.absence.type_id && ctrl.canGetStats && ctrl.canApprove) {
                const absenceFrom: Moment = ctrl.interval.from.clone();

                ctrl.statsData = AbsenceFactory.getStats.query({
                    customerId: ctrl.customer.id,
                    employeeId: ctrl.absence.employee_id,
                    from: absenceFrom.startOf('year'),
                    to: absenceFrom.clone().endOf('year'),
                    absenceTypeId: ctrl.absence.type_id,
                });
            }
        };
        ctrl.searchAbsenceTypes = (filter: string) => AbsenceTypeFactory.getAll({
            entityId: ctrl.customer.id,
            filter,
            can_create: true,
            order_by: 'name',
            direction: 'ascending',
        }).then((resp: any) => resp.data);
        ctrl.deleteAbsence = () => {
            ConfirmDialogDowngraded.open({
                title: Promise.resolve(t('absences:DELETE_ABSENCE') || ''),
                text: Promise.resolve(t('absences:CONFIRM_DELETE') || ''),
                comment: {
                    include: true,
                    required: true,
                },
            }).afterClosed().subscribe((response) => {
                if (!(response?.ok && response.comment)) {
                    return;
                }
                ctrl.disabled = true;
                Absence.delete({
                    customerId: ctrl.customer.id,
                    id: ctrl.absence.id,
                    employeeId: ctrl.absence.employee_id,
                    comment: response.comment,
                }).$promise.then(() => {
                    ToastService.deleted();
                    ctrl.back();
                }).catch(() => {
                    ctrl.disabled = false;
                });
            });
        };
        ctrl.getAbsenceAttachments = () => {
            ctrl.absence.getAttachments(ctrl.customer.id, ctrl.absence.id).$promise.then((res: any) => {
                ctrl.attachments = res.data;
            }).catch(() => {
            });
        };
        ctrl.downloadAbsenceAttachment = (attachmentId: number) => {
            const url = EawUrl.url + `/customers/${ctrl.customer.id}/absences/${ctrl.absence.id}/attachments/${attachmentId}`;
            return FileService.download(url, 'get');
        };
        ctrl.blobToBase64 = (blob: Blob, callback: (url: unknown) => void) => {
            const reader = new FileReader();
            reader.onload = function() {
                const dataUrl = reader.result;
                callback(dataUrl);
            };
            reader.readAsDataURL(blob);
        };
        ctrl.previewAbsenceAttachment = (attachmentId: number) => {
            const url = EawUrl.url + `/customers/${ctrl.customer.id}/absences/${ctrl.absence.id}/attachments/${attachmentId}`;
            FileService.request(url, 'get').then((file: { data: Blob, type: string, status: number }) => {
                ctrl.blobToBase64(file.data, (data: string) => {
                    if (file.type == 'application/pdf') {
                        PdfRendererDialogDowngraded.open({
                            url: of(data),
                        });
                    } else {
                        $mdDialog.show({
                            template: `<img src="${data}" alt="absence file attachment preview (image)" style="max-width: 100%; max-height: 100%; margin: auto; display: block;">`,
                            clickOutsideToClose: true,
                            escapeToClose: true,
                        });
                    }
                });
            });
        };
        ctrl.save = () => {
            ctrl.absence.from = ctrl.interval.from.clone();
            ctrl.absence.to = ctrl.interval.to.clone();
            const hasScheduling = ctrl.customer.hasProduct('Scheduling');
            const promise = hasScheduling && ctrl.absence.approved && ctrl.absence.isModified('approved') ? approveAbsenceDialog(ctrl.absence, hasScheduling) : Promise.resolve();
            ctrl.disabled = true;
            return promise.then(() => ctrl.absence.update(ctrl.absence.approved && ctrl.absence.isModified('approved') || !ctrl.schedulePublishedWarning)
                .$promise.then(() => {
                    $state.reload();
                })
                .catch((error: any) => {
                    if (error.status === 422 && error.data?.type === 'schedule_already_published') {
                        return $mdDialog.show($mdDialog.confirm()
                            .title(t('WARNING'))
                            .textContent(error.data.error)
                            .ok(t('CONTINUE'))
                            .cancel(t('CANCEL')),
                        ).then(() => ctrl.absence.update().$promise
                            .then(() => $state.reload()),
                        );
                    } else {
                        return errorService.displayXhrError(error);
                    }
                })).catch(() => (ctrl.disabled = false));
        };
        ctrl.changedCustomFields = () => {
            ctrl.absence.assignCustomFields();

            ctrl.customFieldsOk = ctrl.absence.customFields.every((cf: any) => {
                if (!cf.pivot.required) {
                    return true;
                }

                const value = ctrl.absence[cf.key]?.value;
                if (value == null) {
                    return false;
                }
                if (typeof value === 'string') {
                    return value.trim() !== '';
                }

                return true;
            });
        };
        ctrl.click = () => {
            ctrl.el.querySelector(`#absence-attachment`)?.click();
        };
        ctrl.attach = () => {
            if (ctrl.images instanceof File) {
                ctrl.images = [ ctrl.images ];
            }

            ctrl.images?.forEach?.((file: any) => {
                const url = EawUrl.url + `/customers/${ctrl.customer.id}/absences/${ctrl.absence.id}/attachments`;
                return FileService.upload(url, file, 'file', {}).then(() => {
                    ctrl.getAbsenceAttachments();
                });
            });
        };
    } ],
});
