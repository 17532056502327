// @ts-nocheck
import { module } from 'angular';

module('eaw.scheduling').controller('UnitFilterCtrl', [ '$mdDialog', 'UnitFilter', function UnitFilterCtrl($mdDialog, UnitFilter) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.UnitFilter = UnitFilter;

        ctrl.storeInitial();

        ctrl.submit = $mdDialog.hide;
    };

    ctrl.storeInitial = () => {
        ctrl.initialShiftWithoutUnit = ctrl.UnitFilter.shiftWithoutUnit;
        ctrl.initialUnits = ctrl.UnitFilter.units.map((u) => {
            return { ...u };
        });
    };

    ctrl.cancel = () => {
        ctrl.UnitFilter.shiftWithoutUnit = ctrl.initialShiftWithoutUnit;
        ctrl.UnitFilter.units = ctrl.initialUnits.map((u) => {
            return { ...u };
        });

        $mdDialog.cancel();
    };
} ]);
