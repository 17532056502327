<eaw-page-header>
    <span title>{{'PASSWORD' | translate | async}}</span>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        <eaw-password [formControl]="passwordCtrl"></eaw-password>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="accent" (click)="update()" [disabled]="passwordCtrl.invalid || submitting">
            {{'SAVE_NEW_PASSWORD' | translate: 'profile' | async}}
        </button>
    </mat-card-actions>
</mat-card>
