<eaw-dialog-header [showCloseButton]="!changing">
    <span title>{{'CHANGE_LOCATION' | translate | async}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="changing" class="tw-flex tw-flex-col tw-gap-16 tw-items-center tw-justify-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <span>{{'CHANGING_LOCATION' | translate | async}}</span>
</mat-dialog-content>

<mat-dialog-content *ngIf="!changing" class="tw-flex tw-flex-col">
    <mat-form-field>
        <mat-label>{{'FILTER' | translate | async}}</mat-label>
        <input matInput #filterInput [formControl]="filterControl" autocomplete="off">
    </mat-form-field>

    <mat-selection-list dense [multiple]="false" (selectionChange)="change($event)" hideSingleSelectionIndicator>
        <mat-list-option *ngFor="let customer of filteredCustomers" [value]="customer" [disabled]="customer.id === data.customer.id">{{customer.name}}</mat-list-option>
    </mat-selection-list>
</mat-dialog-content>
