import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { WidgetInfo } from '../../classes/widget-info';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'eaw-widget-settings-dialog',
    templateUrl: './widget-settings-dialog.component.html',
    styleUrl: './widget-settings-dialog.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DialogHeaderComponent,
        AsyncPipe,
        MatDialogModule,
        MatButtonModule,
        TranslatePipe,
        ActionButtonComponent,
        MatProgressSpinnerModule,
    ],
})
export class WidgetSettingsDialogComponent {
    submitted = output();

    widgetInfo = input.required<WidgetInfo>();
    valid = input.required<boolean>();
    loading = input<boolean>();
}
