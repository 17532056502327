// @ts-nocheck
import { uniqueId } from '../../misc/services/easy-funcs.service';
import moment from 'moment-timezone';
import { module } from 'angular';

module('eaw.time').component('eawDateTimePicker', {
    template: `<eaw-input-button icon="close" ng-click="$dtp.clearValue()" ng-show="!$dtp.required && !$dtp.disabled && !$dtp.readonly && $dtp.dateTime"></eaw-input-button>

<input name="{{::$dtp.inputName}}"
       ng-model="$dtp.dateTimeText"
       readonly
       autocomplete="off"
       ng-click="$dtp.openDialog()"
       ng-required="$dtp.required"
       ng-readonly="$dtp.readonly"
       ng-disabled="$dtp.disabled">
`,
    controllerAs: '$dtp',
    require: {
        ngModel: 'ngModel',
        ngForm: '?^^form',
        interval: '?^eawDateTimeInterval',
    },
    bindings: {
        format: '<?',
        minDate: '<?',
        maxDate: '<?',
        dialogLabel: '<?',
        inputName: '<?',
    },
    controller: [ 'eawDateTimePickerDialog', '$attrs', function(eawDateTimePickerDialog, $attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.format = ctrl.format || 'LLL';
            ctrl.inputName = ctrl.inputName || uniqueId('dtpInput');
            ctrl.formattedMinDate = moment.isMoment(ctrl.minDate) ? ctrl.minDate.format('lll') : undefined;
            ctrl.formattedMaxDate = moment.isMoment(ctrl.maxDate) ? ctrl.maxDate.format('lll') : undefined;

            ctrl.ngModel.$formatters.unshift(ctrl.formatModel);

            $attrs.$observe('required', (value) => {
                ctrl.required = value;
            });

            $attrs.$observe('disabled', (value) => {
                ctrl.disabled = value;
            });

            $attrs.$observe('readonly', (value) => {
                ctrl.readonly = value;
            });
        };

        ctrl.formatModel = (val) => {
            ctrl.setValue(val);
            return val;
        };

        ctrl.setValue = (dateTime) => {
            if (+dateTime !== +ctrl.dateTime) {
                ctrl.dateTime = moment.isMoment(dateTime) ? dateTime : undefined;
                ctrl.dateTimeText = dateTime?.format?.(ctrl.format);

                ctrl.setMinDateValidity();
                ctrl.setMaxDateValidity();

                ctrl.ngModel.setViewValue(ctrl.dateTime);
            }
        };

        ctrl.setMinDateValidity = () => {
            const validity = +ctrl.dateTime < +ctrl.minDate;

            if (ctrl.ngForm) {
                ctrl.ngForm[ctrl.inputName].$setValidity('beforeMinDate', !validity);
            }

            ctrl.ngModel.$setValidity('beforeMinDate', !validity);
        };

        ctrl.setMaxDateValidity = () => {
            const validity = +ctrl.dateTime > +ctrl.maxDate;

            if (ctrl.ngForm) {
                ctrl.ngForm[ctrl.inputName].$setValidity('afterMaxDate', !validity);
            }

            ctrl.ngModel.$setValidity('afterMaxDate', !validity);
        };

        ctrl.clearValue = () => {
            ctrl.setValue(undefined);
        };

        ctrl.openDialog = () => {
            if (ctrl.disabled || ctrl.readonly) {
                return;
            }

            eawDateTimePickerDialog.open(ctrl.dateTime, ctrl.dialogLabel, {
                minDate: ctrl.minDate,
                maxDate: ctrl.maxDate,
            }).then((dateTime) => {
                ctrl.setValue(dateTime);
            });
        };
    } ],
});
