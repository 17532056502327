import type { DirectoryResponse } from '../interfaces/directory-response';
import type { Actions } from '../interfaces/actions';
import { FsFile } from './fsFile';
import { FsNode } from './fs-node';
import { Customer } from '../../shared/models/customer';

export class Directory extends FsNode {
    actions: Actions;
    files?: FsFile[];
    directories?: Directory[];
    color?: string;

    constructor(data: DirectoryResponse) {
        super(data);
        this.actions = data.actions;
        this.customer = data.customer ? new Customer(data.customer) : undefined;
        this.files = data.files?.map((file) => {
            return new FsFile(file);
        });
        this.directories = data.directories?.map((dir) => {
            return new Directory(dir);
        });
    }
}
