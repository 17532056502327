import { AfterViewInit, ChangeDetectionStrategy, Component, computed, inject, signal, viewChild, ViewContainerRef } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { Widget } from '../../classes/widget';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { TranslateService } from '../../../shared/services/translate.service';
import { Subject, take } from 'rxjs';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { ExtendedWidgetInfo } from '../../classes/extended-widget-info';

export interface ExtendedWidgetInfoData extends DialogData {
    widget: Widget<any>;
}

export interface ExtendedWidgetInfoResult {
    action?: 'open_options',
}

@Component({
    selector: 'eaw-extended-widget-info',
    templateUrl: './extended-widget-info.component.html',
    styleUrl: './extended-widget-info.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        InfoLoadingComponent,
    ],
})
export class ExtendedWidgetInfoComponent extends DialogComponent<ExtendedWidgetInfoData, ExtendedWidgetInfoResult> implements AfterViewInit {
    private readonly translate = inject(TranslateService);

    private componentHost = viewChild.required('componentHost', { read: ViewContainerRef });
    private component = signal<ExtendedWidgetInfo|undefined>(undefined);

    protected failedToInstantiate = signal(false);
    protected failedToInstantiateReason = signal(Promise.resolve(''));
    /**
     * Shows a loading spinner, and hides the content of the extended info component.
     *
     * The widget should decide when to show/hide the loading spinner so it should always be false by default.
     */
    protected loading = signal(false);
    protected subtitle = computed(() => this.component()?.getSubtitle() || Promise.resolve(''));

    private openOptionsSubject = new Subject<void>();

    constructor() {
        super(undefined, undefined, DialogSize.Medium);
    }

    ngAfterViewInit() {
        this.dialogRef.afterOpened().pipe(take(1)).subscribe(() => {
            this.createComponent();
        });
    }

    onOpenOptions() {
        return this.openOptionsSubject.asObservable();
    }

    protected openOptions() {
        this.openOptionsSubject.next();
    }

    private createComponent() {
        const extendedComponent = this.data.widget.info.extendedInfoComponent;

        if (!extendedComponent) {
            this.failedToInstantiate.set(true);
            this.failedToInstantiateReason.set(this.translate.t('NO_COMPONENT_FOUND', 'widgets'));
            return;
        }

        const component = this.componentHost().createComponent(extendedComponent);
        this.component.set(component.instance);
        this.failedToInstantiate.set(!component);
        this.failedToInstantiateReason.set(this.translate.t('FAILED_TO_INSTANTIATE_BUILD', 'widgets'));

        component?.setInput('widget', this.data.widget);
    }
}
