import { Component, DestroyRef, Inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { HrFileType } from '../../../hr/models/hr-file-type';
import { HrFileTypeService } from '../../../hr/http/hr-file-type.service';
import { Employee } from 'src/app/shared/models/employee';
import { HrFileService } from '../../../hr/http/hr-file.service';
import { DateTime } from 'luxon';
import { User } from '../../../shared/models/user';
import { EmployeeCommentService } from '../../http/employee-comment.service';
import { catchError, EMPTY, filter, forkJoin, Observable, of } from 'rxjs';
import { HrFile } from '../../../hr/models/hr-file';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { BasicAutocompleteComponent } from '../../../shared/components/basic-autocomplete/basic-autocomplete.component';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface AddEmployeeNoteData extends DialogData {
    employee: Employee;
    showHrOption: Observable<boolean>;
    user: User;
}

interface NoteForm {
    note: FormControl<string>;
    addToHr?: FormControl<boolean>;
    hr?: FormControl<HrFileType | null>;
}

@Component({
    selector: 'eaw-add-employee-note',
    templateUrl: './add-employee-note.component.html',
    styleUrl: './add-employee-note.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        MatCheckboxModule,
        CheckboxHelperDirective,
        BasicAutocompleteComponent,
        MatCardModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AddEmployeeNoteComponent extends DialogComponent<AddEmployeeNoteData, boolean> {
    form: FormGroup<NoteForm>;
    saving = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AddEmployeeNoteData,
        @Inject(HrFileTypeService) protected hrFileTypeService: HrFileTypeService,
        @Inject(DestroyRef) protected destroyRef: DestroyRef,
        @Inject(HrFileService) protected fileService: HrFileService,
        @Inject(EmployeeCommentService) protected commentService: EmployeeCommentService,
        @Inject(MatDialogRef) dialogRef: MatDialogRef<AddEmployeeNoteComponent, boolean>,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        const form = new FormGroup<NoteForm>({
            note: new FormControl('', {
                nonNullable: true,
                validators: Validators.required,
            }),
        });

        data.showHrOption.pipe(filter((showHrOption) => showHrOption)).subscribe(() => {
            const addToHr: FormControl<boolean> = new FormControl(false, { nonNullable: true });
            form.addControl('addToHr', addToHr);

            const hrCtrl: FormControl<HrFileType | null> = new FormControl(null, Validators.required);

            addToHr.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
                if (value) {
                    this.form.addControl('hr', hrCtrl);
                } else {
                    this.form.removeControl('hr');
                }
            });
        });

        this.form = form;
    }

    getHrFileTypes(filter?: string) {
        return this.hrFileTypeService.getAll(this.data.employee.customerId, {
            per_page: 50,
            filter,
        });
    }

    save(): void {
        const note = this.form.value.note;
        if (!note) {
            return;
        }

        const createNote = this.commentService.create(this.data.employee.customerId, this.data.employee.id, note);
        let createFile: Observable<HrFile> | undefined;

        if (this.form.value.addToHr) {
            const fileType = this.form.value.hr;
            if (fileType) {
                createFile = this.fileService.createFile(this.data.employee.customerId, this.data.employee.id, {
                    notify: false,
                    type_id: fileType.id,
                    file: new File([ note ], `${fileType.name}.txt`, { type: 'text/plain' }),
                    name: `${DateTime.now().toFormat('YYYY-MM-DD HH:mm:ss')} - ${this.data.user.name}`,
                });
            }
        }

        this.saving = true;
        forkJoin([ createNote, createFile || of(0) ]).pipe(
            catchError(() => {
                this.saving = false;
                return EMPTY;
            }),
        ).subscribe(() => {
            this.dialogRef.close(true);
        });
    }
}
