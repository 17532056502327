import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit, signal } from '@angular/core';
import { ScheduleTab, ScheduleTabsService } from '../../services/schedule-tabs.service';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { AsyncPipe } from '@angular/common';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { forkJoin, switchMap, tap } from 'rxjs';
import { UserPropertyService } from '../../../shared/http/user-property.service';
import { CurrentService } from '../../../shared/services/current.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { Property } from '../../../shared/models/property';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaw-schedule-settings',
    standalone: true,
    imports: [
        MatFormField,
        MatSelect,
        MatOption,
        AsyncPipe,
        MatLabel,
        InfoLoadingComponent,
        MatHint,
        TranslateSyncPipe,
        ReactiveFormsModule,
        TranslatePipe,
    ],
    templateUrl: './schedule-settings.component.html',
    styleUrl: './schedule-settings.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleSettingsComponent implements OnInit {
    private userPropertyService = inject(UserPropertyService);
    private scheduleTabsService = inject(ScheduleTabsService);
    private snackBarService = inject(SnackBarService);
    private currentService = inject(CurrentService);
    private destroyRef = inject(DestroyRef);

    // Has to be an old input due to the component being used in AngularJS or something :)
    @Input({ required: true }) stackId!: number;
    @Input({ required: true }) customerId!: number;

    private readonly propertyPrefix = 'schedule:setting:';
    fetching = signal(true);
    scheduleTabs = signal<ScheduleTab[]>([]);
    tabControl = new FormControl<string>('', { nonNullable: true });

    ngOnInit() {
        expandAllPages((pagination) => this.userPropertyService.getAll(this.currentService.getUser().id, { ...pagination, filter: this.propertyPrefix }), { per_page: 200 }).pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap((properties) => forkJoin([ this.handleDefaultTab(properties) ])),
        ).subscribe(() => {
            this.fetching.set(false);
        });
    }

    getPropertyKey(key: string) {
        return `${this.propertyPrefix}${key}`;
    }

    getProperty(properties: Property[], key: string) {
        return properties.find((p) => p.key === key);
    }

    handleDefaultTab(properties: Property[]) {
        const defaultTabKey = this.getPropertyKey('default_tab');
        const property = this.getProperty(properties, defaultTabKey);

        return this.scheduleTabsService.getAllowedTabs(this.stackId, this.customerId).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((tabs) => {
                this.scheduleTabs.set(tabs);

                this.tabControl.patchValue(tabs.find((t) => t.name === property?.value.asString())?.name || '');

                this.tabControl.valueChanges.pipe(
                    takeUntilDestroyed(this.destroyRef),
                    switchMap((tab) => {
                        const observable = tab
                            ? this.userPropertyService.update(this.currentService.getUser().id, defaultTabKey, tab)
                            : this.userPropertyService.delete(this.currentService.getUser().id, defaultTabKey);

                        return observable.pipe(takeUntilDestroyed(this.destroyRef));
                    }),
                    tap(() => this.toastSaved()),
                ).subscribe();
            }),
        );
    }

    toastSaved() {
        this.snackBarService.t('SETTING_SAVED_plural');
    }
}
