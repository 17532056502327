@if (loading) {
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
} @else {
    <eaw-quick-grid
            [people]="employees"
            [items]="qualifications"
            [lastDayText]="lastDayText"
            [shownItems]="shownQualifications"
            (toggleItem)="toggleQualification($event)">
        <mat-card-header header>
            <button mat-icon-button (click)="filter()">
                <mat-icon>filter_alt</mat-icon>
            </button>
            <div class="tw-flex-row">
                <mat-card-title>{{'QUICK_ADD_REMOVE_QUALIFICATIONS' | translate: 'company' | async}}</mat-card-title>
                <mat-card-subtitle>{{'QUICK_ADD_QUALIFICATION_HELP' | translate: 'company' | async}}</mat-card-subtitle>
            </div>
        </mat-card-header>
    </eaw-quick-grid>
}
