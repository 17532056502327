@if (pages().length) {
    <div id="pages">
        @if (pages().length > 3) {
            <button mat-icon-button (click)="matPaginator.firstPage()" [disabled]="!matPaginator.hasPreviousPage()"
                    [matTooltip]="('FIRST_PAGE' | translate | async) || ''">
                <mat-icon>first_page</mat-icon>
            </button>
        }

        <button mat-icon-button (click)="matPaginator.previousPage()" [disabled]="!matPaginator.hasPreviousPage()"
                [matTooltip]="('PREVIOUS_PAGE' | translate | async) || ''">
            <mat-icon>chevron_left</mat-icon>
        </button>

        @for (page of pages(); track page.index) {
            <div class="page" matRipple [class]="page.type" (click)="goToPage(page.index)">
                @if (page.type === 'more') {
                    <mat-icon eawSize="20px">more_horiz</mat-icon>
                } @else {
                    <div>{{ page.formattedIndex }}</div>
                }
            </div>
        }

        <button mat-icon-button (click)="matPaginator.nextPage()" [disabled]="!matPaginator.hasNextPage()"
                [matTooltip]="('NEXT_PAGE' | translate | async) || ''">
            <mat-icon>chevron_right</mat-icon>
        </button>

        @if (pages().length > 3) {
            <button mat-icon-button (click)="matPaginator.lastPage()" [disabled]="!matPaginator.hasNextPage()"
                    [matTooltip]="('LAST_PAGE' | translate | async) || ''">
                <mat-icon>last_page</mat-icon>
            </button>
        }
    </div>
}

<mat-paginator #matPaginator
               (page)="emitChange($event)"
               [pageIndex]="index()"
               [length]="total()"
               [pageSize]="perPage()"
               [pageSizeOptions]="[ 10, 25, 50, 100 ]"
               aria-label="Select page of search results">
</mat-paginator>
