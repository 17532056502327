export const enum ApiModel {
    Absence = 'absence',
    Contract = 'contract',
    Comment = 'comment',
    Customer = 'customer',
    CustomField = 'custom_field',
    EmergencyContact = 'emergency_contact',
    Employee = 'employee',
    FamilyMember = 'family_member',
    Form = 'form',
    FormSubmission = 'form_submission',
    HrFile = 'hr_file',
    HrFiletype = 'hr_file_type',
    OffTime = 'off_time',
    PaidTime = 'paid_time',
    Report = 'report',
    ReportRun = 'run',
    Schedule = 'schedule',
    // Setting group, customer group, shift group are all just 'group'
    Group = 'group',
    Shift = 'shift',
    TodoItem = 'todo_item',
    UserGroup = 'user_group',
    User = 'user',
    PermissionSet = 'permission_set',
}

export const enum ApiModelClass {
    Absence = 'Modules\\Absence\\Models\\Absence',
    Contract = 'App\\Models\\Contract',
    Comment = 'App\\Models\\Comment',
    Customer = 'App\\Models\\Customer',
    CustomField = 'App\\Models\\CustomField',
    CustomerGroup = 'App\\Models\\Groups\\CustomerGroup',
    EmergencyContact = 'App\\Models\\EmergencyContact',
    Employee = 'App\\Models\\Employee',
    FamilyMember = 'App\\FamilyMembers\\Entities\\FamilyMember',
    Form = 'Modules\\Forms\\Models\\Form',
    FormSubmission = 'Modules\\Forms\\Models\\FormSubmission',
    HrFile = 'Modules\\Hr\\Models\\File',
    OffTime = 'Modules\\OffTime\\Models\\OffTime',
    PaidTime = 'Modules\\PaidTime\\Models\\PaidTime',
    Report = 'Modules\\Reports\\Models\\Report',
    ReportRun = 'Modules\\Reports\\Models\\Run',
    Schedule = 'Modules\\Scheduling\\Models\\Schedule',
    SettingGroup = 'App\\Models\\Groups\\SettingGroup',
    Shift = 'Modules\\Scheduling\\Models\\Shift',
    TodoItem = 'Modules\\Todo\\Models\\TodoItem',
    PermissionSet = 'App\\Models\\PermissionSet',
    HrFileType = 'Modules\\Hr\\Models\\FileType',
    Signable = 'Modules\\DigiSign\\Models\\Signable',
}
