// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
module('eaw.company.src').factory('familyMemberFactory', [ 'EawResource', 'Pagination', function familyMemberFactory(EawResource, Pagination) {
    const url = 'customers/:customer/employees/:employee/family_members/:member';
    const fields = [ 'first_name', 'last_name', 'relationship', 'prefix', 'sex', 'family_allowance', 'birth_date' ];
    return {
        create(employee, familyMember) {
            return EawResource.create(url).save({
                customer: employee.customer_id,
                employee: employee.id,
            }, pick(familyMember, fields));
        },
        getAll(employee, pagination) {
            return EawResource.create(url).get({
                customer: employee.customer_id,
                employee: employee.id,
                ...Pagination.getParams(pagination, [ 'relationship', 'include_deleted' ]),
            });
        },
        get(employee, memberId, withRelations) {
            return EawResource.create(url).get({
                customer: employee.customer_id,
                employee: employee.id,
                member: memberId,
                'with[]': withRelations,
            });
        },
        update(employee, familyMember) {
            return EawResource.create(url).update({
                customer: employee.customer_id,
                employee: employee.id,
                member: familyMember.id,
            }, pick(familyMember, fields));
        },
        delete(employee, memberId) {
            return EawResource.create(url).delete({
                customer: employee.customer_id,
                employee: employee.id,
                member: memberId,
            });
        },
    };
} ]);
