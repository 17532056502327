import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';
import { Contract, ContractAmountType, ContractResponse } from '../models/contract';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { PaginationOptions } from '../interfaces/pagination-options';
import { formatHttpParams } from '../utils/format-http-params';
import { CustomFieldKeyValues } from '../utils/custom-fields-group';
import { FileDownloadService } from '../services/file-download.service';

interface CreateContract {
    type_id: number;
    from: DateTime;
    to?: DateTime | null;
    amount: number;
    amount_type: ContractAmountType;
    title?: string | null;
}

type UpdateContract = Partial<Omit<CreateContract, 'from'>>;

interface GetAllForEmployeeOptions extends PaginationOptions {
    active?: DateTime
}

@Injectable({
    providedIn: 'root',
})
export class ContractService {
    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(FileDownloadService) private fileDownload: FileDownloadService,
    ) {
    }

    getAllForCustomer(customerId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<ContractResponse>>(`/customers/${customerId}/contracts`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(Contract));
    }

    getAllForEmployee(customerId: number, employeeId: number, options?: GetAllForEmployeeOptions) {
        return this.http.get<ArrayPaginatedResponse<ContractResponse>>(`/customers/${customerId}/employees/${employeeId}/contracts`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(Contract));
    }

    create(customer: number, employee: number, data: CreateContract, customFields: CustomFieldKeyValues) {
        return this.http.post<ContractResponse>(`/customers/${customer}/employees/${employee}/contracts`, {
            ...data,
            ...customFields,
        }).pipe(classifyItem(Contract));
    }

    update(customer: number, employee: number, contract: number, data: UpdateContract, customFields: CustomFieldKeyValues) {
        return this.http.put<ContractResponse>(`/customers/${customer}/employees/${employee}/contracts/${contract}`, {
            ...data,
            ...customFields,
        }).pipe(classifyItem(Contract));
    }

    delete(customerId: number, employeeId: number, contractId: number) {
        return this.http.delete<undefined>(`/customers/${customerId}/employees/${employeeId}/contracts/${contractId}`);
    }

    download(customerId: number, employeeId: number, contractId: number, template: string) {
        return this.fileDownload.download(`/customers/${customerId}/employees/${employeeId}/contract_documents/${contractId}?template=${template}`, 'contract.docx');
    }

    getTemplates(customerId: number, employeeId: number, contractId: number) {
        return this.http.get<string[]>(`/customers/${customerId}/employees/${employeeId}/contract_documents/${contractId}/templates`).pipe();
    }
}
