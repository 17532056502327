import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { LeaveShift, LeaveShiftResponse } from '../models/leave-shift';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { DateTime } from 'luxon';

interface UpdateBody {
    from?: DateTime,
    to?: DateTime,
    break_length?: number,
    break_offset?: number,
}

@Injectable({
    providedIn: 'root',
})
export class AbsenceLeaveShiftService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, employeeId: number, absenceId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<LeaveShiftResponse>>(`/customers/${customerId}/employees/${employeeId}/absences/${absenceId}/leave_shifts`, {
            params: formatHttpParams(options),
        }).pipe(
            classifyArrayPaginatedResponse(LeaveShift),
        );
    }

    update(customerId: number, employeeId: number, absenceId: number, leaveShiftId: number, body: UpdateBody) {
        return this.http.put<LeaveShiftResponse>(`/customers/${customerId}/employees/${employeeId}/absences/${absenceId}/leave_shifts/${leaveShiftId}`, body).pipe(
            classifyItem(LeaveShift),
        );
    }

    delete(customerId: number, employeeId: number, absenceId: number, leaveShiftId: number) {
        return this.http.delete(`/customers/${customerId}/employees/${employeeId}/absences/${absenceId}/leave_shifts/${leaveShiftId}`);
    }
}
