import { Inject, Injectable } from '@angular/core';
import { CountryRegion } from '../models/country-region';
import { TranslateService } from '../services/translate.service';
import { CountryRegionService } from '../http/country-region.service';
import { of } from 'rxjs';
import { Country } from '../models/country';
import { Autocomplete } from './autocomplete';

@Injectable({
    providedIn: 'root',
})
export class CountryRegionAutocompleteService extends Autocomplete<CountryRegion> {
    constructor(
        @Inject(TranslateService) protected translateService: TranslateService,
        @Inject(CountryRegionService) private countryRegionService: CountryRegionService,
    ) {
        super({
            label: translateService.t('REGION', 'company'),
            display: 'name',
            trackByKey: 'id',
        });
    }

    private getCode(value: string | Country | undefined | null) {
        let code = '';
        if (value instanceof Country) {
            code = value.code;
        } else if (typeof value === 'string') {
            code = value;
        }

        return code;
    }

    getter(args: { countryCode: string | Country | undefined | null }) {
        return (filter?: string) => {
            return this.countryRegionService.getAll(this.getCode(args.countryCode), {
                filter,
                per_page: 10,
            });
        };
    }

    setter(args: { countryCode: string | Country | undefined | null }) {
        return (item: unknown) => {
            if (item instanceof CountryRegion) {
                return of(item);
            }
            if (typeof item !== 'number') {
                return of(undefined);
            }

            return this.countryRegionService.get(this.getCode(args.countryCode), item);
        };
    }
}
