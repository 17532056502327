import { Inject, Injectable } from '@angular/core';
import { Autocomplete } from './autocomplete';
import { Language } from '../../admin/models/language';
import { TranslateService } from '../services/translate.service';
import { LanguageService } from '../../admin/http/language.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LanguageAutocompleteService extends Autocomplete<Language> {

    constructor(
        @Inject(LanguageService) protected languageService: LanguageService,
        @Inject(TranslateService) protected translateService: TranslateService,
    ) {
        super({
            label: translateService.t('LANGUAGE'),
            optionTemplate: 'default',
            filterRequirement: 0,
            display: 'name',
            trackByKey: 'id',
        });
    }

    setter() {
        return (item: unknown) => {
            if (item instanceof Language) {
                return of(item);
            }
            if (typeof item !== 'string') {
                return of(undefined);
            }

            return this.languageService.get(item);
        };
    }

    getter() {
        return (filter?: string) => {
            return this.languageService.getAll({
                filter,
                per_page: 20,
                order_by: 'name',
                direction: 'asc',
            });
        };
    }
}
