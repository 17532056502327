import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDownloadService } from './file-download.service';

export type SpreadSheetFormat = 'xlsx' | 'csv' | 'ods';

@Injectable({
    providedIn: 'root',
})
export class SpreadsheetService {
    protected fileDownloadService = inject(FileDownloadService);

    /**
     * Create a spreadsheet file from the given array.
     */
    createSpreadsheet(format: SpreadSheetFormat, data: string[][], filename = 'spreadsheet'): Observable<Blob> {
        return this.fileDownloadService.download('/spreadsheet/generate', filename, {
            body: {
                data,
                filename,
                format,
            },
        }, 'POST');
    }
}
