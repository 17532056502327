import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { Approval, ApprovalResponse } from '../../shared/models/approval';
import { Comment, CommentResponse } from '../../shared/models/comment';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { AbsenceType, AbsenceTypeResponse } from './absence-type';
import { Warning, WarningResponse } from '../../shared/models/warning';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { LeaveShift, LeaveShiftResponse } from '../../leave-shifts/models/leave-shift';
import { BaseApiModel } from '../../shared/models/base-api-model';
import { ApiModel } from '../../shared/enums/api-model';

export interface AbsenceResponse extends ApiResponse {
    id: number;
    // Seems this is just a special case in paid time overview absences array
    customer_id?: number;
    employee_id: number;
    from: string;
    to: string;
    grade: number;
    // While this can be null in database, backend retrieves the from/to diff if that is the case, so this will always be a number
    length: number;
    type_id: number;
    approval_deadline?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
    approval?: ApprovalResponse;
    comments?: CommentResponse[];
    warnings?: WarningResponse[];
    employee?: EmployeeResponse;
    type?: AbsenceTypeResponse;
    leave_shifts?: LeaveShiftResponse[];
}

export class Absence extends BaseApiModel<AbsenceResponse, Absence> {
    id: number;
    customerId: number;
    employeeId: number;
    from: DateTime;
    to: DateTime;
    grade: number;
    // This will be the diff between from and to if the absence doesn't have a length
    length: number;
    typeId: number;
    approvalDeadline?: DateTime | null;
    createdAt: DateTime | null;
    updatedAt: DateTime | null;
    deletedAt: DateTime | null;
    approval?: Approval;
    employee?: Employee;
    comments?: Comment[];
    warnings?: Warning[];
    type?: AbsenceType;
    leaveShifts?: LeaveShift[];

    constructor(data: AbsenceResponse) {
        super(data, ApiModel.Absence);

        this.id = data.id;
        // Use the special case or else the customer id is in the employee object by default
        this.customerId = data.customer_id || data.employee?.customer_id || 0;
        this.employeeId = data.employee_id;
        this.from = stringToDateTime(data.from);
        this.to = stringToDateTime(data.to);
        this.grade = data.grade;
        this.length = data.length;
        this.typeId = data.type_id;
        this.approvalDeadline = data.approval_deadline ? stringToDateTime(data.approval_deadline) : null;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.comments = data.comments?.map((c) => new Comment(c));
        this.warnings = data.warnings?.map((w) => new Warning(w));
        this.approval = data.approval ? new Approval(data.approval) : undefined;
        this.employee = data.employee ? new Employee(data.employee) : undefined;
        this.type = data.type ? new AbsenceType(data.type) : undefined;
        this.leaveShifts = data.leave_shifts ? data.leave_shifts.map((l) => new LeaveShift(l)) : undefined;
    }

    get hourLength() {
        return this.length / 3600;
    }
}
