import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CombinedContract } from '../../models/combined-contract';
import { catchError, EMPTY, forkJoin, Observable, of, switchMap } from 'rxjs';
import { Setting } from '../../../shared/models/setting';
import { Namespace } from '../../../shared/enums/namespace';
import { SettingService } from '../../../shared/http/setting.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { CombinedContractCreateData, CombinedContractService } from '../../http/combined-contract.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ContractDialogComponent, ContractDialogData, ContractDialogFormData, ContractDialogReturn } from '../contract-dialog/contract-dialog.component';
import { DateTime } from 'luxon';
import { CreateAvailabilityDialogComponent, CreateAvailabilityDialogData, CreateAvailabilityDialogReturn } from '../../../availability/dialogs/create-availability/create-availability-dialog.component';
import { Contract } from '../../../shared/models/contract';
import { DateTimeConverter } from '../../../shared/utils/date-time-converter';
import { Availability, AvailabilityResponse } from '../../../availability/models/availability';
import { AvailabilityCreate } from '../../../availability/http/availability.service';
import { HourDistribution } from '../../models/hour-distribution';
import { HourDistributionDaysComponent, HourDistributionDaysDialogData, HourDistributionDaysDialogResult } from '../hour-distribution-days/hour-distribution-days.component';
import { BusinessDate } from '../../../shared/utils/business-date';
import { AvailabilityDayType } from '../../../availability/enums/availability-day-type';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { AvailabilityDaysComponent } from '../../../availability/components/availability-days/availability-days.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InfoBoxComponent } from '../../../shared/components/info-card/info-box.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, AsyncPipe, UpperCasePipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { HourDistributionDisplayComponent } from '../../../shared/components/hour-distribution-display/hour-distribution-display.component';
import { PositionService } from '../../http/position.service';
import { MatDividerModule } from '@angular/material/divider';

export type CombinedContractDialogReturn = CombinedContract;

export interface CombinedContractDialogData extends DialogData {
    customerId: number;
    employeeId: number;
    combinedContract?: Observable<CombinedContract>
    from?: DateTime;
    to?: DateTime;
}

@Component({
    selector: 'eaw-combined-contract-dialog',
    templateUrl: './combined-contract-dialog.component.html',
    styleUrl: './combined-contract-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        InfoBoxComponent,
        MatStepperModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        MatButtonModule,
        MatIconModule,
        AvailabilityDaysComponent,
        MatDialogActions,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        NumberPipe,
        HourDistributionDisplayComponent,
        UpperCasePipe,
        MatDividerModule,
    ],
})
export class CombinedContractDialogComponent extends DialogComponent<CombinedContractDialogData, CombinedContractDialogReturn> implements OnInit, AfterViewInit {
    private readonly settingService = inject(SettingService);
    private readonly translateService = inject(TranslateService);
    private readonly matDialog = inject(MatDialog);
    private readonly combinedContractService = inject(CombinedContractService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly positionService = inject(PositionService);

    @ViewChild(MatStepper) protected matStepper?: MatStepper;

    protected readonly editing;
    protected loading = true;
    protected errorMessage?: Promise<string>;
    // Flag indicating if this employee is working part-time or not
    protected isFullTimeEmployee?: boolean;
    // Number of hours regarded as full time work for employees per week
    protected fullTimeWeekHours?: number | null;
    protected creating = false;
    protected stepperCompleted = false;
    protected validationErrors: string[] = [];
    protected availability?: Availability;

    hourDistributionDays?: number[];
    contractInfo?: ContractDialogFormData | null;
    contractInfoControl = new FormControl<ContractDialogFormData | null>(null, Validators.required);
    availabilityControl = new FormControl<AvailabilityCreate | null>(null, Validators.required);
    hourDistributionControl = new FormControl<HourDistributionDaysDialogResult | null>(null, Validators.required);
    fromToGroup = new FormGroup({
        from: new FormControl<DateTime | null>(null, Validators.required),
        to: new FormControl<DateTime | null>(null),
    });

    constructor() {
        const data = inject(MAT_DIALOG_DATA);
        const dialogRef = inject(MatDialogRef);
        data.size = DialogSize.Large;
        dialogRef.disableClose = true;
        super(dialogRef, data);

        this.editing = !!data.combinedContract;
    }

    ngOnInit() {
        this.fromToGroup.patchValue({
            from: this.data.from ?? null,
            to: this.data.to ?? null,
        });

        this.contractInfoControl.valueChanges.subscribe((value) => {
            this.contractInfo = value;
        });

        this.fromToGroup.valueChanges.subscribe(() => {
            this.contractInfoControl.reset();
        });

        this.hourDistributionControl.valueChanges.subscribe((value) => {
            this.hourDistributionDays = value?.days.map((day) => day.hours) ?? [];
        });

        forkJoin([
            this.data.combinedContract || of(undefined),
            this.getFullTimeWeekSetting(),
        ]).subscribe(([ combinedContract, settings ]) => {
            if (combinedContract) {
                this.updateForm(combinedContract);
            }

            const fullTimeWeekSetting = settings[0] as Setting | undefined;
            this.fullTimeWeekHours = fullTimeWeekSetting?.value?.asFloat() ?? fullTimeWeekSetting?.default?.asFloat();
            this.errorMessage = this.fullTimeWeekHours == null ? this.translateService.t('ERROR_WEEK_HOURS_SETTING', Namespace.FrancePayroll) : undefined;
            this.loading = false;
        });
    }

    ngAfterViewInit() {
        this.fromToGroup.valueChanges.subscribe(this.updateStepperComplete.bind(this));
        this.contractInfoControl.valueChanges.subscribe(this.updateStepperComplete.bind(this));
        this.availabilityControl.valueChanges.subscribe(this.updateAvailability.bind(this));
        this.hourDistributionControl.valueChanges.subscribe(this.updateStepperComplete.bind(this));
    }

    updateAvailability(availability: AvailabilityCreate | null) {
        this.updateStepperComplete();

        const days: AvailabilityResponse['days'] = availability?.days.map((day, index) => {
            const date = availability?.from.plus({ days: index });
            const from = date.startOf('day').plus({ seconds: day.offset });
            const to = from.plus({ seconds: day.length });

            return {
                id: 0,
                availability_id: 0,
                date: DateTimeConverter.convertDateTimeToUtcString(date),
                from: DateTimeConverter.convertDateTimeToUtcString(from),
                to: DateTimeConverter.convertDateTimeToUtcString(to),
                whole_day: day.whole_day,
                day: day.day,
                offset: day.offset,
                length: day.length,
            };
        });

        this.availability = availability ? new Availability({
            ...availability,
            days,
            from: '',
            to: '',
            id: 0,
            employee_id: this.data.employeeId,
            work_days: [],
            created_at: '',
            updated_at: '',
        }) : undefined;
    }

    updateForm(contract: CombinedContract) {
        this.fromToGroup.setValue({
            from: contract.contract.from,
            to: contract.contract.to ?? null,
        });

        this.contractInfoControl.setValue({
            type_id: contract.contract.typeId,
            contract_type: contract.contract.type,
            from: contract.contract.from,
            to: contract.contract.to ?? null,
            amount: contract.contract.amount,
            amount_type: contract.contract.amountType,
            custom_fields: {},
        });

        if (contract.availability) {
            this.availabilityControl.setValue({
                from: contract.contract.from,
                to: contract.contract.to ?? undefined,
                days: contract.availability.days.map((day) => {
                    return {
                        day: day.day,
                        whole_day: day.wholeDay,
                        offset: day.offset ?? 0,
                        length: day.length ?? 0,
                    };
                }),
                repeat: contract.availability.repeat,
                workDays: contract.availability.workDays,
            });
        }

        if (contract.hoursDistribution) {
            this.hourDistributionControl.setValue({
                days: contract.hoursDistribution.contractHoursDays?.map((day) => {
                    return {
                        index: day.index,
                        hours: day.hours,
                    };
                }) || [],
            });
        }
    }

    getFullTimeWeekSetting() {
        return this.settingService.getSome([ 'customers', this.data.customerId ], { 'settings[]': [ 'full_time_week' ] }).pipe(
            catchError(() => {
                this.loading = false;
                this.errorMessage = this.translateService.t('ERROR_GET_REQUIRED_SETTING_plural');
                return EMPTY;
            }),
        );
    }

    updateStepperComplete() {
        // The stepper is a bit slow, so force a change detection before checking the steps
        this.changeDetectorRef.detectChanges();

        const steps = Array.from(this.matStepper?.steps || []);
        if (steps.length) {
            this.stepperCompleted = steps.every((step) => step.optional ? true : step.completed);
        } else {
            this.stepperCompleted = false;
        }
    }

    editHourDistribution() {
        const { from, to } = this.fromToGroup.getRawValue();
        if (!from) {
            return;
        }

        const distribution = new HourDistribution({
            id: 0,
            days: 28,
            employee_id: this.data.employeeId,
            from: '',
            to: '',
            created_at: '',
            updated_at: '',
            contract_hours_days: this.hourDistributionControl.value?.days.map((day, index) => {
                return {
                    id: index,
                    distribution_id: 0,
                    index: day.index,
                    hours: day.hours || 0,
                    created_at: '',
                    updated_at: '',
                };
            }),
            deleted_at: null,
        });

        distribution.from = new BusinessDate(from);
        distribution.to = to ? new BusinessDate(to) : null;

        this.matDialog.open<HourDistributionDaysComponent, HourDistributionDaysDialogData, HourDistributionDaysDialogResult>(HourDistributionDaysComponent, {
            data: {
                customerId: this.data.customerId,
                employeeId: this.data.employeeId,
                noDialogUpdate: true,
                distribution: of(distribution),
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.hourDistributionControl.setValue(result);
            }
        });
    }

    editAvailability() {
        return this.matDialog.open<CreateAvailabilityDialogComponent, CreateAvailabilityDialogData, CreateAvailabilityDialogReturn>(CreateAvailabilityDialogComponent, {
            data: {
                customerId: this.data.customerId,
                employeeId: this.data.employeeId,
                size: DialogSize.Medium,
                returnFormData: true,
                fromTo: this.fromToGroup.getRawValue(),
                defaultAvailabilityDayType: AvailabilityDayType.Off,
            },
        }).afterClosed().subscribe((result) => {
            if (!result || typeof result !== 'object' || result instanceof Availability) {
                return;
            }

            this.availabilityControl.setValue(result);
        });
    }

    editContract() {
        const contractData = this.contractInfoControl.value;

        return this.matDialog.open<ContractDialogComponent, ContractDialogData, ContractDialogReturn>(ContractDialogComponent, {
            data: {
                customerId: this.data.customerId,
                employeeId: this.data.employeeId,
                size: DialogSize.Medium,
                returnFormData: true,
                fromTo: this.fromToGroup.getRawValue(),
                positions: this.positionService.getAll(this.data.customerId, {
                    per_page: 200,
                    'with[]': [ 'properties' ],
                }),
                customFields: contractData?.custom_fields,
                contract: contractData ? new Contract({
                    amount: contractData.amount,
                    amount_type: contractData.amount_type,
                    created_at: '',
                    customer_id: this.data.customerId,
                    employee_id: this.data.employeeId,
                    from: DateTimeConverter.convertDateTimeToUtcString(contractData.from),
                    id: 0,
                    month_hours: 0,
                    percentage: 0,
                    title: contractData.title || undefined,
                    type_id: contractData.type_id,
                    type: contractData.contract_type?._response,
                    updated_at: '',
                    week_hours: 0,
                    year_hours: 0,
                }) : undefined,
            },
        }).afterClosed().subscribe((result) => {
            if (!result || result instanceof Contract || this.fullTimeWeekHours == null) {
                return;
            }

            this.contractInfoControl.setValue(result);
            this.isFullTimeEmployee = Contract.isFullTimeContract(this.fullTimeWeekHours, result.amount, result.amount_type);
            this.updateStepperComplete();
        });
    }

    submit() {
        const contractData = this.contractInfoControl.getRawValue();
        const employeeId = this.data.employeeId;

        if (!contractData) {
            return;
        }

        this.creating = true;
        this.validationErrors = [];

        this.getCreateContractObservable(employeeId, 'validate').pipe(
            switchMap((validationErrors) => {
                if (validationErrors) {
                    this.validationErrors = Object.values(validationErrors).flat();
                    this.creating = false;
                    return EMPTY;
                }

                return this.getCreateContractObservable(employeeId, 'create');
            }),
        ).subscribe((combinedContract) => {
            this.dialogRef.close(combinedContract);
        });
    }

    private getCreateContractObservable(employeeId: number, mode: 'validate'): ReturnType<CombinedContractService['validate']>
    private getCreateContractObservable(employeeId: number, mode: 'create'): ReturnType<CombinedContractService['create']>
    private getCreateContractObservable(employeeId: number, mode: 'validate' | 'create') {
        const { from, to } = this.fromToGroup.getRawValue();
        if (!from) {
            return;
        }

        const contract = this.contractInfoControl.value;
        if (!contract) {
            return;
        }

        const createData = {
            from,
            to: to?.endOf('day') ?? null,
            contract: {
                type_id: contract.type_id,
                title: contract.title || null,
                amount: contract.amount,
                amount_type: contract.amount_type,
            },
            availability: this.availabilityControl.value ?? undefined,
            hours_distribution: this.hourDistributionControl.value?.days ? {
                hour_days: this.hourDistributionControl.value.days,
            } : undefined,
        } satisfies CombinedContractCreateData;

        if (mode === 'validate') {
            return this.combinedContractService.validate(this.data.customerId, employeeId, createData);
        }

        return this.combinedContractService.create(this.data.customerId, employeeId, createData, contract.custom_fields).pipe(
            catchError((error) => {
                console.error(`error`, error);
                this.creating = false;
                return EMPTY;
            }),
        );
    }
}
