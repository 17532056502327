var ei = Object.defineProperty;
var ti = (i, t, e) => t in i ? ei(i, t, {
  enumerable: !0,
  configurable: !0,
  writable: !0,
  value: e
}) : i[t] = e;
var f = (i, t, e) => (ti(i, typeof t != "symbol" ? t + "" : t, e), e),
  nt = (i, t, e) => {
    if (!t.has(i)) throw TypeError("Cannot " + e);
  };
var r = (i, t, e) => (nt(i, t, "read from private field"), e ? e.call(i) : t.get(i)),
  d = (i, t, e) => {
    if (t.has(i)) throw TypeError("Cannot add the same private member more than once");
    t instanceof WeakSet ? t.add(i) : t.set(i, e);
  },
  c = (i, t, e, n) => (nt(i, t, "write to private field"), n ? n.call(i, e) : t.set(i, e), e);
var T = (i, t, e) => (nt(i, t, "access private method"), e);
var de = class {
  addEventListener() {}
  removeEventListener() {}
  dispatchEvent(t) {
    return !0;
  }
};
if (typeof DocumentFragment == "undefined") {
  class i extends de {}
  globalThis.DocumentFragment = i;
}
var Pe = class extends de {},
  at = class extends de {},
  ii = {
    get(i) {},
    define(i, t, e) {},
    upgrade(i) {},
    whenDefined(i) {
      return Promise.resolve(Pe);
    }
  },
  Le,
  ot = class {
    constructor(t, e = {}) {
      d(this, Le, void 0);
      c(this, Le, e == null ? void 0 : e.detail);
    }
    get detail() {
      return r(this, Le);
    }
    initCustomEvent() {}
  };
Le = new WeakMap();
function ri(i, t) {
  return new Pe();
}
var Lt = {
    document: {
      createElement: ri
    },
    DocumentFragment,
    customElements: ii,
    CustomEvent: ot,
    EventTarget: de,
    HTMLElement: Pe,
    HTMLVideoElement: at
  },
  Ot = typeof window == "undefined" || typeof globalThis.customElements == "undefined",
  Oe = Ot ? Lt : globalThis,
  Ii = Ot ? Lt.document : globalThis.document;
import { initialize as ui, teardown as hi, generatePlayerInitTime as li, PlaybackTypes as it, toMuxVideoURL as fi, MediaError as Xr, getError as mi, CmcdTypeValues as Zt, addCuePoints as pi, getCuePoints as gi, getActiveCuePoint as Ei, getStartDate as vi, getCurrentPdt as yi, getStreamType as Ti, getTargetLiveWindow as bi, getLiveEdgeStart as ki, getSeekable as Ai, getEnded as Si } from "@mux/playback-core";
var si = () => {
    try {
      return "0.17.0";
    } catch {}
    return "UNKNOWN";
  },
  ni = si(),
  Ct = () => ni;
var It = i => {
    var t, e, n, a, m, wt, y, We, L, N, M, Ke, ne, $e, qe, Mt, Ue, xt, ae, dt, l, k, S, g, v, P, I, Ce, Be, _t, Ae, ct, Ye, Dt, Se, ut, je, Nt, Re, ht;
    return t = class extends i {
      constructor() {
        super();
        d(this, I);
        d(this, Be);
        d(this, Ae);
        d(this, Ye);
        d(this, Se);
        d(this, je);
        d(this, Re);
        f(this, "castEnabled", !1);
        d(this, l, {
          paused: !1
        });
        d(this, k, void 0);
        d(this, S, {});
        d(this, g, void 0);
        d(this, v, void 0);
        d(this, P, void 0);
        t.instances.add(this), T(this, Se, ut).call(this);
      }
      static get castElement() {
        return r(t, e);
      }
      static get castEnabled() {
        return r(t, n);
      }
      static get castState() {
        var s;
        return (s = r(t, L, N)) == null ? void 0 : s.getCastState();
      }
      static async exitCast() {
        try {
          await r(t, L, N).endCurrentSession(!0);
        } catch (h) {
          console.error(h);
          return;
        }
      }
      get castPlayer() {
        if (t.castElement === this) return r(this, k);
      }
      attributeChangedCallback(s) {
        if (!!this.castPlayer) switch (s) {
          case "cast-stream-type":
          case "cast-src":
            this.load();
            break;
        }
      }
      async requestCast(s = {}) {
        var h;
        T(h = t, ae, dt).call(h, s), c(t, e, this), Object.entries(r(this, S)).forEach(([A, O]) => {
          r(this, k).controller.addEventListener(A, O);
        });
        try {
          await r(t, L, N).requestSession();
        } catch {
          c(t, e, void 0);
          return;
        }
        r(this, l).paused = super.paused, super.pause(), this.muted = super.muted;
        try {
          await this.load();
        } catch (A) {
          console.error(A);
        }
      }
      async load() {
        var D, Q;
        if (!this.castPlayer) return super.load();
        let s = new chrome.cast.media.MediaInfo(this.castSrc, this.castContentType),
          h = [...this.querySelectorAll("track")].filter(({
            kind: x,
            src: w
          }) => w && (x === "subtitles" || x === "captions")),
          A = [],
          O = 0;
        h.length && (s.tracks = h.map(x => {
          let w = ++O;
          A.length === 0 && x.track.mode === "showing" && A.push(w);
          let Z = new chrome.cast.media.Track(w, chrome.cast.media.TrackType.TEXT);
          return Z.trackContentId = x.src, Z.trackContentType = "text/vtt", Z.subtype = x.kind === "captions" ? chrome.cast.media.TextTrackType.CAPTIONS : chrome.cast.media.TextTrackType.SUBTITLES, Z.name = x.label, Z.language = x.srclang, Z;
        })), this.castStreamType === "live" ? s.streamType = chrome.cast.media.StreamType.LIVE : s.streamType = chrome.cast.media.StreamType.BUFFERED, s.metadata = new chrome.cast.media.GenericMediaMetadata(), s.metadata.title = this.title, s.metadata.images = [{
          url: this.poster
        }];
        let C = new chrome.cast.media.LoadRequest(s);
        C.currentTime = (D = super.currentTime) != null ? D : 0, C.autoplay = !r(this, l).paused, C.activeTrackIds = A, await ((Q = r(t, M, Ke)) == null ? void 0 : Q.loadMedia(C)), this.dispatchEvent(new Event("volumechange"));
      }
      play() {
        var s;
        if (this.castPlayer) {
          this.castPlayer.isPaused && ((s = this.castPlayer.controller) == null || s.playOrPause());
          return;
        }
        return super.play();
      }
      pause() {
        var s;
        if (this.castPlayer) {
          this.castPlayer.isPaused || (s = this.castPlayer.controller) == null || s.playOrPause();
          return;
        }
        super.pause();
      }
      get castSrc() {
        var s, h, A;
        return (A = (h = this.getAttribute("cast-src")) != null ? h : (s = this.querySelector("source")) == null ? void 0 : s.src) != null ? A : this.currentSrc;
      }
      set castSrc(s) {
        this.castSrc != s && this.setAttribute("cast-src", `${s}`);
      }
      get castContentType() {
        var s;
        return (s = this.getAttribute("cast-content-type")) != null ? s : void 0;
      }
      set castContentType(s) {
        this.setAttribute("cast-content-type", `${s}`);
      }
      get castStreamType() {
        var s, h;
        return (h = (s = this.getAttribute("cast-stream-type")) != null ? s : this.streamType) != null ? h : void 0;
      }
      set castStreamType(s) {
        this.setAttribute("cast-stream-type", `${s}`);
      }
      get readyState() {
        if (this.castPlayer) switch (this.castPlayer.playerState) {
          case chrome.cast.media.PlayerState.IDLE:
            return 0;
          case chrome.cast.media.PlayerState.BUFFERING:
            return 2;
          default:
            return 3;
        }
        return super.readyState;
      }
      get paused() {
        return this.castPlayer ? this.castPlayer.isPaused : super.paused;
      }
      get muted() {
        var s;
        return this.castPlayer ? (s = this.castPlayer) == null ? void 0 : s.isMuted : super.muted;
      }
      set muted(s) {
        var h;
        if (this.castPlayer) {
          (s && !this.castPlayer.isMuted || !s && this.castPlayer.isMuted) && ((h = this.castPlayer.controller) == null || h.muteOrUnmute());
          return;
        }
        super.muted = s;
      }
      get volume() {
        var s, h;
        return this.castPlayer ? (h = (s = this.castPlayer) == null ? void 0 : s.volumeLevel) != null ? h : 1 : super.volume;
      }
      set volume(s) {
        var h;
        if (this.castPlayer) {
          this.castPlayer.volumeLevel = s, (h = this.castPlayer.controller) == null || h.setVolumeLevel();
          return;
        }
        super.volume = s;
      }
      get duration() {
        var s, h;
        return this.castPlayer && r(this, I, Ce) ? (h = (s = this.castPlayer) == null ? void 0 : s.duration) != null ? h : NaN : super.duration;
      }
      get currentTime() {
        var s, h;
        return this.castPlayer && r(this, I, Ce) ? (h = (s = this.castPlayer) == null ? void 0 : s.currentTime) != null ? h : 0 : super.currentTime;
      }
      set currentTime(s) {
        var h;
        if (this.castPlayer) {
          this.castPlayer.currentTime = s, (h = this.castPlayer.controller) == null || h.seek();
          return;
        }
        super.currentTime = s;
      }
      get onentercast() {
        return r(this, g);
      }
      set onentercast(s) {
        r(this, g) && (this.removeEventListener("entercast", r(this, g)), c(this, g, null)), typeof s == "function" && (c(this, g, s), this.addEventListener("entercast", s));
      }
      get onleavecast() {
        return r(this, v);
      }
      set onleavecast(s) {
        r(this, v) && (this.removeEventListener("leavecast", r(this, v)), c(this, v, null)), typeof s == "function" && (c(this, v, s), this.addEventListener("leavecast", s));
      }
      get oncastchange() {
        return r(this, P);
      }
      set oncastchange(s) {
        r(this, P) && (this.removeEventListener("castchange", r(this, P)), c(this, P, null)), typeof s == "function" && (c(this, P, s), this.addEventListener("castchange", s));
      }
    }, e = new WeakMap(), n = new WeakMap(), a = new WeakMap(), m = new WeakSet(), wt = function () {
      return typeof chrome != "undefined" && chrome.cast && chrome.cast.isAvailable;
    }, y = new WeakSet(), We = function () {
      return typeof cast != "undefined" && cast.framework;
    }, L = new WeakSet(), N = function () {
      if (r(t, y, We)) return cast.framework.CastContext.getInstance();
    }, M = new WeakSet(), Ke = function () {
      var s;
      return (s = r(t, L, N)) == null ? void 0 : s.getCurrentSession();
    }, ne = new WeakSet(), $e = function () {
      var s;
      return (s = r(t, M, Ke)) == null ? void 0 : s.getSessionObj().media[0];
    }, qe = new WeakSet(), Mt = function (s) {
      return new Promise((h, A) => {
        r(t, ne, $e).editTracksInfo(s, h, A);
      });
    }, Ue = new WeakSet(), xt = function (s) {
      return new Promise((h, A) => {
        r(t, ne, $e).getStatus(s, h, A);
      });
    }, ae = new WeakSet(), dt = function (s) {
      return r(t, L, N).setOptions({
        receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
        autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
        androidReceiverCompatible: !1,
        language: "en-US",
        resumeSavedSession: !0,
        ...s
      });
    }, l = new WeakMap(), k = new WeakMap(), S = new WeakMap(), g = new WeakMap(), v = new WeakMap(), P = new WeakMap(), I = new WeakSet(), Ce = function () {
      var s;
      return (s = this.castPlayer) == null ? void 0 : s.isMediaLoaded;
    }, Be = new WeakSet(), _t = function () {
      r(t, e) === this && (Object.entries(r(this, S)).forEach(([s, h]) => {
        r(this, k).controller.removeEventListener(s, h);
      }), c(t, e, void 0), this.muted = r(this, k).isMuted, this.currentTime = r(this, k).savedPlayerState.currentTime, r(this, k).savedPlayerState.isPaused === !1 && this.play());
    }, Ae = new WeakSet(), ct = function () {
      this.dispatchEvent(new CustomEvent("castchange", {
        detail: r(t, L, N).getCastState()
      }));
    }, Ye = new WeakSet(), Dt = async function () {
      var h, A;
      let {
        SESSION_RESUMED: s
      } = cast.framework.SessionState;
      if (r(t, L, N).getSessionState() === s && this.castSrc === ((h = r(t, ne, $e)) == null ? void 0 : h.media.contentId)) {
        c(t, e, this), Object.entries(r(this, S)).forEach(([O, C]) => {
          r(this, k).controller.addEventListener(O, C);
        });
        try {
          await T(A = t, Ue, xt).call(A, new chrome.cast.media.GetStatusRequest());
        } catch (O) {
          console.error(O);
        }
        r(this, S)[cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED](), r(this, S)[cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED]();
      }
    }, Se = new WeakSet(), ut = function () {
      var s;
      !r(t, y, We) || this.castEnabled || (this.castEnabled = !0, T(s = t, ae, dt).call(s), this.textTracks.addEventListener("change", T(this, Re, ht).bind(this)), T(this, Ae, ct).call(this), c(this, k, new cast.framework.RemotePlayer()), new cast.framework.RemotePlayerController(r(this, k)), c(this, S, {
        [cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED]: ({
          value: h
        }) => {
          h === !1 && T(this, Be, _t).call(this), this.dispatchEvent(new Event(h ? "entercast" : "leavecast"));
        },
        [cast.framework.RemotePlayerEventType.DURATION_CHANGED]: () => {
          this.dispatchEvent(new Event("durationchange"));
        },
        [cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED]: () => {
          this.dispatchEvent(new Event("volumechange"));
        },
        [cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED]: () => {
          this.dispatchEvent(new Event("volumechange"));
        },
        [cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED]: () => {
          !r(this, I, Ce) || this.dispatchEvent(new Event("timeupdate"));
        },
        [cast.framework.RemotePlayerEventType.VIDEO_INFO_CHANGED]: () => {
          this.dispatchEvent(new Event("resize"));
        },
        [cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED]: () => {
          this.dispatchEvent(new Event(this.paused ? "pause" : "play"));
        },
        [cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED]: () => {
          var h, A;
          ((h = this.castPlayer) == null ? void 0 : h.playerState) !== chrome.cast.media.PlayerState.PAUSED && this.dispatchEvent(new Event({
            [chrome.cast.media.PlayerState.PLAYING]: "playing",
            [chrome.cast.media.PlayerState.BUFFERING]: "waiting",
            [chrome.cast.media.PlayerState.IDLE]: "emptied"
          }[(A = this.castPlayer) == null ? void 0 : A.playerState]));
        },
        [cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED]: async () => {
          !r(this, I, Ce) || (await Promise.resolve(), T(this, je, Nt).call(this));
        }
      }));
    }, je = new WeakSet(), Nt = function () {
      T(this, Re, ht).call(this);
    }, Re = new WeakSet(), ht = async function () {
      var vt, yt, Tt, bt, kt, At;
      if (!this.castPlayer) return;
      let h = ((yt = (vt = r(this, k).mediaInfo) == null ? void 0 : vt.tracks) != null ? yt : []).filter(({
          type: R
        }) => R === chrome.cast.media.TrackType.TEXT),
        A = [...this.textTracks].filter(({
          kind: R
        }) => R === "subtitles" || R === "captions"),
        O = h.map(({
          language: R,
          name: He,
          trackId: st
        }) => {
          var Rt;
          let {
            mode: St
          } = (Rt = A.find(Pt => Pt.language === R && Pt.label === He)) != null ? Rt : {};
          return St ? {
            mode: St,
            trackId: st
          } : !1;
        }).filter(Boolean),
        D = O.filter(({
          mode: R
        }) => R !== "showing").map(({
          trackId: R
        }) => R),
        Q = O.find(({
          mode: R
        }) => R === "showing"),
        x = (kt = (bt = (Tt = r(t, M, Ke)) == null ? void 0 : Tt.getSessionObj().media[0]) == null ? void 0 : bt.activeTrackIds) != null ? kt : [],
        w = x;
      if (x.length && (w = w.filter(R => !D.includes(R))), Q != null && Q.trackId && (w = [...w, Q.trackId]), w = [...new Set(w)], !((R, He) => R.length === He.length && R.every(st => He.includes(st)))(x, w)) try {
        let R = new chrome.cast.media.EditTracksInfoRequest(w);
        await T(At = t, qe, Mt).call(At, R);
      } catch (R) {
        console.error(R);
      }
    }, d(t, m), d(t, y), d(t, L), d(t, M), d(t, ne), d(t, qe), d(t, Ue), d(t, ae), f(t, "observedAttributes", ["cast-src", "cast-content-type", "cast-stream-type"]), f(t, "instances", new Set()), d(t, e, void 0), d(t, n, !1), f(t, "initCast", () => {
      var s;
      r(t, m, wt) ? r(t, y, We) ? r(s = t, a).call(s, chrome.cast.isAvailable) : customElements.whenDefined("google-cast-button").then(() => {
        var h;
        return r(h = t, a).call(h, chrome.cast.isAvailable);
      }) : globalThis.__onGCastApiAvailable = () => {
        customElements.whenDefined("google-cast-button").then(() => {
          var h;
          return r(h = t, a).call(h, chrome.cast.isAvailable);
        });
      };
    }), d(t, a, s => {
      if (s) {
        c(t, n, !0);
        let {
          CAST_STATE_CHANGED: h
        } = cast.framework.CastContextEventType;
        r(t, L, N).addEventListener(h, O => {
          t.instances.forEach(C => {
            var D;
            return T(D = C, Ae, ct).call(D, O);
          });
        });
        let {
          SESSION_STATE_CHANGED: A
        } = cast.framework.CastContextEventType;
        r(t, L, N).addEventListener(A, O => {
          t.instances.forEach(C => {
            var D;
            return T(D = C, Ye, Dt).call(D, O);
          });
        }), t.instances.forEach(O => {
          var C;
          return T(C = O, Se, ut).call(C);
        });
      }
    }), t;
  },
  lt = globalThis.HTMLVideoElement ? It(HTMLVideoElement) : It(Object);
globalThis.customElements && !globalThis.customElements.get("castable-video") && (customElements.define("castable-video", lt, {
  extends: "video"
}), globalThis.CastableVideoElement = lt);
lt.initCast();
var pt = ["abort", "canplay", "canplaythrough", "durationchange", "emptied", "encrypted", "ended", "error", "loadeddata", "loadedmetadata", "loadstart", "pause", "play", "playing", "progress", "ratechange", "seeked", "seeking", "stalled", "suspend", "timeupdate", "volumechange", "waiting", "waitingforkey", "resize", "enterpictureinpicture", "leavepictureinpicture", "webkitbeginfullscreen", "webkitendfullscreen", "webkitpresentationmodechanged"],
  Vt,
  ft = (Vt = globalThis.document) == null ? void 0 : Vt.createElement("template");
ft && (ft.innerHTML = `
    <style>
      :host {
        display: inline-block;
        line-height: 0;
      }

      audio {
        max-width: 100%;
        max-height: 100%;
        min-width: 100%;
        min-height: 100%;
      }
    </style>
    <slot></slot>
  `);
var Ft,
  mt = (Ft = globalThis.document) == null ? void 0 : Ft.createElement("template");
mt && (mt.innerHTML = `
    <style>
      :host {
        display: inline-block;
        line-height: 0;
      }

      video {
        max-width: 100%;
        max-height: 100%;
        min-width: 100%;
        min-height: 100%;
        object-fit: var(--media-object-fit, contain);
        object-position: var(--media-object-position, 50% 50%);
      }

      video::-webkit-media-text-track-container {
        transform: var(--media-webkit-text-track-transform);
        transition: var(--media-webkit-text-track-transition);
      }
    </style>
    <slot></slot>
  `);
var ze = (i, {
  tag: t,
  is: e
}) => {
  var m, E, y, b, qt, ke, M, q, ce, Fe, Gt, Ge, Ut;
  let n = (m = globalThis.document) == null ? void 0 : m.createElement(t, {
      is: e
    }),
    a = n ? ai(n) : [];
  return E = class extends i {
    constructor() {
      super();
      d(this, q);
      d(this, Fe);
      d(this, Ge);
      d(this, ke, void 0);
      d(this, M, void 0);
      this.shadowRoot || (this.attachShadow({
        mode: "open"
      }), this.shadowRoot.append(this.constructor.template.content.cloneNode(!0)));
    }
    static get observedAttributes() {
      var l, k, S;
      return T(l = E, b, qt).call(l), [...((S = (k = n == null ? void 0 : n.constructor) == null ? void 0 : k.observedAttributes) != null ? S : []), "autopictureinpicture", "disablepictureinpicture", "disableremoteplayback", "autoplay", "controls", "controlslist", "crossorigin", "loop", "muted", "playsinline", "poster", "preload", "src"];
    }
    get nativeEl() {
      var p, l;
      return (l = (p = r(this, M)) != null ? p : this.shadowRoot.querySelector(t)) != null ? l : this.querySelector(t);
    }
    set nativeEl(p) {
      c(this, M, p);
    }
    get defaultMuted() {
      return this.hasAttribute("muted");
    }
    set defaultMuted(p) {
      this.toggleAttribute("muted", Boolean(p));
    }
    get src() {
      return this.getAttribute("src");
    }
    set src(p) {
      this.setAttribute("src", `${p}`);
    }
    get preload() {
      var p, l;
      return (l = this.getAttribute("preload")) != null ? l : (p = this.nativeEl) == null ? void 0 : p.preload;
    }
    set preload(p) {
      this.setAttribute("preload", `${p}`);
    }
    attributeChangedCallback(p, l, k) {
      T(this, q, ce).call(this), T(this, Ge, Ut).call(this, p, l, k);
    }
    connectedCallback() {
      T(this, q, ce).call(this);
    }
  }, y = new WeakMap(), b = new WeakSet(), qt = function () {
    if (r(this, y)) return;
    c(this, y, !0);
    let p = new Set(this.observedAttributes);
    p.delete("muted");
    for (let l of a) {
      if (l in this.prototype) continue;
      if (typeof n[l] == "function") this.prototype[l] = function (...S) {
        return T(this, q, ce).call(this), (() => this.call ? this.call(l, ...S) : this.nativeEl[l].apply(this.nativeEl, S))();
      };else {
        let S = {
          get() {
            var v, P, I;
            T(this, q, ce).call(this);
            let g = l.toLowerCase();
            if (p.has(g)) {
              let oe = this.getAttribute(g);
              return oe === null ? !1 : oe === "" ? !0 : oe;
            }
            return (I = (v = this.get) == null ? void 0 : v.call(this, l)) != null ? I : (P = this.nativeEl) == null ? void 0 : P[l];
          }
        };
        l !== l.toUpperCase() && (S.set = function (g) {
          T(this, q, ce).call(this);
          let v = l.toLowerCase();
          if (p.has(v)) {
            g === !0 || g === !1 || g == null ? this.toggleAttribute(v, Boolean(g)) : this.setAttribute(v, g);
            return;
          }
          if (this.set) {
            this.set(l, g);
            return;
          }
          this.nativeEl[l] = g;
        }), Object.defineProperty(this.prototype, l, S);
      }
    }
  }, ke = new WeakMap(), M = new WeakMap(), q = new WeakSet(), ce = function () {
    var k, S;
    if (r(this, ke)) return;
    if (c(this, ke, !0), !this.nativeEl) {
      let g = document.createElement(t, {
        is: e
      });
      g.part = t, this.shadowRoot.append(g);
    }
    this.nativeEl.muted = this.hasAttribute("muted");
    for (let g of a) T(this, Fe, Gt).call(this, g);
    let p = new Map(),
      l = this.shadowRoot.querySelector("slot:not([name])");
    l == null || l.addEventListener("slotchange", () => {
      let g = new Map(p);
      l.assignedElements().filter(v => ["track", "source"].includes(v.localName)).forEach(v => {
        var I, oe;
        g.delete(v);
        let P = p.get(v);
        P || (P = v.cloneNode(), p.set(v, P)), (oe = (I = this.nativeEl).append) == null || oe.call(I, P);
      }), g.forEach(v => v.remove());
    });
    for (let g of this.constructor.Events) (S = (k = this.shadowRoot).addEventListener) == null || S.call(k, g, v => {
      v.target === this.nativeEl && this.dispatchEvent(new CustomEvent(v.type, {
        detail: v.detail
      }));
    }, !0);
  }, Fe = new WeakSet(), Gt = function (p) {
    if (Object.prototype.hasOwnProperty.call(this, p)) {
      let l = this[p];
      delete this[p], this[p] = l;
    }
  }, Ge = new WeakSet(), Ut = function (p, l, k) {
    var S, g, v, P;
    ["id", "class"].includes(p) || (k === null ? (g = (S = this.nativeEl).removeAttribute) == null || g.call(S, p) : (P = (v = this.nativeEl).setAttribute) == null || P.call(v, p, k));
  }, d(E, b), f(E, "Events", pt), f(E, "template", t.endsWith("audio") ? ft : mt), d(E, y, void 0), E;
};
function ai(i) {
  let t = [];
  for (let e = Object.getPrototypeOf(i); e && e !== HTMLElement.prototype; e = Object.getPrototypeOf(e)) t.push(...Object.getOwnPropertyNames(e));
  return t;
}
var Ni = globalThis.document ? ze(HTMLElement, {
    tag: "video"
  }) : class {},
  Vi = globalThis.document ? ze(HTMLElement, {
    tag: "audio"
  }) : class {};
var V = class extends Event {
  constructor(e, n) {
    super(e);
    f(this, "track");
    this.track = n.track;
  }
};
var gt = new WeakMap();
function o(i) {
  var t;
  return (t = gt.get(i)) != null ? t : oi(i, {});
}
function oi(i, t) {
  let e = gt.get(i);
  return e || gt.set(i, e = {}), Object.assign(e, t);
}
function Xe(i, t) {
  let e = i.videoTracks;
  o(t).media = i, o(t).renditionSet || (o(t).renditionSet = new Set());
  let n = o(e).trackSet;
  n.add(t);
  let a = n.size - 1;
  a in te.prototype || Object.defineProperty(te.prototype, a, {
    get() {
      return [...o(this).trackSet][a];
    }
  }), queueMicrotask(() => {
    e.dispatchEvent(new V("addtrack", {
      track: t
    }));
  });
}
function Je(i) {
  var n;
  let t = (n = o(i).media) == null ? void 0 : n.videoTracks;
  if (!t) return;
  o(t).trackSet.delete(i), queueMicrotask(() => {
    t.dispatchEvent(new V("removetrack", {
      track: i
    }));
  });
}
function Bt(i) {
  var n;
  let t = (n = o(i).media.videoTracks) != null ? n : [],
    e = !1;
  for (let a of t) a !== i && (a.selected = !1, e = !0);
  if (e) {
    if (o(t).changeRequested) return;
    o(t).changeRequested = !0, queueMicrotask(() => {
      delete o(t).changeRequested, t.dispatchEvent(new Event("change"));
    });
  }
}
var G,
  U,
  B,
  ee,
  Ie,
  te = class extends EventTarget {
    constructor() {
      super();
      d(this, ee);
      d(this, G, void 0);
      d(this, U, void 0);
      d(this, B, void 0);
      o(this).trackSet = new Set();
    }
    [Symbol.iterator]() {
      return r(this, ee, Ie).values();
    }
    get length() {
      return r(this, ee, Ie).size;
    }
    getTrackById(e) {
      var n;
      return (n = [...r(this, ee, Ie)].find(a => a.id === e)) != null ? n : null;
    }
    get selectedIndex() {
      return [...r(this, ee, Ie)].findIndex(e => e.selected);
    }
    get onaddtrack() {
      return r(this, G);
    }
    set onaddtrack(e) {
      r(this, G) && (this.removeEventListener("addtrack", r(this, G)), c(this, G, void 0)), typeof e == "function" && (c(this, G, e), this.addEventListener("addtrack", e));
    }
    get onremovetrack() {
      return r(this, U);
    }
    set onremovetrack(e) {
      r(this, U) && (this.removeEventListener("removetrack", r(this, U)), c(this, U, void 0)), typeof e == "function" && (c(this, U, e), this.addEventListener("removetrack", e));
    }
    get onchange() {
      return r(this, B);
    }
    set onchange(e) {
      r(this, B) && (this.removeEventListener("change", r(this, B)), c(this, B, void 0)), typeof e == "function" && (c(this, B, e), this.addEventListener("change", e));
    }
  };
G = new WeakMap(), U = new WeakMap(), B = new WeakMap(), ee = new WeakSet(), Ie = function () {
  return o(this).trackSet;
};
var F = class extends Event {
  constructor(e, n) {
    super(e);
    f(this, "rendition");
    this.rendition = n.rendition;
  }
};
function Yt(i, t) {
  let e = o(i).media.videoRenditions;
  o(t).media = o(i).media, o(t).track = i;
  let n = o(i).renditionSet;
  n.add(t);
  let a = n.size - 1;
  a in ie.prototype || Object.defineProperty(ie.prototype, a, {
    get() {
      return ue(this)[a];
    }
  }), queueMicrotask(() => {
    !i.selected || e.dispatchEvent(new F("addrendition", {
      rendition: t
    }));
  });
}
function jt(i) {
  let t = o(i).media.videoRenditions,
    e = o(i).track;
  o(e).renditionSet.delete(i), queueMicrotask(() => {
    !o(i).track.selected || t.dispatchEvent(new F("removerendition", {
      rendition: i
    }));
  });
}
function Ht(i) {
  let t = o(i).media.videoRenditions;
  !t || o(t).changeRequested || (o(t).changeRequested = !0, queueMicrotask(() => {
    delete o(t).changeRequested, o(i).track.selected && t.dispatchEvent(new Event("change"));
  }));
}
function ue(i) {
  return [...o(i).media.videoTracks].filter(e => e.selected).flatMap(e => [...o(e).renditionSet]);
}
var Y,
  j,
  H,
  ie = class extends EventTarget {
    constructor() {
      super(...arguments);
      d(this, Y, void 0);
      d(this, j, void 0);
      d(this, H, void 0);
    }
    [Symbol.iterator]() {
      return ue(this).values();
    }
    get length() {
      return ue(this).length;
    }
    getRenditionById(e) {
      var n;
      return (n = ue(this).find(a => `${a.id}` == `${e}`)) != null ? n : null;
    }
    get selectedIndex() {
      return ue(this).findIndex(e => e.selected);
    }
    set selectedIndex(e) {
      for (let [n, a] of ue(this).entries()) a.selected = n === e;
    }
    get onaddrendition() {
      return r(this, Y);
    }
    set onaddrendition(e) {
      r(this, Y) && (this.removeEventListener("addrendition", r(this, Y)), c(this, Y, void 0)), typeof e == "function" && (c(this, Y, e), this.addEventListener("addrendition", e));
    }
    get onremoverendition() {
      return r(this, j);
    }
    set onremoverendition(e) {
      r(this, j) && (this.removeEventListener("removerendition", r(this, j)), c(this, j, void 0)), typeof e == "function" && (c(this, j, e), this.addEventListener("removerendition", e));
    }
    get onchange() {
      return r(this, H);
    }
    set onchange(e) {
      r(this, H) && (this.removeEventListener("change", r(this, H)), c(this, H, void 0)), typeof e == "function" && (c(this, H, e), this.addEventListener("change", e));
    }
  };
Y = new WeakMap(), j = new WeakMap(), H = new WeakMap();
var he,
  we = class {
    constructor() {
      f(this, "src");
      f(this, "id");
      f(this, "width");
      f(this, "height");
      f(this, "bitrate");
      f(this, "frameRate");
      f(this, "codec");
      d(this, he, !1);
    }
    get selected() {
      return r(this, he);
    }
    set selected(t) {
      r(this, he) !== t && (c(this, he, t), Ht(this));
    }
  };
he = new WeakMap();
var le,
  fe = class {
    constructor() {
      f(this, "id");
      f(this, "kind");
      f(this, "label", "");
      f(this, "language", "");
      f(this, "sourceBuffer");
      d(this, le, !1);
    }
    addRendition(t, e, n, a, m, E) {
      let y = new we();
      return y.src = t, y.width = e, y.height = n, y.frameRate = E, y.bitrate = m, y.codec = a, Yt(this, y), y;
    }
    removeRendition(t) {
      jt(t);
    }
    get selected() {
      return r(this, le);
    }
    set selected(t) {
      r(this, le) !== t && (c(this, le, t), t === !0 && Bt(this));
    }
  };
le = new WeakMap();
function Wt(i, t) {
  let e = o(i).media.audioRenditions;
  o(t).media = o(i).media, o(t).track = i;
  let n = o(i).renditionSet;
  n.add(t);
  let a = n.size - 1;
  a in re.prototype || Object.defineProperty(re.prototype, a, {
    get() {
      return me(this)[a];
    }
  }), queueMicrotask(() => {
    !i.enabled || e.dispatchEvent(new F("addrendition", {
      rendition: t
    }));
  });
}
function Kt(i) {
  let t = o(i).media.audioRenditions,
    e = o(i).track;
  o(e).renditionSet.delete(i), queueMicrotask(() => {
    !o(i).track.enabled || t.dispatchEvent(new F("removerendition", {
      rendition: i
    }));
  });
}
function $t(i) {
  let t = o(i).media.audioRenditions;
  !t || o(t).changeRequested || (o(t).changeRequested = !0, queueMicrotask(() => {
    delete o(t).changeRequested, o(i).track.enabled && t.dispatchEvent(new Event("change"));
  }));
}
function me(i) {
  return [...o(i).media.audioTracks].filter(e => e.enabled).flatMap(e => [...o(e).renditionSet]);
}
var W,
  K,
  $,
  re = class extends EventTarget {
    constructor() {
      super(...arguments);
      d(this, W, void 0);
      d(this, K, void 0);
      d(this, $, void 0);
    }
    [Symbol.iterator]() {
      return me(this).values();
    }
    get length() {
      return me(this).length;
    }
    getRenditionById(e) {
      var n;
      return (n = me(this).find(a => `${a.id}` == `${e}`)) != null ? n : null;
    }
    get selectedIndex() {
      return me(this).findIndex(e => e.selected);
    }
    set selectedIndex(e) {
      for (let [n, a] of me(this).entries()) a.selected = n === e;
    }
    get onaddrendition() {
      return r(this, W);
    }
    set onaddrendition(e) {
      r(this, W) && (this.removeEventListener("addrendition", r(this, W)), c(this, W, void 0)), typeof e == "function" && (c(this, W, e), this.addEventListener("addrendition", e));
    }
    get onremoverendition() {
      return r(this, K);
    }
    set onremoverendition(e) {
      r(this, K) && (this.removeEventListener("removerendition", r(this, K)), c(this, K, void 0)), typeof e == "function" && (c(this, K, e), this.addEventListener("removerendition", e));
    }
    get onchange() {
      return r(this, $);
    }
    set onchange(e) {
      r(this, $) && (this.removeEventListener("change", r(this, $)), c(this, $, void 0)), typeof e == "function" && (c(this, $, e), this.addEventListener("change", e));
    }
  };
W = new WeakMap(), K = new WeakMap(), $ = new WeakMap();
var pe,
  Me = class {
    constructor() {
      f(this, "src");
      f(this, "id");
      f(this, "bitrate");
      f(this, "codec");
      d(this, pe, !1);
    }
    get selected() {
      return r(this, pe);
    }
    set selected(t) {
      r(this, pe) !== t && (c(this, pe, t), $t(this));
    }
  };
pe = new WeakMap();
function Ze(i, t) {
  let e = i.audioTracks;
  o(t).media = i, o(t).renditionSet || (o(t).renditionSet = new Set());
  let n = o(e).trackSet;
  n.add(t);
  let a = n.size - 1;
  a in se.prototype || Object.defineProperty(se.prototype, a, {
    get() {
      return [...o(this).trackSet][a];
    }
  }), queueMicrotask(() => {
    e.dispatchEvent(new V("addtrack", {
      track: t
    }));
  });
}
function et(i) {
  var n;
  let t = (n = o(i).media) == null ? void 0 : n.audioTracks;
  if (!t) return;
  o(t).trackSet.delete(i), queueMicrotask(() => {
    t.dispatchEvent(new V("removetrack", {
      track: i
    }));
  });
}
function zt(i) {
  let t = o(i).media.audioTracks;
  !t || o(t).changeRequested || (o(t).changeRequested = !0, queueMicrotask(() => {
    delete o(t).changeRequested, t.dispatchEvent(new Event("change"));
  }));
}
var z,
  X,
  J,
  ge,
  Qe,
  se = class extends EventTarget {
    constructor() {
      super();
      d(this, ge);
      d(this, z, void 0);
      d(this, X, void 0);
      d(this, J, void 0);
      o(this).trackSet = new Set();
    }
    [Symbol.iterator]() {
      return r(this, ge, Qe).values();
    }
    get length() {
      return r(this, ge, Qe).size;
    }
    getTrackById(e) {
      var n;
      return (n = [...r(this, ge, Qe)].find(a => a.id === e)) != null ? n : null;
    }
    get onaddtrack() {
      return r(this, z);
    }
    set onaddtrack(e) {
      r(this, z) && (this.removeEventListener("addtrack", r(this, z)), c(this, z, void 0)), typeof e == "function" && (c(this, z, e), this.addEventListener("addtrack", e));
    }
    get onremovetrack() {
      return r(this, X);
    }
    set onremovetrack(e) {
      r(this, X) && (this.removeEventListener("removetrack", r(this, X)), c(this, X, void 0)), typeof e == "function" && (c(this, X, e), this.addEventListener("removetrack", e));
    }
    get onchange() {
      return r(this, J);
    }
    set onchange(e) {
      r(this, J) && (this.removeEventListener("change", r(this, J)), c(this, J, void 0)), typeof e == "function" && (c(this, J, e), this.addEventListener("change", e));
    }
  };
z = new WeakMap(), X = new WeakMap(), J = new WeakMap(), ge = new WeakSet(), Qe = function () {
  return o(this).trackSet;
};
var Ee,
  ve = class {
    constructor() {
      f(this, "id");
      f(this, "kind");
      f(this, "label", "");
      f(this, "language", "");
      f(this, "sourceBuffer");
      d(this, Ee, !1);
    }
    addRendition(t, e, n) {
      let a = new Me();
      return a.src = t, a.codec = e, a.bitrate = n, Wt(this, a), a;
    }
    removeRendition(t) {
      Kt(t);
    }
    get enabled() {
      return r(this, Ee);
    }
    set enabled(t) {
      r(this, Ee) !== t && (c(this, Ee, t), zt(this));
    }
  };
Ee = new WeakMap();
var Xt = tt(globalThis.HTMLMediaElement, "video"),
  Jt = tt(globalThis.HTMLMediaElement, "audio");
function Qt(i) {
  if (!(i != null && i.prototype)) return i;
  let t = tt(i, "video");
  (!t || `${t}`.includes("[native code]")) && Object.defineProperty(i.prototype, "videoTracks", {
    get() {
      return di(this);
    }
  });
  let e = tt(i, "audio");
  (!e || `${e}`.includes("[native code]")) && Object.defineProperty(i.prototype, "audioTracks", {
    get() {
      return ci(this);
    }
  }), "addVideoTrack" in i.prototype || (i.prototype.addVideoTrack = function (m, E = "", y = "") {
    let b = new fe();
    return b.kind = m, b.label = E, b.language = y, Xe(this, b), b;
  }), "removeVideoTrack" in i.prototype || (i.prototype.removeVideoTrack = Je), "addAudioTrack" in i.prototype || (i.prototype.addAudioTrack = function (m, E = "", y = "") {
    let b = new ve();
    return b.kind = m, b.label = E, b.language = y, Ze(this, b), b;
  }), "removeAudioTrack" in i.prototype || (i.prototype.removeAudioTrack = et), "videoRenditions" in i.prototype || Object.defineProperty(i.prototype, "videoRenditions", {
    get() {
      return n(this);
    }
  });
  let n = m => {
    let E = o(m).videoRenditions;
    return E || (E = new ie(), o(E).media = m, o(m).videoRenditions = E), E;
  };
  "audioRenditions" in i.prototype || Object.defineProperty(i.prototype, "audioRenditions", {
    get() {
      return a(this);
    }
  });
  let a = m => {
    let E = o(m).audioRenditions;
    return E || (E = new re(), o(E).media = m, o(m).audioRenditions = E), E;
  };
  return i;
}
function tt(i, t) {
  var e;
  if (i != null && i.prototype) return (e = Object.getOwnPropertyDescriptor(i.prototype, `${t}Tracks`)) == null ? void 0 : e.get;
}
function di(i) {
  var e;
  let t = o(i).videoTracks;
  if (!t && (t = new te(), o(i).videoTracks = t, Xt)) {
    let n = Xt.call((e = i.nativeEl) != null ? e : i);
    for (let a of n) Xe(i, a);
    n.addEventListener("change", () => {
      t.dispatchEvent(new Event("change"));
    }), n.addEventListener("addtrack", a => {
      if ([...t].some(m => m instanceof fe)) {
        for (let m of n) Je(m);
        return;
      }
      Xe(i, a.track);
    }), n.addEventListener("removetrack", a => {
      Je(a.track);
    });
  }
  return t;
}
function ci(i) {
  var e;
  let t = o(i).audioTracks;
  if (!t && (t = new se(), o(i).audioTracks = t, Jt)) {
    let n = Jt.call((e = i.nativeEl) != null ? e : i);
    for (let a of n) Ze(i, a);
    n.addEventListener("change", () => {
      t.dispatchEvent(new Event("change"));
    }), n.addEventListener("addtrack", a => {
      if ([...t].some(m => m instanceof ve)) {
        for (let m of n) et(m);
        return;
      }
      Ze(i, a.track);
    }), n.addEventListener("removetrack", a => {
      et(a.track);
    });
  }
  return t;
}
pt.push("castchange", "entercast", "leavecast");
var Et = Qt(ze(Oe.HTMLElement, {
    tag: "video",
    is: "castable-video"
  })),
  u = {
    BEACON_COLLECTION_DOMAIN: "beacon-collection-domain",
    CUSTOM_DOMAIN: "custom-domain",
    DEBUG: "debug",
    DISABLE_COOKIES: "disable-cookies",
    ENV_KEY: "env-key",
    MAX_RESOLUTION: "max-resolution",
    MIN_RESOLUTION: "min-resolution",
    RENDITION_ORDER: "rendition-order",
    METADATA_URL: "metadata-url",
    PLAYBACK_ID: "playback-id",
    PLAYER_SOFTWARE_NAME: "player-software-name",
    PLAYER_SOFTWARE_VERSION: "player-software-version",
    PREFER_CMCD: "prefer-cmcd",
    PREFER_PLAYBACK: "prefer-playback",
    START_TIME: "start-time",
    STREAM_TYPE: "stream-type",
    TARGET_LIVE_WINDOW: "target-live-window",
    LIVE_EDGE_OFFSET: "live-edge-offset",
    TYPE: "type"
  },
  Ri = Object.values(u),
  Pi = Ct(),
  Li = "mux-video",
  _,
  ye,
  _e,
  Te,
  De,
  Ne,
  Ve,
  be,
  rt,
  xe = class extends Et {
    constructor() {
      super();
      d(this, be);
      d(this, _, void 0);
      d(this, ye, void 0);
      d(this, _e, void 0);
      d(this, Te, {});
      d(this, De, void 0);
      d(this, Ne, void 0);
      d(this, Ve, void 0);
      c(this, _e, li());
    }
    static get observedAttributes() {
      var e;
      return [...Ri, ...((e = Et.observedAttributes) != null ? e : [])];
    }
    get preferCmcd() {
      var e;
      return (e = this.getAttribute(u.PREFER_CMCD)) != null ? e : void 0;
    }
    set preferCmcd(e) {
      e !== this.preferCmcd && (e ? Zt.includes(e) ? this.setAttribute(u.PREFER_CMCD, e) : console.warn(`Invalid value for preferCmcd. Must be one of ${Zt.join()}`) : this.removeAttribute(u.PREFER_CMCD));
    }
    get playerInitTime() {
      return r(this, _e);
    }
    get playerSoftwareName() {
      var e;
      return (e = r(this, Ne)) != null ? e : Li;
    }
    set playerSoftwareName(e) {
      c(this, Ne, e);
    }
    get playerSoftwareVersion() {
      var e;
      return (e = r(this, De)) != null ? e : Pi;
    }
    set playerSoftwareVersion(e) {
      c(this, De, e);
    }
    get _hls() {
      var e;
      return (e = r(this, _)) == null ? void 0 : e.engine;
    }
    get mux() {
      var e;
      return (e = this.nativeEl) == null ? void 0 : e.mux;
    }
    get error() {
      var e;
      return (e = mi(this.nativeEl)) != null ? e : null;
    }
    get errorTranslator() {
      return r(this, Ve);
    }
    set errorTranslator(e) {
      c(this, Ve, e);
    }
    get src() {
      return this.getAttribute("src");
    }
    set src(e) {
      e !== this.src && (e == null ? this.removeAttribute("src") : this.setAttribute("src", e));
    }
    get type() {
      var e;
      return (e = this.getAttribute(u.TYPE)) != null ? e : void 0;
    }
    set type(e) {
      e !== this.type && (e ? this.setAttribute(u.TYPE, e) : this.removeAttribute(u.TYPE));
    }
    get autoplay() {
      let e = this.getAttribute("autoplay");
      return e === null ? !1 : e === "" ? !0 : e;
    }
    set autoplay(e) {
      let n = this.autoplay;
      e !== n && (e ? this.setAttribute("autoplay", typeof e == "string" ? e : "") : this.removeAttribute("autoplay"));
    }
    get preload() {
      let e = this.getAttribute("preload");
      return e === "" ? "auto" : ["none", "metadata", "auto"].includes(e) ? e : super.preload;
    }
    set preload(e) {
      e != this.getAttribute("preload") && (["", "none", "metadata", "auto"].includes(e) ? this.setAttribute("preload", e) : this.removeAttribute("preload"));
    }
    get debug() {
      return this.getAttribute(u.DEBUG) != null;
    }
    set debug(e) {
      e !== this.debug && (e ? this.setAttribute(u.DEBUG, "") : this.removeAttribute(u.DEBUG));
    }
    get disableCookies() {
      return this.hasAttribute(u.DISABLE_COOKIES);
    }
    set disableCookies(e) {
      e !== this.disableCookies && (e ? this.setAttribute(u.DISABLE_COOKIES, "") : this.removeAttribute(u.DISABLE_COOKIES));
    }
    get startTime() {
      let e = this.getAttribute(u.START_TIME);
      if (e == null) return;
      let n = +e;
      return Number.isNaN(n) ? void 0 : n;
    }
    set startTime(e) {
      e !== this.startTime && (e == null ? this.removeAttribute(u.START_TIME) : this.setAttribute(u.START_TIME, `${e}`));
    }
    get playbackId() {
      var e;
      return (e = this.getAttribute(u.PLAYBACK_ID)) != null ? e : void 0;
    }
    set playbackId(e) {
      e !== this.playbackId && (e ? this.setAttribute(u.PLAYBACK_ID, e) : this.removeAttribute(u.PLAYBACK_ID));
    }
    get maxResolution() {
      var e;
      return (e = this.getAttribute(u.MAX_RESOLUTION)) != null ? e : void 0;
    }
    set maxResolution(e) {
      e !== this.maxResolution && (e ? this.setAttribute(u.MAX_RESOLUTION, e) : this.removeAttribute(u.MAX_RESOLUTION));
    }
    get minResolution() {
      var e;
      return (e = this.getAttribute(u.MIN_RESOLUTION)) != null ? e : void 0;
    }
    set minResolution(e) {
      e !== this.minResolution && (e ? this.setAttribute(u.MIN_RESOLUTION, e) : this.removeAttribute(u.MIN_RESOLUTION));
    }
    get renditionOrder() {
      var e;
      return (e = this.getAttribute(u.RENDITION_ORDER)) != null ? e : void 0;
    }
    set renditionOrder(e) {
      e !== this.renditionOrder && (e ? this.setAttribute(u.RENDITION_ORDER, e) : this.removeAttribute(u.RENDITION_ORDER));
    }
    get customDomain() {
      var e;
      return (e = this.getAttribute(u.CUSTOM_DOMAIN)) != null ? e : void 0;
    }
    set customDomain(e) {
      e !== this.customDomain && (e ? this.setAttribute(u.CUSTOM_DOMAIN, e) : this.removeAttribute(u.CUSTOM_DOMAIN));
    }
    get ended() {
      return Si(this.nativeEl, this._hls);
    }
    get envKey() {
      var e;
      return (e = this.getAttribute(u.ENV_KEY)) != null ? e : void 0;
    }
    set envKey(e) {
      e !== this.envKey && (e ? this.setAttribute(u.ENV_KEY, e) : this.removeAttribute(u.ENV_KEY));
    }
    get beaconCollectionDomain() {
      var e;
      return (e = this.getAttribute(u.BEACON_COLLECTION_DOMAIN)) != null ? e : void 0;
    }
    set beaconCollectionDomain(e) {
      e !== this.beaconCollectionDomain && (e ? this.setAttribute(u.BEACON_COLLECTION_DOMAIN, e) : this.removeAttribute(u.BEACON_COLLECTION_DOMAIN));
    }
    get streamType() {
      var e;
      return (e = this.getAttribute(u.STREAM_TYPE)) != null ? e : Ti(this.nativeEl);
    }
    set streamType(e) {
      e !== this.streamType && (e ? this.setAttribute(u.STREAM_TYPE, e) : this.removeAttribute(u.STREAM_TYPE));
    }
    get targetLiveWindow() {
      return this.hasAttribute(u.TARGET_LIVE_WINDOW) ? +this.getAttribute(u.TARGET_LIVE_WINDOW) : bi(this.nativeEl);
    }
    set targetLiveWindow(e) {
      e != this.targetLiveWindow && (e == null ? this.removeAttribute(u.TARGET_LIVE_WINDOW) : this.setAttribute(u.TARGET_LIVE_WINDOW, `${+e}`));
    }
    get liveEdgeStart() {
      var e, n;
      if (this.hasAttribute(u.LIVE_EDGE_OFFSET)) {
        let {
            liveEdgeOffset: a
          } = this,
          m = (e = this.nativeEl.seekable.end(0)) != null ? e : 0,
          E = (n = this.nativeEl.seekable.start(0)) != null ? n : 0;
        return Math.max(E, m - a);
      }
      return ki(this.nativeEl);
    }
    get liveEdgeOffset() {
      if (!!this.hasAttribute(u.LIVE_EDGE_OFFSET)) return +this.getAttribute(u.LIVE_EDGE_OFFSET);
    }
    set liveEdgeOffset(e) {
      e != this.targetLiveWindow && (e == null ? this.removeAttribute(u.LIVE_EDGE_OFFSET) : this.setAttribute(u.LIVE_EDGE_OFFSET, `${+e}`));
    }
    get seekable() {
      return Ai(this.nativeEl);
    }
    async addCuePoints(e) {
      return pi(this.nativeEl, e);
    }
    get activeCuePoint() {
      return Ei(this.nativeEl);
    }
    get cuePoints() {
      return gi(this.nativeEl);
    }
    getStartDate() {
      return vi(this.nativeEl, this._hls);
    }
    get currentPdt() {
      return yi(this.nativeEl, this._hls);
    }
    get preferPlayback() {
      let e = this.getAttribute(u.PREFER_PLAYBACK);
      if (e === it.MSE || e === it.NATIVE) return e;
    }
    set preferPlayback(e) {
      e !== this.preferPlayback && (e === it.MSE || e === it.NATIVE ? this.setAttribute(u.PREFER_PLAYBACK, e) : this.removeAttribute(u.PREFER_PLAYBACK));
    }
    get metadata() {
      return {
        ...this.getAttributeNames().filter(n => n.startsWith("metadata-") && ![u.METADATA_URL].includes(n)).reduce((n, a) => {
          let m = this.getAttribute(a);
          return m != null && (n[a.replace(/^metadata-/, "").replace(/-/g, "_")] = m), n;
        }, {}),
        ...r(this, Te)
      };
    }
    set metadata(e) {
      c(this, Te, e != null ? e : {}), this.mux && this.mux.emit("hb", r(this, Te));
    }
    load() {
      c(this, _, ui(this, this.nativeEl, r(this, _)));
    }
    unload() {
      hi(this.nativeEl, r(this, _)), c(this, _, void 0);
    }
    attributeChangedCallback(e, n, a) {
      var E, y;
      switch (Et.observedAttributes.includes(e) && !["src", "autoplay", "preload"].includes(e) && super.attributeChangedCallback(e, n, a), e) {
        case u.PLAYER_SOFTWARE_NAME:
          this.playerSoftwareName = a != null ? a : void 0;
          break;
        case u.PLAYER_SOFTWARE_VERSION:
          this.playerSoftwareVersion = a != null ? a : void 0;
          break;
        case "src":
          {
            let b = !!n,
              L = !!a;
            !b && L ? T(this, be, rt).call(this) : b && !L ? this.unload() : b && L && (this.unload(), T(this, be, rt).call(this));
            break;
          }
        case "autoplay":
          if (a === n) break;
          (E = r(this, _)) == null || E.setAutoplay(this.autoplay);
          break;
        case "preload":
          if (a === n) break;
          (y = r(this, _)) == null || y.setPreload(a);
          break;
        case u.PLAYBACK_ID:
          this.src = fi(this);
          break;
        case u.DEBUG:
          {
            let b = this.debug;
            this.mux && console.info("Cannot toggle debug mode of mux data after initialization. Make sure you set all metadata to override before setting the src."), this._hls && (this._hls.config.debug = b);
            break;
          }
        case u.METADATA_URL:
          a && fetch(a).then(b => b.json()).then(b => this.metadata = b).catch(() => console.error(`Unable to load or parse metadata JSON from metadata-url ${a}!`));
          break;
        case u.STREAM_TYPE:
          (a == null || a !== n) && this.dispatchEvent(new CustomEvent("streamtypechange", {
            composed: !0,
            bubbles: !0
          }));
          break;
        case u.TARGET_LIVE_WINDOW:
          (a == null || a !== n) && this.dispatchEvent(new CustomEvent("targetlivewindowchange", {
            composed: !0,
            bubbles: !0,
            detail: this.targetLiveWindow
          }));
          break;
        default:
          break;
      }
    }
    connectedCallback() {
      var e;
      (e = super.connectedCallback) == null || e.call(this), this.nativeEl && this.src && !r(this, _) && T(this, be, rt).call(this);
    }
    disconnectedCallback() {
      this.unload();
    }
  };
_ = new WeakMap(), ye = new WeakMap(), _e = new WeakMap(), Te = new WeakMap(), De = new WeakMap(), Ne = new WeakMap(), Ve = new WeakMap(), be = new WeakSet(), rt = async function () {
  r(this, ye) || (await c(this, ye, Promise.resolve()), c(this, ye, null), this.load());
};
Oe.customElements.get("mux-video") || (Oe.customElements.define("mux-video", xe), Oe.MuxVideoElement = xe);
var is = xe;
export { u as Attributes, Xr as MediaError, pt as VideoEvents, is as default };
