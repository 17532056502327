<eaw-dialog-header>
    <span title>{{ 'ADD_QUALIFICATIONS_FROM_UNITS' | translate: 'company' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <eaw-autocomplete
            multiSelect
            [formControl]="formControl"
            [label]="'BUSINESS_UNIT_plural' | translate"
            [getter]="businessUnitAutocomplete.getter(data.customerId)"
            [setter]="businessUnitAutocomplete.setter(data.customerId)"
            [options]="autocompleteData"/>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [loading]="submitting" [disabled]="!formControl.value?.length" (click)="addQualifications()">{{ 'ADD' | translate | async }}</eaw-action-button>
</mat-dialog-actions>
