// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

const template2 = `<md-dialog>
    <form role="form" autocomplete="off" name="rateForm" ng-submit="ctrl.submit()">
        <eaw-dialog-header-old ng-if="ctrl.editing" heading="'admin:EDIT_TARIFF_RATE' | i18next"></eaw-dialog-header-old>
        <eaw-dialog-header-old ng-if="!ctrl.editing" heading="'admin:ADD_TARIFF_RATE' | i18next"></eaw-dialog-header-old>
        <md-dialog-content>
            <div class="md-dialog-content">
                <eaw-date-interval
                        ng-model="ctrl.interval"
                        from-required="true"
                        from-disabled="ctrl.disableFrom"
                        to-disabled="ctrl.disableTo">
                </eaw-date-interval>

                <md-input-container>
                    <label ng-i18next="RATE"></label>
                    <input ng-model="ctrl.rate.rate"
                           pattern="^[0-9]+([\,|\.][0-9]+)?$"
                           step="any"
                           type="number"
                           min="0"
                           required>
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button type="submit" ng-i18next="SUBMIT" ng-disabled="rateForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

const template1 = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title">
                <span ng-i18next="TARIFF_RATE_plural"></span> - <span ng-bind="$chainTariffRatesCtrl.tariff.name"></span>
            </span>
        </md-card-header-text>
        <card-btn-float on-click="$chainTariffRatesCtrl.rateDialog()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="$chainTariffRatesCtrl.table.columns"
                cells="$chainTariffRatesCtrl.table.cells"
                get-data="$chainTariffRatesCtrl.getData(pagination)"
                reload-table="$chainTariffRatesCtrl.reloadTable"
                scope-vars="$chainTariffRatesCtrl.table.vars"
        >
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;

module('eaw.chain-ops').component('chainTariffRates', {
    template: template1,
    bindings: {
        tariff: '<',
    },
    controllerAs: '$chainTariffRatesCtrl',
    controller: [ 'TariffRateFactory', 'deleteItemAlert', '$mdDialog', function(TariffRateFactory, deleteItemAlert, $mdDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.table = {
                columns: [
                    {
                        title: t('ID'),
                        sortable: 'id',
                    },
                    {
                        title: t('FROM'),
                        sortable: 'from',
                    },
                    {
                        title: t('TO'),
                        sortable: 'to',
                    },
                    {
                        title: t('RATE'),
                        sortable: 'rate',
                        class: 'text-right',
                    },
                    {
                        title: t('TYPE'),
                        sortable: 'type',
                    },
                    { title: '' },
                ],
                cells: [
                    { template: 'item.id' },
                    { template: 'item.from | moment:"LL"' },
                    { template: 'item.to | moment:"LL"' },
                    {
                        template: 'item.rate | eawCurrency',
                        class: 'text-right',
                    },
                    { template: 'item.type | uppercase | i18next' },
                    {
                        buttons: [
                            {
                                icon: 'edit',
                                click: ctrl.rateDialog,
                            },
                            {
                                icon: 'delete',
                                type: 'warn',
                                click: ctrl.delete,
                            },
                        ],
                    },
                ],
            };
        };

        ctrl.getData = (pagination) => TariffRateFactory.getAll({
            tariff: ctrl.tariff,
            customer: CurrentOld.customer,
            ...pagination.toWithPaginatorArguments(),
        });

        ctrl.rateDialog = (rate) => {
            $mdDialog.show({
                template: template2,
                controllerAs: 'ctrl',
                controller: function() {
                    const dctrl = this;

                    dctrl.$onInit = () => {
                        dctrl.editing = !!rate;
                        dctrl.rate = { ...rate };
                        dctrl.cancel = $mdDialog.cancel;
                        dctrl.interval = {
                            from: dctrl.rate?.from?.clone?.(),
                            to: dctrl.rate?.to?.clone?.(),
                        };

                        dctrl.disableFrom = moment().isAfter(dctrl.interval.from);
                        dctrl.disableTo = moment().isAfter(dctrl.interval.to);
                    };

                    dctrl.submit = () => {
                        $mdDialog.hide();

                        dctrl.rate.tariff_id = ctrl.tariff.id;
                        dctrl.rate.from = dctrl.interval?.from;
                        dctrl.rate.to = dctrl.interval?.to;

                        TariffRateFactory[dctrl.editing ? 'update' : 'create'](dctrl.rate).$promise.then(() => {
                            ctrl.reloadTable = true;
                        });
                    };
                },
            });
        };

        ctrl.delete = (rate) => {
            deleteItemAlert.alertSimple().then(() => {
                TariffRateFactory.delete(rate).$promise.then(() => ctrl.reloadTable = true);
            });
        };
    } ],
});
