import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../shared/dialogs/dialog-component';
import { ManageTimepunchDialogComponent } from './manage-timepunch-dialog.component';
import { DateTime } from 'luxon';
import { Timepunch } from '../../models/timepunch';
import { Employee } from '../../../shared/models/employee';
import { Observable, of } from 'rxjs';

export interface InitialTimepunchCreateData {
    businessDate?: DateTime;
    date?: DateTime,
    // The ID of the employee or an employee object
    employee?: number | Employee;
}

export interface ManageTimepunchDialogData extends DialogData {
    disableEmployeeSelect?: boolean;
    customerId: number;
    timepunchObservable?: Observable<Timepunch>;
    initialCreateData?: InitialTimepunchCreateData;
}

@Injectable({
    providedIn: 'root',
})
export class ManageTimepunchDialogService {

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
    ) {
    }

    create(customerId: number, dialogData?: Partial<ManageTimepunchDialogData>, initialCreateData?: InitialTimepunchCreateData) {
        return this.dialog.open<ManageTimepunchDialogComponent, ManageTimepunchDialogData, Timepunch>(ManageTimepunchDialogComponent, {
            data: {
                ...dialogData,
                customerId,
                initialCreateData,
            },
        });
    }

    update(customerId: number, timepunch: Observable<Timepunch> | Timepunch, dialogData?: Partial<ManageTimepunchDialogData>): MatDialogRef<ManageTimepunchDialogComponent, Timepunch> {
        return this.dialog.open<ManageTimepunchDialogComponent, ManageTimepunchDialogData, Timepunch>(ManageTimepunchDialogComponent, {
            data: {
                ...dialogData,
                customerId,
                timepunchObservable: timepunch instanceof Observable ? timepunch : of(timepunch),
            },
        });
    }
}
