import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { ScheduleStatistics, ScheduleStatisticsResponse } from '../models/schedule-statistics';
import { ScheduleStatisticsColumnData, ScheduleStatisticsColumnDataResponse } from '../models/schedule-statistics-column-data';
import { DateTime } from 'luxon';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { map } from 'rxjs';

export type TopStatsOperationDataType = 'single' | 'sum' | 'product' | string;

export interface TopStatsOperationData {
    type: TopStatsOperationDataType,
    variables: string[],
}

export interface TopStatsDivisionOperation {
    operation: 'division' | string,
    decimals: number,
    result_type?: 'percent',
    divisor: TopStatsOperationData,
    dividend: TopStatsOperationData,
}

export interface TopStatsStatDefinition {
    wtiKey: string,
    id: string,
    calculation: TopStatsDivisionOperation,
}

export type ScheduleStatisticsTopStatsResponseVariable = {
    bucket_uuid: string,
    code: string,
    description: string,
    name: string,
    uuid: string
};

export interface ScheduleStatisticsTopStatsResponse {
    series: Record<string, number[]>,
    variables: Record<string, ScheduleStatisticsTopStatsResponseVariable | null>,
    additional_variables: Record<string, number>,
    stats_definitions: TopStatsStatDefinition[],
}

@Injectable({
    providedIn: 'root',
})
export class ScheduleStatisticsService {
    private readonly http = inject(HttpClient);

    get(customerId: number, scheduleId: number) {
        return this.http.get<ScheduleStatisticsResponse>(`/customers/${customerId}/schedules/${scheduleId}/employee_statistics_per_week`).pipe(classifyItem(ScheduleStatistics));
    }

    getColumnsData(customerId: number, scheduleId: number, options: { params: { average_days_worked: boolean, sunday_work: boolean; } }) {
        return this.http.get<ScheduleStatisticsColumnDataResponse>(`/customers/${customerId}/schedules/${scheduleId}/columns`, {
            params: { ...options.params },
        }).pipe(classifyItem(ScheduleStatisticsColumnData));
    }

    getTopStats(customerId: number, bucketUuid: string, interval: number, from: DateTime, to: DateTime) {
        return this.http.get<ScheduleStatisticsTopStatsResponse>(`/customers/${customerId}/ml_buckets/${bucketUuid}/scheduling_stats`, {
            params: formatHttpParams({
                interval,
                from,
                to,
            }),
        }).pipe(
            map((response) => { // Remove this map once the API response has changed
                Object.entries(response.variables).forEach(([ key, value ]) => {
                    if (typeof value === 'number') {
                        response.additional_variables = response.additional_variables || {};
                        response.additional_variables[key] = value;
                        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                        delete response.variables[key];
                    }
                });

                return response;
            }),
        );
    }
}
