import { module } from 'angular';
import { t } from 'i18next';
import { EawFormSubmission } from '../../types/form-submission';
import { Namespace } from '../../../shared/enums/namespace';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { FormFactory as FormService } from '../factory/form.factory';
import { EawForm } from '../../types/form';

export class ViewFormController {
    FormFactory;
    ToastService;
    $mdDialog;

    static get $inject() {
        return [ 'FormFactory', 'ToastService', '$mdDialog' ];
    }

    cancel = history.back;

    customerId!: number;
    form!: EawForm;
    submission?: EawFormSubmission;
    recipients = [] as { id: number, name: string }[]; // Users
    constructor(FormFactory: FormService, ToastService: SnackBarService, $mdDialog: any) {
        this.FormFactory = FormFactory;
        this.ToastService = ToastService;
        this.$mdDialog = $mdDialog;
    }

    $onInit() {
        this.customerId = this.form.customer_id;
        this.resetForm();
    }

    submitForm() {
        const submission = this.submission;
        if (!submission) {
            return;
        }

        this.$mdDialog.show(this.$mdDialog.confirm()
            .title(t('forms:SUBMIT_FORM' + (submission.anonymous ? '_ANONYMOUSLY' : '')))
            .textContent(t('forms:SUBMIT_FORM_QUESTION' + (submission.anonymous ? '_ANONYMOUS' : '')))
            .theme(submission.anonymous ? 'delete' : 'default')
            .ok(t('SUBMIT'))
            .cancel(t('CANCEL')))
            .then(() => {

                submission.recipients = this.recipients.map((r) => r.id);

                this.FormFactory.submitForm(this.form, submission).$promise.then(async () => {
                    await this.ToastService.t('FORM_SUBMITTED', Namespace.Forms);
                    this.resetForm();
                });
            });
    }

    resetForm() {
        this.submission = new EawFormSubmission();
        this.recipients = [];
    }
}

module('eaw.forms').component('eawSubmitForm', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-bind="$view.form.name"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <form name="selectedForm" id="selectedForm" layout="column" ng-submit="$view.submitForm()">
            <fieldset layout="column" ng-if="$view.form.user_group_id">
                <user-group-member-autocomplete label="'RECIPIENT_plural'" user-group-id="$view.form.user_group_id" customer-id="$view.form.customer_id" ng-model="$view.recipients"></user-group-member-autocomplete>
            </fieldset>

            <eaw-form ng-model="$view.submission" form="$view.form"></eaw-form>

            <div layout="row">
                <md-button form="selectedForm" type="submit" ng-disabled="selectedForm.$invalid || !selectedForm.$dirty" class="md-accent md-raised">
                    <span ng-i18next="SUBMIT"></span>
                </md-button>
                <md-button ng-click="$view.cancel()" class="md-raised">
                    <span ng-i18next="BACK"></span>
                </md-button>
            </div>
        </form>
    </md-card-content>
</md-card>
`,
    bindings: {
        form: '<',
    },
    controllerAs: '$view',
    controller: ViewFormController,
});
