import { DataTableColumn } from './data-table-column';
import { DataTableHeader } from './data-table-header';
import { Approval } from '../../shared/models/approval';
import { ApprovalPortalComponent } from '../portals/approval-portal/approval-portal.component';
import { DataTableFunctionValue } from '../interfaces/data-table-value';

interface IDataTableApprovalColumn<Item> {
    header: DataTableHeader;
        approval: DataTableFunctionValue<DataTableApprovalColumn<Item>, Item, Approval | undefined | null>;
    includeTime?: boolean;
}

export class DataTableApprovalColumn<Item> extends DataTableColumn<DataTableApprovalColumn<Item>, Item, void> {
    override loading?: never;
    approval: IDataTableApprovalColumn<Item>['approval'];
    component = ApprovalPortalComponent<Item>;
    includeTime: boolean;

    constructor(column: IDataTableApprovalColumn<Item>) {
        super({
            exportable: true,
            ...column,
        });

        this.approval = column.approval;
        this.includeTime = column.includeTime || false;
    }
}
