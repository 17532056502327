import { ChangeDetectionStrategy, Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Widget, WidgetSize } from '../../classes/widget';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';
import { WidgetService } from '../../http/widget.service';
import { PermissionPipe } from '../../../shared/pipes/permission.pipe';

export type WidgetSettingsAction = 'change_color' | 'delete';

export interface WidgetOptionsBottomSheetData {
    widget: Widget<any>;
    defaultWidgetKeys: string[];
}

export interface WidgetOptionsBottomSheetResult {
    action?: WidgetSettingsAction;
}

@Component({
    selector: 'eaw-widget-options-bottom-sheet',
    templateUrl: './widget-options-bottom-sheet.component.html',
    styleUrl: './widget-options-bottom-sheet.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatListModule,
        AsyncPipe,
        TranslatePipe,
        PermissionPipe,
    ],
})
export class WidgetOptionsBottomSheetComponent implements OnInit {
    private readonly widgetService = inject(WidgetService);

    protected isDefaultWidget: boolean;

    protected widths = {
        default: true,
        wide: false,
        full: false,
    };

    protected heights = {
        default: true,
        tall: false,
    };

    constructor(
        @Inject(MatBottomSheetRef) private sheetRef: MatBottomSheetRef<WidgetOptionsBottomSheetComponent, WidgetOptionsBottomSheetResult>,
        @Inject(MAT_BOTTOM_SHEET_DATA) protected data: WidgetOptionsBottomSheetData,
    ) {
        this.isDefaultWidget = this.data.defaultWidgetKeys.includes(this.data.widget.info.key);
    }

    ngOnInit(): void {
        this.setSizeProperties(this.data.widget.size());
    }

    protected showInfo() {
        console.group(this.data.widget.info.key);
        console.log(this.data.widget.info);
        console.groupEnd();
    }

    protected close(action?: WidgetSettingsAction) {
        this.sheetRef.dismiss({ action });
    }

    protected setSizeProperties(size: WidgetSize) {
        this.widths.default = size === 1 || size === 4;
        this.widths.wide = size === 2 || size === 5;
        this.widths.full = size === 3 || size === 6;

        this.heights.default = size === 1 || size === 2 || size === 3;
        this.heights.tall = size === 4 || size === 5 || size === 6;
    }

    protected changeWidth(key: keyof WidgetOptionsBottomSheetComponent['widths']) {
        // Skip if already selected
        if (this.widths[key]) {
            return;
        }

        Object.keys(this.widths).forEach((width) => {
            const w = width as keyof WidgetOptionsBottomSheetComponent['widths'];
            this.widths[w] = w === key;
        });

        this.changeSize();
    }

    protected changeHeight(key: keyof WidgetOptionsBottomSheetComponent['heights']) {
        // Skip if already selected
        if (this.heights[key]) {
            return;
        }

        Object.keys(this.heights).forEach((height) => {
            const h = height as keyof WidgetOptionsBottomSheetComponent['heights'];
            this.heights[h] = h === key;
        });

        this.changeSize();
    }

    protected changeSize() {
        let size: WidgetSize = 1;

        switch (true) {
            case this.widths.default && this.heights.default:
                size = 1;
                break;
            case this.widths.wide && this.heights.default:
                size = 2;
                break;
            case this.widths.full && this.heights.default:
                size = 3;
                break;
            case this.widths.default && this.heights.tall:
                size = 4;
                break;
            case this.widths.wide && this.heights.tall:
                size = 5;
                break;
            case this.widths.full && this.heights.tall:
                size = 6;
                break;
        }

        this.setSizeProperties(size);
        this.data.widget.size.set(size);

        this.widgetService.update(this.data.widget.info.user.id, this.data.widget, { size: this.data.widget.size() }).subscribe();
    }
}
