import { inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { DateTime } from 'luxon';
import { map, Observable } from 'rxjs';
import { ArrayPaginatedResponse, AssocPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { Timepunch, TimepunchResponse } from '../models/timepunch';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { DateTimeConverter } from '../../shared/utils/date-time-converter';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { FileDownloadService } from '../../shared/services/file-download.service';
import { BusinessDate } from '../../shared/utils/business-date';

interface EmployeeTimepunchSummaryResponse {
    data: Record<number, { punched: number, planned: number }>
}

export interface EmployeeTimepunchSummary {
    date: DateTime;
    punched: number;
    planned: number;
}

interface GetForCustomerOptions extends PaginationOptions {
    employeeId?: number;
    from?: DateTime;
    to?: DateTime;
}

export interface CreateBody {
    in: DateTime,
    out?: DateTime | null,
    businessUnitId?: number,
    businessDate: DateTime,
    comment?: string,
}

export interface UpdateBody {
    in?: DateTime,
    out?: DateTime | null,
    businessUnitId?: number | null,
    businessDate?: DateTime,
    comment?: string,
}

export type TimepunchAttachment = {
    id: number;
    name: string;
    mime: string;
}

export type RequestedTimepunchAttachment = {
    employee_id: number;
    timepunch_id: number;
    workflow_id: string;
    run_id: string;
    status: 'WFR_CANCELLED' | 'WFR_COMPLETED' | 'WFR_IN_PROGRESS';
    last_document: string;
}

export interface RequestedTimepunchesResponse {
    requested_attachments: RequestedTimepunchAttachment[]
}

interface CustomerSummaryOptions extends PaginationOptions {
    with_trashed?: boolean,
    from?: BusinessDate,
    to?: BusinessDate,
};

type TimepunchesByEmployee = {
        [employeeId: string]: TimepunchResponse[]
};

@Injectable({
    providedIn: 'root',
})
export class TimepunchService {
    private readonly http = inject(HttpClient);
    private readonly fileDownload = inject(FileDownloadService);

    approve(customerId: number, timepunchIds: number[], comment?: string) {
        return this.http.put<string>(`customers/${customerId}/timepunches/approve`, {
            ids: Array.from(new Set(timepunchIds).values()),
            comment,
        });
    }

    get(customerId: number, timepunchId: number, withs?: string[]): Observable<Timepunch> {
        return this.http.get<TimepunchResponse>(`customers/${customerId}/timepunches/${timepunchId}`, {
            params: formatHttpParams({
                'with[]': withs,
            }),
        }).pipe(classifyItem(Timepunch));
    }

    getForEmployee(customerId: number, employeeId: number, timepunchId: number, options?: {with?: string[], include_changelog?: boolean}): Observable<Timepunch> {
        return this.http.get<TimepunchResponse>(`customers/${customerId}/employees/${employeeId}/timepunches/${timepunchId}`, {
            params: formatHttpParams({
                'with[]': options?.with,
                include_changelog: options?.include_changelog,
            }),
        }).pipe(classifyItem(Timepunch));
    }

    getEmployeeSummary(customerId: number, employeeId: number, from: BusinessDate, to: BusinessDate): Observable<(EmployeeTimepunchSummary | null)[]> {
        return this.http.get<EmployeeTimepunchSummaryResponse>(`/customers/${customerId}/employees/${employeeId}/timepunches/summary`, {
            params: formatHttpParams({ from, to }),
        }).pipe(
            map((response) => {
                const times = Object.keys(response.data)
                    .map((x) => parseInt(x))
                    .sort((a,b) => a - b);

                return times.map((time) => {
                    const data = response.data[time];

                    return data ? {
                        date: DateTime.fromMillis(time * 1000, { zone: 'UTC' }),
                        punched: data.punched,
                        planned: data.planned,
                    } satisfies EmployeeTimepunchSummary : null;
                });
            }),
        );
    }

    getForCustomer(customerId: number, options?: GetForCustomerOptions): Observable<ArrayPaginatedResponse<Timepunch>> {
        return this.http.get<ArrayPaginatedResponse<TimepunchResponse>>(`customers/${customerId}/timepunches`, {
            params: formatHttpParams({
                ...options,
                employee_id: options?.employeeId,
            }, [ 'from', 'to' ]),
        }).pipe(classifyArrayPaginatedResponse(Timepunch));
    }

    create(customerId: number, employeeId: number, body: CreateBody): Observable<Timepunch> {
        return this.http.post<TimepunchResponse>(`customers/${customerId}/employees/${employeeId}/timepunches`, {
            in: body.in,
            out: body.out,
            business_unit_id: body.businessUnitId,
            business_date: DateTimeConverter.convertDateTimeToBusinessDate(body.businessDate),
            comment: body.comment,
        }).pipe(classifyItem(Timepunch));
    }

    update(customerId: number, employeeId: number, timepunchId: number, body: UpdateBody): Observable<Timepunch> {
        return this.http.put<TimepunchResponse>(`customers/${customerId}/employees/${employeeId}/timepunches/${timepunchId}`, {
            in: body.in,
            out: body.out,
            business_unit_id: body.businessUnitId,
            business_date: DateTimeConverter.convertDateTimeToBusinessDate(body.businessDate),
            comment: body.comment,
        }).pipe(classifyItem(Timepunch));
    }

    delete(customerId: number, timepunchId: number, comment?: string) {
        return this.http.delete<undefined>(`customers/${customerId}/timepunches/${timepunchId}`, { body: { comment } });
    }

    history(customerId: number, employeeId: number, timepunchId: number): Observable<Timepunch> {
        return this.getForEmployee(customerId, employeeId, timepunchId, { with: [ 'approvedBy', 'properties', 'comments', 'employee' ] });
    }

    getAttachments(customerId: number, timepunchId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<TimepunchAttachment>>(`/customers/${customerId}/timepunches/${timepunchId}/attachments`, {
            params: { ...options },
        });
    }

    getRequestedAttachments(customerId: number, timepunchId: number, options?: PaginationOptions) {
        return this.http.get<RequestedTimepunchesResponse>(`/customers/${customerId}/timepunches/${timepunchId}/requested_attachments`, {
            params: { ...options },
        });
    }

    downloadAttachment(customerId: number, timepunchId: number, attachmentId: number, attachmentName: string) {
        return this.fileDownload.download(`/customers/${customerId}/timepunches/${timepunchId}/attachments/${attachmentId}`, attachmentName);
    }

    getCustomerSummary(customerId: number, employeeId?: number, options?: CustomerSummaryOptions): Observable<AssocPaginatedResponse<Record<string, Timepunch[]>>> {
        return this.http.get<AssocPaginatedResponse<TimepunchesByEmployee>>(`/customers/${customerId}/timepunches/summary`, {
            params: formatHttpParams({
                employee_id: employeeId,
                ...options,
            }, [ 'from', 'to' ]),
        }).pipe(
            map((page) => {
                return Object.entries(page.data).reduce((acc, [ date, employeeData ]) => {
                    acc.data[date] = Object.entries(employeeData).reduce((carry, [ employeeId, timepunches ]) => {
                        carry[employeeId] = timepunches
                            .map((timepunch: TimepunchResponse) => new Timepunch(timepunch))
                            .sort((a, b) => {
                                return a.in.valueOf() - b.in.valueOf();
                            });
                        return carry;
                    }, {} as Record<string, Timepunch[]>);

                    return acc;
                }, {
                    ...page,
                    data: {},
                } as AssocPaginatedResponse<Record<string, Timepunch[]>>);
            }),
        );

    }
}
