import { DateTime } from 'luxon';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export interface EmployeePayRatePreviewResponse extends ApiResponse {
    employee: {
        id: number;
        number: number;
        name: string;
    }
    customer: {
        id: number;
        number: number;
        name: string;
    }
    pay_rate: {
        rate: number;
        from: string;
        to: string | null;
        tariff_id: number;
    }
}

export class EmployeePayRatePreview {
    employee: {
        id: number;
        number: number;
        name: string;
    };

    customer: {
        id: number;
        number: number;
        name: string;
    };

    payRate: {
        rate: number;
        from: DateTime;
        to: DateTime | null;
        tariffId: number;
    };
    
    constructor(data: EmployeePayRatePreviewResponse) {
        this.employee = data.employee;
        this.customer = data.customer;
        this.payRate = {
            rate: data.pay_rate.rate,
            from: stringToDateTime(data.pay_rate.from),
            to: data.pay_rate.to ? stringToDateTime(data.pay_rate.to) : null,
            tariffId: data.pay_rate.tariff_id,
        };
    }
}
