import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { HourDistribution, HourDistributionResponse } from '../models/hour-distribution';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { DateTime } from 'luxon';
import { BUSINESS_DATES } from '../../shared/http/http-contexts';
import { formatHttpParams } from '../../shared/utils/format-http-params';

@Injectable({
    providedIn: 'root',
})
export class HourDistributionService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    get(customerId: number, employeeId: number, hourDistributionId: number, withs?: string[]) {
        return this.http.get<HourDistributionResponse>(`/customers/${customerId}/employees/${employeeId}/contract_hours_distributions/${hourDistributionId}`, {
            params: formatHttpParams({
                'with[]': withs,
            }),
        }).pipe(classifyItem(HourDistribution));
    }

    getAll(customerId: number, employeeId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<HourDistributionResponse>>(`/customers/${customerId}/employees/${employeeId}/contract_hours_distributions`, {
            params: {
                ...options,
            },
        }).pipe(classifyArrayPaginatedResponse(HourDistribution));
    }

    create(customerId: number, employeeId: number, from: DateTime, to: DateTime | undefined, days: number) {
        return this.http.post<HourDistributionResponse>(`/customers/${customerId}/employees/${employeeId}/contract_hours_distributions`, {
            from,
            to,
            days,
        }, { context: new HttpContext().set(BUSINESS_DATES, [ 'from', 'to' ]) }).pipe(classifyItem(HourDistribution));
    }

    update(customerId: number, employeeId: number, hourDistributionId: number, to: DateTime) {
        return this.http.put<HourDistributionResponse>(`/customers/${customerId}/employees/${employeeId}/contract_hours_distributions/${hourDistributionId}`, {
            to,
        }, { context: new HttpContext().set(BUSINESS_DATES, [ 'to' ]) }).pipe(classifyItem(HourDistribution));
    }

    delete(customerId: number, employeeId: number, hourDistributionId: number) {
        return this.http.delete<undefined>(`/customers/${customerId}/employees/${employeeId}/contract_hours_distributions/${hourDistributionId}`);
    }
}
