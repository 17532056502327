<eaw-dialog-header [eawMaterialColor]="getDisplayColor(selectedColorHue)">
    <span title>{{title | async}}</span>
    <span subtitle>{{getDisplayColor(selectedColorHue)}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <div id="slider">
        <div class="mat-subtitle-2">{{'COLOR_INTENSITY' | translate | async}}</div>
        <mat-slider [min]="0" [max]="steps" [step]="1" showTickMarks discrete [displayWith]="formatSliderLabel">
            <input matSliderThumb [formControl]="slider">
        </mat-slider>
    </div>

    <div id="grid">
        @for (color of colors; track color.name) {
            <div class="color"
                 tabindex="0"
                 (click)="changeColor(color)"
                 (keydown.enter)="colorEnter($event)"
                 matRipple
                 [eawMaterialColor]="getDisplayColor(color)"
                 colorProperty="backgroundColor">
                <span>{{color.translationKey | translate:color.translationNs | async}}</span>
            </div>
        }
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button [matDialogClose]="null">{{ 'REMOVE' | translate | async }}</button>
    <span class="tw-flex-1"></span>
    <button mat-raised-button [matDialogClose]="">{{ 'CLOSE' | translate | async }}</button>
    <button mat-raised-button [eawMaterialColor]="getDisplayColor(selectedColorHue)" colorProperty="backgroundColor" (click)="choose()">{{ 'CHOOSE_COLOR' | translate:'general':{color: selectedColorHue?.fullName} | async }}</button>
</mat-dialog-actions>
