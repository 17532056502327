<eaw-dialog-header>
    <span title>{{'POSITION_plural' | translate:'company' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="loading">
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
        <mat-spinner></mat-spinner>
    </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="!loading">
    <mat-selection-list multiple #list (selectionChange)="selectionChange(list)">
        <mat-list-option *ngFor="let position of positions" [value]="position.id">{{position.name}}</mat-list-option>
    </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
    <button mat-button (click)="add()" [disabled]="!selectedPositions.length || loading">{{'ADD_POSITION' | translate:'company':{count: selectedPositions.length} | async}}</button>
</mat-dialog-actions>
