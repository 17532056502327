// @ts-nocheck
import { module } from 'angular';
import { PaginationOld } from '../../../utils/pagination-old';
module('eaw.customers').factory('CustomerProperty', [ 'EawResource', function(EawResource) {
    const url = '/customers/:customer/properties/:property';
    /**
     * @param {object}  args
     * @param {int}     args.customer - The customer ID
     * @returns {*}
     */
    this.getAll = (args) => EawResource.create(url).get({
        customer: args.customer,
        ...PaginationOld.getParams(args),
    });
    /**
     * @param {int}     customerId
     * @param {string}  key
     * @returns {*}
     */
    this.get = (customerId, key) => EawResource.create(url).get({
        customer: customerId,
        property: key,
    });
    /**
     * @param {int}     customerId
     * @param {string}  key
     * @param {string}  value
     * @returns {*}
     */
    this.create = (customerId, key, value) => EawResource.create(url).save({
        customer: customerId,
    }, {
        key,
        value,
    });
    /**
     * @param {int}     customerId
     * @param {string}  key
     * @param {string}  newValue
     */
    this.update = (customerId, key, newValue) => EawResource.create(url).update({
        customer: customerId,
        property: key,
    }, {
        value: newValue,
    });
    /**
     * @param {int}     customerId
     * @param {string}  key
     * @returns {*}
     */
    this.delete = (customerId, key) => EawResource.create(url).delete({
        customer: customerId,
        property: key,
    });
    return this;
} ]);
