import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { FormSubmission, FormSubmissionResponse } from '../models/form-submission';

type GetAllArgs = PaginationOptions & {
    isClosed?: boolean,
    isAnonymized?: boolean,
}

type GetArgs = {
    customerId: number,
    formId: number,
    submissionId: number,
    with?: string[],
};

@Injectable({
    providedIn: 'root',
})
export class FormSubmissionService {
    constructor(@Inject(HttpClient) protected http: HttpClient) { }

    getAll(customerId: number, formId: number, args: GetAllArgs = {}): Observable<ArrayPaginatedResponse<FormSubmission>> {
        return this.http.get<ArrayPaginatedResponse<FormSubmissionResponse>>(`/customers/${customerId}/forms/${formId}/submissions`, {
            params: formatHttpParams({
                ...args,
                is_closed: typeof args.isClosed === 'boolean' ? args.isClosed : undefined,
                is_anonymized: typeof args.isAnonymized === 'boolean' ? args.isAnonymized : undefined,
            }),
        }).pipe(classifyArrayPaginatedResponse(FormSubmission));
    }

    getAllFromUser(customerId: number, data: { form_id?: number, is_closed?: boolean } & PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<FormSubmissionResponse>>(`/customers/${customerId}/forms/user_submissions`, {
            params: formatHttpParams({ ...data, is_closed: typeof data.is_closed === 'boolean' ? data.is_closed : undefined }),
        }).pipe(classifyArrayPaginatedResponse(FormSubmission));
    }

    get(args: GetArgs): Observable<FormSubmission> {
        return this.http.get<FormSubmissionResponse>(`/customers/${args.customerId}/forms/${args.formId}/submissions/${args.submissionId}`, {
            params: formatHttpParams({
                'with[]': args.with,
            }),
        }).pipe(classifyItem(FormSubmission));
    }

    close(customerId: number, formId: number, submissionId: number) {
        return this.http.post(`/customers/${customerId}/forms/${formId}/submissions/${submissionId}/close`, {});
    }

    anonymize(customerId: number, formId: number, submissionId: number) {
        return this.http.post(`/customers/${customerId}/forms/${formId}/submissions/${submissionId}/anonymize`, {});
    }
}
