import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BaseApiModel } from '../../shared/models/base-api-model';

export interface AvailabilityDayResponse extends ApiResponse {
    id: number;
    availability_id: number;
    date: string;
    from: string;
    to: string;
    whole_day: boolean;
    day: number;
    offset: number;
    length: number;
}

export class AvailabilityDay extends BaseApiModel<AvailabilityDayResponse, AvailabilityDay> {
    id: number;
    date: DateTime | null;
    availabilityId: number;
    from?: DateTime | null;
    // Length of the time period in seconds, if whole day, then this is not relevant
    length: number | null;
    // Offset from 00:00 that day in seconds, if whole day, then this is not relevant
    offset: number | null;
    to?: DateTime | null;
    // Number of days since start of availability
    day: number;
    wholeDay: boolean;
    startsAtMidnight: boolean;
    endsAtMidnight: boolean;

    constructor(data: AvailabilityDayResponse) {
        super(data, undefined);

        this.availabilityId = data.availability_id;
        this.date = stringToDateTime(data.date);
        this.from = data.from ? stringToDateTime(data.from) : null;
        this.id = data.id;
        this.length = data.whole_day ? null : data.length;
        this.offset = data.whole_day ? null : data.offset;
        this.to = data.to ? stringToDateTime(data.to) : null;
        this.day = data.day;
        this.wholeDay = data.whole_day;
        this.startsAtMidnight = this.offset === 0;
        this.endsAtMidnight = this.offset != null && this.length != null ? this.offset + this.length === 86400 : false;
    }
}
