<eaw-page-header>
    <div title>{{ 'BALANCES' | translate: 'navigation' | async }}</div>
    <span subtitle class="tw-flex tw-flex-row tw-items-center tw-gap-8">
        <eaw-date-interval-selector [interval]="form.controls.interval.value" (intervalChange)="updateInterval($event)" [from]="from" [to]="to"/>
    </span>

    <form smallFilter [formGroup]="form" class="header-filter">
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>{{ 'TIME' | translate | async }}</mat-label>
            <mat-select formControlName="interval" (selectionChange)="onIntervalTypeChanged()">
                <mat-option value="day">{{ 'DATE' | translateSync }}</mat-option>
                <mat-option value="week">{{ 'PERIOD' | translateSync }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="standalone">
            <mat-label>{{ 'CONTRACT_TYPE' | translate:'company' | async }}</mat-label>
            <mat-select formControlName="contractType">
                <mat-option [value]="null"></mat-option>

                @for (type of contractTypes | keyvalue; track type.key) {
                    <mat-option [value]="type.value">{{ type.value.name }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</eaw-page-header>

@if (loadingGroups) {
    <mat-spinner class="groups-spinner" mode="indeterminate"></mat-spinner>
}

<mat-accordion multi id="table-card">
    @for (customer of customers; track customer.customer.id) {
        <mat-expansion-panel [disabled]="customer.hasError">
            <mat-expansion-panel-header (click)="panelClick(customer)">
                <mat-panel-title>
                    <span>{{ customer.customer.name }}</span>
                    @if (customer.hasError) {
                        <span class="title-error-text">{{ 'ERROR_GET_DATA' | translate | async }}</span>
                    }
                    @if (customer.loading) {
                        <mat-spinner mode="indeterminate" class="tw-ml-8" diameter="20" strokeWidth="2"></mat-spinner>
                    }
                </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                @if (customer.loading) {
                    <div class="tw-flex tw-items-center tw-justify-center tw-m-20">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                } @else {
                    <div class="table-wrapper">
                        <table mat-table #table [dataSource]="customer.filteredEmployees" matSort matSortDisableClear (matSortChange)="sort($event, customer)">
                            <!-- Name column -->
                            <ng-container matColumnDef="name" [sticky]="true">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="employee">{{ 'EMPLOYEE' | translate | async }}</th>
                                <td mat-cell *matCellDef="let employee">{{ employee.name }}</td>
                                <td mat-footer-cell *matFooterCellDef>{{ 'SUM' | translate | async }}</td>
                            </ng-container>

                            <!-- Contract column -->
                            <ng-container matColumnDef="contract" [sticky]="true">
                                <th mat-header-cell *matHeaderCellDef>{{ 'CONTRACT' | translate | async }}</th>
                                <td mat-cell *matCellDef="let employee">
                                    <div class="td-inner">{{ employee.contract?.type?.name }}</div>
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <!-- Balances columns -->
                            @for (type of customer.balanceTypes; track type.balanceCode) {
                                <ng-container [matColumnDef]="type.balanceCode">
                                    <th class="balance-cell balance-value-header" mat-header-cell *matHeaderCellDef arrowPosition="before" [mat-sort-header]="type.balanceCode"> {{ type.nameTranslation.key | translate:type.nameTranslation.ns | async }}</th>
                                    <td class="balance-cell balance-value" mat-cell *matCellDef="let employee" (click)="openBalanceDetails(employee, type, customer)">{{ getBalance(employee, type) }}</td>
                                    <td class="balance-cell" mat-footer-cell *matFooterCellDef>{{ customer.totals[type.balanceCode] }}</td>
                                </ng-container>
                            }

                            <tr mat-header-row *matHeaderRowDef="customer.displayColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: customer.displayColumns;"></tr>
                            <tr mat-footer-row *matFooterRowDef="customer.displayColumns; sticky: true"></tr>
                        </table>
                    </div>
                }
            </ng-template>
        </mat-expansion-panel>
    }
</mat-accordion>
