import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { KeyValuePipe, NgForOf } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MaterialColorDirective } from '../../directives/material-color.directive';
import { MatIconSizeDirective } from '../../directives/mat-icon-size.directive';

interface ListEntry {
    id: string;
    file: File,
    src: SafeResourceUrl
}

@Component({
    selector: 'eaw-attachment-list',
    templateUrl: './attachment-list.component.html',
    styleUrl: './attachment-list.component.scss' ,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButtonModule,
        NgForOf,
        MatIconModule,
        KeyValuePipe,
        MatRippleModule,
        MaterialColorDirective,
        MatIconSizeDirective,
    ],
})
export class AttachmentListComponent implements OnDestroy {
    private inputs: HTMLInputElement[] = [];
    entries: Map<string, ListEntry> = new Map();

    @Output() filesChange: EventEmitter<File[]> = new EventEmitter<File[]>();

    constructor(
        @Inject(DomSanitizer) private domSanitizer: DomSanitizer,
        @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    ngOnDestroy() {
        this.inputs.forEach((i) => i.remove());
    }

    openInput() {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/jpeg, image/png';
        input.multiple = true;
        input.addEventListener('change', this.inputChange.bind(this), { once: true });

        input.click();
        this.inputs.push(input);
    }

    getAttachments() {
        return Array.from(this.entries.values()).map((v) => v.file);
    }

    delete(key: string) {
        if (this.entries.delete(key)) {
            this.filesChange.emit(this.getAttachments());
            this.changeDetectorRef.markForCheck();
        }
    }

    private inputChange(ev: Event) {
        const input = ev.target as HTMLInputElement;
        const files = input.files;
        // Number of files added
        let adds = 0;

        for (let i = 0; i < (files?.length || 0); i++) {
            const file = files?.[i];
            if (!file) {
                continue;
            }

            const id = window.btoa(`${file.name}${file.lastModified}${file.size}`);
            if (this.entries.has(id)) {
                continue;
            }

            this.entries.set(id, {
                id,
                file,
                src: this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file)),
            });

            adds += 1;
        }

        if (adds) {
            this.filesChange.emit(this.getAttachments());
            this.changeDetectorRef.markForCheck();
        }
    }
}
