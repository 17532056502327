import { ChangeDetectionStrategy, Component, computed, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { Property } from '../../../shared/models/property';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserPropertyService } from '../../../shared/http/user-property.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { SettingGroupPropertyService } from '../../../shared/http/setting-group-property.service';
import { PredefinedPropertyDialogComponent, PredefinedPropertyDialogData, PredefinedPropertyDialogResult } from '../predefined-property-dialog/predefined-property-dialog.component';
import { CustomerPropertyService } from '../../../shared/http/customer-property.service';
import { MatIconModule } from '@angular/material/icon';
import { toSignal } from '@angular/core/rxjs-interop';

export interface AddEditPropertyDialogData extends DialogData {
    property?: Observable<Property>;
    userId?: number;
    settingGroupId?: number;
    customerId?: number;
}

@Component({
    selector: 'eaw-add-edit-property-dialog',
    templateUrl: './add-edit-property-dialog.component.html',
    styleUrl: './add-edit-property-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
        MatIconModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditPropertyDialogComponent extends DialogComponent implements OnInit {
    property?: WritableSignal<Property>;
    loading = signal(true);
    isValidJSON = computed(() => {
        const value = this.valueSignal();

        if (!value) {
            return false;
        }

        try {
            JSON.parse(value);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    });

    form = new FormGroup({
        key: new FormControl<string | null>(null, Validators.required),
        value: new FormControl<string | null>(null, Validators.required),
    });

    valueSignal = toSignal(this.form.controls.value.valueChanges);

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AddEditPropertyDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AddEditPropertyDialogComponent, Property>,
        @Inject(UserPropertyService) private readonly userPropertyService: UserPropertyService,
        @Inject(CustomerPropertyService) private readonly customerPropertyService: CustomerPropertyService,
        @Inject(SettingGroupPropertyService) private settingGroupPropertyService: SettingGroupPropertyService,
        @Inject(MatDialog) private matDialog: MatDialog,
    ) {
        data.size ||= DialogSize.Large;
        super(dialogRef, data);
    }

    ngOnInit() {
        this.loading.set(!!this.data.property);

        this.data.property?.subscribe((property) => {
            this.property = signal(property);

            this.form.patchValue({
                key: property.key,
                value: property.value.value,
            });

            // Disable key field if editing
            this.form.controls.key.disable();

            this.loading.set(false);
        });
    }

    formatJSON() {
        try {
            const parsed = JSON.parse((this.form.value.value || '').trim());
            const pretty = JSON.stringify(parsed, null, 2);

            this.form.patchValue({
                value: pretty,
            });
        } catch (e) {
            console.error(e);
        }
    }

    openPredefinedDialog() {
        this.matDialog.open<PredefinedPropertyDialogComponent, PredefinedPropertyDialogData, PredefinedPropertyDialogResult>(PredefinedPropertyDialogComponent, {
            data: {
                size: DialogSize.Large,
            },
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.form.patchValue({
                key: result.key,
                value: result.value,
            });

            this.add();
        });
    }

    update() {
        const key = this.property?.().key || this.form.controls.key.value;
        const value = this.form.controls.value.value;

        if (key == null || value == null) {
            return;
        }

        this.form.disable();
        if (this.data.userId) {
            this.updateUserProperty(this.data.userId, key, value);
        } else if (this.data.settingGroupId) {
            this.updateSettingGroupProperty(this.data.settingGroupId, key, value);
        } else if (this.data.customerId) {
            this.updateCustomerProperty(this.data.customerId, key, value);
        }
    }

    updateCustomerProperty(customerId: number, key: string, value: string) {
        this.customerPropertyService.update(customerId, key, value).pipe(
            catchError(this.handleError.bind(this)),
        ).subscribe((property) => {
            this.dialogRef.close(property);
        });
    }

    updateUserProperty(userId: number, key: string, value: string) {
        this.userPropertyService.update(userId, key, value).pipe(
            catchError(this.handleError.bind(this)),
        ).subscribe((property) => {
            this.dialogRef.close(property);
        });
    }

    updateSettingGroupProperty(settingGroupId: number, key: string, value: string) {
        this.settingGroupPropertyService.update(settingGroupId, key, value).pipe(
            catchError(this.handleError.bind(this)),
        ).subscribe((property) => {
            this.dialogRef.close(property);
        });
    }

    add() {
        const form = this.form.value;
        if (form.key == null || form.value == null) {
            return;
        }

        this.form.disable();
        if (this.data.userId) {
            this.addUserProperty(this.data.userId, form.key, form.value);
        } else if (this.data.settingGroupId) {
            this.addSettingGroupProperty(this.data.settingGroupId, form.key, form.value);
        } else if (this.data.customerId) {
            this.addCustomerProperty(this.data.customerId, form.key, form.value);
        }
    }

    addCustomerProperty(customerId: number, key: string, value: string) {
        this.customerPropertyService.create(customerId, key, value).pipe(
            catchError(this.handleError.bind(this)),
        ).subscribe((property) => {
            this.dialogRef.close(property);
        });
    }

    addUserProperty(userId: number, key: string, value: string) {
        this.userPropertyService.create(userId, key, value).pipe(
            catchError(this.handleError.bind(this)),
        ).subscribe((property) => {
            this.dialogRef.close(property);
        });
    }

    addSettingGroupProperty(settingGroupId: number, key: string, value: string) {
        this.settingGroupPropertyService.create(settingGroupId, key, value).pipe(
            catchError(this.handleError.bind(this)),
        ).subscribe((property) => {
            this.dialogRef.close(property);
        });
    }

    private handleError(err: unknown) {
        console.error(err);

        if (this.data.property) {
            this.form.controls.value.enable();
        } else {
            this.form.enable();
        }

        return EMPTY;
    }
}
