import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'eaw-sidebar-not-implemented',
    standalone: true,
    imports: [],
    templateUrl: './sidebar-not-implemented.component.html',
    styleUrl: './sidebar-not-implemented.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * TODO: Remove once all sidebar things are implemented
 *
 * @deprecated
 */
export class SidebarNotImplementedComponent {

}
