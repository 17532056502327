import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, output, signal } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatMenuItemDirective } from '../../../../../../../shared/directives/mat-menu-item.directive';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ScheduleComponent, ScheduleComponentProperty } from '../../../../schedule.component';
import { Duration } from 'luxon';
import { DurationPipe } from '../../../../../../../shared/pipes/duration.pipe';
import { UserPropertyService } from '../../../../../../../shared/http/user-property.service';
import { CurrentService } from '../../../../../../../shared/services/current.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '../../../../../../../shared/services/translate.service';
import { Namespace } from '../../../../../../../shared/enums/namespace';
import { AsyncPipe } from '@angular/common';
import { debounceTime, distinctUntilChanged, EMPTY, of, switchMap, tap } from 'rxjs';
import { Products } from '../../../../../../../shared/enums/products';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate.pipe';
import { ToolbarButtonComponent } from '../../../../../../../shared/components/toolbar-button/toolbar-button.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MaterialColorDirective } from '../../../../../../../shared/directives/material-color.directive';
import { MatIconSizeDirective } from '../../../../../../../shared/directives/mat-icon-size.directive';
import { PermissionDirective } from '../../../../../../../permissions/directives/permission.directive';
import { MatDialog } from '@angular/material/dialog';
import { CreateNewShiftDialogComponent, CreateNewShiftDialogData, CreateNewShiftDialogReturn } from '../../../../../../dialogs/create-new-shift-dialog/create-new-shift-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from '../../../../../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogSize } from '../../../../../../../shared/dialogs/dialog-component';
import { ScheduleService } from '../../../../../../http/schedule.service';
import { SnackBarService } from '../../../../../../../shared/services/snack-bar.service';
import { canCreateShiftPermission, canForcePublishSchedulePermission, canPublishSchedulePermission, canUpdateSchedulePermission } from '../../../../../../permissions';
import { PublishScheduleDialogComponent, PublishScheduleDialogData } from '../../../../dialogs/publish-schedule-dialog/publish-schedule-dialog.component';

export type ScheduleTabMenuSorting = 'shift_offset_asc' | 'shift_offset_desc' | 'employee_name_asc' | 'employee_name_desc';
export type ScheduleTabMenuGrouping = 'none' | 'employee' | 'business_unit';
export type ScheduleTabMenuMode = 'edit_shifts' | 'drag_periods';
export type ScheduleTabShiftsDisplay = 'all' | 'assigned' | 'unassigned';

type RadioOption<T> = { value: T, label: Promise<string> };

@Component({
    selector: 'eaw-schedule-tab-menu',
    standalone: true,
    imports: [
        MatButton,
        MatDivider,
        MatIcon,
        MatMenu,
        MatMenuItem,
        MatMenuContent,
        MatMenuTrigger,
        MatMenuItemDirective,
        MatCheckbox,
        MatRadioButton,
        MatRadioGroup,
        ReactiveFormsModule,
        DurationPipe,
        AsyncPipe,
        TranslatePipe,
        MatIconButton,
        ToolbarButtonComponent,
        MatTooltip,
        MaterialColorDirective,
        MatIconSizeDirective,
        PermissionDirective,
    ],
    templateUrl: './schedule-tab-menu.component.html',
    styleUrl: './schedule-tab-menu.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleTabMenuComponent {
    userPropertyService = inject(UserPropertyService);
    translateService = inject(TranslateService);
    scheduleService = inject(ScheduleService);
    snackBarService = inject(SnackBarService);
    currentService = inject(CurrentService);
    destroyRef = inject(DestroyRef);
    matDialog = inject(MatDialog);

    dayChange = output<-1 | 1>();
    refreshStatistics = output<void>();

    stackId = input.required<number>();
    customerId = input.required<number>();
    scheduleId = input.required<number>();

    protected sortings: RadioOption<ScheduleTabMenuSorting>[];
    protected modes: RadioOption<ScheduleTabMenuMode>[];
    protected shiftDisplayModes: RadioOption<ScheduleTabShiftsDisplay>[];
    protected intervals: number[];
    protected groupings = computed(this.computeGroupings.bind(this));

    userId = this.currentService.getUser().id;
    schedule = computed(() => ScheduleComponent.schedule());
    hasBusinessUnits = computed(() => ScheduleComponent.products().has(Products.BusinessUnits));
    hasBackoffice = computed(() => ScheduleComponent.products().has(Products.BackOffice));
    canNotify = computed(this.computeCanNotify.bind(this));
    canPublish = computed(() => canPublishSchedulePermission(this.stackId(), this.customerId(), this.scheduleId()));
    canForcePublish = computed(() => canForcePublishSchedulePermission(this.stackId(), this.customerId(), this.scheduleId()));
    canDeclineSchedule = computed(this.computeCanDeclineSchedule.bind(this));
    isFullscreen = signal(false);
    updateSchedulePermission = computed(() => canUpdateSchedulePermission(this.stackId(), this.customerId(), this.scheduleId()));
    createShiftPermission = computed(() => canCreateShiftPermission(this.stackId(), this.customerId(), this.scheduleId()));

    topStatsEnabledControl = new FormControl<boolean>(ScheduleComponent.properties.scheduleTab.topStatsEnabled.value(), { nonNullable: true });
    expandedShiftsControl = new FormControl<boolean>(ScheduleComponent.properties.scheduleTab.expandedShifts.value(), { nonNullable: true });
    verticalLinesControl = new FormControl<boolean>(ScheduleComponent.properties.scheduleTab.verticalLines.value(), { nonNullable: true });
    openingHoursControl = new FormControl<boolean>(ScheduleComponent.properties.scheduleTab.openingHours.value(), { nonNullable: true });
    nicknamesControl = new FormControl<boolean>(ScheduleComponent.properties.scheduleTab.showNicknames.value(), { nonNullable: true });
    modeControl = new FormControl<ScheduleTabMenuMode>(ScheduleComponent.properties.scheduleTab.mode.value(), { nonNullable: true });
    shiftDisplayModeControl = new FormControl<ScheduleTabShiftsDisplay>(ScheduleComponent.properties.scheduleTab.shiftDisplayMode.value(), { nonNullable: true });
    groupingControl = new FormControl<ScheduleTabMenuGrouping>(ScheduleComponent.properties.scheduleTab.shiftGrouping.value(), { nonNullable: true });
    sortingControl = new FormControl<ScheduleTabMenuSorting>(ScheduleComponent.properties.scheduleTab.sorting.value(), { nonNullable: true });
    intervalControl = new FormControl<number>(ScheduleComponent.properties.scheduleTab.interval.value(), { nonNullable: true });

    constructor() {
        // Save the user properties when the controls change
        this.addChangeListener(this.topStatsEnabledControl, ScheduleComponent.properties.scheduleTab.topStatsEnabled);
        this.addChangeListener(this.sortingControl, ScheduleComponent.properties.scheduleTab.sorting);
        this.addChangeListener(this.intervalControl, ScheduleComponent.properties.scheduleTab.interval);
        this.addChangeListener(this.groupingControl, ScheduleComponent.properties.scheduleTab.shiftGrouping);
        this.addChangeListener(this.verticalLinesControl, ScheduleComponent.properties.scheduleTab.verticalLines);
        this.addChangeListener(this.expandedShiftsControl, ScheduleComponent.properties.scheduleTab.expandedShifts);
        this.addChangeListener(this.openingHoursControl, ScheduleComponent.properties.scheduleTab.openingHours);
        this.addChangeListener(this.nicknamesControl, ScheduleComponent.properties.scheduleTab.showNicknames);

        // These are not saved in the user properties
        this.addChangeListener(this.modeControl, ScheduleComponent.properties.scheduleTab.mode, false);
        this.addChangeListener(this.shiftDisplayModeControl, ScheduleComponent.properties.scheduleTab.shiftDisplayMode, false);

        this.sortings = [
            { value: 'shift_offset_asc', label: this.translateService.t('OFFSET_ASC', Namespace.Scheduling) },
            { value: 'shift_offset_desc', label: this.translateService.t('OFFSET_DESC', Namespace.Scheduling) },
            { value: 'employee_name_asc', label: this.translateService.t('NAME_ASC', Namespace.Scheduling) },
            { value: 'employee_name_desc', label: this.translateService.t('NAME_DESC', Namespace.Scheduling) },
        ];

        this.modes = [
            { value: 'edit_shifts', label: this.translateService.t('EDIT_SHIFT_plural', Namespace.Scheduling) },
            { value: 'drag_periods', label: this.translateService.t('DRAG_PERIODS', Namespace.Scheduling) },
        ];

        this.shiftDisplayModes = [
            { value: 'all', label: this.translateService.t('ALL', Namespace.Scheduling) },
            { value: 'assigned', label: this.translateService.t('ASSIGNED', Namespace.Scheduling) },
            { value: 'unassigned', label: this.translateService.t('UNASSIGNED', Namespace.Scheduling) },
        ];

        this.intervals = [
            Duration.fromObject({ minutes: 10 }).as('seconds'),
            Duration.fromObject({ minutes: 15 }).as('seconds'),
            Duration.fromObject({ minutes: 30 }).as('seconds'),
            Duration.fromObject({ hours: 1 }).as('seconds'),
            Duration.fromObject({ hours: 2 }).as('seconds'),
            Duration.fromObject({ hours: 4 }).as('seconds'),
        ];
    }

    newShift() {
        const schedule = ScheduleComponent.schedule();

        this.matDialog.open<CreateNewShiftDialogComponent, CreateNewShiftDialogData, CreateNewShiftDialogReturn>(CreateNewShiftDialogComponent, {
            data: {
                customerId: this.customerId(),
                scheduleId: this.scheduleId(),
                schedule: schedule ? of(schedule) : undefined,
                date: schedule?.from,
                withs: ScheduleComponent.shiftWiths,
            },
        }).afterClosed().subscribe((shift) => {
            if (shift) {
                ScheduleComponent.setShift(shift);
                ScheduleComponent.broadcastEvent(shift.id, 'created', shift);
            }
        });
    }

    goToOriginal() {
        // TODO: Implement
    }

    declineSchedule() {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translateService.t('DECLINE_SCHEDULE', Namespace.Scheduling),
                text: this.translateService.t('DECLINE_SCHEDULE_QUESTION', Namespace.Scheduling),
                confirmText: this.translateService.t('DECLINE'),
                size: DialogSize.Medium,
            },
        }).afterClosed().subscribe((result) => {
            if (result?.ok) {
                this.scheduleService.declineSchedule(this.customerId(), this.scheduleId()).subscribe(() => {
                    this.snackBarService.t('SCHEDULE_DECLINED', Namespace.Scheduling);
                    ScheduleComponent.properties.schedule.auditorsNotified.value.set(false);
                });
            }
        });
    }

    notifyAuditors() {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translateService.t('NOTIFY_AUDITORS', Namespace.Scheduling),
                text: this.translateService.t('SEND_TO_AUDITORS_QUESTION', Namespace.Scheduling),
                confirmText: this.translateService.t('SEND'),
                size: DialogSize.Medium,
            },
        }).afterClosed().subscribe((result) => {
            if (result?.ok) {
                this.scheduleService.notifyAuditors(this.customerId(), this.scheduleId()).subscribe(() => {
                    this.snackBarService.t('AUDITOR_NOTIFIED_plural', Namespace.Scheduling);
                    ScheduleComponent.properties.schedule.auditorsNotified.value.set(true);
                });
            }
        });
    }

    publish() {
        this.matDialog.open<PublishScheduleDialogComponent, PublishScheduleDialogData>(PublishScheduleDialogComponent, {
            data: {
                stackId: this.stackId(),
                customerId: this.customerId(),
                scheduleId: this.scheduleId(),
            },
        });
    }

    private computeCanDeclineSchedule() {
        const hasAuditors = ScheduleComponent.properties.customer.scheduleAuditorGroupId.value();
        const auditorsNotified = ScheduleComponent.properties.schedule.auditorsNotified.value() === true;
        const schedule = this.schedule();

        if (!schedule) {
            return false;
        }

        return !schedule.isTemplate && !schedule.isPublished && hasAuditors && auditorsNotified;
    }

    private computeCanNotify() {
        const hasAuditors = ScheduleComponent.properties.customer.scheduleAuditorGroupId.value();
        const auditorsNotNotified = ScheduleComponent.properties.schedule.auditorsNotified.value() !== true;
        const schedule = this.schedule();

        if (!schedule) {
            return false;
        }

        return !schedule.isTemplate && !schedule.isPublished && hasAuditors && auditorsNotNotified;
    }

    private addChangeListener<T>(control: FormControl<T>, property: ScheduleComponentProperty<T>, saveProperty = true, valueFormatter: (value: T) => string = (value) => String(value)) {
        control.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((value) => property.value.set(value)),
            debounceTime(1000),
            distinctUntilChanged(),
            switchMap((value) => {
                return saveProperty ? this.userPropertyService.update(this.userId, property.key, valueFormatter(value)).pipe(
                    takeUntilDestroyed(this.destroyRef),
                ) : EMPTY;
            }),
        ).subscribe();
    }

    private computeGroupings() {
        const groupings = [
            { value: 'none', label: this.translateService.t('NO_GROUPING', Namespace.Scheduling) },
            { value: 'employee', label: this.translateService.t('EMPLOYEE') },
        ] as RadioOption<ScheduleTabMenuGrouping>[];

        if (ScheduleComponent.products().has(Products.BusinessUnits)) {
            groupings.push({ value: 'business_unit', label: this.translateService.t('BUSINESS_UNIT') });
        }

        return groupings;
    }
}
