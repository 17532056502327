import { module } from 'angular';
import MlVariable from './shared/ml-variable';
import MlBucket from './shared/ml-bucket';
import { Namespace } from '../../shared/enums/namespace';
import { McdProjectionsComponent } from '../pages/mcd-projections/mcd-projections.component';
import { LearningModuleModule } from '../../learning-module/types/modules';
import { Products } from '../../shared/enums/products';
import { createState } from '../../shared/utils/create-state';
import { staticResolver } from '../../shared/resolvers/static.resolver';
import { dependencyResolver } from '../../shared/resolvers/dependency.resolver';

module('eaw.ai-budgeting', [
    'eaw.misc',
    'eaw.dialogs',
]).config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: `eaw/app/ai_budgeting`,
        parent: 'eaw/app',
        url: '/ai_budgeting',
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            learningModuleModules: [ LearningModuleModule.AiBudgeting ],
            i18nextNs: [ Namespace.AiBudgeting, Namespace.Widgets ],
            breadcrumb: { key: 'AI_BUDGETING', ns: 'ai_budgeting' },
            products: [ Products.AIBudgeting ],
            permissions: [ `customers.{customer}.ml_buckets.*.get` ],
            settings: {
                'ai-projections.use_new_interface': (value) => !value || value?.asBoolean() === false,
            },
        },
        resolve: [
            staticResolver('navbarRoute', 'eaw/app/ai_budgeting'),
            staticResolver('tabs', [
                {
                    label: 'ai_budgeting:SALES_PROJECTION_plural',
                    src: 'eaw/app/ai_budgeting/mcd_suisse',
                },
                {
                    label: 'ai_budgeting:PROJECTION_plural',
                    src: `eaw/app/ai_budgeting/ai_budgeting_easy_projections`,
                },
                {
                    label: 'ai_budgeting:PROJECTION_LOCKS',
                    src: 'eaw/app/ai_budgeting/locks',
                },
                {
                    label: 'ai_budgeting:FORECAST_plural',
                    src: `eaw/app/ai_budgeting/ai_budgeting_forecast`,
                    permission: 'ai-budgeting.superuser',
                }, {
                    label: 'ai_budgeting:PROJECTION_plural',
                    src: `eaw/app/ai_budgeting/projections`,
                    permission: 'ai-budgeting.superuser',
                },
            ]),
            dependencyResolver('constants', ['mlTimeSeriesFactory'], function(mlTimeSeriesFactory: any) {
                return mlTimeSeriesFactory.getConstants.query();
            }),
            dependencyResolver('bucket', ['customer', 'mlVariableFactory', 'mlBucketFactory', 'CustomerSettings'], async function(customer: any, mlVariableFactory: any, mlBucketFactory: any, CustomerSettings: any) {
                if (!customer.hasProduct('ai budgeting')) {
                    return;
                }

                const bucketKey = 'ai-budgeting-ml-bucket';
                let bucketUuid = '';
                try {
                    const resp = await CustomerSettings.get(customer.id, bucketKey);
                    bucketUuid = resp[bucketKey];
                    if (!bucketUuid) {
                        return;
                    }

                    customer.setProperty({ key: bucketKey, value: bucketUuid });
                } catch (e) {
                    console.error(e);
                    return;
                }

                return Promise.all([
                    mlBucketFactory.get.query(customer.id, bucketUuid).then((resp: any) => new MlBucket(resp)),
                    mlVariableFactory.getAll.query(customer.id, bucketUuid, { per_page: 999 }).then((resp: any) => resp.data),
                ]).then((resp) => {
                    const [ bucket, variables ] = resp;
                    variables.forEach((v: any) => bucket.addVariable(new MlVariable(v)));
                    return bucket;
                });
            }),
        ],
    });

    $stateProvider.state('eaw/app/ai_budgeting/mcd_suisse', {
        parent: `eaw/app/ai_budgeting`,
        url: '/mcd_suisse',
        component: 'aiBudgetingMcdSuisse',
        data: {
            breadcrumb: { key: 'SALES_PROJECTION_plural', ns: 'ai_budgeting' },
        },
    });

    $stateProvider.state('eaw/app/ai_budgeting/locks', {
        parent: `eaw/app/ai_budgeting`,
        url: '/locks',
        component: 'aiBudgetForecastLocks',
        data: {
            breadcrumb: { key: 'PROJECTION_LOCKS', ns: 'ai_budgeting' },
        },
    });

    $stateProvider.state(`eaw/app/ai_budgeting/ai_budgeting_easy_projections`, {
        parent: `eaw/app/ai_budgeting`,
        url: '/projections',
        component: 'aiBudgetingEasyProjections',
        data: {
            breadcrumb: { key: 'PROJECTION_plural', ns: 'ai_budgeting' },
        },
    });

    $stateProvider.state(`eaw/app/ai_budgeting/ai_budgeting_forecast`, {
        parent: `eaw/app/ai_budgeting`,
        url: '/forecasts',
        component: 'aiBudgetForecasts',
        data: {
            permission: 'ai-budgeting.superuser',
            breadcrumb: { key: 'FORECAST_plural', ns: 'ai_budgeting' },
        },
    });

    $stateProvider.state(`eaw/app/ai_budgeting/projections`, {
        parent: `eaw/app/ai_budgeting`,
        url: '/projections2',
        component: 'aiBudgetProjections',
        data: {
            permission: 'ai-budgeting.superuser',
            breadcrumb: { key: 'PROJECTION_plural', ns: 'ai_budgeting' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/ai_projections`,
        parent: `eaw/app`,
        url: '/ai_projections',
        views: {
            'content@': {
                component: McdProjectionsComponent,
            },
        },
        data: {
            products: [ Products.AIBudgeting ],
            permissions: [ `customers.{customer}.ml_buckets.*.get` ],
            breadcrumb: { key: 'PROJECTION_plural', ns: 'ai_budgeting' },
            learningModuleModules: [ LearningModuleModule.AiBudgeting ],
            settings: {
                'ai-projections.use_new_interface': (value) => value?.asBoolean() === true,
            },
        },
    });
} ]);
