import { Inject, Injectable } from '@angular/core';
import { Autocomplete } from './autocomplete';
import { TranslateService } from '../services/translate.service';
import { Qualification } from '../models/qualification';
import { QualificationService } from '../http/qualification.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QualificationAutocompleteService extends Autocomplete<Qualification> {

    constructor(
      @Inject(QualificationService) protected qualificationService: QualificationService,
      @Inject(TranslateService) protected translateService: TranslateService,
    ) {
        super({
            label: translateService.t('QUALIFICATION', 'company'),
            optionTemplate: 'default',
            filterRequirement: 3,
            display: 'name',
            trackByKey: 'id',
        });
    }

    setter(args: {customerId: number}) {
        return (item: unknown) => {
            if (item instanceof Qualification) {
                return of(item);
            }
            if (typeof item !== 'number') {
                return of(undefined);
            }

            return this.qualificationService.get(args.customerId, item);
        };
    }

    getter(args: { customerId: number }) {
        return (filter?: string) => {
            return this.qualificationService.getAll(args.customerId, {
                filter,
                per_page: 9999,
                order_by: 'name',
                direction: 'asc',
            });
        };
    }
}
