// @ts-nocheck
import { module } from 'angular';
import {smallDevice} from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
const template1 = `<standby-list employee="$standby.employee"
              days="$standby.days"
              customer-id="$standby.customerId"
              read-only="$standby.readOnly"
              day="$standby.day">
</standby-list>
`;
module('eaw.scheduling').component('sidebarStandbyList', {
    template: template1,
    controllerAs: '$standby',
    bindings: {
        employee: '<',
        days: '<',
        customerId: '<',
        readOnly: '<?',
        maxDate: '<?',
    },
    require: {
        sidebar: '^scheduleSidebar',
    },
    controller: [ 'scheduleTable', function(scheduleTable) {
        this.$onInit = () => {
            this.smallDevice = smallDevice();
            this.day = scheduleTable.getCurrentDay();
        };
    } ],
});
