import { Component, input } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { Widget } from '../../classes/widget';

@Component({
    selector: 'eaw-normal-skeleton-widget',
    template: '',
    styleUrl: './normal-skeleton-widget.component.scss',
    standalone: true,
})
export class NormalSkeletonWidgetComponent extends WidgetComponent {
    widget = input.required<Widget>();

    constructor() {
        super();
        this.setLoading(false);
    }
}
