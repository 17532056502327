<div>
    {{'HOURS_PLANNED_THIS_WEEK' | translate: 'scheduling': {
    count: summary.planned,
    hours: (summary.planned | eawNumber: 2)
} | async }}
</div>
<div>
    {{'HOURS_WORKED_LAST_WEEK' | translate: 'scheduling': {
    count: summary.last,
    hours: (summary.last | eawNumber: 2)
} | async }}
</div>
<div>
    {{'HOURS_WORKED_AVERAGE_PER_WEEK' | translate: 'scheduling': {
    count: summary.average,
    hours: (summary.average | eawNumber: 2)
} | async }}
</div>
