// @ts-nocheck
import { module } from 'angular';
import { LoginComponent } from '../login/pages/login/login.component';
import { ForgotPasswordComponent } from '../login/pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../login/pages/reset-password/reset-password.component';
module('eaw.login', [
    'eaw.resource',
    'eaw.login.verify-email',
    'eaw.login.auth',
    'eaw.misc',
    'eaw.components',
]).config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state('eaw/outside', {
        parent: 'eaw',
        abstract: true,
        data: {
            requireLogin: false,
        },
    });
    $stateProvider.state('eaw/outside/login', {
        parent: 'eaw/outside',
        url: '/login?:email',
        data: {
            fullscreen: true,
            breadcrumb: { key: 'LOGIN', ns: 'navigation' },
        },
        views: {
            'content@': {
                component: LoginComponent,
            },
        },
        params: {
            email: {
                type: 'string',
                squash: true,
            },
            wasLoggedOut: {
                type: 'bool',
                value: false,
                squash: true,
            },
        },
    });
    $stateProvider.state('eaw/outside/forgot_password', {
        parent: 'eaw/outside',
        url: '/forgot_password',
        data: {
            fullscreen: true,
            breadcrumb: { key: 'FORGOT_PASSWORD', ns: 'navigation' },
        },
        views: {
            'content@': {
                component: ForgotPasswordComponent,
            },
        },
    });
    $stateProvider.state('eaw/outside/reset_password', {
        parent: 'eaw/outside',
        url: '/reset_password/:token',
        data: {
            fullscreen: true,
            breadcrumb: { key: 'RESET_PASSWORD', ns: 'navigation' },
        },
        views: {
            'content@': {
                component: ResetPasswordComponent,
            },
        },
    });
} ]);
