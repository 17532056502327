<eaw-dialog-header>
    <span title>{{ 'CONTRACT' | translate | async }}</span>
</eaw-dialog-header>

@if (loading) {
    <mat-dialog-content class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </mat-dialog-content>
} @else {
    <mat-dialog-content>
        <form [formGroup]="form" class="tw-grid tw-grid-cols-2 tw-gap-8">
            <eaw-autocomplete class="tw-col-span-full"
                              formControlName="contractType"
                              [options]="contractTypeAutocomplete.options"
                              [getter]="contractTypeAutocomplete.getter(customerId)"
                              [setter]="contractTypeAutocomplete.setter(customerId)">
            </eaw-autocomplete>

            <mat-form-field eawDatePickerOptions>
                <mat-label>{{ 'FROM' | translate | async }}</mat-label>
                <input matInput formControlName="from" [max]="form.getRawValue().to" [matDatepicker]="fromPicker">
                <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                <mat-datepicker #fromPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field eawDatePickerOptions>
                <mat-label>{{ 'TO' | translate | async }}</mat-label>
                <input matInput formControlName="to" [min]="form.getRawValue().from" [matDatepicker]="toPicker">
                <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                <mat-datepicker #toPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ ('CONTRACT_AMOUNT_' + form.getRawValue().amountType.toUpperCase()) | translate: 'company' | async }}</mat-label>
                <input matInput formControlName="amount" type="number" min="0">
                <mat-error *ngIf="form.hasError('tooLarge', 'amount')" [ngSwitch]="form.getRawValue().amountType">
                    <span *ngSwitchCase="'week'">{{ 'ERROR_HOURS_WEEK' | translate: 'company': {count: 7 * 24} | async }}</span>
                    <span *ngSwitchCase="'month'">{{ 'ERROR_HOURS_MONTH' | translate: 'company': {count: 31 * 24} | async }}</span>
                    <span *ngSwitchCase="'year'">{{ 'ERROR_HOURS_YEAR' | translate: 'company': {count: 365 * 24} | async }}</span>
                    <span *ngSwitchCase="'percent'">{{ 'ERROR_HOURS_PERCENT' | translate: 'company': {max: 100} | async }}</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'CONTRACT_AMOUNT_TYPE' | translate: 'company' | async }}</mat-label>
                <mat-select formControlName="amountType">
                    <mat-option *ngFor="let type of amountTypes" [value]="type">{{ type.toUpperCase() | translateSync }}</mat-option>
                </mat-select>
            </mat-form-field>

            @if (positions?.length) {
                <mat-form-field class="tw-col-span-full">
                    <mat-label>{{ 'TITLE' | translate | async }}</mat-label>
                    <mat-select formControlName="position">
                        <mat-option [value]="null">{{ 'NONE' | translateSync }}</mat-option>

                        @for (position of positions; track position.id) {
                            <mat-option [value]="position">{{ position.name }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            }

            @for (field of customFields; track field.key) {
                <eaw-custom-field-input class="tw-col-span-full" [customField]="field" [formGroup]="form.controls.customFields"/>
            }
        </form>
    </mat-dialog-content>
}

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>

    <eaw-action-button [loading]="false" (click)="submit()" [disabled]="!form.valid || form.pristine">
        {{ (contract ? 'UPDATE' : 'CREATE') | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
