import { Component, Inject, Input, OnInit } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { TasksService } from '../../services/tasks.service';
import { EmployeeTask } from '../../../shared/models/employee-task';
import { UploadDocumentTask } from '../../classes/upload-document-task';
import { MatButtonModule } from '@angular/material/button';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { catchError, of, take, tap } from 'rxjs';
import { FormRulesComponent } from '../../../shared/components/form-rules/form-rules.component';
import { DocumentServiceService } from '../../../shared/http/document-service.service';
import { HrFileType } from '../../../hr/models/hr-file-type';
import { HrFileTypeService } from '../../../hr/http/hr-file-type.service';
import { CurrentService } from '../../../shared/services/current.service';

@Component({
    selector: 'eaw-upload-document',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
        MatButtonModule,
        ActionButtonComponent,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        FormRulesComponent,
        FormsModule,
    ],
    templateUrl: './upload-document.component.html',
    styleUrl: './upload-document.component.scss',
})
export class UploadDocumentComponent implements OnInit {
    @Input({ transform: (value: EmployeeTask): UploadDocumentTask => value as UploadDocumentTask, required: false }) task!: UploadDocumentTask;
    file?: string;
    originalFileName?: string;
    fileNameInput: FormControl<string>;
    saving = false;
    fileType?: HrFileType;

    constructor(
        @Inject(TasksService) public tasksService: TasksService,
        @Inject(DocumentServiceService) public documentServiceService: DocumentServiceService,
        @Inject(HrFileTypeService) public hrFileTypeService: HrFileTypeService,
        @Inject(CurrentService) public current: CurrentService,
    ) {
        const validators = [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(40),
        ];
        this.fileNameInput = new FormControl<string>('', { nonNullable: true, validators });
    }

    ngOnInit() {
        this.hrFileTypeService.get(this.task.customerId, this.task.fileTypeId). subscribe((res) => {
            this.fileType = res;
        });
    }

    selectFile(event: Event) {
        this.saving = true;
        const element = event.currentTarget as HTMLInputElement;
        const fileList: FileList | null = element.files;
        if (fileList) {
            const reader = new FileReader();
            const file = fileList.item(0);
            if (file) {
                this.originalFileName = file.name;
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if (reader.result) {
                        const result = reader.result as string;
                        this.file = result.split(',')[1];
                        this.saving = false;
                    }
                };
            }
        }
    }

    handleUpload() {
        if (this.file && this.originalFileName && this.fileNameInput.value) {
            this.saving = true;
            this.documentServiceService.uploadTaskDocument(
                this.file,
                this.task.customerId,
                this.task.employeeId,
                this.current.getUser().id,
                this.task.workflowId,
                this.task.runId,
                this.task.currentRunStepId,
                this.fileNameInput.value,
                this.originalFileName,
                this.task.revision,
            ).pipe(
                take(1),
                tap(() => {
                    this.task.complete();
                }),
                catchError(() => {
                    this.fileNameInput.enable();
                    return of(null);
                }),
                tap(() => {
                    this.saving = false;
                }),
            ).subscribe();

            this.fileNameInput.disable();
        }
    }
}
