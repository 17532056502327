import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'company-absence-types-upgraded',
    standalone: true,
})
export class companyAbsenceTypesUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('companySettingsAbsenceTypes', elementRef, injector);
    }
}

@Component({
    template: '<company-absence-types-upgraded [customer]="customer"></company-absence-types-upgraded>',
    standalone: true,
    imports: [ companyAbsenceTypesUpgradedDirective ],
})
export class companyAbsenceTypesUpgradedComponent {
    @Input() customer!: any;
}

@NgModule({
    imports: [
        companyAbsenceTypesUpgradedDirective,
        companyAbsenceTypesUpgradedComponent,
    ],
})
export class companyAbsenceTypesUpgradeModule {
}
