import { Injectable } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';
import { NamespaceFile } from '../enums/namespace';

type EawMaterialColor = 'red' | 'pink' | 'purple' | 'deep-purple' | 'indigo' | 'blue' | 'light-blue' | 'cyan' | 'teal' | 'green' | 'light-green' | 'lime' | 'yellow' | 'amber' | 'orange' | 'deep-orange' | 'brown' | 'grey' | 'blue-grey' | 'black' | 'white';
export type EawMaterialHue = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
export type EawMaterialColorHue = `${EawMaterialColor}-${EawMaterialHue}`;

export interface MaterialColorHue {
    name: EawMaterialColor;
    fullName: EawMaterialColorHue;
    hue: EawMaterialHue;
    tiny: TinyColor;
    index: number;
}

export interface MaterialColor {
    name: EawMaterialColor;
    translationKey: string;
    translationNs: NamespaceFile;
    hues: MaterialColorHue[]
}

@Injectable({
    providedIn: 'root',
})
export class MaterialColorService {
    private colors?: Record<EawMaterialColor, Record<EawMaterialHue, string | number>>;

    private async importColors() {
        if (!this.colors) {
            this.colors = (await import(/* webpackChunkName: "material-colors-json" */ 'src/assets/json/material-colors.json')).default;
        }

        return this.colors;
    }

    getColorKeys(): EawMaterialColor[] {
        return [ 'red' , 'pink' , 'purple' , 'deep-purple' , 'indigo' , 'blue' , 'light-blue' , 'cyan' , 'teal' , 'green' , 'light-green' , 'lime' , 'yellow' , 'amber' , 'orange' , 'deep-orange' , 'brown' , 'grey', 'blue-grey', 'black' , 'white' ];
    }

    getHueKeys(): EawMaterialHue[] {
        return [ 50, 100, 200, 300, 400, 500, 600, 700, 800, 900 ];
    }

    /**
     * Get the matching MaterialColor based on input
     * @param input
     */
    async get(input: TinyColor | undefined | null): Promise<MaterialColorHue | undefined> {
        if (!input) {
            return undefined;
        }

        const all = await this.getAll();
        return all.flatMap((color) => color.hues).find((hue) => hue.tiny.equals(input));
    }

    async getAll(): Promise<MaterialColor[]> {
        const colors = await this.importColors();

        return this.getColorKeys().map((color) => {
            return {
                name: color,
                translationKey: color.toUpperCase().replaceAll('-', '_'),
                translationNs: 'colors',
                hues: this.getHueKeys().map((hue, i) => {
                    const tiny = new TinyColor('#' + colors[color][hue]);

                    return {
                        hue,
                        index: i,
                        tiny,
                        fullName: `${color}-${hue}`,
                        name: color,
                    } satisfies MaterialColorHue;
                }),
            } satisfies MaterialColor;
        });
    }

    /**
     * Transform a color to a TinyColor instance
     * @param color
     */
    async colorToTiny(color: EawMaterialColorHue): Promise<TinyColor> {
        const colors = await this.importColors();

        const split = color.split('-');
        let name = split[0];
        let hue = split[1];

        if (split.length === 3) {
            name = `${name}-${hue}`;
            hue = split[2];
        }

        return new TinyColor('#' + colors[name as EawMaterialColor][hue as unknown as EawMaterialHue]);
    }
}
