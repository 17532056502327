import { debounce } from 'lodash-es';
import { module } from 'angular';
import { Subject } from 'rxjs';
import { scheduleViewShiftEventSubject } from '../../signals';

export type AngularJsShiftEventType = 'created' | 'updated' | 'deleted' | 'creating' | 'updating' | 'deleting';

module('eaw.scheduling.shifts').service('shiftEvents', [ '$rootScope', function shiftEvents($rootScope: any) {
    // @ts-ignore
    const events = this;

    let changedSubject = new Subject();
    let loadedSubject = new Subject<void>();
    const prefix = 'schedule:shift';
    const creating = `${prefix}:creating`;
    const created = `${prefix}:created`;
    const periodsChange = `${prefix}:periodsChange`;
    const updating = `${prefix}:updating`;
    const updated = `${prefix}:updated`;
    const deleting = `${prefix}:deleting`;
    const deleted = `${prefix}:deleted`;
    const loaded = `${prefix}:loaded`;
    const loadProgress = `${prefix}:load_progress`;
    let broadcasts: Record<string, any> = {};

    events.reset = () => {
        events.shiftsLoaded = false;
        changedSubject?.unsubscribe();
        loadedSubject?.unsubscribe();
        changedSubject = new Subject();
        loadedSubject = new Subject();
    };
    events.getLoadedSubject = () => loadedSubject;

    events.getChangedSubject = () => changedSubject;

    events.addBroadcast = (event: any, shift: any = {}) => {
        broadcasts[`${shift.id}-${event}`] = [ event, shift ];

        scheduleViewShiftEventSubject.next({ event, data: shift });

        debounce(() => {
            Object.values(broadcasts).forEach((val) => {
                $rootScope.$broadcast(...val);
            });
            broadcasts = {};
        }, 100)();
    };
    events.trigger = {
        periodsChange(shiftId: number) {
            events.addBroadcast(periodsChange, shiftId);
        },
        created(shift: any) {
            events.addBroadcast(created, shift);
            changedSubject.next(shift);
        },
        updated(shift: any) {
            events.addBroadcast(updated, shift);
            changedSubject.next({
                shift,
                event: 'updated',
            });
        },
        deleted(shift: any) {
            events.addBroadcast(deleted, shift);
            changedSubject.next({
                shift,
                event: 'deleted',
            });
        },
        loadProgress(progress: any) {
            $rootScope.$broadcast(loadProgress, progress);
        },
        loaded() {
            events.trigger.loadProgress(100);
            loadedSubject.next();
            loadedSubject.complete();
            events.shiftsLoaded = true;
            $rootScope.$broadcast(loaded);
        },
        creating(shift: any) {
            shift.setStatus('save');
            $rootScope.$broadcast(creating, shift);
            changedSubject.next({
                shift,
                event: 'creating',
            });
        },
        updating(shift: any) {
            shift.setStatus('update');
            $rootScope.$broadcast(updating, shift);
            changedSubject.next({
                shift,
                event: 'updating',
            });
        },
        deleting(shift: any) {
            shift.setStatus('delete');
            $rootScope.$broadcast(deleting, shift);
            changedSubject.next({
                shift,
                event: 'deleting',
            });
        },
    };
    events.register = {
        onCreated($scope: any, callback: any) {
            return $scope.$on(created, callback);
        },
        onUpdated($scope: any, callback: any) {
            return $scope.$on(updated, callback);
        },
        onDeleted($scope: any, callback: any) {
            return $scope.$on(deleted, callback);
        },
        onCreating($scope: any, callback: any) {
            return $scope.$on(creating, callback);
        },
        onUpdating($scope: any, callback: any) {
            return $scope.$on(updating, callback);
        },
        onDeleting($scope: any, callback: any) {
            return $scope.$on(deleting, callback);
        },
        onPeriodsChange($scope: any, callback: any) {
            return $scope.$on(periodsChange, callback);
        },
        onDoing($scope: any, callback: any) {
            return [
                events.register.onCreating($scope, callback),
                events.register.onUpdating($scope, callback),
                events.register.onDeleting($scope, callback),
            ];
        },
        onChange($scope: any, callback: any) {
            return [
                events.register.onCreated($scope, callback),
                events.register.onUpdated($scope, callback),
                events.register.onDeleted($scope, callback),
            ];
        },
        onLoadProgress($scope: any, callback: any) {
            return $scope.$on(loadProgress, callback);
        },
        onLoaded($scope: any, callback: any) {
            if (events.shiftsLoaded) {
                return callback();
            }
            return $scope.$on(loaded, callback);
        },
    };

    return events;
} ]);
