// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
module('eaw.company.src').factory('fiscalInfoFactory', [ 'EawResource', function fiscalInfoFactory(EawResource) {
    const url = '/customers/:customer/employees/:employee/fiscal_info';
    const fields = [
        'iban',
        'bank_id',
        'account_number',
        'visa_permit_type',
        'expiration',
        'emission',
        'spouse_works_switzerland',
        'account_name',
        'spouse_visa_permit_type',
        'fte_other_employment',
    ];
    return {
        create(customerId, employeeId, data) {
            return EawResource.create(url).save({
                customer: customerId,
                employee: employeeId,
            }, pick(data, fields));
        },
        get(customerId, employeeId, withRelations) {
            return EawResource.create(url).get({
                customer: customerId,
                employee: employeeId,
                'with[]': withRelations,
            });
        },
        update(customerId, employeeId, data) {
            return EawResource.create(url).update({
                customer: customerId,
                employee: employeeId,
            }, pick(data, fields));
        },
        delete(customerId, employeeId) {
            return EawResource.create(url).delete({
                customer: customerId,
                employee: employeeId,
            });
        },
    };
} ]);
