// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog id="handle-event-dialog" class="medium-dialog">
    <eaw-dialog-header-old ng-if="!ctrl.event.key" heading="'calendar:NEW_EVENT' | i18next"></eaw-dialog-header-old>
    <eaw-dialog-header-old ng-if="ctrl.event.key" heading="'calendar:EDIT_EVENT' | i18next"></eaw-dialog-header-old>

    <md-dialog-content>
        <form name="handleEventForm">
            <div class="md-dialog-content">
                <div class="section-icon invisible">
                    <md-icon ng-bind="'person'"></md-icon>
                </div>

                <div class="section">
                    <md-input-container>
                        <label ng-i18next="TITLE"></label>
                        <input ng-model="ctrl.event.title">
                    </md-input-container>
                </div>

                <div class="section-icon">
                    <md-icon class="tw-pt-4" ng-bind="'calendar_month'"></md-icon>
                </div>

                <div class="section">
                    <md-autocomplete
                            class="error-spacer"
                            md-selected-item="ctrl.event.calendar"
                            md-selected-item-change="ctrl.calendarChanged()"
                            md-search-text="calendarFilter"
                            md-min-length="0"
                            md-floating-label="{{'calendar:CALENDAR' | i18next}}"
                            required
                            md-menu-class="autocomplete-custom-template"
                            md-items="calendar in ctrl.getCalendars(calendarFilter)"
                            md-item-text="calendar.name">
                        <md-item-template>
                            <span class="autocomplete-item-title" md-highlight-text="calendarFilter" md-highlight-flags="gi">{{calendar.name}}</span>
                            <span class="autocomplete-item-metadata" md-highlight-text="calendarFilter" md-highlight-flags="gi">{{calendar.description}}</span>
                        </md-item-template>
                        <md-not-found>
                            <span ng-i18next="NO_MATCH_FOUND"></span>
                        </md-not-found>
                    </md-autocomplete>
                </div>

                <div class="section-icon">
                    <md-icon ng-bind="'schedule'"></md-icon>
                </div>

                <div id="time-section" class="section" layout="row" layout-wrap>
                    <md-input-container class="date-picker-container" flex="33">
                        <eaw-date-picker ng-model="ctrl.dateFrom"
                                         ng-change="ctrl.updateStartEnd()"
                                         no-label="true"
                                         no-clear="true">
                        </eaw-date-picker>
                    </md-input-container>

                    <div ng-if="ctrl.event.wholeDay" style="padding: 0 5px; margin-top: 11px;"> —</div>

                    <md-input-container class="date-picker-container" flex="33" ng-show="ctrl.event.wholeDay">
                        <eaw-date-picker ng-model="ctrl.dateTo"
                                         ng-change="ctrl.updateStartEnd()"
                                         no-label="true"
                                         no-clear="true">
                        </eaw-date-picker>
                    </md-input-container>

                    <md-input-container class="time-container" flex="33" ng-show="!ctrl.event.wholeDay">
                        <input name="fromTime"
                               eaw-simple-time
                               ng-change="ctrl.updateStartEnd()"
                               autocomplete="off"
                               aria-label="From"
                               ng-model-options="{updateOn: 'blur'}"
                               ng-model="ctrl.timeFrom">
                    </md-input-container>

                    <div ng-if="!ctrl.event.wholeDay" style="padding: 0 5px; margin-top: 11px;"> —</div>

                    <md-input-container class="time-container" flex="33" ng-show="!ctrl.event.wholeDay">
                        <input name="toTime"
                               eaw-simple-time
                               ng-change="ctrl.updateStartEnd()"
                               autocomplete="off"
                               aria-label="From"
                               ng-model-options="{updateOn: 'blur'}"
                               ng-model="ctrl.timeTo">
                    </md-input-container>

                    <div flex="100">
                        <md-checkbox class="condensed" ng-model="ctrl.event.wholeDay" ng-change="ctrl.updateStartEnd()">
                            <span ng-i18next="calendar:ALL_DAY"></span>
                        </md-checkbox>
                    </div>
                </div>

                <div class="section-icon">
                    <md-icon ng-bind="'notes'"></md-icon>
                </div>

                <div class="section">
                    <md-input-container class="md-block">
                        <label ng-i18next="DESCRIPTION"></label>
                        <textarea ng-model="ctrl.event.description" rows="2"></textarea>
                    </md-input-container>
                </div>

                <div class="section-icon">
                    <!-- No icon here -->
                </div>

                <div id="participants-section" class="section">
                    <eaw-accordion>
                        <eaw-accordion-panel>
                            <panel-header>
                                <div layout="row" layout-align="start center">
                                    <md-icon ng-bind="'group_add'"></md-icon>

                                    <div class="tw-ml-12 access-header">
                                        <div ng-i18next="calendar:GUEST_plural"></div>
                                    </div>
                                </div>
                            </panel-header>
                            <panel-content class="tw-p-0">
                                <md-content class="md-padding">
                                    <md-autocomplete
                                            md-selected-item="ctrl.participant"
                                            md-search-text="participantFilter"
                                            md-selected-item-change="ctrl.addParticipant(ctrl.participant)"
                                            md-min-length="0"
                                            md-delay="300"
                                            md-no-cache="true"
                                            placeholder="{{'calendar:GUEST' | i18next}}"
                                            md-menu-class="autocomplete-custom-template"
                                            md-items="participant in ctrl.getParticipants(participantFilter)"
                                            md-item-text="participant.name">
                                        <md-item-template>
                                            <span class="autocomplete-item-title" md-highlight-text="participantFilter" md-highlight-flags="gi">{{participant.name}}</span>
                                            <span class="autocomplete-item-metadata">{{participant.translatedType}}</span>
                                        </md-item-template>
                                        <md-not-found>
                                            <span ng-i18next="NO_MATCH_FOUND"></span>
                                        </md-not-found>
                                    </md-autocomplete>
                                </md-content>

                                <md-list>
                                    <md-list-item ng-repeat="participant in ctrl.participants track by $index">
                                        <eaw-profile-picture-old user="{id: participant.userId, name: participant.name}" size="25"></eaw-profile-picture-old>

                                        <p ng-bind="participant.name"></p>
                                        <md-icon ng-if="!participant.key" class="hover-item" ng-bind="'remove'" ng-click="ctrl.removeParticipant($index)"></md-icon>
                                        <md-icon ng-if="participant.key" class="hover-item" ng-bind="'delete'" ng-click="ctrl.deleteInvitation(participant, $index)"></md-icon>
                                        <md-divider ng-if="!$last"></md-divider>
                                    </md-list-item>
                                </md-list>
                            </panel-content>
                        </eaw-accordion-panel>
                    </eaw-accordion>
                </div>
            </div>
        </form>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>

        <md-button ng-disabled="handleEventForm.$invalid"
                   form="handleEventForm"
                   ng-if="!ctrl.event.key"
                   ng-click="ctrl.create()">
            <span ng-i18next="CREATE"></span>
        </md-button>

        <md-button ng-disabled="handleEventForm.$invalid"
                   form="handleEventForm"
                   ng-if="ctrl.event.key"
                   ng-click="ctrl.update()">
            <span ng-i18next="UPDATE"></span>
        </md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.calendar2').service('HandleEventDialog', [ '$mdDialog', function HandleEventDialog($mdDialog) {
    const service = this;

    /*
     * @param {object}          args
     * @param {moment}          args.date
     * @param {moment}          args.start
     * @param {moment}          args.end
     * @param {Calendar[]}      args.calendars
     * @param {Calendar2Event}  [args.event]
     * @param {boolean}         [args.wholeDay]
     * @return {Promise}
     */
    service.open = (locals) => $mdDialog.show({
        template: template1,
        controller: 'handleEventDialogCtrl',
        controllerAs: 'ctrl',
        bindToController: true,
        clickOutsideToClose: false,
        locals,
    });

    return service;
} ]);
