import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DataTableButton, DataTableButtonColumn } from '../../data-table/types/data-table-button-column';
import { Role } from '../shared/types/role';
import { DataTableHeader } from '../../data-table/types/data-table-header';
import { DataTableCell } from '../../data-table/interfaces/data-table-cell';
import { CurrentService } from '../../shared/services/current.service';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { Observable, of } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { RoleAssignmentService } from '../shared/http/role-assignment.service';
import { DataTableComponent } from '../../data-table/data-table.component';
import { UserResponse } from '../../shared/models/user';
import { Customer } from '../../shared/models/customer';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { ConfirmDialogService } from '../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { RoleAssignmentDialogService } from '../shared/services/role-assignment-dialog.service';
import { RoleAssignment } from '../shared/types/role-assignment';
import { EawDataTable } from '../../data-table/types/data-table';
import { DataTableColumnType } from '../../data-table/interfaces/data-table-columns';
import { PermissionCheckService } from '../../shared/services/permission-check.service';

@Component({
    selector: 'eaw-role-assignment-data-table',
    templateUrl: './assignment-data-table.component.html',
    styleUrl: './assignment-data-table.component.scss',
    standalone: true,
    imports: [ DataTableComponent ],
})
export class AssignmentDataTableComponent implements OnInit, OnChanges, EawDataTable {
    @Input() columns!: DataTableColumnType<any>[];
    @Input() customer!: Customer;
    @Input() role?: Role;
    @Input() user?: UserResponse;

    @ViewChild('table') dataTable!: DataTableComponent<RoleAssignment>;

    cols: DataTableColumnType<RoleAssignment>[] = [];
    request?: Observable<ArrayPaginatedResponse<RoleAssignment>>;

    constructor(
        @Inject(CurrentService) protected current: CurrentService,
        @Inject(RoleAssignmentService) protected roleAssignmentService: RoleAssignmentService,
        @Inject(MatDialog) protected dialog: MatDialog,
        @Inject(SnackBarService) protected snackBar: SnackBarService,
        @Inject(ConfirmDialogService) protected confirmDialogService: ConfirmDialogService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(RoleAssignmentDialogService) protected roleAssignmentDialogService: RoleAssignmentDialogService,
    ) {}

    ngOnInit() {
        this.updateTable({
            page: 1,
            per_page: 25,
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['columns'] && changes['columns'].currentValue?.length) {
            this.cols = changes['columns'].currentValue.concat([ this.getButtonColumn() ]);
        }

        if (changes['role'] && !changes['role'].isFirstChange()) {
            this.updateTable({ page: 1 });
        }
    }

    updateAssignment(cell: DataTableCell<DataTableButtonColumn<RoleAssignment>, RoleAssignment>) {
        this.roleAssignmentDialogService.update(this.customer, cell.item).subscribe((() => {
            cell.disabled.set(false);
        }));
    }

    deleteAssignment(cell: DataTableCell<DataTableButtonColumn<RoleAssignment>, RoleAssignment>) {
        this.roleAssignmentDialogService.delete(this.customer, cell.item).subscribe(() => {
            this.reload();
        });
    }

    canUpdate(a: RoleAssignment) {
        return this.permissionCheckService.isAllowed(`customers.${this.customer.id}.roles.${a.roleId}.assignments.create`);
    }

    canDelete(a: RoleAssignment) {
        return this.permissionCheckService.isAllowed(`customers.${this.customer.id}.roles.${a.roleId}.assignments.${a.id}.update`);
    }

    protected reload() {
        this.updateTable(this.dataTable.getPagination({ page: 1 }));
    }

    private getButtonColumn() {
        const update: DataTableButton<RoleAssignment> = {
            icon: 'edit',
            click: this.updateAssignment.bind(this),
            show: this.canUpdate.bind(this),
            tooltip: { key: 'EDIT' },
            hide: () => of(true),
        };
        const del: DataTableButton<RoleAssignment> = {
            icon: 'delete',
            click: this.deleteAssignment.bind(this),
            show: this.canDelete.bind(this),
            tooltip: { key: 'DELETE' },
            type: 'warn',
        };

        return new DataTableButtonColumn({
            header: new DataTableHeader({ text: '' }),
            buttons: [
                update,
                del,
            ],
        });
    }

    updateTable(pagination: PaginationOptions) {
        pagination['with[]'] = [ 'user', 'role' ];

        const options = { pagination };

        if (this.user) {
            this.request = this.roleAssignmentService.getAllForUser(this.customer.id, this.user.id, options);
        } else if (this.role) {
            this.request = this.roleAssignmentService.getAllForRole(this.customer.id, this.role.id, options);
        } else {
            throw new Error('User or Role is required');
        }
    }
}
