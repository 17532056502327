import { Component, inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { Absence } from '../../models/absence';
import { AbsenceService, GetAllAbsencesOptions, GetAllCustomerAbsencesOptions } from '../../http/absence.service';
import { CurrentService } from '../../../shared/services/current.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AbsenceType } from '../../models/absence-type';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { AbsenceTypeService } from '../../http/absence-type.service';
import { DataTableApprovalColumn } from '../../../data-table/types/data-table-approval-column';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { SettingService } from '../../../shared/http/setting.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { UIRouter } from '@uirouter/core';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { AbsenceStatsDialogComponent, AbsenceStatsDialogData } from '../../dialogs/absence-stats-dialog/absence-stats-dialog.component';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { MatDialog } from '@angular/material/dialog';
import { PercentPipe } from '../../../shared/pipes/percent.pipe';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { catchError, map, of, switchMap } from 'rxjs';
import { CustomerService } from '../../../shared/http/customer.service';
import { mockCustomer } from '../../../../mocks/customer.mock';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { CommentDialogComponent, CommentDialogData } from '../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { DurationUnit } from 'luxon/src/duration';

interface AbsenceListMode {
    id: 'unprocessed' | 'processed' | 'approved' | 'declined' | 'all',
    label: string,
    handled: 1 | 0 | null,
    approved: 1 | 0 | null,
}

type FilterForm = {
    mode: FormControl<AbsenceListMode>,
    dateRange: FormGroup<{ from: FormControl<DateTime | null>, to: FormControl<DateTime | null> }>,
    absenceTypes: FormControl<number[]>
};

@Component({
    selector: 'eaw-employee-absences',
    templateUrl: './employee-absences.component.html',
    styleUrl: './employee-absences.component.scss',
    providers: [ PercentPipe, DurationPipe ],
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatDatepickerModule,
        MatButtonModule,
        MatCardModule,
        AsyncPipe,
        PageHeaderComponent,
        TranslatePipe,
        DatePickerOptionsDirective,
        DataTableComponent,
        TranslateSyncPipe,
        KeyValuePipe,
    ],
})

export class EmployeeAbsencesComponent implements OnInit {
    private absenceService = inject(AbsenceService);
    private current = inject(CurrentService);
    private translate = inject(TranslateService);
    private searchParams = inject(QueryParamsService);
    private absenceTypeService = inject(AbsenceTypeService);
    private confirmDialogService = inject(ConfirmDialogService);
    private settingService = inject(SettingService);
    private uiRouter = inject(UIRouter);
    private matDialog = inject(MatDialog);
    private percentPipe = inject(PercentPipe);
    private customerService = inject(CustomerService);
    private durationPipe = inject(DurationPipe);
    private permissionCheckService = inject(PermissionCheckService);

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) employeeId!: number;

    @ViewChild(DataTableComponent) dataTable?: DataTableComponent<Absence>;

    protected pagination?: Partial<DataTablePagination>;
    request: DataTableRequest = of(mockArrayPaginatedResponse());
    headerButtons: PageHeaderButton[];

    columns: DataTableColumnType<Absence>[];

    groupAbsenceTypes: AbsenceType[] = [];
    modes: Record<AbsenceListMode['id'], AbsenceListMode>;

    formGroup: FormGroup<FilterForm>;

    get mode() {
        return this.formGroup.controls.mode;
    }

    get absenceTypes() {
        return this.formGroup.controls.absenceTypes;
    }

    private canDeleteApproved = false;

    constructor() {
        this.columns = [
            new DataTableTextColumn({
                value: (cell) => this.translate.t(cell.item.type?.name || '', 'absence_types'),
                header: new DataTableHeader({ i18n: 'TYPE' }),
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({ i18n: 'FROM' }),
                value: 'from',
                format: DateTime.DATETIME_MED,
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({ i18n: 'TO' }),
                value: 'to',
                format: DateTime.DATETIME_MED,
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({ i18n: 'ABSENCE_DIFF_LENGTH', ns: 'absences' }),
                value: (cell) => {
                    const daySpan = cell.item.type?.span === 'day';
                    const units: DurationUnit[] = daySpan ? [ 'days' ] : [ 'hours', 'minutes' ];
                    return this.durationPipe.transform(cell.item.to?.plus({ second: Number(daySpan) }).diff(cell.item.from, units) , units);
                },
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({
                    i18n: 'GRADE',
                    ns: 'absences',
                }),
                value: (cell) => this.percentPipe.transform(cell.item.grade),
            }),
            new DataTableApprovalColumn({
                header: new DataTableHeader({ i18n: 'APPROVED' }),
                approval: (cell) => cell.item.approval,
                includeTime: false,
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({ i18n: 'ABSENCE_LENGTH', ns: 'absences' }),
                value: (cell) => this.durationPipe.transform(cell.item.length + Number(cell.item.type?.span === 'day'), [ 'hours', 'minutes' ]),
            }),
            new DataTableButtonColumn<Absence>({
                buttons: [
                    {
                        icon: 'comment-outline',
                        click: (cell) => {
                            cell.disabled.set(false);
                            this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
                                data: {
                                    comments: of(cell.item.comments || []),
                                },
                            });
                        },
                        show: (absence) => of(!!absence.comments?.length),
                        tooltip: { key: 'COMMENT_plural' },
                    },
                    {
                        icon: 'edit',
                        click: (cell) => this.uiRouter.stateService.transitionTo('eaw/app/absence/list/tabs/edit', {
                            isMine: true,
                            employee_id: this.employeeId,
                            id: cell.item.id,
                        }),
                        show: (absence) => {
                            return this.permissionCheckService.isAllowed(`customers.${this.current.getCustomer().id}.employees.${this.employeeId}.absences.${absence.id}.update`).pipe(
                                map((canDelete) => canDelete && (this.canDeleteApproved || !absence.approval)),
                            );
                        },
                        tooltip: { key: 'WARNING_plural' },
                    },
                    {
                        icon: 'delete',
                        click: this.deleteAbsence.bind(this),
                        type: 'warn',
                        show: (absence) => {
                            return this.permissionCheckService.isAllowed(`customers.${this.current.getCustomer().id}.employees.${this.employeeId}.absences.${absence.id}.delete`).pipe(
                                map((canDelete) => canDelete && (this.canDeleteApproved || !absence.approval)),
                            );
                        },
                        tooltip: { key: 'DELETE' },
                    },
                ],
            }),
        ];

        this.modes = {
            unprocessed: {
                id: 'unprocessed',
                label: 'UNPROCESSED',
                handled: 0,
                approved: null,
            },
            processed: {
                id: 'processed',
                label: 'PROCESSED',
                handled: 1,
                approved: null,
            },
            approved: {
                id: 'approved',
                label: 'APPROVED',
                handled: 1,
                approved: 1,
            },
            declined: {
                id: 'declined',
                label: 'DECLINED',
                handled: 1,
                approved: 0,
            },
            all: {
                id: 'all',
                label: 'ALL',
                handled: null,
                approved: null,
            },
        };

        this.formGroup = new FormGroup<FilterForm>({
            mode: new FormControl<AbsenceListMode>(this.modes.all, { nonNullable: true }),
            dateRange: new FormGroup({
                from: new FormControl<DateTime | null>(null),
                to: new FormControl<DateTime | null>(null),
            }),
            absenceTypes: new FormControl<number[]>([], { nonNullable: true }),
        });
        this.headerButtons = [
            new PageHeaderButton({
                icon: 'trending_up',
                click: () => this.showStats(),
                menuText: signal(this.translate.t('STATISTICS', 'navigation')),
            }),
        ];
    }

    ngOnInit(): void {
        this.formGroup.disable();

        this.customerService.get(this.customerId).pipe(
            catchError(() => of(mockCustomer({ id: 0 }))), // Return mock with id 0 if customer is not found
            switchMap((customer) => {
                // If we get 0 as id, we know something went wrong, and we should not try to get absence types
                return customer.id === 0 ? [] : expandAllPages((pagination) => this.absenceTypeService.getAll('customers', customer.id, {
                    ...pagination,
                }), { per_page: 100 }).pipe(
                    catchError(() => of([] as AbsenceType[])),
                );
            }),
        ).subscribe((absenceTypes) => {
            this.groupAbsenceTypes = sort(absenceTypes, this.current.languageTag, [ (x) => x.name ]);

            this.formGroup.setValue({
                absenceTypes: [],
                dateRange: {
                    from: this.searchParams.get('from', 'date') || null,
                    to: this.searchParams.get('to', 'date') || null,
                },
                mode: this.modes[this.searchParams.get<AbsenceListMode['id']>('mode', 'string') || 'all'],
            });

            this.formGroup.enable();
        });

        this.settingService.getSome([ 'customers', this.current.getCustomer().id ], {
            'settings[]': [
                'absences.allow_employee_to_delete_approved',
                'absence.approval_deadline.is_enabled',
            ],
        }).subscribe(([ useDeadline, allowDeleting ]) => {
            this.canDeleteApproved = allowDeleting?.resolvedValue?.asBoolean() ?? false;
            if (useDeadline?.resolvedValue?.asBoolean()) {
                this.columns.splice(6, 0, new DataTableDateTimeColumn({
                    header: new DataTableHeader({ i18n: 'APPROVAL_DEADLINE', ns: 'absences' }),
                    value: 'approvalDeadline',
                }));
                this.columns = [ ...this.columns ];
            }
        });
    }

    getHandledApproved() {
        const mode = this.mode.value;
        const options: Partial<GetAllAbsencesOptions> = {};

        if (mode.handled != null) {
            options.handled = !!mode.handled;
        }

        if (mode.approved != null) {
            options.approved = !!mode.approved;
        }

        return options;
    }

    updateTable($event: Partial<DataTablePagination>) {
        const dateRange = this.formGroup.controls.dateRange;
        const from = dateRange.controls.from.value?.startOf('day');
        const to = dateRange.controls.to.value?.endOf('day');

        const options: GetAllCustomerAbsencesOptions = {
            per_page: 25,
            order_by: 'from',
            direction: 'desc',
            ...$event,
            from,
            to,
            'with[]': [ 'employee', 'type', 'approval', 'comments', 'warnings' ],
            'type_ids[]': this.absenceTypes.value,
            ...this.getHandledApproved(),
        };

        this.request = this.absenceService.getAllForEmployee(this.customerId, this.employeeId, options);
    }

    modeComparer(item1: AbsenceListMode, item2: AbsenceListMode): boolean {
        return item1.id === item2.id;
    }

    update() {
        this.dataTable?.refresh();
        this.formGroup.markAsPristine();
    }

    reset() {
        this.formGroup.reset({
            mode: this.modes.all,
            absenceTypes: [],
        });

        this.update();
    }

    deleteAbsence(cell: DataTableCell<DataTableButtonColumn<Absence>, Absence>) {
        this.confirmDialogService.open({
            title: this.translate.t('DELETE_ABSENCE', 'absences'),
            text: this.translate.t('CONFIRM_DELETE', 'absences'),
        }).afterClosed().subscribe((response) => {
            if (!(response?.ok)) {
                cell.disabled.set(false);
                return;
            }

            this.absenceService.delete(cell.item.customerId, cell.item.employeeId, cell.item.id, response.comment).subscribe(() => {
                this.updateTable({});
            });
        });
    }

    showStats() {
        this.matDialog.open<AbsenceStatsDialogComponent, AbsenceStatsDialogData>(AbsenceStatsDialogComponent, {
            data: {
                size: DialogSize.Small,
                customerId: this.customerId,
                employeeId: this.employeeId,
            },
        });
    }
}
