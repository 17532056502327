import { Inject, Injectable } from '@angular/core';
import { StateObject, UIRouter } from '@uirouter/core';
import { hasLoadedNamespace, loadNamespaces } from 'i18next';

@Injectable({
    providedIn: 'root',
})
export class LoadNamespacesHookService {

    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
        this.uiRouter.transitionService.onBefore({
            to(state) {
                return !!state?.data?.i18nextNs;
            },
        }, (transition) => {
            console.debug('🪝', 'LoadNamespacesHookService.onBefore', transition);

            return loadNamespaces(this.getModules(new Set(), transition.$to()));
        });
    }

    private getModules(set: Set<string>, state: StateObject): string[] {
        (state.data?.i18nextNs as string[] | undefined)?.forEach((ns) => {
            if (hasLoadedNamespace(ns)) {
                return;
            }

            set.add(ns);
        });

        return state.parent != null ? this.getModules(set, state.parent) : Array.from(set);
    }
}
