// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

const template1 = `<md-dialog>
    <form role="form" autocomplete="off" name="newPayTypeForm" ng-submit="ctrl.add()">
        <eaw-dialog-header-old heading="'Add pay type'"></eaw-dialog-header-old>
        <md-dialog-content>
            <div class="md-dialog-content">
                <md-input-container>
                    <label ng-i18next="ID"></label>
                    <input ng-model="ctrl.newPayType.id" type="number" step="1" required>
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="NAME"></label>
                    <input ng-model="ctrl.newPayType.name" ng-maxlength="50" required>
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button type="submit" ng-i18next="SUBMIT" ng-disabled="newPayTypeForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.admin').controller('payTypeCtrl', [ 'PayTypeFactory', 'deleteItemAlert', '$mdDialog', 'ToastService', function(PayTypeFactory, deleteItemAlert, $mdDialog, ToastService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.table = {
            columns: [
                {
                    title: t('ID'),
                    sortable: 'id',
                },
                {
                    title: t('NAME'),
                    sortable: 'name',
                },
                {
                    title: t('admin:CALCULATOR_plural'),
                    class: 'text-right',
                    sortable: 'calculators_count',
                },
                { title: '' },
            ],
            cells: [
                { template: 'item.id' },
                { template: 'item.name' },
                {
                    template: 'item.calculators_count | eawNumber',
                    class: 'text-right',
                },
                {
                    buttons: [
                        {
                            icon: 'delete',
                            type: 'warn',
                            click: ctrl.deletePayType,
                            invisible: (payType) => payType.calculators_count > 0,
                        },
                    ],
                },
            ],
        };
    };

    ctrl.addPayType = () => {
        $mdDialog.show({
            template: template1,
            controllerAs: 'ctrl',
            controller: function() {
                const dctrl = this;

                dctrl.$onInit = () => {
                    dctrl.cancel = $mdDialog.cancel;
                    dctrl.newPayType = {};
                };

                dctrl.add = () => {
                    PayTypeFactory.create(dctrl.newPayType).$promise.then(() => {
                        ToastService.toast(t('CREATED'));
                        ctrl.reloadTable = true;
                        $mdDialog.hide();
                    });
                };
            },
        });
    };

    ctrl.deletePayType = (payType) => {
        deleteItemAlert.alertSimple('DELETE').then(() => {
            PayTypeFactory.delete(payType).$promise.then(() => {
                ToastService.toast(t('DELETED'));
                ctrl.reloadTable = true;
            });
        });
    };
} ]);
