import { PeopleKpiRowField } from './people-kpi-row-field';
import { PeopleKpiResponseRow } from '../types';

export class PeopleKpiRow {
    fields: PeopleKpiRowField[];
    name: PeopleKpiResponseRow['name'];

    constructor(row: PeopleKpiResponseRow) {
        this.fields = row.fields.map((f) => new PeopleKpiRowField(f));
        this.name = row.name;
    }
}
