// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

const template1 = `<table class="warnings-list-table table table-striped sticky-header">
    <thead ng-if="$warnList.showHead">
    <tr>
        <th data-show="{{$warnList.show.infobtn}}"></th>
        <th data-show="{{$warnList.show.date}}" ng-i18next="DATE"></th>
        <th data-show="{{$warnList.show.employee}}" ng-i18next="EMPLOYEE"></th>
        <th data-show="{{$warnList.show.message}}" ng-i18next="MESSAGE"></th>
    </tr>
    </thead>

    <tbody>
    <tr ng-repeat="warning in $warnList.warnings track by warning.id">
        <td data-show="{{$warnList.show.infobtn}}">
            <eaw-icon-button
                    ng-click="warning.openExtendedInfo($event)"
                    icon="info"
                    stop-propagate
                    tooltip="$warnList.informationTooltip">
            </eaw-icon-button>
        </td>
        <td data-show="{{$warnList.show.date}}" ng-bind="warning.business_date | moment:'ll'"></td>
        <td data-show="{{$warnList.show.employee}}" ng-bind="warning.object.employee.name"></td>
        <td data-show="{{$warnList.show.message}}" ng-bind="warning.translatedMessage"></td>
    </tr>
    </tbody>
</table>
`;

module('eaw.warnings').component('warningsList', {
    bindings: {
        warnings: '<',
        fields: '<?', // [infobtn, date, employee, message]
        showHead: '<?',
    },
    controllerAs: '$warnList',
    template: template1,
    controller: [ 'Warning', function(Warning) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.hasFields = ctrl.fields?.length;
            ctrl.showHead = ctrl.showHead ?? true;
            ctrl.informationTooltip = t('INFORMATION');

            ctrl.show = [ 'infobtn', 'date', 'employee', 'message' ].reduce((obj, item) => {
                obj[item] = ctrl.hasFields ? ctrl.fields.includes(item) : true;
                return obj;
            }, {});
        };

        ctrl.$onChanges = () => {
            ctrl.warnings = ctrl.warnings?.map((w) => w instanceof Warning ? w : new Warning(w)) || [];
        };
    } ],
});
