<eaw-dialog-header>
    <span title>
        {{ 'CONNECT_USER' | translate: 'company' | async }}
    </span>
</eaw-dialog-header>
<mat-dialog-content>
    <eaw-info-box color="yellow" icon="warning" class="tw-mb-16">
        <span infoText>{{ 'CONNECT_EMPLOYEE_USER_WARNING' | translate: 'company' | async }}</span>
    </eaw-info-box>
    <form [formGroup]="form" id="switchForm">
        <eaw-customer-user-autocomplete controlName="user" [customerId]="employee.customerId"></eaw-customer-user-autocomplete>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button type="submit" formId="switchForm" [loading]="loading" [disabled]="form.invalid" (click)="save()">
        {{ 'CONNECT' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
