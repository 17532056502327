import { Customer, CustomerResponse } from './customer';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';

export interface CustomerGroupMemberResponse extends CustomerResponse {
    pivot: {
        from: string;
        group_id: number;
        member_id: number;
        to: string | null;
    }
}

export class CustomerGroupMember extends Customer {
    pivot: {
        from: DateTime;
        group_id: number;
        member_id: number;
        to: DateTime | null;
    };

    constructor(data: CustomerGroupMemberResponse) {
        super(data);

        this.pivot = {
            from: stringToDateTime(data.pivot.from),
            group_id: data.pivot.group_id,
            member_id: data.pivot.member_id,
            to: data.pivot.to ? stringToDateTime(data.pivot.to) : null,
        };
    }
}
