import { HttpClient } from '@angular/common/http';
import { EawUrl } from '../../shared/angularjs/modules/resource/url.service';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { EmergencyContact, EmergencyContactResponse } from '../models/emergency-contact';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { CustomFieldKeyValues } from '../../shared/utils/custom-fields-group';

@Injectable({
    providedIn: 'any',
})
export class EmergencyContactService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, pagination?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<EmergencyContactResponse>>(EawUrl.getUrl(`/customers/${customerId}/emergency_contacts`), {
            params: formatHttpParams(pagination),
        }).pipe(classifyArrayPaginatedResponse(EmergencyContact));
    }

    get(customerId: number, employeeId: number, relations?: string[]) {
        return this.http.get<EmergencyContactResponse | object>(EawUrl.getUrl(`/customers/${customerId}/employees/${employeeId}/emergency_contact`), {
            params: formatHttpParams({ 'with[]': relations }),
        }).pipe(
            map((response) => {
                return 'id' in response ? new EmergencyContact(response) : null;
            }),
        );
    }

    create(customerId: number, employeeId: number, name: string, phone: string, relation?: string | null, customFields?: CustomFieldKeyValues) {
        return this.http.post<EmergencyContactResponse>(EawUrl.getUrl(`/customers/${customerId}/employees/${employeeId}/emergency_contact`), {
            name,
            phone,
            relation,
            ...customFields,
        }).pipe(classifyItem(EmergencyContact));
    }

    update(customerId: number, employeeId: number, name: string, phone: string, relation?: string | null, customFields?: CustomFieldKeyValues) {
        return this.http.put<EmergencyContactResponse>(EawUrl.getUrl(`/customers/${customerId}/employees/${employeeId}/emergency_contact`), {
            name,
            phone,
            relation,
            ...customFields,
        }).pipe(classifyItem(EmergencyContact));
    }

    delete(customerId: number, employeeId: number) {
        return this.http.delete<undefined>(EawUrl.getUrl(`/customers/${customerId}/employees/${employeeId}/emergency_contact`));
    }
}
