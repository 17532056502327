import { PeopleKpiResponseHeader } from '../types';

export class PeopleKpiHeader {
    key: string;
    name: string | {ns: 'general' | 'people_kpi', key: string, data: unknown[]};
    prefix: string;
    suffix: '%' | '';

    constructor(header: PeopleKpiResponseHeader) {
        this.key = header.key;
        this.prefix = header.prefix;
        this.suffix = header.suffix;
        this.name = header.name;
    }
}
