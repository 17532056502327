import { Component, Inject } from '@angular/core';
import { DialogComponent } from '../../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CreateScheduleTemplateDialogData } from '../create-template-dialog.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ScheduleTemplateService } from '../../../http/schedule-template.service';
import { DateTime } from 'luxon';
import { TranslateService } from '../../../../shared/services/translate.service';
import { ScheduleTemplate } from '../../../models/schedule-template';
import { catchError, EMPTY, switchMap } from 'rxjs';
import { SettingService } from '../../../../shared/http/setting.service';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { AsyncPipe, NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslateSyncPipe } from '../../../../shared/pipes/translate-sync.pipe';

@Component({
    selector: 'eaw-default-template-create',
    templateUrl: './default-template-create.component.html',
    styleUrl: './default-template-create.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class DefaultTemplateCreateComponent extends DialogComponent {
    startDays: { weekday: string, date: DateTime }[] = [];
    formGroup = new FormGroup({
        name: new FormControl<string | undefined>(undefined, Validators.required),
        startDay: new FormControl<DateTime | undefined>(undefined, Validators.required),
        duration: new FormControl<number | undefined>(undefined, Validators.required),
        type: new FormControl<'days' | 'weeks'>('days'),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateScheduleTemplateDialogData,
        @Inject(ScheduleTemplateService) public scheduleTemplateService: ScheduleTemplateService,
        @Inject(TranslateService) public translate: TranslateService,
        @Inject(SettingService) public settingService: SettingService,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<DefaultTemplateCreateComponent, ScheduleTemplate>,
    ) {
        super(dialogRef, data);

        for (let i = 0; i < 7; i++) {
            const day = DateTime.now().minus({ week: 1 }).startOf('week').plus({ day: i });
            this.startDays.push({
                weekday: day.weekdayLong || '',
                date: day,
            });
        }
    }

    create() {
        const duration = this.formGroup.get('duration')?.value;
        if (duration == null) {
            return;
        }

        this.settingService.getValue([ 'customers', this.data.customerId ], 'schedule:hours_offset', 0)
            .pipe(
                switchMap((offset) => {
                    return this.scheduleTemplateService.createDefaultTemplate(this.data.customerId, {
                        from: (this.formGroup.get('startDay')?.value as DateTime).set({ hour: offset }),
                        name: this.formGroup.get('name')?.value as string,
                        length: this.formGroup.get('type')?.value === 'days' ? duration * 86_400 : duration * 86_400 * 7,
                    });
                }),
                catchError((err) => {
                    console.error(err);
                    this.formGroup.enable();
                    return EMPTY;
                }),
            )
            .subscribe((result) => {
                this.dialogRef.close(result);
            });

        this.formGroup.disable();
    }
}
