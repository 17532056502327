import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ShiftConfiguration, ShiftConfigurationResponse, ShiftConfigurationWeekdays } from '../models/shift-configuration';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';

interface CreateUpdateBody {
    name: string;
    hour_start: number;
    hour_end: number;
    minute_start: number;
    minute_end: number;
    weekdays: ShiftConfigurationWeekdays;
}

@Injectable({
    providedIn: 'root',
})
export class ShiftConfigurationsService {
    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {
    }

    getAll(customerId: number): Observable<ShiftConfiguration[]> {
        return this.http.get<ShiftConfigurationResponse[]>(`/customers/${customerId}/shift_configurations`).pipe(
            map((response) => {
                return response.map((r) => new ShiftConfiguration(r));
            }),
        );
    }

    create(customerId: number, body: CreateUpdateBody): Observable<ShiftConfiguration> {
        return this.http.post<ShiftConfigurationResponse>(`/customers/${customerId}/shift_configurations`, body)
            .pipe(classifyItem(ShiftConfiguration));
    }

    update(customerId: number, configId: string, body: CreateUpdateBody): Observable<ShiftConfiguration> {
        return this.http.put<ShiftConfigurationResponse>(`/customers/${customerId}/shift_configurations/${configId}`, body)
            .pipe(classifyItem(ShiftConfiguration));
    }

    delete(customerId: number, configId: string) {
        return this.http.delete<ShiftConfigurationResponse>(`/customers/${customerId}/shift_configurations/${configId}`);
    }
}
