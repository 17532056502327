import { uniqueId } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import moment from 'moment-timezone';
import { module } from 'angular';
import { Storage } from '../../../../shared/utils/storage';
import { Products } from '../../../../shared/enums/products';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
import { ArrayPaginatedResponse } from '../../../../shared/interfaces/paginated-response';
import { EawResourceFactory } from '../../../../shared/angularjs/modules/resource/resource.service';

module('eaw.dashboard').component('shiftsWidget', {
    template: `<my-shift-calendar ng-if="$ctrl.loaded && $ctrl.mode == 'calendar'"></my-shift-calendar>
<my-shift-list ng-if="$ctrl.loaded && $ctrl.mode == 'list'"></my-shift-list>

`,
    require: {
        eawWidget: '^eawWidget',
    },
    controller: [ 'Absence', 'ShiftFactory', 'EawResource', function(Absence, ShiftFactory, EawResource: EawResourceFactory) {
        // @ts-ignore
        const ctrl = this;
        ctrl.$onInit = async () => {
            const calendarBusinessDateSettingKey = 'my_shifts_widget.calendar_use_business_date';
            let businessDateSetting: Record<string, any>;
            try {
                businessDateSetting = await EawResource.create(`/customers/${ctrl.eawWidget.widget.employee.customer_id}/settings/${calendarBusinessDateSettingKey}`).get().$promise;
                ctrl.displayBusinessDateInCalendar = businessDateSetting[calendarBusinessDateSettingKey] == '1';
            } catch (e) {
                console.error('e', e);
                ctrl.displayBusinessDateInCalendar = false;
            }

            const listFromTimeSettingKey = 'my_shifts_widget.list_use_from_time';
            let listFromTimeSetting: Record<string, any>;
            try {
                listFromTimeSetting = await EawResource.create(`/customers/${ctrl.eawWidget.widget.employee.customer_id}/settings/${listFromTimeSettingKey}`).get().$promise;
                ctrl.displayFromInList = listFromTimeSetting[listFromTimeSettingKey] == '1';
            } catch (e) {
                console.error('e', e);
                ctrl.displayFromInList = false;
            }

            ctrl.lsKey = Storage.prefix('my_shifts_widget_mode');
            ctrl.from = moment().startOf('d');
            ctrl.to = moment().add(1, 'M').endOf('M');
            ctrl.minDate = ctrl.from.toDate(); // To date for use in calendar
            ctrl.maxDate = ctrl.to.toDate(); // To date for use in calendar
            ctrl.createButtons();
            ctrl.changeMode(await Storage.getItem(ctrl.lsKey));
            ctrl.getData();
        };
        ctrl.$onDestroy = () => {
            ctrl.shiftsRes?.$cancelRequest?.();
            ctrl.absRes?.$cancelRequest?.();
        };
        ctrl.createButtons = () => {
            ctrl.eawWidget.setButtons([
                {
                    id: 'list',
                    icon: 'view_list',
                    click: () => ctrl.changeMode('list'),
                },
                {
                    id: 'calendar',
                    icon: 'calendar_month',
                    click: () => ctrl.changeMode('calendar'),
                },
            ]);
        };
        ctrl.changeMode = (mode: string) => {
            if (!(mode === 'list' || mode === 'calendar')) {
                mode = 'calendar';
            }

            ctrl.mode = mode;
            ctrl.eawWidget.updateButton(mode, {
                disabled: true,
            });
            ctrl.eawWidget.updateButton(mode === 'list' ? 'calendar' : 'list', {
                disabled: false,
            });
            void Storage.setItem(ctrl.lsKey, mode);
        };
        ctrl.getData = () => {
            ctrl.eawWidget.setLoading(true);
            return Promise.all([
                ctrl.getShifts(),
                ctrl.getAbsences(),
            ]).then((resp) => {
                ctrl.shifts = resp[0] || [];
                ctrl.approvedAbsences = resp?.[1]?.approved || [];
                ctrl.unapprovedAbsences = resp?.[1]?.unapproved || [];
            }).finally(() => {
                ctrl.eawWidget.setLoading(false);
                ctrl.loaded = true;
            });
        };
        ctrl.getAbsences = () => {
            const customerId = ctrl.eawWidget.widget.employee.customer_id;
            const employeeId = ctrl.eawWidget.widget.employee.id;
            if (!ctrl.eawWidget.customer.hasProduct(Products.Absence) || !CurrentOld.can(`customers.${customerId}.employees.${employeeId}.absences.*.get`)) {
                return Promise.resolve();
            }
            ctrl.absRes = Absence.getForEmployee({
                customerId,
                employeeId,
                with: [ 'type', 'approval', 'comments' ],
                from: ctrl.from,
                to: ctrl.to,
                per_page: 100,
            });
            return ctrl.absRes.$promise.then((resp: any) => {
                const absences: any[] = [];
                // Loop through absences and add for each day
                resp.data.forEach((absence: any) => {
                    const to = absence.to.clone().add(1, 'd'); // Add 1 day in order to negate the while NOT SAME, cause we want final same day
                    const originalFrom = absence.from.clone();
                    while (!to.isSame(absence.from, 'd')) {
                        absences.push({
                            ...absence,
                            from: absence.from.clone(),
                            _originalFrom: originalFrom,
                            _myShiftsId: uniqueId(),
                        });
                        absence.from.add(1, 'd');
                    }
                });
                return {
                    approved: absences.filter((a) => !!a.approval?.approved),
                    unapproved: absences.filter((a) => !a.approval?.approved),
                };
            });
        };
        ctrl.getShifts = () => {
            ctrl.shiftsRes = ShiftFactory.getAll({
                customer_id: ctrl.eawWidget.widget.customer,
                employee: ctrl.eawWidget.widget.employee,
                from: ctrl.from,
                to: ctrl.to,
                with: [ 'employee', 'schedule.customer', 'periods.businessUnit', 'comments' ],
                per_page: 250,
            });
            return ctrl.shiftsRes.$promise.then((resp: ArrayPaginatedResponse<any>) => resp.data);
        };
    } ],
});
