import { DateTime } from 'luxon';

type AcceptedTypes = string | number | boolean | null | undefined;
type KeyInOriginal = string;

export class ObjectDifferenceExtractor {
    private readonly original: Record<string, AcceptedTypes> = {};

    constructor(value?: Record<string, unknown>) {
        if (value) {
            this.setOriginal(value);
        }
    }

    setOriginal(value: Record<string, unknown>) {
        Object.entries(value).forEach(([ key, value ]) => {
            if (!this.isValid(key, value)) {
                return;
            }
            this.original[key] = this.getValue(value);
        });

        Object.freeze(this.original);
    }

    isChanged(key: KeyInOriginal, comparison: unknown): boolean {
        if (!this.isValid(key, comparison)) {
            return false;
        }

        return this.getValue(comparison) !== this.original[key];
    }

    hasChanges<T extends Record<string, any>, U extends Record<string, string>>(comparison: T, mappings?: U) {
        return !!Object.values(this.getChanged(comparison, mappings)).length;
    }

    /**
     * @param comparison - Object to check against the original
     * @param mappings - A map of keys that differ from the original so that you can compare them
     */
    getChanged<T extends Record<string, any>, U extends Record<string, string>>(comparison: T, mappings?: U): Partial<T> {
        const changed = {} as T;

        // Keys we want to get values for
        const valueKeys = {
            ...Object.keys(comparison).reduce((obj: Record<string, string>, key) => {
                obj[key] = key;
                return obj;
            }, {}),
            ...mappings,
        } as Record<keyof T | keyof U, string>;

        Object.entries(comparison).forEach(([ key, comparisonValue ]) => {
            const valueKey = valueKeys[key];
            const original = valueKey ? this.original[valueKey] : undefined;

            if (!this.isValid(key, comparisonValue)) {
                console.warn(`Can't compare because value is not valid`, key, comparisonValue);
                return;
            }

            if (this.getValue(comparisonValue) !== original) {
                changed[key as keyof T] = comparisonValue;
            }
        });

        return changed;
    }

    private getValue(value: unknown): AcceptedTypes {
        if (value instanceof DateTime) {
            return value.toMillis();
        }
        if (value === null) {
            return '';
        }

        return value as AcceptedTypes;
    }

    private isValid(key: string, value: unknown) {
        if (Array.isArray(value)) {
            console.warn('Please handle your array', key, value);
            return false;
        }

        if (value instanceof DateTime) {
            return value.isValid;
        }

        if (value == null) {
            return true;
        }

        if (typeof value === 'object') {
            console.warn('Please handle your object', key, value);
            return false;
        }

        if (typeof value === 'function') {
            console.warn('Please handle your function', key, value);
            return false;
        }

        return true;
    }
}
