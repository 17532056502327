import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { WidgetComponent } from '../../classes/widget-component';
import { ShiftService } from '../../../scheduling/http/shift.service';
import { map, tap } from 'rxjs';
import { CurrentService } from '../../../shared/services/current.service';
import { DateTime } from 'luxon';
import { AsyncPipe } from '@angular/common';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { Widget } from '../../classes/widget';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';

@Component({
    selector: 'eaw-mini-next-shift-widget',
    standalone: true,
    imports: [ MiniWidgetContentComponent, AsyncPipe, TranslatePipe, DateTimePipe ],
    templateUrl: './mini-next-shift-widget.component.html',
    styleUrl: './mini-next-shift-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniNextShiftWidgetComponent extends WidgetComponent {
    private shiftService = inject(ShiftService);
    private current = inject(CurrentService);

    widget = input.required<Widget>();

    shifts = computed(() => {
        return this.shiftService.getAll(this.current.getCustomer().id, {
            direction: 'asc' as SortDirection,
            from: DateTime.now().startOf('hour'),
            order_by: 'from',
            per_page: 2,
            employee_id: this.widget().info.employee?.id,
        }).pipe(
            map((response) => response.data),
            tap(() => this.setLoading(false)),
        );
    });
}
