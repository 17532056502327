@if (rootNode) {
    <div class="tw-flex tw-flex-row tw-items-center">
        <mat-card [class.root]="!rootNode.parent" [class.permission-set]="rootNode.parent">
            <button mat-icon-button (click)="expand()" class="tw-row-span-full">
                <mat-icon>
                    {{ rootNode.expanded ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
            </button>
            <div class="tw-col-start-2 tw-row-start-1 tw-col-span-2">{{ rootNode.value.name }}</div>
            <div class="tw-col-start-4 tw-row-start-1 tw-col-span-2">{{ rootNode.value.productName }}</div>
            <div class="tw-col-start-2 tw-row-start-2 tw-col-span-3">{{ rootNode.value.description }}</div>
            <div class="tw-row-span-2 tw-justify-items-end tw-mr-16 tw-grid tw-grid-cols-2">
                @if (rootNode.count !== undefined) {
                    <button mat-mini-fab
                            class="parent-count-button"
                            matTooltip="{{ 'NUMBER_OF_PARENTS' | translate: 'permissions': {count: rootNode.count} | async }}"
                            color="default">
                        {{ rootNode.count }}
                    </button>
                } @else if (rootNode.loadingCount) {
                    <button mat-mini-fab color="default">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </button>
                } @else {
                    <button mat-mini-fab
                            color="default"
                            matTooltip="{{ 'LOAD_PARENT_COUNT' | translate: 'permissions' | async }}"
                            (click)="loadData()">
                        <mat-icon>info</mat-icon>
                    </button>
                }

                @if ((canAddPermission | async) || (canAddPermissionSet | async)) {
                    <button mat-mini-fab
                            [matMenuTriggerFor]="menu"
                            color="accent"
                            matTooltip="{{ 'ADD' | translate | async }}">
                        <mat-icon>add</mat-icon>
                    </button>
                }
            </div>
            <mat-menu #menu="matMenu">
                @if (canAddPermission | async) {
                    <button mat-menu-item (click)="addPermission()">
                        {{ 'ADD_PERMISSION' | translate: 'admin' | async }}
                    </button>
                }

                @if (canAddPermissionSet | async) {
                    <button mat-menu-item
                            (click)="addPermissionSet()">
                        {{ 'ADD_PERMISSION_SET' | translate: 'admin' | async }}
                    </button>
                }
            </mat-menu>
        </mat-card>
        @if (rootNode.parent && (canDetachFromParent | async)) {
            <button mat-icon-button color="warn"
                    (click)="removeFromParent()"
                    [disabled]="removing"
                    matTooltip="{{ 'REMOVE_FROM_SET' | translate: 'permissions' | async }}">
                <mat-icon>do_not_disturb_on</mat-icon>
            </button>
        } @else if (deletePermissionSet && rootNode) {
            <button mat-icon-button color="warn"
                    (click)="deleteSet()"
                    matTooltip="{{ 'DETACH_PERMISSION_SET' | translate: 'permissions' | async }}">
                <mat-icon>delete</mat-icon>
            </button>
        }
    </div>
}
@if (rootNode?.expanded) {
    <div class="tree-node" [ngStyle]="{ '--height-var': permissions?.length === 0 ? '40px' : '21px' }">
        @for (node of permissionSets; track node.value.id) {
            <eaw-permission-tree
                    [permissionSetId]="node.value.id"
                    [permissionSet]="node.value"
                    [permissionSetParent]="rootNode!.value"
                    (deleted)="onRemovedPermissionSet(node)">
            </eaw-permission-tree>
        }

        @for (node of permissions; track node.value.node) {
            <eaw-permission-node
                    [canDelete]="canDeletePermissions"
                    [canUpdate]="canUpdatePermissions"
                    [hasParent]="true"
                    [disabled]="node.disabled"
                    [permission]="node.value"
                    [changeValue]="changePermissionValue.bind(this)"
                    (deletePermission)="deletePermission(node)">
            </eaw-permission-node>
        }
    </div>
}
