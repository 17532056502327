import { downgradeInjectable } from '@angular/upgrade/static';
import { CurrentService } from './current.service';
import { module } from 'angular';

/**
 * This should only be used in the app route as an angularjs dependency or other rare edge cases.
 *
 * This is not to be used a lot.
 */
module('eaw').factory('CurrentDowngrade', downgradeInjectable(CurrentService));
