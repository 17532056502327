// @ts-nocheck
import { module } from 'angular';
import { EawUrl } from '../../../shared/angularjs/modules/resource/url.service';
import { Mobile } from '../../../shared/utils/eaw-mobile';

module('eaw.hr').controller('fileDownloadHrCtrl', [ '$mdDialog', '$http', function($mdDialog, $http) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.cancel = $mdDialog.cancel;
        ctrl.gettingUrl = true;

        $http.get(EawUrl.url + `/customers/${ctrl.customer}/employees/${ctrl.employee}/hr_files/${ctrl.file}/${ctrl.type}`, {
            params: {
                url: true,
            },
        }).then((resp) => {
            ctrl.url = resp.data.url;
            ctrl.gettingUrl = false;
        }).catch(() => {
            $mdDialog.cancel();
        });
    };

    ctrl.download = () => {
        Mobile.openBrowser(ctrl.url);
    };
} ]);
