import { ChangeDetectionStrategy, Component, EventEmitter, inject, input, OnInit, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetComponent } from '../../classes/widget-component';
import { AlertsService, GetAlertsOptions } from '../../../shared/http/alerts.service';
import { CurrentService } from '../../../shared/services/current.service';
import { Alert } from '../../../shared/models/alert';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { Widget } from '../../classes/widget';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';

@Component({
    selector: 'eaw-mini-contract-requalification-widget',
    standalone: true,
    imports: [ CommonModule, MiniWidgetContentComponent, TranslatePipe ],
    templateUrl: './mini-contract-requalification-widget.component.html',
    styleUrl: './mini-contract-requalification-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniContractRequalificationWidgetComponent extends WidgetComponent implements OnInit {
    alertService = inject(AlertsService);
    current = inject(CurrentService);

    // For ajs container
    @Output() loaded = new EventEmitter();

    widget = input.required<Widget>();

    employeeIds = signal(new Set<number>());

    ngOnInit() {
        expandAllPages<Alert, GetAlertsOptions>((pagination) => this.alertService.getAlerts(this.current.getCustomer().id, pagination), { category: 'overtime' }).subscribe((alerts) => {
            alerts.forEach((alert) => this.employeeIds.update((ids) => ids.add(alert.subject_id)));
            this.setLoading(false);
            this.loaded.emit();
        });
    }
}
