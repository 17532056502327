// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-list class="md-dense">
    <md-list-item ng-show="!absence.error && absence.list.loaded && !absence.list.getLength()">
        <p ng-i18next="NO_DATA"></p>
    </md-list-item>

    <md-list-item ng-show="absence.error">
        <p ng-i18next="errors:SOMETHING_WENT_WRONG"></p>
        <md-button class="md-secondary" ng-click="absence.reload()">
            <md-icon ng-bind="'refresh'"></md-icon>
            <md-tooltip ng-i18next="RELOAD"></md-tooltip>
        </md-button>
    </md-list-item>

    <md-progress-circular ng-if="absence.list.loading" class="md-progress-center tw-mt-20"></md-progress-circular>

    <md-virtual-repeat-container ng-show="absence.list.getLength()">
        <md-list-item class="md-2-line" md-virtual-repeat="absent in absence.list" md-on-demand ng-show="!absence.list.loading">
            <div class="md-list-item-text">
                <p ng-bind="absent.employee.name"></p>
                <h4>{{ absent.type.name | eawTranslate: 'absence_types'}}</h4>
            </div>

            <div class="md-secondary">
                {{absent.from.format('LL - LT') + ' ' + absent.to.format('LL - LT')}}
            </div>
            <md-divider ng-if="!$last"></md-divider>
        </md-list-item>
    </md-virtual-repeat-container>
</md-list>
`;

module('eaw.dashboard').component('dashAbsenceList', {
    template: template1,
    controllerAs: 'absence',
    bindings: {
        loadAbsences: '&', // loadAbsences(pagination)
    },
    controller: [ 'EndlessList', function(EndlessList) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.list = new EndlessList(ctrl.load, {
                per_page: 20,
                with: [ 'employee', 'type' ],
            });
            ctrl.list.loadPage(1);
        };

        ctrl.reload = () => ctrl.list.reload();

        ctrl.load = function loadEntries(pagination) {
            delete ctrl.error;

            const loadAbsences = ctrl.loadAbsences({ pagination });
            loadAbsences.catch(() => ctrl.error = true);

            return loadAbsences;
        };
    } ],
});
