import { inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { DateTimeConverter } from '../../shared/utils/date-time-converter';
import { map, Observable } from 'rxjs';
import { EmployeeResponse } from '../../shared/models/employee';
import { Availability, AvailabilityResponse } from '../models/availability';
import { classifyItem, classifyArrayPaginatedResponse } from '../../shared/utils/rxjs/classify';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { AvailabilityDayResponse } from '../models/availability-day';
import { AvailabilityShiftResponse } from '../models/availability-shift';
import { AvailabilityOverviewInterval } from '../classes/availability-overview-interval';
import { sort } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../shared/services/current.service';

export interface AvailabilityOverviewResponse {
    data: Record<string, {
        employee: EmployeeResponse,
        weeks: AvailabilityDayResponse[][][] | null[][],
    }>
}

/**
 * This is how the interval will look once we finish formatting it
 */
export interface FormattedOverviewInterval {
    // Index is the day index from the start date
    index: number,
    yesterday?: true,
    interval: AvailabilityDayResponse | 'not_available' | 'available'
}

export type FormattedAvailabilityOverview = {
    employee: EmployeeResponse,
    intervals: AvailabilityOverviewInterval[][][],
}[];

export type AvailabilityCreateInterval = {
    day: number,
    whole_day: boolean,
    offset: number,
    length: number,
}

export type AvailabilityCreate = {
    from: DateTime,
    to?: DateTime,
    days: AvailabilityCreateInterval[],
    repeat: number,
    approved?: boolean,
    workDays: number[],
    comment?: string,
};

// Interface for updating data at server
interface AvailabilityUpdate {
    from?: DateTime;
    to?: DateTime;
    approved?: boolean;
    comment?: string;
    repeat?: 7 | 14 | 21 | 28;
    workDays?: number[];
}

interface GetAllOptions extends PaginationOptions {
    from?: DateTime,
    to?: DateTime,
    when?: DateTime,
    active?: boolean,
    approved?: boolean,
    handled?: boolean,
}

@Injectable({
    providedIn: 'any',
})
export class AvailabilityService {
    private http = inject(HttpClient);
    private current = inject(CurrentService);

    private formatOverview(from: DateTime, numberOfWeeks: number, overview: AvailabilityOverviewResponse, includeSplitAvailability?: boolean): FormattedAvailabilityOverview {
        const employees = Object.values(overview.data).map((item) => {
            const intervals: FormattedOverviewInterval[] = [];

            let firstInterval = undefined as AvailabilityDayResponse | undefined;
            let lastInterval = undefined as AvailabilityDayResponse | undefined;

            item.weeks.forEach((week, weekIndex) => {
                const lastWeek = weekIndex === item.weeks.length - 1;

                week.forEach((day, dayIndex) => {
                    const lastDay = lastWeek && dayIndex === week.length - 1;

                    if (weekIndex === 0 && dayIndex === 0) {
                        firstInterval = day?.find((i) => i.offset === 0);
                    }

                    if (lastWeek && lastDay) {
                        lastInterval = day?.find((i) => i.offset + i.length === 86400);
                    }

                    const index = (weekIndex * 7) + dayIndex;

                    if (Array.isArray(day)) {
                        if (day.length) {
                            day.forEach((interval) => intervals.push({ index, interval }));
                        } else {
                            intervals.push({ index, interval: 'not_available' });
                        }
                    } else {
                        intervals.push({ index, interval: 'available' });
                    }
                });
            });

            const sortedIntervals = sort(intervals, this.current.languageTag, [ (i) => i.index, (i) => typeof i.interval === 'string' ? -1 : i.interval.offset ], [ 'asc', 'asc' ], { numeric: true });

            let joinedIntervals: AvailabilityOverviewInterval[] = [];
            for (let i = 0; i < sortedIntervals.length; i++) {
                const isLast = i === sortedIntervals.length - 1;
                const current = sortedIntervals[i];
                const next = isLast ? sortedIntervals[0] : sortedIntervals[i + 1];
                const date = from.plus({ days: current?.index });

                if (!current) {
                    continue;
                }

                if (typeof current.interval === 'object') {
                    if (typeof next?.interval === 'object') {
                        const currentGoesToMidnight = current.interval.offset + current.interval.length === 86400;
                        const nextStartsAtMidnight = next.interval.offset === 0;

                        if (currentGoesToMidnight && nextStartsAtMidnight) {
                            joinedIntervals.push(new AvailabilityOverviewInterval(date, {
                                index: current.index,
                                id: current.interval.id,
                                availability_id: current.interval.availability_id,
                                offset: current.interval.offset,
                                length: current.interval.length + next.interval.length,
                                whole_day: current.interval.whole_day,
                            }));

                            if (!includeSplitAvailability) {
                                i++;
                            }
                        } else {
                            joinedIntervals.push(new AvailabilityOverviewInterval(date, {
                                index: current.index,
                                id: current.interval.id,
                                availability_id: current.interval.availability_id,
                                offset: current.interval.offset,
                                length: current.interval.length,
                                whole_day: current.interval.whole_day,
                            }));
                        }
                    } else {
                        joinedIntervals.push(new AvailabilityOverviewInterval(date, {
                            index: current.index,
                            id: current.interval.id,
                            availability_id: current.interval.availability_id,
                            offset: current.interval.offset,
                            length: current.interval.length,
                            whole_day: current.interval.whole_day,
                        }));
                    }
                } else if (current.interval === 'available') {
                    joinedIntervals.push(new AvailabilityOverviewInterval(date, { index: current.index, whole_day: true }));
                } else {
                    joinedIntervals.push(new AvailabilityOverviewInterval(date, { index: current.index, unavailable: true }));
                }
            }

            if (firstInterval && lastInterval) {
                joinedIntervals = joinedIntervals.slice(1);
            }

            const groupedIntervals = Array.from({ length: numberOfWeeks * 7 }, (_, index) => joinedIntervals.filter((i) => i.index === index));

            return {
                employee: item.employee,
                intervals: groupedIntervals.reduce((acc, _, index, array) => {
                    if (index % 7 === 0) {
                        acc.push(array.slice(index, index + 7));
                    }

                    return acc;
                }, [] as AvailabilityOverviewInterval[][][]),
            };
        });

        return sort(employees, this.current.languageTag, [ (e) => e.employee.first_name, (e) => e.employee.last_name ]);
    }

    getOverview(customerId: number, from: DateTime, weeks: number, includeSplitAvailability?: boolean) {
        const ceilWeeks = Math.ceil(weeks);

        return this.http.get<AvailabilityOverviewResponse>(`customers/${customerId}/availabilities/overview`, {
            params: formatHttpParams({
                from,
                weeks: ceilWeeks,
            }),
        }).pipe(map((response) => this.formatOverview(from, ceilWeeks, response, includeSplitAvailability)));
    }

    getActiveAvailability(customerId: number, employeeId: number) {
        return this.getAll(customerId, employeeId, {
            active: true,
            approved: true,
            'with[]': [ 'days', 'approval' ],
            per_page: 1,
            direction: 'desc',
            order_by: 'created_at',
        }).pipe(map((r) => r.data[0]));
    }

    // Get all availabilities from a company or a specific employee
    getAll(customerId: number, employeeId?: number | null, options?: GetAllOptions): Observable<ArrayPaginatedResponse<Availability>> {
        const url = employeeId ? `/customers/${customerId}/employees/${employeeId}/availabilities` : `/customers/${customerId}/availabilities`;

        return this.http.get<ArrayPaginatedResponse<AvailabilityResponse>>(url, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(Availability));
    }

    // Get availability info for all employees from a specific day, returns an AvailabilityShiftResponse interface
    getOnDay(customerId: number, scheduleId: number, businessDate: DateTime, options?: GetAllOptions): Observable<ArrayPaginatedResponse<AvailabilityShiftResponse>> {
        return this.http.get<ArrayPaginatedResponse<AvailabilityShiftResponse>>(`/customers/${customerId}/schedules/${scheduleId}/employees/${DateTimeConverter.convertDateTimeToBusinessDate(businessDate)}`, {
            params: formatHttpParams(options),
        });
    }

    create(customerId: number, employeeId: number, availabilityData: AvailabilityCreate, withArg: string[] = []): Observable<Availability> {
        return this.http.post<AvailabilityResponse>(`customers/${customerId}/employees/${employeeId}/availabilities`, formatHttpParams({
            from: availabilityData.from,
            to: availabilityData.to || undefined,
            days: availabilityData.days,
            repeat: availabilityData.repeat,
            approved: availabilityData.approved,
            comment: availabilityData.comment,
            work_days: availabilityData.workDays?.slice(0, availabilityData.repeat / 7),
        }), { params: { 'with[]': withArg } }).pipe(classifyItem(Availability));
    }

    get(customerId: number, employeeId: number, availabilityId: number, withArg: string[] = []): Observable<Availability> {
        return this.http.get<AvailabilityResponse>(`customers/${customerId}/employees/${employeeId}/availabilities/${availabilityId}`, {
            params: {
                'with[]': withArg,
            },
        }).pipe(classifyItem(Availability));
    }

    update(customerId: number, employeeId: number, availabilityId: number, availabilityData: AvailabilityUpdate, withArg: string[] = []): Observable<Availability> {
        return this.http.put<AvailabilityResponse>(`customers/${customerId}/employees/${employeeId}/availabilities/${availabilityId}`, {
            from: availabilityData.from,
            to: availabilityData.to,
            approved: availabilityData.approved,
            comment: availabilityData.comment,
            repeat: availabilityData.repeat,
            work_days: availabilityData.workDays?.slice(0, availabilityData.repeat ? availabilityData.repeat / 7 : 4),
        }, { params: { 'with[]': withArg } }).pipe(classifyItem(Availability));
    }

    delete(customerId: number, employeeId: number, availabilityId: number): Observable<undefined> {
        return this.http.delete<undefined>(`customers/${customerId}/employees/${employeeId}/availabilities/${availabilityId}`);
    }
}
