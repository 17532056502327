<eaw-page-header class="tw-mb-8">
    <span title>{{ 'TASK_plural' | translate | async }}</span>
</eaw-page-header>

<mat-card class="tw-overflow-auto">
    <mat-stepper orientation="vertical" #stepper [linear]="tosRequired">
        <ng-container *ngFor="let task of tasks">
            <mat-step *ngIf="task.type === taskTypes.TermsOfService && userId" [completed]="task.completed" [optional]="!task.required">
                <ng-template matStepLabel>{{ 'TERMS_OF_SERVICE' | translate | async }}</ng-template>
                <eaw-tos-task [task]="task" [userId]="userId" (accepted)="tosAccepted(stepper)"></eaw-tos-task>
            </mat-step>

            <mat-step *ngIf="task.type === taskTypes.SignDocument" [completed]="task.completed" [optional]="!task.required">
                <ng-template matStepLabel>{{ 'SIGN_DOCUMENT' | translate: 'tasks' | async }}</ng-template>
                <eaw-sign-document-task [task]="task"></eaw-sign-document-task>
            </mat-step>
            <mat-step *ngIf="task.type === taskTypes.UpdatePinCode && employee" [completed]="task.completed" [optional]="!task.required">
                <ng-template matStepLabel>{{ 'UPDATE_X' | translate:'general':{name: '$t(PIN_CODE)'} | async }}</ng-template>
                <eaw-update-pin-code [task]="task" [employee]="employee"></eaw-update-pin-code>
            </mat-step>
            <mat-step *ngIf="task.type === taskTypes.UploadDocument" [completed]="task.completed" [optional]="!task.required">
                <ng-template matStepLabel>{{ 'UPLOAD_FILE' | translate:'hr' | async }}</ng-template>
                <eaw-upload-document [task]="task"></eaw-upload-document>
            </mat-step>
        </ng-container>
    </mat-stepper>

</mat-card>

<mat-card id="continue-card">
    <mat-card-content>
        <div class="tw-flex tw-flex-col tw-gap-16">
            <div *ngIf="everythingCompleted">{{ 'ALL_COMPLETED' | translate:'tasks' | async }}</div>
            <div *ngIf="!everythingCompleted">{{ 'ALL_NOT_COMPLETED' | translate:'tasks' | async }}</div>

            <eaw-action-button [disabled]="!everythingCompleted" [loading]="false" (click)="continue()">
                {{ 'CONTINUE' | translate | async }}
            </eaw-action-button>
        </div>
    </mat-card-content>
</mat-card>
