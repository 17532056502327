// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

const template1 = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="company:POSITION_plural"></span>
        </md-card-header-text>

        <card-btn-float ng-if="pCtrl.canCreate" on-click="pCtrl.addPosition()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                reload-table="pCtrl.reloadTable"
                columns="pCtrl.table.columns"
                cells="pCtrl.table.cells"
                factory="PositionsFactory"
                factory-function="getAllForCustomer"
                factory-function-arguments="{
                                customer: pCtrl.customer.id
                            }">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;

module('eaw.company').component('companyPositions', {
    template: template1,
    controller: 'positionsCtrl',
    controllerAs: 'pCtrl',
    bindings: {
        canCreate: '<?',
        customer: '<',
    },
}).controller('positionsCtrl', [ 'PositionsFactory', '$mdDialog', 'ToastService', function(PositionsFactory, $mdDialog, ToastService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.table = {
            columns: [
                {
                    title: t('company:POSITION'),
                    sortable: 'name',
                },
                {
                    title: t('AMOUNT'),
                    sortable: 'employees_count',
                    class: 'text-right',
                },
                { title: '' },
            ],
            cells: [
                { template: 'item.name' },
                {
                    template: 'item.employees_count | eawNumber',
                    class: 'text-right',
                },
                {
                    buttons: [
                        {
                            icon: 'edit',
                            click: ctrl.editPosition,
                            invisible: (position) => !CurrentOld.can(`customers.${ctrl.customer.id}.positions.${position.id}.update`),
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            click: ctrl.deletePosition,
                            invisible: (position) => position.employees_count > 0 || !CurrentOld.can(`customers.${ctrl.customer.id}.positions.${position.id}.delete`),
                        },
                    ],
                },
            ],
        };
    };

    ctrl.deletePosition = (position) => {
        $mdDialog.show(
            $mdDialog.confirm()
                .title(t('company:DELETE_POSITION'))
                .textContent(t('company:DELETE_POSITION_TEXT', {
                    name: position.name,
                }))
                .theme('delete')
                .ok(t('DELETE'))
                .cancel(t('CANCEL')),
        ).then(() => {
            PositionsFactory.deleteForCustomer(ctrl.customer.id, position.id).$promise.then(() => {
                ctrl.reloadTable = true;
                ToastService.toast(t('company:POSITION_DELETED'));
            });
        });
    };

    ctrl.editPosition = (position) => {
        $mdDialog.show(
            $mdDialog.prompt()
                .title(t('company:EDIT_POSITION'))
                .initialValue(position.name)
                .placeholder(position.name)
                .required(true)
                .ok(t('OK'))
                .cancel(t('CANCEL')),
        ).then((positionName) => {
            PositionsFactory.updateForCustomer(ctrl.customer.id, position.id, positionName).$promise.then(() => {
                ctrl.reloadTable = true;
                ToastService.toast(t('company:POSITION_EDITED'));
            });
        });
    };

    ctrl.addPosition = () => {
        $mdDialog.show(
            $mdDialog.prompt()
                .title(t('company:NEW_POSITION'))
                .required(true)
                .ok(t('ADD'))
                .cancel(t('CANCEL')),
        ).then((positionName) => {
            PositionsFactory.createForCustomer(ctrl.customer.id, positionName).$promise.then(() => {
                ctrl.reloadTable = true;
                ToastService.toast(t('company:POSITION_ADDED'));
            });
        });
    };
} ]);
