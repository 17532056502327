<eaw-dialog-header>
    <span title>{{'ROLE' | translate:'company' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form class="tw-flex tw-flex-col" [formGroup]="form">
        <mat-form-field>
            <mat-label>{{'NAME' | translate | async}}</mat-label>
            <input formControlName="name" matInput required>
        </mat-form-field>

        <eaw-role-autocomplete controlName="parent" [customerId]="customerId" [label]="'SUPERIOR' | translate: 'company'"></eaw-role-autocomplete>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="" [disabled]="this.form.disabled">{{'CANCEL' | translate | async}}</button>
    <button mat-button mat-raised-button color="primary" (click)="close()" [disabled]="this.form.disabled || form.invalid">{{confirmText | translate | async}}</button>
</mat-dialog-actions>
