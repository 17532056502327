import { Inject, Injectable } from '@angular/core';
import { RejectType, UIRouter } from '@uirouter/core';
import { LoginService } from '../services/login.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlingHookService {
    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(LoginService) private loginService: LoginService,
    ) {
        // Send user to home if first transition errored
        this.uiRouter.transitionService.onError({
            from: (state) => state?.name !== 'eaw/app/home',
            to: (state) => state?.name !== 'eaw/app/home',
        }, (transition) => {
            console.error(transition.error());

            // We only want to redirect / start a new transition if an error occurred
            if (transition.error().type !== RejectType.ERROR) {
                return;
            }

            if (this.loginService.isLoggedIn()) {
                this.uiRouter.stateService.transitionTo('eaw/app/home');
            } else {
                this.uiRouter.stateService.transitionTo('eaw/outside/login');
            }
        });
    }
}
