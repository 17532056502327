import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { Tariff, TariffResponse, TariffSuggestion, TariffType } from '../models/tariff';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { DateTime } from 'luxon';
import { map } from 'rxjs';

export interface GetAllOptions extends PaginationOptions {
    // Get tariffs that are active on this date.
    activeDate?: DateTime | null;
    // Choose which types to get
    'types[]'?: TariffType[];
}

export interface GetSuggestionsOptions extends PaginationOptions {
    seniority?: number;
    from?: DateTime;
}

@Injectable({
    providedIn: 'root',
})
export class CustomerTariffService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, pagination?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<TariffResponse>>(`/customers/${customerId}/tariffs`, {
            params: formatHttpParams({
                ...pagination,
                active_rate: pagination?.activeDate,
            }),
        }).pipe(classifyArrayPaginatedResponse(Tariff));
    }

    get(customerId: number, tariffId: number) {
        return this.http.get<TariffResponse>(`/customers/${customerId}/tariffs/${tariffId}`, {
            params: formatHttpParams({
                'with[]': [ 'rates' ],
            }),
        }).pipe(classifyItem(Tariff));
    }

    getSuggestions(customerId: number, employeeId: number, pagination?: GetSuggestionsOptions) {
        return this.http.get<TariffResponse[]>(`/customers/${customerId}/pay_rates/tariff_suggestions`, {
            params: formatHttpParams({
                ...pagination,
                employee_id: employeeId,
            }),
        }).pipe(
            map((response) => response.map((tariff) => new TariffSuggestion(tariff))),
        );
    }
}
