// @ts-nocheck
import { module } from 'angular';
import { EawResourceFactory } from '../../../shared/angularjs/modules/resource/resource.service';
import { PaginationOld } from '../../../shared/utils/pagination-old';
module('eaw.payroll.flexitime').factory('FlexitimeFactory', [ 'EawResource', function(EawResource: EawResourceFactory) {
    const factory = this;
    const allRes = EawResource.create('/customers/:customer/employees/all/flexitime');
    const empRes = EawResource.create('/customers/:customer/employees/:employee/flexitime/:flexitime');
    const balRes = EawResource.create('/customers/:customer/employees/:employee/flexitime/balance');
    factory.getAll = (args) => allRes.get({
        customer: args?.customer,
        business_date: args?.businessDate,
        include_inactive: args?.include_inactive,
        'with[]': args?.with,
        ...PaginationOld.getParams(args),
    });
    factory.getForEmployee = (args) => empRes.get({
        customer: args?.customer,
        employee: args?.employee,
        from: args?.from,
        to: args?.to,
        ...PaginationOld.getParams(args),
    });
    /**
     * Get the current flexitime balance for an employee
     */
    factory.getEmployeeBalance = (customerId, employeeId, to) => balRes.get({
        customer: customerId,
        employee: employeeId,
        to,
    });
    factory.add = (flexitime, employee, customer) => empRes.save({
        customer,
        employee,
    }, flexitime);
    factory.delete = (flexitime, employee, customer) => empRes.delete({
        customer,
        employee,
        flexitime,
    });
    return factory;
} ]);
