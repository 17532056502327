// @ts-nocheck
import { module } from 'angular';
/**
 * @ngdoc factory
 * @name filesystem-access
 */
module('eaw.filesystem-access').factory('filesystemAccessFactory', [ 'EawResource', function(EawResource) {
    const factory = this;
    const resource = EawResource.create('/customers/:customer/filesystems?files=yes');
    factory.getAll = {
        permission(customerId) {
            return `customers.${customerId}.filesystems.*.get`;
        },
        query(customerId) {
            return resource.get({
                customer: customerId,
            }).$promise;
        },
    };
    return factory;
} ]);
