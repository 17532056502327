import {User, UserResponse} from '../app/shared/models/user';

export function mockUser(data: Partial<UserResponse> = {}) {
    return new User({
        email: '',
        first_name: '',
        id: 0,
        language_code: '',
        last_name: '',
        name: '',
        ...data,
    });
}
