import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BusinessDateString } from '../../shared/types/business-date';
import { BusinessDate } from '../../shared/utils/business-date';

export interface FlexitimeResponse extends ApiResponse {
    business_date: BusinessDateString;
    employee_id: number;
    comment?: string | null;
    delta?: number | null;
    id: number;
    performed_by?: number | null;
    performed_by_name?: string | null;
    type: 'auto' | 'manual';
    created_at: string;
    updated_at: string;
    deleted_at?: string | null;
}

export class Flexitime {
    businessDate: BusinessDate;
    employeeId: number;
    comment?: string;
    delta?: number;
    id: number;
    performedBy?: number;
    performedByName?: string;
    type: 'auto' | 'manual';
    createdAt?: DateTime | null;
    updatedAt?: DateTime | null;
    deletedAt?: DateTime | null;

    constructor(data: FlexitimeResponse) {
        this.businessDate = new BusinessDate(data.business_date);
        this.employeeId = data.employee_id;
        this.comment = data.comment ?? undefined;
        this.delta = data.delta ?? undefined;
        this.id = data.id;
        this.performedBy = data.performed_by ?? undefined;
        this.performedByName = data.performed_by_name ?? undefined;
        this.type = data.type;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
