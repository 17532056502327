import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { RoleService } from '../../../leader-roles/shared/http/role.service';
import { Role } from '../../../leader-roles/shared/types/role';
import { Customer } from '../../../shared/models/customer';
import { DateTime } from 'luxon';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { RoleAssignmentDialogService } from '../../../leader-roles/shared/services/role-assignment-dialog.service';
import { AssignmentDataTableComponent } from '../../../leader-roles/assignment-data-table/assignment-data-table.component';
import { UIRouter } from '@uirouter/core';
import { RoleAssignment } from '../../../leader-roles/shared/types/role-assignment';
import { EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { PermissionSetTableComponent } from '../../components/permission-set-table/permission-set-table.component';
import { PermissionTableComponent } from '../../components/permission-table/permission-table.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { User } from '../../../shared/models/user';
import { CustomerService } from '../../../shared/http/customer.service';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { of } from 'rxjs';

@Component({
    selector: 'eaw-admin-role-role-assignment-list',
    templateUrl: './admin-role-role-assignment-list.component.html',
    styleUrl: './admin-role-role-assignment-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatExpansionModule,
        NgIf,
        MatButtonModule,
        MatIconModule,
        AssignmentDataTableComponent,
        PermissionTableComponent,
        PermissionSetTableComponent,
        AsyncPipe,
        TranslatePipe,
        InfoLoadingComponent,
    ],
})
export class AdminRoleRoleAssignmentListComponent implements OnInit, EawDataTable {
    @Input() roleId!: number;
    @Input() customerId!: number;

    @ViewChild('assignments') table!: AssignmentDataTableComponent;

    createButton!: HeaderFabButton;
    role?: Role;
    customer?: Customer;
    columns: DataTableColumnType<RoleAssignment>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item.user ? new User(cell.item.user).name : '',
            header: new DataTableHeader({
                i18n: 'NAME',
            }),
        }),
        new DataTableDateTimeColumn({
            header: new DataTableHeader({ i18n: 'FROM' }),
            value: 'from',
            format: DateTime.DATETIME_MED,
        }),
        new DataTableDateTimeColumn({
            header: new DataTableHeader({ i18n: 'TO' }),
            value: 'to',
            format: DateTime.DATETIME_MED,
        }),
        new DataTableDateTimeColumn({
            header: new DataTableHeader({ i18n: 'CREATED' }),
            value: 'createdAt',
            format: DateTime.DATETIME_MED,
        }),
        new DataTableDateTimeColumn({
            header: new DataTableHeader({ i18n: 'UPDATED' }),
            value: 'updatedAt',
            format: DateTime.DATETIME_MED,
        }),
    ];

    constructor(
        @Inject(RoleService) protected roles: RoleService,
        @Inject(RoleAssignmentDialogService) protected dialog: RoleAssignmentDialogService,
        @Inject(SnackBarService) protected snackBar: SnackBarService,
        @Inject(UIRouter) protected uiRouter: UIRouter,
        @Inject(CustomerService) protected customerService: CustomerService,
    ) {
    }

    updateTable(): void {
        throw new Error('Method not implemented.');
    }

    ngOnInit(): void {
        this.roleId = this.uiRouter.globals.params['roleId'];
        this.roles.get(this.customerId, this.roleId).subscribe((role) => (this.role = role));
        this.customerService.get(this.customerId).subscribe((customer) => (this.customer = customer));

        this.createButton = {
            click: () => {
                this.dialog.create(this.customerId, this.role).subscribe(() => {
                    this.table.updateTable({ page: 1 });
                });
            },
            icon: 'add',
            type: 'primary',
            hasPermission: () => of(true),
        };
    }

}
