<eaw-dialog-header>
    <span title>{{data.title | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <eaw-object-creator [formControl]="objectControl"></eaw-object-creator>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="">{{'CLOSE' | translate | async}}</button>
    <button mat-button (click)="update()">{{'UPDATE_SETTING' | translate:'settings' | async}}</button>
</mat-dialog-actions>
