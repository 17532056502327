import { Inject, Injectable } from '@angular/core';
import { DateTimeConverter } from '../shared/utils/date-time-converter';
import { DateTime } from 'luxon';
import { map } from 'rxjs';
import { PeopleKpiResponse } from './types';
import { PeopleKpi } from './types/people-kpi';
import { PeopleKpiLevel } from './types/people-kpi-level';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../shared/utils/format-http-params';
import { ApiResponse } from '../shared/interfaces/api-response';

export interface PeopleKpiLevelResponse extends ApiResponse {
    identifier: string;
    name: string;
}

@Injectable({
    providedIn: 'any',
})
export class PeopleKpiService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    get(customer: number, from: DateTime, to: DateTime, level?: PeopleKpiLevel | null) {
        return this.http.get<PeopleKpiResponse>(`/customers/${customer}/people_kpis`, {
            params: formatHttpParams({
                from: DateTimeConverter.convertDateTimeToUtcString(from),
                to: DateTimeConverter.convertDateTimeToUtcString(to),
                level: level?.identifier,
            }),
        }).pipe(
            map((response) => {
                return new PeopleKpi(response);
            }),
        );
    }

    getLevels(customer: number) {
        return this.http.get<PeopleKpiLevelResponse[]>(`/customers/${customer}/people_kpi_levels`).pipe(
            map((response) => {
                return response.map((level) => new PeopleKpiLevel(level));
            }),
        );
    }
}
