import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { User } from '../../../../../shared/models/user';
import { Customer } from '../../../../../shared/models/customer';
import { PermissionCheckService } from '../../../../../shared/services/permission-check.service';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { UIRouter } from '@uirouter/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Language } from '../../../../../admin/models/language';
import { MatCardModule } from '@angular/material/card';
import { ProfilePictureComponent } from '../../../../../shared/components/profile-picture/profile-picture.component';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { MatInputModule } from '@angular/material/input';
import { AutocompleteComponent } from '../../../../../shared/components/autocomplete/autocomplete.component';
import { LanguageAutocompleteService } from '../../../../../shared/autocompletes/language-autocomplete.service';
import { find, from, map, mergeMap } from 'rxjs';
import { BasicAutocompleteComponent } from '../../../../../shared/components/basic-autocomplete/basic-autocomplete.component';
import { CountriesService, JsonCountry } from '../../../../../shared/services/countries.service';
import { MatIconModule } from '@angular/material/icon';
import { MaterialColorDirective } from '../../../../../shared/directives/material-color.directive';
import { InfoBoxComponent } from '../../../../../shared/components/info-card/info-box.component';
import { CustomerUserService } from '../../../../../shared/http/customer-user.service';

interface UserForm {
    firstName: FormControl<string>
    lastName: FormControl<string>
    email: FormControl<string>
    dialCodeCountry: FormControl<JsonCountry | null>
    phoneNumber: FormControl<string | null>
    language: FormControl<Language | null>
}

@Component({
    selector: 'eaw-company-user',
    standalone: true,
    imports: [ CommonModule, MatCardModule, ProfilePictureComponent, ActionButtonComponent, TranslatePipe, FormsModule, ReactiveFormsModule, MatInputModule, AutocompleteComponent, BasicAutocompleteComponent, MatIconModule, MaterialColorDirective, InfoBoxComponent ],
    templateUrl: './user.component.html',
    styleUrl: './user.component.scss',
})
export class UserComponent implements OnInit {
    protected readonly permissionCheckService = inject(PermissionCheckService);
    protected readonly customerUserService = inject(CustomerUserService);
    protected readonly snackBarService = inject(SnackBarService);
    protected readonly uiRouter = inject(UIRouter);
    protected readonly formBuilder = inject(FormBuilder);
    protected readonly languageAutocomplete = inject(LanguageAutocompleteService);
    protected readonly countriesService = inject(CountriesService);

    @Input({ required: true }) user!: User;
    @Input({ required: true }) customer!: Customer;

    form!: FormGroup<UserForm>;
    canUpdate = false;
    updating = false;

    ngOnInit() {
        this.form = this.formBuilder.group({
            firstName: new FormControl<string>(this.user.firstName, { nonNullable: true, validators: [ Validators.required ] }),
            lastName: new FormControl<string>(this.user.lastName, { nonNullable: true, validators: [ Validators.required ] }),
            email: new FormControl<string>(this.user.email, { nonNullable: true, validators: [ Validators.required, Validators.email ] }),
            dialCodeCountry: new FormControl<JsonCountry|null>(null),
            phoneNumber: this.user.phone || null,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            language: this.user.language!,
        });

        from(this.countriesService.get()).pipe(
            // We flatMap it because from(Promise<someObject[]>) will create one emission with the resolved array instead of making every element from the resolved array an emission -_-
            mergeMap((el) => el),
            find((country) => {
                const userCountryCode = typeof this.user.countryCode === 'string'
                    ? parseInt(this.user.countryCode.replace('+', ''))
                    : this.user.countryCode;
                return country.Dial === userCountryCode;
            }),
        ).subscribe((country) => {
            if (country) {
                this.form.controls.dialCodeCountry.setValue(country);
            }
        });

        // Disable while waiting for permission check
        this.form.disable();

        this.permissionCheckService
            .isAllowed(`customers.${this.customer.id}.users.${this.user.id}.update`)
            .subscribe((canUpdate) => {
                this.canUpdate = canUpdate && !this.user.lastActive;

                if (this.canUpdate) {
                    this.form.enable();
                }
            });
    }

    updateUser() {
        this.updating = true;
        const attrs = this.form.value;
        const countryCode = attrs.dialCodeCountry?.Dial;

        this.customerUserService
            .update(this.customer.id, this.user.id, {
                first_name: attrs.firstName,
                last_name: attrs.lastName,
                email: attrs.email,
                country_code: countryCode ? String(countryCode) : undefined,
                phone: attrs.phoneNumber ?? undefined,
                language_code: attrs.language?.code,
            })
            .subscribe((user) => {
                const email: string = user.email;
                this.uiRouter.stateService
                    .reload()
                    .then(() => {
                        if (this.user.email !== email) {
                            this.snackBarService.t('SENT_VERIFICATION_EMAIL', 'company', { email });
                        }

                        this.snackBarService.t('USER_UPDATED', 'company');
                    })
                    .finally(() => this.updating = false);
            });
    }

    dialCodeDisplay(country: JsonCountry|null) {
        return country ? `${country.name} (${country.Dial})` : '';
    }

    getDialCodes = (filter?: string) => {
        return from(this.countriesService.get()).pipe(
            map((countries) => {
                const data = countries.filter((c) => c.name.toLowerCase().includes(filter?.toLowerCase() || ''));

                return {
                    data,
                    from: 1,
                    to: 1,
                    total: data.length,
                    last_page: 1,
                    current_page: 1,
                    per_page: data.length,
                };
            }),
        );
    };
}
