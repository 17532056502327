<div id="component-host" [class.active]="!!activeItem()">
    <div #componentHost></div>
</div>

<div id="items" [class.active]="!!activeItem()">
    @for (item of sidebarItems(); track item) {
        <button class="item"
                [class.active]="item.component && activeItem()?.name === item.name"
                mat-icon-button
                [eawMaterialColor]="item.color"
                eawPermission
                [permissions]="item.permissions"
                [somePermissions]="item.somePermissions"
                [permissionChildrenFilter]="[ 'ui.customers.' + customerId() +'.schedule-sidebar', 'visible', item.name ]"
                (click)="handleItem(item)"
                matTooltipPosition="left"
                [matTooltip]="(item.text.key | translate:item.text.ns | async) || ''">
            <mat-icon>{{ item.icon }}</mat-icon>
        </button>
    }
</div>

