import { Component, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentService } from '../../../shared/services/current.service';
import { UIRouter } from '@uirouter/core';
import { MuxHandlerDirective } from '../../directives/mux-handler.directive';
import { EasyAtWorkLearningModule } from 'tutorial-lib';
import { Mobile } from '../../../shared/utils/eaw-mobile';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';

@Component({
    selector: 'eaw-knowledge-center-page',
    standalone: true,
    imports: [ CommonModule, MuxHandlerDirective, EasyAtWorkLearningModule, InfoLoadingComponent, TranslatePipe ],
    templateUrl: './knowledge-center-page.component.html',
    styleUrl: './knowledge-center-page.component.scss',
})
export class KnowledgeCenterPageComponent implements OnDestroy {
    private uiRouter = inject(UIRouter);
    private currentService = inject(CurrentService);

    // Has to be Function because of UI-Router
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    private transitionDeregister?: Function;
    protected loaded = false;
    protected language: string;
    protected path = '';
    protected userId: string;
    protected isMobile = false;
    protected permissions: Record<string, boolean> = {};

    constructor() {
        this.isMobile = Mobile.isMobile;
        this.language = this.currentService.languageTag;
        this.userId = this.currentService.getUser().id.toString();
    }

    ngOnDestroy() {
        this.transitionDeregister?.();
    }

    handlePath() {
        this.path = this.formatPath(this.uiRouter.globals.params['path']);
    }

    handleBackTransition() {
        this.transitionDeregister = this.uiRouter.transitionService.onSuccess({}, () => {
            this.handlePath();
        });
    }

    onNavigate(path: string) {
        this.transitionDeregister?.();
        this.uiRouter.stateService.go('.', { path: this.formatPath(path) }).then(() => {
            this.handleBackTransition();
        });
    }

    formatPath(path: unknown) {
        if (typeof path === 'string' && path.length) {
            return path.startsWith('/') ? path : `/${path}`;
        }

        return '';
    }
}
