import { Component, inject, Input, OnInit } from '@angular/core';
import { PermissionSetService } from '../../../shared/http/permission-set.service';
import { EMPTY, forkJoin, map, mergeMap, Observable, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { PermissionSet } from '../../../shared/models/permission-set';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { AsyncPipe } from '@angular/common';
import { PermissionTreeComponent } from '../../../shared/components/permission-tree/permission-tree.component';
import { HeaderFabButtonMenu, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { TranslateService } from '../../../shared/services/translate.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';
import { PermissionDialogService } from '../../angularjs/shared/services/permission-dialog.service';
import { Permission } from '../../../shared/models/permission';
import { PermissionNodeComponent } from '../../../shared/components/permission-node/permission-node.component';
import { PermissionService } from '../../angularjs/shared/services/permission.service';
import { MatMenu, MatMenuItem } from '@angular/material/menu';

@Component({
    selector: 'eaw-admin-user-group-permission-sets',
    standalone: true,
    imports: [
        AsyncPipe,
        PermissionTreeComponent,
        PageHeaderComponent,
        TranslatePipe,
        PermissionNodeComponent,
        MatMenu,
        MatMenuItem,
    ],
    templateUrl: './user-group-permission-sets.component.html',
    styleUrl: './user-group-permission-sets.component.scss',
})
export class UserGroupPermissionSetsComponent implements OnInit {
    private readonly permissionSetService = inject(PermissionSetService);
    private readonly translate = inject(TranslateService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly permissionDialogService = inject(PermissionDialogService);
    private readonly permissionService = inject(PermissionService);

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) userGroupId!: number;

    protected fabButton?: HeaderFabButtonMenu;
    protected permissionSets?: PermissionSet[];
    protected permissions: Permission[] = [] as Permission[];
    protected canCreateUpdateOrDeletePermissions?: Observable<boolean>;

    private deletePermissionSetCache = new Map<number, Observable<UserGroupPermissionSetsComponent['deletePermissionSet'] | undefined>>();

    async ngOnInit() {
        const getPermissions = expandAllPages((pagination) => {
            return this.permissionService.getAllForUserGroup(this.userGroupId, { pagination, ignoreStatus: [ 403 ] });
        }, { per_page: 1000 })
            .pipe(
                tap((permissions) => {
                    this.permissions = permissions;
                }),
                take(1),
            );
        const getPermissionSets = expandAllPages(
            (pagination) => {
                return this.permissionSetService.getAllForUserGroup(this.userGroupId, pagination);
            },
            { per_page: 200 },
        ).pipe(
            tap((permissionSets) => {
                this.permissionSets = permissionSets;
            }),
            take(1),
        );

        forkJoin([ getPermissions, getPermissionSets ]).subscribe();

        const permissionOptions = {
            models: [
                { id: this.customerId, type: ApiModel.Customer },
                { id: this.userGroupId, type: ApiModel.UserGroup },
            ],
        };

        const managePermissionsPermission = `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].permissions.manage`;
        const createSetPermission = `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].permission_sets.create`;

        this.canCreateUpdateOrDeletePermissions = this.permissionCheckService.isAllowed(managePermissionsPermission, permissionOptions);
        this.fabButton = {
            icon: 'add',
            hasPermission: () => this.permissionCheckService.isAllowedMany([], [ createSetPermission, managePermissionsPermission ], permissionOptions),
            menu: [
                {
                    hasPermission: () => this.permissionCheckService.isAllowed(createSetPermission, permissionOptions),
                    text: this.translate.t('ADD_PERMISSION_SET', 'admin'),
                    icon: 'add',
                    click: () => this.addPermissionSet(),
                },
                {
                    hasPermission: () => this.canCreateUpdateOrDeletePermissions || of(false),
                    text: this.translate.t('ADD_PERMISSION', 'admin'),
                    icon: 'add',
                    click: () => this.addPermission(),
                },
            ],
        };
    }

    protected addPermissionSet() {
        this.permissionDialogService.addPermissionSet()
            .afterClosed()
            .pipe(
                switchMap((permissionSet) => {
                    if (!permissionSet) {
                        return EMPTY;
                    }

                    return this.permissionSetService.attachToUserGroup(this.customerId, this.userGroupId, permissionSet.id);
                }),
                tap((permissionSet) => {
                    this.permissionSets?.push(permissionSet);
                }),
            ).subscribe();
    }

    protected deletePermissionSet = (permissionSet: PermissionSet) => {
        return this.permissionSetService.detachFromUserGroup(this.userGroupId, permissionSet.id)
            .pipe(
                tap(() => {
                    this.permissionSets = this.permissionSets?.filter((ps) => ps.id !== permissionSet.id);
                    this.translate.t('PERMISSION_SET_REMOVED', 'permissions', { name: permissionSet.name });
                }),
            );
    };

    deletePermission(permission: Permission) {
        this.permissionService.deleteForUserGroup(this.userGroupId, permission.node)
            .pipe(tap(() => this.permissions = this.permissions.filter((p) => p.node !== permission.node)))
            .subscribe();
    }

    changePermissionValue(permission: Permission, value: boolean) {
        return this.permissionService.updateForUserGroup(this.userGroupId, permission.node, value)
            .pipe(map(() => {
                permission.value = value;
                this.permissions = [ ...this.permissions ];

                return value;
            }));
    }

    private addPermission() {
        this.permissionDialogService.addPermission()
            .afterClosed()
            .pipe(
                switchMap((permissions) => {
                    if (!permissions) {
                        return EMPTY;
                    }
                    return of(...permissions).pipe(mergeMap((permission) => {
                        return this.permissionService.createForUserGroup(this.userGroupId, permission);
                    }));
                }),
                tap((permission) => {
                    this.permissions.push(permission);
                }),
            ).subscribe();
    }

    getDeletePermissionSet(permissionSet: PermissionSet) {
        if (this.deletePermissionSetCache.has(permissionSet.id)) {
            return this.deletePermissionSetCache.get(permissionSet.id);
        }

        const deletePermissionSet = this.permissionCheckService.isAllowed(
            `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].permission_sets.[${ApiModel.PermissionSet}].delete`,
            {
                models: [
                    { id: this.customerId, type: ApiModel.Customer },
                    { id: this.userGroupId, type: ApiModel.UserGroup },
                    { id: permissionSet.id, type: ApiModelClass.PermissionSet },
                ],
            },
        ).pipe(map((hasPermission) => {
            return hasPermission ? this.deletePermissionSet : undefined;
        }), shareReplay(1));

        this.deletePermissionSetCache.set(permissionSet.id, deletePermissionSet);
    }
}
