<eaw-page-header class="tw-mb-16" [fabButton]="createButton">
    <div title>{{'QUALIFICATION_plural' | translate:'company' | async}}</div>
</eaw-page-header>

<mat-card class="mat-compact-card">
    <mat-card-content class="tw-flex tw-flex-col">
        <eaw-data-table [columns]="columns"
                        [request]="request"
                        initialSortBy="name"
                        (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>