// @ts-nocheck
import { keyBy } from 'lodash-es';
import { debounce } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';

/**
 * @ngdoc this
 * @name eaw\.scheduling.this.scheduleTable
 */
module('eaw.scheduling').service('scheduleTable', [ '$rootScope', 'scheduleDays', 'ShiftsTableRenderer', 'TimesTableRenderer', 'NewShiftLine', 'intervalService', 'openingHoursRenderer', 'ShiftsDraggable', 'ShiftsResizable', 'PeriodsDraggable', function scheduleTable($rootScope, scheduleDays, ShiftsTableRenderer, TimesTableRenderer, NewShiftLine, intervalService, openingHoursRenderer, ShiftsDraggable, ShiftsResizable, PeriodsDraggable) {
    /** @var {Schedule} _schedule */
    let _schedule;
    return {
        getSchedule() {
            return _schedule;
        },
        getCustomer() {
            return this.getSchedule().customer;
        },
        setSchedule(schedule) {
            _schedule = schedule;
        },
        addScrollingListeners() {
            document.getElementById('schedule-container')?.addEventListener('scroll', this.onScroll.bind(this), { passive: true });
            document.getElementById('schedule-container')?.addEventListener('scroll', this.onScrollDebounced.bind(this), { passive: true });
            document.getElementById('shifts-container')?.addEventListener('scroll', this.onScroll.bind(this), { passive: true });
            document.getElementById('shifts-container')?.addEventListener('scroll', this.onScrollDebounced.bind(this), { passive: true });
        },
        removeScrollingListeners() {
            document.getElementById('schedule-container')?.removeEventListener('scroll', this.onScroll.bind(this));
            document.getElementById('schedule-container')?.removeEventListener('scroll', this.onScrollDebounced.bind(this));
            document.getElementById('shifts-container')?.removeEventListener('scroll', this.onScroll.bind(this));
            document.getElementById('shifts-container')?.removeEventListener('scroll', this.onScrollDebounced.bind(this));
        },
        /**
         * @param {Event|Object} e
         */
        onScroll(e) {
            this.lastScrollTime = this.lastScrollTime ?? e.timeStamp;
            if (e.target.dataset.skipScrollEvent === '1') {
                delete e.target.dataset.skipScrollEvent;
                return;
            }
            const scheduleContainer = document.getElementById('schedule-container');
            const shiftsContainer = document.getElementById('shifts-container');
            if (!scheduleContainer) {
                return;
            }
            if (!shiftsContainer) {
                return;
            }
            const sufficientDuration = e.timeStamp - this.lastScrollTime >= 100;
            const lastScrolledSame = this.lastScrolled === e.target;
            if ((e.target === scheduleContainer && lastScrolledSame) || sufficientDuration) {
                shiftsContainer.scrollLeft = scheduleContainer.scrollLeft;
                this.lastScrolled = e.target;
                this.lastScrollTime = e.timeStamp;
            }
            if ((e.target === shiftsContainer && lastScrolledSame) || sufficientDuration) {
                scheduleContainer.scrollLeft = shiftsContainer.scrollLeft;
                this.lastScrolled = e.target;
                this.lastScrollTime = e.timeStamp;
            }
            // When a scroll event occurs, save the top location of the shift container's scrollbar
            ShiftsTableRenderer.setScrollTop(shiftsContainer.scrollTop);
        },
        onScrollDebounced: debounce(function onScrollDebounced(e) {
            this.findCurrentDate(e);
        }, 500),
        updateShift(shift) {
            return this.getSchedule().updateShift(shift);
        },
        deleteShift(shift) {
            return this.getSchedule().deleteShift(shift);
        },
        renderShifts() {
            const callback = () => {
                this.enableJqueryUi();
                this.renderOpeningHours();
            };
            ShiftsTableRenderer.render(this.getSchedule(), callback);
        },
        enableJqueryUi() {
            const schedule = this.getSchedule();
            ShiftsDraggable.enable(schedule, this.updateShift.bind(this));
            ShiftsResizable.enable(schedule, this.updateShift.bind(this));
            PeriodsDraggable.enable(schedule);
        },
        renderOpeningHours() {
            openingHoursRenderer.render(this.getSchedule(), this.getOpeningHours(), this.getSchedule().settings.verticalLines);
        },
        render() {
            const schedule = this.getSchedule();
            TimesTableRenderer.render(schedule);
            NewShiftLine.render(schedule);
            this.renderOpeningHours();
            const table = document.getElementById('schedule-table');
            if (table) {
                const width = table.getBoundingClientRect().width;
                document.getElementById('shifts-table').style.width = `${width}px`;
                document.documentElement.style.setProperty('--schedule-table-width', `${width}px`);
            }
        },
        setOpeningHours(hours) {
            this.openingHours = hours;
        },
        getOpeningHours() {
            return this.openingHours || [];
        },
        getOpensAt(date) {
            const day = date.isoWeekday();
            const oh = keyBy(this.getOpeningHours(), (oh) => oh.day);
            let opening = date.clone().startOf('d');
            if (oh[day]) {
                const opens = oh[day].open_from;
                opening = date.clone().hours(opens.hours()).minutes(opens.minutes());
            }
            return opening.isBefore(this.getSchedule().getFrom()) ? this.getSchedule().getFrom() : opening;
        },
        getOpenLength(date) {
            const day = date.isoWeekday();
            const oh = keyBy(this.getOpeningHours(), (o) => o.day);
            if (oh[day]) {
                const data = oh[day];
                if (data.open_from.isSameOrAfter(data.open_to)) {
                    data.open_to.add(1, 'd');
                }
                return data.open_to.diff(data.open_from, 's');
            }
            const openAt = this.getOpensAt(this.currentDate);
            return openAt.clone().add(1, 'd').startOf('d').diff(openAt, 's');
        },
        /**
         * The date the user is viewing now.
         */
        getCurrentDate() {
            if (moment.isMoment(this.currentDate)) {
                return this.currentDate.clone();
            }
            if (this.getSchedule()) {
                return this.getSchedule().getFrom();
            }
            return moment();
        },
        setCurrentDate(date, skipRender) {
            if (moment.isMoment(date)) {
                this.currentDate = date.clone().startOf('d');
            }

            this.getSchedule().setRender(this.getSchedule().getFrom(), this.getSchedule().getLength());

            if (!skipRender) {
                this.render();
                this.renderShifts();
            }
            // Broadcast that the date changed
            $rootScope.$broadcast('schedule:dayChanged', this.currentDate.clone());
        },
        resetCurrentDate() {
            delete this.currentDate;
        },
        getCurrentDay() {
            const date = this.getCurrentDate();
            const index = this.getSchedule().getDayIndex(date);
            return scheduleDays.getIndex(index);
        },
        goToDay(date, precise) {
            if (!moment.isMoment(date)) {
                return;
            }

            const start = precise ? date.clone() : this.getOpensAt(date);
            this.setCurrentDate(start);
            // Mathy things
            const diff = start.diff(this.getSchedule().from, 's');
            const px = Math.max(0, diff * intervalService.getOneSecondPixelLength());
            const scheduleContainer = document.getElementById('schedule-container');
            const shiftsContainer = document.getElementById('shifts-container');
            if (!scheduleContainer) {
                return;
            }
            if (!shiftsContainer) {
                return;
            }
            // First avoid event
            scheduleContainer.dataset.skipScrollEvent = '1';
            shiftsContainer.dataset.skipScrollEvent = '1';
            // Then just update here
            scheduleContainer.scrollLeft = px;
            shiftsContainer.scrollLeft = px;

        },
        findCurrentDate(e) {
            const pixelsLeft = e.target.scrollLeft;
            const pixelsRight = pixelsLeft + document.getElementById('schedule-container')?.getBoundingClientRect().width;
            const offsetLeft = intervalService.calculateOffsetFromPixels(pixelsLeft);
            const offsetRight = intervalService.calculateOffsetFromPixels(pixelsRight);
            const tableLeftTime = this.getSchedule().getFrom().add(offsetLeft, 's');
            const nextDayStart = tableLeftTime.clone().endOf('d');
            const tableRightTime = this.getSchedule().getFrom().add(offsetRight, 's');
            const leftDiff = nextDayStart - tableLeftTime;
            const rightDiff = tableRightTime - nextDayStart;
            let date = tableLeftTime;
            if (rightDiff > leftDiff) {
                date = tableLeftTime.add(1, 'd');
            }
            this.setCurrentDate(date.startOf('d'), true);
        },
    };
} ]);
