import { Options, Chart } from 'highcharts';
import { t } from 'i18next';
import { ElementInitialization } from './element-initialization.service';
import { Info, Settings } from 'luxon';

/**
 * @deprecated Use HighchartsService instead
 * @see HighchartsService
 */
export class EawChart {
    private chartPromise: Promise<Chart | undefined>;
    private chart?: Chart;
    private timeout: any;
    private Highcharts: any;
    private renderTarget: HTMLElement | string | null;

    constructor(
        private readonly renderTo: string | HTMLElement | null,
        public options: Options,
        public stockChart = false,
    ) {
        this.renderTarget = renderTo instanceof HTMLElement ? renderTo : this.renderTo;
        this.options = options;
        this.chartPromise = this.getScripts();
    }

    getChart() {
        return this.chartPromise;
    }

    async getScripts() {
        try {
            // We need to get highcharts before we get anything else
            if (this.stockChart) {
                const { default: Highcharts } = await import(/* webpackChunkName: "highstock" */ 'highcharts/highstock.src');
                this.Highcharts = Highcharts;
            } else {
                const { default: Highcharts } = await import(/* webpackChunkName: "highcharts" */ 'highcharts/highcharts.src');
                this.Highcharts = Highcharts;
            }

            const { default: more } = await import(/* webpackChunkName: "highcharts-more" */ 'highcharts/highcharts-more.src');
            more(this.Highcharts);

            const { default: noData } = await import(/* webpackChunkName: "highcharts-no-data" */ 'highcharts/modules/no-data-to-display.src');
            noData(this.Highcharts);

            const { default: data } = await import(/* webpackChunkName: "highcharts-data" */ 'highcharts/modules/data.src');
            data(this.Highcharts);

            if (this.stockChart) {
                const { default: stock } = await import(/* webpackChunkName: "highcharts-stock" */ 'highcharts/modules/stock.src');
                stock(this.Highcharts);
            }

            switch (this.options?.chart?.type?.toLowerCase()) {
                case 'variablepie':
                    const { default: variablepie } = await import(/* webpackChunkName: "highcharts-variable-pie" */ 'highcharts/modules/variable-pie.src');
                    variablepie(this.Highcharts);
                    break;
                case 'xrange':
                    const { default: xrange } = await import(/* webpackChunkName: "highcharts-xrange" */ 'highcharts/modules/xrange.src');
                    xrange(this.Highcharts);
                    break;
                case 'solidgauge':
                    const { default: solidgauge } = await import(/* webpackChunkName: "highcharts-solid-gauge" */ 'highcharts/modules/solid-gauge.src');
                    solidgauge(this.Highcharts);
                    break;
                case 'heatmap':
                    const { default: heatmap } = await import(/* webpackChunkName: "highcharts-heatmap" */ 'highcharts/modules/heatmap.src');
                    heatmap(this.Highcharts);
                    break;
            }

            if (this.options.chart?.options3d?.enabled) {
                const { default: highcharts3d } = await import(/* webpackChunkName: "highcharts-3d" */ 'highcharts/highcharts-3d.src');
                highcharts3d(this.Highcharts);
            }
        } catch (e) {
            console.error(e);
        }

        return this.createChart();
    }

    createChart() {
        if (!this.renderTarget) {
            console.error('No render target');
            return undefined;
        }

        this.Highcharts.setOptions({
            lang: {
                loading: t('LOADING'),
                noData: t('NO_DATA'),
                months: Info.months(),
                shortMonths: Info.months('short'),
                shortWeekdays: Info.weekdays(),
                weekdays: Info.weekdays('short'),
            },
        });

        const target = this.renderTarget instanceof HTMLElement ? this.renderTarget : document.getElementById(this.renderTarget);

        // Create the chart
        this.chart = this.Highcharts[this.stockChart ? 'stockChart' : 'chart'](target, {
            credits: {
                enabled: false,
            },
            time: {
                timezone: Settings.defaultZone,
            },
            ...this.options,
        });

        if (!target) {
            return;
        }

        const elInit = new ElementInitialization(target, undefined, (el) => {
            const rect = el.getBoundingClientRect();
            return !!(rect.height * rect.width);
        });
        elInit.observe().then(() => {
            this.chart?.reflow?.();
            this.watchSize();
        });

        return this.chart;
    }

    watchSize() {
        if (!this.renderTarget) {
            throw Error('No render target');
        }

        const target = this.renderTarget instanceof HTMLElement ? this.renderTarget : document.getElementById(this.renderTarget);

        this.timeout = setTimeout(() => {
            if (target instanceof HTMLElement && document.body.contains(target)) {
                this.chart?.reflow?.();
                this.watchSize();
            } else { // If chart container doesn't exist, then clean up
                clearTimeout(this.timeout);
                this.chart?.destroy();
            }
        }, 1000);
    }
}
