<eaw-page-header [fabButton]="fabButton">
    <span title>
        {{ 'USER_GROUP_plural' | translate | async }}
    </span>
</eaw-page-header>

<mat-card>
    <eaw-data-table
            #dataTable
            [request]="request"
            [columns]="columns"
            (paginationChange)="updateTable($event)"
            [goTo]="goToPage"/>
</mat-card>
