import { inject, Injectable } from '@angular/core';
import { CurrentService } from '../../shared/services/current.service';
import { EmployeeTask, EmployeeTaskType } from '../../shared/models/employee-task';
import { UIRouter } from '@uirouter/core';
import { Transition } from '@uirouter/angularjs';

@Injectable({
    providedIn: 'root',
})
export class TasksService {
    private readonly current = inject(CurrentService);

    private _completed = false;
    private _tasks: EmployeeTask[] = [];
    private redirectTransition?: Transition;

    setRedirect(transition: Transition) {
        this.redirectTransition = transition;
    }

    redirect(uiRouter: UIRouter) {
        if (this.redirectTransition) {
            return uiRouter.stateService.transitionTo(this.redirectTransition.to(), this.redirectTransition.params());
        }

        return uiRouter.stateService.go('eaw/app/home');
    }

    get completed() {
        return this._completed;
    }

    set completed(value: boolean) {
        this._completed = value;
    }

    get tosRequired() {
        const me = this.current.getMe();

        if (me) {
            return me.isActing ? false : !me.acceptedTos;
        }

        return false;
    }

    get hasTasks(): boolean {
        return !this.completed && !!this.tasks.length;
    }

    set tasks(tasks: EmployeeTask[]) {
        // Tos should always be first
        if (this.tosRequired) {
            tasks.unshift(new EmployeeTask({
                is_blocking: true,
                type: EmployeeTaskType.TermsOfService,
            }));
        }

        this._tasks = tasks;
    }

    get tasks() {
        return this._tasks;
    }
}
