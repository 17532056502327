<eaw-page-header [fabButton]="fabButton" [buttons]="headerButtons">
    <span title>{{'CUSTOMER_plural' | translate | async}}</span>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        <eaw-data-table
            #table
            [columns]="columns"
            [request]="request"
            [goTo]="goTo"
            initialSortBy="id"
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
