<eaw-dialog-header>
    <span title *ngIf="data.mode === 'add'">{{'ADD_DAYS' | translate:'vacation' | async}}</span>
    <span title *ngIf="data.mode === 'deduct'">{{'REMOVE_DAYS' | translate:'vacation' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-8" autocomplete="off">
        <mat-form-field>
            <mat-label>{{'DAY_plural' | translate | async}}</mat-label>
            <input matInput formControlName="days" min="1" step="1" max="100" type="number">
        </mat-form-field>

        <mat-form-field eawDatePickerOptions>
            <mat-label>{{'DATE' | translate | async}}</mat-label>
            <input matInput [matDatepicker]="$any(datePicker)" [min]="minDate" formControlName="date" autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-hint>{{'DAYS_VALID_FROM_INFO' | translate:'vacation' | async}}</mat-hint>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'COMMENT' | translate | async}}</mat-label>
            <textarea formControlName="comment" matInput></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="false" (click)="submit()" [disabled]="form.disabled || form.invalid">
        <span *ngIf="data.mode === 'add'">{{'ADD_DAYS' | translate:'vacation' | async}}</span>
        <span *ngIf="data.mode === 'deduct'">{{'REMOVE_DAYS' | translate:'vacation' | async}}</span>
    </eaw-action-button>
</mat-dialog-actions>
