// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
module('eaw.calendar2').component('manageCalendar', {
    template: `<md-card id="create-cal-card">
    <md-card-header>
        <md-button class="md-icon-button" ng-click="$manageCal.back()">
            <md-icon ng-bind="'arrow_back'"></md-icon>
            <md-tooltip md-direction="left" ng-i18next="BACK"></md-tooltip>
        </md-button>

        <md-card-header-text>
            <span ng-if="!$manageCal.calendar.key" class="md-title" ng-i18next="calendar:NEW_CALENDAR"></span>
            <span ng-if="$manageCal.calendar.key" class="md-title" ng-i18next="calendar:EDIT_CALENDAR"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content class="overflow-y-auto">
        <form name="manageCalendarForm">
            <md-input-container>
                <label ng-i18next="NAME"></label>
                <input ng-model="$manageCal.calendar.name" required>
            </md-input-container>

            <eaw-colorpicker ng-model="$manageCal.calendar.color" hex="true" required></eaw-colorpicker>

            <eaw-accordion>
                <eaw-accordion-panel>
                    <panel-header>
                        <div layout="row" layout-align="start center">
                            <md-icon ng-bind="'lock'" ng-if="!$manageCal.controllables.length"></md-icon>
                            <md-icon ng-bind="'lock_open'" ng-if="$manageCal.controllables.length"></md-icon>

                            <div class="tw-ml-12 access-header">
                                <div ng-i18next="ACCESS"></div>

                                <small ng-if="!$manageCal.controllables.length" ng-i18next="calendar:ACCESS_PRIVATE"></small>
                                <small ng-if="$manageCal.controllables.length" ng-i18next="calendar:ACCESS_PUBLIC"></small>
                            </div>
                        </div>
                    </panel-header>
                    <panel-content ng-show="$manageCal.loadingAcl">
                        <md-progress-circular class="md-progress-center"></md-progress-circular>
                    </panel-content>
                    <panel-content ng-show="!$manageCal.loadingAcl" class="tw-p-0">
                        <md-autocomplete
                                class="tw-p-16"
                                md-selected-item="$manageCal.controllable"
                                md-search-text="filter"
                                md-min-length="0"
                                md-delay="300"
                                md-no-cache="true"
                                placeholder="{{'calendar:ACCESS' | i18next}}"
                                md-selected-item-change="$manageCal.addControllable($manageCal.controllable)"
                                md-menu-class="autocomplete-custom-template"
                                md-items="controllable in $manageCal.getControllables(filter)"
                                md-item-text="controllable.name">
                            <md-item-template>
                                <span class="autocomplete-item-title" md-highlight-text="filter" md-highlight-flags="gi">{{controllable.name}}</span>
                                <span class="autocomplete-item-metadata">{{controllable.translatedMorph}}</span>
                            </md-item-template>
                            <md-not-found>
                                <span ng-i18next="NO_MATCH_FOUND"></span>
                            </md-not-found>
                        </md-autocomplete>

                        <table id="access-table" class="table table-bordered-bottom" ng-if="$manageCal.controllables.length">
                            <thead>
                            <tr>
                                <th></th>
                                <th ng-i18next="filesystem:READ"></th>
                                <th ng-i18next="EDIT"></th>
                                <th ng-i18next="calendar:INVITE"></th>
                                <th ng-i18next="calendar:MANAGE"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <md-checkbox class="condensed" ng-checked="$manageCal.isChecked('aclRead')" disabled></md-checkbox>
                                </td>
                                <td>
                                    <md-checkbox class="condensed"
                                                 ng-checked="$manageCal.isChecked('aclEdit')"
                                                 md-indeterminate="$manageCal.isIndeterminate('aclEdit')"
                                                 ng-click="$manageCal.toggleAll('aclEdit')">
                                    </md-checkbox>
                                </td>
                                <td>
                                    <md-checkbox class="condensed"
                                                 ng-checked="$manageCal.isChecked('aclInvite')"
                                                 md-indeterminate="$manageCal.isIndeterminate('aclInvite')"
                                                 ng-click="$manageCal.toggleAll('aclInvite')">
                                    </md-checkbox>
                                </td>
                                <td>
                                    <md-checkbox class="condensed"
                                                 ng-checked="$manageCal.isChecked('aclManage')"
                                                 md-indeterminate="$manageCal.isIndeterminate('aclManage')"
                                                 ng-click="$manageCal.toggleAll('aclManage')">
                                    </md-checkbox>
                                </td>
                            </tr>
                            <tr ng-repeat="controllable in $manageCal.controllables track by $index">
                                <td>
                                    <div class="controllable-name-container">
                                        <md-button class="md-icon-button" ng-click="$manageCal.removeControllable($index)">
                                            <md-tooltip md-direction="top" ng-i18next="REMOVE"></md-tooltip>
                                            <md-icon ng-bind="'remove'"></md-icon>
                                        </md-button>

                                        <span ng-bind="controllable.name"></span>
                                    </div>
                                </td>
                                <td>
                                    <md-checkbox class="condensed" ng-model="controllable.aclRead" disabled></md-checkbox>
                                </td>
                                <td>
                                    <md-checkbox class="condensed" ng-model="controllable.aclEdit" ng-change="$manageCal.updateAcl()"></md-checkbox>
                                </td>
                                <td>
                                    <md-checkbox class="condensed" ng-model="controllable.aclInvite" ng-change="$manageCal.updateAcl()"></md-checkbox>
                                </td>
                                <td>
                                    <md-checkbox class="condensed" ng-model="controllable.aclManage" ng-change="$manageCal.updateAcl()"></md-checkbox>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </panel-content>
                </eaw-accordion-panel>
            </eaw-accordion>

            <div id="create-container">
                <md-button ng-disabled="manageCalendarForm.$invalid"
                           class="md-raised md-accent"
                           form="manageCalendarForm"
                           ng-click="$manageCal.submit()">
                    <span ng-if="!$manageCal.calendar.key" ng-i18next="CREATE"></span>
                    <span ng-if="$manageCal.calendar.key" ng-i18next="UPDATE"></span>
                </md-button>

                <div ng-if="!$manageCal.calendar.key && $manageCal.processing">
                    <span id="create-step">{{$manageCal.step}} / {{$manageCal.steps}}</span>
                    <span id="create-step-action" ng-bind="$manageCal.stepAction"></span>
                </div>
            </div>
        </form>
    </md-card-content>
</md-card>
`,
    controllerAs: '$manageCal',
    require: {
        calview: '^eawCalendarView',
    },
    bindings: {
        calendar: '<?',
    },
    controller: [ 'Calendar', 'colorpickerService', '$state', 'EawResource', 'CalendarAclFactory', 'ToastService', function(Calendar, colorpickerService, $state, EawResource, CalendarAclFactory, ToastService) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.aclRead = true;
            ctrl.controllables = [];
            ctrl.calendar = ctrl.calendar instanceof Calendar ? ctrl.calendar : new Calendar({
                color: colorpickerService.getRandomHexColor(),
            }, CurrentOld.user.id);
            ctrl.handleAcl();
        };
        ctrl.handleAcl = async () => {
            if (!ctrl.calendar.key) {
                return;
            }
            ctrl.loadingAcl = true;
            const resp = await ctrl.calendar.getAcl();
            ctrl.loadingAcl = false;
            const uniqueEntries = new Set();
            resp.entries.forEach((e) => {
                uniqueEntries.add(e.acl_id);
            });
            Array.from(uniqueEntries).forEach((id) => {
                const controllables = resp.entries.filter((e) => e.acl_id === id);
                ctrl.controllables.push({
                    name: controllables[0].controllable.name,
                    aclRead: !!controllables.find((c) => c.action === 'read'),
                    aclEdit: !!controllables.find((c) => c.action === 'edit'),
                    aclInvite: !!controllables.find((c) => c.action === 'invite'),
                    aclManage: !!controllables.find((c) => c.action === 'manage'),
                });
            });
        };
        ctrl.removeControllable = (index) => {
            ctrl.controllables.splice(index, 1);
        };
        ctrl.toggleAll = (acl) => {
            const value = !ctrl.isChecked(acl) || ctrl.isIndeterminate(acl);
            ctrl.controllables.forEach((c) => {
                c[acl] = value;
            });
        };
        ctrl.isChecked = (acl) => ctrl.controllables.length && ctrl.controllables.every((c) => c[acl]);
        ctrl.isIndeterminate = (acl) => !ctrl.isChecked(acl) && ctrl.controllables.some((c) => c[acl]);
        ctrl.getControllables = (filter) => {
            const args = {
                filter: undefined,
                customer: ctrl.calview.customer.id,
            };
            if (filter) {
                args.filter = filter;
            }
            return EawResource.create(`/customers/:customer/calendars/acl_controllables`).get(args).$promise.then((resp) => [
                [ 'user', resp.user ],
                [ 'user_group', resp.user_group ],
            ].reduce((acc, morph) => {
                const items = morph[1].map((item) => {
                    item.morph = morph[0];
                    item.translatedMorph = t(item.morph.toUpperCase());
                    return item;
                });
                return acc.concat(items);
            }, []).filter((item) => !(item.morph === 'user' && item.id === ctrl.calview.user.id)));
        };
        ctrl.addControllable = (controllable) => {
            if (!controllable) {
                return;
            }
            const existing = ctrl.controllables.find((c) => c.id === controllable.id && c.morph === controllable.morph);
            if (!existing) {
                ctrl.controllables.push({
                    ...controllable,
                    aclRead: true,
                });
            }
            ctrl.controllable = null;
            // @ts-ignore
            document.activeElement?.blur();
        };
        ctrl.generateAclPermission = (controllable, calendarKey, action, allowed) => {
            if (!allowed) {
                return Promise.resolve();
            }
            return CalendarAclFactory.add(ctrl.calview.customer.id, calendarKey, controllable.morph, controllable.id, {
                action,
                allowed,
            });
        };
        ctrl.back = () => {
            $state.transitionTo('eaw/app/calendar2');
        };
        ctrl.submit = () => {
            if (ctrl.calendar.key) {
                ctrl.update();
            } else {
                ctrl.create();
            }
        };
        ctrl.update = async () => {
            ctrl.processing = true;
            try {
                await ctrl.calendar.update();
                ToastService.tToast('calendar:CALENDAR_UDPATED');
                ctrl.back();
            } catch (_) {
                ctrl.processing = false;
            }
        };
        ctrl.create = async () => {
            ctrl.steps = ctrl.controllables.length + 1; // +1 for calendar
            ctrl.step = 1;
            ctrl.stepAction = t('calendar:CREATING_CAL');
            ctrl.processing = true;
            let calendar;
            try {
                calendar = await Calendar.create({
                    customer: ctrl.calview.customer.id,
                    name: ctrl.calendar.name,
                    color: ctrl.calendar.color,
                    subscribe: true,
                });
            } catch (e) {
                console.error(e);
                ctrl.processing = false;
                return;
            }
            for (let i = 0; i < ctrl.controllables.length; i++) {
                const c = ctrl.controllables[i];
                ctrl.step++;
                ctrl.stepAction = t('calendar:ADD_ACCESS_FOR', {
                    name: c.name,
                });
                try {
                    await Promise.all([
                        ctrl.generateAclPermission(c, calendar.key, 'read', c.aclRead),
                        ctrl.generateAclPermission(c, calendar.key, 'edit', c.aclEdit),
                        ctrl.generateAclPermission(c, calendar.key, 'invite', c.aclInvite),
                        ctrl.generateAclPermission(c, calendar.key, 'manage', c.aclManage),
                    ]);
                } catch (_) {
                    // Do nothing
                }
            }
            ToastService.tToast('calendar:CALENDAR_CREATED');
            ctrl.calview.addCalendar(calendar);
            ctrl.back();
        };
    } ],
});
