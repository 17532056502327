import { module } from 'angular';
import { Products } from '../../shared/enums/products';
import { Namespace } from '../../shared/enums/namespace';
import { CurrentAvailabilityComponent } from '../pages/my-current/current-availability.component';
import { AvailabilitiesListComponent } from '../pages/my-list/availabilities-list.component';
import { createNavTabsState, createState } from '../../shared/utils/create-state';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { OverrideAvailabilityComponent } from '../pages/override-availability/override-availability.component';
import { AvailabilityOverviewComponent } from '../pages/overview-tab/availability-overview.component';
import { NavTabsComponent } from '../../shared/components/nav-tabs/nav-tabs.component';
import { navTabsTabsResolver } from '../../shared/resolvers/nav-tabs-tabs.resolver';
import { CurrentOld } from '../../shared/angularjs/current.factory';
import { LearningModuleModule } from '../../learning-module/types/modules';
import { DataTableQueryParams } from '../../data-table/data-table.component';
import { HrDefaultFilesComponent } from '../../hr/pages/hr-default-files/hr-default-files.component';

module('eaw.availability', [
    'eaw.resource',
    'eaw.login',
    'eaw.alerts',
    'eaw.employees',
]).config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: `eaw/app/availability`,
        parent: 'eaw/app',
        url: '/availability',
        abstract: true,
        data: {
            learningModuleModules: [ LearningModuleModule.Availability ],
            products: [ Products.Availability ],
            i18nextNs: [ Namespace.Availabilities ],
            breadcrumb: { key: 'AVAILABILITY', ns: 'navigation' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/availability/my`,
        parent: `eaw/app/availability`,
        url: '/my',
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        data: {
            permissions: [
                `customers.{customer}.employees.{employee}.availabilities.create`,
            ],
            breadcrumb: { key: 'MY_AVAILABILITY', ns: 'navigation' },
        },
        resolve: [
            currentResolver('customerId', 'employee', 'customerId'),
            currentResolver('employeeId', 'employee', 'id'),
            navTabsTabsResolver(function() {
                return Promise.resolve([
                    {
                        state: 'eaw/app/availability/my/current',
                        label: { key: 'CURRENT_AVAILABILITY', ns: Namespace.Availabilities },
                    },
                    {
                        state: 'eaw/app/availability/my/list',
                        label: { key: 'LIST' },
                    },
                ]);
            }),
        ],
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/availability/my/current`,
        parent: `eaw/app/availability/my`,
        url: '/current',
        component: CurrentAvailabilityComponent,
        data: {
            breadcrumb: { key: 'CURRENT_AVAILABILITY', ns: Namespace.Availabilities },
            permissions: [ `customers.{customer}.employees.{employee}.availabilities.create` ],
            allowExternal: true,
            requiresEmployee: true,
        },
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/availability/my/list`,
        parent: `eaw/app/availability/my`,
        url: '/list',
        component: AvailabilitiesListComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'LIST' },
        },
    });

    $stateProvider.state(`eaw/app/availability/manage`, {
        parent: `eaw/app/availability`,
        url: '/manage',
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            breadcrumb: { key: 'MANAGE_AVAILABILITY', ns: 'navigation' },
            permissions: [
                `customers.{customer}.employees.*.availabilities.overview.get`,
            ],
        },
        resolve: {
            usesFranceCombinedContract: [ 'EawResource', function(EawResource: any) {
                return EawResource.create(`/customers/${CurrentOld.customer['id']}/settings/france.use_combined_contract`).get().$promise.then((result: any) => {
                    return result['france.use_combined_contract'] === '1';
                });
            } ],
            navbarRoute: () => `eaw/app/availability/manage`,
            tabs: [ 'usesFranceCombinedContract', function(usesFranceCombinedContract: any) {
                const tabs = [
                    {
                        label: 'OVERVIEW',
                        src: `eaw/app/availability/overview`,
                        permission: `customers.${CurrentOld.customer['id']}.employees.*.availabilities.overview.get`,
                    }, {
                        label: 'MANAGE',
                        src: `eaw/app/availability/handle`,
                        permission: `customers.${CurrentOld.customer['id']}.employees.*.availabilities.*.update`,
                    },
                ];

                if (!usesFranceCombinedContract) {
                    tabs.push({
                        label: 'OVERRIDE',
                        src: `eaw/app/availability/override`,
                        permission: `customers.${CurrentOld.customer['id']}.employees.*.availabilities.create`,
                    });
                }

                return tabs;
            } ],
        },
    });

    createState($stateProvider, {
        name: `eaw/app/availability/overview`,
        parent: `eaw/app/availability/manage`,
        url: '/overview',
        data: {
            queryParams: [ 'from', 'weeks' ],
            breadcrumb: { key: 'OVERVIEW' },
            permissions: [ `customers.{customer}.availabilities.*.get` ],
        },
        component: AvailabilityOverviewComponent,
    });

    $stateProvider.state(`eaw/app/availability/handle`, {
        parent: `eaw/app/availability/manage`,
        url: '/approve?e?outdated?mode',
        component: 'availabilityManageTab',
        data: {
            permissions: [ `customers.{customer}.employees.{employee}.availabilities.*.update` ],
        },
        params: {
            e: {
                type: 'int',
                dynamic: true,
                squash: true,
            },
            outdated: {
                type: 'bool',
                dynamic: true,
                squash: true,
            },
            mode: {
                type: 'string',
                dynamic: true,
                squash: true,
            },
        },
    });

    $stateProvider.state(`eaw/app/availability/override`, {
        parent: `eaw/app/availability/manage`,
        url: '/override',
        component: OverrideAvailabilityComponent,
        data: {
            permissions: [ `customers.{customer}.employees.*.availabilities.create` ],
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
    });
} ]);
