// @ts-nocheck
import { module } from 'angular';
import { CustomFieldOld } from '../../../custom-field-old';
import { PaginationOld } from '../../../../utils/pagination-old';
/**
 * @deprecated - Use {@link CustomFieldsService} instead
 */
export default class CustomFieldService {
    EawResource;
    static get $inject() {
        return [ 'EawResource' ];
    }

    url = '/:group/:groupId/custom_fields/:field/:properties/:property';
    url2 = '/setting_groups/:groupId/custom_fields/:fieldId/:pivotId';
    constructor(EawResource) {
        this.EawResource = EawResource;
    }

    // Get all
    getAll(args) {
        return this.EawResource.create(this.url).get(args).$promise.then((resp) => {
            resp.data = resp.data.map((cf) => new CustomFieldOld(cf));
            return resp;
        });
    }

    /**
     * @param id
     */
    getById(id) {
        return this.EawResource.create(this.url).get({
            field: id,
        }).$promise.then((cf) => new CustomFieldOld(cf));
    }

    /**
     * @param args
     */
    create(args) {
        return this.EawResource.create(this.url).save(args).$promise.then((cf) => new CustomFieldOld(cf));
    }

    deleteField(field) {
        return this.EawResource.create(this.url).delete({
            field: field.id,
        }).$promise;
    }

    /**
     * @param customField an object with an id and
     * name, type and or key attribute, for updating a custom field.
     * @returns {*|{method}}
     */
    updateById(customField) {
        return this.EawResource.create(this.url).update({
            field: customField.id,
        }, customField).$promise.then((resp) => new CustomFieldOld(resp));
    }

    getSettingGroupFieldsPaginated(args) {
        return this.EawResource.create(this.url2).get({
            groupId: args.setting_group.id,
            ...PaginationOld.getParams(args),
        }).$promise.then((resp) => {
            resp.data = resp.data.map((cf) => new CustomFieldOld(cf));
            return resp;
        });
    }

    attachFieldToGroup(groupId, args) {
        return this.EawResource.create(this.url2).save({
            groupId,
        }, {
            model: args.model,
            custom_field_id: args.customFieldId,
            required: args.required,
            has_interval: args.hasInterval,
            metadata: args.metadata,
            validator: args.validator,
        }).$promise.then((cf) => new CustomFieldOld(cf));
    }

    updateGroupField(groupId, fieldId, pivotId, updates) {
        return this.EawResource.create(this.url2).update({
            groupId,
            fieldId,
            pivotId,
        }, updates).$promise.then((cf) => new CustomFieldOld(cf));
    }

    removeFieldFromGroup(group, field, pivotId) {
        return this.EawResource.create(this.url2).delete({
            groupId: group.id,
            fieldId: field.id,
            pivotId,
        }).$promise;
    }
}
module('eaw.misc').factory('CustomFieldService', CustomFieldService);
