// @ts-nocheck
import { module } from 'angular';
const template = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="admin:PAY_TYPES"></span>
        </md-card-header-text>

        <card-btn-float on-click="ptctrl.addPayType()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="ptctrl.table.columns"
                cells="ptctrl.table.cells"
                factory="PayTypeFactory"
                factory-function="getAll"
                reload-table="ptctrl.reloadTable">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;
module('eaw.admin').config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state('eaw/app/admin/pay_types', {
        parent: 'eaw/app/admin',
        url: '/pay_types',
        views: {
            'content@': {
                template,
                controller: 'payTypeCtrl',
                controllerAs: 'ptctrl',
            },
        },
        data: {
            requireLogin: true,
            breadcrumb: { key: 'PAY_TYPE_plural', ns: 'admin' },
            permissions: [ `pay_types.*.get` ],
        },
    });
} ]);
