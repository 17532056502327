import { Component, HostBinding, inject, OnInit, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { UserService } from '../../../shared/http/user.service';
import { User } from '../../../shared/models/user';
import { debounceTime, of, tap } from 'rxjs';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DateTime } from 'luxon';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { createExportHeaderButton } from '../../../shared/dialogs/export-dialog/export-dialog.component';
import { MeService } from '../../../shared/http/me.service';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { Namespace } from '../../../shared/enums/namespace';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '../../../shared/services/translate.service';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { AdminCreateUserDialogComponent, AdminCreateUserDialogComponentData, AdminCreateUserDialogComponentReturn } from '../../dialogs/admin-create-user-dialog/admin-create-user-dialog.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

@Component({
    selector: 'eaw-users-list',
    templateUrl: './users-list.component.html',
    styleUrl: './users-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
        TranslateSyncPipe,
    ],
})
export class UsersListComponent implements OnInit, EawDataTable {
    private readonly meService = inject(MeService);
    private readonly userService = inject(UserService);
    private readonly searchParams = inject(QueryParamsService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly matDialog = inject(MatDialog);
    private readonly translateService = inject(TranslateService);

    @HostBinding('class') classes = 'flex-contained';
    @ViewChild(DataTableComponent) dataTable?: DataTableComponent<User>;

    pageButtons: PageHeaderButton[] = [
        createExportHeaderButton(() => this.dataTable),
    ];

    total = 0;
    previousFields: string[] = [];
    fields = [
        {
            name: 'FULL_NAME',
            value: 'name',
        },
        {
            name: 'FIRST_NAME',
            value: 'first_name',
        },
        {
            name: 'LAST_NAME',
            value: 'last_name',
        },
        {
            name: 'EMAIL',
            value: 'email',
        },
        {
            name: 'PHONE',
            value: 'phone',
        },
    ];

    form = new FormGroup({
        filter: new FormControl<string | null>(null),
        fields: new FormControl(this.fields.map((f) => f.value)),
    });

    fabButton: HeaderFabButton = {
        click: this.openCreateDialog.bind(this),
        hasPermission: () => this.permissionCheckService.isAllowed(`users.create`),
    };

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<User>[] = [
        new DataTableNumberColumn({
            value: 'id',
            header: new DataTableHeader({
                text: 'Id',
                sortBy: 'id',
            }),
        }),
        new DataTableTextColumn({
            value: 'firstName',
            header: new DataTableHeader({
                i18n: 'FIRST_NAME',
                sortBy: 'first_name',
            }),
        }),
        new DataTableTextColumn({
            value: 'lastName',
            header: new DataTableHeader({
                i18n: 'LAST_NAME',
                sortBy: 'last_name',
            }),
        }),
        new DataTableTextColumn({
            value: 'email',
            header: new DataTableHeader({
                i18n: 'EMAIL',
                sortBy: 'email',
            }),
        }),
        new DataTableTextColumn({
            value: 'phone',
            header: new DataTableHeader({
                i18n: 'PHONE',
                sortBy: 'phone',
            }),
        }),
        new DataTableTextColumn({
            key: 'lastActive',
            value: (cell) => cell.item.lastActive?.toLocaleString(DateTime.DATETIME_MED) || '',
            header: new DataTableHeader({
                i18n: 'LAST_ACTIVE',
                sortBy: 'last_active',
            }),
        }),
        new DataTableNumberColumn({
            key: 'propertiesCount',
            value: 'propertiesCount',
            header: new DataTableHeader({
                i18n: 'PROPERTY_plural',
                sortBy: 'properties_count',
            }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'swap_horiz',
                    click: (cell) => this.meService.act(cell.item.id),
                    show: () => of(true),
                    tooltip: { key: 'ACT_AS', ns: 'admin' },
                },
                {
                    icon: 'delete',
                    click: (cell) => this.delete(cell.item.id),
                    show: (user) => this.permissionCheckService.isAllowed(`users.${user.id}.delete`),
                    tooltip: { key: 'DELETE' },
                    type: 'warn',
                },
            ],
        }),
    ];

    ngOnInit(): void {
        this.form.controls.filter.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
            this.dataTable?.refresh();
        });

        const filter = this.searchParams.get('filter', 'string');
        if (filter) {
            this.form.controls.filter.setValue(filter);
        }

        this.form.controls.fields.setValue(this.searchParams.get('fields', 'array') || []);
    }

    openCreateDialog() {
        this.matDialog.open<AdminCreateUserDialogComponent, AdminCreateUserDialogComponentData, AdminCreateUserDialogComponentReturn>(AdminCreateUserDialogComponent, {
            data: {
                mode: 'create',
            },
        }).afterClosed().subscribe((user) => {
            if (user) {
                this.dataTable?.refresh();
            }
        });
    }

    delete(userId: number) {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translateService.t('DELETE_USER', Namespace.Admin),
                text: this.translateService.t('DELETE_USER_TEXT', Namespace.Admin),
                confirmText: this.translateService.t('DELETE'),
                confirmColor: 'red-500',
                size: DialogSize.Medium,
            },
        }).afterClosed().subscribe((result) => {
            if (result?.ok) {
                this.userService.delete(userId).subscribe(() => {
                    this.dataTable?.refresh();
                });
            }
        });
    }

    selectClose() {
        const newFields: string[] = this.form.controls.fields.value || [];

        // Only update table if fields are different
        if (newFields.length === this.previousFields.length && newFields.every((f) => this.previousFields.includes(f))) {
            return;
        }

        this.dataTable?.refresh();
    }

    updateTable(pagination: Partial<DataTablePagination> = {}): void {
        this.previousFields = this.form.controls.fields.value || [];

        this.searchParams.set([
            {
                key: 'filter',
                value: this.form.controls.filter.value,
            },
            {
                key: 'fields',
                value: this.form.controls.fields.value,
            },
        ]);

        this.request = this.userService.getAll({
            ...pagination,
            filter: this.form.controls.filter.value || undefined,
            'fields[]': this.previousFields,
            'count[]': [ 'properties' ],
        }).pipe(
            tap((response) => (this.total = response.total)),
        );
    }
}
