// @ts-nocheck
import { module } from 'angular';
module('eaw.calendar2').factory('CalendarAclFactory', [ 'EawResource', function CalendarAclFactory(EawResource) {
    const res = EawResource.create('/customers/:customer/calendars/:calendar/acl_controllables/:morph/:key');
    this.add = (customerId, calendarKey, morph, key, args) => {
        return res.save({
            customer: customerId,
            calendar: calendarKey,
            morph,
            key,
        }, {
            action: args.action,
            allowed: args.allowed,
        }).$promise;
    };
    return this;
} ]);
