import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, Input, OnInit, signal } from '@angular/core';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { InfoBoxComponent } from '../../../shared/components/info-card/info-box.component';
import { AsyncPipe } from '@angular/common';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { VlhService } from '../../http/vlh.service';
import { DateTime, Info } from 'luxon';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatRipple } from '@angular/material/core';
import { AbstractControl, FormControl, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { timeStringToHourMin } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { catchError, EMPTY } from 'rxjs';
import { VlhPeriodResponse } from '../../models/vlh-period';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { Namespace } from '../../../shared/enums/namespace';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { TimeObject } from '../../../shared/components/date-time/time-input/time-input.component';

type DayPeriod = {from: FormControl<string | null>, to: FormControl<string | null>};

interface DayItem {
    day: string,
    periods: DayPeriod[]
}

@Component({
    selector: 'eaw-company-vlh',
    standalone: true,
    imports: [
        PageHeaderComponent,
        InfoBoxComponent,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        MatCard,
        MatCardContent,
        MatButton,
        MatIcon,
        MatIconButton,
        MatIconSizeDirective,
        MaterialColorDirective,
        MatRipple,
        ReactiveFormsModule,
        InfoLoadingComponent,
    ],
    templateUrl: './company-vlh.component.html',
    styleUrl: './company-vlh.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyVlhComponent implements OnInit {
    private vlhService = inject(VlhService);
    private destroyRef = inject(DestroyRef);
    private snackBarService = inject(SnackBarService);

    @Input({ required: true }) customerId!: number;

    days = signal<DayItem[]>([]);
    saving = signal(false);
    loading = signal(false);
    focusedValue = signal<{from: TimeObject | undefined, to: TimeObject | undefined}>({ from: undefined, to: undefined });

    constructor() {
        effect(() => {
            const saving = this.saving();
            this.days().forEach((day) => {
                day.periods.forEach((period) => {
                    if (saving) {
                        period.from.disable();
                        period.to.disable();
                    } else {
                        period.from.enable();
                        period.to.enable();
                    }
                });
            });
        });
    }

    ngOnInit() {
        this.getDays();
    }

    getDays() {
        this.loading.set(true);
        this.days.set([]);

        this.vlhService.getAllPeriods(this.customerId).pipe(
            takeUntilDestroyed(this.destroyRef),
            catchError(() => {
                void this.snackBarService.t('VLH_LOAD_ERROR', Namespace.Company);
                this.loading.set(false);
                return EMPTY;
            }),
        ).subscribe((periods) => {
            Info.weekdays('long').forEach((day, index) => {
                const periodsForDay = periods.filter((period) => period.day === index + 1);
                this.days.update((days) => {
                    days.push({
                        day,
                        periods: periodsForDay.map((period) => {
                            return {
                                from: this.createPeriodControl(period.from.toFormat('HH:mm')),
                                to: this.createPeriodControl(period.to.toFormat('HH:mm')),
                            };
                        }),
                    });

                    return [ ...days ];
                });
            });

            this.loading.set(false);
        });
    }

    createPeriodControl(value: string) {
        const control = new FormControl(value, {
            validators: [ this.timeValidator() ],
            updateOn: 'blur',
        });

        control.valueChanges.subscribe((value) => {
            const hourMin = timeStringToHourMin(value);
            if (hourMin) {
                control.setValue(DateTime.fromObject({ hour: hourMin.hour, minute: hourMin.minute }).toFormat('HH:mm'), { emitEvent: false });
            }
        });

        return control;
    }

    addPeriod(dayIndex: number) {
        this.days.update((days) => {
            days[dayIndex]?.periods.push({
                from: this.createPeriodControl(''),
                to: this.createPeriodControl(''),
            });

            return [ ...days ];
        });
    }

    removePeriod(dayIndex: number, periodIndex: number) {
        let shouldSave = false;

        this.days.update((days) => {
            const result = days[dayIndex]?.periods.splice(periodIndex, 1)?.[0];
            if (timeStringToHourMin(result?.from.value) || timeStringToHourMin(result?.to.value)) {
                shouldSave = true;
            }

            return [ ...days ];
        });

        if (shouldSave) {
            this.save();
        }
    }

    blur(period: DayPeriod) {
        const { from, to } = this.focusedValue();
        const periodFrom = timeStringToHourMin(period.from.value);
        const periodTo = timeStringToHourMin(period.to.value);

        if (periodFrom && periodTo) {
            if (periodFrom.hour !== from?.hour || periodFrom.minute !== from?.minute || periodTo.hour !== to?.hour || periodTo.minute !== to?.minute) {
                this.save();
            }
        }
    }

    focus(event: FocusEvent, period: DayPeriod) {
        this.focusedValue.set({
            from: timeStringToHourMin(period.from.value),
            to: timeStringToHourMin(period.to.value),
        });

        if (event.target instanceof HTMLInputElement) {
            event.target.select();
        }
    }

    save() {
        const post = this.days().map((day, index) => {
            return {
                day: index + 1,
                periods: day.periods.reduce<VlhPeriodResponse[]>((acc, period) => {
                    const from = this.getPostTime(period.from.value);
                    const to = this.getPostTime(period.to.value);

                    if (from && to) {
                        acc.push([ from, to ]);
                    }

                    return acc;
                }, []),
            };
        });

        this.saving.set(true);
        this.vlhService.savePeriods(this.customerId, post).pipe(
            catchError(() => {
                void this.snackBarService.t('VLH_SAVE_ERROR', Namespace.Company);
                this.saving.set(false);
                return EMPTY;
            }),
        ).subscribe(() => {
            void this.snackBarService.t('VLH_SAVED', Namespace.Company);
            this.saving.set(false);
        });
    }

    private getPostTime(time: string | null) {
        const hourMin = timeStringToHourMin(time);
        return hourMin ? DateTime.fromObject({ hour: hourMin.hour, minute: hourMin.minute }).toFormat('HH:mm') : undefined;
    }

    private timeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value === null) {
                return null;
            }

            if (typeof control.value === 'string') {
                if (!control.value.length) {
                    return null;
                } else {
                    const hourMin = timeStringToHourMin(control.value);

                    if (hourMin) {
                        return null;
                    } else {
                        return { invalid_format: { value: control.value } };
                    }
                }
            }

            return { invalid_format: { value: control.value } };
        };
    }
}
