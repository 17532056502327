// @ts-nocheck
import { module } from 'angular';

module('eaw.filesystem').component('eawProgressCircle', {
    bindings: {
        color: '<?',
        diameter: '<?',
        strokeWidth: '<?',
        progress: '<',
    },
    controller: [ '$element', '$mdColors', function($element, $mdColors) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.strokeWidth = ctrl.strokeWidth || 4;
            ctrl.color = ctrl.color || $mdColors.getThemeColor('accent-500');
            ctrl.diameter = ctrl.diameter || 24;
            ctrl.radius = ctrl.diameter / 2;
            ctrl.circumference = 2 * Math.PI * ctrl.radius;
            ctrl.r = ctrl.radius - (ctrl.strokeWidth / 2);

            ctrl.svg();
            ctrl.elementSize();
            ctrl.updateProgress(0);
        };

        ctrl.elementSize = () => {
            const px = `${ctrl.diameter}px`;

            $element[0].style.minHeight = px;
            $element[0].style.maxHeight = px;
        };

        ctrl.svg = () => {
            $element[0].innerHTML = `
                <svg class="eaw-progress" style="width: ${ctrl.diameter}; height: ${ctrl.diameter}" width="${ctrl.diameter}" height="${ctrl.diameter}" viewBox="0 0 ${ctrl.diameter} ${ctrl.diameter}">
                    <circle class="eaw-progress-meter" cx="${ctrl.radius}" cy="${ctrl.radius}" r="${ctrl.r}" stroke-width="${ctrl.strokeWidth}"></circle>
                    <circle class="eaw-progress-value" style="stroke: ${ctrl.color}; stroke-dasharray: ${ctrl.circumference}" cx="${ctrl.radius}" cy="${ctrl.radius}" r="${ctrl.r}" stroke-width="${ctrl.strokeWidth}"></circle>
                </svg>
            `;
        };

        ctrl.updateProgress = (value) => {
            ctrl.progress = ctrl.progress || 0;
            value = Math.max(value, ctrl.progress);

            const valueEl = $element[0].querySelector('.eaw-progress-value');
            if (valueEl) {
                ctrl.dashoffset = ctrl.circumference * (1 - (value / 100));
                valueEl.style.strokeDashoffset = ctrl.dashoffset;
                ctrl.progress = value;
            }
        };

        ctrl.$onChanges = (changes) => {
            if (changes.progress) {
                ctrl.updateProgress(changes.progress.currentValue);
            }
        };
    } ],
});
