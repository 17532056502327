// @ts-nocheck
import { forEach } from 'lodash-es';
import { module } from 'angular';
module('eaw.components').component('eawObjectCreator', {
    template: `<label class="tw-mb-16" ng-class="{'required': $ctrl.required}" ng-bind="$ctrl.label"></label>

<div class="property" ng-repeat="property in $ctrl.properties" ng-class="{'active': property.active}">
    <md-input-container md-no-float>
        <input ng-model="property.key"
               uniq-form-name
               ng-required="$first && $ctrl.required"
               placeholder="{{$ctrl.placeholders[0]}}"
               ng-focus="$ctrl.onFocus(property, $last)"
               ng-blur="$ctrl.onBlur(property, $index)"
               ng-keyup="$ctrl.generate()">
    </md-input-container>

    <md-icon ng-bind="'arrow_right'" ng-if="!$ctrl.createArray"></md-icon>

    <md-input-container md-no-float ng-if="!$ctrl.createArray">
        <input ng-model="property.value"
               uniq-form-name
               ng-required="$first && $ctrl.required"
               placeholder="{{$ctrl.placeholders[1]}}"
               ng-focus="$ctrl.onFocus(property, $last)"
               ng-blur="$ctrl.onBlur(property, $index)"
               ng-keyup="$ctrl.generate()">
    </md-input-container>
</div>
`,
    require: {
        ngModel: 'ngModel',
    },
    bindings: {
        label: '<',
        type: '@?',
        placeholders: '<?', // Array with 2 strings
    },
    controller: [ '$attrs', function($attrs) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.createArray = ctrl.type === 'array';
            ctrl.properties = [
                {
                    key: null,
                    value: null,
                },
            ];
            ctrl.ngModel.$formatters.unshift(ctrl.modelFormatter);
            $attrs.$observe('required', function(value) {
                ctrl.required = value;
            });
        };
        ctrl.modelFormatter = (val) => {
            ctrl.properties = [];
            forEach(val, (value, key) => {
                ctrl.properties.push({
                    key,
                    value,
                });
            });
            ctrl.properties.push({
                key: null,
                value: null,
            });
        };
        ctrl.onFocus = (property, last) => {
            property.active = true;
            if (last) {
                ctrl.properties.push({
                    key: null,
                    value: null,
                });
            }
        };
        ctrl.onBlur = (property, index) => {
            property.active = false;
            const secondToLast = index + 1 === ctrl.properties.length - 1;
            if (!property.key && !property.value && secondToLast) {
                ctrl.properties.pop();
            } else if ((!property.key && !property.value)) {
                ctrl.properties.splice(index, 1);
            }
            ctrl.generate();
        };
        ctrl.generateObject = (properties) => {
            const obj = {};
            Object.entries(properties).forEach((entry) => {
                if (!(!Object.keys(entry[1].key || []).length || !Object.keys(entry[1].value || []).length)) {
                    obj[entry[1].key] = entry[1].value;
                }
            });
            return !Object.keys(obj || []).length ? undefined : obj;
        };
        ctrl.generateArray = (properties) => {
            const arr = Object.values(properties).map((p) => p.key).filter((p) => Object.keys(p || []).length);
            return arr.length ? arr : undefined;
        };
        ctrl.generate = () => {
            let item;
            if (ctrl.createArray) {
                item = ctrl.generateArray(ctrl.properties);
            } else {
                item = ctrl.generateObject(ctrl.properties);
            }
            ctrl.ngModel.setViewValue(item);
        };
    } ],
});
