import { DateTime } from 'luxon';
import { AggregatedResponseRow } from '../http/timepunch-summary.service';
import { AggregateTimepunchInfoService } from '../services/aggregate-timepunch-info.service';
import { AggregateTimepunchValues } from '../types/aggregate-timepunch-values';
import { AggregateTimepunchData } from '../types/aggregate-timepunch-data';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { BusinessDate } from '../../shared/utils/business-date';

interface RowValue {
    value: string | number | undefined;
    pureValue: string | number | undefined;
}

export class AggregatedTimepunchRow {
    from?: DateTime;
    to?: DateTime;
    ids: number[];
    items: number;
    employeeId?: number;
    approvable: boolean;
    deleted?: number;
    progress?: number;
    progress_count?: number;
    values: Partial<Record<AggregateTimepunchValues, AggregateTimepunchData & RowValue>> = {};
    businessDate?: BusinessDate;
    balances?: Record<string, number>;
    fromToSame: boolean;

    private _canDelete = false;
    private _canUpdate = false;

    constructor(row: AggregatedResponseRow, aggregateTimepunchInfoService: AggregateTimepunchInfoService, hasScheduling: boolean) {
        this.ids = row.ids || [];
        this.items = this.ids.length;
        this.employeeId = row.employee_id;
        this.from = row.from ? stringToDateTime(row.from) : undefined;
        this.to = row.to ? stringToDateTime(row.to) : undefined;
        this.fromToSame = this.from && this.to ? this.from.hasSame(this.to, 'day') : false;
        this.progress = row.progress;
        this.progress_count = row.progress_count;
        this.approvable = row['approved_count'] < this.items && !row.trashed_count;

        this.assignValues(row, aggregateTimepunchInfoService, hasScheduling);

        if (row.business_date) {
            this.businessDate = new BusinessDate(row.business_date);
        }

        if (row.trashed_count) {
            this.deleted = row.trashed_count;
        }

        this.balances = row.balances;
    }

    get canDelete() {
        return this._canDelete;
    }

    get canUpdate() {
        return this._canUpdate;
    }

    setCanUpdate(value: boolean) {
        this._canUpdate = value;
    }

    setCanDelete(value: boolean) {
        this._canDelete = value;
    }

    private assignValues(row: AggregatedResponseRow, columnDefinitions: AggregateTimepunchInfoService, hasScheduling: boolean) {
        const items = columnDefinitions.getItems(hasScheduling);

        Object.entries(row).forEach(([ key, value ]) => {
            const itemKey = key as AggregateTimepunchValues;

            if (items[itemKey]) {
                const type = items[itemKey]?.type;
                const bd = type === 'businessDate';
                let formatted: string | undefined = value;
                let pureValue: string | number | undefined = value;

                if (bd || type === 'date') {
                    formatted = stringToDateTime(value, bd).toLocaleString(DateTime.DATE_MED);
                    pureValue = stringToDateTime(value, bd).toUnixInteger();
                } else if (type === 'datetime') {
                    formatted = stringToDateTime(value).toLocaleString(DateTime.DATETIME_MED);
                    pureValue = stringToDateTime(value).toUnixInteger();
                } else if (type === 'time') {
                    formatted = stringToDateTime(value).toLocaleString(DateTime.TIME_SIMPLE);
                    pureValue = stringToDateTime(value).toUnixInteger();
                }

                const colItem = items[itemKey];
                if (colItem) {
                    this.values[itemKey] = {
                        ...colItem,
                        value: formatted,
                        pureValue,
                    };
                }
            }
        });

        if (Number.isFinite(row['length']) && Number.isFinite(row['shifts_length'])) {
            const diffItem = items['diff'];
            if (diffItem) {
                this.values['diff'] = {
                    pureValue: row['length'] - row['shifts_length'],
                    ...diffItem,
                    value: row['length'] - row['shifts_length'],
                };
            }
        }
    }
}
