import { DateTime } from 'luxon';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { BaseApiModel } from '../../shared/models/base-api-model';

export type RateType = 'hour' | 'day' | 'week' | 'biweekly' | 'month' | 'year' | 'quarter';

export interface RateResponse extends ApiResponse {
    id: number;
    from: string;
    to: string | null;
    rate: number;
    type: RateType;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export abstract class Rate extends BaseApiModel<RateResponse, Rate> {
    id: number;
    from: DateTime;
    to: DateTime | null;
    rate: number;
    type: RateType;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;

    protected constructor(data: RateResponse) {
        super(data, undefined);

        this.id = data.id;
        this.from = stringToDateTime(data.from);
        this.to = data.to ? stringToDateTime(data.to) : null;
        this.rate = data.rate;
        this.type = data.type;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
