<eaw-dialog-header>
    <span title>{{'ADD_NOTE' | translate: 'company' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{'NOTE' | translate : 'general' | async}}</mat-label>
            <textarea formControlName="note" matInput rows="2"></textarea>
        </mat-form-field>

        <mat-checkbox formControlName="addToHr" *ngIf="data.showHrOption" eawCheckboxHelper>
            <span>{{'ADD_NOTE_HR' | translate: 'company' | async}}</span>
        </mat-checkbox>

        <eaw-basic-autocomplete [label]="'FILE_TYPE' | translate: 'hr'"
                                *ngIf="form.value.addToHr"
                                formControlName="hr"
                                [getList]="getHrFileTypes.bind(this)">
        </eaw-basic-autocomplete>

        <mat-card *ngIf="form.value.addToHr" class="hr-warning">
            <mat-card-content>
                <span>{{'NOTE_ADD_HR_WARN' | translate: 'company' | async}}</span>
            </mat-card-content>
        </mat-card>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close [matDialogClose]="false">{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="saving" [disabled]="form.invalid" (click)="save()">{{'ADD_NOTE' | translate: 'company' | async}}</eaw-action-button>
</mat-dialog-actions>
