import { module } from 'angular';
import { EmployeeBalancesComponent } from '../pages/employee-balances/employee-balances.component';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { createState } from '../../shared/utils/create-state';
import { CompanyBalancesComponent } from '../../company/pages/company-balances/company-balances.component';
import { myBalancesAccessPermissionCheck } from '../functions/my-balances-access-permission-check';
import { CurrentService } from '../../shared/services/current.service';

module('eaw.balances', []).config([ '$stateProvider', function($stateProvider: any) {
    createState($stateProvider, {
        name: `eaw/app/company/balances`,
        parent: `eaw/app/company`,
        url: '/balances',
        views: {
            'content@': {
                component: CompanyBalancesComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            breadcrumb: { key: 'BALANCES', ns: 'navigation' },
            permissions: [
                'customers.{customer}.balance_types.*.get',
            ],
            somePermissions: [
                'customers.{customer}.employees.*.balances.*.*.get',
                'customers.{customer}.employee_balances.manage',
            ],
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/balances',
        parent: 'eaw/app',
        url: '/my_balances',
        views: {
            'content@': {
                component: EmployeeBalancesComponent,
            },
        },
        data: {
            breadcrumb: { key: 'MY_BALANCES' },
            requiresEmployee: true,
            permissionCheck: (permissionCheckService, injector) => {
                const customerId = injector.get(CurrentService).getCustomer().id;
                const employeeId = injector.get(CurrentService).getEmployee()?.id;

                return myBalancesAccessPermissionCheck(permissionCheckService, customerId, employeeId);
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('employeeId', 'employee', 'id'),
        ],
    });
} ]);
