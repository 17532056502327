// @ts-nocheck
import { module } from 'angular';

module('eaw.decorators').config([ '$provide', function($provide) {
    $provide.decorator('mdButtonDirective', [ '$delegate', function($delegate) {
        const directive = $delegate[0];
        const link = directive.link;

        directive.compile = function() {
            return function($scope, $element) {
                const el = $element[0];

                el.setAttribute('aria-label', 'button');

                // eslint-disable-next-line prefer-rest-params
                link.apply(this, arguments);
            };
        };

        return $delegate;
    } ]);
} ]);
