import { module } from 'angular';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { createState } from '../../utils/create-state';
import { resolver } from '../../resolvers/resolver';
import { NoAccessComponent } from '../../components/no-access/no-access.component';
import { TasksComponent } from '../../../tasks/pages/tasks/tasks.component';
import { SystemAlertsComponent } from '../../components/system-alerts/system-alerts.component';
import { HeaderComponent } from '../../components/header/header.component';
import { SidebarComponent } from '../../../main-menu/sidebar/sidebar.component';
import { LearningModuleSidebarComponent } from '../../../learning-module/components/learning-module-sidebar/learning-module-sidebar.component';
import { CurrentOld } from '../current.factory';

module('eaw').config([ '$stateProvider', '$urlRouterProvider', function config($stateProvider: any, $urlRouterProvider: any) {
    createState($stateProvider, {
        name: 'eaw',
        abstract: true,
    });

    createState($stateProvider, {
        name: 'eaw/app',
        parent: 'eaw',
        abstract: true,
        url: '',
        views: {
            'alerts@': { component: SystemAlertsComponent },
            'header@': { component: HeaderComponent },
            'sidebar@': { component: SidebarComponent },
            'breadcrumb@': { component: BreadcrumbComponent },
            'learning-module@': { component: LearningModuleSidebarComponent },
        },
        data: {
            requireLogin: true,
            breadcrumb: null,
        },
        resolve: [
            resolver('user', async () => CurrentOld.user),
            resolver('userId', async () => CurrentOld.user['id']),
            resolver('customer', async () => CurrentOld.customer),
            resolver('customerId',async () => CurrentOld.customer?.['id']),
            resolver('employee', async () => CurrentOld.employee),
            resolver('employeeId', async () => CurrentOld.employee?.['id']),
        ],
    });

    createState($stateProvider, {
        name: 'eaw/app/tasks',
        parent: 'eaw/app',
        url: '/tasks',
        views: {
            'content@': {
                component: TasksComponent,
            },
        },
        data: {
            fullscreen: true,
            breadcrumb: { key: 'TASK_plural' },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/no_access',
        parent: 'eaw/app',
        url: '/no_access',
        views: {
            'content@': {
                component: NoAccessComponent,
            },
            'learning-module@': { template: '', controller: function() {} },
            'breadcrumb@': { component: BreadcrumbComponent },
        },
        data: {
            breadcrumb: { key: 'NO_ACCESS' },
        },
        resolve: [
            resolver('homeRoute', (transition) => Promise.resolve(transition?.router.stateService.href('eaw/app/no_access'))),
        ],
    });

    $urlRouterProvider.otherwise(($injector: any) => {
        const $state = $injector.get('$state');
        if ($injector.get('LoginServiceDowngrade').isLoggedIn()) {
            $state.go('eaw/app/home');
        } else {
            $state.go('eaw/outside/login');
        }
    });
} ]);
