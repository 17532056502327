@if (loading) {
    <eaw-info-loading size="md" class="tw-my-24"/>
} @else {
    @if (items.length) {
        <ul>
            @for (item of items; track item) {
                <li class="name">{{item[0]?.employeeName}}</li>

                @if (item[0]) {
                    <ul class="fail-list">
                        @for (check of item[0].failedChecks; track check) {
                            <li>{{'Failed' + check | translate: 'autosched' | async}}</li>
                        }
                    </ul>
                }
            }
        </ul>
    } @else {
        <span class="mat-subtitle-2">{{'NO_INFO' | translate | async}}</span>
    }
}
