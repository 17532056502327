import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { CurrentService } from '../services/current.service';
import { StateProviderDataData } from '../utils/create-state';
import { firstValueFrom } from 'rxjs';
import { SettingService } from '../http/setting.service';
import { getTransitionCustomerId } from '../utils/transition-customer';
import { routeDataSettingsChecker } from '../utils/route-data-settings-checker';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
    providedIn: 'root',
})
export class SettingsHookService {

    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(SettingService) private settingService: SettingService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
    ) {
        this.uiRouter.transitionService.onBefore({ to: (state) => state?.data?.settings }, (transition) => {
            console.debug('🪝', 'SettingsHookService.onBefore', transition);

            const customerId = getTransitionCustomerId(transition) || this.current.getCustomer().id;
            const settings = transition.to().data.settings as StateProviderDataData['settings'];

            return firstValueFrom(routeDataSettingsChecker(this.settingService, customerId, settings)).then((response) => {
                if (response) {
                    return response;
                }

                void this.snackBarService.t('INVALID_SETTING');
                console.error('Missing required setting to access route', settings);
                return transition.router.stateService.target('eaw/app/home');
            }).catch(() => transition.router.stateService.target('eaw/app/home'));
        });
    }
}
