import { Component, Inject, OnInit } from '@angular/core';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { CurrentService } from '../../../shared/services/current.service';
import type { Customer } from '../../../shared/models/customer';
import { Products } from '../../../shared/enums/products';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { Namespace } from '../../../shared/enums/namespace';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { UIRouter } from '@uirouter/core';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { firstValueFrom } from 'rxjs';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { FilesComponent } from '../../../hr/pages/files/files.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

interface ChainHrCustomer {
    customer: Customer,
    isActive: boolean,
}

@Component({
    selector: 'eaw-hr',
    templateUrl: './hr.component.html',
    styleUrl: './hr.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatProgressSpinnerModule,
        MatExpansionModule,
        NgFor,
        FilesComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class HrComponent implements OnInit {
    customers: ChainHrCustomer[] = [];

    constructor(
        @Inject(CustomerGroupService) public customerGroupService: CustomerGroupService,
        @Inject(CurrentService) public current: CurrentService,
        @Inject(PermissionCheckService) public permissionCheckService: PermissionCheckService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
    }

    ngOnInit(): void {
        this.getGroups();
    }

    getGroups() {
        expandAllPages((pagination) => this.customerGroupService.getAllForCustomer(this.current.getCustomer().id, pagination), { 'with[]': [ 'products', 'members.products' ] }).subscribe(async (groups) => {
            const customers: ChainHrCustomer[] = [];
            const permissions = groups.flatMap((group) => {
                return group.members.map((customer) => {
                    return `customers.${customer.id}.employees.*.hr_files.*.get`;
                });
            });

            await firstValueFrom(this.permissionCheckService.isAllowedMany(permissions));

            groups.forEach((group) => {
                const groupHasHr = group.products.some((product) => product.name === Products.HumanResources);

                group.members.forEach((customer) => {
                    const canGetHr = this.permissionCheckService.single(`customers.${customer.id}.employees.*.hr_files.*.get`);
                    const customerHasHr = groupHasHr || customer.products.some((product) => product.name === Products.HumanResources);
                    const isDuplicate = customers.some((c) => c.customer.id === customer.id);

                    if (customerHasHr && canGetHr && !isDuplicate) {
                        customers.push({
                            customer,
                            isActive: false,
                        });
                    }
                });
            });

            this.customers = sort(Array.from(customers.values()), this.current.languageTag, [ (r) => r.customer.name ]);

            if (!this.customers.length) {
                void this.snackBarService.t('NO_CUSTOMER_plural', Namespace.ChainOps);
                this.uiRouter.stateService.go('eaw/app/home');
                return;
            }
        });
    }

    customersTrackBy(_: number, item: ChainHrCustomer) {
        return item.customer.id;
    }

    setIsActive(customer: ChainHrCustomer, isActive: boolean) {
        customer.isActive = isActive;
    }
}
