import { Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { FieldErrorComponent } from '../../../shared/components/field-error/field-error.component';
import { catchError, EMPTY } from 'rxjs';
import { Mobile } from '../../../shared/utils/eaw-mobile';

@Component({
    selector: 'eaw-microsoft-sso-dialog',
    standalone: true,
    imports: [
        CommonModule,
        DialogHeaderComponent,
        TranslatePipe,
        MatDialogContent,
        MatDialogActions,
        ActionButtonComponent,
        MatButtonModule,
        MatDialogClose,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        FieldErrorComponent,
    ],
    templateUrl: './microsoft-sso-dialog.component.html',
    styleUrl: './microsoft-sso-dialog.component.scss',
})
export class MicrosoftSsoDialogComponent extends DialogComponent<DialogData> {
    private http = inject(HttpClient);
    override dialogRef = inject(MatDialogRef);

    submitting = signal(false);
    workspaceControl = new FormControl<string | null>(null, [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ]);

    constructor() {
        super(undefined, undefined, DialogSize.Medium);
    }

    submit() {
        const workspace = this.workspaceControl.value;
        if (!workspace) {
            return;
        }

        this.submitting.set(true);
        this.workspaceControl.disable();
        this.http.get<{url: string}>(`/msentra/realms/${workspace}/redirect`).pipe(
            catchError(() => {
                this.submitting.set(false);
                this.workspaceControl.enable();
                return EMPTY;
            }),
        ).subscribe((res) => {
            if (Mobile.isMobile) {
                Mobile.openBrowser(res.url);
            } else {
                window.location.href = res.url;
            }
        });
    }
}
