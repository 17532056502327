import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Position } from '../../../shared/models/position';
import { PositionService } from '../../http/position.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { MatSelectionList, MatListModule } from '@angular/material/list';
import { EmployeePositionService } from '../../http/employee-position.service';
import { catchError, EMPTY, mergeMap, of, reduce } from 'rxjs';
import { HttpContext, HttpErrorResponse } from '@angular/common/http';
import { IGNORE_ERROR } from '../../../shared/http/http-contexts';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface AddPositionsDialogData extends DialogData {
    customerId: number;
    employeeId: number;
}

@Component({
    selector: 'eaw-add-positions-dialog',
    templateUrl: './add-positions-dialog.component.html',
    styleUrl: './add-positions-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatListModule,
        NgFor,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
/**
 * Adds positions to an employee
 */
export class AddPositionsDialogComponent extends DialogComponent<AddPositionsDialogData> implements OnInit {
    // IDs of selected positions
    selectedPositions: number[] = [];
    loading = true;
    positions: Position[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AddPositionsDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AddPositionsDialogComponent, Position[]>,
        @Inject(PositionService) public positionService: PositionService,
        @Inject(EmployeePositionService) private employeePositionService: EmployeePositionService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit() {
        expandAllPages((pagination) => this.positionService.getAll(this.data.customerId, {
            ...pagination,
            order_by: 'name',
            direction: 'asc',
        }), { per_page: 50 }).subscribe((positions) => {
            this.positions = positions;
            this.loading = false;
        });
    }

    selectionChange(list: MatSelectionList) {
        this.selectedPositions = list.selectedOptions.selected.map((option) => option.value);
    }

    add() {
        this.loading = true;

        of(...this.selectedPositions).pipe(
            mergeMap((positionId) => this.getCreateObservable(positionId), 4),
            reduce((acc, response) => {
                return response instanceof Position ? [ ...acc, response ] : acc;
            }, [] as Position[]),
        ).subscribe((positions) => {
            this.dialogRef.close(positions);
        });
    }

    getCreateObservable(positionId: number) {
        return this.employeePositionService.create(this.data.customerId, this.data.employeeId, positionId, new HttpContext().set(IGNORE_ERROR, [ 409 ])).pipe(
            catchError((err: HttpErrorResponse) => {
                return err.status === 409 ? of(null) : EMPTY;
            }),
        );
    }
}
