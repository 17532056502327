import { Component, inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { UserGroupService } from '../../../shared/http/user-group.service';
import { DataTablePagination, EawDataTable } from '../../../data-table/types/data-table';
import { User, UserResponse } from '../../../shared/models/user';
import { DataTableComponent, DataTableGoTo } from '../../../data-table/data-table.component';
import { EMPTY, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { GroupMembership } from '../../../shared/models/group-membership';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { MatDialog } from '@angular/material/dialog';
import { AddUsersToUserGroupComponent,
    AddUsersToUserGroupData } from '../../../shared/dialogs/add-users-to-user-group/add-users-to-user-group.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { IntervalDialogComponent } from '../../../shared/dialogs/interval-dialog/interval-dialog.component';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { DateTime } from 'luxon';
import { UserGroup } from '../../../company/models/user-group';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'eaw-customer-user-group-members',
    standalone: true,
    imports: [
        DataTableComponent,
        PageHeaderComponent,
        AsyncPipe,
    ],
    templateUrl: './customer-user-group-members.component.html',
    styleUrl: './customer-user-group-members.component.scss',
})
export class CustomerUserGroupMembersComponent implements EawDataTable<GroupMembership<User, UserResponse>>, OnInit {
    private readonly userGroupService = inject(UserGroupService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly dialog = inject(MatDialog);
    private readonly confirmDialog = inject(ConfirmDialogService);
    private readonly translate = inject(TranslateService);
    private readonly snackBar = inject(SnackBarService);

    @ViewChild('dataTable', { static: true }) dataTable?: DataTableComponent<GroupMembership<User, UserResponse>>;

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) userGroupId!: number;

    userGroup?: Observable<UserGroup>;

    columns: DataTableColumnType<GroupMembership<User, UserResponse>>[];
    request = of(mockArrayPaginatedResponse<GroupMembership<User, UserResponse>>());

    displayInactive = false;
    goTo: DataTableGoTo;

    fabButton: HeaderFabButton;
    buttons: PageHeaderButton[];

    constructor() {
        this.buttons = [
            new PageHeaderButton({
                click: () => {
                    this.displayInactive = !this.displayInactive;
                    this.dataTable?.refresh();
                },
                active: () => this.displayInactive,
                icon: () => 'history',
                menuText: signal(this.translate.t('INCLUDE_INACTIVE_USERS')),
            }),
        ];

        this.fabButton = {
            icon: 'add',
            tooltip: this.translate.t('ADD_MEMBER'),
            click: this.addMember.bind(this),
            hasPermission: () => of(true),
        };

        this.goTo = {
            state: 'eaw/app/admin/users/view/info',
            params: [
                {
                    itemKey: 'memberId',
                    stateKey: 'id',
                },
            ],
        };

        this.columns = [
            new DataTableNumberColumn({
                value: 'memberId',
                header: new DataTableHeader({
                    text: 'Id',
                    sortBy: 'id',
                }),
            }),
            new DataTableTextColumn({
                value: (cell) => {
                    return cell.item.member?.firstName;
                },
                header: new DataTableHeader({
                    i18n: 'FIRST_NAME',
                    sortBy: 'first_name',
                }),
            }),
            new DataTableTextColumn({
                value: (cell) => cell.item.member?.lastName,
                header: new DataTableHeader({
                    i18n: 'LAST_NAME',
                    sortBy: 'last_name',
                }),
            }),
            new DataTableDateTimeColumn({
                format: DateTime.DATETIME_MED,
                value: (cell) => cell.item.from,
                header: new DataTableHeader({
                    i18n: 'FROM',
                    sortBy: 'from',
                }),
            }),
            new DataTableDateTimeColumn({
                format: DateTime.DATETIME_MED,
                value: (cell) => cell.item.to,
                header: new DataTableHeader({
                    i18n: 'TO',
                    sortBy: 'to',
                }),
            }),
            new DataTableDateTimeColumn({
                format: DateTime.DATETIME_MED,
                value: (cell) => cell.item.member?.lastActive,
                header: new DataTableHeader({
                    i18n: 'LAST_ACTIVE',
                    sortBy: 'last_active',
                }),
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        click: this.editMembership.bind(this),
                        icon: 'edit',
                        show: (item: GroupMembership<User, UserResponse>): Observable<boolean> => {
                            return this.permissionCheckService.isAllowed(`customers.${this.customerId}.user_groups.${this.userGroupId}.members.${item.memberId}.update`);
                        },
                        nonBlocking: true,
                        tooltip: {
                            key: 'EDIT',
                        },
                    },
                    {
                        color: 'red-500',
                        click: this.removeMember.bind(this),
                        icon: 'delete',
                        show: (item: GroupMembership<User, UserResponse>): Observable<boolean> => {
                            return this.permissionCheckService.isAllowed(`customers.${this.customerId}.user_groups.${this.userGroupId}.members.${item.memberId}.remove`);
                        },
                        nonBlocking: true,
                        tooltip: {
                            key: 'DELETE',
                        },
                    },
                ],
            }),
        ];
    }

    ngOnInit(): void {
        this.userGroup = this.userGroupService.get(this.customerId, this.userGroupId, { with: [ 'owner' ] });
    }

    addMember() {
        this.dialog.open<AddUsersToUserGroupComponent, AddUsersToUserGroupData, User[]>(AddUsersToUserGroupComponent, {
            data: {
                userGroupId: this.userGroupId,
                customerId: this.customerId,
            },
        })
            .afterClosed()
            .pipe(
                tap(async (result) => {
                    if (Array.isArray(result)) {
                        this.dataTable?.refresh();
                        void this.snackBar.open(await this.translate.t('ADDED'));
                    }
                }),
            )
            .subscribe();
    }

    removeMember(cell: DataTableButtonCell<GroupMembership<User, UserResponse>>) {
        this.userGroup?.pipe(
            switchMap((userGroup) => {
                return this.confirmDialog.delete({
                    title: this.translate.t('REMOVE'),
                    text: this.translate.t('THIS_WILL_REMOVE_X_FROM_Y', 'general', {
                        name1: `${cell.item.member?.name}`,
                        name2: userGroup.name || '',
                    }),
                    confirmText: this.translate.t('REMOVE'),
                }).afterClosed();
            }),
            switchMap((result) => {
                return !result?.ok ? EMPTY : this.userGroupService.removeMembership(this.customerId, this.userGroupId, cell.item.memberId, cell.item.id);
            }),
            tap(async () => {
                this.dataTable?.refresh();
                void this.snackBar.open(await this.translate.t('X_REMOVED', 'general', { var: cell.item?.member?.name }));
            }),
        )
            .subscribe();

    }

    editMembership(cell: DataTableButtonCell<GroupMembership<User, UserResponse>>) {
        this.dialog.open<IntervalDialogComponent, IntervalDialogComponent['data']>(IntervalDialogComponent, {
            data: {
                from: cell.item.from,
                to: cell.item.to ?? null,
                dialogTitle: of(cell.item.member?.name ?? ''),
            },
        }).afterClosed()
            .pipe(
                switchMap((result) => {
                    return !result ? EMPTY : this.userGroupService.updateMembership(this.customerId, this.userGroupId, cell.item.memberId, cell.item.id, {
                        from: result.from,
                        to: result.to,
                    });
                }),
                tap(async () => {
                    this.dataTable?.refresh();
                    void this.snackBar.open(await this.translate.t('UPDATED'));
                }),
            )
            .subscribe();
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.userGroupService.getMembers(this.customerId, this.userGroupId, {
            display_inactive_users: this.displayInactive,
            ...pagination,
        }).pipe(shareReplay(1));
    }
}
