// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { filter } from '../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
const template1 = `<md-dialog>
  <eaw-dialog-header-old heading="'admin:IMPORT_USER_GROUPS' | i18next"></eaw-dialog-header-old>
  <md-dialog-content>
    <form name="impUserGroupForm" class="md-dialog-content" layout="column">
      <md-autocomplete
        flex
        required
        md-selected-item="importGroupCtrl.selectedCustomer"
        md-search-text="searchText"
        md-floating-label="{{'admin:CUSTOMER' | i18next}}"
        md-items="item in importGroupCtrl.getCustomers(searchText)"
        md-delay="0"
        md-item-text="item.name"
        md-menu-class="autocomplete-custom-template"
        md-clear-button="true"
        md-min-length="0"
        md-selected-item-change="importGroupCtrl.customerSelected()"
        md-require-match="true">
        <md-item-template>
          <span class="autocomplete-item-title">
            <span md-highlight-flags="i" md-highlight-text="searchText">
              {{item.name}}
            </span>
          </span>
        </md-item-template>
        <md-not-found>
          <span ng-i18next="NO_MATCH_FOUND"></span>
        </md-not-found>
      </md-autocomplete>

      <md-progress-circular
        class="md-progress-center"
        ng-if="importGroupCtrl.loading">
      </md-progress-circular>

      <div layout="row" ng-if="!importGroupCtrl.loading && importGroupCtrl.userGroupOptions.length">
        <md-input-container flex="80">
          <label bo-i18next="USER_GROUP_plural"></label>
          <md-chips
            ng-model="importGroupCtrl.selectedUserGroups"
            md-autocomplete-snap
            md-require-match="true">
            <md-autocomplete
              md-min-length="0"
              md-selected-item="selectedItem"
              md-select-on-match="true"
              md-search-text="searchTextUG"
              md-items="item in importGroupCtrl.getUserGroupOptions(searchTextUG)"
              md-item-text="name">
              <span md-highlight-text="searchTextUG" md-highlight-flags="i">
                {{item.name}}
              </span>
            </md-autocomplete>
            <md-chip-template>
              <strong>{{$chip.name}}</strong>
            </md-chip-template>
          </md-chips>
        </md-input-container>

        <div
          flex
          layout="row"
          layout-align="center center">
          <md-checkbox
            ng-model="importGroupCtrl.checkAllUserGroupOptions"
            ng-change="importGroupCtrl.selectOptions()">
            {{'SELECT_ALL' | i18next}}
          </md-checkbox>
        </div>
      </div>
    </form>
  </md-dialog-content>

  <md-dialog-actions layout="row">
    <md-button close-dialog ng-i18next="CANCEL"></md-button>
    <md-button
      form="impUserGroupForm"
      ng-disabled="impUserGroupForm.$invalid"
      ng-click="importGroupCtrl.submit()"
      ng-i18next="SUBMIT">
    </md-button>
  </md-dialog-actions>
</md-dialog>
`;
module('eaw.admin').controller('adminCustomerUserGroupsTabCtrl', [ 'customer', '$mdDialog', 'ToastService', 'UserGroupService', 'CustomerService', function(customer, $mdDialog, ToastService, UserGroupService, CustomerService) {
    const ctrl = this;
    ctrl.$onInit = () => {
        ctrl.customer = customer;
        ctrl.table = {
            columns: [
                {
                    title: t('ID'),
                    sortable: 'id',
                },
                {
                    title: t('NAME'),
                    sortable: 'name',
                },
                {
                    title: t('CREATED'),
                    sortable: 'created_at',
                },
                { title: '' },
            ],
            cells: [
                { template: 'item.id' },
                { template: 'item.name' },
                { template: 'item.created_at | moment' },
                {
                    buttons: [
                        {
                            icon: 'edit',
                            click: ctrl.editGroup,
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            click: ctrl.deleteGroup,
                        },
                    ],
                },
            ],
        };
    };
    ctrl.deleteGroup = (group) => {
        $mdDialog.show($mdDialog.confirm()
            .theme('delete')
            .title('Delete user group')
            .textContent(`Delete the user group ${group.name}?`)
            .ok(t('DELETE'))
            .cancel(t('CANCEL'))).then(() => {
            UserGroupService.deleteById(group.id).$promise.then(() => {
                ToastService.toast('User group deleted');
                ctrl.updateList = true;
            });
        });
    };
    ctrl.editGroup = (group) => {
        $mdDialog.show($mdDialog.prompt()
            .title(t('company:EDIT_USER_GROUP'))
            .textContent(t('NAME'))
            .initialValue(group.name)
            .required(true)
            .ok(t('UPDATE'))
            .cancel(t('CANCEL'))).then((result) => {
            UserGroupService.updateById(group.id, { name: result }).$promise.then(() => {
                ctrl.updateList = true;
                ToastService.toast(t('UPDATED'));
            });
        });
    };
    ctrl.addGroup = () => {
        $mdDialog.show($mdDialog.prompt()
            .title(t('company:ADD_USER_GROUP'))
            .textContent(t('NAME'))
            .required(true)
            .ok(t('CREATE'))
            .cancel(t('CANCEL'))).then((result) => {
            UserGroupService.create(ctrl.customer.id, result).$promise.then(() => {
                ctrl.updateList = true;
                ToastService.toast(t('ADDED'));
            });
        });
    };
    ctrl.importGroup = () => {
        $mdDialog.show({
            template: template1,
            controllerAs: 'importGroupCtrl',
            bindToController: true,
            resolve: {
                /** @ngInject */
                customers: function() {
                    return CustomerService.getAll({
                        per_page: 9999,
                    }).then((resp) => resp.data
                        .map(({ id, name }) => {
                            return {
                                id,
                                name,
                            };
                        })
                        .filter((i) => ctrl.customer.id !== i.id));
                },
            },
            controller: function() {
                const igCtrl = this;
                igCtrl.$onInit = () => {
                    igCtrl.resetValues();
                };
                igCtrl.resetValues = () => {
                    igCtrl.loading = false;
                    igCtrl.userGroupOptions = [];
                    igCtrl.selectedUserGroups = [];
                    igCtrl.checkAllUserGroupOptions = false;
                };
                igCtrl.getCustomers = (filterString) => filter(filterString, igCtrl.customers, (r) => r.name);
                igCtrl.customerSelected = () => {
                    igCtrl.loading = true;
                    if (!igCtrl.selectedCustomer) {
                        igCtrl.resetValues();
                        return;
                    }
                    UserGroupService.getAll(igCtrl.selectedCustomer.id, {
                        per_page: 9999,
                    }).$promise.then(({ data }) => {
                        igCtrl.userGroupOptions = data.map(({ id: key, name }) => {
                            return {
                                key,
                                name,
                            };
                        });
                        igCtrl.loading = false;
                    });
                };
                igCtrl.submit = () => {
                    const data = {
                        from_customer_id: igCtrl.selectedCustomer.id,
                    };
                    if (!igCtrl.checkAllUserGroupOptions && igCtrl.selectedUserGroups?.length) {
                        data.user_group_ids = igCtrl.selectedUserGroups.map((i) => i.key);
                    }
                    UserGroupService.import(ctrl.customer.id, data).$promise.then(() => {
                        $mdDialog.hide();
                    });
                };
                igCtrl.setUserGroupViewValue = () => {
                    igCtrl.selectedUserGroups.$setViewValue(igCtrl.selectedUserGroups?.map((o) => o.key) || []);
                };
                igCtrl.getUserGroupOptions = (searchText) => {
                    if (!searchText || !igCtrl.selectedUserGroups?.length) {
                        return igCtrl.userGroupOptions;
                    }
                    const search = searchText?.toLowerCase();
                    return igCtrl.userGroupOptions.filter((option) => !igCtrl.selectedUserGroups?.includes(option) &&
                        (!search || option.name.toLowerCase().includes(search)));
                };
                igCtrl.selectOptions = () => {
                    igCtrl.selectedUserGroups = igCtrl.checkAllUserGroupOptions ? igCtrl.userGroupOptions : [];
                    // igCtrl.setUserGroupViewValue();
                };
                igCtrl.formatter = (val) => {
                    igCtrl.selectedUserGroups = igCtrl.userGroupOptions.filter((option) => val.find((key) => key == option.key));
                    return val;
                };
            },
        }).then(() => {
            ToastService.toast(t('admin:USER_GROUPS_IMPORTED'));
            ctrl.updateList = true;
        });
    };
} ]);
