import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { User, UserResponse } from '../../shared/models/user';
import { Comment } from '../../shared/models/comment';

export interface FormSubmissionNoteResponse {
    id: number;
    form_submission_id: number;
    user_id?: number;
    body: string;
    is_visible: boolean;
    user?: UserResponse;
    created_at: string;
    updated_at: string;
}

export class FormSubmissionNote {
    id: number;
    formSubmissionId: number;
    userId?: number;
    body: string;
    isVisible: boolean;
    user?: User;
    createdAt: DateTime;
    updatedAt: DateTime;

    constructor(data: FormSubmissionNoteResponse) {
        this.id = data.id;
        this.formSubmissionId = data.form_submission_id;
        this.userId = data.user_id;
        this.body = data.body;
        this.isVisible = data.is_visible;
        this.user = data.user ? new User(data.user) : undefined;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
    }

    toComment() {
        const comment = new Comment({
            id: this.id,
            body: this.body,
            created_at: '',
            user_id: this.userId || 0,
            user_name: this.user?.name || '',
            commentable_id: this.formSubmissionId,
            commentable_type: 'form_submission',
        });
        comment.createdAt = this.createdAt;
        return comment;
    }
}
