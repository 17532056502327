import { Component, inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepper, MatStepperIntl, MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { User } from '../../../shared/models/user';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { CustomerService } from '../../../shared/http/customer.service';
import { Customer } from '../../../shared/models/customer';
import { catchError, EMPTY, forkJoin, tap } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Employee, RequiredEmployeeField } from '../../../shared/models/employee';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { TranslateService } from '../../../shared/services/translate.service';
import { UIRouter } from '@uirouter/core';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { ProductName, Products } from '../../../shared/enums/products';
import { SettingService } from '../../../shared/http/setting.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { CustomerCustomFieldService } from '../../../shared/http/customer-custom-field.service';
import { ModelCustomField } from '../../../custom-fields/models/model-custom-field';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { NewEmployeeLocationAccessComponent } from './steps/new-employee-location-access/new-employee-location-access.component';
import { NewEmployeeUserCheckComponent } from './steps/new-employee-user-check/new-employee-user-check.component';
import { NewEmployeeUserCreateComponent } from './steps/new-employee-user-create/new-employee-user-create.component';
import { NewEmployeeCreateEmployeeComponent } from './steps/new-employee-create-employee/new-employee-create-employee.component';
import { NewEmployeeExtraEmployeeInfoComponent } from './steps/new-employee-extra-employee-info/new-employee-extra-employee-info.component';
import { NewEmployeePayComponent } from './steps/new-employee-pay/new-employee-pay.component';
import { NewEmployeeContractComponent } from './steps/new-employee-contract/new-employee-contract.component';
import { NewEmployeeUserGroupsComponent } from './steps/new-employee-user-groups/new-employee-user-groups.component';
import { NewEmployeePositionsComponent } from './steps/new-employee-positions/new-employee-positions.component';
import { NewEmployeeCustomFieldsComponent } from './steps/new-employee-custom-fields/new-employee-custom-fields.component';
import { NewEmployeeDoneComponent } from './steps/new-employee-done/new-employee-done.component';
import { NewEmployeeDefaultHrFilesComponent } from './steps/new-employee-default-hr-files/new-employee-default-hr-files.component';
import { CustomerUser } from '../../../shared/models/customer-user';
import { InfoBoxComponent } from '../../../shared/components/info-card/info-box.component';
import { HttpContext } from '@angular/common/http';
import { IGNORE_ERROR } from '../../../shared/http/http-contexts';
import { PredefinedSettings } from '../../../shared/enums/predefined-settings';
import { ProductPipe } from '../../../shared/pipes/product.pipe';

@Component({
    selector: 'eaw-new-employee',
    standalone: true,
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
    ],
    imports: [
        CommonModule,
        MatStepperModule,
        MatButtonModule,
        NewEmployeeUserCheckComponent,
        MatCardModule,
        NewEmployeeUserCreateComponent,
        PageHeaderComponent,
        MatProgressSpinnerModule,
        NewEmployeeLocationAccessComponent,
        NewEmployeeCreateEmployeeComponent,
        NewEmployeeExtraEmployeeInfoComponent,
        NewEmployeePayComponent,
        NewEmployeeContractComponent,
        NewEmployeeUserGroupsComponent,
        NewEmployeePositionsComponent,
        NewEmployeeCustomFieldsComponent,
        NewEmployeeDoneComponent,
        NewEmployeeDefaultHrFilesComponent,
        TranslatePipe,
        InfoBoxComponent,
        ProductPipe,
    ],
    templateUrl: './new-employee.component.html',
    styleUrl: './new-employee.component.scss',
})
export class NewEmployeeComponent implements OnInit {
    customerService = inject(CustomerService);
    translateService = inject(TranslateService);
    uiRouter = inject(UIRouter);
    customerProductService = inject(CustomerProductService);
    settingService = inject(SettingService);
    customerCustomFieldService = inject(CustomerCustomFieldService);
    matStepperIntl = inject(MatStepperIntl);

    @ViewChild(MatStepper) stepper?: MatStepper;

    @Input({ required: true }) customerId!: number;

    hasHumanResources = signal(false);
    headerButtons: PageHeaderButton[];
    error = false;
    createUser = true;
    loading = true;
    customer!: Customer;
    foundExistingUser = false;
    user?: User;
    customerUser?: CustomerUser;
    /**
     * Email of the person we are going to add as a user
     */
    email = '';
    employee?: Employee;
    products: Partial<Record<ProductName, boolean>> = {};
    requiredFields: Partial<Record<RequiredEmployeeField, boolean>> = {};
    additionalEmployeeInfoRequired = false;
    customFields: ModelCustomField[] = [];
    customFieldsRequired = false;

    constructor() {
        this.headerButtons = [
            new PageHeaderButton({
                icon: () => this.createUser ? 'key_off' : 'key',
                click: this.toggleCreateUser.bind(this),
                menuText: signal(this.translateService.t('DONT_CREATE_USER', 'new_employee')),
            }),
            new PageHeaderButton({
                icon: 'refresh',
                click: () => this.uiRouter.stateService.reload(),
                menuText: signal(this.translateService.t('RESTART')),
            }),
        ];
    }

    ngOnInit() {
        this.customerProductService.hasProducts(this.customerId, [ Products.HumanResources ]).subscribe((hasHumanResources) => this.hasHumanResources.set(hasHumanResources));

        void this.setStepperIntl();

        forkJoin([
            this.getSetRequiredFields(),
            this.getSetCustomer(),
            this.getCustomFields(),
        ]).subscribe(() => {
            this.loading = false;
            this.customFieldsRequired = this.customFields.length ? this.customFields.some((cf) => cf.required) : false;
            this.additionalEmployeeInfoRequired = ([
                'employee.address_1',
                'employee.address_2',
                'employee.postal_code',
                'employee.city',
                'employee.country_key',
                'employee.region',
                'employee.nationality',
                'employee.gender',
                'employee.birth_date',
                'employee.dial_code',
                'employee.phone',
            ] satisfies RequiredEmployeeField[]).some((field) => this.requiredFields[field]);
        });
    }

    async setStepperIntl() {
        this.matStepperIntl.optionalLabel = await this.translateService.t('OPTIONAL');
        this.matStepperIntl.completedLabel = await this.translateService.t('COMPLETED');
        this.matStepperIntl.editableLabel = await this.translateService.t('EDITABLE');

        this.matStepperIntl.changes.next();
    }

    getCustomFields() {
        return this.customerCustomFieldService.getForModel(this.customerId, ApiModel.Employee).pipe(
            tap((customFields) => (this.customFields = customFields)),
        );
    }

    getSetCustomer() {
        return this.customerService.get(this.customerId, [], new HttpContext().set(IGNORE_ERROR, true)).pipe(
            catchError((err) => {
                console.error(err);
                this.error = true;
                return EMPTY;
            }),
            tap((customer) => {
                this.customer = customer;
            }),
        );
    }

    getSetRequiredFields() {
        return this.settingService.getString([ 'customers', this.customerId ], PredefinedSettings.NewEmployeeRequiredFields).pipe(
            tap((requiredFields) => {
                if (requiredFields) {
                    try {
                        (JSON.parse(requiredFields) as string[]).filter((field) => !field.startsWith('!')).forEach((field) => {
                            this.requiredFields[field as RequiredEmployeeField] = true;
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }
            }),
        );
    }

    toggleCreateUser() {
        this.createUser = !this.createUser;

        if (this.stepper) {
            this.stepper.selectedIndex = 0;
        }
    }
}
