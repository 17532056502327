<eaw-dialog-header>
    <span title>{{ 'ADD_PREDEFINED_SETTING' | translate:'admin' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-16">
    <mat-form-field>
        <mat-label>{{ 'PROPERTY' | translate | async }}</mat-label>
        <mat-select [formControl]="propertyControl">
            @for (property of properties;track property.key) {
                <mat-option [value]="property">{{ property.key }}</mat-option>
            }
        </mat-select>
        <mat-hint>{{propertyControl.value.description}}</mat-hint>
    </mat-form-field>

    @switch (propertyControl.value.key) {
        @case (predefinedSettings.NewEmployeeRequiredFields) {
            <mat-selection-list multiple  (selectionChange)="updateRequiredFieldsValue($event)">
                @for (field of newEmployeeRequiredFields; track field) {
                    <mat-list-option [value]="field">{{field}}</mat-list-option>
                }
            </mat-selection-list>
        }
        @case (predefinedSettings.MaxUserGroups) {
            <mat-form-field>
                <mat-label>{{'Max number of groups' | translate | async}}</mat-label>
                <input type="number" inputmode="numeric" min="0" matInput (keyup)="numberInputChange($event)">
            </mat-form-field>
        }
        @case (predefinedSettings.FamilyMemberEnabled) {
            <mat-slide-toggle (change)="toggleChange($event)">Enable the family member functionality</mat-slide-toggle>
        }
        @case (predefinedSettings.PayrollPersonalTpApp) {
            <mat-slide-toggle (change)="toggleChange($event)">Enable timepunch clock inside the app</mat-slide-toggle>
        }
        @case (predefinedSettings.PayrollTimepunchBlocksEnabled) {
            <mat-slide-toggle (change)="toggleChange($event)">Enable timepunch blocking feature</mat-slide-toggle>
        }
        @case (predefinedSettings.EmployeeVisibleBalanceCodes) {
            <mat-form-field>
                <mat-label>{{'BALANCE_CODE_plural' | translate:'balances' | async}}</mat-label>
                <textarea matInput (keyup)="onePerLineTextareaChange($event)" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10" cdkTextareaAutosize></textarea>
                <mat-hint>One balance code per line</mat-hint>
            </mat-form-field>
        }
        @default {
            <div>Not implemented yet</div>
        }
    }
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>

    <eaw-action-button [loading]="false" [disabled]="!value" (click)="add()">{{ 'ADD' | translate | async }}</eaw-action-button>
</mat-dialog-actions>
