import { Resolvable } from '../types/resolvable';
import { SidenavHeaderInputs } from '../components/sidenav/sidenav.component';

/**
 * ‼ Make sure the resolve function arguments matches the order of the dependencies
 *
 * @see Resolver
 * @param deps
 * @param resolveFn
 * @constructor
 */
export function sidenavHeaderInputsResolver(deps: string[], resolveFn: (...args: any[]) => SidenavHeaderInputs): Resolvable {
    return {
        token: 'headerInputs',
        deps,
        resolveFn,
    };
}
