import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { DefaultScheduleCreateComponent } from './default-schedule-create/default-schedule-create.component';
import { Schedule } from '../../models/schedule';
import { Products } from '../../../shared/enums/products';
import { RepeatingScheduleCreateComponent } from './repeating-schedule-create/repeating-schedule-create.component';
import { AutomaticSchedulingScheduleCreateComponent } from './automatic-scheduling-schedule-create/automatic-scheduling-schedule-create.component';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { switchMap } from 'rxjs';

export interface CreateScheduleDialogData extends DialogData {
    customerId: number;
}

export type ScheduleCreateDialog = typeof DefaultScheduleCreateComponent
    | typeof RepeatingScheduleCreateComponent
    | typeof AutomaticSchedulingScheduleCreateComponent;

@Injectable({
    providedIn: 'any',
})
export class CreateScheduleDialogService {

    constructor(
        @Inject(CustomerProductService) private customerProductService: CustomerProductService,
        @Inject(MatDialog) private dialog: MatDialog,
    ) {
    }

    open(data: CreateScheduleDialogData) {
        let component: ScheduleCreateDialog = DefaultScheduleCreateComponent;

        return this.customerProductService.hasProductsDetailed(data.customerId, [ Products.AutomaticScheduling, Products.RepeatingSchedule ])
            .pipe(
                switchMap((hasProducts) => {
                    if (hasProducts.products[Products.AutomaticScheduling]) {
                        component = AutomaticSchedulingScheduleCreateComponent;
                    }

                    if (hasProducts.products[Products.RepeatingSchedule]) {
                        component = RepeatingScheduleCreateComponent;
                    }

                    return this.dialog.open<InstanceType<ScheduleCreateDialog>, CreateScheduleDialogData, Schedule | 'ok'>(component, {
                        data: {
                            size: DialogSize.Medium,
                            ...data,
                        },
                    }).afterClosed();
                }),
            );
    }
}
