<eaw-dialog-header>
    <span title>{{ 'PAY_RATE' | translate:'company' | async }}</span>
</eaw-dialog-header>

@if (loading) {
    <mat-dialog-content class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </mat-dialog-content>
} @else {
    <mat-dialog-content>
        <form class="tw-grid tw-grid-cols-2 tw-gap-16" [formGroup]="form">
            <mat-form-field eawDatePickerOptions class="tw-col-span-1">
                <mat-label>{{ 'FROM' | translate | async }}</mat-label>
                <input matInput formControlName="from" [min]="fromMinDate" [max]="form.getRawValue().to" [matDatepicker]="fromPicker">
                <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                <mat-datepicker #fromPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field eawDatePickerOptions class="tw-col-span-1">
                <mat-label>{{ 'TO' | translate | async }}</mat-label>
                <input matInput formControlName="to" [min]="form.getRawValue().from" [matDatepicker]="toPicker">
                <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                <mat-datepicker #toPicker></mat-datepicker>
            </mat-form-field>

            @if (providedPayRate) {
                @if (providedPayRate.tariff) {
                    <mat-form-field class="tw-col-span-full">
                        <mat-label>{{ 'TARIFF' | translate | async }}</mat-label>
                        <input [value]="providedPayRate.tariff.name" matInput disabled>
                    </mat-form-field>
                } @else {
                    <mat-form-field class="tw-col-span-1">
                        <mat-label>{{ 'RATE' | translate | async }}</mat-label>
                        <input [value]="providedPayRate.rate" matInput disabled>
                    </mat-form-field>

                    <mat-form-field class="tw-col-span-1">
                        <mat-label>{{ 'TYPE' | translate | async }}</mat-label>
                        <input [value]="(providedPayRate.type | uppercase) + '_PAY' | translate:'payroll' | async" matInput disabled>
                    </mat-form-field>
                }
            } @else {
                <mat-checkbox eawCheckboxHelper formControlName="useTariff" class="tw-col-span-full tw-mb-8">
                    {{ 'USE_TARIFF' | translate: 'company' | async }}
                </mat-checkbox>

                @if (form.controls.useTariff.value) {
                    <eaw-autocomplete formControlName="tariff"
                                      class="tw-col-span-full"
                                      [hint]="'USE_TARIFF_HINT' | translate:'company'"
                                      [options]="tariffAutocompleteService.options"
                                      [triggers]="[form.controls.from]"
                                      [getter]="tariffAutocompleteService.getter({
                                          customerId: data.customerId,
                                          employeeId: data.employeeId,
                                          types: ['hourly', 'monthly'],
                                          from: form.controls.from.value || undefined,
                                      })"
                                      [setter]="tariffAutocompleteService.setter({customerId: data.customerId})">
                    </eaw-autocomplete>
                } @else {
                    <mat-form-field class="tw-col-span-1">
                        <mat-label>{{ 'RATE' | translate | async }}</mat-label>
                        <input type="number" inputmode="numeric" formControlName="rate" min="0" matInput>
                    </mat-form-field>

                    <mat-form-field class="tw-col-span-1">
                        <mat-label>{{ 'TYPE' | translate | async }}</mat-label>
                        <mat-select formControlName="type">
                            <mat-option *ngFor="let type of payTypes" [value]="type">{{ (type | uppercase) + '_PAY' | translateSync:'payroll' }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                }
            }
        </form>
    </mat-dialog-content>
}

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>

    <eaw-action-button [loading]="processing" (click)="submit()" [disabled]="form.invalid || form.disabled">
        @if (data.payRate) {
            {{ 'UPDATE_PAY_RATE' | translate:'company' | async }}
        } @else {
            {{ 'ADD_PAY_RATE' | translate:'company' | async }}
        }
    </eaw-action-button>
</mat-dialog-actions>
