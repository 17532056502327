type ElementInitializationRequirementCallback = (item: HTMLElement) => boolean;

export class ElementInitialization {
    private readonly requirementCallback: ElementInitializationRequirementCallback;
    private observer?: MutationObserver;

    constructor(
        private selectorQuery: string | HTMLElement,
        private target = document.body,
        requirementCallback?: ElementInitializationRequirementCallback,
    ) {
        this.requirementCallback = typeof requirementCallback === 'function' ? requirementCallback : () => true;
    }

    getElement() {
        return this.selectorQuery instanceof HTMLElement ? this.selectorQuery : this.target.querySelector(this.selectorQuery) as HTMLElement;
    }

    observe(): Promise<Element | null> {
        return new Promise((resolve) => {
            if (this.getElement() && this.requirementCallback(this.getElement())) {
                return resolve(this.getElement());
            } else {
                this.observer = new MutationObserver((_, observer) => {
                    if (this.getElement() && this.requirementCallback(this.getElement())) {
                        observer.disconnect();
                        resolve(this.getElement());
                    }
                });

                this.observer.observe(this.target, {
                    childList: true,
                    subtree: true,
                });
            }
        });
    }

    disconnect() {
        if (this.observer instanceof MutationObserver) {
            this.observer.disconnect();
        }
    }
}
