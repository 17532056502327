import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { Settings } from 'luxon';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateAdapter extends LuxonDateAdapter {
    /**
     * Overwrite first day of week in some instances
     */
    override getFirstDayOfWeek() {
        // Codes that use sunday as first day of week
        const countryCodes: Record<string, number> = {
            'en-US': 0,
        };

        // Use sunday or default to monday
        return countryCodes[Settings.defaultLocale] ?? 1;
    }
}
