import { Inject, Injectable } from '@angular/core';
import { DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { TimepunchHistoryDialogComponent } from './timepunch-history-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface TimepunchHistoryDialogData extends DialogData{
    customerId: number;
    employeeId: number;
    timepunchId: number;
}

@Injectable({
    providedIn: 'root',
})
export class TimepunchHistoryDialogService {

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
    ) {
    }

    open(customerId: number, employeeId: number, timepunchId: number) {
        return this.dialog.open<TimepunchHistoryDialogComponent, TimepunchHistoryDialogData, undefined>(TimepunchHistoryDialogComponent, {
            data: {
                size: DialogSize.Medium,
                customerId,
                employeeId,
                timepunchId,
            },
        });
    }
}
