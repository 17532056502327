<eaw-page-header [useBigFilter]="true">
    <div title>{{'SHIFT_SWAP_plural' | translate:'scheduling' | async}}</div>

    <div bigFilter>
        <form class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-8 sm:tw-gap-16" [formGroup]="formGroup">
            <mat-form-field>
                <mat-label>{{'SHIFT_SWAP_plural' | translate:'scheduling' | async}}</mat-label>
                <mat-select formControlName="swap">
                    <mat-option *ngFor="let swap of swaps" [value]="swap">{{swap.text | async}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'MODE' | translate | async}}</mat-label>
                <mat-select formControlName="mode" [compareWith]="modeComparer">
                    <mat-option *ngFor="let mode of modes" [value]="mode">{{mode.text | async}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field eawDatePickerOptions>
                <mat-label>{{'ENTER_DATE_RANGE' | translate | async}}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
                    <input formControlName="from" matStartDate [placeholder]="'START_DATE' | translate | async">
                    <input formControlName="to" matEndDate [placeholder]="'END_DATE' | translate | async">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </form>

        <button mat-raised-button color="primary" (click)="table.refresh()" [disabled]="formGroup.pristine || formGroup.invalid">{{'UPDATE' | translate | async}}</button>
    </div>
</eaw-page-header>

<mat-card>
    <mat-card-content class="tw-flex tw-flex-col">
        <eaw-data-table #table
                        [columns]="columns"
                        [request]="request"
                        (paginationChange)="updateTable()">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
