import { DataTableColumn, DataTableColumnAttributes } from './data-table-column';
import { NumberPortalComponent } from '../portals/number-portal/number-portal.component';
import { DataTableComparer } from '../interfaces/data-table-comparer';

interface DataTableNumberColumnData<Item> extends DataTableColumnAttributes<DataTableNumberColumn<Item>, Item, number | string> {
        compare?: DataTableComparer<DataTableNumberColumn<Item>, Item>
    numberFormatOptions?: Intl.NumberFormatOptions
}

export class DataTableNumberColumn<Item> extends DataTableColumn<DataTableNumberColumn<Item>, Item, number | string> {
    component = NumberPortalComponent;
    comparer: DataTableNumberColumnData<Item>['compare'];
    numberFormatOptions?: Intl.NumberFormatOptions;

    constructor(column: DataTableNumberColumnData<Item>) {
        super({
            exportable: true,
            ...column,
        });
        this.numberFormatOptions = column.numberFormatOptions;
        this.comparer = column.compare;

        if (this.header) {
            this.header.classes.add('header-right');
            this.header.arrowPosition = 'before';
        }
    }
}
