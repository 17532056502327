import { Component, inject, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButton } from '@angular/material/button';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { TimepunchBlocksService } from '../../http/timepunch-blocks.service';
import { catchError, EMPTY, Observable, startWith } from 'rxjs';
import { BusinessDate } from '../../../shared/utils/business-date';
import { TimepunchBlock } from '../../models/timepunch-block';
import { SnackBarService } from '../../../shared/services/snack-bar.service';

export type CreateEditTimepunchBlockDialogReturn = TimepunchBlock;

export interface CreateEditTimepunchBlockDialogData extends DialogData {
    customerId: number,
    employeeId: number,
    block?: Observable<TimepunchBlock>
}

interface TimepunchBlockForm {
    from: FormControl<DateTime | null>,
    to: FormControl<DateTime | null>,
    reason: FormControl<string | null>,
    hideReason: FormControl<boolean>,
}

@Component({
    selector: 'eaw-create-edit-timepunch-block-dialog',
    standalone: true,
    imports: [
        MatFormField,
        MatDialogModule,
        ReactiveFormsModule,
        TranslatePipe,
        MatDatepickerModule,
        CdkTextareaAutosize,
        DatePickerOptionsDirective,
        MatLabel,
        AsyncPipe,
        ActionButtonComponent,
        DialogHeaderComponent,
        MatSlideToggle,
        MatButton,
        MatInput,
        MatHint,
    ],
    templateUrl: './create-edit-timepunch-block-dialog.component.html',
    styleUrl: './create-edit-timepunch-block-dialog.component.scss',
})
export class CreateEditTimepunchBlockDialogComponent extends DialogComponent implements OnInit {
    private readonly snackBarService = inject(SnackBarService);
    private readonly timepunchBlocksService = inject(TimepunchBlocksService);

    blockId?: number;
    loading = false;
    processing = false;
    form: FormGroup<TimepunchBlockForm>;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateEditTimepunchBlockDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<CreateEditTimepunchBlockDialogComponent, CreateEditTimepunchBlockDialogReturn>,
    ) {
        super(dialogRef, data);

        this.form = new FormGroup<TimepunchBlockForm>({
            from: new FormControl(null, Validators.required),
            to: new FormControl(null),
            reason: new FormControl(null),
            hideReason: new FormControl(false, { nonNullable: true }),
        });
    }

    ngOnInit() {
        this.loading = !!this.data.block;
        this.data.block?.pipe(
            catchError(() => {
                void this.snackBarService.t('FAILED_TO_GET_DATA');
                this.dialogRef.close();
                return EMPTY;
            }),
        ).subscribe((block) => {
            this.blockId = block.id;
            this.patchForm(block);
            this.loading = false;
        });

        this.form.controls.reason.valueChanges.pipe(startWith(null)).subscribe((value) => {
            const hideControl = this.form.controls.hideReason;

            if (value) {
                hideControl.enable();
            } else {
                hideControl.setValue(false);
                hideControl.disable();
            }
        });
    }

    patchForm(block: TimepunchBlock) {
        this.form.patchValue({
            from: block.startDate.dateTime,
            to: block.endDate?.dateTime,
            reason: block.reason,
            hideReason: !block.displayReason,
        });
    }

    handle(observable: Observable<TimepunchBlock>) {
        this.form.disable();
        this.processing = true;

        observable.pipe(
            catchError(() => {
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe((res) => {
            this.dialogRef.close(res);
        });
    }

    create() {
        const value = this.form.getRawValue();
        if (!value.from) {
            return;
        }

        this.handle(this.timepunchBlocksService.create(
            this.data.customerId,
            this.data.employeeId,
            new BusinessDate(value.from),
            value.to ? new BusinessDate(value.to) : undefined,
            value.reason || undefined,
            !value.hideReason,
        ));
    }

    update() {
        const value = this.form.getRawValue();
        if (!value.from || !this.blockId) {
            return;
        }

        this.handle(this.timepunchBlocksService.update(
            this.data.customerId,
            this.data.employeeId,
            this.blockId,
            new BusinessDate(value.from),
            value.to ? new BusinessDate(value.to) : undefined,
            value.reason || undefined,
            !value.hideReason,
        ));
    }
}
