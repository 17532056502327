import { ChangeDetectionStrategy, Component, inject, Inject, OnInit, signal } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { WidgetInfoService } from '../../services/widget-info.service';
import { WidgetInfo } from '../../classes/widget-info';
import { MatListModule } from '@angular/material/list';
import { TranslateService } from '../../../shared/services/translate.service';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../shared/services/current.service';
import { WidgetService } from '../../http/widget.service';
import { forkJoin } from 'rxjs';
import { WidgetSettingsDialog, WidgetSettingsDialogData } from '../../classes/widget-settings-dialog';
import { WidgetPropertySettings } from '../../classes/widget';
import { environment } from '../../../../environments/environment';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { MatRippleModule } from '@angular/material/core';

export interface AddWidgetDialogResult {
    widgetKey: string;
    settings?: WidgetPropertySettings;
}

export interface AddWidgetDialogData extends DialogData {
    settingGroupId: number;
    customerId: number;
    userId: number;
}

interface WidgetListItem {
    info: WidgetInfo;
    translatedName: string;
    noInfo: boolean;
}

@Component({
    selector: 'eaw-add-widget-dialog',
    templateUrl: './add-widget-dialog.component.html',
    styleUrl: './add-widget-dialog.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatListModule,
        NgFor,
        MatIconModule,
        MatDividerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        MatRippleModule,
    ],
})
export class AddWidgetDialogComponent extends DialogComponent implements OnInit {
    private widgetInfoService = inject(WidgetInfoService);
    private widgetService = inject(WidgetService);
    private matDialog = inject(MatDialog);
    private translate = inject(TranslateService);
    private current = inject(CurrentService);

    protected isProduction = signal(environment.isProduction);
    protected normalWidgets = signal<WidgetListItem[]>([]);
    protected miniWidgets = signal<WidgetListItem[]>([]);
    protected loading = signal(true);

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AddWidgetDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AddWidgetDialogComponent, AddWidgetDialogResult>,
    ) {
        data.size ||= DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit() {
        void this.assignWidgets();
    }

    async assignWidgets() {
        forkJoin([
            this.widgetInfoService.get(),
            this.widgetService.getAll(this.data.customerId, this.data.userId),
            this.widgetService.getDefault(this.data.settingGroupId),
            this.widgetService.getHiddenWidgets(this.data.customerId),
        ]).subscribe(async ([ widgetInfos, widgets, defaultWidgets, hiddenWidgets ]) => {
            console.debug('Widgets', widgets);
            console.debug('Default widgets', defaultWidgets);

            // Unavailable widgets are the default widgets + the widgets that are already on the dashboard
            const unavailableWidgets = [ ...defaultWidgets, ...hiddenWidgets, ...widgets.map((w) => w.info.key) ];

            for (const widget of widgetInfos) {
                const target = widget.mini ? this.miniWidgets() : this.normalWidgets();
                const isUnavailable = unavailableWidgets.includes(widget.key);

                // Skip if unavailable and not able to add multiple
                if (isUnavailable && !widget.multiple) {
                    continue;
                }

                target.push({
                    info: widget,
                    translatedName: await this.translate.t(widget.translationKey, widget.translationNs),
                    noInfo: !!widget.noInfo,
                });
            }

            this.normalWidgets.update((widgets) => sort(widgets, this.current.languageTag, [ (w) => w.translatedName ]));
            this.miniWidgets.update((widgets) => sort(widgets, this.current.languageTag, [ (w) => w.translatedName ]));
            this.loading.set(false);
        });
    }

    choose(item: WidgetListItem) {
        if (item.info.settingsDialog) {
            this.matDialog.open<WidgetSettingsDialog<any>, WidgetSettingsDialogData, WidgetPropertySettings>(item.info.settingsDialog, {
                data: {
                    widgetInfo: item.info,
                },
            }).afterClosed().subscribe((settings) => {
                if (!settings) {
                    return;
                }

                this.dialogRef.close({
                    widgetKey: item.info.key,
                    settings,
                });
            });
        } else {
            this.dialogRef.close({
                widgetKey: item.info.key,
            });
        }
    }
}
