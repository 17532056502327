import { environment } from '../../../environments/environment';

interface CordovaDevice {
    available: boolean;
    // Version of Cordova running on the device.
    cordova: string;
    isVirtual: boolean;
    isiOSAppOnMac: boolean;
    manufacturer: string;
    // Model of the phone/device. Ex. iPhone10,6 or Pixel 2 XL.
    model: string;
    // Platform. Ex. Android or iOS.
    platform: string;
    serial: string;
    uuid: string;
    version: string;
}

class EawMobile {
    protected callbacks: Record<string, () => void> = {};
    protected mobile?: boolean;

    get isTouchDevice() {
        return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
    }

    get isMobile() {
        if (this.mobile === undefined) {
            this.mobile = window.self !== window.top;
        }

        return this.mobile;
    }

    set isMobile(mobile) {
        this.mobile = mobile;
    }

    $get() {
        return this;
    }

    /**
     * Calling the same command multiple times will overwrite the previous callback.
     *
     * @param {string} command
     * @param args
     */
    postMessage(command: string, ...args: any[]) {
        if (typeof args?.[args.length - 1] === 'function') {
            if (!environment.isTesting && Object.prototype.hasOwnProperty.call(this.callbacks, command)) {
                console.warn('Overwriting callback for command', command);
            }

            this.callbacks[command] = args.pop();
        }

        parent?.postMessage({
            command,
            arguments: args,
        }, '*');
    }

    receiveMessage(event: { data: { command: string | number; arguments: any; }; }) {
        if (!event.data.command) {
            return;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (!this.callbacks.hasOwnProperty(event.data.command)) {
            return;
        }

        // Return false to keep to callback registered.
        // @ts-ignore
        if (this.callbacks[event.data.command]?.(...event.data.arguments) !== false) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete this.callbacks[event.data.command];
        }
    }

    init() {
        addEventListener('message', this.receiveMessage.bind(this));
    }

    pause(callback: () => void) {
        this.postMessage('pause', callback);
    }

    resume(callback: () => void) {
        this.postMessage('resume', callback);
    }

    getDevice() {
        return new Promise<CordovaDevice | undefined>((resolve) => {
            this.postMessage('getDevice', (device: unknown) => {
                const cordova = typeof device === 'object' && device !== null ? device as CordovaDevice : null;
                return cordova ? resolve(cordova) : resolve(undefined);
            });
        });
    }

    removeSplash() {
        this.postMessage('splash', false);
    }

    camera(callback: () => void) {
        // 0 = Base64 string
        this.postMessage('camera', { destinationType: 0 }, callback);
    }

    getPushId(callback: any) {
        this.postMessage('getPushId', callback);
    }

    setBadge(num: number, callback?: () => void) {
        this.postMessage('setBadge', num, callback);
    }

    openBrowser(url: string) {
        this.postMessage('openBrowser', url);
    }

    storageKeys(): Promise<string[]> {
        return new Promise((resolve, reject) => {
            this.postMessage('storageKeys', (keys: string[]) => resolve(keys), (e: unknown) => reject(e));
        });
    }

    storageGet(key: string) {
        return new Promise<string | null>((resolve) => {
            if (!this.isMobile) {
                return resolve(null);
            }

            this.postMessage('storageGet', key, (result: unknown) => {
                resolve(typeof result === 'string' ? result : null);
            });
        });
    }

    /**
     * @param key
     * @param value
     * @Returns - The value that was set (or undefined if not set).
     */
    storageSet(key: string, value: string | null) {
        return new Promise<string | undefined>((resolve) => {
            if (!this.isMobile) {
                return resolve(undefined);
            }

            if (value == null) {
                return resolve(undefined);
            }

            this.postMessage('storageSet', key, value, (result: unknown) => {
                resolve(typeof result === 'string' ? result : undefined);
            });
        });
    }

    storageRemove(key: string) {
        return new Promise<void>((resolve) => {
            this.postMessage('storageRemove', key, () => resolve());
        });
    }

    storageClear() {
        return new Promise<void>((resolve) => {
            this.postMessage('storageClear', () => resolve());
        });
    }

    /**
     * Sets the provided color or the default color if none is provided.
     * @param color
     */
    setStatusBarColor(color?: string) {
        this.postMessage('setStatusBarColor', color || '#5a626a');
    };
}

export const Mobile: EawMobile = new EawMobile();
