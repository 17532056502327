import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, EMPTY, Observable, switchMap, throwError } from 'rxjs';
import { LoginService } from '../services/login.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { TranslateService } from '../services/translate.service';

@Injectable()
export class UnauthenticatedErrorInterceptor implements HttpInterceptor {
    private readonly loginService = inject(LoginService);
    private readonly translate = inject(TranslateService);

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    if (error.url?.endsWith('/oauth/token')) {
                        this.loginService.broadcastLoginError(error);
                    }

                    if (this.loginService.isLoggedIn()) {
                        return fromPromise(this.loginService.logout(undefined, {
                            message: this.translate.t('NOT_AUTHORIZED', 'errors'),
                        })).pipe(
                            catchError(() => EMPTY),
                            switchMap(() => EMPTY),
                        );
                    }

                    return EMPTY;
                }

                return throwError(() => error);
            }),
        );
    }
}
