// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';

module('eaw.admin').component('settingGroupAbsenceType', {
    template: `<script type="text/ng-template" id="adminSettingGroupAbsenceGradable">
    <md-icon ng-if="!item.gradable" ng-bind="'close'" md-colors="{color: 'red'}"></md-icon>
    <md-icon ng-if="item.gradable" ng-bind="'done'" md-colors="{color: 'green'}"></md-icon>
</script>

<script type="text/ng-template" id="adminSettingGroupAbsenceTypePaid">
    <md-icon ng-if="!item.paid" ng-bind="'close'" md-colors="{color: 'red'}"></md-icon>
    <md-icon ng-if="item.paid" ng-bind="'done'" md-colors="{color: 'green'}"></md-icon>
</script>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="ABSENCE_TYPE_plural"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="$saCtrl.includeInactive"
                        icon="history"
                        ng-change="$saCtrl.reloadTable()"
                        i18n-tooltip="SHOW_INACTIVE">
        </eaw-toggle-btn>

        <card-btn-float on-click="$saCtrl.openAddAbsenceType()"></card-btn-float>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <eaw-dynamic-ng-table
                columns="$saCtrl.table.columns"
                cells="$saCtrl.table.cells"
                factory="AbsenceTypeFactory"
                factory-function="getAll"
                reload-table="$saCtrl.reload"
                mark-inactive="true"
                factory-function-arguments="{
                                entityId: $saCtrl.settingGroup.id,
                                includeInactive: $saCtrl.includeInactive,
                                settingGroups: true,
                            }">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$saCtrl',
    bindings: {
        settingGroup: '<',
    },
    controller: [ 'AbsenceTypeDialog', function(AbsenceTypeDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.table = {
                columns: [
                    {
                        title: '#',
                        sortable: 'id',
                    },
                    {
                        title: t('NAME'),
                        sortable: 'name',
                    },
                    {
                        title: t('DESCRIPTION'),
                        sortable: 'description',
                    },
                    {
                        title: t('admin:TRANSLATED_DESCRIPTION'),
                    },
                    {
                        title: t('SPAN'),
                        sortable: 'span',
                    },
                    {
                        title: t('CODE'),
                        sortable: 'code',
                    },
                    {
                        title: t('admin:PAID'),
                        class: 'text-center',
                    },
                    {
                        title: t('admin:GRADABLE'),
                        class: 'text-center',
                    },
                    {
                        title: t('absence_types:APPROVAL_DEADLINE'),
                    },
                    {
                        title: t('FROM'),
                        sortable: 'from',
                    },
                    {
                        title: t('To'),
                        sortable: 'to',
                    },
                    { title: '' },
                ],
                cells: [
                    { template: 'item.id' },
                    { template: 'item.name' },
                    { template: 'item.description' },
                    { template: 'item.description | eawTranslate:"absence_types"' },
                    { template: 'item.span' },
                    { template: 'item.code' },
                    {
                        templateUrl: 'adminSettingGroupAbsenceTypePaid',
                        class: 'text-center',
                    },
                    {
                        templateUrl: 'adminSettingGroupAbsenceGradable',
                        class: 'text-center',
                    },
                    {
                        template: 'item.approval_deadline_days ? ("N_DAY" | i18next: {count: item.approval_deadline_days}) : ""',
                    },
                    { template: 'item.from | moment' },
                    { template: 'item.to | moment' },
                    {
                        buttons: [
                            {
                                icon: 'edit',
                                click: (type) => AbsenceTypeDialog.edit(type).then(ctrl.reloadTable),
                            },
                            {
                                icon: 'delete',
                                type: 'warn',
                                click: (type) => AbsenceTypeDialog.delete(type).then(ctrl.reloadTable),
                            },
                        ],
                    },
                ],
            };
        };

        ctrl.openAddAbsenceType = () => AbsenceTypeDialog.add(ctrl.settingGroup.id).then(ctrl.reloadTable);

        ctrl.reloadTable = () => {
            ctrl.reload = true;
        };
    } ],
});
