// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

const template1 = `<md-list>
    <md-list-item ng-repeat="action in ::$ctrl.actions track by $index" ng-click="$ctrl.go(action)">
        <md-icon md-svg-src="{{action.icon}}"></md-icon>
        <p ng-bind="action.name"></p>
    </md-list-item>
</md-list>
`;

module('eaw.dashboard').component('messageList', {
    template: template1,
    controller: [ '$state', '$mdDialog', function($state, $mdDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.actions = [
                {
                    name: t('messages:NEW_MESSAGE'),
                    icon: 'message-plus',
                    route: `eaw/app/messages/conversations/write`,
                },
                {
                    name: t('messages:INBOX'),
                    icon: 'message-arrow-left',
                    route: `eaw/app/messages/inbox`,
                },
                {
                    name: t('messages:OUTBOX'),
                    icon: 'message-arrow-right',
                    route: `eaw/app/messages/outbox`,
                },
            ];
        };

        ctrl.go = (action) => {
            $mdDialog.hide();
            $state.go(action.route);
        };
    } ],
});
