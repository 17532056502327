import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { BalanceType } from '../../models/balance-type';
import { EmployeeService } from '../../../shared/http/employee.service';
import { Employee } from '../../../shared/models/employee';
import { BalanceDialogComponent, BalanceDialogComponentData } from '../balance-dialog/balance-dialog.component';
import { DateTime } from 'luxon';
import { CurrentService } from '../../../shared/services/current.service';
import { BalanceChange } from '../../models/balance-change';
import { BalanceHistoryComponent } from '../../components/balance-history/balance-history.component';
import { csvCreator } from '../../../shared/utils/csv-creator';
import { TranslateService } from '../../../shared/services/translate.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { NgIf, AsyncPipe } from '@angular/common';

export interface BalanceHistoryDialogData extends DialogData {
    customerId: number;
    employeeId: number;
    balanceType: Observable<BalanceType>;
    allowChanges?: boolean;
}

@Component({
    selector: 'eaw-balance-history-dialog',
    templateUrl: './balance-history-dialog.component.html',
    styleUrl: './balance-history-dialog.component.scss',
    standalone: true,
    imports: [
        NgIf,
        DialogHeaderComponent,
        MatDialogContent,
        MatProgressSpinnerModule,
        BalanceHistoryComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class BalanceHistoryDialogComponent extends DialogComponent implements OnInit {
    @ViewChild('balanceHistoryComponent') balanceHistoryComponent?: BalanceHistoryComponent;

    balanceType?: BalanceType;
    employee?: Employee;
    showAddBalanceButton = false;
    allowDeleting = false;
    balanceChanged = false;
    exporting = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: BalanceHistoryDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<BalanceHistoryDialogComponent, boolean>,
        @Inject(EmployeeService) protected employeeService: EmployeeService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(CurrentService) protected current: CurrentService,
        @Inject(TranslateService) protected translate: TranslateService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit() {
        const getBalanceType = this.data.balanceType.pipe(switchMap((balanceType) => {
            if (this.data.allowChanges) {
                return forkJoin([
                    this.permissionCheckService.isAllowed(`customers.${this.data.customerId}.employees.${this.data.employeeId}.balances.${balanceType.balanceCode}.create`),
                    this.permissionCheckService.isAllowed(`customers.${this.data.customerId}.employees.${this.data.employeeId}.balances.${balanceType.balanceCode}.changes.*.delete`),
                ]).pipe(map(([ canAdd, canDelete ]) => {
                    this.showAddBalanceButton = canAdd;
                    this.allowDeleting = canDelete;
                    return balanceType;
                }));
            }

            return of(balanceType);
        }));

        forkJoin([ getBalanceType, this.employeeService.get(this.data.customerId, this.data.employeeId) ]).subscribe(([ balanceType, employee ]) => {
            this.balanceType = balanceType;
            this.employee = employee;
        });
    }

    export() {
        const employee = this.employee;
        const type = this.balanceType;
        if (!employee || !type || !this.balanceHistoryComponent) {
            return;
        }

        this.exporting = true;
        this.balanceHistoryComponent.export().subscribe(async (res) => {
            const headers = [ await this.translate.t('DATE'), await this.translate.t('CHANGE'), await this.translate.t('DESCRIPTION') ];
            const data = res.map((row) => {
                return [ row.businessDate.toSQLDate(), row.delta / type.factor, row.description ];
            });

            csvCreator(headers, data, true, `${employee.name} - ${await this.translate.t(type.nameTranslation.key, type.nameTranslation.ns)}`);
            this.exporting = false;
        });
    }

    addBalance() {
        this.hideDialog();

        this.matDialog.open<BalanceDialogComponent, BalanceDialogComponentData, BalanceChange>(BalanceDialogComponent, {
            data: {
                date: DateTime.now(),
                customerId: this.data.customerId,
                employeeId: this.data.employeeId,
                employeeName: this.employee?.name || '',
                balanceType: this.data.balanceType,
            },
        }).afterClosed().subscribe((res) => {
            this.balanceChanged = this.balanceChanged || !!res;

            if (res) {
                this.balanceHistoryComponent?.refresh();
            }

            this.showDialog();
        });
    }
}
