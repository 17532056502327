import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, HostBinding, inject, input, OnInit, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Observable } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { InfoLoadingComponent } from '../info-loading/info-loading.component';
import { Mobile } from '../../utils/eaw-mobile';

@Component({
    selector: 'eaw-pdf-renderer',
    standalone: true,
    imports: [ CommonModule, ReactiveFormsModule, MatButtonModule, MatIconModule, MatMenuModule, TranslatePipe, InfoLoadingComponent ],
    templateUrl: './pdf-renderer.component.html',
    styleUrl: './pdf-renderer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfRendererComponent implements OnInit {
    private readonly domSanitizer = inject(DomSanitizer);

    @HostBinding('class.fullscreen') get isFullscreen() {
        return this.fullscreen();
    };

    @HostBinding('class.fallback') get useFallback() {
        return !this.pdfViewerEnabled();
    };

    private canvas = viewChild<ElementRef<HTMLCanvasElement>>('canvas');

    url = input.required<Observable<string>>();
    title = input<string>();

    private pdf: PDFDocumentProxy | null = null;
    resolvedUrl = signal(undefined as string | undefined);
    loading = computed(() => !this.resolvedUrl());
    scale = signal(100);
    scales = [ 25, 33, 50, 67, 75, 80, 90, 100 ] as const;
    fullscreen = signal(false);
    renderedPageNumber = signal(1);
    pageNumControl = new FormControl<number>(1, { nonNullable: true });
    numPages = signal(0);
    safeUrl = signal(null as SafeResourceUrl | null);
    /** If the browser supports inline pdf. Safari at least up to 17.4.1 say it does, but doesn't */
    pdfViewerEnabled = signal(window.navigator.pdfViewerEnabled && !Mobile.isMobile);

    constructor() {
        effect(() => {
            const url = this.resolvedUrl();
            const canvas = this.canvas()?.nativeElement;

            if (!this.pdfViewerEnabled() && url && canvas) {
                void this.setupPdfViewer(canvas, url);
            }
        });
    }

    ngOnInit() {
        this.getUrl();
    }

    getUrl() {
        this.url().subscribe((url) => {
            this.resolvedUrl.set(url);

            if (this.pdfViewerEnabled()) {
                this.safeUrl.set(this.domSanitizer.bypassSecurityTrustResourceUrl(url));
            }
        });
    }

    async setupPdfViewer(canvas: HTMLCanvasElement, url: string) {
        await this.initPdfViewer(canvas, url);

        this.pageNumControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
        ).subscribe((value) => {
            void this.renderPage(canvas, value);
        });
    }

    async initPdfViewer(canvas: HTMLCanvasElement, url: string) {
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        const pdfjsLib = await require(/* webpackChunkName: "pdfjsLib" */ 'pdfjs-dist');
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        pdfjsLib.GlobalWorkerOptions.workerSrc = await require(/* webpackChunkName: "pdfjsWorker" */ 'pdfjs-dist/build/pdf.worker');

        this.pdf = await pdfjsLib.getDocument(url).promise as PDFDocumentProxy;
        this.numPages.set(this.pdf.numPages);

        void this.setTitle(this.pdf);
        void this.renderPage(canvas, 1, this.scale() / 100);
    }

    setFullscreen(fullscreen: boolean) {
        this.fullscreen.set(fullscreen);
    }

    nextPage() {
        this.pageNumControl.setValue(Math.min(this.pageNumControl.value + 1, this.numPages()));
    }

    previousPage() {
        this.pageNumControl.setValue(Math.max(this.pageNumControl.value - 1, 1));
    }

    async setTitle(pdf: PDFDocumentProxy) {
        this.title ||= ((await pdf.getMetadata()).info as any)?.Title || '';
    }

    zoomIn() {
        const canvas = this.canvas()?.nativeElement;
        if (!canvas) {
            return;
        }

        const index = Math.min(this.scales.findIndex((zoom) => zoom === this.scale()) + 1, this.scales.length - 1);
        this.scale.set(this.scales[index] || this.scales[7]);

        void this.renderPage(canvas, this.renderedPageNumber(), this.scale() / 100);
    }

    zoomOut() {
        const canvas = this.canvas()?.nativeElement;
        if (!canvas) {
            return;
        }

        const index = Math.max(this.scales.findIndex((zoom) => zoom === this.scale()) - 1, 0);
        this.scale.set(this.scales[index] || this.scales[7]);

        void this.renderPage(canvas, this.renderedPageNumber(), this.scale() / 100);
    }

    async renderPage(canvas: HTMLCanvasElement, pageNumber: number, scale?: number) {
        const page = await this.pdf?.getPage(pageNumber);
        if (!page) {
            return;
        }

        const context = canvas.getContext('2d');
        if (!context) {
            return;
        }

        const viewport = page.getViewport({ scale: scale || (this.scale() / 100) });
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        try {
            page.render({
                canvasContext: context,
                viewport,
            });
        } catch (e) {
            console.error('Failed to render PDF:', e);
            return;
        }

        this.renderedPageNumber.set(pageNumber);
    }
}
