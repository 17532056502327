import { inject, Injectable } from '@angular/core';
import { CurrentService } from '../../shared/services/current.service';
import { MainMenuEntry } from '../classes/main-menu-entry';
import type { User } from '../../shared/models/user';
import { MainMenuParentEntry } from '../classes/main-menu-parent-entry';
import { PermissionCheckService } from '../../shared/services/permission-check.service';
import { catchError, forkJoin, map, Observable, of, shareReplay, Subject, switchMap } from 'rxjs';
import { UIRouter } from '@uirouter/core';
import { CustomerProductService } from '../../shared/http/customer-product.service';
import { SettingService } from '../../shared/http/setting.service';
import { Setting } from '../../shared/models/setting';
import { SsoServiceService } from '../../sso/http/sso-service.service';
import { MainMenuEntryData } from '../interfaces/main-menu-entry-data';
import { MainMenuDataService } from './main-menu-data.service';
import { LinksService } from '../../links/http/links.service';
import { Products } from '../../shared/enums/products';
import { Link } from '../../links/models/link';
import { Employee } from '../../shared/models/employee';
import { StateProviderDataDataSettingCheck } from '../../shared/utils/create-state';

@Injectable({
    providedIn: 'root',
})
export class MainMenuService {
    private readonly uiRouter = inject(UIRouter);
    private readonly current = inject(CurrentService);
    private readonly linksService = inject(LinksService);
    private readonly ssoService = inject(SsoServiceService);
    private readonly settingService = inject(SettingService);
    private readonly mainMenuDataService = inject(MainMenuDataService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly customerProductService = inject(CustomerProductService);

    private readonly reloadMenuSubject = new Subject<void>();
    private readonly reloadMenuObservable: Observable<void>;

    constructor() {
        this.reloadMenuObservable = this.reloadMenuSubject.asObservable().pipe(shareReplay(1));
    }

    reload() {
        this.reloadMenuSubject.next();
    }

    reloadingMenu(): Observable<void> {
        return this.reloadMenuObservable;
    }

    getLinks(): Observable<Link[]> {
        return forkJoin([
            this.permissionCheckService.isAllowed(`customers.${this.customer.id}.hyper_links.*.get`),
            this.customerProductService.hasProducts(this.customer.id, [ Products.Links ]),
        ]).pipe(
            switchMap(([ hasPermission, hasProducts ]) => {
                if (!hasProducts || !hasPermission) {
                    return of([] as Link[]);
                }

                return this.linksService.getAll(this.customer.id, { main_menu: true, per_page: 20 }).pipe(
                    map((list) => list.data),
                    shareReplay(1),
                );
            }),
        );
    }

    private requiresEmployeeCheck(requiresEmployee: boolean, employee?: Employee) {
        return !requiresEmployee || !!employee;
    }

    private allowExternalCheck(allowExternal: boolean | undefined, isExternal: boolean) {
        if (allowExternal === undefined) {
            return true;
        }

        return !(isExternal && !allowExternal);
    }

    private productsCheck(products: Products[], availableProducts: Set<Products>) {
        return products.every((product) => availableProducts.has(product));
    }

    private settingsCheck(settings: Record<string, StateProviderDataDataSettingCheck>, settingsMap: Map<string, Setting>) {
        for (const [ key, callback ] of Object.entries(settings)) {
            const setting = settingsMap.get(key);
            if (!setting || !callback(setting.value)) {
                return false;
            }
        }

        return true;
    }

    private shouldAddEntry(entry: MainMenuEntryData, availableProducts: Set<Products>, settings: Map<string, Setting>) {
        return [
            this.requiresEmployeeCheck(entry.stateData?.requiresEmployee || false, this.employee),
            this.allowExternalCheck(entry.stateData?.allowExternal, this.employee?.isExternal(this.customer.id) || false),
            this.productsCheck(entry.stateData?.products || [], availableProducts),
            this.settingsCheck(entry.stateData?.settings || {}, settings),
        ].every(Boolean);
    }

    private getAvailableProducts(menu: MainMenuEntryData[]) {
        const products = menu.reduce((acc, entry) => {
            entry.stateData?.products?.forEach((product) => acc.add(product));
            return acc;
        }, new Set<Products>);

        return this.customerProductService.hasProductsDetailed(this.customer.id, Array.from(products)).pipe(
            catchError(() => of(null)),
            map((products) => {
                return Object.entries(products?.products || {}).reduce((acc, [ key, value ]) => {
                    if (value) {
                        acc.add(key as Products);
                    }

                    return acc;
                }, new Set<Products>());
            }),
        );
    }

    private getSettings(menu: MainMenuEntryData[]) {
        const settings = menu.reduce((acc, entry) => {
            Object.keys(entry.stateData?.settings || {}).forEach((key) => acc.add(key));
            return acc;
        }, new Set<string>());

        return this.settingService.getSome([ 'customers', this.customer.id ], { 'settings[]': Array.from(settings) }).pipe(
            catchError(() => of([] as Setting[])),
            map((settingsResponse) => {
                return settingsResponse.reduce((acc, setting) => {
                    acc.set(setting.key, setting);
                    return acc;
                }, new Map<string, Setting>);
            }),
        );
    }

    private rebuildMenu(menuData: MainMenuEntryData[]) {
        const newMenu: MainMenuEntry[] = [];
        const subMenuElements = menuData.reduce((acc, entry) => {
            entry.subMenu?.forEach((subEntry) => acc.add(subEntry.key));
            return acc;
        }, new Set<string>());

        menuData.forEach((dataEntry) => {
            if (dataEntry.subMenu) {
                const submenu = dataEntry.subMenu.map((dataSubEntry) => {
                    const sub = menuData.find((dataEntry) => dataEntry.key === dataSubEntry.key);
                    const subEntry = sub ? new MainMenuEntry(sub, this.customer.id) : null;
                    subEntry?.href.set(this.getHref(subEntry?.state, subEntry?.params));
                    return subEntry;
                }).filter((entry) => entry !== null);

                if (submenu.length) {
                    const parentEntry = new MainMenuParentEntry(dataEntry, this.customer.id);
                    parentEntry.subMenu = submenu;
                    parentEntry.href.set(this.getHref(parentEntry.state, parentEntry.params));
                    newMenu.push(parentEntry);
                }
            } else if (!subMenuElements.has(dataEntry.key)) {
                const item = new MainMenuEntry(dataEntry, this.customer.id);
                item.href.set(this.getHref(item.state, item.params));
                newMenu.push(item);
            }
        });

        return newMenu;
    }

    get() {
        return this.getSsoServices().pipe(
            switchMap((ssoServices) => {
                const flatMenu = [ ...this.mainMenuDataService.get(), ...ssoServices ].reduce<MainMenuEntryData[]>((acc, entry) => acc.concat(entry, ...(entry.subMenu || [])), []);

                return forkJoin([
                    this.getAvailableProducts(flatMenu),
                    this.getSettings(flatMenu),
                ]).pipe(
                    map(([ availableProducts, settings ]) => flatMenu.filter((entry) => this.shouldAddEntry(entry, availableProducts, settings))),
                    map((menu) => this.rebuildMenu(menu)),
                );
            }),
        );
    }

    getHref(state?: string, params?: { key: string, value: string | number }[]) {
        return this.uiRouter.stateService.href(state || '', params?.reduce((obj: Record<string, string | number>, p) => {
            let value = p.value;

            if (typeof value === 'string') {
                value = value.replace(`{employee}`, String(this.employee?.id));
                value = value.replace(`{customer}`, String(this.customer.id));
                value = value.replace(`{user}`, String(this.user.id));
            }

            obj[p.key] = value;
            return obj;
        }, {}));
    }

    // Make a getter shorthand
    private get user(): User {
        return this.current.getUser();
    }

    // Make a getter shorthand
    private get customer() {
        return this.current.getCustomer();
    }

    // Make a getter shorthand
    private get employee() {
        return this.current.getEmployee();
    }

    private getSsoServices(): Observable<MainMenuEntryData[]> {
        return this.ssoService.getAllForCustomer(this.customer.id, true).pipe(
            map((servicesResponse) => {
                return this.mainMenuDataService.getAvailableSingleSignOns().filter((entry) => {
                    return servicesResponse.data.find((ssoService) => ssoService.name.toLowerCase() === entry.ssoService?.toLowerCase());
                });
            }),
        );
    }
}
