// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';
module('eaw.login.auth').factory('authenticationInterceptor', [ '$injector', function($injector) {
    // @ts-ignore
    const interceptor = this;
    interceptor.responseError = function(error) {
        // Unauthorized
        if (error.status === 401) {
            const LoginServiceDowngrade = $injector.get('LoginServiceDowngrade');
            if (LoginServiceDowngrade.isLoggedIn()) {
                LoginServiceDowngrade.logout();
            }

            return Promise.reject(error);
        }
        if (error.status === 403 && error.data?.validation_error_code === 'TOS_NOT_ACCEPTED') {
            CurrentOld.getMe().acceptedTos = false;
            const $state = $injector.get('$state');
            return $state.transitionTo('eaw/app/tasks');
        }
        return Promise.reject(error);
    };
    return interceptor;
} ]);
