import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DateTime, Info } from 'luxon';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { timeStringToHourMin } from '../../angularjs/modules/misc/services/easy-funcs.service';
import { MatSelectionList, MatListModule } from '@angular/material/list';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { SubheaderComponent } from '../../components/subheader/subheader.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { ActionButtonComponent } from '../../components/action-button/action-button.component';

export type DatePickerDialogReturn = {value: DateTime | null, ok: boolean};

export interface DatePickerDialogData extends DialogData {
    // What do we want to have returned from the dialog
    target: 'time' | 'date' | 'month' | 'year';
    date?: DateTime;
    minDate?: DateTime;
    maxDate?: DateTime;
    dateFilter?: (date: DateTime) => boolean;
    includeTime?: boolean;
    title?: Promise<string>;
    subtitle?: Promise<string>;
    // If returned value can be null
    nullable?: boolean;
    confirmText?: Promise<string>;
}

@Component({
    selector: 'eaw-date-picker-dialog',
    templateUrl: './date-picker-dialog.component.html',
    styleUrl: './date-picker-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        ReactiveFormsModule,
        MatDatepickerModule,
        SubheaderComponent,
        MatListModule,
        NgFor,
        MatDialogActions,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
        ActionButtonComponent,
    ],
})
export class DatePickerDialogComponent extends DialogComponent implements OnInit, AfterViewInit {
    @ViewChild('monthsList') monthsList?: MatSelectionList;
    @ViewChild('yearsList') yearsList?: MatSelectionList;

    timeControl = new FormControl<string | null>(DateTime.now().startOf('day').toLocaleString(DateTime.TIME_24_SIMPLE));
    date: DateTime | null = null;
    dateTime: DateTime | null = null;
    minDate: DateTime | null;
    maxDate: DateTime | null;
    dateFilter: (date: DateTime) => boolean;
    years = new Array(DateTime.now().year - 1900 + 1).fill(0).map((_, i) => {
        return {
            year: 1900 + i,
            selected: false,
        };
    }).reverse();

    months = Info.months().map((m, i) => {
        return {
            name: m,
            month: i + 1,
            selected: false,
        };
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: DatePickerDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<DatePickerDialogComponent, DatePickerDialogReturn>,
    ) {
        data.size = DialogSize.Small;
        data.nullable ??= true;

        super(dialogRef, data);

        if (data.date) {
            this.date = data.date;
            this.dateTime = data.date;
            this.timeControl.setValue(data.date.toLocaleString(DateTime.TIME_24_SIMPLE));

            const year = this.years.find((y) => y.year === data.date?.year);
            if (year) {
                year.selected = true;
            }

            const month = this.months.find((m) => m.month === data.date?.month);
            if (month) {
                month.selected = true;
            }
        }

        this.minDate = data.minDate || null;
        this.maxDate = data.maxDate || null;
        this.dateFilter = data.dateFilter || (() => true);
    }

    ngOnInit() {
        this.timeControl.valueChanges.subscribe(() => {
            this.combine();
        });
    }

    ngAfterViewInit() {
        this.yearsList?.selectedOptions.selected[0]?.focus();
        this.monthsList?.selectedOptions.selected[0]?.focus();
    }

    combine() {
        const time = this.getTime();
        const listYear = this.yearsList?.selectedOptions.selected[0]?.value;
        const listMonth = this.monthsList?.selectedOptions.selected[0]?.value;

        switch (this.data.target) {
            case 'time': {
                this.dateTime = time;
                break;
            }
            case 'date': {
                this.dateTime = DateTime.fromObject({
                    year: this.date?.year,
                    month: this.date?.month,
                    day: this.date?.day,
                    hour: time?.hour || 0,
                    minute: time?.minute || 0,
                });
                break;
            }
            case 'month': {
                if (listYear && listMonth) {
                    this.dateTime = DateTime.fromObject({
                        year: listYear,
                        month: listMonth,
                    });
                }
                break;
            }
            case 'year': {
                if (listYear) {
                    this.dateTime = DateTime.fromObject({ year: listYear });
                }
                break;
            }
        }
    }

    timeBlur() {
        const time = this.getTime();
        if (time == null) {
            this.timeControl.setValue(null, { emitEvent: false });
            return;
        }

        this.timeControl.setValue(time.toLocaleString(DateTime.TIME_24_SIMPLE), { emitEvent: false });
        this.combine();
    }

    private getTime() {
        const hourMin = timeStringToHourMin(this.timeControl.value);
        return hourMin ? DateTime.now().set(hourMin) : null;
    }

    dateChange(date: DateTime | null) {
        if (date == null) {
            return;
        }

        this.date = date;
        this.combine();
    }

    close(cancel: boolean, clear = false) {
        this.dialogRef.close({
            value: cancel || clear ? null : this.dateTime,
            ok: !cancel,
        });
    }
}
