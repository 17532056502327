// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-card>
    <md-card-content>
        <form name="swapStatsForm" ng-submit="ssctrl.updateStats(ssctrl.stats.employee, ssctrl.filterInverval.from, ssctrl.filterInverval.to)" layout="column">
            <div layout="row" layout-wrap>
                <eaw-date-interval
                        layout="row"
                        ng-model="ssctrl.filterInverval"
                        required
                        flex="100"
                        flex-gt-xs="66">
                </eaw-date-interval>
                <employee-autocomplete ng-model="ssctrl.stats.employee"
                                       include-external="true"
                                       from="ssctrl.filterInterval.from"
                                       to="ssctrl.filterInterval.to"
                                       required
                                       flex="100"
                                       flex-gt-xs="33">
                </employee-autocomplete>
            </div>

            <div layout="row">
                <md-button ng-disabled="swapStatsForm.$invalid" class="md-raised md-accent" type="submit" ng-i18next="UPDATE"></md-button>
            </div>
        </form>
    </md-card-content>
</md-card>

<md-card ng-if="ssctrl.stats.employee.name && ssctrl.loadedStats">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-bind="ssctrl.stats.employee.name"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <table class="table table-hover table-vmiddle">
            <tr>
                <td><label ng-i18next="scheduling:SHIFTS_WORKED"></label></td>
                <td class="text-right" ng-bind="(ssctrl.stats.data.shifts_worked | eawNumber)"></td>
            </tr>
            <tr>
                <td><label ng-i18next="scheduling:APPROVED_SWAPS"></label></td>
                <td class="text-right" ng-bind="(ssctrl.stats.data.approved_swaps | eawNumber)"></td>
            </tr>
            <tr>
                <td><label ng-i18next="scheduling:DECLINED_SWAPS"></label></td>
                <td class="text-right" ng-bind="(ssctrl.stats.data.declined_swaps | eawNumber)"></td>
            </tr>
            <tr>
                <td><label ng-i18next="scheduling:SWAPS_FROM"></label></td>
                <td class="text-right" ng-bind="(ssctrl.stats.data.swaps_from | eawNumber)"></td>
            </tr>
            <tr>
                <td><label ng-i18next="scheduling:SWAPS_TO"></label></td>
                <td class="text-right" ng-bind="(ssctrl.stats.data.swaps_to | eawNumber)"></td>
            </tr>
            <tr>
                <td><label ng-i18next="scheduling:SWAPS_FROM_COST"></label></td>
                <td class="text-right" ng-bind="(ssctrl.stats.data.cost_swaps_from | eawNumber)"></td>
            </tr>
            <tr>
                <td><label ng-i18next="scheduling:SWAPS_TO_COST"></label></td>
                <td class="text-right" ng-bind="(ssctrl.stats.data.cost_swaps_to | eawNumber)"></td>
            </tr>
        </table>
    </md-card-content>
</md-card>
`;

module('eaw.scheduling.shifts').component('shiftSwapStats', {
    template: template1,
    controllerAs: 'ssctrl',
    bindings: {
        customer: '<',
    },
    controller: [ 'SwapFactory', function shiftSwapStatsCtrl(SwapFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.filterInterval = {
                from: moment().subtract(1, 'month'),
                to: moment().endOf('d'),
            };
        };

        ctrl.updateStats = (employee, from, to) => {
            ctrl.loadedStats = false;
            ctrl.stats.data = SwapFactory.getStats(ctrl.customer.id, employee.id, {
                from,
                to,
            });
            ctrl.stats.data.$promise.then(() => (ctrl.loadedStats = true));
        };
    } ],
});
