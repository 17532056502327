// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { EawUrl } from '../shared/angularjs/modules/resource/url.service';

module('eaw.filesystem-access').component('eawFilesystemAccess', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title">
                <md-button class="md-icon-button" ng-if="!$ctrl.topLevel" ng-click="$ctrl.back()">
                    <md-icon ng-bind="'chevron_left'"></md-icon>
                </md-button>
                <span ng-bind="$ctrl.currentName"></span>
            </span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <table class="table table-vmiddle" id="disks-table" ng-if="$ctrl.topLevel">

            <thead>
            <tr>
                <th>
                    <div layout="row" layout-align="start center">
                        <span ng-i18next="NAME" flex="100"></span>
                    </div>
                </th>
            </tr>
            </thead>

            <tbody>
            <tr class="table-row" ng-repeat="disk in $ctrl.currentLocation" ng-click="$ctrl.openDisk(disk)">
                <td class="icon-name">
                    <md-button>
                        <md-icon md-svg-src="harddisk" class="tw-mr-4"></md-icon>
                        <span class="name" ng-bind="disk.title"></span>
                    </md-button>
                </td>
            </tr>
            <tr class="table-row" ng-show="!$ctrl.currentLocation.length && !$ctrl.loading">
                <td ng-i18next="filesystem_access:NO_DISK"></td>
            </tr>
            </tbody>
        </table>

        <table class="table table-vmiddle table-hover" id="directory-table" ng-if="!$ctrl.topLevel" >

            <thead>
            <tr>
                <th>
                    <div layout="row" layout-align="start center">
                        <span ng-i18next="NAME" flex="100"></span>
                    </div>
                </th>
                <th ng-i18next="DOWNLOAD"></th>
            </tr>
            </thead>

            <tbody>
            <tr class="table-row hover-item" ng-repeat="directory in $ctrl.currentLocation.directories" ng-click="$ctrl.openDirectory(directory)">
                <td class="icon-name" colspan="2">
                    <md-icon ng-bind="'folder'" class="tw-mr-4"></md-icon>
                    <span class="name" ng-bind="directory.name"></span>
                </td>
            </tr>

            <tr class="table-row" ng-repeat="file in $ctrl.currentLocation.files">
                <td class="icon-name">
                    <div class="hover-item" ng-click="$ctrl.download(file)">
                        <md-icon ng-bind="'draft'" class="tw-mr-4"></md-icon>
                        <span class="name" ng-bind="file"></span>
                    </div>
                </td>
                <td>
                    <md-button class="md-icon-button" ng-click="$ctrl.download(file)">
                        <md-icon ng-bind="'download'"></md-icon>
                    </md-button>
                </td>
            </tr>
            </tbody>
        </table>

    </md-card-content>
</md-card>

`,
    bindings: {
        customer: '<',
    },
    controller: [ 'filesystemAccessFactory', 'FileService', function(filesystemAccessFactory, FileService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.loading = true;
            filesystemAccessFactory.getAll.query(ctrl.customer.id).then((resp) => {
                ctrl.disks = resp.data;
                ctrl.currentDisk = null;
                ctrl.currentLocation = resp.data;
                ctrl.loading = false;
                ctrl.topLevel = true;
                ctrl.currentName = t('filesystem_access:DISK_plural');
                ctrl.pathName = [];
                ctrl.path = [];
                ctrl.loading = false;
            });
        };

        ctrl.openDisk = (disk) => {
            ctrl.topLevel = false;
            ctrl.currentLocation = disk;
            ctrl.currentName = disk.title;
            ctrl.currentDisk = disk;
            ctrl.path.push({});
            ctrl.pathName.push('');
        };

        ctrl.openDirectory = (dir) => {
            ctrl.currentLocation = dir;
            ctrl.pathName.push(dir.name);
            ctrl.path.push(dir);
            ctrl.currentName = `${ctrl.currentDisk.title} ${ctrl.pathName.join(' > ')}`;
        };

        ctrl.back = () => {
            ctrl.path.pop();
            ctrl.pathName.pop();

            if (!ctrl.path.length) {
                ctrl.topLevel = true;
                ctrl.currentDisk = null;
                ctrl.currentLocation = ctrl.disks;
                ctrl.currentName = t('filesystem_access:DISK_plural');
            } else if (ctrl.path.length === 1) {
                ctrl.currentLocation = ctrl.currentDisk;
                ctrl.currentName = ctrl.currentDisk.title;
            } else {
                ctrl.currentLocation = ctrl.path[ctrl.path.length - 1];
                ctrl.currentName = `${ctrl.currentDisk.title} ${ctrl.pathName.join(' > ')}`;
            }
        };

        ctrl.download = (file) => {
            const filepath = ctrl.pathName.join('/') + (ctrl.pathName.length > 1 ? '/' : '') + file;
            const url = EawUrl.url + `/customers/${ctrl.customer.id}/filesystems/${ctrl.currentDisk.id}/files?file=${filepath}`;
            return FileService.download(url, 'get');
        };
    } ],
});
