import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Customer } from '../../../../../shared/models/customer';
import { User } from '../../../../../shared/models/user';
import { Employee } from '../../../../../shared/models/employee';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { catchError, EMPTY } from 'rxjs';
import { DateTimePipe } from '../../../../../shared/pipes/date-time.pipe';
import { NumberPipe } from '../../../../../shared/pipes/number.pipe';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { EmployeePayRateService } from '../../../../../payroll/http/employee-pay-rate.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { Tariff } from '../../../../../payroll/models/tariff';
import { RateType } from '../../../../../payroll/classes/rate';
import { AutocompleteComponent } from '../../../../../shared/components/autocomplete/autocomplete.component';
import { CheckboxHelperDirective } from '../../../../../shared/directives/checkbox-helper.directive';
import { DatePickerOptionsDirective } from '../../../../../shared/directives/date-picker-options.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TariffAutocompleteService } from '../../../../../shared/autocompletes/tariff-autocomplete.service';
import { PayRate } from '../../../../../payroll/models/pay-rate';
import { NewEmployeeNextStepComponent } from '../../components/new-employee-next-step/new-employee-next-step.component';
import { InfoLoadingComponent } from '../../../../../shared/components/info-loading/info-loading.component';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';
import { TranslateSyncPipe } from '../../../../../shared/pipes/translate-sync.pipe';

type PayRateForm = {
    from: FormControl<DateTime | null>;
    to: FormControl<DateTime | null>;
    useTariff: FormControl<boolean>;
    tariff: FormControl<Tariff | number | null>;
    rate: FormControl<number | null>;
    type: FormControl<RateType>;
}

@Component({
    selector: 'eaw-new-employee-pay',
    standalone: true,
    imports: [ CommonModule, ActionButtonComponent, NewEmployeeNextStepComponent, DateTimePipe, InfoLoadingComponent, NumberPipe, TranslatePipe, AutocompleteComponent, CheckboxHelperDirective, DatePickerOptionsDirective, FormsModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatOptionModule, MatSelectModule, ReactiveFormsModule, TranslateSyncPipe ],
    templateUrl: './new-employee-pay.component.html',
    styleUrl: './new-employee-pay.component.scss',
})
export class NewEmployeePayComponent extends NewEmployeeStepComponent implements OnInit {
    private readonly employeePayRateService = inject(EmployeePayRateService);
    protected readonly tariffAutocompleteService = inject(TariffAutocompleteService);

    @Input({ required: true }) customer!: Customer;
    @Input({ required: true }) employee!: Employee;
    @Input({ required: true }) user?: User;

    processing = false;
    payTypes: RateType[] = [ 'hour', 'month', 'year' ];
    payRate?: PayRate;
    form: FormGroup<PayRateForm>;

    constructor() {
        super();

        this.form = new FormGroup({
            from: new FormControl<DateTime | null>(null, Validators.required),
            to: new FormControl<DateTime | null>(null),
            useTariff: new FormControl<boolean>(false, { nonNullable: true }),
            tariff: new FormControl<Tariff | number | null>(null),
            rate: new FormControl<number | null>(null, Validators.required),
            type: new FormControl<RateType>('hour', { nonNullable: true }),
        });
    }

    override ngOnInit() {
        super.ngOnInit();

        this.form.patchValue({
            from: this.employee.from,
            to: this.employee.to,
        });

        this.form.controls.useTariff.valueChanges.subscribe((useTariff) => {
            if (useTariff) {
                this.form.controls.tariff.addValidators(Validators.required);
                this.form.controls.rate.clearValidators();
            } else {
                this.form.controls.tariff.clearValidators();
                this.form.controls.rate.addValidators(Validators.required);
            }

            this.form.controls.rate.updateValueAndValidity();
            this.form.controls.tariff.updateValueAndValidity();
        });

        this.form.controls.from.valueChanges.subscribe((from) => {
            if (from) {
                this.form.controls.tariff.enable();
            } else {
                this.form.controls.tariff.setValue(null);
                this.form.controls.tariff.disable();
            }
        });
    }

    getFormData() {
        const value = this.form.getRawValue();
        if (!value.from) {
            return;
        }

        return {
            from: value.from,
            to: value.to || undefined,
            tariff: value.tariff instanceof Tariff ? value.tariff : undefined,
            tariffId: value.tariff instanceof Tariff ? value.tariff.id : undefined,
            rate: value.rate || (value.tariff instanceof Tariff ? value.tariff.rates?.[0]?.rate : undefined) || 0,
            type: value.type || (value.tariff instanceof Tariff ? value.tariff.rates?.[0]?.type : undefined) || 'month',
            useTariff: value.useTariff,
        };
    }

    submit() {
        const formData = this.getFormData();
        if (!formData) {
            return;
        }

        this.form.disable();
        this.processing = true;
        this.employeePayRateService.create(this.customer.id, this.employee.id, {
            type: formData.type,
            from: formData.from,
            to: formData.to,
            rate: formData.useTariff ? undefined : formData.rate,
            tariff_id: formData.useTariff ? formData.tariffId : undefined,
        }).pipe(
            catchError(() => {
                this.form.enable();
                this.processing = false;
                return EMPTY;
            }),
        ).subscribe((payRate) => {
            this.payRate = payRate;
            this.step.completed = true;
            this.stepper.next();
        });
    }
}
