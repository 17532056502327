// @ts-nocheck
import { module } from 'angular';
import { sort } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.absence').component('absenceTypeSelect', {
    template: `<eaw-select-multiple-search ng-model="$select.selected"
                                           ng-if="!$select.loading"
                                           ng-change="$select.select()"
                                           i18n-label="'absences:ABSENCE_TYPE_plural'"
                                           return-value="'id'"
                                           items="$select.types"
                                           display-key="'i18n'">
                </eaw-select-multiple-search>`,
    controllerAs: '$select',
    bindings: {
        settingGroupId: '<',
    },
    require: {
        ngModel: 'ngModel',
    },
    controller: [ 'AbsenceTypeFactory', 'Pagination', '$filter', function absenceTypeSelect(AbsenceTypeFactory, Pagination, $filter) {
        this.$onInit = async () => {
            this.types = [];
            this.loading = true;
            this.ngModel.$formatters.unshift(this.format);
            if (!(this.settingGroupId instanceof Array)) {
                this.settingGroupId = [ this.settingGroupId ];
            }
            const allTypes = await Promise.all(this.settingGroupId.map((entityId) => {
                const p = new Pagination({ per_page: 256 });
                // @ts-ignore
                p.entityId = entityId;
                return Pagination.getData(p, (pagination) => AbsenceTypeFactory.getAll(pagination));
            }));
            const types = allTypes.flat(1).map((type) => {
                return {
                    i18n: $filter('eawTranslate')(type.name, 'absence_types'),
                    ...type,
                };
            });
            this.types = sort(types, CurrentOld.languageTag, [ (i) => i.i18n ], [ 'asc' ]);
            this.loading = false;
        };
        this.format = (val) => this.selected = val instanceof Array ? val : [];
        this.select = () => {
            this.ngModel.setViewValue(this.selected);
        };
    } ],
});
