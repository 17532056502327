import { Transition } from '@uirouter/angularjs';
import { NavTabsTab } from '../components/nav-tabs/nav-tabs.component';

// Resolves tabs for NavTabsComponent
export function navTabsTabsResolver(resolveFn: ($transition$: Transition) => Promise<NavTabsTab[]>) {
    return {
        token: 'tabs',
        deps: [ '$transition$' ],
        resolveFn,
    };
}
