import { t } from 'i18next';
import { module } from 'angular';
import { StateProvider, Transition } from '@uirouter/angularjs';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { lastValueFrom, map } from 'rxjs';
import { UserGroupPermissionSetsComponent } from '../../../admin/pages/admin-user-group-permission-sets/user-group-permission-sets.component';
import { CustomerUserGroupMembersComponent } from '../../../admin/pages/customer-user-group-members/customer-user-group-members.component';
import { staticResolver } from '../../../shared/resolvers/static.resolver';
import { dependencyResolver } from '../../../shared/resolvers/dependency.resolver';
import { currentResolver } from '../../../shared/resolvers/current.resolver';
import { inject } from '@angular/core';
import { UserGroupService } from '../../../shared/http/user-group.service';
import { CurrentService } from '../../../shared/services/current.service';
import { paramResolver } from '../../../shared/resolvers/param.resolver';

module('eaw.company').config([ '$stateProvider', function($stateProvider: StateProvider) {
    $stateProvider.state(`eaw/app/company/user_groups/view`, {
        parent: `eaw/app/company/user_groups/list`,
        url: '/:id',
        views: {
            'content@': {
                component: 'eawNavBar',
            },
        },
        data: {
            permissions: [ `customers.{customer}.user_groups.{id}.members.*.update` ],
            breadcrumb: (transition: Transition) => {
                return inject(UserGroupService).get(inject(CurrentService).getCustomer().id, transition.params()['id']).pipe(map((group) => group.name));
            },
        },
        resolve: [
            staticResolver('navbarRoute', 'eaw/app/company/user_group/members'),
            staticResolver('viewName', 'tabPane'),
            currentResolver('customerId', 'customer', 'id'),
            paramResolver('id', 'userGroupId'),
            dependencyResolver('userGroup', [ 'UserGroupService', '$transition$', 'customer' ], function(UserGroupService: any, $transition$: Transition, customer: any) {
                return UserGroupService.get(customer.id, $transition$.params()['id']).$promise;
            }),
            dependencyResolver('tabs', [ 'userGroupId', 'customerId', 'PermissionCheckServiceDowngrade' ], async function(userGroupId, customerId, permissionCheck: PermissionCheckService) {
                const getPermissions = await lastValueFrom(permissionCheck.isAllowedMany([
                    `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].permission_sets.*.get`,
                ], [
                    `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].permissions.manage`,
                    `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].permissions.get`,
                ], {
                    models: [
                        {
                            id: userGroupId,
                            type: ApiModel.UserGroup,
                        },
                        {
                            id: customerId,
                            type: ApiModel.Customer,
                        },
                    ],
                }));

                let tabs = [ {
                    label: t('MEMBER_plural'),
                    src: 'eaw/app/company/user_group/members',
                }, {
                    label: t('RESPONSIBLE_plural'),
                    src: 'eaw/app/company/user_group/responsible',
                } ];

                if (getPermissions) {
                    tabs = tabs.concat([ {
                        label: t('PERMISSION_plural'),
                        src: 'eaw/app/company/user_group/permissions',
                    } ]);
                }

                return tabs;
            }),
        ],
    });

    $stateProvider.state('eaw/app/company/user_group/members', {
        parent: 'eaw/app/company/user_groups/view',
        url: '/members',
        views: {
            // TODO: If changes are needed here, but not in admin, split this component into two
            tabPane: { component: CustomerUserGroupMembersComponent },
        },
        data: {
            breadcrumb: { key: 'MEMBER_plural' },
        },
    });
    $stateProvider.state('eaw/app/company/user_group/responsible', {
        parent: 'eaw/app/company/user_groups/view',
        url: '/responsible',
        views: { tabPane: 'companyUserGroupResponsibleList' },
        data: {
            breadcrumb: { key: 'RESPONSIBLE_plural' },
        },
    });

    $stateProvider.state('eaw/app/company/user_group/permissions', {
        parent: 'eaw/app/company/user_groups/view',
        url: '/permissions',
        views: {
            tabPane: {
                // TODO: If changes are needed here, but not in admin, split this component into two
                component: UserGroupPermissionSetsComponent,
            },
        },
        data: {
            breadcrumb: { key: 'PERMISSION_plural' },
        },
    });
} ]);
