import { AfterViewInit, Component, Inject } from '@angular/core';
import { DialogComponent } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import type { PromptDialogData, PromptDialogNumberOptions, PromptDialogTextareaOptions, PromptDialogTextOptions } from './prompt-dialog.service';
import { Validators, ReactiveFormsModule } from '@angular/forms';
import { EawValidators } from '../../validators/eaw-validators';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActionButtonComponent } from '../../components/action-button/action-button.component';
import { FieldErrorComponent } from '../../components/field-error/field-error.component';

@Component({
    selector: 'eaw-prompt-dialog',
    templateUrl: './prompt-dialog.component.html',
    styleUrl: './prompt-dialog.component.scss',
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatFormFieldModule,
        NgIf,
        MatInputModule,
        ReactiveFormsModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        ActionButtonComponent,
        FieldErrorComponent,
    ],
})
export class PromptDialogComponent extends DialogComponent implements AfterViewInit {
    textOptions: PromptDialogTextOptions = {};
    numberOptions: PromptDialogNumberOptions = {};
    textareaOptions: PromptDialogTextareaOptions = {};
    minLength = 0;
    maxLength = 0;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: PromptDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<PromptDialogComponent, unknown>,
    ) {
        super(dialogRef, data);
    }

    ngAfterViewInit() {
        this.dialogRef.afterOpened().subscribe(() => {
            if (this.data.type === 'text') {
                this.textOptions = (this.data.options || {}) as PromptDialogTextOptions;
            }
            if (this.data.type === 'integer' || this.data.type === 'decimal') {
                this.numberOptions = (this.data.options || {}) as PromptDialogNumberOptions;
            }
            if (this.data.type === 'textarea') {
                this.textareaOptions = (this.data.options || {}) as PromptDialogTextareaOptions;
            }

            this.addIntegerValidator();
            this.addRequiredValidator();
            this.addMaxValidator();
            this.addMinValidator();
            this.addMinLengthValidator();
            this.addMaxLengthValidator();
        });
    }

    close() {
        if (this.data.formControl.valid) {
            this.dialogRef.close(this.data.formControl.value);
        }
    }

    private addIntegerValidator() {
        if (this.data.type === 'integer') {
            this.data.formControl.addValidators(EawValidators.integer());
        }
    }

    private addRequiredValidator() {
        if (this.data.required === true) {
            this.data.formControl.addValidators(Validators.required);
        }
    }

    private addMaxValidator() {
        if (this.numberOptions.max != null) {
            this.data.formControl.addValidators(Validators.max(this.numberOptions.max));
        }
    }

    private addMinValidator() {
        if (this.numberOptions.min != null) {
            this.data.formControl.addValidators(Validators.min(this.numberOptions.min));
        }
    }

    private addMinLengthValidator() {
        this.minLength = Math.max(this.textOptions.minLength || 0, this.textareaOptions.minLength || 0);
        if (this.minLength > 0) {
            this.data.formControl.addValidators(Validators.minLength(this.minLength));
        }
    }

    private addMaxLengthValidator() {
        this.maxLength = Math.max(this.textOptions.maxLength || 0, this.textareaOptions.maxLength || 0);
        if (this.maxLength > 0) {
            this.data.formControl.addValidators(Validators.maxLength(this.maxLength));
        }
    }
}
