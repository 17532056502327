import { Component, Inject, Input } from '@angular/core';
import { LanguageService } from '../../../admin/http/language.service';
import { Observable, of, shareReplay } from 'rxjs';
import { ArrayPaginatedResponse } from '../../interfaces/paginated-response';
import { Language } from '../../../admin/models/language';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { AutocompleteDeprecatedComponent } from '../autocomplete-deprecated/autocomplete-deprecated.component';

@Component({
    selector: 'eaw-language-autocomplete',
    templateUrl: './language-autocomplete.component.html',
    styleUrl: './language-autocomplete.component.scss',
    standalone: true,
    imports: [ AutocompleteDeprecatedComponent, TranslatePipe ],
})
export class LanguageAutocompleteComponent {
    @Input() controlName!: string;
    @Input() label?: Promise<string>;

    getAllObservable?: Observable<ArrayPaginatedResponse<Language>>;
    observable?: Observable<ArrayPaginatedResponse<Language>>;

    constructor(
        @Inject(LanguageService) private service: LanguageService,
    ) {
    }

    updateResults(filter: string = '') {
        this.getAllObservable ||= this.service.getAll().pipe(shareReplay(1));

        this.getAllObservable.subscribe((result) => {
            this.observable = of({
                ...result,
                data: result.data.filter((lang) => lang.name.toLowerCase().includes(filter)),
            });
        });
    }
}
