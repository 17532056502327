<eaw-page-header [buttons]="menu">
    <span title>
        {{ 'EMPLOYEE_USER' | translate: 'company' | async }}
    </span>
</eaw-page-header>
@if (user && customer) {
    <eaw-customer-user [userId]="user.id" [customer]="customer"></eaw-customer-user>
} @else {
    <mat-card>
        <mat-card-content class="tw-h-full tw-flex-col">
            @if (loading) {
                <mat-progress-spinner mode="indeterminate" diameter="50" strokeWidth="5"></mat-progress-spinner>
            } @else {
                <p class="text-center">
                    {{ 'NO_USER_CONNECTED' | translate: 'company' | async }}
                </p>
                <mat-card-actions class="tw-flex tw-justify-center">
                    <eaw-action-button [loading]="false" (click)="addUser()">
                        {{ 'ADD_USER' | translate: 'company' | async }}
                    </eaw-action-button>
                </mat-card-actions>
            }
        </mat-card-content>
    </mat-card>
}
