import { Component, inject, Input, OnChanges, OnInit, signal, SimpleChanges } from '@angular/core';
import { KpiService } from '../../http/kpi.service';
import { DateTime, Duration } from 'luxon';
import { KpiTypeService } from '../../http/kpi-type.service';
import { catchError, EMPTY, from, map, Observable, of, shareReplay, switchMap, tap, zip } from 'rxjs';
import { KpiType } from '../../models/kpi-type';
import { Kpi } from '../../models/kpi';
import { KpiProviderSum } from '../../models/kpi-provider-sum';
import { CurrentService } from '../../../shared/services/current.service';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { uniqueId } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { ReorderItemsDialogService } from '../../../shared/dialogs/reorder-items-dialog/reorder-items-dialog.service';
import { Namespace, NamespaceFile } from '../../../shared/enums/namespace';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { EawChart } from '../../../shared/angularjs/modules/misc/services/eaw-chart';
import { SeriesOptionsType, XAxisOptions } from 'highcharts';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { DateIntervalSelectorComponent } from '../../../shared/components/date-interval-selector/date-interval-selector.component';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { AlertDialogComponent, AlertDialogData } from '../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserPropertyService } from '../../../shared/http/user-property.service';
import { Property } from '../../../shared/models/property';
import { ItemSelectorDialogComponent, ItemSelectorDialogData, ItemSelectorDialogReturn } from '../../../shared/dialogs/item-selector-dialog/item-selector-dialog.component';

interface Column {
    selected: boolean;
    i18n: string;
    ns: NamespaceFile;
    kpiType: KpiType;
}

interface DataSourceItem {
    id: number;
    date: DateTime;
    values: Record<number | string, string | undefined>;
    pureValues: Record<number | string, number | undefined>;
    lastUpdated: string;
    isToday: boolean;
}

@Component({
    selector: 'eaw-kpi',
    templateUrl: './kpi.component.html',
    styleUrl: './kpi.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        DateIntervalSelectorComponent,
        MatCardModule,
        NgIf,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        NgFor,
        NgClass,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class KpiComponent implements OnInit, OnChanges {
    protected kpiService = inject(KpiService);
    protected kpiTypeService = inject(KpiTypeService);
    protected current = inject(CurrentService);
    protected translate = inject(TranslateService);
    protected numberFormatterService = inject(NumberFormatterService);
    protected reorderItemsDialogService = inject(ReorderItemsDialogService);
    protected permissionCheckService = inject(PermissionCheckService);
    protected propertyService = inject(UserPropertyService);
    protected dialog = inject(MatDialog);

    @Input({ required: true }) customerId!: number;
    @Input() hideRecalculateButton?: boolean;
    @Input() title?: Promise<string> = this.translate.t('KPI', 'kpi');
    @Input() hideFilter?: boolean;
    @Input() from = DateTime.now().startOf('month');
    @Input() to = DateTime.now().endOf('month');

    private kpiTypesObservableCache?: Observable<KpiType[]>;
    private columnsStoreKey = '';
    private kpis: Kpi[] = [];
    private providerSumsResponse?: Record<string, KpiProviderSum> | null | undefined;
    private kpiTypes: KpiType[] = [];

    mode: 'table' | 'chart' = 'table';
    buttons: PageHeaderButton[];

    loading = false;
    compactNumbers = false;
    sortActive = 'date';
    sortDirection: SortDirection = 'asc';
    // All available columns with options
    columns: Column[] = [];
    // Used to determine which columns are shown in the table
    displayColumns: string[] = [];
    dataSource: DataSourceItem[] = [];
    sums: Record<number, string | undefined> = {};
    canRecalculate = false;

    constructor() {
        this.buttons = [
            new PageHeaderButton({
                click: this.getKpis.bind(this),
                icon: 'refresh',
                menuText: signal(this.translate.t('REFRESH')),
                disabled: () => this.loading,
            }),
            new PageHeaderButton({
                click: this.toggleMode.bind(this),
                icon: () => this.mode === 'chart' ? 'show_chart' : 'table_chart',
                menuText: signal(this.translate.t('MODE')),
            }),
            new PageHeaderButton({
                click: this.toggleCompactNumbers.bind(this),
                icon: () => this.compactNumbers ? 'compress' : 'expand',
                active: () => this.compactNumbers,
                menuText: signal(this.translate.t('COMPACT_NUMBERS', 'kpi')),
                rotation: 90,
            }),
            new PageHeaderButton({
                click: this.recalculate.bind(this),
                icon: 'update',
                hide: () => !this.canRecalculate || !!this.hideRecalculateButton,
                menuText: signal(this.translate.t('RECALC_KPI', 'kpi')),
            }),
            new PageHeaderButton({
                click: this.adjustColumns.bind(this),
                icon: 'view_column',
                menuText: signal(this.translate.t('ADJUST_COLUMNS', 'kpi')),
                disabled: () => !this.columns.length,
            }),
        ];
    }

    ngOnInit(): void {
        this.columnsStoreKey = `kpi_columns_order@${this.customerId}`;
        this.permissionCheckService.isAllowed(`customers.${this.customerId}.kpis.*.update`).subscribe((canRecalculate) => {
            this.canRecalculate = canRecalculate;
        });

        this.getKpis();
    }

    ngOnChanges(changes: SimpleChanges) {
        const from = changes['from'];
        const to = changes['to'];

        if (from && to) {
            this.from = from.currentValue;
            this.to = to.currentValue;

            this.getKpis();
        }
    }

    updateInterval(interval: { from: DateTime, to: DateTime }) {
        this.from = interval.from;
        this.to = interval.to;

        return this.getKpis();
    }

    toggleMode() {
        this.mode = this.mode === 'chart' ? 'table' : 'chart';
        void this.updateView();
    }

    tableTrackByFn(_: number, item: DataSourceItem) {
        return item.id;
    }

    toggleCompactNumbers() {
        this.compactNumbers = !this.compactNumbers;
        void this.updateView();
    }

    adjustColumns() {
        const items = this.columns.map((column) => {
            return {
                item: column,
                selected: column.selected,
                text: this.translate.t(column.i18n, column.ns),
            };
        });

        this.reorderItemsDialogService.open({ size: DialogSize.Medium, items: of(items) }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.columns = result.reduce((arr, item) => {
                const columnItem = item.item;
                columnItem.selected = item.selected;
                return arr.concat(columnItem);
            }, [] as Column[]);

            this.setDisplayColumns(true);
        });
    }

    recalculate() {
        const dates = new Array(7).fill(1).map((_, index) => {
            const date = DateTime.now().startOf('day').minus({ day: index });

            return {
                date,
                text: date.toLocaleString(DateTime.DATE_MED),
            };
        });

        this.dialog.open<ItemSelectorDialogComponent<{ date: DateTime, text: string }>, ItemSelectorDialogData<{ date: DateTime, text: string }>, ItemSelectorDialogReturn<{ date: DateTime, text: string }>>(ItemSelectorDialogComponent, {
            data: {
                items: of(dates),
                itemText: (r) => r.text,
                itemSubtext: (item) => item.date.weekdayLong || '',
                multiple: false,
                title: this.translate.t('RECALC_KPI', 'kpi'),
                confirmText: this.translate.t('UPDATE'),
            },
        }).afterClosed().pipe(
            switchMap((res) => {
                if (res == null || Array.isArray(res)) {
                    return EMPTY;
                }

                return this.kpiService.recalculate(this.customerId, res.date);
            }),
            switchMap(() => {
                return this.dialog.open<AlertDialogComponent, AlertDialogData, boolean>(AlertDialogComponent, {
                    data: {
                        title: signal(this.translate.t('RECALC_TITLE', 'kpi')),
                        text: signal(this.translate.t('RECALC_MSG', 'kpi')),
                    },
                }).afterClosed();
            }),
        ).subscribe();
    }

    sort(event: Sort) {
        if (event.direction === '') {
            event.direction = 'asc';
            event.active = 'date';
            this.sortActive = event.active;
            this.sortDirection = event.direction;
        }

        this.dataSource = [ ...this.dataSource.sort((a, b) => {
            const isAsc = event.direction === 'asc';
            const itemA = isAsc ? a : b;
            const itemB = isAsc ? b : a;

            if (event.active === 'date') {
                return itemA.id - itemB.id;
            }

            return (itemA.pureValues[event.active] || 0) - (itemB.pureValues[event.active] || 0);
        }) ];
    }

    get kpiTypesObservable() {
        this.kpiTypesObservableCache ||= expandAllPages((pagination) => this.kpiTypeService.getAll(this.customerId, pagination), { per_page: 200 }).pipe(shareReplay(1));
        return this.kpiTypesObservableCache;
    }

    getKpis() {
        const providerSumsObservable = this.kpiService.getProviderSums(this.customerId, this.from, this.to);
        const kpisObservable = this.kpiService.getAll(this.customerId, { provider_sums: false, from: this.from, to: this.to });

        this.loading = true;

        zip([ kpisObservable, providerSumsObservable, this.kpiTypesObservable ]).subscribe(async ([ kpis, providerSums, types ]) => {
            this.kpis = kpis.data;
            this.providerSumsResponse = providerSums;
            this.kpiTypes = types;

            void this.updateView();
            this.loading = false;
        });
    }

    private getKpiMap(types: KpiType[], kpis: Kpi[]) {
        // Initialize object we use for mapping
        const map = {} as Record<string, Record<string, Kpi | undefined>>;

        // Create a type map just once that can be reused
        const typeMap = types.reduce((obj, type) => {
            obj[type.id] = undefined;
            return obj;
        }, {} as Record<string, Kpi | undefined>);

        kpis.forEach((kpi) => {
            const date = kpi.businessDate?.dateTime.toISODate();
            if (!date) {
                return;
            }

            map[date] ||= { ...typeMap };
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            map[date]![kpi.kpiTypeId] = kpi;
        });

        return map;
    }

    getLastUpdate(kpis: Record<string, Kpi | undefined>): string {
        const largest = Math.max(...Object.values(kpis).map((k) => (k?.updatedAt || k?.createdAt)?.toMillis() || 0));
        return largest ? DateTime.fromMillis(largest).toRelative({ style: 'short' }) || '' : '';
    }

    setDisplayColumns(updateProperty = false) {
        const columns = this.columns.filter((c) => c.selected).map((c) => c.kpiType.key);

        if (updateProperty) {
            this.updateProperty(columns).subscribe();
        }

        this.displayColumns = [ 'date', 'lastUpdated', ...columns ];
    }

    private updateProperty(columns: string[]) {
        return this.propertyService.update(this.current.getUser().id, this.columnsStoreKey, JSON.stringify(columns));
    }

    setColumns(types: KpiType[]) {
        return this.propertyService.get(this.current.getUser().id, this.columnsStoreKey).pipe(
            catchError(() => of(undefined)),
            switchMap((columns: Property | undefined) => {
                if (columns) {
                    return of(columns.value.asArray());
                }

                // TODO: Remove localStorage fallback in a month or two (added 2024-02-14)
                // If no columns were stored as properties, try localstorage
                return from(this.current.retrieve<string[]>(`kpi_columns_order@${this.customerId}`, 'default')).pipe(
                    catchError(() => of(null)),
                    switchMap((storedColumns) => {
                        if (!storedColumns) {
                            return of(null);
                        }

                        return this.updateProperty(storedColumns).pipe(map((property) => property.value.asArray()));
                    }),
                );
            }),
            tap((storedColumns) => {
                this.columns = [];

                storedColumns?.forEach((c) => {
                    const type = types.find((t) => t.key.toLowerCase() === c.toLowerCase());
                    if (type) {
                        this.columns.push({
                            selected: true,
                            kpiType: type,
                            i18n: type.wtiKey,
                            ns: Namespace.KPITypes,
                        });
                    }
                });

                types.forEach((type) => {
                    const existingType = this.columns.find((c) => c.kpiType.key.toLowerCase() === type.key.toLowerCase());
                    if (!existingType) {
                        this.columns.push({
                            selected: !storedColumns?.length, // On selected if no stored columns
                            kpiType: type,
                            i18n: type.wtiKey,
                            ns: Namespace.KPITypes,
                        });
                    }
                });
            }),
        );
    }

    calculateSums(types: KpiType[], kpis: Kpi[], providerSums?: Record<string, KpiProviderSum> | null) {
        this.sums = types.reduce((obj, type) => {
            obj[type.id] = type.sumForCustomer(
                this.customerId,
                kpis,
                providerSums,
                this.current.languageTag,
                this.numberFormatterService,
                this.compactNumbers,
            ).formatted;

            return obj;
        }, {} as Record<number, string | undefined>);
    }

    async updateView() {
        if (this.mode === 'table') {
            this.createTable().subscribe();
        } else if (this.mode === 'chart') {
            await this.createChart();
        }
    }

    createTable() {
        return this.setColumns(this.kpiTypes).pipe(
            tap(() => {
                this.setDisplayColumns();

                let from = this.from;
                const to = this.to;
                const kpisMap = this.getKpiMap(this.kpiTypes, this.kpis);
                const dataSource: DataSourceItem[] = [];

                while (from <= to) {
                    const date = from;
                    const isoDate = date.toISODate() || '';
                    // The date might be undefined
                    const mapDate = kpisMap[isoDate] as Record<string, Kpi | undefined> | undefined;

                    dataSource.push({
                        id: uniqueId(),
                        date,
                        values: this.kpiTypes.reduce((obj, type) => {
                            obj[type.id] = type.valueFormatter(mapDate?.[type.id]?.value, this.current.languageTag, this.numberFormatterService, this.compactNumbers);
                            return obj;
                        }, {} as Record<number, string | undefined>),
                        pureValues: this.kpiTypes.reduce((obj, type) => {
                            obj[type.id] = mapDate?.[type.id]?.value;
                            return obj;
                        }, {} as Record<number, number | undefined>),
                        lastUpdated: this.getLastUpdate(mapDate || {}),
                        isToday: DateTime.now().hasSame(date, 'day'),
                    });

                    from = from.plus({ day: 1 });
                }

                this.dataSource = dataSource;
                this.calculateSums(this.kpiTypes, this.kpis, this.providerSumsResponse);
                this.sort({
                    active: this.sortActive,
                    direction: this.sortDirection,
                });
            }),
        );
    }

    async createChart() {
        const from = this.from;
        const to = this.to;
        const days = Math.round(to.diff(from, 'seconds', { conversionAccuracy: 'longterm' }).as('days'));
        const series: SeriesOptionsType[] = [];

        for (const type of this.kpiTypes) {
            series.push({
                name: await this.translate.t(type.wtiKey, 'kpi_types'),
                visible: !series.length,
                data: new Array(days).fill(0).map((_, index) => {
                    return this.kpis.find((k) => k.kpiTypeId === type.id && k.businessDate?.dateTime.hasSame(from.plus({ day: index }), 'day'))?.value || 0;
                }),
            } as SeriesOptionsType);
        }

        new EawChart(document.getElementById('chart'), {
            chart: {
                type: 'line',
            },
            title: {
                text: undefined,
            },
            time: {
                timezone: this.current.getCustomer().timeZone,
                useUTC: false,
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    overflow: 'justify',
                },
            } as XAxisOptions,
            plotOptions: {
                series: {
                    pointStart: from.startOf('day').toMillis(),
                    pointInterval: Duration.fromObject({ day: 1 }).as('milliseconds'),
                },
            },
            series,
        });
    }
}
