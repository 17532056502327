import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { DateTime } from 'luxon';
import { catchError, Observable, of } from 'rxjs';
import { CombinedContract, CombinedContractResponse } from '../models/combined-contract';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { IGNORE_ERROR } from '../../shared/http/http-contexts';
import { CustomFieldValueWithInterval } from '../../shared/utils/custom-fields-group';
import { ContractAmountType } from '../../shared/models/contract';

export interface CombinedContractCreateData {
    from: DateTime;
    to?: DateTime | null;
    contract: {
        type_id: number,
        title: string | null,
        amount: number,
        amount_type: ContractAmountType, // ContractAmountType,
    },
    availability?: {
        repeat: number,
        days: { day: number, offset: number, length: number, whole_day: boolean }[]
    },
    hours_distribution?: {
        hour_days: { index: number, hours: number }[],
    }
}

@Injectable({
    providedIn: 'root',
})
export class CombinedContractService {
    private readonly http = inject(HttpClient);

    validate(customerId: number, employeeId: number, data: CombinedContractCreateData): Observable<Record<string, string[]> | undefined> {
        // If all goes well, it will return nothing
        return this.http.post<undefined>(`customers/${customerId}/employees/${employeeId}/combined_contract/validate`, data, {
            context: new HttpContext().set(IGNORE_ERROR, [ 422 ]),
        }).pipe(
            catchError((error: HttpErrorResponse) => {
                return error.status === 422 ? of(error.error as Record<string, string[]>) : of({ error: [ error.error ] } as Record<string, string[]>);
            }),
        );
    }

    get(customerId: number, employeeId: number, contractId: number) {
        return this.http.get<CombinedContractResponse>(`customers/${customerId}/employees/${employeeId}/combined_contract/${contractId}`).pipe(
            classifyItem(CombinedContract),
        );
    }

    create(customerId: number, employeeId: number, data: CombinedContractCreateData, contractCustomFields?: Record<string, CustomFieldValueWithInterval>): Observable<CombinedContract> {
        return this.http.post<CombinedContractResponse>(`customers/${customerId}/employees/${employeeId}/combined_contract`, {
            ...data,
            contract: {
                ...data.contract,
                ...Object.entries(contractCustomFields || {}).reduce((acc, [ key, value ]) => {
                    if (value) {
                        acc[key] = value.value;
                    }

                    return acc;
                }, {} as Record<string, string | number | null>),
            },
        }).pipe(
            classifyItem(CombinedContract),
        );
    }

    delete(customerId: number, employeeId: number, contractId: number) {
        return this.http.delete<undefined>(`customers/${customerId}/employees/${employeeId}/combined_contract/${contractId}`);
    }
}
