import { Component, inject, OnInit } from '@angular/core';
import { TasksService } from '../../services/tasks.service';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { EmployeeTask, EmployeeTaskType } from '../../../shared/models/employee-task';
import { CurrentService } from '../../../shared/services/current.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { SignDocumentTaskComponent } from '../../components/sign-document-task/sign-document-task.component';
import { TosTaskComponent } from '../../components/tos-task/tos-task.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { UpdatePinCodeTaskComponent } from '../../components/update-pin-code/update-pin-code-task.component';
import { Employee } from '../../../shared/models/employee';
import { UploadDocumentComponent } from '../../components/upload-document/upload-document.component';
import { UIRouter } from '@uirouter/core';

@Component({
    selector: 'eaw-tasks',
    templateUrl: './tasks.component.html',
    styleUrl: './tasks.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        MatStepperModule,
        NgFor,
        NgIf,
        TosTaskComponent,
        SignDocumentTaskComponent,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
        UpdatePinCodeTaskComponent,
        UploadDocumentComponent,
    ],
})
export class TasksComponent implements OnInit {
    private readonly taskService = inject(TasksService);
    private readonly current = inject(CurrentService);
    private readonly uiRouter = inject(UIRouter);

    tosRequired!: boolean;
    tasks: EmployeeTask[] = [];
    taskTypes = EmployeeTaskType;
    employee?: Employee;
    userId?: number;

    ngOnInit() {
        this.userId = this.current.getMe().authedAs;
        this.employee = this.current.getEmployee();
        this.tosRequired = this.taskService.tosRequired;
        this.tasks = this.taskService.tasks;
    }

    continue() {
        this.taskService.completed = true;
        this.taskService.redirect(this.uiRouter);
    }

    tosAccepted(stepper: MatStepper) {
        this.tosRequired = false;

        setTimeout(() => {
            stepper.next();
        }, 250);
    }

    get everythingCompleted() {
        return this.current.getMe().isActing || this.tasks
            .reduce((arr, task) => [ ...arr, task.completed || !task.required ], [] as boolean[])
            .every((v) => v);
    }
}
