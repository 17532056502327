// @ts-nocheck
import { module } from 'angular';
module('eaw.staffing').factory('staffingIntervalFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/vs_templates/:template/intervals/:interval');
    const autoRes = EawResource.create('/customers/:customer/vs_templates/:template/intervals/auto', {}, {
        save: {
            method: 'POST',
            isArray: true,
        },
    });
    fac.getAll = {
        permission(customerId, templateId) {
            return `customers.${customerId}.vs_templates.${templateId}.intervals.*.get`;
        },
        query(customerId, templateId, args) {
            return res.get({
                ...Pagination.getParams(args),
                customer: customerId,
                template: templateId,
                from: args.from,
                to: args.to,
                per_page: 100,
            });
        },
    };
    fac.get = {
        permission(customerId, templateId, intervalId) {
            return `customers.${customerId}.vs_templates.${templateId}.intervals.${intervalId}.get`;
        },
        query(customerId, templateId, intervalId, withs) {
            return res.get({
                customer: customerId,
                template: templateId,
                interval: intervalId,
                'with[]': withs,
            });
        },
    };
    fac.create = {
        permission(customerId, templateId) {
            return `customers.${customerId}.vs_templates.${templateId}.intervals.create`;
        },
        query(customerId, templateId, from, to) {
            return res.save({
                customer: customerId,
                template: templateId,
            }, {
                from,
                to,
                'with[]': [ 'data' ],
            });
        },
    };
    fac.createMany = {
        permission(customerId, templateId) {
            return `customers.${customerId}.vs_templates.${templateId}.intervals.create`;
        },
        query(customerId, templateId, from, to, interval) {
            return autoRes.save({
                customer: customerId,
                template: templateId,
            }, {
                from,
                to,
                interval,
                'with[]': [ 'data' ],
            });
        },
    };
    fac.update = {
        permission(customerId, templateId, intervalId) {
            return `customers.${customerId}.vs_templates.${templateId}.intervals.${intervalId}.update`;
        },
        query(customerId, templateId, intervalId, from, to) {
            return res.update({
                customer: customerId,
                template: templateId,
                interval: intervalId,
            }, {
                from,
                to,
                'with[]': [ 'data' ],
            });
        },
    };
    fac.delete = {
        permission(customerId, templateId, intervalId) {
            return `customers.${customerId}.vs_templates.${templateId}.intervals.${intervalId}.delete`;
        },
        query(customerId, templateId, intervalId) {
            return res.delete({
                customer: customerId,
                template: templateId,
                interval: intervalId,
            });
        },
    };
    return fac;
} ]);
