// @ts-nocheck
import { module } from 'angular';

module('eaw.scheduling').controller('ShiftOverviewPanel', [ 'mdPanelRef', function ShiftOverviewPanel(mdPanelRef) {
    const ctrl = this;

    ctrl.choose = (item) => {
        // If we select new shift, then item is undefined
        // so initialize new shift with employee and from
        if (!item) {
            item = {
                employee: ctrl.employee,
                from: ctrl.date,
            };
        }

        ctrl.callback(item);
        mdPanelRef.close();
    };
} ]);
