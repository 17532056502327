import { ShiftSwap } from './shift-swap';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface EmployeeSwapSummaryResponse extends ApiResponse {
    average: number;
    last: number;
    planned: number;
}

export interface SwapSummaryResponse {
    employees?: Record<number, EmployeeSwapSummaryResponse>;
    [employeeId: number]: EmployeeSwapSummaryResponse;
}

export class EmployeeSwapSummary {
    average: number;
    last: number;
    planned: number;
    employeeId: number;

    constructor(employeeId: number, summary: EmployeeSwapSummaryResponse) {
        this.average = summary.average;
        this.last = summary.last;
        this.planned = summary.planned;
        this.employeeId = employeeId;
    }

    isFromEmployee(swap: ShiftSwap) {
        return swap.fromId == this.employeeId;
    }
}
