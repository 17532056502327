import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { groupBy } from 'lodash-es';
import { Setting, SettingDataType, SettingInputType } from '../../shared/models/setting';
import { HttpClient } from '@angular/common/http';
import { sort } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { ProductName } from '../../shared/enums/products';
import { Namespace, NamespaceFile } from '../../shared/enums/namespace';

export interface PayrollConfigurationValue {
    category: 'all' | 'employee' | 'manager' | 'executive';
    inherited_value: string | null;
    key: string;
    resolved_value: string | null;
    set_value: string | null;
}

export interface PayrollConfigurationMeta {
    class: string;
    data_type: SettingDataType;
    default: string | boolean | number | null;
    description: string;
    group: string;
    input_type: SettingInputType;
    name: string;
    product: ProductName | null;
    weight: number;
    options?: Record<string, string>;
    // Added in mapping after HTTP call
   translatedName: ReturnType<PayrollConfigurationService['getTranslation']>;
    // Added in mapping after HTTP call
    translatedDescription: ReturnType<PayrollConfigurationService['getTranslation']>;
}

export interface PayrollConfigurationResponse {
    meta: PayrollConfigurationMeta,
    values: PayrollConfigurationValue[],
}

@Injectable({
    providedIn: 'root',
})
export class PayrollConfigurationService {
    private http = inject(HttpClient);

    private getTranslation(string: string) {
        const [ ns, key ] = string.split('.');
        return {
            key: key || '',
            ns: ns ? ns as NamespaceFile : Namespace.General,
        };
    }

    getAll(customerId: number) {
        return this.http.get<PayrollConfigurationResponse[]>(`customers/${customerId}/french_settings`).pipe(
            map((settings) => {
                const grouped = Object.values(groupBy(settings, (setting) => setting.meta.name)).reduce((acc, group) => {
                    const meta = group[0]?.meta;
                    const values = group.flatMap((s) => s.values);

                    if (!meta || !values) {
                        return acc;
                    }

                    return acc.concat({
                        meta,
                        settings: values.map((v) => {
                            return new Setting(v.key, {
                                ...v,
                                ...meta,
                                type: null,
                            });
                        }),
                    });
                }, [] as { meta: PayrollConfigurationMeta, settings: Setting[] }[]);

                for (const group of grouped) {
                    group.meta.translatedName = this.getTranslation(group.meta.name);
                    group.meta.translatedDescription = this.getTranslation(group.meta.description);
                }

                return sort(grouped, 'en', [ (v) => v.meta.weight ]);
            }),
        );
    }
}
