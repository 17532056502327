// @ts-nocheck
import { module } from 'angular';
module('eaw.company').service('PayRate', [ 'EawResource', function(EawResource) {
    const factory = this;
    const url = '/customers/:customer_id/employees/:employee_id/pay_rates/:rate_id';
    factory.create = (args) => EawResource.create(url).save({
        customer_id: args.customer.id,
        employee_id: args.employee.id,
    }, {
        rate: args.rate,
        from: args.from,
        to: args.to,
        type: args.type,
        tariff_id: args.tariff_id,
    });
} ]);
