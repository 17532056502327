// @ts-nocheck
import { t } from 'i18next';
import { forEach } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.staffing').component('weekSetup', {
    template: `<md-card>
    <md-card-content>
        <form name="templateForm" id="templateForm" ng-submit="$week.addTemplate()" layout="column">
            <vs-template-autocomplete clear-btn="true" ng-model="$week.selectedTemplate"></vs-template-autocomplete>
            <div layout="row">
                <md-button type="submit" class="md-raised md-accent" form="templateForm" ng-disabled="!$week.selectedTemplate.id" ng-i18next="ADD"></md-button>
            </div>
        </form>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-bind="$week.weekSetup.name"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <table id="staffing-week-setup-table" class="table table-vmiddle">
            <thead>
            <tr>
                <th ng-i18next="staffing:VS_TEMPLATE"></th>
                <th ng-repeat="day in $week.days track by $index" ng-bind="day.name"></th>
                <th></th> <!-- For delete -->
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="row in $week.rows track by $index">
                <td ng-bind="::row.vs_template.name"></td>
                <td ng-repeat="day in $week.days track by $index">
                    <eaw-simple-interval ng-model="$parent.row[day.prop]" ng-change="$week.updateRow($parent.row, $parent.$index, day.prop)"></eaw-simple-interval>
                </td>
                <td>
                    <eaw-icon-button stop-propagate type="warn" icon="delete" ng-click="$week.delete(row, $parent.$index)"></eaw-icon-button>
                </td>
            </tr>
            </tbody>
        </table>
    </md-card-content>
</md-card>
`,
    bindings: {
        weekSetup: '<',
    },
    controllerAs: '$week',
    controller: [ 'weekSetupRowFactory', '$mdDialog', function(weekSetupRowFactory, $mdDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.createDays(); // Has to be first
            ctrl.rows = ctrl.weekSetup.week_rows.map(ctrl.formatRow);
        };

        ctrl.createDays = () => {
            const m = moment().startOf('w');
            const endOfWeek = moment().endOf('w');

            ctrl.days = [];
            while (m.isSameOrBefore(endOfWeek)) {
                ctrl.days.push({
                    name: m.format('ddd'),
                    prop: m.clone().locale('en').format('ddd').toLowerCase(),
                });
                m.add(1, 'd');
            }
        };

        ctrl.addTemplate = () => {
            weekSetupRowFactory.create.query(CurrentOld.customer.id, ctrl.weekSetup.id, {
                vs_template_id: ctrl.selectedTemplate.id,
                with: [ 'vsTemplate' ],
            }).$promise.then((row) => {
                ctrl.rows.push(ctrl.formatRow(row));
            });
        };

        ctrl.updateRow = (row, index, prop) => {
            console.log(`row`, row);
            console.log(`index`, index);
            console.log(`prop`, prop);

            const from = row?.[prop] || 'from';
            const to = row?.[prop] || 'to';

            if (!from && to || !to && from) {
                return;
            }

            const data = {};
            ctrl.days.forEach((d) => {
                const from = row?.[d.prop]?.from;
                const to = row?.[d.prop]?.to;

                data[d.prop] = from && to ? [ from, to ] : null;
                forEach(data[d.prop], (val, key) => {
                    data[d.prop][key] = moment.isMoment(val) ? val.format('HH:mm') : val;
                });
            });

            if (data[prop]?.length !== 2) {
                return;
            }

            weekSetupRowFactory.update.query(CurrentOld.customer.id, ctrl.weekSetup.id, row.id, data).$promise.then((updatedRow) => {
                updatedRow.vs_template = row.vs_template;
                ctrl.rows[index] = ctrl.formatRow(updatedRow);
            });
        };

        ctrl.delete = (row) => $mdDialog.show(
            $mdDialog.confirm()
                .theme('delete')
                .textContent(t('DELETE_QUESTION'))
                .ok(t('OK'))
                .cancel(t('CANCEL')),
        ).then(() => {
            weekSetupRowFactory.delete.query(CurrentOld.customer.id, ctrl.weekSetup.id, row.id).$promise.then(() => {
                ctrl.rows = ctrl.rows.filter((r) => r.id !== row.id);
            });
        });

        ctrl.formatRow = (row) => {
            ctrl.days.forEach((d) => {
                row[d.prop] = !Object.keys(row[d.prop] || []).length ? {} : {
                    from: row[d.prop][0],
                    to: row[d.prop][1],
                };
            });

            return row;
        };
    } ],
});
