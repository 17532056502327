import { inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { CurrentService } from '../services/current.service';
import { Products } from '../enums/products';
import { CustomerProductService } from '../http/customer-product.service';
import { firstValueFrom, forkJoin } from 'rxjs';
import { StateProviderDataData } from '../utils/create-state';
import { getTransitionCustomerId } from '../utils/transition-customer';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
    providedIn: 'root',
})
export class ProductsHookService {
    private readonly uiRouter = inject(UIRouter);
    private readonly current = inject(CurrentService);
    private readonly customerProductService = inject(CustomerProductService);
    private readonly snackBarService = inject(SnackBarService);
    constructor() {
        this.uiRouter.transitionService.onBefore({ to: (state) => state?.data?.products }, (transition) => {
            console.debug('🪝', 'ProductsHookService.onBefore', transition);

            const transitionCustomerId = getTransitionCustomerId(transition);
            const currentCustomerId = this.current.getCustomer().id;

            const products = (transition.to().data.products as StateProviderDataData['products'])?.reduce((acc, p) => {
                return p ? acc.concat(p) : acc;
            }, [] as Products[]) || [];

            // It's fine if either the current customer or the transition customer has the products
            const currentHasProducts = this.customerProductService.hasProductsDetailed(currentCustomerId, products);
            const transitionHasProducts = transitionCustomerId && transitionCustomerId != currentCustomerId ?
                this.customerProductService.hasProductsDetailed(transitionCustomerId, products) :
                Promise.resolve(undefined);

            // Has to be async in case we are fetching for someone else than the current customer
            return firstValueFrom(forkJoin([ currentHasProducts, transitionHasProducts ])).then(([ currentHas, transitionHas ]) => {
                if (currentHas.hasProducts || transitionHas?.hasProducts) {
                    return true;
                }

                void this.snackBarService.t('ROUTE_INSUFFICIENT_PRODUCTS');
                console.error('Insufficient products to access route', transitionHas?.missingProducts ?? currentHas.missingProducts);
                return transition.router.stateService.target('eaw/app/home');
            });
        });
    }
}
