import { Component, Inject, Input, OnInit } from '@angular/core';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { ProductService } from '../../../shared/services/product.service';
import { Product } from '../../../shared/models/product';
import { catchError, EMPTY, forkJoin, Observable } from 'rxjs';
import { Products } from '../../../shared/enums/products';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../shared/services/current.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { MatListOption, MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor } from '@angular/common';

interface ProductListItem {
    product: Product,
    enabled: boolean,
    isDefault: boolean,
    updating: boolean,
}

@Component({
    selector: 'eaw-admin-customer-group-products',
    templateUrl: './admin-customer-group-products.component.html',
    styleUrl: './admin-customer-group-products.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatCardModule,
        MatProgressSpinnerModule,
        MatListModule,
        NgFor,
    ],
})
export class AdminCustomerGroupProductsComponent implements OnInit {
    @Input({ required: true }) groupId!: number;

    loadingInitial = true;
    defaultProducts: Products[] = [ Products.Core, Products.Messaging, Products.Billing ];
    products: ProductListItem[] = [];

    constructor(
        @Inject(CustomerGroupService) private customerGroupService: CustomerGroupService,
        @Inject(ProductService) private productService: ProductService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
    ) {
    }

    ngOnInit() {
        const allProductsObservable = expandAllPages((pagination) => this.productService.getAll(pagination), { per_page: 40 });
        const groupProductsObservable = expandAllPages((pagination) => this.customerGroupService.getProducts(this.groupId, pagination), { per_page: 40 });

        forkJoin([
            allProductsObservable,
            groupProductsObservable,
        ]).subscribe(([ allProducts, groupProducts ]) => {
            this.loadingInitial = false;

            this.products = allProducts.map((product) => {
                return {
                    product,
                    enabled: !!groupProducts.find((g) => g.name === product.name),
                    isDefault: this.defaultProducts.includes(product.name),
                    updating: false,
                };
            });

            this.products = sort(this.products, this.current.languageTag, [ (p) => p.product.name ], [ 'asc' ]);
        });
    }

    toggleProduct(product: ProductListItem, option: MatListOption) {
        if (product.isDefault || product.updating) {
            return;
        }

        const initialEnabled = product.enabled;
        product.updating = true;

        let observable: Observable<void>;
        if (product.enabled) {
            observable = this.customerGroupService.removeProduct(this.groupId, product.product.name);
        } else {
            observable = this.customerGroupService.addProduct(this.groupId, product.product.name);
        }

        observable.pipe(
            catchError((error) => {
                console.error(error);
                void this.snackBarService.t('FAILED_TO_TOGGLE_PRODUCT', 'general', { product: product.product.name });

                product.updating = false;
                product.enabled = initialEnabled;
                option.selected = initialEnabled;

                return EMPTY;
            }),
        ).subscribe(() => {
            product.updating = false;
            product.enabled = !initialEnabled;
            option.selected = !initialEnabled;

            if (initialEnabled) {
                void this.snackBarService.t('ITEM_TURNED_OFF', 'general', { item: product.product.name });
            } else {
                void this.snackBarService.t('ITEM_TURNED_ON', 'general', { item: product.product.name });
            }
        });
    }
}
