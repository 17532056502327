import { Customer, CustomerResponse } from '../app/shared/models/customer';

export function mockCustomer(data: Partial<CustomerResponse> = {}) {
    return new Customer({
        active: false,
        address1: '',
        city: '',
        id: 0,
        locale_code: '',
        sso_services: [],
        name: '',
        number: '',
        postal_code: '',
        stack_id: 0,
        time_zone: '',
        type: '',
        ...data,
    });
}
