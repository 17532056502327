import { DateTime } from 'luxon';
import { Customer, CustomerResponse } from './customer';
import { stringToDateTime } from '../utils/string-to-date-time';
import { Product, ProductResponse } from './product';
import { ApiResponse } from '../interfaces/api-response';

export interface CustomerGroupResponse extends ApiResponse {
    id: number;
    members?: CustomerResponse[];
    model: 'customer';
    name: string;
    owner_id?: number | null;
    owner_type?: string | null;
    parent_id?: number | null;
    pivot?: any;
    type: 'customer';
    created_at?: string | null;
    deleted_at?: string | null;
    updated_at?: string | null;
    active_memberships_count?: number;
    memberships_count?: number;
    products?: ProductResponse[];
}

export class CustomerGroup {
    id: number;
    members: Customer[];
    model = 'customer';
    name: string;
    ownerId?: number | null;
    ownerType?: string | null;
    parentId?: number | null;
    pivot?: unknown;
    type = 'customer';
    createdAt?: string | DateTime | null;
    updatedAt?: string | DateTime | null;
    deletedAt?: string | DateTime | null;
    activeMembershipsCount?: number;
    membershipsCount?: number;
    products: Product[];

    constructor(data: CustomerGroupResponse) {
        this.id = data.id;
        this.members = data.members?.map((m) => new Customer(m)) || [];
        this.name = data.name;
        this.ownerId = data.owner_id;
        this.ownerType = data.owner_type;
        this.parentId = data.parent_id;
        this.pivot = data.pivot;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.activeMembershipsCount = data.active_memberships_count;
        this.membershipsCount = data.memberships_count;
        this.products = (data.products || []).map((p) => new Product(p));
    }
}
