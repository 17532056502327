// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
module('eaw.checklists').factory('ChecklistCategoryFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const url = '/customers/:customer/checklists/:list/categories/:category';
    /**
     * Get a single category
     * @param {int} customerId
     * @param {int} checklistId
     * @param {int} categoryId
     */
    fac.get = (customerId, checklistId, categoryId) => EawResource.create(url).get({
        customer: customerId,
        list: checklistId,
        category: categoryId,
        'with[]': [ 'items' ],
    });
    /**
     * Get a list of all categories
     * @param args
     * @param args.customer
     * @param args.checklist
     */
    fac.getAll = (args) => EawResource.create(url).get({
        customer: args.customer.id,
        list: args.checklist.id,
        'with[]': [ 'items' ],
        ...Pagination.getParams(args),
    });
    /**
     * Create checklist category
     * @param {object}  customer
     * @param {int}     customer.id
     * @param {object}  list
     * @param {int}     list.id
     * @param {object}  category
     * @param {string}  category.title
     * @param {int}     [category.weight]
     * @param {int}     [category.parent_id]
     */
    fac.create = (customer, list, category) => {
        const picks = pick(category, [ 'title', 'weight', 'parent_id' ]);
        return EawResource.create(url).save({
            customer: customer.id,
            list: list.id,
        }, picks);
    };
    /**
     * Update an existing category
     * @param {object}  customer
     * @param {object}  list
     * @param {object}  category
     * @param {int}     category.id
     * @param {string}  [category.title]
     * @param {int}     [category.weight]
     * @param {int}     [category.parent_id]
     */
    fac.update = (customer, list, category) => EawResource.create(url).update({
        customer: customer.id,
        list: list.id,
        category: category.id,
    }, {
        title: category.title,
        parent_id: category.parent_id === list.id ? null : category.parent_id,
        weight: category.weight,
    });
    /**
     * Delete a category
     * @param customerId
     * @param checklistId
     * @param categoryId
     */
    fac.delete = (customerId, checklistId, categoryId) => EawResource.create(url).delete({
        customer: customerId,
        list: checklistId,
        category: categoryId,
    });
    return fac;
} ]);
