import { Component, inject, Input, OnInit } from '@angular/core';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatDialog } from '@angular/material/dialog';
import { CreateEditTimepunchBlockDialogComponent, CreateEditTimepunchBlockDialogData, CreateEditTimepunchBlockDialogReturn } from '../../dialogs/create-edit-timepunch-block-dialog/create-edit-timepunch-block-dialog.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TimepunchBlocksService } from '../../http/timepunch-blocks.service';
import { TimepunchBlock } from '../../models/timepunch-block';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { CurrentService } from '../../../shared/services/current.service';
import { MatDivider } from '@angular/material/divider';
import { PermissionPipe } from '../../../shared/pipes/permission.pipe';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { Namespace } from '../../../shared/enums/namespace';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { catchError, debounceTime, distinctUntilChanged, EMPTY, of, switchMap } from 'rxjs';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

interface ListItem {
    block: TimepunchBlock,
    hideReason: FormControl<boolean>
}

@Component({
    selector: 'eaw-employee-timepunch-blocks-tab',
    standalone: true,
    imports: [
        DataTableComponent,
        MatCard,
        MatCardContent,
        PageHeaderComponent,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        MatDivider,
        PermissionPipe,
        MatIcon,
        MatIconButton,
        MaterialColorDirective,
        MatButton,
        InfoLoadingComponent,
        MatIconSizeDirective,
        MatSlideToggle,
        NgIf,
        ReactiveFormsModule,
    ],
    templateUrl: './employee-timepunch-blocks-tab.component.html',
    styleUrl: './employee-timepunch-blocks-tab.component.scss',
})
export class EmployeeTimepunchBlocksTabComponent implements OnInit {
    private readonly matDialog = inject(MatDialog);
    private readonly currentService = inject(CurrentService);
    private readonly snackBarService = inject(SnackBarService);
    private readonly translateService = inject(TranslateService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly timepunchBlocksService = inject(TimepunchBlocksService);

    @Input({ required: true }) employeeId!: number;
    @Input({ required: true }) customerId!: number;

    loading = true;
    language: string;
    headerFabButton: HeaderFabButton;
    timepunchBlocks: ListItem[] = [];

    constructor() {
        this.headerFabButton = {
            click: this.create.bind(this),
            hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.timepunch_blocks.create`),
        };

        this.language = this.currentService.languageTag;
    }

    ngOnInit() {
        this.getBlocks();
    }

    blockToListItem(block: TimepunchBlock): ListItem {
        const hideReason = new FormControl<boolean>(!block.displayReason, { nonNullable: true });

        if (!block.reason) {
            hideReason.disable();
        }

        hideReason.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((value) => this.timepunchBlocksService.update(this.customerId, this.employeeId, block.id, block.startDate, undefined, undefined, !value)),
        ).subscribe();

        return { block, hideReason };
    }

    getBlocks() {
        this.loading = true;
        this.timepunchBlocksService.getAllForEmployee(this.customerId, this.employeeId).subscribe((timepunchBlocks) => {
            const blocks = timepunchBlocks.map(this.blockToListItem.bind(this));

            this.timepunchBlocks = sort(blocks, this.currentService.languageTag, [ (x) => x.block.startDate ]);
            this.loading = false;
        });
    }

    create() {
        this.matDialog.open<CreateEditTimepunchBlockDialogComponent, CreateEditTimepunchBlockDialogData, CreateEditTimepunchBlockDialogReturn>(CreateEditTimepunchBlockDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: this.employeeId,
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.timepunchBlocks.push(this.blockToListItem(result));
            }
        });
    }

    update(block: TimepunchBlock) {
        this.matDialog.open<CreateEditTimepunchBlockDialogComponent, CreateEditTimepunchBlockDialogData, CreateEditTimepunchBlockDialogReturn>(CreateEditTimepunchBlockDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: this.employeeId,
                block: of(block),
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.timepunchBlocks.splice(this.timepunchBlocks.findIndex((b) => b.block.id === block.id), 1, this.blockToListItem(result));
            }
        });
    }

    delete(block: TimepunchBlock) {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translateService.t('DELETE_TIMEPUNCH_BLOCK', Namespace.Company),
                confirmText: this.translateService.t('DELETE'),
                confirmColor: 'red-500',
                size: DialogSize.Medium,
            },
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                return;
            }

            // Remove immediately
            this.timepunchBlocks = this.timepunchBlocks.filter((b) => b.block.id !== block.id);
            this.timepunchBlocksService.delete(this.customerId, this.employeeId, block.id).pipe(
                catchError(() => {
                    void this.snackBarService.t('DELETE_FAIL');
                    this.getBlocks();
                    return EMPTY;
                }),
            ).subscribe(() => {
                this.timepunchBlocks = this.timepunchBlocks.filter((b) => b.block.id !== block.id);
            });
        });
    }
}
