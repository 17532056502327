@if (task.completed) {
    <p>
        {{ 'UPDATE_PIN_CODE_TASK_COMPLETED_TEXT' | translate: 'tasks' | async }}
    </p>
} @else {
    <p>
        {{ 'UPDATE_PIN_CODE_TASK_TEXT' | translate: 'tasks' | async }}
    </p>
    <form (ngSubmit)="updatePinCode()">
        <mat-form-field>
            <mat-label>{{ 'NEW_X' | translate: 'tasks':{ name: '$t(PIN_CODE)' } | async }}</mat-label>
            <input matInput type="text" [formControl]="pinCodeInput" [maxlength]="maxLength">
            <eaw-action-button type="submit" class="tw-m-4" matSuffix [loading]="saving" [disabled]="task.completed || pinCodeInput.invalid" (click)="updatePinCode()">
                {{ 'SAVE' | translate | async }}
            </eaw-action-button>
        </mat-form-field>
    </form>

    <eaw-form-rules [rules]="errorMessages" [input]="pinCodeInput"></eaw-form-rules>
}
