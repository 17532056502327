const isReactNative = typeof navigator === "undefined" ? false : navigator.product === "ReactNative";
const defaultOptions = {
  timeout: isReactNative ? 6e4 : 12e4
};
const processOptions = function processOptions2(opts) {
  const options = {
    ...defaultOptions,
    ...(typeof opts === "string" ? {
      url: opts
    } : opts)
  };
  const {
    searchParams
  } = new URL(options.url, "http://localhost");
  options.timeout = normalizeTimeout(options.timeout);
  if (options.query) {
    for (const [key, value] of Object.entries(options.query)) {
      if (value !== void 0) {
        if (Array.isArray(value)) {
          for (const v of value) {
            searchParams.append(key, v);
          }
        } else {
          searchParams.append(key, value);
        }
      }
    }
  }
  const [url] = options.url.split("?");
  const search = searchParams.toString();
  if (search) {
    options.url = "".concat(url, "?").concat(search);
  }
  options.method = options.body && !options.method ? "POST" : (options.method || "GET").toUpperCase();
  return options;
};
function normalizeTimeout(time) {
  if (time === false || time === 0) {
    return false;
  }
  if (time.connect || time.socket) {
    return time;
  }
  const delay = Number(time);
  if (isNaN(delay)) {
    return normalizeTimeout(defaultOptions.timeout);
  }
  return {
    connect: delay,
    socket: delay
  };
}
const validUrl = /^https?:\/\//i;
const validateOptions = function validateOptions2(options) {
  if (!validUrl.test(options.url)) {
    throw new Error('"'.concat(options.url, '" is not a valid URL'));
  }
};
export { processOptions, validateOptions };
