// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
module('eaw.checklists').factory('AutoStartChecklist', [ 'EawResource', function(EawResource) {
    const _resource = EawResource.create('/customers/:customer_id/checklists/:checklist_id/autostart');
    return function AutoStartChecklist(customerId, checklistId) {
        const customer_id = customerId;
        const checklist_id = checklistId;
        this.get = () => _resource.get({
            customer_id: customerId,
            checklist_id: checklistId,
        }).$promise;
        this.set = (data) => _resource.update({
            customer_id,
            checklist_id,
        }, pick(data, [ 'type', 'data', 'user_id', 'skip', 'skipped' ])).$promise;
        this.delete = () => _resource.delete({
            customer_id,
            checklist_id,
        }).$promise;
    };
} ]);
