<eaw-dialog-header *ngIf="!data.title">
    <span title>{{'WARNING_plural' | translate | async}}</span>
</eaw-dialog-header>

<eaw-dialog-header *ngIf="data.title">
    <span title>{{data.title | async}}</span>
    <span subtitle>{{'WARNING_plural' | translate | async}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="loading" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content *ngIf="!loading">
    <div *ngFor="let item of items">
        <h3>{{item.time | DateTime:'DATETIME_MED'}}</h3>
        <p>{{item.message | translate:'warnings':item.messageParameters | async}}</p>
        <div *ngIf="item.hasCauses">
            <button mat-raised-button color="primary" (click)="openCauseDialog(item)" *ngIf="item.id && item.customerId">{{'INFORMATION' | translate | async}}</button>
        </div>
    </div>
</mat-dialog-content>
