// @ts-nocheck
import { find } from 'lodash-es';

import moment from 'moment-timezone';

import { module } from 'angular';

const template2 = `<span id="weekday-name" ng-bind="ctrl.date | moment:'dddd'"></span>
<span id="date" ng-bind="ctrl.date | moment:'D'"></span>

<div id="events">
    <md-menu md-position-mode="target-right target" ng-repeat="(key, event) in ctrl.events track by key">
        <div class="event"
             md-menu-origin
             ng-click="ctrl.openMenu($mdMenu, $event, event)"
             ng-style="{background: event.isPeriod ? event.backgroundColor : '', color: event.isPeriod ? event.textColor : ''}">
            <div ng-if="!event.isPeriod" class="event-color" ng-style="{background: event.backgroundColor}"></div>
            <div ng-if="!event.wholeDay" class="event-time" ng-bind="event.from | moment:'LT'"></div>
            <div class="event-name overflow-ellipsis" ng-bind="event.name"></div>
        </div>

        <md-menu-content class="md-dense" width="3">
            <md-menu-item ng-repeat="item in ctrl.contextMenu" ng-if="item.onClick">
                <md-button ng-click="ctrl.contextMenuClick(event, item)">
                    <md-icon md-menu-align-target md-svg-src="{{item.icon}}"></md-icon>
                    <span ng-bind="item.text"></span>
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>

    <div class="no-events" ng-if="!ctrl.events.length" ng-bind="ctrl.createSource.noItemsText"></div>
</div>


<md-button ng-if="ctrl.createSource.button.hasPermission"
           class="new-event-btn"
           ng-click="ctrl.createBtnClick(ctrl.createSource.button)"
           ng-i18next="ADD">
</md-button>

`;

const template1 = `<div class="day-container" title="{{$yeda.getTitle()}}">
    <span ng-bind="$yeda.day.date"></span>

    <div class="events-container">
        <div class="event-line"
             ng-repeat="event in $yeda.calendar.events[$yeda.key]"
             ng-if="event.visible"
             ng-style="{background: event.backgroundColor}">
        </div>
    </div>
</div>
`;

module('eaw.calendar2').component('eawYearDay', {
    template: template1,
    bindings: {
        day: '<',
    },
    require: {
        calendar: '?^^eawCalendar2',
        yearCalendar: '^^eawYearCalendar',
        monthCalendar: '^eawYearMonth',
    },
    controllerAs: '$yeda',
    controller: [ '$element', '$mdPanel', function($element, $mdPanel) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.element = $element[0]; // Save el
            ctrl.date = ctrl.day.moment; // Moment for this day

            ctrl.checkMonth();
        };

        ctrl.$onDestroy = () => {
            ctrl.element.removeEventListener('click', ctrl.clickEvent);
        };

        ctrl.rangeSelect = () => {
            ctrl.element.addEventListener('mousedown', ctrl.yearCalendar.startRangeSelect);
            ctrl.element.addEventListener('mouseup', ctrl.yearCalendar.stopRangeSelect);
        };

        ctrl.checkClasses = () => {
            ctrl.checkHolidayClass();
            ctrl.checkSundayClass();
            ctrl.checkTodayClass();
        };

        ctrl.checkHolidayClass = () => {
            if (find(ctrl.events, (e) => e.holiday)) {
                ctrl.element.classList.add('holiday');
            } else {
                ctrl.element.classList.remove('holiday');
            }
        };

        ctrl.clickEvent = (e) => {
            const eventsOnDate = ctrl.calendar.getEventsInInterval(ctrl.date.clone().startOf('d'), ctrl.date.clone().endOf('d'));

            $mdPanel.open({
                attachTo: document.body,
                controller: 'eawYearDayPanelCtrl2',
                controllerAs: 'ctrl',
                template: template2,
                panelClass: 'eaw-panel year-day-panel',
                position: $mdPanel.newPanelPosition()
                    .relativeTo(ctrl.element)
                    .addPanelPosition(
                        $mdPanel.xPosition.OFFSET_END,
                        $mdPanel.yPosition.ALIGN_TOPS,
                    ),
                animation: $mdPanel.newPanelAnimation()
                    .openFrom(e)
                    .duration(100)
                    .withAnimation($mdPanel.animation.SCALE),
                targetEvent: e,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                bindToController: true,
                locals: {
                    date: ctrl.date,
                    element: ctrl.element,
                    events: eventsOnDate,
                },
                onDomAdded() {
                    ctrl.element.classList.add('selected-date');
                },
                onRemoving() {
                    ctrl.element.classList.remove('selected-date');
                },
            });
        };

        ctrl.checkSundayClass = () => {
            if (ctrl.date.isoWeekday() === 7) {
                ctrl.element.classList.add('sunday');
            }
        };

        ctrl.checkTodayClass = () => {
            if (ctrl.date.isSame(moment(), 'd')) {
                ctrl.element.classList.add('is-today');
            }
        };

        ctrl.checkMonth = () => {
            if (ctrl.monthCalendar.month === ctrl.date.month()) {
                ctrl.element.dataset.dayOfYear = ctrl.date.dayOfYear(); // Add useful info to dataset
            } else {
                ctrl.element.classList.add('outside-month');
                return;
            }

            // If the day is in the month, do all stuff
            // Init range selection
            ctrl.rangeSelect();
            // Add class(es)
            ctrl.checkClasses();
            // Event handler for clicking on a day
            ctrl.element.addEventListener('click', ctrl.clickEvent);
        };
    } ],
});
