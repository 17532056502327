import { ChangeDetectionStrategy, Component, computed, inject, Input, signal, Signal, viewChild } from '@angular/core';
import { HrFileTypeService } from '../../http/hr-file-type.service';
import { DataTable, DataTablePagination } from '../../../data-table/types/data-table';
import { HrFileType } from '../../models/hr-file-type';
import { DataTableComponent } from 'src/app/data-table/data-table.component';
import { DataTableColumnType } from 'src/app/data-table/interfaces/data-table-columns';
import { MatCard, MatCardContent } from '@angular/material/card';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableBooleanColumn } from '../../../data-table/types/data-table-boolean-column';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { Products } from 'src/app/shared/enums/products';
import { forkJoin, of, switchMap, tap } from 'rxjs';
import { SignalInput } from '../../../shared/decorators/signal-input.decorator';
import { Namespace } from '../../../shared/enums/namespace';
import { DigitalSignatureService, SignatureProvider } from '../../../digital_signing/digital-signature.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { canCreateHrFiletypePermission, canCreateSignablePermission, canDeleteHrFiletypePermission, canUpdateHrFiletypePermission } from '../../permissions';
import { HrFileTypeDialogComponent, HrFileTypeDialogData } from '../../dialogs/hr-file-type-dialog/hr-file-type-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { TranslateService } from '../../../shared/services/translate.service';

@Component({
    selector: 'eaw-hr-filetypes',
    standalone: true,
    imports: [
        MatCard,
        DataTableComponent,
        PageHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        MatCardContent,
    ],
    templateUrl: './hr-filetypes.component.html',
    styleUrl: './hr-filetypes.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HrFiletypesComponent implements DataTable<HrFileType> {
    private digitalSignatureService = inject(DigitalSignatureService);
    private permissionCheckService = inject(PermissionCheckService);
    private customerProductService = inject(CustomerProductService);
    private hrFiletypeService = inject(HrFileTypeService);
    private translateService = inject(TranslateService);
    private matDialog = inject(MatDialog);

    dataTable = viewChild.required(DataTableComponent);

    @Input({ required: true }) @SignalInput() stackId!: Signal<number>;
    @Input({ required: true }) @SignalInput() customerId!: Signal<number>;

    columns = computed(this.computeColumns.bind(this));
    getData = computed(this.computeGetData.bind(this));
    signatureProviders = signal<SignatureProvider[]>([]);

    extraCol = signal<DataTableTextColumn<HrFileType> | undefined>(undefined);

    fabButton: HeaderFabButton = {
        click: this.openDialog.bind(this),
        hasPermission: () => this.permissionCheckService.isAllowed(canCreateHrFiletypePermission(this.customerId())),
    };

    computeColumns(): DataTableColumnType<HrFileType>[] {
        const providers = this.signatureProviders();
        const extraCol = this.extraCol();

        const columns: DataTableColumnType<HrFileType>[] = [
            new DataTableTextColumn({
                value: 'name',
                header: new DataTableHeader({
                    i18n: 'NAME',
                    sortBy: 'name',
                }),
            }),
            new DataTableBooleanColumn({
                boolean: 'mandatory',
                header: new DataTableHeader({
                    i18n: 'MANDATORY_FILE',
                    ns: Namespace.Hr,
                }),
            }),
            new DataTableNumberColumn({
                value: (cell) => cell.item.filesCount,
                header: new DataTableHeader({
                    i18n: 'FILE_plural',
                    ns: Namespace.Hr,
                }),
            }),
        ];

        if (extraCol) {
            columns.unshift(extraCol);
        }

        if (providers?.length) {
            columns.push(new DataTableTextColumn({
                value: (cell) => providers.find((p) => p.class === cell.item.signable?.provider)?.name,
                header: new DataTableHeader({
                    i18n: 'SIGN_WITH',
                    ns: Namespace.Digisign,
                }),
            }));
        }

        columns.push(new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'edit',
                    click: this.openDialog.bind(this),
                    show: (type) => this.permissionCheckService.isAllowed(canUpdateHrFiletypePermission(this.stackId(), this.customerId(), type.id)),
                    tooltip: { key: 'EDIT' },
                },
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.deleteType.bind(this),
                    show: (type) => type.filesCount ? of(false) : this.permissionCheckService.isAllowed(canDeleteHrFiletypePermission(this.stackId(), this.customerId(), type.id)),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }));

        return columns;
    }

    computeGetData() {
        return (pagination: Partial<DataTablePagination>) => {
            const productObservable = this.customerProductService.hasProducts(this.customerId(), [ Products.DigitalSigning ]);
            const permissionObservable = this.permissionCheckService.isAllowed(canCreateSignablePermission(this.customerId()));

            return forkJoin([
                permissionObservable,
                productObservable,
            ]).pipe(
                switchMap(([ hasPermission, hasProduct ]) => {
                    const typesObservable = this.hrFiletypeService.getAll(this.customerId(), {
                        ...pagination,
                        include_signable: true,
                    });

                    const infosObservable = this.digitalSignatureService.getAvailable(this.customerId()).pipe(
                        tap((providers) => this.signatureProviders.set(providers)),
                        switchMap(() => typesObservable),
                    );

                    return hasPermission && hasProduct ? infosObservable : typesObservable;
                }),
            );
        };
    }

    deleteType(cell: DataTableButtonCell<HrFileType>) {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translateService.t('DELETE', Namespace.Admin),
                text: this.translateService.t('DELETE_FILETYPE', Namespace.Hr, { name: cell.item.name }),
                confirmText: this.translateService.t('DELETE'),
                confirmColor: 'red-500',
                size: DialogSize.Medium,
            },
        }).afterClosed().subscribe((result) => {
            if (result?.ok) {
                this.hrFiletypeService.delete(this.customerId(), cell.item.id).subscribe(() => {
                    this.dataTable().refresh();
                });
            } else {
                cell.disabled.set(false);
            }
        });
    }

    openDialog(cell?: DataTableButtonCell<HrFileType>) {
        this.matDialog.open<HrFileTypeDialogComponent, HrFileTypeDialogData, HrFileType>(HrFileTypeDialogComponent, {
            data: {
                customerId: this.customerId(),
                fileType: cell ? of(cell.item) : undefined,
            },
        }).afterClosed().pipe(
            tap((result) => {
                if (result) {
                    this.dataTable().refresh();
                } else {
                    cell?.disabled.set(false);
                }
            }),
        ).subscribe();
    }
}
