// @ts-nocheck
import { t } from 'i18next';
import { orderBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { IntervalOld } from '../../../shared/angularjs/interval-old';
import MlTimeSeriesEntry from '../shared/ml-timeseries-entry';
import MlTimeSeries from '../shared/ml-timeseries';
import { uniqueId } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { UnsavedChangesService } from '../../../shared/services/unsaved-changes.service';
export class AiBudgetingEasyProjections {
    mlComparisonFactory;
    ItemSelectorDialog;
    $element;
    EasyProjection;
    errorService;
    UnsavedChangesDowngrade: UnsavedChangesService;
    $mdDialog;
    mlProjectionsFactory;

    static get $inject() {
        return [ 'mlComparisonFactory', 'ItemSelectorDialog', '$element', 'EasyProjection', 'errorService', 'UnsavedChangesDowngrade', '$mdDialog', 'mlProjectionsFactory' ];
    }

    showFilter = true;
    dateInterval = new IntervalOld(moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month'));
    entries;
    sums = {};
    saving = false;
    gettingData = false;
    interval = 86400;
    // start bindings
    constants;
    user;
    customer;
    bucket;
    // end bindings
    form;
    variable;
    actualSeries;
    forecastSeries;
    automaticForecastSeries;
    selectedInterval;

    constructor(mlComparisonFactory, ItemSelectorDialog, $element, EasyProjection, errorService, UnsavedChangesDowngrade, $mdDialog, mlProjectionsFactory) {
        this.mlComparisonFactory = mlComparisonFactory;
        this.ItemSelectorDialog = ItemSelectorDialog;
        this.$element = $element;
        this.EasyProjection = EasyProjection;
        this.errorService = errorService;
        this.UnsavedChangesDowngrade = UnsavedChangesDowngrade;
        this.$mdDialog = $mdDialog;
        this.mlProjectionsFactory = mlProjectionsFactory;
    }

    $onInit() {
        // None is not a valid interval on this page
        delete this.constants.intervals.INTERVAL_NONE;
        // Initialize with the functions that checks if things are edited
        this.UnsavedChangesDowngrade.init(this.someEdited);
    }

    startEditingClick(entry, column, event) {
        const canEdit = column === 'forecast' || (column === 'diff' && entry.hasLastYear);
        if (canEdit) {
            this.startEditing(event);
        }
    }

    startEditing(event) {
        const cell = event.currentTarget.closest('.entry-cell');
        const input = cell.querySelector('input');
        cell.classList.add('editing');
        input.focus();
        input.select();
        input.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }

    stopEditing(event) {
        event?.currentTarget?.closest('.entry-cell')?.classList?.remove('editing');
        this.updateSums();
    }

    revert(entry) {
        entry.revert();
        this.updateSums();
    }

    someEdited() {
        return this.entries?.some((e) => e.edited);
    }

    getEditedEntries() {
        return this.entries?.filter((e) => e.edited);
    }

    revertAll() {
        this.$mdDialog.show(this.$mdDialog.confirm()
            .theme('delete')
            .title(t('RESET'))
            .textContent(t('ai_budgeting:RESET_ALL_CONFIRM_TEXT', {
                count: this.getEditedEntries()?.length,
            }))
            .ok(t('ai_budgeting:RESET_ALL_VALUE'))
            .cancel(t('CANCEL'))).then(() => {
            this.entries?.forEach((e) => e.revert());
            this.updateSums();
        });
    }

    updateSums() {
        this.sums = {
            lastYear: this.entries?.reduce((sum, e) => sum + (e.lastYear?.value || 0), 0),
            forecast: this.entries?.reduce((sum, e) => sum + (e.forecast?.value || 0), 0),
            autoForecast: this.entries?.reduce((sum, e) => sum + (e.autoForecast?.value || 0), 0),
            actual: this.entries?.reduce((sum, e) => sum + (e.actual?.value || 0), 0),
        };
        const lastYear = this.sums.lastYear || 0;
        const forecast = this.sums.forecast || 0;
        const autoForecast = this.sums.autoForecast || 0;
        const actual = this.sums.actual || 0;
        this.sums.forecastDiff = (forecast / lastYear) - 1;
        this.sums.actualDiff = (actual / forecast) - 1;
        this.sums.autoForecastDiff = (actual / autoForecast) - 1;
    }

    onNext() {
        const table = document.getElementById('entries-table');
        const currentCell = table.getElementsByClassName('editing')[0];
        const currentInput = currentCell.querySelector('input');
        const inputs = table.querySelectorAll('input');
        Array.from(inputs).forEach((input, index) => {
            if (currentInput === input) {
                // If index is the last input then go to the start, or else just next one
                const i = index === inputs.length - 1 ? 0 : index + 1;
                this.startEditing({
                    currentTarget: Array.from(inputs)[i],
                });
            }
        });
    }

    getEditedSection() {
        // Find indexes of first and last edited entry
        const firstEdited = this.entries?.findIndex((e) => e.edited);
        const lastEdited = this.entries?.reduce((lastIndex, entry, index) => entry.edited ? index : lastIndex, -1);
        // Slice the part that is edited
        return this.entries?.slice(firstEdited, lastEdited + 1);
    }

    save() {
        this.chooseVariables().then((vars) => {
            this.onChosenVariables(vars);
        });
    }

    onChosenVariables(vars) {
        // Remove variable we are editing
        let v = vars?.filter((variable) => variable.id !== this.variable.id);
        // Make it undefined if nothing extra is selected
        if (!vars?.length) {
            v = undefined;
        }
        // Clear any entries we might have from before
        this.forecastSeries.clearEntries();
        this.getEditedSection().forEach((e) => {
            this.forecastSeries.addEntry(e.forecast);
        });
        this.saving = true;
        this.mlProjectionsFactory.create.query(this.customer.id, this.bucket.id, this.forecastSeries.id, {
            from: this.dateInterval.from,
            to: this.dateInterval.to,
            name: `${moment().format('YYYY-MM-DD HH:mm')}: Projection by ${this.user.first_name} ${this.user.last_name}`,
            applyProjections: true,
            mirrorToVariables: v?.map((v) => v.id),
            projections: this.forecastSeries.projections,
        }).then(() => {
            this.getData();
        }).finally(() => delete this.saving);
    }

    chooseVariables() {
        return this.ItemSelectorDialog.open({
            title: t('ai_budgeting:VARIABLE_plural'),
            items: orderBy(this.bucket.variables, 'name', 'asc'),
            displayKey: 'name',
            subheader: t('ai_budgeting:CHOOSE_VAR_HELP'),
            selectedItems: [ this.variable ],
            disabledItems: [ this.variable ],
            multiSelect: true,
        });
    }

    validPeriodInterval() {
        const maxDays = moment.duration(1, 'w').asDays();
        const dayDiff = Math.abs(this.dateInterval.from.diff(this.dateInterval.to, 'd'));
        // Period is always valid if selected interval is 1 day or greater
        if (!this.interval || this.interval >= moment.duration(1, 'd').asSeconds()) {
            return true;
        }
        // If period is styles than one day, then make sure day diff is maximum one week
        if (Number.isFinite(dayDiff) && dayDiff <= maxDays) {
            return true;
        }
        this.errorService.showError({
            data: {
                status: 0,
                message: t('ai_budgeting:PERIOD_INTERVAL_WARN', {
                    max: maxDays,
                    days: dayDiff,
                }),
            },
        });
        this.form.unsubmit(false);
        return false;
    }

    getData() {
        if (!this.validPeriodInterval()) {
            return;
        }
        this.gettingData = true;
        this.selectedInterval = this.interval;
        this.mlComparisonFactory.getForecastAndActual.query(this.customer.id, this.bucket.id, this.variable.id, this.dateInterval.from, this.dateInterval.to, this.interval).then((resp) => {
            this.formatData(resp);
        }).finally(() => {
            this.form.unsubmit();
        }).finally(() => delete this.gettingData);
    }

    formatData(resp) {
        const timeSeries = resp.time_series;
        this.actualSeries = new MlTimeSeries(timeSeries.actual);
        this.forecastSeries = new MlTimeSeries(timeSeries.forecast);
        this.automaticForecastSeries = timeSeries.auto_forecast ? new MlTimeSeries(timeSeries.auto_forecast) : undefined;
        this.$element[0].style.setProperty('--projection-columns', this.automaticForecastSeries ? 7 : 5);
        this.entries = resp.times.map((t) => this.formatEntry(t, resp));
        // Update sums because of the new data
        this.updateSums();
    }

    formatEntry(time, resp) {
        const data = {
            id: uniqueId(),
            time,
        };
        const defaultEntry = {
            time,
            value: null,
        };
        // Try to find the values with the corresponding time
        const actual = resp.actual?.find?.((a) => a?.time === time) || defaultEntry;
        const forecast = resp.forecast?.find?.((a) => a?.time === time) || defaultEntry;
        const autoForecast = resp.auto_forecast?.find?.((a) => a?.time === time) || defaultEntry;
        const lastYear = resp.last_year?.find?.((a) => a?.time === time) || defaultEntry;
        // Insert as entries
        data.actual = new MlTimeSeriesEntry(actual, this.actualSeries);
        data.forecast = new MlTimeSeriesEntry(forecast, this.forecastSeries);
        data.lastYear = new MlTimeSeriesEntry(lastYear, this.forecastSeries);
        // This is sometimes null
        if (this.automaticForecastSeries) {
            data.autoForecast = new MlTimeSeriesEntry(autoForecast, this.automaticForecastSeries);
        }
        return new this.EasyProjection(data, this.selectedInterval);
    }
}
module('eaw.ai-budgeting').component('aiBudgetingEasyProjections', {
    template: `<md-card id="projections-filters" ng-show="$ctrl.showFilter">
    <md-card-content>
        <form name="tempForm" ng-submit="$ctrl.getData()">
            <div layout="row" layout-wrap>
                <eaw-date-interval layout="row"
                                   ng-model="$ctrl.dateInterval"
                                   required
                                   flex="100"
                                   flex-md="50"
                                   flex-gt-md="50">
                </eaw-date-interval>

                <md-input-container flex="100" flex-sm="50" flex-gt-sm="25">
                    <label ng-i18next="INTERVAL"></label>
                    <md-select ng-model="$ctrl.interval" required>
                        <md-option ng-repeat="(text, interval) in $ctrl.constants.intervals track by $index" ng-value="interval">
                            <span ng-bind="text | eawTranslate:'ai_budgeting'"></span>
                        </md-option>
                    </md-select>
                </md-input-container>

                <md-input-container flex="100" flex-sm="50" flex-gt-sm="25">
                    <label ng-i18next="ai_budgeting:VARIABLE"></label>
                    <md-select ng-model="$ctrl.variable" required>
                        <md-option ng-repeat="variable in $ctrl.bucket.variables track by variable.uuid" ng-value="variable">
                            <span ng-bind="variable.name"></span>
                        </md-option>
                    </md-select>
                </md-input-container>
            </div>

            <eaw-submit-btn form="$ctrl.form"></eaw-submit-btn>
        </form>
    </md-card-content>
</md-card>

<md-card id="projections-card">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-i18next="ai_budgeting:PROJECTION_plural"></span>
        </md-card-header-text>

        <md-button class="md-icon-button" ng-click="$ctrl.revertAll()" ng-disabled="!$ctrl.someEdited()">
            <md-icon ng-bind="'restore_from_trash'">
                <md-tooltip ng-i18next="ai_budgeting:RESET_ALL_VALUE" md-direction="left"></md-tooltip>
            </md-icon>
        </md-button>

        <eaw-toggle-btn ng-model="$ctrl.showFilter"
                        icon="filter_alt"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>

        <card-btn-float ng-disabled="$ctrl.saving || !$ctrl.someEdited()"
                        mdi-icon="save"
                        tooltip="'SAVE'"
                        on-click="$ctrl.save()">
        </card-btn-float>
    </md-card-header>
    <md-card-content id="projections-table-container" class="tw-p-0 overflow-x-auto">
        <md-progress-circular ng-if="$ctrl.gettingData" class="tw-mt-16 md-progress-center"></md-progress-circular>

        <div id="entries-table" ng-if="!$ctrl.gettingData && $ctrl.entries.length">
            <div id="entries-table-head" class="entry-row">
                <div class="entry-cell" bo-i18next="DATE"></div>
                <div class="entry-cell" bo-i18next="ai_budgeting:LAST_YEAR_SALES"></div>
                <div class="entry-cell">
                    <div layout="column" layout-align="center end">
                        <span bo-i18next="ai_budgeting:FORECAST"></span>
                        <small bo-i18next="ai_budgeting:EDITABLE"></small>
                    </div>
                </div>
                <div class="entry-cell">
                    <div layout="column" layout-align="center end">
                        <span bo-i18next="ai_budgeting:FORECAST_VS_LAST_YEAR"></span>
                        <small bo-i18next="ai_budgeting:EDITABLE"></small>
                    </div>
                </div>
                <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries" bo-i18next="ai_budgeting:AI_FORECAST"></div>
                <div class="entry-cell" bo-i18next="ai_budgeting:ACTUAL_SALES"></div>
                <div class="entry-cell" bo-i18next="ai_budgeting:ACTUAL_VS_FORECAST"></div>
                <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries" bo-i18next="ai_budgeting:ACTUAL_VS_AI_FORECAST"></div>
                <div class="entry-cell"></div>
            </div>

            <md-virtual-repeat-container id="virtual-entries-container">
                <div class="entry-row" md-virtual-repeat="entry in $ctrl.entries" ng-class="{'edited': entry.edited}">
                    <div class="entry-cell date-cell">
                        <div ng-bind="entry.timeString"></div>
                        <div class="weekday" ng-bind="entry.time.format('dddd')"></div>
                    </div>

                    <div class="entry-cell">
                        <span ng-if="entry.hasLastYear" ng-bind="entry.lastYear.value | eawNumber"></span>
                    </div>

                    <div class="entry-cell editable-item hover-item edit-highlight" ng-click="$ctrl.startEditingClick(entry, 'forecast', $event)">
                        <span ng-if="entry.forecast.hasValue" ng-bind="entry.forecast.value | eawNumber"></span>

                        <input type="number"
                               step="1"
                               eaw-on-tab-enter="$ctrl.onNext()"
                               ng-blur="$ctrl.stopEditing($event)"
                               ng-change="entry.updateForecastDiff()"
                               ng-model="entry.forecast.value">
                    </div>

                    <div ng-class="{'editable-item hover-item': entry.hasLastYear}" class="entry-cell edit-highlight" ng-click="$ctrl.startEditingClick(entry, 'diff', $event)">
                        <span ng-if="entry.forecast.hasValue" ng-bind="entry.forecastDiff | percent:2"></span>

                        <input type="number"
                               step=".01"
                               ng-if="entry.hasLastYear"
                               eaw-on-tab-enter="$ctrl.onNext()"
                               ng-blur="$ctrl.stopEditing($event)"
                               ng-change="entry.updateForecastValue()"
                               ng-model="entry.forecastDiffBig">
                    </div>

                    <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries">
                        <span ng-if="entry.hasAutoForecast" ng-bind="entry.autoForecast.value | eawNumber"></span>
                    </div>

                    <div class="entry-cell">
                        <span ng-if="entry.hasActual" ng-bind="entry.actual.value | eawNumber"></span>
                    </div>

                    <div class="entry-cell edit-highlight">
                        <span ng-if="entry.hasActual" ng-bind="entry.actualDiff | percent:2"></span>
                    </div>

                    <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries">
                        <span ng-if="entry.hasAutoForecast" ng-bind="entry.autoForecastDiff | percent:2"></span>
                    </div>

                    <div class="entry-cell">
                        <eaw-icon-button
                                eaw-invisible="{{!entry.edited}}"
                                icon="refresh"
                                tooltip="'RESET'"
                                ng-click="$ctrl.revert(entry)">
                        </eaw-icon-button>
                    </div>
                </div>
            </md-virtual-repeat-container>

            <div id="entries-table-foot" class="entry-row">
                <div class="entry-cell"></div>
                <div class="entry-cell" ng-bind="$ctrl.sums.lastYear | eawNumber"></div>
                <div class="entry-cell" ng-bind="$ctrl.sums.forecast | eawNumber"></div>
                <div class="entry-cell" ng-bind="$ctrl.sums.forecastDiff | percent"></div>
                <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries" ng-bind="$ctrl.sums.autoForecast | eawNumber"></div>
                <div class="entry-cell" ng-bind="$ctrl.sums.actual | eawNumber"></div>
                <div class="entry-cell" ng-bind="$ctrl.sums.actualDiff | percent"></div>
                <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries" ng-bind="$ctrl.sums.autoForecastDiff | percent"></div>
                <div class="entry-cell"></div>
            </div>
        </div>
    </md-card-content>
</md-card>
`,
    bindings: {
        user: '<',
        customer: '<',
        bucket: '<',
        constants: '<',
    },
    controller: AiBudgetingEasyProjections,
});
