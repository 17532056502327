import { Inject, Injectable } from '@angular/core';
import { Products } from '../../../shared/enums/products';
import { MatDialog } from '@angular/material/dialog';
import { DefaultTemplateCreateComponent } from './default-template-create/default-template-create.component';
import { SwitzerlandTemplateCreateComponent } from './switzerland-template-create/switzerland-template-create.component';
import { RepeatingTemplateCreateComponent } from './repeating-template-create/repeating-template-create.component';
import { DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { ScheduleTemplate } from '../../models/schedule-template';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { switchMap } from 'rxjs';

export interface CreateScheduleTemplateDialogData extends DialogData {
    customerId: number;
}

export type ScheduleTemplateCreateDialog = typeof DefaultTemplateCreateComponent
    | typeof SwitzerlandTemplateCreateComponent
    | typeof RepeatingTemplateCreateComponent

@Injectable({
    providedIn: 'any',
})
export class CreateTemplateDialogService {
    constructor(
        @Inject(CustomerProductService) private customerProductService: CustomerProductService,
        @Inject(MatDialog) private dialog: MatDialog,
    ) {
    }

    open(data: CreateScheduleTemplateDialogData) {
        let component: ScheduleTemplateCreateDialog = DefaultTemplateCreateComponent;

        return this.customerProductService.hasProductsDetailed(data.customerId, [ Products.Switzerland, Products.AutomaticScheduling, Products.RepeatingSchedule ])
            .pipe(switchMap((hasProductsResponse) => {
                if (hasProductsResponse.products[Products.Switzerland] && hasProductsResponse.products[Products.AutomaticScheduling]) {
                    component = SwitzerlandTemplateCreateComponent;
                }

                if (hasProductsResponse.products[Products.RepeatingSchedule]) {
                    component = RepeatingTemplateCreateComponent;
                }

                return this.dialog.open<InstanceType<ScheduleTemplateCreateDialog>, CreateScheduleTemplateDialogData, ScheduleTemplate>(component, {
                    data: {
                        size: DialogSize.Medium,
                        ...data,
                    },
                }).afterClosed();
            }));
    }
}
