import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { SwapApplicant, SwapApplicantResponse } from '../models/swap-applicant';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';

interface GetOptions extends PaginationOptions {
    only_dismissed?: boolean,
    include_dismissed?: boolean,
}

@Injectable({
    providedIn: 'root',
})
export class SwapApplicantService {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
    ) {
    }

    addApplicant(customerId: number, swapId: number, employeeId: number): Observable<SwapApplicant> {
        return this.http.post<SwapApplicantResponse>(`/customers/${customerId}/shift_swaps/${swapId}/applicants`, { applicant_id: employeeId }).pipe(classifyItem(SwapApplicant));
    }

    removeApplicant(customerId: number, swapId: number, employeeId: number): Observable<undefined> {
        return this.http.delete<undefined>(`/customers/${customerId}/shift_swaps/${swapId}/applicants/${employeeId}`);
    }

    getSwapApplicants(customerId: number, swapId: number, options: GetOptions): Observable<ArrayPaginatedResponse<SwapApplicant>> {
        return this.http.get<ArrayPaginatedResponse<SwapApplicantResponse>>(`/customers/${customerId}/shift_swaps/${swapId}/swap_applicants`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(SwapApplicant));
    }

    getEmployeeSwapApplicants(customerId: number, employeeId: number, options: GetOptions): Observable<ArrayPaginatedResponse<SwapApplicant>> {
        return this.http.get<ArrayPaginatedResponse<SwapApplicantResponse>>(`/customers/${customerId}/employees/${employeeId}/swap_applicants`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(SwapApplicant));
    }

    dismissSwapApplicant(customerId: number, swapId: number, applicantId: number, comment?: string): Observable<SwapApplicant> {
        return this.http.put<SwapApplicantResponse>(`/customers/${customerId}/shift_swaps/${swapId}/applicants/${applicantId}/dismiss`, { comment: comment ?? undefined }).pipe(classifyItem(SwapApplicant));
    }
}
