import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, signal } from '@angular/core';
import { OpeningHoursService } from '../../../../../../../company/http/opening-hours.service';
import { DateTime } from 'luxon';
import { ScheduleComponent } from '../../../../schedule.component';
import { OpeningHours } from '../../../../../../../company/models/opening-hours';
import { NgStyle } from '@angular/common';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { of, shareReplay, switchMap } from 'rxjs';

interface ClosedHours {
    from: DateTime;
    to: DateTime;
}

interface ClosedInterval {
    offset: string;
    length: string;
}

@Component({
    selector: 'eaw-schedule-tab-opening-hours',
    standalone: true,
    imports: [ NgStyle ],
    templateUrl: './schedule-tab-opening-hours.component.html',
    styleUrl: './schedule-tab-opening-hours.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleTabOpeningHoursComponent {
    private destroyRef = inject(DestroyRef);
    private openingHoursService = inject(OpeningHoursService);

    customerId = input.required<number>();
    pixelsPerSecond = input.required<number>();

    openingHours = signal<OpeningHours[]>([]);
    openingHoursObservable = computed(() => this.openingHoursService.getAll(this.customerId()).pipe(takeUntilDestroyed(this.destroyRef), shareReplay(1)));
    closedIntervals = computed<ClosedInterval[]>(this.computeClosedIntervals.bind(this));
    closedHours = computed(this.computeClosedHours.bind(this));

    constructor() {
        toObservable(ScheduleComponent.properties.scheduleTab.openingHours.value).pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap((toggled) => toggled ? this.openingHoursObservable() : of([])),
        ).subscribe((openingHours) => {
            this.openingHours.set(openingHours);
        });
    }

    private computeClosedIntervals() {
        const pps = this.pixelsPerSecond();
        const closedHours = this.closedHours();
        const schedule = ScheduleComponent.schedule();
        if (!schedule) {
            return [];
        }

        return closedHours.reduce((acc, closed) => {
            const closedFrom = closed.from.diff(schedule.from).as('seconds');
            const closedLength = closed.to.diff(closed.from).as('seconds');

            return (closedFrom + closedLength) <= 0 ? acc : acc.concat({
                offset: `${closedFrom * pps}px`,
                length: `${closedLength * pps}px`,
            });
        }, [] as ClosedInterval[]);
    }

    private computeClosedHours() {
        const schedule = ScheduleComponent.schedule();
        const openingHours = this.openingHours();
        if (!schedule || !openingHours.length) {
            return [];
        }

        const closedHours: ClosedHours[] = [];
        const { from, to } = schedule;
        const maxIterations = 1000;
        let date = from.startOf('day');
        let iteration = 0;

        const scheduleOpeningHours: ({ from: DateTime, to: DateTime })[] = [];
        while (date < to && iteration < maxIterations) {
            iteration++;

            const day = date.weekday;
            const open = openingHours.find((i) => i.day === day);
            if (open) {
                scheduleOpeningHours.push({
                    from: date.set({ hour: open.openFrom.hour, minute: open.openFrom.minute }),
                    to: date.set({ hour: open.openTo.hour, minute: open.openTo.minute }).plus({ days: open.openFrom.hasSame(open.openTo, 'day') ? 0 : 1 }),
                });
            }

            date = date.plus({ days: 1 });
        }

        scheduleOpeningHours.forEach((openingHour, index, array) => {
            const previous = array[index - 1];

            closedHours.push({
                from: previous ? previous.to : from,
                to: openingHour.from,
            });
        });

        return closedHours;
    }
}
