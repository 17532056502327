<eaw-dialog-header>
    <span title>{{'EDIT_LEAVE_SHIFT' | translate:'leaveshifts' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="leaveShift">
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-8">
        <mat-form-field>
            <mat-label>{{'BUSINESS_DATE' | translate | async}}</mat-label>
            <input matInput [matDatepicker]="$any(businessDatePicker)" [ngModelOptions]="{standalone: true}" [ngModel]="leaveShift.businessDate.dateTime" disabled>
            <mat-datepicker-toggle matSuffix [for]="businessDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #businessDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="tw-grid tw-grid-cols-2 tw-gap-8">
            <eaw-time-input [label]="'FROM' | translate" formControlName="from"></eaw-time-input>
            <eaw-time-input [label]="'TO' | translate" formControlName="to"></eaw-time-input>
        </div>

        <mat-checkbox class="tw-mb-16" eawCheckboxHelper formControlName="detailedBreak">{{'DETAILED_BREAK' | translate:'leaveshifts' | async}}</mat-checkbox>

        <div class="tw-grid tw-grid-cols-2 tw-gap-8" [class.tw-hidden]="!form.controls.detailedBreak.value">
            <eaw-time-input [label]="'BREAK_FROM' | translate:'leaveshifts'" formControlName="breakFrom"></eaw-time-input>
            <eaw-time-input [label]="'BREAK_TO' | translate:'leaveshifts'" formControlName="breakTo"></eaw-time-input>
        </div>

        <mat-form-field [class.tw-hidden]="form.controls.detailedBreak.value">
            <mat-label>{{'BREAK_LABEL' | translate:'leaveshifts' | async}}</mat-label>
            <input matInput formControlName="simpleBreak" type="number" min="0">
            <mat-hint *ngIf="(getBreakLength()?.as('minutes') || 0) > 0">{{'FROM_TO' | translate:'general':{from: form.controls.breakFrom.value | DateTime:'TIME_SIMPLE', to: form.controls.breakTo.value | DateTime:'TIME_SIMPLE'} | async}}</mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
    <button mat-raised-button color="primary" [disabled]="form.disabled || form.invalid || form.pristine" (click)="update()">{{'UPDATE' | translate | async}}</button>
</mat-dialog-actions>
