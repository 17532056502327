import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DataTable, DataTablePagination } from '../../../data-table/types/data-table';
import { ProductService } from '../../../shared/services/product.service';
import { Product } from '../../../shared/models/product';
import { DateTime } from 'luxon';

@Component({
    selector: 'eaw-admin-products-list',
    standalone: true,
    imports: [
        PageHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        DataTableComponent,
        MatCard,
        MatCardContent,
    ],
    templateUrl: './admin-products-list.component.html',
    styleUrl: './admin-products-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminProductsListComponent implements DataTable<Product> {
    private productService = inject(ProductService);

    columns = computed(this.computeColumns.bind(this));

    getData = computed(() => (pagination: Partial<DataTablePagination>) =>
        this.productService.getAll({ ...pagination, 'count[]': [ 'subscribers' ] }));

    computeColumns(): DataTableColumnType<Product>[] {
        return [
            new DataTableTextColumn({
                value: 'name',
                header: new DataTableHeader({
                    text: 'NAME',
                    sortBy: 'name',
                }),
            }),
            new DataTableTextColumn({
                value: 'subscriberType',
                header: new DataTableHeader({
                    i18n: 'SUBSCRIBER_plural',
                    ns: 'admin',
                    sortBy: 'subscribers_count',
                }),
            }),
            new DataTableDateTimeColumn({
                value: 'createdAt',
                format: DateTime.DATETIME_MED,
                header: new DataTableHeader({
                    i18n: 'CREATED_AT',
                    sortBy: 'created_at',
                }),
            }),
            new DataTableDateTimeColumn({
                value: 'updatedAt',
                format: DateTime.DATETIME_MED,
                header: new DataTableHeader({
                    i18n: 'UPDATED_AT',
                    sortBy: 'updated_at',
                }),
            }),
            new DataTableDateTimeColumn({
                value: 'deletedAt',
                format: DateTime.DATETIME_MED,
                header: new DataTableHeader({
                    i18n: 'DELETED_AT',
                    sortBy: 'deleted_at',
                }),
            }),
        ];
    }
}
