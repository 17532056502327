import { Component, Inject, Input, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
import { ControlContainer } from '@angular/forms';
import { ScheduleTemplateService } from '../../http/schedule-template.service';
import type { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import type { ScheduleTemplate } from '../../models/schedule-template';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AutocompleteDeprecatedComponent } from '../../../shared/components/autocomplete-deprecated/autocomplete-deprecated.component';

@Component({
    selector: 'eaw-schedule-template-autocomplete',
    templateUrl: './schedule-template-autocomplete.component.html',
    styleUrl: './schedule-template-autocomplete.component.scss',
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [ [ new SkipSelf(), ControlContainer ] ],
        },
    ],
    standalone: true,
    imports: [ AutocompleteDeprecatedComponent, TranslatePipe ],
})
export class ScheduleTemplateAutocompleteComponent {
    @Input() customerId!: number;
    @Input() controlName!: string;
    @Input() label?: Promise<string>;

    observable?: Observable<ArrayPaginatedResponse<ScheduleTemplate>>;
    limit = 10;

    constructor(
        @Inject(ScheduleTemplateService) protected templateService: ScheduleTemplateService,
        @Inject(ControlContainer) public controlContainer: ControlContainer,
    ) {}

    updateTemplates(filter?: string) {
        this.observable = this.templateService.getAll(this.customerId, {
            page: 1,
            filter,
            per_page: this.limit,
        });
    }
}
