import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { MatTab, MatTabChangeEvent, MatTabContent, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { ScheduleSidebarComponent } from './components/schedule-sidebar/schedule-sidebar.component';
import { MatCard } from '@angular/material/card';
import { Schedule } from '../../models/schedule';
import { Shift } from '../../models/shift';
import { Employee } from '../../../shared/models/employee';
import { ScheduleTabComponent } from './components/schedule-tab/schedule-tab.component';
import { ShiftDeletedEvent, ShiftSavedEvent, WarningsClearedEvent, WebsocketService } from '../../../shared/services/websocket.service';
import { ShiftService } from '../../http/shift.service';
import { CurrentService } from '../../../shared/services/current.service';
import { filter, forkJoin, map, Observable, Subject, switchMap, tap } from 'rxjs';
import { ScheduleTab, ScheduleTabsService } from '../../services/schedule-tabs.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { ScheduleService } from '../../http/schedule.service';
import { ScheduleShiftService } from '../../http/schedule-shift.service';
import { AsyncPipe } from '@angular/common';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { UserPropertyService } from '../../../shared/http/user-property.service';
import { MatIconModule } from '@angular/material/icon';
import { Customer } from '../../../shared/models/customer';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { Products } from '../../../shared/enums/products';
import { Warning, WarningResponse } from 'src/app/shared/models/warning';
import { Comment } from '../../../shared/models/comment';
import { ScheduleTabMenuGrouping, ScheduleTabMenuMode, ScheduleTabMenuSorting, ScheduleTabShiftsDisplay } from './components/schedule-tab/components/schedule-tab-menu/schedule-tab-menu.component';
import { Property } from '../../../shared/models/property';
import { PropertyValueDecoder } from '../../../shared/utils/property-value-decoder';
import { ScheduleEmployeesTabComponent } from './components/employees-tab/schedule-employees-tab.component';
import { DateTime } from 'luxon';
import { CustomerEmployeesGetAllOptions, EmployeeService } from 'src/app/shared/http/employee.service';

export type ScheduleShiftEvent = 'creating' | 'created' | 'updating' | 'updated' | 'deleting' | 'deleted' | 'warning_added' | 'warnings_cleared';
export type ScheduleShiftEventData<D> = { shiftId: number, type: ScheduleShiftEvent, data: D };

export interface ScheduleDay {
    date: string;
    weekday: string;
    dateTime: DateTime;
    dstStarts: boolean;
    dstEnds: boolean;
}

export interface ScheduleComponentProperty<T> {
    value: WritableSignal<T>,
    readonly key: string;
}

export interface ScheduleComponentProperties {
    schedule: {
        auditorsNotified: ScheduleComponentProperty<boolean | undefined>,
        budgetingInterval: ScheduleComponentProperty<number | undefined>,
    },
    customer: {
        scheduleAuditorGroupId: ScheduleComponentProperty<number | undefined>,
    },
    user: {
        defaultTab: ScheduleComponentProperty<ScheduleTab['name'] | undefined>,
    },
    scheduleTab: {
        mode: ScheduleComponentProperty<ScheduleTabMenuMode>,
        interval: ScheduleComponentProperty<number>,
        sorting: ScheduleComponentProperty<ScheduleTabMenuSorting>,
        shiftGrouping: ScheduleComponentProperty<ScheduleTabMenuGrouping>,
        shiftDisplayMode: ScheduleComponentProperty<ScheduleTabShiftsDisplay>,
        topStatsEnabled: ScheduleComponentProperty<boolean>,
        verticalLines: ScheduleComponentProperty<boolean>,
        expandedShifts: ScheduleComponentProperty<boolean>,
        openingHours: ScheduleComponentProperty<boolean>,
        showNicknames: ScheduleComponentProperty<boolean>,
    }
}

@Component({
    selector: 'eaw-schedule',
    standalone: true,
    imports: [
        MatTab,
        MatTabGroup,
        ScheduleSidebarComponent,
        MatCard,
        ScheduleTabComponent,
        AsyncPipe,
        InfoLoadingComponent,
        MatIconModule,
        MatTabLabel,
        MatTabContent,
        ScheduleEmployeesTabComponent,
    ],
    templateUrl: './schedule.component.html',
    styleUrl: './schedule.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleComponent implements OnInit, OnDestroy {
    private destroyRef = inject(DestroyRef);
    private shiftService = inject(ShiftService);
    private currentService = inject(CurrentService);
    private scheduleService = inject(ScheduleService);
    private employeeService = inject(EmployeeService);
    private websocketService = inject(WebsocketService);
    private queryParamsService = inject(QueryParamsService);
    private scheduleTabsService = inject(ScheduleTabsService);
    private userPropertyService = inject(UserPropertyService);
    private scheduleShiftService = inject(ScheduleShiftService);
    private customerProductService = inject(CustomerProductService);

    @Input({ required: true }) stackId!: number;
    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) scheduleId!: number;

    private static shiftEvents = new Subject<ScheduleShiftEventData<any>>();
    static schedule = signal<Schedule | undefined>(undefined);
    static customer = signal<Customer | undefined>(undefined);
    static shifts = signal<Map<number, Shift>>(new Map());
    static employees = signal<Map<number, Employee>>(new Map());
    static products = signal<Set<Products>>(new Set());
    static warnings = signal<Map<number, Map<number, Warning>>>(new Map());
    static properties: ScheduleComponentProperties = ScheduleComponent.getDefaultPropertyValues();

    protected loadingInitial = signal(true);
    protected tabs = signal<ScheduleTab[]>([]);
    protected initialTabIndex = computed(this.computeInitialTab.bind(this));

    static readonly shiftWiths = [ 'periods', 'warnings', 'comments', 'qualifications' ];
    private tabIndex = signal(0);
    private destroyController = new AbortController();

    ngOnInit() {
        this.listenToSocket();
        this.getIntialData();
    }

    ngOnDestroy() {
        this.destroyController.abort();

        ScheduleComponent.schedule.set(undefined);
        ScheduleComponent.employees.set(new Map());
        ScheduleComponent.shifts.set(new Map());
        ScheduleComponent.warnings.set(new Map());
        ScheduleComponent.shiftEvents.complete();

        ScheduleComponent.properties = ScheduleComponent.getDefaultPropertyValues();
    }

    private getSchedule() {
        return this.scheduleService.get(this.customerId, this.scheduleId, {
            'with[]': [ 'properties', 'customer.properties' ],
        }).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((schedule) => {
                ScheduleComponent.schedule.set(schedule);
                ScheduleComponent.customer.set(schedule.customer);

                // Schedule properties
                this.setProperty(schedule.properties, ScheduleComponent.properties.schedule.auditorsNotified, (value) => value.asBoolean());
                this.setProperty(schedule.properties, ScheduleComponent.properties.schedule.budgetingInterval, (value) => value.asInteger());

                // Customer properties
                this.setProperty(schedule.customer?.properties, ScheduleComponent.properties.customer.scheduleAuditorGroupId, (value) => value.asInteger());
            }),
        );
    }

    private getProducts() {
        return expandAllPages((pagination) => this.customerProductService.getAll(this.customerId, pagination), { per_page: 100 }).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((products) => ScheduleComponent.products.set(new Set(products.map((p) => p.name)))),
        );
    }

    private getUserProperties() {
        return expandAllPages((pagination) => this.userPropertyService.getAll(this.currentService.getUser().id, {
            ...pagination,
            filter: 'schedule',
        }), { per_page: 100 }).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((properties) => {
                this.setProperty(properties, ScheduleComponent.properties.user.defaultTab, (value) => value.asString());
                this.setProperty(properties, ScheduleComponent.properties.scheduleTab.interval, (value) => value.asInteger());
                this.setProperty(properties, ScheduleComponent.properties.scheduleTab.sorting, (value) => value.asString());
                this.setProperty(properties, ScheduleComponent.properties.scheduleTab.shiftGrouping, (value) => value.asString());
                this.setProperty(properties, ScheduleComponent.properties.scheduleTab.topStatsEnabled, (value) => value.asBoolean());
                this.setProperty(properties, ScheduleComponent.properties.scheduleTab.verticalLines, (value) => value.asBoolean());
                this.setProperty(properties, ScheduleComponent.properties.scheduleTab.expandedShifts, (value) => value.asBoolean());
                this.setProperty(properties, ScheduleComponent.properties.scheduleTab.openingHours, (value) => value.asBoolean());
                this.setProperty(properties, ScheduleComponent.properties.scheduleTab.showNicknames, (value) => value.asBoolean());
            }),
        );
    }

    private setProperty<T>(properties: Property[] | undefined, property: ScheduleComponentProperty<T>, decodeCallback: (decoder: PropertyValueDecoder) => T) {
        property.value.update((defaultValue) => {
            const value = (properties || []).find((p) => p.key === property.key)?.value;
            return value == null ? defaultValue : (decodeCallback(value) ?? defaultValue);
        });
    }

    private getTabs(schedule: Schedule) {
        return this.scheduleTabsService.getScheduleTabs(this.stackId, this.customerId, this.scheduleId, schedule.isTemplate).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((tabs) => this.tabs.set(tabs)),
        );
    }

    private getEmployees() {
        return expandAllPages((pagination) => this.employeeService.getAll(this.customerId, pagination), {
            per_page: 250,
            include_external: true,
            include_inactive: true,
            include_future: true,
            include_custom_fields: false,
        } satisfies CustomerEmployeesGetAllOptions).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((employees) => {
                const employeeMap = new Map<number, Employee>();
                employees.forEach((employee) => employeeMap.set(employee.id, employee));
                ScheduleComponent.employees.set(employeeMap);
            }),
        );
    }

    private getShifts() {
        return expandAllPages((pagination) => this.scheduleShiftService.getAll(this.customerId, this.scheduleId, pagination), {
            per_page: 250,
            'with[]': ScheduleComponent.shiftWiths,
        }).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((shifts) => {
                shifts.forEach((shift) => {
                    ScheduleComponent.setShift(shift);

                    shift.warnings.forEach((w) => ScheduleComponent.setWarning(w));
                });
            }),
        );
    }

    static getDays(schedule?: Schedule) {
        if (!schedule) {
            return [];
        }

        const from = schedule.from;
        const to = schedule.to.minus({ second: 1 });

        if (!from || !to) {
            return [];
        }

        const days: ScheduleDay[] = [];
        for (let i = 0; i <= Math.ceil(to.diff(from, 'days').days); i++) {
            const day = from.plus({ days: i });

            days.push({
                date: day.toLocaleString(DateTime.DATE_MED),
                weekday: day.weekdayLong || '',
                dateTime: day,
                dstStarts: !day.isInDST && day.plus({ days: 1 }).isInDST,
                dstEnds: day.isInDST && !day.plus({ days: 1 }).isInDST,
            });
        }

        return days;
    }

    private getIntialData() {
        this.getSchedule().pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap((schedule) => {
                return forkJoin([
                    this.getTabs(schedule),
                    this.getEmployees(),
                    this.getShifts(),
                    this.getUserProperties(),
                    this.getProducts(),
                ]);
            }),
            tap(() => {
                this.loadingInitial.set(false);
            }),
        ).subscribe();
    }

    private computeInitialTab() {
        let index = -1;
        const tabs = this.tabs();
        const defaultTab = ScheduleComponent.properties.user.defaultTab.value();
        const queryTab = this.queryParamsService.get('tab', 'string');

        // Try to find the query tab if that's set
        if (queryTab) {
            index = tabs.findIndex((tab) => tab.name === queryTab);
        }

        // If index is still not found then try the default tab
        if (index === -1) {
            index = tabs.findIndex((tab) => tab.name === defaultTab);
        }

        // Use the first tab if nothing else is found
        return Math.max(0, index);
    }

    protected tabChange(tab: MatTabChangeEvent) {
        this.tabIndex.set(tab.index);

        const tabName = this.tabs()[tab.index]?.name;
        if (tabName) {
            this.queryParamsService.set([
                { key: 'tab', value: tabName },
            ]);
        };
    }

    private listenToSocket() {
        this.websocketService.listenSchedule(this.customerId, this.scheduleId, 'shift_saved', this.onShiftSavedEvent.bind(this), this.destroyRef);
        this.websocketService.listenSchedule(this.customerId, this.scheduleId, 'shift_deleted', this.onShiftDeletedEvent.bind(this), this.destroyRef);
        this.websocketService.listenSchedule(this.customerId, this.scheduleId, 'warning_added', ScheduleComponent.setWarning, this.destroyRef);
        this.websocketService.listenSchedule(this.customerId, this.scheduleId, 'warnings_cleared', this.onWarningsClearedEvent.bind(this), this.destroyRef);
    }

    private onWarningsClearedEvent(event: WarningsClearedEvent) {

        if (event.observer_id) {
            const shiftWarnings = ScheduleComponent.warnings().get(event.id);
            const warningId = Array.from(shiftWarnings?.values() || []).find((w) => w.observerId === event.observer_id)?.id;

            if (shiftWarnings && warningId) {
                shiftWarnings.delete(warningId);
            }
        } else {
            ScheduleComponent.warnings().get(event.id)?.forEach((w) => ScheduleComponent.deleteWarning(event.id, w.id));
        }
    }

    private onShiftDeletedEvent(event: ShiftDeletedEvent) {
        ScheduleComponent.deleteShift(event.shift_id);
    }

    private onShiftSavedEvent(event: ShiftSavedEvent) {
        const isMe = event.event_triggered_by === this.currentService.getUser().id;
        if (isMe && document.hasFocus()) {
            return;
        }

        this.shiftService.get(this.customerId, event.id, {
            with: ScheduleComponent.shiftWiths,
        }).subscribe((shift) => {
            ScheduleComponent.setShift(shift);
            ScheduleComponent.broadcastEvent(event.id, event.event, shift);
        });
    }

    static setWarning(warning: Warning | WarningResponse) {
        ScheduleComponent.warnings.update((warnings) => {
            const warningClass = warning instanceof Warning ? warning : new Warning(warning);
            const shiftWarnings = warnings.get(warningClass.objectId) || new Map<number, Warning>();
            shiftWarnings.set(warning.id, warningClass);
            warnings.set(warningClass.objectId, shiftWarnings);
            return new Map(warnings);
        });
    }

    static deleteWarning(shiftId: number, warningId: number) {
        ScheduleComponent.warnings.update((warnings) => {
            warnings.get(shiftId)?.delete(warningId);
            return new Map(warnings);
        });
    }

    static setShift(shift: Shift) {
        shift.warnings.forEach((warning) => {
            ScheduleComponent.warnings.update((warnings) => {
                const shiftWarnings = warnings.get(shift.id) || new Map<number, Warning>();
                shiftWarnings.set(warning.id, warning);
                warnings.set(shift.id, shiftWarnings);
                return new Map(warnings);
            });
        });

        ScheduleComponent.shifts.update((shifts) => {
            shifts.set(shift.id, shift);
            return new Map(shifts);
        });
    }

    static deleteShift(shiftId: number) {
        ScheduleComponent.shifts.update((shifts) => {
            shifts.delete(shiftId);
            return new Map(shifts);
        });
    }

    static addComment(shiftId: number, comment: Comment) {
        const shift = ScheduleComponent.shifts().get(shiftId);
        if (!shift) {
            return;
        }

        shift.comments = shift.comments?.concat(comment) || [ comment ];
        shift.commentsCount = shift.comments?.length;
        ScheduleComponent.setShift(shift);
    }

    static deleteComment(shiftId: number, commentId: number) {
        const shift = ScheduleComponent.shifts().get(shiftId);
        if (!shift) {
            return;
        }

        shift.comments = shift.comments?.filter((c) => c.id !== commentId);
        shift.commentsCount = shift.comments?.length;
        ScheduleComponent.setShift(shift);
    }

    static broadcastEvent(shiftId: number, type: 'warning_added', data: Warning): void
    static broadcastEvent(shiftId: number, type: 'created' | 'updated', data: Shift): void
    static broadcastEvent(shiftId: number, type: 'deleted' | 'warnings_cleared'): void
    static broadcastEvent(shiftId: number, type: 'creating' | 'updating' | 'deleting', data: boolean): void
    static broadcastEvent(shiftId: number, type: ScheduleShiftEvent, data?: any) {
        this.shiftEvents.next({ shiftId, type, data });
    }

    /**
     * Listen for an event for a specific shift
     */
    static listenForShiftEvent(shiftId: number, type: 'warning_added', destroyRef: DestroyRef): Observable<Warning>
    static listenForShiftEvent(shiftId: number, type: 'created' | 'updated', destroyRef: DestroyRef): Observable<Shift>
    static listenForShiftEvent(shiftId: number, type: 'deleted' | 'warnings_cleared', destroyRef: DestroyRef): Observable<void>
    static listenForShiftEvent(shiftId: number, type: 'creating' | 'updating' | 'deleting', destroyRef: DestroyRef): Observable<boolean>
    static listenForShiftEvent(shiftId: number, type: ScheduleShiftEvent, destroyRef: DestroyRef) {
        return this.shiftEvents.asObservable().pipe(
            takeUntilDestroyed(destroyRef),
            filter((event) => event.type === type && event.shiftId === shiftId),
            map((e) => e.data),
        );
    }

    /**
     * Listen for an event for any shift
     */
    static listenForEvent() {
        return this.shiftEvents.asObservable();
    }

    private static getDefaultPropertyValues() {
        return {
            schedule: {
                auditorsNotified: {
                    value: signal(undefined),
                    key: 'auditors_notified',
                },
                budgetingInterval: {
                    value: signal(undefined),
                    key: 'budgeting_interval',
                },
            },
            customer: {
                scheduleAuditorGroupId: {
                    value: signal(undefined),
                    key: 'schedule_auditor_group_id',
                },
            },
            user: {
                defaultTab: {
                    value: signal('schedule'),
                    key: 'schedule:setting:default_tab',
                },
            },
            scheduleTab: {
                mode: {
                    value: signal('edit_shifts'),
                    key: 'schedule:scheduleTab:mode',
                },
                sorting: {
                    value: signal('shift_offset_asc'),
                    key: 'schedule:scheduleTab:sorting',
                },
                shiftDisplayMode: {
                    value: signal('all'),
                    key: 'schedule:scheduleTab:scheduleDisplay',
                },
                interval: {
                    value: signal(3600),
                    key: 'schedule:scheduleTab:interval',
                },
                shiftGrouping: {
                    value: signal('none'),
                    key: 'schedule:scheduleTab:shiftGrouping',
                },
                topStatsEnabled: {
                    value: signal(false),
                    key: 'schedule:scheduleTab:topStatsEnabled',
                },
                verticalLines: {
                    value: signal(true),
                    key: 'schedule:scheduleTab:verticalLines',
                },
                expandedShifts: {
                    value: signal(false),
                    key: 'schedule:scheduleTab:expandedShifts',
                },
                openingHours: {
                    value: signal(true),
                    key: 'schedule:scheduleTab:openingHours',
                },
                showNicknames: {
                    value: signal(false),
                    key: 'schedule:scheduleTab:showNicknames',
                },
            },
        } satisfies ScheduleComponentProperties;
    }
}
