// @ts-nocheck
import { t } from 'i18next';
import { keyBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
class ChainScheduleOverviewController {
    chainScheduleOverviewFactory;
    $state;
    changeCustomerService;
    ScheduleService;
    $mdDialog;
    ToastService;
    static get $inject() {
        return [ 'chainScheduleOverviewFactory', '$state', 'changeCustomerService', 'ScheduleService', '$mdDialog', 'ToastService' ];
    }

    intervals = [ {
        value: 'month',
        text: t('MONTH'),
    }, {
        value: 'custom',
        text: t('CUSTOM'),
    } ];

    interval = this.intervals[0].value;
    customerGroupId;
    selectedMonth = moment().startOf('M');
    selectedDateInterval = {
        from: moment().startOf('w'),
        to: moment().endOf('w'),
    };

    from;
    to;
    customerGroup;
    customer;
    tabs;
    customers;
    resp;
    form;
    loading = true;
    constructor(chainScheduleOverviewFactory, $state, changeCustomerService, ScheduleService, $mdDialog, ToastService) {
        this.chainScheduleOverviewFactory = chainScheduleOverviewFactory;
        this.$state = $state;
        this.changeCustomerService = changeCustomerService;
        this.ScheduleService = ScheduleService;
        this.$mdDialog = $mdDialog;
        this.ToastService = ToastService;
        this.from = this.getModeFrom(this.interval);
        this.to = this.getModeTo(this.interval);
    }

    $onInit() {
        this.customerGroupId = typeof this.customerGroupId === 'number' ? this.customerGroupId : parseInt(this.customerGroupId);
        this.customerGroup = this.tabs.customerGroups.find((g) => g.id === this.customerGroupId);
        this.customers = keyBy(this.customerGroup.members, 'id');
    }

    getModeFrom(mode) {
        if (mode === 'month') {
            return this.selectedMonth.clone().startOf('M');
        }
        if (mode === 'custom') {
            return this.selectedDateInterval.from.clone().startOf('w');
        }
    }

    getModeTo(mode) {
        if (mode === 'month') {
            return this.selectedMonth.clone().endOf('M');
        }
        if (mode === 'custom') {
            return this.selectedDateInterval.to.clone().endOf('w');
        }
    }

    update(pagination) {
        this.loading = true;
        const pag = {
            page: 1,
            per_page: 10,
            ...pagination,
        };
        return this.chainScheduleOverviewFactory.getOverview(this.customerGroupId, this.interval === 'month' ? 'month' : 'week', this.getModeFrom(this.interval), this.getModeTo(this.interval), pag.page, pag.per_page).$promise.then((resp) => {
            this.resp = resp;
            Object.entries(this.resp.data).forEach(([ year, yearData ]) => {
                // Index = month or week
                Object.entries(yearData).forEach(([ index, intervalData ]) => {
                    // Don't add/remove interval data properties before we finish going over it's entries
                    let schedArr = [];
                    Object.entries(intervalData).forEach(([ customerId, schedules ]) => {
                        schedules = schedules.length ? schedules : [ { customer_id: customerId } ];
                        schedArr = schedArr.concat(schedules);
                        this.formatSchedules(schedArr);
                    });
                    // Format interval data
                    intervalData.schedules = schedArr;
                    intervalData.header = this.setHeader(year, index);
                });
            });
            return resp; // Pagination component needs this
        }).finally(() => {
            this.loading = false;
            this.form.unsubmit();
        });
    }

    formatSchedules(schedules) {
        schedules.forEach((s) => {
            s.customer = this.customers[s.customer_id];
            s.status = this.setStatus(s);
            s.divergence = this.setDivergence(s);
            s.limits = s.limits?.map((limit) => {
                if (!limit?.id) {
                    return;
                }
                limit.divergence = (limit.used / 3600) - (limit.limit / 3600);
                limit.class = limit.divergence < 0 ? 'success' : 'danger';
                return limit;
            }).filter((l) => l != null);
        });
    }

    goToSchedule(schedule) {
        if (schedule.customer_id === this.customer.id) {
            return this.$state.go(`eaw/app/scheduling/schedules/list/view`, {
                customer: schedule.customer_id,
                id: schedule.id,
            });
        }
        return this.changeCustomerService.go(schedule.customer_id, 'eaw/app/scheduling/schedules/list/view', {
            customer: schedule.customer_id,
            id: schedule.id,
        }, true);
    }

    setDivergence(schedule) {
        const difference = schedule.punched - schedule.planned;
        return {
            value: difference / 3600,
            class: difference > 0 ? 'danger' : '',
        };
    }

    setStatus(schedule) {
        let text = t('MISSING');
        let statusClass = 'missing';
        if (schedule.id) {
            if (schedule.published) {
                text = t('chainops:PUBLISHED');
                statusClass = 'published';
            } else if (schedule.auditing) {
                text = t('chainops:AUDITING');
                statusClass = 'auditing';
            } else {
                text = t('chainops:UNPUBLISHED');
                statusClass = 'unpublished';
            }
        }
        return {
            text,
            class: statusClass,
        };
    }

    publish(schedule) {
        this.$mdDialog.show(this.$mdDialog.confirm()
            .ok(t('OK'))
            .cancel(t('CANCEL'))
            .title(t('scheduling:PUBLISH'))
            .textContent((t('scheduling:PUBLISH_QUESTION')))).then(() => {
            this.ScheduleService.publishSchedule(schedule.customer.id, schedule.id).$promise.then(() => {
                schedule.published = true;
                this.ToastService.tToast('scheduling:PUBLISHING_SCHEDULE');
            });
        });
    }

    setHeader(year, index) {
        if (this.interval === 'month') {
            return `${moment().month(index - 1).format('MMMM')} ${year}`;
        }
        return `${t('WEEK_N', { number: moment().week(index).format('WW') })} - ${year}`;
    }
}
module('eaw.chain-ops').component('chainScheduleOverview', {
    template: `<md-card>
    <md-card-content class="tw-p-0">
        <form class="tw-p-16" layout="row" layout-wrap ng-submit="$overview.update()">
            <md-input-container flex="100" flex-gt-sm="25">
                <label ng-i18next="INTERVAL"></label>
                <md-select ng-model="$overview.interval">
                    <md-option ng-repeat="interval in $overview.intervals track by $index" ng-value="interval.value">{{interval.text}}</md-option>
                </md-select>
            </md-input-container>

            <eaw-date-interval flex="100" flex-gt-sm="50" layout="row" input-flex="50" ng-model="$overview.selectedDateInterval" ng-show="$overview.interval == 'custom'"></eaw-date-interval>

            <md-input-container flex="100" flex-gt-sm="25" ng-show="$overview.interval == 'month'">
                <eaw-date-picker label="MONTH" current-view="year" mode="month" ng-model="$overview.selectedMonth"></eaw-date-picker>
            </md-input-container>

            <div>
                <eaw-submit-btn text="'UPDATE'" form="$overview.form"></eaw-submit-btn>
            </div>
        </form>

        <!-- While loading -->
        <md-progress-circular ng-if="$overview.loading" class="md-progress-center tw-mt-24 tw-mb-24"></md-progress-circular>

        <!-- Use HIDE not IF -->
        <div ng-hide="$overview.loading || $overview.resp.total <= 10">
            <eaw-pagination per-page="10" load-page="$overview.update(pagination)"></eaw-pagination>
        </div>
        <md-divider></md-divider>

        <div ng-repeat="(year, data) in $overview.resp.data track by year">
            <div ng-repeat="(index, interval) in data track by index">
                <md-card-title>
                    <md-card-title-text>
                        <span class="md-title text-capitalize" ng-bind="interval.header"></span>
                    </md-card-title-text>
                </md-card-title>
                <md-divider></md-divider>
                <div class="tw-p-0 overflow-y-auto overflow-x-auto">
                    <table class="table table-bordered table-striped sticky-left">
                        <thead>
                        <tr>
                            <th rowspan="2" ng-i18next="LOCATION"></th>
                            <th colspan="3" class="text-center" ng-i18next="SCHEDULE"></th>
                            <th colspan="4" class="text-center" ng-i18next="HOUR_plural"></th>
                            <th colspan="3" class="text-center" ng-i18next="chainops:DIVERGENCE"></th>
                            <th colspan="2" class="text-center" ng-i18next="chainops:ABSENCE"></th>
                        </tr>
                        <tr>
                            <!-- Location uses rowspan-->
                            <!-- Schedule -->
                            <th class="text-left" ng-i18next="NAME"></th>
                            <th ng-i18next="STATUS"></th>

                            <!-- Publish -->
                            <th class="text-center" ng-i18next="scheduling:PUBLISH"></th>

                            <!-- Hours -->
                            <th class="text-right" ng-i18next="chainops:PAID_HOURS"></th>
                            <th class="text-right" ng-i18next="chainops:PUNCHED_HOURS"></th>
                            <th class="text-right" ng-i18next="chainops:PLANNED_HOURS"></th>
                            <th class="text-right" ng-i18next="chainops:LOCKED"></th>

                            <!-- Divergence -->
                            <th class="text-center" ng-i18next="chainops:LIMIT"></th>
                            <th class="text-center" ng-i18next="chainops:LIMIT_DIVERGENCE"></th>
                            <th class="text-right" ng-i18next="chainops:PLAN_DIVERGENCE"></th>

                            <!-- Absence -->
                            <th class="text-right" ng-i18next="TOTAL"></th>
                            <th class="text-center" ng-i18next="APPROVED"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr ng-repeat-start="schedule in interval.schedules track by $index" ng-if="!schedule.id">
                            <td class="customer-name" ng-bind="schedule.customer.name"></td>
                            <td></td>
                            <td ng-class="schedule.status.class" ng-bind="schedule.status.text"></td>
                            <td colspan="99"></td>
                        </tr>
                        <tr ng-repeat-end ng-if="schedule.id">
                            <!-- Location -->
                            <td class="customer-name" ng-bind="schedule.customer.name"></td>
                            <!-- Schedule name -->
                            <td>
                                <div layout="row" layout-align="space-between center">
                                    <span class="tw-mr-12" ng-bind="schedule.name"></span>

                                    <md-button class="eaw-icon-button md-raised" ng-click="$overview.goToSchedule(schedule)">
                                        <md-icon ng-bind="'open_in_browser'"></md-icon>
                                    </md-button>
                                </div>
                            </td>
                            <!-- Status -->
                            <td ng-class="schedule.status.class" ng-bind="schedule.status.text"></td>
                            <!-- Publish -->
                            <td class="text-center">
                                <md-button class="eaw-icon-button md-raised tw-m-0" ng-if="!schedule.published" ng-click="$overview.publish(schedule)">
                                    <md-icon ng-bind="'send'"></md-icon>
                                    <md-tooltip ng-i18next="scheduling:PUBLISH_SCHEDULE"></md-tooltip>
                                </md-button>

                                <div class="text-ws-nowrap" ng-if="schedule.published" ng-bind="schedule.published_at | moment:'ll'"></div>
                                <small ng-if="schedule.published" ng-bind="schedule.published_at | moment:'LT'"></small>
                            </td>
                            <!-- Paid (time) hours-->
                            <td class="text-right" ng-bind="schedule.paid / 3600 | eawNumber:2"></td>
                            <!-- Punched hours-->
                            <td class="text-right" ng-bind="schedule.punched / 3600 | eawNumber:2"></td>
                            <!-- Planned hours -->
                            <td class="text-right" ng-bind="schedule.planned / 3600 | eawNumber:2"></td>
                            <!-- Lock -->
                            <td class="text-center">
                                <span ng-if="schedule.lock.to" ng-bind="schedule.lock.to | moment:'LL'"></span>
                            </td>
                            <!-- Limit -->
                            <td class="text-center">
                        <span ng-repeat="limit in schedule.limits">
                            {{limit.used / 3600 | eawNumber:2}} / {{limit.limit / 3600 | eawNumber}}
                        </span>
                            </td>
                            <!-- Limit divergence -->
                            <td class="limit-divergence text-center">
                                <span ng-repeat="limit in schedule.limits" ng-class="limit.class" ng-bind="limit.divergence | eawNumber:2:true"></span>
                            </td>
                            <!-- Plan divergence -->
                            <td class="text-right" ng-class="schedule.divergence.class" ng-bind="schedule.divergence.value | eawNumber:2:true"></td>
                            <!-- Total absences -->
                            <td class="text-right" ng-bind="schedule.absences_total | eawNumber"></td>
                            <!-- Approved absences/Total absences -->
                            <td class="text-center">
                                <span ng-bind="schedule.absences_approved | eawNumber"></span>
                                /
                                <span ng-bind="schedule.absences_total | eawNumber"></span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
        customerGroupId: '<',
    },
    require: {
        tabs: '^^chainTabs',
    },
    controllerAs: '$overview',
    controller: ChainScheduleOverviewController,
});
