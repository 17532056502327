import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { EawFormElement, EawFormElementGroup, EawFormNode } from '../../types/form';
import { FormSubmission } from '../../models/form-submission';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'eaw-show-form-node',
    standalone: true,
    imports: [
        MatFormField,
        MatInput,
        MatLabel,
    ],
    templateUrl: './show-form-node.component.html',
    styleUrl: './show-form-node.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowFormNodeComponent {
    /** The form node to show. */
    node = input.required<EawFormNode>();

    /** Form submission to get values from */
    submission = input.required<FormSubmission>();

    /** The form element if the node is an element, null otherwise. */
    formElement: Signal<EawFormElement | null>;

    /** The children of the node if it is an element group, empty array otherwise. */
    children: Signal<EawFormNode[]>;

    /** The value of the node if it is an element, null otherwise. */
    value: Signal<string | null>;

    constructor() {
        this.value = computed(() => {
            const node = this.node();
            if (node instanceof EawFormElement) {
                return this.getValue(node.name);
            }

            return null;
        });

        this.formElement = computed(() => {
            if (this.node() instanceof EawFormElement) {
                return this.node() as EawFormElement;
            }

            return null;
        });

        this.children = computed(() => {
            if (this.node() instanceof EawFormElementGroup) {
                return (this.node() as EawFormElementGroup)
                    .children
                    .sort((a, b) => a.weight - b.weight);
            }

            return [];
        });
    }

    getValue(name: string) {
        return this.submission().data?.[name] || '';
    }
}
