import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { DateTime } from 'luxon';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { CustomFieldType } from '../typings/custom-field-type';
import { Namespace } from '../../shared/enums/namespace';
import { hasLoadedNamespace, loadNamespaces, t } from 'i18next';

export interface CustomFieldResponse extends ApiResponse {
    id: number;
    key: string;
    name: string;
    type: CustomFieldType;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

/**
 * Custom field as we get it from the API without it being attached to a model
 *
 * See {@link ModelCustomField} if you are interested in custom fields that are attached to a model
 */
export class CustomField {
    static readonly translationNs = Namespace.CustomFields;
    public static readonly Types = [ 'string', 'integer', 'decimal', 'boolean', 'date', 'select' ];
    translationKey: string;
    id: number;
    key: string;
    name: string;
    type: CustomFieldType;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;

    constructor(data: CustomFieldResponse) {
        this.id = data.id;
        this.key = data.key;
        this.name = data.name;
        this.translationKey = data.name;
        this.type = data.type;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }

    async translate() {
        if (!hasLoadedNamespace(CustomField.translationNs)) {
            await loadNamespaces(CustomField.translationNs);
        }

        return t(`${CustomField.translationNs}:${this.translationKey}`);
    }
}
