import { t } from 'i18next';
import { module } from 'angular';
import { from, Observable, of, tap } from 'rxjs';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent, CommentDialogData } from '../../../../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { Comment } from '../../../../../../shared/models/comment';

module('eaw.scheduling').component('scheduleSidebarComments', {
    template: `<md-progress-circular class="md-progress-center tw-mt-20" ng-if="$ctrl.loading"></md-progress-circular>

<div id="sidebar-comments-content" ng-if="!$ctrl.loading">
    <md-button class="md-raised md-primary" ng-click="$ctrl.writeComment()" ng-if="$ctrl.canCreate" ng-i18next="ADD_COMMENT"></md-button>

    <md-list class="md-dense">
        <md-list-item class="md-3-line sidebar-comment-item" ng-repeat="comment in $ctrl.comments track by comment.id" md-colors="{'background': comment.background}">
            <eaw-profile-picture-old user="comment.user" size="35"></eaw-profile-picture-old>

            <div class="md-list-item-text" layout="column">
                <h3 ng-if="comment.user.id == $ctrl.currentUser.id" ng-i18next="ME"></h3>
                <h3 ng-if="comment.user.id != $ctrl.currentUser.id" ng-bind="comment.user.name"></h3>
                <h4 ng-bind="comment.created_at | momentRelative"></h4>
                <p class="comment-body" ng-bind="comment.body"></p>
            </div>

            <md-button class="md-secondary md-icon-button md-warn" ng-click="$ctrl.deleteComment(comment)" ng-disabled="comment.deleting" ng-if="comment.canDelete">
                <md-icon ng-bind="'delete'"></md-icon>
            </md-button>

            <md-divider ng-if="!$last"></md-divider>
        </md-list-item>
    </md-list>
</div>
`,
    require: {
        sidebar: '^scheduleSidebar',
    },
    controller: [ 'ScheduleCommentsFactory', 'MatDialogDowngrade', 'ToastService', '$mdDialog', function(ScheduleCommentsFactory, MatDialogDowngrade: MatDialog, ToastService, $mdDialog) {
        // @ts-ignore
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.currentUser = CurrentOld.getUser();
            ctrl.customerId = ctrl.sidebar.schedule.customer_id;
            ctrl.scheduleId = ctrl.sidebar.schedule.id;
            ctrl.canCreate = ScheduleCommentsFactory.create.permission(ctrl.customerId, ctrl.sidebar.schedule.id);
            ctrl.loading = true;
            ScheduleCommentsFactory.getAll.query(ctrl.customerId, ctrl.scheduleId, [ 'user' ]).then((resp: any) => {
                ctrl.comments = resp.data;
                ctrl.comments.forEach((c: any) => ctrl.canDelete(c));
            }).finally(() => ctrl.loading = false);
        };
        // Adds a property to a comment that indicates whether or not comment can be deleted by current user
        ctrl.canDelete = (comment: any) => {
            comment.canDelete = ctrl.currentUser.id === comment.user_id && ScheduleCommentsFactory.delete.permission(ctrl.customerId, ctrl.scheduleId, comment.id);
        };
        ctrl.writeComment = () => {
            MatDialogDowngrade.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
                data: {
                    comments: of([]),
                    saveCallback: (comment) => {
                        return (from(ScheduleCommentsFactory.create.query(ctrl.customerId, ctrl.scheduleId, comment)) as Observable<Comment>).pipe(tap((resp) => {
                            ctrl.canDelete(resp);
                            ctrl.comments.push(resp);
                            ToastService.tToast('scheduling:COMMENT_ADDED');
                        }));
                    }
                },
            });
        };
        ctrl.deleteComment = (comment: any) => {
            $mdDialog.show($mdDialog.confirm()
                .theme('delete')
                .title(t('DELETE_COMMENT'))
                .textContent(t('scheduling:DELETE_SCHEDULE_COMMENT'))
                .ok(t('DELETE'))
                .cancel(t('CANCEL'))).then(() => {
                comment.deleting = true;
                comment.background = 'red-50';
                ScheduleCommentsFactory.delete.query(ctrl.customerId, ctrl.scheduleId, comment.id).then(() => {
                    ctrl.comments = ctrl.comments.filter((c: any) => c.id !== comment.id);
                    ToastService.tToast('COMMENT_DELETED');
                }).catch(() => {
                    // Reset changed properties if deleting fails
                    comment.deleting = false;
                    delete comment.background;
                });
            });
        };
    } ],
});
