import { Component, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlusHoursMonitoringService } from '../../http/plus-hours-monitoring.service';
import { DateTime } from 'luxon';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { PlusHoursMonitoring, PlusHoursMonitoringResponse } from '../../models/plus-hours-monitoring';
import { Namespace } from '../../../shared/enums/namespace';
import { TranslateService } from '../../../shared/services/translate.service';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { DateIntervalSelectorComponent } from '../../../shared/components/date-interval-selector/date-interval-selector.component';
import { catchError, EMPTY } from 'rxjs';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MatSortModule, Sort } from '@angular/material/sort';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../shared/services/current.service';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

type ColumnDef = keyof PlusHoursMonitoringResponse & keyof PlusHoursMonitoring['months'][0];

interface Column {
    columnDef: ColumnDef & string;
    itemKey: ColumnDef;
    label: Promise<string>;
    classes: string[];
    sticky?: boolean;
    sortArrow?: 'before' | 'after';
}

interface Category {
    columnDef: string;
    label: Promise<string> | undefined;
    month?: number;
    columns: Column[];
}

@Component({
    selector: 'eaw-plus-hours-monitoring',
    standalone: true,
    imports: [
        CommonModule,
        PageHeaderComponent,
        TranslatePipe,
        MatCardModule,
        MatIconModule,
        MatIconSizeDirective,
        MatProgressSpinnerModule,
        MatTableModule,
        MaterialColorDirective,
        NumberPipe,
        DateIntervalSelectorComponent,
        DateTimePipe,
        MatSortModule,
        MatInputModule,
        ReactiveFormsModule,
    ],
    templateUrl: './plus-hours-monitoring.component.html',
    styleUrl: './plus-hours-monitoring.component.scss',
})
export class PlusHoursMonitoringComponent implements OnInit {
    @Input({ required: true }) customerId!: number;

    date?: DateTime;
    gettingData = true;
    hasError = false;
    dataSource: PlusHoursMonitoring[] = [];
    filteredDataSource: PlusHoursMonitoring[] = [];
    categories: Category[] = [];
    categoriesDef: string[] = [];
    columnsDef: string[] = [];
    from = DateTime.now();
    to = DateTime.now();
    private currentSort: Sort = { active: 'badgeNumber', direction: 'asc' };

    employeeFilter = new FormControl('', { nonNullable: true });

    constructor(
        @Inject(PlusHoursMonitoringService) private plusHoursMonitoringService: PlusHoursMonitoringService,
        @Inject(TranslateService) private translateService: TranslateService,
        @Inject(CurrentService) private current: CurrentService,
    ) {
    }

    ngOnInit() {
        this.employeeFilter.valueChanges.subscribe(this.filter.bind(this));
        this.getData(DateTime.now());
    }

    intervalChange(event: { from: DateTime, to: DateTime }) {
        this.getData(event.from);
    }

    filter(text: string) {
        this.filteredDataSource = this.dataSource.filter((item) => {
            return `${item.firstName} ${item.lastName}`.toLowerCase().includes(text.toLowerCase());
        });
        this.sort();
    }

    sort(event: Sort = this.currentSort) {
        const def = event.active as keyof PlusHoursMonitoring;
        let result: PlusHoursMonitoring[] = [];
        this.currentSort = event;
        if (def === 'badgeNumber' || def === 'totalPaidTime') {
            result = this.filteredDataSource.sort((a, b) => {
                if (event.direction === 'asc') {
                    return a[def] - b[def];
                }
                return b[def] - a[def];
            });
        } else if (def === 'firstName' || def === 'lastName') {
            result = sort(this.filteredDataSource, this.current.languageTag, [ (item) => item[def] ], [ event.direction || 'asc' ]);
        }

        this.filteredDataSource = [ ...result ];
    }

    getData(date: DateTime) {
        this.gettingData = true;
        this.hasError = false;

        this.plusHoursMonitoringService.get(this.customerId, date).pipe(
            catchError(() => {
                this.hasError = true;
                this.gettingData = false;
                return EMPTY;
            }),
        ).subscribe((res) => {
            this.handleResponse(date, res);
        });
    }

    handleResponse(date: DateTime, response: PlusHoursMonitoring[]) {
        this.gettingData = false;
        this.dataSource = response;
        this.filteredDataSource = response;
        this.date = date;
        const sticky = window.innerWidth > 768;

        this.categories = [
            {
                columnDef: 'employee',
                label: this.translateService.t('EMPLOYEE'),
                columns: [
                    { columnDef: 'badgeNumber', classes: [ 'short' ], itemKey: 'badgeNumber', label: this.translateService.t('NUMBER'), sortArrow: 'after', sticky },
                    { columnDef: 'firstName', classes: [], itemKey: 'firstName', label: this.translateService.t('FIRST_NAME'), sortArrow: 'after', sticky },
                    { columnDef: 'lastName', classes: [], itemKey: 'lastName', label: this.translateService.t('LAST_NAME'), sortArrow: 'after', sticky },
                    { columnDef: 'grade', classes: [ 'short' ], itemKey: 'grade', label: this.translateService.t('GRADE', Namespace.PayrollOverview) },
                    { columnDef: 'level', classes: [ 'short' ], itemKey: 'level', label: this.translateService.t('LEVEL', Namespace.PayrollOverview) },
                    { columnDef: 'extraHoursValue', classes: [ 'right' ], itemKey: 'extraHoursValue', label: this.translateService.t('EXTRA_HOURS_VALUE', Namespace.FrancePayroll) },
                    { columnDef: 'totalPaidTime', classes: [ 'right' ], itemKey: 'totalPaidTime', label: this.translateService.t('TOTAL_EXTRA_HOURS', Namespace.FrancePayroll), sortArrow: 'before' },
                ],
            },
            ...Array.from({ length: 3 }).map((_, index) => {
                return {
                    columnDef: `month_${index}`,
                    label: Promise.resolve(date.plus({ months: index - 2 }).toFormat('MMMM yyyy')),
                    month: index,
                    columns: [
                        { columnDef: `baseHours_month_${index}`, classes: [ 'right' ], itemKey: 'baseHours', label: this.translateService.t('BASE_HOURS', Namespace.FrancePayroll) },
                        { columnDef: `addendumHours_month_${index}`, classes: [ 'right' ], itemKey: 'addendumHours', label: this.translateService.t('ADDENDUM_HOURS', Namespace.FrancePayroll) },
                        { columnDef: `extraHoursOverSetting_month_${index}`, classes: [ 'right' ], itemKey: 'extraHoursOverSetting', label: this.translateService.t('EXTRA_HOURS_OVER_SETTING', Namespace.FrancePayroll) },
                        { columnDef: `paidTime_month_${index}`, classes: [ 'right' ], itemKey: 'paidTime', label: this.translateService.t('OVER_HC_PERCENT', Namespace.FrancePayroll) },
                    ],
                };
            }),
        ];

        this.categoriesDef = this.categories.map((category) => category.columnDef);
        this.columnsDef = this.categories.flatMap((category) => category.columns.map((column) => column.columnDef));
        this.employeeFilter.updateValueAndValidity();
    }
}
