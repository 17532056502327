import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Customer } from '../../../../../shared/models/customer';
import { Employee } from '../../../../../shared/models/employee';
import { MatListModule } from '@angular/material/list';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { Position } from '../../../../../shared/models/position';
import { PositionService } from '../../../../http/position.service';
import { EmployeePositionService } from '../../../../http/employee-position.service';
import { catchError, EMPTY, of, switchMap, tap } from 'rxjs';
import { PermissionCheckService } from '../../../../../shared/services/permission-check.service';
import { InfoLoadingComponent } from '../../../../../shared/components/info-loading/info-loading.component';
import { NewEmployeeNextStepComponent } from '../../components/new-employee-next-step/new-employee-next-step.component';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';
import { InfoBoxComponent } from '../../../../../shared/components/info-card/info-box.component';

@Component({
    selector: 'eaw-new-employee-positions',
    standalone: true,
    imports: [ CommonModule, MatListModule, InfoLoadingComponent, NewEmployeeNextStepComponent, TranslatePipe, InfoBoxComponent ],
    templateUrl: './new-employee-positions.component.html',
    styleUrl: './new-employee-positions.component.scss',
})
export class NewEmployeePositionsComponent extends NewEmployeeStepComponent implements OnInit {
    private readonly positionService = inject(PositionService);
    private readonly employeePositionService = inject(EmployeePositionService);
    private readonly permissionCheckService = inject(PermissionCheckService);

    @Input({ required: true }) customer!: Customer;
    @Input({ required: true }) employee!: Employee;
    @Input({ required: true }) isRequired?: boolean;

    loadingData = true;
    positions: Position[] = [];
    selectedPositions = new Set<number>();
    processingPositions = new Set<number>();
    canGetPositions = false;
    canAddPositionToEmployee = false;

    override ngOnInit() {
        super.ngOnInit();

        this.checkPermissions().pipe(
            switchMap((hasPermissions) => {
                if (!hasPermissions) {
                    this.step.completed = true;
                    this.loadingData = false;
                    return EMPTY;
                }

                return this.getPositions();
            }),
        ).subscribe();
    }

    getPositions() {
        return this.positionService.getAll(this.customer.id, { order_by: 'name', direction: 'asc', per_page: 50 }).pipe(
            tap((positions) => {
                this.loadingData = false;
                this.positions = positions.data;
                this.updateComplete();
            }),
        );
    }

    checkPermissions() {
        const getPositionsPermission = `customers.${this.customer.id}.positions.*.get`;
        const addPositionToEmployeePermission = `customers.${this.customer.id}.employees.${this.employee.id}.positions.create`;

        return this.permissionCheckService.isAllowedMany([
            getPositionsPermission,
            addPositionToEmployeePermission,
        ], []).pipe(
            switchMap((result) => {
                this.canGetPositions = this.permissionCheckService.single(getPositionsPermission);
                this.canAddPositionToEmployee = this.permissionCheckService.single(addPositionToEmployeePermission);

                return of(result);
            }),
        );
    }

    updateComplete() {
        const minimumRequiredPositions = this.selectedPositions.size >= (this.isRequired ? 1 : 0);
        const notProcessing = !this.processingPositions.size;
        const noPositions = !this.positions.length;

        this.step.completed = noPositions || (minimumRequiredPositions && notProcessing);
    }

    handleError(positionId: number) {
        this.processingPositions.delete(positionId);
        this.updateComplete();
        return EMPTY;
    }

    togglePosition(position: Position) {
        if (this.processingPositions.has(position.id)) {
            return;
        }

        this.step.completed = false; // Wait while processing
        this.processingPositions.add(position.id);

        if (this.selectedPositions.has(position.id)) {
            this.employeePositionService.delete(this.customer.id, this.employee.id, position.id).pipe(
                catchError(() => this.handleError(position.id)),
            ).subscribe(() => {
                this.selectedPositions.delete(position.id);
                this.processingPositions.delete(position.id);
                this.updateComplete();
            });
        } else {
            this.employeePositionService.create(this.customer.id, this.employee.id, position.id).pipe(
                catchError(() => this.handleError(position.id)),
            ).subscribe(() => {
                this.selectedPositions.add(position.id);
                this.processingPositions.delete(position.id);
                this.updateComplete();
            });
        }
    }
}
