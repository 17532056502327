// @ts-nocheck
import { module } from 'angular';

module('eaw.dialogs').component('eawDialogHeaderOld', {
    template: `<md-toolbar">
    <div class="md-toolbar-tools">
        <h1 class="mat-mdc-dialog-title mdc-dialog__title" style="font-size: 20px !important; font-weight: 500 !important;" ng-bind="$ctrl.heading"></h1>
        <span flex></span>
        <div ng-transclude="tools"></div>
        <md-button ng-if="$ctrl.closable" class="md-icon-button" close-dialog>
            <md-icon style="color: black !important;" ng-bind="'close'"></md-icon>
        </md-button>
    </div>
</md-toolbar>
`,
    bindings: {
        heading: '<?',
        background: '@?',
        closable: '<?',
    },
    transclude: {
        tools: '?eawDialogHeaderTools',
    },
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.closable = ctrl.closable ?? true;
        };
    },
});
