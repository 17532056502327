// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

const template1 = `<div class="conversation-item" layout="column" ng-class="{'my-msg': ci.belongsToMe}">
    <div class="conversation-content" layout="row" layout-align="start start">
        <div class="profile-pic">
            <eaw-profile-picture-old size="32" user="ci.message.user"></eaw-profile-picture-old>
        </div>

        <div class="message">
        <markdown-downgrade ng-if="!ci.editing" [text]="ci.message.body"></markdown-downgrade>
            <wysiwyg-downgrade ng-if="ci.editing" (content-change)="ci.contentChange($event)"></wysiwyg-downgrade>
        </div>
    </div>

    <div class="conversation-status" layout="row">
        <div layout="column">
            <small ng-if="!ci.belongsToMe && ci.message.created_at && !ci.message.error" ng-bind="ci.message.user.name"></small>
            <eaw-time time="ci.message.created_at" ng-if="!ci.message.sending && ci.message.created_at && !ci.message.error"></eaw-time>
            <small ng-if="ci.message.sending && !ci.message.error" ng-i18next="messages:SENDING"></small>
            <small ng-if="ci.message.error" md-colors="{color: 'red'}" ng-i18next="FAILED"></small>
        </div>

        <md-button class="confirm-read md-accent md-raised" ng-if="!ci.message.confirmed_at && !ci.belongsToMe" ng-click="ci.confirmRead(ci.message)" ng-i18next="messages:CONFIRM_READ" ng-disabled="ci.confirmingMessage"></md-button>
        <md-button class="edit-msg md-accent md-raised" ng-if="ci.editable && !ci.editing && !ci.message.error" ng-click="ci.editMessage(ci.message)" ng-i18next="EDIT"></md-button>
    </div>

    <div class="conversation-stats" ng-if="ci.belongsToMe && !ci.message.error">
        <small>
            <span ng-bind="ci.message.read_count"></span>
            <md-icon md-svg-src="checkbox-blank-circle" class="s12" md-colors="{color: 'amber'}" ng-i18next="[title]messages:OPENED"></md-icon>
        </small>
        <small class="tw-ml-4">
            <span ng-bind="ci.message.confirmed_count"></span>
            <md-icon ng-bind="'done'" class="s12" md-colors="{color: 'green'}" ng-i18next="[title]messages:CONFIRMED"></md-icon>
        </small>
    </div>

    <div class="conversation-files" layout="column" layout-align="start {{ci.belongsToMe ? 'end' : 'start'}}">
        <small ng-if="ci.message.uploadingAttachments" ng-i18next="[i18next]({count: ci.message.uploadingAttachments})messages:UPLOADING_ATTACHMENT"></small>
        <span class="attachment overflow-ellipsis" ng-repeat="att in ci.message.attachments track by $index" ng-click="ci.download(att)" ng-bind="att.name"></span>
    </div>
</div>
`;

module('eaw.messages').component('conversationItem', {
    bindings: {
        message: '<',
    },
    template: template1,
    controllerAs: 'ci',
    controller: [ 'Message', '$interval', '$scope', function(Message, $interval, $scope) {
        const ctrl = this;

        ctrl.contentChange = ev => {
            ctrl.message.body = ev;
        }

        ctrl.$onInit = () => {
            ctrl.belongsToMe = CurrentOld.user.id === ctrl.message.user_id;
            ctrl.message.created_at = moment.isMoment(ctrl.message.created_at) ? ctrl.message.created_at : moment();

            ctrl.buttons = {
                update: {
                    contents: t('SAVE'),
                    click: ctrl.updateMessage,
                },
                cancel: {
                    contents: `<span md-colors="{color: 'red'}">${t('CANCEL')}</span>`,
                    click: ctrl.cancelEditing,
                },
            };

            ctrl.updateEditable();
            ctrl.createInterval();
        };

        ctrl.$onDestroy = () => {
            $interval.cancel(ctrl.messageInterval);
        };

        ctrl.updateMessage = () => {
            ctrl.editing = false;
            if (moment().diff(ctrl.message.created_at, 'm') > 5) {
                ctrl.cancelEditing();
                return;
            }

            ctrl.message.loading = true;
            Message.update(ctrl.message).then((resp) => {
                ctrl.message.body = resp.body;
                ctrl.message.loading = false;
            });
        };

        ctrl.updateEditable = () => {
            ctrl.editable = ctrl.belongsToMe && ctrl.message.id && moment().diff(ctrl.message.created_at, 'm') < 5;
        };

        ctrl.createInterval = () => {
            const minutesSinceCreated = moment().diff(ctrl.message.created_at, 'm') || 1;

            ctrl.messageInterval = $interval(() => {
                ctrl.updateEditable();
                $interval.cancel(ctrl.messageInterval);
                ctrl.createInterval();
            }, minutesSinceCreated * 1000, 0, false);
        };

        ctrl.confirmRead = (msg) => {
            ctrl.confirmingMessage = true;
            return Message.confirm(msg.id).$promise.then((message) => {
                ctrl.message.confirmed_at = message.confirmed_at;
            }).finally(() => delete ctrl.confirmingMessage);
        };

        ctrl.editMessage = () => {
            ctrl.body = ctrl.message.body; // preserve old value
            ctrl.editing = true;
        };

        ctrl.cancelEditing = () => {
            $scope.$evalAsync(() => {
                ctrl.message.body = ctrl.body; // give the old value back.
                ctrl.editing = false;
            });
        };

        ctrl.download = (attachment) => {
            Message.downloadAttachment(attachment.owner_id, attachment.id);
        };
    } ],
});
