// @ts-nocheck
import { t } from 'i18next';
import { debounce } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
module('eaw.calendar').component('eawCalendarOld', {
    template: `<md-menu-bar>
    <md-menu ng-if="$cal.dataSources.length">
        <button ng-click="$mdMenu.open()" ng-i18next="calendar:DATA_SOURCE_plural"></button>
        <md-menu-content width="3">
            <md-menu-item ng-repeat="source in ::$cal.dataSources track by source.name"
                          type="checkbox"
                          md-prevent-menu-close
                          ng-model="source.active"
                          ng-change="$cal.toggleDataSource(source)"
                          ng-value="source">{{source.i18n}}
            </md-menu-item>
        </md-menu-content>
    </md-menu>
</md-menu-bar>

<div id="calendar-settings" layout="row" layout-wrap layout-align="start center">
    <div layout="row" layout-align="start center" flex-xs="100" flex-sm="60" flex-md="70" flex-gt-md="80">
        <md-button class="md-raised" ng-click="$cal.goToDate()" ng-i18next="TODAY"></md-button>

        <md-button class="md-icon-button" ng-click="$cal.increment(-1)">
            <md-icon ng-bind="'chevron_left'"></md-icon>
        </md-button>
        <md-button class="md-icon-button" ng-click="$cal.increment(1)">
            <md-icon ng-bind="'chevron_right'"></md-icon>
        </md-button>

        <span id="mode-value" ng-bind="$cal.displayDate.format($cal.mode.format)"></span>
    </div>

    <div layout="row" layout-align="end center" flex-xs="100" flex-sm="40" flex-md="30" flex-gt-md="20">
        <employee-autocomplete clear-btn="false" label="'calendar:CHOOSE_EMPLOYEE' | i18next" ng-model="$cal.employee" ng-change="$cal.employeeChanged()"></employee-autocomplete>
    </div>


    <!-- ENABLE MODE AT A LATER POINT -->
<!--    <md-select id="mode-selector" class="md-no-underline" ng-if="$cal.modes.length > 1" ng-model="$cal.mode" ng-change="$cal.changeMode()">-->
<!--        <md-option ng-repeat="mode in ::$cal.modes track by mode.value" ng-value="mode" >-->
<!--            <span ng-bind="::mode.name"></span>-->
<!--        </md-option>-->
<!--    </md-select>-->
</div>

<eaw-year-calendar-old year="$cal.date.year()" ng-if="$cal.mode.value == 'y'"></eaw-year-calendar-old>
<eaw-overview-calendar-old date="$cal.date" ng-if="$cal.mode.value == 'A'"></eaw-overview-calendar-old>
`,
    controllerAs: '$cal',
    bindings: {
        customer: '<',
        useModes: '<?',
        initialMode: '<?',
        useSources: '<?',
        createSourceName: '<?',
        eventDisplayFunc: '<?', // Custom function that receives (event, key) and has to return a bool whether to show event or not
    },
    controller: [ 'EventDataSource', 'CalendarEvent', 'OffTimeDataSource', 'AbsenceDataSource', 'HolidayDataSource', 'DataSource', '$scope', function(EventDataSource, CalendarEvent, OffTimeDataSource, AbsenceDataSource, HolidayDataSource, DataSource, $scope) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.events = {};
            ctrl.date = moment();
            ctrl.displayDate = ctrl.date.clone();
            ctrl.listenEvents();
            ctrl.createDataSources();
            ctrl.createModes();
            ctrl.increment(0);
        };
        ctrl.$doCheck = () => {
            ctrl.updateEventDisplayRules();
        };
        ctrl.updateEventDisplayRules = () => {
            Object.entries(ctrl.events || {}).forEach((entry) => {
                const [ key, events ] = entry;
                events.forEach((e) => {
                    e.visible = ctrl.eventDisplayFunc?.(e, key) ?? true;
                });
            });
        };
        ctrl.listenEvents = () => {
            CalendarEvent.onAdded($scope, ctrl.onEventAdded);
        };
        ctrl.onEventAdded = (_, calEvent) => {
            calEvent.keys.forEach((k) => {
                // Create event array for that key
                ctrl.events[k] = ctrl.events[k] || [];
                ctrl.events[k].push(calEvent);
            });
            ctrl.updateEventDisplayRules();
        };
        ctrl.getDayEvents = (key) => ctrl.events[key];
        ctrl.createDataSources = () => {
            // Start off with initial values
            ctrl.dataSources = [
                EventDataSource,
                HolidayDataSource,
                AbsenceDataSource,
                OffTimeDataSource,
            ].map((Source) => new Source(ctrl.customer));
            // Filter our what we don't have permission to do
            ctrl.dataSources = ctrl.dataSources.filter((d) => d.canUseSource);
            // If use sources is passed in, then filter out all the sources that are not in use sources
            // Set as active since we explicitly request that source
            if (Object.keys(ctrl.useSources || []).length) {
                ctrl.dataSources = ctrl.dataSources.filter((d) => ctrl.useSources.includes(d.name)).map((d) => {
                    d.active = true;
                    return d;
                });
            }
            // Set the source we will use for range selection
            ctrl.createSource = ctrl.dataSources.find((s) => s.name === ctrl.createSourceName);
        };
        /**
         * @param {DataSource} [source]
         */
        ctrl.getSourcesData = (source) => {
            const sources = source instanceof DataSource ? [ source ] : ctrl.dataSources;
            sources?.forEach((s) => {
                if (s.active) { // Only get from active sources
                    if (ctrl.employee) {
                        if (s.useOnEmployee) { // Only use sources that can be used when an employee is selected
                            s.get(ctrl.from, ctrl.to, ctrl.employee);
                        }
                    } else { // Default
                        s.get(ctrl.from, ctrl.to);
                    }
                }
            });
        };
        ctrl.toggleDataSource = (source) => {
            if (source.active) {
                ctrl.getSourcesData(source);
            } else {
                ctrl.clearSourceData(source);
            }
        };
        ctrl.createModes = () => {
            // Set all modes we have
            ctrl.modes = [
                {
                    value: 'y',
                    format: 'YYYY',
                    name: t('YEAR'),
                    incrementor: 'y',
                },
                {
                    value: 'A',
                    format: 'MMM - MMM YYYY',
                    name: t('calendar:OVERVIEW'),
                    incrementor: 'M',
                },
            ];
            // If use modes is passed in, then filter out all the modes that are not in use modes
            if (Object.keys(ctrl.useModes || []).length) {
                ctrl.modes = ctrl.modes.filter((m) => ctrl.useModes.includes(m.value));
            }
            // Set initial mode
            ctrl.mode = ctrl.modes.find((m) => m.value === ctrl.initialMode) || ctrl.modes?.[0] || [];
        };
        /**
         * @param {Number} increment
         */
        ctrl.increment = (increment) => {
            ctrl.displayDate = ctrl.displayDate.add(increment, ctrl.mode.incrementor);
            debounce(ctrl.go, 500)();
        };
        ctrl.go = () => {
            ctrl.events = {};
            ctrl.date = ctrl.displayDate.clone();
            ctrl.from = ctrl.date.clone().startOf(ctrl.mode.incrementor);
            ctrl.to = ctrl.date.clone().endOf(ctrl.mode.incrementor);
            ctrl.getSourcesData();
        };
        ctrl.employeeChanged = () => {
            ctrl.employeeSelected = !!ctrl.employee?.id;
            // Clear all data
            ctrl.clearAllData();
            // Update data sources that have an employee option
            ctrl.increment(0);
        };
        ctrl.clearSourceData = (source) => {
            Object.entries(ctrl.events).forEach((entry) => {
                const key = entry[0];
                const events = entry[1];
                // Remove all events with the same source
                ctrl.events[key] = events.filter((e) => e.dataSource !== source.name);
            });
        };
        ctrl.clearAllData = () => {
            ctrl.events = {};
        };
    } ],
});
