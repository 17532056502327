import { module } from 'angular';
import MlBucket from '../../ai-budgeting/angularjs/shared/ml-bucket';
import MlVariable from '../../ai-budgeting/angularjs/shared/ml-variable';
import { Products } from '../../shared/enums/products';
import { Namespace } from '../../shared/enums/namespace';
import { NavTabsComponent } from '../../shared/components/nav-tabs/nav-tabs.component';
import { ScheduleListComponent } from '../pages/schedule-list/schedule-list.component';
import { TemplateListComponent } from '../pages/template-list/template-list.component';
import { CurrentService } from '../../shared/services/current.service';
import { BreadcrumbFn, createNavTabsState, createState } from '../../shared/utils/create-state';
import { navTabsTabsResolver } from '../../shared/resolvers/nav-tabs-tabs.resolver';
import { ScheduleShiftChangesComponent } from '../pages/schedule-shift-changes/schedule-shift-changes.component';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { firstValueFrom, lastValueFrom, map, of } from 'rxjs';
import { CustomerProductService } from '../../shared/http/customer-product.service';
import CustomerOld from '../../shared/angularjs/customer-old';
import { t } from 'i18next';
import { LearningModuleModule } from '../../learning-module/types/modules';
import { DataTableQueryParams } from '../../data-table/data-table.component';
import { PermissionCheckService } from '../../shared/services/permission-check.service';
import { inject } from '@angular/core';
import { ScheduleService } from '../http/schedule.service';
import { Resolvable } from '../../shared/types/resolvable';
import { dependencyResolver } from '../../shared/resolvers/dependency.resolver';
import { staticResolver } from '../../shared/resolvers/static.resolver';
import { ScheduleComponent } from '../pages/schedule/schedule.component';
import { paramResolver } from '../../shared/resolvers/param.resolver';
import { environment } from '../../../environments/environment';

module('eaw.scheduling').config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: 'eaw/app/scheduling',
        parent: 'eaw/app',
        url: '/scheduling',
        abstract: true,
        data: {
            learningModuleModules: [ LearningModuleModule.Scheduling ],
            products: [ Products.Scheduling ],
            i18nextNs: [
                Namespace.Scheduling,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Admin,
                Namespace.SwissRiskAndCare,
                Namespace.ChainOps,
                Namespace.Digisign,
                Namespace.Availabilities,
                Namespace.Absences,
                Namespace.Vacation,
                Namespace.Company,
                Namespace.Calendar,
                Namespace.AbsenceTypes,
            ],
            breadcrumb: { key: 'SCHEDULING', ns: 'navigation' },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/scheduling/shift_changes',
        parent: 'eaw/app/scheduling',
        url: '/shift_changes',
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'CHANGES', ns: 'navigation' },
            permissions: [ 'customers.{customer}.schedules.past.get' ],
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            staticResolver('employeeId', null),
        ],
        views: {
            'content@': {
                component: ScheduleShiftChangesComponent,
            },
        },
    });

    $stateProvider.state('eaw/app/scheduling/shifts_overview', {
        parent: 'eaw/app/scheduling',
        url: '/shifts_overview',
        data: {
            breadcrumb: { key: 'SHIFTS_OVERVIEW', ns: 'navigation' },
            permissions: [
                `customers.{customer}.shifts.*.get`,
                `customers.{customer}.schedules.*.get`,
                `customers.{customer}.employees.*.get`,
            ],
        },
        views: {
            'content@': 'shiftsOverview',
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/scheduling/schedules',
        parent: `eaw/app/scheduling`,
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        resolve: [
            navTabsTabsResolver(function($transition$) {
                const current = $transition$.injector().get(CurrentService) as CurrentService;
                const customerProductService = $transition$.injector().get(CustomerProductService) as CustomerProductService;

                return Promise.resolve([
                    {
                        state: 'eaw/app/scheduling/schedules/list',
                        label: {
                            key: 'SCHEDULE_plural',
                            ns: 'scheduling',
                        },
                    },
                    {
                        state: 'eaw/app/scheduling/schedules/templates',
                        label: {
                            key: 'TEMPLATE_plural',
                            ns: 'scheduling',
                        },
                        show: customerProductService.hasProducts(current.getCustomer().id, [ Products.France ]).pipe(map((has) => !has)),
                    },
                ]);
            }),
        ],
        data: {
            permissions: [ `customers.{customer}.schedules.*.get` ],
            breadcrumb: null,
        },
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/scheduling/schedules/list`,
        url: '/schedules',
        parent: `eaw/app/scheduling/schedules`,
        component: ScheduleListComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'SCHEDULE_plural', ns: Namespace.Scheduling },
            permissionCheck: (permissionCheckService, injector) => {
                const customerId = injector.get(CurrentService).getCustomer().id;
                return permissionCheckService.permissionOrChildren(`customers.${customerId}.schedules.*.get`, undefined, `customers.${customerId}.schedules`, `get`, true)
            },
        },
    });

    const scheduleNameBreadcrumbFn: BreadcrumbFn = (transition) => {
        const scheduleService = inject(ScheduleService);
        const currentService = inject(CurrentService);
        return scheduleService.get(currentService.getCustomer().id, transition.params()['id']).pipe(map((schedule) => schedule.name));
    };

    createState($stateProvider, {
        name: 'eaw/app/scheduling/schedules/list/beta',
        parent: 'eaw/app/scheduling/schedules/list',
        url: '/beta/:id',
        params: {
            tab: {
                type: 'string',
                dynamic: true,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('stackId', 'customer', 'stackId'),
            paramResolver('id', 'scheduleId', (v) => parseInt(String(v))),
        ],
        views: {
            'content@': {
                component: ScheduleComponent,
            },
        },
        data: {
            permissionCheck: (permissionCheckService) => {
                return  environment.isProduction ? permissionCheckService.isAllowed('schedule_beta') : of(true);
            },
            queryParams: [ 'tab' ],
            breadcrumb: scheduleNameBreadcrumbFn,
        },
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/scheduling/schedules/templates`,
        url: '/templates',
        parent: `eaw/app/scheduling/schedules`,
        component: TemplateListComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'TEMPLATE_plural', ns: 'scheduling' },
        },
    });

    function getHolidays(schedule: any, HolidaysFactory: any) {
        return HolidaysFactory.getHolidays(schedule.customer, schedule.getFrom().startOf('d'), schedule.getTo().endOf('d')).then((resp: any) => {
            schedule.setHolidays(resp.data);
            return resp.data;
        });
    }

    const scheduleResolves: Resolvable[] = [
        dependencyResolver('openingHours', [ 'OpeningHoursFactory', 'customer' ], function(OpeningHoursFactory: any, customer: any) {
            return OpeningHoursFactory.getAll(customer.id).catch(() => []);
        }),
        dependencyResolver('schedule', [ 'isOriginal', 'ScheduleService', '$transition$', 'customer', 'Schedule', 'CustomerSettings' ], function(isOriginal: boolean, ScheduleService: any, $transition$: any, customer: any, Schedule: any, CustomerSettings: any) {
            if (isOriginal) {
                return ScheduleService.getOriginal({
                    customer_id: customer.id,
                    schedule_id: $transition$.params().id,
                    with: [ 'properties' ],
                }).$promise.then((schedule: any) => {
                    schedule.original = true;
                    const s = new Schedule(schedule);
                    s.customer = customer;
                    return s;
                });
            }

            return ScheduleService.getSchedule(customer, $transition$.params().id, [ 'properties', 'customer.properties' ]).$promise.then(async (schedule: any) => {
                const shiftBusinessDateStartTimeKey = 'shift:business_date_start_time';
                const businessOffsetDateKey = 'business_date_offset';
                const scheduleCustomer = new CustomerOld(schedule.customer);
                await CustomerSettings.get(customer.id, shiftBusinessDateStartTimeKey.replace(':', '%3A')).then((resp: any) => scheduleCustomer.setProperty({ key: shiftBusinessDateStartTimeKey, value: resp[shiftBusinessDateStartTimeKey] }));
                await CustomerSettings.get(customer.id, businessOffsetDateKey).then((resp: any) => scheduleCustomer.setProperty({ key: businessOffsetDateKey, value: resp[businessOffsetDateKey] }));
                const s = new Schedule(schedule);
                s.customer = scheduleCustomer;
                return s;
            });
        }),
        dependencyResolver('mlBucket', [ 'customer', 'PermissionCheckServiceDowngrade', 'mlVariableFactory', 'mlBucketFactory', 'CustomerSettings' ], function(customer: any, PermissionCheckServiceDowngrade: PermissionCheckService, mlVariableFactory: any, mlBucketFactory: any, CustomerSettings: any) {
            if (!customer.hasProduct('ai budgeting')) {
                return;
            }

            const bucketKey = 'ai-budgeting-ml-bucket';
            let bucketUuid = '';

            return CustomerSettings.get(customer.id, bucketKey).then((resp: any) => {
                bucketUuid = resp[bucketKey];
                if (!bucketUuid) {
                    return;
                }

                customer.setProperty({ key: bucketKey, value: bucketUuid });

                return firstValueFrom(
                    PermissionCheckServiceDowngrade.isAllowedMany([
                        `customers.${customer.id}.ml_buckets.${bucketUuid}.get`,
                        `customers.${customer.id}.ml_buckets.${bucketUuid}.variables.*.get`,
                    ]),
                ).then((canBucketAndVariables) => {
                    if (!canBucketAndVariables) {
                        return;
                    }

                    // Filter those where we have permission and map the promise
                    return Promise.all([
                        mlBucketFactory.get.query(customer.id, bucketUuid).then((resp: any) => new MlBucket(resp)).catch(() => null),
                        mlVariableFactory.getAll.query(customer.id, bucketUuid, { per_page: 999 }).then((resp: any) => resp.data).catch(() => null),
                        CustomerSettings.get(customer.id, 'ai-projections.stats_definitions'),
                    ]).then(([ bucket, variables, statsDefinitionsSetting ]) => {
                        let statsDefinitions: unknown;

                        try {
                            statsDefinitions = JSON.parse(statsDefinitionsSetting['ai-projections.stats_definitions']);
                        } catch (e) {
                            console.error('Failed to parse stats definitions', e);
                        }

                        if (!(bucket instanceof MlBucket)) {
                            return null;
                        }
                        if (!variables?.length) {
                            return bucket;
                        }

                        variables.forEach((v: any) => bucket.addVariable(new MlVariable(v)));

                        if (Array.isArray(statsDefinitions)) {
                            statsDefinitions.forEach((v) => {
                                bucket.addVariable(new MlVariable({
                                    uuid: v.id,
                                    bucket_uuid: bucket.uuid,
                                    code: v.id,
                                    description: '',
                                    name: t(v.wtiKey.replace('.', ':')),
                                }));
                            });
                        }

                        return bucket;
                    });
                });

            });
        }),
        dependencyResolver('holidays', [ 'schedule', 'HolidaysFactory' ], function(schedule: any, HolidaysFactory: any) {
            // @ts-ignore
            return getHolidays(schedule, HolidaysFactory);
        }),
        dependencyResolver('businessUnits', [ 'schedule', 'PermissionCheckServiceDowngrade', 'BusinessUnitDowngraded', 'customer' ], async function(schedule: any, PermissionCheckServiceDowngrade: PermissionCheckService, BusinessUnitDowngraded: any, customer: any) {
            if (!customer.hasProduct(Products.BusinessUnits)) {
                return [];
            }

            try {
                const can = await firstValueFrom(PermissionCheckServiceDowngrade.isAllowed(`customers.${schedule.customer_id}.business_units.*.get`));
                if (!can) {
                    return;
                }
            } catch (_) {
                return;
            }

            return lastValueFrom(BusinessUnitDowngraded.getAllPages(schedule.customer_id, {}));
        }),
        dependencyResolver('employees', [ 'schedule', 'ScheduleService' ], function(schedule: any, ScheduleService: any) {
            const args = {
                per_page: 999,
                order_by: 'name',
                direction: 'asc',
                with: [ 'positions' ],
            };
            return ScheduleService.getEmployees(schedule.customer_id, schedule.id, args).$promise.then((resp: any) => {
                schedule.setEmployees(resp.data);
                return resp.data;
            }).catch((e: any) => {
                console.error(e);
                return [];
            });
        }),
    ];

    // FOR SCHEDULE
    createState($stateProvider, {
        name: 'eaw/app/scheduling/schedules/list/view',
        parent: 'eaw/app/scheduling/schedules/list',
        url: '/:id?:tab',
        views: {
            'content@': 'scheduleView',
        },
        params: {
            tab: {
                type: 'string',
                dynamic: true,
            },
        },
        resolve: [
            staticResolver('isOriginal', false),
            ...scheduleResolves,
        ],
        data: {
            permissions: [ 'customers.{customer}.schedules.{id}.get' ],
            i18nextNs: [
                Namespace.Calendar,
                Namespace.Absences,
                Namespace.Vacation,
                Namespace.Company,
                Namespace.Messages,
                Namespace.AiBudgeting,
                Namespace.Leaveshifts,
            ],
            breadcrumb: scheduleNameBreadcrumbFn,
        },
    });

    // FOR TEMPLATE
    createState($stateProvider, {
        name: 'eaw/app/scheduling/schedules/templates/view',
        parent: 'eaw/app/scheduling/schedules/templates',
        url: '/:id?:tab',
        views: {
            'content@': 'scheduleView',
        },
        params: {
            tab: {
                type: 'string',
                dynamic: true,
            },
        },
        resolve: [
            staticResolver('isOriginal', false),
            ...scheduleResolves,
        ],
        data: {
            permissions: [
                'customers.{customer}.schedules.{id}.get',
                'customers.{customer}.schedules.unpublished.get',
            ],
            i18nextNs: [
                Namespace.Calendar,
                Namespace.Absences,
                Namespace.Vacation,
                Namespace.Company,
                Namespace.Messages,
                Namespace.AiBudgeting,
            ],
            breadcrumb: (transition) => {
                const scheduleService = inject(ScheduleService);
                const currentService = inject(CurrentService);
                return scheduleService.get(currentService.getCustomer().id, transition.params()['id']).pipe(map((schedule) => schedule.name));
            },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/scheduling/schedules/list/view/original',
        parent: 'eaw/app/scheduling/schedules/list/view',
        url: '/original',
        views: {
            'content@': 'scheduleView',
        },
        data: {
            permissions: [ 'customers.{customer}.schedules.{id}.get' ],
            i18nextNs: [
                Namespace.Calendar,
                Namespace.Absences,
                Namespace.Vacation,
                Namespace.Company,
                Namespace.Messages,
                Namespace.AiBudgeting,
            ],
            breadcrumb: { key: 'ORIGINAL' },
        },
        resolve: [
            staticResolver('isOriginal', true),
            ...scheduleResolves,
        ],
    });
} ]);
