import { module } from 'angular';
import { EawForm } from '../types/form';
import { Products } from '../../shared/enums/products';
import { Namespace } from '../../shared/enums/namespace';
import { FormListComponent } from '../pages/form-list/form-list.component';
import { createState } from '../../shared/utils/create-state';
import { DataTableQueryParams } from '../../data-table/data-table.component';
import { UserFormSubmissionListComponent } from '../pages/user-form-submission-list/user-form-submission-list.component';
import { FormSubmissionListComponent } from '../pages/form-submission-list/form-submission-list.component';

module('eaw.forms', [
    'eaw.decorators',
]).config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state(`eaw/app/forms`, {
        parent: 'eaw/app',
        url: '/forms',
        data: {
            permissions: [ 'customers.{customer}.forms.*.get' ],
            products: [ Products.Forms ],
            i18nextNs: [ Namespace.Forms ],
            breadcrumb: { key: 'FORMS', ns: 'navigation' },
        },
        abstract: true,
    });

    createState($stateProvider, {
        name: `eaw/app/forms/list`,
        parent: `eaw/app/forms`,
        url: '/list',
        views: {
            'content@': {
                component: FormListComponent,
            },
        },
        data: {
            queryParams: DataTableQueryParams,
            permissions: [ `customers.{customer}.forms.*.get` ],
            breadcrumb: { key: 'LIST' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/forms/my`,
        parent: `eaw/app/forms`,
        url: '/my',
        views: {
            'content@': {
                component: UserFormSubmissionListComponent,
            },
        },
        data: {
            permissions: [ `customers.{customer}.forms.*.get` ],
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'MY_SUBMISSIONS', ns: 'navigation' },
        },
    });

    $stateProvider.state(`eaw/app/form`, {
        parent: `eaw/app/forms/list`,
        url: '/:id',
        views: {
            'content@': 'eawNavBar',
        },
        resolve: {
            navbarRoute: () => `eaw/app/form`,
            form: [ '$transition$', 'FormFactory', 'customer', ($transition$: any, FormFactory: any, customer: any) => {
                return FormFactory.get({
                    customer,
                    form_id: $transition$.params().id,
                    with: [ 'children' ],
                }).$promise.then((form: any) => new EawForm(form));
            } ],
            tabs: [ 'customer', '$transition$', 'form', function(customer: any, $transition$: any, form: any) {
                return [
                    {
                        label: 'FORM',
                        src: `eaw/app/forms/view`,
                        permission: `customers.${customer.id}.forms.${$transition$.params().id}.get`,
                    }, {
                        label: 'forms:SUBMISSION_plural',
                        src: `eaw/app/forms/submissions`,
                        permission: `customers.${customer.id}.forms.${form.id}.submissions.*.get`,
                    },
                ];
            } ],
        },
        data: {
            breadcrumb: { key: 'FORM', ns: 'forms' },
        },
    });

    $stateProvider.state(`eaw/app/forms/view`, {
        parent: `eaw/app/form`,
        url: '/form',
        component: 'eawSubmitForm',
        data: {
            breadcrumb: { key: 'FORM' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/forms/submissions`,
        parent: `eaw/app/form`,
        url: '/submissions',
        component: FormSubmissionListComponent,
        data: {
            breadcrumb: { key: 'SUBMISSION_plural', ns: 'forms' },
        },
    });
} ]);
