// @ts-nocheck
import { t } from 'i18next';
import { module, noop } from 'angular';

const template1 = `<md-dialog>
    <form role="form" autocomplete="off" name="newStackForm" ng-submit="ctrl.saveStack()">
        <eaw-dialog-header-old heading="'New stack'"></eaw-dialog-header-old>
        <md-dialog-content>
            <div class="md-dialog-content">
                <md-input-container>
                    <label ng-i18next="NAME"></label>
                    <input ng-model="ctrl.stack.name" ng-minlength="2" ng-maxlength="50" required>
                </md-input-container>

                <md-input-container class="tw-w-full">
                    <label ng-i18next="REGION"></label>
                    <md-select ng-model="ctrl.stack.region" required>
                        <md-option ng-repeat="region in ctrl.regions track by $index" ng-value="region" ng-bind="region"></md-option>
                    </md-select>
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button type="submit" ng-i18next="SUBMIT" ng-disabled="newStackForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.admin').controller('adminStacksCtrl', [ 'StackService', 'ToastService', '$mdDialog', function(StackService, ToastService, $mdDialog) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.table = {
            columns: [
                {
                    title: t('ID'),
                    sortable: 'id',
                },
                {
                    title: t('NAME'),
                    sortable: 'name',
                },
                {
                    title: t('REGION'),
                    sortable: 'region',
                },
                {
                    title: t('PROVISIONED'),
                    sortable: 'provisioned',
                },
                {
                    title: t('CUSTOMER_plural'),
                    class: 'text-right',
                },
            ],
            cells: [
                { template: 'item.id' },
                { template: 'item.name' },
                { template: 'item.region' },
                { template: 'item.provisioned' },
                {
                    template: 'item.customers_count',
                    class: 'text-right',
                },
            ],
        };
    };

    ctrl.search = () => {
        ctrl.reloadTable = true;
    };

    ctrl.openNewStackModal = (stack) => {
        $mdDialog.show({
            template: template1,
            controllerAs: 'ctrl',
            controller: [ 'StackRegionsFactory', function(StackRegionsFactory) {
                const dCtrl = this;

                dCtrl.$onInit = () => {
                    dCtrl.cancel = $mdDialog.cancel;
                    dCtrl.stack = stack || {};
                    dCtrl.regions = StackRegionsFactory.getAll();
                };

                dCtrl.saveStack = () => {
                    $mdDialog.hide();

                    StackService.create(dCtrl.stack).$promise.then((data) => {
                        ToastService.toast(t('NAME_ADDED', {
                            name: data.name,
                        }));
                        ctrl.reloadTable = true;
                    }, noop);
                };
            } ],
        });
    };
} ]);
