import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { map, Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { User, UserResponse } from '../models/user';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';
import { CustomerUser, CustomerUserResponse } from '../models/customer-user';
import { formatHttpParams } from '../utils/format-http-params';

interface UpdateCustomerUser {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    // part of phone number
    country_code?: string;
    language_code?: string;
    from?: DateTime;
    to?: DateTime | null;
    pivot_id?: number;
}

@Injectable({
    providedIn: 'root',
})
export class CustomerUserService {
    constructor(@Inject(HttpClient) private http: HttpClient) {}

    getAll(customer: number, options?: PaginationOptions): Observable<ArrayPaginatedResponse<User>> {
        return this.http.get<ArrayPaginatedResponse<UserResponse>>(`customers/${customer}/users`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(User));
    }

    get(customer: number, userId: number, withs?: string[]): Observable<User> {
        return this.http.get<UserResponse>(`customers/${customer}/users/${userId}`, {
            params: formatHttpParams({ 'with[]': withs }),
        }).pipe(classifyItem(User));
    }

    /**
     * Create a new user for a customer
     * @param customerId - The customer ID
     * @param userId - The user ID
     * @param from - Leave undefined if you want them to have access from **now**
     * @param to - Leave undefined if you want them to have access forever
     */
    create(customerId: number, userId: number, from?: DateTime, to?: DateTime) {
        return this.http.post<UserResponse & { pivot: CustomerUserResponse }>(`customers/${customerId}/users`, {
            user: userId,
            from,
            to,
        }).pipe(
            map((response) => new CustomerUser(response.pivot)),
        );
    }

    update(customer: number, userId: number, data: UpdateCustomerUser): Observable<User> {
        return this.http.put<UserResponse>(`customers/${customer}/users/${userId}`, data).pipe(classifyItem(User));
    }

    delete(customer: number, userId: number): Observable<undefined> {
        return this.http.delete<undefined>(`customers/${customer}/users/${userId}`);
    }
}
