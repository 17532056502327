import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Comment, CommentResponse } from '../../shared/models/comment';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { formatHttpParams } from '../../shared/utils/format-http-params';

@Injectable({
    providedIn: 'root',
})
export class ShiftCommentService {
    private http = inject(HttpClient);

    create(customerId: number, scheduleId: number, shiftId: number, text: string) {
        return this.http.post<CommentResponse>(`customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/comments`, { body: text })
            .pipe(classifyItem(Comment));
    }

    getAll(customerId: number, scheduleId: number, shiftId: number, pagination?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<CommentResponse>>(`customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/comments`, {
            params: formatHttpParams(pagination),
        }).pipe(classifyArrayPaginatedResponse(Comment));
    }

    delete(customerId: number, scheduleId: number, shiftId: number, commentId: number) {
        return this.http.delete<undefined>(`customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/comments/${commentId}`);
    }
}
