<eaw-dialog-header>
    <span title>{{'CREATE_AVAILABILITY' | translate: 'availabilities'| async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <eaw-create-availability
            [fromTo]="data.fromTo"
            [customerId]="data.customerId"
            [employeeId]="data.employeeId"
            [defaultAvailabilityDayType]="data.defaultAvailabilityDayType"
            #createAvailability>
    </eaw-create-availability>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="false" (click)="submit()">{{'CREATE' | translate | async}}</eaw-action-button>
</mat-dialog-actions>
