import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../../../../../shared/dialogs/dialog-component';
import { DateTime } from 'luxon';
import { DialogHeaderComponent } from '../../../../../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate.pipe';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { ActionButtonComponent } from '../../../../../../../shared/components/action-button/action-button.component';
import { MatButton } from '@angular/material/button';
import { MatListModule, MatListOption } from '@angular/material/list';
import { DateTimePipe } from '../../../../../../../shared/pipes/date-time.pipe';

export interface ChooseBusinessDateDialogData extends DialogData {
    from: DateTime;
}

export type ChooseBusinessDateDialogReturn = DateTime;

@Component({
    selector: 'eaw-choose-business-date-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        MatDialogContent,
        MatDialogActions,
        ActionButtonComponent,
        MatButton,
        MatDialogClose,
        NgIf,
        MatListOption,
        DateTimePipe,
        MatListModule,
    ],
    templateUrl: './choose-business-date-dialog.component.html',
    styleUrl: './choose-business-date-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseBusinessDateDialogComponent extends DialogComponent<ChooseBusinessDateDialogData, ChooseBusinessDateDialogReturn> {
    protected options = signal([ -1, 0, 1 ]);
    protected value = signal<number>(0);

    constructor() {
        super(undefined, undefined, DialogSize.Medium);
    }

    getDate(index: number) {
        return this.data.from.plus({ days: index });
    }
}
