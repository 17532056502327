// @ts-nocheck
import { module } from 'angular';
module('eaw.coupons').component('eawCouponDialog', {
    template: `<div class="panel-wrapper">
    <div class="coupon-clip" ng-transclude></div>
</div>
<div class="button-wrapper">
    <md-button class="md-primary md-icon-button md-fab" ng-if="dialog.showButton" ng-click="dialog.closeButtonClick()">
        <md-icon ng-bind="'close'"></md-icon>
    </md-button>

</div>
<svg height="0" width="0">
    <clipPath id="dialog-coupon{{$id}}" clipPathUnits="objectBoundingBox">
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0,0.032 C0,0.019,0,0.012,0.005,0.007 C0.006,0.006,0.008,0.004,0.011,0.003 C0.017,0,0.027,0,0.046,0 H0.954 C0.973,0,0.983,0,0.989,0.003 C0.992,0.004,0.994,0.006,0.995,0.007 C1,0.012,1,0.019,1,0.032 V0.693 C1,0.696,1,0.697,0.999,0.698 C0.999,0.698,0.999,0.699,0.998,0.699 C0.997,0.699,0.995,0.7,0.991,0.701 C0.973,0.703,0.959,0.715,0.959,0.728 C0.959,0.742,0.973,0.753,0.991,0.756 C0.995,0.757,0.997,0.757,0.998,0.758 C0.999,0.758,0.999,0.758,0.999,0.758 C1,0.759,1,0.761,1,0.763 V0.968 C1,0.981,1,0.988,0.995,0.993 C0.994,0.994,0.992,0.996,0.989,0.997 C0.983,1,0.973,1,0.954,1 H0.046 C0.027,1,0.017,1,0.011,0.997 C0.008,0.996,0.006,0.994,0.005,0.993 C0,0.988,0,0.981,0,0.968 V0.763 C0,0.761,0,0.759,0.001,0.758 C0.001,0.758,0.001,0.758,0.002,0.758 C0.003,0.757,0.005,0.757,0.009,0.756 C0.027,0.753,0.041,0.742,0.041,0.728 C0.041,0.715,0.027,0.703,0.009,0.701 C0.005,0.7,0.003,0.699,0.002,0.699 C0.001,0.699,0.001,0.698,0.001,0.698 C0,0.697,0,0.696,0,0.693 V0.032"
        ></path>
    </clipPath>
</svg>
`,
    controllerAs: 'dialog',
    bindings: {
        closeButtonClick: '&?',
    },
    transclude: true,
    controller: [ '$scope', '$element', function eawCouponDialog($scope, $element) {
        this.$onInit = () => {
            if (this.closeButtonClick) {
                this.showButton = true;
            }
        };
        this.$postLink = () => {
            const element = $element[0].querySelector('.coupon-clip');
            element.style.clipPath = `url("#dialog-coupon${$scope.$id}")`;
        };
    } ],
});
