<eaw-page-header>
    <div title>{{ 'AVAILABILITY_plural' | translate: 'availabilities' | async }}</div>

    <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-items-center" smallFilter>
        <mat-form-field class="standalone">
            <mat-label>{{ 'SHOW_UNAVAILABLE' | translate:'availabilities' | async }}</mat-label>
            <mat-select [formControl]="showUnavailableToday">
                <mat-option [value]="true">{{ 'YES' | translateSync }}</mat-option>
                <mat-option [value]="false">{{ 'NO' | translateSync }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</eaw-page-header>

@if (loadingGroups()) {
    <eaw-info-loading class="tw-my-24" size="lg"/>
} @else {
    <mat-accordion>
        @for (customer of customers(); track customer.id) {
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span>{{ customer.name }}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <eaw-customer-availability-overview-table [customerId]="customer.id"
                                                              [from]="from()"
                                                              [weeks]="1"
                                                              [includePhone]="true"
                                                              [showUnavailableToday]="showUnavailableToday.value"
                                                              [includeShifts]="true" />
                </ng-template>
            </mat-expansion-panel>
        }
    </mat-accordion>
}
