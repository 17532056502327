// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
import { PaginationOld } from '../../../../shared/utils/pagination-old';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.scheduling').factory('ScheduleService', [ 'EawResource', function ScheduleService(EawResource) {
    const fac = this;
    const scheduleUrl = '/customers/:custid/schedules/:schedid';
    const shiftUrl = '/customers/:custid/schedules/:schedid/shifts/:shift';
    const propUrl = '/customers/:custid/schedules/:schedid/properties/:propertyKey';
    const periodUrl = '/customers/:custid/schedules/:schedid/shifts/:shift/periods/:period';
    const efficiencyUrl = '/customers/:custid/schedules/:schedid/efficiencies/:efficiency';
    fac.addOffsetAndLength = (shift, schedule) => {
        if (!(shift.offset || shift.length)) {
            if (shift.from && shift.to) {
                shift.offset = shift.from.diff(schedule.from, 's');
                shift.length = shift.to.diff(shift.from, 's');
            }
        }
    };
    /**
     * @param args
     * @param args.customer_id
     * @param args.schedule_id
     * @return {*}
     */
    fac.getOriginal = (args) => EawResource.create(`/customers/${args.customer_id}/schedules/${args.schedule_id}/original`).get();
    fac.getAll = (args) => EawResource.create(scheduleUrl).get({
        custid: args.customer_id ? args.customer_id : CurrentOld.getCustomer().id,
        template: args.template,
        'with[]': args.with,
        ...PaginationOld.getParams(args),
    });
    fac.getSchedule = (customer, scheduleId, withArray) => EawResource.create(scheduleUrl).get({
        custid: customer.id,
        schedid: scheduleId,
        'with[]': withArray || undefined,
    });
    fac.get = (params) => fac.getSchedule(params.customer || CurrentOld.getCustomer(), params.id, params.with);
    fac.updateSchedule = (customer, schedule) => {
        return EawResource.create(scheduleUrl).update({
            custid: customer.id,
            schedid: schedule.id,
        }, {
            from: schedule.is_template ? schedule.from : undefined,
            name: schedule.name,
            publish: schedule.publish,
            publish_at: schedule.publish_at,
        });
    };
    fac.notifyAuditors = (schedule) => EawResource.create('/customers/:custid/schedules/:schedid/ready').save({
        custid: schedule.customer_id,
        schedid: schedule.id,
        shifts: 'ready',
    }, {});
    fac.deleteSchedule = (customer, schedule) => EawResource.create(scheduleUrl).delete({
        custid: customer.id,
        schedid: schedule.id,
    });
    /**
     *
     * @param {integer|string} customerId
     * @param {integer|string} scheduleId
     * @param {moment} [publishAt]
     * @return {*}
     */
    fac.publishSchedule = (customerId, scheduleId, publishAt) => EawResource.create(scheduleUrl).update({
        custid: customerId,
        schedid: scheduleId,
    }, {
        publish: true,
        publish_at: publishAt,
    });
    /**
     * Add shift to schedule
     * @param {int}      customerId
     * @param {Object}   schedule
     * @param {object}      shift
     * @param {string[]}    [withRelations]
     * @returns {*}
     */
    fac.addShift = (customerId, schedule, shift, withRelations) => {
        fac.addOffsetAndLength(shift, schedule);
        if (!shift.business_date) {
            shift.business_date = shift.from;
        }
        return EawResource.create(shiftUrl).save({
            custid: customerId,
            schedid: schedule.id,
        }, {
            with: withRelations,
            ...pick(shift, [ 'employee_id', 'offset', 'length', 'business_date', 'qualifications', 'with', 'group_id' ]),
            ...shift.field_values,
        });
    };
    /**
     * Update shift
     * @param {object}      customer
     * @param {int}         customer.id
     * @param {object}      schedule
     * @param {int}         schedule.id
     * @param {object}      shift
     * @param {string[]}    [withRelations]
     * @returns {*}
     */
    fac.updateShift = (customer, schedule, shift, withRelations) => {
        fac.addOffsetAndLength(shift, schedule);
        return EawResource.create(shiftUrl).update({
            custid: customer.id,
            schedid: schedule.id,
            shift: shift.id,
            'with[]': withRelations,
        }, {
            ...pick(shift, [ 'employee_id', 'offset', 'length', 'business_date', 'qualifications' ]),
            ...shift.field_values,
        });
    };
    fac.deleteShift = (customer, schedule, shift) => EawResource.create(shiftUrl).delete({
        custid: customer.id,
        schedid: schedule.id,
        shift: shift.id,
    });
    /**
     * @param {object}  args
     * @param {int}     args.customerId
     * @param {int}     args.scheduleId
     * @returns {*}
     */
    fac.getEfficiencies = (args) => EawResource.create(efficiencyUrl).get({
        custid: args.customerId,
        schedid: args.scheduleId,
        ...PaginationOld.getParams(args),
    });
    fac.addEfficiency = (customer, schedule, efficiency) => EawResource.create(efficiencyUrl).save({
        custid: customer.id,
        schedid: schedule.id,
    }, efficiency);
    fac.deleteEfficiency = (customer, schedule, efficiency) => EawResource.create(efficiencyUrl).delete({
        custid: customer.id,
        schedid: schedule.id,
        efficiency: efficiency.id,
    });
    /**
     * @param customerId
     * @param scheduleId
     * @param shiftId
     * @param withRelations
     */
    fac.getPeriods = (customerId, scheduleId, shiftId, withRelations) => EawResource.create(periodUrl).get({
        custid: customerId,
        schedid: scheduleId,
        shift: shiftId,
        'with[]': withRelations,
    });
    fac.addPeriod = (customerId, scheduleId, shiftId, period) => EawResource.create(periodUrl).save({
        custid: customerId,
        schedid: scheduleId,
        shift: shiftId,
    }, pick(period, [ 'length', 'offset', 'qualifications', 'unproductive', 'color', 'business_unit_id', 'break', 'description', 'with' ]));
    fac.updatePeriod = (customerId, scheduleId, shiftId, period) => EawResource.create(periodUrl).update({
        custid: customerId,
        schedid: scheduleId,
        shift: shiftId,
        period: period.id,
    }, pick(period, [ 'length', 'offset', 'qualifications', 'unproductive', 'color', 'business_unit_id', 'break', 'description' ]));
    fac.deletePeriod = (customerId, scheduleId, shiftId, periodId) => EawResource.create(periodUrl).delete({
        custid: customerId,
        schedid: scheduleId,
        shift: shiftId,
        period: periodId,
    });
    fac.updateProperty = (customer, schedule, propertyKey, propertyValue) => EawResource.create(propUrl).update({
        custid: customer.id,
        schedid: schedule.id,
        propertyKey,
    }, {
        value: propertyValue,
    });
    fac.deleteProperty = (customerId, scheduleId, propertyKey) => EawResource.create(propUrl).delete({
        custid: customerId,
        schedid: scheduleId,
        propertyKey,
    });
    fac.decline = (schedule) => EawResource.create('/customers/:customer/schedules/:schedule/ready').update({
        customer: schedule.customer_id,
        schedule: schedule.id,
    }, {});
    fac.getColumns = (scheduleId, options) => EawResource.create('/customers/:custid/schedules/:schedid/columns').get({
        custid: CurrentOld.getCustomer().id,
        schedid: scheduleId,
        ...options,
    }).$promise;
    fac.getEmployees = (customerId, scheduleId, options) => EawResource.create('/customers/:customerId/schedules/:scheduleId/employees').get({
        customerId,
        scheduleId,
        ...PaginationOld.getParams(options),
    });
    return fac;
} ]);
