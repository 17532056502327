import { t } from 'i18next';
import { forEach } from 'lodash-es';
import { debounce } from 'lodash-es';
import { Storage } from '../../../shared/utils/storage';
import { module } from 'angular';
import { PaginationOld } from '../../../shared/utils/pagination-old';
import { UIRouter } from '@uirouter/core';

module('eaw.leave-shifts').component('leaveShiftList', {
    template: `<eaw-dynamic-ng-table
        columns="$list.columns"
        cells="$list.cells"
        get-data="$list.getData(params, pagination)"
        reload-table="$list.reloadTable">
        </eaw-dynamic-ng-table>
`,
    bindings: {
        customer: '<',
        employee: '<',
        from: '<',
        to: '<',
    },
    controllerAs: '$list',
    controller: [ 'LeaveShiftFactory', '$uiRouter', function(LeaveShiftFactory, $uiRouter: UIRouter) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = async () => {
            ctrl.key = Storage.prefix(`${$uiRouter.globals.current.name}.per_page`);
            ctrl.per_page = (await Storage.getItem(ctrl.key)) || 50;

            ctrl.createTable();
        };

        ctrl.$onChanges = debounce(function() {
            ctrl.reloadTable = true;
        }, 500);

        ctrl.$onDestroy = () => {
            void Storage.setItem(ctrl.key, ctrl.per_page);
        };

        ctrl.createTable = () => {
            ctrl.columns = [
                {
                    title: t('EMPLOYEE'),
                    show: !ctrl.employee,
                },
                { title: t('LOCATION') },
                {
                    title: t('BUSINESS_DATE'),
                    sortable: 'business_date',
                },
                {
                    title: t('FROM'),
                    sortable: 'from',
                },
                {
                    title: t('TO'),
                    sortable: 'to',
                },
                { title: t('LENGTH') },
                { title: t('TYPE') },
                {
                    title: t('absences:GRADE'),
                    class: 'text-right',
                },
                { title: t('leaveshifts:BREAK_FROM') },
                { title: t('leaveshifts:BREAK_TO') },
                { title: t('BREAK') },
            ];
            ctrl.cells = [
                {
                    template: 'item.employee.name',
                    show: !!ctrl.employee,
                },
                { template: 'item.customer.name' },
                { template: 'item.business_date | moment:"L"' },
                { template: 'item.from | moment: item.from.isSame(item.to, "day") ? "LT" : "lll"' },
                { template: 'item.to | moment: item.to.isSame(item.from, "day") ? "LT" : "lll"' },
                { template: 'item.net_length | eawDuration' },
                { template: 'item.leave_type.toUpperCase() | i18next' },
                {
                    template: 'item.leave.grade ? (item.leave.grade | percent) : ("NA" | i18next)',
                    class: 'text-right',
                },
                { template: 'item.break_from | moment:"LT"' },
                { template: 'item.break_to | moment:"LT"' },
                { template: 'item.break_length | eawDuration' },
            ];
        };

        ctrl.getData = (_: unknown, pagination: PaginationOld) => {
            let promise;
            const args = {
                customer_id: ctrl.customer.id,
                from: ctrl.from,
                to: ctrl.to,
                with: [ 'employee', 'leave', 'customer' ],
                ...PaginationOld.getParams(pagination),
            };

            if (ctrl.employee) {
                args.employee_id = ctrl.employee.id;
                promise = LeaveShiftFactory.getAllForEmployee(args).$promise;
            } else {
                promise = LeaveShiftFactory.getAllForCustomer(args).$promise;
            }

            return promise.then((resp: any) => {
                forEach(resp.data, (shift) => {
                    shift.net_length = shift.to.diff(shift.from, 's') - shift.break_length;
                });

                return resp;
            });
        };
    } ],
});
