// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';
import { Mobile } from '../../../shared/utils/eaw-mobile';
module('eaw.sso').component('ssoRedirect', {
    template: `<div class="tw-mt-24 tw-flex tw-flex-col tw-items-center tw-justify-center" ng-if="$sso.loading">
    <md-progress-circular md-mode="indeterminate"></md-progress-circular>
    <h3 class="md-headline tw-mt-24" ng-bind="$sso.heading"></h3>
    <div class="login-hint" ng-bind="$sso.tip"></div>
</div>

<div ng-if="$sso.url" class="tw-mt-16 tw-flex tw-flex-col tw-items-center tw-justify-center">
<a href="{{$sso.url}}" target="_blank">
    <md-button class="md-raised md-accent" ng-i18next="CLICK_NO_REDIRECT"></md-button>
</a>
</div>

<div class="tw-mt-16 tw-flex tw-flex-col tw-items-center tw-justify-center" ng-if="!$sso.loading">
    <div class="hover-item" ng-click="$sso.go()">
        <md-icon class="s36" ng-bind="'refresh'"></md-icon>
    </div>

    <h3 class="md-headline tw-mt-24" ng-i18next="errors:SOMETHING_WENT_WRONG"></h3>
    <div ng-click="$sso.go()" class="login-hint hover-item" ng-i18next="TRY_AGAIN"></div>
</div>

`,
    bindings: {
        serviceId: '<',
        customer: '<',
    },
    controllerAs: '$sso',
    controller: [ 'ssoTokenFactory', function(ssoTokenFactory) {
        const ctrl = this;

        const serviceData = {
            1: { name: 'Snapper', description: 'RTT', endpoint: 'https://rtt.mcd.no/plugin/ecourses' },
            2: { name: 'RTT', description: 'RTT', endpoint: 'https://rtt.mcd.no' },
            3: { name: 'McD LearningBank', description: 'LearningBank', endpoint: 'https://mcdlearningno.learningbankapp.com/sso/token' },
        };

        ctrl.$onInit = () => {
            ctrl.go();
        };

        ctrl.go = () => {
            ctrl.loading = true;
            ctrl.heading = t('REDIRECTING_YOU_TO', serviceData[ctrl.serviceId].name);
            ctrl.tip = serviceData[ctrl.serviceId].description;
            // Post to get sso token
            return ssoTokenFactory.post(ctrl.customer.id, ctrl.serviceId).then(ctrl.redirect, () => ctrl.loading = false);
        };

        ctrl.redirect = (resp) => {
            const token = resp.data.token.includes('=') ? resp.data.token : `sso_token=${resp.data.token}`;
            const url = `${serviceData[ctrl.serviceId].endpoint}?${token}`;
            ctrl.url = url;

            if (Mobile.isMobile) {
                Mobile.openBrowser(url);
            } else {
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        };
    } ],
});
