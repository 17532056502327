import { ChangeDetectionStrategy, Component, computed, inject, Input, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayrollOverviewResponse, PayrollOverviewService } from '../../http/payroll-overview.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { TranslateService } from '../../../shared/services/translate.service';
import { DateTimeInputComponent } from '../../../shared/components/date-time/date-time-input/date-time-input.component';
import { DateTimeRangeInputComponent } from '../../../shared/components/date-time/date-time-range-input/date-time-range-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { Namespace } from '../../../shared/enums/namespace';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { catchError, EMPTY, of } from 'rxjs';
import { HeaderFabButtonMenu, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { MatIconModule } from '@angular/material/icon';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { Employee } from '../../../shared/models/employee';
import { MatSortModule, Sort } from '@angular/material/sort';
import { CurrentService } from '../../../shared/services/current.service';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { SignalInput } from '../../../shared/decorators/signal-input.decorator';
import { SpreadSheetFormat, SpreadsheetService } from '../../../shared/services/spreadsheet.service';

type ColumnDef = keyof PayrollOverviewResponse;

interface Column {
    columnDef: ColumnDef;
    label: Promise<string>;
    type?: 'number' | 'string';
}

interface Category {
    columnDef: string;
    label: Promise<string> | undefined;
    columns: Column[];
}

@Component({
    selector: 'eaw-payroll-overview',
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        MatTableModule,
        DateTimeInputComponent,
        DateTimeRangeInputComponent,
        MatFormFieldModule,
        ReactiveFormsModule,
        TranslatePipe,
        ActionButtonComponent,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatInputModule,
        MatProgressSpinnerModule,
        PageHeaderComponent,
        MatIconModule,
        MaterialColorDirective,
        MatIconSizeDirective,
        DateTimePipe,
        NumberPipe,
        AutocompleteComponent,
        MatSortModule,
    ],
    templateUrl: './payroll-overview.component.html',
    styleUrl: './payroll-overview.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayrollOverviewComponent implements OnInit {
    currentService = inject(CurrentService);
    translateService = inject(TranslateService);
    queryParamsService = inject(QueryParamsService);
    numberFormatterService = inject(NumberFormatterService);
    payrollOverviewService = inject(PayrollOverviewService);
    spreadSheetService = inject(SpreadsheetService);
    employeeAutocompleteService = inject(EmployeeAutocompleteService);

    @Input({ required: true }) @SignalInput() customerId!: Signal<number>;

    readonly defaultSortDirection = 'asc';
    readonly defaultSortActive = 'last_name';

    currentSort = signal<Sort>({ active: this.defaultSortActive, direction: this.defaultSortDirection });
    fetchedFrom = signal<DateTime | undefined>(undefined);
    fetchedTo = signal<DateTime | undefined>(undefined);

    from = new FormControl<DateTime | null>(null);
    to = new FormControl<DateTime | null>(null);
    employee = new FormControl<Employee | number | null>(null);

    gettingOverview = signal(false);
    hasError = signal(false);
    dataSource = signal<PayrollOverviewResponse[]>([]);
    categories = signal<Category[]>([]);
    categoriesDef = computed(() => this.categories().map((category) => category.columnDef));
    columnsDef = computed(() => this.categories().flatMap((category) => category.columns.map((column) => column.columnDef as keyof PayrollOverviewResponse & string)));
    csvButton: WritableSignal<HeaderFabButtonMenu>;

    constructor() {
        this.csvButton = signal({
            icon: 'download',
            menu: [
                {
                    text: this.translateService.t('CSV_FILE'),
                    click: this.exportFile.bind(this, 'csv'),
                    hasPermission: () => of(true),
                },
                {
                    text: this.translateService.t('XLSX_FILE'),
                    click: this.exportFile.bind(this, 'xlsx'),
                    hasPermission: () => of(true),
                },
                {
                    text: this.translateService.t('ODS_FILE'),
                    click: this.exportFile.bind(this, 'ods'),
                    hasPermission: () => of(true),
                },
            ],
            hasPermission: () => of(true),
            tooltip: this.translateService.t('EXPORT', Namespace.General),
            type: 'primary',
        });
    }

    ngOnInit() {
        this.from.setValue(this.queryParamsService.get('from', 'date') || DateTime.now().startOf('month'));
        this.to.setValue(this.queryParamsService.get('to', 'date') || DateTime.now().endOf('month'));
        this.employee.setValue(this.queryParamsService.get('employee', 'number') || null);

        this.createColumns();
        this.getOverview();
    }

    setGettingOverview(gettingOverview: boolean) {
        this.gettingOverview.set(gettingOverview);

        if (this.gettingOverview()) {
            this.from.disable();
            this.to.disable();
            this.employee.disable();
        } else {
            this.from.enable();
            this.to.enable();
            this.employee.enable();
        }
    }

    createColumns() {
        const categories: Category[] = [
            {
                columnDef: 'basic_info',
                label: undefined,
                columns: [
                    { columnDef: 'number', label: this.translateService.t('BADGE_ID', Namespace.PayrollOverview), type: 'string' },
                    { columnDef: 'first_name', label: this.translateService.t('FIRST_NAME'), type: 'string' },
                    { columnDef: 'last_name', label: this.translateService.t('LAST_NAME'), type: 'string' },
                ],
            },
            {
                columnDef: 'contract',
                label: this.translateService.t('CONTRACT', Namespace.PayrollOverview),
                columns: [
                    { columnDef: 'title', label: this.translateService.t('JOB_TITLE', Namespace.PayrollOverview), type: 'string' },
                    { columnDef: 'level', label: this.translateService.t('LEVEL', Namespace.PayrollOverview), type: 'string' },
                    { columnDef: 'grade', label: this.translateService.t('GRADE', Namespace.PayrollOverview), type: 'string' },
                    { columnDef: 'monthly_hours', label: this.translateService.t('MONTH_HOURS_MAIN_CONTRACT', Namespace.PayrollOverview) },
                    { columnDef: 'addendum_hours', label: this.translateService.t('CONTRACT_ADDENDUM_HOURS', Namespace.PayrollOverview) },
                ],
            },
            {
                columnDef: 'hours_2',
                label: this.translateService.t('HOUR_plural'),
                columns: [
                    { columnDef: 'scheduled_hours', label: this.translateService.t('SCHEDULED', Namespace.Scheduling) },
                    { columnDef: 'worked_time', label: this.translateService.t('HOURS_WORKED', Namespace.PayrollOverview) },
                    { columnDef: 'contract_target', label: this.translateService.t('CONTRACT_TARGET', Namespace.PayrollOverview), type: 'number' },
                ],
            },
            {
                columnDef: 'absences',
                label: this.translateService.t('ABSENCE_plural', Namespace.Absences),
                columns: [
                    { columnDef: 'justified_and_unjustified_absences', label: this.translateService.t('JUST_AND_UNJUST_ABSENCES', Namespace.PayrollOverview) },
                    { columnDef: 'other_absences', label: this.translateService.t('ABSENCES_VALUE', Namespace.PayrollOverview) },
                    { columnDef: 'partial_activity', label: this.translateService.t('PARTIAL_ACTIVITY', Namespace.PayrollOverview) },
                ],
            },
            {
                columnDef: 'hours_1',
                label: this.translateService.t('HOUR_plural'),
                columns: [
                    { columnDef: 'regular_hours', label: this.translateService.t('REGULAR_HOURS', Namespace.PayrollOverview) },
                ],
            },
            {
                columnDef: 'extra_hours',
                label: this.translateService.t('EXTRA_HOURS', Namespace.PayrollOverview),
                columns: [
                    { columnDef: 'extra_hours_range_1', label: this.translateService.t('EXTRA_HOURS_RANGE_X', Namespace.PayrollOverview, { number: 1 }) },
                    { columnDef: 'extra_hours_range_2', label: this.translateService.t('EXTRA_HOURS_RANGE_X', Namespace.PayrollOverview, { number: 2 }) },
                ],
            },
            {
                columnDef: 'plus_hours',
                label: this.translateService.t('PLUS_HOURS', Namespace.PayrollOverview),
                columns: [
                    { columnDef: 'plus_hours_range_1_full_time', label: this.translateService.t('PLUS_HOURS_RANGE_X_FULL_TIME', Namespace.PayrollOverview, { number: 1 }) },
                    { columnDef: 'plus_hours_range_2_full_time', label: this.translateService.t('PLUS_HOURS_RANGE_X_FULL_TIME', Namespace.PayrollOverview, { number: 2 }) },
                    { columnDef: 'plus_hours_range_1_part_time', label: this.translateService.t('PLUS_HOURS_RANGE_X_PART_TIME', Namespace.PayrollOverview, { number: 1 }) },
                    { columnDef: 'plus_hours_range_2_part_time', label: this.translateService.t('PLUS_HOURS_RANGE_X_PART_TIME', Namespace.PayrollOverview, { number: 2 }) },
                ],
            },
            {
                columnDef: 'misc_hours',
                label: this.translateService.t('MISC_HOURS', Namespace.PayrollOverview),
                columns: [
                    { columnDef: 'normal_hours', label: this.translateService.t('NORMAL_HOURS', Namespace.PayrollOverview) },
                    { columnDef: 'night_hours_range_1', label: this.translateService.t('NIGHT_HOURS_RANGE_X', Namespace.PayrollOverview, { number: 1 }) },
                    { columnDef: 'night_hours_range_2', label: this.translateService.t('NIGHT_HOURS_RANGE_X', Namespace.PayrollOverview, { number: 2 }) },
                    { columnDef: 'bank_holiday_hours', label: this.translateService.t('BANK_HOLIDAY_HOURS', Namespace.PayrollOverview) },
                ],
            },
            {
                columnDef: 'meals_category',
                label: undefined,
                columns: [
                    { columnDef: 'meals', label: this.translateService.t('NUMBER_OF_MEALS', Namespace.PayrollOverview) },
                ],
            },
            {
                columnDef: 'split_category',
                label: undefined,
                columns: [
                    { columnDef: 'split_shifts', label: this.translateService.t('NUMBER_SPLIT_SHIFTS', Namespace.PayrollOverview) },
                ],
            },
            {
                columnDef: 'days_worked',
                label: undefined,
                columns: [
                    { columnDef: 'number_of_worked_days', label: this.translateService.t('DAYS_WORKED', Namespace.PayrollOverview) },
                ],
            },
        ];

        this.categories.set(categories);
    }

    sortData(sortEvent: Sort) {
        if (sortEvent.direction) {
            this.currentSort.set(sortEvent);
        } else {
            this.currentSort.set({ active: 'last_name', direction: 'asc' });
        }

        this.dataSource.set(
            [ ...sort(this.dataSource(), this.currentService.languageTag, [ (item) => item[this.currentSort().active] ], [ this.currentSort().direction || this.defaultSortDirection ]) ],
        );
    }

    getOverview() {
        const from = this.from.value;
        const to = this.to.value;
        const employee = this.employee.value;
        const employeeId = employee instanceof Employee ? employee.id : (employee ?? undefined);

        if (!from || !to) {
            return;
        }

        this.setGettingOverview(true);
        this.hasError.set(false);
        this.fetchedFrom.set(from);
        this.fetchedTo.set(to);
        this.queryParamsService.set([
            { key: 'from', value: from, type: 'date' },
            { key: 'to', value: to, type: 'date' },
            { key: 'employee', value: employeeId },
        ]);

        this.payrollOverviewService.get(this.customerId(), from, to, employeeId).pipe(
            catchError((err) => {
                console.error(err);
                this.setGettingOverview(false);
                this.hasError.set(true);
                return EMPTY;
            }),
        ).subscribe((res) => {
            this.dataSource.set(res);
            this.sortData(this.currentSort());
            this.setGettingOverview(false);
        });
    }

    getTotal(column: Column) {
        const skips: ColumnDef[] = [ 'number', 'first_name', 'last_name', 'title', 'level', 'grade' ];
        if (skips.includes(column.columnDef)) {
            return '';
        }

        const value = this.dataSource().reduce((acc, item) => {
            return acc + Number(item[column.columnDef]);
        }, 0);

        return this.numberFormatterService.formatDecimal(value, 2);
    }

    async getCsvLines(): Promise<string[][]> {
        const data: string[][] = [];
        const headers = [];

        // Get sub headers
        for (const category of this.categories()) {
            for (const column of category.columns) {
                headers.push((await column.label).trim());
            }
        }

        data.push(headers);

        // Get data
        for (const item of this.dataSource()) {
            const line = this.categories().flatMap((category) => {
                return category.columns.map((column): string => {
                    const value = item[column.columnDef];
                    if (column.type !== 'string') {
                        return Number(value).toFixed(2);
                    } else if (value != null) {
                        return String(value);
                    } else {
                        return '';
                    }
                });
            });

            data.push(line);
        }

        return data;
    }

    async exportFile(type: SpreadSheetFormat) {
        const lines = await this.getCsvLines();
        return this.spreadSheetService.createSpreadsheet(type, lines).subscribe();
    }
}
