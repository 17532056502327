// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
module('eaw').factory('CustomerGroupServiceOld', [ 'EawResource', function(EawResource) {
    const res = EawResource.create('/customer_groups/:group/:members/:customer/:products/:product');
    this.getAll = () => res.get();
    this.getById = (id) => res.get({ group: id });
    this.create = (name) => res.save({ name });
    this.getAllPaginated = (args) => {
        if (!args.filter) {
            delete args.filter;
        }
        return res.get({
            ...args,
            'count[]': [ 'activeMemberships', 'memberships' ],
        });
    };
    this.updateById = (group) => res.update({
        group: group.id,
    }, group);
    this.deleteById = (group) => res.delete({
        group: group.id,
    });
    // Members
    this.getMembers = (args) => {
        return res.get(Object.assign({
            group: args.groupId,
            members: 'members',
        }, args));
    };
    this.addMember = (customerGroup, customer) => res.save({
        group: customerGroup.id,
        members: 'members',
    }, {
        member_id: customer.id,
    });
    this.deleteMember = (customerGroupId, memberId) => res.delete({
        group: customerGroupId,
        members: 'members',
        customer: memberId,
    });
    // Products
    this.getProductsPaginated = (args) => {
        const get = pick(args, [ 'per_page', 'page', 'direction', 'order_by' ]);
        return res.get(Object.assign({
            group: args.groupId,
            products: 'products',
        }, get));
    };
    this.addProduct = (groupId, productName) => res.save({
        group: groupId,
        products: 'products',
    }, {
        product_name: productName,
    });
    this.removeProduct = (groupId, product) => res.delete({
        group: groupId,
        products: 'products',
        product: product.name,
    });
    return this;
} ]);
