// @ts-nocheck
import { isEqual } from 'lodash-es';
import { module } from 'angular';
import { t } from 'i18next';
module('eaw.misc').component('approvalMode', {
    template: `
<md-input-container>
    <label ng-i18next="MODE"></label>
    <md-select ng-model="$approval.mode" ng-change="$approval.setValue()">
        <md-option ng-repeat="mode in $approval.modes track by $index" ng-value="mode.value">{{mode.label}}</md-option>
    </md-select>
</md-input-container>`,
    controllerAs: '$approval',
    bindings: {
        defaultMode: '@?',
    },
    require: {
        ngModel: 'ngModel',
    },
    controller: [ 'UrlParams', function approvalMode(UrlParams) {
        this.$onInit = () => {
            this.modes = [
                {
                    id: 'unprocessed',
                    label: t('UNPROCESSED'),
                    value: { handled: 0 },
                },
                {
                    id: 'processed',
                    label: t('PROCESSED'),
                    value: { handled: 1 },
                },
                {
                    id: 'approved',
                    label: t('APPROVED'),
                    value: { approved: 1 },
                },
                {
                    id: 'declined',
                    label: t('DECLINED'),
                    value: { approved: 0 },
                },
                {
                    id: 'all',
                    label: t('ALL'),
                    value: null,
                },
            ];
            this.ngModel.$formatters.unshift(this.format);
        };
        this.$postLink = () => {
            if (this.mode === undefined) {
                return;
            }
            this.setMode();
            this.setValue();
        };
        this.format = (val) => {
            if (val === undefined) {
                this.setMode();
                this.ngModel.setViewValue(this.mode);
            } else {
                this.mode = val;
            }
            return this.mode;
        };
        this.setMode = () => {
            const id = UrlParams.get('mode') || this.defaultMode;
            this.mode = this.modes.find((m) => m.id == id)?.value;
        };
        this.setValue = () => {
            UrlParams.set('mode', this.modes.find((m) => isEqual(this.mode, m.value))?.id);
            this.ngModel.setViewValue(this.mode);
        };
    } ],
});
