import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { KpiComponent } from '../../pages/kpi/kpi.component';

export interface KpiDialogData extends DialogData {
    customerId: number;
    customerName: string;
    from: DateTime;
    to: DateTime;
}

@Component({
    selector: 'eaw-kpi-dialog',
    templateUrl: './kpi-dialog.component.html',
    styleUrl: './kpi-dialog.component.scss',
    standalone: true,
    imports: [
        MatDialogContent,
        KpiComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class KpiDialogComponent extends DialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) override data: KpiDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<KpiDialogComponent, KpiDialogData>,
    ) {
        data.size = DialogSize.Max;
        super(dialogRef, data);
    }
}
