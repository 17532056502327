import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, signal, viewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';

import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTablePagination } from '../../../data-table/types/data-table';
import { CustomerTransferService } from '../../http/customer-transfer.service';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { AdminAddCustomerTransferDialogComponent, CustomerTransferDialogData } from '../../dialogs/admin-add-customer-transfer-dialog/admin-add-customer-transfer-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, tap } from 'rxjs';

@Component({
    selector: 'eaw-customer-transfer',
    standalone: true,
    imports: [
        TranslatePipe,
        AsyncPipe,
        PageHeaderComponent,
        MatCard,
        MatCardContent,
        DataTableComponent,
    ],
    templateUrl: './customer-transfer.component.html',
    styleUrl: './customer-transfer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerTransferComponent {
    customerTransfersService = inject(CustomerTransferService);
    private matDialog = inject(MatDialog);
    private translateService = inject(TranslateService);
    private destroyedRef = inject(DestroyRef);

    headerButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            icon: 'add',
            click: () => this.addTransfer(),
            menuText: signal(this.translateService.t('ADD')),
        }),
    ];

    columns: DataTableColumnType<any>[] = [
        new DataTableTextColumn({
            value: (data) => data.item.originalCustomer.name,
            header: new DataTableHeader({
                i18n: 'ORIGINAL_CUSTOMER',
                ns: 'admin',
            }),
        }),
        new DataTableTextColumn({
            value: (data) => data.item.newCustomer.name,
            header: new DataTableHeader({
                i18n: 'TRANSFER_CUSTOMER',
                ns: 'admin',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'effectiveDate',
            format: DateTime.DATE_FULL,
            header: new DataTableHeader({
                i18n: 'EFFECTIVE_DATE',
                ns: 'reports',
                sortBy: 'effective_date',
            }),
        }),
        new DataTableTextColumn({
            value: 'status',
            header: new DataTableHeader({
                i18n: 'COMPLETED',
            }),
        }),
        new DataTableNumberColumn({
            value: 'failed',
            header: new DataTableHeader({
                i18n: 'FAILED',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'createdAt',
            format: DateTime.DATETIME_MED,
            header: new DataTableHeader({
                i18n: 'STARTED_ON',
                ns: 'admin',
                sortBy: 'created_at',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'lastUpdated',
            format: DateTime.DATETIME_MED,
            header: new DataTableHeader({
                i18n: 'LAST_UPDATED_ON',
                ns: 'admin',
            }),
        }),
    ];

    table = viewChild.required(DataTableComponent);

    getData = computed(() => (pagination: Partial<DataTablePagination>) =>
        this.customerTransfersService.getAll({ ...pagination }));

    addTransfer(): void {
        this.matDialog.open<AdminAddCustomerTransferDialogComponent, CustomerTransferDialogData>(AdminAddCustomerTransferDialogComponent, {
            data: {
                size: DialogSize.Medium,
            },
        }).afterClosed()
            .pipe(
                takeUntilDestroyed(this.destroyedRef),
                filter((result) => !!result),
                tap(() => this.table()?.refresh()),
            ).subscribe();
    }
}
