@if (loading()) {
    <mat-spinner mode="indeterminate" diameter="20" strokeWidth="2"></mat-spinner>
} @else {
    @if (alerts().length) {
        <mat-accordion multi>
            @for (alert of alerts(); track alert.id) {
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ alert.specifics?.employee_name }}</mat-panel-title>
                    </mat-expansion-panel-header>

                    <div>
                        <p>{{ alert.message }}</p>
                        <button mat-raised-button (click)="deleteAlert(alert.id)">{{ 'DELETE' | translate | async }}</button>
                    </div>
                </mat-expansion-panel>
            }
        </mat-accordion>
    } @else {
        {{ 'NO_DATA' | translate | async }}
    }
}
