// @ts-nocheck
import { module } from 'angular';
export default class PaidTimeSettingsService {
    settings = {};
    set(key, value) {
        this.settings[key] = value;
    }

    get(key) {
        return this.settings[key];
    }
}
module('eaw.payroll.paid-time').service('PaidTimeSettings', PaidTimeSettingsService);
