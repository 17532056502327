import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { EmployeePositionService } from '../../http/employee-position.service';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { EmployeeService } from '../../../shared/http/employee.service';
import { of, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddPositionsDialogComponent, AddPositionsDialogData } from '../../dialogs/add-positions-dialog/add-positions-dialog.component';
import { Position } from '../../../shared/models/position';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-employee-positions-tab',
    templateUrl: './employee-positions-tab.component.html',
    styleUrl: './employee-positions-tab.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmployeePositionsTabComponent implements OnInit, EawDataTable {
    @ViewChild(DataTableComponent) dataTable?: DataTableComponent<Position>;

    @Input({ required: true }) employeeId!: number;
    @Input({ required: true }) customerId!: number;

    employeeName = '';
    columns: DataTableColumnType<Position>[] = [
        new DataTableTextColumn({
            header: new DataTableHeader({
                i18n: 'POSITION',
                ns: 'company',
            }),
            value: 'name',
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'delete',
                    click: this.removePosition.bind(this),
                    type: 'warn',
                    show: (item) => this.permissionCheckService.isAllowed(`customers.${item.customerId}.employees.${item.pivot.employeeId}.positions.${item.id}.delete`),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    createButton: HeaderFabButton = {
        click: this.addPosition.bind(this),
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.positions.create`),
    };

    constructor(
        @Inject(EmployeePositionService) private employeePositionService: EmployeePositionService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(MatDialog) private matDialog: MatDialog,
    ) {
    }

    ngOnInit() {
        this.employeeService.get(this.customerId, this.employeeId).pipe(take(1)).subscribe((employee) => {
            this.employeeName = employee.name || '';
        });
    }

    addPosition() {
        this.matDialog.open<AddPositionsDialogComponent, AddPositionsDialogData, Position[]>(AddPositionsDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: this.employeeId,
            },
        }).afterClosed().subscribe((positions) => {
            if (positions?.length) {
                this.dataTable?.refresh();
            }
        });
    };

    removePosition(cell: DataTableButtonCell<Position>) {
        this.confirmDialogService.delete({
            title: this.translate.t('REMOVE_POSITION', 'company'),
            text: this.translate.t('REMOVE_POSITION_TEXT', 'company', {
                position: cell.item.name,
                name: this.employeeName,
            }),
        }).afterClosed().subscribe((confirmed) => {
            if (confirmed?.ok) {
                this.employeePositionService.delete(this.customerId, this.employeeId, cell.item.id).subscribe(() => {
                    this.dataTable?.refresh();
                });
            } else {
                cell.disabled.set(false);
            }
        });
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.employeePositionService.getAll(this.customerId, this.employeeId, {
            ...pagination,
            order_by: 'name',
            direction: 'asc',
        });
    }
}
