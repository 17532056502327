// @ts-nocheck

import { Moment } from 'moment-timezone';
import { KpiData, KpiTypeData } from './interfaces';
import { KpiType } from './kpi-type';

export class Kpi implements KpiData {
    kpi_type?: KpiType;
    kpi_type_id: number;
    customer_id: number;
    value: number;
    business_date: Moment;
    _business_dates: string[];
    _dates: string[];
    id: number | string;
    created_at: Moment | null;
    updated_at: Moment | null;

    constructor(data: KpiData, kpiType: KpiTypeData = null) {
        Object.assign(this, data);

        if (kpiType) {
            this.kpi_type = new KpiType(kpiType);
        } else if (data.kpi_type) {
            this.kpi_type = new KpiType(data.kpi_type);
        }
    }

    get decimals() {
        return this.kpi_type?.decimals || 0;
    }
}
