import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { DateTime } from 'luxon';
import { WorkflowRunResponse } from '../models/workflow-run';
import { FileDownloadService } from '../services/file-download.service';
import { IGNORE_ERROR } from './http-contexts';

@Injectable({
    providedIn: 'root',
})
export class DocumentServiceService {
    private readonly revision = 2; // TODO: Don't hardcode this; but as of 22.02.2025 document service requires revision 2

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(FileDownloadService) private fileDownload: FileDownloadService,
    ) {
    }

    createVerification(month: DateTime, customerId: number, employeeId: number, workflowId: string, fileTypeId: number, userId: number) {
        return this.http.post<WorkflowRunResponse>(`/document-service/1.0.0/customers/${customerId}/workflows/${workflowId}/runs`, {
            revision: 3,
            input: {
                start_date: month.startOf('month').toISO(),
                end_date: month.endOf('month').toISO(),
                customer_id: customerId,
                employee_id: employeeId,
                file_type_id: fileTypeId,
                user_id: userId,
            },
        });
    }

    getAllRuns(customerId: number, workflowId: string, prefix: string) {
        return this.http.get<WorkflowRunResponse[]>(`/document-service/1.0.0/customers/${customerId}/workflows/${workflowId}/runs?run_identifier_prefix=${prefix}`);
    }

    signDocument(
        customerId: number,
        employeeId: number,
        workflowId: string,
        runId: string,
        stepId: string,
        signatory: string,
        timezone: string,
        signature: string,
        revision: number,
        initials?: string,
    ) {
        return this.http.post<WorkflowRunResponse>(`/document-service/1.0.0/customers/${customerId}/employees/${employeeId}/workflows/${workflowId}/runs/${runId}/steps/${stepId}`, {
            signature,
            signatory,
            initials,
            timezone,
            revision,
        });
    }

    rejectDocument(customerId: number, employeeId: number, workflowId: string, runId: string, stepId: string, comment: string) {
        return this.http.post<WorkflowRunResponse>(`/document-service/1.0.0/customers/${customerId}/employees/${employeeId}/workflows/${workflowId}/runs/${runId}/steps/${stepId}`, {
            rejected: true,
            comment,
        });
    }

    uploadTaskDocument(
        result: string,
        customerId: number,
        employeeId: number,
        employeeUserId: number,
        workflowId: string,
        runId: string,
        currentRunStepId: string,
        name: string,
        originalName: string,
        revision: number,
    ) {
        return this.http.post<WorkflowRunResponse>(
            `/document-service/1.0.0/customers/${customerId}/employees/${employeeId}/workflows/${workflowId}/runs/${runId}/steps/${currentRunStepId}`, {
                revision,
                document_name: name,
                document_original_file_name: originalName,
                document: result,
                user_id: employeeUserId,
            });
    }

    uploadHrDocument(
        result: string,
        customerId: number,
        employeeId: number,
        userId: number,
        workflowId: string,
        fileTypeId: number,
        name: string,
        originalName: string,
        managerUserId?: number,
        initials?: boolean,
    ) {
        return this.http.post<WorkflowRunResponse>(`/document-service/1.0.0/customers/${customerId}/workflows/${workflowId}/runs`, {
            revision: this.revision,
            input: {
                document_name: name,
                document_original_file_name: originalName,
                document: result,
                user_id: userId,
                manager_user_id: managerUserId,
                file_type_id: fileTypeId,
                customer_id: customerId,
                employee_id: employeeId,
                are_initials_required: initials,
            },
        });
    }

    createEditTimepunchSlipDocument(
        customerId: number,
        employeeId: number,
        userId: number,
        timepunchId: number,
        managerFullName: string,
        comment?: string | null,
        timepunchNewIn?: DateTime | null,
        timepunchNewOut?: DateTime | null,
        timepunchOriginalIn?: DateTime | null,
        timepunchOriginalOut?: DateTime | null,
    ) {
        return this.http.post<WorkflowRunResponse>(`/document-service/1.0.0/customers/${customerId}/workflows/f4950f63-c22d-4e30-845f-98b367b916a4/runs`, {
            revision: this.revision,
            input: {
                customer_id: customerId,
                employee_id: employeeId,
                user_id: userId,
                timepunch_id: timepunchId,
                timepunch_original_in: timepunchOriginalIn || timepunchNewIn,
                timepunch_original_out: timepunchOriginalOut || timepunchNewOut,
                timepunch_new_in: timepunchNewIn,
                timepunch_new_out: timepunchNewOut,
                manager_full_name: managerFullName,
                comment: comment || '',
                are_initials_required: false,
            },
        },{
            context: new HttpContext().set(IGNORE_ERROR, true),
        });
    }

    createNewTimepunchSlipDocument(
        customerId: number,
        employeeId: number,
        userId: number,
        timepunchId: number,
        managerFullName: string,
        comment?: string | null,
        timepunchNewIn?: DateTime | null,
        timepunchNewOut?: DateTime | null,
    ) {
        return this.http.post<WorkflowRunResponse>(`/document-service/1.0.0/customers/${customerId}/workflows/cfd7d88e-7545-4506-b956-c27806ba6832/runs`, {
            revision: 1,
            input: {
                customer_id: customerId,
                employee_id: employeeId,
                user_id: userId,
                timepunch_id: timepunchId,
                timepunch_new_in: timepunchNewIn,
                timepunch_new_out: timepunchNewOut,
                manager_full_name: managerFullName,
                comment: comment || '',
                are_initials_required: false,
            },
        },{
            context: new HttpContext().set(IGNORE_ERROR, true),
        });
    }

    downloadTimepunchSlipDocument(
        customerId: number,
        employeeId: number,
        workflowId: string,
        runId: string,
        lastDocument: string,
    ) {
        return this.fileDownload.download(
            `/document-service/1.0.0/customers/${customerId}/employees/${employeeId}/workflows/${workflowId}/runs/${runId}/documents/${lastDocument}`,
            lastDocument,
        );
    }

    requestDocument(customerId: number, employeeId: number, fileTypeId: number) {
        return this.http.post<WorkflowRunResponse>(`/document-service/1.0.0/customers/${customerId}/workflows/85ddbcdc-781b-4544-915e-ef2a68e30ab1/runs`, {
            revision: 1,
            input: {
                customer_id: customerId,
                employee_id: employeeId,
                file_type_id: fileTypeId,
            },
        });
    }
}
