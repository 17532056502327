// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

const template1 = `<md-dialog>
    <form role="form" autocomplete="off" name="addTariffForm" ng-submit="ctrl.create()">
        <eaw-dialog-header-old heading="'New tariff'"></eaw-dialog-header-old>
        <md-dialog-content>
            <div class="md-dialog-content">
                <md-input-container>
                    <label ng-i18next="NAME"></label>
                    <input ng-model="ctrl.tariff.name" required>
                </md-input-container>

                <md-input-container flex>
                    <label ng-i18next="TYPE"></label>
                    <md-select ng-model="ctrl.tariff.type" required>
                        <md-option ng-repeat="type in ctrl.types" ng-value="type.value">
                            <span ng-bind="type.label"></span>
                        </md-option>
                    </md-select>
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button type="submit" ng-i18next="SUBMIT" ng-disabled="addTariffForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.admin').controller('tariffCtrl', [ 'TariffFactory', 'settingGroup', 'deleteItemAlert', '$mdDialog', 'ToastService', function(TariffFactory, settingGroup, deleteItemAlert, $mdDialog, ToastService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.settingGroup = settingGroup;
        ctrl.tableArgs = {
            setting_group: ctrl.settingGroup,
        };
        ctrl.types = [
            {
                label: t('HOUR'),
                value: 'hourly',
            },
            {
                label: t('MONTH'),
                value: 'monthly',
            },
            {
                label: t('EXTRA'),
                value: 'extra',
            },
        ];

        ctrl.table = {
            columns: [
                {
                    title: t('ID'),
                    sortable: 'id',
                },
                {
                    title: t('NAME'),
                    sortable: 'name',
                },
                {
                    title: t('TYPE'),
                    sortable: 'type',
                },
                {
                    title: t('AMOUNT'),
                    sortable: 'rates_count',
                    class: 'text-right',
                },
                { title: '' },
            ],
            cells: [
                { template: 'item.id' },
                { template: 'item.name' },
                {
                    select: {
                        options: ctrl.types,
                        label: 'label',
                        value: 'value',
                        modelValue: 'type',
                        onChange: ctrl.onTypeChange,
                    },
                },
                {
                    template: 'item.rates_count',
                    class: 'text-right',
                },
                {
                    buttons: [
                        {
                            icon: 'edit',
                            click: ctrl.update,
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            click: ctrl.delete,
                            invisible: (tariff) => tariff.rates_count > 0,
                        },
                    ],
                },
            ],
        };
    };

    ctrl.onTypeChange = (item) => {
        TariffFactory.update({
            setting_group: settingGroup,
            tariff: item,
        }).$promise.then(() => {
            ctrl.reloadTable = true;
        });
    };

    ctrl.new = (tariff) => {
        $mdDialog.show({
            template: template1,
            controllerAs: 'ctrl',
            controller: function() {
                const dctrl = this;

                dctrl.$onInit = () => {
                    dctrl.tariff = { ...tariff };
                    dctrl.types = ctrl.types;

                    dctrl.cancel = $mdDialog.cancel;
                };

                dctrl.create = () => {
                    $mdDialog.hide();

                    TariffFactory.create({
                        setting_group: settingGroup,
                        name: dctrl.tariff.name,
                        type: dctrl.tariff.type,
                    }).$promise.then(() => {
                        ToastService.toast(t('CREATED'));
                        ctrl.reloadTable = true;
                    });
                };
            },
        });
    };

    ctrl.update = (tariff) => {
        const tar = { ...tariff };

        $mdDialog.show(
            $mdDialog.prompt()
                .title(t('EDIT'))
                .placeholder(tar.name)
                .initialValue(tar.name)
                .required(true)
                .ok(t('UPDATE'))
                .cancel(t('CANCEL')),
        ).then((newName) => {
            tar.name = newName;

            TariffFactory.update({
                setting_group: settingGroup,
                tariff: tar,
            }).$promise.then(() => {
                ToastService.toast(t('UPDATED'));
                ctrl.reloadTable = true;
            });
        });
    };

    ctrl.delete = (tariff) => {
        deleteItemAlert.alertSimple().then(() => {
            TariffFactory.delete(tariff).$promise.then(() => {
                ToastService.toast(t('DELETED'));
                ctrl.reloadTable = true;
            });
        });
    };
} ]);
