// @ts-nocheck
import { module } from 'angular';

module('eaw.admin').controller('updateMlBucketDialog', [ 'mlBucketFactory', '$mdDialog', function updateMlBucketDialog(mlBucketFactory, $mdDialog) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.tags = { ...ctrl.bucket.tags };
    };

    ctrl.submit = () => {
        mlBucketFactory.update.query(ctrl.customer.id, ctrl.bucket.uuid, {
            tags: ctrl.tags,
        }).then(() => {
            $mdDialog.hide();
        });
    };
} ]);
