import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Observable, switchMap } from 'rxjs';
import { Availability } from '../../models/availability';
import { EmployeeService } from '../../../shared/http/employee.service';
import { Employee } from '../../../shared/models/employee';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { AvailabilityDaysComponent } from '../../components/availability-days/availability-days.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface AvailabilityDaysDialogData extends DialogData {
    customerId: number;
    availability: Observable<Availability>;
}

@Component({
    selector: 'eaw-availability-days-dialog',
    templateUrl: './availability-days-dialog.component.html',
    styleUrl: './availability-days-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        AvailabilityDaysComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AvailabilityDaysDialogComponent extends DialogComponent implements OnInit {
    protected availability?: Availability;
    protected employee?: Employee;
    protected loading = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AvailabilityDaysDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AvailabilityDaysDialogComponent>,
        @Inject(EmployeeService) private employeeService: EmployeeService,
    ) {
        data.size ||= DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit(): void {
        this.data.availability.pipe(
            switchMap((availability) => {
                this.availability = availability;
                return this.employeeService.get(this.data.customerId, availability.employeeId);
            }),
        ).subscribe((employee) => {
            this.employee = employee;
            this.loading = false;
        });
    }
}
