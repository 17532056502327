// @ts-nocheck
import { module } from 'angular';

module('eaw.dialogs').controller('SelectPermissionSetDialogCtrl', [ '$mdDialog', 'PermissionSets', function SelectPermissionSetDialogCtrl($mdDialog, PermissionSets) {
    const ctrl = this;

    // Start by getting all permissionsets
    ctrl.loadingPermissionsets = true;
    PermissionSets.getAll.query({ per_page: 9999 }).$promise.then((data) => {
        ctrl.permissionsets = data.data;
        ctrl.loadingPermissionsets = false;
    });

    ctrl.getPermissionsets = (filter) => ctrl.permissionsets.filter((p) => p.name.toLowerCase().includes(filter.toLowerCase())).slice(0, 20);

    ctrl.returnPermissionset = () => {
        $mdDialog.hide(ctrl.permissionset);
    };

    ctrl.cancel = $mdDialog.cancel;
} ]);
