import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { ReportRun } from '../../models/report-run';
import { RunService } from '../../http/run.service';
import { Mobile } from '../../../shared/utils/eaw-mobile';
import { CurrentService } from '../../../shared/services/current.service';
import { ReportService } from '../../http/report.service';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '../../../shared/services/translate.service';
import { ReportOption } from '../../interfaces/report-option';
import { DateTime } from 'luxon';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { WebsocketService } from '../../../shared/services/websocket.service';
import { isNamespace, Namespace, NamespaceFile } from '../../../shared/enums/namespace';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe, SlicePipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export type DownloadReportRunDialogReturn = 're-run';

export interface DownloadReportRunDialogData extends DialogData {
    report: Report,
    run: ReportRun,
}

type OptionListItem = {key: string, items: (string | number | boolean)[], slice?: number};

@Component({
    selector: 'eaw-download-report',
    templateUrl: './download-report.component.html',
    styleUrl: './download-report.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        NgFor,
        MatDialogActions,
        MatDialogClose,
        AsyncPipe,
        SlicePipe,
        TranslatePipe,
    ],
})
export class DownloadReportComponent extends DialogComponent implements OnInit {
    url?: string;
    isMobile = Mobile.isMobile;
    reportChannel = `customers.${this.current.getCustomer().id}.reports.${this.data.report.id}`;
    ready = false;
    runOptions: OptionListItem[] = [];
    defaultSlice = 5;
    error?: string;

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<DownloadReportComponent, DownloadReportRunDialogReturn>,
        @Inject(MAT_DIALOG_DATA) override data: DownloadReportRunDialogData,
        @Inject(RunService) private runService: RunService,
        @Inject(TranslateService) private translateService: TranslateService,
        @Inject(ReportService) private reportService: ReportService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(WebsocketService) private websocket: WebsocketService,
        @Inject(DestroyRef) private destroyRef: DestroyRef,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit(): void {
        this.ready = !!this.data.run.readyAt;

        this.websocket.listenReport(this.current.getCustomer().id, this.data.report.id, 'report_run_done', (e) => {
            if (e.run === this.data.run.id) {
                this.error = e.error;

                if (!e.error) {
                    this.getUrl();
                }
            }
        }, this.destroyRef);

        if (this.ready) {
            this.getUrl();
        }

        try {
            void this.getOptions();
        } catch (e) {
            console.error(e);
        }
    }

    getUrl() {
        this.runService.getUrl(this.data.report.ownerId, this.data.report.id, this.data.run.id).subscribe((url) => {
            this.url = url;
            this.ready = true;
        });
    }

    download(url: string) {
        Mobile.openBrowser(url);
    }

    generateAgain() {
        this.runService.reRun(this.data.report.ownerId, this.data.run).subscribe(() => {
            this.dialogRef.close('re-run');
        });
    }

    async getOptions() {
        const runOptions = this.data.run.options;
        const report = await lastValueFrom(this.reportService.get(this.data.report.ownerId, this.data.report.id, {
            from: this.data.run.from,
            to: this.data.run.to,
        }));

        let format: string = (this.data.report?.formats[this.data.run.format] || '') as string;
        const [ ns, k ] = format.split('.');

        format = k && ns ? await this.translateService.t(k, ns as NamespaceFile) : await this.translateService.t(format);

        this.runOptions.push({
            key: 'FORMAT',
            items: [ format || '' ],
        });
        this.runOptions.push({
            key: 'STARTED_BY',
            items: [ this.data.run.user?.name || '' ],
        });
        this.runOptions.push({
            key: 'FROM',
            items: [ this.data.run.from.toLocaleString(DateTime.DATETIME_MED) ],
        });
        this.runOptions.push({
            key: 'TO',
            items: [ this.data.run.to.toLocaleString(DateTime.DATETIME_MED) ],
        });

        for (const [ key, reportOption ] of report.options?.entries() || []) {
            const runValue = runOptions[key];

            if (reportOption.type === 'boolean') {
                this.runOptions.push(await this.getBooleanValue(key, runValue, reportOption));
            }

            if (reportOption.type === 'select' && !reportOption.multiple) {
                this.runOptions.push(await this.getSingleSelectValue(key, runValue, reportOption));
            }

            if (reportOption.type === 'select' && reportOption.multiple) {
                this.runOptions.push(await this.getMultipleSelectValue(key, runValue, reportOption));
            }

            if (reportOption.type === 'employee-select' && reportOption.multiple) {
                this.runOptions.push(await this.getMultipleEmployeeValue(key, runValue, reportOption));
            }
        }

        // Add default slice if none is provided already
        this.runOptions.forEach((option) => {
            option.slice ||= this.defaultSlice;
        });
    }

    log(key: string, value: any, reportOption?: ReportOption) {
        console.group(key);
        console.log(`Report option`, reportOption);
        console.log(`Value`, value);
        console.groupEnd();
    }

    async getBooleanValue(key: string, value: any, reportOption?: ReportOption): Promise<OptionListItem> {
        this.log(key, value, reportOption);
        return {
            key,
            items: [ value ? await this.translateService.t('YES') : await this.translateService.t('NO') ],
        };
    }

    async getSingleSelectValue(key: string, value: any, reportOption?: ReportOption): Promise<OptionListItem> {
        this.log(key, value, reportOption);
        const selectedOption = reportOption?.options[value || reportOption?.default];
        let translation: string;
        if (selectedOption) {
            const [ key, ns ] = selectedOption.split('.').reverse();
            translation = ns && isNamespace(ns) ? await this.translateService.t(key, ns) : await this.translateService.t(selectedOption.toUpperCase(), Namespace.Reports);
        } else {
            translation = '';
        }
        return {
            key,
            items: [ translation ],
        };
    }

    async getMultipleSelectValue(key: string, value?: any[], reportOption?: ReportOption): Promise<OptionListItem> {
        this.log(key, value, reportOption);

        /* If no value was selected then it defaults to _all_ values */
        const selectedOptions = value?.length ? value : Object.keys(reportOption?.options || {});

        const translations: string[] = [];
        for (const selectedOption of selectedOptions || []) {
            const [ key, ns ] = reportOption?.options[selectedOption].split('.').reverse();
            translations.push(await this.translateService.t(key, ns || 'reports'));
        }

        return {
            key,
            items: sort(translations, this.current.languageTag, [ (t) => t ], [ 'asc' ]),
        };
    }

    async getMultipleEmployeeValue(key: string, value?: any[], reportOption?: ReportOption): Promise<OptionListItem> {
        this.log(key, value, reportOption);

        const employees: string[] = [];
        Object.values((reportOption?.options || {}) as Record<number, Record<number, string>>).forEach((location) => {
            Object.entries(location).forEach(([ employeeId, employeeName ]) => {
                if (!value?.length || value?.includes(parseInt(employeeId))) {
                    employees.push(employeeName);
                }
            });
        });

        return {
            key,
            items: sort(employees, this.current.languageTag, [ (e) => e ], [ 'asc' ]),
        };
    }
}
