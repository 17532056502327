import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { Shift } from '../../models/shift';
import { of } from 'rxjs';
import { ShiftService } from '../../http/shift.service';
import { DateTime } from 'luxon';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Employee } from '../../../shared/models/employee';
import { ShiftChange, ShiftChangeType } from '../../models/shift-change';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { MatDialog } from '@angular/material/dialog';
import { ShiftChangelogComponentData, ShiftChangelogDialogComponent } from '../../dialogs/shift-changelog-dialog/shift-changelog-dialog.component';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-schedule-shift-changes',
    templateUrl: './schedule-shift-changes.component.html',
    styleUrl: './schedule-shift-changes.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        NgIf,
        AutocompleteComponent,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatButtonModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ScheduleShiftChangesComponent implements OnInit, EawDataTable {
    @ViewChild(DataTableComponent) table?: DataTableComponent<Shift>;

    @Input() customerId!: number;
    @Input() employeeId?: number;

    changeTypes = Object.keys(ShiftChangeType).map((x) => {
        const key = x as keyof typeof ShiftChangeType;
        const item = new ShiftChange(ShiftChangeType[key]);

        return {
            value: item.type,
            text: item.getText(this.translate),
        };
    });

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    formGroup = new FormGroup({
        employee: new FormControl<Employee | null>(null),
        changes: new FormControl<ShiftChangeType[]>([], { nonNullable: true }),
        dateRange: new FormGroup({
            from: new FormControl<DateTime | null>(DateTime.now().minus({ week: 1 }).startOf('day'), Validators.required),
            to: new FormControl<DateTime | null>(DateTime.now().endOf('day'), Validators.required),
        }),
    });

    columns: DataTableColumnType<Shift>[] = [
        new DataTableTextColumn({
            key: 'changes',
            value: async (cell) => {
                const strings: string[] = [];

                for (const change of cell.item.changes || []) {
                    strings.push((await change.getText(this.translate)));
                }

                return strings.join('\n');
            },
            header: new DataTableHeader({ i18n: 'CHANGE' }),
        }),
        new DataTableTextColumn({
            key: 'employee',
            value: async (cell) => cell.item.employee?.name || '',
            header: new DataTableHeader({ i18n: 'EMPLOYEE' }),
        }),
        new DataTableTextColumn({
            value: async (cell) => cell.item.editedByUser?.name || '',
            header: new DataTableHeader({
                i18n: 'CHANGED_BY',
                ns: 'scheduling',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'deletedAt',
            format: DateTime.DATETIME_MED,
            header: new DataTableHeader({
                i18n: 'EDITED',
                sortBy: 'deleted_at',
            }),
        }),
    ];

    constructor(
        @Inject(ShiftService) private shiftService: ShiftService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
    ) {
    }

    ngOnInit(): void {
        if (!this.customerId) {
            throw new Error('Missing customer ID');
        }

        if (this.employeeId) {
            // Remove employee column
            this.columns = this.columns.filter((c) => c.key !== 'employee');
        }
    }

    update() {
        this.updateTable(this.table?.getPagination({ page: 1 }) || {});
    }

    openDialog(shift: Shift) {
        const originalId = shift.properties?.find((p) => p.key === 'original_id')?.value.asInteger();
        if (!originalId) {
            return;
        }

        this.matDialog.open<ShiftChangelogDialogComponent, ShiftChangelogComponentData>(ShiftChangelogDialogComponent, {
            data: {
                customerId: this.customerId,
                shiftId: originalId,
            },
        });
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        const changes: ShiftChangeType[] = this.formGroup.controls.changes.value;

        this.request = this.shiftService.getAll(this.customerId, {
            ...pagination,
            order_by: pagination.order_by || 'deleted_at',
            changed: true,
            'changes[]': changes,
            from: this.formGroup.controls.dateRange.controls.from.value,
            to: this.formGroup.controls.dateRange.controls.to.value,
            employee_id: this.employeeId || this.formGroup.controls.employee.value?.id,
            'with[]': [ 'employee', 'comments', 'editedByUser', 'properties' ],
        });
    }
}
