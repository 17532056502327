import 'zone.js';
import { EnvironmentInjector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { httpInterceptorProviders } from './shared/interceptors';
import { UrlService } from '@uirouter/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { DateAdapter } from '@angular/material/core';
import { CurrentService } from './shared/services/current.service';
import { CustomDateAdapter } from './shared/utils/custom-date-adapter';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntlService } from './shared/services/custom-mat-paginator-intl.service';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { BadgerService } from './shared/services/badger.service';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { VersioningService } from './shared/services/versioning.service';
import { TranslationCheckService } from './shared/services/translation-check.service';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { StateClassHookService } from './shared/transition-hooks/state-class-hook.service';
import { FullscreenHookService } from './shared/transition-hooks/fullscreen-hook.service';
import { PageTransitionHookService } from './shared/transition-hooks/page-transition-hook.service';
import { BlurElementHookService } from './shared/transition-hooks/blur-element-hook.service';
import { NoAccessHookService } from './shared/transition-hooks/no-access-hook.service';
import { RequiresEmployeeHookService } from './shared/transition-hooks/requires-employee-hook.service';
import { LoadNamespacesHookService } from './shared/transition-hooks/load-namespaces-hook.service';
import { UnsavedChangesHookService } from './shared/transition-hooks/unsaved-changes-hook.service';
import { PermissionsHookService } from './shared/transition-hooks/permissions-hook.service';
import { ProductsHookService } from './shared/transition-hooks/products-hook.service';
import { DebugHookService } from './shared/transition-hooks/debug-hook.service';
import { ErrorHandlingHookService } from './shared/transition-hooks/error-handling-hook.service';
import { ScheduleLoadingHookService } from './scheduling/transition-hooks/schedule-loading-hook.service';
import { TasksHookService } from './tasks/transition-hooks/tasks-hook.service';
import { VerifyEmailHookService } from './login/transition-hooks/verify-email-hook.service';
import { NotificationsHooksService } from './shared/transition-hooks/notifications-hooks.service';
import { Mobile } from './shared/utils/eaw-mobile';
import { PushService } from './shared/services/push.service';
import { RequireLoginHookService } from './shared/transition-hooks/require-login-hook.service';
import { SplashHookService } from './shared/transition-hooks/splash-hook.service';
import { LoginService } from './shared/services/login.service';
import { addCompanySettingsSidenav } from './shared/sidenavs/company-settings-sidenav';
import { addLocalEmployeeInfoSidenav } from './shared/sidenavs/local-employee-info-sidenav';
import { addAdminEmployeeInfoSidenav } from './shared/sidenavs/admin-employee-info-sidenav';
import { addChainOpsEmployeeInfoSidenav } from './shared/sidenavs/chain-ops-employee-info-sidenav';
import { addProfileSidenav } from './shared/sidenavs/profile-sidenav';
import { LanguageLoaderService } from './initializer/services/language-loader.service';
import { SettingsHookService } from './shared/transition-hooks/settings-hook.service';
import { InitializerModule } from './initializer/initializer.module';
import { AuthModule } from './auth/auth.module';
import { WelcomeHookService } from './shared/transition-hooks/welcome-hook.service';
import { QueryParamsHookService } from './shared/transition-hooks/query-params-hook.service';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';

// Do bootstrap
@NgModule({
    imports: [ BrowserModule,
        UpgradeModule,
        UIRouterUpgradeModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        MatLuxonDateModule,
        AuthModule,
        InitializerModule,
    ],
    providers: [
        httpInterceptorProviders,
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntlService,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' },
        },
        {
            provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
            useValue: {
                autoActiveFirstOption: true,
                autoSelectActiveOption: true,
            },
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 3000 },
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { hasBackdrop: true },
        },
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { clickAction: 'noop' },
        },
        {
            provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
            useValue: {
                diameter: 50,
                strokeWidth: 5,
            },
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                position: 'above',
                showDelay: 500,
            },
        },
        {
            provide: MAT_CARD_CONFIG,
            useValue: { appearance: 'raised' },
        },
        {
            // This is needed for the language loader service
            provide: '$mdDateLocale',
            useFactory: ($injector: any) => $injector.get('$mdDateLocale'),
            deps: [ '$injector' ],
        },
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    ],
})
export class AppModule {
    constructor() {
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @angular-eslint/use-lifecycle-interface
    ngDoBootstrap() { // This is 100% required
        // Do nothing
    }
}

export class EawSearchParam {
    key: string;
    value: string;

    constructor(key: string, value: string | number | null | undefined) {
        this.key = key;
        this.value = value?.toString() ?? '';
    }
}

function activateUrlService(injector: EnvironmentInjector) {
    // Hooks have to come before the url is started and synced
    const urlService = injector.get(UrlService);

    // Instruct UIRouter to listen to URL changes
    urlService.listen();
    urlService.sync();
}

function activatePush(injector: EnvironmentInjector) {
    const loginService = injector.get(LoginService);

    if (!(Mobile.isMobile && loginService.isLoggedIn())) {
        return;
    }

    const pushService = injector.get(PushService);
    const current = injector.get(CurrentService);

    if (current.getMe() && !current.getMe().isActing) {
        void pushService.register(current.getUser().id);
    }
}

function addTransitionHooks(injector: EnvironmentInjector) {
    injector.get(BlurElementHookService);
    injector.get(DebugHookService);
    injector.get(ErrorHandlingHookService);
    injector.get(FullscreenHookService);
    injector.get(LoadNamespacesHookService);
    injector.get(NoAccessHookService);
    injector.get(PageTransitionHookService);
    injector.get(PermissionsHookService);
    // injector.get(PermissionLoaderHookService); Add back once the dashboard is updated
    injector.get(ProductsHookService);
    injector.get(QueryParamsHookService);
    injector.get(RequiresEmployeeHookService);
    injector.get(StateClassHookService);
    injector.get(UnsavedChangesHookService);
    injector.get(ScheduleLoadingHookService);
    injector.get(TasksHookService);
    injector.get(VerifyEmailHookService);
    injector.get(SettingsHookService);
    injector.get(SplashHookService);
    injector.get(NotificationsHooksService);
    injector.get(RequireLoginHookService);
    injector.get(WelcomeHookService);
}

function addSidenavs(injector: EnvironmentInjector) {
    addCompanySettingsSidenav(injector);
    addLocalEmployeeInfoSidenav(injector);
    addAdminEmployeeInfoSidenav(injector);
    addChainOpsEmployeeInfoSidenav(injector);
    addProfileSidenav(injector);
}

platformBrowserDynamic().bootstrapModule(AppModule).then(async (platformRef) => {
    console.log('🌨️ Bootstrapping both Angular and AngularJS');

    // Do the bootstrapping for AngularJS
    const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
    upgrade.bootstrap(document.body, [ 'eaw' ], { strictDi: true });

    // //////////////////////////////////////////// ⭐
    // After this point, AngularJS is bootstrapped
    // //////////////////////////////////////////// ⭐

    // Firstly load language and handle locales and things.
    // Has to be done after AngularJS is bootstrapped because we rely on an AngularJS service.
    await platformRef.injector.get(LanguageLoaderService).init();

    // Inject other important services
    platformRef.injector.get(TranslationCheckService);
    platformRef.injector.get(CurrentService);
    platformRef.injector.get(BadgerService);
    platformRef.injector.get(VersioningService);

    // Transition hooks 🪝
    addTransitionHooks(platformRef.injector);

    // Add our different sidenavs
    addSidenavs(platformRef.injector);

    // Other
    activateUrlService(platformRef.injector);
    activatePush(platformRef.injector);

}).catch((e) => {
    console.error('😩', e);
});
