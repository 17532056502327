import { find } from 'lodash-es';
import { Model } from '../../../shared/angularjs/model';

export class HrFileTypeOld extends Model {
    static signables: any[];
    getSignable() {
        // @ts-ignore
        return find(this.constructor.signables, (signable) => signable?.filter?.type_id == this.id);
    }

    isSignable() {
        return !!this.getSignable();
    }

    static setSignables(signables: any) {
        this.signables = signables;
    }

    static override getMorphClass() {
        return 'hr_file_type';
    }
}
