// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { AddUserDialog } from '../../../shared/angularjs/modules/dialogs/add-user-user-group/add-user.ctrl';
const template1 = `<md-dialog id="add-user-dialog">
    <eaw-dialog-header-old heading="'admin:ADD_USER_TO_GROUP' | i18next"></eaw-dialog-header-old>

    <md-dialog-content>
        <md-content class="md-padding">
            <form name="userForm" layout="column">
                <md-autocomplete
                        ng-disabled="auCtrl.disableUserGroupSelect"
                        md-floating-label="{{'USER_GROUP' | i18next}}"
                        md-no-cache="true"
                        md-clear-button="!auCtrl.disableUserGroupSelect"
                        md-selected-item="auCtrl.userGroup"
                        md-search-text="filter"
                        md-require-match="true"
                        md-items="item in auCtrl.searchGroups(filter)"
                        md-item-text="item.name"
                        md-delay="300"
                        required
                        md-input-name="userAutocomplete"
                        md-min-length="0">
                    <md-item-template>
                        <span md-highlight-text="filter" md-highlight-flags="gi">{{item.name}}</span>
                    </md-item-template>
                    <md-not-found>
                        <span ng-i18next="NO_MATCH_FOUND"></span>
                    </md-not-found>
                </md-autocomplete>

                <md-autocomplete
                        ng-disabled="auCtrl.disableUserSelect"
                        md-floating-label="{{'USER' | i18next}}"
                        md-no-cache="true"
                        md-clear-button="true"
                        md-selected-item="auCtrl.user"
                        md-search-text="usersFilter"
                        md-require-match="true"
                        md-items="item in auCtrl.searchUsers(usersFilter)"
                        md-item-text="item.name"
                        md-delay="300"
                        required
                        md-input-name="userGroupAutocomplete"
                        md-min-length="0">
                    <md-item-template>
                        <span md-highlight-text="usersFilter" md-highlight-flags="gi">{{item.name}}</span>
                    </md-item-template>
                    <md-not-found>
                        <span ng-i18next="NO_MATCH_FOUND"></span>
                    </md-not-found>
                </md-autocomplete>

                <eaw-date-time-interval ng-model="auCtrl.interval" from-required="true" min-date="auCtrl.minDate"></eaw-date-time-interval>

            </form>
        </md-content>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button form="userForm" ng-click="auCtrl.addUser(auCtrl.user)" ng-disabled="userForm.$invalid" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
export class UserGroupDialog {
    UserGroupService;
    $mdDialog;
    EditDateIntervalDialog;
    static get $inject() {
        return [ 'UserGroupService', '$mdDialog', 'EditDateIntervalDialog' ];
    }

    constructor(UserGroupService, $mdDialog, EditDateIntervalDialog) {
        this.UserGroupService = UserGroupService;
        this.$mdDialog = $mdDialog;
        this.EditDateIntervalDialog = EditDateIntervalDialog;
    }

    edit(group, user) {
        return this.EditDateIntervalDialog.open({
            from: user.pivot.from,
            to: user.pivot.to,
            title: t('USER'),
            includeTime: true,
        }).then((result) => [ this.UserGroupService.updateMember(group.owner_id, group.id, user.id, user.pivot?.id, result.from, result.to).$promise, result ]);
    }

    remove(group, user) {
        return this.$mdDialog.show(this.$mdDialog.confirm()
            .theme('delete')
            .title(t('REMOVE'))
            .textContent(t('THIS_WILL_REMOVE_X_FROM_Y', {
                name1: user.name,
                name2: group.name,
            }))
            .ok(t('REMOVE'))
            .cancel(t('CANCEL'))).then(() => this.UserGroupService.removeMember(group.owner_id, group.id, user.id, (user.pivot || group.pivot)?.id).$promise);
    }

    add(customer, group, user) {
        return this.$mdDialog.show({
            template: template1,
            controller: AddUserDialog,
            controllerAs: 'auCtrl',
            bindToController: true,
            locals: {
                userGroup: group,
                user,
                customer,
                adding: true,
            },
        });
    }
}
module('eaw.company').factory('userGroupDialog', UserGroupDialog);
