import O from "mux-embed";
import ue from "hls.js";
var f = ue;
var x = class extends Error {
    constructor(n, r = x.MEDIA_ERR_CUSTOM, o, a) {
      var s;
      super(n);
      this.name = "MediaError", this.code = r, this.context = a, this.fatal = o != null ? o : r >= x.MEDIA_ERR_NETWORK && r <= x.MEDIA_ERR_ENCRYPTED, this.message || (this.message = (s = x.defaultMessages[this.code]) != null ? s : "");
    }
  },
  g = x;
g.MEDIA_ERR_ABORTED = 1, g.MEDIA_ERR_NETWORK = 2, g.MEDIA_ERR_DECODE = 3, g.MEDIA_ERR_SRC_NOT_SUPPORTED = 4, g.MEDIA_ERR_ENCRYPTED = 5, g.MEDIA_ERR_CUSTOM = 100, g.defaultMessages = {
  1: "You aborted the media playback",
  2: "A network error caused the media download to fail.",
  3: "A media error caused playback to be aborted. The media could be corrupt or your browser does not support this format.",
  4: "An unsupported error occurred. The server or network failed, or your browser does not support this format.",
  5: "The media is encrypted and there are no keys to decrypt it."
};
var de = e => e == null,
  C = (e, t) => de(t) ? !1 : e in t,
  E = {
    ANY: "any",
    MUTED: "muted"
  },
  v = {
    ON_DEMAND: "on-demand",
    LIVE: "live",
    UNKNOWN: "unknown"
  },
  R = {
    MSE: "mse",
    NATIVE: "native"
  },
  A = {
    HEADER: "header",
    QUERY: "query",
    NONE: "none"
  },
  We = Object.values(A),
  b = {
    M3U8: "application/vnd.apple.mpegurl",
    MP4: "video/mp4"
  },
  h = {
    HLS: b.M3U8
  },
  Be = Object.keys(h),
  Ke = [...Object.values(b), "hls", "HLS"],
  qe = {
    upTo720p: "720p",
    upTo1080p: "1080p",
    upTo1440p: "1440p",
    upTo2160p: "2160p"
  },
  Ye = {
    noLessThan480p: "480p",
    noLessThan540p: "540p",
    noLessThan720p: "720p",
    noLessThan1080p: "1080p",
    noLessThan1440p: "1440p",
    noLessThan2160p: "2160p"
  },
  $e = {
    DESCENDING: "desc"
  };
var M = (e, t, n, r, o = e) => {
  o.addEventListener(t, n, r), e.addEventListener("teardown", () => {
    o.removeEventListener(t, n);
  }, {
    once: !0
  });
};
function U(e, t, n) {
  t && n > t && (n = t);
  for (let r = 0; r < e.length; r++) if (e.start(r) <= n && e.end(r) >= n) return !0;
  return !1;
}
var F = e => {
    let t = e.indexOf("?");
    if (t < 0) return [e];
    let n = e.slice(0, t),
      r = e.slice(t);
    return [n, r];
  },
  L = e => {
    let t = e.type;
    if (t) {
      let r = t.toUpperCase();
      return C(r, h) ? h[r] : t;
    }
    let {
      src: n
    } = e;
    return n ? pe(n) : "";
  },
  I = e => e === "VOD" ? v.ON_DEMAND : v.LIVE,
  D = e => e === "EVENT" ? Number.POSITIVE_INFINITY : e === "VOD" ? Number.NaN : 0,
  pe = e => {
    let t = "";
    try {
      t = new URL(e).pathname;
    } catch {
      console.error("invalid url");
    }
    let n = t.lastIndexOf(".");
    if (n < 0) return "";
    let o = t.slice(n + 1).toUpperCase();
    return C(o, b) ? b[o] : "";
  };
var le = Object.values(E),
  W = e => typeof e == "boolean" || typeof e == "string" && le.includes(e),
  B = ({
    autoplay: e
  }, t, n) => {
    let r = !1,
      o = !1,
      a = W(e) ? e : !!e,
      s = () => {
        r || M(t, "playing", () => {
          r = !0;
        }, {
          once: !0
        });
      };
    if (s(), M(t, "loadstart", () => {
      r = !1, s(), w(t, a);
    }, {
      once: !0
    }), M(t, "loadstart", () => {
      n || (o = !Number.isFinite(t.duration)), w(t, a);
    }, {
      once: !0
    }), n && n.once(f.Events.LEVEL_LOADED, (d, c) => {
      var p;
      o = (p = c.details.live) != null ? p : !1;
    }), !a) {
      let d = () => {
        !o || (n != null && n.liveSyncPosition ? t.currentTime = n.liveSyncPosition : Number.isFinite(t.seekable.end(0)) && (t.currentTime = t.seekable.end(0)));
      };
      n && M(t, "play", () => {
        t.preload === "metadata" ? n.once(f.Events.LEVEL_UPDATED, d) : d();
      }, {
        once: !0
      });
    }
    return d => {
      r || (a = W(d) ? d : !!d, w(t, a));
    };
  },
  w = (e, t) => {
    if (!t) return;
    let n = e.muted,
      r = () => e.muted = n;
    switch (t) {
      case E.ANY:
        e.play().catch(() => {
          e.muted = !0, e.play().catch(r);
        });
        break;
      case E.MUTED:
        e.muted = !0, e.play().catch(r);
        break;
      default:
        e.play().catch(() => {});
        break;
    }
  };
var K = ({
  preload: e,
  src: t
}, n, r) => {
  let o = u => {
    u != null && ["", "none", "metadata", "auto"].includes(u) ? n.setAttribute("preload", u) : n.removeAttribute("preload");
  };
  if (!r) return o(e), o;
  let a = !1,
    s = !1,
    i = r.config.maxBufferLength,
    d = r.config.maxBufferSize,
    c = u => {
      o(u);
      let l = u != null ? u : n.preload;
      s || l === "none" || (l === "metadata" ? (r.config.maxBufferLength = 1, r.config.maxBufferSize = 1) : (r.config.maxBufferLength = i, r.config.maxBufferSize = d), p());
    },
    p = () => {
      !a && t && (a = !0, r.loadSource(t));
    };
  return M(n, "play", () => {
    s = !0, r.config.maxBufferLength = i, r.config.maxBufferSize = d, p();
  }, {
    once: !0
  }), c(e), c;
};
function q(e, t) {
  var d;
  if (!("videoTracks" in e)) return;
  let n = new WeakMap();
  t.on(f.Events.MANIFEST_PARSED, function (c, p) {
    i();
    let u = e.addVideoTrack("main");
    u.selected = !0;
    for (let [l, T] of p.levels.entries()) {
      let y = u.addRendition(T.url[0], T.width, T.height, T.videoCodec, T.bitrate);
      n.set(T, `${l}`), y.id = `${l}`;
    }
  }), t.on(f.Events.AUDIO_TRACKS_UPDATED, function (c, p) {
    s();
    for (let u of p.audioTracks) {
      let l = u.default ? "main" : "alternative",
        T = e.addAudioTrack(l, u.name, u.lang);
      T.id = `${u.id}`, u.default && (T.enabled = !0);
    }
  }), e.audioTracks.addEventListener("change", () => {
    var u;
    let c = +((u = [...e.audioTracks].find(l => l.enabled)) == null ? void 0 : u.id),
      p = t.audioTracks.map(l => l.id);
    c != t.audioTrack && p.includes(c) && (t.audioTrack = c);
  }), t.on(f.Events.LEVELS_UPDATED, function (c, p) {
    var T;
    let u = e.videoTracks[(T = e.videoTracks.selectedIndex) != null ? T : 0];
    if (!u) return;
    let l = p.levels.map(y => n.get(y));
    for (let y of e.videoRenditions) y.id && !l.includes(y.id) && u.removeRendition(y);
  });
  let r = c => {
      let p = c.target.selectedIndex;
      p != t.nextLevel && o(p);
    },
    o = c => {
      let p = e.currentTime,
        u = !1,
        l = (T, y) => {
          u || (u = !Number.isFinite(y.endOffset));
        };
      t.on(f.Events.BUFFER_FLUSHING, l), t.nextLevel = c, t.off(f.Events.BUFFER_FLUSHING, l), u || t.trigger(f.Events.BUFFER_FLUSHING, {
        startOffset: p + 10,
        endOffset: 1 / 0,
        type: "video"
      });
    };
  (d = e.videoRenditions) == null || d.addEventListener("change", r);
  let a = () => {
      for (let c of e.videoTracks) e.removeVideoTrack(c);
    },
    s = () => {
      for (let c of e.audioTracks) e.removeAudioTrack(c);
    },
    i = () => {
      a(), s();
    };
  t.once(f.Events.DESTROYING, i);
}
function Y(e, t) {
  t.on(f.Events.NON_NATIVE_TEXT_TRACKS_FOUND, (o, {
    tracks: a
  }) => {
    a.forEach(s => {
      var c;
      let i = (c = s.subtitleTrack) != null ? c : s.closedCaptions,
        d = t.subtitleTracks.findIndex(({
          lang: p,
          name: u,
          type: l
        }) => p == (i == null ? void 0 : i.lang) && u === s.label && l.toLowerCase() === s.kind);
      H(e, s.kind, s.label, i == null ? void 0 : i.lang, `${s.kind}${d}`);
    });
  });
  let n = () => {
    var s;
    if (!t.subtitleTracks.length) return;
    let o = Array.from(e.textTracks).find(i => i.id && i.mode === "showing" && ["subtitles", "captions"].includes(i.kind)),
      a = `${(s = t.subtitleTracks[t.subtitleTrack]) == null ? void 0 : s.type.toLowerCase()}${t.subtitleTrack}`;
    if (o && (t.subtitleTrack < 0 || (o == null ? void 0 : o.id) !== a)) {
      let i = t.subtitleTracks.findIndex(({
        lang: d,
        name: c,
        type: p
      }) => d == o.language && c === o.label && p.toLowerCase() === o.kind);
      t.subtitleTrack = i;
    }
    o && (o == null ? void 0 : o.id) === a && o.cues && Array.from(o.cues).forEach(i => {
      o.addCue(i);
    });
  };
  e.textTracks.addEventListener("change", n), t.on(f.Events.CUES_PARSED, (o, {
    track: a,
    cues: s
  }) => {
    let i = e.textTracks.getTrackById(a);
    if (!i) return;
    let d = i.mode === "disabled";
    d && (i.mode = "hidden"), s.forEach(c => {
      var p;
      (p = i.cues) != null && p.getCueById(c.id) || i.addCue(c);
    }), d && (i.mode = "disabled");
  }), t.once(f.Events.DESTROYING, () => {
    e.textTracks.removeEventListener("change", n), e.querySelectorAll("track[data-removeondestroy]").forEach(a => {
      a.remove();
    });
  });
  let r = () => {
    Array.from(e.textTracks).forEach(o => {
      var a, s;
      if (!["subtitles", "caption"].includes(o.kind) && o.label === "thumbnails") {
        if (!((a = o.cues) != null && a.length)) {
          let i = e.querySelector('track[label="thumbnails"]'),
            d = (s = i == null ? void 0 : i.getAttribute("src")) != null ? s : "";
          i == null || i.removeAttribute("src"), setTimeout(() => {
            i == null || i.setAttribute("src", d);
          }, 0);
        }
        o.mode !== "hidden" && (o.mode = "hidden");
      }
    });
  };
  t.once(f.Events.MANIFEST_LOADED, r), t.once(f.Events.MEDIA_ATTACHED, r);
}
function H(e, t, n, r, o) {
  let a = document.createElement("track");
  return a.kind = t, a.label = n, r && (a.srclang = r), o && (a.id = o), a.track.mode = ["subtitles", "captions"].includes(t) ? "disabled" : "hidden", a.setAttribute("data-removeondestroy", ""), e.append(a), a.track;
}
function fe(e, t) {
  let n = Array.prototype.find.call(e.querySelectorAll("track"), r => r.track === t);
  n == null || n.remove();
}
var P = "cuepoints",
  _ = Object.freeze({
    label: P
  }),
  S = (e, {
    label: t = P
  } = _) => {
    var n;
    return (n = Array.from(e.querySelectorAll("track")).find(r => r.track.label === t && r.track.kind === "metadata")) == null ? void 0 : n.track;
  };
async function $(e, t, n = _) {
  let r = S(e, n);
  if (!r) {
    let {
      label: o = P
    } = n;
    r = H(e, "metadata", o), r.mode = "hidden", await new Promise(a => setTimeout(() => a(void 0), 0));
  }
  return r.mode !== "hidden" && (r.mode = "hidden"), [...t].sort(({
    time: o
  }, {
    time: a
  }) => a - o).forEach(({
    time: o,
    value: a
  }) => {
    var u, l;
    let s = Array.prototype.findIndex.call(r == null ? void 0 : r.cues, T => T.startTime >= o),
      i = (u = r == null ? void 0 : r.cues) == null ? void 0 : u[s],
      d = i ? i.startTime : Number.isFinite(e.duration) ? e.duration : Number.MAX_SAFE_INTEGER,
      c = (l = r == null ? void 0 : r.cues) == null ? void 0 : l[s - 1];
    c && (c.endTime = o);
    let p = new VTTCue(o, d, JSON.stringify(a != null ? a : null));
    r.addCue(p);
  }), r;
}
var z = e => ({
  time: e.startTime,
  value: JSON.parse(e.text)
});
function Te(e, t = {
  label: P
}) {
  let n = S(e, t);
  return n != null && n.cues ? Array.from(n.cues, r => z(r)) : [];
}
function X(e, t = {
  label: P
}) {
  var a, s;
  let n = S(e, t);
  if (!((a = n == null ? void 0 : n.activeCues) != null && a.length)) return;
  let {
      currentTime: r
    } = e,
    o = Array.prototype.find.call((s = n.activeCues) != null ? s : [], ({
      startTime: i,
      endTime: d
    }) => i <= r && d > r);
  return z(o);
}
async function G(e, t = _) {
  return new Promise(n => {
    M(e, "loadstart", async () => {
      let r = await $(e, [], t);
      M(e, "cuechange", () => {
        let o = X(e);
        if (o) {
          let a = new CustomEvent("cuepointchange", {
            composed: !0,
            bubbles: !0,
            detail: o
          });
          e.dispatchEvent(a);
        }
      }, {}, r), n(r);
    });
  });
}
function ye(e, t) {
  if (t) {
    let n = t.playingDate;
    if (n != null) return new Date(n.getTime() - e.currentTime * 1e3);
  }
  return typeof e.getStartDate == "function" ? e.getStartDate() : new Date(NaN);
}
function me(e, t) {
  if (t && t.playingDate) return t.playingDate;
  if (typeof e.getStartDate == "function") {
    let n = e.getStartDate();
    return new Date(n.getTime() + e.currentTime * 1e3);
  }
  return new Date(NaN);
}
var ge = async e => fetch(e).then(t => t.text()).then(t => {
    let n = t.split(`
`).find((r, o, a) => o && a[o - 1].startsWith("#EXT-X-STREAM-INF"));
    return fetch(n).then(r => r.text()).then(r => r.split(`
`));
  }),
  Me = e => {
    var s, i, d;
    let n = (i = ((s = e.find(c => c.startsWith("#EXT-X-PLAYLIST-TYPE"))) != null ? s : "").split(":")[1]) == null ? void 0 : i.trim(),
      r = I(n),
      o = D(n),
      a;
    if (r === v.LIVE) {
      let c = e.find(u => u.startsWith("#EXT-X-PART-INF"));
      if (!!c) a = +c.split(":")[1].split("=")[1] * 2;else {
        let u = e.find(y => y.startsWith("#EXT-X-TARGETDURATION")),
          l = (d = u == null ? void 0 : u.split(":")) == null ? void 0 : d[1];
        a = +(l != null ? l : 6) * 3;
      }
    }
    return {
      streamType: r,
      targetLiveWindow: o,
      liveEdgeStartOffset: a
    };
  },
  ve = async (e, t) => {
    if (t === b.MP4) return {
      streamType: v.ON_DEMAND,
      targetLiveWindow: Number.NaN,
      liveEdgeStartOffset: void 0
    };
    if (t === b.M3U8) {
      let n = await ge(e);
      return Me(n);
    }
    return console.error(`Media type ${t} is an unrecognized or unsupported type for src ${e}.`), {
      streamType: void 0,
      targetLiveWindow: void 0,
      liveEdgeStartOffset: void 0
    };
  },
  J = async (e, t, n = L({
    src: e
  })) => {
    var s, i, d;
    let {
      streamType: r,
      targetLiveWindow: o,
      liveEdgeStartOffset: a
    } = await ve(e, n);
    ((s = m.get(t)) != null ? s : {}).liveEdgeStartOffset = a, ((i = m.get(t)) != null ? i : {}).targetLiveWindow = o, t.dispatchEvent(new CustomEvent("targetlivewindowchange", {
      composed: !0,
      bubbles: !0
    })), ((d = m.get(t)) != null ? d : {}).streamType = r, t.dispatchEvent(new CustomEvent("streamtypechange", {
      composed: !0,
      bubbles: !0
    }));
  },
  be = e => {
    var s;
    let t = e.type,
      n = I(t),
      r = D(t),
      o,
      a = !!((s = e.partList) != null && s.length);
    return n === v.LIVE && (o = a ? e.partTarget * 2 : e.targetduration * 3), {
      streamType: n,
      targetLiveWindow: r,
      liveEdgeStartOffset: o,
      lowLatency: a
    };
  },
  xe = (e, t, n) => {
    var i, d, c, p, u, l, T, y;
    let {
      streamType: r,
      targetLiveWindow: o,
      liveEdgeStartOffset: a,
      lowLatency: s
    } = be(e);
    if (r === v.LIVE) {
      s ? (n.config.backBufferLength = (i = n.userConfig.backBufferLength) != null ? i : 4, n.config.maxFragLookUpTolerance = (d = n.userConfig.maxFragLookUpTolerance) != null ? d : .001, n.config.abrBandWidthUpFactor = (c = n.userConfig.abrBandWidthUpFactor) != null ? c : n.config.abrBandWidthFactor) : n.config.backBufferLength = (p = n.userConfig.backBufferLength) != null ? p : 8;
      let ce = Object.freeze({
        get length() {
          return t.seekable.length;
        },
        start(k) {
          return t.seekable.start(k);
        },
        end(k) {
          var V;
          return k > this.length ? t.seekable.end(k) : (V = n.liveSyncPosition) != null ? V : t.seekable.end(k);
        }
      });
      ((u = m.get(t)) != null ? u : {}).seekable = ce;
    }
    ((l = m.get(t)) != null ? l : {}).liveEdgeStartOffset = a, ((T = m.get(t)) != null ? T : {}).targetLiveWindow = o, t.dispatchEvent(new CustomEvent("targetlivewindowchange", {
      composed: !0,
      bubbles: !0
    })), ((y = m.get(t)) != null ? y : {}).streamType = r, t.dispatchEvent(new CustomEvent("streamtypechange", {
      composed: !0,
      bubbles: !0
    }));
  },
  Q,
  j,
  ke = (j = (Q = globalThis == null ? void 0 : globalThis.navigator) == null ? void 0 : Q.userAgent) != null ? j : "",
  Le = ke.toLowerCase().indexOf("android") !== -1,
  m = new WeakMap(),
  te = "mux.com",
  Z,
  ee,
  ne = (ee = (Z = f).isSupported) == null ? void 0 : ee.call(Z),
  Pe = Le,
  Pt = () => O.utils.now(),
  Ee = O.utils.generateUUID,
  Et = ({
    playbackId: e,
    customDomain: t = te,
    maxResolution: n,
    minResolution: r,
    renditionOrder: o,
    tokens: {
      playback: a
    } = {},
    extraPlaylistParams: s = {}
  } = {}) => {
    if (!e) return;
    let [i, d = ""] = F(e),
      c = new URL(`https://stream.${t}/${i}.m3u8${d}`);
    return a || c.searchParams.has("token") ? (c.searchParams.forEach((p, u) => {
      u != "token" && c.searchParams.delete(u);
    }), a && c.searchParams.set("token", a)) : (n && c.searchParams.set("max_resolution", n), r && (c.searchParams.set("min_resolution", r), n && +n.slice(0, -1) < +r.slice(0, -1) && console.error("minResolution must be <= maxResolution", "minResolution", r, "maxResolution", n)), o && c.searchParams.set("rendition_order", o), Object.entries(s).forEach(([p, u]) => {
      u != null && c.searchParams.set(p, u);
    })), c.toString();
  },
  Ae = e => {
    if (!e) return;
    let [t] = e.split("?");
    return t || void 0;
  },
  he = e => {
    if (!e || !e.startsWith("https://stream.")) return;
    let [t] = new URL(e).pathname.slice(1).split(".m3u8");
    return t || void 0;
  },
  Se = e => {
    var t, n, r;
    return (t = e == null ? void 0 : e.metadata) != null && t.video_id ? e.metadata.video_id : ae(e) && (r = (n = Ae(e.playbackId)) != null ? n : he(e.src)) != null ? r : e.src;
  },
  At = e => {
    var t;
    return (t = m.get(e)) == null ? void 0 : t.error;
  },
  ht = e => {
    var t, n;
    return (n = (t = m.get(e)) == null ? void 0 : t.streamType) != null ? n : v.UNKNOWN;
  },
  St = e => {
    var t, n;
    return (n = (t = m.get(e)) == null ? void 0 : t.targetLiveWindow) != null ? n : Number.NaN;
  },
  Ce = e => {
    var t, n;
    return (n = (t = m.get(e)) == null ? void 0 : t.seekable) != null ? n : e.seekable;
  },
  Ct = e => {
    var r;
    let t = (r = m.get(e)) == null ? void 0 : r.liveEdgeStartOffset;
    if (typeof t != "number") return Number.NaN;
    let n = Ce(e);
    return n.length ? n.end(n.length - 1) - t : Number.NaN;
  },
  Re = (e, t, n = .001) => Math.abs(e - t) <= n,
  re = (e, t, n = .001) => e > t || Re(e, t, n),
  Ie = e => e.paused && re(e.currentTime, e.duration),
  De = (e, t) => e.loop || !!t ? e.ended : e.ended || Ie(e),
  Rt = (e, t, n) => {
    we(t, n);
    let {
        metadata: r = {}
      } = e,
      {
        view_session_id: o = Ee()
      } = r,
      a = Se(e);
    r.view_session_id = o, r.video_id = a, e.metadata = r, m.set(t, {});
    let s = He(e, t);
    Ne(e, t, s), Oe(e, t, s), G(t);
    let i = B(e, t, s),
      d = K(e, t, s);
    return {
      engine: s,
      setAutoplay: i,
      setPreload: d
    };
  },
  we = (e, t) => {
    let n = t == null ? void 0 : t.engine;
    n && (n.detachMedia(), n.destroy()), (e == null ? void 0 : e.mux) && !e.mux.deleted && (e.mux.destroy(), delete e.mux), e && (e.removeAttribute("src"), e.load(), e.removeEventListener("error", se), e.removeEventListener("error", N), e.removeEventListener("durationchange", ie), m.delete(e), e.dispatchEvent(new Event("teardown")));
  };
function oe(e, t) {
  var c;
  let n = L(e);
  if (!(n === b.M3U8)) return !0;
  let o = !n || ((c = t.canPlayType(n)) != null ? c : !0),
    {
      preferPlayback: a
    } = e,
    s = a === R.MSE,
    i = a === R.NATIVE;
  return o && (i || !(ne && (s || Pe)));
}
var He = (e, t) => {
    let {
        debug: n,
        streamType: r,
        startTime: o = -1,
        metadata: a,
        preferCmcd: s
      } = e,
      d = L(e) === b.M3U8,
      c = oe(e, t);
    if (d && !c && ne) {
      let p = {
          backBufferLength: 30,
          renderTextTracksNatively: !1,
          liveDurationInfinity: !0,
          capLevelToPlayerSize: !0,
          capLevelOnFPSDrop: !0
        },
        u = _e(r),
        l = s !== A.NONE ? {
          useHeaders: s === A.HEADER,
          sessionId: a == null ? void 0 : a.view_session_id,
          contentId: a == null ? void 0 : a.video_id
        } : void 0;
      return new f({
        debug: n,
        startPosition: o,
        cmcd: l,
        ...p,
        ...u
      });
    }
  },
  _e = e => e === v.LIVE ? {
    backBufferLength: 8
  } : {},
  ae = ({
    playbackId: e,
    src: t,
    customDomain: n
  }) => {
    if (e) return !0;
    if (typeof t != "string") return !1;
    let r = window == null ? void 0 : window.location.href,
      o = new URL(t, r).hostname.toLocaleLowerCase();
    return o.includes(te) || !!n && o.includes(n.toLocaleLowerCase());
  },
  Ne = (e, t, n) => {
    var a;
    let {
        envKey: r
      } = e,
      o = ae(e);
    if (r || o) {
      let {
          playerInitTime: s,
          playerSoftwareName: i,
          playerSoftwareVersion: d,
          beaconCollectionDomain: c,
          debug: p,
          disableCookies: u
        } = e,
        l = {
          ...e.metadata,
          video_title: ((a = e == null ? void 0 : e.metadata) == null ? void 0 : a.video_title) || void 0
        },
        T = y => typeof y.player_error_code == "string" ? !1 : typeof e.errorTranslator == "function" ? e.errorTranslator(y) : y;
      O.monitor(t, {
        debug: p,
        beaconCollectionDomain: c,
        hlsjs: n,
        Hls: n ? f : void 0,
        automaticErrorTracking: !1,
        errorTranslator: T,
        disableCookies: u,
        data: {
          ...(r ? {
            env_key: r
          } : {}),
          player_software_name: i,
          player_software: i,
          player_software_version: d,
          player_init_time: s,
          ...l
        }
      });
    }
  },
  Oe = (e, t, n) => {
    var a;
    let r = oe(e, t),
      {
        src: o
      } = e;
    if (t && r) {
      let s = L(e);
      typeof o == "string" ? (t.preload === "none" ? M(t, "loadstart", () => J(o, t, s)) : J(o, t, s), t.setAttribute("src", o), e.startTime && (((a = m.get(t)) != null ? a : {}).startTime = e.startTime, t.addEventListener("durationchange", ie, {
        once: !0
      }))) : t.removeAttribute("src"), t.addEventListener("error", se), t.addEventListener("error", N), t.addEventListener("emptied", () => {
        t.querySelectorAll("track[data-removeondestroy]").forEach(c => {
          c.remove();
        });
      }, {
        once: !0
      });
      let i = () => {
        t.ended || !De(t) || t.dispatchEvent(new Event("ended"));
      };
      M(t, "pause", i), M(t, "seeked", i), M(t, "play", () => {
        t.ended || !re(t.currentTime, t.duration) || (t.currentTime = t.seekable.start(0));
      });
    } else n && o ? (n.once(f.Events.LEVEL_LOADED, (s, i) => {
      xe(i.details, t, n);
    }), n.on(f.Events.ERROR, (s, i) => {
      let d = {
          [f.ErrorTypes.NETWORK_ERROR]: g.MEDIA_ERR_NETWORK,
          [f.ErrorTypes.MEDIA_ERROR]: g.MEDIA_ERR_DECODE
        },
        c = new g("", d[i.type]);
      c.fatal = i.fatal, c.data = i, t.dispatchEvent(new CustomEvent("error", {
        detail: c
      }));
    }), t.addEventListener("error", N), q(e, n), Y(t, n), n.attachMedia(t)) : console.error("It looks like the video you're trying to play will not work on this system! If possible, try upgrading to the newest versions of your browser or software.");
  };
function ie(e) {
  var r;
  let t = e.target,
    n = (r = m.get(t)) == null ? void 0 : r.startTime;
  if (!!n && U(t.seekable, t.duration, n)) {
    let o = t.preload === "auto";
    o && (t.preload = "none"), t.currentTime = n, o && (t.preload = "auto");
  }
}
async function se(e) {
  if (!e.isTrusted) return;
  e.stopImmediatePropagation();
  let t = e.target;
  if (!(t != null && t.error)) return;
  let {
      message: n,
      code: r
    } = t.error,
    o = new g(n, r);
  if (t.src && (r !== g.MEDIA_ERR_DECODE || r !== void 0)) try {
    let {
      status: a
    } = await fetch(t.src);
    o.data = {
      response: {
        code: a
      }
    };
  } catch {}
  t.dispatchEvent(new CustomEvent("error", {
    detail: o
  }));
}
function N(e) {
  var r, o;
  if (!(e instanceof CustomEvent) || !(e.detail instanceof g)) return;
  let t = e.target,
    n = e.detail;
  !n || !n.fatal || (((r = m.get(t)) != null ? r : {}).error = n, (o = t.mux) == null || o.emit("error", {
    player_error_code: n.code,
    player_error_message: n.message,
    player_error_context: n.context
  }));
}
export { E as AutoplayTypes, We as CmcdTypeValues, A as CmcdTypes, b as ExtensionMimeTypeMap, f as Hls, qe as MaxResolution, g as MediaError, h as MimeTypeShorthandMap, Ye as MinResolution, R as PlaybackTypes, $e as RenditionOrder, v as StreamTypes, $ as addCuePoints, H as addTextTrack, Ke as allMediaTypes, Pt as generatePlayerInitTime, Ee as generateUUID, X as getActiveCuePoint, Te as getCuePoints, S as getCuePointsTrack, me as getCurrentPdt, De as getEnded, At as getError, Ct as getLiveEdgeStart, ge as getMediaPlaylistLinesFromMultivariantPlaylistSrc, Ce as getSeekable, ye as getStartDate, be as getStreamInfoFromHlsjsLevelDetails, Me as getStreamInfoFromPlaylistLines, ve as getStreamInfoFromSrcAndType, ht as getStreamType, _e as getStreamTypeConfig, St as getTargetLiveWindow, Rt as initialize, C as isKeyOf, ae as isMuxVideoSrc, Ie as isPseudoEnded, Oe as loadMedia, O as mux, m as muxMediaState, fe as removeTextTrack, G as setupCuePoints, He as setupHls, Ne as setupMux, Be as shorthandKeys, we as teardown, Et as toMuxVideoURL, xe as updateStreamInfoFromHlsjsLevelDetails, J as updateStreamInfoFromSrc };
