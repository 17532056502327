// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';

module('eaw.payroll').component('employeeTimepunchStatus', {
    template: `<md-card class="no-shadow">
    <md-card-content class="tw-p-4">
        <form name="employeeForm" layout="row" layout-wrap>
            <eaw-date-interval layout="row" flex="100" flex-gt-xs="50" ng-model="status.interval" from-required="true" to-required="true" ng-change="status.changedInterval()"></eaw-date-interval>
            <employee-autocomplete required ng-model="status.employee" flex="100" flex-gt-xs="50" from="status.from" to="status.to" include-external="true"></employee-autocomplete>
        </form>
        <md-card-actions>
            <md-button class="md-raised md-accent" ng-disabled="employeeForm.$invalid" ng-click="status.getData()">
                <span bo-i18next="SUBMIT"></span>
            </md-button>
        </md-card-actions>
    </md-card-content>
</md-card>

<timepunch-xrange
        class="tw-pt-16"
        ng-show="!status.loading"
        loading="status.loading"
        chart-id="employee-range-chart"
        y-title="status.chart.title"
        categories="status.chart.categories"
        data="status.chart.data"
        date="status.chart.date"
        reversed-y="false">
</timepunch-xrange>

<div class="tw-p-20" ng-if="status.loading" layout="column" layout-align="center center">
    <md-progress-circular class="md-progress-center"></md-progress-circular>
</div>
`,
    controllerAs: 'status',
    bindings: {
        customer: '<',
        colors: '<',
        employee: '<?',
        interval: '<?',
    },
    controller: [ 'TimepunchFactory', function(TimepunchFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            if (!ctrl.interval) {
                const startOfWeek = moment().startOf('week').subtract(1, 'w');

                ctrl.interval = {
                    from: startOfWeek,
                    to: startOfWeek.clone().endOf('week'),
                };

                ctrl.changedInterval();
            }
        };

        ctrl.$postLink = () => {
            if (ctrl.employee) {
                ctrl.getData();
            }
        };

        ctrl.$onChanges = (changes) => {
            if (changes.interval) {
                ctrl.changedInterval();
            }
        };

        ctrl.$onDestroy = () => {
            ctrl.empRes?.$cancelRequest?.();
        };

        ctrl.changedInterval = () => {
            ctrl.from = ctrl.interval?.from;
            ctrl.to = ctrl.interval?.to;
        };

        ctrl.getData = () => {
            // loading is two-way bound and set to false in the child component
            ctrl.loading = true;

            ctrl.empRes = TimepunchFactory.getForEmployee({
                customer: ctrl.customer,
                employee: ctrl.employee,
                from: ctrl.from.clone().subtract(1, 'd').startOf('d'),
                to: ctrl.to.clone().add(1, 'd').startOf('d'),
            });

            return ctrl.empRes.$promise.then(ctrl.then, console.error);
        };

        ctrl.then = (resp) => {
            const dates = new Map();
            const date = ctrl.from.clone();

            resp.data.forEach((tp) => {
                if (!tp.out) {
                    tp.out = moment();
                }
            });

            let index = 0;
            while (date.isBefore(ctrl.to)) {
                dates.set(date.format('L'), {
                    date: date.clone(),
                    index,
                });
                date.add(1, 'd');
                index++;
            }

            const timepunches = ctrl.splitTimepunches(resp.data);

            dates.forEach((value) => {
                const hasTimepunchOnDay = timepunches.find((tp) => tp.business_date.isSame(value.date, 'd'));
                if (!hasTimepunchOnDay) {
                    const from = value.date.clone().startOf('d');
                    const to = value.date.clone().endOf('d');

                    timepunches.push({
                        employee_id: ctrl.employee.id,
                        business_date: value.date.clone(),
                        in: from,
                        out: to,
                        from,
                        to,
                    });
                }
            });

            ctrl.chart = {
                data: ctrl.formatSeries(dates, timepunches),
                categories: [ ...dates.keys() ],
                date: ctrl.from.clone(),
            };
        };

        ctrl.formatSeries = (categories, data) => {
            const collection = data.map((timepunch) => {
                const cat = categories.get(timepunch.business_date.format('L'));

                if (!cat) {
                    return null;
                }

                const point = {
                    timepunch,
                    color: timepunch.id ? ctrl.colors.present : ctrl.colors.notPresent,
                    x: null,
                    x2: null,
                    y: null,
                };

                point.x = ctrl.getFakeDate(timepunch, 'from', ctrl.from).valueOf();
                point.x2 = ctrl.getFakeDate(timepunch, 'to', ctrl.from).valueOf();
                point.y = cat.index;

                return point;
            });

            // filter out falsy values
            return collection.filter((c) => c);
        };

        ctrl.splitTimepunches = (data) => {
            const timepunches = [];

            data.forEach((timepunch) => {
                if (timepunch.in.date() != timepunch.out.date()) {
                    timepunches.push({
                        ...timepunch,
                        from: timepunch.in,
                        to: timepunch.in.clone().endOf('d'),
                        business_date: timepunch.in.clone().startOf('d'),
                    });

                    timepunches.push({
                        ...timepunch,
                        from: timepunch.out.clone().startOf('d'),
                        to: timepunch.out,
                        business_date: timepunch.out.clone().startOf('d'),
                    });
                } else {
                    timepunches.push({
                        ...timepunch,
                        from: timepunch.in,
                        to: timepunch.out,
                    });
                }
            });

            return timepunches;
        };

        ctrl.getFakeDate = (timepunch, prop, date) => {
            if (timepunch[prop].isSame(timepunch.business_date, 'd')) {
                return timepunch[prop].clone().set({
                    date: date.date(),
                    month: date.month(),
                });
            }
            const diff = timepunch[prop].clone().startOf('d').diff(timepunch.business_date, 'd');

            const m = date.clone().add(diff, 'd');
            return timepunch[prop].clone().set({
                date: m.date(),
                month: m.month(),
                year: m.year(),
            });
        };
    } ],
});
