import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, inject, input, OnInit, Signal, signal, viewChild, viewChildren, WritableSignal } from '@angular/core';
import { ShiftGroupService } from '../../../../http/shift-group.service';
import { CurrentService } from '../../../../../shared/services/current.service';
import { catchError, EMPTY, firstValueFrom, forkJoin, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { Employee } from '../../../../../shared/models/employee';
import { Schedule } from '../../../../models/schedule';
import { ShiftGroup } from '../../../../models/shift-group';
import { Shift } from '../../../../models/shift';
import { DateTime } from 'luxon';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { AsyncPipe, NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InfoLoadingComponent } from '../../../../../shared/components/info-loading/info-loading.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { TranslateService } from '../../../../../shared/services/translate.service';
import { sort, timeStringToHourMin } from '../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { ItemSelectorDialogComponent, ItemSelectorDialogData, ItemSelectorDialogReturn } from '../../../../../shared/dialogs/item-selector-dialog/item-selector-dialog.component';
import { MatDivider } from '@angular/material/divider';
import { MaterialColorDirective } from '../../../../../shared/directives/material-color.directive';
import { PositionService } from '../../../../../company/http/position.service';
import { Position } from '../../../../../shared/models/position';
import { Namespace } from '../../../../../shared/enums/namespace';
import { MatIconSizeDirective } from '../../../../../shared/directives/mat-icon-size.directive';
import { PermissionDirective } from '../../../../../permissions/directives/permission.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { EawMaterialColorHue } from '../../../../../shared/services/material-color.service';
import { ShiftService } from '../../../../http/shift.service';
import { BusinessDate } from '../../../../../shared/utils/business-date';
import { HttpContext } from '@angular/common/http';
import { IGNORE_ERROR } from '../../../../../shared/http/http-contexts';
import { AlertDialogComponent, AlertDialogData } from '../../../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { DialogSize } from '../../../../../shared/dialogs/dialog-component';
import { groupBy } from 'lodash-es';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from '../../../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { ScheduleHoursPlannedDialogComponent, ScheduleHoursPlannedDialogData } from '../../../../dialogs/schedule-hours-planned-dialog/schedule-hours-planned-dialog.component';
import { expandAllPages } from '../../../../../shared/utils/rxjs/expand-all-pages';
import { ScheduleComponent } from '../../schedule.component';

type StatusLabel = {
    text: Promise<string>;
    color: EawMaterialColorHue;
    class?: string;
    callback?: () => void;
}

interface HeaderDay {
    date: string;
    weekday: string;
    dateTime: DateTime;
    dstStarts: boolean;
    dstEnds: boolean;
    inViewport: WritableSignal<boolean>;
}

type IntervalValue = { value: WritableSignal<DateTime | undefined>, display: WritableSignal<string> };

interface EmployeeRowDayShiftInterval {
    employeeId: WritableSignal<number | null | undefined>;
    shouldCreate: Signal<boolean>;
    shouldUpdate: Signal<boolean>;
    shouldDelete: Signal<boolean>;
    shift: WritableSignal<Shift | undefined>;
    edited: Signal<boolean>;
    invalid: Signal<boolean>;
    from: IntervalValue;
    to: IntervalValue;
}

interface IntervalsToUpdate {
    shouldCreate: EmployeeRowDayShiftInterval[];
    shouldUpdate: EmployeeRowDayShiftInterval[];
    shouldDelete: EmployeeRowDayShiftInterval[];
}

interface EmployeeRow {
    id: string;
    // Number of edits done on this employee
    edits: Signal<number>;
    // Number of invalid intervals
    invalids: Signal<number>;
    // Dates with one or more invalid intervals
    invalidDates: Signal<Set<string>>;
    processing: WritableSignal<boolean>;
    hasOpenMenu: WritableSignal<boolean>;
    employee: WritableSignal<Employee | undefined>;
    shiftGroup: WritableSignal<ShiftGroup | undefined>;
    intervals: WritableSignal<Record<string, WritableSignal<EmployeeRowDayShiftInterval[]>>>;
    intervalsPerDay: Signal<number>;
    inViewport: WritableSignal<boolean>;
}

@Component({
    selector: 'eaw-schedule-employees-tab',
    standalone: true,
    imports: [
        MatCard,
        MatIcon,
        MatIconButton,
        NgIf,
        ReactiveFormsModule,
        InfoLoadingComponent,
        TranslatePipe,
        AsyncPipe,
        MatMenu,
        MatMenuContent,
        MatMenuItem,
        MatMenuTrigger,
        MatDivider,
        MaterialColorDirective,
        MatIconSizeDirective,
        NgTemplateOutlet,
        NgStyle,
        MatMiniFabButton,
        PermissionDirective,
        MatTooltip,
        NgClass,
    ],
    templateUrl: './schedule-employees-tab.component.html',
    styleUrl: './schedule-employees-tab.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleEmployeesTabComponent implements OnInit {
    private readonly matDialog = inject(MatDialog);
    private readonly elementRef = inject(ElementRef);
    private readonly shiftService = inject(ShiftService);
    private readonly currentService = inject(CurrentService);
    private readonly snackbarService = inject(SnackBarService);
    private readonly positionService = inject(PositionService);
    private readonly translateService = inject(TranslateService);
    private readonly shiftGroupService = inject(ShiftGroupService);

    protected gridRef = viewChild<ElementRef<HTMLDivElement>>('grid');
    protected employeeRowRef = viewChildren<ElementRef<HTMLDivElement>>('employeeRow');
    protected headerDaysRef = viewChildren<ElementRef<HTMLDivElement>>('headerDay');

    stackId = input.required<number>();
    customerId = input.required<number>();
    scheduleId = input.required<number>();

    inputPlaceholders = signal({ from: '', to: '' });
    intervalsToHandle = computed(this.computeIntervalsToHandle.bind(this));
    saveButtonDisabled = computed(() => this.saving() || this.invalidIntervals() > 0 || this.loading() || this.editedIntervals() === 0);
    statusLabel: Signal<StatusLabel> = computed(this.computeStatusLabel.bind(this));
    shiftGroups = signal([] as ShiftGroup[]);
    hideRowsWithNoShifts = signal(false);
    hideRowsWithNoGroup = signal(false);
    loading = signal(true);
    saving = signal(false);
    showNicknames = computed(ScheduleComponent.properties.scheduleTab.showNicknames.value);
    /**
     * Header days are computed based on the schedule start and end date.
     */
    headerDays: Signal<HeaderDay[]> = computed(this.setHeaderDays.bind(this));
    /**
     * Rows is what we set and edit the data on.
     */
    rows = signal(new Map<string, EmployeeRow>());
    /**
     * Sorted rows are computed based on the rows signal, and is used for rendering.
     */
    sortedRows = computed(this.computeSortedRows.bind(this));
    /**
     * Number of edited intervals.
     */
    editedIntervals = computed(() => Array.from(this.rows().values()).reduce((acc, row) => acc + row.edits(), 0));
    /**
     * Number of invalid intervals.
     */
    invalidIntervals = computed(() => Array.from(this.rows().values()).reduce((acc, row) => acc + row.invalids(), 0));
    selectedPositions = signal([] as Position[]);
    subtitle = computed(this.computeSubtitle.bind(this));

    constructor() {
        effect(() => {
            this.elementRef.nativeElement.style.setProperty('--days', String(this.headerDays().length));
        });

        effect(() => {
            const grid = this.gridRef()?.nativeElement;
            const rows = this.employeeRowRef();
            if (!grid || !rows.length) {
                return;
            }

            const intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    this.sortedRows().find((r) => r.id === entry.target.id)?.inViewport.set(entry.isIntersecting);
                });
            }, {
                root: grid,
                rootMargin: '0px 1000000px',
                threshold: 0.5,
            });

            rows.forEach((row) => intersectionObserver.observe(row.nativeElement));
        });

        effect(() => {
            const grid = this.gridRef()?.nativeElement;
            const days = this.headerDaysRef();
            if (!grid || !days.length) {
                return;
            }

            const intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    this.headerDays().find((d) => d.date === entry.target.id)?.inViewport.set(entry.isIntersecting);
                });
            }, {
                root: grid,
                rootMargin: '1000000px 0px',
                threshold: 0.1,
            });

            days.forEach((day) => intersectionObserver.observe(day.nativeElement));
        });
    }

    ngOnInit() {
        void this.setInputPlaceholders();
        this.getData().subscribe();
    }

    async setInputPlaceholders() {
        const [ from, to ] = await Promise.all([
            this.translateService.t('FROM', Namespace.Scheduling),
            this.translateService.t('TO', Namespace.Scheduling),
        ]);

        this.inputPlaceholders.set({ from, to });
    }

    getData() {
        return this.getAllShiftGroups().pipe(
            tap((shiftGroups) => {
                this.shiftGroups.set(shiftGroups);
                this.createRows();
                this.loading.set(false);
            }),
        );
    }

    refreshData() {
        return this.getAllShiftGroups().pipe(
            tap((shiftGroups) => {
                this.shiftGroups.set(shiftGroups);
                this.createRows();
            }),
        );
    }

    computeStatusLabel(): StatusLabel {
        const edits = this.editedIntervals();
        const invalids = this.invalidIntervals();

        if (invalids) {
            return {
                text: this.translateService.t('X_INVALID_INTERVAL', Namespace.Scheduling, { count: invalids }),
                color: 'red-500',
                callback: this.goToInvalidRow.bind(this),
                class: 'invalid',
            };
        }

        if (edits) {
            return {
                text: this.translateService.t('X_CHANGE', Namespace.General, { count: edits }),
                color: 'blue-500',
            };
        }

        return {
            text: Promise.resolve(''),
            color: 'grey-500',
        };
    }

    goToInvalidRow() {
        const grid = this.gridRef()?.nativeElement;
        if (!grid) {
            return;
        }

        const invalid = grid.querySelectorAll('.day-cell.invalid')[0];
        if (!invalid) {
            return;
        }

        invalid.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        invalid.classList.add('highlight');

        setTimeout(() => {
            invalid.classList.remove('highlight');
        }, 3000);
    }

    computeSubtitle() {
        const positions = sort(this.selectedPositions().map((p) => p.name), this.currentService.languageTag);
        return positions.length ? new Intl.ListFormat(this.currentService.languageTag, { style: 'long', type: 'conjunction' }).format(positions) : '';
    }

    createEmployeeRowIntervals(employeeId: number | null | undefined, shiftGroup?: ShiftGroup) {
        const maxShiftsOnDay = Object.values(groupBy(shiftGroup?.members, (shift) => shift.from.toISODate())).reduce((acc, shifts) => Math.max(acc, shifts.length), 1);

        const intervals: EmployeeRow['intervals'] = signal({});

        this.headerDays().forEach((day) => {
            const date = day.date;
            const dayShifts = shiftGroup?.members?.filter((shift) => shift.from.hasSame(day.dateTime, 'day'));
            let shiftIntervals: EmployeeRowDayShiftInterval[] = [];

            if (dayShifts?.length) {
                const additionalIntervals = maxShiftsOnDay - dayShifts.length;
                shiftIntervals = dayShifts.map((shift) => this.createShiftInterval(employeeId, shift));

                if (additionalIntervals) {
                    shiftIntervals = shiftIntervals.concat(new Array(additionalIntervals).fill(this.createShiftInterval(employeeId)));
                }
            } else {
                shiftIntervals = new Array(maxShiftsOnDay).fill(this.createShiftInterval(employeeId));
            }

            intervals.update((d) => {
                d[date] = signal(shiftIntervals);
                return d;
            });
        });

        return intervals;
    }

    getEmployeeRowId(employeeId: number | null | undefined, shiftGroupId: number | null | undefined) {
        return `${String(employeeId)}-${String(shiftGroupId)}`;
    }

    createEmployeeRow(employee?: Employee, shiftGroup?: ShiftGroup) {
        const rowId = this.getEmployeeRowId(employee?.id, shiftGroup?.id);
        const intervals = this.createEmployeeRowIntervals(employee?.id, shiftGroup);

        const row: EmployeeRow = {
            id: rowId,
            employee: signal(employee),
            hasOpenMenu: signal(false),
            processing: signal(false),
            edits: computed(() => Object.values(intervals()).reduce((acc, day) => acc + day().filter((d) => d.edited()).length, 0)),
            invalids: computed(() => Object.values(intervals()).reduce((acc, day) => acc + day().filter((d) => d.invalid()).length, 0)),
            invalidDates: computed(() => {
                const invalidDates = new Set<string>();
                Object.entries(intervals()).forEach(([ date, day ]) => {
                    if (day().some((d) => d.invalid())) {
                        invalidDates.add(date);
                    }
                });

                return invalidDates;
            }),
            shiftGroup: signal(shiftGroup),
            intervals,
            intervalsPerDay: computed(() => Math.max(...Object.values(intervals()).map((d) => d().length))),
            inViewport: signal(this.rows().get(rowId)?.inViewport() || false),
        };

        return row;
    }

    getAllShiftGroups() {
        return expandAllPages((pagination) => this.shiftGroupService.getAll(this.customerId(), this.scheduleId(), pagination), {
            per_page: 100,
            'with[]': [ 'members' ],
        });
    }

    createShiftInterval(employeeId: number | null | undefined, shift?: Shift) {
        const fromSignal = signal(shift?.from);
        const toSignal = signal(shift?.to);
        const shiftSignal = signal(shift);

        const invalid = computed(() => {
            const from = fromSignal();
            const to = toSignal();

            return !!((from && !to) || (to && !from));
        });

        const edited = computed(() => {
            const from = fromSignal()?.toString();
            const to = toSignal()?.toString();
            const shiftFrom = shiftSignal()?.from.toString();
            const shiftTo = shiftSignal()?.to.toString();

            if (invalid()) {
                return false;
            }

            return from !== shiftFrom || to !== shiftTo;
        });

        const shouldCreate = computed(() => edited() && !shiftSignal() && !!fromSignal() && !!toSignal());
        const shouldUpdate = computed(() => edited() && !!shiftSignal() && !!fromSignal() && !!toSignal());
        const shouldDelete = computed(() => edited() && !!shiftSignal() && !fromSignal() && !toSignal());

        const interval: EmployeeRowDayShiftInterval = {
            employeeId: signal(employeeId),
            shift: shiftSignal,
            invalid,
            shouldCreate,
            shouldUpdate,
            shouldDelete,
            edited,
            from: { value: fromSignal, display: signal(this.getTimeDisplayString(shift?.from)) },
            to: { value: toSignal, display: signal(this.getTimeDisplayString(shift?.to)) },
        };

        return interval;
    }

    createRows() {
        const rows = new Map<string, EmployeeRow>();

        ScheduleComponent.employees().forEach((employee) => {
            const employeeShiftGroup = this.shiftGroups().find((group) => group.employeeId === employee.id);
            const row = this.createEmployeeRow(employee, employeeShiftGroup);
            rows.set(row.id, row);
        });

        this.shiftGroups().filter((group) => !group.employeeId).forEach((group) => {
            const row = this.createEmployeeRow(undefined, group);
            rows.set(row.id, row);
        });

        this.rows.set(rows);
    }

    computeIntervalsToHandle(): IntervalsToUpdate {
        const intervals = Array.from(this.rows().values())
            .map((row) => row.intervals())
            .flatMap((intervals) => Object.values(intervals))
            .flatMap((x) => x());

        return {
            shouldCreate: intervals.filter((i) => i.shouldCreate()),
            shouldUpdate: intervals.filter((i) => i.shouldUpdate()),
            shouldDelete: intervals.filter((i) => i.shouldDelete()),
        };
    }

    computeSortedRows() {
        let rows = Array.from(this.rows().values());
        const hideRowsWithNoShifts = this.hideRowsWithNoShifts();
        const hideRowsWithNoGroup = this.hideRowsWithNoGroup();
        const positions = this.selectedPositions();

        rows = rows.filter((row) => {
            if (hideRowsWithNoShifts) {
                const rowIntervals = Object.values(row.intervals()).flatMap((d) => d());
                if (!rowIntervals.length) {
                    return false;
                }
            }

            if (hideRowsWithNoGroup) {
                if (!row.shiftGroup()) {
                    return false;
                }
            }

            if (positions.length) {
                if (!positions.some((p) => row.employee()?.positions?.some((ep) => ep.id === p.id))) {
                    return false;
                }
            }

            return true;
        });

        const noEmployeeGroups = rows.filter((r) => r.shiftGroup()?.employeeId === null);
        const noEmployeeGroupsIds = noEmployeeGroups.map((r) => r.id);
        const others = rows.filter((r) => !noEmployeeGroupsIds.includes(r.id));

        return [
            ...sort(noEmployeeGroups, this.currentService.languageTag, [ (x) => x.id ]),
            ...sort(others, this.currentService.languageTag, [ (x) => x.employee()?.name ]),
        ];
    }

    setHeaderDays() {
        let from = ScheduleComponent.schedule()?.from;
        const to = ScheduleComponent.schedule()?.to.minus({ second: 1 });

        if (!from || !to) {
            return [];
        }

        const days: HeaderDay[] = [];
        while (from <= to) {
            days.push({
                date: from.toLocaleString(DateTime.DATE_MED),
                weekday: from.weekdayLong || '',
                dateTime: from,
                dstStarts: !from.isInDST && from.plus({ days: 1 }).isInDST,
                dstEnds: from.isInDST && !from.plus({ days: 1 }).isInDST,
                inViewport: signal(false),
            });

            from = from.plus({ days: 1 });
        }

        return days;
    }

    addEmptyShiftRow(row: EmployeeRow) {
        Object.values(row.intervals()).forEach((day) => {
            day.update((intervals) => intervals.concat(this.createShiftInterval(row.employee()?.id)));
        });
    }

    deleteShifts(row: EmployeeRow) {
        row.hasOpenMenu.set(false);

        const shiftGroupId = row.shiftGroup()?.id;
        if (!shiftGroupId) {
            return;
        }

        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translateService.t('DELETE_SHIFT_plural', Namespace.Scheduling),
                text: this.translateService.t('DELETE_SHIFT_GROUP_TEXT', Namespace.Scheduling),
                confirmText: this.translateService.t('DELETE'),
                confirmButtonColor: 'warn',
            },
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                return;
            }

            row.processing.set(true);
            this.shiftGroupService.delete(this.customerId(), this.scheduleId(), shiftGroupId).pipe(
                catchError(() => {
                    this.createRows();
                    return EMPTY;
                }),
            ).subscribe(() => {
                void this.snackbarService.t('SHIFT_DELETED_plural', Namespace.Scheduling, { count: row.shiftGroup()?.members?.length || 0 });

                this.shiftGroups.update((groups) => [ ...groups.filter((g) => g.id !== shiftGroupId) ]);

                this.rows.update((rows) => {
                    rows.delete(row.id);

                    const newRow = this.createEmployeeRow(row.employee(), undefined);
                    rows.set(newRow.id, newRow);

                    return new Map([ ...rows ]);
                });
            });
        });
    }

    assignShifts(row: EmployeeRow) {
        const shiftGroup = row.shiftGroup();
        if (!shiftGroup) {
            return;
        }

        const rowEmployeeId = row.employee()?.id;
        const arrayRows = Array.from(this.rows().values());
        const rows = rowEmployeeId ? arrayRows.filter((r) => r.employee()?.id !== rowEmployeeId) : arrayRows;
        const employeeRows = rows.filter((r) => r.employee());

        this.matDialog.open<ItemSelectorDialogComponent<EmployeeRow>, ItemSelectorDialogData<EmployeeRow>, ItemSelectorDialogReturn<EmployeeRow>>(ItemSelectorDialogComponent, {
            data: {
                title: this.translateService.t('CHOOSE_EMPLOYEE', Namespace.Scheduling),
                items: of(sort(employeeRows, this.currentService.languageTag, [ (r) => r.employee()?.name ])),
                itemText: (r) => r.employee()?.name || '',
                multiple: false,
            },
        }).afterClosed().subscribe((selectedRow) => {
            if (!selectedRow || Array.isArray(selectedRow)) {
                return;
            }

            const employee = selectedRow.employee();
            if (!employee) {
                return;
            }

            row.processing.set(true);
            selectedRow.processing.set(true);
            this.shiftGroupService.update(this.customerId(), this.scheduleId(), shiftGroup.id, {
                employee_id: employee.id,
                with: [ 'members' ],
            }).pipe(
                catchError(() => {
                    this.createRows();
                    return EMPTY;
                }),
            ).subscribe((group) => {
                void this.snackbarService.t('SHIFT_ASSIGNED', Namespace.Scheduling, { count: shiftGroup.members?.length || 0 });

                this.shiftGroups.update((groups) => [ group, ...groups.filter((g) => g.id !== shiftGroup.id) ]);

                this.rows.update((rows) => {
                    rows.delete(row.id);
                    rows.delete(selectedRow.id);

                    const unassignedRow = this.createEmployeeRow(row.employee(), undefined);
                    rows.set(unassignedRow.id, unassignedRow);

                    const assignedRow = this.createEmployeeRow(selectedRow.employee(), group);
                    rows.set(assignedRow.id, assignedRow);

                    return new Map([ ...rows ]);
                });
            });
        });
    }

    copyShifts(row: EmployeeRow) {
        const shiftGroupId = row.shiftGroup()?.id;
        if (!shiftGroupId) {
            return;
        }

        const rowEmployeeId = row.employee()?.id;
        const noneEmployeeRow = this.createEmployeeRow(new Employee({ id: 0, customer_id: 0, number: '', name: 'None' }));
        const arrayRows = Array.from(this.rows().values());
        const rows = rowEmployeeId ? arrayRows.filter((r) => r.employee()?.id !== rowEmployeeId) : arrayRows;
        const employeeRows = [ noneEmployeeRow, ...sort(rows.filter((r) => r.employee()), this.currentService.languageTag, [ (r) => r.employee()?.name ]) ];

        this.matDialog.open<ItemSelectorDialogComponent<EmployeeRow>, ItemSelectorDialogData<EmployeeRow>, ItemSelectorDialogReturn<EmployeeRow>>(ItemSelectorDialogComponent, {
            data: {
                title: this.translateService.t('CHOOSE_EMPLOYEE_plural', Namespace.Scheduling),
                items: of(employeeRows),
                itemText: (r) => r.employee()?.name || '',
                multiple: true,
            },
        }).afterClosed().subscribe(async (selectedRows) => {
            if (!employeeRows || !Array.isArray(selectedRows)) {
                return;
            }

            row.processing.set(true);
            selectedRows.forEach((selectedRow) => selectedRow.processing.set(true));

            for (const selectedRow of selectedRows) {
                const selectedRowEmployee = selectedRow.employee();
                if (!selectedRowEmployee) {
                    continue;
                }

                try {
                    const group = await firstValueFrom(this.shiftGroupService.copy(this.customerId(), this.scheduleId(), shiftGroupId, selectedRowEmployee.id || null));
                    void this.snackbarService.t('SHIFT_COPY_SUCCESS', Namespace.Scheduling, { name: selectedRowEmployee.name });

                    const selectedRowGroupId = selectedRow.shiftGroup()?.id;
                    this.shiftGroups.update((groups) => {
                        return selectedRowGroupId ? [ group, ...groups.filter((g) => g.id !== selectedRowGroupId) ] : [ group, ...groups ];
                    });

                    this.rows.update((rows) => {
                        rows.delete(selectedRow.id);

                        const assignedRow = this.createEmployeeRow(selectedRow.employee()?.id ? selectedRow.employee() : undefined, group);
                        rows.set(assignedRow.id, assignedRow);

                        return new Map([ ...rows ]);
                    });
                } catch (_) {
                    void this.snackbarService.t('SHIFT_COPY_FAIL', Namespace.Scheduling, { name: selectedRowEmployee.name });
                    selectedRow.processing.set(false);
                }
            }

            void this.snackbarService.t('SHIFT_COPY_FINISHED', Namespace.Scheduling);
            row.processing.set(false);
        });
    }

    unassignShifts(row: EmployeeRow) {
        const shiftGroup = row.shiftGroup();
        if (!shiftGroup) {
            return;
        }

        row.processing.set(true);
        this.shiftGroupService.update(this.customerId(), this.scheduleId(), shiftGroup.id, {
            employee_id: null,
            with: [ 'members' ],
        }).pipe(
            catchError(() => {
                this.createRows();
                return EMPTY;
            }),
        ).subscribe((group) => {
            void this.snackbarService.t('SHIFT_UNASSIGNED_plural', Namespace.Scheduling, { count: shiftGroup.members?.length || 0 });

            this.shiftGroups.update((groups) => [ group, ...groups.filter((g) => g.id !== shiftGroup.id) ]);

            this.rows.update((rows) => {
                rows.delete(row.id);

                const unassignedRow = this.createEmployeeRow(row.employee(), undefined);
                rows.set(unassignedRow.id, unassignedRow);

                const assignedRow = this.createEmployeeRow(undefined, group);
                rows.set(assignedRow.id, assignedRow);

                return new Map([ ...rows ]);
            });
        });
    }

    selectInput(ev: FocusEvent) {
        (ev.target as HTMLInputElement).select();
    }

    updateValue(ev: FocusEvent, day: HeaderDay, key: 'from' | 'to', interval: EmployeeRowDayShiftInterval) {
        const input = ev.target as HTMLInputElement;
        const hourMin = timeStringToHourMin(input.value);
        const shiftTime = interval.shift()?.[key] || day.dateTime;

        if (hourMin) {
            const dateTime = DateTime.fromObject({
                ...shiftTime?.toObject(),
                ...hourMin,
            });

            interval[key].display.set(this.getTimeDisplayString(dateTime));
            interval[key].value.set(dateTime);
        } else {
            input.value = '';
            interval[key].display.set('');
            interval[key].value.set(undefined);
        }
    }

    getTimeDisplayString(date?: DateTime) {
        return date?.toFormat('HH:mm') || '';
    }

    selectPositions() {
        this.matDialog.open<ItemSelectorDialogComponent<Position>, ItemSelectorDialogData<Position>, ItemSelectorDialogReturn<Position>>(ItemSelectorDialogComponent, {
            data: {
                title: this.translateService.t('SELECT_POSITIONS', Namespace.Scheduling),
                confirmText: this.translateService.t('SELECT'),
                items: this.positionService.getAll(this.customerId(), { per_page: 1000 }).pipe(
                    map((resp) => sort(resp.data, this.currentService.languageTag, [ (r) => r.name ])),
                ),
                value: of(this.selectedPositions()),
                valueMatcher: (val, item) => val.some((p) => p.id === item.id),
                itemText: (p) => p.name,
                multiple: true,
                allowEmptyChoice: signal(true),
            },
        }).afterClosed().subscribe((selectedPositions) => {
            if (Array.isArray(selectedPositions)) {
                this.selectedPositions.set(selectedPositions);
            }
        });

    }

    toggleNoGroupRows() {
        this.hideRowsWithNoGroup.update((hide) => !hide);
    }

    openHoursPlanned() {
        this.matDialog.open<ScheduleHoursPlannedDialogComponent, ScheduleHoursPlannedDialogData>(ScheduleHoursPlannedDialogComponent, {
            data: {
                customerId: this.customerId(),
                scheduleId: this.scheduleId(),
            },
        });
    }

    getCreateUpdateOptions(interval: EmployeeRowDayShiftInterval, schedule: Schedule) {
        const from = interval.from.value();
        const to = interval.to.value();
        if (!from?.isValid || !to?.isValid) {
            return;
        }

        const actualTo = from >= to ? to.plus({ days: 1 }) : to;
        const offset = from.diff(schedule.from, 'seconds').as('seconds');
        const length = actualTo.diff(from, 'seconds').as('seconds');

        return {
            employee_id: interval.employeeId(),
            offset,
            length,
            business_date: new BusinessDate(from),
        };
    }

    getErrorText(employeeId: number | null | undefined, status: 'CREATE' | 'UPDATE' | 'DELETE', time: DateTime | undefined) {
        const unassigned = '$t(scheduling.UNASSIGNED)';

        return of({
            errorText: this.translateService.t('STATUS_SHIFT_FAIL_EMP', Namespace.Scheduling, {
                status: `$t(general.${status})`, // From WTI
                employee: employeeId ? ScheduleComponent.employees().get(employeeId)?.name || unassigned : unassigned,
                time: time?.toLocaleString(DateTime.DATETIME_MED),
            }),
        });

    }

    createObservable(interval: EmployeeRowDayShiftInterval, schedule: Schedule): Observable<{ errorText: Promise<string> | undefined }> {
        const ignoreErrorContext = new HttpContext().set(IGNORE_ERROR, true);
        const defaultError = of({ errorText: this.translateService.t('SOMETHING_WENT_WRONG', Namespace.Errors) });

        if (interval.shouldCreate()) {
            const options = this.getCreateUpdateOptions(interval, schedule);
            if (!options) {
                return defaultError;
            }

            return this.shiftService.create(this.customerId(), this.scheduleId(), options, undefined, ignoreErrorContext).pipe(
                tap((shift) => {
                    ScheduleComponent.setShift(shift);
                    ScheduleComponent.broadcastEvent(shift.id, 'created', shift);
                }),
                map(() => ({ errorText: undefined })),
                catchError(() => this.getErrorText(interval.employeeId(), 'CREATE', interval.from.value())),
            );
        }

        if (interval.shouldUpdate()) {
            const options = this.getCreateUpdateOptions(interval, schedule);
            const shift = interval.shift();
            if (!options || !shift) {
                return defaultError;
            }

            return this.shiftService.update(this.customerId(), this.scheduleId(), shift.id, options, undefined, ignoreErrorContext).pipe(
                tap((shift) => {
                    ScheduleComponent.setShift(shift);
                    ScheduleComponent.broadcastEvent(shift.id, 'updated', shift);
                }),
                map(() => ({ errorText: undefined })),
                catchError(() => this.getErrorText(interval.employeeId(), 'UPDATE', interval.from.value())),
            );
        }

        if (interval.shouldDelete()) {
            const shift = interval.shift();
            if (!shift) {
                return defaultError;
            }

            return this.shiftService.delete(this.customerId(), this.scheduleId(), shift.id, ignoreErrorContext).pipe(
                tap(() => {
                    ScheduleComponent.deleteShift(shift.id);
                    ScheduleComponent.broadcastEvent(shift.id, 'deleted');
                }),
                map(() => ({ errorText: undefined })),
                catchError(() => this.getErrorText(interval.employeeId(), 'DELETE', interval.from.value())),
            );
        }

        return of({ errorText: Promise.resolve('') });
    }

    updateProcessingDialogText(processedEdits: number, editsToProcess: number) {
        return this.translateService.t('COMPLETED_X_Y_CHANGE', Namespace.Scheduling, { complete: processedEdits, count: editsToProcess });
    }

    async save() {
        const schedule = ScheduleComponent.schedule();
        if (!schedule) {
            return;
        }

        const editsToProcess = this.editedIntervals();
        let processedEdits = 0;
        let failedGroupCreation = false;
        const errorTexts: Promise<string>[] = [];
        const dialogText = signal(this.updateProcessingDialogText(processedEdits, editsToProcess));

        const dialogRef = this.matDialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
            data: {
                title: signal(this.translateService.t('PROCESSING', Namespace.Scheduling)),
                text: dialogText,
                loading: this.saving,
                size: DialogSize.Medium,
            },
        });

        this.saving.set(true);

        const newGroups = this.intervalsToHandle().shouldCreate.reduce((acc, interval) => {
            const employeeId = interval.employeeId();
            if (!employeeId || this.shiftGroups().find((g) => g.employeeId === employeeId)) {
                return acc;
            }

            acc.set(employeeId, this.shiftGroupService.create(this.customerId(), this.scheduleId(), employeeId).pipe(
                catchError(() => {
                    this.saving.set(false);
                    failedGroupCreation = true;
                    dialogRef.close();
                    return EMPTY;
                }),
            ));

            return acc;
        }, new Map<number, Observable<ShiftGroup>>());

        const shiftObservables = of(
            ...this.intervalsToHandle().shouldCreate,
            ...this.intervalsToHandle().shouldUpdate,
            ...this.intervalsToHandle().shouldDelete,
        ).pipe(mergeMap((interval) => this.createObservable(interval, schedule), 4));

        (newGroups.size ? forkJoin([ ...newGroups.values() ]).pipe(switchMap(() => shiftObservables)) : shiftObservables).subscribe({
            complete: () => {
                if (failedGroupCreation) {
                    return;
                }

                dialogText.set(this.translateService.t('FETCHING_UPDATED_DATA'));

                this.refreshData().subscribe(async () => {
                    void this.snackbarService.t('CHANGES_SAVED');
                    this.saving.set(false);

                    if (errorTexts.length) {
                        const errorText = await Promise.all(errorTexts);
                        dialogText.set(Promise.resolve(errorText.join('\n')));
                    } else {
                        dialogRef.close();
                    }
                });
            },
            next: (x) => {
                processedEdits += 1;
                dialogText.set(this.updateProcessingDialogText(processedEdits, editsToProcess));

                if (x.errorText) {
                    errorTexts.push(x.errorText);
                }
            },
        });
    }
}
