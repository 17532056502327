<eaw-page-header>
    <span title>{{ 'PAY_RATE_CONTRACT_INFO' | translate:'company' | async }}</span>
</eaw-page-header>

<div id="content" class="tw-mt-24">
    <div id="cards" class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-16 tw-mb-24">
        <mat-card class="info-card" [class.fetching]="fetchingActivePayRate" [class.missing]="!fetchingActivePayRate && !activePayRate">
            <mat-card-header class="tw-flex tw-items-center">
                <mat-card-title>{{ 'PAY_RATE' | translate | async }}</mat-card-title>
                <span class="tw-flex-1"></span>
                <button mat-mini-fab color="accent" *ngIf="canAddPayRate" (click)="addPayRate()">
                    <mat-icon>add</mat-icon>
                </button>
            </mat-card-header>

            @if (!fetchingActivePayRate) {
                @if (activePayRate) {
                    <mat-card-content>
                        <div class="mat-headline-4" *ngIf="activePayRateText">{{ activePayRateText | async }}</div>

                        <div class="mat-body-1">
                            @if (activePayRate.to) {
                                {{ 'FROM_TO' | translate:'general':{from: activePayRate.from | DateTime:'DATE_MED', to: activePayRate.to | DateTime:'DATE_MED'} | async }}
                            } @else {
                                {{ 'FROM_NO_TO' | translate:'general':{from: activePayRate.from | DateTime:'DATE_MED'} | async }}
                            }
                        </div>
                    </mat-card-content>
                } @else {
                    <mat-card-content>
                        <div class="mat-headline-5">{{ 'NO_PAY_RATE' | translate:'company' | async }}</div>
                    </mat-card-content>
                }
            }
        </mat-card>

        <!-- OTHER CARD -->

        <mat-card class="info-card" [class.fetching]="fetchingActiveContract" [class.missing]="!fetchingActiveContract && !activeContract">
            <mat-card-header>
                <mat-card-title>{{ 'CONTRACT' | translate | async }}</mat-card-title>
                <span class="tw-flex-1"></span>
                <button mat-mini-fab color="accent" *ngIf="canAddContract" (click)="addContract()">
                    <mat-icon>add</mat-icon>
                </button>
            </mat-card-header>

            @if (!fetchingActiveContract) {
                @if (activeContract) {
                    <mat-card-content>
                        <div class="mat-headline-4" *ngIf="activeContractText">{{ activeContractText | async }}</div>
                        <div class="mat-body-1">{{ activeContract.type?.name }}</div>

                        <div class="mat-body-1">
                            @if (activeContract.to) {
                                {{ 'FROM_TO' | translate:'general':{from: activeContract.from | DateTime:'DATE_MED', to: activeContract.to | DateTime:'DATE_MED'} | async }}
                            } @else {
                                {{ 'FROM_NO_TO' | translate:'general':{from: activeContract.from | DateTime:'DATE_MED'} | async }}
                            }
                        </div>
                    </mat-card-content>
                } @else {
                    <mat-card-content>
                        <div class="mat-headline-5">{{ 'NO_CONTRACT' | translate:'company' | async }}</div>
                    </mat-card-content>
                }
            }
        </mat-card>

        @if (usesCombinedContract) {
            @if (activeAvailability) {
                <mat-card class="info-card" [class.fetching]="fetchingActiveCombinedContract" [class.missing]="!activeAvailability">
                    <mat-card-header>
                        <mat-card-title>{{ 'AVAILABILITY' | translate:'availabilities' | async }}</mat-card-title>
                        <span class="tw-flex-1"></span>
                    </mat-card-header>

                    @if (activeAvailability) {
                        <eaw-availability-days [availability]="activeAvailability"></eaw-availability-days>
                    } @else {
                        <mat-card-content>
                            <div class="mat-headline-5">{{ 'NO_AVAILABILITY' | translate:'company' | async }}</div>
                        </mat-card-content>
                    }
                </mat-card>
            }

            <!-- Hour distribution is not relevant for 100% contracts -->
            @if (activeContract && activeContract.percentage < 100) {
                <mat-card id="hour-distribution-card" class="info-card" [class.fetching]="fetchingActiveCombinedContract" [class.missing]="!activeHoursDistribution">
                    <mat-card-header>
                        <mat-card-title>{{ 'HOURS_DISTRIBUTION' | translate:'company' | async }}</mat-card-title>
                        <span class="tw-flex-1"></span>
                    </mat-card-header>

                    @if (activeHoursDistribution) {
                        <mat-card-content>
                            <eaw-hour-distribution-display [data]="activeHoursDistribution"></eaw-hour-distribution-display>
                        </mat-card-content>
                    } @else {
                        <mat-card-content>
                            <div class="mat-headline-5">{{ 'NO_HOURS_DISTRIBUTION' | translate:'company' | async }}</div>
                        </mat-card-content>
                    }
                </mat-card>
            }
        }
    </div>

    <mat-accordion multi>
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>{{ 'PAY_RATE' | translate | async }}</mat-panel-title>
            </mat-expansion-panel-header>

            <mat-checkbox id="include-deleted-payrates-checkbox" eawCheckboxHelper [formControl]="includeDeletedPayrates">
                {{ 'INCLUDE_DELETED_PAYRATE_plural' | translate: 'company' | async }}
            </mat-checkbox>

            <eaw-data-table
                    skipQueryParams
                    #payRateDataTable
                    [rowClasses]="payRateRowClasses"
                    [columns]="payRateColumns"
                    [request]="payRateRequest"
                    initialSortBy="from"
                    (paginationChange)="updatePayRateTable($event)"/>
        </mat-expansion-panel>

        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>{{ 'CONTRACT' | translate | async }}</mat-panel-title>
            </mat-expansion-panel-header>

            <eaw-data-table skipQueryParams
                            #contractDataTable
                            [rowClasses]="contractRowClasses"
                            [columns]="contractsColumns"
                            [request]="contractsRequest"
                            (paginationChange)="updateContractsTable($event)"/>
        </mat-expansion-panel>
    </mat-accordion>
</div>
