import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AttachmentListDialogComponent } from './attachment-list-dialog.component';
import { DialogData } from '../dialog-component';

export interface AttachmentListDialogData extends DialogData {
    title?: Promise<string>;
    subtitle?: Promise<string>;
    // URL to get the image from
    urls: Observable<string[]>;
}

@Injectable({
    providedIn: 'root',
})
export class AttachmentListDialogService {

    constructor(
        @Inject(MatDialog) private matDialog: MatDialog,
    ) {
    }

    open(data: AttachmentListDialogData) {
        return this.matDialog.open(AttachmentListDialogComponent, {
            data,
        });
    }
}
