<eaw-dialog-header>
    <span title>{{ report.name }}</span>
</eaw-dialog-header>

<div mat-dialog-content>
    <form [formGroup]="formGroup">
        <mat-form-field>
            <mat-label>{{ 'FORMAT' | translate: 'reports' | async }}</mat-label>
            <mat-select formControlName="format" required>
                @for (format of formats | keyvalue; track format.key) {
                    <mat-option [value]="format.key">{{ format.value }}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field eawDatePickerOptions>
            <mat-label>{{ 'ENTER_DATE_RANGE' | translate | async }}</mat-label>
            <mat-date-range-input formGroupName="interval" [rangePicker]="picker" required>
                <input required matStartDate [placeholder]="'START_DATE' | translate | async" formControlName="from">
                <input required matEndDate [placeholder]="'END_DATE' | translate | async" formControlName="to">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <form [formGroup]="optionsGroup">
            @for (option of options() | keyvalue; track option.key) {
                @if (!loading && optionsGroup.controls[option.key]) {
                    @switch (option.value.type) {
                        @case ('text') {
                            <!--                text             -->
                            <mat-form-field>
                                <mat-label>{{ option.key.toUpperCase() | translate: 'reports' | async }}</mat-label>
                                <input type="text" [formControlName]="option.key" matInput>
                            </mat-form-field>
                        }
                        @case ('date') {
                            @if (!option.value.time) {
                                <!--                date           -->
                                <mat-form-field eawDatePickerOptions>
                                    <mat-label>{{ option.key.toUpperCase() | translate: 'reports' | async }}</mat-label>
                                    <input [formControlName]="option.key" matInput [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            } @else {
                                <!--                date-time           -->
                                <mat-form-field>
                                    <mat-label>{{ option.key.toUpperCase() | translate: 'reports' }}</mat-label>
                                    <eaw-date-time-input [formControlName]="option.key"></eaw-date-time-input>
                                </mat-form-field>
                            }
                        }
                        @case ('boolean') {
                            <!--                boolean             -->
                            <mat-slide-toggle [formControlName]="option.key" (change)="chooseOption(option.key)">
                                {{ option.key.toUpperCase() | translate: 'reports' | async }}
                            </mat-slide-toggle>
                        }
                        @case ('select') {
                            @if (!option.value.multiple) {
                                <!--                select             -->
                                <mat-form-field>
                                    <mat-label>{{ option.key.toUpperCase() | translate: 'reports' | async }}</mat-label>
                                    <mat-select [formControlName]="option.key" (change)="chooseOption(option.key)">
                                        @for (selectOption of option.value.options | keyvalue: sortOptions; track selectOption.key) {
                                            <mat-option [value]="selectOption.key">{{ selectOption.value | translateSync:'reports' }}</mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            } @else {
                                <!--        select multiple            -->
                                <eaw-report-select [formControlName]="option.key" [option]="option.value" [key]="option.key"/>
                            }
                        }
                        @case ('employee-select') {
                            @if (!option.value.multiple) {
                                <!--                employee-select     -->
                                <eaw-employee-select [employees]="option.value.options"
                                                     [customerIds]="getCustomerIds(option.value)"
                                                     [formControlName]="option.key">
                                </eaw-employee-select>
                            } @else {
                                <!--                employee-select-multiple     -->
                                <eaw-employee-select multiple
                                                     [employees]="option.value.options"
                                                     [customerIds]="getCustomerIds(option.value)"
                                                     [formControlName]="option.key">
                                </eaw-employee-select>
                            }
                        }
                    }
                }
            }
        </form>

        @if (loading) {
            <div class="spinner">
                <mat-spinner></mat-spinner>
            </div>
        }
    </form>
</div>

<div mat-dialog-actions>
    <button [mat-dialog-close]="null" mat-button>{{ 'CANCEL' | translate | async }}</button>
    <button (click)="generate()"
            [disabled]="generating || loading || optionsGroup.invalid || formGroup.invalid"
            mat-button
            mat-raised-button
            color="primary">{{ 'GENERATE_REPORT' | translate: 'reports' | async }}
    </button>
</div>
