import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { Observable } from 'rxjs';
import { ShiftSwapResponse } from '../interfaces/shift-swap-response';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { ShiftSwap } from '../models/shift-swap';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { DateTime } from 'luxon';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';

type GetAllOptions = PaginationOptions & {
    handled?: boolean
    approved?: boolean
    can_approve?: boolean
    can_apply?: boolean
    outdated?: boolean
};

type GetAllForEmployeeOptions = PaginationOptions & {
    approved?: boolean
    from?: DateTime | null,
    to?: DateTime | null,
    only_from?: boolean;
    only_to?: boolean;
};

type UpdateOptions = {
    to_id?: number
    approved?: boolean
    comment?: string
}

@Injectable({
    providedIn: 'root',
})
export class ShiftSwapService {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
    ) { }

    getAll(customerId: number, options?: GetAllOptions): Observable<ArrayPaginatedResponse<ShiftSwap>> {
        return this.http.get<ArrayPaginatedResponse<ShiftSwapResponse>>(`/customers/${customerId}/shift_swaps`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(ShiftSwap));
    }

    getAllForEmployee(customerId: number, employeeId: number, options?: GetAllForEmployeeOptions) {
        return this.http.get<ArrayPaginatedResponse<ShiftSwapResponse>>(`/customers/${customerId}/employees/${employeeId}/shift_swaps`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(ShiftSwap));
    }

    create(customerId: number, shiftId: number, toEmployeeId?: number, comment?: string) {
        return this.http.post<ShiftSwapResponse>(`/customers/${customerId}/shift_swaps`, {
            shift_id: shiftId,
            to_id: toEmployeeId,
            comment: comment || undefined,
        }).pipe(classifyItem(ShiftSwap));
    }

    update(customerId: number, swapId: number, updates: UpdateOptions): Observable<ShiftSwap> {
        return this.http.put<ShiftSwapResponse>(`/customers/${customerId}/shift_swaps/${swapId}`, {
            ...updates,
        }).pipe(classifyItem(ShiftSwap));
    }

    /**
     * Deletes a swap for customer or an employee
     * @param customerId
     * @param swapId
     * @param employeeId
     */
    delete(customerId: number, swapId: number, employeeId?: number): Observable<undefined> {
        if (employeeId) {
            return this.http.delete<undefined>(`/customers/${customerId}/employees/${employeeId}/shift_swaps/${swapId}`);
        }

        return this.http.delete<undefined>(`/customers/${customerId}/shift_swaps/${swapId}`);
    }
}
