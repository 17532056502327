// @ts-nocheck
import { module } from 'angular';
import { Storage } from '../../../../../../shared/utils/storage';

module('eaw.scheduling').service('scheduleMode', function() {
    const scheduleSortingKey = 'schedule:sorting';
    let _mode;

    const service = {
        sorting: undefined,
        getMode() {
            return _mode || 'select';
        },
        setMode(mode) {
            _mode = mode;
        },
        async getSorting() {
            service.sorting = await Storage.getItem(Storage.prefix(scheduleSortingKey));

            return {
                field: service.sorting?.field || 'offset',
                direction: service.sorting?.direction || 'asc',
            };
        },
        setSorting(field, direction) {
            service.sorting = {
                field,
                direction,
            };

            void Storage.setItem(Storage.prefix(scheduleSortingKey), service.sorting);
        },
    };

    return service;
});
