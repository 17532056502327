import { Inject, Injectable } from '@angular/core';
import { DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { HrFileType } from '../../models/hr-file-type';
import { CreateHrDefaultFileDialogComponent } from './create-hr-default-file-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { SetFormFieldDialogComponent } from '../../../admin/dialogs/set-form-field-dialog/set-form-field-dialog.component';

// Interface for sending data to dialog
export interface CreateHrDefaultFileData extends DialogData {
  customerId: number;
  filetype?: HrFileType,
  filetypes?: HrFileType[],
  file?: File,
  description?: string;
  name?: string
  id?: number;
}

@Injectable({
    providedIn: 'root',
})
export class CreateHrDefaultDialogService {

    constructor(
      @Inject(MatDialog) private dialog: MatDialog,
      @Inject(ConfirmDialogService) private confirmDialog: ConfirmDialogService,
      @Inject(TranslateService) private translate: TranslateService,
    ) { }

    // Opens create hr default file dialog, sends data if edit
    openDialog(data: CreateHrDefaultFileData) {
        return this.dialog.open<CreateHrDefaultFileDialogComponent, CreateHrDefaultFileData, true>(CreateHrDefaultFileDialogComponent, {
            data: {
                size: DialogSize.Medium,
                ...data,
            },
        });
    }

    formFieldDialog(data: CreateHrDefaultFileData) {
        return this.dialog.open<SetFormFieldDialogComponent, CreateHrDefaultFileData, true>(SetFormFieldDialogComponent, {
            data: {
                size: DialogSize.Large,
                ...data,
            },
        });
    }

    deleteFileDialog(name: string) {
        return this.confirmDialog.delete({
            name,
            title: this.translate.t('DELETE_DEFAULT_FILE', 'hr'),
        });
    }
}
