import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { map, Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { CountryRegion, CountryRegionResponse } from '../models/country-region';
import { HttpClient, HttpContext } from '@angular/common/http';
import { IGNORE_ERROR } from './http-contexts';

@Injectable({
    providedIn: 'root',
})
export class CountryRegionService {
    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {
    }

    /**
     * TODO Add own route to get region in API
     * @param countryCode
     * @param regionId
     */
    get(countryCode: string, regionId: number): Observable<CountryRegion | undefined> {
        return this.http.get<ArrayPaginatedResponse<CountryRegionResponse>>(`/countries/${countryCode}/regions`, {
            params: {
                per_page: 100,
            },
        }).pipe(
            map((resp) => {
                const region = resp.data.find((r) => r.id === regionId);
                return region ? new CountryRegion(region) : undefined;
            }),
        );
    }

    getAll(countryCode: string, options?: PaginationOptions): Observable<ArrayPaginatedResponse<CountryRegion>> {
        return this.http.get<ArrayPaginatedResponse<CountryRegionResponse>>(`/countries/${countryCode}/regions`, {
            params: { ...options },
            context: new HttpContext().set(IGNORE_ERROR, [ 404 ]),
        }).pipe(
            map((resp) => {
                return {
                    ...resp,
                    data: resp.data.map((d) => new CountryRegion(d)),
                };
            }),
        );
    }
}
