// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module, noop } from 'angular';

const template1 = `<md-dialog>
    <form name="addLinkForm" ng-cloak>
        <eaw-dialog-header-old ng-if="!alCtrl.edit" heading="'admin:ADD_LINK' | i18next"></eaw-dialog-header-old>
        <eaw-dialog-header-old ng-if="alCtrl.edit" heading="'admin:EDIT_LINK' | i18next"></eaw-dialog-header-old>

        <md-dialog-content>
            <div class="md-dialog-content" layout="column">
                <md-autocomplete
                        required
                        flex="100"
                        md-selected-item="alCtrl.customer"
                        md-search-text="custFilter"
                        md-floating-label="{{'CUSTOMER' | i18next}}"
                        md-items="cust in alCtrl.getCustomers(custFilter)"
                        md-item-text="cust.name"
                        md-delay="300"
                        md-clear-button="true"
                        md-min-length="0">
                    <md-item-template>
                        <span md-highlight-text="custFilter" md-highlight-flags="gi">{{cust.name}}</span>
                    </md-item-template>
                    <md-not-found>
                        <span ng-i18next="NO_MATCH_FOUND"></span>
                    </md-not-found>
                </md-autocomplete>

                <md-input-container>
                    <label ng-i18next="TYPE"></label>
                    <input ng-model="alCtrl.type" required>
                </md-input-container>

                <eaw-date-time-interval ng-model="alCtrl.interval" from-required="true"></eaw-date-time-interval>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button ng-click="alCtrl.submit()" ng-i18next="SUBMIT" ng-disabled="addLinkForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.admin').controller('adminCustomerRelationsTabCtrl', [ 'customer', 'CustomerService', 'ToastService', '$mdDialog', function(customer, CustomerService, ToastService, $mdDialog) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.customer = { ...customer };

        ctrl.table = {
            columns: [
                { title: t('ID') },
                { title: t('NAME') },
                { title: t('RELATIONSHIP') },
                { title: t('FROM') },
                { title: t('TO') },
                { title: t('TYPE') },
                { title: '' },
            ],
            cells: [
                { template: 'item.id' },
                { template: 'item.relationName' },
                { template: 'item.relationship' },
                { template: 'item.from | moment:"LLLL"' },
                { template: 'item.to | moment:"LLLL"' },
                { template: 'item.type' },
                {
                    buttons: [
                        {
                            icon: 'edit',
                            click: ctrl.addEditLink,
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            click: ctrl.removeLink,
                        },
                    ],
                },
            ],
        };
    };

    ctrl.getData = (pagination) => CustomerService.getAllLinks({
        id: ctrl.customer.id,
        ...pagination.toWithPaginatorArguments(),
    }).$promise.then((resp) => {
        resp.data.forEach((d) => {
            if (ctrl.customer.id == d.child.id) {
                d.relationId = d.parent.id;
                d.relationName = d.parent.name;
                d.relationship = t('admin:PARENT_LOCATION');
            } else {
                d.relationId = d.child.id;
                d.relationName = d.child.name;
                d.relationship = t('admin:CHILD_LOCATION');
            }
        });

        return resp;
    });

    ctrl.removeLink = (link) => {
        $mdDialog.show(
            $mdDialog.confirm()
                .theme('delete')
                .title('Delete link')
                .textContent(t('admin:THIS_REMOVES_LINK_BETWEEN', {
                    parent: link.parent.name,
                    child: link.child.name,
                }))
                .ok(t('DELETE'))
                .cancel(t('CANCEL')),
        ).then(() => {
            CustomerService.removeLink(ctrl.customer, link).$promise.then(() => {
                ToastService.toast('Link deleted');
                ctrl.reloadTable = true;
            });
        });
    };

    ctrl.addEditLink = (link) => {
        $mdDialog.show({
            template: template1,
            controllerAs: 'alCtrl',
            controller: function() {
                const alCtrl = this;

                alCtrl.$onInit = () => {
                    alCtrl.edit = !!link;

                    if (link) {
                        alCtrl.customer = link.child.id === ctrl.customer.id ? link.parent : link.child;
                        alCtrl.type = link.type;
                        alCtrl.interval = {
                            from: link.from,
                            to: link.to,
                        };
                    }
                };

                alCtrl.cancel = $mdDialog.cancel;

                alCtrl.getCustomers = (filter) => CustomerService.getAll({
                    filter,
                }).then((data) => data.data);

                alCtrl.add = () => {
                    CustomerService.addLink(ctrl.customer, alCtrl.customer, alCtrl.type, alCtrl.interval?.from, alCtrl.interval?.to).$promise.then(() => {
                        $mdDialog.hide();
                        ctrl.reloadTable = true;
                        ToastService.toast('Added new relation');
                    }, noop);
                };

                alCtrl.update = () => {
                    const isParent = link.parent.id === ctrl.customer.id; // Parent is me?
                    const parent = isParent ? ctrl.customer : alCtrl.customer;
                    const child = isParent ? alCtrl.customer : ctrl.customer;
                    CustomerService.updateLink(parent, child, alCtrl.interval?.from, alCtrl.interval?.to, alCtrl.type).$promise.then(() => {
                        $mdDialog.hide();
                        ctrl.reloadTable = true;
                        ToastService.toast('Updated relation');
                    }, noop);
                };

                alCtrl.submit = () => {
                    if (alCtrl.edit) {
                        alCtrl.update();
                    } else {
                        alCtrl.add();
                    }
                };
            },
        });
    };
} ]);
