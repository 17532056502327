import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { TranslateService } from '../../../shared/services/translate.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgFor } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { Namespace } from '../../../shared/enums/namespace';
import { MatRippleModule } from '@angular/material/core';
import { Products } from '../../../shared/enums/products';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../shared/services/current.service';
import { filter, map, of, reduce, switchMap, tap } from 'rxjs';

interface Page {
    icon: string;
    title: Promise<string>;
    description: Promise<string>;
    route: string;
    products: Products[];
    permissions: {
        required?: string[];
        someRequired?: string[];
    }
}

@Component({
    selector: 'eaw-analytics-frontpage',
    templateUrl: './analytics-frontpage.component.html',
    styleUrl: './analytics-frontpage.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgFor,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
        MatRippleModule,
        MatProgressSpinnerModule,
    ],
})
export class AnalyticsFrontpageComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);
    private readonly uiRouter = inject(UIRouter);
    private readonly translate = inject(TranslateService);
    private readonly currentService = inject(CurrentService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly customerProductService = inject(CustomerProductService);

    @Input({ required: true }) customerId!: number;

    loading = false;
    pages: Page[] = [];
    pageData: Page[] = [
        {
            icon: 'groups',
            title: this.translate.t('STAFFING_FORECAST', Namespace.Analytics),
            description: this.translate.t('STAFFING_FORECAST_PAGE_DESCRIPTION', Namespace.Analytics),
            route: 'eaw/app/analytics/staffing_forecast',
            products: [ Products.StaffingForecast ],
            permissions: {
                required: [ 'customers.{customer}.staffing_forecast.get' ],
            },
        },
        {
            icon: 'more_time',
            title: this.translate.t('PLUS_HOURS_MONITORING', Namespace.Analytics),
            description: this.translate.t('PLUS_HOURS_MONITORING_DESCRIPTION', Namespace.Analytics),
            route: 'eaw/app/analytics/plus_hours',
            products: [ Products.France ],
            permissions: {
                required: [ 'customers.{customer}.plusHours.france.get' ],
            },
        },
        {
            icon: 'nightlight_round',
            title: this.translate.t('WEEKLY_NIGHT_HOURS_REPORT_PAGE', 'analytics'),
            description: this.translate.t('WEEKLY_NIGHT_WORK_REPORT_PAGE_DESCRIPTION', 'analytics'),
            route: 'eaw/app/analytics/weekly_night_hours',
            products: [ Products.France ],
            permissions: {
                required: [ 'customers.{customer}.timepunches.*.get' ],
            },
        },
        {
            icon: 'nightlight',
            title: this.translate.t('ANNUAL_NIGHT_HOURS_REPORT_PAGE', 'analytics'),
            description: this.translate.t('ANNUAL_NIGHT_HOURS_REPORT_PAGE_DESCRIPTION', 'analytics'),
            route: 'eaw/app/analytics/annual_night_hours',
            products: [ Products.France ],
            permissions: {
                required: [ 'customers.{customer}.timepunches.*.get' ],
            },
        },
    ];

    ngOnInit() {
        this.loading = true;
        of(...this.pageData).pipe(
            switchMap((page) => this.customerProductService.hasProducts(this.customerId, page.products).pipe(map((hasProducts) => hasProducts ? page : null))),
            filter((page) => !!page),
            switchMap((page) => this.permissionCheckService.isAllowedMany(page.permissions.required || [], page.permissions.someRequired || []).pipe(map((hasPermission) => hasPermission ? page : null))),
            reduce((acc, page) => {
                if (page) {
                    acc.push(page);
                }

                return acc;
            }, [] as Page[]),
            tap((pages) => {
                this.pages = sort(pages, this.currentService.languageTag, [ (page) => page.title ]);
                this.loading = false;
            }),
        ).subscribe();
    }

    goToPage(route: string): void {
        this.uiRouter.stateService.go(route);
    }
}
