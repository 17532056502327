// @ts-nocheck
import { module } from 'angular';
module('eaw.components').component('attachmentList', {
    template: `<div class="attachment-list-item" ng-repeat="attachment in $list.attachments track by attachment.id">
    <md-progress-circular md-diameter="25" ng-if="!attachment.loaded"></md-progress-circular>
    <img image-attachment
         class="mat-elevation-z2"
         ng-click="$list.openImages($index)"
         get-image-url="$list.getImage(attachment)"
         attachment="attachment"/>
</div>


`,
    bindings: {
        getImageUrl: '&', // getImageUrl(attachment) returns a promise (see imageAttachment directive).
    },
    controllerAs: '$list',
    require: {
        ngModel: 'ngModel', // Attachments, concat seems to work best
    },
    controller: [ 'ImageGallery', function(ImageGallery) {
        this.$onInit = () => {
            this.ngModel.$formatters.unshift(this.formatModel);
        };
        this.formatModel = (val) => {
            val?.forEach((att) => {
                if (!this.attachments?.find((a) => a.id === att.id)) {
                    this.attachments = (this.attachments || []).concat(att);
                }
            });
            return val;
        };
        this.openImages = (index) => {
            return ImageGallery.open({
                index,
                items: this.attachments.map((a) => {
                    return {
                        source: a.objectUrl,
                        name: a.name,
                    };
                }),
            });
        };
        this.getImage = (attachment) => this.getImageUrl({ attachment }).finally(() => {
            attachment.loaded = true;
        });
    } ],
});
