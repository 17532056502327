import { ChangeDetectionStrategy, Component, ComponentRef, computed, input, reflectComponentType, signal, viewChild, ViewContainerRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MaterialColorDirective } from '../../../../../shared/directives/material-color.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { ComponentType } from '@angular/cdk/overlay';
import { EawMaterialColorHue } from '../../../../../shared/services/material-color.service';
import { Namespace, NamespaceFile } from '../../../../../shared/enums/namespace';
import { SidebarNotImplementedComponent } from './components/sidebar-not-implemented/sidebar-not-implemented.component';
import { PermissionDirective } from '../../../../../permissions/directives/permission.directive';
import { PermissionsInputValue } from '../../../../../permissions/services/element-permission.service';
import { ScheduleComponent } from '../../schedule.component';
import { ScheduleSettingsComponent } from '../../../../components/schedule-settings/schedule-settings.component';
import { Products } from '../../../../../shared/enums/products';
import { ApiModel, ApiModelClass } from '../../../../../shared/enums/api-model';

interface ScheduleSidebarItem {
    name: string;
    component?: ComponentType<any>;
    click?: () => void;
    exclude?: boolean;
    /**
     * Permissions used by the {@link PermissionDirective} to show or hide the item.
     */
    permissions?: PermissionsInputValue,
    /**
     * Some permissions used by the {@link PermissionDirective} to show or hide the item.
     */
    somePermissions?: PermissionsInputValue,
    text: { key: string, ns?: NamespaceFile };
    icon: string;
    // Custom color that defaults to black
    color?: EawMaterialColorHue;
}

@Component({
    selector: 'eaw-schedule-sidebar',
    standalone: true,
    imports: [
        MatIcon,
        MatIconButton,
        MaterialColorDirective,
        MatTooltip,
        TranslatePipe,
        AsyncPipe,
        PermissionDirective,
    ],
    templateUrl: './schedule-sidebar.component.html',
    styleUrl: './schedule-sidebar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleSidebarComponent {
    private componentHost = viewChild.required('componentHost', { read: ViewContainerRef });

    customerId = input.required<number>();
    scheduleId = input.required<number>();
    stackId = input.required<number>();

    activeItem = signal<ScheduleSidebarItem | undefined>(undefined);
    activeComponent = signal<ComponentRef<any> | undefined>(undefined);
    sidebarItems = computed(this.computeSidebarItems.bind(this));

    handleItem(item: ScheduleSidebarItem) {
        if ('click' in item && item.click) {
            item.click();
        } else if (item.component) {
            this.activeComponent()?.destroy();

            const isActive = this.activeItem()?.name === item.name;
            if (isActive) {
                this.activeItem.set(undefined);
            } else {
                this.activeItem.set(item);
                this.createComponent(item.component);
            }
        }
    }

    private notify() {

    }

    private publish() {

    }

    private unpublish() {

    }

    private computeSidebarItems() {
        const schedule = ScheduleComponent.schedule();
        const properties = ScheduleComponent.properties;
        const unpublishThreshold = 14;
        const products = ScheduleComponent.products();

        const items: ScheduleSidebarItem[] = [
            {
                name: 'unpublish',
                click: this.unpublish.bind(this),
                permissions: [
                    [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].unpublish`, this.permissionOptions(this.stackId(), ApiModel.Customer, ApiModel.Schedule) ],
                ],
                exclude: !schedule?.isPublished || schedule.from.diffNow('days').days < unpublishThreshold,
                text: { key: 'UNPUBLISH', ns: Namespace.Scheduling },
                icon: 'cancel',
            },
            {
                name: 'publish',
                click: this.publish.bind(this),
                somePermissions: [
                    [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].publish`, this.permissionOptions(this.stackId(), ApiModel.Customer, ApiModel.Schedule) ],
                    [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].force_publish`, this.permissionOptions(this.stackId(), ApiModel.Customer, ApiModel.Schedule) ],
                ],
                exclude: schedule?.isPublished || schedule?.isTemplate,
                text: { key: 'PUBLISH', ns: Namespace.Scheduling },
                icon: 'send',
            },
            {
                name: 'notify',
                click: this.notify.bind(this),
                permissions: [
                    [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].update`, this.permissionOptions(this.stackId(), ApiModel.Customer, ApiModel.Schedule) ],
                ],
                exclude: schedule?.isPublished || schedule?.isTemplate || !properties.customer.scheduleAuditorGroupId.value() || properties.schedule.auditorsNotified.value(),
                text: { key: 'NOTIFY_AUDITORS', ns: Namespace.Scheduling },
                color: 'amber-500',
                icon: 'notifications_active',
            },
            {
                name: 'settings',
                component: ScheduleSettingsComponent,
                text: { key: 'SETTING_plural' },
                icon: 'settings',
            },
            {
                name: 'comments',
                component: SidebarNotImplementedComponent,
                exclude: schedule?.isTemplate || !products.has(Products.ScheduleComments),
                text: { key: 'COMMENT_plural', ns: Namespace.Scheduling },
                icon: 'comment',
                color: 'teal-500',
            },
            {
                name: 'business-units-drag-and-drop',
                component: SidebarNotImplementedComponent,
                permissions: [
                    [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].shifts.update`, this.permissionOptions(this.stackId(), ApiModel.Customer, ApiModel.Schedule) ],
                ],
                exclude: !products.has(Products.BusinessUnits),
                text: { key: 'UNIT_SELECTOR', ns: Namespace.Scheduling },
                icon: 'work',
            },
            {
                name: 'scheduling-limits',
                component: SidebarNotImplementedComponent,
                exclude: schedule?.isTemplate || !products.has(Products.SchedulingLimits),
                text: { key: 'LIMIT_plural', ns: Namespace.Scheduling },
                icon: 'schedule',
                color: 'brown-500',
            },
            {
                name: 'employee-drag-and-drop',
                component: SidebarNotImplementedComponent,
                exclude: !(products.has(Products.SchedulingEmployeeSidebar) || products.has(Products.Availability)),
                text: { key: 'EMPLOYEE_SELECTOR', ns: Namespace.Scheduling },
                icon: 'switch_account',
            },
            {
                name: 'budget',
                permissions: [
                    [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].summary`, this.permissionOptions(this.stackId(), ApiModel.Customer, ApiModel.Schedule) ],
                ],
                exclude: schedule?.isTemplate || !products.has(Products.Budgeting),
                component: SidebarNotImplementedComponent,
                text: { key: 'BUDGET', ns: Namespace.Scheduling },
                icon: 'payments',
                color: 'green-500',
            },
            {
                name: 'balances',
                component: SidebarNotImplementedComponent,
                permissions: [ `customers.[${ApiModel.Customer}].balance_types.*.get` ],
                somePermissions: [
                    [ `customers.[${ApiModel.Customer}].employees.*.balances.*.*.get`, this.permissionOptions(this.stackId(), ApiModel.Customer) ],
                    [ `customers.[${ApiModel.Customer}].employee_balances.manage`, this.permissionOptions(this.stackId(), ApiModel.Customer) ],
                ],
                exclude: schedule?.isTemplate,
                text: { key: 'BALANCES', ns: Namespace.Navigation },
                icon: 'exposure',
                color: 'green-500',
            },
            {
                name: 'absence',
                component: SidebarNotImplementedComponent,
                exclude: !products.has(Products.Absence),
                text: { key: 'ABSENCE', ns: Namespace.Scheduling },
                icon: 'hotel',
                color: 'blue-grey-500',
            },
            {
                name: 'leave-shifts',
                component: SidebarNotImplementedComponent,
                permissions: [
                    [ `customers.[${ApiModel.Customer}].leave_shifts.*.get`, this.permissionOptions(this.stackId(), ApiModel.Customer) ],
                ],
                exclude: !products.has(Products.LeaveShifts),
                text: { key: 'LEAVE_SHIFTS', ns: Namespace.Navigation },
                icon: 'schedule',
                color: 'grey-500',
            },
            {
                name: 'employee-hours',
                component: SidebarNotImplementedComponent,
                permissions: [
                    [ `customers.[${ApiModel.Customer}].employees.*.contracts.*.get`, this.permissionOptions(this.stackId(), ApiModel.Customer) ],
                ],
                exclude: schedule?.isTemplate,
                text: { key: 'HOURS_PER_CONTRACT_TYPE', ns: Namespace.Scheduling },
                icon: 'schedule',
                color: 'brown-500',
            },
            {
                name: 'standby-list',
                exclude: !schedule?.isPublished,
                component: SidebarNotImplementedComponent,
                text: { key: 'STANDBY_plural', ns: Namespace.Scheduling },
                icon: 'person',
            },
            {
                name: 'day-warnings',
                component: SidebarNotImplementedComponent,
                text: { key: 'WARNING_plural', ns: Namespace.Scheduling },
                icon: 'warning',
                color: 'amber-500',
            },
            {
                name: 'shift-periods',
                component: SidebarNotImplementedComponent,
                text: { key: 'PERIOD_plural', ns: Namespace.Scheduling },
                icon: 'timelapse',
                color: 'blue-500',
            },
            {
                name: 'statistics',
                permissions: [
                    [ `customers.[${ApiModel.Customer}].employees.*.contracts.*.get`, this.permissionOptions(this.stackId(), ApiModel.Customer) ],
                ],
                component: SidebarNotImplementedComponent,
                text: { key: 'STATISTIC_plural' },
                icon: 'table_chart',
                color: 'brown-500',
            },
            {
                name: 'vacation',
                component: SidebarNotImplementedComponent,
                exclude: schedule?.isTemplate || !products.has(Products.Vacation),
                text: { key: 'OFFTIME_plural' },
                icon: 'flight_takeoff',
            },
            {
                name: 'events',
                component: SidebarNotImplementedComponent,
                exclude: schedule?.isTemplate || !products.has(Products.Calendar),
                text: { key: 'EVENT_plural', ns: Namespace.Scheduling },
                icon: 'celebration',
                color: 'red-500',
            },
            {
                name: 'position-cost',
                component: SidebarNotImplementedComponent,
                permissions: [
                    [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].summary`, this.permissionOptions(this.stackId(), ApiModel.Customer, ApiModel.Schedule) ],
                ],
                exclude: schedule?.isTemplate || !products.has(Products.Budgeting),
                text: { key: 'POSITION_COST', ns: Namespace.Scheduling },
                icon: 'payments',
                color: 'green-500',
            },
        ];

        return items.filter((i) => !i.exclude);
    }

    private permissionOptions(stackId: number, ...models: (ApiModel | ApiModelClass)[]) {
        return {
            stackId,
            models: [
                { type: ApiModel.Customer, id: this.customerId() },
                { type: ApiModel.Schedule, id: this.scheduleId() },
            ].filter((m) => models.includes(m.type)),
        };
    }

    private createComponent(sidebarComponent: ComponentType<any>) {
        const component = this.componentHost().createComponent(sidebarComponent);

        const componentMirror = reflectComponentType(sidebarComponent);
        const inputs = [
            [ 'customerId', this.customerId() ],
            [ 'scheduleId', this.scheduleId() ],
            [ 'stackId', ScheduleComponent.customer()?.stackId || 0 ],
        ] as const;

        inputs.forEach(([ inputName, inputValue ]) => {
            const input = componentMirror?.inputs.find((i) => i.propName === inputName);
            if (input) {
                component.setInput(inputName, inputValue);
            }
        });

        this.activeComponent.set(component);
    }
}
