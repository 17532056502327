// @ts-nocheck

import { module } from 'angular';

module('eaw.scheduling').factory('scheduleAvailabilityOverviewService', [ 'Availability', function(Availability) {
    const srv = this;
    let _availability;

    srv.init = (schedule) => {
        srv.schedule = schedule;
        _availability = {};

        if (srv.resource) {
            srv.resource.$cancelRequest();
        }

        srv.availability = null;
        srv.resource = null;
    };

    srv.getOverview = () => {
        if (!srv.resource) {
            srv.resource = getOverview();
            srv.resource.$promise.then((resp) => {
                srv.availability = resp.data;
            });
        }

        return srv.resource.$promise.then(() => srv.availability);
    };

    srv.clearDay = (date) => {
        const key = date.format('Y-M-D');
        if (_availability[key]) {
            delete _availability[key];
        }
    };

    srv.getOnDay = (date, pagination) => {
        const key = date.format('Y-M-D');

        if (!_availability[key]) {
            _availability[key] = Availability.getOnDay({
                business_date: date,
                customer_id: srv.schedule.customer_id,
                schedule_id: srv.schedule.id,
                ...pagination,
            }).then((resp) => resp.data);
        }

        return _availability[key];
    };

    function getOverview() {
        const length = srv.schedule.getTo().diff(srv.schedule.from, 'w', true);

        return Availability.getOverview({
            from: srv.schedule.getFrom().startOf('d'),
            weeks: Math.ceil(length),
            customerId: srv.schedule.customer_id,
        });
    }

    srv.isNotAvailable = (day, index, employeeId) => {
        // Separated out array access to ease debugging
        const availability = srv.availability?.[employeeId];
        const week = availability?.weeks?.[index];
        const weekday = week?.[day];

        return Array.isArray(weekday) && !weekday.length;
    };

    return srv;
} ]);
