import { ApiResponse } from '../../shared/interfaces/api-response';

export interface WeekSetupResponse extends ApiResponse {
    customer_id: number;
    id: number;
    name: string;
}

export class WeekSetup {
    customerId: number;
    id: number;
    name: string;

    constructor(data: WeekSetupResponse) {
        this.customerId = data.customer_id;
        this.id = data.id;
        this.name = data.name;
    }
}
