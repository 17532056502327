import { Component, inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { NotificationService } from '../../http/notification.service';
import { CurrentService } from '../../services/current.service';
import { BasicDataSource } from '../../utils/basic-data-source';
import { Notification } from '../../../notifications/models/notification';
import { UIRouter } from '@uirouter/core';
import { BadgerService } from '../../services/badger.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { map, Observable, tap } from 'rxjs';
import { ArrayPaginatedResponse } from '../../interfaces/paginated-response';

type NotificationItem = { access: boolean, customerName: string, notification: Notification };

@Component({
    selector: 'eaw-notifications-dialog',
    templateUrl: './notifications-dialog.component.html',
    styleUrl: './notifications-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class NotificationsDialogComponent extends DialogComponent<DialogData, void, NotificationsDialogComponent> implements OnInit {
    public readonly notificationService = inject(NotificationService);
    public readonly current = inject(CurrentService);
    public readonly uiRouter = inject(UIRouter);
    public readonly badger = inject(BadgerService);

    dataSource?: BasicDataSource<NotificationItem>;
    total?: number;

    constructor() {
        super(undefined, undefined, DialogSize.Medium);
    }

    ngOnInit(): void {
        const perPage = 10;

        this.notificationService.getAll(this.current.getUser().id, true, { per_page: perPage }).pipe(
            map(this.makeItems.bind(this)),
            tap((res) => {
                this.total = res.total;

                this.dataSource = new BasicDataSource<NotificationItem>(res.total, perPage, res.data, (page): Observable<ArrayPaginatedResponse<NotificationItem>> => {
                    return this.notificationService.getAll(this.current.getUser().id, true, {
                        per_page: perPage,
                        page,
                    }).pipe(map(this.makeItems.bind(this)));
                });
            })).subscribe();
    }

    goToRoute(item: NotificationItem) {
        const notification = item.notification;
        if (notification.type && notification.type.route) {
            this.uiRouter.stateService.go(`eaw/outside/notification-redirect`, { type: notification.type.class, data: notification._response.data });
            this.dialogRef.close();
        }
    }

    click(item: NotificationItem, index: number) {
        const notification = item.notification;

        if (!notification.readAt) {
            this.notificationService.markAsRead(this.current.getUser().id, notification.id).subscribe((res) => {
                this.dataSource?.replace(this.getItem(res), index);
                this.total = (this.total || 0) - 1;
                this.badger.updateBadge(this.current.getCustomer().id, 'notifications.unread', this.badger.getBadge(this.current.getCustomer().id, 'notifications.unread') - 1);
            });
        }

        if (item.access) {
            this.goToRoute(item);
        }
    }

    markAllAsRead() {
        this.notificationService.markAllAsRead(this.current.getUser().id).subscribe(() => {
            this.total = 0;
            this.badger.updateBadge(this.current.getCustomer().id, 'notifications.unread', 0);
        });
    }

    goToAll() {
        this.uiRouter.stateService.go('eaw/app/notifications');
    }

    private makeItems(response: ArrayPaginatedResponse<Notification>): ArrayPaginatedResponse<NotificationItem> {
        const page = response as unknown as ArrayPaginatedResponse<NotificationItem>;
        page.data = response.data.map((n) => {
            return this.getItem(n);
        });

        return page;

    }

    private getItem(n: Notification) {
        const customerName = this.current.getCustomer(n.getRouteParams()['customer_id'])?.name;

        return {
            access: !!customerName,
            customerName: customerName || '',
            notification: n,
        };
    }
}
