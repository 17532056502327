<eaw-page-header>
    <span title>{{ permissionSet ? permissionSet.name : '' }}</span>
</eaw-page-header>

@if (hasLoaded) {
    <eaw-permission-tree [permissionSetId]="permissionSetId" [permissionSet]="permissionSet"></eaw-permission-tree>
} @else {
    <div class="tw-grid tw-grid-cols-1 tw-items-center tw-justify-items-center">
        <mat-progress-spinner diameter="75" strokeWidth="4" mode="indeterminate"></mat-progress-spinner>
    </div>
}


