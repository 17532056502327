<div class="left overflow-ellipsis">
    <mat-icon *ngIf="icon" [style.color]="iconColor || 'black-500' | materialColor | async" aria-hidden="true">{{icon}}</mat-icon>

    <div mat-dialog-title class="title tw-flex tw-flex-col">
        <ng-content select="[title]"></ng-content>

        <span class="subtitle">
            <ng-content select="[subtitle]"></ng-content>
        </span>
    </div>
</div>

<div class="right">
    <ng-content select="[right]"></ng-content>

    <button *ngIf="showCloseButton" mat-icon-button aria-label="Close dialog" [mat-dialog-close]="closeValue">
        <mat-icon>close</mat-icon>
    </button>
</div>
