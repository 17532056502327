<mat-form-field>
    <mat-label *ngIf="label">{{ label | async }}</mat-label>

    <mat-icon *ngIf="icon" matIconPrefix>{{ icon }}</mat-icon>

    <div matPrefix *ngIf="_hasPrefix">
        <ng-content select="[eawPrefix]"></ng-content>
    </div>

    <input matInput name="search" [formControl]="filterControl" [matAutocomplete]="autocomplete" (focus)="focus()" autocomplete="off" [required]="_required">

    <div matSuffix>
        <mat-progress-spinner class="tw-mx-12" strokeWidth="3" diameter="24" *ngIf="loading" mode="indeterminate"></mat-progress-spinner>

        <button mat-icon-button *ngIf="!loading && !disabled && item" (click)="clear()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-hint *ngIf="filterRequirement">{{ 'X_CHARS_FOR_FILTER' | translate:'general':{count: filterRequirement} | async }}</mat-hint>
</mat-form-field>

<mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="autocompleteDisplay">
    <mat-option *ngFor="let item of items; trackBy: trackBy" [value]="item" [style.line-height]="'normal'" (onSelectionChange)="onSelectionChange($event)">
        <ng-container *ngIf="optionTemplate" [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
        <span *ngIf="!optionTemplate">{{ autocompleteDisplay(item) }}</span>
    </mat-option>

    <mat-option *ngIf="loading" disabled>
        {{ 'SEARCHING_ELLIPSIS' | translateSync }}
    </mat-option>

    <mat-option *ngIf="!loading && total === 0 && !filter" disabled>
        {{ 'NO_DATA' | translateSync }}
    </mat-option>

    <mat-option *ngIf="!loading && results === 0 && filter" disabled>
        {{ 'NO_HITS_FOR_FILTER' | translateSync: 'general':{filter: filter} }}
    </mat-option>

    <mat-option *ngIf="!loading && results > 0 && total > 0" disabled>
        {{ 'SHOWING_X_OF_TOTAL' | translateSync: 'general':{number: results, total: total} }}
    </mat-option>
</mat-autocomplete>

