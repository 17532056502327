import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApproveAbsenceDialogComponent, ApproveAbsenceDialogData } from './approve-absence-dialog/approve-absence-dialog.component';
import { ConfirmDialogData } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { Absence } from '../../models/absence';
import { AbsenceService } from '../../http/absence.service';
import { catchError, EMPTY, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HandleAbsenceDialogService {
    constructor(
        @Inject(MatDialog) public dialog: MatDialog,
        @Inject(ConfirmDialogService) public confirmDialog: ConfirmDialogService,
        @Inject(AbsenceService) public absenceService: AbsenceService,
    ) { }

    approve(absence: Absence) {
        return this.dialog.open<ApproveAbsenceDialogComponent, ApproveAbsenceDialogData, Absence>(ApproveAbsenceDialogComponent, {
            data: {
                absence,
            },
        });
    }

    decline(absence: Absence, options: ConfirmDialogData) {
        return new Observable<Absence | undefined>((observer) => {
            this.confirmDialog.open(options).afterClosed().subscribe((result) => {
                if (result?.ok) {
                    this.absenceService.decline(absence.customerId, absence.employeeId, absence.id, result.comment).pipe(
                        catchError(() => {
                            observer.complete();
                            return EMPTY;
                        }),
                    ).subscribe((result) => {
                        observer.next(result);
                        observer.complete();
                    });
                } else {
                    observer.next(undefined);
                    observer.complete();
                }
            });
        });
    }
}
