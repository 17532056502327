import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { Position, PositionResponse } from '../../shared/models/position';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { classifyArrayPaginatedResponse } from '../../shared/utils/rxjs/classify';

@Injectable({
    providedIn: 'root',
})
export class PositionService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, pagination?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<PositionResponse>>(`/customers/${customerId}/positions`, {
            params: formatHttpParams(pagination),
        }).pipe(classifyArrayPaginatedResponse(Position));
    }
}
