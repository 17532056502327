// @ts-nocheck
import { Model } from './model';
import Permission from '../interfaces/simple-permission';
import { snakeCase } from 'lodash-es';
import type { User } from '../models/user';

/**
 * @deprecated
 * @see User
 */
export class UserOld extends Model {
    allowed: Record<string, boolean>;
    language: { ietf_bcp47_tag: string };
    permissions: Record<string, Permission>;
    first_name: string;
    last_name: string;
    name: string;
    email: string;

    constructor(data) {
        super(data);

        this.formatPermissions();
        this.allowed = {};
    }

    get full_name() {
        return this.name;
    }

    get languageTag() {
        return this.language?.ietf_bcp47_tag;
    }

    formatPermissions() {
        Object.entries(this.permissions || {}).forEach(([ permission, value ]) => {
            if (typeof value === 'boolean') {
                this.permissions[permission] = {
                    node: permission,
                    value,
                };
            }
        });
    }

    /**
     * Check if the current user has some permission
     * @see User.can
     */
    can(permission: string, force?: boolean, models?: Record<string, Record<string, any>>): boolean
    can(permission: string, force: boolean, models: Record<string, Record<string, any>> | undefined, openEnded: boolean, returnAll: true): Permission[]
    can(permission: string, force?: boolean, models?: any, openEnded = false, returnAll = false): boolean | Record<string, Permission>[] {
        if (!Object.keys(this.permissions || {}).length) {
            return false;
        }
        if (!force && this.allowed[permission] != null) {
            return this.allowed[permission];
        }

        const all = [];

        this.allowed[permission] = (() => {
            const matches: { [key: number]: { wildcards: any, value: boolean }[] } = {};
            let maxWildcard = -1;

            Object.keys(this.permissions).forEach((node) => {
                let permNode = node;

                if (models) {
                    const params = node.matchAll(new RegExp(/\{[a-z_.]+=.+}/, 'g'));
                    for (const param of params) {
                        const [ k, v ] = param[0].substring(1, param[0].length - 1).split('=', 2);
                        const [ m, attr ] = k.split('.', 2);

                        if (!models[m] || (models[m][attr] ?? null) != v) {
                            return; // This permission is not relevant
                        }

                        permNode = permNode.replace(param[0], models[m].id);
                    }
                }

                if (openEnded && permNode.includes('.')) {
                    permNode = permNode.substring(0, permNode.lastIndexOf('.'));
                }

                let pattern = permNode.replace(/\./g, '\\.');

                if (!openEnded) {
                    pattern = pattern.replace(/\*$/, '.+');
                }

                pattern = pattern.replace(/\*/g, '(\\*|\\w+|\\-)+');

                if (!permission.match(new RegExp(`^${pattern}$`, 'i'))) {
                    return;
                }

                all.push(this.permissions[node]);

                let wildcard = permNode.indexOf('*');
                if (wildcard === -1) {
                    wildcard = permNode.length;
                }

                matches[wildcard] = matches[wildcard] || [];
                matches[wildcard].push({
                    ...this.permissions[node],
                    wildcards: this.permissions[node].node.split('*').length,
                });

                maxWildcard = wildcard > maxWildcard ? wildcard : maxWildcard;
            });

            return matches[maxWildcard]?.sort((a, b) => a.wildcards - b.wildcards)?.[0]?.value || false;
        })();

        if (returnAll) {
            return all;
        }

        return this.allowed[permission];
    }

    static override getMorphClass() {
        return 'user';
    }
}
