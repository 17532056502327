// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
module('eaw.availability').factory('AvailabilityDayFactory', [ 'EawResource', function(EawResource) {
    const factory = this;
    const resource = EawResource.create('/customers/:customer_id/employees/:employee_id/availabilities/:availability_id/days/:day_id');
    factory.get = (options) => resource.get({
        customer_id: options.customer ? options.customer.id : CurrentOld.customer.id,
        employee_id: options.employee ? options.employee.id : CurrentOld.employee.id,
        availability_id: options.availability.id,
        day_id: options.day_id,
        'with[]': options.with,
    });
    factory.getAll = (options) => resource.get({
        customer_id: options.customer ? options.customer.id : CurrentOld.customer.id,
        employee_id: options.employee ? options.employee.id : CurrentOld.employee.id,
        availability_id: options.availability.id,
        'with[]': options.with,
    });
    factory.create = (options) => resource.save({
        customer_id: options.customer ? options.customer.id : CurrentOld.customer.id,
        employee_id: options.availability.employee_id,
        availability_id: options.availability.id,
    }, {
        day: options.day,
        offset: options.offset,
        length: options.length,
        whole_day: options.whole_day,
    });
    factory.update = (options) => resource.get({
        customer_id: options.customer ? options.customer.id : CurrentOld.customer.id,
        employee_id: options.employee ? options.employee.id : CurrentOld.employee.id,
        availability_id: options.availability.id,
        day_id: options.day_id,
        'with[]': options.with,
    }, {
        day: options.day,
        offset: options.offset,
        length: options.length,
        whole_day: options.whole_day,
    });
    factory.delete = (options) => resource.delete({
        customer_id: options.customer ? options.customer.id : CurrentOld.customer.id,
        employee_id: options.employee ? options.employee.id : CurrentOld.employee.id,
        availability_id: options.availability.id,
        day_id: options.day_id,
    });
    return factory;
} ]);
