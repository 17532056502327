<eaw-dialog-header>
    <span title>{{ 'MANAGE_EMPLOYMENT_END' | translate: 'company' | async }}</span>
    <span subtitle>{{ employee.name }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <mat-progress-spinner *ngIf="loadingEmployeeInfo" class="tw-mx-auto" mode="indeterminate"></mat-progress-spinner>
    <h3 *ngIf="loadingEmployeeInfo" class="tw-text-center">{{ 'GETTING_DATA' | translate | async }}</h3>

    <form [formGroup]="formGroup" class="tw-flex tw-flex-col tw-gap-8" *ngIf="!loadingEmployeeInfo">
        <ng-container *ngIf="userAccess.length">
            <label>{{ 'USER_ACCESS' | translate: 'company' | async }}</label>
            <mat-checkbox eawCheckboxHelper formControlName="removeAccess">{{ 'REMOVE_ACCESS' | translate | async }}</mat-checkbox>
            <mat-divider class="tw-my-8"></mat-divider>
        </ng-container>

        <label>{{ 'SHIFT_plural' | translate | async }}</label>
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>{{ 'MANAGE_SHIFT_plural' | translate: 'scheduling' | async }}</mat-label>
            <mat-select formControlName="handleShifts">
                <mat-option [value]="'release'">{{ 'APPROVE_ACTION_RELEASE' | translateSync: 'absences' }}</mat-option>
                <mat-option [value]="'delete'">{{ 'APPROVE_ACTION_DELETE' | translateSync: 'absences' }}</mat-option>
            </mat-select>
            <mat-hint>{{ 'HANDLE_REMAINING_SHIFTS'| translate: 'company' | async }}</mat-hint>
        </mat-form-field>

        <mat-divider class="tw-my-8" *ngIf="groups.length"></mat-divider>

        <label id="user-group-label" *ngIf="groups.length">{{ 'USER_GROUP_plural' | translate | async }}</label>
        <mat-radio-group *ngIf="groups.length" label="user-group-label" formControlName="removeFromGroups" class="tw-flex tw-flex-col">
            <mat-radio-button value="all">{{ 'REMOVE_FROM_ALL_USER_GROUPS' | translate: 'company' | async }}</mat-radio-button>
            <mat-radio-button value="none">{{ 'DO_NOT_REMOVE_FROM_USER_GROUPS' | translate: 'company' | async }}</mat-radio-button>
            <mat-radio-button value="some">{{ 'REMOVE_FROM_SELECTED_USER_GROUPS' | translate: 'company' | async }}</mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="formGroup.controls.removeFromGroups.value === 'some'">
            <mat-label>{{ 'USER_GROUP_plural' | translate | async }}</mat-label>
            <mat-select multiple formControlName="groupsToRemove">
                <mat-option *ngFor="let group of groups" [value]="group">{{ group.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{ 'CANCEL' | translate | async }}</button>
    <button mat-raised-button
            *ngIf="!loadingEmployeeInfo"
            [color]="deactivateNow ? 'warn' : 'accent'"
            (click)="submit()"
            [disabled]="!formGroup.valid || handlingEmployee">{{ (deactivateNow ? 'DEACTIVATE' : 'UPDATE') | translate | async }}
    </button>
</mat-dialog-actions>
