import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Customer } from '../../../../../shared/models/customer';
import { Employee } from '../../../../../shared/models/employee';
import { DefaultHrFileService } from '../../../../../hr/http/default-hr-file.service';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { MatListModule } from '@angular/material/list';
import { HrDefaultFile } from '../../../../../hr/models/hr-default-file';
import { HrFileType } from '../../../../../hr/models/hr-file-type';
import { catchError, EMPTY } from 'rxjs';
import { SubheaderComponent } from '../../../../../shared/components/subheader/subheader.component';
import { HrFileService } from '../../../../../hr/http/hr-file.service';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { InfoLoadingComponent } from '../../../../../shared/components/info-loading/info-loading.component';
import { NewEmployeeNextStepComponent } from '../../components/new-employee-next-step/new-employee-next-step.component';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';
import { Namespace } from '../../../../../shared/enums/namespace';

interface FilesEntry {
    type: HrFileType;
    files: HrDefaultFile[];
}

@Component({
    selector: 'eaw-new-employee-default-hr-files',
    standalone: true,
    imports: [ CommonModule, NewEmployeeNextStepComponent, InfoLoadingComponent, TranslatePipe, MatListModule, SubheaderComponent ],
    templateUrl: './new-employee-default-hr-files.component.html',
    styleUrl: './new-employee-default-hr-files.component.scss',
})
export class NewEmployeeDefaultHrFilesComponent extends NewEmployeeStepComponent implements OnInit {
    private readonly defaultHrFileService = inject(DefaultHrFileService);
    private readonly hrFileService = inject(HrFileService);
    private readonly snackBarService = inject(SnackBarService);

    @Input({ required: true }) customer!: Customer;
    @Input({ required: true }) employee!: Employee;
    @Input({ required: true }) isRequired?: boolean;

    loadingData = true;
    entries: FilesEntry[] = [];
    selectedFiles = new Set<number>();
    processingFiles = new Set<number>();

    override ngOnInit() {
        super.ngOnInit();

        this.defaultHrFileService.getAll(this.customer.id, { per_page: 50, 'with[]': [ 'fileType' ] }).subscribe((defaultHrFiles) => {
            defaultHrFiles.data.forEach((file) => {
                const type = file.fileType;

                if (type) {
                    const entry = this.entries.find((entry) => entry.type.id === type.id);
                    if (entry) {
                        entry.files.push(file);
                    } else {
                        this.entries.push({ type, files: [ file ] });
                    }
                } else {
                    const entry = this.entries.find((entry) => entry.type.id === 0);
                    if (entry) {
                        entry.files.push(file);
                    } else {
                        this.entries.push({
                            type: new HrFileType({
                                accept_file_types: [],
                                id: 0,
                                files_count: 0,
                                mandatory: false,
                                name: 'Unknown',
                                owner_id: 0,
                                owner_type: '',
                            }),
                            files: [ file ],
                        });
                    }
                }
            });

            this.loadingData = false;
            this.updateComplete();
        });
    }

    updateComplete() {
        const minimumRequiredFiles = this.selectedFiles.size >= (this.isRequired ? 1 : 0);
        this.step.completed = minimumRequiredFiles;
    }

    handleError(fileId: number) {
        this.processingFiles.delete(fileId);
        this.updateComplete();
        return EMPTY;

    }

    toggleFile(file: HrDefaultFile) {
        if (this.processingFiles.has(file.id)) {
            return;
        }

        this.step.completed = false; // Wait while processing
        this.processingFiles.add(file.id);

        this.hrFileService.createDefaultFile(this.customer.id, this.employee.id, { default_file_id: file.id }).pipe(
            catchError(() => this.handleError(file.id)),
        ).subscribe(() => {
            this.selectedFiles.add(file.id);
            void this.snackBarService.t('FILE_ADDED', Namespace.Hr);
            this.updateComplete();
        });
    }
}
