<form [class.has-interval]="customField.hasInterval" [formGroup]="form" [attr.data-rows]="rows">
    @switch (customField.type) {
        @case ('string') {
            <mat-form-field>
                <mat-label>{{ customField.translationKey | translate:'custom_fields' | async }}</mat-label>
                <input type="text" inputmode="text" matInput formControlName="value" [autocomplete]="customField.key">
            </mat-form-field>
        }
        @case ('integer') {
            <mat-form-field>
                <mat-label>{{ customField.translationKey | translate:'custom_fields' | async }}</mat-label>
                <input type="number" inputmode="numeric" step="1" matInput formControlName="value" [autocomplete]="customField.key">
            </mat-form-field>
        }
        @case ('decimal') {
            <mat-form-field>
                <mat-label>{{ customField.translationKey | translate:'custom_fields' | async }}</mat-label>
                <input type="number" inputmode="decimal" step="any" matInput formControlName="value" [autocomplete]="customField.key">
            </mat-form-field>
        }
        @case ('boolean') {
            @switch (booleanDisplay) {
                @case ('slide-toggle') {
                    <mat-slide-toggle class="tw-mb-16" formControlName="value">
                        {{ customField.translationKey | translate:'custom_fields' | async }}
                    </mat-slide-toggle>
                }
                @case ('list') {
                    <mat-selection-list [multiple]="false"  (selectionChange)="selectionChange($event)">
                        <mat-list-option [value]="true" [selected]="customField.value === true">{{ 'YES' | translate | async }}</mat-list-option>
                        <mat-list-option [value]="false" [selected]="customField.value === false">{{ 'NO' | translate | async }}</mat-list-option>
                    </mat-selection-list>
                }
            }
        }
        @case ('date') {
            <mat-form-field eawDatePickerOptions>
                <mat-label>{{ customField.translationKey | translate:'custom_fields' | async }}</mat-label>
                <input matInput [autocomplete]="customField.key" [matDatepicker]="$any(datepicker)" formControlName="value">
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
            </mat-form-field>
        }
        @case ('select') {
            @switch (selectDisplay) {
                @case ('select') {
                    <mat-form-field>
                        <mat-label>{{ customField.translationKey | translate:'custom_fields' | async }}</mat-label>
                        <mat-select formControlName="value">
                            <mat-option *ngFor="let option of customField.selectOptions" [value]="option.value">{{ option.translationKey | translateSync:'custom_fields' }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                }
                @case ('list') {
                    <mat-selection-list [multiple]="false"  (selectionChange)="selectionChange($event)">
                        <mat-list-option *ngFor="let option of customField.selectOptions" [value]="option.value" [selected]="option.value === customField.value">
                            {{ option.translationKey | translate:'custom_fields' | async }}
                        </mat-list-option>
                    </mat-selection-list>
                }
            }
        }
    }

    @if (!skipInterval && customField.hasInterval) {
        <mat-form-field eawDatePickerOptions>
            <mat-label>{{ 'FROM' | translate | async }}</mat-label>
            <input matInput formControlName="from" [matDatepicker]="fromPicker">
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field eawDatePickerOptions>
            <mat-label>{{ 'TO' | translate | async }}</mat-label>
            <input matInput formControlName="to" [matDatepicker]="toPicker">
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>
    }
</form>
