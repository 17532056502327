<eaw-dialog-header>
    <span title>
        @if (editing) {
            {{ 'UPDATE_CONTRACT' | translate:'company' | async }}
        } @else {
            {{ 'CREATE_CONTRACT' | translate:'company' | async }}
        }
    </span>
</eaw-dialog-header>

@if (loading) {
    <mat-dialog-content class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </mat-dialog-content>
} @else if (errorMessage) {
    <mat-dialog-content>
        <eaw-info-box color="red" icon="error">
            <span infoText>{{ errorMessage | async }}</span>
        </eaw-info-box>
    </mat-dialog-content>
}

<mat-dialog-content id="stepper-content" [class.tw-hidden]="loading || errorMessage">
    @if (validationErrors.length) {
        <div id="validation-errors">
            @for (error of validationErrors; track error) {
                <div class="validation-error">{{ error }}</div>
                <mat-divider/>
            }
        </div>
    }

    <mat-stepper #matStepper orientation="vertical" linear>
        <mat-step [stepControl]="fromToGroup" [completed]="fromToGroup.valid">
            <ng-template matStepLabel>{{ 'TIME_INTERVAL' | translate:'scheduling' | async }}</ng-template>

            <div class="step-container">
                <form [formGroup]="fromToGroup" class="tw-flex tw-gap-16">
                    <mat-form-field eawDatePickerOptions>
                        <mat-label>{{ 'FROM' | translate | async }}</mat-label>
                        <input matInput formControlName="from" [max]="fromToGroup.getRawValue().to" [matDatepicker]="fromPicker">
                        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #fromPicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field eawDatePickerOptions>
                        <mat-label>{{ 'TO' | translate | async }}</mat-label>
                        <input matInput formControlName="to" [min]="fromToGroup.getRawValue().from" [matDatepicker]="toPicker">
                        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                        <mat-datepicker #toPicker></mat-datepicker>
                    </mat-form-field>
                </form>

                <eaw-info-box color="yellow" *ngIf="contractInfoControl.value">
                    <span infoText>{{ 'CONTRACT_INTERVAL_CHANGE' | translate:'company' | async }}</span>
                </eaw-info-box>

                <div>
                    <button mat-button matStepperNext [disabled]="fromToGroup.invalid">{{ 'NEXT' | translate | async }}</button>
                </div>
            </div>
        </mat-step>

        <mat-step #contractStep
                  [stepControl]="contractInfoControl"
                  [completed]="contractInfoControl.valid"
                  [errorMessage]="('MISSING_CONTRACT_INFO' | translate:'company' | async) || ''"
                  [hasError]="contractStep.interacted && contractInfoControl.invalid">
            <ng-template matStepLabel>{{ 'CONTRACT' | translate:'company' | async }}</ng-template>

            <div class="step-container">
                <div>
                    <button mat-raised-button color="primary" (click)="editContract()">
                        <mat-icon>edit</mat-icon>
                        <span>{{ 'CHANGE_CONTRACT' | translate:'company' | async }}</span>
                    </button>
                </div>

                @if (contractInfo) {
                    <div class="info-container">
                        <div class="info-box">
                            <mat-icon>description</mat-icon>
                            <div class="title">{{ 'TYPE' | translate | async }}</div>
                            <div class="content">{{ contractInfo.contract_type?.name }}</div>
                        </div>

                        <div class="info-box">
                            <mat-icon>punch_clock</mat-icon>
                            <div class="title">{{ 'FULL_TIME_EMPLOYEE' | translate:'company' | async }}</div>
                            <div class="content">{{ (isFullTimeEmployee ? 'YES' : 'NO') | translate | async }}</div>
                        </div>

                        <div class="info-box">
                            <mat-icon>schedule</mat-icon>
                            <div class="title">{{ ('CONTRACT_AMOUNT_' + (contractInfo.amount_type | uppercase)) | translate:'company' | async }}</div>
                            <div class="content">{{ contractInfo.amount | eawNumber }}</div>
                        </div>

                        <div class="info-box">
                            <mat-icon>start</mat-icon>
                            <div class="title">{{ 'FROM' | translate | async }}</div>
                            <div class="content">{{ contractInfo.from | DateTime:'DATE_MED' }}</div>
                        </div>

                        <div class="info-box">
                            <mat-icon>logout</mat-icon>
                            <div class="title">{{ 'TO' | translate | async }}</div>
                            <div class="content" *ngIf="contractInfo.to">{{ contractInfo.to | DateTime:'DATE_MED' }}</div>
                            <div class="content" *ngIf="!contractInfo.to">{{ 'NO_END_DATE' | translate:'company' | async }}</div>
                        </div>
                    </div>
                }

                <div>
                    <button mat-button matStepperNext [disabled]="contractInfoControl.invalid">{{ 'NEXT' | translate | async }}</button>
                </div>
            </div>
        </mat-step>

        <mat-step [stepControl]="availabilityControl" [optional]="!!isFullTimeEmployee" [completed]="availabilityControl.valid">
            <ng-template matStepLabel>{{ 'AVAILABILITY' | translate:'company' | async }}</ng-template>

            <div class="step-container">
                <div class="tw-flex tw-gap-16">
                    <button mat-raised-button color="primary" (click)="editAvailability()">
                        <mat-icon>edit</mat-icon>
                        <span>{{ 'CHANGE_AVAILABILITY' | translate:'availabilities' | async }}</span>
                    </button>

                    <button mat-raised-button color="warn" *ngIf="availabilityControl.value" (click)="availabilityControl.reset()">
                        <mat-icon>delete</mat-icon>
                        <span>{{ 'REMOVE_AVAILABILITY' | translate:'availabilities' | async }}</span>
                    </button>
                </div>

                @if (availability) {
                    <eaw-availability-days [availability]="availability"></eaw-availability-days>
                }

                <div>
                    <button mat-button matStepperNext [disabled]="availabilityControl.invalid">{{ 'NEXT' | translate | async }}</button>
                </div>
            </div>
        </mat-step>

        <mat-step [stepControl]="hourDistributionControl" *ngIf="!isFullTimeEmployee" [completed]="hourDistributionControl.valid">
            <ng-template matStepLabel>{{ 'HOURS_DISTRIBUTION' | translate:'company' | async }}</ng-template>

            <div class="step-container">
                <div>
                    <button mat-raised-button color="primary" (click)="editHourDistribution()">
                        <mat-icon>edit</mat-icon>
                        <span>{{ 'CHANGE_HOUR_DISTRIBUTION' | translate:'company' | async }}</span>
                    </button>
                </div>

                @if (hourDistributionDays) {
                    <eaw-hour-distribution-display [data]="hourDistributionDays"></eaw-hour-distribution-display>
                }
            </div>
        </mat-step>
    </mat-stepper>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [loading]="creating" [disabled]="!stepperCompleted" (click)="submit()">{{ 'ADD_CONTRACT' | translate:'company' | async }}</eaw-action-button>
</mat-dialog-actions>
