import { ApiResponse } from '../../shared/interfaces/api-response';
import { AvailabilityDay, AvailabilityDayResponse } from '../../availability/models/availability-day';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export interface ShiftEmployeeResponse extends ApiResponse {
    availability_days: AvailabilityDayResponse[];
    availability_generated_by: string | null;
    available: boolean;
    first_name: string;
    id: number;
    last_name: string;
    number: number;
    score: number;
    overlaps: { overlap_customer_name: string, overlap_from: string, overlap_to: string }[] | null;
}

/**
 * Special employee used with the ShiftEmployeesController to determine the most suitable employee for a shift.
 */
export class ShiftEmployee {
    availabilityDays: AvailabilityDay[];
    availabilityGeneratedBy: string | null;
    available: boolean;
    firstName: string;
    id: number;
    lastName: string;
    number: number;
    score: number;
    name: string;
    generatedByTranslationKey: string | null;
    overlaps?: { overlapFrom: DateTime, overlapTo: DateTime, overlapCustomerName: string }[];

    constructor(data: ShiftEmployeeResponse) {
        this.availabilityDays = data.availability_days.map((day) => new AvailabilityDay(day));
        this.availabilityGeneratedBy = data.availability_generated_by;
        this.available = data.available;
        this.firstName = data.first_name;
        this.id = data.id;
        this.lastName = data.last_name;
        this.number = data.number;
        this.score = data.score;
        this.name = `${this.firstName} ${this.lastName}`;
        this.generatedByTranslationKey = this.availabilityGeneratedBy ? this.availabilityGeneratedBy.toUpperCase() : null;
        this.overlaps = data.overlaps?.map((overlap) => ({
            overlapFrom: stringToDateTime(overlap.overlap_from),
            overlapTo: stringToDateTime(overlap.overlap_to),
            overlapCustomerName: overlap.overlap_customer_name,
        })) || [];
    }
}
