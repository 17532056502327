<eaw-dialog-header>
    <span title>
        {{ 'ADD_REPORT' | translate:'admin' | async }}
    </span>
</eaw-dialog-header>

<mat-dialog-content>
    <form id="addReportForm" (ngSubmit)="submit()" class="tw-w-full">
        <mat-form-field class="tw-w-full">
            <mat-label>{{ 'REPORT' | translate: 'admin' | async }}</mat-label>
            <input type="text" matInput [formControl]="reportCtrl" [matAutocomplete]="auto">
            <mat-autocomplete #auto (optionSelected)="onOptionSelected($event)" [displayWith]="autoCompDisplayFn">
                @for (report of filteredReports | async; track report.class) {
                    <mat-option [value]="report">
                        <!-- Inline style, since the class didn't get applied. -->
                        <span style="font-weight: 500;">{{ report.translatedName}}</span>
                        <br/>
                        <small>{{report.translatedDescription}}</small>
                        <br/>
                        <small>{{ report.class }}</small>
                    </mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>

        @if (selectedReport) {
            <table class="tw-w-full">
                <tr>
                    <th>
                        {{ 'NAME' | translate | async }}
                    </th>
                    <td>
                        {{ selectedReport.translatedName}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{ 'DESCRIPTION' | translate | async }}
                    </th>
                    <td>
                        {{ selectedReport.translatedDescription  }}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{ 'CLASS' | translate: 'admin' | async }}
                    </th>
                    <td>
                        {{ selectedReport.class }}
                    </td>
                </tr>
            </table>
        }
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button type="submit" formId="addReportForm" [loading]="loading" [disabled]="reportCtrl.invalid">
        {{ 'ADD' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
