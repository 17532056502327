// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<ng-form layout="row" name="createLeaveShift" layout-align="space-around center">
    <eaw-time-interval
            flex="grow"
            layout="row"
            input-flex="50"
            ng-model="$create.interval"
            ng-change="$create.onChange()"
            base-date="$create.date"
            min-date="$create.minDate"
            max-date="$create.maxDate">
    </eaw-time-interval>

    <eaw-time-interval
            ng-if="$create.advanced"
            flex="grow"
            layout="row"
            input-flex="50"
            from-label="'leaveshifts:BREAK_FROM'"
            to-label="'leaveshifts:BREAK_TO'"
            min-date="$create.interval.from"
            max-date="$create.interval.to"
            ng-model="$create.breakInterval"
            base-date="$create.date"
            ng-change="$create.onChange()">
    </eaw-time-interval>

    <md-input-container ng-if="!$create.advanced">
        <label ng-i18next="leaveshifts:BREAK_LABEL"></label>
        <input type="number" ng-model="$create.breakLength" ng-model-options="{updateOn: 'blur'}" ng-change="$create.onChange()">
    </md-input-container>
</ng-form>
`;

module('eaw.leave-shifts').component('createLeaveShift', {
    template: template1,
    require: {
        leaveShifts: '^leaveShifts',
        ngModel: 'ngModel',
    },
    bindings: {
        date: '<',
        advanced: '<',
    },
    controllerAs: '$create',
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.advanced = false;
            ctrl.ngModel.$formatters.push(ctrl.formatter);
        };

        ctrl.$onChanges = (changes) => {
            ctrl.advanced = changes.advanced?.currentValue ?? ctrl.advanced;

            if (changes.advanced?.currentValue != null && !changes.advanced.isFirstChange()) {
                if (ctrl.advanced) {
                    ctrl.breakInterval = ctrl.model?.break_offset ? {
                        from: ctrl.interval.from.clone().add(ctrl.model.break_offset, 's'),
                        to: ctrl.interval.from.clone().add(ctrl.model.break_offset + ctrl.model.break_length, 's'),
                    } : {};
                } else {
                    ctrl.breakInterval = {};
                }
            }

            if (moment.isMoment(changes.date?.currentValue)) {
                ctrl.minDate = ctrl.date.clone().subtract(1, 'd');
                ctrl.maxDate = ctrl.date.clone().add(1, 'd');

                if (ctrl.minDate.isBefore(ctrl.leaveShifts.minDate, 'd')) {
                    ctrl.minDate = ctrl.leaveShifts.minDate.clone();
                }

                if (ctrl.maxDate.isAfter(ctrl.leaveShifts.maxDate, 'd')) {
                    ctrl.maxDate = ctrl.leaveShifts.maxDate.clone();
                }
            }
        };

        ctrl.onChange = () => {
            let breakOffset, breakLength;
            const from = ctrl.interval.from?.clone?.();
            const to = ctrl.interval.to?.clone?.();

            if (!ctrl.advanced) {
                breakLength = ctrl.breakLength * 60; // breakLength is in minutes
            } else if (from && ctrl.breakInterval.from) {
                breakOffset = ctrl.breakInterval.from.diff(ctrl.interval.from, 's');
                if (to) {
                    breakLength = ctrl.breakInterval.to.diff(ctrl.breakInterval.from, 's');
                }
            } else if (!ctrl.breakInterval.from && !ctrl.breakInterval.to && ctrl.advanced) {
                breakOffset = 0;
                breakLength = 0; // breakLength is in minutes
            }

            ctrl.model.from = from;
            ctrl.model.to = to;
            ctrl.model.break_offset = breakOffset;
            ctrl.model.break_length = breakLength;
            // set ngModel
            ctrl.ngModel.setViewValue(ctrl.model);
        };

        ctrl.formatter = (value) => {
            ctrl.model = value;

            ctrl.breakLength = value.break_length / 60; // break_length is in seconds
            ctrl.interval = value ? {
                from: value.from?.clone(),
                to: value.to?.clone(),
            } : {};

            ctrl.breakInterval = value.break_offset ? {
                from: ctrl.interval.from.clone().add(value.break_offset, 's'),
                to: ctrl.interval.from.clone().add(value.break_offset + value.break_length, 's'),
            } : {};

            return value;
        };
    },
});
