import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { CustomerGroup } from '../../../shared/models/customer-group';
import { DateTime } from 'luxon';
import { PeriodLock } from '../../../period-locks/models/period-lock';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { PeriodLockService } from '../../../period-locks/http/period-lock.service';
import { expandPages } from '../../../shared/utils/rxjs/expand-pages';
import { map, mergeMap, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';

type CustomerGroupExpandPanel = {
    group: CustomerGroup;
    locks?: PeriodLock[];
    open?: boolean;
    loading?: boolean;
    loaded?: boolean
    background: 'red-500' | 'green-500' | 'amber-500';
    icon: 'lock' | 'lock_open' | '';
    locked?: number;
};

@Component({
    selector: 'eaw-chain-locks',
    templateUrl: './chain-locks.component.html',
    styleUrl: './chain-locks.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatCardModule,
        MatDatepickerModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        NgIf,
        MatProgressSpinnerModule,
        MatExpansionModule,
        NgFor,
        MatIconModule,
        MaterialColorDirective,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class ChainLocksComponent implements OnInit, OnDestroy {
    @Input() customerId!: number;

    panels: CustomerGroupExpandPanel[] = [];
    /**
     * Customer id -> locks
     */
    locks = new Map<number, PeriodLock>;
    loading = false;
    date = DateTime.now();

    protected subscription?: Subscription;

    constructor(
        @Inject(CustomerGroupService) protected customerGroupService: CustomerGroupService,
        @Inject(PeriodLockService) protected periodLockService: PeriodLockService,
        @Inject(ChangeDetectorRef) protected changeDetector: ChangeDetectorRef,
    ) {
    }

    trackBy: TrackByFunction<CustomerGroupExpandPanel> = (_, panel) => panel.group.id;

    dateUpdated($event: DateTime): void {
        this.date = $event;
        this.panels.map((panel) => this.setStyles(panel));
        this.changeDetector.markForCheck();
    }

    ngOnInit(): void {
        this.loading = true;

        this.subscription = expandPages((pagination) => {
            return this.customerGroupService.getAllForCustomer(this.customerId, pagination);
        }, {
            page: 1,
            'with[]': [ 'members' ],
        }).pipe(
            switchMap((page) => {
                const items: CustomerGroupExpandPanel[] = page.data.map((group) => {
                    return {
                        group,
                        open: false,
                        icon: '',
                        background: 'red-500',
                    };
                });

                this.panels = this.panels.concat(items);
                this.loading = false;
                return of(...items);
            }),
            mergeMap((panel) => {
                return this.getLocks(panel);
            }, 2),
            tap((panel) => {
                this.setStyles(panel);
                this.changeDetector.markForCheck();
            }),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    protected setStyles(panel: CustomerGroupExpandPanel) {
        panel.icon = 'lock_open';

        const locked = panel.locks?.filter((lock) => lock.to > this.date);
        const length: number = locked?.length ?? 0;

        if (!length) {
            panel.background = 'red-500';
        } else if (panel.group.members?.length > length) {
            panel.background = 'amber-500';
        } else {
            panel.icon = 'lock';
            panel.background = 'green-500';
        }

        panel.locked = length;
    }

    protected getLocks(panel: CustomerGroupExpandPanel): Observable<CustomerGroupExpandPanel> {
        let observable;

        if (!panel.loaded && !panel.loading) {
            panel.loading = true;

            observable = this.periodLockService.getForCustomerGroup(panel.group.id).pipe(map((locks) => {
                locks.map((lock: PeriodLock) => {
                    this.locks.set(lock.customerId, lock);
                });

                panel.locks = locks;
                panel.loading = false;
                panel.loaded = true;

                return panel;
            }));
        } else {
            observable = of(panel);
        }

        return observable;
    }

    isLocked(id: number): boolean {
        const periodLock: PeriodLock | undefined = this.locks.get(id);

        return !!periodLock && periodLock.to > this.date;
    }
}
