// @ts-nocheck
import { module } from 'angular';
import { Products } from '../../shared/enums/products';
module('eaw.period-locks', [
    'eaw.resource',
    'eaw.login',
]).config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state(`eaw/app/payroll/period_locks`, {
        parent: `eaw/app/payroll`,
        url: '/period_locks',
        views: {
            'content@': 'periodLock',
        },
        data: {
            breadcrumb: { key: 'PERIOD_LOCK_plural', ns: 'payroll' },
            products: [ Products.PeriodLocks ],
            permissions: [ 'customers.{customer}.period_locks.*.get' ],
        },
    });
} ]);
