import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { User, UserResponse } from '../models/user';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { PaginationOptions } from '../interfaces/pagination-options';
import { HttpClient, HttpContext } from '@angular/common/http';
import { FileUploadService } from '../services/file-upload.service';
import { IGNORE_ERROR } from './http-contexts';
import { UserGroup, UserGroupResponse } from '../../company/models/user-group';
import { UserCustomer, UserCustomerResponse } from '../models/user-customer';

interface UserCreate {
    first_name: string;
    last_name: string;
    email: string;
    phone?: string | null;
    country_code?: string;
    language_code?: string;
}

type UpdateAttrs = {
    first_name?: string;
    last_name?: string;
    email?: string;
    password?: string;
    password_confirmation?: string;
    language_code?: string;
    country_code?: string;
    phone?: string;
};

interface GetAllOptions extends PaginationOptions {
    include_inactive?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(FileUploadService) private fileUploadService: FileUploadService,
    ) {
    }

    create(data: UserCreate, withArgs = []): Observable<User> {
        return this.http.post<UserResponse>('/users', data, { params: { 'with[]': withArgs } }).pipe(classifyItem(User));
    }

    getAll(options?: PaginationOptions): Observable<ArrayPaginatedResponse<User>> {
        return this.http.get<ArrayPaginatedResponse<UserResponse>>(`/users`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(User));
    }

    getAllForCustomer(customerId: number, options?: PaginationOptions): Observable<ArrayPaginatedResponse<User>> {
        return this.http.get<ArrayPaginatedResponse<UserResponse>>(`customers/${customerId}/users`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(User));
    }

    getCustomerForUser(userId: number, options?: PaginationOptions): Observable<ArrayPaginatedResponse<UserCustomer>> {
        return this.http.get<ArrayPaginatedResponse<UserCustomerResponse>>(`users/${userId}/customers`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(UserCustomer));
    }

    get(id: number | string, withArgs: string[] = []): Observable<User> {
        return this.http.get<UserResponse>(`/users/${id}`, {
            params: { 'with[]': withArgs },
            context: new HttpContext().set(IGNORE_ERROR, [ 404 ]),
        }).pipe(classifyItem(User));
    }

    getGroups(id: number, options?: GetAllOptions): Observable<ArrayPaginatedResponse<UserGroup>> {
        return this.http.get<ArrayPaginatedResponse<UserGroupResponse>>(`/users/${id}/groups`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(UserGroup));
    }

    update(id: number, attrs: UpdateAttrs): Observable<User> {
        return this.http.put<UserResponse>(`/users/${id}`, { ...attrs }).pipe(classifyItem(User));
    }

    uploadPicture(userId: number, image: File) {
        return this.fileUploadService.upload(`/users/${userId}/picture`, {
            picture: image,
        });
    }

    resetPassword(email: string) {
        return this.http.get(`/users/reset_password`, {
            params: { email },
        });
    }

    delete(userId: number) {
        return this.http.delete<undefined>(`/users/${userId}`);
    }
}
