import { DateTime } from 'luxon';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface OpeningHoursResponse extends ApiResponse {
    created_at: string,
    updated_at: string,
    customer_id: number,
    day: number,
    id: number,
    open_from: string,
    open_to: string,
}

export class OpeningHours {
    id: number;
    customerId: number;
    day: number;
    openFrom: DateTime;
    openTo: DateTime;

    constructor(data: OpeningHoursResponse) {
        this.id = data.id;
        this.customerId = data.customer_id;
        this.day = data.day;
        this.openFrom = this.getOpenDateTime(data.open_from, data);
        this.openTo = this.getOpenDateTime(data.open_to, data, this.openFrom);
    }

    get openFromString() {
        return this.openFrom.toFormat('HH:mm');
    }

    get openToString() {
        return this.openTo.toFormat('HH:mm');
    }

    private getOpenDateTime(time: string, data: OpeningHoursResponse, from?: DateTime) {
        const dateTime = DateTime.fromFormat(time.slice(0, 5), 'HH:mm').set({ day: data.day });
        return from && dateTime < from ? dateTime.plus({ days: 1 }) : dateTime;
    }
}
