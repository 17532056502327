// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { timeStringToHourMin } from '../../misc/services/easy-funcs.service';
const template1 = `<md-input-container class="error-spacer" flex="50">
    <input name="from"
           ng-i18next="[placeholder]FROM"
           eaw-simple-time
           autocomplete="off"
           format="{{$dtCtrl.format}}"
           aria-label="From"
           ng-aria-disable
           ng-disabled="$dtCtrl.disabled"
           ng-model="$dtCtrl.from"
           ng-change="$dtCtrl.change()"
           ng-model-options="{updateOn: 'blur'}">
</md-input-container>
<md-input-container class="error-spacer" flex="50">
    <input name="to"
           ng-i18next="[placeholder]TO"
           eaw-simple-time
           autocomplete="off"
           format="{{$dtCtrl.format}}"
           aria-label="To"
           ng-aria-disable
           ng-disabled="$dtCtrl.disabled"
           ng-model="$dtCtrl.to"
           ng-change="$dtCtrl.change()"
           ng-model-options="{updateOn: 'blur'}">
</md-input-container>
`;
module('eaw.time').component('eawSimpleInterval', {
    template: template1,
    bindings: {
        format: '@?',
        date: '<?', // Moment
    },
    require: {
        ngModel: 'ngModel',
    },
    controllerAs: '$dtCtrl',
    controller: [ '$attrs', function($attrs) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.format = ctrl.format || 'HH:mm';
            $attrs.$observe('disabled', (value) => {
                ctrl.disabled = value;
            });
        };
        ctrl.$postLink = () => {
            ctrl.date = ctrl.date || moment();
            ctrl.ngModel.$formatters.unshift((modelVal) => {
                if (modelVal?.from) {
                    if (typeof modelVal.from === 'string') {
                        const from = moment(modelVal.from, ctrl.format);
                        if (from.isValid()) {
                            ctrl.from = from.format('HH:mm');
                        }
                    } else if (moment.isMoment(modelVal.from)) {
                        ctrl.from = modelVal.from.format('HH:mm');
                    } else {
                        ctrl.from = modelVal.from.hour + ':' + modelVal.from.minute;
                    }
                } else {
                    ctrl.from = undefined;
                }
                if (modelVal?.to) {
                    if (typeof modelVal.to === 'string') {
                        const to = moment(modelVal.to, ctrl.format);
                        if (to.isValid()) {
                            ctrl.to = to.format('HH:mm');
                        }
                    } else if (moment.isMoment(modelVal.to)) {
                        ctrl.to = modelVal.to.format('HH:mm');
                    } else {
                        ctrl.to = modelVal.to.hour + ':' + modelVal.to.minute;
                    }
                } else {
                    ctrl.to = undefined;
                }
                return modelVal;
            });
        };
        ctrl.change = () => {
            let from = null;
            let to = null;
            // If either is truthy
            if (ctrl.from || ctrl.to) {
                if (ctrl.from) {
                    from = ctrl.date.clone();
                    const obj = timeStringToHourMin(ctrl.from);
                    from.hour(obj.hour);
                    from.minute(obj.minute);
                }
                if (ctrl.to) {
                    const obj = timeStringToHourMin(ctrl.to);
                    to = ctrl.date.clone();
                    to.hour(obj.hour);
                    to.minute(obj.minute);
                    if (to.isValid()) {
                        if (to.isSameOrBefore(from)) {
                            // Settings hours again because dst could have messed it up
                            to.add(1, 'd');
                            to.minute(obj.minute);
                            to.hour(obj.hour);
                        }
                    }
                }
            }
            const mv = ctrl.ngModel.$modelValue;
            ctrl.ngModel.$setViewValue({
                ...mv,
                from,
                to,
            });
        };
    } ],
});
