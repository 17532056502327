// @ts-nocheck
import { module } from 'angular';
import MlVariable from '../../../../../../ai-budgeting/angularjs/shared/ml-variable';

module('eaw.admin').controller('createMlVariableDialog', [ 'mlVariableFactory', '$mdDialog', function createMlVariableDialog(mlVariableFactory, $mdDialog) {
    const ctrl = this;

    ctrl.submit = () => {
        mlVariableFactory.create.query(ctrl.customer.id, ctrl.bucket.id, {
            name: ctrl.name,
            description: ctrl.description,
            code: ctrl.code,
        }).then((resp) => {
            $mdDialog.hide(new MlVariable(resp));
        });
    };
} ]);
