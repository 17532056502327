import { AfterViewInit, ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangeLocationService } from '../../services/change-location.service';
import { CurrentService } from '../../services/current.service';
import { environment } from '../../../../environments/environment';
import { UIRouter } from '@uirouter/core';
import { SnackBarService } from '../../services/snack-bar.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Mobile } from '../../utils/eaw-mobile';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsDialogComponent } from '../../dialogs/notifications-dialog/notifications-dialog.component';
import { WebsocketService } from '../../services/websocket.service';
import { Notification, NotificationSocketResponse } from '../../../notifications/models/notification';
import { TranslateService } from '../../services/translate.service';
import { BadgerService } from '../../services/badger.service';
import { distinctUntilChanged, filter, firstValueFrom, forkJoin, from, of, switchMap, tap, zip } from 'rxjs';
import { NumberFormatterService } from '../../services/number-formatter.service';
import { VersioningService } from '../../services/versioning.service';
import { TosDialogComponent, TosDialogData } from '../../dialogs/tos-dialog/tos-dialog.component';
import { AppService } from '../../services/app.service';
import { Cache } from '../../utils/cache';
import { LearningModuleService } from '../../../learning-module/services/learning-module.service';
import { ChangeLanguageDialogComponent, ChangeLanguageDialogData } from '../../dialogs/change-language-dialog/change-language-dialog.component';
import { I18nextLoaderService } from '../../../initializer/services/i18next-loader.service';
import { Language } from '../../../admin/models/language';
import { CurrentLoaderService } from '../../../initializer/services/current-loader.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { FlagSvgDirective } from '../../directives/flag-svg.directive';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AsyncPipe, NgIf } from '@angular/common';
import { LogoComponent } from '../logo/logo.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NavTabsTab } from '../nav-tabs/nav-tabs.component';
import { PermissionPipe } from '../../pipes/permission.pipe';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';
import { StopActingDialogComponent } from '../../dialogs/stop-acting-dialog/stop-acting-dialog.component';
import { PermissionCheckService } from '../../services/permission-check.service';
import { SettingService } from '../../http/setting.service';
import { ApiModel } from '../../enums/api-model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MainMenuDataService } from '../../../main-menu/services/main-menu-data.service';

@Component({
    selector: 'eaw-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        LogoComponent,
        NgIf,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MatBadgeModule,
        MatMenuModule,
        FlagSvgDirective,
        MatDividerModule,
        AsyncPipe,
        TranslatePipe,
        PermissionPipe,
        PermissionDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit {
    private readonly appService = inject(AppService);
    private readonly badger = inject(BadgerService);
    private readonly breakpointObserver = inject(BreakpointObserver);
    private readonly changeLocationService = inject(ChangeLocationService);
    private readonly current = inject(CurrentService);
    private readonly currentLoaderService = inject(CurrentLoaderService);
    private readonly destroyRef = inject(DestroyRef);
    private readonly settingService = inject(SettingService);
    private readonly i18nextLoaderService = inject(I18nextLoaderService);
    private readonly matDialog = inject(MatDialog);
    private readonly mainMenuDataService = inject(MainMenuDataService);
    private readonly numberFormatterService = inject(NumberFormatterService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly uiRouter = inject(UIRouter);
    private readonly snackBarService = inject(SnackBarService);
    private readonly translateService = inject(TranslateService);
    private readonly versioningService = inject(VersioningService);
    private readonly websocketService = inject(WebsocketService);
    protected readonly learningModuleService = inject(LearningModuleService);

    readonly currentLanguage = signal<undefined|Language>(undefined);
    readonly mail = signal('');
    readonly isActing = signal(false);
    readonly isDevelopment = signal(!environment.isProduction);
    readonly locationName = signal('');
    readonly phone = signal('');
    readonly useItemsInMoreMenu = signal(false);
    readonly learningModuleLoaded = signal(false);
    readonly newVersion: WritableSignal<boolean | undefined> = signal(undefined);
    readonly hasNotifications = signal(false);
    readonly notifications = signal('0');
    readonly useDialogForLearningSidebar = signal(false);
    readonly sidebarToggled = signal(false);
    readonly keepSidebarOpen = signal(false);
    readonly sidebarSmallScreen = signal(true);
    readonly showSidebarButton = computed(this.computeShowSidebarButton.bind(this));
    readonly learningModuleAccess = signal<boolean>(false);

    private readonly sidebarToggledKey = 'sidebar-toggled';
    private readonly SUPPORT_EMAIL = 'support@easyatwork.com';
    private readonly SUPPORT_PHONE = '+4741586100';

    constructor() {
        this.isActing.set(this.current.getMe().isActing);

        effect(() => {
            const smallScreen = this.sidebarSmallScreen();
            const keepOpen = this.keepSidebarOpen();
            const toggled = this.sidebarToggled();
            const pushClass = 'sidebar-toggled';
            const overClass = 'sidebar-shown';

            document.body.classList.remove(pushClass, overClass);

            if (smallScreen) {
                if (toggled) {
                    document.body.classList.add(overClass);
                }
            } else {
                if (keepOpen) {
                    document.body.classList.add(pushClass);
                } else if (toggled) {
                    document.body.classList.add(overClass);
                }

                void this.current.store(this.sidebarToggledKey, keepOpen, 'default');
            }
        });
    }

    ngOnInit() {
        this.currentLoaderService.onLoaded().pipe(
            tap((loaded) => {
                if (loaded) {
                    this.init();
                }
            }),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();
    }

    ngAfterViewInit() {
        if (Mobile.isMobile) {
            // Match status bar to app header
            Mobile.setStatusBarColor();
        }
    }

    computeShowSidebarButton() {
        return this.sidebarSmallScreen() || !this.keepSidebarOpen();
    }

    init() {
        const customer = this.current.getCustomer();
        this.locationName.set(customer?.name || '');
        this.currentLanguage.set(this.i18nextLoaderService.currentLanguage);
        if (!customer) {
            return;
        }

        // If we transition at any point, close the sidebar
        this.uiRouter.transitionService.onStart({}, this.closeSidebarOver.bind(this));

        this.websocketService.notification(`users.${this.current.getUser().id}`, this.onNotification.bind(this), this.destroyRef);

        this.versioningService.onChange('utc')
            .pipe(
                distinctUntilChanged(),
                tap(() => this.newVersion.update((version) => version !== undefined)),
                takeUntilDestroyed(this.destroyRef),
            ).subscribe();

        const options = {
            models: [
                {
                    type: ApiModel.Customer,
                    id: customer.id,
                },
            ],
        };
        forkJoin([
            this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].contact_support.email`, options),
            this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].contact_support.phone`, options),
        ]).pipe(
            filter(([ showEmail, showPhone ]) => showEmail || showPhone),
            switchMap(([ showEmail, showPhone ]: [boolean, boolean]) => {
                const emailObs = showEmail ? this.settingService.getString([ 'customers', customer.id ], 'support_email') : of(null);
                const phoneObs = showPhone ? this.settingService.getString([ 'customers', customer.id ], 'support_phone') : of(null);

                return forkJoin([
                    emailObs.pipe(tap((email) => this.mail.set(email || this.SUPPORT_EMAIL))),
                    phoneObs.pipe(tap((phone) => this.phone.set(phone || this.SUPPORT_PHONE))),
                ]);
            }),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();

        // Check if user has access to learning module
        this.learningModuleService.hasAccess().pipe(
            tap((hasAccess) => this.learningModuleAccess.set(hasAccess)),
            switchMap((hasAccess) => !hasAccess ? of(undefined) : from(this.learningModuleService.setPermissions())),
            tap(() => this.learningModuleLoaded.set(true)),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();

        zip([
            this.breakpointObserver.observe([ Breakpoints.XSmall ]).pipe(tap((result) => this.useItemsInMoreMenu.set(result.matches))),
            this.breakpointObserver.observe([ Breakpoints.XSmall, Breakpoints.Small ]).pipe(tap((result) => this.useDialogForLearningSidebar.set(result.matches))),
            this.breakpointObserver.observe([ Breakpoints.Large, Breakpoints.XLarge ]).pipe(tap(this.onBreakpoint.bind(this))),
            this.badger.onBadge(customer?.id, 'notifications.unread')
                .pipe(
                    distinctUntilChanged(),
                    tap((res) => {
                        const notifications = res || 0;

                        this.hasNotifications.set(!!notifications);
                        this.notifications.set(this.numberFormatterService.format(this.current.languageTag, notifications, {
                            compactDisplay: 'short',
                            notation: 'compact',
                            maximumFractionDigits: 0,
                        }));

                        Mobile.setBadge(notifications);
                    }),
                ),
        ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();

        document.getElementById('sidebar-overlay')?.addEventListener('click', this.closeSidebarOver.bind(this));
    }

    protected displayMainMenuInfo() {
        console.group('Main menu information');
        console.log(this.mainMenuDataService.get());
        console.groupEnd();
    }

    protected async displayStateInfo() {
        console.clear();

        const fontFamily = 'verdana';
        let titleColor = '';
        let textColor = '';

        switch (window.matchMedia?.('(prefers-color-scheme: dark)').matches ? 'dark' : 'light') {
            case 'dark':
                titleColor = '#90caf9';
                textColor = '#ce93d8';
                break;
            case 'light':
                titleColor = '#42a5f5';
                textColor = '#ab47bc';
                break;
        }

        const titleStyle = `color: ${titleColor}; font-weight: bold; font-size: 15px; font-family: ${fontFamily}`;
        const textStyle = `color: ${textColor}; font-size: 13px; font-family: ${fontFamily}`;

        let currentState = this.uiRouter.globals.$current;
        while (currentState.name !== 'eaw/app') {
            console.groupCollapsed(`%c${currentState.name}`, titleStyle);
            console.log(`%cData`, textStyle, currentState.data);
            console.log(`%cParams`, textStyle, currentState.params);
            console.log(`%cResolvables`, textStyle, currentState.resolvables);
            console.log(`%cViews`, textStyle, currentState.views);

            const tabs = currentState.resolvables.find((resolvable) => resolvable.token === 'tabs');
            if (tabs) {
                const tabsData = await tabs.resolveFn(this.uiRouter.globals.transitionHistory.peekHead()) as NavTabsTab[];

                for (const tab of tabsData) {
                    const tabTitle = `%cTab - ${tab.text ? await firstValueFrom(tab.text) : (tab.label ? await this.translateService.t(tab.label.key, tab.label.ns) : tab.state)}`;
                    console.log(tabTitle, textStyle, this.uiRouter.stateService.get(tab.state));
                }
            }

            console.groupEnd();

            currentState = currentState.parent;
        }

        this.snackBarService.open('Check your console for state information');
    }

    protected closeSidebarOver() {
        this.sidebarToggled.set(false);
    }

    changeLanguage() {
        this.matDialog.open<ChangeLanguageDialogComponent, ChangeLanguageDialogData>(ChangeLanguageDialogComponent, {
            data: {
                authId: this.current.getMe().authedAs,
                currentLanguageCode: this.current.getUser().languageCode,
            },
        });
    }

    updateVersion() {
        this.appService.reload();
    }

    onBreakpoint(result: BreakpointState) {
        this.sidebarSmallScreen.set(!result.matches);

        if (!this.sidebarSmallScreen()) {
            void this.setSidebarToggled();
        } else {
            this.sidebarToggled.set(false);
        }
    }

    keepSidebarOpenChange(open: boolean) {
        this.keepSidebarOpen.set(open);
        this.sidebarToggled.set(false);
    }

    async setSidebarToggled() {
        const toggled = await this.current.retrieve<boolean>(this.sidebarToggledKey, 'default') ?? true;
        this.keepSidebarOpen.set(toggled);
    }

    async onNotification(ev: NotificationSocketResponse) {
        this.badger.refresh(this.current.getCustomer()?.id);

        const notification = Notification.fromSocket(ev);
        const route = notification.getRoute();
        const routeParams = notification.getRouteParams();

        if (route) {
            this.snackBarService.open(notification.title, {
                action: () => this.uiRouter.stateService.go(route, routeParams),
                text: await this.translateService.t('GO'),
            });
        } else {
            this.snackBarService.open(notification.title);
        }
    }

    changeLocation() {
        this.changeLocationService.open(this.current.getCustomer());
    }

    openNotificationsDialog() {
        this.matDialog.open(NotificationsDialogComponent);
    }

    openManual() {
        const url = 'https://easyatwork.zendesk.com/hc/en';

        if (Mobile.isMobile) {
            Mobile.openBrowser(url);
        } else {
            window.open(url, '_blank');
        }
    }

    openTos() {
        this.matDialog.open<TosDialogComponent, TosDialogData>(TosDialogComponent, {
            data: {
                required: false,
                userId: this.current.getMe().authedAs,
            },
        });
    }

    stopActing() {
        this.matDialog.open(StopActingDialogComponent);
    }

    reload(what?: 'state' | 'page') {
        if (what === 'page') {
            this.appService.reload();
        }

        if (what === 'state') {
            void this.uiRouter.stateService.reload();
        }
    }

    toggleSidebar() {
        this.sidebarToggled.update((toggled) => !toggled);
    }

    protected listPermissionElements() {
        console.clear();

        for (const allowed of [ true, false ]) {
            const nodes = document.querySelectorAll(`[data-eaw-permission][data-allowed="${allowed}"]`) as NodeListOf<HTMLElement>;

            console.groupCollapsed(allowed ? `Visible elements with permission (${nodes.length})` : `Hidden elements with permission (${nodes.length})`);
            nodes.forEach((node, index) => {
                console.group(`Node ${index + 1} ----------`);
                console.log('Data', node.dataset);
                console.log(`Node`, node);
                console.groupEnd();
            });
            console.groupEnd();
        }

        this.snackBarService.open('Check your console for permission elements information');
    }

    async clearCache() {
        await Cache.clearAll();
        this.appService.reload();
    }
}
