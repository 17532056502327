import { Component, signal } from '@angular/core';
import { DataTablePortalComponent } from '../../types/data-table-portal-component';
import { DataTableProfilePictureColumn } from '../../types/data-table-profile-picture-column';
import { ProfilePictureComponent, ProfilePictureUser } from '../../../shared/components/profile-picture/profile-picture.component';

@Component({
    selector: 'eaw-profile-picture-portal',
    templateUrl: './profile-picture-portal.component.html',
    styleUrl: './profile-picture-portal.component.scss',
    standalone: true,
    imports: [ ProfilePictureComponent ],
})
export class ProfilePicturePortalComponent<Item> extends DataTablePortalComponent<Item, unknown, DataTableProfilePictureColumn<Item>> {
    user = signal<ProfilePictureUser | undefined>(undefined);

    constructor() {
        super();

        void this.setUser();
    }

    private async setUser() {
        this.user.set(await this.cell.column.user(this.cell));
    }
}
