@if (periodItems().length) {
    <mat-list>
        @for (item of periodItems(); track item.period.id) {
            <mat-list-item [class.unproductive]="item.period.unproductive" [ngClass]="item.darkness" [style.color]="item.textColor" [style.background-color]="item.backgroundColor">
                <span matListItemTitle>{{ 'FROM_TO' | translate:'general':{from: item.from, to: item.to} | async }}</span>

                @if (item.hasUnit) {
                    <span matListItemLine>{{ 'BUSINESS_UNIT' | translate | async }} - {{ item.description | async }}</span>
                } @else {
                    <span matListItemLine>{{ item.description | async }}</span>
                }

                @if (!displayOnly()) {
                    <div matListItemMeta>
                        @if (item.processing) {
                            <eaw-info-loading size="sm"/>
                        } @else {
                            @if ('customers.' + customerId() + '.schedules.' + scheduleId() + '.shifts.' + shiftId() + '.periods.' + item.period.id + '.update' | permission | async) {
                                <button mat-icon-button (click)="editPeriod(item.period.id)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            } @else {
                                <button mat-icon-button style="visibility: hidden">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            }

                            @if ('customers.' + customerId() + '.schedules.' + scheduleId() + '.shifts.' + shiftId() + '.periods.' + item.period.id + '.delete' | permission | async) {
                                <button mat-icon-button (click)="deletePeriod(item)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            } @else {
                                <button mat-icon-button style="visibility: hidden">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            }
                        }
                    </div>
                }
            </mat-list-item>

            @if (!$last) {
                <mat-divider/>
            }
        }
    </mat-list>
} @else {
    <div class="tw-flex tw-flex-col tw-gap-16 tw-items-center tw-my-24">
        <mat-icon>info</mat-icon>
        <span class="mat-subtitle-1">{{ 'NO_PERIODS' | translate:'scheduling' | async }}</span>
    </div>
}


