import { Component, inject } from '@angular/core';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from 'src/app/data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { PermissionSet } from '../../../shared/models/permission-set';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';
import { PermissionSetService } from '../../../shared/http/permission-set.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { DataTableComponent, DataTableGoTo } from '../../../data-table/data-table.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { debounceTime, distinctUntilChanged, EMPTY, of, switchMap, tap } from 'rxjs';
import { CreateEditPermissionSetData, CreateEditPermissionSetDialogComponent } from '../../dialogs/create-edit-permission-set-dialog/create-edit-permission-set-dialog.component';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-permission-set-list',
    standalone: true,
    imports: [
        DataTableComponent,
        PageHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        FormsModule,
        ActionButtonComponent,
        MatFormField,
        ReactiveFormsModule,
        MatInput,
        MatLabel,
    ],
    templateUrl: './permission-set-list.component.html',
    styleUrl: './permission-set-list.component.scss',
})
export class PermissionSetListComponent implements EawDataTable<PermissionSet> {
    private readonly confirmDialog = inject(ConfirmDialogService);
    private readonly matDialog = inject(MatDialog);
    private readonly snackBar = inject(SnackBarService);
    private readonly permissionSetService = inject(PermissionSetService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly translate = inject(TranslateService);

    protected fabButton: HeaderFabButton;

    columns: DataTableColumnType<PermissionSet>[];

    request?: DataTableRequest<PermissionSet> = of(mockArrayPaginatedResponse<PermissionSet>());
    goTo: DataTableGoTo;
    loading = false;
    filter: FormControl<string | null> = new FormControl(null);

    private perPage = 25;

    constructor() {
        this.fabButton = {
            icon: 'add',
            hasPermission: () => this.permissionCheckService.isAllowed('permission_sets.create'),
            click: this.addPermissionSet.bind(this),
        };

        this.goTo = {
            params: [ { stateKey: 'id', itemKey: 'id' } ],
            state: `eaw/app/admin/permissionsets/list/info`,
        };

        this.columns = [
            new DataTableTextColumn({
                key: 'id',
                header: new DataTableHeader({ i18n: 'ID', ns: 'general', sortBy: 'id' }),
                value: (cell) => String(cell.item.id),
            }),
            new DataTableTextColumn({
                key: 'name',
                header: new DataTableHeader({ i18n: 'NAME', ns: 'general', sortBy: 'name' }),
                value: (cell) => this.translate.t(cell.item.name, 'permissions'),
            }),
            new DataTableTextColumn({
                key: 'description',
                header: new DataTableHeader({ i18n: 'DESCRIPTION', ns: 'general', sortBy: 'description' }),
                value: (cell) => this.translate.t(cell.item.description, 'permissions'),
            }),
            new DataTableTextColumn({
                key: 'product',
                header: new DataTableHeader({ i18n: 'PRODUCT', ns: 'admin', sortBy: 'product_name' }),
                value: (cell) => cell.item.productName,
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        icon: 'edit',
                        click: this.editSet.bind(this),
                        show: (item) => {
                            return this.permissionCheckService.isAllowed(`permission_sets.[${ApiModel.PermissionSet}].update`, {
                                models: [ {
                                    id: item.id,
                                    type: ApiModelClass.PermissionSet,
                                } ],
                            });
                        },
                        tooltip: { key: 'EDIT', ns: 'general' },
                    },
                    {
                        icon: 'delete',
                        type: 'warn',
                        click: this.deleteSet.bind(this),
                        show: (item) => {
                            return this.permissionCheckService.isAllowed(`permission_sets.[${ApiModel.PermissionSet}].delete`, {
                                models: [ {
                                    id: item.id,
                                    type: ApiModelClass.PermissionSet,
                                } ],
                            });
                        },
                        tooltip: { key: 'DELETE', ns: 'general' },
                    },
                ],
            }),
        ];

        this.filter.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(this.search.bind(this)),
        ).subscribe();
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.perPage = pagination.per_page || this.perPage;
        this.request = this.permissionSetService.getAll({
            pagination: {
                ...pagination,
                filter: this.filter.value || undefined,
            },
        }).pipe(tap(() => this.loading = false));
    }

    protected addPermissionSet() {
        this.matDialog.open<CreateEditPermissionSetDialogComponent, CreateEditPermissionSetData, PermissionSet>(CreateEditPermissionSetDialogComponent)
            .afterClosed()
            .pipe(tap((result) => {
                if (result) {
                    this.snackBar.created();
                    this.search();
                }
            })).subscribe();
    }

    protected search() {
        this.loading = true;
        this.updateTable({ page: 1, per_page: this.perPage });
    }

    private editSet(cell: DataTableButtonCell<PermissionSet>) {
        this.matDialog.open<CreateEditPermissionSetDialogComponent, CreateEditPermissionSetData, PermissionSet>(CreateEditPermissionSetDialogComponent, {
            data: { permissionSet: of(cell.item) },
        }).afterClosed().pipe(tap((result) => {
            cell.disabled.set(false);
            if (result) {
                this.snackBar.updated();
                cell.item = result;
                this.search();
            }
        })).subscribe();
    }

    private deleteSet(cell: DataTableButtonCell<PermissionSet>) {
        this.confirmDialog.delete({})
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (!result?.ok) {
                        cell.disabled.set(false);
                        return EMPTY;
                    }

                    return of(undefined);
                }),
                switchMap(() => this.permissionSetService.delete(cell.item.id)),
                tap(() => {
                    this.snackBar.deleted();
                    this.search();
                }),
            ).subscribe();
    }
}
