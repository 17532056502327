// @ts-nocheck
import { module } from 'angular';
module('eaw.availability').factory('StandbyFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const factory = this;
    const customerRes = EawResource.create('/customers/:customer/standbys');
    const employeeRes = EawResource.create('/customers/:customer/employees/:employee/standbys/:standby');
    /**
     *
     * @param {object}      args
     * @param {int}         args.customer   -   customer id
     * @param {moment}      args.from
     * @param {moment}      args.to
     * @param {string[]}    args.with
     */
    factory.getForCustomer = (args) => customerRes.get({
        customer: args.customer,
        from: args.from,
        to: args.to,
        'with[]': args.with,
        ...Pagination.getParams(args),
    });
    /**
     *
     * @param {object}      args
     * @param {int}         args.customer   -   customer id
     * @param {int}         args.employee   -   employee id
     * @param {moment}      args.from
     * @param {moment}      args.to
     * @param {string[]}    args.with
     */
    factory.getForEmployee = (args) => employeeRes.get({
        customer: args.customer,
        employee: args.employee,
        'with[]': args.with,
        from: args.from,
        to: args.to,
        ...Pagination.getParams(args),
    });
    /**
     * Create a standby
     * @param {object}  args
     * @param {int}     args.customer
     * @param {int}     args.employee
     * @param {moment}  args.from
     * @param {moment}  args.to
     */
    factory.create = (args) => employeeRes.save({
        customer: args.customer,
        employee: args.employee,
    }, {
        from: args.from,
        to: args.to,
    });
    /**
     * Update a standby
     * @param {object}  args
     * @param {int}     args.customer
     * @param {int}     args.employee
     * @param {int}     args.standby
     * @param {moment}  args.from
     * @param {moment}  args.to
     */
    factory.update = (args) => employeeRes.update({
        customer: args.customer,
        employee: args.employee,
        standby: args.standby,
    }, {
        from: args.from,
        to: args.to,
    });
    /**
     *
     * @param {int} customer
     * @param {int} employee
     * @param {int} standby
     */
    factory.delete = (customer, employee, standby) => employeeRes.delete({
        customer,
        employee,
        standby,
    });
    return factory;
} ]);
