import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Property, PropertyResponse } from '../models/property';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { PaginationOptions } from '../interfaces/pagination-options';
import { formatHttpParams } from '../utils/format-http-params';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomerPropertyService {

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
    ) { }

    getAll(customerId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<PropertyResponse>>(`/customers/${customerId}/properties`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(Property));
    }

    create(customerId: number, key: number | string, value: string): Observable<Property> {
        return this.http.post<PropertyResponse>(`/customers/${customerId}/properties`, {
            key,
            value,
        }).pipe(classifyItem(Property));
    }

    update(customerId: number, key: number | string, value: string) {
        return this.http.put<PropertyResponse>(`/customers/${customerId}/properties/${key}`, { value }).pipe(classifyItem(Property));
    }

    delete(customerId: number, key: string) {
        return this.http.delete(`/customers/${customerId}/properties/${key}`);
    }

    flush(customerId: number) {
        return this.http.post<undefined>(`/customers/${customerId}/flush_settings`, {});
    }
}
