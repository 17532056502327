import { ChangeDetectionStrategy, Component, effect, HostBinding, input, viewChild } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'eaw-action-button',
    templateUrl: './action-button.component.html',
    styleUrl: './action-button.component.scss' ,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButtonModule,
        MatProgressSpinnerModule,
    ],
})
export class ActionButtonComponent {
    matButton = viewChild.required(MatButton);

    @HostBinding('class.disabled') get disabledClass() {
        return this.disabled();
    }

    loading = input.required<boolean | undefined>();
    disabled = input<boolean | undefined>();
    color = input<ThemePalette>('accent');
    type = input<HTMLButtonElement['type']>('button');
    /**
     * Use together with a submit button to submit the selected form
     */
    formId = input<string>();

    constructor() {
        effect(() => {
            if (this.formId()) {
                this.matButton()._elementRef.nativeElement.setAttribute('form', this.formId());
            } else {
                this.matButton()._elementRef.nativeElement.removeAttribute('form');
            }
        });
    }
}
