<table>
    <thead>
    <tr>
        <th class="weekday-cell">{{ 'WEEKDAY' | translate | async }}</th>
        <th class="date-cell">{{ 'DATE' | translate | async }}</th>
        <th class="input-cell">{{ payTypeLink.returnUnit.label.translationKey | translate:payTypeLink.returnUnit.label.translationNs | async }}</th>
        <th class="comments-cell">{{ 'COMMENT_plural' | translate | async }}</th>
        <th class="total-cell">{{ 'TOTAL' | translate | async }}</th>
    </tr>
    </thead>

    <tbody>
        @for (day of days; track day.day) {
            <tr>
                <td class="weekday-cell">{{ day.day | DateTime:'WEEKDAY' }}</td>
                <td class="date-cell">
                    <div class="tw-flex tw-gap-8 tw-items-center">
                        <span>{{ day.day | DateTime:'DATE_MED' }}</span>

                        @if (!day.create && canCreate) {
                            <button class="add-button"
                                    [matTooltip]="('ADD_EXTRA_ROW' | translate:'payroll' | async) || ''" mat-ripple
                                    (click)="day.create = true" tabindex="-1">
                                <span>+</span>
                            </button>
                        }
                    </div>
                </td>
                <td class="input-cell">
                    <div class="inputs-container tw-flex tw-flex-col tw-gap-4">
                        @for (payment of day.payments; track payment.payment.id) {
                            <div class="tw-flex tw-gap-8 tw-items-center tw-min-h-24">
                                @if (!payment.disabled && !payment.updating && payment.canDelete && !payment.payment.editable) {
                                    <mat-icon class="delete" (click)="remove(day, payment)"
                                              [matTooltip]="('REMOVE' | translate | async) || ''">delete
                                    </mat-icon>
                                }
                                @if (payment.payment.editable && !payment.updating) {
                                    <mat-icon class="save"
                                              (click)="update(payment.payment, payment.control.value || 0)"
                                              [matTooltip]="('SAVE' | translate | async) || ''">add
                                    </mat-icon>
                                }
                                @if (payment.disabled || payment.updating) {
                                    <mat-progress-spinner diameter="18" mode="indeterminate"
                                                          strokeWidth="2"></mat-progress-spinner>
                                }
                                <input type="number" [attr.disabled]="payment.disabled || payment.updating"
                                       inputmode="numeric"
                                       [formControl]="payment.control" step="0.01" min="1">
                            </div>
                        }

                        @if (day.create) {
                            <div class="tw-flex tw-gap-8 tw-items-center tw-min-h-24">
                                @if (!day.pending && day.quantityValue()) {
                                    <mat-icon [matTooltip]="('ADD' | translate | async) || ''"
                                              class="save" (click)="create(day)">add
                                    </mat-icon>
                                }
                                @if (day.pending) {
                                    <mat-progress-spinner diameter="18" mode="indeterminate"
                                                          strokeWidth="2"></mat-progress-spinner>
                                }
                                <input [attr.disabled]="day.pending" [(ngModel)]="day.quantityValue" type="number"
                                       [class.ml-26]="!day.quantityValue()" inputmode="numeric" autofocus step="0.01"
                                       min="1">
                            </div>
                        }
                    </div>
                </td>
                <td class="comments-cell">
                    <div class="tw-flex tw-flex-col tw-gap-4">
                        @for (payment of day.payments; track payment.payment.id; let index = $index) {
                            <div class="tw-flex tw-gap-8 tw-items-center tw-min-h-24">
                                @if (!payment.payment.editable) {
                                    @if (!payment.disabled && !payment?.payment?.comments?.length) {
                                        <mat-icon class="edit tw-min-h-24 tw-flex tw-items-center" (click)="payment.payment.editable = true"
                                                  [matTooltip]="('ADD_COMMENT' | translate | async) || ''">
                                            add_comment
                                        </mat-icon>
                                    }
                                    @if (payment?.payment?.comments?.length) {
                                        @if (!payment.disabled) {
                                            <mat-icon class="delete" (click)="deleteComment(payment)"
                                                      [matTooltip]="('DELETE_COMMENT' | translate | async) || ''">
                                                cancel
                                            </mat-icon>
                                        }
                                        <span [matTooltip]="('SEE_COMMENTS' | translate: 'chainops' | async) || ''"
                                              class="comments-cell__value" [class.ml-26]="payment.disabled"
                                              (click)="openCommentsDialog(payment.payment.comments)">
                                    {{ payment?.payment?.comments?.[payment.payment.comments.length - 1]?.body }}</span>
                                    }
                                } @else {
                                    @if (!payment.updating) {
                                        <mat-icon class="edit" (click)="cancelPaymentComment(payment.payment, day)"
                                                  [matTooltip]="('CANCEL' | translate | async) || ''">
                                            cancel
                                        </mat-icon>
                                    }
                                    <input matInput [attr.disabled]="payment.updating"
                                           [formControl]="payment.commentControl" [class.ml-26]="payment.updating">
                                }
                            </div>
                        }

                        @if (day.create) {
                            @if (!day.editable) {
                                <mat-icon class="edit tw-min-h-24 tw-flex tw-items-center"
                                          [class.pointer-events-none]="day.pending"
                                          (click)="day.editable = true"
                                          [matTooltip]="('ADD_COMMENT' | translate | async) || ''">
                                    add_comment
                                </mat-icon>
                            } @else {
                                <div class="tw-flex tw-gap-8 tw-items-center tw-min-h-24">
                                    @if (!day.pending) {
                                        <mat-icon class="edit"
                                                  (click)="cancelComment(day)"
                                                  [matTooltip]="('CANCEL' | translate | async) || ''">
                                            cancel
                                        </mat-icon>
                                    }
                                    @if (day.editable) {
                                        <input matInput [attr.disabled]="day.pending" [(ngModel)]="day.commentValue"
                                               [class.ml-26]="day.pending">
                                    }
                                </div>
                            }
                        }
                    </div>
                </td>
                <td class="total-cell">
                    <eaw-variable-payment-display [number]="day.total"
                                                  [payTypeLink]="payTypeLink"></eaw-variable-payment-display>
                </td>
            </tr>
        }
    </tbody>
</table>
