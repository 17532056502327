import { Component, Inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { EmployeeGroupMembership } from '../../models/employee-group-membership';
import { EmployeeGroupsService } from '../../http/employee-groups.service';
import { of } from 'rxjs';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { Namespace } from '../../../shared/enums/namespace';
import { MatDialog } from '@angular/material/dialog';
import { ConnectEmployeeGroupsDialogComponent, ConnectEmployeeGroupsDialogData, ConnectEmployeeGroupsDialogReturn } from '../../dialogs/connect-employee-groups-dialog/connect-employee-groups-dialog.component';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';

@Component({
    selector: 'eaw-employee-group-info',
    templateUrl: './employee-group-info.component.html',
    styleUrl: './employee-group-info.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
    ],
})
export class EmployeeGroupInfoComponent implements OnInit, EawDataTable {
    @ViewChild(DataTableComponent) table?: DataTableComponent<EmployeeGroupMembership>;

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) employeeGroupId!: number;

    includeInactive = false;
    groupName = '';
    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<EmployeeGroupMembership>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item?.member?.name,
            header: new DataTableHeader({ i18n: 'NAME' }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            format: DateTime.DATE_MED,
            header: new DataTableHeader({ i18n: 'FROM', sortBy: 'from' }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            format: DateTime.DATE_MED,
            header: new DataTableHeader({ i18n: 'TO', sortBy: 'to' }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'delete',
                    type: 'warn',
                    tooltip: { key: 'REMOVE' },
                    click: this.removeMember.bind(this),
                    show: (membership) => {
                        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employee_groups.${this.employeeGroupId}.members.${membership.id}.remove`);
                    },
                },
            ],
        }),
    ];

    headerButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            icon: 'add',
            click: this.addEmployees.bind(this),
            menuText: signal(this.translateService.t('ADD_EMPLOYEE_plural', Namespace.Company)),
        }),
        new PageHeaderButton({
            icon: 'history',
            active: () => this.includeInactive,
            click: this.toggleIncludeInactive.bind(this),
            menuText: signal(this.translateService.t('INCLUDE_INACTIVE')),
        }),
    ];

    constructor(
        @Inject(EmployeeGroupsService) private employeeGroupsService: EmployeeGroupsService,
        @Inject(TranslateService) private translateService: TranslateService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
    ) {
    }

    ngOnInit() {
        this.employeeGroupsService.get(this.customerId, this.employeeGroupId).subscribe((group) => {
            this.groupName = group?.name || '';
        });
    }

    addEmployees() {
        const ref = this.matDialog.open<ConnectEmployeeGroupsDialogComponent, ConnectEmployeeGroupsDialogData, ConnectEmployeeGroupsDialogReturn>(ConnectEmployeeGroupsDialogComponent, {
            data: {
                size: DialogSize.Medium,
                customerId: this.customerId,
                groupId: this.employeeGroupId,
            },
        });

        ref.componentInstance.onMemberAdded().subscribe(() => {
            this.table?.refresh();
        });
    }

    removeMember(cell: DataTableButtonCell<EmployeeGroupMembership>) {
        this.confirmDialogService.open({
            title: this.translateService.t('REMOVE_EMPLOYEE_GROUP_MEMBER', Namespace.Company),
            text: this.translateService.t('REMOVE_EMPLOYEE_GROUP_MEMBER_TEXT', Namespace.Company, { name: cell.item?.member?.name }),
            confirmText: this.translateService.t('REMOVE'),
        }).afterClosed().subscribe((confirmed) => {
            if (!confirmed?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.employeeGroupsService.removeMember(this.customerId, this.employeeGroupId, cell.item?.id).subscribe(() => {
                this.table?.refresh();
            });
        });
    }

    toggleIncludeInactive() {
        this.includeInactive = !this.includeInactive;
        this.table?.refresh();
    }

    rowClasses(row: EmployeeGroupMembership) {
        return [ row.isActive() ? '' : 'inactive' ];
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.employeeGroupsService.getMembers(this.customerId, this.employeeGroupId, {
            ...pagination,
            include_inactive: this.includeInactive,
            'with[]': [ 'member' ],
        });
    }
}
