import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { DateTime } from 'luxon';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { IGNORE_ERROR } from '../../shared/http/http-contexts';
import { PlusHoursMonitoring, PlusHoursMonitoringResponse } from '../models/plus-hours-monitoring';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PlusHoursMonitoringService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    get(customerId: number, date: DateTime) {
        return this.http.get<PlusHoursMonitoringResponse[]>(`/customers/${customerId}/plusHours/france`, {
            params: formatHttpParams({ date }),
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(
            map((res) => res.map((item) => new PlusHoursMonitoring(item))),
        );
    }
}
