import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { WarningService } from '../../http/warning.service';
import { Warning } from '../../models/warning';
import { WarningCause } from '../../models/warning-cause';
import { DurationPipe } from '../../pipes/duration.pipe';
import { DateTimePipe } from '../../pipes/date-time.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

export interface CausesDialogData extends DialogData {
    customerId?: number;
    warningId?: number;
}

@Component({
    selector: 'eaw-causes-dialog',
    templateUrl: './causes-dialog.component.html',
    styleUrl: './causes-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        DurationPipe,
    ],
})
export class CausesDialogComponent extends DialogComponent implements OnInit {
    loading = false;
    warning?: Warning;
    causes: WarningCause[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CausesDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<CausesDialogComponent>,
        @Inject(WarningService) public warningService: WarningService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit() {
        this.loading = true;
        if (!this.data.customerId) {
            throw Error('No customer ID');
        }
        if (!this.data.warningId) {
            throw Error('No warning ID');
        }

        this.warningService.get(this.data.customerId, this.data.warningId, [ 'causes.cause.employee.customer', 'observer' ]).subscribe((w) => {
            this.causes = w.causes || [];
            this.loading = false;
        });
    };
}
