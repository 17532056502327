// @ts-nocheck
import { module } from 'angular';
module('eaw.notifications').service('NotificationSettings', [ 'EawResource', function(EawResource) {
    const url = '/users/:user_id/notification_settings';
    this.getSettings = (userId, customerId = undefined) => EawResource.create(url).get({
        user_id: userId,
        customer_id: customerId,
    });
    this.reset = (userId, customerId = undefined) => EawResource.create(url).delete({
        user_id: userId,
        customer_id: customerId,
    });
    this.update = (userId, notification, channels) => EawResource.create(url).update({ user_id: userId }, {
        notification,
        channels,
    });
    return this;
} ]);
