<eaw-dialog-header>
    <span title>{{'COPY_SHIFT' | translate:'scheduling' | async}}</span>
    <span subtitle>{{'COPY_SHIFT_HELP' | translate:'scheduling' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    @if (loading) {
        <eaw-info-loading size="lg"/>
    } @else {
        <mat-selection-list multiple [disabled]="copying">
            @for (option of options; track option.value) {
                <mat-list-option [value]="option.value" [disabled]="option.disabled">
                    <span matListItemTitle>{{option.title | async}}</span>
                    <span matListItemLine>{{option.subtitle | async}}</span>
                </mat-list-option>
            }
        </mat-selection-list>
    }
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="copying" [disabled]="!selectedDays.length" (click)="copy()">{{'COPY' | translate | async}}</eaw-action-button>
</mat-dialog-actions>
