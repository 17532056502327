import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { UpdateUserAccessData } from '../user-access-dialog.service';
import { DialogComponent } from '../../../../../shared/dialogs/dialog-component';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { UserAccessService } from '../../../../http/user-access.service';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { Mobile } from '../../../../../shared/utils/eaw-mobile';
import { UserAccess } from '../../../../models/user-access';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { DateTimeInputComponent } from '../../../../../shared/components/date-time/date-time-input/date-time-input.component';
import { DateTimeRangeInputComponent } from '../../../../../shared/components/date-time/date-time-range-input/date-time-range-input.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../../../shared/dialogs/dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-edit-user-access-dialog',
    templateUrl: './edit-user-access-dialog.component.html',
    styleUrl: './edit-user-access-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DateTimeRangeInputComponent,
        DateTimeInputComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EditUserAccessDialogComponent extends DialogComponent {
    isMobile = Mobile.isMobile;
    userAccess;
    minFrom;
    formGroup: FormGroup<{ from: FormControl<DateTime>, to: FormControl<DateTime | null> }>;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: UpdateUserAccessData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<EditUserAccessDialogComponent, UserAccess>,
        @Inject(UserAccessService) private service: UserAccessService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
    ) {
        super(dialogRef, data);

        this.userAccess = data.userAccess;
        this.minFrom = DateTime.now().startOf('day');
        this.formGroup = new FormGroup({
            from: new FormControl({
                value: data.userAccess.from,
                disabled: data.userAccess.from <= DateTime.now(),
            }, {
                nonNullable: true,
                validators: [ Validators.required ],
            }),
            to: new FormControl(data.userAccess.to ?? null),
        });
    }

    // Update access period
    submit() {
        const from = this.formGroup.controls.from.value;
        const to = this.formGroup.controls.to.value;

        this.formGroup.disable();
        this.service.update(this.data.customer.id, this.userAccess.userId, this.userAccess.id, from, to)
            .subscribe((access) => {
                void this.snackbar.t('USER_ACCESS_UPDATED', 'company');
                this.dialogRef.close(access);
            });
    }
}
