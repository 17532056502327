import { Component, Inject, Input, OnInit } from '@angular/core';
import { UserPermissionService } from '../../angularjs/shared/services/user-permission.service';
import { MatDialog } from '@angular/material/dialog';
import { Permission } from '../../../shared/models/permission';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { AddPermissionDialogComponent } from '../../dialogs/add-permission-dialog/add-permission-dialog.component';
import { DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { SimplePermission } from '../../../shared/interfaces/simple-permission';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { mergeMap, of } from 'rxjs';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

interface ListPermission {
    permission: Permission;
    selected: boolean;
    disabled: boolean;
}

@Component({
    selector: 'eaw-user-permissions-tab',
    templateUrl: './user-permissions-tab.component.html',
    styleUrl: './user-permissions-tab.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        NgIf,
        NgFor,
        MatCheckboxModule,
        CheckboxHelperDirective,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class UserPermissionsTabComponent implements OnInit {
    @Input({ required: true }) userId!: number;

    constructor(
        @Inject(UserPermissionService) private readonly userPermissionService: UserPermissionService,
        @Inject(MatDialog) private readonly matDialog: MatDialog,
        @Inject(ConfirmDialogService) private readonly confirmDialogService: ConfirmDialogService,
        @Inject(PermissionCheckService) private readonly permissionCheckService: PermissionCheckService,
    ) {
    }

    fetching = true;
    permissions: ListPermission[] = [];
    fabButton: HeaderFabButton = {
        hasPermission: () => this.permissionCheckService.isAllowed(`users.${this.userId}.permissions.create`),
        click: this.addPermission.bind(this),
    };

    ngOnInit(): void {
        expandAllPages((pagination) => this.userPermissionService.getAll(this.userId, pagination), { per_page: 50 }).subscribe((permissions) => {
            this.permissions = permissions.map((p) => {
                return {
                    permission: p,
                    selected: p.value,
                    disabled: false,
                };
            });

            this.sortPermissions();
            this.fetching = false;
        });
    }

    addPermission() {
        this.matDialog.open<AddPermissionDialogComponent, DialogData, SimplePermission[]>(AddPermissionDialogComponent, {
            data: {
                size: DialogSize.Medium,
            },
        }).afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
            of(...res).pipe(mergeMap((i) => {
                return this.userPermissionService.create(this.userId, i.node, i.value);
            }, 2)).subscribe({
                next: (res) => {
                    this.permissions.push({
                        permission: res,
                        selected: res.value,
                        disabled: false,
                    });
                    this.sortPermissions();
                },
                error: (permission) => {
                    console.error('The following permission did not save:', permission.node);
                },
            });
        });
    }

    updatePermission(permission: ListPermission) {
        permission.disabled = true;

        this.userPermissionService.update(this.userId, permission.permission.node, permission.selected).subscribe((res) => {
            this.permissions = this.permissions.filter((p) => p.permission.node !== permission.permission.node);
            this.permissions.push({
                permission: res,
                selected: res.value,
                disabled: false,
            });
            this.sortPermissions();
        });
    }

    deletePermission(permission: ListPermission) {
        permission.disabled = true;

        this.confirmDialogService.delete({
            title: Promise.resolve('Delete Permission'),
            confirmText: Promise.resolve('Delete'),
        }).afterClosed().subscribe((res) => {
            if (!res?.ok) {
                permission.disabled = false;
                return;
            }

            this.userPermissionService.delete(this.userId, permission.permission.node).subscribe(() => {
                this.permissions = this.permissions.filter((p) => p.permission.node !== permission.permission.node);
                this.sortPermissions();
            });
        });
    }

    sortPermissions() {
        this.permissions = sort(this.permissions, 'en', [ ((p) => p.permission.node) ], [ 'asc' ]);
    }
}
