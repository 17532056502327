import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeEntry, EmployeeSelectComponent } from '../employee-select.component';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, startWith } from 'rxjs';
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatOptionModule } from '@angular/material/core';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'eaw-employee-select[multiple]',
    templateUrl: './employee-select-multiple.component.html',
    styleUrl: './employee-select-multiple.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: EmployeeSelectMultipleComponent,
        },
    ],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatChipsModule,
        NgFor,
        MatIconModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        CheckboxHelperDirective,
        MatOptionModule,
        NgIf,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmployeeSelectMultipleComponent extends EmployeeSelectComponent implements OnInit, AfterViewInit {
    @ViewChild('employeeInput') employeeInput!: MatAutocompleteTrigger;
    @ViewChild('emps') autocomplete!: MatAutocomplete;
    @ViewChild('checkbox') checkbox!: MatCheckbox;

    selectedEmployees: EmployeeEntry[] = [];

    protected validEmployees: EmployeeEntry[] = [];
    override initialValue = '';

    override ngOnInit() {
        super.ngOnInit();
        this.validEmployees = this.filteredEmployees.concat([]);

        this.selected.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
        ).subscribe((val) => {
            const searchString: string = val.toLowerCase();
            this.filteredEmployees = this.validEmployees.filter((e) => e.name.toLowerCase().includes(searchString) && !this.selectedEmployees.includes(e));

            this.touch();
        });
    }

    ngAfterViewInit(): void {
        this.autocomplete.optionSelected.subscribe((event) => {
            this.add(event.option.value);
        });
    }

    override afterDoCheck() {
        // docheck sets filtered employees = this.getEmployees.
        this.validEmployees = this.filteredEmployees.concat([]);

        this.selectedEmployees = this.selectedEmployees.filter((e) => {
            return this.validEmployees.includes(e);
        });

        if (this.validEmployees.length == this.selectedEmployees.length) {
            this.filteredEmployees = [];
            return;
        }

        this.filteredEmployees = this.filteredEmployees.filter((employee) => {
            return !this.selectedEmployees.includes(employee);
        });
    }

    override writeValue(obj?: number[] | EmployeeEntry[]): void {
        this.selected.setValue('');
        const selectedEmployees = obj?.map((e) => {
            if (typeof e == 'object') {
                return e;
            }

            return this.filteredEmployees.find((emp) => e == emp.id);
        });

        this.selectedEmployees = (selectedEmployees?.filter((e) => !!e) || []) as EmployeeEntry[];
    }

    override change() {
        const val = this.selectedEmployees.map((e) => e.id);

        super.change(val);
    }

    selectAll($event: MatCheckboxChange) {
        if ($event.checked) {
            this.selectedEmployees = this.validEmployees.concat([]);
            this.filteredEmployees = [];
        } else {
            this.selectedEmployees = [];
            this.filteredEmployees = this.validEmployees.concat([]);
        }

        this.change();
    }

    remove(employee: EmployeeEntry) {
        this.selectedEmployees = this.selectedEmployees.filter((e) => e.id != employee.id);
        this.selected.setValue(this.initialValue); // triggers re-filtering of employees.
        this.checkbox.checked = false;
        this.change();
    }

    add(employee: EmployeeEntry) {
        this.selectedEmployees.push(employee);
        this.selected.setValue(this.initialValue); // triggers re-filtering of employees.
        this.change();
        setTimeout(() => this.employeeInput.openPanel(), 100);
    }
}
