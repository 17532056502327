import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
    selector: 'mat-icon[eawOutlined]',
    standalone: true,
})
export class MatIconOutlinedDirective {
    /** Whether the icon should be outlined or not */
    @Input()
    get eawOutlined(): boolean {
        return this._outlined;
    }

    set eawOutlined(value: BooleanInput) {
        this._outlined = coerceBooleanProperty(value);

        if (this._outlined) {
            this.el.nativeElement.classList.add('material-icons-outlined');
        } else {
            this.el.nativeElement.classList.remove('material-icons-outlined');
        }
    }

    private _outlined: boolean = false;

    constructor(@Inject(ElementRef) private el: ElementRef) {
    }
}
