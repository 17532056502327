import { ApiResponse } from '../../shared/interfaces/api-response';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { NamespaceFile } from '../../shared/enums/namespace';

export type BalanceTypeUnit = 'DAYS' | 'HOURS';

export type BalanceTypeFactor = number | string;

/**
 * This is from the module.json file?
 */
interface BalanceTypeV1Response extends ApiResponse {
    /* This is the same as "balance_calculator" in V2 */
    class: string;
    factor: BalanceTypeFactor;
    name: string;
    unit: BalanceTypeUnit
    description: string | null;

    balance_calculator?: never;
    balance_code?: never;
    contract_type_ids?: never;
    from?: never;
    id?: never;
    interval_configuration?: never;
    object_id?: never;
    object_type?: never;
    to?: never;
}

/**
 * This is V2, the "real" one?
 */
interface BalanceTypeV2Response extends ApiResponse {
    class?: never;
    factor: BalanceTypeFactor;
    name: string;
    unit: BalanceTypeUnit;
    description: string | null;

    /* This is the same as "class" in V1 */
    balance_calculator: string;
    balance_code: string;
    contract_type_ids: number[];
    from?: string;
    id: number;
    /* What kind of interval this balance is calculated on */
    interval_configuration: {
        type: string;
    };
    object_id: number;
    object_type: 'customer';
    to: string | null;
}

export type BalanceTypeResponse = BalanceTypeV1Response | BalanceTypeV2Response;

export class BalanceType {
    class: string | undefined;
    balanceCalculator: string | undefined;
    balanceCode: string;
    contractTypeIds: number[] = [];
    description: string | null;
    factor: number;
    from?: DateTime;
    id?: number;
    name: string;
    objectId?: number;
    objectType?: 'customer';
    to?: DateTime | null;
    unit: BalanceTypeUnit;
    nameTranslation: {
        ns: NamespaceFile,
        key: string,
    };

    descriptionTranslation: {
        ns: NamespaceFile,
        key: string,
    };

    /* What kind of interval this balance is calculated on */
    intervalConfiguration?: {
        type: string;
    };

    constructor(code: string, data: BalanceTypeResponse) {
        this.nameTranslation = this.getNameTranslation(data.name);
        this.descriptionTranslation = this.getDescriptionTranslation(data.description || '');

        if ('id' in data) {
            this.id = data.id;
            this.balanceCalculator = data.balance_calculator;
            this.contractTypeIds = data.contract_type_ids || [];
            this.from = data.from ? stringToDateTime(data.from) : undefined;
            this.to = data.to ? stringToDateTime(data.to) : null;
            this.intervalConfiguration = data.interval_configuration;
            this.objectId = data.object_id;
            this.objectType = data.object_type;
        } else {
            this.class = data.class;
        }

        this.factor = typeof data.factor === 'number' ? data.factor : parseInt(data.factor);
        this.name = data.name;
        this.unit = data.unit;
        this.balanceCode = code;
        this.description = data.description;
    }

    private getNameTranslation(name: string) {
        const [ ns, key ] = name.split('.');
        return {
            ns: ns as NamespaceFile,
            key: key || '',
        };
    }

    private getDescriptionTranslation(description: string) {
        const [ ns, key ] = description.split('.');
        return {
            ns: ns as NamespaceFile,
            key: key || '',
        };
    }

    getFormattedValue(value: unknown, language: string, options: Intl.NumberFormatOptions = {}) {
        const number = parseFloat(String(value));
        let factor = 1;
        if (!Number.isFinite(number)) {
            return '';
        }

        if (this.unit === 'HOURS' || this.unit === 'DAYS') {
            factor = this.factor;

            options.style = 'unit';
            options.unit = this.unit === 'HOURS' ? 'hour' : 'day';
        }

        try {
            return new Intl.NumberFormat(language, options).format(number / factor);
        } catch (e) {
            console.error(e);
            return '';
        }
    }
}
