// @ts-nocheck
import { t } from 'i18next';
import { forEach, isDefined, module } from 'angular';

const I18nDirective = (function() {
    function I18nDirective($interpolate) {
        const _this = this;
        this.$interpolate = $interpolate;
        this.restrict = 'A';
        this.scope = false;
        this.controller = 'NgI18nextController';
        this.link = function($scope, $element, $attrs, ctrl) {
            const self = _this;
            let translationValue = '';
            let isTransformed = false;
            translationValue = $attrs.ngI18next.replace(/^\s+|\s+$/g, '');
            if (translationValue.indexOf('__once__') < 0) {
                $attrs.$observe('ngI18next', observe);
            } else {
                // Remove '__once__'
                translationValue = translationValue.split('__once__').join('');
                ctrl.localize(translationValue, true);
            }
            $scope.$on('i18nextLanguageChange', function() {
                ctrl.localize(translationValue);
            });

            function observe(value) {
                if (isDefined(value)) {
                    translationValue = value.replace(/^\s+|\s+$/g, ''); // RegEx removes whitespace
                    if (translationValue === '') {
                        return setupWatcher();
                    }
                    ctrl.localize(translationValue);
                }
            }

            function setupWatcher() {
                // Prevent from executing this method twice
                if (isTransformed) {
                    return;
                }
                // interpolate is allowing to transform {{expr}} into text
                const interpolation = self.$interpolate($element.html());
                $scope.$watch(interpolation, observe);
                isTransformed = true;
            }
        };
    }

    I18nDirective.factory = function() {
        const directive = function($interpolate) {
            return new I18nDirective($interpolate);
        };
        directive.$inject = [ '$interpolate' ];
        return directive;
    };
    return I18nDirective;
}());

const I18nBindOnceDirective = (function() {
    function I18nBindOnceDirective($compile) {
        const _this = this;
        this.$compile = $compile;
        this.restrict = 'A';
        this.scope = false;
        this.link = function(scope, element, attrs) {
            const newElement = element.clone();
            newElement.attr('ng-i18next', '__once__' + attrs.boI18next);
            newElement.removeAttr('bo-i18next');
            element.replaceWith(_this.$compile(newElement)(scope));
        };
    }

    I18nBindOnceDirective.factory = function() {
        const directive = function($compile) {
            return new I18nBindOnceDirective($compile);
        };
        directive.$inject = [ '$compile' ];
        return directive;
    };
    return I18nBindOnceDirective;
}());

const I18nDirectiveController = (function() {
    function I18nDirectiveController($scope, $element, $compile, $parse, $interpolate, $sanitize) {
        this.$scope = $scope;
        this.$element = $element;
        this.$compile = $compile;
        this.$parse = $parse;
        this.$interpolate = $interpolate;
        this.$sanitize = $sanitize;
    }

    I18nDirectiveController.prototype.localize = function(key, noWatch) {
        const keys = key.split(';');
        for (let _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            const item = keys_1[_i];
            key = item.trim();
            if (key === '') {
                continue;
            }
            this.parse(key, noWatch);
        }
    };

    I18nDirectiveController.prototype.parse = function(key, noWatch) {
        const _this = this;
        const parsedKey = this.parseKey(key);
        // If there are watched values, unregister them
        if (this.argsUnregister) {
            this.argsUnregister();
        }
        if (this.stringUnregister) {
            this.stringUnregister();
        }
        if (!noWatch) {
            this.argsUnregister = this.$scope.$watch(function() {
                return parsedKey.i18nOptions(_this.$scope);
            }, function() {
                return _this.render(parsedKey, noWatch);
            }, true);
        }
        this.render(parsedKey, noWatch);
    };
    I18nDirectiveController.prototype.parseKey = function(key) {
        let options = {
            attr: 'text',
        };
        let i18nOptions = '{}';
        let tmp;
        key = key.trim();
        if (key.indexOf('[') === 0) {
            tmp = key.split(']');
            options = this.parseOptions(tmp.shift().substr(1).trim());
            key = tmp.join(']');
        }
        if (key.indexOf('(') === 0 && key.indexOf(')') >= 0) {
            tmp = key.split(')');
            key = tmp.pop().trim();
            i18nOptions = tmp.join(')').substr(1).trim();
        }
        return {
            i18nOptions: this.$parse(i18nOptions),
            key,
            options,
        };
    };
    I18nDirectiveController.prototype.parseOptions = function(options) {
        const res = {
            attr: 'text',
        };
        const optionsSplit = options.split(':');
        for (let i = 0; i < optionsSplit.length; ++i) {
            if (optionsSplit[i] === 'i18next') {
                res[optionsSplit[i]] = true;
            } else {
                res.attr = optionsSplit[i];
            }
        }
        return res;
    };
    I18nDirectiveController.prototype.render = function(parsedKey, noWatch) {
        if (isDefined(this) && isDefined(this.$scope)) {
            const i18nOptions_1 = parsedKey.i18nOptions(this.$scope);
            if (i18nOptions_1.sprintf) {
                i18nOptions_1.postProcess = 'sprintf';
            }
            if (parsedKey.options.attr === 'html') {
                forEach(i18nOptions_1, function(value, key) {
                    let newValue;
                    const sanitized = this.$sanitize(value);
                    const numeric = Number(value);
                    if (typeof numeric === 'number' && !isNaN(numeric)) {
                        newValue = numeric;
                    } else {
                        newValue = sanitized;
                    }
                    i18nOptions_1[key] = newValue; // jshint ignore:line
                }, this);
            }
            const localizedString = t(parsedKey.key, i18nOptions_1);
            if (isDefined(localizedString)) {
                if (parsedKey.options.attr === 'html') {
                    this.$element.empty().append(localizedString);
                    /*
                     * Now compile the content of the element and bind the letiables to
                     * the scope
                     */
                    this.$compile(this.$element.contents())(this.$scope);
                    return;
                }
                if (this.stringUnregister) {
                    this.stringUnregister();
                }
                let insertText = this.$element.text.bind(this.$element);
                if (parsedKey.options.attr !== 'text') {
                    insertText = this.$element.attr.bind(this.$element, parsedKey.options.attr);
                }
                const localizedStringInterpolation = this.$interpolate(localizedString);
                if (!noWatch) {
                    this.stringUnregister = this.$scope.$watch(localizedStringInterpolation, insertText);
                }
                insertText(localizedStringInterpolation(this.$scope));
            }
        }
    };
    I18nDirectiveController.$inject = [ '$scope', '$element', '$compile', '$parse', '$interpolate', '$sanitize' ];
    return I18nDirectiveController;
}());

const I18nFilter = (function() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    function I18nFilter() {
    }

    I18nFilter.factory = function() {
        const filter = function() {
            function i18nextFilter(key, options) {
                const localOptions = isDefined(options) ? options : {};
                return t(key, localOptions);
            }

            i18nextFilter.$stateful = true;
            return i18nextFilter;
        };
        filter.$inject = [ ];
        return filter;
    };
    return I18nFilter;
}());

module('eaw')
    .directive('ngI18next', I18nDirective.factory())
    .directive('boI18next', I18nBindOnceDirective.factory())
    .controller('NgI18nextController', I18nDirectiveController)
    .filter('i18next', I18nFilter.factory());
