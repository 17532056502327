// @ts-nocheck
import { orderBy } from 'lodash-es';

import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.filesystem').controller('moveItemCtrl', [ '$mdDialog', 'FilesystemFactory', function($mdDialog, FilesystemFactory) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.cancel = $mdDialog.cancel;

        ctrl.getDirectory(ctrl.startDirectory.id);
    };

    ctrl.move = (directory) => {
        $mdDialog.hide(directory);
    };

    ctrl.isMoveItem = (directory) => directory.id === ctrl.item.id;

    ctrl.isSelected = (directory) => directory.id === ctrl.selectedDirectory?.id;

    ctrl.selectDirectory = (directory, event) => {
        const target = event.currentTarget;

        if (target.classList.contains('disabled')) {
            return;
        }

        const dirItems = document.querySelectorAll('.directory-element.directory');
        for (let i = 0; i < dirItems.length; i++) {
            dirItems[i].classList.remove('selected');
        }

        if (ctrl.selectedDirectory?.id === directory.id) {
            ctrl.selectedDirectory = null;
        } else {
            ctrl.selectedDirectory = directory;
            target.classList.add('selected');
        }
    };

    ctrl.getDirectory = (id) => {
        ctrl.gettingDirectory = true;
        ctrl.directory = null;
        FilesystemFactory.getDirectory.query(CurrentOld.customer.id, id, [ 'files.attachments', 'directories' ]).then((data) => {
            ctrl.directory = data;
            ctrl.directory.directories = orderBy(ctrl.directory.directories, [ 'name' ], [ 'asc' ]);
            ctrl.directory.files = orderBy(ctrl.directory.files, [ 'name' ], [ 'asc' ]);
            ctrl.isStartDirectory = ctrl.directory.id === ctrl.startDirectory.id;
            ctrl.gettingDirectory = false;
        }).catch(() => {
            $mdDialog.cancel();
        });
    };
} ]);
