import { inject, Injectable } from '@angular/core';
import { BasePromptDialogData, PromptDialogDataNumber, PromptDialogDataText, PromptDialogService } from '../dialogs/prompt-dialog/prompt-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from './translate.service';
import { SnackBarService } from './snack-bar.service';
import { Setting } from '../models/setting';
import { concatAll, from, map, Observable, of } from 'rxjs';
import { DatePickerDialogComponent, DatePickerDialogData, DatePickerDialogReturn } from '../dialogs/date-picker-dialog/date-picker-dialog.component';
import { FormControl } from '@angular/forms';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { KeyValueDialogComponent, KeyValueDialogData, KeyValueDialogReturn } from '../dialogs/key-value-dialog/key-value-dialog.component';
import { DialogSize } from '../dialogs/dialog-component';
import { Namespace } from '../enums/namespace';
import { ItemSelectorDialogComponent, ItemSelectorDialogData, ItemSelectorDialogReturn } from '../dialogs/item-selector-dialog/item-selector-dialog.component';

type SelectOption = { key: string, text: string };

@Injectable({
    providedIn: 'root',
})
export class EditSettingValueDialogService {
    private readonly promptDialogService = inject(PromptDialogService);
    private readonly matDialog = inject(MatDialog);
    private readonly translate = inject(TranslateService);
    private readonly snackBarService = inject(SnackBarService);

    openKeyValue(setting: Setting) {
        return this.matDialog.open<KeyValueDialogComponent, KeyValueDialogData, KeyValueDialogReturn>(KeyValueDialogComponent, {
            data: {
                title: this.translate.t(setting.name.key, setting.name.ns),
                value: setting.value?.asKeyValue(),
            },
        }).afterClosed();
    }

    open(setting: Setting): Observable<string | undefined> {
        if (setting.dataType === 'integer') {
            if (setting.inputType === 'seconds') {
                return this.updateDecimal(setting);
            } else {
                return this.updateInteger(setting);
            }
        }

        if (setting.dataType === 'float' || setting.dataType === 'double') {
            return this.updateDecimal(setting);
        }

        if (setting.dataType === 'time') {
            return this.updateTime(setting);
        }

        if (setting.dataType === 'string') {
            switch (setting.inputType) {
                case 'time':
                    return this.updateTime(setting);
                case 'select':
                    return fromPromise(this.updateSelect(setting)).pipe(concatAll());
                case 'percent':
                default:
                    return this.updateString(setting);
            }
        }

        if (setting.dataType === 'boolean' || setting.dataType === 'select') {
            return fromPromise(this.updateSelect(setting)).pipe(concatAll());
        }

        return from(this.snackBarService.t('UNSUPPORTED_TYPE', Namespace.Company)).pipe(map(() => undefined));
    }

    private getDefaultPromptOptions<T extends BasePromptDialogData>(setting: Setting) {
        return {
            title: this.translate.t(setting.name.key, setting.name.ns),
            text: this.translate.t(setting.description.key, setting.name.ns),
            label: this.translate.t('VALUE'),
            required: true,
            confirmText: this.translate.t('UPDATE'),
            size: DialogSize.Medium,
        } as T;
    }

    private updateTime(setting: Setting) {
        return this.matDialog.open<DatePickerDialogComponent, DatePickerDialogData, DatePickerDialogReturn>(DatePickerDialogComponent, {
            data: {
                title: this.translate.t(setting.name.key, setting.name.ns),
                target: 'time',
                confirmText: this.translate.t('UPDATE'),
                date: setting.value?.asTime() || undefined,
                nullable: false,
                size: DialogSize.Medium,
            },
        }).afterClosed().pipe(
            map((time) => {
                if (time?.ok && time.value) {
                    return time.value.toFormat('HH:mm');
                }

                return undefined;
            }),
        );
    }

    private updateString(setting: Setting) {
        return this.promptDialogService.open('text', {
            ...this.getDefaultPromptOptions<PromptDialogDataText>(setting),
            formControl: new FormControl(setting.value?.asString()),
        }).afterClosed().pipe(
            map((value) => {
                return value;
            }),
        );
    }

    private updateDecimal(setting: Setting) {
        const minValue = setting.minValue?.asFloat();
        const maxValue = setting.maxValue?.asFloat();
        let min = typeof minValue === 'number' ? minValue : 0;
        let max = typeof maxValue === 'number' ? maxValue : Number.MAX_SAFE_INTEGER;
        let value = setting.value?.asFloat();

        if (setting.inputType === 'seconds') {
            min = min / 3600;
            max = max / 3600;
            value = value != null ? value / 3600 : undefined;
        }

        return this.promptDialogService.open('decimal', {
            ...this.getDefaultPromptOptions<PromptDialogDataNumber>(setting),
            formControl: new FormControl(value),
            options: {
                step: 0.01,
                min,
                max,
            },
        }).afterClosed().pipe(map((value) => this.updateDecimalResultFormatter(setting, value)));
    }

    private updateDecimalResultFormatter(setting: Setting, value?: number) {
        if (value != null) {
            return setting.inputType === 'seconds' ? String(Math.round(value * 3600)) : String(value);
        }

        return undefined;
    }

    private updateInteger(setting: Setting) {
        const minValue = setting.minValue?.asInteger();
        const maxValue = setting.maxValue?.asInteger();
        const min = typeof minValue === 'number' ? minValue : 0;
        const max = typeof maxValue === 'number' ? maxValue : Number.MAX_SAFE_INTEGER;

        return this.promptDialogService.open('integer', {
            ...this.getDefaultPromptOptions<PromptDialogDataNumber>(setting),
            formControl: new FormControl(setting.value?.asInteger()),
            options: {
                step: 1,
                min,
                max,
            },
        }).afterClosed().pipe(map((value) => this.updateIntegerResultFormatter(setting, value)));
    }

    private updateIntegerResultFormatter(_: Setting, value?: number) {
        return value != null ? String(value) : undefined;
    }

    private async updateSelect(setting: Setting) {
        let options: SelectOption[] = [];

        for (const option of setting.options || []) {
            options.push({
                key: option.key,
                text: await this.translate.t(option.translation.key, option.translation.ns),
            });
        }

        if (setting.dataType === 'boolean') {
            options = [
                { key: '1', text: await this.translate.t('YES') },
                { key: '0', text: await this.translate.t('NO') },
            ];
        }

        return this.matDialog.open<ItemSelectorDialogComponent<SelectOption>, ItemSelectorDialogData<SelectOption>, ItemSelectorDialogReturn<SelectOption>>(ItemSelectorDialogComponent, {
            data: {
                items: of(options),
                itemText: (r) => r.text,
                multiple: false,
                title: this.translate.t(setting.name.key, setting.name.ns),
                confirmText: this.translate.t('UPDATE'),
            },
        }).afterClosed().pipe(
            map((value) => {
                return Array.isArray(value) ? undefined : value?.key;
            }),
        );
    }
}
