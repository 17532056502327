// @ts-nocheck
import { module } from 'angular';
import {smallDevice} from '../../../misc/services/easy-funcs.service';

const template1 = `<md-toolbar ng-if="dpdCtrl.heading">
    <div class="md-toolbar-tools">
        <h2 ng-bind="dpdCtrl.heading"></h2>
    </div>
</md-toolbar>

<md-calendar ng-model="dpdCtrl.date"
             ng-change="dpdCtrl.onChange()"
             md-mode="{{dpdCtrl.mode}}"
             md-date-filter="dpdCtrl.dateFilter"
             md-min-date="dpdCtrl.minDate"
             md-max-date="dpdCtrl.maxDate">
</md-calendar>
`;

module('eaw.time').factory('eawDatePickerDialog', [ '$mdPanel', function eawDatePickerDialog($mdPanel) {
    const service = this;

    service.open = (date, options) => {
        let el = options.event?.target;
        let position = $mdPanel.newPanelPosition()
            .relativeTo(el)
            .addPanelPosition(
                $mdPanel.xPosition[options.xPosition?.toUpperCase()] || $mdPanel.xPosition.ALIGN_START,
                $mdPanel.yPosition[options.yPosition?.toUpperCase()] || $mdPanel.yPosition.BELOW,
            );

        if (!el || smallDevice()) {
            el = document.body;
            position = $mdPanel.newPanelPosition()
                .absolute()
                .center();
        }

        return $mdPanel.open({
            attachTo: document.body,
            controller: 'datePickerDialogCtrl',
            controllerAs: 'dpdCtrl',
            template: template1,
            panelClass: 'eaw-panel date-picker-panel',
            position,
            hasBackdrop: options.hasBackdrop,
            animation: $mdPanel.newPanelAnimation()
                .openFrom(el)
                .closeTo(el)
                .duration(150)
                .withAnimation($mdPanel.animation.SCALE),
            locals: {
                date,
                options,
            },
            bindToController: true,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 100,
        });
    };

    return service;
} ]);
