import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { CurrentService } from '../services/current.service';

@Injectable({
    providedIn: 'root',
})
export class RequiresEmployeeHookService {
    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(CurrentService) private current: CurrentService,
    ) {
        this.uiRouter.transitionService.onBefore({
            to(state) {
                return state?.data?.requiresEmployee;
            },
        }, (transition) => {
            console.debug('🪝', 'RequiresEmployeeHookService.onBefore', transition);

            return !!this.current.getEmployee();
        });
    }
}
