import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { ApiModel } from '../../shared/enums/api-model';

import { CustomFieldPivot, CustomFieldPivotResponse } from '../models/custom-field-pivot';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';

type CustomFieldMetadata = Record<string, string | Record<string, string>>;

interface CreateBody {
    model: ApiModel;
    custom_field_id: number;
    has_interval?: boolean;
    required?: boolean;
    validator?: string;
    metadata?: CustomFieldMetadata;
    // This is to simplify putting the options into the metadata field
    selectOptions?: Record<string, string>;
    priority?: number;
}

interface UpdateBody {
    validator?: string | null;
    required?: boolean;
    has_interval?: boolean;
    metadata?: CustomFieldMetadata;
    // This is to simplify putting the options into the metadata field
    selectOptions?: Record<string, string> | null;
    priority?: number;
}

@Injectable({
    providedIn: 'root',
})
export class SettingGroupCustomFieldsService {
    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {
    }

    get(groupId: number, customFieldId: number, pivotId: number) {
        return this.http.get<CustomFieldPivotResponse>(`/setting_groups/${groupId}/custom_fields/${customFieldId}/${pivotId}`).pipe(classifyItem(CustomFieldPivot));
    }

    getAll(groupId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<CustomFieldPivotResponse>>(`/setting_groups/${groupId}/custom_fields`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(CustomFieldPivot));
    }

    create(groupId: number, createBody: CreateBody) {
        if (createBody.selectOptions) {
            createBody.metadata = {
                ...createBody.metadata,
                options: createBody.selectOptions,
            };

            delete createBody.selectOptions;
        }
        if (createBody.priority) {
            createBody.metadata = {
                ...createBody.metadata,
                priority: createBody.priority.toString(),
            };

            delete createBody.priority;
        }

        return this.http.post<CustomFieldPivotResponse>(`/setting_groups/${groupId}/custom_fields`, createBody).pipe(classifyItem(CustomFieldPivot));
    }

    update(groupId: number, customFieldId: number, pivotId: number, updateBody: UpdateBody) {
        if (updateBody.selectOptions) {
            updateBody.metadata = {
                ...updateBody.metadata,
                options: updateBody.selectOptions,
            };

            delete updateBody.selectOptions;
        }
        if (updateBody.priority) {
            updateBody.metadata = {
                ...updateBody.metadata,
                priority: updateBody.priority.toString(),
            };

            delete updateBody.priority;
        }

        return this.http.put<CustomFieldPivotResponse>(`/setting_groups/${groupId}/custom_fields/${customFieldId}/${pivotId}`, updateBody).pipe(classifyItem(CustomFieldPivot));
    }

    delete(groupId: number, customFieldId: number, pivotId: number) {
        return this.http.delete<undefined>(`/setting_groups/${groupId}/custom_fields/${customFieldId}/${pivotId}`);
    }
}
