import { Component, Inject, Input, OnInit, SkipSelf } from '@angular/core';
import type { Rotation } from '../shared/models/rotation';
import type { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import type { Observable } from 'rxjs';
import { RotationService } from '../shared/http/rotation.service';
import { ControlContainer } from '@angular/forms';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { AutocompleteDeprecatedComponent } from '../../shared/components/autocomplete-deprecated/autocomplete-deprecated.component';

@Component({
    selector: 'eaw-rotation-autocomplete',
    templateUrl: './rotation-autocomplete.component.html',
    styleUrl: './rotation-autocomplete.component.scss',
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [ [ new SkipSelf(), ControlContainer ] ],
        },
    ],
    standalone: true,
    imports: [ AutocompleteDeprecatedComponent, TranslatePipe ],
})
export class RotationAutocompleteComponent implements OnInit {
    @Input() customerId!: number;
    @Input() controlName!: string;

    limit = 30;
    observable?: Observable<ArrayPaginatedResponse<Rotation>>;

    constructor(
        @Inject(RotationService) protected service: RotationService,
        @Inject(ControlContainer) public controlContainer: ControlContainer,
    ) {}

    ngOnInit(): void {
        if (!Number.isFinite(this.customerId)) {
            throw Error('Missing customer');
        }
    }

    updateList(filter?: string) {
        this.observable = this.service.getAll(this.customerId, {
            page: 1,
            filter,
            per_page: this.limit,
        });
    }
}
