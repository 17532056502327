// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-dialog>
    <form name="fromToForm" ng-cloak>
        <eaw-dialog-header-old heading="::tidCtrl.options.title"></eaw-dialog-header-old>

        <md-dialog-content>
            <div class="md-dialog-content">
                <p ng-bind="::tidCtrl.options.text"></p>

                <eaw-time-interval
                        input-flex="50"
                        ng-model="tidCtrl.interval"
                        min-date="tidCtrl.options.minDate"
                        max-date="tidCtrl.options.maxDate"
                        base-date="tidCtrl.options.baseDate"
                        from-required="tidCtrl.options.fromRequired"
                        to-required="tidCtrl.options.toRequired">
                </eaw-time-interval>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button ng-click="tidCtrl.hide()" ng-i18next="OK" ng-disabled="fromToForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.dialogs').factory('timeIntervalDialog', [ '$mdDialog', function($mdDialog) {
    const service = this;

    /**
     * Open a dialog where a user can set a from and to time
     * @param {object}  options
     * @param {string}  options.title
     * @param {string}  options.text
     * @param {moment}  options.from
     * @param {moment}  options.to
     * @param {moment}  options.minDate
     * @param {moment}  options.maxDate
     * @param {moment}  options.baseDate
     * @param {bool}    options.fromRequired
     * @param {bool}    options.toRequired
     */
    service.open = (options) => $mdDialog.show({
        template: template1,
        controllerAs: 'tidCtrl',
        locals: {
            options,
        },
        multiple: true,
        bindToController: true,
        controller: function() {
            const ctrl = this;

            ctrl.$onInit = () => {
                ctrl.interval = {
                    from: ctrl.options?.from?.clone(),
                    to: ctrl.options?.to?.clone(),
                };
            };

            ctrl.hide = () => $mdDialog.hide({
                from: ctrl.interval?.from?.clone(),
                to: ctrl.interval?.to?.clone(),
            });
        },
    });

    return service;
} ]);
