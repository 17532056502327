// @ts-nocheck
import { find } from 'lodash-es';
import { orderBy } from 'lodash-es';

import { module } from 'angular';

const template1 = `<eaw-add-checklist-item parent="$ctrl.parent" on-add="$ctrl.sort()"></eaw-add-checklist-item>

<div class="children-list">
    <eaw-checklist-child ng-repeat="child in $ctrl.children" child="child"></eaw-checklist-child>
</div>

`;

module('eaw.checklists').component('eawChecklistChildrenList', {
    template: template1,
    bindings: {
        children: '<',
        parent: '<',
    },
    require: {
        checklistTemplate: '^^eawChecklistTemplate',
    },
    controller: [ '$element', 'ChecklistsService', function($element, ChecklistsService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.ChecklistsService = ChecklistsService;
            ctrl.checklistTypes = ctrl.ChecklistsService.getTypes();

            ctrl.makeSortable();
            ctrl.sort();
        };

        ctrl.$onChanges = (changes) => {
            if (changes?.children?.currentValue) {
                ctrl.children = [ ...changes.children.currentValue ];
                $element[0].dataset.parentId = ctrl.parent.id;
                ctrl.sort();
            }
        };

        ctrl.sort = () => {
            ctrl.children = orderBy(ctrl.children, [ 'weight', 'asc' ]);
        };

        ctrl.makeSortable = () => {
            ctrl.sortableOptions = {
                group: 'checklist-child-group',
                filter: '.open',
                draggable: 'eaw-checklist-child',
                handle: '.drag-icon',
                preventOnFilter: false,
                forceFallback: true,
                fallbackClass: 'checklist-child-fallback',
                onEnd(e) {
                    if (e.newIndex === e.oldIndex && e.from === e.to) {
                        return;
                    }

                    const childId = parseInt(e.item?.dataset?.childId);
                    const child = { ...find(ctrl.children, (c) => c.id === childId) };
                    const aboveWeight = parseInt(e.item?.previousElementSibling?.dataset?.weight);
                    const belowWeight = parseInt(e.item?.nextElementSibling?.dataset?.weight);
                    const list = e.item.closest('eaw-checklist-children-list');
                    const parentId = list.dataset.parentId ? parseInt(list.dataset.parentId) : null;

                    child.parent_id = parentId === ctrl.checklistTemplate.checklist.id ? null : parentId;
                    child.weight = ChecklistsService.findWeight(ctrl.parent, aboveWeight, belowWeight);

                    ctrl.checklistTemplate.update(child, e.item).then(() => {
                        e.item.remove(); // Remove what we moved after getting all the info we need
                    }).catch(() => {
                        const clone = e.item.cloneNode(true); // Deep clone what we dragged
                        e.item.remove(); // Remove it from the DOM\
                        e.from.insertBefore(clone, e.from.children[e.oldIndex]); // Insert it back where it was
                    });
                },
            };
        };
    } ],
});
