import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Widget } from '../../classes/widget';
import { WidgetComponent } from '../../classes/widget-component';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { AbsenceService } from '../../../absence/http/absence.service';
import { DateTime } from 'luxon';
import { TranslateService } from '../../../shared/services/translate.service';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { catchError, EMPTY } from 'rxjs';
import { MiniAbsenceWidgetSettings } from './mini-absence-settings-dialog/mini-absence-settings-dialog.component';

@Component({
    selector: 'eaw-mini-absence-widget',
    standalone: true,
    imports: [ CommonModule, MiniWidgetContentComponent ],
    templateUrl: './mini-absence-widget.component.html',
    styleUrl: './mini-absence-widget.component.scss' ,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniAbsenceWidgetComponent extends WidgetComponent implements OnInit {
    private absenceService = inject(AbsenceService);
    private translateService = inject(TranslateService);
    private numberFormatterService = inject(NumberFormatterService);

    widget = input.required<Widget<MiniAbsenceWidgetSettings>>();

    protected title = signal(Promise.resolve(''));
    protected subtitle = signal(Promise.resolve(''));

    ngOnInit() {
        const dayOffset = this.widget().getSetting('day_offset');
        if (dayOffset == null) {
            this.setError('settings');
            return;
        }

        this.absenceService.getAllForCustomer(this.widget().info.customer.id, {
            approved: true,
            per_page: 1,
            from: DateTime.now().plus({ days: dayOffset }).startOf('day'),
            to: DateTime.now().plus({ days: dayOffset }).endOf('day'),
        }).pipe(
            catchError((err) => {
                console.error(err);
                this.setError('data');
                return EMPTY;
            }),
        ).subscribe((absences) => {
            this.subtitle.set(this.translateService.t(dayOffset ? 'TOMORROW' : 'TODAY'));
            this.title.set(Promise.resolve(this.numberFormatterService.formatInteger(absences.total)));
            this.setLoading(false);
        });
    }
}
