import { overlaps } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { t } from 'i18next';
import CustomerOld from '../../../../shared/angularjs/customer-old';
import type { EmployeePaidTimeSegment } from '../employee-paid-time-segment.class';

export class ShiftTooltipCtrl {
    // Binding
    segment!: EmployeePaidTimeSegment;
    customerId!: number;

    currentCustomer!: boolean;
    timepunches!: any[];
    segmentFromTo!: string;
    customer!: CustomerOld;
    shifts!: any[];
    businessDateText?: string;

    $onInit() {
        this.currentCustomer = this.customerId === this.segment.customer;
        this.customer = this.segment.employeePaidTime.allCustomers.find((c: CustomerOld) => c['id'] === this.segment.customer);

        if (this.segment.from.isSame(this.segment.to, 'd')) {
            this.segmentFromTo = t('FROM_TO', {
                from: this.segment.from.format('LT'),
                to: this.segment.to.format('LT'),
            });
        } else {
            this.segmentFromTo = t('FROM_TO', {
                from: this.segment.from.format('lll'),
                to: this.segment.to.format('LT'),
            });
        }

        if (this.segment.businessDate) {
            this.businessDateText = `${t('BUSINESS_DATE')} ${this.segment.businessDate.format('L')}`;
        }

        // Overlapping
        this.shifts = this.segment.employeePaidTime.shifts.filter((s: any) => overlaps(s.from, s.to, this.segment.from, this.segment.to));
        this.timepunches = this.segment.employeePaidTime.timepunches.filter((tp: any) => overlaps(tp.from, tp.to, this.segment.from, this.segment.to));
    }
}
