import { DateTime } from 'luxon';
import { User, UserResponse } from './user';
import { stringToDateTime } from '../utils/string-to-date-time';
import { ApiResponse } from '../interfaces/api-response';

export interface ApprovalResponse extends ApiResponse {
    id: number;
    approvable_id: number;
    approvable_type: string;
    approved: boolean;
    handled_by?: number | null;
    handler?: UserResponse;
    handler_name?: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
}

export class Approval {
    id: number;
    approvableId: number;
    approvableType: string;
    approved: boolean;
    declined: boolean;
    handledBy?: number | null;
    handler?: User;
    handlerName?: string;
    createdAt: DateTime | null;
    updatedAt: DateTime | null;
    deletedAt: DateTime | null;

    constructor(data: ApprovalResponse) {
        this.id = data.id;
        this.approvableId = data.approvable_id;
        this.approvableType = data.approvable_type;
        this.approved = data.approved;
        this.declined = !data.approved;
        this.handledBy = data.handled_by;
        this.handler = data.handler ? new User(data.handler) : undefined;
        this.handlerName = data.handler_name;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
