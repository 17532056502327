// @ts-nocheck
import { module } from 'angular';

module('eaw.staffing').controller('addStaffingIntervalDialogCtrl', [ '$mdDialog', 'staffingIntervalFactory', function($mdDialog, staffingIntervalFactory) {
    const ctrl = this;

    ctrl.add = () => {
        ctrl.adding = true;
        staffingIntervalFactory.create.query(ctrl.staffingTemplate.customer_id, ctrl.staffingTemplate.id, ctrl.from, ctrl.to).$promise.then((newInterval) => {
            ctrl.tableParams.reload();
            $mdDialog.hide(newInterval);
        }).finally(() => ctrl.adding = false);
    };

    ctrl.cancel = $mdDialog.cancel;
} ]);
