import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { classifyArrayPaginatedResponse, classifyItem } from '../../../shared/utils/rxjs/classify';
import { PaginationOptions } from '../../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { DateTime } from 'luxon';
import { RoleAssignment, RoleAssignmentResponse } from '../types/role-assignment';
import { HttpClient } from '@angular/common/http';

type UpdateAttributes = {
    from?: string | DateTime,
    to?: string | DateTime | null
};

type CreateAttributes = {
    from: DateTime,
    to?: DateTime,
    user_id: number,
};

type Options = { pagination: PaginationOptions };

@Injectable({
    providedIn: 'root',
})
export class RoleAssignmentService {
    constructor(@Inject(HttpClient) protected http: HttpClient) { }

    create(customer: number, role: number, attributes: CreateAttributes): Observable<RoleAssignment> {
        return this.http.post<RoleAssignmentResponse>(`customers/${customer}/roles/${role}/assignments`, attributes).pipe(classifyItem(RoleAssignment));
    }

    get(customer: number, user: number, assignment: number): Observable<RoleAssignment> {
        return this.http.get<RoleAssignmentResponse>(`customers/${customer}/users/${user}/assignments/${assignment}`).pipe(classifyItem(RoleAssignment));
    }

    getAllForUser(customer: number, user: number, options?: Options): Observable<ArrayPaginatedResponse<RoleAssignment>> {
        return this.http.get<ArrayPaginatedResponse<RoleAssignmentResponse>>(`customers/${customer}/users/${user}/role_assignments`, {
            params: { ...options?.pagination },
        }).pipe(classifyArrayPaginatedResponse(RoleAssignment));
    }

    getAllForRole(customer: number, role: number, options?: Options): Observable<ArrayPaginatedResponse<RoleAssignment>> {
        return this.http.get<ArrayPaginatedResponse<RoleAssignmentResponse>>(`customers/${customer}/roles/${role}/assignments`, {
            params: { ...options?.pagination },
        }).pipe(classifyArrayPaginatedResponse(RoleAssignment));
    }

    update(customer: number, role: number, assignment: number, attrs: UpdateAttributes): Observable<RoleAssignment> {
        return this.http.put<RoleAssignmentResponse>(`customers/${customer}/roles/${role}/assignments/${assignment}`, attrs).pipe(classifyItem(RoleAssignment));
    }

    delete(customer: number, role: number, assignment: number): Observable<undefined> {
        return this.http.delete<undefined>(`customers/${customer}/roles/${role}/assignments/${assignment}`);
    }
}
