import { module } from 'angular';
import moment from 'moment-timezone';
import { t } from 'i18next';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

class BalancesWidgetCtrl {
    EawResource;

    static get $inject() {
        return [ 'EawResource' ];
    }

    mini: any;
    balance = '';
    name = '';
    constructor(EawResource: any) {
        this.EawResource = EawResource;
    }

    setBalance(type: any, balance: any) {
        this.balance = new Intl.NumberFormat(CurrentOld.languageTag, {
            style: 'unit',
            unit: type.unit.toLowerCase() === 'hours' ? 'hour' : 'day',
            unitDisplay: 'long',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(balance);
    }

    async $postLink() {
        const settingsType = this.mini.widget.settings.type;
        const settingsTime = this.mini.widget.settings.time;
        const types = await this.EawResource.create(`/customers/${this.mini.widget.customer}/balance_types`).get().$promise;
        const type = types.data[settingsType];
        if (!type) {
            this.mini.loading(false);
            return;
        }
        this.name = t(type.name.replace('.', ':'));
        this.EawResource.create(`/customers/${this.mini.widget.customer}/employees/${this.mini.widget.employee?.id}/balances`).get({
            'types[]': [ settingsType ],
            date: [ moment().endOf(settingsTime) ],
            'with[]': [ 'type' ],
        }).$promise.then((resp: any) => {
            this.setBalance(type, (resp[settingsType] ?? 0) / type.factor);
        }).catch(() => {
            this.setBalance(type, 0);
        }).finally(() => {
            this.mini.loading(false);
        });
    }
}
module('eaw.dashboard').component('balancesWidget', {
    template: `<h2 ng-bind="$bal.balance"></h2>
<span ng-bind="$bal.name"></span>
`,
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$bal',
    controller: BalancesWidgetCtrl,
});
