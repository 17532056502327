import { module } from 'angular';
import { createNavTabsState, createState } from '../../../shared/utils/create-state';
import { Namespace } from '../../../shared/enums/namespace';
import { NavTabsComponent } from '../../../shared/components/nav-tabs/nav-tabs.component';
import { navTabsTabsResolver } from '../../../shared/resolvers/nav-tabs-tabs.resolver';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { map } from 'rxjs';
import { AdminCustomerGroupsListComponent } from '../../pages/admin-customer-groups-list/admin-customer-groups-list.component';
import { AdminCustomerGroupCustomersComponent } from '../../pages/admin-customer-group-customers/admin-customer-group-customers.component';
import { AdminCustomerGroupProductsComponent } from '../../pages/admin-customer-group-products/admin-customer-group-products.component';
import { DataTableQueryParams } from '../../../data-table/data-table.component';
import { inject } from '@angular/core';
import { paramResolver } from '../../../shared/resolvers/param.resolver';

module('eaw.admin').config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: 'eaw/app/admin/customer_groups',
        parent: `eaw/app/admin`,
        url: '/customer_groups',
        views: {
            'content@': {
                component: AdminCustomerGroupsListComponent,
            },
        },
        data: {
            queryParams: [ ...DataTableQueryParams, 'filter' ],
            breadcrumb: { key: 'CUSTOMER_GROUPS', ns: Namespace.Navigation },
            permissionCheck: (permissionCheckService) => {
                return permissionCheckService.permissionOrChildren(`customer_groups.*.get`, undefined, `customer_groups`, `get`, true)
            },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/admin/customer_groups/view`,
        parent: `eaw/app/admin/customer_groups`,
        url: '/:id',
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        resolve: [
            paramResolver('id', 'groupId'),
            navTabsTabsResolver(function() {
                return Promise.resolve([
                    {
                        state: 'eaw/app/admin/customer_groups/view/customers',
                        label: { key: 'CUSTOMER_plural' },
                    },
                    {
                        state: 'eaw/app/admin/customer_groups/view/products',
                        label: { key: 'PRODUCT_plural', ns: Namespace.Admin },
                    },
                ]);
            }),
        ],
        data: {
            breadcrumb: (transition) => {
                return inject(CustomerGroupService).get(transition.params()['id']).pipe(
                    map((group) => group.name),
                );
            },
        },
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/admin/customer_groups/view/customers`,
        url: '/customers',
        parent: `eaw/app/admin/customer_groups/view`,
        component: AdminCustomerGroupCustomersComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'CUSTOMER_plural' },
        },
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/admin/customer_groups/view/products`,
        url: '/products',
        parent: `eaw/app/admin/customer_groups/view`,
        component: AdminCustomerGroupProductsComponent,
        data: {
            breadcrumb: { key: 'CUSTOMER_plural' },
        },
    });
} ]);
