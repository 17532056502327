import { module } from 'angular';
import { MyEmergencyContactComponent } from '../../pages/my-emergency-contact/my-emergency-contact.component';
import { EmergencyContactsComponent } from '../../pages/emergency-contacts/emergency-contacts.component';
import { createState } from '../../../shared/utils/create-state';
import { currentResolver } from '../../../shared/resolvers/current.resolver';
import { DataTableQueryParams } from '../../../data-table/data-table.component';

module('eaw.company').config([ '$stateProvider', function($stateProvider: any) {
    createState($stateProvider, {
        name: `eaw/app/company/emergency_contacts`,
        parent: `eaw/app/company`,
        url: '/emergency_contacts',
        views: {
            'content@': {
                component: EmergencyContactsComponent,
            },
        },
        data: {
            queryParams: DataTableQueryParams,
            permissions: [ 'customers.{customer}.employees.*.emergency_contact.get' ],
            breadcrumb: { key: 'EMERGENCY_CONTACTS', ns: 'navigation' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/company/my_emergency_contact`,
        parent: `eaw/app/company`,
        url: '/my_emergency_contact',
        views: {
            'content@': {
                component: MyEmergencyContactComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('employeeId', 'employee', 'id'),
        ],
        data: {
            requiresEmployee: true,
            permissions: [ 'customers.{customer}.employees.{employee}.emergency_contact.update' ],
            breadcrumb: { key: 'MY_EMERGENCY_CONTACT', ns: 'navigation' },
        },
    });
} ]);
