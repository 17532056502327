<mat-card appearance="raised">
    <mat-card-header>
        <mat-card-title>{{ 'OVERRIDE_AVAILABILITY' | translate: 'availabilities' | async}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="tw-pt-16">
        <eaw-autocomplete [formControl]="employeeCtrl"
                          class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 lg:tw-col-span-3"
                          [options]="employeeAutocompleteService.options"
                          [getter]="employeeAutocompleteService.getter({customerId: customerId})"
                          [setter]="employeeAutocompleteService.setter({customerId: customerId})">
        </eaw-autocomplete>
    </mat-card-content>
</mat-card>

<mat-card class="override-availability" appearance="raised">
    <mat-card-content>
        <eaw-create-availability [disabled]="disableForm" [approved]="true" [customerId]="customerId" [employeeId]="employeeCtrl.value?.id"></eaw-create-availability>
    </mat-card-content>
</mat-card>

<mat-card id="submit-card" appearance="raised">
    <mat-card-actions>
        <button mat-raised-button color="primary" [disabled]="employeeCtrl.invalid" (click)="submit()">
            {{ 'SAVE' | translate | async }}
        </button>
    </mat-card-actions>
</mat-card>
