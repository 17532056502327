import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(@Inject(OAuthService) private oAuthService: OAuthService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const uiRequest = request.clone({
            setHeaders: {
                'X-Ui-Version': environment.version,
            },
        });

        const token = this.oAuthService.getAccessToken();
        if (token && this.oAuthService.hasValidAccessToken()) {
            return next.handle(uiRequest.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            }));
        }

        return next.handle(uiRequest);
    }
}
