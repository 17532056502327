// @ts-nocheck
import { module } from 'angular';
module('eaw').factory('LanguageService', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const res = EawResource.create('/languages/:langCode');
    this.getAll = () => res.get();
    this.getAllPaginated = (args) => res.get({ ...Pagination.getParams(args) });
    this.updateByCode = (code, languageObject) => res.update({
        langCode: code,
    }, languageObject);
    this.deleteLanguage = (languageObject) => res.delete({
        langCode: languageObject.code,
    });
    this.create = (languageObject) => res.save(languageObject);
    return this;
} ]);
