import { module } from 'angular';
import { AdminUpgradeProgressComponent } from '../../pages/admin-upgrade-progress/admin-upgrade-progress.component';
import { Namespace } from '../../../shared/enums/namespace';

module('eaw.admin').config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state('eaw/app/admin/stats', {
        parent: 'eaw/app/admin',
        url: '/stats',
        abstract: true,
        data: {
            breadcrumb: { key: 'STATISTIC_plural' },
        },
    });

    $stateProvider.state('eaw/app/admin/stats/user_stats', {
        parent: 'eaw/app/admin/stats',
        url: '/active_users',
        views: {
            'content@': {
                component: 'eawAdminStatsUserStats',
            },
        },
        data: {
            breadcrumb: { key: 'Active users' },
            permissions: [ `users.*.get` ],
        },
    });

    $stateProvider.state('eaw/app/admin/stats/update_progress', {
        parent: 'eaw/app/admin/stats',
        url: '/update_progress',
        views: {
            'content@': {
                component: AdminUpgradeProgressComponent,
            },
        },
        data: {
            breadcrumb: { key: 'UI_UPGRADE_PROGRESS', ns: Namespace.Admin },
            permissions: [ `ui_upgrade_progress_page` ],
        },
    });
} ]);
