<eaw-dialog-header>
    <span title>{{ 'CHOOSE_BUSINESS_DATE' | translate:'scheduling' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <mat-selection-list #selectionList [multiple]="false">
        @for (option of options(); track option) {
            <mat-list-option (click)="value.set(option)" [selected]="value() === option">
                <span matListItemTitle>{{ getDate(option) | DateTime:'DATE_MED_WITH_WEEKDAY' }}</span>

                @if (option === -1) {
                    <span matListItemLine>{{'PREVIOUS_DAY' | translate | async}}</span>
                } @else if (option === 1) {
                    <span matListItemLine>{{'NEXT_DAY' | translate | async}}</span>
                } @else {
                    <span matListItemLine>{{'SAME_DAY' | translate | async}}</span>
                }
            </mat-list-option>
        }
    </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>

    <eaw-action-button type="submit" [loading]="false" (click)="dialogRef.close(this.getDate(value()))">
        <span>{{ 'CHOOSE_DATE' | translate | async }}</span>
    </eaw-action-button>
</mat-dialog-actions>
