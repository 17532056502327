import { AfterViewInit, ChangeDetectionStrategy, Component, inject, signal, viewChild } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { MatMenuModule } from '@angular/material/menu';
import { BulletinService } from '../../../shared/http/bulletin.service';
import { CurrentService } from '../../../shared/services/current.service';
import { Bulletin } from '../../../shared/models/bulletin';
import { TranslateService } from '../../../shared/services/translate.service';
import { catchError, EMPTY, tap } from 'rxjs';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog } from '@angular/material/dialog';
import { WysiwygDialogComponent, WysiwygDialogData, WysiwygDialogReturn } from '../../../wysiwyg/dialogs/wysiwyg-dialog/wysiwyg-dialog.component';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProfilePictureComponent } from '../../../shared/components/profile-picture/profile-picture.component';
import { NgFor, AsyncPipe } from '@angular/common';
import { MarkdownComponent } from '../../../shared/components/markdown/markdown.component';

@Component({
    selector: 'eaw-bulletins-widget',
    templateUrl: './bulletins-widget.component.html',
    styleUrl: './bulletins-widget.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgFor,
        ProfilePictureComponent,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatProgressSpinnerModule,
        AsyncPipe,
        DateTimePipe,
        MarkdownComponent,
    ],
})
export class BulletinsWidgetComponent extends WidgetComponent implements AfterViewInit {
    current = inject(CurrentService);
    bulletinService = inject(BulletinService);
    translate = inject(TranslateService);
    matDialog = inject(MatDialog);

    moreSpinner = viewChild.required<MatProgressSpinner>('moreSpinner');

    private perPage = 8;
    private currentPage = 0;
    bulletins = signal<Bulletin[]>([]);
    hasMore = signal<boolean>(false);

    ngAfterViewInit() {
        this.updateNormalHeaderButtons([
            { click: this.add.bind(this), icon: 'add', tooltip: 'ADD' },
            { click: this.refresh.bind(this), icon: 'refresh', tooltip: 'REFRESH' },
        ]);

        this.getPage(1);
    }

    observeSpinner() {
        const target = this.moreSpinner()._elementRef.nativeElement;
        const observer = new IntersectionObserver((entry, observer) => {
            if (entry[0]?.isIntersecting) {
                observer.unobserve(target);
                this.getPage(this.currentPage + 1);
            }
        }, {
            root: this.elementRef.nativeElement,
            rootMargin: '0px',
            threshold: 0,
        });

        observer.observe(target);
    }

    getPage(page: number) {
        this.bulletinService.getAll(this.current.getCustomer().id, {
            page,
            per_page: this.perPage,
            'with[]': [ 'user' ],
        }).subscribe((res) => {
            this.hasMore.set(res.current_page < res.last_page);
            this.currentPage = res.current_page;
            this.bulletins.update((bulletins) => bulletins.concat(res.data));

            if (this.hasMore()) {
                this.observeSpinner();
            }

            this.setLoading(false);
        });
    }

    add() {
        this.matDialog.open<WysiwygDialogComponent<Bulletin>, WysiwygDialogData<Bulletin>, WysiwygDialogReturn<Bulletin>>(WysiwygDialogComponent, {
            data: {
                title: this.translate.t('NEW_POSTIT', 'widgets'),
                submitText: this.translate.t('PUBLISH_POSTIT', 'widgets'),
                submitFn: (body) => {
                    if (!body) {
                        return EMPTY;
                    }

                    return this.bulletinService.create(this.current.getCustomer().id, body).pipe(
                        tap((bulletin) => {
                            // Set the user
                            bulletin.user = this.current.getUser();
                            this.bulletins.update((bulletins) => [ bulletin, ...bulletins ]);
                        }),
                    );
                },
            },
        });
    }

    editBulletin(bulletin: Bulletin) {
        this.matDialog.open<WysiwygDialogComponent<Bulletin>, WysiwygDialogData<Bulletin>, WysiwygDialogReturn<Bulletin>>(WysiwygDialogComponent, {
            data: {
                title: this.translate.t('EDIT_POSTIT', 'widgets'),
                submitText: this.translate.t('UPDATE_POSTIT', 'widgets'),
                content: bulletin.body,
                submitFn: (body) => {
                    if (!body) {
                        return EMPTY;
                    }

                    return this.bulletinService.update(this.current.getCustomer().id, bulletin.id, body).pipe(
                        tap((bulletin) => {
                            // Set the user
                            bulletin.user = this.current.getUser();

                            // Replace the bulletin
                            this.bulletins.update((bulletins) => {
                                const index = bulletins.findIndex((item) => item.id === bulletin.id);
                                bulletins.splice(index, 1, bulletin);
                                return [ ...bulletins ];
                            });
                        }),
                    );
                },
            },
        });
    }

    deleteBulletin(bulletin: Bulletin) {
        this.bulletinService.delete(this.current.getCustomer().id, bulletin.id).pipe(
            catchError(() => {
                return EMPTY;
            }),
            tap(() => {
                this.bulletins.update((bulletins) => bulletins.filter((item) => item.id !== bulletin.id));
            }),
        ).subscribe();
    }

    refresh() {
        this.bulletins.set([]);
        this.getPage(1);
    }
}
