<eaw-widget-settings-dialog [widgetInfo]="data.widgetInfo" [valid]="form.valid" (submitted)="submitted()">
    <form content class="tw-flex tw-flex-col" [formGroup]="form">
        @if (loading()) {
            <eaw-info-loading size="md"/>
        } @else {
            <mat-form-field>
                <mat-label>{{ 'PERIOD' | translate | async }}</mat-label>
                <mat-select formControlName="month">
                    @for (month of months(); track month.value) {
                        <mat-option [value]="month.value">{{ month.text | async }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'HOUR_plural' | translate | async }}</mat-label>
                <mat-select formControlName="basis">
                    @for (basis of basisOptions(); track basis.value) {
                        <mat-option [value]="basis.value">{{ basis.label | async }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
    </form>
</eaw-widget-settings-dialog>
