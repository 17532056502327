import { Approval, ApprovalResponse } from '../../shared/models/approval';
import { Comment, CommentResponse } from '../../shared/models/comment';
import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { DateTime } from 'luxon';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BaseApiModel } from '../../shared/models/base-api-model';

export interface OffTimeResponse extends ApiResponse {
    availability?: [ number, number ];
    approval?: ApprovalResponse | null;
    comments?: CommentResponse[];
    created_at: string;
    deleted_at?: string | null;
    employee?: EmployeeResponse;
    employee_id: number;
    from: string;
    id: number;
    name: string;
    to: string;
    updated_at: string | null;
    vacation?: boolean;
}

export class OffTime extends BaseApiModel<OffTimeResponse, OffTime> {
    approval: Approval | null;
    comments?: Comment[];
    createdAt: DateTime;
    deletedAt: DateTime | null;
    employee: Employee | null;
    employeeId: number;
    from: DateTime;
    id: number;
    /** Name of the employee */
    name: string;
    to: DateTime;
    updatedAt: DateTime | null;
    vacation?: boolean;
    availability?: [ number, number ];

    constructor(data: OffTimeResponse) {
        super(data, undefined);

        this.approval = data.approval ? new Approval(data.approval) : null;
        this.comments = data.comments?.map((c) => new Comment(c));
        this.employee = data.employee ? new Employee(data.employee) : null;
        this.employeeId = data.employee_id;
        this.availability = data.availability;
        this.from = stringToDateTime(data.from);
        this.to = stringToDateTime(data.to);
        this.id = data.id;
        this.name = data.name;
        this.vacation = data.vacation;
        this.createdAt = stringToDateTime(data.created_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
    }
}
