// @ts-nocheck
import { module } from 'angular';
import { Products } from '../../enums/products';
import { createState } from '../../utils/create-state';
import { InspectionComponent } from '../../../inspection/pages/inspection/inspection.component';
module('eaw').config([ '$stateProvider', function config($stateProvider: any) {
    createState($stateProvider, {
        parent: 'eaw/app',
        name: 'eaw/app/inspection_report',
        url: '/inspection_report',
        views: {
            'content@': {
                component: InspectionComponent,
            },
        },
        data: {
            products: [ Products.Inspection ],
            permissions: [ 'customers.{customer}.inspection.get' ],
            breadcrumb: { key: 'INSPECTION_REPORT', ns: 'navigation' },
        },
    });
} ]);
