<eaw-page-header>
    <div title>
        {{'ORGANIZATIONS' | translate: 'navigation' | async}}
    </div>
    <mat-form-field class="standalone" smallFilter>
        <mat-label>{{'SEARCH' | translate | async}}</mat-label>
        <input matInput type="text" autofocus autocomplete="off" [formControl]="search">
    </mat-form-field>
</eaw-page-header>

<mat-card>
    <eaw-data-table
        #dataTable
        [columns]="columns"
        [goTo]="{ state: 'eaw/app/admin/organizations/info/members', params: [{stateKey: 'organizationId', itemKey: 'id'}] }"
        (paginationChange)="updateTable($event)"
        [request]="request">
    </eaw-data-table>
</mat-card>
