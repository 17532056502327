// @ts-nocheck
import { module } from 'angular';
import { PaginationOld } from '../../../utils/pagination-old';
import { pick } from 'lodash-es';

export class UserGroupService {
    EawResource;
    static get $inject() {
        return [ 'EawResource' ];
    }

    url = '/:customers/:customerId/user_groups/:userGroupId/:members/:memberId/:memberships/:membershipId/:permissions/:permission';
    customerUrl = '/customers/:customerId/user_groups/:userGroupId/:members/:memberId/:memberships/:membershipId/:permissions/:permission';
    constructor(EawResource) {
        this.EawResource = EawResource;
    }

    getAllGroupsForCustomer(customerId, pagination) {
        return this.EawResource.create(`/customers/:customerId/user_groups`).get({
            customerId,
            permissions: pagination.permissions,
            ...PaginationOld.getParams(pagination),
        });
    }

    /**
     * @deprecated
     * @param customerId
     * @param pagination
     * @return {*}
     */
    getAll(customerId, pagination) {
        return this.EawResource.create(this.customerUrl).get({
            customerId,
            ...PaginationOld.getParams(pagination),
        });
    }

    getAllForCustomerGroup(groupId, pagination) {
        return this.EawResource.create(`/customer_groups/:groupId/user_groups`).get({
            groupId,
            ...PaginationOld.getParams(pagination, [ 'permissions' ]),
        });
    }

    /**
     *
     * @param args
     * @deprecated
     * @see UserGroupService.getAll
     */
    getAllPaginated(args) {
        return this.getAll(args.customerId, args);
    }

    get(customerId, id, withRelations) {
        return this.EawResource.create(this.customerUrl).get({
            customerId,
            userGroupId: id,
            'with[]': withRelations,
        });
    }

    create(customerId, name) {
        return this.EawResource.create(this.customerUrl).save({
            customerId,
        }, {
            name,
        });
    }

    update(customerId, id, updates) {
        return this.EawResource.create(this.customerUrl).update({
            customerId,
            userGroupId: id,
        }, updates);
    }

    delete(customerId, id) {
        return this.EawResource.create(this.customerUrl).delete({
            customerId,
            userGroupId: id,
        });
    }

    // ONLY FOR ADMIN
    updateById(userGroupId, groupObject) {
        return this.EawResource.create(this.url).update({
            userGroupId,
        }, groupObject);
    }

    deleteById(id: number) {
        return this.EawResource.create(this.url).delete({
            userGroupId: id,
        });
    }

    /**
     * @param {int} customerId
     * @param {int} userGroupId
     * @param {int} memberId - user id
     * @param {int} membershipId
     * @returns {*}
     */
    removeMember(customerId, userGroupId, memberId, membershipId) {
        return this.EawResource.create(this.customerUrl).delete({
            customerId,
            userGroupId,
            memberId,
            members: 'members',
            ...(membershipId ? {
                membershipId,
                memberships: 'memberships',
            } : {}),
        });
    }

    addMember(customerId, userGroupId, userId, from, to) {
        return this.EawResource.create(this.customerUrl, {}, {
            save: {
                method: 'POST',
                headers: {
                    'X-Ignore-Error': true,
                },
            },
        }).save({
            customerId,
            userGroupId,
            members: 'members',
        }, {
            member_id: userId,
            from,
            to,
        });
    }

    updateMember(customerId, userGroupId, memberId, membershipId, from, to) {
        return this.EawResource.create(this.customerUrl).update({
            customerId,
            userGroupId,
            memberId,
            members: 'members',
            ...(membershipId ? {
                membershipId,
                memberships: 'memberships',
            } : {}),
        }, {
            from,
            to,
        });
    }

    import(customerId, data) {
        return this.EawResource.create(this.customerUrl).save({
            customerId,
            userGroupId: 'import',
        }, data);
    }

    getMembers(ngTableObject) {
        const params = pick(ngTableObject, [ 'customerId', 'userGroupId', 'filter', 'direction', 'per_page', 'page', 'order_by' ]);

        params.customers = params.customerId ? 'customers' : undefined;
        params.members = 'members';
        params.display_inactive_users = ngTableObject.display_inactive_users ? 1 : 0;

        return this.EawResource.create(this.url).get(params);
    }
}
module('eaw.user-groups').service('UserGroupService', UserGroupService);
