// @ts-nocheck
import { module } from 'angular';

module('eaw.scheduling').service('intervalService', function() {
    let _schedule;

    const timeTeller = document.createElement('div');
    timeTeller.id = 'time-teller';

    return {
        setSchedule(schedule) {
            _schedule = schedule;
        },
        getOneSecondPixelLength() {
            return this.getMarkerInterval() / _schedule.settings.interval;
        },
        getIntervalIndexFromDate(date) {
            return _schedule.getIntervalIndexFromDate(date, _schedule.settings.interval);
        },
        calculateTimeFromOffset(offset) {
            return _schedule.getFrom().add(offset, 's');
        },
        calculateOffsetFromPixels(pixels) {
            return pixels / this.getOneSecondPixelLength();
        },
        calculateClosestMarkerOffsetFromPixels(pixels, dstOffset = true) {
            // Closest marker is now a half
            const closestMarkerIndex = Math.round((pixels / this.getMarkerInterval()) * 2) / 2;
            const closestMarkerPixelOffset = closestMarkerIndex * this.getMarkerInterval();

            const calculatedOffset = this.calculateOffsetFromPixels(closestMarkerPixelOffset);
            return (dstOffset && _schedule.dstChangeFrom <= calculatedOffset) ? calculatedOffset - 3600 : calculatedOffset;
        },
        getMarkerInterval() {
            return getComputedStyle(document.body).getPropertyValue('--schedule-shift-table-cell-width').replace('px', '') * 2;
        },
        addTimeTellers() {
            document.body.append(timeTeller);
        },
        removeTimeTellers() {
            timeTeller.remove();
        },
        /**
         * Update time on time tellers
         * @param element
         * @param [fromOffset] - Optional additional offset to add to calculated offset
         * @param toClosestMarker
         */
        calculateTimeTellersTime(element, fromOffset, toClosestMarker = true) {
            const calculateMethod = toClosestMarker ? 'calculateClosestMarkerOffsetFromPixels' : 'calculateOffsetFromPixels';

            const offset = fromOffset || _schedule.render.offset || 0;
            const eleRect = element.getBoundingClientRect();
            const leftOffset = this[calculateMethod](element.offsetLeft) + offset;
            const leftTime = this.calculateTimeFromOffset(leftOffset).format('LT');

            // Subtract a pixel from width because it is added to width to align with cells at the top
            let rightOffset = leftOffset + this.calculateOffsetFromPixels(eleRect.width - 1);
            rightOffset = this[calculateMethod](rightOffset * this.getOneSecondPixelLength());
            const rightTime = this.calculateTimeFromOffset(rightOffset).format('LT');

            timeTeller.innerText = `${leftTime} - ${rightTime}`;
            timeTeller.style.top = `${eleRect.top + eleRect.height}px`;
            timeTeller.style.left = `${eleRect.left}px`;
        },
    };
});
