// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.admin').controller('settingGroupCustomersCtrl', [ 'settingGroup', function(settingGroup) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.settingGroup = settingGroup;
        ctrl.table = {
            columns: [
                {
                    title: t('ID'),
                    sortable: 'id',
                    class: 'text-right',
                },
                {
                    title: t('NAME'),
                    sortable: 'name',
                },
                {
                    title: t('NUMBER'),
                    sortable: 'number',
                },
                {
                    title: 'Organization number',
                    sortable: 'organization_number',
                },
            ],
            cells: [
                {
                    template: 'item.id',
                    class: 'text-right',
                },
                { template: 'item.name' },
                { template: 'item.number' },
                { template: 'item.organization_number' },
            ],
        };
    };
} ]);
