import { inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { DateTime } from 'luxon';
import { forkJoin, map, Observable } from 'rxjs';
import { AggregatedTimepunchRow } from '../models/aggregated-timepunch-row';
import { EmployeeResponse } from '../../shared/models/employee';
import { AggregateTimepunchInfoService } from '../services/aggregate-timepunch-info.service';
import { AggregateTimepunchValues } from '../types/aggregate-timepunch-values';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { BusinessDate } from '../../shared/utils/business-date';
import { Products } from '../../shared/enums/products';
import { CustomerProductService } from '../../shared/http/customer-product.service';

export type AggregateTimepunchInterval = 'none' | 'day' | 'week' | 'month' | 'quarter';

interface GetOptions extends PaginationOptions {
    approved?: boolean;
    'employee_ids[]'?: number[];
    'columns[]'?: AggregateTimepunchValues[],
    business_unit_id?: number,
    only_direct_subordinates?: boolean,
    'balances[]'?: string[],
    only_trashed?: boolean,
    with_trashed?: boolean,
    only_edited?: boolean,
}

export type AggregatedResponseRow = {
    [key in AggregateTimepunchValues]: any;
} & {
    ids?: number[];
    from?: string;
    to?: string;
    progress?: number;
    progress_count?: number;
    trashed_count?: number,
    employee_id?: number;
    business_date?: string,
    balances?: Record<string, number>,
};

export interface AggregatedResponse {
    employees: EmployeeResponse[];
    rows: AggregatedResponseRow[];
}

export interface TimepunchSummary {
    employee_id: number;
    employee_name: string;
    length: number;
    timepunch_count: number;
}

@Injectable({
    providedIn: 'root',
})
export class TimepunchSummaryService {
    private customerProductService = inject(CustomerProductService);
    private http = inject(HttpClient);
    private aggregateTimepunchInfoService = inject(AggregateTimepunchInfoService);

    getAggregated(customerId: number, from: DateTime, to: DateTime, interval: AggregateTimepunchInterval, options: GetOptions = {}): Observable<AggregatedTimepunchRow[]> {
        const hasScheduling = this.customerProductService.hasProducts(customerId, [ Products.Scheduling ]);
        const aggregateRequest = this.http.get<AggregatedResponse>(`customers/${customerId}/timepunches/aggregate`, {
            params: formatHttpParams({
                from,
                to,
                interval,
                'columns[]': options['columns[]'],
                'balances[]': options['balances[]'],
                'employee_ids[]': options['employee_ids[]'],
                approved: options.approved,
                business_unit_id: options.business_unit_id,
                only_direct_subordinates: options.only_direct_subordinates,
                with_trashed: options.with_trashed,
                only_trashed: options.only_trashed,
                only_edited: options.only_edited,
            }),
        });

        return forkJoin([
            aggregateRequest,
            hasScheduling,
        ]).pipe(
            map(([ response, hasScheduling ]) => {
                const rows: AggregatedTimepunchRow[] = [];

                response.rows.forEach((row) => {
                    rows.push(new AggregatedTimepunchRow(row, this.aggregateTimepunchInfoService, hasScheduling));
                });

                return rows;
            }),
        );
    }

    getSummary(customerId: number, from: BusinessDate, to: BusinessDate) {
        return this.http.get<TimepunchSummary[]>(`/customers/${customerId}/employee_timepunch_summary`, {
            params: formatHttpParams({
                from,
                to,
            }),
        });
    }
}
