<eaw-dialog-header>
    <span title>{{'CONNECT_USER_TO_EMPLOYEE' | translate: 'company' | async}}</span>
</eaw-dialog-header>
<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field *ngIf="!searching" formGroupName="user" [ngClass]="{stretch: !showUserSearch}">
            <mat-label>{{'EMAIL' | translate | async}}</mat-label>
            <input matInput type="email" formControlName="email">
        </mat-form-field>
        <div id="searchBtn" *ngIf="showUserSearch && !searching">
            <button
                    mat-button
                    mat-raised-button
                    color="accent"
                    [disabled]="!form.controls.user.controls.email.valid"
                    (click)="search()">
                {{'SEARCH' | translate | async}}
            </button>
        </div>
        <mat-progress-spinner *ngIf="searching" class="tw-col-span-full tw-justify-center tw-items-center" mode="indeterminate"></mat-progress-spinner>

        <ng-container *ngIf="showUserForm" formGroupName="user">
            <mat-form-field>
                <mat-label>{{'FIRST_NAME' | translate | async}}</mat-label>
                <input matInput type="text" formControlName="firstName">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'LAST_NAME' | translate | async}}</mat-label>
                <input matInput type="text" formControlName="lastName">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'DIAL_CODE' | translate | async}}</mat-label>
                <input matInput type="text" formControlName="dialCode" [matAutocomplete]="dialAuto">
                <mat-autocomplete #dialAuto [displayWith]="dialCodeDisplay">
                    <mat-option *ngFor="let country of filteredCountries|async" [value]="country.Dial.toString()">
                        {{country.name}} (+{{country.Dial}})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'PHONE' | translate | async}}</mat-label>
                <input matInput type="tel" formControlName="phone">
            </mat-form-field>

            <eaw-language-autocomplete controlName="language"></eaw-language-autocomplete>
        </ng-container>
        <ng-container formGroupName="access" *ngIf="showAccessForm">
            <mat-form-field>
                <mat-label>{{'USER_GROUP_plural' | translate | async}}</mat-label>
                <mat-select multiple formControlName="groups">
                    <div class="mat-option">
                        <input matInput
                               [placeholder]="('SEARCH' | translate | async) || ''"
                               autofocus
                               autocomplete="off"
                               type="text"
                               formControlName="userGroupSearch">
                    </div>
                    <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
                        {{group.name}}
                    </mat-option>
                </mat-select>
                <mat-progress-spinner matSuffix strokeWidth="3" diameter="24" *ngIf="!userGroups.length" mode="indeterminate"></mat-progress-spinner>
            </mat-form-field>

            <div class="toggles stretch tw-flex tw-flex-col tw-gap-8">
                <mat-slide-toggle formControlName="custom">{{'SET_START_DATE' | translate: 'company' | async}}</mat-slide-toggle>
                <mat-slide-toggle formControlName="temp">{{'TEMPORARY_ACCESS' | translate: 'company' | async}}</mat-slide-toggle>
            </div>

            <eaw-date-time-range-input class="tw-col-span-full tw-w-full tw-grid tw-grid-cols-1 tw-gap-8">
                <mat-form-field eawDateTimeFrom [class.tw-hidden]="!accessForm.custom.value">
                    <mat-label>{{'FROM' | translate | async}}</mat-label>
                    <eaw-date-time-input formControlName="from" [required]="fromRequired"></eaw-date-time-input>
                </mat-form-field>

                <mat-form-field eawDateTimeTo [class.tw-hidden]="!accessForm.temp.value">
                    <mat-label>{{'TO' | translate | async}}</mat-label>
                    <eaw-date-time-input formControlName="to" [required]="toRequired"></eaw-date-time-input>
                </mat-form-field>
            </eaw-date-time-range-input>
        </ng-container>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">{{'CANCEL' | translate | async}}</button>
    <button mat-button
            mat-raised-button
            (click)="submit()"
            [disabled]="disableSubmit()"
            color="primary">
        {{'CONNECT' | translate | async}}
    </button>
</mat-dialog-actions>
