import { ChangeDetectionStrategy, Component, ElementRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { ShiftService } from '../../http/shift.service';
import { BasicDataSource } from '../../../shared/utils/basic-data-source';
import { Shift } from '../../models/shift';
import { AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MatIcon } from '@angular/material/icon';
import { DateTime } from 'luxon';
import { ShiftPeriodListDialogComponent, ShiftPeriodListDialogData } from '../shift-period-list-dialog/shift-period-list-dialog.component';

export interface ShiftListDialogData extends DialogData {
    title?: WritableSignal<Promise<string>>
    subtitle?: WritableSignal<Promise<string>>
    customerId: number;
    from?: DateTime;
    to?: DateTime;
}

@Component({
    selector: 'eaw-shift-list-dialog',
    standalone: true,
    imports: [
        AsyncPipe,
        DialogHeaderComponent,
        TranslatePipe,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        CdkVirtualScrollViewport,
        MatButton,
        MatDialogActions,
        MatDialogClose,
        MatDialogContent,
        MatProgressSpinner,
        DateTimePipe,
        MatIcon,
        MatIconButton,
    ],
    templateUrl: './shift-list-dialog.component.html',
    styleUrl: './shift-list-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftListDialogComponent extends DialogComponent<ShiftListDialogData, undefined, ShiftListDialogComponent> implements OnInit {
    private readonly shiftService = inject(ShiftService);
    private readonly elementRef = inject(ElementRef);
    private readonly matDialog = inject(MatDialog);

    private readonly perPage = signal(16);
    protected readonly itemSize = signal(70);
    protected dataSource: WritableSignal<BasicDataSource<Shift> | undefined> = signal(undefined);

    constructor() {
        super(undefined, undefined, DialogSize.Medium);
    }

    ngOnInit() {
        (this.elementRef.nativeElement as HTMLElement).style.setProperty('--item-size', `${this.itemSize()}px`);

        const options: Parameters<ShiftService['getAll']>[1] = {
            per_page: this.perPage(),
            'with[]': [ 'employee' ],
            'count[]': [ 'periods' ],
            from: this.data.from,
            to: this.data.to,
        };

        this.shiftService.getAll(this.data.customerId, options).subscribe((res) => {
            const source = new BasicDataSource(res.total, this.perPage(), res.data, (page) => {
                return this.shiftService.getAll(this.data.customerId, {
                    ...options,
                    page,
                });
            });

            this.dataSource.set(source);
        });
    }

    openPeriods(shift: Shift) {
        this.matDialog.open<ShiftPeriodListDialogComponent, ShiftPeriodListDialogData>(ShiftPeriodListDialogComponent, {
            data: {
                customerId: this.data.customerId,
                scheduleId: shift.scheduleId,
                shiftId: shift.id,
            },
        });
    }
}
