import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

const template1 = `<h2>{{$ctrl.data.days}} / {{$ctrl.data.received}}</h2>
<span ng-i18next="widgets:VAC_DAY_SUBTITLE"></span>
`;

module('eaw.dashboard').component('vacationDaysMiniWidget', {
    template: template1,
    require: {
        widget: '^eawMiniWidget',
    },
    controllerAs: '$ctrl',
    controller: [ 'vacationDays', function(vacationDays) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.year = new Date().getFullYear();

            ctrl.statsRes = vacationDays.getEmployeeStats({
                employee: { id: CurrentOld.getEmployee()?.id },
                customer: { id: ctrl.widget.widget.customer },
                year: ctrl.year,
            });

            ctrl.statsRes.$promise.then((response: any) => {
                ctrl.data = {
                    days: response.vacation_days,
                    deducted: response.vacation_days_deducted,
                    received: response.vacation_days_received,
                    carriedOver: response.vacation_days_carried_over,
                };
            }).finally(() => {
                ctrl.widget.loading(false);
            });
        };

        ctrl.$onDestroy = () => {
            ctrl.statsRes?.$cancelRequest?.();
        };
    } ],
});
