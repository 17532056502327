// @ts-nocheck
import { kebabCase } from 'lodash-es';
import { debounce } from 'lodash-es';
import { module } from 'angular';
export default class ComponentCreator {
    $compile;
    $rootScope;
    static get $inject() {
        return [ '$compile', '$rootScope' ];
    }

    components = {};
    observer;
    constructor($compile, $rootScope) {
        this.$compile = $compile;
        this.$rootScope = $rootScope;
        this.observer = new MutationObserver(debounce(this.cleanupScopes, 1000));
        this.observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    }

    cleanupScopes() {
        Object.values(this.components || {}).forEach(([ el, scope ]) => {
            if (!document.body.contains(el)) {
                scope.$destroy();
                delete this.components[scope.$id];
            }
        });
    }

    generateBindings(bindings = {}, params, scope) {
        return Object.entries(bindings).reduce((result, [ key, val ]) => {
            if (!val) {
                return result;
            }
            // @ts-ignore
            scope[key] = val; // Set each binding in scope
            if (Array.isArray(val)) {
                const [ keyVal, keyType ] = val;
                if (keyType === 'string' || keyType === 'upgrade') {
                    scope[key] = keyVal;
                    if (keyVal == null) {
                        return result;
                    }
                    if (keyType === 'string') {
                        return result += `${kebabCase(key)}="${keyVal}" `;
                    }
                    if (keyType === 'upgrade') {
                        return result += `[${kebabCase(key)}]="${key}" `;
                    }
                }
            } else if (typeof val === 'function' && params?.[key]) {
                let v = key;
                if (typeof val === 'function' && params?.[key]) {
                    // Create function-parameter string
                    v += '(';
                    params[key].forEach((param, i) => {
                        v += i == 0 ? `${param}` : `, ${param}`;
                    });
                    v += ')';
                }
                return result += `${kebabCase(key)}="${v}" `;
            }
            return result += `${kebabCase(key)}="${key}" `;
        }, '').trim();
    }

    /**
     * Create a component, component needs to have a templateUrl with a real template
     */
    create(args) {
        if (args == null || typeof args !== 'object') {
            throw new Error(`Couldn't create component, no arguments`);
        }
        if (!args.name?.length) {
            throw new Error(`Couldn't create component, missing name`);
        }
        args.scope = args.scope || this.$rootScope.$new();
        args.innerHTML = args.innerHTML || '';
        const tag = kebabCase(args.name);
        const componentScope = args.keepScope ? args.scope : args.scope.$new(true, args.scope);
        const componentBindings = this.generateBindings(args.bindings, args.params, componentScope);
        let transclude = null;
        if (args.transcludeControllers) {
            transclude = {
                transcludeControllers: args.transcludeControllers,
            };
        }
        // @ts-ignore
        const compiledComponent = this.$compile(`<${tag} ${componentBindings}>${args.innerHTML}</${tag}>`);
        // @ts-ignore
        const componentElement = compiledComponent(componentScope, undefined, transclude)[0];
        // Save element and scope so we can clean it up
        this.components[componentScope.$id] = [ componentElement, componentScope ];
        return {
            element: componentElement,
            scope: componentScope,
            bindings: componentBindings,
        };
    }
}
module('eaw.components').service('componentCreatorService', ComponentCreator);
