import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, input, OnInit, signal } from '@angular/core';
import { TranslateService } from '../../services/translate.service';
import { FormControl } from '@angular/forms';
import { Namespace, NamespaceFile } from '../../enums/namespace';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'eaw-field-error',
    standalone: true,
    imports: [
        AsyncPipe,
    ],
    templateUrl: './field-error.component.html',
    styleUrl: './field-error.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldErrorComponent implements OnInit {
    elementRef = inject(ElementRef<HTMLElement>);
    translateService = inject(TranslateService);
    changeDetectorRef = inject(ChangeDetectorRef);

    control = input.required<FormControl>();

    text = signal(Promise.resolve(''));

    ngOnInit() {
        this.control().events.subscribe(this.handleStatus.bind(this));
        this.control().statusChanges.subscribe(this.handleStatus.bind(this));
    }

    private handleStatus() {
        if (this.control().touched && this.control().status === 'INVALID') {
            const [ key, data ] = Object.entries(this.control().errors || {})[0] || [];
            const dataValue = typeof data === 'object' && data !== null ? data as Record<string, any> : {};

            switch (key) {
                case 'required':
                    void this.setText('REQUIRED');
                    break;
                case 'min':
                    void this.setText('MIN_VAL', Namespace.Errors, { number: dataValue['min'] });
                    break;
                case 'max':
                    void this.setText('MAX_VAL', Namespace.Errors, { number: dataValue['max'] });
                    break;
                case 'minlength':
                    void this.setText('MIN_LENGTH_CHARS', Namespace.General, { count: dataValue['requiredLength'] });
                    break;
                case 'maxlength':
                    void this.setText('MAX_LENGTH_CHARS', Namespace.General, { count: dataValue['requiredLength'] });
                    break;
                case 'email':
                    void this.setText('FORM_INVALID_EMAIL');
                    break;
                case 'integer':
                    void this.setText('NOT_INTEGER');
                    break;
                case 'badgeNumberExists':
                    void this.setText('NUMBER_EXISTS');
                    break;
                case 'INVALID_COUNTRY':
                    void this.setText('PHONE_INVALID_COUNTRY');
                    break;
                case 'INVALID_NUMBER':
                case 'NOT_A_NUMBER':
                    void this.setText('PHONE_INVALID_NUMBER');
                    break;
                case 'TOO_SHORT':
                    void this.setText('PHONE_NUMBER_TOO_SHORT');
                    break;
                case 'TOO_LONG':
                    void this.setText('PHONE_NUMBER_TOO_LONG');
                    break;
                case 'INVALID_LENGTH':
                    void this.setText('PHONE_INVALID_LENGTH');
                    break;
                case 'missingDial':
                    void this.setText('CHOOSE_PHONE_COUNTRY_CODE');
                    break;
            }
        } else {
            this.text.set(Promise.resolve(''));
        }
    }

    private async setText(key: string, ns: NamespaceFile = Namespace.Errors, data?: Record<string, any>) {
        this.text.set(this.translateService.t(key, ns, data));
    }
}
