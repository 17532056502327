<span *ngIf="!isSpecialCase">{{value}}</span>

<ng-container *ngIf="isSpecialCase">
    <mat-icon *ngIf="column.key === 'approved_count' && value" [style.color]="'green-500' | materialColor | async">check</mat-icon>
    <mat-icon *ngIf="column.key === 'approved_count' && !value" [style.color]="'orange-500' | materialColor | async">remove</mat-icon>

    <span *ngIf="column.key === 'out'" [class.punched-in]="!value">
        {{value || ('PUNCHED_IN' | translate:'payroll' | async)}}
    </span>

    <button *ngIf="column.key === 'infractions' && value"
            mat-icon-button
            [matTooltip]="('INFRACTION_plural' | translate | async) || ''"
            (click)="buttonClick.emit({row: row, column: column})">
        <mat-icon [matBadge]="$any(value)" matBadgeColor="warn" [style.color]="'orange-500' | materialColor | async">warning</mat-icon>
    </button>

    <button *ngIf="column.key === 'warnings_count' && value"
            mat-icon-button
            [matTooltip]="('WARNING_plural' | translate | async) || ''"
            (click)="buttonClick.emit({row: row, column: column})">
        <mat-icon [matBadge]="$any(value)" matBadgeColor="warn" [style.color]="'orange-500' | materialColor | async">warning</mat-icon>
    </button>

    <button *ngIf="column.key === 'comments_count' && value"
            mat-icon-button
            [matTooltip]="('COMMENT_plural' | translate | async) || ''"
            (click)="buttonClick.emit({row: row, column: column})">
        <mat-icon [matBadge]="$any(value)" matBadgeColor="warn">comment</mat-icon>
    </button>
</ng-container>
