import { Component, Inject, Input } from '@angular/core';
import { EmployeeRotationService } from '../shared/http/employee-rotation.service';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../data-table/types/data-table';
import { EmployeeRotation } from '../shared/models/employee-rotation';
import { of } from 'rxjs';
import { DataTableTextColumn } from '../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../data-table/types/data-table-header';
import { DataTableDateTimeColumn } from '../../data-table/types/data-table-date-time-column';
import { DataTableButtonCell, DataTableButtonColumn } from '../../data-table/types/data-table-button-column';
import { CurrentService } from '../../shared/services/current.service';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeRotationDialogComponent, EmployeeRotationDialogData } from '../shared/dialogs/employee-rotation-dialog/employee-rotation-dialog.component';
import { DateTime } from 'luxon';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { ConfirmDialogService } from '../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { HeaderFabButton, PageHeaderComponent } from '../../shared/components/page-header/page-header.component';
import { DataTableColumnType } from '../../data-table/interfaces/data-table-columns';
import { PermissionCheckService } from '../../shared/services/permission-check.service';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { DataTableComponent } from '../../data-table/data-table.component';
import { MatCardModule } from '@angular/material/card';
import { mockArrayPaginatedResponse } from '../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-employee-rotation-list',
    templateUrl: './employee-rotation-list.component.html',
    styleUrl: './employee-rotation-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmployeeRotationListComponent implements EawDataTable {
    @Input() customerId!: number;
    @Input() employeeId!: number;

    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    fabButton: HeaderFabButton = {
        click: this.create.bind(this),
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.rotations.create`),
    };

    columns: DataTableColumnType<EmployeeRotation>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item.rotation?.name || '',
            header: new DataTableHeader({
                i18n: 'NAME',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            header: new DataTableHeader({
                i18n: 'FROM',
                sortBy: 'from',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            header: new DataTableHeader({
                i18n: 'TO',
                sortBy: 'to',
            }),
        }),
        new DataTableButtonColumn<EmployeeRotation>({
            buttons: [
                {
                    icon: 'edit',
                    click: this.edit.bind(this),
                    show: this.canEdit.bind(this),
                    tooltip: { key: 'EDIT' },
                },
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.delete.bind(this),
                    show: this.canDelete.bind(this),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    protected pagination?: Partial<DataTablePagination>;

    constructor(
        @Inject(EmployeeRotationService) protected employeeRotationService: EmployeeRotationService,
        @Inject(CurrentService) protected current: CurrentService,
        @Inject(MatDialog) protected dialog: MatDialog,
        @Inject(SnackBarService) protected snackBarService: SnackBarService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(ConfirmDialogService) protected confirmDialogService: ConfirmDialogService,
    ) {
    }

    canDelete(rotation: EmployeeRotation) {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.rotations.${rotation.rotationId}.delete`);
    }

    canEdit(rotation: EmployeeRotation) {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.rotations.${rotation.rotationId}.update`);
    }

    canCreate() {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.rotations.create`);
    }

    create() {
        this.dialog.open<EmployeeRotationDialogComponent, EmployeeRotationDialogData, EmployeeRotation>(EmployeeRotationDialogComponent, {
            data: {
                employeeId: this.employeeId,
                customerId: this.customerId,
                rotation: new EmployeeRotation({
                    employee_id: this.employeeId,
                    from: DateTime.now().startOf('day').toFormat('yyyy-MM-dd HH:mm:ss'),
                    id: 0,
                    rotation_id: 0,
                }),
            },
        })
            .afterClosed()
            .subscribe((rotation?: EmployeeRotation) => {
                if (!rotation) {
                    return;
                }

                this.reload();
            });
    }

    edit(cell: DataTableButtonCell<EmployeeRotation>) {
        this.dialog.open<EmployeeRotationDialogComponent, EmployeeRotationDialogData, EmployeeRotation>(EmployeeRotationDialogComponent, {
            data: {
                employeeId: this.employeeId,
                customerId: this.customerId,
                rotation: cell.item,
            },
        })
            .afterClosed()
            .subscribe((rotation?: EmployeeRotation) => {
                cell.disabled.set(false);

                if (!rotation) {
                    return;
                }

                this.reload();
            });
    }

    delete(cell: DataTableButtonCell<EmployeeRotation>) {
        this.confirmDialogService.delete().afterClosed().subscribe((result) => {
            cell.disabled.set(false);

            if (!result) {
                return;
            }

            this.employeeRotationService.delete(this.customerId, cell.item.employeeId, cell.item.id).subscribe(() => {
                this.reload();
            });
        });
    }

    reload() {
        this.updateTable({
            per_page: this.pagination?.per_page,
            direction: this.pagination?.direction,
            order_by: this.pagination?.order_by ?? 'from',
            page: 1,
        });
    }

    updateTable($event: Partial<DataTablePagination>) {
        this.pagination = $event;
        this.request = this.employeeRotationService.getAll(this.customerId, this.employeeId, {
            pagination: {
                'with[]': [ 'rotation.intervals' ],
                direction: $event.direction,
                order_by: $event.order_by,
                per_page: $event.per_page,
                page: $event.page,
            },
        });
    }
}
