// @ts-nocheck
import { module } from 'angular';
import { PaginationOld } from '../../../shared/utils/pagination-old';
module('eaw.budgeting').factory('BudgetFactory', [ 'EawResource', function(EawResource) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/schedules/:schedule/budgets/:budget');
    fac.getAll = {
        permission(customer, schedule) {
            return `customers.${customer}.schedules.${schedule}.budgets.*.get`;
        },
        query(args) {
            return res.get({
                customer: args.customer,
                schedule: args.schedule,
                ...PaginationOld.getParams(args),
            });
        },
    };
    fac.get = {
        permission(customer, schedule, budget) {
            return `customers.${customer}.schedules.${schedule}.budgets.${budget}.get`;
        },
        query(customer, schedule, budget) {
            return res.get({
                customer,
                schedule,
                budget,
            });
        },
    };
    // Rename to add
    fac.addBudget = (budgetIndex, budgetAmount, schedule, customer) => res.save({
        customer: customer.id,
        schedule: schedule.id,
    }, {
        index: budgetIndex,
        budget: budgetAmount,
    });
    // Rename to update
    fac.updateBudget = (budget, schedule, customer) => {
        if (budget.budget == null || budget.budget === '') {
            budget.budget = 0;
        }
        return res.update({
            customer: customer.id,
            schedule: schedule.id,
            budget: budget.id,
        }, {
            budget: budget.budget,
        });
    };
    // Rename to delete
    fac.deleteBudget = (budget, schedule, customer) => res.delete({
        customer: customer.id,
        schedule: schedule.id,
        budget: budget.id,
    });
    // Rename to deleteAll
    fac.deleteAllBudgets = (schedule, customer) => res.delete({
        customer: customer.id,
        schedule: schedule.id,
    });
    return fac;
} ]);
