import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Info, Settings } from 'luxon';
import { NumberPipe } from '../../pipes/number.pipe';
import { ContractHourDayResponse } from '../../../company/models/contract-hour-day';
import { HourDistribution } from '../../../company/models/hour-distribution';
import { SettingService } from '../../http/setting.service';
import { CurrentService } from '../../services/current.service';
import { PredefinedSettings } from '../../enums/predefined-settings';

type WeekStart = 'mon' | 'sun';

@Component({
    selector: 'eaw-hour-distribution-display',
    standalone: true,
    imports: [ CommonModule, NumberPipe ],
    templateUrl: './hour-distribution-display.component.html',
    styleUrl: './hour-distribution-display.component.scss',
})
export class HourDistributionDisplayComponent implements OnInit {
    protected readonly weekdays = Info.weekdays('long', { locale: Settings.defaultLocale });
    protected hours: number[] = [];
    private readonly settingService = inject(SettingService);
    private readonly currentService = inject(CurrentService);

    @Input({ required: true }) data!: HourDistribution | ContractHourDayResponse[] | number[];

    ngOnInit() {
        if (this.data instanceof HourDistribution) {
            const hoursDistribution = this.data;
            this.hours = Array.from({ length: hoursDistribution.days }, (_, index) => hoursDistribution.contractHoursDays?.find((day) => day.index === index)?.hours ?? 0);
        } else {
            this.hours = this.data.map((day) => typeof day === 'number' ? day : day.hours);
        }

        const customerId = this.currentService.getCustomer().id;
        this.settingService.getSome([ 'customers', customerId ], { 'settings[]': [ PredefinedSettings.SchedulingWeekStart ] })
            .subscribe((settings) => {
                const firstDay: WeekStart = 'mon';
                const weekStart = settings.find((setting) => setting.key === PredefinedSettings.SchedulingWeekStart)?.value?.asString<WeekStart>() || firstDay;
                if (weekStart === 'sun') {
                    const sunday = this.weekdays.pop();
                    if (sunday) {
                        this.weekdays.unshift(sunday);
                    } else {
                        throw new Error('Could not set sunday as week start');
                    }
                }
            });
    }
}
