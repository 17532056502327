import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { IdleService } from '../../services/idle.service';
import { CurrentService } from '../../../shared/services/current.service';
import { environment } from 'src/environments/environment';
import { UIRouter } from '@uirouter/core';
import { LoginService } from '../../../shared/services/login.service';
import { AppService } from '../../../shared/services/app.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ChangeLanguageDialogComponent, ChangeLanguageDialogData } from '../../../shared/dialogs/change-language-dialog/change-language-dialog.component';
import { EawValidators } from '../../../shared/validators/eaw-validators';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InfoBoxComponent } from '../../../shared/components/info-card/info-box.component';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, AsyncPipe } from '@angular/common';
import { LogoComponent } from '../../../shared/components/logo/logo.component';
import { MatCardModule } from '@angular/material/card';
import { LoginLanguageSelectComponent } from '../../components/login-language-select/login-language-select.component';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MicrosoftSsoDialogComponent } from '../../dialogs/microsoft-sso-dialog/microsoft-sso-dialog.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { NamespaceFile } from '../../../shared/enums/namespace';
import { FieldErrorComponent } from '../../../shared/components/field-error/field-error.component';

@Component({
    selector: 'eaw-login',
    templateUrl: './login.component.html',
    styleUrls: [ './login.component.scss', '../../../../styles/login.scss' ],
    standalone: true,
    imports: [
        LoginLanguageSelectComponent,
        MatCardModule,
        LogoComponent,
        NgIf,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MaterialColorDirective,
        InfoBoxComponent,
        MatCheckboxModule,
        CheckboxHelperDirective,
        ActionButtonComponent,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
        MatRippleModule,
        MatDividerModule,
        FieldErrorComponent,
    ],
})
export class LoginComponent implements OnInit {
    idleService = inject(IdleService);
    current = inject(CurrentService);
    loginService = inject(LoginService);
    matDialog = inject(MatDialog);
    uiRouter = inject(UIRouter);
    translateService = inject(TranslateService);
    appService = inject(AppService);

    environment = environment;
    errorCode?: string;
    errorMessage?: string;
    unknownError?: boolean;
    showPassword = false;
    publicControl = new FormControl(false, { nonNullable: true });
    loginForm = new FormGroup({
        email: new FormControl('', [ Validators.required, EawValidators.email() ]),
        password: new FormControl('', [ Validators.required ]),
    });

    ngOnInit() {
        this.loginWithToken();
        void this.showError();

        this.loginForm.controls.email.setValue(this.uiRouter.globals.params?.['email']);

        if (this.uiRouter.globals.params?.['wasLoggedOut']) {
            this.errorCode = 'logged_out';
        }

        // Handle any login errors
        this.loginService.onLoginError().subscribe(this.onError.bind(this));
    }

    async showError() {
        const error = new URLSearchParams(window.location.search).get('error');

        if (error) {
            const errorNs = error.split('.')[0];
            const errorKey = error.split('.')[1];

            this.errorMessage = await this.translateService.t(errorKey, errorNs as NamespaceFile);
        }
    }

    loginWithToken() {
        // We might receive a token from the api that has the highest priority
        const token = new URLSearchParams(window.location.search).get('token');

        if (token) {
            this.loginForm.disable();
            this.loginService.login({ token }).then(this.onLogin.bind(this));
        }
    }

    changeLanguage() {
        this.matDialog.open<ChangeLanguageDialogComponent, ChangeLanguageDialogData>(ChangeLanguageDialogComponent);
    }

    login(): void {
        const email = this.loginForm.controls.email.value?.trim();
        const password = this.loginForm.controls.password.value?.trim();

        if (!(email && password)) {
            return;
        }

        // Reset errors
        delete this.errorMessage;
        delete this.errorCode;
        delete this.unknownError;

        this.loginForm.disable();
        this.loginService.login({ email, password }).then(this.onLogin.bind(this));
    }

    protected onLogin() {
        this.idleService.setPublic(this.publicControl.value);
        this.appService.reload(location.origin);
    }

    protected onError(errorResponse: HttpErrorResponse) {
        this.loginForm.enable();

        // The error from the response can be anything, but it should contain an object with an error and a message
        const error = typeof errorResponse.error === 'object' ? errorResponse.error?.error : '';
        const message = typeof errorResponse.error === 'object' ? errorResponse.error?.message : '';

        if (typeof error === 'string') {
            if (error === 'access') {
                this.uiRouter.stateService.go('eaw/app/no_access');
            } else {
                this.errorCode = error;
            }
        } else if (message === 'string') {
            this.errorMessage = message;
        } else {
            this.unknownError = true;
        }
    }

    protected loginWithMicrosoft() {
        this.matDialog.open(MicrosoftSsoDialogComponent);
    }

    goToForgotPassword() {
        this.uiRouter.stateService.go('eaw/outside/forgot_password');
    }
}
