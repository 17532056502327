// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="'admin:ADD_USER' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <form id="editForm" role="form" autocomplete="off" name="editForm" class="md-dialog-content" ng-submit="audCtrl.add()">
            <md-autocomplete
                    required
                    md-selected-item="audCtrl.user"
                    md-search-text="searchText"
                    md-floating-label="{{'USER' | i18next}}"
                    md-items="user in audCtrl.getUsers(searchText)"
                    md-item-text="user.name"
                    md-clear-button="true"
                    md-delay="300"
                    md-menu-class="autocomplete-custom-template"
                    md-min-length="0">
                <md-item-template>
                    <span class="autocomplete-item-title" md-highlight-text="searchText" md-highlight-flags="gi">{{user.name}}</span>
                    <span class="autocomplete-item-metadata" md-highlight-text="searchText" md-highlight-flags="gi">{{user.email}}</span>
                </md-item-template>
                <md-not-found>
                    <span ng-i18next="NO_MATCH_FOUND"></span>
                </md-not-found>
            </md-autocomplete>

            <md-input-container class="error-spacer">
                <label ng-i18next="FROM"></label>
                <eaw-date-time-picker ng-model="audCtrl.from" required min-date="audCtrl.minDate"></eaw-date-time-picker>
            </md-input-container>

            <md-checkbox id="temp" ng-model="audCtrl.temporary">
                <span aria-label="temp" ng-i18next="admin:TEMPORARY_ACCESS"></span>
            </md-checkbox>

            <md-input-container class="error-spacer" ng-if="audCtrl.temporary">
                <label ng-i18next="TO"></label>
                <eaw-date-time-picker ng-model="audCtrl.to" required min-date="audCtrl.from"></eaw-date-time-picker>
            </md-input-container>
        </form>
    </md-dialog-content>
    <md-dialog-actions>
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button type="submit" form="editForm" class="md-accent" ng-disabled="editForm.$invalid" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.admin').service('addUserDialog', [ '$mdDialog', 'UserService', function($mdDialog, UserService) {
    const service = this;

    service.open = (customer, callback) => $mdDialog.show({
        template: template1,
        controllerAs: 'audCtrl',
        controller: function() {
            const ctrl = this;

            ctrl.$onInit = () => {
                ctrl.minDate = moment();
                ctrl.from = ctrl.minDate.clone();
            };

            ctrl.add = () => {
                callback(customer, ctrl.user, ctrl.from, ctrl.to).then($mdDialog.hide);
            };

            ctrl.getUsers = (filter) => UserService.getAll({
                id: customer.id,
                fields: [ 'first_name', 'last_name', 'email', 'name' ],
                filter,
            }).$promise.then((data) => data.data);

            ctrl.cancel = $mdDialog.cancel;
        },
    });

    return service;
} ]);
