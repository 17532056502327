<eaw-dialog-header>
    <span title>{{ 'CHOOSE_CUSTOMER' | translate: 'admin' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <eaw-autocomplete [formControl]="customerControl"
                      [options]="this.options"
                      [setter]="customerAutocompleteService.setter()"
                      [getter]="customerAutocompleteService.getter()">
    </eaw-autocomplete>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <button mat-raised-button color="accent" [disabled]="customerControl.invalid" (click)="close()">{{ 'ADD_CUSTOMER' | translate:'admin' | async }}</button>
</mat-dialog-actions>
