import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'paid-time-employee-upgraded',
    standalone: true
})
export class paidTimeEmployeeUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;
    @Input() employee!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('paidTimeEmployee', elementRef, injector);
    }
}

@Component({
    template: '<paid-time-employee-upgraded [customer]="customer" [employee]="employee"></paid-time-employee-upgraded>',
    standalone: true,
    imports: [paidTimeEmployeeUpgradedDirective],
})
export class paidTimeEmployeeUpgradedComponent {
    @Input() customer!: any;
    @Input() employee!: any;
}

@NgModule({
    imports: [paidTimeEmployeeUpgradedDirective,
        paidTimeEmployeeUpgradedComponent],
})
export class paidTimeEmployeeUpgradeModule {
}
