// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
module('eaw.fixedLabor').factory('FixedLabor', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const factory = this;
    const _resource = EawResource.create('/customers/:customer_id/fixed_labor_costs/:id');
    const _attributes = [ 'from', 'to', 'value', 'period', 'name' ];
    const _businessDates = [ 'from', 'to' ];
    /**
     * @memberOf FixedLabor
     * @type {{permission(int): string, query(int, {from: moment, to?: moment, value?: number, period?: string, name?: string}): *}}
     */
    factory.create = {
        /**
         *
         * @param {int} customerId
         * @returns {string}
         */
        permission(customerId) {
            return 'customers.' + customerId + '.fixed_labor_costs.create';
        },
        /**
         * @param {int} customerId
         * @param {Object} fixedCosts
         * @param {moment} fixedCosts.from
         * @param {moment} [fixedCosts.to=null]
         * @param {number} [fixedCosts.value]
         * @param {string} [fixedCosts.period] year, month, week, day
         * @param {string} [fixedCosts.name]
         * @returns {*}
         */
        query(customerId, fixedCosts) {
            return _resource.save({
                customer_id: customerId,
            }, {
                ...pick(fixedCosts, _attributes),
                _businessDates,
            });
        },
    };
    /**
     * @memberOf FixedLabor
     * @type {{permission(*, *): *, query(int, int): *}}
     */
    factory.get = {
        /**
         *
         * @param {int} customerId
         * @param {int} id
         * @returns {string}
         */
        permission(customerId, id) {
            return 'customers.' + customerId + '.fixed_labor_costs.' + id + '.get';
        },
        /**
         * @param {int} customerId
         * @param {int} id
         * @returns {*}
         */
        query(customerId, id) {
            return _resource.get({
                customer_id: customerId,
                id,
            });
        },
    };
    /**
     * @memberOf FixedLabor
     * @type {{permission(*): *, query(int, Object, Array=): *}}
     */
    factory.getAll = {
        /**
         *
         * @param {int} customerId
         * @returns {string}
         */
        permission(customerId) {
            return 'customers.' + customerId + '.fixed_labor_costs.*.get';
        },
        /**
         * @param {int} customerId
         * @param {Object} pagination
         * @param {array} [withRelations=undefined]
         *
         * @returns {*}
         */
        query(customerId, pagination, withRelations) {
            return _resource.get({
                customer_id: customerId,
                ...Pagination.getParams(pagination),
                'with[]': withRelations,
            });
        },
    };
    /**
     * @memberOf FixedLabor
     * @type {{permission(int, int): string, query(int, int, Object): *}}
     */
    factory.update = {
        /**
         *
         * @param {int} customerId
         * @param {int|string} id
         * @returns {string}
         */
        permission(customerId, id) {
            return 'customers.' + customerId + '.fixed_labor_costs.' + id + '.update';
        },
        /**
         * @param {int} customerId
         * @param {int} id
         * @param {Object} fixedCosts
         * @returns {*}
         */
        query(customerId, id, fixedCosts) {
            return _resource.update({
                customer_id: customerId,
                id,
            }, {
                ...pick(fixedCosts, _attributes),
                _businessDates,
            });
        },
    };
    /**
     * @memberOf FixedLabor
     * @type {{permission(int, int): string, query(*=, int|string): *}}
     */
    factory.delete = {
        /**
         * @param {int} customerId
         * @param {int|string} id
         * @returns {string}
         */
        permission(customerId, id) {
            return 'customers.' + customerId + '.fixed_labor_costs.' + id + '.update';
        },
        /**
         * * @param {int} customerId
         * @param {int} id
         * @returns {*}
         */
        query(customerId, id) {
            return _resource.delete({
                customer_id: customerId,
                id,
            });
        },
    };
    return factory;
} ]);
