<eaw-page-header *ngIf="!employee">
    <span title>{{ 'VACATION_DAYS' | translate:'vacation' | async }}</span>
</eaw-page-header>

<eaw-page-header *ngIf="employee">
    <span title>{{ ('VAC_DAYS_FOR_EMP' | translate:'vacation':{name: employee.name}) | async }}</span>
</eaw-page-header>

<div class="bar-container">
    <mat-form-field class="standalone">
        <mat-label>{{ 'VACATION_DAYS' | translate:'vacation' | async }}</mat-label>
        <mat-select [formControl]="yearControl" (selectionChange)="update()">
            <mat-option *ngFor="let year of availableYears" [value]="year">{{ year }}</mat-option>
        </mat-select>
    </mat-form-field>

    <div id="vacation-days-bar" class="mat-elevation-z2 tw-flex tw-justify-center tw-items-center">
        <span id="text" class="mat-headline-5">
            {{
                (carriedOverVacationDays === 0 ? 'CURR_VAC_DAYS' : 'CURR_VAC_DAYS_WITH_CARRY') | translate: 'vacation': {
                    current: vacationDays,
                    all: receivedVacationDays,
                    year: yearControl.value,
                    carried_over: carriedOverVacationDays
                } | async
            }}
        </span>
    </div>

    <div class="tw-flex tw-flex-row tw-gap-8">
        <button *ngIf="canCreateDays" mat-mini-fab eawMaterialColor="green-400" colorProperty="backgroundColor" (click)="assignDays('add')">
            <mat-icon>add</mat-icon>
        </button>

        <button *ngIf="canCreateDays" mat-mini-fab eawMaterialColor="red-400" colorProperty="backgroundColor" (click)="assignDays('deduct')">
            <mat-icon>remove</mat-icon>
        </button>
    </div>
</div>

<mat-card class="mat-compact-card mat-card-contained">
    <mat-card-content>
        <eaw-data-table
            #dataTableComponent
            [columns]="columns"
            [request]="request"
            (paginationChange)="update($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
