<eaw-dialog-header>
    <span title>{{'IMPORT_PAY_RATES_DIALOG_TITLE' | translate: 'chainops' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col">
    <mat-form-field eawDatePickerOptions>
        <mat-label>{{'DATE' | translate | async}}</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'FILE' | translate:'hr' | async}}</mat-label>
        <mat-icon matPrefix color="primary">attach_file</mat-icon>
        <eaw-file-selector [placeholder]="('CHOOSE_FILE' | translate | async) || ''"
                           [required]="true"
                           [disabled]="isSubscribing()"
                           (fileChange)="setFile($event)"
                           fileFormats=".xlsx"></eaw-file-selector>
        <span matTextSuffix *ngIf="file" [style.color]="'grey-500' | materialColor | async">{{file.size | fileSize}}</span>
        <mat-hint *ngIf="!preview && !dryRunSub">{{'PREVIEW_CHANGES' | translate: 'chainops' | async}}</mat-hint>
    </mat-form-field>

    <button mat-raised-button
            color="accent"
            [disabled]="date.invalid || !file || !!dryRunSub && !dryRunSub.closed"
            (click)="dryRun()">
        {{'PREVIEW' | translate | async}}
    </button>

    <div *ngIf="dryRunSub && !dryRunSub.closed" class="tw-flex tw-items-center tw-gap-8 tw-m-8">
        <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
        <span>{{'PROCESSING' | translate | async}}</span>
    </div>

    <table *ngIf="preview" mat-table [dataSource]="preview.employeePayRates">
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>

        <ng-template matNoDataRow>
            <tr>
                <td [colSpan]="columns.length" class="tw-text-center">{{'NO_DATA' | translate | async}}</td>
            </tr>
        </ng-template>

        <ng-container matColumnDef="employee">
            <th mat-header-cell *matHeaderCellDef>{{'EMPLOYEE' | translate | async}}</th>
            <td mat-cell *matCellDef="let element">
                {{element.employee.name}} ({{'NUMBER_WRAP' | translate: 'general': { x: element.employee.number } | async}})
            </td>
        </ng-container>

        <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef>{{'LOCATION' | translate | async}}</th>
            <td mat-cell *matCellDef="let element">
                {{element.customer.name}} ({{'NUMBER_WRAP' | translate: 'general': { x: element.customer.number } | async}})
            </td>
        </ng-container>

        <ng-container matColumnDef="rate">
            <th mat-header-cell *matHeaderCellDef>{{'RATE' | translate | async}}</th>
            <td mat-cell *matCellDef="let element">{{element.payRate.rate}}</td>
        </ng-container>

        <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef>{{'START_DATE' | translate | async}}</th>
            <td mat-cell *matCellDef="let element">{{element.payRate.from | DateTime: 'DATE_MED'}}</td>
        </ng-container>
    </table>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button matDialogClose>{{'CANCEL' | translate | async}}</button>
    <button mat-raised-button
            color="primary"
            [disabled]="isSubscribing() || !preview"
            (click)="confirm()">{{'UPDATE' | translate | async}}</button>
</mat-dialog-actions>
