import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AutoSchedulingResult, AutoSchedulingResultResponse } from '../classes/auto-scheduling-result';
import { HttpClient } from '@angular/common/http';

type GetAll = Map<number, AutoSchedulingResult[]>;

@Injectable({
    providedIn: 'root',
})
export class AutoScheduleResultService {
    constructor(@Inject(HttpClient) protected http: HttpClient) {}

    getAll(customerId: number, scheduleId: number): Observable<GetAll> {
        return this.http.get<AutoSchedulingResultResponse[]>(`/customers/${customerId}/schedules/${scheduleId}/unassigned_shifts_report`).pipe(map((results) => {
            return results.reduce((acc: Map<number, AutoSchedulingResult[]>, result: AutoSchedulingResultResponse) => {
                const res = new AutoSchedulingResult(result);
                let arr: AutoSchedulingResult[] | undefined = acc.get(result.shift_id);

                if (arr) {
                    arr.push(res);
                } else {
                    arr = [ res ];
                }

                acc.set(result.shift_id, arr);

                return acc;
            }, new Map<number, AutoSchedulingResult[]>);
        }));
    }

    getForShift(customerId: number, scheduleId: number, shiftId: number): Observable<AutoSchedulingResult[]> {
        return this.http.get<AutoSchedulingResultResponse[]>(`/customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/unassigned_report`).pipe(map((results) => {
            return results.map((attrs) => new AutoSchedulingResult(attrs));
        }));
    }
}
