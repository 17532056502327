import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { Newsletter, NewsletterResponse } from '../models/newsletter';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';

interface GetAllOptions {
    has_issues?: boolean;
    only_latest?: boolean;
    only_published?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class NewsletterService {
    private http = inject(HttpClient);

    get(customerId: number, newsletterId: number) {
        return this.http.get<NewsletterResponse>(`/customers/${customerId}/newsletters/${newsletterId}`).pipe(classifyItem(Newsletter));
    }

    getAll(customerId: number, options?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<NewsletterResponse>>(`/customers/${customerId}/newsletters`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(Newsletter));
    }
}
