// @ts-nocheck
import { module } from 'angular';
import { from } from 'rxjs';
module('eaw.scheduling.shifts').factory('shiftCommentFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const resource = EawResource.create('/customers/:customer/schedules/:schedule/shifts/:shift/comments/:comment');
    return {
        getAll: {
            query(customerId, scheduleId, shiftId, options) {
                return resource.get({
                    customer: customerId,
                    schedule: scheduleId,
                    shift: shiftId,
                    ...Pagination.getParams(options),
                });
            },
            permission(customer, schedule, shift) {
                return `customers.${customer}.schedules.${schedule}.shifts.${shift}.get`;
            },
        },
        create: {
            query(customerId, scheduleId, shiftId, comment) {
                return resource.save({
                    customer: customerId,
                    schedule: scheduleId,
                    shift: shiftId,
                }, { body: comment });
            },
            permission(customer, schedule, shift) {
                return `customers.${customer}.schedules.${schedule}.shifts.${shift}.update`;
            },
        },
        delete: {
            query(customerId, scheduleId, shiftId, commentId) {
                return from(resource.delete({
                    customer: customerId,
                    schedule: scheduleId,
                    shift: shiftId,
                    comment: commentId,
                }).$promise);
            },
            permission(customer, schedule, shift) {
                return `customers.${customer}.schedules.${schedule}.shifts.${shift}.update`;
            },
        },
    };
} ]);
