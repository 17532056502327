import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ShiftSwapService } from '../../http/shift-swap.service';
import { DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { of } from 'rxjs';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { ShiftSwap } from '../../models/shift-swap';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DateTime } from 'luxon';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableApprovalColumn } from '../../../data-table/types/data-table-approval-column';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

type Mode = { value: boolean | undefined, text: Promise<string> }
type Swap = { value: 'from' | 'to' | 'all', text: Promise<string> }

@Component({
    selector: 'eaw-employee-shift-swaps',
    templateUrl: './employee-shift-swaps.component.html',
    styleUrl: './employee-shift-swaps.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatButtonModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmployeeShiftSwapsComponent implements OnInit, EawDataTable {
    @ViewChild(DataTableComponent) table?: DataTableComponent<ShiftSwap>;

    @Input() customerId!: number;
    @Input() employeeId!: number;

    swaps: Swap[] = [
        {
            value: 'from',
            text: this.translate.t('ONLY_SWAPS_FROM_ME', 'scheduling'),
        },
        {
            value: 'to',
            text: this.translate.t('ONLY_SWAPS_TO_ME', 'scheduling'),
        },
        {
            value: 'all',
            text: this.translate.t('ALL_SWAPS', 'scheduling'),
        },
    ];

    modes: Mode[] = [
        {
            value: true,
            text: this.translate.t('APPROVED'),
        },
        {
            value: false,
            text: this.translate.t('DECLINED'),
        },
        {
            value: undefined,
            text: this.translate.t('ALL'),
        },
    ];

    formGroup = new FormGroup({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mode: new FormControl<Mode>(this.modes[0]!, { nonNullable: true }),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        swap: new FormControl<Swap>(this.swaps[2]!, { nonNullable: true }),
        dateRange: new FormGroup({
            from: new FormControl<DateTime>(DateTime.now().minus({ week: 2 }).startOf('week')),
            to: new FormControl<DateTime>(DateTime.now().plus({ month: 1 })),
        }),
    });

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<ShiftSwap>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item.shift?.schedule?.customer?.name || '',
            header: new DataTableHeader({ i18n: 'LOCATION' }),
        }),
        new DataTableTextColumn({
            value: this.swapText.bind(this),
            header: new DataTableHeader({
                i18n: 'SHIFT_SWAP',
                ns: 'scheduling',
            }),
        }),
        new DataTableDateTimeColumn({
            value: this.getShiftFrom,
            format: 'WW',
            header: new DataTableHeader({ i18n: 'WEEK' }),
        }),
        new DataTableDateTimeColumn({
            value: this.getShiftFrom,
            format: 'cccc',
            header: new DataTableHeader({ i18n: 'DAY' }),
        }),
        new DataTableDateTimeColumn({
            value: this.getShiftFrom,
            format: DateTime.DATE_MED,
            header: new DataTableHeader({ i18n: 'DATE' }),
        }),
        new DataTableDateTimeColumn({
            value: this.getShiftFrom,
            format: DateTime.TIME_SIMPLE,
            header: new DataTableHeader({ i18n: 'FROM' }),
        }),
        new DataTableDateTimeColumn({
            value: this.getShiftTo,
            format: DateTime.TIME_SIMPLE,
            header: new DataTableHeader({ i18n: 'TO' }),
        }),
        new DataTableApprovalColumn({
            approval: (cell) => cell.item.approval,
            header: new DataTableHeader({ i18n: 'APPROVAL' }),
        }),
    ];

    constructor(
        @Inject(ShiftSwapService) private shiftSwapService: ShiftSwapService,
        @Inject(TranslateService) private translate: TranslateService,
    ) {
    }

    ngOnInit(): void {
        if (!this.customerId) {
            throw new Error('Missing customer ID');
        }
        if (!this.employeeId) {
            throw new Error('Missing employee ID');
        }
    }

    swapText(cell: DataTableCell<DataTableTextColumn<ShiftSwap>, ShiftSwap>) {
        let text = '';
        let name = '';

        // From me
        if (cell.item.fromEmployee?.id === this.employeeId) {
            text = cell.item.toEmployee ? 'SWAP_ME_TO_EMP' : 'SWAP_ME_TO_UNASSIGNED';
            name = cell.item.toEmployee?.name || '';
        }

        // To me
        if (cell.item.toEmployee?.id === this.employeeId) {
            text = cell.item.fromEmployee ? 'SWAP_EMP_TO_ME' : 'SWAP_UNASSIGNED_TO_ME';
            name = cell.item.fromEmployee?.name || '';
        }

        return this.translate.t(text, 'scheduling', { name });
    }

    modeComparer(modeValue: Mode, selectedValue?: Mode) {
        return modeValue.value === selectedValue?.value;
    }

    getShiftFrom(cell: DataTableCell<DataTableDateTimeColumn<ShiftSwap>, ShiftSwap>) {
        return cell.item.shift?.from;
    }

    getShiftTo(cell: DataTableCell<DataTableDateTimeColumn<ShiftSwap>, ShiftSwap>) {
        return cell.item.shift?.to;
    }

    updateTable(): void {
        this.request = this.shiftSwapService.getAllForEmployee(this.customerId, this.employeeId, {
            from: this.formGroup.controls.dateRange.controls.from.value,
            to: this.formGroup.controls.dateRange.controls.to.value,
            'with[]': [ 'fromEmployee', 'toEmployee', 'shift.schedule.customer', 'approval' ],
            only_from: this.formGroup.controls.swap.value.value === 'from' ? true : undefined,
            only_to: this.formGroup.controls.swap.value.value === 'to' ? true : undefined,
            approved: this.formGroup.controls.mode.value.value,
        });
    }
}
