import { module } from 'angular';
import { Namespace } from '../../shared/enums/namespace';
import { AdminOrganizationComponent } from '../pages/admin-organization/admin-organization.component';
import { createNavTabsState, createState } from '../../shared/utils/create-state';
import { NavTabsComponent } from '../../shared/components/nav-tabs/nav-tabs.component';
import { navTabsTabsResolver } from '../../shared/resolvers/nav-tabs-tabs.resolver';
import { OrganizationMembersTabComponent } from '../pages/organization-members-tab/organization-members-tab.component';
import { MetricComponent } from '../../people-kpi/pages/metric/metric.component';
import { DataTableQueryParams } from '../../data-table/data-table.component';
import { StateProvider } from '@uirouter/angularjs';

module('eaw.admin', [
    'eaw.dialogs',
    'eaw.resource',
    'eaw.login',
    'eaw.customers',
    'eaw.digisign',
    'eaw.absence',
    'eaw.misc',
    'eaw.user-groups',
    'eaw.dashboard',
    'eaw.company',
]).config([ '$stateProvider', function($stateProvider: StateProvider) {
    createState($stateProvider, {
        name: 'eaw/app/admin',
        parent: 'eaw/app',
        url: '/admin',
        abstract: true,
        data: {
            breadcrumb: { key: 'ADMIN' },
            i18nextNs: [
                Namespace.Admin,
                Namespace.Widgets,
                Namespace.Reports,
                Namespace.Company,
                Namespace.Payroll,
            ],
            permissions: [ 'admin' ],
        },
    })

    createState($stateProvider, {
        name: `eaw/app/admin/organizations`,
        parent: 'eaw/app/admin',
        url: '/organizations',
        data: {
            queryParams: [ ...DataTableQueryParams, 'search' ],
            breadcrumb: { key: 'ORGANIZATIONS', ns: 'navigation' },
            permissions: [ `organizations.*.get` ],
        },
        views: {
            'content@': {
                component: AdminOrganizationComponent,
            },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/admin/organizations/info',
        parent: `eaw/app/admin/organizations`,
        url: '/:organizationId',
        params: {
            organizationId: { type: 'string' },
        },
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        resolve: [
            navTabsTabsResolver(function() {
                return Promise.resolve([
                    {
                        state: 'eaw/app/admin/organizations/info/members',
                        label: { key: 'MEMBER_plural' },
                    },
                    /* Hide this tab while we figure out what to do with the backend for this
                    {
                        state: 'eaw/app/admin/organizations/info/metrics',
                        label: { key: 'METRIC_plural', ns: 'people_kpi' },
                    }, */
                ]);
            }),
        ],
        data: {
            permissions: [ `organizations.{organization_id}.get` ],
            breadcrumb: { key: 'OPTION_plural' },
        },
    });

    createNavTabsState($stateProvider, {
        parent: `eaw/app/admin/organizations/info`,
        name: `eaw/app/admin/organizations/info/members`,
        url: '/members',
        component: OrganizationMembersTabComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'MEMBER_plural' },
            permissions: [ `organizations.{organization_id}.members.get` ],
        },
    });

    createNavTabsState($stateProvider, {
        parent: `eaw/app/admin/organizations/info`,
        name: `eaw/app/admin/organizations/info/metrics`,
        url: '/metrics',
        component: MetricComponent,
        data: {
            breadcrumb: { key: 'METRIC_plural', ns: 'people_kpi' },
            permissions: [ `organizations.{organization}.pkpi_metrics.*.get` ],
        },
    });
} ]);
