import { Component, Inject, OnInit } from '@angular/core';
import { CurrentService } from '../../../shared/services/current.service';
import { UserService } from '../../../shared/http/user.service';
import { User } from '../../../shared/models/user';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { catchError, EMPTY, map } from 'rxjs';
import { Language } from '../../../admin/models/language';
import { LanguageService } from '../../../admin/http/language.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { Employee } from '../../../shared/models/employee';
import { MeCustomer } from '../../../shared/models/me';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { DateTime } from 'luxon';
import { EmployeeService } from '../../../shared/http/employee.service';
import { Cache } from '../../../shared/utils/cache';
import { AppService } from '../../../shared/services/app.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ProfileInfoEmployeeComponent } from '../../shared/components/profile-info-employee/profile-info-employee.component';
import { MatButtonModule } from '@angular/material/button';
import { BasicAutocompleteComponent } from '../../../shared/components/basic-autocomplete/basic-autocomplete.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { DialPhone, DialPhoneInputComponent } from '../../../shared/components/dial-phone-input/dial-phone-input.component';
import { FieldErrorComponent } from '../../../shared/components/field-error/field-error.component';

interface ProfileCustomer {
    customer: MeCustomer;
    employeeNumber?: Promise<string>;
}

@Component({
    selector: 'eaw-profile-info',
    templateUrl: './profile-info.component.html',
    styleUrl: './profile-info.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatProgressSpinnerModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        BasicAutocompleteComponent,
        MatButtonModule,
        NgFor,
        ProfileInfoEmployeeComponent,
        AsyncPipe,
        TranslatePipe,
        DialPhoneInputComponent,
        JsonPipe,
        FieldErrorComponent,
    ],
})
export class ProfileInfoComponent implements OnInit {
    user?: User;
    employees: Employee[] = [];
    customers: ProfileCustomer[] = sort(
        Object.values(this.current.getCustomers()),
        this.current.languageTag,
        [ (c) => c.name ],
        [ 'asc' ],
    ).map((customer) => ({ customer }));

    userForm = new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        email: new FormControl('', Validators.required),
        phone: new FormControl<DialPhone | null>(null),
        language: new FormControl<Language | null>(null),
    });

    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(UserService) private userService: UserService,
        @Inject(LanguageService) private languageService: LanguageService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(AppService) private appService: AppService,
    ) {
    }

    ngOnInit(): void {
        this.getUser();
        this.getNumbers();
    }

    getNumbers() {
        const userId = this.current.getUser().id;

        this.customers.forEach((customer) => {
            if (!customer.customer.employee_id) {
                customer.employeeNumber = this.translate.t('NO_EMPLOYEE');
                return;
            }

            customer.employeeNumber = this.translate.t('FETCHING_NUMBER', 'profile');

            this.employeeService.getAll(customer.customer.id, {
                user_id: userId,
                active: DateTime.now(),
                include_custom_fields: false,
                include_external: true,
                'fields[]': [ 'number' ],
            }).subscribe((res) => {
                customer.employeeNumber = Promise.resolve(`#${res.data[0]?.number}`);
            });
        });
    }

    getUser() {
        this.userService.get(this.current.getUser().id).subscribe((user) => {
            this.employees = user.employees || [];
            this.setUser(user);
        });
    }

    setUser(user: User) {
        this.user = user;

        this.userForm.setValue({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone || user.countryCode ? { phone: user.phone || '', dial: user.countryCode || '' } : null,
            language: null,
        });

        if (user.languageCode) {
            this.userForm.controls.language.disable();
            this.getLanguages(user.languageCode, true).subscribe((res) => {
                const lang = res.data.find((l) => l.code === user.languageCode);
                if (lang) {
                    this.userForm.controls.language.setValue(lang);
                }

                this.userForm.controls.language.enable();
            });
        }
    }

    updateUser() {
        const values = this.userForm.value;

        this.userService.update(this.current.getUser().id, {
            first_name: values.firstName || undefined,
            last_name: values.lastName || undefined,
            email: values.email || undefined,
            country_code: values.phone?.dial,
            phone: values.phone?.phone,
            language_code: this.userForm.controls.language.value?.code,
        }).pipe(
            catchError(() => {
                this.userForm.enable();
                return EMPTY;
            }),
        ).subscribe(this.onUserUpdate.bind(this));
        this.userForm.disable();
    }

    async onUserUpdate() {
        // Clear the cached me and customer me responses
        await Cache.clearAll();

        // Restart the app
        this.appService.reload();
    }

    getLanguages = (filter?: string, code = false) => {
        return this.languageService.getAll().pipe(
            map((res) => {
                const data = res.data.filter((x) => {
                    if (code) {
                        return x.code?.toLowerCase().includes(filter?.toLowerCase() || '');
                    } else {
                        return x.name.toLowerCase().includes(filter?.toLowerCase() || '');
                    }
                });

                return {
                    data,
                    from: 1,
                    to: 1,
                    total: data.length,
                    last_page: 1,
                    current_page: 1,
                    per_page: data.length,
                };
            }),
        );
    };
}
