import { Component, Inject, OnInit } from '@angular/core';
import { QualificationService } from '../../../shared/http/qualification.service';
import { forkJoin, of } from 'rxjs';
import { EmployeeService } from '../../../shared/http/employee.service';
import { CurrentService } from '../../../shared/services/current.service';
import { Qualification } from '../../../shared/models/qualification';
import { DateTime } from 'luxon';
import { TranslateService } from '../../../shared/services/translate.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { QuickGridComponent } from '../../../shared/components/quick-grid/quick-grid.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, AsyncPipe } from '@angular/common';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { MatDialog } from '@angular/material/dialog';
import { ItemSelectorDialogComponent, ItemSelectorDialogData, ItemSelectorDialogReturn } from '../../../shared/dialogs/item-selector-dialog/item-selector-dialog.component';

export type EmployeeWithQualifications = {
    id: number,
    name?: string | null,
    qualifications?: Qualification[],
    items?: Record<number, Qualification>
}

@Component({
    selector: 'eaw-qualification-grid',
    templateUrl: './qualification-grid.component.html',
    styleUrl: './qualification-grid.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinnerModule,
        QuickGridComponent,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class QualificationGridComponent implements OnInit {
    qualifications: Qualification[] = [];
    employees: EmployeeWithQualifications[] = [];
    loading = false;
    shownQualifications: Qualification[] = [];
    lastDayText?: string;

    constructor(
        @Inject(QualificationService) private service: QualificationService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(EmployeeService) protected employeeService: EmployeeService,
    ) {
    }

    async ngOnInit() {
        this.getItems();
        this.lastDayText = await this.translate.t('LAST_QUALIFIED_DAY', 'company');
    }

    getItems() {
        this.loading = true;
        forkJoin([
            expandAllPages((pagination) => this.service.getAll(this.current.getCustomer().id, { ...pagination }), { per_page: 100 }),
            expandAllPages((pagination) => this.employeeService.getAll(this.current.getCustomer().id, { 'with[]': [ 'qualifications' ], ...pagination }), { per_page: 100 }),
        ]).subscribe(([ allQualifications, allEmployees ]) => {
            this.qualifications = allQualifications;
            if (this.shownQualifications.length == 0) {
                this.shownQualifications = allQualifications;
            }
            this.employees = allEmployees;
            this.loading = false;
            this.setItems();
        });
    }

    setItems() {
        this.employees = this.employees.map((employee) => {

            employee.qualifications = employee.qualifications?.filter((q: Qualification) => {
                q.last_day = q.to ? q.to.hasSame(DateTime.now(), 'day') : false;
                return !q.to || q.to >= DateTime.now();
            });
            if (!employee.items) {
                employee.items = {};
            }
            employee.qualifications?.forEach((q) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                employee.items![q.id] = q;
            });
            return employee;
        });
    }

    async toggleQualification(event: { item: Qualification, person: EmployeeWithQualifications }) {
        const employee = event.person;
        const qualification = event.item;
        const q = employee.items ? employee.items[qualification.id] : null;
        if (q) {
            if (q.last_day) {
                return;
            }
            await this.service.deleteQualificationFromEmployee(this.current.getCustomer().id, employee.id, q.id).subscribe(() => {
                q.last_day = true;
            });
        } else {
            await this.service.addQualificationToEmployee(this.current.getCustomer().id, employee.id, {
                id: qualification.id,
                rate: 1,
                from: DateTime.now(),
                to: null,
            }).subscribe((q: Qualification) => {
                if (employee.items) {
                    employee.items[qualification.id] = q;
                } else {
                    employee.items = { [q.id]: q };
                }
            });
        }
    }

    filter() {
        this.matDialog.open<ItemSelectorDialogComponent<Qualification>, ItemSelectorDialogData<Qualification>, ItemSelectorDialogReturn<Qualification>>(ItemSelectorDialogComponent, {
            data: {
                items: of(Object.values(this.qualifications)),
                itemText: (r) => r.name,
                multiple: true,
                title: this.translate.t('QUALIFICATION_plural'),
            },
        }).afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }

            this.shownQualifications = [ res ].flat();
        });
    }
}
