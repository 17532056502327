@if (loadingData) {
    <eaw-info-loading size="sm">
        {{ 'LOADING_GROUP_INFO' | translate:'new_employee' | async }}
    </eaw-info-loading>
} @else {
    @if (step.optional) {
        @if (!maxGroups) {
            <eaw-info-box>
                <span infoText>{{ 'GROUP_DEFAULT_HINT' | translate:'new_employee' | async }}</span>
            </eaw-info-box>
        } @else if (maxGroups === 1) {
            <eaw-info-box>
                <span infoText>{{ 'GROUP_OPTIONAL_MAX_ONE' | translate:'new_employee' | async }}</span>
            </eaw-info-box>
        } @else {
            <eaw-info-box>
                <span infoText>{{ 'GROUP_OPTIONAL_MAX' | translate:'new_employee':{count: maxGroups} | async }}</span>
            </eaw-info-box>
        }
    } @else {
        @if (!maxGroups) {
            <eaw-info-box>
                <span infoText>{{ 'GROUP_REQUIRED' | translate:'new_employee' | async }}</span>
            </eaw-info-box>
        } @else if (maxGroups === 1) {
            <eaw-info-box>
                <span infoText>{{ 'GROUP_REQUIRED_MAX_ONE' | translate:'new_employee' | async }}</span>
            </eaw-info-box>
        } @else {
            <eaw-info-box>
                <span infoText>{{ 'GROUP_REQUIRED_MAX' | translate:'new_employee':{count: maxGroups} | async }}</span>
            </eaw-info-box>
        }
    }

    <mat-selection-list multiple >
        @for (group of userGroups;track group.id) {
            <mat-list-option [selected]="selectedGroups.has(group.id)" [disabled]="processingGroups.has(group.id)" (click)="toggleGroup(group)">{{ group.name }}</mat-list-option>
        }
    </mat-selection-list>
}

<eaw-new-employee-next-step [stepper]="stepper" [step]="step"/>
