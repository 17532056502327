// @ts-nocheck
import { module } from 'angular';

module('eaw.components').component('componentCreator', {
    bindings: {
        componentName: '@', // Name of component to create (Written in kebab-case or camel case)
        bindings: '<?', // Object
        params: '<?', // map with name of function => array of names of parameters, for &-bound functions
        transcludeControllers: '<?',
    },
    controller: [ '$element', '$scope', 'componentCreatorService', function($element, $scope, componentCreatorService) {
        const ctrl = this;

        ctrl.$postLink = () => {
            ctrl.linked = true;
            if (ctrl.shouldCreate()) {
                ctrl.createComponent();
            }
        };

        ctrl.removeComponent = () => {
            if (!ctrl.created) {
                return;
            }

            ctrl.component?.scope?.$destroy();
            ctrl.component?.element?.remove();
        };

        ctrl.createComponent = () => {
            // Don't do nothin' if we ain't linked
            if (!ctrl.linked) {
                return;
            }

            // Create component if we have component
            ctrl.component = componentCreatorService.create({
                name: ctrl.componentName,
                scope: $scope,
                bindings: ctrl.bindings,
                params: ctrl.params,
                transcludeControllers: ctrl.transcludeControllers,
            });

            $element[0].appendChild(ctrl.component.element);

            ctrl.created = true;
        };

        ctrl.shouldCreate = () => !ctrl.created && !!ctrl.componentName?.length;

        ctrl.$onChanges = (changes) => {
            if (ctrl.created) {
                if (changes.componentName) {
                    ctrl.removeComponent();
                    ctrl.created = false;
                }

                Object.entries(changes.bindings?.currentValue || {}).forEach((entry) => {
                    ctrl.component.scope[entry[0]] = entry[1];
                });
            }

            if (ctrl.shouldCreate()) {
                ctrl.createComponent();
            }
        };
    } ],
});
