<eaw-page-header>
    <span title>{{ 'ANALYTICS' | translate:'analytics' | async }}</span>
</eaw-page-header>

@if (loading) {
    <div class="loading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
} @else {
    <div id="items-container">
        @for (page of pages; track page.route) {
            <div class="item mat-elevation-z2" (click)="goToPage(page.route)" matRipple>
                <mat-icon>{{ page.icon }}</mat-icon>

                <div class="text">
                    <span class="mat-body-1">{{ page.title | async }}</span>
                    <small>{{ page.description | async }}</small>
                </div>
            </div>
        } @empty {
            @if (!loading) {
                <div class="empty">
                    <mat-icon>info</mat-icon>
                    <span class="mat-body-1">{{ 'NO_PAGES' | translate:'analytics' | async }}</span>
                </div>
            }
        }
    </div>
}
