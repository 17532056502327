// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
module('eaw.time').directive('eawSimpleTime', function eawSimpleTimeDirective() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link($scope, element, attrs, ngModel) {
            element[0].addEventListener('focus', (e) => {
                const target = e.target;
                target.select();
            });
            $scope.format = attrs.format || 'HH:mm';
            ngModel.$formatters.unshift((modelVal) => {
                if (!modelVal) {
                    return modelVal;
                }
                const val = moment(modelVal);
                if (moment.isMoment(modelVal)) {
                    return modelVal.format($scope.format);
                }
                if (val.isValid()) {
                    return val.format($scope.format);
                }
                return modelVal;
            });
            ngModel.$parsers.unshift((viewVal) => {
                const val = viewVal.length === 3 ? moment.tz(viewVal, 'Hmm', 'UTC') : moment.tz(viewVal, $scope.format, 'UTC');
                const valid = val.isValid();
                const value = valid ? val.format('HH:mm') : '';
                ngModel.$setValidity('moment', valid || !value);
                element[0].value = value;
                return valid ? value : null;
            });
        },
    };
});
