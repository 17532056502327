import { DateTime } from 'luxon';

interface NeedColumnData {
    employeeTransactionsPerHour: number;
    projections: number;
    contractHours: number;
    absences: number;
}

export class NeedColumn {
    label: string;
    projections: number;
    contractHours: number;
    absences: number;
    employeeTransactionsPerHour: number;
    estimatedNeed = 0;
    available = 0;
    diff = 0;

    constructor(date: DateTime, data: NeedColumnData) {
        this.label = date.toLocaleString(DateTime.DATE_MED);
        this.projections = Math.ceil(data.projections);
        this.contractHours = data.contractHours;
        this.absences = data.absences;
        this.employeeTransactionsPerHour = data.employeeTransactionsPerHour;

        this.calculate();
    }

    calculate() {
        this.estimatedNeed = this.projections / this.employeeTransactionsPerHour;
        this.available = this.contractHours - this.absences;
        this.diff = this.available - this.estimatedNeed;
    }

    setTransactions(value: number) {
        this.projections = value;
        this.calculate();
    }

    setEmployeeTransactions(value: number) {
        this.employeeTransactionsPerHour = value;
        this.calculate();
    }
}
