import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, inject, input, OnInit, output, viewChild } from '@angular/core';
import { PaidTimeSegment } from '../../../../models/paid-time-segment';
import { PercentPipe } from '../../../../../shared/pipes/percent.pipe';
import { DateTime } from 'luxon';
import { EawMaterialColorHue, MaterialColorService } from '../../../../../shared/services/material-color.service';
import { TranslateService } from '../../../../../shared/services/translate.service';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { HandlePaidTimeDialogComponent, HandlePaidTimeDialogData } from '../../../../dialogs/handle-paid-time-dialog/handle-paid-time-dialog.component';
import { ConfirmDialogService } from '../../../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { PaidTimeService } from '../../../../../payroll/http/paid-time.service';
import { PaidTime } from '../../../../models/paid-time';
import { CommentDialogComponent, CommentDialogData } from '../../../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { map, of, switchMap, tap } from 'rxjs';
import { TimepunchService } from '../../../../../payroll/http/timepunch.service';
import { CrateUpdateAbsenceDialogData, CreateUpdateAbsenceDialogComponent } from '../../../../../absence/dialogs/create-update-absence-dialog/create-update-absence-dialog.component';
import { Absence } from '../../../../../absence/models/absence';
import { AbsenceService } from '../../../../../absence/http/absence.service';
import { PaidTimeSegmentTypeKey } from '../../../../types/paid-time-segment-types';
import { ShiftService } from '../../../../../scheduling/http/shift.service';
import { AbsenceLeaveShiftService } from '../../../../../leave-shifts/http/absence-leave-shift.service';
import { EditLeaveShiftDialogComponent, EditLeaveShiftDialogComponentData } from '../../../../../leave-shifts/dialogs/edit-leave-shift-dialog/edit-leave-shift-dialog.component';
import { LeaveShift } from '../../../../../leave-shifts/models/leave-shift';
import { CustomField } from '../../../../../custom-fields/models/custom-field';
import { ModelCustomField } from '../../../../../custom-fields/models/model-custom-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Namespace } from '../../../../../shared/enums/namespace';

type MenuItem = {
    text: Promise<string>,
    action: () => void
} | null

@Component({
    selector: 'eaw-paid-time-overview-segment',
    templateUrl: './paid-time-overview-segment.component.html',
    styleUrl: './paid-time-overview-segment.component.scss',
    providers: [ PercentPipe ],
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatMenuModule,
        NgFor,
        AsyncPipe,
    ],
})
export class PaidTimeOverviewSegmentComponent implements OnInit, AfterViewInit {
    menuTrigger = viewChild(MatMenuTrigger);

    private readonly elementRef = inject(ElementRef);
    private readonly percentPipe = inject(PercentPipe);
    private readonly materialColorService = inject(MaterialColorService);
    private readonly translate = inject(TranslateService);
    private readonly snackBarService = inject(SnackBarService);
    private readonly matDialog = inject(MatDialog);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly paidTimeService = inject(PaidTimeService);
    private readonly timepunchService = inject(TimepunchService);
    private readonly absenceService = inject(AbsenceService);
    private readonly shiftService = inject(ShiftService);
    private readonly absenceLeaveShiftService = inject(AbsenceLeaveShiftService);

    @HostBinding('attr.data-type') get type(): string {
        return this.segment().type.key;
    }

    @HostBinding('class.different-business-date') get differentBusinessDate() {
        return this.segment().differentBusinessDate;
    }

    @HostListener('click') onClick() {
        this.click();
    }

    segmentUpdated = output<PaidTimeSegment>();
    segment = input.required<PaidTimeSegment>();
    /**
     * Flex basis custom field, used to display an additional tooltip
     */
    flexBasis?: Promise<string>;
    processing = false;
    title = '';
    subtitle = '';
    menu: MenuItem[] = [];

    ngOnInit() {
        void this.updateColor('background', this.segment().type.defaultColor);
        void this.handleCustomFields(this.segment().paidTime?.attachedCustomFields);

        this.createMenu();

        switch (this.segment().type.key) {
            case 'hourAbsence':
            case 'dayAbsence': {
                void this.handleAbsence();
                break;
            }
            default: {
                void this.handleDefault();
            }
        }
    }

    ngAfterViewInit() {
        this.menuTrigger()?.menuOpened.subscribe(() => void this.updateColor('background', this.segment().type.activeColor));
        this.menuTrigger()?.menuClosed.subscribe(() => void this.updateColor('background', this.segment().type.defaultColor));
    }

    handleCustomFields(fields?: ModelCustomField[]) {
        const flexBasis = fields?.find((cf) => cf.key === 'cf_flex_basis');
        if (flexBasis && (flexBasis.value === '1' || flexBasis.value === '2')) {
            void this.updateColor('border', 'blue-500');

            this.flexBasis = new Promise(async (resolve) => {
                const title = await this.translate.t(flexBasis.translationKey, CustomField.translationNs);
                const value = await this.translate.t(flexBasis.getSelectedOption()?.translationKey, Namespace.CustomFields);

                resolve(`${title}\n${value}`);
            });
        }
    }

    async handleDefault() {
        this.title = await this.translate.t(this.segment().type.name.key, this.segment().type.name.ns);

        switch (true) {
            case this.segment().type.key === 'suggestedPaidTime' && this.segment().isPunchedIn: {
                this.subtitle = `${this.segment().from.toLocaleString(DateTime.TIME_SIMPLE)} - ${await this.translate.t('NOW')}`;
                break;
            }
            case this.segment().type.key === 'unhandledTime' && this.segment().from >= this.segment().now: {
                this.subtitle = `${await this.translate.t('NOW')} - ${this.segment().to.toLocaleString(DateTime.TIME_SIMPLE)}`;
                break;
            }
            default: {
                this.subtitle = `${this.segment().from.toLocaleString(DateTime.TIME_SIMPLE)} - ${this.segment().to.toLocaleString(DateTime.TIME_SIMPLE)}`;
            }
        }
    }

    async handleAbsence() {
        const absence = this.segment().absence;
        if (!absence) {
            return;
        }

        this.setProperty('--absence-color', absence.type?.color?.toHexString() || 'transparent');
        this.title = absence.type?.name ? await this.translate.t(absence.type.name, Namespace.AbsenceTypes) : await this.translate.t('ABSENCE', Namespace.Absences);

        if (absence.type?.span === 'hour') {
            this.subtitle = `${this.segment().from.toLocaleString(DateTime.TIME_SIMPLE)} - ${this.segment().to.toLocaleString(DateTime.TIME_SIMPLE)}`;
        } else {
            this.subtitle = this.percentPipe.transform(absence.grade);
        }
    }

    createMenu() {
        const addAbsence = {
            text: this.translate.t('ADD_ABSENCE', Namespace.Absences),
            action: this.addAbsence.bind(this),
        };
        const updateAbsence = {
            text: this.translate.t('UPDATE_ABSENCE', Namespace.Absences),
            action: this.updateAbsence.bind(this),
        };
        const removeAbsence = {
            text: this.translate.t('REMOVE_ABSENCE', Namespace.Payroll),
            action: this.removeAbsence.bind(this),
        };
        const addPaidTime = {
            text: this.translate.t('ADD_PAID_TIME', Namespace.Payroll),
            action: this.addPaidTime.bind(this),
        };
        const updatePaidTime = {
            text: this.translate.t('UPDATE_PAID_TIME', Namespace.Payroll),
            action: this.updatePaidTime.bind(this),
        };
        const removePaidTime = {
            text: this.translate.t('REMOVE_PAID_TIME', Namespace.Payroll),
            action: this.removePaidTime.bind(this),
        };
        const deleteShift = {
            text: this.translate.t('DELETE_SHIFT', Namespace.Scheduling),
            action: this.deleteShift.bind(this),
        };
        const addComment = {
            text: this.translate.t('ADD_COMMENT'),
            action: this.addComment.bind(this),
        };
        const seeComments = this.segment().comments.length ? {
            text: this.translate.t('SEE_COMMENTS', Namespace.ChainOps),
            action: this.seeComments.bind(this),
        } : null;
        const editLeaveShift = {
            text: this.translate.t('EDIT_LEAVE_SHIFT', Namespace.Leaveshifts),
            action: this.editLeaveShift.bind(this),
        };
        const deleteLeaveShift = {
            text: this.translate.t('DELETE_LEAVE_SHIFT', Namespace.Leaveshifts),
            action: this.deleteLeaveShift.bind(this),
        };

        const menus: Record<PaidTimeSegmentTypeKey, MenuItem[]> = {
            currentShift: [ seeComments ],
            offtime: [ seeComments ],
            vacation: [ seeComments ],
            unhandledTime: [ addAbsence, addPaidTime, seeComments ],
            upcomingShift: [ addAbsence, addPaidTime, seeComments ],
            paidTime: [ updatePaidTime, removePaidTime, seeComments ],
            extraTime: [ addAbsence, addPaidTime, addComment, seeComments ],
            suggestedPaidTime: [ addAbsence, addPaidTime, seeComments ],
            hourAbsence: [ updateAbsence, removeAbsence, addPaidTime, seeComments ],
            dayAbsence: [ updateAbsence, removeAbsence, addPaidTime, seeComments ],
            unhandledShift: [ addAbsence, addPaidTime, deleteShift, seeComments ],
            leaveShift: [ editLeaveShift, deleteLeaveShift ],
        };

        this.menu = menus[this.segment().type.key].filter((item) => !!item);
    }

    async updateColor(type: 'background' | 'border', color: EawMaterialColorHue) {
        const tinyColor = await this.materialColorService.colorToTiny(color);

        if (type === 'background') {
            this.setProperty('--background-color', tinyColor.toHexString());
            this.setProperty('--text-color', tinyColor.isDark() ? '#fff' : '#000');
            this.setProperty('--spinner-color', tinyColor.isDark() ? '#fff' : '#5a626a');
        } else {
            this.setProperty('--border-color', tinyColor.toHexString());
        }
    }

    setProperty(property: string, value: string) {
        this.elementRef.nativeElement.style.setProperty(property, value);
    }

    click() {
        if (this.segment().isPunchedIn) {
            void this.snackBarService.t('CANT_WHILE_ACTIVE_TP', Namespace.Payroll);
        } else {
            this.menuTrigger()?.openMenu();
        }
    }

    addAbsence() {
        const employeeId = this.segment().employee?.id;
        const customerId = this.segment().customer.id;
        if (!employeeId) {
            throw new Error('Paid time segment does not have an employee');
        }
        if (!customerId) {
            throw new Error('Paid time segment does not have a customer id');
        }

        this.matDialog.open<CreateUpdateAbsenceDialogComponent, CrateUpdateAbsenceDialogData, Absence>(CreateUpdateAbsenceDialogComponent, {
            data: {
                customerId: this.segment().customer.id,
                employeeId,
                from: this.segment().from,
                to: this.segment().to,
                showDuration: true,
                approve: true,
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.processing = true;
                this.segmentUpdated.emit(this.segment());
            }
        });
    }

    updateAbsence() {
        const employeeId = this.segment().employee?.id;
        const customerId = this.segment().customer.id;
        const absence = this.segment().absence;
        if (!absence) {
            throw new Error('Paid time segment does not have an absence');
        }
        if (!employeeId) {
            throw new Error('Paid time segment does not have an employee');
        }
        if (!customerId) {
            throw new Error('Paid time segment does not have a customer id');
        }

        this.matDialog.open<CreateUpdateAbsenceDialogComponent, CrateUpdateAbsenceDialogData, Absence>(CreateUpdateAbsenceDialogComponent, {
            data: {
                customerId: this.segment().customer.id,
                absence,
                showDuration: true,
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.processing = true;
                this.segmentUpdated.emit(this.segment());
            }
        });
    }

    addPaidTime() {
        const employee = this.segment().employee;
        if (!employee) {
            throw new Error('Paid time segment does not have an employee');
        }

        this.matDialog.open<HandlePaidTimeDialogComponent, HandlePaidTimeDialogData, PaidTime>(HandlePaidTimeDialogComponent, {
            data: {
                customerId: this.segment().customer.id,
                employeeId: employee.id,
                from: this.segment().from,
                to: this.segment().to,
                showDuration: true,
                timepunch: this.segment().timepunch,
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.processing = true;
                this.segmentUpdated.emit(this.segment());
            }
        });
    }

    updatePaidTime() {
        const employee = this.segment().employee;
        const paidTime = this.segment().paidTime;
        if (!employee) {
            throw new Error('Paid time segment does not have an employee');
        }
        if (!paidTime) {
            throw new Error('Paid time segment does not have a paid time');
        }

        this.matDialog.open<HandlePaidTimeDialogComponent, HandlePaidTimeDialogData, PaidTime>(HandlePaidTimeDialogComponent, {
            data: {
                paidTime,
                customerId: paidTime.customerId,
                showDuration: true,
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.processing = true;
                this.segmentUpdated.emit(this.segment());
            }
        });
    }

    editLeaveShift() {
        const leaveShift = this.segment().leaveShift;
        if (!leaveShift) {
            throw new Error('Paid time segment does not have a leave shift');
        }

        this.matDialog.open<EditLeaveShiftDialogComponent, EditLeaveShiftDialogComponentData, LeaveShift>(EditLeaveShiftDialogComponent, {
            data: {
                leaveShift: of(leaveShift),
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.processing = true;
                this.segmentUpdated.emit(this.segment());
            }
        });
    }

    deleteLeaveShift() {
        const leaveShift = this.segment().leaveShift;
        if (!leaveShift) {
            throw new Error('Paid time segment does not have a leave shift');
        }

        this.confirmDialogService.delete({
            title: this.translate.t('DELETE_LEAVE_SHIFT', Namespace.Leaveshifts),
            text: this.translate.t('CONFIRM_DELETE_LEAVE_SHIFT', Namespace.Leaveshifts, { name: this.segment().employee?.name }),
            confirmText: this.translate.t('DELETE_LEAVE_SHIFT', Namespace.Leaveshifts),
        }).afterClosed().subscribe((result) => {
            if (result?.ok) {
                this.processing = true;
                this.absenceLeaveShiftService.delete(leaveShift.customerId, leaveShift.employeeId, leaveShift.leaveId, leaveShift.id).subscribe(() => {
                    this.segmentUpdated.emit(this.segment());
                });
            }
        });
    }

    removePaidTime() {
        const paidTime = this.segment().paidTime;
        if (!paidTime) {
            throw new Error('Paid time segment does not have a paid time');
        }

        this.confirmDialogService.delete({
            title: this.translate.t('REMOVE_PAID_TIME', Namespace.Payroll),
            text: this.translate.t('CONFIRM_REMOVE_PAID_TIME', Namespace.Payroll, { name: this.segment().employee?.name }),
            confirmText: this.translate.t('REMOVE_PAID_TIME', Namespace.Payroll),
        }).afterClosed().subscribe((result) => {
            if (result?.ok) {
                this.processing = true;
                this.paidTimeService.delete(paidTime.customerId, paidTime.employeeId, paidTime.id).subscribe(() => {
                    this.segmentUpdated.emit(this.segment());
                });
            }
        });
    }

    addComment() {
        const timepunch = this.segment().timepunch;
        if (!timepunch) {
            throw new Error('Paid time segment does not have a timepunch');
        }

        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                comments: of(timepunch.comments),
                saveCallback: (comment) => this.timepunchService.update(timepunch.customerId, timepunch.employeeId, timepunch.id, { comment }).pipe(
                    switchMap((updatedTimepunch) => {
                        return this.timepunchService.get(updatedTimepunch.customerId, updatedTimepunch.id, [ 'comments' ]);
                    }),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    map((timepunch) => timepunch.comments.reverse()[0]!),
                    tap(() => {
                        this.processing = true;
                        this.segmentUpdated.emit(this.segment());
                    }),
                ),
            },
        });
    }

    seeComments() {
        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                comments: of(this.segment().comments),
            },
        });
    }

    removeAbsence() {
        const absence = this.segment().absence;
        if (!absence) {
            throw new Error('Paid time segment does not have an absence');
        }

        this.confirmDialogService.delete({
            title: this.translate.t('REMOVE_ABSENCE', Namespace.Payroll),
            text: this.translate.t('CONFIRM_REMOVE_ABSENCE', Namespace.Payroll, { name: this.segment().employee?.name }),
            confirmText: this.translate.t('REMOVE_ABSENCE', Namespace.Payroll),
        }).afterClosed().subscribe((result) => {
            if (result?.ok) {
                this.processing = true;
                this.absenceService.delete(absence.customerId, absence.employeeId, absence.id).subscribe(() => {
                    this.segmentUpdated.emit(this.segment());
                });
            }
        });
    }

    deleteShift() {
        const shift = this.segment().shift;
        const customerId = this.segment().shift?.customerId;

        if (!shift) {
            throw new Error('Paid time segment does not have a shift');
        }
        if (!customerId) {
            throw new Error('Shift does not have a customer id');
        }

        this.confirmDialogService.delete({
            title: this.translate.t('DELETE_SHIFT', Namespace.Scheduling),
            text: this.translate.t('DELETE_SHIFT_TEXT', Namespace.Scheduling),
            confirmText: this.translate.t('DELETE_SHIFT', Namespace.Scheduling),
        }).afterClosed().subscribe((result) => {
            if (result?.ok) {
                this.processing = true;
                this.shiftService.delete(customerId, shift.scheduleId, shift.id).subscribe(() => {
                    this.segmentUpdated.emit(this.segment());
                });
            }
        });
    }
}
