// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
import { EawUrl } from '../../shared/angularjs/modules/resource/url.service';
import { PaginationOld } from '../../shared/utils/pagination-old';
import { Mobile } from '../../shared/utils/eaw-mobile';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.messages').factory('Message', [ 'EawResource', 'FileService', '$http', function MessageFactory(EawResource, FileService, $http) {
    const messageUrl = '/messages/:message';
    const threadUrl = '/messages/threads/:thread';
    class Message {
        id;
        currentUser = CurrentOld.getUser();
        belongsToMe;
        user_id;
        sender;
        recipients;
        user;
        message_recipients;
        confirmed_at;
        read_at;
        constructor(data) {
            Object.assign(this, data);
            this.belongsToMe = this.currentUser?.id === this.user_id;
            this.sender = this.belongsToMe ? this.recipients?.[0] : this.user;
            if (this.sender) {
                this.sender.name = `${this.sender?.first_name} ${this.sender?.last_name}`;
            }
            if (this.currentUser?.id !== data.user_id && this.message_recipients) {
                this.confirmed_at = this.message_recipients[0]?.confirmed_at;
                this.read_at = this.message_recipients[0]?.read_at;
            }
            this.archivals_count = this.archivals?.length ?? data.archivals_count;
        }

        getRecipients(pagination) {
            return Message.getRecipients({
                ...PaginationOld.getParams(pagination),
                message_id: this.id,
            });
        }

        static getRecipients(pagination) {
            return EawResource.create('/messages/:message_id/recipients').get(PaginationOld.getParams(pagination, [ 'message_id' ]));
        }

        static getThread(threadId, page) {
            const deferred = EawResource.create(threadUrl).get({
                thread: threadId,
                'with[]': [ 'attachments' ],
                direction: 'desc',
                per_page: 10,
                page,
            });
            deferred.$promise.then((resp) => {
                resp.data = resp.data.map((msg) => new Message(msg));
            });
            return deferred;
        }

        static getThreads(page, perPage, options) {
            return EawResource.create(threadUrl).get({
                page,
                per_page: perPage || 10,
                include_archived: options?.archived,
                'with[]': options?.with,
                'count[]': options?.count,
            }).$promise.then((resp) => {
                resp.data = resp.data.map((m) => new Message(m));
                return resp;
            });
        }

        static getInbox(args) {
            return EawResource.create('/messages/inbox').get({
                ...PaginationOld.getParams(args, [ 'read', 'include_archived' ]),
            }).$promise.then((resp) => {
                resp.data = resp.data.map((d) => new Message(d));
                return resp;
            });
        }

        static getOutbox(args) {
            return EawResource.create('/messages/outbox').get(PaginationOld.getParams(args)).$promise.then((resp) => {
                resp.data = resp.data.map((d) => new Message(d));
                return resp;
            });
        }

        static read(id, relations) {
            return EawResource.create(messageUrl).get({
                message: id,
                'with[]': relations,
            });
        }

        static confirm(id) {
            return EawResource.create(`${messageUrl}/confirm`).save({
                message: id,
            }, {});
        }

        static update(message) {
            return EawResource.create(messageUrl).update({
                message: message.id,
            }, pick(message, [ 'body', 'subject' ])).$promise;
        }

        static sendMessage(subject, body, recipients, withArray) {
            return EawResource.create(messageUrl).save({}, {
                subject,
                body,
                ...pick(recipients, [ 'user_group_recipients', 'customer_recipients', 'recipients', 'thread_id', 'is_blind' ]),
                with: withArray,
            });
        }

        static uploadAttachment(message, file) {
            return FileService.upload(`${EawUrl.url}/messages/${message.id}/attach`, file, 'attachment');
        }

        static downloadAttachment(messageId, attachmentId) {
            $http({
                method: 'get',
                url: `${EawUrl.url}/messages/${messageId}/attachments/${attachmentId}/download`,
                params: {
                    url: true,
                },
            }).then((response) => {
                if (Mobile.isMobile) {
                    return Mobile.openBrowser(response.data.url);
                }
                const link = document.createElement('a');
                link.href = response.data.url;
                link.dispatchEvent(new MouseEvent('click'));
            });
        }

        static remind(message) {
            return EawResource.create(`${messageUrl}/remind`).save({
                message: message.id,
            }, {});
        }
    }
    return Message;
} ]);
