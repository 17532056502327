// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-card>
    <md-card-content>
        <form name="variableLockForm">
            <div>
                <md-icon class="tw-m-0 hover-item variable-locks-help-icon"
                         ng-bind="'help'"
                         ng-click="$ctrl.displayHelp = !$ctrl.displayHelp">
                </md-icon>
            </div>
            <div layout="row" layout-wrap>
                <span ng-if="$ctrl.displayHelp" ng-i18next="ai_budgeting:LOCK_EXPLANATION" class="variable-locks-help-text"></span>
                <md-input-container flex-xs="100" flex-sm="100">
                    <eaw-date-picker ng-model="$ctrl.date"></eaw-date-picker>
                </md-input-container>
            </div>

            <md-button class="md-raised md-accent" ng-disabled="!$ctrl.date || $ctrl.saving" ng-click="$ctrl.setLock()">
                <md-icon class="tw-m-0 hover-item"
                         ng-bind="'lock'">
                </md-icon>
                <span ng-i18next="ai_budgeting:SET_LOCK"></span>
            </md-button>
        </form>
    </md-card-content>
</md-card>
`;

module('eaw.ai-budgeting').component('aiBudgetForecastLocks', {
    template: template1,
    bindings: {
        bucket: '<',
        constants: '<',
        customer: '<',
    },
    controller: [ 'mlForecastLocksFactory', function(mlForecastLocksFactory) {
        const ctrl = this;

        ctrl.saving = false;
        ctrl.hasLock = false;
        ctrl.displayHelp = false;
        ctrl.date = null;

        ctrl.$onInit = () => {
            const dt = moment();
            mlForecastLocksFactory.get.query(
                ctrl.customer.id,
                ctrl.bucket.id,
            ).then(function(resp) {
                if (resp.locked_until) {
                    ctrl.hasLock = true;
                    ctrl.date = moment(resp.locked_until);
                } else {
                    ctrl.hasLock = false;
                }
            });
        };

        ctrl.setLock = () => {
            ctrl.saving = true;
            mlForecastLocksFactory.set.query(
                ctrl.customer.id,
                ctrl.bucket.id,
                ctrl.date,
            ).finally(function() {
                ctrl.hasLock = true;
                ctrl.saving = false;
            });
        };

        ctrl.removeLock = () => {
            ctrl.saving = true;
            mlForecastLocksFactory.delete.del(
                ctrl.customer.id,
                ctrl.bucket.id,
            ).finally(function() {
                ctrl.hasLock = false;
                ctrl.saving = false;
                ctrl.date = null;
            });
        };
    } ],
});
