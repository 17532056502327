<eaw-page-header [buttons]="headerButtons"></eaw-page-header>

<mat-card>
    <mat-card-content>
        <eaw-data-table
            [columns]="columns"
            [request]="request"
            initialSortBy="from"
            [rowClasses]="rowClasses"
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
