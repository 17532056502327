import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { NewsletterIssueService } from '../../http/newsletter-issue.service';
import { CurrentService } from '../../../shared/services/current.service';
import { NewsletterIssue } from '../../models/newsletter-issue';
import { MatCard, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { NewsletterService } from '../../http/newsletter.service';
import { forkJoin, tap } from 'rxjs';
import { Newsletter } from '../../models/newsletter';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { NewsletterPreviewComponent } from '../../components/newsletter-preview/newsletter-preview.component';
import { MatList, MatListItem } from '@angular/material/list';
import { ReadNewsletterIssueService } from '../../services/read-newsletter-issue.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaw-newsletter-issues',
    standalone: true,
    imports: [
        MatCard,
        MatCardHeader,
        NumberPipe,
        MatCardSubtitle,
        MatCardTitle,
        TranslatePipe,
        AsyncPipe,
        NewsletterPreviewComponent,
        MatList,
        MatListItem,
    ],
    templateUrl: './newsletter-issues.component.html',
    styleUrl: './newsletter-issues.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterIssuesComponent implements OnInit {
    private readonly currentService = inject(CurrentService);
    private readonly newsletterIssueService = inject(NewsletterIssueService);
    private readonly newsletterService = inject(NewsletterService);
    private readonly readNewsletterIssueService = inject(ReadNewsletterIssueService);
    private readonly destroyRef = inject(DestroyRef);

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) newsletterId!: string;

    protected loading = signal(true);
    protected language: string;
    protected newsletter: WritableSignal<Newsletter | undefined> = signal(undefined);
    protected issues: WritableSignal<NewsletterIssue[]> = signal([]);
    protected totalIssues: WritableSignal<number> = signal(0);

    constructor() {
        this.language = this.currentService.languageTag;
    }

    ngOnInit() {
        forkJoin([
            this.newsletterService.get(this.customerId, parseInt(this.newsletterId)),
            this.newsletterIssueService.getAll(this.customerId, parseInt(this.newsletterId)),
        ]).pipe(
            tap(([ newsletter, issues ]) => {
                this.newsletter.set(newsletter);
                this.issues.set(issues.data);
                this.totalIssues.set(issues.total);
                this.loading.set(false);
            }),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();
    }

    openIssue(issue: NewsletterIssue) {
        return this.readNewsletterIssueService.open(this.customerId, issue.newsletterId, issue.id, issue.getLanguageCode(this.language));
    }
}
