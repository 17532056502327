<div class="tw-mx-16 tw-mt-16">
    @if (data.isTemplate) {
        <eaw-autocomplete [formControl]="employeeControl"
                          [options]="employeeAutocompleteService.options"
                          [getter]="employeeAutocompleteService.getter({customerId: data.customerId})"
                          [setter]="employeeAutocompleteService.setter({customerId: data.customerId})">
        </eaw-autocomplete>
    } @else {
        <eaw-autocomplete [formControl]="employeeControl"
                          [options]="shiftEmployeeAutocompleteService.options"
                          [getter]="shiftEmployeeAutocompleteService.getter({
                                  customerId: data.customerId,
                                  scheduleId: data.scheduleId,
                                  businessDate: data.shiftId ? undefined: data.businessDate,
                                  shiftId: data.shiftId ? data.shiftId: undefined
                              })"
                          [setter]="shiftEmployeeAutocompleteService.setter({
                                  customerId: data.customerId,
                                  scheduleId: data.scheduleId,
                                  businessDate: data.shiftId ? undefined: data.businessDate,
                                  shiftId: data.shiftId ? data.shiftId: undefined
                              })">
        </eaw-autocomplete>
    }
</div>

@if (loading) {
    <eaw-info-loading size="lg" class="tw-my-24"/>
} @else {
    <mat-list>
        @for (item of items; track item) {
            @if (item.hasPermission) {
                <mat-list-item (click)="item.click()" [disabled]="!item.hasPermission || item.disabled?.()">
                    <mat-icon [eawMaterialColor]="item.color" matListItemIcon>{{ item.icon }}</mat-icon>
                    <span matListItemLine>{{ item.text | async }}</span>
                </mat-list-item>
            }
        }
    </mat-list>
}
