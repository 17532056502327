<eaw-dialog-header>
    <span title>{{data.report.name}}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-12 tw-p-24" *ngIf="!error && !(ready && runOptions.length)">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <span *ngIf="!ready" class="mat-body-2" style="text-align: center; white-space: pre-wrap">{{'NOT_READY_INFO' | translate:'reports' | async}}</span>
</mat-dialog-content>

<mat-dialog-content *ngIf="error" class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-8">
    <mat-icon eawMatIconSize="40px">error</mat-icon>
    <span class="mat-subtitle-2 tw-mb-24">{{error}}</span>
</mat-dialog-content>

<mat-dialog-content *ngIf="ready && runOptions.length">
    <mat-card class="generate-again-tips tw-mb-20">
        <mat-card-content class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-8">
            <div class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-8">
                <mat-icon>tips_and_updates</mat-icon>
                <span class="mat-body-2">{{'REGENERATE_TIPS' | translate:'reports' | async}}</span>
            </div>

            <div>
                <button (click)="generateAgain()" mat-raised-button>{{'GENERATE' | translate | async}}</button>
            </div>
        </mat-card-content>
    </mat-card>

    <table>
        <tr *ngFor="let option of runOptions">
            <td class="mat-subtitle-2"> {{option.key.toUpperCase() | translate:'reports' | async}} </td>
            <td class="mat-body-2">
                <div *ngFor="let items of option.items | slice:0:option.slice"> {{items}} </div>
                <button mat-raised-button *ngIf="option.slice && option.items.length > option.slice" (click)="option.slice = option.items.length">{{('SHOW_ALL_X_ENTRIES' | translate:'general':{count: option.items.length} | async)}}</button>
            </td>
        </tr>
    </table>
</mat-dialog-content>

<mat-dialog-actions>
    <!-- Basic close -->
    <button mat-button mat-raised-button mat-dialog-close="">{{ 'CLOSE' | translate | async}}</button>

    <!-- Try again if error -->
    <button mat-button mat-raised-button mat-dialog-close="" (click)="generateAgain()" *ngIf="error">{{ 'TRY_AGAIN' | translate | async}}</button>

    <!-- Download for mobile -->
    <button mat-button mat-raised-button color="primary" mat-dialog-close="" (click)="download(url!)" *ngIf="isMobile" [disabled]="!url">{{'DOWNLOAD' | translate | async}}</button>
    <button mat-button mat-raised-button color="primary" mat-dialog-close="" *ngIf="!isMobile && !url" disabled>{{'DOWNLOAD' | translate | async}}</button>

    <!-- Download for computer -->
    <a *ngIf="!isMobile && url" download [href]="url" target="_blank" mat-dialog-close="" class="tw-ml-8">
        <button mat-button mat-raised-button color="primary" mat-dialog-close="">{{'DOWNLOAD' | translate | async}}</button>
    </a>
</mat-dialog-actions>
