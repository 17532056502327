import { loadNamespaces, t } from 'i18next';
import { debounce, forEach, groupBy, orderBy, uniqBy } from 'lodash-es';
import { Storage } from '../../shared/utils/storage';
import WidgetGrid from '../factories/widget-grid.factory';
import WidgetInfo, { WidgetInfoObject } from '../factories/widget-info.factory';
import { uniqueId } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import Widget from '../classes/widget.class';
import { ElementInitialization } from '../../shared/angularjs/modules/misc/services/element-initialization.service';
import { catchError, EMPTY, firstValueFrom, lastValueFrom, switchMap } from 'rxjs';
import { UserPropertyService } from '../../shared/http/user-property.service';
import { module } from 'angular';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { SettingResponse } from '../../shared/models/setting';
import { EawResourceFactory } from '../../shared/angularjs/modules/resource/resource.service';
import { PropertyOld } from '../../shared/angularjs/property.class';
import { MatDialog } from '@angular/material/dialog';
import { ReorderItemsDialogComponent } from '../../shared/dialogs/reorder-items-dialog/reorder-items-dialog.component';
import { ReorderItemsDialogData } from '../../shared/dialogs/reorder-items-dialog/reorder-items-dialog.service';
import { DialogSize } from '../../shared/dialogs/dialog-component';

module('eaw.dashboard').component('eawDashboard', {
    template: `<div class="dashboard-header">
    <div class="dashboard-header-left">
        <md-icon ng-bind="'home'"></md-icon>
    </div>

    <div class="dashboard-header-right" hide-xs>
        <md-button ng-show="!$dash.doingSetup && $dash.normalWidgets.length && $dash.miniWidgets.length" class="md-icon-button" ng-click="$dash.toggleMiniTop()">
            <md-tooltip md-direction="bottom" ng-i18next="widgets:TOGGLE_MINI_TOP"></md-tooltip>
            <md-icon ng-bind="'swap_vert'"></md-icon>
        </md-button>

        <md-button ng-show="!$dash.doingSetup" class="md-icon-button" ng-click="$dash.widgetSort()">
            <md-tooltip md-direction="bottom" ng-i18next="SORT"></md-tooltip>
            <md-icon ng-bind="'sort'"></md-icon>
        </md-button>

        <md-button ng-show="!$dash.doingSetup" class="md-icon-button" ng-click="$dash.openAddWidgetDialog()">
            <md-tooltip md-direction="bottom" ng-i18next="widgets:ADD_WIDGET"></md-tooltip>
            <md-icon ng-bind="'add'"></md-icon>
        </md-button>
    </div>
</div>

<div id="dashboard-widgets-wrapper">
    <!-- Minis {{$dash.WidgetGrid.rowHeightMini}} -->
    <div id="minis-container">
                <eaw-lazy-element class="mini-widget" ng-repeat="widget in $dash.miniWidgets track by widget.key">
                <eaw-mini-widget widget="widget" color="$dash.widgetDefaultColor"></eaw-mini-widget>
            </eaw-lazy-element>
         </div>
         
    <!-- Normals {{$dash.WidgetGrid.rowHeightNormal}} -->
         <div id="normals-container">
           <eaw-lazy-element class="normal-widget" data-height="{{widget.grid.height}}" data-width="{{widget.grid.width}}" ng-repeat="widget in $dash.normalWidgets track by widget.key">
                <eaw-widget widget="widget" customer="$dash.customer" color="$dash.widgetDefaultColor"></eaw-widget>
            </eaw-lazy-element>
            </div>

    <div ng-show="$dash.doingSetup || (!$dash.normalWidgets.length && !$dash.miniWidgets.length)" class="dash-info">
        <md-icon ng-bind="'widgets'" class="widget-rotate"></md-icon>
        <span ng-if="$dash.doingSetup" ng-bind="$dash.statusText"></span>
        <span ng-if="!$dash.doingSetup && !$dash.normalWidgets.length && !$dash.miniWidgets.length" ng-i18next="widgets:NO_WIDGETS_ADDED"></span>
    </div>
</div>

<md-fab-speed-dial id="dash-fab" class="md-scale" md-direction="up" hide-gt-xs>
    <md-fab-trigger>
        <md-button aria-label="menu" class="md-fab" ng-if="!$dash.doingSetup">
            <md-icon ng-bind="'menu'"></md-icon>
        </md-button>
    </md-fab-trigger>

    <md-fab-actions>
        <md-button aria-label="Add widget" class="md-fab md-raised md-mini" ng-click="$dash.openAddWidgetDialog()">
            <md-icon ng-bind="'add'" aria-label="Add widget"></md-icon>
        </md-button>

        <md-button aria-label="Sort widgets" class="md-fab md-raised md-mini" ng-click="$dash.widgetSort()">
            <md-icon ng-bind="'sort'" aria-label="Sort widgets"></md-icon>
        </md-button>

        <md-button ng-show="!$dash.doingSetup && $dash.normalWidgets.length && $dash.miniWidgets.length" class="md-fab md-raised md-mini" ng-click="$dash.toggleMiniTop()">
            <md-icon ng-bind="'swap_vert'"></md-icon>
        </md-button>
    </md-fab-actions>
</md-fab-speed-dial>
`,
    controllerAs: '$dash',
    bindings: {
        customer: '<',
        user: '<',
        employee: '<',
    },
    controller: [ 'MatDialogDowngrade', '$timeout', 'UserPropertyDowngraded', 'kpiTypeService', 'CustomerSettings', 'ToastService', '$injector', 'EawResource', 'addWidgetDialog', '$rootScope', '$element', '$state', function(MatDialogDowngrade: MatDialog, $timeout, UserPropertyDowngraded: UserPropertyService, kpiTypeService, CustomerSettings, ToastService: SnackBarService, $injector, EawResource: EawResourceFactory, addWidgetDialog, $rootScope, $element, $state) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = () => {
            kpiTypeService.flush();
            $rootScope.$on('widget:deleted', ctrl.onDeletedWidget);
            ctrl.widgetInfo = WidgetInfo.getAll();
        };

        ctrl.$onDestroy = () => {
            ctrl.resizeObserver?.disconnect();
        };

        ctrl.$postLink = async () => {
            const normalsContainer = document.getElementById('normals-container');
            const gridColumnGap = 25;
            const normalWidgetWidth = 540;

            ctrl.resizeObserver = new ResizeObserver(debounce((entries) => {
                const entryWidth = entries[0]?.contentRect.width;
                if (!entryWidth) {
                    return;
                }

                if (entryWidth < ((normalWidgetWidth * 2) + gridColumnGap)) {
                    normalsContainer?.classList.add('single-column');
                } else {
                    normalsContainer?.classList.remove('single-column');
                }
            }, 100));

            if (normalsContainer) {
                ctrl.resizeObserver.observe(normalsContainer);
            }

            ctrl.el = $element[0];
            ctrl.WidgetGrid = WidgetGrid;
            ctrl.doingSetup = true;
            ctrl.statusText = t('widgets:FETCHING_WIDGETS');

            await ctrl.getWidgetSettings();

            ctrl.handleAnimation();

            await ctrl.checkSetup();

            ctrl.onDeletedWidget();

            await ctrl.setMiniTop();
        };

        ctrl.isWidgetProperty = (key: string) => {
            return RegExp('^widget_v2:\\d+$').test(key);
        };

        ctrl.getWidgetSettings = async () => {
            ctrl.hiddenWidgets = await Widget.getHiddenWidgets(EawResource, ctrl.user.id, ctrl.customer.id);
            ctrl.widgetDefaultColor = (await CustomerSettings.get(ctrl.customer.id, 'widget_color')).widget_color;

            try {
                const val = (await CustomerSettings.get(ctrl.customer.id, 'default_widgets')).default_widgets;
                ctrl.defaultWidgets = typeof val === 'string' ? JSON.parse(val) : [];
            } catch (_) {
                ctrl.defaultWidgets = [];
            }

            try {
                const val = (await CustomerSettings.get(ctrl.customer.id, 'disabled_widgets')).disabled_widgets;
                ctrl.disabledWidgets = typeof val === 'string' ? JSON.parse(val) : [];
                ctrl.disabledWidgets.forEach((w: string) => {
                    if (!ctrl.widgetInfo[w]) {
                        return;
                    }

                    ctrl.widgetInfo[w].disabled = true;
                });
            } catch (_) {
                ctrl.disabledWidgets = [];
            }

            ctrl.defaultWidgets.forEach((w: string) => {
                if (!ctrl.widgetInfo[w]) {
                    return;
                }
                // Add default flag to info, unless the widget is also disabled
                ctrl.widgetInfo[w].default = !ctrl.widgetInfo[w].disabled;
            });

            // Filter out any disabled widgets
            ctrl.defaultWidgets = ctrl.defaultWidgets.filter((w: any) => ctrl.widgetInfo[w]?.default);
        };

        ctrl.handleAnimation = () => {
            ctrl.iconInit = new ElementInitialization('.dash-info md-icon', ctrl.el);
            ctrl.iconInit.observe().then((el: any) => {
                [ 'animationiteration', 'webkitAnimationIteration' ].forEach((e) => {
                    el.addEventListener(e, ctrl.onAnimation);
                });
            });
        };

        ctrl.onAnimation = (event: any) => {
            if (!ctrl.doingSetup) {
                event.target.classList.remove('widget-rotate');
                event.target.removeEventListener(event.type, ctrl.onAnimation);
                $rootScope.$digest();
            }
        };

        ctrl.onDeletedWidget = (_: any, widget: any) => {
            if (!widget) {
                return;
            }

            newrelic.addPageAction('Deleted widget', { widget: widget.name });

            if (widget.mini) {
                ctrl.miniWidgets = ctrl.miniWidgets.filter((mw: any) => mw.key !== widget.key);
            } else {
                ctrl.normalWidgets = ctrl.normalWidgets.filter((nw: any) => nw.key !== widget.key);
            }
        };

        ctrl.checkSetup = async () => {
            const completedKey = Storage.prefix('widget_setup_completed');
            // Get widgets immediately if setup completed is in local storage
            if (await Storage.getItem(completedKey)) {
                await ctrl.getWidgets();
            } else {
                // Get completed for customer
                const observer = UserPropertyDowngraded.get(ctrl.user.id, `widget_setup_completed:${ctrl.customer.id}`).pipe(
                    catchError((err) => {
                        if (err?.status === 404) { // Do setup if we didn't find the property
                            // Adding completed property first, so that if we get a 429 we're not blocked from adding it after
                            ctrl.addCompletedProperty();

                            return ctrl.doSetup();
                        }

                        return EMPTY;
                    }),
                    switchMap(async (): Promise<void> => {
                        await Storage.setItem(completedKey, 1);

                        return ctrl.getWidgets();
                    }),
                );

                await lastValueFrom(observer);
            }
        };

        ctrl.handleDupes = (widgets: any) => {
            let keep = [] as any[];
            let remove = [] as any[];

            // Group the widget by name
            const groupedWidgets = groupBy(widgets, 'name');

            forEach(groupedWidgets, (nameWidgets) => {
                const reversedNameWidgets: any = nameWidgets.reverse(); // Reverse so oldest is first

                if (reversedNameWidgets[0].multiple) { // If the widget can have multiple then go over the settings and remove those with duplicate settings
                    const nameWidgetsSame = groupBy(reversedNameWidgets, (x) => JSON.stringify(x.settings));

                    forEach(nameWidgetsSame, (sameSettings: any) => {
                        const reversed = sameSettings.reverse();

                        keep = keep.concat(reversed.slice(0, 1));
                        remove = remove.concat(reversed.slice(1, reversed.length));
                    });
                } else { // If the widget can have only one then simply keep the newest one
                    keep = keep.concat(reversedNameWidgets.slice(0, 1));
                    remove = remove.concat(reversedNameWidgets.slice(1, reversedNameWidgets.length));
                }
            });

            return keep;
        };

        ctrl.loadWidgetNs = async (widgets: any) => {
            const namespaces = new Set();
            widgets.forEach((w: any) => {
                w.namespaces?.forEach((ns: any) => {
                    namespaces.add(ns);
                });
            });

            // @ts-ignore
            return await loadNamespaces(Array.from(namespaces));
        };

        ctrl.getWidgets = async () => {
            const requiredSettings: string[] = Object.values(WidgetInfo.getAll())
                .map((w) => w.requiredSettings ? Object.keys(w.requiredSettings) : null)
                .filter((s) => !!s)
                .flat() as string[];

            const [ properties, settings ] = await Promise.all([
                EawResource.create(`/users/${ctrl.user.id}/properties`).get({
                    filter: 'widget_v2',
                    per_page: 9999,
                }).$promise,
                CustomerSettings.getSome(ctrl.customer.id, requiredSettings, undefined, undefined, { array: true }),
            ]);

            ctrl.settings = settings.reduce((acc: Record<string, any>, curr: SettingResponse) => {
                acc[curr.key] = curr.resolved_value;
                return acc;
            }, {});

            ctrl.widgets = (properties?.data || [])
                .map((w: any) => {
                    if (w.customer == null) {
                        w.customer = ctrl.customer.id;
                    }

                    return w;
                })
                .filter((w: any) => ctrl.isWidgetProperty(w.key)) // Make sure the property key is a proper widget key
                .map((w: PropertyOld) => {
                    let widget: any;

                    try {
                        widget = JSON.parse(w.value as string);
                    } catch (_) {
                        widget = {};
                    }

                    return new Widget(w, ctrl.user, ctrl.employee, $injector, ctrl.settings, ctrl.hiddenWidgets, ctrl.widgetInfo[widget['name']]);
                }) // Create Widgets
                .filter((w: any) => w.exists) // Must exist in WidgetInfo
                .filter((w: any) => w.customer == ctrl.customer.id) // Must be this customer, or if customer is missing
                .filter((w: any) => w.hasRequirements); // Things might change from the last time the user logged in or visited the dashboard

            await ctrl.loadWidgetNs(ctrl.widgets);

            ctrl.widgets = ctrl.handleDupes(ctrl.widgets);
            ctrl.miniWidgets = orderBy(ctrl.widgets.filter((w: any) => w.mini), [ 'settings.order' ], [ 'asc' ]);
            ctrl.normalWidgets = orderBy(ctrl.widgets.filter((w: any) => !w.mini), [ 'settings.order' ], [ 'asc' ]);
            ctrl.doingSetup = false;

            ctrl.widgets.forEach((w: any) => {
                newrelic.addPageAction('Old dashboard widget (on load)', {
                    widget: w.name,
                    default: w.default,
                    mini: w.mini,
                });
            });

            return ctrl.checkForNewDefaultWidgets();
        };

        ctrl.setMiniTop = async () => {
            const wrapper = ctrl.el.querySelector('#dashboard-widgets-wrapper');
            if (wrapper) {
                ctrl.miniOnTop = await Storage.getItem(Storage.prefix('widgets_mini_top'));
                const func = ctrl.miniOnTop ? 'add' : 'remove';
                wrapper.classList[func]('reverse');
            }
        };

        ctrl.toggleMiniTop = async () => {
            const wrapper = ctrl.el.querySelector('#dashboard-widgets-wrapper');
            ctrl.miniOnTop = !ctrl.miniOnTop;
            if (wrapper) {
                wrapper.classList.toggle('reverse');
                await Storage.setItem(Storage.prefix('widgets_mini_top'), wrapper.classList.contains('reverse'));
            }
        };

        ctrl.checkForNewDefaultWidgets = () => {
            const addedWidgets = ctrl.widgets.map((w: any) => w.name);
            const widgets = ctrl.defaultWidgets?.map((widgetName: any) => {
                let widget: Widget | null = null;
                if (!addedWidgets.includes(widgetName) && !ctrl.widgetInfo[widgetName]?.disabled) {
                    widget = new Widget(ctrl.widgetInfo[widgetName], ctrl.user, ctrl.employee, $injector, ctrl.settings, ctrl.hiddenWidgets, ctrl.widgetInfo);
                }
                return widget?.hasRequirements ? widget : null;
            })?.filter((p: any) => p);

            if (widgets?.length) {
                return Promise.all(widgets.map((widget: any) => ctrl.createWidget(widget, ctrl.user, ctrl.customer))).then(() => $state.reload());
            }

            return Promise.resolve();
        };

        ctrl.doSetup = () => {
            const defaultMiniCount = 2;
            ctrl.statusText = t('widgets:SETTING_UP_WIDGETS');
            // Find all available widgets
            const widgets: WidgetInfoObject[] = Object.values(ctrl.widgetInfo);
            const availableWidgets = widgets.filter((w: WidgetInfoObject) => {
                const widget = new Widget(w, ctrl.user, ctrl.employee, $injector, ctrl.settings, ctrl.hiddenWidgets, w);
                return !widget.excludeFromSetup && widget.hasRequirements;
            });

            // Add all normal and minis that we have available
            // By default the widgets set as "default" will also be added
            const availableNormal = availableWidgets.filter((w: any) => !w.mini);
            let availableMini = availableWidgets.filter((w: any) => w.mini);
            // Handle number of mini widgets
            if (availableMini.length > defaultMiniCount) {
                // We need all defaults
                const defaultMini: any = availableMini.filter((w: any) => w.default);
                // Other ones
                const standardMini: any = availableMini.filter((w: any) => !w.default);
                // New available mini
                availableMini = [].concat(defaultMini);
                // Add standard minis if we have styles than 6 default minis
                if (defaultMini.length < defaultMiniCount) {
                    availableMini = availableMini.concat(standardMini.slice(0, defaultMiniCount - defaultMini.length));
                }
            }
            // Join normal and mini and sort
            // Sort so that the widest widget go first,
            // Then the long ones
            // Finally put the "normal" sized ones in where we can
            const sortedWidgets = uniqBy(orderBy(availableMini.concat(availableNormal), [ 'grid.colspan.xl', 'grid.rowspan.xl' ], [ 'desc', 'desc' ]), 'name');

            // Set order on widgets
            sortedWidgets.forEach((w: any, index) => {
                w.settings = w.settings || {};
                w.settings.order = index;
            });

            ctrl.widgets = [];

            const createWidgets = ctrl.chunkRequests(sortedWidgets, (w: any) => ctrl.createWidget(w, ctrl.user, ctrl.customer).then((wp: any) => {
                let name: string;
                try {
                    name = JSON.parse(wp.value).name;
                } catch (_) {
                    name = '';
                }
                ctrl.widgets.push(new Widget(wp, ctrl.user, ctrl.employee, $injector, ctrl.settings, ctrl.hiddenWidgets, ctrl.widgetInfo[name]));
            }));

            return createWidgets.then(() => {
                ctrl.miniWidgets = ctrl.widgets.filter((w: any) => w.mini);
                ctrl.normalWidgets = ctrl.widgets.filter((w: any) => !w.mini);
            }).finally(() => delete ctrl.doingSetup);
        };

        ctrl.addCompletedProperty = () => {
            UserPropertyDowngraded.update(ctrl.user.id, `widget_setup_completed:${ctrl.customer.id}`, '1').subscribe();
        };

        ctrl.chunkRequests = (arr: any, callback: any) => {
            let promise = Promise.resolve();

            const take = arr.length >= 240 ? 4 : 16;
            for (let skip = 0; skip < arr.length; skip += take) {
                // @ts-ignore
                promise = promise.then(() => {
                    const promises = arr.slice(skip, take + skip).map(callback).concat([ $timeout(1000) ]);
                    return Promise.all(promises);
                });
            }
            return promise;
        };

        ctrl.widgetSort = async () => {
            MatDialogDowngrade.open<ReorderItemsDialogComponent<any>, ReorderItemsDialogData<any>>(ReorderItemsDialogComponent, {
                data: {
                    size: DialogSize.Medium,
                    items: ctrl.normalWidgets.map((w: any) => {
                        return {
                            widget: w,
                            text: Promise.resolve(t(w.widgetInfo.i18name)),
                        };
                    }),
                    title: Promise.resolve(t('widgets:SORT_WIDGETS')),
                    reorderOnly: true,
                },
            }).afterClosed().subscribe((widgets) => {
                ctrl.normalWidgets = widgets.map((w: any) => w.widget);
                ctrl.normalWidgets.forEach((w: any, index: number) => {
                    w.setOrder(index);
                });
            });
        };

        ctrl.addWidget = (widget: any, isDefault: any) => {
            // Always push in the main widget array
            ctrl.widgets.push(widget);
            if (widget.mini) {
                ctrl.miniWidgets.unshift(widget);
            } else {
                ctrl.normalWidgets.unshift(widget);
            }
            ToastService.t(isDefault ? 'DEFAULT_WIDGET_ADDED' : 'WIDGET_ADDED', 'widgets');
        };

        ctrl.openAddWidgetDialog = () => {
            addWidgetDialog.show(ctrl.normalWidgets, ctrl.miniWidgets, ctrl.widgetInfo).then((widget: any) => {
                ToastService.t('ADDING_WIDGET', 'widgets');
                ctrl.createWidget(widget, ctrl.user, ctrl.customer).then(() => $state.reload());
            });
        };

        ctrl.createWidget = async (widget: any, user: any, customer: any) => {
            const id = +new Date(); // Unix ms
            const unique = uniqueId(); // Append to always ensure a unique id, if for some reason add on same ms
            const key = `widget_v2:${id}${unique}`;
            await ctrl.loadWidgetNs([ widget ]);
            return firstValueFrom(UserPropertyDowngraded.update(user.id, key, JSON.stringify({
                name: widget.name,
                customer: customer.id,
                settings: {
                    ...widget.settings,
                    headerColor: ctrl.widgetInfo?.[widget.name]?.settings?.headerColor,
                },
                gridWidth: widget.grid.width,
                gridHeight: widget.grid.height,
            })));
        };
    } ],
});
