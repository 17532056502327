import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { DateTime } from 'luxon';
import { FamilyMemberRelation, FamilyMemberService } from '../../http/family-member.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslateService } from '../../../shared/services/translate.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { catchError, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { CustomFieldsGroup } from '../../../shared/utils/custom-fields-group';
import { CustomerCustomFieldService } from '../../../shared/http/customer-custom-field.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { ModelCustomField } from '../../../custom-fields/models/model-custom-field';
import { CustomFieldInputComponent } from '../../../custom-fields/components/custom-field-input/custom-field-input.component';
import { FamilyMember } from '../../models/family-member';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';

export interface HandleFamilyMemberDialogData extends DialogData {
    customerId: number;
    employeeId: number;
    familyMember?: Observable<FamilyMember> | undefined;
}

export type HandleFamilyMemberDialogReturn = FamilyMember;

@Component({
    selector: 'eaw-handle-family-member-dialog',
    standalone: true,
    imports: [ CommonModule, DialogHeaderComponent, MatButtonModule, MatDialogActions, MatDialogClose, TranslatePipe, MatDialogContent, ReactiveFormsModule, ActionButtonComponent, MatFormFieldModule, MatInputModule, DatePickerOptionsDirective, MatDatepickerModule, MatOptionModule, MatSelectModule, MatSlideToggleModule, CustomFieldInputComponent, InfoLoadingComponent ],
    templateUrl: './handle-family-member-dialog.component.html',
    styleUrl: './handle-family-member-dialog.component.scss',
})
export class HandleFamilyMemberDialogComponent extends DialogComponent implements OnInit {
    processing = false;
    loading = false;
    familyMemberId?: number;
    maxBirthDate = DateTime.now();
    customFields: ModelCustomField[] = [];
    form = new FormGroup({
        name: new FormControl<string | null>(null, Validators.required),
        birthDate: new FormControl<DateTime | null>(null, Validators.required),
        relationship: new FormControl<FamilyMemberRelation | null>(null, Validators.required),
        customFields: new CustomFieldsGroup(),
    });

    relationshipOptions: { value: FamilyMemberRelation, translation: Promise<string> }[] = [
        { value: 'spouse', translation: this.translateService.t('spouse') },
        { value: 'child', translation: this.translateService.t('child') },
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: HandleFamilyMemberDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<HandleFamilyMemberDialogComponent, HandleFamilyMemberDialogReturn>,
        @Inject(TranslateService) private readonly translateService: TranslateService,
        @Inject(FamilyMemberService) private readonly familyMemberService: FamilyMemberService,
        @Inject(CustomerCustomFieldService) private customerCustomFieldService: CustomerCustomFieldService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit() {
        this.loading = true;

        forkJoin([
            this.customerCustomFieldService.getForModel(this.data.customerId, ApiModel.FamilyMember).pipe(catchError(() => [])),
            this.data.familyMember ? this.data.familyMember : of(undefined),
        ]).subscribe(([ customFields, familyMember ]) => {
            this.customFields = customFields.map((customField) => {
                customField.value = familyMember?.customFields.find((cf) => cf.key === customField.key)?.value ?? null;
                return customField;
            });

            this.form.patchValue({
                name: familyMember?.name ?? null,
                birthDate: familyMember?.birthDate ?? null,
                relationship: familyMember?.relation ?? null,
            });

            this.familyMemberId = familyMember?.id;
            this.loading = false;
        });
    }

    handle() {
        const value = this.form.value;
        if (!(value.name && value.birthDate && value.relationship)) {
            return;
        }

        const customFields = this.form.controls.customFields.getValuesWithInterval();
        const data = {
            birth_date: value.birthDate,
            name: value.name,
            relation: value.relationship,
        };

        this.processing = true;
        (this.familyMemberId ?
            this.familyMemberService.update(this.data.customerId, this.data.employeeId, this.familyMemberId, data, customFields) :
            this.familyMemberService.create(this.data.customerId, this.data.employeeId, data, customFields)
        ).pipe(
            catchError(() => {
                this.processing = false;
                return EMPTY;
            }),
        ).subscribe((result) => {
            this.dialogRef.close(result);
        });
    }
}
