// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.messages').factory('recipientDialog', [ 'componentDialog', function recipientDialog(componentDialog) {
    const service = this;
    /**
     * @param {Message} message
     */
    service.open = (message) => componentDialog.simple('messageRecipients', {
        message,
    }, t('messages:RECIPIENT', { count: message.recipients_count }));

    return service;
} ]);
