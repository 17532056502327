// @ts-nocheck
import { module } from 'angular';
import {smallDevice} from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
module('eaw.scheduling').factory('ShiftsResizable', [ 'intervalService', 'ToastService', 'scheduleMode', 'chooseBusinessDate', function(intervalService, ToastService, scheduleMode, chooseBusinessDate) {
    const service = this;
    /**
     * @param el
     * @param side - "left" or "right"
     */
    service.addHandle = (el, side) => {
        const handle = document.createElement('div');
        handle.classList.add('resize-handle', side);
        el.appendChild(handle);
        handle.addEventListener('mousedown', (e) => {
            e.stopPropagation();
            if (e.button !== 0) {
                return;
            }
            service.element = el;
            service.side = side;
            service.orgLeft = el.offsetLeft;
            service.orgWidth = Number.parseInt(el.style.width);
            service.orgRight = service.orgLeft + service.orgWidth;
            service.orgScreenX = e.screenX;
            service.start();
            document.addEventListener('mousemove', service.resize);
            document.addEventListener('mouseup', service.stop, { once: true });
        });
    };
    service.start = () => {
        document.getElementById('shifts-container').classList.add('manipulating-shift-line', 'resizing');
        service.element.classList.add('manipulated');
        service.xStep = getComputedStyle(document.body).getPropertyValue('--schedule-shift-table-cell-width').replace('px', '');
        // Time tellers
        intervalService.addTimeTellers();
    };
    service.resize = (e) => {
        const offStep = (service.side === 'left' ? service.orgLeft : service.orgRight) % service.xStep;
        let diff = Math.floor((e.screenX - service.orgScreenX) / service.xStep) * service.xStep;
        if (diff) {
            diff -= offStep;
        }
        if (service.side === 'left') {
            const left = Math.max(0, service.orgLeft + diff); // Dont go below 0
            service.element.style.left = `${left}px`;
            service.element.style.width = `${service.orgWidth - diff}px`;
        }
        if (service.side === 'right') {
            service.element.style.width = `${service.orgWidth + diff}px`;
        }
        intervalService.calculateTimeTellersTime(service.element, 0, false);
    };
    service.stop = () => {
        document.removeEventListener('mousemove', service.resize);
        document.getElementById('shifts-container').classList.remove('manipulating-shift-line', 'resizing');
        service.element.classList.remove('manipulated');
        const shift = service.schedule.getShift(service.element.dataset.shiftId);
        const copy = { ...shift };
        // Remove
        intervalService.removeTimeTellers();
        const unchangedLeft = service.orgLeft === service.element.offsetLeft;
        const unchangedRight = service.orgRight === service.element.offsetLeft + Number.parseInt(service.element.style.width);
        if (unchangedLeft && unchangedRight) {
            return;
        }
        const nearestLeftMarker = intervalService.calculateClosestMarkerOffsetFromPixels(service.element.offsetLeft) + service.schedule.render.offset;
        const nearestRightMarker = intervalService.calculateClosestMarkerOffsetFromPixels(service.element.offsetLeft + Number.parseInt(service.element.style.width)) + service.schedule.render.offset;
        // Offset already handles the RENDER
        copy.offset = unchangedLeft ? shift.offset : nearestLeftMarker;
        copy.length = (unchangedRight ? shift.offset + shift.length : nearestRightMarker) - copy.offset;
        // So only use schedule from here instead of render from because render is already accounted for
        copy.from = shift.schedule.from.clone().add(copy.offset, 's');
        copy.to = copy.from.clone().add(copy.length, 's');
        // Keep it inside
        if (copy.to.isAfter(shift.schedule.to)) {
            copy.to = copy.schedule.getTo();
            copy.length = copy.schedule.to.diff(copy.from, 's');
        }
        if (service.hasOverlappingShift(copy)) {
            return service.resetPosition();
        }
        // Try to update
        chooseBusinessDate(shift.schedule, copy).then(() => {
            shift.offset = copy.offset;
            shift.length = copy.length;
            shift.business_date = copy.business_date.clone();
            service.callback(shift).catch(() => {
                shift.reset([ 'offset', 'length' ]);
                service.resetPosition();
            });
        }).catch(() => {
            service.resetPosition();
        });
    };
    service.resetPosition = () => {
        service.element.style.left = `${service.orgLeft}px`;
        service.element.style.width = service.orgWidth ? `${service.orgWidth}` : service.element.style.width;
    };
    service.hasOverlappingShift = (shift) => {
        if (!shift.employee_id) {
            return false;
        }
        const hasOverlapping = shift.schedule.getOverlappingShifts(shift).some((s) => s);
        if (hasOverlapping) {
            ToastService.tToast('scheduling:OVERLAPPING_SHIFT');
        }
        return hasOverlapping;
    };
    service.enable = (schedule, callback) => {
        if (smallDevice()) {
            return;
        }
        if (schedule.original) {
            return;
        }
        if (scheduleMode.getMode() !== 'select') {
            return;
        }
        service.callback = callback;
        service.schedule = schedule;
        Array.from(document.getElementsByClassName('shift-line')).forEach((el) => {
            service.addHandle(el, 'left');
            service.addHandle(el, 'right');
        });
    };
    return service;
} ]);
