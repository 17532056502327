// @ts-nocheck
import { module } from 'angular';
const template2 = `<md-dialog>
    <eaw-dialog-header-old heading="'Create time series'"></eaw-dialog-header-old>

    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="newTimeSeriesForm">
                <md-input-container class="error-spacer">
                    <label ng-i18next="NAME"></label>
                    <input ng-model="ctrl.name" md-maxlength="100" name="name" ng-maxlength="100" uniq-form-name required>
                    <div ng-messages="newTimeSeriesForm.name.$error">
                        <div ng-message="required" ng-i18next="errors:FORM_FIELD_REQUIRED"></div>
                        <div ng-message="maxlength">Name is too long</div>
                    </div>
                </md-input-container>

                <md-input-container>
                    <label>Time series type</label>
                    <md-select ng-model="ctrl.type">
                        <md-option ng-repeat="(value, type) in ctrl.constants.time_series_types" ng-value="value">{{type}}</md-option>
                    </md-select>
                </md-input-container>

                <md-input-container>
                    <label>Interval</label>
                    <md-select ng-model="ctrl.interval">
                        <md-option ng-repeat="(value, interval) in ctrl.constants.intervals" ng-value="value">{{interval}}</md-option>
                    </md-select>
                </md-input-container>

                <md-input-container>
                    <label>Value type</label>
                    <md-select ng-model="ctrl.valueType">
                        <md-option ng-repeat="(value, type) in ctrl.constants.value_types" ng-value="value">{{type}}</md-option>
                    </md-select>
                </md-input-container>

                <md-input-container>
                    <label>Aggregate_method</label>
                    <md-select ng-model="ctrl.aggregateMethod">
                        <md-option ng-repeat="(value, method) in ctrl.constants.aggregate_methods" ng-value="value">{{method}}</md-option>
                    </md-select>
                </md-input-container>

                <md-input-container>
                    <label>Customer hidden</label>
                    <md-select ng-model="ctrl.customerHidden">
                        <md-option ng-value="true">Yes</md-option>
                        <md-option ng-value="false">No</md-option>
                    </md-select>
                </md-input-container>

                <eaw-object-creator label="'Tags'" ng-model="ctrl.tags"></eaw-object-creator>

                <md-input-container class="error-spacer">
                    <label>Variable uuid</label>
                    <input ng-model="ctrl.variableUuid" uniq-form-name required>
                </md-input-container>

                <md-input-container class="md-block">
                    <label ng-i18next="DESCRIPTION"></label>
                    <textarea ng-model="ctrl.description" rows="2"></textarea>
                </md-input-container>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
        <md-button form="newTimeSeriesForm" class="md-raised md-accent" ng-disabled="newTimeSeriesForm.$invalid" ng-click="ctrl.submit()" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

import MlVariable from '../../../../../../../../ai-budgeting/angularjs/shared/ml-variable';

module('eaw.admin').component('adminCustomerMlBucketTimeSeries', {
    template: `<md-tab label="Time series" md-on-select="$bucketTimeSeries.getTimeSeries()">
    <md-content>
        <div layout-margin layout="row">
            <md-input-container md-no-float>
                <input placeholder="Search" ng-model="$bucketTimeSeries.filterText" ng-change="$bucketTimeSeries.filter()" uniq-form-name>
            </md-input-container>

            <md-divider class="tw-ml-20 tw-mr-20"></md-divider>

            <md-button class="new-time-series-btn md-icon-button md-accent md-raised" ng-click="$bucketTimeSeries.createTimeSeries()">
                <md-icon ng-bind="'add'"></md-icon>
            </md-button>
        </div>

        <table class="table table-striped" ng-show="$bucketTimeSeries.bucket.variables.length">
            <thead>
            <tr>
                <th>Uuid</th>
                <th>Name</th>
                <th>Code</th>
                <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="variable in $bucketTimeSeries.filteredVariables">
                <td md-highlight-text="$bucketTimeSeries.filterText" md-highlight-flags="gi">{{variable.id}}</td>
                <td md-highlight-text="$bucketTimeSeries.filterText" md-highlight-flags="gi">{{variable.name}}</td>
                <td md-highlight-text="$bucketTimeSeries.filterText" md-highlight-flags="gi">{{variable.code}}</td>
                <td md-highlight-text="$bucketTimeSeries.filterText" md-highlight-flags="gi">{{variable.description}}</td>
            </tr>
            </tbody>
        </table>

        <div layout="column" layout-align="center center" class="no-variables" ng-if="!$bucketTimeSeries.bucket.variables.length">
            <md-icon ng-class="{'variable-rotate': $bucketTimeSeries.gettingVars}" ng-bind="'data_object'" class="s36"></md-icon>
            <span ng-if="$bucketTimeSeries.gettingVars">Fetching variables</span>
            <span ng-if="!$bucketTimeSeries.gettingVars">This bucket doesn't have any variables</span>
        </div>
    </md-content>
</md-tab>
`,
    bindings: {
        customer: '<',
        bucket: '<',
    },
    controllerAs: '$bucketTimeSeries',
    controller: [ 'mlVariableFactory', '$mdDialog', 'mlTimeSeriesFactory', function(mlVariableFactory, $mdDialog, mlTimeSeriesFactory) {
        const ctrl = this;

        ctrl.getVariables = () => {
            ctrl.gettingVars = true;
            mlVariableFactory.getAll.query(ctrl.customer.id, ctrl.bucket.id, { per_page: 9999 }).then((resp) => {
                delete ctrl.gettingVars;
                resp.data.forEach((d) => ctrl.bucket.addVariable(new MlVariable(d)));
                ctrl.filter();
            });
        };

        ctrl.filter = () => {
            const filter = ctrl.filterText?.toLowerCase();
            ctrl.filteredBuckets = !filter ? ctrl.bucket.variables : ctrl.bucket.variables.filter((b) => {
                return [ 'uuid', 'name', 'description', 'code' ].some((k) => b[k]?.toLowerCase().includes(filter));
            });
        };

        ctrl.createTimeSeries = () => $mdDialog.show({
            template: template2,
            controller: 'createMlTimeSeriesDialog',
            controllerAs: 'ctrl',
            bindToController: true,
            resolve: {
                /** @ngInject */
                constants: function() {
                    return mlTimeSeriesFactory.getConstants.query();
                },
            },
            locals: {
                customer: ctrl.customer,
                bucket: ctrl.bucket,
            },
        });
    } ],
});
