import { Component, OnInit, signal } from '@angular/core';
import type { DataTableTextColumn } from '../../types/data-table-text-column';
import { DataTablePortalComponent } from '../../types/data-table-portal-component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'eaw-text-portal',
    templateUrl: './text-portal.component.html',
    styleUrl: './text-portal.component.scss',
    standalone: true,
    imports: [ NgIf, MatProgressSpinnerModule ],
})
export class TextPortalComponent<Item> extends DataTablePortalComponent<Item, unknown, DataTableTextColumn<Item>> implements OnInit {
    text = signal<string>('');
    subtext = signal<undefined | string>(undefined);
    loading = signal(false);

    constructor() {
        super();
    }

    async ngOnInit() {
        this.loading.set(this.cell.column.loading?.(this.cell) || false);
        this.text.set(await this.getValue<string>() || '');
        this.subtext.set(await this.cell.column.subText?.(this.cell));
    }
}
