export interface NotificationTypeData {
    class: string;
    route?: string;
    /**
     * Parameters used for the route
     * Key is that the parameter is called in the state
     * Value = path to the relevant key value our notification ex. 'message.id' OR 'schedule_id'
     * customer_id param is automatically handled in routing function, no need to at it in our param object here
     */
    params?: Record<string, any>;
}

export class NotificationType {
    readonly class: string;
    readonly route: string | undefined;
    readonly params: Record<string, string> | undefined;

    private constructor(public readonly key: string, data: NotificationTypeData) {
        this.route = data.route;
        this.params = data.params;
        this.class = data.class;
    }

    toString() {
        return this.key;
    }

    getParams(data: Record<string, any>, currentCustomerId: number | string | null): Record<string, any> {
        let customerId = data['customer_id'] ?? null;
        const otherParams = this.params ?? {};

        if (customerId && currentCustomerId && currentCustomerId == customerId) {
            customerId = null;
        }

        const params: Record<string, any> = { customer: customerId };

        Object.entries(otherParams).forEach(([ key, value ]) => {
            params[key] = data[value];
        });

        return params;
    }

    static get(klass: string): NotificationType | undefined {
        return Object.values(NotificationType).find((type: NotificationType) => {
            return type.class === klass;
        });
    }

    static readonly absence_for_employee_approved = new NotificationType('absence_for_employee_approved', {
        class: 'Modules\\Absence\\Notifications\\AbsenceForEmployeeApproved',
        route: 'eaw/app/absence/my',
    });

    static readonly absence_processed = new NotificationType('absence_processed', {
        class: 'Modules\\Absence\\Notifications\\AbsenceProcessed',
        route: 'eaw/app/absence/my',
    });

    static readonly absence_request = new NotificationType('absence_request', {
        class: 'Modules\\Absence\\Notifications\\AbsenceRequest',
        route: 'eaw/app/absence/list',
    });

    static readonly access_to_customer = new NotificationType('access_to_customer', {
        class: 'App\\Notifications\\AccessToCustomer',
        route: 'eaw/app/home',
    });

    static readonly approved_your_timepunches = new NotificationType('approved_your_timepunches', {
        class: 'Modules\\Payroll\\Notifications\\ApprovedYourTimepunches',
        route: `eaw/app/payroll/my/timepunches`,
    });

    static readonly available_shift = new NotificationType('available_shift', {
        class: 'Modules\\Scheduling\\Notifications\\AvailableShift',
        route: 'eaw/app/scheduling/shifts/available_shifts',
    });

    static readonly backoffice_budget_imported = new NotificationType('backoffice_budget_imported', {
        class: `Modules\\BackOffice\\Notifications\\BudgetImported`,
        route: 'eaw/app/scheduling/schedules/list/view',
        params: {
            id: 'schedule_id',
        },
    });

    static readonly closed_checklist = new NotificationType('closed_checklist', {
        class: 'Modules\\Checklist\\Notifications\\ClosedChecklist',
        route: 'eaw/app/checklists/list',
    });

    static readonly created_user = new NotificationType('created_user', {
        class: 'App\\Notifications\\CreatedUser',
    });

    static readonly create_paid_time_failed = new NotificationType('create_paid_time_failed', {
        class: 'Modules\\PaidTime\\Notifications\\CreatePaidTimeFailed',
        route: 'eaw/app/payroll/paid_time/day',
    });

    static readonly incoming_swap = new NotificationType('incoming_swap', {
        class: 'Modules\\Scheduling\\Notifications\\IncomingSwap',
        route: 'eaw/app/scheduling/swaps/handle',
    });

    static readonly many_absences_of_type = new NotificationType('many_absences_of_type', {
        class: 'Modules\\Absence\\Notifications\\ManyAbsencesOfType',
        route: 'eaw/app/absence/list',
    });

    static readonly new_checklist = new NotificationType('new_checklist', {
        class: 'Modules\\Checklist\\Notifications\\NewChecklist',
        route: 'eaw/app/checklists/list',
    });

    static readonly new_hr_file = new NotificationType('new_hr_file', {
        class: 'Modules\\Hr\\Notifications\\NewHrFile',
        route: 'eaw/app/hr/my_files',
    });

    static readonly schedule_instructions_completed = new NotificationType('schedule_instructions_completed', {
        class: `Modules\\AutoSched\\Notifications\\ScheduleInstructionsCompleted`,
        route: 'eaw/app/scheduling/schedules/list/view',
        params: {
            id: 'schedule_id',
        },
    });

    static readonly schedule_declined = new NotificationType('schedule_declined', {
        class: `Modules\\Scheduling\\Notifications\\ScheduleDeclined`,
        route: 'eaw/app/scheduling/schedules/list/view',
        params: {
            id: 'schedule_id',
        },
    });

    static readonly schedule_ready = new NotificationType('schedule_ready', {
        class: `Modules\\Scheduling\\Notifications\\ScheduleReady`,
        route: 'eaw/app/scheduling/schedules/list/view',
        params: {
            id: 'schedule_id',
        },
    });

    static readonly published_schedule = new NotificationType('published_schedule', {
        class: `Modules\\Scheduling\\Notifications\\PublishedSchedule`,
        route: 'eaw/app/scheduling/schedules/list/view',
        params: {
            id: 'schedule_id',
        },
    });

    static readonly new_offtime_request = new NotificationType('new_offtime_request', {
        class: `Modules\\Vacation\\Notifications\\NewOffTimeRequest`,
        route: 'eaw/app/vacations/manage/handle',
    });

    static readonly newsletter_published = new NotificationType('newsletter_published', {
        class: 'Modules\\Newsletters\\Notifications\\NewsletterPublished',
        route: 'eaw/app/newsletter',
    });

    static readonly off_time_request_handled = new NotificationType('off_time_request_handled', {
        class: 'Modules\\Vacation\\Notifications\\OffTimeRequestHandled',
        route: 'eaw/app/vacations/request/list',
    });

    static readonly oje_absence_registered = new NotificationType('oje_absence_registered', {
        class: 'Modules\\SwissRiskAndCare\\Notifications\\OjeAbsenceRegistered',
        route: 'eaw/app/absence/list',
    });

    static readonly password_reset = new NotificationType('password_reset', {
        class: 'App\\Notifications\\PasswordReset',
        route: 'eaw/outside/reset_password',
        params: {
            token: 'token',
        },
    });

    static readonly payroll_period_locked = new NotificationType('payroll_period_locked', {
        class: 'Modules\\PeriodLocks\\Notifications\\PayrollPeriodLocked',
        route: 'eaw/app/payroll/period_locks',
    });

    static readonly form_submission = new NotificationType('form_submission', {
        class: 'Modules\\Forms\\Notifications\\FormSubmissionNotification',
        route: 'eaw/app/forms/submissions',
        params: {
            id: 'form_id',
        },
    });

    static readonly file_about_to_expire = new NotificationType('file_about_to_expire', {
        class: 'Modules\\Hr\\Notifications\\FileAboutToExpire',
        route: 'eaw/app/hr/my_files',
    });

    static readonly message_reminder = new NotificationType('message_reminder', {
        class: 'Modules\\Messaging\\Notifications\\MessageReminder',
        route: 'eaw/app/messages/conversations/write',
    });

    static readonly new_message = new NotificationType('new_message', {
        class: 'Modules\\Messaging\\Notifications\\NewMessage',
        route: 'eaw/app/messages/conversations/thread',
        params: {
            id: 'message.id',
        },
    });

    static readonly reminder_to_approve_timepunches = new NotificationType('reminder_to_approve_timepunches', {
        class: 'Modules\\Payroll\\Notifications\\ReminderToApproveTimepunches',
        route: 'eaw/app/payroll/timepunches/all/manage',
    });

    static readonly approved_timepunches_done = new NotificationType('approved_timepunches_done', {
        class: 'Modules\\Payroll\\Notifications\\ApprovedTimepunchesDone',
        route: 'eaw/app/payroll/timepunches/all',
    });

    static readonly approved_timepunches_failed = new NotificationType('approved_timepunches_failed', {
        class: 'Modules\\Payroll\\Notifications\\ApprovedTimepunchesFailed',
        route: 'eaw/app/payroll/timepunches/all',
    });

    static readonly timepunch_deleted = new NotificationType('timepunch_deleted', {
        class: 'Modules\\Payroll\\Notifications\\TimepunchDeleted',
        route: 'eaw/app/payroll/my',
    });

    static readonly timepunch_edited = new NotificationType('timepunch_edited', {
        class: 'Modules\\Payroll\\Notifications\\TimepunchEdited',
        route: 'eaw/app/payroll/my',
    });

    static readonly new_postit = new NotificationType('new_postit', {
        class: 'Modules\\Postits\\Notifications\\NewPostit',
        route: 'eaw/app/home',
    });

    static readonly report_failed = new NotificationType('report_failed', {
        class: `Modules\\Reports\\Notifications\\ReportFailed`,
        route: 'eaw/app/notifications',
    });

    static readonly report_ready = new NotificationType('report_ready', {
        class: `Modules\\Reports\\Notifications\\ReportReady`,
        route: 'eaw/app/reports',
        params: {
            report: 'report_id',
        },
    });

    static readonly changed_shift = new NotificationType('changed_shift', {
        class: `Modules\\Scheduling\\Notifications\\ChangedShift`,
        route: 'eaw/app/scheduling/shifts/shift',
        params: {
            id: 'shift_id',
            customer_id: 'customer_id',
        },
    });

    static readonly changed_of_created_shift = new NotificationType('changed_or_created_shift', {
        class: `Modules\\Scheduling\\Notifications\\ChangedOrCreatedShift`,
        route: 'eaw/app/scheduling/shifts/shift',
        params: {
            id: 'shift_id',
            customer_id: 'customer_id',
        },
    });

    static readonly src_submitted_absence = new NotificationType('src_submitted_absence', {
        class: 'Modules\\SwissRiskAndCare\\Notifications\\SrcSubmittedAbsence',
    });

    static readonly shift_commented = new NotificationType('shift_commented', {
        class: `Modules\\Scheduling\\Notifications\\ShiftCommented`,
        route: 'eaw/app/scheduling/shifts/shift',
        params: {
            customer_id: 'customer_id',
            id: 'shift_id',
        },
    });

    static readonly swap_applied_for = new NotificationType('swap_applied_for', {
        class: `Modules\\Scheduling\\Notifications\\SwapAppliedFor`,
        route: 'eaw/app/scheduling/swaps/handle',
    });

    static readonly swap_declined = new NotificationType('swap_declined', {
        class: `Modules\\Scheduling\\Notifications\\SwapDeclined`,
        params: {
            id: 'swap_id',
        },
    });

    static readonly swap_application_dismissed = new NotificationType('swap_application_dismissed', {
        class: `Modules\\Scheduling\\Notifications\\SwapApplicationDismissed`,
    });

    static readonly swap_to = new NotificationType('swap_to', {
        class: `Modules\\Scheduling\\Notifications\\SwapTo`,
        route: 'eaw/app/scheduling/swaps/handle',
    });

    static readonly todo_item_notification = new NotificationType('todo_item_notification', {
        class: 'Modules\\Todo\\Notifications\\TodoItemNotification',
        route: 'eaw/app/',
        params: {
            item: 'item_id',
        },
    });

    static readonly updated_absence = new NotificationType('updated_absence', {
        class: 'Modules\\Absence\\Notifications\\UpdatedAbsence',
        route: 'eaw/app/absence/list',
    });
}
