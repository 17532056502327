import { Component, Inject, signal } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { EawUrl } from '../../../shared/angularjs/modules/resource/url.service';
import { UIRouter } from '@uirouter/core';
import { EawValidators } from '../../../shared/validators/eaw-validators';
import { from, switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent, AlertDialogData } from '../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { PasswordComponent } from '../../components/password/password.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LogoComponent } from '../../../shared/components/logo/logo.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'eaw-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: [ './reset-password.component.scss', '../../../../styles/login.scss' ],
    standalone: true,
    imports: [
        MatCardModule,
        LogoComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        PasswordComponent,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ResetPasswordComponent {
    form = new UntypedFormGroup({
        email: new FormControl('', [ EawValidators.email() ]),
        password: new FormControl([ '', '' ], [ Validators.required ]),
    });

    submitting = false;

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(UIRouter) protected uiRouter: UIRouter,
        @Inject(MatDialog) protected dialog: MatDialog,
        @Inject(TranslateService) protected translate: TranslateService,
    ) {
    }

    submit() {
        const form = this.form.value;
        this.submitting = true;

        this.http.post(`${EawUrl.url}/users/reset_password`, {
            email: form.email,
            password: form.password[0],
            password_confirmation: form.password[1],
            token: this.uiRouter.globals.params['token'],
        }).pipe(
            switchMap(() => from(this.dialog.open<AlertDialogComponent, AlertDialogData, undefined>(AlertDialogComponent, {
                data: {
                    title: signal(this.translate.t('PASSWORD', 'general')),
                    text: signal(this.translate.t('SUCCESSFULLY_CHANGED_PASSWORD', 'login')),
                },
            }).afterClosed())),
            switchMap(() => from(this.uiRouter.stateService.go('eaw/outside/login', { email: form.email }))),
        ).subscribe({
            error: (e) => {
                this.submitting = false;
                console.error('error', e);
            },
        });
    }
}
