import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { WidgetSettingsDialog } from '../../../classes/widget-settings-dialog';
import { TranslateService } from '../../../../shared/services/translate.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { WidgetPropertySettings } from '../../../classes/widget';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatOptionModule } from '@angular/material/core';
import { AsyncPipe, NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { WidgetSettingsDialogComponent } from '../../widget-settings-dialog/widget-settings-dialog.component';
import { PermissionCheckService } from '../../../../shared/services/permission-check.service';
import { forkJoin, map, tap } from 'rxjs';
import { InfoLoadingComponent } from '../../../../shared/components/info-loading/info-loading.component';
import { CurrentService } from '../../../../shared/services/current.service';
import { CustomerProductService } from '../../../../shared/http/customer-product.service';
import { Products } from '../../../../shared/enums/products';
import { ApiModel } from '../../../../shared/enums/api-model';

export interface HoursWorkedWidgetSettings extends WidgetPropertySettings {
    /**
     * This setting is called "month" but should be renamed to "period" or something similar
     * because it can also be a week. But when this was first created we did not have the week option.
     */
    month: 'this' | 'previous' | 'week';
    basis: 'paid_time' | 'timepunches' | '';
}

interface MonthType {
    value: HoursWorkedWidgetSettings[ 'month' ];
    text: Promise<string>;
}

@Component({
    selector: 'eaw-hours-worked-widget-settings-dialog',
    templateUrl: './hours-worked-widget-settings-dialog.component.html',
    styleUrl: './hours-worked-widget-settings-dialog.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        WidgetSettingsDialogComponent,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgFor,
        MatOptionModule,
        AsyncPipe,
        TranslatePipe,
        InfoLoadingComponent,
    ],
})
export class HoursWorkedWidgetSettingsDialogComponent extends WidgetSettingsDialog<HoursWorkedWidgetSettings> implements OnInit {
    private readonly translate = inject(TranslateService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly current = inject(CurrentService);
    private readonly productCheckService = inject(CustomerProductService);

    months = signal<MonthType[]>([]);
    basisOptions = signal<{ value: string; label: Promise<string> }[]>([]);
    loading = signal(true);
    form: FormGroup<{
        month: FormControl<HoursWorkedWidgetSettings[ 'month' ]>,
        basis: FormControl<HoursWorkedWidgetSettings['basis']>
    }>;

    constructor() {
        super();

        this.months.set([
            { value: 'this', text: this.translate.t('THIS_MONTH', 'widgets') },
            { value: 'previous', text: this.translate.t('PREVIOUS_MONTH', 'widgets') },
            { value: 'week', text: this.translate.t('THIS_WEEK', 'widgets') },
        ]);

        this.basisOptions.set([
            { value: 'timepunches', label: this.translate.t('TIMEPUNCH_plural') },
        ]);

        this.form = new FormGroup({
            month: new FormControl<HoursWorkedWidgetSettings[ 'month' ]>('this', { nonNullable: true, validators: Validators.required }),
            basis: new FormControl<HoursWorkedWidgetSettings['basis']>('', { nonNullable: true, validators: Validators.required }),
        });
    }

    ngOnInit() {
        const customerId = this.current.getCustomer().id;

        forkJoin([
            this.productCheckService.hasProducts(customerId, [ Products.PaidTime ]),
            this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].employees.{employee}.paid_times.*.get`, { models: [ { type: ApiModel.Customer, id: customerId } ] }),
        ]).pipe(
            map(([ hasPaidTime, canGetPaidTime ]) => hasPaidTime && canGetPaidTime),
            tap((can) => {
                if (can) {
                    this.basisOptions.update((options) => [ ...options, { value: 'paid_time', label: this.translate.t('PAID_TIME_plural') } ]);
                } else {
                    this.form.controls.basis.disable();
                    this.form.patchValue({ basis: 'timepunches' });
                }

                this.loading.set(false);
            }),
        ).subscribe();
    }

    submitted() {
        const value = this.form.getRawValue();

        if (value.month && value.basis) {
            this.dialogRef.close({
                month: value.month,
                basis: value.basis,
            });
        }
    }
}
