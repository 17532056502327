// @ts-nocheck
import { module } from 'angular';
module('eaw.admin').factory('LocaleService', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const res = EawResource.create('/locales/:locale');
    return {
        getAll: (args) => res.get({ ...Pagination.getParams(args) }),
        updateByCode: (code, locale) => res.update({
            locale: code,
        }, locale),
        deleteLocale: (locale) => res.delete({
            locale: locale.code,
        }),
        create: (localeObject) => res.save(localeObject),
    };
} ]);
