// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';
module('eaw.alerts').factory('deleteItemAlert', [ '$mdDialog', function($mdDialog) {
    // @ts-ignore
    const service = this;
    /**
     * @param   {string}  [title]         - WTI key
     * @param   {object}  [titleVars]   - object containing variables for WTI key
     * @returns {promise}
     */
    service.alertSimple = (title, titleVars) => {
        title = title || 'DELETE';
        titleVars = titleVars || {};
        return $mdDialog.show($mdDialog.confirm()
            .multiple(true)
            .theme('delete')
            .title(t(title, titleVars))
            .ok(t('DELETE'))
            .cancel(t('CANCEL')));
    };
    /**
     * @param   {string}  title         - WTI key
     * @param   {object}  titleVars     - object containing variables for WTI key
     * @param   {string}  content       - same as title
     * @param   {object}  [contentVars] - same as title
     * @returns {promise}
     */
    service.alert = (title, titleVars, content, contentVars) => {
        titleVars = titleVars || {};
        contentVars = contentVars || {};
        return $mdDialog.show($mdDialog.confirm()
            .theme('delete')
            .title(t(title, titleVars))
            .textContent(t(content, contentVars))
            .ok(t('DELETE'))
            .cancel(t('CANCEL')));
    };
    return service;
} ]);
