<eaw-widget-settings-dialog [widgetInfo]="data.widgetInfo" (submitted)="submitted()" [valid]="fahrenheitCtrl.valid">
    <mat-form-field content class="tw-w-full">
        <mat-label>
            {{ 'UNIT' | translate: 'widgets' | async }}
        </mat-label>
        <mat-select [formControl]="fahrenheitCtrl">
            <mat-option [value]="true">°F</mat-option>
            <mat-option [value]="false">°C</mat-option>
        </mat-select>
    </mat-form-field>
</eaw-widget-settings-dialog>
