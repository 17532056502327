// @ts-nocheck
import { module } from 'angular';

import { PaginationOld } from '../../../shared/utils/pagination-old';
import { Namespace } from '../../../shared/enums/namespace';
import { CurrentService } from '../../../shared/services/current.service';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.chain-ops.user-groups', [
    'eaw.misc',
    'eaw.user-groups',
]).config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state('eaw/app/chain_ops/user_groups', {
        parent: `eaw/app/chain_ops`,
        url: '/groups',
        views: {
            'content@': 'chainTabs',
        },
        resolve: {
            customerGroups: [ 'customerCustomerGroupFactory', 'customer', function(customerCustomerGroupFactory, customer) {
                return PaginationOld.getData(new PaginationOld({
                    page: 1,
                    with: [ 'activeMemberships.member' ],
                }), (pagination) => customerCustomerGroupFactory.getAll.query(customer.id, pagination).$promise).then((data) => {
                    if (!data.length) {
                        void ToastService.t('NO_CUSTOMER_plural', Namespace.ChainOps);
                        return Promise.reject();
                    }
                    // Only show what we are allowed to see
                    data.forEach((customerGroup) => {
                        customerGroup.active_memberships = customerGroup.active_memberships.filter((group) => {
                            const canGetGroup = CurrentOld.can(`customers.${group.member_id}.user_groups.*.get`);
                            const canGetUsers = CurrentOld.can(`customers.${group.member_id}.users.*.get`);
                            return canGetGroup && canGetUsers;
                        });
                    });

                    return data;
                });
            } ],
            sref: function() {
                return 'eaw/app/chain_ops/user_groups/list';
            },
        },
    });
    $stateProvider.state('eaw/app/chain_ops/user_groups/list', {
        parent: 'eaw/app/chain_ops/user_groups',
        url: '?group?mode',
        views: {
            chainTabsView: 'chainUserGroups',
        },
        data: {
            breadcrumb: { key: 'USER_GROUPS', ns: 'navigation' },
        },
        params: {
            // customer group id
            group: {
                type: 'int',
                dynamic: false,
            },
            mode: {
                value: null,
                squash: true,
                type: 'bool',
                dynamic: true,
            },
        },
        resolve: {
            customerGroup: [ 'customerGroups', '$transition$', function(customerGroups, $transition$) {
                const id = $transition$.params().group;
                return customerGroups.find((group) => group.id == id);
            } ],
        },
    });
} ]);
