import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { Signature, SignatureResponse } from '../../shared/models/signature';
import { Property, PropertyResponse } from '../../shared/models/property';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { HrFileType, HrFileTypeResponse } from './hr-file-type';

export interface HrFileResponse extends ApiResponse {
    employee_id: number;
    expires_at?: string | null;
    has_form_fields: boolean;
    id: number;
    name: string;
    notify: boolean;
    properties?: PropertyResponse[];
    responsible_id: number;
    signatures?: SignatureResponse[];
    type_id: number;
    type?: HrFileTypeResponse;
}

export class HrFile {
    employeeId: number;
    expiresAt: DateTime | null;
    hasFormFields: boolean;
    id: number;
    name: string;
    notify: boolean;
    properties?: Property[];
    responsibleId: number;
    signatures: Signature[];
    typeId: number;
    isSigned: boolean;
    defaultFileId?: number;
    type?: HrFileType;

    constructor(data: HrFileResponse) {
        this.employeeId = data.employee_id;
        this.expiresAt = data.expires_at ? stringToDateTime(data.expires_at) : null;
        this.hasFormFields = data.has_form_fields;
        this.id = data.id;
        this.name = data.name;
        this.notify = data.notify;
        this.properties = data.properties?.map((p) => new Property(p));
        this.responsibleId = data.responsible_id;
        this.signatures = data.signatures?.map((s) => new Signature(s)) || [];
        this.typeId = data.type_id;
        this.isSigned = !!this.signatures?.length;
        this.defaultFileId = this.properties?.find((prop) => prop.key === 'default_hr_document')?.value.asInteger() ?? undefined;
        if (data.type) {
            this.type = new HrFileType(data.type);
        }
    }
}
