import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ShiftSwapService } from '../../http/shift-swap.service';
import { SwapApplicantService } from '../../http/swap-applicant.service';
import { MatDialog } from '@angular/material/dialog';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { ShiftSwap } from '../../models/shift-swap';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { DataTableButton,
    DataTableButtonCell,
    DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { User } from '../../../shared/models/user';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { EMPTY, of, shareReplay, switchMap } from 'rxjs';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { ApplyForSwapDialogComponent, ApplyToSwapData } from '../../dialogs/apply-for-swap-dialog/apply-for-swap-dialog.component';
import { CommentDialogComponent, CommentDialogData } from '../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-available-shifts',
    templateUrl: './available-shifts.component.html',
    styleUrl: './available-shifts.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AvailableShiftsComponent implements OnInit, EawDataTable<ShiftSwap> {
    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) user!: User;
    @Input() employeeId?: number;

    @ViewChild(DataTableComponent) dataTable?: DataTableComponent<ShiftSwap>;

    columns: DataTableColumnType<ShiftSwap>[] = [];
    request: DataTableRequest = of(mockArrayPaginatedResponse());

    constructor(
        @Inject(ShiftSwapService) protected shiftSwapService: ShiftSwapService,
        @Inject(SwapApplicantService) protected swapApplicantService: SwapApplicantService,
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(ConfirmDialogService) protected confirmDialog: ConfirmDialogService,
        @Inject(TranslateService) protected translate: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.columns = this.createColumns();
    }

    private createColumns(): DataTableColumnType<ShiftSwap>[] {
        let buttons: DataTableButton<ShiftSwap>[] = [
            {
                show: () => of(true),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                hide: (swap) => of(!swap.comments!.length && !swap.shift!.comments!.length),
                icon: 'comment',
                tooltip: { key: 'COMMENT_plural' },
                nonBlocking: true,
                click: this.comments.bind(this),
            },
        ];

        if (this.employeeId) {
            buttons = buttons.concat([ {
                hide: (swap) => {
                    const a = swap.fromEmployee?.id == this.employeeId;
                    const b = !!swap.applicants?.find((a) => a.employeeId == this.employeeId);

                    return of(a || b);
                },
                show: () => of(this.employeeId != null),
                icon: 'swap_horiz',
                tooltip: { key: 'APPLY_FOR_SHIFT', ns: 'scheduling' },
                click: this.applyForShift.bind(this),
            }, {
                show: () => of(this.employeeId != null),
                hide: (swap) => {
                    const a = swap.fromEmployee?.id == this.employeeId;
                    const b = !swap.validApplicants?.find((a) => a.employeeId == this.employeeId);

                    return of(a || b);
                },
                icon: 'delete',
                type: 'warn',
                tooltip: { key: 'UNAPPLY_FOR_SHIFT', ns: 'scheduling' },
                click: this.removeApplicant.bind(this),
            },
            ]);
        }

        buttons = buttons.concat([ {
            show: this.canDeclineSwap.bind(this),
            hide: (swap) => of(!swap.fromId),
            icon: 'close',
            type: 'warn',
            tooltip: { key: 'DECLINE_REQUEST', ns: 'scheduling' },
            click: this.decline.bind(this),
        } ]);

        return [
            new DataTableTextColumn<ShiftSwap>({
                header: new DataTableHeader({
                    i18n: 'EMPLOYEE',
                    ns: 'general',
                }),
                value: (cell) => {
                    return cell.item.fromEmployee?.name || this.translate.t('UNASSIGNED');
                },
            }),
            new DataTableTextColumn<ShiftSwap>({
                header: new DataTableHeader({
                    i18n: 'PERIOD_plural',
                    ns: 'scheduling',
                }),
                value: (cell) => {
                    return cell.item.shift?.periods.filter((p) => !p.break).map((p) => p.description).join(', ') ?? '';
                },
            }),
            new DataTableTextColumn<ShiftSwap>({
                header: new DataTableHeader({
                    i18n: 'QUALIFICATION_plural',
                    ns: 'general',
                }),
                value: (cell) => {
                    return cell.item.shift?.qualifications?.map((q) => q.name).join(', ') ?? '';
                },
            }),
            new DataTableTextColumn<ShiftSwap>({
                header: new DataTableHeader({
                    i18n: 'SCHEDULE',
                    ns: 'scheduling',
                }),
                value: (cell) => {
                    return cell.item.shift?.schedule?.name ?? '';
                },
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({
                    i18n: 'WEEK',
                    ns: 'general',
                }),
                format: 'W',
                value: (cell) => {
                    return cell.item.shift?.from;
                },
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({
                    i18n: 'FROM',
                    ns: 'general',
                    sortBy: 'from',
                }),
                format: DateTime.DATETIME_SHORT,
                value: (cell) => {
                    return cell.item.shift?.from;
                },
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({
                    i18n: 'TO',
                    ns: 'general',
                }),
                format: DateTime.TIME_SIMPLE,
                value: (cell) => {
                    return cell.item.shift?.to;
                },
            }),
            new DataTableNumberColumn({
                header: new DataTableHeader({
                    i18n: 'APPLICANT_plural',
                    ns: 'scheduling',
                }),
                value: (cell) => {
                    return cell.item.validApplicants.length;
                },
            }),

            new DataTableButtonColumn<ShiftSwap>({ buttons }),
        ];
    }

    canDeclineSwap(swap: ShiftSwap) {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.shift_swaps.${swap.id}.update`);
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.shiftSwapService.getAll(this.customerId, {
            ...pagination,
            can_apply: true,
            handled: false,
            'with[]': [
                'fromEmployee',
                'toEmployee',
                'comments.user',
                'applicants',
                'shift.schedule',
                'shift.comments.user',
                'shift.qualifications',
                'shift.periods',
            ],
        }).pipe(shareReplay({ refCount: true }));
    }

    comments(cell: DataTableButtonCell<ShiftSwap>) {
        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                comments: of(cell.item.comments || []),
            },
        });
    }

    applyForShift(cell: DataTableButtonCell<ShiftSwap>) {
        const employeeId = this.employeeId;
        if (!employeeId) {
            return;
        }

        cell.disabled.set(false);

        this.matDialog.open<ApplyForSwapDialogComponent, ApplyToSwapData>(ApplyForSwapDialogComponent, {
            data: {
                swap: cell.item,
            },
        }).afterClosed().pipe(
            switchMap((result: boolean | { comment: string }) => {
                if (!result) {
                    return EMPTY;
                }
                return this.swapApplicantService.addApplicant(cell.item.customerId, cell.item.id, employeeId);
            }),
            switchMap(this.update.bind(this)),
        ).subscribe(this.getCellSubscriber(cell));
    }

    decline(cell: DataTableButtonCell<ShiftSwap>) {
        cell.disabled.set(false);

        this.confirmDialog.delete({
            title: this.translate.t('DECLINE_REQUEST'),
            text: this.translate.t('DECLINE_AVAILABLE_SHIFT', 'scheduling', {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                date: cell.item.shift!.from?.toLocaleString(DateTime.DATETIME_MED),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                location: cell.item.shift!.schedule!.customer!.name,
            }),
            confirmText: this.translate.t('DECLINE'),
            confirmColor: 'red-500',
            comment: { include: true },
        }).afterClosed().pipe(
            switchMap((result) => {
                if (!result?.ok) {
                    return EMPTY;
                }

                return this.shiftSwapService.update(cell.item.customerId, cell.item.id, { approved: false });
            }),
            switchMap(this.update.bind(this)),
        ).subscribe(this.getCellSubscriber(cell));
    }

    removeApplicant(cell: DataTableButtonCell<ShiftSwap>) {
        const employeeId = this.employeeId;
        if (!employeeId) {
            return;
        }

        this.confirmDialog.open({
            title: this.translate.t('UNAPPLY_FOR_SHIFT', 'scheduling'),
            text: this.translate.t('UNAPPLY_FOR_SHIFT_TEXT', 'scheduling', {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                date: cell.item.shift!.from?.toLocaleString(DateTime.DATETIME_MED),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                location: cell.item.shift!.schedule!.customer!.name,
            }),
            confirmText: this.translate.t('UNAPPLY_FOR_SHIFT', 'scheduling'),
            confirmColor: 'red-500',
        }).afterClosed().pipe(
            switchMap((result) => {
                if (!result?.ok) {
                    return EMPTY;
                }

                return this.swapApplicantService.removeApplicant(cell.item.customerId, cell.item.id, employeeId);
            }),
            switchMap(this.update.bind(this)),
        ).subscribe(this.getCellSubscriber(cell));
    }

    update() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.updateTable(this.dataTable!.getPagination({ page: 1 }));

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.request!;
    }

    getCellSubscriber(cell: DataTableButtonCell<ShiftSwap>) {
        return {
            complete() {
                cell.disabled.set(false);
            },
            error(e: unknown) {
                console.error(e);
                cell.disabled.set(false);
            },
        };
    }
}
