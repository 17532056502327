// @ts-nocheck
import { module } from 'angular';
import { AdminSystemAlertsComponent } from '../../pages/admin-system-alerts/admin-system-alerts.component';
module('eaw.admin').config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state('eaw/app/admin/system_alerts', {
        parent: 'eaw/app/admin',
        views: {
            'content@': {
                component: AdminSystemAlertsComponent
            },
        },
        url: '/system_alerts',
        data: {
            breadcrumb: { key: 'SYSTEM_ALERTS', ns: 'navigation' },
            permissions: [ `system_alerts.*.get` ],
        },
    });
} ]);
