<eaw-dialog-header>
    <span title>{{data.title | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <eaw-wysiwyg #wysiwygComponent [content]="data.content"></eaw-wysiwyg>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="" >{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="processing" (click)="submit(wysiwygComponent.getContents())">{{data.submitText | async}}</eaw-action-button>
</mat-dialog-actions>
