import { Component, Inject, Input, SkipSelf } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { ArrayPaginatedResponse } from 'src/app/shared/interfaces/paginated-response';
import { WeekSetup } from '../../models/week-setup';
import { Observable } from 'rxjs';
import { WeekSetupService } from '../../http/week-setup.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AutocompleteDeprecatedComponent } from '../../../shared/components/autocomplete-deprecated/autocomplete-deprecated.component';

@Component({
    selector: 'eaw-week-setup-autocomplete',
    templateUrl: './week-setup-autocomplete.component.html',
    styleUrl: './week-setup-autocomplete.component.scss',
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [ [ new SkipSelf(), ControlContainer ] ],
        },
    ],
    standalone: true,
    imports: [ AutocompleteDeprecatedComponent, TranslatePipe ],
})
export class WeekSetupAutocompleteComponent {
    @Input() customerId!: number;
    @Input() controlName!: string;
    @Input() label?: Promise<string>;

    observable?: Observable<ArrayPaginatedResponse<WeekSetup>>;
    limit = 10;

    constructor(
        @Inject(ControlContainer) public controlContainer: ControlContainer,
        @Inject(WeekSetupService) private weekSetupService: WeekSetupService,
    ) {}

    updateWeekSetups(filter?: string) {
        this.observable = this.weekSetupService.getAll(this.customerId, {
            page: 1,
            filter,
            per_page: this.limit,
            order_by: 'name',
            direction: 'asc',
        });
    }
}
