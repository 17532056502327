<eaw-page-header [useBigFilter]="true" [bigFilterToggled]="false" [fabButton]="fabButton">
    <span title></span>

    <div bigFilter>
        <form [formGroup]="filterGroup" class="tw-grid tw-grid-cols-12 tw-gap-8">
            <mat-form-field eawDatePickerOptions class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-3">
                <mat-label>{{ 'ENTER_DATE_RANGE' | translate | async }}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
                    <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async" autocomplete="off">
                    <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async" autocomplete="off">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker (closed)="updateTable(dataTable?.getPagination({ page: 1 }))"></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-3">
                <mat-label>{{ 'SEARCH_FIELD_plural' | translate:'company' | async }}</mat-label>
                <mat-select formControlName="status" (selectionChange)="updateTable(dataTable?.getPagination({ page: 1 }))">
                    <mat-option [value]="'all'">{{ 'ALL' | translateSync }}</mat-option>
                    <mat-option [value]="0">{{ 'UNHANDLED' | translateSync:'vacation' }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</eaw-page-header>

<mat-card class="mat-compact-card mat-card-contained">
    <mat-card-content>
        <eaw-data-table
            #dataTableComponent
            [columns]="columns"
            initialSortBy="from"
            [request]="request"
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
