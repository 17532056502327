import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { ShiftService } from '../../http/shift.service';
import { Shift } from '../../models/shift';
import { TranslateService } from '../../../shared/services/translate.service';
import { DateTime } from 'luxon';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ProfilePictureComponent } from '../../../shared/components/profile-picture/profile-picture.component';
import { MatListModule } from '@angular/material/list';
import { SubheaderComponent } from '../../../shared/components/subheader/subheader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface ShiftChangelogComponentData extends DialogData {
    customerId: number;
    shiftId: number;
}

interface Change {
    time: DateTime | null,
    description: Promise<string> | undefined
}

@Component({
    selector: 'eaw-shift-changelog-dialog',
    templateUrl: './shift-changelog-dialog.component.html',
    styleUrl: './shift-changelog-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        SubheaderComponent,
        MatListModule,
        ProfilePictureComponent,
        MatIconModule,
        NgFor,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        DurationPipe,
    ],
})
export class ShiftChangelogDialogComponent extends DialogComponent implements OnInit {
    currentShift?: Shift;
    changes: Change[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ShiftChangelogComponentData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ShiftChangelogDialogComponent>,
        @Inject(ShiftService) private shiftService: ShiftService,
        @Inject(TranslateService) private translate: TranslateService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit(): void {
        this.shiftService.get(this.data.customerId, this.data.shiftId, {
            changelog: true,
            with: [
                'periods',
                'properties',
                'employee',
                'ghosts.employee',
                'ghosts.comments',
                'ghosts.editedByUser',
            ],
        }).subscribe((shift) => {
            this.currentShift = shift;

            this.currentShift.ghosts?.forEach((ghost, index) => {
                const nextShift = this.currentShift?.ghosts?.[index + 1] || this.currentShift;
                const time = ghost.deletedAt || ghost.createdAt;

                ghost.changes?.forEach((change) => {
                    this.changes.unshift({
                        time,
                        description: nextShift ? change.getDescriptiveText(nextShift, this.translate) : undefined,
                    });
                });
            });
        });
    }
}
