import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { HrFileType } from '../../models/hr-file-type';
import { RequestHrFileDialogComponent } from './request-hr-file-dialog.component';

export type RequestHrFileDialogReturn = 'Single' | 'Multiple' | 'All';

export interface RequestHrFileDialogData extends DialogData {
    customerId: number;
    employees: number[];
    type: HrFileType;
    multiSelect: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class RequestHrFileDialogService {

    constructor(@Inject(MatDialog) private dialog: MatDialog) {
    }

    open(data: RequestHrFileDialogData) {
        return this.dialog.open<RequestHrFileDialogComponent, RequestHrFileDialogData, RequestHrFileDialogReturn>(RequestHrFileDialogComponent, {
            data: {
                size: DialogSize.Medium,
                ...data,
            },
        });
    }
}
