import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CreateScheduleDialogData } from '../create-schedule-dialog.service';
import { ScheduleTemplate } from '../../../models/schedule-template';
import { ScheduleService } from '../../../http/schedule.service';
import { SettingService } from '../../../../shared/http/setting.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DateTime, Info, MonthNumbers } from 'luxon';
import { catchError, debounceTime, EMPTY, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { ScheduleTemplateService } from '../../../http/schedule-template.service';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { expandAllPages } from '../../../../shared/utils/rxjs/expand-all-pages';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../../shared/directives/date-picker-options.directive';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslateSyncPipe } from '../../../../shared/pipes/translate-sync.pipe';

@Component({
    selector: 'eaw-repeating-schedule-create',
    templateUrl: './repeating-schedule-create.component.html',
    styleUrl: './repeating-schedule-create.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        FormsModule,
        MatOptionModule,
        MatInputModule,
        NgIf,
        NgFor,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatDialogActions,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class RepeatingScheduleCreateComponent extends DialogComponent implements AfterViewInit {
    @ViewChild('templateInput') templateInput?: ElementRef<HTMLInputElement>;

    gettingAll = false;
    mode: 'year-month' | 'from-to' = 'year-month';
    templateSearch = new FormControl();
    name = new FormControl('');
    selectedTemplates: ScheduleTemplate[] = [];
    filteredTemplates: Observable<ScheduleTemplate[]> = of([]);
    activeGroup!: FormGroup;
    months: { index: number, month: string }[] = Info.months().map((month, i) => {
        return {
            index: i + 1,
            month,
        };
    });

    yearMonthGroup = new FormGroup({
        name: this.name,
        year: new FormControl(DateTime.now().year, Validators.required),
        month: new FormControl<MonthNumbers>(DateTime.now().month as MonthNumbers, Validators.required),
    });

    fromToGroup = new FormGroup({
        name: this.name,
        dateRange: new FormGroup({
            from: new FormControl(DateTime.now().startOf('month'), Validators.required),
            to: new FormControl(DateTime.now().endOf('month'), Validators.required),
        }),
    });

    getAllTemplatesObservable = expandAllPages(
        (pagination) => this.scheduleTemplateService.getAll(this.data.customerId, pagination),
        {
            order_by: 'name',
            direction: 'asc',
        },
    ).pipe(shareReplay(1));

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateScheduleDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<RepeatingScheduleCreateComponent, ScheduleTemplate>,
        @Inject(ScheduleService) public scheduleService: ScheduleService,
        @Inject(ScheduleTemplateService) public scheduleTemplateService: ScheduleTemplateService,
        @Inject(SettingService) public settingService: SettingService,
    ) {
        super(dialogRef, data);

        this.modeChange();
        this.filteredTemplates = this.templateSearch.valueChanges.pipe(
            debounceTime(1000),
            switchMap((filter) => this.getTemplates(filter)),
        );
    }

    ngAfterViewInit() {
        this.templateSearch.setValue('');
    }

    modeChange() {
        this.activeGroup = this.mode === 'year-month' ? this.yearMonthGroup : this.fromToGroup;
    }

    getTemplates(filter: unknown, per_page = 8) {
        return this.scheduleTemplateService.getAll(this.data.customerId, {
            per_page,
            order_by: 'name',
            direction: 'asc',
            page: 1,
            filter: typeof filter === 'string' && filter.trim().length ? filter : undefined,
        }).pipe(
            map((response) => response.data),
        );
    }

    create() {
        const templates = this.selectedTemplates.map((t) => t.id);
        const name = this.name.value || '';
        let observable;

        if (this.mode === 'year-month') {
            const year = this.yearMonthGroup.get('year')?.value;
            const month = this.yearMonthGroup.get('month')?.value;

            if (year == null || month == null) {
                return;
            }

            observable = this.scheduleService.createRepeatingYearAndMonth(
                this.data.customerId,
                year,
                month,
                {
                    name,
                    template_id: templates,
                },
            );
        } else {
            const from = this.fromToGroup.get('dateRange.from')?.value;
            const to = this.fromToGroup.get('dateRange.to')?.value;

            if (from == null || to == null) {
                return;
            }

            observable = this.scheduleService.createRepeatingFromAndTo(
                this.data.customerId,
                from,
                to.plus({ day: 1 }).startOf('day'),
                {
                    name,
                    template_id: templates,
                },
            );
        }

        this.activeGroup.disable();

        observable.pipe(
            catchError((err) => {
                console.error(err);
                this.activeGroup.enable();

                return EMPTY;
            }),
        ).subscribe((result) => {
            this.dialogRef.close(result);
        });
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (this.selectedTemplates.find((t) => t.id === event.option.value.id)) {
            return;
        }

        this.selectedTemplates.push(event.option.value);
        this.activeGroup.updateValueAndValidity();

        if (this.templateInput?.nativeElement) {
            this.templateInput.nativeElement.value = '';
        }

        this.templateSearch.setValue('', { emitEvent: false });
    }

    addAll() {
        this.gettingAll = true;

        this.getAllTemplatesObservable
            .subscribe({
                next: (templates) => {
                    this.selectedTemplates = [ ...templates ];
                    this.activeGroup.updateValueAndValidity();

                    if (this.templateInput?.nativeElement) {
                        this.templateInput.nativeElement.value = '';
                    }

                    this.templateSearch.setValue('', { emitEvent: false });

                    this.gettingAll = false;
                },
                error: () => (this.gettingAll = false),
            });
    }

    remove(template: ScheduleTemplate) {
        this.selectedTemplates.splice(this.selectedTemplates.findIndex((t) => t.id === template.id), 1);
        this.activeGroup.updateValueAndValidity();
    }
}
