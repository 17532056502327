// @ts-nocheck
import { module } from 'angular';

module('eaw.components').component('eawMimeIcon', {
    bindings: {
        type: '<', // image/png
        size: '<?',
    },
    controller: [ '$element', 'IconElement', function($element, IconElement) {
        const ctrl = this;

        ctrl.$postLink = () => {
            const size = ctrl.size || 44;

            $element[0].style.width = `${size}px`;
            $element[0].style.height = `${size}px`;

            IconElement.get(ctrl.type === 'folder' ? 'folder' : 'description').then(ctrl.insertIcon).catch((e1) => {
                console.error('Failed to get', iconPath, e1);
            });
        };

        ctrl.insertIcon = (iconEl) => {
            $element[0].appendChild(iconEl);
        };
    } ],
});
