import { AfterViewInit, ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { DateTime } from 'luxon';
import { ShiftService } from '../../../../scheduling/http/shift.service';
import { CurrentService } from '../../../../shared/services/current.service';
import { TranslateService } from '../../../../shared/services/translate.service';
import { Widget } from '../../../classes/widget';
import { VacantShiftsWidgetSettings } from '../vacant-shifts-widget-settings-dialog/vacant-shifts-widget-settings-dialog.component';
import { expandAllPages } from '../../../../shared/utils/rxjs/expand-all-pages';
import { Shift } from '../../../../scheduling/models/shift';
import { ExtendedWidgetInfo } from '../../../classes/extended-widget-info';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { DateTimePipe } from '../../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { InfoLoadingComponent } from '../../../../shared/components/info-loading/info-loading.component';

@Component({
    selector: 'eaw-vacant-shifts-widget-extended-info',
    templateUrl: './vacant-shifts-widget-extended-info.component.html',
    styleUrl: './vacant-shifts-widget-extended-info.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgFor,
        MatListModule,
        MatDividerModule,
        NgIf,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        InfoLoadingComponent,
    ],
})
export class VacantShiftsWidgetExtendedInfoComponent extends ExtendedWidgetInfo implements OnInit, AfterViewInit {
    protected readonly translate = inject(TranslateService);
    protected readonly shiftService = inject(ShiftService);
    protected readonly current = inject(CurrentService);

    widget = input.required<Widget<VacantShiftsWidgetSettings>>();

    date = signal(DateTime.now());
    shifts = signal<Shift[]>([]);

    ngOnInit() {
        this.setLoading(true);
        const offset = this.widget().getSetting('day_offset');
        if (offset == null) {
            return;
        }

        this.date.set(DateTime.now().plus({ days: offset }));

        this.updateSubtitle(offset === 0 ? this.translate.t('TODAY') : this.translate.t('TOMORROW'));
    }

    ngAfterViewInit() {
        expandAllPages((pagination) => this.shiftService.getAll(this.current.getCustomer().id, {
            ...pagination,
            vacant: true,
            from: this.date().startOf('day'),
            to: this.date().endOf('day'),
            'with[]': [ 'periods' ],
        }), {
            per_page: 20,
        }).subscribe((shifts) => {
            this.setLoading(false);
            this.shifts.set(shifts);
            this.sortShifts();
            this.setLoading(false);
        });
    }

    sortShifts() {
        this.shifts.update((shifts) => {
            return sort(shifts, 'en', [ (s) => s.from.toUnixInteger(), (s) => s.to.toUnixInteger() ], [ 'asc', 'asc' ], { numeric: true }).map((s) => {
                s.periods = sort(s.periods, 'en', [ (p) => p.from.toUnixInteger(), (p) => p.to.toUnixInteger() ], [ 'asc', 'asc' ], { numeric: true });
                return s;
            });
        });
    }
}
