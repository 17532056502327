<eaw-page-header [fabButton]="addUnitButton">
    <div title class="settings-unit-title">
        {{ 'BUSINESS_UNITS' | translate: 'navigation' | async }}
        <mat-progress-spinner *ngIf="gettingUnits" mode="indeterminate" diameter="28" [strokeWidth]="3"></mat-progress-spinner>
    </div>

    <mat-form-field smallFilter class="standalone">
        <mat-label>{{ 'DEFAULT' | translate: 'company' | async }}</mat-label>
        <mat-select [formControl]="defaultCtrl" (selectionChange)="updateDefault($event)">
            <mat-option [value]="null">{{ 'NONE' | translateSync }}</mat-option>
            <mat-option *ngFor="let unit of units" [value]="unit">{{ unit.name }}</mat-option>
        </mat-select>
    </mat-form-field>
</eaw-page-header>

<mat-accordion multi>
    <mat-expansion-panel *ngFor="let unitGroup of tree?.units" class="business-unit-panel">
        <mat-expansion-panel-header>
            <mat-panel-title class="tw-flex tw-flex-row">
                <div class="unit-color" [style.background-color]="unitGroup.color"></div>
                <span class="text-ws-nowrap">{{ unitGroup.name || 'NO_PARENT' | translate:'company' | async }}</span>
                <span class="tw-flex-1 tw-basis-full tw-max-w-full"></span>
                <span class="text-ws-nowrap">{{ ('X_UNIT' | translate:'payroll':{count: unitGroup.children.length}) | async }}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-accordion>
            <mat-expansion-panel *ngFor="let unit of unitGroup.children">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="unit-color small" [style.background-color]="unit.color"></div>
                        {{ unit.name }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table>
                    <tr>
                        <td>{{ 'ID' | translate | async }}</td>
                        <td>{{ unit.id }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'NAME' | translate | async }}</td>
                        <td>{{ unit.name }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'QUALIFICATION_plural' | translate | async }}</td>
                        <td>
                            <div *ngIf="unit.qualifications?.length === 0">
                                {{ 'NONE' | translate | async }}
                            </div>
                            <div *ngFor="let q of unit.qualifications">
                                {{ q.name }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ 'REVERSE_INHERIT_QUALIFICATIONS' | translate: 'company' | async }}</td>
                        <td>{{ (unit.reverseInheritQualifications ? 'YES' : 'NO') | translate | async }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'CREATED_AT' | translate | async }}</td>
                        <td>{{ unit.createdAt | DateTime: 'DATETIME_MED' }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'UPDATED_AT' | translate | async }}</td>
                        <td>{{ unit.updatedAt | DateTime: 'DATETIME_MED' }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'IS_PRODUCTIVE' | translate | async }}</td>
                        <td>{{ (unit.isProductive ? 'YES' : 'NO') | translate | async }}</td>
                    </tr>
                </table>

                <mat-action-row>
                    <button mat-button mat-raised-button (click)="editUnit(unit)">{{ 'EDIT' | translate | async }}</button>
                    <button mat-button mat-raised-button (click)="deleteUnit(unit)" color="warn">{{ 'DELETE' | translate | async }}</button>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-action-row *ngIf="unitGroup.id">
            <button mat-button mat-raised-button (click)="editUnit(unitGroup)">{{ 'EDIT' | translate | async }}</button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>
