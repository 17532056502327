// @ts-nocheck
import { module } from 'angular';
import { PaginationOld } from '../../shared/utils/pagination-old';
import { CurrentOld } from '../../shared/angularjs/current.factory';
module('eaw.vacation').factory('vacationDays', [ 'EawResource', function(EawResource) {
    const url = '/customers/:customer_id/employees/:employee_id/vacation_days';
    this.create = (args) => EawResource.create(url).save({
        customer_id: args.customer ? args.customer.id : CurrentOld.customer.id,
        employee_id: args.employee ? args.employee.id : CurrentOld.customer.employee.id,
    }, {
        delta: args.delta,
        effective: args.start,
        description: args.comment,
    });
    this.getEmployeeStats = (args) => EawResource.create('/customers/:customer_id/employees/:employee_id/vacation_days_stats/:year').get({
        customer_id: args.customer ? args.customer.id : CurrentOld.customer.id,
        employee_id: args.employee ? args.employee.id : CurrentOld.customer.employee.id,
        year: args.year,
    });
    this.getForCustomer = (args) => EawResource.create('/customers/:customer_id/vacation_days').get({
        ...PaginationOld.getParams(args, [ 'include_inactive' ]),
        customer_id: args.customer.id,
    });
    this.getForEmployee = (args) => EawResource.create(url).get({
        ...PaginationOld.getParams(args),
        customer_id: args.customer.id,
        employee_id: args.employee.id,
    });
    return this;
} ]);
