import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { FormControl } from '@angular/forms';

export interface FormRule {
    key: string;
    message: Observable<string> | Promise<string>;
    error?: boolean;
}

@Component({
    selector: 'eaw-form-rules',
    standalone: true,
    imports: [ CommonModule, MatIconModule ],
    templateUrl: './form-rules.component.html',
    styleUrl: './form-rules.component.scss',
})
export class FormRulesComponent implements OnInit {
    @Input({ required: true }) rules!: FormRule[];
    @Input({ required: true }) input!: FormControl;

    private icons = {
        pass: 'check',
        fail: 'close',
        pristine: 'remove',
    };

    ngOnInit() {
        this.input.statusChanges.subscribe((status) => {
            if ([ 'VALID', 'INVALID' ].includes(status)) {
                this.rules.forEach((rule) => {
                    rule.error = this.input.hasError(rule.key);
                });
            }
        });

    }

    getIcon(rule: FormRule) {
        if (this.input.pristine) {
            return this.icons.pristine;
        }

        return rule.error ? this.icons.fail : this.icons.pass;
    }
}
