// @ts-nocheck
import { module } from 'angular';
module('eaw.ai-budgeting').factory('mlTimeSeriesFactory', [ 'EawResource', 'Pagination', function mlTimeSeriesFactory(EawResource, Pagination) {
    const fac = this;
    const baseRes = '/customers/:customer/ml_buckets/:bucket/time_series/:series';
    const res = EawResource.create(baseRes);
    const entriesRes = EawResource.create(`${baseRes}/entries`);
    fac.getConstants = {
        query() {
            return EawResource.create('/time_series_constants').get().$promise;
        },
    };
    fac.get = {
        permission(customer, bucket, series) {
            return `customers.${customer}.ml_buckets.${bucket}.time_series.${series}.get`;
        },
        query(customer, bucket, series) {
            return res.get({
                customer,
                bucket,
                series,
            }).$promise;
        },
    };
    fac.getAll = {
        permission(customer, bucket) {
            return `customers.${customer}.ml_buckets.${bucket}.time_series.*.get`;
        },
        query(customer, bucket, type, variable, pagination) {
            return res.get({
                customer,
                bucket,
                type,
                search: pagination?.filter || undefined,
                variable_uuid: variable,
                ...Pagination.getParams(pagination),
            }).$promise;
        },
    };
    fac.create = {
        permission(customer) {
            return `customers.${customer}.ml_buckets.{bucket}.time_series.create`;
        },
        /**
         * @param {Number} customer
         * @param {Number} bucket
         * @param {Object} args
         * @param {String} args.name
         * @param {Number} args.type
         * @param {String} args.description
         * @param {String} args.variableUuid
         * @param {Number} args.interval
         * @param {Number} args.valueType
         * @param {Number} args.aggregateMethod
         * @param {Boolean} args.customerHidden
         * @param {Object} [args.tags]
         * @returns {Promise}
         */
        query(customer, bucket, args) {
            return res.save({
                customer,
                bucket,
            }, {
                name: args.name,
                type: args.type,
                description: args.description,
                variable_uuid: args.variableUuid,
                interval: args.interval,
                value_type: args.valueType,
                aggregate_method: args.aggregateMethod,
                customer_hidden: args.customerHidden,
                tags: args.tags,
            }).$promise;
        },
    };
    fac.getEntries = {
        permission(customer, bucket, series) {
            return `customers.${customer}.ml_buckets.${bucket}.time_series.${series}.entries.*.get`;
        },
        query(customer, bucket, series, from, to, withCompDates, pagination) {
            return entriesRes.get({
                customer,
                bucket,
                series,
                from: from.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
                to: to.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
                with_comparable_dates: withCompDates,
                ...Pagination.getParams(pagination),
            }).$promise;
        },
    };
    return fac;
} ]);
