import { Component, Inject } from '@angular/core';
import { I18nextLoaderService } from '../../../initializer/services/i18next-loader.service';
import { Language } from '../../../admin/models/language';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlagSvgDirective } from '../../../shared/directives/flag-svg.directive';

@Component({
    selector: 'eaw-login-language-select',
    templateUrl: './login-language-select.component.html',
    styleUrl: './login-language-select.component.scss',
    standalone: true,
    imports: [
        FlagSvgDirective,
        MatFormFieldModule,
        MatIconModule,
    ],
})
export class LoginLanguageSelectComponent {
    language: Language;

    constructor(
        @Inject(I18nextLoaderService) private i18nextLoaderService: I18nextLoaderService,
    ) {
        this.language = this.i18nextLoaderService.currentLanguage;
    }
}
