import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { HrFileType } from '../../models/hr-file-type';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Signable } from '../../../shared/models/signable';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';
import { SignablesService } from '../../../shared/http/signables.service';
import { catchError, EMPTY, forkJoin, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { DigitalSignatureService, SignatureProvider } from '../../../digital_signing/digital-signature.service';
import { HrFileTypeService } from '../../http/hr-file-type.service';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { Products } from '../../../shared/enums/products';
import { MatOption, MatSelect } from '@angular/material/select';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';
import { PermissionsInputValue } from '../../../permissions/services/element-permission.service';
import { PermissionOptions } from '../../../shared/services/permission-check.service';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';

export interface HrFileTypeDialogData extends DialogData {
    customerId: number;
    fileType?: Observable<HrFileType>;
}

interface HrFileForm {
    name: FormControl<string>;
    mandatory: FormControl<boolean>;
    signable: FormControl<boolean>;
    signableProvider: FormControl<SignatureProvider | null>;
}

@Component({
    selector: 'eaw-hr-file-type-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        ActionButtonComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        MatHint,
        MatCheckbox,
        MatSelect,
        MatOption,
        PermissionDirective,
        CheckboxHelperDirective,
    ],
    templateUrl: './hr-file-type-dialog.component.html',
    styleUrl: './hr-file-type-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HrFileTypeDialogComponent extends DialogComponent<HrFileTypeDialogData, HrFileType> implements OnInit {
    private readonly formBuilder = inject(FormBuilder);
    private readonly signableService = inject(SignablesService);
    private readonly digitalSignatureService = inject(DigitalSignatureService);
    private readonly hrFileTypeService = inject(HrFileTypeService);
    private readonly productCheckService = inject(CustomerProductService);

    protected form: FormGroup<HrFileForm>;
    protected signableProviders: Observable<SignatureProvider[]>;

    /** If editing, the signable that applies to the file type */
    private signable?: Signable;
    private fileType?: HrFileType | null;

    saving = signal(false);
    hasDigiSign = signal(false);
    signablePermissions = signal<PermissionsInputValue | undefined>(undefined);

    constructor() {
        super(undefined, undefined, DialogSize.Medium);

        this.form = this.formBuilder.group<HrFileForm>({
            mandatory: this.formBuilder.control(false, { nonNullable: true }),
            name: this.formBuilder.control('', { nonNullable: true, validators: [ Validators.required ] }),
            signable: this.formBuilder.control(false, { nonNullable: true }),
            signableProvider: this.formBuilder.control(null),
        });

        // Disable, then enable when we've gotten signables and file type
        if (this.data.fileType) {
            this.form.disable();
        } else {
            this.form.controls.signable.disable();
        }

        this.signableProviders = this.digitalSignatureService.getAll().pipe(shareReplay(1));

        this.form.controls.signable.valueChanges.pipe(
            tap(((signable) => {
                if (signable) {
                    this.form.controls.signableProvider.addValidators([ Validators.required ]);
                    this.form.controls.signableProvider.enable();
                } else {
                    this.form.controls.signableProvider.setValue(null);
                    this.form.controls.signableProvider.clearValidators();
                    this.form.controls.signableProvider.disable();
                }
            })),
        ).subscribe();
    }

    ngOnInit(): void {
        const models: PermissionOptions['models'] = [ { type: ApiModel.Customer, id: this.data.customerId } ];

        if (this.signable) {
            models.push({ type: ApiModelClass.Signable, id: this.signable.id });
            this.signablePermissions.set([
                [ `customers.[${ApiModel.Customer}].signables.${ApiModelClass.Signable}.delete`, { models } ],
                [ `customers.[${ApiModel.Customer}].signables.${ApiModelClass.Signable}.update`, { models } ],
            ]);
        } else {
            this.signablePermissions.set([ [ `customers.[${ApiModel.Customer}].signables.create`, { models } ] ]);
        }

        forkJoin([
            this.productCheckService.hasProducts(this.data.customerId, [ Products.DigitalSigning ]),
            this.data.fileType || of(null),
        ]).pipe(
            map(([ hasDigiSign, fileType ]) => {
                this.hasDigiSign.set(hasDigiSign);

                this.form.controls.mandatory.setValue(fileType?.mandatory ?? false);
                this.form.controls.name.setValue(fileType?.name ?? '');

                this.fileType = fileType;

                if (!hasDigiSign) {
                    this.form.enable();
                    this.form.controls.signableProvider.disable();
                    return EMPTY;
                }

                return of(undefined);
            }),
            switchMap(() => {
                return forkJoin([
                    this.signableProviders,
                    this.fileType ? expandAllPages(
                        (pagination) => this.signableService.getAll(this.data.customerId, ApiModelClass.HrFile, pagination),
                        // It's unlikely that there's more than one page, but you never know
                        { per_page: 250 },
                    ).pipe(catchError((err) => {
                        if (err.status === 422 || err.status === 403) {
                            return of([]);
                        }

                        throw err;
                    })) : of([] as Signable[]),
                ]);
            }),
            tap(([ providers, signables ]) => {
                this.form.enable();
                this.form.controls.signableProvider.disable();

                if (this.fileType) {
                    for (const signable of signables) {
                        if (signable.filter.type_id === this.fileType.id) {
                            this.signable = signable;
                            break;
                        }
                    }
                    let provider: SignatureProvider | null = null;
                    if (this.signable?.provider) {
                        provider = providers.find((provider) => provider.class === this.signable?.provider) ?? null;
                    }

                    this.form.controls.signableProvider.setValue(provider);
                    this.form.controls.signable.setValue(!!this.signable);
                }
            }),
        ).subscribe();
    }

    save() {
        this.saving.set(true);
        const formValues = this.form.getRawValue();
        let saveFileType: Observable<HrFileType>;
        this.form.disable();

        // We only allow pdfs to be signed. If we had a signable from before, keep it as application/pdf.
        const acceptFileTypes = formValues.signable || this.signable ? [ 'application/pdf' ] : null;

        if (this.fileType) {
            saveFileType = this.hrFileTypeService.update(this.data.customerId, this.fileType.id, {
                name: formValues.name,
                mandatory: formValues.mandatory,
                accept_file_types: acceptFileTypes,
            });
        } else {
            saveFileType = this.hrFileTypeService.create(this.data.customerId, {
                name: formValues.name as string,
                mandatory: formValues.mandatory,
                accept_file_types: acceptFileTypes,
            });
        }

        saveFileType.pipe(
            catchError(() => {
                this.saving.set(false);
                this.form.enable();
                return EMPTY;
            }),
            switchMap((fileType) => {
                // Create signable if needed
                if (!this.signable && !formValues.signable) {
                    return of(fileType);
                }

                const signableProvider = formValues.signableProvider;
                let observable: Observable<Signable | undefined> = of(undefined);

                // We have a signable, so update or delete that
                if (this.signable) {
                    if (signableProvider) {
                        // If it was changed, update it.
                        if (this.signable.provider !== signableProvider.class) {
                            observable = this.signableService.update(this.data.customerId, this.signable.id, { type_id: fileType.id }, signableProvider.class);
                        }
                    } else {
                        observable = this.signableService.delete(this.data.customerId, this.signable.id);
                    }
                } else if (signableProvider) {
                    observable = this.signableService.create(this.data.customerId, { type_id: fileType.id }, ApiModelClass.HrFile, signableProvider.class);
                }

                return observable.pipe(map(() => fileType));
            }),
            tap((fileType) => this.dialogRef.close(fileType))).subscribe();
    }
}
