import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CurrentService } from '../services/current.service';
import { NumberFormatterService } from '../services/number-formatter.service';

@Pipe({
    name: 'fileSize',
    standalone: true,
})
export class FileSizePipe implements PipeTransform {
    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
    ) {
    }

    transform(bytes?: number, options?: Intl.NumberFormatOptions): unknown {
        if (bytes == undefined || !Number.isFinite(bytes)) {
            return '';
        }

        let unit: string;
        let value: number;

        if (bytes < 1000) {
            unit = 'byte';
            value = bytes;
        } else if (bytes < 1e6) {
            unit = 'kilobyte';
            value = bytes / 1000;
        } else if (bytes < 1e9) {
            unit = 'megabyte';
            value = bytes / 1e6;
        } else {
            unit = 'gigabyte';
            value = bytes / 1e9;
        }

        try {
            return this.numberFormatterService.format(this.current.languageTag, value, {
                style: 'unit',
                unit,
                maximumFractionDigits: 0,
                ...options,
            });
        } catch (e) {
            console.error('FileSizePipe formatting error', e);
        }
    }
}
