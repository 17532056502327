// @ts-nocheck
import { uniqueId } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import moment from 'moment-timezone';
import { module } from 'angular';

module('eaw.calendar2').component('eawYearMonth', {
    template: `<div class="month-name" ng-bind="::$yemo.monthName"></div>
<div class="week-days">
    <div ng-repeat="dayName in $yemo.yearCalendar.weekdays track by $index" ng-bind="::dayName"></div>
</div>
<div class="week-nrs">
    <div ng-repeat="weekNr in $yemo.weekNrs track by $index" ng-bind="::weekNr"></div>
</div>
<div class="days">
    <eaw-year-day-old ng-repeat="day in $yemo.days track by day.id" day="day"></eaw-year-day-old>
</div>
`,
    bindings: {
        year: '<',
        month: '<', // From 0 to 11 (because JS is like that)
    },
    require: {
        calendar: '?^eawCalendar2',
        yearCalendar: '^eawYearCalendar',
    },
    controllerAs: '$yemo',
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.monthName = moment.months()[ctrl.month];

            ctrl.generateMonth();
            ctrl.setWeekNrs();
        };

        ctrl.$onChanges = (changes) => {
            ctrl.year = changes?.year?.currentValue ?? ctrl.year;
            ctrl.month = changes?.month?.currentValue ?? ctrl.month;

            ctrl.$onInit();
        };

        ctrl.weekClick = (nr) => {
            let year = ctrl.year;

            if (ctrl.month === 0 && nr > 10) {
                nr = nr > 52 ? nr - 1 : nr;
                year -= 1;
            }

            if (ctrl.month === 11 && nr < 40) {
                year += 1;
            }

            ctrl.calendar.changeMode('w', moment().year(year).startOf('y').add(nr, 'w').startOf('w'));
        };

        ctrl.setWeekNrs = () => {
            ctrl.weekNrs = new Set();
            ctrl.days.forEach((d) => ctrl.weekNrs.add(d.week));
            ctrl.weekNrs = Array.from(ctrl.weekNrs);
        };

        ctrl.generateMonth = () => {
            const monthStart = moment()
                .year(ctrl.year)
                .month(ctrl.month)
                .startOf('M')
                .startOf('w');
            const monthEnd = monthStart.clone().add(5, 'w').endOf('w');
            const loopDays = monthEnd.diff(monthStart, 'd');

            ctrl.days = [];

            for (let i = 0; i <= loopDays; i++) {
                ctrl.days.push({
                    id: uniqueId(),
                    year: monthStart.year(),
                    month: monthStart.month(),
                    date: monthStart.date(),
                    week: monthStart.week(),
                    moment: monthStart.clone(),
                    inMonth: monthStart.month() === ctrl.month,
                });

                monthStart.add(1, 'd');
            }
        };
    },
});
