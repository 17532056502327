// @ts-nocheck
import { module } from 'angular';

module('eaw.admin').component('adminCustomerMlBucketInfo', {
    template: `<md-tab label="Info">
    <md-content>
        <table class="table table-condensed table-striped tw-mt-12 tw-mb-12">
            <tbody>
            <tr>
                <td>Uuid</td>
                <td ng-bind="$bucketInfo.bucket.uuid"></td>
            </tr>
            <tr>
                <td>Tags</td>
                <td class="container-class">
                    <eaw-object-displayer object="$bucketInfo.bucket.tags"></eaw-object-displayer>
                    <md-button ng-if="$bucketInfo.bucket.canUpdate" class="new-bucket-btn md-icon-button md-accent md-raised" ng-click="$bucketInfo.onUpdate()">
                        <md-icon ng-bind="'edit'"></md-icon>
                    </md-button>            
                </td>
            </tr>
            <tr>
                <td>Created at</td>
                <td ng-bind="$bucketInfo.bucket.created_at | moment:'LLLL'"></td>
            </tr>
            <tr>
                <td>Updated at</td>
                <td ng-bind="$bucketInfo.bucket.updated_at | moment:'LLLL'"></td>
            </tr>
            </tbody>
        </table>
    </md-content>
</md-tab>
`,
    bindings: {
        customer: '<',
        bucket: '<',
        onUpdate: '&',
    },
    controllerAs: '$bucketInfo',
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {

        };
    },
});
