import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { MoveItem } from './move-item';
import { MoveItemComponent } from './move-item.component';
import { Directory } from '../../models/directory';
import { DirectoryResponse } from '../../interfaces/directory-response';
import { FsNodeResponse } from '../../interfaces/fs-node-response';
import { FsFile } from '../../models/fsFile';
import { FileResponse } from '../../interfaces/file-response';

@Injectable({
    providedIn: 'root',
})
export class MoveItemDialogService {
    constructor(@Inject(MatDialog) private dialog: MatDialog) { }

    open(options: {item: FsNodeResponse, startDirectory:DirectoryResponse}) {
        const item = options.item.files ? new Directory(options.item as DirectoryResponse) : new FsFile(options.item as FileResponse);

        return this.dialog.open<MoveItemComponent, MoveItem, Directory>(MoveItemComponent, {
            data: {
                size: DialogSize.Large,
                item,
                startDirectory: new Directory(options.startDirectory),
            },
        });
    }
}
