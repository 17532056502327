// @ts-nocheck
import { debounce } from 'lodash-es';
import { module } from 'angular';
import { groupBy } from 'lodash-es';

module('eaw.scheduling').service('openingHoursRenderer', [ 'intervalService', 'scheduleDays', function(intervalService, scheduleDays) {
    const service = this;

    service.saveBoxes = () => {
        service.boxes = Array.from(document.getElementsByClassName('not-open-box'));
    };

    service.addBox = (table, left, width) => {
        const div = document.createElement('div');
        div.classList.add('not-open-box');
        div.style.left = `${left}px`;
        div.style.width = `${width}px`;

        table.appendChild(div);
    };

    service.render = debounce((schedule, openingHours, useLines) => {
        const table = document.getElementById('shifts-table');
        if (!table) {
            return;
        }

        const cellWidth = intervalService.getMarkerInterval();
        let loop = 0;
        const dayStarts = {};
        const hours = groupBy(openingHours, 'day');
        const oneSecondPixelLength = intervalService.getOneSecondPixelLength();
        const scheduleTo = schedule.getTo();
        const date = schedule.getFrom();

        // Remove old divs
        service.boxes?.forEach((el) => el.remove());

        // Loop through all days in the schedule
        while (Object.keys(openingHours || []).length && date.isBefore(scheduleTo, 'h')) {
            const weekday = date.isoWeekday();

            if (hours[weekday]) {
                const oh = hours[weekday][0]; // Opening hours for this day
                // Box from/to is the open interval
                const boxFrom = date.clone().hours(oh.open_from.hours()).minutes(oh.open_from.minutes());
                const boxTo = date.clone().hours(oh.open_to.hours()).minutes(oh.open_to.minutes());

                if (boxTo.isSameOrBefore(boxFrom)) {
                    // If to time is in the past or same as from, it's supposed to be the next day
                    boxTo.add(1, 'd');

                    // Add offset in object so we can add it the next day
                    dayStarts[loop + 1] = boxTo.diff(boxTo.clone().startOf('d'), 's');
                }

                // For times before box start
                const startOfDayOffset = date.clone().startOf('d').add(dayStarts?.[loop] || 0, 's').diff(schedule.getFrom(), 's');
                const boxFromOffset = boxFrom.diff(schedule.getFrom(), 's');
                service.addBox(table, startOfDayOffset * oneSecondPixelLength, (boxFromOffset - startOfDayOffset) * oneSecondPixelLength);

                // For times after box start
                const boxToOffset = boxTo.diff(schedule.getFrom(), 's');
                const endOfDayOffset = date.clone().endOf('d').diff(schedule.getFrom(), 's');
                service.addBox(table, boxToOffset * oneSecondPixelLength, (endOfDayOffset - boxToOffset) * oneSecondPixelLength);
            }
            // Increment date
            date.add(1, 'd');

            // Increment loop
            loop++;
        }

        // Simply lines
        if (useLines) {
            let offset = 0;
            scheduleDays.getAllTimesDoubleRender().forEach((t, i) => {
                const left = i * cellWidth + offset;
                if (t.toDST) {
                    offset = -cellWidth / (schedule.settings.interval / 3600);
                }
                if (t.fromDST) {
                    offset = cellWidth / (schedule.settings.interval / 3600);
                }
                if (left) {
                    service.addBox(table, left, 1);
                }
            });
        }

        service.saveBoxes();
    }, 100);

    return service;
} ]);
