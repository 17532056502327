@if (!noResults) {
    <mat-list>
        <mat-list-item class="shift-header" [class.open]="open" (click)="toggleShift()">
            <span class="date">{{ data.from | DateTime: 'DATE_SHORT' }}</span>
            @if (data.business_units.length) {
                <span class="business-units">
                    @for (unit of data.business_units; track unit; let isLast = $last) {
                        <span>{{ unit + (isLast ? '' : ', ') }}</span>
                    }
            </span>
            }
            <span class="time">{{ data.from | DateTime: 'TIME_SIMPLE' }} - {{ data.to | DateTime: 'TIME_SIMPLE' }}</span>
            <mat-divider></mat-divider>
        </mat-list-item>
        @if (open) {
            @if (results) {
                @for (result of results; track result) {
                    <mat-list-item class="employee-row">
                        <span class="employee-name">{{ employeeNames.get(result.employeeId) }}: </span>
                        @for (check of result.failedChecks; track check; let last = $last) {
                            <span class="failed-check">
                                {{ ('Failed' + check | translate: 'autosched' | async) + (last ? '' : ', ') }}
                            </span>
                        }
                        <mat-divider></mat-divider>
                    </mat-list-item>
                }
            } @else {
                <mat-list-item>
                    <mat-progress-spinner diameter="32" strokeWidth="3" mode="indeterminate"></mat-progress-spinner>
                </mat-list-item>
            }
        }
    </mat-list>
}
