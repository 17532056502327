import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { inject } from '@angular/core';

export const enum DialogSize {
    Content = 'content',
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    XLarge = 'x-large',
    Max = 'max',
}

export interface DialogData {
    size?: DialogSize,
    disableClose?: boolean,
}

export class DialogComponent<Data extends DialogData = DialogData, Return = unknown, Component = unknown> {
    protected data: Data;
    protected dialogRef: MatDialogRef<Component, Return>;

    constructor(
        dialogRef?: MatDialogRef<Component, Return>,
        data?: Data,
        size?: DialogSize,
    ) {
        this.data = data ?? inject(MAT_DIALOG_DATA) ?? {} as Data;
        this.dialogRef = dialogRef || inject(MatDialogRef);

        this.dialogRef.disableClose = data?.disableClose ?? true;
        this.dialogRef.addPanelClass([ 'eaw-dialog', `eaw-dialog-${size || data?.size || DialogSize.Content}` ]);
    }

    hideDialog() {
        this.dialogRef.addPanelClass('hidden');
    }

    showDialog() {
        this.dialogRef.removePanelClass('hidden');
    }
}
