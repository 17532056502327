<eaw-dialog-header *ngIf="timepunch">
    <span title>{{timepunch.employee?.name}}</span>
    <span subtitle>{{('CREATED_DATE' | translate:'general':{when: timepunch.createdAt | DateTime:'DATETIME_MED'}) | async}}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-24" *ngIf="loading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-24" *ngIf="timepunch">
    <table>
        <tr>
            <td class="mat-subtitle-2">{{'APPROVED' | translate | async}}</td>
            <td class="mat-body-2">{{(timepunch.approved ? 'YES' : 'NO') | translate | async}}</td>
        </tr>
        <tr *ngIf="timepunch.approved">
            <td class="mat-subtitle-2">{{'APPROVAL_BY' | translate:'payroll' | async}}</td>
            <td class="mat-body-2">{{timepunch.approvedByName}}</td>
        </tr>
        <tr>
            <td class="mat-subtitle-2">{{'BUSINESS_DATE' | translate:'payroll' | async}}</td>
            <td class="mat-body-2">{{timepunch.businessDate | DateTime:'DATE_MED'}}</td>
        </tr>
        <tr>
            <td class="mat-subtitle-2">{{'IN' | translate:'payroll' | async}}</td>
            <td class="mat-body-2">{{timepunch.in | DateTime:'DATETIME_MED'}}</td>
        </tr>
        <tr>
            <td class="mat-subtitle-2">{{'OUT' | translate:'payroll' | async}}</td>
            <td class="mat-body-2">{{timepunch.out | DateTime:'DATETIME_MED'}}</td>
        </tr>
    </table>
    @if (attachments.length) {
        <mat-divider></mat-divider>
        <h4 class="tw-m-0">{{'SIGNED_ATTACHMENT_plural' | translate | async}}</h4>
        <ul class="tw-m-0">
        @for (attachment of attachments; track attachment) {
            <li><a style="cursor:pointer;text-decoration: underline;" (click)="downloadAttachment(attachment)">{{attachment.name}}</a></li>
        }
        </ul>
    }
    @if (attachmentsInProgress.length) {
        <mat-divider></mat-divider>
        <h4 class="tw-m-0">{{'IN_PROGRESS_ATTACHMENT_plural' | translate | async}}</h4>
        <ul class="tw-m-0">
            @for (attachment of attachmentsInProgress; track attachment) {
                <li><a style="cursor:pointer;text-decoration: underline;" (click)="downloadRequestedAttachment(attachment)">{{attachment.last_document}}</a></li>
            }
        </ul>
    }
    @if (attachmentsRejected.length) {
        <mat-divider></mat-divider>
        <h4 class="tw-m-0">{{'REJECTED_ATTACHMENT_plural' | translate | async}}</h4>
        <ul class="tw-m-0">
        @for (attachment of attachmentsRejected; track attachment) {
            <li><a style="cursor:pointer;text-decoration: underline;" (click)="downloadRequestedAttachment(attachment)">{{attachment.last_document}}</a></li>
        }
        </ul>
    }

    <mat-divider *ngIf="timepunch.changelog.length"></mat-divider>

    <mat-list *ngFor="let change of timepunch.changelog; index as i">
        <div mat-subheader>{{'CHANGED_WHEN_WHO' | translate: 'payroll': {date: ((change.deletedAt || change.updatedAt) | DateTime: 'DATETIME_SHORT'), name: change.editedBy?.name} | async}}</div>
        <mat-list-item *ngFor="let key of filteredChanges(change.changes)">
            <mat-icon matListItemIcon *ngIf="key === 'out' || key === 'in'">schedule</mat-icon>
            <mat-icon matListItemIcon *ngIf="key === 'business_date'">calendar_month</mat-icon>
            <mat-icon matListItemIcon *ngIf="key === 'approval'">done</mat-icon>
            <mat-icon matListItemIcon *ngIf="key === 'manual_open'">manage_history</mat-icon>
            <mat-icon matListItemIcon *ngIf="key === 'manual_close'">manage_history</mat-icon>

            <div matListItemLine *ngIf="key === 'out'">{{('CHANGED_OUT_FROM_TO' | translate:'payroll':{from: change.out | DateTime:'TIME_SIMPLE', to: getNext(i).out | DateTime:'TIME_SIMPLE'}) | async}}</div>
            <div matListItemLine *ngIf="key === 'in'">{{('CHANGED_IN_FROM_TO' | translate:'payroll':{from: change.in | DateTime:'TIME_SIMPLE', to: getNext(i).in | DateTime:'TIME_SIMPLE'}) | async}}</div>
            <div matListItemLine *ngIf="key === 'business_date'">{{('CHANGED_BD_FROM_TO' | translate:'payroll':{from: change.businessDate | DateTime:'DATE_MED', to: getNext(i).businessDate | DateTime:'DATE_MED'}) | async}}</div>
            <div matListItemLine *ngIf="key === 'manual_open'">{{'CHANGED_MANUAL_OPEN' | translate:'payroll' | async}}</div>
            <div matListItemLine *ngIf="key === 'manual_close'">{{'CHANGED_MANUAL_CLOSE' | translate:'payroll' | async}}</div>
        </mat-list-item>

        <mat-list-item *ngIf="!(change.changes | keyvalue)?.length && change.id === timepunch.id && timepunch.deletedAt">
            <mat-icon matListItemIcon>delete</mat-icon>
            <div matListItemLine>{{'DELETED' | translate | async}}</div>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>
