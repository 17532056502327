import { Component, Inject, Input, signal, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { EmployeeGroupsService } from '../../http/employee-groups.service';
import { CurrentService } from '../../../shared/services/current.service';
import { EmployeeGroup } from '../../models/employee-group';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { Namespace } from '../../../shared/enums/namespace';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent, AlertDialogData } from '../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { catchError, EMPTY, of } from 'rxjs';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-employee-groups',
    templateUrl: './employee-groups-list.component.html',
    styleUrl: './employee-groups-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmployeeGroupsListComponent implements EawDataTable {
    @Input({ required: true }) customerId!: number;
    // Route to go to for info view
    @Input({ required: true }) infoRoute!: string;

    @ViewChild(DataTableComponent) table?: DataTableComponent<EmployeeGroup>;

    protected pagination?: Partial<DataTablePagination>;

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<EmployeeGroup>[] = [
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({ i18n: 'NAME', ns: Namespace.Company }),
        }),
        new DataTableNumberColumn({
            value: 'membersCount',
            header: new DataTableHeader({ i18n: 'MEMBER_plural' }),
        }),
        new DataTableButtonColumn<EmployeeGroup>({
            buttons: [
                {
                    icon: 'edit',
                    click: this.editGroup.bind(this),
                    show: this.canEdit.bind(this),
                    tooltip: { key: 'EDIT' },
                },
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.delete.bind(this),
                    show: this.canDelete.bind(this),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    // Button for opening employee group creation dialog
    fabButton: HeaderFabButton = {
        hasPermission: () => this.canCreate(),
        click: this.createGroup.bind(this),
    };

    constructor(
        @Inject(EmployeeGroupsService) private employeeGroupsService: EmployeeGroupsService,
        @Inject(CurrentService) public current: CurrentService,
        @Inject(TranslateService) public translateService: TranslateService,
        @Inject(SnackBarService) protected snackBarService: SnackBarService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(PromptDialogService) protected promptDialogService: PromptDialogService,
    ) {
    }

    createGroup() {
        this.promptDialogService.open('text', {
            size: DialogSize.Medium,
            formControl: new FormControl<string>('', { validators: Validators.required }),
            title: this.translateService.t('CREATE_EMPLOYEE_GROUP', 'company'),
            label: this.translateService.t('NAME'),
            confirmText: this.translateService.t('CREATE'),
        }).afterClosed().subscribe((name) => {
            if (!name) {
                return;
            }

            this.employeeGroupsService.create(this.customerId, name).subscribe((group) => {
                this.table?.refresh();
                void this.snackBarService.t('X_CREATED', 'general', { name: group.name });
            });
        });
    }

    editGroup(cell: DataTableButtonCell<EmployeeGroup>) {
        this.promptDialogService.open('text', {
            size: DialogSize.Medium,
            formControl: new FormControl<string>(cell.item.name, { validators: Validators.required }),
            title: this.translateService.t('EDIT_EMPLOYEE_GROUP', 'company'),
            label: this.translateService.t('NAME'),
            confirmText: this.translateService.t('UPDATE'),
        }).afterClosed().subscribe((name) => {
            if (!name) {
                cell.disabled.set(false);
                return;
            }

            this.employeeGroupsService.update(this.customerId, cell.item.id, name).subscribe((group) => {
                this.table?.refresh();
                void this.snackBarService.t('X_UPDATED', 'general', { name: group.name });
            });
        });
    }

    canCreate() {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employee_groups.create`);
    }

    canEdit(groups: EmployeeGroup) {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employee_groups.${groups.id}.update`);
    }

    canDelete(groups: EmployeeGroup) {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employee_groups.${groups.id}.delete`);
    }

    updateTable($event: Partial<DataTablePagination>) {
        this.pagination = $event;
        this.request = this.employeeGroupsService.getAll(this.customerId, {
            direction: $event.direction,
            order_by: $event.order_by,
            per_page: $event.per_page,
            page: $event.page,
            'count[]': [ 'members' ],
        });
    }

    delete(cell: DataTableButtonCell<EmployeeGroup>) {
        const hasMembers = cell.item.membersCount != null && cell.item.membersCount > 0;

        if (hasMembers) {
            cell.disabled.set(false);

            return this.matDialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
                data: {
                    title: signal(this.translateService.t('DELETE_EMPLOYEE_GROUP', Namespace.Company)),
                    text: signal(this.translateService.t('EMPLOYEE_GROUP_CANNOT_BE_DELETED', Namespace.Company)),
                },
            });
        }

        return this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translateService.t('DELETE_EMPLOYEE_GROUP', Namespace.Company),
                text: this.translateService.t('CONFIRM_DELETING_EMPLOYEE_GROUP', Namespace.Company),
                confirmText: this.translateService.t('DELETE'),
            },
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.employeeGroupsService.delete(this.customerId, cell.item.id).pipe(
                catchError(() => {
                    cell.disabled.set(false);
                    return EMPTY;
                }),
            ).subscribe(() => {
                void this.snackBarService.deleted();
                this.table?.refresh();
            });
        });
    }
}
