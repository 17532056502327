// @ts-nocheck
import { pick } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
module('eaw.admin').factory('TariffRate', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const factory = this;
    const url = '/tariffs/:tariff_id/rates/:rate_id';
    factory.get = (options) => EawResource.create(url).get({
        tariff_id: options.tariff_id,
        rate_id: options.rate_id,
    });
    factory.getActive = {
        /**
         *
         * @param {int} settingGroupId
         * @returns {string}
         */
        permission(settingGroupId) {
            return `setting_groups.${settingGroupId}.tariffs.*.rates.*.get`;
        },
        /**
         *
         * @param {int} settingGroupId
         * @param {moment} date
         * @param {Pagination} pagination
         * @param {string[]} withRelations
         * @returns {*}
         */
        query(settingGroupId, date, pagination, withRelations) {
            return EawResource.create('/setting_groups/:setting_group_id/tariff_rates').get({
                ...Pagination.getParams(pagination),
                setting_group_id: settingGroupId,
                date: date || moment().startOf('d'),
                'with[]': withRelations || [ 'tariff' ],
                _businessDates: [ 'date' ],
            });
        },
    };
    factory.getAll = (args) => {
        const get = Pagination.getParams(args, [ 'tariff_id' ]);
        return EawResource.create(url).get(Object.assign({
            tariff_id: args.tariff.id,
        }, get));
    };
    factory.update = (rate) => EawResource.create(url).update({
        tariff_id: rate.tariff_id,
        rate_id: rate.id,
    }, {
        ...pick(rate, [ 'from', 'to', 'rate' ]),
        _businessDates: [ 'from', 'to' ],
    });
    factory.create = (rate) => EawResource.create(url).save({
        tariff_id: rate.tariff_id,
    }, {
        ...pick(rate, [ 'from', 'to', 'rate' ]),
        _businessDates: [ 'from', 'to' ],
    });
    factory.delete = (rate) => EawResource.create(url).delete({
        tariff_id: rate.tariff_id,
        rate_id: rate.id,
    });
    return factory;
} ]);
