// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.projections').controller('createProjectionsDialog', [ 'date', '$mdDialog', function(date, $mdDialog) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.date = date;
        ctrl.interval = 60; // Default

        ctrl.intervals = [
            {
                value: 60,
                text: t('X_HOURS', { count: 1 }),
            },
            {
                value: 30,
                text: t('X_MINUTES', { count: 30 }),
            },
            {
                value: 15,
                text: t('X_MINUTES', { count: 15 }),
            },
        ];
    };

    ctrl.create = () => {
        const projections = [];
        const time = ctrl.date.clone().startOf('d');
        const nextDay = ctrl.date.clone().startOf('d').add(1, 'd');

        while (time.isBefore(nextDay)) {
            projections.push({
                date: time.format('YYYY-MM-DD'),
                from: time.format('YYYY-MM-DD HH:mm:ss'),
                to: time.clone().add(ctrl.interval, 'm').format('YYYY-MM-DD HH:mm:ss'),
                transactions: 0,
                sales: 0,
            });

            time.add(ctrl.interval, 'm');
        }

        $mdDialog.hide(projections);
    };
} ]);
