import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { EmployeeGroupsResponse, EmployeeGroup } from '../models/employee-group';
import { DateTime } from 'luxon';
import { EmployeeGroupMembership, EmployeeGroupMembershipResponse } from '../models/employee-group-membership';
import { BUSINESS_DATES } from '../../shared/http/http-contexts';
import { Employee, EmployeeResponse } from '../../shared/models/employee';

interface GetMembersOptions extends PaginationOptions {
    include_inactive?: boolean;
}

@Injectable({
    providedIn: 'any',
})
export class EmployeeGroupsService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, pagination?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<EmployeeGroupsResponse>>(`/customers/${customerId}/employee_groups`, {
            params: formatHttpParams(pagination),
        }).pipe(classifyArrayPaginatedResponse(EmployeeGroup));
    }

    get(customerId: number, groupId: number) {
        return this.http.get<EmployeeGroupsResponse>(`/customers/${customerId}/employee_groups/${groupId}`).pipe(
            map((response) => {
                return 'id' in response ? new EmployeeGroup(response) : null;
            }),
        );
    }

    getMembers(customerId: number, groupId: number, pagination?: GetMembersOptions) {
        return this.http.get<ArrayPaginatedResponse<EmployeeGroupMembershipResponse>>(`/customers/${customerId}/employee_groups/${groupId}/members`, {
            params: formatHttpParams(pagination),
        }).pipe(
            classifyArrayPaginatedResponse(EmployeeGroupMembership),
        );
    }

    create(customerId: number, name: string) {
        return this.http.post<EmployeeGroupsResponse>(`/customers/${customerId}/employee_groups`, {
            name,
        }).pipe(classifyItem(EmployeeGroup));
    }

    update(customerId: number, groupId: number, name: string) {
        return this.http.put<EmployeeGroupsResponse>(`/customers/${customerId}/employee_groups/${groupId}`, {
            name,
        }).pipe(classifyItem(EmployeeGroup));
    }

    delete(customerId: number, groupId: number) {
        return this.http.delete<undefined>(`/customers/${customerId}/employee_groups/${groupId}`);
    }

    connectMember(customerId: number, groupId: number, employeeId: number, from: DateTime, to: DateTime | null) {
        return this.http.post<EmployeeResponse>(`/customers/${customerId}/employee_groups/${groupId}/members`, {
            'member_id': employeeId,
            from,
            to,
        },{
            context: new HttpContext().set(BUSINESS_DATES, [ 'from', 'to' ]),
        }).pipe(classifyItem(Employee));
    }

    removeMember(customerId: number, groupId: number, employeeId: number) {
        return this.http.delete<undefined>(`/customers/${customerId}/employee_groups/${groupId}/members/${employeeId}`);
    }
}
