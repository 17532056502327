<mat-card>
    <mat-card-content class="centered">
        {{ 'UNASSIGNED_SHIFTS_AFTER_GENERATING_SCHEDULE' | translate: 'autosched' | async }} <br>
    </mat-card-content>
</mat-card>
@if (!((employees | async) && shifts && results)) {
    <div class="centered">
        <mat-progress-spinner diameter="64" strokeWidth="6" mode="indeterminate"></mat-progress-spinner>
    </div>
} @else {
    <mat-card class="shift">
        @for (result of results | keyvalue: keyVal; track result.key) {
            @if (shifts[result.key]) {
                <eaw-auto-populating-shift-result [shift]="shifts[result.key]!"
                                                  [results]="result.value"
                                                  [employeeNames]="(employees | async)!"/>
            }
        }
    </mat-card>
}
