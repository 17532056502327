import { Inject, Injectable } from '@angular/core';
import { Autocomplete } from './autocomplete';
import { Employee } from '../models/employee';
import { TranslateService } from '../services/translate.service';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { ShiftEmployeeService } from '../../scheduling/http/shift-employee.service';
import { EmployeeService } from '../http/employee.service';
import { BusinessDate } from '../utils/business-date';
import { mockArrayPaginatedResponse } from '../../../mocks/paginated-response.mock';
import { ShiftEmployee } from '../../scheduling/models/shift-employee';

interface GetEmployeesArgs {
    customerId: number;
    scheduleId: number,
    shiftId?: number,
    businessDate?: BusinessDate;
}

interface SetEmployeeArgs {
    customerId: number;
    scheduleId: number,
    shiftId?: number,
    businessDate?: BusinessDate;
}

@Injectable({
    providedIn: 'root',
})
export class ShiftEmployeeAutocompleteService extends Autocomplete<ShiftEmployee | Employee> {
    constructor(
        @Inject(ShiftEmployeeService) protected shiftEmployeeService: ShiftEmployeeService,
        @Inject(EmployeeService) protected employeeService: EmployeeService,
        @Inject(TranslateService) protected translateService: TranslateService,
    ) {
        super({
            label: translateService.t('EMPLOYEE'),
            optionTemplate: 'shiftEmployee',
            filterRequirement: 3,
            display: 'name',
            trackByKey: 'id',
        });
    }

    setter(args: SetEmployeeArgs): (item: unknown) => Observable<Employee | ShiftEmployee | undefined> {
        return (item: unknown) => {
            if (!item || (typeof item !== 'number' && !(item instanceof Employee))) {
                return of(undefined);
            }

            if (item instanceof Employee) {
                return of(item);
            }

            if (args.shiftId) {
                return this.shiftEmployeeService.getEmployee(args.customerId, args.scheduleId, args.shiftId);
            }

            const employeeObservable = this.employeeService.get(args.customerId, item);
            return employeeObservable.pipe(
                catchError(() => of(undefined)),
                switchMap((employee) => {
                    if (!employee) {
                        return of(undefined);
                    }

                    if (args.shiftId) {
                        return this.shiftEmployeeService.getEmployee(args.customerId, args.scheduleId, args.shiftId);
                    }

                    if (args.businessDate) {
                        return this.shiftEmployeeService.getEmployeesByBusinessDate(args.customerId, args.scheduleId, args.businessDate, employee.name).pipe(
                            map((response) => response.data[0]),
                        );
                    }

                    return of(undefined);
                }),
            );
        };
    }

    getter(args: GetEmployeesArgs) {
        return (filter?: string) => {
            if (args.shiftId) {
                return this.shiftEmployeeService.getEmployees(args.customerId, args.scheduleId, args.shiftId, filter);
            }
            if (args.businessDate) {
                return this.shiftEmployeeService.getEmployeesByBusinessDate(args.customerId, args.scheduleId, args.businessDate, filter);
            }

            return of(mockArrayPaginatedResponse<ShiftEmployee>());
        };
    };
}
