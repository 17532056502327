<eaw-page-header>
    <span title>{{ 'REGISTER_ABSENCE' | translate:'absences' | async }}</span>
</eaw-page-header>

@if (canOtherEmployees) {
    <mat-card>
        <mat-card-content class="tw-h-full">
            <form [formGroup]="headerForm" class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 md:tw-grid-cols-4 tw-gap-16">
                <mat-form-field>
                    <mat-label>{{ 'INCLUDE_EXTERNAL_plural' | translate:'absences' | async }}</mat-label>
                    <mat-select formControlName="includeExternal">
                        <mat-option [value]="true">{{ 'YES' | translateSync }}</mat-option>
                        <mat-option [value]="false">{{ 'NO' | translateSync }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'PERIOD' | translate:'absences' | async }}</mat-label>
                    <mat-select formControlName="period" (valueChange)="optionsChange()">
                        <mat-option [value]="'current'">{{ 'CURRENTLY_ACTIVE' | translateSync }}</mat-option>
                        <mat-option [value]="'all'">{{ 'ALL' | translateSync }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </mat-card-content>
    </mat-card>
}
<div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-16 tw-mt-16">
    @for (absence of absences; track absence; let i = $index) {
        <mat-card [class.disabled]="absence.saved">
            @if (absence.loading) {
                <mat-card-content class="tw-p-16 tw-flex tw-justify-center tw-items-center">
                    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                </mat-card-content>
            } @else {
                <mat-card-content class="tw-flex tw-flex-col tw-h-full">
                    <form [formGroup]="absence.form" class="tw-h-full" (ngSubmit)="save(absence)">
                        <div class="tw-grid tw-grid-cols-4 tw-gap-8">
                            <eaw-autocomplete class="tw-col-span-full"
                                              formControlName="employee"
                                              (change)="changeEmployee(absence)"
                                              [options]="employeeAutocompleteService.options"
                                              [triggers]="[headerForm]"
                                              [getter]="employeeAutocompleteService.getter({
                                      customerId: customer.id,
                                      includeExternal: headerForm.controls.includeExternal.value,
                                      active: headerForm.controls.period.value === 'current' ? active : undefined,
                                      includeInactive: headerForm.controls.period.value === 'all',
                                  })"
                                              [setter]="employeeAutocompleteService.setter({customerId: customer.id})">
                            </eaw-autocomplete>

                            <eaw-autocomplete
                                    [class.tw-col-span-3]="absence.canSeeStatistics"
                                    [class.tw-col-span-full]="!absence.canSeeStatistics"
                                    [formControl]="absence.form.controls.type"
                                    [hint]="absence.form.controls.type.value?.description | translate: 'absence_types'"
                                    [options]="absenceTypeAutocompleteService.options"
                                    [getter]="absenceTypeAutocompleteService.getter({id: customer.id || 0})"
                                    [setter]="absenceTypeAutocompleteService.setter({id: customer.id || 0})">
                            </eaw-autocomplete>

                            @if (absence.canSeeStatistics) {
                                <button mat-icon-button
                                        (click)="showInfo(absence)"
                                        color="primary"
                                        type="button"
                                        class="tw-justify-center tw-content-center">
                                    <mat-icon>info</mat-icon>
                                </button>
                            }
                            @if (approvalDeadlineEnabled) {
                                @if (absence.form.controls.type.value?.approvalDeadlineDays && !absence.saved) {
                                    <div class="info-blurb" eawMaterialColor="blue-100" colorProperty="backgroundColor">
                                        <div class="tw-flex tw-gap-4 tw-items-center">
                                            <mat-icon color="primary">priority_high</mat-icon>
                                            <h3>{{ 'APPROVAL_DEADLINE' | translate: 'absences' | async }}</h3>
                                        </div>
                                        <p>{{ 'DAYS_UNTIL_APPROVAL_DEADLINE_INFO' | translate: 'absences': { count: absence.form.controls.type.value?.approvalDeadlineDays } | async }}</p>
                                    </div>
                                } @else if (absence.absence?.approvalDeadline) {
                                    <div class="info-blurb" eawMaterialColor="blue-grey-100" colorProperty="backgroundColor">
                                        <div class="tw-flex tw-gap-4 tw-items-center">
                                            <mat-icon color="primary">priority_high</mat-icon>
                                            <h3>{{ 'APPROVAL_DEADLINE' | translate: 'absences' | async }}</h3>
                                        </div>
                                        <p>{{ 'DATE_FOR_ABSENCE_APPROVAL_INFO' | translate: 'absences':{ date: absence.absence?.approvalDeadline | DateTime: 'DATE_MED' } | async }}</p>
                                    </div>
                                }
                            }

                            <div [formGroup]="absence.form.controls.interval" class="tw-col-span-full">
                                @if (absences[i]?.form?.controls?.type?.value?.span === 'hour') {
                                    <div class="tw-grid tw-grid-cols-2 tw-gap-8">
                                        <mat-form-field eawDatePickerOptions class="tw-col-span-full">
                                            <mat-label>{{ 'DATE' | translate | async }}</mat-label>
                                            <input matInput
                                                   autocomplete="off"
                                                   [matDatepicker]="$any(toPicker)"
                                                   formControlName="date">
                                            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                                            <mat-datepicker #toPicker></mat-datepicker>
                                        </mat-form-field>
                                        <eaw-time-input formControlName="from"
                                                        [label]="'START' | translate"
                                                        class="tw-col-start-1"></eaw-time-input>
                                        <eaw-time-input formControlName="to"
                                                        [label]="'END' | translate"
                                                        class="tw-col-end-3"></eaw-time-input>
                                    </div>
                                } @else {
                                    <mat-form-field eawDatePickerOptions class="tw-w-full">
                                        <mat-label>{{ 'ENTER_DATE_RANGE' | translate | async }}</mat-label>
                                        <mat-date-range-input [rangePicker]="picker" class="tw-basis-full">
                                            <input matStartDate
                                                   formControlName="from"
                                                   [placeholder]="'START_DATE' | translate | async"
                                                   autocomplete="off">
                                            <input matEndDate
                                                   formControlName="to"
                                                   [placeholder]="'END_DATE' | translate | async"
                                                   autocomplete="off">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                    </mat-form-field>
                                }
                            </div>

                            @if (absence.form.controls.type.value) {
                                @if (absence.editLength.get(absence.form.controls.type.value.id)) {
                                    <mat-form-field class="tw-col-span-2">
                                        <mat-label>{{ 'ABSENCE_LENGTH' | translate: 'absences' | async }}</mat-label>
                                        <input type="number" matInput [formControl]="absence.form.controls.hourLength">
                                    </mat-form-field>
                                } @else if (absence.saved) {
                                    <mat-form-field class="tw-col-span-2">
                                        <mat-label>{{ 'ABSENCE_LENGTH' | translate: 'absences' | async }}</mat-label>
                                        <input type="number" matInput [formControl]="absence.form.controls.hourLength">
                                    </mat-form-field>
                                }
                                @if (absence.form.controls.type.value.gradable) {
                                    <mat-form-field class="tw-col-span-2">
                                        <mat-label>{{ 'GRADE' | translate: 'absences' | async }}</mat-label>
                                        <input type="number"
                                               inputmode="numeric"
                                               [formControl]="absence.form.controls.gradePercent"
                                               min="1"
                                               max="100"
                                               matInput>
                                    </mat-form-field>
                                }
                            }

                            @if (absence.canApprove) {
                                <mat-slide-toggle [formControl]="absence.form.controls.approved"
                                                  class="tw-col-span-full tw-mb-12">{{ 'APPROVE' | translate | async }}
                                </mat-slide-toggle>
                            }
                            @if (showGradedWarning(absence)) {
                                <div class="tw-col-span-full tw-mb-8">{{ 'WARNING_APPROVE_GRADED_ABSENCE_WO_HANDLING' | translate: 'absences' | async }}
                                </div>
                            }
                            @if (hasScheduling() && absence.form.controls.approved.value) {
                                <eaw-absence-actions class="tw-col-span-full" [formControl]="absence.form.controls.releaseShifts"></eaw-absence-actions>
                            }
                            @for (field of customFields; track field.key) {
                                <eaw-custom-field-input class="tw-col-span-full"
                                                        [customField]="field"
                                                        booleanDisplay="slide-toggle"
                                                        [formGroup]="absence.form.controls.customFields">
                                </eaw-custom-field-input>
                            }
                            <mat-form-field class="tw-col-span-full">
                                <mat-label>{{ 'COMMENT' | translate | async }}</mat-label>
                                <textarea [formControl]="absence.form.controls.comment" matInput></textarea>
                            </mat-form-field>

                            <mat-form-field class="tw-col-span-full">
                                <mat-label>{{ 'ATTACHMENT' | translate | async }}</mat-label>
                                <eaw-file-selector [placeholder]="('CHOOSE_FILE' | translate | async)!"
                                                   [disabled]="absence.form.disabled || absence.saved"
                                                   [fileFormats]="allowedFileFormats"
                                                   (fileChange)="onFileChange($event)"></eaw-file-selector>
                                @if (requireValidFormat) {
                                    <mat-hint eawMaterialColor="red-500">{{ 'REQUIRED_PDF' | translate | async }}
                                    </mat-hint>
                                }
                            </mat-form-field>
                        </div>
                    </form>
                </mat-card-content>
            }
            @if (absence.saved) {
                <div class="leaveshift-text tw-ml-[32px]"
                     [class.leaveshift-red]="absence.leaveShiftCount === 0">
                    {{ 'LEAVE_SHIFT_plural' | translate: 'leaveshifts' | async }} : {{ absence.leaveShiftCount }}
                </div>
            }
            <mat-card-footer>
                <div class="tw-p-16">
                    @if (absence.saved) {
                        @if (absence.canApprove) {
                            <button mat-raised-button
                                    (click)="goToHandling(absence)"
                                    [color]="'primary'">{{ 'GO_TO_ABSENCE' | translate: 'absences' | async }}
                            </button>
                        }
                        @if (absence.absence!.warnings) {
                            <button mat-raised-button
                                    type="button"
                                    class="warning-button tw-m-16"
                                    (click)="openWarnings(absence)">
                                <mat-icon>warning</mat-icon>
                            </button>
                        }
                    } @else if (!absence.saving) {
                        <button mat-raised-button
                                type="button"
                                [color]="'accent'"
                                [disabled]="absence.form.invalid"
                                (click)="save(absence)">{{ 'SAVE' | translate | async }}
                        </button>
                    }
                </div>
            </mat-card-footer>
        </mat-card>
    }
</div>
