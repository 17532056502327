import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit, Signal, signal } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { SignalInput } from '../../../shared/decorators/signal-input.decorator';
import { Widget } from '../../classes/widget';
import { DateTime } from 'luxon';
import { CalendarEventService } from '../../../calendar/services/calendar-event.service';
import { tap } from 'rxjs';
import { TranslateService } from '../../../shared/services/translate.service';
import { Namespace } from '../../../shared/enums/namespace';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaw-upcoming-event',
    standalone: true,
    imports: [
        MiniWidgetContentComponent,
    ],
    templateUrl: './mini-upcoming-event-widget.component.html',
    styleUrl: './mini-upcoming-event-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniUpcomingEventWidgetComponent extends WidgetComponent implements OnInit {
    private readonly calendarEventService = inject(CalendarEventService);
    private readonly translate = inject(TranslateService);
    private readonly destroyRef = inject(DestroyRef);

    @Input({ required: true }) @SignalInput() widget!: Signal<Widget>;

    protected date = signal('');
    protected name = signal<string | Promise<string>>('');

    ngOnInit(): void {
        const now = DateTime.now();
        this.calendarEventService.getAll(this.widget().info.customer.id, {
            from: now.startOf('day'),
            per_page: 1,
            direction: 'asc',
            order_by: 'from',
        }).pipe(
            // Stops request if user navigates away from the dashboard
            takeUntilDestroyed(this.destroyRef),
            tap((page) => {
                const event = page.data[0];
                if (event) {
                    this.date.set(event.from.toLocaleString(DateTime.DATE_MED));
                    this.name.set(event.name);
                } else {
                    this.name.set(this.translate.t('NO_UPCOMING_EVENT', Namespace.Widgets));
                }
                this.setLoading(false);
            }),
        ).subscribe();
    }
}
