@if (pagination?.total) {
    <div class="info-subtitle">
        @if (widget().getSetting('days')) {
            <span>{{'X_BIRTHDAYS_THE_NEXT_Y_DAYS' | translate: 'widgets': {count: pagination?.total, days: widget().getSetting('days')} | async}}</span>
        } @else {
            <span>{{'X_BIRTHDAYS' | translate: 'widgets': {count: pagination?.total} | async}} {{'TODAY' | translate | async | lowercase}}</span>
        }
    </div>
}

<mat-list class="tw-py-0" role="list" [class.empty-list]="!pagination?.total">
    @for (emp of employees(); track emp.employee.id) {
        <mat-list-item [class.birthday-today]="emp.birthday?.hasSame(today, 'day')" role="listitem">
            <eaw-profile-picture matListItemAvatar [user]="{id: emp.employee.userId, name: emp.employee.name}"></eaw-profile-picture>
            <span matListItemTitle>
                <span>{{emp.employee.name}} - {{getBirthdayText(emp)}}</span>
            </span>
            <span matListItemLine>
                {{emp.employee.birthDate | DateTime: 'DATE_MED'}} | {{emp.customers[0]}}
                @if (emp.customers.length > 1) {
                    <span class="more-customers" [title]="emp.customers.join('\n')"> (+ {{emp.customers.length - 1}})</span>
                }
            </span>
        </mat-list-item>
        @if (!$last) {
            <mat-divider></mat-divider>
        }
    } @empty {
        <mat-list-item disabled>
            @if (widget().getSetting('days')) {
                <h3 class="tw-text-center">{{'X_BIRTHDAYS_THE_NEXT_Y_DAYS' | translate: 'widgets': {count: 0, days: widget().getSetting('days')} | async}}</h3>
            } @else {
                <h3 class="tw-text-center">{{'X_BIRTHDAYS' | translate: 'widgets': {count: 0} | async}} {{'TODAY' | translate | async | lowercase}}</h3>
            }
        </mat-list-item>
    }
</mat-list>

@if (pagination && pagination.current_page < pagination.last_page) {
    <button class="tw-mx-auto tw-block"
            mat-raised-button
            color="accent"
            [disabled]="onLoading() | async"
            (click)="getEmployees(pagination.current_page + 1)">
        {{'LOAD_MORE' | translate | async}}
    </button>
}
