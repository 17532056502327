// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
import { lastValueFrom, of } from 'rxjs';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
import { OldClassAsResponse } from '../../../../shared/utils/old-class-as-response';
import { Comment } from '../../../../shared/models/comment';
import { CommentDialogComponent, CommentDialogData } from '../../../../shared/dialogs/comments-dialog/comment-dialog.component';
const template3 = `<md-dialog>
    <form name="customDialogForm" ng-cloak>
        <eaw-dialog-header-old heading="'AVAILABILITY' | i18next"></eaw-dialog-header-old>

        <md-dialog-content>
            <availability-days-downgrade [availability]="$ctrl.modalAvailability"></availability-days-downgrade>

            <div class="md-dialog-content" ng-if="!$ctrl.usesFranceCombinedContract">
                <form class="form" name="handleForm" id="handleForm" ng-if="$ctrl.showProcessing">
                    <md-input-container class="md-block" ng-if="!$ctrl.modalAvailability.approval.id">
                        <label for="commentBody" ng-i18next="COMMENT"></label>
                        <textarea id="commentBody" rows="2" ng-model="$ctrl.comment"></textarea>
                    </md-input-container>

                    <md-radio-group ng-model="$ctrl.approved" ng-if="!$ctrl.modalAvailability.approval.id">
                        <md-radio-button ng-value="true">{{'APPROVE' | i18next}}</md-radio-button>
                        <md-radio-button ng-value="false">{{'DECLINE' | i18next}}</md-radio-button>
                    </md-radio-group>
                </form>

                <eaw-comment-list [angularjscomments]="$ctrl.modalAvailability.comments"></eaw-comment-list>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CLOSE"></md-button>

            <md-button ng-if="$ctrl.showProcessing && !$ctrl.modalAvailability.approval.id && !$ctrl.usesFranceCombinedContract"
                       form="handleForm"
                       close-dialog
                       resolve="true"
                       reason="{approved: $ctrl.approved, comment: $ctrl.comment}"
                       ng-i18next="SUBMIT"
                       ng-disabled="$ctrl.modalAvailability.approval && $ctrl.modalAvailability.approval.approved"
                       class="md-accent">
            </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;
module('eaw.availability').component('availabilityManageTab', {
    template: `<script type="text/ng-template" id="wanteddaystemplateajshtml">
    <div ng-repeat="wantedDays in item.work_days track by $index" ng-if="$index < item.repeatWeeks">
        <span ng-i18next="[i18next]({number: $index + 1})WEEK_N"></span> - <span ng-i18next="[i18next]({count: wantedDays})N_DAY"></span>
    </div>
</script>

<md-card ng-show="$ctrl.showFilter">
    <md-card-content>
        <div layout="row">
            <employee-autocomplete ng-model="$ctrl.employee" flex="25"></employee-autocomplete>
            <approval-mode flex="25" ng-model="$ctrl.mode" default-mode="unprocessed"></approval-mode>
        </div>

        <div>
            <md-button class="md-raised md-accent" ng-i18next="UPDATE_LIST" ng-click="$ctrl.reload()"></md-button>
        </div>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="availabilities:AVAILABILITY_plural"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="$ctrl.showOutdated"
                        icon="history"
                        i18n-tooltip="availabilities:INCLUDE_OUTDATED"
                        ng-change="$ctrl.reload()">
        </eaw-toggle-btn>

        <eaw-toggle-btn ng-model="$ctrl.showFilter"
                        icon="filter_alt"
                        toggled-initially="true"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                get-data="$ctrl.getData(pagination)"
                table-row-click="$ctrl.showAvailability(item)"
                reload-table="$ctrl.reloadTable"
                cells="$ctrl.cells"
                columns="$ctrl.cols">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
        usesFranceCombinedContract: '<',
    },
    controller: [ 'Availability', 'MatDialogDowngrade', 'availabilityService', 'ToastService', 'UrlParams', '$mdDialog', 'eawDatePickerDialog', function(Availability, MatDialogDowngrade, availabilityService, ToastService, UrlParams, $mdDialog, eawDatePickerDialog) {
        const ctrl = this;
        ctrl.$onInit = () => {
            UrlParams.getEmployee(ctrl.customer.id, 'e').then((e) => {
                ctrl.showOutdated = UrlParams.get('outdated', false);
                ctrl.employee = e;
                ctrl.finishedEmployee = true;
                ctrl.reload();
            });
            ctrl.createTable();
        };
        ctrl.reload = () => {
            ctrl.reloadTable = true;
        };
        ctrl.createTable = () => {
            ctrl.cols = [
                {
                    title: t('CREATED_AT'),
                    sortable: 'created_at',
                },
                {
                    title: t('EMPLOYEE'),
                    sortable: 'employee_id',
                },
                {
                    title: t('LENGTH'),
                    class: 'hidden-xs',
                },
                { title: t('availabilities:WANTED_DAYS') },
                {
                    title: t('FROM'),
                    class: 'hidden-xs',
                    sortable: 'from',
                },
                {
                    title: t('TO'),
                    class: 'hidden-xs',
                    sortable: 'to',
                },
                {
                    title: t('APPROVED'),
                    class: 'text-center',
                },
                { title: '' },
            ];
            ctrl.cells = [
                { template: `item.created_at | moment:'lll'` },
                { template: 'item.employee.name' },
                {
                    template: `item.repeat * 24 * 60 * 60 | eawDuration:'long':'wd'`,
                    class: 'hidden-xs',
                },
                { templateUrl: 'wanteddaystemplateajshtml' },
                {
                    template: 'item.from | moment: "lll"',
                    class: 'hidden-xs',
                },
                {
                    template: 'item.to | moment: "lll"',
                    class: 'hidden-xs',
                },
                {
                    approval: {
                        displayName: true,
                        displayTime: true,
                    },
                },
                {
                    buttons: [
                        {
                            icon: 'comment',
                            invisible: (item) => !item.comments.length,
                            tooltip: t('COMMENT_plural'),
                            click: (item) => {
                                MatDialogDowngrade.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
                                    data: {
                                        comments: of(item.comments.map((c) => new Comment(c))),
                                    },
                                });
                            },
                        },
                        {
                            icon: 'stop_circle',
                            invisible: (item) => !ctrl.canStop(item),
                            tooltip: t('availabilities:STOP'),
                            click: ctrl.stop,
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            invisible: (item) => !ctrl.canDelete(item),
                            tooltip: t('DELETE'),
                            click: ctrl.delete,
                        },
                    ],
                },
            ];
        };
        ctrl.stop = (availability) => {
            eawDatePickerDialog.open(null, {
                heading: t('availabilities:STOP_AVAILABILITY'),
                minDate: availability.from,
                focusOnOpen: true,
                hasBackdrop: true,
                async callback(date) {
                    availability.to = date.endOf('d');
                    await availability.update();
                    ctrl.reload();
                },
            });
        };
        ctrl.delete = (availability) => {
            $mdDialog.show($mdDialog.confirm()
                .theme('delete')
                .title(t('availabilities:DELETE_AVAILABILITY'))
                .textContent(t('availabilities:DELETE_EMPLOYEE_AVAILABILITY', {
                    employee: availability?.employee.name,
                }))
                .ok(t('DELETE'))
                .cancel(t('CANCEL'))).then(() => {
                availability.delete().then(() => {
                    ToastService.toast(t('availabilities:AVAILABILITY_DELETED'));
                    ctrl.reloadTable = true;
                });
            });
        };
        ctrl.canStop = (availability) => availability.approval?.approved && availability.to == null && !ctrl.usesFranceCombinedContract;

        ctrl.canDelete = (availability) => {
            return [
                !ctrl.usesFranceCombinedContract,
                !availability.approval,
                CurrentOld.can(`customers.${ctrl.customer.id}.availabilities.${availability.id}.delete`),
            ].every((x) => x);
        };

        ctrl.showAvailability = (availability) => {
            $mdDialog.show({
                template: template3,
                bindToController: true,
                resolve: {
                    usesFranceCombinedContract: function() {
                        return ctrl.usesFranceCombinedContract;
                    },
                    modalAvailability: function() {
                        return lastValueFrom(availabilityService.get(availability.customer_id, availability.employee_id, availability.id, [ 'comments.user', 'days', 'approval' ]));
                    },
                    showProcessing: function() {
                        const now = moment();
                        return (!availability.approval && (!availability.to || availability.to.isAfter(now))) || availability.from.isAfter(now);
                    },
                },
                controllerAs: '$ctrl',
                controller: function() {
                    this.$onInit = () => {
                        this.approved = true;
                    };
                },
            }).then((result) => {
                availability.approved = result.approved;
                availability.comment = result.comment;
                availability.update().then(() => {
                    ctrl.reloadTable = true;
                    ToastService.toast(t(result.approved ? 'REQUEST_APPROVED' : 'REQUEST_DECLINED'));
                });
            });
        };
        ctrl.getData = (pagination) => {
            if (!ctrl.finishedEmployee) {
                return;
            }
            UrlParams.set('outdated', ctrl.showOutdated);
            UrlParams.set('e', ctrl.employee?.id);
            return Availability.getAll({
                ...ctrl.mode,
                ...pagination.toWithPaginatorArguments(),
                include_outdated: ctrl.showOutdated,
                with: [ 'approval', 'employee', 'comments' ],
                customer_id: ctrl.customer.id,
                employee_id: ctrl.employee?.id,
            });
        };
    } ],
});
