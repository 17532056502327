// @ts-nocheck
import { module } from 'angular';
import { EawDialogController } from '../eaw-dialog-controller';
import {smallDevice} from '../../misc/services/easy-funcs.service';
const template1 = `<md-dialog>
    <eaw-dialog-header-old ng-if="dialog.heading" heading="dialog.heading"></eaw-dialog-header-old>
    <md-dialog-content>
        <div ng-class="dialog.contentClass" style="height: 100%">
            <component-creator component-name="{{dialog.component}}" bindings="dialog.componentBindings" params="dialog.params"></component-creator>
        </div>
    </md-dialog-content>
    <md-dialog-actions>
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
const tabbedTemplate = `<md-dialog>
    <eaw-dialog-header-old heading="dialog.heading | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <md-tabs md-stretch-tabs="always" md-dynamic-height>
            <md-tab label="{{tab.label | i18next}}" ng-repeat="tab in dialog.tabs">
                <component-creator component-name="{{tab.name}}"
                                   bindings="tab.bindings"
                                   params="tab.params">
                </component-creator>
            </md-tab>
        </md-tabs>
    </md-dialog-content>
    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.dialogs').factory('componentDialog', [ '$mdDialog', function componentDialog($mdDialog) {
    const settings = {
        controller: EawDialogController,
        controllerAs: 'dialog',
        clickOutsideToClose: true,
        multiple: true,
        fullscreen: smallDevice(),
    };
    return {
        close: $mdDialog.hide.bind($mdDialog),
        simple: function(component, componentBindings, heading, params, contentClass) {
            return $mdDialog.show({
                template: template1,
                locals: {
                    heading,
                    component,
                    componentBindings,
                    params,
                    contentClass: contentClass == null ? 'md-dialog-content' : contentClass,
                },
                ...settings,
            });
        }.bind(this),
        tabs: function(components, heading) {
            return $mdDialog.show({
                template: tabbedTemplate,
                locals: {
                    heading,
                    tabs: components,
                },
                ...settings,
            });
        }.bind(this),
    };
} ]);
