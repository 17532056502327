import { Model } from './model';
import { UserOld } from './user-old';
import { CustomerProductService } from '../http/customer-product.service';
import { CurrentOld } from './current.factory';

interface Product {
    active: boolean;
    name: string;
    properties: any;
}

/**
 * @deprecated
 */
export default class CustomerOld extends Model {
    user: any;
    organization_number?: number;
    products?: Product[] | undefined;
    setting_group_id!: number;
    settingGroupId!: number;
    custom_fields: any;
    time_zone!: string;
    address1?: string;
    address2?: string;
    active?: boolean;
    stack?: any;
    billing_customer?: any;
    setting_group?: any;
    name!: string;
    customerProductService?: CustomerProductService;
    customerGroups: Record<any, any>[] = [];

    constructor(data: any, user?: UserOld) {
        super(data);

        this.user = user;
        this.organization_number = Number.isFinite(parseInt(data.organization_number)) ? parseInt(data.organization_number) : undefined;
        this.customerGroups = data.customer_groups ?? [];
        this.setting_group_id = data.setting_group_id;
        this.settingGroupId = data.setting_group_id;
    }

    override _set(customer: any, prop: string, value: any) {
        const set = super._set(customer, prop, value);

        if (set) {
            if (prop === 'region') {
                customer.region_id = customer.region?.id ?? null;
            } else if (prop == 'billing_customer') {
                customer.billing_customer_id = value?.id ?? null;
            } else if (prop == 'setting_group') {
                customer.setting_group_id = value?.id ?? null;
            }
        }

        return set;
    }

    hasProduct(name: string) {
        return CurrentOld.hasProduct(name);
    }

    static override getMorphClass() {
        return 'customer';
    }
}
