// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { chunk } from 'lodash-es';
import { pick } from 'lodash-es';
import { AvailabilityDayOld } from './availability-day.class';
import EmployeeOld from '../../../shared/angularjs/employee-old';
import { PaginationOld } from '../../../shared/utils/pagination-old';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
module('eaw.availability').factory('Availability', [ 'EawResource', function AvailabilityClass(EawResource) {
    const availabilityUrl = '/customers/:customer/employees/:employee/availabilities/:availability';
    class AvailabilityOld {
        id;
        employee_id;
        employee;
        customer_id;
        from;
        weeks;
        deleted_at;
        updated_at;
        created_at;
        properties;
        comments;
        work_days;
        repeat;
        generated_by_type;
        generated_by_id;
        days;
        approval;
        approved;
        active;
        to;
        comment;
        constructor(data = {}) {
            this.id = data.id;
            this.employee_id = data.employee_id || CurrentOld.employee?.id;
            this.employee = data.employee != null ? new EmployeeOld(data.employee) : null;
            this.customer_id = data.customer_id || CurrentOld.customer.id;
            this.from = data.from || moment().add(1, 'd');
            this.to = data.to;
            this.active = data.active;
            this.approval = data.approval;
            this.days = data.days?.map((d) => new AvailabilityDayOld(this, d)) || [];
            this.generated_by_id = data.generated_by_id;
            this.generated_by_type = data.generated_by_type;
            this.repeat = data.repeat || 7;
            this.work_days = new Array(4).fill(5).map((five, i) => data.work_days?.[i] ?? five);
            this.comments = data.comments || [];
            this.properties = data.properties;
            this.created_at = data.created_at;
            this.updated_at = data.updated_at;
            this.deleted_at = data.deleted_at;
            // Use days if we have a saved availability
            this.createWeeks(!!this.id);
            return new Proxy(this, {
                set: this._set,
            });
        }

        _set(obj, prop, value) {
            obj[prop] = value;
            if ([ 'from', 'to', 'repeat' ].includes(prop)) {
                obj.createWeeks();
            }
            if (prop === 'employee') {
                obj['employee_id'] = value?.id;
            }
            return true;
        }

        get repeatWeeks() {
            return this.repeat / 7;
        }

        get selectedWeeks() {
            return this.weeks.slice(0, this.repeatWeeks);
        }

        createWeeks(useDays) {
            // 2d array of weeks
            this.weeks = this.weeks || new Array(4).fill(new Array(7));
            if (!this.from?.isValid()) {
                return;
            }
            let to, daysCount;
            const flatWeeks = this.weeks.flat();
            const from = this.from.clone();
            const repeatTo = from.clone().add(this.repeat, 'd');
            // Calculate number of days to fill
            if (this.to?.isValid() && this.to.isSameOrBefore(repeatTo, 'd')) {
                to = this.to.clone();
                daysCount = to.diff(from, 'd') + 1;
            } else {
                daysCount = this.repeat;
            }
            new Array(Math.max(0, daysCount)).fill(0).forEach((_, index) => {
                // Default day
                let day = new AvailabilityDayOld(this, {
                    date: from.clone().add(index, 'd'),
                });
                // Generate from days
                if (useDays) {
                    let days = this.days?.filter((d) => d.day === index);
                    if (days.length === 0) {
                        flatWeeks[index] = day.toOff();
                    } else if (days.length === 1) {
                        day = days[0];
                        if (day.isWholeDay) {
                            flatWeeks[index] = day;
                        } else {
                            flatWeeks[index] = day.toTime();
                        }
                    } else {
                        days = days.map((d) => d.toTime());
                        flatWeeks[index] = days.reduce((mergedDay, siblingDay, index) => {
                            if (!index) {
                                return mergedDay;
                            }
                            mergedDay.times = mergedDay.times.concat(siblingDay.times);
                            return mergedDay;
                        }, days[0]);
                    }
                } else {
                    flatWeeks[index] = flatWeeks[index] || day;
                }
                // Make sure date is updated even if using same as before
                flatWeeks[index].date = day.date.clone();
            });
            // Convert to 2d week array
            this.weeks = chunk(flatWeeks.slice(0, daysCount), 7);
        }

        isValid() {
            const valids = [
                this.from?.isValid(),
            ];
            this.selectedWeeks.forEach((week) => {
                week.forEach((day) => {
                    valids.push(day.isValid);
                });
            });
            this.work_days.forEach((wd) => {
                valids.push(wd != null && wd >= 0 && wd <= 7);
            });
            return valids.every((x) => x);
        }

        daysToBackend() {
            return this.weeks
                .flat() // Weeks are an array of weeks with an array of days
                .map((day) => day.toBackend)
                .filter((day) => day != null) // Remove "off" days
                .flat(); // Flat days with time arrays
        }

        create(comment) {
            return EawResource.create(availabilityUrl).save({
                customer: this.customer_id,
                employee: this.employee_id,
            }, {
                days: this.daysToBackend(),
                from: this.from,
                to: this.to,
                repeat: this.repeat,
                work_days: this.work_days.slice(0, this.repeatWeeks),
                approved: this.approved,
                comment: comment || this.comment,
            }).$promise.then((resp) => new AvailabilityOld(resp));
        }

        update() {
            const args = pick(this, [ 'from', 'to', 'approved', 'comment' ]);
            if (!moment.isMoment(args.to)) {
                delete args.to;
            }
            return EawResource.create(availabilityUrl).update({
                customer: this.customer_id,
                employee: this.employee_id,
                availability: this.id,
            }, args).$promise.then((resp) => new AvailabilityOld(resp));
        }

        delete() {
            return EawResource.create(availabilityUrl).delete({
                customer: this.customer_id,
                employee: this.employee_id,
                availability: this.id,
            }).$promise;
        }

        static getActiveAvailability(customerId, employeeId) {
            return AvailabilityOld.getAll({
                customer_id: customerId,
                employee_id: employeeId,
                active: 1,
                approved: 1,
                with: [ 'days', 'approval' ],
                per_page: 1,
                direction: 'desc',
                order_by: 'created_at',
            }).then((resp) => resp.data[0] ? new AvailabilityOld(resp.data[0]) : null);
        }

        static get(args) {
            return EawResource.create(availabilityUrl).get({
                customer: args.customer_id,
                employee: args.employee_id,
                availability: args.id,
                'with[]': args.with,
            }).$promise.then((resp) => new AvailabilityOld(resp));
        }

        static getAll(args) {
            const url = args.employee_id ? '/customers/:customer_id/employees/:employee_id/availabilities' : '/customers/:customer_id/availabilities';
            return EawResource.create(url).get({
                customer_id: args.customer_id,
                employee_id: args.employee_id,
                ...PaginationOld.getParams(args, [ 'active', 'handled', 'approved', 'from', 'to', 'include_outdated' ]),
            }).$promise.then((resp) => {
                resp.data = resp.data.map((data) => new AvailabilityOld({
                    ...data,
                    customer_id: args.customer_id,
                }));
                return resp;
            });
        }

        static getOnDay(args) {
            return EawResource.create('/customers/:customer_id/schedules/:schedule_id/employees/:business_date').get({
                customer_id: args.customer_id,
                schedule_id: args.schedule_id,
                business_date: args.business_date.format('Y-M-D'),
                ...PaginationOld.getParams(args),
            }).$promise;
        }

        static getOverview(args) {
            return EawResource.create('/customers/:customer_id/availabilities/overview').get({
                customer_id: args.customerId,
                from: args.from,
                weeks: args.weeks, // number of weeks
            });
        }

        static override getMorphClass() {
            return 'availability';
        }
    }
    return AvailabilityOld;
} ]);
