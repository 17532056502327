<eaw-page-header>
    <span title>{{'VLH' | translate:'company' | async}}</span>
</eaw-page-header>

<eaw-info-box color="blue" icon="info">
    <span infoText>{{ 'VLH_SETTINGS_INFO' | translate: 'company' | async }}</span>
</eaw-info-box>

<mat-card>
    <mat-card-content class="card-content">
        @if (loading()) {
            <eaw-info-loading class="tw-self-center tw-my-24" size="lg"/>
        } @else {
            <div id="grid">
                @for (day of days(); track day; let dayIndex = $index) {
                    <div class="day">
                        <div class="day-header interval-grid">
                            <span class="day-title">{{day.day}}</span>

                            <mat-icon class="icon-button"
                                      [class.disabled]="saving()"
                                      matRipple
                                      eawMaterialColor="green-500"
                                      colorProperty="color"
                                      (click)="addPeriod(dayIndex)">add</mat-icon>
                        </div>

                        @for (period of day.periods; track period; let periodIndex = $index) {
                            <div class="day-container interval-grid">
                                <input maxlength="5" [class.invalid]="period.from.invalid" [placeholder]="'FROM' | translate | async" [formControl]="period.from" (focus)="focus($event, period)" (blur)="blur(period)">
                                <input maxlength="5" [class.invalid]="period.to.invalid" [placeholder]="'TO' | translate | async" [formControl]="period.to" (focus)="focus($event, period)" (blur)="blur(period)">

                                <mat-icon class="icon-button"
                                          [class.disabled]="saving()"
                                          matRipple
                                          eawMaterialColor="red-500"
                                          colorProperty="color"
                                          (click)="removePeriod(dayIndex, periodIndex)">remove</mat-icon>
                            </div>
                        } @empty {
                            <div class="day-container">
                                <span class="empty">{{'NO_TIME_SLOTS' | translate:'company' | async}}</span>
                            </div>
                        }
                    </div>
                }
            </div>
        }
    </mat-card-content>
</mat-card>
