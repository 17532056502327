// @ts-nocheck
import { module } from 'angular';
module('eaw.components').component('eawSubmitBtn', {
    template: `<md-button ng-class="$eawSubmitBtn.classes" type="submit" ng-disabled="$eawSubmitBtn.disabled()">
    <span ng-if="!$eawSubmitBtn.submitted" ng-i18next="{{$eawSubmitBtn.text}}"></span>
    <md-progress-circular ng-if="$eawSubmitBtn.submitted" md-diameter="16"></md-progress-circular>
</md-button>
`,
    bindings: {
        text: '<?',
        classes: '<?',
        form: '=?',
        btnDisabled: '<?',
    },
    require: {
        form: '^^form',
    },
    controllerAs: '$eawSubmitBtn',
    controller: [ '$element', '$timeout', function($element, $timeout) {
        const ctrl = this;
        ctrl.$postLink = () => {
            if (!ctrl.form?.$$element?.[0].hasAttribute('ng-submit')) {
                console.error('Missing required attribute', 'ng-submit');
                return;
            }
            /**
             * Augment form with an extra function to make it easy to unsubmit
             */
            ctrl.form.unsubmit = function unsubmit(pristine) {
                pristine = pristine ?? true;
                $timeout(() => {
                    // Enable editing again
                    delete ctrl.submitted;
                    ctrl.toggleSubmittedClass('remove');
                    ctrl.disableControls(false);
                    if (pristine) {
                        this?.$setPristine?.();
                        this?.$setUntouched?.();
                        ctrl?.setPristine?.(pristine);
                    }
                });
            };
            ctrl.text = ctrl.text || 'SUBMIT';
            ctrl.classes = ctrl.classes || [ 'md-raised md-accent' ];
            $element[0].children[0].addEventListener('click', ctrl.submit);
        };
        ctrl.toggleSubmittedClass = (action) => {
            $element[0].children[0].classList[action]('submitted');
        };
        ctrl.$onDestroy = () => {
            $element[0].children[0].removeEventListener('click', ctrl.submit);
        };
        ctrl.submit = () => {
            if (!ctrl.disabled()) {
                // It has to happen inside a timeout in order to trigger the digest loop
                $timeout(() => {
                    ctrl.toggleSubmittedClass('add');
                    ctrl.submitted = true;
                    ctrl.disableControls(true);
                });
            }
        };
        ctrl.disabled = () => ctrl.btnDisabled || ctrl.form.$invalid || ctrl.submitted;
        /**
         * @param {Boolean} pristine
         */
        ctrl.setPristine = (pristine) => {
            ctrl.form.$$controls.forEach((c) => {
                c.$$attr.$set('pristine', pristine);
            });
        };
        /**
         * @param {Boolean} disable
         */
        ctrl.disableControls = (disable) => {
            ctrl.form.$$controls.forEach((c) => {
                c.$$attr.$set('disabled', disable);
            });
        };
    } ],
});
