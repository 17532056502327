import { module } from 'angular';
import { PaginationOld } from '../../shared/utils/pagination-old';
import { pick } from 'lodash-es';
import { EawResourceFactory } from '../../shared/angularjs/modules/resource/resource.service';

module('eaw.absence').factory('AbsenceTypeFactory', [ 'EawResource', function AbsenceTypeFactory(EawResource: EawResourceFactory) {
    // @ts-ignore
    const fac = this;
    const url = '/setting_groups/:group/absence_types/:absence_type_id';

    fac.getAll = (args: any) => {
        return EawResource.create(`/${args.settingGroups ? 'setting_groups' : 'customers'}/${args.entityId}/absence_types`).get({
            only_creatable: args.can_create,
            'with[]': args.with,
            span: args.span,
            include_inactive: args.includeInactive,
            ...PaginationOld.getParams(args),
        }).$promise;
    };

    const format = (absenceType: any) => pick(absenceType, [ 'name', 'description', 'span', 'color', 'from', 'to', 'paid', 'gradable', 'code', 'approval_deadline_days' ]);

    fac.add = (settingGroupId: any, absenceType: any) => EawResource.create(url).save({
        group: settingGroupId,
    }, format(absenceType));

    fac.update = (settingGroupId: any, absenceType: any) => EawResource.create(url).update({
        group: settingGroupId,
        absence_type_id: absenceType.id,
    }, format(absenceType));

    fac.delete = (settingGroupId: any, absenceTypeId: any) => EawResource.create(url).delete({
        group: settingGroupId,
        absence_type_id: absenceTypeId,
    });

    return fac;
} ]);
