// @ts-nocheck
import { find } from 'lodash-es';
import { module } from 'angular';
import { Pagination } from '../../../../shared/utils/pagination';
import { t } from 'i18next';
import { lastValueFrom } from 'rxjs';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.staffing').component('staffingBusinessUnits', {
    template: `<md-card ng-show="$businessUnits.showFilter">
    <md-card-content layout="column">
        <div layout="row">
            <md-input-container flex="100" flex-gt-sm="50">
                <label ng-i18next="FROM"></label>
                <input ng-model="$businessUnits.template.from" type="number">
            </md-input-container>

            <md-input-container flex="100" flex-gt-sm="50">
                <label ng-i18next="TO"></label>
                <input ng-model="$businessUnits.template.to" type="number">
            </md-input-container>
        </div>

        <div layout="row">
            <md-button class="md-raised md-accent" ng-i18next="UPDATE" ng-click="$businessUnits.tableParams.reload()"></md-button>
        </div>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-bind="$businessUnits.template.name"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="$businessUnits.hideColumns"
                        icon="visibility"
                        i18n-tooltip="staffing:TOGGLE_COLUMNS">
        </eaw-toggle-btn>

        <md-button class="md-icon-button"
                   ng-if="$businessUnits.transactionsInput && $businessUnits.staffingService.canUpdateTemplate($businessUnits.template)"
                   ng-click="$businessUnits.updateAverageTray()">
            <md-tooltip ng-i18next="staffing:AVERAGE_TRAY"></md-tooltip>
            <md-icon ng-bind="'fastfood'"></md-icon>
        </md-button>

        <eaw-toggle-btn ng-model="$businessUnits.showFilter"
                        icon="filter_alt"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>

        <md-button class="md-icon-button"
                   ng-if="$businessUnits.staffingService.canAddInterval($businessUnits.template)"
                   ng-click="$businessUnits.staffingService.addInterval($businessUnits.template, $businessUnits.tableParams)">
            <md-tooltip ng-i18next="staffing:ADD_INTERVAL"></md-tooltip>
            <md-icon ng-bind="'add'"></md-icon>
        </md-button>

        <md-button class="md-icon-button"
                   ng-if="$businessUnits.staffingService.canGenerateIntervals($businessUnits.template)"
                   ng-click="$businessUnits.staffingService.generateIntervals($businessUnits.template, $businessUnits.tableParams)">
            <md-tooltip ng-i18next="staffing:GENERATE_INTERVALS"></md-tooltip>
            <md-icon ng-bind="'magic_button'"></md-icon>
        </md-button>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <table ng-table="$businessUnits.tableParams"
               template-pagination="modules/templates/ng-table-pagination.ajs.html"
               class="table staffing-table table-bordered table-condensed table-vmiddle table-hover">
            <thead>
            <tr>
                <th style="text-align: right" ng-i18next="INTERVAL"></th>
                <th style="text-align: right" ng-class="{'display-none': $businessUnits.hideColumns}" ng-if="$businessUnits.transactionsInput" ng-i18next="staffing:ETPH"></th>
                <th style="text-align: right" ng-class="{'display-none': $businessUnits.hideColumns}" ng-if="$businessUnits.transactionsInput" ng-i18next="staffing:ESPH"></th>
                <th style="text-align: right" ng-class="{'display-none': $businessUnits.hideColumns}" ng-if="$businessUnits.transactionsInput" ng-i18next="staffing:SALES"></th>
                <th style="text-align: right" ng-repeat="unit in $businessUnits.businessUnits track by unit.id" ng-bind="::unit.name" ng-if="unit.show"></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="interval in $data track by interval.id">
                <td ng-bind="interval | fromToText:'from':'to'"></td>
                <td ng-class="{'display-none': $businessUnits.hideColumns}" ng-if="$businessUnits.transactionsInput" ng-bind="interval | fromToText:'ETPH[1]':'ETPH[2]':1"></td>
                <td ng-class="{'display-none': $businessUnits.hideColumns}" ng-if="$businessUnits.transactionsInput" ng-bind="interval | fromToText:'ESPH[1]':'ESPH[2]':1"></td>
                <td ng-class="{'display-none': $businessUnits.hideColumns}" ng-if="$businessUnits.transactionsInput" ng-bind="interval | fromToText:'sales[1]':'sales[2]'"></td>
                <td class="input" ng-repeat="unit in $businessUnits.businessUnits track by unit.id" ng-if="unit.show">
                    <input ng-model="interval.tableData[unit.id].data.headcount"
                           ng-focus="$businessUnits.focus(interval.tableData[unit.id])"
                           ng-blur="$businessUnits.blur(interval, unit, interval.tableData[unit.id])"
                           ng-disabled="interval.tableData[unit.id].disabled"
                           inputmode="numeric"
                           style="box-sizing: border-box">
                </td>
                <td>
                    <eaw-icon-button
                            class="sopPropagate"
                            ng-click="$businessUnits.staffingService.updateInterval($businessUnits.template, interval, $businessUnits.tableParams)"
                            icon="edit"
                            ng-disabled="interval.disabled"
                            tooltip="'staffing:UPDATE_INTERVAL'">
                    </eaw-icon-button>
                    <eaw-icon-button
                            class="sopPropagate"
                            ng-click="$businessUnits.staffingService.deleteInterval($businessUnits.template, interval, $businessUnits.tableParams)"
                            icon="delete"
                            type="warn"
                            ng-disabled="interval.disabled"
                            tooltip="'staffing:DELETE_INTERVAL'">
                    </eaw-icon-button>
                </td>
            </tr>
            </tbody>
        </table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$businessUnits',
    bindings: {
        template: '<',
    },
    controller: [ 'staffingService', 'BusinessUnitDowngraded', 'staffingDataFactory', 'staffingTemplatePropertiesFactory', '$mdDialog', 'staffingTemplateFactory', function(staffingService, BusinessUnitDowngraded, staffingDataFactory, staffingTemplatePropertiesFactory, $mdDialog, staffingTemplateFactory) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.selectedUnitsKey = 'selectedUnits';
            ctrl.transactionsInput = ctrl.template.input === 'transactions';
            ctrl.staffingService = staffingService; // Service we need
            ctrl.getBusinessUnits(); // Get data we need before doing intervals
        };
        ctrl.getBusinessUnits = async () => {
            ctrl.businessUnits = await lastValueFrom(BusinessUnitDowngraded.getAllPages(CurrentOld.getCustomer().id, new Pagination({
                per_page: 100,
                order_by: 'created_at',
            })));
            ctrl.setSelectedUnits();
            ctrl.tableParams = staffingService.createNgTableParams(ctrl.template, ctrl.formatIntervals); // Create table
        };
        ctrl.setSelectedUnits = () => staffingTemplatePropertiesFactory.get.query(ctrl.template.customer_id, ctrl.template.id, ctrl.selectedUnitsKey).$promise.then((data) => {
            const selectedUnits = JSON.parse(data.value);
            ctrl.businessUnits = ctrl.businessUnits.map((b) => {
                return {
                    ...b,
                    show: selectedUnits.includes(b.id),
                };
            });
        }).catch(() => {
            // Show all defaultly if no property was found
            ctrl.businessUnits.forEach((u) => u.show = true);
        });
        ctrl.selectUnits = function selectUnits() {
            columnSelectorService.open(ctrl.businessUnits, 'name').catch(() => {
                const selectedUnits = JSON.stringify(ctrl.businessUnits.filter((b) => b.show).map((b) => b.id));
                staffingTemplatePropertiesFactory.update.query(ctrl.template.customer_id, ctrl.template.id, ctrl.selectedUnitsKey, selectedUnits);
            });
        };
        ctrl.showHideColumns = () => {
            ctrl.hideColumns = !ctrl.hideColumns;
        };
        ctrl.calcHeadcount = (interval) => {
            return Object.values(interval.tableData).reduce((sum, d) => sum + d.data?.headcount, 0);
        };
        ctrl.calcETPH = (interval) => {
            const headcount = ctrl.calcHeadcount(interval);
            return {
                1: interval.from / headcount,
                2: interval.to / headcount,
            };
        };
        ctrl.calcESPH = (interval) => {
            const headcount = ctrl.calcHeadcount(interval);
            const sales = ctrl.calcSales(interval);
            return {
                1: sales['1'] / headcount,
                2: sales['2'] / headcount,
            };
        };
        ctrl.calcSales = (interval) => {
            const averageTray = ctrl.template?.average_tray;
            return {
                1: interval.from * averageTray,
                2: interval.to * averageTray,
            };
        };
        ctrl.updateAverageTray = async function updateAverageTray() {
            const result = await $mdDialog.show($mdDialog.prompt()
                .title(t('staffing:UPDATE_AVERAGE_TRAY'))
                .initialValue(ctrl.template?.average_tray)
                .required(true)
                .ok(t('UPDATE'))
                .cancel(t('CANCEL')));
            ctrl.template = await staffingTemplateFactory.update.query(ctrl.template.customer_id, ctrl.template.id, { average_tray: result }).$promise;
            ctrl.tableParams.reload();
        };
        /**
         * Format intervals so that we can select by key
         * @param intervals
         */
        ctrl.formatIntervals = (intervals) => {
            if (Array.isArray(intervals) && intervals.length) {
                intervals.forEach(ctrl.formatInterval);
            }
        };
        ctrl.formatInterval = (interval) => {
            interval.tableData = {};
            ctrl.businessUnits?.forEach((unit) => {
                interval.tableData[unit.id] = find(interval.data || [], (data) => data.data?.business_unit_id === unit.id) || {};
            });
            ctrl.calculateTransactionStats(interval);
        };
        ctrl.focus = (data) => {
            data.oldValue = data.data?.headcount; // Save old value
        };
        ctrl.blur = (interval, unit, data) => {
            data.disabled = true; // Disable input
            switch (staffingService.getDataAction(data.oldValue, data.data?.headcount)) {
                case 'create': {
                    ctrl.create(interval, unit, data);
                    break;
                }
                case 'update': {
                    ctrl.update(interval, unit, data);
                    break;
                }
                case 'delete': {
                    ctrl.delete(interval, unit, data);
                    break;
                }
                default: {
                    data.disabled = false; // Enable input
                }
            }
        };
        ctrl.calculateTransactionStats = (interval) => {
            interval.ETPH = ctrl.calcETPH(interval);
            interval.ESPH = ctrl.calcESPH(interval);
            interval.sales = ctrl.calcSales(interval);
        };
        ctrl.setIntervalTableData = (interval, unit, data) => {
            interval.tableData[unit.id] = data;
            if (ctrl.transactionsInput) {
                ctrl.calculateTransactionStats(interval);
            }
        };
        ctrl.create = (interval, unit, data) => staffingDataFactory.create.query(ctrl.template.customer_id, ctrl.template.id, interval.id, {
            business_unit_id: unit.id,
            headcount: data.data.headcount,
        }).$promise
            .then((newData) => ctrl.setIntervalTableData(interval, unit, newData))
            .catch(() => data.data.headcount = data.oldValue)
            .finally(() => data.disabled = false);
        ctrl.update = (interval, unit, data) => staffingDataFactory.update.query(ctrl.template.customer_id, ctrl.template.id, interval.id, data.id, {
            business_unit_id: unit.id,
            headcount: data.data.headcount,
        }).$promise
            .then((updatedData) => ctrl.setIntervalTableData(interval, unit, updatedData))
            .catch(() => data.data.headcount = data.oldValue)
            .finally(() => data.disabled = false);
        ctrl.delete = (interval, unit, data) => staffingDataFactory.delete.query(ctrl.template.customer_id, ctrl.template.id, interval.id, data.id).$promise
            .then(() => ctrl.setIntervalTableData(interval, unit, {}))
            .catch(() => data.data.headcount = data.oldValue)
            .finally(() => data.disabled = false);
    } ],
});
