import { Component, inject, Inject } from '@angular/core';
import { DialogComponent, DialogData } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Link } from '../../models/link';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { LinksService } from '../../http/links.service';
import { CurrentService } from '../../../shared/services/current.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface LinkDialogData extends DialogData {
    link?: Link;
}

type LinkForm = {
    text: FormControl<string>,
    url: FormControl<string>,
    description: FormControl<string | null>,
    inheritable: FormControl<boolean>,
    mainMenu: FormControl<boolean>,
};

@Component({
    selector: 'eaw-link-dialog',
    templateUrl: './link-dialog.component.html',
    styleUrl: './link-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class LinkDialogComponent extends DialogComponent {
    private readonly linksService = inject(LinksService);
    private readonly current = inject(CurrentService);

    link?: Link = this.data.link;
    form: FormGroup<LinkForm>;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: LinkDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<LinkDialogComponent, Link>,
    ) {
        super(dialogRef, data);

        this.form = new FormGroup<LinkForm>({
            text: new FormControl(this.link?.text || '', {
                nonNullable: true,
                validators: Validators.required,
            }),
            url: new FormControl(this.link?.url || 'http://', {
                nonNullable: true,
                validators: Validators.required,
            }),
            description: new FormControl(this.link ? this.link.description || '' : ''),
            inheritable: new FormControl(this.link?.inheritable || false, { nonNullable: true }),
            mainMenu: new FormControl(this.link?.mainMenu || false, { nonNullable: true }),
        });
    }

    submit() {
        const controls = this.form.controls;
        const customerId = this.current.getCustomer().id;
        const data = {
            inheritable: controls.inheritable.value,
            text: controls.text.value,
            url: controls.url.value,
            description: controls.description.value || undefined,
            main_menu: controls.mainMenu.value,
        };

        (this.link ? this.linksService.update(customerId, this.link.id, data) : this.linksService.create(customerId, data)).subscribe((response) => this.dialogRef.close(response));
    }
}
