import { ApiResponse } from '../../shared/interfaces/api-response';
import { NamespaceFile } from '../../shared/enums/namespace';

export interface MetricResponse extends ApiResponse {
    key: string;
    name: {
        ns: NamespaceFile,
        key: string,
        data: [];
    };
    prefix: string;
    suffix: ''|'%';
}

export class Metric {
    key: string;
    name: {
        ns: NamespaceFile,
        key: string,
        data: [],
    };

    prefix: string;
    suffix: ''|'%';
    constructor(data: MetricResponse) {
        this.key = data.key;
        this.name = data.name;
        this.prefix = data.prefix;
        this. suffix = data.suffix;
    }
}
