import { ChangeDetectionStrategy, Component, computed, effect, inject, signal, viewChild } from '@angular/core';
import type { DataTable } from '../../../data-table/types/data-table';
import { DataTablePagination } from '../../../data-table/types/data-table';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { CustomerService } from '../../../shared/http/customer.service';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { debounceTime, distinctUntilChanged, EMPTY, of, switchMap, tap } from 'rxjs';
import { UIRouter } from '@uirouter/core';
import { Customer } from '../../../shared/models/customer';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';

@Component({
    selector: 'eaw-customer-list',
    templateUrl: './customer-list.component.html',
    styleUrl: './customer-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerListComponent implements DataTable<Customer> {
    private customerService = inject(CustomerService);
    private uiRouter = inject(UIRouter);
    private searchParams = inject(QueryParamsService);
    private permissionCheckService = inject(PermissionCheckService);
    private confirmDialogService = inject(ConfirmDialogService);
    private translateService = inject(TranslateService);

    dataTable = viewChild.required(DataTableComponent);

    headerButtons: PageHeaderButton[];
    total = signal(0);
    filterControl = new FormControl<string | null>(this.searchParams.get('filter', 'string') || '');
    filter = signal<string | null>(this.searchParams.get('filter', 'string') || '');

    columns = computed(this.computeColumns.bind(this));
    fabButton: HeaderFabButton;

    getData = computed(() => {
        const filter = this.filter() || undefined;

        return (pagination: Partial<DataTablePagination>) => {
            return this.customerService.getAll({
                ...pagination,
                filter,
                'count[]': [ 'users' ],
                'fields[]': [ 'name', 'billing_contact', 'number' ],
            }).pipe(
                tap((result) => (this.total.set(result.total))),
            );
        };
    });

    constructor() {
        effect(() => {
            this.searchParams.set([ {
                key: 'filter',
                value: this.filter(),
            } ]);
        });

        this.filterControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap((value) => {
                this.filter.set(value);
            }),
            takeUntilDestroyed(),
        ).subscribe();

        this.headerButtons = [
            new PageHeaderButton({
                icon: 'real_estate_agent',
                click: () => this.uiRouter.stateService.transitionTo('eaw/app/admin/customers/transfer'),
                menuText: signal(Promise.resolve('Transfer')),
            }),
        ];

        this.fabButton = {
            click: () => this.uiRouter.stateService.go('eaw/app/admin/customers/create'),
            hasPermission: () => of(true),
        };
    }

    computeColumns(): DataTableColumnType<Customer>[] {
        return [
            new DataTableNumberColumn({
                value: 'id',
                header: new DataTableHeader({
                    text: 'Id',
                    sortBy: 'id',
                }),
            }),
            new DataTableTextColumn({
                value: 'name',
                header: new DataTableHeader({
                    i18n: 'NAME',
                    sortBy: 'name',
                }),
            }),
            new DataTableTextColumn({
                value: 'number',
                header: new DataTableHeader({
                    i18n: 'NUMBER',
                    sortBy: 'number',
                }),
            }),
            new DataTableTextColumn({
                value: 'countryCode',
                header: new DataTableHeader({
                    i18n: 'COUNTRY',
                    sortBy: 'country_code',
                }),
            }),
            new DataTableTextColumn({
                value: 'currency',
                header: new DataTableHeader({
                    i18n: 'CURRENCY',
                    sortBy: 'currency',
                }),
            }),
            new DataTableTextColumn({
                value: 'timeZone',
                header: new DataTableHeader({
                    i18n: 'TIMEZONE',
                    ns: 'admin',
                    sortBy: 'time_zone',
                }),
            }),
            new DataTableNumberColumn({
                value: 'usersCount',
                header: new DataTableHeader({
                    i18n: 'USER_plural',
                    sortBy: 'users_count',
                }),
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        icon: 'delete',
                        type: 'warn',
                        click: this.deleteCustomer.bind(this),
                        show: (customer) => this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].delete`, {
                            models: [
                                { id: customer.id, type: ApiModelClass.Customer },
                            ],
                        }),
                        hide: (customer) => of((customer.usersCount || 0) > 0),
                        tooltip: { key: 'DELETE' },
                    },
                ],
            }),
        ];
    }

    deleteCustomer(cell: DataTableButtonCell<Customer>) {
        this.confirmDialogService.delete({
            title: this.translateService.t('DELETE_CUSTOMER', 'admin'),
            text: this.translateService.t('DELETE_CUSTOMER_CONFIRM', 'admin', { name: cell.item.name }),
            confirmText: this.translateService.t('DELETE'),
        }).afterClosed().pipe(
            switchMap((result) => {
                if (!result?.ok) {
                    return EMPTY;
                }

                return this.customerService.delete(cell.item.id);
            }),
            tap(() => this.dataTable().refresh()),
        ).subscribe();
    }
}
