import { Component, inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { BusinessUnitAutocompleteService } from '../../../shared/autocompletes/business-unit-autocomplete.service';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { BusinessUnit } from '../../../business-units/models/business-unit';
import { AutoCompleteData } from '../../../shared/autocompletes/autocomplete';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { QualificationService } from '../../../shared/http/qualification.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';

export interface EmployeeQualificationsFromBusinessUnitsData extends DialogData {
    customerId: number;
    employeeId: number;
}

@Component({
    selector: 'eaw-add-employee-qualifications-from-business-units',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        AutocompleteComponent,
        ReactiveFormsModule,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        TranslatePipe,
        AsyncPipe,
        ActionButtonComponent,
    ],
    templateUrl: './add-employee-qualifications-from-business-units.component.html',
    styleUrl: './add-employee-qualifications-from-business-units.component.scss',
})
export class AddEmployeeQualificationsFromBusinessUnitsComponent extends DialogComponent<EmployeeQualificationsFromBusinessUnitsData, boolean> {
    protected override readonly data: EmployeeQualificationsFromBusinessUnitsData;
    protected readonly businessUnitAutocomplete = inject(BusinessUnitAutocompleteService);
    private readonly qualificationService = inject(QualificationService);

    formControl = new FormControl([] as BusinessUnit[]);
    autocompleteData: AutoCompleteData<BusinessUnit> = { display: 'name', trackByKey: 'id' };
    submitting = false;

    constructor() {
        const data: EmployeeQualificationsFromBusinessUnitsData = inject(MAT_DIALOG_DATA);
        data.size = DialogSize.Medium;
        super(inject(MatDialogRef), data);
        this.data = data;
    }

    addQualifications() {
        this.submitting = true;
        const buIds = this.formControl.value?.map((bu) => bu.id) || [];
        this.qualificationService.addQualificationsToEmployeeFromBusinessUnits(this.data.customerId, this.data.employeeId, buIds).subscribe({
            next: () => this.dialogRef.close(true),
            error: () => (this.submitting = false),
        });
    }
}
