<eaw-dialog-header>
    <span title>
    @if (multiSelect) {
        {{'REQUEST_FILE_MULTIPLE' | translate:'hr' | async}}
    } @else {
        @if (employees.length <= 1) {
            {{'REQUEST_FILE' | translate:'hr' | async}}
        } @else {
            {{'REQUEST_FILE_ALL' | translate:'hr' | async}}
        }
    }
    </span>
</eaw-dialog-header>

<mat-dialog-content>
    {{'REQUEST_FILE_TEXT' | translate: 'hr': { fileType: this.filetype.name } | async}}
    @if (loading) {
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
            <mat-spinner></mat-spinner>
        </div>
    }
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="">{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="loading" (click)="submit()">{{'REQUEST' | translate: 'hr' | async}}</eaw-action-button>
</mat-dialog-actions>