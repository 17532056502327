// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
import { IntervalOld } from '../../../shared/angularjs/interval-old';
import SavingDialogController from './saving-dialog/saving-dialog.ctrl';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
const savingDialogTemplate = `<div id="saving-dialog" ng-if="!ctrl.adjustmentEnd" layout="column" layout-align="center center">
    <span class="md-headline" ng-bind="ctrl.currentSaving.textDate"></span>
    <span class="md-body-1" style="opacity: 0.8; margin-bottom: 12px" ng-i18next="ai_budgeting:STARTING_ADJUSTMENT"></span>
    <md-progress-circular md-mode="{{ctrl.isFirst ? 'indeterminate' : 'determinate'}}" md-diameter="75" value="{{ctrl.complete}}"></md-progress-circular>
    <span class="md-body-2" style="margin-top: 8px" ng-bind="ctrl.currentSaving.xofy"></span>
</div>
`;
class AiBudgetingMcdSuisse {
    EawResource;
    eawDatePickerDialog;
    UnsavedChangesDowngrade;
    $mdDialog;
    ToastService;

    static get $inject() {
        return [ 'EawResource', 'eawDatePickerDialog', 'UnsavedChangesDowngrade', '$mdDialog', 'ToastService' ];
    }

    backendDateFormat = 'YYYY-MM-DD';
    showFilter = true;
    saving = false;
    gettingData = false;
    updatingData = false;
    minAdjustment = -90;
    maxAdjustment = 300;
    adjustmentEnd = 0;
    adjustmentEndRemaining = 0;
    dateInterval = new IntervalOld(moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month'));
    user;
    customer;
    bucket;
    entries = [];
    totalData;
    constants;
    form;
    constructor(EawResource, eawDatePickerDialog, UnsavedChangesDowngrade, $mdDialog, ToastService) {
        this.EawResource = EawResource;
        this.eawDatePickerDialog = eawDatePickerDialog;
        this.UnsavedChangesDowngrade = UnsavedChangesDowngrade;
        this.$mdDialog = $mdDialog;
        this.ToastService = ToastService;
    }

    $onInit() {
        // Initialize with the functions that checks if things are edited
        this.UnsavedChangesDowngrade.init(this.someEdited);
        void this.checkAdjustmentEnd();
    }

    async checkAdjustmentEnd() {
        this.adjustmentEnd = (await CurrentOld.retrieve('projection_adjustment_end', 'location')) || 0;
        if (this.adjustmentEnd) {
            this.countdown();
        } else {
            this.reset();
        }
    }

    countdown() {
        const end = moment(this.adjustmentEnd);
        this.adjustmentEndRemaining = Math.max(0, end.diff(moment(), 'seconds'));
        this.updatingData = this.adjustmentEndRemaining > 0;
        if (this.updatingData) {
            setTimeout(() => this.countdown(), 100);
        } else {
            this.reset();
        }
    }

    getData() {
        const promise = this.someEdited() ? this.$mdDialog.show(this.$mdDialog.confirm()
            .theme('delete')
            .title(t('UNSAVED_CHANGES'))
            .textContent(t('ai_budgeting:SUBMIT_UNSAVED'))
            .ok(t('SUBMIT'))
            .cancel(t('CANCEL'))) : Promise.resolve();
        promise.then(() => {
            this.gettingData = true;
            this.EawResource.create(`/customers/${this.customer.id}/ml_buckets/${this.bucket.id}/mcd_suisse/get_projections`).get({
                ...this.dateInterval,
            }).$promise.then((resp) => {
                this.entries = Object.entries(resp).reduce((arr, entry) => {
                    const [ dateString, data ] = entry;
                    const date = moment(dateString, this.backendDateFormat);
                    if (!date.isValid()) {
                        return arr;
                    }
                    data.metadata.comp_dates.forEach(function(dt, i) {
                        const cdt = moment(dt);
                        data.metadata.comp_dates[i] = {
                            date: cdt.clone(),
                            backendFormat: cdt.format('YYYY-MM-DD'),
                            dateString: cdt.format('ll'),
                        };
                    });
                    return arr.concat({
                        date: date.clone(),
                        weekDay: date.format('dddd'),
                        dateString: date.format('ll'),
                        sales_ly_diff: (data.sales / data.sales_ly) - 1,
                        transactions_ly_diff: (data.transactions / data.transactions_ly) - 1,
                        adjustment: data.metadata.adjustment,
                        compDates: {},
                        displayMetadata: true,
                        metadata: data.metadata,
                        ...data,
                    });
                }, []);
                this.totalData = this.entries.reduce((totalObj, entry) => {
                    totalObj.sales += entry.sales || 0;
                    totalObj.sales_ly += entry.sales_ly || 0;
                    totalObj.transactions += entry.transactions || 0;
                    totalObj.transactions_ly += entry.transactions_ly || 0;
                    return totalObj;
                }, {
                    sales: 0,
                    sales_ly_diff: 0,
                    sales_ly: 0,
                    transactions: 0,
                    transactions_ly_diff: 0,
                    transactions_ly: 0,
                });
                if (this.totalData.sales_ly) {
                    this.totalData.sales_ly_diff = (this.totalData.sales / this.totalData.sales_ly) - 1;
                }
                if (this.totalData.transactions_ly) {
                    this.totalData.transactions_ly_diff = (this.totalData.transactions / this.totalData.transactions_ly) - 1;
                }
            }).finally(() => {
                this.form?.unsubmit?.();
                this.gettingData = false;
            });
        }).catch(() => this.form?.unsubmit?.());
    }

    adjustmentFocus(event) {
        event.target.select();
    }

    adjustmentBlur(entry) {
        if (entry.adjustment < this.minAdjustment) {
            this.ToastService.tToast('ai_budgeting:MIN_ADJUST_WARN', { value: this.minAdjustment }, {
                icon: 'information',
            });
            entry.adjustment = this.minAdjustment;
        }
        if (entry.adjustment > this.maxAdjustment) {
            this.ToastService.tToast('ai_budgeting:MAX_ADJUST_WARN', { value: this.maxAdjustment }, {
                icon: 'information',
            });
            entry.adjustment = this.maxAdjustment;
        }
    }

    openSaveDialog() {
        return this.$mdDialog.show({
            template: savingDialogTemplate,
            controller: SavingDialogController,
            controllerAs: 'ctrl',
            bindToController: true,
            escapeToClose: false,
            locals: {
                customer: this.customer,
                bucket: this.bucket,
                entries: this.entries,
                backendDateFormat: this.backendDateFormat,
            },
        });
    }

    async save() {
        this.openSaveDialog().then(() => {
            this.checkAdjustmentEnd();
        });
    }

    someEdited() {
        return this.entries?.some((e) => e.hasBudget);
    }

    reset() {
        this.entries.forEach((e) => {
            delete e.hasBudget;
            e.adjustment = 0;
            e.compDates = {};
            this.calcEntryBudget(e);
        });
        this.saving = false;
        this.gettingData = false;
        this.getData(); // Get data again
    }

    addCompDate(entry, event) {
        const callback = (date) => {
            // Create own comp date object for easy access
            const id = +date;
            const compDate = {
                id,
                date: date.clone(),
                backendFormat: date.format('YYYY-MM-DD'),
                dateString: date.format('ll'),
                processing: true,
            };
            // Add to entries comp dates, but it will be mutated
            entry.compDates[id] = compDate;
            entry.displayMetadata = Object.keys(entry.compDates).length === 0;
            // Get data for new comp date
            this.EawResource.create(`/customers/${this.customer.id}/ml_buckets/${this.bucket.id}/mcd_suisse/get_data/${compDate.backendFormat}`).get().$promise.then((resp) => {
                compDate.budget = this.calcCompDateSum(resp);
                this.calcEntryBudget(entry);
            }).catch(() => {
                compDate.error = true;
            }).finally(() => delete compDate.processing);
        };
        this.eawDatePickerDialog.open(null, {
            event,
            xPosition: 'ALIGN_END',
            yPosition: 'ALIGN_TOPS',
            maxDate: moment().subtract(1, 'd'),
            callback,
        });
    }

    calcEntryBudget(entry) {
        // Get number of comp dates without any errors
        const compDatesCount = Object.values(entry.compDates || {}).filter((d) => !d.error).length;
        if (compDatesCount) {
            // Sum total in all the comp dates
            entry.budget = {
                sales: Object.values(entry.compDates).reduce((sum, value) => sum + value.budget?.sales || 0, 0),
                transactions: Object.values(entry.compDates).reduce((sum, value) => sum + value.budget?.transactions || 0, 0),
            };
            // Get average
            entry.budget.sales /= compDatesCount;
            entry.budget.transactions /= compDatesCount;
            // Add adjustments
            const percent = (100 + entry.adjustment) / 100;
            entry.budget.sales *= percent;
            entry.budget.transactions *= percent;
        }
        entry.hasBudget = !!compDatesCount;
    }

    calcCompDateSum(dateResp) {
        return Object.values(dateResp).reduce((obj, budget) => {
            obj.sales += budget?.sales || 0;
            obj.transactions += budget?.transactions || 0;
            return obj;
        }, {
            sales: 0,
            transactions: 0,
        });
    }

    removeCompDate(entry, date) {
        if (date.processing) {
            return;
        }
        delete entry.compDates[date.id];
        entry.displayMetadata = Object.keys(entry.compDates).length === 0;
        this.calcEntryBudget(entry);
    }
}
module('eaw.ai-budgeting').component('aiBudgetingMcdSuisse', {
    template: `<md-card id="projections-filters" ng-show="$ctrl.showFilter && !$ctrl.updatingData">
    <md-card-content>
        <form name="tempForm" ng-submit="$ctrl.getData()">
            <div layout="row" layout-align="start center" layout-wrap>
                <eaw-date-interval layout="row"
                                   ng-model="$ctrl.dateInterval"
                                   required
                                   business-date="true"
                                   flex="100"
                                   flex-md="50"
                                   flex-gt-md="50">
                </eaw-date-interval>

                <div>
                    <eaw-submit-btn form="$ctrl.form"></eaw-submit-btn>
                </div>
            </div>
        </form>
    </md-card-content>
</md-card>

<md-card id="mcd-projections-card">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-i18next="ai_budgeting:SALES_PROJECTION_plural"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="$ctrl.showFilter"
                        ng-if="!$ctrl.updatingData"
                        icon="filter_alt"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>

        <card-btn-float ng-disabled="$ctrl.saving || !$ctrl.someEdited()"
                        ng-if="!$ctrl.updatingData"
                        mdi-icon="save"
                        tooltip="'SAVE'"
                        on-click="$ctrl.save()">
        </card-btn-float>
    </md-card-header>

    <md-card-content id="projections-table-container" class="tw-p-0 overflow-x-auto">
        <div id="no-data" ng-if="!$ctrl.entries.length && !$ctrl.gettingData && !$ctrl.updatingData">
            <md-icon ng-bind="'smart_toy'"></md-icon>
            <span bo-i18next="ai_budgeting:CHOOSE_INTERVAL"></span>
        </div>

        <div id="getting-data" ng-if="$ctrl.gettingData">
            <md-icon class="rotate" ng-bind="'smart_toy'"></md-icon>
            <span bo-i18next="FETCHING_DATA"></span>
        </div>

        <div id="updating-data" ng-if="$ctrl.updatingData">
            <md-icon class="rotate" ng-bind="'smart_toy'"></md-icon>
            <span ng-i18next="ai_budgeting:ADJUSTMENT_WILL_FINISH_IN"></span>
            <span ng-bind="$ctrl.adjustmentEndRemaining | eawDuration:'long':'ms'"></span>
        </div>

        <div id="entries-table" ng-if="!$ctrl.gettingData && !$ctrl.updatingData && $ctrl.entries.length">
            <div id="entries-table-head" class="entry-row">
                <div class="header-cell entry-cell" bo-i18next="DATE"></div>
                <div class="header-cell entry-cell" bo-i18next="WEEKDAY"></div>
                <div class="header-cell entry-cell" bo-i18next="SALE_plural"></div>
                <div class="header-cell entry-cell" bo-i18next="ai_budgeting:SALES_DIFF"></div>
                <div class="header-cell entry-cell" bo-i18next="ai_budgeting:LAST_YEAR_SALES"></div>
                <div class="header-cell entry-cell" bo-i18next="ai_budgeting:TRANSACTION_plural"></div>
                <div class="header-cell entry-cell" bo-i18next="ai_budgeting:TRANSACTIONS_DIFF"></div>
                <div class="header-cell entry-cell" bo-i18next="ai_budgeting:LAST_YEAR_TRANSACTIONS"></div>
                <div class="header-cell entry-cell" bo-i18next="ai_budgeting:ADJUSTMENT_PERCENT"></div>
                <div class="header-cell entry-cell" bo-i18next="ai_budgeting:COMPARABLE_DATE_plural"></div>
                <div class="header-cell entry-cell"></div>
            </div>

            <md-virtual-repeat-container id="virtual-entries-container">
                <div class="entry-row" md-virtual-repeat="entry in $ctrl.entries">
                    <div class="entry-cell" ng-bind="entry.dateString"></div>
                    <div class="entry-cell" ng-bind="entry.weekDay"></div>
                    <div class="entry-cell">
                        <span class="budget" ng-class="{'overwritten': entry.hasBudget}" ng-bind="entry.sales | eawNumber"></span>
                        <span class="budget" ng-if="entry.hasBudget" ng-bind="entry.budget.sales | eawNumber"></span>
                    </div>
                    <div class="entry-cell">
                        <span md-colors="{'color': entry.sales_ly_diff >= 0 ? 'green' : 'red'}"
                              ng-bind="entry.sales_ly_diff | percent:2:true">
                        </span>
                    </div>
                    <div class="entry-cell" ng-bind="entry.sales_ly | eawNumber"></div>
                    <div class="entry-cell">
                        <span class="budget" ng-class="{'overwritten': entry.hasBudget}" ng-bind="entry.transactions | eawNumber"></span>
                        <span class="budget" ng-if="entry.hasBudget" ng-bind="entry.budget.transactions | eawNumber"></span>
                    </div>
                    <div class="entry-cell">
                        <span md-colors="{'color': entry.transactions_ly_diff >= 0 ? 'green' : 'red'}"
                              ng-bind="entry.transactions_ly_diff | percent:2:true">
                        </span>
                    </div>
                    <div class="entry-cell" ng-bind="entry.transactions_ly | eawNumber"></div>
                    <div class="entry-cell editable-item">
                        <input type="number"
                               step="any"
                               ng-model="entry.adjustment"
                               ng-blur="$ctrl.adjustmentBlur(entry)"
                               ng-focus="$ctrl.adjustmentFocus($event)"
                               ng-change="$ctrl.calcEntryBudget(entry)">
                    </div>
                    <div class="entry-cell comp-date">
                        <div class="comp-date-container" ng-repeat="(id, date) in entry.compDates" ng-click="$ctrl.removeCompDate(entry, date)">
                            <span ng-class="{'invalid-date': date.error}">
                                <span ng-bind="date.dateString"></span>
                                <md-tooltip md-direction="left" ng-if="date.error" ng-i18next="ai_budgeting:NO_DATA_DATE"></md-tooltip>
                            </span>

                            <md-icon ng-if="!date.processing" class="s18" ng-bind="'delete'"></md-icon>
                            <md-progress-circular ng-if="date.processing" md-diameter="18"></md-progress-circular>
                        </div>
                        <div class="comp-date-container-metadata" ng-repeat="(id, date) in entry.metadata.comp_dates" ng-if="entry.displayMetadata">
                            <span>
                                <span ng-bind="date.dateString"></span>
                            </span>
                        </div>
                    </div>
                    <div class="entry-cell">
                        <eaw-icon-button icon="calendar_month" ng-click="$ctrl.addCompDate(entry, $event)"></eaw-icon-button>
                    </div>
                </div>
                <div class="entry-row total-row" ng-if="$ctrl.entries.length">
                    <div class="entry-cell" bo-i18next="TOTAL"></div>
                    <div class="entry-cell"></div>
                    <div class="entry-cell">
                        <span class="budget" ng-bind="$ctrl.totalData.sales | eawNumber"></span>
                    </div>
                    <div class="entry-cell">
                        <span md-colors="{'color': $ctrl.totalData.sales_ly_diff >= 0 ? 'green' : 'red'}"
                              ng-bind="$ctrl.totalData.sales_ly_diff | percent:2:true">
                        </span>
                    </div>
                    <div class="entry-cell" ng-bind="$ctrl.totalData.sales_ly | eawNumber"></div>
                    <div class="entry-cell">
                        <span class="budget" ng-bind="$ctrl.totalData.transactions | eawNumber"></span>
                    </div>
                    <div class="entry-cell">
                        <span md-colors="{'color': $ctrl.totalData.transactions_ly_diff >= 0 ? 'green' : 'red'}"
                              ng-bind="$ctrl.totalData.transactions_ly_diff | percent:2:true">
                        </span>
                    </div>
                    <div class="entry-cell" ng-bind="$ctrl.totalData.transactions_ly | eawNumber"></div>
                    <div class="entry-cell"></div>
                    <div class="entry-cell"></div>
                    <div class="entry-cell"></div>
                </div>
            </md-virtual-repeat-container>
        </div>
    </md-card-content>
</md-card>
`,
    bindings: {
        user: '<',
        customer: '<',
        bucket: '<',
        constants: '<',
    },
    controller: AiBudgetingMcdSuisse,
});
