<img id="customer-logo" [class.tw-invisible]="!logoUrl()" [src]="logoUrl() ?? 'data:'" alt="Logo">

<div id="logo-overlay" (click)="toggleMini()">
    <div id="profile-picture-container">
        <eaw-profile-picture [user]="user()" size="48"></eaw-profile-picture>
    </div>

    <div id="bar">
        <span>{{ user().name }}</span>
        <mat-icon eawSize="20px" [@rotateAnimation]="miniToggled() ? 'expanded' : 'minimized'">expand_more</mat-icon>
    </div>
</div>

<div id="mini-menu" [@inOutAnimation]="{value: miniToggled() ? 'expanded' : 'minimized', params: {height: (miniMenu().length + 1) * 40}}">
    @for (item of miniMenu(); track item) {
        @if (item.href) {
            <a class="mini-item" [href]="item.href">
                <mat-icon>{{ item.icon }}</mat-icon>
                <span>{{ item.name | translate:item.ns | async }}</span>
            </a>
        } @else {
            <div class="mini-item" (click)="item.onClick?.()">
                <mat-icon>{{ item.icon }}</mat-icon>
                <span>{{ item.name | translate:item.ns | async }}</span>
            </div>
        }
    }

    <div id="version" class="mini-item" [matTooltip]="lastUpdate() | DateTime:'DATETIME_MED_WITH_WEEKDAY'">{{ version() }}</div>
</div>

@if (loadingMenu()) {
    <mat-progress-spinner id="menu-spinner" diameter="40" strokeWidth="3" mode="indeterminate"></mat-progress-spinner>
} @else if (showMenu()) {
    <mat-accordion displayMode="flat" multi>
        @for (item of menu(); track item.id) {
            <mat-expansion-panel [hideToggle]="!item.subMenu?.length" [class.display-none]="!item.hasPermission()">
                <mat-expansion-panel-header
                        collapsedHeight="44px"
                        expandedHeight="44px"
                        [class.active]="item.active()"
                        [class.parent]="!!item.subMenu?.length"
                        eawPermission
                        [permissions]="item.stateData?.permissions"
                        [somePermissions]="item.stateData?.somePermissions"
                        [permissionCheck]="item.stateData?.permissionCheck"
                        [permissionChildrenFilter]="[item.getPermissionChildPrefix(), 'visible', item.key]">
                    @if (item.state) {
                        <a [href]="item.href()">
                            <mat-panel-title>
                                <mat-icon class="menu-item-icon">{{ item.icon }}</mat-icon>
                                <span class="menu-item-name">{{ item.translationKey | translate:item.ns | async }}</span>
                                @if (item.badgeCount()) {
                                    <div class="badge top-badge">{{ item.badgeCount() | eawNumber:0:badgeFormatterOptions() }}</div>
                                }
                            </mat-panel-title>
                        </a>
                    } @else {
                        <mat-panel-title>
                            <mat-icon class="menu-item-icon">{{ item.icon }}</mat-icon>
                            <span class="menu-item-name">{{ item.translationKey | translate:item.ns | async }}</span>
                            @if (item.badgeCount()) {
                                <div class="badge">{{ item.badgeCount() | eawNumber:0:badgeFormatterOptions() }}</div>
                            }
                        </mat-panel-title>
                    }
                </mat-expansion-panel-header>

                @if (item.subMenu?.length) {
                    <mat-nav-list dense>
                        @for (sub of item.subMenu; track sub.id) {
                            <a class="menu-item-name"
                               mat-list-item
                               [class.active]="sub.active()"
                               [href]="sub.href()"
                               eawPermission
                               [permissions]="sub.stateData?.permissions"
                               [somePermissions]="sub.stateData?.somePermissions"
                               [permissionCheck]="sub.stateData?.permissionCheck"
                               [permissionChildrenFilter]="[sub.getPermissionChildPrefix(), 'visible', sub.key]"
                               (permissionsChecked)="onPermissionChecked($event, item, sub)">

                                <div class="sub-name">{{ sub.translationKey | translate:sub.ns | async }}</div>

                                @if (sub.badgeCount()) {
                                    <div class="badge sub-badge">{{ sub.badgeCount() | eawNumber:0:badgeFormatterOptions() }}</div>
                                }
                            </a>
                        }
                    </mat-nav-list>
                }
            </mat-expansion-panel>
        }
        @for (link of linksMenu(); track link.id) {
            <mat-expansion-panel hideToggle class="custom-link">
                <mat-expansion-panel-header collapsedHeight="44px" expandedHeight="44px">
                    <a [href]="link.url" (click)="link.onClick($any($event), link)" [title]="link.description" target="_blank">
                        <mat-panel-title>
                            <mat-icon class="menu-item-icon">open_in_new</mat-icon>
                            <span class="menu-item-name">{{ link.text }}</span>
                        </mat-panel-title>
                    </a>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
        }
    </mat-accordion>

    @if (bottomMenu().length) {
        <mat-accordion id="bottom-menu" displayMode="flat" multi>
            @for (item of bottomMenu(); track item.id) {
                <mat-expansion-panel hideToggle eawPermission [permissions]="item.permissions">
                    <mat-expansion-panel-header collapsedHeight="44px" expandedHeight="44px">
                        <a [href]="item.href">
                            <mat-panel-title>
                                <mat-icon class="menu-item-icon">{{ item.icon }}</mat-icon>
                                <span class="menu-item-name">{{ item.name | translate:item.ns | async }}</span>
                            </mat-panel-title>
                        </a>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
            }
        </mat-accordion>
    }
}
