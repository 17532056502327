import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'family-members-upgraded',
    standalone: true
})
export class familyMembersUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;
    @Input() employee!: any;
    @Input() user!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('familyMembers', elementRef, injector);
    }
}

@Component({
    template: '<family-members-upgraded [customer]="customer" [employee]="employee" [user]="user"></family-members-upgraded>',
    standalone: true,
    imports: [familyMembersUpgradedDirective],
})
export class familyMembersUpgradedComponent {
    @Input() customer!: any;
    @Input() employee!: any;
    @Input() user!: any;
}

@NgModule({
    imports: [familyMembersUpgradedDirective,
        familyMembersUpgradedComponent],
})
export class familyMembersUpgradeModule {
}
