<eaw-dialog-header>
    @if (data.title) {
        <span title>{{ data.title() | async }}</span>
    } @else {
        <span title>{{ 'SHIFT_plural' | translate:'scheduling' | async }}</span>
    }

    @if (data.subtitle) {
        <span subtitle>{{ data.subtitle() | async }}</span>
    }
</eaw-dialog-header>

@if (dataSource()) {
    <cdk-virtual-scroll-viewport [itemSize]="itemSize()" class="shifts-viewport">
        <div *cdkVirtualFor="let item of dataSource(); let index = index" class="shift-item">
            @if (item) {
                <div class="shift-content tw-flex tw-justify-between tw-items-center">
                    <div class="text">
                        <div class="mat-body-2">{{ item.from | DateTime:'DATE_MED' }}</div>
                        <div class="mat-subtitle-2">{{ item.employee?.name || ('UNASSIGNED' | translate | async) }}</div>
                    </div>

                    <div class="tw-flex tw-gap-8 tw-items-center">
                        @if (item.periodsCount) {
                            <button mat-icon-button (click)="openPeriods(item)">
                                <mat-icon>timelapse</mat-icon>
                            </button>
                        }

                        <div class="tw-flex tw-gap-2">
                            <span>{{ item.from | DateTime:'TIME_SIMPLE' }}</span>
                            <span>-</span>
                            <span>{{ item.to | DateTime:'TIME_SIMPLE' }}</span>
                        </div>
                    </div>
                </div>
            } @else {
                <div class="loading">{{ 'LOADING' | translate | async }}</div>
            }
        </div>
    </cdk-virtual-scroll-viewport>
} @else {
    <div id="spinner-box" class="tw-flex tw-flex-row tw-justify-center tw-items-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
}

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="">{{ 'CLOSE' | translate | async }}</button>
</mat-dialog-actions>
