// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { EawChart } from '../../../../shared/angularjs/modules/misc/services/eaw-chart';
import { HandleOffTimeService } from '../../../dialogs/handle-offtime-dialog/handle-off-time.service';
import { DateTime } from 'luxon';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
module('eaw.vacation').component('vacationOverview', {
    template: `<md-card ng-show="$ctrl.showFilter">
    <md-card-content layout="row" layout-wrap>
        <form name="calendarOverviewForm" ng-submit="$ctrl.getData()" layout="row" flex="100" flex-gt-md="50">
            <eaw-date-interval flex="100" layout="row" ng-model="$ctrl.overviewInterval" required></eaw-date-interval>
            <div>
                <md-button class="md-raised md-accent" type="submit" ng-disabled="calendarOverviewForm.$invalid" ng-i18next="UPDATE"></md-button>
            </div>
        </form>
    </md-card-content>
</md-card>

<md-card style="overflow: auto">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="OVERVIEW"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="$ctrl.showUnhandled"
                        ng-if="$ctrl.unhandledPermission"
                        icon="layers"
                        ng-change="$ctrl.getData()"
                        i18n-tooltip="vacation:DISPLAY_UNHANDLED">
        </eaw-toggle-btn>

        <eaw-toggle-btn ng-model="$ctrl.showFilter"
                        toggled-initially="true"
                        icon="filter_alt"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>
    </md-card-header>
    <md-card-content class="tw-p-0" style="overflow: auto">
        <div id="range-chart-container">
            <md-progress-linear ng-class="{'invisible': !$ctrl.gettingData}"></md-progress-linear>

            <item-legend items="$ctrl.overviewLegend"></item-legend>

            <div id="range-chart"></div>
        </div>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
    },
    controller: [ 'OffTimeService', 'HandleOfftimeService', '$element', 'ItemSelectorDialog', 'offTimeInfoDialog', '$mdColors', function(OffTimeService, HandleOfftimeService: HandleOffTimeService, $element, ItemSelectorDialog, offTimeInfoDialog, $mdColors) {
        const ctrl = this;
        ctrl.$onInit = () => {
            $element[0].style.display = 'flex';
            $element[0].style.flexDirection = 'column';
            $element[0].style.maxHeight = '100%';
            ctrl.approvedVacationColor = $mdColors.getThemeColor('accent');
            ctrl.approvedNonVacationColor = $mdColors.getThemeColor('amber');
            ctrl.unhandledVacationColor = $mdColors.getThemeColor('primary');
            ctrl.showUnhandled = false;
            ctrl.offTimeFactory = new OffTimeService(ctrl.customer.id);
            ctrl.unhandledPermission = CurrentOld.can(`customers.${ctrl.customer.id}.off_times.*.update`);
            ctrl.overviewInterval = {
                from: moment().startOf('M'),
                to: moment().endOf('M'),
            };
            ctrl.overviewLegend = [
                {
                    color: ctrl.approvedVacationColor,
                    text: t('vacation:VACATION'),
                },
                {
                    color: ctrl.approvedNonVacationColor,
                    text: t('vacation:TIME_OFF'),
                },
                {
                    color: ctrl.unhandledVacationColor,
                    text: t('vacation:UNHANDLED'),
                },
            ];
            ctrl.getData();
        };
        ctrl.getData = () => {
            ctrl.gettingData = true;
            ctrl.offTimeFactory.customer.getAll.query({
                approved: ctrl.showUnhandled ? undefined : true,
                per_page: 9999,
                from: ctrl.overviewInterval?.from,
                to: ctrl.overviewInterval?.to,
                exclude_declined: ctrl.showUnhandled ? true : undefined,
                with: [ 'approval', 'employee' ],
            }).$promise.then((resp) => {
                ctrl.offTimeData = resp.data;
                ctrl.createRangeChart(ctrl.overviewInterval?.from, ctrl.overviewInterval?.to);
            }).finally(() => ctrl.gettingData = false);
        };
        ctrl.createTickData = (from, to) => {
            const longDateFormat = moment.localeData().longDateFormat('ll').replace(/D+\.?/g, '').trim();
            const HOUR = {
                duration: 1000 * 3600,
                format: (mom) => mom.format('LT'),
            };
            const DAY = {
                duration: HOUR.duration * 24,
                format: (mom) => mom.format('ll'),
            };
            const WEEK = {
                duration: DAY.duration * 7,
                format(mom) {
                    return `<div>${mom.format(longDateFormat)}</div><div>${t('WEEK_N', { number: mom.week() })}</div>`;
                },
            };
            const maxTicks = 14;
            const tickFrom = from.clone();
            const tickTo = to.clone();
            const chartDuration = tickTo.diff(tickFrom);
            let positions = [];
            // Use the FIRST unit that has a duration lower than the chart duration
            const selectedUnit = [ WEEK, DAY, HOUR ].find((x) => x.duration < chartDuration);
            // Add ticks at the unit's duration
            while (tickFrom.isBefore(tickTo)) {
                positions.push(tickFrom.unix() * 1000);
                tickFrom.add(selectedUnit.duration);
            }
            // Limit the ticks
            while (positions.length > maxTicks) {
                positions = positions.filter((p, i) => i % 2);
            }
            return {
                positions,
                format: selectedUnit.format,
            };
        };
        ctrl.getLineColor = (offTime) => {
            // Offtime is a vacation and it is approved
            if (offTime.vacation && offTime.approval?.approved) {
                return ctrl.approvedVacationColor;
            }
            // Offtime is not a vacation but it's approved
            if (!offTime.vacation && offTime.approval?.approved) {
                return ctrl.approvedNonVacationColor;
            }
            // If offtime doesn't have any approval
            if (!offTime.approval) {
                return ctrl.unhandledVacationColor;
            }
            return '';
        };
        /**
         * @param {moment.Moment} from
         * @param {moment.Moment} to
         * @returns {string|[]}
         */
        ctrl.createRangeChart = (from, to) => {
            const employeeNames = sort(Array.from(new Set(ctrl.offTimeData.map((x) => x.name))), CurrentOld.languageTag, [ (x) => x ]);
            const seriesData = ctrl.offTimeData.reduce((arr, offTime) => {
                arr.push({
                    x: offTime.from.unix() * 1000,
                    x2: offTime.to.unix() * 1000,
                    y: employeeNames.indexOf(offTime.employee.name),
                    offTime,
                    color: ctrl.getLineColor(offTime),
                });
                return arr;
            }, []);
            const tickData = ctrl.createTickData(from, to);
            ctrl.chartOptions = {
                chart: {
                    type: 'xrange',
                    height: ((employeeNames.length || 1) * 50) + 65,
                    scrollablePlotArea: {
                        minWidth: 1000,
                    },
                },
                title: {
                    text: null,
                },
                plotOptions: {
                    series: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click() {
                                    const request = this.options.offTime;
                                    if (!request.approval) {
                                        ItemSelectorDialog.open({
                                            title: t('vacation:APPROVE_OR_DECLINE_QUESTION'),
                                            displayKey: 'text',
                                            items: [
                                                {
                                                    text: t('APPROVE'),
                                                    value: true,
                                                },
                                                {
                                                    text: t('DECLINE'),
                                                    value: false,
                                                },
                                            ],
                                        }).then((res) => {
                                            const args = [ request.employee.customer_id, request.employee.id ];
                                            const approveOpts = {
                                                from: DateTime.fromISO(request.from.toISOString()),
                                                to: DateTime.fromISO(request.to.toISOString()),
                                                mode: 'update',
                                                offTimeId: request.id,
                                            };
                                            (res.value ? HandleOfftimeService.approve(...args, approveOpts).afterClosed() : HandleOfftimeService.decline(...args, request.id)).subscribe((res) => {
                                                if (!res) {
                                                    return;
                                                }
                                                ctrl.getData();
                                            });
                                        });
                                    } else {
                                        offTimeInfoDialog.open(ctrl.offTimeFactory, request);
                                    }
                                },
                            },
                        },
                    },
                },
                xAxis: {
                    minRange: to.diff(from),
                    min: from.unix() * 1000,
                    max: to.unix() * 1000,
                    type: 'datetime',
                    labels: {
                        style: {
                            textOverflow: 'none',
                            whiteSpace: 'nowrap',
                        },
                        useHTML: true,
                        formatter() {
                            return tickData.format(moment(this.value));
                        },
                    },
                    tickPositioner() {
                        return tickData.positions;
                    },
                },
                yAxis: {
                    title: {
                        text: '',
                    },
                    categories: employeeNames,
                    reversed: true,
                },
                tooltip: {
                    formatter() {
                        const fromTo = t('FROM_TO', {
                            from: moment(this.point.options.offTime.from).format('lll'),
                            to: moment(this.point.options.offTime.to).format('lll'),
                        });
                        return `<strong style="font-size: 12px">${this.point.yCategory}</strong><br/>${fromTo}<br/>`;
                    },
                },
                series: [
                    {
                        name: t('vacation:VACATION'),
                        pointWidth: 20,
                        showInLegend: false,
                        data: seriesData,
                    },
                ],
            };
            ctrl.chart = new EawChart('range-chart', ctrl.chartOptions);
        };
    } ],
});
