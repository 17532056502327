import { ApiResponse } from '../../shared/interfaces/api-response';

export interface IFormField {
    name: string,
    value: string,
    resolve_value: boolean,
}

export interface FormFieldResponse extends ApiResponse {
    id: number;
    name: string;
    object_type: string;
    object_id: number;
    resolve_value: boolean;
    value: string;
}

export class FormField {
    id: number;
    name: string;
    objectType: string;
    objectId: number;
    resolveValue: boolean;
    value: string;

    constructor(data: FormFieldResponse) {
        this.id = data.id;
        this.name = data.name;
        this.objectType = data.object_type;
        this.objectId = data.object_id;
        this.resolveValue = data.resolve_value;
        this.value = data.value;
    }
}
