import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { map, Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Country, CountryResponse } from '../models/country';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {
    }

    get(languageCode: string, countryCode: string) {
        return this.getAll(languageCode, {
            filter: countryCode,
            per_page: 999,
        }).pipe(
            map((resp) => {
                return resp.data.find((c) => c.code.toLowerCase() === countryCode.toLowerCase());
            }),
        );
    }

    getAll(languageCode: string, options?: PaginationOptions): Observable<ArrayPaginatedResponse<Country>> {
        return this.http.get<ArrayPaginatedResponse<CountryResponse>>('/countries', {
            params: {
                ...options,
                'fields[]': [ 'code' ],
            },
        }).pipe(
            map((resp) => {
                return {
                    ...resp,
                    data: resp.data.map((d) => new Country(d, languageCode)),
                };
            }),
        );
    }
}
