// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.calendar').service('HolidayDataSource', [ 'DataSource', 'CalendarEvent', 'HolidaysFactory', function HolidayDataSourceClass(DataSource, CalendarEvent, HolidaysFactory) {
    class HolidayDataSource extends DataSource {
        constructor(customer) {
            super(customer);

            this.name = 'holidays';
            this.i18n = HolidayDataSource.getI18nName();
            this.useOnEmployee = true;
            this.active = true;
            this.canUseSource = CurrentOld.can(`holidays.*.get`);
        }

        /**
         * @param {moment.Moment} from
         * @param {moment.Moment} to
         * @returns {Promise}
         */
        get(from, to) {
            return HolidaysFactory.getHolidays(this.customer, from, to).then((resp) => {
                // Make data into events
                resp.data.forEach((d) => {
                    d.from = d.date.clone().startOf('d');
                    d.to = d.date.clone().endOf('d');
                    d.holiday = true;
                    d.color = 'red'; // Material
                    d.name = d.name.substring(d.name.indexOf('/') + 1).split('-').join(' ');
                    d.dataSource = this.name;

                    const event = new CalendarEvent(d);
                    event.broadcastAdded();
                });
            });
        }

        static getI18nName() {
            return t('calendar:HOLIDAY_plural');
        }
    }

    return HolidayDataSource;
} ]);
