<eaw-dialog-header>
    <span title>{{ 'ADD_TO_GROUP' | translate: 'admin' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form class="tw-flex tw-flex-col tw-gap-16" [formGroup]="form">
        <eaw-autocomplete formControlName="customer"
                          [options]="userCustomerAutocompleteService.options"
                          [setter]="userCustomerAutocompleteService.setter()"
                          [getter]="userCustomerAutocompleteService.getter(userId)"
                          required>
            <mat-icon eawPrefix>public</mat-icon>
        </eaw-autocomplete>

        @if (form.controls.customer.value) {
            <eaw-autocomplete formControlName="userGroup"
                              [options]="customerUserGroupAutocompleteService.options"
                              [setter]="customerUserGroupAutocompleteService.setter(form.controls.customer.value.id, form.value.userGroup?.id!)"
                              [getter]="customerUserGroupAutocompleteService.getter(form.controls.customer.value.id)"
                              required>
                <mat-icon eawPrefix>public</mat-icon>
            </eaw-autocomplete>
        }

        @if (form.controls.userGroup.value) {
            <eaw-date-time-range-input class="tw-col-span-full tw-grid tw-grid-cols-2 tw-gap-8">
                <mat-form-field eawDateTimeFrom>
                    <mat-label>{{ 'FROM' | translate | async }}</mat-label>
                    <eaw-date-time-input [formControl]="form.controls.dateRange.controls.from"
                                         required></eaw-date-time-input>
                </mat-form-field>

                <mat-form-field eawDateTimeTo>
                    <mat-label>{{ 'TO' | translate | async }}</mat-label>
                    <eaw-date-time-input [formControl]="form.controls.dateRange.controls.to"
                                         required></eaw-date-time-input>
                </mat-form-field>
            </eaw-date-time-range-input>
        }
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [loading]="loading()" [disabled]="form.invalid"
                       (click)="submit()">{{ 'SUBMIT' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
