// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { Model } from '../../../shared/angularjs/model';
module('eaw.ai-budgeting').factory('EasyProjection', function EasyProjectionClass() {
    class EasyProjection extends Model {
        interval;
        time;
        timeString;
        hasLastYear;
        hasActual;
        hasAutoForecast;
        lastYear;
        actual;
        actualDiff;
        autoForecast;
        autoForecastDiff;
        forecastDiffBig;
        forecast;
        forecastDiff;
        edited;
        constructor(data, interval) {
            super(data);
            this.interval = interval;
            // Set some props
            this.time = moment.utc(this.time, 'YYYY-MM-DD HH:mm:ss');
            this.timeString = interval < moment.duration(1, 'd').asSeconds() ? this.time.format('lll') : this.time.format('ll');
            this.hasLastYear = Number.isFinite(this.lastYear?.value);
            this.hasActual = Number.isFinite(this.actual?.value);
            this.hasAutoForecast = Number.isFinite(this.autoForecast?.value);
            // Calculate props
            this.updateForecastDiff();
            this.updateAutoForecastDiff();
            this.updateActualDiff();
            // Set as original when everything is calculated
            this.setOriginal(this);
            this.updateEdited();
        }

        updateActualDiff() {
            this.actualDiff = this.calculateDiff('actual', 'forecast');
        }

        updateForecastValue() {
            if (Number.isFinite(this.forecastDiffBig)) {
                this.forecast.value = ((this.forecastDiffBig / 100) + 1) * this.lastYear.value;
                this.updateForecastDiff();
            } else {
                delete this.forecastDiff;
                delete this.forecast.value;
                this.updateActualDiff();
                this.updateEdited();
            }
        }

        updateForecastDiff() {
            if (Number.isFinite(this.forecast.value)) {
                this.forecastDiff = this.calculateDiff('forecast', 'lastYear');
                this.forecastDiffBig = parseFloat((this.forecastDiff * 100).toFixed(2));
            } else {
                delete this.forecastDiff;
                delete this.forecastDiffBig;
            }
            this.updateActualDiff();
            this.updateEdited();
        }

        updateAutoForecastDiff() {
            this.autoForecastDiff = this.calculateDiff('actual', 'autoForecast');
        }

        revert() {
            this.reset([ 'forecastDiff' ]);
            this.reset([ 'forecastDiffBig' ]);
            this.reset([ 'actualDiff' ]);
            this.forecast.reset([ 'value' ]);
            this.updateEdited();
        }

        updateEdited() {
            this.edited = this.isModified('forecastDiff') || this.forecast.isModified('value');
        }

        calculateDiff(val1, val2) {
            return (this[val1]?.value / this[val2]?.value) - 1;
        }
    }
    return EasyProjection;
});
