<eaw-page-header [fabButton]="fabButton">
    <span title>{{'ROTATION_plural' | translate:'rotation' | async}}</span>
</eaw-page-header>

<mat-card class="mat-compact-card mat-card-contained">
    <mat-card-content>
        <eaw-data-table
            #table
            [columns]="columns"
            [request]="request"
            initialSortBy="id"
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
