<eaw-page-header>
    <span title>{{ 'FILE_plural' | translate:'hr' | async }}</span>

    <form smallFilter>
        <mat-form-field class="standalone tw-mr-16">
            <mat-label>{{ 'FILETYPE' | translate:'hr' | async }}</mat-label>
            <mat-select name="allFiletypes" [(ngModel)]="allFiletypes" (selectionChange)="getOverview().subscribe()">
                <mat-option [value]="true" selected>{{ 'ALL_FILETYPES' | translateSync:'hr' }}</mat-option>
                <mat-option [value]="false">{{ 'MANDATORY_FILETYPES' | translateSync:'hr' }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="standalone">
            <mat-label>{{ 'FILES_FOR' | translate:'hr' | async }}</mat-label>
            <mat-select name="includeInactive" [(ngModel)]="includeInactive" (selectionChange)="getOverview().subscribe()">
                <mat-option [value]="true">{{ 'INACTIVE_EMPLOYEES' | translateSync: 'company' }}</mat-option>
                <mat-option [value]="false" selected>{{ 'ACTIVE_EMPLOYEES' | translateSync: 'company' }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</eaw-page-header>

<mat-card id="stats-card">
    <mat-card-content class="tw-flex tw-flex-row tw-justify-between tw-items-center">
        <div id="legend" class="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-8">
            <div class="item not-uploaded tw-flex tw-justify-start tw-items-center">
                <mat-icon>close</mat-icon>
                <span>{{ 'NOT_UPLOADED' | translate:'hr' | async }}</span>
                <span>({{ stats.notUploaded | eawPercent }})</span>
            </div>

            <div class="item expired tw-flex tw-justify-start tw-items-center">
                <mat-icon>history</mat-icon>
                <span>{{ 'UPLOADED_EXPIRED' | translate:'hr' | async }}</span>
                <span>({{ stats.expired | eawPercent }})</span>
            </div>

            <div class="item unsigned tw-flex tw-justify-start tw-items-center">
                <mat-icon>verified_user</mat-icon>
                <span>{{ 'UNSIGNED' | translate:'hr' | async }}</span>
                <span>({{ stats.unsigned | eawPercent }})</span>
            </div>

            <div class="item uploaded-signed tw-flex tw-justify-start tw-items-center">
                <mat-icon>check</mat-icon>
                <span>{{ 'UPLOADED_SIGNED' | translate:'hr' | async }}</span>
                <span>({{ stats.uploadedOrSigned | eawPercent }})</span>
            </div>
        </div>

        <div class="tw-flex tw-gap-8 tw-justify-end tw-items-center">
            @if (documentServiceEnabled) {
                <mat-slide-toggle [disabled]="gettingOverview" (change)="toggleSelectableMode($event)">{{ 'SELECT_MULTIPLE' | translate | async }}</mat-slide-toggle>
            }

            <button mat-button *ngIf="showHasPayslipImport" (click)="uploadPayslip()" [matTooltip]="('UPLOAD_PAYSLIP_FILE' | translate: 'payslip_import' | async) || ''">
                <mat-icon class="tw-mr-4">upload</mat-icon>
                {{ 'IMPORT_PAYSLIP' | translate: 'payslip_import' | async }}
            </button>

            <button mat-button (click)="createCsv()" [disabled]="gettingOverview" [matTooltip]="('EXPORT_TO_CSV' | translate: 'hr' | async) || ''">
                <mat-icon class="tw-mr-4">download</mat-icon>
                <span>{{ 'EXPORT' | translate | async }}</span>
            </button>

            <div class="tw-flex tw-flex-row tw-justify-center tw-items-center tw-min-w-[40px]">
                <mat-progress-spinner *ngIf="gettingOverview" class="tw-mr-8" diameter="24" strokeWidth="2" mode="indeterminate"></mat-progress-spinner>

                <button mat-icon-button *ngIf="!gettingOverview" (click)="getOverview().subscribe()" [matTooltip]="('REFRESH' | translate | async) || ''">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="mat-compact-card">
    <mat-card-content>
        <table>
            <thead>
            <tr>
                <th rowspan="2"></th>
                <th *ngFor="let type of filetypes" [colSpan]="type.defaultFiles.length + 1" class="first" [matTooltip]="type.type.name">
                    <div class="cell-content filetype-name">{{ type.type.name }}<small *ngIf="type.type.mandatory && allFiletypes && !gettingOverview"> - {{ 'MANDATORY' | translate | async }}</small></div>
                </th>
            </tr>
            <tr>
                <th *ngFor="let data of fileData" [class.first]="data.first" (click)="openFileDescription(data)">
                    <div class="cell-content header-subtype" [matTooltip]="(data.description | translate | async) || ''">
                        <span *ngIf="data.first">{{ 'OPTIONAL' | translate | async }}</span>
                        <span *ngIf="!data.first">{{ data.text }}</span>
                    </div>
                </th>
            </tr>
            <tr>
                <th>
                    <div class="cell-content employee-cell">
                        @if (selectableMode) {
                            <span>{{ 'SELECTED_EMPLOYEE_plural' | translate: 'hr' | async }}: {{ selectedEmployees.length }}</span>
                        } @else {
                            <span>{{ 'ALL' | translate | async }}</span>
                        }
                    </div>
                </th>
                <th *ngFor="let data of fileData" [class.first]="data.first">
                    <div class="cell-content">
                        <button mat-button (click)="createMany(data)" [disabled]="selectableMode && selectedEmployees.length === 0">
                            <mat-icon>file_upload</mat-icon>
                            {{ 'UPLOAD' | translate: 'hr' | async }}
                        </button>
                        @if (documentServiceEnabled && !data.defaultFile) {
                            <button mat-button (click)="requestMany(data)" [disabled]="selectableMode && selectedEmployees.length === 0">
                                <mat-icon>assignment_late</mat-icon>
                                {{ 'REQUEST' | translate: 'hr' | async }}
                            </button>
                        }
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let employee of employees">
                <td (click)="selectableMode ? null : goToEmployee(employee.employee.id)">
                    <div class="cell-content employee-cell">
                        <mat-checkbox eawCheckboxHelper *ngIf="this.selectableMode" (helperChange)="selectEmployee(employee, $event)"></mat-checkbox>
                        <span>{{ employee.employee.name }}</span>
                        <mat-icon (click)="goToEmployee(employee.employee.id)">launch</mat-icon>
                    </div>
                </td>
                <td *ngFor="let file of employee.files"
                    [class.first]="file.fileData.first"
                    [class.processing]="file.processing"
                    [ngClass]="file.status">
                    <div class="cell-content tw-p-8">
                        <mat-progress-spinner *ngIf="file.processing" mode="indeterminate" diameter="20" strokeWidth="2"></mat-progress-spinner>

                        <mat-icon class="status" *ngIf="file.status !== 'MISSING' || !file.optional">{{ file.icon }}</mat-icon>
                        <mat-icon class="status" *ngIf="file.requested">assignment_late</mat-icon>

                        <div class="action tw-flex" *ngIf="!file.processing" matRipple>
                            <div class="action-button upload" (click)="handleFile(employee, file, 'upload')">{{ 'UPLOAD' | translate:'hr' | async }}</div>
                            <div class="action-button download" (click)="handleFile(employee, file, 'download')" *ngIf="file.status === 'UPLOADED' || file.status ==='SIGNED'">{{ 'DOWNLOAD' | translate:'hr' | async }}</div>
                            @if (documentServiceEnabled && !file.fileData.defaultFile) {
                                <div class="action-button request" (click)="handleFile(employee, file, 'request')">{{ 'REQUEST' | translate:'hr' | async }}</div>
                            }
                            <div class="action-button delete" (click)="handleFile(employee, file, 'delete')" *ngIf="file.status !== 'MISSING'">{{ 'DELETE' | translate | async }}</div>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>
