// @ts-nocheck
import { module } from 'angular';
import { PaginationOld } from '../shared/utils/pagination-old';
import { TinyColor } from '@ctrl/tinycolor';
export default class Calendar {
    type;
    key;
    name;
    email;
    color;
    customer;
    access;
    userId;
    subscribed;
    textColor;
    myCalendar;
    constructor(calendar, userId) {
        this.type = calendar.type;
        this.key = calendar.key;
        this.name = calendar.name;
        this.email = calendar.email;
        this.color = calendar.color;
        this.customer = calendar.owner_key;
        this.access = {
            edit: calendar.access?.edit || false,
            invite: calendar.access?.invite || false,
            manage: calendar.access?.manage || false,
            read: calendar.access?.read || false,
        };
        this.userId = userId;
        this.subscribed = calendar.subscribed;
        this.textColor = new TinyColor(this.color).isDark() ? 'white' : 'black';
        this.myCalendar = this.key?.startsWith(`user:${this.userId}`);
    }

    subscribe() {
        return Promise.resolve();
    }

    unsubscribe() {
        return Promise.resolve();
    }

    getAcl() {
        return Promise.resolve();
    }

    update() {
        return Object.getPrototypeOf(this).update({
            customerId: this.customer,
            calendarKey: this.key,
            ...this,
        });
    }

    static get() {
        return Promise.resolve(new Calendar(null));
    }

    static getAll() {
        return Promise.resolve();
    }

    static getSubscribedCalendars() {
        return Promise.resolve([]);
    }

    static create() {
        return Promise.resolve(new Calendar(null));
    }
}
module('eaw.calendar2').service('Calendar', [ 'EawResource', function CalendarClass(EawResource) {
    return class Cal extends Calendar {
        subscribe(customerId, userId) {
            return EawResource.create('/customers/:customer/users/:user/calendar_subscriptions/:calendar').save({
                customer: customerId,
                user: userId,
                calendar: this.key,
            }, null).$promise;
        }

        unsubscribe(customerId, userId) {
            return EawResource.create('/customers/:customer/users/:user/calendar_subscriptions/:calendar').delete({
                customer: customerId,
                user: userId,
                calendar: this.key,
            }).$promise;
        }

        getAcl() {
            return EawResource.create('/customers/:customer/calendars/:calendar/acl').get({
                customer: this.customer,
                calendar: this.key,
            }).$promise;
        }

        static get(customerId, calendarKey, userId) {
            return EawResource.create('/customers/:customer/calendars/:calendar').get({
                customer: customerId,
                calendar: calendarKey,
            }).$promise.then((resp) => new this(resp, userId));
        }

        static getAll(args) {
            return EawResource.create('/customers/:customer/calendars').get({
                customer: args.customerId,
                user_id: args.userId,
                'types[]': args.types,
                subscribed: args.subscribed,
                ...PaginationOld.getParams(args),
            }).$promise.then((resp) => Object.values(resp.calendars).map((calendars) => calendars.map((calendar) => new this(calendar, args.userId))).flat());
        }

        /**
         * @param {number} customer
         * @param {object} args
         * @param {string} args.filter
         * @param {number} args.userId
         * @return {Promise<Calendar[]>}
         */
        static getSubscribedCalendars(customer, args) {
            return this.getAll({
                customer,
                user_id: args.userId,
                subscribed: true,
                ...args,
            });
        }

        static create(args) {
            return EawResource.create('/customers/:customer/calendars').save({
                customer: args.customer,
            }, {
                name: args.name,
                description: args.description,
                color: args.color,
                subscribe: args.subscribe,
            }).$promise.then((resp) => new this(resp));
        }

        static update(args) {
            return EawResource.create('/customers/:customer/calendars/:calendar').update({
                customer: args.customerId,
                calendar: args.calendarKey,
            }, {
                name: args.name,
                description: args.description,
                color: args.color,
                subscribe: args.subscribe,
            }).$promise.then((resp) => new this(resp));
        }
    };
} ]);
