import { DateTime, Interval } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { TinyColor } from '@ctrl/tinycolor';
import { BusinessUnitResponse } from '../../business-units/interfaces/business-unit-response';
import { BusinessUnit } from '../../business-units/models/business-unit';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { Qualification, QualificationResponse } from '../../shared/models/qualification';

export interface ShiftPeriodResponse extends ApiResponse {
    length: number;
    offset: number;
    shift_id: number;
    break: boolean;
    description?: string | null;
    from: string;
    to: string;
    id: number;
    unproductive: boolean;
    qualifications?: QualificationResponse[];
    business_unit_id?: number | null;
    business_unit?: BusinessUnitResponse;
    color?: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
}

export class ShiftPeriod {
    break: boolean;
    businessUnitId?: number;
    color: TinyColor;
    description?: string;
    from: DateTime;
    to: DateTime;
    id: number;
    length: number;
    offset: number;
    shiftId: number;
    interval: Interval;
    businessUnit?: BusinessUnit;
    qualifications?: Qualification[];
    unproductive: boolean;
    createdAt: DateTime | null;
    updatedAt: DateTime | null;
    deletedAt: DateTime | null;

    constructor(data: ShiftPeriodResponse) {
        this.break = data.break;
        this.businessUnitId = data.business_unit_id ?? undefined;
        this.color = new TinyColor(data.color || '#a9c93b');
        this.description = data.description ?? undefined;
        this.from = stringToDateTime(data.from);
        this.to = stringToDateTime(data.to);
        this.interval = Interval.fromDateTimes(this.from, this.to);
        this.id = data.id;
        this.length = data.length;
        this.offset = data.offset;
        this.shiftId = data.shift_id;
        this.unproductive = data.unproductive;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.businessUnit = data.business_unit ? new BusinessUnit(data.business_unit) : undefined;
        this.qualifications = data.qualifications ? data.qualifications.map((q) => new Qualification(q)) : undefined;
    }
}
