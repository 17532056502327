// @ts-nocheck
import { module } from 'angular';
export class EawCommentField {
    addComment;
    commentOn;
    body = '';
    $onInit() {
        this.body = '';
    }

    comment() {
        this.addComment({
            comment: this.body.trim(),
            commentOn: this.commentOn,
        });
        this.body = '';
    }
}
module('eaw.components').component('eawCommentField', {
    template: `<form ng-submit="commentField.comment()">
    <md-input-container class="md-block">
        <textarea rows="3" id="body{{id}}" md-no-autogrow class="auto-size" ng-i18next="[aria-label]COMMENT_PLACEHOLDER;[placeholder]COMMENT_PLACEHOLDER" ng-model="commentField.body" required></textarea>
    </md-input-container>
    <md-button class="md-accent md-raised" type="submit" ng-disabled="!commentField.body"
               ng-i18next="ADD_COMMENT"></md-button>
</form>
`,
    bindings: {
        addComment: '&',
        commentOn: '<',
    },
    controllerAs: 'commentField',
    controller: EawCommentField,
});
