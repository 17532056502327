import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { RoleService } from '../../../leader-roles/shared/http/role.service';
import { EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { Role } from '../../../leader-roles/shared/types/role';
import { CurrentService } from '../../../shared/services/current.service';
import { Observable, of } from 'rxjs';
import { DataTableComponent, DataTableGoTo } from '../../../data-table/data-table.component';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { PaginationOptions } from '../../../shared/interfaces/pagination-options';
import { DataTableButton, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { Namespace } from '../../../shared/enums/namespace';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { RoleDialogComponent, RoleDialogData } from '../../../leader-roles/role-dialog/role-dialog.component';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { UIRouter } from '@uirouter/core';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

/**
 * Shows a list of Roles the Customer has and lets you add a role.
 */
@Component({
    selector: 'eaw-customer-role-list',
    templateUrl: './customer-role-list.component.html',
    styleUrl: './customer-role-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class CustomerRoleListComponent implements OnInit, EawDataTable {
    @Input() customerId!: number;
    @Input() goTo?: DataTableGoTo;

    @ViewChild('table') dataTable?: DataTableComponent<Role>;

    columns: DataTableColumnType<Role>[] = [
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.parent?.name || '',
            header: new DataTableHeader({
                i18n: 'SUPERIOR',
                ns: Namespace.Company,
            }),
        }),
        new DataTableNumberColumn({
            value: 'assignmentCount',
            header: new DataTableHeader({
                i18n: 'USERS_WITH_ROLE',
                ns: Namespace.Company,
            }),
        }),
    ];

    request = of(mockArrayPaginatedResponse<Role>());
    createButton?: HeaderFabButton;

    constructor(
        @Inject(RoleService) protected roleService: RoleService,
        @Inject(CurrentService) protected current: CurrentService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(ConfirmDialogService) protected confirmDialog: ConfirmDialogService,
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(SnackBarService) protected snackBar: SnackBarService,
        @Inject(UIRouter) protected uiRouter: UIRouter,
    ) {
    }

    ngOnInit(): void {
        this.createButton = {
            click: this.createRole.bind(this),
            icon: 'add',
            type: 'accent',
            hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.roles.create`),
        };

        this.addButtonColumns();
    }

    canUpdate(role: Role) {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.roles.${role.id}.update`);
    }

    canDelete(role: Role) {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.roles.${role.id}.delete`);
    }

    updateTable(pagination: Partial<PaginationOptions>): void {
        pagination['count[]'] = [ 'assignments', 'allAssignments' ];
        pagination['with[]'] = [ 'parent' ];

        this.request = this.roleService.getAll(this.customerId, { pagination });
    }

    createRole() {
        this.openDialog(new Role({
            id: 0,
            name: '',
            parent_id: null,
            customer_id: this.customerId,
        })).subscribe((role) => {
            if (!role) {
                return;
            }

            this.roleService.create(this.customerId, {
                name: role.name,
                parent_id: role.parent_id,
            }).subscribe((role) => {
                this.snackBar.created();

                if (this.goTo) {
                    this.confirmDialog.open({
                        text: Promise.resolve('Go to new role?'),
                        title: Promise.resolve('Go'),
                    }).afterClosed().subscribe((ok) => {
                        if (ok) {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            this.uiRouter.stateService.go(this.goTo!.state, { roleId: role.id });
                        } else {
                            this.dataTable?.refresh();
                        }
                    });
                } else {
                    this.dataTable?.refresh();
                }
            });
        });
    }

    updateRole(cell: DataTableCell<DataTableButtonColumn<Role>, Role>) {
        this.openDialog(cell.item).subscribe((result) => {
            cell.disabled.set(false);

            if (!result) {
                return;
            }

            this.roleService.update({
                customer_id: this.customerId,
                id: cell.item.id,
                ...result,
            }).subscribe(() => {
                this.dataTable?.refresh();
            });
        });
    }

    deleteRole(cell: DataTableCell<DataTableButtonColumn<Role>, Role>) {
        this.confirmDialog.delete({ name: cell.item.name }).afterClosed().subscribe((result) => {
            cell.disabled.set(false);

            if (!result) {
                return;
            }

            this.roleService.delete(this.customerId, cell.item.id).subscribe(() => {
                this.snackBar.deleted();
                this.dataTable?.refresh();
            });
        });
    }

    protected openDialog(role: Role): Observable<{ name: string; parent_id?: number } | undefined> {
        return this.matDialog.open<RoleDialogComponent, RoleDialogData, { name: string, parent_id?: number }>(RoleDialogComponent, {
            data: {
                customerId: this.customerId,
                role,
            },
        }).afterClosed();
    }

    private addButtonColumns() {
        const update: DataTableButton<Role> = {
            icon: 'edit',
            click: this.updateRole.bind(this),
            show: this.canUpdate.bind(this),
            tooltip: { key: 'EDIT' },
        };
        const del: DataTableButton<Role> = {
            icon: 'delete',
            click: this.deleteRole.bind(this),
            hide: (role) => of(role.assigned),
            show: this.canDelete.bind(this),
            tooltip: { key: 'DELETE' },
            type: 'warn',
        };

        this.columns.push(
            // Edit
            new DataTableButtonColumn({
                header: new DataTableHeader({ text: '' }),
                buttons: [
                    update,
                    del,
                ],
            }),
        );
    }
}
