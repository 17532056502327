import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { WidgetComponent } from '../../classes/widget-component';
import { AlertsService, GetAlertsOptions } from '../../../shared/http/alerts.service';
import { CurrentService } from '../../../shared/services/current.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { Alert } from '../../../shared/models/alert';
import { Widget } from '../../classes/widget';
import { CustomerEmployeesGetAllOptions, EmployeeService } from '../../../shared/http/employee.service';
import { Employee } from '../../../shared/models/employee';
import { forkJoin } from 'rxjs';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';

@Component({
    selector: 'eaw-night-worker-widget',
    standalone: true,
    imports: [ CommonModule, MiniWidgetContentComponent, TranslatePipe ],
    templateUrl: './night-worker-widget.component.html',
    styleUrl: './night-worker-widget.component.scss',
})
export class NightWorkerWidgetComponent extends WidgetComponent implements OnInit {
    alertsService = inject(AlertsService);
    employeeService = inject(EmployeeService);
    current = inject(CurrentService);

    @Input() widget?: Widget;
    // For ajs container
    @Output() loaded = new EventEmitter<boolean>();

    qualifiedEmployees = new Set<number>();
    nightWorkEmployees: Employee[] = [];

    ngOnInit() {
        this.onExtendedInfoClosed().subscribe(this.getData.bind(this));
        this.getData();
    }

    getData() {
        this.setLoading(true);
        forkJoin([
            expandAllPages<Employee, CustomerEmployeesGetAllOptions>(
                (pagination) => this.employeeService.getAll(this.current.getCustomer().id, pagination),
                { 'properties[]': { cf_night_worker: '1' } },
            ),
            expandAllPages<Alert, GetAlertsOptions>((pagination) => this.alertsService.getAlerts(this.current.getCustomer().id, pagination), { category: 'night_worker' }),
        ]).subscribe(([ employees, alerts ]) => {
            this.nightWorkEmployees = employees;
            alerts.filter((alert) => !this.nightWorkEmployees.some((employee) => employee.id === alert.subject_id))
                .forEach((alert) => this.qualifiedEmployees.add(alert.subject_id));

            this.setLoading(false);
            this.loaded.emit(true);
        });
    }
}
