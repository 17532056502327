// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

module('eaw.misc').factory('UrlParams', [ '$location', '$state', 'EmployeeService', function UrlParams($location, $state, EmployeeService) {
    const fac = this;

    fac.get = (param, def = undefined) => {
        const val = $state.params[param] || $location.$$search[param];
        if (val === undefined) {
            return def;
        }

        // Check ts
        if (val instanceof Date) {
            return moment(val);
        }

        if (val instanceof Array) {
            return val;
        }

        if (typeof val == 'number') {
            return val;
        }

        const m = moment(val, 'Y-MM-DD', true);
        if (m.isValid()) {
            return m;
        }

        // Check number
        const v = parseInt(val);
        if (Number.isNaN(v)) {
            return val;
        }

        // Check array
        if (typeof val === 'string' && val.includes(',')) {
            return val.split(',').map((x) => parseInt(x));
        }

        return v;
    };

    fac.set = (param, value) => {
        if (moment.isMoment(value)) {
            value = value.isValid() ? value.format('Y-MM-DD') : null;
        }

        if (typeof value == 'boolean') {
            value = +value;
        }

        $location.search(param, value);
    };

    fac.delete = (param) => {
        $location.search(param, null);
    };

    /**
     * @param {string[]} [exceptions]
     */
    fac.deleteAll = (exceptions = []) => {
        Object.keys($location.$$search).forEach((key) => {
            if (!exceptions.includes(key)) {
                $location.search(key, null);
            }
        });
    };

    /**
     * @param {number} customerId
     * @param {string} param - name of the employee parameter
     */
    fac.getEmployee = (customerId, param) => {
        if (!Number.isFinite(customerId)) {
            return Promise.resolve(null);
        }

        const employeeId = parseInt(fac.get(param));
        if (!Number.isFinite(employeeId)) {
            return Promise.resolve(null);
        }

        return EmployeeService.get({ id: customerId }, employeeId, [ 'user' ]).catch(() => null);
    };

    return fac;
} ]);
