// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
module('eaw.newsletter').component('editIssue', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-if="!$issue.editing" ng-i18next="newsletter:CREATE_ISSUE"></span>
            <span class="md-title" ng-if="$issue.editing" ng-i18next="newsletter:UPDATE_ISSUE"></span>
            <span class="md-subhead" ng-if="$issue.issue.default_title" ng-bind="$issue.issue.default_title"></span>
        </md-card-header-text>

        <md-button class="md-raised" ng-click="$issue.submit()" ng-disabled="!$issue.canSave()" ng-i18next="SAVE"></md-button>
    </md-card-header>
    <md-card-content layout="row">
        <md-input-container flex="100" flex-gt-md="25" ng-hide="$issue.isPublished">
            <label ng-i18next="newsletter:ISSUE_DATE"></label>
            <md-select ng-model="$issue.publishAt">
                <md-option value="now">{{'NOW' | i18next}}</md-option>
                <md-option value="later">{{'newsletter:SPECIFIC_DATE' | i18next}}</md-option>
            </md-select>
        </md-input-container>

        <md-input-container flex="100" flex-gt-md="25" ng-if="$issue.publishAt == 'later'" ng-hide="$issue.isPublished">
            <eaw-date-picker ng-model="$issue.publishDate" required label="newsletter:PUBLISH_AT" min-date="$issue.minDate"></eaw-date-picker>
        </md-input-container>

        <md-input-container ng-show="$issue.isPublished">
            <label ng-i18next="newsletter:PUBLISHED_AT"></label>
            <input ng-value="$issue.publishDate | moment:'LL'" disabled>
        </md-input-container>
    </md-card-content>
</md-card>

<eaw-accordion multi-toggle="true">
    <eaw-accordion-panel ng-repeat="language in $issue.issueLanguages" on-open="$issue.getLanguageFile(language)">
        <panel-header>
            <div class="tw-w-full" layout="row" layout-align="space-between center">
                <div ng-bind="language.languageName" ng-class="{'required': language.isDefault}"></div>

                <small md-colors="{color: 'red'}" ng-if="language.isDefault && !language.isFilledOut" ng-i18next="newsletter:DEFAULT_LANG_REQUIRED"></small>
                <small ng-if="language.isFilledOut" ng-i18next="newsletter:LANG_FILLED"></small>
            </div>
        </panel-header>
        <panel-content>
            <div layout="row" ng-if="$issue.editing && !$issue.loading || !$issue.editing">
                <div class="tw-mr-24" layout="column" flex="100" flex-gt-md="30">
                    <md-input-container>
                        <label ng-i18next="TITLE"></label>
                        <input ng-model="language.title" required ng-change="$issue.languageChange(language)">
                    </md-input-container>

                    <md-input-container>
                        <label ng-i18next="DESCRIPTION"></label>
                        <textarea rows="1" ng-model="language.description"></textarea>
                    </md-input-container>

                    <md-input-container>
                        <label ng-i18next="newsletter:UPLOAD_NEWSLETTER_FILE"></label>
                        <input ngf-select
                               ngf-change="$issue.fileChange(language, $file)"
                               ngf-model-options="{updateOn: 'change'}"
                               ng-model="language.file"
                               ng-value="language.file.name"
                               ng-i18next="newsletter:SELECT_FILE"
                               accept="application/pdf"
                               readonly
                               required>
                        <small class="hint" ng-if="language.file" ng-bind="language.file.size | filesize"></small>
                    </md-input-container>

                    <div layout="column">
                        <div layout="row" layout-align="start center">
                            <md-button class="md-fab md-raised md-mini" ng-click="$issue.choosePDFPreview(language)" ng-disabled="!(language._resolvedPDF || language.file)">
                                <md-icon ng-bind="'description'"></md-icon>
                            </md-button>

                            <span ng-i18next="newsletter:USE_PDF_AS_PREVIEW"></span>
                        </div>


                        <div layout="row" layout-align="start center">
                            <md-button class="md-fab md-raised md-mini" ng-click="$issue.chooseCustomPreview($event)">
                                <md-icon ng-bind="'add_photo_alternate'"></md-icon>
                            </md-button>

                            <input ngf-select
                                   ngf-change="$issue.customPreviewImageChange(language, $file)"
                                   ngf-model-options="{updateOn: 'change'}"
                                   class="display-none"
                                   ng-model="language.customPreviewImage"
                                   ng-value="language.customPreviewImage.name"
                                   accept="image/*"
                                   readonly>

                            <span ng-i18next="newsletter:CUSTOM_PREVIEW"></span>
                        </div>
                    </div>
                </div>

                <eaw-issue-preview flex="100"
                                   flex-gt-md="30"
                                   issue="$issue.issue"
                                   language-code="language.languageCode"
                                   customer-id="$issue.customer.id"
                                   src="language.previewSrc">
                </eaw-issue-preview>
            </div>
        </panel-content>
    </eaw-accordion-panel>
</eaw-accordion>
`,
    controllerAs: '$issue',
    bindings: {
        user: '<',
        customer: '<',
        languages: '<',
        newsletter: '<',
        issue: '<?',
    },
    controller: [ '$state', 'Newsletter', 'ToastService', 'Upload', function($state, Newsletter, ToastService, Upload) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.editing = !!ctrl.issue;
            ctrl.minDate = moment().startOf('d');
            ctrl.publishDate = ctrl.issue?.publish_date?.clone() || ctrl.minDate.clone();
            ctrl.isPublished = ctrl.editing && ctrl.publishDate.isSameOrBefore(moment(), 'd');
            ctrl.publishAt = !ctrl.isPublished && ctrl.publishDate.isSame(ctrl.minDate, 'd') ? 'now' : 'later';
            ctrl.issueLanguages = ctrl.newsletter.languages.map((nl) => {
                const issueLanguage = ctrl.issue?.languages[nl.code];
                return {
                    languageCode: nl.code,
                    languageName: ctrl.languages.find((l) => l.code === nl.code)?.name,
                    isDefault: nl.code === ctrl.newsletter.language_code,
                    title: issueLanguage?.title,
                    description: issueLanguage?.description,
                    file: ctrl.issue?.attachments.find((ia) => ia.name.endsWith(`_${nl.code}.pdf`)),
                };
            });
            // Check statuses
            ctrl.issueLanguages.forEach(ctrl.languageChange);
        };
        ctrl.getLanguageFile = (language) => {
            ctrl.loading = true;
            return !ctrl.issue?.id ? Promise.resolve() : Newsletter.getIssuePDF(ctrl.issue, ctrl.customer.id, language.languageCode).then((resp) => {
                language._resolvedPDF = new File([ resp.data ], ctrl.issue.attachments.find((a) => a.name.endsWith(`${language.languageCode}.pdf`))?.name, { type: resp.data.type });
                ctrl.loading = false;
            }).catch(() => {
                ctrl.loading = false;
            });
        };
        ctrl.choosePDFPreview = async (language) => {
            const image = await ctrl.pdfToImageFile(language._resolvedPDF || language.file, null, 1);
            ctrl.setLanguagePreviews(language, image);
        };
        ctrl.chooseCustomPreview = (event) => {
            event.target.parentElement.nextElementSibling.click();
        };
        ctrl.canSave = () => ctrl.issueLanguages.find((il) => il.isDefault)?.isFilledOut && ctrl.issueLanguages.some((il) => il.isFilledOut);
        ctrl.languageChange = (language) => {
            language.isFilledOut = language.file && language.title?.length;
        };
        ctrl.customPreviewImageChange = (language, file) => {
            ctrl.setLanguagePreviews(language, file);
            ctrl.languageChange(language);
        };
        ctrl.fileChange = async (language, file) => {
            if (language.useCustomPreviewImg) {
                return;
            }
            const image = await ctrl.pdfToImageFile(file, null, 1);
            ctrl.setLanguagePreviews(language, image);
            ctrl.languageChange(language);
        };
        ctrl.setLanguagePreviews = async (language, image) => {
            const resizedImageBlob = await Upload.resize(image, {
                height: 10,
                quality: 0.1,
            });
            const resizedImage = new File([ resizedImageBlob ], image.name, { type: image.type });
            language.preview = image;
            language.previewSmall = resizedImage;
            language.previewSrc = URL.createObjectURL(language.preview);
            language.previewSmallSrc = URL.createObjectURL(language.previewSmall);
        };
        ctrl.pdfToImageFile = (file, title, scale = 1) => new Promise((resolve, reject) => {
            const pdfDocument = file instanceof File ? URL.createObjectURL(file) : undefined;
            if (!pdfDocument) {
                resolve(undefined);
                return;
            }
            // @ts-ignore
            pdfjsLib.getDocument(pdfDocument).promise.then((pdf) => {
                pdf.getPage(1).then((page) => {
                    const viewport = page.getViewport({ scale });
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    page.render({
                        canvasContext: context,
                        viewport,
                    }).promise.then(() => {
                        canvas.toBlob((blob) => {
                            resolve(new File([ blob ], `${title}.png`, { type: 'image/png' }));
                        });
                    });
                });
            });
        });
        ctrl.submit = async () => {
            ToastService.toast(t('newsletter:ISSUE_SAVING'));
            const issue = {
                language_code: '',
                default_title: '',
                default_description: '',
                publish_date: ctrl.publishAt === 'now' ? moment().startOf('d') : ctrl.publishDate.format('YYYY-MM-DD'),
                languages: [],
                _businessDates: [ 'publish_date' ],
            };
            for (let i = 0; i < ctrl.issueLanguages.length; i++) {
                const l = ctrl.issueLanguages[i];
                if (l.isDefault) {
                    issue.language_code = l.languageCode;
                    issue.default_title = l.title;
                    issue.default_description = l.description;
                    issue.file = l.file instanceof File ? l.file : undefined;
                    issue.preview = l.preview;
                    issue.preview_lazy = l.previewSmall;
                } else if (l.title?.length) {
                    issue.languages.push({
                        language_code: l.languageCode,
                        title: l.title,
                        description: l.description || '',
                    });
                    issue[`file_${l.languageCode}`] = l.file instanceof File ? l.file : undefined;
                    issue[`preview_${l.languageCode}`] = l.preview;
                    issue[`preview_lazy_${l.languageCode}`] = l.previewSmall;
                }
            }
            if (ctrl.editing) {
                await Newsletter.updateIssue(ctrl.newsletter.customer_id, ctrl.newsletter.key, ctrl.issue.id, issue);
                ToastService.toast(t('newsletter:ISSUE_UPDATED'));
            } else {
                await Newsletter.createIssue(ctrl.newsletter.customer_id, ctrl.newsletter.key, issue);
                ToastService.toast(t('newsletter:ISSUE_CREATED'));
            }
            $state.go('^');
        };
    } ],
});
