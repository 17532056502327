import { Inject, Injectable } from '@angular/core';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { formatHttpParams } from '../utils/format-http-params';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';
import { ExternalEmployee, ExternalEmployeeResponse } from '../../company/models/external-employee';
import { PaginationOptions } from '../interfaces/pagination-options';
import { EmploymentTerminationHandling } from '../../company/dialogs/handle-employee-termination/handle-employee-termination.component';
import { DateTime } from 'luxon';

interface GetAllOptions extends PaginationOptions {
    include_inactive?: boolean;
}

export interface ExternalEmployeeUpdateRequestData {
    cost?: number | null;
    number?: number | null;
    from?: DateTime;
    to?: DateTime | null;
    resolve_termination?: EmploymentTerminationHandling;
}

@Injectable({
    providedIn: 'root',
})
export class ExternalEmployeeService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, pagination?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<ExternalEmployeeResponse>>(`/customers/${customerId}/external_employees`, {
            params: formatHttpParams(pagination),
        }).pipe(classifyArrayPaginatedResponse(ExternalEmployee));
    }

    update(customerId: number, externalEmployeeId: number, data: ExternalEmployeeUpdateRequestData) {
        return this.http.put<ExternalEmployeeResponse>(`/customers/${customerId}/external_employees/${externalEmployeeId}`, data).pipe(classifyItem(ExternalEmployee));
    }

    getLoansForCustomer(customerId: number, pagination?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<ExternalEmployeeResponse>>(`/customers/${customerId}/external_employees/overview`, {
            params: formatHttpParams(pagination),
        }).pipe(classifyArrayPaginatedResponse(ExternalEmployee));
    }
}
