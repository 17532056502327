import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BusinessDateString } from '../../shared/types/business-date';
import { BusinessDate } from '../../shared/utils/business-date';
import { ContractHourDay, ContractHourDayResponse } from './contract-hour-day';

export interface HourDistributionResponse extends ApiResponse {
    created_at: string;
    // Days are always a multiple of 7
    days: number;
    deleted_at: string | null;
    updated_at: string;
    employee_id: number;
    from: BusinessDateString;
    to: BusinessDateString | null;
    id: number;
    contract_hours_days?: ContractHourDayResponse[];
}

export class HourDistribution {
    id: number;
    // Days are always a multiple of 7
    days: number;
    weeks: number;
    employeeId: number;
    from: BusinessDate;
    to: BusinessDate | null;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;
    contractHoursDays?: ContractHourDay[];

    constructor(data: HourDistributionResponse) {
        this.id = data.id;
        this.days = data.days;
        this.weeks = Math.round(data.days / 7);
        this.employeeId = data.employee_id;
        this.from = new BusinessDate(data.from);
        this.to = data.to ? new BusinessDate(data.to) : null;
        this.contractHoursDays = data.contract_hours_days ? data.contract_hours_days.map((day) => new ContractHourDay(day)) : undefined;

        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
