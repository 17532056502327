<eaw-page-header>
    <span title>{{ 'FORM_plural' | translate:'forms' | async }}</span>

    <div smallFilter>
        <mat-form-field class="standalone">
            <mat-label>{{ 'MODE' | translate | async }}</mat-label>
            <mat-select [formControl]="mode" [compareWith]="modeComparer">
                <mat-option *ngFor="let mode of modes" [value]="mode">{{ mode.title | translateSync }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</eaw-page-header>

<mat-card class="mat-compact-card">
    <mat-card-content>
        <eaw-data-table
                [columns]="columns"
                [request]="request"
                [goTo]="{state: 'eaw/app/forms/view', params: [{stateKey: 'id', itemKey: 'id'}]}"
                initialSortBy="name"
                initialSortDirection="asc"
                (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
