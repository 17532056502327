import { SimplePermission } from '../interfaces/simple-permission';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { CustomerUser, CustomerUserResponse } from './customer-user';
import type { RoleAssignment } from '../../leader-roles/shared/types/role-assignment';
import { Language, LanguageResponse } from '../../admin/models/language';
import { Employee, EmployeeResponse } from './employee';
import { UserGroup, UserGroupResponse } from '../../company/models/user-group';
import { ApiResponse } from '../interfaces/api-response';
import { BaseApiModel } from './base-api-model';

export interface UserResponse extends ApiResponse {
    country_code?: number | string | null;
    created_at?: string | null;
    email: string;
    first_name: string,
    id: number;
    language?: LanguageResponse,
    language_code: string;
    last_name: string,
    last_active?: string | null,
    permissions?: Record<string, SimplePermission>,
    phone?: string | null,
    profile_picture_id?: number | null,
    customer_users?: CustomerUserResponse[];
    employees?: EmployeeResponse[];
    groups?: UserGroupResponse[];
    properties_count?: number;
    email_change?: any;
}

export class User extends BaseApiModel<UserResponse, User> {
    allowed: Map<string, boolean> = new Map();
    countryCode?: string | null;
    createdAt?: DateTime | null;
    languageCode: string;
    email: string;
    firstName: string;
    id: number;
    language?: Language;
    lastName: string;
    lastActive?: DateTime | null;
    name: string;
    permissions: Record<string, SimplePermission>;
    permissionNodes: string[];
    phone?: string | null;
    fullPhone?: string | null;
    profilePictureId?: number | null;
    customerUsers?: CustomerUser[];
    employees?: Employee[];
    userGroups?: UserGroup[];
    propertiesCount?: number;
    emailChange: any;

    constructor(data: UserResponse) {
        super(data, undefined);

        this.countryCode = data.country_code ? String(data.country_code) : undefined;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.lastActive = data.last_active ? stringToDateTime(data.last_active) : null;
        this.email = data.email?.trim(); // Email might be undefined in some tests atm
        this.id = data.id;
        this.language = data.language ? new Language(data.language) : undefined;
        this.firstName = data.first_name;
        this.lastName = data.last_name;
        this.name = `${this.firstName} ${this.lastName}`;
        this.permissions = data.permissions || {};
        this.permissionNodes = Object.keys(this.permissions);
        this.phone = data.phone;
        this.profilePictureId = data.profile_picture_id;
        this.employees = data.employees ? data.employees.map((e) => new Employee(e)) : undefined;
        this.languageCode = data.language_code;
        this.userGroups = data.groups?.map((g) => new UserGroup(g));
        this.propertiesCount = data.properties_count;
        this.emailChange = data.email_change;

        this.customerUsers = data.customer_users?.map((cu) => {
            const customerUser = new CustomerUser(cu);
            customerUser.user = this;

            return customerUser;
        });

        this.setFullPhone();
    }

    private setFullPhone() {
        if (this.countryCode && this.phone) {
            this.fullPhone = `+${this.countryCode} ${this.phone}`;
        } else if (this.phone) {
            this.fullPhone = this.phone;
        }
    }

    /**
     * This getter is here for compatibility with the old profile-picture directive
     * @deprecated
     * @returns {number | null | undefined}
     */
    get profile_picture_id(): number | null | undefined {
        return this.profilePictureId;
    }

    static hasLeaderRole(roleAssignments: RoleAssignment[], customerId: number) {
        return (roleAssignments.filter((roleAssignment: RoleAssignment) => roleAssignment.role?.customer_id == customerId).length ?? 0) > 0;
    }
}
