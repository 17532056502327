// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog id="hr-file-download-dialog" class="mini-dialog">
    <eaw-dialog-header-old heading="'DOWNLOAD' | i18next"></eaw-dialog-header-old>

    <md-dialog-content ng-if="!$ctrl.gettingUrl" layout="column" layout-align="center center">
        <md-icon ng-bind="'done'" md-colors="{color: 'green'}"></md-icon>
        <span ng-i18next="hr:FILE_READY_DOWNLOAD"></span>
    </md-dialog-content>

    <md-dialog-content ng-if="$ctrl.gettingUrl" layout="column" layout-align="center center">
        <md-progress-circular  class="md-progress-center"></md-progress-circular>
        <span ng-i18next="hr:PREPARING_DOWNLOAD"></span>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button class="md-raised md-accent" ng-i18next="DOWNLOAD" ng-click="$ctrl.download()" ng-disabled="$ctrl.gettingUrl"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.hr').factory('fileDownloadHr', [ '$mdDialog', function($mdDialog) {
    const fac = this;

    fac.open = (customer, employee, file, type) => $mdDialog.show({
        template: template1,
        controllerAs: '$ctrl',
        controller: 'fileDownloadHrCtrl',
        bindToController: true,
        locals: {
            customer,
            employee,
            file,
            type,
        },
    });

    return fac;
} ]);
