import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Injectable({
    providedIn: 'root',
})
export class VerifyEmailHookService {

    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
        // Check that we have the required params to go to email verification
        this.uiRouter.transitionService.onBefore({
            to: 'eaw/outside/verify_email',
        }, (transition) => {
            const email = transition.params()['email'];
            const token = transition.params()['token'];

            if (!(email && token)) {
                return transition.router.stateService.target('eaw/outside/login');
            }
        });
    }
}
