import { Directive, effect, ElementRef, inject, input } from '@angular/core';
import { EawMaterialColorHue } from '../services/material-color.service';
import { MaterialColorPipe } from '../pipes/material-color.pipe';
import { TinyColor } from '@ctrl/tinycolor';
import { environment } from '../../../environments/environment';

type ColorProp = 'backgroundColor' | 'color';

@Directive({
    selector: '[eawMaterialColor]',
    providers: [ MaterialColorPipe ],
    standalone: true,
})
export class MaterialColorDirective {
    private materialColorPipe = inject(MaterialColorPipe);
    private el = inject(ElementRef);

    eawMaterialColor = input<EawMaterialColorHue>();
    colorProperty = input<ColorProp>();

    private readonly className = 'material-color-directive';

    constructor() {
        if (!environment.isTesting) {
            effect(() => this.updateColor());
        }
    }

    async updateColor() {
        const el = this.el.nativeElement;
        const colorProperty = this.colorProperty() || 'color';
        const materialColor = this.eawMaterialColor();

        if (!materialColor) {
            el.classList.remove(this.className); // @see src/styles/material-color-directive.scss
            return;
        }

        el.classList.add(this.className); // @see src/styles/material-color-directive.scss

        const color = await this.materialColorPipe.transform(materialColor);
        const tiny = new TinyColor(color);

        if (colorProperty === 'backgroundColor') {
            el.style.setProperty('--background-color', tiny.toHexString());
            el.style.setProperty('--color', tiny.isLight() ? 'black' : 'white');
        } else {
            el.style.setProperty('--color', tiny.toHexString());
        }
    }
}
