import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { VacationDaysGetAllReturn, VacationDaysService } from '../../http/vacation-days.service';
import { Employee } from '../../../shared/models/employee';
import { Customer } from '../../../shared/models/customer';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { forkJoin, map, of, Subject, take, tap } from 'rxjs';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { VacationDay } from '../../models/vacation-day';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { TranslateService } from '../../../shared/services/translate.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AssignVacationDaysDialogService } from '../../dialogs/assign-vacation-days/assign-vacation-days-dialog.service';
import { EmployeeService } from '../../../shared/http/employee.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';

@Component({
    selector: 'eaw-employee-vacation-days',
    templateUrl: './employee-vacation-days.component.html',
    styleUrl: './employee-vacation-days.component.scss',
    standalone: true,
    imports: [
        NgIf,
        PageHeaderComponent,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgFor,
        MatOptionModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MaterialColorDirective,
        MatIconModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmployeeVacationDaysComponent implements OnInit, EawDataTable<VacationDay> {
    @ViewChild('dataTableComponent') dataTable?: DataTableComponent<VacationDay>;
    @Input() customerId!: number;
    @Input() employeeId!: number;

    private readonly earliestYear = 2018;

    availableYears = new Array(DateTime.now().year + 2 - this.earliestYear)
        .fill(0)
        .reduce((arr, _, index) => arr.concat(this.earliestYear + index), [])
        .reverse();

    yearControl = new FormControl(DateTime.now().year, { nonNullable: true });
    vacationDays = 0;
    receivedVacationDays = 0;
    carriedOverVacationDays = 0;
    employee!: Employee;
    customer!: Customer;
    request: DataTableRequest = of(mockArrayPaginatedResponse());
    canCreateDays = false;
    columns: DataTableColumnType<VacationDay>[] = [
        new DataTableNumberColumn({
            value: 'delta',
            numberFormatOptions: {
                maximumFractionDigits: 0,
                signDisplay: 'exceptZero',
            },
            header: new DataTableHeader({
                i18n: 'DAY_CHANGE',
                ns: 'vacation',
                sortBy: 'delta',
            }),
        }),
        new DataTableTextColumn({
            header: new DataTableHeader({
                i18n: 'CHANGED_BY',
                ns: 'vacation',
            }),
            value: (cell) => {
                if (cell.item.type === 'automatic') {
                    return this.translate.t('AUTO', 'payroll');
                }
                if (cell.item.type === 'vacation') {
                    return this.translate.t('VACATION', 'vacation');
                }

                return Promise.resolve(cell.item.performedByName || '');
            },
        }),
        new DataTableDateTimeColumn({
            value: 'effective',
            header: new DataTableHeader({
                i18n: 'VALID_FROM',
                sortBy: 'effective',
            }),
            format: DateTime.DATE_MED,
        }),
        new DataTableDateTimeColumn({
            value: 'createdAt',
            header: new DataTableHeader({
                i18n: 'EDITED',
                sortBy: 'created_at',
            }),
            format: DateTime.DATETIME_MED,
        }),
        new DataTableTextColumn({
            value: 'description',
            header: new DataTableHeader({ i18n: 'COMMENT' }),
        }),
    ];

    constructor(
        @Inject(VacationDaysService) private vacationDaysService: VacationDaysService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(ElementRef) private el: ElementRef,
        @Inject(AssignVacationDaysDialogService) private assignVacationDaysDialog: AssignVacationDaysDialogService,
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
    ) {
    }

    ngOnInit() {
        if (!this.customerId) {
            throw Error('Missing customer ID');
        }
        if (!this.employeeId) {
            throw Error('Missing employee ID');
        }

        this.employeeService.get(this.customerId, this.employeeId).subscribe((employee) => {
            this.employee = employee;
        });
    }

    assignDays(mode: 'add' | 'deduct') {
        this.assignVacationDaysDialog.open(mode, this.customerId, this.employeeId).afterClosed().subscribe((result) => {
            if (result == null) {
                return;
            }
            this.update(this.dataTable?.getPagination({ page: 1 }));
        });
    }

    setProgressPercent(percent: number) {
        this.el.nativeElement.style.setProperty('--progress-percent', `${percent}%`);
    }

    update(pagination: Partial<DataTablePagination> = {}) {
        if (!(this.employeeId && this.customerId)) {
            return;
        }

        // It's a temporary subject that will go along with the data table request and emit when that request is finished
        // so that our forkJoin waits for both stats and table request to finish
        const tableSubject: Subject<VacationDaysGetAllReturn> = new Subject<VacationDaysGetAllReturn>();

        forkJoin([ this.getStats(), tableSubject.asObservable() ])
            .pipe(take(1))
            .subscribe(([ stats, table ]) => {
                this.vacationDays = stats.days;
                this.receivedVacationDays = stats.received;
                this.carriedOverVacationDays = stats.carriedOver;
                this.setProgressPercent(this.vacationDays / this.receivedVacationDays * 100);
                this.setCanCreate(table.employee, table.employee?.customer);
            });

        this.updateTable(pagination, tableSubject);
    }

    setCanCreate(employee?: Employee, customer?: Customer) {
        if (employee && customer) {
            this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].employees.[${ApiModel.Employee}].vacation_days.create`, {
                stackId: customer.stackId,
                models: [
                    { type: ApiModel.Employee, id: employee.id },
                    { type: ApiModel.Customer, id: customer.id },
                ],
            }).subscribe((canCreate) => {
                this.canCreateDays = canCreate;
            });
        } else {
            this.canCreateDays = false;
        }
    }

    getStats() {
        this.setProgressPercent(0);
        return this.vacationDaysService.getStats(this.customerId, this.employeeId, this.yearControl.value);
    }

    updateTable(pagination: Partial<DataTablePagination> = {}, subject: Subject<VacationDaysGetAllReturn>): void {
        const year = DateTime.fromObject({ year: this.yearControl.value });

        this.request = this.vacationDaysService.getAll(this.customerId, this.employeeId, {
            order_by: 'created_at',
            direction: 'desc',
            from: year.startOf('year'),
            to: year.endOf('year'),
            ...pagination,
        }).pipe(
            tap((response) => {
                subject.next(response);
                subject.complete();
            }),
            map((response) => {
                return {
                    current_page: response.current_page,
                    last_page: response.last_page,
                    total: response.total,
                    per_page: response.per_page,
                    from: response.from,
                    to: response.to,
                    data: Array.isArray(response.data) ? response.data : Object.values(response.data),
                } satisfies ArrayPaginatedResponse<VacationDay>;
            }),
        );
    }
}
