import { Component, inject, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { UIRouter } from '@uirouter/core';
import { ScheduleShiftDialogComponent, ScheduleShiftDialogData, ScheduleShiftDialogReturn } from '../schedule-shift-dialog/schedule-shift-dialog.component';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { ShiftEmployeeAutocompleteService } from '../../../shared/autocompletes/shift-employee-autocomplete.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ShiftEmployee } from '../../models/shift-employee';
import { Employee } from '../../../shared/models/employee';
import { BusinessDate } from '../../../shared/utils/business-date';
import { CommentDialogComponent, CommentDialogData } from '../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { catchError, EMPTY, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';
import { ShiftCommentService } from '../../http/shift-comment.service';
import { ShiftService } from '../../http/shift.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { Namespace } from '../../../shared/enums/namespace';
import { AddEditShiftPeriodDialogComponent, AddEditShiftPeriodDialogData, AddEditShiftPeriodDialogReturn } from '../add-edit-shift-period-dialog/add-edit-shift-period-dialog.component';
import { DateTime } from 'luxon';
import { ShiftCopyDialogComponent, ShiftCopyDialogData, ShiftCopyDialogResult } from '../shift-copy-dialog/shift-copy-dialog.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { PermissionPipe } from '../../../shared/pipes/permission.pipe';
import { EawMaterialColorHue } from '../../../shared/services/material-color.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { Shift } from '../../models/shift';
import { CurrentService } from '../../../shared/services/current.service';

export interface ScheduleMiniShiftDialogData extends DialogData {
    customerId: number;
    scheduleId: number;
    shiftId: number;
    isTemplate: boolean;
    date: DateTime;
    businessDate: BusinessDate;
    employee?: Employee;
    employeeId?: number;
}

interface ListItem {
    text: Promise<string>,
    permission: Observable<boolean>,
    hasPermission?: boolean,
    color: EawMaterialColorHue,
    icon: string,
    click: () => void,
    disabled?: () => boolean
}

@Component({
    selector: 'eaw-schedule-mini-shift-dialog',
    standalone: true,
    imports: [ CommonModule, MatListModule, MatIconModule, TranslatePipe, MaterialColorDirective, AutocompleteComponent, ReactiveFormsModule, PermissionPipe, InfoLoadingComponent ],
    providers: [
        {
            provide: 'scheduleTable',
            useFactory: ($injector: any) => $injector.get('scheduleTable'),
            deps: [ '$injector' ],
        },
        {
            provide: 'shiftEvents',
            useFactory: ($injector: any) => $injector.get('shiftEvents'),
            deps: [ '$injector' ],
        },
    ],
    templateUrl: './schedule-mini-shift-dialog-component-old.component.html',
    styleUrl: './schedule-mini-shift-dialog-component-old.component.scss',
})
/**
 * @deprecated
 * @see ScheduleMiniShiftDialogComponent
 *
 * REMOVE when new schedule is completed
 */
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ScheduleMiniShiftDialogComponentOld extends DialogComponent implements OnInit {
    private readonly uiRouter = inject(UIRouter);
    private readonly matDialog = inject(MatDialog);
    protected readonly shiftService = inject(ShiftService);
    protected readonly currentService = inject(CurrentService);
    protected readonly snackBarService = inject(SnackBarService);
    protected readonly translateService = inject(TranslateService);
    protected readonly shiftCommentService = inject(ShiftCommentService);
    protected readonly permissionCheckService = inject(PermissionCheckService);
    protected readonly employeeAutocompleteService = inject(EmployeeAutocompleteService);
    protected readonly shiftEmployeeAutocompleteService = inject(ShiftEmployeeAutocompleteService);

    private readonly shiftWiths = [ 'periods', 'periods.businessUnit', 'periods.qualifications', 'employee', 'qualifications', 'properties', 'shiftGroup', 'comments' ];
    protected readonly defaultItemColor: EawMaterialColorHue = 'grey-800';
    protected employeeControl = new FormControl<ShiftEmployee | Employee | number | null>(null);
    protected loading = true;
    protected employeeId?: number;
    protected items: ListItem[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ScheduleMiniShiftDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ScheduleMiniShiftDialogComponentOld>,
        @Inject('scheduleTable') private scheduleTable: any,
        @Inject('shiftEvents') private shiftEvents: any,
    ) {
        data.disableClose = false;
        data.size = DialogSize.Small;
        super(dialogRef, data);

        this.items = [
            {
                permission: this.permissionCheckService.isAllowed(`customers.${data.customerId}.schedules.${data.scheduleId}.shifts.${data.shiftId}.update`),
                icon: 'edit',
                color: 'light-green-500',
                click: this.edit.bind(this),
                text: this.translateService.t('EDIT'),
            },
            {
                permission: this.permissionCheckService.isAllowed(`customers.${data.customerId}.schedules.${data.scheduleId}.shifts.${data.shiftId}.update`),
                icon: 'person_off',
                click: this.removeEmployee.bind(this),
                disabled: () => !this.employeeId,
                color: this.defaultItemColor,
                text: this.translateService.t('REMOVE_EMPLOYEE', Namespace.Scheduling),
            },
            {
                permission: this.permissionCheckService.isAllowed(`customers.${data.customerId}.schedules.${data.scheduleId}.shifts.${data.shiftId}.periods.create`),
                icon: 'playlist_add',
                color: 'blue-500',
                click: this.addPeriod.bind(this),
                text: this.translateService.t('NEW_PERIOD', Namespace.Scheduling),
            },
            {
                permission: this.permissionCheckService.isAllowed(`customers.${data.customerId}.schedules.${data.scheduleId}.shifts.${data.shiftId}.periods.*.get`),
                icon: 'list',
                color: 'blue-500',
                click: this.openPeriods.bind(this),
                text: this.translateService.t('PERIOD_plural', Namespace.Scheduling),
            },
            {
                permission: this.permissionCheckService.isAllowed(`customers.${data.customerId}.schedules.${data.scheduleId}.shifts.${data.shiftId}.update`),
                icon: 'comment',
                color: this.defaultItemColor,
                click: this.comment.bind(this),
                text: this.translateService.t('COMMENT_plural'),
            },
            {
                permission: this.permissionCheckService.isAllowed(`customers.${data.customerId}.schedules.${data.scheduleId}.shifts.create`),
                icon: 'content_copy',
                color: this.defaultItemColor,
                click: this.copy.bind(this),
                text: this.translateService.t('COPY'),
            },
            {
                permission: this.permissionCheckService.isAllowed(`customers.${data.customerId}.schedules.${data.scheduleId}.shifts.${data.shiftId}.get`),
                icon: 'info',
                color: this.defaultItemColor,
                click: this.goToShift.bind(this),
                text: this.translateService.t('INFORMATION'),
            },
            {
                permission: this.permissionCheckService.isAllowed(`customers.${data.customerId}.schedules.${data.scheduleId}.shifts.${data.shiftId}.delete`),
                icon: 'delete',
                color: 'red-500',
                click: this.delete.bind(this),
                text: this.translateService.t('DELETE'),
            },
            {
                permission: of(true),
                icon: 'close',
                color: this.defaultItemColor,
                click: this.dialogRef.close.bind(this.dialogRef),
                text: this.translateService.t('CLOSE_DIALOG'),
            },
        ];
    }

    ngOnInit() {
        this.employeeId = this.data.employee?.id || this.data.employeeId || undefined;
        this.employeeControl.setValue(this.data.employee || this.data.employeeId || null);

        this.permissionCheckService.isAllowed(`customers.${this.data.customerId}.schedules.${this.data.scheduleId}.shifts.${this.data.shiftId}.update`).subscribe((canUpdate) => {
            if (!canUpdate) {
                this.employeeControl.disable();
            }
        });

        forkJoin(
            this.items.map((i) => {
                return i.permission.pipe(
                    tap((hasPermission) => {
                        i.hasPermission = hasPermission;
                    }),
                );
            }),
        ).subscribe(() => {
            this.loading = false;
        });

        this.items.forEach((item) => {
            item.permission.subscribe((hasPermission) => (item.hasPermission = hasPermission));
        });

        this.employeeControl.valueChanges.pipe(
            switchMap((employee) => {
                if (employee instanceof Employee || employee instanceof ShiftEmployee) {
                    if (this.employeeId === employee.id) {
                        return EMPTY;
                    }

                    void this.snackBarService.t(`ASSIGNING_EMP_TO_SHIFT`, Namespace.Scheduling, { name: employee.name });
                    this.dialogRef.close();
                    this.shiftEvents.trigger.updating(this.getOldVersionOfShift());

                    return this.shiftService.update(this.data.customerId, this.data.scheduleId, this.data.shiftId, { employee_id: employee.id, with: this.shiftWiths }).pipe(
                        catchError(() => {
                            this.employeeControl.setValue(null);
                            void this.snackBarService.t(`ASSIGNING_EMP_TO_SHIFT_FAIL`, Namespace.Scheduling, { name: employee.name });
                            this.shiftEvents.trigger.updated(this.getOldVersionOfShift());
                            return EMPTY;
                        }),
                        tap((shift) => {
                            this.getOldVersionOfShift().refresh(shift._response);
                            this.employeeId = employee.id;
                            void this.snackBarService.t(`ASSIGNING_EMP_TO_SHIFT_SUCCESS`, Namespace.Scheduling, { name: employee.name });
                        }),
                    );
                }

                return EMPTY;
            }),
        ).subscribe();
    }

    edit() {
        const ref = this.matDialog.open<ScheduleShiftDialogComponent, ScheduleShiftDialogData, ScheduleShiftDialogReturn>(ScheduleShiftDialogComponent, {
            data: {
                stackId: this.currentService.getCustomer().stackId,
                customerId: this.data.customerId,
                scheduleId: this.data.scheduleId,
                shiftId: this.data.shiftId,
                shiftWiths: this.shiftWiths,
                periodAddedCallback: () => this.shiftEvents.trigger.periodsChange(this.data.shiftId),
                periodEditedCallback: () => this.shiftEvents.trigger.periodsChange(this.data.shiftId),
                periodDeletedCallback: () => this.shiftEvents.trigger.periodsChange(this.data.shiftId),
            },
        });

        ref.afterOpened().subscribe(() => this.dialogRef.close());
        ref.afterClosed().subscribe((res) => {
            if (res instanceof Shift) {
                this.getOldVersionOfShift().refresh(res._response);
            }

            if (res === null) {
                this.scheduleTable.getSchedule().removeShift(this.data.shiftId);
                this.shiftEvents.trigger.deleted({ id: this.data.shiftId });
            }
        });
    }

    addPeriod() {
        const ref = this.matDialog.open<AddEditShiftPeriodDialogComponent, AddEditShiftPeriodDialogData, AddEditShiftPeriodDialogReturn>(AddEditShiftPeriodDialogComponent, {
            data: {
                customerId: this.data.customerId,
                scheduleId: this.data.scheduleId,
                shiftId: this.data.shiftId,
            },
        });

        ref.afterClosed().subscribe((period) => {
            if (period) {
                this.shiftEvents.trigger.periodsChange(this.data.shiftId);
            }
        });

        ref.afterOpened().subscribe(() => this.dialogRef.close());
    }

    openPeriods() {
        const ref = this.matDialog.open<ScheduleShiftDialogComponent, ScheduleShiftDialogData>(ScheduleShiftDialogComponent, {
            data: {
                stackId: this.currentService.getCustomer().stackId,
                customerId: this.data.customerId,
                scheduleId: this.data.scheduleId,
                shiftId: this.data.shiftId,
                initialTab: 1,
                shiftWiths: this.shiftWiths,
                periodAddedCallback: () => this.shiftEvents.trigger.periodsChange(this.data.shiftId),
                periodEditedCallback: () => this.shiftEvents.trigger.periodsChange(this.data.shiftId),
                periodDeletedCallback: () => this.shiftEvents.trigger.periodsChange(this.data.shiftId),
            },
        });

        ref.afterOpened().subscribe(() => this.dialogRef.close());
    }

    copy() {
        const ref = this.matDialog.open<ShiftCopyDialogComponent, ShiftCopyDialogData, ShiftCopyDialogResult>(ShiftCopyDialogComponent, {
            data: {
                customerId: this.data.customerId,
                scheduleId: this.data.scheduleId,
                shiftId: this.data.shiftId,
            },
        });

        ref.afterClosed().subscribe((result) => {
            if (result?.copied) {
                void this.uiRouter.stateService.reload();
            }
        });

        ref.afterOpened().subscribe(() => this.dialogRef.close());
    }

    comment() {
        const ref = this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                comments: this.shiftCommentService.getAll(this.data.customerId, this.data.scheduleId, this.data.shiftId).pipe(map((response) => response.data)),
                saveCallback: (comment) => this.shiftCommentService.create(this.data.customerId, this.data.scheduleId, this.data.shiftId, comment),
                deleteCallback: (commentId) => this.shiftCommentService.delete(this.data.customerId, this.data.scheduleId, this.data.shiftId, commentId),
            },
        });

        ref.afterOpened().subscribe(() => this.dialogRef.close());
        ref.afterClosed().subscribe(() => {
            this.getOldVersionOfShift().refresh();
        });
    }

    delete() {
        void this.snackBarService.t('DELETING_SHIFT', Namespace.Scheduling);
        this.shiftEvents.trigger.deleting(this.getOldVersionOfShift());
        this.dialogRef.close();

        this.shiftService.delete(this.data.customerId, this.data.scheduleId, this.data.shiftId).pipe(
            catchError(() => {
                void this.snackBarService.t('DELETE_SHIFT_FAIL', Namespace.Scheduling);
                return EMPTY;
            }),
        ).subscribe(() => {
            this.scheduleTable.getSchedule().removeShift(this.data.shiftId);
            this.shiftEvents.trigger.deleted({ id: this.data.shiftId });
            void this.snackBarService.t('SHIFT_DELETED', Namespace.Scheduling);
        });
    }

    removeEmployee() {
        if (!this.employeeId) {
            return;
        }

        void this.snackBarService.t('UNASSIGNING_SHIFT', Namespace.Scheduling);
        this.shiftService.update(this.data.customerId, this.data.scheduleId, this.data.shiftId, { employee_id: null, with: this.shiftWiths }).pipe(
            catchError(() => {
                void this.snackBarService.t('UNASSIGNING_SHIFT_FAIL', Namespace.Scheduling);
                return EMPTY;
            }),
        ).subscribe((shift) => {
            void this.snackBarService.t('UNASSIGNING_SHIFT_SUCCESS', Namespace.Scheduling);
            this.getOldVersionOfShift().refresh(shift._response);
        });

        this.dialogRef.close();
    }

    goToShift() {
        this.uiRouter.stateService.transitionTo('eaw/app/scheduling/shifts/shift', { id: this.data.shiftId, customer_id: this.data.customerId });
        this.dialogRef.close();
    }

    getOldVersionOfShift() {
        return this.scheduleTable.getSchedule().getShift(this.data.shiftId);
    }
}
