import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddPermissionSetDialogComponent } from '../../../dialogs/add-permission-set-dialog/add-permission-set-dialog.component';
import { DialogData, DialogSize } from '../../../../shared/dialogs/dialog-component';
import { PermissionSet } from '../../../../shared/models/permission-set';
import { ConfirmDialogService } from '../../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../../shared/services/translate.service';
import { AddPermissionDialogComponent } from '../../../dialogs/add-permission-dialog/add-permission-dialog.component';
import { SimplePermission } from '../../../../shared/interfaces/simple-permission';

@Injectable({
    providedIn: 'root',
})
export class PermissionDialogService {

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(ConfirmDialogService) private confirm: ConfirmDialogService,
        @Inject(TranslateService) private translate: TranslateService,
    ) { }

    addPermission() {
        return this.dialog.open<AddPermissionDialogComponent, DialogData, SimplePermission[]>(AddPermissionDialogComponent, { data: { size: DialogSize.Medium } });
    }

    addPermissionSet() {
        return this.dialog.open<AddPermissionSetDialogComponent, DialogData, PermissionSet>(AddPermissionSetDialogComponent, { data: { size: DialogSize.Medium } });
    }

    // Remove confirmation for permission and permission sets, takes an array parameters corresponding to TranslateService's "t" method parameters
    remove(text: Parameters<TranslateService['t']>) {
        return this.confirm.delete({
            title: this.translate.t('REMOVE'),
            confirmText: this.translate.t('REMOVE'),
            // Uses parameter for this method to set text content
            text: this.translate.t(...text),
        });
    }
}
