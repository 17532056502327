import { ApiResponse } from '../interfaces/api-response';
import { Permission, PermissionResponse } from './permission';

export interface PermissionSetResponse extends ApiResponse {
    id: number;
    name: string;
    description: string;
    product_name: string;
    permission_sets?: PermissionSetResponse[];
    permissions?: PermissionResponse[];
    permission_set_objects_count?: number;
}

export class PermissionSet {
    id: number;
    name: string;
    description: string;
    productName: string;
    permissionSets?: PermissionSet[];
    permissions?: Permission[];
    countParents?: number;

    constructor(data: PermissionSetResponse) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.productName = data.product_name;
        this.permissionSets = data.permission_sets ? data.permission_sets.map((p) => new PermissionSet(p)) : undefined;
        this.permissions = data.permissions ? data.permissions.map((p) => new Permission(p)) : undefined;
        this.countParents = typeof data.permission_set_objects_count === 'number' ? data.permission_set_objects_count : undefined;
    }
}
