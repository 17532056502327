import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { ScheduleSummary, ScheduleSummaryResponse } from '../models/schedule-summary';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';

type GetOptions = {
    business_date?: DateTime
    from?: DateTime
    to?: DateTime
    force?: boolean,
};

@Injectable({
    providedIn: 'root',
})
export class ScheduleSummaryService {
    constructor(@Inject(HttpClient) private http: HttpClient) { }

    get(customerId: number, scheduleId: number, options: GetOptions = {}) {
        return this.http.get<ScheduleSummaryResponse>(`/customers/${customerId}/schedules/${scheduleId}/summary`, {
            params: formatHttpParams({
                business_date: options.business_date?.toISODate(),
                from: options.from?.toISODate(),
                to: options.to?.toISODate(),
                force: options.force,
            }),
        }).pipe(classifyItem(ScheduleSummary));
    }
}
