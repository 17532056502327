// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.components').component('eawIconButton', {
    template: `<md-button class="eaw-icon-button"
           md-colors="::$ctrl.btnMdColors"
           ng-class="::$ctrl.classes"
           type="{{::$ctrl.buttonType}}"
           form="{{::$ctrl.form}}"
           ng-disabled="$ctrl.disabled">
    <md-tooltip ng-if="$ctrl.tooltip" md-direction="top" ng-bind="$ctrl.tooltip"></md-tooltip>
    <md-icon class="s18" ng-if="$ctrl.icon" ng-bind="$ctrl.icon"></md-icon>
    <span ng-if="$ctrl.text" ng-bind="::$ctrl.text"></span>
</md-button>
`,
    bindings: {
        buttonType: '@',
        form: '@',
        type: '@?', // ok, primary, accent, warn, alert
        raised: '<?', // default true (md-raised)
        fab: '<?', // md-fab, md-mini
        icon: '@', // mdi icon name, ex "plus"
        text: '<?',
        tooltip: '<?', // ex 'scheduling:REQUEST_SWAP' | i18next // Remember to include ''
    },
    controller: [ '$attrs', function($attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.classes = [];
            ctrl.raised = ctrl.raised ?? true;
            ctrl.buttonType = ctrl.buttonType || 'button';

            if (ctrl.raised) {
                ctrl.classes.push('md-raised');
            }

            if (ctrl.tooltip) {
                ctrl.tooltip = t(ctrl.tooltip);
            }

            if (ctrl.type) {
                if (ctrl.type === 'ok') {
                    ctrl.btnMdColors = { background: 'green' };
                } else if (ctrl.type === 'alert') {
                    ctrl.btnMdColors = { background: 'amber' };
                } else {
                    ctrl.classes.push(`md-${ctrl.type}`);
                }
            }

            if (ctrl.fab) {
                ctrl.classes.push('md-fab', 'md-mini');
            }

            $attrs.$observe('disabled', (value) => {
                ctrl.disabled = value;
            });
        };
    } ],
});
