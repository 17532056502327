import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { use } from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import { Namespace } from '../../shared/enums/namespace';
import { Duration } from 'luxon';
import { CurrentLoaderService } from './current-loader.service';
import { firstValueFrom, forkJoin, from, of, switchMap, take, tap } from 'rxjs';
import { CurrentService } from '../../shared/services/current.service';
import { LanguageService } from '../../admin/http/language.service';
import { expandAllPages } from '../../shared/utils/rxjs/expand-all-pages';
import { Language, LanguageResponse } from '../../admin/models/language';
import { Storage } from '../../shared/utils/storage';
import { InitializerCacheItem, InitializerCacheService } from './initializer-cache.service';
import { AppService } from '../../shared/services/app.service';

@Injectable({
    providedIn: 'root',
})
export class I18nextLoaderService {
    readonly fallbackLanguage = new Language({ code: 'en', created_at: '', ietf_bcp47_tag: 'en-US', iso639_1: '', name: 'English', updated_at: '' });
    availableWtiLanguages: Language[] = [];
    currentLanguage = this.fallbackLanguage;

    constructor(
        @Inject(CurrentLoaderService) private currentLoader: CurrentLoaderService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(LanguageService) private languageService: LanguageService,
        @Inject(InitializerCacheService) private initializerCacheService: InitializerCacheService,
        @Inject(AppService) private appService: AppService,
    ) {
    }

    init() {
        // Wait for current to fetch data and load.
        // It returns true or false depending on whether it was loaded or not.
        return firstValueFrom(this.currentLoader.onLoaded().pipe(
            switchMap((loaded) => {
                return forkJoin([
                    of(loaded),
                    this.getLanguages(),
                ]);
            }),
            switchMap(async ([ loaded, languages ]) => {
                this.availableWtiLanguages = [ ...languages ];

                const fallbackLanguageCodes = (await Storage.getLanguage()) || navigator.language || this.fallbackLanguage.languageTag;
                const languageCode = loaded ? this.current.getMe().user.languageCode : fallbackLanguageCodes;

                return this.initI18next(languageCode);
            }),
        ));
    }

    private checkCacheEquality(cached: InitializerCacheItem<LanguageResponse[]>) {
        this.languageService.getAll({ per_page: 1, order_by: 'updated_at', direction: 'desc' }).pipe(take(1)).subscribe(async (response) => {
            const mostRecentlyUpdatedLanguage = response.data.length ? response.data[0] : undefined;
            const differentAmountOfLanguages = cached.value.length !== response.total;
            const languageHasUpdated = cached.updatedAt !== mostRecentlyUpdatedLanguage?._response.updated_at;

            if (differentAmountOfLanguages || languageHasUpdated) {
                await this.initializerCacheService.clear('default');
                this.appService.reload();
            }
        });
    }

    private async getLanguages() {
        const languagesCacheKey = 'languages';
        const cached = await this.initializerCacheService.get<LanguageResponse[]>('default', languagesCacheKey);

        if (cached) {
            setTimeout(() => this.checkCacheEquality(cached), 5000);
            return cached.value.map((v) => new Language(v));
        }

        const allLanguages = expandAllPages((pagination) => this.languageService.getAll(pagination), { per_page: 50, order_by: 'updated_at', direction: 'desc' }).pipe(
            tap(async (languages) => {
                const mostRecentlyUpdated = languages.length ? languages[0] : undefined;
                if (mostRecentlyUpdated) {
                    await this.initializerCacheService.store('default', languagesCacheKey, languages.map((l) => l._response), mostRecentlyUpdated._response.updated_at);
                }
            }),
        );

        return firstValueFrom(allLanguages);
    }

    /**
     * Find the correct language to use for WebTranslateIt
     */
    private chooseWtiLanguage(languageCode: string): Language {
        // If the language is available, use it
        const availableLanguage = this.availableWtiLanguages.find((x) => x.code.toLowerCase() === languageCode.toLowerCase());
        if (availableLanguage) {
            return availableLanguage;
        }

        return this.fallbackLanguage;
    }

    private initI18next(languageCode: string) {
        this.currentLanguage = this.chooseWtiLanguage(languageCode);

        // Update the language of the document
        document.documentElement.setAttribute('lang', this.currentLanguage.code);

        // Initialize i18next
        const initFn = use(Backend).init({
            backend: {
                backends: [
                    HttpApi,
                ],
                backendOptions: [
                    {
                        loadPath: environment.isTesting ? '' : 'https://static-lang.easyatwork.com/eaw2/__lng__/__ns__.json',
                    },
                ],
            },
            load: 'currentOnly',
            debug: false, // !environment.isLive, // Uncomment if you want to see debug logs
            lng: this.currentLanguage.code.startsWith('en') ? 'en' : this.currentLanguage.code,
            fallbackLng: false,
            interpolation: {
                prefix: '__',
                suffix: '__',
                useRawValueToEscape: true,
                skipOnVariables: false,
                escape: function(thing: unknown) {
                    const string = String(thing);

                    return string.includes('<') || string.includes('>')
                        ? string.replace(/</g, '&lt').replace(/>/g, '&gt')
                        : string;
                },
            },
            ns: [
                Namespace.General,
                Namespace.Errors,
                Namespace.Navigation,
                Namespace.CustomFields,
                Namespace.Warnings,
                Namespace.Colors,
                Namespace.Notifications,
                Namespace.Login,
            ],
            defaultNS: Namespace.General,
            fallbackNS: Namespace.General,
            compatibilityJSON: 'v1',
            cache: {
                expirationTime: Duration.fromObject({ day: 1 }).as('milliseconds'),
                enabled: environment.isProduction,
                defaultVersion: environment.isProduction ? environment.version : +Date.now(),
            },
        });

        return from(initFn);
    }
}
