import { inject, Injectable } from '@angular/core';
import { Me, MeCustomer, MeResponse } from '../../shared/models/me';
import { CurrentService } from '../../shared/services/current.service';
import { catchError, firstValueFrom, map, of, ReplaySubject, switchMap, tap, timer } from 'rxjs';
import { Storage } from '../../shared/utils/storage';
import { BaseLoaderService } from './base-loader.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { UserPropertyService } from '../../shared/http/user-property.service';
import { CustomerMe, MeService } from '../../shared/http/me.service';
import { TasksService } from '../../tasks/services/tasks.service';
import { UserOld } from '../../shared/angularjs/user-old';
import { CurrentOld } from '../../shared/angularjs/current.factory';
import CustomerOld from '../../shared/angularjs/customer-old';
import { LoginService } from '../../shared/services/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { InitializerCacheService } from './initializer-cache.service';
import { AppService } from '../../shared/services/app.service';
import { Product } from '../../shared/models/product';
import { CustomerProductService } from '../../shared/http/customer-product.service';

@Injectable({
    providedIn: 'root',
})
export class CurrentLoaderService {
    private readonly meService = inject(MeService);
    private readonly appService = inject(AppService);
    private readonly current = inject(CurrentService);
    private readonly taskService = inject(TasksService);
    private readonly oAuthService = inject(OAuthService);
    private readonly loginService = inject(LoginService);
    private readonly baseLoaderService = inject(BaseLoaderService);
    private readonly userPropertyService = inject(UserPropertyService);
    private readonly customerProductService = inject(CustomerProductService);
    private readonly initializerCacheService = inject(InitializerCacheService);

    private currentLoadedSubject = new ReplaySubject<boolean>(1);

    private async getRelevantCustomerId(me: Me) {
        // Available customers
        const meCustomers = Object.values(me.customers || {});

        // Stored customer
        const storedCustomerId = await Storage.getCustomer(me.user.id);

        // Get default customer id if applicable
        const defaultCustomerId = await this.getDefaultCustomerId(storedCustomerId, meCustomers, me);

        const firstCustomerId = meCustomers[0]?.id || undefined;
        return storedCustomerId || defaultCustomerId || firstCustomerId;
    }

    onLoaded() {
        return this.currentLoadedSubject.asObservable();
    }

    init() {
        this.baseLoaderService.onLoaded().subscribe((loaded) => {
            if (loaded) {
                void this.initCurrent();
            }
        });
    }

    private async getDefaultCustomerId(storedCustomer: number | null, customers: MeCustomer[], me: Me) {
        if (storedCustomer) {
            return null;
        }

        // Only check default customer if user has access to more than 1 and no stored customer is set
        let defaultCustomerId: number | null | undefined;
        if (customers.length > 1) {
            defaultCustomerId = await firstValueFrom(this.userPropertyService.get(me.user.id, 'default_customer').pipe(
                map((property) => property.value.asInteger()),
                catchError(() => of(undefined)),
            ));
        }

        if (defaultCustomerId) {
            if (!customers.find((c) => c.id === defaultCustomerId)) {
                defaultCustomerId = undefined;
                this.userPropertyService.delete(me.user.id, 'default_customer').subscribe();
            }
        }

        return defaultCustomerId;
    }

    private async initCurrent() {
        const token = this.oAuthService.hasValidAccessToken() ? this.oAuthService.getAccessToken() : undefined;

        // If we have a token, that means we're logged in
        if (!token) {
            this.currentLoadedSubject.next(false);
            return;
        }
        const cachedMe = (await this.initializerCacheService.get<MeResponse>('default', 'me'))?.value;

        // Get me and set it
        const meObservable = timer(cachedMe ? 5_000 : 0)
            .pipe(
                switchMap(() => this.meService.get(true)),
                catchError((e) => {
                    return this.loginService.logout(undefined, {
                        message: Promise.resolve('Failed to get your information'),
                        httpError: e instanceof HttpErrorResponse ? e : undefined,
                    });
                }),
                tap(async (me) => {
                    if (me) {
                        await this.initializerCacheService.store('default', 'me', me, '');

                        if (cachedMe) {
                            const meStr = JSON.stringify(me);
                            const cachedMeStr = JSON.stringify(cachedMe);

                            if (meStr !== cachedMeStr) {
                                console.error('Me has changed');
                                console.debug('cached: ', '|' + cachedMeStr + '|');
                                console.debug('received: ', '|' + meStr + '|');

                                this.appService.reload();
                            }
                        }
                    }
                }),
                map((me) => {
                    return me ? new Me(me) : undefined;
                }),
            );

        let me: Me | undefined;

        if (cachedMe) {
            me = new Me(cachedMe);
            meObservable.subscribe();
        } else {
            me = await firstValueFrom(meObservable);
        }

        if (!me) {
            return;
        }

        this.current.setMe(me);
        newrelic.setUserId(String(me.user.id));

        const customerId = await this.getRelevantCustomerId(me);
        const userOld = new UserOld(me.user._response);

        if (!environment.isTesting) {
            CurrentOld.setMe(me);
            CurrentOld.setUser(userOld);
            CurrentOld.setCustomers(me._response);
            CurrentOld.store = this.current.store.bind(this.current);
            CurrentOld.retrieve = this.current.retrieve.bind(this.current);
        }

        if (customerId) {
            let customerMe: CustomerMe;
            try {
                customerMe = await firstValueFrom(this.meService.getCustomerMe(customerId));
            } catch (e) {
                console.error(e);
                return this.loginService.logout(me.authedAs, {
                    message: Promise.resolve('Failed to get more information'),
                    httpError: e instanceof HttpErrorResponse ? e : undefined,
                });
            }

            const customer = customerMe.customer;
            const employee = customerMe.employee;
            const tasks = customerMe.tasks;

            // Set customer
            await this.current.setCustomer(me.user.id, customer);
            newrelic.setCustomAttribute('Location id', String(customer.id));
            newrelic.setCustomAttribute('Location name', String(customer.name));

            // Set employee
            if (employee) {
                this.current.setEmployee(employee);
                newrelic.setCustomAttribute('Employee id', String(employee.id));
            } else {
                newrelic.setCustomAttribute('Employee id', false);
            }

            // Add tasks to task service
            this.taskService.tasks = tasks;

            if (!environment.isTesting) {
                // Update old current
                CurrentOld.setCustomer(new CustomerOld(customer._response, userOld));
                CurrentOld.setEmployee(employee?._response);

                // TODO: Remove when CurrentOld.hasProduct is removed
                let products: Product[] = [];
                try {
                    products = (await firstValueFrom(this.customerProductService.getAll(customer.id, { per_page: 100 }))).data;
                    CurrentOld.setProducts(products);
                } catch (e) {
                    console.error(e);
                }
            }
        }

        // At the end set current as loaded
        this.currentLoadedSubject.next(true);
    }
}
