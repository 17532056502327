import moment, { Moment } from 'moment-timezone';
import { Model } from '../../../shared/angularjs/model';
import type MlTimeSeries from './ml-timeseries';

export default class MlTimeSeriesEntry extends Model {
    timeSeriesInterval: any;
    time: Moment;
    unix: number;
    value: any;
    time_series_uuid!: string;

    constructor(mlTimeSeriesEntry: any, mlTimeSeries: MlTimeSeries) {
        super(mlTimeSeriesEntry);

        this.timeSeriesInterval = mlTimeSeries.interval;
        // @ts-ignore
        this.time = moment.utc(this.time, 'YYYY-MM-DD HH:mm:ss');
        this.unix = +this.time;
    }

    get hasValue() {
        return Number.isFinite(this.value);
    }

    get timeSeriesId() {
        return this.time_series_uuid;
    }

    get displayStringBasedOnInterval() {
        return this.time.format(this.timeSeriesInterval >= 86400 ? 'LL' : 'LLL');
    }

    get timeString() {
        return this.time.format('YYYY-MM-DD HH:mm:ss');
    }

    get dateString() {
        return this.time.format('YYYY-MM-DD');
    }
}
