import { Component, Inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { SubheaderComponent } from '../../components/subheader/subheader.component';
import { csvCreator } from '../../utils/csv-creator';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { PageHeaderButton } from '../../components/page-header/classes/page-header-button';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { t } from 'i18next';
import { TranslatePipe } from '../../pipes/translate.pipe';

// A column of data to be used in export
export interface ExportColumn {
    // Header for the column
    header: Promise<string>;
    // All data in the column
    data: unknown[];
    included?: boolean;
}

export interface ExportDialogData extends DialogData {
    items: ExportColumn[];
}

export function createExportHeaderButton<TableItem extends Record<string, any>>(getTable: () => DataTableComponent<TableItem> | undefined) {
    return new PageHeaderButton({
        click: () => getTable()?.exportData(),
        icon: () => 'download',
        menuText: signal(Promise.resolve(t('EXPORT') || 'Export')),
    });
}

@Component({
    selector: 'eaw-export-dialog',
    standalone: true,
    imports: [ CommonModule, DialogHeaderComponent, MatDialogModule, MatButtonModule, MatListModule, SubheaderComponent, MatInputModule, ReactiveFormsModule, TranslatePipe ],
    templateUrl: './export-dialog.component.html',
    styleUrl: './export-dialog.component.scss' ,
})
export class ExportDialogComponent extends DialogComponent {
    name = new FormControl<string | null>('export');

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ExportDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ExportDialogComponent>,
    ) {
        data.size = DialogSize.Small;

        super(dialogRef, data);

        data.items.forEach((i) => {
            i.included ??= true;
        });
    }

    async export() {
        const columns = this.data.items.filter((i) => i.included);
        const headers: string[] = [];
        const data: any[][] = [];

        for await (const column of columns) {
            headers.push(await column.header);
            data.push(this.formatData(column.data));
        }

        // Rotate 2d array
        const output = data[0]?.map((_, colIndex) => data.map((row) => row[colIndex]));

        // Creat
        this.create(headers, output || []);
    }

    private create(headers: string[], data: unknown[][]) {
        csvCreator(headers, data, true, this.name.value || 'export');
    }

    private formatData(data: unknown[]) {
        const formatted: unknown[] = [];

        data.forEach((d) => {
            switch (true) {
                case d instanceof DateTime: {
                    formatted.push((d as DateTime).toFormat('yyyy-MM-dd HH:mm:ss'));
                    break;
                }
                case d == null: {
                    formatted.push('');
                    break;
                }
                default: {
                    formatted.push(d);
                }
            }
        });

        return formatted;
    }
}
