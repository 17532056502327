import { MainMenuEntry } from './main-menu-entry';
import { MainMenuEntryData } from '../interfaces/main-menu-entry-data';

export class MainMenuParentEntry extends MainMenuEntry {
    constructor(data: MainMenuEntryData, customerId: number) {
        super(data, customerId);

        this.subMenu = data.subMenu?.map((entry) => new MainMenuEntry(entry, customerId));
    }

    override updateActiveStatus(name?: string) {
        super.updateActiveStatus(name);
        this.subMenu?.forEach((sub: MainMenuEntry) => sub.updateActiveStatus(name));
    }

    override updateBadgeCount(badges: Map<string, number>) {
        super.updateBadgeCount(badges);
        this.subMenu?.forEach((sub) => sub.updateBadgeCount(badges));
        this.badgeCount.update((count) => count + ((this.subMenu?.reduce((total, sub) => (total + (sub.badgeCount() || 0)), 0)) || 0));
    }
}
