import { PeopleKpiResponseRowField } from '../types';

export class PeopleKpiRowField implements PeopleKpiResponseRowField {
    key: PeopleKpiResponseRowField['key'];
    trend: PeopleKpiResponseRowField['trend'];
    value: PeopleKpiResponseRowField['value'];

    constructor(field: PeopleKpiResponseRowField) {
        this.key = field.key;
        this.trend = field.trend;
        this.value = field.value;
    }
}
