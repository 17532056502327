import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { I18nextLoaderService } from '../../../initializer/services/i18next-loader.service';
import { Language } from '../../../admin/models/language';
import { MatFormFieldModule } from '@angular/material/form-field';
import { sort } from '../../angularjs/modules/misc/services/easy-funcs.service';
import { MatRippleModule } from '@angular/material/core';
import { Storage } from '../../utils/storage';
import { AppService } from '../../services/app.service';
import { UserService } from '../../http/user.service';
import { firstValueFrom } from 'rxjs';
import { Cache } from '../../utils/cache';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlagSvgDirective } from '../../directives/flag-svg.directive';
import { ActionButtonComponent } from '../../components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslatePipe } from '../../pipes/translate.pipe';

export interface ChangeLanguageDialogData extends DialogData {
    /**
     * Id of the authenticated / real user.
     *
     * When passed in the current user's language will be updated, not the user we (maybe) are acting as.
     */
    authId?: number;
    // Use this or else fall back to the current language
    currentLanguageCode?: string;
}

@Component({
    selector: 'eaw-change-language-dialog',
    standalone: true,
    imports: [ CommonModule, DialogHeaderComponent, MatDialogModule, MatFormFieldModule, MatRippleModule, MatProgressSpinnerModule, FlagSvgDirective, ActionButtonComponent, MatButtonModule, TranslatePipe ],
    templateUrl: './change-language-dialog.component.html',
    styleUrl: './change-language-dialog.component.scss' ,
})
export class ChangeLanguageDialogComponent extends DialogComponent<ChangeLanguageDialogData, undefined, ChangeLanguageDialogComponent> {
    languages: Language[] = [];
    selectedLanguage?: Language;
    updating = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: ChangeLanguageDialogData | undefined,
        @Inject(MatDialogRef) dialogRef: MatDialogRef<ChangeLanguageDialogComponent>,
        @Inject(I18nextLoaderService) private i18nextLoaderService: I18nextLoaderService,
        @Inject(AppService) private appService: AppService,
        @Inject(UserService) private userService: UserService,
    ) {
        super(dialogRef, {
            size: DialogSize.Medium,
            ...data,
        });

        this.languages = sort(this.i18nextLoaderService.availableWtiLanguages, navigator.language, [ (x) => x.name, (x) => x.languageTag ]);
        this.selectedLanguage = this.i18nextLoaderService.availableWtiLanguages.find((x) => x.code === this.i18nextLoaderService.currentLanguage.code);
    }

    changeLanguage(language: Language) {
        this.selectedLanguage = language;
    }

    async submit() {
        if (this.selectedLanguage && this.selectedLanguage.code !== this.i18nextLoaderService.currentLanguage.code) {
            this.updating = true;

            await Storage.setLanguage(this.selectedLanguage?.code);

            if (this.data?.authId) {
                await firstValueFrom(this.userService.update(this.data.authId, { language_code: this.selectedLanguage?.code }));
                await Cache.clearAll();
            }

            this.appService.reload();
        }

        this.dialogRef.close();
    }
}
