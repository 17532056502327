import { module } from 'angular';
import { StateProvider } from '@uirouter/angularjs';
import { AdminReportsListComponent } from '../../pages/admin-reports-list/admin-reports-list.component';

module('eaw.admin').config([ '$stateProvider', ($stateProvider: StateProvider) => {
    $stateProvider.state('eaw/app/admin/reports', {
        parent: 'eaw/app/admin',
        url: '/reports',
        views: {
            'content@': {
                component: AdminReportsListComponent
            },
        },
        data: {
            breadcrumb: { key: 'REPORTS', ns: 'navigation' },
            permissions: [ `reports.*.get` ],
        },
    });
} ]);
