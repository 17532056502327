import { Component, inject, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { CurrentService } from '../../../shared/services/current.service';
import { NotificationType } from '../../classes/notification-type';
import { ChangeLocationService } from '../../../shared/services/change-location.service';

@Component({
    selector: 'eaw-notification-redirect',
    template: '',
    standalone: true,
})
export class NotificationRedirectComponent implements OnInit {
    private readonly uiRouter = inject(UIRouter);
    private readonly current = inject(CurrentService);
    private readonly changeLocation = inject(ChangeLocationService);

    ngOnInit() {
        const type: string | undefined = this.uiRouter.globals.params['type'];
        const data: Record<string, any> | undefined = this.uiRouter.globals.params['data'];

        const notification: NotificationType | undefined = NotificationType.get(type ?? '');

        if (!notification) {
            return;
        }

        let route = notification.route;
        let params: Record<string, any> = {};

        if (!route) {
            route = 'eaw/app/home';
        } else {
            params = data ? notification.getParams(data, this.current.getCustomer()?.id) : {};

            const customerId = params['customer'];

            if (customerId && this.current.getCustomer(customerId)) {
                const url = this.uiRouter.stateService.href(route, params);
                this.changeLocation.go(customerId, url);
                return;
            }
        }

        this.uiRouter.stateService.go(route, params);
    }
}
