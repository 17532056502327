// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog>
    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2 ng-bind="isdCtrl.options.title" flex></h2>
            <md-icon class="hover-item" ng-bind="'close'" close-dialog></md-icon>
        </div>
    </md-toolbar>

    <md-subheader ng-if="isdCtrl.options.subheader">{{isdCtrl.options.subheader}}</md-subheader>

    <md-dialog-content ng-if="!isdCtrl.options.items.length">
        <div class="md-dialog-content" layout="column" layout-align="center center">
            <span style="font-size: 18px" ng-bind="isdCtrl.options.emptyText"></span>
        </div>
    </md-dialog-content>

    <md-dialog-content ng-if="isdCtrl.options.items.length">
        <md-list flex>
            <md-list-item ng-if="!isdCtrl.options.subtitleKey"
                          ng-repeat="item in ::isdCtrl.options.items track by $index"
                          ng-disabled="item[isdCtrl.disabledKey]"
                          ng-click="isdCtrl.select(item)">
                <md-icon ng-hide="isdCtrl.options.disableSelecting" eaw-invisible="{{!item[isdCtrl.selectedKey]}}" ng-bind="'done'"></md-icon>
                <p>{{item[isdCtrl.options.displayKey]}}</p>
            </md-list-item>

            <md-list-item ng-if="isdCtrl.options.subtitleKey"
                          class="md-2-line"
                          ng-repeat="item in ::isdCtrl.options.items track by $index"
                          ng-disabled="item[isdCtrl.disabledKey]"
                          ng-click="isdCtrl.select(item)">
                <md-icon ng-hide="isdCtrl.options.disableSelecting" eaw-invisible="{{!item[isdCtrl.selectedKey]}}" ng-bind="'done'"></md-icon>
                <div class="md-list-item-text">
                    <h3 ng-bind="item[isdCtrl.options.displayKey]"></h3>
                    <p ng-bind="item.subtitle"></p>
                </div>
            </md-list-item>
        </md-list>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
        <md-button ng-if="isdCtrl.options.multiSelect" ng-i18next="SUBMIT" ng-click="isdCtrl.submit()"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.dialogs').factory('ItemSelectorDialog', [ '$mdDialog', function ItemSelectorDialogFactory($mdDialog) {
    const fac = this;

    /**
     * Open a dialog and list out sent in items for the user to select one
     * @param {Object}          options
     * @param {String}          options.title - Title of the dialog
     * @param {Object[]}        options.items - Array of items to select from
     * @param {String}          options.displayKey - Object key to display
     * @param {String}          [options.disableSelecting=false] - Wether or not to disable selection of item(s)
     * @param {String|Function} [options.subtitleKey] - Key for text to show as a subtitle, or function that recieves item
     * @param {Boolean}         [options.multiSelect] - Can choose more than one item?
     * @param {String}          [options.subheader] - A string for the subheader
     * @param {String}          [options.emptyText] - A string to show when no items
     * @param {String}          [options.selectedItems] - Pass in the items that we will select on init
     * @returns {Object}        Returns the selected object
     */
    fac.open = (options) => $mdDialog.show({
        template: template1,
        controller: 'ItemSelectorDialogCtrl',
        controllerAs: 'isdCtrl',
        multiple: true,
        bindToController: true,
        locals: {
            options,
        },
    });

    return fac;
} ]);
