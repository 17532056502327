import { Mobile } from './eaw-mobile';

/**
 * Unified storage class for both web and mobile
 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Storage {
    private static storagePrefix = 'x';

    static async getCustomer(userId: number) {
        const id = await this.getItem<number>(`user-${userId}-customer`);
        return Number.isInteger(id) ? id : null;
    }

    // Stores the customer id for the given user id
    static async setCustomer(userId: number, customerId: number) {
        await this.setItem(`user-${userId}-customer`, customerId);
    }

    static async removeCustomer(userId: number) {
        await this.removeItem(`user-${userId}-customer`);
    }

    static prefix(key: string) {
        return `${this.storagePrefix}:${key}`;
    }

    static getPrefix() {
        return this.storagePrefix;
    }

    static setPrefix(prefix: string) {
        this.storagePrefix = prefix;
    }

    static async clear() {
        if (Mobile.isMobile) {
            await Mobile.storageClear();
        }

        void localStorage.clear();
    }

    static async removeItem(key: string) {
        if (Mobile.isMobile) {
            await Mobile.storageRemove(key);
        }

        void localStorage.removeItem(key);
    }

    static async setItem(key: string, value: string | number | boolean | object) {
        async function set(key: string, value: string) {
            if (Mobile.isMobile) {
                await Mobile.storageSet(key, value);
            }

            void localStorage.setItem(key, value);
        }

        // Try to store the value as JSON first, and if that fails, store it as a string if it's a string
        try {
            if (typeof value === 'object' && value !== null) {
                const stringifiedValue = JSON.stringify(value);
                await set(key, stringifiedValue);
            }
        } catch (e) {
            console.error(e);
            return;
        }

        if (typeof value === 'string') {
            await set(key, value);
        }

        if (typeof value === 'number') {
            await set(key, String(value));
        }

        if (typeof value === 'boolean') {
            await set(key, String(+value));
        }

        if (typeof value === 'string') {
            await set(key, value);
        }
    }

    /**
     * Parses a JSON string from localStorage and returns the parsed value as the given type
     * @param key
     */
    static async getItem<Return = string>(key: string) {
        let item: string | null;

        if (Mobile.isMobile) {
            item = await Mobile.storageGet(key);
        } else {
            item = localStorage.getItem(key);
        }

        if (!item) {
            return null;
        }

        // Try parsing in case it's JSON, else just return whatever it is
        try {
            return JSON.parse(item) as Return;
        } catch (_) {
            // Don't show error, it's annoying, and not really an error :)
        }

        return item as unknown as Return;
    }

    static async setLanguage(language: string) {
        await this.setItem('language', language);
    }

    static async getLanguage() {
        return this.getItem('language');
    }
}
