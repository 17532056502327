import { booleanAttribute, ChangeDetectionStrategy, Component, computed, inject, input, model, signal, Signal } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { PageHeaderButton, PageHeaderButtonMenu } from './classes/page-header-button';
import { TranslateService } from '../../services/translate.service';
import { Observable } from 'rxjs';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { PermissionChildrenFilterInInput, PermissionChildrenFilterOutInput, PermissionsInputValue } from '../../../permissions/services/element-permission.service';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';
import { MatMenuModule } from '@angular/material/menu';

export interface HeaderFabButtonMenuItem {
    hasPermission?: () => Observable<boolean>;
    text: Promise<string>;
    click: () => void;
    icon?: string;
}

type PermissionInputs = {
    permissions: PermissionsInputValue;
    somePermissions?: PermissionsInputValue;
    permissionChildrenFilterOut?: PermissionChildrenFilterOutInput;
    permissionChildrenFilterIn?: PermissionChildrenFilterInInput;
};

interface HeaderFabButtonBase {
    icon?: string,
    type?: ThemePalette,
    tooltip?: Promise<string>,
    ariaLabel?: string;
    // In most cases "hasPermission" should be used instead of "hide"
    hide?: () => boolean;
    disabled?: Signal<boolean>;
    hasPermission: () => Observable<boolean>;
    permissionInputs?: PermissionInputs;
}

export interface HeaderFabButtonClick extends HeaderFabButtonBase {
    click: () => void,
    menu?: never;
}

export interface HeaderFabButtonMenu extends HeaderFabButtonBase {
    click?: never;
    menu: HeaderFabButtonMenuItem[];
}

export type HeaderFabButton = HeaderFabButtonClick | HeaderFabButtonMenu;

@Component({
    selector: 'eaw-page-header',
    templateUrl: './page-header.component.html',
    styleUrl: './page-header.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        MatCardModule,
        AsyncPipe,
        PermissionDirective,
    ],
})
export class PageHeaderComponent {
    private translate = inject(TranslateService);

    icon = input<string | undefined>(undefined);
    buttons = input<(PageHeaderButton | PageHeaderButtonMenu)[]>([]);
    fabButton = input<HeaderFabButton | undefined>(undefined);
    useBigFilter = input(false, { transform: booleanAttribute });
    bigFilterToggled = model(false);

    protected computedButtons = computed(this.computeButtons.bind(this));
    protected hasFabButton = signal(false);
    protected checkingFabButton = signal(false);
    protected fabButtonTooltip = computed(() => this.fabButton()?.tooltip || Promise.resolve(''));

    private computeButtons() {
        const filterToggleButton = new PageHeaderButton({
            click: () => this.bigFilterToggled.update((toggled) => !toggled),
            active: () => this.bigFilterToggled(),
            icon: () => this.bigFilterToggled() ? 'filter_alt' : 'filter_alt_off',
            menuText: signal(this.translate.t('FILTER')),
        });

        return this.buttons().concat(this.useBigFilter() ? [ filterToggleButton ] : []);
    }
}
