import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Tag, TagResponse } from '../models/tag';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { TagSet, TagSetResponse } from '../models/tag-set';
import { CommentSet, CommentSetResponse } from '../models/comment-set';
import { Comment, CommentResponse } from '../models/comment';
import { ProjectionDaySet, ProjectionDaySetResponse } from '../models/projection-day-set';
import { ProjectionDayUpdate } from '../models/projection-day';
import { ReferenceDayResponse } from '../models/reference-day';
import { ProjectionDayDetails, ProjectionDayDetailsResponse } from '../models/projection-day-details';
import { formatHttpParams } from '../../shared/utils/format-http-params';

export type ProjectionDayHoliday = {
    date: string,
    customer: number,
    name: string,
    type: 'school' | 'public' | null,
}

@Injectable({
    providedIn: 'root',
})
export class ProjectionsService {
    constructor(@Inject(HttpClient) protected http: HttpClient) { }

    getTags(customerId: number, options: { start: string, end: string}): Observable<TagSet> {
        return this.http.get<TagSetResponse>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/tags`, {
            params: {
                ...options,
            },
        }).pipe(classifyItem(TagSet));
    }

    getTag(customerId: number, date: string): Observable<Tag> {
        return this.http.get<TagResponse>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/${date}/tag`, {
        }).pipe(classifyItem(Tag));
    }

    setDayTag(customerId: number, date: string, options: { tag: string }): Observable<Tag> {
        return this.http.put<TagResponse>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/${date}/tag`, {
            ...options,
        }).pipe(classifyItem(Tag));
    }

    deleteTag(customerId: number, date: string): Observable<null> {
        return this.http.delete<null>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/${date}/tag`);
    }

    getComments(customerId: number, options: { start: string, end: string}): Observable<CommentSet> {
        return this.http.get<CommentSetResponse>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/comments`, {
            params: {
                ...options,
            },
        }).pipe(classifyItem(CommentSet));
    }

    getComment(customerId: number, date: string): Observable<Comment> {
        return this.http.get<CommentResponse>(`/ai-projection-service/1.0.0/customers/${customerId}/comments/${date}/comment`, {
        }).pipe(classifyItem(Comment));
    }

    setDayComment(customerId: number, date: string, options: { comment: string | null }): Observable<Comment> {
        return this.http.put<CommentResponse>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/${date}/comment`, {
            ...options,
        }).pipe(classifyItem(Comment));
    }

    deleteComment(customerId: number, date: string): Observable<null> {
        return this.http.delete<null>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/${date}/comment`);
    }

    getProjections(customerId: number, options: { start: string, end: string}): Observable<ProjectionDaySet> {
        return this.http.get<ProjectionDaySetResponse>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/projections`, {
            params: {
                ...options,
            },
        }).pipe(classifyItem(ProjectionDaySet));
    }

    updateProjection(customerId: number, date: string, payload: ProjectionDayUpdate) {
        return this.http.put<ProjectionDayUpdate>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/${date}/interval/1d/projections`, {
            ...payload,
        }).pipe();
    }

    saveReferenceDays(customerId: number, date: string, payload: string[]) {
        return this.http.put<ProjectionDayUpdate>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/${date}/reference-days`, {
            dates: payload.map((date) => {
                return { day: date };
            }),
        }).pipe();
    }

    searchReferenceDays(customerId: number, limit: number, tag?: string, weekday?: string[]) {
        return this.http.get<{reference_days: ReferenceDayResponse[]}>(`ai-projection-service/1.0.0/customers/${customerId}/reference-days`, {
            params: formatHttpParams({
                tag,
                weekday: weekday?.join(','),
                limit,
            }),
        }).pipe();
    }

    getTimeSlotProjections(customerId: number, date: string): Observable<ProjectionDayDetails> {
        return this.http.get<ProjectionDayDetailsResponse>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/${date}/interval/15m/projections`).pipe(classifyItem(ProjectionDayDetails));
    }

    saveTimeSlotProjections(customerId: number, date: string, payload: ProjectionDayUpdate) {
        return this.http.put<ProjectionDayUpdate>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/${date}/interval/15m/projections`, {
            ...payload,
        }).pipe();
    }

    getHolidays(customerId: number, options: { start: string, end: string}) {
        return this.http.get<{holidays: ProjectionDayHoliday[] | null}>(`/ai-projection-service/1.0.0/customers/${customerId}/dates/holidays`, {
            params: {
                ...options,
            },
        });
    }
}
