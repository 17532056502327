import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CurrentService } from '../services/current.service';
import { NumberFormatterService } from '../services/number-formatter.service';

@Pipe({
    name: 'eawPercent',
    standalone: true,
})
export class PercentPipe implements PipeTransform {
    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
    ) {
    }

    transform(value?: number | string, decimals = 0): string {
        return this.numberFormatterService.format(this.current.languageTag, value, {
            style: 'percent',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        });
    }
}
