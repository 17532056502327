// @ts-nocheck
import { module } from 'angular';
import { EawUrl } from '../../shared/angularjs/modules/resource/url.service';
import moment from 'moment-timezone';
module('eaw.newsletter').service('Newsletter', [ 'EawResource', 'Upload', 'FileService', 'Pagination', function NewsletterClass(EawResource, Upload, FileService, Pagination) {
    class Newsletter {
        id;
        key;
        customer_id;
        language_code;
        default_title;
        default_description;
        languages;
        issues;
        groups;
        constructor(newsletter) {
            this.id = newsletter.id;
            this.key = newsletter.id;
            this.customer_id = newsletter.customer_id;
            this.language_code = newsletter.language_code;
            this.default_title = newsletter.default_title;
            this.default_description = newsletter.default_description;
            this.languages = newsletter.languages;
            this.issues = newsletter.newsletter_issues || [];
            this.groups = newsletter.newsletter_groups?.map((g) => g.group_id);
            this.mapLanguages(this);
            this.issues.forEach(this.mapLanguages.bind(this));
        }

        getMostRecentIssue(includeFuture = false) {
            return this.issues
                .filter((i) => includeFuture ? true : i.publish_date.isBefore(moment()))
                .sort((a, b) => b.publish_date - a.publish_date)[0];
        }

        mapLanguages(item) {
            item.languages = Object.entries(item.languages || {}).map(([ key, value ]) => {
                return {
                    ...value,
                    code: key,
                };
            });
        }

        setLanguage(customer, user, defaultLanguage) {
            Newsletter.setLanguage(this, customer, user, defaultLanguage);
        }

        /**
         * @param {object} item - Either newsletter or issue
         * @param {object} customer
         * @param {object} user
         * @param {string} [languageCode] - Override with this language
         */
        static setLanguage(item, customer, user, languageCode) {
            const languages = new Map(Object.values(item.languages).map((v) => [ v.code.toLowerCase(), v ]));
            let itemLanguage;
            switch (true) {
                case languages.has(languageCode?.toLowerCase()): {
                    itemLanguage = languages.get(languageCode?.toLowerCase());
                    break;
                }
                case languages.has(user.language_code?.toLowerCase()): {
                    itemLanguage = languages.get(user.language_code?.toLowerCase());
                    break;
                }
                case languages.has(customer.language_code?.toLowerCase()): {
                    itemLanguage = languages.get(customer.language_code?.toLowerCase());
                    break;
                }
                case languages.has(item.language_code?.toLowerCase()): {
                    itemLanguage = languages.get(item.language_code?.toLowerCase());
                    break;
                }
            }
            if (itemLanguage) {
                item.viewLanguage = {
                    code: itemLanguage.code,
                    title: itemLanguage.title,
                    description: itemLanguage.description,
                };
            } else {
                item.viewLanguage = {
                    code: item.language_code,
                    title: item.default_title,
                    description: item.default_description,
                };
            }
            item.issues?.forEach((issue) => {
                const issueLang = Object.values(issue.languages).find((il) => (il.code === item.viewLanguage.code));
                if (issueLang) {
                    issue.viewLanguage = {
                        code: issueLang.code,
                        title: issueLang.title,
                        description: issueLang.description,
                        file: issueLang.file,
                    };
                } else {
                    issue.viewLanguage = {
                        code: item.viewLanguage.code,
                        title: item.viewLanguage.title,
                        description: item.viewLanguage.description,
                        file: item.viewLanguage.file,
                    };
                }
            });
        }

        static getIssues(customerId, newsletterKey, args) {
            return EawResource.create(`/customers/${customerId}/newsletters/${newsletterKey}/issues`).get(args).$promise;
        }

        static getIssue(customerId, newsletterKey, issueId) {
            return EawResource.create('/customers/:customer/newsletters/:newsletter/issues/:issue').get({
                customer: customerId,
                newsletter: newsletterKey,
                issue: issueId,
            }).$promise;
        }

        static get(customerId, newsletterKey) {
            return EawResource.create(`/customers/:customer/newsletters/:newsletter`).get({
                customer: customerId,
                newsletter: newsletterKey,
            }).$promise.then((resp) => new Newsletter(resp));
        }

        static getAll(customerId, args) {
            return EawResource.create(`/customers/${customerId}/newsletters`).get({
                ...args,
            }).$promise.then((resp) => {
                resp.data = resp.data.map((newsletter) => new Newsletter(newsletter));
                return resp;
            });
        }

        static getAdmin(customerId, newsletterKey) {
            return EawResource.create('/customers/:customer/newsletters/:newsletter').get({
                customer: customerId,
                newsletter: newsletterKey,
            }).$promise.then((resp) => new Newsletter(resp));
        }

        static getAdminAll(args) {
            return EawResource.create('/customers/:customer/newsletters').get({
                customer: args.customerId,
                ...Pagination.getParams(args),
            }).$promise.then((resp) => {
                resp.data = resp.data.map((newsletter) => new Newsletter(newsletter));
                return resp;
            });
        }

        static create(args) {
            return EawResource.create('/customers/:customer/newsletters').save({
                customer: args.customer_id,
                language_code: args.language_code,
                default_title: args.default_title,
                default_description: args.default_description,
                languages: args.languages,
                customer_groups: args.customer_groups,
            }, null).$promise.then((resp) => new Newsletter(resp));
        }

        static update(args) {
            return EawResource.create('/customers/:customer/newsletters/:newsletter').update({
                customer: args.customer_id,
                newsletter: args.id,
            }, args).$promise.then((resp) => new Newsletter(resp));
        }

        static delete(customer_id, newsletter_id) {
            return EawResource.create('/customers/:customer/newsletters/:newsletter').delete({
                customer: customer_id,
                newsletter: newsletter_id,
            }, null).$promise;
        }

        static createIssue(customerId, newsletterId, args) {
            return Upload.upload({
                url: `${EawUrl.url}/customers/${customerId}/newsletters/${newsletterId}/issue`,
                data: args,
            });
        }

        static updateIssue(customerId, newsletterId, issueId, args) {
            return Upload.upload({
                url: `${EawUrl.url}/customers/${customerId}/newsletters/${newsletterId}/issues/${issueId}`,
                data: args,
            });
        }

        static deleteIssue(customer_id, newsletter_id, issue_id) {
            return EawResource.create('/customers/:customer/newsletters/:newsletter/issues/:issue').delete({
                customer: customer_id,
                newsletter: newsletter_id,
                issue: issue_id,
            }, null).$promise;
        }

        static setIssueLanguage(issue, customer, user, language) {
            Newsletter.setLanguage(issue, customer, user, language);
        }

        static getIssuePDF(issue, customerId, languageCode, url) {
            return Newsletter.getIssueFile(issue, customerId, languageCode, { url });
        }

        static getIssuePreview(issue, customerId, languageCode, lazy) {
            return new Promise((resolve, reject) => {
                Newsletter.getIssueFile(issue, customerId, languageCode, {
                    lazy,
                    preview: true,
                }).then((resp) => {
                    const file = new Blob([ resp.data ], {
                        type: resp.headers()['content-type'],
                    });
                    resolve(URL.createObjectURL(file));
                }).catch(reject);
            });
        }

        /**
         * Gets the file binary data from server
         */
        static getIssueFile(issue, customerId, languageCode, args = {}) {
            const url = `customers/${customerId}/newsletters/${issue.newsletter_id}/issues/${issue.id}/download/${languageCode}`;
            return args.url ? EawResource.create(url).get(args).$promise : FileService.request(`${EawUrl.url}/${url}`, 'get', args);
        }
    }
    return Newsletter;
} ]);
