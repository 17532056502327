import { User, UserResponse } from '../../shared/models/user';
import { Customer, CustomerResponse } from '../../shared/models/customer';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface PeriodLockResponse extends ApiResponse {
    id: number;
    customer_id: number;
    user_id: number;
    to: string;
    created_at: string;
    updated_at: string;
    deleted_at?: string | null;

    user?: UserResponse;
    customer?: CustomerResponse;
}

export class PeriodLock {
    id: number;
    customerId: number;
    userId: number;
    to: DateTime;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt?: DateTime | null;

    user?: User;
    customer?: Customer;

    constructor(data: PeriodLockResponse) {
        this.id = data.id;
        this.customerId = data.customer_id;
        this.userId = data.user_id;
        this.to = stringToDateTime(data.to);
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;

        if (data.customer) {
            this.customer = new Customer(data.customer);
        }

        if (data.user) {
            this.user = new User(data.user);
        }
    }
}
