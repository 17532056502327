import { Component, Inject } from '@angular/core';
import { Customer } from '../../../shared/models/customer';
import { CurrentService } from '../../../shared/services/current.service';
import { firstValueFrom, map, shareReplay, switchMap } from 'rxjs';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { Products } from '../../../shared/enums/products';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { PaidTimeOverviewComponent } from '../../../paid-time/pages/paid-time-overview/paid-time-overview.component';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

@Component({
    selector: 'eaw-chain-paid-time',
    templateUrl: './chain-paid-time.component.html',
    styleUrl: './chain-paid-time.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        PaidTimeOverviewComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ChainPaidTimeComponent {
    customersObservable = expandAllPages((pagination) => this.customerGroupService.getAllForCustomer(this.current.getCustomer().id, pagination), { 'with[]': [ 'products', 'members.products' ] }).pipe(
        switchMap(async (groups) => {
            const permissions = groups.flatMap((group) => {
                return group.members.map((customer) => {
                    return `customers.${customer.id}.employees.*.paid_times.*.get`;
                });
            });

            await firstValueFrom(this.permissionCheckService.isAllowedMany(permissions));

            return groups;
        }),
        map((groups) => {
            const customers = new Map<number, Customer>();

            groups.forEach((group) => {
                const groupHasPaidTime = group.products.some((product) => product.name === Products.PaidTime);

                group.members.forEach((customer) => {
                    const canGetPaidTime = this.permissionCheckService.single(`customers.${customer.id}.employees.*.paid_times.*.get`);
                    const customerHasPaidTime = groupHasPaidTime || customer.products.some((product) => product.name === Products.PaidTime);

                    if (customerHasPaidTime && canGetPaidTime) {
                        customers.set(customer.id, customer);
                    }
                });
            });

            return sort(Array.from(customers.values()), this.current.languageTag, [ (c) => c.name ]);
        }),
        shareReplay(1),
    );

    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(CustomerGroupService) private customerGroupService: CustomerGroupService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
    ) {
    }
}
