// @ts-nocheck
import { module } from 'angular';

module('eaw').directive('eawOnEnter', function() {
    return function(scope, element, attrs) {
        function eawOnEnter(e) {
            if (e.key === 'Enter' || e.which === 13 || e.keyCode === 13) {
                scope.$apply(() => {
                    scope.$eval(attrs.eawOnEnter);
                });

                e.preventDefault();
            }
        }

        element[0].addEventListener('keyup', eawOnEnter);

        scope.$on('$destroy', () => {
            element[0].removeEventListener('keyup', eawOnEnter);
        });
    };
});
