@if (fetching()) {
    <eaw-info-loading class="tw-my-24" size="md" direction="column">
        <span>{{ 'FETCHING_SETTING_plural' | translate | async }}</span>
    </eaw-info-loading>
} @else {
    <mat-form-field>
        <mat-label>{{'INITIAL_SCHEDULE_TAB_LABEL' | translate:'scheduling' | async}}</mat-label>
        <mat-select [formControl]="tabControl">
            <mat-option [value]="''">{{ 'DEFAULT' | translateSync }}</mat-option>

            @for (tab of scheduleTabs(); track tab.name) {
                <mat-option [value]="tab.name">{{ tab.syncLabel }}</mat-option>
            }
        </mat-select>
        <mat-hint>{{'INITIAL_SCHEDULE_TAB_HINT' | translate:'scheduling' | async}}</mat-hint>
    </mat-form-field>
}
