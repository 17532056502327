import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Notification, NotificationResponse } from '../../notifications/models/notification';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {
    }

    getAll(userId: number, unread: boolean, pagination?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<NotificationResponse>>(`users/${userId}/notifications`, {
            params: {
                ...pagination,
                unread,
            },
        }).pipe(classifyArrayPaginatedResponse(Notification));
    }

    markAsRead(userId: number, notificationId: string) {
        return this.http.put<NotificationResponse>(`users/${userId}/notifications/${notificationId}`, {}).pipe(classifyItem(Notification));
    }

    markAllAsRead(userId: number) {
        return this.http.put(`users/${userId}/notifications`, {});
    }
}
