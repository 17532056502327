<table>
    <thead>
    <tr>
        <th class="mat-body-2"> {{ 'FROM' | translate | async }}</th>
        <td class="mat-body-1"> {{ availability.from | DateTime: 'DATE_MED' }}</td>
    </tr>
    <tr>
        <th class="mat-body-2"> {{ 'TO' | translate | async }}</th>
        <td class="mat-body-1"> {{ availability.to | DateTime: 'DATE_MED' }}</td>
    </tr>
    <tr>
        <th class="mat-body-2">{{ 'WANTED_DAYS' | translate: 'availabilities' | async }}</th>
        <td class="mat-body-1">{{ availability.workDays.join(', ') }}</td>
    </tr>
    </thead>

    <tbody>
        @for (week of availability.weeks; track $index; let weekIndex = $index) {
            <tr>
                <td class="mat-body-2 week-cell" colspan="2">{{ 'WEEK_N' | translate:'general':{number: weekIndex + 1} | async }}</td>
            </tr>

            @for (day of week; track day) {
                <tr>
                    <td>{{ day[0]?.date | DateTime: 'WEEKDAY' }}</td>

                    <td>
                        @for (interval of day; track interval.id) {
                            @if (interval.wholeDay) {
                                <div> {{ 'ALL_DAY' | translate: 'availabilities' | async }}</div>
                            } @else if (interval.from && interval.to) {
                                <div> {{ interval.from | DateTime: 'TIME_SIMPLE' }} - {{ interval.to | DateTime: 'TIME_SIMPLE' }}</div>
                            } @else () {
                                <div> {{ 'OFF' | translate: 'availabilities' | async }}</div>
                            }
                        }
                    </td>
                </tr>
            }
        }
    </tbody>
</table>
