import { module } from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { MiniWeatherWidgetComponent } from '../../../../dashboard-new/components/mini-weather-widget/mini-weather-widget.component';
import { Widget } from '../../../../dashboard-new/classes/widget';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
import { Customer } from '../../../../shared/models/customer';
import { OldClassAsResponse } from '../../../../shared/utils/old-class-as-response';
import { WeatherWidgetExtendedComponent } from '../../../../dashboard-new/components/mini-weather-widget/weather-widget-extended/weather-widget-extended.component';

module('eaw.dashboard').directive('eawWeatherWidgetExtended', downgradeComponent({ component: WeatherWidgetExtendedComponent }));
module('eaw.dashboard').directive('eawWeatherWidget', downgradeComponent({ component: MiniWeatherWidgetComponent }));

module('eaw.dashboard').component('weatherForecast', {
    template: `<eaw-weather-widget-extended [widget]="$ctrl.widget"></eaw-weather-widget-extended>`,
    bindings: {
        widget: '<',
    },
});

module('eaw.dashboard').component('weatherWidget', {
    template: `<eaw-weather-widget [widget]="$ctrl.widget" (loaded)="$ctrl.loaded()" [dark-background]="$ctrl.darkBackground"></eaw-weather-widget>`,
    require: {
        mini: '^eawMiniWidget',
    },
    controller: [ function() {
        // @ts-ignore
        const ctrl = this;

        ctrl.hasError = false;

        ctrl.$onInit = async () => {
            ctrl.widget = new Widget(ctrl.mini.widget.propertyResponse, {
                customer: new Customer(OldClassAsResponse(CurrentOld.getCustomer())),
            } as any);
            ctrl.mini.loading(true);
        };

        ctrl.loaded = () => {
            ctrl.addForecastTab();
            ctrl.mini.loading(false);
            ctrl.darkBackground = ctrl.mini.el.style.color === 'white';
        };

        ctrl.addForecastTab = () => {
            ctrl.mini.dialogComponents = [
                {
                    name: 'weatherForecast',
                    label: 'widgets:WEATHER_FORECAST',
                    bindings: {
                        widget: ctrl.widget,
                    },
                },
            ];
        };
    } ],
});
