import { Shift } from 'src/app/scheduling/models/shift';
import { uniqueId } from 'lodash-es';

/**
 * Represents a temporary shift that is not yet saved to the database.
 */
export class TempShift extends Shift {
    constructor(offset: number, length: number) {
        super({
            // Temporary shifts have negative IDs to indicate that they are not yet saved to the database.
            id: parseInt(uniqueId()) * -1,
            length,
            net_length: length,
            offset,
            schedule_id: 0,
            from: '',
            to: '',
            created_at: '',
            updated_at: '',
        });
    }
}
