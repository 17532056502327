import { module } from 'angular';
import { Namespace } from '../../shared/enums/namespace';
import { createState } from '../../shared/utils/create-state';
import { CustomerProductService } from '../../shared/http/customer-product.service';
import CustomerOld from '../../shared/angularjs/customer-old';
import { NewslettersComponent } from '../pages/newsletters/newsletters.component';
import { Products } from '../../shared/enums/products';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { NewsletterIssuesComponent } from '../pages/newsletter-issues/newsletter-issues.component';
import { paramResolver } from '../../shared/resolvers/param.resolver';

module('eaw.newsletter').config([ '$stateProvider', function newsletterRoute($stateProvider: any) {
    createState($stateProvider, {
        parent: 'eaw/app',
        name: 'eaw/app/newsletters',
        url: '/newsletters',
        views: {
            'content@': {
                component: NewslettersComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            products: [ Products.Newsletter ],
            breadcrumb: { key: 'NEWSLETTER_plural', ns: 'newsletter' },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/newsletters/issues',
        parent: 'eaw/app/newsletters',
        url: '/:newsletter_id/issues',
        views: {
            'content@': {
                component: NewsletterIssuesComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            paramResolver('newsletter_id', 'newsletterId'),
        ],
        data: {
            breadcrumb: { key: 'ISSUE_plural', ns: 'newsletter' },
        },
    });

    $stateProvider.state('eaw/app/chain_ops/newsletters', {
        parent: 'eaw/app/chain_ops',
        url: '/newsletters',
        views: {
            'content@': 'manageNewsletters',
        },
        data: {
            products: [ Products.Newsletter ],
            i18nextNs: [ Namespace.Newsletter ],
            permissions: [ `customers.{customer}.newsletters.*.manage` ],
            breadcrumb: { key: 'NEWSLETTER_plural', ns: 'newsletter' },
        },
    });
    $stateProvider.state('eaw/app/chain_ops/newsletter/update', {
        parent: 'eaw/app/chain_ops/newsletters',
        url: '/:key',
        views: {
            'content@': 'eawNavBar',
        },
        resolve: {
            navbarRoute: () => `eaw/app/chain_ops/newsletter/update`,
            viewName: () => 'tabPane',
            newsletter: [ 'customer', 'user', 'Newsletter', '$transition$', function(customer: any, user: any, Newsletter: any, $transition$: any) {
                return Newsletter.getAdmin(customer.id, $transition$.params().key).then((newsletter: any) => {
                    newsletter.setLanguage(customer, user);
                    return newsletter;
                });
            } ],
            customer: [ 'CustomerService', 'user', 'customer', function(CustomerService: any, user: any, customer: any) {
                return CustomerService.get({
                    id: customer.id,
                    with: [ 'customerGroups' ],
                }).$promise.then((cust: any) => {
                    return new CustomerOld(cust, user);
                });
            } ],
            languages: [ 'LanguageService', function(LanguageService: any) {
                return LanguageService.getAll().$promise.then((resp: any) => resp.data);
            } ],
            tabs: function() {
                return [
                    {
                        src: 'eaw/app/chain_ops/newsletter/update/issues',
                        label: 'newsletter:ISSUES',
                    },
                    {
                        src: 'eaw/app/chain_ops/newsletter/update/settings',
                        label: 'SETTING_plural',
                    },
                ];
            },
        },
        data: {
            breadcrumb: { key: 'NEWSLETTER_TITLE', ns: 'newsletter' },
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/newsletter/update/issues`, {
        parent: `eaw/app/chain_ops/newsletter/update`,
        url: '/issues',
        views: { tabPane: 'manageNewsletterIssuesTab' },
    });
    $stateProvider.state(`eaw/app/chain_ops/newsletter/update/settings`, {
        parent: `eaw/app/chain_ops/newsletter/update`,
        url: '/settings',
        views: { tabPane: 'manageNewsletterSettingsTab' },
    });
    $stateProvider.state('eaw/app/chain_ops/newsletter/update/issues/create', {
        parent: 'eaw/app/chain_ops/newsletter/update/issues',
        url: '/create',
        views: {
            'content@': 'editIssue',
        },
        resolve: {
            languages: [ 'LanguageService', function(LanguageService: any) {
                return LanguageService.getAll().$promise.then((resp: any) => resp.data);
            } ],
            newsletter: [ 'customer', 'user', 'Newsletter', '$transition$', function(customer: any, user: any, Newsletter: any, $transition$: any) {
                return Newsletter.getAdmin(customer.id, $transition$.params().key).then((newsletter: any) => {
                    newsletter.setLanguage(customer, user);
                    return newsletter;
                });
            } ],
        },
        data: {
            breadcrumb: { key: 'CREATE_ISSUE', ns: 'newsletter' },
        },
    });
    $stateProvider.state('eaw/app/chain_ops/newsletter/update/issues/update', {
        parent: 'eaw/app/chain_ops/newsletter/update/issues',
        url: '/:issue_id',
        views: {
            'content@': 'editIssue',
        },
        resolve: {
            languages: [ 'LanguageService', function(LanguageService: any) {
                return LanguageService.getAll().$promise.then((resp: any) => resp.data);
            } ],
            newsletter: [ 'customer', 'user', 'Newsletter', '$transition$', function(customer: any, user: any, Newsletter: any, $transition$: any) {
                return Newsletter.getAdmin(customer.id, $transition$.params().key).then((newsletter: any) => {
                    newsletter.setLanguage(customer, user);
                    return newsletter;
                });
            } ],
            issue: [ 'Newsletter', '$transition$', 'customer', 'user', function(Newsletter: any, $transition$: any, customer: any, user: any) {
                return Newsletter.getIssue(customer.id, $transition$.params().key, $transition$.params().issue_id).then((issue: any) => {
                    Newsletter.setIssueLanguage(issue, customer, user);
                    return issue;
                });
            } ],
        },
        data: {
            breadcrumb: { key: 'ISSUE_TITLE', ns: 'newsletter' },
        },
    });
} ]);
