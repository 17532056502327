import { orderBy } from 'lodash-es';
import { debounce } from 'lodash-es';

import { module } from 'angular';
import { WebsocketService } from '../../../../../shared/services/websocket.service';
import { AngularJsDestroyRef } from '../../../../../shared/angularjs/angularjs-destroy-ref.class';

const template1 = `<md-card>
    <md-card-content>
        <warnings-list warnings="$tab.warnings"></warnings-list>
    </md-card-content>
</md-card>
`;

module('eaw.scheduling').component('scheduleWarningTab', {
    template: template1,
    bindings: {
        schedule: '<',
    },
    controllerAs: '$tab',
    controller: [ 'WebsocketDowngrade', '$scope', 'shiftEvents', function(WebsocketDowngrade: WebsocketService, $scope, shiftEvents) {
        // @ts-ignore
        const ctrl = this;
        const destroyRef = new AngularJsDestroyRef();

        ctrl.$onInit = () => {
            ctrl.reload();

            shiftEvents.register.onChange($scope, ctrl.reload);
            shiftEvents.register.onLoaded($scope, ctrl.reload);

            const callback = debounce(ctrl.reload, 500);

            WebsocketDowngrade.listenSchedule(ctrl.schedule.customer_id, ctrl.schedule.id, 'warning_added', callback, destroyRef);
            WebsocketDowngrade.listenSchedule(ctrl.schedule.customer_id, ctrl.schedule.id, 'warnings_cleared', callback, destroyRef);
        }

        ctrl.$onDestroy = () => {
            destroyRef.destroy();
        };

        ctrl.reload = () => {
            ctrl.warnings = orderBy(ctrl.schedule.getWarnings(), (w) => +w.business_date);
        };
    } ],
});
