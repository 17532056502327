// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog id="file-download-dialog" class="mini-dialog">
    <eaw-dialog-header-old heading="'DOWNLOAD' | i18next"></eaw-dialog-header-old>

    <md-dialog-content layout="row" layout-align="center center">
        <md-icon md-svg-src="{{fileDownCtrl.file.mime.icon}}" md-colors="{color: fileDownCtrl.file.mime.color}"></md-icon>

        <table class="table">
            <tr>
                <td ng-i18next="NAME"></td>
                <td ng-bind="fileDownCtrl.truncatedName"></td>
            </tr>
            <tr>
                <td ng-i18next="filesystem:TYPE"></td>
                <td ng-bind="fileDownCtrl.file.mime.type"></td>
            </tr>
            <tr>
                <td ng-i18next="filesystem:FILE_SIZE"></td>
                <td ng-bind="fileDownCtrl.file.size.defaultFormat"></td>
            </tr>
        </table>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>

        <md-button class="md-raised md-accent" ng-show="fileDownCtrl.isMobile" ng-bind="fileDownCtrl.statusText" ng-click="fileDownCtrl.download()" ng-disabled="fileDownCtrl.gettingUrl"></md-button>

        <md-button class="md-raised md-accent" ng-show="!fileDownCtrl.isMobile" ng-disabled="fileDownCtrl.gettingUrl">
            <a href="{{fileDownCtrl.url}}" download target="_blank" ng-bind="fileDownCtrl.statusText"></a>
        </md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.dialogs').factory('FileDownloadFactory', [ '$mdDialog', function($mdDialog) {
    const fac = this;

    fac.open = (file) => $mdDialog.show({
        template: template1,
        controllerAs: 'fileDownCtrl',
        controller: 'fileDownloadDialogCtrl',
        bindToController: true,
        locals: {
            file,
        },
    });

    return fac;
} ]);
