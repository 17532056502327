import { EawFormSubmissionData } from './interfaces';
import { EawForm } from './form';
import { Nullable } from '../../shared/types/nullable';
import { DateTime } from 'luxon';

/**
 * @deprecated
 * @see FormSubmission
 */
export class EawFormSubmission implements EawFormSubmissionData {
    id!: number;
    anonymous: boolean = false;
    data: any = {};
    form?: EawForm;
    form_id?: number;
    recipients: number[] = [];
    user?: { first_name: string; last_name: string; email: string };
    user_id?: number | null;
    created_at: Nullable<DateTime> = null;
    deleted_at: Nullable<DateTime> = null;
    updated_at: Nullable<DateTime> = null;

    constructor(data?: EawFormSubmissionData) {
        if (!data) {
            return;
        }

        Object.assign(this, data);
    }
}
