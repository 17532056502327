import { inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { DateTime } from 'luxon';
import { DateTimeConverter } from '../../shared/utils/date-time-converter';
import { map, Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { Shift, ShiftResponse } from '../models/shift';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { ShiftChangeType } from '../models/shift-change';
import { HttpClient, HttpContext } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { IGNORE_ERROR } from '../../shared/http/http-contexts';
import { BusinessDate } from '../../shared/utils/business-date';
import { CustomFieldKeyValues, CustomFieldGroupValue } from '../../shared/utils/custom-fields-group';

interface GetAllOptions extends PaginationOptions {
    from?: DateTime | null,
    to?: DateTime | null,
    changed?: boolean;
    'changes[]'?: ShiftChangeType[];
    vacant?: boolean;
    published?: boolean;
    employee_id?: number;
}

interface GetAllForEmployeeOptions extends PaginationOptions {
    from?: DateTime,
    to?: DateTime,
    fromBusinessDate?: BusinessDate,
    toBusinessDate?: BusinessDate,
    ignoreErrors?: boolean | [number];
}

interface GetOptions {
    changelog?: boolean;
    with?: string[];
    count?: string[];
}

interface CreateOptions {
    employee_id?: number | null;
    offset: number;
    length: number;
    business_date: BusinessDate;
    qualifications?: number[];
    group_id?: number;
    comment?: string;
    with?: string[];
}

interface UpdateOptions {
    employee_id?: number | null;
    offset?: number;
    length?: number;
    business_date?: BusinessDate;
    qualifications?: number[];
    with?: string[];
}

@Injectable({
    providedIn: 'any',
})
export class ShiftService {
    private http = inject(HttpClient);

    get(customerId: number, shiftId: number, options?: GetOptions) {
        return this.http.get<ShiftResponse>(`customers/${customerId}/shifts/${shiftId}`, {
            params: formatHttpParams({
                changelog: options?.changelog,
                'with[]': options?.with,
                'count[]': options?.count,
            }),
        }).pipe(classifyItem(Shift));
    }

    getAll(customerId: number, options?: GetAllOptions, ignoreError: boolean | number[] = false): Observable<ArrayPaginatedResponse<Shift>> {
        const context = new HttpContext();
        if (ignoreError) {
            if (Array.isArray(ignoreError)) {
                context.set(IGNORE_ERROR, ignoreError);
            } else {
                context.set(IGNORE_ERROR, true);
            }
        }
        return this.http.get<ArrayPaginatedResponse<ShiftResponse>>(`customers/${customerId}/shifts`, {
            params: formatHttpParams({
                ...options,
                from: DateTimeConverter.convertDateTimeToUtcString(options?.from),
                to: DateTimeConverter.convertDateTimeToUtcString(options?.to),
            }),
            context,
        }).pipe(classifyArrayPaginatedResponse(Shift));
    }

    getAllForEmployee(customerId: number, employeeId: number, options?: GetAllForEmployeeOptions): Observable<ArrayPaginatedResponse<Shift>> {
        const params = {
            ...options,
            from_business_date: options?.fromBusinessDate,
            to_business_date: options?.toBusinessDate,
            from: options?.from,
            to: options?.to,
        };

        delete params.ignoreErrors;
        const context = new HttpContext();
        if (options?.ignoreErrors) {
            context.set(IGNORE_ERROR, true);
        }

        return this.http.get<ArrayPaginatedResponse<ShiftResponse>>(`customers/${customerId}/employees/${employeeId}/shifts`, {
            params: formatHttpParams(params, [ 'from_business_date', 'to_business_date' ]),
            context,
        }).pipe(classifyArrayPaginatedResponse(Shift));
    }

    create(customerId: number, scheduleId: number, options: CreateOptions, customFields?: CustomFieldKeyValues, context?: HttpContext) {
        return this.http.post<ShiftResponse>(`customers/${customerId}/schedules/${scheduleId}/shifts`, {
            ...options,
            ...customFields,
        }, {
            context,
        }).pipe(classifyItem(Shift));
    }

    update(customerId: number, scheduleId: number, shiftId: number, options: UpdateOptions, customFields?: Record<string, CustomFieldGroupValue>, context?: HttpContext) {
        return this.http.put<ShiftResponse>(`customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}`, {
            ...options,
            ...customFields,
        }, {
            context,
        }).pipe(classifyItem(Shift));
    }

    delete(customerId: number, scheduleId: number, shiftId: number, context?: HttpContext) {
        return this.http.delete(`customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}`, { context });
    }

    copy(customerId: number, scheduleId: number, shiftId: number, offsets: number[]) {
        return this.http.post<ShiftResponse[]>(`customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/copy`, {
            shifts: offsets.map((offset) => ({ offset })),
        }).pipe(
            map((shifts) => shifts.map((shift) => new Shift(shift))),
        );
    }
}
