<eaw-dialog-header>
    <span title *ngIf="balanceType">{{balanceType.nameTranslation.key | translate:balanceType.nameTranslation.ns | async}}</span>
    <span subtitle>{{data.employeeName}}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-p-0" *ngIf="balanceType">
            <form [formGroup]="editForm" class="tw-flex tw-flex-col tw-gap-8 tw-p-16">
                <mat-form-field eawDatePickerOptions>
                    <mat-label>{{'DATE' | translate | async}}</mat-label>
                    <input matInput formControlName="date" [matDatepicker]="toPicker">
                    <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                    <mat-datepicker #toPicker></mat-datepicker>
                    <mat-hint *ngIf="editForm.controls.date.value">{{editForm.controls.date.value.toRelativeCalendar()}}</mat-hint>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'CHANGE' | translate: 'availabilities' | async }}</mat-label>
                    <input matInput type="number" inputmode="numeric" formControlName="change" step="0.01">
                    <span matTextSuffix>{{getTextSuffix()}}</span>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'DESCRIPTION' | translate | async}}</mat-label>
                    <textarea formControlName="description" matInput></textarea>
                </mat-form-field>
            </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="">{{'CLOSE' | translate | async}}</button>
    <button mat-raised-button color="primary" (click)="addChange()" [disabled]="editForm.invalid || editForm.disabled">{{'ADD_BALANCE' | translate:'balances' | async}}</button>
</mat-dialog-actions>
