// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.absence').factory('AbsenceFactory', [ 'EawResource', function(EawResource) {
    const fac = this;
    fac.getStats = {
        permission(customerId, employeeId) {
            return [
                `customers.${customerId}.absence_types.*.get`,
                `customers.${customerId}.employees.${employeeId}.absences.*.get`,
                `customers.${customerId}.employees.${employeeId}.off_times.*.get`,
            ].every((x) => CurrentOld.can(x));
        },
        query(args) {
            return EawResource.create('/customers/:customer/employees/:employee/absences/statistics').get({
                customer: args.customerId,
                employee: args.employeeId,
                from: args.from,
                to: args.to,
                type_id: args.absenceTypeId,
            });
        },
    };
    return fac;
} ]);
