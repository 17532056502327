import { Component, Inject, Input, signal, ViewChild } from '@angular/core';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataTablePagination, DataTableRequest } from '../../../data-table/types/data-table';
import { debounceTime, distinctUntilChanged, of } from 'rxjs';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { ExternalEmployee } from '../../models/external-employee';
import { TranslateService } from '../../../shared/services/translate.service';
import { ExternalEmployeeService } from '../../../shared/http/external-employee.service';
import { DataTableProfilePictureColumn } from '../../../data-table/types/data-table-profile-picture-column';
import { DateTime } from 'luxon';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { CustomerService } from '../../../shared/http/customer.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { MatDialog } from '@angular/material/dialog';
import { EditExternalEmployeeDialogComponent, EditExternalEmployeeDialogData } from '../../dialogs/edit-external-employee-dialog/edit-external-employee-dialog.component';
import { AlertDialogComponent, AlertDialogData } from '../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { Namespace } from '../../../shared/enums/namespace';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-external-employees-list',
    templateUrl: './external-employees-list.component.html',
    styleUrl: './external-employees-list.component.scss',
    providers: [
        {
            provide: 'newExternalEmployeeDialog',
            useFactory: ($injector: any) => $injector.get('newExternalEmployeeDialog'),
            deps: [ '$injector' ],
        },
    ],
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ExternalEmployeesListComponent {
    @ViewChild('table') table?: DataTableComponent<ExternalEmployee>;

    @Input({ required: true }) customerId!: number;

    fabButton: HeaderFabButton = {
        click: this.newExternal.bind(this),
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.external_employees.create`),
    };

    filterToggled = true;
    includeInactive = false;
    headerButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            icon: () => this.filterToggled ? 'filter_alt' : 'filter_alt_off',
            active: () => this.filterToggled,
            click: () => this.filterToggled = !this.filterToggled,
            menuText: signal(this.translateService.t('FILTER')),
        }),
        new PageHeaderButton({
            icon: 'history',
            active: () => this.includeInactive,
            click: () => {
                this.includeInactive = !this.includeInactive;
                this.table?.refresh();
            },
            menuText: signal(this.translateService.t('INCLUDE_INACTIVE')),
        }),
    ];

    filter = new FormControl<string | null>('');
    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<ExternalEmployee>[] = [
        new DataTableProfilePictureColumn({
            user: (cell) => cell.item.employee?.user,
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.employee?.name || '',
            header: new DataTableHeader({ i18n: 'EMPLOYEE', sortBy: 'employee_id' }),
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.employee?.customer?.name,
            header: new DataTableHeader({ i18n: 'EMPLOYED_AT', ns: 'company', sortBy: 'customer_id' }),
        }),
        new DataTableTextColumn({
            value: this.costDisplayer.bind(this),
            header: new DataTableHeader({ i18n: 'COST', ns: 'company', sortBy: 'cost' }),
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.employee?.number.toString(),
            header: new DataTableHeader({ i18n: 'NUMBER', ns: 'company' }),
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.number?.toString(),
            header: new DataTableHeader({ i18n: 'EXTERNAL_EMP_NUMBER', ns: 'company' }),
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.employee?.email || '',
            header: new DataTableHeader({ i18n: 'EMAIL' }),
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.employee?.phone || '',
            header: new DataTableHeader({ i18n: 'PHONE' }),
        }),
        new DataTableTextColumn({
            value: this.ageDisplayer.bind(this),
            header: new DataTableHeader({ i18n: 'AGE' }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            format: DateTime.DATETIME_MED,
            header: new DataTableHeader({ i18n: 'FROM', sortBy: 'from' }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            format: DateTime.DATETIME_MED,
            header: new DataTableHeader({ i18n: 'TO', sortBy: 'to' }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'edit',
                    tooltip: { key: 'EDIT' },
                    click: this.editExternal.bind(this),
                    show: (employee) => this.permissionCheckService.isAllowed(`customers.${employee.customerId}.external_employees.${employee.id}.update`),
                },
            ],
        }),
    ];

    constructor(
        @Inject('newExternalEmployeeDialog') private newExternalEmployeeDialog: any,
        @Inject(ExternalEmployeeService) private externalEmployeeService: ExternalEmployeeService,
        @Inject(TranslateService) private translateService: TranslateService,
        @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
        @Inject(CustomerService) private customerService: CustomerService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(MatDialog) private matDialog: MatDialog,
    ) {
        this.filter.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
        ).subscribe(() => {
            this.table?.refresh();
        });
    }

    ageDisplayer(cell: DataTableCell<DataTableTextColumn<ExternalEmployee>, ExternalEmployee>) {
        const birthDate = cell.item.employee?.birthDate?.dateTime;
        return birthDate ? this.numberFormatterService.formatUnit(Math.floor(DateTime.now().diff(birthDate, 'seconds').as('years')), 'year', {
            maximumFractionDigits: 0,
        }) : '';
    }

    costDisplayer(cell: DataTableCell<DataTableTextColumn<ExternalEmployee>, ExternalEmployee>) {
        return this.numberFormatterService.formatCurrency(cell.item.cost);
    }

    editExternal(cell: DataTableCell<DataTableButtonColumn<ExternalEmployee>, ExternalEmployee>) {
        this.matDialog.open<EditExternalEmployeeDialogComponent, EditExternalEmployeeDialogData, ExternalEmployee>(EditExternalEmployeeDialogComponent, {
            data: {
                customerId: this.customerId,
                externalEmployee: of(cell.item),
            },
        }).afterClosed().subscribe((employee) => {
            if (employee) {
                this.table?.refresh();
            } else {
                cell.disabled.set(false);
            }
        });
    }

    showExternalError(key: string) {
        this.matDialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
            data: {
                title: signal(this.translateService.t('EXTERNAL_EMPLOYEE')),
                text: signal(this.translateService.t(key, Namespace.Company)),
            },
        });
    }

    newExternal() {
        this.customerService.get(this.customerId).subscribe((customer) => {
            this.newExternalEmployeeDialog.open(customer).then(() => {
                void this.snackBarService.t('X_ADDED', 'general', { name: '$t(company:EXTERNAL_EMPLOYEE)' });
                this.table?.refresh();
            }).catch((code: string) => {
                switch (code) {
                    case 'external':
                        this.showExternalError('EXTERNAL_EMPLOYEE_CREATE_ERROR');
                        break;
                    case 'user':
                        this.showExternalError('EXTERNAL_EMPLOYEE_USER_CREATE_ERROR');
                        break;
                    case 'employee':
                        this.showExternalError('EXTERNAL_EMPLOYEE_EMPLOYEE_UPDATE_ERROR');
                        break;
                    case 'access':
                        this.showExternalError('EXTERNAL_EMPLOYEE_USER_ACCESS_ERROR');
                        break;
                    case 'cancel':
                    default:
                }
            });
        });
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.externalEmployeeService.getAll(this.customerId, {
            ...pagination,
            filter: this.filter.value || undefined,
            'with[]': [ 'employee.customer', 'employee.user' ],
            include_inactive: this.includeInactive,
        });
    }

    rowClasses(row: ExternalEmployee) {
        const classes: string[] = [];
        if (!row.isActive) {
            classes.push('inactive');
        }
        return classes;
    }
}
