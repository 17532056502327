import { module } from 'angular';
import { TinyColor } from '@ctrl/tinycolor';
import { overlaps } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { t } from 'i18next';

module('eaw.scheduling').controller('absenceTooltipCtrl', [ '$filter', function absenceTooltipCtrl($filter) {
    // @ts-ignore
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.absences = ctrl.empPaidTime.absences.filter((a: any) => overlaps(a.from, a.to, ctrl.date.clone().startOf('d'), ctrl.date.clone().endOf('d'))).map((a: any) => {
            a.type.textColor = new TinyColor(a.type.color || '#fff').isDark() ? 'white' : 'black';
            const format = a.from.clone().startOf('d').isSame(a.from, 's') && a.to.clone().endOf('d').isSame(a.to, 's') ? 'll' : 'llll';
            if (a.type.gradable) {
                a._tooltipText = t('payroll:ABS_GRADABLE_FROM_TO', {
                    grade: $filter('percent')(a.grade),
                    from: a.from.format(format),
                    to: a.to.format(format),
                });
            } else {
                a._tooltipText = t('FROM_TO', {
                    from: a.from.format(format),
                    to: a.to.format(format),
                });
            }
            return a;
        });
    };
} ]);
