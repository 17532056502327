// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.staffing').filter('fromToText', [ '$filter', ($filter) => function(interval) {
    if (typeof interval?.from === 'number' && typeof interval?.to === 'number') {
        return `${$filter('eawNumber')(interval.from)} - ${$filter('eawNumber')(interval.to)}`;
    }

    return t('ERROR');
} ]);
