// @ts-nocheck
import { module } from 'angular';
import { EawUrl } from '../../shared/angularjs/modules/resource/url.service';

module('eaw.sso').factory('ssoTokenFactory', [ '$http', function($http) {
    const factory = this;

    /**
     *
     * @param {integer} [customer]
     * @param {integer} [service]
     * @return {*}
     */
    factory.post = (customer, service) => $http.post(`${EawUrl.url}/sso/${customer}`, { sso_service_id: service });

    return factory;
} ]);
