<eaw-dialog-header>
    <span title>{{'NEW_PIN_CODE' | translate: 'profile' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" class="tw-flex tw-flex-col">
        <mat-form-field>
            <mat-label>{{'PIN_CODE' | translate: 'profile' | async}}</mat-label>
            <input matInput type="text" formControlName="pinCode"/>
        </mat-form-field>

        <ul class="rules">
            <li [class.invalid]="form.controls.pinCode.hasError('pinCodelength')">
                <mat-icon>{{form.controls.pinCode.hasError('pinCodelength') ? 'close' : 'check'}}</mat-icon>
                {{'EXACT_LENGTH_CHARS_plural' | translate:'general':{count: pinCodelength} | async}}
            </li>
            <li [class.invalid]="form.controls.pinCode.hasError('minLetters')">
                <mat-icon>{{form.controls.pinCode.hasError('minLetters') ? 'close' : 'check'}}</mat-icon>
                {{'MIN_LENGTH_LETTERS' | translate:'general':{count: minLetters} | async}}
            </li>
            <li [class.invalid]="form.controls.pinCode.hasError('minDigits')">
                <mat-icon>{{form.controls.pinCode.hasError('minDigits') ? 'close' : 'check'}}</mat-icon>
                {{'MIN_LENGTH_DIGITS' | translate:'general':{count: minDigits} | async}}
            </li>
            <li [class.invalid]="form.controls.pinCode.hasError('lowerAlphaAndNumericOnly')">
                <mat-icon>{{form.controls.pinCode.hasError('lowerAlphaAndNumericOnly') ? 'close' : 'check'}}</mat-icon>
                {{'LOWERCASE_OR_NUMERIC_CHARACTERS_ONLY' | translate:'general' | async}}
            </li>
        </ul>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close [matDialogClose]="false">{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="false" [disabled]="form.invalid" (click)="save()">{{'SAVE_NEW_PIN_CODE' | translate: 'profile' | async}}</eaw-action-button>
</mat-dialog-actions>
