@if (saving() || updating() || deleting()) {
    <div class="status-overlay">
        <eaw-info-loading size="xs">
            @if (updating()) {
                {{ 'UPDATING_SHIFT' | translate:'scheduling' | async }}
            } @else if (deleting()) {
                {{ 'DELETING_SHIFT' | translate:'scheduling' | async }}
            } @else if (saving()) {
                {{ 'SAVING_SHIFT' | translate:'scheduling' | async }}
            }
        </eaw-info-loading>
    </div>
}

<div class="periods-container">
    @for (period of periodItems(); track period.period.id) {
        <div class="period"
             [class.unproductive]="period.period.unproductive"
             [class.dark]="period.isDark"
             [class.light]="period.isLight"
             [style.background-color]="period.backgroundColor"
             [style.z-index]="period.index"
             [style.width]="period.width"
             [style.left]="period.left">
        </div>
    }
</div>

<div class="content">
    @if (expanded()) {
        <eaw-profile-picture [user]="user()" size="30"/>
    }

    <span class="name">{{ displayedName() || ('UNASSIGNED' | translate:'scheduling' | async) }}</span>

    <span class="duration tw-flex tw-gap-8">
        <span>{{ shift().length | duration:['hours', 'minutes'] }}</span>

        @if (expanded()) {
            <div class="from-to tw-flex">
                <span>{{ shift().from | DateTime:'TIME_SIMPLE' }}</span>
                <span class="tw-mx-4">-</span>
                <span>{{ shift().to | DateTime:'TIME_SIMPLE' }}</span>
            </div>
        }
    </span>
</div>


@if (warningsCount()) {
    <div class="indicator warning-indicator"></div>
}

@if (commentsCount()) {
    <div class="indicator comment-indicator"></div>
}
