 <eaw-page-header>
    <span title>{{ 'METRIC_plural' | translate: 'people_kpi' | async }}</span>
</eaw-page-header>

<mat-card class="tw-grid l:tw-grid-cols-2 tw-gap-8">
    <mat-progress-spinner mode="indeterminate" diameter="60" strokeWidth="4" color="primary" *ngIf="loading" class="tw-m-16"></mat-progress-spinner>

    <div>
        <form>
        <div *ngFor="let metric of this.orgMetric">
            <mat-checkbox [formControl]="metric.form" eawCheckboxHelper>{{ metric.metric.name.key | translate: metric.metric.name.ns | async }}</mat-checkbox>
        </div>
        </form>
    </div>

    <mat-dialog-actions>
        <button class="tw-m-12" mat-raised-button (click)="save()" color="accent">{{ 'SAVE' | translate | async }}</button>
    </mat-dialog-actions>
</mat-card>
