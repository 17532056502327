<eaw-page-header [fabButton]="fabButton">
    <span title>
        {{ 'REPORT_plural' | translate: 'admin' | async }}
    </span>
</eaw-page-header>
<mat-card>
    <eaw-data-table
            [request]="request"
            [columns]="columns"
            [initialSortBy]="'id'"
            initialSortDirection="asc"
            (paginationChange)="updateTable($event)"
    />
</mat-card>
