import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'company-settings-pay-types-upgraded',
    standalone: true
})
export class companySettingsPayTypesUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('companySettingsPayTypes', elementRef, injector);
    }
}

@Component({
    template: '<company-settings-pay-types-upgraded [customer]="customer"></company-settings-pay-types-upgraded>',
    standalone: true,
    imports: [companySettingsPayTypesUpgradedDirective],
})
export class companySettingsPayTypesUpgradedComponent {
    @Input() customer!: any;
}

@NgModule({
    imports: [companySettingsPayTypesUpgradedDirective,
        companySettingsPayTypesUpgradedComponent],
})
export class companySettingsPayTypesUpgradeModule {
}
