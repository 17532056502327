// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { DateTimeConverter } from '../../../../../utils/date-time-converter';
module('eaw.time').controller('datePickerDialogCtrl', [ 'date', 'options', 'mdPanelRef', function(date, options, mdPanelRef) {
    const ctrl = this;
    ctrl.$onInit = () => {
        ctrl.date = date?.toDate();
        ctrl.heading = options?.heading;
        ctrl.minDate = options?.minDate?.toDate();
        ctrl.maxDate = options?.maxDate?.toDate();
        ctrl.dateFilter = options?.dateFilter;
        ctrl.mode = options?.mode;
    };
    ctrl.onChange = () => {
        let result = ctrl.date instanceof Date ? moment([ ctrl.date.getFullYear(), ctrl.date.getMonth(), ctrl.date.getDate() ]) : undefined;
        if (options?.businessDate) {
            result = DateTimeConverter.convertMomentToBusinessDate(result);
        }
        options.callback?.(result);
        mdPanelRef.close();
    };
} ]);
