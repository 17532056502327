// @ts-nocheck
import { t } from 'i18next';
import { uniqueId } from '../../misc/services/easy-funcs.service';
import moment from 'moment-timezone';
import { module } from 'angular';
import { CurrentOld } from '../../../current.factory';

module('eaw.employees').component('employeeAutocomplete', {
    template: `<md-autocomplete
        class="error-spacer"
        md-selected-item="$empAutocomplete.employee"
        md-search-text="employeeFilter"
        md-selected-item-change="$empAutocomplete.select()"
        md-min-length="0"
        md-delay="300"
        md-no-cache="true"
        md-input-name="$empAutocomplete.inputName"
        ng-disabled="$empAutocomplete.disabled"
        md-floating-label="{{$empAutocomplete.label}}"
        md-clear-button="$empAutocomplete.clearBtn && !$empAutocomplete.disabled"
        ng-required="$empAutocomplete.required"
        md-menu-class="autocomplete-custom-template employee-autocomplete"
        md-items="emp in $empAutocomplete.searchEmployees(employeeFilter)"
        md-item-text="emp.name">
    <md-item-template>
        <div class="autocomplete-item-title" ng-class="{'inactive': emp.inactive}">
           <div layout="row" layout-align="start center">
               <eaw-profile-picture-old user="emp.user" class="tw-mr-12"></eaw-profile-picture-old>

               <div layout="column">
                   <span md-highlight-text="employeeFilter" md-highlight-flags="gi">{{emp.name}}</span>
                   <div class="autocomplete-item-metadata">
                       <div ng-if="!emp.isExternal($empAutocomplete.customerId)">#{{emp.number}}</div>
                       <div ng-if="emp.isExternal($empAutocomplete.customerId)">#{{emp.number}} — {{emp.customer.name}}</div>
                   </div>
               </div>
           </div>
        </div>
    </md-item-template>
    <md-not-found>
        <span ng-i18next="NO_MATCH_FOUND"></span>
    </md-not-found>
    <small class="autocomplete-hint" ng-i18next="THREE_CHARS_FOR_FILTER"></small>
</md-autocomplete>
`,
    bindings: {
        customerId: '<?',
        // either use active or from-to
        active: '<?', // moment or "now"
        from: '<?',
        to: '<?',
        clearBtn: '<?',
        includeExternal: '<?', // boolean
        label: '<?',
    },
    require: {
        ngModel: 'ngModel',
    },
    controllerAs: '$empAutocomplete',
    controller: [ 'EmployeeService', '$attrs', function(EmployeeService, $attrs) {
        const ctrl = this;
        const cache = {
            promise: null,
            params: null,
            options: null,
        };

        ctrl.$onInit = () => {
            ctrl.customerId = ctrl.customerId || CurrentOld.customer.id;
            ctrl.clearBtn = ctrl.clearBtn ?? true;
            ctrl.label = ctrl.label || t('EMPLOYEE');
            ctrl.inputName = uniqueId('employeeAutocomplete');
            ctrl.active = ctrl.active === 'now' ? moment() : ctrl.active;
            ctrl.ngModel.$formatters.unshift(ctrl.setEmployee);

            $attrs.$observe('required', (value) => {
                ctrl.required = value;
            });

            $attrs.$observe('disabled', (value) => {
                ctrl.disabled = value;
            });
        };

        /**
         * @param {Employee|Number} employee
         * @return {Promise<*>}
         */
        ctrl.setEmployee = async (employee) => {
            if (Number.isFinite(employee)) {
                ctrl.employee = await EmployeeService.get({ id: ctrl.customerId }, employee);
            } else {
                ctrl.employee = employee;
            }

            return ctrl.employee;
        };

        /**
         * @param {string} [filter]
         * @returns {*}
         */
        ctrl.searchEmployees = (filter = '') => {
            const withs = [ 'user' ];

            if (ctrl.includeExternal) {
                withs.push('customer');
            }

            const options = {
                customer_id: ctrl.customerId,
                filter: filter.length >= 3 ? filter : undefined,
                active: ctrl.active,
                from: ctrl.from,
                to: ctrl.to,
                fields: [ 'name' ],
                order_by: 'name',
                with: withs,
                per_page: 20,
                direction: 'asc',
                include_external: ctrl.includeExternal,
            };

            const stringOpts = JSON.stringify(options);

            if (cache.promise && stringOpts === cache.options) {
                return cache.promise;
            }

            cache.options = stringOpts;
            cache.promise = EmployeeService.getAll(options).$promise.then((resp) => resp.data);

            return cache.promise;
        };

        ctrl.select = () => {
            ctrl.ngModel.setViewValue(ctrl.employee);
        };
    } ],
});
