import { Component, DestroyRef, Inject, Input, OnInit, signal, viewChild } from '@angular/core';
import { ReportService } from '../../http/report.service';
import { Report } from '../../models/report';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { map, Observable, of, Subject, tap } from 'rxjs';
import { RunService } from '../../http/run.service';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { ReportRun } from '../../models/report-run';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { RunReportComponent } from '../../dialogs/run-report/run-report.component';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { DownloadReportComponent, DownloadReportRunDialogData, DownloadReportRunDialogReturn } from '../../dialogs/download-report/download-report.component';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { WebsocketService } from '../../../shared/services/websocket.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { DateTime } from 'luxon';
import { isNamespace, NamespaceFile } from '../../../shared/enums/namespace';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

export interface ReportRunDoneSocketResponse {
    error: string,
    report: { id: number, class: string, name: string },
    run: number,
}

@Component({
    selector: 'eaw-reports-page',
    templateUrl: './reports-page.component.html',
    styleUrls: [ './reports-page.component.scss' ],
    standalone: true,
    imports: [
        MatCardModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        NgIf,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        NgFor,
        AsyncPipe,
        PageHeaderComponent,
        DataTableComponent,
        TranslatePipe,
    ],
})
export class ReportsPageComponent implements OnInit, EawDataTable {
    table = viewChild(DataTableComponent<ReportRun>);
    matDrawer = viewChild.required(MatDrawer);

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) stackId!: number;

    reportChanged = new Subject<true>();
    fetchingReports = true;
    tableResponse: ReportRun[] = [];
    reportFilter = new FormControl('');
    filteredReports: Observable<Report[]> = of([]);
    drawerMode: 'over' | 'side' = 'over';
    drawerOpened = false;
    fabButton?: HeaderFabButton;
    headerButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            icon: 'menu_open',
            click: () => this.matDrawer().toggle(),
            menuText: signal(this.translate.t('OPEN_MENU')),
        }),
    ];

    reports: Report[] = [];
    report?: Report;
    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<ReportRun>[] = [
        new DataTableTextColumn({
            header: new DataTableHeader({
                i18n: 'READY_AT',
                ns: 'reports',
                sortBy: 'ready_at',
            }),
            value: (cell) => cell.item.readyAt?.toLocaleString(DateTime.DATETIME_MED) || '',
            loading: (cell) => !cell.item.readyAt,
        }),
        new DataTableTextColumn({
            header: new DataTableHeader({
                i18n: 'FORMAT',
                ns: 'reports',
                sortBy: 'format',
            }),
            value: async (cell) => {
                const value = this.report?.formats[cell.item.format] || '';
                if (!value) {
                    return value;
                }

                const [ ns, k ] = value.split('.');

                if (k && isNamespace(ns)) {
                    return this.translate.t(k, ns as NamespaceFile);
                } else {
                    return this.translate.t(value);
                }

            },
        }),
        new DataTableDateTimeColumn({
            header: new DataTableHeader({
                i18n: 'FROM',
                sortBy: 'from',
            }),
            value: 'from',
        }),
        new DataTableDateTimeColumn({
            header: new DataTableHeader({
                i18n: 'TO',
                sortBy: 'to',
            }),
            value: 'to',
        }),
        new DataTableTextColumn({
            header: new DataTableHeader({
                i18n: 'STARTED_BY',
                ns: 'reports',
            }),
            value: (cell) => cell.item.user?.name || '',
        }),
        new DataTableTextColumn({
            header: new DataTableHeader({
                i18n: 'STARTED',
                ns: 'reports',
                sortBy: 'created_at',
            }),
            value: (cell) => cell.item.createdAt?.toRelative() || '',
        }),
        new DataTableButtonColumn<ReportRun>({
            buttons: [
                {
                    click: this.openDownloadDialog.bind(this),
                    icon: 'download',
                    tooltip: { key: 'DOWNLOAD' },
                    show: (run) => {
                        return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].reports.[${ApiModel.Report}].runs.[${ApiModel.ReportRun}].get`, {
                            stackId: this.stackId,
                            models: [
                                { type: ApiModel.Customer, id: this.customerId },
                                { type: ApiModel.Report, id: run.reportId },
                                { type: ApiModel.ReportRun, id: run.id },
                            ],
                        });
                    },
                    nonBlocking: true,
                },
                {
                    click: this.deleteRun.bind(this),
                    hide: (run) => of(!run.readyAt),
                    icon: 'delete',
                    type: 'warn',
                    tooltip: { key: 'DELETE' },
                    show: (run) => {
                        return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].reports.[${ApiModel.Report}].runs.[${ApiModel.ReportRun}].delete`, {
                            stackId: this.stackId,
                            models: [
                                { type: ApiModel.Customer, id: this.customerId },
                                { type: ApiModel.Report, id: run.reportId },
                                { type: ApiModel.ReportRun, id: run.id },
                            ],
                        });
                    },
                },
            ],
        }),
    ];

    constructor(
        @Inject(BreakpointObserver) breakpointObserver: BreakpointObserver,
        @Inject(ReportService) private reportService: ReportService,
        @Inject(RunService) private runService: RunService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(QueryParamsService) private searchParams: QueryParamsService,
        @Inject(DestroyRef) private destroyRef: DestroyRef,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(ConfirmDialogService) private confirmDialog: ConfirmDialogService,
        @Inject(WebsocketService) private websocketService: WebsocketService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
    ) {
        breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
        ]).subscribe((result) => {
            this.drawerMode = result.matches ? 'over' : 'side';
            this.drawerOpened = this.drawerMode === 'side';
        });

        this.filteredReports = this.reportFilter.valueChanges.pipe(
            map((result) => {
                const filter = (result || '').toLowerCase();
                return this.reports.filter((r) => r.name.toLowerCase().includes(filter) || r.description.toLowerCase().includes(filter));
            }),
        );
    }

    ngOnInit(): void {
        expandAllPages((pagination) => this.reportService.getAll(this.customerId, pagination), { per_page: 50, order_by: 'name', direction: 'asc' }).subscribe((reports) => {
            this.reports = reports.reduce((acc, report) => report ? acc.concat(report) : acc, [] as Report[]);
            this.reportFilter.setValue(''); // Trigger list update
            this.setSearchReport();
            this.fetchingReports = false;
        });
    }

    setSearchReport() {
        const report = this.reports.find((r) => r.id === this.searchParams.get('report', 'number'));

        if (report) {
            this.chooseReport(report);
        }
    }

    deleteRun(cell: DataTableCell<DataTableButtonColumn<ReportRun>, ReportRun>) {
        this.confirmDialog.open({
            title: this.translate.t('DELETE'),
            text: this.translate.t('DELETE_REPORT_TEXT', 'reports'),
            confirmText: this.translate.t('DELETE'),
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.runService.delete(this.customerId, cell.item.reportId, cell.item.id).subscribe(() => this.table()?.refresh());
        });
    }

    openDownloadDialog(cell: DataTableCell<DataTableButtonColumn<ReportRun>, ReportRun>) {
        if (!this.report) {
            return;
        }

        this.dialog.open<DownloadReportComponent, DownloadReportRunDialogData, DownloadReportRunDialogReturn>(DownloadReportComponent, {
            data: {
                report: this.report,
                run: cell.item,
            },
        }).afterClosed().subscribe((result) => {
            if (result === 're-run') {
                this.table()?.refresh();
            }
        });
    }

    newRun() {
        if (!this.report) {
            return;
        }

        this.dialog.open<RunReportComponent, { report: Report }, 'run'>(RunReportComponent, {
            data: {
                report: this.report,
            },
        }).afterClosed().subscribe((result) => {
            if (result !== 'run') {
                return;
            }

            this.table()?.refresh();
        });
    }

    chooseReport(report: Report) {
        if (report.id === this.report?.id) {
            return;
        }

        if (this.report?.id) {
            this.reportChanged.next(true);
        }

        this.fabButton = {
            click: this.newRun.bind(this),
            hasPermission: () => {
                if (!this.report) {
                    return of(false);
                }

                return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].reports.[${ApiModel.Report}].runs.create`, {
                    stackId: this.stackId,
                    models: [
                        { type: ApiModel.Customer, id: this.customerId },
                        { type: ApiModel.Report, id: this.report.id },
                    ],
                });
            },
        };

        this.report = report;
        this.searchParams.set([ {
            key: 'report',
            value: report.id,
        } ]);

        this.matDrawer().toggle(this.drawerMode === 'side');

        this.websocketService.listenReport(this.customerId, this.report.id, 'report_run_done', () => this.table()?.refresh(), this.destroyRef, { customDestroy: this.reportChanged });

        this.table()?.refresh();
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        if (!this.report?.id) {
            return;
        }

        this.request = this.runService.getAll(this.customerId, this.report.id, {
            ...pagination,
            'with[]': [ 'user' ],
        }).pipe(
            tap((response) => {
                this.tableResponse = response.data;
            }),
        );
    }
}
