import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CreateScheduleTemplateDialogData } from '../create-template-dialog.service';
import { ScheduleTemplateService } from '../../../http/schedule-template.service';
import { TranslateService } from '../../../../shared/services/translate.service';
import { SettingService } from '../../../../shared/http/setting.service';
import { ScheduleTemplate } from '../../../models/schedule-template';
import { DialogComponent } from '../../../../shared/dialogs/dialog-component';
import { catchError, EMPTY, switchMap } from 'rxjs';
import { DateTime } from 'luxon';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../../shared/directives/date-picker-options.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslateSyncPipe } from '../../../../shared/pipes/translate-sync.pipe';

@Component({
    selector: 'eaw-switzerland-template-create',
    templateUrl: './switzerland-template-create.component.html',
    styleUrl: './switzerland-template-create.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatSelectModule,
        MatOptionModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class SwitzerlandTemplateCreateComponent extends DialogComponent {
    formGroup = new FormGroup({
        name: new FormControl<string | undefined>(undefined, Validators.required),
        date: new FormControl<DateTime | undefined>(undefined, Validators.required),
        duration: new FormControl<number | undefined>(undefined, Validators.required),
        type: new FormControl<'days' | 'weeks'>('days'),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateScheduleTemplateDialogData,
        @Inject(ScheduleTemplateService) public scheduleTemplateService: ScheduleTemplateService,
        @Inject(TranslateService) public translate: TranslateService,
        @Inject(SettingService) public settingService: SettingService,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<SwitzerlandTemplateCreateComponent, ScheduleTemplate>,
    ) {
        super(dialogRef, data);
    }

    create() {
        const duration = this.formGroup.get('duration')?.value;
        if (duration == null) {
            return;
        }

        this.settingService.getValue([ 'customers', this.data.customerId ], 'schedule:hours_offset', 0)
            .pipe(
                switchMap((offset) => {
                    return this.scheduleTemplateService.createDefaultTemplate(this.data.customerId, {
                        from: (this.formGroup.get('date')?.value as DateTime).set({ hour: offset }),
                        name: this.formGroup.get('name')?.value as string,
                        length: this.formGroup.get('type')?.value === 'days' ? duration * 86_400 : duration * 86_400 * 7,
                    });
                }),
                catchError((err) => {
                    console.error(err);
                    this.formGroup.enable();
                    return EMPTY;
                }),
            )
            .subscribe((result) => {
                this.dialogRef.close(result);
            });

        this.formGroup.disable();
    }
}
