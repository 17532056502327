// @ts-nocheck
import { module } from 'angular';

module('eaw.misc').directive('eawInvisible', function eawInvisible() {
    return {
        restrict: 'A',
        link($scope, $element, $attrs) {
            $attrs.$observe('eawInvisible', (val) => {
                if (val === 'true') {
                    $element[0].classList.add('invisible');
                } else {
                    $element[0].classList.remove('invisible');
                }
            });
        },
    };
});
