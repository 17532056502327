import { module } from 'angular';

module('eaw.components').component('eawFormData', {
    template: `<div id="eaw-form-data-container" ng-bind-html="$formData.html"></div>
`,
    bindings: {
        html: '<',
    },
    require: {
        ngModel: 'ngModel', // The model will be undefined as long as the form is invalid
    },
    controllerAs: '$formData',
    controller: [ '$element', '$sce', function($element, $sce) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.element = $element[0];
            ctrl.html = $sce.trustAsHtml(ctrl.html);

            // What to observe
            ctrl.formContainer = document.getElementById('eaw-form-data-container');

            // Observer instance
            ctrl.observer = new MutationObserver(() => {
                ctrl.forms = Array.from(ctrl.element.querySelectorAll('form'));

                if (ctrl.forms.length === 1) {
                    ctrl.form = ctrl.forms[0];
                    ctrl.addListeners();
                } else {
                    console.error(`Found ${ctrl.forms.length} forms!`);
                }
            });

            // Start observing
            ctrl.observer.observe(ctrl.formContainer, {
                childList: true,
            });
        };

        ctrl.addListeners = () => {
            ctrl.eventListeners = [
                'keydown',
                'keyup',
                'click',
                'blur',
                'focus',
            ];

            ctrl.eventListeners.forEach((event: string) => {
                $element[0].addEventListener(event, ctrl.onChange);
            });
        };

        ctrl.onChange = () => {
            if (ctrl.form.checkValidity()) {
                const formData = new FormData(ctrl.form);
                ctrl.formData = {};

                // @ts-ignore
                Array.from(formData.entries()).forEach((entry) => {
                    // @ts-ignore
                    const [ key, value ] = entry;
                    ctrl.formData[key] = value;
                });
            } else {
                ctrl.formData = undefined;
            }

            ctrl.ngModel.setViewValue(ctrl.formData);
        };

        ctrl.$onDestroy = () => {
            ctrl.eventListeners.forEach((event: string) => {
                $element[0].removeEventListener(event, ctrl.onChange);
            });
        };
    } ],
});
