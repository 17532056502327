import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'employee-vacation-upgraded',
    standalone: true
})
export class employeeVacationUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;
    @Input() employee!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('employeeVacation', elementRef, injector);
    }
}

@Component({
    template: '<employee-vacation-upgraded [customer]="customer" [employee]="employee"></employee-vacation-upgraded>',
    standalone: true,
    imports: [employeeVacationUpgradedDirective],
})
export class employeeVacationUpgradedComponent {
    @Input() customer!: any;
    @Input() employee!: any;
}

@NgModule({
    imports: [employeeVacationUpgradedDirective,
        employeeVacationUpgradedComponent],
})
export class employeeVacationUpgradeModule {
}
