import { Component, DestroyRef, inject, Input, viewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { MatCard } from '@angular/material/card';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { UserService } from '../../../shared/http/user.service';
import { MatDialog } from '@angular/material/dialog';
import { DataTablePagination, DataTableRequest } from '../../../data-table/types/data-table';
import { filter, of } from 'rxjs';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserCustomer } from '../../../shared/models/user-customer';
import { AdminUserCustomersCustomerDialogComponent,
    AdminUserCustomersCustomerDialogData } from '../../dialogs/admin-user-customers-customer-dialog/admin-user-customers-customer-dialog.component';

@Component({
    selector: 'eaw-admin-user-customers',
    standalone: true,
    imports: [
        AsyncPipe,
        DataTableComponent,
        MatCard,
        PageHeaderComponent,
        TranslatePipe,
    ],
    templateUrl: './admin-user-customers.component.html',
    styleUrl: './admin-user-customers.component.scss',
})
export class AdminUserCustomersComponent {
    private userService = inject(UserService);
    private destroyedRef = inject(DestroyRef);
    private matDialog = inject(MatDialog);

    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    fabButton: HeaderFabButton = {
        click: this.addToCustomer.bind(this),
        hasPermission: () => of(true),
    };

    table = viewChild.required(DataTableComponent);

    columns: DataTableColumnType<UserCustomer>[] = [];
    @Input() userId!: number;

    constructor() {
        this.columns = [
            new DataTableTextColumn({
                value: 'name',
                header: new DataTableHeader({
                    i18n: 'NAME',
                    sortBy: 'name',
                }),
            }),
            new DataTableDateTimeColumn({
                format: DateTime.DATETIME_MED,
                value: (cell) => cell.item.pivot?.from,
                header: new DataTableHeader({
                    i18n: 'FROM',
                }),
            }),
            new DataTableDateTimeColumn({
                format: DateTime.DATETIME_MED,
                value: (cell) => cell.item.pivot?.to,
                header: new DataTableHeader({
                    i18n: 'TO',
                }),
            }),
        ];
    }

    addToCustomer(): void {
        this.matDialog.open<AdminUserCustomersCustomerDialogComponent, AdminUserCustomersCustomerDialogData>(AdminUserCustomersCustomerDialogComponent, {
            data: {
                userId: this.userId,
            },
        }).afterClosed()
            .pipe(takeUntilDestroyed(this.destroyedRef),
                filter((data) => !!data))
            .subscribe(() => this.table().refresh());
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.userService.getCustomerForUser(this.userId, {
            ...pagination,
        });
    }
}
