import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';

@Component({
    selector: 'eaw-new-employee-next-step',
    standalone: true,
    imports: [ CommonModule, MatListModule, MatButtonModule, TranslatePipe ],
    templateUrl: './new-employee-next-step.component.html',
    styleUrl: './new-employee-next-step.component.scss',
})
/**
 * Provides a button to go to the next step in the stepper if the step is optional **OR** not optional **AND** completed.
 */
export class NewEmployeeNextStepComponent {
    @Input({ required: true }) stepper!: MatStepper;
    @Input({ required: true }) step!: MatStep;
}
