// @ts-nocheck

import { t } from 'i18next';
import moment from 'moment-timezone';
import { module, noop } from 'angular';
import { Comment } from '../../../../shared/models/comment';
import { of } from 'rxjs';
import { Warning } from '../../../../shared/models/warning';
import { csvCreator } from '../../../../shared/utils/csv-creator';
import { PaginationOld } from '../../../../shared/utils/pagination-old';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
import { CommentDialogComponent, CommentDialogData } from '../../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { MatDialog } from '@angular/material/dialog';

module('eaw.payroll.timepunches').component('myTimepunches', {
    template: `<md-card id="my-tps-filter-card" ng-show="$timepunches.showFilter">
    <md-card-content>
        <form name="myTpFilter" ng-submit="$timepunches.update()">
            <div class="tw-flex tw-gap-8 tw-flex-col md:tw-flex-row">
                <md-input-container class="md:tw-w-1/4">
                    <label ng-i18next="chainops:SHOW_ALL_LOCATIONS"></label>
                    <md-select ng-model="$timepunches.includeExternal">
                        <md-option ng-value="true">
                            <span ng-bind="'YES' | eawTranslate"></span>
                        </md-option>

                        <md-option ng-value="false">
                            <span ng-bind="'NO' | eawTranslate"></span>
                        </md-option>
                    </md-select>
                </md-input-container>

                <eaw-date-interval class="tw-flex tw-flex-col md:tw-flex-row" ng-model="$timepunches.filterInterval"></eaw-date-interval>

                <md-input-container class="md:tw-w-1/4" ng-if="$timepunches.businessUnits.length > 1">
                    <label ng-i18next="BUSINESS_UNIT_plural"></label>
                    <md-select multiple ng-model="$timepunches.selectedUnits">
                        <md-option ng-repeat="unit in $timepunches.businessUnits" ng-value="unit.id">{{unit.name}}</md-option>
                    </md-select>
                </md-input-container>
            </div>

            <div layout="row" layout-align="start">
                <eaw-submit-btn form="$timepunches.form"></eaw-submit-btn>
                <md-button class="md-raised" ng-i18next="RESET" ng-click="$timepunches.reset()"></md-button>
            </div>
        </form>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-i18next="payroll:TIMEPUNCH_plural"></span>
            <span class="md-subhead" ng-if="$timepunches.totalLength" ng-bind="$timepunches.totalLength | eawDuration"></span>
        </md-card-header-text>

        <md-button class="md-icon-button" ng-click="$timepunches.exportCsv()">
            <md-icon ng-bind="'download'"></md-icon>
        </md-button>

        <eaw-toggle-btn ng-model="$timepunches.withTrashed"
                        ng-change="$timepunches.update()"
                        icon="restore_from_trash"
                        i18n-tooltip="INCLUDE_DELETED">
        </eaw-toggle-btn>

        <eaw-toggle-btn ng-model="$timepunches.showFilter"
                        icon="filter_alt"
                        toggled-initially="true"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>

        <card-btn-float ng-if="$timepunches.canCreate" on-click="$timepunches.new()"></card-btn-float>
    </md-card-header>

    <md-card-content>
        <eaw-dynamic-ng-table
                columns="$timepunches.columns"
                cells="$timepunches.cells"
                reload-table="$timepunches.reloadTable"
                row-class="$timepunches.rowClass(item)"
                table-row-click="$timepunches.history(item)"
                get-data="$timepunches.getData(pagination)">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
        employee: '<',
        businessUnits: '<',
    },
    controllerAs: '$timepunches',
    controller: [ 'TimepunchFactory', 'TimepunchServiceDowngraded', 'TimepunchHistoryDialogService', 'MatDialogDowngrade', 'WarningsDialogService', 'ManageTimepunchDialogServiceDowngraded', 'ToastService', '$mdDialog', 'CustomerSettings', function(TimepunchFactory, TimepunchServiceDowngraded, TimepunchHistoryDialogService, MatDialogDowngrade: MatDialog, WarningsDialogService, ManageTimepunchDialogServiceDowngraded, ToastService, $mdDialog, CustomerSettings) {
        // @ts-ignore
        const ctrl = this;
        ctrl.$onInit = async () => {
            ctrl.includeExternal = false;
            ctrl.businessUnits = ctrl.businessUnits.concat([
                {
                    id: null,
                    name: t('NONE'),
                },
            ]);
            ctrl.selectedUnits = [];
            ctrl.filterInterval = {
                from: moment().subtract(1, 'w'),
                to: moment(),
            };
            ctrl.canUpdate = CurrentOld.can(`customers.${ctrl.customer.id}.employees.${ctrl.employee.id}.timepunches.*.update`);
            ctrl.canCreate = CurrentOld.can(`customers.${ctrl.customer.id}.employees.${ctrl.employee.id}.timepunches.create`);
            ctrl.deleteCommentRequired = await CustomerSettings.get(ctrl.customer.id, 'payroll.require_comment_on_timepunch_delete')
                .then((setting) => !!setting['payroll.require_comment_on_timepunch_delete'])
                .catch(() => false);

            ctrl.columns = [
                {
                    title: t('DATE'),
                    sortable: 'in',
                },
                { title: t('LOCATION') },
                {
                    title: t('FROM'),
                    sortable: 'in',
                },
                {
                    title: t('TO'),
                    sortable: 'out',
                },
                {
                    title: t('BUSINESS_DATE'),
                    sortable: 'business_date',
                },
                { title: t('payroll:TIME_EDITED') },
                {
                    title: t('BUSINESS_UNIT'),
                    show: !!ctrl.businessUnits.length,
                },
                { title: t('DAY') },
                {
                    title: t('LENGTH'),
                    class: 'text-right',
                },
                {
                    title: t('APPROVED'),
                    sortable: 'approved',
                    class: 'text-center',
                },
                {},
            ];
            ctrl.cells = [
                { template: 'item.in | moment:"LL"' },
                { template: 'item.customer.name' },
                { template: 'item.in | moment:"LT"' },
                { template: 'item.out | moment:"LT"' },
                { template: 'item.business_date | moment:"LL"' },
                { template: 'item.time_edited ? ("YES" | i18next) : ("NO" | i18next)' },
                { template: 'item.business_unit.name' },
                { template: 'item.in | moment:"dddd"' },
                {
                    template: 'item.length | eawDuration',
                    class: 'text-right',
                },
                { approval: {} },
                {
                    buttons: [
                        {
                            type: 'accent',
                            icon: 'edit',
                            tooltip: 'EDIT',
                            invisible(punch) {
                                return punch.deleted_at || !CurrentOld.can(`customers.${ctrl.customer.id}.employees.${punch.employee_id}.timepunches.${punch.id}.update`);
                            },
                            displayNone(punch) {
                                return this.invisible(punch) && !ctrl.canUpdate;
                            },
                            click: ctrl.edit,
                        },
                        {
                            type: 'warn',
                            icon: 'alert',
                            tooltip: 'WARNING_plural',
                            invisible(punch) {
                                return !punch?.warnings?.length;
                            },
                            click: function(punch) {
                                const warnings = punch.warnings.map((w) => new Warning(w));
                                WarningsDialogService.open(of(warnings));
                            },
                        },
                        {
                            type: 'warn',
                            icon: 'delete',
                            invisible(punch) {
                                return punch.deleted_at || !CurrentOld.can(`customers.${ctrl.customer.id}.employees.${punch.employee_id}.timepunches.${punch.id}.delete`);
                            },
                            displayNone(punch) {
                                return this.invisible(punch) && !ctrl.canDelete;
                            },
                            click(punch) {
                                const dialog = $mdDialog
                                    .prompt()
                                    .theme('delete')
                                    .ok(t('DELETE'))
                                    .cancel(t('CANCEL'))
                                    .title(t('DELETE'))
                                    .textContent(t('DELETE_QUESTION'))
                                    .placeholder(ctrl.deleteCommentRequired ? (t('COMMENT') + '*') : t('COMMENT_OPTIONAL'))
                                    .required(ctrl.deleteCommentRequired);
                                $mdDialog.show(dialog).then((comment) => TimepunchFactory.deleteForEmployee({ ...punch, comment }).$promise.then(() => {
                                    ToastService.deleted();
                                    ctrl.update();
                                }), noop);
                            },
                        },
                        {
                            icon: 'comment',
                            tooltip: 'COMMENT_plural',
                            invisible(punch) {
                                return !punch.comments.length;
                            },
                            click: ctrl.showComments,
                        },
                        {
                            icon: 'information',
                            tooltip: 'INFORMATION',
                            click: function(punch) {
                                TimepunchHistoryDialogService.open(punch.customer_id, punch.employee_id, punch.id);
                            },
                        },
                    ],
                },
            ];
        };
        ctrl.edit = (punch) => {
            const obs = TimepunchServiceDowngraded.get(punch.customer_id, punch.id, [ 'comments.user', 'businessUnit', 'warnings', 'employee' ]);
            ManageTimepunchDialogServiceDowngraded.update(punch.customer_id, obs, { disableEmployeeSelect: true }).beforeClosed().subscribe((punch) => {
                if (punch) {
                    ToastService.updated();
                    ctrl.update();
                }
            });
        };
        ctrl.new = () => {
            ManageTimepunchDialogServiceDowngraded.create(ctrl.customer.id, { disableEmployeeSelect: true }, { employee: ctrl.employee.id }).beforeClosed().subscribe((punch) => {
                if (punch) {
                    ToastService.created();
                    ctrl.update();
                }
            });
        };
        ctrl.showComments = (timepunch) => {
            MatDialogDowngrade.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
                data: {
                    comments: of(timepunch.comments.map((c) => new Comment(c))),
                },
            })
        };
        ctrl.update = () => {
            ctrl.reloadTable = true;
        };
        ctrl.reset = () => {
            ctrl.reloadTable = true;
        };
        ctrl.exportCsv = () => {
            if (!Array.isArray(ctrl.tpData)) {
                return;
            }
            const data = ctrl.tpData.map((data) => {
                return [ data.customer.name, data.in.format('YYYY-MM-DD'), data.in.format('HH:mm:ss'), data.out?.format('HH:mm:ss') || '', (data.length / 3600).toFixed(3) ];
            });
            data.push([ '', '', '', '', (ctrl.totalLength / 3600).toFixed(3) ]);
            csvCreator([ 'location', 'date', 'from', 'to', 'duration (hours)' ], data, true, `export-timepunches-employee-${ctrl.employee.id}`);
        };
        ctrl.rowClass = (timepunch) => timepunch.deleted_at ? [ 'active' ] : [];
        ctrl.getData = (pag) => {
            delete ctrl.totalLength;
            const args = {
                customer: ctrl.customer,
                employee: ctrl.employee,
                edit: false,
                format: 'LL',
                from: ctrl.filterInterval?.from,
                to: ctrl.filterInterval?.to,
                with: [ 'comments.user', 'businessUnit', 'warnings', 'customer' ],
                with_trashed: ctrl.withTrashed,
                include_external: ctrl.includeExternal,
                ...PaginationOld.getParams(pag),
            };
            if (ctrl.selectedUnits.length) {
                args.business_unit_ids = ctrl.selectedUnits;
            }
            return TimepunchFactory.getForEmployee(args).$promise.then((resp) => {
                ctrl.tpData = resp.data;
                ctrl.form.unsubmit();
                ctrl.totalLength = resp.data.reduce((sum, tp) => sum + tp.length, 0);
                return resp;
            });
        };
    } ],
});
