<mat-card>
    <mat-card-header>
        <mat-card-title *ngIf="customerId">{{fetchedCustomer?.name}}</mat-card-title>
        <mat-card-title *ngIf="!customerId">{{'NEW_CUSTOMER' | translate:'admin' | async}}</mat-card-title>
        <mat-card-subtitle>{{'INFORMATION' | translate:'company' | async}}</mat-card-subtitle>

        <span class="tw-flex-1"></span>

        <div class="tw-flex tw-gap-16">
            <button mat-mini-fab color="accent" [matTooltip]="('SAVE' | translate | async)!" matTooltipPosition="left" [disabled]="!customerForm.valid" (click)="submit(fetchedCustomer?._response)">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </mat-card-header>

    <div *ngIf="fetchingCustomer" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

    <div id="content" *ngIf="!fetchingCustomer">
        <form [formGroup]="customerForm">
            <mat-form-field>
                <mat-label>{{'NAME' | translate | async}}</mat-label>
                <input type="text" matInput required formControlName="name">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'NUMBER' | translate | async}}</mat-label>
                <input type="text" matInput required formControlName="number">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'ORG_NUMBER' | translate: 'admin' | async}}</mat-label>
                <input type="number" step="1" min="1" matInput formControlName="organization_number">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'ADDRESS_1' | translate | async}}</mat-label>
                <input type="text" matInput required formControlName="address1" (change)="addressChange()" (keyup)="keyupAddress($event)">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'ADDRESS_2' | translate | async}}</mat-label>
                <input type="text" matInput formControlName="address2" (change)="addressChange()" (keyup)="keyupAddress($event)">
            </mat-form-field>

            <!-- If only country and no region -->
            <ng-container *ngIf="customerForm.controls.countryCode && !customerForm.controls.region">
                <eaw-autocomplete formControlName="countryCode"
                                  [options]="countryAutocompleteService.options"
                                  [setter]="countryAutocompleteService.setter()"
                                  [getter]="countryAutocompleteService.getter()"
                                  required>
                    <mat-icon eawPrefix>public</mat-icon>
                </eaw-autocomplete>
            </ng-container>

            <!-- If country and region -->
            <ng-container *ngIf="customerForm.controls.countryCode && customerForm.controls.region">
                <eaw-autocomplete formControlName="countryCode"
                                  [options]="countryAutocompleteService.options"
                                  [setter]="countryAutocompleteService.setter()"
                                  [getter]="countryAutocompleteService.getter()"
                                  required>
                    <mat-icon eawPrefix>public</mat-icon>
                </eaw-autocomplete>

                <mat-form-field *ngIf="!customerForm.controls.countryCode.value">
                    <mat-label>{{'REGION' | translate:'company' | async}}</mat-label>

                    <div matPrefix class="tw-mx-12">
                        <mat-icon>pin_drop</mat-icon>
                    </div>

                    <input matInput disabled>
                </mat-form-field>

                <eaw-autocomplete formControlName="region"
                                  *ngIf="customerForm.controls.countryCode.value"
                                  [options]="countryRegionAutocompleteService.options"
                                  [setter]="countryRegionAutocompleteService.setter({ countryCode: customerForm.controls.countryCode.value })"
                                  [getter]="countryRegionAutocompleteService.getter({ countryCode: customerForm.controls.countryCode.value })"
                                  [triggers]="[regionTrigger]">
                    <mat-icon eawPrefix>pin_drop</mat-icon>
                </eaw-autocomplete>
            </ng-container>

            <mat-form-field>
                <mat-label>{{'CITY' | translate | async}}</mat-label>
                <input type="text" matInput required formControlName="city" (change)="addressChange()" (keyup)="keyupAddress($event)">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'ZIP' | translate | async}}</mat-label>
                <input type="text" matInput required formControlName="postalCode">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'CURRENCY' | translate | async}}</mat-label>
                <input type="text"
                       matInput
                       required
                       formControlName="currency"
                       [matAutocomplete]="currencies">
                <mat-autocomplete #currencies="matAutocomplete" [displayWith]="currencyDisplay">
                    <mat-option *ngFor="let currency of filteredCurrencies | async" [value]="currency">
                        <span>{{currency.name}}</span> |
                        <small>{{currency.code}}</small>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <eaw-autocomplete formControlName="languageCode"
                              [options]="languageAutocompleteService.options"
                              [setter]="languageAutocompleteService.setter()"
                              [getter]="languageAutocompleteService.getter()">
            </eaw-autocomplete>

            <mat-form-field>
                <mat-label>{{'LOCALE' | translate | async}}</mat-label>
                <mat-select formControlName="localeCode" required>
                    <mat-option *ngFor="let locale of locales" [value]="locale.code">{{locale.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'TYPE' | translate | async}}</mat-label>
                <mat-select formControlName="type" required>
                    <mat-option *ngFor="let type of customerTypes" [value]="type.type">{{type.translationKey | translateSync:type.namespace }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'TIMEZONE' | translate: 'admin' | async}}</mat-label>
                <input type="text"
                       matInput
                       formControlName="timeZone"
                       [matAutocomplete]="timezones">
                <mat-autocomplete #timezones="matAutocomplete">
                    <mat-option *ngFor="let timezone of filteredTimezones | async" [value]="timezone">
                        <span>{{timezone}}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <eaw-autocomplete formControlName="stack"
                              [options]="stackAutocompleteService.options"
                              [setter]="stackAutocompleteService.setter()"
                              [getter]="stackAutocompleteService.getter()">
            </eaw-autocomplete>

            <mat-form-field>
                <mat-label>{{'BILLING_CONTACT' | translate | async}}</mat-label>
                <input type="text" matInput required formControlName="billingContact">
            </mat-form-field>

            <eaw-autocomplete formControlName="billingCustomerId"
                              [options]="this.billingCustomerOptions"
                              [setter]="customerAutocompleteService.setter()"
                              [getter]="customerAutocompleteService.getter()">
            </eaw-autocomplete>

            <eaw-autocomplete formControlName="settingGroup"
                              [options]="this.settingGroupOptions"
                              useCustomHint
                              [setter]="settingGroupAutocompleteService.setter()"
                              [getter]="settingGroupAutocompleteService.getter()">
                <span id="go-to-setting-group" custom-hint (click)="goToSettingGroup()" *ngIf="getSelectedSettingGroupId()">{{'GO_TO_SETTING_GROUP' | translate:'admin' | async}}</span>
            </eaw-autocomplete>

            <mat-form-field>
                <mat-label>{{'LATITUDE' | translate: 'admin' | async}}</mat-label>
                <input type="text" matInput readonly formControlName="latitude">
                <mat-hint>{{'AUTOMATIC_INPUT' | translate: 'forms' | async}}</mat-hint>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'LONGITUDE' | translate: 'admin' | async}}</mat-label>
                <input type="text" matInput readonly formControlName="longitude">
                <mat-hint>{{'AUTOMATIC_INPUT' | translate: 'forms' | async}}</mat-hint>
            </mat-form-field>
        </form>

        <div id="logo-container" [matTooltip]="('UPLOAD_IMAGE' | translate | async) || ''" matTooltipPosition="left" (click)="updateLogo()">
            <img [src]="logoUrl" *ngIf="hasLogo && logoUrl && !fetchingLogo">
            <div class="logo-skeleton" *ngIf="fetchingLogo"></div>
            <div class="no-logo mat-body-1" *ngIf="!fetchingLogo && !hasLogo">{{'CLICK_ADD_CUSTOMER_LOGO' | translate:'admin' | async}}</div>
        </div>

        <eaw-map [center]="place" [mapOptions]="{disableDefaultUI: true, fullscreenControl: false}"></eaw-map>
    </div>
</mat-card>
