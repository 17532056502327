import { DestroyRef, Directive, effect, ElementRef, inject, input, output } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { ElementPermissionService, PermissionChildrenFilterInInput, PermissionChildrenFilterOutInput, PermissionsInputValue } from '../services/element-permission.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateProviderDataDataPermissionCheck } from '../../shared/utils/create-state';

@Directive({
    selector: '[eawPermission]',
    standalone: true,
    exportAs: 'eawPermissionDirective',
})
export class PermissionDirective {
    private readonly element = inject(ElementRef);
    private readonly destroyRef = inject(DestroyRef);
    private readonly elementPermissionService = inject(ElementPermissionService);

    /** Required permissions to show the element */
    permissions = input<PermissionsInputValue>();

    /** Some of these permissions are required to show the element */
    somePermissions = input<PermissionsInputValue>();

    /** Function to check if the user has the permission to show the element */
    permissionCheck = input<StateProviderDataDataPermissionCheck>();

    /**
     * Hide the element if the user is explicitly forbidden from some permission children.
     * The two first elements in the array are the two first arguments to permissionChildrenSingle, the last is for comparing
     * the result to.
     */
    permissionChildrenFilter = input<PermissionChildrenFilterOutInput>();

    /**
     * Show the element if the user is explicitly allowed some permission children. If permission children filter is set, that
     * result overrides this one.
     */
    permissionChildrenInclude = input<PermissionChildrenFilterInInput>();

    /**
     * Emits true when the permissions have been checked and the element is shown, false if the element is hidden.
     */
    permissionsChecked = output<boolean>();

    private checkPermissionSub?: Subscription;
    private displayStyle: string | null = null;

    constructor() {
        effect(() => {
            const element = this.element.nativeElement as HTMLElement;
            const permissions = this.permissions();
            const filter = this.permissionChildrenFilter();
            const somePermissions = this.somePermissions();
            const include = this.permissionChildrenInclude();
            const permissionCheck = this.permissionCheck();

            if (!this.elementPermissionService.setOrRemoveDataOnElement(element, permissions, somePermissions, filter, include)) {
                return;
            }

            this.setDisplayStyle(false);
            this.checkPermissionSub?.unsubscribe();
            this.checkPermissionSub = this.elementPermissionService.shouldShow(permissions, somePermissions, filter, include, permissionCheck)
                .pipe(
                    takeUntilDestroyed(this.destroyRef),
                    tap((result) => {
                        if (result) {
                            this.setDisplayStyle(true);
                        } else {
                            this.elementPermissionService.insertPlaceholderNode(element, permissions, somePermissions, filter, include);
                        }

                        this.permissionsChecked.emit(result);
                    }),
                ).subscribe();
        });
    }

    private setDisplayStyle(hasPermission: boolean) {
        this.element.nativeElement.dataset['allowed'] = hasPermission.toString();
        const currentDisplay = this.element.nativeElement.style.display;

        if (this.displayStyle === null && currentDisplay !== 'none') {
            this.displayStyle = currentDisplay;
        }

        if (!hasPermission) {
            this.element.nativeElement.style.display = 'none';
        } else if (currentDisplay === 'none') {
            this.element.nativeElement.style.display = this.displayStyle;
        }
    }
}
