export const Namespace = {
    AbsenceTypes: 'absence_types',
    Absences: 'absences',
    Admin: 'admin',
    AiBudgeting: 'ai_budgeting',
    Alerts: 'alerts',
    Analytics: 'analytics',
    Autosched: 'autosched',
    Availabilities: 'availabilities',
    Balances: 'balances',
    Calendar: 'calendar',
    ChainOps: 'chainops',
    Checklists: 'checklists',
    Colors: 'colors',
    Company: 'company',
    Coupons: 'coupons',
    CustomFields: 'custom_fields',
    Digisign: 'digisign',
    EmailsUsers: 'emails_users',
    EmphoursVerification: 'emphours_verification',
    EmplistVerification: 'emplist_verification',
    Errors: 'errors',
    Filesystem: 'filesystem',
    FilesystemAccess: 'filesystem_access',
    FixedLaborCost: 'fixed_labor_cost',
    Flexitime: 'flexitime',
    Forms: 'forms',
    FrancePayroll: 'france_payroll',
    FranceSocialRules: 'france_social_rules',
    General: 'general',
    Hr: 'hr',
    Holidays: 'holidays',
    InspectionReport: 'inspection_report',
    KPI: 'kpi',
    KPITypes: 'kpi_types',
    LearningModule: 'learning_module',
    Leaveshifts: 'leaveshifts',
    Links: 'links',
    Login: 'login',
    Messages: 'messages',
    Navigation: 'navigation',
    NewEmployee: 'new_employee',
    Newsletter: 'newsletter',
    Notifications: 'notifications',
    Organization: 'organization',
    Payroll: 'payroll',
    PayrollOverview: 'payroll_overview',
    PayslipImport: 'payslip_import',
    PeopleKPI: 'people_kpi',
    Permissions: 'permissions',
    Profile: 'profile',
    Projections: 'projections',
    Reports: 'reports',
    Rotation: 'rotation',
    Scheduling: 'scheduling',
    Settings: 'settings',
    SwissRiskAndCare: 'src',
    Staffing: 'staffing',
    Tasks: 'tasks',
    TBS: 'tbs',
    Todo: 'todo',
    UseraccessVerification: 'useraccess_verification',
    Vacation: 'vacation',
    VariableStaffing: 'variable_staffing',
    Warnings: 'warnings',
    WarningsV3: 'warnings_v3',
    Widgets: 'widgets',
} as const;

export type NamespaceFile = typeof Namespace[keyof typeof Namespace];

export function isNamespace(ns?: string) {
    const namespaces = Object.values(Namespace) as string[];
    return namespaces.includes(ns || '') ? ns as NamespaceFile : undefined;
}
