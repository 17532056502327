import { ApiResponse } from '../interfaces/api-response';

export enum EmployeeTaskType {
    TermsOfService = 'Ui\\Task\\TermsOfService',
    ProvideEmergencyContact = 'App\\Tasks\\ProvideEmergencyContactTask',
    FillOutEmployeeProfile = 'App\\Tasks\\FillOutEmployeeProfile',
    SignDocument = 'App\\Tasks\\DocumentService\\SignDocumentTask',
    UpdatePinCode = 'App\\Tasks\\Employee\\EmployeeChangePinCodeTask',
    UploadDocument = 'App\\Tasks\\DocumentService\\UploadDocumentTask',
}

export interface EmployeeTaskResponse extends ApiResponse {
    is_blocking: boolean;
    type: EmployeeTaskType;
}

export class EmployeeTask {
    private _completed = false;

    required: boolean;
    type: EmployeeTaskType;

    constructor(response: EmployeeTaskResponse) {
        this.required = response.is_blocking;
        this.type = response.type;
    }

    get completed() {
        return this._completed;
    }

    complete() {
        this._completed = true;
    }
}
