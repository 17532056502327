// @ts-nocheck
import { module } from 'angular';

module('eaw.decorators').config([ '$provide', function($provide) {
    $provide.decorator('$mdColors', [ '$delegate', function $mdColorsDecorator($delegate) {
        const getThemeColor = $delegate.getThemeColor;

        $delegate.getThemeColor = function getThemeColorDecorator(color) {
            try {
                color = getThemeColor(color.replace(/-a(\d{2,3})$/, '-A$1'));
            } catch (_) {
                console.warn(`Couldn't find theme color`, color);
            }

            return color;
        };

        return $delegate;
    } ]);
} ]);
