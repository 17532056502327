import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { BaseSchedule, ScheduleResponse } from '../models/base-schedule';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { DateTime, MonthNumbers } from 'luxon';
import { Schedule } from '../models/schedule';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ScheduleTemplate } from '../models/schedule-template';
import { classifyArrayPaginatedResponse } from 'src/app/shared/utils/rxjs/classify';
import { Warning, WarningResponse } from '../../shared/models/warning';

interface GetAllOptions extends PaginationOptions {
    from?: DateTime;
    to?: DateTime;
}

interface BaseCreateBody {
    from: DateTime,
    name: string,
}

interface CreateNoTemplateBody extends BaseCreateBody {
    length: number;
    template_id?: never;
}

interface CreateWithTemplateBody extends BaseCreateBody {
    length?: never;
    template_id: number,
}

type CreateBody = CreateNoTemplateBody | CreateWithTemplateBody;

interface UpdateBody {
    name?: string;
}

interface GetOptions {
    'with[]': string[];
}

@Injectable({
    providedIn: 'root',
})
export class ScheduleService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    private toSchedule(scheduleResponse: ScheduleResponse): BaseSchedule {
        return scheduleResponse.is_template ? new ScheduleTemplate(scheduleResponse) : new Schedule(scheduleResponse);
    }

    getAll(customerId: number, options?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<ScheduleResponse>>(`customers/${customerId}/schedules`, {
            params: formatHttpParams({
                ...options,
                template: false,
            }),
        }).pipe(map((response) => {
            return {
                ...response,
                data: response.data.map(this.toSchedule),
            };
        }));
    }

    get(customerId: number, scheduleId: number, options?: GetOptions) {
        return this.http.get<ScheduleResponse>(`customers/${customerId}/schedules/${scheduleId}`, {
            params: formatHttpParams(options),
        }).pipe(map(this.toSchedule));
    }

    createDefault(customerId: number, body: CreateBody): Observable<Schedule> {
        return this.http.post<ScheduleResponse>(`customers/${customerId}/schedules`, {
            is_template: false,
            ...body,
        }).pipe(map(this.toSchedule));
    }

    createRepeatingYearAndMonth(customerId: number, year: number, month: MonthNumbers, body: { template_id: number[], name?: string }) {
        return this.http.post<ScheduleResponse>(`customers/${customerId}/schedules/months/${year}/${month}`, {
            name: body.name?.trim().length ? body.name : undefined,
            template_id: body.template_id,
        }).pipe(map(this.toSchedule));
    }

    createRepeatingFromAndTo(customerId: number, from: DateTime, to: DateTime, body: { template_id: number[], name?: string }) {
        return this.http.post<ScheduleResponse>(`customers/${customerId}/schedules/repeating`, {
            name: body.name?.trim().length ? body.name : undefined,
            template_id: body.template_id,
            from,
            to,
        }).pipe(map(this.toSchedule));
    }

    update(customerId: number, scheduleId: number, body: UpdateBody): Observable<Schedule> {
        return this.http.put<ScheduleResponse>(`customers/${customerId}/schedules/${scheduleId}`, body).pipe(map(this.toSchedule));
    }

    publish(customerId: number, scheduleId: number, publishAt?: DateTime) {
        return this.http.put<ScheduleResponse>(`customers/${customerId}/schedules/${scheduleId}`, {
            publish: true,
            publish_at: publishAt,
        }).pipe(map(this.toSchedule));
    }

    unpublish(customerId: number, scheduleId: number) {
        return this.http.put(`customers/${customerId}/schedules/${scheduleId}/unpublish`, {});
    }

    delete(customerId: number, scheduleId: number) {
        return this.http.delete(`customers/${customerId}/schedules/${scheduleId}`);
    }

    getWarnings(customerId: number, scheduleId: number, pagination?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<WarningResponse>>(`customers/${customerId}/schedules/${scheduleId}/warnings`, {
            params: formatHttpParams(pagination),
        }).pipe(classifyArrayPaginatedResponse(Warning));
    }

    notifyAuditors(customerId: number, scheduleId: number) {
        return this.http.post(`customers/${customerId}/schedules/${scheduleId}/ready`, {});
    }

    declineSchedule(customerId: number, scheduleId: number) {
        return this.http.put(`customers/${customerId}/schedules/${scheduleId}/ready`, {});
    }
}
