import { Component, inject, Input, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatOption } from '@angular/material/autocomplete';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { HrFileService } from '../../http/hr-file.service';
import { CurrentService } from '../../../shared/services/current.service';
import { HrFile } from '../../models/hr-file';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { catchError, EMPTY, tap } from 'rxjs';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { ShowHtmlFormComponent } from '../../components/show-html-form/show-html-form.component';
import { OldClassAsResponse } from '../../../shared/utils/old-class-as-response';

@Component({
    selector: 'eaw-sign-document-service',
    standalone: true,
    imports: [
        AsyncPipe,
        MatOption,
        PageHeaderComponent,
        TranslatePipe,
        MatIconModule,
        MatCardModule,
        ActionButtonComponent,
        MatProgressSpinner,
        MatButtonModule,
        ShowHtmlFormComponent,
    ],
    templateUrl: './sign-document-service.component.html',
    styleUrl: './sign-document-service.component.scss',
})
export class SignDocumentServiceComponent implements OnInit {
    @Input({ required: true }) file!: HrFile;
    @Input({ required: true }) customerId!: number;

    private readonly hrFileService = inject(HrFileService);
    private readonly current = inject(CurrentService);
    private readonly snackbar = inject(SnackBarService);

    loading = true;
    signing = false;
    htmlForm?: string;
    formFieldsSubmitted: boolean = false;
    formData?: Record<string, any> | null;
    submittingFormFields?: boolean;

    ngOnInit() {
        // Doing it like this because transform doesn't work
        this.file = new HrFile(OldClassAsResponse(this.file));
        this.loading = false;
        if (this.file.hasFormFields) {
            this.hrFileService.getRaw(this.customerId, this.file.employeeId, this.file.id)
                .pipe(tap((resp) => this.htmlForm = resp))
                .subscribe();
        }
    }

    download() {
        const employeeId = this.current.getEmployee()?.id;
        if (!employeeId) {
            return;
        } else {
            this.hrFileService.download(
                this.current.getCustomer().id,
                employeeId,
                this.file.id,
                this.file.name).subscribe();
        }
    }

    sign() {
        const employeeId = this.current.getEmployee()?.id;
        if (!employeeId) {
            return;
        } else {
            this.signing = true;
            this.hrFileService.sign(
                this.current.getCustomer().id,
                employeeId,
                this.file.id,
            ).pipe(
                catchError(() => {
                    this.signing = false;
                    void this.snackbar.t('SIGN_WITH_DOCUMENT_SERVICE_FAILED', 'digisign');
                    return EMPTY;
                }),
            ).subscribe(() => {
                this.reloadPage();
            });
        }
    }

    reloadPage() {
        setTimeout(() => window.location.reload(), 5000);
    }

    onFormFilled($event: Record<string, any> | null) {
        this.formData = $event;
    }

    submitFormFieldsAndSign() {
        if (this.formData) {
            this.submittingFormFields = true;
            this.hrFileService.saveFormFields(this.customerId, this.file.employeeId, this.file.id, this.formData)
                .pipe(tap(() => {
                    this.formFieldsSubmitted = true;
                    this.submittingFormFields = false;

                    this.sign();
                }))
                .subscribe();
        }
    }
}
