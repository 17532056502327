@if (value.length) {
    {{value}}
} @else if (objectValue.length) {
    <table class="tw-text-right">
        @for (x of objectValue; track x) {
            <tr>
                <td>{{x.key}}</td>
                <td>⟶</td>
                <td>{{x.value}}</td>
            </tr>
        }
    </table>
}
