import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Injectable({
    providedIn: 'root',
})
export class NotificationsHooksService {

    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
        this.uiRouter.transitionService.onBefore({
            to: 'eaw/outside/notification-redirect',
        }, (transition) => {
            console.debug('🪝', 'NotificationsHooksService.onBefore', transition);

            const data = transition.params()['data'];
            const type = transition.params()['type'];

            if (!(data && type)) {
                throw new Error('Missing data or type');
            }
        });
    }
}
