<eaw-dialog-header>
    <span title>{{'ADD_MEMBER_plural' | translate | async}}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-12">
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-8">
        <mat-form-field eawDatePickerOptions>
            <mat-label>{{'START_DATE' | translate | async}}</mat-label>
            <input matInput formControlName="from" [matDatepicker]="fromPicker" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field eawDatePickerOptions>
            <mat-label>{{'END_DATE' | translate | async}}</mat-label>
            <input matInput formControlName="to" [matDatepicker]="toPicker" [min]="form.controls.from.value || minDate">
            <button *ngIf="form.controls.to?.value" matSuffix mat-icon-button aria-label="Clear" (click)="form.controls.to.setValue(null)">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>

        <mat-hint *ngIf="isFutureDate(form.controls.from.value)">{{'ADD_FUTURE_EMPLOYEE_GROUP_MEMBER_HINT' | translate:'company' | async}}</mat-hint>

        <eaw-autocomplete formControlName="employee"
                          [options]="employeeAutocompleteService.options"
                          [getter]="employeeAutocompleteService.getter({customerId: data.customerId})"
                          [setter]="employeeAutocompleteService.setter({customerId: data.customerId})"/>

        <button mat-raised-button color="primary" (click)="addMember()" [disabled]="form.invalid || form.disabled">{{'ADD_MEMBER' | translate | async}}</button>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-raised-button [mat-dialog-close]="">{{'CLOSE' | translate | async}}</button>
</mat-dialog-actions>
