import { PermissionsInputValueTuple } from '../permissions/services/element-permission.service';
import { ApiModel } from '../shared/enums/api-model';
import { customerPermissionModel } from '../shared/permissions';
import { PermissionModel } from '../shared/services/permission-check.service';

export function hrFiletypePermissionModel(id: number): PermissionModel {
    return { type: ApiModel.HrFiletype, id };
}

export function canCreateSignablePermission(customerId: number): PermissionsInputValueTuple {
    return [ `customers.[${ApiModel.Customer}].signables.create`, {
        models: [ customerPermissionModel(customerId) ],
    } ];
}

export function canCreateHrFiletypePermission(customerId: number): PermissionsInputValueTuple {
    return [ `customers.[${ApiModel.Customer}].hr_file_types.create`, {
        models: [ customerPermissionModel(customerId) ],
    } ];
}

export function canUpdateHrFiletypePermission(stackId: number, customerId: number, typeId: number): PermissionsInputValueTuple {
    return [ `customers.[${ApiModel.Customer}].hr_file_types.[${ApiModel.HrFiletype}].update`, {
        stackId,
        models: [ customerPermissionModel(customerId), hrFiletypePermissionModel(typeId) ],
    } ];
}

export function canDeleteHrFiletypePermission(stackId: number, customerId: number, typeId: number): PermissionsInputValueTuple {
    return [ `customers.[${ApiModel.Customer}].hr_file_types.[${ApiModel.HrFiletype}].delete`, {
        stackId,
        models: [ customerPermissionModel(customerId), hrFiletypePermissionModel(typeId) ],
    } ];
}
