<mat-progress-spinner mode="indeterminate" *ngIf="loading"></mat-progress-spinner>

<div *ngIf="!loading && message">
    <span>{{ message | async }}</span>
</div>

<form [formGroup]="form" *ngIf="!loading && !message" class="tw-grid tw-grid-cols-1 tw-gap-8 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
    <mat-form-field>
        <mat-label>{{ 'FIRST_NAME' | translate | async }}</mat-label>
        <input formControlName="firstName" matInput readonly>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'LAST_NAME' | translate | async }}</mat-label>
        <input formControlName="lastName" matInput readonly>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'ADDRESS_1' | translate | async }}</mat-label>
        <input formControlName="address1" matInput>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'ADDRESS_2' | translate | async }}</mat-label>
        <input formControlName="address2" matInput>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'ZIP' | translate | async }}</mat-label>
        <input formControlName="postalCode" matInput>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'CITY' | translate | async }}</mat-label>
        <input formControlName="city" matInput>
    </mat-form-field>

    <eaw-autocomplete formControlName="nationality"
                      [label]="'NATIONALITY' | translate"
                      [options]="countryAutocompleteService.options"
                      [setter]="countryAutocompleteService.setter()"
                      [getter]="countryAutocompleteService.getter()">
    </eaw-autocomplete>

    <!-- If only country and no region -->
    <ng-container *ngIf="form.controls.countryKey && !form.controls.region">
        <eaw-autocomplete formControlName="countryKey"
                          prefix="public"
                          [options]="countryAutocompleteService.options"
                          [setter]="countryAutocompleteService.setter()"
                          [getter]="countryAutocompleteService.getter()">
        </eaw-autocomplete>
    </ng-container>

    <!-- If country and region -->
    <ng-container *ngIf="form.controls.countryKey && form.controls.region">
        <eaw-autocomplete formControlName="countryKey"
                          prefix="public"
                          [options]="countryAutocompleteService.options"
                          [setter]="countryAutocompleteService.setter()"
                          [getter]="countryAutocompleteService.getter()">
        </eaw-autocomplete>

        <mat-form-field *ngIf="!form.controls.countryKey.value">
            <mat-label>{{ 'REGION' | translate:'company' | async }}</mat-label>

            <div matPrefix class="tw-mx-12">
                <mat-icon>pin_drop</mat-icon>
            </div>

            <input matInput disabled>
        </mat-form-field>

        <eaw-autocomplete formControlName="region"
                          prefix="pin_drop"
                          *ngIf="form.controls.countryKey.value"
                          [options]="countryRegionAutocompleteService.options"
                          [setter]="countryRegionAutocompleteService.setter({ countryCode: form.controls.countryKey.value })"
                          [getter]="countryRegionAutocompleteService.getter({ countryCode: form.controls.countryKey.value })"
                          [triggers]="[regionTrigger]">
        </eaw-autocomplete>
    </ng-container>
    <mat-form-field>
        <mat-label>{{ 'GENDER' | translate | async }}</mat-label>
        <mat-select formControlName="gender">
            <mat-option [value]="'female'">{{ 'FEMALE' | translateSync }}</mat-option>
            <mat-option [value]="'male'">{{ 'MALE' | translateSync }}</mat-option>
            <mat-option [value]="'other'">{{ 'OTHER' | translateSync }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field eawDatePickerOptions>
        <mat-label>{{ 'BIRTH_DATE' | translate:'company' | async }}</mat-label>
        <input formControlName="birthDate" matInput [matDatepicker]="$any(birthDatePicker)">
        <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #birthDatePicker startView="multi-year"></mat-datepicker>
    </mat-form-field>
</form>
