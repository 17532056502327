<eaw-page-header>
    <div title>
        {{ 'REQUEST_plural' | translate  | async }}
    </div>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        <form id="filterForm" class="tw-grid tw-gap-8 xl:tw-grid-cols-4 lg:tw-grid-cols-3 md:tw-grid-cols-2" [formGroup]="formGroup" (ngSubmit)="update()">
            <mat-form-field>
                <mat-label>{{ 'MODE' | translate:'vacation' | async }}</mat-label>
                <mat-select [compareWith]="modeComparer" formControlName="approvalMode">
                    <mat-option *ngFor="let mode of modes" [value]="mode">
                        {{ mode.label | translateSync }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field eawDatePickerOptions>
                <mat-label>{{ 'ENTER_DATE_RANGE' | translate | async }}</mat-label>
                <mat-date-range-input formGroupName="interval" [rangePicker]="picker">
                    <input matStartDate formControlName="from" [placeholder]="'FROM' | translate | async" autocomplete="off">
                    <input matEndDate formControlName="to" [placeholder]="'TO' | translate | async" autocomplete="off">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <eaw-autocomplete formControlName="employee"
                              [options]="employeeAutocompleteService.options"
                              [getter]="employeeAutocompleteService.getter({
                                  customerId: customerId,
                                  from: formGroup.controls.interval.controls.from.value,
                                  to: formGroup.controls.interval.controls.to.value
                              })"
                              [setter]="employeeAutocompleteService.setter({customerId: customerId})">
            </eaw-autocomplete>

            <div class="tw-flex tw-flex-col tw-justify-center tw-pl-8 tw-pb-16" *ngIf="showSubordinates && !formGroup.controls.employee.value">
                <mat-checkbox eawCheckboxHelper formControlName="onlyMyEmployees">
                    {{ 'ONLY_MY_EMPLOYEES' | translate: 'general' | async }}
                </mat-checkbox>
            </div>
        </form>
    </mat-card-content>

    <mat-card-actions class="tw-pt-0">
        <button class="tw-ml-8"
                form="filterForm"
                color="primary"
                mat-raised-button type="submit"
                [disabled]="formGroup.invalid || formGroup.pristine"
                (click)="update(1, true)">
            {{ 'UPDATE' | translate | async }}
        </button>
    </mat-card-actions>
</mat-card>

<mat-card class="tw-mt-8">
    <eaw-data-table
        #dataTableComponent
        [goTo]="goTo"
        (paginationChange)="updateTable($event)"
        [request]="request"
        [columns]="columns">
    </eaw-data-table>
</mat-card>
