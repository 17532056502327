<eaw-page-header [buttons]="buttons">
    <span title>{{locationName}}</span>
    <span title *ngIf="orgNumber">, {{orgNumber}}</span>
</eaw-page-header>
<div *ngIf=" !(reports.length)" class="centered">
    <mat-progress-spinner diameter="64" strokeWidth="6" mode="indeterminate"></mat-progress-spinner>
</div>
<ng-container *ngIf="reports.length">
    <mat-card *ngFor="let report of reports; let i = index" class="tw-mb-20">
        <mat-card-content *ngIf="report.date" class="tw-pl-0 tw-pr-0 tw-pb-0">
            <span mat-card-title>{{report.date | DateTime:'DATE_MED_WITH_WEEKDAY'}}</span>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>{{'EMPLOYEE' | translate: 'inspection_report' | async}}</th>
                    <th>{{'EMPLOYEE_NUMBER' | translate: 'company' | async}}</th>
                    <th>{{'SHIFT' | translate: 'inspection_report' | async}}</th>
                    <th>{{'TIMEPUNCH' | translate: 'payroll' | async}}</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let employee of employees">
                    <tr *ngIf="employee.shifts[i]!.length>0 || employee.timepunches[i]!.length>0">
                        <td>{{employee.name}}</td>
                        <td>{{employee.number}}</td>
                        <td>
                            <div *ngFor="let shift of employee.shifts[i]">
                                <em>{{shift.from | DateTime:'DATE_SHORT'}}</em>
                                <span> {{shift.from | DateTime:'TIME_24_SIMPLE'}}</span>
                                -
                                <span>{{shift.to | DateTime:'TIME_24_SIMPLE'}}</span>
                            </div>
                        </td>
                        <td>
                            <div *ngFor="let timepunch of employee.timepunches[i]">
                                <em>{{timepunch.in | DateTime:'DATE_SHORT'}}</em>
                                <span> {{timepunch.in | DateTime:'TIME_24_SIMPLE'}}</span>
                                -
                                <span>{{timepunch.out | DateTime:'TIME_24_SIMPLE'}}</span>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
</ng-container>
