export interface ReportDescriptorResponse {
    description: string,
    module: string,
    name: string
}

export class ReportDescriptor {
    description: string;
    module: string;
    name: string;
    class: string;

    translatedName?: string;
    translatedDescription?: string;

    constructor(data: ReportDescriptorResponse, klass: string) {
        this.description = data.description;
        this.module = data.module;
        this.name = data.name;
        this.class = klass;
    }
}
