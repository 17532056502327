// @ts-nocheck
import { module } from 'angular';

/**
 * directive which sets the src-attribute asynchronously on an element.
 */
module('eaw.misc').directive('imageAttachment', function imageAttachment() {
    return {
        restrict: 'A',
        scope: {
            attachment: '<',
            getImageUrl: '&', // returns a promise
        },
        link($scope, $element) {
            // @ts-ignore
            getImage($scope.attachment, $element[0]);

            /**
             * @param {Object} attachment
             * @param {HTMLImageElement} element
             */
            function getImage(attachment, element) {
                // @ts-ignore
                $scope.getImageUrl({ attachment }).then((response) => {
                    const url = URL.createObjectURL(new Blob([ response.data ], {
                        type: response.headers()['content-type'],
                    }));

                    attachment.objectUrl = url;
                    element.setAttribute('src', url);
                });
            }
        },
    };
});
