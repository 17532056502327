import { Component, computed, ElementRef, inject, input, OnInit, output, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ENTER } from '@angular/cdk/keycodes';
import { Schedule } from '../../models/schedule';
import { ModelCustomField } from '../../../custom-fields/models/model-custom-field';
import { DateTime } from 'luxon';
import { catchError, EMPTY, map, Observable, of, startWith, tap } from 'rxjs';
import { Qualification } from '../../../shared/models/qualification';
import { Namespace } from '../../../shared/enums/namespace';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomFieldsGroup } from '../../../shared/utils/custom-fields-group';
import { ShiftService } from '../../http/shift.service';
import { SettingService } from '../../../shared/http/setting.service';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { QualificationService } from '../../../shared/http/qualification.service';
import { CustomerCustomFieldService } from '../../../shared/http/customer-custom-field.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { BusinessDate } from '../../../shared/utils/business-date';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogContent } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { TimeInputComponent } from '../../../shared/components/date-time/time-input/time-input.component';
import { CustomFieldInputComponent } from '../../../custom-fields/components/custom-field-input/custom-field-input.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MatIconModule } from '@angular/material/icon';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { Shift } from '../../models/shift';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { ShiftEmployeeAutocompleteService } from '../../../shared/autocompletes/shift-employee-autocomplete.service';
import { ShiftEmployee } from '../../models/shift-employee';
import { Employee } from '../../../shared/models/employee';
import { Products } from '../../../shared/enums/products';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

type BusinessDateOption = { value: 1 | 0 | -1, translation: string };

interface HandleShiftForm {
    employee: FormControl<ShiftEmployee | Employee | number | null>;
    date: FormControl<DateTime | null>;
    templateDateIndex: FormControl<number>;
    businessDate: FormControl<BusinessDateOption['value']>;
    from: FormControl<DateTime | null>;
    to: FormControl<DateTime | null>;
    qualifications: FormControl<Qualification | string | null>;
    customFields: CustomFieldsGroup;
    comment: FormControl<string | null>;
}

@Component({
    selector: 'eaw-handle-shift',
    standalone: true,
    imports: [
        CommonModule,
        MatInputModule,
        MatDatepickerModule,
        MatDialogContent,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        TimeInputComponent,
        MatChipsModule,
        MatAutocompleteModule,
        CustomFieldInputComponent,
        TranslatePipe,
        DateTimePipe,
        MatIconModule,
        DatePickerOptionsDirective,
        AutocompleteComponent,
        InfoLoadingComponent,
        TranslateSyncPipe,
    ],
    templateUrl: './handle-shift.component.html',
    styleUrl: './handle-shift.component.scss',
})
/**
 * Used to create or edit a shift
 */
export class HandleShiftComponent implements OnInit {
    protected readonly shiftEmployeeAutocompleteService = inject(ShiftEmployeeAutocompleteService);
    protected readonly employeeAutocompleteService = inject(EmployeeAutocompleteService);
    private readonly shiftService = inject(ShiftService);
    private readonly settingService = inject(SettingService);
    private readonly customerProductService = inject(CustomerProductService);
    private readonly translateService = inject(TranslateService);
    private readonly qualificationService = inject(QualificationService);
    private readonly customerCustomFieldService = inject(CustomerCustomFieldService);

    customerId = input.required<number>();
    schedule = input.required<Schedule>();
    qualifications = input.required<Qualification[]>();
    /**
     * What are the we getting along with the created / updated shift?
     */
    withs = input.required<string[]>();
    /**
     * Undefined if creating a new shift
     */
    shift = input<Shift>();
    employeeId = input<number>();
    date = input<DateTime>();

    shiftCreated = output<Shift>();
    shiftUpdated = output<Shift>();

    qualificationInput = viewChild<ElementRef<HTMLInputElement>>('qualificationInput');
    qualificationsAutocompleteTrigger = viewChild(MatAutocompleteTrigger);

    creating = false;
    customFields = signal<ModelCustomField[]>([]);
    businessDateFollowsFrom = false;
    businessDate?: BusinessDate;
    hasFrance = computed(() => this.customerProductService.hasProducts(this.customerId(), [ Products.France ]));
    maxDate = DateTime.now();
    qualificationsSeparatorKeysCodes: number[] = [ ENTER ];
    gettingQualifications = false;
    filteredQualifications: Observable<Qualification[]>;
    loadedQualifications: Qualification[] | null = null;
    selectedQualifications = new Map<number, Qualification>();
    employeeHint?: Promise<string>;
    businessDateOptions: BusinessDateOption[] = [];
    form: FormGroup<HandleShiftForm>;

    constructor() {
        this.form = new FormGroup<HandleShiftForm>({
            employee: new FormControl<ShiftEmployee | Employee | number | null>(null),
            date: new FormControl<DateTime | null>(null, Validators.required),
            templateDateIndex: new FormControl<number>(0, { nonNullable: true }),
            businessDate: new FormControl<BusinessDateOption['value']>(0, { nonNullable: true }),
            from: new FormControl<DateTime | null>(null, Validators.required),
            to: new FormControl<DateTime | null>(null, Validators.required),
            qualifications: new FormControl<Qualification | string | null>(null),
            customFields: new CustomFieldsGroup(),
            comment: new FormControl<string | null>(null),
        });

        this.filteredQualifications = this.form.controls.qualifications.valueChanges.pipe(
            startWith(null),
            map((search) => {
                if (search instanceof Qualification) {
                    return this.loadedQualifications?.find((q) => q.id === search.id) ? [ search ] : [];
                }

                return this.loadedQualifications?.filter((q) => q.name.toLowerCase().includes(search?.toLowerCase() ?? '')) || [];
            }),
        );
    }

    ngOnInit() {
        this.patchShift(this.shift());
        void this.setBusinessDateOptions();
        this.setHasBusinessDateOffset();
        this.setCustomFields(this.shift());

        this.setSchedule();
    }

    async setBusinessDateOptions() {
        this.businessDateOptions = [
            { value: -1, translation: await this.translateService.t('DAY_BEFORE_SHIFT', Namespace.Scheduling) },
            { value: 0, translation: await this.translateService.t('DAY_OF_SHIFT', Namespace.Scheduling) },
            { value: 1, translation: await this.translateService.t('DAY_AFTER_SHIFT', Namespace.Scheduling) },
        ];
    }

    updateBusinessDate() {
        const date = this.form.value.date;
        this.businessDate = date ? new BusinessDate(date.plus({ days: this.form.value.businessDate })) : undefined;
    }

    setCustomFields(shift: Shift | undefined) {
        this.customerCustomFieldService.getForModel(this.customerId(), ApiModel.Shift, shift).pipe(
            tap((customFields) => {
                this.customFields.set(customFields);
            }),
        ).subscribe();
    }

    getBusinessDayDiff(shift: Shift): BusinessDateOption['value'] {
        let businessDate: BusinessDateOption['value'] = 0;

        if (shift.businessDate?.dateTime.hasSame(shift.from.minus({ day: 1 }), 'day')) {
            businessDate = -1;
        }
        if (shift.businessDate?.dateTime.hasSame(shift.from, 'day')) {
            businessDate = 0;
        }
        if (shift.businessDate?.dateTime.hasSame(shift.from.plus({ day: 1 }), 'day')) {
            businessDate = 1;
        }

        return businessDate;
    }

    patchShift(shift: Shift | undefined) {
        if (shift) {
            this.form.patchValue({
                employee: shift.employee || shift.employeeId,
                date: shift.from,
                from: shift.from,
                to: shift.to,
                businessDate: this.getBusinessDayDiff(shift),
            });

            this.selectedQualifications = new Map(this.qualifications().map((q) => [ q.id, q ]));
        } else {
            this.form.patchValue({
                date: this.date(),
                employee: this.employeeId(),
            });
        }

        this.updateBusinessDate();
    }

    setSchedule() {
        if (this.schedule().isTemplate) {
            const passedInDate = this.date();
            this.form.patchValue({
                templateDateIndex: passedInDate ? this.schedule().days.findIndex((day) => day.dateTime.hasSame(passedInDate, 'day')) : 0,
            });
        } else {
            // Subtract one second to avoid ending on 00:00 which makes that day selectable
            this.maxDate = this.schedule().to.minus({ second: 1 });
        }
    }

    getQualifications() {
        if (this.gettingQualifications || this.loadedQualifications != null) {
            return;
        }

        this.gettingQualifications = true;
        expandAllPages((pagination) => this.qualificationService.getAll(this.customerId(), pagination), {
            per_page: 100,
            direction: 'asc',
            order_by: 'name',
        }).subscribe((qualifications) => {
            this.gettingQualifications = false;
            this.loadedQualifications = qualifications;
            this.form.controls.qualifications.patchValue('');
            this.qualificationsAutocompleteTrigger()?.openPanel();
        });
    }

    setHasBusinessDateOffset() {
        const settingKey = 'scheduling.shift_business_date_follows_from';

        this.form.controls.businessDate.disable();
        this.settingService.getSome([ 'customers', this.customerId() ], { 'settings[]': [ settingKey ] }).pipe(
            map((settings) => Number.isInteger(settings.find((setting) => setting.key === settingKey)?.value?.asInteger())),
            catchError(() => of(false)),
            tap((follows) => {
                this.businessDateFollowsFrom = follows;

                if (!this.businessDateFollowsFrom) {
                    this.form.controls.businessDate.enable();
                }
            }),
        ).subscribe();
    }

    selectedQualification(event: MatAutocompleteSelectedEvent): void {
        const value = event.option.value;
        if (!(value instanceof Qualification)) {
            return;
        }

        this.selectedQualifications.set(value.id, value);
        this.form.controls.qualifications.setValue(null);

        const input = this.qualificationInput();
        if (input) {
            input.nativeElement.value = '';
        }
    }

    addQualification(event: MatChipInputEvent) {
        const qualificationName = (event.value || '').trim().toLowerCase();

        const qualifications = this.loadedQualifications?.filter((q) => q.name.toLocaleLowerCase().includes(qualificationName)) || [];
        if (qualifications.length !== 1) {
            return;
        }

        // We are making sure above that there's EXACTLY one qualification
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.selectedQualifications.set(qualifications[0]!.id, qualifications[0]!);

        // Clear the input value
        event.chipInput?.clear();
        this.form.controls.qualifications.setValue(null);
    }

    removeQualification(qualification: Qualification) {
        this.selectedQualifications.delete(qualification.id);
    }

    update() {
        if (!this.shift()?.id) {
            return;
        }

        this.save();
    }

    create() {
        this.save();
    }

    save() {
        const options = this.getOptions();
        if (!options) {
            return;
        }

        let observable: Observable<Shift>;
        const shiftId = this.shift()?.id;
        if (shiftId) {
            observable = this.shiftService.update(this.customerId(), this.schedule().id, shiftId, options, this.form.controls.customFields.getValues());
        } else {
            observable = this.shiftService.create(this.customerId(), this.schedule().id, options, this.form.controls.customFields.getValues());
        }

        this.creating = true;
        this.form.disable();

        observable.pipe(
            catchError(() => {
                this.creating = false;
                this.form.enable();
                return EMPTY;
            }),
            tap((shift) => {
                if (this.shift()?.id) {
                    this.shiftUpdated.emit(shift);
                } else {
                    this.shiftCreated.emit(shift);
                }
            }),
        ).subscribe();
    }

    private getOptions() {
        const value = this.form.getRawValue();
        if (!this.schedule || !value.date || !value.from || !value.to || !this.businessDate) {
            return null;
        }

        const start = value.date.startOf('day');
        const from = start.set({ hour: value.from.hour, minute: value.from.minute });
        let to = start.set({ hour: value.to.hour, minute: value.to.minute });

        if (to <= from) {
            to = to.plus({ days: 1 });
        }

        return {
            employee_id: typeof value.employee === 'number' ? value.employee : value.employee?.id ?? null,
            business_date: this.businessDate,
            offset: from.diff(this.schedule().from, 'seconds').as('seconds'),
            length: to.diff(from, 'seconds').as('seconds'),
            qualifications: Array.from(this.selectedQualifications.keys()),
            with: this.withs(),
        };
    }
}
