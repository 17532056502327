// @ts-nocheck
import { module } from 'angular';
module('eaw.dialogs').controller('CreatePermissionDialogCtrl', [ '$mdDialog', 'PermissionFactory', function($mdDialog, PermissionFactory) {
    const ctrl = this;
    ctrl.$onInit = () => {
        ctrl.existing = {
            permission: null,
            allow: false,
            fields: {},
        };
        ctrl.custom = {
            permission: null,
            allow: false,
        };
        ctrl.getAllPermissions();
    };
    ctrl.getAllPermissions = () => {
        ctrl.loadingPermissions = true;
        PermissionFactory.getAll({ per_page: 9999 }).$promise.then((data) => {
            ctrl.permissions = data.data;
            ctrl.loadingPermissions = false;
        });
    };
    ctrl.insertFields = (permission) => {
        if (permission == null) {
            ctrl.existing.fields = {};
            ctrl.existingPermissionFields = [];
            return;
        }
        ctrl.existingPermissionFields = permission.value.match(/{[A-Za-z]+}*/gi);
        ctrl.existing.fields = {};
        ctrl.existingPermissionFields.forEach((field) => {
            ctrl.existing.fields[field] = null;
        });
    };
    ctrl.getPermissions = (filter) =>
    // Return matching objects
        Object.keys(ctrl.permissions).reduce((arr, key) => {
            const permission = ctrl.permissions[key];
            if (permission.name.toLowerCase().includes(filter.toLowerCase())) {
                return arr.concat({
                    value: key,
                    ...permission,
                });
            }
            return arr;
        }, []).slice(0, 20);
    ctrl.returnPermission = (node, value) => {
        $mdDialog.hide({
            node,
            value,
        });
    };
    ctrl.addExistingPermission = (form) => {
        if (form.$valid) {
            let node = ctrl.existing.permission.value;
            Object.keys(ctrl.existing.fields).forEach((field) => {
                node = node.replace(field, ctrl.existing.fields[field]);
            });
            ctrl.returnPermission(node, ctrl.existing.allow);
        }
    };
    ctrl.addCustomPermission = () => {
        ctrl.returnPermission(ctrl.custom.permission, ctrl.custom.allow);
    };
} ]);
