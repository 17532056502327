<eaw-page-header [fabButton]="csvButton()">
    <span title>{{ 'PAYROLL_OVERVIEW' | translate:'payroll_overview' | async }}</span>
</eaw-page-header>

<mat-card>
    <mat-card-content class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-16">
        <mat-form-field eawDatePickerOptions>
            <mat-label>{{ 'FROM' | translate | async }}</mat-label>
            <input matInput [formControl]="from" [matDatepicker]="fromPicker">
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field eawDatePickerOptions>
            <mat-label>{{ 'TO' | translate | async }}</mat-label>
            <input matInput [formControl]="to" [matDatepicker]="toPicker">
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>

        <eaw-autocomplete
                [formControl]="employee"
                [getter]="employeeAutocompleteService.getter({customerId: customerId(), from: from.value, to: to.value})"
                [setter]="employeeAutocompleteService.setter({customerId: customerId()})"
                [options]="employeeAutocompleteService.options"/>

        <eaw-action-button class="md:tw-row-start-2" [loading]="gettingOverview()" (click)="getOverview()">{{ 'UPDATE_LIST' | translate | async }}</eaw-action-button>
    </mat-card-content>
</mat-card>

<mat-card id="table-card" [class.error]="hasError()" [class.getting]="gettingOverview()">
    @if (gettingOverview()) {
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    } @else if (hasError()) {
        <div class="tw-flex tw-flex-col tw-items-center tw-gap-8">
            <mat-icon eawMaterialColor="red-500" eawSize="48px">error</mat-icon>
            <span class="mat-subtitle-1">{{ 'UNEXPECTED_ERROR' | translate:'payroll_overview' | async }}</span>
        </div>
    } @else {
        <mat-card-header>
            <mat-card-title>
                @if (fetchedFrom() && fetchedTo()) {
                    <span>{{ 'FROM_TO' | translate:'general':{from: fetchedFrom() | DateTime:'DATE_MED', to: fetchedTo() | DateTime:'DATE_MED'} | async }}</span>
                } @else {
                    <span>{{ 'INVALID_DATE' | translate | async }}</span>
                }
            </mat-card-title>
        </mat-card-header>

        <mat-card-content class="tw-p-0 tw-overflow-auto">
            <table mat-table [dataSource]="dataSource()" matSort (matSortChange)="sortData($event)" matSortDisableClear [matSortDirection]="currentSort().direction" [matSortActive]="currentSort().active">
                @for (category of categories(); track category.columnDef; let isFirstCategory = $first) {
                    <ng-container [matColumnDef]="category.columnDef">
                        <th mat-header-cell class="category category-line" *matHeaderCellDef [colSpan]="category.columns.length" [title]="category.label | async">
                            {{ category.label | async }}
                        </th>
                    </ng-container>

                    @for (column of category.columns; track column.columnDef; let colIndex = $index, firstCol = $first) {
                        <ng-container [matColumnDef]="$any(column.columnDef)" [sticky]="isFirstCategory && colIndex < 3">
                            <th mat-header-cell [class.category-line]="firstCol" *matHeaderCellDef [title]="column.label | async" [mat-sort-header]="$any(column.columnDef)">
                                {{ column.label | async }}
                            </th>

                            <td mat-cell [class.category-line]="firstCol" *matCellDef="let item" [class.negative]="column.type === 'number' && item[column.columnDef] < 0">
                                {{ column.type === 'string' ? item[column.columnDef] : (item[column.columnDef] | eawNumber:2) }}
                            </td>

                            <td mat-footer-cell *matFooterCellDef>
                                {{ getTotal(column) }}
                            </td>
                        </ng-container>
                    }
                }

                <tr mat-header-row *matHeaderRowDef="categoriesDef(); sticky: true"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsDef(); sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsDef();"></tr>
                <tr mat-footer-row *matFooterRowDef="columnsDef(); sticky: true"></tr>
            </table>
        </mat-card-content>
    }
</mat-card>
