import { module } from 'angular';
import { Products } from '../../shared/enums/products';
import { createState } from '../../shared/utils/create-state';
import { KpiComponent } from '../pages/kpi/kpi.component';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { LearningModuleModule } from '../../learning-module/types/modules';
import { map, of, switchMap } from 'rxjs';

module('eaw.kpi', []).config([ '$stateProvider', function($stateProvider: any) {
    createState($stateProvider, {
        name: 'eaw/app/kpi',
        parent: 'eaw/app',
        url: '/kpi',
        views: {
            'content@': {
                component: KpiComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            learningModuleModules: [LearningModuleModule.KeyPerformanceIndicators],
            breadcrumb: {key: 'KEY_PERFORMANCE_INDICATORS', ns: 'navigation'},
            products: [Products.KeyPerformanceIndicators],
            permissionCheck: (permissionCheckService) => {
                return permissionCheckService.isAllowedMany([`customers.{customer}.kpi_types.*.get`, `customers.{customer}.kpis.*.get`]).pipe(
                    switchMap((getAll) => {
                        if (getAll) {
                            return of(true);
                        }

                        return permissionCheckService.permissionChildren([`customers.{customer}.kpi_types`, `customers.{customer}.kpis`], `get`, true).pipe(
                            map((children) => Object.values(children).every((c) => c.length)),
                        );
                    }),
                );
            }
        },
    });
}]);
