// @ts-nocheck
import { module } from 'angular';
import { timeStringToHourMin } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../shared/angularjs/current.factory';
module('eaw.calendar2').controller('handleEventDialogCtrl', [ '$mdDialog', 'Calendar2Event', 'ToastService', 'CalendarEventParticipant', function($mdDialog, Calendar2Event, ToastService, CalendarEventParticipant) {
    // @ts-ignore
    const ctrl = this;
    ctrl.$onInit = () => {
        ctrl.event = ctrl.event instanceof Calendar2Event ? ctrl.event.clone() : ctrl.createDefaultEvent();
        ctrl.participants = ctrl.event.participants.slice(0);
        ctrl.dateFrom = ctrl.event.start.clone();
        ctrl.dateTo = ctrl.event.end.clone();
        ctrl.timeFrom = ctrl.event.start.clone();
        ctrl.timeTo = ctrl.event.end.clone();
    };
    ctrl.calendarChanged = () => {
        ctrl.event.calendarKey = ctrl.event.calendar?.key;
    };
    ctrl.getCalendars = (filter = '') => {
        filter = filter.toLowerCase();
        return ctrl.calendars.filter((c) => c.access.edit && c.name.toLowerCase().includes(filter));
    };
    ctrl.getParticipants = (filter) => {
        const args = {
            customerId: ctrl.event.calendar.customer,
            calendar: ctrl.event.calendar,
        };
        if (filter) {
            args.filter = filter;
        }
        return CalendarEventParticipant.getAll(args);
    };
    ctrl.updateStartEnd = () => {
        ctrl.event.start = ctrl.dateFrom.clone();
        ctrl.event.end = ctrl.dateTo.clone();
        if (ctrl.event.wholeDay) {
            return;
        }
        const timeFrom = timeStringToHourMin(ctrl.timeFrom);
        if (timeFrom) {
            ctrl.event.start.hour(timeFrom.hour);
            ctrl.event.start.minute(timeFrom.minute);
            ctrl.event.start.second(0);
        }
        const timeTo = timeStringToHourMin(ctrl.timeTo);
        if (timeTo) {
            ctrl.event.end.hour(timeTo.hour);
            ctrl.event.end.minute(timeTo.minute);
            ctrl.event.end.second(0);
        }
    };
    ctrl.removeParticipant = (index) => {
        ctrl.participants.splice(index, 1);
    };
    ctrl.deleteInvitation = async (participant, index) => {
        await participant.deleteInvitation(ctrl.event);
        ctrl.removeParticipant(index);
        ctrl.event.participants.splice(ctrl.event.participants.findIndex((p) => p.key === participant.key), 1);
        ctrl.event.broadcastUpdated();
    };
    ctrl.addParticipant = (participant) => {
        ctrl.participant = null;
        // @ts-ignore
        document.activeElement?.blur();
        if (!participant) {
            return;
        }
        ctrl.participants.unshift(participant);
    };
    ctrl.createDefaultEvent = () => {
        const myCal = ctrl.calendars.find((c) => c.myCalendar);
        return Calendar2Event.createDefault(ctrl.date, {
            startTime: ctrl.start,
            endTime: ctrl.end,
            calendar: myCal,
            calendarKey: myCal?.key,
            wholeDay: ctrl.wholeDay,
            ownerKey: CurrentOld.customer.id,
        });
    };
    ctrl.create = async () => {
        ctrl.processing = true;
        try {
            const event = await ctrl.event.create();
            ctrl.sendInvitations(event);
            $mdDialog.hide(event);
        } catch (e) {
            console.error(e);
            ctrl.processing = false;
        }
    };
    ctrl.update = async () => {
        ctrl.processing = true;
        try {
            const event = await ctrl.event.update();
            ctrl.sendInvitations(event);
            $mdDialog.hide(event);
        } catch (e) {
            console.error(e);
            ctrl.processing = false;
        }
    };
    ctrl.sendInvitations = async (event) => {
        // Remove people that are already participating
        ctrl.participants = ctrl.participants.filter((p) => !(p instanceof CalendarEventParticipant));
        if (ctrl.participants.length) {
            try {
                ToastService.t(ctrl.event.key ? 'calendar:UPDATING_INVITATIONS' : 'calendar:SENDING_INVITATIONS');
                const participants = await CalendarEventParticipant.invite({
                    customerId: event.calendar.customer,
                    calendar: event.calendar,
                    event,
                    userIds: ctrl.participants.filter((p) => p.recipient_type === 'user').map((p) => p.id),
                    customerIds: ctrl.participants.filter((p) => p.recipient_type === 'customer').map((p) => p.id),
                    userGroupIds: ctrl.participants.filter((p) => p.recipient_type === 'user_group').map((p) => p.id),
                });
                // Concat the new one(s)
                event.participants = event.participants.concat(participants);
            } catch (e) {
                console.error(e);
            }
        }
    };
} ]);
