// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

const template2 = `<md-dialog>
    <form name="addResponsibleForm">
        <eaw-dialog-header-old heading="'RESPONSIBLE'|i18next"></eaw-dialog-header-old>
        <md-dialog-content>
            <md-content class="md-dialog-content" layout="column">
                <customer-user-autocomplete required label="USER" ng-model="ctrl.user"></customer-user-autocomplete>

                <md-input-container ng-if="!ctrl.temporary">
                    <eaw-date-picker label="FROM" required ng-model="ctrl.interval.from"></eaw-date-picker>
                </md-input-container>

                <eaw-date-interval
                        ng-if="ctrl.temporary"
                        layout="row"
                        flex
                        required
                        ng-model="ctrl.interval">
                </eaw-date-interval>

                <md-checkbox ng-model="ctrl.temporary" ng-change="ctrl.interval.to = undefined">
                    <span bo-i18next="TEMPORARY"></span>
                </md-checkbox>
            </md-content>
        </md-dialog-content>

        <md-dialog-actions>
            <md-button close-dialog>
                <span bo-i18next="CANCEL"></span>
            </md-button>
            <md-button ng-click="ctrl.submit()" ng-disabled="addResponsibleForm.$invalid">
                <span bo-i18next="SUBMIT"></span>
            </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

const template1 = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-i18next="company:RESPONSIBLE"></span>
        </md-card-header-text>

        <card-btn-float ng-if="$responsible.canUpdate" on-click="$responsible.openAddUserDialog()"></card-btn-float>
    </md-card-header>

    <md-card-content class="tw-p-0">
        <md-subheader>
            <span ng-i18next="company:RESPONSIBLE_USERS"></span>
        </md-subheader>
        <eaw-dynamic-ng-table
                columns="$responsible.table.columns"
                cells="$responsible.table.cells"
                get-data="$responsible.getData(pagination)"
                reload-table="$responsible.reload"
                go-to="eaw/app/company/users/view|id=user_id">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;

module('eaw.company').component('companyUserGroupResponsibleList', {
    template: template1,
    controllerAs: '$responsible',
    bindings: {
        customer: '<',
        userGroup: '<',
    },
    controller: [ 'UserGroupResponsibleFactory', '$mdDialog', 'EditDateIntervalDialog', function(UserGroupResponsibleFactory, $mdDialog, EditDateIntervalDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.canUpdate = CurrentOld.can(`customers.${ctrl.customer.id}.user_groups.${ctrl.userGroup.id}.update`);

            ctrl.table = {
                columns: [
                    {
                        title: t('FIRST_NAME'),
                        sortable: 'first_name',
                    },
                    {
                        title: t('LAST_NAME'),
                        sortable: 'last_name',
                    },
                    {
                        title: t('FROM'),
                        sortable: 'from',
                    },
                    {
                        title: t('TO'),
                        sortable: 'to',
                    },
                    { title: '' },
                ],
                cells: [
                    { template: 'item.user.first_name' },
                    { template: 'item.user.last_name' },
                    { template: 'item.from | moment' },
                    { template: 'item.to | moment' },
                    {
                        buttons: [
                            {
                                icon: 'edit',
                                click: ctrl.editResponsable,
                            },
                            {
                                icon: 'stop',
                                type: 'warn',
                                invisible(responsible) {
                                    return !(!responsible.to || responsible.to?.isAfter(moment()));
                                },
                                click: (responsible) => {
                                    ctrl.updateResponsible(responsible, { to: moment() });
                                },
                            },
                        ],
                    },
                ],
            };
        };

        ctrl.editResponsable = (responsible) => {
            EditDateIntervalDialog.open({
                from: responsible.from,
                to: responsible.to,
                title: responsible.user.name,
                includeTime: true,
            }).then((result) => {
                ctrl.updateResponsible(responsible, {
                    from: result.from,
                    to: result.to,
                });
            });
        };

        ctrl.updateResponsible = (responsible, args) => {
            UserGroupResponsibleFactory.update(ctrl.customer.id, ctrl.userGroup.id, responsible.id, args).$promise.then(() => {
                ctrl.reload = true;
            });
        };

        ctrl.openAddUserDialog = () => {
            $mdDialog.show({
                template: template2,
                controllerAs: 'ctrl',
                controller: function() {
                    const addCtrl = this;

                    addCtrl.$onInit = () => {
                        addCtrl.interval = {
                            from: moment().startOf('d'),
                        };
                    };

                    addCtrl.submit = () => {
                        UserGroupResponsibleFactory.create(ctrl.customer.id, ctrl.userGroup.id, {
                            from: addCtrl.interval.from,
                            to: addCtrl.interval.to,
                            user_id: addCtrl.user.id,
                        }).$promise.then((responsible) => $mdDialog.hide(responsible));
                    };
                },
            }).then(() => ctrl.reload = true);
        };

        ctrl.getData = (pagination) => UserGroupResponsibleFactory.getAll(ctrl.customer.id, ctrl.userGroup.id, {
            with: [ 'user' ],
            include_inactive: true,
            ...pagination.toWithPaginatorArguments(),
        });
    } ],
});
