import { DateTime } from 'luxon';
import { ApiResponse } from '../interfaces/api-response';
import { WorkflowStatus } from './workflow-run';

export interface WorkflowRunStepResponse extends ApiResponse {
    id: string;
    run_id: string;
    step_id: string;
    responsible?: string;
    status: WorkflowStatus;
    started_at: string;
    updated_at: string;
}

export class WorkflowRunStep {
    id: string;
    runId: string;
    stepId: string;
    responsible?: string;
    status: WorkflowStatus;
    startedAt: DateTime;
    updatedAt: DateTime;

    constructor(response: WorkflowRunStepResponse) {
        this.id = response.id;
        this.runId = response.run_id;
        this.stepId = response.step_id;
        this.responsible = response.responsible;
        this.status = response.status;
        this.startedAt = DateTime.fromISO(response.started_at);
        this.updatedAt = DateTime.fromISO(response.updated_at);
    }
}
