import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface PayrollOverviewResponse extends ApiResponse {
    /**
     * ID of the employee
     */
    id: number;
    number: number;
    first_name: string;
    last_name: string;
    title: string;
    level: string;
    grade: string;
    contract_target: number;
    justified_and_unjustified_absences: number;
    other_absences: number;
    partial_activity: string | number;
    regular_hours: string | number;
    plus_hours_range_1_full_time: number;
    plus_hours_range_2_full_time: number;
    plus_hours_range_1_part_time: number;
    plus_hours_range_2_part_time: number;
    normal_hours: string | number;
    night_hours_range_1: number;
    night_hours_range_2: number;
    meals: number;
    split_shifts: number;
    worked_time: number;
}

@Injectable({
    providedIn: 'root',
})
export class PayrollOverviewService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    get(customerId: number, from: DateTime, to: DateTime, employeeId?: number) {
        return this.http.get<PayrollOverviewResponse[]>(`customers/${customerId}/payroll-overview`, {
            params: formatHttpParams({
                from,
                to,
                employee_id: employeeId,
            }),
        });
    }
}
