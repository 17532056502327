<eaw-page-header [buttons]="headerButtons()">
    <span title>{{ 'INFORMATION' | translate | async }}</span>
</eaw-page-header>

<mat-card>
    <mat-card-content class="tw-flex tw-flex-col tw-gap-16">
        <eaw-admin-create-update-user #createUpdateUserComponent mode="update" [userId]="userId"/>

        <div>
            <eaw-action-button [loading]="createUpdateUserComponent.processing()"
                               [disabled]="createUpdateUserComponent.form.invalid"
                               (click)="createUpdateUserComponent.update()">
                {{'UPDATE' | translate | async}}
            </eaw-action-button>
        </div>
    </mat-card-content>
</mat-card>
