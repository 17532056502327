import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'eaw-logo',
    standalone: true,
    imports: [ CommonModule ],
    templateUrl: './logo.component.svg',
    styleUrl: './logo.component.scss' ,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class LogoComponent {
    // Flip text color
    @Input() inverse?: boolean;

    // A valid css height value
    @Input() height?: string;

    // A valid css width value
    @Input() width?: string;
}
