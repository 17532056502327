import { Component, Inject, Input, OnInit } from '@angular/core';
import { CustomerService } from '../../../shared/http/customer.service';
import { Customer } from '../../../shared/models/customer';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ObjectDifferenceExtractor } from '../../../shared/utils/object-difference-extractor';
import { UIRouter } from '@uirouter/core';
import { catchError, EMPTY } from 'rxjs';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, AsyncPipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

@Component({
    selector: 'eaw-my-company',
    templateUrl: './my-company.component.html',
    styleUrl: './my-company.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatProgressSpinnerModule,
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class MyCompanyComponent implements OnInit {
    @Input({ required: true }) customerId!: number;

    private objectDiff = new ObjectDifferenceExtractor();

    hasChanges = false;
    canUpdate = false;
    customer?: Customer;
    form = new FormGroup({
        name: new FormControl<string>('', { nonNullable: true, validators: [ Validators.required ] }),
        organizationNumber: new FormControl<string | null>(''),
        address1: new FormControl<string>('', { nonNullable: true, validators: [ Validators.required ] }),
        address2: new FormControl<string | null>(''),
        city: new FormControl<string>('', { nonNullable: true, validators: [ Validators.required ] }),
        number: new FormControl<string>('', { nonNullable: true, validators: [ Validators.required ] }),
        postalCode: new FormControl<string>('', { nonNullable: true, validators: [ Validators.required ] }),
    });

    constructor(
        @Inject(CustomerService) private customerService: CustomerService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
    }

    ngOnInit() {
        this.customerService.get(this.customerId).subscribe((customer) => {
            this.customer = customer;

            this.form.patchValue({
                name: customer.name,
                organizationNumber: customer.organizationNumber,
                address1: customer.address1,
                address2: customer.address2,
                city: customer.city || '',
                number: customer.number,
                postalCode: customer.postalCode,
            });

            this.objectDiff.setOriginal(this.form.value);
        });

        this.permissionCheckService.isAllowed(`customers.${this.customerId}.update`).subscribe((canUpdate) => {
            this.canUpdate = canUpdate;
        });

        this.form.valueChanges.subscribe((value) => {
            this.hasChanges = this.objectDiff.hasChanges(value);
        });
    }

    update() {
        const changes = this.objectDiff.getChanged(this.form.value);

        this.form.disable();
        this.customerService.update(this.customerId, {
            name: changes.name,
            number: changes.number,
            address1: changes.address1,
            address2: changes.address2,
            postal_code: changes.postalCode,
            city: changes.city,
            organization_number: changes.organizationNumber,
        }).pipe(
            catchError(() => {
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe(() => {
            void this.uiRouter.stateService.reload();
        });
    }
}
