// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
module('eaw.user-groups').factory('UserGroupResponsibleFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const url = '/customers/:customer/user_groups/:group/responsible/:responsible';
    return {
        create(customerId, groupId, args) {
            return EawResource.create(url).save({
                customer: customerId,
                group: groupId,
            }, pick(args, [ 'from', 'to', 'user_id' ]));
        },
        get(customerId, groupId, responsibleId, args) {
            return EawResource.create(url).get({
                customer: customerId,
                group: groupId,
                responsible: responsibleId,
                ...Pagination.getParams(args),
            });
        },
        getAll(customerId, groupId, args) {
            return EawResource.create(url).get({
                customer: customerId,
                group: groupId,
                ...Pagination.getParams(args, [ 'include_inactive' ]),
            });
        },
        update(customerId, groupId, responsibleId, args) {
            return EawResource.create(url).update({
                customer: customerId,
                group: groupId,
                responsible: responsibleId,
            }, pick(args, [ 'from', 'to' ]));
        },
        delete(customerId, groupId, responsibleId) {
            return EawResource.create(url).delete({
                customer: customerId,
                group: groupId,
                responsible: responsibleId,
            });
        },
    };
} ]);
