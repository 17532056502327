import moment, { Moment } from 'moment-timezone';
import { IntervalOld } from '../../../shared/angularjs/interval-old';
import { ModelData } from '../../../shared/angularjs/model-data';
import { AvailabilityDayType } from '../../enums/availability-day-type';
import { t } from 'i18next';

export class AvailabilityDayOld implements ModelData {
    whole_day: boolean;
    offset: number;
    length: number;
    to: Moment;
    from: Moment;
    day: number;
    date: Moment;
    availability: any;
    availability_id: number;
    id: number;
    times: IntervalOld[];
    type: string;
    deleted_at?: Moment;
    updated_at?: Moment;
    created_at?: Moment;

    constructor(availability: any, data: any = {}) {
        this.id = data.id;
        this.availability_id = data.availability_id;
        this.availability = availability;
        this.date = data.date;
        this.day = data.day;
        this.from = data.from;
        this.to = data.to;
        this.length = data.length;
        this.offset = data.offset;
        this.whole_day = data.whole_day;

        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.deleted_at = data.deleted_at;

        // Let's see if we are working on an availability
        if (this.availability_id) {
            this.type = this.getType();
            this.times = [];
        } else {
            this.type = AvailabilityDayType.WholeDay;
            this.times = [
                new IntervalOld(moment().hour(8).startOf('h'), moment().hour(16).startOf('h')),
                new IntervalOld(),
            ];
        }
    }

    get isWholeDay() {
        return this.type === AvailabilityDayType.WholeDay;
    }

    get isTime() {
        return this.type === AvailabilityDayType.Time;
    }

    get isOff() {
        return this.type === AvailabilityDayType.Off;
    }

    get isValid() {
        if (!this.isTime) {
            return true;
        }

        return this.times.filter((t) => t.from != null && t.to != null).length;
    }

    get toBackend() {
        if (this.isOff) {
            return;
        }

        if (this.isWholeDay) {
            return {
                day: this.date.diff(this.availability.from, 'd'),
                whole_day: true,
            };
        }

        if (this.isTime) {
            return this.times
                .filter((time) => time.from?.isValid() && time.to?.isValid())
                .map((time) => {
                    const from = time.from?.clone();
                    const to = time.to?.clone();

                    if (to?.isBefore(from)) {
                        to.add(1, 'd');
                    }

                    return {
                        day: this.date.diff(this.availability.from, 'd'),
                        offset: from?.diff(from.clone().startOf('d'), 's'),
                        length: to?.diff(from, 's'),
                    };
                });
        }
    }

    toOff() {
        this.type = AvailabilityDayType.Off;
        return this;
    }

    toTime() {
        this.type = AvailabilityDayType.Time;
        this.times = [
            new IntervalOld(this.from, this.to),
        ];
        return this;
    }

    getType() {
        if (this.whole_day) {
            return AvailabilityDayType.WholeDay;
        } else if (this.length && this.offset >= 0) {
            return AvailabilityDayType.Time;
        } else {
            return AvailabilityDayType.Off;
        }
    }

    static getTranslatedType(type: AvailabilityDayType) {
        let key: string;

        switch (type) {
            case AvailabilityDayType.Off: {
                key = 'OFF';
                break;
            }
            case AvailabilityDayType.WholeDay: {
                key = 'WHOLE_DAY';
                break;
            }
            case AvailabilityDayType.Time: {
                key = 'AT_TIME';
                break;
            }
            default: {
                return t('NA');
            }
        }

        return t(`availabilities:${key}`);
    }
}
