// @ts-nocheck
import { module } from 'angular';

module('eaw.scheduling.backoffice', [
    'ui.router',
    'eaw.misc',
    'eaw.alerts',
    'eaw.login',
    'eaw.notifications',
]);
