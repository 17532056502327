// @ts-nocheck
import { KpiData, KpiTypeData, ProviderSum } from './interfaces';

export class KpiType implements KpiTypeData {
    id: number;
    prefix: string;
    suffix: string;
    name: string;
    type: string;
    sum: string;
    include?: boolean;
    provider_sums: ProviderSum[];
    decimals: number;

    constructor(data: KpiTypeData, sums: ProviderSum[] = []) {
        Object.assign(this, data);
        this.provider_sums = sums;
        this.decimals = this.type === 'integer' ? 0 : 2;
    }

    getSum(kpis: KpiData[], customerId): number | null {
        // Check if we have a provider sum first
        if (this.provider_sums?.length) {
            return this.provider_sums.find((p) => p.customer_id == customerId)?.value;
        }

        // All data regarding a specific kpi type
        let total;
        const filteredData = kpis.filter((k) => k.kpi_type_id == this.id && k.customer_id == customerId);

        switch (this.sum) {
            case 'add':
                return filteredData.reduce((sum, fd) => sum + (fd?.value || 0), 0);
            case 'average': {
                const items = filteredData.length;
                total = filteredData.reduce((sum, fd) => sum + (fd?.value || 0), 0);
                return total / items;
            }
            case 'median': {
                filteredData.sort((a, b) => a.value - b.value);
                const lowMiddle = Math.floor((filteredData.length - 1) / 2);
                const highMiddle = Math.ceil((filteredData.length - 1) / 2);
                return (filteredData[lowMiddle].value + filteredData[highMiddle].value) / 2;
            }
            default:
                return null;
        }
    }
}
