// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="selAdv.item.title"></eaw-dialog-header-old>
    <form name="optionsForm">
        <md-dialog-content>
            <md-list id="select-options-list" flex>
                <md-list-item class="select-option" ng-repeat="option in selAdv.selectOptions track by $index">
                    <md-icon class="option-handle" ng-bind="'drag_indicator'"></md-icon>

                    <p ng-bind="option"></p>

                    <md-button class="md-secondary md-icon-button"
                               md-colors="{color: 'amber'}"
                               ng-if="!!selAdv.deviationOptions[$index] == true"
                               ng-click="selAdv.toggleDeviation($index)">
                        <md-icon ng-bind="'assignment_late'" md-colors="{color: 'red'}">
                            <md-tooltip md-direction="left" ng-i18next="checklists:TURN_OFF_DEVIATION"></md-tooltip>
                        </md-icon>
                    </md-button>

                    <md-button class="md-secondary md-icon-button"
                               md-colors="{color: 'amber'}"
                               ng-if="!!selAdv.deviationOptions[$index] == false"
                               ng-click="selAdv.toggleDeviation($index)">
                        <md-icon ng-bind="'assignment'">
                            <md-tooltip md-direction="left" ng-i18next="checklists:TURN_ON_DEVIATION"></md-tooltip>
                        </md-icon>
                    </md-button>

                    <md-button class="md-secondary md-icon-button" ng-click="selAdv.deleteOption($index)">
                        <md-icon ng-bind="'delete'">
                            <md-tooltip md-direction="left" ng-i18next="DELETE"></md-tooltip>
                        </md-icon>
                    </md-button>
                </md-list-item>
            </md-list>

            <div class="md-dialog-content" layout="column">
                <div layout="row" layout-align="start center">
                    <md-input-container flex md-no-float>
                        <input ng-model="selAdv.newOption" ng-i18next="[placeholder]checklists:NEW_OPTION" autocomplete="off" eaw-on-enter="selAdv.addOption()">
                    </md-input-container>

                    <md-button class="md-icon-button" ng-click="selAdv.addOption()">
                        <md-icon ng-bind="'add'">
                            <md-tooltip md-direction="left" ng-i18next="ADD"></md-tooltip>
                        </md-icon>
                    </md-button>
                </div>

                <!-- Warnings I guess -->
                <div>
                    <span md-colors="{color: 'red'}" ng-if="!selAdv.selectOptions.length" ng-i18next="checklists:AT_LEAST_ONE_OPTION"></span>
                </div>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button ng-click="selAdv.submit()" ng-disabled="selAdv.sameOptions || optionsForm.$invalid || !selAdv.selectOptions.length" ng-i18next="SUBMIT"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.checklists').factory('eawSelectAdvanced', [ '$mdDialog', function($mdDialog) {
    return function(item) {
        return $mdDialog.show({
            template: template1,
            controller: 'eawSelectAdvancedCtrl',
            controllerAs: 'selAdv',
            bindToController: true,
            locals: {
                item: { ...item },
            },
        });
    };
} ]);
