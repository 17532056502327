import { Component,
    DestroyRef,
    EventEmitter,
    inject,
    Inject,
    Input,
    OnInit,
    Output,
    signal,
    WritableSignal } from '@angular/core';
import { DateTime } from 'luxon';
import { PayTypeLink } from '../../../../shared/models/pay-type-link';
import { VariablePaymentOverview } from '../../../models/variable-payment-overview';
import { VariablePayment } from '../../../models/variable-payment';
import { VariablePaymentService } from '../../../http/variable-payment.service';
import { catchError, debounceTime, EMPTY, filter, firstValueFrom, of, take } from 'rxjs';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Employee } from '../../../../shared/models/employee';
import { PermissionCheckService } from '../../../../shared/services/permission-check.service';
import { DateTimePipe } from '../../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { VariablePaymentDisplayComponent } from '../../../components/variable-payment-display/variable-payment-display.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatInput } from '@angular/material/input';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommentDialogComponent,
    CommentDialogData } from '../../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Comment } from '../../../../shared/models/comment';

interface PaymentObject {
    payment: VariablePayment;
    canUpdate: boolean;
    canDelete: boolean;
    disabled: boolean;
    updating: boolean;
    control: FormControl<number | null>;
    commentControl: FormControl<string | null>;
}

type SectionDay = {
    day: DateTime,
    payments: PaymentObject[],
    total: number;
    // Indicates if we should show the creation input or not
    create?: boolean;
    editable?: boolean;
    pending?: boolean;
    commentValue: WritableSignal<string>,
    quantityValue: WritableSignal<string>,
};

@Component({
    selector: 'eaw-variable-payments-section',
    templateUrl: './variable-payments-section.component.html',
    styleUrl: './variable-payments-section.component.scss',
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        MatRippleModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        VariablePaymentDisplayComponent,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        CdkTextareaAutosize,
        MatInput,
        FormsModule,
    ],
})
/**
 * Used under manual payroll to display variable payments in the given period
 */
export class VariablePaymentsSectionComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);
    private readonly matDialog = inject(MatDialog);

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) from!: DateTime;
    @Input({ required: true }) to!: DateTime;
    @Input({ required: true }) overview!: VariablePaymentOverview;
    @Input({ required: true }) payTypeLink!: PayTypeLink;
    @Input({ required: true }) employee!: Employee;
    /**
     * **paytype** if we got our overview from paytype, **employee** if we got it from employee
     */
    @Input({ required: true }) mode!: 'employee' | 'paytype';

    @Output() totalChange = new EventEmitter<number>();

    days: SectionDay[] = [];
    // Does the user have permission to create a variable payment?
    canCreate = false;

    constructor(
        @Inject(VariablePaymentService) private variablePaymentService: VariablePaymentService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
    ) {
    }

    ngOnInit() {
        // Assign here because we need to wait for @Inputs
        this.canCreatePayment();

        this.createDays();
    }

    canCreatePayment() {
        this.permissionCheckService.isAllowedMany([
            `customers.${this.customerId}.employees.${this.employee.id}.variable_payments.create`,
            `customers.${this.customerId}.pay_type_links.${this.payTypeLink.id}.variable_payments.create`,
        ]).subscribe((can) => {
            this.canCreate = can;
        });
    }

    canHandlePayment(paymentId: number, handle: 'update' | 'delete') {
        return this.permissionCheckService.isAllowedMany([
            `customers.${this.customerId}.employees.${this.employee.id}.variable_payments.${handle}`,
            `customers.${this.customerId}.pay_type_links.${this.payTypeLink.id}.variable_payments.${paymentId}.${handle}`,
        ]);
    }

    async createDays() {
        let day = this.from;
        while (!day.minus({ day: 1 }).hasSame(this.to, 'day')) {
            const payments = this.mode === 'paytype' ? this.overview.get(this.employee.id, day) : this.overview.get(this.payTypeLink.id, day);
            const paymentObjects: PaymentObject[] = [];
            for await (const payment of payments) {
                paymentObjects.push(await this.createPaymentObject(payment));
            }

            this.days.push({
                day,
                total: 0,
                payments: paymentObjects,
                create: !payments.length,
                commentValue: signal(''),
                quantityValue: signal(''),
            });

            day = day.plus({ days: 1 });
        }

        this.days.forEach((day) => this.updateTotal(day));
    }

    async createPaymentObject(payment: VariablePayment) {
        const canUpdate = firstValueFrom(this.canHandlePayment(payment.id, 'update'));
        const canDelete = firstValueFrom(this.canHandlePayment(payment.id, 'delete'));
        const startValue = this.payTypeLink.returnUnit.useRateInput ? payment.rate : payment.quantity;
        const control = new FormControl(startValue);
        const commentControl = new FormControl('');

        if (!canUpdate) {
            control.disable();
            commentControl.disable();
        }

        control.valueChanges.pipe(
            debounceTime(1000),
            takeUntilDestroyed(this.destroyRef),
            filter(() => !payment.editable),
        ).subscribe((value) => {
            this.update(payment, value || 0);
        });

        return {
            payment,
            control,
            commentControl,
            disabled: false,
            updating: false,
            canUpdate: await canUpdate,
            canDelete: await canDelete,
        } satisfies PaymentObject;
    }

    updateTotal(day: SectionDay) {
        day.total = day.payments.reduce((total, payment) => total + payment.payment.sum, 0);

        this.totalChange.emit(Object.values(this.days).reduce((total, day) => total + day.total, 0));
    }

    getQuantity(value: number) {
        if (this.payTypeLink.returnUnit.defaultQuantity) {
            return this.payTypeLink.returnUnit.defaultQuantity;
        }

        return value;
    }

    getRate(value: number) {
        if (this.payTypeLink.returnUnit.defaultRate) {
            return this.payTypeLink.returnUnit.defaultRate;
        }

        return value;
    }

    create(day: SectionDay): void {
        const value = +day.quantityValue() || 0;
        const quantity = this.getQuantity(value);
        const rate = this.getRate(value);

        if (quantity && rate) {
            day.pending = true;
            this.variablePaymentService.create(this.customerId, this.employee.id, {
                business_date: day.day,
                pay_type_link_id: this.payTypeLink.id,
                ...(day.commentValue()) && { comment: day.commentValue() },
                quantity,
                rate,
            }).pipe(
                take(1),
                catchError(() => {
                    day.commentValue.set('');
                    day.quantityValue.set('');
                    day.pending = false;
                    return EMPTY;
                }),
            ).subscribe(async (payment) => {
                day.pending = false;
                day.create = false;
                day.editable = false;
                day.commentValue.set('');
                day.quantityValue.set('');
                day.payments.push(await this.createPaymentObject(payment));
                this.updateTotal(day);
            });
        }
    }

    update(payment: VariablePayment, value: number) {
        const day = this.days.find((d) => d.payments.map((p) => p.payment.id).includes(payment.id));
        const updatePayment = day?.payments.find((p) => p.payment.id === payment.id);

        if (!updatePayment) {
            return;
        }

        updatePayment.updating = true;
        this.variablePaymentService.update(this.customerId, this.employee.id, payment.id, {
            rate: this.getRate(value),
            quantity: this.getQuantity(value),
            ...(updatePayment.commentControl.value) && { comment: updatePayment.commentControl.value },
        }).pipe(
            take(1),
            catchError(() => {
                updatePayment.updating = false;
                updatePayment.disabled = false;
                updatePayment.control.reset(payment.sum);
                updatePayment.commentControl.reset('');
                return EMPTY;
            }),
        ).subscribe(async (updatedPayment) => {
            if (!day) {
                return;
            }
            day.payments.splice(day.payments.findIndex((p) => p.payment.id === payment.id), 1, await this.createPaymentObject(updatedPayment));

            this.updateTotal(day);
        });
    }

    remove(day: SectionDay, payment: PaymentObject) {
        payment.disabled = true;

        this.variablePaymentService.delete(this.customerId, this.employee.id, payment.payment.id).pipe(take(1)).subscribe(() => {
            day.payments = day.payments.filter((p) => p.payment.id !== payment.payment.id);
            // Enable create mode if we have no payments left so at least one input is visible
            day.create = !day.payments.length;

            this.updateTotal(day);
        });
    }

    deleteComment(payment: PaymentObject): void {
        if (!payment?.payment?.comments?.[0]) {
            return;
        }
        payment.disabled = true;
        this.variablePaymentService.deleteComment(this.customerId, this.employee.id, payment.payment.id, payment.payment.comments[0].id).pipe(
            take(1),
            catchError(() => {
                payment.disabled = false;
                payment.control = new FormControl(payment.control.value);
                return EMPTY;
            })).subscribe(() => {
            payment.payment.comments = [];
            payment.disabled = false;
            payment.control = new FormControl(payment.control.value);
        });
    }

    cancelComment(day: SectionDay): void {
        day.editable = false;
        day.commentValue.set('');
    }

    cancelPaymentComment(payment: VariablePayment, day: SectionDay): void {
        payment.editable = false;
        day.commentValue.set('');
    }

    openCommentsDialog(comments: Comment[]): void {
        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: { comments: of(comments) },
        });
    }
}
