import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { Customer, CustomerResponse } from '../../shared/models/customer';
import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface ExternalEmployeeResponse extends ApiResponse {
    id: number;
    employee_id: number;
    customer_id: number;
    cost?: number | null;
    number?: number | null;
    from: string;
    to?: string | null;
    customer?: CustomerResponse;
    employee?: EmployeeResponse;
    created_at?: string | null;
    updated_at?: string | null;
}

export class ExternalEmployee {
    id: number;
    employeeId: number;
    customerId: number;
    cost?: number | null;
    number?: number | null;
    from: DateTime;
    to?: DateTime | null;
    customer?: Customer;
    employee?: Employee;
    createdAt?: DateTime | null;
    updatedAt?: DateTime | null;

    constructor(data: ExternalEmployeeResponse) {
        this.id = data.id;
        this.employeeId = data.employee_id;
        this.customerId = data.customer_id;
        this.cost = data.cost;
        this.number = data.number;
        this.from = stringToDateTime(data.from);
        this.to = data.to ? stringToDateTime(data.to) : null;
        this.customer = data.customer ? new Customer(data.customer) : undefined;
        this.employee = data.employee ? new Employee(data.employee) : undefined;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
    }

    get isActive() {
        if (!this.from) {
            return false;
        }

        const now = DateTime.now();
        const fakeTo = this.to || now.plus({ year: 1 });
        return now >= this.from && now <= fakeTo;
    }
}
