import { inject, Injectable } from '@angular/core';
import { AbsenceType } from '../../absence/models/absence-type';
import { TranslateService } from '../services/translate.service';
import { map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { AbsenceTypeService } from '../../absence/http/absence-type.service';
import { Autocomplete } from './autocomplete';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { sort } from '../angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../services/current.service';

@Injectable({
    providedIn: 'root',
})
export class AbsenceTypeAutocompleteService extends Autocomplete<AbsenceType> {
    private getterObservables: Map<number, Observable<ArrayPaginatedResponse<AbsenceType>>> = new Map();

    absenceTypeService = inject(AbsenceTypeService);
    translate = inject(TranslateService);
    current = inject(CurrentService);

    constructor() {
        super({
            label: inject(TranslateService).t('ABSENCE_TYPE', 'absences'),
            display: 'translatedName',
            trackByKey: 'id',
        });
    }

    private getGetterObservable(id: number): Observable<ArrayPaginatedResponse<AbsenceType>> {
        const savedObservable = this.getterObservables.get(id);
        if (savedObservable) {
            return savedObservable;
        }

        const getterObservable = this.absenceTypeService.getAll('customers', id, {
            per_page: 100,
            only_creatable: true,
        }).pipe(shareReplay(1));

        this.getterObservables.set(id, getterObservable);
        return getterObservable;
    }

    setter(args: { id: number }) {
        return (item: unknown) => {
            if (item instanceof AbsenceType) {
                return of(item).pipe(
                    switchMap(async (absenceType) => {
                        absenceType.setTranslatedName(await this.translate.t(absenceType.name, 'absence_types'));
                        return absenceType;
                    }),
                );
            }

            if (typeof item !== 'number') {
                return of(undefined);
            }

            return this.absenceTypeService.getAbsenceType('customers', args.id, item);
        };
    }

    getter(args: { id: number}) {
        return (filter?: string) => {
            const lcFilter = filter?.toLowerCase() || '';

            return this.getGetterObservable(args.id).pipe(
                map((response) => {
                    const items = response.data.filter((x) => x.translatedName.toLowerCase().includes(lcFilter));

                    return {
                        ...response,
                        data: sort(items, this.current.languageTag, [ (x) => x.translatedName ], [ 'asc' ]),
                    };
                }),
            );
        };
    }
}
