// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';

module('eaw.checklists').factory('ChecklistsService', [ 'eawNumberAdvanced', 'eawSelectAdvanced', function(eawNumberAdvanced, eawSelectAdvanced) {
    const _weightMultiplier = 1000000;
    this._addingWeights = [];

    const _itemTypes = {
        boolean: {
            type: 'boolean',
            name: 'boolean',
            icon: 'check_box',
            text: t('checklists:CHECKBOX_TYPE'),
        },
        yesno: {
            type: 'select',
            name: 'yesno',
            icon: 'playlist_add_check',
            text: t('checklists:YES_NO_TYPE'),
            advanced: eawSelectAdvanced,
            defaultOptions: {
                selectOptions: [ t('YES'), t('NO'), t('checklists:DONT_KNOW') ],
                deviationOptions: [ 0, 0, 0 ],
            },
        },
        text: {
            type: 'text',
            name: 'text',
            icon: 'abc',
            text: t('checklists:TEXT_TYPE'),
        },
        number: {
            type: 'number',
            name: 'number',
            icon: '123',
            text: t('checklists:NUMBER_TYPE'),
            advanced: eawNumberAdvanced,
            advancedText: t('ADVANCED'),
        },
        select: {
            type: 'select',
            name: 'select',
            icon: 'playlist_add_check',
            text: t('checklists:SELECT_TYPE'),
            advanced: eawSelectAdvanced,
            advancedText: t('checklists:CHANGE_OPTIONS'),
        },
        file: {
            type: 'file',
            name: 'file',
            icon: 'image',
            text: t('checklists:FILE_TYPE'),
        },
    };

    this.isCategory = (item) => Array.isArray(item.children);

    this.hasAdvanced = (type) => typeof _itemTypes?.[type]?.advanced === 'function';

    this.roundWeight = (weight, mathFunction) => {
        if (!(mathFunction === 'ceil' || mathFunction === 'floor')) {
            mathFunction = 'round';
        }

        return Math[mathFunction](weight);
    };

    this.findWeight = (location, aboveWeight, belowWeight) => {
        location = location || {};
        let newWeight = null;

        // Moving item to the bottom
        if (typeof aboveWeight === 'number' && typeof belowWeight !== 'number') {
            newWeight = this.roundWeight(aboveWeight + 1, 'ceil');
        }

        // Moving item to the top
        if (typeof aboveWeight !== 'number' && typeof belowWeight === 'number') {
            newWeight = this.roundWeight(belowWeight - 1, 'floor');
        }

        // We have weights to calculate from
        if (typeof aboveWeight === 'number' && typeof belowWeight === 'number') {
            // Create array of possible weights divisible with our weight multiplier
            const weights = [];
            const from = this.roundWeight(aboveWeight, 'floor');
            const to = this.roundWeight(belowWeight, 'ceil');

            for (let i = from; i <= to; i += _weightMultiplier) {
                if (i > aboveWeight && i < belowWeight) {
                    weights.push(i);
                }
            }

            // If no divisible weights found, divide by two so it's placed in the middle
            newWeight = weights.length ? weights[0] : (aboveWeight + belowWeight) / 2;
        }

        // Adding item
        if (typeof aboveWeight !== 'number' && typeof belowWeight !== 'number') {
            const currentAddingMax = Math.max(...this._addingWeights);
            const currentMax = Math.max(...(location?.children || []).map((c) => c.weight), currentAddingMax);
            newWeight = Number.isFinite(currentMax) ? this.roundWeight(currentMax + 1, 'ceil') : 0;
            this._addingWeights.push(parseInt(newWeight));
        }

        return parseInt(newWeight);
    };

    this.cleanAddedWeight = (item) => {
        const length = this._addingWeights.length;
        const last = length - 1;
        const index = this._addingWeights.indexOf(item.weight);

        // If we try to remove the last item in an array with more than just that single item
        // things might not work as expected, so don't do it
        if (index === last && length > 1) {
            return;
        }

        this._addingWeights.splice(index, 1);
    };

    this.getTypes = () => {
        return { ..._itemTypes };
    };

    this.getType = (type) => type == null ? { icon: 'view-list' } : { ..._itemTypes[type] };

    /**
     * Find all categories in a checklist and return an object with the IDs as keys and its items
     * @param obj
     * @param categories
     * @returns {*|{}}
     */
    this.findCategories = (obj, categories = {}) => {
        categories = categories || {};

        if (!obj.parent_id) { // This is the top, whole checklist
            categories[0] = { // Add top as a category object
                parent: undefined,
                items: obj.children.filter((o) => !o.isCategory),
            };
        }

        if (obj.isCategory) {
            categories[obj.id] = { // Add category object
                parent: obj.parent_type === 'checklist' ? 0 : obj.parent_id, // Checklist is always zero, if not id of parent category
                items: (obj?.children || []).filter((o) => !o.isCategory),
            };
        }

        if (obj.children) {
            obj.children.forEach((child) => {
                this.findCategories(child, categories);
            });
        }

        return categories;
    };

    this.updatePercentages = (checklist) => {
        const checklistCopy = Object.assign({}, checklist);
        const categories = this.findCategories(checklistCopy);
        const ids = Object.keys(categories).reverse();

        ids.forEach((id) => {
            const cat = categories[id];

            if (cat.parent != null) {
                categories[cat.parent].items.push(...cat.items);
            }
        });

        Object.keys(categories).forEach((key) => {
            const cat = categories[key];

            cat.complete = cat.items.filter((i) => {
                if (typeof i === 'string') {
                    return i.length > 0;
                } else {
                    return i.data != null;
                }
            }).length;

            cat.percentage = cat.complete / cat.items.length;
        });

        this.percentages = categories;
    };

    return this;
} ]);
