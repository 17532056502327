import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Directory } from '../../models/directory';
import { DirectoryService } from '../../services/directory.service';
import { CurrentService } from '../../../shared/services/current.service';
import { MoveItem } from './move-item';
import { orderBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { FsNode } from '../../models/fs-node';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-move-item',
    templateUrl: './move-item.component.html',
    styleUrl: './move-item.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatListModule,
        NgFor,
        NgClass,
        MatIconModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class MoveItemComponent extends DialogComponent implements OnInit {
    startDirectory: Directory;
    directory: Directory;
    gettingDirectory!: boolean;
    isStartDirectory!: boolean;
    item!: FsNode;
    sub?: Subscription;
    selectedDirectory?: Directory;

    constructor(
      @Inject(MatDialogRef) dialogRef:MatDialogRef<MoveItemComponent>,
      @Inject(MAT_DIALOG_DATA) data:MoveItem,
      @Inject(DirectoryService) protected directoryService: DirectoryService,
      @Inject(CurrentService) protected current:CurrentService) {
        super(dialogRef, data);
        this.startDirectory = data.startDirectory;
        this.directory = data.startDirectory;
        this.item = data.item;
    }

    ngOnInit(): void {
        this.getDirectory(this.startDirectory.id);
    }

    move() {
        this.dialogRef.close(this.directory);
    }

    trackByFn(_: number, item: FsNode) {
        return item.id;
    }

    isMoveItem(dir:FsNode | undefined) {
        if (!dir) {
            return;
        }
        return dir?.id === this.item.id;
    }

    /**
   * TypeScript gets angry if you don't include as HTMLInputElement when trying to call classList on element/target.
   * Alternatively you can disable strictDomEventTypes if you
   * think typescript is to mean(which is less than ideal)
   * https://stackoverflow.com/questions/42066421/property-value-does-not-exist-on-type-eventtarget
   * @param directory
   * @param event
   */
    selectDirectory(directory:Directory, event:Event): undefined {
        const element = event.currentTarget as HTMLInputElement;

        if (element.classList.contains('disabled')) {
            return;
        }

        const dirItems = document.querySelectorAll('.directory-element.directory');
        for (let i = 0; i < dirItems.length; i++) {
            dirItems[i]?.classList.remove('selected');
        }

        if (this.selectedDirectory?.id === directory.id) {
            delete this.selectedDirectory;
        } else {
            this.selectedDirectory = directory;
            element.classList.add('selected');
        }
    }

    getDirectory(id: number | undefined): void {
        if (!id || this.gettingDirectory) {
            return;
        }

        this.gettingDirectory = true;

        this.directoryService.getDirectory(this.current.getCustomer().id, id, [ 'files.attachments', 'directories' ]).subscribe({
            next: (directory) => {
                this.directory = directory;
                this.directory.directories = orderBy(this.directory.directories, [ 'name' ], 'asc');
                this.directory.files = orderBy(this.directory.files, [ 'name' ], [ 'asc' ]);
                this.isStartDirectory = directory.id === this.startDirectory.id;
                this.gettingDirectory = false;
            },
            error: () => {
                this.gettingDirectory = false;
            },
        });
    }

    getItemName(item: FsNode): string {
        return `<i>${item.name}</i>`;
    }
}
