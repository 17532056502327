import { extend } from 'angular';

export class EawDialogController {
    static get $inject() {
        return [ 'locals', '$mdDialog' ];
    }

    constructor(locals: any, private $mdDialog: any) {
        extend(this, locals);
    }

    cancel(reason: any) {
        this.$mdDialog.cancel(reason);
    }

    close(result: any) {
        this.$mdDialog.hide(result);
    }
}
