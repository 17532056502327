import { MatFormFieldModule } from '@angular/material/form-field';
import { WidgetPropertySettings } from '../../../classes/widget';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { WidgetSettingsDialogComponent } from '../../widget-settings-dialog/widget-settings-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgFor } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { WidgetSettingsDialog } from '../../../classes/widget-settings-dialog';
import { TranslateService } from '../../../../shared/services/translate.service';

export interface VacantShiftsWidgetSettings extends WidgetPropertySettings {
    day_offset: 0 | 1;
}

interface Day {
    value: VacantShiftsWidgetSettings[ 'day_offset' ];
    text: Promise<string>;
}

@Component({
    selector: 'eaw-vacant-shifts-widget-settings-dialog',
    templateUrl: './vacant-shifts-widget-settings-dialog.component.html',
    styleUrl: './vacant-shifts-widget-settings-dialog.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        WidgetSettingsDialogComponent,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgFor,
        MatOptionModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class VacantShiftsWidgetSettingsDialogComponent extends WidgetSettingsDialog<VacantShiftsWidgetSettings> {
    private readonly translate = inject(TranslateService);

    private readonly today: Day = { value: 0, text: this.translate.t('TODAY') };
    private readonly tomorrow: Day = { value: 1, text: this.translate.t('TOMORROW') };

    days = signal<Day[]>([ this.today, this.tomorrow ]);
    day = new FormControl(this.today, { nonNullable: true });

    constructor() {
        super();
    }

    submitted() {
        this.dialogRef.close({
            day_offset: this.day.value.value,
        });
    }
}
