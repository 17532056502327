import { ChangeDetectionStrategy, Component, inject, Input, Signal, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, AsyncPipe, NgClass } from '@angular/common';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { DateTime } from 'luxon';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { FieldErrorComponent } from '../../../shared/components/field-error/field-error.component';
import { EawValidators } from '../../../shared/validators/eaw-validators';
import { SignalInput } from '../../../shared/decorators/signal-input.decorator';
import { CustomerAvailabilityOverviewTableComponent } from '../../components/customer-availability-overview-table/customer-availability-overview-table.component';

interface AvailabilityOverviewForm {
    from: FormControl<DateTime | null>;
    weeks: FormControl<number | null>;
}

@Component({
    selector: 'eaw-availability-overview',
    templateUrl: './availability-overview.component.html',
    styleUrl: './availability-overview.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatTableModule,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        MatProgressSpinnerModule,
        AsyncPipe,
        TranslatePipe,
        ActionButtonComponent,
        DateTimePipe,
        NgClass,
        FieldErrorComponent,
        CustomerAvailabilityOverviewTableComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityOverviewComponent {
    private queryParamsService = inject(QueryParamsService);

    @Input({ required: true }) @SignalInput() customerId!: Signal<number>;

    form: FormGroup<AvailabilityOverviewForm>;
    loading = signal(false);
    minDate = signal(DateTime.now().startOf('week'));
    from = signal(DateTime.now().startOf('week'));
    weeks = signal(1);
    showFilter = signal(true);

    constructor() {
        this.from.set(this.queryParamsService.get('from', 'date') || this.minDate());
        this.weeks.set(this.queryParamsService.get('weeks', 'number') || this.weeks());

        this.form = new FormGroup<AvailabilityOverviewForm>({
            from: new FormControl(this.from(), Validators.required),
            weeks: new FormControl(this.weeks(), [ Validators.required, Validators.min(1), Validators.max(4), EawValidators.integer() ]),
        });
    }

    updateOverview() {
        const { from, weeks } = this.form.value;
        if (from && weeks) {
            this.queryParamsService.set([
                { key: 'from', value: from, type: 'date' },
                { key: 'weeks', value: weeks },
            ]);

            this.from.set(from);
            this.weeks.set(weeks);
        }
    }
}
