import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CurrentService } from '../services/current.service';
import { NumberFormatterService } from '../services/number-formatter.service';

@Pipe({
    name: 'unit',
    standalone: true,
})
export class UnitPipe implements PipeTransform {
    constructor(
      @Inject(CurrentService) private current: CurrentService,
      @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
    ) {
    }

    /**
     * Transforms a number to the given unit based on allowed units for ECMAScript
     * @see https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
     */
    transform(value: unknown, unit: string, display: Intl.NumberFormatOptions['unitDisplay'], decimals = 2, options?: Intl.NumberFormatOptions): string {
        return this.numberFormatterService.format(this.current.languageTag, value, {
            style: 'unit',
            unitDisplay: display,
            unit,
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
            ...options,
        });
    }
}
