import { module } from 'angular';
import { createState } from '../../../shared/utils/create-state';
import { dependencyResolver } from '../../../shared/resolvers/dependency.resolver';
import {PredefinedSettings} from '../../../shared/enums/predefined-settings';

module('eaw.payroll.punch-clock', [
    'ngResource',
    'ngFileUpload',
    'eaw.misc',
    'eaw.alerts',
    'eaw.login',
    'eaw.resource',
    'eaw.customers',
    'eaw.company',
]).config([ '$stateProvider', ($stateProvider: any) => {

    createState($stateProvider, {
        name: `eaw/app/payroll/punch_clock`,
        parent: `eaw/app/payroll`,
        url: '/clock',
        views: {
            'content@': 'punchClock',
        },
        data: {
            breadcrumb: { key: 'PUNCH_CLOCK', ns: 'navigation' },
            requiresEmployee: true,
            allowExternal: true,
            permissions: [
                'customers.{customer}.employees.{employee}.timepunches.punch',
                'customers.{customer}.employees.{employee}.timepunches.*.get',
            ],
            settings: {
                [PredefinedSettings.PayrollPersonalTpApp]: (decoder) => decoder?.asBoolean() === true,
            },
        },
        resolve: [
            dependencyResolver('activeTimepunch', [ 'PunchClock', 'customer', 'employee' ], function(PunchClock, customer, employee) {
                return PunchClock.activeTimepunch(customer.id, employee.id).$promise.catch(() => null);
            }),
        ],
    });
} ]);
