import { debounce } from 'lodash-es';

import moment from 'moment-timezone';

import { WebsocketService } from '../../../../../../shared/services/websocket.service';
import { AngularJsDestroyRef } from 'src/app/shared/angularjs/angularjs-destroy-ref.class';
import { module } from 'angular';

const template1 = `<warnings-list warnings="dwCtrl.warnings" fields="['infobtn', 'employee', 'message']"></warnings-list>

`;

module('eaw.scheduling').component('sidebarDayWarnings', {
    template: template1,
    controllerAs: 'dwCtrl',
    bindings: {
        customer: '<',
        schedule: '<',
        days: '<',
    },
    require: {
        sidebar: '^scheduleSidebar',
    },
    controller: [ 'WebsocketDowngrade', 'scheduleTable', '$scope', 'shiftEvents', '$timeout', function(WebsocketDowngrade: WebsocketService, scheduleTable, $scope, shiftEvents, $timeout) {
        // @ts-ignore
        const ctrl = this;
        const destroyRef = new AngularJsDestroyRef();


        ctrl.$onInit = () => {
            const refreshWarningsDebounced = debounce(ctrl.refreshWarnings, 500);
            ctrl.day = scheduleTable.getCurrentDay();

            $scope.$on('sidebarchildren:dayChanged', ctrl.refreshWarnings);
            shiftEvents.register.onChange($scope, refreshWarningsDebounced);
            shiftEvents.register.onLoaded($scope, refreshWarningsDebounced);

            WebsocketDowngrade.listenSchedule(ctrl.schedule.customer_id, ctrl.schedule.id, 'warning_added', refreshWarningsDebounced, destroyRef);
            WebsocketDowngrade.listenSchedule(ctrl.schedule.customer_id, ctrl.schedule.id, 'warnings_cleared', refreshWarningsDebounced, destroyRef);

            ctrl.refreshWarnings();
        };

        ctrl.$onDestroy = () => {
            destroyRef.destroy();
        }

        ctrl.refreshWarnings = () => {
            const day = ctrl.sidebar.day;
            if (moment.isMoment(day.moment)) {
                $timeout(() => {
                    ctrl.warnings = ctrl.schedule.warningsOn(day.moment);
                });
            }
        };
    } ],
});
