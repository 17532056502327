import { Inject, Injectable } from '@angular/core';
import { Autocomplete } from './autocomplete';
import { TranslateService } from '../services/translate.service';
import { of } from 'rxjs';
import { Stack } from '../models/stack';
import { StackService } from '../http/stack.service';

@Injectable({
    providedIn: 'root',
})
export class StackAutocompleteService extends Autocomplete<Stack> {

    constructor(
        @Inject(StackService) protected stackService: StackService,
        @Inject(TranslateService) protected translateService: TranslateService,
    ) {
        super({
            label: translateService.t('STACK', 'admin'),
            optionTemplate: 'default',
            filterRequirement: 3,
            display: 'name',
            trackByKey: 'id',
        });
    }

    setter() {
        return (item: unknown) => {
            if (item instanceof Stack) {
                return of(item);
            }
            if (typeof item !== 'number') {
                return of(undefined);
            }

            return this.stackService.get(item);
        };
    }

    getter() {
        return (filter?: string) => {
            return this.stackService.getAll({
                filter,
                per_page: 9999,
                order_by: 'name',
                direction: 'asc',
            });
        };
    }
}
