// @ts-nocheck
import { module } from 'angular';
import { EawDialogController } from './eaw-dialog-controller';

/**
 * @ngdoc service
 * @name eaw\.dialogs.service.EawDialogController
 */
module('eaw.dialogs').service('EawDialogController', function() {
    return EawDialogController;
});
