import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { PermissionPipe } from '../../../shared/pipes/permission.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ShiftPeriod } from '../../models/shift-period';
import { DateTime } from 'luxon';
import { ShiftPeriodService } from '../../http/shift-period.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { catchError, EMPTY, filter } from 'rxjs';
import { AddEditShiftPeriodDialogComponent, AddEditShiftPeriodDialogData, AddEditShiftPeriodDialogReturn } from '../../dialogs/add-edit-shift-period-dialog/add-edit-shift-period-dialog.component';
import { MatDialog } from '@angular/material/dialog';

interface PeriodItem {
    hasUnit: boolean;
    period: ShiftPeriod,
    from: string,
    to: string,
    backgroundColor: string,
    textColor: string,
    darkness: 'dark' | 'light',
    description: Promise<string>,
    processing?: boolean;
}

@Component({
    selector: 'eaw-shift-period-list',
    standalone: true,
    imports: [ CommonModule, MatButtonModule, MatIconModule, MatListModule, PermissionPipe, TranslatePipe, InfoLoadingComponent ],
    templateUrl: './shift-period-list.component.html',
    styleUrl: './shift-period-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftPeriodListComponent {
    private shiftPeriodService = inject(ShiftPeriodService);
    private translateService = inject(TranslateService);
    private matDialog = inject(MatDialog);

    customerId = input.required<number>();
    scheduleId = input.required<number>();
    shiftId = input.required<number>();
    /**
     * The periods we will display in this list.
     */
    periods = input.required<ShiftPeriod[]>();
    /**
     * If true, the component will only display the periods and not allow any actions
     */
    displayOnly = input<boolean>(false);

    periodEdited = output<ShiftPeriod>();
    periodDeleted = output<number>();

    protected periodItems = computed(() => sort(this.periods().map(this.toPeriodItem.bind(this)), 'en', [ (x) => x.period.offset ]));

    protected toPeriodItem(period: ShiftPeriod): PeriodItem {
        const unit = period.businessUnit;
        const backgroundColor = unit ? unit.color: period.color;
        const description = unit ? unit.name: period.description;

        return {
            hasUnit: !!unit,
            period,
            from: period.from.toLocaleString(DateTime.TIME_SIMPLE),
            to: period.to.toLocaleString(DateTime.TIME_SIMPLE),
            backgroundColor: backgroundColor.toHexString(),
            darkness: backgroundColor.isDark() ? 'dark' : 'light',
            textColor: backgroundColor.isDark() ? '#fff' : '#000',
            description: description ? Promise.resolve(description) : this.translateService.t('NO_DESCRIPTION'),
        };
    }

    protected editPeriod(periodId: number) {
        this.matDialog.open<AddEditShiftPeriodDialogComponent, AddEditShiftPeriodDialogData, AddEditShiftPeriodDialogReturn>(AddEditShiftPeriodDialogComponent, {
            data: {
                customerId: this.customerId(),
                scheduleId: this.scheduleId(),
                shiftId: this.shiftId(),
                periodId,
            },
        }).afterClosed().pipe(
            filter((period) => !!period),
        ).subscribe((period) => this.periodEdited.emit(period));
    }

    protected deletePeriod(periodItem: PeriodItem) {
        periodItem.processing = true;

        this.shiftPeriodService.delete(this.customerId(), this.scheduleId(), this.shiftId(), periodItem.period.id).pipe(
            catchError(() => {
                periodItem.processing = false;
                return EMPTY;
            }),
        ).subscribe(() => this.periodDeleted.emit(periodItem.period.id));
    }
}
