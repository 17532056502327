import { MeUser } from './me-user';
import { ApiResponse } from '../interfaces/api-response';
import { BaseApiModel } from './base-api-model';
import { SimplePermission } from '../interfaces/simple-permission';

export interface MeUserResponse extends ApiResponse {
    country_code?: number | string | null,
    created_at?: string | null,
    email: string;
    id: number,
    permissions: Record<string, SimplePermission>,
    language_code: string;
    last_active?: string,
    last_name: string,
    name: string,
    first_name: string,
    phone?: string | null,
    profile_picture_id?: number | null,
    updated_at?: string | null;
}

export interface MeCustomer extends ApiResponse {
    api_url: string,
    employee_id: number | null,
    id: number,
    name: string,
    number: string,
    setting_group_id: number,
    stack_id: number,
}

export interface MeResponse extends ApiResponse {
    accepted_tos: boolean,
    authed_as: number,
    customers: Record<number, MeCustomer>,
    user: MeUserResponse,
}

export class Me extends BaseApiModel<MeResponse, Me> {
    readonly isActing: boolean;
    acceptedTos = false;
    authedAs: number;
    customers: Record<number, MeCustomer>;
    user: MeUser;

    constructor(data: MeResponse) {
        super(data, undefined);

        this.acceptedTos = data.accepted_tos;
        this.authedAs = data.authed_as;
        this.user = new MeUser(data.user);
        this.customers = data.customers;
        this.isActing = this.authedAs !== this.user.id;
    }
}
