import { DateTime } from 'luxon';
import { inject, Injectable } from '@angular/core';
import { pick } from 'lodash-es';
import { mergeMap } from 'rxjs/operators';
import { map, Observable, of } from 'rxjs';
import { PaginationOptions } from '../interfaces/pagination-options';
import { UserGroup, UserGroupResponse } from '../../company/models/user-group';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { HttpClient, HttpContext } from '@angular/common/http';
import { User, UserResponse } from '../models/user';
import { ApiModel } from '../enums/api-model';
import { GroupMembership, GroupMembershipResponse } from '../models/group-membership';
import { formatHttpParams } from '../utils/format-http-params';

export type AddMemberArgs = {
    group: { owner_id: number, id: number },
    member_id: number,
    from: DateTime| null,
    to?: DateTime | null
};

interface GetAllOptions extends PaginationOptions {
    user_id?: number;
    responsible?: number;
    permissions?: boolean;
}

interface GetMembersOptions extends PaginationOptions {
    display_inactive_users?: boolean;
}

@Injectable({
    providedIn: 'any',
})
export class UserGroupService {
    private http = inject(HttpClient);

    create(customerId: number, data: { name: string }) {
        return this.http.post<UserGroupResponse>(`/customers/${customerId}/user_groups`, data).pipe(classifyItem(UserGroup));
    }

    update(customerId: number, groupId: number, data: { name: string }) {
        return this.http.put<UserGroupResponse>(`/customers/${customerId}/user_groups/${groupId}`, data).pipe(classifyItem(UserGroup));
    }

    delete(customerId: number, groupId: number) {
        return this.http.delete<void>(`/customers/${customerId}/user_groups/${groupId}`);
    }

    get(customerId: number, groupId: number, options?: {with: string[]}) {
        return this.http.get<UserGroupResponse>(`/customers/${customerId}/user_groups/${groupId}`, {
            params: formatHttpParams({
                'with[]': options?.with,
            }),
        }).pipe(classifyItem(UserGroup));
    }

    getMembers(customerId: number, groupId: number, options?: GetMembersOptions): Observable<ArrayPaginatedResponse<GroupMembership<User, UserResponse>>> {
        return this.http.get<ArrayPaginatedResponse<UserResponse>>(`/customers/${customerId}/user_groups/${groupId}/members`, {
            params: {
                ...options,
            },
        }).pipe(
            map((response) => {
                return {
                    ...response,
                    data: response.data.map((item) => {
                        return new GroupMembership<User, UserResponse>({
                            // Id and from should come from the pivot object
                            from: '',
                            group_id: groupId,
                            member_id: item.id,
                            member_type: ApiModel.User,
                            // @ts-ignore
                            ...item['pivot'],
                            member: item,
                        }, User);
                    }),
                };
            }),
        );
    }

    addMember(membership: AddMemberArgs, context?: HttpContext) {
        return this.http.post<UserResponse>(`/customers/${membership.group.owner_id}/user_groups/${membership.group.id}/members`, {
            ...pick(membership, [ 'member_id', 'from', 'to' ]),
        }, { context }).pipe(classifyItem(User));
    }

    addMembers(memberships: AddMemberArgs[], options?: HttpContext) {
        if (!memberships?.length) {
            throw Error('Empty array passed to addMembers');
        }

        return of(...memberships).pipe(mergeMap((membership) => {
            return this.addMember(membership, options);
        }, 4));
    }

    /**
     * @deprecated
     * @see updateMembership
     */
    updateMember(ownerId: number, groupId: number, memberId: number, data: { from?: DateTime, to?: DateTime | null }) {
        return this.http.put<UserResponse>(`/customers/${ownerId}/user_groups/${groupId}/members/${memberId}`, data).pipe(classifyItem(User));
    }

    updateMembers(groups: UserGroup[], memberId: number, data: { from?: DateTime, to?: DateTime | null }) {
        if (!groups?.length) {
            throw Error('Empty array passed to updateMembers');
        }

        return of(...groups).pipe(mergeMap((group) => {
            if (!group.pivot.id) {
                return this.updateMember(group.ownerId, group.id, memberId, data);
            }
            return this.updateMembership(group.ownerId, group.id, memberId, group.pivot.id, data);
        }, 4));
    }

    updateMembership(ownerId: number, groupId: number, memberId: number, membershipId: number, data: { from?: DateTime, to?: DateTime | null }) {
        return this.http.put<GroupMembershipResponse<UserResponse>>(`/customers/${ownerId}/user_groups/${groupId}/members/${memberId}/memberships/${membershipId}`, data)
            .pipe(map((response) => {
                return new GroupMembership(response, User);
            }));
    }

    removeMembership(ownerId: number, groupId: number, memberId: number, membershipId: number) {
        return this.http.delete<undefined>(`/customers/${ownerId}/user_groups/${groupId}/members/${memberId}/memberships/${membershipId}`);
    }

    /**
     *
     * @deprecated
     * @see removeMembership
     */
    removeMember(ownerId: number, groupId: number, memberId: number) {
        return this.http.delete<undefined>(`/customers/${ownerId}/user_groups/${groupId}/members/${memberId}`);
    }

    removeMembers(groups: UserGroup[], memberId: number) {
        if (!groups?.length) {
            throw Error('Empty array passed to removeMembers');
        }

        return of(...groups).pipe(mergeMap((group) => {
            if (!group.pivot.id) {
                return this.removeMember(group.ownerId, group.id, memberId);
            }
            return this.removeMembership(group.ownerId, group.id, memberId, group.pivot.id);
        }, 4));
    }

    getAllForCustomer(customerId: number, options?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<UserGroupResponse>>(`/customers/${customerId}/user_groups`, {
            params: {
                ...options,
            },
        }).pipe(classifyArrayPaginatedResponse(UserGroup));
    }
}
