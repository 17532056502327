import { Component, Inject, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { PayTypeLinkService } from '../../../shared/http/pay-type-link.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { PayTypeLink } from '../../../shared/models/pay-type-link';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { firstValueFrom, forkJoin, map, mergeMap, Observable, of, reduce, startWith, switchMap, take, tap } from 'rxjs';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../shared/services/current.service';
import { EmployeeService } from '../../../shared/http/employee.service';
import { Employee } from '../../../shared/models/employee';
import { VariablePaymentService } from '../../http/variable-payment.service';
import { VariablePaymentOverview } from '../../models/variable-payment-overview';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { VariablePaymentsSectionComponent } from './variable-payments-section/variable-payments-section.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProfilePictureComponent } from '../../../shared/components/profile-picture/profile-picture.component';
import { VariablePaymentDisplayComponent } from '../../components/variable-payment-display/variable-payment-display.component';
import { InfoBoxComponent } from '../../../shared/components/info-card/info-box.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';
import { PermissionsInputValue } from '../../../permissions/services/element-permission.service';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

type Mode = 'paytype' | 'employee';

interface OverviewResult {
    from: DateTime;
    to: DateTime;
    overview: VariablePaymentOverview;
    total: number;
    payTypeLink?: PayTypeLink;
    employee?: Employee;
}

@Component({
    selector: 'eaw-manual-payroll',
    templateUrl: './manual-payroll.component.html',
    styleUrl: './manual-payroll.component.scss',
    standalone: true,
    imports: [
        NgIf,
        PageHeaderComponent,
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        InfoBoxComponent,
        VariablePaymentDisplayComponent,
        ProfilePictureComponent,
        MatExpansionModule,
        VariablePaymentsSectionComponent,
        AsyncPipe,
        TranslatePipe,
        PermissionDirective,
        TranslateSyncPipe,
    ],
})
export class ManualPayrollComponent implements OnInit {
    @Input({ required: true }) customerId!: number;
    // If set, we are using this page for just a single employee
    @Input() employee?: Employee | Observable<Employee>;

    readonly defaultFrom = DateTime.now().startOf('month');
    readonly defaultTo = DateTime.now().endOf('month');

    modes: { value: Mode, label: string, can: boolean }[] = [];
    // The name of what we are fetching, a pay type or employee
    fetchingName = '';
    // Are we fetching the initial data?
    fetchingInitialData = true;
    // Are we fetching the overview?
    fetchingOverview = false;
    overview?: OverviewResult | null;
    // A simple record of totals for employees when using pay type, employee ID is the key, and total is value
    employeeTotals: Record<number, number> = {};
    // A simple record of totals for pay type links, link ID is the key, and total is value
    payTypeLinkTotals: Record<number, number> = {};
    // Show hint if interval is too small
    intervalTooSmall = false;
    formGroup = new FormGroup({
        mode: new FormControl<Mode>('paytype', { nonNullable: true }),
        dateRange: new FormGroup({
            from: new FormControl<DateTime | null>(this.defaultFrom, Validators.required),
            to: new FormControl<DateTime | null>(this.defaultTo, Validators.required),
        }),
        payType: new FormControl<PayTypeLink | string | null>(null),
        employee: new FormControl<Employee | string | null>(null),
    });

    filteredPayTypeLinks: Observable<PayTypeLink[]> = of([]);
    payTypeLinks: PayTypeLink[] = [];
    filteredEmployees: Observable<Employee[]> = of([]);
    employees: Employee[] = [];
    permissions: Map<number, WritableSignal<PermissionsInputValue | undefined>> = new Map();

    constructor(
        @Inject(PayTypeLinkService) private payTypeLinkService: PayTypeLinkService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(VariablePaymentService) private variablePaymentService: VariablePaymentService,
        @Inject(QueryParamsService) private searchParamsService: QueryParamsService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
    ) {
    }

    ngOnInit() {
        this.formGroup.disable();

        this.handleFormChange();
        this.setFilteredEmployees();
        this.setFilteredPayTypeLinks();
        this.getInitialData();
    }

    setFilteredPayTypeLinks() {
        this.filteredPayTypeLinks = this.formGroup.controls.payType.valueChanges.pipe(
            startWith(''),
            map((value) => {
                if (value instanceof PayTypeLink) {
                    return this.payTypeLinks;
                }

                return this.payTypeLinks.filter((p) => {
                    const filter = value?.toLowerCase() || '';
                    return p.name.toLowerCase().includes(filter);
                });
            }),
        );
    }

    setFilteredEmployees() {
        this.filteredEmployees = this.formGroup.controls.employee.valueChanges.pipe(
            startWith(''),
            map((value) => {
                if (value instanceof Employee) {
                    return this.employees;
                }

                return this.employees.filter((e) => {
                    const filter = value?.toLowerCase() || '';
                    return e.name?.toLowerCase().includes(filter);
                });
            }),
        );
    }

    handleFormChange() {
        this.formGroup.valueChanges.subscribe((value) => {
            this.intervalTooSmall = false;

            if (!value.dateRange?.from || !value.dateRange?.to) {
                return;
            }

            if (value.dateRange.from.hasSame(value.dateRange.to, 'day')) {
                this.intervalTooSmall = true;
                return;
            }

            if (this.formGroup.controls.mode.value === 'paytype') {
                this.getPayTypeOverview();
            } else {
                this.getEmployeeOverview();
            }
        });
    }

    updateEmployeeTotals(employeeId: number, value: number) {
        this.employeeTotals[employeeId] = value;

        if (this.overview) {
            this.overview.total = Object.values(this.employeeTotals).reduce((acc, total) => acc + total, 0);
        }
    }

    getInitialData() {
        const from = this.formGroup.controls.dateRange.controls.from.value || undefined;
        const to = this.formGroup.controls.dateRange.controls.to.value || undefined;

        // Observable to get the provided employee from the @Input
        const employee = (this.employee instanceof Employee ? of(this.employee) : this.employee)?.pipe(
            map((employee) => {
                // Change customerId in case the provided employee is from another customer
                this.customerId = employee.customerId;

                // Return an array since that's what's expected
                return [ employee ];
            }),
        );

        // Observable to get all employees
        const employees = expandAllPages((options) => this.employeeService.getAll(this.customerId, options), {
            per_page: 50,
            from,
            to,
        });

        // Don't get all employees if a specific employee is provided in the input
        (employee || employees).pipe(
            tap((empResp) => {
                // Set the fetched employees
                this.employees = sort(empResp, this.current.languageTag, [ (e) => e.name ], [ 'asc' ]);
                // Filter employees based on permission to get variable_payments for each employee
                this.employees.forEach((emp) => {
                    this.permissions.set(emp.id, signal([ `customers.${this.customerId}.employees.${emp.id}.variable_payments.*.get` ]));
                });
            }),
            switchMap(() => {
                // Get all pay type links after the customer id has been updated
                return expandAllPages((options) => this.payTypeLinkService.getAll(this.customerId, options), {
                    per_page: 50,
                    manual: true,
                    from,
                    to,
                    'with[]': [ 'properties' ],
                });
            }),
            tap((payTypeLinkResp) => {
                // Set all the pay type links
                this.payTypeLinks = sort(payTypeLinkResp, this.current.languageTag, [ (p) => p.name ], [ 'asc' ]);
            }),
        ).subscribe(this.onInitialDataFetched.bind(this));
    }

    async onInitialDataFetched() {
        // Enable has to be before modes
        this.formGroup.enable();

        // Modes has to come before updateFormSearchParams
        await this.updateModes();

        // This has to come after modes
        this.updateFormSearchParams();
    }

    async updateModes() {
        await firstValueFrom(forkJoin([
            this.permissionCheckService.permissionOrChildren(`customers.${this.customerId}.employees.*.variable_payments.create`, undefined, `customers.${this.customerId}.employees`, 'variable_payments.create', true),
            of(...this.employees).pipe(
                mergeMap((e: Employee) => {
                    const permission = `customers.${this.customerId}.employees.${e.id}.variable_payments.*.get`;
                    // TODO: Add stack id (we need the customer object)
                    return this.permissionCheckService.isAllowed(permission).pipe(map((can) => can ? e : null));
                }),
                reduce((acc, employee: Employee | null) => {
                    if (employee) {
                        acc.push(employee);
                    }

                    return acc;
                }, [] as Employee[]),
            ),
        ])
            .pipe(
                tap(([ can, employees ]) => {
                    // Update employees to only show those the user can get
                    this.employees = employees;

                    if (can) {
                        this.modes.push({
                            value: 'paytype',
                            label: 'PAY_TYPE',
                            can: true,
                        });
                    }

                    // Add employee mode if at least one is ok
                    if (this.employees.length) {
                        this.modes.push({
                            value: 'employee',
                            label: 'EMPLOYEE',
                            can: true,
                        });
                    }

                    if (!this.modes.length) {
                        this.formGroup.disable();
                    }
                }),
            ));
    }

    updateFormSearchParams() {
        // Employee we might have gotten from our input
        const employeeInputObservable = (this.employee instanceof Observable ? this.employee : of(this.employee)).pipe(take(1));

        employeeInputObservable.subscribe((resolvedEmployee) => {
            // Set the mode we get from params, or default to paytype
            let mode = resolvedEmployee ? 'employee' : this.searchParamsService.get<Mode>('mode', 'string') || 'paytype';

            // Check if mode is includes in the modes we have permission for
            if (!this.modes.map((m) => m.value).includes(mode)) {
                // Set mode to the only available mode if we have one
                if (this.modes.length) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    mode = this.modes[0]!.value;
                } else {
                    // Don't continue if we don't have any modes
                    this.fetchingInitialData = false;
                    return;
                }
            }

            this.formGroup.patchValue({
                mode,
                dateRange: {
                    from: this.searchParamsService.get('from', 'date') || this.defaultFrom,
                    to: this.searchParamsService.get('to', 'date') || this.defaultTo,
                },
                employee: resolvedEmployee || this.employees.find((e) => e.id === this.searchParamsService.get('employee', 'number')),
                payType: resolvedEmployee ? null : this.payTypeLinks.find((p) => p.id === this.searchParamsService.get('paytype', 'number')),
            });

            this.fetchingInitialData = false;
        });
    }

    getEmployeeOverview() {
        const from = this.formGroup.controls.dateRange.controls.from.value || undefined;
        const to = this.formGroup.controls.dateRange.controls.to.value || undefined;
        const employeeControl = this.formGroup.controls.employee;
        const employee = employeeControl.value instanceof Employee ? employeeControl.value : undefined;

        if (!from || !to || !employee) {
            return;
        }

        this.overview = null;
        this.fetchingName = employee.name || '';
        this.fetchingOverview = true;
        this.variablePaymentService.getEmployeeOverview(this.customerId, employee.id, from, to).pipe(
            take(1),
        ).subscribe((overview) => {
            this.fetchingOverview = false;

            this.payTypeLinks.forEach((link) => {
                this.payTypeLinkTotals[link.id] = overview.get(link.id).reduce((acc, pay) => acc + (pay.rate * pay.quantity), 0);
            });

            this.overview = {
                from,
                to,
                overview,
                employee,
                total: Object.values(this.payTypeLinkTotals).reduce((acc, total) => acc + total, 0),
            };

            this.setUrlParams(this.overview);
        });
    }

    getPayTypeOverview() {
        const from = this.formGroup.controls.dateRange.controls.from.value || undefined;
        const to = this.formGroup.controls.dateRange.controls.to.value || undefined;
        const payTypeLinkControl = this.formGroup.controls.payType;
        const payTypeLink = payTypeLinkControl.value instanceof PayTypeLink ? payTypeLinkControl.value : undefined;

        if (!from || !to || !payTypeLink) {
            return;
        }

        this.overview = null;
        this.fetchingName = payTypeLink.name;
        this.fetchingOverview = true;
        this.variablePaymentService.getOverview(this.customerId, payTypeLink.id, from, to).pipe(
            take(1),
        ).subscribe((overview) => {
            this.fetchingOverview = false;

            this.employees.forEach((employee) => {
                this.employeeTotals[employee.id] = overview.get(employee.id).reduce((acc, pay) => acc + pay.sum, 0);
            });

            this.overview = {
                from,
                to,
                overview,
                total: Object.values(this.employeeTotals).reduce((acc, total) => acc + total, 0),
                payTypeLink,
            };

            this.setUrlParams(this.overview);
        });
    }

    setUrlParams(overview: OverviewResult) {
        if (this.employee) {
            this.searchParamsService.set([
                { key: 'from', value: overview.from, type: 'date' },
                { key: 'to', value: overview.to, type: 'date' },
            ]);
        } else {
            this.searchParamsService.set([
                { key: 'from', value: overview.from, type: 'date' },
                { key: 'to', value: overview.to, type: 'date' },
                { key: 'employee', value: overview.employee?.id },
                { key: 'paytype', value: overview.payTypeLink?.id },
                { key: 'mode', value: overview.employee ? 'employee' satisfies Mode : 'paytype' satisfies Mode },
            ]);
        }
    }

    autocompleteNameDisplay(item?: PayTypeLink | Employee): string {
        return item?.name || '';
    }
}
