import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnDestroy, OnInit, signal, Signal } from '@angular/core';
import { CustomerEmployeesGetAllOptions, EmployeeService } from '../../../shared/http/employee.service';
import { Employee } from '../../../shared/models/employee';
import { ProfilePictureComponent } from '../../../shared/components/profile-picture/profile-picture.component';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../shared/services/current.service';
import { MatOption, MatRipple } from '@angular/material/core';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { Products } from '../../../shared/enums/products';
import { catchError, combineLatest, debounceTime, EMPTY, forkJoin, map, Observable, of, shareReplay, startWith, switchMap } from 'rxjs';
import { AvailabilityService, FormattedAvailabilityOverview } from '../../../availability/http/availability.service';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AvailabilityShiftResponse } from '../../../availability/models/availability-shift';
import { DateTime } from 'luxon';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { AsyncPipe, LowerCasePipe, UpperCasePipe } from '@angular/common';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { MatIcon } from '@angular/material/icon';
import { stringToDateTime } from '../../../shared/utils/string-to-date-time';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { scheduleDaySelectorDate, scheduleViewDragAndDrop, scheduleViewSchedule, scheduleViewShiftEventSubject } from '../../signals';
import { AngularJsScheduleViewSchedule } from '../../angularjs/schedules/view/schedule.component';
import { Qualification } from '../../../shared/models/qualification';
import { QualificationService } from '../../../shared/http/qualification.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { MatInput } from '@angular/material/input';
import { AvailabilityOverviewInterval } from '../../../availability/classes/availability-overview-interval';
import { UserPropertyService } from '../../../shared/http/user-property.service';

interface EmployeeAvailability {
    employee: Employee;
    hasAvailability: boolean;
    available: boolean;
    days: AvailabilityOverviewInterval[];
    availability_generated_by?: string;
    overlaps?: {
        from: DateTime,
        to: DateTime,
        location: string,
    }[];
}

@Component({
    selector: 'eaw-schedule-sidebar-employee-drag-and-drop',
    standalone: true,
    imports: [
        ProfilePictureComponent,
        MatRipple,
        DateTimePipe,
        UpperCasePipe,
        TranslatePipe,
        AsyncPipe,
        InfoLoadingComponent,
        MatIcon,
        MatIconSizeDirective,
        FormsModule,
        LowerCasePipe,
        MatFormField,
        MatLabel,
        MatOption,
        MatSelect,
        ReactiveFormsModule,
        TranslateSyncPipe,
        MatInput,
    ],
    templateUrl: './schedule-sidebar-employee-drag-and-drop.component.html',
    styleUrl: './schedule-sidebar-employee-drag-and-drop.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleSidebarEmployeeDragAndDropComponent implements OnInit, OnDestroy {
    private customerProductService = inject(CustomerProductService);
    private qualificationService = inject(QualificationService);
    private availabilityService = inject(AvailabilityService);
    private employeeService = inject(EmployeeService);
    private snackBarService = inject(SnackBarService);
    private currentService = inject(CurrentService);
    private userPropertyService = inject(UserPropertyService);
    private destroyRef = inject(DestroyRef);

    // Global signals etc., has to be bound to the component, so it will be bound to the proper context and can properly use it in tests.
    scheduleViewShiftEventSubject = scheduleViewShiftEventSubject;
    scheduleViewSchedule = scheduleViewSchedule;
    scheduleDaySelectorDate = scheduleDaySelectorDate;
    scheduleViewDragAndDrop = scheduleViewDragAndDrop;

    protected readonly yesterdayText = DateTime.now().minus({ days: 1 }).toRelativeCalendar({ unit: 'days' });
    private availabilityOverviewObservable?: Observable<FormattedAvailabilityOverview>;
    private dayAvailabilityObservables = new Map<string, Observable<AvailabilityShiftResponse[]>>();
    private hasAvailabilityProduct = signal<boolean>(false);

    qualificationsControl = new FormControl<number[]>([], { nonNullable: true });
    nameControl = new FormControl<string | null>(null);
    qualifications = signal<Qualification[]>([]);
    selectedQualifications: Signal<number[] | undefined>;
    filterName: Signal<string | undefined>;
    loading = signal(true);
    selectedEmployeeId = signal<number | undefined>(undefined);
    employeeAvailabilities: Signal<EmployeeAvailability[]> = computed(this.computeEmployeeAvailabilities.bind(this));
    fetchingAvailability = signal(false);
    employees = signal<Employee[]>([]);
    availabilityResponse = signal<{ shiftResponse: AvailabilityShiftResponse[], overview: FormattedAvailabilityOverview }>({ shiftResponse: [], overview: [] });
    // once schedule is upgraded, can be changed to computed(ScheduleComponent.properties.scheduleTab.showNicknames.value());
    showNicknames = signal(false);

    constructor() {
        this.selectedQualifications = toSignal(this.qualificationsControl.valueChanges);
        this.filterName = toSignal(this.nameControl.valueChanges.pipe(map((value) => value?.trim().toLowerCase())));

        combineLatest([
            toObservable(this.scheduleDaySelectorDate),
            toObservable(this.hasAvailabilityProduct),
            this.scheduleViewShiftEventSubject.asObservable().pipe(startWith(undefined)),
        ]).pipe(
            switchMap(([ date, hasAvailability ]) => this.getAvailabilityVariables(date, hasAvailability)),
            debounceTime(300),
            switchMap(({ date, schedule, from, to }) => this.getAvailabilityData(date, schedule, from, to)),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe(([ shiftResponse, overview ]) => {
            this.fetchingAvailability.set(false);

            this.availabilityResponse.set({
                shiftResponse,
                overview,
            });
        });
    }

    ngOnInit() {
        this.getEmployees();
        this.setHasAvailabilityProduct();
        this.getQualifications();
        this.getShowNicknameSetting();
    }

    ngOnDestroy() {
        this.stopDragAndDrop();
    }

    getShowNicknameSetting() {
        return this.userPropertyService.get(this.currentService.getUser().id, 'schedule:setting:showNicknames').pipe(
            map((property) => property.value.asBoolean()),
            catchError(() => of(false)),
        ).subscribe((val) => {
            this.showNicknames.set(!!val);
        });
    }

    getQualifications() {
        this.qualificationsControl.disable();

        expandAllPages((pagination) => this.qualificationService.getAll(this.scheduleViewSchedule().customerId, pagination), {
            per_page: 200,
        }).subscribe((qualifications) => {
            this.qualifications.set(sort(qualifications, this.currentService.languageTag, [ (q) => q.name ]));
            this.qualificationsControl.enable();
        });
    }

    getAvailabilityVariables(date: DateTime | undefined, hasAvailability: boolean) {
        const schedule = this.scheduleViewSchedule();

        if (!date || !hasAvailability) {
            return EMPTY;
        }

        this.fetchingAvailability.set(true);

        return of({
            date,
            schedule,
            from: schedule.from,
            to: schedule.to,
        });
    }

    getAvailabilityData(date: DateTime, schedule: AngularJsScheduleViewSchedule, from: DateTime, to: DateTime) {
        const existing = this.dayAvailabilityObservables.get(date.toString());
        const availabilityOnDay = existing || expandAllPages((pagination) => this.availabilityService.getOnDay(schedule.customerId, schedule.id, date, pagination), {
            per_page: 200,
        }).pipe(shareReplay(1));

        this.dayAvailabilityObservables.set(date.toString(), availabilityOnDay);

        if (!this.availabilityOverviewObservable) {
            this.availabilityOverviewObservable = this.availabilityService.getOverview(schedule.customerId, from, to.diff(from, 'weeks').as('weeks'), true).pipe(
                shareReplay(1),
            );
        }

        return forkJoin([
            availabilityOnDay,
            this.availabilityOverviewObservable,
        ]).pipe(
            catchError(() => {
                this.fetchingAvailability.set(false);
                return EMPTY;
            }),
        );
    }

    setHasAvailabilityProduct() {
        this.customerProductService.hasProducts(this.scheduleViewSchedule().customerId, [ Products.Availability ]).pipe(
            catchError(() => EMPTY),
        ).subscribe((hasProduct) => {
            this.hasAvailabilityProduct.set(hasProduct);
        });
    }

    computeEmployeeAvailabilities() {
        const fetching = this.fetchingAvailability();
        const availabilityResponse = this.availabilityResponse();
        const date = this.scheduleDaySelectorDate() || DateTime.now();
        const qualifications = this.selectedQualifications() || [];
        const filterName = this.filterName() || '';
        const employeeAvailabilities: EmployeeAvailability[] = [];
        const employees = this.employees().filter((employee) => {
            if (filterName && !employee.name.toLowerCase().includes(filterName)) {
                return false;
            }

            return qualifications.length ? qualifications.some((q) => employee.qualifications?.map((eq) => eq.id)?.includes(q)) : true;
        });

        if (!employees) {
            return employeeAvailabilities;
        }

        if (fetching) {
            return employees.map((employee) => {
                return {
                    employee,
                    hasAvailability: false,
                    available: false,
                    days: [],
                };
            });
        }

        employees.forEach((employee) => {
            const availability = availabilityResponse.shiftResponse.find((a) => a.id === employee.id);
            const employeeOverview = availabilityResponse.overview.find((a) => a.employee.id === employee.id);

            if (!availability) {
                employeeAvailabilities.push({
                    employee,
                    hasAvailability: false,
                    available: false,
                    days: [],
                });

                return;
            }

            const days = employeeOverview?.intervals.flat(3).filter((i) => i.date.hasSame(date, 'day')) || [];
            employeeAvailabilities.push({
                employee,
                hasAvailability: true,
                available: availability.available || days.length > 0,
                availability_generated_by: availability.availability_generated_by,
                overlaps: sort(availability.overlaps?.map((o) => {
                    return {
                        from: stringToDateTime(o.overlap_from),
                        to: stringToDateTime(o.overlap_to),
                        location: o.overlap_customer_name,
                    };
                }), this.currentService.languageTag, [ (o) => o.from.toMillis() ], [ 'asc' ], { numeric: true }),
                days,
            });
        });

        return sort(employeeAvailabilities, this.currentService.languageTag, [ (e) => e.employee.name ]);
    }

    getEmployees() {
        const staticOptions: CustomerEmployeesGetAllOptions = {
            from: this.scheduleViewSchedule().from,
            to: this.scheduleViewSchedule().to,
            'with[]': [ 'qualifications' ],
            include_external: true,
        };

        expandAllPages((pagination) => this.employeeService.getAll(this.scheduleViewSchedule().customerId, { ...pagination, ...staticOptions }), {
            per_page: 200,
        }).subscribe((employees) => {
            this.employees.set(sort(employees, this.currentService.languageTag, [ (e) => e.name ]));
            this.loading.set(false);
        });
    }

    employeeClick(employee: Employee) {
        if (employee.id === this.selectedEmployeeId()) {
            this.stopDragAndDrop();
        } else {
            this.startDragAndDrop(employee);
        }
    }

    startDragAndDrop(employee: Employee) {
        void this.snackBarService.t(`Drag and drop mode started for ${employee.name}.`);
        this.selectedEmployeeId.set(employee.id);
        this.scheduleViewDragAndDrop.set({ id: employee.id, type: 'employee' });
    }

    stopDragAndDrop() {
        void this.snackBarService.t(`Drag and drop mode stopped.`);
        this.selectedEmployeeId.set(undefined);
        this.scheduleViewDragAndDrop.set(undefined);
    }
}
