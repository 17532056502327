// @ts-nocheck
import { t } from 'i18next';
import { remove } from 'lodash-es';
import { module } from 'angular';
const template3 = `<md-dialog>
    <eaw-dialog-header-old heading="$ctrl.item.title"></eaw-dialog-header-old>
    <md-dialog-content>
        <md-list>
            <md-list-item ng-repeat="(key, type) in $ctrl.checklistTypes track by key" ng-click="$ctrl.select(type)">
                <md-icon ng-bind="type.icon"></md-icon>
                <p ng-bind="type.text"></p>
                <md-icon class="md-secondary" ng-show="type.selected" ng-bind="'done'"></md-icon>
            </md-list-item>
        </md-list>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button class="md-accent md-raised" ng-click="$ctrl.update()" ng-i18next="UPDATE"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
const template2 = `<md-bottom-sheet class="md-list md-has-header">
    <md-subheader ng-cloak>
        <span>{{$ctrl.child.title}}</span>
    </md-subheader>

    <md-list ng-cloak>
        <md-list-item ng-repeat="item in $ctrl.menu" ng-if="item.show">
            <md-button ng-click="$ctrl.itemClick(item)" class="md-list-item-content">
                <md-icon ng-bind="item.icon"></md-icon>
                <span class="md-inline-list-icon-label">{{item.text}}</span>
            </md-button>
        </md-list-item>
    </md-list>
</md-bottom-sheet>
`;

module('eaw.checklists').component('eawChecklistChild', {
    template: `<div class="child-body" ng-click="$ctrl.toggleCategory()">
    <div class="left-bar"></div>

    <!-- Drag handle icon -->
    <md-icon class="drag-icon" ng-bind="'drag_indicator'"></md-icon>

    <!-- Progress -->
    <md-progress-circular class="child-progress" md-diameter="24"></md-progress-circular>

    <!-- Icon for item or category -->
    <md-icon class="type-icon" ng-if="$ctrl.child.isCategory" ng-bind="$ctrl.child.open ? 'expand_less' : 'expand_more'"></md-icon>
    <md-icon class="type-icon" ng-if="!$ctrl.child.isCategory" ng-bind="$ctrl.child.type.icon"></md-icon>

    <div class="child-texts">
        <span class="child-title" ng-bind="$ctrl.child.title"></span>
        <span class="child-description" ng-bind="$ctrl.child.description"></span>
        <span ng-if="$ctrl.child.isCategory" class="child-description" ng-i18next="[i18next]({count: $ctrl.child.children.length})checklists:X_ITEM"></span>
        <!-- enable line under to debug weights -->
        <!-- <span ng-bind="$ctrl.child.weight"></span> -->
    </div>

    <div class="child-btns">
        <md-button class="md-icon-button" ng-repeat="item in ::$ctrl.menu" ng-if="item.show" ng-click="$ctrl.menuCallback(item, $event)">
            <md-icon ng-bind="item.icon"></md-icon>
            <md-tooltip ng-bind="item.text"></md-tooltip>
        </md-button>
    </div>

    <md-menu class="child-menu" md-position-mode="target-right target">
        <md-button class="md-icon-button" ng-class="{'invisible': $ctrl.child.temp}" ng-click="$ctrl.openMenu($mdMenu, $event)">
            <md-icon md-menu-origin ng-bind="'more_vert'"></md-icon>
        </md-button>
        <md-menu-content width="4">
            <md-menu-item ng-repeat="item in ::$ctrl.menu" ng-if="item.show">
                <md-button ng-click="$ctrl.menuCallback(item, $event)">
                    <md-icon ng-bind="item.icon"></md-icon>
                    <span ng-bind="::item.text"></span>
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>
</div>

<eaw-checklist-children-list
        ng-if="$ctrl.child.isCategory"
        ng-show="$ctrl.child.open"
        parent="$ctrl.child"
        children="$ctrl.child.children">
</eaw-checklist-children-list>
`,
    bindings: {
        child: '<',
    },
    require: {
        checklistTemplate: '^^eawChecklistTemplate',
    },
    controller: [ '$element', '$mdDialog', 'ChecklistsService', '$mdBottomSheet', '$mdMedia', function($element, $mdDialog, ChecklistsService, $mdBottomSheet, $mdMedia) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.ChecklistsService = ChecklistsService;
        };

        ctrl.$onChanges = (changes) => {
            if (changes?.child?.currentValue) {
                ctrl.updateChangedData(changes.child.currentValue);
                ctrl.createMenu();
            }
        };

        ctrl.createMenu = () => {
            ctrl.menu = [
                {
                    icon: 'title',
                    callback: ctrl.editTitle,
                    text: t('checklists:EDIT_TITLE'),
                },
                {
                    show: !ctrl.child.isCategory,
                    icon: 'edit',
                    callback: ctrl.changeType,
                    text: t('checklists:CHANGE_TYPE'),
                },
                {
                    show: !ctrl.child.isCategory && !ctrl.child.description,
                    icon: 'add_comment',
                    callback: ctrl.addDescription,
                    text: t('checklists:ADD_DESCRIPTION'),
                },
                {
                    show: !!ctrl.child.description,
                    icon: 'rate_review',
                    callback: ctrl.editDescription,
                    text: t('checklists:EDIT_DESCRIPTION'),
                },
                {
                    show: !!ctrl.child.description,
                    icon: 'remove',
                    callback: ctrl.removeDescription,
                    text: t('checklists:REMOVE_DESCRIPTION'),
                },
                {
                    show: ctrl.child.hasAdvancedOption,
                    icon: 'code',
                    callback: ctrl.advanced,
                    text: ctrl.child.type.advancedText,
                },
                {
                    icon: 'delete',
                    callback: ctrl.delete,
                    text: t('DELETE'),
                },
            ];

            ctrl.menu.forEach((x) => {
                x.show = x.show ?? true;
            });
        };

        ctrl.menuCallback = (item, event) => {
            event.stopPropagation();
            item.callback();
        };

        ctrl.updateChangedData = (child) => {
            ctrl.child = {
                ...child,
                type: ChecklistsService.getType(ctrl.child.type),
            };
            ctrl.child.isCategory = ChecklistsService.isCategory(ctrl.child);
            ctrl.child.hasAdvancedOption = ChecklistsService.hasAdvanced(ctrl.child?.type?.type);

            if (ctrl.child.isCategory) {
                $element[0].classList.add('category-child');
            } else {
                $element[0].classList.remove('category-child');
            }

            $element[0].dataset.childId = ctrl.child.id;
            $element[0].dataset.weight = ctrl.child.weight;
            $element[0].classList.remove('open');

            // Toggle open
            ctrl.child.open = !Object.values(ctrl.checklistTemplate.openCategories).some((x) => x === ctrl.child.id);
            ctrl.toggleCategory();
        };

        ctrl.openMenu = function($mdMenu, ev) {
            if ($mdMedia('gt-sm')) {
                $mdMenu.open(ev);
            } else {
                $mdBottomSheet.show({
                    template: template2,
                    controller: 'checklistChildMenuBottomSheetCtrl',
                    controllerAs: '$ctrl',
                    bindToController: true,
                    locals: {
                        child: { ...ctrl.child },
                        menu: ctrl.menu,
                    },
                }).then((item) => {
                    item.callback();
                });
            }
        };

        ctrl.advanced = () => {
            ctrl.child.type.advanced(ctrl.child).then((updatedChild) => {
                ctrl.checklistTemplate.update(updatedChild, $element[0]);
            });
        };

        ctrl.toggleCategory = () => {
            if (ctrl.child.isCategory) {
                ctrl.child.open = !ctrl.child.open;

                if (ctrl.child.open) {
                    $element[0].classList.add('open');
                    ctrl.checklistTemplate.openCategories.push(ctrl.child.id);
                } else {
                    $element[0].classList.remove('open');
                    remove(ctrl.checklistTemplate.openCategories, (x) => x === ctrl.child.id);
                }
            }
        };

        ctrl.delete = () => {
            ctrl.checklistTemplate.delete(ctrl.child, $element[0]);
        };

        ctrl.addDescription = () => {
            $mdDialog.show(
                $mdDialog.prompt()
                    .title(t('checklists:ADD_DESCRIPTION'))
                    .required(true)
                    .ok(t('ADD'))
                    .cancel(t('CANCEL')),
            ).then((description) => {
                ctrl.checklistTemplate.update({
                    ...ctrl.child,
                    description,
                }, $element[0]);
            });
        };

        ctrl.editDescription = () => {
            $mdDialog.show(
                $mdDialog.prompt()
                    .title(t('checklists:EDIT_DESCRIPTION'))
                    .required(true)
                    .initialValue(ctrl.child.description)
                    .ok(t('UPDATE'))
                    .cancel(t('CANCEL')),
            ).then((description) => {
                ctrl.checklistTemplate.update({
                    ...ctrl.child,
                    description,
                }, $element[0]);
            });
        };

        ctrl.removeDescription = () => {
            ctrl.checklistTemplate.update({
                ...ctrl.child,
                description: null,
            }, $element[0]);
        };

        ctrl.editTitle = () => {
            $mdDialog.show(
                $mdDialog.prompt()
                    .title(t('checklists:EDIT_TITLE'))
                    .required(true)
                    .initialValue(ctrl.child.title)
                    .ok(t('UPDATE'))
                    .cancel(t('CANCEL')),
            ).then((title) => {
                ctrl.checklistTemplate.update({
                    ...ctrl.child,
                    title,
                }, $element[0]);
            });
        };

        ctrl.changeType = () => {
            $mdDialog.show({
                template: template3,
                controllerAs: '$ctrl',
                controller: 'changeItemTypeCtrl',
                bindToController: true,
                locals: {
                    item: { ...ctrl.child },
                },
            }).then((updatedChild) => {
                if (updatedChild.typeName === 'select') {
                    updatedChild.typeAdvanced(updatedChild).then((updatedChild2) => {
                        ctrl.checklistTemplate.update(updatedChild2, $element[0]);
                    });
                } else {
                    ctrl.checklistTemplate.update(updatedChild, $element[0]);
                }
            });
        };
    } ],
});
