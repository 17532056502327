// @ts-nocheck
import { module } from 'angular';

module('eaw.scheduling.limits').component('limitList', {
    template: `<md-list>
    <md-list-item ng-repeat="limit in $limits.list track by limit.id" class="md-2-line">
        <div class="md-list-item-text" layout="column">
            <h3>
                <span ng-bind="limit.used / 3600 | eawNumber:2"></span> / <span ng-bind="limit.limit / 3600 | eawNumber"></span>
                <span ng-i18next="HOUR_plural"></span>
            </h3>
            <p class="text-muted">
                <span ng-bind="limit.from | moment: 'LL'"></span> -
                <span ng-bind="limit.to | moment: 'LL'"></span>
            </p>
        </div>
    </md-list-item>
</md-list>
`,
    controllerAs: '$limits',
    bindings: {
        from: '<',
        to: '<',
        schedulingLimits: '<',
    },
    controller: [ '$scope', 'shiftEvents', function($scope, shiftEvents) {
        const ctrl = this;
        ctrl.$onInit = () => {
            // listen to shift change event
            shiftEvents.register.onChange($scope, update);
        };

        ctrl.$postLink = update;

        function update() {
            ctrl.schedulingLimits.getAll.query({
                per_page: 999,
                from: ctrl.from,
                to: ctrl.to,
                include_used: true,
                _businessDates: [ 'from', 'to' ],
            }).$promise.then((resp) => {
                ctrl.list = resp.data;
            });
        }
    } ],
});
