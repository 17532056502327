// @ts-nocheck
import { module } from 'angular';
module('eaw.dashboard').factory('PostitFactory', [ 'EawResource', function(EawResource) {
    const resource = EawResource.create('/customers/:customer_id/postits/:postit_id');
    const factory = this;
    /**
     * @param {number} customerId
     * @param {number} page
     * @param {number} perPage=5
     * @param {string[]} relations
     * @return {*}
     */
    factory.getAll = (customerId, page, perPage = 5, relations) => resource.get({
        customer_id: customerId,
        'with[]': relations,
        per_page: perPage,
        direction: 'desc',
        order_by: 'created_at',
        page,
    });
    factory.get = (customerId, postitId, relations) => resource.get({
        customer_id: customerId,
        postit_id: postitId,
        'with[]': relations,
    });
    factory.delete = (postitId, customerId) => resource.delete({
        customer_id: customerId,
        postit_id: postitId,
    });
    factory.create = (body, customerId, relations) => resource.save({
        customer_id: customerId,
        'with[]': relations,
    }, {
        body,
    });
    factory.update = (postitId, body, customerId, relations) => resource.update({
        customer_id: customerId,
        postit_id: postitId,
        'with[]': relations,
    }, {
        body,
    });
    return factory;
} ]);
