import { Component, Inject } from '@angular/core';
import { DialogComponent } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { DefaultHrFileService, HrDefaultFileSubmitData } from '../../http/default-hr-file.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { HrFileTypeService } from '../../http/hr-file-type.service';
import { HrFileType } from '../../models/hr-file-type';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { map, Observable, of, startWith } from 'rxjs';
import { CreateHrDefaultFileData } from './create-hr-default-dialog.service';
import { FileSizePipe } from '../../../shared/pipes/file-size.pipe';
import { MaterialColorPipe } from '../../../shared/pipes/material-color.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { FileSelectorComponent } from '../../../shared/components/file-selector/file-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-create-hr-default-file-dialog',
    templateUrl: './create-hr-default-file-dialog.component.html',
    styleUrl: './create-hr-default-file-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        NgIf,
        MatOptionModule,
        MatProgressSpinnerModule,
        NgFor,
        MatIconModule,
        FileSelectorComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        MaterialColorPipe,
        FileSizePipe,
    ],
})
export class CreateHrDefaultFileDialogComponent extends DialogComponent {
    // Boolean, if set to true autocomplete will show a loading option
    loading = true;
    file?: File;
    customerId: number;
    // Filetypes for use with autocomplete
    filetypes: HrFileType[] = [];
    // Holds filtered filetypes
    filteredTypes: Observable<HrFileType[]> = of([]);
    // Form group which holds form data
    formGroup = new FormGroup({
        filetype: new FormControl(this.data.filetype, Validators.required),
        file: new FormControl<File | null>(null, !this.data.id ? Validators.required : null),
        name: new FormControl(this.data.name, Validators.required),
        description: new FormControl(this.data.description),
    });

    // Injects data from main component, as well as the dialog reference and various services
    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateHrDefaultFileData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<CreateHrDefaultFileDialogComponent, true>,
        @Inject(DefaultHrFileService) private service: DefaultHrFileService,
        @Inject(HrFileTypeService) private fileTypeService: HrFileTypeService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
    ) {
        // Initialize DialogComponent super class
        super(dialogRef, data);

        // Set customer id from injected data
        this.customerId = data.customerId;
        // If injected data has a non-empty filetypes array, set that as file type
        if (data.filetypes?.length) {
            this.filetypes = data.filetypes;
            this.setFiletypeFilter();
        } else {
            // Else get filetypes from server and continue setup
            this.fileTypeService.getAll(this.customerId, { per_page: 9999 }).subscribe((files) => {
                this.filetypes = files.data;
                this.setFiletypeFilter();
            });
        }
    }

    // Called after file types are set, start by setting loading to false
    setFiletypeFilter() {
        this.loading = false;
        // Set up filter to use with autocomplete, see if input matches any filetype names
        this.filteredTypes = this.formGroup.get('filetype')?.valueChanges.pipe(
            map((value) => typeof value === 'string' ? value : ''),
            startWith(''),
            map((value) => value.toLowerCase()),
            map((value) => this.filetypes.filter((type) => type.name.toLowerCase().includes(value))),
        ) || of([]);
    }

    // Provides a display value when a type is chosen
    autoCompDisplay(type?: HrFileType) {
        return type?.name || '';
    }

    // Sets the file when it is chosen and set the file name as name input if it is empty
    onFileChange(file?: File) {
        this.file = file;

        const nameControl = this.formGroup.controls.name;
        if (!nameControl.value) {
            nameControl.setValue(file?.name || null);
        }
    }

    // Submit form and create a new default file
    submit() {
        const formValue = this.formGroup.value;
        const submit: HrDefaultFileSubmitData = {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            name: formValue.name!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            filetypeId: formValue.filetype!.id,
            description: formValue.description || undefined,
        };
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const file: File | undefined = formValue.file !== this.data.file ? formValue.file! : undefined;

        // Disable form group so that the form doesn't allow any changes while submitting
        this.formGroup.disable();
        // Update if editing existing file
        if (this.data.id) {
            this.service.update(this.customerId, this.data.id, submit).subscribe({
                next: () => {
                    this.dialogRef.close(true);
                    void this.snackbar.t('X_UPDATED', 'general', { var: submit.name });
                },
                error: () => this.formGroup.enable(),
            });
        } else {
            // Create a new default file, then close the dialog and show snackbar to confirm it was successful
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.service.create(this.customerId, submit, file!).subscribe({
                next: () => {
                    this.dialogRef.close(true);
                    void this.snackbar.t('X_CREATED', 'general', { name: submit.name });
                },
                // Re-enable form if creation fails
                error: () => this.formGroup.enable(),
            });
        }
    }
}
