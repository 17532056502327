// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<div layout="row" layout-align="space-between center">
    <div>
        <md-checkbox id="{{$adCtrl.label}}show" ng-model="$adCtrl.day.showTime" ng-change="$adCtrl.toggleTime($adCtrl.day)"></md-checkbox>
        <label for="{{$adCtrl.label}}show" ng-bind="$adCtrl.label"></label>
    </div>
    <div ng-show="!$adCtrl.day.showTime"></div>

    <div ng-show="$adCtrl.day.showTime">
        <md-input-container>
        <eaw-time-picker-old ng-model="$adCtrl.day.time" ng-change="$adCtrl.onChange($adCtrl.day.time)" ng-if="$adCtrl.day.showTime"  ng-required="true"></eaw-time-picker-old>
        </md-input-container>
    </div>
</div>
`;

module('eaw.time').component('autoStartDay', {
    controller: 'AutoStartDayCtrl',
    controllerAs: '$adCtrl',
    template: template1,
    require: {
        'ngModel': 'ngModel', // string|null
    },
    bindings: {
        label: '@', // name of day, presumably
        showTime: '<?', // if we should show or hide timepicker by default (false by default)
    },
});

module('eaw.time').controller('AutoStartDayCtrl', function AutoStartDayCtrl() {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.day = {
            showTime: ctrl.showTime || false,
        };

        ctrl.ngModel.$formatters.unshift((modelValue) => ctrl.day.time = moment.isMoment(modelValue) ? modelValue : null);

    };

    ctrl.toggleTime = () => {
        ctrl.ngModel.setViewValue(ctrl.day?.showTime ? ctrl.day?.time?.clone() : null);
    };

    ctrl.onChange = () => {
        ctrl.ngModel.setViewValue(ctrl.day?.time ? ctrl.day?.time?.clone() : null);
    };
});
