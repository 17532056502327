// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
module('eaw.coupons').component('eawUseCouponButton', {
    template: `<div class="drag-container">
                    <div ng-disabled="ctrl.disabled" md-ink-ripple="md-primary" class="swipe-button hover-item" ng-i18next="coupons:SWIPE_TO_USE"></div>
                    <div class="swipes" ng-hide="ctrl.hideSwipes">
                        <div class="swipe"></div>
                        <div class="swipe"></div>
                        <div class="swipe"></div>
                    </div>
                    <div class="drop-zone"></div>
                </div>`,
    controllerAs: 'ctrl',
    bindings: {
        onSwiped: '&',
    },
    controller: [ '$element', '$attrs', function eawUseCouponButton($element, $attrs) {
        this.$onInit = () => {
            this.btnText = t('coupons:SWIPE_TO_USE');
            this.usedText = t('coupons:USED');
            $attrs.$observe('disabled', (disabled) => {
                this.disabled = disabled;
            });
        };
        this.$postLink = () => {
            this.addEventListeners();
        };
        this.addEventListeners = function() {
            const container = $element[0].querySelector('.drag-container');
            const dropZone = container.querySelector('.drop-zone');
            container.style.setProperty('display', '');
            dropZone.style.setProperty('display', '');
            let btn;
            let rect;
            let endX;
            let btnWidth;
            let startX;
            const padding = 4;
            const mousedown = (event) => {
                if (this.disabled || !(event.target instanceof HTMLElement) || !event.target.classList.contains('swipe-button')) {
                    return;
                }
                btn = event.target;
                btnWidth = btn.getBoundingClientRect().width;
                rect = container.getBoundingClientRect();
                endX = dropZone.getBoundingClientRect().x;
                startX = event instanceof TouchEvent ? event.touches.item(0).clientX : event.clientX;
            };
            const mousemove = (event) => {
                if (!btn) {
                    return;
                }
                const clientX = event instanceof TouchEvent ? event.touches.item(0).clientX : event.clientX;
                if (clientX - padding <= rect.x || clientX >= rect.x + rect.width) {
                    return;
                }
                const delta = clientX - startX;
                if (Math.abs(delta) >= 5) {
                    btn.style.left = delta + 'px';
                    this.hideSwipes = clientX > endX - btnWidth;
                }
            };
            this.mouseup = (event) => {
                if (!btn) {
                    return;
                }
                const clientX = event instanceof TouchEvent ? event.changedTouches.item(0).clientX : event.clientX;
                let left = 0;
                if (clientX >= endX - padding) {
                    this.onSwipe();
                    left = rect.width - btnWidth - padding;
                } else {
                    this.hideSwipes = false;
                }
                btn.style.left = left + 'px'; // Reset position
                btn = undefined;
            };
            this.touchcancel = () => {
                btn.style.left = 0 + 'px'; // Reset position
                btn = undefined;
            };
            container.addEventListener('mousedown', mousedown, { passive: true });
            container.addEventListener('touchstart', mousedown, { passive: true });
            container.addEventListener('mousemove', mousemove, { passive: true });
            container.addEventListener('touchmove', mousemove, { passive: true });
            document.addEventListener('mouseup', this.mouseup, { passive: true });
            document.addEventListener('touchend', this.mouseup, { passive: true });
            document.addEventListener('touchcancel', this.touchcancel, { passive: true });
        };
        this.$onDestroy = () => {
            document.body.removeEventListener('touchend', this.mouseup);
            document.body.removeEventListener('mouseup', this.mouseup);
            document.body.removeEventListener('touchcancel', this.touchcancel);
        };
        this.onSwipe = () => {
            this.onSwiped(); // Let parent know it was swiped
        };
    } ],
});
