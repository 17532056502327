import { Component, Inject, Input, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { Customer } from '../../../shared/models/customer';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { PermissionSetService } from '../../../shared/http/permission-set.service';
import { of } from 'rxjs';
import { PermissionSet } from '../../../shared/models/permission-set';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { PermissionDialogService } from '../../angularjs/shared/services/permission-dialog.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

// For now, this component only works with leader roles
@Component({
    selector: 'eaw-permission-set-table',
    templateUrl: './permission-set-table.component.html',
    styleUrl: './permission-set-table.component.scss',
    standalone: true,
    imports: [ DataTableComponent ],
})
export class PermissionSetTableComponent implements EawDataTable<PermissionSet> {
    @Input() customer!: Customer;
    @Input() roleId!: number;

    @ViewChild(DataTableComponent) table!: DataTableComponent<PermissionSet>;

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<PermissionSet>[] = [
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableTextColumn({
            value: 'productName',
            header: new DataTableHeader({
                i18n: 'PRODUCT',
                ns: 'admin',
                sortBy: 'product_name',
            }),
        }),
        new DataTableTextColumn({
            value: 'description',
            header: new DataTableHeader({
                i18n: 'DESCRIPTION',
                sortBy: 'description',
            }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'delete',
                    type: 'warn',
                    tooltip: { key: 'REMOVE' },
                    click: (cell) => this.detachSet(cell),
                    show: () => of(true),
                },
            ],
        }),
    ];

    constructor(
        @Inject(PermissionSetService) private service: PermissionSetService,
        @Inject(PermissionDialogService) private dialog: PermissionDialogService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
    ) {
    }

    // Open dialog for adding permission set, then attach one if a set is returned
    attachSet() {
        this.dialog.addPermissionSet().afterClosed().subscribe((set) => {
            if (!set) {
                return;
            }

            this.service.attachToRole(this.customer.id, this.roleId, set.id).subscribe(() => {
                this.updateTable(this.table.getPagination({ page: 1 }));
                void this.snackbar.t('X_ADDED', 'general', { name: set.name });
            });
        });
    }

    // Open confirm dialog for removal, then detach if confirmed
    detachSet(cell: DataTableButtonCell<PermissionSet>) {
        this.dialog.remove([ 'REMOVE_PERMISSIONSET', 'general', { name: cell.item.name } ]).afterClosed().subscribe((del) => {
            cell.disabled.set(del?.ok);

            if (del?.ok) {
                this.service.detachFromRole(this.customer.id, this.roleId, cell.item.id).subscribe(() => {
                    this.updateTable(this.table.getPagination({ page: 1 }));
                    void this.snackbar.t('REMOVED');
                });
            }
        });
    }

    // Update table
    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.service.getAllForRole(this.customer.id, this.roleId, pagination);
    }
}
