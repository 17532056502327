import { Component, Inject, OnInit, signal } from '@angular/core';
import { DialogComponent, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { Timepunch } from '../../models/timepunch';
import type { InitialTimepunchCreateData, ManageTimepunchDialogData } from './manage-timepunch-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BusinessUnitService } from '../../../business-units/http/business-unit.service';
import { BusinessUnit } from '../../../business-units/models/business-unit';
import { CreateBody, TimepunchService, UpdateBody } from '../../http/timepunch.service';
import { Employee } from '../../../shared/models/employee';
import { catchError, EMPTY, forkJoin, map, of, startWith, switchMap, take, tap, throwError } from 'rxjs';
import { DateTime } from 'luxon';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { CurrentService } from '../../../shared/services/current.service';
import { Products } from '../../../shared/enums/products';
import { getTimeInterval } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { SettingService } from '../../../shared/http/setting.service';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TimeInputComponent } from '../../../shared/components/date-time/time-input/time-input.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { DocumentServiceService } from '../../../shared/http/document-service.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { AlertDialogComponent } from '../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

type TimepunchForm = {
    date: FormControl<DateTime | null | undefined>,
    businessDate: FormControl<DateTime | null | undefined>,
    in: FormControl<DateTime | null | undefined>,
    out: FormControl<DateTime | undefined | null>,
    employee: FormControl<Employee | number | undefined>,
    comment: FormControl<string | null | undefined>,
    businessUnit?: FormControl<BusinessUnit | null>,
    originalIn?: FormControl<DateTime | null | undefined>,
    originalOut?: FormControl<DateTime | null | undefined>,
};

@Component({
    selector: 'eaw-manage-timepunch-dialog',
    templateUrl: './manage-timepunch-dialog.component.html',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        TimeInputComponent,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        AutocompleteComponent,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        TextFieldModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        ActionButtonComponent,
        TranslateSyncPipe,
    ],
})
export class ManageTimepunchDialogComponent extends DialogComponent implements OnInit {
    createMode: boolean;
    timepunch?: Timepunch;
    commentRequired: boolean;
    documentServiceEnabled: boolean;
    loadingBusinessUnits: boolean;
    loading: boolean;
    fetching = false;
    minBusinessDate: DateTime | null = null;
    maxBusinessDate: DateTime | null = null;
    businessUnits: BusinessUnit[] | null = null;
    formGroup: FormGroup<TimepunchForm>;

    get date(): DateTime | null | undefined {
        return this.formGroup.controls.date.value;
    };

    get businessDate(): DateTime | null | undefined {
        return this.formGroup.controls.businessDate.value;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ManageTimepunchDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ManageTimepunchDialogComponent, Timepunch>,
        @Inject(TranslateService) public translate: TranslateService,
        @Inject(BusinessUnitService) public businessUnitService: BusinessUnitService,
        @Inject(TimepunchService) public timepunchService: TimepunchService,
        @Inject(CurrentService) public currentService: CurrentService,
        @Inject(PermissionCheckService) public permissionCheckService: PermissionCheckService,
        @Inject(SettingService) private settingService: SettingService,
        @Inject(CustomerProductService) private customerProductService: CustomerProductService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
        @Inject(DocumentServiceService) private documentService: DocumentServiceService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
        @Inject(MatDialog) protected matDialog: MatDialog,
    ) {
        data.size ||= DialogSize.Medium;
        super(dialogRef, data);

        this.commentRequired = false;
        this.documentServiceEnabled = false;

        this.createMode = !data.timepunchObservable;
        this.loadingBusinessUnits = false;

        const initial: InitialTimepunchCreateData | undefined = data.initialCreateData;
        const employeeCtrl = new FormControl(initial?.employee, {
            nonNullable: true,
            validators: [ Validators.required ],
        });

        this.formGroup = new FormGroup<TimepunchForm>({
            date: new FormControl(initial?.date, Validators.required),
            businessDate: new FormControl(initial?.businessDate, { validators: [ Validators.required ] }),
            in: new FormControl(null, { validators: [ Validators.required ] }),
            out: new FormControl(undefined),
            employee: employeeCtrl,
            comment: new FormControl(''),
            originalIn: new FormControl(undefined),
            originalOut: new FormControl(undefined),
        });

        if (data.disableEmployeeSelect || !this.createMode) {
            employeeCtrl.disable();
        }

        this.loading = !!this.data.timepunchObservable;
    }

    ngOnInit() {
        this.initBusinessUnits();
        this.handleBusinessDateChange();
        this.settingService.getValue([ 'customers', this.data.customerId ], 'timepunch.mandatory_edit_comment', false).subscribe((result) => {
            this.commentRequired = Boolean(result);
        });
        this.settingService.getValue([ 'customers', this.data.customerId ], 'document-service-hr-file-workflows', false).subscribe((result) => {
            this.documentServiceEnabled = Boolean(result);
        });
    }

    initTimepunchObservable() {
        if (!this.data.timepunchObservable) {
            const businessUnit = this.businessUnits?.find((bu) => bu.default) || null;
            this.formGroup.controls.businessUnit?.setValue(businessUnit);
            this.loading = false;

            return;
        }

        this.data.timepunchObservable.pipe(
            take(1),
            catchError((err) => {
                console.error(`Failed to get timepunch`, err);
                return EMPTY;
            }),
        ).subscribe((tp) => {
            this.timepunch = tp;
            this.initControls(this.timepunch);
            this.updateMinMaxBusinessDate();

            const businessUnit = this.businessUnits?.find((bu) => bu.id === tp.businessUnitId);
            if (businessUnit) {
                this.formGroup.controls.businessUnit?.setValue(businessUnit);
            }
            this.loading = false;
        });
    }

    handleBusinessDateChange() {
        const controls = this.formGroup.controls;

        controls.businessDate.valueChanges.pipe(startWith(null)).subscribe((value) => {
            if (this.createMode) {
                return;
            }

            const empControl = controls.employee;

            if (value) {
                empControl.enable();
            } else if (!empControl.value) {
                empControl.disable();
            }
        });
    };

    initControls(tp: Timepunch) {
        const controls = this.formGroup.controls;

        controls.in.setValue(tp.in);
        controls.out.setValue(tp.out);
        controls.businessDate.setValue(tp.businessDate.dateTime);
        controls.date.setValue(controls.in.value);
        controls.originalIn?.setValue(tp.in);
        controls.originalOut?.setValue(tp.out);

        // Disable if an employee is given
        if (tp.employee instanceof Employee) {
            controls.employee.setValue(tp.employee);
            controls.employee.disable();
        }
    }

    /**
     * Gets business units and then inits the timepunch
     */
    initBusinessUnits() {
        const businessUnitCtrl = new FormControl<BusinessUnit | null>(null);

        forkJoin([
            this.customerProductService.hasProducts(this.data.customerId, [ Products.BusinessUnits ]),
            this.permissionCheckService.isAllowed(`customers.${this.data.customerId}.business_units.*.get`),
        ]).pipe(
            switchMap(([ hasProducts, canGetBusinessUnits ]) => {
                if (!canGetBusinessUnits || !hasProducts) {
                    this.initTimepunchObservable();
                    return of(null);
                }

                this.formGroup.addControl('businessUnit', businessUnitCtrl);
                this.loadingBusinessUnits = true;
                businessUnitCtrl.disable();

                return this.businessUnitService.getAll(this.data.customerId, {
                    per_page: 99,
                    direction: 'asc',
                    order_by: 'name',
                });
            }),
            catchError(() => {
                this.initTimepunchObservable();
                return EMPTY;
            }),
            tap((response) => {
                if (!response) {
                    return;
                }

                this.businessUnits = response.data;
                this.loadingBusinessUnits = false;
                businessUnitCtrl.enable();
                this.initTimepunchObservable();
            }),
        ).subscribe();
    }

    dateChange(event: MatDatepickerInputEvent<DateTime>) {
        if (!event.value) {
            return;
        }

        this.formGroup.controls.businessDate.setValue(event.value);
        this.updateMinMaxBusinessDate();
    }

    businessDateChange(event: MatDatepickerInputEvent<DateTime>) {
        if (!this.formGroup.controls.date.value) {
            this.formGroup.controls.date.setValue(event.value || undefined);
        }

        this.updateMinMaxBusinessDate();
    }

    updateMinMaxBusinessDate() {
        if (this.formGroup.controls.date.value) {
            this.minBusinessDate = this.formGroup.controls.date.value.minus({ day: 1 });
            this.maxBusinessDate = this.formGroup.controls.date.value.plus({ day: 1 });
        }
    }

    getCreateBody(): CreateBody | null {
        const timepunchIn = this.formGroup.controls.in.value;
        const out = this.formGroup.controls.out.value;
        const businessDate = this.formGroup.controls.businessDate.value;
        const businessUnit = this.formGroup.controls.businessUnit?.value;
        const comment = this.formGroup.controls.comment?.value;
        if (!timepunchIn || !businessDate) {
            return null;
        }

        const interval = getTimeInterval(this.date, timepunchIn, out);
        if (!interval) {
            return null;
        }

        const body: CreateBody = {
            businessDate,
            in: interval.from,
            out: interval.to,
        };

        body.businessUnitId = businessUnit?.id;

        if (comment) {
            body.comment = comment;
        }

        return body;
    }

    getUpdateBody(): UpdateBody {
        const body: UpdateBody = {};
        const form = this.formGroup.getRawValue();
        const interval = getTimeInterval(this.date, form.in, form.out);

        if (interval) {
            body.in = interval.from;
            body.out = interval.to;
        }

        body.businessUnitId = form.businessUnit?.id;
        body.businessDate = form.businessDate || undefined;

        if (form.comment) {
            body.comment = form.comment;
        }

        return body;
    }

    handleTimepunch() {
        this.fetching = true;

        const employeeId = this.formGroup.controls.employee.value instanceof Employee ? this.formGroup.controls.employee.value.id : null;

        this.formGroup.disable();
        if (!employeeId) {
            this.fetching = false;
            this.formGroup.enable();
            return;
        }

        if (this.timepunch) {
            const update = this.timepunchService.update(this.data.customerId, employeeId, this.timepunch.id, this.getUpdateBody());
            const timepunchSlip = this.getEditTimepunchSlipObservable(employeeId);
            update.pipe(
                switchMap(() => {
                    return this.documentServiceEnabled ? timepunchSlip : of(undefined);
                }),
                catchError((e) => {
                    if (e.url.includes('document-service')) {
                        this.matDialog.open(AlertDialogComponent, {
                            data: {
                                title: signal(this.translate.t('TIMEPUNCH_SLIP', 'payroll')),
                                text: signal(this.translate.t('TIMEPUNCH_SLIP_GENERATION_FAILED', 'payroll')),
                            },
                        }).afterClosed().subscribe(() => {
                            this.dialogRef.close(this.timepunch);
                        });
                    } else {
                        this.snackbar.t('EDIT_FAIL');
                    }
                    this.fetching = false;
                    this.formGroup.enable();
                    return EMPTY;
                }),
            ).subscribe(async (slipResponse) => {
                if (slipResponse) {
                    await this.snackbar.t('TIMEPUNCH_SLIP_GENERATED', 'payroll');
                }
                this.dialogRef.close(this.timepunch);
            });
        } else {
            const createBody = this.getCreateBody();
            if (createBody) {
                const create = this.timepunchService.create(this.data.customerId, employeeId, createBody);
                create.pipe(
                    catchError((e) => {
                        this.snackbar.t('CREATE_FAIL');
                        this.fetching = false;
                        this.formGroup.enable();
                        return throwError(() => e);
                    }),
                    switchMap((timepunch) => {
                        const timepunchSlip = this.documentService.createNewTimepunchSlipDocument(
                            this.currentService.getCustomer().id,
                            employeeId,
                            this.currentService.getUser().id,
                            timepunch.id,
                            this.currentService.getUser().name,
                            createBody.comment,
                            createBody.in,
                            createBody.out,
                        );
                        if (this.documentServiceEnabled) {
                            return timepunchSlip.pipe(
                                map((slipResponse) => [ timepunch, slipResponse ]),
                                catchError((e) => {
                                    if (e.url.includes('document-service')) {
                                        this.matDialog.open(AlertDialogComponent, {
                                            data: {
                                                title: signal(this.translate.t('TIMEPUNCH_SLIP', 'payroll')),
                                                text: signal(this.translate.t('TIMEPUNCH_SLIP_GENERATION_FAILED', 'payroll')),
                                            },
                                        }).afterClosed().subscribe(() => {
                                            this.dialogRef.close(timepunch);
                                        });
                                    } else {
                                        this.snackbar.t('CREATE_FAIL');
                                    }
                                    this.fetching = false;
                                    this.formGroup.enable();
                                    return EMPTY;
                                }),
                            );
                        } else {
                            return of([ timepunch, undefined ]);
                        }
                    }),
                ).subscribe(async ([ response, slipResponse ]) => {
                    if (slipResponse) {
                        await this.snackbar.t('TIMEPUNCH_SLIP_GENERATED', 'payroll');
                    }
                    this.dialogRef.close(response instanceof Timepunch ? response : undefined);
                });
            }
        }
    }

    getEditTimepunchSlipObservable(employeeId: number) {
        if (!this.timepunch) {
            return of(undefined);
        }
        const timepunchSlip = this.documentService.createEditTimepunchSlipDocument(
            this.currentService.getCustomer().id,
            employeeId,
            this.currentService.getUser().id,
            this.timepunch.id,
            this.currentService.getUser().name,
            this.formGroup.controls.comment.value,
            this.formGroup.controls.in.value,
            this.formGroup.controls.out.value,
            this.formGroup.controls.originalIn?.value,
            this.formGroup.controls.originalOut?.value,
        );
        // if timepunch edit includes in/out time change, send request to create timepunch slip document
        return this.documentServiceEnabled &&
               (this.timepunch.in !== this.formGroup.controls.in.value || this.timepunch.out !== this.formGroup.controls.out.value)
            ? timepunchSlip : of(undefined);
    }
}
