// @ts-nocheck
import { module } from 'angular';
module('eaw.scheduling').factory('StatisticsFactory', [ 'EawResource', function(EawResource) {
    const fac = this;
    fac.resource = EawResource.create('/customers/:customer/schedules/:schedule/employee_stats/:contractType');
    /**
     * @memberOf StatisticsFactory
     *
     * @param {integer} customerId
     * @param {integer} scheduleId
     * @param {integer} [contractTypeId=undefined]
     * @returns {*}
     */
    fac.getHours = (customerId, scheduleId, contractTypeId) => fac.resource.get({
        customer: customerId,
        schedule: scheduleId,
        contractType: contractTypeId,
    });
    return fac;
} ]);
