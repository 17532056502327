import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { ApiResponse } from '../interfaces/api-response';

export interface SettingGroupResponse extends ApiResponse {
    id: number;
    members_count: number;
    model: string;
    name: string;
    owner_id?: number | null;
    owner_type?: string | null;
    parent_id?: number | null;
    type: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
}

export class SettingGroup {
    id: number;
    membersCount: number;
    model: string;
    name: string;
    ownerId?: number | null;
    ownerType?: string | null;
    parentId?: number | null;
    type: string;
    createdAt?: DateTime | null;
    updatedAt?: DateTime | null;
    deletedAt?: DateTime | null;

    constructor(data: SettingGroupResponse) {
        this.id = data.id;
        this.membersCount = data.members_count;
        this.name = data.name;
        this.model = data.model;
        this.ownerId = data.owner_id;
        this.ownerType = data.owner_type;
        this.parentId = data.parent_id;
        this.type = data.type;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
