<span id="title" class="mat-body-2">{{ data.widget.info.translationKey | translate:data.widget.info.translationNs | async }}</span>

<div id="top-row">
    @if (data.widget.info.route) {
        <div class="top-button">
            <button mat-icon-button>
                <mat-icon>arrow_forward</mat-icon>
            </button>

            <span>{{'GO_TO_PAGE' | translate:'widgets' | async}}</span>
        </div>
    }

    <div class="top-button" (click)="close('change_color')">
        <button mat-icon-button>
            <mat-icon>palette</mat-icon>
        </button>

        <span>{{'COLOR' | translate | async}}</span>
    </div>

    @if (!isDefaultWidget) {
        <div class="top-button" (click)="close('delete')">
            <button mat-icon-button>
                <mat-icon>delete</mat-icon>
            </button>

            <span>{{'DELETE' | translate | async}}</span>
        </div>
    }

    @if ('admin' | permission | async) {
        <div class="top-button" (click)="showInfo()">
            <button mat-icon-button>
                <mat-icon>info</mat-icon>
            </button>

            <span>{{'INFORMATION' | translate | async}}</span>
        </div>
    }
</div>

@if (!data.widget.info.mini) {
    <mat-divider></mat-divider>

    <mat-selection-list [multiple]="false" >
        <mat-list-option [selected]="widths.default" (click)="changeWidth('default')">{{ 'WIDTH_NORMAL' | translate:'widgets' | async }}</mat-list-option>
        <mat-list-option [selected]="widths.wide" (click)="changeWidth('wide')">{{ 'WIDTH_WIDE' | translate:'widgets' | async }}</mat-list-option>
        <mat-list-option [selected]="widths.full" (click)="changeWidth('full')">{{ 'WIDTH_FULL' | translate:'widgets' | async }}</mat-list-option>
    </mat-selection-list>

    <mat-divider></mat-divider>

    <mat-selection-list [multiple]="false" >
        <mat-list-option [selected]="heights.default" (click)="changeHeight('default')">{{ 'HEIGHT_NORMAL' | translate:'widgets' | async }}</mat-list-option>
        <mat-list-option [selected]="heights.tall" (click)="changeHeight('tall')">{{ 'HEIGHT_TALL' | translate:'widgets' | async }}</mat-list-option>
    </mat-selection-list>
}


