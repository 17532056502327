<eaw-dialog-header>
    <span title>{{ 'PERMISSIONSET' | translate | async }}</span>
</eaw-dialog-header>
<mat-dialog-content>
    <form [formGroup]="form" autocomplete="off" id="permissionSetForm" class="tw-flex tw-flex-col">
        <mat-form-field>
            <mat-label>
                {{ 'NAME' | translate | async }}
            </mat-label>
            <input type="text" matInput formControlName="name">
        </mat-form-field>
        <mat-form-field>
            <mat-label>
                {{ 'DESCRIPTION' | translate | async }}
            </mat-label>
            <input type="text" matInput formControlName="description">
        </mat-form-field>
        <eaw-autocomplete
                formControlName="product"
                [options]="productAutocompleteService.options"
                [setter]="productAutocompleteService.setter()"
                [getter]="productAutocompleteService.getter()"/>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>
        {{ 'CANCEL' | translate | async }}
    </button>
    <eaw-action-button type="submit" formId="permissionSetForm" [loading]="submitting" (click)="save()">
        {{ 'SAVE' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
