<eaw-dialog-header>
    <span title>{{ 'CREATE_SCHEDULE' | translate:'scheduling' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-8">
    <form class="tw-mb-8 tw-grid tw-grid-cols-12 tw-gap-8" [formGroup]="baseForm">
        <mat-form-field class="tw-col-[1_/_7]">
            <mat-label>{{ 'TYPE' | translate | async }}</mat-label>
            <mat-select formControlName="type">
                @for (type of types; track type) {
                    <mat-option [value]="type">{{ type | uppercase | translateSync:'autosched' }}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field class="tw-col-[7_/_-1]">
            <mat-label>{{ 'TIME' | translate | async }}</mat-label>
            <mat-select formControlName="dateMode">
                <mat-option [value]="false">{{ 'USE_INTERVAL' | translateSync:'autosched' }}</mat-option>
                <mat-option [value]="true">{{ 'USE_DATES' | translateSync:'autosched' }}</mat-option>
            </mat-select>
        </mat-form-field>

        @if (!baseForm.controls.dateMode.value) {
            <mat-form-field eawDatePickerOptions class="tw-col-span-full">
                <mat-label>{{ 'ENTER_DATE_RANGE' | translate | async }}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker"
                                      [required]="!baseForm.controls.dateMode.value">
                    <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async"
                           autocomplete="off">
                    <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async"
                           autocomplete="off">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        } @else {
            <div id="dates-container"
                 class="mat-elevation-z2 tw-col-span-full tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-8"
                 [class.error]="!baseForm.controls.dates.value?.length">
                <mat-calendar [dateClass]="dateClass"
                              [style.min-width]="'300px'"
                              (selectedChange)="dateSelected($event)"/>

                @if (baseForm.controls.dates.value?.length) {
                    <mat-chip-set
                            role="list"
                            class="mat-mdc-chip-set-stacked tw-p-12 tw-flex tw-flex-col tw-justify-center tw-content-start tw-basis-full">
                        @for (date of baseForm.controls.dates.value; track date) {
                            <mat-chip (removed)="dateRemoved($any(date))" role="listitem">
                                {{ $any(date) | DateTime:'DATE_MED' }}
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                        }
                    </mat-chip-set>
                } @else {
                    <span id="info-text"
                          class="mat-body-2 tw-p-12">{{ 'AUTO_SCHED_DATES_HELP' | translate:'scheduling' | async }}</span>
                }
            </div>
        }
    </form>

    @switch (baseForm.controls.type.value) {
        @case ('generate') {
            <form [formGroup]="generateForm" class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-16">
                <eaw-schedule-template-autocomplete controlName="template" [customerId]="data.customerId"/>

                <eaw-schedule-template-autocomplete [label]="'RECURRING_TEMPLATE' | translate:'scheduling'"
                                                    controlName="recurringTemplate" [customerId]="data.customerId"/>

                <eaw-week-setup-autocomplete [label]="'WEEK_SETUP' | translate:'scheduling'" controlName="weekSetup"
                                             [customerId]="data.customerId"/>

                <mat-form-field>
                    <mat-label>{{ 'GRANULARITY' | translate:'scheduling' | async }}</mat-label>
                    <mat-select formControlName="granularity">
                        @for (granularity of granularities; track granularity.value) {
                            <mat-option
                                    [value]="granularity.value">{{ (granularity.seconds | duration:[]:{unitDisplay: 'long'}) }}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>

                <div class="tw-flex tw-flex-col tw-gap-16">
                    <mat-slide-toggle
                            formControlName="shouldClearPeriod">{{ 'SHOULD_CLEAR_PERIOD' | translate:'scheduling' | async }}
                    </mat-slide-toggle>
                    <mat-slide-toggle
                            formControlName="mergeShortShifts">{{ 'MERGE_SHORT_SHIFTS' | translate:'scheduling' | async }}
                    </mat-slide-toggle>
                </div>
            </form>
        }
        @case ('populate') {
            <form [formGroup]="populateForm" class="tw-flex tw-flex-col tw-gap-16">
                <mat-slide-toggle
                        formControlName="allowUnqualified">{{ 'ALLOW_UNQUALIFIED' | translate:'scheduling' | async }}
                </mat-slide-toggle>
                <mat-slide-toggle
                        formControlName="allowOverContract">{{ 'ALLOW_OVER_CONTRACT' | translate:'scheduling' | async }}
                </mat-slide-toggle>
                <mat-slide-toggle
                        formControlName="preferMultipleShifts">{{ 'PREFER_MULTIPLE_SHIFTS_PER_DAY' | translate:'scheduling' | async }}
                </mat-slide-toggle>
            </form>
        }
        @case ('vlh') {
            @if (vlhForm) {
                <div [formGroup]="vlhForm" class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-16">
                    <mat-form-field class="tw-col-span-full">
                        <mat-label>{{ 'GRANULARITY' | translate:'scheduling' | async }}</mat-label>
                        <mat-select formControlName="granularity">
                            @for (granularity of granularities; track granularity.value) {
                                <mat-option
                                        [value]="granularity.value">{{ (granularity.seconds | duration:[]:{unitDisplay: 'long'}) }}
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>

                    <div class="tw-col-span-full">
                        <mat-slide-toggle
                                formControlName="mergeShortShifts">{{ 'MERGE_SHORT_SHIFTS' | translate:'scheduling' | async }}
                        </mat-slide-toggle>
                    </div>

                    @if (vlhForm.controls.adjustments?.controls) {
                        <ng-container formGroupName="adjustments">
                            <eaw-info-box icon="tips_and_updates" class="tw-col-span-full tw-my-16">
                                <span infoText>{{ 'VLH_ADJUSTMENT_EXPLANATION' | translate:'scheduling' | async }}</span>
                            </eaw-info-box>

                            @for (control of vlhForm.controls.adjustments?.controls || {} | keyvalue; track control.key) {
                                <mat-form-field>
                                    <mat-label>{{ control.key.toUpperCase() | translate:'scheduling' | async }}</mat-label>
                                    <input #adjustmentInput [formControlName]="control.key" matInput type="number"
                                           min="0" step="0.01" autocomplete="off">
                                    <mat-hint>
                                        <ng-container [ngTemplateOutlet]="percentHint"
                                                      [ngTemplateOutletContext]="{$implicit: adjustmentInput.value}"></ng-container>
                                    </mat-hint>
                                </mat-form-field>
                            }
                        </ng-container>
                    }
                </div>
            }
        }
    }
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-raised-button [mat-dialog-close]>{{ 'CLOSE' | translate | async }}</button>

    <eaw-action-button [disabled]="!formValid()" [loading]="creating"
                       (click)="create()">{{ 'CREATE' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>

<ng-template #percentHint let-value>
    @if (value > 1) {
        {{ ('INCREASE_PERCENT' | translate:'scheduling':{percent: value - 1 | eawPercent} | async) }}
    } @else if (value < 1) {
        {{ ('DECREASE_PERCENT' | translate:'scheduling':{percent: 1 - value | eawPercent} | async) }}
    }
</ng-template>
