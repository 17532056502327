import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { Link, LinkResponse } from '../models/link';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { HttpClient } from '@angular/common/http';

interface LinkCreate {
    text: string;
    url: string;
    description?: string;
    inheritable: boolean;
    main_menu: boolean;
}

interface GetAllOptions extends PaginationOptions {
    main_menu?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LinksService {
    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {
    }

    create(customerId: number, data: LinkCreate): Observable<Link> {
        return this.http.post<LinkResponse>(`/customers/${customerId}/hyper_links`, data).pipe(classifyItem(Link));
    }

    getAll(customerId: number, options?: GetAllOptions): Observable<ArrayPaginatedResponse<Link>> {
        return this.http.get<ArrayPaginatedResponse<LinkResponse>>(`/customers/${customerId}/hyper_links`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(Link));
    }

    update(customerId: number, linkId: number, data: Partial<LinkCreate>): Observable<Link> {
        return this.http.put<LinkResponse>(`/customers/${customerId}/hyper_links/${linkId}`, data).pipe(classifyItem(Link));
    }

    delete(customerId: number, linkId: number): Observable<null> {
        return this.http.delete<null>(`/customers/${customerId}/hyper_links/${linkId}`);
    }
}
