import { module } from 'angular';

module('eaw.customers').component('userGroupMemberAutocomplete', {
    controllerAs: '$acCtrl',
    template: `<label for="autocomplete{{$id}}" ng-if="$acCtrl.label" ng-bind="::$acCtrl.label | i18next"></label>
<md-chips ng-model="$acCtrl.members"
          md-on-add="$acCtrl.onChange()"
          md-on-remove="$acCtrl.onChange()"
          ng-required="$acCtrl.required"
          md-require-match="true">
    <md-autocomplete flex="100"
                     md-input-id="autocomplete{{$id}}"
                     md-selected-item="$acCtrl.selectedMember"
                     md-search-text="search"
                     md-min-length="0"
                     md-items="item in $acCtrl.getMembers(search)"
                     md-item-text="item.name"
                     md-delay="300"
                     placeholder="{{'START_TYPING' | i18next}}">
        <span>{{item.name}}</span>
    </md-autocomplete>
    <md-chip-template>
        <span>{{$chip.name}}</span>
    </md-chip-template>
</md-chips>
`,
    require: {
        ngModel: 'ngModel',
    },
    bindings: {
        userGroupId: '<',
        customerId: '<',
        label: '<?',
    },
    controller: [ 'UserGroupService', function(UserGroupService) {
        // @ts-ignore
        const ctrl = this;

        ctrl.members = [];

        ctrl.$onChanges = () => {
            ctrl.ngModel.$formatters.unshift((modelValue: any) => {
                if (Array.isArray(modelValue)) {
                    ctrl.members = modelValue;
                }
            });
        };

        ctrl.getMembers = (search: string) => UserGroupService.getMembers({
            userGroupId: ctrl.userGroupId,
            customerId: ctrl.customerId,
            filter: search,
            fields: [ 'name' ],
            order_by: 'last_name',
            per_page: 20,
        }).$promise.then((resp: any) => resp.data);

        ctrl.onChange = () => {
            ctrl.ngModel.setViewValue(ctrl.members);
        };
    } ],
});
