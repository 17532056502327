import moment, { Moment } from 'moment-timezone';
import { module } from 'angular';
import { Storage } from '../../../shared/utils/storage';
import { IntervalOld } from '../../../shared/angularjs/interval-old';
import { UIRouter } from '@uirouter/core';
module('eaw.leave-shifts').controller('leaveShiftController', [ '$uiRouter', function($uiRouter: UIRouter) {
    // @ts-ignore
    const ctrl = this;

    ctrl.$onInit = async () => {
        ctrl.key = `${$uiRouter.globals.current.name}.date-interval`;
        const interval = await Storage.getItem<{from: Moment, to: Moment|null}>(Storage.prefix(ctrl.key));
        if (interval) {
            ctrl.interval = new IntervalOld(interval);
        } else {
            ctrl.interval = new IntervalOld(moment().startOf('M'), moment().endOf('M'));
        }
        await ctrl.update();
    };

    ctrl.update = async () => {
        await Storage.setItem(Storage.prefix(ctrl.key), ctrl.interval);
    };
} ]);
