<eaw-page-header [buttons]="headerButtons" [useBigFilter]="true">
    <span title>{{ 'EMPLOYEE_plural' | translate : 'admin' | async }}</span>
    <div class="filter-card" bigFilter>
        <form class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-x-16" [formGroup]="form"
              (submit)="updateTable()">
            <mat-form-field>
                <mat-label>{{ 'SEARCH_FIELD_plural' | translate: 'company' | async }}</mat-label>
                <mat-select formControlName="searchFields" multiple>
                    @for (searchField of selectableSearchFields(); track searchField.field) {
                        <mat-option [value]="searchField.field">{{ searchField.text }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'SEARCH' | translate | async }}</mat-label>
                <input matInput type="text" formControlName="search">
            </mat-form-field>

            <div class="tw-row-start-3 sm:tw-row-start-4 tw-justify-self-start">
                <button class="tw-mr-16" mat-raised-button color="accent" [disabled]="loading()">
                    {{ 'UPDATE' | translate | async }}
                </button>

                <button mat-raised-button (click)="resetForm()" [disabled]="loading()">
                    {{ 'RESET' | translate | async }}
                </button>
            </div>
        </form>
    </div>
</eaw-page-header>

<mat-card>
    <eaw-data-table
        #table
        [columns]="columns()"
        [getData]="getData()"
        [goTo]="{state: 'eaw/app/admin/customers/view/employees/view/information', params: [{stateKey: 'id', itemKey: 'customerId'}, {stateKey: 'emp', itemKey: 'id'}]}"
        initialSortDirection="asc"
        initialSortBy="name">
    </eaw-data-table>
</mat-card>
