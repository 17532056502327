// @ts-nocheck
import { module } from 'angular';
import { PaginationOld } from '../../../../utils/pagination-old';
module('eaw.misc').factory('PermissionFactory', [ 'EawResource', function(EawResource) {
    const factory = this;
    const resource = EawResource.create('/permissions');
    factory.getAll = (args) => resource.get({
        ...PaginationOld.getParams(args),
    });
    return factory;
} ]);
