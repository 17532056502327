import { Component, Inject, OnInit } from '@angular/core';
import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ProjectionDay, ProjectionDayUpdate } from '../../models/projection-day';
import { MatTableModule } from '@angular/material/table';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { ProjectionsService } from '../../http/projections.service';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { ProjectionsTransposed } from '../../models/projection-day-details';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { catchError, EMPTY, forkJoin, of } from 'rxjs';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { DateTime } from 'luxon';

export interface DailyDetailsDialogData extends DialogData {
    customerId: number;
    projectionDay: ProjectionDay;
}

type ProjectionTimeSlotLine = FormGroup<{
    transactions: FormControl<number>,
}>;

export interface ProjectionTimeSlot extends ProjectionsTransposed {
    edited?: boolean,
    realAt?: number,
    form?: ProjectionTimeSlotLine,
    blocked: boolean,
}

export interface TotalsTimeSlot {
    transactions: number,
    sales: number,
    at: number,
    realTransactions: number,
    realAt: number,
    realSales: number,
    warning: boolean,
}

@Component({
    selector: 'eaw-daily-details-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        MatTableModule,
        MatInputModule,
        ReactiveFormsModule,
        ActionButtonComponent,
        DateTimePipe,
        NumberPipe,
        DecimalPipe,
    ],
    templateUrl: './daily-details-dialog.component.html',
    styleUrl: './daily-details-dialog.component.scss',
})

export class DailyDetailsDialogComponent extends DialogComponent implements OnInit {
    protected loading = true;
    protected saving = false;
    dataSource: ProjectionTimeSlot[] = [];
    displayedColumns: string[] = [ 'time', 'transactions', 'atGenerated', 'sales', 'realGc', 'realAt', 'realSales' ];
    form = new FormGroup({
        comment: new FormControl<string | null>(this.data.projectionDay.comment || ''),
    });

    today: DateTime = DateTime.now();
    sumValid = true;
    totals: TotalsTimeSlot = {
        transactions: 0,
        sales: 0,
        at: 0,
        realSales: 0,
        realTransactions: 0,
        realAt: 0,
        warning: false,
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: DailyDetailsDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<DailyDetailsDialogData>,
        @Inject(ProjectionsService) private projectionsService: ProjectionsService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
    ) {
        data.size ||= DialogSize.Large;
        super(dialogRef, data);
    }

    ngOnInit(): void {
        this.updateTable();
    }

    updateTable() {
        if (this.data?.projectionDay?.projections) {
            this.totals = {
                transactions: this.data.projectionDay.projections.transactionsEdited?.value || this.data.projectionDay.projections.transactions?.value || 0,
                sales: this.data.projectionDay.projections.salesGenerated?.value || this.data.projectionDay.projections.sales?.value || 0,
                at: this.data.projectionDay.projections.atEdited?.value || this.data.projectionDay.projections.atGenerated?.value || 0,
                realSales: 0,
                realTransactions: 0,
                realAt: 0,
                warning: false,
            };

            this.projectionsService.getTimeSlotProjections(this.data.customerId, this.data.projectionDay.date.toFormat('yyyy-MM-dd')).pipe(
                catchError(() => {
                    this.loading = false;
                    return EMPTY;
                }),
            ).subscribe((res) => {
                this.loading = true;
                this.dataSource = [];
                this.sumValid = true;
                let totalTransactions = 0;
                let totalSales = 0;
                res.projectionsTransposed.forEach((timeSlot: ProjectionTimeSlot) => {
                    const timeSlotFormed = { ...timeSlot };
                    timeSlotFormed.blocked = timeSlot.time.startOf('day') < this.today.startOf('day');
                    timeSlotFormed.transactions.value = +(timeSlot.transactions.percent * this.totals.transactions / 100).toFixed(2);
                    timeSlotFormed.sales.value = +(timeSlot.sales.percent * this.totals.sales / 100).toFixed(2);
                    timeSlotFormed.atGenerated = {
                        value: timeSlot.transactions.value > 0 ? +(timeSlotFormed.sales.value/timeSlotFormed.transactions.value).toFixed(2) : 0,
                        percent: 0,
                    };
                    timeSlotFormed.realAt = timeSlot.realTransactions.value > 0 ? +(timeSlot.realSales.value / timeSlot.realTransactions.value).toFixed(2) : 0;
                    timeSlotFormed.form = this.getFormLine(timeSlot);

                    totalSales += timeSlot.realSales.value || 0;
                    totalTransactions += timeSlot.realTransactions.value || 0;

                    this.dataSource.push(timeSlotFormed);
                });
                this.totals.realTransactions = totalTransactions;
                this.totals.realSales = totalSales;
                this.totals.realAt = totalTransactions > 0 ? +(totalSales/totalTransactions).toFixed(2) : 0;

                if (this.totals.realTransactions >= this.totals.transactions*1.2) {
                    this.totals.warning = true;
                }
                this.loading = false;
            });
        }
    }

    saveForm(): void {
        const payload = this.preparePayload();
        const saveTimeSltProjectionsObservable = payload
            ? this.projectionsService.saveTimeSlotProjections(this.data.customerId, this.data.projectionDay.date.toFormat('yyyy-MM-dd'), payload)
            : of(true);
        const setDayCommentObservable = this.form.controls.comment.value !== this.data.projectionDay.comment
            ? this.projectionsService.setDayComment(this.data.customerId, this.data.projectionDay.date.toFormat('yyyy-MM-dd'), { comment: this.form.controls.comment.value || null })
            : of(true);
        this.saving = true;
        forkJoin([ saveTimeSltProjectionsObservable, setDayCommentObservable ]).subscribe((results) => {
            if (results) {
                void this.snackBarService.t('UPDATED');
                this.dialogRef.close(true);
            }
        });
    }

    getFormLine(config: ProjectionTimeSlot): ProjectionTimeSlotLine {
        return new FormGroup({
            transactions: new FormControl(config.transactions.value, {
                validators: [ ],
                nonNullable: true,
            }),
        });
    }

    transactionChange(timeSlot: ProjectionTimeSlot, event: Event) {
        const target = event.target as HTMLInputElement;
        if (timeSlot) {
            timeSlot.edited = true;
            if (isNaN(Number(target.value))) {
                timeSlot.transactions.value = 0;
                timeSlot.transactions.percent = 0;
            } else {
                timeSlot.transactions.value = +(Number(target.value).toFixed(2));
                timeSlot.transactions.percent = +(+target.value/this.totals.transactions*100).toFixed(2);
            }
        }
        this.recalculateTransactions();
    }

    recalculateTransactions() {
        this.sumValid = true;
        const total = this.totals.transactions || 0;
        let newTotal = 0;
        let uneditedSum = 0;
        this.dataSource.forEach((timeSlot) => {
            newTotal += timeSlot.transactions.value;
            if (!timeSlot.edited) {
                uneditedSum+=timeSlot.transactions.value;
            }
        });
        const adjustment = newTotal - total;
        newTotal = 0;
        this.dataSource.forEach((timeSlot) => {
            if (timeSlot.edited || !uneditedSum) {
                newTotal += timeSlot.transactions.value;
                return;
            }
            timeSlot.transactions.value -= timeSlot.transactions.value * (adjustment / uneditedSum);
            if (timeSlot.transactions.value < 0) {
                timeSlot.transactions.value = 0;
            }
            newTotal += timeSlot.transactions.value;
        });
        // don't allow saving if new sum is not equal to old sum
        if (newTotal.toFixed() !== total.toFixed()) {
            this.sumValid = false;
        }
    }

    preparePayload() {
        if (this.dataSource.find((timeSlot) => timeSlot.edited)) {
            const response: ProjectionDayUpdate = {
                reference_days: [],
                projections: {
                    transactionsEdited: [],
                    transactions: [],
                    'sales.net': [],
                },
            };
            this.dataSource.forEach((timeSlot) => {
                let transactionValue = +timeSlot.transactions.value;
                if (timeSlot.form && timeSlot.edited) {
                    transactionValue = +timeSlot.form.controls.transactions.value;
                }

                const transactions = {
                    value: 0,
                    percent: transactionValue/this.totals.transactions*100,
                    time: timeSlot.time.toFormat('yyyy-MM-dd HH:mm:ss'),
                };
                const sales = {
                    value: 0,
                    percent: +timeSlot.sales.percent,
                    time: timeSlot.time.toFormat('yyyy-MM-dd HH:mm:ss'),
                };
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                response.projections['transactions']!.push(transactions);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                response.projections['sales.net']!.push(sales);
            });
            return response;
        }
        return null;

    }
}
