import { Directive, ElementRef, Inject } from '@angular/core';

@Directive({
    selector: '[eawMuxHandler]',
    standalone: true,
})
export class MuxHandlerDirective {
    constructor(
        @Inject(ElementRef) private elementRef: ElementRef,
    ) {
        this.elementRef.nativeElement.addEventListener('click', (event: MouseEvent) => {
            // We have to find the clicked item deep inside Mux' nested shadow dom so we can actually click it
            // without getting caught in Angular NgZone
            const mux = (event.target as HTMLElement).shadowRoot?.elementsFromPoint(event.x, event.y);
            if (mux?.length) {
                const muxControls = mux[0]?.shadowRoot?.elementsFromPoint(event.x, event.y);

                if (muxControls?.length) {
                    muxControls[0]?.dispatchEvent(new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                    }));
                }
            }
        });
    }
}
