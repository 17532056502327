import { FormGroup } from '@angular/forms';
import { CustomFieldControl } from '../../custom-fields/components/custom-field-input/custom-field-input.component';
import { DateTimeConverter } from './date-time-converter';
import { BusinessDate } from './business-date';
import { DateTime } from 'luxon';

export type CustomFieldGroupValue = string | number | null;
export type CustomFieldGroupDateTime = string | null | undefined;

// For one field
export interface CustomFieldValueWithInterval {
    value: CustomFieldGroupValue;
    from: CustomFieldGroupDateTime;
    to: CustomFieldGroupDateTime;
}

export type CustomFieldKeyValues = Record<string, CustomFieldGroupValue>;

// For a group of multiple fields
export class CustomFieldsGroup extends FormGroup<Record<string, CustomFieldControl>> {
    private modifiedFields: Set<string> = new Set();

    constructor() {
        super({});
    }

    override addControl(name: string, control: CustomFieldControl, options?: { emitEvent?: boolean }) {
        super.addControl(name, control, options);
        this.modifiedFields.add(name);
    }

    hasModifiedField(key: string) {
        return this.modifiedFields.has(key);
    }

    addModifiedField(key: string) {
        this.modifiedFields.add(key);
    }

    removeModifiedField(key: string) {
        this.modifiedFields.delete(key);
    }

    getEntryValue(key: string): CustomFieldValueWithInterval | null {
        const entry = this.value[key];
        if (entry?.value == null) {
            return null;
        }

        const type = entry.customField?.type || 'string';
        const from = entry.from ? DateTimeConverter.convertDateTimeToUtcString(entry.from) : entry.from;
        const to = entry.to ? DateTimeConverter.convertDateTimeToUtcString(entry.to) : entry.to;

        if (entry.value instanceof BusinessDate) {
            return { value: entry.value.toString(), from, to };
        }

        if (entry.value instanceof DateTime) {
            return { value: DateTimeConverter.convertDateTimeToBusinessDate(entry.value), from, to };
        }

        if (typeof entry.value === 'boolean') {
            return { value: String(+entry.value), from, to };
        }

        if (type === 'decimal' || type === 'integer') {
            return { value: entry.value, from, to };
        }

        return { value: String(entry.value), from, to };
    }

    getValuesWithInterval(includeNotModified?: boolean): Record<string, CustomFieldValueWithInterval> {
        return Object.entries(this.value || {}).reduce((acc, [ key, value ]) => {
            if (value && (this.modifiedFields.has(key) || includeNotModified)) {
                const value = this.getEntryValue(key);
                if (value) {
                    acc[key] = value;
                }
            }

            return acc;
        }, {} as Record<string, CustomFieldValueWithInterval>);
    }

    /**
     * Get values of all fields as a plain object
     * @param {boolean} includeNotModified
     * @returns {CustomFieldKeyValues}
     */
    getValues(includeNotModified?: boolean): CustomFieldKeyValues {
        return Object.entries(this.value || {}).reduce((acc, [ key, value ]) => {
            if (value && (this.modifiedFields.has(key) || includeNotModified)) {
                const value = this.getEntryValue(key);
                if (value) {
                    acc[key] = value.value;
                }
            }

            return acc;
        }, {} as CustomFieldKeyValues);
    }
}
