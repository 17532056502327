import { uniqBy } from 'lodash-es';
import { debounce } from 'lodash-es';
import { module } from 'angular';
import { NotificationType } from '../../../../notifications/classes/notification-type';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
import { WebsocketService } from '../../../../shared/services/websocket.service';

module('eaw.messages').component('eawConversationList', {
    template: `<div class="loading-conversations md-subhead" ng-if="$list.gettingThreads && !$list.firstLoad" ng-i18next="messages:LOADING_CONVERSATIONS"></div>

<md-list flex class="thread-list md-dense tw-p-0">
    <md-list-item ng-class="{archived: thread.archivals_count}" class="md-3-line" ng-repeat="thread in $list.threads track by thread.id" ng-click="$list.openThread(thread)">
        <eaw-profile-picture-old ng-if="thread.belongsToMe && !thread.is_blind" user="thread.recipients[0]"></eaw-profile-picture-old>
        <eaw-profile-picture-old ng-if="!thread.belongsToMe || thread.is_blind" user="thread.user"></eaw-profile-picture-old>

        <div class="md-list-item-text" layout="column">
            <h3 class="overflow-ellipsis"
                ng-if="thread.belongsToMe && !thread.is_blind"
                ng-i18next="[i18next]({count: thread.recipients_count > 1 ? thread.recipients_count - 1 : 1, name: thread.sender.name})messages:X_AND_COUNT_OTHERS"></h3>
            <h3 class="overflow-ellipsis"
                ng-if="!thread.belongsToMe || thread.is_blind"
                ng-i18next="[i18next]({count: thread.recipients_count > 1 ? thread.recipients_count - 1 : 1, name: thread.sender.name})messages:X_AND_COUNT_OTHERS">
            </h3>
            <h4 ng-bind="thread.subject"></h4>
            <p md-colors="{color: 'red'}" ng-if="thread.unread_count > 0" ng-i18next="[i18next]({count: thread.unread_count})messages:X_NEW_MESSAGE"></p>
        </div>

        <md-divider></md-divider>
    </md-list-item>
</md-list>
<md-list class="md-dense" ng-if="$list.nextPage">
    <md-list-item>
        <span flex></span>
        <md-button class="md-secondary" ng-disabled="$list.gettingThreads" ng-click="$list.loadThreads()" ng-i18next="messages:LOAD_OLDER"></md-button>
    </md-list-item>
</md-list>
`,
    controllerAs: '$list',
    require: {
        msgConv: '^eawMessageConversations',
    },
    controller: [ 'Message', 'WebsocketDowngrade', '$state', '$scope', function(Message, WebsocketDowngrade: WebsocketService, $state, $scope) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.loadThreads();
            ctrl.onNewMessage();

            WebsocketDowngrade.notification(`users.${CurrentOld.getUser()['id']}`, (data) => {
                if (data.type !== NotificationType.new_message.class) {
                    return;
                }
                if (!data.message) {
                    return;
                } // Skip if no message
                if (data.message?.thread_id) {
                    return;
                } // Skip if message has a thread id
                // Or else add the thread
                ctrl.addThread(null, data.message);
            });
        };

        ctrl.onNewMessage = () => {
            $scope.$on('messages:new_message_sent', ctrl.addThread);
        };
        ctrl.addThread = debounce(async function() {
            const resp = await Message.getThreads(1, 1, {
                archived: $state.params['include_archived'],
                count: [ 'archivals' ],
            });
            const msg = resp.data[0];
            if (ctrl.threads.find((t: any) => t.id === msg.id)) {
                return;
            }
            ctrl.threads.unshift(msg);
        }, 1000);
        ctrl.loadThreads = async () => {
            ctrl.page = ctrl.page || 1;
            ctrl.gettingThreads = true;
            const resp = await Message.getThreads(ctrl.page, 32, {
                archived: $state.params['include_archived'],
                count: [ 'archivals' ],
            });
            ctrl.threads = uniqBy((ctrl.threads || []).concat(resp.data), (t: any) => t.id);
            ctrl.nextPage = !!resp.next_page_url;
            ctrl.page = ctrl.nextPage ? ctrl.page + 1 : ctrl.page;
            ctrl.firstLoad = true;
            delete ctrl.gettingThreads;
        };
        ctrl.openThread = (thread: any) => {
            ctrl.msgConv.convListSideNav.toggle();
            $state.go(`eaw/app/messages/conversations/thread`, {
                id: thread.id,
            }).then(() => {
                thread.unread = 0; // Set unread to none when the thread is opened
            });
        };
    } ],
});
