<eaw-page-header [fabButton]="fabButton" [buttons]="headerButtons">
    <span title>{{'CUSTOMER_plural' | translate | async}}</span>
    <span subtitle>{{(total() | eawNumber:0)}}</span>

    <form smallFilter role="search" class="tw-flex tw-flex-row tw-justify-center">
        <mat-form-field class="standalone">
            <mat-label>{{'SEARCH' | translate | async}}</mat-label>
            <input [formControl]="filterControl" matInput type="text" autofocus autocomplete="off">
        </mat-form-field>
    </form>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        <eaw-data-table
            [columns]="columns()"
            [goTo]="{state: 'eaw/app/admin/customers/view/info', params: [{stateKey: 'id', itemKey: 'id'}]}"
            [getData]="getData()"
            initialSortBy="id">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
