<eaw-page-header [fabButton]="fabButton" [buttons]="pageButtons">
    <span title>{{'USER_plural' | translate | async}}</span>
    <span subtitle>{{(total | eawNumber:0)}}</span>

    <form smallFilter [formGroup]="form" class="tw-flex tw-flex-row tw-items-center tw-gap-8">
        <mat-form-field class="standalone">
            <mat-label>{{'FILTER' | translate | async}}</mat-label>
            <input matInput formControlName="filter" autocomplete="off">
        </mat-form-field>

        <mat-form-field class="standalone">
            <mat-label>{{'SEARCH_FIELD_plural' | translate:'company' | async}}</mat-label>
            <mat-select formControlName="fields" [multiple]="true" (closed)="selectClose()">
                <mat-option *ngFor="let field of fields" [value]="field.value">{{field.name | translateSync }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</eaw-page-header>

<mat-card class="mat-compact-card mat-card-contained">
    <mat-card-content>
        <eaw-data-table
            #dataTableComponent
            [columns]="columns"
            [request]="request"
            [goTo]="{state: 'eaw/app/admin/users/view/info', params: [{itemKey: 'id', stateKey: 'id'}]}"
            initialSortBy="id"
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
