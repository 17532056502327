// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

const template1 = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-i18next="newsletter:NEWSLETTER_plural"></span>
        </md-card-header-text>

        <card-btn-float on-click="$ctrl.createNewsletter()"></card-btn-float>
    </md-card-header>

    <md-card-content>
        <eaw-dynamic-ng-table get-data="$ctrl.getData(pagination)"
                              columns="$ctrl.columns"
                              go-to="eaw/app/chain_ops/newsletter/update/issues"
                              cells="$ctrl.cells"
                              reload-table="$ctrl.reload_table">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;

module('eaw.newsletter').component('manageNewsletters', {
    template: template1,
    controllerAs: '$ctrl',
    bindings: {
        user: '<',
        customer: '<', // Object
    },
    controller: [ 'Newsletter', '$state', '$mdDialog', 'ToastService', 'loadingBar', function(Newsletter, $state, $mdDialog, ToastService, loadingBar) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.newsletters = [];
            ctrl.loading = false;
            ctrl.columns = [
                { title: t('newsletter:TITLE') },
                { title: t('newsletter:LATEST_ISSUE') },
                { title: t('newsletter:LATEST_ISSUE_DATE') },
                { title: '' },
            ];
            ctrl.cells = [
                { template: 'item.viewLanguage.title' },
                { template: 'item.issues[0].viewLanguage.title' },
                { template: `item.issues[0].publish_date.format('MMMM DD, YYYY')` },
                {
                    buttons: [
                        {
                            icon: 'delete',
                            type: 'warn',
                            click: ctrl.deleteNewsletter,
                        },
                    ],
                },
            ];
        };

        ctrl.getData = async (pagination) => {
            ctrl.loading = true;

            const resp = await Newsletter.getAdminAll({
                ...pagination,
                customerId: ctrl.customer.id,
                'with[]': [ 'newsletterIssues' ],
            });

            ctrl.newsletters = resp.data.map((newsletter) => {
                newsletter.setLanguage(ctrl.customer, ctrl.user);
                return newsletter;
            });

            ctrl.loading = false;

            return resp;
        };

        ctrl.createNewsletter = async () => {
            loadingBar.open(null, false, t('newsletter:GENERATING_NEWSLETTER'));

            const resp = await Newsletter.create({
                customer_id: ctrl.customer.id,
                language_code: CurrentOld.user.language_code,
                default_title: t('newsletter:DEFAULT_TITLE'),
                default_description: '',
            });

            ToastService.toast(t('newsletter:NEWSLETTER_CREATED'));
            await $state.go('eaw/app/chain_ops/newsletter/update/settings', { key: resp.key });

            loadingBar.close();
        };

        ctrl.deleteNewsletter = (newsletter) => {
            $mdDialog.show(
                $mdDialog.confirm()
                    .theme('delete')
                    .title(newsletter.title)
                    .textContent(t('newsletter:DELETE_NEWSLETTER_TEXT'))
                    .ok(t('DELETE'))
                    .cancel(t('CANCEL')),
            ).then(() => {
                ToastService.toast(t('newsletter:NEWSLETTER_DELETING'));
                Newsletter.delete(newsletter.customer_id, newsletter.key).then(() => {
                    ToastService.toast(t('newsletter:NEWSLETTER_DELETED'));
                    ctrl.reload_table = true;
                });
            });
        };
    } ],
});
