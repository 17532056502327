import { SimplePermission } from '../../../shared/interfaces/simple-permission';
import { UserResponse } from '../../../shared/models/user';
import { RoleAssignmentResponse } from './role-assignment';
import { ApiResponse } from '../../../shared/interfaces/api-response';

export interface RoleResponse extends ApiResponse {
    id: number
    name: string
    customer_id: number
    parent_id?: number | null
    parent?: RoleResponse
    assignments_count?: number;
    all_assignments_count?: number;
    permissions?: any[]
    users?: UserResponse[];
    permission_sets?: any[] // TODO: add PermissionSet interface
        assignments?: RoleAssignmentResponse[]
}

export class Role {
    id: number;
    customerId: number;
    name: string;
    parentId?: number | null;
    parent?: Role | null;
    permissions?: SimplePermission[];
    permissionSets?: any[];
    assignmentCount?: number;
    assigned = false;
    users?: UserResponse[];

    constructor(role: RoleResponse) {
        this.id = role.id;
        this.name = role.name;
        this.customerId = role.customer_id;
        this.parentId = role.parent_id;
        this.parent = role.parent ? new Role(role.parent) : null;
        this.permissions = role.permissions;
        this.permissionSets = role.permission_sets;
        this.assignmentCount = role.assignments_count;
        this.users = role.users;

        if (role.all_assignments_count != undefined) {
            this.assigned = role.all_assignments_count > 0;
        }
    }
}
