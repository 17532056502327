import { Component, ElementRef, EventEmitter, HostBinding, inject, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { uniqueId } from '../../angularjs/modules/misc/services/easy-funcs.service';

@Component({
    selector: 'eaw-file-selector',
    templateUrl: './file-selector.component.html',
    styleUrl: './file-selector.component.scss',
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: FileSelectorComponent,
        },
    ],
    standalone: true,
})
export class FileSelectorComponent implements OnDestroy, OnChanges, ControlValueAccessor, MatFormFieldControl<File | undefined> {
    readonly ngControl = inject(NgControl, { optional: true, self: true });
    protected readonly parentFormField = inject(MatFormField, { optional: true });
    private readonly elementRef = inject(ElementRef);

    @HostBinding() id = uniqueId('eaw-file-input-');

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        return !!this.placeholder || !this.empty;
    }

    @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;

    @Input() label?: string | null;
    @Input() subtext?: string | null;
    @Input() icon?: string;
    @Input() fileFormats?: string;

    @Input()
    get ariaDescribedBy(): string[] {
        return [ this.userAriaDescribedBy ];
    }

    set ariaDescribedBy(ids: string[]) {
        this.setDescribedByIds(ids);
    }

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }

    set placeholder(p: string) {
        this._placeholder = p;
        this.stateChange();
    }

    @Input()
    get required() {
        return this._required;
    }

    set required(req) {
        this._required = req;
        this.stateChange();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = value;
        this.stateChange();
    }

    @Output() fileChange = new EventEmitter<File | undefined>();

    file?: File;
    stateChanges = new Subject<void>();
    focused = false;

    readonly userAriaDescribedBy: string = '';
    readonly controlType = 'file';

    private onTouched?: () => void;
    private onChanges?: (file?: File) => void;
    private touched = false;

    private _placeholder = '';
    private _required = false;
    private _disabled = false;

    constructor() {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const input = this.elementRef.nativeElement?.querySelector('input') as HTMLInputElement | null;

        if (!input || !changes['fileFormats']) {
            return;
        }

        const fileFormats = changes['fileFormats'].currentValue;
        if (fileFormats) {
            input.setAttribute('accept', fileFormats);
        } else {
            input.removeAttribute('accept');
        }

        this.stateChange();
    }

    get empty(): boolean {
        return !this.file;
    }

    get errorState(): boolean {
        return this.touched && this.required && !this.fileInput?.nativeElement.value;
    }

    set value(file: File | undefined) {
        this.file = file;
        this.stateChange();
    }

    get value(): File | undefined {
        return this.file;
    }

    private stateChange(): void {
        this.stateChanges.next(undefined);
    }

    onFileSelect(event: Event) {
        const target = event.target as HTMLInputElement;
        this.file = target.files?.[0];
        this.fileChange.emit(this.file instanceof File ? this.file : undefined);
        this.onChanges?.(this.file);
        this.stateChange();
    }

    ngOnDestroy(): void {
        this.stateChanges?.complete();
    }

    registerOnChange(fn: (file?: File) => void): void {
        this.onChanges = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(obj: File): void {
        this.file = obj;
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this.fileInput?.nativeElement.click();
        }

        if (!this.touched) {
            this.touched = true;
            this.onTouched?.();
        }

        this.stateChange();
    }

    setDescribedByIds(ids: string[]): void {
        this.elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
    }
}
