import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PeriodLock, PeriodLockResponse } from '../models/period-lock';
import { map, Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { classifyArrayPaginatedResponse } from '../../shared/utils/rxjs/classify';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';

@Injectable({
    providedIn: 'root',
})
export class PeriodLockService {
    constructor(@Inject(HttpClient) protected http: HttpClient) {
    }

    getForCustomer(customerId: number, pagination?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<PeriodLockResponse>>(`customers/${customerId}/period_locks`, {
            params: formatHttpParams(pagination),
        }).pipe(classifyArrayPaginatedResponse(PeriodLock));
    }

    getForCustomerGroup(customerGroupId: number): Observable<PeriodLock[]> {
        return this.http.get<PeriodLockResponse[]>(`customer_groups/${customerGroupId}/period_locks`).pipe(
            map((resp) => {
                return resp.map((data) => new PeriodLock(data));
            }));
    }
}
