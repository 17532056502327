import { Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { DateTime } from 'luxon';
import { Absence } from '../../models/absence';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { AbsenceService } from '../../http/absence.service';
import { catchError, EMPTY, firstValueFrom, tap } from 'rxjs';
import { CustomFieldsGroup } from '../../../shared/utils/custom-fields-group';
import { AbsenceType } from '../../models/absence-type';
import { AbsenceTypeAutocompleteService } from '../../../shared/autocompletes/absence-type-autocomplete.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { CustomerCustomFieldService } from '../../../shared/http/customer-custom-field.service';
import { ModelCustomField } from '../../../custom-fields/models/model-custom-field';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { DurationInputComponent } from '../../../shared/components/duration-input/duration-input.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DateTimeRangeInputComponent } from '../../../shared/components/date-time/date-time-range-input/date-time-range-input.component';
import { DateTimeInputComponent } from '../../../shared/components/date-time/date-time-input/date-time-input.component';
import { CustomFieldInputComponent } from '../../../custom-fields/components/custom-field-input/custom-field-input.component';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { AbsenceActionsComponent } from '../../components/absence-actions/absence-actions.component';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { Products } from '../../../shared/enums/products';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbsenceReleaseShifts } from '../../types/types';

interface BaseAbsenceDialogData extends DialogData {
    // Shows an additional duration input field that is just cosmetic
    showDuration?: boolean;
    approve?: boolean;
}

interface CreateAbsence extends BaseAbsenceDialogData {
    customerId: number;
    employeeId: number;
    absence?: never,
    from: DateTime;
    to: DateTime;
}

interface UpdateAbsence extends BaseAbsenceDialogData {
    customerId: number;
    employeeId?: never;
    absence: Absence,
    from?: never;
    to?: never;
}

export type CrateUpdateAbsenceDialogData = CreateAbsence | UpdateAbsence;

type CreateUpdateAbsenceForm = {
    releaseShifts?: FormControl<AbsenceReleaseShifts>;
    dateRange: FormGroup<{
        from: FormControl<DateTime | null>,
        to: FormControl<DateTime | null>,
    }>,
    customFields: CustomFieldsGroup,
    comment: FormControl<string | null>,
    absenceType: FormControl<AbsenceType | number | null>,
}

@Component({
    selector: 'eaw-create-update-absence-dialog',
    templateUrl: './create-update-absence-dialog.component.html',
    styleUrl: './create-update-absence-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        ReactiveFormsModule,
        AutocompleteComponent,
        MatFormFieldModule,
        MatDatepickerModule,
        DurationInputComponent,
        NgFor,
        MatInputModule,
        TextFieldModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
        DateTimeRangeInputComponent,
        DateTimeInputComponent,
        CustomFieldInputComponent,
        DatePickerOptionsDirective,
        AbsenceActionsComponent,
    ],
})
export class CreateUpdateAbsenceDialogComponent extends DialogComponent<CrateUpdateAbsenceDialogData, Absence, CreateUpdateAbsenceDialogComponent> implements OnInit {
    private readonly absenceService = inject(AbsenceService);
    private readonly customerCustomFieldService = inject(CustomerCustomFieldService);
    private readonly customerProductService = inject(CustomerProductService);
    private readonly destroyRef = inject(DestroyRef);
    protected readonly absenceTypeAutocompleteService = inject(AbsenceTypeAutocompleteService);

    customFieldPivots: WritableSignal<ModelCustomField[]> = signal([]);
    hasScheduling = signal<boolean>(false);

    // Object used to show the duration of an absence
    durationRange: { from?: DateTime | null, to?: DateTime | null };
    form: FormGroup<CreateUpdateAbsenceForm>;

    constructor() {
        super(undefined, undefined, DialogSize.Medium);

        this.form = new FormGroup({
            dateRange: new FormGroup({
                from: new FormControl<DateTime | null>(null, Validators.required),
                to: new FormControl<DateTime | null>(null, Validators.required),
            }),
            customFields: new CustomFieldsGroup(),
            comment: new FormControl<string | null>(''),
            absenceType: new FormControl<AbsenceType | number | null>(null, Validators.required),
        });

        if (this.data.approve) {
            this.form.addControl('releaseShifts', new FormControl<AbsenceReleaseShifts>('release', { nonNullable: true }));
        }

        this.durationRange = {
            from: null,
            to: null,
        };
    }

    async ngOnInit() {
        this.setInitialDateRange();
        this.customerProductService.hasProducts(this.data.customerId, [ Products.Scheduling ]).pipe(
            tap((hasScheduling) => this.hasScheduling.set(hasScheduling)),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();

        await this.setCustomFields();

        if (this.data.absence) {
            this.form.patchValue({ absenceType: this.data.absence.type || this.data.absence.typeId });
        }

        this.form.valueChanges.subscribe(this.setDurationRange.bind(this));
    }

    async setCustomFields() {
        try {
            this.customFieldPivots.set(await firstValueFrom(this.customerCustomFieldService.getForModel(this.data.customerId, ApiModel.Absence, this.data.absence)));
        } catch (e) {
            console.error(e);
            this.customFieldPivots.set([]);
        }
    }

    getAbsenceType() {
        return this.form.value.absenceType instanceof AbsenceType ? this.form.value.absenceType : null;
    }

    setDurationRange() {
        const value = this.form.value;
        const from = value.dateRange?.from;
        const to = value.dateRange?.to;
        const absenceSpan = this.getAbsenceType()?.span;

        if (absenceSpan == null || from == null || to == null) {
            this.durationRange = {};
            return;
        }

        if (absenceSpan === 'day') {
            this.durationRange = {
                from: from.startOf('day'),
                to: to.plus({ day: 1 }).startOf('day'),
            };
        } else {
            this.durationRange = {
                from,
                to,
            };
        }
    }

    setInitialDateRange() {
        this.form.controls.dateRange.setValue({
            from: this.data.absence ? this.data.absence.from : this.data.from,
            to: this.data.absence ? this.data.absence.to : this.data.to,
        }, {
            emitEvent: false,
            onlySelf: true,
        });
    }

    getFromTo() {
        const form = this.form.value;
        const span = this.getAbsenceType()?.span;
        const from = form.dateRange?.from;
        const to = form.dateRange?.to;

        if (from && to) {
            return {
                from: span === 'day' ? from.startOf('day') : from,
                to: span === 'day' ? to.endOf('day') : to,
            };
        }
    }

    submit() {
        const { from, to } = this.getFromTo() || {};
        if (!from || !to) {
            return;
        }

        this.form.disable();
        const observable = this.data.absence ? this.update(this.data.absence, from, to) : this.create(this.data.customerId, this.data.employeeId, from, to);

        observable.pipe(
            catchError(() => {
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe((res) => {
            this.dialogRef.close(res);
        });
    }

    create(customerId: number, employeeId: number, from: DateTime, to: DateTime) {
        return this.absenceService.create(customerId, employeeId, {
            from,
            to,
            release_shifts: this.form.controls.releaseShifts?.value,
            comment: this.form.controls.comment.value,
            ...this.form.controls.customFields.getValues(),
            type_id: this.getAbsenceType()?.id || 0,
            approved: this.data.approve,
        });
    }

    update(absence: Absence, from: DateTime, to: DateTime) {
        return this.absenceService.update(absence.customerId, absence.employeeId, absence.id, {
            from,
            to,
            release_shifts: this.form.controls.releaseShifts?.value,
            comment: this.form.controls.comment.value,
            ...this.form.controls.customFields.getValues(),
            type_id: this.getAbsenceType()?.id || 0,
        });
    }
}
