import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../interfaces/pagination-options';
import { Customer, CustomerResponse } from '../models/customer';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { HttpClient, HttpContext } from '@angular/common/http';

interface GetAllOptions extends PaginationOptions {
    with_trashed?: boolean,
}

export interface CustomerCreateOptions {
    name: string;
    billing_contact: string;
    billing_customer_id?: number | null;
    number: string;
    address1: string;
    address2?: string | null;
    postal_code: string;
    city: string;
    currency?: string;
    country_code: string;
    region_id?: number | null;
    language_code?: string | null;
    locale_code: string;
    time_zone: string;
    stack_id: number;
    setting_group_id: number;
    type: string;
    active: boolean;
    organization_number?: string | null;
    latitude?: number | null;
    longitude?: number | null;
}

export type CustomerUpdateOptions = Partial<CustomerCreateOptions>;

@Injectable({
    providedIn: 'any',
})
export class CustomerService {
    http = inject(HttpClient);

    get(customerId: number, withs: string[] = [], context?: HttpContext): Observable<Customer> {
        return this.http.get<CustomerResponse>(`/customers/${customerId}`, {
            params: {
                'with[]': withs,
            },
            context,
        }).pipe(classifyItem(Customer));
    }

    getAll(options?: GetAllOptions): Observable<ArrayPaginatedResponse<Customer>> {
        return this.http.get<ArrayPaginatedResponse<CustomerResponse>>('/customers', {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(Customer));
    }

    create(options: CustomerCreateOptions) {
        return this.http.post<CustomerResponse>(`/customers`, { ...options }).pipe(classifyItem(Customer));
    }

    update(customerId: number, options: CustomerUpdateOptions): Observable<Customer> {
        return this.http.put<CustomerResponse>(`/customers/${customerId}`, options).pipe(
            classifyItem(Customer),
        );
    }

    delete(customerId: number) {
        return this.http.delete(`/customers/${customerId}`);
    }
}
