<eaw-page-header>
    <span title>{{'SETTING_plural' | translate | async}}</span>
</eaw-page-header>

@if (loading) {
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-20">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
} @else {
    <mat-accordion>
        @for (group of groups; track group.name) {
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{group.translatedName}}</mat-panel-title>
                    <mat-panel-description>{{('COUNT_SETTINGS' | translate:'settings':{count: group.items.length}) | async}}</mat-panel-description>
                </mat-expansion-panel-header>

                @for (item of group.items; track item.setting.name.key) {
                    <div class="setting-item">
                        <div class="info tw-flex tw-flex-col">
                            <div class="mat-subtitle-2 setting-title">{{item.setting.name.key | translate:item.setting.name.ns | async}}</div>
                            <div class="mat-body-2 setting-subtitle">{{item.setting.description.key | translate:item.setting.description.ns | async}}</div>
                        </div>

                        @if (item.loading) {
                            <mat-progress-spinner mode="indeterminate" diameter="28" strokeWidth="3"></mat-progress-spinner>
                        } @else {
                            <span class="value">
                                <eaw-property-value-display [propertyValue]="item.setting.value ?? item.setting.default" [dataType]="item.setting.dataType" [inputType]="item.setting.inputType" [options]="item.setting.options"/>
                            </span>

                            <button [disabled]="(item.disabled | async) || false" [matTooltip]="('EDIT' | translate | async)!" mat-icon-button (click)="edit(item)">
                                <mat-icon>edit</mat-icon>
                            </button>

                            @if (item.setting.setValue) {
                                <button [disabled]="(item.disabled | async) || false" [matTooltip]="('RESET' | translate | async)!" mat-icon-button (click)="reset(item)">
                                    <mat-icon>replay</mat-icon>
                                </button>
                            }
                        }
                    </div>
                }
            </mat-expansion-panel>
        }
    </mat-accordion>
}


