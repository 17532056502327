// @ts-nocheck
import { module } from 'angular';
import { overlaps } from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
class PaidTimeCommentExtraTime {
    $scope;
    TimepunchFactory;
    static get $inject() {
        return [ '$scope', 'TimepunchFactory' ];
    }

    comment = '';
    // bindings
    segment;
    parent;
    timepunches;
    constructor($scope, TimepunchFactory) {
        this.$scope = $scope;
        this.TimepunchFactory = TimepunchFactory;
    }

    $onInit() {
        this.$scope.$on('HandleSomethingCtrl:handle', this.addComment.bind(this));
        this.timepunches = this.segment.employeePaidTime.timepunches;
    }

    async addComment() {
        let from = this.parent.interval.from;
        let to = this.parent.interval.to;
        const comments = await Promise.all(this.timepunches.map((timepunch) => {
            const out = timepunch.out ?? timepunch.in.clone().add(1, 'd');
            if (!overlaps(timepunch.in, out, from, to)) {
                return;
            }
            if (from.isBefore(timepunch.in, 'm')) {
                from = timepunch.in.clone();
            }
            if (to.isAfter(out, 'm')) {
                to = timepunch.out.clone();
            }
            const comment = `(${from.format(`L LT`)} - ${to.format(`LT`)}) ${this.comment}`;
            this.TimepunchFactory.update(timepunch.customer_id, timepunch.id, { comment });
        }));
        return this.parent.close(comments);
    }
}
module('eaw.payroll.paid-time').component('ptCommentExtraTime', {
    template: `<md-input-container>
    <label ng-i18next="COMMENT"></label>
    <textarea ng-model="extraTime.comment"></textarea>
</md-input-container>
`,
    controllerAs: 'extraTime',
    bindings: {
        segment: '<',
        parent: '<',
    },
    controller: PaidTimeCommentExtraTime,
});
