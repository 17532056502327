import { Component, Inject, Input, OnInit } from '@angular/core';
import { SettingGroupCustomFieldsService } from '../../../custom-fields/http/setting-group-custom-fields.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { of, take } from 'rxjs';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { MatDialog } from '@angular/material/dialog';
import { AddEditCustomFieldPivotDialogComponent, AddEditCustomFieldPivotDialogData } from '../../dialogs/add-edit-custom-field-pivot-dialog/add-edit-custom-field-pivot-dialog.component';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { CustomFieldPivot } from '../../../custom-fields/models/custom-field-pivot';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf, NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { ApiModel } from '../../../shared/enums/api-model';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';

@Component({
    selector: 'eaw-setting-group-custom-fields',
    templateUrl: './setting-group-custom-fields.component.html',
    styleUrl: './setting-group-custom-fields.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatExpansionModule,
        NgFor,
        MatSlideToggleModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        KeyValuePipe,
    ],
})
export class SettingGroupCustomFieldsComponent implements OnInit {
    @Input() settingGroup!: { id: number };

    loading = true;
    models: Record<ApiModel, CustomFieldPivot[]>;
    headerFabButton: HeaderFabButton = {
        click: this.addCustomField.bind(this),
        hasPermission: () => of(true),
    };

    constructor(
        @Inject(SettingGroupCustomFieldsService) private settingGroupCustomFieldsService: SettingGroupCustomFieldsService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
    ) {
        this.models = CustomFieldPivot.getCustomFieldPivotModels().reduce((acc, model) => {
            acc[model] = [];
            return acc;
        }, {} as Record<ApiModel, CustomFieldPivot[]>);
    }

    ngOnInit() {
        this.getCustomFields();
    }

    getCustomFields() {
        this.loading = true;

        expandAllPages((pagination) => this.settingGroupCustomFieldsService.getAll(this.settingGroup.id, {
            ...pagination,
            direction: 'asc',
            'with[]': [ 'customField' ],
        }), {
            per_page: 40,
        }).subscribe((customFields) => {
            this.groupByModel(customFields);
            this.loading = false;
        });
    }

    groupByModel(customFieldPivots: CustomFieldPivot[]) {
        Object.keys(this.models).forEach((model) => {
            this.models[model as ApiModel] = sort(customFieldPivots.filter((x) => x.model === model), 'en', [ (x) => x.customField?.key ]);
        });
    }

    addCustomField() {
        this.matDialog.open<AddEditCustomFieldPivotDialogComponent, AddEditCustomFieldPivotDialogData, CustomFieldPivot>(AddEditCustomFieldPivotDialogComponent, {
            data: {
                settingGroupId: this.settingGroup.id,
            },
        }).afterClosed().subscribe((customField) => {
            if (customField) {
                this.getCustomFields();
            }
        });
    }

    updateToggle(key: 'required' | 'has_interval', customFieldPivot: CustomFieldPivot, value: boolean) {
        this.settingGroupCustomFieldsService.update(this.settingGroup.id, customFieldPivot.customFieldId, customFieldPivot.id, {
            [key]: value,
        }).pipe(take(1)).subscribe();
    }

    edit(customFieldPivot: CustomFieldPivot) {
        this.matDialog.open<AddEditCustomFieldPivotDialogComponent, AddEditCustomFieldPivotDialogData, CustomFieldPivot>(AddEditCustomFieldPivotDialogComponent, {
            data: {
                settingGroupId: this.settingGroup.id,
                customFieldPivot: of(customFieldPivot),
            },
        }).afterClosed().subscribe((customField) => {
            if (customField) {
                this.getCustomFields();
            }
        });
    }

    delete(customFieldPivot: CustomFieldPivot) {
        this.confirmDialogService.delete().afterClosed().subscribe((result) => {
            if (result?.ok) {
                this.settingGroupCustomFieldsService.delete(this.settingGroup.id, customFieldPivot.customFieldId, customFieldPivot.id).pipe(take(1)).subscribe(() => {
                    this.models[customFieldPivot.model] = this.models[customFieldPivot.model].filter((x) => x.id !== customFieldPivot.id);
                });
            }
        });
    }
}
