// @ts-nocheck
import { module } from 'angular';
import { uniqueId } from '../misc/services/easy-funcs.service';
import { ElementInitialization } from '../misc/services/element-initialization.service';

module('eaw.misc').directive('uniqFormName', function() {
    return {
        restrict: 'A',
        link($scope, $element) {
            const el = $element[0];

            if (el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement) {
                setName(el);
            } else {
                const inputInit = new ElementInitialization('input', el);
                const areaInit = new ElementInitialization('textarea', el);

                inputInit.observe().then(setName);
                areaInit.observe().then(setName);
            }

            function setName(el) {
                el.setAttribute('name', `eaw-${+new Date()}-${uniqueId()}`);
                el.setAttribute('autocomplete', `off-${+new Date()}-${uniqueId()}`);
            }
        },
    };
});
