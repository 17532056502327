import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CurrentService } from '../services/current.service';
import { NumberFormatterService } from '../services/number-formatter.service';

@Pipe({
    name: 'eawNumber',
    standalone: true,
})
export class NumberPipe implements PipeTransform {
    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
    ) {}

    transform(value?: number | string | null, decimals = 2, options?: Intl.NumberFormatOptions): string {
        try {
            return this.numberFormatterService.format(this.current.languageTag, value, {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
                ...options,
                currency: options?.currency || this.current.getCustomer().currency || 'USD',
            });
        } catch (e) {
            console.error('Number pipe formatting error', e);
            return '';
        }
    }
}
