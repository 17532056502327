<mat-form-field>
    <mat-label>{{'EMPLOYEE' | translate: 'general' | async}}</mat-label>
    <input type="text"
           aria-label="Number"
           matInput
           [formControl]="selected"
           [matAutocomplete]="emps">
    <mat-autocomplete #emps="matAutocomplete">
        <mat-option *ngFor="let employee of filteredEmployees; trackBy: trackEmployeeBy" [value]="employee.id">{{employee.name}}</mat-option>
    </mat-autocomplete>
</mat-form-field>
