// @ts-nocheck
import { module } from 'angular';
module('eaw.misc').component('quickGrid', {
    template: `<md-card id="card">
    <md-card-header ng-if="grid.headerIsFilled">
        <div ng-transclude="header"></div>
    </md-card-header>
    <md-card-content id="grid" class="tw-p-0 pretty-scroll">
        <table class="table table-bordered sticky-header sticky-left">
            <thead>
            <tr>
                <th></th>
                <th class="overflow-ellipsis hover-item hover-highlight"
                    ng-repeat="item in grid._items"
                    ng-class="{'highlight-primary': item.hover}"
                    ng-hide="item.hide"
                    ng-click="grid.sortPeople(item)">
                    <span ng-bind="item.name"></span>
                    <md-tooltip ng-bind="item.name"></md-tooltip>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="person in grid._people">
                <th class="overflow-ellipsis hover-item hover-highlight" ng-class="{'highlight-primary': person.hover}" ng-click="grid.sortItems(person)">
                    <span ng-bind="person.name"></span>
                </th>
                <td class="quick-cell"
                    ng-mouseenter="item.hover=true;person.hover=true;"
                    ng-mouseleave="item.hover=false;person.hover=false;"
                    ng-repeat="item in grid._items"
                    ng-click="grid.toggle(item, person)"
                    ng-class="{'display-none': item.hide, 'highlight-red': person.items[item.id] && !person.items[item.id].last_day, 'highlight-green': !person.items[item.id]}">

                    <md-tooltip ng-if="person.items[item.id].last_day" ng-bind="grid.lastDayText"></md-tooltip>

                    <md-icon class="show-on-hover"
                             md-colors="{color: 'white'}"
                             ng-bind="'remove'"
                             ng-if="person.items[item.id] && !person.items[item.id].last_day">
                    </md-icon>

                    <md-icon class="show-on-hover"
                             md-colors="{color: 'white'}"
                             ng-bind="'add'"
                             ng-if="!person.items[item.id]">
                    </md-icon>

                    <md-icon ng-class="{'last-day': person.items[item.id].last_day, 'hide-highlight': !person.items[item.id].last_day}"
                             ng-if="person.items[item.id]"
                             ng-bind="'done'"
                             md-colors="{color: 'green'}">
                    </md-icon>
                </td>
            </tr>
            </tbody>
        </table>
    </md-card-content>
</md-card>
`,
    controllerAs: 'grid',
    bindings: {
        people: '<',
        items: '<',
        lastDayText: '<?',
        toggle: '<', // function toggle(item, employee)
    },
    transclude: {
        header: '?eawQuickHeader',
    },
    controller: [ '$transclude', function($transclude) {
        this.$onInit = () => {
            this.headerIsFilled = $transclude.isSlotFilled('header');
        };
        this.$onChanges = (change) => {
            const nameSort = (a, b) => b.name > a.name ? -1 : 1;
            if (change.items) {
                this._items = change.items.currentValue ? Object.values(change.items.currentValue).sort(nameSort) : undefined;
            }
            if (change.people) {
                this._people = change.people.currentValue ? Object.values(change.people.currentValue).sort(nameSort) : undefined;
            }
        };
        this.sortPeople = (item) => {
            this._people = Object.values(this.people).sort((a, b) => (b.items[item.id] ? 1 : 0) - (a.items[item.id] ? 1 : 0));
        };
        this.sortItems = (person) => {
            this._items = Object.values(this.items).sort((a, b) => (person.items[b.id] ? 1 : 0) - (person.items[a.id] ? 1 : 0));
        };
    } ],
});
