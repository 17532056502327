import moment from 'moment-timezone';
import { t } from 'i18next';
import { module } from 'angular';
import { DateTimeConverter } from '../../../shared/utils/date-time-converter';
import { Products } from '../../../shared/enums/products';
import { TinyColor } from '@ctrl/tinycolor';
import { Customer } from '../../../shared/models/customer';
import { lastValueFrom } from 'rxjs';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
import { MeCustomer } from '../../../shared/models/me';
import { cloneDeep } from 'lodash-es';
import { WebsocketService } from '../../../shared/services/websocket.service';
import { AngularJsDestroyRef } from '../../../shared/angularjs/angularjs-destroy-ref.class';

module('eaw.payroll.punch-clock').component('punchClock', {
    template: `<md-card ng-if="!$punch.widget && $punch.activeTimepunch">
    <md-card-content>
        <table class="table tw-mb-16">
            <thead>
            <tr>
                <th ng-i18next="DATE"></th>
                <th class="text-right">#</th>
                <th ng-i18next="NAME"></th>
                <th ng-i18next="IN"></th>
                <th ng-i18next="OUT"></th>
                <th ng-i18next="LENGTH"></th>
            </tr>
            </thead>
            <tbody>
            <tr ng-if="$punch.employee">
                <td ng-bind="$punch.activeTimepunch.business_date | moment:'L'"></td>
                <td class="text-right" ng-bind="$punch.employee.number"></td>
                <td ng-bind="$punch.employee.name"></td>
                <td ng-bind="$punch.activeTimepunch.in | moment: 'L LT'"></td>
                <td ng-bind="$punch.activeTimepunch.out | moment: 'L LT'"></td>
                <td>
                    <eaw-time time="$punch.activeTimepunch.in" type="duration" interval="1"></eaw-time>
                </td>
            </tr>
            </tbody>
        </table>
    </md-card-content>
</md-card>

<md-card ng-if="$punch.loading" style="height: 100%; justify-content: center">
    <md-progress-circular  class="md-progress-center"></md-progress-circular>
</md-card>

<md-card ng-if="!$punch.loading">
    <md-card-content class="punch-clock-form-container">
        <form id="punch-form" layout="column" layout-align="space-around stretch" autocomplete="off">
            <md-input-container>
                <label ng-i18next="LOCATION"></label>
                <md-select ng-model="$punch.customer" ng-change="$punch.changedCustomer()" ng-disabled="$punch.customers.length === 1 || $punch.activeTimepunch || $punch.gettingActiveTimepunch" ng-required="true">
                    <md-option ng-repeat="customer in $punch.customers" ng-value="customer">
                        {{customer.name}}
                    </md-option>
                </md-select>
            </md-input-container>

            <md-input-container ng-show="$punch.businessUnits.length">
                <label ng-i18next="BUSINESS_UNIT"></label>
                <md-select ng-model="$punch.businessUnitId" ng-disabled="$punch.activeTimepunch || $punch.gettingActiveTimepunch">
                    <md-option ng-value="null"></md-option>
                    <md-option ng-repeat="unit in $punch.businessUnits" ng-value="unit.id">{{unit.name}}</md-option>
                </md-select>
            </md-input-container>

            <md-input-container class="md-block">
                <textarea rows="1" id="comment" md-no-autogrow ng-disabled="$punch.gettingActiveTimepunch" ng-i18next="[aria-label]COMMENT_PLACEHOLDER;[placeholder]COMMENT_PLACEHOLDER" ng-model="$punch.comment"></textarea>
            </md-input-container>

        <div class="tw-flex">
            <md-button ng-style="{background: $punch.buttonColor, color: $punch.buttonTextColor}"
                       class="md-raised tw-w-full" 
                       ng-click="$punch.punchIn()"
                       ng-disabled="$punch.punching || !$punch.customer || $punch.gettingActiveTimepunch"
                       ng-if="!$punch.activeTimepunch || $punch.activeTimepunch.out"
                <span ng-i18next="payroll:PUNCH_IN"></span>
            </md-button>
            
            <md-button class="md-warn md-raised tw-w-full"
                       ng-click="$punch.punchOut()"
                       ng-disabled="$punch.punching || !$punch.customer || $punch.gettingActiveTimepunch"
                       ng-if="$punch.activeTimepunch && !$punch.activeTimepunch.out">
                <span ng-i18next="payroll:PUNCH_OUT"></span>
            </md-button>
        </div>

        </form>
    </md-card-content>
</md-card>
`,
    bindings: {
        employee: '<',
        activeTimepunch: '<?',
    },
    require: {
        'eawWidget': '^?eawWidget',
    },
    controllerAs: '$punch',
    controller: [ 'PunchClock', 'ToastService', 'CustomerService', 'WebsocketDowngrade', 'BusinessUnitDowngraded', 'colorpickerService', function(PunchClock, ToastService, CustomerService, WebsocketDowngrade: WebsocketService, BusinessUnitDowngraded, colorpickerService) {
        // @ts-ignore
        const ctrl = this;
        const destroyRef = new AngularJsDestroyRef();

        ctrl.$postLink = async () => {
            ctrl.widget = ctrl.eawWidget?.widget;
            ctrl.loading = true;
            ctrl.businessUnits = [];
            ctrl.customers = Object.values(CurrentOld.getCustomers()).filter((c) => c.employee_id).map((c) => cloneDeep(c));
            ctrl.buttonColor = colorpickerService.getHex(ctrl.widget?.headerColor) || '#5a626a';
            ctrl.buttonTextColor = new TinyColor(colorpickerService.getHex(ctrl.buttonColor)).isDark() ? 'white' : 'black';

            ctrl.setCurrentCustomer(CurrentOld.getCustomer()['id']);
        };

        ctrl.$onDestroy = () => {
            destroyRef.destroy();
        };

        ctrl.setCurrentCustomer = (customerId: number) => {
            const currentCustomer = ctrl.customers.length === 1 ? ctrl.customers[0] as MeCustomer : ctrl.customers.find((c: MeCustomer) => c.id === customerId) as MeCustomer | undefined;
            if (currentCustomer) {
                ctrl.customer = currentCustomer;
                ctrl.changedCustomer();
            }

            if (ctrl.eawWidget && !ctrl.customers.filter((c: MeCustomer) => c.employee_id).length) {
                ctrl.eawWidget.setEmpty(true);
            }

            ctrl.loading = false;
        };

        ctrl.getBusinessUnits = async (customerId: number) => {
            ctrl.businessUnits = [];

            if (!CurrentOld.can(`customers.${ctrl.customer.id}.business_units.*.get`)) {
                return;
            }

            const customerResponse = await CustomerService.get({
                id: customerId,
                with: [ 'products', 'customerGroups' ],
            }).$promise;

            const customer = new Customer(customerResponse);
            const hasUnits = customer.products.length ? customer.products.some((p) => p.name === Products.BusinessUnits) : false;

            if (!hasUnits) {
                return;
            }

            ctrl.businessUnits = await lastValueFrom(BusinessUnitDowngraded.getAllPages(customerId, undefined, false));
        };

        ctrl.changedCustomer = async () => {
            ctrl.loading = true;
            ctrl.businessUnitId = null;

            await ctrl.getBusinessUnits(ctrl.customer.id);
            ctrl.listenChannel(ctrl.customer.id, ctrl.customer.employee_id);

            try {
                ctrl.gettingActiveTimepunch = true;
                ctrl.activeTimepunch = await PunchClock.activeTimepunch(ctrl.customer.id, ctrl.customer.employee_id).$promise;

                if (ctrl.activeTimepunch['customer_id'] && ctrl.activeTimepunch['customer_id'] !== ctrl.customer.id) {
                    ctrl.setCurrentCustomer(ctrl.activeTimepunch['customer_id']);
                    return;
                }

                ctrl.businessUnitId = ctrl.activeTimepunch.business_unit_id;
            } catch (e) {
                console.error(e);
            }

            ctrl.gettingActiveTimepunch = false;
            ctrl.loading = false;
        };

        ctrl.listenChannel = async (customerId: number, employeeId: number) => {
            ctrl.channel = `customers.${customerId}.employees.${employeeId}.timepunches`;
            WebsocketDowngrade.listenEmployeeTimepunches(customerId, employeeId, 'punched', ctrl.onTimepunch, destroyRef);
        };

        ctrl.onTimepunch = (tp: any) => {
            tp.business_date = typeof tp.business_date === 'string' ? DateTimeConverter.convertStringToMoment(tp.business_date, true) : tp.business_date;
            tp.in = typeof tp.in === 'string' ? DateTimeConverter.convertStringToMoment(tp.in) : tp.in;
            tp.out = typeof tp.out === 'string' ? DateTimeConverter.convertStringToMoment(tp.out) : tp.out;
            if (tp.out) {
                ctrl.activeTimepunch = null;
            } else {
                ctrl.activeTimepunch = tp;
                ctrl.businessUnitId = tp.business_unit_id;
            }
        };

        ctrl.punchIn = async () => {
            if (!navigator.onLine) {
                ToastService.toast(t('PROBABLY_OFFLINE'));
                return;
            }

            ctrl.punching = true;
            try {
                ctrl.businessDate = await PunchClock.suggestedTimePunch(ctrl.customer.id, ctrl.customer.employee_id);
            } catch (_) {
                ctrl.businessDate = moment();
            }
            try {
                const tp = await PunchClock.punchIn(ctrl.customer.id, ctrl.customer.employee_id, ctrl.businessDate, ctrl.businessUnitId, ctrl.comment);
                ToastService.toast(t('payroll:PUNCH_IN_SUCCESS'));
                ctrl.comment = undefined;
                ctrl.onTimepunch(tp);
            } catch (e) {
                console.error(e);
            }
            delete ctrl.punching;
        };

        ctrl.punchOut = async () => {
            ctrl.punching = true;
            try {
                const tp = await PunchClock.punchOut(ctrl.customer.id, ctrl.customer.employee_id, ctrl.comment);
                ToastService.toast(t('payroll:PUNCH_OUT_SUCCESS'));
                ctrl.comment = undefined;
                ctrl.onTimepunch(tp);
            } catch (e) {
                console.error(e);
            }
            delete ctrl.punching;
        };
    } ],
});
