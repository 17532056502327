import { ApiResponse } from '../interfaces/api-response';

export interface CountryResponse extends ApiResponse {
    code: string;
    name: string;
}

export class Country {
    code: string;
    name: string;
    translatedName: string;

    constructor(data: CountryResponse, languageCode: string) {
        this.code = data.code;
        this.name = data.name;
        this.translatedName = new Intl.DisplayNames(languageCode, { type: 'region' }).of(this.code.toUpperCase()) || this.name;
    }
}
