import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'employee-transfers-tab-upgraded',
    standalone: true
})
export class employeeTransfersTabUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;
    @Input() employee!: any;
    @Input() user!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('employeeTransfersTab', elementRef, injector);
    }
}

@Component({
    template: '<employee-transfers-tab-upgraded [customer]="customer" [employee]="employee" [user]="user"></employee-transfers-tab-upgraded>',
    standalone: true,
    imports: [employeeTransfersTabUpgradedDirective],
})
export class employeeTransfersTabUpgradedComponent {
    @Input() customer!: any;
    @Input() employee!: any;
    @Input() user!: any;
}

@NgModule({
    imports: [employeeTransfersTabUpgradedDirective,
        employeeTransfersTabUpgradedComponent],
})
export class employeeTransfersTabUpgradeModule {
}
