import { inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { StateProviderDataData } from '../utils/create-state';
import { Transition } from '@uirouter/angularjs';

@Injectable({
    providedIn: 'root',
})
export class QueryParamsHookService {
    uiRouter = inject(UIRouter);

    constructor() {
        this.uiRouter.transitionService.onBefore({
            from(state) {
                return state?.data?.queryParams;
            },
        }, this.onTransition.bind(this));
    }

    private onTransition(transition: Transition) {
        console.debug('🪝', 'QueryParamsHookService.onBefore', transition);

        if (transition.options().custom?.skipHook) {
            return;
        }

        const from = transition.$from();
        const to = transition.$to();
        const queryParams = from?.data?.queryParams as StateProviderDataData['queryParams'];

        if (from.name !== to.name && queryParams?.length) {
            const params = Object.entries(transition.params()).reduce((acc, [ key, value ]) => {
                if (queryParams.includes(key)) {
                    acc[key] = '';
                } else {
                    acc[key] = value;
                }

                return acc;
            }, {} as Record<string, string>);

            return this.uiRouter.stateService.target(transition.to(), params, {
                location: true,
                custom: {
                    skipHook: true,
                },
            });
        }
    }
}
