import { module } from 'angular';
import { environment } from '../../../environments/environment';
import { reportError } from './modules/misc/services/easy-funcs.service';

module('eaw', [
    'ngAnimate',
    'ngResource',
    'ngMaterial',
    'ngTable',
    'ui.router',
    'ui.router.upgrade',
    'ngSanitize',
    'ngFileUpload',
    // Our modules
    'ew-endless-list',
    // Our sub modules.
    'eaw.theme',
    'eaw.filesystem',
    'eaw.decorators',
    'eaw.table',
    'eaw.resource',
    'eaw.misc',
    'eaw.employees',
    'eaw.time',
    'eaw.dialogs',
    'eaw.alerts',
    'eaw.login',
    'eaw.customers',
    'eaw.company',
    'eaw.messages',
    'eaw.payroll',
    'eaw.period-locks',
    'eaw.calendar',
    'eaw.calendar2',
    'eaw.availability',
    'eaw.scheduling',
    'eaw.vacation',
    'eaw.links',
    'eaw.projections',
    'eaw.fixedLabor',
    'eaw.checklists',
    'eaw.digisign',
    'eaw.dashboard',
    'eaw.hr',
    'eaw.kpi',
    'eaw.staffing',
    'eaw.todo',
    'eaw.forms',
    'eaw.chain-ops',
    'eaw.sso',
    'eaw.leave-shifts',
    'eaw.admin',
    'eaw.notifications',
    'eaw.icalendar',
    'eaw.ai-budgeting',
    'eaw.reports',
    'eaw.filesystem-access',
    'eaw.tbs',
    'eaw.newsletter',
    'eaw.coupons',
    'eaw.people-kpi',
    'eaw.balances',
])
    // Configure angularjs
    .config([ '$compileProvider' , function($compileProvider: any) {
        $compileProvider.commentDirectivesEnabled(false);
        $compileProvider.cssClassDirectivesEnabled(false);
        $compileProvider.debugInfoEnabled(!environment.isProduction);
    } ])
    .config([ '$urlServiceProvider', ($urlServiceProvider: any) => {
        $urlServiceProvider.deferIntercept();
    } ])
    // Configure angularJS material
    .config([ '$mdProgressCircularProvider', function($mdProgressCircularProvider: any) {
        $mdProgressCircularProvider.configure({
            progressSize: 35,
            strokeWidth: 10,
            duration: 800,
        });
    } ])
    .config([ '$provide', ($provide: any) => {
        $provide.decorator('$exceptionHandler', [ '$delegate', function($delegate: any) {
            return function(exception: any, cause: any) {
            // Ignore dialog cancel exception
                if (exception?.startsWith?.('Possibly unhandled rejection') && cause == null) {
                    return;
                }

                reportError(exception);

                $delegate(exception, cause);
            };
        } ]);
    } ]);
