// @ts-nocheck
import { module } from 'angular';
module('eaw.calendar2').controller('eawYearDayPanelCtrl2', [ 'mdPanelRef', 'EventInfoPanel', function eawYearDayPanelCtrl(mdPanelRef, EventInfoPanel) {
    this.$onInit = () => {
        this.wholeDayEvents = this.events?.filter((e) => e.wholeDay) || [];
        this.dayEvents = this.events?.filter((e) => !e.wholeDay) || [];
        // Put whole day at the top
        this.events = [ ...this.wholeDayEvents, ...this.dayEvents ];
    };
    this.createBtnClick = (button) => {
        button.onClick(this.date.clone().startOf('d'), this.date.clone().endOf('d'));
        mdPanelRef.close();
    };
    this.eventInfo = (event, e) => {
        EventInfoPanel.open(event, this.element, e, {
            onAction: () => {
                mdPanelRef.close();
            },
        });
    };
} ]);
