import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { catchError, EMPTY, forkJoin } from 'rxjs';
import { PaidTimeService } from '../../../payroll/http/paid-time.service';
import { CustomFieldsService } from '../../../custom-fields/http/custom-fields.service';
import { PaidTime } from '../../models/paid-time';
import { SettingService } from '../../../shared/http/setting.service';
import { CustomFieldsGroup } from '../../../shared/utils/custom-fields-group';
import { BusinessUnitAutocompleteService } from '../../../shared/autocompletes/business-unit-autocomplete.service';
import { AutocompleteOptions } from '../../../shared/autocompletes/autocomplete';
import { BusinessUnit } from '../../../business-units/models/business-unit';
import { Timepunch } from '../../../payroll/models/timepunch';
import { ApiModel } from '../../../shared/enums/api-model';
import { CustomerCustomFieldService } from '../../../shared/http/customer-custom-field.service';
import { ModelCustomField } from '../../../custom-fields/models/model-custom-field';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CustomFieldInputComponent } from '../../../custom-fields/components/custom-field-input/custom-field-input.component';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { DurationInputComponent } from '../../../shared/components/duration-input/duration-input.component';
import { DateTimeInputComponent } from '../../../shared/components/date-time/date-time-input/date-time-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateTimeRangeInputComponent } from '../../../shared/components/date-time/date-time-range-input/date-time-range-input.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

interface BasePaidTimeDialogData extends DialogData {
    // Shows an additional duration input field that is just cosmetic
    showDuration?: boolean;
}

interface CreatePaidTime extends BasePaidTimeDialogData {
    customerId: number;
    employeeId: number;
    paidTime?: never,
    timepunch?: Timepunch | null;
    from: DateTime;
    to: DateTime;
}

interface UpdatePaidTime extends BasePaidTimeDialogData {
    customerId: number;
    employeeId?: never;
    paidTime: PaidTime,
    timepunch?: never;
    from?: never;
    to?: never;
}

export type HandlePaidTimeDialogData = CreatePaidTime | UpdatePaidTime;

@Component({
    selector: 'eaw-add-paid-time-dialog',
    templateUrl: './handle-paid-time-dialog.component.html',
    styleUrl: './handle-paid-time-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        DateTimeRangeInputComponent,
        MatFormFieldModule,
        DateTimeInputComponent,
        DurationInputComponent,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        AutocompleteComponent,
        NgFor,
        CustomFieldInputComponent,
        TextFieldModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class HandlePaidTimeDialogComponent extends DialogComponent implements OnInit {
    initialLoading = true;
    customFields: ModelCustomField[] = [];
    form = new FormGroup({
        from: new FormControl<DateTime | null>(null, Validators.required),
        to: new FormControl<DateTime | null>(null, Validators.required),
        businessDate: new FormControl<DateTime | null>(null, Validators.required),
        businessUnit: new FormControl<BusinessUnit| number | null>(null),
        customFields: new CustomFieldsGroup(),
        comment: new FormControl<string | null>(''),
    });

    protected businessUnitAutocomplete: AutocompleteOptions<BusinessUnit>;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: HandlePaidTimeDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<HandlePaidTimeDialogComponent, PaidTime>,
        @Inject(PaidTimeService) public paidTimeService: PaidTimeService,
        @Inject(CustomFieldsService) public customFieldsService: CustomFieldsService,
        @Inject(SettingService) public settingService: SettingService,
        @Inject(BusinessUnitAutocompleteService) protected businessUnitAutocompleteService: BusinessUnitAutocompleteService,
        @Inject(CustomerCustomFieldService) protected customerCustomFieldService: CustomerCustomFieldService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        this.businessUnitAutocomplete = {
            options: businessUnitAutocompleteService.options,
            getter: businessUnitAutocompleteService.getter(data.customerId),
            setter: businessUnitAutocompleteService.setter(data.customerId),
        };
    }

    ngOnInit() {
        const customFields = this.customerCustomFieldService.getForModel(this.data.customerId, ApiModel.PaidTime, this.data.paidTime);
        const commentRequired = this.settingService.getValue([ 'customers', this.data.customerId ], 'paidtime.comment.required', false);

        forkJoin([ customFields, commentRequired ]).subscribe(([ customFields, commentRequiredValue ]) => {
            this.customFields = customFields;
            this.initialLoading = false;
            this.initForm(commentRequiredValue);
        });
    }

    initForm(commentRequired: boolean) {
        if (this.data.paidTime) {
            this.form.controls.from.setValue(this.data.paidTime.from);
            this.form.controls.to.setValue(this.data.paidTime.to);
            this.form.controls.businessDate.setValue(this.data.paidTime.businessDate.dateTime);
            this.form.controls.businessUnit.setValue(this.data.paidTime.businessUnitId);
        } else {
            this.form.controls.from.setValue(this.data.from);
            this.form.controls.to.setValue(this.data.to);
            this.form.controls.businessDate.setValue(this.data.from.startOf('day'));
            this.form.controls.businessUnit.setValue(this.data.timepunch?.businessUnitId || null);
        }

        if (commentRequired) {
            this.form.controls.comment.addValidators(Validators.required);
        }
    }

    submit() {
        const form = this.form.value;
        if (!(form.from && form.to && form.businessDate)) {
            return;
        }

        this.form.disable();
        const unitId = typeof form.businessUnit === 'number' ? form.businessUnit : form.businessUnit?.id || null;
        const observable = this.data.paidTime ?
            this.update(this.data.paidTime, form.from, form.to, form.businessDate, unitId) :
            this.create(this.data.customerId, this.data.employeeId, form.from, form.to, form.businessDate, unitId);

        observable.pipe(
            catchError(() => {
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe((res) => {
            this.dialogRef.close(res);
        });
    }

    create(customerId: number, employeeId: number, from: DateTime, to: DateTime, businessDate: DateTime, businessUnitId?: number | null) {
        return this.paidTimeService.create(customerId, employeeId, {
            from,
            to,
            businessDate,
            businessUnitId,
            comment: this.form.controls.comment.value || undefined,
            customFields: this.form.controls.customFields.getValues(),
        });
    }

    update(paidTime: PaidTime, from: DateTime, to: DateTime, businessDate: DateTime, businessUnitId?: number | null) {
        return this.paidTimeService.update(paidTime.customerId, paidTime.employeeId, paidTime.id, {
            from,
            to,
            businessDate,
            businessUnitId,
            comment: this.form.controls.comment.value || undefined,
            customFields: this.form.controls.customFields.getValues(),
        });
    }
}
