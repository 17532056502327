// @ts-nocheck
import { module } from 'angular';
export class ImageGallery {
    $mdDialog;
    static get $inject() {
        return [ '$mdDialog' ];
    }

    static template = `<md-dialog id="image-gallery-dialog" class="large-dialog">
    <eaw-dialog-header-old heading="ctrl.items[ctrl.index].name">
        <eaw-dialog-header-tools>
            <eaw-toggle-btn ng-model="ctrl.containImage" icon="photo_size_select_large"></eaw-toggle-btn>
        </eaw-dialog-header-tools>
    </eaw-dialog-header-old>

    <md-dialog-content>
        <div class="md-dialog-content" layout="row" layout-align="center center" ng-class="{'contain': ctrl.containImage}">
            <img src="{{ctrl.items[ctrl.index].source}}" />
        </div>
    </md-dialog-content>
</md-dialog>
`;

    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    open(options) {
        return this.$mdDialog.show({
            template: ImageGallery.template,
            controllerAs: 'ctrl',
            bindToController: true,
            clickOutsideToClose: true,
            controller: function() {
                this.$onInit = () => {
                    this.containImage = true;
                };
            },
            locals: {
                index: options.index || 0,
                items: options.items || [],
            },
        });
    }
}
module('eaw.dialogs').service('ImageGallery', ImageGallery);
