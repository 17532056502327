<eaw-page-header [buttons]="buttons">
    <span title>{{title | async}}</span>

    <span subtitle class="tw-flex tw-flex-row tw-items-center tw-gap-8">
        <eaw-date-interval-selector interval="month" (intervalChange)="updateInterval($event)" [from]="from" [to]="to"/>
    </span>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        @if (loading) {
            <div class="loading-overlay">
                <mat-progress-spinner mode="indeterminate" diameter="80" strokeWidth="7"></mat-progress-spinner>
            </div>
        }

        @if (mode === 'table') {
            <table mat-table matSort recycleRows (matSortChange)="sort($event)" [fixedLayout]="true" [dataSource]="dataSource" [trackBy]="tableTrackByFn" [matSortActive]="sortActive" [matSortDirection]="sortDirection">
                <!-- Date Column -->
                <ng-container matColumnDef="date" sticky>
                    <th mat-header-cell mat-sort-header="date" *matHeaderCellDef>{{'DATE' | translate | async}}</th>
                    <td mat-cell *matCellDef="let item">
                        <span>{{item.date | DateTime:'DATE_MED_WITH_WEEKDAY'}}</span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- Last Updated Column -->
                <ng-container matColumnDef="lastUpdated">
                    <th mat-header-cell *matHeaderCellDef>{{'UPDATED' | translate | async}}</th>
                    <td mat-cell *matCellDef="let item">
                        <span>{{item.lastUpdated}}</span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- Value Column -->
                @for (column of columns; track column.kpiType) {
                    <ng-container [matColumnDef]="column.kpiType.key">
                        <th mat-header-cell [mat-sort-header]="column.kpiType.id + ''" *matHeaderCellDef [ngClass]="column.kpiType.type">
                            {{column.kpiType.wtiKey | translate:'kpi_types' | async}}
                        </th>
                        <td mat-cell *matCellDef="let item" [ngClass]="column.kpiType.type">
                            {{item.values[column.kpiType.id]}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef [ngClass]="column.kpiType.type">
                            {{sums[column.kpiType.id]}}
                        </td>
                    </ng-container>
                }

                <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns;" [class.is-today]="row.isToday"></tr>
                <tr mat-footer-row *matFooterRowDef="displayColumns; sticky: true"></tr>
            </table>
        }

        <div id="chart" [style.display]="mode === 'chart' ? 'block' : 'none'"></div>
    </mat-card-content>
</mat-card>
