import { module } from 'angular';
import MlBucket from '../../../../../../ai-budgeting/angularjs/shared/ml-bucket';
import { sort } from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { Products } from '../../../../../../shared/enums/products';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';

const createDialogTemplate = `<md-dialog>
    <eaw-dialog-header-old heading="'Create bucket'"></eaw-dialog-header-old>

    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="newBucketForm">
                <md-input-container class="error-spacer">
                    <label ng-i18next="NAME"></label>
                    <input ng-model="ctrl.name" md-maxlength="100" name="name" ng-maxlength="100" ng-minlength="5" uniq-form-name required>
                    <div ng-messages="newBucketForm.name.$error">
                        <div ng-message="required" ng-i18next="errors:FORM_FIELD_REQUIRED"></div>
                        <div ng-message="minlength">Name must be at least 5 characters</div>
                        <div ng-message="maxlength">Name is too long</div>
                    </div>
                </md-input-container>

                <md-input-container class="md-block">
                    <label ng-i18next="DESCRIPTION"></label>
                    <textarea ng-model="ctrl.description" rows="2"></textarea>
                </md-input-container>

                <eaw-object-creator label="'Tags'" ng-model="ctrl.tags"></eaw-object-creator>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
        <md-button form="newBucketForm" class="md-raised md-accent" ng-disabled="newBucketForm.$invalid" ng-click="ctrl.submit()" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
const updateDialogTemplate = `<md-dialog>
    <eaw-dialog-header-old heading="'Update bucket'"></eaw-dialog-header-old>

    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="updateBucketForm">
                <eaw-object-creator label="'Tags'" ng-model="ctrl.tags"></eaw-object-creator>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
        <md-button form="updateBucketForm" class="md-raised md-accent" ng-disabled="updateBucketForm.$invalid" ng-click="ctrl.submit()" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.admin').component('adminCustomerMlBucketsList', {
    template: `
<div ng-if="!$ctrl.hasBucketProduct">
    <span>This customer does not have the required product <code>Machine Learning</code>.</span>
</div>

<div ng-if="$ctrl.hasBucketProduct">
<md-card>
    <md-card-content layout="row">
        <md-input-container md-no-float>
            <input placeholder="Search" ng-model="$ctrl.filterText" ng-change="$ctrl.filter()" uniq-form-name>
        </md-input-container>

        <md-divider class="tw-ml-20 tw-mr-20"></md-divider>

        <md-button class="new-bucket-btn md-icon-button md-accent md-raised" ng-click="$ctrl.createBucket()">
            <md-icon ng-bind="'add'"></md-icon>
        </md-button>
    </md-card-content>
</md-card>

<md-progress-circular ng-if="$ctrl.gettingBuckets" md-diameter="45" class="md-progress-center tw-mt-20"></md-progress-circular>

<eaw-accordion class="ml-bucket-accordion" ng-show="$ctrl.buckets.length">
    <admin-customer-ml-bucket ng-repeat="bucket in $ctrl.filteredBuckets track by bucket.uuid"
                              customer="$ctrl.customer"
                              bucket="bucket"
                              on-update="$ctrl.updateBucket(bucket)">
    </admin-customer-ml-bucket>
</eaw-accordion>
</div>
`,
    bindings: {
        customer: '<',
        customerDetailsId: '<',
    },
    controller: [ 'mlBucketFactory', '$mdDialog', function(mlBucketFactory, $mdDialog) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.hasBucketProduct = ctrl.customer.hasProduct(Products.MachineLearning);
            ctrl.canCreate = CurrentOld.can(mlBucketFactory.create.permission(ctrl.customer.id));

            if (ctrl.hasBucketProduct) {
                ctrl.getBuckets();
            }
        };
        ctrl.filter = () => {
            const filter = ctrl.filterText?.toLowerCase();
            ctrl.filteredBuckets = !filter ? ctrl.buckets : ctrl.buckets.filter((b: any) => {
                return [ 'name', 'description', 'uuid' ].some((k) => b[k]?.toLowerCase().includes(filter));
            });
        };
        ctrl.getBuckets = () => {
            ctrl.gettingBuckets = true;
            return mlBucketFactory.getAllForCustomer.query(ctrl.customerDetailsId, {
                per_page: 999,
            }).then((resp: any) => {
                ctrl.buckets = resp.data.map((b: any) => (new MlBucket(b)).setCanUpdate(CurrentOld.can(mlBucketFactory.update.permission(ctrl.customer.id, b.uuid))));
                ctrl.sortBuckets();
                ctrl.filter();
            }).finally(() => {
                delete ctrl.gettingBuckets;
            });
        };
        ctrl.sortBuckets = () => {
            ctrl.buckets = sort(ctrl.buckets, CurrentOld.languageTag, [ (b: any) => b.name.toLowerCase() ], [ 'asc' ]);
        };
        ctrl.createBucket = () => $mdDialog.show({
            template: createDialogTemplate,
            controller: 'createMlBucketDialog',
            controllerAs: 'ctrl',
            bindToController: true,
            locals: {
                customer: ctrl.customer,
            },
        }).then((bucket: any) => {
            ctrl.buckets.push(bucket);
            ctrl.sortBuckets();
            ctrl.filter();
        });
        ctrl.updateBucket = (bucket: any) => $mdDialog.show({
            template: updateDialogTemplate,
            controller: 'updateMlBucketDialog',
            controllerAs: 'ctrl',
            bindToController: true,
            locals: {
                customer: ctrl.customer,
                bucket,
            },
        }).then(() => {
            ctrl.buckets = [];
            ctrl.getBuckets();
        });
    } ],
});
