import { booleanAttribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, input, OnInit, output, signal } from '@angular/core';
import { BasicDataSource } from '../../../shared/utils/basic-data-source';
import { BalanceService } from '../../http/balance.service';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { CurrentService } from '../../../shared/services/current.service';
import { BalanceChange } from '../../models/balance-change';
import { BalanceType } from '../../models/balance-type';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { PaginationOptions } from '../../../shared/interfaces/pagination-options';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, AsyncPipe } from '@angular/common';
import { SubheaderComponent } from '../../../shared/components/subheader/subheader.component';
import { MatButton } from '@angular/material/button';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaw-balance-history',
    templateUrl: './balance-history.component.html',
    styleUrl: './balance-history.component.scss',
    standalone: true,
    imports: [
        SubheaderComponent,
        NgIf,
        MatProgressBarModule,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        MatTooltipModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        MatButton,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceHistoryComponent implements OnInit {
    balanceService = inject(BalanceService);
    numberFormatterService = inject(NumberFormatterService);
    current = inject(CurrentService);
    changeDetectorRef = inject(ChangeDetectorRef);
    destroy = inject(DestroyRef);

    customerId = input.required<number>();
    employeeId = input.required<number>();
    height = input.required<string>();
    balanceType = input.required<BalanceType>();
    deletable = input(false, { transform: booleanAttribute });

    deleted = output();

    protected dataSource?: BasicDataSource<BalanceChange>;
    protected loadingInitial = signal(true);
    protected deleting = new Set<number>();

    ngOnInit(): void {
        this.createDataSource();
    }

    export() {
        return expandAllPages((pagination: PaginationOptions) => this.balanceService.getChanges(this.customerId(), this.employeeId(), this.balanceType().balanceCode, pagination), {
            per_page: 64,
            order_by: 'business_date',
        });
    }

    refresh() {
        this.loadingInitial.set(true);
        this.dataSource?.refresh().subscribe(() => this.loadingInitial.set(false));
    }

    delete(change: BalanceChange) {
        this.deleting.add(change.id);
        this.balanceService.deleteBalanceChange(this.customerId(), this.employeeId(), this.balanceType().balanceCode, change.id).pipe(takeUntilDestroyed(this.destroy)).subscribe({
            next: () => {
                if (!this.loadingInitial()) {
                    this.refresh();
                }
                this.deleted.emit();
            },
            error: () => {
                this.deleting.delete(change.id);
                this.changeDetectorRef.markForCheck();
            },
        });
    }

    createDataSource() {
        const orderBy = 'business_date';
        const perPage = 64;

        this.balanceService.getChanges(this.customerId(), this.employeeId(), this.balanceType().balanceCode, { per_page: perPage, order_by: orderBy }).pipe(takeUntilDestroyed(this.destroy)).subscribe((res) => {
            this.loadingInitial.set(false);

            this.dataSource = new BasicDataSource(res.total, perPage, res.data, (page) => {
                return this.balanceService.getChanges(this.customerId(), this.employeeId(), this.balanceType().balanceCode, {
                    per_page: perPage,
                    order_by: orderBy,
                    page,
                }).pipe(takeUntilDestroyed(this.destroy));
            });
        });
    }

    formatNumber(value: number) {
        return this.balanceType().getFormattedValue(value, this.current.languageTag, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            signDisplay: 'exceptZero',
        });
    }
}
