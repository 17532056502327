import { EnvironmentInjector } from '@angular/core';
import { ProfileSidenavService } from '../../profile/services/profile-sidenav.service';
import { ProfileNavHeaderComponent } from '../../profile/shared/components/profile-nav-header/profile-nav-header.component';
import { currentResolver } from '../resolvers/current.resolver';
import { LearningModuleModule } from '../../learning-module/types/modules';

export function addProfileSidenav(injector: EnvironmentInjector) {
    injector.get(ProfileSidenavService).init({
        sidenavParentState: `eaw/app`,
        sidenavState: `eaw/app/profile`,
        url: '/profile',
        headerComponent: ProfileNavHeaderComponent,
        hideGoIcon: true,
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            learningModuleModules: [ LearningModuleModule.Profile ],
            breadcrumb: { key: 'PROFILE' },
        },
    });
}
