<eaw-dialog-header>
    <span title>{{'USER_ACCESS' | translate: 'company' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="formGroup" class="tw-flex tw-flex-col">
        <mat-form-field>
            <mat-label>{{'USER' | translate | async}}</mat-label>
            <input matInput [value]="data.userAccess.user.name" disabled>
        </mat-form-field>

        <eaw-date-time-range-input class="tw-w-full tw-grid tw-grid-cols-1 tw-gap-8">
            <mat-form-field eawDateTimeFrom>
                <mat-label>{{'FROM' | translate | async}}</mat-label>
                <eaw-date-time-input formControlName="from" [minDate]="minFrom" ></eaw-date-time-input>
            </mat-form-field>

            <mat-form-field eawDateTimeTo>
                <mat-label>{{'TO' | translate | async}}</mat-label>
                <eaw-date-time-input formControlName="to"></eaw-date-time-input>
            </mat-form-field>
        </eaw-date-time-range-input>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">{{'CANCEL' | translate | async}}</button>
    <button mat-button
            mat-raised-button
            (click)="submit()"
            [disabled]="!formGroup.valid"
            color="primary">{{'SUBMIT' | translate | async}}</button>
</mat-dialog-actions>
