// @ts-nocheck
import { module } from 'angular';
import moment from 'moment/moment';
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="'scheduling:CHOOSE_BUSINESS_DATE' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="dateForm" id="dateForm" ng-cloak ng-submit="bdCtrl.submit()" autocomplete="off">
                <shift-business-date shift="bdCtrl.shift" schedule="bdCtrl.schedule" required></shift-business-date>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button form="dateForm" ng-disabled="dateForm.$invalid" type="submit" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.scheduling').factory('chooseBusinessDate', [ '$mdDialog', function chooseBusinessDateFactory($mdDialog) {
    return function chooseBusinessDate(schedule, shift) {
        const businessDateOffset = moment.duration(schedule.customer.getProperty('shift:business_date_start_time', '00:00'));
        const businessDateOffsetEnabled = schedule.customer.getProperty('business_date_offset');
        if (businessDateOffsetEnabled === '1') {
            return Promise.resolve(shift);
        }
        const shiftFrom = shift.from.clone().utc(true).subtract(businessDateOffset);
        const shiftTo = shift.to.clone().utc(true).subtract(businessDateOffset);
        const busDateAsUTC = shift.business_date.clone().utc(true);

        if (shiftFrom.isSame(busDateAsUTC, 'd') && shiftTo.clone().subtract(1, 's').isSame(busDateAsUTC, 'd')) {
            return Promise.resolve(shift);
        }
        const preset = {
            template: template1,
            controller: function() {
                // @ts-ignore
                this.submit = () => $mdDialog.hide(this.shift);
            },
            bindToController: true,
            controllerAs: 'bdCtrl',
            locals: {
                schedule,
                shift,
            },
        };
        return new Promise((resolve, reject) => {
            $mdDialog.show(preset).then(resolve).catch(reject);
        });
    };
} ]);
