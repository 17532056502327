import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'employee-external-upgraded',
    standalone: true
})
export class employeeExternalUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;
    @Input() employee!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('employeeExternal', elementRef, injector);
    }
}

@Component({
    template: '<employee-external-upgraded [customer]="customer" [employee]="employee"></employee-external-upgraded>',
    standalone: true,
    imports: [employeeExternalUpgradedDirective],
})
export class employeeExternalUpgradedComponent {
    @Input() customer!: any;
    @Input() employee!: any;
}

@NgModule({
    imports: [employeeExternalUpgradedDirective,
        employeeExternalUpgradedComponent],
})
export class employeeExternalUpgradeModule {
}
