import { Component, Inject, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { ScheduleDay } from '../../angularjs/types/schedule-day';
import type angular from 'angular';
import { Namespace } from '../../../shared/enums/namespace';
import { ScheduleStatisticsService } from '../../http/schedule-statistics.service';
import { EmployeeStat, ScheduleStatistics, WeekStatistic } from '../../models/schedule-statistics';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../shared/services/current.service';
import { Employee } from '../../../shared/models/employee';
import { catchError, firstValueFrom, forkJoin, map, Observable, of, shareReplay, Subscription, tap } from 'rxjs';
import { DateTime } from 'luxon';
import { SettingService } from '../../../shared/http/setting.service';
import { Customer } from '../../../shared/models/customer';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ScheduleStatisticsColumnData } from '../../models/schedule-statistics-column-data';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { loadNamespaces } from 'i18next';
import { UserPropertyService } from '../../../shared/http/user-property.service';

type EmployeeWithStats = Employee & {
    stats?: EmployeeStat
}

@Component({
    selector: 'eaw-statistics-sidebar',
    templateUrl: './statistics-sidebar.component.html',
    styleUrl: './statistics-sidebar.component.scss',
    providers: [
        {
            provide: 'scheduleDays',
            useFactory: ($injector: any) => $injector.get('scheduleDays'),
            deps: [ '$injector' ],
        },
        {
            provide: 'shiftEvents',
            useFactory: ($injector: any) => $injector.get('shiftEvents'),
            deps: [ '$injector' ],
        },
        {
            provide: 'scheduleTable',
            useFactory: ($injector: any) => $injector.get('scheduleTable'),
            deps: [ '$injector' ],
        },
        {
            provide: '$rootScope',
            useFactory: ($injector: any) => $injector.get('$rootScope'),
            deps: [ '$injector' ],
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatExpansionModule,
        MatTooltipModule,
        MatCardModule,
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
        TranslateSyncPipe,
    ],
})
export class StatisticsSidebarComponent implements OnInit, OnDestroy {
    private readonly currentService = inject(CurrentService);
    private readonly scheduleStatisticsService: ScheduleStatisticsService = inject(ScheduleStatisticsService);
    private readonly settingService: SettingService = inject(SettingService);
    private userPropertyService = inject(UserPropertyService);

    statistics?: ScheduleStatistics;
    schedule!: {
        id: number,
        employees: EmployeeWithStats[],
        customer_id: number
    };

    customer?: Customer;
    loading = true;
    sundayCheckAvailable = false;
    averageDaysCheckAvailable = false;
    currentWeek?: WeekStatistic;
    sorts = [
        {
            type: 'name',
            direction: 'asc',
            label: 'NAME',
            namespace: undefined,
        },
        {
            type: 'diff',
            direction: 'asc',
            label: 'MISSING_CONTRACT_HOURS',
            namespace: Namespace.Scheduling,
        },
        {
            type: 'diff',
            direction: 'desc',
            label: 'TOO_MANY_CONTACT_HOURS',
            namespace: Namespace.Scheduling,
        },
    ] as const;

    sort = this.sorts[0];

    private weekNr!: number;
    private weekYear?: number;
    private onChangedShift?: Subscription;
    private weekChangeListener?: () => void;
    private weeklyStats?: Observable<ScheduleStatistics>;

    // once schedule is upgraded, can be changed to computed(ScheduleComponent.properties.scheduleTab.showNicknames.value());
    showNicknames = signal(false);

    constructor(
        // using inject() with AngularJS services doesn't work
        @Inject('shiftEvents') protected shiftEvents: any,
        @Inject('scheduleTable') protected scheduleTable: any,
        @Inject('$rootScope') protected rootScope: angular.IRootScopeService,
    ) {
    }

    async ngOnInit() {
        this.customer = this.currentService.getCustomer();

        if (this.customer) {
            const settings = await firstValueFrom(this.settingService.getSome([ 'customers', this.customer.id ], {
                'settings[]': [ 'scheduling.display_sunday_work', 'scheduling.display_average_days' ],
            }));

            this.sundayCheckAvailable = settings.find((s) => s.key === 'scheduling.display_sunday_work')?.value?.asBoolean() ?? false;
            this.averageDaysCheckAvailable = settings.find((s) => s.key === 'scheduling.display_average_days')?.value?.asBoolean() ?? false;
        }

        this.schedule = this.scheduleTable.getSchedule();

        this.sort = this.sorts[0];

        this.weekChangeListener = this.rootScope.$on('sidebarchildren:weekChanged', (_, week: ScheduleDay[]) => {
            const firstDayOfWeek = week[0];

            this.weekYear = firstDayOfWeek?.weekYear;
            this.updateDays(firstDayOfWeek);
        });

        this.onChangedShift = this.shiftEvents.getChangedSubject()
            .pipe(tap(() => this.updateDays(undefined, true)))
            .subscribe();

        this.updateDays();
        this.getShowNicknameSetting();

        await loadNamespaces([ Namespace.Scheduling ]);
    }

    ngOnDestroy(): void {
        this.weekChangeListener?.();
        this.onChangedShift?.unsubscribe();
    }

    updateDays(day?: ScheduleDay, updateWeekly?: boolean) {
        const d = day || this.scheduleTable.getCurrentDay();

        this.weekNr = d.weekNr;
        this.getStatistics(updateWeekly);
    }

    sortChange() {
        if (this.sort?.type === 'name') {
            sort(this.schedule.employees, this.currentService.languageTag, [ (e: EmployeeWithStats) => e.name ], [ this.sort.direction ]);
        } else {
            this.schedule.employees = this.schedule.employees?.sort((a: EmployeeWithStats, b: EmployeeWithStats) => {
                let valA = a.stats?.accumulated_hours_difference_with_contract || 0;
                let valB = b.stats?.accumulated_hours_difference_with_contract || 0;
                if (this.sort?.direction !== 'asc') {
                    [ valA, valB ] = [ valB, valA ];
                } // swap values
                return valA - valB;
            });
        }
    }

    getWeeklyStatistics(force: boolean = false) {
        if (!this.weeklyStats || force) {
            this.weeklyStats = this.scheduleStatisticsService.get(this.schedule.customer_id, this.schedule.id).pipe(shareReplay(1));
        }

        return this.weeklyStats;
    }

    getStatistics(updateWeekly?: boolean) {
        this.loading = true;

        forkJoin([
            this.getWeeklyStatistics(updateWeekly),
            this.averageDaysCheckAvailable || this.sundayCheckAvailable ? this.scheduleStatisticsService.getColumnsData(this.schedule.customer_id, this.schedule.id, {
                params: {
                    average_days_worked: this.averageDaysCheckAvailable,
                    sunday_work: this.sundayCheckAvailable,
                },
            }) : of(new ScheduleStatisticsColumnData({})),
        ]).pipe(tap((([ statistics, columnsData ]) => {
            this.statistics = statistics;

            const weekMinus1: number = this.weekNr - 1;
            const previousWeek: number = weekMinus1 > 0 ? weekMinus1 : DateTime.now().set({
                weekNumber: this.weekNr,
                weekYear: this.weekYear,
            }).minus({ weeks: 1 }).weekNumber;

            this.schedule.employees?.forEach((employee: EmployeeWithStats) => {
                // Set default stats if not existing
                employee.stats ||= {};

                const weekData = this.statistics?.weeklyStatistics?.find((week) => week.week_number == this.weekNr);
                if (weekData) {
                    const empData = weekData.employees_stats.find((e) => e.employee_id == employee.id);
                    employee.stats = empData || employee.stats;
                }

                if (columnsData.sundayWork) {
                    employee.stats.worked_last_sunday = columnsData.sundayWork?.[previousWeek]?.includes(employee.id);
                }

                if (columnsData.averageDaysWorked) {
                    employee.stats.average_days_worked = columnsData.averageDaysWorked[employee.id]?.[this.weekNr];
                }
            });

            this.currentWeek = this.statistics?.weeklyStatistics?.find((week) => week.week_number == this.weekNr);

            this.sortChange();
            this.loading = false;
        }))).subscribe();
    }

    getShowNicknameSetting() {
        return this.userPropertyService.get(this.currentService.getUser().id, 'schedule:setting:showNicknames').pipe(
            map((property) => property.value.asBoolean()),
            catchError(() => of(false)),
        ).subscribe((val) => {
            this.showNicknames.set(!!val);
        });
    }
}
