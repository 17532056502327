<div class="tw-flex tw-items-center">
    <button mat-icon-button
            aria-label="Open side menu button"
            [style.visibility]="showSidebarButton() ? 'visible' : 'hidden'"
            (click)="toggleSidebar()">
        <mat-icon>{{ sidebarToggled() ? 'menu_open' : 'menu' }}</mat-icon>
    </button>

    <button id="location-button" mat-button (click)="changeLocation()">
        <mat-icon class="tw-mr-4 tw-hidden md:tw-block">domain</mat-icon>
        <span>{{ locationName() }}</span>
    </button>
</div>

<div class="tw-flex tw-justify-center">
    <eaw-logo [inverse]="true" class="tw-hidden md:tw-block"></eaw-logo>
</div>

@if (newVersion()) {
    <div class="tw-flex tw-items-center tw-justify-end tw-gap-16 sm:tw-gap-12 md:tw-gap-8">
        <button id="new-version-button" mat-stroked-button (click)="updateVersion()">
            <mat-icon>system_update</mat-icon>
            <span>{{ 'NEW_VERSION' | translate | async }}</span>
        </button>
    </div>
} @else {
    <div class="tw-flex tw-items-center tw-justify-end tw-gap-16 sm:tw-gap-12 md:tw-gap-8">
        @if (!sidebarSmallScreen()) {
            <mat-slide-toggle [checked]="keepSidebarOpen()"
                              (change)="keepSidebarOpenChange($event.checked)"></mat-slide-toggle>
        }

        @if (!useItemsInMoreMenu()) {
            <button mat-icon-button (click)="openNotificationsDialog()">
                <mat-icon [matBadge]="notifications()"
                          matBadgeColor="warn"
                          [matBadgeHidden]="!hasNotifications()"
                          aria-hidden="false">
                    notifications
                </mat-icon>
            </button>

            <button mat-icon-button [matMenuTriggerFor]="supportMenu">
                <mat-icon>help</mat-icon>
            </button>
        }

        @if (isActing()) {
            <button mat-icon-button (click)="stopActing()">
                <mat-icon>person_off</mat-icon>
            </button>
        }

        @if (learningModuleAccess() && learningModuleLoaded()) {
            <button mat-icon-button (click)="learningModuleService.toggle(useDialogForLearningSidebar())">
                <mat-icon>menu_book</mat-icon>
            </button>
        }

        <img id="flag" (click)="changeLanguage()" [eawFlagSvg]="currentLanguage()?.countryCode" alt="flag">

        <button mat-icon-button [matMenuTriggerFor]="moreMenu">
            <mat-icon [matBadge]="notifications()"
                      matBadgeColor="warn"
                      [matBadgeHidden]="!hasNotifications() || !useItemsInMoreMenu()"
                      matBadgeOverlap>
                more_vert
            </mat-icon>
        </button>
    </div>
}

<mat-menu #supportMenu="matMenu">
    <button mat-menu-item (click)="openManual()">
        <mat-icon>auto_stories</mat-icon>
        <span>{{ 'USER_MANUAL' | translate | async }}</span>
    </button>

    @if (phone()) {
        <a mat-menu-item [href]="'tel:' + phone()">
            <mat-icon>support_agent</mat-icon>
            <span>{{ phone() }}</span>
        </a>
    }

    @if (mail()) {
        <a mat-menu-item [href]="'mailto:' + mail()">
            <mat-icon>mail</mat-icon>
            <span>{{ mail() }}</span>
        </a>
    }

    <button mat-menu-item (click)="openTos()">
        <mat-icon>gavel</mat-icon>
        <span>{{ 'TERMS_OF_SERVICE' | translate | async }}</span>
    </button>
</mat-menu>

<mat-menu #moreMenu="matMenu">
    @if (useItemsInMoreMenu()) {
        <button mat-menu-item [matMenuTriggerFor]="supportMenu">Support</button>

        <mat-divider></mat-divider>

        <button mat-menu-item (click)="openNotificationsDialog()">
            <mat-icon [matBadge]="notifications()"
                      matBadgeColor="warn"
                      [matBadgeHidden]="!hasNotifications() || !useItemsInMoreMenu()"
                      matBadgeOverlap>
                notifications
            </mat-icon>
            <span>{{ 'NOTIFICATION_plural' | translate | async }}</span>
        </button>
    }

    <button mat-menu-item (click)="clearCache()">
        <mat-icon>delete</mat-icon>
        <span>{{ 'CLEAR_CACHE' | translate | async }}</span>
    </button>

    @if (isDevelopment()) {
        <button mat-menu-item (click)="reload('state')">
            <mat-icon>refresh</mat-icon>
            <span>Reload state</span>
        </button>

        <button mat-menu-item (click)="reload('page')">
            <mat-icon>refresh</mat-icon>
            <span>Reload page</span>
        </button>
    }

    @if (isActing() || ('ui_debugger' | permission)) {
        <mat-divider></mat-divider>

        <button mat-menu-item (click)="listPermissionElements()">
            <mat-icon>code_blocks</mat-icon>
            <span>List permission elements</span>
        </button>

        <button mat-menu-item (click)="displayStateInfo()">
            <mat-icon>not_listed_location</mat-icon>
            <span>State info</span>
        </button>

        <button mat-menu-item (click)="displayMainMenuInfo()">
            <mat-icon>info</mat-icon>
            <span>Main menu info</span>
        </button>
    }
</mat-menu>
