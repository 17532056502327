import { Inject, Injectable } from '@angular/core';
import { SidenavTabsRegisterService, SidenavTabsRegisterServiceData } from './sidenav-tabs-register.service';
import { UIRouter } from '@uirouter/core';
import { SidenavTab } from '../components/sidenav/sidenav.component';
import { EmployeeInformationTabComponent } from '../../company/pages/employee-information-tab/employee-information-tab.component';
import { dependencyResolver } from '../resolvers/dependency.resolver';
import { ModelData } from '../angularjs/model-data';
import { UserOld } from '../angularjs/user-old';
import { UserLeaderRolesComponent } from '../../company/pages/user-leader-roles/user-leader-roles.component';
import { Namespace } from '../enums/namespace';
import { EmployeeCustomFieldsTabComponent } from '../../company/pages/employee-custom-fields-tab/employee-custom-fields-tab.component';
import { EmployeeQualificationsComponent } from '../../company/pages/employee-qualifications/employee-qualifications.component';
import { EmployeePositionsTabComponent } from '../../company/pages/employee-positions-tab/employee-positions-tab.component';
import { EmployeeHoursDistributionListComponent } from '../../company/pages/employee-hours-distribution-list/employee-hours-distribution-list.component';
import { Products } from '../enums/products';
import { familyMembersUpgradedComponent } from '../../company/angularjs/src/family-members/family-members.upgrade.component';
import { fiscalInfoUpgradedComponent } from '../../company/angularjs/src/fiscal-info/fiscal-info.upgrade.component';
import { employeeTransfersTabUpgradedComponent } from '../../company/angularjs/employees/info/transfers-tab/employee-transfers.upgrade.component';
import { EmployeeNotesComponent } from '../../company/pages/employee-notes/employee-notes.component';
import { hrEmployeeFilesUpgradedComponent } from '../../hr/angularjs/employee-files/employee-files.upgrade.component';
import { EmployeeAbsencesComponent } from '../../absence/pages/employee-absences/employee-absences.component';
import { employeeVacationUpgradedComponent } from '../../company/angularjs/employees/info/vacation/employee-vacation.upgrade.component';
import { employeeExternalUpgradedComponent } from '../../company/angularjs/employees/info/external/employee-external.upgrade.component';
import { myTimepunchesUpgradedComponent } from '../../payroll/angularjs/timepunch/my/my-timepunches.upgrade.component';
import { lastValueFrom, switchMap } from 'rxjs';
import { Pagination } from '../utils/pagination';
import { EmployeeRotationListComponent } from '../../rotation/employee-rotation-list/employee-rotation-list.component';
import { paidTimeEmployeeUpgradedComponent } from '../../payroll/angularjs/paid-time/employee/paid-time-employee.upgrade.component';
import { EmployeePayContractComponent } from '../../company/pages/employee-pay-contract/employee-pay-contract.component';
import { CustomerProductService } from '../http/customer-product.service';
import moment from 'moment-timezone';
import { EmployeeUserComponent } from '../../company/pages/employee-user/employee-user.component';
import { EmployeeFamilyMembersTabComponent } from '../../company/pages/employee-family-members-tab/employee-family-members-tab.component';
import { DataTableQueryParams } from '../../data-table/data-table.component';
import { EmployeeTimepunchBlocksTabComponent } from '../../company/pages/employee-timepunch-blocks-tab/employee-timepunch-blocks-tab.component';
import { PredefinedSettings } from '../enums/predefined-settings';

@Injectable({
    providedIn: 'root',
})
export class EmployeeSidenavService extends SidenavTabsRegisterService {
    constructor(
        @Inject(UIRouter) override router: UIRouter,
    ) {
        super(router);
    }

    getOldCustomer = (CustomerService: any, customerId: number) => {
        return CustomerService.get({ id: customerId }).$promise;
    };

    getOldEmployee = (EmployeeService: any, customerId: number, employeeId: number) => {
        return EmployeeService.get({ id: customerId }, employeeId, [ 'user.email_change', 'properties' ]);
    };

    /**
     * @param data
     * @param customerIdPlaceholder - Placeholder for customer id used in permission strings in state data
     * @param employeeIdPlaceholder - Placeholder for employee id used in permission strings in state data
     */
    init(data: SidenavTabsRegisterServiceData, customerIdPlaceholder: string, employeeIdPlaceholder: string) {
        this.registerTabs(data, this.getTabs(data, customerIdPlaceholder, employeeIdPlaceholder));
    }

    private getTabs(data: SidenavTabsRegisterServiceData, customerIdPlaceholder: string, employeeIdPlaceholder: string): SidenavTab[] {
        // Name of tokens used in resolves in parent state
        const customerIdToken = 'customerId';
        const employeeIdToken = 'employeeId';

        // Wrap param tokens in curly braces, so they can be replaced with the actual values when doing permission checks
        const c = `{${customerIdPlaceholder}}`;
        const e = `{${employeeIdPlaceholder}}`;

        // Pass in resolved customer/employee id tokens from our resolvers above, so we always get correct value
        const oldCustomerResolver = dependencyResolver('customer', [ 'CustomerService', customerIdToken ], this.getOldCustomer);

        // Pass in resolved customer/employee id tokens from our resolvers above, so we always get correct value
        const oldEmployeeResolver = dependencyResolver('employee', [ 'EmployeeService', customerIdToken, employeeIdToken ], this.getOldEmployee);

        // Pass in resolved customer/employee id tokens from our resolvers above, so we always get correct value
        const oldUserResolver = dependencyResolver('user', [ 'employee', 'CustomerService', customerIdToken ], (employee: any, CustomerService: any, customerId: any) => {
            if (!employee.user_id) {
                return null;
            }

            if (employee.user) {
                return new UserOld(employee.user);
            }

            return CustomerService.getUserById(customerId, employee.user_id, [ 'email_change' ]).$promise.then((resp: ModelData) => {
                return new UserOld(resp);
            });
        });

        return [
            {
                url: '/information',
                state: `${data.sidenavState}/information`,
                component: EmployeeInformationTabComponent,
                data: {
                    breadcrumb: { key: 'INFORMATION' },
                },
            },
            {
                url: '/user',
                state: `${data.sidenavState}/user`,
                component: EmployeeUserComponent,
                data: {
                    breadcrumb: { key: 'USER' },
                },
            },
            {
                url: '/roles',
                state: `${data.sidenavState}/roles`,
                component: UserLeaderRolesComponent,
                data: {
                    breadcrumb: { key: 'ROLE_plural', ns: Namespace.Company },
                    permissions: [ `customers.${c}.roles.*.get` ],
                },
            },
            {
                url: '/custom_fields',
                state: `${data.sidenavState}/custom_fields`,
                component: EmployeeCustomFieldsTabComponent,
                data: {
                    breadcrumb: { key: 'CUSTOM_FIELD_plural' },
                },
            },
            {
                url: '/pay_contract',
                state: `${data.sidenavState}/pay_contract`,
                component: EmployeePayContractComponent,
                data: {
                    breadcrumb: { key: 'PAY_RATE_CONTRACT_INFO', ns: Namespace.Company },
                    permissions: [
                        `customers.${c}.employees.${e}.pay_rates.*.get`,
                        `customers.${c}.employees.${e}.contracts.*.get`,
                    ],
                },
            },
            {
                url: '/qualifications',
                state: `${data.sidenavState}/qualifications`,
                component: EmployeeQualificationsComponent,
                data: {
                    breadcrumb: { key: 'QUALIFICATION_plural' },
                    permissions: [ `customers.${c}.employees.${e}.qualifications.*.get` ],
                },
            },
            {
                url: '/family',
                state: `${data.sidenavState}/family`,
                component: EmployeeFamilyMembersTabComponent,
                data: {
                    queryParams: DataTableQueryParams,
                    breadcrumb: { key: 'FAMILY_MEMBER_plural', ns: Namespace.Company },
                    permissions: [ `customers.${c}.employees.${e}.family_members_v2.*.get` ],
                    settings: {
                        [PredefinedSettings.FamilyMemberEnabled]: (value) => value?.asBoolean() === true,
                    },
                },
            },
            {
                url: '/positions',
                state: `${data.sidenavState}/positions`,
                component: EmployeePositionsTabComponent,
                data: {
                    queryParams: DataTableQueryParams,
                    breadcrumb: { key: 'POSITION_plural', ns: Namespace.Company },
                    permissions: [ `customers.${c}.employees.${e}.positions.*.get` ],
                },
            },
            {
                url: '/timepunch_blocks',
                state: `${data.sidenavState}/timepunch_blocks`,
                component: EmployeeTimepunchBlocksTabComponent,
                data: {
                    breadcrumb: { key: 'TIMEPUNCH_BLOCK_plural', ns: Namespace.Company },
                    permissions: [ `customers.${c}.employees.${e}.timepunch_blocks.*.get` ],
                    settings: {
                        [PredefinedSettings.PayrollTimepunchBlocksEnabled]: (value) => value?.asBoolean() === true,
                    },
                },
            },
            {
                url: '/hours_distribution',
                state: `${data.sidenavState}/hours_distribution`,
                component: EmployeeHoursDistributionListComponent,
                data: {
                    queryParams: DataTableQueryParams,
                    breadcrumb: { key: 'HOURS_DISTRIBUTION', ns: Namespace.Company },
                    permissions: [ `customers.${c}.employees.${e}.contract_hours_distributions.*.get` ],
                    products: [ Products.France ],
                },
            },
            {
                url: '/family_members',
                state: `${data.sidenavState}/family_members`,
                component: familyMembersUpgradedComponent,
                data: {
                    breadcrumb: { key: 'FAMILY_MEMBER_plural', ns: Namespace.Company },
                    permissions: [ `customers.${c}.employees.${e}.family_members.*.get` ],
                    products: [ Products.Switzerland ],
                    settings: {
                        'family_member.enabled': (value) => value?.asBoolean() !== true,
                    },
                },
                resolve: [
                    oldUserResolver,
                    oldEmployeeResolver,
                    oldCustomerResolver,
                ],
            },
            {
                url: '/fiscal',
                state: `${data.sidenavState}/fiscal`,
                component: fiscalInfoUpgradedComponent,
                data: {
                    breadcrumb: { key: 'FISCAL_INFO', ns: Namespace.Company },
                    permissions: [ `customers.${c}.employees.${e}.fiscal_info.get` ],
                    products: [ Products.Switzerland ],
                },
                resolve: [
                    oldEmployeeResolver,
                    oldCustomerResolver,
                ],
            },
            {
                url: '/transfers',
                state: `${data.sidenavState}/transfers`,
                component: employeeTransfersTabUpgradedComponent,
                data: {
                    breadcrumb: { key: 'TRANSFER_plural', ns: Namespace.ChainOps },
                    permissions: [
                        `customers.${c}.employees.${e}.swiss_transfers.*.get`,
                        `customers.${c}.swiss_transfers.*.delete`,
                    ],
                    products: [ Products.SwissRiskAndCare ],
                    i18nextNs: [ Namespace.ChainOps, Namespace.Scheduling ],
                },
                resolve: [
                    oldEmployeeResolver,
                    oldCustomerResolver,
                ],
            },
            {
                url: '/notes',
                state: `${data.sidenavState}/notes`,
                component: EmployeeNotesComponent,
                data: {
                    breadcrumb: { key: 'NOTE_plural' },
                    permissions: [ `customers.${c}.employees.${e}.comments.*.get` ],
                },
            },
            {
                url: '/hr',
                state: `${data.sidenavState}/hr`,
                component: hrEmployeeFilesUpgradedComponent,
                data: {
                    breadcrumb: { key: 'HR', ns: Namespace.Navigation },
                    permissions: [ `customers.${c}.employees.${e}.hr_files.*.get` ],
                    i18nextNs: [ Namespace.Hr, Namespace.Digisign ],
                    products: [ Products.HumanResources ],
                },
                resolve: [
                    dependencyResolver('hrEmp', [ 'EmployeeService', customerIdToken, employeeIdToken ], function(EmployeeService: any, customerId: any, employeeId: any) {
                        return EmployeeService.get({ id: customerId }, employeeId);
                    }),
                    dependencyResolver('signables', [ 'HrFileType', 'CustomerProductServiceDowngrade', 'signableService', customerIdToken ],
                        function(HrFileType: any, productService: CustomerProductService, signableService: any, customerId: number) {
                            const source = productService.hasProducts(customerId, [ Products.DigitalSigning ]).pipe(switchMap((hasDigitalSigning) => {
                                if (!hasDigitalSigning) {
                                    return [];
                                }

                                return signableService.getAll.query(customerId, { per_page: 100 }, 'Modules\\Hr\\Models\\File').then((resp: any) => {
                                    HrFileType.setSignables(resp.data);
                                    return resp.data;
                                }).catch((e: any) => e);
                            }));
                            return lastValueFrom(source);
                        }),
                ],
            },
            {
                url: '/absence',
                state: `${data.sidenavState}/absence`,
                component: EmployeeAbsencesComponent,
                data: {
                    breadcrumb: { key: 'ABSENCE_plural' },
                    permissions: [ `customers.${c}.employees.${e}.absences.*.get` ],
                    products: [ Products.Absence ],
                },
            },
            {
                url: '/vacation',
                state: `${data.sidenavState}/vacation`,
                component: employeeVacationUpgradedComponent,
                data: {
                    breadcrumb: { key: 'VACATION_plural' },
                    permissions: [ `customers.${c}.employees.${e}.off_times.*.get` ],
                    i18nextNs: [ Namespace.Vacation, Namespace.Company, Namespace.Absences ],
                    products: [ Products.Vacation ],
                },
                resolve: [
                    oldEmployeeResolver,
                    oldCustomerResolver,
                ],
            },
            {
                url: '/external',
                state: `${data.sidenavState}/external`,
                component: employeeExternalUpgradedComponent,
                data: {
                    breadcrumb: { key: 'EXTERNAL_AT', ns: Namespace.Company },
                    permissions: [ `customers.${c}.external_employees.*.get` ],
                },
                resolve: [
                    oldEmployeeResolver,
                    oldCustomerResolver,
                ],
            },
            {
                url: '/timepunches',
                state: `${data.sidenavState}/timepunches`,
                component: myTimepunchesUpgradedComponent,
                data: {
                    breadcrumb: { key: 'TIMEPUNCH_plural' },
                    permissions: [ `customers.${c}.employees.${e}.timepunches.*.get` ],
                    i18nextNs: [ Namespace.Payroll, Namespace.Absences ],
                    products: [ Products.Payroll ],
                },
                resolve: [
                    oldEmployeeResolver,
                    oldCustomerResolver,
                    dependencyResolver('businessUnits', [ 'BusinessUnitDowngraded', 'customer', 'CustomerProductServiceDowngrade' ], function(BusinessUnitDowngraded: any, customer: any, CustomerProductServiceDowngrade: CustomerProductService) {
                        if (!CustomerProductServiceDowngrade.hasProducts(customer.id, [ Products.BusinessUnits ])) {
                            return [];
                        }

                        return lastValueFrom(BusinessUnitDowngraded.getAllPages(customer['id'], new Pagination({
                            per_page: 100,
                            order_by: 'name',
                            direction: 'asc',
                            with: [ 'qualifications', 'properties' ],
                        })));
                    }),
                ],
            },
            {
                url: '/rotations',
                state: `${data.sidenavState}/rotations`,
                component: EmployeeRotationListComponent,
                data: {
                    queryParams: DataTableQueryParams,
                    breadcrumb: { key: 'ROTATION_plural', ns: Namespace.Rotation },
                    products: [ Products.Rotation ],
                },
            },
            {
                url: '/paid_time',
                state: `${data.sidenavState}/paid_time`,
                component: paidTimeEmployeeUpgradedComponent,
                data: {
                    breadcrumb: { key: 'PAID_TIME' },
                    permissions: [ `customers.${c}.employees.${e}.paid_times.*.get` ],
                    i18nextNs: [
                        Namespace.Vacation,
                        Namespace.Company,
                        Namespace.Absences,
                        Namespace.AbsenceTypes,
                        Namespace.Payroll,
                    ],
                    products: [ Products.PaidTime ],
                },
                params: {
                    from: {
                        dynamic: true,
                        type: 'date',
                        squash: true,
                        value: moment().startOf('month').toDate(),
                    },
                    to: {
                        dynamic: true,
                        type: 'date',
                        squash: true,
                        value: moment().endOf('month').toDate(),
                    },
                    locations: {
                        dynamic: true,
                        type: 'string',
                        squash: true,
                        value: '',
                    },
                },
                resolve: [
                    oldEmployeeResolver,
                    oldCustomerResolver,
                ],
            },
        ];
    }
}
