import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Customer } from '../../../../../shared/models/customer';
import { User } from '../../../../../shared/models/user';
import { Employee } from '../../../../../shared/models/employee';
import { MatDialog } from '@angular/material/dialog';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { ContractDialogComponent, ContractDialogData, ContractDialogReturn } from '../../../../dialogs/contract-dialog/contract-dialog.component';
import { DialogSize } from '../../../../../shared/dialogs/dialog-component';
import { CombinedContractDialogComponent, CombinedContractDialogData, CombinedContractDialogReturn } from '../../../../dialogs/combined-contract-dialog/combined-contract-dialog.component';
import { catchError, EMPTY, of } from 'rxjs';
import { CombinedContract } from '../../../../models/combined-contract';
import { SettingService } from '../../../../../shared/http/setting.service';
import { Contract } from '../../../../../shared/models/contract';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { NumberPipe } from '../../../../../shared/pipes/number.pipe';
import { DateTimePipe } from '../../../../../shared/pipes/date-time.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from '../../../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '../../../../../shared/services/translate.service';
import { CombinedContractService } from '../../../../http/combined-contract.service';
import { ContractService } from '../../../../../shared/http/contract.service';
import { InfoLoadingComponent } from '../../../../../shared/components/info-loading/info-loading.component';
import { NewEmployeeNextStepComponent } from '../../components/new-employee-next-step/new-employee-next-step.component';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';

@Component({
    selector: 'eaw-new-employee-contract',
    standalone: true,
    imports: [
        CommonModule,
        ActionButtonComponent,
        TranslatePipe,
        NumberPipe,
        DateTimePipe,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        InfoLoadingComponent,
        NewEmployeeNextStepComponent,
    ],
    templateUrl: './new-employee-contract.component.html',
    styleUrl: './new-employee-contract.component.scss',
})
export class NewEmployeeContractComponent extends NewEmployeeStepComponent implements OnInit {
    private matDialog = inject(MatDialog);
    private settingService = inject(SettingService);
    private translateService = inject(TranslateService);
    private combinedContractService = inject(CombinedContractService);
    private contractService = inject(ContractService);

    @Input({ required: true }) customer!: Customer;
    @Input({ required: true }) employee!: Employee;
    @Input({ required: true }) user?: User;

    loadingData = true;
    processing = false;
    useCombinedContract = false;
    contract?: Contract;
    combinedContract?: CombinedContract;

    override ngOnInit() {
        super.ngOnInit();

        this.settingService.getString([ 'customers', this.customer.id ], 'france.use_combined_contract').subscribe((value) => {
            if (value === '1') {
                this.useCombinedContract = true;
            }

            this.loadingData = false;
        });
    }

    open() {
        if (this.useCombinedContract) {
            this.openCombinedContract();
        } else {
            this.openDefault();
        }
    }

    edit() {
        if (this.useCombinedContract && this.combinedContract) {
            this.openCombinedContract(this.combinedContract);
        }

        if (!this.useCombinedContract && this.contract) {
            this.openDefault(this.contract);
        }
    }

    delete() {
        if (this.useCombinedContract && this.combinedContract) {
            this.deleteCombinedContract(this.combinedContract);
        }

        if (!this.useCombinedContract && this.contract) {
            this.deleteContract(this.contract);
        }
    }

    deleteContract(contract: Contract) {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                size: DialogSize.Medium,
                title: this.translateService.t('DELETE_CONTRACT', 'payroll'),
                text: this.translateService.t('DELETE_CONTRACT_CONFIRM', 'payroll'),
                confirmColor: 'red-500',
            },
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                return;
            }

            this.processing = true;
            this.contractService.delete(this.customer.id, this.employee.id, contract.id).pipe(
                catchError(() => {
                    this.processing = false;
                    return EMPTY;
                }),
            ).subscribe(() => {
                this.contract = undefined;
                this.processing = false;
                this.step.completed = this.step.optional;
            });
        });
    }

    deleteCombinedContract(combinedContract: CombinedContract) {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                size: DialogSize.Medium,
                title: this.translateService.t('DELETE_CONTRACT', 'payroll'),
                text: this.translateService.t('DELETE_CONTRACT_CONFIRM', 'payroll'),
                confirmColor: 'red-500',
            },
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                return;
            }

            this.processing = true;
            this.combinedContractService.delete(this.customer.id, this.employee.id, combinedContract.contract.id).pipe(
                catchError(() => {
                    this.processing = false;
                    return EMPTY;
                }),
            ).subscribe(() => {
                this.combinedContract = undefined;
                this.contract = undefined;
                this.processing = false;
                this.step.completed = this.step.optional;
            });
        });
    }

    openDefault(contract?: Contract) {
        this.matDialog.open<ContractDialogComponent, ContractDialogData, ContractDialogReturn>(ContractDialogComponent, {
            data: {
                customerId: this.customer.id,
                employeeId: this.employee.id,
                contract: contract ? contract : undefined,
                size: DialogSize.Medium,
                fromTo: {
                    editable: true,
                    from: this.employee.from,
                    to: this.employee.to,
                },
            },
        }).afterClosed().subscribe((result) => {
            if (result instanceof Contract) {
                this.contract = result;
                this.step.completed = true;
            }
        });
    }

    openCombinedContract(combinedContract?: CombinedContract) {
        this.matDialog.open<CombinedContractDialogComponent, CombinedContractDialogData, CombinedContractDialogReturn>(CombinedContractDialogComponent, {
            data: {
                customerId: this.customer.id,
                employeeId: this.employee.id,
                combinedContract: combinedContract ? of(combinedContract) : undefined,
                from: this.employee.from || undefined,
                to: this.employee.to || undefined,
            },
        }).afterClosed().subscribe((result) => {
            if (result instanceof CombinedContract) {
                this.combinedContract = result;
                this.contract = result.contract;
                this.step.completed = true;
            }
        });
    }
}
