// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-list class="md-dense">
    <md-list-item ng-show="!ctrl.error && ctrl.list.loaded && !ctrl.list.getLength()">
        <p ng-i18next="NO_DATA"></p>
    </md-list-item>

    <md-list-item ng-show="ctrl.error">
        <p ng-i18next="errors:SOMETHING_WENT_WRONG"></p>
        <md-button class="md-secondary" ng-click="ctrl.reload()">
            <md-icon ng-bind="'refresh'"></md-icon>
            <md-tooltip ng-i18next="RELOAD"></md-tooltip>
        </md-button>
    </md-list-item>

    <md-progress-circular ng-if="ctrl.list.loading" class="md-progress-center tw-mt-20"></md-progress-circular>

    <md-virtual-repeat-container ng-show="ctrl.list.getLength()">
        <md-list-item class="md-2-line" md-virtual-repeat="alert in ctrl.list" md-on-demand ng-show="!ctrl.list.loading">
            <div class="md-list-item-text tw-mb-16">
                <p ng-bind="alert.specifics.employee_name"></p>
                <span ng-bind="alert.message"></span>
            </div>

            <div class="md-secondary">
                <md-button class="md-secondary" ng-click="ctrl.delete(alert.id)">
                    <span ng-bind="'done'"></span>
                    <md-tooltip ng-i18next="DONE"></md-tooltip>
                </md-button>
            </div>
            <md-divider ng-if="!$last"></md-divider>
        </md-list-item>
    </md-virtual-repeat-container>
</md-list>
`;

module('eaw.dashboard').component('dashEmployeeAlertsList', {
    template: template1,
    controllerAs: 'ctrl',
    bindings: {
        getAlerts: '&', // getAlerts(pagination)
        deleteAlert: '&', // deleteAlert(id)
        resetTotal: '&',
    },
    controller: [ 'EndlessList', function(EndlessList) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.list = new EndlessList(ctrl.load, {
                per_page: 20,
            });
            ctrl.list.loadPage(1);
        };

        ctrl.reload = () => ctrl.list.reload();

        ctrl.load = function loadEntries(pagination) {
            delete ctrl.error;

            const getAlerts = ctrl.getAlerts({ pagination });
            getAlerts.catch(() => ctrl.error = true);

            return getAlerts;
        };

        ctrl.delete = (id) => {
            ctrl.deleteAlert({ id }).then(() => {
                ctrl.reload();
            }).finally(() => {
                ctrl.resetTotal();
            });
        };
    } ],
});
