// @ts-nocheck
import { t } from 'i18next';
import { orderBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';

module('eaw.company').component('employeeTransfersTab', {
    template: `<script type="text/ng-template" id="source-customer.template.ajs.html">
    <span>
    <span ng-bind="item.source_customer.name"></span>
    <md-tooltip ng-if="!item.source_customer_id" bo-i18next="[title]chainops:TRANSFER_SOURCE_CUSTOMER_TOOLTIP"></md-tooltip>
</span>
</script>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-i18next="chainops:TRANSFER_plural"></span>
        </md-card-header-text>
        <eaw-icon-button ng-if="$ctrl.canTransfer" icon="add" fab="true" type="accent" ng-click="$ctrl.addTransfer()"></eaw-icon-button>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <eaw-dynamic-ng-table
                reload-table="$ctrl.reloadTable"
                columns="$ctrl.columns"
                cells="$ctrl.cells"
                pagination="$ctrl.pagination"
                get-data="$ctrl.getTransfers(pagination)">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$ctrl',
    bindings: {
        customer: '<',
        employee: '<',
    },
    controller: [ 'ChainEmployeeFactory', 'eawDatePickerDialog', 'ItemSelectorDialog', '$mdDialog', function(ChainEmployeeFactory, eawDatePickerDialog, ItemSelectorDialog, $mdDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.availableCustomers = Object.values(CurrentOld.customers);
            ctrl.pagination = { order_by: { effective_date: 'asc' } };

            ctrl.columns = [
                {
                    title: t('chainops:EFFECTIVE_DATE'),
                    sortable: 'effective_date',
                },
                {
                    title: t('FROM'),
                    sortable: 'source_customer_id',
                },
                {
                    title: t('TO'),
                    sortable: 'target_customer_id',
                },
                {
                    title: t('chainops:EXECUTED_AT'),
                    sortable: 'executed_at',
                },
                { title: '' },
            ];

            ctrl.cells = [
                { template: 'item.effective_date | moment:"LL"' },
                { templateUrl: 'source-customer.template.ajs.html' },
                { template: 'item.target_customer.name' },
                { template: 'item.executed_at | moment:"LLL"' },
                {
                    buttons: [
                        {
                            type: 'warn',
                            icon: 'delete',
                            click: ctrl.deleteTransfer,
                            invisible: (item) => item.executed_at,
                        },
                    ],
                },
            ];

            ctrl.canTransfer = CurrentOld.can(`customers.${ctrl.customer.id}.swiss_transfers.create`);
        };

        ctrl.addTransfer = () => {
            ItemSelectorDialog.open({
                title: t('LOCATION_plural'),
                items: orderBy(ctrl.availableCustomers.filter((c) => c.id !== ctrl.customer.id), 'name', 'asc'),
                subheader: t('chainops:TRANSFER_SUBHEADER', {
                    name: ctrl.employee.name,
                }),
                displayKey: 'name',
            }).then((customer) => {
                eawDatePickerDialog.open(null, {
                    heading: t('chainops:TRANSFER_DATE'),
                    minDate: moment(),
                    businessDate: true,
                    callback(date) {
                        ChainEmployeeFactory.transfer(ctrl.customer.id, customer.id, ctrl.employee.id, date).then(ctrl.reload);
                    },
                });
            });
        };

        ctrl.deleteTransfer = (transfer) => {
            $mdDialog.show(
                $mdDialog.confirm()
                    .theme('delete')
                    .title(t('DELETE'))
                    .textContent(t('chainops:DELETE_TRANSFER_TEXT', {
                        loc1: transfer.source_customer.name,
                        loc2: transfer.target_customer.name,
                        name: ctrl.employee.name,
                    }))
                    .ok(t('DELETE'))
                    .cancel(t('CANCEL')),
            ).then(() => {
                ChainEmployeeFactory.deleteTransfer(ctrl.customer.id, transfer.id).then(ctrl.reload);
            });
        };

        ctrl.getTransfers = (pagination) => {
            const transfers = ChainEmployeeFactory.getAllEmployeeTransfers(ctrl.customer.id, ctrl.employee.id, {
                with: [ 'targetCustomer', 'sourceCustomer' ],
                ...pagination.toWithPaginatorArguments(),
            });

            transfers.then((resp) => {
                resp.data.forEach((item) => {
                    if (!item.source_customer) {
                        item.source_customer = ctrl.customer;
                    }
                });
            });

            return transfers;
        };

        ctrl.reload = () => ctrl.reloadTable = true;
    } ],
});
