import { debounce } from 'lodash-es';
import moment, { Moment } from 'moment-timezone';
import { module } from 'angular';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleMiniShiftDialogComponentOld, ScheduleMiniShiftDialogData } from '../../../../dialogs/schedule-mini-shift-dialog-old/schedule-mini-shift-dialog-component-old.component';
import { BusinessDate } from '../../../../../shared/utils/business-date';
import { DateTime } from 'luxon';
import { Employee } from '../../../../../shared/models/employee';
import { shiftTooltipTemplate } from '../shift-tooltip/shift-tooltip.template';
import { scheduleViewDragAndDrop } from '../../../../signals';
import { WebsocketService } from '../../../../../shared/services/websocket.service';
import { AngularJsDestroyRef } from '../../../../../shared/angularjs/angularjs-destroy-ref.class';

module('eaw.scheduling').component('scheduleTab', {
    template: `<schedule-toolbar schedule="$stCtrl.schedule"></schedule-toolbar>

<div id="schedule-container">
    <table id="schedule-table">
        <!-- DAYS -->
        <tbody id="schedule-table-days"></tbody>

        <!-- TABLE TOP STATS -->
        <tbody ng-if="$stCtrl.schedule.settings.topStatsEnabled" id="table-top-stats-body" schedule-top-stats></tbody>

        <!-- TABLE TIMES -->
        <tbody id="schedule-table-times"></tbody>

        <!-- NEW SHIFT LINE -->
        <tbody id="schedule-table-new"></tbody>
    </table>
</div>

<div id="shifts-container">
    <table id="shifts-table">
        <!--One or many-->
        <!--<tbody class="shifts-table-body"></tbody>-->
    </table>
</div>
`,
    controllerAs: '$stCtrl',
    bindings: {
        schedule: '<',
    },
    require: {
        scheduleView: '^scheduleView',
    },
    // @ts-ignore that these are not type hinted
    controller: [ 'WebsocketDowngrade', '$element', 'ComplexTooltip', 'BackOfficeSync', '$timeout', 'shiftEvents', 'ShiftsTableRenderer', 'MatDialogDowngrade', 'scheduleTable', 'scheduleMode', '$scope', function(WebsocketDowngrade: WebsocketService, $element, ComplexTooltip, BackOfficeSync, $timeout, shiftEvents, ShiftsTableRenderer, MatDialogDowngrade: MatDialog, scheduleTable, scheduleMode, $scope) {
        // @ts-ignore
        const ctrl = this;
        const destroyRef = new AngularJsDestroyRef();

        ctrl.$postLink = () => {
            $timeout(() => {
                BackOfficeSync.subscribe($scope, ctrl.scheduleView.recalcTopStats);
                ctrl.registerShiftEvents();

                WebsocketDowngrade.listenSchedule(ctrl.schedule.customer_id, ctrl.schedule.id, 'warning_added', debounce(ctrl.websocketWarningAdded, 100), destroyRef);
                WebsocketDowngrade.listenSchedule(ctrl.schedule.customer_id, ctrl.schedule.id, 'warnings_cleared', debounce(ctrl.websocketWarningAdded, 100), destroyRef);

                // Render elements we need, times and new shift line
                scheduleTable.render();
                // Handle two-way scrolling
                scheduleTable.addScrollingListeners();
                ctrl.addEventListeners();
                // Render when stuff happens
                shiftEvents.register.onLoadProgress($scope, ctrl.render);
                shiftEvents.register.onLoaded($scope, ctrl.render);
                $scope.$on('schedule:unit_order:changed', ctrl.render);
                // Go to today's day
                ctrl.goToCurrentDate();
            });
        };
        ctrl.$onDestroy = () => {
            ctrl.observer?.disconnect();
            destroyRef.destroy();
        };
        /**
         * Handle dynamic elements
         */
        ctrl.addEventListeners = () => {
            const observer = new MutationObserver(() => {
                Array.from($element[0].getElementsByClassName('shift-line')).forEach((el: any) => {
                    // We always want to show the tooltip
                    el.addEventListener('mouseenter', (ev: any) => {
                        const timeout = setTimeout(() => {
                            ctrl.shiftLineEnter(ev);
                        }, 500);

                        el.addEventListener('mouseleave', () => {
                            clearTimeout(timeout);
                        }, { once: true });
                    });

                    // No more events if original
                    if (ctrl.scheduleView.schedule.original) {
                        return;
                    }
                    el.addEventListener('mousedown', ctrl.mousedown);
                    el.addEventListener('click', ctrl.shiftClick);
                });
            });
            observer.observe($element[0], {
                subtree: true,
                childList: true,
            });
            ctrl.observer = observer;
        };
        ctrl.websocketWarningsCleared = (data: any) => {
            $element[0].querySelector(`[data-shift-id="${data.object_id}"]`)?.querySelector('.warning-indicator')?.remove();
        };
        ctrl.websocketWarningAdded = (data: any) => {
            const shiftElement = $element[0].querySelector(`[data-shift-id="${data.object_id}"]`);
            if (shiftElement) {
                const shift = ctrl.schedule.getShift(data.object_id);
                const indicator = shiftElement.querySelector('.warning-indicator');
                if (indicator) {
                    const warnEl = document.createElement('div');
                    warnEl.innerHTML = ShiftsTableRenderer.getWarningHtml(shift);
                    indicator.replaceWith(warnEl.children[0]);
                }
            }
        };
        ctrl.registerShiftEvents = () => {
            shiftEvents.register.onDoing($scope, () => {
                ctrl.render();
            });
            shiftEvents.register.onChange($scope, (_: any, shift: any) => {
                ctrl.schedule.getShift(shift.id)?.removeStatus();
                ctrl.render(true);
            });
            shiftEvents.register.onPeriodsChange($scope, async (_: any, shiftId: number) => {
                await ctrl.schedule.getShift(shiftId)?.refresh();
                ctrl.render(true);
            });
        };

        ctrl.render = debounce(() => {
            scheduleTable.renderShifts();
        }, 100);

        ctrl.goToCurrentDate = () => {
            if (ctrl.schedule.is_template) {
                return;
            }
            const now = moment();
            if (now.isBetween(ctrl.schedule.from, ctrl.schedule.to, null, '[]')) {
                scheduleTable.goToDay(now);
            } else {
                scheduleTable.goToDay(scheduleTable.getOpensAt(ctrl.schedule.from));
            }
        };
        ctrl.$onDestroy = () => {
            scheduleTable.removeScrollingListeners();
        };
        ctrl.shiftLineEnter = (ev: any) => {
            const el = ev.target.closest('.shift-line');
            const shift = ctrl.schedule.getShift(el.dataset.shiftId);
            // Make sure we have a shift
            if (!shift) {
                return;
            }
            // Some checks before opening
            const inContent = document.getElementById('main-content')?.contains(ev.relatedTarget);
            const notDummy = !shift.isDummy();
            const noStatus = !shift.status;
            // Don't open if not all checks are ok
            if (![ inContent, notDummy, noStatus ].every((e) => e)) {
                return;
            }
            ComplexTooltip(ev, el, {
                controller: 'shiftTooltipCtrl',
                template: shiftTooltipTemplate,
                panelClasses: [ 'shift-tooltip' ],
                locals: {
                    shift,
                },
            });
        };
        ctrl.mousedown = (event: any) => {
            ctrl.shiftLineMouseDownTimestamp = event.timeStamp;
        };
        ctrl.shiftClick = (event: any) => {
            // If mouse was pressed for more than X ms
            if (event.timeStamp - ctrl.shiftLineMouseDownTimestamp > 200) {
                return;
            }
            const currentTarget = event.currentTarget;
            const shift = ctrl.schedule.getShift(parseInt(currentTarget.dataset.shiftId));
            // Don't open if no shifts were found or if it's a dummy shift or if it has a status
            if (!shift || shift.status || shift.isDummy()) {
                return;
            }

            const dropId = scheduleViewDragAndDrop()?.id;
            const dropType = scheduleViewDragAndDrop()?.type;

            if (dropId && dropType) {
                const dragEmployee = dropType === 'employee' ? ctrl.schedule.getEmployees().find((e: any) => e.id === dropId) : undefined;
                const dragUnit = dropType === 'unit' ? scheduleViewDragAndDrop()?.data : undefined;
                if (dragEmployee) {
                    shift.employee = dragEmployee;
                    shift.employee_id = dragEmployee.id;
                    ctrl.schedule.updateShift(shift);
                }
                if (dragUnit) {
                    ctrl.scheduleView.onDropUnit(shift);
                }
                return;
            }
            switch (scheduleMode.getMode()) {
                case 'select':
                case 'dragPeriods':
                    const bdMom = shift.business_date as Moment;
                    const dateMom = shift.from as Moment;
                    const date = DateTime.fromObject({
                        year: dateMom.year(),
                        month: dateMom.month() + 1,
                        day: dateMom.date(),
                    });

                    const employee = shift.employee ? new Employee({
                        id: shift.employee.id,
                        customer_id: shift.employee.customer_id,
                        number: shift.employee.number,
                        name: shift.employee.name,
                        first_name: shift.employee.first_name,
                        last_name: shift.employee.last_name,
                        user_id: shift.employee.user_id,
                    }) : undefined;

                    MatDialogDowngrade.open<ScheduleMiniShiftDialogComponentOld, ScheduleMiniShiftDialogData>(ScheduleMiniShiftDialogComponentOld, {
                        backdropClass: 'transparent-backdrop',
                        data: {
                            customerId: CurrentOld.getCustomer()['id'],
                            scheduleId: shift.schedule_id,
                            shiftId: shift.id,
                            employeeId: shift.employee_id,
                            employee,
                            isTemplate: false,
                            date,
                            businessDate: new BusinessDate(bdMom.format('YYYY-MM-DD')),
                        },
                    });
                    break;
            }
        };
    } ],
});
