<h1 mat-dialog-title>{{data.title() | async}}</h1>

<mat-dialog-content class="tw-whitespace-pre-line">{{data.text() | async}}</mat-dialog-content>

<mat-dialog-actions>
    <eaw-action-button [loading]="data.loading?.() || false" [disabled]="data.buttonDisabled?.() || false" [mat-dialog-close]="undefined">
        @if (data.buttonText) {
            <span>{{data.buttonText() | async}}</span>
        } @else {
            <span>{{'OK' | translate | async}}</span>
        }
    </eaw-action-button>
</mat-dialog-actions>
