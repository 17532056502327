/**
 * @see modules/todo/Models/Status.php
 *
 * In the API project
 */
export enum TodoStatusType {
    Done = 'done',
    InProgress = 'in_progress',
    Initial = 'initial',
    Default = 'default',
}
