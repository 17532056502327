// @ts-nocheck
import { module } from 'angular';
module('eaw.misc').factory('ContractTypeFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const resource = EawResource.create('/setting_groups/:setting_group_id/contract_types/:contract_type_id');
    const customerResource = EawResource.create('/customers/:customer_id/contract_types/:contract_type_id');
    return {
        getAll(obj) {
            return resource.get({
                setting_group_id: obj.setting_group ? obj.setting_group.id : obj.setting_group_id,
                ...Pagination.getParams(obj),
                'with[]': 'settingGroup',
            });
        },
        create(obj) {
            return resource.save({
                setting_group_id: obj.setting_group ? obj.setting_group.id : obj.setting_group_id,
            }, {
                name: obj.name,
                from: obj.from,
                to: obj.to,
            });
        },
        update(obj) {
            return resource.update({
                setting_group_id: obj.setting_group ? obj.setting_group.id : obj.setting_group_id,
                contract_type_id: obj.contract_type.id,
            }, {
                name: obj.name,
                from: obj.from,
                to: obj.to,
            });
        },
        delete(obj) {
            return resource.delete({
                setting_group_id: obj.setting_group ? obj.setting_group.id : obj.setting_group_id,
                contract_type_id: obj.contract_type.id,
            });
        },
        getAllForCustomer(obj) {
            return customerResource.get({
                customer_id: obj.customer_id,
                ...Pagination.getParams(obj),
            });
        },
    };
} ]);
