import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Metric, MetricResponse } from '../app/people-kpi/models/metric';
import { map, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MetricServiceMock {
    mockValue: Metric[];

    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {
        this.mockValue = [ { 'key': 'average_headcount', 'name': { 'ns': 'people_kpi', 'key': 'AVERAGE_HEADCOUNT', 'data': [] }, 'prefix': '', 'suffix': '' }, { 'key': 'headcount', 'name': { 'ns': 'people_kpi', 'key': 'HEADCOUNT', 'data': [] }, 'prefix': '', 'suffix': '' }, { 'key': 'leavers', 'name': { 'ns': 'people_kpi', 'key': 'LEAVERS', 'data': [] }, 'prefix': '', 'suffix': '' }, { 'key': 'starters', 'name': { 'ns': 'people_kpi', 'key': 'STARTERS', 'data': [] }, 'prefix': '', 'suffix': '' }, { 'key': 'turnover', 'name': { 'ns': 'people_kpi', 'key': 'TURNOVER', 'data': [] }, 'prefix': '', 'suffix': '%' }, { 'key': '90_day_turnover', 'name': { 'ns': 'people_kpi', 'key': 'TURNOVER_90_DAYS', 'data': [] }, 'prefix': '', 'suffix': '%' } ];
    }

    getAll() {
        return this.http.get<MetricResponse[]>('/pkpi_metrics').pipe(map((resp) => resp.map((r) => new Metric(r))));
    };

    getForCustomer() {
        return of(this.mockValue);
    };

    getForOrganization() {
        return of(this.mockValue);
    }

    addToOrganization(_: string, body: Metric) {
        this.mockValue.push(body);
        return this.mockValue;
    };

    delete(_: string, metricId: string) {
        this.mockValue.forEach((m, i) => {
            if (m.key === metricId) {
                this.mockValue.splice(i, 1);
            }
        });
        return this.mockValue;
    };
}
