<eaw-page-header [useBigFilter]="true">
    <span title>{{'STAFFING_FORECAST' | translate:'analytics' | async}}</span>

    <div bigFilter>
        <form [formGroup]="form" class="tw-flex tw-flex-row tw-gap-16">
            <mat-form-field>
                <mat-label>{{'INTERVAL' | translate | async}}</mat-label>
                <mat-select formControlName="granularity" required (selectionChange)="granularityChange()">
                    <mat-option *ngFor="let granularity of granularities" [value]="granularity">{{granularity.translation | async}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field eawDatePickerOptions>
                <mat-label>{{'ENTER_DATE_RANGE' | translate | async}}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="$any(picker)" [dateFilter]="dateFilter">
                    <input formControlName="from" matStartDate [placeholder]="'START_DATE' | translate | async">
                    <input formControlName="to" matEndDate [placeholder]="'END_DATE' | translate | async">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </form>

        <div class="tw-flex tw-flex-row tw-gap-8">
            <button mat-raised-button color="primary" (click)="update()" [disabled]="form.pristine ||  form.invalid || loading">{{'UPDATE' | translate | async}}</button>
        </div>
    </div>
</eaw-page-header>

<mat-card id="transactions-card" (click)="transactionsInput.focus()">
    <mat-card-content>
        <mat-icon>edit</mat-icon>
        <input type="number" min="1" max="99" step="0.25" [value]="defaultEmployeeTransactions" [placeholder]="defaultEmployeeTransactions" #transactionsInput>
        <span class="mat-subtitle-2">{{'EMP_TRANSACTIONS_HOUR' | translate:'analytics' | async}}</span>
    </mat-card-content>
</mat-card>

<div id="loading-box" *ngIf="loading">
    <mat-progress-spinner mode="indeterminate" diameter="50" strokeWidth="5"></mat-progress-spinner>
</div>

<mat-card id="columns-card" *ngIf="!loading">
    <mat-card-content class="tw-p-0">
        <div class="left">
            <span class="row label"></span>
            <span class="input row label">{{'TRANSACTION_plural' | translate | async}}</span>
            <span class="estimated-need row label">{{'ESTIMATED_NEED' | translate:'analytics' | async}}</span>
            <span class="contract-hours row label">{{'CONTRACT_HOUR_plural' | translate:'analytics' | async}}</span>
            <span class="absences row label">{{'ABSENCE_plural' | translate | async}}</span>
            <span class="available row label">{{'AVAILABLE' | translate:'analytics' | async}}</span>
            <span class="diff row label">{{'DIFFERENCE' | translate | async}}</span>
            <span class="scroll row label"></span>
        </div>

        <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="125" id="columns-viewport">
            <div class="column" *cdkVirtualFor="let item of items; let index = index">
                <strong class="label row cell">{{item.label}}</strong>
                <div class="input row cell">
                    <input matInput [ngModel]="item.projections" type="number" step="1" min="0" (ngModelChange)="change($event, index)">
                </div>
                <span class="estimated-need row cell">{{item.estimatedNeed | eawNumber}}</span>
                <span class="contract-hours row cell">{{item.contractHours | eawNumber}}</span>
                <span class="absences row cell">{{item.absences | eawNumber}}</span>
                <span class="available row cell">{{item.available | eawNumber}}</span>
                <span class="diff row cell" [class.positive]="item.diff >= 0" [class.negative]="item.diff < 0">{{item.diff | eawNumber}}</span>
                <span class="scroll row cell"></span>
            </div>
        </cdk-virtual-scroll-viewport>

        <div class="right">
            <span class="row label"></span>
            <span class="row label">{{totals.projections | eawNumber:0}}</span>
            <span class="row label">{{totals.estimatedNeed | eawNumber:2}}</span>
            <span class="row label">{{totals.contractHours | eawNumber:2}}</span>
            <span class="row label">{{totals.absences | eawNumber:2}}</span>
            <span class="row label">{{totals.available | eawNumber:2}}</span>
            <span class="row label diff" [class.positive]="totals.diff >= 0">{{totals.diff | eawNumber:2}}</span>
            <span class="row label"></span>
        </div>
    </mat-card-content>
</mat-card>
