import { Resolvable } from '../types/resolvable';
import { Transition } from '@uirouter/angularjs';

/**
 * Resolver that uses Angular-ish way of resolving where you use the transition to get the injector to get whatever you want
 * @param token
 * @param resolveFn
 * @constructor
 */
export function resolver(token: string, resolveFn: ($transition$: Transition) => Promise<any>): Resolvable {
    return {
        token,
        deps: [ '$transition$' ],
        resolveFn,
    };
}
