import { EMPTY, Observable } from 'rxjs';
import { PaginationOptions } from '../../interfaces/pagination-options';
import { PaginatedResponse } from '../../interfaces/paginated-response';
import { expand } from 'rxjs/operators';

export type ExpandPagesCallback<Return, Pagination extends PaginationOptions, Response extends PaginatedResponse<Return>> = (pagination: Pagination) => Observable<Response>;

export function expandPages<Return, Pagination extends PaginationOptions, Response extends PaginatedResponse<Return>>(callback: ExpandPagesCallback<Return, Pagination, Response>, pagination?: Pagination, concurrent = 4) {
    const pages: number[] = [];
    const firstPage = pagination?.page || 1;
    const defaultPagination = { page: firstPage } as Pagination;

    return callback(pagination ?? defaultPagination).pipe(
        expand((response, index) => {
            const page: number = firstPage + index;

            if (response.last_page == null) {
                throw new Error('Invalid Response, expandPages can only be used for endpoints that return a paginated response');
            }

            if (pages.indexOf(page) > 0 || response.last_page <= page) {
                return EMPTY;
            }

            pages.push(page);
            return callback({
                ...pagination,
                page: page + 1,
            } as Pagination);
        }, concurrent),
    );
}
