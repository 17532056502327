<eaw-dialog-header>
    <span title>
        @if (user) {
            {{ 'GRANT_USER_ACCESS' | translate: 'company' | async }}
        } @else {
            {{ 'ADD_USER' | translate: 'company' | async }}
        }
    </span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-12">
    <form [formGroup]="form" class="tw-grid tw-grid-cols-4 tw-gap-12">
        @if (searching) {
            <mat-progress-spinner class="tw-col-span-full tw-items-center tw-justify-center" mode="indeterminate"></mat-progress-spinner>
        } @else {
            <mat-form-field formGroupName="user" class="tw-col-span-full">
                <mat-label>{{ 'EMAIL' | translate | async }}</mat-label>
                <input matInput type="email" formControlName="email" (keydown.enter)="searchForUser()">
            </mat-form-field>

            @if (!searched && !user) {
                <eaw-action-button [loading]="searching" [disabled]="!form.controls.user.controls.email.valid" (click)="searchForUser()">
                    <span>{{ 'SEARCH_EXISTING_USER' | translate:'company' | async }}</span>
                </eaw-action-button>
            }
        }

        @if (searched || user) {
            <div formGroupName="user" class="tw-grid tw-gap-12 tw-grid-cols-2 tw-col-span-full">
                <mat-form-field class="tw-col-span-2">
                    <mat-label>{{ 'FIRST_NAME' | translate | async }}</mat-label>
                    <input matInput type="text" formControlName="firstName">
                </mat-form-field>

                <mat-form-field class="tw-col-span-2">
                    <mat-label>{{ 'LAST_NAME' | translate | async }}</mat-label>
                    <input matInput type="text" formControlName="lastName">
                </mat-form-field>

                <mat-form-field class="tw-col-span-2">
                    <mat-label>{{ 'PHONE' | translate | async }}</mat-label>
                    <eaw-dial-phone-input formControlName="phone"></eaw-dial-phone-input>
                    <mat-error><eaw-field-error [control]="form.controls.user.controls.phone"></eaw-field-error></mat-error>
                </mat-form-field>

                <eaw-language-autocomplete class="tw-col-span-full" controlName="language"></eaw-language-autocomplete>
            </div>

            <mat-form-field class="tw-col-span-full">
                <mat-label>{{ 'USER_GROUP_plural' | translate | async }}</mat-label>
                <mat-select multiple formControlName="userGroups">
                    <div class="mat-mdc-option">
                        <input class="mat-mdc-form-field-input-control"
                               [placeholder]="('SEARCH' | translate | async) || ''"
                               autofocus
                               autocomplete="off"
                               type="text"
                               formControlName="userGroupSearch">
                    </div>
                    @for (group of filteredGroups | async; track group.id) {
                        <mat-option [value]="group" [disabled]="group.isDisabled">
                            <span>{{ group.name }}</span>
                            @if (group.isDisabled) {
                                <small> - {{'GROUP_NO_PERMISSION_TO_ADD' | translate: 'permissions' | async}}</small>
                            }
                        </mat-option>
                    }
                </mat-select>
                @if (!userGroups.length) {
                    <mat-progress-spinner matSuffix strokeWidth="3" diameter="24" mode="indeterminate"></mat-progress-spinner>
                }
            </mat-form-field>

            <div class="tw-mb-16 tw-flex tw-flex-col tw-col-span-full tw-gap-16">
                <mat-slide-toggle formControlName="customStartToggle">{{ 'SET_START_DATE' | translate: 'company' | async }}</mat-slide-toggle>
                <mat-slide-toggle formControlName="temporaryAccessToggle">{{ 'TEMPORARY_ACCESS' | translate: 'company' | async }}</mat-slide-toggle>
            </div>

            <eaw-date-time-range-input class="tw-col-span-full tw-grid tw-grid-cols-1 tw-gap-8">
                <mat-form-field eawDateTimeFrom [class.tw-hidden]="!form.controls.customStartToggle.value">
                    <mat-label>{{ 'FROM' | translate | async }}</mat-label>
                    <eaw-date-time-input [minDate]="minDate" [maxDate]="form.controls.to.value || undefined" formControlName="from"/>
                </mat-form-field>

                <mat-form-field eawDateTimeTo [class.tw-hidden]="!form.controls.temporaryAccessToggle.value">
                    <mat-label>{{ 'TO' | translate | async }}</mat-label>
                    <eaw-date-time-input [minDate]="form.controls.from.value || minDate" formControlName="to"/>
                </mat-form-field>
            </eaw-date-time-range-input>
        }
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">{{ 'CANCEL' | translate | async }}</button>

    <eaw-action-button [disabled]="!form.valid" [loading]="processing" (click)="submit()">
        {{ 'GRANT_ACCESS' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
