<eaw-page-header [fabButton]="fabButton" [useBigFilter]="true" [bigFilterToggled]="false" [buttons]="headerButtons">
    <span title>
        <span>{{'SCHEDULE_plural' | translate:'scheduling' | async}}</span>

        @if (usingBetaSchedule()) {
            <span class="tw-ml-8">({{ 'BETA' | translate | async }})</span>
        }
    </span>

    <div subtitle eawPermission [permissions]="['schedule_beta']">
        @if (usingBetaSchedule()) {
            <button mat-raised-button (click)="usingBetaSchedule.set(false)">👎 Use old schedule</button>
        } @else {
            <button mat-raised-button (click)="usingBetaSchedule.set(true)">⭐ Use new schedule</button>
        }
    </div>

    <div bigFilter>
        <form [formGroup]="formGroup" class="tw-grid tw-grid-cols-2 sm:tw-grid-cols-4 tw-gap-8">
            <mat-form-field class="tw-col-[1_/_2]">
                <mat-label>{{'FILTER' | translate | async}}</mat-label>
                <input formControlName="filter" matInput autocomplete="off">
            </mat-form-field>

            <mat-form-field eawDatePickerOptions class="tw-col-[2_/_3]">
                <mat-label>{{'ENTER_DATE_RANGE' | translate | async}}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
                    <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async" autocomplete="off">
                    <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async" autocomplete="off">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </form>
    </div>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        <eaw-data-table
            [columns]="columns()"
            [getData]="getData()"
            [goTo]="goTo()"
            initialSortBy="from"
            initialSortDirection="desc">
        </eaw-data-table>
    </mat-card-content>
</mat-card>

