import { module } from 'angular';
import { SignDocumentServiceComponent } from '../../pages/sign-document-service/sign-document-service.component';
import { dependencyResolver } from '../../../shared/resolvers/dependency.resolver';
import { currentResolver } from '../../../shared/resolvers/current.resolver';
import { StateProvider, Transition } from '@uirouter/angularjs';

module('eaw.hr.sign', [ 'eaw.login', 'eaw.digisign' ])
    .config([ '$stateProvider', ($stateProvider: StateProvider) => {
        $stateProvider.state(`eaw/app/hr/my_files/file/sign`, {
            parent: `eaw/app/hr/my_files`,
            url: '/sign/:file_id',
            abstract: true,
            data: {
                breadcrumb: { key: 'SIGN' },
            },
            resolve: {
                /** @ngInject */
                file: [ '$transition$', 'HrFactory', 'HrFile', 'customer', 'hrEmp', function($transition$: Transition, HrFactory: any, HrFile: any, customer: any, hrEmp: any) {
                    return HrFactory.getEmployeeFile(customer.id, hrEmp.id, $transition$.params()['file_id'], { with: [ 'type' ] }).$promise.then((response: any) => new HrFile(response, customer));
                } ],
            },
        });

        $stateProvider.state(`eaw/app/hr/my_files/file/sign/checkbox`, {
            parent: `eaw/app/hr/my_files/file/sign`,
            url: '/checkbox',
            views: {
                'content@': 'hrSignCheckbox',
            },
            resolve: {
                /** @ngInject */
                htmlForm: [ 'file', 'HrFactory', function(file: any, HrFactory: any) {
                    if (file.has_form_fields) {
                        return HrFactory.getRaw(file.employee.customer_id, file.employee.id, file.id).then((resp: any) => resp.data);
                    }
                } ],
            },
        });

        $stateProvider.state(`eaw/app/hr/my_files/file/sign/document-service`, {
            parent: `eaw/app/hr/my_files/file/sign`,
            url: '/document-service',
            views: {
                'content@': {
                    component: SignDocumentServiceComponent,
                },
            },
            resolve: [
                dependencyResolver('redirectUrl', [ '$window'], function($window) {
                    const ind = $window.location.href.indexOf('sign');
                    if (ind < 0) {
                        return $window.location.href;
                    }

                    return $window.location.href.substr(0, ind - 1);

                }),
                currentResolver('customerId', 'customer', 'id'),
            ],
        });
    } ]);
