// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog id="template-date-picker-dialog">
    <eaw-dialog-header-old heading="'scheduling:SELECT_DAY' | i18next"></eaw-dialog-header-old>

    <md-dialog-content>
        <md-tabs md-dynamic-height>
            <md-tab label="{{tdpCtrl.weekString($index + 1)}}" ng-repeat="week in ::tdpCtrl.weeks track by $index">
                <md-tabs md-dynamic-height>
                    <md-tab label="{{tdpCtrl.dayString($parent.$index+1, $index+1)}}" ng-repeat="day in ::tdpCtrl.days track by $index">

                        <md-content class="md-padding text-center">
                            <md-button ng-click="tdpCtrl.goToDay($parent.$index+1, $index+1)" class="md-accent md-raised tw-m-24">{{tdpCtrl.goToString($parent.$parent.$index+1, $index+1)}}</md-button>
                        </md-content>

                    </md-tab>
                </md-tabs>
            </md-tab>
        </md-tabs>

    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.scheduling').factory('templateDatePicker', [ '$mdDialog', function($mdDialog) {
    return {
        open() {
            return $mdDialog.show({
                template: template1,
                controller: 'templateDatePickerCtrl',
                controllerAs: 'tdpCtrl',
            });
        },
        close(cancel) {
            return cancel ? $mdDialog.hide() : $mdDialog.cancel();
        },
    };
} ]);
