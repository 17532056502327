import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { Comment } from '../../models/comment';
import { Observable, of, Subscription, tap } from 'rxjs';
import { SnackBarService } from '../../services/snack-bar.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { CommentListComponent } from '../../components/comment-list/comment-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { InfoLoadingComponent } from '../../components/info-loading/info-loading.component';

export interface CommentDialogData extends DialogData {
    comments?: Observable<Comment[]>,
    title?: Promise<string>,
    canDelete?: (id: number) => Observable<boolean>,
    saveCallback?: (comment: string) => Observable<Comment>,
    deleteCallback?: (id: number) => Observable<any>,
    hideComments?: boolean;
}

@Component({
    selector: 'eaw-comment-dialog',
    templateUrl: './comment-dialog.component.html',
    styleUrl: './comment-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        CommentListComponent,
        AsyncPipe,
        TranslatePipe,
        InfoLoadingComponent,
    ],
})
export class CommentDialogComponent extends DialogComponent<CommentDialogData> implements OnInit, OnDestroy {
    loading = false;
    saving = false;
    newComment = '';
    comments: Comment[] = [];
    title?: Observable<string>;
    private subscription?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CommentDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<CommentDialogComponent>,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
    ) {
        super(undefined, undefined, DialogSize.Medium);
    }

    ngOnInit() {
        this.loading = this.data.comments != null;

        (this.data.hideComments ? of([]) : this.data.comments)?.subscribe((response) => {
            this.setComments([ ...response ]);
            this.comments = [ ...response ];
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    /**
     * Sets comments so that the newest comments are first
     */
    setComments(comments: Comment[]) {
        this.comments = comments.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());
    }

    saveComment(newComment: string) {
        this.saving = true;
        void this.snackBarService.t('SAVING_COMMENT');

        this.subscription = this.data.saveCallback?.(newComment).pipe(
            tap((comment) => {
                this.saving = false;
                this.newComment = '';
                this.setComments([ comment, ...this.comments ]);
            }),
        ).subscribe(() => void this.snackBarService.t('COMMENT_ADDED'));
    }
}
