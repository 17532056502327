// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { EawUrl } from '../../../shared/angularjs/modules/resource/url.service';
import { Mobile } from '../../../shared/utils/eaw-mobile';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.dialogs').controller('fileDownloadDialogCtrl', [ '$mdDialog', 'FileService', function($mdDialog, FileService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.cancel = $mdDialog.cancel;
        ctrl.isMobile = Mobile.isMobile;
        ctrl.gettingUrl = true;
        ctrl.truncatedName = ctrl.file.name;
        ctrl.statusText = t('filesystem:PREPARING');
        FileService.downloadUrl(`${EawUrl.url}/customers/${CurrentOld.customer.id}/fs_files/${ctrl.file.id}/download`).then((url) => {
            ctrl.gettingUrl = false;
            ctrl.url = url;
            ctrl.statusText = t('DOWNLOAD');
        }).catch($mdDialog.cancel);
    };

    ctrl.download = () => {
        Mobile.openBrowser(ctrl.url);
    };
} ]);
