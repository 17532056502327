import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { Report } from '../../../reports/models/report';
import { DataTableComponent } from 'src/app/data-table/data-table.component';
import { DataTableColumnType } from 'src/app/data-table/interfaces/data-table-columns';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';
import { ReportService } from '../../../reports/http/report.service';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, Observable, of, switchMap, tap } from 'rxjs';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { Customer } from '../../../shared/models/customer';
import { CustomerService } from '../../../shared/http/customer.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { AddReportDialogComponent, AddReportDialogData } from '../../dialogs/add-report-dialog/add-report-dialog.component';
import { Namespace } from '../../../shared/enums/namespace';
import { TranslateService } from '../../../shared/services/translate.service';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-customer-report-list',
    standalone: true,
    imports: [
        MatCard,
        PageHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        DataTableComponent,
    ],
    templateUrl: './customer-report-list.component.html',
    styleUrl: './customer-report-list.component.scss',
})
export class CustomerReportListComponent implements EawDataTable<Report>, OnInit {
    private readonly reportService = inject(ReportService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly customerService = inject(CustomerService);
    private readonly confirm = inject(ConfirmDialogService);
    private readonly snackBar = inject(SnackBarService);
    private readonly matDialog = inject(MatDialog);
    private readonly translate = inject(TranslateService);

    protected customerObservable?: Observable<Customer>;
    // From ui-router, so it can't be a signal

    @ViewChild(DataTableComponent) dataTable?: DataTableComponent<Report> | undefined;

    @Input({ required: true }) customerId!: number;
    columns: DataTableColumnType<Report>[];
    request: DataTableRequest<Report> = of(mockArrayPaginatedResponse<Report>([]));
    fabButton?: HeaderFabButton;

    constructor() {
        this.columns = [
            new DataTableTextColumn({
                key: 'id',
                header: new DataTableHeader({
                    'i18n': 'ID',
                    sortBy: 'id',
                }),
                value: 'id',
            }),
            new DataTableTextColumn({
                key: 'name',
                header: new DataTableHeader({
                    'i18n': 'NAME',
                }),
                value: 'name',
            }),
            new DataTableTextColumn({
                key: 'description',
                header: new DataTableHeader({
                    'i18n': 'DESCRIPTION',
                }),
                value: 'description',
            }),
            new DataTableTextColumn({
                key: 'class',
                header: new DataTableHeader({
                    'i18n': 'CLASS',
                    sortBy: 'class',
                }),
                value: 'class',
            }),
            new DataTableButtonColumn({
                buttons: [ {
                    type: 'warn',
                    icon: 'delete',
                    click: this.deleteReport.bind(this),
                    show: this.showDelete.bind(this),
                    tooltip: {
                        key: 'DELETE',
                    },
                } ],
            }),
        ];
    }

    ngOnInit() {
        this.fabButton = {
            hasPermission: () => of(true),
            icon: 'add',
            permissionInputs: {
                permissions: [
                    [ `customers.[${ApiModel.Customer}].reports.create`, { models: [ { id: this.customerId, type: ApiModel.Customer } ] } ],
                ],
            },
            tooltip: Promise.resolve(''),
            type: undefined,
            click: this.addReport.bind(this),
        };
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.reportService.getAll(this.customerId, pagination);
    }

    protected showDelete(item: Report) {
        return this.getCustomer().pipe(switchMap((customer) => {
            return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].reports.[${item.id}]delete`, {
                stackId: customer.stackId,
                models: [
                    { id: this.customerId, type: ApiModel.Customer },
                    { id: item.id, type: ApiModelClass.Report },
                ],
            });
        }));
    }

    protected getCustomer() {
        if (!this.customerObservable) {
            this.customerObservable = this.customerService.get(this.customerId);
        }

        return this.customerObservable;
    }

    protected async deleteReport(cell: DataTableButtonCell<Report>) {
        this.confirm.delete({
            title: this.translate.t('DELETE_REPORT', Namespace.Admin),
            text: this.translate.t('DELETE_REPORT_TEXT', Namespace.Admin, { name: cell.item.name }), // name is translated in the API
            confirmText: this.translate.t('REMOVE'),
        })
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (!result?.ok) {
                        cell.disabled.set(false);
                        return EMPTY;
                    }

                    return this.reportService.delete(this.customerId, cell.item.id);
                }),
                tap(() => {
                    this.snackBar.deleted();
                    this.dataTable?.refresh();
                })).subscribe();
    }

    protected addReport() {
        this.matDialog.open<AddReportDialogComponent, AddReportDialogData, Report>(AddReportDialogComponent, {
            data: {
                customerId: this.customerId,
            },
        })
            .afterClosed()
            .pipe(tap((result) => {
                if (result) {
                    this.dataTable?.refresh();
                }
            }))
            .subscribe();
    }
}
