import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { User, UserResponse } from '../../shared/models/user';
import { Report } from './report';
import { ApiResponse } from '../../shared/interfaces/api-response';

type ReportRunFormat = keyof Report['formats'];

export interface ReportRunResponse extends ApiResponse {
    id: number;
    report_id: number;
    format: ReportRunFormat;
    from: string;
    to: string;
    name: string;
    options: Record<string, any>;
    user_id: number;
    ready_at?: string | null;
    created_at: string;
    user?: UserResponse;
}

export class ReportRun {
    readonly _response: Readonly<ReportRunResponse & { [p: string]: any }>;

    id: number;
    reportId: number;
    format: ReportRunFormat;
    from: DateTime;
    to: DateTime;
    name: string;
    options: Record<string, any>;
    userId: number;
    readyAt?: DateTime;
    createdAt: DateTime;
    user?: User;

    constructor(data: ReportRunResponse) {
        this._response = Object.freeze(data);
        this.id = data.id;
        this.reportId = data.report_id;
        this.format = data.format;
        this.from = stringToDateTime(data.from);
        this.to = stringToDateTime(data.to);
        this.name = data.name;
        this.options = data.options;
        this.userId = data.user_id;
        this.readyAt = data.ready_at ? stringToDateTime(data.ready_at) : undefined;
        this.createdAt = stringToDateTime(data.created_at);
        this.user = data.user ? new User(data.user) : undefined;
    }
}
