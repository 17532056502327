<eaw-page-header>
    <span title>{{ 'SIGN_A_FILE' | translate:'digisign' | async }}</span>
</eaw-page-header>
@if (file) {
    <mat-card id="stats-card">
        <mat-card-content class="tw-flex tw-flex-col tw-justify-between tw-items-start">
            <table class="table table-vmiddle">
                <tbody>
                <tr>
                    <td>{{ 'NAME' | translate | async }}</td>
                    <td>{{ file.name }}</td>
                </tr>
                    @if (file.type) {
                        <tr>
                            <td>{{ 'FILETYPE' | translate: 'hr' | async }}</td>
                            <td>{{ file.type.name }}</td>
                        </tr>
                    }
                <tr>
                    <td>{{ 'DOWNLOAD' | translate | async }}</td>
                    <td>
                        <button mat-icon-button id="download" (click)="download()">
                            <mat-icon>download</mat-icon>
                        </button>
                    </td>
                </tr>
                    @if (htmlForm && !formFieldsSubmitted) {
                        <tr>
                            <td colspan="2">
                                <eaw-show-html-form (formFilled)="onFormFilled($event)"
                                                    (formSubmitted)="submitFormFieldsAndSign()" [html]="htmlForm"/>
                                <eaw-action-button [loading]="submittingFormFields" [disabled]="!formData"
                                                   (click)="submitFormFieldsAndSign()">
                                    {{ 'SIGN' | translate | async }}
                                </eaw-action-button>
                            </td>
                        </tr>
                    } @else {
                        <tr>
                            <td colspan="2">
                                <eaw-action-button [loading]="signing" (click)="sign()">{{ 'SIGN' | translate | async }}
                                </eaw-action-button>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
}
