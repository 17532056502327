import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MuxHandlerDirective } from '../../directives/mux-handler.directive';
import { CurrentService } from '../../../shared/services/current.service';
import { UserPropertyService } from '../../../shared/http/user-property.service';
import { EasyAtWorkLearningModule } from 'tutorial-lib';
import { Mobile } from '../../../shared/utils/eaw-mobile';

@Component({
    selector: 'eaw-welcome-dialog',
    standalone: true,
    imports: [ CommonModule, MuxHandlerDirective, EasyAtWorkLearningModule ],
    templateUrl: './welcome-dialog.component.html',
    styleUrl: './welcome-dialog.component.scss',
})
export class WelcomeDialogComponent extends DialogComponent<DialogData, void> {
    private currentService = inject(CurrentService);
    private userPropertyService = inject(UserPropertyService);

    protected userId: string;
    protected language: string;
    protected isMobile = Mobile.isMobile;

    constructor() {
        super(undefined, {
            size: DialogSize.Large,
        });

        this.dialogRef.disableClose = true;
        this.userId = this.currentService.getUser().id.toString();
        this.language = this.currentService.languageTag;
        this.userPropertyService.create(this.currentService.getMe().authedAs, 'received_welcome_popup', '1').subscribe();
    }

    onNoArticles() {
        this.dialogRef.close();
    }
}
