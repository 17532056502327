<eaw-dialog-header [showCloseButton]="!handleShiftComponent()?.creating">
    <span title>{{ 'CREATE_SHIFT_DIALOG_HEADER' | translate: 'scheduling' | async }}</span>
</eaw-dialog-header>

@if (schedule) {
    <mat-dialog-content>
        <eaw-handle-shift
            (shiftCreated)="close($event)"
            [customerId]="data.customerId"
            [schedule]="schedule"
            [withs]="data.withs"
            [qualifications]="[]"
            [employeeId]="data.employeeId"
            [date]="data.date"/>
    </mat-dialog-content>
}


<mat-dialog-actions>
    <button mat-button mat-dialog-close [disabled]="handleShiftComponent()?.creating">{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [loading]="handleShiftComponent()?.creating || false"
                       [disabled]="handleShiftComponent()?.form?.invalid || false"
                       (click)="handleShiftComponent()?.create()">
        {{ 'CREATE_SHIFT' | translate: 'scheduling' | async }}
    </eaw-action-button>
</mat-dialog-actions>
