import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../../../shared/dialogs/dialog-component';
import { DialogHeaderComponent } from '../../../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { AsyncPipe, LowerCasePipe } from '@angular/common';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { MatButton } from '@angular/material/button';
import { ScheduleComponent } from '../../schedule.component';
import { ScheduleService } from '../../../../http/schedule.service';
import { DateTime } from 'luxon';
import { catchError, EMPTY, forkJoin, map, of, startWith } from 'rxjs';
import { Warning } from '../../../../../shared/models/warning';
import { MatDivider } from '@angular/material/divider';
import { MatListItemLine } from '@angular/material/list';
import { Schedule } from '../../../../models/schedule';
import { CheckboxHelperDirective } from '../../../../../shared/directives/checkbox-helper.directive';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { MaterialColorDirective } from '../../../../../shared/directives/material-color.directive';
import { MatIconSizeDirective } from '../../../../../shared/directives/mat-icon-size.directive';
import { TranslateService } from '../../../../../shared/services/translate.service';
import { Namespace } from '../../../../../shared/enums/namespace';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { DateTimeInputComponent } from '../../../../../shared/components/date-time/date-time-input/date-time-input.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { InfoLoadingComponent } from '../../../../../shared/components/info-loading/info-loading.component';
import { PermissionCheckService } from '../../../../../shared/services/permission-check.service';
import { canForcePublishSchedulePermission } from '../../../../permissions';
import { NumberPipe } from '../../../../../shared/pipes/number.pipe';
import { expandAllPages } from '../../../../../shared/utils/rxjs/expand-all-pages';

type PublishOption = 'now' | 'later';

export type PublishScheduleDialogResult = { option: PublishOption, time?: DateTime };

export interface PublishScheduleDialogData extends DialogData {
    stackId: number;
    customerId: number;
    scheduleId: number;
    /**
     * @deprecated - Remove once schedule is upgraded (TODO)
     */
    fetchWarnings?: boolean;
}

interface WarningItem {
    warning: Warning;
    count: number;
}

@Component({
    selector: 'eaw-publish-schedule-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        MatDialogContent,
        ActionButtonComponent,
        MatButton,
        MatDialogActions,
        MatDivider,
        MatListItemLine,
        CheckboxHelperDirective,
        LowerCasePipe,
        MatCheckbox,
        MatDialogClose,
        ReactiveFormsModule,
        MatIcon,
        MaterialColorDirective,
        MatIconSizeDirective,
        MatRadioButton,
        MatRadioGroup,
        DateTimeInputComponent,
        MatFormField,
        MatLabel,
        InfoLoadingComponent,
        NumberPipe,
    ],
    templateUrl: './publish-schedule-dialog.component.html',
    styleUrl: './publish-schedule-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishScheduleDialogComponent extends DialogComponent<PublishScheduleDialogData, PublishScheduleDialogResult> implements OnInit {
    private destroyRef = inject(DestroyRef);
    private scheduleService = inject(ScheduleService);
    private translateService = inject(TranslateService);
    private permissionCheckService = inject(PermissionCheckService);

    protected readonly minDate = signal(DateTime.now());
    warningCheckbox = new FormControl(false, { nonNullable: true });
    publishRadio = new FormControl<PublishOption>('now', { nonNullable: true });
    publishInput = new FormControl<DateTime | null>(null);
    acceptedWarnings = toSignal(this.warningCheckbox.valueChanges);
    warningsAcknowledged = computed(() => this.warnings().length ? this.acceptedWarnings() : true);
    publishNow = toSignal(this.publishRadio.valueChanges.pipe(startWith('now'), map((v) => v === 'now')));
    publishTime = toSignal(this.publishInput.valueChanges);
    publishing = signal(false);
    warnings = computed<WarningItem[]>(this.computeWarnings.bind(this));
    warningsCount = computed(() => this.warnings().reduce((acc, val) => acc + val.count, 0));
    fetchedWarnings = signal<Warning[] | null>(null);
    loading = signal(false);
    canForcePublish = signal(false);
    publishDisabled = computed(this.computePublishDisabled.bind(this));
    publishOptions: { value: PublishOption, label: Promise<string> }[] = [
        { value: 'now', label: this.translateService.t('PUBLISH_NOW', Namespace.Scheduling) },
        { value: 'later', label: this.translateService.t('PUBLISH_LATER', Namespace.Scheduling) },
    ];

    constructor() {
        super(undefined, undefined, DialogSize.Medium);
    }

    ngOnInit() {
        const force = this.permissionCheckService.isAllowed(canForcePublishSchedulePermission(this.data.stackId, this.data.customerId, this.data.scheduleId)).pipe(takeUntilDestroyed(this.destroyRef));
        const getWarnings = expandAllPages(
            (pagination) => this.scheduleService.getWarnings(this.data.customerId, this.data.scheduleId, pagination),
            { page: 1, per_page: 500 },
        ).pipe(takeUntilDestroyed(this.destroyRef));

        this.loading.set(true);
        forkJoin([ force, this.data.fetchWarnings ? getWarnings : of(null) ]).subscribe(([ canForce, warnings ]) => {
            this.canForcePublish.set(canForce);
            this.fetchedWarnings.set(warnings);
            this.loading.set(false);
        });
    }

    private computePublishDisabled() {
        if (this.loading() || this.publishing() || !this.warningsAcknowledged()) {
            return true;
        }

        return this.publishNow() ? false : !this.publishTime()?.isValid;
    }

    private computeWarnings(): WarningItem[] {
        const warnings = this.fetchedWarnings() || Array.from(ScheduleComponent.warnings().values()).reduce<Warning[]>((acc, val) => {
            return acc.concat(Array.from(val.values()));
        }, []);

        const warningCountMap = warnings.reduce((acc, warning) => {
            const key = warning.message + JSON.stringify(warning.messageParameters);
            const existing = acc.get(key);

            if (existing) {
                existing.push(warning);
            } else {
                acc.set(key, [ warning ]);
            }

            return acc;
        }, new Map<string, Warning[]>());

        return Array.from(warningCountMap.values()).reduce<WarningItem[]>((acc, warnings) => {
            const warning = warnings[0];

            return warning ? acc.concat({
                warning,
                count: warnings.length,
            }) : acc;
        }, []).sort((a, b) => b.count - a.count);
    }

    private sendRequest(publishNow: boolean, time?: DateTime) {
        this.publishing.set(true);
        this.publishInput.disable();
        this.warningCheckbox.disable();
        this.publishRadio.disable();

        this.scheduleService.publish(this.data.customerId, this.data.scheduleId, time).pipe(
            catchError(() => {
                this.publishing.set(false);
                this.publishInput.enable();
                this.warningCheckbox.enable();
                this.publishRadio.enable();
                return EMPTY;
            }),
        ).subscribe(() => {
            ScheduleComponent.schedule.update((s) => {
                if (s) {
                    return new Schedule({
                        ...s._response,
                        is_published: !!publishNow,
                    });
                }

                return s;
            });

            this.dialogRef.close({
                option: publishNow ? 'now' : 'later',
                time,
            });
        });
    }

    publish() {
        if (this.publishNow()) {
            this.sendRequest(true);
        } else {
            const time = this.publishTime();

            if (time) {
                this.sendRequest(false, time);
            }
        }
    }
}
