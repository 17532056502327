import { Component, inject, OnInit, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { HandleShiftComponent } from '../../components/handle-shift/handle-shift.component';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { PermissionPipe } from '../../../shared/pipes/permission.pipe';
import { ShiftPeriodListComponent } from '../../components/shift-period-list/shift-period-list.component';
import { AddEditShiftPeriodDialogComponent, AddEditShiftPeriodDialogData, AddEditShiftPeriodDialogReturn } from '../add-edit-shift-period-dialog/add-edit-shift-period-dialog.component';
import { CommentListComponent } from '../../../shared/components/comment-list/comment-list.component';
import { catchError, EMPTY, filter, map, Observable, of, tap } from 'rxjs';
import { ProductPipe } from '../../../shared/pipes/product.pipe';
import { Products } from '../../../shared/enums/products';
import { AutoPopulatingShiftResultComponent } from '../../../auto-scheduling/components/auto-scheduling-shift-results/auto-populating-shift-result.component';
import { ShiftService } from '../../http/shift.service';
import { Shift } from '../../models/shift';
import { ShiftCommentService } from '../../http/shift-comment.service';
import { Comment } from '../../../shared/models/comment';
import { Warning } from '../../../shared/models/warning';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CausesDialogComponent, CausesDialogData } from '../../../shared/dialogs/causes-dialog/causes-dialog.component';
import { Namespace } from '../../../shared/enums/namespace';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { AutoSchedulingShiftResultComponent } from '../../../auto-scheduling/components/auto-scheduling-shift-result/auto-scheduling-shift-result.component';
import { ShiftPeriod } from '../../models/shift-period';
import { Qualification } from '../../../shared/models/qualification';
import { Schedule } from '../../models/schedule';
import { ScheduleComponent } from '../../pages/schedule/schedule.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { canDeleteShiftCommentPermission } from '../../permissions';

export type ScheduleShiftDialogData = DialogData & {
    stackId: number;
    customerId: number;
    scheduleId: number;
    shiftId: number;
    /**
     * Extra data to get with the shift whenever it's changed.
     */
    shiftWiths: string[];
    /**
     * Explicitly require returned value to be with periods and comments etc. Everything we need to display properly.
     */
    shift?: Observable<{
        shift: Shift,
        periods: ShiftPeriod[],
        comments: Comment[],
        warnings: Warning[],
        qualifications: Qualification[],
        schedule: Schedule,
    }>
    initialTab?: number;
    periodAddedCallback?: (period: ShiftPeriod) => void;
    periodEditedCallback?: (period: ShiftPeriod) => void;
    periodDeletedCallback?: (periodId: number) => void;
}

/**
 * If a `Shift` is returned, it means the shift was created or updated.
 *
 * If `null` is returned, it means the shift was deleted.
 */
export type ScheduleShiftDialogReturn = Shift | null;

@Component({
    selector: 'eaw-schedule-shift-dialog',
    standalone: true,
    imports: [
        CommonModule,
        DialogHeaderComponent,
        TranslatePipe,
        MatTabsModule,
        HandleShiftComponent,
        InfoLoadingComponent,
        MatDialogActions,
        ActionButtonComponent,
        MatButtonModule,
        MatDialogClose,
        MatListModule,
        MatIconModule,
        PermissionPipe,
        MatDialogContent,
        ShiftPeriodListComponent,
        CommentListComponent,
        ProductPipe,
        AutoPopulatingShiftResultComponent,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        AutoSchedulingShiftResultComponent,
    ],
    templateUrl: './schedule-shift-dialog.component.html',
    styleUrl: './schedule-shift-dialog.component.scss',
})
export class ScheduleShiftDialogComponent extends DialogComponent<ScheduleShiftDialogData, ScheduleShiftDialogReturn> implements OnInit {
    private matDialog = inject(MatDialog);
    private shiftService = inject(ShiftService);
    private snackBarService = inject(SnackBarService);
    private shiftCommentService = inject(ShiftCommentService);
    private permissionCheckService = inject(PermissionCheckService);

    protected shiftPeriodListComponent = viewChild(ShiftPeriodListComponent);
    protected handleShiftComponent = viewChild(HandleShiftComponent);

    readonly autoSchedulingProduct = Products.AutomaticScheduling;
    protected periodsCount = 0;
    protected loading = signal(true);
    protected commentControl = new FormControl<string | null>(null, Validators.required);
    protected schedule?: Schedule;
    protected shift?: Shift;
    protected periods = signal<ShiftPeriod[]>([]);
    protected comments: Comment[] = [];
    protected warnings: Warning[] = [];
    protected qualifications: Qualification[] = [];
    protected addingComment = false;
    protected shiftWiths: string[] = [];

    constructor() {
        super(undefined, undefined, DialogSize.Large);
    }

    ngOnInit() {
        this.shiftWiths = this.data.shiftWiths || ScheduleComponent.shiftWiths;

        const shiftObservable = this.shiftService.get(this.data.customerId, this.data.shiftId, { with: [ 'comments', 'periods', 'warnings', 'qualifications', 'schedule' ] }).pipe(
            map((shift) => {
                return {
                    shift,
                    periods: shift.periods,
                    comments: shift.comments,
                    warnings: shift.warnings,
                    qualifications: shift.qualifications,
                    schedule: shift.schedule,
                };
            }),
        );

        (this.data.shift || shiftObservable).subscribe((shift) => {
            this.shift = shift.shift;
            this.periods.set(shift.periods);
            this.comments = shift.comments;
            this.warnings = shift.warnings;
            this.qualifications = shift.qualifications;
            this.schedule = shift.schedule;
            this.loading.set(false);
        });
    }

    deleteShift() {
        void this.snackBarService.t('DELETING_SHIFT', Namespace.Scheduling);
        this.shiftService.delete(this.data.customerId, this.data.scheduleId, this.data.shiftId).subscribe(() => {
            this.close(null);
            void this.snackBarService.t('SHIFT_DELETED', Namespace.Scheduling);
        });
    }

    addComment() {
        const comment = this.commentControl.value;
        if (!comment) {
            return;
        }

        this.addingComment = true;
        this.commentControl.disable();
        this.shiftCommentService.create(this.data.customerId, this.data.scheduleId, this.data.shiftId, comment).pipe(catchError(() => of(null))).subscribe((newComment) => {
            this.addingComment = false;
            this.commentControl.enable();

            if (newComment) {
                this.commentControl.setValue(null);
                this.comments = [ newComment, ...this.comments ];
            }
        });
    }

    canDelete = (commentId: number) => {
        return this.permissionCheckService.isAllowed(canDeleteShiftCommentPermission(this.data.stackId, this.data.customerId, this.data.shiftId, commentId));
    };

    deleteComment = (commentId: number) => {
        return this.shiftCommentService.delete(this.data.customerId, this.data.scheduleId, this.data.shiftId, commentId).pipe(
            catchError(() => EMPTY),
            tap(() => this.comments = [ ...this.comments.filter((c) => c.id !== commentId) ]),
        );
    };

    openCauseDialog(warning: Warning) {
        this.matDialog.open<CausesDialogComponent, CausesDialogData, boolean>(CausesDialogComponent, {
            data: {
                customerId: warning.customerId,
                warningId: warning.id,
            },
        });
    }

    addPeriod() {
        this.matDialog.open<AddEditShiftPeriodDialogComponent, AddEditShiftPeriodDialogData, AddEditShiftPeriodDialogReturn>(AddEditShiftPeriodDialogComponent, {
            data: {
                customerId: this.data.customerId,
                scheduleId: this.data.scheduleId,
                shiftId: this.data.shiftId,
            },
        }).afterClosed().pipe(filter((period) => !!period)).subscribe((period) => {
            this.periods.update((periods) => [ ...periods, period ]);
            this.data.periodAddedCallback?.(period);
        });
    }

    onPeriodEdited(period: ShiftPeriod) {
        this.periods.update((periods) => periods.filter((x) => x.id !== period.id).concat(period));
        this.data.periodEditedCallback?.(period);
    }

    onPeriodDeleted(periodId: number) {
        this.periods.update((periods) => periods.filter((x) => x.id !== periodId));
        this.data.periodDeletedCallback?.(periodId);
    }

    close(shift: ScheduleShiftDialogReturn) {
        this.dialogRef.close(shift);
    }
}
