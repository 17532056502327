import { module } from 'angular';

class UpcomingBirthdaysWidgetContainer {
    eawWidget: any;
    fakeWidget: any;
    component: any;

    constructor() {
        this.fakeWidget = () => ({
            getSetting: (setting: string) => {
                return this.eawWidget.widget.settings[setting];
            },
        });
    }

    $onInit() {
        this.eawWidget.setLoading(true);
        const onSettingsChanged = this.eawWidget.widgetSettingsChange;
        this.eawWidget.widgetSettingsChange = (settings: any) => {
            onSettingsChanged(settings);
            if (settings.locations || settings.days) {
                this.eawWidget.setLoading(true);
                this.component.getEmployees();
            }
        };
    }
}

module('eaw.dashboard').component('upcomingBirthdaysWidgetContainer', {
    template: `<upcoming-birthdays-widget-downgrade class="tw-h-full" [widget]="$widget.fakeWidget" (component)="$widget.component = $event" (loaded)="$widget.eawWidget.setLoading(false)"></upcoming-birthdays-widget-downgrade>`,
    require: {
        eawWidget: '^eawWidget',
    },
    controller: UpcomingBirthdaysWidgetContainer,
    controllerAs: '$widget',
});
