// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.staffing', [
    'eaw.resource',
    'eaw.login',
]).config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state(`eaw/app/company/settings/staffing/matrix`, {
        parent: `eaw/app/company/settings/staffing_templates`,
        url: '/matrix/:id',
        views: {
            'content@': 'componentCreator',
        },
        resolve: {
            template: [ '$transition$', 'staffingTemplateFactory', function($transition$, staffingTemplateFactory) {
                return staffingTemplateFactory.get.query(CurrentOld.customer.id, $transition$.params().id).$promise;
            } ],
            bindings: [ 'template', function(template) {
                return { template };
            } ],
            componentName: [ 'template', function(template) {
                if (template.output === 'headcount') {
                    return 'staffing-headcount';
                }
                if (template.output === 'business_units') {
                    return 'staffing-business-units';
                }
            } ],
        },
    });

    $stateProvider.state(`eaw/app/company/settings/week_setup/rows`, {
        parent: `eaw/app/company/settings/week_setup`,
        url: '/:week_setup_id',
        views: {
            'content@': 'weekSetup',
        },
        resolve: {
            weekSetup: [ '$transition$', 'weekSetupFactory', 'customer', function($transition$, weekSetupFactory, customer) {
                return weekSetupFactory.get.query(customer.id, $transition$.params().week_setup_id, [ 'weekRows.vsTemplate' ]).$promise;
            } ],
        },
        eawBreadcrumb: {
            label: 'WEEK',
        },
    });
} ]);
