import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject, signal } from '@angular/core';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef } from '@angular/material/dialog';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { catchError, EMPTY, tap } from 'rxjs';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Customer } from '../../../shared/models/customer';
import { CustomerUserService } from '../../../shared/http/customer-user.service';
import { CustomerAutocompleteService } from '../../../shared/autocompletes/customer-autocomplete.service';

export interface AdminUserCustomersCustomerDialogData extends DialogData {
    userId: number;
}

@Component({
    selector: 'eaw-admin-user-customers-customer-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        AutocompleteComponent,
        TranslatePipe,
        AsyncPipe,
        MatDialogActions,
        ActionButtonComponent,
        ReactiveFormsModule,
        MatIcon,
        MatButton,
        MatDialogClose,
    ],
    templateUrl: './admin-user-customers-customer-dialog.component.html',
    styleUrl: './admin-user-customers-customer-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUserCustomersCustomerDialogComponent extends DialogComponent {
    private destroyedRef = inject(DestroyRef);
    customerAutocompleteService = inject(CustomerAutocompleteService);
    customerUserService = inject(CustomerUserService);

    loading = signal(false);

    userId: number;
    form = new FormGroup({
        customer: new FormControl<Customer | null>(null, Validators.required),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AdminUserCustomersCustomerDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AdminUserCustomersCustomerDialogComponent>,
    ) {
        super(dialogRef, data);
        this.userId = data.userId;
        data.size = DialogSize.Medium;
    }

    submit(): void {
        const { customer } = this.form.value;
        if (!customer) {
            return;
        }
        this.loading.set(true);
        this.form.disable();
        this.customerUserService.create(customer.id, this.userId).pipe(
            takeUntilDestroyed(this.destroyedRef),
            tap(() => {
                this.loading.set(false);
                this.form.enable();
            }),
            catchError(() => {
                this.loading.set(false);
                this.form.enable();
                return EMPTY;
            })).subscribe(() => this.dialogRef.close(customer.name));
    }
}
