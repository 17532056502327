// @ts-nocheck
import { module } from 'angular';

module('eaw.payroll').component('employeeTimepunchStatusTabs', {
    template: `<md-card>
    <md-tabs md-selected="time.tabIndex" md-dynamic-height="true">
        <md-tab label="{{'DAY' | i18next}}" md-on-select="time.changedTab()">
            <day-timepunch-status customer="time.customer" colors="time.colors"></day-timepunch-status>
        </md-tab>
        <md-tab label="{{'EMPLOYEE' | i18next}}" md-on-select="time.changedTab()">
            <employee-timepunch-status customer="time.customer" colors="time.colors"></employee-timepunch-status>
        </md-tab>
    </md-tabs>
</md-card>
`,
    controllerAs: 'time',
    bindings: {
        customer: '<',
    },
    controller: [ '$mdColors', function showPunchedIn($mdColors) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.colors = {
                present: $mdColors.getThemeColor('green'),
                notPresent: $mdColors.getThemeColor('red'),
            };
        };
    } ],
});
