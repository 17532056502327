import { Component, Inject, OnInit, signal } from '@angular/core';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { CurrentService, StoreIn } from '../../../shared/services/current.service';
import { Customer } from '../../../shared/models/customer';
import { DateTime } from 'luxon';
import { KpiService } from '../../../kpi/http/kpi.service';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { Sort, SortDirection, MatSortModule } from '@angular/material/sort';
import { KpiType } from '../../../kpi/models/kpi-type';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { TranslateService } from '../../../shared/services/translate.service';
import { ReorderDialogItem, ReorderItemsDialogService } from '../../../shared/dialogs/reorder-items-dialog/reorder-items-dialog.service';
import { Namespace } from '../../../shared/enums/namespace';
import { KpiTypeService } from '../../../kpi/http/kpi-type.service';
import { MatDialog } from '@angular/material/dialog';
import { KpiDialogComponent, KpiDialogData } from '../../../kpi/dialogs/kpi-dialog/kpi-dialog.component';
import { DatePickerDialogComponent, DatePickerDialogData, DatePickerDialogReturn } from '../../../shared/dialogs/date-picker-dialog/date-picker-dialog.component';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

type ColumnStoreItem = {
    id: number,
    wtiKey: string;
}

type DataSourceItemValue = {
    type: KpiType,
    pure: number | undefined,
    formatted: string | undefined,
};

interface DataSourceItem {
    customer: Customer;
    values: Record<string, DataSourceItemValue>,
}

@Component({
    selector: 'eaw-chain-kpi',
    templateUrl: './chain-kpi.component.html',
    styleUrl: './chain-kpi.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatButtonModule,
        MatIconModule,
        NgIf,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        NgFor,
        NgClass,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ChainKpiComponent implements OnInit {
    private readonly storeLocation: StoreIn = 'default';
    private readonly selectedColumnsStoreKey = 'chainops_selected_kpi_columns';
    private readonly deselectedColumnsStoreKey = 'chainops_deselected_kpi_columns';
    readonly locationColumn = 'location';
    readonly detailsColumn = 'details';

    filterForm = new FormGroup({
        dateRange: new FormGroup({
            from: new FormControl<DateTime | null>(DateTime.now().startOf('month'), Validators.required),
            to: new FormControl<DateTime | null>(DateTime.now().endOf('month'), Validators.required),
        }),
    });

    isThisMonth = true;
    overviewInterval: {from?: DateTime, to?: DateTime} = {};
    kpiTypes: KpiType[] = [];
    loading = false;
    sortActive = this.locationColumn;
    sums: Record<string, DataSourceItemValue> = {};
    columns: KpiType[] = [];
    compactNumbers = false;
    month = DateTime.now();
    displayColumns: string[] = [];
    sortDirection: SortDirection = '';
    dataSource: DataSourceItem[] = [];
    buttons: PageHeaderButton[] = [
        new PageHeaderButton({
            click: this.chooseMonth.bind(this),
            icon: 'calendar_month',
            menuText: signal(this.translate.t('MONTH')),
        }),
        new PageHeaderButton({
            click: this.toggleCompactNumbers.bind(this),
            icon: () => this.compactNumbers ? 'compress' : 'expand',
            active: () => this.compactNumbers,
            menuText: signal(this.translate.t('COMPACT_NUMBERS', 'kpi')),
            rotation: 90,
        }),
        new PageHeaderButton({
            click: this.adjustColumns.bind(this),
            icon: 'view_column',
            menuText: signal(this.translate.t('ADJUST_COLUMNS', 'kpi')),
            disabled: () => !this.columns.length,
        }),
    ];

    constructor(
        @Inject(CustomerGroupService) public customerGroupService: CustomerGroupService,
        @Inject(CurrentService) public current: CurrentService,
        @Inject(MatDialog) public matDialog: MatDialog,
        @Inject(KpiService) public kpiService: KpiService,
        @Inject(KpiTypeService) public kpiTypeService: KpiTypeService,
        @Inject(NumberFormatterService) public numberFormatterService: NumberFormatterService,
        @Inject(TranslateService) public translate: TranslateService,
        @Inject(ReorderItemsDialogService) public reorderItemsDialogService: ReorderItemsDialogService,
    ) {
    }

    ngOnInit(): void {
        void this.getOverview();
    }

    async getSelectedTypes() {
        return (await this.current.retrieve<ColumnStoreItem[]>(this.selectedColumnsStoreKey, this.storeLocation)) || [];
    }

    async getOverview() {
        const from = this.filterForm.controls.dateRange.controls.from.value;
        const to = this.filterForm.controls.dateRange.controls.to.value;
        if (!(from && to)) {
            return;
        }

        this.loading = true;
        const selectedTypeIds = (await this.getSelectedTypes()).map((t) => t.id);

        this.kpiService.getOverview(from, to, selectedTypeIds).subscribe((result) => {
            const dataSource: DataSourceItem[] = [];

            result.customers.forEach((customer) => {
                dataSource.push({
                    customer,
                    values: result.kpiTypes.reduce((obj, type) => {
                        obj[type.key] = {
                            type,
                            ...type.sumForCustomer(customer.id, result.kpiData, result.providerSums, this.current.languageTag, this.numberFormatterService, this.compactNumbers),
                        };

                        return obj;
                    }, {} as Record<string, DataSourceItemValue>),
                });
            });

            result.kpiTypes.forEach((type) => {
                this.sums[type.key] = {
                    type,
                    ...type.sumAll(result.kpiData, result.providerSums, this.current.languageTag, this.numberFormatterService, this.compactNumbers),
                };
            });

            this.overviewInterval = {
                from,
                to,
            };
            this.kpiTypes = [ ...result.kpiTypes ];
            this.dataSource = dataSource;
            this.columns = result.kpiTypes;
            this.displayColumns = this.getDisplayColumns(selectedTypeIds, result.kpiTypes);
            this.sort({
                active: this.locationColumn,
                direction: 'asc',
            });
            this.loading = false;
        });
    }

    changeMonth(change: 1 | -1) {
        this.month = this.month.plus({ month: change });
        this.isThisMonth = this.month.hasSame(DateTime.now(), 'month');

        this.updateDateRange();
        void this.getOverview();
    }

    chooseMonth() {
        this.matDialog.open<DatePickerDialogComponent, DatePickerDialogData, DatePickerDialogReturn>(DatePickerDialogComponent, {
            data: {
                date: this.month,
                target: 'month',
                maxDate: DateTime.now().endOf('month'),
                nullable: false,
            },
        }).afterClosed().subscribe((result) => {
            if (!result?.ok || result.value == null) {
                return;
            }
            this.month = result.value;
            this.isThisMonth = this.month.hasSame(DateTime.now(), 'month');

            this.updateDateRange();
            void this.getOverview();
        });
    }

    getDisplayColumns(selectedTypeIds: number[], kpiTypes: KpiType[]) {
        const columns = [ this.locationColumn, this.detailsColumn ];

        selectedTypeIds.forEach((typeId) => {
            const type = kpiTypes.find((t) => t.id === typeId);
            if (type) {
                columns.push(type.key);
            }
        });

        kpiTypes.forEach((type) => {
            if (!columns.includes(type.key)) {
                columns.push(type.key);
            }
        });

        return columns;
    }

    toggleCompactNumbers() {
        this.compactNumbers = !this.compactNumbers;

        this.dataSource.forEach((item) => {
            Object.entries(item.values || {}).forEach(([ _, value ]) => {
                value.formatted = value.type.valueFormatter(value.pure, this.current.languageTag, this.numberFormatterService, this.compactNumbers);
            });
        });

        Object.entries(this.sums || {}).forEach(([ _, value ]) => {
            value.formatted = value.type.valueFormatter(value.pure, this.current.languageTag, this.numberFormatterService, this.compactNumbers);
        });
    }

    async adjustColumns() {
        const columns: ReorderDialogItem<ColumnStoreItem>[] = this.columns.map((c) => {
            return {
                item: {
                    id: c.id,
                    wtiKey: c.wtiKey,
                },
                selected: true,
                text: this.translate.t(c.wtiKey, Namespace.KPITypes),
            };
        });

        const deselectedColumns = (await this.current.retrieve<ColumnStoreItem[]>(this.deselectedColumnsStoreKey, this.storeLocation)) || [];
        deselectedColumns.forEach((c) => {
            columns.push({
                item: c,
                selected: false,
                text: this.translate.t(c.wtiKey, Namespace.KPITypes),
            });
        });

        this.reorderItemsDialogService.open<ColumnStoreItem>({
            items: columns,
        }).afterClosed().subscribe(async (result) => {
            if (result == null) {
                return;
            }

            await this.current.store<ColumnStoreItem[]>(this.selectedColumnsStoreKey, result
                .filter((r) => r.selected)
                .map((r) => ({
                    id: r.item.id,
                    wtiKey: r.item.wtiKey,
                })), this.storeLocation);

            await this.current.store<ColumnStoreItem[]>(this.deselectedColumnsStoreKey, result
                .filter((r) => !r.selected)
                .map((r) => ({
                    id: r.item.id,
                    wtiKey: r.item.wtiKey,
                })), this.storeLocation);

            await this.getOverview();
        });
    }

    showDetails(item: DataSourceItem) {
        if (!(this.overviewInterval.from && this.overviewInterval.to)) {
            return;
        }

        this.matDialog.open<KpiDialogComponent, KpiDialogData, null>(KpiDialogComponent, {
            data: {
                customerId: item.customer.id,
                customerName: item.customer.name,
                from: this.overviewInterval.from,
                to: this.overviewInterval.to,
            },
        });
    }

    sort(event: Sort) {
        if (event.direction === '') {
            event.direction = 'asc';
            event.active = 'date';
            this.sortActive = event.active;
            this.sortDirection = event.direction;
        }

        this.dataSource = [ ...this.dataSource.sort((a, b) => {
            const isAsc = event.direction === 'asc';
            const itemA = isAsc ? a : b;
            const itemB = isAsc ? b : a;

            if (event.active === this.locationColumn) {
                return itemA.customer.name.localeCompare(itemB.customer.name);
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return (itemA.values[event.active]!.pure || 0) - (itemB.values[event.active]!.pure || 0);
        }) ];
    }

    tableTrackByFn(_: number, item: DataSourceItem) {
        return item.customer.id;
    }

    private updateDateRange() {
        this.filterForm.controls.dateRange.controls.from.setValue(this.month.startOf('month'));
        this.filterForm.controls.dateRange.controls.to.setValue(this.month.endOf('month'));
    }
}
