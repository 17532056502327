import { Component, inject, Input, ViewChild } from '@angular/core';
import { LinksService } from '../../http/links.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { Mobile } from '../../../shared/utils/eaw-mobile';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { Link } from '../../models/link';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { of, shareReplay, switchMap, tap } from 'rxjs';
import { LinkDialogComponent, LinkDialogData } from '../../dialogs/link-dialog/link-dialog.component';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { DataTableBooleanColumn } from '../../../data-table/types/data-table-boolean-column';
import { MainMenuService } from '../../../main-menu/services/main-menu.service';

@Component({
    selector: 'eaw-links',
    templateUrl: './links.component.html',
    styleUrl: './links.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class LinksComponent implements EawDataTable {
    private readonly linksService = inject(LinksService);
    private readonly matDialog = inject(MatDialog);
    private readonly confirm = inject(ConfirmDialogService);
    private readonly snackbar = inject(SnackBarService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly mainMenuService = inject(MainMenuService);

    @ViewChild(DataTableComponent) table!: DataTableComponent<Link>;

    @Input({ required: true }) customerId!: number;

    fabButton: HeaderFabButton;

    columns: DataTableColumnType<Link>[];

    request: DataTableRequest = of(mockArrayPaginatedResponse());

    constructor() {
        this.fabButton = {
            click: this.openDialog.bind(this),
            hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.hyper_links.create`),

        };

        this.columns = [
            new DataTableButtonColumn<Link>({
                buttons: [ {
                    icon: 'open_in_new',
                    click: (cell) => {
                        this.followLink(cell.item);
                    },
                    show: () => of(true),
                    tooltip: { key: 'OPEN' },
                    nonBlocking: true,
                } ],
            }),
            new DataTableTextColumn({
                value: 'text',
                header: new DataTableHeader({
                    i18n: 'TITLE',
                    sortBy: 'text',
                }),
            }),
            new DataTableTextColumn({
                value: 'description',
                header: new DataTableHeader({
                    i18n: 'DESCRIPTION',
                    sortBy: 'description',
                }),
            }),
            new DataTableBooleanColumn({
                boolean: 'mainMenu',
                header: new DataTableHeader({
                    i18n: 'MAIN_MENU',
                    sortBy: 'main_menu',
                }),
            }),
            new DataTableButtonColumn<Link>({
                buttons: [ {
                    icon: 'edit',
                    click: this.openDialog.bind(this),
                    show: (link) => this.permissionCheckService.isAllowed(`customers.${this.customerId}.hyper_links.${link.id}.update`),
                    tooltip: { key: 'EDIT' },
                },
                {
                    icon: 'delete',
                    click: this.delete.bind(this),
                    type: 'warn',
                    show: (link) => this.permissionCheckService.isAllowed(`customers.${this.customerId}.hyper_links.${link.id}.delete`),
                    tooltip: { key: 'DELETE' },
                },
                ],
            }),
        ];
    }

    openDialog(cell?: DataTableButtonCell<Link>) {
        this.matDialog.open<LinkDialogComponent, LinkDialogData, Link>(LinkDialogComponent, {
            data: {
                size: DialogSize.Medium,
                link: cell?.item,
            },
        }).afterClosed().pipe(tap((link) => {
            if (link) {
                this.mainMenuService.reload();
                this.updateTable();
            } else {
                if (cell) {
                    cell.disabled.set(false);
                }
            }
        })).subscribe();
    }

    updateTable(pagination: Partial<DataTablePagination> = {}) {
        this.request = this.linksService.getAll(this.customerId, {
            ...pagination,
            'fields[]': [ 'text', 'description' ],
        }).pipe(shareReplay());

        return this.request;
    }

    followLink(link: Link) {
        if (Mobile.isMobile) {
            Mobile.openBrowser(link.url);
        } else {
            window.open(link.url, '_blank');
        }
    }

    delete(cell: DataTableButtonCell<Link>) {
        this.confirm.delete()
            .afterClosed()
            .pipe(
                switchMap((response) => {
                    if (!response?.ok) {
                        cell.disabled.set(false);
                        return this.request;
                    }

                    return this.linksService.delete(this.customerId, cell.item.id)
                        .pipe(
                            switchMap(() => {
                                this.snackbar.deleted();
                                this.mainMenuService.reload();
                                return this.updateTable();
                            }));
                }),
            )
            .subscribe();
    };
}
