// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

const template3 = `<md-dialog>
    <form name="generateIntervalsForm">
        <eaw-dialog-header-old heading="'staffing:GENERATE_INTERVALS' | i18next"></eaw-dialog-header-old>

        <md-dialog-content>
            <div class="md-dialog-content" layout="column">
                <md-input-container>
                    <label ng-i18next="FROM"></label>
                    <input ng-model="ctrl.from" type="number" min="0" step="1" required autocomplete="off">
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="TO"></label>
                    <input ng-model="ctrl.to" type="number" min="{{ctrl.from}}" step="1" required autocomplete="off">
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="STEP"></label>
                    <input ng-model="ctrl.step" type="number" min="1" max="{{ctrl.to - ctrl.from}}" step="1" required autocomplete="off">
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CLOSE"></md-button>
            <md-button ng-click="ctrl.generate()" ng-i18next="GENERATE" ng-disabled="generateIntervalsForm.$invalid || ctrl.generating"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;
const template2 = `<md-dialog>
    <form name="updateIntervalForm">
        <eaw-dialog-header-old heading="'staffing:UPDATE_INTERVAL' | i18next"></eaw-dialog-header-old>

        <md-dialog-content>
            <div class="md-dialog-content" layout="column">
                <md-input-container>
                    <label ng-i18next="FROM"></label>
                    <input ng-model="ctrl.from" type="number" min="0" max="{{ctrl.to}}" step="1" required autocomplete="off">
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="TO"></label>
                    <input ng-model="ctrl.to" type="number" min="{{ctrl.from}}" step="1" required autocomplete="off">
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CLOSE"></md-button>
            <md-button ng-click="ctrl.update()" ng-i18next="UPDATE" ng-disabled="updateIntervalForm.$invalid || ctrl.updating"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;
const template1 = `<md-dialog>
    <form name="addIntervalForm">
        <eaw-dialog-header-old heading="'staffing:ADD_INTERVAL' | i18next"></eaw-dialog-header-old>

        <md-dialog-content>
            <div class="md-dialog-content" layout="column">
                <md-input-container>
                    <label ng-i18next="FROM"></label>
                    <input ng-model="ctrl.from" type="number" min="0" max="{{ctrl.to}}" step="1" required autocomplete="off">
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="TO"></label>
                    <input ng-model="ctrl.to" type="number" min="{{ctrl.from}}" step="1" required autocomplete="off">
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CLOSE"></md-button>
            <md-button ng-click="ctrl.add()" ng-i18next="ADD" ng-disabled="addIntervalForm.$invalid || ctrl.adding"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.staffing').factory('staffingService', [ '$mdDialog', 'staffingIntervalFactory', 'staffingTemplateFactory', 'NgTableParams', function($mdDialog, staffingIntervalFactory, staffingTemplateFactory, NgTableParams) {
    const service = this;

    service.createNgTableParams = (template, formatterFunction) => new NgTableParams({
        count: 10,
    }, {
        getData(params) {
            return staffingIntervalFactory.getAll.query(template.customer_id, template.id, {
                per_page: params.count(),
                page: params.page(),
                from: template.from,
                to: template.to,
                order_by: 'from',
                direction: 'asc',
                with: [ 'data' ],
            }).$promise.then((data) => {
                params.total(data.total);
                formatterFunction?.(data.data);

                return data.data;
            });
        },
    });

    service.getDataAction = (oldValue, newValue) => {
        if (oldValue !== newValue) {
            if (oldValue == null && newValue != null) {
                return 'create';
            }

            if (oldValue != null && newValue != null) {
                return 'update';
            }

            if (oldValue != null && newValue == null) {
                return 'delete';
            }
        }
    };

    service.addInterval = (template, tableParams) => {
        $mdDialog.show({
            template: template1,
            controllerAs: 'ctrl',
            bindToController: true,
            locals: {
                staffingTemplate: template,
                tableParams,
            },
            controller: 'addStaffingIntervalDialogCtrl',
        });
    };

    service.updateInterval = (template, interval, tableParams) => {
        $mdDialog.show({
            template: template2,
            controllerAs: 'ctrl',
            bindToController: true,
            locals: {
                staffingTemplate: template,
                interval,
                tableParams,
            },
            controller: 'updateStaffingIntervalDialogCtrl',
        });
    };

    service.generateIntervals = (template, tableParams) => {
        $mdDialog.show({
            template: template3,
            controllerAs: 'ctrl',
            bindToController: true,
            locals: {
                staffingTemplate: template,
                tableParams,
            },
            controller: 'generateStaffingIntervalsDialogCtrl',
        });
    };

    service.deleteInterval = (template, interval, tableParams) => {
        $mdDialog.show(
            $mdDialog.confirm()
                .theme('delete')
                .title(t('staffing:DELETE_INTERVAL'))
                .textContent(t('staffing:DELETE_INTERVAL_TEXT', {
                    from: interval.from,
                    to: interval.to,
                }))
                .ok(t('DELETE'))
                .cancel(t('CANCEL')),
        ).then(() => {
            interval.disabled = true;
            staffingIntervalFactory.delete.query(template.customer_id, interval.template_id, interval.id).$promise.then(() => {
                tableParams.reload();
            }).catch(() => interval.disabled = false);
        });
    };

    service.canUpdateTemplate = (template) => CurrentOld.can(staffingTemplateFactory.update.permission(template.customer_id, template.id));

    service.canAddInterval = (template) => CurrentOld.can(staffingIntervalFactory.create.permission(template.customer_id, template.id));

    service.canGenerateIntervals = (template) => CurrentOld.can(staffingIntervalFactory.createMany.permission(template.customer_id, template.id));

    service.canUpdateInterval = (template, interval) => CurrentOld.can(staffingIntervalFactory.update.permission(template.customer_id, template.id, interval.id));

    service.canDeleteInterval = (template, interval) => CurrentOld.can(staffingIntervalFactory.delete.permission(template.customer_id, template.id, interval.id));

    return service;
} ]);
