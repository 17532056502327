import { stringToDateTime } from '../utils/string-to-date-time';
import { DateTime } from 'luxon';
import { WarningCause, WarningCauseResponse } from './warning-cause';
import { ApiResponse } from '../interfaces/api-response';
import { BusinessDate } from '../utils/business-date';
import { BaseApiModel } from './base-api-model';

export interface WarningResponse extends ApiResponse {
    action?: any | null;
    attribute?: string;
    business_date?: string | null;
    created_at: string;
    customer_id: number;
    deleted_at?: string | null;
    deleted_by?: number | null;
    id: number;
    message: string;
    message_parameters?: Record<string, string> | null;
    object_id: number;
    object_type: string;
    observer_id?: number | null;
    updated_at: string;
    causes?: WarningCauseResponse[];
}

export class Warning extends BaseApiModel<WarningResponse, Warning> {
    action?: unknown | null;
    attribute?: string;
    businessDate: BusinessDate | null;
    createdAt: DateTime;
    customerId: number;
    deletedAt?: string | null;
    deletedBy?: number | null;
    id: number;
    message: string;
    messageParameters: Record<string, string>;
    objectId: number;
    objectType: string;
    observerId?: number | null;
    updatedAt: DateTime;
    causes?: WarningCause[];

    constructor(data: WarningResponse) {
        super(data, undefined);

        this.action = data.action;
        this.attribute = data.attribute;
        this.businessDate = data.business_date ? new BusinessDate(data.business_date) : null;
        this.customerId = data.customer_id;
        this.deletedBy = data.deleted_by;
        this.id = data.id;
        this.message = data.message;
        this.messageParameters = data.message_parameters || {};
        this.objectId = data.object_id;
        this.objectType = data.object_type;
        this.observerId = data.observer_id;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);

        if (data.causes) {
            this.causes = data.causes.map((c) => new WarningCause(c));
        }
    }
}
