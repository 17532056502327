import { Component, Inject } from '@angular/core';
import { DialogComponent } from '../../../shared/dialogs/dialog-component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { RemoveUserAccessData } from '../../pages/users/dialog/user-access-dialog.service';
import { UserGroupService } from '../../../shared/http/user-group.service';
import { UserGroup } from '../../models/user-group';
import { forkJoin, Observable } from 'rxjs';
import { CustomerUserService } from '../../../shared/http/customer-user.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { DateTime } from 'luxon';

@Component({
    selector: 'eaw-remove-user-access-dialog',
    templateUrl: './remove-user-access-dialog.component.html',
    styleUrl: './remove-user-access-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class RemoveUserAccessDialogComponent extends DialogComponent<RemoveUserAccessData, boolean, RemoveUserAccessDialogComponent> {
    formGroup = new FormGroup({
        handleShifts: new FormControl<'delete' | 'release'>('release', { nonNullable: true }),
        removeFromGroups: new FormControl<'all' | 'none' | 'some'>('all'),
        groupsToRemove: new FormControl([], { nonNullable: true }),
    });

    loadingUserInfo = true;
    handlingUser = false;
    groups: UserGroup[] = [];
    customerId: number;
    userId: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: RemoveUserAccessData,
        @Inject(MatDialogRef) dialogRef: MatDialogRef<RemoveUserAccessDialogComponent, boolean>,
        @Inject(UserGroupService) private userGroupService: UserGroupService,
        @Inject(CustomerUserService) private customerUserService: CustomerUserService,
    ) {
        super(dialogRef, data);

        this.customerId = data.customer.id;
        this.userId = data.user.id;

        this.formGroup.controls.removeFromGroups.valueChanges.subscribe((opt) => {
            if (opt === 'some') {
                this.formGroup.controls.groupsToRemove.setValidators(Validators.required);
            } else {
                this.formGroup.controls.groupsToRemove.clearValidators();
            }
            this.formGroup.controls.groupsToRemove.updateValueAndValidity();
        });

        this.getData();
    }

    getData() {
        this.loadingUserInfo = true;

        this.userGroupService.getAllForCustomer(this.customerId, {
            user_id: this.userId,
            per_page: 100,
        }).subscribe({
            next: (groups) => {
                this.groups = groups.data.filter((group) => !group.pivot.to || group.pivot.to > DateTime.now());
                this.loadingUserInfo = false;
            },
            error: () => {
                this.formGroup.controls.removeFromGroups.setValue('none');
                this.loadingUserInfo = false;
            },
        });
    }

    submit() {
        const actions: Observable<unknown>[] = [];

        if (this.groups.length && this.formGroup.controls.removeFromGroups.value === 'all') {
            actions.push(this.userGroupService.removeMembers(this.groups, this.userId));
        } else if (this.formGroup.controls.removeFromGroups.value === 'some') {
            actions.push(this.userGroupService.removeMembers(this.formGroup.controls.groupsToRemove.value, this.userId));
        }

        this.formGroup.disable();

        actions.push(this.customerUserService.delete(this.customerId, this.userId));

        this.handlingUser = true;

        forkJoin(actions).subscribe({
            next: () => {
                this.dialogRef.close(true);
            },
            error: () => {
                this.handlingUser = false;
                this.getData();
                this.formGroup.enable();
            },
        });
    }
}
