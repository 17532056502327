// @ts-nocheck
import { pick } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { Model } from '../../shared/angularjs/model';
module('eaw.vacation').factory('OffTime', [ 'EawResource', 'Pagination', function OffTimeClass(EawResource, Pagination) {
    const empUrl = '/customers/:customer/employees/:employee/off_times/:offTime';
    const cusUrl = '/customers/:customer/off_times';
    return class OffTime extends Model {
        to;
        from;
        customer_id;
        employee_id;
        constructor(data) {
            data.customer_id = data.customer_id || data.employee?.customer_id;
            data.approved = data.approval?.approved;
            super(data);
        }

        _set(obj, prop, value) {
            switch (prop) {
                case 'customer':
                    obj.customer_id = value?.id;
                    break;
                case 'employee':
                    obj.employee_id = value?.id;
                    // recursive; "this" is a Proxy with this method as "set"
                    // @ts-ignore
                    this.set(obj, 'customer', value?.customer);
                    break;
                case 'approval':
                    obj.approved = value?.approved;
                    break;
            }
            return super._set(obj, prop, value);
        }

        get length() {
            return moment.duration(this.to?.diff(this.from)).asDays();
        }

        create() {
            return OffTime.create(this);
        }

        approve(approved, options = {}) {
            return this.update({
                approved,
                ...options,
            });
        }

        update(args = {}) {
            return OffTime.update({
                customer_id: this.customer_id,
                employee_id: this.employee_id,
                off_time_id: this.id,
                ...this.getModified(),
                ...args,
            });
        }

        delete() {
            return OffTime.delete({
                customer_id: this.customer_id,
                employee_id: this.employee_id,
                off_time_id: this.id,
            });
        }

        /**
         * @param {Object} args
         * @param {integer} args.customer_id
         * @param {integer} args.employee_id
         * @param {integer} args.off_time_id
         * @param {Array} [args.with]
         * @return {Promise<OffTime>}
         */
        static get(args) {
            return EawResource.create(empUrl).get({
                customer: args.customer_id,
                employee: args.employee_id,
                offTime: args.off_time_id,
                'with[]': args.with,
            }).$promise.then((data) => {
                data.customer_id = args.customer_id;
                return new OffTime(data);
            });
        }

        static getForCustomerGroup(args) {
            return EawResource.create('/customer_groups/:group_id/off_times').get({
                ...Pagination.getParams(args, [ 'group_id', 'from', 'to', 'handled', 'approved' ]),
                group_id: args.groupId,
            }).$promise.then((resp) => {
                resp.data = resp.data.map((o) => new OffTime(o));
                return resp;
            });
        }

        /**
         * @param {Object} args
         * @param {integer} args.customer_id
         * @param {integer} args.employee_id
         * @param {integer} args.off_time_id
         * @param {Array} [args.with]
         * @return {Promise<Pagination>|{data: OffTime[]}}
         */
        static getAll(args) {
            const resource = args.employee_id ? EawResource.create(empUrl) : EawResource.create(cusUrl);
            return resource.get({
                customer: args.customer_id,
                employee: args.employee_id,
                ...Pagination.getParams(args, [ 'approved', 'handled', 'from', 'to', 'exclude_declined' ]),
            }).$promise.then((resp) => {
                resp.data = resp.data.map((data) => {
                    data.customer_id = args.customer_id;
                    return new OffTime(data);
                });
                return new Pagination(resp);
            });
        }

        /**
         * @param args
         * @return {Promise<OffTime>}
         */
        static create(args) {
            return EawResource.create(empUrl).save({
                customer: args.customer_id,
                employee: args.employee_id,
            }, {
                ...pick(args, [ 'from', 'to', 'vacation', 'comment', 'approved', 'action' ]),
                days: args.days ? -args.days : undefined,
                schedule_published_warning_accepted: true,
            }).$promise.then((data) => {
                data.customer_id = args.customer_id;
                return new OffTime(data);
            });
        }

        /**
         * @param args
         * @return {Promise<OffTime>}
         */
        static update(args) {
            return EawResource.create(empUrl).update({
                customer: args.customer_id,
                employee: args.employee_id,
                offTime: args.off_time_id,
            }, {
                ...pick(args, [ 'from', 'to', 'vacation', 'comment', 'approved', 'action' ]),
                days: args.days ? -args.days : undefined,
                schedule_published_warning_accepted: true,
            }).$promise.then((data) => {
                data.customer_id = args.customer_id;
                return new OffTime(data);
            });
        }

        /**
         * @param args
         * @return {Promise}
         */
        static delete(args) {
            return EawResource.create(empUrl).delete({
                customer: args.customer_id,
                employee: args.employee_id,
                offTime: args.off_time_id,
            }).$promise;
        }

        static override getMorphClass() {
            return 'off_time';
        }
    };
} ]);
