import { module } from 'angular';
import { Namespace } from '../shared/enums/namespace';
import { DashboardComponent } from '../dashboard-new/pages/dashboard/dashboard.component';
import { createState } from '../shared/utils/create-state';
import { currentResolver } from '../shared/resolvers/current.resolver';
import { LearningModuleModule } from '../learning-module/types/modules';

module('eaw.dashboard', [
    'ew-endless-list',
    'eaw.misc',
    'eaw.scheduling',
    'eaw.payroll',
    'eaw.login',
    'eaw.kpi',
]).config([ '$stateProvider', function config($stateProvider: any) {
    // TODO Delete this route once the new dashboard is ready
    $stateProvider.state('eaw/app/home', {
        parent: 'eaw/app',
        url: '/dashboard',
        views: {
            'content@': 'eawDashboard',
        },
        data: {
            learningModuleModules: [ LearningModuleModule.Dashboard ],
            i18nextNs: [ Namespace.KPITypes, Namespace.KPI, Namespace.Widgets, Namespace.Balances, Namespace.Newsletter ],
            breadcrumb: { key: 'DASHBOARD', ns: 'navigation' },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/dashboard',
        parent: 'eaw/app',
        url: '/dashboard/beta',
        views: {
            'content@': {
                component: DashboardComponent,
            },
        },
        resolve: [
            currentResolver('userId', 'user', 'id'),
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            i18nextNs: [ Namespace.Widgets ],
            learningModuleModules: [ LearningModuleModule.Dashboard ],
            permissions: [ 'dashboard_beta' ],
            breadcrumb: { key: 'DASHBOARD_BETA', ns: 'navigation' },
        },
    });
} ]);
