import { ApiResponse } from '../../shared/interfaces/api-response';
import { NewsletterIssue, NewsletterIssueResponse } from './newsletter-issue';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { BaseApiModel } from '../../shared/models/base-api-model';

export type NewsletterLanguage = {
    code: string;
    description: string;
    file: string;
    title: string;
}

export type NewsletterLanguages = Record<string, NewsletterLanguage>;

export interface NewsletterResponse extends ApiResponse {
    created_at: string;
    customer_id: number;
    default_description: string | null;
    default_title: string;
    deleted_at: string | null;
    id: number;
    language_code: string;
    languages: NewsletterLanguages
    newsletter_issues: NewsletterIssueResponse[];
}

export class Newsletter extends BaseApiModel<NewsletterResponse, Newsletter> {
    createdAt: DateTime;
    customerId: number;
    defaultDescription: string | null;
    defaultTitle: string;
    deleted_at: DateTime | null;
    id: number;
    languageCode: string;
    languages: NewsletterLanguages;
    issues: NewsletterIssue[];

    constructor(data: NewsletterResponse) {
        super(data, undefined);

        this.createdAt = stringToDateTime(data.created_at);
        this.customerId = data.customer_id;
        this.defaultDescription = data.default_description;
        this.defaultTitle = data.default_title;
        this.deleted_at = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.id = data.id;
        this.languageCode = data.language_code;
        this.languages = data.languages;
        this.issues = data.newsletter_issues.map((issue) => new NewsletterIssue(issue));
    }

    getMostRecentIssue(includeFuture = false) {
        const issues = includeFuture ? this.issues : this.issues.filter((issue) => issue.publishDate && (issue.publishDate <= DateTime.now()));
        return issues
            .filter((i) => i.publishDate)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .sort((a, b) => b.publishDate!.toMillis() - a.publishDate!.toMillis())[0];
    }

    getTitle(languageCode: string) {
        return this.languages[languageCode]?.title || this.defaultTitle;
    }

    getDescription(languageCode: string) {
        return this.languages[languageCode]?.description || this.defaultDescription;
    }

    getLanguageCode(languageCode?: string) {
        if (languageCode && languageCode in this.languages) {
            return languageCode;
        }

        return this.languageCode;
    }
}
