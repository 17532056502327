// @ts-nocheck
import { module } from 'angular';
module('eaw.filesystem').factory('fileAclFactory', [ 'EawResource', function(EawResource) {
    const fac = this;
    fac.directory = EawResource.create('/customers/:customer/fs_directories/:id/acl/:entry/:morph');
    fac.file = EawResource.create('/customers/:customer/fs_files/:id/acl/:entry/:morph');
    fac.getEntries = (customerId, item) => fac[item.type].get({
        customer: customerId,
        id: item.id,
    }).$promise;
    fac.updateAcl = (customerId, item, inherits, replace) => fac[item.type].update({
        customer: customerId,
        id: item.id,
    }, {
        inherits,
        replace,
    }).$promise;
    fac.getControllables = (customerId, item, filter) => fac[item.type].get({
        customer: customerId,
        id: item.id,
        entry: 'controllables',
        filter: filter || undefined,
    }).$promise;
    fac.addControllable = (customerId, item, controllable, action, allowed) => fac[item.type].save({
        customer: customerId,
        id: item.id,
        entry: controllable.morph_class,
        morph: controllable.id,
    }, {
        action,
        allowed,
    }).$promise;
    fac.updateEntry = (customerId, item, controllable, action, allowed) => fac[item.type].update({
        customer: customerId,
        id: item.id,
        entry: controllable.morph_class,
        morph: controllable.id,
    }, {
        action,
        allowed,
    }).$promise;
    fac.removeControllable = (customerId, item, controllable) => fac[item.type].delete({
        customer: customerId,
        id: item.id,
        entry: controllable.morph_class,
        morph: controllable.id,
    }).$promise;
    return fac;
} ]);
