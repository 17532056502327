<mat-card>
    <mat-toolbar class="tw-p-8 tw-flex-row tw-justify-between">
        {{ 'NOTIFICATION_plural' | translate | async }}
        <mat-slide-toggle [formControl]="includeRead">
           {{ 'INCLUDE_READ' | translate:'notifications' | async }}
        </mat-slide-toggle>
    </mat-toolbar>
    @if (!loading()) {
        <mat-card-content id="notification-list">
            @for (item of notifications(); track item.notification.id) {
                <div class="notification tw-flex tw-flex-row tw-items-center"
                     [class.unread]="!item.notification.readAt">
                    <div class="tw-flex tw-flex-col tw-flex-1">
                        <span class="notification-title">{{ item.notification.title }}</span>
                        @if (item.notification.title !== item.notification.body) {
                            <span class="notification-body">{{ item.notification.body }}</span>
                        }

                        <div class="tw-mt-4">
                            <small class="notification-time">
                                {{ item.notification.createdAt | DateTime: 'DATE_SHORT' }}
                            </small>
                            <small>{{ item.customerName }}</small>
                            @if ((item.access | async) === false) {
                                <small class="no-access"> {{ 'NO_ACCESS_TO_LOCATION' | translate:'notifications'| async }} </small>
                            }
                        </div>
                    </div>

                    <div class="tw-flex tw-flex-row buttons">
                        @if (item.notification.url && (item.access | async)) {
                            <a [href]="item.notification.url" mat-icon-button>
                                <mat-icon>open_in_browser</mat-icon>
                            </a>
                        }
                        <button mat-icon-button type="button" class="read-status" [class.reading]="item.marking"
                                (click)="markAsRead(item)">
                                <mat-icon [eawOutlined]="!!item.notification.readAt">
                                    circle
                                </mat-icon>

                        </button>
                    </div>
                </div>
            }
        </mat-card-content>
        @if (notifications().length === 0) {
            <mat-card-content id="no-notification-data" class="tw-flex tw-justify-center tw-items-center tw-w-full">
                <span>{{ 'NO_DATA' | translate | async }}</span>
            </mat-card-content>
        }
    } @else {
        <mat-card-content class="loading tw-flex tw-justify-center tw-items-center tw-w-full">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </mat-card-content>
    }
    <mat-card-footer>
        <eaw-paginator
                [pageIndex]="index()"
                [total]="totalNotifications()"
                [perPage]="perPage()"
                (paginationChange)="loadPage($event)"/>
    </mat-card-footer>
</mat-card>
