<div class="tw-p-10 tw-flex tw-flex-col tw-justify-around">
    <div *ngIf="loadingDay" class="tw-flex tw-flex-row tw-justify-center tw-items-center">
        <mat-progress-spinner diameter="28" strokeWidth="3" mode="indeterminate"></mat-progress-spinner>
    </div>
    <div *ngIf="!loadingDay">
        <button mat-icon-button (click)="getBudget()">
            <mat-icon>refresh</mat-icon>
        </button>
        <span *ngIf="summary?.createdAt">
            {{ lastUpdatedSummary[businessDate] }}<br>
        </span>

        <button mat-icon-button disabled></button>
        <span *ngIf="!summary || !summary.createdAt || summary.lastDispatch && summary.createdAt && summary.createdAt < summary.lastDispatch">
            {{ pleaseWaitSummary[businessDate] }}
        </span>
    </div>
    <ng-container [ngTemplateOutlet]="budgetDay" [ngTemplateOutletContext]="{day: summary?.days?.get(businessDate)}" *ngIf="!loadingDay && summary?.days?.get(businessDate)"></ng-container>
</div>

<div class="tw-flex tw-flex-col tw-p-10">
    <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
        <div>
            <h4>
                {{'WEEK_N' | translate: 'general': { number: week } | async}}
            </h4>
            <h5 [hidden]="loading">
                <span *ngIf="totals[weekNr]?.from">
                    {{ totals[weekNr]!.from | DateTime: 'DATE_MED' }}
                </span>
                -
                <span *ngIf="totals[weekNr]?.to">
                    {{ totals[weekNr]!.to | DateTime: 'DATE_MED' }}
                </span>
            </h5>
        </div>

        <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <button [hidden]="loading" mat-icon-button (click)="onRefreshWeek()">
                <mat-icon>refresh</mat-icon>
            </button>
            <h6>
                <span *ngIf="lastUpdatedTotal[weekNr]">
                    {{ lastUpdatedTotal[weekNr] }}
                </span>
                <span *ngIf="!lastUpdatedTotal[weekNr]">
                    {{ pleaseWaitTotal[weekNr] }}
                </span>
            </h6>
        </div>
    </div>
    <div class="tw-p-10 tw-flex tw-flex-col tw-justify-around" *ngIf="totals?.[weekNr]?.summary?.sums">
        <ng-container *ngIf="totals[weekNr]?.summary?.sums" [ngTemplateOutlet]="budgetDay" [ngTemplateOutletContext]="{day: totals[weekNr]!.summary?.sums}"></ng-container>
    </div>
</div>

<div *ngIf="loading" class="tw-flex tw-flex-row tw-justify-center tw-items-center">
    <mat-progress-spinner diameter="28" strokeWidth="3" mode="indeterminate"></mat-progress-spinner>
</div>

<ng-template #budgetDay let-day="day">
    <div class="budget-info-row">
        <span class="row-text">
            {{'BUDGET' | translate: 'scheduling' | async}}
        </span>
        <span class="row-value">
            {{day.budget | eawNumber:0}}
        </span>
    </div>
    <div class="budget-info-row">
        <span class="row-text">
            {{'EFFICIENCY' | translate: 'scheduling' | async}}
        </span>
        <span class="row-value">
            {{day.efficiency | eawNumber: 0}}
        </span>
    </div>
    <div class="budget-info-row">
        <span class="row-text">
            {{'MAN_HOURS' | translate: 'scheduling' | async}}
        </span>
        <span class="row-value">
            {{(day.work_time / 3600) | eawNumber:2}}
        </span>
    </div>
    <div class="budget-info-row">
        <span class="row-text">
            {{'LABOR_COST' | translate: 'scheduling' | async}}
        </span>
        <span class="row-value">
            {{day.labor_cost | eawNumber: 2}}
        </span>
    </div>
    <div class="budget-info-row">
        <span class="row-text">
            {{'LABOR_PERCENT' | translate: 'scheduling' | async}}
        </span>
        <span class="row-value">
            {{day.labor_ratio | eawPercent:2}}
        </span>
    </div>
    <div class="budget-info-row">
        <span class="row-text">
            {{'ESTIMATED_NEED' | translate: 'scheduling' | async}}
        </span>
        <span class="row-value">
            {{day.need  | roundToFraction | eawNumber: 2}}
        </span>
    </div>
    <div class="budget-info-row">
        <span class="row-text">
            {{'STAFFING_DIFFERENCE' | translate: 'scheduling' | async}}
        </span>
        <span class="row-value">
            {{day.need_diff  | roundToFraction | eawNumber: 2}}
        </span>
    </div>
</ng-template>
