import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from './confirm-dialog.component';
import { DialogSize } from '../dialog-component';
import { TranslateService } from '../../services/translate.service';

type DeleteOptions = Partial<ConfirmDialogData> & {
    // Name of the item/thing you want to delete
    name?: string
}

@Injectable({
    providedIn: 'root',
})
export class ConfirmDialogService {

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(TranslateService) private translate: TranslateService,
    ) {
    }

    open(options: ConfirmDialogData) {
        return this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                size: DialogSize.Small,
                ...options,
            },
        });
    }

    delete(options: DeleteOptions = {}) {
        return this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                size: DialogSize.Small,
                title: options.title || this.translate.t('DELETE'),
                text: options.text || this.translate.t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_X', 'general', { x: options.name ?? '' }),
                confirmText: options.confirmText || this.translate.t('DELETE'),
                cancelText: options.cancelText || this.translate.t('CANCEL'),
                comment: options.comment,
                confirmColor: 'red-500',
                ...options,
            },
        });
    }
}
