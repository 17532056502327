import { ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnInit,
    signal } from '@angular/core';
import { TodoStatusService } from '../../http/todo-status.service';
import { TodoStatusType } from '../../enums/todo-status-type';
import { TodoStatus } from '../../models/todo-status';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { EMPTY, switchMap, tap } from 'rxjs';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';

@Component({
    selector: 'eaw-todo-status-list',
    templateUrl: './todo-status-list.component.html',
    styleUrl: './todo-status-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        NgFor,
        NgIf,
        MaterialColorDirective,
        AsyncPipe,
        TranslatePipe,
        PermissionDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoStatusListComponent implements OnInit {
    private readonly translate = inject(TranslateService);
    private readonly todoStatusService = inject(TodoStatusService);
    private readonly promptDialogService = inject(PromptDialogService);
    private readonly confirmDialogService = inject(ConfirmDialogService);

    @Input({ required: true }) customerId!: number;

    canCreate?: string[];
    showHistoricalDefaults = signal(false);
    statuses = signal(new Map<TodoStatusType, TodoStatus[]>());

    ngOnInit(): void {
        this.canCreate = [ `customers.${this.customerId}.todo_statuses.create` ];

        this.getStatuses().subscribe();
    }

    canUpdate(statusId: number) {
        return [ `customers.${this.customerId}.todo_statuses.${statusId}.update` ];
    }

    canDelete(statusId: number) {
        return [ `customers.${this.customerId}.todo_statuses.${statusId}.delete` ];
    }

    getStatuses() {
        return this.todoStatusService.getAll(this.customerId).pipe(
            switchMap(async (statusResponse) => {
                const statusMap = new Map<TodoStatusType, TodoStatus[]>([
                    [ TodoStatusType.Default, [] ],
                    [ TodoStatusType.Initial, [] ],
                    [ TodoStatusType.InProgress, [] ],
                    [ TodoStatusType.Done, [] ],
                ]);

                statusResponse.data.forEach((todoStatus) => {
                    statusMap.get(todoStatus.type)?.push(todoStatus);
                });

                for (const value of statusMap.values()) {
                    value.sort((a, b) => b.itemsCount - a.itemsCount);
                }

                return statusMap;
            }),
            tap((statusMap) => this.statuses.set(statusMap)),
        );
    }

    create(type: keyof typeof TodoStatusType) {
        let translationKey = '';

        switch (type) {
            case 'Default': {
                translationKey = 'CREATE_DEFAULT_STATUS_TEXT';
                break;
            }
            case 'InProgress': {
                translationKey = 'CREATE_INPROGRESS_STATUS_TEXT';
                break;
            }
            case 'Done': {
                translationKey = 'CREATE_DONE_STATUS_TEXT';
                break;
            }
        }

        this.promptDialogService.open('text', {
            formControl: new FormControl<string>('', {
                nonNullable: true,
                validators: Validators.required,
            }),
            title: this.translate.t('CREATE_STATUS', 'todo'),
            text: this.translate.t(translationKey, 'todo'),
            label: this.translate.t('NAME'),
            confirmText: this.translate.t('CREATE'),
        }).afterClosed().pipe(
            switchMap((name) => {
                if (!name) {
                    return EMPTY;
                }

                return this.todoStatusService.create(this.customerId, name, TodoStatusType[type]);
            }),
            switchMap(() => this.getStatuses()),
        ).subscribe();
    }

    edit(todoStatus: TodoStatus) {
        this.promptDialogService.open('text', {
            formControl: new FormControl<string>(todoStatus.name, {
                nonNullable: true,
                validators: Validators.required,
            }),
            title: this.translate.t('EDIT_TODO_STATUS', 'todo'),
            label: this.translate.t('NAME'),
            confirmText: this.translate.t('CREATE'),
        }).afterClosed().pipe(
            switchMap((name) => {
                if (!name) {
                    return EMPTY;
                }

                return this.todoStatusService.update(this.customerId, todoStatus.id, name);
            }),
            switchMap(() => this.getStatuses()),
        ).subscribe();
    }

    delete(todoStatus: TodoStatus) {
        if (todoStatus.itemsCount) {
            return;
        }

        this.confirmDialogService.delete({
            title: this.translate.t('DELETE_TODO_STATUS', 'todo'),
            text: this.translate.t('DELETE_TODO_STATUS_TEXT', 'todo', { name: todoStatus.name }),
        }).afterClosed().pipe(
            switchMap((res) => {
                if (!res?.ok) {
                    return EMPTY;
                }

                return this.todoStatusService.delete(this.customerId, todoStatus.id);
            }),
            switchMap(() => this.getStatuses()),
        ).subscribe();
    }

    getStatusesFor(type: keyof typeof TodoStatusType): TodoStatus[] {
        if (type === 'Initial' && !this.showHistoricalDefaults()) {
            return [];
        }

        return this.statuses().get(TodoStatusType[type]) || [];
    }
}
