@if (loadingData) {
    <eaw-info-loading size="sm">
        {{'LOADING_AVAIL_POSITIONS' | translate:'new_employee' | async}}
    </eaw-info-loading>
} @else {
    @if (!canGetPositions) {
        <eaw-info-box>
            <span infoText>{{'CANT_GET_POS' | translate:'new_employee' | async}}</span>
        </eaw-info-box>
    } @else if (!canAddPositionToEmployee) {
        <eaw-info-box>
            <span infoText>{{'CANT_ASSIGN_POS' | translate:'new_employee' | async}}</span>
        </eaw-info-box>
    } @else {
        @if (positions.length) {
            <eaw-info-box>
                <span infoText>{{'CHOOSE_POS' | translate:'new_employee' | async}}</span>
            </eaw-info-box>

            <mat-selection-list multiple >
                @for (position of positions; track position.id) {
                    <mat-list-option [selected]="selectedPositions.has(position.id)" [disabled]="processingPositions.has(position.id)" (click)="togglePosition(position)">{{position.name}}</mat-list-option>
                }
            </mat-selection-list>
        } @else {
            <eaw-info-box>
                <span infoText>{{'NO_POS' | translate:'new_employee' | async}}</span>
            </eaw-info-box>
        }
    }
}

<eaw-new-employee-next-step [stepper]="stepper" [step]="step"/>
