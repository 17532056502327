import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../shared/utils/format-http-params';
import { Observable } from 'rxjs';
import { SignatureProviderType } from '../hr/enums/SignatureProviderType';

export interface SignatureProvider {
    accepts_external: false;
    class: SignatureProviderType;
    description: string;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class DigitalSignatureService {
    private readonly http = inject(HttpClient);

    getAll(): Observable<SignatureProvider[]> {
        return this.http.get<SignatureProvider[]>('/signature_providers', {
            params: formatHttpParams({
                return_array: true,
            }),
        });
    }

    getAvailable(customerId: number, filter?: string): Observable<SignatureProvider[]> {
        return this.http.get<SignatureProvider[]>(`/customers/${customerId}/signature_providers`, {
            params: formatHttpParams({ filter }),
        });
    }
}
