import { AfterViewInit, ChangeDetectionStrategy, Component, computed, effect, ElementRef, inject, input, Signal, signal, viewChild, WritableSignal } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { Widget } from '../../classes/widget';
import { DateTime } from 'luxon';
import { EmployeeTimepunchSummary, TimepunchService } from '../../../payroll/http/timepunch.service';
import { BusinessDate } from '../../../shared/utils/business-date';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HighchartsService } from '../../../shared/services/highcharts.service';
import { Chart, Series } from 'highcharts';
import { TranslateService } from '../../../shared/services/translate.service';
import { Namespace } from '../../../shared/enums/namespace';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { catchError, EMPTY, tap } from 'rxjs';

@Component({
    selector: 'eaw-timepunch-widget',
    standalone: true,
    imports: [
        MatIcon,
        MatButtonModule,
        TranslatePipe,
        AsyncPipe,
    ],
    templateUrl: './timepunch-widget.component.html',
    styleUrl: './timepunch-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimepunchWidgetComponent extends WidgetComponent implements AfterViewInit {
    private readonly numberFormatterService = inject(NumberFormatterService);
    private readonly highchartsService = inject(HighchartsService);
    private readonly timepunchService = inject(TimepunchService);
    private readonly translateService = inject(TranslateService);

    chartEl = viewChild.required<ElementRef<HTMLDivElement>>('chart');

    widget = input.required<Widget>();

    from = signal(DateTime.now().startOf('week'));
    to = signal(DateTime.now().endOf('week'));
    summary: WritableSignal<(EmployeeTimepunchSummary | null)[]> = signal([]);
    plannedSecondsSum: Signal<string>;
    punchedSecondsSum: Signal<string>;
    changing = false;
    chart?: Chart;
    plannedSeries?: Series;
    punchedSeries?: Series;

    constructor() {
        super();

        /**
         * Update data and dates when summary changes
         */
        effect(async () => {
            const data = this.summary();
            const week = this.from().weekNumber;
            const year = this.from().weekYear;

            this.chart?.xAxis[0]?.setCategories(data.map((d) => d?.date.toLocaleString(DateTime.DATE_SHORT) || ''));

            if (this.plannedSeries && this.punchedSeries) {
                this.plannedSeries.setData(data.map((s) => (s?.planned || 0) / 3600));
                this.punchedSeries.setData(data.map((s) => (s?.punched || 0) / 3600));
            }

            this.chart?.title.update({
                text: await this.translateService.t('WEEK_AND_YEAR', Namespace.General, { week, year }),
            });
        });

        this.plannedSecondsSum = computed(() => this.formatSeconds('planned'));
        this.punchedSecondsSum = computed(() => this.formatSeconds('punched'));
    }

    async ngAfterViewInit() {
        await this.createChart();

        this.getData(this.from(), this.to()).subscribe(() => {
            this.setLoading(false);
        });
    }

    formatSeconds(type: keyof Pick<EmployeeTimepunchSummary, 'planned' | 'punched'>) {
        const hours = this.summary().reduce((acc, s) => acc + (s?.[type] || 0), 0) / 3600;
        return this.numberFormatterService.formatDecimal(hours, 2);
    }

    getData(from: DateTime, to: DateTime) {
        this.changing = true;
        return this.timepunchService.getEmployeeSummary(
            this.widget().info.customer.id,
            this.widget().info.employee?.id || 0,
            new BusinessDate(from),
            new BusinessDate(to),
        ).pipe(
            catchError(() => {
                this.summary.set([]);
                this.changing = false;
                return EMPTY;
            }),
            tap((summary) => {
                this.summary.set(summary);
                this.changing = false;
            }),
        );
    }

    changeWeek(change: 1 | -1) {
        this.from.update((x) => x.plus({ week: change }));
        this.to.update((x) => x.plus({ week: change }));

        this.getData(this.from(), this.to()).subscribe();
    }

    async createChart() {
        const secondFormatter = (value: number) => this.numberFormatterService.formatDecimal(value, 2);

        this.chart = await this.highchartsService.create(this.chartEl().nativeElement, 'column', false, {
            chart: {
                type: 'column',
            },
            legend: {
                enabled: true,
            },
            tooltip: {
                shared: true,
                formatter() {
                    let text = `<strong>${this.x}</strong>`;

                    this.points?.forEach((p) => {
                        text += `<br/>${p.series.name}: ${secondFormatter(p.y || 0)}`;
                    });

                    return text;
                },
            },
            xAxis: {
                categories: [ '1', '2', '3', '4', '5', '6', '7' ],
                type: 'datetime',
            },
            yAxis: {
                min: 0,
                title: {
                    text: await this.translateService.t('HOUR_plural'),
                },
            },
            title: {
                align: 'center',
                text: await this.translateService.t('WEEK_AND_YEAR', Namespace.General, {
                    week: this.from().weekNumber,
                    year: this.from().weekYear,
                }),
            },
        });

        this.plannedSeries = this.chart?.addSeries({
            type: 'column',
            name: await this.translateService.t('PLANNED', Namespace.Widgets),
            data: [] as number[],
        });

        this.punchedSeries = this.chart?.addSeries({
            type: 'column',
            name: await this.translateService.t('PUNCHED', Namespace.Widgets),
            data: [] as number[],
        });
    }
}
