import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../../../shared/interfaces/pagination-options';
import { HttpClient } from '@angular/common/http';
import { classifyArrayPaginatedResponse, classifyItem } from '../../../../shared/utils/rxjs/classify';
import { Permission, PermissionResponse } from '../../../../shared/models/permission';
import { ArrayPaginatedResponse } from '../../../../shared/interfaces/paginated-response';

@Injectable({
    providedIn: 'root',
})
export class UserPermissionService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(userId: number, options: PaginationOptions = {}) {
        return this.http.get<ArrayPaginatedResponse<PermissionResponse>>(`/users/${userId}/permissions`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(Permission));
    }

    create(userId: number, node: string, value: boolean) {
        return this.http.post<PermissionResponse>(`/users/${userId}/permissions`, {
            node,
            value,
        }).pipe(classifyItem(Permission));
    }

    update(userId: number, node: string, value: boolean) {
        return this.http.put<PermissionResponse>(`/users/${userId}/permissions/${node}`, {
            value,
        }).pipe(classifyItem(Permission));
    }

    delete(userId: number, node: string) {
        return this.http.delete<undefined>(`/users/${userId}/permissions/${node}`);
    }
}
