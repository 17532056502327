import { DateTime } from 'luxon';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BaseApiModel } from '../../shared/models/base-api-model';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiModel } from '../../shared/enums/api-model';

export interface EmployeeGroupsResponse extends ApiResponse {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    members_count?: number;
}

export class EmployeeGroup extends BaseApiModel<EmployeeGroupsResponse, EmployeeGroup> {
    id: number;
    name: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;
    membersCount?: number;

    constructor(data: EmployeeGroupsResponse) {
        super(data, ApiModel.Employee);

        this.id = data.id;
        this.name = data.name;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.membersCount = data.members_count;
    }
}
