import { pick } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { EawUrl } from '../shared/angularjs/modules/resource/url.service';
import { PaginationOld } from '../shared/utils/pagination-old';
import { EawResourceFactory } from '../shared/angularjs/modules/resource/resource.service';
import { UserCustomer } from '../shared/models/user-customer';

module('eaw.login').factory('UserService', [ 'EawResource', 'FileService', '$http', function(EawResource: EawResourceFactory, FileService: any, $http: angular.IHttpService) {
    // @ts-ignore
    const userService = this;
    const url = '/users/:user/:customers/:groups';
    userService.avatarCache = {};
    userService.getAll = (args = { fields: [] }) => {
        return EawResource.create(url).get({
            'fields[]': args.fields,
            ...PaginationOld.getParams(args),
        });
    };
    userService.getAllForCustomerGroup = (groupId: any, pagination: any) => EawResource.create('/customer_groups/:groupId/users').get({
        groupId,
        ...PaginationOld.getParams(pagination, [ 'with_memberships', 'include_inactive' ]),
    });

    userService.getCustomers = (args: any) => {
        return EawResource.create(url).get({
            user: args.user.id,
            customers: 'customers',
            ...PaginationOld.getParams(args),
        }).$promise.then((resp: any) => {
            resp.data = resp.data.map((cust: any) => {
                return new UserCustomer({
                    ...cust,
                    created_at: cust.created_at?.format('YYYY-MM-DD HH:mm:ss') ?? null,
                    updated_at: cust.updated_at?.format('YYYY-MM-DD HH:mm:ss') ?? null,
                    pivot: {
                        ...cust.pivot,
                        from: cust.pivot.from?.format('YYYY-MM-DD HH:mm:ss') ?? null,
                        to: cust.pivot.to?.format('YYYY-MM-DD HH:mm:ss') ?? null,
                    },
                });
            });
            return resp;
        });
    };

    /**
     * Create a new user
     * @param data - Info about the user
     * @param data.first_name
     * @param data.last_name
     * @param data.email
     * @param data.phone
     * @param data.language_code
     * @param data.country_code
     */
    userService.create = (data: any) => {
        return EawResource.create(url).save({}, pick(data, [ 'first_name', 'last_name', 'country_code', 'language_code', 'phone', 'email' ]));
    };
    userService.getById = (id: any, args: any) => {
        return EawResource.create(url).get({
            user: id,
            'with[]': args?.with,
        });
    };
    userService.getByEmail = (email: any, reportError: any) => {
        reportError = reportError || false;
        let config;
        if (reportError) {
            config = {
                get: {
                    method: 'GET',
                    headers: {
                        'X-Ignore-Error': true,
                    },
                },
            };
        }
        const emailRes = EawResource.create('/users/:user', {}, config);
        return emailRes.get({
            user: email,
        }).$promise;
    };
    userService.forgotPassword = (email: any) => EawResource.create(url).get({
        user: 'reset_password',
        email,
    });
    userService.resetPassword = (email: any, password: any, passwordConfirmed: any, token: any) => EawResource.create(url).save({
        user: 'reset_password',
        email,
        password,
        password_confirmed: passwordConfirmed,
        token,
    });
    /**
     * @param {int} id
     * @param {object} user
     */
    userService.updateById = (id: any, user: any) => EawResource.create(url).update({
        user: id,
    }, pick(user, [ 'first_name', 'last_name', 'email', 'country_code', 'phone', 'language_code', 'password', 'password_confirmation' ]));
    userService.getGroups = (data: any) => EawResource.create(url).get({
        user: data.userId,
        groups: 'groups',
        ...PaginationOld.getParams(data, [ 'include_inactive' ]),
    });
    userService.getPicture = (userId: any, thumb: any) => {
        if (!userService.avatarCache[userId]) {
            userService.avatarCache[userId] = FileService.request(`${EawUrl.url}/users/${userId}/picture`, 'get', thumb ? { thumb } : null);
        }
        return userService.avatarCache[userId];
    };
    userService.getDnd = (userId: any) => $http.get(`${EawUrl.url}/users/${userId}/dnd`).then((resp) => convertDndToMoment(resp.data));
    function convertDndToMoment(dnd: any) {
        return !Object.keys(dnd || []).length ? {} : {
            from: moment(dnd[0], 'HH:mm'),
            to: moment(dnd[1], 'HH:mm'),
        };
    }
    /**
     *
     * @param {integer} userId
     * @param {Moment} from
     * @param {Moment} to
     */
    userService.setDnd = (userId: any, from: any, to: any) => {
        const data = {
            from: from.format('HH:mm'),
            to: to.format('HH:mm'),
        };
        return $http.put(`${EawUrl.url}/users/${userId}/dnd`, data).then((resp) => convertDndToMoment(resp.data));
    };
    userService.deleteDnd = (userId: any) => EawResource.create(url).delete({
        user: userId,
        customers: 'dnd',
    }).$promise;
    return userService;
} ]);
