import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';

type VacationDayType = 'automatic' | 'manual' | 'vacation';

export interface VacationDayResponse extends ApiResponse {
    created_at: string;
    delta: number;
    description?: string | null;
    effective: string;
    employee_id: number;
    id: number;
    off_time_id?: number | null;
    performed_by_name?: string | null;
    type: VacationDayType;
    updated_at: string;
}

export class VacationDay {
    createdAt: DateTime;
    delta: number;
    description?: string | null;
    effective: DateTime;
    employeeId: number;
    id: number;
    offTimeId?: number | null;
    performedByName?: string | null;
    type: VacationDayType;
    updatedAt: DateTime;

    constructor(data: VacationDayResponse) {
        this.delta = data.delta;
        this.description = data.description;
        this.effective = stringToDateTime(data.effective);
        this.employeeId = data.employee_id;
        this.id = data.id;
        this.offTimeId = data.off_time_id;
        this.performedByName = data.performed_by_name;
        this.type = data.type;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
    }
}
