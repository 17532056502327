import { module } from 'angular';
import { Namespace } from '../../shared/enums/namespace';
import { FilesComponent } from '../pages/files/files.component';
import { HrDefaultFilesComponent } from '../pages/hr-default-files/hr-default-files.component';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { CurrentOld } from '../../shared/angularjs/current.factory';
import { Products } from '../../shared/enums/products';
import { CustomerProductService } from '../../shared/http/customer-product.service';
import { firstValueFrom } from 'rxjs';
import { ApiModelClass } from '../../shared/enums/api-model';
import { createState } from '../../shared/utils/create-state';
import { LearningModuleModule } from '../../learning-module/types/modules';
import { DataTableQueryParams } from '../../data-table/data-table.component';
import { HrFiletypesComponent } from '../pages/hr-filetypes/hr-filetypes.component';

const signablesResolve = [ 'CustomerProductServiceDowngrade', 'HrFileType', 'customer', 'signableService', async function(CustomerProductServiceDowngrade: CustomerProductService, HrFileType: any, customer: any, signableService: any) {
    const hasDigitalSigning = await firstValueFrom(CustomerProductServiceDowngrade.hasProducts(customer.id, [ Products.DigitalSigning ]));
    if (!hasDigitalSigning) {
        return [];
    }

    return signableService.getAll.query(customer.id, { per_page: 100 }, ApiModelClass.HrFile).then((resp: any) => {
        HrFileType.setSignables(resp.data);
        return resp.data;
    }).catch((e: any) => e);
} ];

module('eaw.hr', [
    'ngMaterial',
    // other eaw.modules
    'eaw.resource',
    'eaw.misc',
    // submodules
    'eaw.hr.sign',
]).config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: `eaw/app/hr`,
        parent: 'eaw/app',
        url: '/hr',
        abstract: true,
        data: {
            i18nextNs: [ Namespace.Hr, Namespace.Digisign, Namespace.Company ],
            learningModuleModules: [ LearningModuleModule.HumanResources ],
            products: [ Products.HumanResources ],
            permissions: [ 'customers.{customer}.employees.{employee}.hr_files.*.get' ],
            breadcrumb: { key: 'HR', ns: 'navigation' },
        },
    });

    $stateProvider.state(`eaw/app/hr/files`, {
        parent: `eaw/app/hr`,
        url: '/files',
        views: {
            'content@': {
                component: FilesComponent,
            },
        },
        data: {
            breadcrumb: { key: 'HR_FILES', ns: 'navigation' },
            somePermissions: [
                `customers.{customer}.employees.*.hr_files.*.get`,
                `customers.{customer}.hr_files.manage`,
            ],
        },
        resolve: [
            currentResolver('customer', 'customer'),
        ],
    });

    createState($stateProvider, {
        name: 'eaw/app/hr/filetypes',
        parent: `eaw/app/hr`,
        url: '/filetypes',
        views: {
            'content@': { component: HrFiletypesComponent },
        },
        data: {
            breadcrumb: { key: 'HR_FILETYPES', ns: 'navigation' },
            permissions: [ `customers.{customer}.hr_file_types.create` ],
            queryParams: [ ...DataTableQueryParams ],
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('stackId', 'customer', 'stackId'),
        ]
    })

    $stateProvider.state(`eaw/app/hr/my_files`, {
        parent: `eaw/app/hr`,
        url: '/my_files',
        views: {
            'content@': 'hrEmployeeFiles',
        },
        data: {
            requiresEmployee: true,
            permissions: [ `customers.{customer}.employees.{employee}.hr_files.*.get` ],
            breadcrumb: { key: 'HR_MY_FILES', ns: 'navigation' },
        },
        resolve: {
            signables: signablesResolve,
            fileState: function() {
                return `eaw/app/hr/my_files/file`;
            },
            customer: [ function() {
                return CurrentOld.customer;
            } ],
            hrEmp: [ 'EmployeeService', 'employee', 'customer', function(EmployeeService: any, employee: any, customer: any) {
                return EmployeeService.get(customer, employee.id);
            } ],
        },
    });
    $stateProvider.state(`eaw/app/hr/emp_files`, {
        parent: `eaw/app/hr/files`,
        url: '/:emp/files',
        params: {
            customer_id: null, // Optional in order to overwrite current customer
        },
        data: {
            breadcrumb: { key: 'EMPLOYEE' },
            permissions: [ `customers.{customer}.employees.{emp}.hr_files.*.get` ],
        },
        views: {
            'content@': 'hrEmployeeFiles',
        },
        resolve: {
            signables: signablesResolve,
            fileState: function() {
                return `eaw/app/hr/emp_file`;
            },
            customer: [ '$transition$', function($transition$: any) {
                return CurrentOld.getCustomer($transition$.params().customer_id) || CurrentOld.customer;
            } ],
            hrEmp: [ '$transition$', 'EmployeeService', 'customer', function($transition$: any, EmployeeService: any, customer: any) {
                return EmployeeService.get(customer, $transition$.params().emp);
            } ],
        },
    });
    $stateProvider.state(`eaw/app/hr/emp_file`, {
        parent: `eaw/app/hr/emp_files`,
        url: '/:file',
        data: {
            breadcrumb: { key: 'FILE', ns: 'hr' },
        },
        views: {
            'content@': 'hrEmployeeFile',
        },
        resolve: {
            file: [ '$transition$', 'HrFactory', 'HrFile', 'customer', 'employee', function($transition$: any, HrFactory: any, HrFile: any, customer: any, employee: any) {
                const id = $transition$.params().emp || employee?.id;
                return HrFactory.getEmployeeFile(customer.id, id, $transition$.params().file, {
                    with: [ 'attachments', 'attachments.user', 'logs', 'logs.user', 'type', 'employee' ],
                }).$promise.then((response: any) => new HrFile(response, customer));
            } ],
        },
    });
    $stateProvider.state(`eaw/app/hr/my_files/file`, {
        parent: `eaw/app/hr/my_files`,
        url: '/:file',
        data: {
            breadcrumb: { key: 'FILE', ns: 'hr' },
        },
        views: {
            'content@': 'hrEmployeeFile',
        },
        resolve: {
            file: [ '$transition$', 'HrFactory', 'HrFile', 'customer', 'employee', function($transition$: any, HrFactory: any, HrFile: any, customer: any, employee: any) {
                const id = $transition$.params().emp || employee?.id;
                return HrFactory.getEmployeeFile(customer.id, id, $transition$.params().file, {
                    with: [ 'attachments', 'attachments.user', 'logs', 'logs.user', 'type', 'employee' ],
                }).$promise.then((response: any) => new HrFile(response, customer));
            } ],
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/hr/default_files',
        parent: 'eaw/app/hr',
        url: '/default_files',
        data: {
            queryParams: [ ...DataTableQueryParams, 'search', 'filetypeFilter' ],
            breadcrumb: { key: 'DEFAULT_HR_FILES', ns: 'navigation' },
            products: [ Products.Onboarding ],
            permissions: [ `customers.{customer}.default_hr_files.*.get`, `customers.{customer}.default_hr_files.create` ],
        },
        views: {
            'content@': {
                component: HrDefaultFilesComponent,
            },
        },
    });
} ]);
