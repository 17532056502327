import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Holiday, HolidayResponse } from '../models/holiday';
import { classifyArrayPaginatedResponse } from '../utils/rxjs/classify';
import { PaginationOptions } from '../interfaces/pagination-options';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../utils/format-http-params';

@Injectable({
    providedIn: 'root',
})
export class HolidayService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getForCustomer(customerId: number, from: DateTime, to: DateTime, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<HolidayResponse>>(`customers/${customerId}/holidays`, {
            params: formatHttpParams({
                ...options,
                from,
                to,
            }, [ 'from', 'to' ]),
        }).pipe(classifyArrayPaginatedResponse(Holiday));
    }

    get(from: DateTime, to: DateTime, countryCode?: string, regionId?: number, options?: PaginationOptions) {
        const url = regionId ? `countries/${countryCode}/regions/${regionId}/holidays` : `countries/${countryCode}/holidays`;
        return this.http.get<ArrayPaginatedResponse<HolidayResponse>>(url, {
            params: formatHttpParams({
                ...options,
                from,
                to,
            }, [ 'from', 'to' ]),
        }).pipe(classifyArrayPaginatedResponse(Holiday));
    }
}
