// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.calendar').factory('CalendarEventFactory', [ 'EawResource', 'colorpickerService', 'Pagination', function(EawResource, colorpickerService, Pagination) {
    const fac = this;
    const res = EawResource.create('/customers/:customer_id/calendar_events/:event_id');
    /**
     * @param {object} args
     * @param {string[]} args.with
     * @param {object} args.customer
     * @param {int} args.customer.id
     * @param {moment} args.from
     * @param {moment} args.to
     * @param {boolean | int} args.period
     * @param {string} args.filter search string
     * @param {string[]} args.fields which fields to search
     * @param {boolean} useResource
     */
    fac.getAll = (args, useResource) => {
        const allRes = res.get({
            customer_id: CurrentOld.customer.id,
            from: args.from,
            to: args.to,
            'with[]': args.with ? args.with : undefined,
            ...Pagination.getParams(args),
            ...pick(args, [ 'fields', 'period', 'color' ]),
        });
        return useResource ? allRes : allRes.$promise;
    };
    fac.create = (customerId, from, to, name, description, color) => res.save({
        customer_id: customerId,
    }, {
        from,
        to,
        color: colorpickerService.getHex(color),
        name,
        description,
    }).$promise;
    fac.update = (ids, from, to, name, description, color) => res.update(pick(ids, [ 'customer_id', 'event_id' ]), {
        from,
        to,
        color: colorpickerService.getHex(color),
        name,
        description,
    }).$promise;
    fac.delete = (customerId, id) => res.delete({
        customer_id: customerId,
        event_id: id,
    }).$promise;
    return fac;
} ]);
