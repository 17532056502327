import { Component, Inject, OnInit } from '@angular/core';
import { CustomerService } from '../../../shared/http/customer.service';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { PermissionSetService } from '../../../shared/http/permission-set.service';
import { PermissionSet } from '../../../shared/models/permission-set';
import { UserGroup } from '../../../company/models/user-group';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, merge } from 'rxjs';
import { Customer } from '../../../shared/models/customer';
import { CustomerGroup } from '../../../shared/models/customer-group';
import { groupBy, uniqBy } from 'lodash-es';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { UIRouter } from '@uirouter/core';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLineModule, MatOptionModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { loadNamespaces } from 'i18next';
import { Namespace } from '../../../shared/enums/namespace';

interface NameGroupedUserGroups {
    name: string;
    howMany: number;
}

@Component({
    selector: 'eaw-permission-set-assignment',
    templateUrl: './permission-set-assignment.component.html',
    styleUrl: './permission-set-assignment.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        NgIf,
        MatProgressSpinnerModule,
        MatIconModule,
        MatListModule,
        NgFor,
        MatLineModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatOptionModule,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class PermissionSetAssignmentComponent implements OnInit {
    selectedPermissionSets: PermissionSet[] = [];
    permSetResults?: ArrayPaginatedResponse<PermissionSet>;
    selectedCustomers: (Customer | CustomerGroup)[] = [];
    customerSearchResult?: ArrayPaginatedResponse<Customer | CustomerGroup>;
    // Available user groups
    groups: UserGroup[] = [];
    filteredGroups: UserGroup[] = [];
    selectedGroups: UserGroup[] = [];
    // Available grouped groups
    groupedGroups: NameGroupedUserGroups[] = [];
    filteredGroupedGroups: NameGroupedUserGroups[] = [];
    selectedGroupedGroups: NameGroupedUserGroups[] = [];
    // Group user groups by name
    groupGroups = true;
    loadingPermSetSearch = false;
    loadingCustomerSearch = false;

    // Whether data is being submitted
    submittingSets = false;

    permissionSetSearch = new FormControl('', { nonNullable: true });
    customerSearch = new FormControl('', { nonNullable: true });
    // Search for individual customers if false, customer groups if true
    customerSearchToggle = new FormControl(false, { nonNullable: true });
    userGroupSearch = new FormControl('', { nonNullable: true });

    constructor(
        @Inject(CustomerService) private customerService: CustomerService,
        @Inject(CustomerGroupService) private customerGroupService: CustomerGroupService,
        @Inject(PermissionSetService) private permissionSetService: PermissionSetService,
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(SnackBarService) private snackbar: SnackBarService,
    ) {
        this.permissionSetSearch.valueChanges.pipe(debounceTime(1000)).subscribe(() => this.searchForPermissionSets());
        merge(this.customerSearch.valueChanges.pipe(debounceTime(1000)), this.customerSearchToggle.valueChanges).subscribe(() => this.searchForCustomers());
        this.userGroupSearch.valueChanges.subscribe(this.filterUserGroups.bind(this));

        this.searchForPermissionSets();
        this.searchForCustomers();
    }

    async ngOnInit() {
        await loadNamespaces(Namespace.Admin);
    }

    // Get permission sets, triggered by search input
    searchForPermissionSets(per_page = 10) {
        this.loadingPermSetSearch = true;
        this.permissionSetService.getAll({
            pagination: {
                order_by: 'name',
                direction: 'asc',
                per_page,
                'fields[]': [ 'name', 'description', 'product_name' ],
                filter: this.permissionSetSearch.value || undefined,
            },
        }).subscribe((resp) => this.handlePermissionSetSearchData(resp));
    }

    // Get customers or customer groups, triggered by search input
    searchForCustomers(per_page = 10) {
        this.loadingCustomerSearch = true;
        if (this.customerSearchToggle.value) {
            this.customerGroupService.getAll({
                order_by: 'name',
                direction: 'asc',
                per_page,
                'with[]': [ 'members.ownGroups' ],
                filter: this.customerSearch.value || undefined,
            }).subscribe((resp) => this.handleCustomerSearchData(resp));
        } else {
            this.customerService.getAll({
                order_by: 'name',
                direction: 'asc',
                per_page,
                'with[]': [ 'ownGroups' ],
                filter: this.customerSearch.value || undefined,
                'fields[]': [ 'name' ],
            }).subscribe((resp) => this.handleCustomerSearchData(resp));
        }
    }

    // Remove already selected sets from search results and sort by name
    private handlePermissionSetSearchData(resp: ArrayPaginatedResponse<PermissionSet>) {
        resp.data = resp.data.filter((set) => !this.selectedPermissionSets.find((s) => s.id === set.id));
        this.permSetResults = resp;
        this.permSetResults.data.sort((a, b) => a.name.localeCompare(b.name));
        this.loadingPermSetSearch = false;
    }

    // Remove already selected customers or customer groups from search results
    private handleCustomerSearchData(resp: ArrayPaginatedResponse<Customer | CustomerGroup>) {
        resp.data = resp.data.filter((c) => !this.selectedCustomers.find((cu) => cu instanceof c.constructor && c.id === cu.id));
        this.customerSearchResult = resp;
        this.loadingCustomerSearch = false;
    }

    // Tracking methods for ngFor

    trackById<Type extends { id: number }>(_: number, item: Type) {
        return item.id;
    }

    trackByName(_: number, item: NameGroupedUserGroups) {
        return item.name;
    }

    // Methods for selecting/unselecting items

    addPermissionSet(set: PermissionSet, index: number) {
        this.selectedPermissionSets.unshift(set);
        this.permSetResults?.data.splice(index, 1);
        if (this.permSetResults?.data.length === 0 && this.permSetResults.total > this.permSetResults.to) {
            this.searchForCustomers(this.permSetResults.to + 10);
        }
    }

    removePermissionSet(index: number) {
        this.selectedPermissionSets.splice(index, 1);
        this.searchForPermissionSets(this.permSetResults?.to);
    }

    addCustomer(customer: Customer | CustomerGroup, index: number) {
        this.selectedCustomers.unshift(customer);
        this.customerSearchResult?.data.splice(index, 1);
        if (this.customerSearchResult?.data.length === 0 && this.customerSearchResult.total > this.customerSearchResult.to) {
            this.searchForCustomers(this.customerSearchResult.to + 10);
        }

        this.getUserGroups();
    }

    removeCustomer(index: number) {
        this.selectedCustomers.splice(index, 1);
        this.searchForCustomers(this.customerSearchResult?.to);
        this.getUserGroups();
    }

    addUserGroup(selected: UserGroup | NameGroupedUserGroups) {
        if (this.groupGroups) {
            this.selectedGroupedGroups.unshift(selected as NameGroupedUserGroups);
        } else {
            this.selectedGroups.unshift(selected as UserGroup);
        }

        this.filterUserGroups();
    }

    removeUserGroup(index: number) {
        if (this.groupGroups) {
            this.selectedGroupedGroups.splice(index, 1);
        } else {
            this.selectedGroups.splice(index, 1);
        }

        this.filterUserGroups();
    }

    // Filter by search term, triggered by search input
    filterUserGroups() {
        const search = this.userGroupSearch.value.toLowerCase();
        this.filteredGroupedGroups = this.groupedGroups.filter((groups) =>
            !this.selectedGroupedGroups.find((g) => g.name === groups.name)
            && groups.name.toLowerCase().includes(search),
        );

        this.filteredGroups = this.groups.filter((group) =>
            !this.selectedGroups.find((g) => g.id === group.id)
            && (group.name.toLowerCase().includes(search) || group.owner?.name.toLowerCase().includes(search)),
        );
    }

    // Extract user groups from selected customers
    getUserGroups() {
        // Get groups, then sort
        this.groups = this.selectedCustomers.flatMap((customer) => {
            if (customer instanceof CustomerGroup) {
                return customer.members.flatMap((member) => {
                    member.userGroups?.forEach((group) => (group.owner = member));
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    return member.userGroups!;
                });
            } else {
                customer.userGroups?.forEach((group) => (group.owner = customer));
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return customer.userGroups!;
            }
        })
            .reverse().sort((a, b) => a.name.localeCompare(b.name));

        // Remove duplicate groups
        this.groups = uniqBy(this.groups, 'id');

        const grouped = groupBy(this.groups, 'name');
        this.groupedGroups = [];
        for (const name in grouped) {
            this.groupedGroups.push({
                name,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                howMany: grouped[name]!.length,
            });
        }
        this.groupedGroups.sort((a, b) => b.howMany - a.howMany);

        // Refresh selected groups to avoid keeping groups from removed customers
        this.selectedGroups = this.groups.filter((group) => this.selectedGroups.find((g) => g.id === group.id));
        this.selectedGroupedGroups = this.groupedGroups.filter((groups) => this.selectedGroupedGroups.find((g) => g.name === groups.name));

        this.filterUserGroups();
    }

    // Check if ready for submitting
    canSubmit(): boolean {
        return !!this.selectedPermissionSets.length && (this.groupGroups ? !!this.selectedGroupedGroups.length : !!this.selectedGroups.length) && !this.submittingSets;
    }

    // Get ids and/or names, submit and reload if successful
    submit() {
        this.submittingSets = true;
        const permissionSetIds = this.selectedPermissionSets.map((set) => set.id);

        const customerIds = this.selectedCustomers.flatMap((customer) => {
            if (customer instanceof CustomerGroup) {
                return customer.members.map((member) => member.id);
            } else {
                return customer.id;
            }
        });

        const userGroupIds = this.selectedGroups.map((group) => group.id);

        const userGroupNames = this.selectedGroupedGroups.map((group) => group.name);

        const body = this.groupGroups ? {
            set_ids: permissionSetIds,
            customer_ids: customerIds,
            group_names: userGroupNames,
        } : {
            set_ids: permissionSetIds,
            group_ids: userGroupIds,
        };

        this.permissionSetService.attachToManyUserGroups(body).subscribe({
            next: () => {
                this.snackbar.created();
                void this.uiRouter.stateService.reload();
            },
            error: () => (this.submittingSets = false),
        });
    }
}
