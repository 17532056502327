import { module } from 'angular';
import { PredefinedSettings } from '../../../../shared/enums/predefined-settings';

const template1 = `<punch-clock flex
             ng-if="$clock.loaded && $clock.hasSetting"
             employee="$clock.eawWidget.widget.employee">
</punch-clock>
`;

module('eaw.dashboard').component('punchClockWidget', {
    template: template1,
    require: {
        eawWidget: '^eawWidget',
    },
    controllerAs: '$clock',
    controller: [ 'CustomerSettings', function(CustomerSettings) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = async () => {
            ctrl.eawWidget.setLoading(true);

            try {
                ctrl.hasSetting = (await CustomerSettings.get(ctrl.eawWidget.widget.customer, PredefinedSettings.PayrollPersonalTpApp))?.[PredefinedSettings.PayrollPersonalTpApp] === '1';
            } catch (e) {
                console.error(e);
            }

            ctrl.loaded = true;
            ctrl.eawWidget.setLoading(false);
        };
    } ],
});
