<eaw-dialog-header>
    <span title>{{'STATUS' | translate | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <mat-selection-list [multiple]="false">
        <ng-container *ngFor="let type of statuses">
            <eaw-subheader>{{type.translation | async}}</eaw-subheader>
            <mat-list-option *ngFor="let status of type.statuses" [value]="status" (selectedChange)="dialogRef.close(status)">{{status.name}}</mat-list-option>
        </ng-container>
    </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
</mat-dialog-actions>
