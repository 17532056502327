// @ts-nocheck
import { module } from 'angular';

module('eaw.warnings').controller('extendedWarningInfoCtrl', [ 'mdPanelRef', function(mdPanelRef) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.loading = true;

        ctrl.warning.getCauses()
            .then(() => delete ctrl.loading)
            .catch(mdPanelRef.close);
    };
} ]);
