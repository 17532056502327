import { Resolvable } from '../types/resolvable';

/**
 * In most cases you should use the {@link resolver} to resolve, only use this if you have some other resolve dependency that you need
 *
 * ‼ Make sure the resolve function arguments matches the order of the dependencies
 * @deprecated
 * @see Resolver
 * @param token
 * @param deps
 * @param resolveFn
 * @constructor
 */
export function dependencyResolver(token: string, deps: string[], resolveFn: (...args: any[]) => any): Resolvable {
    return {
        token,
        deps,
        resolveFn,
    };
}
