<eaw-dialog-header>
    <span title>{{data.title | async}}</span>

    @if (data.text) {
        <span subtitle>{{data.text | async}}</span>
    }
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-24">
    @if (loading()) {
        <eaw-info-loading size="lg"/>
    } @else {
        <mat-selection-list [multiple]="data.multiple" (selectionChange)="selectionChange($event)" >
            @for (item of items(); track item) {
                <mat-list-option [value]="item" [selected]="isSelected(item)">
                    <div matListItemLine>{{data.itemText(item)}}</div>

                    @if (data.itemSubtext) {
                        <div matListItemLine>{{data.itemSubtext(item)}}</div>
                    }
                </mat-list-option>
            }
        </mat-selection-list>
    }
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="">{{data.cancelText || ('CANCEL' | translate) | async}}</button>
    <eaw-action-button [loading]="loading()" [disabled]="disabled()" (click)="close()">{{data.confirmText || ('CHOOSE' | translate) | async}}</eaw-action-button>
</mat-dialog-actions>
