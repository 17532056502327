<mat-card id="filter-card" *ngIf="showFilter">
    <mat-card-content>
        <form [formGroup]="filterGroup" class="tw-grid tw-grid-cols-12 tw-gap-8 tw-items-start" autocomplete="off">
            <mat-form-field class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 xl:tw-col-span-2">
                <mat-label>{{ 'APPROVAL' | translate | async }}</mat-label>
                <mat-select formControlName="approval">
                    <mat-option [value]="1">{{ 'APPROVED' | translateSync }}</mat-option>
                    <mat-option [value]="0">{{ 'UNAPPROVED' | translateSync }}</mat-option>
                    <mat-option [value]="2">{{ 'ALL' | translateSync }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 xl:tw-col-span-2">
                <mat-label>{{ 'GROUP_BY' | translate:'payroll' | async }}</mat-label>
                <mat-select formControlName="grouping">
                    <mat-option *ngFor="let option of groupingOptions" [value]="option.value">{{ option.text | translateSync: option.ns }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 xl:tw-col-span-2" eawDatePickerOptions>
                <mat-label>{{ 'ENTER_DATE_RANGE' | translate | async }}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="$any(picker)">
                    <input formControlName="from" matStartDate [placeholder]="'FROM' | translate | async">
                    <input formControlName="to" matEndDate [placeholder]="'TO' | translate | async">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <eaw-autocomplete formControlName="employee"
                              class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 xl:tw-col-span-2"
                              [options]="employeeAutocompleteService.options"
                              [getter]="employeeAutocompleteService.getter({
                                  customerId: customerId,
                                  includeExternal: true,
                                  from: filterGroup.controls.dateRange.value.from,
                                  to: filterGroup.controls.dateRange.value.to
                              })"
                              [setter]="employeeAutocompleteService.setter({customerId: customerId})">
            </eaw-autocomplete>

            <section class="tw-col-span-full sm:tw-col-span-2 tw-flex tw-flex-col tw-mb-12" id="view">
                <label for="view" class="f-700 tw-mb-4">{{ 'DISPLAY_OPTION_plural' | translate | async }}</label>
                <mat-checkbox eawCheckboxHelper *ngIf="showSubordinates" formControlName="onlyDirectSubordinates"><label>{{ 'ONLY_MY_EMPLOYEES_TIMEPUNCHES' | translate:'payroll' | async }}</label></mat-checkbox>
                <mat-checkbox eawCheckboxHelper formControlName="includeDeleted">{{ 'INCLUDE_DELETED_TIMEPUNCHES' | translate:'payroll' | async }}</mat-checkbox>
                <mat-checkbox eawCheckboxHelper formControlName="onlyDeleted">{{ 'ONLY_DELETED_TIMEPUNCHES' | translate:'payroll' | async }}</mat-checkbox>
                <mat-checkbox eawCheckboxHelper formControlName="onlyEdited">{{ 'ONLY_EDITED_TIMEPUNCHES' | translate:'payroll' | async }}</mat-checkbox>
            </section>
        </form>

        <div class="tw-flex tw-flex-row tw-gap-8">
            <button mat-raised-button color="primary" (click)="updateList()" [disabled]="filterGroup.pristine || filterGroup.invalid || filterGroup.disabled">{{ 'UPDATE' | translate | async }}</button>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="content-container">
    <mat-card-header class="tw-flex tw-flex-row tw-justify-between tw-items-center">
        <mat-card-title class="tw-flex tw-flex-row tw-justify-start tw-items-center">
            <span *ngIf="currentFilter?.approval === 0">{{ 'UNAPPROVED_TIMEPUNCHES' | translate:'payroll' | async }}</span>
            <span *ngIf="currentFilter?.approval === 1">{{ 'APPROVED_TIMEPUNCHES' | translate:'payroll' | async }}</span>
            <span *ngIf="currentFilter?.approval === 2">{{ 'ALL_TIMEPUNCHES' | translate:'payroll' | async }}</span>

            <div *ngIf="loading">
                <mat-progress-spinner class="tw-ml-12" mode="indeterminate" diameter="24" strokeWidth="3"></mat-progress-spinner>
            </div>
        </mat-card-title>
        <mat-card-subtitle *ngIf="loadedRange.from">
            {{ 'FROM_TO' | translate: undefined:{from: loadedRange.from | DateTime:'DATE_MED', to: loadedRange.to | DateTime:'DATE_MED'} | async }}
        </mat-card-subtitle>
        <mat-card-actions class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-8">
            <button class="sm:tw-hidden" mat-icon-button [disabled]="loading || !hasApprovable || !(timepunchTableComponent.isIndeterminate() || timepunchTableComponent.isAllChecked())" (click)="approveSelected()">
                <mat-icon>done_all</mat-icon>
            </button>

            <button class="max-sm:tw-hidden" mat-raised-button color="accent" [disabled]="loading || !hasApprovable || !(timepunchTableComponent.isIndeterminate() || timepunchTableComponent.isAllChecked())" (click)="approveSelected()">
                <mat-icon>done_all</mat-icon>
                {{ 'APPROVE_SELECTED' | translate: 'payroll' | async }}
            </button>

            <button mat-icon-button (click)="showFilter = !showFilter">
                <mat-icon>filter_alt</mat-icon>
            </button>

            <button mat-mini-fab color="accent" (click)="createTimepunch()">
                <mat-icon>add</mat-icon>
            </button>
        </mat-card-actions>
    </mat-card-header>

    <mat-card-content id="table-card">
        <eaw-timepunch-table #timepunchTableComponent
                             [rows]="rows"
                             (rowClick)="getData($event)"
                             (timepunchUpdated)="getData()"
                             (refreshRows)="getData()"
                             (adjustColumnsClick)="adjustColumns()"
                             [customerId]="customerId"
                             [columnsKeys]="columnsKeys"
                             [columnsData]="columnsData"
                             [balanceData]="balanceData"
                             [totals]="totals"
                             [balances]="balances"
                             [filter]="currentFilter">
        </eaw-timepunch-table>
    </mat-card-content>
</mat-card>
