import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { CustomerGroup, CustomerGroupResponse } from './customer-group';
import { UserGroup, UserGroupResponse } from '../../company/models/user-group';
import { Product, ProductResponse } from './product';
import { ApiResponse } from '../interfaces/api-response';
import { BaseApiModel } from './base-api-model';
import { CountryRegion, CountryRegionResponse } from './country-region';
import { Stack, StackResponse } from './stack';
import { Property, PropertyResponse } from './property';

export interface CustomerResponse extends ApiResponse {
    customer_groups?: CustomerGroupResponse[];
    id: number;
    stack_id: number;
    stack?: StackResponse;
    setting_group_id?: number | null;
    name: string;
    number: string;
    address1: string;
    address2?: string | null;
    postal_code: string;
    city: string;
    billing_contact?: string | null;
    billing_customer_id?: number | null;
    locale_code: string;
    time_zone: string;
    latitude?: number | null;
    longitude?: number | null;
    type: string;
    active: boolean;
    logo_id?: number | null;
    created_at?: string | null;
    sso_services: { name: string }[]
    updated_at?: string | null;
    properties?: PropertyResponse[];
    deleted_at?: string | null;
    organization_number?: string | null;
    own_groups?: UserGroupResponse[];
    region_id?: number | null;
    region?: CountryRegionResponse;
    language_code?: string | null;
    country_code?: string | null;
    currency?: string | null;
    products?: ProductResponse[];
    users_count?: number;
}

export class Customer extends BaseApiModel<CustomerResponse, Customer> {
    active: boolean;
    address1: string;
    address2?: string | null;
    billingCustomerId?: number | null;
    billingContact?: string | null;
    city: string | null;
    countryCode?: string;
    createdAt?: DateTime | null;
    currency?: string | null;
    deletedAt?: DateTime | null;
    id: number;
    languageCode?: string | null;
    latitude?: number | null;
    localeCode: string;
    logoId?: number | null;
    longitude?: number | null;
    name: string;
    shortName: string;
    number: string;
    organizationNumber?: string | null;
    postalCode: string;
    region: CountryRegion | undefined;
    regionId?: number;
    settingGroupId?: number;
    stack?: Stack;
    properties?: Property[];
    ssoServices?: { name: string }[];
    stackId: number;
    timeZone: string;
    type: string;
    updatedAt?: DateTime | null;
    userGroups?: UserGroup[];
    products: Product[];
    customerGroups?: CustomerGroup[];
    usersCount?: number;

    constructor(data: CustomerResponse) {
        super(data, undefined);

        this.active = data.active;
        this.address1 = data.address1;
        this.address2 = data.address2;
        this.billingCustomerId = data.billing_customer_id;
        this.billingContact = data.billing_contact;
        this.city = data.city;
        this.countryCode = data.country_code ?? undefined;
        this.currency = data.currency;
        this.id = data.id;
        this.languageCode = data.language_code;
        this.latitude = data.latitude;
        this.localeCode = data.locale_code;
        this.logoId = data.logo_id;
        this.longitude = data.longitude;
        this.name = data.name;
        this.shortName = data.name;
        this.number = data.number;
        this.organizationNumber = data.organization_number;
        this.postalCode = data.postal_code;
        this.city = data.city;
        this.region = data.region ? new CountryRegion(data.region) : undefined;
        this.regionId = data.region_id ?? undefined;
        this.settingGroupId = data.setting_group_id || undefined;
        this.stack = data.stack ? new Stack(data.stack) : undefined;
        this.stackId = data.stack_id;
        this.timeZone = data.time_zone;
        this.type = data.type;
        this.ssoServices = data.sso_services;
        this.userGroups = data.own_groups?.map((group) => new UserGroup(group));
        this.products = (data.products || []).map((product) => new Product(product));
        this.properties = data.properties ? data.properties.map((property) => new Property(property)) : undefined;
        this.usersCount = data.users_count;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;

        this.setShortName();

        this.customerGroups = data.customer_groups?.map((cg) => new CustomerGroup(cg));
    }

    /**
     * Set a short name for a customer in instances where all customers are prefixed with the same thing
     * @private
     */
    private setShortName() {
        // Handle McDonald's
        if (this.name.toLowerCase().startsWith(`mcdonald`)) {
            this.shortName = this.name.replace(/^mc\s?donald'?s\s?/i, '');
        }
    }
}
