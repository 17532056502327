import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { AsyncPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatError, MatInput } from '@angular/material/input';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { CustomerAutocompleteService } from '../../../shared/autocompletes/customer-autocomplete.service';
import { Customer } from '../../../shared/models/customer';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { DateTime } from 'luxon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CustomerTransferService } from '../../http/customer-transfer.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { FieldErrorComponent } from '../../../shared/components/field-error/field-error.component';
import { catchError, EMPTY } from 'rxjs';

export type CustomerTransferDialogData = DialogData;

@Component({
    selector: 'eaw-admin-add-customer-transfer-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        TranslatePipe,
        AsyncPipe,
        MatButton,
        MatDialogActions,
        MatDialogClose,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteComponent,
        MatIcon,
        DatePickerOptionsDirective,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatFormField,
        MatInput,
        MatLabel,
        MatSuffix,
        ActionButtonComponent,
        FieldErrorComponent,
        MatError,
    ],
    templateUrl: './admin-add-customer-transfer-dialog.component.html',
    styleUrl: './admin-add-customer-transfer-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminAddCustomerTransferDialogComponent extends DialogComponent<CustomerTransferDialogData> {
    protected customerAutocompleteService = inject(CustomerAutocompleteService);
    private customerTransferService = inject(CustomerTransferService);
    private translateService = inject(TranslateService);
    private snackBar = inject(SnackBarService);
    private destroyedRef = inject(DestroyRef);

    constructor() {
        super(undefined, undefined, DialogSize.Medium);
    }

    loading = signal(false);

    form = new FormGroup({
        customer: new FormControl<Customer | null>(null, Validators.required),
        transferCustomer: new FormControl<Customer | null>(null, Validators.required),
        effectiveDate: new FormControl<DateTime | null>(null, Validators.required),
    });

    transferCustomerOption = {
        ...this.customerAutocompleteService.options,
        label: this.translateService.t('TRANSFER_CUSTOMER', 'admin'),
    };

    customerFilter = (customer: Customer) =>
        customer.id === this.form.controls.customer.value?.id;

    transferCustomerFilter = (transferCustomer: Customer) =>
        transferCustomer.id === this.form.controls.transferCustomer.value?.id;

    submit(): void {
        const { customer, transferCustomer, effectiveDate } = this.form.value;
        if (customer instanceof Customer && transferCustomer instanceof Customer) {
            this.loading.set(true);
            this.customerTransferService.create({
                original_customer_id: customer.id,
                new_customer_id: transferCustomer.id,
                effective_date: effectiveDate?.toFormat('yyyy-MM-dd'),
            }).pipe(
                takeUntilDestroyed(this.destroyedRef),
                catchError(() => {
                    this.loading.set(false);
                    return EMPTY;
                }))
                .subscribe(() => {
                    this.loading.set(false);
                    this.snackBar.t('TRANSFER_CREATED', 'chainops');
                    this.dialogRef.close(true);
                });
        }
    }
}
