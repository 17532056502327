import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatActionList, MatListItem, MatListItemIcon } from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import { ExtendedWidgetInfo } from '../../../classes/extended-widget-info';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { UIRouter } from '@uirouter/core';

interface MessageAction {
    name: string;
    icon: string;
    route: string;
}

@Component({
    selector: 'eaw-mini-messages-widget-extended-info',
    standalone: true,
    imports: [
        AsyncPipe,
        TranslatePipe,
        MatActionList,
        MatIcon,
        MatListItem,
        MatListItemIcon,
    ],
    templateUrl: './mini-messages-widget-extended-info.component.html',
    styleUrl: './mini-messages-widget-extended-info.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniMessagesWidgetExtendedInfoComponent extends ExtendedWidgetInfo {
    protected uiRouter = inject(UIRouter);

    actions: MessageAction[] = [
        {
            name: 'NEW_MESSAGE',
            icon: 'message-plus',
            route: `eaw/app/messages/conversations/write`,
        },
        {
            name: 'INBOX',
            icon: 'move_to_inbox',
            route: `eaw/app/messages/inbox`,
        },
        {
            name: 'OUTBOX',
            icon: 'outbox',
            route: `eaw/app/messages/outbox`,
        },
    ];
}
