<eaw-dialog-header>
    <span title>{{'EDIT_EXTERNAL_EMPLOYEE' | translate:'company' | async}}</span>
    <span subtitle>{{externalEmployee?.employee?.name}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="loading" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content *ngIf="!loading">
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-16">
        <mat-form-field>
            <mat-label>{{'COST' | translate | async}}</mat-label>
            <input formControlName="cost" type="number" inputmode="numeric" min="0" matInput>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'NUMBER' | translate | async}}</mat-label>
            <input formControlName="number" type="number" inputmode="numeric" min="0" step="1" matInput>
        </mat-form-field>

        <eaw-date-time-range-input class="tw-flex tw-flex-col md:tw-flex-row tw-gap-16">
            <mat-form-field eawDateTimeFrom>
                <mat-label>{{'FROM' | translate | async}}</mat-label>
                <eaw-date-time-input formControlName="from"></eaw-date-time-input>
            </mat-form-field>

            <mat-form-field eawDateTimeTo>
                <mat-label>{{'TO' | translate | async}}</mat-label>
                <eaw-date-time-input formControlName="to"></eaw-date-time-input>
            </mat-form-field>
        </eaw-date-time-range-input>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{'CANCEL' | translate | async}}</button>
    <button mat-button mat-raised-button color="primary" [disabled]="form.invalid || form.disabled || form.pristine || !hasChanges" (click)="update()">{{'UPDATE' | translate | async}}</button>
</mat-dialog-actions>
