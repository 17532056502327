import { Component, inject, OnInit, signal } from '@angular/core';
import { CurrentService } from '../../../shared/services/current.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { PeriodLockService } from '../../../period-locks/http/period-lock.service';
import { DateTime } from 'luxon';
import { WidgetComponent } from '../../classes/widget-component';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';

@Component({
    selector: 'eaw-period-lock-widget',
    templateUrl: './period-lock-widget.component.html',
    styleUrl: './period-lock-widget.component.scss' ,
    standalone: true,
    imports: [ MiniWidgetContentComponent ],
})
export class PeriodLockWidgetComponent extends WidgetComponent implements OnInit {
    private periodLockService = inject(PeriodLockService);
    private current = inject(CurrentService);
    private translate = inject(TranslateService);

    title = signal(Promise.resolve(''));
    subtitle = signal(Promise.resolve(''));

    ngOnInit() {
        this.periodLockService.getForCustomer(this.current.getCustomer().id, {
            per_page: 1,
            order_by: 'created_at',
            direction: 'desc',
            'with[]': [ 'user' ],
        }).subscribe((periodLocks) => {
            this.setLoading(false);

            const lock = periodLocks.data[0];
            if (lock) {
                this.title.set(Promise.resolve(lock.to.toLocaleString(DateTime.DATE_FULL)));
                this.subtitle.set(Promise.resolve(lock.user?.name || ''));
            } else {
                this.title.set(this.translate.t('NO_PERIOD_LOCK_plural', 'payroll'));
            }
        });
    }
}
