import { Component, Inject, OnInit, signal } from '@angular/core';
import { InspectionService } from '../../http/inspection.service';
import { CurrentService } from '../../../shared/services/current.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { set, sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { Shift } from 'src/app/scheduling/models/shift';
import { Timepunch } from '../../../payroll/models/timepunch';
import { Employee } from '../../../shared/models/employee';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

interface Report {
    date: DateTime,
    shifts: Record<number, Shift[]>
    timepunches: Record<number, Timepunch[]>,
}

type EmployeeWithRecords = {
    id: number,
    name?: string | null,
    number?: string,
    shifts: Record<number, Shift[]>,
    timepunches: Record<number, Timepunch[]>,
}

@Component({
    selector: 'eaw-inspection',
    templateUrl: './inspection.component.html',
    styleUrl: './inspection.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatProgressSpinnerModule,
        NgFor,
        MatCardModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})

export class InspectionComponent implements OnInit {
    readonly locationName: string = this.current.getCustomer()?.name;
    readonly orgNumber: string|null|undefined = this.current.getCustomer().organizationNumber;
    reports: Report[] = [];
    employees: EmployeeWithRecords[] = [];
    buttons: PageHeaderButton[] = [
        new PageHeaderButton({
            click: this.print.bind(this),
            icon: 'print',
            menuText: signal(this.translate.t('PRINT')),
        }),
    ];

    constructor(
      @Inject(InspectionService) private inspectionService: InspectionService,
      @Inject(CurrentService) private current: CurrentService,
      @Inject(TranslateService) private translate: TranslateService,
    ) {}

    ngOnInit(): void {
        this.inspectionService.getInspection(this.current.getCustomer().id).subscribe((res) => {
            this.reports = res.reports;
            res.employees.forEach((employee: Employee) => {
                const emp: EmployeeWithRecords = {
                    name: employee.name,
                    id: employee.id,
                    shifts: {},
                    timepunches: {},
                };
                Object.entries(res.reports).reduce((acc, [ key, val ]: [string, Report]) => {
                    set(acc, 'timepunches.' + key, sortBy(val.timepunches?.[employee.id] || [], 'in'));
                    set(acc, 'shifts.' + key, sortBy(val.shifts?.[employee.id] || [], 'from'));
                    return acc;
                }, emp);
                this.employees.push(emp);
            });
        });
    }

    print() {
        window.print();
    }

}
