// @ts-nocheck
import { module } from 'angular';

module('eaw.components').component('eawAccordionPanel', {
    template: `<div class="panel-header md-subhead" ng-click="$accordionPanel.toggle($event)">
    <div ng-if="$accordionPanel.headerFilled" ng-transclude="header"></div>
    <div ng-if="$accordionPanel.titleFilled" ng-transclude="title"></div>

    <md-icon ng-show="$accordionPanel.showChevron" class="chevron" ng-bind="'expand_more'"></md-icon>
</div>
<div class="panel-content">
    <div ng-transclude="content"></div>

    <div class="content-loader" ng-if="$accordionPanel.onOpen && !$accordionPanel.opened">
        <md-progress-circular></md-progress-circular>
    </div>
</div>
`,
    controllerAs: '$accordionPanel',
    require: {
        accordion: '^eawAccordion',
    },
    bindings: {
        onOpen: '&?', // has to return a Promise
        onOpenCallback: '&?', // Called after opened
        toggled: '<?',
        bindOpen: '<?',
        showChevron: '<?',
        disable: '<?', // Cant be [disabled] cause that interacts with inputs
    },
    transclude: {
        header: '?panelHeader', // Any content u want
        title: '?panelTitle', // Just text
        content: 'panelContent', // Any content you want
    },
    controller: [ '$element', '$transclude', function($element, $transclude) {
        const ctrl = this;

        ctrl.$postLink = () => {
            ctrl.element = $element[0];
            ctrl.showChevron = ctrl.showChevron ?? true;
            ctrl.contentEl = ctrl.element.querySelector('.panel-content');
            ctrl.headerFilled = $transclude.isSlotFilled('header');
            ctrl.titleFilled = $transclude.isSlotFilled('title');

            if (ctrl.bindOpen) {
                ctrl.element.classList.add('bind-open');
            }

            if (typeof ctrl.toggled === 'boolean') {
                ctrl.toggle(null, ctrl.toggled);
            }
        };

        ctrl.$onChanges = (changes) => {
            ctrl.disable = changes.disable?.currentValue ?? ctrl.disable;
            ctrl.showChevron = changes.showChevron?.currentValue ?? ctrl.showChevron;

            if (typeof changes.toggled?.currentValue === 'boolean') {
                ctrl.toggle(null, changes.toggled.currentValue);
            }
        };

        ctrl.toggleHeight = () => {
            if (ctrl.toggled) {
                const child = ctrl.contentEl?.children[0];

                if (child) {
                    ctrl.resObs = new ResizeObserver(() => {
                        if (!ctrl.toggled) {
                            return;
                        }

                        ctrl.contentEl.style.maxHeight = `${ctrl.contentEl.scrollHeight}px`;
                    });

                    ctrl.resObs.observe(child);
                }
            } else {
                ctrl.resObs?.disconnect();
                ctrl.contentEl.style.maxHeight = null;
            }

            if (ctrl.bindOpen) {
                ctrl.contentEl.style.maxHeight = 'none';
            }
        };

        ctrl.toggle = (event, toggled, external) => {
            if (!ctrl.element) {
                return;
            }
            if (event && ctrl.disable) {
                return;
            }
            if (event && ctrl.bindOpen) {
                return;
            }

            ctrl.toggled = toggled ?? !ctrl.toggled;

            ctrl.toggleHeight();

            if (ctrl.toggled) {
                const hasOpenFunc = typeof ctrl.onOpen === 'function';
                const hasOpenCallback = typeof ctrl.onOpenCallback === 'function';
                ctrl.opened = ctrl.opened ?? !hasOpenFunc;

                if (hasOpenCallback) {
                    ctrl.onOpenCallback();
                }

                if (!ctrl.opened && hasOpenFunc) {
                    ctrl.onOpen()?.then?.(() => {
                        ctrl.opened = true;
                        ctrl.toggle(null, true);
                    });
                }

                ctrl.element.classList.add('toggled');
                ctrl.element.previousElementSibling?.classList.add('toggled-prev-sibling', 'm-b-15');
                ctrl.element.nextElementSibling?.classList.add('toggled-next-sibling');
            } else {
                ctrl.element.classList.remove('toggled');
                ctrl.element.previousElementSibling?.classList.remove('toggled-prev-sibling', 'toggled-next-sibling', 'm-b-15');
                ctrl.element.nextElementSibling?.classList.remove('toggled-prev-sibling', 'toggled-next-sibling');
            }

            if (!ctrl.accordion.multiToggle && !external) {
                ctrl.accordion.getPanels()
                    .filter((p) => p.el !== ctrl.element)
                    .forEach((p) => {
                        p.controller?.toggle(null, false, true);
                    });
            }
        };
    } ],
});
