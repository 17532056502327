// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-dialog>
    <eaw-dialog-header-old ng-if="cdvCtrl.product < 0" heading="'vacation:REMOVE_DAYS' | i18next" ></eaw-dialog-header-old>
    <eaw-dialog-header-old ng-if="cdvCtrl.product > 0" heading="'vacation:ADD_DAYS' | i18next" ></eaw-dialog-header-old>

    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="removeDaysForm" autocomplete="off" layout="column">
                <md-input-container class="md-block">
                    <label ng-i18next="AMOUNT"></label>
                    <input required type="number" step="1" name="days" min="1" ng-model="cdvCtrl.amount" max="365" />
                </md-input-container>

                <md-input-container>
                    <eaw-date-picker ng-model="cdvCtrl.start" min-date="cdvCtrl.minDate" ng-required="true"></eaw-date-picker>
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="COMMENT"></label>
                    <textarea required ng-model="cdvCtrl.comment" rows="2" md-select-on-focus></textarea>
                </md-input-container>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-click="cdvCtrl.submit()" ng-i18next="SUBMIT" ng-disabled="removeDaysForm.$invalid || cdvCtrl.submitting"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.vacation').factory('changeVacationDays', [ '$mdDialog', 'vacationDays', function($mdDialog, vacationDays) {
    return {
        open(employee, product) {
            return $mdDialog.show({
                template: template1,
                controllerAs: 'cdvCtrl',
                controller: function() {
                    const ctrl = this;

                    ctrl.$onInit = () => {
                        ctrl.minDate = moment();
                        ctrl.product = product;
                    };

                    ctrl.submit = () => {
                        ctrl.submitting = true;

                        const args = {
                            customer: ctrl.customer,
                            employee,
                            delta: ctrl.amount * ctrl.product,
                            start: ctrl.start,
                            comment: ctrl.comment,
                        };

                        vacationDays.create(args).$promise.then(() => {
                            $mdDialog.hide();
                        }).finally(() => {
                            ctrl.submitting = false;
                        });
                    };

                    ctrl.cancel = $mdDialog.cancel;
                },
            });
        },
    };
} ]);
