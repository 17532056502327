import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { DurationPipe } from '../../pipes/duration.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { DurationUnit } from 'luxon/src/duration';

@Component({
    selector: 'eaw-duration-input',
    standalone: true,
    imports: [ CommonModule, MatInputModule, ReactiveFormsModule, TranslatePipe ],
    templateUrl: './duration-input.component.html',
    styleUrl: './duration-input.component.scss' ,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ DurationPipe ],
})
export class DurationInputComponent implements OnChanges {
    @Input() from?: DateTime | null = null;
    @Input() to?: DateTime | null = null;
    @Input() units?: DurationUnit[];

    durationControl = new FormControl<string | null>('');

    constructor(
        @Inject(DurationPipe) public durationPipe: DurationPipe,
        @Inject(ChangeDetectorRef) public changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        const from = changes['from'] ? changes['from'].currentValue : this.from;
        const to = changes['to'] ? changes['to'].currentValue : this.to;

        if (from && to) {
            this.durationControl.setValue(this.durationPipe.transform(to.diff(from, 'seconds').as('seconds'), this.units || [ 'hours', 'minutes', 'seconds' ]));
        } else {
            this.durationControl.setValue('');
        }

        this.changeDetectorRef.markForCheck();
    }
}
