<form [formGroup]="form">
    <mat-form-field>
        <mat-icon matPrefix>key</mat-icon>
        <mat-label>{{'PASSWORD' | translate:'login' | async}}</mat-label>
        <input id="new_password" autocomplete="new-password" matInput [type]="showPwd ? 'text': 'password'" formControlName="password1" required (focus)="markAsTouched()">
        <mat-icon color="primary" matSuffix (click)="showPwd = !showPwd">{{showPwd ? 'visibility_off' : 'visibility' }}</mat-icon>
    </mat-form-field>

    <mat-form-field>
        <mat-icon matPrefix>key</mat-icon>
        <mat-label>{{'CONFIRM_PASSWORD' | translate:'login' | async}}</mat-label>
        <input id="new_password_confirm" autocomplete="new-password" [type]="showConfirmPwd ? 'text': 'password'" matInput formControlName="password2" required (focus)="markAsTouched()">
        <mat-icon color="primary" matSuffix (click)="showConfirmPwd = !showConfirmPwd">{{showConfirmPwd ? 'visibility_off' : 'visibility' }}</mat-icon>
    </mat-form-field>
</form>

<ul class="rules">
    <li [class.invalid]="!hasMinLength">
        <mat-icon>{{!hasMinLength ? 'close' : 'check'}}</mat-icon>
        {{'PASS_MIN_CHARS' | translate:'login': { number: minLength } | async}}
    </li>
    <li [class.invalid]="!hasLowercase">
        <mat-icon>{{!hasLowercase ? 'close' : 'check'}}</mat-icon>
        {{'PASS_LOWERCASE' | translate:'login' | async}}
    </li>
    <li [class.invalid]="!hasUppercase">
        <mat-icon>{{!hasUppercase ? 'close' : 'check'}}</mat-icon>
        {{'PASS_UPPERCASE' | translate:'login' | async}}
    </li>
    <li [class.invalid]="!hasNumber">
        <mat-icon>{{!hasNumber ? 'close' : 'check'}}</mat-icon>
        {{'PASS_NUMBER' | translate:'login' | async}}
    </li>
    <li [class.invalid]="!hasSpecial">
        <mat-icon>{{!hasSpecial ? 'close' : 'check'}}</mat-icon>
        {{'PASS_SPECIAL' | translate:'login' | async}}
    </li>
    <li [class.invalid]="!isEqual">
        <mat-icon>{{!isEqual ? 'close' : 'check'}}</mat-icon>
        {{'PASS_EQUAL' | translate:'login' | async}}
    </li>
</ul>
