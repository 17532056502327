import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { CustomerTransfer, CustomerTransferResponse } from '../models/customer-transfer';
import { Observable } from 'rxjs';

interface CreateCustomerTransfer {
    original_customer_id: number;
    new_customer_id: number;
    effective_date: string | undefined;
}

@Injectable({
    providedIn: 'root',
})
export class CustomerTransferService {
    http = inject(HttpClient);

    getAll(paginationOptions: PaginationOptions = {}): Observable<ArrayPaginatedResponse<CustomerTransfer>> {
        return this.http.get<ArrayPaginatedResponse<CustomerTransferResponse>>(`/customer_transfers`, {
            params: formatHttpParams(paginationOptions),
        }).pipe(classifyArrayPaginatedResponse(CustomerTransfer));
    }

    create(data: CreateCustomerTransfer): Observable<CustomerTransfer> {
        return this.http.post<CustomerTransferResponse>(`/customer_transfers`, data)
            .pipe(classifyItem(CustomerTransfer));
    }
}
