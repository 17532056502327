<eaw-dialog-header>
    <span title>{{'CHANGE_LANGUAGE' | translate | async}}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col">
    <div *ngFor="let language of languages" class="language" matRipple [class.selected]="language.code === selectedLanguage?.code" (click)="changeLanguage(language)">
        <img class="flag" [eawFlagSvg]="language.countryCode" alt="flag">
        <span>{{language.name}}</span>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="updating" (click)="submit()">{{'CHANGE_LANGUAGE' | translate | async}}</eaw-action-button>
</mat-dialog-actions>
