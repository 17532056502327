import { module } from 'angular';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { UpcomingBirthdaysWidgetSettingsComponent } from 'src/app/dashboard-new/components/upcoming-birthdays-widget/upcoming-birthdays-widget-settings/upcoming-birthdays-widget-settings.component';

module('eaw.dashboard').factory('upcomingBirthdaysWidgetSettingsDowngrade', [
    'MatDialogDowngrade',
    (MatDialogDowngrade: MatDialog) => function upcomingBirthdaysWidgetSettingsDowngrade(widget: any) {
        const data = { widgetInfo: { translationKey: 'UPCOMING_BIRTHDAYS', translationNs: 'widgets' }, settings: widget.settings };
        return firstValueFrom(MatDialogDowngrade.open(UpcomingBirthdaysWidgetSettingsComponent, { data }).afterClosed()).then((settings) => {
            if (!settings) {
                throw 'Cancelled';
            }
            return { ...widget.settings, ...settings };
        });
    },
]);
