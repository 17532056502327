// @ts-nocheck
import { t } from 'i18next';
import { orderBy } from 'lodash-es';
import { remove } from 'lodash-es';
import { module } from 'angular';
module('eaw.filesystem').service('Directory', [ 'FilesystemItem', 'FilesystemFile', function(FilesystemItem, FilesystemFile) {
    class Directory extends FilesystemItem {
        constructor(directory, parent) {
            super(directory, parent);
            this.name = directory.name.toLowerCase() === 'root' ? t('filesystem:ROOT') : directory.name;
            this.color = 'grey-700';
            this.directories = [];
            (directory?.directories || []).forEach((d) => this.addDirectory(new Directory(d)));
            this.files = [];
            (directory?.files || []).forEach((f) => this.addFile(new FilesystemFile(f)));
        }

        isRoot() {
            return !this.directory_id;
        }

        /**
         * @param {Directory} directory
         */
        addDirectory(directory) {
            this.directories.push(directory);
        }

        /**
         * @param {FilesystemFile} file
         */
        addFile(file) {
            this.files.push(file);
        }

        /**
         * @param {FilesystemItem} item
         */
        updateItem(item) {
            if (item.isDirectory()) {
                remove(this.directories, (x) => x.id === item.id);
                this.addDirectory(item);
            }
            if (item.isFile()) {
                remove(this.files, (x) => x.id === item.id);
                this.addFile(item);
            }
        }

        /**
         * @param {FilesystemItem} item
         */
        removeItem(item) {
            if (item.isDirectory()) {
                remove(this.directories, (x) => x.id === item.id);
            }
            if (item.isFile()) {
                remove(this.files, (x) => x.id === item.id);
            }
        }

        isEmpty() {
            return !(this.directories.length || this.files.length);
        }

        getAllItems() {
            return [].concat(this.files).concat(this.directories);
        }

        clearSelectedItem() {
            this.getAllItems().forEach((i) => {
                i.deselect();
            });
        }

        hasSelectedItem() {
            return this.getAllItems().some((i) => i.isSelected());
        }

        sort(field, direction) {
            field = field || 'name';
            direction = direction || 'asc';
            this.getAllItems().forEach(function(item) {
                item.setSortingFieldPrimary(field);
                item.setSortingFieldSecondary('name');
            });
            this.directories = orderBy(this.directories, [ '_sortingFieldPrimary', '_sortingFieldSecondary' ], [ direction, 'asc' ]);
            this.files = orderBy(this.files, [ '_sortingFieldPrimary', '_sortingFieldSecondary' ], [ direction, 'asc' ]);
        }
    }
    return Directory;
} ]);
