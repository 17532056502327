import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Shift, ShiftResponse } from '../models/shift';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { classifyArrayPaginatedResponse } from '../../shared/utils/rxjs/classify';

@Injectable({
    providedIn: 'root',
})
export class ScheduleShiftService {
    private readonly http = inject(HttpClient);

    getAll(customerId: number, scheduleId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<ShiftResponse>>(`/customers/${customerId}/schedules/${scheduleId}/shifts`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(Shift));
    }
}
