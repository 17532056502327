import { ApiResponse } from '../../shared/interfaces/api-response';
import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { NamespaceFile } from '../../shared/enums/namespace';

export interface ParsePayslipDryRunResultResponse extends ApiResponse {
    missing_status: Record<number, string>;
    found_employees: Record<number, { employee: EmployeeResponse, pages: number[] }>;
    mismatch_employees: Record<number, EmployeeResponse>;
}

type MissingStatus = {
    info: string;
    code: string;
    i18n: {
        key: string;
        ns: NamespaceFile;
    };
};

export class ParsePayslipDryRunResult {
    missingStatus: Map<number, MissingStatus>;
    foundEmployees: Map<number, Employee>;
    mismatchEmployees: Map<number, Employee>;

    constructor(data: ParsePayslipDryRunResultResponse) {
        this.foundEmployees = new Map(Object.entries(data.found_employees).map(([ num, e ]) => [ Number(num), new Employee(e.employee) ]));
        this.mismatchEmployees = new Map(Object.entries(data.mismatch_employees).map(([ num, e ]) => [ Number(num), new Employee(e) ]));
        this.missingStatus = new Map(Object.entries(data.missing_status).map(this.mapMissingStatus));
    }

    protected mapMissingStatus = ([ num, code ]: [string, string]): [number, MissingStatus] => {
        let info = '';
        const employee = this.mismatchEmployees.get(Number(num));
        if (employee) {
            switch (code) {
                case 'bank_account_mismatch':
                    info = employee.getCustomFieldValue('cf_bank_account') as string;
                    break;
                case 'address_mismatch':
                    info = [
                        employee.address1,
                        employee.address2,
                        employee.postalCode,
                        employee.city,
                    ].filter(Boolean).join(' ');
                    break;
            }

            if (info) {
                info = employee.name + ', ' + info;
            } else {
                info = employee.name || '';
            }
        }
        return [
            Number(num),
            {
                code,
                info,
                i18n: {
                    key: code.toUpperCase(),
                    ns: 'payslip_import',
                },
            },
        ];
    };
}
