import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'eaw-subheader',
    standalone: true,
    imports: [ CommonModule ],
    templateUrl: './subheader.component.html',
    styleUrl: './subheader.component.scss' ,
})
export class SubheaderComponent {
}
