<mat-card class="card" [class.tree]="hasParent()">
    <div class="tw-col-start-2 tw-col-span-3">{{ permission().node }}</div>
    <div class="tw-col-start-5 tw-col-span-1 tw-items-center tw-justify-items-center time">
        {{ permission().updatedAt ? (permission().updatedAt | DateTime: 'DATETIME_MED') : (permission().createdAt | DateTime: 'DATETIME_MED') }}
    </div>
    <div class="tw-col-start-6 tw-grid tw-grid-cols-3 tw-items-center tw-justify-items-center">
        @if (changingValue()) {
            <mat-progress-spinner class="tw-col-start-1"
                                  diameter="24"
                                  strokeWidth="3"
                                  mode="indeterminate">
            </mat-progress-spinner>
        } @else {
            <mat-icon [style.color]="permission().value ? 'green' : 'red'"
                      class="tw-col-start-1">{{ permission().value ? "done" : "close" }}
            </mat-icon>
        }
        @if (canUpdate()) {
            <button mat-icon-button
                    type="button"
                    [disabled]="disabled()"
                    (click)="changePermissionValue()"
                    matTooltip="{{ 'ALLOW_DISALLOW' | translate: 'permissions' | async }}">
                <mat-icon>swap_horiz</mat-icon>
            </button>
        } @else {
            <!-- Placeholder -->
            <button mat-icon-button type="button" disabled>
                <mat-icon></mat-icon>
            </button>
        }

        @if (canDelete()) {
            <button mat-icon-button type="button" color="warn" [disabled]="disabled()" (click)="delete()"
                    matTooltip="{{ 'DELETE_PERMISSION' | translate: 'permissions' | async }}">
                <mat-icon>delete</mat-icon>
            </button>
        }
    </div>
</mat-card>
