<eaw-page-header [useBigFilter]="true">
    <span title>{{'SHIFTS' | translate | async}}</span>

    <div class="filter-card" bigFilter>
        <form class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-x-16" [formGroup]="form">
            <eaw-autocomplete formControlName="employee"
                              [options]="employeeAutocompleteService.options"
                              [getter]="employeeAutocompleteService.getter({
                                  customerId: customerId,
                                  from: form.controls.range.controls.from.value,
                                  to: form.controls.range.controls.to.value,
                                  includeExternal: true,
                              })"
                              [setter]="employeeAutocompleteService.setter({customerId: customerId})">
            </eaw-autocomplete>

            <mat-form-field eawDatePickerOptions class="tw-w-full">
                <mat-label>{{'ENTER_DATE_RANGE' | translate | async}}</mat-label>
                <mat-date-range-input formGroupName="range" [rangePicker]="picker">
                    <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async" autocomplete="off">
                    <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async" autocomplete="off">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <div class="tw-row-start-3 sm:tw-row-start-4 tw-justify-self-start">
                <button class="tw-mr-16" mat-raised-button color="accent" [disabled]="form.invalid" (click)="updateTable({})">
                    {{'UPDATE' | translate | async}}
                </button>

                <button mat-raised-button (click)="resetForm()">
                    {{'RESET' | translate | async}}
                </button>
            </div>
        </form>
    </div>
</eaw-page-header>

<mat-card class="tw-p-0">
    <mat-card-content>
        <eaw-data-table
                initialSortBy="from"
                [request]="request"
                [columns]="columns"
                [goTo]="{state: 'eaw/app/scheduling/shifts/shift', params: [{stateKey: 'id', itemKey: 'id'}]}"
                (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
