import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { formatHttpParams } from '../utils/format-http-params';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { Stack, StackResponse } from '../models/stack';

@Injectable({
    providedIn: 'root',
})
export class StackService {
    constructor(@Inject(HttpClient) protected http: HttpClient) { }

    getAll(options?: PaginationOptions): Observable<ArrayPaginatedResponse<Stack>> {
        return this.http.get<ArrayPaginatedResponse<StackResponse>>(`/stacks`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(Stack));
    }

    get(stackId: number, options?: { with: string[] }): Observable<Stack> {
        return this.http.get<StackResponse>(`/stacks/${stackId}`, {
            params: formatHttpParams({
                'with[]': options?.with,
            }),
        }).pipe(classifyItem(Stack));
    }

    addStack(options: { name: string }): Observable<Stack> {
        return this.http.post<StackResponse>(`/stacks`, {
            ...options,
        }).pipe(classifyItem(Stack));
    }

    updateStack(stackId: number, options: { name?: string }): Observable<Stack> {
        return this.http.put<StackResponse>(`/stacks/${stackId}`, {
            ...options,
        }).pipe(classifyItem(Stack));
    }

    deleteStack(stackId: number) {
        return this.http.delete(`/stacks/${stackId}`);
    }
}
