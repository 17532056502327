// @ts-nocheck
import { module } from 'angular';

module('eaw.checklists').controller('changeItemTypeCtrl', [ '$mdDialog', 'ChecklistsService', function($mdDialog, ChecklistsService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.cancel = $mdDialog.cancel;

        // Get all ts and deselect all
        ctrl.checklistTypes = ChecklistsService.getTypes();
        ctrl.deselectAll();

        // Set selected on type we have
        ctrl.itemType = ctrl.item.type?.type || ctrl.item.type;
        ctrl.checklistTypes[ctrl.itemType].selected = true;
    };

    ctrl.deselectAll = () => {
        Object.keys(ctrl.checklistTypes).forEach((k) => {
            ctrl.checklistTypes[k].selected = false;
        });
    };

    ctrl.select = (type) => {
        // Deselect previous
        ctrl.deselectAll();

        // Set this as selected
        type.selected = true;

        // Update type and name of the type
        ctrl.item.type = type.type;
        ctrl.item.typeName = type.name;
        ctrl.item.typeAdvanced = type.advanced;

        // Get this type's default options or remove options by setting it as null
        ctrl.item.options = type.defaultOptions || null;
    };

    ctrl.update = () => {
        $mdDialog.hide(ctrl.item);
    };
} ]);
