import { t } from 'i18next';
import { module } from 'angular';
import { sort } from '../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
import { Moment } from 'moment-timezone';

module('eaw.scheduling').controller('shiftTooltipCtrl', function shiftTooltipCtrl() {
    // @ts-ignore
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.user = {
            id: ctrl.shift.employee?.user_id,
            name: ctrl.shift.employee?.name,
        };

        ctrl.fromTo = ctrl.setFromTo(ctrl.shift.from, ctrl.shift.to);
        ctrl.qualifications = ctrl.shift.all_qualifications;

        ctrl.periods = sort(ctrl.shift.periods, CurrentOld.languageTag, [ (p: any) => p.offset, (p: any) => p.length ], [ 'asc', 'asc' ]).map((p) => {
            return {
                ...p,
                fromTo: ctrl.setFromTo(p.from, p.to),
                description: p.description || t('NO_DESCRIPTION'),
            };
        });
        ctrl.warnings = ctrl.shift.warnings?.map((w: any) => {
            return {
                ...w,
                message: ctrl.getWarningMessage(w),
            };
        });
    };

    ctrl.getWarningMessage = (w: any) => {
        return t(`warnings:${w.message}`, w.message_parameters);
    };

    ctrl.setFromTo = (from: Moment, to: Moment) => {
        return t('FROM_TO', {
            from: from.format('LT'),
            to: to.format('LT'),
        });
    };
});
