// @ts-nocheck
import { module } from 'angular';
module('eaw.warnings').factory('WarningCause', function WarningCauseClass() {
    class WarningCause {
        type;
        constructor(data = {}) {
            Object.assign(this, data || {});
            this.type = data.cause_type;
        }
    }
    return WarningCause;
});
