<eaw-page-header [fabButton]="fabButton">
    <span title>{{ 'LANGUAGE_plural' | translate | async }}</span>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        <eaw-data-table
            #table
            [columns]="columns"
            [request]="request"
            (paginationChange)="updateTable($event)"/>
    </mat-card-content>
</mat-card>
