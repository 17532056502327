import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { TranslateService } from '../../services/translate.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { CheckboxHelperDirective } from '../../directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgFor, NgIf, AsyncPipe, JsonPipe } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { FormArray, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

export interface ErrorDialogData extends DialogData {
    response: HttpErrorResponse;
    request: HttpRequest<unknown>;
}

interface EawError {
    ignorable?: boolean;
    message: string;
}

export interface MultipleError {
    type: 'Multiple';
    error: Record<string, EawError>;
}

interface Error422 {
    type?: string;
    error: Record<string, string[]>;
}

interface DefaultError {
    error: string;
}

@Component({
    selector: 'eaw-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrl: './error-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgFor,
        NgIf,
        MatCheckboxModule,
        CheckboxHelperDirective,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        JsonPipe,
        TranslatePipe,
        ReactiveFormsModule,
    ],
})
export class ErrorDialogComponent extends DialogComponent implements OnInit {
    hashes: string[] = [];
    ignorable = false;
    messages: string[] = [];
    status!: number;
    exception?: string;
    url: string;
    showException: boolean;
    stackTrace: object;
    ignoreForm: FormArray<FormControl<boolean>>;

    protected error: HttpErrorResponse['error'];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ErrorDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ErrorDialogComponent>,
        @Inject(TranslateService) public translate: TranslateService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        this.error = data.response.error;
        // Only shown in development or staging environments
        this.showException = !!this.error?.stack;
        this.stackTrace = this.error?.stack;
        this.status = data.response.status;
        this.exception = this.error?.exception;
        this.url = data.request.url;
        this.ignoreForm = new FormArray([] as FormControl<boolean>[]);
    }

    async ngOnInit() {
        if (this.error?.type?.toLowerCase() === 'multiple') {
            this.handleMultiple();

            return;
        }

        this.ignoreForm.push(new FormControl(false, { validators: [ Validators.required ], nonNullable: true }));

        let message: Promise<string>;

        if (this.status == 422) {
            this.handle422();
            return;
        }

        const errorMessage: string | undefined = (this.error as DefaultError).error;

        if (errorMessage) {
            this.messages = [ errorMessage ];
            return;
        }

        switch (this.status) {
            case 401:
                message = this.getMessage('401_ERROR');
                break;
            case 405:
                message = this.getMessage('405_ERROR', {
                    method: this.data.request.method,
                    url: this.url,
                });
                break;
            case 403:
                message = this.getMessage('403_ERROR');
                break;
            case 404:
                message = this.getMessage('NOT_FOUND');
                break;
            case 409:
                message = this.getMessage('CONFLICT');
                break;
            case 500:
            default:
                message = this.getMessage('SOMETHING_WENT_WRONG');
                break;
        }

        this.messages = [ await message ];
    }

    async getMessage(key: string, options?: Record<string, string>): Promise<string> {
        return await this.translate.t(key, 'errors', options) as string;
    }

    handleMultiple(): void {
        const error = this.error as MultipleError;
        this.hashes = Object.keys(error.error);
        this.ignorable = Object.values(error.error).flat().every((e) => e.ignorable);
        this.messages = Object.values(error.error).flat().map((e) => e.message);

        for (let i = 0; i < this.messages.length; i++) {
            this.ignoreForm.push(new FormControl(false, { validators: [ Validators.required ], nonNullable: true }));
        }
    }

    handle422() {
        this.messages = typeof this.error?.error === 'string' ? [ this.error.error ] : Object.values(this.error as Error422).flat();
    }

    resend() {
        this.dialogRef.close(this.ignorable ? this.hashes : undefined);
    }
}
