import { Component, Inject, Input, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { User } from '../../../shared/models/user';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { MatDialog } from '@angular/material/dialog';
import { ConnectUserAndEmployeeComponent, ConnectUserAndEmployeeData } from '../../dialogs/connect-user-and-employee/connect-user-and-employee.component';
import { Employee } from '../../../shared/models/employee';
import { EMPTY, map, of, shareReplay, switchMap, tap } from 'rxjs';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { UIRouter } from '@uirouter/core';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { EmployeeService } from '../../../shared/http/employee.service';
import { PermissionCheckService, ReadableFieldsResponse } from '../../../shared/services/permission-check.service';
import { Customer } from '../../../shared/models/customer';
import { ApiModel } from '../../../shared/enums/api-model';
import { CustomerUserComponent } from '../users/view/company-user/customer-user.component';
import { SwitchEmployeeUserDialogComponent, SwitchEmployeeUserDialogData } from '../../dialogs/switch-user-dialog/switch-employee-user-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PageHeaderButtonMenu } from '../../../shared/components/page-header/classes/page-header-button';

@Component({
    selector: 'eaw-employee-user',
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        TranslatePipe,
        ActionButtonComponent,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        PageHeaderComponent,
        CustomerUserComponent,
        MatProgressSpinnerModule,
    ],
    templateUrl: './employee-user.component.html',
    styleUrl: './employee-user.component.scss',
})
export class EmployeeUserComponent implements OnInit {
    @Input({ required: true }) employeeId!: number;
    @Input({ required: true }) customerId!: number;

    // Available after ngOnInit
    menu!: PageHeaderButtonMenu[];
    employee?: Employee;
    customer?: Customer;
    user?: User | null;
    loading = true;

    constructor(
        @Inject(TranslateService) protected readonly translateService: TranslateService,
        @Inject(MatDialog) protected readonly matDialog: MatDialog,
        @Inject(SnackBarService) protected readonly snackBarService: SnackBarService,
        @Inject(UIRouter) protected readonly uiRouter: UIRouter,
        @Inject(ConfirmDialogService) protected readonly confirmDialogService: ConfirmDialogService,
        @Inject(EmployeeService) protected readonly employeeService: EmployeeService,
        @Inject(PermissionCheckService) protected readonly permissionCheckService: PermissionCheckService,
    ) {
    }

    ngOnInit() {
        const hasPermission = this.employeeService
            .get(this.customerId, this.employeeId, { 'with[]': [ 'user.language', 'user.email_change', 'customer' ] })
            .pipe(
                switchMap((employee) => {
                    this.employee = employee;
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    this.customer = employee.customer!;
                    this.user = employee.user;
                    this.loading = false;

                    if (!employee.user) {
                        return of(undefined);
                    }

                    return this.permissionCheckService.getReadableFields(this.customer.stackId, `customers.${this.customerId}.employees`, { type: ApiModel.Employee, id: this.employee.id });
                }),
                map((resp: ReadableFieldsResponse | undefined) => {
                    if (!resp) {
                        return false;
                    }

                    const find: ReadableFieldsResponse | undefined = resp;
                    return !!find?.attributes.find((attr) => attr === 'user_id');
                }),
                shareReplay(1),
            );

        this.menu = [
            new PageHeaderButtonMenu({
                menuText: signal(this.translateService.t('ACTION_plural')),
                hasPermission: () => hasPermission,
                icon: () => 'more_vert',
                type: 'primary',
                menu: [
                    {
                        text: this.translateService.t('SWITCH_CONNECTED_USER', 'company'),
                        click: () => this.switchUser(),
                    },
                    {
                        text: this.translateService.t('DISCONNECT_USER', 'company'),
                        click: () => this.disconnectUser(),
                    },
                ],
            }),
        ];
    }

    addUser() {
        if (!this.employee) {
            return;
        }

        this.matDialog.open<ConnectUserAndEmployeeComponent, ConnectUserAndEmployeeData, boolean>(ConnectUserAndEmployeeComponent, { data: { employee: this.employee } })
            .afterClosed()
            .pipe(tap((connected) => {
                if (connected) {
                    this.toastAndReload();
                }
            }))
            .subscribe();
    }

    private toastAndReload() {
        void this.uiRouter.stateService.reload();
        this.snackBarService.t('EMPLOYEE_UPDATED', 'company');
    }

    switchUser() {
        if (!this.employee) {
            return;
        }

        this.matDialog
            .open<SwitchEmployeeUserDialogComponent, SwitchEmployeeUserDialogData, number | undefined>(SwitchEmployeeUserDialogComponent, { data: { employee: this.employee } })
            .afterClosed()
            .pipe(tap((userId) => {
                if (userId) {
                    this.toastAndReload();
                }
            }))
            .subscribe();
    }

    disconnectUser() {
        if (!this.employee) {
            return;
        }

        const employee = this.employee;

        this.confirmDialogService
            .delete({
                title: this.translateService.t('DISCONNECT_USER', 'company'),
                text: this.translateService.t('DISCONNECT_USER_QUESTION', 'company', { name: this.employee.name }),
                confirmText: this.translateService.t('DISCONNECT_USER', 'company'),
            })
            .afterClosed()
            .pipe(switchMap((result) => {
                if (result) {
                    return this.employeeService.update(employee.customerId, employee.id, { user_id: null }).pipe(tap(() => {
                        this.toastAndReload();
                    }));
                }

                return EMPTY;
            }))
            .subscribe();
    }
}
