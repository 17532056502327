import { booleanAttribute, ChangeDetectionStrategy, Component, computed, DestroyRef, effect, ElementRef, HostBinding, inject, Input, input, numberAttribute, OnChanges, signal, SimpleChanges } from '@angular/core';
import { ProfilePictureService } from '../../services/profile-picture.service';
import { TinyColor } from '@ctrl/tinycolor';
import { MatIconModule } from '@angular/material/icon';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

export interface ProfilePictureUser {
    id?: number | null,
    name?: string | null,
    profilePictureId?: number | null,
}

const possibleColors = [
    // 400s
    '#ef5350',
    '#ec407a',
    '#ab47bc',
    '#7e57c2',
    '#5c6bc0',
    '#42a5f5',
    '#29b6f6',
    '#26c6da',
    '#26a69a',
    '#66bb6a',
    '#9ccc65',
    '#d4e157',
    '#ffee58',
    '#ffca28',
    '#ffa726',
    '#ff7043',
    '#8d6e63',
    '#78909c',
] as const;

@Component({
    selector: 'eaw-profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrl: './profile-picture.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ MatIconModule ],
})
export class ProfilePictureComponent implements OnChanges {
    private profilePictureService = inject(ProfilePictureService);
    private destroyRef = inject(DestroyRef);
    private el = inject(ElementRef);

    @HostBinding('class.disabled') get isDisabled() {
        return this.disabled();
    }

    @Input() angularjsuser?: ProfilePictureUser;
    @Input() angularjssize?: number;
    @Input() angularjsthumb?: boolean;
    @Input() angularjsappearance?: 'circle' | 'square';

    protected internalUser = signal<ProfilePictureUser | undefined>(undefined);
    user = input<ProfilePictureUser>();

    protected internalSize = signal<number>(35);
    size = input(35, { transform: numberAttribute });

    protected internalAppearance = signal<'circle' | 'square'>('circle');
    appearance = input<'circle' | 'square'>('circle');

    protected internalDisabled = signal<boolean>(false);
    disabled = input(false, { transform: booleanAttribute });

    protected internalThumb = signal<boolean>(true);
    thumb = input(false, { transform: booleanAttribute });

    private urlObservable = computed(this.computeUrlObservable.bind(this));
    color = computed(this.computeColor.bind(this));
    textColor = computed(() => new TinyColor(this.color()).isDark() ? 'white' : 'black');
    letters = computed(this.computeLetters.bind(this));
    viewBox = signal(100);
    url = signal<string | undefined>(undefined);

    constructor() {
        effect(() => {
            const element = this.el.nativeElement as HTMLElement;
            element.style.setProperty('--size', `${this.internalSize()}px`);
            element.style.setProperty('--border-radius', `${this.internalAppearance() === 'circle' ? '50%': 0}`);
        });

        toObservable(this.urlObservable).pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap((o) => o),
            tap((url) => this.url.set(url)),
        ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('angularjsuser' in changes) {
            this.internalUser.set(this.angularjsuser);
        }
        if ('user' in changes) {
            this.internalUser.set(this.user());
        }

        if ('angularjssize' in changes) {
            this.internalSize.set(this.angularjssize || 35);
        }
        if ('size' in changes) {
            this.internalSize.set(this.size());
        }

        if ('angularjsappearance' in changes) {
            this.internalAppearance.set(this.angularjsappearance === 'square' ? 'square' : 'circle');
        }
        if ('appearance' in changes) {
            this.internalAppearance.set(this.appearance());
        }

        if ('angularjsthumb' in changes) {
            this.internalThumb.set(this.angularjsthumb ?? false);
        }
        if ('thumb' in changes) {
            this.internalThumb.set(this.thumb());
        }
    }

    computeUrlObservable(): Observable<string | undefined> {
        const thumb = this.internalThumb();
        const user = this.internalUser();
        const doesNotHavePicture = user && ('profilePictureId' in user) && user.profilePictureId === null;

        if (!user?.id || doesNotHavePicture) {
            return of(undefined);
        }

        return this.profilePictureService.get(user.id, thumb).pipe(
            takeUntilDestroyed(this.destroyRef),
            catchError(() => of(undefined)),
        );
    }

    computeColor() {
        const userId = this.internalUser()?.id;
        return userId ? possibleColors[userId % possibleColors.length] : possibleColors[0];
    }

    computeLetters() {
        return (this.internalUser()?.name || '').split(' ')
            .map((string) => string.charAt(0))
            .filter((_, i, arr) => i === 0 || i === arr.length - 1)
            .join('')
            .toUpperCase();
    }
}
