<eaw-dialog-header>
    <span title>{{ 'CREATE_USER' | translate:'admin' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <eaw-admin-create-update-user #createUpdateUserComponent mode="create" (created)="dialogRef.close($event)"/>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="" >{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="createUpdateUserComponent.processing()"
                       [disabled]="createUpdateUserComponent.form.invalid"
                       (click)="createUpdateUserComponent.create()">
        {{'CREATE' | translate | async}}
    </eaw-action-button>
</mat-dialog-actions>
