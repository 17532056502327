// @ts-nocheck
import { module } from 'angular';
import { Model } from '../../model';
module('eaw.digisign').factory('Signable', function SignableFactory() {
    return class Signable extends Model {
        type_id;
        filter;
        constructor(data) {
            super(data);
            this.type_id = this.filter?.type_id;
        }

        static override getMorphClass() {
            return 'signable';
        }
    };
});
