// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.calendar').component('eawCalendarEvents', {
    template: `<script type="text/ng-template" id="table-event-color.ajs.html">
    <div style="width: 20px; height: 20px; border-radius: 100%; background-color: {{item.color}}"></div>
</script>

<md-card ng-show="$ctrl.showFilter">
    <md-card-content ng-form="filterForm">
        <div layout="column" layout-wrap>
            <eaw-date-time-interval layout="row" ng-model="$ctrl.interval"></eaw-date-time-interval>

            <md-input-container flex>
                <label ng-i18next="SEARCH"></label>
                <input type="search" ng-model="$ctrl.search" uniq-form-name>
            </md-input-container>

            <div>
                <md-button type="submit" class="tw-ml-0 md-accent md-raised" ng-click="$ctrl.reloadTable = true">
                    <span ng-i18next="SEARCH"></span>
                </md-button>
            </div>
        </div>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="calendar:EVENT_plural"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="$ctrl.showFilter"
                        icon="filter_alt"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>

        <card-btn-float ng-if="$ctrl.canCreate" on-click="$ctrl.addEvent()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                cells="$ctrl.cells"
                columns="$ctrl.cols"
                factory="CalendarEventFactory"
                factory-function="getAll"
                factory-function-arguments="{
                            from: $ctrl.interval.from,
                            to: $ctrl.interval.to,
                            filter: $ctrl.search
                            }"
                reload-table="$ctrl.reloadTable">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controller: [ 'ToastService', 'CalendarEventService', 'deleteItemAlert', function(ToastService, CalendarEventService, deleteItemAlert) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.canCreate = CurrentOld.can(`customers.${CurrentOld.customer.id}.calendar_events.create`);
            ctrl.cols = [
                { title: '' },
                {
                    title: t('NAME'),
                    sortable: 'name',
                },
                {
                    title: t('FROM'),
                    sortable: 'from',
                },
                {
                    title: t('TO'),
                    sortable: 'to',
                },
                { show: CurrentOld.can(`customers.${CurrentOld.customer.id}.calendar_events.*.delete`) },
            ];
            ctrl.cells = [
                {
                    templateUrl: 'table-event-color.ajs.html',
                    class: 'text-center',
                },
                { template: 'item.name' },
                { template: 'item.from | moment:"lll"' },
                { template: 'item.to | moment:"lll"' },
                {
                    buttons: [
                        {
                            icon: 'edit',
                            invisible(event) {
                                return !CurrentOld.can(`customers.${CurrentOld.customer.id}.calendar_events.${event.id}.update`);
                            },
                            click: ctrl.editEvent,
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            invisible(event) {
                                return !CurrentOld.can(`customers.${CurrentOld.customer.id}.calendar_events.${event.id}.delete`);
                            },
                            click: ctrl.deleteEvent,
                        },
                    ],
                },
            ];
        };

        ctrl.addEvent = () => {
            CalendarEventService.dialog().then((result) => {
                CalendarEventService.add(result).then(() => {
                    ctrl.reloadTable = true;
                    ToastService.toast(t('X_ADDED', { name: '$t(calendar:EVENT)' }));
                });
            });
        };

        ctrl.editEvent = (event) => {
            const customer = CurrentOld.customer;
            // TODO: remove the unchanged attributes.
            CalendarEventService.dialog(event, CurrentOld.can(`customers.${customer.id}.calendar_events.${event.id}.update`)).then((result) => {
                CalendarEventService.update(result).then(() => {
                    ctrl.reloadTable = true;
                    ToastService.toast(t('X_UPDATED', { var: '$t(calendar:EVENT)' }));
                });
            });
        };

        ctrl.deleteEvent = (event) => {
            deleteItemAlert.alertSimple().then(() => {
                CalendarEventService.delete(event).then(() => {
                    ctrl.reloadTable = true;
                    ToastService.toast(t('DELETED'));
                });
            });
        };
    } ],
});
