import { DataTablePortalTypes } from './interfaces/data-table-portals';
import { ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, EventEmitter, HostBinding, inject, Injector, input, Input, OnChanges, OnDestroy, OnInit, Output, output, Signal, signal, viewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DataTableColumn } from './types/data-table-column';
import { DataTableChanges, DataTablePagination, DataTableRequest, DataTableRequestFunction, DataTableResponse } from './types/data-table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { catchError, Observable, of, Subscription, tap } from 'rxjs';
import { UIRouter } from '@uirouter/core';
import { MatDialog } from '@angular/material/dialog';
import { ExportColumn, ExportDialogComponent, ExportDialogData } from '../shared/dialogs/export-dialog/export-dialog.component';
import { CurrentService } from '../shared/services/current.service';
import { TranslateService } from '../shared/services/translate.service';
import { QueryParamsService } from '../shared/services/query-params.service';
import { CELL_DATA } from './interfaces/data-table-cell';
import { TranslatePipe } from '../shared/pipes/translate.pipe';
import { MatIconSizeDirective } from '../shared/directives/mat-icon-size.directive';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { CdkTableModule } from '@angular/cdk/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { uniqueId } from 'lodash-es';
import { PaginatorComponent } from '../shared/components/paginator/paginator.component';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { filterNullish } from '../shared/utils/rxjs/filter-nullish';

interface GoToParam {
    stateKey: string,
    itemKey: string,
}

export interface PaginationPage {
    index: number;
    formattedIndex: string;
    type: 'page' | 'current' | 'more';
}

export interface DataTableItem<Item> {
    // Internal only
    id: string;
    item: Item;
    components: Record<string, ComponentPortal<DataTablePortalTypes<Item>>>;
    classes: string[];
}

export type DataTableGoTo = { state: string, params?: GoToParam[] };

export const DataTableQueryParams = [ 'direction', 'order_by', 'per_page', 'page' ];

type RowClasses<Item extends Record<string, any> = any> = (row: Item) => string[];

@Component({
    selector: 'eaw-data-table',
    templateUrl: './data-table.component.html',
    styleUrl: './data-table.component.scss',
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({
                    maxHeight: '0',
                    minHeight: '0',
                    opacity: 0,
                }),
                animate('400ms ease-out', style({
                    maxHeight: '4px',
                    minHeight: '4px',
                    opacity: 1,
                })),
            ]),
            transition(':leave', [
                style({
                    maxHeight: '4px',
                    minHeight: '4px',
                    opacity: 1,
                }),
                animate('400ms ease-out', style({
                    maxHeight: '0',
                    minHeight: '0',
                    opacity: 0,
                })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [
        NgIf,
        MatProgressBarModule,
        MatTableModule,
        MatSortModule,
        MatProgressSpinnerModule,
        NgFor,
        NgClass,
        PortalModule,
        CdkTableModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatRippleModule,
        MatIconSizeDirective,
        MatPaginatorModule,
        AsyncPipe,
        TranslatePipe,
        PaginatorComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent<Item extends Record<string, any>> implements OnInit, OnChanges, OnDestroy {
    private readonly uiRouter = inject(UIRouter);
    private readonly matDialog = inject(MatDialog);
    private readonly destroyRef = inject(DestroyRef);
    private readonly current = inject(CurrentService);
    private readonly translate = inject(TranslateService);
    private readonly searchParams = inject(QueryParamsService);

    @HostBinding('class.fill') get fill() {
        return this.fillAvailable();
    }

    @Input()
    set initialSortBy(value: string) {
        this.sortActive.update((sortActive) => value || sortActive);
    }

    @Input()
    set initialSortDirection(value: SortDirection) {
        this.sortDirection.update((sortDirection) => value || sortDirection);
    }

    getData = input<DataTableRequestFunction<Item>>();
    hidePaginator = input<BooleanInput>();
    fillAvailable = input<boolean>(true);
    noDataText = input<Promise<string>>(this.translate.t('NO_DATA'));
    columns = input.required<DataTableColumn<any, Item, any>[]>();
    /**
     * @deprecated
     * @see getData
     */
    request = input<DataTableRequest<Item>>();
    goTo = input<DataTableGoTo>();
    skipQueryParams = input<BooleanInput>(false);
    rowClasses = input<RowClasses<Item>>();

    /**
     * Emits when the pagination changes.
     * @deprecated
     * @see getData
     */
    paginationChange = output<DataTablePagination>();
    @Output() rowClick = new EventEmitter<Item>();

    protected matSort = viewChild.required(MatSort);

    protected response = signal<DataTableResponse<Item> | undefined>(undefined);
    protected noPaginator: Signal<boolean>;
    protected hasRowClick = computed(() => typeof this.goTo()?.state === 'string' || this.rowClickObserved());
    protected dataSource = computed<DataTableResponse<DataTableItem<Item>> | undefined>(this.computeDataSource.bind(this));
    /** Column definitions */
    protected columnDefs = computed(() => Array.from(new Set(this.columns().map((c) => c.key))));
    protected sortActive = signal('created_at');
    protected sortDirection = signal<SortDirection>('desc');
    protected rowClickObserved = signal(false);
    protected pageIndex = signal(0);
    protected pageSize = signal(25);
    protected pageTotal = signal(0);
    protected allSticky = computed(() => !this.columns().some((c) => c.sticky || c.stickyEnd));
    protected loading = signal<boolean>(false);

    /**
     * @deprecated - Move over to using `getData`
     */
    private internalRequest = signal<DataTableRequest<Item> | undefined>(undefined);
    private paginationReady = signal(false);
    private noQueryParams = computed(() => coerceBooleanProperty(this.skipQueryParams()));

    private perPageStorage: LocalForage;
    private subscription?: Subscription;

    private get perPageStorageKey() {
        return this.uiRouter.globals.current.name || '';
    }

    constructor() {
        this.perPageStorage = this.current.createStore('Per page');

        this.noPaginator = computed(() => {
            const hide = coerceBooleanProperty(this.hidePaginator());
            const notReady = !this.paginationReady();
            return hide || notReady;
        });

        toObservable(this.getData).pipe(
            takeUntilDestroyed(this.destroyRef),
            filterNullish(),
        ).subscribe((getDataFn) => this.handleRequestChange(getDataFn(this.getPagination())));

        toObservable(this.internalRequest).pipe(
            takeUntilDestroyed(this.destroyRef),
            filterNullish(),
        ).subscribe((request) => this.handleRequestChange(request));
    }

    ngOnInit(): void {
        void this.initiatePagination();
        this.rowClickObserved.set(this.rowClick.observed);
    }

    ngOnChanges(changes: DataTableChanges) {
        if (changes.request) {
            this.internalRequest.set(changes.request.currentValue);
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    exportData() {
        const exportColumns: ExportColumn[] = [];

        for (const col of this.columns()) {
            if (!col.exportable) {
                continue;
            }

            const exportColumn: ExportColumn = {
                header: col.header?.text ? Promise.resolve(col.header.text) : this.translate.t(col.header?.i18n, col.header?.ns),
                data: [],
            };

            for (const row of this.dataSource()?.data || []) {
                if (!(col.key in row.item)) {
                    console.error(`Could not find key`, col.key, 'in item', row);
                    exportColumn.data.push(null);
                    continue;
                }

                const value = row.item[col.key];
                exportColumn.data.push(value);
            }

            exportColumns.push(exportColumn);
        }

        this.matDialog.open<ExportDialogComponent, ExportDialogData>(ExportDialogComponent, {
            data: {
                items: exportColumns,
            },
        });
    }

    modifyResponse(itemFinder: (items: DataTableResponse<Item>) => DataTableResponse<Item>) {
        this.response.update((resp) => resp ? { ...itemFinder(resp) } : resp);
    }

    getPagination(pagination?: Partial<DataTablePagination>): DataTablePagination {
        return {
            direction: pagination?.direction ?? (this.matSort().direction || this.sortDirection()) ?? '',
            order_by: pagination?.order_by ?? this.matSort().active ?? this.sortActive(),
            per_page: pagination?.per_page ?? this.pageSize(),
            page: pagination?.page ?? (this.pageIndex() ?? 0) + 1,
        };
    }

    /**
     * Refreshes the table from the first page with all other pagination settings
     */
    refresh() {
        this.pageIndex.set(0);
        this.onPaginationChange(this.getPagination({ page: 1 }));
    }

    paginatorPage(change?: { per_page: number, page: number }) {
        if (change) {
            this.pageSize.set(change.per_page);
            this.pageIndex.set(change.page - 1);
        }

        this.updateSearchParams();
        this.onPaginationChange(this.getPagination(change));
    }

    protected tableTrackBy(_index: number, item: DataTableItem<Item>) {
        return item.id;
    }

    protected onRowClick(row: DataTableItem<Item>, event: MouseEvent) {
        this.rowClick.emit(row.item);
        this.goToState(row.item, event.ctrlKey);
    }

    protected goToPage(index: number) {
        this.pageIndex.set(index - 1);
        this.paginatorPage();
    }

    protected sortChange() {
        this.pageIndex.set(0);

        this.updateSearchParams();
        this.onPaginationChange(this.getPagination());
    }

    private async initiatePagination() {
        if (!this.noQueryParams()) {
            this.pageIndex.set((this.searchParams.get('page', 'number') || 1) - 1);
            this.sortActive.set(this.searchParams.get('order_by', 'string') || this.sortActive());
            this.sortDirection.set(this.searchParams.get('direction', 'string') as SortDirection || this.sortDirection());
            this.pageSize.set(this.searchParams.get('per_page', 'number') || this.pageSize() || await this.perPageStorage.getItem(this.perPageStorageKey) || 25);
        }

        this.paginationReady.set(true);
        this.paginationChange.emit(this.getPagination());
    }

    private goToState(row: Item, blank?: boolean) {
        const goTo = this.goTo();
        if (!goTo?.state) {
            return;
        }

        const goto = goTo.params?.reduce((obj: Record<string, string>, param: GoToParam) => {
            obj[param.stateKey] = row[param.itemKey];
            return obj;
        }, {});
        const params = {
            ...this.uiRouter.globals.params,
            ...goto,
        };

        if (blank) {
            window.open(this.uiRouter.stateService.href(goTo.state, params), '_blank');
        } else {
            this.uiRouter.stateService.transitionTo(goTo.state, params);
        }
    }

    private updatePagination(response: DataTableResponse<Item>) {
        this.updateSearchParams();

        this.pageTotal.update((total) => response.total || total);
        this.pageSize.set(parseInt(typeof response.per_page === 'number' ? String(response.per_page) : response.per_page) ?? String(this.pageSize()));
        this.pageIndex.set(response.current_page - 1 || this.pageIndex());

        void this.perPageStorage.setItem(this.perPageStorageKey, this.pageSize());
    }

    private computeDataSource(): DataTableResponse<DataTableItem<Item>> | undefined {
        const response = this.response();
        const rowClasses = this.rowClasses();
        const columns = this.columns();

        if (!response) {
            return undefined;
        }

        const data = response.data.map((item) => {
            return {
                id: uniqueId(),
                item,
                classes: rowClasses?.(item) || [],
                components: columns.reduce((obj, column) => {
                    obj[column.key] = new ComponentPortal<DataTablePortalTypes<Item>>(column.component, null, Injector.create({
                        providers: [
                            {
                                provide: CELL_DATA,
                                useValue: {
                                    column,
                                    item,
                                    disabled: signal(false),
                                },
                            },
                        ],
                    }));

                    return obj;
                }, {} as Record<string, ComponentPortal<DataTablePortalTypes<Item>>>),
            } satisfies DataTableItem<Item>;
        }) || [];

        return { ...response, data };
    }

    private handleRequestChange(request?: DataTableRequest<Item>) {
        const isObservable = request instanceof Observable;
        const validPagination = this.noPaginator() ? true : this.paginationReady();
        if (!(isObservable && validPagination)) {
            return;
        }

        this.loading.set(true);
        this.subscription = request.pipe(
            tap((response) => this.updatePagination(response)),
            tap((response) => this.response.set(response)),
            tap(() => this.loading.set(false)),
            catchError((err) => {
                console.error('DataTable, error loading', err);
                this.loading.set(true);
                return of(null);
            }),
        ).subscribe();
    }

    private updateSearchParams() {
        if (this.skipQueryParams()) {
            return;
        }

        const pagination = this.getPagination();

        this.searchParams.set([
            {
                key: 'direction',
                value: pagination.direction,
            },
            {
                key: 'order_by',
                value: pagination.order_by,
            },
            {
                key: 'per_page',
                value: pagination.per_page,
            },
            {
                key: 'page',
                value: pagination.page,
            },
        ]);
    }

    private onPaginationChange(pagination: DataTablePagination) {
        this.paginationChange.emit(pagination);
        const getData = this.getData();
        if (getData) {
            this.handleRequestChange(getData(pagination));
        }
    }
}
