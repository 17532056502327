import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { catchError, EMPTY, first } from 'rxjs';
import { DialogComponent, DialogData } from 'src/app/shared/dialogs/dialog-component';
import { LanguageService } from 'src/app/admin/http/language.service';
import { Language } from '../../models/language';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface LanguageDialogData extends DialogData {
    code?: string;
    name?: string;
    ietfBcp47Tag?: string;
}

@Component({
    selector: 'eaw-language-dialog',
    templateUrl: './language-dialog.component.html',
    styleUrl: './language-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class LanguageDialogComponent extends DialogComponent {
    form = new FormGroup({
        code: new FormControl({ value: this.data?.code || '', disabled: this.editing }, { nonNullable: true, validators: [ Validators.required, Validators.minLength(2), Validators.maxLength(10) ] }),
        name: new FormControl(this.data?.name || '', { nonNullable: true, validators: [ Validators.required, Validators.minLength(2), Validators.maxLength(50) ] }),
        ietfBcp47Tag: new FormControl({ value: this.data?.ietfBcp47Tag || '', disabled: this.editing }, { nonNullable: true, validators: [ Validators.required, Validators.minLength(2), Validators.maxLength(16) ] }),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: LanguageDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<LanguageDialogComponent, Language>,
        @Inject(LanguageService) protected languageService: LanguageService,
    ) {
        super(dialogRef, data);
    }

    submit(): void {
        this.form.disable();

        const { code, name, ietfBcp47Tag } = this.form.getRawValue();
        const observable = this.editing ? this.languageService.update(code, name) : this.languageService.create({ code, name, ietfBcp47Tag });
        observable.pipe(
            first(),
            catchError(() => {
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe((response) => this.dialogRef.close(response));
    }

    get editing() {
        return !!this.data?.code;
    }
}
