<eaw-dialog-header>
    <span title>{{'CREATE_HOURS_DISTRIBUTION' | translate:'company' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-8">
        <div class="tw-grid tw-grid-cols-2 tw-gap-8">
            <mat-form-field eawDatePickerOptions>
                <mat-label>{{'FROM' | translate | async}}</mat-label>
                <input matInput
                       autocomplete="off"
                       [matDatepicker]="$any(fromPicker)"
                       formControlName="from"
                       [min]="minDate">
                <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                <mat-datepicker #fromPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field eawDatePickerOptions>
                <mat-label>{{'TO' | translate | async}}</mat-label>
                <input matInput
                       autocomplete="off"
                       [matDatepicker]="$any(toPicker)"
                       formControlName="to"
                       [min]="form.controls.from.value || minDate">
                <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                <mat-datepicker #toPicker></mat-datepicker>
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>{{'WEEK_plural' | translate | async}}</mat-label>
            <input matInput type="number" inputmode="numeric" min="1" step="1" max="4" formControlName="weeks">
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
    <button mat-button (click)="create()" [disabled]="form.invalid || form.disabled">{{'CREATE' | translate | async}}</button>
</mat-dialog-actions>
