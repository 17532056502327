import { isObject } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { DateTimeConverter } from '../../../../utils/date-time-converter';
import { EawUrl } from '../url.service';
import { environment } from '../../../../../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

module('eaw.resource').factory('requestInterceptor', [ 'OAuthServiceDowngrade', function requestInterceptorFactory(OAuthServiceDowngrade: OAuthService) {
    // @ts-ignore
    const interceptor = this;

    interceptor.handleMoment = function handleMoment(obj: any, key: any) {
        if (key === 'business_date' || (Array.isArray(obj._businessDates) && obj._businessDates.includes(key))) {
            obj[key] = DateTimeConverter.convertMomentToBusinessDate(obj[key]);
        } else {
            obj[key] = DateTimeConverter.convertMomentToUtcString(obj[key]);
        }
    };

    interceptor.handleString = function handleString(obj: any, key: any) {
        obj[key] = obj[key].trim();
    };

    interceptor.handleBoolean = function handleBoolean(obj: any, key: any) {
        obj[key] = +obj[key];
    };

    interceptor.handleNull = function handleNull(obj: any, key: any) {
        obj[key] = '';
    };

    interceptor.walkCheck = function walkCheck(obj: any, key: any) {
        try { // Add try because logout token fails when handling string
            if (moment.isMoment(obj[key])) {
                interceptor.handleMoment(obj, key);
            } else if (typeof obj[key] === 'string') {
                interceptor.handleString(obj, key);
            } else if (typeof obj[key] === 'boolean') {
                interceptor.handleBoolean(obj, key);
            } else if (isObject(obj[key]) || Array.isArray(obj[key])) {
                interceptor.walk(obj[key]);
            } else if (obj[key] === null) {
                interceptor.handleNull(obj, key);
            }
        } catch (e) {
            console.error(e);
        }
    };

    interceptor.walk = function walk(obj: any) {
        Object.keys(obj).forEach((key) => {
            if (!key.startsWith('$')) {
                interceptor.walkCheck(obj, key);
            }
        });

        delete obj._businessDates;
    };

    interceptor.shouldWalk = (request: any) => {
        const badRequests = [ '.html', '.json', 'link', '.svg', '/logo', '/badges', '/token', '/logout' ]; // Skip these
        return !request.ignoreInterceptor && !badRequests.some((bad) => request.url.endsWith(bad));
    };

    interceptor.request = (request: any) => {
        if (EawUrl.isApiUrl(request.url)) {
            return interceptor.handleApiRequest(request);
        }

        return interceptor.handleNonApiRequests(request);
    };

    interceptor.handleNonApiRequests = (request: any) => {
        // Remove our auth when sending request somewhere else than our API
        delete request.headers?.Authorization;

        return request;
    };

    interceptor.handleApiRequest = (request: any) => {
        if (interceptor.shouldWalk(request)) {
            const key = request?.method?.toLowerCase?.() === 'get' ? 'params' : 'data';
            interceptor.walk(request?.[key] || {});
        }

        // Set header(s) we always send on request to API
        request.headers['X-Ui-Version'] = environment.version;
        request.headers['Authorization'] = `Bearer ${OAuthServiceDowngrade.getAccessToken()}`;

        return request;
    };

    interceptor.requestError = function requestError(request: any) {
        request.config = request.config || {};
        return Promise.reject(request);
    };

    return interceptor;
} ]);
