// @ts-nocheck
import { module } from 'angular';
import { Products } from '../../../shared/enums/products';
import { loadNamespaces } from 'i18next';
import { Namespace } from '../../../shared/enums/namespace';
import { createState } from '../../../shared/utils/create-state';
import { paramResolver } from '../../../shared/resolvers/param.resolver';
import { dependencyResolver } from '../../../shared/resolvers/dependency.resolver';
import { staticResolver } from '../../../shared/resolvers/static.resolver';
import { CustomFieldOld } from '../../../shared/angularjs/custom-field-old';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.absence').config([ '$stateProvider', function config($stateProvider: any) {
    createState($stateProvider, {
        name: 'eaw/app/absence/list/tabs/edit',
        parent: 'eaw/app/absence/list/tabs',
        url: '/edit?:isMine',
        component: 'editAbsence',
        data: {
            i18nextNs: [ Namespace.Leaveshifts, Namespace.Absences ],
            somePermissions: [],
            permissions: [],
        },
        resolve: [
            paramResolver('isMine'),
            // Old custom fields are used here, so set them up
            dependencyResolver('customFieldsSetter', [ '$http', 'customer' ], function($http: angular.IHttpService, customer) {
                return CustomFieldOld.storeFields(customer.id, $http);
            }),
        ],
        params: {
            isMine: {
                type: 'bool',
                squash: true,
                dynamic: false,
            },
        },
    });
    createState($stateProvider, {
        name: 'eaw/app/absence/list/tabs/leave_shifts',
        parent: 'eaw/app/absence/list/tabs',
        url: '/leave_shifts',
        component: 'leaveShifts',
        data: {
            products: [ Products.LeaveShifts ],
            permissions: [ 'customers.{customer}.employees.{employee_id}.absences.{id}.leave_shifts.*.get' ],
            i18nextNs: [ Namespace.Leaveshifts, Namespace.Absences ],
        },
        resolve: [
            dependencyResolver('leaveShifts', [ 'LeaveShiftFactory', 'absence', 'customer', '$transition$' ], function(LeaveShiftFactory, absence, customer, $transition$) {
                const params = $transition$.params();
                return LeaveShiftFactory.getAll({
                    per_page: 500,
                    customer_id: customer.id,
                    employee_id: params.employee_id,
                    model: 'absences',
                    model_id: params.id,
                    with: [ 'customer' ],
                }).$promise.then((resp) => resp.data);
            }),
            dependencyResolver('object', [ 'absence' ], (x) => x),
            staticResolver('objectType', 'absences'),
            dependencyResolver('permissions', [ 'customer', 'absence' ], function(customer, absence) {
                const whatWeWant = {
                    id: absence.id,
                    employee_id: absence.employee_id,
                };
                return {
                    delete: CurrentOld.can('customers.{customer}.employees.{employee_id}.absences.{id}.leave_shifts.*.delete', whatWeWant),
                    update: CurrentOld.can('customers.{customer}.employees.{employee_id}.absences.{id}.leave_shifts.*.update', whatWeWant),
                    create: CurrentOld.can('customers.{customer}.employees.{employee_id}.absences.{id}.leave_shifts.create', whatWeWant),
                };
            }),
        ],
    });
    $stateProvider.state('eaw/app/absence/list/tabs/shifts', {
        parent: 'eaw/app/absence/list/tabs',
        url: '/shifts',
        component: 'shiftsDuringAbsence',
        resolve: {
            editTab() {
                return 'eaw/app/absence/list/tabs/edit';
            },
        },
        data: {
            breadcrumb: { key: 'SHIFT_plural' },
        },
    });
    createState($stateProvider, {
        name: 'eaw/app/absence/list/tabs',
        parent: 'eaw/app/absence/list',
        abstract: true,
        url: '/:employee_id/:id',
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            breadcrumb: { key: 'EDIT_ABSENCE', ns: 'absences' },
            i18nextNs: [
                Namespace.Scheduling,
                Namespace.Calendar,
                Namespace.Availabilities,
                Namespace.Staffing,
                Namespace.Autosched,
                Namespace.Company,
            ],
        },
        resolve: [
            staticResolver('navbarRoute', 'eaw/app/absence/list/tabs'),
            dependencyResolver('absence', [ 'Absence', 'customer', '$transition$' ], function(Absence, customer, $transition$) {
                const params = $transition$.params();
                return Absence.get({
                    customerId: customer.id,
                    employeeId: params.employee_id,
                    absenceId: params.id,
                    with: [ 'employee', 'type', 'approval', 'comments', 'properties' ],
                }).$promise.then((data) => new Absence(data, customer));
            }),
            dependencyResolver('employee', [ 'absence' ], (x) => x.employee),
            dependencyResolver('hasLeaveShifts', [ 'customer' ], (x) => x.hasProduct('leave shifts')),
            dependencyResolver('tabs', [ 'absence', 'hasLeaveShifts' ], function(absence, hasLeaveShifts) {
                const tabs = [
                    {
                        label: 'absences:EDIT_ABSENCE',
                        src: 'eaw/app/absence/list/tabs/edit',
                    },
                ];
                const namespaces = [];
                if (hasLeaveShifts && CurrentOld.can('customers.{customer}.employees.{emp}.absences.{absence}.leave_shifts.*.get', {
                    absence: absence.id,
                    emp: absence.employee.id,
                })) {
                    tabs.push({
                        label: 'leaveshifts:LEAVE_SHIFT_plural',
                        src: 'eaw/app/absence/list/tabs/leave_shifts',
                    });
                    namespaces.push(Namespace.Leaveshifts);
                }
                if (absence.customer.hasProduct('Scheduling')) {
                    tabs.push({
                        label: 'SHIFT_plural',
                        src: 'eaw/app/absence/list/tabs/shifts',
                    });
                    namespaces.push(Namespace.Scheduling);
                }
                const cb = () => tabs;
                return loadNamespaces(namespaces).then(cb, cb);
            }),
        ],
    });
} ]);
