<eaw-dialog-header>
    <span title>{{(data.id ? 'EDIT_DEFAULT_FILE' : 'CREATE_DEFAULT_FILE') | translate: 'hr' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="formGroup" class="tw-flex tw-flex-col">

        <mat-form-field>
            <mat-label>{{'FILETYPE' | translate: 'hr' | async}}</mat-label>
            <input type="text"
                   matInput
                   formControlName="filetype"
                   [matAutocomplete]="filetypeAuto">
            <mat-autocomplete #filetypeAuto [displayWith]="autoCompDisplay">
                <mat-option *ngIf="loading">
                    <div class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-8">
                        <mat-progress-spinner diameter="20" strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
                        <span>{{'LOADING'|translate|async}}</span>
                    </div>
                </mat-option>
                <mat-option *ngFor="let type of filteredTypes | async" [value]="type">
                    <span>{{type.name}}</span>
                    <small style="color: var(--eaw-primary-500)" *ngIf="type.mandatory"> - {{'MANDATORY_FILE'|translate: 'hr'|async}}</small>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="!data.id">
            <mat-label>{{'FILE' | translate:'hr' | async}}</mat-label>
            <mat-icon matPrefix color="primary">attach_file</mat-icon>
            <span matTextSuffix *ngIf="formGroup.value.file" [style.color]="'grey-500' | materialColor | async">{{formGroup.value.file!.size | fileSize}}</span>
            <eaw-file-selector formControlName="file" [placeholder]="('UPLOAD_FILE' | translate: 'hr' | async)!" [required]="!data.id" (fileChange)="onFileChange($event)"></eaw-file-selector>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'NAME' | translate | async}}</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'DESCRIPTION' | translate | async}}</mat-label>
            <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{'CANCEL' | translate | async}}</button>
    <button mat-button
            mat-raised-button
            (click)="submit()"
            [disabled]="!formGroup.valid"
            color="primary">{{(data.id ? 'EDIT' : 'CREATE') | translate | async}}</button>
</mat-dialog-actions>
