import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { CustomFieldDialogService, CustomFieldHistoryDialogData } from '../../services/custom-field-dialog.service';
import { EmployeePropertyService } from '../../../shared/http/employee-property.service';
import { ModelCustomField } from '../../models/model-custom-field';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { CustomFieldValueDisplayComponent } from '../../components/custom-field-value-display/custom-field-value-display.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-custom-field-history-dialog',
    templateUrl: './custom-field-history-dialog.component.html',
    styleUrl: './custom-field-history-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatIconModule,
        MatIconSizeDirective,
        NgFor,
        MaterialColorDirective,
        MatTooltipModule,
        CustomFieldValueDisplayComponent,
        MatButtonModule,
        MatDialogActions,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class CustomFieldHistoryDialogComponent extends DialogComponent implements OnInit {
    loading = false;
    hasChanges = false;
    canUpdate = false;
    customFields: ModelCustomField[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CustomFieldHistoryDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<CustomFieldHistoryDialogComponent, boolean>,
        @Inject(EmployeePropertyService) private employeePropertyService: EmployeePropertyService,
        @Inject(CustomFieldDialogService) private customFieldDialogService: CustomFieldDialogService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
    ) {
        dialogRef.disableClose = true;
        data.size ||= DialogSize.Medium;
        super(dialogRef, data);

        this.permissionCheckService.isAllowed(`customers.${this.data.customerId}.employees.${this.data.employeeId}.update`).subscribe((canUpdate) => {
            this.canUpdate = canUpdate;
        });

        this.dialogRef.backdropClick().subscribe(this.close.bind(this));
    }

    ngOnInit() {
        this.getFields();
    }

    getFields() {
        this.loading = true;
        this.employeePropertyService.getAllForKey(this.data.customerId, this.data.employeeId, this.data.customField.key).subscribe((res) => {
            this.customFields = res.data.map((property) => {
                return new ModelCustomField({
                    ...this.data.customField.response,
                    from: property._response.from ?? null,
                    to: property._response.to ?? null,
                    value: property._response.value,
                });
            });

            this.customFields = this.customFields.sort((a, b) => +(b.from || 0) - +(a.from || 0));
            this.loading = false;
        });
    }

    edit(customField: ModelCustomField) {
        this.customFieldDialogService.manage('edit', this.data.customerId, this.data.employeeId, customField).afterClosed().subscribe((res) => {
            if (res) {
                this.hasChanges = true;
                this.getFields();
            }
        });
    }

    delete(pivot: ModelCustomField, index: number) {
        this.customFieldDialogService.delete(this.data.customerId, this.data.employeeId, pivot).subscribe(() => {
            this.hasChanges = true;
            this.customFields.splice(index, 1);
        });
    }

    close() {
        this.dialogRef.close(this.hasChanges);
    }
}
