import { Component, inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AsyncPipe, KeyValuePipe, NgForOf, NgIf } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { catchError, EMPTY, filter, forkJoin, map, of, switchMap } from 'rxjs';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { CurrentService } from '../../../shared/services/current.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { Customer } from '../../../shared/models/customer';
import { MatSortModule, Sort } from '@angular/material/sort';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractType } from '../../../shared/models/contract-type';
import { DateIntervalSelectorComponent } from '../../../shared/components/date-interval-selector/date-interval-selector.component';
import { MatInputModule } from '@angular/material/input';
import { DateTime } from 'luxon';
import { BalanceService } from '../../../balances/http/balance.service';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { BalanceType } from '../../../balances/models/balance-type';
import { EmployeeService } from '../../../shared/http/employee.service';
import { Contract } from '../../../shared/models/contract';
import { BalanceHistoryDialogComponent, BalanceHistoryDialogData } from '../../../balances/dialogs/balance-history-dialog/balance-history-dialog.component';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

interface EmployeeItem {
    name: string,
    id: number,
    balances: Record<string, number | undefined>
    contract: Contract | undefined,
}

export type ChainBalanceCustomer = {
    customer: Customer,
    loading: boolean,
    isOpen: boolean,
    hasError: boolean,
    employees: EmployeeItem[],
    filteredEmployees: EmployeeItem[],
    balanceTypes: BalanceType[],
    displayColumns: string[],
    totals: Record<string, string>
};

@Component({
    selector: 'eaw-chain-balances',
    standalone: true,
    imports: [
        AsyncPipe,
        MatButtonModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        NgForOf,
        NgIf,
        PageHeaderComponent,
        TranslatePipe,
        DateIntervalSelectorComponent,
        FormsModule,
        KeyValuePipe,
        MatInputModule,
        ReactiveFormsModule,
        TranslateSyncPipe,
    ],
    templateUrl: './chain-balances.component.html',
    styleUrl: './chain-balances.component.scss',
})
export class ChainBalancesComponent implements OnInit {
    private balanceService = inject(BalanceService);
    private current = inject(CurrentService);
    private customerGroupService = inject(CustomerGroupService);
    private employeeService = inject(EmployeeService);
    private matDialog = inject(MatDialog);
    private numberFormatterService = inject(NumberFormatterService);
    private permissionCheckService = inject(PermissionCheckService);

    @ViewChildren(MatTable) table!: QueryList<MatTable<any>>;

    private lastSort: Sort = {
        active: 'employee',
        direction: 'asc',
    };

    from = DateTime.now();
    to = DateTime.now().endOf('week');
    contractTypes = new Map<number, ContractType>();
    fetching = false;
    form = new FormGroup({
        interval: new FormControl<'day'| 'week'>('day', { nonNullable: true }),
        contractType: new FormControl<ContractType | null>(null),
    });

    loadingGroups = true;
    customers: ChainBalanceCustomer[] = [];

    ngOnInit(): void {
        this.form.valueChanges.subscribe(this.filter.bind(this));
        this.getGroups();
    }

    filter() {
        const { contractType } = this.form.getRawValue();

        this.customers.forEach((customer) => {
            if (customer.isOpen) {
                customer.filteredEmployees = customer.employees.filter((employee) => {
                    return contractType ? employee.contract?.type?.id === contractType.id : true;
                });
                this.updateBalanceSums(customer);
            }
        });
    }

    updateInterval(event: { from: DateTime, to: DateTime }) {
        this.from = event.from;
        this.to = event.to;
        this.customers.forEach((customer) => {
            if (customer.isOpen) {
                this.getData(customer);
            }
        });
    }

    onIntervalTypeChanged() {
        if (this.form.controls.interval.value === 'week') {
            this.to = this.from.endOf('week');
            this.from = this.from.startOf('week');
        }
        this.customers.forEach((customer) => {
            if (customer.isOpen) {
                this.getData(customer);
            }
        });
    }

    sort(event: Sort, customer: ChainBalanceCustomer) {
        let result: EmployeeItem[];
        this.lastSort = event;

        if (event.active === 'employee') {
            result = sort(customer.filteredEmployees, this.current.languageTag, [ (e) => e.name ], [ event.direction || 'asc' ]);
        } else {
            result = customer.filteredEmployees.sort((a, b) => {
                if (event.direction === 'asc') {
                    return (a.balances[event.active] || 0) - (b.balances[event.active] || 0);
                } else {
                    return (b.balances[event.active] || 0) - (a.balances[event.active] || 0);
                }
            });
        }

        // Spread it to trigger change detection
        customer.filteredEmployees = [ ...result ];
    }

    getData(customer: ChainBalanceCustomer) {
        customer.loading = true;
        customer.balanceTypes = [];
        customer.displayColumns = [ 'name', 'contract' ];
        customer.totals = {};
        const time = this.form.controls.interval.value === 'day' ? { date: this.from } : { from: this.from, to: this.to };
        const balances = expandAllPages((pagination) => this.balanceService.getEmployeesOverview(customer.customer.id, { ...time, ...pagination }), { per_page: 100 });
        const types = this.getTypes(customer);
        const employeesWithContracts = expandAllPages((pagination) => this.employeeService.getAll(customer.customer.id, pagination), { per_page: 100, 'with[]': [ 'contracts.type' ] });

        this.fetching = true;
        forkJoin([ balances, types, employeesWithContracts ]).subscribe(([ balances, types, employeesWithContracts ]) => {
            customer.balanceTypes = types;
            customer.employees = [];

            balances.forEach((balance) => {
                const activeContract = employeesWithContracts.find((employee) => employee.id === balance.id)?.getActiveContract(this.from);

                customer.employees.push({
                    name: balance.name,
                    id: balance.id,
                    contract: activeContract,
                    balances: balance.balance_changes.reduce((acc, value) => {
                        acc[value.type] = parseFloat(String(value.delta));
                        return acc;
                    }, {} as Record<string, number>),
                });

                if (activeContract?.type) {
                    this.contractTypes.set(activeContract.type.id, activeContract.type);
                }
            });

            customer.employees = sort(customer.employees, this.current.languageTag, [ (e) => e.name ], [ 'asc' ]);
            customer.displayColumns = [ 'name', 'contract', ...customer.balanceTypes.map((type) => type.balanceCode) ];
            customer.filteredEmployees = customer.employees;

            // Re-apply sorting and filter
            this.sort(this.lastSort, customer);

            this.updateBalanceSums(customer);

            customer.loading = false;
            this.fetching = false;
        });
    }

    getTypes(customer: ChainBalanceCustomer) {
        if (customer.balanceTypes.length) {
            return of(customer.balanceTypes);
        }

        return expandAllPages((pagination) => this.balanceService.getTypes(customer.customer.id, pagination).pipe(
            catchError((e) => {
                console.error(e);
                return of(mockArrayPaginatedResponse([]));
            }),
            map((response) => {
                return {
                    ...response,
                    data: Object.values(response.data),
                };
            }),
        ), {
            per_page: 40,
        });
    }

    updateBalanceSums(customer: ChainBalanceCustomer) {
        customer.balanceTypes.forEach((type) => {
            customer.totals[type.balanceCode] = this.getBalanceSum(type, customer);
        });
    }

    getBalanceSum(type: BalanceType, customer: ChainBalanceCustomer) {
        const value = customer.filteredEmployees.reduce((acc, employee) => acc + (employee.balances[type.balanceCode] || 0), 0) / type.factor;

        return this.numberFormatterService.formatDecimal(value, 2, this.current.languageTag, {
            signDisplay: 'exceptZero',
        });
    }

    openBalanceDetails(employee: EmployeeItem, type: BalanceType, customer: ChainBalanceCustomer) {
        this.matDialog.open<BalanceHistoryDialogComponent, BalanceHistoryDialogData, boolean>(BalanceHistoryDialogComponent, {
            data: {
                customerId: customer.customer.id,
                employeeId: employee.id,
                balanceType: of(type),
                allowChanges: true,
            },
        }).afterClosed().pipe(filter((balanceChanged) => !!balanceChanged)).subscribe(() => this.getData(customer));
    }

    getBalance(employee: EmployeeItem, type: BalanceType) {
        const value = (employee.balances[type.balanceCode] || 0) / type.factor;

        return this.numberFormatterService.formatDecimal(value, 2, this.current.languageTag, {
            signDisplay: 'exceptZero',
        });
    }

    getGroups() {
        expandAllPages((pagination) => this.customerGroupService.getAllForCustomer(this.current.getCustomer().id, pagination), { page: 1, 'with[]': [ 'members' ] }).pipe(
            switchMap((groups) => {
                const customers = groups.flatMap((g) => {
                    return g.members;
                });
                if (!customers.length) {
                    return EMPTY;
                }
                return forkJoin(customers.map((c) => {
                    return this.permissionCheckService.isAllowedMany([
                        `customers.${c.id}.balances.*.*.get`,
                        `customers.${c.id}.balance_types.*.get`,
                    ]).pipe(
                        map((allowed) => {
                            return allowed ? c : undefined;
                        }),
                    );
                }));
            }),
        ).subscribe((data) => {
            this.loadingGroups = false;
            // Use a map for uniqueness
            const customers = new Map<number, ChainBalanceCustomer>();
            if (!data.length) {
                return;
            }
            data.flatMap((r) => r).forEach((c) => {
                if (c) {
                    const chainBalanceCustomer: ChainBalanceCustomer = {
                        customer: c,
                        loading: false,
                        isOpen: false,
                        hasError: false,
                        employees: [],
                        filteredEmployees: [],
                        balanceTypes: [],
                        displayColumns: [],
                        totals: {},
                    };
                    customers.set(c.id, chainBalanceCustomer);
                }
            });

            this.customers = sort(Array.from(customers.values()), this.current.languageTag, [ (r) => r.customer.name ]);
        });
    }

    panelClick(customer: ChainBalanceCustomer) {
        if (!customer.isOpen) {
            this.getData(customer);
        }
        customer.isOpen = !customer.isOpen;
    }
}
