import { Inject, Pipe, PipeTransform } from '@angular/core';
import { EawMaterialColorHue, MaterialColorService } from '../services/material-color.service';

@Pipe({
    name: 'materialColor',
    standalone: true,
})
export class MaterialColorPipe implements PipeTransform {
    constructor(@Inject(MaterialColorService) private colorService: MaterialColorService) {
    }

    async transform(color: EawMaterialColorHue): Promise<string> {
        return (await this.colorService.colorToTiny(color)).toHexString();
    }
}
