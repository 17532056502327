import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { ShiftPeriodListComponent } from '../../components/shift-period-list/shift-period-list.component';
import { ShiftPeriodService } from '../../http/shift-period.service';
import { ShiftPeriod } from '../../models/shift-period';
import { catchError, of } from 'rxjs';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';

export interface ShiftPeriodListDialogData extends DialogData {
    customerId: number;
    scheduleId: number;
    shiftId: number;
}

@Component({
    selector: 'eaw-shift-period-list-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        MatButton,
        MatDialogActions,
        MatDialogClose,
        ShiftPeriodListComponent,
        InfoLoadingComponent,
    ],
    templateUrl: './shift-period-list-dialog.component.html',
    styleUrl: './shift-period-list-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftPeriodListDialogComponent extends DialogComponent<ShiftPeriodListDialogData, undefined, ShiftPeriodListDialogComponent> implements OnInit {
    private shiftPeriodService = inject(ShiftPeriodService);

    protected periods: ShiftPeriod[] = [];
    protected loading = signal(true);

    constructor() {
        super(undefined, undefined, DialogSize.Small);
    }

    ngOnInit() {
        this.shiftPeriodService.getAll(this.data.customerId, this.data.scheduleId, this.data.shiftId, { 'with[]': [ 'businessUnit' ] }).pipe(catchError(() => of(null))).subscribe((periods) => {
            if (periods) {
                this.periods = periods.data;
            }

            this.loading.set(false);
        });
    }
}
