import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { TodoStatus, TodoStatusResponse } from '../models/todo-status';
import { TodoStatusType } from '../enums/todo-status-type';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class TodoStatusService {

    constructor(@Inject(HttpClient) protected http: HttpClient) { }

    getAll(customerId: number, options?: PaginationOptions): Observable<ArrayPaginatedResponse<TodoStatus>> {
        return this.http.get<ArrayPaginatedResponse<TodoStatusResponse>>(`/customers/${customerId}/todo_statuses`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(TodoStatus));
    }

    create(customerId: number, name: string, type: TodoStatusType) {
        return this.http.post<TodoStatusResponse>(`/customers/${customerId}/todo_statuses`, {
            name,
            type,
        }).pipe(classifyItem(TodoStatus));
    }

    update(customerId: number, statusId: number, name: string) {
        return this.http.put<TodoStatusResponse>(`/customers/${customerId}/todo_statuses/${statusId}`, {
            name,
        }).pipe(classifyItem(TodoStatus));
    }

    delete(customerId: number, statusId: number) {
        return this.http.delete(`/customers/${customerId}/todo_statuses/${statusId}`);
    }
}
