import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { WidgetPropertySettings } from '../../../classes/widget';
import { WidgetSettingsDialogComponent } from '../../widget-settings-dialog/widget-settings-dialog.component';
import { WidgetSettingsDialog } from '../../../classes/widget-settings-dialog';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { TranslateSyncPipe } from '../../../../shared/pipes/translate-sync.pipe';

export interface MiniAbsenceWidgetSettings extends WidgetPropertySettings {
    /**
     * The offset in days from today
     */
    day_offset: 0 | 1;
}

@Component({
    selector: 'eaw-mini-absence-settings-dialog',
    standalone: true,
    imports: [ CommonModule, MatFormFieldModule, MatOptionModule, MatSelectModule, ReactiveFormsModule, WidgetSettingsDialogComponent, TranslatePipe, TranslateSyncPipe ],
    templateUrl: './mini-absence-settings-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrl: './mini-absence-settings-dialog.component.scss' ,
})
export class MiniAbsenceSettingsDialogComponent extends WidgetSettingsDialog<MiniAbsenceWidgetSettings> {
    protected formControl = new FormControl<MiniAbsenceWidgetSettings['day_offset']>(0, { nonNullable: true });

    constructor() {
        super();
    }

    submitted() {
        this.dialogRef.close({
            day_offset: this.formControl.value,
        });
    }
}
