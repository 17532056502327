import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { UnsavedChangesService } from '../services/unsaved-changes.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UnsavedChangesHookService {
    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(UnsavedChangesService) private unsavedChangesService: UnsavedChangesService,
    ) {
        this.uiRouter.transitionService.onBefore({}, (transition) => {
            console.debug('🪝', 'UnsavedChangesHookService.onBefore', transition);

            if (!this.unsavedChangesService.hasUnsaved()) {
                return;
            }

            return lastValueFrom(this.unsavedChangesService.showMessage().afterClosed()).then((result) => {
                if (result?.ok) {
                    this.unsavedChangesService.reset();
                } else {
                    transition.abort();
                }
            }).catch(() => transition.abort());
        });
    }
}
