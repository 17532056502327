<eaw-dialog-header>
    <span title>{{'ROLE_ASSIGNMENT' | translate:'company' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form class="tw-flex tw-flex-col tw-gap-16" [formGroup]="form">
        <eaw-role-autocomplete controlName="role" [customerId]="customerId"></eaw-role-autocomplete>

        <eaw-customer-user-autocomplete controlName="user" [customerId]="customerId"></eaw-customer-user-autocomplete>

        <mat-form-field>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate formControlName="from" placeholder="{{'FROM' | translate | async}}" required>
                <input matEndDate formControlName="to" placeholder="{{'TO' | translate | async}}">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="" [disabled]="this.form.disabled">{{'CANCEL' | translate | async}}</button>
    <button mat-button mat-raised-button color="accent" (click)="close()" [disabled]="form.disabled || form.invalid">{{confirmText | translate | async}}</button>
</mat-dialog-actions>
