import { ChangeDetectionStrategy, Component, inject, Input, OnInit, Signal, signal } from '@angular/core';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { WidgetComponent } from '../../classes/widget-component';
import { DashboardStoreService } from '../../services/dashboard-store.service';
import { DateTime } from 'luxon';
import { finalize, tap } from 'rxjs';
import { SignalInput } from '../../../shared/decorators/signal-input.decorator';
import { Widget } from '../../classes/widget';
import { NumberPipe } from '../../../shared/pipes/number.pipe';

@Component({
    selector: 'eaw-mini-shift-sum-widget',
    standalone: true,
    imports: [
        MiniWidgetContentComponent,
        NumberPipe,
    ],
    templateUrl: './mini-shift-sum-widget.component.html',
    styleUrl: './mini-shift-sum-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniShiftSumWidgetComponent extends WidgetComponent implements OnInit {
    private readonly dashboardStore = inject(DashboardStoreService);

    sum = signal<number>(0);
    subtext = signal<Promise<string> | string>('');
    @Input({ required: true }) @SignalInput() widget!: Signal<Widget>;

    ngOnInit() {
        const employeeId = this.widget().info.employee?.id;
        const customerId = this.widget().info.customer.id;

        if (!employeeId) {
            this.setLoading(false);
            return;
        }

        const startOfMonth = DateTime.now().startOf('month');
        const endOfMonth = DateTime.now().endOf('month');

        this.dashboardStore.getEmployeeShifts(startOfMonth, endOfMonth, customerId, employeeId)
            .pipe(
                tap((shifts) => {
                    this.sum.set(shifts.reduce((sum, x) => sum + x.netLength, 0) / 3600);
                    this.subtext.set(DateTime.now().toFormat('MMMM'));
                }),
                finalize(() => this.setLoading(false)),
            )
            .subscribe();
    }

}
