import { module } from 'angular';
import { Namespace } from '../shared/enums/namespace';
import { Products } from '../shared/enums/products';
import { ICalendarSyncComponent } from './icalendar-sync/i-calendar-sync.component';
import { StateProvider } from '@uirouter/angularjs';

module('eaw.icalendar', [ 'eaw.misc', 'eaw.resource' ])
    .config([ '$stateProvider', function($stateProvider: StateProvider) {
        $stateProvider.state('eaw/app/icalendar', {
            parent: 'eaw/app',
            url: '/icalendar',
            views: {
                'content@': {
                    component: ICalendarSyncComponent,
                },
            },
            data: {
                products: [ Products.ICalendar ],
                i18nextNs: [ Namespace.Calendar ],
                breadcrumb: { key: 'CALENDAR_SYNC', ns: 'navigation' },
            },
        });
    } ]);
