// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<h2 ng-if="$kpi.nothing" ng-i18next="NA"></h2>
<h2 ng-if="!$kpi.nothing" ng-bind="$kpi.value | eawNumber:$kpi.decimals"></h2>
<span ng-i18next="{{::$kpi.type.name}}"></span>
`;

module('eaw.dashboard').component('miniKpiWidget', {
    template: template1,
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$kpi',
    controller: [ 'KpiFactory', function(KpiFactory) {
        const ctrl = this;

        ctrl.$postLink = () => {
            // Get the kpi this one needs (widget.value.kpi_type)
            ctrl.type = KpiFactory.getType(ctrl.mini.widget.settings.kpi_type, ctrl.mini.widget.customer);
            ctrl.kpiRes = KpiFactory.get({
                customer_id: ctrl.mini.widget.customer,
                kpi_type: ctrl.mini.widget.settings.kpi_type,
                // yesterday
                business_date: moment().subtract(1, 'd'),
            });

            Promise.all([
                ctrl.type.$promise,
                ctrl.kpiRes.$promise.then((resp) => {
                    ctrl.value = resp.value;
                    ctrl.nothing = resp.value === undefined;
                }),
            ]).then(() => ctrl.decimals = ctrl.type.type === 'int' ? 0 : 2).finally(() => {
                ctrl.mini.loading(false);
            });
        };

        ctrl.$onDestroy = () => {
            ctrl.type?.$cancelRequest?.();
            ctrl.kpiRes?.$cancelRequest?.();
        };
    } ],
});
