import { TranslateService } from '../../shared/services/translate.service';
import { Shift } from './shift';
import { DateTime } from 'luxon';

export enum ShiftChangeType {
    // New shift created after schedule was published
    New = 'new',
    // Forced change
    EmployeeChange = 'employee_change',
    // Voluntary switching
    EmployeeSwitch = 'employee_switch',
    // Published shift deleted
    Deleted = 'deleted',
    // Edited length or offset of published shift
    Time = 'time_edit',
}

export class ShiftChange {
    type: ShiftChangeType;
    shift?: Shift;

    constructor(type: ShiftChangeType, shift?: Shift) {
        this.type = type;
        this.shift = shift;
    }

    getText(translate: TranslateService) {
        switch (this.type) {
            case ShiftChangeType.New: {
                return translate.t('NEW_SHIFT', 'scheduling');
            }
            case ShiftChangeType.EmployeeChange: {
                return translate.t('FORCED_CHANGE', 'scheduling');
            }
            case ShiftChangeType.EmployeeSwitch: {
                return translate.t('SHIFT_SWAP', 'scheduling');
            }
            case ShiftChangeType.Time: {
                return translate.t('TIME_EDIT', 'scheduling');
            }
            case ShiftChangeType.Deleted: {
                return translate.t('SHIFT_DELETED', 'scheduling');
            }
        }
    }

    getDescriptiveText(nextShift: Shift, translate: TranslateService) {
        switch (this.type) {
            case ShiftChangeType.New: {
                return translate.t('NEW_SHIFT', 'scheduling');
            }
            case ShiftChangeType.EmployeeChange: {
                return this.getEmployeeChangeDescription(nextShift, translate);
            }
            case ShiftChangeType.EmployeeSwitch: {
                return this.getEmployeeSwitchDescription(nextShift, translate);
            }
            case ShiftChangeType.Time: {
                return this.getTimeEditedDescription(nextShift, translate);
            }
            case ShiftChangeType.Deleted: {
                return translate.t('SHIFT_DELETED', 'scheduling');
            }
        }
    }

    private getEmployeeSwitchDescription(nextShift: Shift, translate: TranslateService) {
        return translate.t('SHIFT_SWAP_DESCRIPTION', 'scheduling', {
            prevEmp: this.shift?.employee?.name,
            newEmp: nextShift.employee?.name,
        });
    }

    private getEmployeeChangeDescription(nextShift: Shift, translate: TranslateService) {
        if (!nextShift.employee && this.shift?.employee) {
            return translate.t('FORCED_CHANGE_DESCRIPTION_REMOVE_EMP', 'scheduling', {
                name: this.shift.editedByUser?.name,
                emp: this.shift.employee.name,
            });
        }

        if (!this.shift?.employee && nextShift.employee) {
            return translate.t('FORCED_CHANGE_DESCRIPTION_ADD_EMP', 'scheduling', {
                name: this.shift?.editedByUser?.name,
                emp: nextShift.employee.name,
            });
        }

        return translate.t('FORCED_CHANGE_DESCRIPTION', 'scheduling', {
            name: this.shift?.editedByUser?.name,
            prevEmp: this.shift?.employee?.name,
            newEmp: nextShift.employee?.name,
        });
    }

    private getTimeEditedDescription(nextShift: Shift, translate: TranslateService) {
        return translate.t('TIME_EDIT_DESCRIPTION', 'scheduling', {
            name: this.shift?.editedByUser?.name,
            prevFrom: this.shift?.from?.toLocaleString(DateTime.TIME_SIMPLE),
            prevTo: this.shift?.to?.toLocaleString(DateTime.TIME_SIMPLE),
            newFrom: nextShift.from?.toLocaleString(DateTime.TIME_SIMPLE),
            newTo: nextShift.to?.toLocaleString(DateTime.TIME_SIMPLE),
        });
    }
}
