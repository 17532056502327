<eaw-page-header [fabButton]="fabButton" [buttons]="headerButtons">
    <span title>{{ 'SETTING_plural' | translate | async }}</span>
</eaw-page-header>

<mat-card>
    <eaw-data-table
        [columns]="columns"
        [request]="request"
        initialSortBy="id"
        (paginationChange)="updateTable($event)">
    </eaw-data-table>
</mat-card>
