import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { ProductName } from '../../shared/enums/products';

export interface ObserverResponse extends ApiResponse {
    class: string;
    product: ProductName;
    name: string;
    description: string
}

export interface SettingGroupObserverResponse {
    class: string;
    created_at: string;
    description: string
    from: string;
    id: number;
    name: string;
    product_name: ProductName;
    setting_group_id: number;
    to: string | null;
    updated_at?: string | null;
}

export class Observer {
    class: string;
    name: string;
    description: string;
    product: ProductName;

    constructor(data: ObserverResponse) {
        this.class = data.class;
        this.name = data.name;
        this.description = data.description;
        this.product = data.product;
    }
}

export class SettingGroupObserver {
    class: string;
    createdAt: DateTime;
    description: string;
    from: DateTime;
    id: number;
    name: string;
    product: ProductName;
    settingGroupId: number;
    to: DateTime | null;
    updatedAt?: DateTime | null;

    constructor(data: SettingGroupObserverResponse) {
        this.class = data.class;
        this.createdAt = stringToDateTime(data.created_at);
        this.description = data.description;
        this.from = stringToDateTime(data.from);
        this.id = data.id;
        this.name = data.name;
        this.product = data.product_name;
        this.settingGroupId = data.setting_group_id;
        this.to = data.to ? stringToDateTime(data.to) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
    }
}

