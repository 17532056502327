import { module } from 'angular';

const template1 = `<md-dialog id="add-widget-dialog">
    <eaw-dialog-header-old heading="'widgets:ADD_WIDGET' | i18next"></eaw-dialog-header-old>

    <md-dialog-content ng-if="!awdCtrl.loaded">
        <md-progress-circular  class="md-progress-center"></md-progress-circular>
    </md-dialog-content>

    <md-dialog-content ng-if="awdCtrl.loaded">
        <md-tabs md-stretch-tabs="always" md-dynamic-height>
            <md-tab label="{{'widgets:WIDGET_plural' | i18next}}">
                <md-list>
                    <md-list-item ng-repeat="widget in awdCtrl.normalWidgets track by widget.key" ng-click="awdCtrl.add(widget)">
                        <md-icon ng-bind="widget.icon"></md-icon>
                        <p ng-i18next="{{::widget.i18name}}"></p>
                    </md-list-item>
                </md-list>

                <div class="all-widgets-added" ng-if="!awdCtrl.normalWidgets.length">
                    <md-icon ng-bind="'widgets'"></md-icon>
                    <span ng-i18next="widgets:ALL_NORMAL_ADDED"></span>
                </div>
            </md-tab>

            <md-tab label="{{'widgets:MINI_WIDGET_plural' | i18next}}">
                <md-list>
                    <md-list-item ng-repeat="widget in awdCtrl.miniWidgets track by widget.key" ng-click="awdCtrl.add(widget)">
                        <md-icon ng-bind="widget.icon"></md-icon>
                        <p ng-i18next="{{::widget.i18name}}"></p>
                    </md-list-item>
                </md-list>

                <div class="all-widgets-added" ng-if="!awdCtrl.miniWidgets.length">
                    <md-icon ng-bind="'widgets'"></md-icon>
                    <span ng-i18next="widgets:ALL_MINI_ADDED"></span>
                </div>
            </md-tab>
        </md-tabs>
    </md-dialog-content>

    <md-dialog-actions>
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.dashboard').factory('addWidgetDialog', [ '$mdDialog', function addWidgetDialog($mdDialog: any) {
    // @ts-ignore
    const fac = this;

    fac.show = (normalWidgets: any[], miniWidgets: any[], widgetInfos: any) => $mdDialog.show({
        template: template1,
        controller: 'addWidgetDialogCtrl',
        controllerAs: 'awdCtrl',
        bindToController: true,
        locals: {
            miniWidgets: miniWidgets || [],
            normalWidgets: normalWidgets || [],
            widgetInfos,
        },
    });

    return fac;
} ]);
