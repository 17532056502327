import { CouponGraphic } from './interfaces';
import { Translation } from '../../shared/interfaces/translation';

export class CouponTerms implements Translation {
    default_language: string;
    translations: Record<string, string>;

    constructor(terms: Translation) {
        this.default_language = terms.default_language;
        this.translations = terms.translations;
    }

    getText(language_code: string) {
        return this.translations[language_code] ?? this.translations[this.default_language];
    }
}

export class CouponType {
    id: string;
    name: string;
    owner_id: string;
    terms: CouponTerms;
    icon: CouponGraphic;
    background_graphics: Record<string, CouponGraphic>;

    constructor(props: { id: string, owner_id: string, name: string, terms: Translation, icon: CouponGraphic, background_graphics: Record<string, CouponGraphic> }) {
        this.id = props.id;
        this.name = props.name;
        this.owner_id = props.owner_id;
        this.terms = new CouponTerms(props.terms);
        this.icon = props.icon;
        this.background_graphics = props.background_graphics;
    }

    getBackgroundUrl(): string {
        const keys = Object.keys(this.background_graphics);
        const key = keys[Math.floor(Math.random()*keys.length)];

        return this.background_graphics[key || '']?.url ?? '';
    }
}
