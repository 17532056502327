// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
module('eaw.calendar').service('DataSource', function DataSourceClass() {
    class DataSource {
        customer;
        createRequiresEmployee;
        constructor(customer) {
            this.customer = customer;
            this.createRequiresEmployee = true;
        }

        /**
         * @param {moment.Moment} from
         * @param {moment.Moment} [to]
         * @returns {moment.Moment[]}
         */
        static assignFromTo(from, to) {
            if (moment.isMoment(to)) {
                from = from.startOf('d');
                to = to.endOf('d');
            } else {
                from = from.clone().startOf('d').add(12, 'h');
                to = from.clone().startOf('d').add(13, 'h');
            }
            return [ from, to ];
        }
    }
    return DataSource;
});
