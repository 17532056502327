import { Component, DestroyRef, EventEmitter, inject, Inject, Input, Output, ViewChild } from '@angular/core';
import { DataTableColumnType } from '../../../../../data-table/interfaces/data-table-columns';
import { DataTableDateTimeColumn } from '../../../../../data-table/types/data-table-date-time-column';
import { DataTableHeader } from '../../../../../data-table/types/data-table-header';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../../../data-table/types/data-table-button-column';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../../../data-table/types/data-table';
import { UserAccessService } from '../../../../http/user-access.service';
import { DataTableComponent } from '../../../../../data-table/data-table.component';
import { DateTime } from 'luxon';
import { UserAccess } from '../../../../models/user-access';
import { UserAccessDialogService } from '../../dialog/user-access-dialog.service';
import { User } from '../../../../../shared/models/user';
import { of, tap } from 'rxjs';
import { PermissionCheckService } from '../../../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { mockArrayPaginatedResponse } from '../../../../../../mocks/paginated-response.mock';
import { Customer } from '../../../../../shared/models/customer';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaw-user-access',
    templateUrl: './user-access.component.html',
    styleUrl: './user-access.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class UserAccessComponent implements EawDataTable {
    private readonly destroyRef = inject(DestroyRef);
    // Receives customer and user, gets the old version since the container runs ajs code
    @Input({ required: true }) customer!: Customer;

    @Input({ required: true }) user?: User;

    // Output that assigns the parent reload value
    @Output() reload = new EventEmitter<boolean>();
    // Gets table
    @ViewChild(DataTableComponent) table!: DataTableComponent<UserAccess>;

    // Columns for table
    columns: DataTableColumnType<UserAccess>[] = [
        new DataTableDateTimeColumn({
            value: 'from',
            format: DateTime.DATETIME_MED_WITH_WEEKDAY,
            header: new DataTableHeader({
                i18n: 'FROM',
                sortBy: 'from',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            format: DateTime.DATETIME_MED_WITH_WEEKDAY,
            header: new DataTableHeader({
                i18n: 'TO',
                sortBy: 'to',
            }),
        }),
        new DataTableButtonColumn({
            buttons: [
                // Edit/delete buttons. Hidden if user does not have permission, or to date is in the past
                {
                    icon: 'edit',
                    tooltip: { key: 'EDIT' },
                    click: (cell) => this.updateAccess(cell),
                    show: (item) => this.permissionCheckService.isAllowed(`customers.${this.customer['id']}.users.${item.userId}.update`),
                    hide: (item) => of(!!item.to && item.to <= DateTime.now()),
                },
                {
                    icon: 'person_off',
                    type: 'warn',
                    tooltip: { key: 'REMOVE_ACCESS' },
                    click: (cell) => this.deleteAccess(cell),
                    show: (item) => this.permissionCheckService.isAllowed(`customers.${this.customer['id']}.users.${item.userId}.delete`),
                    hide: (item) => of(!!item.to && item.to <= DateTime.now()),
                },
            ],
        }),
    ];

    // Stores table data
    request?: DataTableRequest = of(mockArrayPaginatedResponse());

    constructor(
        @Inject(UserAccessService) private userAccessService: UserAccessService,
        @Inject(UserAccessDialogService) private dialog: UserAccessDialogService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
    ) {
    }

    // Open add user dialog box, then update when submitted
    addAccess() {
        this.dialog.createAccess(this.customer, this.user).afterClosed()
            .pipe(
                tap((update) => update ? this.table.refresh() : null),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    // Open update dialog box, update table on submit, re-enable cell if cancelled/failed
    async updateAccess(cell: DataTableButtonCell<UserAccess>) {
        this.dialog.updateAccess(this.customer, cell.item).afterClosed()
            .pipe(
                tap((update) => update ? this.table.refresh() : (cell.disabled.set(false))),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    // Open delete dialog box, update table on submit, re-enable cell if cancelled/failed
    async deleteAccess(cell: DataTableButtonCell<UserAccess>) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.dialog.deleteAccess(this.customer, this.user!)
            .afterClosed()
            .pipe(
                tap((remove) => remove ? this.table.refresh() : (cell.disabled.set(false))),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    // Update table with new data and set parent reload to true, updating the user group table
    updateTable(pagination?: Partial<DataTablePagination>) {
        this.reload.emit(true);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.request = this.userAccessService.getAllForUser(this.customer.id, this.user!.id, pagination);
    }
}
