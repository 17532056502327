// @ts-nocheck
import { filter } from 'lodash-es';
import { forEach } from 'lodash-es';
import { orderBy } from 'lodash-es';
import { find } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { chunk } from 'lodash-es';
import { groupBy } from 'lodash-es';
module('eaw.leave-shifts').component('leaveShifts', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <div class="md-title" ng-i18next="absences:ABSENCE_BASIS"></div>
            <div class="md-subhead">{{::$leave.object.from | moment}} - {{::$leave.object.to | moment}}</div>
        </md-card-header-text>

        <card-btn-float
                on-click="$leave.save()"
                mdi-icon="save"
                tooltip="'SAVE' | i18next"
                ng-disabled="!$leave.canSave">
        </card-btn-float>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <form name="leaveShiftForm" ng-disabled="$leave.saving || !$leave.permissions.create && !$leave.permissions.update">
            <table class="table table-vmiddle table-condensed sticky-header">
                <thead>
                <tr>
                    <th ng-i18next="BUSINESS_DATE"></th>
                    <th ng-i18next="LOCATION"></th>
                    <th colspan="2"></th>
                    <th colspan="2" ng-i18next="leaveshifts:DETAILED_BREAK"></th>
                </tr>
                </thead>
                <tbody ng-repeat="bd in $leave.businessDates">
                <tr ng-repeat="shift in $leave.shifts[bd.key].shifts track by $index">
                    <td ng-if="$first" rowspan="{{$leave.shifts[bd.key].shifts.length}}">
                        <div layout="row" layout-align="start center">
                            <div class="tw-mr-12" ng-bind="bd.date | moment:'ll'"></div>

                            <eaw-icon-button
                                    ng-if="$leave.permissions.create"
                                    stop-propagate
                                    type="accent"
                                    icon="add"
                                    ng-click="$leave.addLeaveShift(bd.date)">
                            </eaw-icon-button>
                        </div>
                    </td>
                    <td>
                        <div ng-bind="shift.customer.name"></div>
                        <small md-colors="{color: 'red'}" ng-if="shift.edited">Shift edited</small>
                    </td>
                    <td>
                        <md-input-container>
                            <label ng-i18next="DATE"></label>
                            <eaw-date-picker min-date="$leave.shifts[bd.key].minDate"
                                             max-date="$leave.shifts[bd.key].maxDate"
                                             no-label="true"
                                             ng-model="shift.date"
                                             ng-change="$leave.onChangeDate(shift, bd.date, $index)">
                            </eaw-date-picker>
                        </md-input-container>
                    </td>
                    <td>
                        <create-leave-shift ng-model="shift"
                                            date="shift.date"
                                            advanced="shift.advanced"
                                            ng-change="$leave.onChange(shift, bd.date, $index)">
                        </create-leave-shift>
                    </td>
                    <td class="text-center">
                        <md-checkbox class="condensed" ng-model="shift.advanced"></md-checkbox>
                    </td>
                    <td class="text-center">
                        <eaw-icon-button
                                ng-if="$leave.permissions.delete"
                                stop-propagate
                                type="warn"
                                ng-disabled="$leave.shifts[bd.key].shifts.length == 1 && !$leave.shifts[bd.key].shifts[0].id"
                                icon="delete"
                                ng-click="$leave.deleteLeaveShift(shift, bd.key, $index)">
                        </eaw-icon-button>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
    </md-card-content>
</md-card>
`,
    controllerAs: '$leave',
    bindings: {
        object: '<',
        objectType: '@',
        leaveShifts: '<',
        customer: '<',
        employee: '<',
        permissions: '<', // update, delete, create
    },
    controller: [ 'LeaveShiftFactory', 'deleteItemAlert', 'loadingBar', 'ToastService', '$state', function(LeaveShiftFactory, deleteItemAlert, loadingBar, ToastService, $state) {
        const ctrl = this;
        const df = 'L';
        ctrl.$onInit = () => {
            ctrl.from = ctrl.object.from.clone().startOf('d');
            ctrl.to = ctrl.object.to.clone().startOf('d');
            ctrl.minDate = ctrl.from.clone().subtract(1, 'd');
            ctrl.maxDate = ctrl.to.clone().add(1, 'd');
            ctrl.setUp();
            ctrl.setCanSave();
        };
        ctrl.setUp = () => {
            const leaveShifts = groupBy(ctrl.leaveShifts, (shift) => {
                shift.date = shift.from.clone();
                return shift.business_date.format(df);
            });
            // Insert object for each day that has none, for easy fill-out
            const shifts = {};
            ctrl.businessDates = [];
            for (let i = 0; i < ctrl.to.diff(ctrl.from, 'd') + 3; i++) {
                const date = ctrl.from.clone().subtract(1, 'd').add(i, 'd');
                ctrl.businessDates.push({
                    key: date.format(df),
                    date: date.clone(),
                });
                shifts[date.format(df)] = ctrl.makeDefaultShiftObject(date);
            }
            Object.entries(leaveShifts).forEach(([ bd, shiftsOnDate ]) => {
                const date = moment(bd, df);
                shifts[bd] = ctrl.makeShiftObject(date, shiftsOnDate);
            });
            ctrl.shifts = shifts;
        };
        ctrl.makeDefaultShiftObject = (date) => ctrl.makeShiftObject(date, [ {
            customer: ctrl.customer,
            date: date.clone(),
        } ]);
        ctrl.makeShiftObject = (date, shifts) => {
            let minDate = date.clone().subtract(1, 'd');
            let maxDate = date.clone().add(1, 'd');
            if (minDate.isBefore(ctrl.minDate)) {
                minDate = ctrl.minDate.clone();
            } else if (maxDate.isAfter(ctrl.maxDate)) {
                maxDate = ctrl.maxDate.clone();
            }
            return {
                advanced: false,
                date: date.clone(),
                minDate,
                maxDate,
                business_date: date.clone(),
                shifts,
            };
        };
        ctrl.addLeaveShift = (date) => {
            const key = date.format(df);
            if (!find(ctrl.businessDates, { key })) {
                ctrl.businessDates = orderBy(ctrl.businessDates.concat({
                    key,
                    date: date.clone(),
                }), 'date');
                ctrl.shifts[key] = ctrl.makeDefaultShiftObject(date);
            }
            if (date.isAfter(ctrl.to)) {
                date = ctrl.to.clone();
            } else if (date.isBefore(ctrl.from)) {
                date = ctrl.from.clone();
            }
            ctrl.shifts[key].shifts.push({
                date,
                customer: ctrl.customer,
            });
        };
        ctrl.onChangeDate = (shift, date, index) => {
            if (shift.date) { // copy date to trigger $onChanges
                shift.date = shift.date.clone().startOf('d');
            }
            ctrl.onChange(shift, date, index);
        };
        ctrl.onChange = (shift, date, index) => {
            shift.edited = ctrl.validShift(shift);
            shift.business_date = date.clone();
            ctrl.shifts[date.format(df)].shifts[index] = shift;
            ctrl.setCanSave();
        };
        ctrl.validShift = (shift) => shift.from?.isValid?.() && shift.to?.isValid?.() && shift.break_length >= 0;
        ctrl.setCanSave = () => {
            ctrl.canSave = Object.values(ctrl.shifts).flatMap((obj) => obj.shifts).some(ctrl.validShift);
        };
        ctrl.save = () => {
            ctrl.saving = true;
            // Create all new leave shifts
            const newShifts = [];
            const oldShifts = [];
            forEach(ctrl.shifts, (obj) => {
                forEach(obj.shifts, (shift) => {
                    if (!ctrl.validShift(shift)) {
                        return;
                    }
                    if (!shift.break_offset) {
                        shift.break_offset = shift.to.diff(shift.from, 's') / 2 - shift.break_length / 2;
                    }
                    if (shift.edited) {
                        if (!shift.id) {
                            newShifts.push(shift);
                        } else if (!shift.readOnly) {
                            oldShifts.push(shift);
                        }
                    }
                });
            });
            let create = Promise.resolve();
            const saving = Promise.all(chunk(newShifts, 10).map((shifts) => create = create.then(() => Promise.all(shifts.map((shift) => LeaveShiftFactory.create({
                ...shift,
                customer_id: ctrl.customer.id,
                employee_id: ctrl.employee.id,
                model: ctrl.objectType,
                model_id: ctrl.object.id,
            }).$promise)))));
            // Update all old leave shifts (if changed)
            let update = Promise.resolve();
            const updating = Promise.all(chunk(oldShifts, 10).map((shifts) => update = update.then(() => Promise.all(shifts.map((shift) => LeaveShiftFactory.update({
                ...shift,
                customer_id: ctrl.customer.id,
                employee_id: ctrl.employee.id,
                model: ctrl.objectType,
                model_id: ctrl.object.id,
            }).$promise)))));
            loadingBar.open();
            Promise.all([ saving, updating ]).finally(() => {
                $state.reload().finally(() => {
                    ctrl.saving = false;
                    loadingBar.close();
                });
            });
        };
        ctrl.deleteLeaveShift = async (shift, key, index) => {
            if (!shift.id) {
                return ctrl.onDelete(key, index, shift.date);
            }
            await deleteItemAlert.alertSimple();
            await LeaveShiftFactory.delete({
                customer_id: ctrl.customer.id,
                employee_id: ctrl.employee.id,
                model: ctrl.objectType,
                model_id: ctrl.object.id,
                id: shift.id,
            }).$promise;
            ToastService.tToast('leaveshifts:DELETED');
            ctrl.onDelete(key, index, shift.date);
        };
        ctrl.onDelete = (key, index, date) => {
            delete ctrl.shifts[key].shifts[index];
            ctrl.shifts[key].shifts = filter(ctrl.shifts[key].shifts);
            ctrl.saving = false;
            if (!ctrl.shifts[key].shifts.length) {
                ctrl.shifts[key] = ctrl.makeDefaultShiftObject(date);
            }
            ctrl.setCanSave();
        };
    } ],
});
