// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.scheduling').controller('scheduleShiftsLoaderCtrl', [ '$scope', '$timeout', 'mdPanelRef', 'Shift', 'shiftEvents', function scheduleShiftsLoaderCtrl($scope, $timeout, mdPanelRef, Shift, shiftEvents) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.loaded = 0;
        ctrl.schedule = ctrl.scheduleViewCtrl.schedule;
        ctrl.resources = [];
        ctrl.shiftsTranslation = t('scheduling:LOADING_SHIFT', {
            count: ctrl.schedule.shifts_count,
        });

        $scope.$on('schedule_view:destroyed', ctrl.cancelRequests);

        const { perPage, pages } = ctrl.calculatePerPage();
        const promises = new Array(pages).fill(0).map((x, i) => {
            ctrl.resources.push(ctrl.getShifts(i + 1, perPage));

            return ctrl.resources[i].$promise.then((r) => {
                ctrl.loaded += r.data.length;
                ctrl.loadPercentage = (ctrl.loaded / ctrl.schedule.shifts_count) * 100;

                ctrl.schedule.appendShifts(r.data);
                ctrl.attachData();
                shiftEvents.trigger.loadProgress(ctrl.loadPercentage);

                return r;
            });
        });

        return Promise.all(promises).finally(ctrl.onFinally);
    };

    ctrl.attachData = () => {
        ctrl.schedule.shifts.forEach((s) => {
            // Attach employees
            s.employee = ctrl.scheduleViewCtrl.employees?.find((e) => e.id === s.employee_id);

            const periodQuals = s.all_qualifications?.filter((q) => q.pivot?.qualified_type === 'period');
            const unitQuals = s.all_qualifications?.filter((q) => q.pivot?.qualified_type === 'business_unit');
            s.periods?.forEach((p) => {
                // Attach units on loaded shift's periods
                p.business_unit = ctrl.scheduleViewCtrl.businessUnits?.find((u) => u.id === p.business_unit_id);

                // Attach qualifications on the period
                p.qualifications = periodQuals?.filter((pq) => pq.pivot?.qualified_id === p.id) || [];

                // Attach qualifications on the business unit
                if (p.business_unit) {
                    p.business_unit.qualifications = unitQuals?.filter((uq) => uq.pivot?.qualified_id === p.business_unit.id) || [];
                }
            });
        });
    };

    ctrl.cancelRequests = () => {
        ctrl.resources.forEach((res) => res?.$cancelRequest?.());
    };

    ctrl.onFinally = () => {
        shiftEvents.trigger.loaded();

        // Small timeout to let the user see 100% before it disappears
        $timeout(() => {
            mdPanelRef.close();
        }, 200);
    };

    ctrl.calculatePerPage = () => {
        const maxPages = 8;
        const minPerPage = 100;
        const perPage = Math.max(minPerPage, Math.ceil(ctrl.schedule.shifts_count / maxPages));
        const pages = Math.ceil(ctrl.schedule.shifts_count / perPage);

        return {
            perPage,
            pages,
        };
    };

    ctrl.getShifts = (page, perPage) => Shift.getAllForSchedule(ctrl.schedule.customer_id, ctrl.schedule.id, {
        with: ctrl.schedule.getShiftWiths([ 'periods.businessUnit', 'employee', 'periods.qualifications' ]),
        from: ctrl.schedule.getFrom(),
        to: ctrl.schedule.getTo(),
        page,
        per_page: perPage,
        count: 'comments',
    });

    return this;
} ]);
