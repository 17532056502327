import { APP_INITIALIZER, NgModule } from '@angular/core';
import { I18nextLoaderService } from './services/i18next-loader.service';
import { CurrentLoaderService } from './services/current-loader.service';
import { BaseLoaderService } from './services/base-loader.service';

@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [ BaseLoaderService ],
            useFactory: (baseLoaderService: BaseLoaderService) => {
                return () => baseLoaderService.init();
            },
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [ CurrentLoaderService ],
            useFactory: (currentLoaderService: CurrentLoaderService) => {
                return () => currentLoaderService.init();
            },
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [ I18nextLoaderService ],
            useFactory: (i18nextInitService: I18nextLoaderService) => {
                return () => i18nextInitService.init();
            },
        },
    ],
})
export class InitializerModule {
}
