import { inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { formatHttpParams } from '../utils/format-http-params';
import { HttpClient } from '@angular/common/http';
import { Product, ProductResponse } from '../models/product';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { ProductSubscriber, ProductSubscriberResponse } from '../../admin/models/product-subscriber';
import { map, Observable } from 'rxjs';

interface ArrayPaginatedSubscriberResponse<T> extends ArrayPaginatedResponse<T> {
    customers_count: number;
    groups_count: number;
    total_customers_count: number;
    users_count: number;
}

export interface ProductCounters {
    customersCount: number;
    groupsCount: number;
    totalCustomersCount: number;
    usersCount: number;
}

interface ArrayPaginatedSubscriber<T> extends ProductCounters, ArrayPaginatedResponse<T> {
}

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    private readonly http = inject(HttpClient);

    getAll(paginationOptions: PaginationOptions = {}) {
        return this.http.get<ArrayPaginatedResponse<ProductResponse>>(`/products`, {
            params: formatHttpParams(paginationOptions),
        }).pipe(classifyArrayPaginatedResponse(Product));
    }

    get(name: string) {
        return this.http.get<ProductResponse>(`/products/${name}`).pipe(classifyItem(Product));
    }

    getSubscribers(name: string, options: PaginationOptions): Observable<ArrayPaginatedSubscriber<ProductSubscriber>> {
        return this.http.get<ArrayPaginatedSubscriberResponse<ProductSubscriberResponse>>(`/products/${name}/subscribers`, {
            params: { ...options },
        }).pipe(map((data) => {
            const { from, to, total, per_page, last_page, current_page } = data;
            return ({
                data: data.data.map((e) => new ProductSubscriber(e)),
                customersCount: data.customers_count,
                groupsCount: data.groups_count,
                totalCustomersCount: data.total_customers_count,
                usersCount: data.users_count,
                from,
                to,
                total,
                per_page,
                current_page,
                last_page,
            });
        },
        ));
    }
}
