import { Inject, Injectable } from '@angular/core';
import { Directory } from '../models/directory';
import type { DirectoryResponse } from '../interfaces/directory-response';
import type { Updates } from '../interfaces/updates';
import { Observable } from 'rxjs';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';

/**
 * @description
 * The goal is to provide the necessary functions we need for the directory functionality to work
 * The functions are: getRoot, getDirectory, createDirectory, updateDirectory and deleteDirectory
 * DirectoryResponse is the interface that we use to denote the type of every CRUD operation
 * The classifyItem function maps what we have receive of data from the request into an object
 */
@Injectable({
    providedIn: 'root',
})
export class DirectoryService {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
    }

    /**
     * @param {number} customerId - The customer id
     * @param {number} fileId - The file id
     * @param {string[]} withs - Other files related to the file we want to get
     * @description
     * Fetches the root directory and returns an observable from the get request with the Directory type.
     */
    getRoot(customerId: number, fileId: number, withs?: string[]): Observable<Directory> {
        return this.http.get<DirectoryResponse>(`/customers/${customerId}/fs_directories/${fileId}`, {
            params: formatHttpParams({ 'with[]': withs }),
        }).pipe(classifyItem(Directory));
    }

    /**
     * @param {number} customerId - The customer id
     * @param {number} directoryId - The directory id
     * @param {string[]} withs - Other files related to the file we want to get
     * @description
     * Fetches the directory and returns an observable from the get request with the Directory type.
     */
    getDirectory(customerId: number, directoryId: number, withs?: string[]): Observable<Directory> {
        return this.http.get<DirectoryResponse>(`/customers/${customerId}/fs_directories/${directoryId}`, {
            params: formatHttpParams({ 'with[]': withs }),
        }).pipe(classifyItem(Directory));
    }

    /**
     * @param {number} customerId - The customer id
     * @param {number} directoryId - The directory id
     * @param {string} name - The name of the directory
     * @param {string[]} withs - Other files related to the file we want to get
     * @description
     * Creates the directory and returns an observable from the post request with the Directory type.
     */

    createDirectory(customerId: number, directoryId: number, name: string, withs?: string[]): Observable<Directory> {
        return this.http.post<DirectoryResponse>(`/customers/${customerId}/fs_directories/${directoryId}`, {
            customer: customerId,
            directory_id: directoryId,
            name,
            'with[]': withs,
        }).pipe(classifyItem(Directory));
    }

    /**
     * @param {number} customerId - The customer id
     * @param {number} directoryId - The directory id
     * @param {Updates} updates - The updates we wish to make
     * @param {string[]} withs - Other files related to the file we want to get
     * @description
     * Updates the directory and returns an observable from the put request with the Directory type.
     */
    updateDirectory(customerId: number, directoryId: number, updates: Updates, withs?: string[]): Observable<Directory> {
        return this.http.put<DirectoryResponse>(`/customers/${customerId}/fs_directories/${directoryId}`, {
            ...updates,
            'with[]': withs,
        }).pipe(classifyItem(Directory));
    }

    /**
     * @param {number} customerId - The customer id
     * @param {number} directoryId - The directory id
     * @description
     * Deletes the directory and returns an observable of type any.
     */
    deleteDirectory(customerId: number, directoryId: number) {
        return this.http.delete<undefined>(`/customers/${customerId}/fs_files/${directoryId}`);
    }
}
