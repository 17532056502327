// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
/**
 * @ngdoc service
 * @name SchedulingLimits
 */
module('eaw.scheduling.limits').service('SchedulingLimits', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const resource = EawResource.create('/customers/:customer/scheduling_limits/:limit');
    const _businessDates = [ 'from', 'to' ];
    const _attributes = [ 'from', 'to', 'limit' ];
    return function SchedulingLimits(customerId) {
        const _customerId = customerId;
        this.create = {
            permission() {
                return `customers.${_customerId}.scheduling_limits.create`;
            },
            query(limitData) {
                return resource.save({
                    customer: _customerId,
                }, {
                    ...pick(limitData, _attributes),
                    _businessDates,
                });
            },
        };
        this.get = {
            permission(limitId) {
                return `customers.${_customerId}.scheduling_limits.${limitId}.get`;
            },
            query(limitId) {
                return resource.get({
                    customer: _customerId,
                    limit: limitId,
                });
            },
        };
        this.getStats = {
            permission() {
                return `customers.${_customerId}.scheduling_limits.*.get`;
            },
            query(time) {
                return EawResource.create('/customers/:customer/scheduling_limits/stats', {}, {
                    get: {
                        method: 'GET',
                        headers: {
                            'X-Ignore-Error': true,
                        },
                    },
                }).get({
                    customer: _customerId,
                    time,
                });
            },
        };
        this.getAll = {
            permission() {
                return `customers.${_customerId}.scheduling_limits.*.get`;
            },
            query(pagination, withRelations) {
                return resource.get({
                    customer: _customerId,
                    'with[]': withRelations,
                    ...Pagination.getParams(pagination, [ 'from', 'to', 'include_used' ]),
                });
            },
        };
        this.update = {
            permission(limitId) {
                return `customers.${_customerId}.scheduling_limits.${limitId}.update`;
            },
            /**
             *
             * @param {integer} limitId
             * @param {Object} limitData
             * @param {Moment} limitData.from
             * @param {Moment} limitData.to
             * @param {Moment} limitData.limit
             */
            query(limitId, limitData) {
                return resource.update({
                    customer: _customerId,
                    limit: limitId,
                }, {
                    ...pick(limitData, _attributes),
                    _businessDates,
                });
            },
        };
        this.delete = {
            permission(limitId) {
                return `customers.${_customerId}.scheduling_limits.${limitId}.delete`;
            },
            query(limitId) {
                return resource.delete({
                    customer: _customerId,
                    limit: limitId,
                });
            },
        };
    };
} ]);
