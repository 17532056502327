<form *ngIf="form" [formGroup]="form" autocomplete="off">
    <section id="availability" class="tw-flex tw-flex-col tw-gap-y-20">
        <div class="tw-flex tw-flex-row" formGroupName="interval">
            <mat-form-field eawDatePickerOptions class="tw-w-1/2 tw-mr-4">
                <mat-label>{{ 'FROM' | translate | async }}</mat-label>
                <input formControlName="from" matInput (dateChange)="fromChanged($event)" [matDatepicker]="$any(startPicker)" autocomplete="off">
                <mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field eawDatePickerOptions class="tw-w-1/2 tw-ml-4">
                <mat-label>{{ 'TO' | translate | async }}</mat-label>
                <input formControlName="to" matInput (dateChange)="toChanged($event)" [matDatepicker]="$any(endPicker)" autocomplete="off" [min]="minTo">
                <mat-hint>{{ 'INTERVAL_TO_HINT' | translate: 'availabilities' | async }}</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>{{ 'NUM_ROTATING_WEEKS' | translate: 'availabilities' | async }}</mat-label>
            <mat-select formControlName="weeks">
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="md:tw-col-span-2">
            <mat-label>{{ 'COMMENT' | translate | async }}</mat-label>
            <textarea matInput formControlName="comment"></textarea>
        </mat-form-field>
    </section>
    <section class="week" *ngFor="let week of form?.controls?.availabilityWeeks?.controls; index as wIndex;" [formGroup]="week">
        <h1>{{'WEEK_N' | translate: 'general':{ number: wIndex + 1 } | async }}</h1>
        <mat-form-field>
            <mat-label>{{ 'WANTED_DAYS' | translate: 'availabilities' | async }}</mat-label>
            <input matInput type="number" inputmode="numeric" formControlName="wantedDays" step="1">
            <mat-hint>{{ 'WANTED_DAYS_HINT' | translate: 'availabilities' | async }}</mat-hint>
        </mat-form-field>

        <table class="weekTable">
            <tr class="day" *ngFor="let day of week.controls.days?.controls; index as dIndex" [formGroup]="day">
                <th>
                    <label for="dayType{{dIndex}}">{{ getDayName(dIndex) }}</label>
                </th>
                <td>
                    <mat-radio-group id="dayType{{dIndex}}" formControlName="type">
                        <mat-radio-button *ngFor="let type of types" [value]="type.value">
                            {{ type.text | translate: 'availabilities' | async }}
                        </mat-radio-button>
                    </mat-radio-group>
                    <div class="tw-flex tw-items-center">
                        <div class="time" *ngIf="day.controls.intervals">
                            <ng-container *ngFor="let times of day.controls.intervals?.controls; index as tIndex" [formGroup]="times">
                                <eaw-time-input [label]="'FROM' | translate" (focusin)="onIntervalFocusIn(day, tIndex)" formControlName="from"></eaw-time-input>
                                <eaw-time-input [label]="'TO' | translate" (focusin)="onIntervalFocusIn(day, tIndex)" formControlName="to"></eaw-time-input>

                                <button tabindex="-1" disabled type="button" class="tw-invisible" *ngIf="!showX(day, tIndex)" mat-icon-button><mat-icon>close</mat-icon></button>
                                <button tabindex="-1" type="button" *ngIf="showX(day, tIndex)" mat-icon-button (click)="removeInterval(day, tIndex)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </td>
            </tr>
        </table>

    </section>
</form>
