import { DateTime } from 'luxon';
import { EmployeeResponse } from '../../../shared/models/employee';
import { UserResponse } from '../../../shared/models/user';
import { stringToDateTime } from '../../../shared/utils/string-to-date-time';
import { RoleResponse } from './role';
import { ApiResponse } from '../../../shared/interfaces/api-response';

export interface RoleAssignmentResponse extends ApiResponse {
    id: number;
    updated_at: string;
    created_at: string;
    user_id: number
    role_id: number
    from: string
    to?: string | null
    role?: RoleResponse
    user?: UserResponse
    employees?: EmployeeResponse[]
}

export class RoleAssignment {
    id: number;
    userId: number;
    roleId: number;
    from: DateTime;
    to: DateTime | null;
    role?: RoleResponse;
    employees?: EmployeeResponse[];
    user?: UserResponse;
    createdAt: DateTime;
    updatedAt: DateTime;

    constructor(assignment: RoleAssignmentResponse) {
        this.id = assignment.id;
        this.user = assignment.user;
        this.userId = assignment.user_id;
        this.roleId = assignment.role_id;
        this.from = stringToDateTime(assignment.from);
        this.to = assignment.to ? stringToDateTime(assignment.to) : null;
        this.createdAt = stringToDateTime(assignment.created_at);
        this.updatedAt = stringToDateTime(assignment.updated_at);
        this.role = assignment.role;
        this.employees = assignment.employees;
    }
}
