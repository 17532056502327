import { inject, Injectable } from '@angular/core';
import { DialogData, DialogSize } from '../dialog-component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PromptDialogComponent } from './prompt-dialog.component';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

export interface BasePromptDialogData extends DialogData {
    // Text content inside the dialog
    text?: Promise<string>;
    // Title of the dialog
    title: Promise<string>;
    // Text for the label over the input
    label: Promise<string>;
    // Text for the cancel button
    cancelText?: Promise<string>;
    // Text for the confirm button
    confirmText?: Promise<string>;
    formControl: FormControl;
    // Should it be required to provide a value?
    required?: boolean;
    confirmButtonColor?: ThemePalette;
}

export type PromptDialogType = 'decimal' | 'textarea' | 'integer' | 'text';

export interface PromptDialogTextOptions {
    minLength?: number;
    maxLength?: number;
}
export interface PromptDialogNumberOptions {
    step?: number;
    min?: number;
    max?: number;
}
export interface PromptDialogTextareaOptions {
    minLength?: number;
    maxLength?: number;
}

export interface PromptDialogDataText extends BasePromptDialogData {
    options?: PromptDialogTextOptions;
}
export interface PromptDialogDataNumber extends BasePromptDialogData {
    options?: PromptDialogNumberOptions;
}
export interface PromptDialogDataTextarea extends BasePromptDialogData {
    options?: PromptDialogTextareaOptions;
}

type PromptDialogOptions = PromptDialogDataText | PromptDialogDataNumber | PromptDialogDataTextarea;

export type PromptDialogData = PromptDialogOptions & {
    type: PromptDialogType,
};

@Injectable({
    providedIn: 'root',
})
export class PromptDialogService {
    private readonly dialog = inject(MatDialog);

    open(type: 'textarea', options: PromptDialogDataTextarea): MatDialogRef<PromptDialogComponent, string>;
    open(type: 'integer' | 'decimal', options: PromptDialogDataNumber): MatDialogRef<PromptDialogComponent, number>;
    open(type: 'text', options: PromptDialogDataText): MatDialogRef<PromptDialogComponent, string>;
    open(type: PromptDialogType, options: PromptDialogOptions): MatDialogRef<PromptDialogComponent, unknown> {
        return this.dialog.open<PromptDialogComponent, PromptDialogData, string>(PromptDialogComponent, {
            data: {
                size: DialogSize.Content,
                ...options,
                type,
            },
        });
    }
}
