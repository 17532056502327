import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef } from '@angular/material/dialog';
import { CustomField } from '../../../custom-fields/models/custom-field';
import { catchError, EMPTY, forkJoin, map, Observable, of, startWith } from 'rxjs';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { filter, sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { PropertyValidatorService } from '../../http/property-validator.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { CustomFieldsService } from '../../../custom-fields/http/custom-fields.service';
import { ObjectCreatorComponent,
    ObjectCreatorObject } from '../../../shared/components/object-creator/object-creator.component';
import { SettingGroupCustomFieldsService } from '../../../custom-fields/http/setting-group-custom-fields.service';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';
import { CustomFieldPivot } from '../../../custom-fields/models/custom-field-pivot';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { InfoBoxComponent } from '../../../shared/components/info-card/info-box.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

export interface AddEditCustomFieldPivotDialogData extends DialogData {
    customFieldPivot?: Observable<CustomFieldPivot>;
    settingGroupId: number;
}

@Component({
    selector: 'eaw-add-edit-custom-field-pivot-dialog',
    templateUrl: './add-edit-custom-field-pivot-dialog.component.html',
    styleUrl: './add-edit-custom-field-pivot-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatInputModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        ObjectCreatorComponent,
        InfoBoxComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
        InfoLoadingComponent,
        TranslateSyncPipe,
    ],
})
/**
 * This is for adding and editing custom fields on a setting group
 */
export class AddEditCustomFieldPivotDialogComponent extends DialogComponent implements OnInit {
    // The pivot we are editing, if any
    editingPivot: CustomFieldPivot | null = null;
    models: (ApiModel | ApiModelClass)[] = sort([
        ApiModel.Absence,
        ApiModel.Contract,
        ApiModel.EmergencyContact,
        ApiModel.Employee,
        ApiModel.HrFile,
        ApiModel.PaidTime,
        ApiModel.Shift,
        ApiModelClass.FamilyMember,
    ], 'en', [ (x) => x ], [ 'asc' ]);

    processing = false;
    customFields: CustomField[] = [];
    filteredCustomFields: Observable<CustomField[]> = of([]);
    validators: string[] = [];
    filteredValidators: Observable<string[]> = of([]);
    loading = true;
    form = new FormGroup({
        model: new FormControl<ApiModel | null>(null, Validators.required),
        validator: new FormControl<string | null>(null),
        customField: new FormControl<CustomField | string | null>(null, Validators.required),
        options: new FormControl<ObjectCreatorObject | null>(null, Validators.required),
        priority: new FormControl<number | null>(null),
        hasInterval: new FormControl<boolean>(false, { nonNullable: true }),
        isRequired: new FormControl<boolean>(false, { nonNullable: true }),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AddEditCustomFieldPivotDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AddEditCustomFieldPivotDialogData, CustomFieldPivot>,
        @Inject(PropertyValidatorService) private propertyValidatorService: PropertyValidatorService,
        @Inject(CustomFieldsService) private customFieldsService: CustomFieldsService,
        @Inject(SettingGroupCustomFieldsService) private settingGroupCustomFieldsService: SettingGroupCustomFieldsService,
    ) {
        data.size ||= DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit() {
        this.filteredValidators = this.form.controls.validator.valueChanges.pipe(
            startWith(''),
            map((value) => filter(value || '', this.validators, (x) => x)),
        );

        this.form.controls.customField.valueChanges.subscribe((value) => {
            if (value instanceof CustomField && value.type === 'select') {
                this.form.controls.options.addValidators(Validators.required);
            } else {
                this.form.controls.options.removeValidators(Validators.required);
            }
        });

        this.filteredCustomFields = this.form.controls.customField.valueChanges.pipe(
            startWith(''),
            map((value) => {
                if (value instanceof CustomField) {
                    return [ value ];
                } else {
                    return filter(value || '', this.customFields, (x) => x.name);
                }
            }),
        );

        this.getInitialData();
    }

    getInitialData() {
        const customFieldObservable = this.data.customFieldPivot || of(null);
        const propertyValidatorsObservable = expandAllPages((pagination) => this.propertyValidatorService.getAll(pagination), { per_page: 40 });
        const customFieldsObservable = expandAllPages((pagination) => this.customFieldsService.getAll(pagination), { per_page: 40, order_by: 'name', direction: 'asc' });

        forkJoin([ customFieldObservable, propertyValidatorsObservable, customFieldsObservable ]).subscribe(([ customField, validators, customFields ]) => {
            this.validators = validators;
            this.customFields = customFields;
            this.editingPivot = null;
            this.editingPivot = customField?.clone() || null;

            this.form.setValue({
                model: this.editingPivot?.model ?? null,
                validator: this.editingPivot?.validator ?? null,
                hasInterval: this.editingPivot?.hasInterval ?? false,
                isRequired: this.editingPivot?.required ?? false,
                priority: this.editingPivot?.priority ?? null,
                options: this.editingPivot?.customField?.type === 'select' ? this.editingPivot?.getSelectOptions() : null,
                customField: customFields.find((cf) => cf.id === this.editingPivot?.customField?.id) ?? null,
            });

            if (this.editingPivot) {
                this.form.controls.customField.disable();
                this.form.controls.model.disable();
            }

            this.loading = false;
        });
    }

    get selectedCustomField() {
        return this.form.controls.customField.value instanceof CustomField ? this.form.controls.customField.value : null;
    }

    customFieldDisplay(cf?: CustomField) {
        return cf?.key || '';
    }

    add() {
        const model = this.form.controls.model.value;
        const customField = this.selectedCustomField;

        if (!model) {
            return;
        }

        if (!(customField instanceof CustomField)) {
            return;
        }

        const body = {
            custom_field_id: customField.id,
            selectOptions: this.form.controls.options.value || undefined,
            model,
            priority: this.form.controls.priority.value || undefined,
            has_interval: this.form.controls.hasInterval.value,
            required: this.form.controls.isRequired.value,
            validator: this.form.controls.validator.value || undefined,
        };
        this.processing = true;
        this.form.disable();
        this.settingGroupCustomFieldsService.create(this.data.settingGroupId, body).pipe(
            catchError((err) => {
                console.error(err);
                this.form.enable();
                this.processing = false;
                return EMPTY;
            }),
        ).subscribe((customField) => {
            this.dialogRef.close(customField);
        });
    }

    update() {
        const model = this.form.controls.model.value;
        const customField = this.selectedCustomField;

        if (!this.editingPivot) {
            return;
        }

        if (!model) {
            return;
        }

        if (!(customField instanceof CustomField)) {
            return;
        }

        const updateBody = {
            selectOptions: this.form.controls.options.value,
            has_interval: this.form.controls.hasInterval.value,
            priority: this.form.controls.priority.value || undefined,
            required: this.form.controls.isRequired.value,
            validator: this.form.controls.validator.value,
        };
        this.processing = true;
        this.form.disable();
        this.settingGroupCustomFieldsService.update(this.data.settingGroupId, this.editingPivot.customFieldId, this.editingPivot.id, updateBody).pipe(
            catchError((err) => {
                console.error(err);
                this.form.enable();
                this.processing = false;
                return EMPTY;
            }),
        ).subscribe((customField) => {
            this.dialogRef.close(customField);
        });
    }
}
