import { ApiResponse } from '../../shared/interfaces/api-response';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { DateTime } from 'luxon';
import { TariffRate, TariffRateResponse } from './tariff-rate';
import { BaseApiModel } from '../../shared/models/base-api-model';

export type TariffType = 'hourly' | 'monthly' | 'extra';

export interface TariffResponse extends ApiResponse {
    id: number;
    name: string;
    setting_group_id: number;
    type: TariffType;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    rates?: TariffRateResponse[];
}

export class Tariff extends BaseApiModel<TariffResponse, Tariff> {
    id: number;
    name: string;
    settingGroupId: number;
    type: TariffType;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;
    rates: TariffRate[];

    constructor(data: TariffResponse) {
        super(data, undefined);

        this.id = data.id;
        this.name = data.name;
        this.settingGroupId = data.setting_group_id;
        this.type = data.type;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.rates = data.rates ? data.rates?.map((rate) => new TariffRate(rate)) : [];
    }
}

export class TariffSuggestion extends Tariff {
    readonly isSuggestion = true;
}
