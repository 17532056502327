<form class="tw-p-16 tw-flex tw-flex-col">
    <mat-form-field>
        <mat-label>{{ 'BALANCE' | translate:'france_payroll' | async }}</mat-label>
        <mat-select [formControl]="balanceTypeControl">
            <mat-option *ngFor="let type of balanceTypes" [value]="type.balanceCode">
                <span>{{ type.nameTranslation.key | translateSync:type.nameTranslation.ns }}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>

<div *ngIf="balanceTypeControl.disabled" class="tw-flex tw-items-center tw-justify-center">
    <mat-spinner mode="indeterminate"></mat-spinner>
</div>

<div *ngIf="balanceTypeControl.enabled && !balanceTypeControl.value" class="tw-flex tw-items-center tw-justify-center">
    <span class="mat-body-1">{{ 'CHOOSE_BALANCE_TYPE' | translate:'france_payroll' | async }}</span>
</div>

<ng-container *ngIf="balanceTypeControl.enabled && balanceTypeControl.value">
    <mat-list *ngFor="let overview of combinedBalanceOverview">
        <mat-list-item>
            <span matListItemTitle>{{ overview.employeeName }}</span>
            <span matListItemMeta>{{ getChangeForType(overview, balanceTypeControl.value) }}</span>
        </mat-list-item>
    </mat-list>
</ng-container>

