import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Employee } from '../../../../../shared/models/employee';
import { PaidTimeOverview } from '../../../../models/paid-time-overview';
import { DurationPipe } from '../../../../../shared/pipes/duration.pipe';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { NgIf, AsyncPipe } from '@angular/common';
import { ProfilePictureComponent } from '../../../../../shared/components/profile-picture/profile-picture.component';

@Component({
    selector: 'eaw-paid-time-overview-employee',
    templateUrl: './paid-time-overview-employee.component.html',
    styleUrl: './paid-time-overview-employee.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ProfilePictureComponent,
        NgIf,
        AsyncPipe,
        TranslatePipe,
        DurationPipe,
    ],
})
export class PaidTimeOverviewEmployeeComponent implements OnInit {
    @Input({ required: true }) employee!: Employee;
    @Input({ required: true }) overview?: PaidTimeOverview;

    // Number of shifts
    shiftsCount = 0;
    // Total duration of shifts in seconds
    shiftsDuration = 0;

    ngOnInit() {
        const shifts = this.overview?.filter({ employeeId: this.employee.id }).shifts || [];
        this.shiftsCount = shifts.length;
        this.shiftsDuration = shifts.reduce((acc, s) => acc + s.netLength, 0);
    }
}
