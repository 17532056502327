<eaw-page-header>
    <div title>{{'HR' | translate:'navigation' | async}}</div>
</eaw-page-header>

<mat-spinner class="groups-spinner" mode="indeterminate" *ngIf="!customers.length"></mat-spinner>

<mat-accordion>
    <mat-expansion-panel *ngFor="let hrCustomer of customers; trackBy: customersTrackBy" (click)="setIsActive(hrCustomer, true)" (afterCollapse)="setIsActive(hrCustomer, false)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span>{{hrCustomer.customer.name}}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
            <eaw-files *ngIf="hrCustomer.isActive" [customer]="hrCustomer.customer"></eaw-files>
        </ng-template>
    </mat-expansion-panel>
</mat-accordion>
