import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '../../../shared/services/translate.service';
import { DateTime } from 'luxon';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { BusinessDate } from '../../../shared/utils/business-date';
import { ModelCustomField } from '../../models/model-custom-field';

@Component({
    selector: 'eaw-custom-field-value-display',
    templateUrl: './custom-field-value-display.component.html',
    styleUrl: './custom-field-value-display.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class CustomFieldValueDisplayComponent implements OnChanges {
    @Input({ required: true }) customField!: ModelCustomField;

    value = '';

    constructor(
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
        @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
        @Inject(ElementRef) private elementRef: ElementRef,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['customField'] && changes['customField'].currentValue instanceof ModelCustomField) {
            void this.updateValue(changes['customField'].currentValue);

            // This can be used to target or style the element based on the type of the custom field
            this.elementRef.nativeElement.dataset.type = changes['customField'].currentValue.type;
        }
    }

    async updateValue(field: ModelCustomField) {
        const value = field.value;

        switch (true) {
            case value == null: {
                this.value = '';
                break;
            }
            case typeof value === 'boolean': {
                this.value = value as boolean ? await this.translate.t('YES') : await this.translate.t('NO');
                break;
            }
            case typeof value === 'string': {
                this.value = field.type === 'select' ? await this.translate.t(field.getSelectedOption()?.translationKey, 'custom_fields') ?? '' : value as string;
                break;
            }
            case value instanceof BusinessDate: {
                this.value = (value as BusinessDate).dateTime.toLocaleString(DateTime.DATE_MED);
                break;
            }
            case typeof value === 'number': {
                this.value = field.type === 'decimal' ? this.numberFormatterService.formatDecimal(value) : this.numberFormatterService.formatInteger(value);
            }
        }

        this.changeDetectorRef.markForCheck();
    }
}
