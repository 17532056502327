// @ts-nocheck
import { t } from 'i18next';
import { uniq } from 'lodash-es';
import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
module('eaw.absence').controller('offTimeDialogCtrl', [ 'vacationDays', '$mdDialog', function(vacationDays, $mdDialog) {
    // @ts-ignore
    const ctrl = this;
    ctrl.$onInit = () => {
        ctrl.customer = ctrl.args?.customer;
        ctrl.employee = ctrl.args?.employee;
        ctrl.minDate = ctrl.args?.minDate?.clone?.();
        ctrl.prevOfftimeFrom = null;
        ctrl.prevOffTimeTo = null;
    };
    ctrl.offtimeChange = () => {
        if (ctrl.prevOfftimeFrom?.isSame(ctrl.offtime?.from)) {
            return;
        }
        if (ctrl.prevOffTimeTo?.isSame(ctrl.offtime?.to)) {
            return;
        }
        ctrl.prevOfftimeFrom = ctrl.offtime?.from?.clone?.();
        ctrl.prevOfftimeTo = ctrl.offtime?.to?.clone?.();
        const years = uniq([
            ctrl.offtime?.from?.year?.(),
            ctrl.offtime?.to?.year?.(),
        ].filter((y) => typeof y === 'number'));
        if (years.length && years !== ctrl.years) {
            ctrl.years = years;
            ctrl.getDays();
        }
    };
    ctrl.getDays = () => {
        if (!CurrentOld.getUser().can(`customers.${ctrl.customer.id}.employees.${ctrl.employee.id}.vacation_days.*.get`, false, {
            employee: ctrl.employee,
            customer: ctrl.customer,
        })) {
            return;
        }
        if (!ctrl.customer?.id) {
            return;
        }
        if (!ctrl.employee?.id) {
            return;
        }
        ctrl.vacDays = []; // Reset
        ctrl.loading = true;
        Promise.all(ctrl.years.map((y) => vacationDays.getEmployeeStats({
            customer: ctrl.customer,
            employee: ctrl.employee,
            year: y,
        }).$promise)).then((resp) => {
            ctrl.vacDays = resp.map((r, i) => t('vacation:REMAINING_VAC_DAYS', {
                count: r.vacation_days,
                year: ctrl.years[i],
            }));
        });
    };
    ctrl.submit = (offtime) => {
        $mdDialog.hide(offtime);
    };
} ]);
