import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import { FormControl, FormGroup, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateService } from '../../../shared/services/translate.service';
import { fromEvent } from 'rxjs';
import { NeedColumn } from './need-column';
import { StaffingForecastService } from '../../http/staffing-forecast.service';
import { StaffingForecastInterval } from '../../models/staffing-forecast';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatInputModule } from '@angular/material/input';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

interface Granularity {
    key: 'day' | 'week' | 'month';
    interval: StaffingForecastInterval;
    translation: Promise<string>;
}

@Component({
    selector: 'eaw-forecast-need',
    templateUrl: './staffing-forecast.component.html',
    styleUrl: './staffing-forecast.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        NgIf,
        MatProgressSpinnerModule,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        MatInputModule,
        FormsModule,
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
    ],
})
export class StaffingForecastComponent implements OnInit, AfterViewInit {
    @ViewChild('transactionsInput') transactionsInput?: ElementRef<HTMLInputElement>;
    @Input() customerId!: number;

    totals = {
        projections: 0,
        estimatedNeed: 0,
        contractHours: 0,
        absences: 0,
        available: 0,
        diff: 0,
    };

    loading = false;
    defaultEmployeeTransactions = 1;
    items: NeedColumn[] = [];
    granularities: Granularity[] = [
        {
            key: 'day',
            interval: 86_400,
            translation: this.translate.t('DAY'),
        },
        {
            key: 'week',
            interval: 604_800,
            translation: this.translate.t('WEEK'),
        },
        {
            key: 'month',
            interval: 2_592_000,
            translation: this.translate.t('MONTH'),
        },
    ];

    form = new FormGroup({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        granularity: new FormControl<Granularity>(this.granularities[0]!, { nonNullable: true }),
        dateRange: new FormGroup({
            from: new FormControl<DateTime | null>(DateTime.now().startOf('month'), Validators.required),
            to: new FormControl<DateTime | null>(DateTime.now().endOf('month'), Validators.required),
        }),
    });

    constructor(
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(StaffingForecastService) private staffingForecastService: StaffingForecastService,
    ) {
    }

    ngOnInit() {
        if (!this.customerId) {
            throw new Error('Missing customer');
        }

        this.update();
    }

    ngAfterViewInit() {
        if (this.transactionsInput?.nativeElement) {
            fromEvent(this.transactionsInput?.nativeElement, 'keyup').subscribe(() => {
                const value = this.getEmployeeTransactions();
                this.items.forEach((i) => i.setEmployeeTransactions(value));
                this.updateTotals();
            });
        }
    }

    getEmployeeTransactions() {
        const value = this.transactionsInput?.nativeElement?.value;
        return value ? parseFloat(value) : this.defaultEmployeeTransactions;
    }

    dateFilter = (date: DateTime | null) => {
        if (date == null) {
            return true;
        }

        switch (this.form.controls.granularity.value.key) {
            case 'day': {
                return true;
            }
            case 'week': {
                return date.weekday === 1 || date.weekday === 7;
            }
            case 'month': {
                return date.day === 1 || date.day === date.daysInMonth;
            }
        }

        return true;
    };

    granularityChange() {
        this.form.controls.dateRange.setValue({
            from: null,
            to: null,
        });
    }

    update() {
        const dateRange = this.form.controls.dateRange.controls;
        const from = dateRange.from.value;
        const to = dateRange.to.value;
        if (!(from && to)) {
            return;
        }

        this.loading = true;
        this.staffingForecastService.get(this.customerId, from, to, this.form.controls.granularity.value.interval).subscribe((res) => {
            this.loading = false;
            this.items = [];
            const min = Math.min(res.projections.length, res.contractHours.length, res.absenceHours.length);

            for (let i = 0; i < min; i++) {
                const projection = res.projections[i];
                const contractHours = res.contractHours[i];
                const absenceHours = res.absenceHours[i];

                if (projection != null && contractHours != null && absenceHours != null) {
                    this.items.push(
                        new NeedColumn(res.startDate.dateTime.plus({ [res.durationUnit]: i }), {
                            employeeTransactionsPerHour: this.getEmployeeTransactions(),
                            projections: projection,
                            contractHours,
                            absences: absenceHours,
                        }),
                    );
                }
            }

            this.updateTotals();
        });
    }

    updateTotals() {
        this.totals = {
            projections: this.items.reduce((num, i) => i.projections + num, 0),
            estimatedNeed: this.items.reduce((num, i) => i.estimatedNeed + num, 0),
            contractHours: this.items.reduce((num, i) => i.contractHours + num, 0),
            absences: this.items.reduce((num, i) => i.absences + num, 0),
            available: this.items.reduce((num, i) => i.available + num, 0),
            diff: this.items.reduce((num, i) => i.diff + num, 0),
        };
    }

    change(ev: number, index: number) {
        this.items[index]?.setTransactions(ev);
        this.updateTotals();
    }
}
