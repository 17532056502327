// @ts-nocheck
import { module } from 'angular';
import { Products } from '../../shared/enums/products';
import { Namespace } from '../../shared/enums/namespace';

module('eaw.leave-shifts', [ 'ngTable', 'eaw.misc', 'eaw.resource', 'eaw.login' ])
    .config([ '$stateProvider', ($stateProvider: any) => {
        $stateProvider.state(`eaw/app/leave_shifts`, {
            parent: 'eaw/app',
            url: '/leave',
            abstract: true,
            data: {
                products: [ Products.LeaveShifts ],
                i18nextNs: [ Namespace.Leaveshifts, Namespace.Absences ],
                breadcrumb: { key: 'LEAVE_SHIFTS', ns: 'navigation' },
            },
        });
        $stateProvider.state(`eaw/app/leave_shifts/customer`, {
            parent: `eaw/app/leave_shifts`,
            url: '/shifts',
            views: {
                'content@': 'customerLeaveShifts',
            },
            data: {
                breadcrumb: { key: 'CUSTOMER_LEAVE_SHIFTS', ns: 'navigation' },
                permissions: [ `customers.{customer}.leave_shifts.*.get` ],
            },
            resolve: {
                employee: function() {
                    return undefined; // Overwrite potential employee from 'eaw/app' state
                },
            },
        });

        $stateProvider.state(`eaw/app/leave_shifts/my`, {
            parent: `eaw/app/leave_shifts`,
            url: '/my_shifts',
            views: {
                'content@': 'employeeLeaveShifts',
            },
            data: {
                breadcrumb: { key: 'MY_LEAVE_SHIFTS', ns: 'navigation' },
                permissions: [ `customers.{customer}.employees.{employee}.leave_shifts.*.get` ],
                requiresEmployee: true,
            },
        });
    } ]);
