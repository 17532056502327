<eaw-dialog-header>
    <span title>{{ 'SHIFT_SWAP' | translate: 'scheduling' | async }}</span>
</eaw-dialog-header>
<mat-dialog-content>
    <p class="mat-body"> {{ text }} </p>
    <div *ngIf="!summaries" class="tw-flex tw-w-full tw-h-1/3 tw-justify-center">
        <mat-progress-spinner mode="indeterminate" strokeWidth="4"></mat-progress-spinner>
    </div>
    <mat-list class="tw-mb-8">
        <ng-container *ngIf="summaries">
            <ng-container *ngFor="let employee of employeeNames | keyvalue">
                <div mat-subheader>{{employee.value}}</div>
                <mat-list-item class="tw-m-2">
                    <eaw-swap-summary [summary]="summaries[employee.key]!"></eaw-swap-summary>
                </mat-list-item>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="additionalSwaps?.length">
            <mat-divider></mat-divider>
            <div mat-subheader>
                {{ 'OTHER_SWAP' | translate: 'scheduling': { count: additionalSwaps.length } }}
            </div>
            <ng-container *ngFor="let swap of additionalSwaps">
                <mat-list-item class="tw-m-2">
                    <div>
                        {{employeeNames[swap.fromId ?? ''] || ('UNASSIGNED' | translate: 'scheduling' | async)}} - {{employeeNames[swap.toId!]}}
                    </div>
                    <span>
                        {{ swap.shift!.from | DateTime: 'DATE_MED_WITH_WEEKDAY' }}
                    </span>
                    <span>
                        {{ swap.shift!.from | DateTime: 'TIME_SIMPLE' }} - {{ swap.shift!.to | DateTime: 'TIME_SIMPLE' }}
                    </span>
                    <span *ngIf="swap.cost !== undefined" matLine>
                        {{'COST' | translate | async}} :
                        <span [eawMaterialColor]="swap.cost <= 0 ? 'green-500' : 'red-500'" colorProperty="backgroundColor"></span>
                    </span>
                </mat-list-item>
            </ng-container>
        </ng-container>
    </mat-list>

    <mat-form-field class="tw-w-full">
        <mat-label>
            {{'COMMENT' | translate | async}}
        </mat-label>
        <textarea matInput [formControl]="commentCtrl" [disabled]="submitting"></textarea>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button [mat-dialog-close]="undefined" mat-button [disabled]="submitting">
        {{'CANCEL' | translate | async }}
    </button>

    <button (click)="submit()" mat-raised-button color="accent" [disabled]="submitting">
        {{'APPROVE' | translate | async }}
    </button>
</mat-dialog-actions>
