<mat-form-field>
    <mat-label>{{'EMPLOYEE' | translate | async}}</mat-label>

    <mat-chip-grid aria-label="Employee selection" required #chipList>
        <mat-chip-row (removed)="remove(employee)" selectable="false" *ngFor="let employee of selectedEmployees">
            {{employee.name}}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
        <input placeholder="{{'TYPE_TO_SEARCH_AUTOCOMPLETE' | translate | async}}"
               #employeeInput="matAutocompleteTrigger"
               [formControl]="selected"
               [matAutocomplete]="emps"
               [matChipInputFor]="chipList">
    </mat-chip-grid>
    <mat-checkbox eawCheckboxHelper matPrefix (change)="selectAll($event)" #checkbox></mat-checkbox>
    <mat-autocomplete #emps="matAutocomplete">
        <mat-option *ngFor="let employee of filteredEmployees; trackBy: trackEmployeeBy" [value]="employee">{{employee.name}}</mat-option>
    </mat-autocomplete>
</mat-form-field>

<mat-error class="tw-mb-12" *ngIf="!getEmployees().length">{{('NO_EMPS_ON_LOCATION' | translate:'reports':{count: customerIds.length}) | async}}</mat-error>
