import { Property, PropertyResponse } from '../app/shared/models/property';

export function mockProperty(data: Partial<PropertyResponse> = {}) {
    return new Property({
        created_at: '',
        id: 1,
        key: '',
        updated_at: '',
        value: '',
        ...data,
    });
}
