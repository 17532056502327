import { DialogComponent, DialogData, DialogSize } from '../../shared/dialogs/dialog-component';
import { MatDialogRef } from '@angular/material/dialog';
import { WidgetInfo } from './widget-info';
import { WidgetPropertySettings } from './widget';

export interface WidgetSettingsDialogData<Settings extends WidgetPropertySettings | undefined = undefined> extends DialogData {
    widgetInfo: WidgetInfo;
    settings?: Settings;
}

export class WidgetSettingsDialog<Settings extends WidgetPropertySettings, Component extends DialogComponent = DialogComponent> extends DialogComponent<WidgetSettingsDialogData<Settings>, Settings, Component> {
    constructor(
        dialogRef?: MatDialogRef<Component, Settings>,
        data?: WidgetSettingsDialogData<Settings>,
    ) {
        super(dialogRef, data, DialogSize.Medium);
    }
}
