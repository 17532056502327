import { BaseApiModel } from '../../shared/models/base-api-model';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface EfficiencyResponse extends ApiResponse {
    id: number;
    budget_low: number;
    budget_high: number;
    schedule_id: number;
    value: number;
}

export class Efficiency extends BaseApiModel<EfficiencyResponse, Efficiency> {
    id: number;
    budgetLow: number;
    budgetHigh: number;
    scheduleId: number;
    value: number;

    constructor(data: EfficiencyResponse) {
        super(data, undefined);

        this.id = data.id;
        this.budgetLow = data.budget_low;
        this.budgetHigh = data.budget_high;
        this.scheduleId = data.schedule_id;
        this.value = data.value;
    }
}
