import { Component, Inject, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { catchError, map, of } from 'rxjs';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { FamilyMemberService } from '../../http/family-member.service';
import { MatCardModule } from '@angular/material/card';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatDialog } from '@angular/material/dialog';
import { HandleFamilyMemberDialogComponent, HandleFamilyMemberDialogData, HandleFamilyMemberDialogReturn } from '../../dialogs/handle-family-member-dialog/handle-family-member-dialog.component';
import { FamilyMember } from '../../models/family-member';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { Namespace } from '../../../shared/enums/namespace';
import { DialogSize } from '../../../shared/dialogs/dialog-component';

@Component({
    selector: 'eaw-employee-family-members-tab',
    standalone: true,
    imports: [ CommonModule, DataTableComponent, MatCardModule, PageHeaderComponent, TranslatePipe ],
    templateUrl: './employee-family-members-tab.component.html',
    styleUrl: './employee-family-members-tab.component.scss',
})
export class EmployeeFamilyMembersTabComponent implements EawDataTable<FamilyMember> {
    @ViewChild('table') table?: DataTableComponent<FamilyMember>;

    @Input({ required: true }) employeeId!: string;
    @Input({ required: true }) customerId!: number;

    fabButton: HeaderFabButton = {
        click: this.handleMember.bind(this),
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${parseInt(this.employeeId)}.family_members_v2.create`),
    };

    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<FamilyMember>[] = [
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({ i18n: 'NAME' }),
        }),
        new DataTableTextColumn({
            value: 'relation',
            header: new DataTableHeader({ i18n: 'RELATION' }),
        }),
        new DataTableDateTimeColumn({
            format: DateTime.DATE_MED,
            value: 'birthDate',
            header: new DataTableHeader({ i18n: 'BIRTH_DATE' }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'edit',
                    click: this.handleMember.bind(this),
                    show: (member) => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${member.employeeId}.family_members_v2.${member.id}.update`),
                    tooltip: { key: 'UPDATE' },
                },
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.removeFamilyMember.bind(this),
                    show: (member) => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${member.employeeId}.family_members_v2.${member.id}.delete`),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    constructor(
        @Inject(TranslateService) private readonly translate: TranslateService,
        @Inject(FamilyMemberService) private readonly familyMemberService: FamilyMemberService,
        @Inject(MatDialog) private readonly matDialog: MatDialog,
        @Inject(PermissionCheckService) private readonly permissionCheckService: PermissionCheckService,
    ) {
    }

    handleMember(cell?: DataTableCell<DataTableButtonColumn<FamilyMember>, FamilyMember>) {
        this.matDialog.open<HandleFamilyMemberDialogComponent, HandleFamilyMemberDialogData, HandleFamilyMemberDialogReturn>(HandleFamilyMemberDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: parseInt(this.employeeId),
                familyMember: cell?.item ? of(cell.item) : undefined,
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.table?.refresh();
            } else {
                if (cell) {
                    cell.disabled.set(false);
                }
            }
        });
    }

    removeFamilyMember(cell: DataTableCell<DataTableButtonColumn<FamilyMember>, FamilyMember>) {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translate.t('REMOVE_FAM_MEM', Namespace.Company),
                text: this.translate.t('REMOVE_FAM_MEM_CONFIRM', Namespace.Company, { name: cell.item.name }),
                confirmText: this.translate.t('REMOVE'),
                confirmColor: 'red-500',
                size: DialogSize.Medium,
            },
        }).afterClosed().pipe(catchError(() => of(undefined))).subscribe((result) => {
            if (result?.ok) {
                this.familyMemberService.delete(this.customerId, parseInt(this.employeeId), cell.item.id).subscribe(() => {
                    this.table?.refresh();
                });
            } else {
                cell.disabled.set(false);
            }
        });
    }

    updateTable(_: Partial<DataTablePagination>) {
        this.request = this.familyMemberService.getForEmployee(this.customerId, parseInt(this.employeeId)).pipe(
            map((data) => {
                return mockArrayPaginatedResponse(data);
            }),
        );
    }
}
