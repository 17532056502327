import { inject, Injectable } from '@angular/core';
import { ShiftPeriod, ShiftPeriodResponse } from '../models/shift-period';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { formatHttpParams } from '../../shared/utils/format-http-params';

interface CreateOptions {
    offset: number;
    length: number;
    description?: string;
    break?: boolean;
    unproductive?: boolean;
    business_unit_id?: number;
    color?: string;
    qualifications?: number[] | null,
}

interface UpdateOptions {
    offset?: number;
    length?: number;
    description?: string | null;
    break?: boolean;
    unproductive?: boolean;
    business_unit_id?: number | null;
    color?: string;
    qualifications?: number[] | null,
}

@Injectable({
    providedIn: 'root',
})
export class ShiftPeriodService {
    private http = inject(HttpClient);

    get(customerId: number, scheduleId: number, shiftId: number, periodId: number, withs?: string[]) {
        return this.http.get<ShiftPeriodResponse>(`customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/periods/${periodId}`, {
            params: formatHttpParams({
                'with[]': withs,
            }),
        }).pipe(classifyItem(ShiftPeriod));
    }

    getAll(customerId: number, scheduleId: number, shiftId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<ShiftPeriodResponse>>(`customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/periods`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(ShiftPeriod));
    }

    create(customerId: number, scheduleId: number, shiftId: number, options?: CreateOptions): Observable<ShiftPeriod> {
        return this.http.post<ShiftPeriodResponse>(`customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/periods`, {
            ...options,
            offset: options && options.offset != null ? Math.max(0, options.offset) : undefined,
        }).pipe(classifyItem(ShiftPeriod));
    }

    update(customerId: number, scheduleId: number, shiftId: number, periodId: number, options?: UpdateOptions): Observable<ShiftPeriod> {
        return this.http.put<ShiftPeriodResponse>(`customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/periods/${periodId}`, {
            ...options,
            offset: options && options.offset != null ? Math.max(0, options.offset) : undefined,
        }).pipe(classifyItem(ShiftPeriod));
    }

    delete(customerId: number, scheduleId: number, shiftId: number, periodId: number) {
        return this.http.delete(`customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/periods/${periodId}`);
    }
}
