<mat-card>
    <mat-card-content>
        <mat-drawer-container>
            <mat-drawer #matDrawer [mode]="drawerMode" [opened]="drawerOpened" [disableClose]="drawerMode === 'side'">
                <mat-form-field>
                    <mat-label>{{'FILTER' | translate | async}}</mat-label>
                    <input [formControl]="reportFilter" matInput autocomplete="off">
                    <button mat-icon-button matSuffix (click)="reportFilter.setValue('')" *ngIf="reportFilter.value">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-progress-spinner mode="indeterminate" *ngIf="fetchingReports" class="tw-self-center"></mat-progress-spinner>

                <div id="no-reports" *ngIf="!fetchingReports && !reports.length">
                    <span class="mat-subtitle-1">{{'NO_REPORTS' | translate:'reports' | async}}</span>
                </div>

                <div class="reports-list">
                    <div class="report-list-item tw-flex tw-flex-col"
                         *ngFor="let r of filteredReports | async"
                         (click)="chooseReport(r)"
                         [class.selected]="r.id === report?.id">
                        <span class="mat-subtitle-2"> {{r.name}} </span>
                        <small> {{r.description}}</small>
                    </div>
                </div>
            </mat-drawer>

            <mat-drawer-content>
                @if (drawerMode === 'over') {
                    <button id="open-menu-fab" mat-fab color="primary" (click)="matDrawer.toggle()">
                        <mat-icon>menu_open</mat-icon>
                    </button>
                }

                @if (report) {
                    <eaw-page-header [fabButton]="fabButton" [buttons]="headerButtons">
                        <span title>{{report.name}}</span>
                        <span subtitle>{{report.description}}</span>
                    </eaw-page-header>

                    <eaw-data-table [columns]="columns"
                                    [request]="request"
                                    (paginationChange)="updateTable($event)"/>
                } @else {
                    <div id="choose-box" class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-basis-full tw-gap-16">
                        <mat-icon>arrow_circle_left</mat-icon>
                        <span class="mat-headline-5">{{'NO_SELECTED_BIG' | translate:'reports' | async}}</span>
                    </div>
                }
            </mat-drawer-content>
        </mat-drawer-container>
    </mat-card-content>
</mat-card>
