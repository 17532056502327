import { ApiResponse } from '../../shared/interfaces/api-response';
import { ProjectionDay, ProjectionDayResponse } from './projection-day';

export interface ProjectionDaySetResponse extends ApiResponse {
    projections: ProjectionDayResponse[];
    savedProjections?: ProjectionDayResponse[];
}

export class ProjectionDaySet {
    projections: ProjectionDay[];
    savedProjections: ProjectionDay[];

    constructor(data: ProjectionDaySetResponse) {
        this.projections = data.projections ? data.projections.map((projection) => new ProjectionDay(projection)) : [];
        this.savedProjections = data.savedProjections ? data.savedProjections.map((projection) => new ProjectionDay(projection)) : [];
    }
}
