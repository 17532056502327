// @ts-nocheck
import { module } from 'angular';
import { PaginationOld } from '../../../../shared/utils/pagination-old';
module('eaw.admin').factory('PermissionsetPermissions', [ 'EawResource', function(EawResource) {
    const fac = this;
    const res = EawResource.create('/permission_sets/:set/permissions/:permission');
    fac.getAll = {
        /**
         * @param {int} setId
         * @returns {string}
         */
        permission(setId) {
            return `permission_sets.${setId}.permissions.*.get`;
        },
        /**
         * @param {object} args
         * @param {int} args.setId
         * @returns {*}
         */
        query(args) {
            return res.get({
                set: args.setId,
                ...PaginationOld.getParams(args),
            });
        },
    };
    fac.get = {
        /**
         * @param {int} setId
         * @param {int} permissionId
         * @returns {string}
         */
        permission(setId, permissionId) {
            return `permission_sets.${setId}.permissions.${permissionId}.get`;
        },
        /**
         * @param {int} setId
         * @param {int} permissionId
         * @returns {*}
         */
        query(setId, permissionId) {
            return res.get({
                set: setId,
                permission: permissionId,
            });
        },
    };
    fac.addPermission = {
        /**
         * @param {int} setId
         * @returns {string}
         */
        permission(setId) {
            return `permission_sets.${setId}.permissions.create`;
        },
        /**
         * @param {int} setId
         * @param {string} node
         * @param {bool} value
         * @returns {*}
         */
        query(setId, node, value) {
            return res.save({
                set: setId,
            }, {
                node,
                value,
            });
        },
    };
    fac.updatePermission = {
        /**
         * @param {int} setId
         * @param {int} permissionId
         * @returns {string}
         */
        permission(setId, permissionId) {
            return `permission_sets.${setId}.permissions.${permissionId}.update`;
        },
        /**
         * @param {int} setId
         * @param {string} node
         * @param {bool} value
         * @returns {*}
         */
        query(setId, node, value) {
            return res.update({
                set: setId,
                permission: node,
            }, {
                value,
            });
        },
    };
    fac.deletePermission = {
        /**
         * @param {int} setId
         * @param {int} permissionId
         * @returns {string}
         */
        permission(setId, permissionId) {
            return `permission_sets.${setId}.permissions.${permissionId}.delete`;
        },
        /**
         * @param {int} setId
         * @param {int} permissionNode
         * @returns {*}
         */
        query(setId, permissionNode) {
            return res.delete({
                set: setId,
                permission: permissionNode,
            });
        },
    };
    return fac;
} ]);
