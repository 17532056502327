import { Rate, RateResponse } from '../classes/rate';

export interface TariffRateResponse extends RateResponse {
    tariff_id: number;
}

export class TariffRate extends Rate {
    tariffId: number;

    constructor(data: TariffRateResponse) {
        super(data);

        this.tariffId = data.tariff_id;
    }
}
