import { module } from 'angular';
import { RotationListComponent } from '../../rotation-list/rotation-list.component';
import { Products } from '../../../shared/enums/products';
import { createState } from '../../../shared/utils/create-state';
import { currentResolver } from '../../../shared/resolvers/current.resolver';
import { DataTableQueryParams } from '../../../data-table/data-table.component';

module('eaw').config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: `eaw/app/rotation`,
        parent: 'eaw/app',
        url: '/rotations',
        views: {
            'content@': {
                component: RotationListComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            queryParams: DataTableQueryParams,
            products: [ Products.Rotation ],
            permissions: [ 'customers.{customer}.rotations.*.get' ],
            breadcrumb: { key: 'ROTATION_plural', ns: 'rotation' },
            settings: {
                'france.use_combined_contract': (value) => !value || value?.asBoolean() === false,
            },
        },
    });
} ]);
