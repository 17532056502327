// @ts-nocheck
import { pick } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { Model } from '../../shared/angularjs/model';
import { overlaps } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { PaginationOld } from '../../shared/utils/pagination-old';
import {ShiftResponse} from '../models/shift';

module('eaw.scheduling.shifts').factory('Shift', [ 'ShiftPeriod', 'shiftEvents', 'shiftCommentFactory', 'EawResource', function ShiftClass(ShiftPeriod, shiftEvents, shiftCommentFactory, EawResource) {
    const _businessDates = [ 'from_business_date', 'to_business_date' ];
    const urls = {
        customer: '/customers/:customer_id/shifts/:shift_id',
        employee: '/customers/:customer_id/employees/:employee_id/shifts',
        schedule: '/customers/:customer_id/schedules/:schedule_id/shifts/:shift_id',
        group: '/customer_groups/:group_id/shifts',
    };

    /** @deprecated */
    class Shift extends Model {
        schedule;
        group_id;
        periods;
        status;
        from;
        to;
        business_date;
        business_date_offset;
        employee_id;
        shift_grouping_id;
        offset;
        length;

        constructor(data, schedule) {
            super(data);
            this.schedule = schedule;
            this.group_id = data.shift_group?.id;
            // Handle colors of periods in case they have hex values
            this.periods = this.periods?.map((p) => new ShiftPeriod(p));
            // Original
            this.setOriginal(this);
            this.setBusinessDateOffset();
        }

        setBusinessDateOffset() {
            if (!this.from || !this.business_date || this.business_date.isSame(this.from, 'd')) {
                this.business_date_offset = 0;
            } else {
                this.business_date_offset = this.business_date.isAfter(this.from, 'd') ? 1 : -1;
            }
        }

        _set(shift, prop, value) {
            const set = super._set(shift, prop, value);
            switch (prop) {
                case 'employee':
                    super._set(shift, 'employee_id', value?.id ?? null);
                    break;
                case 'business_date_offset':
                    if (shift.schedule?.is_template && shift.from) {
                        const businessDate = shift.from.clone().add(shift.business_date_offset, 'd');
                        super._set(shift, 'business_date', businessDate);
                    }
                    break;
                case 'from':
                    if (value == null) {
                        super._set(shift, 'offset', undefined);
                        super._set(shift, 'length', undefined);
                    } else if (moment.isMoment(value)) {
                        if (shift.schedule) {
                            super._set(shift, 'offset', value.diff(shift.schedule.from, 's'));
                        }
                        if (shift.from && shift.to) {
                            super._set(shift, 'length', shift.to.diff(shift.from, 's'));
                        }
                    }
                    break;
                case 'to':
                    if (value == null) {
                        super._set(shift, 'length', undefined);
                    } else if (moment.isMoment(value)) {
                        if (shift.from && shift.to) {
                            super._set(shift, 'length', shift.to.diff(shift.from, 's'));
                        }
                    }
                    break;
                case 'business_date':
                    shift.setBusinessDateOffset();
                    break;
                case 'shift_group':
                    super._set(shift, 'group_id', value?.id);
            }
            return set;
        }

        _get(shift, prop) {
            if (!Object.prototype.hasOwnProperty.call(shift, prop) && shift.schedule) {
                if ([ 'from', 'to', 'business_date' ].includes(prop) && shift.offset != null && shift.offset >= 0) {
                    switch (prop) {
                        case 'from':
                            shift.from = shift.schedule.getFrom().add(shift.offset, 's');
                            break;
                        case 'to':
                            if (shift.length != null && shift.length >= 0) {
                                shift.to = shift.schedule.getFrom().add(shift.offset + shift.length, 's');
                            }
                            break;
                        case 'business_date':
                            if (shift.schedule.is_template) {
                                shift.business_date = shift.from.clone().add(shift.business_date_offset, 'd');
                            } else {
                                shift.business_date = shift.from.clone();
                            }
                            break;
                    }
                } else if (prop === 'offset' && moment.isMoment(shift.from)) {
                    shift.offset = shift.from.diff(shift.schedule.from, 's');
                } else if (prop === 'length' && moment.isMoment(shift.to)) {
                    shift.length = shift.to.diff(shift.from, 's');
                }
            }
            if (prop === 'shift_grouping_id') {
                shift.shift_grouping_id = shift.employee_id || `unassigned${shift.id}`;
            }
            return super._get(shift, prop);
        }

        isDummy() {
            return this.id?.toString().startsWith('dummy');
        }

        removeStatus() {
            delete this.status;
        }

        setStatus(status) {
            this.status = status;
        }

        overlaps(shift) {
            return overlaps(shift.from, shift.to, this.from, this.to, true);
        }

        comment(body) {
            return shiftCommentFactory.create.query(this.schedule.customer_id, this.schedule.id, this.id, body);
        }

        getComments(options) {
            return shiftCommentFactory.getAll.query(this.schedule.customer_id, this.schedule.id, this.id, options);
        }

        create() {
            shiftEvents.trigger.creating(this);
            if (!this.business_date) {
                this.business_date = this.from.clone();
            }
            return Shift.create(this.schedule.customer_id, this.schedule.id, this, this.schedule.getShiftWiths([ 'comments' ]));
        }

        /**
         * Update modified attributes
         * @return {Promise}
         */
        update() {
            if (!this.id) {
                return Promise.resolve();
            }
            if (this.schedule.is_template && this.business_date_offset != null) {
                this.business_date = this.from.clone().add(this.business_date_offset, 'd');
            }
            shiftEvents.trigger.updating(this);
            return Shift.update(this.schedule.customer_id, this.schedule.id, this, this.schedule.getShiftWiths()).$promise.then((data) => new Shift(data, this.schedule));
        }

        /**
         * Get data for this shift form server.
         * @return {Promise}
         */
        refresh(shift?: ShiftResponse) {
            if (!this.id) {
                return Promise.resolve();
            }
            shiftEvents.trigger.updating(this);

            return (shift ? Promise.resolve(shift) : Shift.getShift(this.schedule.customer_id, null, this.id, { with: this.schedule.getShiftWiths() }).$promise).then((resp) => {
                const shift = new Shift(resp, this.schedule);
                this.schedule.onShiftSaved(shift, 'updated');
                return shift;
            }).catch(() => this);
        }

        delete() {
            shiftEvents.trigger.deleting(this);
            return EawResource.create(urls.schedule).delete({
                customer_id: this.schedule.customer_id,
                schedule_id: this.schedule.id,
                shift_id: this.id,
            });
        }

        static create(customerId, scheduleId, shift, withRelations) {
            if (!shift.business_date) {
                shift.business_date = shift.from;
            }
            const picks = pick(shift, [ 'employee_id', 'offset', 'length', 'business_date', 'qualifications', 'group_id', 'initialComment' ]);
            picks.employee_id = picks.employee_id || null;
            // We change the key of this value because shift.comment is already used by a function, so we need to do this operation here
            picks.comment = picks.initialComment?.trim() || undefined;
            delete picks.initialComment;
            return EawResource.create(urls.schedule).save({
                customer_id: customerId,
                schedule_id: scheduleId,
                'with[]': withRelations,
            }, Object.assign(picks, shift.field_values));
        }

        static getAll(args) {
            if (args.employee_id || args.employee) {
                return Shift.getAllForEmployee(args.customer_id, args.employee_id || args.employee.id, args);
            }
            if (args.group_id) {
                return Shift.getAllForGroup(args.group_id, args);
            }
            return Shift.getAllForCustomer(args.customer_id, args);
        }

        static getAllForGroup(groupId, pagination) {
            return Shift.get({
                type: 'group',
                group_id: groupId,
            }, pagination);
        }

        static getAllForEmployee(customerId, employeeId, pagination) {
            return Shift.get({
                type: 'employee',
                customer_id: customerId,
                employee_id: employeeId,
                'changes[]': pagination.changes,
            }, pagination);
        }

        static getAllForCustomer(customerId, pagination) {
            return Shift.get({
                type: 'customer',
                customer_id: customerId,
                'changes[]': pagination.changes,
            }, pagination);
        }

        static getAllForSchedule(customerId, scheduleId, pagination) {
            return Shift.get({
                type: 'schedule',
                customer_id: customerId,
                schedule_id: scheduleId,
            }, pagination);
        }

        /**
         * @param {number} customerId
         * @param {number} scheduleId
         * @param {number} shiftId
         * @param {object} options
         * @return {$resource}
         */
        static getShift(customerId, scheduleId, shiftId, options) {
            return Shift.get({
                type: scheduleId ? 'schedule' : 'customer',
                customer_id: customerId,
                schedule_id: scheduleId,
                shift_id: shiftId,
                changelog: options.includeChangelog,
            }, options);
        }

        static get(options, pagination) {
            const keys = [
                'schedule_id',
                'customer_id',
                'group_id',
                'shift_id',
                'employee_id',
                'changelog',
                'changes[]',
                'from_business_date',
                'to_business_date',
                'from',
                'to',
                'changed',
                'vacant',
                'published',
            ];
            return EawResource.create(urls[options.type]).get({
                _businessDates,
                ...pick(options, keys),
                ...PaginationOld.getParams(pagination, keys),
            });
        }

        static update(customerId, scheduleId, shift, withRelations) {
            return EawResource.create(urls.schedule).update({
                customer_id: customerId,
                schedule_id: scheduleId,
                shift_id: shift.id,
                'with[]': withRelations,
            }, {
                ...pick(shift, [ 'employee_id', 'offset', 'length', 'business_date', 'qualifications', 'group_id' ]),
                ...shift.field_values,
            });
        }

        static delete(customerId, scheduleId, shiftId) {
            return EawResource.create(urls.schedule).delete({
                customer_id: customerId,
                schedule_id: scheduleId,
                shift_id: shiftId,
            });
        }

        static override getMorphClass() {
            return 'shift';
        }
    }

    return Shift;
} ]);
