import { Moment } from 'moment-timezone';
import MlVariable from './ml-variable';

export default class MlBucket {
    uuid: string;
    description: string;
    name: string;
    tags: Record<string, string>;
    variables: MlVariable[] = [];
    canUpdate: boolean = false;

    created_at: Moment;
    updated_at: Moment;
    deleted_at: Moment;

    constructor(mlBucket: any) {
        this.uuid = mlBucket.uuid;
        this.description = mlBucket.description;
        this.name = mlBucket.name;
        this.tags = mlBucket.tags;
        this.created_at = mlBucket.created_at;
        this.updated_at = mlBucket.updated_at;
        this.deleted_at = mlBucket.deleted_at;
    }

    get id() {
        return this.uuid;
    }

    addVariable(variable: any) {
        if (this.variables.find((v) => v.uuid === variable.uuid)) {
            return;
        }
        this.variables.push(variable);
    }

    setCanUpdate(canUpdate:boolean) {
        this.canUpdate = canUpdate;
        return this;
    }
}
