import { Inject, Injectable } from '@angular/core';
import { Autocomplete } from './autocomplete';
import { Tariff, TariffType } from '../../payroll/models/tariff';
import { TranslateService } from '../services/translate.service';
import { CustomerTariffService } from '../../payroll/http/customer-tariff.service';
import { map, of, switchMap } from 'rxjs';
import { DateTime } from 'luxon';
import { sort } from '../angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../services/current.service';

interface SetTariffArgs {
    customerId: number;
}

interface GetTariffsArgs {
    customerId: number;
    // Use this to get suggestions for a specific employee.
    employeeId?: number;
    // This is used as from in suggestions if employeeId is set, and also as the active date when getting all.
    from?: DateTime;
    with?: string[];
    types?: TariffType[];
}

@Injectable({
    providedIn: 'root',
})
export class TariffAutocompleteService extends Autocomplete<Tariff> {

    constructor(
        @Inject(CustomerTariffService) protected customerTariffService: CustomerTariffService,
        @Inject(TranslateService) protected translateService: TranslateService,
        @Inject(CurrentService) protected current: CurrentService,
    ) {
        super({
            label: translateService.t('TARIFF'),
            optionTemplate: 'tariff',
            filterRequirement: 0,
            display: 'name',
            trackByKey: 'id',
        });
    }

    setter(args: SetTariffArgs) {
        return (tariff: unknown) => {
            if (tariff instanceof Tariff) {
                return of(tariff);
            }
            if (typeof tariff !== 'number') {
                return of(undefined);
            }

            return this.customerTariffService.get(args.customerId, tariff);
        };
    }

    getter(args: GetTariffsArgs) {
        return (filter?: string) => {
            const employeeId = args.employeeId;
            const suggestions = employeeId ? this.customerTariffService.getSuggestions(args.customerId, employeeId, { from: args.from }) : of([]);

            return suggestions.pipe(
                switchMap((suggestions) => {
                    return this.customerTariffService.getAll(args.customerId, {
                        order_by: 'name',
                        direction: 'asc',
                        'with[]': args.with,
                        filter: filter?.trim() || undefined,
                        per_page: 20,
                        activeDate: args.from,
                        'types[]': args.types,
                    }).pipe(
                        map((tariffsResponse) => {
                            const dataWithSuggestions = [
                                ...sort(suggestions, this.current.languageTag, [ (suggestion) => suggestion.name ]),
                                ...tariffsResponse.data.filter((tariff) => !suggestions.find((suggestion) => suggestion.id === tariff.id)),
                            ];

                            return {
                                ...tariffsResponse,
                                data: dataWithSuggestions,
                            };
                        }),
                    );
                }),
            );
        };
    };
}
