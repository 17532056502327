import { Component, DoCheck, ElementRef, HostBinding, inject, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, ReactiveFormsModule, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { OnTouched } from '../../types/on-touched';
import { OnChange } from '../../types/on-change';
import { NgIf } from '@angular/common';
import { TinyColor } from '@ctrl/tinycolor';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatDialog } from '@angular/material/dialog';
import { ColorpickerDialogComponent, ColorpickerDialogData, ColorpickerDialogResult } from '../../dialogs/colorpicker-dialog/colorpicker-dialog.component';

@Component({
    selector: 'eaw-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrl: './color-picker.component.scss',
    providers: [
        { provide: MatFormFieldControl, useExisting: ColorPickerComponent },
    ],
    standalone: true,
    imports: [ NgIf, ReactiveFormsModule ],
})
export class ColorPickerComponent implements DoCheck, OnDestroy, MatFormFieldControl<string | null>, ControlValueAccessor {
    private matDialog = inject(MatDialog);

    @HostBinding('class.floating') readonly shouldLabelFloat = true;
    @HostBinding() id = `color-picker-${ColorPickerComponent.nextId++}`;

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    set placeholder(ph: string) {
        this._placeholder = ph;
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(req: BooleanInput) {
        this._disabled = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(req: BooleanInput) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    onTouched?: OnTouched;
    onChange = (_: any) => {
    };

    protected color: string | null = null;
    protected _placeholder: string = '';
    protected touched: boolean = false;
    protected _required = false;
    protected _disabled = false;
    static nextId = 0;
    readonly stateChanges: Subject<void> = new Subject();
    readonly controlType = 'color-picker';
    errorState = false;
    focused = false;
    describedByIds?: string[];

    constructor(
        @Optional() private _parentForm: NgForm,
        @Optional() private _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl | null,
        private _elementRef: ElementRef<HTMLElement>,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.updateErrorState();
        }

        const required = this.ngControl?.control?.hasValidator(Validators.required) || false;
        if (required !== this._required) {
            this._required = required;
            this.stateChanges.next();
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    get empty() {
        return false;
    }

    private updateErrorState() {
        const parent = this._parentFormGroup || this._parentForm;

        const oldState = this.errorState;
        const newState = !!(this.ngControl?.invalid && (this.touched || parent.submitted));

        if (oldState !== newState) {
            this.errorState = newState;
            this.stateChanges.next();
        }
    }

    get value(): string | null {
        return this.color;
    }

    set value(val: string | null) {
        this.color = this.formatValue(val);
        this.onChange(this.color);
        this.stateChanges.next();
    }

    formatValue(value: string | null | undefined): string | null {
        if (value == null) {
            return null;
        }

        const tiny = new TinyColor(value);
        if (tiny.isValid) {
            return tiny.toHexString();
        } else {
            return null;
        }
    }

    openColorPicker() {
        const color = new TinyColor(this.color || '#fff');

        this.matDialog.open<ColorpickerDialogComponent, ColorpickerDialogData, ColorpickerDialogResult>(ColorpickerDialogComponent, {
            data: {
                color: color.isValid ? color : null,
            },
        }).afterClosed().subscribe((color) => {
            if (color === null) {
                this.value = null;
            }
            if (color instanceof TinyColor && color.isValid) {
                this.value = color.toHexString();
            }
        });
    }

    onFocusIn() {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            if (!this.touched) {
                this.touched = true;
                this.onTouched?.();
            }

            if (this.focused) {
                this.focused = false;
            }

            this.stateChanges.next();
        }
    }

    onContainerClick(): void {
        this.openColorPicker();
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: OnChange<string | null>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: OnTouched): void {
        this.onTouched = fn;
    }

    setDescribedByIds(ids: string[]): void {
        this.describedByIds = ids;
    }

    writeValue(val: string): void {
        this.value = val;
    }
}
