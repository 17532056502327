<mat-card>
    <mat-tab-group [mat-stretch-tabs]="false">
        <mat-tab [label]="('NORMAL' | translate:'widgets' | async) || ''">
            <ng-container *ngIf="!fetchingInitial">
                <ng-container *ngTemplateOutlet="table; context: {$implicit: normalWidgets}"></ng-container>
            </ng-container>
        </mat-tab>
        <mat-tab [label]="('MINI' | translate:'widgets' | async) || ''">
            <ng-container *ngIf="!fetchingInitial">
                <ng-container *ngTemplateOutlet="table; context: {$implicit: miniWidgets}"></ng-container>
            </ng-container>
        </mat-tab>
        <mat-tab [label]="('SETTING_plural' | translate | async) || ''">
            <mat-action-list>
                <mat-list-item [disabled]="fetchingInitial" (click)="chooseColor()">
                    <mat-icon matListItemIcon [style.color]="defaultColor?.toHexString()">palette</mat-icon>
                    <span matListItemTitle>{{'DEFAULT_COLOR' | translate:'admin' | async}}</span>
                    <span matListItemLine *ngIf="!defaultColor">{{'SYSTEM_DEFAULT' | translate:'admin' | async}}</span>
                    <span matListItemLine *ngIf="defaultColor">{{defaultColor.toHexString() || ('LOADING' | translate | async)}}</span>
                </mat-list-item>
            </mat-action-list>
        </mat-tab>
    </mat-tab-group>
</mat-card>

<ng-template #table let-widgets>
    <table>
        <thead>
        <tr>
            <th class="icon tw-text-center"></th>
            <th class="translated-name tw-text-left">{{'TRANSLATED_NAME' | translate:'admin' | async}}</th>
            <th class="key tw-text-left">{{'KEY' | translate | async}}</th>
            <th class="toggle tw-text-center">
                <div class="header-container" [matTooltip]="('WIDGET_ENABLED_COL_TIP' | translate:'admin' | async) || ''">
                    <span>{{'ENABLED' | translate | async}}</span>
                    <mat-icon eawSize="16px">help</mat-icon>
                </div>
            </th>
            <th class="toggle tw-text-center">
                <div class="header-container" [matTooltip]="('WIDGET_DEFAULT_COL_TIP' | translate:'admin' | async) || ''">
                    <span>{{'DEFAULT' | translate | async}}</span>
                    <mat-icon eawSize="16px">help</mat-icon>
                </div>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let widget of widgets">
            <td class="icon tw-text-center">
                <mat-icon>{{widget.info.icon}}</mat-icon>
            </td>
            <td class="translated-name tw-text-left">
                {{widget.info.translationKey | translate:widget.info.translationNs | async}}
            </td>
            <td class="key tw-text-left">
                {{widget.info.key}}
            </td>
            <td class="toggle tw-text-center">
                <mat-slide-toggle [formControl]="widget.enabled"></mat-slide-toggle>
            </td>
            <td class="toggle tw-text-center">
                <mat-slide-toggle [formControl]="widget.default"></mat-slide-toggle>
            </td>
        </tr>
        </tbody>
    </table>
</ng-template>
