import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { AvailabilityDay, AvailabilityDayResponse } from './availability-day';
import { Approval, ApprovalResponse } from '../../shared/models/approval';
import { Comment, CommentResponse } from '../../shared/models/comment';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { groupBy, sort } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { BaseApiModel } from '../../shared/models/base-api-model';

export type AvailabilityRepeat = 7 | 14 | 21 | 28;

export interface AvailabilityResponse extends ApiResponse {
    id: number;
    employee_id: number;
    from: string;
    to?: string | null;
    repeat: AvailabilityRepeat | number;
    approved?: boolean;
    days?: AvailabilityDayResponse[];
    work_days: number[];
    comments?: CommentResponse[];
    approval?: ApprovalResponse;
    created_at: string;
    updated_at: string;
    deleted_at?: string | null;
    generated_by_id?: number | null;
    generated_by_type?: string | null;
}

export class Availability extends BaseApiModel<AvailabilityResponse, Availability> {
    id: number;
    employeeId: number;
    from: DateTime;
    to?: DateTime | null;
    // Determines how many weeks the availability lasts (up to 4)
    repeat: AvailabilityRepeat | number;
    // Comment which can be sent to backend
    comment?: string;
    approved: boolean;
    days: AvailabilityDay[] = [];
    weeks: AvailabilityDay[][][] = [];
    // Number of days the employee wants to work per week
    workDays: number[];
    // Comments received from backend
    comments?: Comment[];
    approval?: Approval | null;

    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;
    generatedById?: number | null;
    generatedByType?: string | null;

    constructor(data: AvailabilityResponse) {
        super(data, undefined);

        this.id = data.id;
        this.employeeId = data.employee_id;
        this.from = stringToDateTime(data.from);
        this.to = data.to ? stringToDateTime(data.to) : null;
        this.repeat = data.repeat || 7;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.generatedById = data.generated_by_id;
        this.generatedByType = data.generated_by_type;
        this.approval = data.approval ? new Approval(data.approval) : null;
        this.approved = data?.approved || this.approval?.approved || false;
        this.workDays = data.work_days?.slice(0, 4) ?? new Array(this.repeat / 7).fill(5);
        this.comments = data.comments?.map((c) => new Comment(c));

        this.setDays(data);
        this.setWeeks();
    }

    private setWeeks() {
        this.weeks = [
            this.groupIntervals(this.days.filter((d) => d.day < 7)),
            this.groupIntervals(this.days.filter((d) => d.day >= 7 && d.day < 14)),
            this.groupIntervals(this.days.filter((d) => d.day >= 14 && d.day < 21)),
            this.groupIntervals(this.days.filter((d) => d.day >= 21)),
        ].reduce<AvailabilityDay[][][]>((acc, week) => {
            if (week.length > 0) {
                acc.push(week);
            }

            return acc;
        }, []);
    }

    private setDays(data: AvailabilityResponse) {
        const sortedDays = sort(data.days?.map((d) => new AvailabilityDay(d)) || [], 'en', [ (d) => d.day, (d) => d.offset ]);
        let mergedDays: AvailabilityDay[] = [];

        for (let i = 0; i < sortedDays.length; i++) {
            const day = sortedDays[i];
            if (!day) {
                continue;
            }

            const nextDay = sortedDays[i + 1] || sortedDays[0];
            if ((nextDay?.day === day.day + 1 || nextDay?.day === 0) && nextDay.startsAtMidnight && day.endsAtMidnight && day.length != null && nextDay.length != null) {
                const newLength = day.length + nextDay.length;
                if (newLength > 86400) {
                    continue;
                }

                if (i === sortedDays.length - 1) {
                    mergedDays = mergedDays.slice(1);
                }

                day.to = nextDay.to;
                day.length = newLength;
                mergedDays.push(day);
                i++;
            } else {
                mergedDays.push(day);
            }
        }

        const days: AvailabilityDay[] = [];
        for (let i = 0; i < data.repeat; i++) {
            const filteredDays = mergedDays.filter((d) => d.day === i);
            if (filteredDays.length) {
                days.push(...filteredDays);
            } else {
                const offDay = new AvailabilityDay({
                    availability_id: data.id,
                    date: '',
                    from: '',
                    id: 0,
                    length: 0,
                    offset: 0,
                    to: '',
                    whole_day: false,
                    day: i,
                });

                offDay.date = this.from.plus({ days: i });
                days.push(offDay);
            }
        }

        this.days = sort(days, 'en', [ (d) => d.day, (d) => d.offset ]);
    }

    private groupIntervals(days: AvailabilityDay[]): AvailabilityDay[][] {
        return Array.from(groupBy(days, 'day').values());
    }

    isValid() {
        const valids = [ this.from?.isValid ];
        this.workDays?.forEach((day) => valids.push(day != null && day >= 0 && day <= 7));
        return valids.every((x) => x);
    }
}
