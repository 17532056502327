import { module } from 'angular';
import { Products } from '../../shared/enums/products';
import { Namespace } from '../../shared/enums/namespace';
import { NavTabsComponent } from '../../shared/components/nav-tabs/nav-tabs.component';
import { EmployeeTimeOffComponent } from '../pages/employee-time-off/employee-time-off.component';
import { EmployeeVacationDaysComponent } from '../pages/employee-vacation-days/employee-vacation-days.component';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { createNavTabsState, createState } from '../../shared/utils/create-state';
import { navTabsTabsResolver } from '../../shared/resolvers/nav-tabs-tabs.resolver';
import { paramResolver } from '../../shared/resolvers/param.resolver';
import { HandleVacationListComponent } from '../pages/handle-vacation-list/handle-vacation-list.component';
import { OverrideOffTimeComponent } from '../pages/override-off-time/override-off-time.component';
import { dependencyResolver } from '../../shared/resolvers/dependency.resolver';
import { staticResolver } from '../../shared/resolvers/static.resolver';
import { DataTableQueryParams } from '../../data-table/data-table.component';
import { ApiModel } from '../../shared/enums/api-model';
import { PermissionCheckService } from '../../shared/services/permission-check.service';
import { switchMap, lastValueFrom, map, of, forkJoin } from 'rxjs';
import { CurrentService } from '../../shared/services/current.service';

module('eaw.vacation', [
    'ngTable',
    'eaw.resource',
    'eaw.login',
    'eaw.misc',
    'eaw.alerts',
    'eaw.time',
    'eaw.dialogs',
    'eaw.employees',
]).config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state(`eaw/app/vacations`, {
        parent: 'eaw/app',
        url: '/vacations',
        abstract: true,
        data: {
            breadcrumb: { key: 'VACATIONS', ns: 'navigation' },
            products: [ Products.Vacation ],
            i18nextNs: [ Namespace.Absences, Namespace.Vacation, Namespace.Company ],
        },
    });
    createState($stateProvider, {
        name: `eaw/app/vacations/request/list`,
        parent: `eaw/app/vacations`,
        url: '/my',
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        data: {
            permissions: [
                `customers.{customer}.employees.{employee}.off_times.*.get`,
            ],
            breadcrumb: { key: 'MY_VACATION', ns: 'navigation' },
            requiresEmployee: true,
        },
        resolve: [
            navTabsTabsResolver(function($transition$) {
                const current = $transition$.injector().get(CurrentService) as CurrentService;
                return Promise.resolve([
                    {
                        state: 'eaw/app/vacations/request/list/timeoff',
                        label: {
                            key: 'MY_REQUESTS',
                            ns: 'navigation',
                        },
                    },
                    {
                        state: 'eaw/app/vacations/request/list/vacation_days',
                        label: {
                            key: 'VACATION_DAYS',
                            ns: 'vacation',
                        },
                        permissions: [
                            [
                                `customers.[${ApiModel.Customer}].employees.[${ApiModel.Employee}].vacation_days.*.get`, {
                                    stackId: current.getCustomer().stackId,
                                    models: [
                                        {
                                            type: ApiModel.Customer,
                                            id: current.getCustomer().id,
                                        }, {
                                            type: ApiModel.Employee,
                                            // requires employee hook will abort if employee is not set
                                            id: current.getEmployee()!.id,
                                        },
                                    ],
                                },
                            ],
                        ],
                    },
                ]);
            }),
        ],
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/vacations/request/list/timeoff`,
        parent: `eaw/app/vacations/request/list`,
        url: '/timeoff',
        resolve: [
            currentResolver('employee', 'employee'),
            currentResolver('customer', 'customer'),
        ],
        component: EmployeeTimeOffComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'MY_REQUESTS', ns: 'navigation' },
            requiresEmployee: true,
            allowExternal: true,
            permissions: [ `customers.{customer}.employees.{employee}.off_times.*.get` ],
        },
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/vacations/request/list/vacation_days`,
        parent: `eaw/app/vacations/request/list`,
        url: '/vacation_days',
        component: EmployeeVacationDaysComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'VACATION_DAYS', ns: 'vacation' },
        },
        resolve: [
            currentResolver('customerId', 'employee', 'customerId'),
            currentResolver('employeeId', 'employee', 'id'),
        ],
    });

    createState($stateProvider, {
        name: `eaw/app/vacations/manage`,
        parent: `eaw/app/vacations`,
        abstract: true,
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            somePermissions: [
                'customers.{customer}.employees.*.off_times.*.update',
                'customers.{customer}.off_times.manage',
            ],
            i18nextNs: [ Namespace.Leaveshifts, Namespace.Absences ],
            breadcrumb: { key: 'VACATION_MANAGE', ns: 'navigation' },
        },
        resolve: [
            staticResolver('navbarRoute', 'eaw/app/vacations/manage'),
            dependencyResolver('offTimeFactory', [ 'OffTimeService', 'customer' ], function(OffTimeService: any, customer: any) {
                return new OffTimeService(customer.id);
            }),
            dependencyResolver('tabs', [ 'offTimeFactory', 'PermissionCheckServiceDowngrade', 'customer' ], function(offTimeFactory: any, PermissionCheckServiceDowngrade: PermissionCheckService, customer: any) {
                const highlightGoal = DateTime.fromObject({
                    year: 2022,
                    month: 9,
                    day: 22,
                });
                const tabs = [
                    {
                        label: 'vacation:REQUEST_plural',
                        src: `eaw/app/vacations/manage/handle`,
                    },
                    {
                        label: 'vacation:OVERRIDE',
                        src: `eaw/app/vacations/manage/override`,
                        permission: offTimeFactory.create.permission(),
                    },
                    {
                        label: 'OVERVIEW',
                        src: `eaw/app/vacations/manage/overview`,
                        highlight: DateTime.now() < highlightGoal,
                    },
                ];

                const vacationDaysTab = {
                    label: 'vacation:VACATION_DAYS',
                    src: `eaw/app/vacations/manage/days`,
                    highlight: DateTime.now() < highlightGoal,
                };

                const checkAll = PermissionCheckServiceDowngrade.isAllowed(`customers.[${ApiModel.Customer}].employees.*.vacation_days.*.get`, {
                    models: [
                        {
                            type: ApiModel.Customer,
                            id: customer['id'],
                        },
                    ],
                }).pipe(
                    switchMap((result) => {
                        if (result) {
                            tabs.push(vacationDaysTab);

                            return of(tabs);
                        }

                        return PermissionCheckServiceDowngrade.permissionChildrenSingle(`customers.${customer['id']}.employees`, 'vacation_days.*.get', true)
                            .pipe(map((children) => {
                                if (Array.isArray(children) && children.length) {
                                    tabs.push(vacationDaysTab);
                                }

                                return tabs;
                            }));
                    }));

                return lastValueFrom(checkAll);
            }),
        ],
    });

    $stateProvider.state(`eaw/app/vacations/manage/days`, {
        parent: `eaw/app/vacations/manage`,
        url: '/days',
        data: {
            breadcrumb: { key: 'DAY_plural' },
        },
        component: 'vacationDaysOverview',
    });
    createState($stateProvider, {
        name: `eaw/app/vacations/manage/days/employee`,
        parent: `eaw/app/vacations/manage/days`,
        url: '/:customerid/:id',
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'EMPLOYEE' },
            permissions: [ `customers.{customer}.employees.{id}.vacation_days.*.get` ],
        },
        views: {
            'content@': {
                component: EmployeeVacationDaysComponent,
            },
        },
        resolve: [
            paramResolver('customerid', 'customerId'),
            paramResolver('id', 'employeeId'),
        ],
    });
    $stateProvider.state(`eaw/app/vacations/manage/overview`, {
        parent: `eaw/app/vacations/manage`,
        url: '/overview',
        component: 'vacationOverview',
        data: {
            breadcrumb: { key: 'OVERVIEW', ns: 'navigation' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/vacations/manage/handle`,
        parent: `eaw/app/vacations/manage`,
        url: '/handle',
        component: HandleVacationListComponent,
        data: {
            queryParams: [ ...DataTableQueryParams, 'e', 'o', 'from', 'to' ],
            breadcrumb: { key: 'VACATION_HANDLE', ns: 'navigation' },
            somePermissions: [
                `customers.{customer}.employees.*.off_times.*.update`,
                `customers.{customer}.off_times.manage`,
            ],
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('stackId', 'customer', 'stackId'),
            currentResolver('userId', 'user', 'id'),
        ],
    });

    createState($stateProvider, {
        name: `eaw/app/vacations/manage/override`,
        parent: `eaw/app/vacations/manage`,
        url: '/override',
        component: OverrideOffTimeComponent,
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            breadcrumb: { key: 'OVERRIDE_OFFTIME', ns: 'vacation' },
        },
    });
    $stateProvider.state('eaw/app/vacations/view', {
        parent: `eaw/app/vacations/manage/handle`,
        url: '/:employeeId/:id',
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            breadcrumb: { key: 'OFF_TIME' },
        },
        resolve: {
            offTime: [ '$transition$', 'customer', 'OffTime', function($transition$: any, customer: any, OffTime: any) {
                const params = $transition$.params();
                return OffTime.get({
                    customer_id: customer.id,
                    employee_id: params.employeeId,
                    off_time_id: params.id,
                    with: [
                        'employee.positions',
                        'employee.user',
                        'approval',
                        'comments.user',
                        'vacationDaysChange',
                    ],
                });
            } ],
            employee: [ 'offTime', function(offTime: any) {
                return offTime.employee;
            } ],
            navbarRoute: () => 'eaw/app/vacations/view/edit',
            tabs: [ 'customer', function(customer: any) {
                const tabs = [ {
                    label: 'EDIT',
                    src: 'eaw/app/vacations/view/edit',
                } ];
                if (customer.hasProduct('Leave Shifts')) {
                    tabs.push({
                        label: 'leaveshifts:LEAVE_SHIFT_plural',
                        src: 'eaw/app/vacations/view/leave-shifts',
                    });
                }
                tabs.push({
                    label: 'vacation:TIMELINE',
                    src: 'eaw/app/vacations/view/timeline',
                    // @ts-ignore
                    permission: `customers.${customer.id}.employees.*.off_times.*.get`,
                });
                return tabs;
            } ],
        },
    });
    $stateProvider.state('eaw/app/vacations/view/edit', {
        parent: `eaw/app/vacations/view`,
        url: '/edit',
        component: 'editOffTime',
        data: {
            i18nextNs: [ Namespace.Calendar ],
            breadcrumb: { key: 'EDIT' },
        },
        resolve: {
            vacationDaysText: [ 'vacationDays', 'offTime', 'employee', 'customer', function(vacationDays: any, offTime: any, employee: any, customer: any) {
                if (customer.hasProduct(Products.Switzerland)) {
                    return '';
                }
                const year = offTime.from.year();
                return vacationDays.getEmployeeStats({
                    customer,
                    employee,
                    year,
                }).$promise.then((resp: any) => t('vacation:VAC_DAYS_REMAINING_YEAR', {
                    count: resp.vacation_days,
                    year,
                }));
            } ],
        },
    });
    $stateProvider.state('eaw/app/vacations/view/leave-shifts', {
        parent: `eaw/app/vacations/view`,
        url: '/leave_shifts',
        component: 'leaveShifts',
        data: {
            breadcrumb: { key: 'LEAVE_SHIFTS', ns: 'navigation' },
            permissions: [ 'customers.{customer}.employees.{employeeId}.off_times.{id}.leave_shifts.*.get' ],
        },
        resolve: {
            leaveShifts: [ 'LeaveShiftFactory', 'customer', '$transition$', function(LeaveShiftFactory: any, customer: any, $transition$: any) {
                const params = $transition$.params();
                return LeaveShiftFactory.getAll({
                    customer_id: customer.id,
                    employee_id: params.employeeId,
                    model: 'off_times',
                    model_id: params.id,
                    with: [ 'customer' ],
                }).$promise.then((resp: any) => resp.data);
            } ],
            object: [ 'offTime', function(offTime: any) {
                return offTime;
            } ],
            objectType() {
                return 'off_times';
            },
            permissions: [ '$transition$', function($transition$: any) {
                const params = $transition$.params();

                const permissionCheck = $transition$.injector().get(PermissionCheckService) as PermissionCheckService;
                const current = $transition$.injector().get(CurrentService) as CurrentService;

                const options = {
                    stackId: current.getCustomer().stackId,
                    models: [
                        {
                            type: ApiModel.Customer,
                            id: current.getCustomer().id,
                        }, {
                            type: ApiModel.Employee,
                            id: params.employeeId,
                        }, {
                            type: ApiModel.OffTime,
                            id: params.id,
                        },
                    ],
                };

                const observable = forkJoin([
                    permissionCheck.isAllowed(`customers.[${ApiModel.Customer}].employees.[${ApiModel.Employee}].off_times.[${ApiModel.OffTime}].leave_shifts.create`, options),
                    permissionCheck.isAllowed(`customers.[${ApiModel.Customer}].employees.[${ApiModel.Employee}].off_times.[${ApiModel.OffTime}].leave_shifts.*.update`, options),
                    permissionCheck.isAllowed(`customers.[${ApiModel.Customer}].employees.[${ApiModel.Employee}].off_times.[${ApiModel.OffTime}].leave_shifts.*.delete`, options),
                ]).pipe(map(([ canCreate, canUpdate, canDelete ]) => {
                    return {
                        create: canCreate,
                        update: canUpdate,
                        delete: canDelete,
                    };
                }));

                return lastValueFrom(observable);
            } ],
        },
    });
    $stateProvider.state('eaw/app/vacations/view/timeline', {
        parent: `eaw/app/vacations/view`,
        url: '/edit',
        component: 'timeline',
        data: {
            breadcrumb: { key: 'TIMELINE', ns: 'vacation' },
            permissions: [ `customers.{customer}.employees.*.off_times.*.get` ],
        },
        resolve: {
            rows: [ 'offTimeFactory', 'offTime', function(offTimeFactory: any, offTime: any) {
                return offTimeFactory.customer.getAll.query({
                    approved: true,
                    from: offTime.from,
                    to: offTime.to,
                    with: [ 'employee.positions' ],
                }).$promise.then((resp: any) => {
                    return resp.data.map((absence: any) => {
                        absence.name = `${absence.employee.name}<br />${absence.employee.positions.map((p: any) => p.name).join(', ')}`;
                        return absence;
                    });
                });
            } ],
        },
    });
} ]);
