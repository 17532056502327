// @ts-nocheck
import { module } from 'angular';
import { HandleSwapsComponent } from '../../../pages/handle-swaps/handle-swaps.component';
import { createState } from '../../../../shared/utils/create-state';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
import { DataTableQueryParams } from '../../../../data-table/data-table.component';
module('eaw.scheduling.shifts').config([ '$stateProvider', function config($stateProvider: any) {
    $stateProvider.state('eaw/app/scheduling/swaps', {
        parent: `eaw/app/scheduling`,
        url: '/swaps',
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            breadcrumb: { key: 'SHIFT_SWAPS', ns: 'navigation' },
            permissions: [ 'customers.{customer}.shift_swaps.*.get' ],
        },
        resolve: {
            navbarRoute: () => 'eaw/app/scheduling/swaps',
            tabs: [ 'customer', function(customer) {
                return [ {
                    src: 'eaw/app/scheduling/swaps/handle',
                    label: 'scheduling:HANDLE_SWAPS',
                }, {
                    src: 'eaw/app/scheduling/swaps/stats',
                    label: 'STATISTIC_plural',
                    permission: `customers.${customer.id}.shift_swaps.statistics.get`,
                } ];
            } ],
            permissions: [ 'customer', function(customer) {
                return { update: CurrentOld.can(`customers.${customer.id}.shift_swaps.*.update`) };
            } ],
        },
    });
    createState($stateProvider, {
        name: 'eaw/app/scheduling/swaps/handle',
        parent: 'eaw/app/scheduling/swaps',
        url: '/handle',
        component: HandleSwapsComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'HANDLE_SWAPS', ns: 'scheduling' },
            permissions: [ `customers.{customer}.shift_swaps.*.update` ],
        },
    });
    $stateProvider.state('eaw/app/scheduling/swaps/stats', {
        parent: 'eaw/app/scheduling/swaps',
        url: '/stats',
        component: 'shiftSwapStats',
        data: {
            breadcrumb: { key: 'STATISTIC_plural', ns: 'scheduling' },
        },
    });
} ]);
