// @ts-nocheck
import { module } from 'angular';
import { PaginationOld } from '../../shared/utils/pagination-old';
export class PeriodLocksFactory {
    EawResource;
    static get $inject() {
        return [ 'EawResource' ];
    }

    url = '/customers/:customer/period_locks/:lock';
    constructor(EawResource) {
        this.EawResource = EawResource;
    }

    getLocks(customerId, pagination) {
        return this.EawResource.create(this.url).get({
            ...PaginationOld.getParams(pagination),
            customer: customerId,
            order_by: 'to',
            direction: 'desc',
            per_page: 9999,
            'with[]': [ 'user' ],
        });
    }

    create(customerId, to) {
        return this.EawResource.create(this.url).save({ customer: customerId }, { to });
    }

    delete(customerId, lockId) {
        return this.EawResource.create(this.url).delete({
            customer: customerId,
            lock: lockId,
        });
    }
}
module('eaw.period-locks').service('PeriodLocksFactory', PeriodLocksFactory);
