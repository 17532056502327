import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentServiceService } from '../../../shared/http/document-service.service';
import { SignDocumentTask } from '../../classes/sign-document-task';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { CurrentService } from '../../../shared/services/current.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DateTime } from 'luxon';
import { MatDialog } from '@angular/material/dialog';
import { SignatureDialogComponent, SignatureDialogComponentData, SignatureDialogReturn } from '../../../shared/dialogs/signature-dialog/signature-dialog.component';
import { Namespace } from '../../../shared/enums/namespace';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatButtonModule } from '@angular/material/button';
import { PdfRendererComponent } from '../../../shared/components/pdf-renderer/pdf-renderer.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { EmployeeTask } from '../../../shared/models/employee-task';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'eaw-sign-document-task',
    templateUrl: './sign-document-task.component.html',
    styleUrl: './sign-document-task.component.scss',
    standalone: true,
    imports: [
        NgIf,
        PdfRendererComponent,
        MatButtonModule,
        MaterialColorDirective,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
        MatBottomSheetModule,
    ],
})
export class SignDocumentTaskComponent implements OnInit {
    @Input({ transform: (value: EmployeeTask): SignDocumentTask => value as SignDocumentTask, required: true }) task!: SignDocumentTask;

    protected status: 'unhandled' | 'signed' | 'rejected' | 'postponed' = 'unhandled';
    protected url: Observable<string> | null = null;
    protected formattedUserName: string;
    protected date: string;
    protected handling = false;

    constructor(
        @Inject(DomSanitizer) public sanitizer: DomSanitizer,
        @Inject(TranslateService) public translate: TranslateService,
        @Inject(DocumentServiceService) public timepunchVerificationService: DocumentServiceService,
        @Inject(CurrentService) public current: CurrentService,
        @Inject(ConfirmDialogService) public confirmDialogService: ConfirmDialogService,
        @Inject(MatDialog) public matDialog: MatDialog,
    ) {
        this.formattedUserName = `${this.current.getUser()?.lastName}, ${this.current.getUser()?.firstName}`;
        this.date = DateTime.now().toLocaleString(DateTime.DATE_SHORT);
    }

    ngOnInit(): void {
        this.url = this.task.url ? of(this.task.url) : null;
    }

    signDocument() {
        this.matDialog.open<SignatureDialogComponent, SignatureDialogComponentData, SignatureDialogReturn>(SignatureDialogComponent, { data: {} }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            const signature = result.base64;

            if (this.task.initialsRequired) {
                this.matDialog.open<SignatureDialogComponent, SignatureDialogComponentData, SignatureDialogReturn>(SignatureDialogComponent, {
                    data: {
                        initials: true,
                    },
                }).afterClosed().subscribe((resultInitials) => {
                    if (!resultInitials) {
                        return;
                    }
                    const initials = resultInitials.base64;
                    this.completeSigning(signature, initials);
                });
            } else {
                this.completeSigning(signature);
            }
        });
    }

    completeSigning(signature: string, initials?: string) {
        this.handling = true;
        this.timepunchVerificationService.signDocument(
            this.task.customerId,
            this.task.employeeId,
            this.task.workflowId,
            this.task.runId,
            this.task.currentStep,
            this.task.signatoryName,
            this.current.getCustomer().timeZone,
            signature,
            this.task.revision,
            initials,
        ).subscribe(() => {
            this.status = 'signed';
            this.task.complete();
        });
    }

    rejectDocument() {
        this.confirmDialogService.delete({
            title: this.translate.t('REJECT'),
            text: this.translate.t('REJECT_COMMENT', Namespace.Tasks),
            confirmText: this.translate.t('REJECT'),
            comment: {
                include: true,
                required: true,
            },
        }).afterClosed().subscribe((result) => {
            if (!result?.ok || !result.comment) {
                return;
            }

            this.handling = true;
            this.timepunchVerificationService.rejectDocument(
                this.task.customerId,
                this.task.employeeId,
                this.task.workflowId,
                this.task.runId,
                this.task.currentStep,
                result.comment,
            ).subscribe(() => {
                this.status = 'rejected';
                this.task.complete();
            });
        });
    }
}
