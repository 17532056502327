<form [formGroup]="form">
    <mat-form-field id="first-name">
        <mat-label>{{ 'FIRST_NAME' | translate | async }}</mat-label>
        <input matInput formControlName="firstName">
        <mat-error><eaw-field-error [control]="form.controls.firstName"/></mat-error>
    </mat-form-field>

    <mat-form-field id="last-name">
        <mat-label>{{ 'LAST_NAME' | translate | async }}</mat-label>
        <input matInput formControlName="lastName">
        <mat-error><eaw-field-error [control]="form.controls.lastName"/></mat-error>
    </mat-form-field>

    <eaw-autocomplete formControlName="languageCode"
                      id="language"
                      [hint]="languageHint"
                      [options]="languageAutocompleteService.options"
                      [setter]="languageAutocompleteService.setter()"
                      [getter]="languageAutocompleteService.getter()">
    </eaw-autocomplete>

    <mat-form-field id="phone">
        <mat-label>{{ 'PHONE' | translate | async }}</mat-label>
        <eaw-dial-phone-input formControlName="phone"></eaw-dial-phone-input>
        <mat-error><eaw-field-error [control]="form.controls.phone"></eaw-field-error></mat-error>
    </mat-form-field>
</form>

@if (!user) {
    <eaw-action-button id="submit-btn" type="submit" [loading]="processing" [disabled]="form.invalid" (click)="create()">{{ 'CREATE_APP_ACCESS' | translate:'new_employee' | async }}</eaw-action-button>
}

<eaw-new-employee-next-step [stepper]="stepper" [step]="step"/>
