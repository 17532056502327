// @ts-nocheck
import { timeStringToHourMin, uniqueId } from '../../misc/services/easy-funcs.service';
import moment from 'moment-timezone';
import { module } from 'angular';
import { Mobile } from '../../../../utils/eaw-mobile';

module('eaw.time').component('eawTimePickerOld', {
    template: `<label ng-if="$timePicker.label" ng-i18next="{{$timePicker.label}}" for="{{::$timePicker.uniqueId}}"></label>
<input ng-if="$timePicker.useTime"
       ng-model="$timePicker.time"
       ng-change="$timePicker.update()"
       ng-disabled="$timePicker.isDisabled"
       name="{{::$timePicker.uniqueId}}"
       id="{{::$timePicker.uniqueId}}"
       ng-model-options="{timeStripZeroSeconds: true}"
       type="time"
       step="60"
       uniq-form-name
       ng-disabled="$timePicker.isDisabled"
       ng-required="$timePicker.isRequired">

<input ng-if="!$timePicker.useTime"
       ng-model="$timePicker.time"
       name="{{::$timePicker.uniqueId}}"
       eaw-simple-time
       uniq-form-name
       id="{{::$timePicker.uniqueId}}"
       ng-model-options="{updateOn: 'blur'}"
       ng-change="$timePicker.update()"
       ng-disabled="$timePicker.isDisabled"
       ng-required="$timePicker.isRequired">
`,
    bindings: {
        baseDate: '<?',
        label: '=?',
        isDisabled: '<?',
        minTime: '<?',
        maxTime: '<?',
        useParent: '<?', // For time interval
    },
    require: {
        ngModel: 'ngModel',
        eawTimeInterval: '?^^eawTimeInterval',
    },
    controllerAs: '$timePicker',
    controller: [ '$scope', '$attrs', function($scope, $attrs) {
        const ctrl = this;
        ctrl.$onChanges = (changes) => {
            ctrl.checkValidity();
            if (changes.baseDate && !changes.baseDate.isFirstChange()) {
                update();
            }
        };
        ctrl.update = () => {
            ctrl.checkValidity();
            update();
        };
        ctrl.$onInit = () => {
            ctrl.useTime = ctrl.timeInputSupported();
            ctrl.form = {};
            ctrl.uniqueId = uniqueId('eaw-time-');
            if (ctrl.label == null) {
                ctrl.label = 'TIME';
            }
            ctrl.ngModel.$formatters.unshift(ctrl.formatter);
            $attrs.$observe('required', (value) => {
                ctrl.isRequired = value;
            });
            $attrs.$observe('disabled', (value) => {
                ctrl.isDisabled = value;
            });
        };
        ctrl.formatter = (model) => {
            ctrl.setBaseDate(model);
            ctrl.setTime(model);
            ctrl.checkValidity();
            return model;
        };
        ctrl.setTime = (model) => {
            if (moment.isMoment(model)) {
                if (ctrl.useTime) {
                    ctrl.time = model.toDate();
                    ctrl.time.setYear(ctrl.baseDate.year());
                    ctrl.time.setMonth(ctrl.baseDate.month());
                    ctrl.time.setDate(ctrl.baseDate.date());
                    ctrl.time.setHours(model.hours());
                    ctrl.time.setMinutes(model.minutes());
                    ctrl.time.setSeconds(0);
                    ctrl.time.setMilliseconds(0);
                } else {
                    ctrl.time = model;
                }
            } else {
                ctrl.time = undefined;
            }
        };
        ctrl.setBaseDate = (model) => {
            if (!moment.isMoment(ctrl.baseDate)) {
                if (moment.isMoment(model)) {
                    ctrl.baseDate = model.clone();
                } else {
                    ctrl.baseDate = moment();
                }
            }
        };
        ctrl.getTime = () => {
            const clone = ctrl.baseDate.clone();
            if (ctrl.useTime && ctrl.time instanceof Date) {
                clone.hours(ctrl.time.getHours());
                clone.minutes(ctrl.time.getMinutes());
            }
            if (moment.isMoment(ctrl.time)) {
                clone.hours(ctrl.time.hours()).minutes(ctrl.time.minutes()).seconds(0);
            } else if (typeof ctrl.time === 'string') {
                const time = timeStringToHourMin(ctrl.time);
                clone.hours(time.hour);
                clone.minutes(time.minute);
                clone.seconds(0);
            }
            return clone;
        };
        ctrl.getTimeInput = () => ctrl.form?.[ctrl.uniqueId]?.[ctrl.uniqueId];
        ctrl.checkValidity = () => {
            $scope.$evalAsync(checkValidity);
        };
        ctrl.timeInputSupported = () => {
            let supported;
            const testInput = document.createElement('input');
            try {
                testInput.type = 'time';
                supported = testInput.type !== 'text';
            } catch (_) {
                supported = false;
            }
            testInput.remove();
            return supported && Mobile.isMobile;
        };

        function update() {
            ctrl.ngModel.setViewValue(ctrl.getTime());
        }

        function checkValidity() {
            let beforeMinValid = true;
            let afterMaxValid = true;
            // This specific component instance if the to input in the interval component if true
            const time = ctrl.useParent && ctrl.eawTimeInterval ? ctrl.eawTimeInterval.to : ctrl.getTime();
            const input = ctrl.getTimeInput();
            if (input) {
                input.$setDirty();
                if (time) {
                    input.$setTouched();
                }
                if (moment.isMoment(time) && moment.isMoment(ctrl.minTime)) {
                    beforeMinValid = !time.isBefore(ctrl.minTime, 'm');
                    ctrl.formattedMinTime = ctrl.minTime.format('lll zz');
                    input.$setValidity('beforeMinDate', beforeMinValid);
                } else {
                    input.$setValidity('beforeMinDate', true);
                }
                if (moment.isMoment(time) && moment.isMoment(ctrl.maxTime)) {
                    afterMaxValid = !time.isAfter(ctrl.maxTime, 'm');
                    ctrl.formattedMaxTime = ctrl.maxTime.format('lll zz');
                    input.$setValidity('afterMaxDate', afterMaxValid);
                } else {
                    input.$setValidity('afterMaxDate', true);
                }
                // Show better error logs
                if (!beforeMinValid || !afterMaxValid) {
                    console.groupCollapsed('Time interval error');
                    if (ctrl.baseDate?.format) {
                        console.log('Base date', ctrl.baseDate.format('LLLL'), ctrl.baseDate);
                    }
                    if (time?.format) {
                        console.log('Time', time.format('LLLL'), time);
                    }
                    if (ctrl.minTime?.format) {
                        console.log('Min date', ctrl.minTime.format('LLLL'), ctrl.minTime);
                    }
                    if (ctrl.maxTime?.format) {
                        console.log('Max date', ctrl.maxTime.format('LLLL'), ctrl.maxTime);
                    }
                    console.groupEnd();
                }
            }
        }
    } ],
});
