<div id="virtual-scroll-container">
    <mat-progress-spinner mode="indeterminate" *ngIf="!dataSource"></mat-progress-spinner>

    <cdk-virtual-scroll-viewport *ngIf="dataSource" itemSize="100" appendOnly class="shifts-viewport mat-elevation-z2">
        <div *cdkVirtualFor="let item of dataSource; templateCacheSize: 0; let index = index">
            <div class="shift tw-flex tw-flex-row tw-justify-start tw-items-center" *ngIf="item" [ngClass]="item.shift.status" [class.has-swap]="item.shift.hasUnprocessedSwaps()">
                <ng-container *ngIf="useSmallScroll" [ngTemplateOutlet]="smallTemplate" [ngTemplateOutletContext]="{shift: item.shift}"></ng-container>
                <ng-container *ngIf="!useSmallScroll" [ngTemplateOutlet]="bigTemplate" [ngTemplateOutletContext]="{shift: item.shift}"></ng-container>

                <div class="buttons-container tw-grid-cols-[40px_40px] tw-gap-8">
                    <mat-progress-spinner class="tw-col-span-full" mode="indeterminate" strokeWidth="3" diameter="28" *ngIf="item.processing"></mat-progress-spinner>

                    <ng-container *ngIf="!item.processing && !item.shift.hasUnprocessedSwaps()">
                        <button mat-icon-button class="tw-col-[1_/_2]" (click)="swap(item)" *ngIf="item.canCreateSwap | async">
                            <mat-icon>swap_horiz</mat-icon>
                        </button>
                    </ng-container>

                    <ng-container *ngIf="!item.processing && item.shift.hasUnprocessedSwaps()">
                        <button mat-icon-button class="tw-col-[1_/_2]" (click)="withdraw(item)" *ngIf="item.canWithdrawSwap | async">
                            <mat-icon>keyboard_return</mat-icon>
                        </button>
                    </ng-container>

                    <button mat-icon-button class="tw-col-[2_/_-1]" *ngIf="!item.processing && item.shift.comments.length" (click)="showComments(item.shift)">
                        <mat-icon>comment</mat-icon>
                    </button>
                </div>
            </div>

            <div *ngIf="!item" class="shift loading">{{'LOADING' | translate | async}}</div>
        </div>
    </cdk-virtual-scroll-viewport>
</div>

<ng-template #smallTemplate let-shift="shift">
    <div class="small tw-flex tw-flex-row tw-justify-between tw-items-center tw-basis-full">
        <div class="tw-flex tw-flex-col tw-justify-center tw-content-start">
            <div class="mat-body-2 from-time" style="margin: 0">{{shift.from | DateTime:'DATETIME_MED_WITH_WEEKDAY'}} - {{shift.to | DateTime:'TIME_SIMPLE'}}</div>
            <div class="mat-body-2" style="margin: 0">{{shift.schedule.customer?.name}}</div>
            <!--            <div style="font-style: italic" *ngIf="shift.periods[0].businessUnit">{{shift.periods[0].businessUnit.name}}</div>-->
            <div class="mat-body-2" style="margin: 0">{{(shift.length | duration:['hours', 'minutes']:{unitDisplay: 'long'})}}</div>
        </div>
    </div>
</ng-template>

<ng-template #bigTemplate let-shift="shift">
    <div class="big tw-flex tw-flex-row tw-justify-start tw-items-center tw-basis-full">
        <div class="tw-flex tw-flex-col">
            <div class="mat-subtitle-1 from-time" style="margin: 0">{{shift.from | DateTime:'DATETIME_MED_WITH_WEEKDAY'}} - {{shift.to | DateTime:'TIME_SIMPLE'}}</div>
            <div style="margin: 0; font-style: italic">{{shift.schedule.customer?.name}}</div>
        </div>

        <div class="tw-basis-full"></div>

        <div class="tw-flex tw-flex-col tw-justify-center tw-content-end">
            <div class="mat-body-1" style="margin: 0" *ngIf="shift.periods?.[0]?.businessUnit">{{shift.periods[0].businessUnit.name}}</div>
            <div class="mat-body-2" style="margin: 0">{{(shift.length | duration:['hours', 'minutes']:{unitDisplay: 'long'})}}</div>
        </div>
    </div>
</ng-template>
