// @ts-nocheck
import { uniqueId } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.filesystem').component('eawItemInfo', {
    template: `<md-sidenav class="md-sidenav-right mat-elevation-z2" md-component-id="{{$ctrl.id}}">
    <div id="sidenav-right-info-header">
        <eaw-mime-icon class="tw-mr-12" size="'24'" ng-if="$ctrl.item.type == 'file'" type="$ctrl.item.file.mime"></eaw-mime-icon>
        <eaw-mime-icon class="tw-mr-12" size="'24'" ng-if="$ctrl.item.type == 'directory'" type="'folder'"></eaw-mime-icon>

        <h1 ng-bind="$ctrl.item.name" flex></h1>

        <md-button class="md-icon-button" ng-click="$ctrl.sidenav.close()">
            <md-icon ng-bind="'close'"></md-icon>
        </md-button>
    </div>
    <md-content>
        <md-progress-circular ng-if="$ctrl.infoLoading" class="tw-mt-20 md-progress-center"></md-progress-circular>

        <md-tabs ng-if="!$ctrl.infoLoading && $ctrl.item" md-stretch-tabs="always">
            <md-tab label="{{'filesystem:DETAILS' | i18next}}">
                <md-tab-body>
                    <div id="item-owner-content" layout="row">
                        <eaw-profile-picture-old size="'50'" user="$ctrl.item.user"></eaw-profile-picture-old>
                        <div layout="column">
                            <span id="owner-label" ng-i18next="filesystem:OWNER"></span>
                            <span id="owner-name" ng-bind="$ctrl.item.owner"></span>
                        </div>
                    </div>

                    <table id="info-table" class="table">
                        <tr>
                            <td ng-i18next="filesystem:TYPE"></td>
                            <td ng-if="$ctrl.item.type == 'file'" ng-bind="$ctrl.item.mime.type"></td>
                            <td ng-if="$ctrl.item.type == 'directory'" ng-i18next="filesystem:DIRECTORY"></td>
                        </tr>
                        <tr ng-if="$ctrl.item.type == 'file'">
                            <td ng-i18next="filesystem:FILE_SIZE"></td>
                            <td>{{$ctrl.item.size.defaultFormat}} ({{$ctrl.item.size.byteFormat}})</td>
                        </tr>
                        <tr>
                            <td ng-i18next="filesystem:LOCATION"></td>
                            <td>
                                <div>
                                    <md-icon ng-bind="'folder'" md-colors="::{color: $ctrl.item.directory.color}"></md-icon>
                                    <span ng-bind="$ctrl.item.directory.name"></span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td ng-i18next="filesystem:OWNER"></td>
                            <td ng-bind="$ctrl.item.owner"></td>
                        </tr>
                        <tr>
                            <td ng-i18next="filesystem:CREATED"></td>
                            <td ng-bind="$ctrl.item.created_at | moment:'lll'"></td>
                        </tr>
                    </table>
                </md-tab-body>
            </md-tab>

            <md-tab label="{{'filesystem:ACTIVITY' | i18next}}">
                <md-tab-body>
                    <md-list class="md-dense" flex>
                        <md-list-item class="md-2-line md-long-text" ng-repeat="log in ::$ctrl.item.logs track by log.id">
                            <eaw-profile-picture-old class="md-avatar" user="log.user"></eaw-profile-picture-old>
                            <div class="md-list-item-text" layout="column">
                                <!-- I download the file -->
                                <h3 ng-if="$ctrl.isMe(log.user.id) && $ctrl.item.type == 'file' && log.action == 'download'"
                                    ng-i18next="filesystem:FILE_DOWNLOADED_ME">
                                </h3>
                                <!-- Someone else downloaded the file -->
                                <h3 ng-if="!$ctrl.isMe(log.user.id) && $ctrl.item.type == 'file' && log.action == 'download'"
                                    ng-i18next="[i18next]({name:log.user.name})filesystem:FILE_DOWNLOADED">
                                </h3>

                                <p ng-bind="::log.created_at | moment:'lll'"></p>
                            </div>
                            <md-divider></md-divider>
                        </md-list-item>
                        <!-- last item is when/who uploaded it -->
                        <md-list-item class="md-2-line md-long-text">
                            <eaw-profile-picture-old class="md-avatar" user="$ctrl.item.user"></eaw-profile-picture-old>
                            <div class="md-list-item-text" layout="column">
                                <!-- I uploaded the file -->
                                <h3 ng-if="$ctrl.isMe($ctrl.item.user_id) && $ctrl.item.type == 'file'" ng-i18next="filesystem:FILE_UPLOADED_ME"></h3>
                                <!-- Someone else uploaded the file -->
                                <h3 ng-if="!$ctrl.isMe($ctrl.item.user_id) && $ctrl.item.type == 'file'" ng-i18next="[i18next]({name:$ctrl.item.user.name})filesystem:FILE_UPLOADED"></h3>

                                <!-- I created the directory -->
                                <h3 ng-if="$ctrl.isMe($ctrl.item.user_id) && $ctrl.item.type == 'directory'" ng-i18next="filesystem:DIRECTORY_CREATED_ME"></h3>
                                <!-- Someone else created the directory -->
                                <h3 ng-if="!$ctrl.isMe($ctrl.item.user_id) && $ctrl.item.type == 'directory'" ng-i18next="[i18next]({name:$ctrl.item.user.name})filesystem:DIRECTORY_CREATED"></h3>

                                <p ng-bind="::$ctrl.item.created_at | moment:'lll'"></p>
                            </div>
                        </md-list-item>
                    </md-list>
                </md-tab-body>
            </md-tab>
        </md-tabs>
    </md-content>
</md-sidenav>
`,
    bindings: {
        item: '<?',
    },
    controller: [ '$mdSidenav', 'FilesystemFactory', function($mdSidenav, FilesystemFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.id = uniqueId('sidenav-item-info-');
            ctrl.customer = CurrentOld.customer;
            ctrl.user = CurrentOld.user;

            ctrl.saveSidenav();
        };

        ctrl.$onChanges = (changes) => {
            ctrl.item = changes.item?.currentValue;

            if (ctrl.item) {
                ctrl.getDetails();
            }
        };

        ctrl.isMe = (userId) => userId === ctrl.user?.id;

        ctrl.saveSidenav = () => {
            $mdSidenav(ctrl.id, true).then((sidenav) => {
                ctrl.sidenav = sidenav;

                ctrl.sidenav.onClose(() => {
                    ctrl.item = undefined;
                });
            });
        };

        ctrl.getDetails = () => {
            ctrl.infoLoading = true;
            ctrl.sidenav.toggle();

            const factoryFunction = ctrl.item.type === 'file' ? 'getFile' : 'getDirectory';
            const withs = ctrl.item.type === 'file' ? [ 'logs.user', 'directory', 'user', 'attachments' ] : [ 'logs.user', 'directory', 'user', 'files' ];
            FilesystemFactory[factoryFunction].query(ctrl.customer.id, ctrl.item.id, withs).then((data) => {
                ctrl.item.logs = data.logs.reverse();
                ctrl.item.directory = data.directory;

                ctrl.infoLoading = false;
            });
        };
    } ],
});
