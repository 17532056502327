<eaw-dialog-header>
    <span title>{{'NEW_REQUEST' | translate:'vacation' | async}}</span>
    <span subtitle *ngIf="!loadingDays && canGetStats">{{'CURR_VAC_DAYS' | translate:'vacation':{current: vacationDays, all: receivedVacationDays, year: year} | async}}</span>
    <span subtitle *ngIf="loadingDays">{{'LOADING' | translate | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-8" autocomplete="off">
        <eaw-date-time-range-input class="tw-w-full tw-grid tw-grid-cols-1 tw-gap-8">
            <mat-form-field eawDateTimeFrom>
                <mat-label>{{'FROM' | translate | async}}</mat-label>
                <eaw-date-time-input formControlName="from" [required]="true"></eaw-date-time-input>
            </mat-form-field>

            <mat-form-field eawDateTimeTo>
                <mat-label>{{'TO' | translate | async}}</mat-label>
                <eaw-date-time-input formControlName="to" [required]="true" [defaultTime]="defaultToTime"></eaw-date-time-input>
            </mat-form-field>
        </eaw-date-time-range-input>

        <mat-form-field>
            <mat-label>{{'COMMENT' | translate | async}}</mat-label>
            <textarea formControlName="comment" matInput></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="false" (click)="submit()" [disabled]="form.disabled || form.invalid">{{'SEND_REQUEST' | translate | async}}</eaw-action-button>
</mat-dialog-actions>
