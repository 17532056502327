import { inject, Injectable } from '@angular/core';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { classifyArrayPaginatedResponse } from '../utils/rxjs/classify';
import { PaginationOptions } from '../interfaces/pagination-options';
import { Product, ProductResponse } from '../models/product';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Products } from '../enums/products';
import { catchError, map, Observable, of, shareReplay } from 'rxjs';
import { mockArrayPaginatedResponse } from '../../../mocks/paginated-response.mock';
import { IGNORE_ERROR } from './http-contexts';

interface HasProductsResponse {
    // Returns true if all sent in products are available for the customer
    hasProducts: boolean;
    // Returns a record of the products with a true/false value if they are available for the customer
    products: Partial<Record<Products, boolean>>;
    // List of missing products
    missingProducts: Products[];
}

@Injectable({
    providedIn: 'root',
})
export class CustomerProductService {
    private http = inject(HttpClient);

    private readonly customerProductsStore: Map<number, Observable<ArrayPaginatedResponse<Product>>> = new Map();

    private getAllProducts(customerId: number) {
        let productsObservable = this.customerProductsStore.get(customerId);
        if (!productsObservable) {
            productsObservable = this.getAll(customerId, { per_page: 100, order_by: 'updated_at', direction: 'desc' }).pipe(
                catchError(() => of(mockArrayPaginatedResponse<Product>())),
                shareReplay(1),
            );

            this.customerProductsStore.set(customerId, productsObservable);
        }

        return productsObservable.pipe(
            map((products) => {
                return products?.data.reduce((acc, product) => {
                    acc.add(product.name);
                    return acc;
                }, new Set<Products>());
            }),
        );
    }

    hasProducts(customerId: number, products: Products[]) {
        return this.hasProductsDetailed(customerId, products).pipe(
            map((response) => response.hasProducts),
        );
    }

    hasProductsDetailed(customerId: number, products: Products[]): Observable<HasProductsResponse> {
        return this.getAllProducts(customerId).pipe(
            map((productsResponse) => {
                const missingProducts = products.filter((product) => !productsResponse?.has(product));

                return {
                    hasProducts: !missingProducts.length,
                    missingProducts,
                    products: Array.from(products).reduce((acc, product) => {
                        acc[product] = productsResponse?.has(product);
                        return acc;
                    }, {} as Partial<Record<Products, boolean>>),
                };
            }),
        );
    }

    getAll(customerId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<ProductResponse>>(`customers/${customerId}/products`, {
            params: { ...options },
            context: new HttpContext().set(IGNORE_ERROR, [ 404 ]),
        }).pipe(classifyArrayPaginatedResponse(Product));
    }
}
