import { Customer, CustomerResponse } from './customer';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';

export interface UserCustomerResponse extends CustomerResponse {
    pivot: {
        customer_id: number;
        user_id: number;
        from: string;
        to: string|null;
    }
}

export class UserCustomer extends Customer {
    pivot: {
        from: DateTime;
        to: DateTime | null;
        userId: number;
    };

    constructor(data: UserCustomerResponse) {
        super(data);

        this.pivot = {
            userId: data.pivot.user_id,
            from: stringToDateTime(data.pivot.from),
            to: data.pivot.to ? stringToDateTime(data.pivot.to) : null,
        };
    }
}
