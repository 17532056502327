import { module } from 'angular';
import { NotificationRedirectComponent } from '../pages/notification-redirect/notification-redirect.component';
import { NotificationListComponent } from '../pages/notification-list/notification-list.component';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { StateProvider } from '@uirouter/angularjs';

module('eaw.notifications', [
    'eaw.misc',
    'eaw.customers',
    'ew-endless-list',
]).config([ '$stateProvider', ($stateProvider: StateProvider) => {
    $stateProvider.state(`eaw/outside/notification-redirect`, {
        parent: 'eaw/outside',
        url: '/notification?{type}&{data}',
        params: {
            data: {
                type: 'json',
                nullable: false,
            },
            type: {
                type: 'string',
                nullable: false,
            },
        },
        views: {
            'content@': {
                component: NotificationRedirectComponent,
            },
        },
    });
    $stateProvider.state('eaw/app/notifications', {
        parent: 'eaw/app',
        views: {
            'content@': {
                component: NotificationListComponent,
            },
        },
        url: '/notifications',
        data: {
            breadcrumb: { key: 'NOTIFICATION_plural' },
        },
        resolve: [
            currentResolver('userId', 'user', 'id'),
        ],
    });
} ]);
