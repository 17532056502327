// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { pick } from 'lodash-es';
import { sort } from '../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';

const template1 = `<md-dialog id="new-external-employee-dialog">
    <eaw-dialog-header-old heading="'company:NEW_EXTERNAL_EMPLOYEE' | i18next"></eaw-dialog-header-old>

    <form name="externalForm" id="externalForm" class="dialog-container" ng-cloak autocomplete="off">
        <div>
            <div class="ext-head" id="select-emp">
                <eaw-profile-picture-old user="newCtrl.employee.user" size="65" square="true"></eaw-profile-picture-old>

                <linkable-employee-autocomplete name="autocomplete"
                                                required
                                                flex="100"
                                                customer="newCtrl.customer"
                                                ng-change="newCtrl.onEmployeeChange()"
                                                ng-model="newCtrl.employee">
                </linkable-employee-autocomplete>
            </div>

            <div class="ext-head" id="emp-info" ng-if="newCtrl.employee">
                <table class="table table-condensed">
                    <tr>
                        <td ng-i18next="company:EMPLOYEE_NUMBER"></td>
                        <td ng-bind="newCtrl.employee.number"></td>
                    </tr>
                    <tr>
                        <td ng-i18next="company:LOCATION"></td>
                        <td ng-bind="newCtrl.employee.customer.name"></td>
                    </tr>
                    <tr>
                        <td ng-i18next="company:EMPLOYMENT_DATE"></td>
                        <td ng-bind="newCtrl.employee.from | moment:'LL'"></td>
                    </tr>
                    <tr>
                        <td ng-i18next="EMAIL"></td>
                        <td ng-bind="newCtrl.employee.email"></td>
                    </tr>
                </table>
            </div>
        </div>

        <div ng-if="!newCtrl.external">
            <div class="md-dialog-content" layout="column" layout-align="center center">
                <md-icon ng-bind="'arrow_upward'"></md-icon>
                <span style="font-weight: 300;" ng-i18next="company:HIRE_EXT_EMP_TEXT"></span>
            </div>
        </div>

        <div ng-if="newCtrl.external">
            <div class="md-dialog-content" layout="column">
                <eaw-date-interval layout="row" ng-model="newCtrl.interval" from-required="true" ng-change="newCtrl.onEmployeeChange()"></eaw-date-interval>

                <md-input-container>
                    <label ng-i18next="company:EXTERNAL_EMP_NUMBER"></label>
                    <input ng-model="newCtrl.external.number" uniq-form-name placeholder="{{newCtrl.employee.number}}">
                    <div class="hint" ng-i18next="[i18next]({customer:newCtrl.customer.name})EXTERNAL_EMP_NUMBER_HELP"></div>
                </md-input-container>

                <md-input-container>
                    <label for="cost" ng-i18next="COST"></label>
                    <input type="number"
                           name="cost"
                           min="0"
                           step="0.01"
                           id="cost"
                           ng-model="newCtrl.cost"
                           ng-change="newCtrl.onEmployeeChange()">
                    <small class="hint" ng-i18next="company:EXTERNAL_EMPLOYEE_COST_HELP"></small>
                </md-input-container>

                <md-checkbox ng-model="newCtrl.external.giveAccess" ng-change="newCtrl.onEmployeeChange()">
                    <span ng-i18next="company:USER_ACCESS"></span>
                </md-checkbox>

                <md-card ng-if="newCtrl.external.giveAccess">
                    <md-card-header>
                        <md-card-header-text class="smaller">
                            <span class="md-title required" bo-i18next="USER_GROUP_plural"></span>
                            <span class="md-subhead" ng-i18next="company:MIN_ONE_USER_GROUP"></span>
                        </md-card-header-text>
                    </md-card-header>
                    <md-card-content class="tw-p-0">
                        <md-progress-circular md-diameter="30" class="md-progress-center"
                                              ng-if="!newCtrl.userGroups"></md-progress-circular>

                        <md-list class="md-dense">
                            <md-list-item ng-repeat="group in newCtrl.userGroups track by group.id" ng-click="newCtrl.groupSelect(group)">
                                <md-checkbox ng-model="group.selected" ng-disabled="group.isDisabled" ng-change="newCtrl.onEmployeeChange()"></md-checkbox>
                                <p>
                                    <span ng-bind="group.name"></span>
                                    <span ng-if="group.isDisabled"> -</span>
                                    <small ng-if="group.isDisabled" ng-i18next="permissions:GROUP_NO_PERMISSION_TO_ADD"></small>
                                </p>
                            </md-list-item>
                        </md-list>
                    </md-card-content>
                </md-card>

                <div layout="row" ng-if="newCtrl.external.giveAccess && newCtrl.employee && !newCtrl.employee.user_id">
                    <!-- add new user -->
                    <md-input-container>
                        <label for="email" ng-i18next="EMAIL"></label>
                        <input type="text" id="email" name="email" required ng-model="newCtrl.user.email"
                               ng-change="newCtrl.onEmployeeChange()">
                        <ng-messages for="newExternalEmployeeForm.email.$errors">
                            <ng-message when="required">
                                <span ng-i18next="errors:FORM_FIELD_REQUIRED"></span>
                            </ng-message>
                        </ng-messages>
                    </md-input-container>
                </div>
            </div>
        </div>
    </form>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button form="externalForm" close-dialog resolve="true" reason="newCtrl.external"
                   ng-disabled="externalForm.$invalid || newCtrl.external.giveAccess && !newCtrl.external.userGroups.length"
                   type="submit" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.company').service('newExternalEmployeeDialog', [ '$mdDialog', 'CustomerService', 'UserGroupService', 'EmployeeService', 'UserService', 'ExternalEmployeeFactory', function($mdDialog, CustomerService, UserGroupService, EmployeeService, UserService, ExternalEmployeeFactory) {
    // @ts-ignore
    const srv = this;
    /**
     * Returns a promise that resolves when an external employee has been added (and maybe a user and or a customer user has been made).
     * Rejects:
     * - "cancel" if the dialog was canceled
     * - "user" if something went wrong while adding a user
     * - "access" if something went wrong while adding access for the user.
     * - "employee" if something went wrong updating the employee with user id.
     *
     * @param {Customer} customer - customer where the employee will work
     */
    srv.open = async (customer) => {
        let external;
        try {
            external = await $mdDialog.show({
                template: template1,
                bindToController: true,
                locals: {
                    customer,
                },
                controllerAs: 'newCtrl',
                controller: function() {
                    const ctrl = this;
                    ctrl.$onInit = () => {
                        ctrl.minDate = moment();
                        ctrl.interval = {
                            from: ctrl.minDate.clone(),
                        };
                        CustomerService.getAllUserGroups({ per_page: 999 }).$promise.then((resp) => {
                            const groups = [];
                            resp.data.forEach((group) => {
                                if (CurrentOld.can(`customers.${ctrl.customer.id}.user_groups.${group.id}.members.add`, {
                                    user_group: group,
                                })) {
                                    groups.push(group);
                                } else {
                                    groups.push({ ...group, isDisabled: true });
                                }
                            });
                            ctrl.userGroups = sort(groups, CurrentOld.languageTag, [ (x) => x.name ]);
                        });
                    };
                    ctrl.onEmployeeChange = () => {
                        if (!ctrl.employee?.id) {
                            delete ctrl.external;
                            return;
                        }
                        let giveAccess = ctrl.external?.giveAccess;
                        if (giveAccess == null) {
                            giveAccess = !!ctrl.employee.user;
                        }
                        ctrl.external = {
                            ...pick(ctrl, [ 'employee', 'cost' ]),
                            ...ctrl.interval,
                            user: giveAccess ? {
                                ...ctrl.employee?.user,
                                ...pick(ctrl.employee, [ 'first_name', 'last_name', 'from', 'to', 'email' ]),
                            } : null,
                            id: ctrl.employee.id,
                            userGroups: ctrl.userGroups.filter((g) => g.selected).map((g) => g.id),
                            number: ctrl.external?.number,
                            giveAccess,
                        };
                    };
                    ctrl.groupSelect = (group) => {
                        if (!group.isDisabled) {
                            group.selected = !group.selected;
                            ctrl.onEmployeeChange();
                        }
                    };
                },
            });
        } catch (_) {
            return Promise.reject('cancel');
        }
        try {
            // Don't send in the number if it's the same as employee
            if (external.number == external.employee.number) {
                delete external.number;
            }
            await ExternalEmployeeFactory.create(customer.id, external.id, external).$promise;
        } catch (_) {
            console.error('e', e);
            return Promise.reject('external');
        }
        try {
            return userAccess(customer.id, external.employee, external.giveAccess ? external.user : null, external.userGroups);
        } catch (_) {
            return Promise.reject(e);
        }
    };
    async function userAccess(customerId, employee, user, userGroups = []) {
        if (!user) {
            return Promise.resolve();
        }
        const addUser = async (u) => {
            try {
                await CustomerService.addUser({ id: customerId }, u.id, u.from, u.to).$promise;
            } catch (_) {
                return Promise.reject('access');
            }
            await Promise.all(userGroups.map((groupId) => UserGroupService.addMember(customerId, groupId, u.id, u.from, u.to).$promise));
        };
        if (employee.user_id) { // existing user
            return await addUser(user);
        }
        let newUser;
        try {
            newUser = await UserService.create(user).$promise;
        } catch (_) {
            return Promise.reject('user');
        }
        await Promise.all([
            addUser(newUser),
            EmployeeService.updateEmployee(customerId, employee.id, { user_id: newUser.id }).catch(() => Promise.reject('employee')),
        ]);
    }
} ]);
