// @ts-nocheck
import { module } from 'angular';
module('eaw.chain-ops.employees').factory('ChainEmployeeFactory', [ 'EawResource', 'Pagination', function ChainEmployeeFactory(EawResource, Pagination) {
    const f = this;
    /**
     * @param customerGroupId
     * @param {Object} pagination
     */
    f.getAll = (customerGroupId, pagination) => {
        const props = Object.entries(pagination.properties || {}).reduce((carry, [ key, val ]) => {
            carry['properties[' + key + ']'] = val;
            return carry;
        }, {});
        const data = {
            ...props,
            group: customerGroupId,
            ...Pagination.getParams(pagination, [ 'birth_date', 'include_inactive' ]),
            'customers[]': pagination.customers,
            _businessDates: [ 'from', 'to' ],
        };
        return EawResource.create('/customer_groups/:group/employees').get(data).$promise;
    };
    f.transfer = (fromCustomer, targetCustomer, employee, date) => EawResource.create('/customers/:customer/swiss_transfers').save({
        customer: fromCustomer,
    }, {
        target_customer_id: targetCustomer,
        effective_date: date,
        employee_id: employee,
    }).$promise;
    f.getAllEmployeeTransfers = (customer, employee, args) => EawResource.create('/customers/:customer/employees/:employee/swiss_transfers').get({
        customer,
        employee,
        executed: args.executed,
        from: args.from,
        to: args.to,
        ...Pagination.getParams(args),
    }).$promise;
    f.getAllCustomerTransfers = (customer, args) => EawResource.create('/customers/:customer/swiss_transfers').get({
        customer,
        transfer_direction: args.transferDirection,
        executed: args.executed,
        from: args.from,
        to: args.to,
        ...Pagination.getParams(args),
    }).$promise;
    f.deleteTransfer = (customer, transfer) => EawResource.create('/customers/:customer/swiss_transfers/:transfer').delete({
        customer,
        transfer,
    }).$promise;
    return f;
} ]);
