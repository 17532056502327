import { Component, Inject, Input, OnInit } from '@angular/core';
import { CurrentService } from '../../../shared/services/current.service';
import { EmployeeService } from '../../../shared/http/employee.service';
import { CustomFieldDialogService } from '../../../custom-fields/services/custom-field-dialog.service';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { CustomerCustomFieldService } from '../../../shared/http/customer-custom-field.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { TranslateService } from '../../../shared/services/translate.service';
import { Namespace } from '../../../shared/enums/namespace';
import { forkJoin, map, of, switchMap } from 'rxjs';
import { ModelCustomField } from '../../../custom-fields/models/model-custom-field';
import { DateTime } from 'luxon';
import { CustomerService } from '../../../shared/http/customer.service';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { CustomFieldValueDisplayComponent } from '../../../custom-fields/components/custom-field-value-display/custom-field-value-display.component';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

type CustomFieldRow = {
    field: ModelCustomField,
    from: DateTime | null;
    to: DateTime | null;
    creatable: boolean;
    editable: boolean;
    deletable: boolean;
    translatedName: string;
    priority?: number;
    disabled?: boolean
};

@Component({
    selector: 'eaw-employee-custom-fields-tab',
    templateUrl: './employee-custom-fields-tab.component.html',
    styleUrl: './employee-custom-fields-tab.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        FormsModule,
        NgIf,
        MatProgressSpinnerModule,
        MatIconModule,
        MatIconSizeDirective,
        NgFor,
        MaterialColorDirective,
        CustomFieldValueDisplayComponent,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class EmployeeCustomFieldsTabComponent implements OnInit {
    @Input({ required: true }) employeeId!: number;
    @Input({ required: true }) customerId!: number;

    loading = true;
    rows: CustomFieldRow[] = [];
    canUpdate = false;

    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(CustomerService) private customerService: CustomerService,
        @Inject(CustomFieldDialogService) private customFieldDialogService: CustomFieldDialogService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(CustomerCustomFieldService) private customerCustomFieldService: CustomerCustomFieldService,
        @Inject(TranslateService) private translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        // Set here because we are waiting for the inputs
        this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.update`).subscribe((canUpdate) => {
            this.canUpdate = canUpdate;
        });

        this.getFields();
    }

    getStackId() {
        if (this.current.getCustomer().id === this.customerId) {
            return of(this.current.getCustomer().stackId);
        }

        return this.customerService.get(this.customerId).pipe(map((customer) => customer.stackId));
    }

    getFields() {
        forkJoin([
            this.employeeService.get(this.customerId, this.employeeId).pipe(
                switchMap((employee) => {
                    return this.customerCustomFieldService.getForModel(this.customerId, ApiModel.Employee, employee);
                }),
            ),
            this.getStackId().pipe(
                switchMap((stackId) => {
                    return this.permissionCheckService.getReadableFields(stackId, `customers.${this.customerId}.employees`, { type: ApiModel.Employee, id: this.employeeId }).pipe(
                        map((response) => response.attributes),
                    );
                }),
            ),
        ]).subscribe(async ([ customFields, readableFields ]) => {
            this.rows = [];
            for await (const field of customFields) {
                if (!readableFields.includes(field.key)) {
                    continue;
                }

                this.rows.push({
                    field,
                    from: field.from,
                    to: field.to,
                    creatable: field.creatable,
                    editable: field.editable,
                    deletable: field.deletable,
                    priority: field.priority,
                    translatedName: (await this.translateService.t(field.translationKey, Namespace.CustomFields)) || '',
                });
            }

            this.rows = sort(this.rows, this.current.languageTag, [ (row) => row.priority, (row) => row.translatedName ]);
            this.loading = false;
        });
    }

    manage(mode: 'add' | 'edit', row: CustomFieldRow) {
        this.customFieldDialogService.manage(mode, this.customerId, this.employeeId, row.field).afterClosed().subscribe((res) => {
            if (res?.hasChange) {
                row.disabled = true;
                this.getFields();
            }
        });
    }

    history(row: CustomFieldRow) {
        this.customFieldDialogService.history(this.customerId, this.employeeId, row.field).afterClosed().subscribe((hasChanges) => {
            if (!hasChanges) {
                return;
            }
            row.disabled = true;
            this.getFields();
        });
    }

    delete(row: CustomFieldRow) {
        this.customFieldDialogService.delete(this.customerId, this.employeeId, row.field).subscribe(() => {
            row.disabled = true;
            this.getFields();
        });
    }
}
