import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { catchError, EMPTY, map, Observable, of } from 'rxjs';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AttachmentListComponent } from '../../components/attachment-list/attachment-list.component';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

export interface AddAttachmentsDialogComponentData extends DialogData {
    saveFn: (files: File[]) => Observable<unknown>
}

@Component({
    selector: 'eaw-add-attachments-dialog',
    templateUrl: './add-attachments-dialog.component.html',
    styleUrl: './add-attachments-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        AttachmentListComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AddAttachmentsDialogComponent extends DialogComponent {
    files: File[] = [];
    disabled = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AddAttachmentsDialogComponentData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AddAttachmentsDialogComponent, boolean>,
    ) {
        data.size = DialogSize.Large;
        super(dialogRef, data);
    }

    filesChange(event: File[]): void {
        this.files = event;
    }

    add() {
        this.disabled = true;

        const observable = this.files.length? this.data.saveFn(this.files).pipe(map(() => true)) : of(false);
        observable.pipe(
            catchError(() => {
                this.disabled = false;
                return EMPTY;
            }),
        ).subscribe((res) => {
            this.dialogRef.close(res);
        });
    }
}
