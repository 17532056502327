// @ts-nocheck
import { module } from 'angular';
import { Namespace } from '../shared/enums/namespace';
import { Products } from '../shared/enums/products';

module('eaw.fixedLabor', [ 'eaw.resource', 'eaw.login', 'eaw.time' ]);

module('eaw.fixedLabor').config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state('eaw/app/fixed_labor_cost', {
        parent: 'eaw/app',
        url: '/fixed_labor_costs',
        views: {
            'content@': 'fixedLaborList',
        },
        data: {
            i18nextNs: [ Namespace.FixedLaborCost ],
            breadcrumb: { key: 'FIXED_LABOR_COST', ns: 'navigation' },
            products: [ Products.FixedLaborCost ],
            permissions: [ `customers.{customer}.fixed_labor_costs.*.get` ],
        },
    });
} ]);
