// @ts-nocheck
import { module } from 'angular';
import moment from 'moment/moment';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';
// @ts-ignore
module('eaw.scheduling').service('NewShiftLine', [ 'scheduleDays', 'Shift', 'chooseBusinessDate', 'intervalService', function NewShiftLineService(scheduleDays, Shift, chooseBusinessDate, intervalService) {
    // @ts-ignore
    const service = this;
    // Shift line
    service.shiftLine = document.createElement('div');
    service.shiftLine.classList.add('shift-line');
    // Schedule overlay
    service.overlay = document.createElement('div');
    service.overlay.id = 'schedule-overlay';
    document.body.append(service.overlay);
    service.render = (schedule) => {
        service.schedule = schedule;
        const container = document.getElementById('schedule-table-new');
        if (!container) {
            return;
        }
        let html = `<tr>`;
        scheduleDays.getAllTimesRender().forEach((time) => {
            html += `<td class="time-cell${time.halfSize ? ' half-cell' : ''}" colspan="${time.span}" title="${time.label}" data-offset="${time.offset}"></td>`;
        });
        html += `</tr>`;
        container.innerHTML = html;
        const canCreateShift = CurrentOld.can(`customers.${service.schedule.customer_id}.schedules.${service.schedule.id}.shifts.create`);
        if (!canCreateShift || schedule.original) {
            return;
        }
        Array.from(document.getElementsByClassName('time-cell')).forEach((cell, i, e) => {
            cell.addEventListener('mousedown', (event) => {
                if (event.button !== 0) {
                    return;
                }
                const target = event.target;
                intervalService.addTimeTellers();
                service.fromOffset = parseInt(target.dataset.offset || '0');
                service.screenXFrom = event.screenX - event.offsetX;
                service.activateOverlay();
                service.overlay.addEventListener('mousemove', service.mouseMoveEvent, false);
                service.overlay.addEventListener('mousedown', service.mouseDownEvent, false);
                service.overlay.addEventListener('mouseup', service.mouseUpEvent, false);
                service.showLine(target);
            }, false);
        });
    };
    service.mouseDownEvent = (event) => {
        if (event.button === 2) {
            service.reset();
            service.hideLine();
        }
    };
    service.hideLine = () => {
        service.shiftLine.style.width = '1px';
        service.shiftLine.remove();
    };
    service.showLine = (target) => {
        target.append(service.shiftLine);
    };
    service.activateOverlay = () => {
        service.overlay.style.display = 'block';
    };
    service.deactivateOverlay = () => {
        service.overlay.style.display = 'none';
    };
    service.mouseMoveEvent = (event) => {
        const width = event.screenX - service.screenXFrom;
        service.shiftLine.style.width = `${width}px`;
        intervalService.calculateTimeTellersTime(service.shiftLine, service.fromOffset);
    };
    service.reset = () => {
        service.overlay.removeEventListener('mousemove', service.mouseMoveEvent);
        service.overlay.removeEventListener('mouseup', service.mouseUpEvent);
        service.overlay.removeEventListener('mousedown', service.mouseDownEvent);
        service.deactivateOverlay();
        intervalService.removeTimeTellers();
    };
    service.mouseUpEvent = (event) => {
        // Clean up
        service.reset();
        // Vars
        const shiftLineRect = service.shiftLine.getBoundingClientRect();
        const toOffset = intervalService.calculateClosestMarkerOffsetFromPixels(shiftLineRect.width, false) + service.fromOffset;
        // Hide the line
        service.hideLine();
        // Don't add anything if dragging backwards
        if (event.screenX <= service.screenXFrom || toOffset > service.schedule.length) {
            return;
        }
        // Length has to be a number greater than zero
        const length = toOffset - service.fromOffset;
        const bdStartTime = moment.duration(service.schedule.customer.getProperty('shift:business_date_start_time', '00:00'), 'HH:mm');
        if (Number.isFinite(length) && length) {
            const from = service.schedule.getFrom().add(service.fromOffset, 's');
            const shift = {
                from,
                business_date: from.clone().utc(true).subtract(bdStartTime).utcOffset(from.utcOffset(), true),
                to: from.clone().add(length, 's'),
            };
            chooseBusinessDate(service.schedule, shift).then(() => {
                service.schedule.addShift(new Shift(shift, service.schedule));
            });
        }
    };
    return service;
} ]);
