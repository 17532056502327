import { Inject, Injectable } from '@angular/core';
import { catchError, filter, map, Observable, of, ReplaySubject, Subscription, switchMap, take, tap, timer } from 'rxjs';
import { CurrentService } from './current.service';
import { Duration } from 'luxon';
import { IdleService } from '../../login/services/idle.service';
import { LoginService } from './login.service';
import { HttpClient } from '@angular/common/http';

export type Badges = Record<string, number>;

@Injectable({
    providedIn: 'root',
})
export class BadgerService {
    private subjects: Map<number, ReplaySubject<Map<string, number>>> = new Map();
    private currentBadges: Map<number, Map<string, number>> = new Map();
    private timers: Map<number, Subscription> = new Map();

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(LoginService) private loginService: LoginService,
        @Inject(IdleService) private idleService: IdleService,
    ) {
    }

    getBadges(customerId: number): Observable<Badges> {
        return this.http.get<Badges>(`/customers/${this.current.getCustomer().id}/badges`).pipe(
            tap((resp) => {
                const newBadges = new Map(Object.entries(resp));

                if (this.isSameBadges(customerId, newBadges)) {
                    return;
                }

                this.currentBadges.set(customerId, new Map(newBadges.entries()));
                this.subjects.get(customerId)?.next(this.currentBadges.get(customerId) || new Map());
            }),
        );
    }

    private createTimer(customerId: number) {
        if (this.timers.get(customerId)) {
            return;
        }

        for (const [ key, value ] of this.timers.entries()) {
            value.unsubscribe();
            this.timers.delete(key);
        }

        const t = timer(0, Duration.fromObject({ minutes: 5 }).as('milliseconds')).pipe(
            filter(() => this.shouldGet()),
            switchMap(() => this.getBadges(customerId)),
            catchError(() => of({})),
        ).subscribe();

        this.timers.set(customerId, t);
    }

    private shouldGet() {
        return ([
            this.loginService.isLoggedIn(), // Must be logged in
            !!this.current.getMe()?.acceptedTos, // Must have accepted TOS. It is possible things from current is not set yet
            document.visibilityState === 'visible', // Document / page must be in view
            !this.idleService.isIdle(), // Must have been recent activity on the app
            !!this.current.getCustomer(), // Customer must be set. It is possible things from current is not set yet
        ] as boolean[]).every((r) => r);
    }

    private isSameBadges(customerId: number, newBadges: Map<string, number>) {
        const sameSize = newBadges.size === this.currentBadges.get(customerId)?.size;
        const sameItems = Array.from(newBadges.entries()).every(([ key, value ]) => this.currentBadges.get(customerId)?.get(key) === value);

        return sameSize && sameItems;
    }

    refresh(customerId: number) {
        this.getBadges(customerId).pipe(take(1)).subscribe();
    }

    getBadge(customerId: number, key: string) {
        return this.currentBadges.get(customerId)?.get(key) || 0;
    }

    updateBadge(customerId: number, key: string, value: number) {
        if (!this.currentBadges.get(customerId)?.has(key)) {
            return;
        }

        this.currentBadges.get(customerId)?.set(key, Math.max(0, value));
        this.subjects.get(customerId)?.next(this.currentBadges.get(customerId) || new Map());
    }

    onBadges(customerId: number) {
        if (!this.subjects.get(customerId)) {
            this.subjects.set(customerId, new ReplaySubject(1));
        }

        this.createTimer(customerId);

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.subjects.get(customerId)!.asObservable();
    }

    onBadge(customerId: number, key: string): Observable<number | undefined> {
        return this.onBadges(customerId).pipe(
            map((badges) => badges.get(key)),
        );
    }
}
