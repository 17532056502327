import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { Observable } from 'rxjs';
import { Language, LanguageResponse } from '../models/language';
import { HttpClient } from '@angular/common/http';

interface CreateLanguagePayLoad {
    code: string;
    name: string;
    ietfBcp47Tag: string;
}

@Injectable({
    providedIn: 'root',
})
export class LanguageService {

    constructor(
      @Inject(HttpClient) private http: HttpClient,
    ) { }

    getAll(options: PaginationOptions = {}): Observable<ArrayPaginatedResponse<Language>> {
        return this.http.get<ArrayPaginatedResponse<LanguageResponse>>('/languages', { params: { ...options } }).pipe(classifyArrayPaginatedResponse(Language));
    }

    get(code: string): Observable<Language> {
        return this.http.get<LanguageResponse>(`/languages/${code}`).pipe(classifyItem(Language));
    }

    delete(code: string): Observable<string> {
        return this.http.delete<string>(`/languages/${code}`);
    }

    create(payload: CreateLanguagePayLoad) {
        return this.http.post<LanguageResponse>(`/languages`, { ...payload, ietf_bcp47_tag: payload.ietfBcp47Tag }).pipe(classifyItem(Language));
    }

    update(code: string, name: string) {
        return this.http.put<LanguageResponse>(`/languages/${code}`, { name }).pipe(classifyItem(Language));
    }
}
