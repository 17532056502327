import { processOptions, validateOptions } from './_chunks/defaultOptionsValidator-733a091f.js';
import parseHeaders from 'parse-headers';
const middlewareReducer = middleware => function applyMiddleware(hook, defaultValue) {
  const bailEarly = hook === "onError";
  let value = defaultValue;
  for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    args[_key - 2] = arguments[_key];
  }
  for (let i = 0; i < middleware[hook].length; i++) {
    const handler = middleware[hook][i];
    value = handler(value, ...args);
    if (bailEarly && !value) {
      break;
    }
  }
  return value;
};
function createPubSub() {
  const subscribers = /* @__PURE__ */Object.create(null);
  let nextId = 0;
  function subscribe(subscriber) {
    const id = nextId++;
    subscribers[id] = subscriber;
    return function unsubscribe() {
      delete subscribers[id];
    };
  }
  function publish(event) {
    for (const id in subscribers) {
      subscribers[id](event);
    }
  }
  return {
    publish,
    subscribe
  };
}
const channelNames = ["request", "response", "progress", "error", "abort"];
const middlehooks = ["processOptions", "validateOptions", "interceptRequest", "finalizeOptions", "onRequest", "onResponse", "onError", "onReturn", "onHeaders"];
function createRequester(initMiddleware, httpRequest) {
  const loadedMiddleware = [];
  const middleware = middlehooks.reduce((ware, name) => {
    ware[name] = ware[name] || [];
    return ware;
  }, {
    processOptions: [processOptions],
    validateOptions: [validateOptions]
  });
  function request(opts) {
    const onResponse = (reqErr, res, ctx) => {
      let error = reqErr;
      let response = res;
      if (!error) {
        try {
          response = applyMiddleware("onResponse", res, ctx);
        } catch (err) {
          response = null;
          error = err;
        }
      }
      error = error && applyMiddleware("onError", error, ctx);
      if (error) {
        channels.error.publish(error);
      } else if (response) {
        channels.response.publish(response);
      }
    };
    const channels = channelNames.reduce((target, name) => {
      target[name] = createPubSub();
      return target;
    }, {});
    const applyMiddleware = middlewareReducer(middleware);
    const options = applyMiddleware("processOptions", opts);
    applyMiddleware("validateOptions", options);
    const context = {
      options,
      channels,
      applyMiddleware
    };
    let ongoingRequest;
    const unsubscribe = channels.request.subscribe(ctx => {
      ongoingRequest = httpRequest(ctx, (err, res) => onResponse(err, res, ctx));
    });
    channels.abort.subscribe(() => {
      unsubscribe();
      if (ongoingRequest) {
        ongoingRequest.abort();
      }
    });
    const returnValue = applyMiddleware("onReturn", channels, context);
    if (returnValue === channels) {
      channels.request.publish(context);
    }
    return returnValue;
  }
  request.use = function use(newMiddleware) {
    if (!newMiddleware) {
      throw new Error("Tried to add middleware that resolved to falsey value");
    }
    if (typeof newMiddleware === "function") {
      throw new Error("Tried to add middleware that was a function. It probably expects you to pass options to it.");
    }
    if (newMiddleware.onReturn && middleware.onReturn.length > 0) {
      throw new Error("Tried to add new middleware with `onReturn` handler, but another handler has already been registered for this event");
    }
    middlehooks.forEach(key => {
      if (newMiddleware[key]) {
        middleware[key].push(newMiddleware[key]);
      }
    });
    loadedMiddleware.push(newMiddleware);
    return request;
  };
  request.clone = () => createRequester(loadedMiddleware, httpRequest);
  initMiddleware.forEach(request.use);
  return request;
}
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj)) throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var _method, _url, _resHeaders, _headers, _controller, _init, _useAbortSignal;
class FetchXhr {
  constructor() {
    /**
     * Public interface, interop with real XMLHttpRequest
     */
    __publicField(this, "onabort");
    __publicField(this, "onerror");
    __publicField(this, "onreadystatechange");
    __publicField(this, "ontimeout");
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
     */
    __publicField(this, "readyState", 0);
    __publicField(this, "response");
    __publicField(this, "responseText");
    __publicField(this, "responseType", "");
    __publicField(this, "status");
    __publicField(this, "statusText");
    __publicField(this, "withCredentials");
    /**
     * Private implementation details
     */
    __privateAdd(this, _method, void 0);
    __privateAdd(this, _url, void 0);
    __privateAdd(this, _resHeaders, void 0);
    __privateAdd(this, _headers, {});
    __privateAdd(this, _controller, void 0);
    __privateAdd(this, _init, {});
    __privateAdd(this, _useAbortSignal, void 0);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- _async is only declared for typings compatibility
  open(method, url, _async) {
    __privateSet(this, _method, method);
    __privateSet(this, _url, url);
    __privateSet(this, _resHeaders, "");
    this.readyState = 1;
    this.onreadystatechange();
    __privateSet(this, _controller, void 0);
  }
  abort() {
    if (__privateGet(this, _controller)) {
      __privateGet(this, _controller).abort();
    }
  }
  getAllResponseHeaders() {
    return __privateGet(this, _resHeaders);
  }
  setRequestHeader(name, value) {
    __privateGet(this, _headers)[name] = value;
  }
  // Allow setting extra fetch init options, needed for runtimes such as Vercel Edge to set `cache` and other options in React Server Components
  setInit(init) {
    let useAbortSignal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    __privateSet(this, _init, init);
    __privateSet(this, _useAbortSignal, useAbortSignal);
  }
  send(body) {
    const textBody = this.responseType !== "arraybuffer";
    const options = {
      ...__privateGet(this, _init),
      method: __privateGet(this, _method),
      headers: __privateGet(this, _headers),
      body
    };
    if (typeof AbortController === "function" && __privateGet(this, _useAbortSignal)) {
      __privateSet(this, _controller, new AbortController());
      if (typeof EventTarget !== "undefined" && __privateGet(this, _controller).signal instanceof EventTarget) {
        options.signal = __privateGet(this, _controller).signal;
      }
    }
    if (typeof document !== "undefined") {
      options.credentials = this.withCredentials ? "include" : "omit";
    }
    fetch(__privateGet(this, _url), options).then(res => {
      res.headers.forEach((value, key) => {
        __privateSet(this, _resHeaders, __privateGet(this, _resHeaders) + "".concat(key, ": ").concat(value, "\r\n"));
      });
      this.status = res.status;
      this.statusText = res.statusText;
      this.readyState = 3;
      return textBody ? res.text() : res.arrayBuffer();
    }).then(resBody => {
      if (typeof resBody === "string") {
        this.responseText = resBody;
      } else {
        this.response = resBody;
      }
      this.readyState = 4;
      this.onreadystatechange();
    }).catch(err => {
      var _a;
      if (err.name === "AbortError") {
        this.onabort();
        return;
      }
      (_a = this.onerror) == null ? void 0 : _a.call(this, err);
    });
  }
}
_method = new WeakMap();
_url = new WeakMap();
_resHeaders = new WeakMap();
_headers = new WeakMap();
_controller = new WeakMap();
_init = new WeakMap();
_useAbortSignal = new WeakMap();
const adapter = typeof XMLHttpRequest === "function" ? "xhr" : "fetch";
const XmlHttpRequest = adapter === "xhr" ? XMLHttpRequest : FetchXhr;
const httpRequester = (context, callback) => {
  var _a;
  const opts = context.options;
  const options = context.applyMiddleware("finalizeOptions", opts);
  const timers = {};
  const injectedResponse = context.applyMiddleware("interceptRequest", void 0, {
    adapter,
    context
  });
  if (injectedResponse) {
    const cbTimer = setTimeout(callback, 0, null, injectedResponse);
    const cancel = () => clearTimeout(cbTimer);
    return {
      abort: cancel
    };
  }
  let xhr = new XmlHttpRequest();
  if (xhr instanceof FetchXhr && typeof options.fetch === "object") {
    xhr.setInit(options.fetch, (_a = options.useAbortSignal) != null ? _a : true);
  }
  const headers = options.headers;
  const delays = options.timeout;
  let aborted = false;
  let loaded = false;
  let timedOut = false;
  xhr.onerror = event => {
    onError(new Error("Request error while attempting to reach ".concat(options.url).concat(event.lengthComputable ? "(".concat(event.loaded, " of ").concat(event.total, " bytes transferred)") : "")));
  };
  xhr.ontimeout = event => {
    onError(new Error("Request timeout while attempting to reach ".concat(options.url).concat(event.lengthComputable ? "(".concat(event.loaded, " of ").concat(event.total, " bytes transferred)") : "")));
  };
  xhr.onabort = () => {
    stopTimers(true);
    aborted = true;
  };
  xhr.onreadystatechange = () => {
    resetTimers();
    if (aborted || xhr.readyState !== 4) {
      return;
    }
    if (xhr.status === 0) {
      return;
    }
    onLoad();
  };
  xhr.open(options.method, options.url, true
  // Always async
  );
  xhr.withCredentials = !!options.withCredentials;
  if (headers && xhr.setRequestHeader) {
    for (const key in headers) {
      if (headers.hasOwnProperty(key)) {
        xhr.setRequestHeader(key, headers[key]);
      }
    }
  }
  if (options.rawBody) {
    xhr.responseType = "arraybuffer";
  }
  context.applyMiddleware("onRequest", {
    options,
    adapter,
    request: xhr,
    context
  });
  xhr.send(options.body || null);
  if (delays) {
    timers.connect = setTimeout(() => timeoutRequest("ETIMEDOUT"), delays.connect);
  }
  return {
    abort
  };
  function abort() {
    aborted = true;
    if (xhr) {
      xhr.abort();
    }
  }
  function timeoutRequest(code) {
    timedOut = true;
    xhr.abort();
    const error = new Error(code === "ESOCKETTIMEDOUT" ? "Socket timed out on request to ".concat(options.url) : "Connection timed out on request to ".concat(options.url));
    error.code = code;
    context.channels.error.publish(error);
  }
  function resetTimers() {
    if (!delays) {
      return;
    }
    stopTimers();
    timers.socket = setTimeout(() => timeoutRequest("ESOCKETTIMEDOUT"), delays.socket);
  }
  function stopTimers(force) {
    if (force || aborted || xhr.readyState >= 2 && timers.connect) {
      clearTimeout(timers.connect);
    }
    if (timers.socket) {
      clearTimeout(timers.socket);
    }
  }
  function onError(error) {
    if (loaded) {
      return;
    }
    stopTimers(true);
    loaded = true;
    xhr = null;
    const err = error || new Error("Network error while attempting to reach ".concat(options.url));
    err.isNetworkError = true;
    err.request = options;
    callback(err);
  }
  function reduceResponse() {
    return {
      body: xhr.response || (xhr.responseType === "" || xhr.responseType === "text" ? xhr.responseText : ""),
      url: options.url,
      method: options.method,
      headers: parseHeaders(xhr.getAllResponseHeaders()),
      statusCode: xhr.status,
      statusMessage: xhr.statusText
    };
  }
  function onLoad() {
    if (aborted || loaded || timedOut) {
      return;
    }
    if (xhr.status === 0) {
      onError(new Error("Unknown XHR error"));
      return;
    }
    stopTimers();
    loaded = true;
    callback(null, reduceResponse());
  }
};
const getIt = function () {
  let initMiddleware = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let httpRequest = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : httpRequester;
  return createRequester(initMiddleware, httpRequest);
};
const environment = "browser";
export { adapter, environment, getIt };
