<eaw-dialog-header [showCloseButton]="!connecting">
    <span title>{{'CONNECT_EMPLOYEE_plural' | translate:'rotation' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-12">
    <mat-form-field eawDatePickerOptions>
        <mat-label>{{'CONNECTION_RANGE' | translate:'rotation' | async}}</mat-label>
        <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker" [disabled]="connecting">
            <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async">
            <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-progress-spinner style="align-self: center" mode="indeterminate" *ngIf="!employees.length"></mat-progress-spinner>

    <mat-selection-list *ngIf="employees.length && !connecting" (selectionChange)="employeesSelectionChange($event)">
        <mat-list-option *ngFor="let employee of employees" [value]="employee">
            <eaw-profile-picture matListItemAvatar  [user]="employee.user"></eaw-profile-picture>
            <h3 matListItemLine > {{employee.name}} </h3>
        </mat-list-option>
    </mat-selection-list>

    <mat-list *ngIf="connecting">
        <mat-list-item *ngFor="let employee of connectEmployees">
            <mat-progress-spinner matListItemAvatar  mode="indeterminate" *ngIf="employee.status === 'processing'" diameter="32" strokeWidth="3"></mat-progress-spinner>
            <mat-icon matListItemIcon *ngIf="employee.status !== 'processing'">{{employee.icon}}</mat-icon>

            <h3 matListItemLine > {{employee.name}} </h3>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-raised-button [mat-dialog-close]="undefined" [disabled]="connecting">{{'CLOSE' | translate | async}}</button>
    <button mat-button mat-raised-button color="primary" (click)="prepareForConnect()" [disabled]="!selectedEmployees.length || connecting || dateRange.invalid">{{('CONNECT_EMPLOYEE' | translate:'rotation':{count:selectedEmployees.length}) | async}}</button>
</mat-dialog-actions>
