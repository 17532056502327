import { DateTime } from 'luxon';
import { Shift } from '../../scheduling/models/shift';
import { Timepunch } from '../../payroll/models/timepunch';
import { PaidTime } from './paid-time';
import { Absence } from '../../absence/models/absence';
import { OffTime } from '../../vacations/models/off-time';
import { Comment } from '../../shared/models/comment';
import { Employee } from '../../shared/models/employee';
import { Customer } from '../../shared/models/customer';
import { PaidTimeSegmentType, PaidTimeSegmentTypes } from '../types/paid-time-segment-types';
import { LeaveShift } from '../../leave-shifts/models/leave-shift';

export interface PaidTimeSegmentData {
    customer: Customer;
    employee?: Employee | null;
    from: DateTime;
    to: DateTime;
    isPunchedIn: boolean;
    shift?: Shift | null;
    timepunch?: Timepunch | null;
    paidTime?: PaidTime | null;
    leaveShift?: LeaveShift | null;
    absence?: Absence | null;
    offTime?: OffTime | null;
    businessDate?: DateTime | null;
}

export class PaidTimeSegment {
    readonly customer: Customer;
    readonly employee: Employee | null;
    readonly from: DateTime;
    readonly to: DateTime;
    readonly shift?: Shift | null;
    readonly timepunch?: Timepunch | null;
    readonly paidTime?: PaidTime | null;
    readonly leaveShift?: LeaveShift | null;
    readonly absence?: Absence | null;
    readonly offTime?: OffTime | null;
    readonly isPunchedIn: boolean;
    readonly now: DateTime;
    readonly type: PaidTimeSegmentType;
    // In seconds
    readonly duration: number;
    readonly comments: Comment[] = [];
    readonly businessDate: DateTime | null;
    readonly differentBusinessDate: boolean;

    constructor(type: keyof typeof PaidTimeSegmentTypes, data: PaidTimeSegmentData) {
        this.type = PaidTimeSegmentTypes[type];
        this.customer = data.customer;
        this.employee = data.employee || null;
        this.from = data.from;
        this.to = data.to;
        this.shift = data.shift;
        this.timepunch = data.timepunch;
        this.paidTime = data.paidTime;
        this.leaveShift = data.leaveShift;
        this.absence = data.absence;
        this.offTime = data.offTime;
        this.businessDate = data.businessDate || null;
        this.differentBusinessDate = this.businessDate ? !this.businessDate.hasSame(this.from, 'day') : false;
        this.duration = this.to.diff(this.from, 'seconds').as('seconds');
        this.isPunchedIn = data.isPunchedIn || this.timepunch?.isPunchedIn || false;
        this.now = DateTime.now();
        this.comments = [
            ...this.shift?.comments || [],
            ...this.timepunch?.comments || [],
            ...this.paidTime?.comments || [],
            ...this.absence?.comments || [],
            ...this.offTime?.comments || [],
        ];
    }
}
