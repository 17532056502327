import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData } from '../../../shared/dialogs/dialog-component';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { Employee } from '../../../shared/models/employee';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PredefinedSettings } from '../../../shared/enums/predefined-settings';

type PredefinedProperty = {
    key: string,
    description: string,
    initialValue?: string,
};

export type PredefinedPropertyDialogData = DialogData;

export type PredefinedPropertyDialogResult = {key: string, value: string};

@Component({
    selector: 'eaw-predefined-property-dialog',
    standalone: true,
    imports: [ CommonModule, ActionButtonComponent, DialogHeaderComponent, FormsModule, MatButtonModule, MatDialogActions, MatDialogClose, MatDialogContent, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, ReactiveFormsModule, TranslatePipe, MatOptionModule, MatSelectModule, MatListModule, MatSlideToggleModule ],
    templateUrl: './predefined-property-dialog.component.html',
    styleUrl: './predefined-property-dialog.component.scss',
})
export class PredefinedPropertyDialogComponent extends DialogComponent implements OnInit {
    protected readonly predefinedSettings: typeof PredefinedSettings = PredefinedSettings;
    properties = [
        { key: PredefinedSettings.NewEmployeeRequiredFields, description: 'Fields that are required when creating a new employee' },
        { key: PredefinedSettings.MaxUserGroups, description: 'Max number of user groups a user can be a member of' },
        { key: PredefinedSettings.PayrollTimepunchBlocksEnabled, description: 'Enable or disable the timepunch blocking feature' },
        { key: PredefinedSettings.PayrollPersonalTpApp, description: 'Required to use the timepunch clock in the app', initialValue: '0' },
        { key: PredefinedSettings.EmployeeVisibleBalanceCodes, description: 'The balances that the employee will see when checking "My balances"' },
        { key: PredefinedSettings.EmployeeAbsencePayPeriodExempt, description: '' },
        { key: PredefinedSettings.FamilyMemberEnabled, description: 'Enable the family member functionality' },
    ] as const satisfies readonly PredefinedProperty[];

    value?: string;
    propertyControl = new FormControl<PredefinedProperty>(this.properties[0], { nonNullable: true });
    newEmployeeRequiredFields = Employee.possibleRequiredFields();

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: PredefinedPropertyDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<PredefinedPropertyDialogComponent, PredefinedPropertyDialogResult>,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.propertyControl.valueChanges.subscribe((value) => {
            this.value = value.initialValue || '';
        });
    }

    toggleChange(event: MatSlideToggleChange) {
        this.value = event.checked ? '1' : '0';
    }

    numberInputChange(ev: KeyboardEvent) {
        this.value = (ev.target as HTMLInputElement).value;
    }

    onePerLineTextareaChange(ev: KeyboardEvent) {
        const value = (ev.target as HTMLTextAreaElement).value.split('\n').map((s) => s.trim()).filter((s) => s.length);

        if (!value.length) {
            this.value = undefined;
            return;
        }

        try {
            this.value = JSON.stringify(value);
        } catch (_) {
            this.value = undefined;
        }
    }

    updateRequiredFieldsValue(event: MatSelectionListChange) {
        try {
            this.value = JSON.stringify(event.source.options.filter((o) => o.selected).map((o) => o.value));
        } catch (_) {
            this.value = '';
        }
    }

    add() {
        if (this.value) {
            this.dialogRef.close({ key: this.propertyControl.value.key, value: this.value });
        }
    }
}
