import { Component, Input } from '@angular/core';
import { EmployeeSwapSummary } from '../../models/employee-swap-summary';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'eaw-swap-summary',
    templateUrl: './swap-summary.component.html',
    styleUrl: './swap-summary.component.scss',
    standalone: true,
    imports: [
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
    ],
})
export class SwapSummaryComponent {
    @Input() summary!: EmployeeSwapSummary;
}
