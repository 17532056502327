// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';

const template1 = `<md-dialog>
  <eaw-dialog-header-old heading="'absences:APPROVE_ABSENCE' | i18next"></eaw-dialog-header-old>
  <md-dialog-content>
    <form class="md-dialog-content"
          name="approve"
          layout="column"
          id="approve"
          ng-cloak
          ng-submit="dialog.submit()"
          autocomplete="off">

      <md-input-container>
        <label ng-i18next="ACTION"></label>
        <md-select ng-if="dialog.hasScheduling" ng-model="dialog.releaseShifts">
          <md-option value="keep" ng-i18next="APPROVE_ACTION_KEEP"></md-option>
          <md-option value="release" ng-i18next="APPROVE_ACTION_RELEASE"></md-option>
          <md-option value="delete" ng-i18next="APPROVE_ACTION_DELETE"></md-option>
        </md-select>
      </md-input-container>

      <md-input-container>
        <label for="comment" ng-i18next="COMMENT"></label>
        <textarea id="comment" ng-model="dialog.comment"></textarea>
      </md-input-container>
    </form>
  </md-dialog-content>

  <md-dialog-actions layout="row">
    <md-button close-dialog ng-i18next="CANCEL"></md-button>
    <md-button form="approve" ng-disabled="approve.$invalid" type="submit" ng-i18next="SUBMIT"></md-button>
  </md-dialog-actions>
</md-dialog>
`;

module('eaw.absence').factory('approveAbsenceDialog', [ '$mdDialog', 'EawDialogController', function approveAbsenceDialog($mdDialog, EawDialogController) {

    return function show(absence, hasScheduling) {
        return $mdDialog.show({
            template: template1,
            controllerAs: 'dialog',
            controller: EawDialogController,
            locals: {
                hasScheduling,
                releaseShifts: 'keep',
                absence,
                text: t('absences:APPROVE_ABSENCE_TEXT', {
                    from: absence.from.format('LLL'),
                    to: absence.to.format('LLL'),
                }),
                submit() {
                    absence.comment = this.comment;
                    absence.release_shifts = this.releaseShifts;
                    this.close(absence.approve().$promise);
                },
            },
        });
    };
} ]);
