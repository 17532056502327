import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { UIRouter } from '@uirouter/core';
import { ScheduleShiftDialogComponent, ScheduleShiftDialogData, ScheduleShiftDialogReturn } from '../../../../dialogs/schedule-shift-dialog/schedule-shift-dialog.component';
import { MaterialColorDirective } from '../../../../../shared/directives/material-color.directive';
import { AutocompleteComponent } from '../../../../../shared/components/autocomplete/autocomplete.component';
import { EmployeeAutocompleteService } from '../../../../../shared/autocompletes/employee-autocomplete.service';
import { ShiftEmployeeAutocompleteService } from '../../../../../shared/autocompletes/shift-employee-autocomplete.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Employee } from '../../../../../shared/models/employee';
import { BusinessDate } from '../../../../../shared/utils/business-date';
import { CommentDialogComponent, CommentDialogData } from '../../../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { catchError, EMPTY, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';
import { ShiftCommentService } from '../../../../http/shift-comment.service';
import { ShiftService } from '../../../../http/shift.service';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { Namespace } from '../../../../../shared/enums/namespace';
import { AddEditShiftPeriodDialogComponent, AddEditShiftPeriodDialogData, AddEditShiftPeriodDialogReturn } from '../../../../dialogs/add-edit-shift-period-dialog/add-edit-shift-period-dialog.component';
import { DateTime } from 'luxon';
import { ShiftCopyDialogComponent, ShiftCopyDialogData, ShiftCopyDialogResult } from '../../../../dialogs/shift-copy-dialog/shift-copy-dialog.component';
import { PermissionCheckService } from '../../../../../shared/services/permission-check.service';
import { PermissionPipe } from '../../../../../shared/pipes/permission.pipe';
import { EawMaterialColorHue } from '../../../../../shared/services/material-color.service';
import { TranslateService } from '../../../../../shared/services/translate.service';
import { InfoLoadingComponent } from '../../../../../shared/components/info-loading/info-loading.component';
import { ShiftEmployee } from 'src/app/scheduling/models/shift-employee';
import { Shift } from 'src/app/scheduling/models/shift';
import { ScheduleComponent } from '../../schedule.component';
import { ApiModel, ApiModelClass } from '../../../../../shared/enums/api-model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ShiftPeriod } from '../../../../models/shift-period';
import { Comment } from '../../../../../shared/models/comment';

export interface MiniShiftDialogComponentData extends DialogData {
    stackId: number;
    customerId: number;
    scheduleId: number;
    shiftId: number;
    shift: ScheduleShiftDialogData['shift'];
    isTemplate: boolean;
    date: DateTime;
    businessDate: BusinessDate;
    employee?: Employee;
}

interface ListItem {
    text: Promise<string>,
    permission: Observable<boolean>,
    hasPermission?: boolean,
    color: EawMaterialColorHue,
    icon: string,
    click: () => void,
    disabled?: () => boolean
}

@Component({
    selector: 'eaw-schedule-mini-shift-dialog',
    standalone: true,
    imports: [ CommonModule, MatListModule, MatIconModule, TranslatePipe, MaterialColorDirective, AutocompleteComponent, ReactiveFormsModule, PermissionPipe, InfoLoadingComponent ],
    templateUrl: './mini-shift-dialog.component.html',
    styleUrl: './mini-shift-dialog.component.scss',
})
export class MiniShiftDialogComponent extends DialogComponent<MiniShiftDialogComponentData, void, MiniShiftDialogComponent> implements OnInit {
    private readonly uiRouter = inject(UIRouter);
    private readonly matDialog = inject(MatDialog);
    private readonly destroyRef = inject(DestroyRef);
    private readonly shiftService = inject(ShiftService);
    private readonly snackBarService = inject(SnackBarService);
    private readonly translateService = inject(TranslateService);
    private readonly shiftCommentService = inject(ShiftCommentService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    protected readonly employeeAutocompleteService = inject(EmployeeAutocompleteService);
    protected readonly shiftEmployeeAutocompleteService = inject(ShiftEmployeeAutocompleteService);

    protected readonly defaultItemColor: EawMaterialColorHue = 'grey-800';
    protected employeeControl = new FormControl<ShiftEmployee | Employee | number | null>(null);
    protected loading = true;
    protected employeeId?: number;
    protected items: ListItem[] = [];

    constructor() {
        super(undefined, {
            ...inject(MAT_DIALOG_DATA) as MiniShiftDialogComponentData,
            size: DialogSize.Small,
            disableClose: false,
        });

        const customerScheduleOptions = this.permissionOptions(this.data.stackId, ApiModel.Customer, ApiModel.Schedule);
        const customerScheduleShiftOptions = this.permissionOptions(this.data.stackId, ApiModel.Customer, ApiModel.Schedule, ApiModel.Shift);
        const shiftPermissionBase = `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].shifts.[${ApiModel.Shift}]`;

        this.items = [
            {
                permission: this.canUpdateShiftObservable,
                icon: 'edit',
                color: 'light-green-500',
                click: this.edit.bind(this),
                text: this.translateService.t('EDIT'),
            },
            {
                permission: this.canUpdateShiftObservable,
                icon: 'person_off',
                click: this.removeEmployee.bind(this),
                disabled: () => !this.employeeId,
                color: this.defaultItemColor,
                text: this.translateService.t('REMOVE_EMPLOYEE', Namespace.Scheduling),
            },
            {
                permission: this.permissionCheckService.isAllowed(`${shiftPermissionBase}.periods.create`, customerScheduleShiftOptions),
                icon: 'playlist_add',
                color: 'blue-500',
                click: this.addPeriod.bind(this),
                text: this.translateService.t('NEW_PERIOD', Namespace.Scheduling),
            },
            {
                permission: this.permissionCheckService.isAllowed(`${shiftPermissionBase}.periods.*.get`, customerScheduleShiftOptions),
                icon: 'list',
                color: 'blue-500',
                click: this.openPeriods.bind(this),
                text: this.translateService.t('PERIOD_plural', Namespace.Scheduling),
            },
            {
                permission: this.canUpdateShiftObservable,
                icon: 'comment',
                color: this.defaultItemColor,
                click: this.comment.bind(this),
                text: this.translateService.t('COMMENT_plural'),
            },
            {
                permission: this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].shifts.create`, customerScheduleOptions),
                icon: 'content_copy',
                color: this.defaultItemColor,
                click: this.copy.bind(this),
                text: this.translateService.t('COPY'),
            },
            {
                permission: this.permissionCheckService.isAllowed(`${shiftPermissionBase}.get`, customerScheduleShiftOptions),
                icon: 'info',
                color: this.defaultItemColor,
                click: this.goToShift.bind(this),
                text: this.translateService.t('INFORMATION'),
            },
            {
                permission: this.permissionCheckService.isAllowed(`${shiftPermissionBase}.delete`, customerScheduleShiftOptions),
                icon: 'delete',
                color: 'red-500',
                click: this.delete.bind(this),
                text: this.translateService.t('DELETE'),
            },
            {
                permission: of(true),
                icon: 'close',
                color: this.defaultItemColor,
                click: this.dialogRef.close.bind(this.dialogRef),
                text: this.translateService.t('CLOSE_DIALOG'),
            },
        ];
    }

    ngOnInit() {
        this.employeeId = this.data.employee?.id || undefined;
        this.employeeControl.setValue(this.data.employee || null);

        this.canUpdateShiftObservable.pipe(
            takeUntilDestroyed(this.destroyRef),
        ).subscribe((canUpdate) => {
            if (!canUpdate) {
                this.employeeControl.disable();
            }
        });

        forkJoin(
            this.items.map((i) => {
                return i.permission.pipe(
                    tap((hasPermission) => {
                        i.hasPermission = hasPermission;
                    }),
                );
            }),
        ).subscribe(() => {
            this.loading = false;
        });

        this.items.forEach((item) => {
            item.permission.subscribe((hasPermission) => (item.hasPermission = hasPermission));
        });

        this.employeeControl.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap((employee) => {
                if (employee instanceof Employee || employee instanceof ShiftEmployee) {
                    if (this.employeeId === employee.id) {
                        return EMPTY;
                    }

                    void this.snackBarService.t(`ASSIGNING_EMP_TO_SHIFT`, Namespace.Scheduling, { name: employee.name });
                    this.dialogRef.close();

                    ScheduleComponent.broadcastEvent(this.data.shiftId, 'updating', true);
                    return this.changeEmployee(employee.id).pipe(
                        catchError(() => {
                            this.employeeControl.setValue(null);
                            void this.snackBarService.t(`ASSIGNING_EMP_TO_SHIFT_FAIL`, Namespace.Scheduling, { name: employee.name });
                            ScheduleComponent.broadcastEvent(this.data.shiftId, 'updating', false);
                            return EMPTY;
                        }),
                        tap((shift) => {
                            ScheduleComponent.broadcastEvent(this.data.shiftId, 'updating', false);
                            ScheduleComponent.setShift(shift);

                            this.employeeId = employee.id;
                            void this.snackBarService.t(`ASSIGNING_EMP_TO_SHIFT_SUCCESS`, Namespace.Scheduling, { name: employee.name });
                        }),
                    );
                }

                return EMPTY;
            }),
        ).subscribe();
    }

    private get canUpdateShiftObservable() {
        return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].shifts.[${ApiModel.Shift}].update`, this.permissionOptions(this.data.stackId, ApiModel.Customer, ApiModel.Schedule, ApiModel.Shift));
    }

    private permissionOptions(stackId: number, ...models: (ApiModel | ApiModelClass)[]) {
        return {
            stackId,
            models: [
                { type: ApiModel.Customer, id: this.data.customerId },
                { type: ApiModel.Schedule, id: this.data.scheduleId },
                { type: ApiModel.Shift, id: this.data.shiftId },
            ].filter((m) => models.includes(m.type)),
        };
    }

    private periodAddedCallback(period: ShiftPeriod) {
        const shift = ScheduleComponent.shifts().get(this.data.shiftId);
        if (shift) {
            shift.periods.push(period);
            ScheduleComponent.setShift(shift);
        }
    }

    private periodEditedCallback(period: ShiftPeriod) {
        const shift = ScheduleComponent.shifts().get(this.data.shiftId);
        if (shift) {
            shift.periods = shift.periods.filter((p) => p.id !== period.id).concat(period);
            ScheduleComponent.setShift(shift);
        }
    }

    private periodDeletedCallback(periodId: number) {
        const shift = ScheduleComponent.shifts().get(this.data.shiftId);
        if (shift) {
            shift.periods = shift.periods.filter((p) => p.id !== periodId);
            ScheduleComponent.setShift(shift);
        }
    }

    edit() {
        const ref = this.matDialog.open<ScheduleShiftDialogComponent, ScheduleShiftDialogData, ScheduleShiftDialogReturn>(ScheduleShiftDialogComponent, {
            data: {
                stackId: this.data.stackId,
                customerId: this.data.customerId,
                scheduleId: this.data.scheduleId,
                shiftId: this.data.shiftId,
                shift: this.data.shift,
                shiftWiths: ScheduleComponent.shiftWiths,
                periodAddedCallback: this.periodAddedCallback.bind(this),
                periodEditedCallback: this.periodEditedCallback.bind(this),
                periodDeletedCallback: this.periodDeletedCallback.bind(this),
            },
        });

        ref.afterOpened().subscribe(() => this.dialogRef.close());
        ref.afterClosed().subscribe((res) => {
            if (res instanceof Shift) {
                ScheduleComponent.setShift(res);
            }

            if (res === null) {
                ScheduleComponent.deleteShift(this.data.shiftId);
            }
        });
    }

    addPeriod() {
        const ref = this.matDialog.open<AddEditShiftPeriodDialogComponent, AddEditShiftPeriodDialogData, AddEditShiftPeriodDialogReturn>(AddEditShiftPeriodDialogComponent, {
            data: {
                customerId: this.data.customerId,
                scheduleId: this.data.scheduleId,
                shiftId: this.data.shiftId,
            },
        });

        ref.afterClosed().subscribe((period) => {
            if (period) {
                const shift = ScheduleComponent.shifts().get(this.data.shiftId);
                if (shift) {
                    shift.periods.push(period);
                    ScheduleComponent.setShift(shift);
                }
            }
        });

        ref.afterOpened().subscribe(() => this.dialogRef.close());
    }

    openPeriods() {
        const ref = this.matDialog.open<ScheduleShiftDialogComponent, ScheduleShiftDialogData>(ScheduleShiftDialogComponent, {
            data: {
                stackId: this.data.stackId,
                customerId: this.data.customerId,
                scheduleId: this.data.scheduleId,
                shiftId: this.data.shiftId,
                shift: this.data.shift,
                shiftWiths: ScheduleComponent.shiftWiths,
                initialTab: 1,
                periodAddedCallback: this.periodAddedCallback.bind(this),
                periodEditedCallback: this.periodEditedCallback.bind(this),
                periodDeletedCallback: this.periodDeletedCallback.bind(this),
            },
        });

        ref.afterOpened().subscribe(() => this.dialogRef.close());
    }

    copy() {
        const ref = this.matDialog.open<ShiftCopyDialogComponent, ShiftCopyDialogData, ShiftCopyDialogResult>(ShiftCopyDialogComponent, {
            data: {
                customerId: this.data.customerId,
                scheduleId: this.data.scheduleId,
                shiftId: this.data.shiftId,
            },
        });

        ref.afterClosed().subscribe((result) => {
            if (result?.copied) {
                void this.uiRouter.stateService.reload();
            }
        });

        ref.afterOpened().subscribe(() => this.dialogRef.close());
    }

    commentSaveCallback(commentText: string, dialogRef: MatDialogRef<CommentDialogComponent>) {
        return this.shiftCommentService.create(this.data.customerId, this.data.scheduleId, this.data.shiftId, commentText).pipe(
            tap((comment) => ScheduleComponent.addComment(this.data.shiftId, comment)),
            tap(() => dialogRef.close()),
        );
    }

    commentDeleteCallback(commentId: number) {
        return this.shiftCommentService.delete(this.data.customerId, this.data.scheduleId, this.data.shiftId, commentId).pipe(
            tap(() => ScheduleComponent.deleteComment(this.data.shiftId, commentId)),
        );
    }

    comment() {
        const ref = this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                comments: this.shiftCommentService.getAll(this.data.customerId, this.data.scheduleId, this.data.shiftId).pipe(map((response) => response.data)),
                saveCallback: (comment): Observable<Comment> => {
                    return this.commentSaveCallback(comment, ref);
                },
                deleteCallback: this.commentDeleteCallback.bind(this),
            },
        });

        ref.afterOpened().subscribe(() => this.dialogRef.close());
    }

    delete() {
        void this.snackBarService.t('DELETING_SHIFT', Namespace.Scheduling);
        this.dialogRef.close();

        ScheduleComponent.broadcastEvent(this.data.shiftId, 'deleting', true);
        this.shiftService.delete(this.data.customerId, this.data.scheduleId, this.data.shiftId).pipe(
            catchError(() => {
                ScheduleComponent.broadcastEvent(this.data.shiftId, 'deleting', false);
                void this.snackBarService.t('DELETE_SHIFT_FAIL', Namespace.Scheduling);
                return EMPTY;
            }),
        ).subscribe(() => {
            ScheduleComponent.deleteShift(this.data.shiftId);
            ScheduleComponent.broadcastEvent(this.data.shiftId, 'deleted');
            void this.snackBarService.t('SHIFT_DELETED', Namespace.Scheduling);
        });
    }

    removeEmployee() {
        if (!this.employeeId) {
            return;
        }

        ScheduleComponent.broadcastEvent(this.data.shiftId, 'updating', true);
        void this.snackBarService.t('UNASSIGNING_SHIFT', Namespace.Scheduling);
        this.changeEmployee(null).pipe(
            catchError(() => {
                ScheduleComponent.broadcastEvent(this.data.shiftId, 'updating', false);
                void this.snackBarService.t('UNASSIGNING_SHIFT_FAIL', Namespace.Scheduling);
                return EMPTY;
            }),
        ).subscribe((shift) => {
            ScheduleComponent.setShift(shift);
            ScheduleComponent.broadcastEvent(this.data.shiftId, 'updating', false);
            void this.snackBarService.t('UNASSIGNING_SHIFT_SUCCESS', Namespace.Scheduling);
        });

        this.dialogRef.close();
    }

    goToShift() {
        this.uiRouter.stateService.transitionTo('eaw/app/scheduling/shifts/shift', { id: this.data.shiftId, customer_id: this.data.customerId });
        this.dialogRef.close();
    }

    private changeEmployee(employeeId: number | null) {
        return this.shiftService.update(this.data.customerId, this.data.scheduleId, this.data.shiftId, {
            employee_id: employeeId,
            with: ScheduleComponent.shiftWiths,
        });
    }
}
