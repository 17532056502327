// @ts-nocheck
import { module } from 'angular';
import { Namespace } from '../../shared/enums/namespace';
import { Products } from '../../shared/enums/products';

module('eaw.calendar').config([ '$stateProvider', function calendarRoute($stateProvider: any) {
    $stateProvider.state(`eaw/app/calendar`, {
        parent: 'eaw/app',
        abstract: true,
        url: '/calendar',
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            breadcrumb: { key: 'CALENDAR', ns: 'navigation' },
            products: [ Products.Calendar ],
            i18nextNs: [
                Namespace.Calendar,
                Namespace.Vacation,
                Namespace.Company,
                Namespace.Absences,
                Namespace.AbsenceTypes,
            ],
        },
        resolve: {
            navbarRoute: () => `eaw/app/calendar`,
            createSourceName: () => 'events',
            tabs: function() {
                return [
                    {
                        label: 'calendar:CALENDAR',
                        src: `eaw/app/calendar/calendar`,
                    },
                    {
                        label: 'calendar:EVENT_plural',
                        src: `eaw/app/calendar/events`,
                    },
                ];
            },
        },
    });

    $stateProvider.state(`eaw/app/calendar/calendar`, {
        parent: `eaw/app/calendar`,
        url: '/calendar',
        component: 'eawCalendarOld',
        data: {
            permissions: [ `customers.{customer}.calendar_events.*.get` ],
            breadcrumb: { key: 'CALENDAR', ns: 'calendar' },
        },
        resolve: {
            createSourceName: () => 'events',
        },
    });

    $stateProvider.state(`eaw/app/calendar/events`, {
        parent: `eaw/app/calendar`,
        url: '/events',
        component: 'eawCalendarEvents',
        data: {
            breadcrumb: { key: 'EVENT_plural', ns: 'calendar' },
        },
    });
} ]);
