import { Component, inject } from '@angular/core';
import { catchError, debounceTime, EMPTY, filter, forkJoin, map, Observable, of, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { ReportService } from '../../../reports/http/report.service';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { Report } from '../../../reports/models/report';
import { expandAllAssocPages, expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { ReportDescriptor } from '../../../reports/models/report-descriptor';
import { TranslateService } from '../../../shared/services/translate.service';
import { Namespace } from '../../../shared/enums/namespace';
import { MatDivider } from '@angular/material/divider';

export interface AddReportDialogData extends DialogData {
    customerId: number;
}

@Component({
    selector: 'eaw-add-report',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        MatDialogContent,
        FormsModule,
        MatAutocomplete,
        ReactiveFormsModule,
        MatOption,
        MatFormField,
        MatDialogActions,
        ActionButtonComponent,
        MatDialogClose,
        MatButton,
        MatLabel,
        MatInput,
        MatAutocompleteTrigger,
        MatDivider,
    ],
    templateUrl: './add-report-dialog.component.html',
    styleUrl: './add-report-dialog.component.scss',
})
export class AddReportDialogComponent extends DialogComponent<AddReportDialogData, Report> {
    private readonly reportService = inject(ReportService);
    private readonly translate = inject(TranslateService);

    private reportsObservable?: Observable<ReportDescriptor[]>;
    protected filteredReports: Observable<ReportDescriptor[]> = of([] as ReportDescriptor[]);

    reportCtrl = new FormControl<string | Report>('', { validators: Validators.required, nonNullable: true });
    loading: boolean = false;
    selectedReport?: ReportDescriptor;

    constructor() {
        super(undefined, undefined, DialogSize.Medium);

        this.reportCtrl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            filter((value) => typeof value === 'string'),
            tap((filter) => this.filteredReports = this.getReports(filter as string)),
        ).subscribe();
    }

    protected getReports(filter: string): Observable<ReportDescriptor[]> {
        if (!this.reportsObservable) {
            const existingReports = expandAllAssocPages(
                (pagination) => this.reportService.getAllReports(pagination),
                { per_page: 500 });
            const customerReports = expandAllPages(
                (pagination) => this.reportService.getAll(this.data.customerId, pagination),
                { per_page: 500 },
            );
            this.reportsObservable = forkJoin([
                existingReports,
                customerReports,
            ]).pipe(
                switchMap(async ([ reportResp, customerReports ]) => {
                    const reports = [];
                    for (const [ key, val ] of reportResp) {
                        if (customerReports.find((r) => r.class === key)) {
                            // The customer already has this report
                            continue;
                        }

                        const report = new ReportDescriptor(val, key);
                        report.translatedName = await this.translate.t(report.name, Namespace.Reports);
                        report.translatedDescription = await this.translate.t(report.description, Namespace.Reports);

                        reports.push(report);
                    }

                    return reports;
                }),
                shareReplay(1),
            );
        }

        if (!filter) {
            return this.reportsObservable;
        }

        return this.reportsObservable.pipe(map((reports) => {
            return reports.filter((r) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return r.translatedName!.toLowerCase().includes(filter) || r.translatedDescription!.toLowerCase().includes(filter) || r.class.toLowerCase().includes(filter);
            });
        }));
    }

    protected submit() {
        if (!this.selectedReport) {
            return;
        }

        this.loading = true;
        this.reportService.create(this.data.customerId, this.selectedReport.class)
            .pipe(
                catchError(() => {
                    this.loading = false;
                    return EMPTY;
                }),
                tap((report) => {
                    this.dialogRef.close(report);
                }),
            ).subscribe();
    }

    protected autoCompDisplayFn = (report?: ReportDescriptor): string => {
        return report?.translatedName ?? '';
    };

    onOptionSelected($event: MatAutocompleteSelectedEvent) {
        this.selectedReport = $event.option.value;
    }
}
