@if (loadingData) {
    <eaw-info-loading size="sm">
        <span>{{ 'CHECKING_EXISTING_ACCESS' | translate:'new_employee' | async }}</span>
    </eaw-info-loading>
} @else {
    @if (activeAccess) {
        <eaw-info-box>
            <span infoText>{{ 'ALREADY_ACCESS' | translate:'new_employee':{name: user.name} | async }}</span>
        </eaw-info-box>
    } @else if (futureAccess) {
        <eaw-info-box>
            <span infoText>{{ 'HAS_FUTURE_ACCESS' | translate:'new_employee':{name: user.name, date: futureAccess.from | DateTime} | async }}</span>
        </eaw-info-box>

        <form [formGroup]="futureAccessForm" class="tw-grid tw-gap-16 tw-grid-cols-1">
            <mat-form-field>
                <mat-label>{{ 'ACCESS' | translate:'new_employee' | async }}</mat-label>
                <mat-select formControlName="addAccessNow">
                    <mat-option [value]="false">{{ 'USE_FUTURE_ACCESS' | translateSync:'new_employee' }}</mat-option>
                    <mat-option [value]="true">{{ 'GIVE_ACCESS_NOW' | translateSync:'new_employee' }}</mat-option>
                </mat-select>
            </mat-form-field>

            @if (!step.completed) {
                @if (futureAccessForm.controls.addAccessNow.value) {
                    <eaw-action-button [loading]="processing" [disabled]="futureAccessForm.invalid" (click)="update()">{{ 'UPDATE_ACCESS' | translate:'new_employee' | async }}</eaw-action-button>
                } @else {
                    <eaw-action-button [loading]="processing" [disabled]="futureAccessForm.invalid" (click)="update()">{{ 'USE_FUTURE_ACCESS' | translate:'new_employee' | async }}</eaw-action-button>
                }
            }
        </form>
    } @else {
        <form [formGroup]="noAccessForm" class="tw-grid tw-gap-16 tw-grid-cols-1">
            <mat-form-field>
                <mat-label>{{ 'ACCESS' | translate:'new_employee' | async }}</mat-label>
                <mat-select formControlName="useFromDate">
                    <mat-option [value]="false">{{ 'GIVE_ACCESS_NOW' | translateSync:'new_employee' }}</mat-option>
                    <mat-option [value]="true">{{ 'GIVE_ACCESS_DATE' | translateSync:'new_employee' }}</mat-option>
                </mat-select>
            </mat-form-field>

            @if (noAccessForm.controls.useFromDate.value) {
                <mat-form-field>
                    <mat-label>{{ 'FROM' | translate | async }}</mat-label>
                    <eaw-date-time-input formControlName="from"></eaw-date-time-input>
                </mat-form-field>
            }
        </form>

        @if (!step.completed) {
            <eaw-action-button [loading]="processing" [disabled]="noAccessForm.invalid" (click)="create()">{{ 'ADD_ACCESS' | translate:'new_employee' | async }}</eaw-action-button>
        }
    }
}

<eaw-new-employee-next-step [stepper]="stepper" [step]="step"/>
