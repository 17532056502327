import { EmployeeTask, EmployeeTaskResponse } from '../../shared/models/employee-task';
import { EawUrl } from '../../shared/angularjs/modules/resource/url.service';

export interface SignDocumentTaskResponse extends EmployeeTaskResponse {
    data: {
        current_step: string;
        customer_id: number;
        document: string;
        employee_id: number;
        run_id: string;
        workflow_id: string;
        signature_topic: string;
        signature_description: string;
        signatory_name: string;
        are_initials_required: boolean;
        workflow_revision: number;
        messages: {
            signed_title: string;
            signed_text: string;
            rejected_title: string;
            rejected_text: string;
            postponed_title: string;
            postponed_text: string;
        }
    }
}

export class SignDocumentTask extends EmployeeTask {
    currentStep: string;
    customerId: number;
    document: string;
    employeeId: number;
    runId: string;
    workflowId: string;
    signatureTopic: string;
    signatureDescription: string;
    signatoryName: string;
    initialsRequired: boolean;
    revision: number;
    messages: {
        signedTitle: string,
        signedText: string,
        rejectedTitle: string,
        rejectedText: string,
        postponedTitle: string,
        postponedText: string,
    };

    constructor(response: SignDocumentTaskResponse) {
        super(response);

        this.currentStep = response.data.current_step;
        this.customerId = response.data.customer_id;
        this.document = response.data.document;
        this.employeeId = response.data.employee_id;
        this.runId = response.data.run_id;
        this.workflowId = response.data.workflow_id;
        this.signatureTopic = response.data.signature_topic;
        this.signatureDescription = response.data.signature_description;
        this.signatoryName = response.data.signatory_name;
        this.initialsRequired = response.data.are_initials_required;
        this.revision = response.data.workflow_revision;
        this.messages = {
            signedTitle: response.data.messages.signed_title,
            signedText: response.data.messages.signed_text,
            rejectedTitle: response.data.messages.rejected_title,
            rejectedText: response.data.messages.rejected_text,
            postponedTitle: response.data.messages.postponed_title,
            postponedText: response.data.messages.postponed_text,
        };
    }

    /**
     * Url to download the document - only for pdf renderer
     */
    get url() {
        if (!this.workflowId) {
            return null;
        }

        return EawUrl.getUrl(`/document-service/1.0.0/customers/${this.customerId}/employees/${this.employeeId}/workflows/${this.workflowId}/runs/${this.runId}/documents/${this.document}`);
    }
}
