import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Employee } from '../../../../../shared/models/employee';
import { UIRouter } from '@uirouter/core';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { DataTableComponent } from '../../../../../data-table/data-table.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';

@Component({
    selector: 'eaw-new-employee-done',
    standalone: true,
    imports: [ CommonModule, MatButtonModule, ActionButtonComponent, DataTableComponent, TranslatePipe ],
    templateUrl: './new-employee-done.component.html',
    styleUrl: './new-employee-done.component.scss',
})
export class NewEmployeeDoneComponent extends NewEmployeeStepComponent {
    protected uiRouter = inject(UIRouter);

    @Input({ required: true }) employee!: Employee;
}
