import { catchError, map, of } from 'rxjs';
import { Setting } from '../models/setting';
import { StateProviderDataData } from './create-state';
import { SettingService } from '../http/setting.service';

export function routeDataSettingsChecker(settingService: SettingService, customerId: number, settings: StateProviderDataData['settings']) {
    if (!settings || !Object.keys(settings).length) {
        return of(true);
    }

    return settingService.getSome([ 'customers', customerId ], { 'settings[]': Object.keys(settings) }).pipe(
        catchError(() => of([] as Setting[])),
        map((settingsResponse) => {
            return Object.entries(settings).every(([ key, callback ]) => {
                const setting = settingsResponse.find((s) => s.key === key);
                return setting ? callback(setting.value) : false;
            });
        }),
    );
}
