// @ts-nocheck
import { module } from 'angular';
import { Products } from '../../shared/enums/products';
import { Namespace } from '../../shared/enums/namespace';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.filesystem', [
    'eaw.resource',
    'eaw.misc',
]).config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state('eaw/app/filesystem', {
        parent: 'eaw/app',
        views: {
            'content@': 'eawFilesystem',
        },
        url: '/files?path',
        params: {
            path: {
                type: 'string',
                squash: true,
            },
        },
        data: {
            products: [ Products.FileSystem ],
            i18nextNs: [ Namespace.Filesystem ],
            permissions: [ 'customers.{customer}.fs_user' ],
            breadcrumb: { key: 'FILES', ns: 'navigation' },
        },
        resolve: {
            directories: [ '$transition$', 'FilesystemFactory', function($transition$, FilesystemFactory) {
                const path = $transition$.params().path || '';
                const promises = [];

                path.split('/').forEach((id, index, arr) => {
                    // If last item, then get the content of directory
                    const withs = index === arr.length - 1 ? [ 'files.user', 'files.attachments', 'directories.user' ] : undefined;

                    if (index === 0) { // First in our path is root
                        promises.push(FilesystemFactory.getRoot.query(CurrentOld.customer.id, withs));
                    } else {
                        promises.push(FilesystemFactory.getDirectory.query(CurrentOld.customer.id, id, withs));
                    }
                });

                return Promise.all(promises);
            } ],
            directory: [ 'directories', function(directories) {
                return directories[directories.length - 1];
            } ],
        },
    });
} ]);
