import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { ScheduleTabInterval } from '../../schedule-tab.component';
import { ScheduleComponent } from '../../../../schedule.component';

@Component({
    selector: 'eaw-schedule-tab-vertical-lines',
    standalone: true,
    imports: [],
    templateUrl: './schedule-tab-vertical-lines.component.html',
    styleUrl: './schedule-tab-vertical-lines.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleTabVerticalLinesComponent {
    renderedIntervals = input.required<ScheduleTabInterval[]>();
    pixelsPerSecond = input.required<number>();

    lines = computed(() => {
        const showVertical = ScheduleComponent.properties.scheduleTab.verticalLines.value();
        return showVertical ? this.renderedIntervals().map((i) => ((i.offset * this.pixelsPerSecond()) - 1) + 'px') : [];
    });
}
