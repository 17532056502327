import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DefaultHrFileService } from '../../http/default-hr-file.service';
import { HrDefaultFile } from '../../models/hr-default-file';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { debounceTime, forkJoin, map, of } from 'rxjs';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { HrFileType } from '../../models/hr-file-type';
import { HrFileTypeService } from '../../http/hr-file-type.service';
import { CreateHrDefaultDialogService } from '../../dialogs/create-hr-default-file-dialog/create-hr-default-dialog.service';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-hr-default-files',
    templateUrl: './hr-default-files.component.html',
    styleUrl: './hr-default-files.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class HrDefaultFilesComponent implements EawDataTable, OnInit {
    // Get data table
    @ViewChild(DataTableComponent) table!: DataTableComponent<HrFileType>;

    @Input() customerId!: number;

    filetypes: HrFileType[] = [];
    // Form control with file type ids to be submitted
    typeFilter = new FormControl<number[]>({
        value: this.searchParams.get('filetypeFilter', 'array')?.map(parseInt) || [],
        disabled: true,
    }, { nonNullable: true });

    // For comparing if there are changes to file type filter
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    typeFilterCompare: number[] = this.typeFilter.value!;
    // FormControl, holds search input
    search = new FormControl<string>(this.searchParams.get('search', 'string') || '');
    // Button for opening default file creation dialog, sends customer id and filetypes
    fabButton: HeaderFabButton = {
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.default_hr_files.create`),
        click: () => this.dialog.openDialog({
            customerId: this.customerId,
            filetypes: this.filetypes,
        }).afterClosed().subscribe((update) => update ? this.updateTable() : null),
    };

    // Columns
    columns: DataTableColumnType<HrDefaultFile>[] = [
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.fileType?.name || '',
            header: new DataTableHeader({
                i18n: 'FILETYPE',
                ns: 'hr',
                sortBy: 'type_id',
            }),
        }),
        new DataTableTextColumn({
            value: 'description',
            header: new DataTableHeader({
                i18n: 'DESCRIPTION',
                sortBy: 'description',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'createdAt',
            format: DateTime.DATETIME_MED_WITH_WEEKDAY,
            header: new DataTableHeader({
                i18n: 'CREATED_AT',
                sortBy: 'created_at',
            }),
        }),
        new DataTableButtonColumn<HrDefaultFile>({
            buttons: [
                // Manage form fields for admins
                {
                    icon: 'assignment_add',
                    click: (cell) => {
                        this.dialog.formFieldDialog({
                            customerId: this.customerId,
                            id: cell.item.id,
                        }).afterClosed().subscribe((update) => {
                            if (update) {
                                this.updateTable();
                            } else {
                                cell.disabled.set(false);
                            }
                        });
                    },
                    tooltip: {
                        key: 'MANAGE_FORM_FIELDS',
                        ns: 'admin',
                    },
                    show: () => this.permissionCheckService.isAllowed(`admin`),
                },
                {
                    icon: 'edit',
                    tooltip: { key: 'EDIT' },
                    click: (cell) => this.update(cell),
                    show: (item) => this.permissionCheckService.isAllowed(`customers.${item.customerId}.default_hr_files.${item.id}.update`),
                },
                {
                    icon: 'download',
                    click: (cell) => {
                        void this.snackbar.t('DOWNLOAD_STARTED');
                        this.service.download(this.customerId, cell.item.id, cell.item.name).subscribe(() => this.snackbar.t('DOWNLOAD_FINISHED'));
                    },
                    nonBlocking: true,
                    tooltip: { key: 'DOWNLOAD' },
                    show: (item) => this.permissionCheckService.isAllowed(`customers.${item.customerId}.default_hr_files.${item.id}.get`),
                },
                {
                    hide: (item) => {
                        return forkJoin([
                            of(!!item.formFieldsCount),
                            this.permissionCheckService.isAllowed(`admin`),
                        ]).pipe(map(([ hasFormFields, isAdmin ]) => hasFormFields && !isAdmin));
                    },
                    icon: 'delete',
                    type: 'warn',
                    tooltip: { key: 'DELETE' },
                    click: (cell) => this.delete(cell),
                    show: (item) => this.permissionCheckService.isAllowed(`customers.${item.customerId}.default_hr_files.${item.id}.delete`),
                } ],
        }),
    ];

    // Request; which the table use to get data
    request: DataTableRequest = of(mockArrayPaginatedResponse());

    constructor(
        @Inject(DefaultHrFileService) private service: DefaultHrFileService,
        @Inject(CreateHrDefaultDialogService) private dialog: CreateHrDefaultDialogService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(QueryParamsService) private searchParams: QueryParamsService,
        @Inject(HrFileTypeService) private filetypeService: HrFileTypeService,
    ) {
        // If admin, show form field count
        this.permissionCheckService.isAllowed('admin').subscribe((isAdmin) => {
            if (isAdmin) {
                this.columns.splice(4, 0, new DataTableNumberColumn({
                    value: 'formFieldsCount',
                    header: new DataTableHeader({ text: 'Form fields' }),
                }));
            }
        });

        // Update table with a short delay when search field input is changed
        this.search.valueChanges.pipe(debounceTime(1000)).subscribe(() => this.updateTable(this.table.getPagination({ page: 1 })));
    }

    // Get and set file types
    ngOnInit() {
        this.filetypeService.getAll(this.customerId, { per_page: 9999 }).subscribe((resp) => {
            this.filetypes = resp.data;
            this.typeFilter.enable();
            this.typeFilter.setValue(this.searchParams.get('filetypeFilter', 'array')?.map(parseInt) ?? []);
        });
    }

    update(cell: DataTableButtonCell<HrDefaultFile>) {
        this.dialog.openDialog({
            customerId: this.customerId,
            id: cell.item.id,
            name: cell.item.name,
            description: cell.item.description,
            filetype: cell.item.fileType,
            filetypes: this.filetypes,
        }).afterClosed().subscribe((update) => update ? this.updateTable() : (cell.disabled.set(false)));
    }

    // Deletes a file and update table when done
    delete(cell: DataTableButtonCell<HrDefaultFile>) {
        this.dialog.deleteFileDialog(cell.item.name).afterClosed().subscribe((remove) => {
            if (remove?.ok) {
                this.service.delete(this.customerId, cell.item.id).subscribe({
                    next: () => {
                        this.updateTable();
                        void this.snackbar.t('X_DELETED', 'general', { name: cell.item.name });
                    },
                    error: () => (cell.disabled.set(false)),
                });
            } else {
                cell.disabled.set(false);
            }
        });
    }

    // Runs when filter box is closed, checks that there are changes and then update table
    onFilterClose() {
        if (this.typeFilter.value === this.typeFilterCompare) {
            return;
        }
        this.typeFilterCompare = this.typeFilter.value ?? [];
        this.updateTable(this.table.getPagination({ page: 1 }));
    }

    // Updates table when called on, or is called by the table on initialization
    updateTable(pagination: Partial<DataTablePagination> = {}) {
        // Set search params
        this.searchParams.set([
            {
                key: 'search',
                value: this.search.value,
            },
            {
                key: 'filetypeFilter',
                value: this.typeFilter.value,
            },
        ]);

        // Request to get all default files on customer, sends search values and filtered types, and requests filetype data
        this.request = this.service.getAll(this.customerId, {
            ...pagination,
            filter: this.search.value || undefined,
            'fields[]': [ 'name', 'description' ],
            'with[]': [ 'fileType' ],
        }, this.typeFilter.value ?? undefined);
    }
}
