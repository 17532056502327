import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataTableNumberColumn } from '../../data-table/types/data-table-number-column';
import { DataTableHeader } from '../../data-table/types/data-table-header';
import { DataTableColumnType } from '../../data-table/interfaces/data-table-columns';
import { PeopleKpiService } from '../people-kpi.service';
import { DataTableTextColumn } from '../../data-table/types/data-table-text-column';
import { TranslateService } from '../../shared/services/translate.service';
import { DateTime } from 'luxon';
import { debounceTime, distinctUntilChanged, EMPTY, map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { PeopleKpiRowField } from '../types/people-kpi-row-field';
import { DataTableRequest, DataTableResponse, EawDataTable } from '../../data-table/types/data-table';
import { PeopleKpiRow } from '../types/people-kpi-row';
import { PeopleKpiLevel } from '../types/people-kpi-level';
import { CurrentService } from '../../shared/services/current.service';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { DataTableComponent } from '../../data-table/data-table.component';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { PeopleKpi } from '../types/people-kpi';
import { mockArrayPaginatedResponse } from '../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-people-kpi',
    templateUrl: './people-kpi.component.html',
    styleUrl: './people-kpi.component.scss',
    standalone: true,
    imports: [
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        NgFor,
        MatOptionModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class PeopleKpiComponent implements OnInit, EawDataTable {
    columns: DataTableColumnType<PeopleKpiRow>[] = [];
    request?: DataTableRequest;
    loading = false;
    dateRange = new FormGroup({
        from: new FormControl(DateTime.now().minus({ months: 12 }), { updateOn: 'blur' }),
        to: new FormControl(DateTime.now(), { updateOn: 'blur' }),
    });

    formGroup = new FormGroup({
        level: new FormControl<PeopleKpiLevel | undefined>(undefined),
        dateRange: this.dateRange,
    });

    selectedLevel: PeopleKpiLevel | null = null;
    filteredLevels: Observable<PeopleKpiLevel[]> = of([]);

    constructor(
        @Inject(PeopleKpiService) public pplKpiService: PeopleKpiService,
        @Inject(TranslateService) public translator: TranslateService,
        @Inject(CurrentService) public current: CurrentService,
    ) {
    }

    ngOnInit(): void {
        this.formGroup.controls.level.setValue(new PeopleKpiLevel({
            name: this.current.getCustomer().name,
            identifier: `customer.${this.current.getCustomer().id}`,
        }));

        this.filteredLevels = this.formGroup.controls.level.valueChanges.pipe(
            debounceTime(1000),
            startWith(''),
            map((value: unknown) => typeof value === 'string' ? value.toLowerCase() : ''),
            switchMap((locationFilter) => {
                return this.pplKpiService.getLevels(this.current.getCustomer().id).pipe(
                    switchMap((levels) => {
                        return of(levels.filter((lvl) => lvl.name.toLowerCase().includes(locationFilter || '')));
                    }),
                );
            }),
        );

        this.formGroup.valueChanges.pipe(
            debounceTime(1000),
            switchMap((formValue) => {

                if (!formValue.dateRange?.from?.isValid) {
                    return EMPTY;
                }
                if (!formValue.dateRange?.to?.isValid) {
                    return EMPTY;
                }

                return of(formValue);
            }),
            distinctUntilChanged((prev, curr) => {
                const sameLevel = prev.level?.identifier === curr.level?.identifier;
                const sameFrom = prev.dateRange?.from === curr.dateRange?.from;
                const sameTo = prev.dateRange?.to === curr.dateRange?.to;

                return sameLevel && sameFrom && sameTo;
            }),
        ).subscribe(() => this.getKPI());

        this.getKPI();
    }

    levelDisplay(selectedLevel?: PeopleKpiLevel) {
        return selectedLevel?.name || '';
    }

    getKPI() {
        const { from, to } = this.dateRange.value;
        if (!(from && to)) {
            return;
        }

        this.request = this.pplKpiService.get(this.current.getCustomer().id, from.startOf('day'), to.endOf('day'), this.selectedLevel).pipe(
            tap(this.createColumns.bind(this)),
            map((response) => mockArrayPaginatedResponse(response.rows) satisfies DataTableResponse<PeopleKpiRow>),
        );
    }

    createColumns(peopleKpi: PeopleKpi) {
        this.columns = [
            new DataTableTextColumn<PeopleKpiRow>({
                header: new DataTableHeader({ i18n: 'NAME' }),
                value: async (cell) => {
                    if (typeof cell.item.name === 'string') {
                        return cell.item.name;
                    }

                    return this.translator.t(cell.item.name.key, cell.item.name.ns);
                },
            }),
            ...peopleKpi.headers.map((header) => {
                let columnHeader;
                if (typeof header.name === 'string') {
                    columnHeader = { text: header.name };
                }
                if (typeof header.name === 'object') {
                    columnHeader = {
                        i18n: header.name.key,
                        ns: header.name.ns,
                    };
                }

                return new DataTableNumberColumn<PeopleKpiRow>({
                    numberFormatOptions: {
                        style: header.suffix === '%' ? 'percent' : 'decimal',
                        minimumFractionDigits: header.suffix === '%' ? 2 : 0,
                        maximumFractionDigits: header.suffix === '%' ? 2 : 0,
                    },
                    value(cell) {
                        return cell.item.fields.find((f) => f.key === header.key)?.value ?? '';
                    },
                    header: new DataTableHeader(columnHeader),
                    compare(cell) {
                        const field = cell.item.fields.find((f: PeopleKpiRowField) => f.key === header.key);
                        return field?.trend ? field.trend === '+' : undefined;
                    },
                });
            }),
        ];
    }

    updateTable(): void {
        this.getKPI();
    }

    changeLevel(level: PeopleKpiLevel) {
        this.selectedLevel = level;
    }
}
