<eaw-dialog-header>
    <span title>{{ 'CREATE_TEMPLATE' | translate:'scheduling' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="formGroup" class="tw-grid tw-grid-cols-12 tw-gap-8">
        <mat-form-field class="tw-col-[1_/_7]">
            <mat-label>{{ 'NAME' | translate | async }}</mat-label>
            <input matInput formControlName="name" autocomplete="off">
        </mat-form-field>

        <mat-form-field class="tw-col-[7_/_-1]">
            <mat-label>{{ 'START' | translate | async }}</mat-label>
            <mat-select formControlName="startDay">
                <mat-option *ngFor="let day of startDays" [value]="day.date">{{ day.weekday }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="tw-col-[1_/_7]">
            <mat-label>{{ 'DURATION' | translate | async }}</mat-label>
            <input matInput formControlName="duration" autocomplete="off" type="number">
        </mat-form-field>

        <mat-form-field class="tw-col-[7_/_-1]">
            <mat-select formControlName="type">
                <mat-option value="days" selected>{{ ('DAY_plural' | translateSync) }}</mat-option>
                <mat-option value="weeks">{{ ('WEEK_plural' | translateSync) }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-raised-button [mat-dialog-close]>{{ 'CLOSE' | translate | async }}</button>
    <button mat-button mat-raised-button color="primary" [disabled]="formGroup.invalid" (click)="create()">{{ 'CREATE' | translate | async }}</button>
</mat-dialog-actions>
