export default class MlVariable {
    uuid: string;
    bucket_uuid: string;
    code: string;
    description: string;
    name: string;

    constructor(mlVariable: any) {
        this.uuid = mlVariable.uuid;
        this.bucket_uuid = mlVariable.bucket_uuid;
        this.code = mlVariable.code;
        this.description = mlVariable.description;
        this.name = mlVariable.name;
    }

    get id() {
        return this.uuid;
    }
}
