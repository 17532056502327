import { module } from 'angular';
import { NavTabsComponent, NavTabsTab } from '../../../shared/components/nav-tabs/nav-tabs.component';
import { resolver } from '../../../shared/resolvers/resolver';
import { PermissionOptions } from '../../../shared/services/permission-check.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { UIRouter } from '@uirouter/core';
import { dependencyResolver } from '../../../shared/resolvers/dependency.resolver';
import { CustomerUserGroupMembersComponent } from '../../pages/customer-user-group-members/customer-user-group-members.component';
import { createState } from '../../../shared/utils/create-state';
import { UserGroupPermissionSetsComponent } from '../../pages/admin-user-group-permission-sets/user-group-permission-sets.component';

module('eaw.admin').config([ '$stateProvider', ($stateProvider: any) => {
    // @ts-ignore
    $stateProvider.state('eaw/app/admin/customers/view/user_groups/view/nav', {
        parent: `eaw/app/admin/customers/view/user_groups`,
        abstract: true,
        url: '/:group_id',
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        params: {
            group_id: {
                type: 'int',
            },
        },
        data: {
            breadcrumb: { key: 'USER_GROUP', ns: 'admin' },
        },
        resolve: [
            resolver('tabs', function(transition): Promise<NavTabsTab[]> {
                const customerId: number = transition.params()['id'];
                const userGroupId = transition.params()['group_id'];
                const options: PermissionOptions = {
                    models: [
                        { id: customerId, type: ApiModel.Customer },
                        { id: userGroupId, type: ApiModel.UserGroup },
                    ],
                };

                const tabs: NavTabsTab[] = [
                    {
                        state: 'eaw/app/admin/customers/view/user_groups/view/nav/users',
                        label: { key: 'USER_plural' },
                        permissions: [ [ `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].members.*.get`, options ] ],
                    },
                    {
                        state: 'eaw/app/admin/customers/view/user_groups/view/nav/permissions',
                        label: { key: 'PERMISSION_plural' },
                        permissions: [
                            [ `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].permission_sets.*.get`,options ],
                        ],
                        somePermissions: [
                            [ `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].permissions.get`, options ],
                            [ `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].permissions.manage`, options ],
                        ],
                    },
                ];

                return Promise.resolve(tabs);
            }),
            resolver('userGroupId', function(transition): Promise<number> {
                return Promise.resolve(transition.params()['group_id']);
            }),
            resolver('customerId', function(transition): Promise<number> {
                return Promise.resolve(transition.params()['id']);
            }),
            dependencyResolver('userGroup', [ 'UserGroupService', '$uiRouter' ], function(UserGroupService: any, $uiRouter: UIRouter) {
                return UserGroupService.get($uiRouter.globals.transition.params()['id'], $uiRouter.globals.transition.params()['group_id'], [ 'owner' ]).$promise;
            }),
            resolver('userGroupId', function(transition): Promise<number> {
                return Promise.resolve(transition.params()['group_id']);
            }),
        ],
    });

    createState(
        $stateProvider,
        {
            name: 'eaw/app/admin/customers/view/user_groups/view/nav/users',
            parent: `eaw/app/admin/customers/view/user_groups/view/nav`,
            url: '/members',
            views: {
                'nav-view': {
                    // TODO: if changes are needed under 'company', but not here, split this into two components
                    component: CustomerUserGroupMembersComponent,
                },
            },
            data: {
                breadcrumb: { key: 'MEMBER_plural' },
                queryParams: [ 'display_inactive_users' ],
            },
        },
    );

    $stateProvider.state('eaw/app/admin/customers/view/user_groups/view/nav/permissions', {
        parent: `eaw/app/admin/customers/view/user_groups/view/nav`,
        url: '/permissions',
        views: {
            'nav-view': {
                // TODO: If changes are needed here, but not in "my company", split this component into two
                component: UserGroupPermissionSetsComponent,
            },
        },
        data: {
            breadcrumb: { key: 'PERMISSION_plural' },
        },
    });
} ]);
