// @ts-nocheck
import { module } from 'angular';
import { EawUrl } from '../../shared/angularjs/modules/resource/url.service';
import { Mobile } from '../../shared/utils/eaw-mobile';
module('eaw.hr').factory('HrFactory', [ 'EawResource', 'Upload', 'Pagination', 'fileDownloadHr', '$http', 'FileService', 'HrFileType', function(EawResource, Upload, Pagination, fileDownloadHr, $http, FileService, HrFileType) {
    const fac = this;
    const ftUrl = '/customers/:customer/hr_file_types/:type';
    const fUrl = '/customers/:customer/employees/:employee/hr_files/:file';
    /**
     * @param {number}          customerId
     * @param {number}          employeeId
     * @param {object}          args
     * @param {File}            args.file
     * @param {boolean}         args.notify
     * @param {number}          args.type_id
     * @param {string}          args.name
     * @param {moment.Moment}   args.expires_at
     * @param {number}          args.warn_days
     * @param {number[]}        [args.employee_ids]
     * @returns {Promise}
     */
    fac.upload = (customerId, employeeId, args = {}) => {
        args.expires_at = args.expires_at?.endOf('d');
        return Upload.upload({
            url: args.employee_ids?.length ? `${EawUrl.url}/customers/${customerId}/hr_files` : `${EawUrl.url}/customers/${customerId}/employees/${employeeId}/hr_files`,
            data: args,
        });
    };
    /**
     * @memberOf HrFactory
     *
     * @param {int} customerId
     * @param {int} employeeId
     * @param {int} fileId
     * @param {object} formData
     * @returns {*}
     */
    fac.saveFormFields = (customerId, employeeId, fileId, formData) => EawResource.create('/customers/:customer/employees/:employee/hr_files/:file/form_fields').save({
        customer: customerId,
        employee: employeeId,
        file: fileId,
    }, formData);
    /**
     * @memberOf HrFactory
     *
     * Get raw HTML form for a HR file signing
     * @param {int} customerId
     * @param {int} employeeId
     * @param {int} fileId
     */
    fac.getRaw = (customerId, employeeId, fileId) => $http({
        method: 'GET',
        url: `${EawUrl.url}/customers/${customerId}/employees/${employeeId}/hr_files/${fileId}/raw`,
    });
    /**
     * @memberOf HrFactory
     *
     * Get HR overview for employee
     * @param {object} args
     * @param {object} args.customer
     * @param {object} args.employee
     * @param {object} [args.with]
     */
    fac.getEmployeeOverview = (args) => EawResource.create('/customers/:customer/employees/:employee/hr_overview').get({
        customer: args.customer.id,
        employee: args.employee.id,
        include_expired: args.include_expired,
        'with[]': args.with,
    });
    /**
     * @memberOf HrFactory
     *
     * Get file ts for a customer
     * @param {object} args
     * @param {int} args.customerId
     */
    fac.getCustomerFileTypes = (args) => EawResource.create(ftUrl).get({
        customer: args.customerId,
        ...Pagination.getParams(args),
    }).$promise.then((response) => {
        response.data = response.data.map((d) => new HrFileType(d));
        return response;
    });
    /**
     * @memberOf HrFactory
     *
     * Create a new file type for HR
     * @param {object} customer
     * @param {string} name
     * @param {boolean} mandatory
     */
    fac.createNewFileType = (customer, name, mandatory) => EawResource.create(ftUrl).save({
        customer: customer.id,
    }, {
        name,
        mandatory,
    });
    /**
     * @memberOf HrFactory
     *
     * Edit an existing file type
     * @param {object} customer
     * @param {object} fileType
     */
    fac.updateFileType = (customer, fileType) => EawResource.create(ftUrl).update({
        customer: customer.id,
        type: fileType.id,
    }, {
        name: fileType.name,
        mandatory: fileType.mandatory,
    }).$promise.then((resp) => new HrFileType(resp));
    /**
     * @memberOf HrFactory
     *
     * Delete a HR file type for a customer
     * @param {object} customer
     * @param {integer} id
     */
    fac.deleteFileType = (customer, id) => EawResource.create(ftUrl).delete({
        customer: customer.id,
        type: id,
    }).$promise;
    /**
     * @memberOf HrFactory
     *
     * Get a specific file
     * @param {int} customer
     * @param {int} employee
     * @param {int} file
     * @param {object} args
     */
    fac.getEmployeeFile = (customer, employee, file, args) => EawResource.create(fUrl).get({
        customer,
        employee,
        file,
        'with[]': args.with,
    });
    /**
     * @param {Integer} customer
     * @param {Integer} employee
     * @param {Integer} file
     * @returns {Promise}
     */
    fac.delete = (customer, employee, file) => EawResource.create(fUrl).delete({
        customer,
        employee,
        file,
    }).$promise;
    /**
     * @memberOf HrFactory
     *
     * Create a default HR file
     * @param {int} customerId
     * @param {int} employeeId
     * @param {int} defaultFileId
     * @param {object} [args]
     * @param {moment} [args.expiresAt]
     * @param {boolean} [args.notify]
     * @param {int} [args.warnDays]
     */
    fac.createDefaultEmployeeFile = (customerId, employeeId, defaultFileId, args = {}) => EawResource.create(fUrl).save({
        customer: customerId,
        employee: employeeId,
    }, {
        default_file_id: defaultFileId,
        notify: args.notify,
        expires_at: args.expiresAt?.endOf('d'),
        warn_days: args.warnDays,
    });
    /**
     * @memberOf HrFactory
     *
     * Preview a HR file
     * @param {int}     customer
     * @param {int}     employee
     * @param {int}     file
     */
    fac.previewFile = (customer, employee, file) => {
        if (Mobile.isMobile) {
            return fileDownloadHr.open(customer, employee, file, 'preview');
        }
        const url = `${EawUrl.url}/customers/${customer}/employees/${employee}/hr_files/${file}/preview`;
        return FileService.download(url, 'get');
    };
    /**
     * @memberOf HrFactory
     *
     * Download a HR file
     * @param {int}     customer
     * @param {int}     employee
     * @param {int}     file
     */
    fac.downloadFile = (customer, employee, file) => {
        if (Mobile.isMobile) {
            return fileDownloadHr.open(customer, employee, file, 'download');
        }
        const url = `${EawUrl.url}/customers/${customer}/employees/${employee}/hr_files/${file}/download`;
        return FileService.download(url, 'get');
    };
    return fac;
} ]);
