import { AfterViewInit, Component, ElementRef, HostBinding, inject, Input, OnDestroy, Signal, viewChild } from '@angular/core';
import { DateTime } from 'luxon';
import type { PaidTimeOverview } from '../../models/paid-time-overview';
import { PaidTimeSegmentTypeKey, PaidTimeSegmentTypes } from '../../types/paid-time-segment-types';
import { Namespace, NamespaceFile } from '../../../shared/enums/namespace';
import { debounceTime, forkJoin, fromEvent, map, Observable, startWith, Subject, take, takeUntil, tap } from 'rxjs';
import { Customer } from '../../../shared/models/customer';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { Holiday } from '../../../shared/models/holiday';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { PaidTimeOverviewEmployeeRowComponent } from './components/paid-time-overview-employee-row/paid-time-overview-employee-row.component';
import { PaidTimeOverviewDateComponent } from './components/paid-time-overview-date/paid-time-overview-date.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateIntervalSelectorComponent } from '../../../shared/components/date-interval-selector/date-interval-selector.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { PaidTimeService } from '../../../payroll/http/paid-time.service';
import { CurrentService } from '../../../shared/services/current.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { UIRouter } from '@uirouter/core';
import { HolidayService } from '../../../shared/http/holiday.service';
import { QueryParamsService } from '../../../shared/services/query-params.service';

type HeaderDate = {
    date: DateTime,
    isHoliday: boolean,
}

interface PaidTimeSegmentTypeMenuItem {
    key: PaidTimeSegmentTypeKey,
    name: { key: string, ns: NamespaceFile }
    selected: boolean,
}

@Component({
    selector: 'eaw-paid-time-overview',
    templateUrl: './paid-time-overview.component.html',
    styleUrl: './paid-time-overview.component.scss',
    standalone: true,
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatListModule,
        NgFor,
        MatCheckboxModule,
        DateIntervalSelectorComponent,
        NgIf,
        MatProgressSpinnerModule,
        PaidTimeOverviewDateComponent,
        PaidTimeOverviewEmployeeRowComponent,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class PaidTimeOverviewComponent implements AfterViewInit, OnDestroy {
    private gridContainer: Signal<ElementRef<HTMLDivElement>> = viewChild.required('gridContainer');

    private readonly paidTimeService = inject(PaidTimeService);
    private readonly current = inject(CurrentService);
    private readonly snackBarService = inject(SnackBarService);
    private readonly uiRouter = inject(UIRouter);
    private readonly elementRef = inject(ElementRef);
    private readonly holidayService = inject(HolidayService);
    private readonly searchParamsService = inject(QueryParamsService);

    @HostBinding('class.empty-rows')
    get showEmptyRowsClass() {
        return this.showEmptyRows;
    }

    @Input()
    set customers(customers: Observable<Customer[]>) {
        const initialCustomerId = this.searchParamsService.get('location', 'number') || this.current.getCustomer().id;

        customers.pipe(take(1)).subscribe((res) => {
            if (!res.length) {
                void this.snackBarService.t('NO_CUSTOMER_plural', Namespace.ChainOps);
                this.uiRouter.stateService.go('eaw/app/home');
                return;
            }

            this.resolvedCustomers = res;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.customer = this.resolvedCustomers.find((customer) => customer.id === initialCustomerId) || res[0]!;
            this.searchParamsService.set([ {
                key: 'location',
                value: this.customer.id,
            } ]);

            this.customerFilter.setValue(null, { emitEvent: true });
            this.from = this.searchParamsService.get('from', 'date') || DateTime.now().startOf('week');
            this.to = this.searchParamsService.get('to', 'date') || DateTime.now().endOf('week');

            this.getData();
        });
    }

    private destroySubject = new Subject<boolean>();
    protected from: DateTime | undefined = undefined;
    protected to: DateTime | undefined = undefined;

    customerFilter = new FormControl<string | null>(null);
    resolvedCustomers: Customer[] = [];
    filteredCustomers: Observable<Customer[]>;
    customer?: Customer;
    showEmptyRows = false;
    paidTimeSegmentTypes: PaidTimeSegmentTypeMenuItem[];
    selectedTypes: PaidTimeSegmentTypeKey[];
    overview?: PaidTimeOverview;
    fetching = true;
    headerDates: HeaderDate[] = [];
    dates: DateTime[] = [];
    showWeekNr = true;

    constructor() {
        this.paidTimeSegmentTypes = Object.values(PaidTimeSegmentTypes)
            .filter((type) => type.interactive)
            .map((type) => {
                return {
                    key: type.key,
                    name: type.name,
                    selected: true,
                };
            });
        this.selectedTypes = this.paidTimeSegmentTypes.map((type) => type.key);
        this.filteredCustomers= this.customerFilter.valueChanges.pipe(
            startWith(''),
            map((value) => {
                if (!value) {
                    return this.resolvedCustomers;
                }

                return this.resolvedCustomers.filter((customer) => customer.name.toLowerCase().includes(value.toLowerCase()));
            }),
        );
    }

    ngAfterViewInit() {
        this.initScroll();
    }

    ngOnDestroy() {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    initScroll() {
        const target = this.gridContainer().nativeElement;
        if (!target) {
            return;
        }

        fromEvent(target, 'scroll').pipe(
            takeUntil(this.destroySubject),
            debounceTime(1000),
            tap(() => {
                const stickyEmployeeWidth = target.querySelector('eaw-paid-time-overview-employee')?.getBoundingClientRect().width || 0;
                const scrollLeft = target.scrollLeft;
                const stickyLeft = stickyEmployeeWidth + scrollLeft + 8;

                const segments = Array.from(target.querySelectorAll('[data-type="vacation"], [data-type="dayAbsence"]')) as HTMLDivElement[];
                segments.forEach((segment) => {
                    segment.style.setProperty('--content-offset', `${Math.max(0, stickyLeft - segment.offsetLeft)}px`);
                });
            }),
        ).subscribe();
    }

    getDates(from: DateTime, to: DateTime, holidays: Holiday[]) {
        const dates: HeaderDate[] = [];
        const numDays = to.startOf('day').diff(from.startOf('day'), 'day').days;

        for (let i = 0; i <= numDays; i++) {
            const date = from.plus({ day: i });
            dates.push({
                date,
                isHoliday: holidays.some((holiday) => holiday.date.dateTime.hasSame(date, 'day')),
            });
        }

        return dates;
    }

    updateInterval(interval: { from: DateTime, to: DateTime }) {
        this.from = interval.from;
        this.to = interval.to;

        this.getData();
    }

    getData() {
        const customerId = this.customer?.id;
        if (!customerId) {
            return;
        }

        const from = this.from?.startOf('day');
        const to = this.to?.startOf('day');
        if (!from || !to) {
            return;
        }

        // Show week number as long as interval is a week or less
        this.showWeekNr = to.diff(from, 'week').weeks <= 1;

        // Abort if interval is too large
        if (to.diff(from).as('months') > 1) {
            void this.snackBarService.t('INTERVAL_TOO_LARGE_MONTH', 'general', { count: 1 });
            return;
        }

        this.searchParamsService.set([
            {
                key: 'from',
                value: from,
                type: 'date',
            },
            {
                key: 'to',
                value: to,
                type: 'date',
            },
        ]);

        this.fetching = true;
        forkJoin([
            this.paidTimeService.getOverview(customerId, from, to),
            this.holidayService.getForCustomer(customerId, from, to) ,
        ]).subscribe(([ overview, holidays ]) => {
            this.fetching = false;

            overview.employees = sort(overview.employees, this.current.languageTag, [ (e) => e.name ], [ 'asc' ]);
            this.headerDates = this.getDates(from, to, holidays.data);
            this.dates = this.headerDates.map((headerDate) => headerDate.date);
            this.overview = overview;

            this.elementRef.nativeElement.style.setProperty('--grid-template-columns', this.headerDates.length);
            this.elementRef.nativeElement.style.setProperty('--grid-template-rows', overview.employees.length);
        });
    }

    updateShowEmptyRows() {
        this.showEmptyRows = !this.showEmptyRows;
    }

    setSelectedSegments(change: MatSelectionListChange) {
        this.selectedTypes = change.source.selectedOptions.selected.map((option) => (option.value as PaidTimeSegmentTypeMenuItem).key);
    }

    setCustomer(change: MatSelectionListChange) {
        const option = change.options[0];
        const customer = option ? option.value as Customer : undefined;

        if (this.customer?.id === customer?.id) {
            return;
        }

        if (!customer) {
            return;
        }

        this.customer = customer;
        this.searchParamsService.set([ {
            key: 'location',
            value: customer.id,
        } ]);
        this.customerFilter.setValue(null, { emitEvent: true });
        this.getData();
    }
}
