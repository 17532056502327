import { module } from 'angular';

class ContractRequalificationWidgetContainer {
    mini: any;

    loaded() {
        this.mini.loading(false);
        this.mini.dialogComponents = [ {
            name: 'contractRequalificationInfoContainer',
            label: 'widgets:EMPLOYEE_ALERTS',
        } ];
    }
}

module('eaw.dashboard').component('contractRequalificationWidgetContainer', {
    template: `
    <contract-requalification-downgrade (loaded)="$widget.loaded()"></contract-requalification-downgrade>
    `,
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$widget',
    controller: ContractRequalificationWidgetContainer,
});
