import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { GroupMembership, GroupMembershipResponse } from '../../shared/models/group-membership';

export type EmployeeGroupMembershipResponse = GroupMembershipResponse<EmployeeResponse>;

export class EmployeeGroupMembership extends GroupMembership<Employee, EmployeeResponse> {
    constructor(data: EmployeeGroupMembershipResponse) {
        super(data, Employee);
    }
}
