<div class="tw-p-10 tw-flex tw-flex-col tw-justify-around">
    <div *ngIf="loading">
        <mat-progress-spinner diameter="24" class="tw-ml-8 tw-mt-8" strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
    </div>
    <div *ngIf="!loading">
        <button mat-icon-button (click)="updateDays()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="!loading" class="tw-p-8">
    <mat-form-field appearance="outline" class="sorting-select">
        <mat-label>{{ 'SORTING' | translate: 'scheduling' | async }}</mat-label>
        <mat-select [(value)]="this.sort" (selectionChange)="sortChange()">
            <mat-option *ngFor="let sort of sorts" [value]="sort">{{ sort.label | translateSync: sort.namespace }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-accordion>
        <mat-expansion-panel *ngFor="let employee of schedule.employees">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ showNicknames() ? (employee.nickname || employee.name) : employee.name }}
                </mat-panel-title>
                <mat-panel-description class="tw-justify-end" *ngIf="employee.stats">
                    {{ (employee.stats.hours_difference_with_contract | eawNumber: 2: {signDisplay: 'exceptZero'}) }}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <table *ngIf="employee.stats">
                <tr>
                    <td></td>
                    <td>
                        <div class="tw-flex tw-flex-row tw-items-center">
                            <span>{{ 'WEEK' | translate | async }}</span>
                            <mat-icon [matTooltip]="('DATA_CHOSEN_WEEK' | translate: 'scheduling' | async) || ''" class="s14 tw-ml-4">info</mat-icon>
                        </div>
                    </td>
                    <td>
                        <div class="tw-flex tw-flex-row tw-items-center">
                            <span>{{ 'ACCUMULATED' | translate | async }}</span>
                            <mat-icon [matTooltip]="('DATA_ACC_SCHEDULE' | translate: 'scheduling' | async) || ''" class="s14 tw-ml-4">info</mat-icon>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>{{ 'HOURS_PLANNED_AT_LOCATION' | translate: 'scheduling' | async }}</td>
                    <td>{{ employee.stats.hours_planned_at_location | eawNumber:2 }}</td>
                    <td>{{ employee.stats.accumulated_hours_planned_at_location | eawNumber:2 }}</td>
                </tr>
                <tr>
                    <td>{{ 'HOURS_PLANNED_AT_OTHER_LOCATION' | translate: 'scheduling' | async }}</td>
                    <td>{{ employee.stats.hours_planned_outside_location | eawNumber:2 }}</td>
                    <td>{{ employee.stats.accumulated_hours_planned_outside_location | eawNumber:2 }}</td>
                </tr>
                <tr>
                    <td>{{ 'CONTRACT' | translate | async }}</td>
                    <td>{{ employee.stats.hours_per_week_from_contract | eawNumber:2 }}</td>
                    <td>{{ employee.stats.accumulated_hours_per_week_from_contract | eawNumber:2 }}</td>
                </tr>
                <tr>
                    <td>{{ 'CONTRACT_DIFF' | translate: 'scheduling' | async }}</td>
                    <td>{{ (employee.stats.hours_difference_with_contract | eawNumber:2: {signDisplay: 'exceptZero'}) }}</td>
                    <td>{{ (employee.stats.accumulated_hours_difference_with_contract | eawNumber:2: {signDisplay: 'exceptZero'}) }}</td>
                </tr>
                <tr *ngIf="sundayCheckAvailable">
                    <td>{{ 'LAST_SUNDAY' | translate: 'scheduling' | async }}</td>
                    <td>
                        <span *ngIf="employee.stats.worked_last_sunday">{{ 'YES' | translate | async }}</span>
                        <span *ngIf="!employee.stats.worked_last_sunday">{{ 'NO' | translate | async }}</span>
                    </td>
                </tr>
                <tr *ngIf="averageDaysCheckAvailable">
                    <td>{{ 'AVERAGE_DAYS_WORKED_PER_WEEK' | translate: 'scheduling' | async }}</td>
                    <td *ngIf="employee.stats.average_days_worked">{{ (employee.stats.average_days_worked | eawNumber:2) }}</td>
                </tr>
            </table>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-card class="total-table">
        <mat-card-content>
            <table>
                <tr>
                    <td>{{ 'ACCUMULATED_TOTAL' | translate | async }}</td>
                    <td>{{ (currentWeek ? currentWeek.total_accumulated : 0) | eawNumber:2 }}</td>
                </tr>
                <tr>
                    <td>{{ 'TOTAL_WEEK' | translate: 'scheduling' | async }}</td>
                    <td>{{ (currentWeek ? currentWeek.total_week_hours : 0) | eawNumber:2 }}</td>
                </tr>
                <tr>
                    <td>{{ 'DIFFERENCE_TOTAL' | translate: 'scheduling' | async }}</td>
                    <td>{{ ((currentWeek ? currentWeek.difference_total : 0) | eawNumber:2: {signDisplay: 'exceptZero'}) }}</td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>
