// @ts-nocheck
import { debounce } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
const template1 = `<md-card id="chart-card">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title">Active users</span>
        </md-card-header-text>

        <md-select ng-model="$ctrl.period" ng-change="$ctrl.getStats()">
            <md-option ng-repeat="period in ::$ctrl.periods track by $index" ng-value="period">
                <span ng-bind="period.value"></span>
            </md-option>
        </md-select>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <div id="user-stats-container"></div>
    </md-card-content>
</md-card>
`;
import { EawChart } from '../../../../shared/angularjs/modules/misc/services/eaw-chart';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
module('eaw.admin').component('eawAdminStatsUserStats', {
    template: template1,
    controller: [ 'EawResource', 'UrlParams', function(EawResource, UrlParams) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.periods = {
                hour: {
                    value: 'hour',
                    trend: 24,
                },
                day: {
                    value: 'day',
                    trend: 7,
                },
                week: {
                    value: 'week',
                    trend: 4,
                },
                month: {
                    value: 'month',
                    trend: 3,
                },
                year: {
                    value: 'year',
                    trend: 1,
                },
            };
            ctrl.period = ctrl.periods[UrlParams.get('period')] || ctrl.periods.day;
            ctrl.getStats();
        };
        ctrl.setZoom = () => {
            const from = ctrl.zoomInterval.from?.unix();
            const to = ctrl.zoomInterval.to?.unix();
            if (from && to) {
                ctrl.chart.xAxis[0].setExtremes(from * 1000, to * 1000);
            }
        };
        ctrl.setSubtitle = (from, to) => {
            ctrl.chart.setSubtitle({
                text: `From ${from.format('lll')} to ${to.format('lll')}`,
            });
        };
        ctrl.getPeakPlotline = (peak) => {
            return {
                id: 'peak',
                value: peak,
                color: 'green',
                dashStyle: 'shortdash',
                width: 2,
                label: {
                    text: `Peak users (${peak})`,
                },
            };
        };
        ctrl.getActiveData = (data) => data.map((d) => [
            +d.x,
            d.active_users,
        ]);
        ctrl.getTrendData = (data) => data.map((d) => [
            +d.x,
            d.lastXTrend,
        ]);
        ctrl.createdAtDate = (created) => new Date(Date.UTC(created.substring(0, 4), parseInt(created.substring(5, 7)) - 1, created.substring(8, 10), created.substring(11, 13), created.substring(14, 16), created.substring(17, 19)));
        ctrl.getTrendName = () => `Last ${ctrl.period.trend} trend`;
        ctrl.getStats = () => {
            UrlParams.set('period', ctrl.period.value);
            EawResource.create('/users/stats').get({
                from: moment().year(2017).startOf('y'),
                period: ctrl.period.value,
                per_page: 99999,
                order_by: 'created_at',
                direction: 'asc',
            }).$promise.then((resp) => {
                let peak = 0;
                let lastXTrend = [];
                const data = resp.data.map((d) => {
                    d.x = ctrl.createdAtDate(d.created_at);
                    // Peak
                    if (d.active_users > peak) {
                        peak = d.active_users;
                    }
                    // Last X trend
                    lastXTrend.push(d.active_users);
                    lastXTrend = lastXTrend.slice(ctrl.period.trend * -1);
                    d.lastXTrend = lastXTrend.reduce((sum, val) => sum + val, 0) / ctrl.period.trend;
                    return d;
                });
                if (ctrl.chart) {
                    ctrl.chart.series[0].setData(ctrl.getActiveData(data));
                    ctrl.chart.series[1].setData(ctrl.getTrendData(data));
                    ctrl.chart.series[1].setName(ctrl.getTrendName());
                    ctrl.chart.yAxis[0].removePlotLine('peak');
                    ctrl.chart.yAxis[0].addPlotLine(ctrl.getPeakPlotline(peak));
                    return;
                }
                const options = {
                    rangeSelector: {
                        enabled: false,
                    },
                    title: {
                        text: 'User stats',
                    },
                    subtitle: {
                        text: 'from to',
                    },
                    yAxis: {
                        plotLines: [
                            ctrl.getPeakPlotline(peak),
                        ],
                    },
                    xAxis: {
                        events: {
                            setExtremes: debounce((evt) => {
                                ctrl.setSubtitle(moment(evt.min), moment(evt.max));
                            }, 1000),
                        },
                    },
                    tooltip: {
                        formatter() {
                            const date = new Date(this.x).toLocaleString(CurrentOld.user.languageTag, {
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                weekday: 'short',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                timeZoneName: 'short',
                            });
                            const formatter = new Intl.NumberFormat(CurrentOld.user.languageTag, {
                                maximumFractionDigits: 0,
                            });
                            const active = this.points[0];
                            const last7 = this.points[1];
                            return `
                                <table>
                                <tr>
                                    <td colspan="2">${date}</td>
                                </tr>
                                <tr>
                                    <td><span style="color: ${active.color}">⬤</span> ${active.series.name}</td>
                                    <td style="text-align: right">${formatter.format(active.y)}</td>
                                </tr>
                                <tr>
                                    <td><span style="color: ${last7.color}">⬤</span> ${last7.series.name}</td>
                                    <td style="text-align: right">${formatter.format(last7.y)}</td>
                                </tr>
                                </table>
                            `;
                        },
                        shared: true,
                        useHTML: true,
                    },
                    series: [
                        {
                            name: 'Active users',
                            data: ctrl.getActiveData(data),
                        },
                        {
                            name: ctrl.getTrendName(),
                            data: ctrl.getTrendData(data),
                        },
                    ],
                };
                new EawChart('user-stats-container', options, true).getChart().then((chart) => {
                    ctrl.chart = chart;
                    ctrl.setSubtitle(moment(chart.xAxis[0].min), moment(chart.xAxis[0].max));
                });
            });
        };
    } ],
});
