export enum LearningModuleModule {
    AiBudgeting = '255c551e-2bbb-4239-b9b1-57160d3cb429',
    Absence = '953cd1e4-c576-474d-8a64-d3233f60a5eb',
    Availability = '5a4584fa-4861-418e-907e-637a6bf49c02',
    ChainOps = '8afbdd13-f920-484e-aace-dc1ee2683430',
    Dashboard = '64a4dcf7-291a-410e-974d-98bcd1344a6e',
    HumanResources = '304c357a-2c5b-4a1a-8735-32a5b59c4121',
    KeyPerformanceIndicators = '057cfb18-2168-4e41-8366-d00c80576921',
    McdPeopleKpi = '7818d978-7da6-4e43-9828-2b170ef8434b',
    Messaging = '4e937fa5-eed0-462a-a281-fdb07cc61987',
    MyCompany = '8bbfb2c7-3f0d-4637-8eed-ae05bdf81201',
    Payroll = '947f5703-a2f8-4249-bed0-3ff0eed82e53',
    Profile = '7a544c8a-986d-4bd6-89ae-0004e3b76acf',
    Reports = 'aa1c36c2-73de-4442-a702-764c75d9111b',
    Scheduling = 'f4a8205f-f759-4e7a-9584-206befb51dfe',
}
