import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { TosService } from '../../http/tos.service';
import { Tos } from '../../models/tos';
import { LoginService } from '../../services/login.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgIf } from '@angular/common';

export interface TosDialogData extends DialogData {
    required: boolean;
    userId: number;
}

@Component({
    selector: 'eaw-tos-dialog',
    templateUrl: './tos-dialog.component.html',
    styleUrl: './tos-dialog.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class TosDialogComponent extends DialogComponent implements OnInit {
    tos?: Tos;
    accepting = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: TosDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<DialogComponent>,
        @Inject(TosService) private tosService: TosService,
        @Inject(LoginService) private loginService: LoginService,
    ) {
        data.size = DialogSize.Large;
        super(dialogRef, data);
    }

    ngOnInit() {
        this.tosService.get().subscribe((tos) => {
            this.tos = tos;
        });
    }

    accept() {
        this.accepting = true;
        this.tosService.accept().subscribe(() => {
            this.dialogRef.close();
        });
    }

    decline() {
        void this.loginService.logout(this.data.userId, { message: Promise.resolve('Terms of service declined') });
    }
}
