import { ChangeDetectionStrategy, Component, computed, effect, EventEmitter, inject, Input, OnChanges, OnDestroy, Output, Signal, signal, SimpleChanges } from '@angular/core';
import { WeatherService } from '../../../weather/services/weather.service';
import { WidgetComponent } from '../../classes/widget-component';
import { WeatherForecast } from '../../../weather/classes/weather-forecast';
import { catchError, EMPTY, interval, Subscription, switchMap, tap } from 'rxjs';
import { DateTime } from 'luxon';
import { WeatherIconComponent } from '../../../weather/components/weather-icon/weather-icon.component';
import { TemperatureComponent } from '../../../weather/components/temperature/temperature.component';
import { WeatherWidgetSettings } from './weather-widget-settings-dialog/weather-widget-settings-dialog.component';
import { Widget } from '../../classes/widget';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MetNoCreditComponent } from '../../../weather/components/met-no-credit/met-no-credit.component';
import { WeatherForecastTime } from '../../../weather/classes/weather-forecast-time';

@Component({
    selector: 'eaw-weather-widget',
    standalone: true,
    imports: [
        WeatherIconComponent,
        TemperatureComponent,
        TranslatePipe,
        AsyncPipe,
        MetNoCreditComponent,
    ],
    templateUrl: './mini-weather-widget.component.html',
    styleUrl: './mini-weather-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniWeatherWidgetComponent extends WidgetComponent implements OnDestroy, OnChanges {
    private readonly weatherService = inject(WeatherService);

    // Using annotation so the downgrade works
    @Input() widget!: Widget<WeatherWidgetSettings>;
    @Input() darkBackground?: boolean;
    @Output() loaded = new EventEmitter<boolean>();

    protected _widget = signal<Widget<WeatherWidgetSettings> | null>(null);
    protected _darkBackground = signal(false);
    protected forecastTime: Signal<WeatherForecastTime | undefined>;
    protected location: Signal<string>;

    /** The forecast for the current customer, false if something went wrong fetching the weather */
    protected forecast = signal<WeatherForecast | undefined | false>(undefined);
    private updatedAt = signal<DateTime>(DateTime.now());
    private isFahrenheit = computed(() => !!this._widget()?.getSetting('useFahrenheit'));

    private weatherSubscription?: Subscription;

    constructor() {
        super();

        this.forecastTime = computed(() => {
            const forecast = this.forecast();

            if (forecast === false) {
                return undefined;
            }

            return forecast?.forTime(this.updatedAt(), this.isFahrenheit());
        });

        // This makes sure that even if the user keeps the widget open for a long time, the forecast will be updated (at load time + ~every 10 days...)
        effect(() => {
            const customerId = this._widget()?.info.customer.id;
            if (!this.forecastTime() && customerId) {
                this.getWeatherForecast(customerId);
            }
        });

        this.location = computed(() => this._widget()?.info.customer.city || this._widget()?.info.customer.shortName ||'');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['widget']) {
            this._widget.set(changes['widget'].currentValue);
        }

        if (changes['darkBackground']) {
            this._darkBackground.set(!!changes['darkBackground'].currentValue);
        }
    }

    private getWeatherForecast(customerId: number) {
        this.weatherSubscription?.unsubscribe();
        this.weatherSubscription = this.weatherService.getForecast(customerId).pipe(
            catchError(() => {
                this.forecast.set(false);
                this.setLoading(false);
                this.loaded.emit(false);
                return EMPTY;
            }),
            tap((forecast) => {
                this.forecast.set(forecast);
                this.updatedAt.set(DateTime.now());
                this.setLoading(false);
                this.loaded.emit(true);
            }),
            switchMap(() => {
                return interval(1000 * 60 * 5);
            }),
            tap(() => {
                this.updatedAt.set(DateTime.now());
            }),
        ).subscribe();
    }

    ngOnDestroy() {
        this.weatherSubscription?.unsubscribe();
    }
}
