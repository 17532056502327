// @ts-nocheck
import { t } from 'i18next';
import { isObject } from 'lodash-es';
import { module } from 'angular';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="$settings.widget.i18name | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content" ng-if="$settings.gettingData">
            <md-progress-circular class="md-progress-center tw-mt-24"></md-progress-circular>
        </div>

        <!-- has to be IF becuase of async data -->
        <div class="md-dialog-content" ng-if="!$settings.gettingData" layout="column">
            <md-input-container>
                <label ng-i18next="MODE"></label>
                <md-select ng-model="$settings.settings.mode">
                    <md-option ng-repeat="option in $settings.modes track by $index" ng-value="option.value">
                        {{option.text}}
                    </md-option>
                </md-select>
            </md-input-container>

            <md-input-container>
                <label ng-i18next="widgets:PERIOD"></label>
                <md-select ng-model="$settings.settings.range">
                    <md-option ng-repeat="option in $settings.ranges track by $index" ng-value="option.value">
                        {{option.text}}
                    </md-option>
                </md-select>
            </md-input-container>

            <eaw-select-multiple-search
                    ng-model="$settings.settings.customers"
                    i18n-label="'LOCATION_plural'"
                    items="$settings.customers"
                    item-key="id"
                    display-key="'name'">
            </eaw-select-multiple-search>

            <div>
                <md-button ng-click="$settings.selectAllLocations()" ng-i18next="SELECT_ALL"></md-button>
                <md-button ng-click="$settings.deselectAllLocations()" ng-i18next="SELECT_NONE"></md-button>
            </div>

            <eaw-select-multiple-search
                    ng-model="$settings.settings.kpiTypes"
                    i18n-label="'kpi:KPI_TYPE_plural'"
                    items="$settings.kpiTypes"
                    item-key="id"
                    display-key="'name'">
            </eaw-select-multiple-search>

            <div>
                <md-button ng-click="$settings.selectAllTypes()" ng-i18next="SELECT_ALL"></md-button>
                <md-button ng-click="$settings.deselectAllTypes()" ng-i18next="SELECT_NONE"></md-button>
            </div>

            <div layout="column">
                <span md-colors="{color: 'red'}" ng-if="!$settings.settings.customers.length" ng-i18next="widgets:MIN_ONE_LOCATION"></span>
                <span md-colors="{color: 'red'}" ng-if="!$settings.settings.kpiTypes.length" ng-i18next="widgets:MIN_ONE_KPI_TYPE"></span>
            </div>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-click="$settings.submit()" ng-i18next="SUBMIT" ng-disabled="!$settings.settings.customers.length || !$settings.settings.kpiTypes.length"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.dashboard').factory('defaultKpiSettings', [ '$mdDialog', function($mdDialog) {
    return function defaultKpiSettings(widget) {
        return $mdDialog.show({
            template: template1,
            controllerAs: '$settings',
            controller: 'defaultKpiSettingsCtrl',
            bindToController: true,
            multiple: true,
            locals: {
                widget,
            },
        });
    };
} ]);
module('eaw.dashboard').controller('defaultKpiSettingsCtrl', [ '$mdDialog', 'kpiTypeService', function($mdDialog, kpiTypeService) {
    const ctrl = this;
    ctrl.$onInit = () => {
        ctrl.cancel = $mdDialog.cancel;
        ctrl.customers = Object.values(CurrentOld.customers);
        ctrl.ranges = [
            {
                text: t('widgets:YESTERDAY'),
                value: 'yesterday',
            },
            {
                text: t('THIS_WEEK'),
                value: 'week',
            },
            {
                text: t('widgets:THIS_MONTH'),
                value: 'this_month',
            },
            {
                text: t('widgets:LAST_WEEK'),
                value: 'last_week',
            },
            {
                text: t('widgets:LAST_MONTH'),
                value: 'last_month',
            },
        ];
        ctrl.modes = [
            {
                text: t('widgets:TABLE'),
                value: 'table',
            },
            {
                text: t('widgets:COLUMN_CHART'),
                value: 'chart',
            },
        ];
        ctrl.settings = {
            range: 'yesterday',
            mode: 'table',
            ...ctrl.widget.settings,
        };
        if (!Object.keys(ctrl.settings.customers || []).length) {
            ctrl.selectAllLocations();
        }
        ctrl.getData();
    };
    ctrl.getData = () => {
        ctrl.gettingData = true;
        kpiTypeService.get().then((data) => {
            ctrl.kpiTypes = data.map((type) => {
                return {
                    ...type,
                    name: t(type.name),
                };
            });
            if (!Object.keys(ctrl.settings.kpiTypes || []).length) {
                ctrl.selectAllTypes();
            }
        }).finally(() => ctrl.gettingData = false);
    };
    ctrl.selectAllLocations = () => {
        ctrl.settings.customers = ctrl.customers.map((c) => c.id);
    };
    ctrl.deselectAllLocations = () => {
        ctrl.settings.customers = [];
    };
    ctrl.selectAllTypes = () => {
        ctrl.settings.kpiTypes = ctrl.kpiTypes.map((k) => k.id);
    };
    ctrl.deselectAllTypes = () => {
        ctrl.settings.kpiTypes = [];
    };
    ctrl.formatObjectIds = (selected, available) => {
        if (selected.length === available.length) {
            return;
        }
        return selected.map((x) => isObject(x) ? x.id : x);
    };
    ctrl.submit = () => {
        $mdDialog.hide({
            ...ctrl.settings,
            kpiTypes: ctrl.formatObjectIds(ctrl.settings.kpiTypes, ctrl.kpiTypes),
            customers: ctrl.formatObjectIds(ctrl.settings.customers, ctrl.customers),
        });
    };
} ]);
