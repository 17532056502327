// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { t } from 'i18next';

module('eaw.time').component('autoStartPreview', {
    template: `<div layout="column">
    <div layout-margin>
        <label for="responsible" ng-i18next="RESPONSIBLE"></label>
        <p id="responsible" ng-bind="$apCtrl.user.name"></p>
    </div>
    <div layout="row" layout-wrap>
        <section flex flex-gt-sm="25" ng-if="$apCtrl.autoStart.type == 'weekly' && week.length" ng-repeat="week in $apCtrl.weeks track by $index">
            <md-subheader>
                <span ng-i18next="[i18next]({number: $index + 1})WEEK_N"></span>
            </md-subheader>
            <auto-start-preview-list days="week"></auto-start-preview-list>
        </section>

        <section ng-if="$apCtrl.autoStart.type == 'monthly'" flex>
            <md-subheader>
                <span ng-if="$apCtrl.autoStart.skip == 0" ng-i18next="EVERY_MONTH"></span>
                <span ng-if="$apCtrl.autoStart.skip == 1" ng-i18next="EVERY_OTHER_MONTH"></span>
                <span ng-if="$apCtrl.autoStart.skip == 2" ng-i18next="EVERY_THIRD_MONTH"></span>
            </md-subheader>
            <auto-start-preview-list days="$apCtrl.days"></auto-start-preview-list>
        </section>
    </div>
</div>
`,
    controllerAs: '$apCtrl',
    controller: 'AutoStartPreviewCtrl',
    bindings: {
        autoStart: '<', // has attributes data and type and user_id (?)
        customerId: '<',
    },
});

module('eaw.time').controller('AutoStartPreviewCtrl', [ 'CustomerService', function AutoStartPreviewController(CustomerService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.user = CustomerService.getUserById(ctrl.customerId, ctrl.autoStart.user_id);
    };

    ctrl.$onChanges = () => {
        const m = moment().startOf('y');

        if (ctrl.autoStart.type == 'weekly') {
            const weeks = new Array(4).fill([]);

            Object.entries(ctrl.autoStart.data).forEach(([ day, time ]) => {
                const dIndex = parseInt(day);
                m.isoWeekday(dIndex);
                const d = {
                    time: moment(time, 'HH:mm'),
                    name: m.format('dddd'),
                };
                weeks[dIndex <= 7 ? 0 : (dIndex <= 14 ? 1 : (dIndex <= 21 ? 2 : 3))].push(d);
            });
            ctrl.weeks = weeks;
        } else if (ctrl.autoStart.type == 'monthly') {
            const temp = Object.entries(ctrl.autoStart.data).map(([ day, time ]) => {
                const d = parseInt(day);
                return {
                    day: d,
                    time: moment(time, 'HH:mm'),
                    name: t('DAY_OF_MONTH', { day: m.date(d).format('Do') }),
                };
            });

            ctrl.days = temp.sort((a,b) => a.day - b.day);
        }
    };
} ]);
