<eaw-page-header [fabButton]="fabButton" [useBigFilter]="true" [bigFilterToggled]="true">
    <span title>{{ 'EMPLOYEE_plural' | translate | async }}</span>
    <span subtitle>{{ (total | eawNumber:0) }}</span>

    <form bigFilter class="tw-grid sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-8" [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'SEARCH' | translate | async }}</mat-label>
            <input matInput formControlName="filter" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'COLUMNS' | translate | async }}</mat-label>
            <mat-select formControlName="tableColumns" multiple (closed)="updateColumns()">
                <mat-option *ngFor="let column of allColumns" [value]="column">{{ column.translation.key | translateSync:column.translation.ns }}</mat-option>
            </mat-select>
            <mat-hint>{{ 'ONLY_TABLE' | translate:'company' | async }}</mat-hint>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'SHOW' | translate:'company' | async }}</mat-label>
            <mat-select formControlName="showOption">
                <mat-option *ngFor="let option of showOptions" [value]="option">{{ option.translation.key | translateSync:option.translation.ns }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        <eaw-data-table
            #table
            [columns]="columns"
            [request]="request"
            [rowClasses]="rowClasses"
            [goTo]="{state: 'eaw/app/company/employees/view/information', params: [{stateKey: 'emp', itemKey: 'id'}]}"
            initialSortBy="id"
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
