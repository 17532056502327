import { AbsenceReleaseShifts, AbsenceSpan } from '../types/types';
import { Inject, Injectable } from '@angular/core';
import { Absence, AbsenceResponse } from '../models/absence';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { DateTime } from 'luxon';
import { Observable, switchMap } from 'rxjs';
import { HttpClient, HttpContext } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { TranslateService } from '../../shared/services/translate.service';
import { FileUploadService } from '../../shared/services/file-upload.service';

export interface GetAllAbsencesOptions extends PaginationOptions {
    'type_ids[]'?: number[];
    from?: DateTime;
    to?: DateTime | null;
    handled?: boolean;
    approved?: boolean;
    span?: AbsenceSpan;
}

export interface GetAllCustomerAbsencesOptions extends GetAllAbsencesOptions {
    only_direct_subordinates?: boolean;
}

export interface CreateAbsenceBody {
    type_id: number;
    from: DateTime;
    to: DateTime;
    /* Override the automatic calculation of the duration */
    length?: number | null;
    comment?: string | null;
    /* Value between 0 and 1 */
    grade?: number;
    approved?: boolean;
    release_shifts?: AbsenceReleaseShifts;
    with?: string[];
    schedule_published_warning_accepted?: boolean;
}

@Injectable({
    providedIn: 'any',
})
export class AbsenceService {
    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(FileUploadService) private fileUploadService: FileUploadService,
    ) { }

    getAllForCustomer(customerId: number, options?: GetAllCustomerAbsencesOptions): Observable<ArrayPaginatedResponse<Absence>> {
        return this.http.get<ArrayPaginatedResponse<AbsenceResponse>>(`/customers/${customerId}/absences`, {
            params: formatHttpParams({
                ...options,
                from: options?.from,
                to: options?.to,
            }),
        }).pipe(
            classifyArrayPaginatedResponse(Absence),
            switchMap(async (absences) => {
                for (const a of absences.data) {
                    if (a.type?.name) {
                        a.type.setTranslatedName(await this.translate.t(a.type.name, 'absence_types'));
                    }
                }
                return absences;
            }),
        );
    }

    getAllForEmployee(customerId: number, employeeId: number, options?: GetAllAbsencesOptions) {
        return this.http.get<ArrayPaginatedResponse<AbsenceResponse>>(`/customers/${customerId}/employees/${employeeId}/absences`, {
            params: formatHttpParams({
                ...options,
                from: options?.from,
                to: options?.to,
            }),
        }).pipe(
            classifyArrayPaginatedResponse(Absence),
            // Add customer id to absences since backend does not
            switchMap(async (absences) => {
                for (const a of absences.data) {
                    a.customerId = customerId;
                    if (a.type?.name) {
                        a.type.setTranslatedName(await this.translate.t(a.type.name, 'absence_types'));
                    }
                }
                return absences;
            }),
        );
    }

    create(customerId: number, employeeId: number, body: CreateAbsenceBody, context?: HttpContext) {
        body.comment ||= undefined; // Handle empty string
        return this.http.post<AbsenceResponse>(
            `customers/${customerId}/employees/${employeeId}/absences`,
            { schedule_published_warning_accepted: true, ...body },
            { context },
        ).pipe(classifyItem(Absence));
    }

    update(customerId: number, employeeId: number, absenceId: number, body: Partial<CreateAbsenceBody>, context?: HttpContext) {
        body.comment ||= undefined; // Handle empty string
        return this.http.put<AbsenceResponse>(
            `customers/${customerId}/employees/${employeeId}/absences/${absenceId}`,
            { schedule_published_warning_accepted: true, ...body },
            { context },
        ).pipe(classifyItem(Absence));
    }

    delete(customerId: number, employeeId: number, absenceId: number, comment?: string) {
        return this.http.delete<null>(`customers/${customerId}/employees/${employeeId}/absences/${absenceId}`, {
            body: {
                comment,
            },
        });
    }

    approve(customerId: number, employeeId: number, absenceId: number, releaseShifts: AbsenceReleaseShifts = 'keep', comment?: string): Observable<Absence> {
        return this.http.put<AbsenceResponse>(`customers/${customerId}/employees/${employeeId}/absences/${absenceId}`, {
            approved: true,
            comment: comment || undefined,
            release_shifts: releaseShifts,
            'with[]': [ 'type', 'approval' ],
        }).pipe(classifyItem(Absence));
    }

    decline(customerId: number, employeeId: number, absenceId: number, comment?: string): Observable<Absence> {
        return this.http.put<AbsenceResponse>(`customers/${customerId}/employees/${employeeId}/absences/${absenceId}`, {
            approved: false,
            comment: comment || undefined,
            'with[]': [ 'type', 'approval' ],
        }).pipe(classifyItem(Absence));
    }

    createAttachment(customerId: number, absenceId: number, options: { file: File }) {
        return this.fileUploadService.upload(`customers/${customerId}/absences/${absenceId}/attachments`, options as Record<string, any>);
    }
}
