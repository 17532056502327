// @ts-nocheck
import { module } from 'angular';
import { AvailableShiftsComponent } from '../../pages/available-shifts/available-shifts.component';
import { ShiftListComponent } from '../../pages/shift-list/shift-list.component';
import { currentResolver } from '../../../shared/resolvers/current.resolver';
import { createState } from '../../../shared/utils/create-state';
import { dependencyResolver } from '../../../shared/resolvers/dependency.resolver';
import { CustomFieldOld } from '../../../shared/angularjs/custom-field-old';
import { DataTableQueryParams } from '../../../data-table/data-table.component';
import { paramResolver } from '../../../shared/resolvers/param.resolver';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
import { StateProvider } from '@uirouter/angularjs';

module('eaw.scheduling.shifts').config([ '$stateProvider', ($stateProvider: StateProvider) => {
    $stateProvider.state(`eaw/app/scheduling/shifts`, {
        parent: `eaw/app/scheduling`,
        abstract: true,
        url: '/shifts',
        data: {
            breadcrumb: { key: 'SHIFTS', ns: 'navigation' },
        },
    });
    createState($stateProvider, {
        name: `eaw/app/scheduling/shifts/available_shifts`,
        parent: `eaw/app/scheduling/shifts`,
        url: '/available_shifts',
        views: {
            'content@': {
                component: AvailableShiftsComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('employeeId', 'employee', 'id'),
            currentResolver('user', 'user'),
        ],
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'AVAILABLE_SHIFTS', ns: 'navigation' },
            permissions: [ `customers.{customer}.shift_swaps.*.get` ],
        },
    });
    createState($stateProvider, {
        name: `eaw/app/scheduling/shifts/shifts`,
        parent: `eaw/app/scheduling/shifts`,
        url: '/list',
        views: {
            'content@': { component: ShiftListComponent },
        },
        data: {
            queryParams: [ ...DataTableQueryParams, 'employee', 'from', 'to' ],
            breadcrumb: { key: 'LIST' },
            permissions: [ `customers.{customer}.shifts.*.get` ],
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
    });

    createState($stateProvider, {
        name: 'eaw/app/scheduling/shifts/shift',
        parent: 'eaw/app/scheduling/shifts/shifts',
        url: '/:customer_id/:id',
        views: {
            'content@': 'shiftInfo',
        },
        data: {
            breadcrumb: { key: 'SHIFT' },
        },
        params: {
            customer_id: {
                type: 'string',
                value: '',
                squash: true,
            },
            id: {
                type: 'int',
            },
        },
        resolve: [
            paramResolver('id', 'shiftId'),
            dependencyResolver('customerId', [ '$stateParams' ], ($stateParams) => $stateParams.customer_id || CurrentOld.customer['id']),
            // Old custom fields are used here, so set them up
            dependencyResolver('customFieldsSetter', [ '$http', 'customerId' ], function($http: angular.IHttpService, customerId) {
                return CustomFieldOld.storeFields(customerId, $http);
            }),
        ],
    });
} ]);
