// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<div ng-repeat="shift in $next.shifts"
     class="next-shift-cal-time"
     layout="column"
     layout-align="start end">
    <md-tooltip ng-bind="shift.from.format('llll') + ' - ' + shift.to.format('llll')"></md-tooltip>
    <span ng-class="{required: !shift.sameBd}" ng-bind="shift.date"></span>
</div>

<div ng-if="!$next.shifts.length">
    <span ng-i18next="widgets:NO_UPCOMING_SHIFT"></span>
</div>
`;

module('eaw.dashboard').component('nextShiftWidget', {
    template: template1,
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$next',
    controller: [ 'ShiftFactory', function(ShiftFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.allRes = ShiftFactory.getAll({
                // Only people with employee get to see this
                employee_id: ctrl.mini.widget.employee.id,
                customer_id: ctrl.mini.widget.customer,
                per_page: 2,
                from: moment().startOf('hour'),
                order_by: 'from',
                direction: 'asc',
                with: [ 'schedule.customer' ],
            });

            return ctrl.allRes.$promise.then(ctrl.onLoaded).finally(() => {
                ctrl.mini.loading(false);
            });
        };

        ctrl.onLoaded = (resp) => {
            ctrl.shifts = resp.data.map((s) => {
                s.sameBd = s.from.isSame(s.business_date, 'd');
                s.date = s.from.calendar(null, {
                    sameElse: 'LL',
                });

                return s;
            });

            ctrl.mini.dialogComponents = [ {
                name: 'nextShiftWidgetList',
                label: 'scheduling:SHIFT_plural',
                bindings: {
                    shifts: ctrl.shifts,
                },
            } ];
        };

        ctrl.$onDestroy = () => {
            ctrl.allRes?.$cancelRequest?.();
        };
    } ],
});
