<eaw-dialog-header>
    <span title>{{ 'CREATE_SCHEDULE' | translate:'scheduling' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-8">
    <mat-form-field>
        <mat-label>{{ 'MODE' | translate | async }}</mat-label>
        <mat-select [(ngModel)]="mode" (selectionChange)="modeChange()" [disabled]="activeGroup.disabled">
            <mat-option value="year-month">{{ 'YEAR_AND_MONTH' | translateSync:'scheduling' }}</mat-option>
            <mat-option value="from-to">{{ 'FROM_AND_TO' | translateSync:'scheduling' }}</mat-option>
        </mat-select>
    </mat-form-field>

    <form [formGroup]="activeGroup" class="tw-grid tw-grid-cols-12 tw-gap-8">
        <mat-form-field class="tw-col-span-full">
            <mat-label>{{ 'NAME' | translate | async }}</mat-label>
            <input matInput formControlName="name" autocomplete="off">
        </mat-form-field>

        <mat-form-field class="tw-col-span-full" *ngIf="mode === 'year-month'">
            <mat-label>{{ 'YEAR' | translate | async }}</mat-label>
            <input matInput formControlName="year" autocomplete="off" type="number" step="1">
        </mat-form-field>

        <mat-form-field class="tw-col-span-full" *ngIf="mode === 'year-month'">
            <mat-label>{{ 'MONTH' | translate | async }}</mat-label>
            <mat-select formControlName="month">
                <mat-option *ngFor="let month of months" [value]="month.index">{{ month.month }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="tw-col-span-full" eawDatePickerOptions *ngIf="mode === 'from-to'">
            <mat-label>{{ 'DATE_RANGE' | translate | async }}</mat-label>
            <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
                <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async" autocomplete="off">
                <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async" autocomplete="off">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <div class="tw-flex tw-gap-4 tw-items-baseline tw-col-span-full">
            <mat-form-field class="standalone tw-grow">
                <mat-label>{{ 'TEMPLATE_plural' | translate:'scheduling' | async }}</mat-label>
                <mat-progress-spinner *ngIf="gettingAll" class="tw-mx-4" diameter="20" strokeWidth="2" matPrefix></mat-progress-spinner>
                <mat-chip-grid #chipList [disabled]="activeGroup.disabled">
                    <mat-chip-row *ngFor="let template of selectedTemplates" (removed)="remove(template)">
                        {{ template.name }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>

                    <input [placeholder]="('SEARCH' | translate | async) || ''"
                           #templateInput
                           [formControl]="templateSearch"
                           [matAutocomplete]="auto"
                           [matChipInputFor]="chipList">
                </mat-chip-grid>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let template of filteredTemplates | async" [value]="template">
                        {{ template.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <button [disabled]="gettingAll || activeGroup.disabled" class="tw-px-8" mat-button color="primary" (click)="addAll()">{{ 'ADD_ALL' | translate | async }}</button>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-raised-button [mat-dialog-close]>{{ 'CLOSE' | translate | async }}</button>
    <button mat-button mat-raised-button color="primary" [disabled]="activeGroup.invalid || activeGroup.disabled || !selectedTemplates.length" (click)="create()">{{ 'CREATE' | translate | async }}</button>
</mat-dialog-actions>
