<div id="inputs" role="group" [attr.aria-labelledby]="parentFormField?.getLabelId()" [attr.aria-describedby]="userAriaDescribedBy">

    <input #dateInput
           [placeholder]="shouldLabelFloat ? ('DATE' | translate | async) : ''"
           [matDatepicker]="$any(picker)"
           (focusout)="onFocusOut($event)"
           (blur)="setDateTime()"
           [min]="minDate"
           [max]="maxDate"
           (click)="dateInputClick()"
           (dateChange)="dateChange()"
           [formControl]="dateControl">

    <input #timeInput
           (click)="focusTime()"
           (focusin)="focusTime()"
           (focusout)="onFocusOut($event)"
           (blur)="setDateTime()"
           [placeholder]="shouldLabelFloat ? (defaultTime | DateTime:localeStringFormat) : ''"
           [formControl]="timeControl">

    <mat-datepicker-toggle [tabIndex]="-1" [for]="picker"></mat-datepicker-toggle>
</div>

<mat-datepicker #picker [restoreFocus]="false" [touchUi]="useTouchUi" [dateClass]="dateClass"></mat-datepicker>
