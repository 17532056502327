import { ApiResponse } from '../../shared/interfaces/api-response';
import { CustomField, CustomFieldResponse } from './custom-field';
import { CustomFieldMetadata } from '../typings/custom-field-metadata';
import { ApiModel } from '../../shared/enums/api-model';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export interface CustomFieldPivotResponse extends ApiResponse {
    created_at: string;
    custom_field?: CustomFieldResponse;
    custom_field_id: number;
    default: any
    deleted_at: string | null;
    has_interval: boolean
    id: number;
    metadata: CustomFieldMetadata;
    model: ApiModel;
    object_id: number;
    object_type: string
    required: boolean
    updated_at: string
    validator: string | null
}

export class CustomFieldPivot {
    readonly response: CustomFieldPivotResponse;

    createdAt: DateTime;
    customField?: CustomField;
    customFieldId: number;
    default: any;
    deletedAt: DateTime | null;
    hasInterval: boolean;
    // Formatted and pretty select options
    selectOptions: { value: string, translationKey: string }[] = [];
    priority?: number;
    id: number;
    metadata: CustomFieldMetadata;
    model: ApiModel;
    objectId: number;
    objectType: string;
    required: boolean;
    updatedAt: DateTime;
    validator: string | null;
    stringifiedMetadata = '';

    constructor(data: CustomFieldPivotResponse) {
        this.response = data;

        this.customFieldId = data.custom_field_id;
        this.default = data.default;
        this.hasInterval = data.has_interval;
        this.id = data.id;
        this.metadata = data.metadata;
        this.model = data.model;
        this.objectId = data.object_id;
        this.objectType = data.object_type;
        this.required = data.required;
        this.validator = data.validator;
        this.priority = data.metadata?.priority;

        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;

        this.customField = data.custom_field ? new CustomField(data.custom_field) : undefined;

        try {
            this.stringifiedMetadata = typeof data.metadata === 'object' && data.metadata != null ? JSON.stringify(data.metadata) : '';
        } catch (e) {
            console.error(e);
        }

        // Other functions
        this.setSelectOptions();
    }

    // Returns all select options as they are in the pivot's metadata
    getSelectOptions(): Record<string, string> {
        if (this.customField?.type !== 'select') {
            return {};
        }

        if (this.metadata == null || !this.metadata.options) {
            return {};
        }

        if (Array.isArray(this.metadata.options)) {
            return Object.entries(this.metadata.options).reduce((carry, [ key, value ]) => {
                carry[key] = value;
                return carry;
            }, {} as Record<string, string>);
        }

        return this.metadata.options;
    }

    clone(data: Partial<CustomFieldPivotResponse> = {}) {
        return new CustomFieldPivot({ ...this.response, ...data });
    }

    private setSelectOptions() {
        this.selectOptions = Object.entries(this.getSelectOptions()).map(([ key, value ]) => {
            return {
                value: key,
                translationKey: value,
            };
        });
    }

    static getCustomFieldPivotModels(): ApiModel[] {
        return [
            ApiModel.Absence,
            ApiModel.Contract,
            ApiModel.Comment,
            ApiModel.Customer,
            ApiModel.EmergencyContact,
            ApiModel.Employee,
            ApiModel.FamilyMember,
            ApiModel.HrFile,
            ApiModel.OffTime,
            ApiModel.PaidTime,
            ApiModel.Report,
            ApiModel.ReportRun,
            ApiModel.Schedule,
            ApiModel.Shift,
        ];
    }
}
