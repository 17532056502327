// @ts-nocheck
import { uniqueId } from '../../misc/services/easy-funcs.service';
import { module } from 'angular';

module('eaw.time').component('eawDateTimeInterval', {
    template: `<div layout="column" flex="100">
    <div layout="row" layout-wrap>
        <md-input-container class="error-spacer" flex="100" flex-gt-xs="50">
            <label ng-i18next="{{::$dti.fromLabel}}"></label>
            <eaw-date-time-picker ng-model="$dti.from"
                                  input-name="$dti.fromInputName"
                                  ng-required="$dti.fromRequired"
                                  ng-disabled="$dti.fromDisabled"
                                  ng-readonly="$dti.fromReadonly"
                                  min-date="$dti.minDate"
                                  max-date="$dti.maxDate"
                                  ng-change="$dti.updateFrom()">
            </eaw-date-time-picker>
        </md-input-container>

        <md-input-container class="error-spacer" flex="100" flex-gt-xs="50">
            <label ng-i18next="{{::$dti.toLabel}}"></label>
            <eaw-date-time-picker ng-model="$dti.to"
                                  input-name="$dti.toInputName"
                                  ng-required="$dti.toRequired"
                                  ng-disabled="$dti.toDisabled"
                                  ng-readonly="$dti.toReadonly"
                                  min-date="$dti.minDate"
                                  max-date="$dti.maxDate"
                                  ng-change="$dti.updateTo()">
            </eaw-date-time-picker>
        </md-input-container>
    </div>
</div>
`,
    controllerAs: '$dti',
    require: {
        ngModel: 'ngModel',
    },
    bindings: {
        fromLabel: '@?',
        toLabel: '@?',
        fromRequired: '<?',
        toRequired: '<?',
        fromDisabled: '<?',
        toDisabled: '<?',
        minDate: '<?',
        maxDate: '<?',
    },
    controller: [ '$attrs', function($attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.inputName = uniqueId('dti-');
            ctrl.ngModel.$name = ctrl.inputName;
            ctrl.fromLabel = ctrl.fromLabel || 'FROM';
            ctrl.toLabel = ctrl.toLabel || 'TO';

            $attrs.$observe('required', (value) => {
                ctrl.fromRequired = value;
                ctrl.toRequired = value;
            });

            $attrs.$observe('disabled', (value) => {
                ctrl.fromDisabled = value;
                ctrl.toDisabled = value;
            });

            $attrs.$observe('readonly', (value) => {
                ctrl.fromReadonly = value;
                ctrl.toReadonly = value;
            });

            ctrl.ngModel.$formatters.push(ctrl.formatModel);
        };

        ctrl.formatModel = (val) => {
            ctrl.from = val?.from?.clone?.();
            ctrl.to = val?.to?.clone?.();

            return val;
        };

        ctrl.updateFrom = () => {
            // Update _only_ date if they differ
            if (ctrl.to && ctrl.from?.isAfter?.(ctrl.to)) {
                const hours = ctrl.to.hour();
                const minutes = ctrl.to.minutes();
                ctrl.to = ctrl.from.clone().set({
                    hours,
                    minutes,
                    seconds: 0,
                });
            }

            ctrl.update();
        };

        ctrl.updateTo = () => {
            // Update _only_ date if they differ
            if (ctrl.from && ctrl.to?.isBefore?.(ctrl.from)) {
                const hours = ctrl.from.hour();
                const minutes = ctrl.from.minutes();
                ctrl.from = ctrl.to.clone().set({
                    hours,
                    minutes,
                    seconds: 0,
                });
            }

            ctrl.update();
        };

        ctrl.updateErrors = () => {
            const fromBeforeTo = +ctrl.from > +ctrl.to;
            ctrl.ngModel.$setValidity('fromBeforeTo', !fromBeforeTo);
        };

        ctrl.update = () => {
            ctrl.updateErrors();

            ctrl.ngModel.setViewValue({
                from: ctrl.from,
                to: ctrl.to,
            });
        };
    } ],
});
