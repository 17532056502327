import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { MaterialColorDirective } from '../../directives/material-color.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgFor, NgIf } from '@angular/common';

export interface Item {
    id: number;
    name: string;
    customerId: number;
    updatedAt: DateTime;
    createdAt: DateTime;
    last_day?: boolean;
}
export type Person = {
    id: number;
    name?: string | null;
    items?: Record<number, Item>;
};

@Component({
    selector: 'eaw-quick-grid',
    templateUrl: './quick-grid.component.html',
    styleUrl: './quick-grid.component.scss',
    standalone: true,
    imports: [
        NgFor,
        MatTooltipModule,
        NgIf,
        MatIconModule,
        MaterialColorDirective,
    ],
})
export class QuickGridComponent<T extends Item, K extends Person> implements OnChanges {
    @Input() people: K[] = [];
    @Input() items: T[] = [];
    @Input() shownItems: T[] = [];
    @Input() lastDayText?: string;
    @Output() toggleItem = new EventEmitter<{ item: Item, person: Person }>();

    computedItems: {
        id: number,
        name: string,
        hover: boolean,
        customerId: number,
        updatedAt: DateTime,
        createdAt: DateTime,
        hide: boolean,
        item: Item,
    }[] = [];

    computedPeople: {
        id: number,
        name: string,
        hover: boolean,
        items: Record<number, Item>,
        person: Person,
    }[] = [];

    ngOnChanges() {
        this.computedItems = this.items ? this.items.map((item) => {
            const shown = this.shownItems?.findIndex((shownItem) => shownItem.id == item.id) == -1;
            return {
                id: item.id,
                name: item.name,
                customerId: item.customerId,
                updatedAt: item.updatedAt,
                createdAt: item.createdAt,
                hover: false,
                hide: shown,
                item,
            };
        }) : [];
        this.computedPeople = this.people ? this.people.map((person) => {
            return {
                id: person.id,
                name: person.name ? person.name : '',
                items: person.items ? person.items : [],
                hover: false,
                person,
            };
        }) : [];
    }

    public toggleHandler(item: Item, person: Person) {
        this.toggleItem.emit({ item, person });
    }

    sortItems(item: Item) {
        this.computedPeople = Object.values(this.computedPeople).sort((a, b) => (b.items[item.id] ? 1 : 0) - (a.items[item.id] ? 1 : 0));
    }

    sortPeople(item: Person) {
        this.computedItems = Object.values(this.computedItems).sort((a, b) => (item.items && item.items[b.id] ? 1 : 0) - (item.items && item.items[a.id] ? 1 : 0));
    }
}
