import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'company-settings-contract-types-upgraded',
    standalone: true
})
export class companySettingsContractTypesUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('companySettingsContractTypes', elementRef, injector);
    }
}

@Component({
    template: '<company-settings-contract-types-upgraded [customer]="customer"></company-settings-contract-types-upgraded>',
    standalone: true,
    imports: [companySettingsContractTypesUpgradedDirective],
})
export class companySettingsContractTypesUpgradedComponent {
    @Input() customer!: any;
}

@NgModule({
    imports: [companySettingsContractTypesUpgradedDirective,
        companySettingsContractTypesUpgradedComponent],
})
export class companySettingsContractTypesUpgradeModule {
}
