// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-button class="md-icon-button" eaw-invisible="{{arrow.disabled}}" ng-click="arrow.changeDate(-1)" ng-disabled="ctrl.hidePrev()">
    <md-icon ng-bind="'chevron_left'"></md-icon>
</md-button>
<span ng-bind="arrow.date | moment: arrow.format"></span>
<md-button class="md-icon-button" eaw-invisible="{{arrow.disabled}}" ng-click="arrow.changeDate(1)" ng-disabled="ctrl.hideNext()">
    <md-icon ng-bind="'chevron_right'"></md-icon>
</md-button>
`;

module('eaw.time').component('arrowDates', {
    template: template1,
    controllerAs: 'arrow',
    bindings: {
        format: '@',
        minDate: '<?',
        maxDate: '<?',
    },
    require: {
        ngModel: 'ngModel',
    },
    controller: [ '$attrs', function($attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.format = ctrl.format || 'date';
            ctrl.date = moment();
            $attrs.$observe('disabled', (val) => {
                ctrl.disabled = val;
            });
            ctrl.ngModel.$formatters.unshift(ctrl.formatter);
        };

        ctrl.changeDate = (add) => {
            ctrl.ngModel.setViewValue(ctrl.date.add(add, 'd'));
        };

        ctrl.hideNext = () => {
            if (!ctrl.maxDate) {
                return false;
            }

            return ctrl.maxDate.isSameOrBefore(ctrl.date, 'd');
        };

        ctrl.hidePrev = () => {
            if (!ctrl.minDate) {
                return false;
            }

            return ctrl.minDate.isSameOrAfter(ctrl.date, 'd');
        };

        ctrl.formatter = (val) => {
            ctrl.date = val;

            return val;
        };
    } ],
});
