import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { Property, PropertyResponse } from '../../shared/models/property';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BaseApiModel } from '../../shared/models/base-api-model';
import { ApiModel } from '../../shared/enums/api-model';

export interface EmergencyContactResponse extends ApiResponse {
    employee?: EmployeeResponse;
    employee_id: number;
    employee_name: string;
    id: number;
    name: string;
    phone: string;
    properties?: PropertyResponse[];
    relation: string | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export class EmergencyContact extends BaseApiModel<EmergencyContactResponse, EmergencyContact> {
    id: number;
    employee?: Employee;
    employeeId: number;
    employeeName: string;
    name: string;
    phone: string;
    properties?: Property[];
    relation: string | null;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;

    constructor(data: EmergencyContactResponse) {
        super(data, ApiModel.EmergencyContact);

        this.id = data.id;
        this.employee = data.employee ? new Employee(data.employee) : undefined;
        this.employeeId = data.employee_id;
        this.employeeName = data.employee_name;
        this.name = data.name;
        this.phone = data.phone;
        this.properties = data.properties?.map((property) => new Property(property));
        this.relation = data.relation;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
