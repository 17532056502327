import { Rate, RateResponse } from '../classes/rate';
import { Tariff, TariffResponse } from './tariff';
import { DateTime } from 'luxon';

export interface PayRateResponse extends RateResponse {
    employee_id: number;
    tariff_id: number | null;
    tariff?: TariffResponse;
}

export class PayRate extends Rate {
    employeeId: number;
    tariffId: number | null;
    tariff?: Tariff;

    constructor(data: PayRateResponse) {
        super(data);

        this.employeeId = data.employee_id;
        this.tariffId = data.tariff_id;
        this.tariff = data.tariff ? new Tariff(data.tariff) : undefined;
    }

    isActive(date: DateTime) {
        if (this.to) {
            return this.from <= date && this.to >= date;
        }

        return this.from <= date;
    }
}
