<eaw-dialog-header>
    <span title>
        {{'SHIFT_SWAP' | translate: 'scheduling' | async}}
    </span>
</eaw-dialog-header>
<mat-dialog-content>
    <section *ngIf="swap.fromId">
        <h3> {{'EMPLOYEE' | translate | async}} </h3>

        <mat-accordion>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{swap.fromEmployee!.name}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="tw-flex tw-justify-center summary" *ngIf="!summary">
                    <mat-progress-spinner diameter="30" strokeWidth="4" mode="indeterminate"></mat-progress-spinner>
                </div>


                <eaw-swap-summary id="originalSummary" *ngIf="summary && swap.fromId && summary[swap.fromId]" [summary]="summary[swap.fromId]!"></eaw-swap-summary>
            </mat-expansion-panel>
        </mat-accordion>
    </section>

    <h3> {{'APPLICANT_plural' | translate: 'scheduling' | async}} </h3>
    <mat-accordion multi>
        <mat-expansion-panel *ngFor="let applicant of applicants">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ applicant.employeeName }}
                </mat-panel-title>
                <mat-panel-description>
                    {{ applicant.cost | eawNumber:2: {style: 'currency', currencyDisplay: 'narrowSymbol'} }}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <eaw-swap-summary *ngIf="summary && summary[applicant.employeeId]" [summary]="summary[applicant.employeeId]!"></eaw-swap-summary>
            <div class="tw-flex tw-justify-center summary" *ngIf="!summary">
                <mat-progress-spinner diameter="30" strokeWidth="4" mode="indeterminate"></mat-progress-spinner>
            </div>
            <mat-action-row>
                <div class="tw-w-full tw-flex tw-justify-between action-row">
                    <button mat-icon-button eawMaterialColor="red-500"
                            (click)="dismiss(applicant)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button mat-icon-button eawMaterialColor="green-500"
                            (click)="choose(applicant)">
                        <mat-icon>check</mat-icon>
                    </button>
                </div>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="close()">
        {{'CANCEL' | translate | async}}
    </button>
</mat-dialog-actions>
