import { inject, Injectable } from '@angular/core';
import { StateObject, UIRouter } from '@uirouter/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScheduleLoadingDialogComponent, ScheduleLoadingDialogData } from '../dialogs/schedule-loading-dialog/schedule-loading-dialog.component';
import { Transition } from '@uirouter/angularjs';

@Injectable({
    providedIn: 'root',
})
export class ScheduleLoadingHookService {
    private readonly uiRouter = inject(UIRouter);
    private readonly matDialog = inject(MatDialog);

    private readonly dialogId = 'schedule-loading-dialog';
    private readonly scheduleViewState = 'eaw/app/scheduling/schedules/list/view';
    private readonly templateViewState = 'eaw/app/scheduling/schedules/templates/view';
    private dialogRef?: MatDialogRef<ScheduleLoadingDialogComponent>;

    private isScheduleViewState(state: StateObject | undefined) {
        return state?.name === this.scheduleViewState || state?.name === this.templateViewState;
    }

    constructor() {
        // Show dialog going to a schedule / template
        this.uiRouter.transitionService.onCreate({ to: this.isScheduleViewState.bind(this) }, this.open.bind(this));

        // Hide dialog if we leave a schedule / template
        this.uiRouter.transitionService.onSuccess({ to: this.isScheduleViewState.bind(this) }, this.close.bind(this));
        this.uiRouter.transitionService.onError({ to: this.isScheduleViewState.bind(this) }, this.close.bind(this));

        // Only hide on exit if not going to a schedule
        this.uiRouter.transitionService.onExit({
            from: this.isScheduleViewState.bind(this),
            to: this.isScheduleViewState.bind(this),
        }, this.close.bind(this));
    }

    private open(transition: Transition) {
        const existing = this.matDialog.openDialogs.find((d) => d.id === this.dialogId);

        if (transition.$id && !existing) {
            this.dialogRef = this.matDialog.open<ScheduleLoadingDialogComponent, ScheduleLoadingDialogData>(ScheduleLoadingDialogComponent, {
                id: this.dialogId,
                data: {
                    isTemplate: transition.to().name === this.templateViewState,
                },
            });
        }
    }

    private close(transition: Transition) {
        if (transition.error()?.type !== 2) {
            this.dialogRef?.close();
        }
    }
}
