<eaw-page-header [useBigFilter]="true" [buttons]="headerButtons">
    <div title *ngIf="!employeeId">{{ 'MY_ABSENCES' | translate:'absences' | async }}</div>
    <div title *ngIf="employeeId">{{ 'ABSENCE_plural' | translate:'absences' | async }}</div>
    <div bigFilter>
        <form [formGroup]="formGroup" class="tw-grid tw-gap-8 tw-grid-cols-12">
            <mat-form-field class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 lg:tw-col-span-3">
                <mat-label>{{ 'MODE' | translate:'absences' | async }}</mat-label>
                <mat-select [compareWith]="modeComparer" formControlName="mode">
                    <mat-option *ngFor="let mode of modes | keyvalue" [value]="mode.value">
                        {{ mode.value.label | translateSync }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field eawDatePickerOptions class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 lg:tw-col-span-3">
                <mat-label>{{ 'ENTER_DATE_RANGE' | translate | async }}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
                    <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async" autocomplete="off">
                    <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async" autocomplete="off">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field class="tw-col-span-full sm:tw-col-span-6 md:tw-col-span-4 lg:tw-col-span-3">
                <mat-label>{{ 'ABSENCE_TYPE_plural' | translate | async }}</mat-label>
                <mat-select formControlName="absenceTypes" multiple>
                    <mat-option *ngFor="let type of groupAbsenceTypes" [value]="type.id">{{ type.name | translateSync:'absence_types' }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <div class="tw-flex tw-gap-8 tw-col-span-full">
            <button mat-raised-button color="primary" (click)="update()" [disabled]="formGroup.pristine">{{ 'UPDATE' | translate | async }}</button>
            <button mat-raised-button (click)="reset()">{{ 'RESET' | translate | async }}</button>
        </div>
    </div>
</eaw-page-header>

<mat-card class="mat-compact-card tw-mt-20">
    <mat-card-content class="tw-flex tw-flex-col">
        <eaw-data-table [columns]="columns"
                        [request]="request"
                        initialSortBy="id"
                        (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
