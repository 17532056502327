<eaw-page-header class="tw-mb-16" [fabButton]="createButton">
    <span title>{{'POSITION_plural' | translate:'company' | async}}</span>
</eaw-page-header>

<eaw-data-table
        class="mat-elevation-z4"
        [request]="request"
        (paginationChange)="updateTable($event)"
        [columns]="columns">
</eaw-data-table>
