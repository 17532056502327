// @ts-nocheck
import { module } from 'angular';
module('eaw.misc').factory('PermissionSets', [ 'EawResource', 'Pagination', function PermissionSetFactory(EawResource, Pagination) {
    const fac = this;
    const url = '/permission_sets/:set';
    fac.getAll = {
        /**
         * @returns {string}
         */
        permission() {
            return `permission_sets.*.get`;
        },
        /**
         * @param {object} args
         * @returns {*}
         */
        query(args) {
            return EawResource.create(url).get({
                ...Pagination.getParams(args),
            });
        },
    };
    fac.get = {
        /**
         * @param {int} setId
         * @returns {string}
         */
        permission(setId) {
            return `permission_sets.${setId}.get`;
        },
        /**
         * @param {int} setId
         * @returns {*}
         */
        query(setId) {
            return EawResource.create(url).get({
                set: setId,
            });
        },
    };
    fac.create = {
        permission() {
            return `permission_sets.create`;
        },
        /**
         * @param {string} productName
         * @param {string} name
         * @param {string} description
         * @returns {*}
         */
        query(productName, name, description) {
            return EawResource.create(url).save({
                product_name: productName,
                name,
                description,
            });
        },
    };
    fac.update = {
        /**
         * @param {int} setId
         * @returns {string}
         */
        permission(setId) {
            return `permission_sets.${setId}.update`;
        },
        /**
         * @param {int} setId
         * @param {string} productName
         * @param {string} name
         * @param {string} description
         * @returns {*}
         */
        query(setId, productName, name, description) {
            return EawResource.create(url).update({
                set: setId,
            }, {
                product_name: productName,
                name,
                description,
            });
        },
    };
    fac.delete = {
        /**
         * @param {int} setId
         * @returns {string}
         */
        permission(setId) {
            return `permission_sets.${setId}.delete`;
        },
        /**
         * @param {int} setId
         * @returns {*}
         */
        query(setId) {
            return EawResource.create(url).delete({
                set: setId,
            });
        },
    };
    return fac;
} ]);
