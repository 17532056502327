import { Component, computed, DestroyRef, effect, inject, signal, viewChild } from '@angular/core';
import { ScheduleService } from '../../http/schedule.service';
import { DataTable, DataTablePagination } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { catchError, debounceTime, EMPTY, of, tap } from 'rxjs';
import { CurrentService } from '../../../shared/services/current.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { DataTableComponent, DataTableGoTo } from '../../../data-table/data-table.component';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { DataTableIconColumn } from '../../../data-table/types/data-table-icon-column';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { MatDatepickerModule, MatDateRangePicker } from '@angular/material/datepicker';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { Schedule } from '../../models/schedule';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { CreateScheduleDialogService } from '../../dialogs/create-schedule-dialog/create-schedule-dialog.service';
import { ScheduleProgressEvent, WebsocketService } from '../../../shared/services/websocket.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Namespace } from '../../../shared/enums/namespace';
import { ScheduleSettingsDialogComponent, ScheduleSettingsDialogData } from '../../dialogs/schedule-settings-dialog/schedule-settings-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';
import { DataTableProgressColumn } from '../../../data-table/types/data-table-progress-column';
import { mockProperty } from '../../../../mocks/property.mock';

@Component({
    selector: 'eaw-schedule-list',
    templateUrl: './schedule-list.component.html',
    styleUrl: './schedule-list.component.scss',
    providers: [ DurationPipe ],
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        MatButton,
        PermissionDirective,
    ],
})
export class ScheduleListComponent implements DataTable<Schedule> {
    private scheduleService = inject(ScheduleService);
    private current = inject(CurrentService);
    private permissionCheckService = inject(PermissionCheckService);
    private durationPipe = inject(DurationPipe);
    private promptDialog = inject(PromptDialogService);
    private confirmDialogService = inject(ConfirmDialogService);
    private matDialog = inject(MatDialog);
    private translate = inject(TranslateService);
    private createScheduleDialogService = inject(CreateScheduleDialogService);
    private websocketService = inject(WebsocketService);
    private destroyRef = inject(DestroyRef);

    table = viewChild.required(DataTableComponent<Schedule>);
    dateRangePicker = viewChild.required(MatDateRangePicker);

    formGroup = new FormGroup({
        filter: new FormControl(),
        dateRange: new FormGroup({
            from: new FormControl<DateTime | undefined>(DateTime.now().startOf('day')),
            to: new FormControl<DateTime | undefined>(undefined),
        }),
    });

    private betaScheduleKey = 'usingBetaSchedule';
    getData = computed(this.computeGetData.bind(this));
    usingBetaSchedule = signal(false);
    goTo = computed<DataTableGoTo>(() => {
        return {
            state: this.usingBetaSchedule() ? 'eaw/app/scheduling/schedules/list/beta' : 'eaw/app/scheduling/schedules/list/view',
            params: [ { stateKey: 'id', itemKey: 'id' } ],
        };
    });

    headerButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            click: this.openSettings.bind(this),
            icon: 'settings',
            menuText: signal(this.translate.t('SETTING_plural')),
        }),
        new PageHeaderButton({
            click: this.showAll.bind(this),
            icon: 'history',
            menuText: signal(this.translate.t('SHOW_ALL_SCHEDULES', Namespace.Scheduling)),
        }),
    ];

    fabButton: HeaderFabButton = {
        click: this.createSchedule.bind(this),
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.current.getCustomer().id}.schedules.create`),
    };

    columns = computed(this.computeColumns.bind(this));

    constructor() {
        this.formGroup.controls.filter.valueChanges.pipe(
            debounceTime(1000),
            tap(() => this.table().refresh()),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();

        this.formGroup.controls.dateRange.valueChanges.pipe(
            debounceTime(1000),
            tap(() => this.table().refresh()),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();

        this.websocketService.listenCustomerSchedules(this.current.getCustomer().id, 'created', () => this.table().refresh(), this.destroyRef);
        this.websocketService.listenCustomerSchedules(this.current.getCustomer().id, 'progress', this.onProgressEvent.bind(this), this.destroyRef);

        effect(() => this.current.store(this.betaScheduleKey, this.usingBetaSchedule(), 'default'));
        this.current.retrieve<boolean>(this.betaScheduleKey, 'default').then((val) => this.usingBetaSchedule.set(val ?? false));
    }

    onProgressEvent(ev: ScheduleProgressEvent) {
        this.table().modifyResponse((response) => {
            const item = response.data.find((i) => i.id === ev.id);
            if (!item) {
                return response;
            }

            const progressProperty = item?.properties?.find((p) => p.key === 'progress');
            if (progressProperty) {
                progressProperty.setValue(ev.progress.toString());
                return response;
            }

            const newProgressProperty = mockProperty({ key: 'progress', value: ev.progress.toString() });
            if (item.properties) {
                item.properties.push(newProgressProperty);
            } else {
                item.properties = [ newProgressProperty ];
            }

            return response;
        });
    }

    showAll() {
        this.formGroup.controls.dateRange.controls.from.setValue(undefined);
    }

    createSchedule() {
        this.createScheduleDialogService.open({ customerId: this.current.getCustomer().id }).subscribe((result) => {
            if (result == null) {
                return;
            }

            this.table().refresh();
        });
    }

    delete(cell: DataTableCell<DataTableButtonColumn<Schedule>, Schedule>) {
        this.confirmDialogService.open({
            title: this.translate.t('DELETE'),
            text: this.translate.t('DELETE_SCHEDULE', 'scheduling', { name: cell.item.name }),
            confirmText: this.translate.t('DELETE'),
        }).afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.scheduleService.delete(cell.item.customerId, cell.item.id).pipe(
                catchError((err) => {
                    console.error(err);
                    cell.disabled.set(false);
                    return EMPTY;
                }),
            ).subscribe(() => {
                this.table().refresh();
            });
        });
    }

    async updateName(cell: DataTableCell<DataTableButtonColumn<Schedule>, Schedule>) {
        this.promptDialog.open('text', {
            formControl: new FormControl(cell.item.name),
            confirmText: this.translate.t('UPDATE'),
            label: this.translate.t('NAME'),
            title: this.translate.t('EDIT_SCHEDULE', 'scheduling'),
        }).afterClosed().subscribe((val) => {
            if (val == null) {
                cell.disabled.set(false);
                return;
            }

            this.scheduleService.update(cell.item.customerId, cell.item.id, { name: val }).pipe(
                catchError((err) => {
                    console.error(err);
                    cell.disabled.set(false);
                    return EMPTY;
                }),
            ).subscribe(() => {
                this.table().refresh();
            });
        });
    }

    openSettings() {
        this.matDialog.open<ScheduleSettingsDialogComponent, ScheduleSettingsDialogData>(ScheduleSettingsDialogComponent, {
            data: {
                customerId: this.current.getCustomer().id,
                stackId: this.current.getCustomer().stackId,
            },
        });
    }

    scheduleLength(cell: DataTableCell<DataTableTextColumn<Schedule>, Schedule>): string {
        return this.durationPipe.transform(cell.item.length + 3600, [ 'weeks', 'days' ], { unitDisplay: 'long' });
    }

    computeColumns(): DataTableColumnType<Schedule>[] {
        return [
            new DataTableProgressColumn({
                value: (cell) => cell.item.properties?.find((property) => property.key === 'progress')?.value.asInteger(),
                header: null,
            }),
            new DataTableTextColumn({
                value: 'name',
                header: new DataTableHeader({
                    i18n: 'NAME',
                    sortBy: 'name',
                }),
            }),
            new DataTableNumberColumn({
                value: 'shiftsCount',
                header: new DataTableHeader({
                    i18n: 'SHIFT_plural',
                    ns: 'scheduling',
                }),
            }),
            new DataTableIconColumn({
                header: new DataTableHeader({
                    i18n: 'PUBLISHED',
                    ns: 'scheduling',
                }),
                icon: async (cell) => cell.item.isPublished ? 'done' : 'close',
                color: async (cell) => cell.item.isPublished ? 'green-500' : 'red-500',
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({
                    i18n: 'LENGTH',
                    sortBy: 'length',
                }),
                value: this.scheduleLength.bind(this),
            }),
            new DataTableDateTimeColumn({
                value: 'from',
                header: new DataTableHeader({
                    i18n: 'FROM',
                    sortBy: 'from',
                }),
                format: DateTime.DATETIME_MED,
            }),
            new DataTableDateTimeColumn({
                value: 'to',
                header: new DataTableHeader({
                    i18n: 'TO',
                    sortBy: 'to',
                }),
                format: DateTime.DATETIME_MED,
            }),
            new DataTableDateTimeColumn({
                value: 'publishedAt',
                header: new DataTableHeader({
                    i18n: 'PUBLISH_DATE',
                    ns: 'scheduling',
                    sortBy: 'published_at',
                }),
                format: DateTime.DATETIME_MED,
            }),
            new DataTableTextColumn({
                value: 'templateName',
                header: new DataTableHeader({
                    i18n: 'TEMPLATE',
                    ns: 'scheduling',
                }),
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        click: this.updateName.bind(this),
                        icon: 'edit',
                        tooltip: { key: 'EDIT' },
                        show: (item) => this.permissionCheckService.isAllowed(`customers.${item.customerId}.schedules.${item.id}.update`),
                    },
                    {
                        click: this.delete.bind(this),
                        icon: 'delete',
                        tooltip: { key: 'DELETE' },
                        type: 'warn',
                        show: (item) => item.isPublished ? of(false) : this.permissionCheckService.isAllowed(`customers.${item.customerId}.schedules.${item.id}.delete`),
                    },
                ],
            }),
        ];
    }

    computeGetData() {
        return (pagination: Partial<DataTablePagination>) => {
            return this.scheduleService.getAll(this.current.getCustomer().id, {
                ...pagination,
                'count[]': [ 'shifts' ],
                'with[]': [ 'properties' ],
                from: this.formGroup.controls.dateRange.controls.from.value || undefined,
                to: this.formGroup.controls.dateRange.controls.to.value || undefined,
                filter: this.formGroup.controls.filter.value,
            });
        };
    }
}
