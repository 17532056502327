import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { DataTablePagination, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { FormSubmissionService } from '../../http/form-submission.service';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { catchError, EMPTY, Observable, of, switchMap } from 'rxjs';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { CurrentService } from '../../../shared/services/current.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DataTableBooleanColumn } from '../../../data-table/types/data-table-boolean-column';
import { DateTime } from 'luxon';
import { FormSubmission } from '../../models/form-submission';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormService } from '../../http/form.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { EawForm } from '../../types/form';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { MatDialog } from '@angular/material/dialog';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { FormSubmissionNoteDialogComponent, FormSubmissionNoteDialogData } from '../../dialogs/form-submission-note-dialog/form-submission-note-dialog.component';
import { ShowFormSubmissionComponent } from '../../components/show-form-submission/show-form-submission.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { loadNamespaces } from 'i18next';
import { Namespace } from '../../../shared/enums/namespace';

@Component({
    selector: 'eaw-user-form-submission-list',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCard,
        MatCardContent,
        DataTableComponent,
        MatFormFieldModule,
        TranslatePipe,
        AsyncPipe,
        MatSelect,
        MatOption,
        ReactiveFormsModule,
        TranslateSyncPipe,
    ],
    templateUrl: './user-form-submission-list.component.html',
    styleUrl: './user-form-submission-list.component.scss',
})
export class UserFormSubmissionListComponent implements EawDataTable, OnInit {
    private readonly formSubmissionService = inject(FormSubmissionService);
    private readonly formService = inject(FormService);
    private readonly current = inject(CurrentService);
    private readonly matDialog = inject(MatDialog);

    @ViewChild(DataTableComponent) table!: DataTableComponent<FormSubmission>;

    customerId = this.current.getCustomer().id;

    columns: DataTableColumnType<FormSubmission>[] = [
        new DataTableTextColumn({
            header: new DataTableHeader({ i18n: 'FORM' }),
            value: (row) => row.item.form?.name,
        }),
        new DataTableBooleanColumn({
            header: new DataTableHeader({ i18n: 'ANONYMOUS', sortBy: 'anonymous' }),
            boolean: 'anonymous',
            error: { color: 'grey-400', icon: 'close' },
        }),
        new DataTableDateTimeColumn({
            header: new DataTableHeader({ i18n: 'SUBMITTED', ns: 'forms', sortBy: 'created_at' }),
            value: 'createdAt',
            format: DateTime.DATETIME_MED,
        }),
        new DataTableDateTimeColumn({
            header: new DataTableHeader({ i18n: 'CLOSED_AT', ns: 'forms', sortBy: 'closed_at' }),
            value: 'closedAt',
            format: DateTime.DATETIME_MED,
        }),
        new DataTableNumberColumn({
            header: new DataTableHeader({ i18n: 'NOTE_plural', ns: 'forms' }),
            value: 'notesCount',
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'info',
                    tooltip: { key: 'VIEW' },
                    click: (cell) => this.viewDetails(cell),
                    show: (item) => of(!!item.formId),
                },
                {
                    icon: 'comment',
                    tooltip: { key: 'NOTE_plural' },
                    click: (cell) => this.viewNotes(cell.item),
                    show: () => of(true),
                    nonBlocking: true,
                },
            ],
        }),
    ];

    formGroup = new FormGroup({
        formSelect: new FormControl<number | null>({ value: null, disabled: true }),
        closedFilter: new FormControl(false),
    });

    request?: Observable<ArrayPaginatedResponse<FormSubmission>>;
    forms?: EawForm[];

    async ngOnInit() {
        this.formGroup.valueChanges.subscribe(() => this.updateTable(this.table.getPagination({ page: 1 })));
        expandAllPages((p) => this.formService.getAll(this.customerId, p), { per_page: 100 })
            .subscribe((forms) => {
                this.forms = forms;
                this.formGroup.controls.formSelect.enable({ emitEvent: false });
            });

        await loadNamespaces([ Namespace.Forms ]);
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.formSubmissionService.getAllFromUser(this.customerId, {
            'with[]': [ 'form' ],
            form_id: this.formGroup.controls.formSelect.value || undefined,
            is_closed: this.formGroup.controls.closedFilter.value ?? undefined,
            count: 'notes',
            ...pagination,
        });
    }

    viewDetails(cell: DataTableButtonCell<FormSubmission>) {
        this.formService.get(this.customerId, cell.item.formId)
            .pipe(
                catchError(() => of(null)),
                switchMap((form) => {
                    cell.disabled.set(false);
                    if (!form) {
                        return EMPTY;
                    }

                    cell.item.user = this.current.getUser();

                    return this.matDialog.open(ShowFormSubmissionComponent, {
                        data: {
                            form,
                            submission: cell.item,
                            hideAnonymizeButton: true,
                        },
                    }).afterClosed();
                }),
            )
            .subscribe();
    }

    viewNotes(submission: FormSubmission) {
        this.matDialog.open(FormSubmissionNoteDialogComponent, {
            data: {
                customerId: this.customerId,
                submission,
                showHideCheckbox: false,
            } as FormSubmissionNoteDialogData,
        });
    }
}
