import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ObjectCreatorObject, ObjectCreatorComponent } from '../../components/object-creator/object-creator.component';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

export type KeyValueDialogReturn = Record<string, string> | null;

export interface KeyValueDialogData extends DialogData {
    value?: KeyValueDialogReturn;
    title: Promise<string>;
}

@Component({
    selector: 'eaw-key-value-dialog',
    templateUrl: './key-value-dialog.component.html',
    styleUrl: './key-value-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ObjectCreatorComponent,
        ReactiveFormsModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class KeyValueDialogComponent extends DialogComponent {
    objectControl = new FormControl<ObjectCreatorObject | null>(null);

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: KeyValueDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<KeyValueDialogComponent, KeyValueDialogReturn>,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        this.objectControl.patchValue(data.value || null);
    }

    update() {
        this.dialogRef.close(this.objectControl.value);
    }
}
