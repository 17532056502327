// @ts-nocheck
import { t } from 'i18next';
import { uniqueId } from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { module } from 'angular';

module('eaw.scheduling').controller('templateDatePickerCtrl', [ '$mdDialog', 'scheduleDays', 'scheduleTable', function($mdDialog, scheduleDays, scheduleTable) {
    const tdpCtrl = this;

    const days = scheduleDays.getAll().length;
    tdpCtrl.days = new Array(7).fill(uniqueId());
    tdpCtrl.weeks = new Array(Math.ceil(days / 7)).fill(uniqueId());
    tdpCtrl.lastWeekDays = new Array(days % 7).fill(uniqueId());

    tdpCtrl.weekString = (weeknr) => t('WEEK_N', {
        number: weeknr,
    });

    tdpCtrl.dayString = (weeknr, daynr) => t('DAY_N', {
        number: ((weeknr - 1) * 7) + daynr,
    });

    tdpCtrl.goToString = (weeknr, daynr) => t('scheduling:GO_TO_DAY_N', {
        number: ((weeknr - 1) * 7) + daynr,
    });

    tdpCtrl.goToDay = (weeknr, daynr) => {
        const day = (((weeknr - 1) * 7) + daynr) - 1;
        $mdDialog.hide(scheduleTable.getSchedule().getFrom().add(day * 86400, 's'));
    };

    tdpCtrl.cancel = () => $mdDialog.cancel();
} ]);
