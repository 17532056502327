// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';
import { CustomerSettings } from '../../../../../shared/angularjs/modules/customers/customer-settings.factory';

module('eaw.scheduling').component('scheduleBudgetTab', {
    template: `<md-card>
    <md-card-content>
        <div layout="row" layout-wrap>
            <md-input-container flex="100" flex-gt-sm="50" flex-gt-md="25" class="tw-w-full">
                <label ng-i18next="INTERVAL"></label>
                <md-select ng-model="$budget.budgetInterval" ng-disabled="$budget.scheduleView.schedule.original" ng-change="$budget.changeInterval()">
                    <md-option ng-repeat="interval in $budget.intervals track by $index" ng-value="interval.value">{{interval.text}}</md-option>
                </md-select>
            </md-input-container>

            <md-input-container flex="100" flex-gt-sm="50" flex-gt-md="25" class="tw-w-full" ng-if="!$budget.dayMode && !$budget.scheduleView.schedule.is_template">
                <label ng-i18next="DAY"></label>
                <md-select ng-model="$budget.selectedDay" ng-change="$budget.changeDay()">
                    <md-option ng-repeat="day in $budget.days track by $index" ng-value="day.index">
                        <span ng-bind="::day.dayName + ', ' + day.date"></span>
                    </md-option>
                </md-select>
            </md-input-container>

            <md-input-container flex="100" flex-gt-sm="50" flex-gt-md="25" class="tw-w-full" ng-if="!$budget.dayMode && $budget.scheduleView.schedule.is_template">
                <label ng-i18next="DAY"></label>
                <md-select ng-model="$budget.selectedDay" ng-change="$budget.changeDay()">
                    <md-option ng-repeat="day in $budget.days track by $index" ng-value="day.index">{{day.templateDay}}</md-option>
                </md-select>
            </md-input-container>
        </div>
    </md-card-content>
</md-card>

<md-card ng-if="!$budget.scheduleView.schedule.is_template">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-if="!$budget.dayMode" ng-i18next="scheduling:DAILY_BUDGETING"></span>
            <span class="md-title" ng-if="$budget.dayMode" ng-bind="$budget.days[$budget.selectedDay].templateDay"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <table class="table table-condensed table-bordered">
            <thead>
            <tr>
                <th ng-if="$budget.dayMode">
                    <span ng-i18next="DAY"></span>
                </th>
                <th>
                    <span ng-if="$budget.dayMode" ng-i18next="DATE"></span>
                    <span ng-if="!$budget.dayMode" ng-i18next="TIME"></span>
                </th>
                <th>
                    <span ng-i18next="scheduling:BUDGET"></span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="row in $budget.tableRows track by $index">
                <td ng-if="$budget.dayMode">
                    <strong>{{row.day}}</strong>
                </td>
                <td>
                    <strong>{{row.time}}</strong>
                </td>
                <td>
                    <input id="budget-input-{{row.budgetIndex}}"
                           ng-readonly="$budget.scheduleView.schedule.original"
                           ng-keyup="$budget.checkEnter($event)"
                           ng-focus="$budget.focusedBudgetInitialValue = $budget.budgets[row.budgetIndex].budget"
                           ng-blur="$budget.updateBudgetData($budget.budgets[row.budgetIndex], row.budgetIndex)"
                           ng-model="$budget.budgets[row.budgetIndex].budget">
                </td>
            </tr>
            <tr ng-if="!$budget.dayMode">
                <td>
                    <strong ng-i18next="scheduling:DAY_TOTAL"></strong>
                </td>
                <td class="budget-day-total">
                    <input ng-model="$budget.dayTotal" readonly>
                </td>
            </tr>
            <tr>
                <td ng-if="!$budget.dayMode">
                    <strong ng-i18next="scheduling:SCHEDULE_TOTAL"></strong>
                </td>
                <td ng-if="$budget.dayMode" colspan="2">
                    <strong ng-i18next="scheduling:SCHEDULE_TOTAL"></strong>
                </td>
                <td class="budget-schedule-total">
                    <input ng-model="$budget.scheduleTotal" readonly>
                </td>
            </tr>
            </tbody>
        </table>
    </md-card-content>
</md-card>

<md-card ng-if="$budget.scheduleView.schedule.is_template">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-if="!$budget.dayMode">{{$budget.getDay() | moment:'LL'}}</span>
            <span class="md-title" ng-if="$budget.dayMode" ng-i18next="scheduling:DAILY_BUDGETING"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <table class="table table-condensed table-bordered">
            <thead>
            <tr>
                <th ng-if="$budget.dayMode">
                    <span ng-i18next="DAY"></span>
                </th>
                <th>
                    <span ng-if="$budget.dayMode" ng-i18next="DATE"></span>
                    <span ng-if="!$budget.dayMode" ng-i18next="TIME"></span>
                </th>
                <th>
                    <span ng-i18next="scheduling:BUDGET"></span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="row in $budget.tableRows track by $index">
                <td ng-if="$budget.dayMode">
                    <strong>{{row.day}}</strong>
                </td>
                <td>
                    <strong>{{row.time}}</strong>
                </td>
                <td>
                    <input id="budget-input-{{row.budgetIndex}}"
                           ng-readonly="$budget.scheduleView.schedule.original"
                           ng-keyup="$budget.checkEnter($event)"
                           ng-focus="$budget.focusedBudgetInitialValue = $budget.budgets[row.budgetIndex].budget"
                           ng-blur="$budget.updateBudgetData($budget.budgets[row.budgetIndex], row.budgetIndex)"
                           ng-model="$budget.budgets[row.budgetIndex].budget">
                </td>
            </tr>
            <tr ng-if="!$budget.dayMode">
                <td>
                    <strong ng-i18next="scheduling:DAY_TOTAL"></strong>
                </td>
                <td class="budget-day-total">
                    <input ng-model="$budget.dayTotal" readonly>
                </td>
            </tr>
            <tr>
                <td ng-if="!$budget.dayMode">
                    <strong ng-i18next="scheduling:SCHEDULE_TOTAL"></strong>
                </td>
                <td ng-if="$budget.dayMode" colspan="2">
                    <strong ng-i18next="scheduling:SCHEDULE_TOTAL"></strong>
                </td>
                <td class="budget-schedule-total">
                    <input ng-model="$budget.scheduleTotal" readonly>
                </td>
            </tr>
            </tbody>
        </table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$budget',
    require: {
        scheduleView: '^scheduleView',
    },
    controller: [ '$scope', 'BackOfficeSync', 'BudgetFactory', '$timeout', 'ToastService', 'ScheduleService', 'CustomerSettings', '$mdDialog', 'scheduleBudget', 'intervalService', function($scope, BackOfficeSync, BudgetFactory, $timeout, ToastService, ScheduleService, CustomerSettings, $mdDialog, scheduleBudget, intervalService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            BackOfficeSync.subscribe($scope, ctrl.backOfficeSyncComplete);

            ctrl.budgetInterval = scheduleBudget.getInterval();
            ctrl.selectedDay = 0;

            ctrl.intervals = [
                {
                    text: t('X_MINUTES', { count: 15 }),
                    value: 900,
                },
                {
                    text: t('X_MINUTES', { count: 30 }),
                    value: 1800,
                },
                {
                    text: t('X_HOURS', { count: 1 }),
                    value: 3600,
                },
                {
                    text: t('N_DAY', { count: 1 }),
                    value: 86400,
                },
            ];

            // Init budgets if we don't have any loaded yet
            if (!Object.keys(scheduleBudget.get() || []).length) {
                scheduleBudget.init().then(ctrl.loaded);
            } else {
                ctrl.loaded();
            }
        };

        ctrl.getDay = () => ctrl.scheduleView.schedule.getDay(ctrl.selectedDay);

        ctrl.loaded = async () => {
            await ctrl.getBusinessDateOffset();
            ctrl.generateDays();
            ctrl.generateRows();
            ctrl.updateTotalBudgets();
            ctrl.convertBudgets();
        };

        ctrl.getBusinessDateOffset = async () => {
            try {
                const resp = await CustomerSettings.get(ctrl.scheduleView.schedule.customer.id, 'business_date_offset');
                // business date offset has negative value so it must be reverted
                ctrl.businessDateOffset = resp['business_date_offset'] ? -resp['business_date_offset'] : 0;
            } catch (e) {
                console.error(e);
                ctrl.businessDateOffset = 0;
                return;
            }
        }

        ctrl.convertBudgets = () => {
            ctrl.budgets = {};
            scheduleBudget.get()?.forEach((b) => {
                ctrl.budgets[b.index] = b;
                ctrl.budgets[b.index].exists = true;
            });
        };

        ctrl.checkEnter = (event) => {
            if (event.key === 'Enter') {
                event.target.closest('tr').nextElementSibling().find('input').focus();
            }
        };

        ctrl.generateRows = () => {
            if (ctrl.budgetInterval >= 86400) { // Use day mode
                ctrl.dayMode = true;

                ctrl.tableRows = ctrl.days.reduce((rows, day) => rows.concat({
                    budgetIndex: day.index,
                    day: day.dayName,
                    time: day.date,
                }), []);
            } else {
                const intervals = ctrl.scheduleView.schedule.getArrayOfIntervalsForDayWithOffset(ctrl.selectedDay, scheduleBudget.getInterval(), ctrl.businessDateOffset);
                ctrl.dayMode = false;

                ctrl.tableRows = intervals.reduce((rows, interval) => rows.concat({
                    budgetIndex: intervalService.getIntervalIndexFromDate(interval),
                    time: interval.format('LT'),
                }), []);
            }
        };

        ctrl.changeDay = () => {
            $timeout(() => {
                ctrl.convertBudgets();
                ctrl.generateRows();
                ctrl.updateTotalBudgets();
            });
        };

        ctrl.changeInterval = () => {
            $mdDialog.show(
                $mdDialog.confirm()
                    .title(t('WARNING'))
                    .textContent(t('scheduling:CHANGE_INTERVAL_QUESTION'))
                    .ok(t('scheduling:CHANGE'))
                    .cancel(t('CANCEL')),
            ).then(() => {
                const propPromise = ScheduleService.updateProperty(ctrl.scheduleView.schedule.customer, ctrl.scheduleView.schedule, 'budgeting_interval', ctrl.budgetInterval + '').$promise;
                const deletePromise = BudgetFactory.deleteAllBudgets(ctrl.scheduleView.schedule, ctrl.scheduleView.schedule.customer).$promise;

                Promise.all([ propPromise, deletePromise ]).then((data) => {
                    $timeout(() => {
                        ctrl.scheduleView.schedule.setProperty(data[0]);
                        scheduleBudget.reset();
                        ctrl.budgets = {};
                        ctrl.selectedDay = 0;
                        ctrl.changeDay();
                        ToastService.toast(t('scheduling:BUDGETS_RESET'));
                    });
                });
            }).catch(() => {
                $timeout(() => {
                    ctrl.budgetInterval = scheduleBudget.getInterval();
                });
            });
        };

        ctrl.generateDays = () => {
            ctrl.days = [];
            ctrl.scheduleView.schedule.getDays().forEach((day, index) => {
                // lose last day due to the possible business date offset
                if (day.add(ctrl.businessDateOffset, 'h').isBefore(ctrl.scheduleView.schedule.getTo())) {
                    ctrl.days.push({
                        day: day.day(), // day of week, locale aware.
                        dayName: day.format('dddd'),
                        date: ctrl.scheduleView.schedule.is_template ? t('DAY_N', { number: index + 1 }) : day.format('LL'),
                        week: day.format('ww'),
                        month: day.format('MMMM'),
                        templateDay: t('DAY_N', { number: index + 1 }),
                        index,
                    });
                }
            });
        };

        ctrl.updateTotalBudgets = () => {
            ctrl.scheduleTotal = scheduleBudget.getTotal();
            ctrl.dayTotal = scheduleBudget.getDayTotal(ctrl.selectedDay, ctrl.businessDateOffset);
        };

        function updateDone() {
            $timeout(() => {
                ctrl.updateTotalBudgets();
                ctrl.scheduleView.recalcTopStats();
            });
        }

        ctrl.updateBudgetData = (budget, budgetIndex) => {
            // If undefined just do nothing
            // Or just nevermind if it is the same as before
            if (budget?.budget === ctrl.focusedBudgetInitialValue) {
                return;
            }

            // If we send in a budget that does not have exist set, it means it does not exist in the database and therefore must be created
            if (budget.exists == null && budget.budget !== '') {
                scheduleBudget.add(budget.budget, budgetIndex).then((data) => {
                    ctrl.budgets[budgetIndex] = data;
                    ctrl.budgets[budgetIndex].exists = true;
                    updateDone();
                }).catch(() => {
                    ctrl.budgets[budgetIndex] = undefined;
                });
            }

            // Update budget if it exists from before
            if (budget.exists && budget.budget !== null) {
                // But only update if the budget is not the same as before
                if (budget.budget !== ctrl.focusedBudgetInitialValue) {
                    scheduleBudget.update(budget).then((data) => {
                        ctrl.budgets[budgetIndex] = data;
                        ctrl.budgets[budgetIndex].exists = true;
                        updateDone();
                    }).catch(() => updateDone());
                }
            }

            if (budget.exists && budget.budget === null) {
                scheduleBudget.delete(budget).then(() => {
                    // Delete from local budgets
                    delete ctrl.budgets[budget.index];
                    updateDone();
                }).catch(() => updateDone());
            }
        };

        ctrl.backOfficeSyncComplete = () => {
            BudgetFactory.getAll.query({
                customer: ctrl.scheduleView.schedule.customer_id,
                schedule: ctrl.scheduleView.schedule.id,
                per_page: 9999,
            }).$promise.then((data) => {
                // Set new budget data
                scheduleBudget.set(data.data);

                // Update
                ctrl.changeDay();
            });
        };
    } ],
});
