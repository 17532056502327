// @ts-nocheck
import { t } from 'i18next';
import { isObject } from 'lodash-es';
import { module } from 'angular';

module('eaw.calendar').controller('CalendarEventDialogCtrl', [ '$mdDialog', 'colorpickerService', function($mdDialog, colorpickerService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.event = isObject(ctrl.event) ? { ...ctrl.event } : {};
        ctrl.event.color = ctrl.event.backgroundColor;
        ctrl.editable = ctrl.editable ?? true;
        ctrl.title = ctrl.event?.name || t('calendar:NEW_EVENT');
        ctrl.eventInterval = {
            from: ctrl.event?.from?.clone(),
            to: ctrl.event?.to?.clone(),
        };

        if (!ctrl.event?.color) {
            ctrl.event.color = colorpickerService.getRandomHexColor();
        }
    };

    ctrl.submit = () => {
        ctrl.event.from = ctrl.eventInterval.from;
        ctrl.event.to = ctrl.eventInterval.to;
        $mdDialog.hide(ctrl.event);
    };
} ]);
