// @ts-nocheck
import { module } from 'angular';

module('eaw.dialogs').controller('ItemSelectorDialogCtrl', [ '$mdDialog', function ItemSelectorDialogCtrl($mdDialog) {
    const ctrl = this;

    ctrl.$onInit = () => {
        // Create a bit of a unique key so we dont affect any items that might have had a similar key when passed in
        ctrl.selectedKey = '_itemDialogSelected';
        ctrl.disabledKey = '_itemDialogDisabled';

        ctrl.options.items.forEach((i) => {
            i[ctrl.selectedKey] = ctrl.options.selectedItems?.some((si) => si[ctrl.options.displayKey] === i[ctrl.options.displayKey]);
            i[ctrl.disabledKey] = ctrl.options.disabledItems?.some((di) => di[ctrl.options.displayKey] === i[ctrl.options.displayKey]);

            // Add subtitle under item(s)
            if (ctrl.options.subtitleKey) {
                i.subtitle = typeof ctrl.options.subtitleKey === 'function' ? ctrl.options.subtitleKey(i) : i[ctrl.options.subtitleKey];
            }
        });
    };

    ctrl.select = (item) => {
        if (ctrl.options.disableSelecting) {
            return;
        }

        item[ctrl.selectedKey] = !item[ctrl.selectedKey];

        if (!ctrl.options.multiSelect) {
            ctrl.submit();
        }
    };

    ctrl.submit = () => {
        const items = ctrl.options.items?.filter?.((i) => i[ctrl.selectedKey]).map((i) => {
            delete i[ctrl.selectedKey];
            return i;
        });

        if (ctrl.options.multiSelect) {
            $mdDialog.hide(items);
        } else {
            $mdDialog.hide(items[0]);
        }
    };
} ]);
