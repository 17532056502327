// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

const template1 = `<script type="text/ng-template" id="issuedownloadtemplate">
    <div class="issue-lang-download" ng-repeat="language in item.languages" ng-click="item.viewIssueFile(item, language, $event)">
        <md-icon ng-bind="'open_in_browser'"></md-icon>
        <div class="title">{{language.title}}</div>
    </div>
</script>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-i18next="newsletter:ISSUES"></span>
        </md-card-header-text>

        <card-btn-float ng-if="$ctrl.canCreate" state="eaw/app/chain_ops/newsletter/update/issues/create"></card-btn-float>
    </md-card-header>

    <md-card-content>
        <eaw-dynamic-ng-table get-data="$ctrl.getIssueData(pagination)"
                              columns="$ctrl.columns"
                              cells="$ctrl.cells"
                              go-to="eaw/app/chain_ops/newsletter/update/issues/update|key=newsletter_id|issue_id=id"
                              reload-table="$ctrl.reloadTable">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;

module('eaw.newsletter').component('manageNewsletterIssuesTab', {
    template: template1,
    controllerAs: '$ctrl',
    bindings: {
        user: '<',
        customer: '<',
        languages: '<',
        newsletter: '<',
    },
    controller: [ '$mdDialog', 'Newsletter', 'ToastService', 'ReadNewsletterIssueServiceDowngrade', function($mdDialog, Newsletter, ToastService, ReadNewsletterIssueServiceDowngrade) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.canCreate = CurrentOld.can(`customers.${ctrl.newsletter.customer_id}.newsletters.${ctrl.newsletter.id}.issues.create`);
            ctrl.columns = [
                { title: t('TITLE') },
                { title: t('DESCRIPTION') },
                {
                    title: t('newsletter:VIEW_plural'),
                    class: 'text-right',
                },
                { title: t('DATE') },
                { title: t('LANGUAGE_plural') },
                { title: '' },
            ];
            ctrl.cells = [
                {
                    template: 'item.viewLanguage.title',
                    class: 'text-ws-nowrap',
                },
                {
                    template: 'item.viewLanguage.description',
                    class: 'issue-description',
                },
                {
                    template: `item.newsletter_view_logs_count | eawNumber:0:false:'short'`,
                    class: 'text-right',
                },
                {
                    template: `item.publish_date | moment:'LL'`,
                    class: 'text-ws-nowrap',
                },
                { templateUrl: 'issuedownloadtemplate' },
                {
                    buttons: [
                        {
                            icon: 'delete',
                            type: 'warn',
                            click: ctrl.deleteIssue,
                            invisible: (issue) => !CurrentOld.can(`customers.${ctrl.newsletter.customer_id}.newsletters.${issue.newsletter_id}.issues.${issue.id}.delete`),
                        },
                    ],
                },
            ];
        };

        ctrl.getIssueData = (pagination) => {
            ctrl.loading = true;

            return Newsletter.getIssues(ctrl.customer.id, ctrl.newsletter.key, {
                ...pagination.toWithPaginatorArguments(),
                'count[]': [ 'newsletterViewLogs' ],
            }).then((resp) => {
                resp.data.forEach((issue) => {
                    issue.viewIssueFile = ctrl.viewIssueFile;
                    Newsletter.setIssueLanguage(issue, ctrl.customer, ctrl.user);
                });

                ctrl.hasIssues = !!resp.total;

                return resp;
            }).finally(() => delete ctrl.loading);
        };

        ctrl.viewIssueFile = (issue, language, event) => {
            event.preventDefault();
            event.stopPropagation();

            ReadNewsletterIssueServiceDowngrade.open(ctrl.customer.id, issue.newsletter_id, issue.id, language.code);
        };

        ctrl.deleteIssue = (issue) => {
            $mdDialog.show(
                $mdDialog.confirm()
                    .theme('delete')
                    .title(issue.title)
                    .textContent(t('newsletter:DELETE_ISSUE_TEXT'))
                    .ok(t('DELETE'))
                    .cancel(t('CANCEL')),
            ).then(() => {
                Newsletter.deleteIssue(ctrl.newsletter.customer_id, issue.newsletter_id, issue.id).then(() => {
                    ToastService.toast(t('newsletter:ISSUE_DELETED'));
                    ctrl.reloadTable = true;
                });
            });
        };
    } ],
});
