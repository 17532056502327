<eaw-page-header [fabButton]="createButton">
    <span title>{{'ROLE_plural' | translate: 'company' | async}}</span>
</eaw-page-header>

<eaw-role-assignment-data-table
    *ngIf="customer"
    #table
    class="mat-elevation-z2"
    [user]="employee?.user"
    [customer]="customer"
    [columns]="columns">
</eaw-role-assignment-data-table>
