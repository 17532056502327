<form [formGroup]="form" class="tw-grid tw-gap-16 tw-grid-cols-1 sm:tw-grid-cols-2">
    <mat-form-field>
        <mat-label>{{ 'FIRST_NAME' | translate | async }}</mat-label>
        <input matInput type="text" formControlName="firstName">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'LAST_NAME' | translate | async }}</mat-label>
        <input matInput type="text" formControlName="lastName">
    </mat-form-field>

    <mat-form-field eawDatePickerOptions>
        <mat-label>{{ 'EMPLOYMENT_FROM' | translate:'company' | async }}</mat-label>
        <input matInput formControlName="from" [matDatepicker]="fromPicker">
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field eawDatePickerOptions>
        <mat-label>{{ 'EMPLOYMENT_TO' | translate:'company' | async }}</mat-label>
        <input matInput formControlName="to" [matDatepicker]="toPicker">
        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
    </mat-form-field>

    <mat-divider class="tw-col-span-full tw-mt-8 tw-mb-20"></mat-divider>

    <div class="tw-flex tw-flex-col tw-gap-16 tw-col-span-full">
        <mat-radio-group formControlName="generateAutomatically" class="tw-flex tw-gap-8 tw-flex-col">
            <mat-radio-button [value]="false">{{ 'BADGE_NUMBER_MANUAL' | translate: 'company' | async }}</mat-radio-button>
            <mat-radio-button [value]="true">{{ 'AUTOGEN_BADGE_NUMBER_TOOLTIP' | translate: 'company' | async }}</mat-radio-button>
        </mat-radio-group>

        @if (!form.controls.generateAutomatically.value) {
            <mat-form-field class="tw-mt-8">
                <mat-label>{{ 'EMPLOYEE_NUMBER' | translate:'company' | async }}</mat-label>
                <input matInput type="number" inputmode="numeric" formControlName="customNumber" min="1" step="1">

                @if (form.controls.customNumber.status === 'PENDING') {
                    <mat-hint>{{ 'CHECKING_NUMBER_AVAILABILITY' | translate:'new_employee':{number: form.controls.customNumber.value} | async }}</mat-hint>
                }

                <mat-error><eaw-field-error [control]="form.controls.customNumber"/></mat-error>
            </mat-form-field>
        }
    </div>
</form>

@if (!step.completed) {
    <eaw-action-button [loading]="processing" [disabled]="form.invalid || form.status === 'PENDING'" (click)="create()">{{ 'SAVE_INFO' | translate:'new_employee' | async }}</eaw-action-button>
}

<eaw-new-employee-next-step [stepper]="stepper" [step]="step"/>

