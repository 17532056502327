import { Inject, Injectable } from '@angular/core';
import { DialogData, DialogSize } from '../../../../shared/dialogs/dialog-component';
import { MatDialog } from '@angular/material/dialog';
import { RotationDialogComponent } from './rotation-dialog.component';
import { Rotation } from '../../models/rotation';

export interface RotationDialogData extends DialogData {
    customerId: number;
    rotation?: Rotation;
}

@Injectable({
    providedIn: 'root',
})
export class RotationDialogService {

    constructor(@Inject(MatDialog) private dialog: MatDialog) {
    }

    create(customerId: number) {
        return this.dialog.open<RotationDialogComponent, RotationDialogData, Rotation>(RotationDialogComponent, {
            data: {
                size: DialogSize.Large,
                customerId,
            },
        });
    }

    update(rotation: Rotation) {
        return this.dialog.open<RotationDialogComponent, RotationDialogData, undefined>(RotationDialogComponent, {
            data: {
                size: DialogSize.Large,
                customerId: rotation.customerId,
                rotation,
            },
        });
    }
}
