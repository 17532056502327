// @ts-nocheck
import { module } from 'angular';
import { CustomFieldOld } from '../../../../../../shared/angularjs/custom-field-old';
import { t } from 'i18next';
module('eaw.payroll.paid-time').component('ptRegisterAbsence', {
    template: `
<md-input-container>
  <label>{{'ABSENCE_TYPE' | i18next}}</label>
<md-select ng-model="$ctrl.absenceType" ng-disabled="!$ctrl.absenceTypes" flex="100" required>
    <md-option ng-repeat="type in $ctrl.absenceTypes" ng-value="type" ng-bind="type.translatedName"></md-option>
</md-select>
</md-input-container>

<eaw-custom-fields ng-model="$ctrl.customFields"></eaw-custom-fields>

<md-input-container class="md-block">
    <label ng-i18next="COMMENT" for="comment"></label>
    <textarea id="comment" ng-model="$ctrl.comment" rows="1" ng-required="$ctrl.commentRequired"></textarea>
</md-input-container>

`,
    bindings: {
        segment: '<',
        parent: '<',
    },
    controller: [ '$scope', 'AbsenceTypeFactory', 'Absence', 'ToastService', '$mdDialog', function($scope, AbsenceTypeFactory, Absence, ToastService, $mdDialog) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.customFields = CustomFieldOld.getModelFields('absence');
            $scope.$on('HandleSomethingCtrl:handle', ctrl.register);

            ctrl.getAbsenceTypes();
        };
        ctrl.getAbsenceTypeName = (absenceType) => {
            const name = absenceType?.name;
            if (!name) {
                return '';
            }

            if (name.toUpperCase() === name) {
                return t('absence_types:' + name);
            }

            return name;
        };
        ctrl.getAbsenceTypes = () => {
            return AbsenceTypeFactory.getAll({
                entityId: ctrl.segment.employeePaidTime.customer.id,
                order_by: 'name',
                direction: 'asc',
                span: 'hour',
                per_page: 999,
            }).then((data) => {
                data.data = data.data.map((absenceType) => {
                    absenceType.translatedName = ctrl.getAbsenceTypeName(absenceType);
                    return absenceType;
                });

                ctrl.absenceTypes = data.data;
            });
        };
        ctrl.register = () => {
            const absence = new Absence({
                customer_id: ctrl.segment.employeePaidTime.customer.id,
                employee_id: ctrl.segment.employeePaidTime.employee.id,
                type_id: ctrl.absenceType?.id,
                from: ctrl.parent.interval.from,
                to: ctrl.parent.interval.to,
                comment: ctrl.comment,
                customFields: ctrl.customFields,
                approved: true,
            }, ctrl.segment.employeePaidTime.customer);
            absence.save().$promise.then(() => {
                $mdDialog.hide();
                ToastService.t('ABSENCE_ADDED', 'payroll');
            }).catch(() => {
                ctrl.parent.handling = false;
            });
        };
    } ],
});
