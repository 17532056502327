import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { PayRate, PayRateResponse } from '../models/pay-rate';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { RateType } from '../classes/rate';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';

interface PayRateCreateBody {
    type: RateType;
    from: DateTime;
    to?: DateTime | null;
    tariff_id?: number;
    rate?: number;
}

interface PayRateUpdateBody {
    rate?: number | null;
    type?: RateType | null;
    from?: DateTime;
    to?: DateTime | null;
}

interface GetAllOptions extends PaginationOptions {
    active?: DateTime;
    include_deleted?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class EmployeePayRateService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    get(customerId: number, employeeId: number, payRateId: number, options?: {'with:[]': string[]}) {
        return this.http.get<PayRateResponse>(`/customers/${customerId}/employees/${employeeId}/pay_rates/${payRateId}`, {
            params: formatHttpParams(options),
        }).pipe(classifyItem(PayRate));
    }

    getAll(customerId: number, employeeId: number, options?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<PayRateResponse>>(`/customers/${customerId}/employees/${employeeId}/pay_rates`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(PayRate));
    }

    create(customerId: number, employeeId: number, body: PayRateCreateBody) {
        return this.http.post<PayRateResponse>(`/customers/${customerId}/employees/${employeeId}/pay_rates`, body)
            .pipe(classifyItem(PayRate));
    }

    update(customerId: number, employeeId: number, payRateId: number, body: PayRateUpdateBody) {
        return this.http.put<PayRateResponse>(`/customers/${customerId}/employees/${employeeId}/pay_rates/${payRateId}`, body)
            .pipe(classifyItem(PayRate));
    }

    delete(customerId: number, employeeId: number, payRateId: number) {
        return this.http.delete<undefined>(`/customers/${customerId}/employees/${employeeId}/pay_rates/${payRateId}`);
    }
}
