// @ts-nocheck
import { module } from 'angular';
module('eaw.newsletter').controller('AddNewsletterLanguageDialogCtrl', [ '$mdDialog', 'Newsletter', function($mdDialog, Newsletter) {
    const ctrl = this;
    ctrl.$onInit = () => {
        ctrl.default = ctrl.language.is_default;
        ctrl.newsletterLanguages = ctrl.newsletterLanguages.reduce((arr, nl) => {
            if (!nl.added) {
                return arr;
            }
            if (ctrl.editing && nl.code === ctrl.language.code) {
                return arr;
            }
            return arr.concat({ ...nl });
        }, []);
    };
    ctrl.submit = () => {
        const newsletter = {
            id: ctrl.newsletter.key,
            customer_id: ctrl.newsletter.customer_id,
        };
        if (ctrl.default) {
            newsletter.language_code = ctrl.language.code;
            newsletter.default_title = ctrl.title;
            newsletter.default_description = ctrl.description;
            ctrl.newsletterLanguages.forEach((nl) => {
                nl.is_default = false;
            });
        }
        newsletter.languages = ctrl.newsletterLanguages.concat({
            code: ctrl.language.code,
            title: ctrl.title,
            description: ctrl.description,
            is_default: ctrl.default ?? false,
        });
        Newsletter.update(newsletter).then((newsletter) => $mdDialog.hide(newsletter));
    };
} ]);
