// @ts-nocheck
import { t } from 'i18next';
import { isObject } from 'lodash-es';
import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.calendar').service('OffTimeDataSource', [ 'CalendarEvent', 'OffTimeDialogService', 'ToastService', 'DataSource', 'OffTimeService', function OffTimeDataSourceClass(CalendarEvent, OffTimeDialogService, ToastService, DataSource, OffTimeService) {
    class OffTimeDataSource extends DataSource {
        constructor(customer) {
            super(customer);

            this.name = 'offtimes';
            this.i18n = OffTimeDataSource.getI18nName();
            this.useOnEmployee = true;
            this.active = false;
            this.noItemsText = t('calendar:NO_OFFTIMES_THIS_DAY');
            this.canUseSource = CurrentOld.can(`customers.${this.customer.id}.employees.*.off_times.*.get`);
            this.button = {
                text: t('calendar:OFF_TIME'),
                onClick: this.create.bind(this),
                icon: 'airplane-takeoff',
                hasPermission: CurrentOld.can(`customers.${this.customer.id}.employees.*.off_times.create`),
            };
            this.contextMenu = [
                {
                    text: t('EDIT'),
                    icon: 'edit',
                    hasPermission: CurrentOld.can(`customers.${this.customer.id}.employees.*.off_times.*.update`),
                },
            ];
        }

        /**
         * @param {moment.Moment} from
         * @param {moment.Moment} to
         * @param {Object} [employee]
         * @returns {Promise}
         */
        get(from, to, employee) {
            const offtime = new OffTimeService(this.customer.id, employee?.id);

            let promise = offtime.customer.getAll.query({
                from,
                to,
                approved: true,
                per_page: 999,
            }).$promise;

            if (isObject(employee)) {
                promise = offtime.employee.getAll.query({
                    from,
                    to,
                    approved: true,
                    per_page: 99,
                }).$promise;
            }

            return promise.then((resp) => {
                // Make data into events
                resp.data.forEach((d) => {
                    OffTimeDataSource.dataToCalendar(d).broadcastAdded();
                });
            });
        }

        /**
         * @param {moment.Moment} date
         * @param {Object} employee
         * @returns {Promise}
         */
        create(_, employee) {
            if (!employee?.id) {
                return ToastService.tToast('calendar:NO_EMP_SELECTED');
            }

            const offtime = new OffTimeService(this.customer.id, employee.id);

            OffTimeDialogService.open().then((newOfftime) => {
                offtime.create.query(null, newOfftime).$promise.then(() => {
                    ToastService.toast(t('vacation:REQUEST_SENT'));
                });
            });
        }

        static getI18nName() {
            return t('calendar:OFFTIME_plural');
        }

        static dataToCalendar(data) {
            data.dataSource = 'offtimes';
            data.color = 'deep-purple';
            data.name = `${data.name} - ${t('calendar:VACATION')}`;

            return new CalendarEvent(data);
        }
    }

    return OffTimeDataSource;
} ]);
