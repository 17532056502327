import { Component, inject, Input, OnInit } from '@angular/core';
import { PayrollConfigurationMeta, PayrollConfigurationService, PayrollConfigurationValue } from '../../http/payroll-configuration.service';
import { Setting } from '../../../shared/models/setting';
import { catchError, EMPTY, forkJoin, switchMap, tap } from 'rxjs';
import { SettingService } from '../../../shared/http/setting.service';
import { EditSettingValueDialogService } from '../../../shared/services/edit-setting-value-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { CustomerPropertyService } from '../../../shared/http/customer-property.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { PropertyValueDisplayComponent } from '../../../shared/components/property-value-displayer/property-value-display.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe, UpperCasePipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { PropertyValueDecoder } from '../../../shared/utils/property-value-decoder';
import { Namespace } from '../../../shared/enums/namespace';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { DateTime } from 'luxon';

@Component({
    selector: 'eaw-payroll-configuration',
    templateUrl: './payroll-configuration.component.html',
    styleUrl: './payroll-configuration.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatProgressSpinnerModule,
        MatExpansionModule,
        NgFor,
        PropertyValueDisplayComponent,
        MatRippleModule,
        MatTooltipModule,
        MatIconModule,
        MatIconSizeDirective,
        AsyncPipe,
        UpperCasePipe,
        TranslatePipe,
        InfoLoadingComponent,
        MaterialColorDirective,
    ],
})
export class PayrollConfigurationComponent implements OnInit {
    protected readonly payrollConfigurationService = inject(PayrollConfigurationService);
    protected readonly customerPropertyService = inject(CustomerPropertyService);
    protected readonly settingService = inject(SettingService);
    protected readonly editSettingValueDialogService = inject(EditSettingValueDialogService);
    protected readonly confirmDialogService = inject(ConfirmDialogService);
    protected readonly translateService = inject(TranslateService);

    @Input({ required: true }) customerId!: number;

    // Key of the property and whether it is loading
    loaders: Record<string, boolean> = {};
    loading = true;
    configurations: { meta: PayrollConfigurationMeta, settings: Setting[] }[] = [];
    translatedTargets?: Record<PayrollConfigurationValue['category'], string>;
    defaultValues: Record<string, PropertyValueDecoder> = {};

    ngOnInit() {
        forkJoin([
            this.getData(),
            this.translateTargets(),
        ]).subscribe();
    }

    edited(settings: Setting[]) {
        return settings.some((value) => value.setValue != null);
    }

    missingValue(settings: Setting[]) {
        return settings.some((value) => value.resolvedValue == null);
    }

    async translateTargets() {
        this.translatedTargets = {
            all: await this.translateService.t('ALL', Namespace.FrancePayroll),
            employee: await this.translateService.t('EMPLOYEE', Namespace.FrancePayroll),
            manager: await this.translateService.t('MANAGER', Namespace.FrancePayroll),
            executive: await this.translateService.t('EXECUTIVE', Namespace.FrancePayroll),
        };
    }

    getData() {
        return this.payrollConfigurationService.getAll(this.customerId).pipe(
            tap((configurations) => {
                this.loading = false;
                this.loaders = {};
                this.configurations = configurations;

                configurations.forEach((c) => {
                    this.defaultValues[c.meta.name] = new PropertyValueDecoder(c.meta.default, c.meta.data_type);
                });
            }),
        );
    }

    getTarget(setting: Setting) {
        const split = setting.key.split('.');
        return split[split.length - 1] as PayrollConfigurationValue['category'];
    }

    updateValue(setting: Setting) {
        if (setting.dataType === 'object') {
            this.editSettingValueDialogService.openKeyValue(setting).subscribe((value) => {
                if (value != undefined) {
                    this.update(value, setting);
                }
            });

            return;
        }

        this.editSettingValueDialogService.open(setting).subscribe((value) => {
            if (value != undefined) {
                this.update(value, setting);
            }
        });
    }

    private update(value: Record<string, string> | string, setting: Setting) {
        this.loaders[setting.key] = true;
        return this.settingService.update([ 'customers', this.customerId ], setting.key, value, DateTime.now().startOf('month')).pipe(
            catchError(() => {
                this.loaders[setting.key] = false;
                return EMPTY;
            }),
            switchMap(() => this.getData()),
        ).subscribe();
    }

    clearValue(setting: Setting) {
        const resetText = this.translateService.t('RESET');

        this.confirmDialogService.delete({
            title: resetText,
            confirmText: resetText,
            text: this.translateService.t('RESET_SOCIAL_RULE_PROMPT', 'france_social_rules'),
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.loaders[setting.key] = true;
            this.customerPropertyService.delete(this.customerId, setting.key).pipe(
                catchError(() => {
                    this.loaders[setting.key] = false;
                    return EMPTY;
                }),
                switchMap(() => this.getData()),
            ).subscribe();
        });
    }
}
