import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnInit } from '@angular/core';
import { FileDownloadService } from '../../services/file-download.service';
import { of } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import type { MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'eaw-image-attachment',
    templateUrl: './image-attachment.component.html',
    styleUrl: './image-attachment.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ImageAttachmentComponent implements OnInit {
    @HostListener('document:keydown.escape') onEscape() {
        this.zoom(false);
    }

    @HostListener('click') onClick() {
        if (this.isZoomed) {
            return;
        }
        this.zoom();
    }

    @Input() url?: string;
    @Input() dialogRef?: MatDialogRef<unknown>;

    isZoomed = false;
    src?: SafeResourceUrl;

    constructor(
        @Inject(FileDownloadService) private fileDownloadService: FileDownloadService,
        @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
        @Inject(ElementRef) private el: ElementRef,
    ) {
    }

    ngOnInit() {
        (this.url ? this.fileDownloadService.getImageHref(this.url) : of('')).subscribe((res) => {
            this.src = res;
            this.changeDetectorRef.markForCheck();
        });
    }

    zoom(zoom?: boolean, event?: Event) {
        event?.preventDefault();
        event?.stopPropagation();

        this.isZoomed = zoom ?? !this.isZoomed;

        if (this.dialogRef) {
            this.dialogRef.disableClose = this.isZoomed;
        }

        if (this.isZoomed) {
            this.el.nativeElement.classList.add('zoom');
        } else {
            this.el.nativeElement.classList.remove('zoom');
        }
    }
}
