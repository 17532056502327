import { ChangeDetectionStrategy, Component, inject, input, OnInit, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetComponent } from '../../classes/widget-component';
import { Widget } from '../../classes/widget';
import { MiniBalancesSettings } from './mini-balances-settings/mini-balances-settings.component';
import { BalanceService } from '../../../balances/http/balance.service';
import { DateTime } from 'luxon';
import { forkJoin } from 'rxjs';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { CurrentService } from '../../../shared/services/current.service';

@Component({
    selector: 'eaw-mini-balances',
    standalone: true,
    imports: [ CommonModule, MiniWidgetContentComponent ],
    templateUrl: './mini-balances.component.html',
    styleUrl: './mini-balances.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniBalancesComponent extends WidgetComponent implements OnInit {
    private balanceService = inject(BalanceService);
    private translateService = inject(TranslateService);
    private currentService = inject(CurrentService);

    widget = input.required<Widget<MiniBalancesSettings>>();

    text?: WritableSignal<string>;
    subtext?: WritableSignal<Promise<string>>;

    ngOnInit() {
        const interval = this.widget().getSetting('interval');
        const balanceCode = this.widget().getSetting('balanceCode');
        const employeeId = this.widget().info.employee?.id;
        const customerId = this.widget().info.customer.id;

        if (!(interval && balanceCode && employeeId)) {
            return this.setError('settings');
        }

        forkJoin([
            this.balanceService.getType(customerId, balanceCode),
            this.balanceService.getAllForEmployee(customerId, employeeId, [ balanceCode ], { date: DateTime.now().endOf(interval) }),
        ]).subscribe(([ balanceType, balances ]) => {
            if (!balanceType) {
                return this.setError('data');
            }

            const balance = balances[balanceType.balanceCode];
            const value = balance ? balance / balanceType.factor : 0;
            if (Number.isNaN(value)) {
                return this.setError('data');
            }

            this.text?.set(
                balanceType.getFormattedValue(value, this.currentService.languageTag, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    unitDisplay: 'long',
                }),
            );

            this.subtext?.set(this.translateService.t(balanceType.nameTranslation.key, balanceType.nameTranslation.ns));
            this.setLoading(false);
        });
    }
}
