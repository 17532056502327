<eaw-dialog-header>
    <span title>{{editing ? 'UPDATE_LANGUAGE' : 'ADD_LANGUAGE' | translate: 'admin' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form id="language-form" class="tw-flex tw-flex-col" [formGroup]="form" novalidate>
        <mat-form-field>
            <mat-label>{{'NAME' | translate | async}}</mat-label>
            <input type="text" name="name" formControlName="name" matInput autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'CODE' | translate | async}}</mat-label>
            <input type="text" name="code" formControlName="code" matInput autocomplete="off">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'BCP_47' | translate:'admin' | async}}</mat-label>
            <input type="text" name="ietfBcp47Tag" formControlName="ietfBcp47Tag" matInput autocomplete="off">
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="false" type="submit" formId="language-form" (click)="submit()" [disabled]="form.disabled || form.invalid">{{editing ? 'UPDATE_LANGUAGE' : 'ADD_LANGUAGE' | translate: 'admin' | async}}</eaw-action-button>
</mat-dialog-actions>
