// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.filesystem').component('eawUploadingBox', {
    template: `<md-card id="uploading-box" ng-if="$ctrl.uploads">
    <md-card-header md-colors="{background: 'grey-800'}">
        <md-card-header-text>
            <span class="md-title" ng-bind="$ctrl.uploadingStatusText"></span>
        </md-card-header-text>

        <md-button class="md-icon-button" ng-click="$ctrl.uploads = null">
            <md-icon ng-bind="'close'"></md-icon>
        </md-button>
    </md-card-header>
    <md-card-content ng-if="$ctrl.init.length || $ctrl.uploading.length">
        <div id="cancel-row"
             ng-click="$ctrl.cancelUploads()"
             ng-i18next="filesystem:CANCEL_ALL"
             md-colors="{color: 'blue-500', background: 'grey-100'}">
        </div>
    </md-card-content>
    <md-card-content>
        <div class="upload-row" layout="row" layout-align="space-between center" ng-repeat="(key, file) in $ctrl.uploads track by key">
            <eaw-mime-icon type="file.file.type"></eaw-mime-icon>

            <div class="content-column" layout="column" layout-align="center start">
                <strong ng-bind="file.file.name"></strong>
                <small ng-bind="file.file.sizeText"></small>
            </div>

            <!-- abort upload -->
            <md-icon class="abort-icon"
                     ng-click="$ctrl.cancelUpload(file)"
                     ng-if="file.status == 'init' || file.status == 'uploading'"
                     md-svg-src="stop-circle"
                     md-colors="{color: 'red'}">
            </md-icon>

            <!-- statuses -->
            <eaw-progress-circle ng-if="file.status == 'init' || file.status == 'uploading'" diameter="22" stroke-width="3" progress="file.status == 'init' ? 0 : file.progress * 100"></eaw-progress-circle>
            <md-icon class="status-icon" ng-if="file.status == 'success'" md-svg-src="check-circle" md-colors="{color: 'green'}"></md-icon>
            <md-icon class="status-icon" ng-if="file.status == 'error'" md-svg-src="alert-circle" md-colors="{color: 'red'}"></md-icon>
            <md-icon class="status-icon" ng-if="file.status == 'abort'" ng-bind="'cancel'" md-colors="{color: 'deep-orange'}"></md-icon>
        </div>
    </md-card-content>
</md-card>
`,
    controller: [ '$scope', '$element', function($scope, $element) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.show(false);

            $scope.$on('file:uploading', (event, file) => {
                ctrl.show(true);

                ctrl.uploads = ctrl.uploads || {}; // Use existing or create new empty
                ctrl.uploads[file.file.id] = { ...file }; // Spread file
                ctrl.uploadsArray = Object.values(ctrl.uploads);

                // Statuses
                ctrl.init = ctrl.uploadsArray.filter((f) => f.status === 'init');
                ctrl.uploading = ctrl.uploadsArray.filter((f) => f.status === 'uploading');
                ctrl.success = ctrl.uploadsArray.filter((f) => f.status === 'success');
                ctrl.error = ctrl.uploadsArray.filter((f) => f.status === 'error');
                ctrl.abort = ctrl.uploadsArray.filter((f) => f.status === 'abort');

                if (ctrl.init.length + ctrl.uploading.length > 0) {
                    ctrl.uploadingStatusText = t('filesystem:UPLOADING_ITEMS', {
                        count: ctrl.init.length + ctrl.uploading.length,
                    });
                }

                // If all complete
                if (ctrl.success.length + ctrl.error.length + ctrl.abort.length === ctrl.uploadsArray.length) {
                    ctrl.uploadingStatusText = t('filesystem:X_UPLOAD_COMPLETE', {
                        count: ctrl.success.length,
                    });
                }
            });
        };

        ctrl.cancelUpload = (file) => {
            file.upload.abort();
        };

        ctrl.cancelUploads = () => {
            ctrl.uploadsArray.forEach(ctrl.cancelUpload);
        };

        ctrl.show = (show) => {
            if (show) {
                $element[0].style.display = 'block';
            } else {
                $element[0].style.display = 'none';
            }
        };
    } ],
});
