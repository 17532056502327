// @ts-nocheck
import { t } from 'i18next';
import { orderBy } from 'lodash-es';
import { module } from 'angular';
import { Comment } from '../../../../shared/models/comment';
import { EawChart } from '../../../../shared/angularjs/modules/misc/services/eaw-chart';
module('eaw.scheduling.shifts').component('shiftInfo', {
    template: `<md-card ng-if="!$shift.shift" layout="column" layout-align="center center" flex="100">
    <md-card-content class="loading-shift" layout="column" layout-align="center center">
        <md-icon class="loading-icon" md-svg-src="{{$shift.noShift ? 'calendar-question' : 'calendar-search'}}"></md-icon>

        <span ng-if="$shift.noShift" ng-i18next="scheduling:SHIFT_NOT_EXIST"></span>
        <span ng-if="!$shift.noShift" ng-i18next="scheduling:LOADING_SHIFT"></span>
    </md-card-content>
</md-card>

<md-card ng-if="$shift.shift.warnings.length" md-colors="{background: 'amber-300'}">
    <md-card-content>
        <warnings-list-simple warnings="$shift.shift.warnings"></warnings-list-simple>
    </md-card-content>
</md-card>

<md-card ng-if="$shift.shift">
    <md-card-content class="tw-p-0">
        <table id="general-table" class="table table-striped">
            <tr>
                <td colspan="2" class="tw-p-0">
                    <div layout="row" layout-align="start center">
                        <a ng-if="$shift.shift.employee" ui-sref="eaw/app/company/employees/view/information({id: $shift.shift.employee_id})">
                            <eaw-profile-picture-old size="80" user="$shift.shift.employee.user" square="true"></eaw-profile-picture-old>
                        </a>

                        <eaw-profile-picture-old ng-if="!$shift.shift.employee" size="80" user="" square="true"></eaw-profile-picture-old>

                        <div class="tw-p-16" layout="column">
                            <span ng-if="$shift.shift.employee_id" ng-bind="$shift.shift.employee.name"></span>
                            <span ng-if="!$shift.shift.employee_id" ng-i18next="scheduling:UNASSIGNED"></span>
                            <small ng-if="$shift.shift.employee_id" ng-bind="$shift.shift.employee.birth_date | eawDuration:'long':'yMd'"></small>
                        </div>
                    </div>
                </td>
                <td>
                    <a ui-sref="eaw/app/company/employees/view/information({id: $shift.shift.employee_id})" ng-if="$shift.shift.employee">
                        <md-button class="md-raised" ng-i18next="GO_TO_EMP"></md-button>
                    </a>
                </td>
            </tr>
            <tr>
                <td bo-i18next="WHEN"></td>
                <td>
                    <div layout="column">
                        <span ng-bind="$shift.shift.from | momentRelative"></span>
                        <small ng-bind="$shift.shift.from | moment:'LL'"></small>
                    </div>
                </td>
                <td></td>
            </tr>

            <tr>
                <td bo-i18next="WHERE"></td>
                <td>
                    <div layout="column">
                        <span ng-bind="$shift.shift.schedule.customer.name"></span>
                    </div>
                </td>
                <td></td>
            </tr>

            <tr>
                <td bo-i18next="BUSINESS_DATE"></td>
                <td>
                    <div layout="column">
                        <span ng-bind="$shift.shift.business_date | moment:'LL'"></span>
                    </div>
                </td>
                <td></td>
            </tr>

            <tr>
                <td bo-i18next="FROM"></td>
                <td>
                    <div layout="column">
                        <span ng-bind="$shift.shift.from | moment:'LT'"></span>
                    </div>
                </td>
                <td></td>
            </tr>

            <tr>
                <td bo-i18next="TO"></td>
                <td>
                    <div layout="column">
                        <span ng-bind="$shift.shift.to | moment:'LT'"></span>
                    </div>
                </td>
                <td></td>
            </tr>

            <tr>
                <td bo-i18next="LENGTH"></td>
                <td>
                    <div layout="column">
                        <span ng-bind="$shift.shift.length | eawDuration:'long'"></span>
                    </div>
                </td>
                <td></td>
            </tr>

            <tr>
                <td bo-i18next="BREAK"></td>
                <td>
                    <div layout="column">
                        <span ng-bind="$shift.shift.length - $shift.shift.net_length | eawDuration:'long'"></span>
                    </div>
                </td>
                <td></td>
            </tr>

            <tr>
                <td>
                    <span bo-i18next="scheduling:SCHEDULE"></span>
                </td>
                <td>
                    <div layout="column">
                        <span ng-if="$shift.shift.schedule.name" ng-bind="$shift.shift.schedule.name"></span>
                        <span ng-if="!$shift.shift.schedule.name" ng-i18next="UNNAMED"></span>

                        <small ng-if="!$shift.shift.schedule.published_at" ng-i18next="scheduling:UNPUBLISHED"></small>
                        <small ng-if="$shift.shift.schedule.published_at" ng-i18next="scheduling:PUBLISHED"></small>
                    </div>
                </td>
                <td>
                    <a ui-sref="eaw/app/scheduling/schedules/list/view({id: $shift.shift.schedule_id})">
                        <md-button class="md-raised" ng-i18next="scheduling:GO_TO_SCHED"></md-button>
                    </a>
                </td>
            </tr>
        </table>
    </md-card-content>
</md-card>

<eaw-accordion ng-if="$shift.shift" multi-toggle="true">
    <eaw-accordion-panel id="comments-panel">
        <panel-header>
            <md-icon ng-bind="'comment'"></md-icon>
            <span ng-i18next="[i18next]({count: $shift.shift.comments.length})X_COMMENT"></span>
        </panel-header>
        <panel-content class="tw-p-0">
            <eaw-comment-list [angularjscomments]="$shift.shift.comments"></eaw-comment-list>
        </panel-content>
    </eaw-accordion-panel>

<!-- Add back when upgrading -->
<!--    <eaw-accordion-panel id="map-panel" on-open="$shift.createMap()">-->
<!--        <panel-header>-->
<!--            <md-icon ng-bind="'pin_drop'"></md-icon>-->
<!--            <span bo-i18next="LOCATION"></span>-->
<!--        </panel-header>-->
<!--        <panel-content class="tw-p-0">-->
<!--            <eaw-map ng-if="$shift.mapOptions" ng-model="$shift.map" ng-change="$shift.addMapButton()" options="$shift.mapOptions"></eaw-map>-->
<!--        </panel-content>-->
<!--    </eaw-accordion-panel>-->

    <eaw-accordion-panel id="periods-panel" on-open="$shift.createPeriodsChart()">
        <panel-header>
            <md-icon ng-bind="'work'"></md-icon>
            <span bo-i18next="scheduling:PERIOD_plural"></span>
        </panel-header>
        <panel-content class="tw-p-0">
            <div id="shift-periods-chart"></div>
        </panel-content>
    </eaw-accordion-panel>

    <eaw-accordion-panel id="qualifications-panel" on-open="$shift.createQualificationsChart()">
        <panel-header>
            <md-icon md-svg-src="card-account-details"></md-icon>
            <span bo-i18next="scheduling:QUALIFICATION_plural"></span>
        </panel-header>
        <panel-content class="tw-p-0">
            <div id="employee-qualifications-chart"></div>
        </panel-content>
    </eaw-accordion-panel>

    <eaw-accordion-panel id="cf-panel">
        <panel-header>
            <md-icon ng-bind="'label'"></md-icon>
            <span bo-i18next="CUSTOM_FIELD_plural"></span>
        </panel-header>
        <panel-content>
            <eaw-custom-field ng-repeat="field in $shift.shift.customFields track by field.id" ng-model="field" disabled></eaw-custom-field>
        </panel-content>
    </eaw-accordion-panel>

    <eaw-accordion-panel id="changes-panel">
        <panel-header>
            <md-icon ng-bind="'history'"></md-icon>
            <span bo-i18next="CHANGE_plural"></span>
        </panel-header>
        <panel-content>

            <eaw-accordion>
                <eaw-accordion-panel ng-repeat="ghost in $shift.ghosts">
                    <panel-header layout="column" layout-align="center start">
                        <span ng-i18next="[i18next]({count: ghost.changes.length})X_CHANGE"></span>
                        <small ng-bind="ghost.when"></small>
                    </panel-header>
                    <panel-content class="tw-p-0">
                        <md-list>
                            <md-list-item class="md-2-line md-long-text" ng-repeat="change in ghost.changes">
                                <div class="md-list-item-text">
                                    <h3 ng-bind="change.text"></h3>
                                    <p ng-bind="change.descriptiveText"></p>
                                </div>
                                <md-divider ng-if="!$last"></md-divider>
                            </md-list-item>
                        </md-list>
                    </panel-content>
                </eaw-accordion-panel>
            </eaw-accordion>

        </panel-content>
    </eaw-accordion-panel>
</eaw-accordion>
`,
    bindings: {
        customerId: '<',
        shiftId: '<',
    },
    controllerAs: '$shift',
    controller: [ 'Shift', 'Schedule', '$mdColorPalette', 'IconElement', function(Shift, Schedule, $mdColorPalette, IconElement) {
        const ctrl = this;
        ctrl.$onInit = async () => {
            try {
                ctrl.shift = await ctrl.getShift();
                ctrl.shift = new Shift(await ctrl.getShift(), new Schedule(ctrl.shift.schedule));
                ctrl.shift.comments = ctrl.shift.comments.map((c) => new Comment(c));
                // Is broken for some reason, nobody has complained, so wait until we upgrade
                // ctrl.setShiftChanges();
            } catch (e) {
                ctrl.noShift = true;
                console.error(e);
            }
        };
        ctrl.$onDestroy = () => {
            ctrl.shiftRes.$cancelRequest();
        };
        ctrl.setShiftChanges = () => {
            ctrl.ghosts = [];
            if (!ctrl.shift?.ghosts?.length) {
                return;
            }
            ctrl.shift.ghosts.forEach((g, i) => {
                ctrl.ghosts.push({
                    when: g.created_at.format('LLLL'),
                    changes: g.changes.map((c) => {
                        return {
                            text: shiftChangeTypes[c]?.text,
                            descriptiveText: shiftChangeTypes[c]?.descriptiveText(g, ctrl.shift.ghosts[i + 1] || ctrl.shift),
                        };
                    }),
                });
            });
            ctrl.ghosts.reverse();
        };
        ctrl.getShift = () => {
            ctrl.shiftRes = Shift.getShift(ctrl.customerId, null, ctrl.shiftId, {
                includeChangelog: true,
                with: [
                    'employee.qualifications',
                    'periods.businessUnit',
                    'qualifications',
                    'properties',
                    'comments',
                    'warnings',
                    'ghosts.employee',
                    'ghosts.editedByUser',
                    'swaps.applicants.employee.user',
                    'schedule',
                ],
            });
            return ctrl.shiftRes.$promise;
        };
        ctrl.addMapButton = () => {
            async function Control(el) {
                // Set CSS for the control border.
                const controlUI = document.createElement('div');
                controlUI.id = 'get-directions-btn';
                controlUI.title = 'Get directions';
                el.appendChild(controlUI);
                // Set CSS for the control interior.
                const controlText = document.createElement('div');
                controlText.innerHTML = 'Get directions';
                controlUI.appendChild(controlText);
                controlUI.addEventListener('click', () => {
                    window.open(`https://www.google.com/maps/dir/?api=1&destination=${ctrl.mapCenter.lat},${ctrl.mapCenter.lng}`, '_blank');
                });
                controlUI.prepend(await IconElement.get('assistant_direction'));
            }
            const el = document.createElement('div');
            Control(el);
            // @ts-ignore
            ctrl.map.controls[google.maps.ControlPosition.TOP_CENTER].push(el);
            // @ts-ignore
            new google.maps.Marker({
                position: ctrl.mapCenter,
                map: ctrl.map,
                title: ctrl.shift.schedule.customer.name,
            });
        };
        ctrl.createMap = () => {
            ctrl.mapCenter = {
                lat: ctrl.shift?.schedule?.customer?.latitude,
                lng: ctrl.shift?.schedule?.customer?.longitude,
            };
            ctrl.mapOptions = {
                zoom: 16,
                center: ctrl.mapCenter,
            };
            return Promise.resolve();
        };
        ctrl.createPeriodsChart = () => {
            const categories = ctrl.shift.periods.reduce((obj, p) => {
                if (p.description) {
                    p.category = p.description;
                } else {
                    p.category = p.break ? t('BREAK') : t('NO_DESCRIPTION');
                }
                obj[p.category] = obj[p.category] || [];
                obj[p.category].push(p);
                return obj;
            }, {});
            const data = Object.entries(categories).reduce((arr, cat, i) => {
                const [ name, periods ] = cat;
                periods.forEach((p) => {
                    arr.push({
                        label: `${p.from.format('LT')} - ${p.to.format('LT')}`,
                        x: Date.UTC(p.from.year(), p.from.month(), p.from.date(), p.from.hours(), p.from.minutes()),
                        x2: Date.UTC(p.to.year(), p.to.month(), p.to.date(), p.to.hours(), p.to.minutes()),
                        y: i,
                        color: p.backgroundColor || '#5a626a',
                    });
                });
                return arr;
            }, []);
            ctrl.periodsChart = new EawChart('shift-periods-chart', {
                chart: {
                    type: 'xrange',
                },
                title: {
                    text: ctrl.title,
                },
                tooltip: {
                    enabled: false,
                },
                xAxis: {
                    type: 'datetime',
                    min: Date.UTC(ctrl.shift.from.year(), ctrl.shift.from.month(), ctrl.shift.from.date(), ctrl.shift.from.hours(), ctrl.shift.from.minutes()),
                    max: Date.UTC(ctrl.shift.to.year(), ctrl.shift.to.month(), ctrl.shift.to.date(), ctrl.shift.to.hours(), ctrl.shift.to.minutes()),
                },
                yAxis: {
                    title: {
                        text: '',
                    },
                    categories: Object.keys(categories),
                },
                series: [ {
                    name: 'series 1',
                    pointWidth: 22,
                    data,
                    dataLabels: {
                        enabled: true,
                        formatter() {
                            return this.point.label;
                        },
                    },
                } ],
                legend: {
                    enabled: false,
                },
            });
            return Promise.resolve();
        };
        ctrl.createQualificationsChart = () => {
            let qualifications = [];
            // Add all qualifications from the shift
            ctrl.shift.all_qualifications.forEach((sq) => {
                const empQual = ctrl.shift.employee.qualifications.find((eq) => eq.id === sq.id);
                qualifications.push({
                    name: sq.name,
                    rate: (parseFloat(empQual?.pivot?.rate) || 0) * 100,
                });
            });
            // Sort highest first
            qualifications = orderBy(qualifications, [ 'rate', 'name' ], [ 'desc', 'asc' ]);
            ctrl.qualificationsChart = new EawChart('employee-qualifications-chart', {
                chart: {
                    type: 'bar',
                },
                title: {
                    text: ctrl.title,
                },
                xAxis: {
                    categories: qualifications.map((q) => q.name),
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    title: {
                        text: 'Qualified percentage',
                    },
                },
                plotOptions: {
                    series: {
                        stacking: 'normal',
                    },
                },
                // Shift qualifications has to be first to employee qualifications can come on top
                series: [
                    {
                        name: 'Rate',
                        data: qualifications.map((q) => q.rate),
                        color: $mdColorPalette.green[400].hex,
                        pointWidth: 20,
                    },
                ],
                legend: {
                    enabled: false,
                },
            });
            return Promise.resolve();
        };
    } ],
});
