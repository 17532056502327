<eaw-page-header>
    <span title>{{ role?.name }}</span>
</eaw-page-header>

@if (customer) {
    <mat-accordion multi displayMode="flat" class="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between md:tw-content-start">
        <mat-expansion-panel expanded #rolePanel class="tw-basis-1/3">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>{{ 'ROLE_ASSIGNMENT_plural' | translate: 'company' | async }}</span>
                </mat-panel-title>
                <div class="tw-pb-16 tw-pr-16" *ngIf="rolePanel.expanded">
                    <button mat-mini-fab *ngIf="createButton.click" (click)="$event.stopPropagation(); createButton.click()" class="primary">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </mat-expansion-panel-header>
            <eaw-role-assignment-data-table #assignments *ngIf="!!role" [columns]="columns" [customer]="customer" [role]="role"></eaw-role-assignment-data-table>
        </mat-expansion-panel>

        <mat-expansion-panel expanded #permissionPanel class="tw-basis-1/3">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>{{ 'PERMISSION_plural' | translate | async }}</span>
                </mat-panel-title>
                <div class="tw-pb-16 tw-pr-16" *ngIf="permissionPanel.expanded">
                    <button mat-mini-fab (click)="$event.stopPropagation(); permissions.createPermission()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </mat-expansion-panel-header>
            <eaw-permission-table #permissions [customer]="customer" [roleId]="roleId"></eaw-permission-table>
        </mat-expansion-panel>

        <mat-expansion-panel expanded #setPanel class="tw-basis-1/3">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>{{ 'PERMISSIONSET_plural' | translate | async }}</span>
                </mat-panel-title>
                <div class="tw-pb-16 tw-pr-16" *ngIf="setPanel.expanded">
                    <button mat-mini-fab (click)="$event.stopPropagation(); permissionSets.attachSet()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </mat-expansion-panel-header>
            <eaw-permission-set-table #permissionSets [customer]="customer" [roleId]="roleId"></eaw-permission-set-table>
        </mat-expansion-panel>
    </mat-accordion>
} @else {
    <eaw-info-loading size="lg"/>
}
