// @ts-nocheck
import { module } from 'angular';
module('eaw.ai-budgeting').factory('mlComparisonFactory', [ 'EawResource', function comparisonFactory(EawResource) {
    const fac = this;
    fac.getForecastAndActual = {
        permission(customer, bucket, variable) {
            return `customers.${customer}.ml_buckets.${bucket}.variables.${variable}.comparisons.*.get`;
        },
        /**
         * @param {Number} customer
         * @param {String} bucket
         * @param {String} variable
         * @param {moment.Moment} from
         * @param {moment.Moment} to
         * @param {Number} interval
         * @returns {*}
         */
        query(customer, bucket, variable, from, to, interval) {
            return EawResource.create(`customers/${customer}/ml_buckets/${bucket}/variables/${variable}/comparisons`).get({
                from: from.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
                to: to.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
                interval,
            }).$promise;
        },
    };
    return fac;
} ]);
