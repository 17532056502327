// @ts-nocheck
import { module } from 'angular';

module('eaw.dashboard').controller('widgetHeaderMenu', [ 'mdPanelRef', function(mdPanelRef) {
    const ctrl = this;

    ctrl.click = (item) => {
        mdPanelRef.close();
        item.click();
    };
} ]);
