import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { uniqueId } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BaseApiModel } from '../../shared/models/base-api-model';

export interface LanguageResponse extends ApiResponse {
    code: string;
    created_at: string;
    iso639_1: string;
    name: string;
    updated_at: string;
    // This only comes from the language controller api
    users_count?: number;
}

export class Language extends BaseApiModel<LanguageResponse, Language> {
    // For usage in autocomplete
    id = uniqueId();
    /**
     * This would be the same as the code in WebTranslateIt
     *
     * @see https://webtranslateit.com/docs/locale
     */
    code: string;
    /**
     * In the format of 'en-US' or 'da-DK'
     */
    languageTag: string;
    iso639_1: string;
    name: string;
    usersCount?: number;
    createdAt: DateTime;
    updatedAt: DateTime;
    /**
     * This is the last part of a language tag, e.g. 'US' in 'en-US'
     */
    countryCode?: string;

    constructor(data: LanguageResponse) {
        super(data, undefined);

        this.name = data.name || this.getJsDisplayName();
        this.code = data.code;
        this.languageTag = this.getLanguageTag();
        this.iso639_1 = data.iso639_1;
        this.usersCount = data.users_count;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.countryCode = this.languageTag.split('-')[1]?.toUpperCase();
    }

    private getLanguageTag() {
        switch (this.code) {
            case 'da':
                return 'da-DK';
            case 'en':
                return 'en-US';
            case 'fi':
                return 'fi-FI';
            case 'fr':
                return 'fr-FR';
            case 'no':
                return 'nb-NO';
            case 'pl':
                return 'pl-PL';
            case 'sv':
                return 'sv-SE';
            default:
                return this.code;
        }
    }

    private getJsDisplayName() {
        try {
            return new Intl.DisplayNames([ this.languageTag ], { type: 'language' }).of(this.languageTag) || '';
        } catch (_) {
            return '';
        }
    }
}
