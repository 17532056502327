import { ChangeDetectionStrategy, Component, DestroyRef, Inject, inject, signal } from '@angular/core';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef } from '@angular/material/dialog';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButton } from '@angular/material/button';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatIcon } from '@angular/material/icon';
import { UserCustomerAutocompleteService } from '../../../shared/autocompletes/user-customer-autocomplete.service';
import { CustomerUserGroupAutocompleteService } from '../../../shared/autocompletes/customer-user-group-autocomplete.service';
import { Customer } from '../../../shared/models/customer';
import { DateTimeInputComponent } from '../../../shared/components/date-time/date-time-input/date-time-input.component';
import { DateTimeRangeInputComponent } from '../../../shared/components/date-time/date-time-range-input/date-time-range-input.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { DateTime } from 'luxon';
import { UserGroupService } from '../../../shared/http/user-group.service';
import { catchError, EMPTY, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserGroup } from '../../../company/models/user-group';

interface AdminUserGroupsDialogData extends DialogData {
    userId: number;
}

@Component({
    selector: 'eaw-admin-user-groups-group-dialog',
    standalone: true,
    imports: [
        AsyncPipe,
        DialogHeaderComponent,
        TranslatePipe,
        ActionButtonComponent,
        MatButton,
        MatDialogActions,
        MatDialogClose,
        AutocompleteComponent,
        MatIcon,
        ReactiveFormsModule,
        MatDialogContent,
        JsonPipe,
        DateTimeInputComponent,
        DateTimeRangeInputComponent,
        MatFormField,
        MatLabel,
        NgIf,
    ],
    templateUrl: './admin-user-groups-group-dialog.component.html',
    styleUrl: './admin-user-groups-group-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUserGroupsGroupDialogComponent extends DialogComponent {
    private destroyedRef = inject(DestroyRef);
    userCustomerAutocompleteService = inject(UserCustomerAutocompleteService);
    customerUserGroupAutocompleteService = inject(CustomerUserGroupAutocompleteService);
    userGroupService = inject(UserGroupService);

    loading = signal(false);

    userId: number;

    form = new FormGroup({
        customer: new FormControl<Customer | null>(null, Validators.required),
        userGroup: new FormControl<UserGroup | null>(null, Validators.required),
        dateRange: new FormGroup({
            from: new FormControl<DateTime | null>(null, Validators.required),
            to: new FormControl<DateTime | null>(null, Validators.required),
        }),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AdminUserGroupsDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AdminUserGroupsGroupDialogComponent>,
    ) {
        super(dialogRef, data);
        this.userId = data.userId;
        data.size = DialogSize.Medium;
    }

    submit(): void {
        const { userGroup, dateRange } = this.form.value;
        if (!userGroup || !dateRange) {
            return;
        }
        this.loading.set(true);
        this.form.disable();
        const body = {
            member_id: this.userId,
            group: {
                owner_id: userGroup.ownerId,
                id: userGroup.id,
            },
            from: dateRange.from || null,
            to: dateRange.to,
        };
        this.userGroupService.addMember(body).pipe(
            takeUntilDestroyed(this.destroyedRef),
            tap(() => {
                this.loading.set(false);
                this.form.enable();
            }),
            catchError(() => {
                this.loading.set(false);
                this.form.enable();
                return EMPTY;
            })).subscribe((resp) => this.dialogRef.close(resp));
    }
}
