import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BusinessUnitService, CreateBusinessUnit, UpdateBusinessUnit } from '../../http/business-unit.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { BusinessUnit } from '../../models/business-unit';
import { EditBusinessUnitComponent, EditBusinessUnitData, EditBusinessUnitResult } from '../../dialogs/edit-business-unit/edit-business-unit.component';
import { MatDialog } from '@angular/material/dialog';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { BusinessUnitTree } from '../../models/business-unit-tree';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

@Component({
    selector: 'eaw-company-business-units',
    templateUrl: './company-business-units.component.html',
    styleUrl: './company-business-units.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatOptionModule,
        NgFor,
        MatExpansionModule,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        TranslateSyncPipe,
    ],
})
export class CompanyBusinessUnitsComponent implements OnInit, OnDestroy {
    @Input({ required: true }) customerId!: number;

    units: BusinessUnit[] = [];
    default?: BusinessUnit;
    gettingUnits: boolean = false;
    addUnitButton: HeaderFabButton = {
        click: this.editUnit.bind(this),
        hide: () => !this.units,
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.business_units.create`),
    };

    defaultCtrl: FormControl<BusinessUnit | null>;
    tree?: BusinessUnitTree;

    private subscriber?: Subscription;

    constructor(
        @Inject(BusinessUnitService) protected businessUnitService: BusinessUnitService,
        @Inject(SnackBarService) protected snackbar: SnackBarService,
        @Inject(ConfirmDialogService) protected confirmDialog: ConfirmDialogService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(MatDialog) protected dialog: MatDialog,
    ) {
        this.defaultCtrl = new FormControl<BusinessUnit | null>(null);
    }

    ngOnInit(): void {
        this.updateList();
    }

    ngOnDestroy(): void {
        this.subscriber?.unsubscribe();
    }

    editUnit(unit?: BusinessUnit) {
        this.dialog.open<EditBusinessUnitComponent, EditBusinessUnitData, EditBusinessUnitResult>(EditBusinessUnitComponent, {
            data: {
                customerId: this.customerId,
                units: this.units || [],
                unit,
            },
        }).afterClosed().subscribe((unit) => {
            if (!unit) {
                return;
            }

            if (unit.id) {
                this.businessUnitService.update(this.customerId, unit as UpdateBusinessUnit).subscribe(() => {
                    this.snackbar.updated();
                    this.updateList();
                });
            } else {
                this.businessUnitService.create(this.customerId, unit as CreateBusinessUnit).subscribe(() => {
                    this.snackbar.created();
                    this.updateList();
                });
            }
        });
    }

    deleteUnit(unit: BusinessUnit) {
        this.confirmDialog.delete({ name: unit.name }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                return;
            }

            this.businessUnitService.delete(this.customerId, unit.id).subscribe(() => {
                this.snackbar.deleted();
                this.updateList();
            });
        });
    }

    updateDefault(change: MatSelectChange) {
        const unit = change.value as BusinessUnit;

        if (!this.units || !this.default || unit.id == this.default?.id) {
            return;
        }

        this.defaultCtrl.disable();
        this.businessUnitService.update(this.customerId, {
            id: unit?.id || this.default.id,
            default: !!unit,
        }).subscribe({
            next: () => {
                this.updateList();
                this.defaultCtrl.enable();
            },
            error: () => {
                this.defaultCtrl.enable();
            },
        });
    }

    onUnits(units: BusinessUnit[]) {
        this.defaultCtrl.enable();
        this.units = units;
        this.default = this.units.find((u: BusinessUnit) => u.default);
        this.defaultCtrl.setValue(this.default ?? null);
        this.gettingUnits = false;

        this.tree = new BusinessUnitTree([ ...units ]);
    }

    updateList() {
        if (this.gettingUnits) {
            return;
        }

        this.defaultCtrl.disable();
        this.gettingUnits = true;
        this.subscriber = this.businessUnitService.getAllPages(this.customerId, {
            per_page: 100,
            order_by: 'name',
            direction: 'asc',
            'with[]': [ 'qualifications', 'properties' ],
        }).subscribe(this.onUnits.bind(this));
    }
}
