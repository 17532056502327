import { inject, Injectable } from '@angular/core';
import { PdfRendererDialogComponent } from '../../shared/dialogs/pdf-renderer-dialog/pdf-renderer-dialog.component';
import { PdfRendererDialogData } from '../../shared/dialogs/pdf-renderer-dialog/pdf-renderer-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { NewsletterIssueService } from '../http/newsletter-issue.service';

@Injectable({
    providedIn: 'root',
})
export class ReadNewsletterIssueService {
    private matDialog = inject(MatDialog);
    private newsletterIssueService = inject(NewsletterIssueService);

    open(customerId: number, newsletterId: number, issueId: number, language: string) {
        return this.matDialog.open<PdfRendererDialogComponent, PdfRendererDialogData>(PdfRendererDialogComponent, {
            data: {
                url: this.newsletterIssueService.getFileObjectUrl(customerId, newsletterId, issueId, language),
            },
        });
    }
}
