import { Component, Inject, OnDestroy } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Todo } from '../../models/todo';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { User } from '../../../shared/models/user';
import { DateTime } from 'luxon';
import { TodoService } from '../../http/todo.service';
import { forkJoin, of, Subscription, switchMap } from 'rxjs';
import { TodoAttachmentService } from '../../http/todo-attachment.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AttachmentListComponent } from '../../../shared/components/attachment-list/attachment-list.component';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateTimeInputComponent } from '../../../shared/components/date-time/date-time-input/date-time-input.component';
import { CustomerUserAutocompleteComponent } from '../../../shared/components/customer-user-autocomplete/customer-user-autocomplete.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface CreateTodoDialogData extends DialogData {
    customerId: number;
    userId?: number;
}

@Component({
    selector: 'eaw-create-todo-dialog',
    templateUrl: './create-todo-dialog.component.html',
    styleUrl: './create-todo-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        MatTabsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        CustomerUserAutocompleteComponent,
        DateTimeInputComponent,
        MatCheckboxModule,
        CheckboxHelperDirective,
        AttachmentListComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class CreateTodoDialogComponent extends DialogComponent implements OnDestroy {
    attachments: File[] = [];
    minDate = DateTime.now();
    form = new FormGroup({
        title: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        responsible: new FormControl<User | number | null | undefined>(null),
        publishAt: new FormControl<DateTime | null>(null),
        dueDate: new FormControl<DateTime | null>(null),
        notify: new FormControl(false, { nonNullable: true }),
    });

    private subscription?: Subscription;

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<CreateTodoDialogComponent, Todo>,
        @Inject(MAT_DIALOG_DATA) override data: CreateTodoDialogData,
        @Inject(TodoService) private todoService: TodoService,
        @Inject(TodoAttachmentService) private todoAttachmentService: TodoAttachmentService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        if (data.userId) {
            this.form.controls.responsible.setValue(data.userId);
            this.form.controls.responsible.disable();
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    attachmentChange(attachments: File[]) {
        this.attachments = attachments;
    }

    createTodo() {
        void this.snackBarService.t('CREATING_TODO', 'todo');

        const responsible = this.form.controls.responsible.value;

        this.subscription = this.todoService.create(this.data.customerId, {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            description: this.form.controls.description.value!,
            title: this.form.controls.title.value,
            responsible_id: responsible instanceof User ? responsible.id : responsible,
            notify: this.form.controls.notify.value,
            from: this.form.controls.publishAt.value || undefined,
            due: this.form.controls.dueDate.value || undefined,
        }).pipe(
            switchMap((todo) => {
                if (this.attachments.length) {
                    void this.snackBarService.t('UPLOADING_ATTACHMENT_plural', 'messages');

                    return forkJoin(this.attachments.map((a) => this.todoAttachmentService.create(todo.customerId, todo.id, a))).pipe(
                        switchMap(() => of(todo)),
                    );
                }

                return of(todo);
            }),
        ).subscribe((todo) => {
            void this.snackBarService.t('TODO_CREATED', 'todo');
            this.dialogRef.close(todo);
        });
    }
}
