import { Moment } from 'moment-timezone';
import { environment } from '../../../../../../environments/environment';
import { TimeObject } from '../../../../components/date-time/time-input/time-input.component';
import { DateTime } from 'luxon';
import { DateObjectUnits } from 'luxon/src/datetime';
import { Mobile } from '../../../../utils/eaw-mobile';

export function timeStringToHourMin(string?: string | null): TimeObject | undefined {
    const timeString = string?.replace(/\D/g, '').trim() || '';

    if (!timeString.length || timeString.length > 4) {
        return undefined;
    }

    let hours;
    let minutes = '0';

    if (timeString.length === 4) {
        hours = timeString.slice(0, 2);
        minutes = timeString.slice(2);
    } else if (timeString.length === 3) {
        hours = timeString.slice(0, 1);
        minutes = timeString.slice(1);
    } else {
        hours = timeString;
    }

    const hourNum = parseInt(hours);
    const minuteNum = parseInt(minutes);

    if (Number.isInteger(hourNum) && Number.isInteger(minuteNum)) {
        if (hourNum > 23 || minuteNum > 59) {
            return undefined;
        }

        return {
            hour: hourNum,
            minute: minuteNum,
        };
    }

    return undefined;
}

export function reportError(e: Error) {
    if (environment.isProduction) {
        newrelic.noticeError(e);
    } else {
        console.error('Error report ⚠', e);
    }
}

export function groupBy<T extends Record<string, any>>(list: T[], itemKey: keyof T) {
    const map = new Map<keyof T, T[]>();

    list.forEach((item) => {
        const collectionKey = item[itemKey];
        const collection = map.get(collectionKey);
        if (!collection) {
            map.set(collectionKey, [item]);
        } else {
            collection.push(item);
        }
    });

    return map;
}


export function inRange(number: number, low: number, high: number): boolean {
    return number >= low && number <= high;
}

export function clamp(number: number, min: number, max: number): number {
    if (number > max) {
        return max;
    }
    if (number < min) {
        return min;
    }

    return number;
}

let idIncrementor = 0;

export function uniqueId(): number;
export function uniqueId(prefix: string): string;
export function uniqueId(prefix?: string) {
    return prefix ? `${prefix}${++idIncrementor}` : ++idIncrementor;
}

/**
 * Removes accents and diacritics in a string
 * @see https://stackoverflow.com/a/37511463/3623300
 */
export function normalizeString(string: string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function filter<Item>(filter = '', items: Item[], func: (i: Item) => string): Item[] {
    if (!items.length) {
        return items;
    } // Return empty array if no items to filter
    if (!filter.length) {
        return items;
    } // We know we have items, so if no filter then return the items

    const cleanFilter = normalizeString(filter?.toLowerCase() || '');
    return items.filter((i) => normalizeString(func(i)?.toLowerCase()).includes(cleanFilter));
}

export function smallDevice() {
    return Mobile.isMobile || window.screen.width < 960; // gt-sm
}

/**
 * Sorts an array of items by multiple functions
 * @param items
 * @param languageCode
 * @param functions
 * @param orders - Defaults to 'asc' for all functions
 * @param options
 */
export function sort<Item>(items: Item[] = [], languageCode: string, functions: ((i: Item) => any)[] = [(i) => i], orders: ('asc' | 'desc')[] = [], options: Intl.CollatorOptions = {}): Item[] {
    if (!items.length) {
        return items;
    } // Return empty array if no items to sort

    const functionOrders = functions.map((f, i) => {
        return {
            function: f,
            order: orders[i] || 'asc',
        };
    });

    const collator = new Intl.Collator(languageCode, {
        numeric: true,
        ...options,
    });

    return items.sort((a, b) => {
        for (const func of functionOrders) {
            const comp1 = func.order === 'asc' ? a : b;
            const comp2 = func.order === 'asc' ? b : a;
            const result = collator.compare(func.function(comp1), func.function(comp2));

            if (result !== 0) {
                return result;
            }
        }

        return 0;
    });
}

export function overlaps(item1From: Moment | number, item1To: Moment | number, item2From: Moment | number, item2To: Moment | number, allowTouch = false): boolean {
    return allowTouch ? !(item1To <= item2From || item1From >= item2To) : !(item1To < item2From || item1From > item2To);
}

export function getOverlap(item1From: Moment | number, item1To: Moment | number, item2From: Moment | number, item2To: Moment | number) {
    if (!overlaps(item1From, item1To, item2From, item2To)) {
        return 0;
    }

    const low = item1From > item2From ? item1From : item2From;
    const high = item1To < item2To ? item1To : item2To;

    return +high - +low;
}

export function sortByOrder<T extends Record<string, any>>(arrayToSort: T[], arrayWithOrder: string[], keyToSortBy: keyof T, options?: { prependUnorderedItems: boolean }): T[] {
    const orderedArray: T[] = [];
    const added: Set<string> = new Set();

    // Order items that exist in the array we sort by
    arrayWithOrder.forEach((order) => {
        arrayToSort.forEach((item) => {
            if (String(item[keyToSortBy]) === order) {
                added.add(order);
                orderedArray.push(item);
            }
        });
    });

    // Add everything that was missing
    arrayToSort.filter((item) => !added.has(String(item[keyToSortBy]))).forEach((item) => {
        if (options?.prependUnorderedItems) {
            orderedArray.unshift(item);
        } else {
            orderedArray.push(item);
        }
    });

    return orderedArray;
}

type TimeIntervalArg = DateObjectUnits | DateTime;

export function getTimeInterval(baseDate: TimeIntervalArg, from: TimeIntervalArg, to: TimeIntervalArg): { from: DateTime, to: DateTime };
export function getTimeInterval(baseDate: TimeIntervalArg, from: TimeIntervalArg, to: TimeIntervalArg | null | undefined): { from: DateTime, to: DateTime | null };
export function getTimeInterval(baseDate: undefined | null, from: undefined | null, to: TimeIntervalArg | null | undefined): null;
export function getTimeInterval(baseDate: TimeIntervalArg | null | undefined, from: TimeIntervalArg | null | undefined, to: TimeIntervalArg | null | undefined): { from: DateTime, to: DateTime | null } | null;
export function getTimeInterval(baseDate: TimeIntervalArg | null | undefined, from: TimeIntervalArg | null | undefined, to: TimeIntervalArg | null | undefined): { from: DateTime, to: DateTime | null } | null {
    const baseDateUnits = baseDate instanceof DateTime ? baseDate.toObject() : baseDate;
    const fromUnits = from instanceof DateTime ? from.toObject() : from;
    const toUnits = to instanceof DateTime ? to.toObject() : to;

    if (!baseDateUnits) {
        return null;
    }
    if (!fromUnits) {
        return null;
    }

    const fromDateTime = DateTime.fromObject({
        ...baseDateUnits,
        hour: fromUnits.hour,
        minute: fromUnits.minute,
        second: fromUnits.second || 0,
        millisecond: fromUnits.millisecond || 0,
    });

    let toDateTime: DateTime | null = null;

    if (toUnits) {
        toDateTime = DateTime.fromObject({
            ...baseDateUnits,
            hour: toUnits.hour,
            minute: toUnits.minute,
            second: toUnits.second || 0,
            millisecond: toUnits.millisecond || 0,
        });

        if (toDateTime.toMillis() < fromDateTime.toMillis()) {
            toDateTime = toDateTime.plus({days: 1});
        }
    }

    return {
        from: fromDateTime,
        to: toDateTime,
    };
}
