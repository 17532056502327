<eaw-page-header [useBigFilter]="true" [bigFilterToggled]="true">
    <span title>{{ 'AI_PROJECTION_plural' | translate: 'ai_budgeting' | async }}</span>
    <div bigFilter>
        <form class="tw-grid sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-8" [formGroup]="form">
            <mat-form-field eawDatePickerOptions>
                <mat-label>{{ 'ENTER_DATE_RANGE' | translate | async }}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
                    <input matStartDate formControlName="from" [placeholder]="'FROM' | translate | async" autocomplete="off" required>
                    <input matEndDate formControlName="to" [placeholder]="'TO' | translate | async" autocomplete="off" required>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </form>
        <eaw-action-button [disabled]="!form.valid" [loading]="loading" (click)="updateTable()">{{ 'UPDATE' | translate | async }}</eaw-action-button>
    </div>
</eaw-page-header>
@if (loading) {
    <mat-card class="tw-p-16">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </mat-card>
} @else {
    <mat-card class="tw-relative mat-compact-card">
        <mat-card-content>
            @if (fetching) {
                <div class="fetching">
                    <mat-spinner></mat-spinner>
                </div>
            }
            <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="dateTop">
                    <th mat-header-cell *matHeaderCellDef>{{ 'DATE' | translate: 'ai_budgeting' | async }}</th>
                </ng-container>

                <ng-container matColumnDef="dailyDetailsTop">
                    <th mat-header-cell *matHeaderCellDef>{{ 'DAILY_DETAILS' | translate: 'ai_budgeting' | async }}</th>
                </ng-container>

                <ng-container matColumnDef="gc">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">{{ 'GC' | translate: 'ai_budgeting' | async }}</th>
                </ng-container>

                <ng-container matColumnDef="at">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">{{ 'AVERAGE_TRAY' | translate: 'ai_budgeting' | async }}</th>
                </ng-container>

                <ng-container matColumnDef="salesProjectionTop">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">{{ 'SALES' | translate: 'ai_budgeting' | async }}</th>
                </ng-container>

                <ng-container matColumnDef="tagsTop">
                    <th mat-header-cell *matHeaderCellDef>{{ 'TAG_plural' | translate: 'ai_budgeting' | async }}</th>
                </ng-container>

                <ng-container matColumnDef="referenceDaysTop">
                    <th mat-header-cell *matHeaderCellDef>{{ 'REFERENCE_DAY_plural' | translate: 'ai_budgeting' | async }}</th>
                </ng-container>

                <ng-container matColumnDef="commentsLastYearTop">
                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMENT_N_1' | translate: 'ai_budgeting' | async }}</th>
                </ng-container>

                <!-- second row -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item" [class.holiday]="item.holiday" [ngClass]="item.holiday ? item.holiday : ''" [matTooltip]="(item.holidayTooltip | translate: 'ai_budgeting' | async) || ''">
                        <div class="tw-flex tw-items-center tw-justify-center tw-gap-4">
                            @if (item.loading) {
                                <mat-spinner diameter="16" strokeWidth="2"></mat-spinner>
                            }
                            @if (item.date) {
                                <span>{{ item.date | DateTime:'DATE_MED_WITH_WEEKDAY' }}</span>
                            }
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>{{ 'TOTAL' | translate | async }}</td>
                </ng-container>
                <ng-container matColumnDef="dailyDetails">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item">
                        <button mat-icon-button (click)="openDetailsDialog(item)" [disabled]="item.chosenReferenceDays.length === 0">
                            <mat-icon>desktop_windows</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="gcAiProjection">
                    <th mat-header-cell class="tw-text-right" *matHeaderCellDef>{{ 'AI_PROJECTION' | translate: 'ai_budgeting' | async }}</th>
                    <td mat-cell class="tw-text-right" *matCellDef="let item">{{ item.projections.transactions?.value || 0 | eawNumber: 0 }}</td>
                    <td mat-footer-cell class="tw-text-right" *matFooterCellDef>{{ totals.gcAiProjections | eawNumber: 0 }}</td>
                </ng-container>
                <ng-container matColumnDef="gcScheduleProjection">
                    <th mat-header-cell class="tw-text-right" *matHeaderCellDef>{{ 'PROJECTION_FOR_SCHEDULE' | translate: 'ai_budgeting' | async }}</th>
                    <td mat-cell class="tw-text-right" *matCellDef="let item">
                        @if (item.blocked) {
                            <span class="tw-pr-16">{{ item.form.controls.gcScheduleProjection.value | eawNumber: 0 }}</span>
                        } @else {
                            <input matInput type="text" autocomplete="off" [formControl]="item.form.controls.gcScheduleProjection" (change)="gcScheduleProjectionChange(item)">
                        }
                    </td>
                    <td mat-footer-cell class="tw-text-right" *matFooterCellDef>
                        <span class="tw-pr-16">{{ totals.gcScheduleProjections | eawNumber: 0 }}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="gcDiffYear">
                    <th mat-header-cell class="tw-text-right" *matHeaderCellDef>% vs N-1</th>
                    <td mat-cell class="tw-text-right" *matCellDef="let item">{{ item.percentageVsPrevYear.transactions > 0 ? '+' : '' }}{{ item.percentageVsPrevYear.transactions | percent:'1.2' }}</td>
                    <td mat-footer-cell class="tw-text-right" *matFooterCellDef>{{ totals.gcDiffYear > 0 ? '+' : '' }}{{ totals.gcDiffYear | percent:'1.2' }}</td>
                </ng-container>
                <ng-container matColumnDef="atAiProjection">
                    <th mat-header-cell class="tw-text-right" *matHeaderCellDef>{{ 'AI_PROJECTION' | translate: 'ai_budgeting' | async }}</th>
                    <td mat-cell class="tw-text-right" *matCellDef="let item">{{ item.projections.atGenerated.value | eawNumber:2 }}</td>
                    <td mat-footer-cell class="tw-text-right" *matFooterCellDef>{{ (totals.gcScheduleProjections > 0 ? totals.salesAiProjections / totals.gcAiProjections : '0') | eawNumber: 2 }}</td>
                </ng-container>
                <ng-container matColumnDef="atScheduleProjection">
                    <th mat-header-cell class="tw-text-right" *matHeaderCellDef>{{ 'PROJECTION_FOR_SCHEDULE' | translate: 'ai_budgeting' | async }}</th>
                    <td mat-cell class="tw-text-right" *matCellDef="let item">
                        @if (item.blocked) {
                            <span class="tw-pr-16">{{ item.form.controls.atScheduleProjection.value | eawNumber: 2 }}</span>
                        } @else {
                            <input matInput type="text" autocomplete="off" [formControl]="item.form.controls.atScheduleProjection" (change)="atScheduleProjectionChange(item)">

                        }
                    </td>
                    <td mat-footer-cell class="tw-text-right" *matFooterCellDef>
                        <span class="tw-pr-16">{{ (totals.gcAiProjections > 0 ? totals.salesScheduleProjections / totals.gcScheduleProjections : '0') | eawNumber: 2 }}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="atDiffYear">
                    <th mat-header-cell *matHeaderCellDef>% vs N-1</th>
                    <td mat-cell *matCellDef="let item">{{ item.percentageVsPrevYear.at > 0 ? '+' : '' }}{{ item.percentageVsPrevYear.at | percent:'1.2' }}</td>
                    <td mat-footer-cell *matFooterCellDef>{{ totals.atDiffYear > 0 ? '+' : '' }}{{ totals.atDiffYear | percent:'1.2' }}</td>
                </ng-container>
                <ng-container matColumnDef="salesProjection">
                    <th mat-header-cell class="tw-text-right" *matHeaderCellDef>{{ 'PROJECTION_FOR_SCHEDULE' | translate: 'ai_budgeting' | async }}</th>
                    <td mat-cell class="tw-text-right" *matCellDef="let item">{{ item.projections.salesGenerated?.value || item.projections.sales?.value || 0 | eawNumber: 0 }}</td>
                    <td mat-footer-cell class="tw-text-right" *matFooterCellDef>{{ totals.salesScheduleProjections | eawNumber: 0 }}</td>
                </ng-container>
                <ng-container matColumnDef="salesDiffYear">
                    <th mat-header-cell class="tw-text-right" *matHeaderCellDef>% vs N-1</th>
                    <td mat-cell class="tw-text-right" *matCellDef="let item">{{ item.percentageVsPrevYear.sales > 0 ? '+' : '' }}{{ item.percentageVsPrevYear.sales | percent:'1.2' }}</td>
                    <td mat-footer-cell class="tw-text-right" *matFooterCellDef>{{ totals.salesDiffYear > 0 ? '+' : '' }}{{ totals.salesDiffYear | percent:'1.2' }}</td>
                </ng-container>
                <ng-container matColumnDef="tags">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item">
                        <mat-select [formControl]="item.form.controls.tag" [(value)]="item.tag" [panelWidth]="200" (selectionChange)="tagChange(item.date.toFormat('yyyy-MM-dd'), $event)">
                            <mat-option>{{ 'NONE' | translateSync }}</mat-option>
                            @for (tag of tags; track $index) {
                                @if (tag === item.name) {
                                    <mat-option selected [value]="tag">{{ tag }}</mat-option>
                                } @else {
                                    <mat-option [value]="tag">{{ tag }}</mat-option>
                                }
                            }
                        </mat-select>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="referenceDays">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item">
                        <button mat-icon-button (click)="openReferenceDaysDialog(item)">
                            <mat-icon>calendar_month</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="commentsLastYear">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item">{{ item.commentLastYear }}</td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['dateTop', 'dailyDetailsTop', 'gc', 'at', 'salesProjectionTop', 'tagsTop', 'referenceDaysTop', 'commentsLastYearTop']"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.loading]="row.loading"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell tw-pt-16 tw-pb-16" colspan="2">{{ 'NO_DATA' | translate | async }}</td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>
}
