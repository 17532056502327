import { module } from 'angular';
import { CurrentOld } from '../../../current.factory';

module('eaw.misc').filter('eawNumber', () => {
    function numberFormatter(number: unknown, decimals = 0, sign?: boolean, compactDisplay?: 'short' | 'long') {
        if (!Number.isFinite(number)) {
            return number;
        }

        try {
            return new Intl.NumberFormat(CurrentOld.user.languageTag, {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
                notation: compactDisplay ? 'compact' : 'standard',
                compactDisplay,
                signDisplay: sign ? 'exceptZero' : 'auto',
            }).format(number as number);
        } catch (e) {
            console.error(e);
            return number;
        }
    }

    return numberFormatter;
});
