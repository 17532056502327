// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';

const dialogTemplate = `<md-dialog>
    <eaw-dialog-header-old heading=" 'checklists:START_NEW_CHECKLIST' | i18next"></eaw-dialog-header-old>
    <form name="startChecklistDialogForm" ng-cloak ng-submit="$ctrl.submit()">
        <md-dialog-content>
            <div class="md-dialog-content" layout="column">
                <md-autocomplete
                        flex="100"
                        md-selected-item="$ctrl.selectedTemplate"
                        md-search-text="searchText"
                        md-clear-button="true"
                        required
                        md-delay="300"
                        md-input-name="newListTemplate"
                        md-floating-label="{{'checklists:CHECKLIST_TEMPLATE' | i18next}}"
                        md-items="item in $ctrl.getTemplates(searchText)"
                        md-item-text="item.title"
                        md-min-length="0">
                    <md-item-template>
                        <span md-highlight-text="searchText" md-highlight-flags="gi">
                            {{item.title}}
                        </span>
                        <span md-colors="{color: 'red'}" ng-show="!item.canStart">*</span>
                    </md-item-template>
                    <md-not-found>
                        <span ng-i18next="NO_MATCH_FOUND"></span>
                    </md-not-found>
                </md-autocomplete>

                <md-input-container>
                    <label ng-i18next="TITLE"></label>
                    <input ng-model="$ctrl.title" required uniq-form-name>
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <eaw-submit-btn></eaw-submit-btn>
        </md-dialog-actions>
    </form>
</md-dialog>
`;
export class StartChecklistDialogController {
    $mdDialog;
    ChecklistFactory;

    static get $inject() {
        return [ '$mdDialog', 'ChecklistFactory' ];
    }

    customer;
    title = '';
    selectedTemplate;
    constructor($mdDialog, ChecklistFactory) {
        this.$mdDialog = $mdDialog;
        this.ChecklistFactory = ChecklistFactory;
    }

    getTemplates(searchText) {
        return this.ChecklistFactory.getAll({
            customer: this.customer,
            template: true,
            filter: searchText,
        }).$promise.then((resp) => {
            return resp.data.map((t) => {
                t.canStart = CurrentOld.can(`customers.{customer}.checklists.${t.id}.start`);
                return t;
            });
        });
    }

    submit() {
        this.ChecklistFactory.create(this.customer, {
            title: this.title,
            template_id: this.selectedTemplate?.id,
        }).$promise.then(() => {
            this.$mdDialog.hide();
        });
    }
}
export class StartChecklistDialogService {
    $mdDialog;
    static get $inject() {
        return [ '$mdDialog' ];
    }

    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    open(customer) {
        return this.$mdDialog.show({
            template: dialogTemplate,
            controllerAs: '$ctrl',
            multiple: true,
            bindToController: true,
            locals: {
                customer,
            },
            controller: StartChecklistDialogController,
        });
    }
}
module('eaw.checklists').service('startDialog', StartChecklistDialogService);
