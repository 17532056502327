// @ts-nocheck
import { module } from 'angular';

module('eaw.filesystem').controller('filesystemContextMenuCtrl', [ 'mdPanelRef', function(mdPanelRef) {
    const ctrl = this;

    ctrl.click = (item) => {
        mdPanelRef.close();
        item.click(ctrl.item);
    };
} ]);
