import { module } from 'angular';
import { BalanceType } from '../../../../../balances/models/balance-type';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="$settings.widget.i18name | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <md-input-container>
                <label ng-i18next="TIME"></label>
                <md-select ng-model="$settings.time" required>
                    <md-option ng-repeat="time in $settings.times" ng-value="time.type" ng-i18next="{{time.translation}}"></md-option>
                </md-select>
            </md-input-container>

            <md-input-container>
                <label ng-i18next="TYPE"></label>
                <md-select ng-model="$settings.type" required ng-disabled="!$settings.balances.length">
                    <md-option ng-repeat="bal in $settings.balances" ng-value="bal.balanceCode" ng-i18next="{{bal.nameTranslation.ns + ':' + bal.nameTranslation.key}}"></md-option>
                </md-select>
            </md-input-container>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-click="$settings.submit()" ng-i18next="SUBMIT" ng-disabled="!$settings.balances.length || !$settings.type"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.dashboard').factory('miniBalancesSettings', [ '$mdDialog', 'EawResource', function($mdDialog: any, EawResource: any) {
    return function(widget: any) {
        return $mdDialog.show({
            template: template1,
            controllerAs: '$settings',
            multiple: true,
            controller: function() {
                const ctrl = this;
                ctrl.$onInit = async () => {
                    ctrl.cancel = $mdDialog.cancel;
                    ctrl.widget = widget;
                    ctrl.times = [
                        {
                            type: 'year',
                            translation: 'YEAR',
                        },
                        {
                            type: 'week',
                            translation: 'WEEK',
                        },
                        {
                            type: 'day',
                            translation: 'DAY',
                        },
                    ];
                    ctrl.time = ctrl.widget.settings.time;
                    ctrl.type = ctrl.widget.settings.type;
                    EawResource.create(`/customers/${CurrentOld.getCustomer()['id']}/balance_types`).get().$promise.then((resp: any) => {
                        ctrl.balances = Object.entries(resp.data).map(([ key, value ]) => {
                            return new BalanceType(key, value as any);
                        });
                    });
                };
                ctrl.submit = () => {
                    $mdDialog.hide({
                        time: ctrl.time,
                        type: ctrl.type,
                    });
                };
            },
        });
    };
} ]);
