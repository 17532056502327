// @ts-nocheck
import { module } from 'angular';

module('eaw.dialogs').directive('eawCancelDialog', [ '$mdDialog', ($mdDialog) => {
    return {
        restrict: 'A',
        scope: {
            eawCancelDialog: '<?',
        },
        link(scope, element) {
            element[0].addEventListener('click', () => {
                // @ts-ignore
                $mdDialog.cancel(scope.eawCancelDialog);
            });
        },
    };
} ]);
