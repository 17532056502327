import { module } from 'angular';
import { Products } from '../../../shared/enums/products';
import { Namespace } from '../../../shared/enums/namespace';
import { dependencyResolver } from '../../../shared/resolvers/dependency.resolver';
import { staticResolver } from '../../../shared/resolvers/static.resolver';
import moment from 'moment-timezone';
import { CustomFieldOld } from '../../../shared/angularjs/custom-field-old';
import { StateProvider } from '@uirouter/angularjs';

module('eaw.payroll.paid-time', [
    'eaw.time',
    'eaw.login',
    'eaw.absence',
    'eaw.period-locks',
]).config([ '$stateProvider', ($stateProvider: StateProvider) => {
    $stateProvider.state(`eaw/app/payroll/paid_time`, {
        parent: `eaw/app/payroll`,
        url: '/paid_time',
        abstract: true,
        data: {
            products: [ Products.PaidTime ],
            i18nextNs: [ Namespace.Absences, Namespace.Vacation, Namespace.AbsenceTypes, Namespace.Leaveshifts ],
            breadcrumb: { key: 'PAID_TIME', ns: 'navigation' },
        },
        views: {
            'content@': {
                component: 'eawNavBar',
            },
        },
        resolve: [
            staticResolver('navbarRoute', 'eaw/app/payroll/paid_time'),
            staticResolver('viewName', 'nav-view'),
            staticResolver('tabs', [
                {
                    label: 'DAY',
                    src: 'eaw/app/payroll/paid_time/day',
                },
                {
                    label: 'EMPLOYEE',
                    src: 'eaw/app/payroll/paid_time/employee',
                },
            ]),
            dependencyResolver('paidTimeSettings', [ 'CustomerSettings', 'customer', 'PaidTimeSettings' ], function(CustomerSettings, customer, PaidTimeSettings) {
                return CustomerSettings.get(customer.id, 'paidtime.comment.required').then((resp: any) => {
                    PaidTimeSettings.set('paidtime.comment.required', resp['paidtime.comment.required']);
                    return true;
                });
            }),
            // Old custom fields are used here, so set them up
            dependencyResolver('customFieldsSetter', [ '$http', 'customer' ], function($http: angular.IHttpService, customer) {
                return CustomFieldOld.storeFields(customer.id, $http);
            }),
        ],
    });
    $stateProvider.state('eaw/app/payroll/paid_time/day', {
        parent: `eaw/app/payroll/paid_time`,
        url: '/day?:date',
        params: {
            date: {
                dynamic: true,
                squash: true,
                type: 'date',
            },
        },
        data: {
            permissions: [ `customers.{customer}.employees.*.paid_times.*.get` ],
            i18nextNs: [
                Namespace.AbsenceTypes,
                Namespace.Absences,
                Namespace.Vacation,
                Namespace.Company,
                Namespace.Scheduling,
                Namespace.Payroll,
            ],
            breadcrumb: { key: 'DAY' },
        },
        views: {
            'nav-view': { component: 'paidTimeDay' },
        },
    });
    $stateProvider.state(`eaw/app/payroll/paid_time/employee`, {
        parent: `eaw/app/payroll/paid_time`,
        url: '/employee?:from&:to&:e&:locations',
        views: {
            'nav-view': { component: 'paidTimeEmployee' },
        },
        data: {
            i18nextNs: [
                Namespace.AbsenceTypes,
                Namespace.Absences,
                Namespace.Vacation,
                Namespace.Company,
            ],
            breadcrumb: { key: 'EMPLOYEE' },
        },
        resolve: [
            staticResolver('employee', null),
        ],
        params: {
            from: {
                dynamic: true,
                squash: true,
                type: 'date',
            },
            to: {
                dynamic: true,
                squash: true,
                type: 'date',
            },
            e: {
                dynamic: true,
                squash: true,
                type: 'string',
            },
            locations: {
                dynamic: true,
                squash: true,
                type: 'string',
            },
        },
    });
    $stateProvider.state('eaw/app/payroll/paid_time/my', {
        parent: 'eaw/app/payroll/my',
        url: '/paid_time',
        data: {
            i18nextNs: [
                Namespace.AbsenceTypes,
                Namespace.Absences,
                Namespace.Vacation,
                Namespace.Company,
            ],
            breadcrumb: { key: 'MY_PAID_TIME', ns: 'navigation' },
        },
        views: {
            myPayrollView: 'paidTimeEmployee',
        },
        params: {
            from: {
                dynamic: true,
                type: 'date',
                squash: true,
                value: moment().startOf('month').toDate(),
            },
            to: {
                dynamic: true,
                type: 'date',
                squash: true,
                value: moment().endOf('month').toDate(),
            },
            locations: {
                dynamic: true,
                type: 'string',
                squash: true,
                value: '',
            },
        },
    });
} ]);
