// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';

const template2 = `<md-dialog>
    <eaw-dialog-header-old heading="'tbs:PUNCH_PROPOSAL' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <md-input-container>
                <label bo-i18next="EMPLOYEE"></label>
                <p ng-bind="dialog.proposal.employee.name"></p>
            </md-input-container>
            <div layout="row">
                <section>
                    <h3 bo-i18next="tbs:PUNCH_PROPOSAL"></h3>
                    <md-input-container>
                        <label bo-i18next="BUSINESS_DATE"></label>
                        <p ng-bind="dialog.proposal.business_date | moment:'date'"></p>
                    </md-input-container>
                    <md-input-container>
                        <label bo-i18next="payroll:IN"></label>
                        <p ng-bind="dialog.proposal.in | moment:'datetime'"></p>
                    </md-input-container>
                    <md-input-container>
                        <label bo-i18next="payroll:OUT"></label>
                        <p ng-bind="dialog.proposal.out | moment:'time'"></p>
                    </md-input-container>
                    <md-input-container>
                        <label bo-i18next="LENGTH"></label>
                        <p ng-bind="dialog.proposal.length | eawDuration"></p>
                    </md-input-container>
                    <md-input-container>
                        <label bo-i18next="tbs:CONTEXT"></label>
                        <p>
                            <span bo-i18next="tbs:{{::dialog.proposal.contextName}}"></span>
                            (<span ng-bind="dialog.proposal.context"></span>)
                        </p>
                    </md-input-container>
                </section>
                <section>
                    <h3 bo-i18next="payroll:TIMEPUNCH"></h3>
                    <md-input-container>
                        <label bo-i18next="BUSINESS_DATE"></label>
                        <p ng-bind="dialog.timepunch.business_date | moment:'date'"></p>
                    </md-input-container>
                    <md-input-container>
                        <label bo-i18next="payroll:IN"></label>
                        <p ng-bind="dialog.timepunch.in | moment:'datetime'"></p>
                    </md-input-container>
                    <md-input-container>
                        <label bo-i18next="payroll:OUT"></label>
                        <p ng-bind="dialog.timepunch.out | moment:'time'"></p>
                    </md-input-container>
                    <md-input-container>
                        <label bo-i18next="LENGTH"></label>
                        <p ng-bind="dialog.timepunch.length | eawDuration"></p>
                    </md-input-container>
                </section>
            </div>
        </div>
    </md-dialog-content>
    <md-dialog-actions layout="row">
        <md-button close-dialog bo-i18next="CLOSE"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

const template1 = `<md-card ng-show="manage.showFilter">
    <md-card-content>
        <form name="filterForm" ng-submit="manage.update()">
            <eaw-date-interval layout="row" ng-model="manage.interval" required></eaw-date-interval>

            <employee-autocomplete
                    ng-model="manage.employee"
                    from="manage.interval.from"
                    to="manage.interval.to"
                    customer-id="manage.customer.id">
            </employee-autocomplete>

            <md-checkbox ng-model="manage.include_timepunches">
                <span bo-i18next="tbs:INCLUDE_TIMEPUNCHES"></span>
            </md-checkbox>
            <br/>

            <md-button type="submit" class="md-accent md-raised tw-m-0" ng-disabled="filterForm.$invalid || !manage.interval.from && manage.interval.to || manage.interval.to && !manage.interval.from">
                <span bo-i18next="UPDATE"></span>
            </md-button>
        </form>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="tbs:PUNCH_PROPOSAL_plural"></span>
        </md-card-header-text>
        <eaw-toggle-btn ng-model="manage.showFilter"
                        toggled-initially="true"
                        icon="filter_alt"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>
    </md-card-header>
    <md-card-content layout="row" ng-if="manage.loading" layout-align="center center">
        <md-progress-circular></md-progress-circular>
    </md-card-content>
    <md-card-content class="dynamic-ng-table-container" ng-show="!manage.loading">
        <table class="table table-hover table-condensed sticky-header" ng-show="!manage.loading">
            <thead>
            <tr>
                <th class="text-center" ng-if="!manage.employee" bo-i18next="EMPLOYEE"></th>
                <th class="text-center" bo-i18next="BUSINESS_DATE"></th>
                <th class="text-center" bo-i18next="DATE"></th>
                <th bo-i18next="IN"></th>
                <th bo-i18next="OUT"></th>
                <th bo-i18next="LENGTH"></th>
                <th class="text-center" bo-i18next="tbs:CONTEXT"></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="timepunch-row" ng-repeat-start="item in manage.items" ng-if="item.type == 'timepunch'">
                <td ng-if="!manage.employee" ng-bind="item.employee.name"></td>
                <td class="date-cell" ng-bind="item.in | moment:'L'"></td>
                <td class="date-cell" ng-bind="item.business_date | moment:'date'"></td>
                <td ng-bind="item.in | moment:'time'"></td>
                <td ng-bind="item.out | moment:'time'"></td>
                <td class="text-center" ng-bind="item.length | eawDuration"></td>
                <td class="text-center" ng-if="!item.context" bo-i18next="NA"></td>
                <td class="text-center" ng-if="item.context" bo-i18next="tbs:{{::item.context}}"></td>
                <td layout="row" layout-align="center end">
                    <eaw-icon-button ng-click="manage.viewProposal(item)" icon="info" eaw-invisible="{{!item.proposal}}"></eaw-icon-button>
                </td>
            </tr>
            <tr class="proposal-row" ng-repeat-end ng-if="item.type != 'timepunch'">
                <td ng-if="!manage.employee" ng-bind="item.employee.name"></td>
                <td>
                    <arrow-dates ng-model="item.business_date" ng-disabled="item.saving" format="L"></arrow-dates>
                </td>
                <td>
                    <arrow-dates ng-model="item.date" ng-change="manage.changedTime(item)" ng-disabled="item.saving"></arrow-dates>
                </td>
                <td>
                    <md-input-container>
                        <eaw-time-picker-old ng-model="item.in" ng-change="manage.changedTime(item)" ng-disabled="item.saving" label="''" base-date="item.date" ></eaw-time-picker-old>
                    </md-input-container>
                </td>
                <td >
                    <md-input-container ng-if="!item.long">
                        <eaw-time-picker-old ng-model="item.out" ng-change="manage.changedTime(item)" ng-disabled="item.saving" base-date="item.date" label="''"></eaw-time-picker-old>
                    </md-input-container>
                    <md-input-container ng-if="item.long">
                        <eaw-date-time-picker
                                              ng-model="item.out"
                                              ng-change="manage.changedTime(item)"
                                              ng-disabled="item.saving">
                        </eaw-date-time-picker>
                    </md-input-container>
                </td>
                <td class="text-center" ng-bind="item.length | eawDuration"></td>
                <td>
                    <span ng-if="item.timepunch_id" bo-i18next="tbs:{{::item.contextName}}"></span>
                    <md-select ng-model="item.context" ng-if="!item.timepunch_id" ng-disabled="item.saving">
                        <md-option ng-repeat="(ctx, name) in manage.contexts"
                                   ng-value="ctx"
                                   ng-i18next="tbs:{{::name}}">
                        </md-option>
                    </md-select>
                </td>
                <td layout="row" layout-align="center end">
                    <eaw-icon-button ng-click="manage.saveProposal($index, item)" ng-disabled="item.saving" icon="save" type="accent" tooltip="'tbs:CREATE_TIMEPUNCH' | i18next" eaw-invisible="{{manage.hideSave(item)}}"></eaw-icon-button>
                    <eaw-icon-button ng-click="manage.deleteProposal($index, item)" ng-disabled="item.saving" icon="delete" type="warn" tooltip="'DELETE' | i18next" eaw-invisible="{{manage.hideDelete(item)}}"></eaw-icon-button>
                </td>
            </tr>
            </tbody>
        </table>
    </md-card-content>
    <md-card-footer layout="row" layout-align="space-between center">
        <eaw-pagination flex load-page="manage.getProposals(pagination)"></eaw-pagination>
    </md-card-footer>
</md-card>
`;

module('eaw.tbs').component('managePunchProposals', {
    controllerAs: 'manage',
    template: template1,
    bindings: {
        customer: '<',
    },
    controller: [ 'PunchProposal', 'deleteItemAlert', '$mdDialog', '$scope', function(PunchProposal, deleteItemAlert, $mdDialog, $scope) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.interval = {
                from: moment().startOf('month'),
                to: moment().endOf('month'),
            };
            ctrl.contexts = PunchProposal.contexts;

            ctrl.update();
        };

        ctrl.hideDelete = (item) => !CurrentOld.can(`customers.${item.customer_id}.employees.${item.employee_id}.punch_proposals.${item.id}.delete`);
        ctrl.hideSave = (item) => !CurrentOld.can(`customers.${item.customer_id}.employees.${item.employee_id}.timepunches.create`);

        ctrl.saveProposal = (i, proposal) => {
            proposal.saving = true;
            proposal.createTimepunch().$promise.then((p) => {
                p.employee = proposal.employee;
                ctrl.items[i] = ctrl.formatItem(p);
                ctrl.items = [].concat(ctrl.items);
            }, () => delete proposal.saving).catch(console.error);
        };

        ctrl.deleteProposal = (i, proposal) => {
            deleteItemAlert.alertSimple().then(() => {
                proposal.delete().then(() => {
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete ctrl.items[i];
                    ctrl.items = ctrl.items.filter((p) => p);
                });
            });
        };

        ctrl.viewProposal = (timepunch) => {
            $mdDialog.show({
                template: template2,
                controllerAs: 'dialog',
                bindToController: true,
                locals: {
                    proposal: timepunch.proposal,
                    timepunch,
                },
            });
        };

        ctrl.update = () => {
            $scope.$broadcast('eawPagination:trigger');
        };

        ctrl.getProposals = (pagination) => {
            ctrl.loading = true;

            const w = [ 'timepunch' ];
            if (!ctrl.employee) {
                w.push('employee');
            }

            return PunchProposal.getAll(Object.assign(pagination, {
                customer_id: ctrl.customer.id,
                employee_id: ctrl.employee?.id,
                from: ctrl.interval?.from,
                to: ctrl.interval?.to,
                include_timepunches: ctrl.include_timepunches,
                with: w,
            })).$promise
                .then((resp) => {
                    ctrl.items = resp.data.map(ctrl.formatItem);

                    return resp;
                })
                .finally(() => delete ctrl.loading);
        };

        ctrl.formatItem = (prop) => {
            let proposal = prop;

            if (proposal.context) {
                proposal = new PunchProposal(prop);
            } else {
                prop.type = 'timepunch';
            }

            if (prop.timepunch) {
                // eslint-disable-next-line no-param-reassign
                prop = prop.timepunch;
                prop.type = 'timepunch';
                prop.context = proposal.contextName;
                prop.proposal = proposal;
                prop.employee = proposal.employee;

                delete proposal.timepunch;

                return prop;
            }

            return proposal;
        };
    } ],
});
