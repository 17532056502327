import { debounceTime, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { ArrayPaginatedResponse } from '../../interfaces/paginated-response';
import { OnChange } from '../../types/on-change';
import { OnTouched } from '../../types/on-touched';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { TranslateSyncPipe } from '../../pipes/translate-sync.pipe';

// Type the autocomplete item(s) have to fit
type AutocompleteItem = {
    id?: number;
    [key: string]: any;
};

export type UpdateListFunction<Item> = (filter?: string) => Observable<ArrayPaginatedResponse<Item>>;

@Component({
    standalone: true,
    selector: 'eaw-basic-autocomplete',
    templateUrl: './basic-autocomplete.component.html',
    styleUrl: './basic-autocomplete.component.scss' ,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: BasicAutocompleteComponent,
        },
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        MatAutocompleteModule,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
/**
 * @deprecated
 * @see AutocompleteComponent
 */
export class BasicAutocompleteComponent<Item extends AutocompleteItem> implements ControlValueAccessor, OnChanges, OnDestroy {
    @Input({ required: true }) getList!: UpdateListFunction<Item>;

    @Input()
    set hasPrefix(value: BooleanInput) {
        this._hasPrefix = coerceBooleanProperty(value);
    }

    @Input() label?: Promise<string | null>;
    @Input() trackByProperty: keyof Item = 'id';
    // What to display in the input field
    @Input() display?: keyof Item | ((item: Item) => string);
    @Input() debounce = 1000;
    // How many characters are required for the filter to be passed along
    @Input() filterRequirement = 0;
    @Input() disabled?: boolean;
    @Input() icon?: string;
    @Input() optionTemplate?: TemplateRef<any>;

    @Input()
    set required(req: BooleanInput) {
        this._required = coerceBooleanProperty(req);
    }

    _required = false;
    item?: Item;
    items: Item[] = [];
    loading = false;
    filter?: string;
    total = 0;
    results = 0;
    filterControl = new FormControl<string | Item>('');
    trackBy = (_: number, item: Item) => item[this.trackByProperty];

    protected focused?: boolean;
    protected onChange?: OnChange<Item | undefined>;
    protected onTouched?: OnTouched;
    protected filterChanges: Subscription;
    protected _hasPrefix = false;

    constructor(
        @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.filterChanges = this.filterControl.valueChanges.pipe(
            debounceTime(this.debounce),
            distinctUntilChanged(),
        ).subscribe((filter) => {
            if (this.disabled || typeof filter != 'string') {
                return;
            }

            // Item is removed if a new filter is applied
            this.item = undefined;

            if (this.filterRequirement) {
                if (filter.length === 0) {
                    this.updateList(undefined);
                } else if (filter.length >= this.filterRequirement) {
                    this.updateList(filter);
                }
            } else {
                this.updateList(filter.length ? filter : undefined);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['disabled']) {
            this.setDisabledState(changes['disabled'].currentValue);
        }
    }

    ngOnDestroy() {
        this.filterChanges.unsubscribe();
    }

    /**
     * Has to be an arrow function to access this
     */
    autocompleteDisplay = (item?: Item) => {
        if (!item) {
            return null;
        }
        if (typeof this.display === 'function') {
            return this.display(item);
        }
        if (this.display) {
            return item[this.display];
        }
        if (typeof item === 'object' && 'name' in item) {
            return item['name'];
        }

        return null;
    };

    onSelectionChange(event: { source: { value: Item } }) {
        // Set the item
        this.item = event.source.value;

        // Remove all items except selected item
        this.items = this.items.filter((l) => l.id === this.item?.id);

        // We only have one item now, so update accordingly
        this.total = 1;
        this.results = 1;

        // Change
        this.onChange?.(this.item);
    }

    focus() {
        this.onTouched?.();

        if (this.focused || this.item) {
            return;
        }
        this.focused = true;

        this.updateList(undefined, true);
    }

    clear() {
        this.items = [];
        this.changeDetectorRef.markForCheck();

        if (this.item == null) {
            return;
        }

        this.item = undefined;
        this.filterControl.setValue('', { emitEvent: false });
        this.onChange?.(undefined);
        this.updateList(undefined, true);
    }

    registerOnChange(onChange: OnChange<Item | undefined>): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: OnTouched): void {
        this.onTouched = onTouched;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;

        if (isDisabled) {
            this.filterControl.disable();
        } else {
            this.filterControl.enable();
        }
    }

    writeValue(val?: Item): void {
        if (val == null) {
            return this.clear();
        }

        this.item = val;
        this.filter = this.autocompleteDisplay(val);
        this.filterControl.setValue(val);
    }

    updateList(filter?: string, force = false) {
        if (this.filter == filter && !force) {
            return;
        }

        this.filter = filter ? filter : undefined;
        this.loading = true;
        this.items = [];
        this.changeDetectorRef.markForCheck();

        this.getList(filter).subscribe((resp) => {
            this.loading = false;
            this.total = resp.total;
            this.results = resp.data.length;
            this.items = resp.data;
            this.changeDetectorRef.markForCheck();
        });
    }
}
