import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Customer, CustomerResponse } from '../models/customer';
import { classifyArrayPaginatedResponse } from '../utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';

interface GetAllOptions extends PaginationOptions {
    active?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class CustomerLinkService {
    constructor(@Inject(HttpClient) private http: HttpClient) {}

    getAll(customerId: number, options?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<CustomerResponse>>(`customers/${customerId}/links`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(Customer));
    }

    getChildren(customerId: number, options?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<CustomerResponse>>(`customers/${customerId}/children`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(Customer));
    }

    getDescendants(customerId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<CustomerResponse>>(`customers/${customerId}/descendants`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(Customer));
    }

    getParents(customerId: number, options?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<CustomerResponse>>(`customers/${customerId}/parents`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(Customer));
    }
}
