<eaw-dialog-header>
    <span title>{{'MANAGE_FORM_FIELDS' | translate: 'admin' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <mat-form-field>
        <mat-label>{{'FILE' | translate:'hr' | async}}</mat-label>
        <mat-icon matPrefix color="primary">attach_file</mat-icon>
        <span matTextSuffix [style.color]="'grey-500' | materialColor | async">{{file.value?.size | fileSize}}</span>
        <eaw-file-selector [formControl]="file"
                           fileFormats="text/csv"
                           placeholder="(CSV)"
                           [required]="true"
                           (fileChange)="onFileChange($event)"></eaw-file-selector>
    </mat-form-field>

    <mat-accordion>
        <mat-expansion-panel *ngFor="let fields of [existingFormFields, formFields];index as i"
                             [expanded]="!!fields.length"
                             [disabled]="!fields.length">
            <mat-expansion-panel-header>
                <mat-panel-title>{{(!i ? 'EXISTING_FORM_FIELDS' : 'NEW_FORM_FIELDS') | translate: 'admin' | async}}
                    ({{loading && !i ? ('LOADING' |translate | async) : fields.length}})
                </mat-panel-title>
                <mat-progress-spinner mode="indeterminate" diameter="30" strokeWidth="4" *ngIf="loading && !i"></mat-progress-spinner>
            </mat-expansion-panel-header>
            <table class="table table-condensed">
                <thead>
                <tr>
                    <th>{{'NAME' | translate | async}}</th>
                    <th>{{'VALUE' | translate | async}}</th>
                    <th>{{'RESOLVE_VALUE' | translate: 'admin' | async}}</th>
                </tr>
                </thead>

                <tbody>
                <tr *ngFor="let field of fields">
                    <td [title]="field.name">{{field.name}}</td>
                    <td [title]="field.value">{{field.value}}</td>
                    <td [title]="field.resolve_value" class="resolve" [class.do-resolve]="field.resolve_value">{{field.resolve_value}}</td>
                </tr>
                </tbody>
            </table>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-divider class="tw-mt-8"></mat-divider>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{'CANCEL' | translate | async}}</button>
    <button mat-raised-button color="primary" [disabled]="!formFields.length || !file.valid" (click)="submit()">{{'SET' | translate | async}}</button>
</mat-dialog-actions>
