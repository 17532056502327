// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
import { CustomFieldOld } from '../../../shared/angularjs/custom-field-old';
export default class PaidTimeFactory {
    EawResource;
    static get $inject() {
        return [ 'EawResource' ];
    }

    url = '/customers/:customer/employees/:employee/paid_times/:paid_time';
    constructor(EawResource) {
        this.EawResource = EawResource;
    }

    create(pt) {
        const data = pick(pt, [ 'business_date', 'from', 'to', 'comment', 'business_unit_id' ]);
        return this.EawResource.create(this.url).save({
            customer: pt.customer_id,
            employee: pt.employee_id,
        }, Object.assign(data, CustomFieldOld.getFieldValues(pt)));
    }

    /**
     *
     * @param {Object} args
     * @param {integer} args.customer_id
     * @param {integer} args.employee_id
     * @param {Moment|string} args.business_date
     * @param {Moment|string} args.from
     * @param {Moment|string} args.to
     * @return {*}
     */
    update(args) {
        return this.EawResource.create(this.url).update({
            customer: args.customer_id,
            employee: args.employee_id,
            paid_time: args.id,
        }, Object.assign(pick(args, [ 'business_date', 'from', 'to', 'comment', 'business_unit_id' ]), CustomFieldOld.getFieldValues(args)));
    }

    /**
     * Remove a paid time from an employee
     * @param {int} customerId
     * @param {int} employeeId
     * @param {int} paidTimeId
     */
    removeEmployeePaidTime(customerId, employeeId, paidTimeId) {
        return this.EawResource.create(this.url).delete({
            customer: customerId,
            employee: employeeId,
            paid_time: paidTimeId,
        });
    }

    /**
     * Get paid times for a customer
     * @param {int} customerId - Id of customer to get
     * @param {moment} from
     * @param {moment} to
     */
    getCustomerPaidTimes(customerId, from, to) {
        return this.EawResource.create('/customers/:customer/paid_times').get({
            customer: customerId,
            from,
            to,
        });
    }

    /**
     * Get paid times overview for a customer
     */
    getCustomerOverview(customerId, from, to, employeeId) {
        return this.EawResource.create('/customers/:customer/paid_times/overview').get({
            customer: customerId,
            from,
            to,
            employee_id: employeeId || undefined,
        });
    }

    /**
     * Get paid times overview for an employee spanning one or more customers
     */
    getEmployeeCustomersOverview(customerIds, from, to, employeeId) {
        return this.EawResource.create('/customers/:customers/employees/:employee/paid_times/overview').get({
            customers: customerIds.join(','),
            employee: employeeId,
            from,
            to,
        });
    }
}
module('eaw.payroll.paid-time').service('PaidTimeFactory', PaidTimeFactory);
