import { ChangeDetectionStrategy, Component, computed, inject, signal, WritableSignal } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { ProductCounters, ProductService } from '../../../shared/services/product.service';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTable, DataTablePagination } from '../../../data-table/types/data-table';
import { UIRouter } from '@uirouter/core';
import { ProductSubscriber } from '../../models/product-subscriber';
import { tap } from 'rxjs';

@Component({
    selector: 'eaw-admin-product',
    standalone: true,
    imports: [
        AsyncPipe,
        DataTableComponent,
        MatCard,
        MatCardContent,
        PageHeaderComponent,
        TranslatePipe,
        MatCardHeader,
        MatCardSubtitle,
        MatCardTitle,
    ],
    templateUrl: './admin-product.component.html',
    styleUrl: './admin-product.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminProductComponent implements DataTable<ProductSubscriber> {
    private productService = inject(ProductService);
    private uiRouter = inject(UIRouter);

    subscriber = this.uiRouter.globals.params['name'];
    columns = computed(this.computeColumns.bind(this));

    computeColumns(): DataTableColumnType<ProductSubscriber>[] {
        return [
            new DataTableNumberColumn({
                value: 'subscriberId',
                header: new DataTableHeader({
                    text: 'ID',
                    sortBy: 'subscriber_id',
                }),
            }),
            new DataTableTextColumn({
                value: 'subscriberType',
                header: new DataTableHeader({
                    text: 'TYPE',
                    sortBy: 'subscriber_type',
                }),
            }),
            new DataTableTextColumn({
                value: (cell) => cell?.item?.subscriber?.name,
                header: new DataTableHeader({
                    text: 'NAME',
                }),
            }),
        ];
    }

    details: WritableSignal<ProductCounters & { total: number } | null> = signal(null);

    getData = computed(() => (pagination: Partial<DataTablePagination>) =>
        this.productService.getSubscribers(this.subscriber, { ...pagination, 'with[]': [ 'subscriber' ] })
            .pipe(tap((resp) => {
                const { customersCount, groupsCount, totalCustomersCount, usersCount, total } = resp;
                this.details.set({ customersCount, groupsCount, totalCustomersCount, usersCount, total });
            })));

    onNavigateTo(event: ProductSubscriber): void {
        let route;
        if (event?.subscriberType === 'customer') {
            route = 'eaw/app/admin/customers/view/info';
        } else if (event?.subscriberType === 'group') {
            route = 'eaw/app/admin/customer_groups/view';
        } else {
            return;
        }
        this.uiRouter.stateService.go(route, { id: event.subscriberId });
    }
}
