import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WidgetSettingsDialog } from '../../../classes/widget-settings-dialog';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { WidgetSettingsDialogComponent } from '../../widget-settings-dialog/widget-settings-dialog.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { TranslateSyncPipe } from '../../../../shared/pipes/translate-sync.pipe';
import { WidgetPropertySettings } from '../../../classes/widget';

export interface WeatherWidgetSettings extends WidgetPropertySettings {
    useFahrenheit: boolean;
}

@Component({
    selector: 'eaw-weather-widget-settings-dialog',
    standalone: true,
    imports: [
        FormsModule,
        WidgetSettingsDialogComponent,
        MatFormField,
        MatLabel,
        TranslatePipe,
        AsyncPipe,
        ReactiveFormsModule,
        MatOption,
        MatSelect,
        TranslateSyncPipe,
    ],
    templateUrl: './weather-widget-settings-dialog.component.html',
    styleUrl: './weather-widget-settings-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherWidgetSettingsDialogComponent extends WidgetSettingsDialog<WeatherWidgetSettings> {
    protected fahrenheitCtrl = new FormControl<boolean>(false, { nonNullable: true, validators: Validators.required });

    constructor() {
        super();
    }

    submitted() {
        this.dialogRef.close({
            useFahrenheit: this.fahrenheitCtrl.value,
        });
    }
}
