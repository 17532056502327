<eaw-dialog-header>
    <span title>{{'REQUEST_SWAP' | translate:'scheduling' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" class="tw-grid tw-grid-cols-12 tw-gap-8">
        <mat-radio-group *ngIf="canGetEmployees" class="tw-p-[8px_0] tw-col-span-full tw-flex tw-flex-col tw-gap-8" formControlName="mode">
            <mat-radio-button *ngFor="let mode of modes" [value]="mode.value">{{mode.translation | async}}</mat-radio-button>
        </mat-radio-group>

        <eaw-autocomplete formControlName="employee"
                          class="tw-col-span-full"
                          *ngIf="form.controls.employee && canGetEmployees"
                          [options]="employeeAutocompleteService.options"
                          [getter]="employeeAutocompleteService.getter({customerId: data.customerId, includeExternal: true})"
                          [setter]="employeeAutocompleteService.setter({customerId: data.customerId})">
        </eaw-autocomplete>

        <mat-form-field class="tw-col-span-full">
            <mat-label>{{'COMMENT' | translate | async}}</mat-label>
            <textarea formControlName="comment" matInput rows="2"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-raised-button [mat-dialog-close]>{{'CLOSE' | translate | async}}</button>
    <button mat-button mat-raised-button color="primary" [disabled]="form.invalid || form.disabled" (click)="sendRequest()">{{'SEND_REQUEST' | translate | async}}</button>
</mat-dialog-actions>
