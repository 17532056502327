// @ts-nocheck
import { module } from 'angular';

module('eaw.decorators').config([ '$provide', function($provide) {
    $provide.decorator('mdDialogDirective', [ '$delegate', function($delegate) {
        const directive = $delegate[0];
        const compile = directive.compile;

        directive.compile = function(element, attrs) {
            // Add our mini dialog class if dialog just uses default class
            // because that means it's one of angular material's templates
            // and not one of our custom ones
            if (attrs.ngClass === 'dialog.css') {
                element[0].classList.add('mini-dialog');
            }

            return compile(element, attrs);
        };

        return $delegate;
    } ]);
} ]);
