// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.messages').component('eawInbox', {
    template: `<script type="text/ng-template" id="fromprofilepic">
    <div layout="row" layout-align="start center">
        <eaw-profile-picture-old class="tw-pr-12" user="item.user" size="30"></eaw-profile-picture-old>
        <span ng-bind="item.user.first_name + ' ' + item.user.last_name"></span>
    </div>
</script>

<script type="text/ng-template" id="inboxstatus">
    <md-icon ng-if="item.pivot.read_at && !item.pivot.confirmed_at" ng-bind="'circle'" class="s18" md-colors="{color: 'orange'}">
        <md-tooltip ng-i18next="messages:OPENED"></md-tooltip>
    </md-icon>

    <md-icon ng-if="item.pivot.confirmed_at" ng-bind="'done'" class="s18" md-colors="{color: 'green'}">
        <md-tooltip ng-i18next="messages:CONFIRMED"></md-tooltip>
    </md-icon>
</script>

<md-card>
    <md-card-content>
        <md-input-container flex>
            <label ng-i18next="SEARCH"></label>
            <input type="search" ng-model="$inboxCtrl.filter" uniq-form-name>
        </md-input-container>

        <md-button type="submit" class="tw-ml-0 md-accent md-raised" ng-click="$inboxCtrl.reloadTable = true">
            <span ng-i18next="SEARCH"></span>
        </md-button>
    </md-card-content>
</md-card>

<md-card>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="$inboxCtrl.table.columns"
                cells="$inboxCtrl.table.cells"
                get-data="$inboxCtrl.getInbox(pagination)"
                scope-vars="$inboxCtrl.table.vars"
                table-row-click="$inboxCtrl.showInfo(item)"
                reload-table="$inboxCtrl.reloadTable">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$inboxCtrl',
    bindings: {
        user: '<',
    },
    controller: [ 'MessageInfoService', 'Message', function(MessageInfoService, Message) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.table = {
                columns: [
                    { title: t('FROM') },
                    { title: t('messages:SUBJECT') },
                    {
                        title: t('STATUS'),
                        class: 'text-center',
                    },
                    {
                        title: t('messages:RECEIVED'),
                        sortable: 'updated_at',
                    },
                ],
                cells: [
                    { templateUrl: 'fromprofilepic' },
                    { template: 'item.subject' },
                    {
                        templateUrl: 'inboxstatus',
                        class: 'text-center',
                    },
                    { template: `item.created_at | moment` },
                ],
            };
        };

        ctrl.getInbox = (pagination) => {
            return Message.getInbox({
                with: [ 'attachments' ],
                user_id: ctrl.user.id,
                filter: ctrl.filter,
                fields: ctrl.fields,
                ...pagination.toWithPaginatorArguments(),
            });
        };

        ctrl.showInfo = (message) => {
            MessageInfoService.showInfo(message);
        };
    } ],
});
