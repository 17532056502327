export interface CurrencyResponse {
    code: string;
    name: string;
}

export class Currency {
    code: string;
    name: string;
    translatedName: string;

    constructor(data: CurrencyResponse, languageCode?: string) {
        this.code = data.code;
        this.name = data.name;

        try {
            this.translatedName = new Intl.DisplayNames(languageCode, { type: 'currency' }).of(this.code.toUpperCase()) || this.name;

            if (!this.name) {
                this.name = this.translatedName;
            }
        } catch (e) {
            console.error(e);
            this.translatedName = this.name;
        }
    }
}
