<eaw-page-header [buttons]="headerButtons" [fabButton]="fabButton">
    <span title>{{'EXTERNAL_EMPLOYEE_plural' | translate:'company' | async}}</span>
</eaw-page-header>

<mat-card [class.tw-hidden]="!filterToggled" class="tw-mb-12">
    <mat-card-content>
        <form class="tw-grid tw-grid-cols-1">
            <mat-form-field>
                <mat-label>{{'SEARCH' | translate | async}}</mat-label>
                <input matInput [formControl]="filter" autocomplete="off">
            </mat-form-field>
        </form>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-content>
        <eaw-data-table
            #table
            [columns]="columns"
            [request]="request"
            [rowClasses]="rowClasses"
            initialSortBy="id"
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
