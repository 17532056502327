// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-input-container>
    <eaw-date-picker ng-if="!$bDate.schedule.is_template"
                     label="BUSINESS_DATE"
                     ng-required="$bDate.required"
                     min-date="$bDate.minDate"
                     max-date="$bDate.maxDate"
                     ng-model="$bDate.shift.business_date">
    </eaw-date-picker>
</md-input-container>

<md-input-container ng-if="$bDate.schedule.is_template">
    <label ng-i18next="BUSINESS_DATE"></label>
    <md-select ng-model="$bDate.shift.business_date_offset" ng-change="$bDate.setBusinessDate()" ng-required="$bDate.required">
        <md-option ng-value="0" ng-i18next="scheduling:DAY_OF_SHIFT"></md-option>
        <md-option ng-value="-1" ng-i18next="scheduling:DAY_BEFORE_SHIFT"></md-option>
        <md-option ng-value="1" ng-i18next="scheduling:DAY_AFTER_SHIFT"></md-option>
    </md-select>
</md-input-container>
`;

module('eaw.scheduling').component('shiftBusinessDate', {
    template: template1,
    bindings: {
        shift: '<',
        schedule: '<',
    },
    controllerAs: '$bDate',
    controller: [ '$attrs', function($attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.minDate = ctrl.shift.from.clone().subtract(1, 'd');
            ctrl.maxDate = ctrl.shift.from.clone().add(1, 'd');

            $attrs.$observe('required', (value) => {
                ctrl.required = value;
            });
        };

        ctrl.setBusinessDate = () => { // for templates.
            ctrl.shift.business_date = ctrl.shift.from.clone().add(ctrl.shift.business_date_offset, 'd');
        };
    } ],
});
