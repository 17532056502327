<eaw-dialog-header>
    <span title>{{'NOTE_plural' | translate | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    @if (loading) {
        <eaw-info-loading size="lg"></eaw-info-loading>
    } @else {
        @if (canCreate) {
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon>add</mat-icon>
                        <span>{{'ADD_NOTE' | translate: 'company' | async}}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-form-field class="tw-w-full">
                    <mat-label>{{'NOTE' | translate | async}}</mat-label>
                    <textarea matInput [formControl]="bodyControl"></textarea>
                </mat-form-field>
                <div id="create-note-actions">
                    @if (this.data.showHideCheckbox) {
                        <mat-checkbox eawCheckboxHelper [formControl]="visibleControl">
                            {{'HIDE_NOTE_FROM_SUBMITTER' | translate: 'forms' | async}}
                        </mat-checkbox>
                    }
                    <eaw-action-button [loading]="submitting" (click)="createNote()">{{'SAVE' | translate | async}}</eaw-action-button>
                </div>
            </mat-expansion-panel>
        }

        @if (notes.length) {
            <eaw-comment-list [comments]="notesAsComments()"></eaw-comment-list>
        } @else {
            <h4>{{'NO_DATA' | translate | async}}</h4>
        }
    }
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CLOSE' | translate | async}}</button>
</mat-dialog-actions>
