import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { catchError, EMPTY, Observable, take } from 'rxjs';
import { LeaveShift } from '../../models/leave-shift';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { EawValidators } from '../../../shared/validators/eaw-validators';
import { AbsenceLeaveShiftService } from '../../http/absence-leave-shift.service';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TimeInputComponent } from '../../../shared/components/date-time/time-input/time-input.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface EditLeaveShiftDialogComponentData extends DialogData {
    leaveShift: Observable<LeaveShift>
}

@Component({
    selector: 'eaw-edit-leave-shift-dialog',
    templateUrl: './edit-leave-shift-dialog.component.html',
    styleUrl: './edit-leave-shift-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        FormsModule,
        TimeInputComponent,
        MatCheckboxModule,
        CheckboxHelperDirective,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class EditLeaveShiftDialogComponent extends DialogComponent implements OnInit {
    leaveShift: LeaveShift | null = null;
    form = new FormGroup({
        from: new FormControl<DateTime | null>(null, Validators.required),
        to: new FormControl<DateTime | null>(null, Validators.required),
        simpleBreak: new FormControl<number | null>(null, [ Validators.required, Validators.min(0), EawValidators.integer() ]),
        detailedBreak: new FormControl<boolean>(false, { nonNullable: true }),
        breakFrom: new FormControl<DateTime | null>(null, Validators.required),
        breakTo: new FormControl<DateTime | null>(null, Validators.required),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: EditLeaveShiftDialogComponentData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<EditLeaveShiftDialogComponent, LeaveShift>,
        @Inject(AbsenceLeaveShiftService) private absenceLeaveShiftService: AbsenceLeaveShiftService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit(): void {
        this.form.controls.from.valueChanges.subscribe(this.updateBreakFromTo.bind(this));
        this.form.controls.to.valueChanges.subscribe(this.updateBreakFromTo.bind(this));
        this.form.controls.breakFrom.valueChanges.subscribe(this.updateBreakMinutes.bind(this));
        this.form.controls.breakTo.valueChanges.subscribe(this.updateBreakMinutes.bind(this));
        this.form.controls.simpleBreak.valueChanges.subscribe(this.updateBreakFromTo.bind(this));

        this.data.leaveShift.pipe(take(1)).subscribe((leaveShift) => {
            this.leaveShift = leaveShift;

            this.form.patchValue({
                from: leaveShift.from,
                to: leaveShift.to,
                simpleBreak: leaveShift.breakLengthMinutes,
                breakFrom: leaveShift.breakFrom,
                breakTo: leaveShift.breakTo,
            });
        });
    }

    updateBreakMinutes() {
        this.form.controls.simpleBreak.setValue(this.getBreakLength()?.as('minutes') ?? null, { emitEvent: false });
    }

    updateBreakFromTo() {
        const breakMinutes = this.form.controls.simpleBreak.value || 0;
        const from = this.form.controls.from.value;
        const to = this.form.controls.to.value;

        if (from && to) {
            const shiftLength = to.diff(from, 'minutes').as('minutes');
            const breakFrom = from.plus({ minutes: Math.floor(shiftLength / 2) - Math.floor(breakMinutes / 2) });
            const breakTo = breakFrom.plus({ minutes: breakMinutes });

            this.form.patchValue({
                breakFrom,
                breakTo,
            }, { emitEvent: false });
        }
    }

    getBreakOffset() {
        const breakFrom = this.form.controls.breakFrom.value;
        const from = this.form.controls.from.value;

        if (breakFrom && from) {
            return breakFrom.diff(from, 'seconds').seconds;
        }

        return null;
    }

    getBreakLength() {
        const breakFrom = this.form.controls.breakFrom.value;
        const breakTo = this.form.controls.breakTo.value;

        if (breakFrom && breakTo) {
            return breakTo.diff(breakFrom, 'seconds');
        }

        return null;
    }

    update() {
        if (!this.leaveShift) {
            return;
        }

        this.absenceLeaveShiftService.update(this.leaveShift.customerId, this.leaveShift.employeeId, this.leaveShift.leaveId, this.leaveShift.id, {
            from: this.form.controls.from.value || undefined,
            to: this.form.controls.to.value || undefined,
            break_length: this.getBreakLength()?.seconds ?? undefined,
            break_offset: this.getBreakOffset() ?? undefined,
        }).pipe(
            catchError((error) => {
                console.error(error);
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe((leaveShift) => {
            this.dialogRef.close(leaveShift);
        });
        this.form.disable();
    }
}
