import { module } from 'angular';
import { createState } from '../shared/utils/create-state';
import { AnalyticsFrontpageComponent } from './pages/analytics-frontpage/analytics-frontpage.component';
import { StaffingForecastComponent } from './pages/staffing-forecast/staffing-forecast.component';
import { currentResolver } from '../shared/resolvers/current.resolver';
import { Products } from '../shared/enums/products';
import { PlusHoursMonitoringComponent } from './pages/plus-hours-monitoring/plus-hours-monitoring.component';
import { Namespace } from '../shared/enums/namespace';
import { WeeklyNightWorkReportComponent } from './pages/weekly-night-work-report/weekly-night-work-report.component';
import { AnnualNightHoursReportComponent } from './pages/annual-night-hours-report/annual-night-hours-report.component';

module('eaw').config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: `eaw/app/analytics`,
        parent: 'eaw/app',
        url: '/analytics',
        views: {
            'content@': {
                component: AnalyticsFrontpageComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            somePermissions: [
                'customers.{customer}.staffing_forecast.get',
                'customers.{customer}.plusHours.france.get',
            ],
            breadcrumb: { key: 'ANALYTICS', ns: Namespace.Analytics },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/analytics/staffing_forecast`,
        parent: 'eaw/app/analytics',
        url: '/staffing_forecast',
        views: {
            'content@': {
                component: StaffingForecastComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            products: [ Products.StaffingForecast ],
            permissions: [ 'customers.{customer}.staffing_forecast.get' ],
            breadcrumb: { key: 'STAFFING_FORECAST', ns: Namespace.Analytics },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/analytics/plus_hours`,
        parent: 'eaw/app/analytics',
        url: '/plus_hours',
        views: {
            'content@': {
                component: PlusHoursMonitoringComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            products: [ Products.France ],
            permissions: [ 'customers.{customer}.plusHours.france.get' ],
            breadcrumb: { key: 'PLUS_HOURS_MONITORING', ns: Namespace.Analytics },
        },
    });
    createState($stateProvider, {
        name: 'eaw/app/analytics/weekly_night_hours',
        parent: 'eaw/app/analytics',
        url: '/weekly_night_hours',
        views: {
            'content@': {
                component: WeeklyNightWorkReportComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            products: [ Products.France ],
            permissions: [ 'customers.{customer}.timepunches.*.get' ],
            breadcrumb: { key: 'WEEKLY_NIGHT_HOURS_REPORT_PAGE', ns: 'analytics' },
        },
    });
    createState($stateProvider, {
        name: 'eaw/app/analytics/annual_night_hours',
        parent: 'eaw/app/analytics',
        url: '/annual_night_hours',
        views: {
            'content@': {
                component: AnnualNightHoursReportComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            products: [ Products.France ],
            permissions: [ 'customers.{customer}.timepunches.*.get' ],
            breadcrumb: { key: 'ANNUAL_NIGHT_HOURS_REPORT_PAGE', ns: 'analytics' },
        },
    });
} ]);
