import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { DialogComponent, DialogData } from '../../../shared/dialogs/dialog-component';
import { ContractService } from '../../../shared/http/contract.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

export interface ContractDownloadDialogData extends DialogData {
    customerId: number;
    employeeId: number;
    contractId: number;
}

@Component({
    selector: 'eaw-contract-download-dialog',
    standalone: true,
    imports: [
        CommonModule,
        DialogHeaderComponent,
        TranslatePipe,
        MatDialogContent,
        MatProgressSpinnerModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        ActionButtonComponent,
        MatDialogActions,
        MatDialogClose,
        TranslateSyncPipe,
    ],
    templateUrl: './contract-download-dialog.component.html',
    styleUrl: './contract-download-dialog.component.scss',
})
export class ContractDownloadDialogComponent extends DialogComponent<ContractDownloadDialogData> implements OnInit {
    loading = true;
    customerId = this.data.customerId;
    employeeId = this.data.employeeId;
    contractId = this.data.contractId;
    contractTemplates: string[] = [];
    form = new FormGroup({
        contractTemplates: new FormControl<string | null>(null),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ContractDownloadDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ContractDownloadDialogComponent, null>,
        @Inject(ContractService) private contractService: ContractService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.contractService.getTemplates(this.customerId, this.employeeId, this.contractId).subscribe((res) => {
            this.contractTemplates = res;
            this.loading = false;
        });
    }

    async downloadContract() {
        if (this.form.controls.contractTemplates.value) {
            void this.snackbar.t('DOWNLOAD_STARTED');
            this.contractService.download(this.customerId, this.employeeId, this.contractId, this.form.controls.contractTemplates.value?.toLowerCase()).subscribe(() => {
                void this.snackbar.t('DOWNLOAD_FINISHED');
            });
        }
    }
}
