import { ChangeDetectionStrategy, Component, effect, input, output, signal } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { DateTimePipe } from '../../pipes/date-time.pipe';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { Permission } from '../../models/permission';
import { Observable, tap } from 'rxjs';

type ChangeValue = (permission: Permission, value: boolean) => Observable<boolean>;

@Component({
    selector: 'eaw-permission-node',
    standalone: true,
    imports: [
        AsyncPipe,
        DateTimePipe,
        MatCard,
        MatIcon,
        MatIconButton,
        MatProgressSpinner,
        MatTooltip,
        TranslatePipe,
    ],
    templateUrl: './permission-node.component.html',
    styleUrl: './permission-node.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionNodeComponent {
    hasParent = input<boolean>();
    permission = input.required<Permission>();

    canDelete = input<Observable<boolean | null>>();
    canUpdate = input<Observable<boolean | null>>();
    changeValue = input<ChangeValue>();
    disabled = input<boolean>();

    permissionValue = signal<boolean>(false);

    deletePermission = output();

    protected changingValue = signal(false);

    constructor() {
        effect(() => {
            this.permissionValue.set(this.permission().value);
        }, { allowSignalWrites: true });
    }

    protected changePermissionValue() {
        const changeValue = this.changeValue();
        if (!changeValue) {
            return;
        }

        this.changingValue.set(true);
        changeValue(this.permission(), !this.permissionValue())
            .pipe(
                tap((value) => {
                    this.changingValue.set(false);
                    this.permissionValue.set(value);
                }),
            ).subscribe();
    }

    protected delete() {
        this.deletePermission.emit();
    }
}
