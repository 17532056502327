import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { Shift, ShiftResponse } from './shift';
import { Customer, CustomerResponse } from '../../shared/models/customer';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { Property, PropertyResponse } from '../../shared/models/property';
import { BaseApiModel } from '../../shared/models/base-api-model';
import { ApiModel } from '../../shared/enums/api-model';

export interface ScheduleDay {
    index: number;
    dateTime: DateTime;
}

export interface ScheduleResponse extends ApiResponse {
    created_at: string;
    deleted_at?: string | null;
    customer_id: number;
    from: string;
    id: number;
    is_published: boolean;
    is_template: boolean;
    length: number;
    name: string;
    customer?: CustomerResponse;
    shifts?: ShiftResponse[];
    properties?: PropertyResponse[];
    published_at?: string | null;
    shifts_count?: number;
    template_name?: string | null;
    to: string;
    updated_at?: string | null;
}

export abstract class BaseSchedule extends BaseApiModel<ScheduleResponse, BaseSchedule> {
    from: DateTime;
    id: number;
    customerId: number;
    isPublished: boolean;
    isTemplate: boolean;
    length: number;
    name: string;
    publishedAt?: DateTime | null;
    shiftsCount?: number;
    shifts?: Shift[];
    templateName?: string | null;
    properties?: Property[];
    to: DateTime;
    customer?: Customer;
    createdAt: DateTime;
    updatedAt?: DateTime | null;
    deletedAt?: DateTime | null;

    constructor(data: ScheduleResponse) {
        super(data, ApiModel.Schedule);

        this.id = data.id;
        this.customerId = data.customer_id;
        this.isPublished = data.is_published;
        this.isTemplate = data.is_template;
        this.length = data.length;
        this.name = data.name;
        this.properties = data.properties?.map((p) => new Property(p));
        this.shifts = data.shifts?.map((s) => new Shift(s));
        this.publishedAt = data.published_at ? stringToDateTime(data.published_at) : null;
        this.shiftsCount = data.shifts_count;
        this.templateName = data.template_name;
        this.from = stringToDateTime(data.from);
        this.to = stringToDateTime(data.to);
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;

        if (data.customer) {
            this.customer = new Customer(data.customer);
        }
    }

    get days() {
        const days: ScheduleDay[] = [];
        let from = this.from;
        let index = 0;

        while (from < this.to) {
            days.push({
                index,
                dateTime: from,
            });

            from = from.plus({ days: 1 });
            index++;
        }

        return days;
    }
}
