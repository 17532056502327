@if (task.completed) {
    <p>
        {{ 'DOCUMENT_UPLOAD_COMPLETED' | translate: 'tasks' | async }}
    </p>
} @else {
    @if (fileType?.name) {
        <p>
            {{ 'UPLOAD_DOCUMENT_TASK_TEXT_PART_1' | translate: 'tasks' | async }}
        </p>
        <ul>
            <li><strong>{{ fileType?.name}}</strong></li>
        </ul>
        <p>
            {{ 'UPLOAD_DOCUMENT_TASK_TEXT_PART_2' | translate: 'tasks' | async }}
        </p>
    } @else {
        <p>
            {{ 'UPLOAD_DOCUMENT_TASK_TEXT' | translate: 'tasks' | async }}
        </p>
    }

    <form (ngSubmit)="handleUpload()">
        <mat-form-field>
            <mat-label>{{ 'FILENAME' | translate: 'hr' | async }}</mat-label>
            <input matInput type="text" [formControl]="fileNameInput">
        </mat-form-field>
        <div class="tw-m-12">
            <input type="file" (change)="selectFile($event)">
        </div>
        <eaw-action-button type="submit" class="tw-m-4" matSuffix [loading]="saving" [disabled]="task.completed || !this.file || fileNameInput.invalid" (click)="handleUpload()">
            {{ 'UPLOAD_FILE' | translate: 'hr' | async }}
        </eaw-action-button>
    </form>
}
