<eaw-page-header>
    <div title>{{'MY_EMERGENCY_CONTACT' | translate:'company' | async}}</div>
</eaw-page-header>

<mat-card>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <mat-card-content class="tw-flex tw-flex-col">
            <mat-form-field>
                <mat-label>{{'NAME' | translate | async}}</mat-label>
                <input matInput formControlName="name">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'PHONE' | translate | async}}</mat-label>
                <input matInput type="tel" formControlName="phone">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'RELATION' | translate:'company' | async}}</mat-label>
                <input matInput formControlName="relation">
            </mat-form-field>

            <div class="tw-flex tw-flex-col tw-gap-8">
                <eaw-custom-field-input *ngFor="let field of customFields" [formGroup]="form.controls.customFields" [customField]="field"></eaw-custom-field-input>
            </div>
        </mat-card-content>

        <mat-card-actions class="tw-flex tw-gap-8 tw-mt-12">
            <button type="submit" [disabled]="form.pristine || form.invalid || processing" mat-button mat-raised-button color="primary">{{'UPDATE' | translate | async}}</button>
            <button type="button" (click)="delete()" mat-button mat-raised-button color="warn" *ngIf="existing" [disabled]="processing">{{'DELETE' | translate | async}}</button>
        </mat-card-actions>
    </form>
</mat-card>
