import { module } from 'angular';
import { Namespace } from '../../../../../shared/enums/namespace';

module('eaw.admin').config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state(`eaw/app/admin/customers/view/buckets`, {
        parent: `eaw/app/admin/customers/view`,
        url: '/ml_buckets',
        abstract: true,
        data: {
            i18nextNs: [ Namespace.AiBudgeting ],
            breadcrumb: { key: 'ML Buckets' },
        },
    });

    $stateProvider.state('eaw/app/admin/customers/view/buckets/list', {
        parent: `eaw/app/admin/customers/view/buckets`,
        url: '/list',
        views: {
            'adminCustomerTabView@^.^': 'adminCustomerMlBucketsList',
        },
        data: {
            breadcrumb: { key: 'List' },
        },
        resolve: {
            customerDetailsId: [ '$transition$', function ($transition$: any) {
                return $transition$.params().id;
            } ],
        },
    });
} ]);
