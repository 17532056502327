import { Component, HostBinding, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeService } from '../../http/employee.service';
import { Employee } from '../../models/employee';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';

@Component({
    selector: 'eaw-employee-sidenav-header',
    standalone: true,
    imports: [ CommonModule, MatTooltipModule, ProfilePictureComponent ],
    templateUrl: './employee-sidenav-header.component.html',
    styleUrl: './employee-sidenav-header.component.scss' ,
})
export class EmployeeSidenavHeaderComponent implements OnInit {
    @HostBinding('class.loading') get loading() {
        return this._loading;
    }

    @Input({ required: true }) employeeId!: number;
    @Input({ required: true }) customerId!: number;

    private _loading = true;
    protected employee?: Employee;

    constructor(
        @Inject(EmployeeService) private employeeService: EmployeeService,
    ) {

    }

    ngOnInit(): void {
        this.employeeService.get(this.customerId, this.employeeId, { 'with[]': [ 'customer', 'user' ] }).subscribe((employee) => {
            this.employee = employee;
            this._loading = false;
        });
    }
}
