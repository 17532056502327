<eaw-dialog-header>
    <span title>{{'APPLY_FOR_SHIFT' | translate: 'scheduling' | async}}</span>
</eaw-dialog-header>
<mat-dialog-content class="tw-flex tw-flex-col tw-gap-12">
    <section>
        <p>
            {{'APPLY_FOR_SHIFT_TEXT' | translate:'scheduling': {date: date, location: location} | async}}
        </p>
    </section>
    <section *ngIf="shiftComments.length">
        <h3>{{'COMMENTS_ON_X'| translate: 'general':{x: shiftTranslation} | async}}</h3>
        <eaw-comment-list [comments]="shiftComments"></eaw-comment-list>
    </section>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="false">
        {{ 'CANCEL' | translate | async }}
    </button>
    <button mat-raised-button color="accent" [mat-dialog-close]="true">
        {{ 'APPLY' | translate:'general' | async }}
    </button>
</mat-dialog-actions>
