<eaw-dialog-header>
    <span title>{{'AVAILABILITY' | translate:'availabilities' | async}}</span>
    <span subtitle>{{employee?.name}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="loading" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content id="days-content" *ngIf="availability && !loading">
    <eaw-availability-days [availability]="availability"></eaw-availability-days>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CLOSE' | translate | async}}</button>
</mat-dialog-actions>
