import { Component, Directive, ElementRef, Inject, Injector, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'profile-notifications-upgraded-directive',
    standalone: true
})
export class profileNotificationsUpgradedDirective extends UpgradeComponent {
    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('profileNotifications', elementRef, injector);
    }
}

@Component({
    template: '<profile-notifications-upgraded-directive></profile-notifications-upgraded-directive>',
    standalone: true,
    imports: [profileNotificationsUpgradedDirective],
})
export class ProfileNotificationsComponent {
}

@NgModule({
    imports: [profileNotificationsUpgradedDirective,
        ProfileNotificationsComponent],
})
export class profileNotificationsUpgradeModule {
}
