import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Property, PropertyResponse } from '../models/property';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { DateTime } from 'luxon';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { PaginationOptions } from '../interfaces/pagination-options';
import { formatHttpParams } from '../utils/format-http-params';
import { IGNORE_ERROR } from './http-contexts';

@Injectable({
    providedIn: 'any',
})
export class EmployeePropertyService {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
    ) {
    }

    get(customerId: number, employeeId: number, key: string) {
        return this.http.get<ArrayPaginatedResponse<PropertyResponse>>(`/customers/${customerId}/employees/${employeeId}/properties/${key}`, {
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(classifyArrayPaginatedResponse(Property));
    }

    getAllForKey(customerId: number, employeeId: number, key: string, options?: PaginationOptions): Observable<ArrayPaginatedResponse<Property>> {
        return this.http.get<ArrayPaginatedResponse<PropertyResponse>>(`/customers/${customerId}/employees/${employeeId}/properties/${key}`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(Property));
    }

    create(customerId: number, employeeId: number, key: string, value: string, from?: DateTime | string | null, to?: DateTime | string | null): Observable<Property> {
        return this.http.post<PropertyResponse>(`/customers/${customerId}/employees/${employeeId}/properties`, {
            key,
            value,
            from: from || undefined,
            to: to || undefined,
        }).pipe(classifyItem(Property));
    }

    /**
     *
     * @param customerId
     * @param employeeId
     * @param key
     * @param date - Pass in a date to update the property that overlaps that date, defaults to now
     * @param value
     * @param from
     * @param to
     */
    update(customerId: number, employeeId: number, key: string, date: DateTime | null, value: string, from?: DateTime | string | null, to?: DateTime | string | null): Observable<Property> {
        return this.http.put<PropertyResponse>(`/customers/${customerId}/employees/${employeeId}/properties/${key}`, {
            date: date || DateTime.now(),
            value,
            from: from || undefined,
            to: to || undefined,
        }).pipe(classifyItem(Property));
    }

    /**
     * Delete a property for an employee
     * @param customerId
     * @param employeeId
     * @param key
     * @param date - Pass in a date to delete the property that overlaps that date, defaults to now
     */
    delete(customerId: number, employeeId: number, key: string, date: DateTime | null) {
        return this.http.delete<undefined>(`/customers/${customerId}/employees/${employeeId}/properties/${key}`, {
            params: formatHttpParams({ date: date || DateTime.now() }, [ 'date' ]),
        });
    }
}
