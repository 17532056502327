// @ts-nocheck
import { module } from 'angular';
import { FileSizeFormatter } from '../services/file-size-formatter.service';
import { CurrentOld } from '../../../current.factory';
module('eaw.misc').filter('filesize', function() {
    const formatter = new FileSizeFormatter(CurrentOld.languageTag, 'short');

    return function(size) {
        return formatter.format(size);
    };
});
