// @ts-nocheck
import { module } from 'angular';
import { Model } from '../../../../../shared/angularjs/model';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="'EDIT' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="editForm" id="editForm" ng-cloak ng-submit="dialog.close(dialog.externalEmployee)" autocomplete="off">
                <md-input-container>
                    <label ng-i18next="company:EXTERNAL_EMP_NUMBER"></label>
                    <input ng-model="dialog.externalEmployee.number" uniq-form-name>
                    <div class="hint" ng-i18next="[i18next]({customer:dialog.customer.name})EXTERNAL_EMP_NUMBER_HELP"></div>
                </md-input-container>

                <md-input-container>
                    <label for="cost" ng-i18next="COST"></label>
                    <input id="cost"
                           type="number"
                           min="0"
                           step="0.01"
                           ng-model="dialog.externalEmployee.cost">
                </md-input-container>

                <eaw-date-time-interval ng-model="dialog.interval"></eaw-date-time-interval>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button form="editForm" ng-disabled="editForm.$invalid" type="submit" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.company').factory('editExternalEmployeeDialog', [ '$mdDialog', function($mdDialog) {
    const preset = {
        locals: undefined,
        template: template1,
        controllerAs: 'dialog',
        bindToController: true,
        controller: function() {
            const ctrl = this;

            ctrl.$onInit = () => {
                const cost = parseFloat(ctrl.externalEmployee.cost);

                ctrl.externalEmployee.cost = Number.isFinite(cost) ? cost : undefined;
                ctrl.interval = {
                    from: ctrl.externalEmployee.from?.clone(),
                    to: ctrl.externalEmployee.to?.clone(),
                };
            };

            ctrl.close = () => {
                ctrl.externalEmployee.from = ctrl.interval.from;
                ctrl.externalEmployee.to = ctrl.interval.to;

                $mdDialog.hide();
            };
        },
    };

    return {
        open: function(ext, customer) {
            preset.locals = {
                externalEmployee: new Model(ext),
                customer,
            };

            return $mdDialog.show(preset).then(() => preset.locals.externalEmployee.getModified());
        },
    };
} ]);
