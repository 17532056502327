<eaw-schedule-tab-opening-hours [customerId]="customerId()" [pixelsPerSecond]="pixelsPerSecond()"/>

<eaw-schedule-tab-vertical-lines [renderedIntervals]="renderedIntervals()" [pixelsPerSecond]="pixelsPerSecond()"/>

@for (shift of shifts(); track shift.id) {
    <eaw-shift-line [stackId]="stackId()"
                    [scheduleId]="scheduleId()"
                    [shift]="shift"
                    [customerId]="customerId()"
                    [pixelsPerSecond]="pixelsPerSecond()"
                    (dragging)="isDraggingShift.set($event)"/>
}

