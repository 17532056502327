import { Inject, Injectable } from '@angular/core';
import { FileUploadService } from '../../shared/services/file-upload.service';
import { map, Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { HttpClient } from '@angular/common/http';
import { RateType } from '../../payroll/classes/rate';
import { EmployeePayRatePreview, EmployeePayRatePreviewResponse } from '../models/employee-pay-rate-preview';

interface PayRateInfo {
    id: number;
    employee_id: number;
    rate: number;
    tariff_id: number;
    from: DateTime;
    to: DateTime;
    type: RateType;
}

type PayRatesToCUD = Record<'insert' | 'update' | 'delete', PayRateInfo[]>;

interface SalaryUpdateResponse {
    employee_pay_rates: EmployeePayRatePreviewResponse[];
    pay_rates_to_cud: PayRatesToCUD;
}

export interface SalaryUpdate {
    employeePayRates: EmployeePayRatePreview[];
    payRatesToCUD: PayRatesToCUD;
}

@Injectable({
    providedIn: 'root',
})
export class PayRateUploadService {

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(FileUploadService) private upload: FileUploadService,
    ) { }

    dryRun(customerGroupId: number, file: File, date: DateTime, parser?: string): Observable<SalaryUpdate> {
        return this.upload.upload<SalaryUpdateResponse>(`/customer_groups/${customerGroupId}/salary_update/dry`, {
            salary_update_file: file,
            date,
            parser,
        }).pipe(map((update) => {
            return {
                employeePayRates: update.employee_pay_rates.map((e) => new EmployeePayRatePreview(e)),
                payRatesToCUD: update.pay_rates_to_cud,
            };
        }));
    }

    applyChanges(customerGroupId: number, update: SalaryUpdate): Observable<unknown> {
        return this.http.post(`/customer_groups/${customerGroupId}/salary_update/wet`, { pay_rates_to_cud: update.payRatesToCUD });
    }
}
