import { ChangeDetectionStrategy, Component, computed, inject, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { NotificationService } from '../../../shared/http/notification.service';
import { debounceTime, Observable, of, take, tap } from 'rxjs';
import { PaginationChange, PaginatorComponent } from '../../../shared/components/paginator/paginator.component';
import { Notification } from '../../models/notification';
import { MatCard, MatCardContent, MatCardFooter, MatCardTitle } from '@angular/material/card';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconAnchor, MatIconButton } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';
import { CurrentService } from '../../../shared/services/current.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIconOutlinedDirective } from '../../../shared/directives/mat-icon-outlined.directive';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatToolbar } from '@angular/material/toolbar';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { MeCustomer } from '../../../shared/models/me';

type NotificationItem = { access: Observable<boolean>, customerName: string, notification: Notification, marking: boolean };

@Component({
    selector: 'eaw-notification-list',
    standalone: true,
    imports: [
        PaginatorComponent,
        MatCard,
        MatCardTitle,
        TranslatePipe,
        AsyncPipe,
        MatCardFooter,
        DateTimePipe,
        MatIcon,
        MatButton,
        MatIconButton,
        MatProgressBar,
        MatCardContent,
        MatProgressSpinner,
        MatIconAnchor,
        MatIconOutlinedDirective,
        MatCheckbox,
        ReactiveFormsModule,
        CheckboxHelperDirective,
        MatToolbar,
        MatButtonToggle,
        MatSlideToggle,
    ],
    templateUrl: './notification-list.component.html',
    styleUrl: './notification-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent implements OnInit {
    private readonly notificationService = inject(NotificationService);
    private readonly current = inject(CurrentService);
    private permissionCheckService = inject(PermissionCheckService);

    @Input({ required: true }) userId!: number;

    protected notifications: WritableSignal<NotificationItem[]> = signal([]);
    protected loading = signal(false);
    protected page = signal(1);
    protected perPage = signal(25);
    protected totalNotifications = signal(0);
    protected index = computed(() => this.page() - 1);
    protected includeRead = new FormControl(false, { nonNullable: true });

    constructor() {
        this.includeRead.valueChanges.pipe(
            debounceTime(300),
            tap(() => {
                this.loadPage({ page: this.page(), per_page: this.perPage() });
            }),
            takeUntilDestroyed(),
        ).subscribe();
    }

    ngOnInit() {
        this.loadPage({ page: this.page(), per_page: this.perPage() });
    }

    protected loadPage(event: PaginationChange) {
        this.loading.set(true);
        this.page.set(event.page);
        this.perPage.set(event.per_page);

        this.notificationService.getAll(this.userId, !this.includeRead.value, {
            ...event,
            order_by: 'created_at',
            direction: 'desc',
        }).pipe(
            tap((response) => {
                this.notifications.set(response.data.map(this.makeNotificationItem.bind(this)));
                this.totalNotifications.set(response.total);
                this.perPage.set(parseInt(String(response.per_page)));
                this.page.set(response.current_page);
                this.loading.set(false);
            }),
            take(1),
        ).subscribe();
    }

    protected markAsRead(item: NotificationItem) {
        item.marking = true;
        this.notificationService.markAsRead(this.userId, item.notification.id).pipe(
            tap((readNotification) => {
                this.notifications.update((notifications) => {
                    const index = notifications.findIndex((n) => n.notification.id === item.notification.id);
                    if (index > -1) {
                        // Could happen if you changed the page while marking as read
                        notifications[index] = this.makeNotificationItem(readNotification);
                    }

                    return notifications;
                });
            }),
            take(1),
        ).subscribe();
    }

    protected makeNotificationItem(notification: Notification): NotificationItem {
        const routeParams = notification.getRouteParams();
        let customerName = '';
        let access = of(true);

        if (routeParams['customer_id']) {
            const customer = this.current.getCustomer(routeParams['customer_id']);
            access = this.hasNotificationPermission(customer, routeParams['id'], notification);
            customerName = customer?.name || '';
        }

        return {
            access,
            customerName,
            notification,
            marking: false,
        };
    }

    hasNotificationPermission(customer: MeCustomer | undefined, id: number, notification: Notification): Observable<boolean> {
        switch (notification?.type?.key) {
            case 'changed_or_created_shift':
                return this.permissionCheckService.isAllowed(`customers.${customer?.id}.employees.${notification.notifiableId}.shifts.${id}.get`);
            case 'shift_commented':
                return this.permissionCheckService.isAllowed(`customers.${customer?.id}.employees.${notification.notifiableId}.shifts.${id}.get`);
            default:
                return of(!!customer);
        }
    }
}
