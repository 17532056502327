// @ts-nocheck
import { debounce } from 'lodash-es';

import { module } from 'angular';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

const template2 = `<md-dialog>
    <eaw-dialog-header-old heading="'newsletter:ADD_LANGUAGE' | i18next"></eaw-dialog-header-old>

    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="newsletterAddLangForm">
                <md-input-container>
                    <label for="language" ng-i18next="LANGUAGE"></label>
                    <input id="language" name="language" ng-model="ctrl.language.languageName" required disabled>
                </md-input-container>

                <md-input-container>
                    <label for="title" ng-i18next="TITLE"></label>
                    <input id="title" name="title" ng-model="ctrl.title" required>
                    <small class="hint">{{'newsletter:NEWSLETTER_TITLE_HINT' | i18next}}</small>
                </md-input-container>

                <md-input-container class="md-block">
                    <label ng-i18next="DESCRIPTION"></label>
                    <textarea ng-model="ctrl.description" rows="2"></textarea>
                    <small class="hint">{{'newsletter:NEWSLETTER_DESCRIPTION_HINT' | i18next}}</small>
                </md-input-container>

                <md-checkbox ng-model="ctrl.default" ng-if="!ctrl.currentDefault">
                    <span ng-i18next="newsletter:USE_AS_DEFAULT_LANG"></span>
                </md-checkbox>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-click="ctrl.submit()" ng-i18next="SUBMIT" form="newsletterAddLangForm" ng-disabled="newsletterAddLangForm.$invalid"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

const template1 = `<eaw-accordion multi-toggle="true">
    <eaw-accordion-panel toggled="true">
        <panel-header>
            <div layout="row" layout-align="space-between center" class="tw-w-full">
                <div layout="column">
                    <div ng-i18next="LANGUAGE_plural"></div>
                    <small ng-i18next="newsletter:LANGUAGES_HELP"></small>
                </div>

                <small ng-i18next="[i18next]({count: $ctrl.addedLanguages.length})newsletter:X_LANGS_ADDED"></small>
            </div>
        </panel-header>
        <panel-content class="tw-p-0">
            <table class="table table-vmiddle table-bordered-bottom table-striped">
                <thead>
                <tr>
                    <th></th>
                    <th ng-i18next="STATUS"></th>
                    <th ng-i18next="LANGUAGE"></th>
                    <th ng-i18next="TITLE"></th>
                    <th ng-i18next="DESCRIPTION"></th>
                    <th></th>
                </tr>
                </thead>
                <tr ng-repeat="language in $ctrl.newsletterLanguages">
                    <td class="text-center">
                        <eaw-icon-button eaw-invisible="{{language.added}}" icon="add" type="primary" ng-click="$ctrl.addLanguage(language)"></eaw-icon-button>
                    </td>
                    <td>
                        <strong ng-if="language.is_default" md-colors="{color: 'blue'}" ng-i18next="DEFAULT"></strong>
                        <span ng-if="!language.is_default && language.added" md-colors="{color: 'green'}" ng-i18next="ADDED"></span>
                        <span ng-if="!language.is_default && !language.added" md-colors="{color: 'red'}" ng-i18next="NOT_ADDED"></span>
                    </td>
                    <td ng-bind="language.languageName"></td>
                    <td ng-bind="language.title"></td>
                    <td ng-bind="language.description"></td>
                    <td class="text-center">
                        <eaw-icon-button
                                eaw-invisible="{{!(language.added && !language.is_default)}}"
                                icon="task_alt"
                                tooltip="'newsletter:SET_DEFAULT'"
                                ng-click="$ctrl.setDefault(language)">
                        </eaw-icon-button>

                        <eaw-icon-button
                                eaw-invisible="{{!language.added}}"
                                icon="edit"
                                tooltip="'EDIT'"
                                ng-click="$ctrl.editLanguage(language)">
                        </eaw-icon-button>

                        <eaw-icon-button
                                eaw-invisible="{{!(language.added && !language.is_default)}}"
                                icon="delete"
                                type="warn"
                                tooltip="'DELETE'"
                                ng-click="$ctrl.deleteLanguage(language)">
                        </eaw-icon-button>
                    </td>
                </tr>
            </table>
        </panel-content>
    </eaw-accordion-panel>

    <eaw-accordion-panel toggled="true">
        <panel-header>
            <div layout="row" layout-align="space-between center" class="tw-w-full">
                <div layout="column">
                    <div ng-i18next="GROUP_plural"></div>
                    <small ng-i18next="newsletter:GROUPS_HELP"></small>
                </div>

                <small md-colors="{color: 'red'}" ng-if="!$ctrl.selectedGroups" ng-i18next="newsletter:NO_GROUPS_ACCESS"></small>
                <small ng-if="$ctrl.selectedGroups" ng-i18next="[i18next]({count: $ctrl.selectedGroups})newsletter:X_GROUP_ACCESS"></small>
            </div>
        </panel-header>
        <panel-content class="tw-p-0">
            <md-list>
                <md-list-item ng-repeat="group in $ctrl.groups">
                    <p ng-bind="group.name"></p>
                    <md-switch class="md-secondary" ng-model="group.selected" ng-change="$ctrl.updateGroups()"></md-switch>
                    <md-divider ng-if="!$last"></md-divider>
                </md-list-item>
            </md-list>
        </panel-content>
    </eaw-accordion-panel>
</eaw-accordion>
`;

module('eaw.newsletter').component('manageNewsletterSettingsTab', {
    template: template1,
    controllerAs: '$ctrl',
    bindings: {
        user: '<',
        customer: '<',
        languages: '<',
        newsletter: '<',
    },
    controller: [ '$mdDialog', 'Newsletter', '$state', function($mdDialog, Newsletter, $state) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.setGroups();
            ctrl.setNewsletterLanguages();
        };

        ctrl.setGroups = () => {
            ctrl.groups = ctrl.customer.customerGroups.reduce((arr, group) => {
                group.selected = ctrl.newsletter.groups?.includes(group.id);
                return arr.concat(group);
            }, []);

            ctrl.selectedGroups = ctrl.groups.filter((g) => g.selected).length;
        };

        ctrl.updateGroups = debounce(() => {
            const newsletter = {
                id: ctrl.newsletter.key,
                customer_id: ctrl.newsletter.customer_id,
                customer_groups: ctrl.groups.filter((g) => g.selected).map((g) => g.id),
            };

            Newsletter.update(newsletter).then(() => {
                $state.reload();
            });
        }, 500);

        ctrl.setNewsletterLanguages = () => {
            ctrl.newsletterLanguages = ctrl.newsletter.languages.map((nl) => {
                const language = ctrl.languages.find((l) => l.code === nl.code);

                return {
                    ...nl,
                    code: language.code,
                    languageName: language.name,
                    is_default: language.code === ctrl.newsletter.language_code,
                    added: true,
                };
            });

            ctrl.languages.forEach((l) => {
                if (!ctrl.newsletterLanguages.find((nl) => nl.code === l.code)) {
                    ctrl.newsletterLanguages.push({
                        language: l.code,
                        languageName: l.name,
                        code: l.code,
                        is_default: false,
                        added: false,
                    });
                }
            });

            ctrl.newsletterLanguages = sort(ctrl.newsletterLanguages, CurrentOld.languageTag, [ (nl) => nl.languageName ], 'asc');
            ctrl.addedLanguages = ctrl.newsletterLanguages.filter((nl) => nl.added);
        };

        ctrl.getNewsletterLanguages = () => ctrl.newsletterLanguages.reduce((arr, nl) => arr.concat({ ...nl }), []);

        ctrl.addLanguage = (language, editing) => {
            $mdDialog.show({
                template: template2,
                controller: 'AddNewsletterLanguageDialogCtrl',
                controllerAs: 'ctrl',
                bindToController: true,
                locals: {
                    editing,
                    language,
                    title: language.title,
                    description: language.description,
                    currentDefault: language.is_default,
                    newsletterLanguages: ctrl.getNewsletterLanguages(),
                    newsletter: ctrl.newsletter,
                    customer: ctrl.customer,
                },
            }).then((newsletter) => {
                ctrl.newsletter = newsletter;
                ctrl.setNewsletterLanguages();
            });
        };

        ctrl.setDefault = (language) => {
            const newsletter = {
                id: ctrl.newsletter.key,
                customer_id: ctrl.newsletter.customer_id,
                language_code: language.code,
                default_title: language.title,
                default_description: language.description,
                languages: ctrl.getNewsletterLanguages()
                    .filter((nl) => nl.added)
                    .map((nl) => {
                        nl.is_default = nl.code === language.code;
                        return nl;
                    }),
            };

            Newsletter.update(newsletter).then((newsletter) => {
                ctrl.newsletter = newsletter;
                ctrl.setNewsletterLanguages();
            });
        };

        ctrl.editLanguage = (language) => {
            ctrl.addLanguage(language, true);
        };

        ctrl.deleteLanguage = (language) => {
            if (language.is_default) {
                return;
            }

            const newsletter = {
                id: ctrl.newsletter.key,
                customer_id: ctrl.newsletter.customer_id,
                languages: ctrl.getNewsletterLanguages()
                    .filter((nl) => nl.added)
                    .filter((nl) => nl.language !== language.language),
            };

            Newsletter.update(newsletter).then((newsletter) => {
                ctrl.newsletter = newsletter;
                ctrl.setNewsletterLanguages();
            });
        };
    } ],
});
