import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { DataTablePagination, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { FormService } from '../../http/form.service';
import { CurrentService } from '../../../shared/services/current.service';
import { Observable, of } from 'rxjs';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { EawForm } from '../../types/form';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

@Component({
    selector: 'eaw-form-list',
    templateUrl: './form-list.component.html',
    styleUrl: './form-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgFor,
        MatOptionModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class FormListComponent implements OnInit, EawDataTable {
    @HostBinding('class') classes = 'flex-contained';

    modes = [
        {
            title: 'ACTIVE',
            value: true,
        },
        {
            title: 'INACTIVE',
            value: false,
        },
        {
            title: 'ALL',
            value: undefined,
        },
    ];

    mode = new UntypedFormControl(this.modes[0]);
    request?: Observable<ArrayPaginatedResponse<EawForm>>;
    columns: DataTableColumnType<EawForm>[] = [
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'check',
                    click: this.toggleActive.bind(this),
                    type: 'ok',
                    hide: (form) => of(form.active),
                    show: (form) => this.permissionCheckService.isAllowed(`customers.${form.customer_id}.forms.${form.id}.update`),
                    tooltip: { key: 'REACTIVATE' },
                },
                {
                    icon: 'close',
                    click: this.toggleActive.bind(this),
                    type: 'warn',
                    hide: (form) => of(!form.active),
                    show: (form) => this.permissionCheckService.isAllowed(`customers.${form.customer_id}.forms.${form.id}.update`),
                    tooltip: { key: 'DEACTIVATE' },
                },
            ],
        }),
    ];

    constructor(
        @Inject(FormService) private formService: FormService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
    ) {
        this.mode.valueChanges.subscribe(() => {
            this.updateTable({});
        });
    }

    ngOnInit(): void {
        this.updateTable({});
    }

    toggleActive(cell: DataTableCell<DataTableButtonColumn<EawForm>, EawForm>) {
        this.formService.update(cell.item.customer_id, cell.item.id, {
            active: !cell.item.active,
        }).subscribe(() => {
            this.updateTable({});
        });
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.formService.getAll(this.current.getCustomer().id, {
            per_page: 25,
            order_by: 'name',
            direction: 'asc',
            ...pagination,
            active: this.mode.value.value,
        });
    }

    modeComparer(item1: { title: string, value?: boolean }, item2: { title: string, value?: boolean }): boolean {
        return item1.value === item2.value;
    }
}
