// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<h2 ng-bind="$widget.absent"></h2>
<span ng-bind="::$widget.text"></span>
`;

module('eaw.dashboard').component('miniAbsenceWidget', {
    template: template1,
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$widget',
    controller: [ 'Absence', function(Absence) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.offset = ctrl.mini.widget.settings?.day_offset;
            ctrl.text = t(ctrl.offset ? 'TOMORROW' : 'TODAY');

            ctrl.loadAbsences({ page: 1 }).then((resp) => ctrl.absent = resp.total).finally(() => ctrl.mini.loading(false));
            ctrl.mini.dialogComponents = [ {
                name: 'dashAbsenceList',
                label: 'absences:ABSENCE_plural',
                bindings: {
                    loadAbsences: ctrl.loadAbsences,
                },
                params: {
                    loadAbsences: [ 'pagination' ],
                },
            } ];
        };

        ctrl.$onDestroy = () => {
            ctrl.absRes?.$cancelRequest?.();
        };

        ctrl.loadAbsences = function loadAbsences(pagination) {
            const date = moment().add(ctrl.offset, 'd');

            ctrl.absRes = Absence.getForCustomer({
                customerId: ctrl.mini.widget.customer,
                from: date.startOf('d'),
                to: date.clone().endOf('d'),
                approved: true,
                ...pagination,
            });

            return ctrl.absRes.$promise;
        };
    } ],
});
