import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, viewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatCard } from '@angular/material/card';
import { FormControl, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { DataTableComponent } from '../../../data-table/data-table.component';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DataTablePagination } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { SystemAlertService } from '../../../shared/http/system-alert.service';
import { SystemAlert } from '../../../shared/models/system-alert';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { Namespace } from '../../../shared/enums/namespace';
import { EMPTY, of, switchMap, tap } from 'rxjs';
import { TranslateService } from '../../../shared/services/translate.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';

@Component({
    selector: 'eaw-admin-system-alerts',
    standalone: true,
    imports: [
        AsyncPipe,
        DataTableComponent,
        MatCard,
        PageHeaderComponent,
        TranslatePipe,
    ],
    templateUrl: './admin-system-alerts.component.html',
    styleUrl: './admin-system-alerts.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSystemAlertsComponent {
    private systemAlertService = inject(SystemAlertService);
    private translate = inject(TranslateService);
    private confirmDialogService = inject(ConfirmDialogService);
    private destroyedRef = inject(DestroyRef);
    private promptDialogService = inject(PromptDialogService);

    columns = computed(this.computeColumns.bind(this));
    getData = computed(() => (pagination: Partial<DataTablePagination>) =>
        this.systemAlertService.getAll({ ...pagination }));

    table = viewChild.required(DataTableComponent);

    fabButton: HeaderFabButton = {
        icon: 'add',
        click: () => this.addSystemAlert(),
        hasPermission: () => of(true),
    };

    computeColumns(): DataTableColumnType<SystemAlert>[] {
        return [
            new DataTableTextColumn({
                value: 'id',
                header: new DataTableHeader({
                    text: 'Id',
                    sortBy: 'id',
                }),
            }),
            new DataTableTextColumn({
                value: 'text',
                header: new DataTableHeader({
                    i18n: 'TEXT',
                }),
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        icon: 'edit',
                        click: this.updateSystemAlert.bind(this),
                        show: () => of(true),
                        tooltip: { key: 'UPDATE' },
                    },
                    {
                        icon: 'delete',
                        type: 'warn',
                        click: this.deleteSystemAlert.bind(this),
                        show: () => of(true),
                        tooltip: { key: 'DELETE' },
                    },
                ],
            }),
        ];
    }

    updateSystemAlert(cell: DataTableButtonCell<SystemAlert>): void {
        this.promptDialogService.open('textarea', {
            size: DialogSize.Medium,
            formControl: new FormControl<string>(cell.item.text, [ Validators.required ]),
            title: this.translate.t('EDIT_SYSTEM_ALERT', Namespace.Admin),
            label: this.translate.t('DESCRIPTION'),
            confirmText: this.translate.t('UPDATE'),
        }).afterClosed()
            .pipe(
                takeUntilDestroyed(this.destroyedRef),
                switchMap((text) => {
                    if (!text) {
                        cell.disabled.set(false);
                        return EMPTY;
                    }
                    return this.systemAlertService.update(cell.item.id, text);
                }),
                tap(() => this.table()?.refresh()),
            ).subscribe();
    }

    addSystemAlert(): void {
        this.promptDialogService.open('textarea', {
            size: DialogSize.Medium,
            formControl: new FormControl('', [ Validators.required ]),
            title: this.translate.t('NEW_SYSTEM_ALERT', Namespace.Admin),
            label: this.translate.t('DESCRIPTION'),
            confirmText: this.translate.t('CREATE'),
        }).afterClosed()
            .pipe(
                takeUntilDestroyed(this.destroyedRef),
                switchMap((text) => {
                    if (!text) {
                        return EMPTY;
                    }
                    return this.systemAlertService.create(text);
                }),
                tap(() => this.table()?.refresh()))
            .subscribe();
    }

    deleteSystemAlert(cell: DataTableButtonCell<SystemAlert>): void {
        this.confirmDialogService.delete({
            title: this.translate.t('DELETE', Namespace.Admin),
            text: this.translate.t('THIS_WILL_REMOVE_THE', Namespace.Admin, { name: cell.item.text }),
            confirmText: this.translate.t('DELETE', Namespace.General),
        }).afterClosed()
            .pipe(
                takeUntilDestroyed(this.destroyedRef),
                switchMap((result) => {
                    if (!result?.ok) {
                        cell.disabled.set(false);
                        return EMPTY;
                    }
                    return this.systemAlertService.delete(cell.item.id);
                }),
                tap(() => this.table()?.refresh()),
            ).subscribe();
    }
}
