// @ts-nocheck
import { module } from 'angular';

module('eaw.dashboard').component('flexitimeWidget', {
    template: `<md-subheader ng-if="$ctrl.flexitimeBalance" layout="row" layout-align="center center">
    <span class="md-headline">{{($ctrl.flexitimeBalance * 3600) | eawDuration}}</span>
</md-subheader>

<md-list class="md-dense" flex>
    <md-list-item ng-if="$ctrl.deltas.length" ng-repeat="delta in $ctrl.deltas track by delta.id">
        <span class="md-body-1">{{::delta.business_date | moment:'LL'}}</span>
        <span class="md-secondary md-body-1">{{::(delta.delta * 3600) | eawDuration}}</span>
        <md-divider ng-hide="$last"></md-divider>
    </md-list-item>
</md-list>
`,
    require: {
        eawWidget: '^eawWidget',
    },
    controller: [ 'FlexitimeFactory', function(FlexitimeFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.balanceRes = FlexitimeFactory.getEmployeeBalance(ctrl.eawWidget.widget.customer, ctrl.eawWidget.widget.employee.id);
            ctrl.deltasRes = FlexitimeFactory.getForEmployee({
                customer: ctrl.eawWidget.widget.customer,
                employee: ctrl.eawWidget.widget.employee.id,
                per_page: 5,
            });

            ctrl.eawWidget.setLoading(true);
            Promise.all([
                ctrl.balanceRes.$promise,
                ctrl.deltasRes.$promise,
            ]).then((data) => {
                ctrl.eawWidget.setLoading(false);

                ctrl.flexitimeBalance = data[0].balance;
                ctrl.deltas = data[1].data;

                if (!ctrl.deltas.length) {
                    ctrl.eawWidget.setEmpty(true);
                }
            });
        };

        ctrl.$onDestroy = () => {
            ctrl.balanceRes?.$cancelRequest?.();
            ctrl.deltasRes?.$cancelRequest?.();
        };
    } ],
});
