import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { EawValidators } from '../../../../../shared/validators/eaw-validators';
import { MatInputModule } from '@angular/material/input';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { UserService } from '../../../../../shared/http/user.service';
import { catchError, EMPTY, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '../../../../../shared/models/user';
import { InfoBoxComponent } from '../../../../../shared/components/info-card/info-box.component';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';
import { NewEmployeeNextStepComponent } from '../../components/new-employee-next-step/new-employee-next-step.component';
import { FieldErrorComponent } from '../../../../../shared/components/field-error/field-error.component';

@Component({
    selector: 'eaw-new-employee-user-check',
    standalone: true,
    imports: [ CommonModule, MatInputModule, TranslatePipe, ReactiveFormsModule, ActionButtonComponent, InfoBoxComponent, NewEmployeeNextStepComponent, FieldErrorComponent ],
    templateUrl: './new-employee-user-check.component.html',
    styleUrl: './new-employee-user-check.component.scss',
})
export class NewEmployeeUserCheckComponent extends NewEmployeeStepComponent implements OnInit {
    private readonly userService = inject(UserService);

    @Output() userChange = new EventEmitter<User | undefined>();
    @Output() emailChange = new EventEmitter<string>();

    existingUser?: User;
    checkComplete = false;
    checking = false;
    email = new FormControl<string | null>(null, [ Validators.required, EawValidators.email() ]);

    check() {
        const email = this.email.value;
        if (!email) {
            return;
        }

        this.checking = true;
        this.email.disable();
        this.userService.get(email).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 404) {
                    return of(undefined);
                }

                this.email.enable();
                return EMPTY;
            }),
        ).subscribe((user) => {
            this.checking = false;
            this.checkComplete = true;
            this.existingUser = user;
            this.userChange.emit(user);
            this.emailChange.emit(email);
            this.step.completed = true;
            this.stepper.next();
        });
    }
}
