// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
import { PaginationOld } from '../../../../shared/utils/pagination-old';
module('eaw.scheduling.shifts').factory('SwapFactory', [ 'EawResource', function SwapFactory(EawResource) {
    const fac = this;
    const applicantUrl = '/customers/:customer/shift_swaps/:swap/applicants/:applicant';
    const swapUrl = '/customers/:customer/shift_swaps/:swap';
    fac.all = (args) => EawResource.create('/customers/:customer/employee/:employee/available_shifts').get({
        ...PaginationOld.getParams(args),
        customer: args.customer,
        employee: args.employee,
    });
    fac.getAll = (args) => EawResource.create(swapUrl).get({
        ...args.pagination.toWithPaginatorArguments(),
        ...pick(args.params, [ 'handled', 'approved', 'can_apply', 'can_approve', 'outdated' ]),
        customer: args.customer_id,
    });
    fac.update = (swapId, customer, updateData) => EawResource.create(swapUrl).update({
        customer: customer.id,
        swap: swapId,
    }, updateData);
    fac.addApplicant = (swap, customer) => EawResource.create(applicantUrl).save({
        customer: customer.id,
        swap: swap.id,
    }, { applicant_id: customer.employee.id });
    fac.removeApplicant = (swap, customer) => EawResource.create(applicantUrl).delete({
        customer: customer.id,
        swap: swap.id,
        applicant: customer.employee.id,
    });
    fac.withdrawApplication = (customerId, swapId, employeeId) => EawResource.create(applicantUrl).delete({
        customer: customerId,
        swap: swapId,
        applicant: employeeId,
    });
    fac.applyForSwap = (customerId, swapId, employeeId) => EawResource.create(applicantUrl).save({
        customer: customerId,
        swap: swapId,
    }, {
        applicant_id: employeeId,
    });
    fac.delete = (swap, customer) => EawResource.create(swapUrl).delete({
        customer: customer.id,
        swap: swap.id,
    });
    fac.deleteForEmployee = (customerId, employeeId, swapId) => EawResource.create('/customers/:customer/employees/:employee/shift_swaps/:swap').delete({
        customer: customerId,
        employee: employeeId,
        swap: swapId,
    });
    fac.getStats = (customerId, employeeId, options) => EawResource.create('/customers/:customer/shift_swaps/statistics').get({
        customer: customerId,
        employee_id: employeeId,
        from: options.from,
        to: options.to,
    });
    fac.getSummary = (customerId, swapId) => EawResource.create('/customers/:customer/shift_swaps/:swap/summary').get({
        customer: customerId,
        swap: swapId,
    });
    return fac;
} ]);
