<mat-card id="filter-card">
    <mat-card-content>
        <form class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-12" [formGroup]="formGroup">
            <mat-form-field class="tw-w-full sm:tw-w-1/2 md:tw-w-1/4" eawDatePickerOptions>
                <mat-label>{{'ENTER_DATE_RANGE' | translate | async}}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
                    <input formControlName="from" matStartDate [placeholder]="'START_DATE' | translate | async">
                    <input formControlName="to" matEndDate [placeholder]="'END_DATE' | translate | async">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <eaw-autocomplete formControlName="employee"
                              class="tw-w-full sm:tw-w-1/2 md:tw-w-1/4"
                              [options]="employeeAutocompleteService.options"
                              [getter]="employeeAutocompleteService.getter({
                                  customerId: current.getCustomer().id,
                                  includeExternal: true,
                                  from: formGroup.value.dateRange?.from,
                                  to: formGroup.value.dateRange?.to
                              })"
                              [setter]="employeeAutocompleteService.setter({customerId: current.getCustomer().id})">
            </eaw-autocomplete>
        </form>

        <div>
            <button mat-raised-button color="primary" (click)="getData()" [disabled]="formGroup.pristine || formGroup.invalid || formGroup.disabled">{{'SUBMIT' | translate | async}}</button>
        </div>
    </mat-card-content>
</mat-card>

<mat-card id="data-card" class="mat-compact-card" *ngIf="selectedEmployee">
    <div id="processing" *ngIf="formGroup.disabled">
        <mat-spinner mode="indeterminate" diameter="80" strokeWidth="7"></mat-spinner>
    </div>

    <mat-card-header class="tw-grid tw-grid-cols-[auto_auto_1fr]">
        <mat-card-title>{{selectedEmployee.name}}</mat-card-title>
        <mat-card-subtitle>{{selectedInterval?.[0] | DateTime:'DATE_SHORT'}} - {{selectedInterval?.[1] | DateTime:'DATE_SHORT'}}</mat-card-subtitle>

        <button class="new-tp-btn" mat-raised-button *ngIf="selectedEmployee" (click)="updateTimepunch()">{{'NEW_TIMEPUNCH' | translate:'payroll' | async}}</button>

        <button class="filter-btn" mat-icon-button (click)="showFilter = !showFilter">
            <mat-icon>filter_alt</mat-icon>
        </button>
    </mat-card-header>

    <mat-card-content class="tw-grid tw-grid-rows-[auto]">
        <div id="header" class="tw-grid tw-grid-cols-[135px_110px_85px_85px_120px_120px_90px_90px_130px_90px_140px_110px_1fr]">
            <!--Categories-->
            <div class="tw-col-[1_/_span_2]"></div>
            <div class="tw-col-[3_/_span_4]">{{'SHIFT' |  translate | async}}</div>
            <div class="tw-col-[7_/_span_4]">{{'TIMEPUNCH' |  translate:'payroll' | async}}</div>
            <div class="tw-col-[11_/_-1]"></div>

            <!--Subheader-->
            <div>{{'DATE' |  translate | async}}</div>
            <div></div>
            <div>{{'FROM' |  translate | async}}</div>
            <div>{{'TO' |  translate | async}}</div>
            <div>{{'DURATION' |  translate | async}}</div>
            <div>{{'BREAK' |  translate:'payroll' | async}}</div>
            <div>{{'IN' |  translate:'payroll' | async}}</div>
            <div>{{'OUT' |  translate:'payroll' | async}}</div>
            <div>{{'DURATION' |  translate | async}}</div>
            <div class="cell-center">{{'APPROVED' |  translate | async}}</div>
            <div>{{'LOCATION' |  translate | async}}</div>
            <div class="cell-center">{{'COMMENT_plural' |  translate | async}}</div>
            <div>{{'FLEXI_BALANCE' |  translate:'payroll' | async}}</div>
        </div>

        <div class="overview-day tw-grid tw-grid-cols-[135px_110px_auto_minmax(190px,_1fr)]" *ngFor="let overviewDay of overviewDays">
            <div class="date cell" [class.holiday]="overviewDay.holiday" [class.sunday]="overviewDay.sunday">{{overviewDay.day | DateTime:'DATE_FULL'}}</div>
            <div class="weekday cell" [class.holiday]="overviewDay.holiday" [class.sunday]="overviewDay.sunday">{{overviewDay.day | DateTime:'WEEKDAY'}}</div>

            <div class="shifts-container tw-grid tw-grid-rows-[auto]">
                <div class="shift-info tw-grid tw-grid-cols-[85px_85px_120px_120px_auto]" *ngFor="let item of overviewDay.shifts | keyvalue">
                    <div class="cell">{{item.key?.from | DateTime:'TIME_24_SIMPLE'}}</div>
                    <div class="cell">{{item.key?.to | DateTime:'TIME_24_SIMPLE'}}</div>
                    <div class="cell">{{item.key?.netLength | duration}}</div>
                    <div class="cell" [class.actionable]="!!item.key" (click)="changeBreakLength(item.key)">{{item.key?.breakLength | duration}}</div>

                    <div class="timepunches-container tw-grid tw-grid-rows-[auto]" [style.min-width]="(tpInColumn + tpOutColumn + tpLengthColumn + tpApprovedColumn + tpCustomerColumn + tpCommentsColumn) + 'px'">
                        <div class="timepunch-info tw-grid tw-grid-cols-[90px_90px_130px_90px_140px_110px]" *ngFor="let tp of item.value; first as firstPunch">
                            <div class="cell actionable" (click)="updateTimepunch(overviewDay, tp)">{{tp.in | DateTime:'TIME_24_SIMPLE'}}</div>
                            <div class="cell actionable" (click)="updateTimepunch(overviewDay, tp)">{{tp.out | DateTime:'TIME_24_SIMPLE'}}</div>
                            <div class="cell">{{tp.length | duration:[]}}</div>
                            <div class="cell approval cell-center" [class.approved]="tp.approved" [class.rejected]="!tp.approved">
                                <mat-icon>{{tp.approved ? 'check' : 'close'}}</mat-icon>
                            </div>
                            <div class="cell">{{tp.customer?.name}}</div>
                            <div class="cell comments" (click)="tp.comments.length ? showComments(tp) : null">
                                <div *ngIf="tp.comments.length">
                                    <span>{{tp.comments.length}}</span>
                                    <mat-icon>comment</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cell">{{(overviewDay.flexitimes[0]?.delta || 0) * 3600 | duration}}</div>
        </div>

        <div id="footer" class="tw-grid tw-grid-cols-[135px_110px_85px_85px_120px_120px_90px_90px_130px_90px_140px_110px_1fr]">
            <div class="tw-col-[1_/_span_4]"></div>
            <div>{{totalShiftDuration | duration:['hours', 'minutes']}}</div>
            <div>{{totalBreakDuration | duration:['hours', 'minutes']}}</div>
            <div class="tw-col-[7_/_span_2]"></div>
            <div>{{totalPunchDuration | duration:['hours', 'minutes']}}</div>
            <div class="tw-col-[10_/_span_3]"></div>
            <div>{{flexitimeBalance * 3600 | duration:['hours', 'minutes']}} ({{'CURRENT' | translate | async}})</div>
        </div>
    </mat-card-content>
</mat-card>
