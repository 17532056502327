import { DataTableColumn } from './data-table-column';
import { DataTableHeader } from './data-table-header';
import { DataTableFunctionValue } from '../interfaces/data-table-value';
import { CustomFieldType } from '../../custom-fields/typings/custom-field-type';
import { TextPortalComponent } from '../portals/text-portal/text-portal.component';
import { DataTableCell } from '../interfaces/data-table-cell';
import { t } from 'i18next';
import { BusinessDate } from '../../shared/utils/business-date';
import { DateTime } from 'luxon';
import { Namespace } from '../../shared/enums/namespace';
import { ModelCustomField } from '../../custom-fields/models/model-custom-field';

interface IDataTableCustomFieldColumn<Item> {
    header?: DataTableHeader;
    languageTag: string;
    customFieldType: CustomFieldType | undefined,
    customField: DataTableFunctionValue<DataTableCustomFieldColumn<Item>, Item, ModelCustomField | undefined>
}

export class DataTableCustomFieldColumn<Item> extends DataTableColumn<DataTableCustomFieldColumn<Item>, Item, unknown> {
    component = TextPortalComponent;

    constructor(column: IDataTableCustomFieldColumn<Item>) {
        const header = column.header || new DataTableHeader({ text: '' });
        if (column.customFieldType === 'integer' || column.customFieldType === 'decimal') {
            header.classes.add('header-right');
            header.arrowPosition = 'before';
        }

        super({
            exportable: true,
            header,
            ...column,
        });

        switch (column.customFieldType) {
            case 'string': {
                this.value = async (item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>) => this.getStringValue(item, column);
                break;
            }
            case 'integer': {
                this.value = async (item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>) => this.getIntegerValue(item, column);
                break;
            }
            case 'decimal': {
                this.value = async (item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>) => this.getDecimalValue(item, column);
                break;
            }
            case 'boolean': {
                this.value = async (item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>) => this.getBooleanValue(item, column);
                break;
            }
            case 'date': {
                this.value = async (item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>) => this.getDateValue(item, column);
                break;
            }
            case 'select': {
                this.value = async (item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>) => this.getSelectValue(item, column);
                break;
            }
            default: {
                this.value = () => '';
            }
        }
    }

    private async getBooleanValue(item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>, column: IDataTableCustomFieldColumn<Item>) {
        const value = await this.getFieldValue(item, column);
        if (typeof value === 'boolean') {
            return value ? t('YES') : t('NO');
        }

        return '';
    }

    private async getSelectValue(item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>, column: IDataTableCustomFieldColumn<Item>) {
        const value = await this.getField(item, column);
        const selectedOption = value?.getSelectedOption()?.translationKey;

        return selectedOption ? t(`${Namespace.CustomFields}:${selectedOption}`) : '';
    }

    private async getDateValue(item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>, column: IDataTableCustomFieldColumn<Item>) {
        const value = await this.getFieldValue(item, column);
        return value instanceof BusinessDate ? value.dateTime.toLocaleString(DateTime.DATE_MED) : '';
    }

    private async getDecimalValue(item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>, column: IDataTableCustomFieldColumn<Item>) {
        const value = await this.getFieldValue(item, column);
        return typeof value === 'number' ? this.numberFormatter(column.languageTag, value, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '';
    }

    private async getIntegerValue(item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>, column: IDataTableCustomFieldColumn<Item>) {
        const value = await this.getFieldValue(item, column);
        return typeof value === 'number' ? this.numberFormatter(column.languageTag, value, { maximumFractionDigits: 0 }) : '';
    }

    private async getStringValue(item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>, column: IDataTableCustomFieldColumn<Item>) {
        const value = await this.getFieldValue(item, column);
        return typeof value === 'string' ? value : '';
    }

    private async getFieldValue(item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>, column: IDataTableCustomFieldColumn<Item>) {
        return (await this.getField(item, column))?.value;
    }

    private async getField(item: DataTableCell<DataTableCustomFieldColumn<Item>, Item>, column: IDataTableCustomFieldColumn<Item>) {
        return column.customField(item);
    }

    private numberFormatter(language: string, value: number, options: Intl.NumberFormatOptions = {}): string {
        try {
            return new Intl.NumberFormat(language, options).format(value);
        } catch (e) {
            console.error(e);
            return String(value);
        }
    }
}
