// @ts-nocheck
import { module } from 'angular';
import { smallDevice } from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';

const shiftsLoaderTemplate = `<div class="status-text" ng-bind="ctrl.shiftsTranslation"></div>

<div class="loaded-shifts">{{ctrl.loaded}} / {{ctrl.schedule.shifts_count}}</div>

<md-progress-linear md-mode="determinate" value="{{ctrl.loadPercentage}}"></md-progress-linear>
`;

module('eaw.scheduling').service('shiftsLoader', [ 'shiftEvents', '$mdPanel', function shiftsLoaderService(shiftEvents, $mdPanel) {
    this.loading = false;

    this.load = (scheduleViewCtrl) => {
        if (this.loading) {
            return;
        }

        if (scheduleViewCtrl.schedule.original) {
            // Shifts are already on the original schedule
            shiftEvents.trigger.loaded();
            return;
        }

        if (!scheduleViewCtrl.schedule.shifts_count) {
            shiftEvents.trigger.loaded();
            return;
        }

        const element = scheduleViewCtrl.el || document.body;
        const rect = element.getBoundingClientRect();
        const openClose = {
            top: rect.bottom,
            left: rect.left,
        };
        const xPos = smallDevice() ? $mdPanel.xPosition.CENTER : $mdPanel.xPosition.ALIGN_START;
        const yPos = smallDevice() ? $mdPanel.yPosition.CENTER : $mdPanel.yPosition.ALIGN_BOTTOMS;
        const xOffset = smallDevice() ? 0 : 20;
        const yOffset = smallDevice() ? 0 : -35;

        this.loading = true;

        $mdPanel.open({
            attachTo: document.body,
            controller: 'scheduleShiftsLoaderCtrl',
            controllerAs: 'ctrl',
            template: shiftsLoaderTemplate,
            panelClass: 'eaw-panel shifts-loader-panel',
            position: $mdPanel.newPanelPosition()
                .relativeTo(element)
                .withOffsetX(xOffset)
                .withOffsetY(yOffset)
                .addPanelPosition(xPos, yPos),
            animation: $mdPanel.newPanelAnimation()
                .openFrom(openClose)
                .closeTo(openClose)
                .duration(200)
                .withAnimation($mdPanel.animation.SCALE),
            locals: {
                scheduleViewCtrl,
            },
            propagateContainerEvents: true,
            clickOutsideToClose: false,
            escapeToClose: false,
            focusOnOpen: true,
            bindToController: true,
        }).then((panelRef) => {
            this.panelRef = panelRef;
        });
    };

    this.remove = () => {
        this.panelRef?.close?.();
    };

    return this;
} ]);
