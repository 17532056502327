import { t } from 'i18next';
import { module } from 'angular';
import WidgetGrid from '../factories/widget-grid.factory';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.dashboard').component('defaultWidgetOptions', {
    template: `<md-button ng-if="$ctrl.widget.settingsDialog" class="md-icon-button" ng-click="$ctrl.openSettings()">
    <md-icon ng-bind="'settings'"></md-icon>
</md-button>

<md-menu>
    <md-button class="md-icon-button default-widget-options-btn tw-mr-0" ng-click="$mdMenu.open()">
        <md-icon ng-bind="'more_vert'"></md-icon>
    </md-button>

    <md-menu-content width="4">
        <md-menu-item ng-if="$ctrl.widget.widgetInfo.route">
            <md-button ui-sref="{{'eaw/app/' + $ctrl.widget.widgetInfo.route}}">{{'GO' | i18next}}</md-button>
        </md-menu-item>

        <md-menu-divider ng-if="$ctrl.widget.widgetInfo.route"></md-menu-divider>

        <md-menu-item ng-if="!$ctrl.color">
            <md-button ng-click="$ctrl.changeHeaderColor()">{{'widgets:HEADER_COLOR' | i18next}}</md-button>
        </md-menu-item>

        <md-menu-divider ng-if="!$ctrl.color"></md-menu-divider>

        <md-menu-item ng-repeat="width in $ctrl.widths">
            <md-button ng-click="$ctrl.changeWidth(width.value)" ng-disabled="$ctrl.widget.grid.width == width.value" ng-bind="width.text"></md-button>
        </md-menu-item>

        <md-menu-divider></md-menu-divider>

        <md-menu-item ng-repeat="height in $ctrl.heights">
            <md-button ng-click="$ctrl.changeHeight(height.value)" ng-disabled="$ctrl.widget.grid.height == height.value" ng-bind="height.text"></md-button>
        </md-menu-item>

        <md-menu-divider ng-if="$ctrl.canDelete"></md-menu-divider>

        <md-menu-item ng-if="$ctrl.canDelete">
            <md-button ng-click="$ctrl.deleteMyself()">{{'REMOVE' | i18next}}</md-button>
        </md-menu-item>
    </md-menu-content>
</md-menu>
`,
    bindings: {
        widget: '<',
        color: '<',
        onChange: '<?',
    },
    controller: [ '$injector', '$element', function($injector, $element) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.el = $element[0];
            ctrl.canDelete = CurrentOld.getMe().isActing || !ctrl.widget.default;

            ctrl.widths = WidgetGrid.widths.map((w) => {
                return {
                    value: w,
                    text: t(`widgets:WIDTH_${w.toUpperCase()}`),
                };
            });
            ctrl.heights = WidgetGrid.heights.map((h) => {
                return {
                    value: h,
                    text: t(`widgets:HEIGHT_${h.toUpperCase()}`),
                };
            });

            ctrl.setWidthAndHeightClass();
        };

        ctrl.openSettings = () => {
            $injector.get(ctrl.widget.settingsDialog)(ctrl.widget).then((settings: any) => {
                ctrl.widget.setSettings(settings);
                ctrl.onChange?.(settings);
            });
        };

        ctrl.setWidthAndHeightClass = () => {
            const widgetEl = ctrl.el.closest('eaw-widget');

            for (const entryArr of widgetEl.classList.entries()) {
                entryArr.forEach((entry: any) => {
                    if (typeof entry === 'string' && entry.includes('widget-size')) {
                        widgetEl.classList.remove(entry);
                    }
                });
            }

            widgetEl.classList.add('widget-size-width-' + ctrl.widget.grid.width);
            widgetEl.classList.add('widget-size-height-' + ctrl.widget.grid.height);
        };

        ctrl.changeWidth = async (width: any) => {
            await ctrl.widget.changeWidth(width);
            ctrl.setWidthAndHeightClass();
        };

        ctrl.changeHeight = async (height: any) => {
            await ctrl.widget.changeHeight(height);
            ctrl.setWidthAndHeightClass();
        };

        ctrl.changeHeaderColor = async () => {
            await ctrl.widget.changeHeaderColor();
            ctrl.onChange?.(ctrl.widget.settings);
        };

        ctrl.deleteMyself = () => ctrl.widget.delete();
    } ],
});
