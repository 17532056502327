// @ts-nocheck
import { module } from 'angular';
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="(ctrl.limit.id ? 'scheduling:EDIT_LIMIT' : 'scheduling:NEW_LIMIT') | i18next" ></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="limitForm" id="limitForm" ng-cloak ng-submit="ctrl.submit(limitForm)" autocomplete="off">
                <md-input-container>
                    <label for="limit" ng-i18next="HOUR_plural"></label>
                    <input type="number" required id="limit" ng-model="ctrl.limit.limit">
                </md-input-container>

                <eaw-date-interval ng-model="ctrl.limitInterval" from-required="true" to-required="true"></eaw-date-interval>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button form="limitForm" ng-disabled="limitForm.$invalid || limitForm.$pristine || ctrl.loading" type="submit" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.scheduling.limits').service('SchedulingLimitDialog', [ '$mdDialog', function($mdDialog) {
    return function(factory) {
        const service = this;
        service.dialogOptions = {
            bindToController: true,
            template: template1,
            locals: {},
            controllerAs: 'ctrl',
            controller: function() {
                const ctrl = this;
                ctrl.$onInit = () => {
                    ctrl.limitInterval = {
                        from: ctrl.limit?.from?.clone(),
                        to: ctrl.limit?.to?.clone(),
                    };
                };
                ctrl.submit = () => {
                    const data = { ...ctrl.limit };
                    data.limit = data.limit * 3600; // limit should be in seconds.
                    data.from = ctrl.limitInterval?.from;
                    data.to = ctrl.limitInterval?.to;
                    $mdDialog.hide(data);
                };
                ctrl.cancel = $mdDialog.cancel;
            },
        };
        service.edit = (limit) => {
            service.dialogOptions.locals.limit = {
                ...limit,
                limit: limit.limit / 3600, // Back to hours
            }; // Assign limit
            return new Promise((resolve, reject) => {
                $mdDialog.show(service.dialogOptions).then((updated) => {
                    service.dialogOptions.locals.limit = undefined; // cleanup
                    factory.update.query(updated.id, updated).$promise.then(resolve, reject);
                }, reject);
            });
        };
        /**
         * On rejection, returns true if "add another" was checked (presumably at least one was added).
         */
        service.new = () => {
            service.dialogOptions.locals.limit = {}; // Reset limit
            return new Promise((resolve, reject) => {
                $mdDialog.show(service.dialogOptions).then((data) => {
                    factory.create.query(data).$promise.then(resolve, reject);
                }).catch(reject);
            });
        };
    };
} ]);
