import { inject, Injectable } from '@angular/core';
import { HttpContext, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap, take, throwError } from 'rxjs';
import { ErrorDialogService } from '../dialogs/error-dialog/error-dialog.service';
import { IGNORE_ERROR } from '../http/http-contexts';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private readonly errorDialog = inject(ErrorDialogService);

    shouldShowDialog(error: HttpErrorResponse, context: HttpContext) {
        // This gets handled in the unauthenticated-error.interceptor
        if (error.status === 401) {
            return false;
        }

        const ignoreErrorContext = context.get(IGNORE_ERROR);
        switch (typeof ignoreErrorContext) {
            case 'boolean':
                return !ignoreErrorContext;
            case 'function':
                return !ignoreErrorContext(error);
        }

        return !ignoreErrorContext.includes(error.status);
    }

    /**
     * Intercepts the request and handles the error response.
     * @param request
     * @param next
     * @see UnauthenticatedErrorInterceptor
     */
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                /**
                 * Use https://angular.io/api/common/http/HttpContext to add a context token to requests where errors can be ignored.
                 * See a usage example in the service under.
                 * @see ProfilePictureService
                 */
                if (this.shouldShowDialog(error, request.context)) {
                    return this.showDialog(request, error, next);
                }

                return throwError(() => error);
            }),
        );
    }

    private showDialog(request: HttpRequest<unknown>, error: HttpErrorResponse, next: HttpHandler) {
        return this.errorDialog.open({
            request,
            response: error,
        })
            .afterClosed()
            .pipe(
                take(1),
                switchMap((ignoreHashes) => {
                    if (!ignoreHashes?.length) {
                        return throwError(() => error);
                    }

                    const reSend = request.clone({
                        body: {
                            ...(request.body || {}),
                            ignore_exceptions: ignoreHashes,
                        },
                    });

                    return next.handle(reSend);
                }),
            );
    }
}
