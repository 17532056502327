import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { OpenExternalLinkService } from '../../../shared/services/open-external-link.service';

@Component({
    selector: 'eaw-met-no-credit',
    standalone: true,
    imports: [ TranslatePipe, AsyncPipe ],
    template: `{{ 'MET_NORWAY_CREDIT' | translate: 'widgets' | async }}
        <a rel="license" (click)="go($any($event))" [href]="href()">(CC BY 4.0)</a>`,
    styleUrl: './met-no-credit.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetNoCreditComponent {
    private readonly openExternalLinkDialog = inject(OpenExternalLinkService);
    href = signal('http://creativecommons.org/licenses/by/4.0/');

    go($event: PointerEvent | TouchEvent) {
        this.openExternalLinkDialog.open({ url: this.href() }, $event).subscribe();
    }
}
