import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbsenceType } from '../../models/absence-type';
import { AbsenceStat } from '../../http/absence-stats.service';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { NumberPipe } from '../../../shared/pipes/number.pipe';

@Component({
    selector: 'eaw-absence-stat-card',
    templateUrl: './absence-stat-card.component.html',
    styleUrl: './absence-stat-card.component.scss' ,
    standalone: true,
    imports: [
        MatCardModule,
        NgIf,
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
    ],
})
export class AbsenceStatCardComponent implements OnInit, OnChanges {
    @Input() absenceType!: AbsenceType;
    @Input() stats: AbsenceStat = {
        length: 0,
        count: 0,
    };

    decimals = 0;

    ngOnChanges(): void {
        this.ngOnInit();
    }

    ngOnInit(): void {
        this.stats = this.stats || {
            count: 0,
            length: 0,
        };

        this.decimals = this.stats.length % 1 === 0 ? 0 : 2;
    };

}
