import { ApiResponse } from '../../shared/interfaces/api-response';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { DateTime } from 'luxon';

export interface BalanceChangeResponse extends ApiResponse {
    id: number,
    type: string,
    object_id: number,
    object_type: string,
    business_date: string,
    delta: number,
    locked: boolean,
    description: string,
    created_at: string,
    updated_at?: string,
    deleted_at?: string,
}
export class BalanceChange {
    id: number;
    type: string;
    objectId?: number;
    ObjectType: string;
    businessDate: DateTime;
    delta: number;
    locked: boolean;
    createdAt: DateTime;
    updatedAt: DateTime | null;
    deletedAt: DateTime | null;
    description: string;

    constructor(data: BalanceChangeResponse) {
        this.id = data.id;
        this.type = data.type;
        this.objectId = data.object_id;
        this.ObjectType = data.object_type;
        this.businessDate = stringToDateTime(data.business_date);
        this.delta = data.delta;
        this.locked = data.locked;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.description = data.description || '';
    }
}
