<eaw-dialog-header>
    <span title>{{'GO_TO_EMP_ID' | translate: 'chainops' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form (ngSubmit)="toEmployee()">
        <mat-form-field class="tw-w-full">
            <mat-label>{{'EMPLOYEE_ID' | translate: 'company' | async}}</mat-label>
            <input matInput type="number" autocomplete="off" [formControl]="employeeId">
            <mat-error>{{'REQUIRED' | translate | async}}</mat-error>
        </mat-form-field>
        <mat-error class="not-found-error" *ngIf="error">{{error}}</mat-error>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{'CANCEL' | translate | async}}</button>
    <button mat-raised-button
            color="primary"
            [disabled]="!employeeId.valid"
            (click)="toEmployee()">{{'GO' | translate | async}}</button>
</mat-dialog-actions>
