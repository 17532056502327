import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DurationPipe } from '../../../../shared/pipes/duration.pipe';
import { NumberPipe } from '../../../../shared/pipes/number.pipe';
import { ColumnHeader } from '../../../types/column-header';
import { AggregateRow } from '../../../types/aggregate-row';
import { AggregateTimepunchValues } from '../../../types/aggregate-timepunch-values';
import { DateTime } from 'luxon';
import { Setting } from '../../../../shared/models/setting';
import { TranslateService } from '../../../../shared/services/translate.service';
import { MaterialColorPipe } from '../../../../shared/pipes/material-color.pipe';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'eaw-cell-value',
    templateUrl: './cell-value.component.html',
    styleUrl: './cell-value.component.scss',
    providers: [ DurationPipe, NumberPipe ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatBadgeModule,
        AsyncPipe,
        TranslatePipe,
        MaterialColorPipe,
    ],
})
export class CellValueComponent implements OnInit, OnChanges {
    @Output() buttonClick = new EventEmitter<{ row: AggregateRow, column: ColumnHeader }>();

    @Input() column!: ColumnHeader;
    @Input() row!: AggregateRow;
    @Input() settings: Setting[] = [];

    value: unknown;
    isSpecialCase = false;
    specialCases: AggregateTimepunchValues[] = [ 'approved_count', 'warnings_count', 'infractions', 'comments_count', 'out' ];

    constructor(
        @Inject(ElementRef) private elementRef: ElementRef,
        @Inject(DurationPipe) private durationPipe: DurationPipe,
        @Inject(NumberPipe) private numberPipe: NumberPipe,
        @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
        @Inject(TranslateService) private translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.setValue();
    }

    ngOnChanges() {
        this.setValue();
        this.changeDetectorRef.markForCheck();
    }

    setValue() {
        this.isSpecialCase = !this.row.aggregated && this.specialCases.includes(this.column.key);
        this.value = this.row.data.values[this.column.key]?.value;

        /**
         * Do not return after cases where the value is not in the standard location; we want the value to be passed on and formatted
         * according to the column's type
         */
        if (this.column.key === 'infractions') {
            this.value = (this.value as unknown[] | undefined)?.length;
        }

        if (this.column.key === 'deleted') {
            this.value = this.row.data.deleted;
        }

        if (this.column.key === 'business_date') {
            const dates: DateTime[] = [];

            if (this.row.data.businessDate) {
                dates.push(this.row.data.businessDate.dateTime);
            } else if (this.row.data.from) {
                dates.push(this.row.data.from);

                if (this.row.data.to && !this.row.data.fromToSame) {
                    dates.push(this.row.data.to);
                }
            }

            this.value = dates.map((d) => d.toLocaleString(DateTime.DATE_SHORT)).join(' - ');
        }

        // Keep the value going to be formatted unless it's nil or a special case
        if (this.value == null || this.isSpecialCase) {
            return;
        }

        switch (this.column.type) {
            case 'integer': {
                if (!this.row.aggregated && this.value == 0) {
                    this.value = '-';
                    break;
                }

                const count = this.transformNumber(this.value as number, false);
                this.value = this.row.aggregated && this.row.data.values[this.column.key]?.aggregatedXY ? count + ' / ' + this.numberPipe.transform(this.row.data.items, 0) : count;
                break;
            }
            case 'decimal': {
                this.value = this.transformNumber(this.value as number, true);
                break;
            }
            case 'hours': {
                this.value = this.durationPipe.transform(this.value, [ 'hours', 'minutes' ], { unitDisplay: 'narrow' });
                break;
            }
            case 'days': {
                this.value = this.durationPipe.transform(this.value, [ 'days' ], { unitDisplay: 'narrow' });
                break;
            }
            default: {
                // Do nothing
            }
        }

        void this.applySettings();
    }

    async applySettings() {
        const tpShiftDiffSetting = this.settings.find((s) => s.key === 'payroll.tp_shift_diff_margin');
        const value = this.row.data.values[this.column.key]?.pureValue;

        // Apply diff highlight if diff exceeds setting (minutes) and row has to be aggregated
        if (this.column.key === 'diff' && this.row.aggregated && tpShiftDiffSetting?.value) {
            const el = this.elementRef.nativeElement;
            const minutes = tpShiftDiffSetting.value.asInteger() || 0;
            const seconds = minutes * 60;

            if (typeof value === 'number' && Math.abs(value) >= seconds) {
                el.style.color = 'red';
                el.title = await this.translate.t('EXCEEDS_X_MINUTES', 'payroll', { count: minutes });
            } else {
                el.style.color = 'inherit';
                el.removeAttribute('title');
            }
        }
    }

    transformNumber(value: number, decimal: boolean) {
        return this.numberPipe.transform(value, decimal ? 2 : 0);
    }
}
