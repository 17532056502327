// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

const template1 = `<h2 ng-bind="$shifts.sum | eawNumber:2"></h2>
<span ng-bind="::$shifts.month"></span>
`;

module('eaw.dashboard').component('shiftSumMiniWidget', {
    template: template1,
    require: {
        widget: '^eawMiniWidget',
    },
    controllerAs: '$shifts',
    controller: [ 'ShiftFactory', function(ShiftFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.month = moment().format('MMMM');

            ctrl.shiftRes = ShiftFactory.getAll({
                // Only people with employee get to see this
                employee_id: CurrentOld.employee.id,
                customer_id: CurrentOld.customer.id,
                per_page: 250, // Most likely there are max 30 shifts
                from: moment().startOf('M'),
                to: moment().endOf('M'),
                order_by: 'from',
                direction: 'asc',
                with: [],
            });

            ctrl.shiftRes.$promise.then((resp) => {
                ctrl.sum = resp.data.reduce((sum, x) => sum + x.net_length, 0) / 3600;
            }).finally(() => {
                ctrl.widget.loading(false);
            });
        };

        ctrl.$onDestroy = () => {
            ctrl.shiftRes?.$cancelRequest?.();
        };
    } ],
});
