import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { ApiResponse } from '../interfaces/api-response';
import { BusinessDate } from '../utils/business-date';
import { BusinessDateString } from '../types/business-date';

export interface InfractionResponse extends ApiResponse {
    business_date?: BusinessDateString | null;
    customer_id: number;
    customer_name: string;
    employee_id: number;
    employee_name: string;
    event: string;
    ignored: boolean;
    message: string;
    source: string;
    time: string;
    type: string;
}

export class Infraction {
    businessDate: BusinessDate | null;
    customerId: number;
    customerName: string;
    employeeId: number;
    employeeName: string;
    event: string;
    ignored: boolean;
    message: string;
    source: string;
    time: DateTime | null;
    type: string;

    constructor(data: InfractionResponse) {
        this.businessDate = data.business_date ? new BusinessDate(data.business_date) : null;
        this.customerId = data.customer_id;
        this.customerName = data.customer_name;
        this.employeeId = data.employee_id;
        this.employeeName = data.employee_name;
        this.event = data.event;
        this.ignored = data.ignored;
        this.message = data.message;
        this.source = data.source;
        this.time = stringToDateTime(data.time);
        this.type = data.type;
    }
}
