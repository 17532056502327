import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Namespace, NamespaceFile } from '../enums/namespace';

export interface CustomerType {
    id: number;
    type: string;
    namespace: NamespaceFile;
    translationKey: string;
}

@Injectable({
    providedIn: 'root',
})
export class CustomerTypeService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(): Observable<CustomerType[]> {
        return this.http.get<Record<string, number>>('/customer_types').pipe(
            map((resp) => {
                return Object.entries(resp).map(([ key, value ]) => {
                    return {
                        id: value,
                        type: key,
                        namespace: Namespace.Admin,
                        translationKey: key,
                    };
                });
            }),
        );
    }
}
