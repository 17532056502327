// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
module('eaw.company').component('employeeVacation', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="VACATION_plural"></span>
            <span ng-if="$empVac.vacationDaysText" class="md-subhead" ng-bind="$empVac.vacationDaysText"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="$empVac.showFilter"
                        icon="filter_alt"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>
    </md-card-header>
    <md-card-content>
        <md-card ng-show="$empVac.showFilter">
            <md-card-content layout="row" layout-wrap>
                <md-input-container flex="100" flex-gt-sm="25">
                    <label ng-i18next="MODE"></label>
                    <md-select ng-model="$empVac.mode" ng-change="$empVac.filterChanged()">
                        <md-option ng-repeat="(key, mode) in $empVac.modes track by key" ng-value="mode">{{mode.label}}</md-option>
                    </md-select>
                </md-input-container>

                <md-input-container flex="100" flex-gt-sm="25">
                    <label ng-i18next="vacation:VACATION"></label>
                    <md-select ng-model="$empVac.vacation" ng-change="$empVac.filterChanged()">
                        <md-option ng-repeat="(key, mode) in $empVac.vacationModes track by key" ng-value="mode">{{mode.label}}</md-option>
                    </md-select>
                </md-input-container>

                <eaw-date-interval layout="row"
                                   flex="100"
                                   flex-gt-sm="50"
                                   input-flex="50"
                                   ng-model="$empVac.interval"
                                   ng-change="$empVac.filterChanged()">
                </eaw-date-interval>
            </md-card-content>
        </md-card>

        <eaw-dynamic-ng-table
                cells="$empVac.cells"
                columns="$empVac.columns"
                get-data="$empVac.getVacations(pagination)"
                reload-table="$empVac.reloadTable">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$empVac',
    bindings: {
        customer: '<',
        employee: '<',
    },
    controller: [ 'OffTimeService', 'UrlParams', 'vacationDays', function(OffTimeService, UrlParams, vacationDays) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.offTimeFactory = new OffTimeService(ctrl.customer.id, ctrl.employee.id);
            ctrl.interval = {
                from: moment().startOf('y'),
                to: moment().endOf('y'),
            };
            ctrl.vacationModes = [
                {
                    label: t('YES'),
                    value: true,
                },
                {
                    label: t('NO'),
                    value: false,
                },
                {
                    label: t('ALL'),
                    value: undefined,
                },
            ];
            ctrl.vacation = ctrl.vacationModes.find((vm) => UrlParams.get('vacation') != null && !!UrlParams.get('vacation') === vm.value) || ctrl.vacationModes[ctrl.vacationModes.length - 1];
            ctrl.modes = [
                {
                    name: 'unprocessed',
                    label: t('vacation:UNPROCESSED_VACATIONS'),
                    handled: 0,
                },
                {
                    name: 'processed',
                    label: t('vacation:PROCESSED_VACATIONS'),
                    handled: 1,
                },
                {
                    name: 'approved',
                    label: t('vacation:APPROVED_VACATIONS'),
                    approved: 1,
                },
                {
                    name: 'declined',
                    label: t('vacation:DECLINED_VACATIONS'),
                    approved: 0,
                },
                {
                    name: 'all',
                    label: t('vacation:ALL_VACATIONS'),
                },
            ];
            ctrl.mode = ctrl.modes.find((m) => m.name === UrlParams.get('mode')) || ctrl.modes[ctrl.modes.length - 1];
            ctrl.columns = [
                {
                    title: t('FROM'),
                    sortable: 'from',
                },
                {
                    title: t('TO'),
                    sortable: 'to',
                },
                {
                    title: t('vacation:DAYS_DEDUCTED'),
                    sortable: 'length',
                    class: 'text-right',
                },
                {
                    title: t('company:DURATION_DAYS'),
                    class: 'text-right',
                },
                {
                    title: t('APPROVED'),
                    class: 'text-center',
                },
            ];
            ctrl.cells = [
                {
                    template: 'item.from | moment:"LL"',
                    sortable: 'from',
                },
                {
                    template: 'item.to | moment:"LL"',
                    sortable: 'to',
                },
                {
                    template: 'item.days',
                    classes: [ 'text-right' ],
                },
                {
                    template: 'item.duration',
                    sortable: 'length',
                    classes: [ 'text-right' ],
                },
                {
                    approval: {
                        displayName: true,
                        displayTime: true,
                    },
                },
            ];
            if (CurrentOld.getUser().can(`customers.${ctrl.customer.id}.employees.${ctrl.employee.id}.vacation_days.*.get`, false, {
                employee: ctrl.employee,
                customer: ctrl.customer,
            })) {
                ctrl.getVacationDays();
            }
        };
        ctrl.getVacationDays = () => {
            const year = moment().year();
            vacationDays.getEmployeeStats({
                customer: ctrl.customer,
                employee: ctrl.employee,
                year,
            }).$promise.then((resp) => {
                ctrl.vacationDaysText = t(resp.vacation_days_carried_over == 0 ? 'vacation:CURR_VAC_DAYS' : 'vacation:CURR_VAC_DAYS_WITH_CARRY', {
                    current: resp.vacation_days,
                    all: resp.vacation_days_received,
                    year,
                    carried_over: resp.vacation_days_carried_over,
                });
            });
        };
        ctrl.filterChanged = () => {
            UrlParams.set('mode', ctrl.mode.name);
            UrlParams.set('vacation', ctrl.vacation.value);
            UrlParams.set('from', ctrl.interval.from);
            UrlParams.set('to', ctrl.interval.to);
            ctrl.reloadTable = true;
        };
        ctrl.getVacations = (pagination) => ctrl.offTimeFactory.employee.getAll.query({
            ...pagination,
            from: ctrl.interval?.from,
            to: ctrl.interval?.to,
            handled: ctrl.mode.handled,
            approved: ctrl.mode.approved,
            vacation: ctrl.vacation.value,
            with: [ 'approval', 'vacationDaysChange' ],
        }).$promise.then((resp) => {
            resp.data.forEach((vac) => {
                vac.days = Math.abs(vac.vacation_days_change?.reduce((sum, change) => sum + change.delta, 0));
                vac.duration = vac.to.diff(vac.from, 'd') + 1;
            });
            return resp.data;
        });
    } ],
});
