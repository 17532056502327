import { Component } from '@angular/core';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { DefaultCustomerSettingComponent } from './settings/default-customer-setting/default-customer-setting.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

@Component({
    selector: 'eaw-profile-settings',
    templateUrl: './profile-settings.component.html',
    styleUrl: './profile-settings.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatExpansionModule,
        DefaultCustomerSettingComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ProfileSettingsComponent {
}
