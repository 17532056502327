import { module } from 'angular';

const template1 = `
<div class="tw-m-24 tw-flex tw-flex-col">
<span class="mat-body-2" ng-bind="$ctrl.event.name"></span>
<span class="mat-body-1" ng-bind="$ctrl.event.description"></span>
</div>
`;

module('eaw.dashboard').component('miniEventDescription', {
    template: template1,
    bindings: {
        event: '<',
    },
});
