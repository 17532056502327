import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { BusinessDate } from '../../shared/utils/business-date';
import { TimepunchBlock, TimepunchBlockResponse } from '../models/timepunch-block';

@Injectable({
    providedIn: 'root',
})
export class TimepunchBlocksService {
    private readonly http = inject(HttpClient);

    getAllForEmployee(customerId: number, employeeId: number) {
        return this.http.get<TimepunchBlockResponse[]>(`/customers/${customerId}/employees/${employeeId}/timepunch_blocks`).pipe(
            map((timepunchBlocks) => {
                return timepunchBlocks.map((timepunchBlock) => new TimepunchBlock(timepunchBlock));
            }),
        );
    }

    create(customerId: number, employeeId: number, from: BusinessDate, to?: BusinessDate, reason?: string, displayReason?: boolean) {
        return this.http.post<TimepunchBlockResponse>(`/customers/${customerId}/employees/${employeeId}/timepunch_blocks`, {
            start_date: from,
            end_date: to,
            reason,
            display_reason: displayReason,
        }).pipe(
            map((timepunchBlock) => {
                return new TimepunchBlock(timepunchBlock);
            }),
        );
    }

    update(customerId: number, employeeId: number, timepunchBlockId: number, from: BusinessDate, to?: BusinessDate, reason?: string, displayReason?: boolean) {
        return this.http.put<TimepunchBlockResponse>(`/customers/${customerId}/employees/${employeeId}/timepunch_blocks/${timepunchBlockId}`, {
            start_date: from,
            end_date: to,
            reason,
            display_reason: displayReason,
        }).pipe(
            map((timepunchBlock) => {
                return new TimepunchBlock(timepunchBlock);
            }),
        );
    }

    delete(customerId: number, employeeId: number, timepunchBlockId: number) {
        return this.http.delete(`/customers/${customerId}/employees/${employeeId}/timepunch_blocks/${timepunchBlockId}`);
    }
}
