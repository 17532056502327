@if (forecastTime()) {
    <div class="forecast">
        <eaw-weather-icon [size]="56" [symbol]="forecastTime()!.symbol"></eaw-weather-icon>
        <div class="temperature">
            <span class="mat-body-1">{{ location() }}</span>
            <eaw-temperature class="temperature-text"
                             [forecastTime]="forecastTime()!"
                             [darkBackground]="_darkBackground()">
            </eaw-temperature>
        </div>
    </div>
    <eaw-met-no-credit></eaw-met-no-credit>
}
@if (forecast() === false) {
    <div class="forecast">
        <div></div>
        <span class="mat-body-1">{{ 'NO_WEATHER_DATA' | translate : 'widgets' | async }}</span>
    </div>
}
