import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { catchError, debounceTime, EMPTY, forkJoin, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { UIRouter } from '@uirouter/core';
import { CurrentService } from '../../../shared/services/current.service';
import { CustomerEmployeesGetAllOptions, EmployeeService } from '../../../shared/http/employee.service';
import { Employee } from '../../../shared/models/employee';
import { DataTableProfilePictureColumn } from '../../../data-table/types/data-table-profile-picture-column';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Namespace, NamespaceFile } from '../../../shared/enums/namespace';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { MatDialog } from '@angular/material/dialog';
import { WarningsDialogComponent } from '../../../shared/dialogs/warnings-dialog/warnings-dialog.component';
import { WarningsDialogData } from '../../../shared/dialogs/warnings-dialog/warnings-dialog.service';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { DataTableCustomFieldColumn } from '../../../data-table/types/data-table-custom-field-column';
import { TranslateService } from '../../../shared/services/translate.service';
import { HandleEmployeeTerminationService } from '../../dialogs/handle-employee-termination/handle-employee-termination.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { ConfirmDialogReturn } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { UserAccessDialogService } from '../users/dialog/user-access-dialog.service';
import { UserPropertyService } from '../../../shared/http/user-property.service';
import { CustomerService } from '../../../shared/http/customer.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { CustomerCustomFieldService } from '../../../shared/http/customer-custom-field.service';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { AsyncPipe, NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

interface TableColumnOption {
    column: DataTableColumnType<Employee>;
    selected: boolean;
    key: string;
    translation: {
        key?: string;
        ns?: NamespaceFile;
    };
}

interface ShowOption {
    key: string;
    translation: {
        key: string,
        ns: NamespaceFile
    };
    parameters: Partial<Pick<Record<keyof CustomerEmployeesGetAllOptions, boolean>, 'include_future' | 'only_future' | 'only_past' | 'include_inactive'>>;
}

@Component({
    selector: 'eaw-local-employees-list',
    templateUrl: './local-employees-list.component.html',
    styleUrl: './local-employees-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
        TranslateSyncPipe,
    ],
})
export class LocalEmployeesListComponent implements EawDataTable, OnInit {
    @ViewChild('table') table?: DataTableComponent<Employee>;

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) customerStackId!: number;

    // All columns
    allColumns: TableColumnOption[] = [];
    showOptions = [
        { key: 'currentAndFuture', translation: { key: 'CURRENT_AND_FUTURE_EMP_OPT', ns: Namespace.Company }, parameters: { include_future: true } },
        { key: 'current', translation: { key: 'CURRENT_EMP_OPT', ns: Namespace.Company }, parameters: {} },
        { key: 'future', translation: { key: 'FUTURE_EMP_OPT', ns: Namespace.Company }, parameters: { only_future: true } },
        { key: 'past', translation: { key: 'PAST_EMP_OPT', ns: Namespace.Company }, parameters: { only_past: true } },
        { key: 'all', translation: { key: 'ALL_EMP_OPT', ns: Namespace.Company }, parameters: { include_inactive: true } },
    ] as const satisfies readonly ShowOption[];

    total = 0;
    fabButton?: HeaderFabButton;
    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<Employee>[] = [];
    form = new FormGroup({
        filter: new FormControl<string | null>(''),
        showOption: new FormControl<ShowOption>(this.showOptions[0], { nonNullable: true }),
        tableColumns: new FormControl<TableColumnOption[]>([], { nonNullable: true }),
    });

    constructor(
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(CustomerService) private customerService: CustomerService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(TranslateService) private translateService: TranslateService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
        @Inject(HandleEmployeeTerminationService) private handleEmployeeTerminationService: HandleEmployeeTerminationService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(UserAccessDialogService) private userAccessDialogService: UserAccessDialogService,
        @Inject(CustomerCustomFieldService) private customerCustomFieldService: CustomerCustomFieldService,
        @Inject(UserPropertyService) private userPropertyService: UserPropertyService,
    ) {
    }

    ngOnInit() {
        this.fabButton = {
            click: this.createNewEmployee.bind(this),
            // Check if the user can create employees
            hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.create`),
        };

        this.handleShowOptionChange();
        this.handleFilterChange();
        this.setColumns();
    }

    get userColumnsKey() {
        return `local-employees:columns:${this.customerId}`;
    }

    updateColumns() {
        const selectedColumns = this.form.controls.tableColumns.value || [];
        const newColumns = [ ...selectedColumns, this.buttonsColumn ];

        this.columns = newColumns.map((c) => c.column);

        // Save
        this.userPropertyService.update(this.current.getUser().id, this.userColumnsKey, newColumns.map((c) => c.key).join(',')).pipe(take(1)).subscribe();
        this.table?.refresh();
    }

    createNewEmployee() {
        this.uiRouter.stateService.go('eaw/app/company/employees/new');
    }

    handleShowOptionChange() {
        this.form.controls.showOption.valueChanges.subscribe(() => {
            this.table?.refresh();
        });
    }

    handleFilterChange() {
        this.form.controls.filter.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
            this.table?.refresh();
        });
    }

    get buttonsColumn(): TableColumnOption {
        return {
            key: 'buttons',
            selected: false,
            translation: {},
            column: new DataTableButtonColumn({
                buttons: [
                    {
                        icon: 'warning',
                        color: 'amber-500',
                        click: this.openWarningsDialog.bind(this),
                        show: () => of(true),
                        hide: (employee) => of(!employee.warningsCount && !employee.warnings.length),
                        tooltip: { key: 'WARNING_plural' },
                        nonBlocking: true,
                    },
                    {
                        icon: 'person_off',
                        type: 'warn',
                        click: this.deactivate.bind(this),
                        show: (employee) => this.canUpdateEmployees(employee.id),
                        hide: (employee) => of(!employee.isActive),
                        tooltip: { key: 'DEACTIVATE' },
                    },
                    {
                        icon: 'person_add',
                        type: 'warn',
                        click: this.reactivate.bind(this),
                        show: (employee) => this.canUpdateEmployees(employee.id),
                        hide: (employee) => of(employee.isActive),
                        tooltip: { key: 'REACTIVATE' },
                    },
                ],
            }),
        };
    }

    /**
     * Sets all the available columns for the table
     */
    setColumns() {
        this.allColumns = [
            {
                key: 'profilePicture',
                selected: true,
                translation: { key: 'PROFILE_PICTURE' },
                column: new DataTableProfilePictureColumn({
                    user: (cell) => cell.item.user,
                }),
            },
            {
                key: 'name',
                selected: true,
                translation: { key: 'NAME' },
                column: new DataTableTextColumn({
                    value: 'name',
                    subText: (cell) => (cell.item.positions || []).map((p) => p.name).join('\n'),
                    header: new DataTableHeader({ i18n: 'NAME', sortBy: 'name' }),
                }),
            },
            {
                key: 'number',
                selected: true,
                translation: { key: 'NUMBER' },
                column: new DataTableTextColumn({
                    value: 'number',
                    header: new DataTableHeader({ i18n: 'NUMBER', sortBy: 'number' }),
                }),
            },
            {
                key: 'email',
                selected: true,
                translation: { key: 'EMAIL' },
                column: new DataTableTextColumn({
                    value: 'email',
                    header: new DataTableHeader({ i18n: 'EMAIL', sortBy: 'email' }),
                }),
            },
            {
                key: 'phone',
                selected: true,
                translation: { key: 'PHONE' },
                column: new DataTableTextColumn({
                    value: 'phone',
                    header: new DataTableHeader({ i18n: 'PHONE', sortBy: 'phone' }),
                }),
            },
            {
                key: 'birthDate',
                selected: false,
                translation: { key: 'BIRTH_DATE' },
                column: new DataTableDateTimeColumn({
                    value: (cell) => cell.item.birthDate?.dateTime,
                    format: DateTime.DATE_SHORT,
                    header: new DataTableHeader({ i18n: 'BIRTH_DATE', sortBy: 'birth_date' }),
                }),
            },
            {
                key: 'age',
                selected: true,
                translation: { key: 'AGE' },
                column: new DataTableTextColumn({
                    key: 'age',
                    value: async (cell) => {
                        const birthDate = cell.item.birthDate?.dateTime;
                        return birthDate ? this.numberFormatterService.formatUnit(Math.floor(DateTime.now().diff(birthDate, 'seconds').as('years')), 'year', {
                            maximumFractionDigits: 0,
                        }) : '';
                    },
                    header: new DataTableHeader({ i18n: 'AGE', sortBy: 'age' }),
                }),
            },
            {
                key: 'from',
                selected: true,
                translation: { key: 'FROM' },
                column: new DataTableDateTimeColumn({
                    value: 'from',
                    format: DateTime.DATE_MED,
                    header: new DataTableHeader({ i18n: 'FROM', sortBy: 'from' }),
                }),
            },
            {
                key: 'to',
                selected: true,
                translation: { key: 'TO' },
                column: new DataTableDateTimeColumn({
                    value: 'to',
                    format: DateTime.DATE_MED,
                    header: new DataTableHeader({ i18n: 'TO', sortBy: 'to' }),
                }),
            },
        ];

        const customFieldColumns = this.customerCustomFieldService.getForModel(this.customerId, ApiModel.Employee).pipe(
            tap((employeeCustomFieldPivots) => {
                for (const field of employeeCustomFieldPivots) {
                    const header = new DataTableHeader({
                        i18n: field.translationKey,
                        ns: Namespace.CustomFields,
                    });

                    this.allColumns.push({
                        key: field.key || '',
                        selected: false,
                        translation: { key: field.translationKey, ns: Namespace.CustomFields },
                        column: new DataTableCustomFieldColumn({
                            header,
                            languageTag: this.current.languageTag,
                            customFieldType: field.type,
                            customField: (cell) => cell.item.attachedCustomFields.find((p) => p.id === field.id),
                        }),
                    });
                }
            }),
        );

        forkJoin([
            customFieldColumns,
            this.userPropertyService.get(this.current.getUser().id, this.userColumnsKey).pipe(
                catchError(() => of(null)),
                map((columns) => columns?.value.asArray('comma-separated-string'))),
        ]).subscribe(([ _, selectedColumns ]) => {
            // Set the selected columns in the form
            this.form.controls.tableColumns.setValue(this.allColumns.filter((column) => selectedColumns?.includes(column.key) ?? column.selected));

            // Trigger column update
            this.updateColumns();
        });
    }

    openWarningsDialog(cell: DataTableButtonCell<Employee>) {
        this.matDialog.open<WarningsDialogComponent, WarningsDialogData>(WarningsDialogComponent, {
            data: {
                warnings: this.employeeService.get(this.customerId, cell.item.id, {
                    'with[]': [ 'warnings.causes' ],
                }).pipe(
                    map((employee) => employee.warnings),
                ),
            },
        });
    }

    /**
     * Checks if you can update all employees
     * @param employeeId - If provided it will check a specific employee
     */
    private canUpdateEmployees(employeeId?: number) {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${employeeId || '*'}.update`);
    }

    reactivate(cell: DataTableButtonCell<Employee>) {
        this.confirmDialogService.delete({
            title: this.translateService.t('REACTIVATE', 'company'),
            text: this.translateService.t('REACTIVATE_TEXT', 'company', { name: cell.item.name }),
            confirmText: this.translateService.t('REACTIVATE', 'company'),
        }).afterClosed().pipe(
            switchMap((confirmation) => {
                if (!confirmation?.ok) {
                    cell.disabled.set(false);
                    return EMPTY;
                }

                return this.employeeService.update(this.customerId, cell.item.id, { to: null }).pipe(
                    catchError(() => {
                        cell.disabled.set(false);
                        return EMPTY;
                    }),
                );
            }),
            switchMap(() => {
                this.table?.refresh();

                const employeeObservable = this.employeeService.get(this.customerId, cell.item.id, { 'with[]': [ 'user.customerUsers' ] });
                const customerObservable = this.customerService.get(this.customerId);

                return forkJoin([ employeeObservable, customerObservable ]);
            }),
            tap(([ employee, customer ]) => {
                const currentAccess = employee.user?.customerUsers?.find((cu) => {
                    return cu.customerId === this.customerId && cu.isActive;
                });

                if (employee.user && !currentAccess) {
                    this.userAccessDialogService.createAccess(customer, employee.user).beforeClosed().subscribe(() => {
                        this.table?.refresh();
                    });
                } else {
                    cell.disabled.set(false);
                }
            })).subscribe();
    }

    deactivate(cell: DataTableButtonCell<Employee>) {
        const customerId = cell.item.customerId;
        const employeeId = cell.item.id;
        if (!customerId || !employeeId) {
            return;
        }

        // This confirmation defaults to true, but will be overwritten if a user tries to deactivate themselves
        let deactivationConfirmation: Observable<ConfirmDialogReturn | undefined> = of({ ok: true, comment: undefined });

        // Deactivating yourself?
        if (this.current.getUser().id === cell.item.userId) {
            deactivationConfirmation = this.confirmDialogService.delete({
                title: this.translateService.t('DEACTIVATE', 'company'),
                text: this.translateService.t('DEACTIVATE_YOURSELF_TEXT', 'company'),
                confirmText: this.translateService.t('DEACTIVATE', 'company'),
            }).afterClosed();
        }

        deactivationConfirmation.subscribe((confirmation) => {
            if (!confirmation?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.handleEmployeeTerminationService.open(cell.item).beforeClosed().subscribe((handle) => {
                if (!handle) {
                    cell.disabled.set(false);
                    return;
                }

                this.employeeService.update(customerId, employeeId, {
                    to: DateTime.now(),
                    resolve_termination: handle,
                }).subscribe(() => {
                    this.table?.refresh();
                });
            });
        });
    }

    rowClasses(row: Employee) {
        const classes: string[] = [];
        if (!row.isActive) {
            classes.push('inactive');
        }
        return classes;
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        // const fields = this.form.controls.searchFields.value;
        const withs = [ 'positions', 'user' ];

        // Special case for name because counting warnings does not work when sorting by name
        if (pagination.order_by === 'name') {
            withs.push('warnings');
        }

        this.request = this.employeeService.getAll(this.customerId, {
            ...pagination,
            order_by: pagination.order_by === 'age' ? 'birth_date' : pagination.order_by,
            filter: this.form.value.filter ?? undefined,
            // 'fields[]': fields,
            'count[]': [ 'warnings' ],
            'with[]': withs,
            ...this.form.controls.showOption.value.parameters,
        }).pipe(
            tap((result) => (this.total = result.total)),
        );
    }
}
