<eaw-page-header [fabButton]="createButton">
    <span title>{{'ROLE_plural' | translate: 'company' | async}}</span>
</eaw-page-header>
<mat-card class="mat-compact-card mat-card-contained tw-mt-20">
    <mat-card-content>
        <eaw-data-table
            #table
            [columns]="columns"
            [request]="request"
            [goTo]="goTo"
            sortActive="name"
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
