<eaw-page-header [buttons]="headerButtons">
    <span title>{{ 'OWNERSHIP_TRANSFER' | translate : 'admin' | async }}</span>
</eaw-page-header>

<mat-card>
        <eaw-data-table
                #table
                [columns]="columns"
                [getData]="getData()"
                initialSortBy="created_at">
        </eaw-data-table>
</mat-card>
