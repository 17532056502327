// @ts-nocheck
import { module } from 'angular';
module('eaw.ai-budgeting').factory('mlProjectionsFactory', [ 'EawResource', 'Pagination', function mlProjectionsFactory(EawResource, Pagination) {
    const fac = this;
    const baseUrl = '/customers/:customer/ml_buckets/:bucket/time_series/:series/projections/:projection';
    const res = EawResource.create(baseUrl);
    const applyRes = EawResource.create(`${baseUrl}/apply`);
    fac.get = {
        permission(customer, bucket, series, projection) {
            return `customers.${customer}.ml_buckets.${bucket}.time_series.${series}.projections.${projection}.get`;
        },
        query(customer, bucket, series, projection) {
            return res.get({
                customer,
                bucket,
                series,
                projection,
            }).$promise;
        },
    };
    fac.getAll = {
        permission(customer, bucket, series) {
            return `customers.${customer}.ml_buckets.${bucket}.time_series.${series}.projections.*.get`;
        },
        query(customer, bucket, series, args) {
            return res.get({
                customer,
                bucket,
                series,
                from: args?.from,
                to: args?.to,
                ...Pagination.getParams(args),
            }).$promise;
        },
    };
    fac.create = {
        permission(customer, bucket, series) {
            return `customers.${customer}.ml_buckets.${bucket}.time_series.${series}.projections.*.create`;
        },
        /**
         * @param {Number} customer
         * @param {String} bucket
         * @param {String} series
         * @param {Object} args
         * @param {moment.Moment} args.from
         * @param {moment.Moment} args.to
         * @param {String} args.name
         * @param {Object[]} args.projections
         * @param {Number|null} args.projections.value
         * @param {moment.Moment} args.projections.time
         * @param {Boolean} args.propagateProjections
         * @param {String[]} args.mirrorToVariables - array of uuids
         * @param {Boolean} args.applyProjections
         * @param {moment.Moment[]} [args.comparableDates]
         * @returns {Promise}
         */
        query(customer, bucket, series, args) {
            return res.save({
                customer,
                bucket,
                series,
            }, {
                from: args.from,
                to: args.to,
                name: args.name,
                projections: args.projections,
                propagate_projections: args.propagateProjections,
                apply_projections: args.applyProjections,
                mirror_to_variables: args.mirrorToVariables,
                comparable_dates: args.comparableDates,
            }).$promise;
        },
    };
    fac.apply = {
        permission(customer, bucket, series, projection) {
            return `customers.${customer}.ml_buckets.${bucket}.time_series.${series}.projections.${projection}.apply`;
        },
        query(customer, bucket, series, projection, args) {
            return applyRes.update({
                customer,
                bucket,
                series,
                projection,
            }, {
                comparable_dates: args?.comparable_dates,
            }).$promise;
        },
    };
    return fac;
} ]);
