import { Component, Inject, Input, OnChanges } from '@angular/core';
import { PayTypeLink } from '../../../shared/models/pay-type-link';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';

@Component({
    selector: 'eaw-variable-payment-display',
    template: '{{ value }}',
    styleUrl: './variable-payment-display.component.scss',
    standalone: true,
})
export class VariablePaymentDisplayComponent implements OnChanges {
    @Input({ required: true }) payTypeLink!: PayTypeLink;
    @Input({ required: true }) number!: number;

    protected value: string = '';

    constructor(
        @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
    ) {

    }

    ngOnChanges() {
        this.update();
    }

    update() {
        switch (this.payTypeLink.returnUnit.type) {
            case 'currency': {
                this.value = this.numberFormatterService.formatCurrency(this.number);
                break;
            }
            case 'quantity': {
                this.value = this.numberFormatterService.formatDecimal(this.number, 2);
                break;
            }
            case 'seconds': {
                this.value = this.numberFormatterService.formatUnit(this.number, 'second');
                break;
            }
            case 'hours': {
                this.value = this.numberFormatterService.formatUnit(this.number, 'hour');
                break;
            }
            case 'days': {
                this.value = this.numberFormatterService.formatUnit(this.number, 'day');
                break;
            }
            case 'weeks': {
                this.value = this.numberFormatterService.formatUnit(this.number, 'week');
                break;
            }
            default: {
                console.error('Missing return unit');
                this.value = this.numberFormatterService.formatDecimal(this.number, 2);
            }
        }
    }
}
