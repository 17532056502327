<eaw-page-header [fabButton]="fabButton">
    <span title>{{ 'SYSTEM_ALERT_plural' | translate : 'admin' | async }}</span>
</eaw-page-header>

<mat-card>
    <eaw-data-table
            [columns]="columns()"
            [getData]="getData()"
            initialSortBy="created_at">
    </eaw-data-table>
</mat-card>
