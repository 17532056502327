<eaw-dialog-header>
    <span title>{{'PERIOD_plural' | translate:'scheduling' | async }}</span>
</eaw-dialog-header>

@if (loading()) {
    <eaw-info-loading size="lg"/>
} @else {
    <eaw-shift-period-list [customerId]="data.customerId" [scheduleId]="data.scheduleId" [shiftId]="data.shiftId" [displayOnly]="true" [periods]="periods"/>
}

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="">{{ 'CLOSE' | translate | async }}</button>
</mat-dialog-actions>
