// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';
import { EawChart } from '../../../../shared/angularjs/modules/misc/services/eaw-chart';

const template1 = `<div class="widget-content">
    <div id="limit-chart"></div>
</div>
`;

module('eaw.dashboard').component('schedulingHourLimitWidget', {
    template: template1,
    require: {
        eawWidget: '^eawWidget',
    },
    controller: [ 'SchedulingLimits', '$filter', function(SchedulingLimits, $filter) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.eawWidget.setLoading(true);

            ctrl.schedulingLimits = new SchedulingLimits(ctrl.eawWidget.widget.customer);
            ctrl.limitRes = ctrl.schedulingLimits.getStats.query(moment());

            ctrl.limitRes.$promise.then(ctrl.onLimit).catch(() => {
                ctrl.eawWidget.setEmpty(true);
            }).finally(() => ctrl.eawWidget.setLoading(false));
        };

        ctrl.onLimit = (resp) => {
            ctrl.stats = resp;
            ctrl.max = Math.max(
                ctrl.stats.limit.limit,
                ctrl.stats.planned,
                ctrl.stats.worked,
                ctrl.stats.prognosis,
            );

            new EawChart('limit-chart', {
                chart: {
                    type: 'column',
                },
                title: {
                    useHTML: true,
                    text: `<div style="font-size: 15px">${ctrl.stats.limit.from.format('LL')} - ${ctrl.stats.limit.to.format('LL')}</div>`,
                    margin: 8,
                },
                exporting: {
                    enabled: false,
                },
                legend: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                xAxis: {
                    categories: [
                        t('APPROVED'),
                        t('PLANNED'),
                        t('widgets:SCHED_LIMIT_PROGNOSIS'),
                    ],
                },
                yAxis: {
                    minRange: Math.ceil(ctrl.stats.limit.limit * 1.1),
                    title: {
                        text: null,
                    },
                    labels: {
                        enabled: false,
                        formatter() {
                            return ctrl.toHours(this.value);
                        },
                    },
                    plotLines: [
                        {
                            id: 'limit',
                            value: ctrl.stats.limit.limit,
                            color: '#EF5350',
                            dashStyle: 'shortdash',
                            width: 2,
                            label: {
                                useHTML: true,
                                text: `<div style="white-space: pre-line">${t('widgets:SCHED_LIMIT_LIMIT', { hours: ctrl.toHours(ctrl.stats.limit.limit) })}</div>`,
                            },
                        },
                    ],
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                        },
                    },
                },
                series: [
                    {
                        name: 'Over',
                        data: ctrl.getOver(),
                        color: '#EF5350',
                        dataLabels: {
                            formatter() {
                                return ctrl.toHours(this.y);
                            },
                        },
                    },
                    {
                        name: 'Under',
                        data: ctrl.getUnder(),
                        color: '#66BB6A',
                        dataLabels: {
                            formatter() {
                                return ctrl.toHours(this.y);
                            },
                        },
                    },
                ],
            });
        };

        ctrl.getOver = () => [
            ctrl.stats.worked - ctrl.stats.limit.limit > 0 ? ctrl.stats.worked - ctrl.stats.limit.limit : null,
            ctrl.stats.planned - ctrl.stats.limit.limit > 0 ? ctrl.stats.planned - ctrl.stats.limit.limit : null,
            ctrl.stats.prognosis - ctrl.stats.limit.limit > 0 ? ctrl.stats.prognosis - ctrl.stats.limit.limit : null,
        ];

        ctrl.getUnder = () => [
            Math.min(ctrl.stats.worked, ctrl.stats.limit.limit),
            Math.min(ctrl.stats.planned, ctrl.stats.limit.limit),
            Math.min(ctrl.stats.prognosis, ctrl.stats.limit.limit),
        ];

        ctrl.toHours = (seconds) => $filter('eawDuration')(seconds, 'narrow', 'h', 'narrow');
    } ],
});
