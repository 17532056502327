import { module } from 'angular';
import { createState } from '../shared/utils/create-state';
import { TodoStatusListComponent } from './pages/todo-status-list/todo-status-list.component';
import { currentResolver } from '../shared/resolvers/current.resolver';
import { TodoListComponent } from './pages/todo-list/todo-list.component';
import { Products } from '../shared/enums/products';
import { DataTableQueryParams } from '../data-table/data-table.component';
import { StateProvider } from '@uirouter/angularjs';
import { staticResolver } from '../shared/resolvers/static.resolver';

module('eaw').config([ '$stateProvider', ($stateProvider: StateProvider) => {
    createState($stateProvider, {
        name: `eaw/app/todo`,
        parent: 'eaw/app',
        url: '/todo',
        abstract: true,
        data: {
            products: [ Products.Todo ],
            breadcrumb: { key: 'TODO', ns: 'navigation' },
        },
    });
    createState($stateProvider, {
        name: `eaw/app/todo/my`,
        parent: `eaw/app/todo`,
        url: '/my',
        views: {
            'content@': {
                component: TodoListComponent,
            },
        },
        data: {
            queryParams: DataTableQueryParams,
            permissions: [ 'customers.{customer}.users.{user}.todo_items.*.get' ],
            breadcrumb: { key: 'TODO_MY', ns: 'navigation' },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('stackId', 'customer', 'stackId'),
            currentResolver('userId', 'user', 'id'),
        ],
    });
    createState($stateProvider, {
        name: 'eaw/app/todo/statuses',
        parent: 'eaw/app/todo',
        url: '/statuses',
        views: {
            'content@': {
                component: TodoStatusListComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            permissions: [ `customers.{customer}.todo_statuses.create` ],
            breadcrumb: { key: 'STATUS_plural', ns: 'todo' },
        },
    });
    createState($stateProvider, {
        name: 'eaw/app/todo/all',
        parent: 'eaw/app/todo',
        url: '/all',
        views: {
            'content@': {
                component: TodoListComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('stackId', 'customer', 'stackId'),
            staticResolver('userId', null),
        ],
        data: {
            queryParams: DataTableQueryParams,
            permissions: [ 'customers.{customer}.todo_items.*.get' ],
            breadcrumb: { key: 'ALL_TODOS', ns: 'todo' },
        },
    });
} ]);
