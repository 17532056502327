import { DateTime } from 'luxon';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export interface LocaleResponse extends ApiResponse {
    code: string;
    name: string;
    created_at: string;
    updated_at: string | undefined;
    customers_count?: number;
}

export class Locale {
    code: string;
    name: string;
    createdAt: DateTime;
    updatedAt: DateTime | null;
    customersCount: number | null;

    constructor(data: LocaleResponse) {
        this.code = data.code;
        this.name = data.name;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.customersCount = data.customers_count ? data.customers_count : null;
    }
}
