<mat-expansion-panel [disabled]="updating">
    <mat-expansion-panel-header>
        <mat-panel-title>{{'DEFAULT_LOCATION' | translate :'profile' | async }}</mat-panel-title>
        <mat-panel-description *ngIf="updating">
            <mat-progress-spinner mode="indeterminate" [diameter]="20" [strokeWidth]="3"></mat-progress-spinner>
        </mat-panel-description>

        <mat-panel-description *ngIf="!updating">
            <span *ngIf="customerName"> {{ customerName }} </span>
            <span *ngIf="!customerName">{{ 'NOT_SELECTED' | translate: 'profile' | async }}</span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
        <form [formGroup]="form">
            <eaw-basic-autocomplete [label]="'LOCATION' | translate"
                                    [disabled]="updating"
                                    formControlName="defaultLocation"
                                    [debounce]="0"
                                    [getList]="getAvailableLocations">
            </eaw-basic-autocomplete>
        </form>
    </ng-template>

    <mat-action-row>
        <button mat-button color="primary" [disabled]="!form.controls.defaultLocation.value || updating" (click)="updateDefaultCustomer()">
            {{'UPDATE' | translate | async}}
        </button>
    </mat-action-row>
</mat-expansion-panel>
