import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { DateTimeConverter } from '../utils/date-time-converter';
import { BUSINESS_DATES } from '../http/http-contexts';
import { BusinessDate } from '../utils/business-date';

export type SafeApiParams = Record<string, string | number | ReadonlyArray<string | number>>;

@Injectable()
export class WalkInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const walkedRequest = request.clone({
            url: encodeURI(request.url),
            body: WalkInterceptor.modifyRequestBody(request),
            params: WalkInterceptor.modifyRequestParams(request),
        });

        return next.handle(walkedRequest);
    }

    private static modifyRequestBody(request: HttpRequest<unknown>): Record<string, unknown> | null {
        const body = typeof request.body === 'object' ? request.body as Record<string, unknown> : null;
        if (!body) {
            return body;
        }

        const businessDates = request.context.get(BUSINESS_DATES);
        Object.entries(body).forEach(([ key, value ]) => {
            if (value instanceof DateTime) {
                body[key] = businessDates.includes(key) ? DateTimeConverter.convertDateTimeToBusinessDate(value) : DateTimeConverter.convertDateTimeToUtcString(value);
            }

            if (value instanceof BusinessDate) {
                body[key] = value.toString();
            }
        });

        return body;
    }

    private static modifyRequestParams(request: HttpRequest<unknown>): HttpParams {
        const paramsObject: SafeApiParams = {};

        request.params.keys().forEach((key) => {
            // Get value
            const value = request.params.get(key);

            // Skip values that are undefined
            if (value === 'undefined') {
                return;
            }

            // Params that are arrays are just passed on as-is
            if (key.endsWith('[]')) {
                paramsObject[key] = request.params.getAll(key) || [];
                return;
            }

            // If we receive filter that has no length, we skip it
            if (key === 'filter' && !value?.length) {
                return;
            }

            // If we get a stringified boolean, we convert it to it's number representation
            if (value === 'true' || value === 'false') {
                paramsObject[key] = value === 'true' ? '1' : '0';
                return;
            }

            // After all is checked, just set the value as-is
            paramsObject[key] = value ?? '';
        });

        return new HttpParams({
            fromObject: paramsObject,
        });
    }
}
