import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
    selector: 'mat-icon[eawRounded]',
    standalone: true,
})
export class MatIconRoundedDirective {
    /** Whether the icon should be rounded or not */
    @Input()
    get eawRounded(): boolean {
        return this._rounded;
    }

    set eawRounded(value: BooleanInput) {
        this._rounded = coerceBooleanProperty(value);

        if (this._rounded) {
            this.el.nativeElement.classList.add('material-icons-round');
        } else {
            this.el.nativeElement.classList.remove('material-icons-round');
        }
    }

    private _rounded: boolean = false;

    constructor(@Inject(ElementRef) private el: ElementRef) {
    }
}
