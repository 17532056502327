import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { OffTimeService } from '../../http/off-time.service';
import { OffTime } from '../../models/off-time';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ApproveOfftimeDialogComponent, ApproveOfftimeDialogData, ApproveOfftimeDialogReturn } from './approve-offtime-dialog/approve-offtime-dialog.component';
import { DateTime } from 'luxon';

interface BaseApproveOptions {
    from: DateTime,
    to: DateTime,
}

interface CreateApproveOptions extends BaseApproveOptions {
    mode: 'create',
    offTimeId?: never,
}

interface UpdateApproveOptions extends BaseApproveOptions {
    mode: 'update',
    offTimeId: number,
}

type ApproveOptions = CreateApproveOptions | UpdateApproveOptions;

@Injectable({
    providedIn: 'root',
})
export class HandleOffTimeService {

    constructor(
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(ConfirmDialogService) protected confirmDialogService: ConfirmDialogService,
        @Inject(TranslateService) protected translate: TranslateService,
        @Inject(SnackBarService) protected snackBarService: SnackBarService,
        @Inject(OffTimeService) protected offTimeService: OffTimeService,
    ) {
    }

    approve(customerId: number, employeeId: number, options: ApproveOptions): MatDialogRef<ApproveOfftimeDialogComponent, ApproveOfftimeDialogReturn> {
        return this.matDialog.open<ApproveOfftimeDialogComponent, ApproveOfftimeDialogData, ApproveOfftimeDialogReturn>(ApproveOfftimeDialogComponent, {
            data: {
                customerId,
                employeeId,
                ...options,
            },
        });
    }

    decline(customerId: number, employeeId: number, offTimeId: number): Observable<OffTime | null> {
        return this.confirmDialogService.open({
            title: this.translate.t('DECLINE_REQUEST'),
            confirmText: this.translate.t('DECLINE'),
            confirmColor: 'red-500',
            comment: {
                include: true,
                required: true,
            },
        }).afterClosed().pipe(
            switchMap((result) => {
                if (!result?.ok) {
                    return of(null);
                }
                if (!result?.comment) {
                    return of(null);
                }

                return this.offTimeService.update(customerId, employeeId, offTimeId, {
                    approved: false,
                    comment: result.comment,
                    with: [ 'comments', 'approval' ],
                }).pipe(
                    tap(() => {
                        void this.snackBarService.t('REQUEST_DECLINED', 'vacation');
                    }),
                );
            }),
        );
    }
}
