import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadHrFileDialogComponent } from './upload-hr-file-dialog.component';
import { DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { HrFileType } from '../../models/hr-file-type';

export type UploadHrFileDialogReturn = 'Custom' | 'Default' | 'DefaultMany' | 'Reload';

export interface UploadHrFileDialogData extends DialogData {
    customerId: number;
    employees: number[];
    type?: HrFileType;
}

@Injectable({
    providedIn: 'root',
})
export class UploadHrFileDialogService {

    constructor(@Inject(MatDialog) private dialog: MatDialog) {
    }

    open(data: UploadHrFileDialogData) {
        return this.dialog.open<UploadHrFileDialogComponent, UploadHrFileDialogData, UploadHrFileDialogReturn>(UploadHrFileDialogComponent, {
            data: {
                size: DialogSize.Medium,
                ...data,
            },
        });
    }
}
