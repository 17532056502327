import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { HourDistributionService } from '../../http/hour-distribution.service';
import { forkJoin, map, Observable, of, shareReplay } from 'rxjs';
import { HourDistribution } from '../../models/hour-distribution';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DateTime } from 'luxon';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateHourDistributionDialogComponent, CreateHourDistributionDialogData } from './create-hour-distribution-dialog/create-hour-distribution-dialog.component';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { DatePickerDialogComponent, DatePickerDialogData, DatePickerDialogReturn } from '../../../shared/dialogs/date-picker-dialog/date-picker-dialog.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { HourDistributionDaysComponent, HourDistributionDaysDialogData, HourDistributionDaysDialogResult } from '../../dialogs/hour-distribution-days/hour-distribution-days.component';
import { ContractService } from '../../../shared/http/contract.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { SettingService } from '../../../shared/http/setting.service';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-employee-hours-distribution-list',
    templateUrl: './employee-hours-distribution-list.component.html',
    styleUrl: './employee-hours-distribution-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmployeeHoursDistributionListComponent implements OnInit, EawDataTable {
    @ViewChild(DataTableComponent) dataTable?: DataTableComponent<HourDistribution>;

    @Input({ required: true }) employeeId!: number;
    @Input({ required: true }) customerId!: number;

    useCombinedContract: Observable<boolean> = of(false);
    createButton: HeaderFabButton = {
        click: this.create.bind(this),
        hasPermission: () => {
            return forkJoin([
                this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.contract_hours_distributions.create`),
                this.useCombinedContract,
            ]).pipe(
                map(([ hasPermission, useCombinedContract ]) => hasPermission && !useCombinedContract),
            );
        },
    };

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<HourDistribution>[] = [
        new DataTableDateTimeColumn({
            value: (cell) => cell.item.from.dateTime,
            format: DateTime.DATE_MED,
            header: new DataTableHeader({ i18n: 'FROM' }),
        }),
        new DataTableDateTimeColumn({
            value: (cell) => cell.item.to?.dateTime,
            format: DateTime.DATE_MED,
            header: new DataTableHeader({ i18n: 'TO' }),
        }),
        new DataTableNumberColumn({
            value: 'weeks',
            header: new DataTableHeader({ i18n: 'WEEK_plural' }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'edit',
                    hide: (item) => of(!!item.to),
                    click: this.update.bind(this),
                    show: (item) => {
                        return forkJoin([
                            this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${item.employeeId}.contract_hours_distributions.${item.id}.update`),
                            this.useCombinedContract,
                        ]).pipe(
                            map(([ hasPermission, useCombinedContract ]) => hasPermission && !useCombinedContract),
                        );
                    },
                    tooltip: { key: 'EDIT' },
                },
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.delete.bind(this),
                    show: (item) => {
                        return forkJoin([
                            this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${item.employeeId}.contract_hours_distributions.${item.id}.delete`),
                            this.useCombinedContract,
                        ]).pipe(
                            map(([ hasPermission, useCombinedContract ]) => hasPermission && !useCombinedContract),
                        );
                    },
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    constructor(
        @Inject(HourDistributionService) private hourDistributionService: HourDistributionService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(ContractService) private contractService: ContractService,
        @Inject(SettingService) private settingService: SettingService,
    ) {
    }

    ngOnInit() {
        this.useCombinedContract = this.settingService.getValue<number | null>([ 'customers', this.customerId ], 'france.use_combined_contract', 0).pipe(
            map((value) => !!value),
            shareReplay(1),
        );
    }

    create() {
        this.matDialog.open<CreateHourDistributionDialogComponent, CreateHourDistributionDialogData, HourDistribution>(CreateHourDistributionDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: this.employeeId,
            },
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.dataTable?.refresh();
            }
        });
    }

    update(cell: DataTableButtonCell<HourDistribution>) {
        this.matDialog.open<DatePickerDialogComponent, DatePickerDialogData, DatePickerDialogReturn>(DatePickerDialogComponent, {
            data: {
                minDate: cell.item.from.dateTime,
                target: 'date',
                title: this.translate.t('UPDATE_HOURS_DISTRIBUTION', 'company'),
                subtitle: this.translate.t('UPDATE_HOURS_DISTRIBUTION_DESCRIPTION', 'company'),
                nullable: false,
            },
        }).afterClosed().subscribe((result) => {
            if (!result?.ok || result.value == null) {
                cell.disabled.set(false);
                return;
            }

            this.hourDistributionService.update(this.customerId, this.employeeId, cell.item.id, result.value).subscribe(() => {
                this.dataTable?.refresh();
            });
        });
    }

    delete(cell: DataTableButtonCell<HourDistribution>) {
        this.confirmDialogService.delete({
            title: this.translate.t('DELETE_HOURS_DISTRIBUTION', 'company'),
            text: this.translate.t('DELETE_HOURS_DISTRIBUTION_TEXT', 'company'),
            confirmText: this.translate.t('DELETE'),
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.hourDistributionService.delete(this.customerId, this.employeeId, cell.item.id).subscribe(() => {
                this.dataTable?.refresh();
            });
        });
    }

    openHourDistributionDialog(item: HourDistribution) {
        this.useCombinedContract.subscribe((useCombinedContract) => {
            this.matDialog.open<HourDistributionDaysComponent, HourDistributionDaysDialogData, HourDistributionDaysDialogResult>(HourDistributionDaysComponent, {
                data: {
                    customerId: this.customerId,
                    employeeId: item.employeeId,
                    distributionId: item.id,
                    noDialogUpdate: useCombinedContract,
                    readonly: useCombinedContract,
                    contractMonthHours: this.contractService.getAllForEmployee(this.customerId, item.employeeId, { active: item.from.dateTime }).pipe(
                        map((response) => {
                            const contract = response.data[0];
                            return contract ? contract.monthHours : undefined;
                        }),
                    ),
                },
            });
        });
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.hourDistributionService.getAll(this.customerId, this.employeeId, pagination);
    }
}
