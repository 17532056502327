import { HrFile, HrFileResponse } from './hr-file';
import { HrFileType, HrFileTypeResponse } from './hr-file-type';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BusinessDateString } from '../../shared/types/business-date';
import { BusinessDate } from '../../shared/utils/business-date';

export interface HrOverviewResponseEmployee extends ApiResponse {
    id: number;
    customer_id: number;
    from: BusinessDateString | null;
    to: BusinessDateString | null;
    hr_files: HrFileResponse[];
    missing_type_ids: number[];
    name: string;
    number: number;
}

export interface FileRequestResponse extends ApiResponse {
    [employee_id: number]: {
        [file_type_id: number]: {
            employee_id: number;
            file_type_id: string;
            run_id: string;
        };
    };
}

export interface HrOverviewResponse extends ApiResponse {
    employees: HrOverviewResponseEmployee[];
    types: Record<number, HrFileTypeResponse>;
    requested_files?: FileRequestResponse;
}

export interface HrOverviewEmployee {
    id: number;
    customerId: number;
    from: BusinessDate | null;
    to: BusinessDate | null;
    hrFiles: HrFile[];
    missingTypeIds: number[];
    name: string;
    number: number;
}

export class HrOverview {
    employees: HrOverviewEmployee[];
    types: HrFileType[];
    requestedFiles?: FileRequestResponse;

    constructor(data: HrOverviewResponse) {
        this.types = Object.values(data.types).map((type) => new HrFileType(type));

        this.employees = data.employees.map((employee) => {
            return {
                id: employee.id,
                customerId: employee.customer_id,
                from: employee.from ? new BusinessDate(employee.from) : null,
                to: employee.to ? new BusinessDate(employee.to) : null,
                hrFiles: employee.hr_files.map((file) => new HrFile(file)),
                missingTypeIds: employee.missing_type_ids,
                name: employee.name,
                number: employee.number,
            };
        });
        if (data.requested_files) {
            this.requestedFiles = data.requested_files;
        }
    }
}
