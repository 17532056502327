import { t } from 'i18next';
import moment, { Moment } from 'moment-timezone';
import { DateTimeConverter } from '../utils/date-time-converter';
import { EawResourceFactory } from './modules/resource/resource.service';

/**
 * @deprecated
 */
export class PropertyOld {
    id: number;
    key: string;
    created_at: Moment;
    updated_at: Moment;
    from: Moment;
    to: Moment;
    value: unknown;

    constructor(prop: any) {
        prop ||= {};

        this.id = prop.id;
        this.key = prop.key;
        this.created_at = prop.created_at;
        this.updated_at = prop.updated_at;
        this.from = prop.from;
        this.to = prop.to;
        this.value = PropertyOld.formatValue(prop.value);
    }

    userReadable() {
        if (moment.isMoment(this.value) && this.value.isValid()) {
            return this.value.format('LL');
        }

        if (typeof this.value === 'boolean') {
            return this.value ? t('YES') : t('NO');
        }

        return this.value;
    }

    toString() {
        if (typeof this.value === 'number') {
            return this.value.toString();
        }

        if (typeof this.value === 'boolean') {
            return `${+this.value}`;
        }

        return this.value;
    }

    static formatDate(value: string) {
        return DateTimeConverter.convertStringToMoment(value);
    }

    static formatBusinessDate(value: string) {
        return DateTimeConverter.convertStringToMoment(value, true);
    }

    static formatNumber(value: string) {
        return parseFloat(value);
    }

    static formatValue(value: string, type?: string): unknown {
        if (type === 'boolean') {
            if (value === '1') {
                return true;
            }
            if (value === '0') {
                return false;
            }

            return undefined;
        }

        // Regex to test for date "YYYY-MM-DD HH:mm:ss"
        if (/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/.test(value)) {
            return PropertyOld.formatDate(value);
        } else if (/\d{4}-\d{2}-\d{2}/.test(value)) { // Try testing for business date
            return PropertyOld.formatBusinessDate(value);
        } else if (!Number.isNaN(parseFloat(value)) && !value.startsWith?.('0')) { // Try parsing number
            return PropertyOld.formatNumber(value);
        } else {
            return value;
        }
    }

    static getEmployeeProperty(resource: EawResourceFactory, customer: number, employee: number, key: string) {
        return resource.create(`/customers/${customer}/employees/${employee}/properties/${key}`).get().$promise;
    }
}
