import { Subject } from 'rxjs';
import { IAngularEvent } from 'angular';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { WebsocketService } from '../../../../shared/services/websocket.service';
import { NotificationSocketResponse } from '../../../../notifications/models/notification';
import { NotificationType } from '../../../../notifications/classes/notification-type';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

export class BackOfficeSyncService {
    static get $inject() {
        return [ 'BackOffice', '$rootScope', 'ToastService', 'WebsocketDowngrade' ];
    }

    static event = 'backoffice-sync:complete';
    subject: Subject<void>;

    constructor(
        protected BackOffice: any,
        protected $rootScope: angular.IRootScopeService,
        protected ToastService: SnackBarService,
        protected WebsocketDowngrade: WebsocketService,
    ) {
        this.subject = new Subject<void>();
    }

    start(customerId: number, scheduleId: number) {
        this.WebsocketDowngrade.notification(`users.${CurrentOld.getUser()['id']}`, (data: NotificationSocketResponse) => {
            if (data.type !== NotificationType.backoffice_budget_imported.class) {
                return;
            }
            this.broadcast();
        });

        this.BackOffice.sync.query(customerId, scheduleId).then(() => {
            this.ToastService.t('scheduling:STARTED_BO_SYNC');
        });
    }

    subscribe(scope: angular.IScope, callback: (event: IAngularEvent, notification: Notification) => void) {
        return scope.$on(BackOfficeSyncService.event, callback);
    }

    broadcast() {
        this.$rootScope.$broadcast(BackOfficeSyncService.event, {});
        this.subject.next();
    }
}
