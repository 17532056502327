// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../current.factory';

module('eaw.components').component('customerUserAutocomplete', {
    template: `<md-autocomplete
        md-selected-item-change="$cuCtrl.change()"
        md-selected-item="$cuCtrl.user"
        md-search-text="filter"
        md-delay="300"
        md-floating-label="{{$cuCtrl.label | i18next}}"
        md-clear-button="true"
        md-min-length="0"
        ng-required="$cuCtrl.required"
        md-match-case-insensitive="true"
        md-items="user in $cuCtrl.getUsers(filter)"
        md-item-text="user.name">
    <md-item-template>
        <span md-highlight-text="filter" md-highlight-flags="gi">{{user.name}}</span>
    </md-item-template>
    <md-not-found>
        <span ng-i18next="NO_MATCH_FOUND"></span>
    </md-not-found>
</md-autocomplete>
`,
    controllerAs: '$cuCtrl',
    bindings: {
        label: '@', // i18n string
        customer: '<?', // Gets current customer if not provided.
    },
    require: {
        ngModel: 'ngModel',
    },
    controller: [ 'CustomerService', '$attrs', function(CustomerService, $attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            $attrs.$observe('required', (value) => {
                ctrl.required = value;
            });
        };

        ctrl.getUsers = (filter) => CustomerService.getUsers({
            customer: ctrl.customer && ctrl.customer.id ? ctrl.customer : CurrentOld.customer,
            filter,
            direction: 'asc',
            fields: [ 'name' ],
        }).$promise.then((resp) => resp.data);

        ctrl.change = () => {
            ctrl.ngModel.setViewValue(ctrl.user);
        };
    } ],
});
