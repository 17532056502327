import { Component, Inject } from '@angular/core';
import { FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UIRouter } from '@uirouter/core';
import { HttpClient } from '@angular/common/http';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, AsyncPipe } from '@angular/common';
import { LogoComponent } from '../../../shared/components/logo/logo.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'eaw-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: [ './forgot-password.component.angular.scss', '../../../../styles/login.scss' ],
    standalone: true,
    imports: [
        MatCardModule,
        LogoComponent,
        NgIf,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ForgotPasswordComponent {
    emailCtrl = new FormControl('', Validators.required);
    success = false;
    submitting = false;
    email = '';

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(UIRouter) protected uiRouter: UIRouter,
    ) {
    }

    resetPassword() {
        const email = this.emailCtrl.value;
        if (!email?.length) {
            return;
        }

        this.submitting = true;

        this.http.get(`/users/reset_password`, { params: { email: email.trim() } }).subscribe({
            complete: () => {
                this.submitting = false;
                this.success = true;
                this.email = email;
                this.emailCtrl.setValue('');
            },
            error: () => (this.submitting = false),
        });
    }

    goToLogin() {
        this.uiRouter.stateService.go('eaw/outside/login');
    }
}
