@switch (status) {
    @case ('unhandled') {
        <div class="mat-headline-5">{{ task.signatureTopic }}</div>
        <div id="text" class="tw-whitespace-pre-line">
            {{ task.signatureDescription }}
        </div>

        <eaw-pdf-renderer class="mat-elevation-z2" *ngIf="url" [url]="url" [title]="task.document" style="height: 50vh"></eaw-pdf-renderer>

        <button class="tw-flex-1" mat-raised-button eawMaterialColor="green-500" colorProperty="backgroundColor" [disabled]="handling" (click)="signDocument()">{{ 'SIGN' | translate | async }}</button>
        <button class="tw-flex-1" mat-raised-button eawMaterialColor="red-500" colorProperty="backgroundColor" [disabled]="handling" (click)="rejectDocument()">{{ 'REJECT' | translate | async }}</button>
    }
    @case ('signed') {
        <div class="post-info">
            <mat-icon eawMaterialColor="green-500">check</mat-icon>

            <span class="title">{{ task.messages.signedTitle }}</span>
            <span class="subtitle">{{ task.messages.signedText }}</span>
        </div>
    }
    @case ('rejected') {
        <div class="post-info">
            <mat-icon eawMaterialColor="red-500">close</mat-icon>

            <span class="title">{{ task.messages.rejectedTitle }}</span>
            <span class="subtitle">{{ task.messages.rejectedText }}</span>
        </div>
    }
    @case ('postponed') {
        <div class="post-info">
            <mat-icon>skip_next</mat-icon>

            <span class="title">{{ task.messages.postponedTitle }}</span>
            <span class="subtitle">{{ task.messages.postponedText }}</span>
        </div>
    }
}
