<eaw-dialog-header>
    <span title>{{'CHANGELOG' | translate:'scheduling' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="!currentShift" class="tw-flex tw-flex-col tw-justify-center tw-items-center">
    <mat-progress-spinner mode="indeterminate" diameter="40" strokeWidth="4"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content *ngIf="currentShift">
    <eaw-subheader>{{'CURRENT_SHIFT' | translate:'scheduling' | async}}</eaw-subheader>

    <mat-list>
        <mat-list-item>
            <eaw-profile-picture matListItemAvatar  [user]="{id: currentShift.employee?.userId, name: currentShift.employee?.name}"></eaw-profile-picture>
            <div matListItemLine>{{currentShift.employee?.name}}</div>
            <div matListItemLine> {{'EMPLOYEE' | translate | async}} </div>
        </mat-list-item>

        <mat-list-item>
            <mat-icon matListItemIcon>start</mat-icon>
            <div matListItemLine>{{currentShift.from | DateTime:'DATETIME_MED'}}</div>
            <div matListItemLine> {{'FROM' | translate | async}} </div>
        </mat-list-item>

        <mat-list-item>
            <mat-icon matListItemIcon style="transform: rotate(180deg)">start</mat-icon>
            <div matListItemLine>{{currentShift.to | DateTime:'DATETIME_MED'}}</div>
            <div matListItemLine> {{'TO' | translate | async}} </div>
        </mat-list-item>

        <mat-list-item>
            <mat-icon matListItemIcon>timer</mat-icon>
            <div matListItemLine>{{currentShift.length | duration:['hours', 'minutes']}}</div>
            <div matListItemLine> {{'DURATION' | translate | async}} </div>
        </mat-list-item>
    </mat-list>

    <eaw-subheader>{{'CHANGE_plural' | translate | async}}</eaw-subheader>

    <mat-list>
        <mat-list-item *ngFor="let change of changes">
            <div matListItemLine>{{change.time | DateTime:'DATETIME_MED'}}</div>
            <div matListItemLine> {{change.description | async}} </div>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="false">{{'CLOSE' | translate | async}}</button>
</mat-dialog-actions>
