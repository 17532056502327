// @ts-nocheck
import moment from 'moment-timezone';
import { t } from 'i18next';
import { module } from 'angular';
import { ElementInitialization } from '../../../../../shared/angularjs/modules/misc/services/element-initialization.service';
import ComponentCreator, { CreatedComponent } from '../../../../../shared/angularjs/components/component-creator/component-creator.service';
import { IntervalLike } from '../../../../../shared/angularjs/interval-old';
import type { PtDialogComponent, PtSegmentDialog, PtSegmentItem } from '../../employee-paid-time-segment.class';

export class HandleSomethingCtrl {
    static get $inject() {
        return [ '$mdDialog', '$scope', 'componentCreatorService' ];
    }

    readonly = false;
    component!: PtDialogComponent;
    interval!: IntervalLike;
    originalInterval!: IntervalLike;
    dialog!: PtSegmentDialog;
    elementInitialization!: ElementInitialization;
    segment!: PtSegmentItem;
    handling?: boolean;
    renderedComponent?: CreatedComponent;
    businessDate: any;

    constructor(protected $mdDialog: any, protected $scope: angular.IScope, protected componentCreatorService: ComponentCreator) {}

    $onInit() {
        this.component = this.dialog.components[0];
        this.interval = {
            from: this.segment.from?.clone(),
            to: this.segment.to?.clone(),
        };
        this.originalInterval = {
            from: this.segment.from?.clone(),
            to: this.segment.to?.clone(),
        };

        this.elementInitialization = new ElementInitialization('#component-container');
        this.elementInitialization.observe().then(this.componentChange.bind(this));
    }

    handle() {
        this.handling = true;
        this.$scope.$broadcast('HandleSomethingCtrl:handle');
    }

    close(resolve?: any) {
        return this.$mdDialog.hide(resolve).catch(() => delete this.handling);
    }

    componentChange() {
        this.renderedComponent?.scope.$destroy();

        this.readonly = this.component.name === 'ptRemovePaidTime' || this.component.name === 'ptRemoveAbsence';

        this.renderedComponent = this.componentCreatorService.create({
            name: this.component.name,
            scope: this.$scope,
            bindings: {
                segment: this.segment,
                parent: this,
            },
        });

        const container = document.getElementById('component-container')!;
        Array.from(container.children).forEach((el) => el.remove());
        container.appendChild(this.renderedComponent.element);
    }

    calcDuration() {
        if (moment.isMoment(this.interval?.from) && moment.isMoment(this.interval?.to)) {
            return this.interval.to.diff(this.interval.from, 's');
        }

        return t('NA');
    }
}
