<eaw-page-header>
    <span title>{{ 'REPORT_plural' | translate : 'admin' | async }}</span>
    <form smallFilter role="search" class="tw-flex">
        <mat-form-field class="standalone">
            <mat-label>{{ 'SEARCH' | translate | async }}</mat-label>
            <input matInput type="text" [(ngModel)]="search" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </form>
</eaw-page-header>

<mat-card>
    <eaw-data-table
        #table
        [columns]="columns"
        [getData]="getData()"
        initialSortBy="created_at">
    </eaw-data-table>
</mat-card>
