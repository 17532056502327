import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { ShiftEvent } from '../../types/shift-event';
import { debounce } from 'lodash-es';
import { ScheduleDay } from '../../angularjs/types/schedule-day';
import { BalanceService, CombinedBalanceOverviewItem } from '../../../balances/http/balance.service';
import { DateTime } from 'luxon';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BalanceType } from '../../../balances/models/balance-type';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../shared/services/current.service';
import { environment } from '../../../../environments/environment';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { loadNamespaces } from 'i18next';
import { Namespace } from '../../../shared/enums/namespace';

@Component({
    selector: 'eaw-balances-sidebar',
    templateUrl: './balances-sidebar.component.html',
    styleUrl: './balances-sidebar.component.scss',
    providers: [
        {
            provide: 'scheduleTable',
            useFactory: ($injector: any) => $injector.get('scheduleTable'),
            deps: [ '$injector' ],
        },
        {
            provide: '$rootScope',
            useFactory: ($injector: any) => $injector.get('$rootScope'),
            deps: [ '$injector' ],
        },
    ],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        NgIf,
        MatProgressSpinnerModule,
        MatListModule,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class BalancesSidebarComponent implements OnInit, OnDestroy {
    private readonly balanceService = inject(BalanceService);
    private readonly currentService = inject(CurrentService);

    private dayChangeListener!: () => void;

    day?: ScheduleDay;
    balanceTypeControl = new FormControl<string>('', { nonNullable: true });
    balanceTypes: BalanceType[] | null = null;
    combinedBalanceOverview: CombinedBalanceOverviewItem[] = [];

    constructor(
        @Inject('scheduleTable') protected scheduleTable: any,
        @Inject('$rootScope') protected rootScope: angular.IRootScopeService,
    ) {
    }

    async ngOnInit() {
        await loadNamespaces(Namespace.Balances);
        this.updateDays();

        this.dayChangeListener = environment.isTesting ? () => {} : this.rootScope.$on('sidebarchildren:dayChanged', debounce((_, day) => {
            this.day = day;
            this.updateDays(undefined, day);
        }, 500));
    }

    ngOnDestroy(): void {
        this.dayChangeListener?.();
    }

    getBalances(date: DateTime) {
        this.balanceTypeControl.disable();

        this.balanceService.getCombinedOverviewWithTypes(this.scheduleTable.getSchedule()['customer_id'], date).subscribe((res) => {
            this.balanceTypes ||= res.types;

            this.combinedBalanceOverview = sort(res.combined, this.currentService.languageTag, [ (x) => x.employeeName ]);
            this.balanceTypeControl.enable();
        });
    }

    getChangeForType(overview: CombinedBalanceOverviewItem, balanceCode: string) {
        const type = this.balanceTypes?.find((type) => type.balanceCode === balanceCode);
        if (!type) {
            return;
        }

        const delta = overview.balanceChanges.find((change) => change.balanceType?.balanceCode === type.balanceCode)?.delta || 0;
        return type.getFormattedValue(delta, this.currentService.languageTag, {
            signDisplay: 'exceptZero',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    }

    getDay(): ScheduleDay {
        return this.day || this.scheduleTable.getCurrentDay();
    }

    updateDays(_?: ShiftEvent, day = this.getDay()) {
        const datetime = DateTime.fromJSDate(day.moment.toDate());
        this.getBalances(datetime);
    }
}
