// @ts-nocheck
import { module } from 'angular';

import { CalendarEventParticipantStatuses } from '../calendar-event-participant.class';

module('eaw.calendar2').controller('eventInfoPanelCtrl', [ 'mdPanelRef', 'HandleEventDialog', function(mdPanelRef, HandleEventDialog) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.participantStatuses = CalendarEventParticipantStatuses;
        ctrl.organizerUser = {
            id: parseInt(ctrl.event.organizer?.key.replace('user:', '')),
            name: ctrl.event.organizer?.name,
        };
    };

    ctrl.respond = (status) => {
        if (!ctrl.event.isMine) {
            return;
        }

        ctrl.event.participant.updateStatus(ctrl.event, status);
        ctrl.close();
    };

    ctrl.delete = async () => {
        await ctrl.event.delete();
        ctrl.close();
    };

    ctrl.edit = async () => {
        ctrl.close();
        await HandleEventDialog.open({
            event: ctrl.event,
        });
    };

    ctrl.close = () => {
        ctrl.args?.onAction?.();
        mdPanelRef.close();
    };
} ]);
