import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { HrFileType, HrFileTypeResponse } from './hr-file-type';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface HrDefaultFileResponse extends ApiResponse {
    customer_id: number;
    description: string;
    id: number;
    mime: string;
    name: string;
    type_id: number;
    form_fields_count?: number;
    file_type?: HrFileTypeResponse
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
}

export class HrDefaultFile {
    customerId: number;
    description: string;
    id: number;
    mime: string;
    name: string;
    typeId: number;
    formFieldsCount?: number;
    fileType?: HrFileType;
    createdAt?: DateTime | null;
    updatedAt?: DateTime | null;
    deletedAt?: DateTime | null;

    constructor(data: HrDefaultFileResponse) {
        this.customerId = data.customer_id;
        this.description = data.description;
        this.id = data.id;
        this.mime = data.mime;
        this.name = data.name;
        this.typeId = data.type_id;
        this.formFieldsCount = data.form_fields_count;
        this.fileType = data.file_type ? new HrFileType(data.file_type) : undefined;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
