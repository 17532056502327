import { module } from 'angular';
import { PaginationOptions } from '../../../interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../../interfaces/paginated-response';

export class EndlessList extends Object {
    total;
    added;
    loadedPages: Record<string, any>;
    load;
    pagination: PaginationOptions;
    loading?: boolean;
    loaded?: boolean;

    constructor(loadPage: any, pagination: PaginationOptions = {}) {
        super();
        this.total = 1;
        this.added = 0;
        this.loadedPages = {};
        this.load = loadPage;
        this.pagination = {
            per_page: 10,
            direction: 'asc',
            ...pagination,
        };
    }

    resolvePage(index: number) {
        return Math.floor(index / this.pagination.per_page!) + 1;
    }

    getItemAtIndex(i: number) {
        const p = this.resolvePage(i);
        const index = i % this.pagination.per_page!;
        if (this.loadedPages[p]) {
            return this.loadedPages?.[p]?.[index] ?? null;
        }
        this.loadPage(p);
    }

    add(item: any) {
        this.loadedPages?.[1].unshift(item);
        this.added += 1;
    }

    remove(item: any, key: string|number) {
        Object.keys(this.loadedPages).forEach((page) => {
            this.loadedPages[page] = this.loadedPages[page].filter((x: any) => x[key] !== item[key]);
        });
        this.added -= 1;
    }

    loadPage(p: number) {
        if (Array.isArray(this.loadedPages[p])) {
            return;
        }
        this.loadedPages[p] = [];
        this.loading = true;
        return this.load({
            ...this.pagination,
            page: p,
        }).then((resp: ArrayPaginatedResponse<any>) => {
            this.loadedPages[resp.current_page] = resp.data;
            this.total = resp.total;
            return resp;
        }).finally(() => {
            this.loaded = true;
            delete this.loading;
        });
    }

    reload(index?: number) {
        let page = index == undefined ? 1 : this.resolvePage(index);
        if (page) {
            delete this.loadedPages[String(page)];
        } else {
            page = 1;
            this.loadedPages = {};
        }
        return this.loadPage(page);
    }

    isLoading() {
        return this.loading || !this.loaded;
    }

    getLength() {
        return this.total + this.added;
    }
}

/**
 * @ngdoc
 *
 * @name EndlessList
 * @description For use with md-on-demand
 * @see https://material.angularjs.org/latest/api/directive/mdVirtualRepeat
 */
module('ew-endless-list').factory('EndlessList', function EndlessListClass() {
    return EndlessList;
});
