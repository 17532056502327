import { ApiResponse } from '../interfaces/api-response';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { BaseApiModel } from './base-api-model';

export interface ContractTypeResponse extends ApiResponse {
    id: number;
    name: string;
    from: string | null;
    to: string | null;
    setting_group_id: number;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
}

export class ContractType extends BaseApiModel<ContractTypeResponse, ContractType> {
    id: number;
    name: string;
    from: DateTime | null;
    to: DateTime | null;
    settingGroupId: number;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt?: DateTime | null;

    constructor(data: ContractTypeResponse) {
        super(data, undefined);

        this.id = data.id;
        this.name = data.name;
        this.from = data.from ? stringToDateTime(data.from) : null;
        this.to = data.to ? stringToDateTime(data.to) : null;
        this.settingGroupId = data.setting_group_id;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
