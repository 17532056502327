import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Customer } from '../../../../../shared/models/customer';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Employee, EmployeeGender, RequiredEmployeeField } from '../../../../../shared/models/employee';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { AutocompleteComponent } from '../../../../../shared/components/autocomplete/autocomplete.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DatePickerOptionsDirective } from '../../../../../shared/directives/date-picker-options.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CurrentService } from '../../../../../shared/services/current.service';
import { CountryAutocompleteService } from '../../../../../shared/autocompletes/country-autocomplete.service';
import { CountryRegionAutocompleteService } from '../../../../../shared/autocompletes/country-region-autocomplete.service';
import { Country } from '../../../../../shared/models/country';
import { CountryRegion } from '../../../../../shared/models/country-region';
import { DateTime } from 'luxon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { catchError, EMPTY, startWith } from 'rxjs';
import { User } from '../../../../../shared/models/user';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { EmployeeService } from '../../../../../shared/http/employee.service';
import { NewEmployeeNextStepComponent } from '../../components/new-employee-next-step/new-employee-next-step.component';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';
import { TranslateService } from '../../../../../shared/services/translate.service';
import { InfoBoxComponent } from '../../../../../shared/components/info-card/info-box.component';
import { NumberFormatterService } from '../../../../../shared/services/number-formatter.service';
import { Namespace } from '../../../../../shared/enums/namespace';
import { TranslateSyncPipe } from '../../../../../shared/pipes/translate-sync.pipe';
import { DialPhone, DialPhoneInputComponent } from '../../../../../shared/components/dial-phone-input/dial-phone-input.component';
import { FieldErrorComponent } from '../../../../../shared/components/field-error/field-error.component';

type EmployeeForm = {
    address1: FormControl<string | null>;
    address2: FormControl<string | null>;
    postalCode: FormControl<string | null>;
    city: FormControl<string | null>;
    countryKey: FormControl<Country | string | null>;
    region: FormControl<CountryRegion | number | null>;
    nationality: FormControl<Country | string | null>;
    birthDate: FormControl<DateTime | null>;
    gender: FormControl<EmployeeGender | ''>;
    phone: FormControl<DialPhone | null>;
}

@Component({
    selector: 'eaw-new-employee-extra-employee-info',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        TranslatePipe,
        AutocompleteComponent,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatAutocompleteModule,
        ActionButtonComponent,
        NewEmployeeNextStepComponent,
        InfoBoxComponent,
        TranslateSyncPipe,
        DialPhoneInputComponent,
        FieldErrorComponent,
    ],
    templateUrl: './new-employee-extra-employee-info.component.html',
    styleUrl: './new-employee-extra-employee-info.component.scss',
})
export class NewEmployeeExtraEmployeeInfoComponent extends NewEmployeeStepComponent implements OnInit {
    protected countryAutocompleteService = inject(CountryAutocompleteService);
    protected countryRegionAutocompleteService = inject(CountryRegionAutocompleteService);
    private currentService = inject(CurrentService);
    private translateService = inject(TranslateService);
    private employeeService = inject(EmployeeService);
    private numberFormatterService = inject(NumberFormatterService);

    @Input({ required: true }) customer!: Customer;
    @Input({ required: true }) user?: User;
    @Input({ required: true }) employee!: Employee;
    @Input({ required: true }) requiredFields!: Partial<Record<RequiredEmployeeField, boolean>>;

    minBirthdate = DateTime.now().minus({ years: 130 });
    maxBirthdate = DateTime.now();
    infoSaved = false;
    regionHint = this.translateService.t('REGION_INPUT_HINT');
    loading = true;
    processing = false;
    emptyForm = true;
    ageHint?: string;
    form: FormGroup<EmployeeForm>;

    constructor() {
        super();

        this.form = new FormGroup({
            address1: new FormControl<string | null>(null),
            address2: new FormControl<string | null>(null),
            postalCode: new FormControl<string | null>(null),
            city: new FormControl<string | null>(null),
            countryKey: new FormControl<Country | string | null>(null),
            region: new FormControl<CountryRegion | number | null>(null),
            nationality: new FormControl<Country | string | null>(null),
            birthDate: new FormControl<DateTime | null>(null),
            gender: new FormControl<EmployeeGender | ''>('', { nonNullable: true }),
            phone: new FormControl<DialPhone | null>(null),
        });
    }

    override ngOnInit() {
        super.ngOnInit();

        this.addRequiredFields();

        this.form.valueChanges.subscribe((value) => {
            this.emptyForm = Object.values(value).every((v) => v == null || v === '');
        });

        this.form.controls.countryKey.valueChanges.subscribe((countryKey) => {
            if (countryKey instanceof Country) {
                this.form.controls.region.enable();
            } else {
                this.form.controls.region.setValue(null);
                this.form.controls.region.disable();
            }
        });

        this.form.controls.birthDate.valueChanges.pipe(startWith(null)).subscribe((birthDate) => {
            if (birthDate instanceof DateTime && birthDate.isValid) {
                const age = DateTime.now().diff(birthDate, 'years').years;
                this.ageHint = this.numberFormatterService.formatUnit(age, 'year', { maximumFractionDigits: 0 }, this.currentService.languageTag);
            } else {
                this.ageHint = undefined;
            }
        });

        this.form.patchValue({
            phone: { phone: this.user?.phone || '', dial: this.user?.countryCode || '' },
            countryKey: this.currentService.getCustomer().countryCode,
        });

        this.form.statusChanges.subscribe(async (status) => {
            if (status === 'INVALID') {
                this.step.hasError = true;
                this.step.errorMessage = await this.translateService.t('PLEASE_FILL_ALL_REQUIRED', Namespace.NewEmployee);
            } else {
                this.step.hasError = false;
                this.step.errorMessage = '';
            }
        });
    }

    addRequiredFields() {
        if (this.requiredFields['employee.address_1']) {
            this.addRequiredValidator('address1');
        }

        if (this.requiredFields['employee.address_2']) {
            this.addRequiredValidator('address2');
        }

        if (this.requiredFields['employee.postal_code']) {
            this.addRequiredValidator('postalCode');
        }

        if (this.requiredFields['employee.city']) {
            this.addRequiredValidator('city');
        }

        if (this.requiredFields['employee.country_key']) {
            this.addRequiredValidator('countryKey');
        }

        if (this.requiredFields['employee.region']) {
            this.addRequiredValidator('region');
        }

        if (this.requiredFields['employee.nationality']) {
            this.addRequiredValidator('nationality');
        }

        if (this.requiredFields['employee.birth_date']) {
            this.addRequiredValidator('birthDate');
        }

        if (this.requiredFields['employee.gender']) {
            this.addRequiredValidator('gender');
        }

        if (this.requiredFields['employee.phone']) {
            this.addRequiredValidator('phone');
        }
    }

    addRequiredValidator(name: keyof NewEmployeeExtraEmployeeInfoComponent['form']['controls']) {
        this.form.controls[name].addValidators(Validators.required);
    }

    update() {
        const form = this.form.value;
        const countryKey = form.countryKey instanceof Country ? form.countryKey.code : null;
        const regionId = form.region instanceof CountryRegion ? form.region.id : null;

        this.processing = true;
        this.employeeService.update(this.customer.id, this.employee.id, {
            address1: form.address1 ? form.address1 : null,
            address2: form.address2 ? form.address2 : null,
            postal_code: form.postalCode ? form.postalCode : null,
            city: form.city ? form.city : null,
            country_key: countryKey,
            region_id: countryKey && regionId ? regionId : null,
            nationality: form.nationality instanceof Country ? form.nationality.code : null,
            birth_date: form.birthDate ? form.birthDate : null,
            gender: form.gender || undefined,
            phone: form.phone ? form.phone?.dial + form.phone?.phone : '',
        }).pipe(
            catchError(() => {
                this.processing = false;
                return EMPTY;
            }),
        ).subscribe(() => {
            this.step.completed = true;
            this.infoSaved = true;
            this.stepper.next();
        });
    }
}
