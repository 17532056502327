// @ts-nocheck
import { module } from 'angular';

module('eaw.staffing').controller('generateStaffingIntervalsDialogCtrl', [ '$mdDialog', 'staffingIntervalFactory', function($mdDialog, staffingIntervalFactory) {
    const ctrl = this;

    ctrl.generate = () => {
        ctrl.generating = true;
        staffingIntervalFactory.createMany.query(ctrl.staffingTemplate.customer_id, ctrl.staffingTemplate.id, ctrl.from, ctrl.to, ctrl.step).$promise.then((newIntervals) => {
            ctrl.tableParams.reload();
            $mdDialog.hide(newIntervals);
        }).finally(() => ctrl.generating = false);
    };

    ctrl.cancel = $mdDialog.cancel;
} ]);
