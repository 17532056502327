// @ts-nocheck
import { module } from 'angular';
import { Products } from '../../../shared/enums/products';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.scheduling.limits', [
    'eaw.resource',
    'eaw.scheduling.shifts',
]).config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state(`eaw/app/scheduling/limits`, {
        parent: `eaw/app/scheduling`,
        url: '/limits',
        views: {
            'content@': 'schedulingLimitList',
        },
        resolve: {
            factory: [ 'SchedulingLimits', function(SchedulingLimits) {
                const factory = new SchedulingLimits(CurrentOld.customer.id);
                if (!factory.getAll.permission()) {
                    return Promise.reject();
                }

                return factory;
            } ],
        },
        data: {
            breadcrumb: { key: 'SCHEDULING_LIMITS', ns: 'navigation' },
            products: [ Products.SchedulingLimits ],
            permissions: [ `customers.{customer}.scheduling_limits.*.get` ],
        },
    });
} ]);
