import { Inject, Injectable } from '@angular/core';
import { Autocomplete } from './autocomplete';
import { Customer } from '../models/customer';
import { TranslateService } from '../services/translate.service';
import { Observable, of } from 'rxjs';
import { UserService } from '../http/user.service';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';

@Injectable({
    providedIn: 'root',
})
export class UserCustomerAutocompleteService extends Autocomplete<Customer> {

    constructor(
        @Inject(UserService) protected userService: UserService,
        @Inject(TranslateService) protected translateService: TranslateService,
    ) {
        super({
            label: translateService.t('CUSTOMER'),
            optionTemplate: 'default',
            filterRequirement: 3,
            display: 'name',
            trackByKey: 'id',
        });
    }

    setter() {
        return (item: unknown) => {
            if (item instanceof Customer) {
                return of(item);
            }
            return of(undefined);
        };
    }

    getter(userId: number): () => Observable<ArrayPaginatedResponse<Customer>> {
        return () => this.userService.getCustomerForUser(userId);
    }
}
