import { Inject, Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, of, switchMap, tap, timer } from 'rxjs';
import { CurrentLoaderService } from './current-loader.service';
import { CurrentService } from '../../shared/services/current.service';
import moment from 'moment-timezone';
import { DateTime, Settings } from 'luxon';
import { DateAdapter } from '@angular/material/core';
import { t } from 'i18next';
import { SettingService } from '../../shared/http/setting.service';
import { InitializerCacheService } from './initializer-cache.service';
import { AppService } from '../../shared/services/app.service';

@Injectable({
    providedIn: 'root',
})
export class LanguageLoaderService {
    private readonly mdDateFormat = 'l';
    private readonly defaultLanguage = 'en';
    private readonly defaultLocale = 'en';

    constructor(
        @Inject(CurrentLoaderService) private currentLoader: CurrentLoaderService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(SettingService) private settingService: SettingService,
        @Inject(DateAdapter) private dateAdapter: DateAdapter<DateTime>,
        @Inject(InitializerCacheService) private initializerCacheService: InitializerCacheService,
        @Inject(AppService) private appService: AppService,
        @Inject('$mdDateLocale') private $mdDateLocale: Record<string, unknown>,
    ) {
    }

    init() {
        return firstValueFrom(this.currentLoader.onLoaded().pipe(
            switchMap((currentLoaded) => {
                const customer = this.current.getCustomer();
                if (!currentLoaded) {
                    return Promise.resolve(true);

                }
                return this.loadLanguage(customer?.id, customer?.localeCode || 'en-US', this.current.languageTag);
            }),
        ));
    }

    private async loadLanguage(customerId: number | undefined, customerLocaleCode: string | undefined, userLanguageTag: string): Promise<boolean> {
        if (customerId && customerLocaleCode) {
            await this.setUpMoment(customerId, customerLocaleCode, userLanguageTag);
        }

        try {
            await this.loadAngular(userLanguageTag);
            return true;
        } catch (err) {
            console.error(err);

            if (customerLocaleCode === this.defaultLocale && userLanguageTag === this.defaultLanguage) {
                return true;
            }

            return this.loadLanguage(customerId, this.defaultLocale, this.defaultLanguage);
        }
    }

    private async loadAngular(userLanguageTag: string) {
        return await import(/* webpackChunkName: "angular-locale" */ `../../../../div/angular-i18n/angular-locale_${userLanguageTag.toLowerCase()}.js`);
    }

    private async getMomentDayOfWeek(customerId: number, localeData: moment.Locale) {
        const dayOfWeekCacheKey = 'dayOfWeek';
        const cached = await this.initializerCacheService.get<number>(customerId, dayOfWeekCacheKey);
        const settingObservable = this.settingService.getString<'mon' | 'sun'>([ 'customers', this.current.getCustomer()?.id ], 'scheduling.week_start', 'mon').pipe(
            catchError(() => of(null)),
            map((day) => day == null ? localeData.firstDayOfWeek() : { 'mon': 1, 'sun': 0 }[day]),
            tap((day) => this.initializerCacheService.store(customerId, dayOfWeekCacheKey, day, '')),
        );

        if (cached?.value != null) {
            timer(5_000).pipe(switchMap(() => settingObservable)).subscribe(async (day) => {
                if (day != cached.value) {
                    await this.initializerCacheService.clear(customerId);
                    this.appService.reload();
                }
            });

            return cached.value;
        }

        return firstValueFrom(settingObservable);
    }

    private async setUpMoment(customerId: number, customerLocaleCode: string, userLanguageTag: string) {
        const customerLocaleAsMomentCode = this.handleMomentCode(customerLocaleCode);
        const userLanguageTagAsMomentCode = this.handleMomentCode(userLanguageTag);

        await import(/* webpackChunkName: "moment-locale" */ `../../../../node_modules/moment/locale/${userLanguageTagAsMomentCode}.js`);

        // Set locale to user locale to extract language stuff
        const userLocale = moment.localeData(userLanguageTagAsMomentCode);
        const customerLocale = moment.localeData(customerLocaleAsMomentCode);
        const dayOfWeek = await this.getMomentDayOfWeek(customerId, customerLocale);
        const dayOfYear = customerLocale.firstDayOfYear();

        // Set timezone so that everyone and everything uses the same as the customer
        moment.tz.setDefault(this.current.getCustomer().timeZone); // IMPORTANT AF :)

        // Set locale
        moment.locale(userLanguageTagAsMomentCode);

        // Keep customer first day of week and year
        moment.updateLocale(userLanguageTagAsMomentCode, {
            week: {
                dow: dayOfWeek,
                doy: dayOfYear,
            },
        });

        // Luxon
        Settings.defaultZone = this.current.getCustomer().timeZone;
        // Use "en-GB" as the default locale, so that users don't get the American date format
        Settings.defaultLocale = this.current.languageTag === 'en-US' ? 'en-GB' : this.current.languageTag;
        this.dateAdapter.setLocale(Settings.defaultLocale);

        // Set locale data for
        this.$mdDateLocale['firstDayOfWeek'] = dayOfWeek;
        this.$mdDateLocale['firstDayOfYear'] = dayOfYear;
        this.$mdDateLocale['months'] = userLocale.months();
        this.$mdDateLocale['shortMonths'] = userLocale.monthsShort();
        this.$mdDateLocale['days'] = userLocale.weekdays();
        this.$mdDateLocale['shortDays'] = userLocale.weekdaysShort();
        this.$mdDateLocale['msgCalendar'] = t('CALENDAR');
        this.$mdDateLocale['msgOpenCalendar'] = t('OPEN_CALENDAR');
        this.$mdDateLocale['weekNumberFormatter'] = (week: number) => t('WEEK_N', { number: week });
        this.$mdDateLocale['formatDate'] = this.mdDateLocaleFormatter.bind(this);
        this.$mdDateLocale['parseDate'] = this.mdDateLocaleParser.bind(this);
    }

    private mdDateLocaleParser = (value: unknown) => {
        if (typeof value !== 'string') {
            return undefined;
        }

        const m = moment(value, this.mdDateFormat, true);
        return m.isValid() ? m.toDate() : undefined;
    };

    private mdDateLocaleFormatter = (date: unknown) => {
        if (date instanceof Date) {
            // Create a moment in the TZ first, then update all the values
            const x = moment();
            x.year(date.getFullYear());
            x.month(date.getMonth());
            x.date(date.getDate());
            return x.format(this.mdDateFormat);
        }

        return '';
    };

    private handleMomentCode(code: string = '') {
        // Moment writes codes in lowercase
        const lowerCase: string = code.toLowerCase();

        switch (lowerCase) {
            case 'no':
            case 'nb-no': // Norwegian
                return 'nb';
            case 'en': // English
            case 'en-us': // English
                return 'en-gb';
            case 'fi-fi': // Finnish
                return 'fi';
            case 'fr-fr': // French
                return 'fr';
            case 'it-ch':
            case 'it-it': // Italian
                return 'it';
            case 'ka-ge': // Georgian
                return 'ka';
            case 'pl-pl': // Polish
                return 'pl';
            case 'sv-se': // Swedish
                return 'sv';
            case 'da-dk': // Danish
                return 'da';
            default:
                return lowerCase;
        }
    }
}
