import { signal } from '@angular/core';

export function SignalInput(): (target: any, name: string) => void {
    return function(target: any, name: string) {
        let value = target[name];
        Reflect.defineProperty(target, name, {
            set(next: any) {
                value = signal(next);
            },
            get() {
                return value;
            },
            enumerable: true,
            configurable: true,
        });
    };
}
