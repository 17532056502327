// @ts-nocheck
import { t } from 'i18next';
import { forEach } from 'lodash-es';

import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.staffing').component('vsTemplateAutocomplete', {
    template: `<md-autocomplete
        md-selected-item="$autocomplete.selectedItem"
        md-search-text="filter"
        md-selected-item-change="$autocomplete.select()"
        md-min-length="0"
        md-input-id="{{$id}}autocomplete"
        md-delay="300"
        ng-disabled="$autocomplete.disable"
        md-floating-label="{{$autocomplete.label}}"
        md-clear-button="$autocomplete.clearBtn"
        ng-required="$autocomplete.required"
        md-items="item in $autocomplete.search(filter)"
        md-item-text="item.text">
    <md-item-template>
        <span md-highlight-text="filter" md-highlight-flags="gi">{{item.text}}</span>
    </md-item-template>
    <md-not-found>
        <span ng-i18next="NO_MATCH_FOUND"></span>
    </md-not-found>
</md-autocomplete>
`,
    bindings: {
        clearBtn: '<?',
    },
    require: {
        ngModel: 'ngModel',
    },
    controllerAs: '$autocomplete',
    controller: [ 'staffingTemplateFactory', '$attrs', function(staffingTemplateFactory, $attrs) {
        const ctrl = this;
        ctrl.label = t('staffing:VARIABLE_STAFFING_TEMPLATE');

        ctrl.$onInit = () => {
            ctrl.required = $attrs.$attr.required == 'required';
            ctrl.disable = $attrs.$attr.disabled == 'disabled';
            ctrl.ngModel.$formatters.unshift(formatter);
        };

        ctrl.select = () => {
            ctrl.ngModel.setViewValue(ctrl.selectedItem);
        };

        ctrl.search = (filter) => staffingTemplateFactory.getAll.query(CurrentOld.customer.id, { filter }).$promise.then((resp) => {
            forEach(resp.data, (template) => {
                template.text = template.name;
            });

            return resp.data;
        });

        function formatter(modelValue) {
            ctrl.selectedItem = modelValue;
            return modelValue;
        }
    } ],
});
