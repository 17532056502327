import { Component, inject, Inject, Input, OnInit } from '@angular/core';
import { EmployeeService } from '../../../shared/http/employee.service';
import { ShiftResponse } from '../../models/shift';
import { Moment } from 'moment-timezone';
import { AutoSchedulingResult } from '../../../auto-scheduling/classes/auto-scheduling-result';
import { AutoScheduleResultService } from '../../../auto-scheduling/services/auto-schedule-result.service';
import { AsyncPipe, KeyValue, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { momentToDateTime } from '../../../shared/angularjs/moment-to-date-time';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AutoPopulatingShiftResultComponent } from '../../../auto-scheduling/components/auto-scheduling-shift-results/auto-populating-shift-result.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { OldClassAsResponse } from '../../../shared/utils/old-class-as-response';
import { map, Observable, shareReplay } from 'rxjs';

@Component({
    selector: 'eaw-auto-scheduling-result-tab',
    templateUrl: './auto-scheduling-result-tab.component.html',
    styleUrl: './auto-scheduling-result-tab.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        NgIf,
        MatProgressSpinnerModule,
        NgFor,
        AutoPopulatingShiftResultComponent,
        AsyncPipe,
        KeyValuePipe,
        TranslatePipe,
    ],
    providers: [
        {
            provide: 'shiftEvents',
            useFactory: ($injector: any) => $injector.get('shiftEvents'),
            deps: [ '$injector' ],
        },
    ],
})
export class AutoSchedulingResultTabComponent implements OnInit {
    private readonly employeeService = inject(EmployeeService);
    private readonly autoSchedulingResultService = inject(AutoScheduleResultService);

    /** Old schedule version, has old shifts */
    @Input() schedule!: { id: number, customer_id: number, shifts: any[], from: Moment, to: Moment };

    shifts?: Record<string, ShiftResponse>;
    employees?: Observable<Map<number, string>>;
    results?: Map<number, AutoSchedulingResult[]>;

    constructor(@Inject('shiftEvents') protected shiftEvents: any) {
    }

    ngOnInit(): void {
        if (this.shiftEvents.shiftsLoaded) {
            this.setShifts();
        } else {
            this.shiftEvents.getLoadedSubject().subscribe(() => {
                this.setShifts();
            });
        }

        this.employees = this.employeeService.getAll(this.schedule.customer_id, {
            from: momentToDateTime(this.schedule.from),
            to: momentToDateTime(this.schedule.to),
            per_page: 999,
        }).pipe(
            map((resp) => {
                return new Map(resp.data.map((emp) => [ emp.id, emp.name ]));
            }),
            shareReplay(1),
        );

        this.autoSchedulingResultService.getAll(this.schedule.customer_id, this.schedule.id).subscribe((results: Map<number, AutoSchedulingResult[]>) => {
            this.results = results;
        });
    }

    keyVal(a: KeyValue<number, AutoSchedulingResult[]>, b: KeyValue<number, AutoSchedulingResult[]>): number {
        const aValue = a.value[0];
        const bValue = b.value[0];

        if (aValue && bValue) {
            return aValue.date.dateTime.valueOf() - bValue.date.dateTime.valueOf();
        }

        return 0;
    }

    private setShifts() {
        this.shifts = this.schedule.shifts.reduce((acc, shift) => {
            acc[shift.id] = OldClassAsResponse(shift);

            return acc;
        }, {} as Record<string, ShiftResponse>);
    }
}
