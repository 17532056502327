<cdk-virtual-scroll-viewport [itemSize]="shiftHeight">
    <ng-container *cdkVirtualFor="let item of dataSource()">
        @if (item) {
            <div class="shift">
                <eaw-profile-picture size="28" [user]="{id: item.shift.employee?.userId, name: item.shift.employee?.name}"/>

                <div class="text-wrapper">
                    <div class="name overflow-ellipsis">{{ item.shift.employee?.name }}</div>
                    <div class="date">{{ item.shift.businessDate | DateTime: 'DATE_MED' }}</div>
                    <div class="time">{{ 'FROM_TO' | translate:'general':{ from: item.shift.from | DateTime:'TIME_SIMPLE', to: item.shift.to | DateTime:'TIME_SIMPLE' } | async }}</div>
                    @if(item.unproductivePercent) {
                        <div class="unproductive">{{ 'UNPRODUCTIVE_PERCENT' | translate:'scheduling':{ percent: item.unproductivePercent } | async }}</div>
                    }
                    @if(item.differentBusinessDate) {
                        <div class="different-business-date">Shift has a different business date</div>
                    }
                </div>

                <span class="tw-flex-1"></span>

                @if(item.shift.commentsCount) {
                    <button mat-icon-button (click)="openComments(item.shift)">
                        <mat-icon>comment</mat-icon>
                    </button>
                }

                @if(item.shift.periodsCount) {
                    <button mat-icon-button (click)="openPeriods(item.shift)">
                        <mat-icon>timelapse</mat-icon>
                    </button>
                }
            </div>
        }

        @if(!item) {
            <div class="loading">
                <span>{{'LOADING' | translate | async}}</span>
            </div>
        }
    </ng-container>
</cdk-virtual-scroll-viewport>
