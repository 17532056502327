import { Component, Input } from '@angular/core';
import { EawMaterialColorHue } from '../../services/material-color.service';
import { MatDialogModule } from '@angular/material/dialog';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MaterialColorPipe } from '../../pipes/material-color.pipe';

@Component({
    selector: 'eaw-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrl: './dialog-header.component.scss' ,
    standalone: true,
    imports: [
        MatDialogModule,
        NgIf,
        MatIconModule,
        AsyncPipe,
        MatButtonModule,
        MaterialColorPipe,
    ],
})
export class DialogHeaderComponent {
    @Input() showCloseButton = true;
    @Input() closeValue?: any;
    @Input() icon?: string;
    @Input() iconColor?: EawMaterialColorHue;
}
