<mat-card class="mat-compact-card tw-m-8">
    <mat-card-header *ngIf="availability" class="tw-p-16 tw-flex tw-justify-between tw-items-center">
        <mat-card-title class="tw-m-0">{{'CURRENT_AVAILABILITY' | translate:'availabilities' | async}}</mat-card-title>

        <button mat-mini-fab color="accent" (click)="openDialog()" *ngIf="canCreate">
            <mat-icon>add</mat-icon>
        </button>
    </mat-card-header>

    <mat-card-content *ngIf="availability && !loading">
        <eaw-availability-days [availability]="availability"></eaw-availability-days>
    </mat-card-content>

    <mat-card-content *ngIf="!availability && !loading" class="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-24 tw-gap-16">
        <mat-icon eawSize="100px">search</mat-icon>
        <span class="mat-headline-6">{{'NO_CURRENT' | translate: 'availabilities' | async}}</span>
        <button mat-raised-button (click)="openDialog()" color="accent">{{'ADD_AVAILABILITY' | translate: 'availabilities' | async}}</button>
    </mat-card-content>

    <mat-card-content *ngIf="loading" class="tw-h-full tw-gap-16 tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-24">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <span class="mat-headline-6">{{'GETTING_AVAILABILITY' | translate: 'availabilities' | async}}</span>
    </mat-card-content>
</mat-card>
