import { module } from 'angular';
import { createState } from '../../../shared/utils/create-state';
import { AdminCustomFieldsComponent } from '../../pages/admin-custom-fields/admin-custom-fields.component';
import { DataTableQueryParams } from '../../../data-table/data-table.component';

module('eaw.admin').config([ '$stateProvider', function($stateProvider: any) {
    createState($stateProvider, {
        name: 'eaw/app/admin/custom_fields',
        parent: 'eaw/app/admin',
        url: '/custom_fields',
        views: {
            'content@': { component: AdminCustomFieldsComponent },
        },
        data: {
            queryParams: [...DataTableQueryParams, 'filter'],
            breadcrumb: { key: 'CUSTOM_FIELDS', ns: 'navigation' },
            permissions: [ `custom_fields.*.get` ],
        },
    });
} ]);
