import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { PaidTimeOverview } from '../../../../models/paid-time-overview';
import { DurationPipe } from '../../../../../shared/pipes/duration.pipe';
import { DateTimePipe } from '../../../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'eaw-paid-time-overview-date',
    templateUrl: './paid-time-overview-date.component.html',
    styleUrl: './paid-time-overview-date.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        DurationPipe,
    ],
})
export class PaidTimeOverviewDateComponent implements OnInit {
    @HostBinding('class.today')
    get today() {
        return this.date.hasSame(DateTime.now(), 'day');
    };

    @HostBinding('class.holiday')
    get holiday() {
        return this.isHoliday;
    };

    @Input({ required: true }) overview!: PaidTimeOverview;
    @Input({ required: true }) date!: DateTime;
    @Input({ required: true }) isHoliday!: boolean;

    // Number of shifts
    shiftsCount = 0;
    // Total duration of shifts in seconds
    shiftsDuration = 0;

    ngOnInit() {
        const shifts = this.overview.filter({
            from: this.date.startOf('day'),
            to: this.date.endOf('day'),
        }).shifts;
        this.shiftsCount = shifts.length;
        this.shiftsDuration = shifts.reduce((acc, s) => acc + s.netLength, 0);
    }
}
