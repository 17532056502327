import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { ApiResponse } from '../interfaces/api-response';

export interface ApiTokenResponse extends ApiResponse {
    expires_at: string,
    api_token: string
}

export class ApiToken {
    expiresAt: DateTime;
    apiToken: string;

    constructor(data: ApiTokenResponse) {
        this.expiresAt = stringToDateTime(data.expires_at);
        this.apiToken = data.api_token;
    }

    isExpired() {
        return this.expiresAt.isValid ? this.expiresAt < DateTime.now() : true;
    }
}
