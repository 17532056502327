import { Transition } from '@uirouter/angularjs';
import { CurrentService } from '../services/current.service';
import { Resolvable } from '../types/resolvable';
import { User } from '../models/user';
import { Employee } from '../models/employee';
import { Customer } from '../models/customer';

export function currentResolver(token: string, what: 'customer'): Resolvable<Customer>
export function currentResolver<K extends keyof Customer>(token: string, what: 'customer', key: K): Resolvable<Customer[K]>
export function currentResolver(token: string, what: 'employee'): Resolvable<Employee>
export function currentResolver<K extends keyof Employee>(token: string, what: 'employee', key: K): Resolvable<Employee[K]>
export function currentResolver(token: string, what: 'user'): Resolvable<User>
export function currentResolver<K extends keyof User>(token: string, what: 'user', key: K): Resolvable<User[K]>
export function currentResolver(token: string, what: 'user' | 'employee' | 'customer', key?: keyof User | keyof Employee | keyof Customer): Resolvable<User | Employee | Customer | User[keyof User] | Employee[keyof Employee] | Customer[keyof Customer] | undefined> {
    return {
        token,
        deps: [ '$transition$' ],
        resolveFn: function($transition$: Transition) {
            const current = $transition$.injector().get(CurrentService) as CurrentService;

            if (what === 'user') {
                return key ? current.getUser()[key as keyof User] : current.getUser();
            }

            if (what === 'employee') {
                const employee = current.getEmployee();
                if (!employee) {
                    return undefined;
                }

                return key ? employee[key as keyof Employee] : employee;
            }

            if (what === 'customer') {
                return key ? current.getCustomer()[key as keyof Customer] : current.getCustomer();
            }
        },
    };
}
