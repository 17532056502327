import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges, SkipSelf, TemplateRef, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ArrayPaginatedResponse } from '../../interfaces/paginated-response';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { catchError, debounceTime, distinctUntilChanged, EMPTY, fromEvent, map, Observable, Subject, switchMap, tap } from 'rxjs';
import { CurrentService } from '../../services/current.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { NgIf, NgFor, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateSyncPipe } from '../../pipes/translate-sync.pipe';

@Component({
    selector: 'eaw-autocomplete-deprecated',
    templateUrl: './autocomplete-deprecated.component.html',
    styleUrl: './autocomplete-deprecated.component.scss',
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [ [ new SkipSelf(), ControlContainer ] ],
        },
    ],
    standalone: true,
    imports: [
        MatFormFieldModule,
        NgIf,
        ProfilePictureComponent,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        NgFor,
        MatOptionModule,
        NgTemplateOutlet,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
/**
 * @deprecated
 * @see AutocompleteComponent
 */
export class AutocompleteDeprecatedComponent<T = unknown> implements OnChanges, AfterViewInit, OnDestroy {
    @ViewChild(MatAutocomplete) matAutocomplete!: MatAutocomplete;
    @ViewChild('filterInput') filterInput!: ElementRef<HTMLInputElement>;

    @Output() initialFocus = new EventEmitter<undefined>();
    @Output() filterChanged = new EventEmitter<string | undefined>();
    @Output() selectedItem: EventEmitter<T> = new EventEmitter<T>();

    @Input() observable?: Observable<ArrayPaginatedResponse<unknown>>;
    @Input() optionTemplate?: TemplateRef<any>;
    @Input() debounce = 1000;
    @Input() controlName!: string;
    @Input() itemKey?: string;
    @Input() label?: Promise<string>;
    @Input() profilePicturePrefix = false;
    @Input() icon?: string;
    /**
     * Function to update and set the value based on input to the autocomplete
     *
     * The observable **MUST** return a value, EMPTY will make it not work
     */
    @Input() changeObservable?: (value: unknown) => Observable<unknown>;

    private subject = new Subject<Observable<ArrayPaginatedResponse<T>>>();
    private fetches = 0;

    control?: FormControl;
    filter?: string;
    loading: boolean = false;
    items: T[] = [];
    total = 0;
    results = 0;

    constructor(
        @Inject(CurrentService) public current: CurrentService,
        @Inject(ControlContainer) public controlContainer: ControlContainer,
        @Inject(ElementRef) public elementRef: ElementRef,
        @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    ngAfterViewInit() {
        if (!this.controlName) {
            throw Error('Missing control name');
        }
        if (!this.label) {
            throw Error('Missing label');
        }

        this.control = this.controlName ? this.controlContainer.control?.get(this.controlName) as FormControl : undefined;
        if (!this.control) {
            throw Error('Missing control');
        }

        this.subject.pipe(
            debounceTime(this.debounce),
            switchMap((observable) => {
                this.loading = true;

                return observable;
            }),
            catchError(() => {
                this.loading = false;
                this.items = [];
                this.total = 0;
                this.results = 0;

                return EMPTY;
            }),
            tap((response) => {
                this.fetches++;
                this.loading = false;
                this.items = response.data;
                this.total = response.total;
                this.results = this.items.length;
                this.changeDetectorRef.markForCheck();
            }),
        ).subscribe();

        fromEvent(this.filterInput.nativeElement, 'keyup').pipe(
            debounceTime(this.debounce),
            map(() => this.filterInput.nativeElement.value),
            distinctUntilChanged(),
        ).subscribe((filter: string) => {
            this.filter = filter.trim() || undefined;
            this.filterChanged.emit(this.filter);
        });

        // Handle whenever a change happens while input is not in focus
        // Which would mean some kind of external change that we have to react to
        const initiallyDisabled = this.control?.disabled;
        this.control?.valueChanges.pipe(
            map((value) => value?.valueOf() || ''),
            distinctUntilChanged(),
            switchMap((value) => {
                if (document.activeElement === this.filterInput.nativeElement) {
                    return EMPTY;
                }
                if (typeof this.changeObservable !== 'function') {
                    return EMPTY;
                }

                this.control?.disable();
                this.loading = true;
                return this.changeObservable(value);
            }),
            tap(() => {
                if (!initiallyDisabled) {
                    this.control?.enable();
                }
                this.loading = false;
            }),
        ).subscribe((result) => {
            this.control?.patchValue(result, { emitEvent: false });
        });

        // Try to set initial value if any value is provided
        this.changeObservable?.(this.control?.value).subscribe((result) => this.control?.patchValue(result));
    }

    ngOnChanges(changes: SimpleChanges): void {
        const observable = changes['observable']?.currentValue;

        if (observable instanceof Observable) {
            this.subject.next(observable as Observable<ArrayPaginatedResponse<T>>);
        }
    }

    ngOnDestroy() {
        this.subject.complete();
    }

    focus() {
        if (this.fetches) {
            return;
        }
        this.initialFocus.emit();
    }

    // Arrow function in order to have access to this
    autocompleteDisplay = (item?: Record<string, any>) => {
        return item?.[this.itemKey || 'name'] ?? '';
    };

    clearValue() {
        this.control?.setValue('');
        this.control?.markAsDirty();
    }

    selected(item?: T | MatAutocompleteSelectedEvent) {
        this.selectedItem.emit(<T>item);
    }
}
