// @ts-nocheck
import { t } from 'i18next';
import { orderBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { Products } from '../../../../shared/enums/products';
import { CustomerProductService } from '../../../../shared/http/customer-product.service';
import { lastValueFrom } from 'rxjs';

module('eaw.chain-ops.employees').component('chainEmployeeList', {
    template: `<script type="text/ng-template" id="chainopsemployeelistwarnings">
    <div layout="row" layout-align="start center" ng-if="item.warnings.length">
        <md-icon class="tw-m-0" md-colors="{color: 'amber'}" ng-bind="'error'"></md-icon>
        <div class="tw-ml-12" layout="column">
            <div ng-repeat="warning in item.warnings track by warning.id" ng-i18next="[i18next](warning.message_parameters || {})warnings:{{warning.message}}"></div>
        </div>
    </div>
</script>

<md-card>
    <md-card-content>
        <form ng-submit="$employees.search()" layout="row" layout-wrap layout-padding>
            <eaw-select-multiple-search
                    flex="100"
                    flex-gt-xs="50"
                    flex-gt-md="33"
                    flex-gt-lg="25"
                    ng-model="$employees.selectedCustomers"
                    i18n-label="'LOCATION_plural'"
                    items="$employees.availableCustomers"
                    item-key="id"
                    display-key="'name'">
            </eaw-select-multiple-search>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="33" flex-gt-lg="25" ng-if="$employees.hasSrc">
                <label>EMPLID</label>
                <input type="text" ng-model="$employees.emplid">
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="33" flex-gt-lg="25">
                <eaw-date-picker label="BIRTH_DATE" current-view="year" business-date="true" ng-model="$employees.birth_date"></eaw-date-picker>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="33" flex-gt-lg="25">
                <label ng-i18next="SEARCH"></label>
                <input ng-model="$employees.filter" ng-change="$employees.filterChange()">
            </md-input-container>

            <md-card-actions flex="100">
                <eaw-submit-btn text="'SEARCH'" form="$employees.form"></eaw-submit-btn>
            </md-card-actions>
        </form>

        <md-divider></md-divider>
        <div layout="row" layout-align="space-between center">
            <span class="md-title tw-pl-16" ng-bind="$employees.header"></span>

            <md-card-actions layout="row" layout-align="space-between center">
                <eaw-icon-button raised="false"
                                 icon="request_quote" ng-if="$employees.payRateImport"
                                 text="'chainops:IMPORT_PAY_RATES' | i18next"
                                 tooltip="'chainops:IMPORT_PAY_RATES_DIALOG_TITLE'"
                                 ng-click="$employees.updateSalaryImport()">
                </eaw-icon-button>               
                <eaw-toggle-btn ng-model="$employees.includeInactive"
                                icon="history"
                                ng-change="$employees.search()"
                                i18n-tooltip="SHOW_INACTIVE">
                </eaw-toggle-btn>
                <eaw-icon-button icon="person_search" fab="true" ng-click="$employees.openIdSearch()" tooltip="'GO_TO_EMP'" type="accent"></eaw-icon-button>
            </md-card-actions>
        </div>
        <md-divider></md-divider>

        <eaw-dynamic-ng-table
                columns="$employees.columns"
                cells="$employees.cells"
                get-data="$employees.getEmployees(params, pagination)"
                go-to="eaw/app/chain_ops/employee_list/view/information|emp=id|location=customer_id|group=group"
                pagination="$employees.pagination"
                mark-inactive="true"
                reload-table="$employees.reloadTable">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$employees',
    bindings: {
        customer: '<',
        customers: '<',
        customerGroup: '<',
    },
    controller: [ 'ChainEmployeeFactory', '$filter', 'ItemSelectorDialog', 'UrlParams', 'ToastService', 'eawDatePickerDialog', 'FindEmployeeDialogDowngrade', 'PayRateImportUpdateDialogDowngrade', 'CustomerSettings', 'CustomerProductServiceDowngrade', function(ChainEmployeeFactory, $filter, ItemSelectorDialog, UrlParams, ToastService, eawDatePickerDialog, FindEmployeeDialogDowngrade, PayRateImportUpdateDialogDowngrade, CustomerSettings, customerProductService: CustomerProductService) {
        this.$onInit = async () => {
            this.showFilter = true;
            this.filter = UrlParams.get('filter');
            this.header = t('EMPLOYEE_plural');
            this.pagination = { order_by: { name: 'asc' } };
            this.hasSrc = await lastValueFrom(customerProductService.hasProducts(this.customer.id, [ Products.SwissRiskAndCare ]));
            this.availableCustomers = Object.values(this.customers).map((c) => {
                return {
                    id: c.id,
                    name: c.name,
                };
            });
            this.columns = [
                {
                    title: t('ID'),
                    sortable: 'id',
                },
                {
                    title: t('NUMBER'),
                    class: 'text-right',
                    sortable: 'number',
                },
                {
                    title: t('EMPLID'),
                    _show: this.hasSrc,
                },
                {
                    title: t('NAME'),
                    sortable: 'name',
                },
                {
                    title: t('PHONE'),
                    sortable: 'phone',
                },
                {
                    title: t('BIRTH_DATE'),
                    sortable: 'birth_date',
                },
                { title: t('LOCATION') },
                {
                    title: t('FROM'),
                    sortable: 'from',
                },
                {
                    title: t('TO'),
                    sortable: 'to',
                },
                { title: t('WARNING_plural') },
            ];
            this.cells = [
                { template: 'item.id' },
                {
                    template: '"#" + item.number',
                    class: 'text-right',
                },
                {
                    template: 'item.emplid',
                    _show: this.hasSrc,
                },
                { template: 'item.name' },
                { template: 'item.phone' },
                { template: 'item.birth_date | moment:"date"' },
                { template: 'item.customer.name' },
                { template: `item.from | moment:'ll'` },
                { template: `item.to | moment:'ll'` },
                { templateUrl: 'chainopsemployeelistwarnings' },
            ];
            if (this.hasSrc) {
                this.columns.push({ title: '' });
                this.cells.push({
                    buttons: [
                        {
                            icon: 'account-arrow-right',
                            tooltip: 'chainops:TRANSFER_VERB',
                            click: this.transferEmployee,
                        },
                    ],
                });
            }
            this.columns = this.columns.filter((c) => !Object.prototype.hasOwnProperty.call(c, '_show') || c._show);
            this.cells = this.cells.filter((c) => !Object.prototype.hasOwnProperty.call(c, '_show') || c._show);
            CustomerSettings.get(this.customer.id, 'pay_rate_import').then((setting) => (this.payRateImport = setting['pay_rate_import']));
        };
        this.filterChange = () => {
            UrlParams.set('filter', this.filter);
        };
        this.search = () => {
            this.reloadTable = true;
        };
        this.openIdSearch = () => {
            FindEmployeeDialogDowngrade.open(this.customerGroup.id);
        };
        this.transferEmployee = (employee) => {
            ItemSelectorDialog.open({
                title: t('LOCATION_plural'),
                items: orderBy(this.availableCustomers.filter((c) => c.id !== employee.customer_id), 'name', 'asc'),
                subheader: t('chainops:TRANSFER_SUBHEADER', {
                    name: employee.name,
                }),
                displayKey: 'name',
            }).then((customer) => {
                eawDatePickerDialog.open(null, {
                    heading: t('chainops:TRANSFER_DATE'),
                    minDate: moment(),
                    businessDate: true,
                    callback: (date) => {
                        this.doTransfer(employee, customer, date);
                    },
                });
            });
        };
        this.doTransfer = (employee, customer, date) => {
            ChainEmployeeFactory.transfer(employee.customer_id, customer.id, employee.id, date).then(() => {
                ToastService.tToast('chainops:TRANSFER_CREATED', null, {
                    action: {
                        text: t('GO'),
                        state: {
                            name: `eaw/app/chain_ops/employee_list/view`,
                            params: {
                                emp: employee.id,
                                location: employee.customer_id,
                                tab: 'transfers',
                            },
                        },
                    },
                });
            });
        };
        this.getEmployees = (params, pagination) => {
            const ids = this.selectedCustomers?.map((c) => c.id) || [];
            const args = {
                ...pagination.toWithPaginatorArguments(),
                include_inactive: this.includeInactive,
                filter: this.filter,
                customers: ids.length ? ids : undefined,
                birth_date: this.birth_date,
                fields: [ 'name', 'number' ],
                with: [
                    'warnings',
                    ...this.hasSrc ? [ 'properties' ] : '',
                ],
            };
            if (this.emplid) {
                args.properties = {
                    emplid: this.emplid,
                };
            }
            return ChainEmployeeFactory.getAll(this.customerGroup.id, args).then((resp) => {
                this.header = `${t('EMPLOYEE_plural')} (${$filter('eawNumber')(resp.total)})`;
                resp.data.forEach((employee) => {
                    employee.customer = this.customers.find((c) => c.id === employee.customer_id);
                    employee.emplid = employee.properties?.find((p) => p.key.toLowerCase() === 'emplid')?.value;
                    employee.group = this.customerGroup.id;
                });
                return resp;
            }).finally(() => this.form.unsubmit());
        };
        this.updateSalaryImport = () => {
            PayRateImportUpdateDialogDowngrade.open(this.customerGroup.id);
        };
    } ],
});
