import { DateTime } from 'luxon';
import { stringToDateTime } from '../../../shared/utils/string-to-date-time';
import { RotationInterval, RotationIntervalResponse } from './rotation-interval';
import { Weekday } from '../../../shared/types/weekday';
import { ApiResponse } from '../../../shared/interfaces/api-response';

type StartDay = { index: number, value: Weekday, dateTime: DateTime };

export interface RotationResponse extends ApiResponse {
    id: number;
    customer_id: number;
    name: string;
    start_day: Weekday;
    days: number;
    intervals?: RotationIntervalResponse[];
    employees_count?: number;
    updated_at?: | string | null;
    created_at?: | string | null;
    deleted_at?: | string | null;
}

export class Rotation {
    private _days = 0;
    private _weeks = 0;

    id: number;
    startDay: Weekday;
    startDayDateTime: DateTime;
    customerId: number;
    name: string;
    employeesCount?: number;
    intervals: RotationInterval[] = [];
    createdAt?: DateTime | null;
    updatedAt?: DateTime | null;
    deletedAt?: DateTime | null;

    static daysObject: Record<Weekday, StartDay> = new Array(7).fill('').reduce((obj, _, index) => {
        const dateTime = DateTime.now().startOf('week').plus({ day: index });
        const weekday = dateTime.toFormat('cccc', { locale: 'en' }).toLowerCase();

        obj[weekday] = {
            index,
            value: weekday,
            dateTime,
        };

        return obj;
    }, {} as Record<Weekday, StartDay>);

    static daysArray: StartDay[] = Object.values(Rotation.daysObject);

    constructor(data: RotationResponse) {
        this.id = data.id;
        this.startDay = data.start_day;
        this.startDayDateTime = Rotation.daysObject[this.startDay].dateTime;
        this.customerId = data.customer_id;
        this.name = data.name;
        this.days = data.days;
        this.employeesCount = data.employees_count;
        this.intervals = data.intervals ? data.intervals.map((i) => new RotationInterval(i)) : [];
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }

    get days() {
        return this._days;
    }

    set days(days: number) {
        this._days = days;
        this._weeks = days / 7;
    }

    get weeks() {
        return this._weeks;
    }

    set weeks(weeks: number) {
        this._weeks = weeks;
        this._days = weeks * 7;
    }
}
