import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { CurrentService } from '../../../shared/services/current.service';
import { Widget } from '../../classes/widget';
import { Products } from '../../../shared/enums/products';
import { HoursWorkedWidgetSettings } from './hours-worked-widget-settings-dialog/hours-worked-widget-settings-dialog.component';
import { DateTime } from 'luxon';
import { forkJoin, map, of, switchMap, take, tap } from 'rxjs';
import { TranslateService } from '../../../shared/services/translate.service';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { DashboardStoreService } from '../../services/dashboard-store.service';

@Component({
    selector: 'eaw-hours-worked-widget',
    templateUrl: './hours-worked-widget.component.html',
    styleUrl: './hours-worked-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ MiniWidgetContentComponent, DurationPipe ],
})
export class HoursWorkedWidgetComponent extends WidgetComponent implements OnInit {
    private current = inject(CurrentService);
    private translate = inject(TranslateService);
    private customerProductService = inject(CustomerProductService);
    private permissionCheckService = inject(PermissionCheckService);
    private dashboardStoreService = inject(DashboardStoreService);

    widget = input.required<Widget<HoursWorkedWidgetSettings>>();

    seconds = signal(0);
    subtext = signal(Promise.resolve(''));

    ngOnInit() {
        this.setSubtext();

        const interval = this.interval;
        if (!interval) {
            return;
        }

        forkJoin([
            this.permissionCheckService.isAllowed('customers.{customer}.employees.{employee}.paid_times.*.get'),
            this.customerProductService.hasProducts(this.widget().info.customer.id, [ Products.PaidTime ]),
        ]).pipe(
            switchMap(([ can, hasPaidTime ]) => {
                const basis: HoursWorkedWidgetSettings['basis'] | null = this.widget().getSetting('basis');
                return hasPaidTime && can && (!basis || basis === 'paid_time') ? this.getPaidTime(interval.from, interval.to) : this.getTimepunches(interval.from, interval.to);
            }),
            take(1),
            tap((seconds) => {
                this.setLoading(false);
                this.seconds.set(seconds);
            }),
        ).subscribe();
    }

    setSubtext() {
        switch (this.widget().getSetting('month')) {
            case 'previous': {
                this.subtext.set(this.translate.t('PREVIOUS_MONTH', 'widgets'));
                break;
            }
            case 'week': {
                this.subtext.set(this.translate.t('THIS_WEEK', 'widgets'));
                break;
            }
            case 'this': {
                this.subtext.set(this.translate.t('THIS_MONTH', 'widgets'));
                break;
            }
            default: {
                this.setError('settings');
            }
        }
    }

    /**
     * @Returns The total amount of seconds worked in the given interval based on timepunches
     */
    getTimepunches(from: DateTime, to: DateTime) {
        const employee = this.current.getEmployee();
        if (!employee) {
            return of(0);
        }

        return this.dashboardStoreService.getEmployeeTimepunches(from, to, this.current.getCustomer().id, employee.id).pipe(
            map((timepunches) => {
                return timepunches.reduce((acc, timepunch) => (acc + timepunch.length), 0);
            }),
        );
    }

    /**
     * @Returns The total amount of seconds worked in the given interval based on paid time
     */
    getPaidTime(from: DateTime, to: DateTime) {
        const employee = this.current.getEmployee();
        if (!employee) {
            return of(0);
        }

        return this.dashboardStoreService.getEmployeePaidTime(from, to, this.current.getCustomer().id, employee.id).pipe(
            map((paidTimes) => {
                return paidTimes.reduce((acc, paidTime) => (acc + paidTime.duration.as('seconds')), 0);
            }),
        );
    }

    get interval(): { from: DateTime, to: DateTime } | null {
        switch (this.widget().getSetting('month')) {
            case 'previous': {
                return {
                    from: DateTime.now().minus({ month: 1 }).startOf('month'),
                    to: DateTime.now().minus({ month: 1 }).endOf('month'),
                };
            }
            case 'week': {
                return {
                    from: DateTime.now().startOf('week'),
                    to: DateTime.now().endOf('week'),
                };
            }
            case 'this': {
                return {
                    from: DateTime.now().startOf('month'),
                    to: DateTime.now().endOf('month'),
                };
            }
            default: {
                this.setError('settings');
                return null;
            }
        }
    }
}
