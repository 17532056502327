// @ts-nocheck
import { module } from 'angular';
module('eaw.components').component('eawCustomField', {
    template: `<md-input-container class="boolean-field" flex ng-if="$ctrl.customField.type == 'boolean'">
    <label ng-class="{required: $ctrl.required}" ng-bind="::$ctrl.customField.translatedName"></label>
    <md-radio-group ng-model="$ctrl.customField.value" ng-required="$ctrl.required" ng-disabled="$ctrl.disabled" ng-change="$ctrl.change()">
        <md-radio-button ng-value="true">
            <span ng-i18next="YES"></span>
        </md-radio-button>
        <md-radio-button ng-value="false">
            <span ng-i18next="NO"></span>
        </md-radio-button>
    </md-radio-group>
</md-input-container>

<md-input-container ng-if="$ctrl.customField.type == 'date'" class="error-spacer">
    <eaw-date-picker ng-model="$ctrl.customField.value" ng-change="$ctrl.change()" ng-disabled="$ctrl.disabled" business-date="true" label="{{$ctrl.customField.translatedName}}" ng-required="$ctrl.required"></eaw-date-picker>
</md-input-container>

<md-input-container ng-if="$ctrl.customField.type == 'decimal'" flex>
    <label ng-bind="::$ctrl.customField.translatedName"></label>
    <input type="number" step="any" ng-required="$ctrl.required" ng-disabled="$ctrl.disabled" ng-model="$ctrl.customField.value" ng-change="$ctrl.change()" uniq-form-name>
</md-input-container>

<md-input-container ng-if="$ctrl.customField.type == 'integer'" flex>
    <label ng-bind="::$ctrl.customField.translatedName"></label>
    <input type="number" step="1" ng-required="$ctrl.required" ng-disabled="$ctrl.disabled" ng-model="$ctrl.customField.value" ng-change="$ctrl.change()" uniq-form-name>
</md-input-container>

<md-input-container ng-if="$ctrl.customField.type == 'float'" flex>
    <label ng-bind="::$ctrl.customField.translatedName"></label>
    <input type="number" step="any" ng-required="$ctrl.required" ng-disabled="$ctrl.disabled" ng-model="$ctrl.customField.value" ng-change="$ctrl.change()" uniq-form-name>
</md-input-container>

<md-input-container ng-if="$ctrl.customField.type == 'string'" flex>
    <label ng-bind="::$ctrl.customField.translatedName"></label>
    <input ng-required="$ctrl.required" ng-model="$ctrl.customField.value" ng-disabled="$ctrl.disabled" ng-change="$ctrl.change()" uniq-form-name>
</md-input-container>

<md-input-container ng-if="$ctrl.customField.type == 'select'" flex>
    <label ng-bind="::$ctrl.customField.translatedName"></label>
    <md-select ng-model="$ctrl.customField.value" ng-change="$ctrl.change()" ng-disabled="$ctrl.disabled" ng-required="$ctrl.required">
        <md-option></md-option>
        <md-option ng-repeat="(value, i18n) in $ctrl.customField.selectOptions track by $index" ng-bind="i18n" ng-value="value"></md-option>
    </md-select>
</md-input-container>
`,
    require: {
        ngModel: 'ngModel', // CustomFieldOld
    },
    controller: [ '$attrs', function($attrs) {
        this.$onInit = () => {
            this.ngModel.$formatters.unshift(this.formatter);
            $attrs.$observe('required', (value) => {
                this.required = value;
            });
            $attrs.$observe('disabled', (value) => {
                this.disabled = value;
            });
        };
        this.formatter = (val) => {
            this.customField = val;
            return this.customField;
        };
        this.change = () => {
            this.ngModel.setViewValue(this.customField);
        };
    } ],
});
