import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { WysiwygComponent } from '../../components/wysiwyg/wysiwyg.component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { catchError, EMPTY, Observable } from 'rxjs';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';

export interface WysiwygDialogData<Return> extends DialogData {
    content?: string;
    title: Promise<string>;
    submitText: Promise<string>;
    submitFn: (body: string | undefined) => Observable<Return>;
}

export type WysiwygDialogReturn<Return> = Return;

@Component({
    selector: 'eaw-wysiwyg-dialog',
    templateUrl: './wysiwyg-dialog.component.html',
    styleUrl: './wysiwyg-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        WysiwygComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        ActionButtonComponent,
    ],
})
export class WysiwygDialogComponent<Return> extends DialogComponent {
    processing = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: WysiwygDialogData<Return>,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<WysiwygDialogComponent<Return>, WysiwygDialogReturn<Return>>,
    ) {
        data.size ||= DialogSize.Large;
        super(dialogRef, data);
    }

    submit(content: string | undefined) {
        this.processing = true;
        this.data.submitFn(content).pipe(
            catchError(() => {
                this.processing = false;
                return EMPTY;
            }),
        ).subscribe((res) => {
            this.dialogRef.close(res);
        });
    }
}
