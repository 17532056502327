import { t } from 'i18next';
import { module } from 'angular';
import { Namespace } from '../../shared/enums/namespace';
import { createState } from '../../shared/utils/create-state';
import { LearningModuleModule } from '../../learning-module/types/modules';
import { StateProvider } from '@uirouter/angularjs';
import { CompanyUserGroupListComponent } from '../pages/company-user-group-list/company-user-group-list.component';
import { currentResolver } from '../../shared/resolvers/current.resolver';

module('eaw.company', [
    'eaw.time',
    'eaw.decorators',
    'eaw.dialogs',
    'eaw.login',
    'eaw.customers',
    'eaw.employees',
    'eaw.company.users',
    'eaw.company.src',
    'eaw.user-groups',
]).config([ '$stateProvider', function($stateProvider: StateProvider) {
    createState($stateProvider, {
        name: `eaw/app/company`,
        parent: 'eaw/app',
        url: '/company',
        abstract: true,
        data: {
            learningModuleModules: [ LearningModuleModule.MyCompany ],
            breadcrumb: { key: 'MY_COMPANY', ns: 'navigation' },
            i18nextNs: [
                Namespace.Admin,
                Namespace.Company,
                Namespace.SwissRiskAndCare,
                Namespace.ChainOps,
                Namespace.Digisign,
                Namespace.Payroll,
                Namespace.Absences,
            ],
        },
    });

    $stateProvider.state(`eaw/app/company/user_groups`, {
        parent: `eaw/app/company`,
        url: '/user_groups',
        data: {
            permissions: [
                `customers.{customer}.user_groups.*.get`,
            ],
            breadcrumb: null,
        },
        views: {
            'content@': 'eawNavBar',
        },
        resolve: {
            navbarRoute: () => `eaw/app/company/user_groups`,
            viewName: function() {
                return 'tabPane';
            },
            tabs: function() {
                return [
                    {
                        label: t('navigation:OVERVIEW'),
                        src: 'eaw/app/company/user_groups/quick',
                    },
                    {
                        label: t('LIST'),
                        src: `eaw/app/company/user_groups/list`,
                    },
                ];
            },
        },
    });

    $stateProvider.state('eaw/app/company/user_groups/quick', {
        url: '/quick?mode',
        params: {
            mode: {
                type: 'int',
                squash: true,
                dynamic: true,
            },
        },
        parent: `eaw/app/company/user_groups`,
        views: {
            tabPane: 'quickUserGroups',
        },
        data: {
            permissions: [ `customers.{customer}.user_groups.*.get` ],
            breadcrumb: { key: 'USER_GROUPS', ns: 'navigation' },
        },
    });
    $stateProvider.state(`eaw/app/company/user_groups/list`, {
        parent: `eaw/app/company/user_groups`,
        url: '/list',
        views: {
            tabPane: {
                component: CompanyUserGroupListComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            breadcrumb: { key: 'USER_GROUPS', ns: 'navigation' },
        },
    });
} ]);
