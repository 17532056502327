// @ts-nocheck
import { module } from 'angular';

module('eaw.misc').factory('clipboardService', [ 'ToastService', function clipboardService(ToastService) {
    return {
        copy(text) {
            try {
                const input = document.createElement('textarea');
                input.innerHTML = text;
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);

                ToastService.tToast('COPIED_TO_CLIPBOARD', {}, {
                    icon: 'clipboard-check',
                    color: 'green',
                });
            } catch (_) {
                ToastService.tToast('COULDNT_COPY_TO_CLIPBOARD', {}, {
                    icon: 'clipboard-alert',
                    color: 'amber',
                });
            }
        },
    };
} ]);
