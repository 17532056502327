import { Component, Inject, OnInit } from '@angular/core';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { ProjectionDay } from '../../models/projection-day';
import { AsyncPipe, NgIf } from '@angular/common';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReferenceDay } from '../../models/reference-day';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { ProjectionsService } from '../../http/projections.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

export interface ReferenceDaysDialogData extends DialogData {
    customerId: number;
    projectionDay: ProjectionDay;
    tags: string[];
}

export interface ReferenceDaySelectable extends ReferenceDay {
    selected?: boolean,
}

@Component({
    selector: 'eaw-reference-days-dialog',
    standalone: true,
    imports: [
        NgIf,
        DialogHeaderComponent,
        FormsModule,
        MatButtonModule,
        MatDialogActions,
        MatDialogClose,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatTableModule,
        TranslatePipe,
        AsyncPipe,
        CheckboxHelperDirective,
        MatCheckboxModule,
        DateTimePipe,
        ActionButtonComponent,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        TranslateSyncPipe,
    ],
    templateUrl: './reference-days-dialog.component.html',
    styleUrl: './reference-days-dialog.component.scss',
})

export class ReferenceDaysDialogComponent extends DialogComponent implements OnInit {
    protected loading = true;
    protected fetching = false;
    dataSourceAvailable: ReferenceDaySelectable[] = [];
    dataSourceChosen: ReferenceDaySelectable[] = [];
    displayedColumns: string[] = [ 'status', 'date', 'tag', 'comment' ];
    selectedDates: ReferenceDaySelectable[] = [];
    initialSearch: boolean = true;
    limitResults:number = 365;

    weekdays = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
    ];

    formGroup = new FormGroup({
        tag: new FormControl<string | null>(null),
        weekdays: new FormControl<string[] | null>(null),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ReferenceDaysDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ReferenceDaysDialogData>,
        @Inject(ProjectionsService) private projectionsService: ProjectionsService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
    ) {
        data.size ||= DialogSize.XLarge;
        super(dialogRef, data);
    }

    ngOnInit(): void {
        this.getReferenceDays();
    }

    getReferenceDays() {
        this.loading = true;
        if (this.data?.projectionDay) {
            const selectedDays: ReferenceDaySelectable[] = this.initialSearch ? [ ...this.data.projectionDay.chosenReferenceDays ] : [ ...this.selectedDates ];
            this.initialSearch = false;
            let referenceDays: ReferenceDaySelectable[] = this.data.projectionDay.defaultReferenceDays ? [ ...this.data.projectionDay.defaultReferenceDays ] : [];
            if (selectedDays.length>0) {
                selectedDays.forEach((selDay) => {
                    referenceDays = referenceDays.filter((refDay) => selDay.date.toFormat('yyyy-MM-dd') !== refDay.date.toFormat('yyyy-MM-dd'));
                    selDay.selected = true;
                });
            }
            referenceDays.sort(this.compareDates);
            this.dataSourceAvailable = [ ...referenceDays ];
            this.selectedDates = selectedDays;
            this.dataSourceChosen = [ ...selectedDays ];
            this.loading = false;
        }
    }

    searchReferenceDays() {
        this.fetching = true;
        const tag = this.formGroup.controls.tag.value || undefined;
        const weekday = this.formGroup.controls.weekdays.value || undefined;

        this.projectionsService.searchReferenceDays(this.data.customerId, this.limitResults, tag, weekday).subscribe((res) => {
            let referenceDays: ReferenceDaySelectable[] = res.reference_days?.length > 0 ? res.reference_days.map((day) => new ReferenceDay(day)) : [];
            const selectedDays = this.selectedDates;
            selectedDays.forEach((selDay) => {
                referenceDays = referenceDays.filter((refDay) => selDay.date.toFormat('yyyy-MM-dd') !== refDay.date.toFormat('yyyy-MM-dd'));
                selDay.selected = true;
            });
            referenceDays.sort(this.compareDates);
            this.dataSourceAvailable = [ ...referenceDays ];
            this.dataSourceChosen = selectedDays;
            this.fetching = false;
        });
    }

    compareDates(a: ReferenceDaySelectable, b: ReferenceDaySelectable) {
        return b.date.toMillis() - a.date.toMillis();
    }

    selectDate(day: ReferenceDaySelectable) {
        if (!this.selectedDates.find((selDay) => selDay.date.toFormat('yyyy-MM-dd') == day.date.toFormat('yyyy-MM-dd'))) {
            this.selectedDates.push({ ...day });
            this.selectedDates.sort(this.compareDates);
            this.dataSourceChosen = [ ...this.selectedDates ];
            this.dataSourceAvailable = this.dataSourceAvailable.filter((resDay) => resDay.date.toFormat('yyyy-MM-dd') !== day.date.toFormat('yyyy-MM-dd'));
        }
    }

    unSelectDate(day: ReferenceDaySelectable) {
        this.selectedDates = this.selectedDates.filter((refDay) => day.date.toFormat('yyyy-MM-dd') !== refDay.date.toFormat('yyyy-MM-dd'));
        this.dataSourceChosen = [ ...this.selectedDates ];
        const refDays = [ ...this.dataSourceAvailable ];
        refDays.push({ ...day });
        refDays.sort(this.compareDates);
        this.dataSourceAvailable = [ ...refDays ];
    }

    saveReferenceDays() {
        this.fetching = true;
        this.projectionsService.saveReferenceDays(this.data.customerId, this.data.projectionDay.date.toFormat('yyyy-MM-dd'), this.selectedDates.map((day) => day.date.toFormat('yyyy-MM-dd'))).subscribe(() => {
            this.fetching = false;
            void this.snackBarService.t('UPDATED');
            this.dialogRef.close(true);
        });
    }

    clearForm() {
        this.formGroup.controls.tag.setValue(null);
        this.formGroup.controls.weekdays.setValue(null);
        this.getReferenceDays();
    }
}
