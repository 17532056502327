import { ApiResponse } from '../interfaces/api-response';
import { ContractType, ContractTypeResponse } from './contract-type';
import { stringToDateTime } from '../utils/string-to-date-time';
import { DateTime } from 'luxon';
import { BaseApiModel } from './base-api-model';
import { ApiModel } from '../enums/api-model';

export type ContractAmountType = 'week' | 'month' | 'year' | 'percent';

export interface ContractResponse extends ApiResponse {
    id: number;
    title?: string;
    amount_type: ContractAmountType;
    amount: number;
    percentage: number;
    week_hours: number;
    month_hours: number;
    year_hours: number;
    from: string;
    to?: string;
    type_id: number;
    customer_id: number;
    employee_id: number;
    type?: ContractTypeResponse;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export class Contract extends BaseApiModel<ContractResponse, Contract> {
    id: number;
    title?: string;
    amountType: ContractAmountType;
    amount: number;
    percentage: number;
    weekHours: number;
    monthHours: number;
    yearHours: number;
    from: DateTime;
    to?: DateTime | null;
    typeId: number;
    customerId: number;
    employeeId: number;
    type?: ContractType;
    createdAt?: DateTime;
    updatedAt?: DateTime;
    deletedAt?: DateTime | null;

    constructor(data: ContractResponse) {
        super(data, ApiModel.Contract);

        this.id = data.id;
        this.title = data.title;
        this.amountType = data.amount_type;
        this.amount = data.amount;
        this.percentage = data.percentage;
        this.weekHours = data.week_hours;
        this.monthHours = data.month_hours;
        this.yearHours = data.year_hours;
        this.from = stringToDateTime(data.from);
        this.to = data.to ? stringToDateTime(data.to) : null;
        this.typeId = data.type_id;
        this.customerId = data.customer_id;
        this.employeeId = data.employee_id;
        this.type = data.type ? new ContractType(data.type) : undefined;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : undefined;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : undefined;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }

    isActive(date: DateTime) {
        if (this.to) {
            return this.from <= date && this.to >= date;
        }

        return this.from <= date;
    }

    /**
     * Checks if the contract data is a full time contract based given inputs.
     * @param weekHoursForFullTime - Number of hours regarded as full time work for employees per week
     * @param workingAmount - Amount set in the contract (hours or percentage)
     * @param amountType - Type of the amount
     */
    static isFullTimeContract(weekHoursForFullTime: number, workingAmount: number, amountType: ContractAmountType) {
        const weekHoursMonthCoefficient = 13 / 3; // 4.333...
        const round = (number: number) => parseFloat(number.toFixed(2));

        switch (amountType) {
            case 'week':
                return workingAmount >= weekHoursForFullTime;
            case 'month':
                return workingAmount >= round(weekHoursForFullTime * weekHoursMonthCoefficient);
            case 'year':
                return workingAmount >= round(weekHoursForFullTime * weekHoursMonthCoefficient * 12);
            case 'percent':
                return workingAmount >= 100;
        }
    }
}
