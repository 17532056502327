import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class EmployeeGroupPropertyService {
    constructor(
      @Inject(HttpClient) protected http: HttpClient,
    ) { }

    delete(customerId: number, groupId: number, key: string) {
        return this.http.delete(`/customers/${customerId}/employee_groups/${groupId}/properties/${key}`);
    }
}
