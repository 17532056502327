import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormSubmissionService } from '../../http/form-submission.service';
import { EawForm } from '../../types/form';
import CustomerOld from '../../../shared/angularjs/customer-old';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { TranslateService } from '../../../shared/services/translate.service';
import { DateTime } from 'luxon';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { MatCardModule } from '@angular/material/card';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { catchError, EMPTY, of, shareReplay, switchMap, tap } from 'rxjs';
import { FormSubmission } from '../../models/form-submission';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';
import { FormSubmissionNoteDialogComponent, FormSubmissionNoteDialogData } from '../../dialogs/form-submission-note-dialog/form-submission-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { ShowFormSubmissionComponent, ShowFormSubmissionData } from '../../components/show-form-submission/show-form-submission.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { loadNamespaces } from 'i18next';
import { Namespace } from '../../../shared/enums/namespace';

@Component({
    selector: 'eaw-form-submission-list',
    templateUrl: './form-submission-list.component.html',
    standalone: true,
    imports: [
        MatCardModule,
        DataTableComponent,
        PageHeaderComponent,
        MatFormFieldModule,
        MatSelect,
        MatOption,
        TranslatePipe,
        AsyncPipe,
        ReactiveFormsModule,
        TranslateSyncPipe,
    ],
})
export class FormSubmissionListComponent implements EawDataTable, OnInit {
    private readonly formSubmissionService = inject(FormSubmissionService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly translate = inject(TranslateService);
    private readonly confirmDialog = inject(ConfirmDialogService);
    private readonly matDialog = inject(MatDialog);

    request?: DataTableRequest;
    columns: DataTableColumnType<FormSubmission>[] = [
        new DataTableTextColumn({
            value: async (cell) => {
                return cell.item.anonymous ? this.translate.t('ANONYMOUS') : cell.item.user?.name;
            },
            header: new DataTableHeader({
                i18n: 'SUBMITTED_BY',
                ns: 'forms',
            }),
        }),
        new DataTableTextColumn({
            value: (cell) => {
                return cell.item.createdAt?.toLocaleString(DateTime.DATETIME_MED) ?? '';
            },
            header: new DataTableHeader({
                i18n: 'SUBMITTED',
                ns: 'forms',
                sortBy: 'created_at',
            }),
        }),
        new DataTableDateTimeColumn({
            header: new DataTableHeader({ i18n: 'CLOSED_AT', ns: 'forms', sortBy: 'closed_at' }),
            value: 'closedAt',
            format: DateTime.DATETIME_MED,
        }),
        new DataTableDateTimeColumn({
            header: new DataTableHeader({ i18n: 'ANONYMIZED_AT', ns: 'forms', sortBy: 'anonymized_at' }),
            value: 'anonymizedAt',
            format: DateTime.DATETIME_MED,
        }),
        new DataTableNumberColumn({
            header: new DataTableHeader({ i18n: 'NOTE_plural', ns: 'forms' }),
            value: 'notesCount',
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'info',
                    click: (cell) => this.showSubmission(cell),
                    tooltip: { key: 'VIEW', ns: 'forms' },
                    show: () => of(true),
                },
                {
                    icon: 'comment',
                    tooltip: { key: 'NOTE_plural' },
                    click: (cell) => this.viewNotes(cell.item),
                    show: () => of(true),
                    nonBlocking: true,
                },
                {
                    icon: 'done',
                    type: 'ok',
                    click: (cell) => this.closeSubmission(cell),
                    show: (item) => {
                        if (item.closedAt) {
                            return of(false);
                        }
                        return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].forms.[${ApiModel.Form}].submissions.[${ApiModel.FormSubmission}].close`, {
                            stackId: this.customer['stack_id'],
                            models: [
                                { id: this.customer['id'], type: ApiModel.Customer },
                                { id: this.form.id, type: ApiModelClass.Form },
                                { id: item.id, type: ApiModelClass.FormSubmission },
                            ],
                        });
                    },
                    tooltip: { key: 'CLOSE', ns: 'forms' },
                },
                {
                    icon: 'person_off',
                    color: 'red-500',
                    click: (cell) => this.anonymizeSubmission(cell),
                    show: (item) => {
                        if (!item.closedAt || item.anonymizedAt) {
                            return of(false);
                        }
                        return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].forms.[${ApiModel.Form}].submissions.[${ApiModel.FormSubmission}].anonymize`, {
                            stackId: this.customer['stack_id'],
                            models: [
                                { id: this.customer['id'], type: ApiModel.Customer },
                                { id: this.form.id, type: ApiModelClass.Form },
                                { id: item.id, type: ApiModelClass.FormSubmission },
                            ],
                        });
                    },
                    tooltip: { key: 'ANONYMIZE', ns: 'forms' },
                },
            ],
        }),
    ];

    @Input() customer!: CustomerOld;
    @Input() form!: EawForm;
    @ViewChild(DataTableComponent) table!: DataTableComponent<FormSubmission>;

    filterForm = new FormGroup({ closed: new FormControl(false), anonymized: new FormControl(false) });

    async ngOnInit() {
        this.filterForm.valueChanges.subscribe(() => {
            this.updateTable(this.table.getPagination({ page: 1 }));
        });

        await loadNamespaces([ Namespace.Forms ]);
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.formSubmissionService.getAll(this.customer['id'], this.form.id, {
            ...pagination,
            'with[]': [ 'user' ],
            count: 'notes',
            isClosed: this.filterForm.controls.closed.value ?? undefined,
            isAnonymized: this.filterForm.controls.anonymized.value ?? undefined,
        }).pipe(shareReplay(1));

        return this.request;
    }

    showSubmission(cell: DataTableButtonCell<FormSubmission>) {
        this.formSubmissionService.get({
            customerId: this.customer['id'],
            formId: this.form.id,
            submissionId: cell.item.id,
            with: [ 'user' ],
        }).pipe(
            switchMap((sub) => {
                return this.matDialog.open<ShowFormSubmissionComponent, ShowFormSubmissionData, { anonymize: boolean }>(ShowFormSubmissionComponent, {
                    data: {
                        form: this.form,
                        submission: sub,
                    },
                }).afterClosed();
            }),
            tap(() => cell.disabled.set(false)),
            switchMap((result) => {
                return result?.anonymize ? this.formSubmissionService.anonymize(this.customer['id'], this.form.id, cell.item.id) : EMPTY;
            }),
            switchMap(() => this.updateTable(this.table.getPagination({ page: 1 }))),
        ).subscribe();
    }

    viewNotes(submission: FormSubmission) {
        this.matDialog.open(FormSubmissionNoteDialogComponent, {
            data: {
                customerId: this.customer['id'],
                submission,
                showHideCheckbox: true,
            } as FormSubmissionNoteDialogData,
        });
    }

    closeSubmission(cell: DataTableButtonCell<FormSubmission>) {
        this.confirmDialog.open({
            title: this.translate.t('CLOSE_SUBMISSION_TITLE', 'forms'),
            text: this.translate.t('CLOSE_SUBMISSION_TEXT', 'forms'),
            confirmText: this.translate.t('CLOSE', 'forms'),
        }).beforeClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.formSubmissionService.close(this.customer['id'], this.form.id, cell.item.id).pipe(catchError(() => of(null))).subscribe(() => {
                this.updateTable(this.table.getPagination({ page: 1 }));
            });
        });
    }

    anonymizeSubmission(cell: DataTableButtonCell<FormSubmission>) {
        this.confirmDialog.open({
            title: this.translate.t('ANONYMIZE_SUBMISSION_TITLE', 'forms'),
            text: this.translate.t('ANONYMIZE_SUBMISSION_TEXT', 'forms'),
            confirmText: this.translate.t('ANONYMIZE', 'forms'),
        }).beforeClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.formSubmissionService.anonymize(this.customer['id'], this.form.id, cell.item.id).pipe(catchError(() => of(null))).subscribe(() => {
                this.updateTable(this.table.getPagination({ page: 1 }));
            });
        });
    }
}
