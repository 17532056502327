import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { catchError, EMPTY } from 'rxjs';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { CustomerGroupAutocompleteService } from '../../../shared/autocompletes/customer-group-autocomplete.service';
import { CustomerGroup } from '../../../shared/models/customer-group';

export interface CustomerGroupDialogData extends DialogData {
    customerId: number;
    customerGroupIds: number[];
}

@Component({
    selector: 'eaw-add-customer-group-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ActionButtonComponent,
        MatDialogActions,
        TranslatePipe,
        AsyncPipe,
        ReactiveFormsModule,
        AutocompleteComponent,
        MatButton,
        MatDialogClose,
    ],
    templateUrl: './add-customer-group-dialog.component.html',
    styleUrl: './add-customer-group-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCustomerGroupDialogComponent extends DialogComponent<CustomerGroupDialogData> {
    private destroyedRef = inject(DestroyRef);
    customerGroupAutocompleteService = inject(CustomerGroupAutocompleteService);
    customerGroupService = inject(CustomerGroupService);

    loading = signal(false);

    form = new FormGroup({
        customerGroup: new FormControl<CustomerGroup | null>(null, Validators.required),
    });

    constructor() {
        super(undefined, undefined, DialogSize.Medium);
    }

    customerFilter = (customer: CustomerGroup) => this.data.customerGroupIds.includes(customer.id);

    submit(): void {
        const { customerGroup } = this.form.value;
        if (customerGroup) {
            this.loading.set(true);
            this.customerGroupService.addMember(+customerGroup.id, +this.data.customerId).pipe(
                takeUntilDestroyed(this.destroyedRef),
                catchError(() => {
                    this.loading.set(false);
                    return EMPTY;
                }))
                .subscribe(() => {
                    this.loading.set(false);
                    this.dialogRef.close([ ...this.data.customerGroupIds, customerGroup.id ]);
                });
        }
    }
}
