<eaw-dialog-header>
    <span *ngIf="!data.edit" title>{{'CREATE_TEMPLATE' | translate:'scheduling' | async}}</span>
    <span *ngIf="data.edit" title>{{'EDIT_TEMPLATE' | translate:'scheduling' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <mat-card id="overlap-info" class="tw-mb-20" *ngIf="overlapErrorText">
        <mat-card-content class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-8">
            <div class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-8">
                <mat-icon>tips_and_updates</mat-icon>
                <div class="tw-flex tw-flex-col tw-gap-8">
                    <span class="mat-body-2">{{overlapErrorText}}</span>
                    <button mat-raised-button color="primary" (click)="autoAdjust()">{{'ADJUST_FOR_DST_AUTO' | translate:'scheduling' | async}}</button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <form [formGroup]="formGroup" class="tw-grid tw-grid-cols-1 tw-gap-8">
        <mat-form-field class="tw-col-span-full">
            <mat-label>{{'NAME' | translate | async}}</mat-label>
            <input matInput formControlName="name" autocomplete="off">
        </mat-form-field>

        <mat-form-field class="tw-col-span-full" eawDatePickerOptions>
            <mat-label>{{'START_DATE' | translate | async}}</mat-label>
            <input matInput formControlName="startDate" [matDatepickerFilter]="dateFilter" [matDatepicker]="$any(picker)" autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="tw-col-span-full">
            <mat-label>{{'DURATION' | translate | async}}</mat-label>
            <input matInput formControlName="duration" autocomplete="off" type="number" min="1" step="1">
            <span matTextSuffix>{{('WEEK' | translate:'general':{count: formGroup.get('duration')?.value || 0}) | async | lowercase}}</span>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-raised-button [mat-dialog-close] [disabled]="formGroup.disabled">{{'CLOSE' | translate | async}}</button>

    <button mat-button mat-raised-button color="primary" [disabled]="formGroup.invalid || !!overlapErrorText || formGroup.disabled" (click)="handle()">
        <span *ngIf="!data.edit">{{'CREATE' | translate | async}}</span>
        <span *ngIf="data.edit">{{'UPDATE' | translate | async}}</span>
    </button>
</mat-dialog-actions>
