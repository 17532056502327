import { User, UserResponse } from '../../shared/models/user';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface UserAccessResponse extends ApiResponse {
    id: number;
    customer_id: number;
    user_id: number;
    user: Omit<UserResponse, 'id'>;
    from: string;
    to?: string | null;
    deleted_at?: string | null;
}

export class UserAccess {
    id: number;
    customerId: number;
    userId: number;
    user: User;
    from: DateTime;
    to?: DateTime;
    deletedAt?: DateTime;

    constructor(data: UserAccessResponse) {
        this.id = data.id;
        this.customerId = data.customer_id;
        this.userId = data.user_id;
        this.user = new User({
            email: '',
            last_name: '',
            first_name: '',
            language_code: '',
            name: '',
            ...data.user,
            id: this.userId,
        });
        this.from = stringToDateTime(data.from);
        this.to = data.to ? stringToDateTime(data.to) : undefined;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : undefined;
    }

    get isActive() {
        const now = DateTime.now();
        const fakeTo = this.to || now.plus({ year: 1 });
        return now >= this.from && now <= fakeTo;
    }

    get isFuture() {
        return this.from > DateTime.now();
    }
}
