<eaw-widget-settings-dialog [widgetInfo]="data.widgetInfo" [valid]="day.valid" (submitted)="submitted()">
    <div content class="tw-flex tw-flex-col">
        <mat-form-field>
            <mat-label>{{'PERIOD' | translate | async}}</mat-label>
            <mat-select [formControl]="day">
                <mat-option *ngFor="let day of days()" [value]="day">{{day.text | async}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</eaw-widget-settings-dialog>
