// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<eaw-year-month-old ng-if="$ye.months" ng-repeat="month in ::$ye.months track by month.index" year="$ye.year" month="month.index"></eaw-year-month-old>
`;

module('eaw.calendar2').component('eawYearCalendar', {
    template: template1,
    controllerAs: '$ye',
    bindings: {
        year: '<',
    },
    require: {
        calendar: '?^eawCalendar2',
    },
    controller: [ '$element', '$scope', function($element, $scope) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.element = $element[0];
            ctrl.weekdays = moment.weekdaysMin(true);

            ctrl.setMonths();
        };

        ctrl.$postLink = () => {
            ctrl.calendar.onEventsChange($scope, ctrl.highlightDays);
        };

        ctrl.$onChanges = (changes) => {
            ctrl.year = changes?.year?.currentValue;
        };

        ctrl.highlightDays = () => {
            const days = new Set();
            ctrl.calendar.events.forEach((event) => {
                const start = event.start.clone();

                while (start.year() === ctrl.year && start.isSameOrBefore(event.end, 'd')) {
                    days.add(start.dayOfYear());
                    start.add(1, 'd');
                }
            });

            days.forEach((dayNr) => {
                document.querySelector(`[data-day-of-year="${dayNr}"]`).classList.add('has-event');
            });
        };

        ctrl.setMonths = () => {
            ctrl.months = moment.months().reduce((arr, month, i) => {
                arr.push({
                    index: i,
                    name: month,
                });

                return arr;
            }, []);
        };

        /**
         * @param {HTMLElement} el
         * @param {Integer} [fallback=1]
         * @returns {number}
         */
        ctrl.getElementDayOfYear = (el, fallback) => {
            const day = el.closest('eaw-year-day');

            if (day) {
                return parseInt(day.dataset.dayOfYear);
            }

            return fallback || 1;
        };
    } ],
});
