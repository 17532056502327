import { Component, Inject, ViewChild } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { Availability } from '../../models/availability';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CreateAvailabilityComponent } from '../../components/create-availability/create-availability.component';
import { AvailabilityCreate } from '../../http/availability.service';
import { DateTime } from 'luxon';
import { AvailabilityDayType } from '../../enums/availability-day-type';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export type CreateAvailabilityDialogReturn = Availability | AvailabilityCreate | undefined;

export interface CreateAvailabilityDialogData extends DialogData {
    employeeId: number,
    customerId: number,
    // Returns the data without sending a request to the server
    returnFormData?: boolean,
    defaultAvailabilityDayType?: AvailabilityDayType,
    // Override and set "from" and "to" to a specific date range, unless an availability is provided
    fromTo?: { from?: DateTime | null, to?: DateTime | null };
}

@Component({
    selector: 'eaw-create-availability-dialog',
    templateUrl: './create-availability-dialog.component.html',
    styleUrl: './create-availability-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        CreateAvailabilityComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class CreateAvailabilityDialogComponent extends DialogComponent<CreateAvailabilityDialogData, CreateAvailabilityDialogReturn> {
    @ViewChild('createAvailability') createAvailabilityForm?: CreateAvailabilityComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateAvailabilityDialogData,
        @Inject(MatDialogRef) ref: MatDialogRef<CreateAvailabilityDialogComponent, Availability>,
    ) {
        data.size = DialogSize.Large;
        super(ref, data);
    }

    submit(): void {
        this.createAvailabilityForm?.create(this.data.returnFormData).subscribe((result) => {
            this.dialogRef.close(result satisfies CreateAvailabilityDialogReturn);
        });
    }
}
