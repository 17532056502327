// @ts-nocheck
import { module } from 'angular';
import { CommentDialogComponent, CommentDialogData } from '../../../../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { of } from 'rxjs';
import { Comment } from '../../../../../../shared/models/comment';
import { MatDialog } from '@angular/material/dialog';

module('eaw.scheduling').component('sidebarAbsence', {
    template: `<md-subheader class="sidebar-absence-header" ng-show="$ctrl.list.loaded">
    <span ng-i18next="[i18next]({count: $ctrl.list.getLength()})scheduling:X_ABSENCE"></span>
</md-subheader>

<md-virtual-repeat-container>
    <div class="sidebar-absence-item" layout="row" layout-align="space-between center" md-virtual-repeat="absence in $ctrl.list" md-on-demand>
        <div layout="column" ng-show="$ctrl.list.loaded">
            <div layout="row" layout-align="start center">
                <md-icon class="s18 status-icon" md-colors="{color: absence.icon.color}" md-svg-src="{{absence.icon.name}}"></md-icon>
                <span class="abs-emp-name" ng-bind="absence.employee.name"></span>
            </div>

            <span class="abs-from-to" ng-i18next="[i18next]({from: absence.fromFormatted, to: absence.toFormatted})FROM_TO"></span>
            <span class="abs-type-name">{{absence.type.name | eawTranslate:'absence_types'}}</span>
            <span class="abs-type-name" ng-bind="absence.grade | percent"></span>
        </div>

        <eaw-icon-button ng-if="absence.comments.length" ng-click="$ctrl.showComments(absence)" icon="comment"></eaw-icon-button>
    </div>
    <md-progress-circular ng-if="$ctrl.list.isLoading()" class="tw-mt-16 md-progress-center"></md-progress-circular>
</md-virtual-repeat-container>
`,
    bindings: {
        customer: '<',
        schedule: '<',
        days: '<',
    },
    require: {
        sidebar: '^scheduleSidebar',
    },
    controller: [ '$scope', 'EawResource', 'EndlessList', 'MatDialogDowngrade', function($scope, EawResource, EndlessList, MatDialogDowngrade: MatDialog) {
        const ctrl = this;
        ctrl.$onInit = () => {
            $scope.$on('sidebarchildren:dayChanged', ctrl.dayChange);
            ctrl.dayChange();
        };
        ctrl.dayChange = () => {
            ctrl.list = new EndlessList(ctrl.getAbsences, {
                per_page: 10,
                'with[]': [ 'comments', 'approval', 'type', 'employee' ],
                order_by: 'from',
                direction: 'asc',
            });
            ctrl.list.loadPage(1);
        };
        ctrl.showComments = (absence) => {
            MatDialogDowngrade.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
                data: {
                    comments: of(absence.comments.map((c) => new Comment(c))),
                },
            })
        };
        ctrl.getAbsences = (pagination) => EawResource.create(`/customers/${ctrl.customer.id}/absences`).get({
            from: ctrl.sidebar.day.moment.clone().startOf('d'),
            to: ctrl.sidebar.day.moment.clone().endOf('d'),
            exclude_declined: true,
            ...pagination,
        }).$promise.then((resp) => {
            resp.data = resp.data.map((abs) => {
                if (abs.approval) {
                    abs.icon = abs.approval.approved ? {
                        name: 'checkbox-marked',
                        color: 'green',
                    } : {
                        name: 'close-box',
                        color: 'red',
                    };
                } else {
                    abs.icon = {
                        name: 'help-box',
                        color: 'grey',
                    };
                }
                const sameDay = abs.to.isSame(abs.from, 'd');
                const isHour = abs.type.span === 'hour';
                if (isHour) {
                    abs.fromFormatted = abs.from.format('lll');
                    abs.toFormatted = sameDay ? abs.to.format('LT') : abs.to.format('lll');
                } else {
                    abs.fromFormatted = abs.from.format('ll');
                    abs.toFormatted = abs.to.format('ll');
                }
                return abs;
            });
            return resp;
        });
    } ],
});
