<eaw-page-header>
    <span class="tw-pr-8" title>{{ 'MY_FORM_SUBMISSIONS' | translate: 'forms' | async }}</span>

    <div smallFilter>
        <form [formGroup]="formGroup" class="tw-flex tw-gap-8">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>{{ 'FORM' | translate | async }}</mat-label>
                <mat-select formControlName="formSelect">
                    <mat-option>{{ 'NONE' | translateSync }}</mat-option>
                    @for (form of forms; track form.id) {
                        <mat-option [value]="form.id">{{ form.name }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic">
                <mat-label>{{ 'STATUS' | translate | async }}</mat-label>
                <mat-select formControlName="closedFilter">
                    <mat-option [value]="false">{{ 'OPEN' | translateSync }}</mat-option>
                    <mat-option [value]="true">{{ 'CLOSED' | translateSync: 'forms' }}</mat-option>
                    <mat-option value="undefined">{{ 'ALL' | translateSync }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</eaw-page-header>

<mat-card class="mat-compact-card">
    <mat-card-content>
        <eaw-data-table [columns]="columns" [request]="request" (paginationChange)="updateTable($event)"/>
    </mat-card-content>
</mat-card>
