import { Component, Inject, Input } from '@angular/core';
import { t } from 'i18next';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UIRouter } from '@uirouter/core';
import { HttpClient } from '@angular/common/http';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'eaw-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: [ './verify-email.component.angular.scss', '../../../../styles/login.scss' ],
    standalone: true,
    imports: [
        MatCardModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class VerifyEmailComponent {
    @Input() token?: string;
    @Input() email?: string;
    submitting = false;

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(MatSnackBar) protected snackbar: MatSnackBar,
        @Inject(UIRouter) protected uiRouter: UIRouter,
    ) {
    }

    submit() {
        this.submitting = true;
        this.http.put<{ email: string }>('/users/verify_email', {
            token: this.token,
            email: this.email,
        }).subscribe({
            next: (resp) => {
                this.snackbar.open(t('NEW_EMAIL', { mail: resp.email }));
                this.uiRouter.stateService.go('eaw/outside/login', { email: resp.email });
            },
            error: (e: unknown) => {
                this.submitting = false;
                console.error(e);
            },
        });
    }
}
