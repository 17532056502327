import { Component, Inject, Input, OnInit } from '@angular/core';
import { AvailabilityService } from '../../http/availability.service';
import { Availability } from '../../models/availability';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { Namespace } from '../../../shared/enums/namespace';
import { MatDialog } from '@angular/material/dialog';
import { CreateAvailabilityDialogComponent, CreateAvailabilityDialogData } from '../../dialogs/create-availability/create-availability-dialog.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { AvailabilityDaysComponent } from '../../components/availability-days/availability-days.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { forkJoin, map } from 'rxjs';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { SettingService } from '../../../shared/http/setting.service';

@Component({
    selector: 'eaw-current-availability',
    templateUrl: './current-availability.component.html',
    styleUrl: './current-availability.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        NgIf,
        MatButtonModule,
        MatIconModule,
        AvailabilityDaysComponent,
        MatIconSizeDirective,
        MatProgressSpinnerModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class CurrentAvailabilityComponent implements OnInit {
    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) employeeId!: number;

    loading = true;
    availability?: Availability;
    canCreate = false;

    constructor(
        @Inject(AvailabilityService) private availabilityService: AvailabilityService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(SettingService) private settingService: SettingService,
    ) { }

    ngOnInit(): void {
        forkJoin([
            this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.availabilities.create`),
            this.settingService.getValue<number | null>([ 'customers', this.customerId ], 'france.use_combined_contract', 0).pipe(map((value) => !!value)),
        ]).subscribe(([ canCreate, useCombinedContract ]) => {
            this.canCreate = canCreate && !useCombinedContract;
        });

        this.availabilityService.getActiveAvailability(this.customerId, this.employeeId).subscribe((value) => {
            this.availability = value;
            this.loading = false;
        });
    }

    openDialog() {
        this.matDialog.open<CreateAvailabilityDialogComponent, CreateAvailabilityDialogData, Availability>(CreateAvailabilityDialogComponent, {
            data: {
                employeeId: this.employeeId,
                customerId: this.customerId,
            },
        }).afterClosed().subscribe((availability) => {
            if (!availability) {
                return;
            }

            void this.snackBarService.t('AVAILABILITY_SUBMITTED', Namespace.Availabilities);
        });
    }
}
