<eaw-dialog-header>
    <span title>{{'CREATE_SCHEDULE' | translate:'scheduling' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <mat-slide-toggle [formControl]="createEmpty">{{'CREATE_EMPTY_SCHEDULE' | translate:'scheduling' | async}}</mat-slide-toggle>

    <form [formGroup]="activeForm" autocomplete="off">
        <mat-form-field >
            <mat-label>{{'NAME' | translate | async}}</mat-label>
            <input matInput formControlName="name" autocomplete="off">
        </mat-form-field>

        <eaw-basic-autocomplete
                *ngIf="useTemplate"
                [label]="'TEMPLATE' | translate:'scheduling'"
                [getList]="getTemplates"
                formControlName="template">
        </eaw-basic-autocomplete>

        <mat-form-field  *ngIf="!useTemplate" eawDatePickerOptions>
            <mat-label>{{'ENTER_DATE_RANGE' | translate | async}}</mat-label>
            <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
                <input formControlName="from" matStartDate [placeholder]="'START_DATE' | translate | async">
                <input formControlName="to" matEndDate [placeholder]="'END_DATE' | translate | async">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field *ngIf="useTemplate"  eawDatePickerOptions>
            <mat-label>{{'DATE' | translate | async}}</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <eaw-info-box color="blue" *ngIf="dstTransition">
            <span infoText>{{'SCHEDULE_DST_INFO_MSG' | translate: 'scheduling' | async}}</span>
        </eaw-info-box>
    </form>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-raised-button [mat-dialog-close]="undefined">{{'CLOSE' | translate | async}}</button>
    <button mat-button mat-raised-button color="primary" [disabled]="activeForm.invalid" (click)="create()">{{'CREATE' | translate | async}}</button>
</mat-dialog-actions>
