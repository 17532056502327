// @ts-nocheck
import { module } from 'angular';
module('eaw.scheduling').service('TimesTableRenderer', [ 'scheduleDays', function TimesTableRenderer(scheduleDays) {
    const service = this;
    function addHolidayClass(el, day) {
        if (day.holiday) {
            el.classList.add('holiday');
        }
    }
    function getDate(day) {
        return scheduleDays.isTemplate() ? day.templateLabel : day.date;
    }
    function createTemplateDate(dayContainer, day) {
        const templateDate = document.createElement('small');
        templateDate.classList.add('template-date');
        templateDate.textContent = `(${day.date})`;
        dayContainer.appendChild(templateDate);
    }
    service.renderDays = (schedule) => {
        const $container = document.getElementById('schedule-table-days');
        if (!$container) {
            return;
        }
        scheduleDays.generateRender();
        const swissTemplate = schedule.is_template && schedule.customer.hasProduct('Automatic Scheduling') && schedule.customer.hasProduct('Switzerland');
        const tr = document.createElement('tr');
        scheduleDays.getAllRender().forEach((day) => {
            const td = document.createElement('td');
            td.colSpan = day.colspan;
            addHolidayClass(td, day);
            const dayContainer = document.createElement('div');
            dayContainer.classList.add('day-container', 'overflow-ellipsis');
            const dayName = document.createElement('strong');
            dayName.classList.add('day-name');
            dayName.textContent = day.dayName;
            const dayDate = document.createElement('span');
            dayDate.classList.add('day-date');
            dayDate.textContent = getDate(day);
            tr.appendChild(td);
            td.appendChild(dayContainer);
            dayContainer.appendChild(dayName);
            dayContainer.appendChild(dayDate);
            if (day.dstMessage) {
                const messageSmall = document.createElement('small');
                messageSmall.classList.add('c-red');
                messageSmall.classList.add('tw-ml-4');
                messageSmall.textContent = day.dstMessage;
                dayContainer.appendChild(messageSmall);
                console.log(`dayContainer`, dayContainer);
            }
            if (swissTemplate) {
                createTemplateDate(dayContainer, day);
            }
        });
        // Remove previous children if any before appending
        Array.from($container.children).forEach((c) => c.remove());
        $container.appendChild(tr);
    };
    service.renderTimes = () => {
        const $container = document.getElementById('schedule-table-times');
        if (!$container) {
            return;
        }
        scheduleDays.generateRender();
        let html = `<tr>`;
        scheduleDays.getAllTimesDoubleRender().forEach((time) => {
            html += `<td class="time-label" title="${time.info || ''}" colspan="${time.labelSpan}">${time.label}</td>`;
        });
        html += `</tr>`;
        $container.innerHTML = html;
    };
    service.render = (schedule) => {
        service.renderTimes();
        service.renderDays(schedule);
    };
    return service;
} ]);
