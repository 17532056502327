// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
const template2 = `<md-dialog>
    <eaw-dialog-header-old heading="'checklists:WHEN_TO_START' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="skipped" id="skipped" ng-cloak ng-submit="skip.submit()" autocomplete="off" layout="row">
                <md-input-container flex>
                    <label for="skippedSelect" ng-i18next="MONTH"></label>
                    <md-select id="skippedSelect" ng-model="skip.skipped">
                        <md-option ng-repeat="option in skip.options" ng-value="option.value">
                            {{::option.text}}
                        </md-option>
                    </md-select>
                </md-input-container>
                <md-input-container flex>
                    <label for="date" ng-i18next="DATE"></label>
                    <md-select id="date" ng-model="skip.date">
                        <md-option ng-repeat="date in skip.dates track by $index" ng-value="$index + 1">
                            {{::$index + 1}}
                        </md-option>
                    </md-select>
                </md-input-container>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button form="skipped" ng-disabled="skipped.$invalid" type="submit" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>

</md-dialog>
`;

module('eaw.time').component('autoStartSettings', {
    template: `<form id="autostart" name="autostart" ng-submit="$autoCtrl.submitForm()" layout="column">
    <div layout="row" layout-wrap>
        <customer-user-autocomplete
                flex="100"
                flex-gt-xs="33"
                ng-show="$autoCtrl.hasResponsible"
                label="RESPONSIBLE"
                ng-model="$autoCtrl.user">
        </customer-user-autocomplete>

        <md-input-container flex="100" flex-gt-xs="33">
            <label for="quick-choices" ng-i18next="AUTO_START"></label>
            <md-select id="quick-choices" flex ng-model="$autoCtrl.selectedOption" ng-change="$autoCtrl.optionChanged()">
                <md-option ng-repeat="day in $autoCtrl.weekdays track by $index" ng-value="day.no">
                    <span ng-i18next="[i18next]({name: day.name})EVERY_N"></span>
                </md-option>
                <md-option ng-value="'month-start'">
                    <span ng-i18next="START_OF_EACH_MONTH"></span>
                </md-option>
                <md-option ng-value="'month-start-1'">
                    <span ng-i18next="EVERY_OTHER_MONTH"></span>
                </md-option>
                <md-option ng-value="'month-start-2'">
                    <span ng-i18next="EVERY_THIRD_MONTH"></span>
                </md-option>
                <md-option ng-value="'month-start-3'">
                    <span ng-i18next="EVERY_SIXTH_MONTH"></span>
                </md-option>
                <md-option ng-value="'every-day'">
                    <span ng-i18next="EVERY_DAY"></span>
                </md-option>
                <md-option ng-value="'custom'">
                    <span ng-i18next="CUSTOM"></span>
                </md-option>
            </md-select>
        </md-input-container>

        <!-- If day is number, show timepicker, default 6:00 -->
        <md-input-container>
            <eaw-time-picker-old
                    flex="100"
                    flex-gt-xs="33"
                    ng-model="$autoCtrl.time"
                    ng-if="$autoCtrl.selectedOption != 'custom'"
                    required>
            </eaw-time-picker-old>
        </md-input-container>
    </div>

    <!-- Show a preview of the cycle here if custom -->
    <div>
        <md-button layout-align="start start" class="md-raised md-accent" type="submit" ng-i18next="SUBMIT"></md-button>
    </div>
</form>
`,
    bindings: {
        onSubmit: '&', // onSubmit(autoStart)
        hasResponsible: '<?', // boolean if there should be a field for (responsible) user
    },
    controller: 'AutoStartChecklistCtrl',
    controllerAs: '$autoCtrl',
});

module('eaw.time').controller('AutoStartChecklistCtrl', [ 'autoStartDialog', '$mdDialog', function AutoStartCtrl(autoStartDialog, $mdDialog) {
    const ctrl = this;
    let _type = 'weekly';
    ctrl.selectedOption = 'every-day';
    ctrl.time = moment().hour(6).minutes(0);

    ctrl.$onInit = () => {
        // Iso weekdays
        ctrl.weekdays = new Array(7).fill(0).map((_, index) => {
            return {
                no: index + 1,
                name: moment().isoWeekday(index + 1).format('dddd'),
            };
        });
    };

    ctrl.optionChanged = () => {
        if (ctrl.selectedOption === 'custom') {
            ctrl.openModal();
        }
    };

    ctrl.submitForm = () => {
        _type = 'weekly';
        let skip = 0;
        switch (ctrl.selectedOption) {
            case 'every-day':
                ctrl.autoStart = ctrl.weekdays.reduce((obj, day) => Object.assign(obj, generateDay(day.no)), {});
                break;
            case 'month-start':
                _type = 'monthly';
                ctrl.autoStart = generateDay(1);
                break;
            case 'month-start-1':
                skip = 1;
                _type = 'monthly';
                ctrl.autoStart = generateDay(1);
                break;
            case 'month-start-2':
                skip = 2;
                _type = 'monthly';
                ctrl.autoStart = generateDay(1);
                break;
            case 'month-start-3':
                skip = 5;
                _type = 'monthly';
                ctrl.autoStart = generateDay(1);
                break;
            case 'custom':
                return ctrl.openModal();
            default:
                ctrl.autoStart = generateDay(ctrl.selectedOption);
                break;
        }

        return submit({
            type: _type,
            data: { ...ctrl.autoStart },
            skip,
        });
    };

    ctrl.openModal = () => autoStartDialog().then((result) => {
        submit(result);
    });

    function submit(autoStart) {
        if (ctrl.user) {
            autoStart.user_id = ctrl.user.id;
        }

        if (autoStart.skip) {
            $mdDialog.show({
                template: template2,
                controllerAs: 'skip',
                controller: function() {
                    const ctrl = this;
                    ctrl.cancel = $mdDialog.cancel;

                    ctrl.$onInit = () => {
                        ctrl.dates = new Array(31);
                        ctrl.date = 1;
                        ctrl.options = [];
                        // skip is max for skipped
                        for (let i = 0; i <= autoStart.skip; i++) {
                            const date = moment().month(moment().month() + autoStart.skip - i + 1).startOf('M');
                            const text = date.format('MMMM Y');
                            ctrl.options.push({
                                value: {
                                    index: i,
                                    date,
                                },
                                text,
                            });
                        }
                        ctrl.skipped = ctrl.options[0].value;
                    };

                    ctrl.submit = () => {
                        $mdDialog.hide({
                            skipped: ctrl.skipped.index,
                            date: ctrl.date,
                        });
                    };
                },
            }).then((result) => {
                if (result.date !== 1) {
                    autoStart.data[result.date] = autoStart.data[1];
                    delete autoStart.data[1];

                    if (result.skipped === autoStart.skip && moment().date(result.date).isAfter(moment())) {
                        // Make sure it starts next month, and not the xth this month
                        --result.skipped;
                    }
                }

                autoStart.skipped = result.skipped;
                ctrl.onSubmit({ autoStart });
            });
        } else {
            ctrl.onSubmit({ autoStart });
        }
    }

    function generateDay(number) {
        const day = {};
        day[number] = ctrl.time.format('HH:mm');
        return day;
    }
} ]);
