<eaw-page-header *ngIf="!employee">
    <span title>{{'MANUAL_PAYROLL' | translate:'navigation' | async}}</span>
</eaw-page-header>

<mat-card id="filter-card" class="tw-mb-12" *ngIf="!fetchingInitialData">
    <mat-card-content>
        <form class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-items-start tw-gap-12" [formGroup]="formGroup">
            <mat-form-field *ngIf="!employee">
                <mat-label>{{'MODE' | translate | async}}</mat-label>
                <mat-select formControlName="mode">
                    <mat-option [value]="mode.value" *ngFor="let mode of modes">{{mode.label | translateSync: 'payroll'}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field eawDatePickerOptions>
                <mat-label>{{'ENTER_DATE_RANGE' | translate | async}}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
                    <input formControlName="from" matStartDate [placeholder]="'START_DATE' | translate | async">
                    <input formControlName="to" matEndDate [placeholder]="'END_DATE' | translate | async">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-hint *ngIf="intervalTooSmall">{{'MANUAL_PAYROLL_INTERVAL_HELP' | translate:'payroll' | async}}</mat-hint>
            </mat-form-field>

            <mat-form-field *ngIf="!employee" [class.tw-hidden]="formGroup.controls.mode.value !== 'paytype'">
                <mat-label>Pay type</mat-label>
                <input type="text" placeholder="Pick one" matInput formControlName="payType" [matAutocomplete]="payTypeAuto">
                <mat-autocomplete [displayWith]="autocompleteNameDisplay" #payTypeAuto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredPayTypeLinks | async" [value]="option">
                        <div class="tw-flex tw-flex-col">
                            <span>{{option.name}}</span>
                            <small style="margin-top: -6px; color: #979797">{{option.returnUnit.name.translationKey | translate:option.returnUnit.name.translationNs | async}}</small>
                        </div>
                    </mat-option>
                </mat-autocomplete>

                <mat-hint>{{'MANUAL_PAYROLL_CHOOSE_PAYTYPE_HELP' | translate:'payroll' | async}}</mat-hint>
            </mat-form-field>

            <mat-form-field *ngIf="!employee" [class.tw-hidden]="formGroup.controls.mode.value !== 'employee'">
                <mat-label>{{'EMPLOYEE' | translate | async}}</mat-label>
                <input type="text" placeholder="Pick one" matInput formControlName="employee" [matAutocomplete]="employeeAuto">
                <mat-autocomplete [displayWith]="autocompleteNameDisplay" #employeeAuto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredEmployees | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>

                <mat-hint>{{'MANUAL_PAYROLL_CHOOSE_EMPLOYEE_HELP' | translate:'payroll' | async}}</mat-hint>
            </mat-form-field>
        </form>

    </mat-card-content>
</mat-card>

<div *ngIf="fetchingInitialData || fetchingOverview" class="tw-flex tw-flex-col tw-gap-12 tw-justify-center tw-items-center">
    <mat-progress-spinner mode="indeterminate" diameter="36" strokeWidth="3"></mat-progress-spinner>
    <span class="mat-subtitle-2" *ngIf="fetchingInitialData">{{'FETCHING_DATA' | translate | async}}</span>
    <span class="mat-subtitle-2" *ngIf="fetchingOverview">{{'FETCHING_MANUAL_OVERVIEW_FOR' | translate:'payroll':{name: fetchingName} | async}}</span>
</div>

<eaw-info-box *ngIf="!modes.length && !fetchingInitialData" color="yellow" icon="error">
    <span infoText>You don't have sufficient permissions to handle manual pay</span>
    <!--    <span infoText>{{ 'MISMATCHED_EMPLOYEE_DESCRIPTION' | translate: 'payslip_import' | async }}</span>-->
</eaw-info-box>

<ng-container *ngIf="overview">
    <mat-card *ngIf="!employee" class="tw-mb-12">
        <mat-card-content class="tw-flex tw-flex-col" *ngIf="overview.payTypeLink">
            <span class="mat-subtitle-2">{{overview.payTypeLink.name}}</span>
            <eaw-variable-payment-display [number]="overview.total" [payTypeLink]="overview.payTypeLink"></eaw-variable-payment-display>
        </mat-card-content>

        <mat-card-content class="tw-flex tw-gap-8 tw-items-center" *ngIf="overview.employee">
            <eaw-profile-picture [user]="{id: overview.employee.userId, name: overview.employee.name}"></eaw-profile-picture>
            <span class="mat-subtitle-2">{{overview.employee.name}}</span>
        </mat-card-content>
    </mat-card>

    <mat-accordion multi *ngIf="overview.payTypeLink">
        <mat-expansion-panel *ngFor="let employee of employees" eawPermission [permissions]="permissions.get(employee.id)?.()">
            <mat-expansion-panel-header>
                <mat-panel-title>{{employee.name}}</mat-panel-title>
                <mat-panel-description>
                    <eaw-variable-payment-display [number]="this.employeeTotals[employee.id]!" [payTypeLink]="overview.payTypeLink"></eaw-variable-payment-display>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <eaw-variable-payments-section [customerId]="customerId"
                                               mode="paytype"
                                               [employee]="employee"
                                               [from]="overview.from"
                                               [to]="overview.to"
                                               [overview]="overview.overview"
                                               [payTypeLink]="overview.payTypeLink"
                                               (totalChange)="updateEmployeeTotals(employee.id, $event);"/>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion multi *ngIf="overview.employee">
        <mat-expansion-panel *ngFor="let link of payTypeLinks">
            <mat-expansion-panel-header>
                <mat-panel-title>{{link.name}}</mat-panel-title>
                <mat-panel-description>
                    <eaw-variable-payment-display [number]="this.payTypeLinkTotals[link.id]!" [payTypeLink]="link"></eaw-variable-payment-display>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <eaw-variable-payments-section [customerId]="customerId"
                                               mode="employee"
                                               [employee]="overview.employee"
                                               [from]="overview.from"
                                               [to]="overview.to"
                                               [overview]="overview.overview"
                                               [payTypeLink]="link"
                                               (totalChange)="this.payTypeLinkTotals[link.id] = $event"/>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
