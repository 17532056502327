<eaw-page-header>
    <span title>{{ 'SHIFT_SWAP_REQUEST_plural' | translate: 'scheduling' | async }}</span>
</eaw-page-header>

<mat-card class="mat-compact-card">
    <mat-card-content>
        <eaw-data-table
                initialSortBy="from"
                initialSortDirection="asc"
                [columns]="columns"
                [request]="request"
                (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>

