import { DateTime } from 'luxon';
import { Weekday } from '../../shared/types/weekday';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { BusinessDateString } from '../../shared/types/business-date';
import { BusinessDate } from '../../shared/utils/business-date';

type AutoSchedulingCheck = 'CheckAvailability' | 'CheckQualifications' | 'CheckMaxHoursPerDay' | 'CheckOverlap' | 'CheckMaxWorkDays' | 'CheckWeeklyBreak';

export interface AutoSchedulingResultResponse extends ApiResponse {
    job_id: string
    employee_id: number,
    employee_name: string,
    shift_id: number,
    date: BusinessDateString,
    day: Weekday,
    from: string,
    to: string,
    failed_checks: AutoSchedulingCheck[]
}

export class AutoSchedulingResult {
    employeeId: number;
    employeeName: string;
    shiftId: number;
    date: BusinessDate;
    from: DateTime;
    to: DateTime;
    failedChecks: AutoSchedulingCheck[];

    constructor(data: AutoSchedulingResultResponse) {
        this.employeeId = data.employee_id;
        this.employeeName = data.employee_name;
        this.shiftId = data.shift_id;
        this.date = new BusinessDate(data.date);
        this.from = stringToDateTime(data.from);
        this.to = stringToDateTime(data.to);
        this.failedChecks = data.failed_checks;
    }
}
