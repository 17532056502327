import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { EawUrl } from '../angularjs/modules/resource/url.service';
import { IGNORE_ERROR } from '../http/http-contexts';
import { catchError, map, Observable, of, shareReplay } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProfilePictureService {
    private readonly http = inject(HttpClient);

    private observables = new Map<string, Observable<string>>();

    get(userId: number, thumb = true) {
        const key = `${userId}-${thumb}`;
        const existing = this.observables.get(key);
        if (existing) {
            return existing;
        }

        const observable = this.http.get(EawUrl.getUrl(`/users/${userId}/picture`), {
            responseType: 'blob',
            params: new HttpParams({ fromObject: { thumb } }),
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(
            catchError(() => of(null)),
            map((blob) => blob ? URL.createObjectURL(blob) : ''),
            shareReplay(1),
        );

        this.observables.set(key, observable);
        return observable;
    }
}
