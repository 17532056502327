// @ts-nocheck

import { module } from 'angular';
import moment from 'moment-timezone';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

class VacationDaysOverviewController {
    vacationDays;
    changeVacationDays;
    $state;

    static get $inject() {
        return [ 'vacationDays', 'changeVacationDays', '$state' ];
    }

    customer;
    includeInactive = false;
    employees = [];

    constructor(vacationDays, changeVacationDays, $state) {
        this.vacationDays = vacationDays;
        this.changeVacationDays = changeVacationDays;
        this.$state = $state;
    }

    $onInit() {
        this.getVacationDays();
    }

    changeDays(event, employee, change) {
        event.preventDefault();
        event.stopPropagation();
        this.changeVacationDays.open(employee, change).then(() => {
            this.getVacationDays();
        });
    }

    cantChangeDays(employee) {
        return !CurrentOld.can(`customers.${this.customer.id}.employees.${employee.id}.vacation_days.create`);
    }

    getVacationDays() {
        this.vacationDays.getForCustomer({
            customer: this.customer,
            include_inactive: this.includeInactive,
            per_page: 999,
        }).$promise.then((resp) => {
            this.employees = sort(resp.data, CurrentOld.languageTag, [ (x) => x.name ], [ 'asc' ]);
            this.employees.forEach((emp) => {
                emp.inactive = moment().isAfter(emp.to, 's') || moment().isBefore(emp.from, 's');
            });
        });
    }

    toggleInactive() {
        this.getVacationDays();
    }

    goToEmployee(employee) {
        this.$state.transitionTo('eaw/app/vacations/manage/days/employee', {
            customerid: employee.customer_id,
            id: employee.id,
        });
    }
}
module('eaw.vacation').component('vacationDaysOverview', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="vacation:VACATION_DAYS"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="$ctrl.includeInactive"
                        ng-change="$ctrl.toggleInactive()"
                        icon="history"
                        i18n-tooltip="company:INCLUDE_INACTIVE_EMPLOYEES">
        </eaw-toggle-btn>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <table class="table table-striped">
            <thead>
            <tr>
                <th ng-i18next="EMPLOYEE"></th>
                <th ng-i18next="vacation:VACATION_DAYS_LEFT" class="text-right"></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="employee in $ctrl.employees" ng-class="{inactive: employee.inactive}" ng-click="$ctrl.goToEmployee(employee)" style="cursor: pointer">
                <td ng-bind="employee.name"></td>
                <td ng-bind="employee.vacation_days" class="text-right"></td>
                <td class="text-center">
                    <eaw-icon-button icon="add" type="accent" eaw-invisible="{{::$ctrl.cantChangeDays(employee)}}" ng-click="$ctrl.changeDays($event, employee, 1)"></eaw-icon-button>
                    <eaw-icon-button icon="remove" type="warn" eaw-invisible="{{::$ctrl.cantChangeDays(employee)}}" ng-click="$ctrl.changeDays($event, employee, -1)"></eaw-icon-button>
                </td>
            </tr>
            </tbody>
        </table>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
    },
    controller: VacationDaysOverviewController,
});
