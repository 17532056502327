import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { Widget } from '../../classes/widget';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { take } from 'rxjs';
import { AlertsService } from '../../../shared/http/alerts.service';
import { CurrentService } from '../../../shared/services/current.service';
import { TranslateService } from '../../../shared/services/translate.service';

@Component({
    selector: 'eaw-mini-skeleton-widget',
    templateUrl: './mini-employee-alerts-widget.component.html',
    styleUrls: [ './mini-employee-alerts-widget.component.scss' ],
    standalone: true,
    imports: [ MiniWidgetContentComponent ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniEmployeeAlertsWidgetComponent extends WidgetComponent implements OnInit {
    private alertService = inject(AlertsService);
    private translate = inject(TranslateService);
    private current = inject(CurrentService);

    widget = input.required<Widget>();

    text = signal(Promise.resolve(''));
    subtext = signal(Promise.resolve(''));

    ngOnInit() {
        this.loadAlerts();

        this.onExtendedInfoClosed().subscribe(() => {
            this.loadAlerts();
        });
    }

    loadAlerts() {
        this.alertService.getAlerts(this.current.getCustomer().id, {
            per_page: 1,
        }).pipe(take(1)).subscribe((res) => {
            this.text.set(this.translate.t('X_ALERTS', 'alerts', { count: res.total }));
            this.setLoading(false);
        });
    }
}
