import { inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { catchError, map, Observable, of, Subject } from 'rxjs';
import { expandAllPages } from '../../shared/utils/rxjs/expand-all-pages';
import { PaidTimeService } from '../../payroll/http/paid-time.service';
import { TimepunchService } from '../../payroll/http/timepunch.service';
import { KpiTypeService } from '../../kpi/http/kpi-type.service';
import { KpiType } from '../../kpi/models/kpi-type';
import { KpiService } from '../../kpi/http/kpi.service';
import { ShiftService } from '../../scheduling/http/shift.service';

interface DashboardGetter {
    timeout?: number;
    subject?: Subject<any>;
    data: unknown[];
}

@Injectable({
    providedIn: 'root',
})
export class DashboardStoreService {
    private kpiService = inject(KpiService);
    private shiftService = inject(ShiftService);
    private kpiTypeService = inject(KpiTypeService);
    private paidTimeService = inject(PaidTimeService);
    private timepunchService = inject(TimepunchService);

    private readonly defaultTimeout = 500;
    private stores: Map<string, DashboardGetter> = new Map();

    /**
     * Generic getter function to fetch data from whatever place.
     *
     * It is debounced, so it can receive multiple parameters and only fetch the data once based on all the parameters, or only the relevant ones.
     *
     * You can use a pipe on the getter function to filter the data from the getter based on the parameters that was passed in.
     *
     * @param key - Identifier for that debounced piece of data. It should not be too specific, as it will be used to group similar data.
     * @param data - The data that is passed in to that specific call. It will be collected with other calls and data and everything will be returned in the getter callback.
     * @param getter - The function that will be called once the timeout is reached. It has all data available and should use/filter that data to fetch the relevant data.
     * @private
     */
    private getterFn<Data extends Record<string, unknown>, Return>(key: string, data: Data, getter: (data: Data[]) => Observable<Return | null>) {
        const store = this.stores.get(key) || { data: [] };
        this.stores.set(key, store);

        clearTimeout(store.timeout);

        store.timeout = window.setTimeout(() => {
            const storeSubject = store.subject;
            const storeData = store.data as Data[];
            this.stores.delete(key);

            if (!storeSubject) {
                return;
            }

            getter(storeData).pipe(catchError(() => of(null))).subscribe((items) => {
                storeSubject.next(items);
                storeSubject.complete();
            });
        }, this.defaultTimeout);

        // Add data
        store.data.push(data);

        if (!store.subject) {
            store.subject = new Subject();
        }

        return store.subject.asObservable() as Observable<Return>;
    }

    private getFetchFrom(dates: DateTime[]) {
        return dates.map((d) => d).sort((a, b) => a.valueOf() - b.valueOf())[0];
    }

    private getFetchTo(dates: DateTime[]) {
        return dates.map((d) => d).sort((a, b) => b.valueOf() - a.valueOf())[0];
    }

    /**
     * Return all kpis in the given interval for the given customer.
     *
     * Can be filtered by kpiType.
     */
    getCustomerKpis(customerId: number, from: DateTime, to: DateTime, kpiType?: number) {
        return this.getterFn(`customer-${customerId}-kpis`, {
            customerId,
            kpiType,
            from,
            to,
        }, (data) => {
            const dataCustomerId = data[0]?.customerId;
            const fetchFrom = this.getFetchFrom(data.map((d) => d.from));
            const fetchTo = this.getFetchTo(data.map((d) => d.to));

            if (!dataCustomerId || !fetchFrom || !fetchTo) {
                return of([]);
            }

            return this.kpiService.getAll(dataCustomerId, {
                from: fetchFrom,
                to: fetchTo,
            }).pipe(
                map((resp) => resp.data),
            );
        }).pipe(
            map((kpis) => {
                // Return only kpis within the interval
                const intervalKpis = kpis.filter((kpi) => {
                    return kpi.businessDate ? kpi.businessDate.dateTime >= from && kpi.businessDate.dateTime <= to : false;
                });

                // Return all kpis if no kpiType is specified
                return kpiType ? intervalKpis.filter((kpi) => kpi.kpiTypeId === kpiType) : intervalKpis;
            }),
        );
    }

    getCustomerKpiTypes(customerId: number): Observable<KpiType[] | undefined>
    getCustomerKpiTypes(customerId: number, kpiType: number): Observable<KpiType | undefined>
    getCustomerKpiTypes(customerId: number, kpiType?: number): Observable<KpiType[] | KpiType | undefined> {
        return this.getterFn(`customer-${customerId}-kpi-types`, {
            customerId,
            kpiType,
        }, (data) => {
            const dataCustomerId = data[0]?.customerId;
            const dataKpiType = data[0]?.kpiType;

            if (!dataCustomerId || !dataKpiType) {
                return of(kpiType ? undefined : []);
            }

            return expandAllPages((pagination) => this.kpiTypeService.getAll(dataCustomerId, pagination), { per_page: 500 });
        }).pipe(
            map((kpiTypes) => {
                return kpiType ? kpiTypes?.find((type) => type.id === kpiType) : kpiTypes;
            }),
        );
    }

    getEmployeeTimepunches(from: DateTime, to: DateTime, customerId: number, employeeId: number) {
        return this.getterFn(`customer-${customerId}-employee-${employeeId}-timepunches`, {
            customerId,
            employeeId,
            from,
            to,
        }, (data) => {
            const dataCustomerId = data[0]?.customerId;
            const dataEmployeeId = data[0]?.employeeId;
            const fetchFrom = this.getFetchFrom(data.map((d) => d.from));
            const fetchTo = this.getFetchTo(data.map((d) => d.to));

            if (!dataCustomerId || !dataEmployeeId || !fetchFrom || !fetchTo) {
                return of([]);
            }

            return expandAllPages((pagination) => this.timepunchService.getForCustomer(dataCustomerId, {
                ...pagination,
                from: fetchFrom,
                to: fetchTo,
                employeeId: dataEmployeeId,
            }), {
                per_page: 500,
            });
        }).pipe(
            map((timepunches) => timepunches.filter((timepunch) => timepunch.businessDate.dateTime >= from && timepunch.businessDate.dateTime <= to)),
        );
    }

    getEmployeeShifts(from: DateTime, to: DateTime, customerId: number, employeeId: number) {
        return this.getterFn(`customer-${customerId}-employee-${employeeId}-shifts`, {
            customerId,
            employeeId,
            from,
            to,
        }, (data) => {
            const dataCustomerId = data[0]?.customerId;
            const dataEmployeeId = data[0]?.employeeId;
            const fetchFrom = this.getFetchFrom(data.map((d) => d.from));
            const fetchTo = this.getFetchTo(data.map((d) => d.to));

            if (!dataCustomerId || !dataEmployeeId || !fetchFrom || !fetchTo) {
                return of([]);
            }

            return expandAllPages((pagination) => this.shiftService.getAllForEmployee(dataCustomerId, dataEmployeeId, {
                ...pagination,
                from: fetchFrom,
                to: fetchTo,
            }), {
                per_page: 500,
            });
        }).pipe(
            map((shifts) => shifts.filter((s) => s.from >= from && s.to <= to)),
        );
    }

    getCustomerShifts(from: DateTime, to: DateTime, customerId: number) {
        return this.getterFn(`customer-${customerId}-shifts`, {
            customerId,
            from,
            to,
        }, (data) => {
            const dataCustomerId = data[0]?.customerId;
            const fetchFrom = this.getFetchFrom(data.map((d) => d.from));
            const fetchTo = this.getFetchTo(data.map((d) => d.to));

            if (!dataCustomerId || !fetchFrom || !fetchTo) {
                return of([]);
            }

            return expandAllPages((pagination) => this.shiftService.getAll(dataCustomerId, {
                ...pagination,
                from: fetchFrom,
                to: fetchTo,
            }), {
                per_page: 500,
            });
        }).pipe(
            map((shifts) => shifts.filter((s) => s.from >= from && s.to <= to)),
        );
    }

    getEmployeePaidTime(from: DateTime, to: DateTime, customerId: number, employeeId: number) {
        return this.getterFn(`customer-${customerId}-employee-${employeeId}-paidtime`, {
            customerId,
            employeeId,
            from,
            to,
        }, (data) => {
            const dataCustomerId = data[0]?.customerId;
            const dataEmployeeId = data[0]?.employeeId;
            const fetchFrom = this.getFetchFrom(data.map((d) => d.from));
            const fetchTo = this.getFetchTo(data.map((d) => d.to));

            if (!dataCustomerId || !dataEmployeeId || !fetchFrom || !fetchTo) {
                return of([]);
            }

            return expandAllPages((pagination) => this.paidTimeService.getAllForEmployee(customerId, employeeId, fetchFrom, fetchTo, pagination), { per_page: 500 });
        }).pipe(
            map((paidTimes) => paidTimes.filter((paidTime) => paidTime.from >= from && paidTime.to <= to)),
        );
    }
}
