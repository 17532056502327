<eaw-dialog-header>
    <span title>{{'CREATE_TODO' | translate:'todo' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <mat-tab-group [mat-stretch-tabs]="false">
        <mat-tab [label]="('TODO' | translate:'todo' | async) || 'Todo'">
            <form id="todo-form" class="tw-flex tw-flex-col tw-gap-16" [formGroup]="form">
                <mat-form-field>
                    <mat-label>{{'TITLE' | translate | async}}</mat-label>
                    <input matInput formControlName="title" autocomplete="off">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'DESCRIPTION' | translate | async}}</mat-label>
                    <textarea matInput formControlName="description"></textarea>
                </mat-form-field>

                <eaw-customer-user-autocomplete controlName="responsible" [label]="'RESPONSIBLE' | translate" [customerId]="data.customerId"></eaw-customer-user-autocomplete>

                <mat-form-field>
                    <mat-label>{{'PUBLISH_AT' | translate:'todo' | async}}</mat-label>
                    <eaw-date-time-input formControlName="publishAt" [minDate]="minDate"></eaw-date-time-input>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'DUE_DATE' | translate:'todo' | async}}</mat-label>
                    <eaw-date-time-input formControlName="dueDate" [minDate]="minDate"></eaw-date-time-input>
                </mat-form-field>

                <mat-checkbox eawCheckboxHelper formControlName="notify">{{'TODO_NOTIFICATION' | translate:'todo' | async}}</mat-checkbox>
            </form>
        </mat-tab>

        <mat-tab [label]="('X_ATTACHMENT' | translate:'general':{count: attachments.length} | async) || 'Attachments'">
            <eaw-attachment-list (filesChange)="attachmentChange($event)"></eaw-attachment-list>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="">{{'CANCEL' | translate | async}}</button>
    <button mat-button mat-raised-button color="primary" (click)="createTodo()" [disabled]="form.invalid">{{'CREATE' | translate | async}}</button>
</mat-dialog-actions>
