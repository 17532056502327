<eaw-dialog-header>
    <span title *ngIf="!data.paidTime">{{ 'ADD_PAID_TIME' | translate:'payroll' | async }}</span>
    <span title *ngIf="data.paidTime">{{ 'UPDATE_PAID_TIME' | translate:'payroll' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="initialLoading">
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
        <mat-spinner></mat-spinner>
    </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="!initialLoading">
    <form [formGroup]="form" (ngSubmit)="submit()" class="tw-grid tw-grid-cols-12">
        <eaw-date-time-range-input class="tw-col-span-full tw-grid tw-grid-cols-2 tw-gap-8">
            <mat-form-field eawDateTimeFrom>
                <mat-label>{{ 'FROM' | translate | async }}</mat-label>
                <eaw-date-time-input formControlName="from" required></eaw-date-time-input>
            </mat-form-field>

            <mat-form-field eawDateTimeTo>
                <mat-label>{{ 'TO' | translate | async }}</mat-label>
                <eaw-date-time-input formControlName="to" required></eaw-date-time-input>
            </mat-form-field>
        </eaw-date-time-range-input>

        <eaw-duration-input class="tw-col-span-full"
                            *ngIf="data.showDuration"
                            [from]="form.controls.from.value"
                            [to]="form.controls.to.value">
        </eaw-duration-input>

        <mat-form-field eawDatePickerOptions class="tw-col-span-full">
            <mat-label>{{ 'BUSINESS_DATE' | translate | async }}</mat-label>
            <input formControlName="businessDate" matInput [matDatepicker]="$any(startPicker)" autocomplete="off">
            <mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>

        <eaw-autocomplete
                class="tw-col-span-full"
                formControlName="businessUnit"
                [options]="businessUnitAutocomplete.options"
                [getter]="businessUnitAutocomplete.getter"
                [setter]="businessUnitAutocomplete.setter">
        </eaw-autocomplete>

        @for (field of customFields;track field.key) {
            <eaw-custom-field-input class="tw-col-span-full" [customField]="field" [formGroup]="form.controls.customFields"/>
        }

        <mat-form-field class="tw-col-span-full">
            <mat-label>{{ 'COMMENT' | translate | async }}</mat-label>
            <textarea matInput cdkAutosizeMinRows="2" cdkAutosizeMaxRows="4" cdkTextareaAutosize formControlName="comment"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{ 'CANCEL' | translate | async }}</button>
    <button mat-raised-button type="submit" color="primary" (click)="submit()" [disabled]="form.invalid">
        <span *ngIf="!data.paidTime">{{ 'ADD_PAID_TIME' | translate: 'payroll' | async }}</span>
        <span *ngIf="data.paidTime">{{ 'UPDATE_PAID_TIME' | translate:'payroll' | async }}</span>
    </button>
</mat-dialog-actions>
