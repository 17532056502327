import { Inject, Injectable } from '@angular/core';
import { Role } from '../types/role';
import { RoleAssignmentDialogComponent, RoleAssignmentDialogData, RoleAssignmentResult } from '../../assignment-dialog/role-assignment-dialog.component';
import { RoleAssignmentService } from '../http/role-assignment.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { Customer } from '../../../shared/models/customer';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { EMPTY, switchMap, tap } from 'rxjs';
import { DateTime } from 'luxon';
import { UserResponse } from '../../../shared/models/user';
import { RoleAssignment } from '../types/role-assignment';

@Injectable({
    providedIn: 'root',
})
export class RoleAssignmentDialogService {

    constructor(
        @Inject(RoleAssignmentService) protected service: RoleAssignmentService,
        @Inject(SnackBarService) protected snack: SnackBarService,
        @Inject(MatDialog) protected dialog: MatDialog,
        @Inject(ConfirmDialogService) protected confirm: ConfirmDialogService,
    ) { }

    create(customerId: number, role?: Role, user?: UserResponse) {
        return this.dialog.open<RoleAssignmentDialogComponent, RoleAssignmentDialogData, RoleAssignmentResult>(RoleAssignmentDialogComponent, {
            data: {
                customerId,
                role,
                user,
                assignment: new RoleAssignment({
                    created_at: '',
                    updated_at: '',
                    from: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'),
                    id: 0,
                    role_id: role?.id ?? 0,
                    user_id: user?.id ?? 0,
                }),
            },
        })
            .afterClosed()
            .pipe(switchMap((result) => {
                if (!result) {
                    return EMPTY;
                }

                return this.service.create(customerId, result.role_id, result)
                    .pipe(tap(() => {
                        this.snack.created();
                    }));
            }));
    }

    update(customer: Customer, item: RoleAssignment, role?: Role) {
        return this.dialog.open<RoleAssignmentDialogComponent, RoleAssignmentDialogData, RoleAssignmentResult>(RoleAssignmentDialogComponent, {
            data: {
                customerId: customer.id,
                assignment: item,
                role: role ?? (item.role ? new Role(item.role) : undefined),
            },
        })
            .afterClosed()
            .pipe(switchMap((result) => {
                if (!result) {
                    return EMPTY;
                }

                return this.service.update(customer.id, item.roleId, item.id, {
                    from: result.from,
                    to: result.to,
                })
                    .pipe(tap((assignment) => {
                        item.from = assignment.from;
                        item.to = assignment.to;

                        this.snack.updated();
                    }));
            }));
    }

    delete(customer: Customer, assignment: RoleAssignment) {
        return this.confirm.delete().afterClosed().pipe(switchMap((ok) => {
            if (!ok) {
                return EMPTY;
            }

            return this.service.delete(customer.id, assignment.roleId, assignment.id).pipe(tap(() => {
                this.snack.deleted();
            }));
        }));
    }
}
