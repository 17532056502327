import { Component, Inject } from '@angular/core';
import { CurrentService } from '../../../../shared/services/current.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperDialogComponent, ImageCropperDialogData } from '../../../../shared/dialogs/image-cropper-dialog/image-cropper-dialog.component';
import { UserService } from '../../../../shared/http/user.service';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { TranslateService } from '../../../../shared/services/translate.service';
import { MatIconSizeDirective } from '../../../../shared/directives/mat-icon-size.directive';
import { MatIconModule } from '@angular/material/icon';
import { ProfilePictureComponent } from '../../../../shared/components/profile-picture/profile-picture.component';

@Component({
    selector: 'eaw-profile-nav-header',
    templateUrl: './profile-nav-header.component.html',
    styleUrl: './profile-nav-header.component.scss',
    standalone: true,
    imports: [
        ProfilePictureComponent,
        MatIconModule,
        MatIconSizeDirective,
    ],
})
export class ProfileNavHeaderComponent {
    user = this.current.getUser();
    employee = this.current.getEmployee();

    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(UserService) private userService: UserService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(TranslateService) private translateService: TranslateService,
    ) {
    }

    chooseProfileImage() {
        this.matDialog.open<ImageCropperDialogComponent, ImageCropperDialogData, File>(ImageCropperDialogComponent, {
            data: {
                aspectRatio: 1,
                title: this.translateService.t('NEW_PROFILE_PICTURE', 'profile'),
            },
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            const id = this.current.getUser().id;

            void this.snackBarService.t('PROCESSING_NEW_PROFILE_PICTURE', 'profile');
            this.userService.uploadPicture(id, result).subscribe();
        });
    }
}
