// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
const template1 = `<md-dialog id="item-permission-dialog" class="medium-dialog">
    <eaw-dialog-header-old heading="'ACL' | i18next">
        <eaw-dialog-header-tools>
            <md-button class="md-icon-button" ng-click="permCtrl.load()">
                <md-icon ng-bind="'refresh'"></md-icon>
                <md-tooltip ng-i18next="REFRESH"></md-tooltip>
            </md-button>

            <md-button class="md-icon-button" ng-click="permCtrl.addEntry()">
                <md-icon ng-bind="'group_add'"></md-icon>
                <md-tooltip ng-i18next="filesystem:GIVE_ACCESS"></md-tooltip>
            </md-button>
        </eaw-dialog-header-tools>
    </eaw-dialog-header-old>

    <md-dialog-content>
        <md-subheader>
            <eaw-mime-icon size="'24'" ng-if="permCtrl.item.type == 'file'" type="permCtrl.item.file.mime"></eaw-mime-icon>
            <eaw-mime-icon size="'24'" ng-if="permCtrl.item.type == 'directory'" type="'folder'"></eaw-mime-icon>
            <span class="name" ng-bind="::permCtrl.item.name"></span>
        </md-subheader>

        <div ng-if="permCtrl.loading" class="md-dialog-content tw-mt-16" layout="column" layout-align="center center">
            <md-progress-circular class="md-progress-center"></md-progress-circular>
        </div>

        <div class="md-dialog-content nothing" layout="column" layout-align="center center" ng-if="!permCtrl.loading && !permCtrl.acl.list.length">
            <md-icon ng-click="permCtrl.addEntry()" ng-bind="'group_add'"></md-icon>
            <span ng-i18next="[i18next]({name: permCtrl.item.name})filesystem:NO_ENTRIES"></span>
        </div>

        <div class="md-dialog-content" ng-if="!permCtrl.loading && permCtrl.acl.list.length">
            <table id="permissions-table" class="table table-vmiddle table-condensed">
                <thead>
                <tr>
                    <th ng-i18next="filesystem:WHO"></th>
                    <th class="text-center" ng-repeat="action in permCtrl.acl.actions" ng-i18next="filesystem:{{::action.toUpperCase()}}"></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr ng-repeat="item in permCtrl.acl.list track by item._uniqueId">
                    <td ng-bind="item.name"></td>
                    <td class="text-center" ng-repeat="action in permCtrl.acl.actions">
                        <md-checkbox class="condensed" ng-model="item.acl_entries[action].allowed" ng-disabled="item.inherited" ng-change="item.changed(action)"></md-checkbox>
                    </td>
                    <td>
                        <md-button class="md-warn md-icon-button" ng-if="!item.inherited" ng-click="permCtrl.remove(item)">
                            <md-icon ng-bind="'delete'"></md-icon>
                            <md-tooltip ng-i18next="DELETE"></md-tooltip>
                        </md-button>

                        <md-button class="md-icon-button" ng-if="item.inherited" disabled>
                            <md-icon ng-bind="'link'"></md-icon>
                            <md-tooltip ng-i18next="filesystem:INHERITED"></md-tooltip>
                        </md-button>

                        <md-button class="md-icon-button" ng-if="item.inherited" ng-click="permCtrl.addControllable(item.morph_class, item.id)">
                            <md-icon ng-bind="'edit'"></md-icon>
                            <md-tooltip ng-i18next="filesystem:OVERWRITE"></md-tooltip>
                        </md-button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="column" layout-align="start stretch">
        <div layout="row" layout-align="start center">
            <md-checkbox ng-if="!permCtrl.item.isRoot()" class="condensed" ng-model="permCtrl.acl.inherits" ng-change="permCtrl.inheritChanged()" ng-disabled="permCtrl.loading">
                <span ng-i18next="filesystem:ACL_INHERITS"></span>
            </md-checkbox>
            <span flex></span>
            <md-button close-dialog ng-i18next="CLOSE"></md-button>
        </div>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.filesystem').service('FilesystemItem', [ '$mdDialog', function($mdDialog) {
    class FilesystemItem {
        _selected;
        id;
        type;
        directory_id;
        created_at;
        deleted_at;
        updated_at;
        user;
        customer_id;
        user_id;
        logs;
        owner;
        directory;
        actions;
        name;
        size;
        _sortingFieldPrimary;
        _sortingFieldSecondary;
        constructor(item, parent) {
            this._selected = false;
            this.id = item.id;
            this.type = item.type;
            this.directory_id = item.directory_id;
            this.created_at = item.created_at;
            this.deleted_at = item.deleted_at;
            this.updated_at = item.updated_at;
            this.user = item.user;
            this.customer_id = item.customer_id;
            this.user_id = item.user_id;
            this.logs = item.logs;
            this.owner = CurrentOld.user.id === item.user_id ? t('ME').toLowerCase() : item?.user?.name;
            this.directory = parent;
            this.actions = item.actions;
        }

        select() {
            this._selected = true;
        }

        deselect() {
            this._selected = false;
        }

        isSelected() {
            return this._selected;
        }

        isDirectory() {
            return this.type === 'directory';
        }

        isFile() {
            return this.type === 'file';
        }

        setSortingField(field) {
            switch (field) {
                case 'name': {
                    return this.name.toLowerCase();
                }
                case 'owner': {
                    return this.owner.toLowerCase();
                }
                case 'updated': {
                    return parseInt(this.updated_at.format('X'));
                }
                case 'filesize': {
                    return this.size?.integer || 0;
                }
                default: {
                    return this[field];
                }
            }
        }

        setSortingFieldPrimary(field) {
            this._sortingFieldPrimary = this.setSortingField(field);
        }

        setSortingFieldSecondary(field) {
            this._sortingFieldSecondary = this.setSortingField(field);
        }

        updateAccess() {
            return $mdDialog.show({
                template: template1,
                controllerAs: 'permCtrl',
                controller: 'itemAccessDialogCtrl',
                bindToController: true,
                multiple: true,
                locals: {
                    item: this,
                },
            });
        }
    }
    return FilesystemItem;
} ]);
