// @ts-nocheck
import { module } from 'angular';
module('eaw.misc').factory('CustomerSettingsGroupService', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const customerSettingGroupRes = EawResource.create('/setting_groups/:group/:members/:customer/:properties/:property');
    const customerSettingGroupObserverRes = EawResource.create('/setting_groups/:group/observers/:observer');
    const customerSettingGroupRes1 = EawResource.create('/customers/:customer/setting_group');
    this.getAll = () => customerSettingGroupRes.get();
    this.getAllPaginated = (args) => customerSettingGroupRes.get({
        ...Pagination.getParams(args),
    });
    this.getById = (id) => customerSettingGroupRes.get({ group: id });
    this.getByCustomerId = (customerId) => customerSettingGroupRes1.get({ customer: customerId });
    this.create = (name) => customerSettingGroupRes.save({ name });
    this.deleteById = (id) => customerSettingGroupRes.delete({ group: id });
    this.getMembers = (args) => customerSettingGroupRes.get({
        group: args.groupId,
        members: 'members',
        ...Pagination.getParams(args),
    });
    this.addMember = (settingGroup, member) => customerSettingGroupRes.save({
        group: settingGroup.id,
        members: 'members',
    }, {
        member_id: member.id,
    });
    this.deleteMember = (settingGroupId, memberId) => customerSettingGroupRes.delete({
        group: settingGroupId,
        members: 'members',
        customer: memberId,
    });
    this.updateById = (id, settingGroup) => customerSettingGroupRes.update({
        group: id,
    }, settingGroup);
    this.getSetting = (settingGroupId, key) => customerSettingGroupRes.get({
        group: settingGroupId,
        properties: 'properties',
        property: key,
    });
    this.getSettingsPaginated = (args) => customerSettingGroupRes.get({
        ...Pagination.getParams(args),
        group: args.groupId,
        properties: 'properties',
    });
    this.addSetting = (settingGroup, setting) => customerSettingGroupRes.save({
        group: settingGroup.id,
        properties: 'properties',
    }, setting);
    this.updateSetting = (settingGroup, setting) => customerSettingGroupRes.update({
        group: settingGroup.id,
        properties: 'properties',
        property: setting.key,
    }, setting);
    this.removeSetting = (settingGroup, setting) => customerSettingGroupRes.delete({
        group: settingGroup.id,
        properties: 'properties',
        property: setting.key,
    });
    // / /////////////
    // OBSERVERS
    // / /////////////
    this.getAllObservers = (args) => customerSettingGroupObserverRes.get({
        group: args.settingGroup.id,
        ...Pagination.getParams(args),
    });
    this.addObserver = (settingGroup, observer, from, to) => customerSettingGroupObserverRes.save({
        group: settingGroup.id,
    }, {
        class: observer.class,
        from,
        to,
    });
    this.updateObserver = (settingGroup, observer, args) => customerSettingGroupObserverRes.update({
        group: settingGroup.id,
        observer: observer.id,
    }, {
        from: args.from,
        to: args.to,
    });
    this.deleteObserver = (settingGroup, observer) => customerSettingGroupObserverRes.delete({
        group: settingGroup.id,
        observer: observer.id,
    });
    return this;
} ]);
