import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { ScheduleSettingsComponent } from '../../components/schedule-settings/schedule-settings.component';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { AsyncPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';

export interface ScheduleSettingsDialogData extends DialogData {
    stackId: number;
    customerId: number;
}

@Component({
    selector: 'eaw-schedule-settings-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        ScheduleSettingsComponent,
        ActionButtonComponent,
        AsyncPipe,
        MatButton,
        MatDialogActions,
        TranslatePipe,
        MatDialogClose,
    ],
    templateUrl: './schedule-settings-dialog.component.html',
    styleUrl: './schedule-settings-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleSettingsDialogComponent extends DialogComponent<ScheduleSettingsDialogData> {
    constructor() {
        super(undefined, undefined, DialogSize.Medium);
    }
}
