<eaw-dialog-header>
    <span title>{{'PERMISSIONSET' | translate | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="formGroup" (ngSubmit)="formGroup.valid ? submit() : null">
        <eaw-autocomplete-deprecated [observable]="permissionData" (filterChanged)="onSearch($event)" (initialFocus)="onSearch()" [label]="'PERMISSIONSET' | translate " controlName="permissionSet"></eaw-autocomplete-deprecated>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{'CANCEL' | translate | async}}</button>
    <button mat-raised-button color="primary" [disabled]="permissionSet.invalid" (click)="submit()">{{'ADD' | translate | async}}</button>
</mat-dialog-actions>
