// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="'PERMISSIONSET' | i18next"></eaw-dialog-header-old>

    <md-dialog-content ng-show="spdCtrl.loadingPermissionsets">
        <md-progress-circular class="md-progress-center tw-mt-20 tw-mb-20"></md-progress-circular>
    </md-dialog-content>

    <md-dialog-content ng-show="!spdCtrl.loadingPermissionsets">
        <md-content class="md-padding">
                <md-autocomplete
                        md-floating-label="{{'PERMISSIONSET' | i18next}}"
                        md-no-cache="true"
                        md-clear-button="true"
                        md-selected-item="spdCtrl.permissionset"
                        md-search-text="filter"
                        md-require-match="true"
                        md-items="item in spdCtrl.getPermissionsets(filter)"
                        md-item-text="item.name"
                        md-delay="300"
                        required
                        md-input-name="permissionAutocomplete"
                        md-min-length="0">
                    <md-item-template>
                        <span md-highlight-text="filter" md-highlight-flags="gi">{{item.name}}</span>
                    </md-item-template>
                    <md-not-found>
                        <span ng-i18next="NO_MATCH_FOUND"></span>
                    </md-not-found>
                </md-autocomplete>
        </md-content>
    </md-dialog-content>

    <md-dialog-actions ng-show="!spdCtrl.loadingPermissionsets" layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-click="spdCtrl.returnPermissionset()" ng-disabled="!spdCtrl.permissionset" ng-i18next="SELECT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.dialogs').factory('SelectPermissionsetDialog', [ '$mdDialog', function($mdDialog) {
    const service = this;

    service.open = () => $mdDialog.show({
        template: template1,
        controller: 'SelectPermissionSetDialogCtrl',
        controllerAs: 'spdCtrl',
    }).then((result) => result);

    return service;
} ]);
