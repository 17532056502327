import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    private _angularJsLoaded = false;

    get angularJsLoaded() {
        return this._angularJsLoaded;
    }

    set angularJsLoaded(value: boolean) {
        this._angularJsLoaded = value;
    }

    /**
     * Reloads the application.
     * @param url - Full url with http and hash and everything
     */
    reload(url?: string) {
        if (environment.isTesting) {
            return;
        }

        if (url && url !== window.location.href) {
            window.location.replace(url);
        }

        setTimeout(() => {
            window.location.reload();
        }, 1);
    }
}
