import { module } from 'angular';
import { PermissionSetAssignmentComponent } from '../../pages/permission-set-assignment/permission-set-assignment.component';
import { createNavTabsState, createState } from '../../../shared/utils/create-state';
import { PermissionSetInfoComponent } from '../../pages/permission-set-info/permission-set-info.component';
import { paramResolver } from '../../../shared/resolvers/param.resolver';
import { StateProvider } from '@uirouter/angularjs';
import { PermissionSetListComponent } from '../../pages/permission-set-list/permission-set-list.component';
import { NavTabsComponent, NavTabsTab } from '../../../shared/components/nav-tabs/nav-tabs.component';
import { navTabsTabsResolver } from '../../../shared/resolvers/nav-tabs-tabs.resolver';
import { inject } from '@angular/core';
import { PermissionSetService } from '../../../shared/http/permission-set.service';
import { map } from 'rxjs';
import { CurrentService } from '../../../shared/services/current.service';

module('eaw.admin').config([ '$stateProvider', ($stateProvider: StateProvider) => {
    $stateProvider.state(`eaw/app/admin/permissionsets`, {
        parent: 'eaw/app/admin',
        url: '/permissionsets',
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        data: {
            breadcrumb: { key: 'PERMISSIONSET_plural' },
        },
        resolve: [
            navTabsTabsResolver((): Promise<NavTabsTab[]> => Promise.resolve([
                {
                    state: `eaw/app/admin/permissionsets/list`,
                    label: {
                        key: 'OVERVIEW',
                    },
                },
                {
                    state: `eaw/app/permissionsets/assign`,
                    label: {
                        key: 'ASSIGN',
                    },
                },
            ])),
        ],
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/admin/permissionsets/list`,
        parent: `eaw/app/admin/permissionsets`,
        url: '/list',
        component: PermissionSetListComponent,
        data: {
            breadcrumb: { key: 'OVERVIEW' },
            permissions: [ `permission_sets.*.get` ],
            permissionCheck: (permissionCheckService) => {
                return permissionCheckService.permissionOrChildren(`permission_sets.*.get`, undefined, `permission_sets`, `get`, true)
            },
        },
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/permissionsets/assign`,
        parent: `eaw/app/admin/permissionsets`,
        url: `/assign`,
        component: PermissionSetAssignmentComponent,
        data: {
            breadcrumb: { key: 'ASSIGN' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/admin/permissionsets/list/info`,
        parent: `eaw/app/admin/permissionsets/list`,
        url: `/info/:id`,
        views: {
            'content@': {
                component: PermissionSetInfoComponent,
            },
        },
        data: {
            breadcrumb: (transition) => {
                return inject(PermissionSetService).get(transition.params()['id']).pipe(map((permissionSet) => permissionSet.name));
            },
        },
        resolve: [
            paramResolver('id', 'permissionSetId'),
        ],
    });
} ])
;
;
