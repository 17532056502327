import { module } from 'angular';
import { NavTabsComponent } from '../../../shared/components/nav-tabs/nav-tabs.component';
import { UsersListComponent } from '../../pages/users-list/users-list.component';
import { resolver } from '../../../shared/resolvers/resolver';
import { createNavTabsState, createState } from '../../../shared/utils/create-state';
import { navTabsTabsResolver } from '../../../shared/resolvers/nav-tabs-tabs.resolver';
import { UserPasswordTabComponent } from '../../pages/user-password-tab/user-password-tab.component';
import { UserPermissionsTabComponent } from '../../pages/user-permissions-tab/user-permissions-tab.component';
import { AdminUserPropertiesComponent } from '../../pages/admin-user-properties/admin-user-properties.component';
import { DataTableQueryParams } from '../../../data-table/data-table.component';
import { AdminUserInformationComponent } from '../../pages/admin-user-information/admin-user-information.component';
import { paramResolver } from '../../../shared/resolvers/param.resolver';
import { UserService } from '../../../shared/http/user.service';
import { map } from 'rxjs';
import { inject } from '@angular/core';
import { AdminUserGroupsComponent } from '../../pages/admin-user-groups/admin-user-groups.component';
import { AdminUserCustomersComponent } from '../../pages/admin-user-customers/admin-user-customers.component';

module('eaw.admin').config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state(`eaw/app/admin/users`, {
        parent: 'eaw/app/admin',
        url: '/users',
        abstract: true,
        data: {
            breadcrumb: { key: 'USER_plural' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/admin/users/list`,
        parent: `eaw/app/admin/users`,
        url: '/list',
        views: {
            'content@': {
                component: UsersListComponent,
            },
        },
        data: {
            queryParams: [ ...DataTableQueryParams, 'filter', 'fields' ],
            breadcrumb: { key: 'LIST' },
            permissions: [ `users.*.get` ],
        },
    });

    createState($stateProvider, {
        name: `eaw/app/admin/users/view`,
        parent: `eaw/app/admin/users/list`,
        url: '/:id',
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        resolve: [
            paramResolver('id', 'userId', v => parseInt(String(v), 10)),
            navTabsTabsResolver(function() {
                return Promise.resolve([
                    {
                        state: 'eaw/app/admin/users/view/info',
                        label: { key: 'INFORMATION' },
                    },
                    {
                        state: 'eaw/app/admin/users/view/password',
                        label: { key: 'PASSWORD' },
                    },
                    {
                        state: 'eaw/app/admin/users/view/customers',
                        label: { key: 'CUSTOMER_plural', ns: 'admin' },
                    },
                    {
                        state: 'eaw/app/admin/users/view/groups',
                        label: { key: 'GROUP_plural' },
                    },
                    {
                        state: 'eaw/app/admin/users/view/permissions',
                        label: { key: 'PERMISSION_plural' },
                    },
                    {
                        state: 'eaw/app/admin/users/view/properties',
                        label: { key: 'PROPERTY_plural' },
                    },
                ]);
            }),
        ],
        data: {
            breadcrumb: (transition) => {
                return inject(UserService).get(transition.params()['id']).pipe(
                    map((user) => `${user.firstName} ${user.lastName}`),
                );
            },
        },
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/admin/users/view/info`,
        url: '/information',
        parent: `eaw/app/admin/users/view`,
        component: AdminUserInformationComponent,
        data: {
            breadcrumb: { 'key': 'INFORMATION' },
        },
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/admin/users/view/password`,
        url: '/password',
        parent: `eaw/app/admin/users/view`,
        component: UserPasswordTabComponent,
        data: {
            breadcrumb: { key: 'PASSWORD' },
        },
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/admin/users/view/permissions`,
        url: '/permissions',
        parent: `eaw/app/admin/users/view`,
        component: UserPermissionsTabComponent,
        data: {
            breadcrumb: { key: 'PERMISSION_plural' },
        },
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/admin/users/view/properties`,
        url: '/properties',
        parent: `eaw/app/admin/users/view`,
        component: AdminUserPropertiesComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'PROPERTY_plural' },
        },
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/admin/users/view/customers`,
        url: '/customers',
        parent: `eaw/app/admin/users/view`,
        component: AdminUserCustomersComponent,
        data: {
            breadcrumb: {
                key: 'CUSTOMER_plural',
                ns: 'admin',
            },
        },
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/admin/users/view/groups`,
        url: '/groups',
        parent: `eaw/app/admin/users/view`,
        component: AdminUserGroupsComponent,
        data: {
            breadcrumb: { key: 'GROUP_plural' },
        },
    });
} ]);
