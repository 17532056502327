import { ChangeDetectionStrategy, Component, computed, effect, inject, Input, OnChanges, OnDestroy, signal, SimpleChanges } from '@angular/core';
import { WeatherForecastComponent } from '../../../../weather/components/weather-forecast/weather-forecast.component';
import { ExtendedWidgetInfo } from '../../../classes/extended-widget-info';
import { WeatherService } from '../../../../weather/services/weather.service';
import { WeatherForecast } from '../../../../weather/classes/weather-forecast';
import { catchError, EMPTY, Subscription, tap } from 'rxjs';
import { Widget } from '../../../classes/widget';
import { WeatherWidgetSettings } from '../weather-widget-settings-dialog/weather-widget-settings-dialog.component';

@Component({
    selector: 'eaw-weather-widget-extended',
    standalone: true,
    imports: [
        WeatherForecastComponent,
    ],
    templateUrl: './weather-widget-extended.component.html',
    styleUrl: './weather-widget-extended.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherWidgetExtendedComponent extends ExtendedWidgetInfo implements OnDestroy, OnChanges {
    private readonly weatherService = inject(WeatherService);

    // Annotation for AngularJS downgrade
    @Input() widget!: Widget<WeatherWidgetSettings>;
    _widget = signal<Widget<WeatherWidgetSettings> | null>(null);

    protected forecast = signal<WeatherForecast | undefined>(undefined);
    protected customerId = computed(() => this._widget()?.info?.customer?.id);
    protected isFahrenheit = computed(() => !!this._widget()?.getSetting('useFahrenheit'));

    private weatherSubscription?: Subscription;

    constructor() {
        super();
        this.setLoading(true);
        effect(() => {
            const customerId = this.customerId();
            if (customerId) {
                this.getWeatherForecast(customerId);
            }
        });
    }

    private getWeatherForecast(customerId: number) {
        this.weatherSubscription?.unsubscribe();
        this.weatherSubscription = this.weatherService.getForecast(customerId).pipe(
            catchError(() => EMPTY),
            tap((forecast) => {
                this.forecast.set(forecast);
                this.setLoading(false);
            }),
        ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['widget']) {
            this._widget.set(changes['widget'].currentValue);
        }
    }

    ngOnDestroy() {
        this.weatherSubscription?.unsubscribe();
    }
}
