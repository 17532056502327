// @ts-nocheck
import { module } from 'angular';
/**
 * @ngdoc factory
 * @name eaw.admin.factory.CalculatorFactory
 */
module('eaw.misc').factory('CalculatorFactory', [ 'EawResource', 'Pagination', function CalculatorFactory(EawResource, Pagination) {
    return {
        getAll(options) {
            return EawResource.create('/calculators').get(Pagination.getParams(options, [ 'per_page', 'page', 'order_by', 'direction' ]));
        },
    };
} ]);
