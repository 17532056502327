import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { DateTime } from 'luxon';
import { VariablePayment, VariablePaymentResponse } from '../models/variable-payment';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { Observable } from 'rxjs';
import { BUSINESS_DATES } from '../../shared/http/http-contexts';
import { VariablePaymentOverview, VariablePaymentOverviewResponse } from '../models/variable-payment-overview';

type CreateData = {
    pay_type_link_id: number;
    quantity?: number;
    rate?: number;
    comment?: string;
    business_date: DateTime;
}

type UpdateBody = {
    rate?: number;
    quantity?: number;
     comment?: string;
}

@Injectable({
    providedIn: 'root',
})
export class VariablePaymentService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    /**
     * Get overview of variable payments for an employee, indexed by pay type link id and business date.
     */
    getEmployeeOverview(customerId: number, employeeId: number, from: DateTime, to: DateTime): Observable<VariablePaymentOverview> {
        return this.http.get<VariablePaymentOverviewResponse>(`/customers/${customerId}/employees/${employeeId}/variable_payments/overview`, {
            params: formatHttpParams({
                from,
                to,
            }, [ 'from', 'to' ]),
        }).pipe(classifyItem(VariablePaymentOverview));
    }

    /**
     * Get overview of variable payments for all employees, indexed by employee id and business date.
     */
    getOverview(customerId: number, payTypeLinkId: number, from: DateTime, to: DateTime): Observable<VariablePaymentOverview> {
        return this.http.get<VariablePaymentOverviewResponse>(`/customers/${customerId}/variable_payments/overview`, {
            params: formatHttpParams({
                from,
                to,
                pay_type_link_id: payTypeLinkId,
            }, [ 'from', 'to' ]),
        }).pipe(classifyItem(VariablePaymentOverview));
    }

    create(customerId: number, employeeId: number, data: CreateData) {
        return this.http.post<VariablePaymentResponse>(`/customers/${customerId}/employees/${employeeId}/variable_payments`, data, {
            context: new HttpContext().set(BUSINESS_DATES, [ 'business_date' ]),
        }).pipe(classifyItem(VariablePayment));
    }

    update(customerId: number, employeeId: number, paymentId: number, data: UpdateBody) {
        return this.http.put<VariablePaymentResponse>(`/customers/${customerId}/employees/${employeeId}/variable_payments/${paymentId}`, data, {
            context: new HttpContext().set(BUSINESS_DATES, [ 'business_date' ]),
        }).pipe(classifyItem(VariablePayment));
    }

    delete(customerId: number, employeeId: number, paymentId: number) {
        return this.http.delete(`/customers/${customerId}/employees/${employeeId}/variable_payments/${paymentId}`);
    }

    deleteComment(customerId: number, employeeId: number, paymentId: number, commentId: number) {
        return this.http.delete(`/customers/${customerId}/employees/${employeeId}/variable_payments/${paymentId}/comments/${commentId}`);
    }
}
