// @ts-nocheck
import { module } from 'angular';
module('eaw.admin').factory('StackService', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = {};
    const res = EawResource.create('/stacks/:stack/:subQuery');
    fac.getAll = () => res.get();
    fac.getAllPaginated = (args) => res.get({ ...Pagination.getParams(args) });
    fac.getById = (id) => res.get({ stack: id });
    fac.getCustomersById = (id) => res.query({
        stack: id,
        subQuery: 'customers',
    });
    fac.create = (stackObj) => res.save(stackObj);
    fac.deleteById = (id) => res.delete({ stack: id });
    fac.getCustomersByIdPaginated = (args) => res.get({
        ...Pagination.getParams(args),
        stack: args.id,
        subQuery: args.subQuery,
    }, args);
    fac.updateById = (stack) => res.update({
        stack: stack.id,
    }, {
        name: stack.name,
    });
    // PROPERTIES
    fac.getProperties = (args) => res.get({
        ...Pagination.getParams(args),
        stack: args.id,
        subQuery: args.subQuery,
    }, args);
    fac.addProperty = (stackId, property) => res.save({
        stack: stackId,
        subQuery: 'properties',
    }, property);
    return fac;
} ]);
