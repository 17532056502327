// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';

module('eaw.filesystem').controller('fsBsNewCtrl', [ '$mdBottomSheet', function($mdBottomSheet) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.items = [
            {
                icon: 'upload',
                name: t('filesystem:UPLOAD'),
                click: ctrl.uploadFunction,
            },
            {
                icon: 'create_new_folder',
                name: t('filesystem:DIRECTORY'),
                click: ctrl.createDirectoryFunction,
            },
        ];
    };

    ctrl.itemClick = (item) => {
        $mdBottomSheet.hide();
        item.click();
    };
} ]);
