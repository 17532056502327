import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { Signable, SignableResponse } from '../../shared/models/signable';

export interface HrFileTypeResponse extends ApiResponse {
    files_count: number;
    id: number;
    mandatory: boolean;
    name: string;
    owner_id: number;
    owner_type: string;
    signable?: SignableResponse;
    accept_file_types: string[];
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
}

export class HrFileType {
    filesCount: number;
    id: number;
    mandatory: boolean;
    name: string;
    ownerId: number;
    ownerType: string;
    signable?: Signable;
    acceptFileTypes: string[];
    createdAt?: DateTime | null;
    updatedAt?: DateTime | null;
    deletedAt?: DateTime | null;

    constructor(data: HrFileTypeResponse) {
        this.acceptFileTypes = data.accept_file_types;
        this.filesCount = data.files_count;
        this.id = data.id;
        this.mandatory = data.mandatory;
        this.name = data.name;
        this.ownerId = data.owner_id;
        this.ownerType = data.owner_type;
        this.signable = data.signable ? new Signable(data.signable) : undefined;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
