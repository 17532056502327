import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { CurrentService } from '../../../shared/services/current.service';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { Customer } from '../../../shared/models/customer';
import { DateTime } from 'luxon';
import { firstValueFrom, switchMap } from 'rxjs';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { CustomerAvailabilityOverviewTableComponent } from '../../../availability/components/customer-availability-overview-table/customer-availability-overview-table.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'eaw-availability',
    templateUrl: './chain-availability.component.html',
    styleUrl: './chain-availability.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        PageHeaderComponent,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatButtonModule,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
        InfoLoadingComponent,
        CustomerAvailabilityOverviewTableComponent,
        ReactiveFormsModule,
    ],
})
export class ChainAvailabilityComponent implements OnInit {
    private customerGroupService = inject(CustomerGroupService);
    private current = inject(CurrentService);
    private permissionCheckService = inject(PermissionCheckService);

    showUnavailableToday = new FormControl<boolean>(false, { nonNullable: true });
    from = signal(DateTime.now().startOf('day'));
    customers = signal<Customer[]>([]);
    loadingGroups = signal(true);

    ngOnInit(): void {
        this.getGroups();
    }

    getGroups() {
        expandAllPages((pagination) => this.customerGroupService.getAllForCustomer(this.current.getCustomer().id, pagination), { page: 1, 'with[]': [ 'members' ] }).pipe(
            switchMap(async (groups) => {
                const permissions = groups.flatMap((group) => {
                    return group.members.map((c) => {
                        return `customers.${c.id}.availabilities.*.get`;
                    });
                });

                await firstValueFrom(this.permissionCheckService.isAllowedMany(permissions));

                return groups;
            }),
        ).subscribe((data) => {
            this.loadingGroups.set(false);

            // Use a map for uniqueness
            const customers = new Map<number, Customer>();
            data.flatMap((r) => r.members).forEach((c) => {
                if (this.permissionCheckService.single(`customers.${c.id}.availabilities.*.get`)) {
                    customers.set(c.id, c);
                }
            });

            this.customers.set(sort(Array.from(customers.values()), this.current.languageTag, [ (r) => r.name ]));
        });
    }
}
