import { ApiResponse } from '../../shared/interfaces/api-response';
import { DateTime } from 'luxon';
export interface ProjectionResponse extends ApiResponse {
    time: string;
    value: number;
    percent?: number;
}

export class Projection {
    time: DateTime;
    value: number;
    percent: number;

    constructor(data: ProjectionResponse) {
        this.value = data.value;
        this.percent = data.percent || 0;
        this.time = DateTime.fromFormat(data.time, 'yyyy-MM-dd HH:mm:ss', {
            zone: 'UTC',
        });
    }
}
