import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { AsyncPipe } from '@angular/common';
import { AbsenceReleaseShifts } from '../../types/types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { OnChange } from '../../../shared/types/on-change';
import { OnTouched } from '../../../shared/types/on-touched';

@Component({
    selector: 'eaw-absence-actions',
    standalone: true,
    imports: [
        MatFormField,
        MatLabel,
        MatSelect,
        ReactiveFormsModule,
        MatOption,
        TranslatePipe,
        TranslateSyncPipe,
        AsyncPipe,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: AbsenceActionsComponent,
        },
    ],
    templateUrl: './absence-actions.component.html',
    styleUrl: './absence-actions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsenceActionsComponent implements ControlValueAccessor {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    releaseShifts = new FormControl<AbsenceReleaseShifts>('keep', { nonNullable: true });
    private onChange?: OnChange<AbsenceReleaseShifts>;
    private onTouched?: OnTouched;
    private touched = false;

    constructor() {
        this.releaseShifts.valueChanges.pipe(
            tap((value) => {
                if (!this.touched) {
                    this.touched = true;
                    this.onTouched?.();
                }
                this.onChange?.(value);
                this.changeDetectorRef.markForCheck();
            }),
            takeUntilDestroyed(),
        ).subscribe();
    }

    writeValue(action: AbsenceReleaseShifts): void {
        this.releaseShifts.patchValue(action);
    }

    registerOnChange(fn: OnChange<AbsenceReleaseShifts>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: OnTouched): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.releaseShifts[isDisabled ? 'disable' : 'enable']();
        this.changeDetectorRef.markForCheck();
    }
}
