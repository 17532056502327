<a [href]="homeRoute" class="crumb">
    <mat-icon [eawSize]="'18px'">home</mat-icon>
</a>

@for (crumb of breadcrumbs(); track crumb.state.name) {
    <span class="separator">»</span>

    <a class="crumb" [href]="crumb.href" [class.loading]="crumb.loading()" [class.abstract]="crumb.state.abstract" [class.last]="$last">
        <span>{{ crumb.label() }}</span>
    </a>
}
