// @ts-nocheck
import { module } from 'angular';

const template1 = `<table class="table table-vmiddle">
    <tbody>
    <tr>
        <td ng-i18next="NAME"></td>
        <td ng-bind="$ctrl.file.name"></td>
    </tr>
    <tr>
        <td ng-i18next="hr:FILETYPE"></td>
        <td ng-bind="$ctrl.file.type.name"></td>
    </tr>
    <tr ng-if="$ctrl.file.showDownloadButton">
        <td ng-i18next="DOWNLOAD"></td>
        <td>
            <md-button class="md-icon-button" id="download" ng-click="$ctrl.file.download()">
                <md-icon ng-bind="'download'"></md-icon>
            </md-button>
        </td>
    </tr>
    </tbody>
</table>
`;

module('eaw.hr').component('hrFileInfo', {
    template: template1,
    bindings: {
        file: '<',
    },
});
