// @ts-nocheck
import { module } from 'angular';
import { TinyColor } from '@ctrl/tinycolor';
module('eaw.calendar').service('CalendarEvent', [ 'colorpickerService', '$rootScope', function CalendarEventClass(colorpickerService, $rootScope) {
    const keyFormat = 'YYYY-MM-DD';
    class CalendarEvent {
        id;
        visible;
        sourceObject;
        dataSource;
        date;
        employee;
        employee_id;
        uniqueId;
        owner_id;
        owner_type;
        name;
        description;
        from;
        to;
        created_at;
        updated_at;
        deleted_at;
        backgroundColor;
        textColor;
        holiday;
        vacation;
        length;
        isPeriod;
        wholeDay;
        absenceType;
        keys;
        constructor(data) {
            // Don't use ID as event identifier as there can be event's from different sources with the same ID
            this.id = data.id;
            this.visible = true;
            this.sourceObject = data;
            this.dataSource = data.dataSource || 'events';
            this.date = data.date;
            this.employee = data.employee;
            this.employee_id = data.employee_id;
            this.uniqueId = `${data.id}-${data.from.format('lll')}-${data.to.format('lll')}`;
            this.owner_id = data.owner_id;
            this.owner_type = data.owner_type;
            this.name = data.name;
            this.description = data.description;
            this.from = data.from;
            this.to = data.to;
            this.created_at = data.created_at;
            this.updated_at = data.updated_at;
            this.deleted_at = data.deleted_at;
            this.backgroundColor = colorpickerService.getHex(data.color);
            this.textColor = new TinyColor(this.backgroundColor).isDark() ? 'white' : 'black';
            this.holiday = !!data.holiday;
            this.vacation = !!data.vacation;
            this.length = this.to.diff(this.from, 's');
            this.isPeriod = this.length >= (60 * 60 * 24) - 1; // Check if it's an event over a longer period of time, subtract 1 for 'endOfDay'
            this.wholeDay = this.from.format('HH:mm:ss') === '00:00:00' && this.to.format('HH:mm:ss') === '23:59:59';
            this.absenceType = data.absenceType;
            this.setDayKeys();
        }

        setDayKeys() {
            const keys = [];
            const date = this.from.clone();
            /*
            Add all dates if a period (over one day)
            Ex. mon. 19:00 to tue. 06:00 is styles than a day but overlaps two days, but only show it on monday
             */
            if (this.isPeriod) {
                while (date.isBefore(this.to)) {
                    keys.push(CalendarEvent.getKey(date));
                    date.add(1, 'd');
                }
            } else {
                keys.push(CalendarEvent.getKey(date));
            }
            this.keys = keys;
        }

        broadcastAdded() {
            $rootScope.$broadcast(`calendar:event:added`, this);
        }

        broadcastUpdated() {
            $rootScope.$broadcast(`calendar:event:updated`, this);
        }

        broadcastDeleted() {
            $rootScope.$broadcast(`calendar:event:deleted`, this);
        }

        static onAdded(scope, callback) {
            return scope.$on('calendar:event:added', callback);
        }

        /**
         * @param {moment.Moment} date
         * @returns {String}
         */
        static getKey(date) {
            return date.format(keyFormat);
        }
    }
    return CalendarEvent;
} ]);
