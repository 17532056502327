import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { NotificationType } from '../classes/notification-type';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface NotificationSocketResponse extends ApiResponse {
    type: string;
    title: string;
    intro: string;
    id: string;
    body: string[];
    url: string;
    [key: string]: any;
}

export interface NotificationResponse extends ApiResponse {
    created_at?: string | undefined;
    id: string;
    notifiable_id?: number;
    notifiable_type?: string;
    read_at?: string | null;
    type: string;
    updated_at?: string | undefined;
    data: {
        customer_id?: number;
        body: string[];
        intro: string;
        title: string;
        url: string;
        [k: string]: any; // Allows for added properties (e.g. Shift id, schedule id) that are used in route parameters
    }
}

export class Notification {
    readonly _response: NotificationResponse;
    id: string;
    createdAt: DateTime | null;
    notifiableId: number | undefined;
    notifiableType: string | undefined;
    readAt: DateTime | null;
    type: NotificationType | undefined;
    updatedAt: DateTime | null;
    body: string;
    intro: string;
    title: string;
    url: string | undefined;
    createdRelative: string | null | undefined;
    routeParameters: Record<string, any> = {};

    constructor(response: NotificationResponse) {
        this._response = Object.freeze(response);
        this.id = response.id;
        this.notifiableId = response.notifiable_id;
        this.notifiableType = response.notifiable_type;
        this.type = NotificationType.get(response.type);
        this.body = response.data.body.filter((b) => b !== 'Log in to easy@work to process this request.').join('\n'); // Exclude log in to easyatwork
        this.intro = response.data.intro;
        this.title = response.data.title;
        this.readAt = response.read_at ? stringToDateTime(response.read_at) : null;
        this.createdAt = response.created_at ? stringToDateTime(response.created_at) : null;
        this.updatedAt = response.updated_at ? stringToDateTime(response.updated_at) : null;
        this.createdRelative = this.createdAt?.toRelative();
        this.url = response.data.url; // .replace('https://app.easyatwork.com', 'http://localhost:8080');
        // Eagerly finds the route parameters
        Object.entries(this.type?.params ?? {}).forEach(([ key, value ]) => {
            this.routeParameters[key] = response.data[value];
        });

        this.routeParameters['customer_id'] = response.data.customer_id;

        if (this.title === this.body) {
            this.body = '';
        }
    }

    static fromSocket(data: NotificationSocketResponse) {
        return new Notification({
            id: data.id,
            type: data.type,
            data: Object.entries(data).reduce((acc, [ key, value ]) => {
                if (key != 'id' && key != 'type') {
                    acc[key] = value;
                }

                return acc;
            }, {} as NotificationResponse['data']),
        });
    }

    getRoute() {
        return this.type?.route;
    }

    getRouteParams() {
        return this.routeParameters;
    }
}
