import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { ContractHourDay, ContractHourDayResponse } from '../models/contract-hour-day';

@Injectable({
    providedIn: 'root',
})
export class ContractHourDayService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    create(customerId: number, employeeId: number, distributionId: number, hours: number, index: number) {
        return this.http.post<ContractHourDayResponse>(`/customers/${customerId}/employees/${employeeId}/contract_hours_distributions/${distributionId}/days`, {
            hours,
            index,
        }).pipe(classifyItem(ContractHourDay));
    }

    update(customerId: number, employeeId: number, distributionId: number, dayId: number, hours: number) {
        return this.http.put<ContractHourDayResponse>(`/customers/${customerId}/employees/${employeeId}/contract_hours_distributions/${distributionId}/days/${dayId}`, {
            hours,
        }).pipe(classifyItem(ContractHourDay));
    }
}
