import { ApiResponse } from '../interfaces/api-response';
import { OrganizationMember, OrganizationMemberResponse } from './organization-member';

export interface OrganizationResponse extends ApiResponse {
    id: string;
    name: string;
    members: OrganizationMemberResponse[];
}

export class Organization {
    id: string;
    name: string;
    members: OrganizationMember[];

    constructor(organization: OrganizationResponse) {
        this.id = organization.id;
        this.name = organization.name;
        this.members = organization.members.map((member) => new OrganizationMember(member));
    }
}
