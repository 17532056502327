@if (forecast()) {
    <table mat-table [dataSource]="dataSource">
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>

        <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef> {{ 'DAY' | translate | async }}</th>
            <td mat-cell *matCellDef="let element" [class.border]="element.border"> {{ element.day }} <small class="time">{{ element.time() | DateTime: 'TIME_SIMPLE' }}</small>
            </td>
        </ng-container>

        <ng-container matColumnDef="temperature">
            <th mat-header-cell *matHeaderCellDef>{{ 'TEMPERATURE' | translate | async }}</th>
            <td mat-cell *matCellDef="let element" [class.border]="element.border">
                <eaw-temperature [forecastTime]="element.temperature"></eaw-temperature>
            </td>
        </ng-container>

        <ng-container matColumnDef="rain">
            <th mat-header-cell *matHeaderCellDef> {{ 'PRECIPITATION' | translate | async }}</th>
            <td mat-cell *matCellDef="let element" [class.border]="element.border">
                @if (element.precipitation) {
                    {{ element.precipitation | eawNumber: 1 }}mm
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="wind">
            <th mat-header-cell *matHeaderCellDef> {{ 'WIND' | translate | async }}</th>
            <td mat-cell *matCellDef="let element" [class.border]="element.border">
                @if (element.wind_speed) {
                    {{ element.wind_speed | eawNumber: 1 }}m/s
                }
            </td>
        </ng-container>
    </table>
    <eaw-met-no-credit></eaw-met-no-credit>
}
