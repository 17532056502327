// @ts-nocheck
import { module } from 'angular';
module('eaw.misc').service('ComplexTooltip', [ '$mdPanel', function ComplexTooltip($mdPanel) {
    /**
     * @param {MouseEvent}  event
     * @returns {*}
     */
    return function open(event, el, args) {
        const noMouseleave = args.noMouseleave ?? false;
        // Add listener before we open to see if mouse has left during the opening
        let mouseleave = false;
        let panelRef = null;
        if (!noMouseleave) {
            el.addEventListener('mouseleave', () => {
                mouseleave = true;
                panelRef?.close?.();
            }, { once: true });
            el.addEventListener('dragstart', () => {
                mouseleave = true;
                panelRef?.close?.();
            }, { once: true });
            if (args.parentNode) {
                args.parentNode.addEventListener('mouseleave', () => {
                    mouseleave = true;
                    panelRef?.close?.();
                }, { once: true });
            }
        }
        const relativePositionElement = args.parentNode || el;
        const position = args.position || $mdPanel.newPanelPosition()
            .relativeTo(relativePositionElement)
            .withOffsetY('-7')
            .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ABOVE);
        const animation = args.animation || $mdPanel.newPanelAnimation()
            .openFrom(event)
            .duration(80)
            .withAnimation($mdPanel.animation.FADE);
        $mdPanel.open({
            attachTo: document.body,
            controller: args.controller,
            controllerAs: args.controllerAs || 'ctrl',
            template: args.template,
            panelClass: [ 'eaw-panel' ].concat(args.panelClasses).join(' ').trim(),
            propagateContainerEvents: true,
            position,
            animation,
            // @ts-ignore
            targetEvent: event,
            focusOnOpen: false,
            groupName: [
                // @ts-ignore this method exists on $mdPanel but is not in the typing
                $mdPanel.newPanelGroup(args.groupName || 'complex-group', { maxOpen: 1 }),
            ],
            bindToController: true,
            clickOutsideToClose: true,
            locals: args.locals,
            onDomAdded(addedEvent) {
                // If mouse has left already, just destroy
                if (mouseleave) {
                    addedEvent[1].destroy();
                }
            },
        }).then((ref) => {
            panelRef = ref;
            // When the panel has opened, close it if mouse has already left
            if (mouseleave) {
                panelRef.close();
            }
        }, (e) => {
            console.error('error opening shift panel', e);
        });
    };
} ]);
