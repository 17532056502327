// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.admin').component('customerPayTypesTab', {
    template: `<script type="text/ng-template" id="paytypelinksContractTypes">
    <div ng-repeat="type in item.contract_types track by $index" ng-bind="type.name"></div>
</script>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="admin:PAY_TYPE_plural"></span>
        </md-card-header-text>

        <card-btn-float on-click="$ptlCtrl.add()"></card-btn-float>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <eaw-dynamic-ng-table
                columns="$ptlCtrl.table.columns"
                cells="$ptlCtrl.table.cells"
                get-data="$ptlCtrl.getData(pagination)"
                reload-table="$ptlCtrl.reloadTable"
                scope-vars="$ptlCtrl.table.vars">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$ptlCtrl',
    bindings: {
        customer: '<',
    },
    controller: [ 'PayTypeLinkFactory', '$mdDialog', 'ToastService', 'payTypeLinkDialog', function(PayTypeLinkFactory, $mdDialog, ToastService, payTypeLinkDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.table = {
                columns: [
                    {
                        title: t('ID'),
                        sortable: 'id',
                    },
                    {
                        title: t('CODE'),
                        sortable: 'code',
                        class: 'text-right',
                    },
                    {
                        title: t('NAME'),
                        sortable: 'name',
                    },
                    {
                        title: t('admin:PAY_TYPE'),
                        sortable: 'pay_type_id',
                        class: 'text-right',
                    },
                    {
                        title: t('admin:PAY_TYPE_NAME'),
                        sortable: 'pay_type_id',
                    },
                    { title: t('admin:RETURN_UNIT') },
                    {
                        title: t('TARIFF'),
                        sortable: 'tariff_id',
                    },
                    { title: t('admin:CONTRACT_TYPE_plural') },
                    { title: t('admin:INCLUDE_IN_LABOR_COST') },
                    { title: t('admin:EFFECT_ON_LABOR_COST') },
                    {
                        title: t('FROM'),
                        sortable: 'from',
                    },
                    {
                        title: t('TO'),
                        sortable: 'to',
                    },
                    {
                        title: t('CALCULATOR'),
                        sortable: 'calculator_id',
                    },
                    { title: '' },
                ],
                cells: [
                    { template: 'item.id' },
                    {
                        template: 'item.code',
                        classes: [ 'text-right' ],
                    },
                    { template: 'item.name' },
                    {
                        template: 'item.pay_type.id',
                        classes: [ 'text-right' ],
                    },
                    { template: 'item.pay_type.name' },
                    { template: 'item.return_unit' },
                    { template: 'item.tariff.name' },
                    { templateUrl: 'paytypelinksContractTypes' },
                    { template: 'item.include_in_labor_cost ? ("YES" | i18next) : ("NO" | i18next)' },
                    { template: 'item.effect_on_labor | uppercase | i18next' },
                    { template: 'item.from | moment' },
                    { template: 'item.to | moment' },
                    { template: 'item.calculator.name' },
                    {
                        buttons: [
                            {
                                icon: 'edit',
                                click: ctrl.update,
                            },
                            {
                                icon: 'delete',
                                type: 'warn',
                                click: ctrl.remove,
                            },
                        ],
                    },
                ],
            };
        };

        ctrl.add = () => {
            payTypeLinkDialog.add(ctrl.customer).then((link) => {
                link.customer_id = ctrl.customer.id;
                PayTypeLinkFactory.add(link).$promise.then((data) => {
                    ToastService.toast(`${data.name} created`);
                    ctrl.reloadTable = true;
                });
            });
        };

        ctrl.remove = (payTypeLink) => {
            $mdDialog.show(
                $mdDialog.confirm()
                    .title('Delete pay type')
                    .textContent('Delete pay type from customer?')
                    .ok(t('DELETE'))
                    .cancel(t('CANCEL')),
            ).then(() => {
                PayTypeLinkFactory.delete({
                    pay_type_link: payTypeLink,
                    customer_id: ctrl.customer.id,
                }).$promise.then(() => {
                    ToastService.toast('Removed paytype');
                    ctrl.reloadTable = true;
                });
            });
        };

        ctrl.update = (payTypeLink) => {
            const ptl = { ...payTypeLink };

            payTypeLinkDialog.update(ptl, ctrl.customer).then((link) => {
                link.customer_id = ctrl.customer.id;
                if (!link.to) {
                    link.to = null;
                }

                PayTypeLinkFactory.update(link).$promise.then(() => {
                    ctrl.reloadTable = true;
                    ToastService.updated();
                });
            });
        };

        ctrl.getData = (pagination) => PayTypeLinkFactory.getAll(Object.assign(pagination, {
            customer_id: ctrl.customer.id,
            with: [ 'payType', 'calculator', 'properties', 'tariff', 'contractTypes', 'absenceTypes' ],
        },
        ));
    } ],
});
