// @ts-nocheck
import { module } from 'angular';
import { lastValueFrom } from 'rxjs';
const template1 = `<md-dialog>
    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2 ng-i18next="scheduling:UNIT_FILTER" flex></h2>
            <md-icon ng-bind="'close'" ng-click="unitFilterCtrl.cancel()"></md-icon>
        </div>
    </md-toolbar>
    <md-dialog-content>
        <md-list flex>
            <md-list-item>
                <p ng-i18next="scheduling:SHIFT_NO_UNIT"></p>
                <md-checkbox class="md-secondary" ng-model="unitFilterCtrl.UnitFilter.shiftWithoutUnit"></md-checkbox>
            </md-list-item>
            <md-list-item ng-repeat="unit in ::unitFilterCtrl.UnitFilter.units track by unit.id">
                <p>{{unit.name}}</p>
                <md-checkbox class="md-secondary" ng-model="unit.selected"></md-checkbox>
            </md-list-item>
        </md-list>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button ng-click="unitFilterCtrl.UnitFilter.reset()" ng-i18next="RESET"></md-button>
        <span flex></span>
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-click="unitFilterCtrl.submit()" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.scheduling').service('UnitFilter', [ '$mdDialog', 'BusinessUnitDowngraded', function($mdDialog, BusinessUnitDowngraded) {
    const service = this;
    /**
     * @param {Schedule} schedule
     * @returns {promise}
     */
    service.open = (schedule) => {
        service.schedule = schedule;
        return $mdDialog.show({
            template: template1,
            controllerAs: 'unitFilterCtrl',
            controller: 'UnitFilterCtrl',
            resolve: {
                /** @ngInject */
                units: service.unitsResolve,
            },
        });
    };
    service.unitsResolve = async () => {
        if (!service.units) {
            service.units = await lastValueFrom(BusinessUnitDowngraded.getAllPages(service.schedule.customer_id, {}, true));
        }
        return service.units;
    };
    service.getSelectedUnits = () => service.units?.filter((u) => u.selected) || [];
    service.filterEnabled = () => service.shiftWithoutUnit || service.units?.some((u) => u.selected);
    service.reset = () => {
        service.shiftWithoutUnit = false;
        service.units?.forEach((u) => u.selected = false);
    };
} ]);
