import { Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

export type CardColor = 'blue' | 'red' | 'yellow' | 'green' | 'white';

@Component({
    selector: 'eaw-info-box',
    templateUrl: './info-box.component.html',
    styleUrl: './info-box.component.scss',
    standalone: true,
    imports: [ MatIconModule ],
})
export class InfoBoxComponent {
    @HostBinding('class') get klass() {
        return this.color;
    };

    @Input() icon = 'info';
    @Input() color: CardColor = 'blue';
}
