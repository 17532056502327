import { Inject, Injectable } from '@angular/core';
import { Autocomplete } from './autocomplete';
import { BusinessUnit } from '../../business-units/models/business-unit';
import { TranslateService } from '../services/translate.service';
import { Observable, of } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { BusinessUnitService } from '../../business-units/http/business-unit.service';

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitAutocompleteService extends Autocomplete<BusinessUnit> {
    constructor(
        @Inject(TranslateService) protected translate: TranslateService,
        @Inject(BusinessUnitService) protected businessUnitService: BusinessUnitService,
    ) {
        super({
            display: 'name',
            trackByKey: 'id',
            label: translate.t('BUSINESS_UNIT_plural'),
        });
    }

    getter(customerId: number): (filter?: string) => Observable<ArrayPaginatedResponse<BusinessUnit>> {
        return (filter?: string) => {
            return this.businessUnitService.getAll(customerId, { filter, per_page: 64 });
        };
    }

    setter(customerId: number): (item: unknown) => Observable<BusinessUnit | undefined> {
        return (item: unknown) => {
            if (item instanceof BusinessUnit) {
                return of(item);
            }

            if (typeof item === 'number') {
                return this.businessUnitService.get(customerId, item);
            }

            return of(undefined);
        };
    }
}
