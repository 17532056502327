<eaw-dialog-header>
    <span title>{{ 'NEW_CUSTOM_FIELD' | translate: 'admin' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form class="tw-flex tw-flex-col tw-gap-16" [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'NAME' | translate: 'admin' | async }}</mat-label>
            <input matInput formControlName="name">
            <mat-error>
                <eaw-field-error [control]="form.controls.name"/>
            </mat-error>
        </mat-form-field>

        <mat-card eawMaterialColor="yellow-100" colorProperty="backgroundColor" class="tw-mb-24">
            <mat-card-content layout="column">
                <span>Remember to enter the name as a translation in WebTranslateIt.</span>
                <span>Put the new translation in the <code><strong>custom_fields</strong></code> namespace.</span>
                <span>They have to be an exact match.</span>
                <strong>If you don't know or are unsure how to do it, then ask somebody!</strong>
                <br><br>
                <a class="tw-mt-12" target="_blank"
                   href="https://webtranslateit.com/en/projects/15497-easy-work-2-0/locales/en-US..no/strings?sort_by=created_at&file=801207">Add
                    translation (New window)</a>
            </mat-card-content>
        </mat-card>

        <mat-form-field>
            <mat-label>{{ 'TYPE' | translate | async }}</mat-label>
            <mat-select formControlName="type">
                @for (type of types; track type) {
                    <mat-option [value]="type">{{ type }}</mat-option>
                }
            </mat-select>
            <mat-error>
                <eaw-field-error [control]="form.controls.type"/>
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'CODE' | translate | async }}</mat-label>
            <input matInput formControlName="key">
            <mat-error>
                <eaw-field-error [control]="form.controls.key"/>
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [loading]="loading()" [disabled]="form.invalid"
                       (click)="submit()">{{ 'SUBMIT' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
