import { ReportOption, ReportOptions } from '../interfaces/report-option';
import { ApiResponse } from '../../shared/interfaces/api-response';

export type ReportFormat = 'csv' | 'html' | 'xlsx' | 'pdf' | 'vpayroll';
export type ReportFormats = Partial<Record<ReportFormat, string>>;

export interface ReportResponse extends ApiResponse {
    id: number;
    owner_id: number
    owner_type: string
    owner?: any
    description: string
    name: string,
    formats: ReportFormats,
    class: string,
    options?: Record<string, ReportOption>
}

export class Report {
    readonly _response;

    id: number;
    ownerId: number;
    ownerType: string;
    owner?: unknown;
    description: string;
    name: string;
    formats: ReportFormats;
    options?: ReportOptions;
    class: string;

    constructor(data: ReportResponse) {
        this._response = Object.freeze(data);
        this.id = data.id;
        this.ownerId = data.owner_id;
        this.ownerType = data.owner_type;
        this.description = data.description;
        this.name = data.name;
        this.formats = data.formats;
        this.class = data.class;

        if (data.options) {
            this.options = new Map<string, ReportOption>();

            // Preserve the order of the options the way they came from the API
            Object.keys(data.options).forEach((key) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.options!.set(key, data.options![key]!);
            });
        }
    }
}
