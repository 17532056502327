// @ts-nocheck
import { module } from 'angular';
import { pick } from 'lodash-es';
module('eaw.company').service('ExternalEmployeeFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const url = '/customers/:customer/external_employees/:employee';
    return {
        create(customerId, employeeId, data) {
            return EawResource.create(url).save({
                customer: customerId,
            }, {
                employee_id: employeeId,
                ...pick(data, [ 'cost', 'from', 'to', 'number' ]),
            });
        },
        get(customerId, employeeId, withRelations) {
            return EawResource.create(url).get({
                customer: customerId,
                employee: employeeId,
                'with[]': withRelations,
            });
        },
        getAll(args) {
            return EawResource.create(url).get({
                customer: args.customerId,
                ...Pagination.getParams(args, [ 'include_inactive' ]),
            });
        },
        linkable(customerId, pagination) {
            return EawResource.create(url).get({
                customer: customerId,
                employee: 'linkable',
                ...Pagination.getParams(pagination),
            });
        },
        /**
         *
         * @param {integer} customerId
         * @param {integer} employeeId
         * @param {object} data
         * @param {moment} data.from
         * @param {moment} data.to
         * @param {integer} data.cost
         */
        update(customerId, employeeId, data) {
            return EawResource.create(url).update({
                customer: customerId,
                employee: employeeId,
            }, pick(data, [ 'cost', 'from', 'to', 'number', 'resolve_termination' ]));
        },
        delete(customerId, employeeId) {
            return EawResource.create(url).delete({
                customer: customerId,
                employee: employeeId,
            });
        },
    };
} ]);
