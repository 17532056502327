import { module } from 'angular';
import { EawFormElement, EawFormElementGroup } from '../../../types/form';
import { FormController } from '../eaw-form/form.component';

export class FormElementController {
    element: any;
    submission: any;
    data: any;
    formCtrl!: FormController;
    $onInit() {
        this.setSubmission();
    }

    $onChanges() {
        this.setSubmission();
    }

    onChange() {
        const element = this.element;
        const submission = element.type == 'date' && element.data.format ? this.submission.format(element.data.format) : this.submission;
        this.formCtrl.onChange(element.name, submission);
    }

    isGroup() {
        return this.element instanceof EawFormElementGroup;
    }

    setSubmission() {
        if (this.element instanceof EawFormElement) {
            this.submission = this.element.getValue(this.data);
        }
    }
}
module('eaw.forms').component('eawFormElement', {
    template: `<label ng-if="$elCtrl.isGroup() && $elCtrl.element.label">
    <h4 ng-bind="$elCtrl.element.label"></h4>
</label>

<div layout="{{$elCtrl.element.layout === 'h' ? 'row' : 'column'}}">
    <eaw-form-element ng-if="::$elCtrl.isGroup()" ng-repeat="element in $elCtrl.element.children track by element.id"
                      flex
                      element="element"
                      data="$elCtrl.data">
    </eaw-form-element> <!-- recursion! -->

    <!-- Turns out it wasn't a group but an element... -->
    <div ng-if="!($elCtrl.isGroup() || $elCtrl.element.name == 'anonymous' && $elCtrl.formCtrl.disabled)"
         flex>
        <div ng-switch on="$elCtrl.element.type" layout="{{$elCtrl.element.label_position === 'l' ? 'row' : 'column'}}"
             layout-align="start center">

            <label for="element{{::$elCtrl.element.id}}"
                   class="tw-pr-12"
                   ng-if="$elCtrl.element.label_position == 'l'"
                   ng-bind="$elCtrl.element.label">
            </label>

            <md-input-container class="error-spacer"
                                ng-switch-when="input|date|textarea|number"
                                ng-switch-when-separator="|"
                                ng-switch
                                md-no-float
                                on="$elCtrl.element.type">
                <label for="element{{::$elCtrl.element.id}}"
                       class="md-no-float"
                       ng-if="$elCtrl.element.label_position == 'o'"
                       ng-bind="$elCtrl.element.label"></label>
                <!-- text-/number fields -->
                <input ng-switch-when="input" id="element{{::$elCtrl.element.id}}"
                       ng-required="$elCtrl.element.data.required"
                       ng-change="$elCtrl.onChange()"
                       ng-maxlength="$elCtrl.element.data.maxlength"
                       ng-model="$elCtrl.submission" name="{{::$elCtrl.element.name}}">

                <!-- Date only -->
                <eaw-date-picker
                        ng-switch-when="date" ng-if="$elCtrl.element.data.date && !$elCtrl.element.data.time"
                        no-label="true"
                        ng-disabled="$elCtrl.formCtrl.disabled"
                        ng-change="$elCtrl.onChange()"
                        ng-model="$elCtrl.submission"
                        ng-required="$elCtrl.element.data.required">
                </eaw-date-picker>

                <!-- Time only -->
                <eaw-time-picker-old
                        ng-switch-when="date"
                        label="false"
                        is-disabled="$elCtrl.formCtrl.disabled"
                        ng-if="!$elCtrl.element.data.date && $elCtrl.element.data.time"
                        ng-change="$elCtrl.onChange()"
                        ng-model="$elCtrl.submission"
                        ng-required="$elCtrl.element.data.required">
                </eaw-time-picker-old>

                <!-- Date and time -->
                <eaw-date-time-picker
                        is-disabled="$elCtrl.formCtrl.disabled"
                        format="$elCtrl.element.data.format"
                        ng-switch-when="date"
                        ng-if="$elCtrl.element.data.date && $elCtrl.element.data.time"
                        ng-change="$elCtrl.onChange()"
                        ng-model="$elCtrl.submission"
                        ng-required="$elCtrl.element.data.required">
                </eaw-date-time-picker>

                <textarea ng-switch-when="textarea" id="element{{::$elCtrl.element.id}}"
                          rows="{{::$elCtrl.element.data.rows || 8}}"
                          ng-change="$elCtrl.onChange()"
                          ng-maxlength="$elCtrl.element.data.maxlength"
                          ng-required="$elCtrl.element.data.required"
                          ng-model="$elCtrl.submission"
                          ng-model-options="{debounce: 500}"
                          name="{{::$elCtrl.element.name}}">
                    </textarea>

                <input ng-switch-when="number" type="number" id="element{{::$elCtrl.element.id}}"
                       ng-required="$elCtrl.element.data.required"
                       ng-change="$elCtrl.onChange()"
                       max="{{::$elCtrl.element.data.max}}" min="{{::$elCtrl.element.data.min}}"
                       ng-model="$elCtrl.submission" name="{{::$elCtrl.element.name}}">
            </md-input-container>

            <div ng-switch-when="radio" ng-class="$elCtrl.element.type" layout="column" layout-margin>
                <div>
                    <input type="radio" id="element{{::$elCtrl.element.id}}"
                           ng-required="$elCtrl.element.data.required"
                           ng-value="$elCtrl.element.label"
                           ng-change="$elCtrl.onChange()"
                           ng-model="$elCtrl.submission" name="{{::$elCtrl.element.name}}">
                    <label for="element{{::$elCtrl.element.id}}"
                           ng-if="$elCtrl.element.label_position == 'r'"
                           ng-bind="$elCtrl.element.label">
                    </label>
                </div>
            </div>

            <div ng-switch-when="checkbox">
                <md-checkbox id="element{{::$elCtrl.element.id}}"
                             name="{{::$elCtrl.element.name}}"
                             ng-change="$elCtrl.onChange()"
                             ng-model="$elCtrl.submission">
                        <span for="element{{::$elCtrl.element.id}}"
                              ng-if="$elCtrl.element.label_position == 'r'"
                              ng-bind="$elCtrl.element.label">
                        </span>
                </md-checkbox>
            </div>

            <md-input-container ng-switch-when="select">
                <label ng-bind="::$elCtrl.element.label" ng-if="$elCtrl.element.label_position == 'o'"></label>
                <md-select id="element{{::$elCtrl.element.id}}"
                           name="{{::$elCtrl.element.name}}"
                           ng-change="$elCtrl.onChange()"
                           ng-required="$elCtrl.element.data.required"
                           ng-disabled="$elCtrl.formCtrl.disabled"
                           ng-model="$elCtrl.submission">
                    <md-option ng-repeat="option in $elCtrl.element.data.options track by option.value"
                               ng-value="option.value">
                        {{option.name}}
                    </md-option>
                </md-select>
            </md-input-container>

            <!-- static elements -->
            <img ng-switch-when="image"
                 ng-src="{{::$elCtrl.element.data.src}}"
                 alt="{{$elCtrl.element.label}}"
                 title="{{$elCtrl.element.data.title || $elCtrl.element.label}}"
                 width="{{$elCtrl.element.data.width}}"
                 height="{{$elCtrl.element.data.height}}"
                 id="{{::$elCtrl.element.name}}">

            <p ng-switch-when="text" name="{{$elCtrl.element.name}}" ng-bind="::$elCtrl.element.label"></p>

            <label for="element{{::$elCtrl.element.id}}"
                   ng-switch-when="input|date|textarea|number|select"
                   ng-switch-when-separator="|"
                   ng-if="$elCtrl.element.label_position == 'u' || $elCtrl.element.label_position == 'r'"
                   ng-bind="::$elCtrl.element.label"></label>
        </div>
    </div>
</div>
`,
    controllerAs: '$elCtrl',
    require: {
        formCtrl: '^eawForm',
    },
    bindings: {
        element: '<',
        data: '<?',
    },
    controller: FormElementController,
});
