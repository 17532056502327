// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { DateTimeConverter } from '../../../../utils/date-time-converter';
module('eaw.time').component('eawDatePicker', {
    template: `<eaw-input-button icon="close" ng-click="$dp.clearValue()" ng-if="!$dp.noClear" ng-show="!$dp.disabled && !$dp.readonly && $dp.date"></eaw-input-button>
<label for="{{$dp.name}}" ng-i18next="{{::$dp.label}}" ng-if="$dp.label" ng-class="{'md-required': $dp.required}"></label>
<md-datepicker ng-model="$dp.date"
               ng-disabled="$dp.readonly || $dp.disabled"
               id="{{$dp.name}}"
               name="{{$dp.name}}"
               md-current-view="{{$dp.currentView}}"
               md-date-filter="$dp.dateFilter"
               md-hide-icons="all"
               ng-click="$dp.setOpen(true)"
               md-current-view="$dp.currentView"
               md-placeholder="{{$dp.placeholder}}"
               md-is-open="$dp.open"
               ng-change="$dp.setValue($dp.date)"
               ng-keyup="$dp.keyup($event)"
               ng-required="$dp.required"
               md-mode="{{$dp.mode}}"
               md-min-date="$dp.min"
               md-max-date="$dp.max">
</md-datepicker>
`,
    controllerAs: '$dp',
    require: {
        ngModel: 'ngModel',
        dateInterval: '?^eawDateInterval',
    },
    bindings: {
        noLabel: '<?',
        label: '@?',
        minDate: '<?',
        maxDate: '<?',
        dateFilter: '<?',
        businessDate: '<?',
        pickerRole: '@?',
        mode: '@?',
        currentView: '@?',
        noClear: '<?',
    },
    controller: [ '$element', '$attrs', '$timeout', function($element, $attrs, $timeout) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.element = $element[0];
            ctrl.label = ctrl.label || ctrl.noLabel ? '' : 'DATE';
            // @ts-ignore
            ctrl.format = moment().localeData()._config.longDateFormat['l']; // Found in LanguageChangerService.mdDateFormat
            ctrl.placeholder = ctrl.placeholder || ctrl.format;
            $attrs.$observe('required', (value) => {
                ctrl.required = value;
            });
            $attrs.$observe('disabled', (value) => {
                ctrl.disabled = value;
            });
            $attrs.$observe('readonly', (value) => {
                ctrl.readonly = value;
            });
            $attrs.$observe('name', (value) => {
                ctrl.name = value;
            });
            // Only observe if inside a date interval
            if (ctrl.dateInterval && ctrl.pickerRole) {
                ctrl.observeDates();
            }
            ctrl.ngModel.$formatters.unshift(ctrl.formatModel);
            ctrl.ngModel.$parsers.unshift(ctrl.parseModel);
            ctrl.ngModel.$validators.beforeMinDate = ctrl.beforeMinValidator;
            ctrl.ngModel.$validators.afterMaxDate = ctrl.afterMaxValidator;
        };
        ctrl.$postLink = () => {
            ctrl.element.querySelector('input')?.setAttribute('autocomplete', 'off');
            document.body.addEventListener('click', ctrl.outsideClick);
        };
        ctrl.$onChanges = (changes) => {
            const changedMinDate = changes.minDate?.currentValue;
            const changedMaxDate = changes.maxDate?.currentValue;
            if (changedMinDate) {
                ctrl.min = ctrl.toDate(changedMinDate);
            }
            if (changedMaxDate) {
                ctrl.max = ctrl.toDate(changedMaxDate);
            }
            ctrl.ngModel.$validate();
        };
        ctrl.$onDestroy = () => {
            ctrl.observer?.disconnect();
            document.body.removeEventListener('click', ctrl.outsideClick);
        };
        ctrl.keyup = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
            }
        };
        ctrl.outsideClick = (event) => {
            if (!ctrl.open) {
                return;
            }
            if (document.elementsFromPoint(event.x, event.y).includes(ctrl.element)) {
                return;
            }
            $timeout(() => {
                document.activeElement?.blur();
            });
        };
        ctrl.observeDates = () => {
            ctrl.observer = new MutationObserver(() => {
                if (ctrl.open) {
                    // Flip role
                    const siblingRole = ctrl.pickerRole === 'from' ? 'to' : 'from';
                    // Find the other date picker inside the date interval
                    // Date is YYYY-MM-DD
                    const date = $element[0]
                        ?.closest('eaw-date-interval')
                        ?.querySelector(`eaw-date-picker[picker-role="${siblingRole}"]`)
                        ?.dataset
                        ?.selectedDate;
                    // Add highlight class to the date in the sibling
                    document.querySelector(`[id$="${date}"]`)?.classList.add('sibling-date');
                }
            });
            ctrl.observer.observe(document.body, {
                childList: true,
                subtree: true,
            });
        };
        ctrl.beforeMinValidator = (date) => {
            const minDate = ctrl.toMoment(ctrl.minDate);
            const checkDate = ctrl.toMoment(date);
            if (!checkDate || !minDate) {
                return true;
            }
            return minDate.isSameOrBefore(checkDate, 'd');
        };
        ctrl.afterMaxValidator = (date) => {
            const maxDate = ctrl.toMoment(ctrl.maxDate);
            const checkDate = ctrl.toMoment(date);
            return !checkDate || !maxDate ? true : maxDate.isSameOrAfter(checkDate, 'd');
        };
        /**
         * @param {Date} date
         * @return {*}
         */
        ctrl.parseModel = (date) => {
            // Update attr
            ctrl.addDateAttr(date);
            // Convert to moment
            let parsedDate = ctrl.toMoment(date);
            // Convert to string if we are dealing with a business date
            if (ctrl.businessDate && !ctrl.dateInterval?.businessDate) {
                parsedDate = DateTimeConverter.convertMomentToBusinessDate(parsedDate);
            }
            return parsedDate;
        };
        ctrl.addDateAttr = (date) => {
            if (!(date instanceof Date)) {
                return;
            }
            // Update data attribute on datepicker to show the date selected
            $element[0].dataset.selectedDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
        };
        ctrl.formatModel = (val) => {
            if (!(val instanceof Date || val?.isValid?.())) {
                return;
            }
            // Make it a date
            ctrl.date = ctrl.toDate(val);
            // Update attr
            ctrl.addDateAttr(ctrl.date);
            // Return the date
            return ctrl.date;
        };
        ctrl.toDate = (date) => {
            if (date instanceof Date) {
                return date;
            }
            if (moment.isMoment(date)) {
                return new Date(date.year(), date.month(), date.date());
            }
        };
        ctrl.toMoment = (date) => {
            if (date instanceof Date) {
                // Create an initial moment with the customer's timezone
                // Then apply the date after so that the date is not changed
                const m = moment().startOf('d');
                m.year(date.getFullYear());
                m.month(date.getMonth());
                m.date(date.getDate());
                return m;
            }
            // If it's already a moment then just clone it
            if (moment.isMoment(date)) {
                return date.clone();
            }
        };
        ctrl.setValue = (value) => {
            if (value) {
                ctrl.ngModel.setViewValue(value);
            } else {
                ctrl.clearValue();
            }
            ctrl.setOpen(false);
        };
        ctrl.setOpen = (open) => {
            ctrl.open = open;
        };
        ctrl.clearValue = () => {
            ctrl.date = undefined;
            ctrl.ngModel.setViewValue(undefined);
        };
    } ],
});
