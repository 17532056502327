import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Customer } from '../../../../../shared/models/customer';
import { User } from '../../../../../shared/models/user';
import { UserGroupService } from '../../../../../shared/http/user-group.service';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { catchError, EMPTY, forkJoin } from 'rxjs';
import { MatListModule } from '@angular/material/list';
import { UserGroup } from '../../../../models/user-group';
import { DateTime } from 'luxon';
import { SettingService } from '../../../../../shared/http/setting.service';
import { InfoLoadingComponent } from '../../../../../shared/components/info-loading/info-loading.component';
import { NewEmployeeNextStepComponent } from '../../components/new-employee-next-step/new-employee-next-step.component';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';
import { InfoBoxComponent } from '../../../../../shared/components/info-card/info-box.component';
import { PredefinedSettings } from '../../../../../shared/enums/predefined-settings';

@Component({
    selector: 'eaw-new-employee-user-groups',
    standalone: true,
    imports: [ CommonModule, InfoLoadingComponent, TranslatePipe, NewEmployeeNextStepComponent, MatListModule, InfoBoxComponent ],
    templateUrl: './new-employee-user-groups.component.html',
    styleUrl: './new-employee-user-groups.component.scss',
})
export class NewEmployeeUserGroupsComponent extends NewEmployeeStepComponent implements OnInit {
    private readonly userGroupService = inject(UserGroupService);
    private readonly settingService = inject(SettingService);

    @Input({ required: true }) customer!: Customer;
    @Input({ required: true }) user!: User;

    loadingData = true;
    maxGroups?: number;
    userGroups: UserGroup[] = [];
    selectedGroups = new Set<number>();
    processingGroups = new Set<number>();

    override ngOnInit() {
        super.ngOnInit();

        forkJoin([
            this.userGroupService.getAllForCustomer(this.customer.id, { per_page: 50, order_by: 'name', direction: 'asc' }),
            this.userGroupService.getAllForCustomer(this.customer.id, { user_id: this.user.id }),
            this.settingService.getValue<number>([ 'customers', this.customer.id ], PredefinedSettings.MaxUserGroups),
        ]).subscribe(([ userGroups, userUserGroups, maxGroups ]) => {
            this.loadingData = false;
            this.userGroups = userGroups.data;
            this.maxGroups = maxGroups ?? undefined;

            userUserGroups.data.forEach((userGroup: UserGroup) => {
                const from = userGroup.pivot.from;
                const to = userGroup.pivot.to;
                if (from != null && from <= DateTime.now() && (to == null || to >= DateTime.now())) {
                    this.selectedGroups.add(userGroup.id);
                }
            });

            this.updateComplete();
        });
    }

    updateComplete() {
        const lessThanMaxGroups = this.selectedGroups.size <= (this.maxGroups || Number.MAX_SAFE_INTEGER);
        const atLeastRequiredGroups = this.selectedGroups.size >= (this.step.optional ? 0 : 1);
        const notProcessing = !this.processingGroups.size;

        this.step.completed = atLeastRequiredGroups && lessThanMaxGroups && notProcessing;
    }

    handleError(groupId: number) {
        this.processingGroups.delete(groupId);
        this.updateComplete();
        return EMPTY;
    }

    toggleGroup(group: UserGroup) {
        if (this.processingGroups.has(group.id)) {
            return;
        }

        this.step.completed = false; // Wait while processing
        this.processingGroups.add(group.id);

        if (this.selectedGroups.has(group.id)) {
            this.userGroupService.removeMember(this.customer.id, group.id, this.user.id).pipe(
                catchError(() => this.handleError(group.id)),
            ).subscribe(() => {
                this.selectedGroups.delete(group.id);
                this.processingGroups.delete(group.id);
                this.updateComplete();
            });
        } else {
            this.userGroupService.addMember({
                group: {
                    owner_id: this.customer.id,
                    id: group.id,
                },
                member_id: this.user.id,
                from: DateTime.now(),
            }).pipe(
                catchError(() => this.handleError(group.id)),
            ).subscribe(() => {
                this.selectedGroups.add(group.id);
                this.processingGroups.delete(group.id);
                this.updateComplete();
            });
        }
    }
}
