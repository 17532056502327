import { Inject, Injectable } from '@angular/core';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { EawFormData } from '../types/interfaces';
import { EawForm } from '../types/form';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';

interface GetAllOptions extends PaginationOptions {
    active?: boolean,
}

interface UpdateOptions {
    active?: boolean,
}

@Injectable({
    providedIn: 'root',
})
export class FormService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, options?: GetAllOptions): Observable<ArrayPaginatedResponse<EawForm>> {
        return this.http.get<ArrayPaginatedResponse<EawFormData>>(`/customers/${customerId}/forms`, {
            params: formatHttpParams({
                ...options,
                active: options?.active,
            }),
        })
            .pipe(classifyArrayPaginatedResponse(EawForm));
    }

    get(customerId: number, formId: number): Observable<EawForm> {
        return this.http.get<EawFormData>(`/customers/${customerId}/forms/${formId}`)
            .pipe(classifyItem(EawForm));

    }

    update(customerId: number, formId: number, options?: UpdateOptions) {
        return this.http.put<EawFormData>(`/customers/${customerId}/forms/${formId}`, {
            active: options?.active,
        }).pipe(classifyItem(EawForm));
    }
}
