import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { CurrentService } from '../../../shared/services/current.service';
import { Products } from '../../../shared/enums/products';
import { SettingService } from '../../../shared/http/setting.service';
import { Employee } from '../../../shared/models/employee';
import { DateTime } from 'luxon';
import { Absence } from '../../models/absence';
import { AbsenceService, CreateAbsenceBody } from '../../http/absence.service';
import { MatDialog } from '@angular/material/dialog';
import { AbsenceStatsDialogComponent, AbsenceStatsDialogData } from '../../dialogs/absence-stats-dialog/absence-stats-dialog.component';
import { getTimeInterval, overlaps } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { t } from 'i18next';
import { EmployeePropertyService } from '../../../shared/http/employee-property.service';
import { AbsenceType } from '../../models/absence-type';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TinyColor } from '@ctrl/tinycolor';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { catchError, EMPTY, firstValueFrom, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { WarningsDialogService } from '../../../shared/dialogs/warnings-dialog/warnings-dialog.service';
import { CustomFieldsGroup } from '../../../shared/utils/custom-fields-group';
import { UIRouter } from '@uirouter/core';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { AbsenceReleaseShifts } from '../../types/types';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { CustomerCustomFieldService } from '../../../shared/http/customer-custom-field.service';
import { ModelCustomField } from '../../../custom-fields/models/model-custom-field';
import { Customer } from '../../../shared/models/customer';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { TimeInputComponent } from '../../../shared/components/date-time/time-input/time-input.component';
import { CustomFieldInputComponent } from '../../../custom-fields/components/custom-field-input/custom-field-input.component';
import { PredefinedSettings } from '../../../shared/enums/predefined-settings';
import { FileSelectorComponent } from '../../../shared/components/file-selector/file-selector.component';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { HttpContext } from '@angular/common/http';
import { IGNORE_ERROR } from '../../../shared/http/http-contexts';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { AbsenceTypeAutocompleteService } from '../../../shared/autocompletes/absence-type-autocomplete.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbsenceActionsComponent } from '../../components/absence-actions/absence-actions.component';

type ExcludeHistoryItem = { value: string, from: DateTime | null, to: DateTime | null }

type AbsenceFormType = {
    comment: FormControl<string>,
    gradePercent: FormControl<number | null>,
    releaseShifts: FormControl<AbsenceReleaseShifts | null>,
    employee: FormControl<Employee | undefined>,
    approved: FormControl<boolean | null>,
    interval: FormGroup<{
        date: FormControl<DateTime | null>;
        from: FormControl<DateTime | null>;
        to: FormControl<DateTime | null>;
    }>,
    hourLength: FormControl<number | null>;
    type: FormControl<AbsenceType | null>;
    customFields: CustomFieldsGroup;
};

interface AbsenceFormInterface {
    leaveShiftCount?: number;
    lengthDays?: number;
    form: FormGroup<AbsenceFormType>,
    grade: number;
    saved: boolean;
    saving: boolean;
    canSeeStatistics: boolean;
    background: TinyColor | undefined;
    failed: boolean;
    excludePayPeriodWarning: boolean;
    absence: Absence | null;
    editLength: Map<number, number>;
    canApprove: boolean;
    loading: boolean;
}

@Component({
    selector: 'eaw-register-absence',
    templateUrl: './register-absence.component.html',
    styleUrl: './register-absence.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatProgressSpinnerModule,
        AutocompleteComponent,
        MatButtonModule,
        MatIconModule,
        MatDatepickerModule,
        MatInputModule,
        MatSlideToggleModule,
        AsyncPipe,
        TranslatePipe,
        PageHeaderComponent,
        DatePickerOptionsDirective,
        TimeInputComponent,
        CustomFieldInputComponent,
        FileSelectorComponent,
        MaterialColorDirective,
        DateTimePipe,
        TranslateSyncPipe,
        AbsenceActionsComponent,
    ],
})
export class RegisterAbsenceComponent implements OnInit {
    protected readonly current = inject(CurrentService);
    protected readonly settingService = inject(SettingService);
    protected readonly absenceService = inject(AbsenceService);
    protected readonly destroyRef = inject(DestroyRef);
    protected readonly uiRouter = inject(UIRouter);
    protected readonly permissionCheckService = inject(PermissionCheckService);
    protected readonly dialog = inject(MatDialog);
    protected readonly employeePropertyService = inject(EmployeePropertyService);
    protected readonly warningDialog = inject(WarningsDialogService);
    protected readonly employeeAutocompleteService = inject(EmployeeAutocompleteService);
    protected readonly absenceTypeAutocompleteService = inject(AbsenceTypeAutocompleteService);
    protected readonly customerProductService = inject(CustomerProductService);
    protected readonly customerCustomFieldService = inject(CustomerCustomFieldService);
    protected readonly confirmDialog = inject(ConfirmDialogService);
    protected readonly translate = inject(TranslateService);

    permissions: Map<string, boolean> = new Map<string, boolean>();

    customer: Customer;
    canOtherEmployees = false;
    hasScheduling = signal(false);
    absenceExcludeSettings = false;
    absenceExcludeWarningText = '';
    approvalDeadlineEnabled = false;
    interval: {
        from: DateTime | null;
        to: DateTime | null;
    };

    excludeHistoryLoading = false;
    absenceType: AbsenceType | null = null;
    saving = false;

    headerForm = new FormGroup({
        includeExternal: new FormControl<boolean>(true, { nonNullable: true }),
        period: new FormControl<'current' | 'all'>('current', { nonNullable: true }),
    });

    absences: AbsenceFormInterface[] = [];
    active: DateTime | null = DateTime.now();
    excludeHistory: ExcludeHistoryItem[] = [];
    customFields: ModelCustomField[] = [];
    lastUsedEmployee?: Employee;
    allowedFileFormats = 'application/pdf,image/*';
    allowedFileExtensions = [ 'pdf' ];
    file?: File;
    requireValidFormat = false;

    constructor() {
        this.headerForm = new FormGroup({
            includeExternal: new FormControl<boolean>(false, { nonNullable: true }),
            period: new FormControl<'current' | 'all'>('current', { nonNullable: true }),
        });
        this.interval = {
            from: null,
            to: null,
        };
        this.customer = this.current.getCustomer();
    }

    ngOnInit(): void {
        forkJoin([
            this.permissionCheckService.isAllowedMany([], [ `customers.${this.customer.id}.employees.*.absences.create`, `customers.${this.customer.id}.absences.manage` ]).pipe(takeUntilDestroyed(this.destroyRef)),
            this.settingService.getSome([ 'customers', this.customer.id ], { 'settings[]': [ PredefinedSettings.EmployeeAbsencePayPeriodExempt, 'absence.approval_deadline.is_enabled' ] }).pipe(takeUntilDestroyed(this.destroyRef)),
            this.customerProductService.hasProducts(this.customer.id, [ Products.Scheduling ]).pipe(takeUntilDestroyed(this.destroyRef)),
        ])
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                map(([ canOtherEmployees, [ approvalDeadline, absenceExcludeSettings ], hasScheduling ]) => {
                    this.canOtherEmployees = canOtherEmployees;
                    this.absenceExcludeSettings = absenceExcludeSettings?.resolvedValue?.asBoolean() ?? false;
                    this.approvalDeadlineEnabled = approvalDeadline?.resolvedValue?.asBoolean() ?? false;
                    this.hasScheduling.set(hasScheduling);
                }),
                switchMap(() => {
                    return this.addCard();
                }),
                switchMap(() => {
                    return this.getEmployeeAbsenceExcludeData({} as AbsenceFormInterface);
                }),
            )
            .subscribe();
    }

    async addCard() {
        try {
            this.customFields = await firstValueFrom(this.customerCustomFieldService.getForModel(this.customer.id, ApiModel.Absence));
        } catch (e) {
            this.customFields = [];
            console.error(e);
        }

        const interval = new FormGroup({
            date: new FormControl<DateTime | null>(null),
            from: new FormControl<DateTime | null>(null, Validators.required),
            to: new FormControl<DateTime | null>(null, Validators.required),
        });

        const newItem: AbsenceFormInterface = {
            form: new FormGroup<AbsenceFormType>({
                comment: new FormControl(),
                employee: new FormControl(this.lastUsedEmployee || this.current.getEmployee() || undefined, {
                    nonNullable: true,
                    validators: Validators.required,
                }),
                approved: new FormControl(false),
                interval,
                hourLength: new FormControl(null),
                type: new FormControl(null, Validators.required),
                releaseShifts: new FormControl('release', {nonNullable: true}),
                gradePercent: new FormControl(100),
                customFields: new CustomFieldsGroup(),
            }),
            canSeeStatistics: false,
            saved: false,
            saving: false,
            background: this.absenceType?.color,
            failed: false,
            excludePayPeriodWarning: false,
            absence: null,
            editLength: new Map<number, number>(),
            grade: 1,
            canApprove: false,
            loading: false,
        };

        if (!this.canOtherEmployees) {
            newItem.form.controls.employee.disable();
        }

        newItem.form.controls.employee.valueChanges.subscribe((employee) => {
            const isEmployee = employee instanceof Employee;
            newItem.canApprove = false;
            newItem.loading = isEmployee;
            const observable = isEmployee ? this.permissionCheckService.isAllowed(`customers.${this.customer.id}.employees.${employee.id}.absences.*.approve`) : of(false);

            if (employee instanceof Employee) {
                this.permissionCheckService.isAllowedMany([
                    `customers.${this.customer.id}.absence_types.*.get`,
                    `customers.${this.customer.id}.employees.${employee.id}.absences.*.get`,
                    `customers.${this.customer.id}.employees.${employee.id}.off_times.*.get`,
                ]).subscribe((canSeeStatistics) => {
                    newItem.canSeeStatistics = canSeeStatistics;
                });
            } else {
                newItem.canSeeStatistics = false;
            }

            observable.pipe(catchError(() => of(false))).subscribe((canApprove) => {
                newItem.canApprove = canApprove;
                newItem.loading = false;
            });
        });

        newItem.form.controls.type.valueChanges.subscribe((type) => {
            if (!type) {
                return;
            }

            if (type.span === 'hour') {
                newItem.form.controls.interval.controls.date.addValidators(Validators.required);
            } else {
                newItem.form.controls.interval.controls.date.removeValidators(Validators.required);
            }

            this.checkCanEditLength(newItem);
        });

        this.absences.unshift(newItem);
        interval.valueChanges.subscribe((v) => {
            this.checkExclude(v.from, v.to);
        });
    }

    goToHandling(absence: AbsenceFormInterface) {
        const employeeId = absence.form.controls.employee.value?.id;
        const absenceId = absence.absence?.id;

        if (!absenceId || !employeeId) {
            return;
        }

        const params = {
            employee_id: employeeId,
            id: absenceId,
        };

        if (this.hasScheduling()) {
            this.uiRouter.stateService.go('eaw/app/absence/list/tabs/shifts', params);
        } else {
            this.uiRouter.stateService.go('eaw/app/absence/list/tabs/edit', params);
        }
    }

    checkCanEditLength(absence: AbsenceFormInterface) {
        const type = absence.form.controls.type.value?.id;
        if (!type) {
            return false;
        }

        const length = absence.editLength?.get(type);
        if (length !== undefined) {
            if (length > 0) {
                absence.form.controls.hourLength.setValue(length);
            }
            return;
        }

        if (type) {
            const setting: string = 'absence.type_' + type + '.edit_length';

            this.settingService.getValue([ 'customers', this.customer.id ], setting, 0).subscribe((value) => {
                if (value > 0) {
                    absence.form.controls.hourLength.setValue(value);
                }
                absence.editLength.set(type, isNaN(value) || value <= 0 ? 0 : value);
            });
        }
    }

    changeEmployee(absence: AbsenceFormInterface) {
        this.getEmployeeAbsenceExcludeData(absence).subscribe();
    }

    optionsChange() {
        switch (this.headerForm.value.period) {
            case 'current':
                this.interval.from = null;
                this.interval.to = null;
                this.active = DateTime.now().startOf('minute');
                break;
            case 'all':
                this.interval.from = DateTime.fromSeconds(0).startOf('minute');
                this.interval.to = DateTime.now().plus({ years: 100 }).startOf('minute');
                this.active = null;
        }
    }

    showInfo(absence: AbsenceFormInterface) {
        const employee = absence.form.controls.employee.value;

        if (!employee || !absence.canSeeStatistics) {
            return;
        }

        this.dialog.open<AbsenceStatsDialogComponent, AbsenceStatsDialogData>(AbsenceStatsDialogComponent, {
            data: {
                size: DialogSize.Small,
                customerId: this.customer.id,
                employeeId: employee.id,
            },
        });
    }

    /** Checks if the current absence will overlap with an exclude pay period, and displays a warning if that is the case */
    checkExclude(from?: DateTime | null, to?: DateTime | null) {
        this.absenceExcludeWarningText = '';
        if (!this.absenceExcludeSettings || !from || !to) {
            return;
        }

        for (const cf of this.excludeHistory) {
            const cfFrom = cf.from;
            if (!cfFrom) {
                continue;
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (!cf.to && to > cfFrom || overlaps(+from, +to, +cfFrom, +cf.to!)) {
                this.absenceExcludeWarningText = t('absences:EMP_EXCLUDE_PAY_PERIOD_WARNING', {
                    from: cfFrom.toFormat('LLL'),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    to: cf.to!.toFormat('LLL'),
                });
                break;
            }
        }
    }

    getEmployeeAbsenceExcludeData(absence: AbsenceFormInterface) {
        if (!this.absenceExcludeSettings || !absence.form.controls.employee.value) {
            return of();
        }

        this.excludeHistory = [];

        this.excludeHistoryLoading = true;
        return this.employeePropertyService.get(this.customer.id, absence.form.controls.employee.value.id, 'cf_emp_exempt_absence_pay_period')
            .pipe(
                tap((resp) => {
                    this.excludeHistory = resp.data
                        .filter((f) => f.from != null)
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        .sort((a, b) => a.from!.toMillis() - b.from!.toMillis())
                        .map((p) => {
                            return {
                                from: p.from,
                                to: p.to,
                                value: p.value.asString(),
                            };
                        });
                }),
            );
    }

    showGradedWarning(absence: AbsenceFormInterface) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return absence.form.controls.approved.value && !(absence.saved || absence.saving) && absence.grade && absence.form.controls.gradePercent.value! < 100;
    }

    save(absence: AbsenceFormInterface) {
        absence.saving = true;
        absence.form.disable({ emitEvent: false });
        absence.form.controls.customFields.disable({ emitEvent: false });

        const intervalCtrl = absence.form.controls.interval.controls;

        let hourDate: { from: DateTime; to: DateTime | null; } | null;

        if (absence.form.controls.type.value?.span === 'hour') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            hourDate = getTimeInterval(intervalCtrl.date.value, intervalCtrl.from.value, intervalCtrl.to.value!);
        }

        const approved = absence.form.controls.approved.value;
        const length: number | null = absence.form.controls.hourLength.value;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const type: AbsenceType = absence.form.controls.type.value!;
        const grade: number | null = absence.form.controls.gradePercent.value;
        const comment: string | null = absence.form.controls.comment?.value;
        const fields = absence.form.controls.customFields.getValues();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const employee: Employee = absence.form.controls.employee.value!;

        this.customerProductService.hasProducts(this.customer.id, [ Products.LeaveShifts ]).pipe(
            map((hasLeaveShifts) => {
                const body: CreateAbsenceBody & { with: string[] } = {
                    ...fields,
                    type_id: type.id,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    from: type?.span === 'hour' ? hourDate!.from : intervalCtrl.from.value!,
                    grade: grade ? grade / 100 : 1,
                    length: length && length > 0 ? length * 3600 : undefined,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    to: type.span === 'hour' ? hourDate!.to! : intervalCtrl.to.value!.endOf('day'),
                    comment: comment || undefined,
                    with: [ 'approval' ],
                    schedule_published_warning_accepted: approved || false,
                };

                if (hasLeaveShifts) {
                    body.with.push('leaveShifts');
                }

                if (approved) {
                    body.release_shifts = absence.form.controls.releaseShifts.value || 'keep';
                    body.approved = true;
                }

                return body;
            }),
            switchMap((body) => {
                return this.absenceService.create(this.customer.id, employee.id, body, new HttpContext().set(IGNORE_ERROR, (error) => error.error?.type === 'schedule_already_published'))
                    .pipe(
                        catchError((error) => {
                            if (error.status !== 422 || error.error?.type !== 'schedule_already_published') {
                                throw error;
                            }

                            return this.confirmDialog.open({
                                title: this.translate.t('WARNING'),
                                text: Promise.resolve(error.error.error),
                                confirmText: this.translate.t('CONTINUE'),
                                confirmButtonColor: 'primary',
                            }).beforeClosed().pipe(
                                switchMap((r) => {
                                    if (r?.ok) {
                                        return this.absenceService.create(this.customer.id, employee.id, {
                                            ...body,
                                            schedule_published_warning_accepted: true,
                                        });
                                    }
                                    throw error;
                                }),
                            );
                        }));
            }),
            switchMap((a: Absence) => {
                if (!absence) {
                    return EMPTY;
                }
                absence.absence = a;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                absence.form.controls.hourLength.setValue(a.hourLength!);
                absence.saved = true;
                absence.leaveShiftCount = a.leaveShifts?.length;
                if (this.file) {
                    return this.absenceService.createAttachment(this.customer.id, a.id, { file: this.file });
                } else {
                    return EMPTY;
                }
            }),
        ).subscribe({
            error: () => {
                absence.failed = true;
                absence.saving = false;
                absence.saved = false;
                absence.form.enable();
            },
            complete: () => {
                this.lastUsedEmployee = employee;
                this.addCard();
                absence.saving = false;
            },
        });
    }

    openWarnings(absence: AbsenceFormInterface) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.warningDialog.open(of(absence.absence!.warnings!));
    }

    onFileChange(file?: File) {
        this.file = file ? new File([ file ], file.name, { type: file.type }) : undefined;
    }
}
