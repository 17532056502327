import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Metric } from '../../models/metric';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { merge, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MetricServiceMock } from '../../../../mocks/metric-service.mock';
import { UIRouter } from '@uirouter/core';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions } from '@angular/material/dialog';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { UnsavedChangesService } from '../../../shared/services/unsaved-changes.service';

type orgMetricForm = {
    form: FormControl<boolean | null>,
    metric: Metric,
    initValue?: boolean | null
}

@Component({
    selector: 'eaw-metric',
    templateUrl: './metric.component.html',
    styleUrl: './metric.component.scss' ,
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        NgIf,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        NgFor,
        MatCheckboxModule,
        CheckboxHelperDirective,
        MatDialogActions,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class MetricComponent implements OnInit, OnDestroy {
    metricArr: Metric[];
    orgId: string;
    orgMetric: orgMetricForm[];
    initForm: orgMetricForm[];
    loading: boolean;

    constructor(
        @Inject(UnsavedChangesService) private unsavedChangesService: UnsavedChangesService,
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(MetricServiceMock) public metricService: MetricServiceMock,
        @Inject(SnackBarService) public snackBar: SnackBarService,
    ) {
        this.metricArr = [];
        this.orgMetric = [];
        this.initForm = [];
        this.orgId = this.uiRouter.globals.params['organizationId'];
        this.loading = true;
    }

    ngOnDestroy(): void {
        this.unsavedChangesService.reset();
    }

    ngOnInit() {
        this.unsavedChangesService.init(this.hasChanged.bind(this));
        this.loading = true;

        this.metricService.getAll().subscribe((resp) => {
            this.orgMetric = resp.map((r) => {
                return {
                    form: new FormControl<boolean>(false),
                    metric: r,
                    initValue: false,
                };
            });

            this.metricService.getForOrganization().subscribe((arr) => {
                for (const control of this.orgMetric) {
                    for (let j = 0; j < arr.length; j++) {
                        if (control.metric.key == arr[j]?.key) {
                            control.form.setValue(true);
                            control.initValue = true;
                        }
                    }
                }
                this.loading = false;
            });
        });
    }

    save() {
        const addArr: orgMetricForm[] = [];
        const deleteArr: orgMetricForm[] = [];

        this.orgMetric.forEach((value, i) => {
            if (value.initValue !== value.form.value) {
                if (value.form.value == true) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    addArr.push(this.orgMetric[i]!);
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    deleteArr.push(this.orgMetric[i]!);
                }
            }
            value.initValue = value.form.value;
        });

        const add = this.add(addArr);
        const del = this.delete(deleteArr);

        merge(del, add).subscribe({
            complete: () => {
                void this.snackBar.t('CHANGES_SAVED');
            },
        });
    }

    delete(deleteArr: orgMetricForm[]) {
        if (deleteArr.length) {
            return of(...deleteArr).pipe(mergeMap((metric) => {
                return this.metricService.delete(this.orgId, metric.metric.key);
            }, 2));
        }
        return of([]);
    }

    add(addArr: orgMetricForm[]) {
        if (addArr.length) {
            return of(...addArr).pipe(mergeMap((metric) => {
                return this.metricService.addToOrganization(this.orgId, metric.metric);
            }, 2));
        }
        return of([]);
    }

    private hasChanged() {
        return !!this.orgMetric.find((value) => {
            return (value.initValue !== value.form.value);
        });
    }
}
