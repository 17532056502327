// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.staffing').component('weekSetupAutocomplete', {
    template: `<md-autocomplete
        md-selected-item="$autocomplete.selectedItem"
        md-search-text="filter"
        md-selected-item-change="$autocomplete.select()"
        md-min-length="0"
        md-input-id="{{$id}}autocomplete"
        md-delay="300"
        ng-disabled="$autocomplete.disable"
        md-floating-label="{{$autocomplete.label}}"
        md-clear-button="$autocomplete.clearBtn"
        ng-required="$autocomplete.required"
        md-items="item in $autocomplete.search(filter)"
        md-item-text="item.text">
    <md-item-template>
        <span md-highlight-text="filter" md-highlight-flags="gi">{{item.text}}</span>
    </md-item-template>
    <md-not-found>
        <span ng-i18next="NO_MATCH_FOUND"></span>
    </md-not-found>
</md-autocomplete>
`,
    bindings: {
        customerId: '<',
        clearBtn: '<?',
    },
    require: {
        ngModel: 'ngModel',
    },
    controllerAs: '$autocomplete',
    controller: [ 'weekSetupFactory', '$attrs', function(weekSetupFactory, $attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.label = t('staffing:WEEK_SETUP');
            ctrl.clearBtn = ctrl.clearBtn ?? true;

            $attrs.$observe('required', (value) => {
                ctrl.required = value;
            });

            $attrs.$observe('disabled', (value) => {
                ctrl.disable = value;
                ctrl.clearBtn = !ctrl.disable;
            });

            ctrl.ngModel.$formatters.unshift(ctrl.formatter);
        };

        ctrl.search = (filter) => weekSetupFactory.getAll.query(ctrl.customerId, {
            filter,
            order_by: 'name',
            per_page: 20,
        }).$promise.then((resp) => {
            resp.data.forEach((l) => l.text = l.name);
            return resp.data;
        });

        ctrl.select = () => {
            ctrl.ngModel.setViewValue(ctrl.selectedItem);
        };

        ctrl.formatter = (val) => {
            if (val) {
                val.text = val.name;
            }
            ctrl.selectedItem = val;
            return val;
        };
    } ],
});
