// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
export class NewsletterWidgetComponent {
    $state;
    Newsletter;
    ReadNewsletterIssueServiceDowngrade;
    static get $inject() {
        return [ '$state', 'Newsletter', 'ReadNewsletterIssueServiceDowngrade' ];
    }

    eawWidget;
    issue;
    newsletter;
    constructor($state, Newsletter, ReadNewsletterIssueServiceDowngrade) {
        this.$state = $state;
        this.Newsletter = Newsletter;
        this.ReadNewsletterIssueServiceDowngrade = ReadNewsletterIssueServiceDowngrade;
    }

    async $onInit() {
        await this.getNewsletters();
        this.createButtons();
    }

    createButtons() {
        if (!this.issue) {
            return;
        }
        this.eawWidget.setButtons([
            {
                id: 'read',
                icon: 'menu_book',
                tooltip: t('newsletter:READ_ISSUE'),
                click: this.viewIssue.bind(this),
            },
            {
                id: 'view_all',
                icon: 'view_list',
                tooltip: t('newsletter:VIEW_ALL_ISSUES'),
                click: this.viewNewsletter.bind(this),
            },
        ]);
    }

    async getNewsletters() {
        this.eawWidget.setLoading(true);
        const resp = await this.Newsletter.getAll(this.eawWidget.widget.customer, {
            only_latest: true,
            only_published: true,
            has_issues: true,
            per_page: 10,
        });
        this.eawWidget.setLoading(false);
        if (!resp.data.length) {
            this.eawWidget.setEmpty(true);
            return;
        }
        // Sort so the most recently published issue in a newsletter is selected
        this.newsletter = resp.data.sort((nl1, nl2) => nl2.getMostRecentIssue().publish_date - nl1.getMostRecentIssue().publish_date)[0];
        this.Newsletter.setLanguage(this.newsletter, this.eawWidget.customer, this.eawWidget.widget.user);
        this.issue = this.newsletter.getMostRecentIssue();
    }

    viewNewsletter() {
        this.$state.go('eaw/app/newsletters/issues', { newsletter_id: this.issue.newsletter_id });
    }

    viewIssue() {

        this.ReadNewsletterIssueServiceDowngrade.open(this.eawWidget.widget.customer, this.issue.newsletter_id, this.issue.id, this.issue.viewLanguage.code);
    }
}
module('eaw.dashboard').component('newsletterWidget', {
    template: `<div class="widget-content">
    <div class="overflow-ellipsis issue-info" ng-if="$ctrl.issue">
        <div ng-bind="$ctrl.issue.viewLanguage.title"></div>
        <small ng-bind="$ctrl.issue.viewLanguage.description"></small>
        <small class="issue-release" ng-bind="$ctrl.issue.publish_date | momentRelative"></small>
    </div>

    <eaw-issue-preview ng-if="$ctrl.issue"
                       issue="$ctrl.issue"
                       ng-click="$ctrl.viewIssue()"
                       root-selector="#main-content"
                       customer-id="$ctrl.eawWidget.widget.customer">
    </eaw-issue-preview>
</div>
`,
    require: {
        eawWidget: '^eawWidget',
    },
    controller: NewsletterWidgetComponent,
});
