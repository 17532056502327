import { DurationUnit } from 'luxon/src/duration';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BusinessDateString } from '../../shared/types/business-date';
import { BusinessDate } from '../../shared/utils/business-date';

export type StaffingForecastInterval = 86400 | 604800 | 2592000;

export interface StaffingForecastResponse extends ApiResponse {
    start_date: BusinessDateString;
    interval: StaffingForecastInterval;
    projections: number[];
    // This is in seconds
    contract_hours: number[];
    // This is in seconds
    absence_hours: number[];
}

export class StaffingForecast {
    startDate: BusinessDate;
    interval: StaffingForecastInterval;
    durationUnit: DurationUnit;
    projections: number[];
    contractHours: number[];
    absenceHours: number[];

    constructor(data: StaffingForecastResponse) {
        this.startDate = new BusinessDate(data.start_date);
        this.interval = data.interval;
        this.projections = data.projections;
        this.contractHours = data.contract_hours.map((h) => h / 3600);
        this.absenceHours = data.absence_hours.map((h) => h / 3600);

        switch (this.interval) {
            case 86400: {
                this.durationUnit = 'day';
                break;
            }
            case 604800: {
                this.durationUnit = 'week';
                break;
            }
            case 2592000: {
                this.durationUnit = 'month';
                break;
            }
        }
    }
}
