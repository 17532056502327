import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Injectable({
    providedIn: 'root',
})
export class PageTransitionHookService {

    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
        this.uiRouter.transitionService.onSuccess({}, (transition) => {
            console.debug('🪝', 'PageTransitionHookService.onSuccess', transition);
            const name = String(transition.to().name);
            newrelic.setCurrentRouteName(name);
        });

        /**
         * This hook fixes this problem: [ui-router changes the url but view does not change](https://github.com/angular-ui/ui-router/issues/3652)
         */
        this.uiRouter.transitionService.onStart({}, (transition) => {
            console.debug('🪝', 'PageTransitionHookService.onStart', transition);

            const toList = transition.treeChanges().to.map((t) => t.state.name);

            transition.router.viewService['_viewConfigs'] = transition.router.viewService['_viewConfigs'].filter((vc: any) => {
                return toList.includes(vc.viewDecl.$context.name);
            });
        }, { priority: 100 });
    }
}
