// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import EmployeeOld from '../../employee-old';
import { PaginationOld } from '../../../utils/pagination-old';
module('eaw.employees').factory('EmployeeService', [ 'EawResource', function(EawResource) {
    const service = this;
    const url = '/customers/:customer_id/employees/:employee_id';
    /**
     * @param {object} customer
     * @param {number} employeeId
     * @param {string[]} relationships array of relationships to send with the employee.
     * @returns promise.
     */
    service.get = (customer, employeeId, relationships) => EawResource.create(url).get({
        customer_id: customer.id,
        employee_id: employeeId,
        'with[]': relationships,
    }).$promise.then((resp) => new EmployeeOld(resp));
    service.getAll = (args = {}) => {
        const def = EawResource.create(url).get({
            customer_id: args.customer?.id,
            user_id: args.userId,
            'qualifications[]': args.qualifications,
            'fields[]': args.fields,
            ...PaginationOld.getParams(args, [
                'include_inactive',
                'include_external',
                'from',
                'to',
                'active',
                'include_future',
                'customer_id',
                'include_custom_fields',
                'only_future',
                'only_past',
            ]),
            _businessDates: [ 'from', 'to', 'active' ],
        });
        def.$promise.then((resp) => {
            resp.data = resp.data.map((emp) => new EmployeeOld(emp));
            return resp;
        });
        return def;
    };
    service.getLoansForEmployee = (args) => EawResource.create('/customers/:customer/employees/:employee/external_employees').get({
        ...PaginationOld.getParams(args),
        customer: args.customerId,
        employee: args.employeeId,
        include_inactive: args.include_inactive,
    }).$promise;
    service.getLoans = (args) => EawResource.create('/customers/:customer/external_employees/overview').get({
        ...PaginationOld.getParams(args),
        customer: args.customerId,
        include_inactive: args.include_inactive,
    }).$promise;
    /**
     * @param {object} args
     * @param {int} args.customerId
     * @param {int} args.employeeId
     * @param {moment} args.date - Point in time to get which customers (s)he were employed at
     * @returns {*}
     */
    service.getCustomers = (args) => EawResource.create('/customers/:customer/employees/:employee/customers').get({
        customer: args.customerId,
        employee: args.employeeId,
        active: args.date,
        ...PaginationOld.getParams(args),
    });
    /**
     * @param {Customer|Object} customer
     * @param {Number}          customer.id
     * @param {Object}          data
     */
    service.create = (customer, data) => {
        // Add from to data if not already set
        data.from = moment.isMoment(data.from) ? data.from : moment();
        return EawResource.create(url).save({
            customer_id: customer.id,
        }, EmployeeOld.getRequestPayload(data)).$promise;
    };
    /**
     * @param customerId
     * @param employeeId
     * @param updates
     */
    service.updateEmployee = (customerId, employeeId, updates) => EawResource.create(url).update({
        customer_id: customerId,
        employee_id: employeeId,
    }, EmployeeOld.getRequestPayload(updates)).$promise.then((resp) => new EmployeeOld(resp));
    return service;
} ]);
