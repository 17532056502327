import { Inject, Injectable } from '@angular/core';
import { Role, RoleResponse } from '../types/role';
import { classifyArrayPaginatedResponse, classifyItem } from '../../../shared/utils/rxjs/classify';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class RoleService {
    constructor(@Inject(HttpClient) protected http: HttpClient) { }

    create(customer: number, attributes: Omit<RoleResponse, 'id' | 'customer_id'>): Observable<Role> {
        return this.http.post<RoleResponse>(`customers/${customer}/roles`, attributes).pipe(classifyItem(Role));
    }

    get(customer: number, role: number): Observable<Role> {
        return this.http.get<RoleResponse>(`customers/${customer}/roles/${role}`).pipe(classifyItem(Role));
    }

    getAll(customer: number, options?: { pagination: PaginationOptions }): Observable<ArrayPaginatedResponse<Role>> {
        return this.http.get<ArrayPaginatedResponse<RoleResponse>>(`customers/${customer}/roles`, {
            params: { ...options?.pagination },
        }).pipe(classifyArrayPaginatedResponse(Role));
    }

    update(role: { customer_id: number, id: number } & Partial<RoleResponse>): Observable<Role> {
        return this.http.put<RoleResponse>(`customers/${role.customer_id}/roles/${role.id}`, role).pipe(classifyItem(Role));
    }

    delete(customer: number, role: number): Observable<undefined> {
        return this.http.delete<undefined>(`customers/${customer}/roles/${role}`);
    }
}
