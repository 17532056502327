// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { keyBy } from 'lodash-es';

module('eaw.components').component('eawSelectMultipleSearch', {
    template: `<md-input-container class="tw-w-full">
    <label ng-bind="$select.label"></label>
    <md-select ng-model="$select.selectedItems"
               md-on-close="$select.clearSearchTerm()"
               ng-change="$select.selectedItemsChanged()"
               data-md-container-class="select-multiple-search"
               ng-required="$select.required"
               ng-disabled="$select.disabled"
               multiple>
        <md-select-header class="select-multiple-search-header">
            <input ng-model="$select.searchTerm"
                   type="search"
                   uniq-form-name
                   placeholder="{{'SEARCH' | i18next}}"
                   ng-change="$select.searchChange($select.searchTerm)"
                   class="select-multiple-search-header-searchbox md-text">
        </md-select-header>
        <md-optgroup>
            <md-progress-circular ng-if="$select.loadingData"></md-progress-circular>

            <md-option ng-value="item" ng-if="!item.hide" ng-repeat="item in $select.items | filter:$select.searchTerm track by $index">
                <span md-highlight-text="$select.searchTerm" md-highlight-flags="gi">{{item[$select.displayKey]}}</span>
            </md-option>
        </md-optgroup>
    </md-select>
</md-input-container>
`,
    bindings: {
        items: '<?', // Items to iterate over in the list
        displayKey: '<?', // Key we use to show the text in the select
        label: '<?',
        i18nLabel: '<?',
        itemKey: '@?',
        loadingData: '<?',
        returnValue: '<?',
        getData: '<?', // Pass in a function that returns a promise
        getDataCallback: '<?', // Callback we want to run with the response from the promise
        onBlur: '&?',
    },
    controllerAs: '$select',
    require: {
        ngModel: 'ngModel',
    },
    controller: [ '$element', '$attrs', function($element, $attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.label = ctrl.i18nLabel ? t(ctrl.i18nLabel) : ctrl.label;
            ctrl.keyedItems = keyBy(ctrl.items, ctrl.itemKey || 'id');

            // The md-select directive eats keydown events for some quick select
            // logic. Since we have a search input here, we don't need that logic.
            // Taken from angularjs material docs ;)
            $element[0].querySelector('input')?.addEventListener('keydown', (e) => {
                e.stopPropagation();
            });

            $element[0].addEventListener('click', () => {
                if (typeof ctrl.getData === 'function') {
                    ctrl.loadingData = true;
                    ctrl.getData().then((resp) => {
                        ctrl.loadingData = false;
                        ctrl.items = typeof ctrl.getDataCallback == 'function' ? ctrl.getDataCallback(resp) : resp;
                    })?.catch((err) => {
                        console.error(err);
                    });
                }
            }, { once: true });

            $attrs.$observe('required', (value) => {
                ctrl.required = value;
            });

            $attrs.$observe('disabled', (value) => {
                ctrl.disabled = value;
            });

            ctrl.ngModel.$formatters.unshift(ctrl.setSelectedItems);
        };

        ctrl.setSelectedItems = (val) => {
            ctrl.selectedItems = [];

            if (Array.isArray(val)) {
                if (ctrl.returnValue) {
                    val.forEach((v) => {
                        const item = ctrl.items.find((i) => i[ctrl.returnValue] == v);

                        if (item) {
                            ctrl.selectedItems.push(item);
                        }
                    });
                } else {
                    val.forEach((v) => ctrl.selectedItems.push(ctrl.keyedItems[v]));
                }
            }

            return val;
        };

        ctrl.selectedItemsChanged = () => {
            ctrl.ngModel.setViewValue(ctrl.selectedItems?.map((i) => i[ctrl.returnValue] ?? i));
        };

        ctrl.searchChange = (filter) => {
            filter = filter?.toLowerCase();
            ctrl.items.forEach((item) => item.hide = !item[ctrl.displayKey].toLowerCase().includes(filter));
        };

        ctrl.clearSearchTerm = () => {
            ctrl.searchTerm = undefined;
            ctrl.items?.forEach((i) => delete i.hide);

            ctrl.onBlur?.();
        };
    } ],
});
