<eaw-page-header [fabButton]="fabButton">
    <span title>{{'DEFAULT_FILES' | translate: 'company' | async}}</span>

    <form smallFilter class="tw-flex tw-gap-8">
        <mat-form-field class="standalone">
            <mat-label>{{'SEARCH'|translate|async}}</mat-label>
            <input matInput type="text" autocomplete="off" [formControl]="search">
        </mat-form-field>

        <mat-form-field class="standalone">
            <mat-label>{{typeFilter.disabled ? ('LOADING'|translate|async) : ('FILETYPE_plural'|translate: 'hr'|async)}}</mat-label>
            <mat-select multiple [formControl]="typeFilter" (closed)="onFilterClose()">
                <mat-option *ngFor="let type of filetypes" [value]="type.id">{{type.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</eaw-page-header>

<mat-card class="mat-compact-card mat-card-contained">
    <mat-card-content>
        <eaw-data-table
                [columns]="columns"
                [request]="request"
                (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
