import { DataTableFunctionValue, DataTableLoading, DataTableValue } from '../interfaces/data-table-value';
import { DataTableHeader } from './data-table-header';
import { uniqueId } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { DataTableTextColumn } from './data-table-text-column';
import { DataTablePortal } from '../interfaces/data-table-portals';

export interface DataTableColumnAttributes<Column, Item, Value> {
    exportable?: boolean;
    header: DataTableHeader | null,
    value?: DataTableValue<Column, Item, Value>
    loading?: DataTableLoading<Column, Item>
    // This is used as a column identifier
    key?: string;
    classes?: string[] | Set<string> | DataTableFunctionValue<Column, Item, string[] | Set<string>>,
    styles?: Partial<Record<keyof CSSStyleDeclaration, string>> | DataTableFunctionValue<Column, Item, Partial<Record<keyof CSSStyleDeclaration, string>>>;
    sticky?: boolean;
    stickyEnd?: boolean;
    nowrap?: boolean;
    // Text to display under the main text
    subText?: DataTableFunctionValue<DataTableTextColumn<Item>, Item, string | undefined>,
}

export abstract class DataTableColumn<Column, Item, Value> {
    abstract component: DataTablePortal<Item>

    // This is used as a column identifier and also for exporting
    key: string;
    header: DataTableHeader | null;
    value?: DataTableFunctionValue<Column, Item, Value>;
    loading?: DataTableLoading<Column, Item>;
    classes: DataTableColumnAttributes<Column, Item, Value>['classes'] ;
    styles: DataTableColumnAttributes<Column, Item, Value>['styles'] ;
    exportable: boolean;
    sticky: boolean;
    stickyEnd: boolean;
    nowrap: boolean;
    subText?: DataTableFunctionValue<DataTableTextColumn<Item>, Item, string | undefined>;

    constructor(attrs: DataTableColumnAttributes<Column, Item, Value>) {
        this.header = attrs.header;
        this.loading = attrs.loading;
        this.exportable = attrs.exportable ?? false;
        this.classes = attrs.classes;
        this.styles = attrs.styles;
        this.sticky = attrs.sticky ?? false;
        this.stickyEnd = attrs.stickyEnd ?? false;
        this.nowrap = attrs.nowrap ?? false;
        this.subText = attrs.subText;

        if (typeof attrs.value === 'string') {
            this.value = (data) => (typeof attrs.value === 'string' ? data.item[attrs.value] : '') as Value;
            this.key = attrs.key || attrs.value || uniqueId('column-');
        } else {
            this.value = attrs.value;
            this.key = attrs.key || uniqueId('column-');
        }
    }
}
