<form class="tw-grid tw-grid-cols-2 tw-gap-16" [formGroup]="form">
    <mat-form-field eawDatePickerOptions class="tw-col-span-1">
        <mat-label>{{ 'FROM' | translate | async }}</mat-label>
        <input matInput formControlName="from" [max]="form.getRawValue().to" [matDatepicker]="fromPicker">
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field eawDatePickerOptions class="tw-col-span-1">
        <mat-label>{{ 'TO' | translate | async }}</mat-label>
        <input matInput formControlName="to" [min]="form.getRawValue().from" [matDatepicker]="toPicker">
        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
    </mat-form-field>

    <mat-checkbox eawCheckboxHelper formControlName="useTariff" class="tw-col-span-full tw-mb-8">
        {{ 'USE_TARIFF' | translate: 'company' | async }}
    </mat-checkbox>

    @if (form.controls.useTariff.value) {
        <eaw-autocomplete formControlName="tariff"
                          class="tw-col-span-full"
                          [options]="tariffAutocompleteService.options"
                          [triggers]="[form.controls.from]"
                          [getter]="tariffAutocompleteService.getter({
                                          customerId: customer.id,
                                          employeeId: employee.id,
                                          types: ['hourly', 'monthly'],
                                          from: form.controls.from.value || undefined,
                                      })"
                          [setter]="tariffAutocompleteService.setter({customerId: customer.id})">
        </eaw-autocomplete>
    } @else {
        <mat-form-field class="tw-col-span-1">
            <mat-label>{{ 'RATE' | translate | async }}</mat-label>
            <input type="number" inputmode="numeric" formControlName="rate" min="0" matInput>
        </mat-form-field>

        <mat-form-field class="tw-col-span-1">
            <mat-label>{{ 'TYPE' | translate | async }}</mat-label>
            <mat-select formControlName="type">
                <mat-option *ngFor="let type of payTypes" [value]="type">{{ (type | uppercase) + '_PAY' | translateSync:'payroll' }}</mat-option>
            </mat-select>
        </mat-form-field>
    }
</form>

@if (!payRate) {
    <eaw-action-button [loading]="processing" [disabled]="form.invalid" (click)="submit()">{{ 'ADD_PAY_INFO' | translate:'new_employee' | async }}</eaw-action-button>
}

<eaw-new-employee-next-step [stepper]="stepper" [step]="step"/>

