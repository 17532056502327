import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { AttachmentListDialogData } from './attachment-list-dialog.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { ImageAttachmentComponent } from '../../components/image-attachment/image-attachment.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-attachment-list-dialog',
    templateUrl: './attachment-list-dialog.component.html',
    styleUrl: './attachment-list-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        NgFor,
        ImageAttachmentComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AttachmentListDialogComponent extends DialogComponent implements OnInit {
    urls: string[] = [];

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AttachmentListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) override data: AttachmentListDialogData,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.data.urls.subscribe((res) => {
            this.urls = res;
        });
    }
}
