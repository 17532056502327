import { Component, inject, Input, OnInit } from '@angular/core';
import { DateTime, Info } from 'luxon';
import { OpeningHoursService } from '../../http/opening-hours.service';
import CustomerOld from '../../../shared/angularjs/customer-old';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { OpeningHours } from '../../models/opening-hours';
import { debounceTime, delayWhen, forkJoin, tap } from 'rxjs';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TimeInputComponent } from '../../../shared/components/date-time/time-input/time-input.component';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { PermissionPipe } from '../../../shared/pipes/permission.pipe';

interface FromToForm {
    from: FormControl<DateTime | number | null>,
    to: FormControl<DateTime | number | null>
}

interface OpeningHourDay {
    /** Used to disable input for a day if a http request is being handled */
    processing?: boolean,
    /** How far into the week the day is */
    dayIndex: number,
    /** The weekday's name */
    name: string,
    form: FormGroup<FromToForm>
    openingHours?: OpeningHours,
}

@Component({
    selector: 'eaw-opening-hours',
    templateUrl: './opening-hours.component.html',
    styleUrl: './opening-hours.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgFor,
        ReactiveFormsModule,
        TimeInputComponent,
        NgIf,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
        PermissionPipe,
    ],
})
export class OpeningHoursComponent implements OnInit {
    private readonly openingHoursService = inject(OpeningHoursService);
    private readonly permissionCheckService = inject(PermissionCheckService);

    @Input() customer!: CustomerOld;

    days: OpeningHourDay[] = [];
    canEditAll = false;

    /** On init, get existing data and assign days */
    ngOnInit() {
        this.createDaysArray([], true);

        void this.getHours();
    }

    getHours() {
        this.openingHoursService.getAll(this.customer['id']).pipe(
            delayWhen((r) => this.updateAllPermissions(r)),
        ).subscribe((res) => {
            this.createDaysArray(res);
        });
    }

    /**
     * Create an array that contains the necessary data for each day
     * @param openingHours
     * @param disabled - Whether the form should be disabled, usually while we wait for the data, so we can display a form in the meantime
     * @private
     */
    private createDaysArray(openingHours: OpeningHours[], disabled = false) {
        this.days = [];

        for (let i = 0; i < 7; i++) {
            const data = openingHours.find((oh) => oh.day === i + 1);

            const form = new FormGroup<FromToForm>({
                from: new FormControl({ value: data?.openFrom || null, disabled: true }),
                to: new FormControl({ value: data?.openTo || null, disabled: true }),
            });

            form.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.updateOpeningHours(this.days[i]!, value);
            });

            const canChange = data ? this.permissionCheckService.single(`customers.${this.customer['id']}.opening_hours.${data.id}.update`) : true;

            this.days.push({
                dayIndex: i + 1,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                name: Info.weekdays()[i]!,
                form,
                openingHours: data,
            });

            if (disabled || !canChange) {
                this.days[i]?.form.disable();
            } else {
                this.days[i]?.form.enable();
            }
        }
    }

    updateAllPermissions(hours: OpeningHours[]) {
        const permissions = [
            `customers.${this.customer['id']}.opening_hours.*.update`,
            ...hours.map((hour) => `customers.${this.customer['id']}.opening_hours.${hour.id}.update`),
        ];

        return forkJoin([
            this.permissionCheckService.isAllowed(`customers.${this.customer['id']}.opening_hours.*.update`).pipe(tap((can) => this.canEditAll = can)),
            this.permissionCheckService.isAllowedMany(permissions, []),
        ]);
    }

    updateOpeningHours(openingHourDay: OpeningHourDay, value: Partial<{
        from: number | DateTime | null,
        to: number | DateTime | null
    }>) {
        if (!(value.from instanceof DateTime && value.to instanceof DateTime)) {
            return;
        }

        const from = value.from.toFormat('HH:mm');
        const to = value.to.toFormat('HH:mm');

        if (openingHourDay.openingHours?.id) {
            if (from === openingHourDay.openingHours.openFromString && to === openingHourDay.openingHours.openToString) {
                openingHourDay.processing = false;
                return;
            }

            openingHourDay.processing = true;
            this.openingHoursService.update(this.customer['id'], openingHourDay.openingHours.id, from, to).subscribe((updated) => {
                openingHourDay.openingHours = updated;
                openingHourDay.processing = false;
            });
        } else {
            if (openingHourDay.processing) {
                return;
            }

            openingHourDay.processing = true;
            this.openingHoursService.create(this.customer['id'], openingHourDay.dayIndex, from, to).subscribe((created) => {
                openingHourDay.openingHours = created;
                if (!this.canEditAll) {
                    this.permissionCheckService.isAllowed(`customers.${this.customer['id']}.opening_hours.${created.id}.update`).subscribe((canChange) => {
                        if (!canChange) {
                            openingHourDay.form.disable();
                        }
                        openingHourDay.processing = false;
                    });
                } else {
                    openingHourDay.processing = false;
                }
            });
        }
    }

    removingOpeningHours(day: OpeningHourDay) {
        if (!day.openingHours?.id) {
            return;
        }

        day.processing = true;
        this.openingHoursService.delete(this.customer['id'], day.openingHours.id).subscribe(() => {
            day.processing = false;
            day.openingHours = undefined;
            day.form.setValue({ from: null, to: null });
        });
    }
}
