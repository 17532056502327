import { Component, inject, OnInit } from '@angular/core';
import { DialogComponent, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { UploadHrFileDialogData, UploadHrFileDialogReturn } from './upload-hr-file-dialog.service';
import { HrFileType } from '../../models/hr-file-type';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DefaultHrFileService } from '../../http/default-hr-file.service';
import { HrDefaultFile } from '../../models/hr-default-file';
import { HrFileTypeService } from '../../http/hr-file-type.service';
import { catchError, forkJoin, map, merge, Observable, of, startWith, tap } from 'rxjs';
import { HrFileService } from '../../http/hr-file.service';
import { Employee } from '../../../shared/models/employee';
import { DateTime } from 'luxon';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { Namespace } from '../../../shared/enums/namespace';
import { HrFile } from '../../models/hr-file';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { FileSizePipe } from '../../../shared/pipes/file-size.pipe';
import { MaterialColorPipe } from '../../../shared/pipes/material-color.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { FileSelectorComponent } from '../../../shared/components/file-selector/file-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { User } from '../../../shared/models/user';
import { CustomerUserService } from '../../../shared/http/customer-user.service';
import { DocumentServiceService } from '../../../shared/http/document-service.service';
import { CurrentService } from '../../../shared/services/current.service';
import { mergeMap } from 'rxjs/operators';
import { SettingService } from '../../../shared/http/setting.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import mime from 'mime';

type SignatoryType = {
    name: Promise<string>,
    workflowId?: string,
    userSelect: boolean,
    type: 'none' | 'employee' | 'manager' | 'employee_manager';
}

const EMPLOYEE_SIGN_WORKFLOW = '88b7ccd1-2678-4888-8319-d855bda6d601';
const MANAGER_SIGN_WORKFLOW = '25f833dc-332c-4745-be17-3d33f78a9cc1';
const EMPLOYEE_AND_MANAGER_SIGN_WORKFLOW = '91c5c846-3090-4f5a-ac63-ccb217e15621';

type UploadHrFileForm = {
    filetype: FormControl<HrFileType | string | null>;
    warnDays: FormControl<number | null>;
    initials: FormControl<boolean>;
    employee: FormControl<Employee | number | null>;
    fileMethod: FormControl<'Custom' | 'Default' | 'DefaultMany' | 'Reload'>;
    defaultFileSearch: FormControl<any>;
    defaultFiles: FormControl<HrDefaultFile[]>;
    notify: FormControl<boolean>;
    managerUser: FormControl<User | string | null>;
    expiryDate: FormControl<DateTime | null>;
    defaultFile: FormControl<HrDefaultFile | string | null>;
    name: FormControl<string | null>;
    file: FormControl<File | null>;
    signatory: FormControl<SignatoryType | null>
};

@Component({
    selector: 'eaw-upload-hr-file-dialog',
    templateUrl: './upload-hr-file-dialog.component.html',
    styleUrl: './upload-hr-file-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        AutocompleteComponent,
        MatRadioModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        NgFor,
        MatOptionModule,
        MatSelectModule,
        MatIconModule,
        FileSelectorComponent,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatCheckboxModule,
        CheckboxHelperDirective,
        NgSwitch,
        NgSwitchCase,
        MatListModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        MaterialColorPipe,
        FileSizePipe,
    ],
})
export class UploadHrFileDialogComponent extends DialogComponent<UploadHrFileDialogData, UploadHrFileDialogReturn, UploadHrFileDialogComponent> implements OnInit {
    public readonly defaultHrFileService = inject(DefaultHrFileService);
    public readonly hrFileService = inject(HrFileService);
    public readonly hrFileTypeService = inject(HrFileTypeService);
    public readonly snackbar = inject(SnackBarService);
    public readonly translate = inject(TranslateService);
    public readonly employeeAutocompleteService = inject(EmployeeAutocompleteService);
    public readonly customerUserService = inject(CustomerUserService);
    public readonly documentServiceService = inject(DocumentServiceService);
    public readonly currentService = inject(CurrentService);
    public readonly settingService = inject(SettingService);
    public readonly permissionCheckService = inject(PermissionCheckService);

    fileMethod: UploadHrFileDialogReturn = 'Custom';
    minDate = DateTime.now();

    viewExistingFileHandleOptions = false;
    setExistingAsExpired = false;
    documentServiceEnabled = false;
    signingOptions: SignatoryType[] = [
        {
            name: this.translate.t('NONE'),
            type: 'none',
            userSelect: false,
        },
        {
            name: this.translate.t('EMPLOYEE', 'hr'),
            type: 'employee',
            workflowId: EMPLOYEE_SIGN_WORKFLOW,
            userSelect: false,
        },
        {
            name: this.translate.t('MANAGER', 'hr'),
            type: 'manager',
            workflowId: MANAGER_SIGN_WORKFLOW,
            userSelect: true,
        },
        {
            name: this.translate.t('EMPLOYEE_AND_MANAGER', 'hr'),
            type: 'employee_manager',
            workflowId: EMPLOYEE_AND_MANAGER_SIGN_WORKFLOW,
            userSelect: true,
        },
    ];

    customerId: number;
    employees: number[];
    loading = true;
    hasExpiry = false;
    notify = false;
    file?: File;
    defaultFiles: HrDefaultFile[] = [];
    filteredDefaultFiles: Observable<HrDefaultFile[][]> = of([]);
    filetype?: HrFileType;
    filetypes: HrFileType[] = [];
    users: User[] = [];
    filteredFiletypes: Observable<HrFileType[]> = of([]);
    filteredUsers: Observable<User[]> = of([]);
    employeeProvided = this.data.employees?.length >= 1;
    formGroup: FormGroup<UploadHrFileForm>;

    fileFormats?: string;
    invalidFileFormat = false;
    expectedFormats?: string;

    constructor() {
        const data = inject(MAT_DIALOG_DATA);
        data.size = DialogSize.Medium;

        super(undefined, data);

        this.formGroup = new FormGroup({
            employee: new FormControl<Employee | number | null>(this.employeeProvided ? this.data.employees[0] ?? null : null, Validators.required),
            fileMethod: new FormControl(this.fileMethod, { nonNullable: true }),
            filetype: new FormControl<HrFileType | null | string>(this.data.type ?? null),
            file: new FormControl<File | null>(null),
            name: new FormControl<string>(''),
            defaultFile: new FormControl<HrDefaultFile | string | null>(null),
            defaultFileSearch: new FormControl(),
            defaultFiles: new FormControl<HrDefaultFile[]>([], { nonNullable: true }),
            expiryDate: new FormControl<DateTime | null>(null),
            notify: new FormControl<boolean>(this.notify, { nonNullable: true }),
            warnDays: new FormControl<number | null>(14),
            signatory: new FormControl<SignatoryType | null>(null),
            managerUser: new FormControl<User | string | null>(null),
            initials: new FormControl<boolean>(false, { nonNullable: true }),
        });

        this.employees = this.data.employees;
        this.filetype = this.data.type;
        this.customerId = this.data.customerId;

        this.updateRequired();

        if (this.employeeProvided) {
            this.formGroup.controls.employee.disable();
        }

        if (this.filetype) {
            this.formGroup.controls.filetype.disable();
        }

        this.formGroup.controls.expiryDate.valueChanges.subscribe((date) => {
            this.hasExpiry = !!date;
            if (!date) {
                this.formGroup.controls.notify.setValue(false);
            }
        });

        this.formGroup.controls.notify.valueChanges.subscribe((value) => {
            this.notify = value;

            if (value) {
                this.formGroup.controls.warnDays.setValidators(Validators.required);
            } else {
                this.formGroup.controls.warnDays.clearValidators();
            }
            this.formGroup.controls.warnDays.updateValueAndValidity();
        });

        this.formGroup.controls.fileMethod.valueChanges.subscribe((val) => {
            this.fileMethod = val;
            this.updateRequired();
        });

        this.formGroup.controls.filetype.valueChanges.subscribe(() => {
            const signatory = this.formGroup.controls.signatory.value;
            this.updateFileFormats(signatory);
        });

        this.formGroup.controls.signatory.valueChanges.subscribe(this.updateFileFormats.bind(this));

        this.formGroup.controls.file.valueChanges.pipe(tap((file) => this.onFileChange(file || undefined))).subscribe();

        this.filteredDefaultFiles = merge(this.formGroup.controls.defaultFile.valueChanges, this.formGroup.controls.defaultFileSearch.valueChanges).pipe(
            map((value?: string | HrDefaultFile) => typeof value === 'string' ? value : ''),
            startWith(''),
            map((value) => value.toLowerCase()),
            map((value) => this.defaultFiles.filter((file) => file.name.toLowerCase().includes(value))),
            map((value) => this.filetypes.map((type) => value.filter((file) => file.typeId === type.id))),
        ) || of([]);

        this.filteredFiletypes = this.formGroup.controls.filetype.valueChanges.pipe(
            map((value) => typeof value === 'string' ? value : ''),
            startWith(''),
            map((value) => value.toLowerCase()),
            map((value) => this.filetypes.filter((type) => type.name.toLowerCase().includes(value))),
        ) || of([]);

        this.filteredUsers = this.formGroup.controls.managerUser.valueChanges.pipe(
            map((value) => typeof value === 'string' ? value : ''),
            startWith(''),
            map((value) => value.toLowerCase()),
            map((value) => this.users.filter((user) => user.name.toLowerCase().includes(value))),
        ) || of([]);

        this.getData();
    }

    private updateFileFormats(val: SignatoryType | null) {
        let formats: string | string[] | undefined;

        let acceptMimeTypes = (this.formGroup.controls.filetype.value as HrFileType)?.acceptFileTypes;

        if (val && val.type !== 'none') {
            formats = 'application/pdf';
            acceptMimeTypes = [ formats ];
        } else {
            formats = acceptMimeTypes?.join(',');
        }

        this.checkFileType();
        if (acceptMimeTypes) {
            this.expectedFormats = acceptMimeTypes.flatMap((type) => {
                const allExtensions = mime.getAllExtensions(type);

                return allExtensions ? Array.from(allExtensions).map((ext) => `.${ext}`) : [];
            }).join(', ');
        }

        this.fileFormats = formats;
    }

    ngOnInit() {
        this.settingService.getValue([ 'customers', this.customerId ], 'document-service-hr-file-workflows', 0).subscribe((value) => {
            if (value > 0) {
                this.documentServiceEnabled = true;
            }
        });
        this.permissionCheckService.isAllowed(`customers.${this.customerId}.users.*.get`).subscribe((hasPermission) => {
            if (!hasPermission) {
                this.signingOptions = this.signingOptions.filter((option) => option.type !== 'manager' && option.type !== 'employee_manager');
            }
        });
    }

    getData() {
        forkJoin([
            this.hrFileTypeService.getAll(this.customerId, { per_page: 9999 }),
            this.defaultHrFileService.getAll(this.customerId, { per_page: 9999 }).pipe(
                catchError(() => of({ data: [] })),
            ),
            this.customerUserService.getAll(this.customerId, {
                per_page: 9999,
            }),
        ]).subscribe(([ fileTypeResp, defaultFilesResp, usersResp ]) => {
            this.filetypes = fileTypeResp.data;
            this.defaultFiles = this.filetype ? defaultFilesResp.data.filter((x) => x.typeId === this.filetype?.id) : defaultFilesResp.data;
            this.users = usersResp.data;

            if (!this.filetype) {
                this.formGroup.controls.filetype.setValue(null);
            }

            this.formGroup.controls.defaultFile.setValue('');
            this.loading = false;
        });
    }

    onFileChange(file?: File) {
        this.file = file;
        this.checkFileType();
        this.formGroup.controls.name.setValue(file?.name || '');
    }

    checkFileType() {
        if (this.file) {
            const mimeType = mime.getType(this.file.name);
            const accepted = this.fileFormats || (this.formGroup.controls.filetype.value as HrFileType)?.acceptFileTypes;

            if (!accepted?.length || !mimeType) {
                return;
            }

            this.invalidFileFormat = !accepted.includes(mimeType);
        }
    }

    autocompleteDisplay(item?: HrDefaultFile | HrFileType) {
        return item?.name || '';
    }

    autocompleteManagerDisplay(item?: User) {
        return item?.name || '';
    }

    updateRequired() {
        this.formGroup.controls.filetype.clearValidators();
        this.formGroup.controls.name.clearValidators();
        this.formGroup.controls.defaultFile.clearValidators();
        this.formGroup.controls.defaultFiles.clearValidators();
        this.formGroup.controls.signatory.clearValidators();
        this.formGroup.controls.managerUser.clearValidators();

        switch (this.fileMethod) {
            case 'DefaultMany':
                this.formGroup.controls.defaultFiles.setValidators(Validators.required);
                break;
            case 'Default':
                this.formGroup.controls.defaultFile.setValidators(Validators.required);
                break;
            case 'Custom':
                this.formGroup.controls.name.setValidators(Validators.required);
                this.formGroup.controls.filetype.setValidators(Validators.required);

                if (this.documentServiceEnabled && this.formGroup.controls.signatory.value?.type !== 'none') {
                    this.formGroup.controls.signatory.setValidators(Validators.required);
                }
        }

        this.formGroup.controls.filetype.updateValueAndValidity();
        this.formGroup.controls.name.updateValueAndValidity();
        this.formGroup.controls.defaultFile.updateValueAndValidity();
        this.formGroup.controls.defaultFiles.updateValueAndValidity();
        this.formGroup.controls.signatory.updateValueAndValidity();
        this.formGroup.controls.managerUser.updateValueAndValidity();
    }

    private checkExistingFiles(filter: (file: HrFile) => boolean, files?: HrFile[]): boolean {
        if (this.viewExistingFileHandleOptions) {
            this.viewExistingFileHandleOptions = false;
            return false;
        } else if (files?.find((file) => filter(file) && (!file.expiresAt || file.expiresAt > DateTime.now()))) {
            this.viewExistingFileHandleOptions = true;
            this.formGroup.enable();
            return true;
        }

        return false;
    }

    submit() {
        const employee = this.formGroup.controls.employee.value;
        if (!(employee instanceof Employee)) {
            return;
        }

        this.formGroup.disable({ emitEvent: false });

        if (this.fileMethod === 'DefaultMany') {
            this.submitManyDefault(employee);
        } else if (this.fileMethod === 'Default') {
            this.submitDefault(employee);
        } else {
            this.submitCustom(employee);
        }
    }

    private submitManyDefault(employee: Employee) {
        const files = this.formGroup.controls.defaultFiles.value;
        const expiry = this.formGroup.controls.expiryDate.value ?? undefined;
        const notify = expiry ? this.formGroup.controls.notify.value : false;
        const warnDays = notify ? this.formGroup.controls.warnDays.value : undefined;
        const fileIds = files.map((file) => file.id);

        if (this.checkExistingFiles((file) => !!fileIds.find((id) => id === file.defaultFileId), employee.hrFiles)) {
            return;
        }

        void this.snackbar.t('ADDING_FILES_TO_EMP', 'hr', { employee: employee.name || '' });

        this.hrFileService.createManyOnOne(employee.customerId, employee.id, {
            default_file_ids: fileIds,
            expires_at: expiry,
            notify,
            warn_days: warnDays ?? undefined,
            set_existing_as_expired: this.setExistingAsExpired,
        }).subscribe({
            next: () => this.dialogRef.close('DefaultMany'),
            error: () => this.formGroup.enable(),
        });
    }

    private submitDefault(employee: Employee) {
        const file: HrDefaultFile = this.formGroup.controls.defaultFile.value as HrDefaultFile;
        const expiry = this.formGroup.controls.expiryDate.value as DateTime ?? undefined;
        const notify = expiry ? this.formGroup.controls.notify.value : false;
        const warnDays = notify ? this.formGroup.controls.warnDays.value : undefined;

        if (this.data.employees.length > 1) {
            void this.snackbar.t('ADDING_FILE_TO_EMP_MULT', Namespace.Hr, {
                file: file.name,
                count: this.data.employees.length,
            });

            this.hrFileService.createMany(this.data.customerId, {
                default_file_id: file.id,
                expires_at: expiry,
                notify,
                warn_days: warnDays ?? undefined,
                employee_ids: this.data.employees,
            }).subscribe({
                next: () => this.dialogRef.close('Default'),
                error: () => this.formGroup.enable(),
            });
        } else {
            if (this.checkExistingFiles((empFile) => empFile.defaultFileId === file.id, employee.hrFiles)) {
                return;
            }

            void this.snackbar.t('ADDING_FILE_TO_EMP', Namespace.Hr, {
                file: file.name,
                employee: employee.name || '',
            });

            this.hrFileService.createDefaultFile(employee.customerId, employee.id, {
                default_file_id: file.id,
                expires_at: expiry,
                notify,
                warn_days: warnDays ?? undefined,
                set_existing_as_expired: this.setExistingAsExpired,
            }).subscribe({
                next: () => this.dialogRef.close('Default'),
                error: () => this.formGroup.enable(),
            });
        }
    }

    private blobToData = (blob: Blob) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve((reader.result as string).split(',')[1] as string);
            reader.readAsDataURL(blob);
        });
    };

    private async submitCustom(employee: Employee) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const file = this.file!;
        const name = this.formGroup.controls.name.value;
        const type = this.formGroup.controls.filetype.value;
        const typeId = type instanceof HrFileType ? type.id : undefined;
        const expiry = this.formGroup.controls.expiryDate.value;
        const notify = expiry ? this.formGroup.controls.notify.value : false;
        const warnDays = notify ? this.formGroup.controls.warnDays.value : undefined;
        const signedBy = this.formGroup.controls.signatory.value;
        const manager = this.formGroup.controls.managerUser.value;
        const initials = this.formGroup.controls.initials.value;
        const originalFileName = file.name;
        const managerReloadTasksCheck = manager instanceof User && this.currentService.getUser().id == manager?.id;

        if (!name || !typeId) {
            return;
        }

        if (signedBy && signedBy?.type !== 'none') {
            const preparedFile = await this.blobToData(file);
            // use documentService
            if (this.data.employees.length > 1) {
                void this.snackbar.t('ADDING_FILE_TO_EMP_MULT', Namespace.Hr, {
                    file: name,
                    count: this.data.employees.length,
                });
                of(...this.data.employees).pipe(mergeMap((employeeId) => {
                    return this.documentServiceService.uploadHrDocument(
                        preparedFile as string,
                        this.customerId,
                        employeeId,
                        this.currentService.getUser().id,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        signedBy.workflowId!,
                        typeId,
                        name,
                        originalFileName,
                        manager instanceof User ? manager?.id : undefined,
                        initials,
                    );
                }, 2)).subscribe({
                    next: () => this.dialogRef.close(managerReloadTasksCheck ? 'Reload' : 'Custom'),
                    error: () => this.formGroup.enable(),
                });
            } else {
                if (signedBy?.workflowId && this.data.employees[0]) {
                    void this.snackbar.t('ADDING_FILE_TO_EMP', Namespace.Hr, {
                        file: name,
                        employee: employee.name || '',
                    });
                    this.documentServiceService.uploadHrDocument(
                        preparedFile as string,
                        this.customerId,
                        this.data.employees[0],
                        this.currentService.getUser().id,
                        signedBy.workflowId,
                        typeId,
                        name,
                        originalFileName,
                        manager instanceof User ? manager?.id : undefined,
                        initials,
                    ).subscribe({
                        next: () => this.dialogRef.close(managerReloadTasksCheck ? 'Reload' : 'Custom'),
                        error: () => this.formGroup.enable(),
                    });
                }
            }
        } else {
            // use hrFileService
            if (this.data.employees.length > 1) {
                void this.snackbar.t('ADDING_FILE_TO_EMP_MULT', Namespace.Hr, {
                    file: name,
                    count: this.data.employees.length,
                });

                this.hrFileService.createMany(this.data.customerId, {
                    notify,
                    file,
                    name,
                    type_id: typeId,
                    expires_at: expiry ?? undefined,
                    warn_days: warnDays ?? undefined,
                    employee_ids: this.data.employees,
                }).subscribe({
                    next: () => this.dialogRef.close('Custom'),
                    error: () => this.formGroup.enable(),
                });
            } else {
                if (this.checkExistingFiles((empFile) => empFile.typeId === typeId && empFile.name === name, employee.hrFiles)) {
                    return;
                }

                void this.snackbar.t('ADDING_FILE_TO_EMP', Namespace.Hr, {
                    file: name,
                    employee: employee.name || '',
                });

                this.hrFileService.createFile(this.data.customerId, employee.id, {
                    notify,
                    file,
                    name,
                    type_id: typeId,
                    expires_at: expiry ?? undefined,
                    warn_days: warnDays ?? undefined,
                    set_existing_as_expired: this.setExistingAsExpired,
                }).subscribe({
                    next: () => this.dialogRef.close('Custom'),
                    error: () => this.formGroup.enable(),
                });
            }
        }
    }
}
