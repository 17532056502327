// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { lastValueFrom } from 'rxjs';
module('eaw.notifications').component('notificationSettingList', {
    template: `<div layout="row" layout-align="center center" class="progress-container" ng-if="$list.loading">
    <md-progress-circular></md-progress-circular>
</div>

<div id="settings-list" ng-hide="$list.loading">
    <div class="setting-item header">
        <h3 class="f-500 text" ng-i18next="NOTIFICATION"></h3>

        <div class="options">
            <div class="option" ng-repeat="(icon, channel) in $list.icons track by $index">
                <md-icon ng-bind="icon">
                    <md-tooltip>{{'notifications:' + channel.toUpperCase() | i18next}}</md-tooltip>
                </md-icon>
            </div>
        </div>
    </div>

    <div class="setting-item" ng-repeat="setting in $list.settings">
        <span class="text" ng-i18next="notifications:{{setting.i18n}}"></span>

        <div class="options">
            <div class="option" ng-show="setting.value != setting.default">
                <md-button class="md-icon-button" ng-click="$list.reset(setting)">
                    <md-icon ng-bind="'refresh'"></md-icon>
                </md-button>
            </div>
            <div class="option" ng-repeat="(channel, active) in setting.channels track by channel">
                <md-checkbox class="condensed"
                             ng-model="setting.channels[channel]"
                             ng-change="$list.onToggled(setting)"
                             ng-disabled="setting.updating">
                </md-checkbox>
            </div>
        </div>
    </div>
</div>
`,
    bindings: {
        customer: '<?',
        user: '<',
        onNotificationUpdated: '&',
    },
    controllerAs: '$list',
    controller: [ 'NotificationSettings', 'UserPropertyDowngraded', function(NotificationSettings, UserPropertyDowngraded) {
        const ctrl = this;
        ctrl.$onInit = async () => {
            ctrl.settings = [];
            ctrl.icons = {
                mail: 'email',
                smartphone: 'mobile',
                notifications: 'database',
            };
            await ctrl.loadNotifications();
        };
        ctrl.loadNotifications = async () => {
            ctrl.loading = true;
            try {
                const notifications = await NotificationSettings.getSettings(ctrl.user.id, ctrl.customer?.id).$promise;
                ctrl.settings = Object.entries(notifications).map(([ key, notification ]) => {
                    if (key.startsWith('$')) {
                        return null;
                    }
                    notification.key = key;
                    notification.i18n = t(key.split('.')[1].toUpperCase());
                    notification.value ??= 'database,apn,gcm,mail';
                    notification.channels = getChannels(notification.value);
                    return notification;
                }).filter((n) => n);
            } finally {
                delete ctrl.loading;
            }
        };
        ctrl.onToggled = async (notification) => {
            const channels = Object.entries(notification.channels).map(([ k, v ]) => (v ? k : null)).filter((e) => e);
            notification.updating = true;
            try {
                const updated = await NotificationSettings.update(ctrl.user.id, notification.key, channels).$promise;
                updated.channels = getChannels(updated.value);
                Object.assign(notification, updated);
                ctrl.onNotificationUpdated?.({ notification });
            } finally {
                delete notification.updating;
            }
        };
        ctrl.reset = async (notification) => {
            await lastValueFrom(UserPropertyDowngraded.delete(ctrl.user.id, notification.key));
            notification.value = notification.default;
            notification.channels = getChannels(notification.value);
            ctrl.onNotificationUpdated?.({ notification });
        };
        function getChannels(val) {
            const channels = val.split(',');
            return {
                mail: !!channels.find((val) => val == 'mail'),
                mobile: !!channels.find((val) => {
                    const channel = val.toLowerCase();
                    return channel.indexOf('apn') >= 0 || channel.indexOf('gcm') >= 0;
                }),
                database: !!channels.find((val) => val == 'database'),
            };
        }
    } ],
});
