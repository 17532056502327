import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { TodoService } from '../../http/todo.service';
import { Todo } from '../../models/todo';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableIconColumn } from '../../../data-table/types/data-table-icon-column';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { TranslateService } from '../../../shared/services/translate.service';
import { TodoStatusService } from '../../http/todo-status.service';
import { TodoStatus } from '../../models/todo-status';
import { User } from '../../../shared/models/user';
import { CustomerLinkService } from '../../../shared/http/customer-link.service';
import { CurrentService } from '../../../shared/services/current.service';
import { Customer } from '../../../shared/models/customer';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { map, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { StatusSelectorDialogComponent, TodoStatusSelectorDialogData } from '../../dialogs/status-selector-dialog/status-selector-dialog.component';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { CreateTodoDialogComponent, CreateTodoDialogData } from '../../dialogs/create-todo-dialog/create-todo-dialog.component';
import { TodoStatusType } from '../../enums/todo-status-type';
import { AttachmentListDialogService } from '../../../shared/dialogs/attachment-list-dialog/attachment-list-dialog.service';
import { TodoInfoDialogComponent, TodoInfoDialogData } from '../../dialogs/todo-info-dialog/todo-info-dialog.component';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { CustomerUserAutocompleteComponent } from '../../../shared/components/customer-user-autocomplete/customer-user-autocomplete.component';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { CommentDialogComponent, CommentDialogData } from '../../../shared/dialogs/comments-dialog/comment-dialog.component';

type TodoCompleted = 'all' | 'completed' | 'not_completed';

interface DueDate {
    name: Promise<string>;
    value: 'past' | 'today' | 'tomorrow' | 'this_week' | 'this_month' | 'all';
}

interface FilterForm {
    includeFuture: FormControl<boolean>,
    filter: FormControl<string | null>,
    due: FormControl<DueDate['value']>,
    done: FormControl<TodoCompleted>,
    user: FormControl<User | null | undefined>,
    customerLink?: FormControl<Customer | null | undefined>,
    status: FormControl<number[]>
}

@Component({
    selector: 'eaw-todo-list',
    templateUrl: './todo-list.component.html',
    styleUrl: './todo-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatInputModule,
        CustomerUserAutocompleteComponent,
        MatButtonModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class TodoListComponent implements OnInit, EawDataTable {
    private readonly todoService = inject(TodoService);
    private readonly todoStatusService = inject(TodoStatusService);
    private readonly customerLinkService = inject(CustomerLinkService);
    private readonly current = inject(CurrentService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly matDialog = inject(MatDialog);
    private readonly attachmentListDialog = inject(AttachmentListDialogService);
    private readonly translate = inject(TranslateService);

    @ViewChild(DataTableComponent) table!: DataTableComponent<Todo>;

    @Input({ required: true }) stackId!: number;
    @Input({ required: true }) customerId!: number;
    @Input() userId?: number;

    fabButton: HeaderFabButton;

    dueDates: DueDate[] = [
        {
            value: 'past',
            name: this.translate.t('PAST_DUE', 'todo'),
        },
        {
            value: 'today',
            name: this.translate.t('TODAY'),
        },
        {
            value: 'tomorrow',
            name: this.translate.t('TOMORROW'),
        },
        {
            value: 'this_week',
            name: this.translate.t('THIS_WEEK'),
        },
        {
            value: 'this_month',
            name: this.translate.t('THIS_MONTH'),
        },
        {
            value: 'all',
            name: this.translate.t('ALL'),
        },
    ];

    form: FormGroup<FilterForm>;

    columns: DataTableColumnType<Todo>[];

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    todoStatuses: TodoStatus[] = [];
    childLocations: Customer[] = [];

    constructor() {
        this.columns = [
            new DataTableTextColumn({
                value: (cell) => cell.item.responsible?.name || '',
                header: new DataTableHeader({
                    i18n: 'RESPONSIBLE',
                    ns: 'todo',
                }),
            }),
            new DataTableTextColumn({
                value: 'title',
                header: new DataTableHeader({ i18n: 'TITLE' }),
            }),
            new DataTableTextColumn({
                key: 'description',
                value: 'description',
                header: new DataTableHeader({ i18n: 'DESCRIPTION' }),
            }),
            new DataTableTextColumn({
                value: (cell) => cell.item.due?.toRelativeCalendar() || '',
                header: new DataTableHeader({
                    i18n: 'DUE_DATE',
                    ns: 'todo',
                }),
            }),
            new DataTableIconColumn({
                icon: (cell) => cell.item.status?.icon || '',
                color: 'black-50',
                text: (cell) => cell.item.status?.name || '',
                header: new DataTableHeader({
                    i18n: 'STATUS',
                    ns: 'todo',
                }),
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        click: this.changeStatus.bind(this),
                        icon: 'edit_note',
                        show: () => of(true),
                        tooltip: {
                            key: 'CHANGE_STATUS',
                            ns: 'todo',
                        },
                        nonBlocking: true,
                    },
                    {
                        click: this.showComments.bind(this),
                        icon: 'comment',
                        show: (item) => {
                            if (!item.commentsCount) {
                                return of(false);
                            }

                            return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].todo_items.[${ApiModel.TodoItem}].get`, {
                                stackId: this.stackId,
                                models: [
                                    { id: item.id, type: ApiModelClass.TodoItem },
                                    { id: this.customerId, type: ApiModel.Customer },
                                ],
                            });
                        },
                        tooltip: { key: 'COMMENT_plural' },
                        nonBlocking: true,
                    },
                    {
                        click: this.showAttachments.bind(this),
                        icon: 'image',
                        show: (item) => {
                            if (!item.attachmentsCount) {
                                return of(false);
                            }

                            return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].todo_items.[${ApiModel.TodoItem}].get`, {
                                stackId: this.stackId,
                                models: [
                                    { id: item.id, type: ApiModelClass.TodoItem },
                                    { id: this.customerId, type: ApiModel.Customer },
                                ],
                            });
                        },
                        tooltip: { key: 'ATTACHMENT_plural' },
                        nonBlocking: true,
                    },
                    {
                        click: this.delete.bind(this),
                        icon: 'delete',
                        show: (item) => {
                            return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].todo_items.[${ApiModel.TodoItem}].delete`, {
                                stackId: this.stackId,
                                models: [
                                    { id: item.id, type: ApiModelClass.TodoItem },
                                    { id: this.customerId, type: ApiModel.Customer },
                                ],
                            });
                        },
                        tooltip: { key: 'DELETE' },
                        type: 'warn',
                    },
                ],
            }),
        ];
        this.form = new FormGroup<FilterForm>({
            includeFuture: new FormControl(false, { nonNullable: true }),
            filter: new FormControl(''),
            done: new FormControl('not_completed', { nonNullable: true }),
            due: new FormControl('all', { nonNullable: true }),
            status: new FormControl([], { nonNullable: true }),
            user: new FormControl(null),
        });
        this.fabButton = {
            click: this.createTodo.bind(this),
            hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.current.getCustomer().id}.todo_items.create`),
        };
    }

    ngOnInit(): void {
        this.todoStatusService.getAll(this.customerId).subscribe((res) => {
            this.todoStatuses = res.data.filter((s) => s.itemsCount || s.type !== TodoStatusType.Initial);
        });

        this.permissionCheckService.isAllowed(`customers.${this.customerId}.links.*.get`, {
            models: [
                { id: this.customerId, type: ApiModel.Customer },
            ],
        }).subscribe((hasPermission) => {
            if (hasPermission) {
                this.form.addControl('customerLink', new FormControl());
                this.form.controls.customerLink?.disable();

                this.customerLinkService.getChildren(this.customerId).subscribe((res) => {
                    this.form.controls.customerLink?.enable();
                    this.childLocations = res.data;
                });
            }
        });
    }

    rowClick(todo: Todo) {
        this.matDialog.open<TodoInfoDialogComponent, TodoInfoDialogData, boolean>(TodoInfoDialogComponent, {
            data: {
                todo,
            },
        }).afterClosed().subscribe((changed) => {
            if (changed) {
                this.updateTable(this.table.getPagination());
            }
        });
    }

    rowClasses(row: Todo) {
        const classes = [];

        if (row.expiresSoon()) {
            if (row.isExpired) {
                classes.push('expired');
            } else {
                classes.push('soon');
            }
        }

        return classes;
    }

    createTodo() {
        this.matDialog.open<CreateTodoDialogComponent, CreateTodoDialogData, Todo>(CreateTodoDialogComponent, {
            data: {
                customerId: this.customerId,
                userId: this.userId,
            },
        }).afterClosed().subscribe((res) => {
            if (res) {
                this.updateTable(this.table.getPagination({ page: 1 }));
            }
        });
    }

    showComments(cell: DataTableButtonCell<Todo>) {
        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                comments: this.todoService.getComments(cell.item.customerId, cell.item.id).pipe(map((resp) => resp.data)),
            },
        });
    }

    showAttachments(cell: DataTableButtonCell<Todo>) {
        const customerId = cell.item.customerId;
        const todoId = cell.item.id;

        this.attachmentListDialog.open({
            urls: this.todoService.getAttachments(customerId, todoId).pipe(
                map((resp) => {
                    return resp.data.map((a) => `/customers/${customerId}/todo_items/${todoId}/attachments/${a.id}`);
                }),
            ),
        });
    }

    changeStatus(cell: DataTableButtonCell<Todo>) {
        this.matDialog.open<StatusSelectorDialogComponent, TodoStatusSelectorDialogData, TodoStatus>(StatusSelectorDialogComponent, {
            data: {
                customerId: cell.item.customerId,
            },
        }).afterClosed().subscribe((status) => {
            if (status == null || status.id === cell.item.statusId) {
                return;
            }

            this.todoService.update(cell.item.customerId, cell.item.id, {
                status_id: status.id,
            }).subscribe(() => {
                this.updateTable(this.table.getPagination({ page: 1 }));
            });
        });
    }

    delete(cell: DataTableButtonCell<Todo>) {
        this.confirmDialogService.delete({}).afterClosed().subscribe((confirmation) => {
            if (confirmation?.ok) {
                this.todoService.delete(cell.item.customerId, cell.item.id).subscribe(() => {
                    this.updateTable(this.table.getPagination({ page: 1 }));
                });
            } else {
                cell.disabled.set(false);
            }
        });
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        let done = undefined;
        let due = undefined;
        let dueEnd = undefined;

        if (this.form.controls.done.value === 'completed') {
            done = true;
        }
        if (this.form.controls.done.value === 'not_completed') {
            done = false;
        }

        switch (this.form.controls.due.value) {
            case 'past': {
                due = DateTime.fromObject({ year: 2017 });
                dueEnd = DateTime.now();
                break;
            }
            case 'today': {
                due = DateTime.now().startOf('day');
                dueEnd = DateTime.now().endOf('day');
                break;
            }
            case 'tomorrow': {
                due = DateTime.now().plus({ day: 1 }).startOf('day');
                dueEnd = DateTime.now().plus({ day: 1 }).endOf('day');
                break;
            }
            case 'this_week': {
                due = DateTime.now().startOf('week');
                dueEnd = DateTime.now().endOf('week');
                break;
            }
            case 'this_month': {
                due = DateTime.now().startOf('month');
                dueEnd = DateTime.now().endOf('month');
                break;
            }
        }

        const params = {
            ...pagination,
            direction: 'asc' as const,
            done,
            include_future: this.form.controls.includeFuture.value,
            order_by: 'duehack',
            due,
            due_end: dueEnd,
            filter: this.form.controls.filter.value || undefined,
            customer_id: this.form.controls.customerLink?.value?.id || undefined,
            'with[]': [ 'status', 'responsible' ],
            'count[]': [ 'attachments', 'comments' ],
            'status_ids[]': this.form.controls.status.value,
        };

        const userId = this.userId || this.form.controls.user.value?.id || undefined;
        if (userId) {
            this.request = this.todoService.getAllForUser(this.customerId, userId, params);
        } else {
            this.request = this.todoService.getAllForCustomer(this.customerId, params);
        }
    }
}
