import { inject, Pipe, PipeTransform } from '@angular/core';
import { PermissionCheckService } from '../services/permission-check.service';

@Pipe({
    name: 'permission',
    standalone: true,
})
export class PermissionPipe implements PipeTransform {
    permissionCheckService = inject(PermissionCheckService);

    transform(value: string, placeholders?: (string | number)[]) {
        let replaced = value;
        placeholders?.forEach((p) => (replaced = replaced.replace('#', String(p))));

        return this.permissionCheckService.isAllowed(replaced);
    }
}
