// @ts-nocheck
import { module } from 'angular';

module('eaw.scheduling').controller('WeekOverviewPanel', [ 'mdPanelRef', function WeekOverviewPanel(mdPanelRef) {
    const ctrl = this;

    ctrl.close = () => {
        mdPanelRef.close();
    };
} ]);
