// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { PaginationOld } from '../../../shared/utils/pagination-old';
import { lastValueFrom } from 'rxjs';
module('eaw.chain-ops.user-groups').component('chainUserGroups', {
    template: `<md-card>
    <md-card-content>
        <md-radio-group ng-model="groups.mode" ng-disabled="groups.loading" ng-change="groups.refresh()">
            <md-radio-button ng-value="1">{{'company:USER_GROUP_OVERVIEW_WITH' | i18next}}</md-radio-button>
            <md-radio-button ng-value="0">{{'company:USER_GROUP_OVERVIEW_WITHOUT' | i18next}}</md-radio-button>
            <md-radio-button ng-value="undefined">{{'company:USER_GROUP_OVERVIEW_ALL' | i18next}}</md-radio-button>
        </md-radio-group>
    </md-card-content>
</md-card>

<md-progress-circular ng-if="groups.loading" class="md-progress-center tw-mt-24"></md-progress-circular>

<eaw-accordion ng-hide="groups.loading">
    <eaw-accordion-panel ng-repeat="mship in groups.customerGroup.active_memberships" toggled="groups.customerGroup.active_memberships.length == 1">
        <panel-header>
            <span ng-bind="mship.member.name"></span>
            <span flex></span>
            <span ng-i18next="[i18next]({count: groups.people[mship.member_id].length})X_PEOPLE"></span>
        </panel-header>
        <panel-content class="tw-p-0">
            <quick-grid people="groups.people[mship.member_id]" items="groups.userGroups[mship.member_id]" toggle="groups.toggleGroup"></quick-grid>
        </panel-content>
    </eaw-accordion-panel>
</eaw-accordion>
`,
    controllerAs: 'groups',
    bindings: {
        customerGroup: '<',
    },
    controller: [ 'UserGroupService', 'AngularUserService', '$location', function chainUserGroups(UserGroupService, AngularUserService, $location) {
        this.$onInit = async () => {
            this.mode = $location.search().mode ?? 1;
            this.loading = true;
            await this.getUsers();
            await this.load();
            this.loading = false;
            this.addMemberships();
        };
        this.refresh = async () => {
            $location.search('mode', this.mode);
            this.loading = true;
            await this.load();
            this.addMemberships();
            this.loading = false;
        };
        this.getUsers = async () => {
            this.users = await PaginationOld.getData(new PaginationOld({
                per_page: 512,
                page: 1,
            }), (pagination) => lastValueFrom(AngularUserService.getAllForCustomerGroup(this.customerGroup.id, {
                ...pagination,
                direction: typeof pagination.direction === 'string' ? pagination.direction : undefined,
                order_by: typeof pagination.order_by === 'string' ? pagination.order_by : undefined,
                'order_by[]': typeof pagination.order_by === 'object' ? pagination.order_by : undefined,
            })));
            this.people = {};
            this.users.map((user) => {
                user.customerUsers.map((cu) => {
                    this.people[cu.customerId] = (this.people?.[cu.customerId] || []).concat([ user ]);
                });
            });
        };
        this.load = async () => {
            const pag = new PaginationOld({
                per_page: 256,
                page: 1,
                with: [ 'activeMemberships' ],
            });
            pag.permissions = this.mode;
            this.userGroups = await PaginationOld.getData(pag, (pagination) => UserGroupService.getAllForCustomerGroup(this.customerGroup.id, pagination).$promise);
            this.userGroups = this.userGroups.reduce((obj, group) => {
                obj[group.owner_id] ||= [];
                obj[group.owner_id].push(group);
                return obj;
            }, {});
        };
        this.addMemberships = () => {
            this.users.map((user) => {
                user.customerUsers.map((cu) => {
                    const items = this.userGroups[cu.customerId]?.reduce((items, group) => {
                        items[group.id] = group.active_memberships.find((m) => m.member_id == user.id);
                        return items;
                    }, {});
                    user.items = user.items ? Object.assign(user.items, items) : items;
                });
            });
        };
        this.toggleGroup = (group, user) => {
            const groupId = group.id;
            if (user.items[groupId]) {
                UserGroupService.removeMember(group.owner_id, groupId, user.id, user.items[groupId].id).$promise.then(() => user.items[groupId] = null);
            } else {
                UserGroupService.addMember(group.owner_id, groupId, user.id, moment()).$promise.then((g) => {
                    user.items[groupId] = g.pivot;
                });
            }
        };
    } ],
});
