import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { ApiResponse } from '../interfaces/api-response';
import { Property, PropertyResponse } from './property';

export interface PositionResponse extends ApiResponse {
    created_at: string;
    customer_id: number;
    id: number;
    name: string;
    updated_at: string;
    properties?: PropertyResponse[];
    pivot?: {
        employee_id: number;
        position_id: number;
    };
}

export class Position {
    id: number;
    name: string;
    customerId: number;
    properties?: Property[];
    createdAt: DateTime;
    updatedAt: DateTime;
    pivot: {
        employeeId: number;
        positionId: number;
    };

    constructor(data: PositionResponse) {
        this.id = data.id;
        this.name = data.name;
        this.customerId = data.customer_id;
        this.properties = data.properties?.map((property) => new Property(property));
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.pivot = {
            employeeId: data.pivot?.employee_id || 0,
            positionId: data.pivot?.position_id || 0,
        };
    }
}
