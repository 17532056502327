@if (loading()) {
    <eaw-info-loading size="lg"/>
} @else {
    <mat-form-field subscriptSizing="dynamic" class="tw-w-full">
        <mat-label>{{ 'SHOW' | translate | async }}</mat-label>
        <mat-select value="all" (selectionChange)="setFilter($event.value)">
            <mat-option value="all">{{ 'ALL' | translateSync }}</mat-option>
            <mat-option value="qualified">{{ 'MISSING_NIGHT_WORKER_STATUS' | translateSync: 'widgets' }}</mat-option>
            <mat-option value="night-workers">{{ 'NIGHT_WORKER_plural' | translateSync:'widgets' }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-list>
        @for (worker of displayWorkers; track worker.employee.id) {
            <mat-list-item [class.lined]="!$first">
                <mat-icon matListItemIcon>{{ worker.hasNightWorkStatus ? 'done' : 'close' }}</mat-icon>
                <span matListItemTitle>{{ worker.employee.name }}</span>
                @if (worker.hasNightWorkStatus) {
                    <span matListItemLine>
                        <span>{{ 'NIGHT_WORKER' | translate: 'custom_fields' | async }} | </span>
                        <span>{{ 'FROM' | translate | async }}: {{ worker.from | DateTime: 'DATE_SHORT' }}</span>&nbsp;
                        <span *ngIf="worker.to">{{ 'TO' | translate | async }}: {{ worker.to | DateTime: 'DATE_SHORT' }}</span>
                    </span>
                } @else {
                    <span matListItemLine eawMaterialColor="red-700">{{ 'MISSING_NIGHT_WORKER_STATUS' | translate: 'widgets' | async }}</span>
                }
                <button matListItemMeta mat-icon-button [matTooltip]="('GO_TO_EMP' | translate | async)!" (click)="goToEmployee(worker.employee)">
                    <mat-icon>exit_to_app</mat-icon>
                </button>
            </mat-list-item>

            <mat-expansion-panel [expanded]="!!worker.alerts.length" [disabled]="!worker.alerts.length" class="tw-mx-16">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'X_ALERTS' | translate: 'alerts': {count: worker.alerts.length} | async }}</mat-panel-title>
                </mat-expansion-panel-header>
                @for (alert of worker.alerts; track alert.id) {
                    <div class="alert tw-flex tw-items-center tw-justify-between">
                        <p>{{ alert.message }}</p>
                        @if (worker.hasNightWorkStatus) {
                            <button mat-icon-button
                                    (click)="deleteAlert(alert, worker.alerts, $index)"
                                    [disabled]="deleting[alert.id]"
                                    [matTooltip]="('DELETE' | translate | async)!"
                                    color="warn">
                                <mat-icon>delete</mat-icon>
                            </button>
                        }
                    </div>
                }
            </mat-expansion-panel>
        } @empty {
            <mat-list-item>
                <mat-icon matListItemIcon>info</mat-icon>
                <span matListItemTitle>{{ 'NO_DATA' | translate | async }}</span>
            </mat-list-item>
        }
    </mat-list>
}
