import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import type SignaturePad from 'signature_pad';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

export type SignatureDialogReturn = { base64: string, svg: SVGElement };

export interface SignatureDialogComponentData extends DialogData {
    initials?: boolean;
}

@Component({
    selector: 'eaw-signature-dialog',
    templateUrl: './signature-dialog.component.html',
    styleUrl: './signature-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class SignatureDialogComponent extends DialogComponent implements AfterViewInit {
    @ViewChild('signatureCanvas') signatureCanvas!: ElementRef<HTMLCanvasElement>;

    width = 0;
    height = 0;
    signaturePad?: SignaturePad;

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<SignatureDialogComponent, SignatureDialogReturn>,
        @Inject(MAT_DIALOG_DATA) override data: SignatureDialogComponentData,
        @Inject(BreakpointObserver) private breakpointObserver: BreakpointObserver,
    ) {
        dialogRef.disableClose = true;
        data.size ||= DialogSize.Medium;
        super(dialogRef, data);
    }

    ngAfterViewInit() {
        void this.getPad();
    }

    async getPad() {
        const { default: SignaturePadClass } = await import(/* webpackChunkName: "signature-pad" */ 'signature_pad');
        this.dialogRef.afterOpened().subscribe(() => {
            this.breakpointObserver.observe([
                Breakpoints.HandsetLandscape,
                Breakpoints.TabletLandscape,
                Breakpoints.WebLandscape,
                Breakpoints.HandsetPortrait,
                Breakpoints.TabletPortrait,
                Breakpoints.WebPortrait,
            ]).subscribe((res) => {
                if (!res.matches) {
                    return;
                }

                const canvas = this.signatureCanvas.nativeElement;
                const canvasRect = canvas.getBoundingClientRect();

                this.signaturePad = new SignaturePadClass(canvas, { penColor: '#000' });
                this.signatureCanvas.nativeElement.width = canvasRect.width;
                this.signatureCanvas.nativeElement.height = canvasRect.height;
                this.width = canvasRect.width;
                this.height = canvasRect.height;
            });
        });
    }

    clear() {
        this.signaturePad?.clear();
    }

    modify(dataUrl: string) {
        const svgEl = this.getSvgElement(dataUrl);
        svgEl.setAttribute('viewBox', `0 0 ${this.width} ${this.height}`);
        svgEl.setAttribute('width', String(this.width));
        svgEl.setAttribute('height', String(this.height));
        svgEl.setAttribute('preserveAspectRatio', 'xMinYMid meet');
        return `data:image/svg+xml;base64,${window.btoa(new XMLSerializer().serializeToString(svgEl))}`;
    }

    getSvgElement(dataUrl: string) {
        const tempEl = document.createElement('div');
        tempEl.innerHTML = window.atob(dataUrl.slice(dataUrl.indexOf(',') + 1));
        return tempEl.firstChild as SVGElement;
    }

    submit() {
        if (this.signaturePad) {
            const svg = this.modify(this.signaturePad.toDataURL('image/svg+xml'));

            this.dialogRef.close({
                base64: this.signaturePad.toDataURL(),
                svg: this.getSvgElement(svg),
            });
        }
    }
}
