import { DateTime } from 'luxon';
import { WorkflowRunStep, WorkflowRunStepResponse } from './workflow-run-step';
import { ApiResponse } from '../interfaces/api-response';

export type WorkflowStatus = 'pending' | 'started' | 'in_progress' | 'completed' | 'failed' | 'cancelled';

export interface WorkflowRunResponse extends ApiResponse {
    id: string;
    workflow_id: string;
    run_identifier: string;
    owner_id: string;
    revision: number;
    status: WorkflowStatus;
    documents: string[];
    steps: WorkflowRunStepResponse[];
    created_at: string;
    updated_at: string;
}

export class WorkflowRun {
    id: string;
    workflowId: string;
    runIdentifier: string;
    ownerId: string;
    revision: number;
    status: WorkflowStatus;
    documents: string[];
    steps: WorkflowRunStep[];
    createdAt: DateTime;
    updatedAt: DateTime;

    constructor(response: WorkflowRunResponse) {
        this.id = response.id;
        this.workflowId = response.workflow_id;
        this.runIdentifier = response.run_identifier;
        this.ownerId = response.owner_id;
        this.revision = response.revision;
        this.status = response.status;
        this.documents = response.documents;
        this.steps = response.steps.map((step) => new WorkflowRunStep(step));
        this.createdAt = DateTime.fromISO(response.created_at);
        this.updatedAt = DateTime.fromISO(response.updated_at);
    }

    /**
     * Returns the most recent document version if there are any documents.
     */
    getMostRecentDocumentVersion() {
        if (this.documents.length) {
            return this.documents[this.documents.length - 1];
        }

        return undefined;
    }
}
