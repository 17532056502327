// @ts-nocheck
import { filter } from 'lodash-es';
import { module } from 'angular';
import { groupBy } from 'lodash-es';

import { Comment } from '../../shared/models/comment';
import { CurrentOld } from '../../shared/angularjs/current.factory';
export class ChecklistItemService {
    ChecklistItemFactory;
    static get $inject() {
        return [ 'ChecklistItemFactory' ];
    }

    checklist;
    attachments;
    comments;

    constructor(ChecklistItemFactory) {
        this.ChecklistItemFactory = ChecklistItemFactory;
    }

    setChecklist(checklist) {
        this.checklist = checklist;
        this.attachments = checklist.attachments ? groupBy(checklist.attachments, (attachment) => attachment.owner_id) : {};
        this.comments = checklist.comments ? groupBy(checklist.comments, (comment) => comment.commentable_id) : {};
    }

    getItemComments(itemId) {
        return this.comments?.[itemId]?.map((c) => new Comment(c));
    }

    getItemAttachments(itemId) {
        return this.attachments?.[itemId];
    }

    getComments(item) {
        return this.ChecklistItemFactory.getComments({
            list_id: this.checklist.id,
            item_id: item.id,
            with: [ 'user' ],
        }).then((response) => {
            item.comments = response.data;
            this.comments[item.id] = response.data;
            item.commentsLoaded = true;
            return this.comments[item.id];
        });
    }

    getAttachments(item) {
        return this.ChecklistItemFactory.getAttachments(this.checklist.id, item.id).then((resp) => {
            this.attachments[item.id] = item.type === 'file' ? filter(this.attachments, (att) => att.id !== (item?.data?.attachment_id || 0)) : resp.data;
            item.attachments = this.attachments[item.id];
            return this.attachments[item.id];
        });
    }

    addComment(item, body) {
        return this.ChecklistItemFactory.comment(this.checklist.id, item.id, body).then((comment) => {
            comment.user = CurrentOld.getUser();
            this.comments[item.id] = (this.comments[item.id] || []).concat(comment);
            item.comments = this.comments[item.id];
            return this.comments[item.id];
        }, console.error);
    }

    addAttachment(item, attachment) {
        return this.ChecklistItemFactory.uploadImage(this.checklist.id, item.id, attachment).then((resp) => {
            resp.data.user = CurrentOld.getUser();
            this.attachments[item.id] = (this.attachments[item.id] || []).concat(resp.data);
            item.attachments = this.attachments[item.id];
            return this.attachments[item.id];
        });
    }
}
module('eaw.misc').factory('ChecklistItemService', ChecklistItemService);
