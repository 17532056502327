import { Component, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AnnualNightHoursResponse, NightHoursReportService } from '../../http/night-hours-report.service';
import { DateTime, Info } from 'luxon';
import { MatButtonModule } from '@angular/material/button';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { Employee } from '../../../shared/models/employee';
import { MatCardModule } from '@angular/material/card';
import { catchError, of } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { BusinessDate } from '../../../shared/utils/business-date';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'eaw-annual-night-hours-report',
    standalone: true,
    imports: [
        CommonModule, PageHeaderComponent, TranslatePipe, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatButtonModule, AutocompleteComponent,
        MatCardModule, MatProgressBarModule, NumberPipe, DateTimePipe, MatSelectModule,
    ],
    templateUrl: './annual-night-hours-report.component.html',
    styleUrl: './annual-night-hours-report.component.scss',
})
export class AnnualNightHoursReportComponent implements OnInit {
    @Input() customerId!: number;
    data?: AnnualNightHoursResponse;
    loading = false;
    months = Info.months();
    minYear = DateTime.now().startOf('year').minus({ years: 2 });
    maxYear = DateTime.now().endOf('year');

    form = new FormGroup({
        year: new FormControl(this.maxYear.year, { nonNullable: true }),
        employees: new FormControl<Employee | null>(null),
    });

    constructor(
        @Inject(NightHoursReportService) private nightHoursReportService: NightHoursReportService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
    ) {}

    ngOnInit() {
        this.submit();
    }

    submit() {
        this.loading = true;
        const values = this.form.value;
        this.nightHoursReportService.getAnnualNightHours(this.customerId, {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            year: values.year!,
            'employees[]': values.employees ? [ values.employees.id ] : undefined,
        }).pipe(catchError(() => of(undefined))).subscribe((data) => {
            this.loading = false;
            if (!data) {
                return;
            }

            this.data = data;
        });
    }

    getCompensatedHours(employee: AnnualNightHoursResponse[number], month?: number) {
        if (employee.total_hours <= employee.annual_threshold) {
            return 0;
        }

        const compensationRate = employee.compensation_rate / 100;
        const amount = month ? employee.hours_per_month[month] || 0 : employee.total_hours;
        return amount * compensationRate;
    }

    formatEffectiveDate(date: string | null) {
        return date ? new BusinessDate(date) : null;
    }
}
