import { Inject, Injectable } from '@angular/core';
import { Autocomplete } from './autocomplete';
import { TranslateService } from '../services/translate.service';
import { Observable, of } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { UserGroupService } from '../http/user-group.service';
import { UserGroup } from '../../company/models/user-group';

@Injectable({
    providedIn: 'root',
})
export class CustomerUserGroupAutocompleteService extends Autocomplete<UserGroup> {

    constructor(
        @Inject(UserGroupService) protected userGroupService: UserGroupService,
        @Inject(TranslateService) protected translateService: TranslateService,
    ) {
        super({
            label: translateService.t('USER_GROUP'),
            optionTemplate: 'default',
            filterRequirement: 3,
            display: 'name',
            trackByKey: 'id',
        });
    }

    setter(customerId: number, groupId: number) {
        return (item: unknown) => {
            if (item instanceof UserGroup) {
                return of(item);
            }
            if (typeof item !== 'number') {
                return of(undefined);
            }
            return this.userGroupService.get(customerId, groupId);
        };
    }

    getter(customerId: number): () => Observable<ArrayPaginatedResponse<UserGroup>> {
        return () => this.userGroupService.getAllForCustomer(customerId);
    }
}
