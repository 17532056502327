import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { CurrentService } from '../services/current.service';
import { LoginService } from '../services/login.service';

@Injectable({
    providedIn: 'root',
})
export class NoAccessHookService {

    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(LoginService) private loginService: LoginService,
    ) {
        // Redirect to no access if the page requires login and the user doesn't have a customer
        this.uiRouter.transitionService.onBefore({
            to(state) {
                return state?.data?.requireLogin && state.name !== 'eaw/app/no_access' && !state.includes['eaw/app/profile'];
            },
        }, (transition) => {
            console.debug('🪝', 'NoAccessHookService.onBefore', transition);

            if (this.loginService.isLoggedIn()) {
                return this.current.hasCustomer() || transition.router.stateService.target('eaw/app/no_access');
            } else {
                return transition.router.stateService.target('eaw/app/login');
            }
        });

        // Redirect from no access if the user has a customer
        this.uiRouter.transitionService.onBefore({
            to(state) {
                return state?.data?.requireLogin && state?.name === 'eaw/app/no_access';
            },
        }, (transition) => {
            console.debug('🪝', 'NoAccessHookService.onBefore', transition);

            return !this.current.hasCustomer() || transition.router.stateService.target('eaw/app/home');
        });
    }
}
