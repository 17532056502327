<mat-card class="calendar tw-hidden md:tw-block">
    <mat-calendar (selectedChange)="dateUpdated($event)" [selected]="date"></mat-calendar>
</mat-card>

<mat-form-field eawDatePickerOptions class="md:tw-hidden calendar standalone">
    <mat-label>{{ 'DATE' | translate | async }}</mat-label>
    <input type="text" matInput [disabled]="loading" [matDatepicker]="$any(datepicker)" [(ngModel)]="date" (change)="dateUpdated($any($event))">
    <mat-datepicker #datepicker color="accent"></mat-datepicker>
</mat-form-field>

<div *ngIf="loading" class="calendar tw-h-full tw-flex tw-flex-col tw-items-center tw-content-center">
    <mat-progress-spinner diameter="64" mode="indeterminate"></mat-progress-spinner>
</div>

<mat-accordion multi class="accordion" *ngIf="!loading">
    <mat-expansion-panel *ngFor="let panel of panels;trackBy:trackBy" [(expanded)]="panel.open">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon [eawMaterialColor]="panel.background" class="tw-pr-12">
                    {{panel.icon}}
                </mat-icon>
                {{panel.group.name}}
            </mat-panel-title>
            <mat-panel-description>
                <span *ngIf="panel.loading">{{'FETCHING_INFORMATION' | translate | async}}</span>
                <span *ngIf="panel.loaded && panel.locks">
                    {{'XY_LOCKED' | translate: 'chainops': { x: panel.locked, y: panel.group.members.length } | async }}
                </span>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <table *ngIf="!panel.loading" class="members">
            <thead>
            <tr>
                <th>#</th>
                <th>{{ 'LOCATION' | translate| async }}</th>
                <th>{{ 'LOCKED_TO' | translate:'chainops' | async }}</th>
            </tr>
            </thead>
            <tr *ngFor="let customer of panel.group.members" class="tw-py-12" [class.locked]="isLocked(customer.id)">
                <td>
                    {{ customer.number }}
                </td>
                <td>
                    {{ customer.name }}
                </td>
                <td>
                    {{ locks.get(customer.id)?.to | DateTime:'DATE_MED' }}
                </td>
            </tr>
        </table>
    </mat-expansion-panel>
</mat-accordion>

