// @ts-nocheck
import { module } from 'angular';

const template1 = `<h2 ng-bind="$messages.unread"></h2>
<span ng-i18next="[i18next]({count: $messages.unread})widgets:UNREAD_MESSAGE"></span>
`;

module('eaw.dashboard').component('miniMessageWidget', {
    template: template1,
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$messages',
    controller: [ 'Message', function(Message) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.mini.dialogComponents = [ {
                name: 'messageList',
                label: 'MESSAGE_plural',
            } ];

            Message.getInbox({
                per_page: 1,
                read: false,
            }).then((resp) => {
                ctrl.unread = resp.total;
            }).finally(() => {
                ctrl.mini.loading(false);
            });
        };
    } ],
});
