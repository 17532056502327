@for (item of commentItems(); track item.comment.id) {
    <div class="comment mat-elevation-z2 tw-flex tw-flex-col tw-gap-8" [class.deleting]="item.deleting()">
        <div class="head tw-flex tw-flex-row tw-gap-8 tw-justify-between tw-items-center">
            <div class="tw-flex tw-flex-row tw-gap-12 tw-justify-start tw-items-center">
                <eaw-profile-picture appearance="circle" [user]="{id: item.comment.userId, name: item.comment.userName}"></eaw-profile-picture>

                <div class="tw-flex tw-flex-col">
                    <span class="mat-subtitle-2">{{item.comment.userName}}</span>
                    <span class="mat-caption">{{item.comment.createdAt | DateTime:'DATETIME_MED'}}</span>
                </div>
            </div>

            @if ((item.canDelete | async) && !item.deleting()) {
                <button mat-icon-button aria-label="Delete comment" (click)="delete(item)">
                    <mat-icon eawMaterialColor="red-500">delete</mat-icon>
                </button>
            }
        </div>

        <div class="body mat-body-2">{{item.comment.body}}</div>
    </div>
}
