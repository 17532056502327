// @ts-nocheck
import { module } from 'angular';

const template1 = `<div layout="row" ng-if="!recipient.loaded" layout-align="center center">
    <md-progress-circular></md-progress-circular>
</div>
<md-virtual-repeat-container class="eaw-virtual-repeat">
    <md-list class="md-dense" ng-show="recipient.loaded && recipient.list.getLength()">
        <md-list-item>
            <eaw-profile-picture-old class="tw-mr-16" user="recipient.message.user"></eaw-profile-picture-old>
            <p ng-bind="recipient.message.user.name"></p>
            <div class="md-secondary">
                <p bo-i18next="messages:SENDER"></p>
            </div>
        </md-list-item>
        <md-list-item md-virtual-repeat="user in recipient.list" md-on-demand>
            <eaw-profile-picture-old class="tw-mr-16" user="user"></eaw-profile-picture-old>
            <p ng-bind="user.name"></p>
            <div class="md-secondary" ng-if="recipient.showStats">
                <p md-colors="{color: 'amber'}" ng-show="user.pivot.read_at && !user.pivot.confirmed_at" bo-i18next="messages:OPENED"></p>
                <p md-colors="{color: 'red'}" ng-show="!user.pivot.read_at && !user.pivot.confirmed_at" bo-i18next="messages:NOT_OPENED"></p>
                <p md-colors="{color: 'green'}" bo-i18next="messages:CONFIRMED" ng-show="user.pivot.confirmed_at"></p>
            </div>
        </md-list-item>
    </md-list>
</md-virtual-repeat-container>
`;

/**
 * @ngdoc component
 *
 * @param {Message} message
 * @param {boolean} [showStats]
 */
module('eaw.messages').component('messageRecipients', {
    template: template1,
    controllerAs: 'recipient',
    bindings: {
        message: '<',
        showStats: '<?',
    },
    controller: [ 'EndlessList', function(EndlessList) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.list = new EndlessList(ctrl.loadPage, { order_by: [ 'first_name', 'last_name' ] });
            ctrl.list.loadPage(1).finally(() => {
                ctrl.loaded = true;
            });
        };

        ctrl.loadPage = (options) => ctrl.message.getRecipients(options).$promise;
    } ],
});
