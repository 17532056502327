import { ApiResponse } from '../interfaces/api-response';
import { User, UserResponse } from './user';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';

export interface BulletinResponse extends ApiResponse {
    id: number;
    body: string;
    customer_id: number;
    user_id: number,
    user?: UserResponse;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export class Bulletin {
    id: number;
    body: string;
    customer_id: number;
    user_id: number;
    user?: User;
    created_at: DateTime;
    updated_at: DateTime;
    deleted_at: DateTime | null;

    constructor(data: BulletinResponse) {
        this.id = data.id;
        this.body = data.body;
        this.customer_id = data.customer_id;
        this.user_id = data.user_id;
        this.user = data.user ? new User(data.user) : undefined;
        this.created_at = stringToDateTime(data.created_at);
        this.updated_at = stringToDateTime(data.updated_at);
        this.deleted_at = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
