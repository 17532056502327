<eaw-widget-settings-dialog [widgetInfo]="data.widgetInfo" [valid]="form.valid" (submitted)="submitted()" [loading]="loading()">
    <form content [formGroup]="form" class="tw-flex tw-flex-col tw-gap-16">
        <mat-form-field>
            <mat-label>{{ 'INTERVAL' | translate | async }}</mat-label>
            <mat-select formControlName="interval">
                @for (interval of intervals(); track interval.interval) {
                    <mat-option [value]="interval.interval">{{ interval.translation | async }}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'BALANCE' | translate:'balances' | async }}</mat-label>
            <mat-select formControlName="balanceCode">
                @for (type of balanceTypes(); track type.balanceCode) {
                    <mat-option [value]="type.balanceCode">{{ type.nameTranslation.key | translateSync:type.nameTranslation.ns }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</eaw-widget-settings-dialog>
