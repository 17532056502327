import { module } from 'angular';
import { CustomFieldOld } from '../../../../../../shared/angularjs/custom-field-old';
import { overlaps } from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import PaidTimeSettingsService from '../../../paid-time-settings.service';
import PaidTimeFactory from '../../../paid-time.factory';
import { Products } from '../../../../../../shared/enums/products';
import { BusinessUnit } from '../../../../../../business-units/models/business-unit';
import { BusinessUnitService } from '../../../../../../business-units/http/business-unit.service';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';

export class UpdatePaidTimeCtrl {
    businessUnits: BusinessUnit[] = [];
    hasBusinessUnits = false;
    businessUnitId?: number;
    // bindings
    parent: any;
    segment: any;
    paidTime: any;
    customFields: any;
    commentRequired = false;
    comment?: string;

    // Injects
    protected BusinessUnitDowngraded: BusinessUnitService;
    protected PaidTimeFactory: PaidTimeFactory;
    protected PaidTimeSettings: PaidTimeSettingsService;
    protected $scope: angular.IScope;

    static get $inject() {
        return [ '$scope', 'PaidTimeSettings', 'PaidTimeFactory', 'BusinessUnitDowngraded' ];
    }

    constructor(
        $scope: angular.IScope,
        PaidTimeSettings: PaidTimeSettingsService,
        PaidTimeFactory: PaidTimeFactory,
        BusinessUnitDowngraded: BusinessUnitService,
    ) {
        this.$scope = $scope;
        this.PaidTimeSettings = PaidTimeSettings;
        this.PaidTimeFactory = PaidTimeFactory;
        this.BusinessUnitDowngraded = BusinessUnitDowngraded;
    }

    $onInit = () => {
        this.paidTime = this.segment.paidTime || this.segment.paidTimeFlex;
        this.businessUnitId = this.paidTime?.business_unit_id;
        this.customFields = CustomFieldOld.getModelFields('paid_time', this.paidTime?.customFields);
        this.commentRequired = this.PaidTimeSettings.get('paidtime.comment.required');
        this.parent.businessDate = this.paidTime?.business_date.clone();
        this.$scope.$on('HandleSomethingCtrl:handle', this.updatePaidTime.bind(this));

        this.hasBusinessUnits = CurrentOld.hasProduct(Products.BusinessUnits);

        if (this.hasBusinessUnits) {
            this.getBusinessUnits();
        }
    };

    getBusinessUnits() {
        const customerId = this.segment.employeePaidTime.customer.id;
        this.businessUnits = [];

        this.BusinessUnitDowngraded.getAllPages(customerId, undefined, false).subscribe((businessUnits) => {
            this.businessUnits = businessUnits;
        });
    }

    updatePaidTime = () => {
        const intervals = this.segment.employeePaidTime.findAvailableIntervals(this.parent.interval, this.segment, 'update_paid_time');
        const interval = intervals.filter((i: any) => overlaps(i.from, i.to, this.segment.from, this.segment.to))[0];
        if (!this.paidTime) {
            return;
        }
        this.paidTime.from = interval.from;
        this.paidTime.to = interval.to;
        this.paidTime.business_unit_id = this.businessUnitId ?? null;
        this.paidTime.business_date = this.parent.businessDate;
        this.paidTime.customFields = this.customFields;
        this.paidTime.assignCustomFields();
        this.paidTime.comment = this.comment || undefined;

        return this.PaidTimeFactory.update(this.paidTime).$promise.then((res: any) => this.parent.close(res));
    };
}

module('eaw.payroll.paid-time').component('ptUpdatePaidTime', {
    template: `<md-input-container ng-if="$ctrl.hasBusinessUnits">
    <label ng-i18next="BUSINESS_UNIT_plural"></label>
    <md-select ng-model="$ctrl.businessUnitId" ng-disabled="!$ctrl.businessUnits">
        <md-option ng-value="undefined">
            {{ 'NONE' | i18next }}
        </md-option>
        <md-option ng-if="$ctrl.businessUnits" ng-repeat="unit in $ctrl.businessUnits" ng-value="unit.id">
            {{ unit.name }}
        </md-option>
    </md-select>
</md-input-container>

<eaw-custom-fields ng-model="$ctrl.customFields" ng-disabled="$ctrl.parent.handling"></eaw-custom-fields>

<md-input-container class="md-block">
    <label ng-i18next="COMMENT" for="comment"></label>
    <textarea id="comment" ng-model="$ctrl.comment" rows="1" ng-required="$ctrl.commentRequired" ng-disabled="$ctrl.parent.handling"></textarea>
</md-input-container>

`,
    bindings: {
        segment: '<',
        parent: '<',
    },
    controller: UpdatePaidTimeCtrl,
});
