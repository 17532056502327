// @ts-nocheck
import { module } from 'angular';
import { Namespace } from '../../../shared/enums/namespace';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.chain-ops.employees', [
    'eaw.resource',
    'eaw.login',
    'eaw.misc',
]).config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state('eaw/app/chain_ops/employee_list', {
        url: '/employees',
        parent: `eaw/app/chain_ops`,
        views: {
            'content@': 'chainTabs',
        },
        data: {
            i18nextNs: [ Namespace.Admin, Namespace.Company, Namespace.SwissRiskAndCare, Namespace.ChainOps, Namespace.Digisign ],
            breadcrumb: { key: 'EMPLOYEES', ns: 'navigation' },
        },
        resolve: {
            customerGroups: [ 'customerCustomerGroupFactory', 'customer', 'ToastService', function(customerCustomerGroupFactory, customer, ToastService) {
                return customerCustomerGroupFactory.getAll.query(customer.id, { with: [ 'members' ] }).$promise.then((resp) => {
                    const data = resp.data.filter((group) => {
                        group.members = group.members.filter((c) => CurrentOld.can(`customers.${c.id}.employees.*.get`));
                        return group.members.length;
                    });

                    if (data.length) {
                        return data;
                    }

                    void ToastService.t('NO_CUSTOMER_plural', Namespace.ChainOps);
                    return Promise.reject();
                });
            } ],
            sref: function() {
                return 'eaw/app/chain_ops/employee_list/tab';
            },
        },
    });

    $stateProvider.state('eaw/app/chain_ops/employee_list/tab', {
        parent: 'eaw/app/chain_ops/employee_list',
        url: '/:group',
        params: {
            group: {
                type: 'int',
                squash: true,
                default: null,
            },
        },
        views: {
            chainTabsView: 'chainEmployeeList',
        },
        data: {
            breadcrumb: { key: 'CUSTOMER_GROUP', ns: 'admin' },
        },
        resolve: {
            customerGroup: [ 'customerGroups', '$transition$', function(customerGroups, $transition$) {
                const id = $transition$.params().group;

                return customerGroups.find((group) => group.id == id);
            } ],
            customers: [ 'customerGroup', function(customerGroup) {
                return customerGroup.members;
            } ],
        },
    });
} ]);
