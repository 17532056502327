<eaw-dialog-header>
    <span title>{{ (data.initials ? 'INITIALS' : 'SIGN') | translate: 'hr' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content id="dialog-content">
    <div id="canvas-overlay" [class]="data.initials ? 'initials' : ''">
        <canvas #signatureCanvas></canvas>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{'CANCEL' | translate | async}}</button>
    <button mat-button mat-raised-button [disabled]="!signaturePad || signaturePad.isEmpty()" (click)="clear()">{{'CLEAR' | translate | async}}</button>
    <button mat-button mat-raised-button color="primary" [disabled]="!signaturePad || signaturePad.isEmpty()" (click)="submit()">{{'SIGN' | translate: 'hr' | async}}</button>
</mat-dialog-actions>


