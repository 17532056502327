// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog id="sync-event-dialog" class="medium-dialog">
    <eaw-dialog-header-old heading="'calendar:SYNC_EVENTS' | i18next"></eaw-dialog-header-old>

    <md-progress-circular ng-if="ctrl.loading" class="md-progress-center tw-mt-20 tw-mb-20"></md-progress-circular>

    <md-dialog-content ng-if="!ctrl.loading">
        <md-subheader>
            <div layout="row" layout-align="start center">
                <div layout="column" flex="100" class="tw-mr-20">
                    <input id="url-input" type="text" readonly ng-model="ctrl.url">
                    <small ng-i18next="calendar:CALENDAR_SYNC_URL_HELP_TEXT"></small>
                    <small md-colors="{color: 'red'}" ng-if="ctrl.error" ng-bind="ctrl.error"></small>
                </div>

                <md-button class="md-secondary md-icon-button md-raised md-accent" ng-disabled="ctrl.error" ng-click="ctrl.copy()">
                    <md-icon ng-bind="'content_copy'"></md-icon>
                    <md-tooltip md-direction="left" ng-i18next="calendar:COPY_LINK"></md-tooltip>
                </md-button>
            </div>
        </md-subheader>

        <div class="md-dialog-content">
            <div class="list-container">
                <div class="list-header" layout="row" layout-align="start center">
                    <md-checkbox class="condensed" md-indeterminate="ctrl.typeIndeterminate()" ng-checked="ctrl.typeChecked()" ng-click="ctrl.typeClicked()"></md-checkbox>
                    <span ng-i18next="calendar:TYPE_plural"></span>
                </div>

                <md-list class="md-dense">
                    <md-list-item ng-repeat="type in ctrl.types track by $index">
                        <md-checkbox ng-model="type.selected" ng-change="ctrl.updateUrl()"></md-checkbox>
                        <p ng-bind="type.name"></p>
                        <md-divider ng-if="!$last"></md-divider>
                    </md-list-item>
                </md-list>
            </div>

            <div class="list-container">
                <div class="list-header" layout="row" layout-align="start center">
                    <md-checkbox class="condensed" md-indeterminate="ctrl.locationIndeterminate()" ng-checked="ctrl.locationChecked()" ng-click="ctrl.locationClicked()"></md-checkbox>
                    <span ng-i18next="LOCATION_plural"></span>
                </div>

                <md-list class="md-dense">
                    <md-list-item ng-repeat="location in ctrl.locations track by $index">
                        <md-checkbox ng-model="location.selected" ng-change="ctrl.updateUrl()"></md-checkbox>
                        <p ng-bind="location.name"></p>
                        <md-divider ng-if="!$last"></md-divider>
                    </md-list-item>
                </md-list>
            </div>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.calendar2').service('SyncEventDialog', [ '$mdDialog', function HandleEventDialog($mdDialog) {
    const service = this;

    service.open = (customerId) => $mdDialog.show({
        template: template1,
        controller: 'syncEventDialogCtrl',
        controllerAs: 'ctrl',
        bindToController: true,
        clickOutsideToClose: false,
        locals: {
            customerId,
        },
    });

    return service;
} ]);
