// @ts-nocheck
import { pick } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { PaginationOld } from '../../../shared/utils/pagination-old';
module('eaw.payroll').factory('TimepunchFactory', [ 'EawResource', function TimepunchFactory(EawResource) {
    const fac = this;
    const customerUrl = '/customers/:customer_id/timepunches/:timepunch_id';
    const groupUrl = '/customer_groups/:group_id/timepunches';
    const employeeUrl = '/customers/:customer_id/employees/:employee_id/timepunches/:id';
    fac.getAll = (args) => {
        const url = args.group_id ? groupUrl : customerUrl;
        return EawResource.create(url).get({
            customer_id: args.customerId,
            group_id: args.group_id,
            'business_unit_ids[]': args.business_unit_ids,
            ...PaginationOld.getParams(args, [ 'from', 'to', 'customer_id', 'employee_id', 'approved', 'with_trashed', 'user_groups' ]),
            _businessDates: [ 'from', 'to' ],
        });
    };
    fac.get = (args) => EawResource.create(customerUrl).get({
        customer_id: args.customer,
        timepunch_id: args.timepunch,
        'with[]': args.with,
        include_changelog: args.include_changelog,
    });
    fac.approve = (args) => EawResource.create('/customers/:customer_id/timepunches/approve').update({
        ...pick(args, [ 'customer_id' ]),
    }, {
        ...pick(args, [ 'comment', 'ids' ]),
    });
    fac.getEmployeePunch = (args) => EawResource.create(employeeUrl).get({
        customer_id: args.customer,
        employee_id: args.employee,
        id: args.timepunch,
        'with[]': args.with,
        include_changelog: args.include_changelog,
    });
    fac.getForCustomer = (args) => EawResource.create(customerUrl).get({
        customer_id: args.customer.id,
        employee_id: args.employee ? args.employee.id : undefined,
        approved: args.approved,
        from: args.from,
        to: args.to,
        _businessDates: [ 'from', 'to' ],
        'with[]': args.with,
        ...PaginationOld.getParams(args),
    });
    fac.getWithShifts = (options) => EawResource.create('/customers/:customer_id/timepunches/summary').get({
        'user_groups[]': options.user_groups,
        ...PaginationOld.getParams(options, [ 'customer_id', 'employee_id', 'approved', 'from', 'to', 'with_trashed' ]),
    });
    fac.create = (customerId, employeeId, timepunchObj) => EawResource.create(employeeUrl).save({
        customer_id: customerId,
        employee_id: employeeId,
    }, {
        in: timepunchObj.in,
        out: timepunchObj.out,
        business_date: timepunchObj.business_date,
        business_unit_id: timepunchObj.business_unit_id,
        comment: timepunchObj.comment,
    });
    fac.update = function(customerId, timepunchId, timepunchObj) {
        return EawResource.create(customerUrl).update({
            customer_id: customerId,
            timepunch_id: timepunchId,
        }, {
            in: timepunchObj.in,
            out: timepunchObj.out,
            business_date: timepunchObj.business_date,
            business_unit_id: timepunchObj.business_unit_id,
            comment: timepunchObj.comment,
            approved: timepunchObj.approved,
            customer_id: timepunchObj.customer_id,
        });
    };
    fac.updateForEmployee = (timepunch) => EawResource.create(employeeUrl).update(pick(timepunch, [ 'customer_id', 'employee_id', 'id' ]), pick(timepunch, [ 'comment', 'out', 'in', 'business_date', 'business_unit_id' ]));
    fac.deleteForEmployee = (timepunch) => EawResource.create(employeeUrl).delete(pick(timepunch, [ 'customer_id', 'employee_id', 'id', 'comment' ]));
    fac.getForEmployee = (args) => EawResource.create(employeeUrl).get({
        customer_id: args.customer.id,
        employee_id: args.employee.id,
        'business_unit_ids[]': args.business_unit_ids,
        from: args.from ? moment(args.from).format('YYYY-MM-DD') : undefined,
        to: args.to ? moment(args.to).format('YYYY-MM-DD') : undefined,
        approved: args.approved == null ? undefined : args.approved,
        ...PaginationOld.getParams(args, [ 'customer_id', 'employee_id', 'approved', 'with_trashed', 'include_external' ]),
    });
    fac.delete = (customerId, timepunchId) => EawResource.create(customerUrl).delete({
        customer_id: customerId,
        timepunch_id: timepunchId,
    });
    return fac;
} ]);
