<eaw-page-header>
    <span title>{{ 'UI_UPGRADE_PROGRESS' | translate:'admin' | async }}</span>
    <span subtitle>{{ subtitle() }}</span>
</eaw-page-header>

<mat-slide-toggle [formControl]="hideUpgraded">Only show not upgraded</mat-slide-toggle>

<mat-card>
    @for (item of items(); track $index) {
        @if ((hideUpgraded.value && !item.upgraded) || !hideUpgraded.value) {
            <div class="item" [class.upgraded]="item.upgraded">
                <div class="name">{{ item.state.name }}</div>
                <div class="view">{{ item.view }}</div>

                @if (item.component) {
                    <div class="target">{{ item.component }}</div>
                }

                @if (item.controller) {
                    <div class="target">{{ item.controller }}</div>
                }
            </div>
        }
    }
</mat-card>
