import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrganizationMember, OrganizationMemberResponse } from '../../shared/models/organization-member';
import { map } from 'rxjs';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { DateTime } from 'luxon';

type CreateMemberOptions = { organization_id: string, customer_id: number, from: DateTime, to?: DateTime | null }
@Injectable({
    providedIn: 'root',
})
export class OrganizationMembersService {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
    }

    getAll(orgId: string) {
        return this.http.get<OrganizationMemberResponse[]>(`/organizations/${orgId}/members`).pipe(map((r) => {
            return r.map((m) => new OrganizationMember(m));
        }));
    }

    add(orgId: string, member: CreateMemberOptions) {
        return this.http.put<OrganizationMemberResponse>(`/organizations/${orgId}/members`, member).pipe(classifyItem(OrganizationMember));
    }

    delete(orgId: string, customerId: number) {
        return this.http.delete<null>(`/organizations/${orgId}/members`, { body: { customer_id: customerId } });
    }
}
