import { DateTime, Interval } from 'luxon';
import { Comment, CommentResponse } from '../../shared/models/comment';
import { Customer, CustomerResponse } from '../../shared/models/customer';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { Property, PropertyResponse } from '../../shared/models/property';
import { User, UserResponse } from 'src/app/shared/models/user';
import { Warning, WarningResponse } from '../../shared/models/warning';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BaseApiModel } from '../../shared/models/base-api-model';
import { BusinessUnit } from '../../business-units/models/business-unit';
import { BusinessUnitResponse } from '../../business-units/interfaces/business-unit-response';
import { BusinessDate } from '../../shared/utils/business-date';
import { BusinessDateString } from '../../shared/types/business-date';
import { Shift, ShiftResponse } from '../../scheduling/models/shift';

export type TimepunchChangesProperties = 'approval' | 'business_date' | 'in' | 'manual_close' | 'manual_open' | 'out';

export interface TimepunchResponse extends ApiResponse {
    warnings?: WarningResponse[];
    changelog?: TimepunchResponse[];
    properties?: PropertyResponse[];
    approved: boolean;
    approved_by?: null | number;
    approved_by_name?: null | string;
    business_date: BusinessDateString;
    business_unit_id?: number | null;
    business_unit?: BusinessUnitResponse | null;
    changes?: Record<TimepunchChangesProperties, boolean>;
    comments?: CommentResponse[];
    shifts?: ShiftResponse[];
    customer?: CustomerResponse;
    customer_id: number;
    edited_by_id?: number | null;
    edited_by?: UserResponse | null;
    employee_id: number;
    id: number;
    in: string;
    out?: string | null;
    in_client_id: number;
    length: number;
    manually_closed: boolean;
    manually_opened: boolean;
    out_client_id: number;
    time_edited: number | boolean;
    employee?: EmployeeResponse;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
}

export class Timepunch extends BaseApiModel<TimepunchResponse, Timepunch> {
    approved: boolean;
    approvedBy: null | number;
    approvedByName: null | string;
    businessDate: BusinessDate;
    businessUnitId?: number;
    comments: Comment[];
    customer?: Customer;
    customerId: number;
    editedById?: number | null;
    editedBy?: User | null;
    employeeId: number;
    id: number;
    in: DateTime;
    out: DateTime | null;
    isPunchedIn: boolean;
    inClientId: number | null;
    length: number;
    manuallyClosed: boolean;
    manuallyOpened: boolean;
    outClientId: number | null;
    timeEdited: boolean;
    interval?: Interval;
    createdAt: DateTime | null;
    updatedAt: DateTime | null;
    deletedAt: DateTime | null;
    employee?: Employee;
    changelog: Timepunch[];
    warnings?: Warning[];
    properties?: Property[];
    changes?: Record<TimepunchChangesProperties, boolean>;

    businessUnit: BusinessUnit | null = null;
    shifts: Shift[];

    constructor(data: TimepunchResponse) {
        super(data, undefined);

        this.approved = data.approved;
        this.approvedBy = data.approved_by ?? null;
        this.approvedByName = data.approved_by_name ?? null;
        this.businessDate = new BusinessDate(data.business_date);
        this.businessUnitId = data.business_unit_id ?? undefined;
        this.comments = data.comments?.map((c) => new Comment(c)) || [];
        this.customer = data.customer ? new Customer(data.customer) : undefined;
        this.customerId = data.customer_id;
        this.editedById = data.edited_by_id ?? null;
        this.editedBy = data.edited_by ? new User(data.edited_by) : null;
        this.employeeId = data.employee_id;
        this.id = data.id;
        this.in = stringToDateTime(data.in);
        this.out = data.out ? stringToDateTime(data.out) : null;
        this.isPunchedIn = !data.out;
        this.inClientId = data.in_client_id;
        this.length = data.length;
        this.manuallyClosed = data.manually_closed;
        this.manuallyOpened = data.manually_opened;
        this.outClientId = data.out_client_id;
        this.timeEdited = !!data.time_edited;
        this.employee = data.employee ? new Employee(data.employee) : undefined;
        this.interval = this.in && this.out ? Interval.fromDateTimes(this.in, this.out) : undefined;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.changelog = data.changelog?.map((c) => new Timepunch(c)) || [];
        this.warnings = data.warnings ? data.warnings.map((w) => new Warning(w)) : [];
        this.properties = data.properties?.map((p) => new Property(p));
        this.businessUnit = data.business_unit ? new BusinessUnit(data.business_unit) : null;
        // Parse the changes property (has all history)
        this.changes = JSON.parse(this.properties?.find((p) => p.key === 'changes')?.value.asString() || '{}');
        this.shifts = data.shifts?.map((s) => new Shift(s)) || [];
    }

    // Alias for "in"
    get from() {
        return this.in;
    }

    // Alias for "out"
    get to() {
        return this.out;
    }
}
