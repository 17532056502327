// @ts-nocheck
import { t } from 'i18next';
import { orderBy, uniqBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { ScheduleWeek } from '../../../types/schedule-week';

export class ScheduleDays {
    _schedule;
    _days;
    _renderDays;
    _allTimes;
    _allTimesRender;
    _allTimesDoubleRender;
    baseSpan = 4;

    setSchedule(schedule) {
        this._schedule = schedule;
    }

    isTemplate() {
        return this._schedule.is_template;
    }

    generate() {
        this.generateAll();
        this.generateRender();
    }

    generateAll() {
        const interval = this._schedule.settings.interval;
        const lastWeekday = moment().endOf('w').weekday();
        const time = this._schedule.from.clone();
        // Add one day to account for overflowing shifts
        const scheduleDaysSpan = Math.round(this._schedule.to.clone().diff(this._schedule.from.clone().startOf('d'), 's') / 86400) + 1;
        const days = [];
        for (let i = 0; i < scheduleDaysSpan; i++) {
            const newDay = {
                index: i,
                moment: time.clone(),
                weekNr: time.week(),
                dayName: time.format('dddd'),
                date: time.format('LL'),
                year: time.year(),
                weekYear: time.weekYear(),
                lastDayOfWeek: time.weekday() === lastWeekday,
                yearDay: time.dayOfYear(),
                offset: time.diff(this._schedule.from, 's'),
                templateLabel: t('DAY_N', { number: i + 1 }),
                holiday: this._schedule.is_template ? false : this._schedule.holidays?.some((h) => h.date.isSame(time, 'd')), // Don't show holidays if it's a template
            };
            const from = newDay.moment.clone().startOf('day');
            const to = newDay.moment.clone().endOf('day');
            const fromDst = from.isDST();
            const toDst = to.isDST();
            if (!this._schedule.is_template && fromDst != toDst) {
                if (fromDst && !toDst) {
                    // Going from dst to regular time
                    newDay.dstMessage = t('DAYLIGHT_SAVINGS_ENDS');
                } else {
                    // Going from regular time to dst
                    newDay.dstMessage = t('DAYLIGHT_SAVINGS_STARTS');
                }
            }
            days.push(newDay);
            // Find and add time to start of next day
            const timeUntilNextDay = time.clone().add(1, 'd').startOf('d').diff(time, 's');
            time.add(timeUntilNextDay, 's');
        }
        // Find colspans
        days.forEach((day, i) => {
            // Next day?
            if (days[i + 1]) {
                day.colspan = (days[i + 1].moment.diff(day.moment, 's') / interval) * this.baseSpan;
            } else {
                day.colspan = 999; // Max for Edge and stuff
            }
        });
        this._allTimes = [];
        const intervals = this._schedule.length / (interval / 2);
        const interv = interval / 2;
        const startTime = this._schedule.from.clone();
        const first = {
            id: 0,
            offset: 0,
            label: startTime.format('LT'),
        };
        // Add first time first
        this._allTimes.push(first);
        // Add the rest
        // Start at 1 because we already added 1
        for (let i = 1; i < intervals; i++) {
            const time = startTime.add(interv, 's');
            const push = {
                offset: (interv * i),
                label: time.format('LT'),
            };
            this._allTimes.push(push);
        }
        this._days = days;
    }

    generateRender() {
        const interval = parseInt(this._schedule.settings.interval);
        let offset = this._schedule.render.offset;
        const lastWeekday = moment().endOf('w').weekday();
        const time = this._schedule.render.from.clone();
        const scheduleDaysSpan = Math.round(this._schedule.render.to.clone().diff(this._schedule.render.from.clone().startOf('d'), 's') / 86400);
        const renderDays = [];
        for (let i = 0; i < scheduleDaysSpan; i++) {
            const index = time.diff(this._schedule.from.clone().startOf('d'), 'd');
            const newDay = {
                index,
                moment: time.clone(),
                weekNr: time.week(),
                dayName: time.format('dddd'),
                date: time.format('LL'),
                year: time.year(),
                weekYear: time.weekYear(),
                lastDayOfWeek: time.weekday() === lastWeekday,
                offset: time.diff(this._schedule.render.from, 's') + offset,
                templateLabel: t('DAY_N', { number: index + 1 }),
                holiday: this._schedule.is_template ? false : this._schedule.holidays?.some((h) => h.date.isSame(time, 'd')), // Don't show holidays if it's a template
            };
            const from = newDay.moment.clone().startOf('day');
            const to = newDay.moment.clone().endOf('day');
            const fromDst = from.isDST();
            const toDst = to.isDST();
            if (!this._schedule.is_template && fromDst != toDst) {
                if (fromDst && !toDst) {
                    // Going from dst to regular time
                    newDay.dstMessage = t('DAYLIGHT_SAVINGS_ENDS');
                } else {
                    // Going from regular time to dst
                    newDay.dstMessage = t('DAYLIGHT_SAVINGS_STARTS');
                }
            }
            renderDays.push(newDay);
            // Find and add time to start of next day
            const timeUntilNextDay = time.clone().add(1, 'd').startOf('d').diff(time, 's');
            time.add(timeUntilNextDay, 's');
        }
        // Find colspans
        renderDays.forEach((day, i) => {
            // Next day?
            if (renderDays[i + 1]) {
                if (interval > 3600) {
                    day.colspan = (86400 / interval) * this.baseSpan;
                    if (!i) {
                        day.colspan -= (3600 * day.moment.hour() / interval) * this.baseSpan;
                    }
                } else {
                    day.colspan = (renderDays[i + 1].moment.diff(day.moment, 's') / interval) * this.baseSpan;
                }
            } else {
                day.colspan = 999; // Max for Edge and stuff
            }
        });
        this._renderDays = renderDays;
        this._allTimesRender = [];
        this._allTimesDoubleRender = [];
        delete this._schedule.dstChangeFrom;
        this._schedule.dstOffset = -3600;
        const intervals = this._schedule.render.length / (interval / 2);
        const interv = interval / 2;
        const startTime = this._schedule.render.from.clone();
        const first = {
            id: 0,
            offset,
            interval,
            label: startTime.format('LT'),
            span: this.baseSpan / 2,
            labelSpan: this.baseSpan,
        };
        // Add first time first
        this._allTimesRender.push(first);
        this._allTimesDoubleRender.push(first);
        let prev;
        let isDST = startTime.isDST();
        let checkOn = false;
        // Add the rest
        // Start at 1 because we already added 1
        for (let i = 1; i < intervals; i++) {
            prev = startTime.clone();
            const push = {
                offset: (interv * i) + offset,
                interval,
                label: startTime.add(interv, 's').format('LT'),
                span: this.baseSpan / 2,
                labelSpan: this.baseSpan,
            };
            if (interval > 3600) {
                let labelCell = i % 2 == checkOn ? push : this._allTimesDoubleRender.at(-1);
                if (isDST && prev.isDST() && !startTime.isDST()) {
                    if (interval == 14400) {
                        if (labelCell === push) {
                            labelCell = this._allTimesDoubleRender.at(-1);
                        }
                        push.halfSize = true;
                        startTime.subtract(1, 'h');
                        offset -= 3600;
                        labelCell.fromDST = true;
                        this._schedule.dstChangeFrom = push.offset;
                    }
                    labelCell.info = t('scheduling:FROM_DST_INFO');
                    labelCell.label += '*';
                    labelCell.fromDST = true;
                    labelCell.interval += 3600;
                    this._allTimesRender.at(-1).span /= 2;
                    push.span /= 2;
                    checkOn = !checkOn;
                    isDST = false;
                    if (interval != 14400) {
                        this._allTimesRender.push(push);
                        continue;
                    }
                } else if (!isDST && !prev.isDST() && startTime.isDST()) {
                    labelCell.label += '*';
                    labelCell.toDST = true;
                    labelCell.interval -= 3600;
                    labelCell.info = t('scheduling:TO_DST_INFO');
                    if (interval == 14400) {
                        push.halfSize = true;
                        startTime.subtract(1, 'h');
                        offset -= 3600;
                        this._schedule.dstChangeFrom = push.offset;
                    } else {
                        push.span = this.baseSpan;
                        if (i % 2 == checkOn) {
                            this._allTimesDoubleRender.push(push);
                        }
                        checkOn = !checkOn;
                    }
                    isDST = true;
                }
            }
            this._allTimesRender.push(push);
            if (i % 2 == checkOn) {
                this._allTimesDoubleRender.push(push);
            }
        }
    }

    getWeeksArray() {
        let weeks: ScheduleWeek[] = orderBy(this.getAll(), [ 'year', 'week' ]).map((d) => {
            return {
                from: d.moment.clone().startOf('w'),
                to: d.moment.clone().endOf('w'),
                week: d.weekNr,
                weekNr: d.weekNr,
                year: this._schedule.is_template ? undefined : d.moment.weekYear(),
            };
        });
        weeks = uniqBy(weeks, (w) => [ w.week, w.year ].join());
        if (this._schedule.is_template) {
            weeks.forEach((week, index) => {
                week.week = index + 1;
            });
        }
        // Include index
        weeks.forEach((w, i) => {
            w.index = i;
        });
        return weeks;
    }

    // Exclude overflow day by default
    getAll(includeExtra = false) {
        if (includeExtra) {
            return this._days;
        } else {
            return this._days.slice(0, this._days.length - 1);
        }
    }

    getAllRender() {
        return this._renderDays;
    }

    getAllTimesRender() {
        return this._allTimesRender;
    }

    getAllTimesDoubleRender() {
        return this._allTimesDoubleRender;
    }

    getIndex(index) {
        return this._days[index];
    }
}

/**
 * @ngdoc this
 * @name scheduleDays
 */
module('eaw.scheduling').service('scheduleDays', ScheduleDays);
