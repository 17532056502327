import { inject, Injectable } from '@angular/core';
import { MainMenuEntryData } from '../interfaces/main-menu-entry-data';
import { Namespace } from '../../shared/enums/namespace';
import { UIRouter } from '@uirouter/core';
import { CurrentService } from '../../shared/services/current.service';
import { StateProviderDataData, StateProviderDataDataSettingCheck } from '../../shared/utils/create-state';
import { Products } from '../../shared/enums/products';
import { forkJoin, map, of } from 'rxjs';

@Injectable({
    providedIn: `root`,
})
export class MainMenuDataService {
    private readonly currentService = inject(CurrentService);
    private readonly uiRouter = inject(UIRouter);

    private getStateData(name: string, datas: StateProviderDataData[] = []) {
        const state = this.uiRouter.stateService.get(name);
        const data = typeof state.data === 'object' ? state.data as StateProviderDataData : undefined;

        if (data) {
            datas.push(data);
        }

        if (typeof state.parent === 'string') {
            this.getStateData(state.parent, datas);
        }

        return datas;
    }

    get(): MainMenuEntryData[] {
        const entries: MainMenuEntryData[] = [
            {
                key: `home`,
                state: `eaw/app/home`,
                icon: `home`,
                translationKey: `DASHBOARD`,
            },
            {
                key: `dashboard`,
                state: `eaw/app/dashboard`,
                icon: `home`,
                translationKey: `DASHBOARD_BETA`,
            },
            {
                key: `messages`,
                state: `eaw/app/messages/conversations/write`,
                glob: `eaw/app/messages`,
                icon: `email`,
                translationKey: `MESSAGES`,
                badges: [ `messages.unread` ],
            },
            {
                key: `newsletters`,
                state: `eaw/app/newsletters`,
                glob: `eaw/app/newsletters`,
                translationKey: `NEWSLETTER_plural`,
                icon: `newspaper-variant-outline`,
            },
            {
                key: `admin`,
                glob: `eaw/app/admin`,
                icon: `lock`,
                translationKey: `ADMIN`,
                subMenu: [
                    {
                        key: `admin_users`,
                        state: `eaw/app/admin/users/list`,
                        glob: `eaw/app/admin/users`,
                        translationKey: `USERS`,
                    },
                    {
                        key: `admin_customers`,
                        state: `eaw/app/admin/customers/list`,
                        glob: `eaw/app/admin/customers`,
                        translationKey: `CUSTOMERS`,
                    },
                    {
                        key: `admin_products`,
                        state: `eaw/app/admin/products/list`,
                        glob: `eaw/app/admin/products`,
                        translationKey: `PRODUCTS`,
                    },
                    {
                        key: `admin_setting_groups`,
                        state: `eaw/app/admin/setting_groups`,
                        translationKey: `SETTING_GROUPS`,
                    },
                    {
                        key: `admin_customer_groups`,
                        state: `eaw/app/admin/customer_groups`,
                        glob: `eaw/app/admin/customer_groups`,
                        translationKey: `CUSTOMER_GROUPS`,
                    },
                    {
                        key: `admin_permission_sets`,
                        state: `eaw/app/admin/permissionsets/list`,
                        glob: `eaw/app/admin/permissionsets`,
                        translationKey: `PERMISSIONSETS`,
                    },
                    {
                        key: `admin_custom_fields`,
                        state: `eaw/app/admin/custom_fields`,
                        translationKey: `CUSTOM_FIELDS`,
                    },
                    {
                        key: `admin_languages`,
                        state: `eaw/app/admin/languages`,
                        translationKey: `LANGUAGES`,
                    },
                    {
                        key: `admin_locales`,
                        state: `eaw/app/admin/locales`,
                        translationKey: `LOCALES`,
                    },
                    {
                        key: `admin_stacks`,
                        state: `eaw/app/admin/stacks/list`,
                        translationKey: `STACKS`,
                    },
                    {
                        key: `admin_pay_types`,
                        state: `eaw/app/admin/pay_types`,
                        translationKey: `PAY_TYPES`,
                    },
                    {
                        key: `admin_reports`,
                        state: `eaw/app/admin/reports`,
                        translationKey: `REPORTS`,
                    },
                    {
                        key: `admin_system_alerts`,
                        state: `eaw/app/admin/system_alerts`,
                        translationKey: `SYSTEM_ALERTS`,
                    },
                    {
                        key: `admin_system_settings`,
                        state: `eaw/app/admin/organizations`,
                        translationKey: `ORGANIZATIONS`,
                    },
                    {
                        key: `admin_user_stats`,
                        state: `eaw/app/admin/stats/user_stats`,
                        translationKey: `STATISTICS`,
                    },
                    {
                        key: `admin_update_progress`,
                        state: `eaw/app/admin/stats/update_progress`,
                        translationKey: `UI_UPGRADE_PROGRESS`,
                        translationNs: `admin`,
                    },
                ],
            },
            {
                key: `scheduling`,
                glob: `eaw/app/scheduling`,
                icon: `view_week`,
                translationKey: `SCHEDULING`,
                subMenu: [
                    {
                        key: `scheduling_schedules`,
                        state: `eaw/app/scheduling/schedules/list`,
                        translationKey: `SCHEDULES`,
                    },
                    {
                        key: `scheduling_shift_templates`,
                        state: `eaw/app/scheduling/limits`,
                        translationKey: `SCHEDULING_LIMITS`,
                    },
                    {
                        key: `scheduling_my_shifts`,
                        state: `eaw/app/scheduling/shifts/my/list`,
                        glob: `eaw/app/scheduling/shifts/my`,
                        translationKey: `MY_SHIFTS`,
                    },
                    {
                        key: `scheduling_available_shifts`,
                        state: `eaw/app/scheduling/shifts/available_shifts`,
                        translationKey: `AVAILABLE_SHIFTS`,
                        badges: [ `scheduling.swaps.available` ],
                    },
                    {
                        key: `scheduling_shifts`,
                        state: `eaw/app/scheduling/shifts/shifts`,
                        translationKey: `SHIFTS`,
                    },
                    {
                        key: `scheduling_shifts_overview`,
                        state: `eaw/app/scheduling/shifts_overview`,
                        translationKey: `SHIFTS_OVERVIEW`,
                    },
                    {
                        key: `scheduling_shift_swaps`,
                        state: `eaw/app/scheduling/swaps/handle`,
                        glob: `eaw/app/scheduling/swaps`,
                        translationKey: `SHIFT_SWAPS`,
                        badges: [ `scheduling.swaps.unhandled` ],
                    },
                    {
                        key: `scheduling_shift_changes`,
                        state: `eaw/app/scheduling/shift_changes`,
                        translationKey: `SCHEDULE_CHANGES`,
                    },
                ],
            },
            {
                key: `availability`,
                glob: `eaw/app/availability`,
                translationKey: `AVAILABILITY`,
                icon: `calendar_today`,
                subMenu: [
                    {
                        key: `availability_my`,
                        state: `eaw/app/availability/my/current`,
                        glob: `eaw/app/availability/my`,
                        translationKey: `MY_AVAILABILITY`,
                    },
                    {
                        key: `availability_manage`,
                        state: `eaw/app/availability/overview`,
                        glob: `eaw/app/availability/manage`,
                        translationKey: `MANAGE_AVAILABILITY`,
                        badges: [ `availability.unhandled` ],
                    },
                ],
            },
            {
                key: `balances_my`,
                state: `eaw/app/balances`,
                icon: `exposure`,
                translationKey: `MY_BALANCES`,
            },
            {
                key: `absence`,
                glob: `eaw/app/absence`,
                icon: `hotel`,
                translationKey: `ABSENCE`,
                subMenu: [
                    {
                        key: `absence_register`,
                        state: `eaw/app/absence/register`,
                        translationKey: `REGISTER_ABSENCE`,
                    },
                    {
                        key: `absence_list`,
                        state: `eaw/app/absence/list`,
                        translationKey: `SEE_ABSENCES`,
                        badges: [ `absence.unhandled` ],
                    },
                    {
                        key: `absence_my`,
                        state: `eaw/app/absence/my`,
                        translationKey: `MY_ABSENCES`,
                    },
                    {
                        key: `absence_calendar`,
                        state: `eaw/app/absence/calendar`,
                        translationKey: `ABSENCE_CALENDAR`,
                    },
                    {
                        key: `absence_stats`,
                        state: `eaw/app/absence/stats`,
                        translationKey: `STATISTICS`,
                    },
                ],
            },
            {
                key: `leave_shifts`,
                glob: `eaw/app/leave_shifts`,
                translationKey: `LEAVE_SHIFTS`,
                icon: `event`,
                subMenu: [
                    {
                        key: `leave_shifts_all`,
                        state: `eaw/app/leave_shifts/customer`,
                        translationKey: `CUSTOMER_LEAVE_SHIFTS`,
                    },
                    {
                        key: `leave_shifts_my`,
                        state: `eaw/app/leave_shifts/my`,
                        translationKey: `MY_LEAVE_SHIFTS`,
                    },
                ],
            },
            {
                key: `vacation`,
                glob: `eaw/app/vacations`,
                icon: `flight_takeoff`,
                translationKey: `VACATIONS`,
                subMenu: [
                    {
                        key: `vacation_my`,
                        state: `eaw/app/vacations/request/list/timeoff`,
                        glob: `eaw/app/vacations/request`,
                        translationKey: `MY_VACATION`,
                    },
                    {
                        key: `vacation_manage`,
                        state: `eaw/app/vacations/manage/handle`,
                        glob: `eaw/app/vacations/manage`,
                        translationKey: `VACATION_HANDLE`,
                        badges: [ `vacation.unhandled` ],
                    },
                ],
            },
            {
                key: `ai_budgeting`,
                state: `eaw/app/ai_budgeting`,
                icon: `smart_toy`,
                translationKey: `AI_BUDGETING`,
            },
            {
                key: `ai_projections`,
                state: `eaw/app/ai_projections`,
                icon: `smart_toy`,
                translationKey: `AI_PROJECTION_plural`,
                translationNs: `ai_budgeting`,
            },
            {
                key: `analytics`,
                state: `eaw/app/analytics`,
                glob: `eaw/app/analytics`,
                icon: `insights`,
                translationKey: `ANALYTICS`,
                translationNs: `analytics`,
            },
            {
                key: `people_kpi`,
                state: `eaw/app/people_kpi`,
                icon: `supervisor_account`,
                translationKey: `PEOPLE_KPI`,
            },
            {
                key: `kpi`,
                state: `eaw/app/kpi`,
                icon: `insights`,
                translationKey: `KEY_PERFORMANCE_INDICATORS`,
            },
            {
                key: `chain_ops`,
                glob: `eaw/app/chain_ops`,
                icon: `store`,
                translationKey: `CHAIN_OPS`,
                subMenu: [
                    {
                        key: `chain_ops_availability`,
                        state: `eaw/app/chain_ops/availability`,
                        translationKey: `AVAILABILITY`,
                    },
                    {
                        key: `chain_ops_balances`,
                        state: `eaw/app/chain_ops/balances`,
                        translationKey: `BALANCES`,
                        translationNs: `navigation`,
                    },
                    {
                        key: `paid_time_overview`,
                        state: `eaw/app/chain_ops/paid_time`,
                        translationKey: `PAID_TIME_OVERVIEW`,
                        translationNs: `payroll`,
                    },
                    {
                        key: `chain_ops_kpi`,
                        state: `eaw/app/chain_ops/kpi`,
                        translationKey: `KEY_PERFORMANCE_INDICATORS`,
                    },
                    {
                        key: `chain_ops_hr`,
                        state: `eaw/app/chain_ops/hr`,
                        translationKey: `HR`,
                    },
                    {
                        key: `chain_ops_scheduling`,
                        state: `eaw/app/chain_ops/schedule_overview`,
                        translationKey: `SCHEDULE_OVERVIEW`,
                    },
                    {
                        key: `chain_ops_absences`,
                        state: `eaw/app/chain_ops/absences`,
                        translationKey: `CHAINOPS_ABSENCES`,
                    },
                    {
                        key: `chain_ops_period_locks`,
                        state: `eaw/app/chain_ops/locks`,
                        translationKey: `LOCKS`,
                    },
                    {
                        key: `chain_ops_employees`,
                        state: `eaw/app/chain_ops/employee_list`,
                        translationKey: `EMPLOYEES`,
                    },
                    {
                        key: `chain_ops_user_groups`,
                        state: `eaw/app/chain_ops/user_groups`,
                        translationKey: `USER_GROUPS`,
                    },
                    {
                        key: `chain_ops_tariffs`,
                        state: `eaw/app/chain_ops/tariffs`,
                        translationKey: `CHAIN_TARIFF`,
                    },
                    {
                        key: `chain_ops_newsletters`,
                        state: `eaw/app/chain_ops/newsletters`,
                        translationKey: `CHAIN_NEWSLETTER`,
                    },
                ],
            },
            {
                key: `my_company`,
                glob: `eaw/app/company`,
                icon: `domain`,
                translationKey: `MY_COMPANY`,
                subMenu: [
                    {
                        key: `employees`,
                        state: `eaw/app/company/employees/local`,
                        glob: `eaw/app/company/employees`,
                        translationKey: `EMPLOYEES`,
                        badges: [ `employees.warnings` ],
                    },
                    {
                        key: `users`,
                        state: `eaw/app/company/users`,
                        translationKey: `USERS`,
                        badges: [ `users.warnings` ],
                    },
                    {
                        key: `settings`,
                        state: `eaw/app/company/settings/my_company`,
                        translationKey: `SETTINGS`,
                    },
                    {
                        key: `user_groups`,
                        state: `eaw/app/company/user_groups/quick`,
                        glob: `eaw/app/company/user_groups`,
                        translationKey: `USER_GROUPS`,
                    },
                    {
                        key: `employee_groups`,
                        state: `eaw/app/company/employee_groups`,
                        glob: `eaw/app/company/employee_groups`,
                        translationKey: `EMPLOYEE_GROUPS`,
                    },
                    {
                        key: `fixed_labor_cost`,
                        state: `eaw/app/fixed_labor_cost`,
                        translationKey: `FIXED_LABOR_COST`,
                    },
                    {
                        key: `balances`,
                        state: `eaw/app/company/balances`,
                        translationKey: `BALANCES`,
                    },
                    {
                        key: `emergency_contacts`,
                        state: `eaw/app/company/emergency_contacts`,
                        translationKey: `EMERGENCY_CONTACTS`,
                    },
                    {
                        key: `emergency_contacts_my`,
                        state: `eaw/app/company/my_emergency_contact`,
                        translationKey: `MY_EMERGENCY_CONTACT`,
                        badges: [ `emergency_contact` ],
                    },
                ],
            },
            {
                key: `coupons`,
                state: `eaw/app/coupons/my`,
                icon: `local_activity`,
                translationKey: `COUPONS`,
            },
            {
                key: `hr`,
                glob: `eaw/app/hr`,
                icon: `inventory_2`,
                translationKey: `HR`,
                subMenu: [
                    {
                        key: `hr_my_files`,
                        state: `eaw/app/hr/my_files`,
                        translationKey: `HR_MY_FILES`,
                        badges: [ `hr.employee_missing` ],
                    },
                    {
                        key: `hr_files`,
                        state: `eaw/app/hr/files`,
                        translationKey: `HR_FILES`,
                        badges: [ `hr.customer_missing` ],
                    },
                    {
                        key: `hr_file_types`,
                        state: `eaw/app/hr/filetypes`,
                        translationKey: `HR_FILETYPES`,
                    },
                    {
                        key: `hr_default_files`,
                        state: `eaw/app/hr/default_files`,
                        translationKey: `DEFAULT_HR_FILES`,
                    },
                ],
            },
            {
                key: `file_system`,
                state: `eaw/app/filesystem`,
                icon: `folder`,
                translationKey: `FILES`,
                params: [
                    { key: `path`, value: `` },
                ],
            },
            {
                key: `file_system_access`,
                state: `eaw/app/filesystem_access`,
                icon: `folder`,
                translationKey: `FILESYSTEM_ACCESS`,
            },
            {
                key: `rotation`,
                state: `eaw/app/rotation`,
                icon: `manage_history`,
                translationKey: `ROTATIONS`,
            },
            {
                key: `payroll`,
                glob: `eaw/app/payroll`,
                icon: `payments`,
                translationKey: `PAYROLL`,
                subMenu: [
                    {
                        key: `payroll_overview`,
                        state: `eaw/app/payroll/overview`,
                        translationKey: `PAYROLL_OVERVIEW`,
                        translationNs: Namespace.PayrollOverview,
                    },
                    {
                        key: `payroll_my`,
                        state: `eaw/app/payroll/my/timepunches`,
                        glob: `eaw/app/payroll/my`,
                        translationKey: `MY_PAYROLL`,
                    },
                    {
                        key: `payroll_timepunch_validation`,
                        state: `eaw/app/payroll/timepunch_validation`,
                        translationKey: `TIMEPUNCH_VALIDATION`,
                        translationNs: Namespace.Reports,
                    },
                    {
                        key: `ccnt_monthly_balance_sheet`,
                        state: `eaw/app/payroll/monthly_balance_sheet`,
                        translationKey: `CCNT_MONTHLY_BALANCE_SHEET`,
                        translationNs: Namespace.Reports,
                    },
                    {
                        key: `monthly_paid_time_sheet`,
                        state: `eaw/app/payroll/monthly_paid_time_sheet`,
                        translationKey: `MONTHLY_PAID_TIME_SHEET`,
                        translationNs: Namespace.Reports,
                    },
                    {
                        key: `payroll_manage_timepunches`,
                        state: `eaw/app/payroll/timepunches/all/manage`,
                        translationKey: `ALL_TIMEPUNCHES`,
                        badges: [ `payroll.timepunches.unhandled` ],
                    },
                    {
                        key: `payroll_punch_proposals`,
                        state: `eaw/app/tbs/punch-proposals`,
                        translationKey: `TBS_PUNCH_PROPOSALS`,
                    },
                    {
                        key: `payroll_punch_clock`,
                        state: `eaw/app/payroll/punch_clock`,
                        translationKey: `PUNCH_CLOCK`,
                    },
                    {
                        key: `flexitime`,
                        state: `eaw/app/payroll/flexitime`,
                        translationKey: `FLEXITIME`,
                    },
                    {
                        key: `paid_time`,
                        state: `eaw/app/payroll/paid_time/day`,
                        glob: `eaw/app/payroll/paid_time`,
                        translationKey: `PAID_TIME`,
                    },
                    {
                        key: `payroll_manual`,
                        state: `eaw/app/payroll/manual`,
                        translationKey: `MANUAL_PAYROLL`,
                    },
                    {
                        key: `period_locks`,
                        state: `eaw/app/payroll/period_locks`,
                        translationKey: `PERIOD_LOCKS`,
                    },
                ],
            },
            {
                key: `todo`,
                glob: `eaw/app/todo`,
                icon: `task_alt`,
                translationKey: `TODO`,
                subMenu: [
                    {
                        key: `todo_my`,
                        state: `eaw/app/todo/my`,
                        translationKey: `TODO_MY`,
                        badges: [ `todo.my_items` ],
                    },
                    {
                        key: `todo_all`,
                        state: `eaw/app/todo/all`,
                        translationKey: `TODO_ALL`,
                        badges: [ `todo.all_items` ],
                    },
                    {
                        key: `todo_statuses`,
                        state: `eaw/app/todo/statuses`,
                        translationKey: `TODO_STATUSES`,
                    },
                ],
            },
            {
                key: `checklists`,
                state: `eaw/app/checklists/list`,
                glob: `eaw/app/checklists`,
                icon: `checklist`,
                translationKey: `CHECKLISTS`,
                badges: [ `checklists.open` ],
            },
            {
                key: `calendar`,
                state: `eaw/app/calendar/calendar`,
                icon: `event`,
                translationKey: `CALENDAR`,
            },
            {
                key: `calendar2`,
                state: `eaw/app/calendar2`,
                icon: `event`,
                translationKey: `CALENDAR2`,
            },
            {
                key: `links`,
                state: `eaw/app/links`,
                translationKey: `LINKS`,
                icon: `link`,
            },
            {
                key: `reports`,
                state: `eaw/app/reports`,
                icon: `insert_chart`,
                translationKey: `REPORTS`,
            },
            {
                key: `forms`,
                glob: `eaw/app/forms`,
                icon: `note_alt`,
                translationKey: `FORMS`,
                subMenu: [
                    {
                        key: `my_submissions`,
                        state: `eaw/app/forms/my`,
                        translationKey: `MY_SUBMISSIONS`,
                    },
                    {
                        key: `form_list`,
                        state: `eaw/app/forms/list`,
                        translationKey: `FORM_LIST`,
                    },
                ],
            },
            {
                key: `inspection_report`,
                state: `eaw/app/inspection_report`,
                icon: `badge`,
                translationKey: `INSPECTION_REPORT`,
            },
        ];

        entries.forEach((entry) => entry.subMenu?.forEach((subEntry) => this.formatEntry(subEntry)) ?? this.formatEntry(entry));

        return entries;
    }

    private formatEntry(entry: MainMenuEntryData) {
        const state = entry.state;
        if (!state) {
            return;
        }

        const data = this.getStateData(state);
        const checks = this.getAllStatePermissionChecks(data);

        entry.stateData = {
            allowExternal: this.getAllStateAllowsExternal(data),
            requiresEmployee: this.getAllStateRequiresEmployee(data),
            products: this.getAllStateProducts(data),
            permissions: this.getAllStatePermissions(data),
            somePermissions: this.getAllStateSomePermissions(data),
            settings: this.getAllSettingChecks(data),
            permissionCheck: checks.length ? (permissionCheckService, injector) => {
                return forkJoin(checks.map((permissionCheck) => permissionCheck(permissionCheckService, injector))).pipe(
                    map((results) => results.every((result) => result)),
                );
            } : () => of(true),
        };
    }

    private getAllStateAllowsExternal(data: StateProviderDataData[]) {
        return data.every((d) => d.allowExternal === true || d.allowExternal === undefined);
    }

    private getAllStateRequiresEmployee(data: StateProviderDataData[]) {
        return data.map((d) => d.requiresEmployee || false).some(Boolean);
    }

    private getAllStateProducts(data: StateProviderDataData[]) {
        return Array.from(data.reduce((products, data) => {
            data.products?.forEach((product) => products.add(product));
            return products;
        }, new Set<Products>()));
    }

    private getAllStatePermissions(data: StateProviderDataData[]) {
        return Array.from(data.reduce((permissions, data) => {
            data.permissions?.forEach((p) => permissions.add(this.replacePlaceholders(p)));
            return permissions;
        }, new Set<string>()));
    }

    private getAllStateSomePermissions(data: StateProviderDataData[]) {
        return Array.from(data.reduce((permissions, data) => {
            data.somePermissions?.forEach((p) => permissions.add(this.replacePlaceholders(p)));
            return permissions;
        }, new Set<string>()));
    }

    private getAllStatePermissionChecks(data: StateProviderDataData[]) {
        return data.map((d) => d.permissionCheck).filter((check) => !!check);
    }

    private getAllSettingChecks(data: StateProviderDataData[]) {
        return data.reduce<Record<string, StateProviderDataDataSettingCheck>>((settings, data) => {
            Object.entries(data.settings || {}).forEach(([ key, value ]) => {
                settings[key] = value;
            });

            return settings;
        }, {});
    }

    private replacePlaceholders(string: string) {
        return string
            .replace('{customer}', String(this.currentService.getCustomer()?.id))
            .replace('{customer.setting_group_id}', String(this.currentService.getCustomer()?.settingGroupId))
            .replace('{employee}', String(this.currentService.getEmployee()?.id))
            .replace('{employee.customer_id}', String(this.currentService.getEmployee()?.customerId))
            .replace('{user}', String(this.currentService.getMe().user?.id));
    }

    getAvailableSingleSignOns(): MainMenuEntryData[] {
        return [
            {
                key: `sso_e_learning`,
                state: `eaw/app/sso`,
                translationKey: `E_LEARNING`,
                icon: `chair-school`,
                params: [ { key: `id`, value: `1` } ],
                ssoService: `snapper`,
            },
            {
                key: `sso_rtt`,
                state: `eaw/app/sso`,
                translationKey: `RTT`,
                icon: `school`,
                params: [ { key: `id`, value: `2` } ],
                ssoService: `rtt`,
            },
            {
                key: `sso_learning_bank`,
                state: `eaw/app/sso`,
                translationKey: `LEARNING_BANK`,
                icon: `school`,
                params: [ { key: `id`, value: `3` } ],
                ssoService: `McD LearningBank`,
            },
        ];
    }
}
