<eaw-page-header [fabButton]="fabButton" [buttons]="buttons">
    <span title>{{ (userGroup | async)?.name }}</span>
</eaw-page-header>

<eaw-data-table
        #dataTable
        initialSortBy="id"
        [columns]="columns"
        [request]="request"
        [goTo]="goTo"
        (paginationChange)="updateTable($event)"
></eaw-data-table>
