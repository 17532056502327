// @ts-nocheck
import { module } from 'angular';
import {smallDevice} from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
module('eaw.scheduling').service('ShiftsDraggable', [ 'chooseBusinessDate', 'ToastService', 'intervalService', 'scheduleMode', function ShiftsDraggable(chooseBusinessDate, ToastService, intervalService, scheduleMode) {
    const service = this;
    service.start = () => {
        document.getElementById('shifts-container').classList.add('manipulating-shift-line', 'dragging');
        service.xStep = getComputedStyle(document.body).getPropertyValue('--schedule-shift-table-cell-width').replace('px', '');
        service.element.classList.add('manipulated');
        intervalService.addTimeTellers();
        service.closestMarkerStart = intervalService.calculateClosestMarkerOffsetFromPixels(service.element.offsetLeft);
    };
    service.stop = () => {
        document.removeEventListener('mousemove', service.drag);
        document.getElementById('shifts-container').classList.remove('manipulating-shift-line', 'dragging');
        service.element.classList.remove('manipulated');
        // Remove
        intervalService.removeTimeTellers();
        const closestMarkerEnd = intervalService.calculateClosestMarkerOffsetFromPixels(service.element.offsetLeft);
        if (closestMarkerEnd === service.closestMarkerStart) {
            return service.resetPosition();
        }
        const shift = service.schedule.getShift(service.element.dataset.shiftId);
        const copy = { ...shift };
        copy.offset = intervalService.calculateClosestMarkerOffsetFromPixels(service.element.offsetLeft) + service.schedule.render.offset;
        copy.from = service.schedule.getFrom().add(copy.offset, 's');
        copy.to = copy.from.clone().add(copy.length, 's');
        // Keep it inside
        if (copy.to.isAfter(service.schedule.to)) {
            copy.to = service.schedule.getTo();
            copy.length = service.schedule.to.diff(copy.from, 's');
        }
        if (service.hasOverlappingShift(copy)) {
            return service.resetPosition();
        }
        // Try to update
        chooseBusinessDate(service.schedule, copy).then(() => {
            shift.offset = copy.offset;
            shift.length = copy.length;
            shift.business_date = copy.business_date.clone();
            service.callback(shift).catch(() => {
                shift.reset([ 'offset', 'length' ]); // reset changes
                service.resetPosition();
            });
        }).catch(() => {
            service.resetPosition();
        });
    };
    service.resetPosition = () => {
        service.element.style.left = `${service.orgLeft}px`;
        service.element.style.width = service.orgWidth ? `${service.orgWidth}` : service.element.style.width;
    };
    service.hasOverlappingShift = (shift) => {
        if (!shift.employee_id) {
            return false;
        }
        const hasOverlapping = shift.schedule.getOverlappingShifts(shift).some((s) => s);
        if (hasOverlapping) {
            ToastService.tToast('scheduling:OVERLAPPING_SHIFT');
        }
        return hasOverlapping;
    };
    service.drag = (e) => {
        const diff = Math.floor((e.screenX - service.orgScreenX) / service.xStep) * service.xStep;
        const left = Math.max(0, service.orgLeft + diff); // Dont go below 0
        service.element.style.left = `${left}px`;
        intervalService.calculateTimeTellersTime(service.element);
    };
    service.enable = (schedule, callback) => {
        if (smallDevice()) {
            return;
        }
        if (schedule.original) {
            return;
        }
        if (scheduleMode.getMode() !== 'select') {
            return;
        }
        service.callback = callback;
        service.schedule = schedule;
        Array.from(document.getElementsByClassName('shift-line')).forEach((el) => {
            el.addEventListener('mousedown', (e) => {
                e.stopPropagation();
                if (e.button !== 0) {
                    return;
                }
                service.element = el;
                service.orgLeft = el.offsetLeft;
                service.orgScreenX = e.screenX;
                service.start();
                document.addEventListener('mousemove', service.drag);
                document.addEventListener('mouseup', service.stop, { once: true });
            });
        });
    };
    return service;
} ]);
