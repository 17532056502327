import { ApiResponse } from '../../shared/interfaces/api-response';

export interface ScheduleStatisticsColumnDataResponse extends ApiResponse {
    sunday_work?: Record<number, number[]>,
    average_days_worked?: Record<number, number[]>,
}

export class ScheduleStatisticsColumnData {
    sundayWork: Record<number, number[]>;
    averageDaysWorked: Record<number, number[]>;

    constructor(columnData: ScheduleStatisticsColumnDataResponse) {
        this.sundayWork = columnData.sunday_work || {};
        this.averageDaysWorked = columnData.average_days_worked || {};
    }
}
