import { ChangeDetectionStrategy, Component, HostBinding, inject, OnInit, signal } from '@angular/core';
import { DataTablePortalComponent } from '../../types/data-table-portal-component';
import { DataTableIconColumn } from '../../types/data-table-icon-column';
import { MaterialColorService } from '../../../shared/services/material-color.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'eaw-icon-portal',
    templateUrl: './icon-portal.component.html',
    styleUrl: './icon-portal.component.scss',
    standalone: true,
    imports: [ MatIconModule ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPortalComponent<Item> extends DataTablePortalComponent<Item, unknown, DataTableIconColumn<Item>> implements OnInit {
    private readonly materialColorService = inject(MaterialColorService);

    @HostBinding('class.has-text') get hasText() {
        return !!this.cell.column.text;
    };

    icon = signal('');
    color = signal('#000');
    text = signal<string|undefined>('');

    constructor() {
        super();
    }

    async ngOnInit() {
        this.icon.set(await this.cell.column.icon(this.cell));
        this.text.set(await this.cell.column.text?.(this.cell));

        this.materialColorService.colorToTiny(typeof this.cell.column.color === 'function' ? await this.cell.column.color(this.cell) : this.cell.column.color).then((color) => {
            this.color.set(color.toHexString());
        });
    }
}
