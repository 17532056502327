// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="ctrl.title | i18next"></eaw-dialog-header-old>

    <md-dialog-content>
        <form autocomplete="off" class="md-dialog-content" layout="column" id="checklistForm" name="checklistForm" ng-submit="ctrl.submit()">
            <md-input-container>
                <label for="title" ng-i18next="TITLE"></label>
                <input type="text"
                       id="title"
                       required
                       ng-model="ctrl.checklist.title">
            </md-input-container>

            <md-switch ng-show="ctrl.checklist.is_template" ng-model="ctrl.checklist.useTemplate" ng-change="ctrl.useTemplateToggled()" aria-labelledby="tlabel">
                <span id="tlabel" ng-i18next="checklists:BASE_ON_TEMPLATE"></span>
            </md-switch>

            <md-autocomplete ng-show="ctrl.checklist.useTemplate || !ctrl.checklist.is_template"
                             md-selected-item="ctrl.selectedTemplate"
                             md-selected-item-change="ctrl.checklist.template_id = ctrl.selectedTemplate.id"
                             flex-xs="100"
                             flex-sm="50"
                             flex-gt-sm="25"
                             md-search-text="templateFilter"
                             md-min-length="0"
                             md-delay="300"
                             md-floating-label="{{'checklists:CHECKLIST_TEMPLATE_plural' | i18next}}"
                             md-clear-button="true"
                             md-items="item in ctrl.getTemplates(templateFilter)"
                             md-item-text="item.title">
                <md-item-template>
                    <span md-highlight-text="templateFilter" md-highlight-flags="gi">{{item.title}}</span>
                </md-item-template>
                <md-not-found>
                    <span>{{'NO_MATCH_FOUND' | i18next}}</span>
                </md-not-found>
            </md-autocomplete>
        </form>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-i18next="SUBMIT" form="checklistForm" type="submit" ng-disabled="customDialogForm.$invalid"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.checklists').factory('checklistDialog', [ '$mdDialog', 'ChecklistFactory', 'ToastService', function($mdDialog, ChecklistFactory, ToastService) {
    const locals = {
        getTemplates(filter) {
            return ChecklistFactory.getAll({
                customer: CurrentOld.customer,
                template: true,
                filter,
                per_page: 20,
            }).$promise.then((resp) => resp.data);
        },
    };
    function open() {
        return $mdDialog.show({
            controller: DialogController,
            controllerAs: 'ctrl',
            template: template1,
            bindToController: true,
            locals,
        });
    }
    function DialogController() {
        const ctrl = this;
        ctrl.useTemplateToggled = () => {
            ctrl.selectedTemplate = undefined;
            ctrl.checklist.template_id = undefined;
        };
        ctrl.submit = () => {
            $mdDialog.hide(ctrl.checklist);
        };
    }
    function save(checklist) {
        return ChecklistFactory.create(CurrentOld.customer, checklist).$promise;
    }
    return {
        template() {
            locals.checklist = { is_template: true };
            locals.title = 'checklists:CREATE_CHECKLIST_TEMPLATE';
            return open().then(save).then(() => {
                ToastService.toast(t('checklists:TEMPLATE_CREATED'));
            });
        },
        checklist() {
            locals.checklist = { is_template: false };
            locals.title = 'checklists:CREATE_CHECKLIST_QUESTION';
            return open().then(save).then(() => {
                ToastService.toast(t('checklists:CHECKLIST_STARTED'));
            });
        },
    };
} ]);
