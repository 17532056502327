<mat-dialog-content *ngIf="!tos" class="tw-flex tw-justify-center tw-items-center tw-my-20">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content *ngIf="tos">
    <div [innerHTML]="tos.text"></div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close="" *ngIf="!data.required">{{'CLOSE' | translate | async}}</button>
    <button mat-button mat-dialog-close="" *ngIf="data.required" (click)="decline()" [disabled]="accepting">{{'DECLINE' | translate | async}}</button>
    <button mat-raised-button color="primary" *ngIf="data.required" (click)="accept()" [disabled]="accepting">{{'ACCEPT' | translate | async}}</button>
</mat-dialog-actions>
