import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ObserverService } from '../../http/observer.service';
import { forkJoin } from 'rxjs';
import { SettingGroup } from '../../../shared/models/setting-group';
import { DateTime } from 'luxon';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { MatDialog } from '@angular/material/dialog';
import { DatePickerDialogComponent, DatePickerDialogData, DatePickerDialogReturn } from '../../../shared/dialogs/date-picker-dialog/date-picker-dialog.component';
import { Observer, SettingGroupObserver } from '../../models/observer';
import { CurrentService } from '../../../shared/services/current.service';
import { groupBy } from 'lodash-es';
import { KeyValue, NgIf, NgSwitch, NgSwitchCase, NgFor, NgClass, AsyncPipe, LowerCasePipe, KeyValuePipe } from '@angular/common';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SubheaderComponent } from '../../../shared/components/subheader/subheader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

type ObserverController = (Observer | SettingGroupObserver) & { loading?: boolean };

@Component({
    selector: 'eaw-observers',
    templateUrl: './observers.component.html',
    styleUrl: './observers.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatCheckboxModule,
        CheckboxHelperDirective,
        MatButtonModule,
        NgSwitch,
        NgSwitchCase,
        MatIconModule,
        MatProgressSpinnerModule,
        NgFor,
        SubheaderComponent,
        MatSlideToggleModule,
        MatTooltipModule,
        NgClass,
        AsyncPipe,
        LowerCasePipe,
        KeyValuePipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class ObserversComponent implements OnInit {
    @Input() settingGroup!: SettingGroup;

    showFilter = true;

    filter = new FormControl('', { nonNullable: true });
    showActive = new FormControl<'active' | 'inactive' | null>(null);
    groupByProduct = new FormControl(true, { nonNullable: true });
    allObservers: ObserverController[] = [];
    activeObservers: number = 0;
    filteredObservers: ObserverController[] = [];
    groupedObservers: Record<string, ObserverController[]> = {};

    constructor(
        @Inject(ObserverService) private observerService: ObserverService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(CurrentService) private current: CurrentService,
    ) {
        this.filter.valueChanges.subscribe(this.updateFilter.bind(this));
        this.showActive.valueChanges.subscribe(() => this.filter.updateValueAndValidity());
        this.groupByProduct.valueChanges.subscribe(() => this.filter.updateValueAndValidity());
    }

    ngOnInit() {
        forkJoin([
            this.observerService.getAll({
                per_page: 999,
                group_id: this.settingGroup.id,
            }),
            this.observerService.getAllForSettingsGroup(this.settingGroup.id, { per_page: 999 }),
        ])
            .subscribe(([ allObs, groupObs ]) => {
                this.activeObservers = groupObs.total;
                this.allObservers = [ ...allObs.data, ...groupObs.data ];
                sort(this.allObservers, this.current.languageTag, [ (o) => o.name ]);
                this.filter.updateValueAndValidity();
            });
    }

    trackByKey(_: number, item: KeyValue<string, ObserverController[]>) {
        return item.key;
    }

    trackByName(_: number, item: ObserverController) {
        return item.name;
    }

    private isActive(ob: ObserverController) {
        switch (this.showActive.value) {
            case 'active':
                return 'from' in ob && !!ob.from;
            case 'inactive':
                return 'from' in ob && !ob.from;
            default:
                return true;
        }
    }

    updateFilter(search: string) {
        const filter = search.toLowerCase();

        this.filteredObservers = this.allObservers.filter((ob) => {
            const hasFilter = [ ob.name, ob.description, ob.class, ob.product ].some((f) => f.toLowerCase().includes(filter));
            return this.isActive(ob) && hasFilter;
        });

        this.groupedObservers = this.groupByProduct.value ? groupBy(this.filteredObservers, 'product') : groupBy(this.filteredObservers, 'name');
    }

    getObserverFrom(observer: ObserverController) {
        if (!('from' in observer)) {
            return undefined;
        }
        return observer.from || undefined;
    }

    getObserverTo(observer: ObserverController) {
        if (!('to' in observer)) {
            return undefined;
        }
        return observer.to || undefined;
    }

    toggleObserver(observer: ObserverController, enable: boolean) {
        observer.loading = true;
        if (enable) {
            this.observerService.create(this.settingGroup.id, {
                from: DateTime.now(),
                class: observer.class,
            }).subscribe((item) => {
                Object.assign(observer, item);
                observer.loading = false;
                this.activeObservers++;
                this.filter.updateValueAndValidity();
            });
        } else if ('id' in observer && observer.id) {
            this.observerService.delete(this.settingGroup.id, observer.id).subscribe(() => {
                Object.assign(observer, {
                    from: undefined,
                    to: undefined,
                });
                observer.loading = false;
                this.activeObservers--;
                this.filter.updateValueAndValidity();
            });
        }
    }

    setToTime(observer: ObserverController, existingTo: DateTime = DateTime.now().endOf('day')) {
        if (!('id' in observer)) {
            return;
        }

        this.matDialog.open<DatePickerDialogComponent, DatePickerDialogData, DatePickerDialogReturn>(DatePickerDialogComponent, {
            data: {
                date: existingTo,
                minDate: 'from' in observer ? observer.from : undefined,
                title: this.translate.t('TO'),
                target: 'date',
                includeTime: true,
            },
        }).beforeClosed().subscribe((to) => {
            if (to?.ok) {
                observer.loading = true;
                this.observerService.update(this.settingGroup.id, observer.id, { to: to.value }).subscribe((item) => {
                    Object.assign(observer, item);
                    observer.loading = false;
                });
            }
        });
    }

    clearToTime(observer: ObserverController) {
        if (!('id' in observer)) {
            return;
        }

        observer.loading = true;
        this.observerService.update(this.settingGroup.id, observer.id, { to: null }).subscribe((item) => {
            Object.assign(observer, item);
            observer.loading = false;
        });
    }
}
