<eaw-dialog-header>
    <span title>
        <div class="tw-flex tw-gap-8">
            <img src="assets/img/ms-logo.svg" alt="Microsoft">
            <span>{{ 'SIGN_IN_WITH_MS' | translate:'login' | async }}</span>
        </div>
    </span>
</eaw-dialog-header>

<mat-dialog-content>
    <form id="workspaceForm" method="dialog" class="tw-flex tw-flex-col tw-gap-16">
        <mat-form-field>
            <mat-label>{{ 'WORKSPACE' | translate:'login' | async }}</mat-label>
            <input matInput [formControl]="workspaceControl">
            <mat-hint>{{'WORKSPACE_HINT' | translate:'login' | async}}</mat-hint>
            <mat-error><eaw-field-error [control]="workspaceControl"/></mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>

    <eaw-action-button formId="workspaceForm" type="submit" [loading]="submitting()" (click)="submit()" [disabled]="workspaceControl.invalid">
        {{ 'LOGIN' | translate:'login' | async }}
    </eaw-action-button>
</mat-dialog-actions>
