// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';

import { DateTimeConverter } from '../../../../utils/date-time-converter';

module('eaw.time').component('eawDateInterval', {
    template: `<md-input-container flex="$di.inputFlex">
    <eaw-date-picker label="{{::$di.fromLabel}}"
                     name="{{$di.name}}"
                     ng-model="$di.from"
                     no-clear="$di.fromRequired"
                     picker-role="from"
                     ng-disabled="$di.fromDisabled"
                     ng-required="$di.fromRequired"
                     min-date="$di.fromDisabled ? undefined : $di.minDate"
                     max-date="$di.fromDisabled ? undefined : $di.maxDate"
                     business-date="$di.businessDate"
                     ng-change="$di.updateFrom()">
    </eaw-date-picker>
    <small class="hint" ng-if="$di.fromHint" ng-bind="$di.fromHint"></small>
</md-input-container>
<md-input-container flex="$di.inputFlex">
    <eaw-date-picker label="{{::$di.toLabel}}"
                     name="{{$di.name}}"
                     ng-model="$di.to"
                     no-clear="$di.toRequired"
                     picker-role="to"
                     ng-disabled="$di.toDisabled"
                     ng-required="$di.toRequired"
                     min-date="$di.toDisabled ? undefined : $di.minDate"
                     max-date="$di.toDisabled ? undefined : $di.maxDate"
                     business-date="$di.businessDate"
                     ng-change="$di.updateTo()">
    </eaw-date-picker>
    <small class="hint" ng-if="$di.toHint" ng-bind="$di.toHint"></small>
</md-input-container>
`,
    controllerAs: '$di',
    require: {
        ngModel: 'ngModel',
    },
    bindings: {
        fromLabel: '@?',
        toLabel: '@?',
        fromRequired: '<?',
        toRequired: '<?',
        fromDisabled: '<?',
        toDisabled: '<?',
        minDate: '<?',
        maxDate: '<?',
        inputFlex: '<?',
        businessDate: '<?',
        fromHint: '<?',
        toHint: '<?',
    },
    controller: [ '$attrs', function($attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.fromLabel = ctrl.fromLabel || 'FROM';
            ctrl.toLabel = ctrl.toLabel || 'TO';
            ctrl.inputFlex = ctrl.inputFlex || 50;

            $attrs.$observe('required', (value) => {
                ctrl.fromRequired = value;
                ctrl.toRequired = value;
            });

            $attrs.$observe('disabled', (value) => {
                ctrl.fromDisabled = value;
                ctrl.toDisabled = value;
            });

            $attrs.$observe('name', (value) => {
                ctrl.name = value;
            });

            ctrl.ngModel.$formatters.unshift(ctrl.formatModel);
            ctrl.ngModel.$parsers.unshift(ctrl.parseModel);

            // Save that component has been initialized
            ctrl.hasInit = true;
        };

        ctrl.$onChanges = (changes) => {
            ctrl.businessDate = changes.businessDate?.currentValue ?? ctrl.businessDate;
            ctrl.maxDate = changes.maxDate?.currentValue ?? ctrl.maxDate;

            if (ctrl.hasInit && changes.businessDate) {
                ctrl.formatModel({
                    from: ctrl.from,
                    to: ctrl.to,
                });
            }
        };

        ctrl.parseModel = (interval) => {
            // Convert to string
            if (ctrl.businessDate) {
                interval.from = DateTimeConverter.convertMomentToBusinessDate(interval.from);
                interval.to = DateTimeConverter.convertMomentToBusinessDate(interval.to);
            }

            return interval;
        };

        ctrl.formatModel = (val) => {
            ctrl.from = val?.from?.clone?.();
            ctrl.to = val?.to?.clone?.();

            ctrl.updateFrom(false);
            ctrl.updateTo(true);

            return val;
        };

        ctrl.updateFrom = (update = true) => {
            if (ctrl.businessDate) {
                if (moment.isMoment(ctrl.from)) {
                    if (moment.isMoment(ctrl.to) && ctrl.from.isSameOrAfter(ctrl.to, 'd')) {
                        ctrl.to = ctrl.from.clone();
                    }
                }
            } else if (moment.isMoment(ctrl.from)) {
                ctrl.from.startOf('d');

                if (moment.isMoment(ctrl.to) && ctrl.from.isAfter(ctrl.to)) {
                    ctrl.to = ctrl.from.clone().endOf('d');
                }
            }

            if (!update) {
                return;
            }

            ctrl.update();
        };

        ctrl.updateTo = (update = true) => {
            if (ctrl.businessDate) {
                if (moment.isMoment(ctrl.to)) {
                    if (moment.isMoment(ctrl.from) && ctrl.to.isSameOrBefore(ctrl.from, 'd')) {
                        ctrl.from = ctrl.to.clone();
                    }
                }
            } else if (moment.isMoment(ctrl.to)) {
                ctrl.to.endOf('d');

                if (moment.isMoment(ctrl.from) && ctrl.to.isBefore(ctrl.from)) {
                    ctrl.from = ctrl.to.clone().startOf('d');
                }
            }

            if (!update) {
                return;
            }

            ctrl.update();
        };

        ctrl.update = () => {
            ctrl.ngModel.setViewValue({
                from: ctrl.from,
                to: ctrl.to,
            });
        };
    } ],
});
