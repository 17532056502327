// @ts-nocheck
import { module } from 'angular';

const template1 = `<ng-transclude ng-if="$lazy.intersecting"></ng-transclude>
`;

module('eaw.components').component('eawLazyElement', {
    template: template1,
    transclude: true,
    controllerAs: '$lazy',
    bindings: {
        rootId: '<?rootElementId',
    },
    controller: [ '$element', '$timeout', '$rootScope', function($element, $timeout, $rootScope) {
        const ctrl = this;

        ctrl.$onInit = () => {
            const rootId = ctrl.rootId || 'main-content';

            $timeout(() => {
                ctrl.intersectObserver = new IntersectionObserver(ctrl.onIntersect, {
                    root: document.getElementById(rootId),
                    rootMargin: '0px',
                    threshold: 0,
                });

                ctrl.intersectObserver.observe($element[0]);
            }, 100);
        };

        ctrl.$onDestroy = () => {
            ctrl.intersectObserver?.disconnect();
        };

        ctrl.onIntersect = (intersect) => {
            if (intersect?.[0]?.isIntersecting) {
                ctrl.intersectObserver.disconnect();

                $rootScope.$evalAsync(() => {
                    ctrl.intersecting = true;
                });
            }
        };
    } ],
});
