import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { classifyArrayPaginatedResponse, classifyItem } from '../../../shared/utils/rxjs/classify';
import { EmployeeRotation, EmployeeRotationResponse } from '../models/employee-rotation';
import { PaginationOptions } from '../../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class EmployeeRotationService {

    constructor(@Inject(HttpClient) private http: HttpClient) {}

    create(customerId: number, employeeId: number, rotationId: number, from: DateTime, to?: DateTime | null): Observable<EmployeeRotation> {
        return this.http.post<EmployeeRotationResponse>(`/customers/${customerId}/employees/${employeeId}/rotations`, {
            rotation_id: rotationId,
            from,
            to,
        }).pipe(classifyItem(EmployeeRotation));
    }

    getAll(customerId: number, employeeId: number, options: { pagination: PaginationOptions } = { pagination: { order_by: 'from' } }): Observable<ArrayPaginatedResponse<EmployeeRotation>> {
        return this.http.get<ArrayPaginatedResponse<EmployeeRotationResponse>>(`/customers/${customerId}/employees/${employeeId}/rotations`, {
            params: {
                ...options.pagination,
            },
        }).pipe(classifyArrayPaginatedResponse(EmployeeRotation));
    }

    update(customerId: number, employeeId: number, id: number, attributes: { from?: DateTime, to?: DateTime | null}): Observable<EmployeeRotation> {
        return this.http.put<EmployeeRotationResponse>(`/customers/${customerId}/employees/${employeeId}/rotations/${id}`, {
            from: attributes.from,
            to: attributes.to,
        }).pipe(classifyItem(EmployeeRotation));
    }

    delete(customerId: number, employeeId: number, rotationId: number): Observable<undefined> {
        return this.http.delete<undefined>(`/customers/${customerId}/employees/${employeeId}/rotations/${rotationId}`);
    }
}
