// @ts-nocheck
import { module } from 'angular';

const template1 = `<span ng-if="$lock.activeLock" ng-i18next="widgets:LOCKED_UNTIL"></span>
<span ng-if="!$lock.activeLock" ng-i18next="widgets:NOT_LOCKED"></span>
<h2 ng-bind="$lock.activeLock | moment:'L'"></h2>
`;

module('eaw.dashboard').component('periodLockWidget', {
    template: template1,
    controllerAs: '$lock',
    require: {
        mini: '^eawMiniWidget',
    },
    controller: [ 'PeriodLocksFactory', function(PeriodLocksFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.lockRes = PeriodLocksFactory.getLocks(ctrl.mini.widget.customer, { per_page: 1 });
            ctrl.lockRes.$promise.then((resp) => {
                ctrl.activeLock = resp.data[0]?.to;
            }).finally(() => {
                ctrl.mini.loading(false);
            });
        };

        ctrl.$onDestroy = () => {
            ctrl.lockRes?.$cancelRequest?.();
        };
    } ],
});
