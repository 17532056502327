import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { Widget } from '../../classes/widget';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';

@Component({
    selector: 'eaw-no-info-widget',
    templateUrl: './no-info-widget.component.html',
    styleUrl: './no-info-widget.component.scss' ,
    standalone: true,
    imports: [
        MatIconModule,
        CommonModule,
        MatIconSizeDirective,
        TranslatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoInfoWidgetComponent extends WidgetComponent {
    widget = input.required<Widget>();

    constructor() {
        super();
        this.setLoading(false);
    }
}
