// @ts-nocheck
import { module, noop } from 'angular';

module('eaw.admin').component('adminSignables', {
    template: `<script type="text/ng-template" id="signableFilterTemplate">
    <div class="signable-filter" ng-repeat="(key, value) in ::item.filter track by key">
        <span style="color: rgb(136,19,145);" ng-bind="key"></span>
        <span>: </span>

        <!-- STRING -->
        <span class="value-container" ng-if="scopeVars.getType(value) == 'string'">
        <span>"</span>
        <span class="string" ng-bind="value"></span>
        <span>"</span>
    </span>

        <!-- NUMBER -->
        <span class="value" ng-if="scopeVars.getType(value) == 'number'">
        <span class="number" ng-bind="value"></span>
    </span>
    </div>
</script>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="admin:SIGNABLE_plural"></span>
        </md-card-header-text>

        <card-btn-float on-click="$signables.add()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                cells="$signables.table.cells"
                columns="$signables.table.columns"
                get-data="$signables.getData(params, pagination)"
                scope-vars="$signables.table.vars"
                reload-table="$signables.reload">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
    },
    controllerAs: '$signables',
    controller: [ 'signableService', 'signableDialog', function(signableService, signableDialog) {
        const ctrl = this;
        let customer;

        ctrl.$onInit = () => {
            customer = { ...ctrl.customer };

            ctrl.table = {
                columns: [
                    { title: 'Model' },
                    { title: 'Provider' },
                    { title: 'Filter' },
                    { title: 'Added' },
                    { title: '' },
                ],
                cells: [
                    { template: 'item.model' },
                    { template: 'item.provider' },
                    { templateUrl: 'signableFilterTemplate' },
                    { template: 'item.created_at | moment:"LLLL"' },
                    {
                        buttons: [
                            {
                                icon: 'edit',
                                click(signable) {
                                    signableDialog.update(customer.id, signable).then(ctrl.reloadTable);
                                },
                            },
                            {
                                icon: 'delete',
                                type: 'warn',
                                click(signable) {
                                    signableDialog.delete(customer.id, signable).then(ctrl.reloadTable);
                                },
                            },
                        ],
                    },
                ],
                vars: {
                    getType(item) {
                        return Number.isNaN(parseFloat(item)) ? 'string' : 'number';
                    },
                },
            };
        };

        ctrl.reloadTable = () => ctrl.reload = true;

        // Get all signables for a list
        ctrl.getData = (params, pagination) => signableService.getAll.query(customer.id, pagination);

        // Add signable to customer (new dialog)
        ctrl.add = () => signableDialog.add(customer.id).then(ctrl.reloadTable, noop);
    } ],
});
