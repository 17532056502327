@if (loading()) {
    <eaw-info-loading size="md"/>
} @else {
    <mat-action-list role="list">
        @for (shift of shifts(); track shift.shift.id) {
            <mat-list-item (click)="navigateToShift({id: shift.shift.id, customer_id: shift.shift.schedule?.customerId})">
                <div class="tw-flex tw-justify-between tw-items-center tw-p-8">
                    <div class="tw-flex tw-flex-col">
                        <h4 class="tw-m-0">
                            @switch (shift?.inDays) {
                                @case (0) {
                                    {{ 'TODAY' | translate | async }}
                                }
                                @case (1) {
                                    {{ 'TOMORROW' | translate | async }}
                                }
                                @default {
                                    {{'X_IN_DAYS' | translate: 'widgets':{ x: shift?.inDays, count: shift?.inDays } | async }}
                                }
                            }
                        </h4>
                        <h5 class="tw-m-0">{{ shift.fromTo }}</h5>
                        <p class="tw-m-0">{{ shift.shift.schedule?.customer?.name }}</p>
                    </div>
                    <div>
                        <mat-icon matListItemIcon>open_in_new</mat-icon>
                    </div>
                </div>
            </mat-list-item>

            <mat-divider></mat-divider>
        } @empty {
            <p class="tw-px-24">{{ 'NO_UPCOMING_SHIFT' | translate: 'widgets' | async }}</p>
        }
    </mat-action-list>
}
