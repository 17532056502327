import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { EmployeeCommentService } from '../../http/employee-comment.service';
import { DateTime } from 'luxon';
import { AutoCompleteData } from '../../../shared/autocompletes/autocomplete';
import { Qualification } from '../../../shared/models/qualification';
import { QualificationService } from '../../../shared/http/qualification.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { QualificationAutocompleteService } from '../../../shared/autocompletes/qualification-autocomplete.service';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { Employee } from '../../../shared/models/employee';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface AddEmployeeQualificationData extends DialogData {
    customerId: number;
    employeeId?: number;
    qualificationId?: number;
    updatedQualification?: Qualification;
}

interface QualificationForm {
    qualification: FormControl<Qualification | null>;
    employee: FormControl<Employee | null>;
    qualificationLevel: FormControl<number>;
    from: FormControl<DateTime | null>;
    to?: FormControl<DateTime | null>;
}

interface Percentage {
    percent: number,
    text: Promise<string>
}

@Component({
    selector: 'eaw-add-employee-qualification',
    templateUrl: './add-employee-qualification-dialog.component.html',
    styleUrl: './add-employee-qualification-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        NgIf,
        AutocompleteComponent,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatButtonModule,
        MatIconModule,
        MatDialogActions,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AddEmployeeQualificationDialogComponent extends DialogComponent<AddEmployeeQualificationData, boolean> {
    form: FormGroup<QualificationForm>;
    qualifications: AutoCompleteData<Qualification>;
    employees: AutoCompleteData<Employee>;
    percent: number = 1;
    percentages: Percentage[];

    readonly minDate = DateTime.now().startOf('day');
    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AddEmployeeQualificationData,
        @Inject(EmployeeCommentService) protected commentService: EmployeeCommentService,
        @Inject(QualificationService) public qualificationService: QualificationService,
        @Inject(QualificationAutocompleteService) public qualificationAutocompleteService: QualificationAutocompleteService,
        @Inject(EmployeeAutocompleteService) public employeeAutocompleteService: EmployeeAutocompleteService,
        @Inject(TranslateService) protected translate: TranslateService,
        @Inject(MatDialogRef) dialogRef: MatDialogRef<AddEmployeeQualificationDialogComponent, boolean>,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        const form = new FormGroup<QualificationForm>({
            qualification: new FormControl(null, this.data.qualificationId ? {} : {
                validators: Validators.required,
            }),
            employee: new FormControl(null, this.data.employeeId ? {} : {
                validators: Validators.required,
            }),
            qualificationLevel: new FormControl(1, {
                nonNullable: true,
                validators: Validators.required,
            }),
            from: new FormControl<DateTime>(this.data.updatedQualification?.from || DateTime.now(), { validators: Validators.required }),
            to: new FormControl<DateTime | null>(this.data.updatedQualification?.to || null),
        });
        this.qualifications = {
            label: this.translate.t('QUALIFICATION_plural', 'company'),
            optionTemplate: 'default',
            display: 'name',
            trackByKey: 'id',
        };
        this.employees = {
            label: this.translate.t('EMPLOYEE_plural', 'company'),
            optionTemplate: 'default',
            display: 'name',
            trackByKey: 'id',
        };
        this.percentages = [
            {
                percent: 1,
                text: this.translate.t('FULLY_QUALIFIED', 'company'),
            },
            {
                percent: 0.75,
                text: this.translate.t('PERCENT_QUALIFIED', 'company', { number: 75 }),
            },
            {
                percent: 0.50,
                text: this.translate.t('PERCENT_QUALIFIED', 'company', { number: 50 }),
            },
            {
                percent: 0.25,
                text: this.translate.t('PERCENT_QUALIFIED', 'company', { number: 25 }),
            },
        ];
        this.form = form;
    }

    save(): void {
        const employeeId = this.form.value.employee ? this.form.value.employee.id : this.data.employeeId;
        const qualificationId = this.form.value.qualification ? this.form.value.qualification?.id : this.data.qualificationId;
        if (employeeId) {
            if (this.data.updatedQualification) {
                if (this.form.value.from) {
                    this.qualificationService.updateQualificationForEmployee(this.data.customerId, employeeId, this.data.updatedQualification.id, {
                        from: this.form.value.from,
                        to: this.form.value.to,
                    }).subscribe({
                        next: () => {
                            this.dialogRef.close(true);
                        },
                    });
                }
            } else {
                if (qualificationId && this.form.value.qualificationLevel && this.form.value.from) {
                    const qualification = {
                        id: qualificationId,
                        rate: this.form.value.qualificationLevel,
                        from: this.form.value.from,
                        to: this.form.value.to,
                    };
                    this.qualificationService.addQualificationToEmployee(this.data.customerId, employeeId, qualification).subscribe({
                        next: () => {
                            this.dialogRef.close(true);
                        },
                    });
                }
            }
        }
    }
}
