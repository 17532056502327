// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.staffing').component('staffingTemplates', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="navigation:STAFFING_TEMPLATES"></span>
        </md-card-header-text>

        <card-btn-float ng-if="$staffingTemplates.canCreateTemplate" on-click="$staffingTemplates.addTemplate()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="$staffingTemplates.table.columns"
                cells="$staffingTemplates.table.cells"
                reload-table="$staffingTemplates.reloadTable"
                go-to="eaw/app/company/settings/staffing/matrix|id"
                get-data="$staffingTemplates.getTemplates(params, pagination)">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$staffingTemplates',
    bindings: {
        customer: '<',
    },
    controller: [ 'staffingTemplateFactory', 'addStaffingTemplateService', '$mdDialog', function(staffingTemplateFactory, addStaffingTemplateService, $mdDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.canCreateTemplate = CurrentOld.can(staffingTemplateFactory.create.permission(ctrl.customer.id));

            ctrl.table = {
                columns: [
                    { title: t('NAME') },
                    { title: t('staffing:INPUT') },
                    { title: t('staffing:OUTPUT') },
                    { title: '' },
                ],
                cells: [
                    { template: 'item.name' },
                    { template: '("staffing:" + item.input.toUpperCase() | i18next)' },
                    { template: '("staffing:" + item.output.toUpperCase() | i18next)' },
                    {
                        buttons: [
                            {
                                icon: 'edit',
                                click: ctrl.editTemplate,
                                invisible: (item) => !CurrentOld.can(staffingTemplateFactory.update.permission(ctrl.customer.id, item.id)),
                            },
                            {
                                icon: 'delete',
                                type: 'warn',
                                click: ctrl.deleteTemplate,
                                invisible: (item) => !CurrentOld.can(staffingTemplateFactory.delete.permission(ctrl.customer.id, item.id)),
                            },
                        ],
                    },
                ],
            };
        };

        ctrl.addTemplate = () => {
            addStaffingTemplateService.open().then(() => {
                ctrl.reloadTable = true;
            });
        };

        ctrl.editTemplate = (template) => {
            $mdDialog.show(
                $mdDialog.prompt()
                    .title(t('EDIT'))
                    .textContent(t('staffing:CHANGE_TEMPLATE_NAME', {
                        template: template.name,
                    }))
                    .placeholder(t('NAME'))
                    .initialValue(template.name)
                    .required(true)
                    .ok(t('UPDATE'))
                    .cancel(t('CANCEL')),
            ).then((newName) => {
                template.disabled = true;
                staffingTemplateFactory.update.query(ctrl.customer.id, template.id, { name: newName }).$promise.then(() => {
                    ctrl.reloadTable = true;
                }).catch(() => template.disabled = false);
            });
        };

        ctrl.getTemplates = (params, pagination) => staffingTemplateFactory.getAll.query(ctrl.customer.id, {
            ...pagination.toWithPaginatorArguments(),
            'with[]': [ 'intervals' ],
        });

        ctrl.deleteTemplate = (template) => {
            $mdDialog.show(
                $mdDialog.confirm()
                    .theme('delete')
                    .title(t('staffing:DELETE_TEMPLATE'))
                    .textContent(t('staffing:DELETE_TEMPLATE_TEXT'), {
                        template: template.name,
                    })
                    .ok(t('DELETE'))
                    .cancel(t('CANCEL')),
            ).then(() => {
                template.disabled = true;
                staffingTemplateFactory.delete.query(ctrl.customer.id, template.id).$promise.then(() => {
                    ctrl.reloadTable = true;
                }).catch(() => template.disabled = false);
            });
        };
    } ],
});
