import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { CalendarEvent, CalendarEventResponse } from '../models/calendar-event';
import { classifyArrayPaginatedResponse } from '../../shared/utils/rxjs/classify';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { Observable } from 'rxjs';

interface GetAllOptions extends PaginationOptions {
    from?: DateTime,
    to?: DateTime,
}

@Injectable({
    providedIn: 'root',
})
export class CalendarEventService {
    private readonly http = inject(HttpClient);

    getAll(customerId: number, options: GetAllOptions): Observable<ArrayPaginatedResponse<CalendarEvent>> {
        return this.http.get<ArrayPaginatedResponse<CalendarEventResponse>>(`customers/${customerId}/calendar_events`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(CalendarEvent));
    }
}
