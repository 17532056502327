<eaw-dialog-header>
    <span title>{{'REMOVE_ACCESS' | translate | async}}</span>
    <span subtitle>{{data.user.name}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <mat-progress-spinner *ngIf="loadingUserInfo" class="tw-mx-auto" mode="indeterminate"></mat-progress-spinner>
    <h3 *ngIf="loadingUserInfo" class="tw-text-center">{{'GETTING_DATA' | translate | async}}</h3>

    <form [formGroup]="formGroup" class="tw-flex tw-flex-col tw-gap-8" *ngIf="!loadingUserInfo">
        <h3 *ngIf="!groups.length">{{'NOTHING_TO_HANDLE' | translate | async}}</h3>

        <label id="user-group-label" *ngIf="groups.length">{{'USER_GROUP_plural' | translate | async}}</label>
        <mat-radio-group *ngIf="groups.length" label="user-group-label" formControlName="removeFromGroups" class="tw-flex tw-flex-col">
            <mat-radio-button value="all">{{'REMOVE_FROM_ALL_USER_GROUPS' | translate: 'company' | async}}</mat-radio-button>
            <mat-radio-button value="none">{{'DO_NOT_REMOVE_FROM_USER_GROUPS' | translate: 'company' | async}}</mat-radio-button>
            <mat-radio-button value="some">{{'REMOVE_FROM_SELECTED_USER_GROUPS' | translate: 'company' | async}}</mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="formGroup.controls.removeFromGroups.value === 'some'">
            <mat-label>{{'USER_GROUP_plural' | translate | async}}</mat-label>
            <mat-select multiple formControlName="groupsToRemove">
                <mat-option *ngFor="let group of groups" [value]="group">{{group.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button matDialogClose="">{{'CANCEL' | translate |async}}</button>
    <button mat-raised-button
            *ngIf="!loadingUserInfo"
            color="warn"
            (click)="submit()"
            [disabled]="formGroup.invalid || handlingUser">{{'REMOVE' | translate | async}}</button>
</mat-dialog-actions>
