import { Inject, Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { CurrentService } from '../services/current.service';
import { Cache } from '../utils/cache';
import { UIRouter } from '@uirouter/core';
import { AppService } from '../services/app.service';

@Injectable()
export class TosInterceptor implements HttpInterceptor {

    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(Injector) private injector: Injector,
        @Inject(AppService) private appService: AppService,
    ) {
    }

    private checkAccepted(data: unknown) {
        if (!(data instanceof Object)) {
            return;
        }

        if (!('validation_error_code' in data)) {
            return;
        }

        const validationErrorCode = data.validation_error_code;
        if (typeof validationErrorCode === 'string' && validationErrorCode === 'TOS_NOT_ACCEPTED') {
            void Cache.clearAll();
            this.current.getMe().acceptedTos = false;

            if (this.appService.angularJsLoaded) {
                this.injector.get(UIRouter).stateService.go('eaw/app/tasks');
            }
        }
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403) {
                    if (error.error instanceof Blob) {
                        error.error.text().then((data) => {
                            let parsedData: unknown;

                            try {
                                parsedData = JSON.parse(data);
                            } catch (_) {
                                return throwError(() => error);
                            }

                            this.checkAccepted(parsedData);
                        });
                    } else {
                        this.checkAccepted(error.error);
                    }
                }

                return throwError(() => error);
            }),
        );
    }
}
