import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { TosService } from '../../../shared/http/tos.service';
import { Tos } from '../../../shared/models/tos';
import { LoginService } from '../../../shared/services/login.service';
import { EmployeeTask } from '../../../shared/models/employee-task';
import { catchError, of } from 'rxjs';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { InfoBoxComponent } from '../../../shared/components/info-card/info-box.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
    selector: 'eaw-tos-task',
    templateUrl: './tos-task.component.html',
    styleUrl: './tos-task.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinnerModule,
        InfoBoxComponent,
        MatButtonModule,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class TosTaskComponent implements OnInit {
    @Input({ required: true }) task!: EmployeeTask;
    @Input({ required: true }) userId!: number;

    @Output() accepted = new EventEmitter<void>();

    tos?: Tos;
    accepting = false;
    hasAccepted = false;

    constructor(
        @Inject(TosService) private tosService: TosService,
        @Inject(LoginService) private loginService: LoginService,
    ) {
    }

    ngOnInit() {
        this.tosService.get().subscribe((tos) => {
            this.tos = tos;
        });
    }

    declineTos() {
        void this.loginService.logout(this.userId, { message: Promise.resolve('Terms of service were not accepted') });
    }

    async acceptTos() {
        this.accepting = true;
        this.tosService.accept()
            .pipe(catchError(() => of(null)))
            .subscribe((val) => {
                if (!val) {
                    this.accepting = false;
                    return;
                }

                this.task.complete();
                this.accepted.emit();
                this.accepting = false;
                this.hasAccepted = true;
            });
    }
}
