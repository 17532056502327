import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CustomFieldDialogData, CustomFieldDialogService } from '../../services/custom-field-dialog.service';
import { Property } from '../../../shared/models/property';
import { EmployeePropertyService } from '../../../shared/http/employee-property.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { catchError, EMPTY } from 'rxjs';
import { CustomFieldsGroup } from '../../../shared/utils/custom-fields-group';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, AsyncPipe } from '@angular/common';
import { CustomFieldInputComponent } from '../../components/custom-field-input/custom-field-input.component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';

export type CustomFieldDialogReturn = {
    property?: Property;
    hasChange: boolean;
}

type CustomFieldDialogForm = {
    customFields: CustomFieldsGroup;
}

@Component({
    selector: 'eaw-custom-field-value-dialog',
    templateUrl: './custom-field-value-dialog.component.html',
    styleUrl: './custom-field-value-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        CustomFieldInputComponent,
        NgIf,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        ActionButtonComponent,
    ],
})
/**
 * Dialog for creating / editing a value for a custom field
 */
export class CustomFieldValueDialogComponent extends DialogComponent {
    customField = this.data.customField;
    processing = false;
    form: FormGroup<CustomFieldDialogForm> = new FormGroup({
        customFields: new CustomFieldsGroup(),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CustomFieldDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<CustomFieldValueDialogComponent, CustomFieldDialogReturn>,
        @Inject(EmployeePropertyService) private employeePropertyService: EmployeePropertyService,
        @Inject(CustomFieldDialogService) private customFieldDialogService: CustomFieldDialogService,
    ) {
        data.size ||= DialogSize.Medium;
        super(dialogRef, data);
    }

    get hasValue() {
        return this.form.controls.customFields.hasModifiedField(this.customField.key);
    }

    submit() {
        const value = this.form.controls.customFields.getValuesWithInterval()[this.data.customField.key];
        if (value?.value == null) {
            return;
        }

        this.form.disable();
        this.processing = true;
        const str = String(value.value);
        (this.data.mode === 'edit' ? this.update(str, value.from, value.to) : this.add(str, value.from, value.to))?.pipe(
            catchError((err) => {
                this.processing = false;
                console.error(err);
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe((property) => {
            this.dialogRef.close({
                property,
                hasChange: true,
            });
        });
    }

    delete() {
        this.customFieldDialogService.delete(this.data.customerId, this.data.employeeId, this.customField).subscribe(() => {
            this.dialogRef.close({ hasChange: true });
        });
    }

    add(value: string, from: string | null | undefined, to: string | null | undefined) {
        return this.employeePropertyService.create(
            this.data.customerId,
            this.data.employeeId,
            this.customField.key,
            value,
            from,
            to,
        );
    }

    update(value: string, from: string | null | undefined, to: string | null | undefined) {
        return this.employeePropertyService.update(
            this.data.customerId,
            this.data.employeeId,
            this.customField.key,
            this.customField.from,
            value,
            from,
            to,
        );
    }
}
