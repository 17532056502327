import { booleanAttribute, ChangeDetectionStrategy, Component, ElementRef, HostBinding, inject, Input, OnInit, viewChild } from '@angular/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { uniqueId } from '../../angularjs/modules/misc/services/easy-funcs.service';
import { startWith, Subject } from 'rxjs';
import { ControlValueAccessor, FormControl, FormGroup, NgControl, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { CurrentService } from '../../services/current.service';
import { EawValidators } from '../../validators/eaw-validators';
import { OnChange } from '../../types/on-change';
import { OnTouched } from '../../types/on-touched';
import { MatInputModule } from '@angular/material/input';
import { TranslateService } from '../../services/translate.service';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';

export type DialPhone = { dial: string, phone: string };

interface Country {
    id: CountryCode;
    name?: string;
    dialCode: string;
}

@Component({
    selector: 'eaw-dial-phone-input',
    standalone: true,
    imports: [
        TranslatePipe,
        AsyncPipe,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatTooltip,
    ],
    templateUrl: './dial-phone-input.component.html',
    styleUrl: './dial-phone-input.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ { provide: MatFormFieldControl, useExisting: DialPhoneInputComponent } ],
})
export class DialPhoneInputComponent implements MatFormFieldControl<DialPhone>, ControlValueAccessor, OnInit {
    current = inject(CurrentService);
    translate = inject(TranslateService);
    ngControl = inject(NgControl, { self: true, optional: true });
    elementRef = inject(ElementRef<Element>);
    formField = inject(MatFormField, { optional: true });
    container = viewChild.required<ElementRef<HTMLElement>>('container');
    dialSelect = viewChild.required<MatSelect>('dial');
    phoneInput = viewChild.required<ElementRef<HTMLInputElement>>('phone');

    @HostBinding() id: string = uniqueId('eaw-dial-phone-input-');

    @Input({ transform: booleanAttribute })
    get required() {
        return this.ngControl?.control?.hasValidator(Validators.required) || this.form.controls.phone.hasValidator(Validators.required);
    }

    set required(required: boolean) {
        const control = this.ngControl?.control || this.form.controls.phone;
        if (required) {
            control.addValidators(Validators.required);
        } else {
            control.removeValidators(Validators.required);
        }
        control.updateValueAndValidity();
        this.stateChanges.next();
    }

    @Input({ transform: booleanAttribute })
    get disabled() {
        return this.form.disabled;
    }

    set disabled(disabled: boolean) {
        this.setDisabledState(disabled);
    }

    @Input() placeholder = '';

    @Input() defaultCountry?: string | CountryCode;

    form = new FormGroup({
        countryCode: new FormControl<Country | null>(null),
        phone: new FormControl(''),
    });

    stateChanges = new Subject<void>();
    onChange?: OnChange<DialPhone | null>;
    onTouched?: OnTouched;

    countries: Country[] = [];
    readonly controlType: string = 'eaw-dial-phone-input';
    readonly shouldLabelFloat = true;

    focused = false;
    touched = false;

    private phoneValidator?: ValidatorFn;

    get value() {
        if (this.form.value.countryCode && this.form.value.phone) {
            return ({
                dial: this.form.value.countryCode.dialCode,
                phone: this.form.value.phone,
            } as DialPhone);
        }
        return null;
    }

    set value(value) {
        this.writeValue(value);
    }

    get empty() {
        return !this.form.getRawValue().countryCode && !this.form.getRawValue().phone;
    }

    get errorState() {
        return this.touched && (this.form.controls.phone.invalid || !!this.ngControl?.control?.invalid);
    }

    get autofilled() {
        return this.form.pristine && !!this.form.controls.countryCode.value;
    }

    get userAriaDescribedBy() {
        return this.elementRef.nativeElement.getAttribute('aria-describedby') ?? '';
    }

    constructor() {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }

        this.countries = getCountries().map((c) => {
            let name: string | undefined = c;

            try {
                name = new Intl.DisplayNames([ this.current.languageTag ], { type: 'region' }).of(c);
            } catch (_) {
                // Ignore
            }

            return {
                id: c,
                name,
                dialCode: getCountryCallingCode(c),
            };
        }).sort((a, b) => {
            if (a.id === this.current.getCustomer().countryCode || b.id === this.current.getCustomer().countryCode) {
                return a.id === this.current.getCustomer().countryCode ? -1 : 1;
            }
            return a.name?.localeCompare(b.name ?? '', this.current.languageTag) ?? 0;
        });

        this.handlePhoneControls();
    }

    ngOnInit() {
        const defaultCode = this.countries.find((c) => c.id === (this.defaultCountry || this.current.getCustomer().countryCode));
        if (!this.form.getRawValue().phone && defaultCode) {
            this.form.controls.countryCode.setValue(defaultCode);
        }
    }

    handlePhoneControls() {
        this.form.controls.phone.addValidators((phone) => phone.value && !this.form.controls.countryCode.value ? { missingDial: true } : null);

        this.form.controls.countryCode.valueChanges.pipe(startWith(null)).subscribe((value) => {
            if (this.disabled) {
                return;
            }
            const phoneControl = this.form.controls.phone;
            if (this.phoneValidator) {
                phoneControl.removeValidators(this.phoneValidator);
            }

            if (value) {
                this.phoneValidator = EawValidators.phoneNumber(value.id);
                phoneControl.addValidators(this.phoneValidator);
            }

            phoneControl.updateValueAndValidity();
            this.stateChanges.next();
        });

        this.form.valueChanges.subscribe(() => {
            this.onChange?.(this.value);
            if (this.form.controls.phone.errors || this.ngControl?.control?.errors) {
                this.ngControl?.control?.setErrors({ ...this.form.controls.phone.errors, ...this.ngControl.control.errors });
            }
            this.stateChanges.next();
        });
    }

    focusIn() {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    focusOut(event: FocusEvent) {
        if (this.focused && event.relatedTarget !== this.dialSelect()._elementRef.nativeElement && event.relatedTarget !== this.phoneInput().nativeElement) {
            this.focused = false;
            if (!this.touched) {
                this.touched = true;
                this.onTouched?.();
                this.form.updateValueAndValidity();
            }
            this.stateChanges.next();
        }
    }

    onContainerClick() {
        if (!this.focused) {
            this.phoneInput().nativeElement.focus();
        }
    }

    setDescribedByIds(ids: string[]) {
        this.container().nativeElement.setAttribute('aria-describedby', ids.join(' '));
    }

    registerOnChange(fn: OnChange<DialPhone | null>) {
        this.onChange = fn;
    }

    registerOnTouched(fn: OnTouched) {
        this.onTouched = fn;
    }

    writeValue(obj: DialPhone | null) {
        this.form.setValue({
            phone: obj?.phone ?? '',
            countryCode: obj?.dial ? this.countries.find((c) => c.dialCode === obj?.dial) ?? null : null,
        });
    }

    setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.form.disable({ emitEvent: false });
        } else {
            this.form.enable({ emitEvent: false });
        }
        this.stateChanges.next();
    }
}
