import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, ElementRef, inject, input, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { ScheduleTabComponent, ScheduleTabInterval } from '../../schedule-tab.component';
import { ScheduleComponent } from '../../../../schedule.component';
import { ShiftService } from '../../../../../../http/shift.service';
import { BusinessDate } from 'src/app/shared/utils/business-date';
import { catchError, EMPTY, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { DateTimePipe } from '../../../../../../../shared/pipes/date-time.pipe';
import { DurationPipe } from '../../../../../../../shared/pipes/duration.pipe';
import { MatDialog } from '@angular/material/dialog';
import { ChooseBusinessDateDialogComponent, ChooseBusinessDateDialogData, ChooseBusinessDateDialogReturn } from '../../dialogs/choose-business-date-dialog/choose-business-date-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TempShift } from '../../../../classes/temp-shift';
import { DateTime } from 'luxon';

interface HalfInterval {
    offset: number;
}

@Component({
    selector: 'eaw-schedule-tab-create-shift-row',
    standalone: true,
    imports: [
        DateTimePipe,
        DurationPipe,
    ],
    templateUrl: './schedule-tab-create-shift-row.component.html',
    styleUrl: './schedule-tab-create-shift-row.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleTabCreateShiftRowComponent implements OnInit, OnDestroy {
    private destroyRef = inject(DestroyRef);
    private elementRef = inject(ElementRef) as ElementRef<HTMLElement>;
    private shiftService = inject(ShiftService);
    private matDialog = inject(MatDialog);

    private newShiftElement = viewChild.required<ElementRef<HTMLDivElement>>('newShift');

    private static draggingSubject = new ReplaySubject<boolean>(1);

    customerId = input.required<number>();
    scheduleId = input.required<number>();
    renderedIntervals = input.required<ScheduleTabInterval[]>();
    pixelsPerSecond = input.required<number>();

    element = this.elementRef.nativeElement;

    isDragging = signal(false);
    preciseOffset = signal<number | undefined>(undefined);
    resizeStartX = signal<number | undefined>(undefined);
    currentResizeX = signal<number | undefined>(undefined);
    halfIntervals = computed(this.computeHalfIntervals.bind(this));
    newShiftOffset = computed(this.computeNewShiftOffset.bind(this));
    dragFrom = computed(this.computeDragFrom.bind(this));
    dragTo = computed(this.computeDragTo.bind(this));
    draggedPx = computed(this.computeDraggedPx.bind(this));
    superfluousSeconds = computed(this.computeSuperfluousSeconds.bind(this));

    constructor() {
        effect(() => this.newShiftEl.style.setProperty('--offset', `${(this.newShiftOffset() || 0) * this.pixelsPerSecond()}px`));
        effect(() => this.newShiftEl.style.setProperty('--width', `${this.draggedPx() || 0}px`));
        effect(() => ScheduleTabCreateShiftRowComponent.draggingSubject.next(this.isDragging()));

        effect(() => {
            if (this.draggedPx() !== undefined) {
                this.newShiftEl.classList.add('dragging');
            } else {
                this.newShiftEl.classList.remove('dragging');
            }
        });
    }

    ngOnInit() {
        this.element.addEventListener('mousedown', (downEvent) => {
            if (downEvent.button !== 0) {
                return;
            }

            this.isDragging.set(true);
            this.preciseOffset.set(downEvent.offsetX / this.pixelsPerSecond());
            this.resizeStartX.set(downEvent.clientX);
            this.currentResizeX.set(downEvent.clientX);

            this.addDragOverlay();
        });
    }

    ngOnDestroy() {
        ScheduleTabCreateShiftRowComponent.draggingSubject.complete();
    }

    private addDragOverlay() {
        const overlay = document.createElement('div');
        overlay.style.position = 'fixed';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.zIndex = Number.MAX_SAFE_INTEGER.toString();
        document.body.appendChild(overlay);

        const eventAborter = new AbortController();
        overlay.addEventListener('mousedown', (downDownEvent) => {
            this.resetDragging(eventAborter);
            overlay.remove();

            if (downDownEvent.button === 2) {
                document.addEventListener('contextmenu', (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }, { once: true });
            }
        }, { once: true, signal: eventAborter.signal });

        overlay.addEventListener('mouseup', () => {
            this.createShift(this.newShiftOffset(), this.draggedPx());
            this.resetDragging(eventAborter);
            overlay.remove();
        }, { signal: eventAborter.signal, once: true });

        overlay.addEventListener('mousemove', (downMoveEvent) => this.currentResizeX.set(downMoveEvent.clientX), { signal: eventAborter.signal });
    }

    get newShiftEl() {
        return this.newShiftElement().nativeElement;
    }

    computeNewShiftOffset() {
        const offset = this.preciseOffset();
        return offset === undefined ? undefined : ScheduleTabComponent.findClosestInterval(offset, { onlyLookBackwards: true, useHalf: true });
    }

    computeDragFrom() {
        const offset = this.newShiftOffset();
        return offset === undefined ? undefined : ScheduleComponent.schedule()?.from.plus({ seconds: offset });
    }

    computeDragTo() {
        const offset = this.newShiftOffset();
        const draggedPx = this.draggedPx();
        const halfInterval = ScheduleComponent.properties.scheduleTab.interval.value() / 2;
        if (offset === undefined || draggedPx === undefined) {
            return;
        }

        const length = Math.round(draggedPx / this.pixelsPerSecond() / halfInterval) * halfInterval;
        return ScheduleComponent.schedule()?.from.plus({ seconds: offset + length });
    }

    computeDraggedPx() {
        const start = this.resizeStartX();
        const now = this.currentResizeX();
        const extra = (this.superfluousSeconds() || 0) * this.pixelsPerSecond();
        return start === undefined || now === undefined ? undefined : Math.max(0, now - start + extra);
    }

    computeSuperfluousSeconds() {
        const preciseOffset = this.preciseOffset();
        const shiftOffset = this.newShiftOffset();
        return preciseOffset === undefined || shiftOffset === undefined ? undefined : preciseOffset - shiftOffset;
    }

    resetDragging(aborter: AbortController) {
        aborter.abort();

        this.isDragging.set(false);
        this.preciseOffset.set(undefined);
        this.resizeStartX.set(undefined);
        this.currentResizeX.set(undefined);
    }

    createShift(offset: number | undefined, draggedPx: number | undefined) {
        if (offset === undefined || draggedPx === undefined) {
            return;
        }

        const halfInterval = ScheduleComponent.properties.scheduleTab.interval.value() / 2;
        const length = Math.round(draggedPx / this.pixelsPerSecond() / halfInterval) * halfInterval;
        const scheduleFrom = ScheduleComponent.schedule()?.from;

        if (!length || !scheduleFrom) {
            return;
        }

        const from = scheduleFrom.plus({ seconds: offset });
        const to = from.plus({ seconds: length });
        const tempShift = new TempShift(offset, length);

        const dialogRef = from.hasSame(to, 'day')
            ? of(from)
            : this.matDialog.open<ChooseBusinessDateDialogComponent, ChooseBusinessDateDialogData, ChooseBusinessDateDialogReturn>(ChooseBusinessDateDialogComponent, { data: { from } }).afterClosed();

        dialogRef.pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap((businessDate) => {
                if (!(businessDate instanceof DateTime)) {
                    return EMPTY;
                }

                ScheduleComponent.setShift(tempShift);
                const createObservable = this.shiftService.create(this.customerId(), this.scheduleId(), {
                    length,
                    offset,
                    business_date: new BusinessDate(businessDate),
                });

                return businessDate ? createObservable.pipe(
                    takeUntilDestroyed(this.destroyRef),
                    catchError(() => {
                        ScheduleComponent.deleteShift(tempShift.id);
                        return EMPTY;
                    }),
                ) : EMPTY;
            }),
            tap((response) => {
                ScheduleComponent.deleteShift(tempShift.id);
                ScheduleComponent.setShift(response);
            }),
        ).subscribe();
    }

    computeHalfIntervals() {
        const halfInterval = ScheduleComponent.properties.scheduleTab.interval.value() / 2;

        return this.renderedIntervals().reduce((acc, interval) => {
            return acc.concat({ offset: interval.offset }, { offset: interval.offset + halfInterval });
        }, [] as HalfInterval[]);
    }

    static dragging(destroyRef: DestroyRef) {
        return this.draggingSubject.asObservable().pipe(
            takeUntilDestroyed(destroyRef),
        );
    }
}
