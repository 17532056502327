<div class="tw-flex tw-justify-between tw-items-center">
    <eaw-page-header>
        <span title>{{label | translate:Namespace.Reports | async}}</span>
        <span subtitle class="tw-flex tw-flex-row tw-items-center tw-gap-8">
            <eaw-date-interval-selector interval="month"
                                        disableCustomSelect
                                        (intervalChange)="updateTable(table.getPagination(), $event)"
                                        [from]="interval.from"
                                        [to]="interval.to"/>
        </span>
    </eaw-page-header>
</div>

<mat-card>
    <eaw-data-table
        #table
        hidePaginator
        [columns]="columns"
        [request]="request"
        initialSortBy="id"
        (paginationChange)="updateTable($event)">
    </eaw-data-table>
</mat-card>
