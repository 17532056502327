// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="numAdv.item.title"></eaw-dialog-header-old>
    <form name="optionsForm">
        <md-dialog-content>
            <div class="md-dialog-content" layout="column">
                <div layout="row">
                    <md-input-container>
                        <label ng-i18next="checklists:MIN"></label>
                        <input ng-model="numAdv.item.options.minimum"
                               type="number"
                               ng-change="numAdv.optionChanged()"
                               autocomplete="off"
                               max="{{numAdv.item.options.maximum}}">
                    </md-input-container>

                    <md-input-container>
                        <label ng-i18next="checklists:MAX"></label>
                        <input ng-model="numAdv.item.options.maximum"
                               type="number"
                               ng-change="numAdv.optionChanged()"
                               autocomplete="off"
                               min="{{numAdv.item.options.minimum}}">
                    </md-input-container>
                </div>

                <div layout="row">
                    <md-input-container>
                        <label ng-i18next="checklists:SOFT_MIN"></label>
                        <input ng-model="numAdv.item.options.soft_minimum"
                               type="number"
                               ng-change="numAdv.optionChanged()"
                               autocomplete="off"
                               min="{{numAdv.softMinMin}}"
                               max="{{numAdv.softMinMax}}">
                    </md-input-container>

                    <md-input-container>
                        <label ng-i18next="checklists:SOFT_MAX"></label>
                        <input ng-model="numAdv.item.options.soft_maximum"
                               type="number"
                               ng-change="numAdv.optionChanged()"
                               autocomplete="off"
                               min="{{numAdv.softMaxMin}}"
                               max="{{numAdv.softMaxMax}}">
                    </md-input-container>
                </div>

                <div ng-if="optionsForm.$valid">
                    <span ng-if="!numAdv.showSoftMin && !numAdv.showSoftMax" ng-i18next="checklists:ANY_VALUE_ACCEPTABLE"></span>
                    <span ng-if="numAdv.showSoftMin && !numAdv.showSoftMax" ng-i18next="[i18next]({value: numAdv.item.options.soft_minimum})checklists:VALUE_LESS_DEVIATION"></span>
                    <span ng-if="!numAdv.showSoftMin && numAdv.showSoftMax" ng-i18next="[i18next]({value: numAdv.item.options.soft_maximum})checklists:VALUE_GREATER_DEVIATION"></span>
                    <span ng-if="!numAdv.softValuesEqual && numAdv.showSoftMin && numAdv.showSoftMax" ng-i18next="[i18next]({min: numAdv.item.options.soft_minimum, max: numAdv.item.options.soft_maximum})checklists:VALUE_OUTSIDE_DEVIATION"></span>
                    <span ng-if="numAdv.softValuesEqual" ng-i18next="[i18next]({value: numAdv.item.options.soft_minimum})checklists:VALUE_ONLY_DEVIATION"></span>
                </div>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button ng-click="numAdv.submit()" ng-disabled="numAdv.sameOptions || optionsForm.$invalid" ng-i18next="SUBMIT"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.checklists').factory('eawNumberAdvanced', [ '$mdDialog', function($mdDialog) {
    return function(item) {
        return $mdDialog.show({
            template: template1,
            controller: 'eawNumberAdvancedCtrl',
            controllerAs: 'numAdv',
            bindToController: true,
            locals: {
                item: { ...item },
            },
        });
    };
} ]);
