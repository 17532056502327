import { ChangeDetectionStrategy, Component, effect, inject, input, signal } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { Widget } from '../../classes/widget';
import { MatCalendar, MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { DashboardStoreService } from '../../services/dashboard-store.service';
import { Shift } from '../../../scheduling/models/shift';
import { MatDialog } from '@angular/material/dialog';
import { ShiftListDialogComponent, ShiftListDialogData } from '../../../scheduling/dialogs/shift-list-dialog/shift-list-dialog.component';

@Component({
    selector: 'eaw-all-shifts-calendar-widget',
    standalone: true,
    imports: [
        MatCalendar,
    ],
    templateUrl: './all-shifts-calendar-widget.component.html',
    styleUrl: './all-shifts-calendar-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllShiftsCalendarWidgetComponent extends WidgetComponent {
    private dashboardStoreService = inject(DashboardStoreService);
    private matDialog = inject(MatDialog);

    widget = input.required<Widget>();

    protected shiftsLoaded = signal(false);
    protected minDate = signal(DateTime.now().startOf('day'));
    protected maxDate = signal(DateTime.now().endOf('day').plus({ months: 2 }));
    protected shifts = signal([] as Shift[]);
    protected dateClassFn: MatCalendarCellClassFunction<DateTime>;

    constructor() {
        super();

        effect(() => {
            const customerId = this.widget().info.customer.id;
            const employeeId = this.widget().info.employee?.id;

            (employeeId
                ? this.dashboardStoreService.getEmployeeShifts(this.minDate(), this.maxDate(), customerId, employeeId)
                : this.dashboardStoreService.getCustomerShifts(this.minDate(), this.maxDate(), customerId)
            ).subscribe((shifts) => {
                this.setLoading(false);

                this.shifts.set(shifts);
                this.shiftsLoaded.set(true);
            });
        });

        this.dateClassFn = (date, view) => {
            if (view !== 'month') {
                return '';
            }

            return this.shifts().some((shift) => shift.from.hasSame(date, 'day')) ? 'has-shift' : '';
        };
    }

    change(date: DateTime | null) {
        if (!date) {
            return;
        }

        this.matDialog.open<ShiftListDialogComponent, ShiftListDialogData>(ShiftListDialogComponent, {
            data: {
                customerId: this.widget().info.customer.id,
                subtitle: signal(Promise.resolve(date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY))),
                from: date.startOf('day'),
                to: date.endOf('day'),
            },
        });
    }
}
