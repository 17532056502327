import { DateTime, Interval } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import type { ShiftPeriodResponse } from './shift-period';
import { ShiftPeriod } from './shift-period';
import type { ScheduleResponse } from './base-schedule';
import type { PropertyResponse } from '../../shared/models/property';
import { Property } from '../../shared/models/property';
import type { ShiftSwapResponse } from '../interfaces/shift-swap-response';
import { ShiftSwap } from './shift-swap';
import { Schedule } from './schedule';
import { Comment, CommentResponse } from '../../shared/models/comment';
import { ShiftChange, ShiftChangeType } from './shift-change';
import { User, UserResponse } from '../../shared/models/user';
import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { Warning, WarningResponse } from '../../shared/models/warning';
import { Qualification, QualificationResponse } from '../../shared/models/qualification';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BaseApiModel } from '../../shared/models/base-api-model';
import { BusinessDate } from '../../shared/utils/business-date';
import { BusinessDateString } from '../../shared/types/business-date';
import { ApiModel } from '../../shared/enums/api-model';

export enum ShiftStatus {
    Complete = 'complete',
    InProgress = 'in_progress',
    Upcoming = 'upcoming',
}

export interface ShiftResponse extends ApiResponse {
    employee?: EmployeeResponse;
    properties?: PropertyResponse[];
    all_qualifications?: QualificationResponse[];
    edited_by?: number | null;
    edited_by_user?: UserResponse;
    employee_id?: number | null;
    id: number;
    length: number;
    net_length: number;
    offset: number;
    original_id?: number | null;
    schedule_id: number;
    customer_id?: number;
    comments?: CommentResponse[];
    schedule?: ScheduleResponse
    business_date?: BusinessDateString | null;
    from: string;
    to: string;
    periods?: ShiftPeriodResponse[];
    unprocessed_swaps?: ShiftSwapResponse[];
    created_at: string;
    updated_at: string;
    deleted_at?: string | null;
    changes?: ShiftChangeType[];
    ghosts?: ShiftResponse[];
    warnings?: WarningResponse[];
    comments_count?: number;
    warnings_count?: number;
    periods_count?: number;
}

export class Shift extends BaseApiModel<ShiftResponse, Shift> {
    qualifications: Qualification[];
    changes?: ShiftChange[];
    editedBy?: number | null;
    editedByUser?: User;
    employeeId?: number | null;
    employee?: Employee;
    id: number;
    length: number;
    breakLength: number;
    netLength: number;
    offset: number;
    // An easier way to access shift.offset + shift.length
    rightOffset: number;
    originalId?: number | null;
    scheduleId: number;
    businessDate: BusinessDate | null;
    from: DateTime;
    to: DateTime;
    customerId?: number;
    schedule?: Schedule;
    comments: Comment[];
    interval: Interval;
    periods: ShiftPeriod[];
    unprocessedSwaps?: ShiftSwap[];
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;
    ghosts?: Shift[];
    properties?: Property[];
    warnings: Warning[];
    warningsCount?: number;
    commentsCount?: number;
    periodsCount?: number;

    constructor(data: ShiftResponse) {
        super(data, ApiModel.Shift);

        this.changes = data.changes?.map((c) => new ShiftChange(c, this));
        this.editedBy = data.edited_by;
        this.editedByUser = data.edited_by_user ? new User(data.edited_by_user) : undefined;
        this.employeeId = data.employee_id;
        this.employee = data.employee ? new Employee(data.employee) : undefined;
        this.id = data.id;
        this.length = data.length;
        this.netLength = data.net_length;
        this.offset = data.offset;
        this.rightOffset = data.offset + data.length;
        this.originalId = data.original_id;
        this.scheduleId = data.schedule_id;
        this.businessDate = data.business_date ? new BusinessDate(data.business_date) : null;
        this.from = stringToDateTime(data.from);
        this.to = stringToDateTime(data.to);
        this.interval = Interval.fromDateTimes(this.from, this.to);
        this.breakLength = this.length - this.netLength;
        this.periods = data.periods?.map((p) => new ShiftPeriod(p)) || [];
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.warnings = data.warnings?.map((w) => new Warning(w)) || [];
        this.customerId = data.customer_id;
        this.warningsCount = data.warnings_count || data.warnings?.length;
        this.commentsCount = data.comments_count || data.comments?.length;
        this.periodsCount = data.periods_count || data.periods?.length;
        this.properties = data.properties?.map((p) => new Property(p));
        this.ghosts = data.ghosts?.map((g) => new Shift(g));
        this.unprocessedSwaps = data.unprocessed_swaps?.map((s) => new ShiftSwap(s));
        this.comments = data.comments?.map((c) => new Comment(c)) || [];
        this.schedule = data.schedule ? new Schedule(data.schedule) : undefined;
        this.qualifications = data.all_qualifications?.map((q) => new Qualification(q)) || [];
    }

    hasUnapprovedSwaps() {
        return this.unprocessedSwaps?.some((s) => !s.approved);
    }

    hasUnprocessedSwaps(): boolean {
        return (this.unprocessedSwaps?.length || 0) > 0;
    }

    get status(): ShiftStatus {
        if (this.to < DateTime.now()) {
            return ShiftStatus.Complete;
        }
        if (this.from > DateTime.now()) {
            return ShiftStatus.Upcoming;
        }

        return ShiftStatus.InProgress;
    }
}
