// @ts-nocheck
import { module } from 'angular';
/**
 * @name ShiftFactory
 */
module('eaw.scheduling.shifts').factory('ShiftFactory', [ 'EawResource', 'Shift', function shiftFactory(EawResource, Shift) {
    const factory = this;
    factory.getAll = (args) => Shift.getAll(args);
    factory.getEmployees = {
        permission(customerId, scheduleId, shiftId) {
            return `customers.${customerId}.schedules.${scheduleId}.shifts.${shiftId}.update`;
        },
        query(customerId, scheduleId, shiftId, searchText) {
            return EawResource.create('/customers/:customer_id/schedules/:schedule_id/shifts/:shift_id/employees').get({
                customer_id: customerId,
                schedule_id: scheduleId,
                shift_id: shiftId,
                filter: searchText,
            });
        },
    };
    factory.getAllForCustomer = (customerId, options) => {
        if (options.useResource) {
            return Shift.getAllForCustomer(customerId, options);
        }
        return Shift.getAllForCustomer(customerId, options).$promise;
    };
    factory.getAllForEmployee = (customerId, employeeId, args) => Shift.getAllForEmployee(customerId, employeeId, args).$promise;
    /**
     * @param customerId
     * @param shiftId
     * @param withRelations
     * @param includeChangelog
     */
    factory.getShift = (customerId, shiftId, withRelations, includeChangelog) => {
        return Shift.getShift(customerId, null, shiftId, {
            with: withRelations,
            includeChangelog,
        });
    };
    factory.addOffsetAndLength = (shift, schedule) => {
        if ((shift.offset == null || shift.length == null) && shift.from && shift.to) {
            shift.offset = shift.from.diff(schedule.from, 's');
            shift.length = shift.to.diff(shift.from, 's');
        }
    };
    /**
     * Add shift to schedule
     * @param {int}      customerId
     * @param {Object}   schedule
     * @param {object}      shift
     * @param {string[]}    [withRelations]
     * @returns {*}
     */
    factory.create = (customerId, schedule, shift, withRelations) => {
        factory.addOffsetAndLength(shift, schedule);
        return Shift.create(customerId, schedule.id, shift, withRelations);
    };
    /**
     * Update shift
     * @param {int}         customerId
     * @param {Object}         schedule
     * @param {Object}      shift
     * @param {string[]}    [withRelations]
     */
    factory.update = (customerId, schedule, shift, withRelations) => {
        factory.addOffsetAndLength(shift, schedule);
        return Shift.update(customerId, schedule.id, shift, withRelations);
    };
    factory.delete = (customerId, scheduleId, shiftId) => Shift.delete(customerId, scheduleId, shiftId);
    /**
     * @param {integer} customerId
     * @param {Object} commands
     * @param {string} commands.*.first_half
     * @param {string} [commands.*.second_half]
     * @param {integer[]} [commands.*.employees]
     * @param {integer} [commands.*.length]
     * @param {Object} [model]
     */
    factory.splitShifts = (customerId, commands, model) => EawResource.create('/customers/:customer_id/shifts/:shift_id').update({
        customer_id: customerId,
        shift_id: 'split',
    }, {
        shifts: commands,
        model,
    });
    return factory;
} ]);
