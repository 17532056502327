import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Message, MessageResponse } from '../models/message';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { MessageArchival } from '../angularjs/types/message-archival';
import { classifyArrayPaginatedResponse } from '../../shared/utils/rxjs/classify';

interface GetAllOptions extends PaginationOptions {
    read?: boolean,
}

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    http = inject(HttpClient);

    getMessages(options?: GetAllOptions): Observable<ArrayPaginatedResponse<Message>> {
        return this.http.get<ArrayPaginatedResponse<MessageResponse>>(`/messages/inbox`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(Message));
    }

    archive(messageId: number, archive: boolean): Observable<MessageArchival> {
        return this.http.put<MessageArchival>(`/messages/${messageId}/archive`, { archive });
    }
}
