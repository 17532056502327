import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'week-setup-list-upgraded',
    standalone: true
})
export class weekSetupListUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('weekSetupList', elementRef, injector);
    }
}

@Component({
    template: '<week-setup-list-upgraded [customer]="customer"></week-setup-list-upgraded>',
    standalone: true,
    imports: [weekSetupListUpgradedDirective],
})
export class weekSetupListUpgradedComponent {
    @Input() customer!: any;
}

@NgModule({
    imports: [weekSetupListUpgradedDirective,
        weekSetupListUpgradedComponent],
})
export class weekSetupListUpgradeModule {
}
