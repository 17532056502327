<eaw-page-header [useBigFilter]="true" [bigFilterToggled]="false" [fabButton]="fabButton">
    <span title *ngIf="!userId">{{ 'ALL_TODOS' | translate:'todo' | async }}</span>
    <span title *ngIf="userId">{{ 'MY_TODO_plural' | translate:'todo' | async }}</span>

    <div bigFilter>
        <form [formGroup]="form" class="tw-grid tw-grid-cols-2 sm:tw-grid-cols-[1fr_1fr_1fr_2fr] tw-gap-8">
            <mat-form-field>
                <mat-label>{{ 'INCLUDE_FUTURE_TODOS' | translate:'todo' | async }}</mat-label>
                <mat-select formControlName="includeFuture">
                    <mat-option [value]="true">{{ 'YES' | translateSync }}</mat-option>
                    <mat-option [value]="false">{{ 'NO' | translateSync }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'COMPLETED' | translate:'todo' | async }}</mat-label>
                <mat-select formControlName="done">
                    <mat-option [value]="'completed'">{{ 'SHOW_COMPLETED' | translateSync:'todo' }}</mat-option>
                    <mat-option [value]="'not_completed'">{{ 'HIDE_COMPLETED' | translateSync:'todo' }}</mat-option>
                    <mat-option [value]="'all'">{{ 'ALL' | translateSync }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'DUE_DATE' | translate:'todo' | async }}</mat-label>
                <mat-select formControlName="due">
                    <mat-option *ngFor="let due of dueDates" [value]="due.value">{{ due.name | async }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'STATUS' | translate | async }}</mat-label>
                <mat-select formControlName="status" multiple>
                    <mat-option *ngFor="let status of todoStatuses" [value]="status.id">{{ status.name }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'FILTER' | translate | async }}</mat-label>
                <input matInput formControlName="filter" autocomplete="off">
            </mat-form-field>

            <eaw-customer-user-autocomplete *ngIf="!userId" controlName="user" [customerId]="customerId"></eaw-customer-user-autocomplete>

            <mat-form-field *ngIf="form.controls.customerLink">
                <mat-label>{{ 'CUSTOMER_LINK' | translate | async }}</mat-label>
                <mat-select formControlName="customerLink">
                    <mat-option [value]="{id: undefined}">{{ 'ALL_LINKS' | translateSync }}</mat-option>
                    <mat-option *ngFor="let child of childLocations" [value]="child">{{ child.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <div class="tw-flex tw-flex-row tw-gap-8">
            <button mat-raised-button color="primary" (click)="dataTable.paginatorPage()" [disabled]="form.pristine">{{ 'UPDATE' | translate | async }}</button>
        </div>
    </div>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        <eaw-data-table
            #dataTable
            [columns]="columns"
            [request]="request"
            [rowClasses]="rowClasses"
            initialSortBy="id"
            (rowClick)="rowClick($event)"
            initialSortDirection="desc"
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
