import { DataTableValue } from '../interfaces/data-table-value';
import { DataTableColumn } from './data-table-column';
import { DataTableHeader } from './data-table-header';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { TextPortalComponent } from '../portals/text-portal/text-portal.component';
import { DataTableCell } from '../interfaces/data-table-cell';

type DataTableDateFormat<Item> = DateTimeFormatOptions | string | ((cell: DataTableCell<DataTableDateTimeColumn<Item>, Item>) => string | DateTimeFormatOptions);

interface DataTableDateTimeColumnData<Item> {
    header: DataTableHeader
    /**
     * Either tokens from {@link https://moment.github.io/luxon/#/formatting?id=table-of-tokens}
     *
     * Or from {@link DateTimeFormatOptions}
     */
    format?: DataTableDateFormat<Item>;
        value: DataTableValue<DataTableDateTimeColumn<Item>, Item, DateTime | null | undefined>
    exportable?: boolean,
}

export class DataTableDateTimeColumn<Item> extends DataTableColumn<DataTableDateTimeColumn<Item>, Item, string> {
    component = TextPortalComponent;
    format: DataTableDateFormat<Item>;

    constructor(column: DataTableDateTimeColumnData<Item>) {
        const value = async (data: DataTableCell<DataTableDateTimeColumn<Item>, Item>): Promise<string> => {
            const value = typeof column.value === 'string' ? data.item[column.value] as DateTime : await column.value(data);

            if (value == null) {
                return '';
            }

            const format = typeof this.format === 'function' ? this.format(data) : this.format;

            if (typeof format === 'string') {
                return value.toFormat(format);
            } else {
                return value.toLocaleString(format);
            }
        };

        super({
            exportable: true,
            ...column,
            nowrap: true,
            value,
        });

        this.format = column.format ?? DateTime.DATE_MED;
    }
}
