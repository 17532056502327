// @ts-nocheck
import { t } from 'i18next';
import { remove } from 'lodash-es';
import { uniqBy } from 'lodash-es';
import { uniqueId } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { orderBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { groupBy } from 'lodash-es';
const template2 = `<md-list>
    <md-list-item ng-click="$ctrl.choose()">
        <md-icon ng-bind="'add'"></md-icon>
        <p bo-i18next="NEW"></p>
    </md-list-item>
    <md-list-item ng-repeat="shift in $ctrl.shifts" ng-click="$ctrl.choose(shift)">
        <md-icon ng-bind="'edit'"></md-icon>
        <p>{{shift.from | moment:'LT'}} - {{shift.to | moment:'LT'}}</p>
    </md-list-item>
</md-list>
`;
module('eaw.scheduling').component('shiftsOverview', {
    template: `<md-card ng-show="$so.showFilter">
    <md-card-content>
        <form name="shiftsOverviewForm" ng-submit="$so.getData()">
            <div layout="row" layout-wrap>
                <eaw-date-interval layout="row" flex="100" flex-gt-sm="50" ng-model="$so.interval" required></eaw-date-interval>
            </div>
            <div layout="row">
                <eaw-submit-btn form="$so.form"></eaw-submit-btn>
            </div>
        </form>
    </md-card-content>
</md-card>

<md-card id="shift-overview-content">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-i18next="OVERVIEW"></span>
        </md-card-header-text>

        <md-button class="md-icon-button" ng-click="$so.sortEmployees()">
            <md-icon>sort</md-icon>
            <md-tooltip ng-if="$so.employeeSort == 'asc'">{{'SORT_DESCENDING' | i18next}}</md-tooltip>
            <md-tooltip ng-if="$so.employeeSort == 'desc'">{{'SORT_ASCENDING' | i18next}}</md-tooltip>
        </md-button>

        <eaw-toggle-btn ng-model="$so.showEmpWithNoShift"
                        ng-change="$so.updateEmpRowShow()"
                        icon="calendar_today"
                        i18n-tooltip="scheduling:SHOW_EMPS_NO_SHIFT">
        </eaw-toggle-btn>

        <eaw-toggle-btn ng-model="$so.showFilter"
                        icon="filter_alt"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>
    </md-card-header>

    <md-card-content id="menu-container" class="tw-p-0">
        <md-menu-bar>
            <md-menu>
                <button ng-click="$mdMenu.open()" ng-i18next="scheduling:SCHEDULE_plural"></button>
                <md-menu-content>
                    <md-menu-item type="checkbox"
                                  md-prevent-menu-close
                                  ng-repeat="schedule in $so.schedules track by schedule.id"
                                  ng-model="schedule._included"
                                  ng-change="$so.onScheduleToggle(schedule)">{{schedule.name}}
                    </md-menu-item>
                    <md-menu-divider></md-menu-divider>
                    <md-menu-item type="checkbox"
                                  ng-model="$so.showScheduleColor"
                                  ng-change="$so.insertShifts()">{{'scheduling:SHOW_SCHED_COLOR' | i18next}}
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
            <md-menu>
                <button ng-click="$mdMenu.open()" ng-i18next="WEEK_plural"></button>
                <md-menu-content>
                    <md-menu-item type="checkbox"
                                  md-prevent-menu-close
                                  ng-repeat="week in $so.weeks track by week.id"
                                  ng-model="week._included"
                                  ng-change="$so.onWeekToggle(week)">{{week.text}}
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
            <md-menu>
                <button ng-click="$mdMenu.open()" ng-i18next="scheduling:PERIODS_AND_UNITS"></button>
                <md-menu-content>
                    <md-menu-item type="checkbox"
                                  md-prevent-menu-close
                                  ng-repeat="unit in $so.units track by unit.id"
                                  ng-model="unit._included"
                                  ng-change="$so.onUnitToggle(unit)">{{unit.name}}
                    </md-menu-item>
                    <md-menu-divider></md-menu-divider>
                    <md-menu-item type="checkbox"
                                  ng-model="$so.showBreakPeriods"
                                  ng-change="$so.onBreakPeriodToggle()">{{'scheduling:SHOW_BREAK_plural' | i18next}}
                    </md-menu-item>
                    <md-menu-item type="checkbox"
                                  ng-model="$so.showShiftNoUnit"
                                  ng-change="$so.insertShifts()">{{'scheduling:SHOW_SHIFTS_NO_UNIT' | i18next}}
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        </md-menu-bar>
    </md-card-content>

    <md-card-content id="schedule-color-legend" ng-show="$so.showScheduleColor">
        <div class="schedule-color" ng-repeat="schedule in $so.schedules track by schedule.id" style="border-left-color: {{schedule._color[100].hex}}">
            <span ng-bind="schedule.name"></span>
        </div>
    </md-card-content>

    <md-card-content id="table-container" class="tw-p-0 pretty-scroll">
        <table id="shifts-overview-table" class="table table-bordered table-condensed table-hover sticky-header sticky-left"></table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$so',
    bindings: {
        customer: '<',
    },
    controller: [ 'Shift', 'Schedule', '$element', '$mdColorPalette', 'ShiftPeriod', 'EawResource', 'CustomerService', 'IconElement', '$mdPanel', function(Shift, Schedule, $element, $mdColorPalette, ShiftPeriod, EawResource, CustomerService, IconElement, $mdPanel) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.el = $element[0];
            ctrl.showFilter = true;
            ctrl.showShiftNoUnit = true;
            ctrl.interval = {
                from: moment().startOf('M'),
                to: moment().endOf('M'),
            };
        };
        ctrl.getData = () => {
            Promise.all([
                ctrl.getShifts(1, 1),
                ctrl.getEmployees(),
                ctrl.getSchedules(),
            ]).then(ctrl.onData).catch((e) => {
                console.error(e);
            });
        };
        ctrl.onData = (resp) => {
            const shiftCount = resp[0].total;
            const perPage = 40;
            const promises = new Array(Math.ceil(shiftCount / perPage)).fill(0).map((x, i) => ctrl.getShifts(100, i + 1, [ 'warnings', 'comments', 'periods.businessUnit' ]));
            Promise.all(promises).then(ctrl.onAllShifts).catch((e) => {
                console.error(e);
            }).finally(() => {
                ctrl.form?.unsubmit();
            });
        };
        ctrl.onAllShifts = (resp) => {
            ctrl.formatShifts(resp);
            ctrl.createDays();
            ctrl.createWeeks();
            ctrl.createTable();
            ctrl.sortEmployees();
        };
        ctrl.sortEmployees = () => {
            const body = ctrl.table?.querySelector('tbody');
            if (!body) {
                return;
            }
            ctrl.employeeSort = ctrl.employeeSort === 'asc' ? 'desc' : 'asc';
            ctrl.employees = orderBy(ctrl.employees, 'name', ctrl.employeeSort);
            ctrl.employees.forEach((e, i) => {
                const tr = body.querySelector(`tr[data-employee-id="${e.id}"]`);
                body.insertBefore(tr, body.children[i + 1]); // Add 1 to index for unassigned row
            });
        };
        ctrl.createDays = () => {
            ctrl.days = [];
            const from = ctrl.interval.from.clone();
            const to = ctrl.interval.to.clone();
            // Generate days
            while (from.isSameOrBefore(to)) {
                ctrl.days.push({
                    unix: +from,
                    key: from.format('YYYY-MM-DD'),
                    date: from.format('ll'),
                    week: from.week(),
                    year: from.year(),
                    weekYear: from.weekYear(),
                    day: from.format('dddd'),
                    dayDate: `${from.format('ddd')} - ${from.format('ll')}`,
                });
                from.add(1, 'd');
            }
        };
        ctrl.onScheduleToggle = (schedule) => {
            Array.from(ctrl.table.querySelectorAll(`.shift[data-schedule-id="${schedule.id}"]`))?.forEach((el) => {
                const action = schedule._included ? 'remove' : 'add';
                el.classList[action]('display-none');
            });
            ctrl.updateEmpRowShow();
        };
        ctrl.onUnitToggle = (unit) => {
            Array.from(ctrl.table.querySelectorAll(`.shift-period[data-unit-id="${unit.id}"]`))?.forEach((el) => {
                el.classList[unit._included ? 'remove' : 'add']('display-none');
            });
            ctrl.updateEmpRowShow();
        };
        ctrl.onBreakPeriodToggle = () => {
            Array.from(ctrl.table.querySelectorAll(`.shift-period[data-break="true"]`))?.forEach((el) => {
                el.classList[ctrl.showBreakPeriods ? 'remove' : 'add']('display-none');
            });
            ctrl.updateEmpRowShow();
        };
        ctrl.onWeekToggle = (week) => {
            Array.from(ctrl.table.querySelectorAll(`[data-week="${week.week}"]`))?.forEach((el) => {
                el.classList[week._included ? 'remove' : 'add']('display-none');
            });
            ctrl.updateEmpRowShow();
        };
        ctrl.updateEmpRowShow = () => {
            const trs = ctrl.table?.querySelectorAll('tbody .employee-row');
            if (!trs) {
                return;
            }
            Array.from(trs).forEach((tr) => {
                if (ctrl.showEmpWithNoShift) {
                    tr.classList.remove('display-none');
                } else {
                    const shifts = !!tr.querySelectorAll('td:not(.display-none) .shift:not(.display-none)').length;
                    const action = shifts ? 'remove' : 'add';
                    tr.classList[action]('display-none');
                }
            });
        };
        ctrl.getEmployees = () => EawResource.create(`/customers/${ctrl.customer.id}/employees`).get().$promise.then((resp) => {
            ctrl.employees = resp.data;
        });
        ctrl.createTable = () => {
            // Get the table
            ctrl.table = document.getElementById('shifts-overview-table');
            // Remove any existing stuff
            Array.from(ctrl.table.children).forEach((c) => c.remove());
            // (Re)create
            ctrl.createHeader();
            ctrl.createBody();
            ctrl.insertShifts();
            ctrl.updateEmpRowShow();
        };
        ctrl.createWeeks = () => {
            ctrl.weeks = Object.entries(groupBy(ctrl.days, 'week')).map((entry) => {
                const [ weekNr, weekDays ] = entry;
                return {
                    id: uniqueId(),
                    text: t('WEEK_AND_YEAR', {
                        week: weekNr,
                        year: weekDays[0].weekYear,
                    }),
                    week: weekNr,
                    length: weekDays.length,
                    _included: true,
                };
            });
        };
        ctrl.showShiftNoUnitFilter = (s) => {
            // Do check if we want to remove those without a unit
            if (!ctrl.showShiftNoUnit) {
                // Return true if some of the period(s) have a business unit
                return s.periods?.some((p) => p.business_unit_id);
            }
            return true;
        };
        ctrl.insertShifts = () => {
            const shiftDivs = ctrl.table?.querySelectorAll('.shift');
            if (shiftDivs?.length) {
                Array.from(shiftDivs).forEach((d) => d.remove());
            }
            ctrl.shifts
                ?.filter(ctrl.showShiftNoUnitFilter)
                ?.sort((a, b) => +a.from - +b.from)
                ?.forEach(ctrl.insertShift);
        };
        ctrl.insertShift = (shift) => {
            const empQuery = `[data-employee-id="${shift.employee_id || 0}"]`;
            const dayQuery = `[data-day-key="${shift.from.format('YYYY-MM-DD')}"]`;
            const td = ctrl.table.querySelector(`td${empQuery}${dayQuery}`);
            if (!(td instanceof Node)) {
                return;
            }
            td.appendChild(ctrl.createShift(shift));
        };
        ctrl.createShift = (shift, status) => {
            const shiftDiv = document.createElement('div');
            const textSpan = document.createElement('span');
            // Manipulate shift div
            shiftDiv.classList.add('shift');
            shiftDiv.dataset.shiftId = shift.id;
            shiftDiv.dataset.scheduleId = shift.schedule_id;
            shiftDiv.style.backgroundColor = ctrl.showScheduleColor ? shift.schedule?._color[50].hex : '';
            // Manipulate and add span
            textSpan.innerText = `${shift.from.format('LT')} - ${shift.to.format('LT')}`;
            shiftDiv.appendChild(textSpan);
            ctrl.handleShiftStatus(shiftDiv, textSpan, shift, status);
            ctrl.addShiftPeriodsUnits(shift, shiftDiv);
            return shiftDiv;
        };
        ctrl.addShiftPeriodsUnits = (shift, shiftDiv) => {
            shift.periods?.sort((a, b) => a.offset - b.offset).forEach((p) => {
                const periodDiv = document.createElement('div');
                const fromTo = `${p.from.format('LT')} - ${p.to.format('LT')}`;
                periodDiv.classList.add('shift-period');
                periodDiv.dataset.periodId = p.id;
                periodDiv.dataset.break = p.break;
                periodDiv.style.backgroundColor = p.backgroundColor;
                periodDiv.title = `${p.business_unit?.name || p.description}\n${fromTo}`;
                // Spesific actions for periods with a business unit
                if (p.business_unit) {
                    periodDiv.dataset.unitId = p.business_unit.id;
                }
                // We want to add to the DOM, but hide it
                if (!ctrl.showBreakPeriods && p.break) {
                    periodDiv.classList.add('display-none');
                }
                shiftDiv.appendChild(periodDiv);
            });
        };
        ctrl.handleShiftStatus = (shiftEl, textEl, shift, status) => {
            // temp id means it's a new shift we are creating
            if (shift.tempId) {
                shiftEl.dataset.shiftTempId = shift.tempId;
                status = 'creating';
            }
            // Show cog and handle status
            if (status) {
                shiftEl.classList.add('status', status);
                IconElement.get('settings').then((el) => {
                    el.classList.add('shift-status-icon');
                    shiftEl.prepend(el);
                });
                switch (status) {
                    case 'creating': {
                        textEl.innerText = t('scheduling:SHIFT_STATUS_SAVE');
                        break;
                    }
                    case 'updating': {
                        textEl.innerText = t('scheduling:SHIFT_STATUS_UPDATE');
                        break;
                    }
                    case 'deleting': {
                        textEl.innerText = t('scheduling:SHIFT_STATUS_DELETE');
                        break;
                    }
                }
            }
        };
        ctrl.createBody = () => {
            const body = document.createElement('tbody');
            // First row is for unassigned
            body.appendChild(ctrl.createEmployeeRow({
                id: 0,
                name: t('scheduling:UNASSIGNED'),
            }, ctrl.days));
            // All following rows are for employees
            ctrl.employees.forEach((emp) => {
                body.appendChild(ctrl.createEmployeeRow(emp, ctrl.days));
            });
            ctrl.table.appendChild(body);
        };
        ctrl.createEmployeeRow = (emp) => {
            const tr = document.createElement('tr');
            tr.dataset.employeeId = emp.id;
            tr.classList.add(emp?.id ? 'employee-row' : 'unassigned-row');
            // EmployeeOld name td
            const nameTd = document.createElement('td');
            nameTd.classList.add('employee-name');
            nameTd.innerText = emp.name;
            tr.appendChild(nameTd);
            // Days on employee
            ctrl.days.forEach((day) => {
                const td = document.createElement('td');
                td.addEventListener('click', ctrl.open);
                td.dataset.dayKey = day.key;
                td.dataset.employeeId = emp.id;
                td.dataset.week = day.week;
                td.classList.add('shift-td');
                tr.appendChild(td);
            });
            return tr;
        };
        ctrl.createHeader = () => {
            const header = document.createElement('thead');
            // Row with weeks
            const weeksTr = document.createElement('tr');
            // Add one th before which is where employee names go
            weeksTr.appendChild(document.createElement('th'));
            ctrl.weeks.forEach((w) => weeksTr.appendChild(ctrl.createHeaderWeek(w)));
            header.appendChild(weeksTr);
            // Row with days
            const daysTr = document.createElement('tr');
            // Add one th before which is where employee names go
            daysTr.appendChild(document.createElement('th'));
            ctrl.days.forEach((d) => daysTr.appendChild(ctrl.createHeaderDay(d)));
            header.appendChild(daysTr);
            ctrl.table.appendChild(header);
        };
        ctrl.createHeaderWeek = (week) => {
            const th = document.createElement('th');
            th.colSpan = week.length;
            th.dataset.week = week.week;
            const span = document.createElement('span');
            span.innerText = week.text;
            th.appendChild(span);
            return th;
        };
        ctrl.createHeaderDay = (day) => {
            const th = document.createElement('th');
            th.innerText = day.dayDate;
            th.dataset.week = day.week;
            return th;
        };
        ctrl.formatShifts = (resp) => {
            // Join all the shifts together from all the responses
            const shifts = resp.reduce((sArr, shiftsResp) => sArr.concat(shiftsResp.data), []);
            // Make shifts and stuff into classes
            ctrl.shifts = shifts.map((s) => new Shift(s, ctrl.getSchedule(s.schedule_id)));
            ctrl.shifts.forEach((s) => {
                s.periods = s.periods?.map((p) => new ShiftPeriod(p));
            });
            // Extract units
            ctrl.units = ctrl.shifts.reduce((units, shift) => units.concat(shift.periods?.map((p) => p.business_unit)), []).filter((u) => u?.id);
            ctrl.units = orderBy(uniqBy(ctrl.units, 'id'), 'name', 'asc');
            ctrl.units.forEach((u) => u._included = true);
        };
        ctrl.getSchedules = () => CustomerService.getAllSchedulesPaginated({
            id: ctrl.customer.id,
            query: 'schedules',
            from: ctrl.interval?.from,
            to: ctrl.interval?.to,
        }).$promise.then(ctrl.formatSchedules);
        ctrl.formatSchedules = (resp) => {
            const colors = [
                'deep-purple',
                'teal',
                'orange',
                'indigo',
                'brown',
                'blue',
                'purple',
                'amber',
                'pink',
                'brown',
            ];
            ctrl.schedules = resp.data.map((schedule, i) => {
                schedule._included = true;
                schedule._color = $mdColorPalette[colors[i]];
                return new Schedule(schedule);
            });
        };
        ctrl.getShifts = (perPage, page, withs) => Shift.getAll({
            customer_id: ctrl.customer.id,
            from: ctrl.interval?.from,
            to: ctrl.interval?.to,
            with: withs,
            per_page: perPage,
            page,
        }).$promise;
        ctrl.extractTdData = (td) => {
            const date = moment(td.dataset.dayKey, 'YYYY-MM-DD');
            const employee = ctrl.employees.find((e) => e.id === parseInt(td.dataset.employeeId));
            const shifts = ctrl.shifts.filter((s) => {
                // If we have an employee, get shifts for that employee on that day
                if (employee?.id) {
                    return s.employee_id === employee?.id && s.from.isSame(date, 'd');
                }
                // Return shifts without an employee
                return !s.employee_id && s.from.isSame(date, 'd');
            });
            return {
                date,
                employee,
                shifts,
            };
        };

        /**
         * result from mdpanel or bottomsheet
         * @param {object} shift
         */
        ctrl.onShiftSelect = (shift) => {

        };

        /**
         * @param {Shift} shift
         */
        ctrl.onShiftDelete = (shift) => {
            // Use update shift div while we update
            const deletingShiftDiv = ctrl.createShift(shift, 'deleting');
            ctrl.getShiftDiv(shift.id)?.replaceWith(deletingShiftDiv);
            shift.delete().$promise.then(() => {
                // If successful, remove shift from array and redraw
                ctrl.shifts = ctrl.shifts.filter((s) => s.id !== shift.id);
            }).catch((e) => {
                // If not successful then remove the temp deleting div and redraw
                deletingShiftDiv.remove();
                console.error(e);
            }).finally(() => {
                // Redraw
                ctrl.insertShifts();
            });
        };
        ctrl.getSchedule = (id) => ctrl.schedules.find((s) => s.id === id);
        ctrl.getScheduleForDate = (date) => ctrl.schedules.filter((s) => date.isBetween(s.from, s.to))?.[0];
        ctrl.getShiftDiv = (id) => ctrl.table.querySelector(`[data-shift-id="${id}"]`);
        ctrl.onNewShift = (newShift) => {
            // Push the new shift with a temporary identifier
            const tempId = parseInt(uniqueId());
            newShift.tempId = tempId;
            ctrl.shifts.push(newShift);
            ctrl.insertShifts();
            newShift.create().$promise.then((createdShift) => {
                // Push the real created shift
                ctrl.shifts.push(new Shift(createdShift, createdShift.schedule));
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                // Remove the temp shift regardless of the outcome
                ctrl.shifts = ctrl.shifts.filter((s) => s.tempId !== tempId);
                // Insert
                ctrl.insertShifts();
            });
        };
        ctrl.onShiftUpdate = (editedShift) => {
            // Use update shift div while we update
            const updatingShiftDiv = ctrl.createShift(editedShift, 'updating');
            ctrl.getShiftDiv(editedShift.id)?.replaceWith(updatingShiftDiv);
            editedShift.update().then((newShift) => {
                // Remove the old shift that we edited
                remove(ctrl.shifts, (s) => s.id === editedShift.id);
                // Insert the newly created shift
                ctrl.shifts.push(newShift);
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                ctrl.insertShifts();
            });
        };
        ctrl.open = (event) => {
            const td = event.target.closest('td');
            if (!td) {
                return;
            }
            const data = ctrl.extractTdData(td);
            $mdPanel.open({
                attachTo: document.body,
                controller: 'ShiftOverviewPanel',
                controllerAs: '$ctrl',
                template: template2,
                panelClass: 'eaw-panel shift-overview-panel',
                position: $mdPanel.newPanelPosition()
                    .relativeTo(event.currentTarget || event.target)
                    .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ALIGN_TOPS),
                animation: $mdPanel.newPanelAnimation()
                    .openFrom(event)
                    .duration(100)
                    .withAnimation($mdPanel.animation.SCALE),
                targetEvent: event,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                bindToController: true,
                locals: {
                    shifts: data.shifts,
                    employee: data.employee,
                    date: data.date,
                    callback: ctrl.onShiftSelect,
                },
            });
        };
    } ],
});
