import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'positions-upgraded',
    standalone: true
})
export class positionsUpgradedDirective extends UpgradeComponent {
    @Input() canCreate!: any;
    @Input() customer!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('companyPositions', elementRef, injector);
    }
}

@Component({
    template: '<positions-upgraded [customer]="customer" [canCreate]="canCreate"></positions-upgraded>',
    standalone: true,
    imports: [positionsUpgradedDirective],
})
export class positionsUpgradedComponent {
    @Input() canCreate!: any;
    @Input() customer!: any;
}

@NgModule({
    imports: [positionsUpgradedDirective,
        positionsUpgradedComponent],
})
export class positionsUpgradeModule {
}
