import { Component, Inject, OnInit, signal } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AbsenceService } from '../../../http/absence.service';
import { Absence } from '../../../models/absence';
import { catchError, EMPTY, tap } from 'rxjs';
import { CurrentService } from '../../../../shared/services/current.service';
import { Products } from '../../../../shared/enums/products';
import { CustomerProductService } from '../../../../shared/http/customer-product.service';
import { ActionButtonComponent } from '../../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { AsyncPipe, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { AbsenceReleaseShifts } from '../../../types/types';
import { InfoLoadingComponent } from '../../../../shared/components/info-loading/info-loading.component';

export interface ApproveAbsenceDialogData extends DialogData {
    absence: Absence,
}

@Component({
    selector: 'eaw-approve-absence-dialog',
    templateUrl: './approve-absence-dialog.component.html',
    styleUrl: './approve-absence-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        NgIf,
        MatRadioModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
        InfoLoadingComponent,
    ],
})
export class ApproveAbsenceDialogComponent extends DialogComponent implements OnInit {
    form = new FormGroup({
        action: new FormControl('release' as AbsenceReleaseShifts),
        comment: new FormControl(''),
    });

    loading = signal(true);
    hasScheduling = signal(false);

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ApproveAbsenceDialogComponent>,
        @Inject(AbsenceService) private absenceService: AbsenceService,
        @Inject(CurrentService) protected current: CurrentService,
        @Inject(CustomerProductService) protected customerProductService: CustomerProductService,
        @Inject(MAT_DIALOG_DATA) override data: ApproveAbsenceDialogData,
    ) {
        super(dialogRef, { size: DialogSize.Small });
    }

    ngOnInit(): void {
        this.customerProductService.hasProducts(this.current.getCustomer().id, [ Products.Scheduling ]).subscribe((hasScheduling) => {
            this.hasScheduling.set(hasScheduling);
            this.loading.set(false);
        });
    }

    approve() {
        const action = this.form.controls.action.value as AbsenceReleaseShifts;
        const comment = this.form.controls.comment.value || undefined;

        this.form.disable();
        this.absenceService.approve(
            this.data.absence.customerId,
            this.data.absence.employeeId,
            this.data.absence.id,
            action,
            comment,
        ).pipe(
            catchError(() => {
                this.form.enable();
                return EMPTY;
            }),
            tap((response) => this.dialogRef.close(response)),
        ).subscribe();
    }
}
