<eaw-dialog-header>
    <span title>{{'HOURS_DISTRIBUTION' | translate:'company' | async}}</span>
    <span subtitle *ngIf="employee && hourDistribution && !hourDistribution.to">{{('HOURS_DIST_SUBTITLE_NO_END' | translate:'company':{employee: employee.name, start: hourDistribution.from | DateTime}) | async}}</span>
    <span subtitle *ngIf="employee && hourDistribution && hourDistribution.to">{{('HOURS_DIST_SUBTITLE_WITH_END' | translate:'company':{employee: employee.name, start: hourDistribution.from | DateTime, end: hourDistribution.to | DateTime}) | async}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="loading" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content *ngIf="!loading">
    <table id="dist-table">
        <thead>
        <tr>
            <th>{{'WEEK' | translate | async}}</th>
            <th *ngFor="let day of weekdays">{{day}}</th>
            <th>{{'SUM' | translate | async}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let week of weeks" class="week-row">
            <td>{{week.index + 1 | eawNumber:0}}</td>

            <td *ngFor="let day of week.days" (click)="inputElement.select()">
                <div *ngIf="!canUpdate">{{day.value | eawNumber:2}}</div>

                <input #inputElement
                       [class.display-none]="day.processing || !canUpdate"
                       inputmode="numeric"
                       type="number"
                       [readOnly]="data.readonly"
                       [min]="this.minHours"
                       [max]="this.maxHours"
                       [(ngModel)]="day.value"
                       (ngModelChange)="update(day, week)"
                       step="0.01"
                       [ngModelOptions]="{updateOn: data.noDialogUpdate ? 'change' : 'blur'}">

                <ng-container *ngIf="day.processing" [ngTemplateOutlet]="processingTemplate"></ng-container>
            </td>
            <td>
                <div *ngIf="!week.processing">{{week.sum | eawNumber:2}}</div>
                <ng-container *ngIf="week.processing" [ngTemplateOutlet]="processingTemplate"></ng-container>
            </td>
        </tr>
        <tr>
            <td colspan="8">{{'TOTAL_SUM' | translate:'scheduling' | async}}</td>
            <td id="total-sum">{{totalSum | eawNumber:2}}</td>
        </tr>
        <tr *ngIf="useContractMonthHours && contractMonthHours">
            <td colspan="8">{{'CONTRACT_HOUR' | translate:'analytics' | async}}</td>
            <td id="contract-hours">{{contractMonthHours | eawNumber:2}}</td>
        </tr>
        <tr *ngIf="contractMonthHours">
            <td colspan="8">{{'DIFFERENCE' | translate | async}}</td>
            <td id="contract-diff">{{(totalSum - contractMonthHours | eawNumber:2:{signDisplay: 'always'})}}</td>
        </tr>
        </tbody>
    </table>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{'CLOSE' | translate | async}}</button>
    <button *ngIf="data.noDialogUpdate && !data.readonly" mat-raised-button color="primary" (click)="submitResult()">{{'OK' | translate | async}}</button>
</mat-dialog-actions>

<ng-template #processingTemplate>
    <div class="tw-flex tw-justify-center">
        <mat-spinner diameter="24" strokeWidth="2"></mat-spinner>
    </div>
</ng-template>
