// @ts-nocheck
import { t } from 'i18next';
import { forEach } from 'lodash-es';

import { module } from 'angular';

module('eaw.checklists').component('checklistAutocomplete', {
    template: `<md-autocomplete
        md-selected-item="$autocomplete.selectedItem"
        md-search-text="filter"
        md-selected-item-change="$autocomplete.select()"
        md-min-length="0"
        md-input-id="{{$id}}autocomplete"
        md-delay="300"
        ng-disabled="$autocomplete.disable"
        md-floating-label="{{$autocomplete.label}}"
        md-clear-button="$autocomplete.clearBtn"
        ng-required="$autocomplete.required"
        md-items="item in $autocomplete.search(filter)"
        md-item-text="item.text">
    <md-item-template>
        <span md-highlight-text="filter" md-highlight-flags="gi">{{item.text}}</span>
    </md-item-template>
    <md-not-found>
        <span ng-i18next="NO_MATCH_FOUND"></span>
    </md-not-found>
</md-autocomplete>
`,
    bindings: {
        customer: '<',
        template: '<?',
        clearBtn: '<?',
    },
    require: {
        ngModel: 'ngModel',
    },
    controllerAs: '$autocomplete',
    controller: [ '$attrs', 'ChecklistFactory', function($attrs, ChecklistFactory) {
        const ctrl = this;

        ctrl.$onInit = function() {
            ctrl.label = t(`checklists:${(ctrl.template ? 'CHECKLIST_TEMPLATE' : 'CHECKLIST')}`);
            ctrl.required = $attrs.$attr.required == 'required';
            ctrl.disable = $attrs.$attr.disabled == 'disabled';
            ctrl.ngModel.$formatters.unshift(formatter);
        };

        ctrl.search = function(filter) {
            return ChecklistFactory.getAll({
                customer: ctrl.customer,
                template: ctrl.template,
                filter,
                order_by: 'title',
                per_page: 40,
            }).$promise.then((resp) => {
                forEach(resp.data, (list) => list.text = list.title);
                return resp.data;
            });
        };

        ctrl.select = () => {
            ctrl.ngModel.setViewValue(ctrl.selectedItem);
        };

        function formatter(modelValue) {
            if (modelValue) {
                modelValue.text = modelValue.title;
            }
            ctrl.selectedItem = modelValue;
            return modelValue;
        }
    } ],
});
