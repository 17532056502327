import { Component, Inject, Input, OnChanges } from '@angular/core';
import { PropertyValueDecoder } from '../../utils/property-value-decoder';
import { SettingDataType, SettingInputType, SettingOption } from '../../models/setting';
import { TranslateService } from '../../services/translate.service';
import { DateTime } from 'luxon';
import { NumberFormatterService } from '../../services/number-formatter.service';
import { CurrentService } from '../../services/current.service';
import { DurationPipe } from '../../pipes/duration.pipe';

type ObjectValueItem = { key: string, value: string };

@Component({
    selector: 'eaw-property-value-display',
    templateUrl: './property-value-display.component.html',
    styleUrl: './property-value-display.component.scss',
    providers: [ DurationPipe ],
    standalone: true,
})
export class PropertyValueDisplayComponent implements OnChanges {
    @Input({ required: true }) propertyValue!: PropertyValueDecoder | null;
    @Input({ required: true }) dataType!: SettingDataType;
    @Input({ required: true }) inputType?: SettingInputType;
    // Options for select
    @Input({ required: true }) options?: SettingOption[];

    value = '';
    objectValue: ObjectValueItem[] = [];

    constructor(
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(DurationPipe) private durationPipe: DurationPipe,
    ) {
    }

    ngOnChanges() {
        void this.setValue();
    }

    private async handleBoolean() {
        const bool = this.propertyValue?.asBoolean();
        if (bool === true) {
            this.value = await this.translate.t('YES');
        }
        if (bool === false) {
            this.value = await this.translate.t('NO');
        }
    }

    private async handleSelect() {
        const translation = this.options?.find((option) => option.key === this.propertyValue?.value)?.translation;
        if (translation) {
            this.value = await this.translate.t(translation.key, translation.ns);
        }
    }

    private handleTime() {
        this.value = this.propertyValue?.asTime()?.toLocaleString(DateTime.TIME_SIMPLE) || '';
    }

    private handlePercent() {
        const value = this.propertyValue?.asFloat();
        this.value = value != null ? this.numberFormatterService.formatDecimal(value / 100, 2, this.current.languageTag, { style: 'percent' }) : '';
    }

    private handleInteger() {
        const value = this.propertyValue?.asInteger();
        this.value = value != null ? this.numberFormatterService.formatInteger(value) : '';
    }

    private handleFloat() {
        const value = this.propertyValue?.asFloat();
        this.value = value != null ? this.numberFormatterService.formatDecimal(value, 2) : '';
    }

    private handleSeconds() {
        this.value = this.durationPipe.transform(this.propertyValue?.asInteger(), [ 'hours', 'minutes', 'seconds' ]);
    }

    private handleMinutes() {
        this.value = this.durationPipe.transform((this.propertyValue?.asInteger() || 0) * 60, [ 'hours', 'minutes', 'seconds' ]);
    }

    async setValue() {
        if (this.dataType === 'boolean') {
            await this.handleBoolean();
            return;
        }

        if (this.inputType === 'select' || this.dataType === 'select') {
            await this.handleSelect();
            return;
        }

        if (this.dataType === 'time') {
            this.handleTime();
            return;
        }

        if (this.inputType === 'percent') {
            this.handlePercent();
            return;
        }

        if (this.inputType === 'seconds') {
            this.handleSeconds();
            return;
        }

        if (this.inputType === 'minutes') {
            this.handleMinutes();
            return;
        }

        if (this.dataType === 'integer') {
            this.handleInteger();
            return;
        }

        if (this.dataType === 'double' || this.dataType === 'float') {
            this.handleFloat();
            return;
        }

        if (this.dataType === 'object' && this.inputType === 'key-value') {
            this.objectValue = Object.entries(this.propertyValue?.asKeyValue() || {}).reduce((acc, [ key, value ]) => {
                return acc.concat({ key, value });
            }, [] as ObjectValueItem[]);
        }

        if (this.inputType === 'text' || this.dataType === 'string') {
            this.value = this.propertyValue?.asString() || '';
        }
    }
}
