import { Component, Inject, Input, ViewChild } from '@angular/core';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Employee } from '../../../shared/models/employee';
import { EmployeeService } from '../../../shared/http/employee.service';
import { CreateAvailabilityComponent } from '../../components/create-availability/create-availability.component';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { tap } from 'rxjs';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'eaw-override-availability',
    templateUrl: './override-availability.component.html',
    styleUrl: './override-availability.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        AutocompleteComponent,
        ReactiveFormsModule,
        CreateAvailabilityComponent,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class OverrideAvailabilityComponent {
    @Input() customerId!: number;
    @ViewChild(CreateAvailabilityComponent) createComponent!: CreateAvailabilityComponent;

    employeeCtrl = new FormControl<Employee | null>(null, Validators.required);
    disableForm = false;

    constructor(
        @Inject(EmployeeService) protected employeeService: EmployeeService,
        @Inject(SnackBarService) protected snackbar: SnackBarService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
    ) {
        this.employeeCtrl.valueChanges.subscribe((employee) => {
            this.disableForm = !employee;
        });
    }

    submit() {
        // employee is required
        const name = this.employeeCtrl.value?.name || '';
        this.disableForm = true;
        this.createComponent.create().pipe(
            tap(() => {
                this.snackbar.t('AVAILABILITY_OVERRIDDEN', 'availabilities', { name });
            }),
        ).subscribe({
            complete: () => {
                this.employeeCtrl.setValue(null);
                this.disableForm = false;
            },
            error: () => {
                this.disableForm = false;
            },
        });
    }
}
