import { Component, Inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NonNullableFormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { SettingService } from '../../../shared/http/setting.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { AlertDialogComponent, AlertDialogData } from '../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { tap } from 'rxjs';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { DateTime } from 'luxon';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { PayslipUploadService } from '../../http/payslip-upload.service';
import { ParsePayslipDryRunResult } from '../../models/parse-payslip-dry-run-result';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { InfoBoxComponent } from '../../../shared/components/info-card/info-box.component';
import { MatListModule } from '@angular/material/list';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { FileSelectorComponent } from '../../../shared/components/file-selector/file-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';

export interface PayslipImportDialogData extends DialogData {
    customerId: number,
}

type ImportPayslipForm = {
    file: FormControl<File | null>,
    month: FormControl<DateTime>,
    replace: FormControl<boolean>,
}

@Component({
    selector: 'eaw-payslip-import',
    templateUrl: './parse-payslip.component.html',
    styleUrl: './parse-payslip.component.scss',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'MM/YYYY',
                },
                display: {
                    dateInput: 'MM/yyyy',
                    monthYearLabel: 'MMM yyyy',
                    dateA11yLabel: 'LLL',
                    monthYearA11yLabel: 'LLL yyyy',
                },
            },
        },
    ],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        NgIf,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        FileSelectorComponent,
        MatInputModule,
        MatDatepickerModule,
        MatCheckboxModule,
        CheckboxHelperDirective,
        MatListModule,
        NgFor,
        InfoBoxComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        KeyValuePipe,
        TranslatePipe,
    ],
})
export class ParsePayslipComponent extends DialogComponent<PayslipImportDialogData, true, ParsePayslipComponent> implements OnInit {
    customerId!: number;

    hrFileTypeId?: number;
    error = false;
    formats = '.pdf';
    processing = false;
    formGroup: FormGroup<ImportPayslipForm>;
    result?: ParsePayslipDryRunResult;

    constructor(
        @Inject(PayslipUploadService) protected payslipUploadService: PayslipUploadService,
        @Inject(SettingService) protected settingService: SettingService,
        @Inject(TranslateService) protected translate: TranslateService,
        @Inject(MatDialog) protected dialog: MatDialog,
        @Inject(MatDialogRef) ref: MatDialogRef<ParsePayslipComponent>,
        @Inject(MAT_DIALOG_DATA) data: PayslipImportDialogData,
        @Inject(FormBuilder) protected fb: FormBuilder,
    ) {
        data.size = DialogSize.Medium;
        super(ref, data);

        this.customerId = data.customerId;

        const nonNullable: NonNullableFormBuilder = this.fb.nonNullable;
        this.formGroup = this.fb.group<ImportPayslipForm>({
            file: this.fb.control(null, Validators.required),
            month: nonNullable.control(DateTime.now(), Validators.required),
            replace: nonNullable.control(false),
        });
    }

    ngOnInit(): void {
        this.processing = true;
        this.settingService.getValue<number>([ 'customers', this.customerId ], 'payslip.hr_file_type_id').subscribe(async (id) => {
            if (id) {
                this.hrFileTypeId = id;
            } else {
                this.error = true;
                this.formGroup.disable();
            }

            this.processing = false;
        });
    }

    dryRun(controls: ImportPayslipForm): void {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.payslipUploadService.dryRun(this.customerId, this.hrFileTypeId!, {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            file: controls.file.value!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            date: controls.month.value!,
        }).subscribe({
            next: (result) => {
                this.result = result;
            },
            complete: () => {
                this.processing = false;
            },
            error: () => {
                this.processing = false;
            },
        });
    }

    submitFile(dryRun = false): void {
        const controls = this.formGroup.controls;

        this.processing = true;

        if (dryRun) {
            this.dryRun(controls);
        } else {
            this.upload(controls);
        }
    }

    private upload(controls: ImportPayslipForm): void {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.payslipUploadService.uploadFile(this.customerId, this.hrFileTypeId!, {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            file: controls.file.value!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            date: controls.month.value!,
            replace: controls.replace.value,
        })
            .pipe(
                tap(() => this.dialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
                    data: {
                        title: signal(this.translate.t('FILE_WAS_UPLOADED', 'payslip_import')),
                        text: signal(this.translate.t('FILE_UPLOADED_ALERT_TEXT', 'payslip_import')),
                    },
                })),
            )
            .subscribe({
                next: () => this.dialogRef.close(true),
                complete: () => {
                    this.processing = false;
                },
                error: () => {
                    this.processing = false;
                },
            });
    }

    setDate($event: DateTime, datepicker: MatDatepicker<DateTime>): void {
        datepicker.close();
        this.formGroup.controls.month.setValue($event.startOf('month'));
    }
}
