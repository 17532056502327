import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Injectable({
    providedIn: 'root',
})
export class StateClassHookService {
    private lastAddedClass?: string;

    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
        this.uiRouter.transitionService.onSuccess({}, (transition) => {
            console.debug('🪝', 'StateClassHookService.onSuccess', transition);

            if (this.lastAddedClass) {
                document.body.classList.remove(this.lastAddedClass);
            }

            const klass = `state-${transition.to().name?.split('/').slice(2).join('-')}`;
            document.body.classList.add(klass);
            this.lastAddedClass = klass;
        });
    }
}
