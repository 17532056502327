<mat-drawer-container autosize>
    <mat-drawer #matDrawer [mode]="drawerMode" [opened]="drawerOpened">
        <div id="header"></div>

        @for (tab of _tabs; track tab) {
            @if (tab.available | async) {
                <div class="tab"
                     eawPermission
                     [permissions]="tab.permissions"
                     [permissionChildrenFilter]="tab.permissionChildrenFilter"
                     [permissionCheck]="tab.permissionCheck"
                     [somePermissions]="tab.somePermissions"
                     matRipple
                     [uiSref]="tab.info.state"
                     uiSrefActive="active"
                     [class.loading]="checkedTabs !== tabs.length">

                    @if (tab.info.icon) {
                        <mat-icon class="tw-mr-8">{{ tab.info.icon }}</mat-icon>
                    }

                    <span class="title mat-body-1">{{ tab.label | async }}</span>

                    @if (hideGoIcon) {
                        <mat-icon class="go-icon">chevron_right</mat-icon>
                    }
                </div>
            }
        }

        @if (checkedTabs !== tabs.length) {
            <div id="loader">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        }
        <!-- Some space at the bottom to allow for scrolling -->
        <div id="bottom-spacer"></div>
    </mat-drawer>

    @if (!isTesting) {
        <ui-view name="sidenav-view"></ui-view>
    }
</mat-drawer-container>

<div id="content-overlay">
    <button id="toggle-menu-btn" mat-mini-fab (click)="matDrawer.toggle()">
        <mat-icon>{{ matDrawer.opened ? 'menu_open' : 'menu' }}</mat-icon>
    </button>
</div>
