import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { DateTime } from 'luxon';
import { BusinessDate } from '../../shared/utils/business-date';
import { ArrayPaginatedResponse, AssocPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { BalanceType, BalanceTypeResponse } from '../models/balance-type';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { BalanceChange, BalanceChangeResponse } from '../models/balance-change';
import { catchError, forkJoin, map, of } from 'rxjs';
import { expandAllPages } from '../../shared/utils/rxjs/expand-all-pages';
import { mockArrayPaginatedResponse } from '../../../mocks/paginated-response.mock';

interface GetManyFromToOptions extends PaginationOptions {
    from?: DateTime,
    to?: DateTime,
}

interface GetManyDateOptions extends PaginationOptions {
    date?: DateTime,
}

type GetManyOptions = GetManyDateOptions | GetManyFromToOptions;

interface CreateBalanceChangeData {
    business_date: BusinessDate,
    delta: number,
    description?: string,
}

interface UpdateBalanceChangeData {
    business_date: BusinessDate,
    delta: number,
}

interface EmployeesOverviewResponse {
    // Employee ID
    id: number,
    // Name of the employee
    name: string,
    balance_changes: {
        // Balance type code
        type: string,
        delta: string | number,
    }[]
}

export interface CombinedBalanceOverviewItem {
    employeeName: string,
    employeeId: number,
    balanceChanges: {
        balanceType: BalanceType | undefined,
        delta: number,
    }[];
}

@Injectable({
    providedIn: 'root',
})
export class BalanceService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getCombinedOverviewWithTypes(customerId: number, date: DateTime) {
        const types = expandAllPages((typesPagination) => this.getTypes(customerId, typesPagination).pipe(
            catchError((e) => {
                console.error(e);
                return of(mockArrayPaginatedResponse([]));
            }),
            map((response) => {
                return {
                    ...response,
                    data: Object.values(response.data),
                };
            }),
        ), {
            per_page: 40,
        });

        const balances = expandAllPages((pagination) => this.getEmployeesOverview(customerId, { date, ...pagination }), { per_page: 40 });

        return forkJoin([ balances, types ]).pipe(
            map(([ balances, types ]) => {
                const result: CombinedBalanceOverviewItem[] = [];

                balances.forEach((balance) => {
                    result.push({
                        employeeName: balance.name,
                        employeeId: balance.id,
                        balanceChanges: balance.balance_changes.map((change) => {
                            return {
                                balanceType: types.find((type) => type.balanceCode === change.type),
                                delta: parseFloat(String(change.delta)),
                            };
                        }),
                    });
                });

                return {
                    balances,
                    types,
                    combined: result,
                };
            }),
        );
    }

    /**
     * Get balance types
     */
    getTypes(customerId: number, options?: PaginationOptions) {
        return this.http.get<AssocPaginatedResponse<BalanceTypeResponse>>(`/customers/${customerId}/balance_types`, {
            params: formatHttpParams(options),
        }).pipe(
            map((response) => {
                return {
                    ...response,
                    data: Object.entries(response.data).map(([ code, data ]) => {
                        return new BalanceType(code, data);
                    }),
                };
            }),
        );
    }

    getType(customerId: number, balanceCode: string) {
        return expandAllPages((pagination) => this.getTypes(customerId, pagination), { per_page: 50 }).pipe(
            map((res) => {
                return res.find((type) => type.balanceCode === balanceCode);
            }),
        );
    }

    /**
     * Get current value for all the employees at the given date
     */
    getEmployeesOverview(customerId: number, options?: GetManyOptions) {
        return this.http.get<ArrayPaginatedResponse<EmployeesOverviewResponse>>(`/customers/${customerId}/employee_balances`, {
            params: formatHttpParams({
                ...options,
            }, [ 'date', 'from', 'to' ]),
        });
    }

    /**
     * Get balances for the given employee at the given date
     */
    getAllForEmployee(customerId: number, employeeId: number, types: string[], options?: GetManyDateOptions) {
        return this.http.get<Record<string, number>>(`/customers/${customerId}/employees/${employeeId}/balances`, {
            params: formatHttpParams({
                ...options,
                'types[]': types,
                date: options?.date ?? DateTime.now(),
            }, [ 'date' ]),
        });
    }

    getChanges(customerId: number, employeeId: number, balanceTypeCode: string, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<BalanceChangeResponse>>(`/customers/${customerId}/employees/${employeeId}/balances/${balanceTypeCode}`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(BalanceChange));
    }

    createBalanceChange(customerId: number, employeeId: number, balanceCode: string, data: CreateBalanceChangeData) {
        return this.http.post<BalanceChangeResponse>(`/customers/${customerId}/employees/${employeeId}/balances/${balanceCode}`, data).pipe(classifyItem(BalanceChange));
    }

    updateBalanceChange(customerId: number, employeeId: number, balanceCode: string, balanceId: number, data: UpdateBalanceChangeData) {
        return this.http.put<BalanceChangeResponse>(`/customers/${customerId}/employees/${employeeId}/balances/${balanceCode}/changes/${balanceId}`, data).pipe(classifyItem(BalanceChange));
    }

    deleteBalanceChange(customerId: number, employeeId: number, balanceCode: string, balanceId: number) {
        return this.http.delete(`/customers/${customerId}/employees/${employeeId}/balances/${balanceCode}/changes/${balanceId}`);
    }
}
