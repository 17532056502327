// @ts-nocheck
import { module } from 'angular';
const template2 = `<md-dialog>
    <eaw-dialog-header-old heading="'Create variable'"></eaw-dialog-header-old>

    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="newVariableForm">
                <md-input-container class="error-spacer">
                    <label ng-i18next="NAME"></label>
                    <input ng-model="ctrl.name" md-maxlength="100" name="name" ng-maxlength="100" uniq-form-name required>
                    <div ng-messages="newVariableForm.name.$error">
                        <div ng-message="required" ng-i18next="errors:FORM_FIELD_REQUIRED"></div>
                        <div ng-message="maxlength">Name is too long</div>
                    </div>
                </md-input-container>

                <md-input-container class="error-spacer">
                    <label ng-i18next="CODE"></label>
                    <input ng-model="ctrl.code" md-maxlength="100" name="code" ng-maxlength="100" uniq-form-name>
                    <div ng-messages="newVariableForm.code.$error">
                        <div ng-message="maxlength">Code is too long</div>
                    </div>
                </md-input-container>

                <md-input-container class="md-block">
                    <label ng-i18next="DESCRIPTION"></label>
                    <textarea ng-model="ctrl.description" rows="2"></textarea>
                </md-input-container>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
        <md-button form="newVariableForm" class="md-raised md-accent" ng-disabled="newVariableForm.$invalid" ng-click="ctrl.submit()" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

import MlVariable from '../../../../../../../../ai-budgeting/angularjs/shared/ml-variable';

module('eaw.admin').component('adminCustomerMlBucketVariables', {
    template: `<md-tab label="Variables" md-on-select="$bucketVars.getVariables()">
    <md-content>
        <div layout-margin layout="row">
            <md-input-container md-no-float>
                <input placeholder="Search" ng-model="$bucketVars.filterText" ng-change="$bucketVars.filter()" uniq-form-name>
            </md-input-container>

            <md-divider class="tw-ml-20 tw-mr-20"></md-divider>

            <md-button class="new-variable-btn md-icon-button md-accent md-raised" ng-click="$bucketVars.createVariable()">
                <md-icon ng-bind="'add'"></md-icon>
            </md-button>
        </div>

        <table class="table table-striped" ng-show="$bucketVars.bucket.variables.length">
            <thead>
            <tr>
                <th>Uuid</th>
                <th>Name</th>
                <th>Code</th>
                <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="variable in $bucketVars.filteredVariables">
                <td md-highlight-text="$bucketVars.filterText" md-highlight-flags="gi">{{variable.id}}</td>
                <td md-highlight-text="$bucketVars.filterText" md-highlight-flags="gi">{{variable.name}}</td>
                <td md-highlight-text="$bucketVars.filterText" md-highlight-flags="gi">{{variable.code}}</td>
                <td md-highlight-text="$bucketVars.filterText" md-highlight-flags="gi">{{variable.description}}</td>
            </tr>
            </tbody>
        </table>

        <div layout="column" layout-align="center center" class="no-variables" ng-if="!$bucketVars.bucket.variables.length">
            <md-icon ng-class="{'variable-rotate': $bucketVars.gettingVars}" ng-bind="'data_object'" class="s36"></md-icon>
            <span ng-if="$bucketVars.gettingVars">Fetching variables</span>
            <span ng-if="!$bucketVars.gettingVars">This bucket doesn't have any variables</span>
        </div>
    </md-content>
</md-tab>
`,
    bindings: {
        customer: '<',
        bucket: '<',
    },
    controllerAs: '$bucketVars',
    controller: [ 'mlVariableFactory', '$mdDialog', function(mlVariableFactory, $mdDialog) {
        const ctrl = this;

        ctrl.getVariables = () => {
            ctrl.gettingVars = true;
            mlVariableFactory.getAll.query(ctrl.bucket.tags['eaw.customer_id'], ctrl.bucket.id, { per_page: 9999 }).then((resp) => {
                delete ctrl.gettingVars;
                resp.data.forEach((d) => ctrl.bucket.addVariable(new MlVariable(d)));
                ctrl.filter();
            });
        };

        ctrl.filter = () => {
            const filter = ctrl.filterText?.toLowerCase();
            ctrl.filteredVariables = !filter ? ctrl.bucket.variables : ctrl.bucket.variables.filter((b) => {
                return [ 'uuid', 'name', 'description', 'code' ].some((k) => b[k]?.toLowerCase().includes(filter));
            });
        };

        ctrl.createVariable = () => $mdDialog.show({
            template: template2,
            controller: 'createMlVariableDialog',
            controllerAs: 'ctrl',
            bindToController: true,
            locals: {
                customer: ctrl.customer,
                bucket: ctrl.bucket,
            },
        }).then((variable) => {
            ctrl.bucket.addVariable(variable);
        });
    } ],
});
