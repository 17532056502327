<div class="tw-flex tw-flex-row tw-justify-start tw-items-center">
    @switch (approval()?.approved) {
        @case (true) {
            <mat-icon [matTooltip]="('APPROVED' | translate: 'admin' | async) || ''" class="tw-mr-8 approved">
                check
            </mat-icon>
        }
        @case (false) {
            <mat-icon [matTooltip]="('DECLINED' | translate: 'admin' | async) || ''" class="tw-mr-8 denied">
                close
            </mat-icon>
        }
        @default {
            <mat-icon [matTooltip]="('PENDING_STATUS' | translate: 'admin' | async) || ''" class="tw-mr-8 pending">
                pending
            </mat-icon>
        }
    }
    <div class="tw-flex tw-flex-col">
        <div>{{ approval()?.handlerName }}</div>
        @if (cell.column.includeTime) {
            <small>{{ approval()?.updatedAt | DateTime:'DATETIME_MED' }}</small>
        }
    </div>
</div>
