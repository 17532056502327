// @ts-nocheck
import { module } from 'angular';

module('eaw.components').component('eawInputButton', {
    template: `<md-button class="md-icon-button">
    <md-icon ng-bind="$ib.icon"></md-icon>
</md-button>
`,
    bindings: {
        icon: '@',
    },
    require: {
        parent: '^mdInputContainer',
    },
    controllerAs: '$ib',
});
