import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { User, UserResponse } from './user';
import { Customer, CustomerResponse } from './customer';
import { Warning, WarningResponse } from './warning';
import { CountryRegion, CountryRegionResponse } from './country-region';
import { Role, RoleResponse } from '../../leader-roles/shared/types/role';
import { ShiftSwapResponse } from '../../scheduling/interfaces/shift-swap-response';
import { ShiftSwap } from '../../scheduling/models/shift-swap';
import { Shift, ShiftResponse } from '../../scheduling/models/shift';
import { HrFile, HrFileResponse } from '../../hr/models/hr-file';
import { Position, PositionResponse } from './position';
import { ApiResponse } from '../interfaces/api-response';
import { BaseApiModel } from './base-api-model';
import { EmployeeTask, EmployeeTaskResponse, EmployeeTaskType } from './employee-task';
import { Timepunch } from '../../payroll/models/timepunch';
import { SignDocumentTask, SignDocumentTaskResponse } from '../../tasks/classes/sign-document-task';
import { BusinessDate } from '../utils/business-date';
import { BusinessDateString } from '../types/business-date';
import { Qualification, QualificationResponse } from './qualification';
import { Contract, ContractResponse } from './contract';
import { ApiModel } from '../enums/api-model';

export type RequiredEmployeeField = 'user.access_from' | 'user.access_to' | 'employee.first_name' | 'employee.last_name' | 'employee.nickname' | 'employee.gender' | 'employee.language_code' | 'employee.address_1' | 'employee.address_2' | 'employee.postal_code' | 'employee.city' | 'employee.country_key' | 'employee.region' | 'employee.nationality' | 'employee.dial_code' | 'employee.phone' | 'employee.birth_date' | 'employee.from' | 'employee.to' | 'employee.number' | 'employee.extra_seniority' | 'payrate' | 'contract' | 'usergroup' | 'default_hr_files' | 'position';

export type EmployeeGender = 'female' | 'male' | 'other';

/**
 * The employee response has more or less all fields as optional since the API checks permissions for what fields the user can read.
 */
export interface EmployeeResponse extends ApiResponse {
    positions?: PositionResponse[];
    address1?: string;
    address2?: string;
    birth_date?: BusinessDateString | null;
    city?: string | null;
    country_key?: string | null;
    created_at?: string | null;
    customer_id: number;
    email?: string | null;
    extra_seniority?: number;
    from?: string | null;
    gender?: EmployeeGender;
    id: number;
    /**
     * @deprecated
     * Use **first_name** and **last_name** instead
     */
    name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    nickname?: string | null;
    nationality?: string | null;
    number: number | string;
    phone?: string | null;
    postal_code?: string | null;
    region_id?: number | null;
    role_id?: number | null;
    role?: RoleResponse | null;
    region?: CountryRegionResponse;
    seniority?: number;
    tasks?: EmployeeTaskResponse[];
    to?: string | null;
    updated_at?: string | null;
    user_id?: number | null;
    user?: UserResponse;
    customer?: CustomerResponse;
    warnings?: WarningResponse[];
    warnings_count?: number;
    shifts?: ShiftResponse[];
    active_swaps_to?: ShiftSwapResponse[];
    hr_files?: HrFileResponse[];
    qualifications?: QualificationResponse[];
    contracts?: ContractResponse[];
}

export class Employee extends BaseApiModel<EmployeeResponse, Employee> {
    address1?: string | null;
    address2?: string | null;
    birthDate?: BusinessDate | null;
    city?: string | null;
    countryKey?: string | null;
    createdAt?: DateTime | null;
    customerId: number;
    email?: string | null;
    extraSeniority?: number;
    from?: DateTime | null;
    gender?: EmployeeGender;
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    nickname: string;
    nationality?: string | null;
    number: number;
    phone?: string | null;
    postalCode?: string | null;
    regionId?: number | null;
    seniority?: number;
    tasks?: EmployeeTask[];
    inactive? = false;
    to?: DateTime | null;
    updatedAt?: DateTime | null;
    userId?: number | null;
    roleId?: number | null;
    user?: User | undefined;
    customer?: Customer | undefined;
    warnings: Warning[];
    warningsCount?: number;
    region?: CountryRegion | undefined;
    role?: Role | undefined;
    shifts?: Shift[];
    activeSwapsTo: ShiftSwap[];
    hrFiles?: HrFile[];
    positions?: Position[];
    timepunches?: Timepunch[];
    qualifications?: Qualification[];
    contracts?: Contract[];

    constructor(data: EmployeeResponse) {
        super(data, ApiModel.Employee);

        this.address1 = data.address1;
        this.address2 = data.address2;
        this.birthDate = data.birth_date ? new BusinessDate(data.birth_date) : null;
        this.city = data.city;
        this.countryKey = data.country_key;
        this.customerId = data.customer_id;
        this.email = data.email?.trim();
        this.extraSeniority = data.extra_seniority;
        this.from = data.from ? stringToDateTime(data.from, true) : null;
        this.to = data.to ? stringToDateTime(data.to, true) : null;
        this.gender = data.gender;
        this.id = data.id;
        this.name = `${data.first_name || ''} ${data.last_name || ''}`.trim() || data.name || '';
        this.firstName = data.first_name || data.name?.split(' ')[0] || '';
        this.lastName = data.last_name || data.name?.split(' ')[1] || '';
        this.nickname = data.nickname || '';
        this.nationality = data.nationality;
        this.number = typeof data.number === 'number' ? data.number : parseInt(data.number);
        this.phone = data.phone;
        this.postalCode = data.postal_code;
        this.regionId = data.region_id;
        this.seniority = data.seniority;
        this.userId = data.user_id;
        this.roleId = data.role_id;
        this.role = data.role ? new Role(data.role) : undefined;
        this.user = data.user ? new User(data.user) : undefined;
        this.inactive = !this.isActive;
        this.customer = data.customer ? new Customer(data.customer) : undefined;
        this.warnings = data.warnings ? data.warnings.map((w) => new Warning(w)) : [];
        this.warningsCount = data.warnings_count;
        this.region = data.region ? new CountryRegion(data.region) : undefined;
        this.shifts = data.shifts ? data.shifts.map((s) => new Shift(s)) : [];
        this.activeSwapsTo = data.active_swaps_to?.length ? data.active_swaps_to.map((x) => new ShiftSwap(x)) : [];
        this.hrFiles = data.hr_files?.map((f) => new HrFile(f));
        this.qualifications = data.qualifications?.map((q) => new Qualification(q));
        this.positions = data.positions?.map((p) => new Position(p));
        this.contracts = data.contracts?.map((c) => new Contract(c));
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;

        this.tasks = data.tasks?.map((t) => {
            switch (t.type) {
                case EmployeeTaskType.SignDocument:
                    return new SignDocumentTask(t as SignDocumentTaskResponse);
                case EmployeeTaskType.TermsOfService:
                default:
                    return new EmployeeTask(t);
            }
        });
    }

    get isActive() {
        if (this.from == null) {
            return true;
        }

        const now = DateTime.now();
        const fakeTo = this.to || now.plus({ year: 1 });
        return now >= this.from && now <= fakeTo;
    }

    isExternal(customerId: number) {
        return customerId !== this.customerId;
    }

    getActiveContract(date: DateTime) {
        return this.contracts?.find((c) => c.isActive(date));
    }

    static possibleRequiredFields(): RequiredEmployeeField[] {
        return [
            'user.access_from',
            'user.access_to',
            'employee.first_name',
            'employee.last_name',
            'employee.nickname',
            'employee.gender',
            'employee.language_code',
            'employee.address_1',
            'employee.address_2',
            'employee.postal_code',
            'employee.city',
            'employee.country_key',
            'employee.region',
            'employee.nationality',
            'employee.dial_code',
            'employee.phone',
            'employee.birth_date',
            'employee.from',
            'employee.to',
            'employee.number',
            'employee.extra_seniority',
            'payrate',
            'contract',
            'usergroup',
            'default_hr_files',
            'position',
        ];
    }
}
