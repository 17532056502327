// @ts-nocheck
import { module } from 'angular';

module('eaw.company').component('employeeInfoMenuHeader', {
    template: `<eaw-profile-picture-old user="$ctrl.employee.user" size="60" square="true"></eaw-profile-picture-old>

<div id="names" layout="column" ng-if="$ctrl.employee">
    <span class="md-body-1" ng-bind="$ctrl.employee.name"></span>
    <small ng-bind="$ctrl.employee.customer.name"></small>
</div>
`,
    bindings: {
        employee: '<',
    },
});
