import { booleanAttribute, Component, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { MatSelectionListChange, MatListModule } from '@angular/material/list';
import { CustomFieldsGroup } from '../../../shared/utils/custom-fields-group';
import { BusinessDate } from '../../../shared/utils/business-date';
import { ModelCustomField } from '../../models/model-custom-field';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DateTime } from 'luxon';
import { CustomFieldValue } from '../../typings/custom-field-value';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

export type CustomFieldControl = FormGroup<{
    customField: FormControl<ModelCustomField | null>,
    value: FormControl<CustomFieldValue | DateTime>,
    from: FormControl<DateTime | null>,
    to: FormControl<DateTime | null>,
}>;

@Component({
    selector: 'eaw-custom-field-input',
    templateUrl: './custom-field-input.component.html',
    styleUrl: './custom-field-input.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatListModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
/**
 * Component to display a custom field in a form.
 *
 * See [how to use in a form]{@link https://easyatwork.slite.com/app/docs/yVbXCbtnEj4zio#94ae7058}
 */
export class CustomFieldInputComponent implements OnInit, OnDestroy {
    // The custom field to display
    @Input({ required: true }) customField!: ModelCustomField;
    // A group for the custom fields in the parent form
    @Input({ required: true }) formGroup!: CustomFieldsGroup;
    // How to display a boolean value
    @Input() booleanDisplay: 'slide-toggle' | 'list' = 'slide-toggle';
    // How to display a select value
    @Input() selectDisplay: 'select' | 'list' = 'select';
    // Don't use interval for the custom field
    @Input({ transform: booleanAttribute }) skipInterval = false;

    protected resizeObserver?: ResizeObserver;
    protected rows = 3;

    // Form that is used to handle the value of the custom field inside this component
    form: CustomFieldControl = new FormGroup({
        customField: new FormControl<ModelCustomField | null>(null),
        value: new FormControl<CustomFieldValue | DateTime>(null),
        from: new FormControl<DateTime | null>(null),
        to: new FormControl<DateTime | null>(null),
    });

    constructor(
        @Inject(ElementRef) private readonly elementRef: ElementRef,
    ) {
    }

    ngOnInit() {
        if (this.skipInterval) {
            this.rows = 3;
        } else {
            this.resizeObserver = new ResizeObserver((entries) => {
                const entry = entries[0];
                if (entry) {
                    const width = entry.contentRect.width;
                    if (width > 700) {
                        this.rows = 1;
                    }
                    if (width < 700 && width > 500) {
                        this.rows = 2;
                    }
                    if (width < 500) {
                        this.rows = 3;
                    }
                }
            });

            this.resizeObserver.observe(this.elementRef.nativeElement);
        }

        if (this.customField.required) {
            // Skip required validation for boolean fields if the booleanDisplay "slide-toggle" since the toggle can't be empty
            if (!(this.customField.type === 'boolean' && this.booleanDisplay === 'slide-toggle')) {
                this.form.controls.value.setValidators(Validators.required);
            }

            if (this.hasInterval) {
                this.form.controls.from.setValidators(Validators.required);
            }
        }

        // Add form control to the parent form
        this.formGroup.addControl(this.customField.key, this.form, { emitEvent: false });

        this.form.valueChanges.subscribe((value) => {
            // Clone the field to avoid mutations and check if it is modified
            const cf = this.customField.clone();
            cf.value = value.value;

            this.updateFromValidation(cf);
            this.updateModified(cf);
        });

        this.patch();

        // Mark the form as touched so errors and required inputs are highlighted
        this.form.markAllAsTouched();
    }

    ngOnDestroy() {
        // Remove the form control from the parent form
        this.formGroup.removeControl(this.customField.key);
        this.resizeObserver?.disconnect();
    }

    get hasInterval() {
        return !this.skipInterval && this.customField.hasInterval;
    }

    updateModified(cf: ModelCustomField) {
        const controls = this.form.controls;
        if (cf.isModified || (this.hasInterval && (controls.from.value !== cf.from || controls.to.value !== cf.to))) {
            this.formGroup.addModifiedField(cf.key);
        } else {
            this.formGroup.removeModifiedField(cf.key);
        }
    }

    updateFromValidation(cf: ModelCustomField) {
        if (this.hasInterval && !this.customField.required) {
            const from = this.form.controls.from;

            if (cf.value != null && cf.value !== '') {
                from.setValidators(Validators.required);
            } else {
                from.clearValidators();
            }

            from.markAsTouched();
            from.updateValueAndValidity({ emitEvent: false });
        }
    }

    patch() {
        this.form.patchValue({
            customField: this.customField.clone(),
            value: this.customField.value instanceof BusinessDate ? this.customField.value.dateTime : this.customField.value,
            from: this.customField.from,
            to: this.customField.to,
        });

        this.form.markAsTouched();
        this.form.updateValueAndValidity({ emitEvent: false });
    }

    selectionChange(val: MatSelectionListChange) {
        const option = val.options[0];
        if (option) {
            this.form.controls.value.setValue(option.value);
        }
    }
}
