import { stringToDateTime } from '../utils/string-to-date-time';
import { DateTime } from 'luxon';
import { ApiResponse } from '../interfaces/api-response';

export interface AlertsResponse extends ApiResponse {
    total: number;
    id: number,
    customer_id: number,
    subject_type: string,
    subject_id: number,
    alert_type: string,
    alert_category: string,
    alert_identity: string,
    message: string,
    specifics?: {
        medical_visit_expiration_date?: string,
        employee_name: string,
    }
}

export class Alert {
    total: number;
    id: number;
    customer_id: number;
    subject_type: string;
    subject_id: number;
    alert_type: string;
    alert_category: string;
    alert_identity: string;
    message: string;
    specifics?: {
        medical_visit_expiration_date?: DateTime,
        employee_name: string,
    };

    constructor(data: AlertsResponse) {
        this.total = data.total;
        this.id = data.id;
        this.customer_id = data.customer_id;
        this.subject_type = data.subject_type;
        this.subject_id = data.subject_id;
        this.alert_type = data.alert_type;
        this.alert_category = data.alert_category;
        this.alert_identity = data.alert_identity;
        this.message = data.message;
        this.specifics = {
            medical_visit_expiration_date: data.specifics?.medical_visit_expiration_date ? stringToDateTime(data.specifics.medical_visit_expiration_date) : undefined,
            employee_name: data.specifics?.employee_name || '',
        };
    }
}
