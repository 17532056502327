// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';

import { ElementInitialization } from '../../../../../shared/angularjs/modules/misc/services/element-initialization.service';
import { t } from 'i18next';

module('eaw.dashboard').component('myShiftCalendar', {
    template: `<md-content id="my-shift-calendar" class="my-shift-calendar">
    <md-calendar md-min-date="$shiftCal.shiftsWidget.minDate"
                 md-max-date="$shiftCal.shiftsWidget.maxDate"
                 class="shifts-widget-calendar"
                 ng-model="$shiftCal.date"
                 ng-change="$shiftCal.dateChanged()">
    </md-calendar>
</md-content>

<md-content class="calendar-sub-content">
    <md-subheader>
        <span ng-bind="$shiftCal.momentDate | moment:'LL'" flex></span>
    </md-subheader>

    <div layout="column" layout-padding ng-if="!$shiftCal.itemsOnDate">
        <span ng-i18next="widgets:NO_SHIFTS_TEXT"></span>
    </div>

    <md-list class="md-dense tw-p-0" ng-if="$shiftCal.dateApprovedAbsences.length">
        <md-list-item ng-repeat="absence in $shiftCal.dateApprovedAbsences track by absence.id" class="md-2-line">
            <div class="md-list-item-text" layout="column">
                <h3>
                    <span ng-i18next="widgets:APPROVED_ABSENCE"></span>
                    <span>({{absence.type.name | eawTranslate: 'absence_types'}})</span>
                </h3>
                <p ng-i18next="[i18next]({from: absence._originalFrom.format('LLL'), to: absence.to.format('LLL')})FROM_TO"></p>
            </div>
        </md-list-item>
    </md-list>

    <md-list class="md-dense tw-p-0" ng-if="$shiftCal.dateUnapprovedAbsences.length">
        <md-list-item ng-repeat="absence in $shiftCal.dateUnapprovedAbsences track by absence.id" class="md-2-line">
            <div class="md-list-item-text" layout="column">
                <h3>
                    <span ng-i18next="widgets:UNAPPROVED_ABSENCE"></span>
                    <span>({{absence.type.name | eawTranslate: 'absence_types'}})</span>
                </h3>
                <p ng-i18next="[i18next]({from: absence._originalFrom.format('LLL'), to: absence.to.format('LLL')})FROM_TO"></p>
            </div>
        </md-list-item>
    </md-list>

    <md-list class="md-dense tw-p-0" ng-if="$shiftCal.dateShifts.length">
        <md-list-item ng-repeat="shift in $shiftCal.dateShifts track by shift.id" class="md-3-line">
            <div class="md-list-item-text" layout="column">
                <h3>
                    <span ng-i18next="SHIFT"></span>
                    <span>({{shift.schedule.name}})</span>
                </h3>

                <h4 ng-bind="shift.schedule.customer.name"></h4>
                <p ng-class="shift.sameBusinessDate ? '' : 'required'">
                    {{shift.fromToText}}
                    <md-tooltip ng-if="!shift.sameBusinessDate">{{shift.tooltip}}</md-tooltip>
                </p>
            </div>
        </md-list-item>
    </md-list>
</md-content>
`,
    require: {
        shiftsWidget: '^shiftsWidget',
    },
    controllerAs: '$shiftCal',
    controller: [ '$element', function($element) {
        const ctrl = this;

        ctrl.$postLink = () => {
            ctrl.el = $element[0];
            ctrl.momentDate = moment();
            ctrl.date = ctrl.momentDate.clone().toDate();
            ctrl.dateProperty = ctrl.shiftsWidget.displayBusinessDateInCalendar ? 'business_date' : 'from';

            ctrl.elInit = new ElementInitialization('#my-shift-calendar md-calendar table td', ctrl.el);
            ctrl.elInit.observe().then(() => {
                ctrl.setColors();
                ctrl.dateChanged();
            });
        };

        ctrl.dateChanged = () => {
            ctrl.momentDate = moment([ ctrl.date.getFullYear(), ctrl.date.getMonth(), ctrl.date.getDate() ]);

            ctrl.dateShifts = ctrl.shiftsWidget.shifts?.filter((s) => s[ctrl.dateProperty].isSame(ctrl.momentDate, 'd'));
            ctrl.dateShifts?.forEach((shift) => {
                shift.sameBusinessDate = shift.from.isSame(shift.business_date, 'date');
                shift.fromToText = t('FROM_TO', {
                    from: shift.from.format('LT'),
                    to: shift.to.format('LT'),
                });
                shift.tooltip = t('FROM_TO', {
                    from: shift.from.format('LL - LT'),
                    to: shift.to.format('LL - LT'),
                });
            });

            ctrl.dateApprovedAbsences = ctrl.shiftsWidget.approvedAbsences?.filter((ab) => ab.from.isSame(ctrl.momentDate, 'd'));
            ctrl.dateUnapprovedAbsences = ctrl.shiftsWidget.unapprovedAbsences?.filter((ab) => ab.from.isSame(ctrl.momentDate, 'd'));

            ctrl.itemsOnDate = ctrl.dateShifts?.length || 0
                + ctrl.dateApprovedAbsences?.length || 0
                + ctrl.dateUnapprovedAbsences?.length || 0;
        };

        ctrl.setColors = () => {
            ctrl.shiftsWidget.shifts.forEach((shift) => {
                ctrl.getCalendarDateElement(shift[ctrl.dateProperty])?.classList.add('has-shift');
            });

            ctrl.shiftsWidget.approvedAbsences.forEach((absence) => {
                ctrl.getCalendarDateElement(absence.from)?.classList.add('has-approved-absence');
            });

            ctrl.shiftsWidget.unapprovedAbsences.forEach((absence) => {
                ctrl.getCalendarDateElement(absence.from)?.classList.add('has-unapproved-absence');
            });
        };

        ctrl.getCalendarDateElement = (date) => {
            const idFormat = `${date.year()}-${date.month()}-${date.date()}`;
            return ctrl.el.querySelector(`.shifts-widget-calendar [id$="${idFormat}"] span`);
        };
    } ],
});
