<eaw-dialog-header>
    @if (familyMemberId || data.familyMember) {
        <span title>{{ 'UPDATE_FAM_MEM' | translate:'company' | async }}</span>
    } @else {
        <span title>{{ 'ADD_FAM_MEM' | translate:'company' | async }}</span>
    }
</eaw-dialog-header>

<mat-dialog-content>
    @if (loading) {
        <eaw-info-loading size="md"/>
    } @else {
        <form [formGroup]="form" class="tw-grid tw-gap-16 tw-grid-cols-1">
            <mat-form-field>
                <mat-label>{{ 'NAME' | translate | async }}</mat-label>
                <input matInput type="text" formControlName="name">
            </mat-form-field>

            <mat-form-field eawDatePickerOptions>
                <mat-label>{{ 'BIRTH_DATE' | translate:'company' | async }}</mat-label>
                <input formControlName="birthDate" matInput [matDatepicker]="$any(birthDatePicker)" [max]="maxBirthDate">
                <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #birthDatePicker startView="multi-year"></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'RELATIONSHIP' | translate | async }}</mat-label>
                <mat-select formControlName="relationship">
                    <mat-option [value]="null"></mat-option>

                    @for (option of relationshipOptions;track option.value) {
                        <mat-option [value]="option.value">{{ option.translation | async }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            @for (customField of customFields;track customField.id) {
                <eaw-custom-field-input [customField]="customField" [formGroup]="form.controls.customFields"></eaw-custom-field-input>
            }
        </form>
    }
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>

    <eaw-action-button [loading]="processing" [disabled]="loading || form.invalid" (click)="handle()">
        @if (familyMemberId || data.familyMember) {
            {{ 'UPDATE_FAM_MEM' | translate:'company' | async }}
        } @else {
            {{ 'ADD_FAM_MEM' | translate:'company' | async }}
        }
    </eaw-action-button>
</mat-dialog-actions>
