import { ChangeDetectionStrategy, Component, inject, Input, signal } from '@angular/core';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { MeService } from '../../../shared/http/me.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { Namespace } from '../../../shared/enums/namespace';
import { MatCard, MatCardContent } from '@angular/material/card';
import { AdminCreateUpdateUserComponent } from '../../../scheduling/components/admin-create-update-user/admin-create-update-user.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';

@Component({
    selector: 'eaw-admin-user-information',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCard,
        MatCardContent,
        AdminCreateUpdateUserComponent,
        TranslatePipe,
        AsyncPipe,
        ActionButtonComponent,
    ],
    templateUrl: './admin-user-information.component.html',
    styleUrl: './admin-user-information.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUserInformationComponent {
    private readonly meService = inject(MeService);
    private readonly translateService = inject(TranslateService);

    @Input({ required: true }) userId!: number;

    headerButtons = signal([] as PageHeaderButton[]);

    constructor() {
        this.headerButtons.set([
            new PageHeaderButton({
                icon: 'swap_horiz',
                click: this.act.bind(this),
                menuText: signal(this.translateService.t('ACT_AS', Namespace.Admin)),
            }),
        ]);
    }

    act() {
        void this.meService.act(this.userId);
    }
}
