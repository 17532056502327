// @ts-nocheck
import { module } from 'angular';
import { Storage } from '../../shared/utils/storage';

export class EawCalendarViewController {
    Calendar;
    $mdSidenav;

    static get $inject() {
        return [ 'Calendar', '$mdSidenav' ];
    }

    storageCalendarsKey = 'calendar:calendars';
    gettingCalendars;
    customer;
    user;
    calendars;
    displayCalendars;

    constructor(Calendar, $mdSidenav) {
        this.Calendar = Calendar;
        this.$mdSidenav = $mdSidenav;
    }

    async $onInit() {
        await this.getCalendars();
    }

    openSidebar() {
        this.$mdSidenav('calendar-sidenav').open();
    }

    async getCalendars() {
        this.gettingCalendars = true;
        this.calendars = await this.Calendar.getAll({
            customerId: this.customer.id,
            userId: this.user.id,
            subscribed: true,
            per_page: 888,
        });
        const selectedCalendars = await Storage.getItem(this.storageCalendarsKey);
        this.calendars.forEach((c) => {
            c.visible = selectedCalendars?.includes(c.key) ?? true;
            if (c.type === 'periods') {
                c.visible = false;
            }
        });
        await this.updateDisplayCalendars();
    }

    toggleCalendarVisibility(calendar) {
        calendar.visible = !calendar.visible;
        this.updateDisplayCalendars();
    }

    displayThisOnly(calendar) {
        this.calendars.forEach((c) => {
            c.visible = false;
        });
        calendar.visible = true;
        void this.updateDisplayCalendars();
    }

    async updateDisplayCalendars() {
        this.gettingCalendars = false;
        this.displayCalendars = this.calendars?.filter((c) => c.key).filter((c) => c.visible);
        await Storage.setItem(this.storageCalendarsKey, this.displayCalendars.map((c) => c.key));
    }

    removeCalendar(calendar) {
        this.calendars = this.calendars.filter((c) => c.key !== calendar.key);
        void this.updateDisplayCalendars();
    }

    addCalendar(calendar) {
        calendar.visible = true;
        this.calendars.push(calendar);
        void this.updateDisplayCalendars();
    }
}

module('eaw.calendar2').component('eawCalendarView', {
    template: `<md-card>
    <md-card-content layout="row" class="tw-p-0">
        <md-sidenav class="md-sidenav-left mat-elevation-z2" md-component-id="calendar-sidenav" md-is-locked-open="$mdMedia('gt-md')">
            <md-progress-circular ng-show="$cal.gettingCalendars" class="md-progress-center tw-mt-20"></md-progress-circular>
            <eaw-calendar-view-sidenav ng-show="!$cal.gettingCalendars"></eaw-calendar-view-sidenav>
        </md-sidenav>

        <eaw-calendar2
                customer="$cal.customer.id"
                user="$cal.user"
                calendars="$cal.displayCalendars"
                menu-icon="$cal.openSidebar()">
        </eaw-calendar2>
    </md-card-content>
</md-card>

<ui-view name="manage-calendar"></ui-view>
`,
    controllerAs: '$cal',
    bindings: {
        user: '<',
        customer: '<', // Object
    },
    controller: EawCalendarViewController,
});
