import { inject, Injectable, signal } from '@angular/core';
import { PermissionCheckService } from '../../shared/services/permission-check.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LearningModuleSidebarDialogComponent } from '../components/learning-module-sidebar-dialog/learning-module-sidebar-dialog.component';
import { UIRouter } from '@uirouter/core';
import { SanityService } from 'tutorial-lib';

@Injectable({
    providedIn: 'root',
})
export class LearningModuleService {
    /**
     * The resolved permissions after being processed by us
     */
    private resolvedPermissions?: Record<string, boolean>;
    /**
     * The permissions that are stored in Sanity
     */
    private sanityPermissions?: string[];
    private uiRouter = inject(UIRouter);
    private matDialog = inject(MatDialog);
    private sanityService = inject(SanityService);
    private permissionCheckService = inject(PermissionCheckService);

    private toggledSubject = new BehaviorSubject<boolean>(false);
    hasBeenToggled = signal(false);

    async setPermissions(): Promise<Record<string, boolean>> {
        if (this.resolvedPermissions) {
            return this.resolvedPermissions;
        }

        const resolvedPermissions: Record<string, boolean> = {};

        try {
            this.sanityPermissions ||= (await this.sanityService.getAllPermissions()).map((p) => p.name);
        } catch (_) {
            return resolvedPermissions;
        }

        try {
            await firstValueFrom(this.permissionCheckService.isAllowedMany(this.sanityPermissions));
        } catch (_) {
            return resolvedPermissions;
        }

        this.sanityPermissions.forEach((p) => (resolvedPermissions[p] = this.permissionCheckService.single(p)));
        this.sanityService.setUserPermissionMap(resolvedPermissions);
        this.resolvedPermissions = { ...resolvedPermissions };
        return resolvedPermissions;
    }

    getModules() {
        return Array.from(this.uiRouter.globals.$current.path.reduce((acc, state) => {
            if (typeof state.data === 'object' && 'learningModuleModules' in state.data && Array.isArray(state.data.learningModuleModules)) {
                (state.data.learningModuleModules as string[]).forEach((module) => acc.add(module));
            }

            return acc;
        }, new Set<string>));
    }

    onToggle() {
        return this.toggledSubject.asObservable();
    }

    toggle(useDialog: boolean) {
        if (useDialog) {
            this.matDialog.open(LearningModuleSidebarDialogComponent);
        } else {
            this.hasBeenToggled.set(true);
            this.toggledSubject.next(!this.toggledSubject.value);
        }
    }

    openPanel() {
        this.hasBeenToggled.set(true);
        this.toggledSubject.next(true);
    }

    closePanel() {
        this.toggledSubject.next(false);
    }

    hasAccess() {
        return this.permissionCheckService.isAllowed('learning_module.beta');
    }
}
