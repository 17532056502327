import type { FileResponse } from '../interfaces/file-response';
import { FsNode } from './fs-node';

export class FsFile extends FsNode {
    // private _parent: Directory | null;
    attachments: any[];

    constructor(data: FileResponse) {
        super(data);
        this.attachments = data.attachments;
    }

    /* set parent(value: Directory | null) {
        this._parent = value;
    } */
}
