import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { classifyArrayPaginatedResponse } from '../../shared/utils/rxjs/classify';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { WeekSetup, WeekSetupResponse } from '../models/week-setup';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class WeekSetupService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, options?: PaginationOptions): Observable<ArrayPaginatedResponse<WeekSetup>> {
        return this.http.get<ArrayPaginatedResponse<WeekSetupResponse>>(`customers/${customerId}/week_setups`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(WeekSetup));
    }
}
