import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Transition } from '@uirouter/angularjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DebugHookService {

    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
        this.uiRouter.transitionService.onError({}, this.errorLog.bind(this));

        // Do not log other transition hooks in production
        if (environment.isProduction) {
            return;
        }

        this.uiRouter.transitionService.onBefore({}, (t) => this.log('onBefore', t));
        this.uiRouter.transitionService.onStart({}, (t) => this.log('onStart', t));
        this.uiRouter.transitionService.onExit({}, (t) => this.log('onExit', t));
        this.uiRouter.transitionService.onRetain({}, (t) => this.log('onRetain', t));
        this.uiRouter.transitionService.onEnter({}, (t) => this.log('onEnter', t));
        this.uiRouter.transitionService.onFinish({}, (t) => this.log('onFinish', t));
        this.uiRouter.transitionService.onSuccess({}, (t) => this.log('onSuccess', t));
    }

    private errorLog(transition: Transition) {
        console.error('Error', transition.error().message, `(${transition.from().name} to ${transition.to().name})`, transition);
    }

    private log(type: string, transition: Transition) {
        console.debug(type, `(${transition.from().name} to ${transition.to().name})`, transition);
    }
}
