// @ts-nocheck
import { module } from 'angular';

module('eaw.admin').controller('createMlTimeSeriesDialog', [ 'mlTimeSeriesFactory', '$mdDialog', function createMlTimeSeriesDialog(mlTimeSeriesFactory, $mdDialog) {
    const ctrl = this;

    ctrl.submit = () => {
        mlTimeSeriesFactory.create.query(ctrl.customer.id, ctrl.bucket.id, {
            name: ctrl.name,
            description: ctrl.description,
            code: ctrl.code,
        }).then(() => {
            $mdDialog.hide();
        });
    };
} ]);
