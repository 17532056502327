// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
module('eaw.leave-shifts').factory('LeaveShiftFactory', [ 'EawResource', 'Pagination', function LeaveShiftFactory(EawResource, Pagination) {
    const url = '/customers/:customer/:employees/:employee/:model/:id/leave_shifts/:leave_shift';
    return {
        getAll(args) {
            return EawResource.create(url).get({
                customer: args.customer_id,
                employees: 'employees',
                employee: args.employee_id,
                model: args.model,
                id: args.model_id,
                ...Pagination.getParams(args, [ 'leave_type' ]),
            });
        },
        getAllForCustomer(args) {
            return EawResource.create(url).get({
                customer: args.customer_id,
                ...Pagination.getParams(args, [ 'leave_type', 'from', 'to' ]),
            });
        },
        getAllForEmployee(args) {
            return EawResource.create(url).get({
                customer: args.customer_id,
                employees: 'employees',
                employee: args.employee_id,
                ...Pagination.getParams(args, [ 'leave_type', 'from', 'to' ]),
            });
        },
        get(args) {
            return EawResource.create(url).get({
                customer: args.customer_id,
                employees: 'employees',
                employee: args.employee_id,
                model: args.model,
                id: args.model_id,
                leave_shift: args.leave_shift_id,
                'with[]': args.with,
            });
        },
        create(args) {
            return EawResource.create(url).save({
                customer: args.customer_id,
                employees: 'employees',
                employee: args.employee_id,
                model: args.model,
                id: args.model_id,
            }, pick(args, [ 'from', 'to', 'business_date', 'break_length', 'break_offset' ]));
        },
        update(args) {
            return EawResource.create(url).update({
                customer: args.customer_id,
                employees: 'employees',
                employee: args.employee_id,
                model: args.model,
                id: args.model_id,
                leave_shift: args.id,
            }, pick(args, [ 'from', 'to', 'break_length', 'break_offset' ]));
        },
        delete(args) {
            return EawResource.create(url).delete({
                customer: args.customer_id,
                employees: 'employees',
                employee: args.employee_id,
                model: args.model,
                id: args.model_id,
                leave_shift: args.id,
            });
        },
    };
} ]);
