import { t } from 'i18next';
import { module } from 'angular';
import { EndlessList } from '../../../../shared/angularjs/modules/classes/endless-list.class';
import { PaginationOptions } from '../../../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../../../shared/interfaces/paginated-response';
import { ShiftPeriodResponse } from '../../../../scheduling/models/shift-period';
import { Comment } from '../../../../shared/models/comment';
import { ShiftPeriodListDialogComponent, ShiftPeriodListDialogData } from '../../../../scheduling/dialogs/shift-period-list-dialog/shift-period-list-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent, CommentDialogData } from '../../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { of } from 'rxjs';

module('eaw.dashboard').component('endlessShifts', {
    template: `<md-virtual-repeat-container md-auto-shrink-min="5" md-auto-shrink>
    <md-list class="md-dense">
        <md-list-item class="md-3-line" md-virtual-repeat="shift in $endless.list" md-on-demand md-item-size="76">
            <div class="md-list-item-text">
                <h3 ng-bind="shift.business_date.format('ll')"></h3>
                <h4 ng-bind="shift.employeeName"></h4>
                <p ng-if="shift.hasUnproductivePeriod" md-colors="{color: 'red'}" ng-i18next="[i18next]({percent: shift.unproductivePercent})scheduling:UNPRODUCTIVE_PERCENT"></p>
            </div>

            <md-button class="md-secondary md-icon-button tw-p-0 tw-m-0" ng-if="shift.comments.length" ng-click="$endless.showComments(shift)">
                <md-icon ng-bind="'comment'"></md-icon>
            </md-button>

            <md-button class="md-secondary md-icon-button tw-p-0 tw-m-0" ng-if="shift.periods.length" ng-click="$endless.displayPeriods(shift)">
                <md-icon ng-bind="'timelapse'"></md-icon>
            </md-button>

            <div class="md-secondary">
                <span ng-class="{required: !shift.from.isSame(shift.business_date, 'd')}"
                      ng-bind="shift.from.format('LT') + ' - ' + shift.to.format('LT')">
                </span>
                <md-tooltip ng-if="!shift.from.isSame(shift.business_date, 'd')" ng-bind="shift.from.format('LL - LT') + ' ' + shift.to.format('LL - LT')"></md-tooltip>
            </div>

            <md-divider ng-if="!$last"></md-divider>
        </md-list-item>
        <md-list-item ng-show="!$endless.list.getLength()">
            <div class="md-list-item-text">
                <p ng-i18next="scheduling:NO_SHIFT_plural"></p>
            </div>
            <md-divider ng-if="!$last"></md-divider>
        </md-list-item>

        <md-progress-circular ng-if="$endless.loading" class="tw-mt-16 md-progress-center"></md-progress-circular>
    </md-list>
</md-virtual-repeat-container>
`,
    controllerAs: '$endless',
    bindings: {
        customerId: '<',
        from: '<',
        to: '<?',
        employeeId: '<?',
        onLoaded: '&?',
        businessDate: '<?',
    },
    controller: [ 'ShiftFactory', '$element', 'MatDialogDowngrade', function(ShiftFactory, $element, MatDialogDowngrade: MatDialog) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.list = new EndlessList(ctrl.getShifts, {
                'with[]': [ 'periods.businessUnit', 'employee', 'schedule', 'comments' ],
                order_by: 'from',
                direction: 'asc',
            });
            ctrl.list.loadPage(1).then(() => {
                ctrl.onLoaded?.({ list: ctrl.list });
                ctrl.loaded = true;
            });
        };

        ctrl.$postLink = () => {
            $element[0].querySelector('.md-virtual-repeat-scroller')?.classList.add('pretty-scroll');
        };

        ctrl.$onDestroy = () => {
            ctrl.shiftsRes?.$cancelRequest?.();
        };

        ctrl.getShifts = (pagination: PaginationOptions) => {
            ctrl.loading = true;
            ctrl.shiftsRes = ShiftFactory.getAll({
                customer_id: ctrl.customerId,
                employee_id: ctrl.employeeId,
                from: ctrl.businessDate ? undefined : ctrl.from,
                to: ctrl.businessDate ? undefined : ctrl.to,
                from_business_date: ctrl.businessDate ? ctrl.businessDate : undefined,
                to_business_date: ctrl.businessDate ? ctrl.businessDate : undefined,
                _businessDates: [ 'from_business_date', 'to_business_date' ],
                ...pagination,
            });

            ctrl.shiftsRes.$promise.then((resp: ArrayPaginatedResponse<any>) => {
                resp.data.forEach((shift) => {
                    shift.hasUnproductivePeriod = shift.periods.some((p: ShiftPeriodResponse) => p.unproductive && !p.break);
                    shift.unproductivePercent = Math.round(shift.periods.reduce((acc: number, p: ShiftPeriodResponse) => acc + (p.unproductive ? p.length : 0), 0) / shift.length * 100);
                    shift.employeeName = shift.employee?.name || t('UNASSIGNED');
                });
            });

            return ctrl.shiftsRes.$promise.finally(() => delete ctrl.loading);
        };

        ctrl.showComments = (shift: any) => {
            MatDialogDowngrade.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
                data: {
                    comments: of(shift.comments.map((c: any) => new Comment(c))),
                },
            });
        };

        ctrl.displayPeriods = (shift: any) => {
            if (!shift.periods.length) {
                return;
            }

            MatDialogDowngrade.open<ShiftPeriodListDialogComponent, ShiftPeriodListDialogData>(ShiftPeriodListDialogComponent, {
                data: {
                    customerId: ctrl.customerId,
                    scheduleId: shift.schedule_id,
                    shiftId: shift.id,
                },
            });
        };
    } ],
});
