import { NgModule } from '@angular/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

// We need a factory, since localStorage is not available during AOT build time.
function storageFactory(): OAuthStorage {
    return localStorage;
}

@NgModule({
    declarations: [],
    imports: [
        OAuthModule.forRoot(),
    ],
    providers: [
        { provide: OAuthStorage, useFactory: storageFactory },
    ],
})
export class AuthModule {
}
