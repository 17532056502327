<div id="header" class="tw-flex tw-items-center tw-gap-16">
    <eaw-profile-picture [user]="profilePictureUser()"></eaw-profile-picture>

    <div class="tw-flex tw-flex-col">
        <small>{{employee()?.name}}</small>
        <div>{{ 'FROM_TO' | translate:'general':{from: tooltipData.shift.from | DateTime:'DATETIME_MED', to: tooltipData.shift.to | DateTime:'TIME_SIMPLE'} | async }}</div>
    </div>
</div>

@if (warnings()?.size) {
    <div class="section warnings">
        <div class="icon">
            <mat-icon>warning</mat-icon>
        </div>

        <div class="content">
            <ul>
                @for (warning of warnings()?.values(); track warning.id) {
                    <li>{{ warning.message | translate:'warnings':warning.messageParameters | async }}</li>
                }
            </ul>
        </div>
    </div>
}

@if (comments().length) {
    <div class="section comments">
        <div class="icon">
            <mat-icon>comment</mat-icon>
        </div>

        <div class="content">
                @for (comment of comments(); track comment.id) {
                    <div class="comment">
                        <blockquote>
                            <span>{{comment.body}}</span>
                        </blockquote>
                        <span class="commenter">—{{comment.userName}}, <cite>{{comment.createdAt | DateTime:'DATETIME_MED'}}</cite></span>
                    </div>
                }
        </div>
    </div>
}

@if (periods().length) {
    <div class="section periods">
        <div class="icon">
            <mat-icon>timelapse</mat-icon>
        </div>

        <div class="content">
            @for (period of periods(); track period.id) {
                <div class="period">
                    <div class="color" [style.background-color]="period.color.toHexString()"></div>

                    <div class="tw-flex tw-flex-col tw-gap-4">
                        <span class="description">{{ period.description || ('NO_DESCRIPTION' | translate | async) }}</span>
                        <span>{{ 'FROM_TO' | translate:'general':{from: period.from | DateTime:'DATETIME_MED', to: period.to | DateTime:'TIME_SIMPLE'} | async }}</span>
                    </div>
                </div>
            }
        </div>
    </div>
}

@if (qualifications().length) {
    <div class="section qualifications">
        <div class="icon">
            <mat-icon>badge</mat-icon>
        </div>

        <div class="content">
            <ul>
                @for (qualification of qualifications(); track qualification.id) {
                    <li>{{ qualification.name }}</li>
                }
            </ul>
        </div>
    </div>
}
