<eaw-subheader>
    <div id="subheader-content">
        <span>{{'DATE' | translate | async}}</span>
        <span class="tw-text-right">{{'CHANGE' | translate | async}}</span>
        <span>{{'DESCRIPTION' | translate | async}}</span>
    </div>
</eaw-subheader>

@if (loadingInitial()) {
    <mat-progress-bar class="tw-absolute" mode="indeterminate"></mat-progress-bar>
}

<cdk-virtual-scroll-viewport itemSize="40" [style.height]="height()">
    <ng-container *cdkVirtualFor="let item of dataSource">
        @if (item) {
            <div class="item" [class.deletable]="!item.locked && deletable()">
                <div>{{ item.businessDate | DateTime: 'DATE_SHORT' }}</div>
                <div class="tw-text-right">{{ formatNumber(item.delta) }}</div>
                <div class="description" [matTooltip]="item.description">{{item.description}}</div>
                @if (!item.locked && deletable()) {
                    <button mat-button color="warn" (click)="delete(item)" [disabled]="deleting.has(item.id)">{{'DELETE' | translate | async}}</button>
                }
            </div>
        } @else {
            <div class="loading"></div>
        }
    </ng-container>
</cdk-virtual-scroll-viewport>
