// @ts-nocheck
import { module } from 'angular';

const localesTemplate = `
<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="LOCALE_plural"></span>
        </md-card-header-text>

        <card-btn-float on-click="locCtrl.addEditLocale()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="locCtrl.table.columns"
                cells="locCtrl.table.cells"
                factory="LocaleService"
                factory-function="getAll"
                reload-table="locCtrl.reloadTable">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;

module('eaw.admin').config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state('eaw/app/admin/locales', {
        parent: 'eaw/app/admin',
        url: '/locales',
        views: {
            'content@': {
                template: localesTemplate,
                controller: 'adminLocaleCtrl',
                controllerAs: 'locCtrl',
            },
        },
        data: {
            breadcrumb: { key: 'LOCALES', ns: 'navigation' },
            permissions: [ `locales.*.get` ],
        },
    });
} ]);
