import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';

interface GetAllOptions extends PaginationOptions {
    registry?: boolean
}

@Injectable({
    providedIn: 'root',
})
export class PropertyValidatorService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(options?: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<string>>('/property_validators', { params: formatHttpParams(options) });
    }
}
