import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { ShiftGroup, ShiftGroupResponse } from '../models/shift-group';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';

interface UpdateShiftGroupData {
    employee_id: number | null;
    with?: string[];
}

@Injectable({
    providedIn: 'root',
})
export class ShiftGroupService {
    private readonly http = inject(HttpClient);

    getAll(customerId: number, scheduleId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<ShiftGroupResponse>>(`/customers/${customerId}/schedules/${scheduleId}/groups`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(ShiftGroup));
    }

    create(customerId: number, scheduleId: number, employeeId: number) {
        return this.http.post<ShiftGroupResponse>(`/customers/${customerId}/schedules/${scheduleId}/groups`, { employee_id: employeeId }).pipe(
            classifyItem(ShiftGroup),
        );
    }

    update(customerId: number, scheduleId: number, groupId: number, data: UpdateShiftGroupData) {
        return this.http.put<ShiftGroupResponse>(`/customers/${customerId}/schedules/${scheduleId}/groups/${groupId}`, data).pipe(
            classifyItem(ShiftGroup),
        );
    }

    copy(customerId: number, scheduleId: number, groupId: number, targetEmployeeId: number | null, withs?: string[]) {
        return this.http.post<ShiftGroupResponse>(`/customers/${customerId}/schedules/${scheduleId}/groups/${groupId}/copy`, {
            employee_id: targetEmployeeId,
            with: withs,
        }).pipe(
            classifyItem(ShiftGroup),
        );
    }

    delete(customerId: number, scheduleId: number, groupId: number) {
        return this.http.delete(`/customers/${customerId}/schedules/${scheduleId}/groups/${groupId}`);
    }
}
