import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomFieldValueDialogComponent, CustomFieldDialogReturn } from '../dialogs/custom-field-value-dialog/custom-field-value-dialog.component';
import { DialogData } from '../../shared/dialogs/dialog-component';
import type { DateTime } from 'luxon';
import { CustomFieldHistoryDialogComponent } from '../dialogs/custom-field-history-dialog/custom-field-history-dialog.component';
import { ConfirmDialogService } from '../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../shared/services/translate.service';
import { EMPTY, switchMap, tap } from 'rxjs';
import { EmployeePropertyService } from '../../shared/http/employee-property.service';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { ModelCustomField } from '../models/model-custom-field';

export interface CustomFieldDialogData extends DialogData {
    mode: 'add' | 'edit';
    minDate?: DateTime;
    maxDate?: DateTime;
    customerId: number;
    employeeId: number;
    customField: ModelCustomField;
}

export interface CustomFieldHistoryDialogData extends DialogData {
    customerId: number;
    employeeId: number;
    customField: ModelCustomField;
}

@Injectable({
    providedIn: 'root',
})
export class CustomFieldDialogService {
    private readonly matDialog = inject(MatDialog);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly translate = inject(TranslateService);
    private readonly employeePropertyService = inject(EmployeePropertyService);
    private readonly snackBarService = inject(SnackBarService);

    manage(mode: 'add' | 'edit', customerId: number, employeeId: number, customField: ModelCustomField) {
        return this.matDialog.open<CustomFieldValueDialogComponent, CustomFieldDialogData, CustomFieldDialogReturn>(CustomFieldValueDialogComponent, {
            data: {
                mode,
                customField: mode === 'edit' ? customField.clone() : new ModelCustomField({ ...customField.response, value: null, from: null, to: null }),
                customerId,
                employeeId,
            },
        });
    }

    /**
     * @return {MatDialogRef<CustomFieldHistoryDialogComponent, boolean>} A boolean indicating whether any changes were made
     */
    history(customerId: number, employeeId: number, customField: ModelCustomField): MatDialogRef<CustomFieldHistoryDialogComponent, boolean> {
        return this.matDialog.open<CustomFieldHistoryDialogComponent, CustomFieldHistoryDialogData, boolean>(CustomFieldHistoryDialogComponent, {
            data: {
                customerId,
                employeeId,
                customField,
            },
        });
    }

    delete(customerId: number, employeeId: number, customField: ModelCustomField) {
        return this.confirmDialogService.delete({
            title: this.translate.t('DELETE'),
            text: this.translate.t('DELETE_CUSTOM_FIELD', 'company', {
                fieldName: '$t(custom_fields:' + customField.name + ')',
            }),
            confirmText: this.translate.t('DELETE'),
        }).afterClosed().pipe(
            switchMap((result) => {
                if (!result?.ok) {
                    return EMPTY;
                }

                return this.employeePropertyService.delete(customerId, employeeId, customField.key, customField.from);
            }),
            tap(() => {
                this.snackBarService.t('DELETED');
            }),
        );
    }
}
