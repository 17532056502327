<ng-content select="[header]"></ng-content>
<table class="table table-bordered sticky-header sticky-left">
    <thead>
    <tr>
        <th></th>

        @for (item of computedItems; track item.id) {
            <th class="overflow-ellipsis hover-item hover-highlight"
                [class.highlight-primary]="item.hover"
                [class.display-none]="item.hide"
                (click)="sortItems(item)"
                [matTooltip]="item.name">
                <span>{{ item.name }}</span>
            </th>
        }
    </tr>
    </thead>

    <tbody>
        @for (person of computedPeople; track person.id) {
            <tr>
                <td class="overflow-ellipsis hover-item hover-highlight" [matTooltip]="person.name" [class.highlight-primary]="person.hover" (click)="sortPeople(person)">
                    <span>{{ person.name }}</span>
                </td>

                @for (item of computedItems; track item.id) {
                    <td class="quick-cell"
                        (mouseenter)="item.hover=true;person.hover=true;"
                        (mouseleave)="item.hover=false;person.hover=false;"
                        (click)="toggleHandler(item.item, person.person)"
                        [matTooltip]="lastDayText!"
                        matTooltipShowDelay="500"
                        [matTooltipDisabled]="!person.items[item.id]?.last_day"
                        [class.display-none]="item.hide"
                        [class.highlight-red]="person.items && person.items[item.id] && !person.items[item.id]?.last_day"
                        [class.highlight-green]="!person.items! || !person.items[item.id]">

                        @if (person.items && person.items[item.id] && !person.items[item.id]?.last_day) {
                            <mat-icon>remove</mat-icon>
                        }

                        @if (!person.items || !person.items[item.id]) {
                            <mat-icon>add</mat-icon>
                        }

                        @if (person.items && person.items[item.id]) {
                            <mat-icon class="done" [class.last-day]="person.items[item.id]?.last_day" [class.hide-highlight]="!person.items[item.id]?.last_day">done</mat-icon>
                        }
                    </td>
                }
            </tr>
        }
    </tbody>
</table>
