<eaw-dialog-header>
    <span title>{{title.i18n | translate: title.ns | async}}</span>
    <span subtitle>{{employee?.name}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="!employee" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content *ngIf="employee" class="tw-flex tw-flex-col">
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-16">
        <eaw-rotation-autocomplete controlName="rotation" [customerId]="employee.customerId"></eaw-rotation-autocomplete>

        <mat-form-field eawDatePickerOptions>
            <mat-label>{{'CONNECTION_RANGE' | translate:'rotation' | async}}</mat-label>
            <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker" [disabled]="saving">
                <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async">
                <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-raised-button mat-dialog-close="">{{'CANCEL' | translate | async}}</button>
    <button mat-button mat-raised-button color="primary" (click)="save()" [disabled]="saving || form.invalid">{{confirmText | translate | async}}</button>
</mat-dialog-actions>
