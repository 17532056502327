import { ChangeDetectionStrategy, Component, computed, Input, input, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { Comment } from '../../models/comment';
import { catchError, Observable, of } from 'rxjs';
import { DateTimePipe } from '../../pipes/date-time.pipe';
import { MaterialColorDirective } from '../../directives/material-color.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { AsyncPipe } from '@angular/common';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';
import { SignalInput } from '../../decorators/signal-input.decorator';

type CommentItem = {
    comment: Comment,
    deleting: WritableSignal<boolean>,
    canDelete: Observable<boolean>,
}

@Component({
    selector: 'eaw-comment-list',
    templateUrl: './comment-list.component.html',
    styleUrl: './comment-list.component.scss',
    standalone: true,
    imports: [
        ProfilePictureComponent,
        MatButtonModule,
        MatIconModule,
        MaterialColorDirective,
        AsyncPipe,
        DateTimePipe,
        PermissionDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentListComponent implements OnInit {
    /**
     * @deprecated - Only used in AngularJS
     */
    @Input() @SignalInput() angularjscomments?: Signal<Comment[]>;

    comments = input<Comment[]>([]);
    canDelete = input<(id: number) => Observable<boolean>>();
    deleteCallback = input<(id: number) => Observable<void>>();

    private internalComments = signal<Comment[]>([]);

    commentItems = computed(() => {
        return this.internalComments().map((c) => {
            return {
                comment: c,
                deleting: signal(false),
                canDelete: this.canDelete()?.(c.id) || of(false),
            };
        });
    });

    ngOnInit() {
        if (this.angularjscomments) {
            this.internalComments.set([ ...this.angularjscomments() ]);
        } else {
            this.internalComments.set([ ...this.comments() ]);
        }
    }

    delete(item: CommentItem) {
        item.deleting.set(true);
        this.deleteCallback()?.(item.comment.id).pipe(
            catchError((e) => {
                item.deleting.set(false);
                return e;
            }),
        ).subscribe();
    }
}
