import { Component, Inject, OnInit } from '@angular/core';
import { CurrentService } from '../../../shared/services/current.service';
import { Setting } from '../../../shared/models/setting';
import { TranslateService } from '../../../shared/services/translate.service';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { groupBy } from 'lodash-es';
import { SettingService } from '../../../shared/http/setting.service';
import { map, Observable } from 'rxjs';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { EditSettingValueDialogService } from '../../../shared/services/edit-setting-value-dialog.service';
import { CustomerPropertyService } from '../../../shared/http/customer-property.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { PropertyValueDisplayComponent } from '../../../shared/components/property-value-displayer/property-value-display.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { KeyValueDialogReturn } from '../../../shared/dialogs/key-value-dialog/key-value-dialog.component';

type GroupItem = {
    setting: Setting,
    loading?: boolean,
    disabled?: Observable<boolean>
};

type ListGroup = {
    translatedName: string,
    name: string,
    items: GroupItem[]
};

@Component({
    selector: 'eaw-company-settings',
    templateUrl: './company-settings.component.html',
    styleUrl: './company-settings.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatProgressSpinnerModule,
        MatExpansionModule,
        NgFor,
        PropertyValueDisplayComponent,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class CompanySettingsComponent implements OnInit {
    readonly groupsToFetch = [ 'general', 'general_scheduling', 'paid_time', 'parent_company', 'payroll' ];

    groups: ListGroup[] = [];
    loading = true;

    constructor(
        @Inject(SettingService) private settingService: SettingService,
        @Inject(CustomerPropertyService) private customerPropertyService: CustomerPropertyService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(EditSettingValueDialogService) private editSettingValueDialogService: EditSettingValueDialogService,
    ) {
    }

    ngOnInit(): void {
        this.settingService.getSome([ 'customers', this.current.getCustomer().id ], {
            'groups[]': this.groupsToFetch,
        }).subscribe(async (settings) => {
            // Translate the group names but ensure "general" stays first
            for await (const group of Object.entries(groupBy(settings.filter((s) => s.group), 'group'))) {
                const [ name, settings ] = group;
                const translatedName = await this.translate.t(name, 'settings');
                const customerId = this.current.getCustomer().id;

                this.groups.push({
                    translatedName,
                    name,
                    items: settings.map((s) => {
                        return {
                            setting: s,
                            disabled: this.permissionCheckService.isAllowed(`customers.${customerId}.properties.${s.key}.update`).pipe(map((x) => !x)),
                        };
                    }),
                });
            }

            this.groups = [
                ...this.groups.filter((g) => g.name === 'general'),
                ...sort(this.groups.filter((g) => g.name !== 'general'), this.current.languageTag, [ ((x) => x.translatedName) ]),
            ];

            this.loading = false;
        });
    }

    edit(item: GroupItem) {
        if (item.setting.dataType === 'object') {
            this.editSettingValueDialogService.openKeyValue(item.setting).subscribe((res) => {
                if (res != null) {
                    this.update(item, res);
                }
            });

            return;
        } else {
            this.editSettingValueDialogService.open(item.setting).subscribe((res) => {
                if (res != null) {
                    this.update(item, res);
                }
            });
        }
    }

    update(item: GroupItem, value: string | KeyValueDialogReturn) {
        item.loading = true;
        this.settingService.update([ 'customers', this.current.getCustomer().id ], item.setting.key, value).subscribe(() => {
            // Get settings after updating and find the one we updated and splice it in place of the old one
            this.settingService.getSome([ 'customers', this.current.getCustomer().id ], {
                'groups[]': item.setting.group ? [ item.setting.group ] : this.groupsToFetch,
            }).subscribe((res) => {
                const updatedSetting = res.find((x) => x.key === item.setting.key);
                if (!updatedSetting) {
                    return;
                }

                const group = this.groups.find((g) => g.name === updatedSetting.group);
                if (!group) {
                    return;
                }

                const index = group.items.findIndex((s) => s.setting.key === item.setting.key);
                group.items.splice(index, 1, {
                    setting: updatedSetting,
                    disabled: this.permissionCheckService.isAllowed(`customers.${this.current.getCustomer().id}.properties.${updatedSetting.key}.update`).pipe(map((x) => !x)),
                });
            });
        });
    }

    reset(item: GroupItem) {
        item.loading = true;
        this.customerPropertyService.delete(this.current.getCustomer().id, item.setting.key).subscribe(() => {
            item.loading = false;
            item.setting.value = item.setting.inheritedValue;
            item.setting.setValue = null;
        });
    }
}
