import { Products } from '../enums/products';
import { NamespaceFile } from '../enums/namespace';
import { Resolvable } from '../types/resolvable';
import { ComponentType } from '@angular/cdk/overlay';
import { NavTabsComponent } from '../components/nav-tabs/nav-tabs.component';
import { Observable } from 'rxjs';
import { PropertyValueDecoder } from './property-value-decoder';
import type { PermissionCheckService } from '../services/permission-check.service';
import { Transition } from '@uirouter/angularjs';
import { ParamDeclaration } from '@uirouter/core';
import { LearningModuleModule } from '../../learning-module/types/modules';
import type { Injector } from '@angular/core';

export type BreadcrumbFn = (transition: Transition) => Observable<string>;
export type BreadcrumbTranslation = { key: string, ns?: NamespaceFile };
export type StateProviderDataDataPermissionCheck = (permissionCheckService: PermissionCheckService, injector: Injector) => Observable<boolean>;
export type StateProviderDataDataSettingCheck = (value: PropertyValueDecoder | null) => boolean;

/**
 * Default interface for a state
 */
export interface StateProviderDataData {
    requireLogin?: boolean;
    fullscreen?: boolean;
    products?: Products[];
    learningModuleModules?: LearningModuleModule[];
    somePermissions?: string[];
    permissions?: string[];
    permissionCheck?: StateProviderDataDataPermissionCheck;
    /**
     * Provide the setting name as key, and a callback function that returns true/false.
     *
     * When all keys and their respective callback functions return true, the state will be shown.
     */
    settings?: Record<string, StateProviderDataDataSettingCheck>
    requiresEmployee?: boolean;
    allowExternal?: boolean;
    breadcrumb: BreadcrumbFn | BreadcrumbTranslation | null;
    queryParams?: string[];
    /**
     * @deprecated
     */
    i18nextNs?: NamespaceFile[];
}

/**
 * Default interface for our states
 */
export interface StateProviderData {
    name: string;
    parent?: string;
    component?: string | ComponentType<any>;
    url?: string;
    /**
     * @deprecated
     */
    template?: string;
    abstract?: boolean;
    views?: Record<string, string | { component: any } | { controller: string | (() => void), controllerAs?: string; template: string }>;
    data?: StateProviderDataData,
    resolve?: Resolvable[];
    params?: Record<string, ParamDeclaration>
}

export interface SidenavChildStateProviderData extends StateProviderData {
    data: StateProviderDataData & {
        name?: { key: string, ns: NamespaceFile };
        icon?: string;
    }
}

/**
 * For creating a nav tab state
 */
interface NavTabsStateProviderData extends Omit<StateProviderData, 'views'> {
    component: ComponentType<any>,
}

/**
 * Mutates the data object to add query params and update the url
 */
function addQueryParams(data: StateProviderData, params: string[]) {
    const queryString = params.map((p) => `:${p}`).join('&');

    params.forEach((param) => {
        if (!data.params) {
            data.params = {};
        }

        data.params[param] = {
            type: 'query',
            dynamic: true,
            value: '',
            squash: true,
        };
    });

    if (data.url) {
        data.url = data.url.includes('?') ? `${data.url}&${queryString}` : `${data.url}?${queryString}`;
    }
}

export function createState($stateProvider: any, data: StateProviderData): void {
    const isNavTabsState = Object.values(data.views || {}).some((v) => typeof v === 'object' && 'component' in v && (v.component === NavTabsComponent));
    if (isNavTabsState && !data.abstract) {
        data.abstract = true;
        console.error('Nav tabs state should be abstract', data);
    }

    if (data.data?.queryParams) {
        addQueryParams(data, data.data?.queryParams);
    }

    return $stateProvider.state(data);
}

export function createNavTabsState($stateProvider: any, data: NavTabsStateProviderData) {
    createState($stateProvider, {
        ...data,
        component: undefined,
        views: {
            'nav-view': {
                component: data.component,
            },
        },
    });
}

export function createSidenavChildState($stateProvider: any, data: SidenavChildStateProviderData) {
    createState($stateProvider, {
        ...data,
        component: undefined,
        views: {
            'sidenav-view': {
                component: data.component,
            },
        },
    });
}
