import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { WidgetSettingsDialog } from '../../../classes/widget-settings-dialog';
import { WidgetPropertySettings } from '../../../classes/widget';
import { CustomerLinkService } from '../../../../shared/http/customer-link.service';
import { WidgetSettingsDialogComponent } from '../../widget-settings-dialog/widget-settings-dialog.component';
import { CurrentService } from '../../../../shared/services/current.service';
import { expandAllPages } from '../../../../shared/utils/rxjs/expand-all-pages';
import { Customer } from '../../../../shared/models/customer';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { forkJoin, map, of, switchMap } from 'rxjs';
import { Products } from '../../../../shared/enums/products';
import { PermissionCheckService } from '../../../../shared/services/permission-check.service';
import { CustomerProductService } from '../../../../shared/http/customer-product.service';
import { ApiModel } from '../../../../shared/enums/api-model';
import { FieldErrorComponent } from '../../../../shared/components/field-error/field-error.component';

export interface UpcomingBirthdaysWidgetSettings extends WidgetPropertySettings {
    locations: string;
    days: number;
}

@Component({
    selector: 'eaw-upcoming-birthdays-widget-settings',
    standalone: true,
    imports: [
        WidgetSettingsDialogComponent,
        MatFormFieldModule,
        TranslatePipe,
        AsyncPipe,
        ReactiveFormsModule,
        MatInput,
        MatSelectModule,
        FieldErrorComponent,
    ],
    templateUrl: './upcoming-birthdays-widget-settings.component.html',
    styleUrl: './upcoming-birthdays-widget-settings.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpcomingBirthdaysWidgetSettingsComponent extends WidgetSettingsDialog<UpcomingBirthdaysWidgetSettings> implements OnInit {
    private readonly customerLinkService = inject(CustomerLinkService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly customerProductService = inject(CustomerProductService);
    private readonly current = inject(CurrentService);

    loading = signal(true);
    locations = signal<Customer[]>([]);

    form = new FormGroup({
        locations: new FormControl<number[]>(this.data.settings?.locations?.split(',').map(Number) || [], { nonNullable: true, validators: Validators.required }),
        days: new FormControl(this.data.settings?.days ?? 7, { nonNullable: true, validators: [ Validators.required, Validators.min(0), Validators.max(364) ] }),
    });

    constructor() {
        super();
    }

    ngOnInit() {
        forkJoin([
            this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].links.*.get`, { models: [ { type: ApiModel.Customer, id: this.current.getCustomer().id } ] }),
            this.customerProductService.hasProducts(this.current.getCustomer().id, [ Products.ChainOps ]),
        ]).pipe(
            map(([ canGetLinks, hasChainOps ]) => canGetLinks && hasChainOps),
            switchMap((getDescendants) => {
                if (getDescendants) {
                    return expandAllPages((pagination) => this.customerLinkService.getDescendants(this.current.getCustomer().id, pagination), { per_page: 200 });
                }

                return of([]);
            }),
        ).subscribe((locations) => {
            if (!locations.length) {
                this.form.controls.locations.setValue([ this.current.getCustomer().id ]);
                this.form.controls.locations.disable();
            }
            this.loading.set(false);
            this.locations.set([ ...locations, this.current.getCustomer() ]);
        });
    }

    save() {
        const values = this.form.getRawValue();
        this.dialogRef.close({
            days: values.days,
            locations: values.locations.join(','),
        });
    }
}
