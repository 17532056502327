import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { UserAccess, UserAccessResponse } from '../models/user-access';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { DateTime } from 'luxon';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class UserAccessService {

    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {}

    getAll(customerId: number, options: PaginationOptions = {}) {
        return this.http.get<ArrayPaginatedResponse<UserAccessResponse>>(`customers/${customerId}/user_access`, {
            params: {
                order_by: 'from',
                ...options,
            },
        }).pipe(classifyArrayPaginatedResponse(UserAccess));
    }

    // Get all user accesses for a specific user
    getAllForUser(customerId: number, userId: number, options: PaginationOptions = {}): Observable<ArrayPaginatedResponse<UserAccess>> {
        return this.http.get<ArrayPaginatedResponse<UserAccessResponse>>(`customers/${customerId}/users/${userId}/access`, {
            params: {
                order_by: 'from',
                ...options,
            },
        }).pipe(classifyArrayPaginatedResponse(UserAccess));
    }

    get(customerId: number, userId: number, userAccessId: number): Observable<UserAccess> {
        return this.http.get<UserAccessResponse>(`customers/${customerId}/users/${userId}/access/${userAccessId}`).pipe(classifyItem(UserAccess));
    }

    create(customerId: number, userId: number, from: DateTime, to?: DateTime | null): Observable<UserAccess> {
        return this.http.post<UserAccessResponse>(`customers/${customerId}/users/${userId}/access`, {
            from,
            to,
        }).pipe(classifyItem(UserAccess));
    }

    update(customerId: number, userId: number, userAccessId: number, from: DateTime, to?: DateTime | null): Observable<UserAccess> {
        return this.http.put<UserAccessResponse>(`customers/${customerId}/users/${userId}/access/${userAccessId}`, {
            from,
            to,
        }).pipe(classifyItem(UserAccess));
    }

    delete(customerId: number, userId: number, userAccessId: number): Observable<undefined> {
        return this.http.delete<undefined>(`customers/${customerId}/users/${userId}/access/${userAccessId}`);
    }
}
