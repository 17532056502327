import moment, { Moment } from 'moment-timezone';
import { module } from 'angular';
import { PaginationOld } from '../../../../shared/utils/pagination-old';
import { Products } from '../../../../shared/enums/products';
import { t } from 'i18next';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
import { EawResourceFactory } from '../../../../shared/angularjs/modules/resource/resource.service';

class MiniHoursWorkedWidgetCtrl {
    static get $inject() {
        return [ 'TimepunchFactory', 'EawResource' ];
    }

    mini: any;
    subtext?: string;
    seconds: number | null = null;

    constructor(
        public TimepunchFactory: any,
        public EawResource: EawResourceFactory,
    ) {
    }

    async $postLink() {
        let from, to;
        // The setting is called "month" but it should be called "period"
        // But it's too troublesome to update :)
        switch (this.mini.widget.settings.month) {
            case 'previous': {
                from = moment().subtract(1, 'M').startOf('M');
                to = from.clone().endOf('M');
                this.subtext = t('widgets:PREVIOUS_MONTH');
                break;
            }
            case 'week': {
                from = moment().startOf('w');
                to = moment();
                this.subtext = t('widgets:THIS_WEEK');
                break;
            }
            // "this" is also the default
            case 'this':
            default: {
                from = moment().startOf('M');
                to = moment();
                this.subtext = t('widgets:THIS_MONTH');
            }
        }

        try {
            this.seconds = await this.getBasis(from, to);
        } catch (e) {
            console.error(e);
        }

        this.mini.loading(false);
    }

    async getBasis(from: Moment, to: Moment): Promise<number> {
        const hasPaidTime: boolean = CurrentOld.hasProduct(Products.PaidTime);
        // Default to using paid time if they have it, as it has been.
        const usePaidTime = hasPaidTime &&
            (this.mini.widget.settings.basis == 'paid_time' || !this.mini.widget.settings.basis) &&
            CurrentOld.can('customers.{customer}.employees.{employee}.paid_times.*.get');

        const data: { from: Moment, to: Moment, length: number }[] = await PaginationOld.getData(new PaginationOld({ per_page: 150 }), async (pagination) => {
            return usePaidTime ? this.getPaidTime(from, to, pagination) : this.getTimepunches(from, to, pagination);
        });
        if (usePaidTime) {
            return data.reduce((sum, o) => {
                return sum + o.to.diff(o.from, 's');
            }, 0) as number;
        }

        return data.reduce((sum, o) => {
            return sum + o.length;
        }, 0);
    }

    getTimepunches(from: Moment, to: Moment, pagination: PaginationOld) {
        return this.TimepunchFactory.getForEmployee({
            customer: { id: this.mini.widget.customer },
            employee: this.mini.widget.employee,
            from,
            to,
            ...pagination,
        }).$promise;
    }

    getPaidTime(from: Moment, to: Moment, pagination: PaginationOld) {
        return this.EawResource.create(
            `/customers/${this.mini.widget.customer}/employees/${this.mini.widget.employee.id}/paid_times`,
            {},
            {
                get: {
                    method: 'GET',
                    headers: {
                        'X-Ignore-Error': true,
                    },
                },
            })
            .get({
                from,
                to,
                ...pagination.toWithPaginatorArguments(),
            }).$promise;
    }
}

module('eaw.dashboard').component('miniHoursWorkedWidget', {
    template: `
<h2 ng-show="$hrsWorked.seconds !== null" ng-bind="$hrsWorked.seconds | eawDuration"></h2>
<span ng-bind="$hrsWorked.subtext"></span>
`,
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$hrsWorked',
    controller: MiniHoursWorkedWidgetCtrl,
});
