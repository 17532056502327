import { Injectable } from '@angular/core';
import { createInstance } from 'localforage';

export interface InitializerCacheItem<T> {
    value: T;
    updatedAt: string;
}

/**
 * The cache to use for the initializer.
 *
 * Either a customer ID or the default cache.
 */
type TargetCache = number | 'default';

@Injectable({
    providedIn: 'root',
})
export class InitializerCacheService {
    private readonly cacheName = 'Initializer cache';
    private readonly version = 2;
    private customerCaches: Map<TargetCache, LocalForage> = new Map();
    private defaultCache: LocalForage = createInstance({
        name: this.cacheName,
        storeName: 'Default',
        version: this.version,
    });

    private getCache(targetCache: TargetCache) {
        if (!targetCache) {
            return this.defaultCache;
        }

        return this.customerCaches.get(targetCache) || this.createCustomerCache(targetCache);
    }

    private createCustomerCache(targetCache: TargetCache) {
        const cache = createInstance({
            name: this.cacheName,
            storeName: `Customer-${targetCache}`,
            version: this.version,
        });

        this.customerCaches.set(targetCache, cache);

        return cache;
    }

    async clear(targetCache: TargetCache) {
        await this.getCache(targetCache)?.clear();
    }

    async store<T>(targetCache: TargetCache, key: string, value: T, updatedAt: string) {
        await this.getCache(targetCache)?.setItem(key, { value, updatedAt } satisfies InitializerCacheItem<T>);
    }

    async get<T>(targetCache: TargetCache, key: string) {
        return this.getCache(targetCache)?.getItem<InitializerCacheItem<T>>(key);
    }
}
