// @ts-nocheck
import { remove } from 'lodash-es';
import { find } from 'lodash-es';
import { module } from 'angular';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
import { Products } from '../../../../../shared/enums/products';

module('eaw.scheduling').service('scheduleBudget', [ 'BudgetFactory', '$rootScope', function scheduleBudget(BudgetFactory, $rootScope) {
    let _budgets;
    let _schedule;
    /**
     * @name scheduleBudget
     */
    const service = {
        showBudget() {
            return CurrentOld.hasProduct(Products.Budgeting) && CurrentOld.can(BudgetFactory.getAll.permission(_schedule.customer_id, _schedule.id));
        },
        lastId: -1,
        setSchedule(s) {
            _schedule = s;
        },
        init() {
            if (!service.showBudget()) {
                return;
            }
            if (this.lastId === _schedule.id) {
                return new Promise((resolve) => resolve(_budgets));
            } else {
                _budgets = [];
            }
            this.lastId = _schedule.id;
            return new Promise((resolve, reject) => {
                BudgetFactory.getAll.query({
                    customer: _schedule.customer_id,
                    schedule: _schedule.id,
                    per_page: 99999,
                }).$promise.then((data) => {
                    _budgets = data.data;
                    service.events.trigger.change(data.data);
                    resolve(undefined);
                }, reject);
            });
        },
        get() {
            return _budgets;
        },
        reset() {
            this.lastId = -1;
            _budgets = [];
            service.events?.trigger?.change?.(null);
        },
        /**
         * Returns the budget at any given index
         * @param {int} index
         * @returns {object}
         */
        getAtIndex(index) {
            return find(_budgets, { index });
        },
        /**
         * @param index
         * @returns {int}
         */
        getBudgetAtIndex(index) {
            // Find number of intervals difference
            const scheduleInterval = parseInt(_schedule.settings.interval);
            const budgetInterval = service.getInterval();
            const intervalsDiff = scheduleInterval / budgetInterval;
            // Get section of budgets we need
            const from = index * intervalsDiff;
            const to = from + intervalsDiff;
            return new Array(Math.round(to - from)).fill(0).map((x, i) => i + from).reduce((sum, i) => {
                const budObj = find(_budgets, (b) => b.index === i);
                const value = budObj?.budget || 0;
                return sum + value;
            }, 0);
        },
        /**
         * Get budget for whole schedule
         * @returns {int}
         */
        getTotal() {
            return _budgets.reduce((sum, budget) => sum + budget.budget, 0);
        },
        /**
         * Get budget for day at index X
         * @param {int} index
         * @param {int} offset
         * @returns {int}
         */
        getDayTotal(index, offset) {
            const dates = _schedule.getArrayOfIntervalsForDayWithOffset(index, service.getInterval(), offset);
            const indexes = [];
            dates.forEach((date) => {
                indexes.push(_schedule.getIntervalIndexFromDate(date, service.getInterval()));
            });
            return indexes.reduce((sum, index) => sum + (service.getAtIndex(index)?.budget || 0), 0);
        },
        /**
         *
         * @returns int
         */
        getInterval() {
            return _schedule.getProperty('budgeting_interval') == null ? 3600 : parseInt(_schedule.getProperty('budgeting_interval'));
        },
        /**
         * Add a new budget
         * @param   {int}       budget
         * @param   {int}       index
         * @returns {Promise}
         */
        add(budget, index) {
            return new Promise((resolve, reject) => {
                BudgetFactory.addBudget(index, budget, _schedule, { id: _schedule.customer_id }).$promise.then((data) => {
                    _budgets.push(data);
                    service.events.trigger.change();
                    resolve(data);
                }, reject);
            });
        },
        /**
         * Update existing budget
         * @param budget
         * @returns {Promise}
         */
        update(budget) {
            return new Promise((resolve, reject) => {
                BudgetFactory.updateBudget(budget, _schedule, { id: _schedule.customer_id }).$promise.then((data) => {
                    _budgets[_budgets.findIndex((b) => b.id === data.id)] = data;
                    service.events.trigger.change(_budgets);
                    resolve(data);
                }, reject);
            });
        },
        /**
         * Delete a budget
         * @param budget
         * @returns {Promise}
         */
        delete(budget) {
            return new Promise((resolve, reject) => {
                BudgetFactory.deleteBudget(budget, _schedule, { id: _schedule.customer_id }).$promise.then(() => {
                    remove(_budgets, (b) => b.id === budget.id);
                    service.events.trigger.change();
                    resolve(undefined);
                }, reject);
            });
        },
        events: {
            trigger: {
                change(data) {
                    $rootScope.$broadcast('schedule:budgets:changed', data);
                },
            },
            register: {
                change($scope, callback) {
                    $scope.$on('schedule:budgets:changed', callback);
                },
            },
        },
    };
    return service;
} ]);
