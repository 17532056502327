import { inject, Injectable } from '@angular/core';
import { UserPropertyService } from '../../shared/http/user-property.service';
import { expandAllPages } from '../../shared/utils/rxjs/expand-all-pages';
import { catchError, EMPTY, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { WidgetInfoService } from '../services/widget-info.service';
import { Widget, WidgetPropertyData, WidgetPropertySettings, WidgetSize } from '../classes/widget';
import { SettingService } from '../../shared/http/setting.service';
import { TinyColor } from '@ctrl/tinycolor';
import { HttpContext } from '@angular/common/http';
import { Property } from '../../shared/models/property';
import { SettingGroupPropertyService } from '../../shared/http/setting-group-property.service';
import { PermissionCheckService } from '../../shared/services/permission-check.service';

@Injectable({
    providedIn: 'root',
})
export class WidgetService {
    private userPropertyService = inject(UserPropertyService);
    private settingService = inject(SettingService);
    private widgetInfoService = inject(WidgetInfoService);
    private settingGroupPropertyService = inject(SettingGroupPropertyService);
    private permissionCheckService = inject(PermissionCheckService);

    static readonly DEFAULT_WIDGETS_KEY = 'default_widgets';
    static readonly DISABLED_WIDGETS_KEY = 'disabled_widgets';
    static readonly WIDGET_COLOR_KEY = 'widget_color';

    /**
     * Get the property key for the widget
     * @param customerId - Customer ID used to construct the key
     * @param includeSuffix - Include a unique suffix, useful when creating a new widget property
     */
    private getPropertyKey(customerId: number, includeSuffix?: boolean) {
        const base = `widget_v3:${customerId}`;
        return includeSuffix ? `${base}:${uuidv4()}` : base;
    }

    /**
     * Get the default widgets for the given setting group
     */
    getDefault(settingGroupId: number) {
        return this.settingService.getValue<string[]>([ 'setting_groups', settingGroupId ], WidgetService.DEFAULT_WIDGETS_KEY, []).pipe(
            catchError(() => of([] as string[])),
        );
    }

    /**
     * Get the disabled widgets for the given setting group
     */
    getDisabled(settingGroupId: number) {
        return this.settingService.getValue<string[]>([ 'setting_groups', settingGroupId ], WidgetService.DISABLED_WIDGETS_KEY, []).pipe(
            catchError(() => of([] as string[])),
        );
    }

    /**
     * Will check if setup is completed for the given user and customer, and if not, will add the initial widgets
     * @param settingGroupId
     * @param customerId
     * @param userId
     * @returns true if setup happened, false if it was already completed
     */
    doSetup(settingGroupId: number | undefined, customerId: number, userId: number) {
        const setupCompletedKey = `widget_setup_completed:${customerId}`;

        return this.userPropertyService.get(userId, setupCompletedKey).pipe(
            // Handle error
            catchError((e) => {
                return e.status === 404 ? of(false) : EMPTY;
            }),
            // Handle the setup completed property
            map((value) => {
                return value instanceof Property ? value.value.asBoolean() : value;
            }),
            // Returns an array with the setup completed and the default widgets
            switchMap((setupCompleted): Observable<[boolean, string[]]> => {
                if (setupCompleted) {
                    return of([ true, [] ]);
                }

                if (!settingGroupId) {
                    return of([ false, [] ]);
                }

                return this.getDefault(settingGroupId).pipe(
                    map((defaultWidgets) => [ false, defaultWidgets ]),
                );
            }),
            switchMap(([ setupCompleted, defaultWidgets ]) => {
                if (setupCompleted) {
                    return of(false);
                }

                // Initial widgets is whatever is default
                const widgetKeys = new Set<string>(defaultWidgets);
                const miniWidgets = this.widgetInfoService.get({ type: 'mini', setup: true });
                const normalWidgets = this.widgetInfoService.get({ type: 'normal', setup: true });

                return forkJoin([ miniWidgets, normalWidgets ]).pipe(
                    switchMap(([ miniWidgets, normalWidgets ]) => {
                        miniWidgets.forEach((info) => widgetKeys.add(info.key));
                        normalWidgets.forEach((info) => widgetKeys.add(info.key));

                        return forkJoin(Array.from(widgetKeys).map((key) => this.add(customerId, userId, key))).pipe(
                            switchMap(() => this.userPropertyService.update(userId, setupCompletedKey, '1')),
                            map(() => true),
                        );

                    }),
                );
            }),
        );
    }

    /**
     * Get the default color for the given setting group
     */
    getColor(settingGroupId: number): Observable<TinyColor | null> {
        return this.settingService.getString([ 'setting_groups', settingGroupId ], WidgetService.WIDGET_COLOR_KEY).pipe(
            catchError(() => of(null)),
            map((color) => {
                const newColor = color ? new TinyColor(color) : null;
                return newColor?.isValid ? newColor : null;
            }),
        );
    }

    /**
     * Set the default color for the given setting group
     */
    setColor(settingGroupId: number, color: string | TinyColor): Observable<TinyColor | null> {
        const newColor = typeof color === 'string' ? new TinyColor(color) : color;

        if (!newColor.isValid) {
            return of(null);
        }

        return this.settingService.update([ 'setting_groups', settingGroupId ], WidgetService.WIDGET_COLOR_KEY, newColor.toHexString()).pipe(
            catchError(() => of(null)),
            map((color) => {
                const newColor = color ? new TinyColor(color.value?.asString()) : null;
                return newColor?.isValid ? newColor : null;
            }),
        );
    }

    /**
     * Remove the default color for the given setting group
     */
    removeColor(settingGroupId: number) {
        return this.settingGroupPropertyService.delete(settingGroupId, WidgetService.WIDGET_COLOR_KEY).pipe(
            catchError(() => of(null)),
            map(() => null),
        );
    }

    getHiddenWidgets(customerId: number) {
        const permission = `ui.customers.${customerId}.dashboard.widgets`;
        return this.permissionCheckService.permissionChildrenSingle(permission, 'visible', false).pipe(
            map((response) => response || []),
        );
    }

    /**
     * Updates the list of default widgets
     * @param settingGroupId
     * @param keys - Keys of the widgets as it is in widget info
     */
    updateDefaultWidgets(settingGroupId: number, keys: string[]) {
        return this.settingService.update([ 'setting_groups', settingGroupId ], WidgetService.DEFAULT_WIDGETS_KEY, JSON.stringify(keys));
    }

    /**
     * Updates the list of disabled widgets
     * @param settingGroupId
     * @param keys - Keys of the widgets as it is in widget info
     */
    updateDisabledWidgets(settingGroupId: number, keys: string[]) {
        return this.settingService.update([ 'setting_groups', settingGroupId ], WidgetService.DISABLED_WIDGETS_KEY, JSON.stringify(keys));
    }

    /**
     * Get all settings related to widgets
     */
    getSettings(settingGroupId: number | undefined, context?: HttpContext) {
        if (!settingGroupId) {
            return of({
                [WidgetService.DEFAULT_WIDGETS_KEY]: [],
                [WidgetService.DISABLED_WIDGETS_KEY]: [],
                [WidgetService.WIDGET_COLOR_KEY]: null,
            });
        }

        return this.settingService.getSome([ 'setting_groups', settingGroupId ], {
            'settings[]': [
                WidgetService.DEFAULT_WIDGETS_KEY,
                WidgetService.DISABLED_WIDGETS_KEY,
                WidgetService.WIDGET_COLOR_KEY,
            ],
        }, context).pipe(
            map((settings) => {
                const color = new TinyColor(settings.find((setting) => setting.key === WidgetService.WIDGET_COLOR_KEY)?.value?.asString());

                return {
                    [WidgetService.DEFAULT_WIDGETS_KEY]: settings.find((s) => s.key === WidgetService.DEFAULT_WIDGETS_KEY)?.value?.asArray() || [],
                    [WidgetService.DISABLED_WIDGETS_KEY]: settings.find((s) => s.key === WidgetService.DISABLED_WIDGETS_KEY)?.value?.asArray() || [],
                    [WidgetService.WIDGET_COLOR_KEY]: color.isValid ? color : null,
                };
            }),
        );
    }

    /**
     * Returns all the widgets for the given user at a location that are not hidden from the user
     */
    getAll(customerId: number, userId: number): Observable<Widget<any>[]> {
        // Append ":" to the key to make sure we don't get the wrong properties since
        // something like "widget_v3:1" would match "widget_v3:10" otherwise
        const filterKey = `${this.getPropertyKey(customerId)}:`;

        const properties = expandAllPages((pagination) => this.userPropertyService.getAll(userId, { filter: filterKey, ...pagination }), { per_page: 100 });
        const hiddenWidgets = this.getHiddenWidgets(customerId);
        const widgetInfos = this.widgetInfoService.get();

        return forkJoin([ properties, widgetInfos, hiddenWidgets ]).pipe(
            switchMap(([ properties, widgetInfos, hiddenWidgets ]) => {
                // Convert the properties into widgets
                const widgets = properties.reduce((acc, property) => {
                    const widget = new Widget(property._response, widgetInfos);
                    if (hiddenWidgets.includes(widget.info.key)) {
                        return acc;
                    }

                    return acc.concat(widget);
                }, [] as Widget[]);

                return of(widgets);
            }),
        );
    }

    /**
     * Adds a new widget to the user's dashboard
     */
    add(customerId: number, userId: number, widgetInfoKey: string, color?: TinyColor, settings: WidgetPropertySettings = {}): Observable<Widget<any> | null> {
        return this.widgetInfoService.getByKey(widgetInfoKey).pipe(
            switchMap((widgetInfo) => {
                if (!widgetInfo) {
                    return of(null);
                }

                // Create the default widget property data
                const value: WidgetPropertyData = {
                    key: widgetInfoKey,
                    size: widgetInfo.mini ? WidgetSize.Mini : widgetInfo.recommendedSize ?? WidgetSize.Default,
                    settings,
                };

                if (color) {
                    value.color = color.toHexString();
                }

                // Try to stringify the value, or return null if it fails
                let stringifiedValue = '';
                try {
                    stringifiedValue = JSON.stringify(value);
                } catch (_) {
                    return of(null);
                }

                return this.userPropertyService.create(userId, this.getPropertyKey(customerId, true), stringifiedValue).pipe(
                    catchError(() => of(null)),
                    map((response) => {
                        if (!response) {
                            return null;
                        }

                        return new Widget(response._response, [ widgetInfo ]);
                    }),
                );
            },
            ));
    }

    update(userId: number, widget: Widget<any>, data: Partial<Omit<WidgetPropertyData, 'key'>>) {
        const value: WidgetPropertyData = {
            key: widget.info.key,
            size: data.size ?? widget.size(),
            settings: data.settings ?? widget.settings,
            color: data.color ?? widget.color()?.toHexString(),
            order: data.order ?? widget.order,
        };

        // Try to stringify the value, or return null if it fails
        let stringifiedValue = '';
        try {
            stringifiedValue = JSON.stringify(value);
        } catch (_) {
            return of(widget);
        }

        return this.userPropertyService.update(userId, widget.property.key, stringifiedValue).pipe(
            map((response) => {
                return new Widget(response._response, [ widget.info ]);
            }),
        );
    }

    delete(userId: number, widget: Widget<any>) {
        return this.userPropertyService.delete(userId, widget.property.key);
    }
}
