<eaw-page-header>
    <span title>{{'OVERRIDE_OFFTIME' | translate:'vacation' | async}}</span>
</eaw-page-header>

<mat-card>
    <mat-card-content class="lg:tw-max-w-[800px]">
        <form [formGroup]="form" class="tw-mb-16 tw-grid tw-gap-16 tw-grid-cols-1">
            <eaw-autocomplete formControlName="employee"
                              [options]="employeeAutocompleteService.options"
                              [getter]="employeeAutocompleteService.getter({customerId: customerId, from: getFrom(), to: getTo()})"
                              [setter]="employeeAutocompleteService.setter({customerId: customerId})">
            </eaw-autocomplete>

            <eaw-date-time-range-input class="tw-w-full tw-grid tw-grid-cols-2 tw-gap-8">
                <mat-form-field eawDateTimeFrom>
                    <mat-label>{{'FROM' | translate | async}}</mat-label>
                    <eaw-date-time-input formControlName="from" [required]="true"></eaw-date-time-input>
                </mat-form-field>

                <mat-form-field eawDateTimeTo>
                    <mat-label>{{'TO' | translate | async}}</mat-label>
                    <eaw-date-time-input formControlName="to" [required]="true" [defaultTime]="defaultToTime"></eaw-date-time-input>
                </mat-form-field>
            </eaw-date-time-range-input>
        </form>

        <div class="tw-flex tw-flex-col tw-justify-center tw-items-center" *ngIf="loading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>

        <mat-card *ngIf="offtimes.length" class="tw-my-12 mat-elevation-z8">
            <mat-card-header>
                <mat-card-title>{{'OVERLAPPING' | translate | async}}</mat-card-title>
                <mat-card-subtitle>{{'OVERLAPPING_HELP' | translate:'vacation' | async}}</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
                <mat-list>
                    <mat-list-item *ngFor="let offtime of offtimes">
                        <div matListItemTitle [class.handled]="isHandled(offtime)">{{offtime.from | DateTime:'DATETIME_MED'}} - {{offtime.to | DateTime:'DATETIME_MED'}}</div>

                        <div matListItemLine *ngIf="!offtime.approval && !offtime.deletedAt">{{'UNHANDLED' | translate:'vacation' | async}}</div>
                        <div matListItemLine *ngIf="offtime.approval?.approved && !offtime.deletedAt">{{'APPROVED' | translate:'vacation' | async}}</div>
                        <div matListItemLine *ngIf="isHandled(offtime)">{{offtime.comments?.[0]?.body}}</div>

                        <div matListItemMeta *ngIf="!isHandled(offtime)">
                            <button mat-button (click)="decline(offtime)">{{'DECLINE' | translate | async}}</button>
                            <button mat-button (click)="delete(offtime)" [disabled]="offtime.approval?.approved">{{'DELETE' | translate | async}}</button>
                        </div>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>

        <div class="tw-mt-16">
            <button mat-raised-button (click)="override()" color="primary" [disabled]="form.invalid || form.disabled || loading || !allHandled">{{'OVERRIDE' | translate | async}}</button>
        </div>
    </mat-card-content>
</mat-card>
