import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { DateTime, Duration } from 'luxon';
import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { BaseApiModel } from '../../shared/models/base-api-model';
import { Comment, CommentResponse } from '../../shared/models/comment';
import { BusinessDateString } from '../../shared/types/business-date';
import { BusinessDate } from '../../shared/utils/business-date';
import { BusinessUnitResponse } from '../../business-units/interfaces/business-unit-response';
import { BusinessUnit } from '../../business-units/models/business-unit';
import { ApiModel } from '../../shared/enums/api-model';

export interface PaidTimeResponse extends ApiResponse {
    business_date: BusinessDateString;
    created_at: string;
    deleted_at: string | null;
    customer_id: number;
    employee_id: number;
    business_unit_id: number | null;
    from: string;
    id: number;
    to: string;
    updated_at: string;
    employee?: EmployeeResponse;
    comments?: CommentResponse[];
    business_unit?: BusinessUnitResponse | null;
}

export class PaidTime extends BaseApiModel<PaidTimeResponse, PaidTime> {
    businessDate: BusinessDate;
    createdAt: DateTime;
    deletedAt: DateTime | null;
    customerId: number;
    employeeId: number;
    from: DateTime;
    id: number;
    to: DateTime;
    updatedAt: DateTime;
    employee?: Employee;
    businessUnit?: BusinessUnit | null;
    duration: Duration;
    comments: Comment[] = [];
    businessUnitId: number | null;

    constructor(data: PaidTimeResponse) {
        super(data, ApiModel.PaidTime);

        this.id = data.id;
        this.customerId = data.customer_id;
        this.employeeId = data.employee_id;
        this.businessUnitId = data.business_unit_id;
        this.from = stringToDateTime(data.from);
        this.to = stringToDateTime(data.to);
        this.businessDate = new BusinessDate(data.business_date);
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.employee = data.employee ? new Employee(data.employee) : undefined;
        this.businessUnit = data.business_unit ? new BusinessUnit(data.business_unit) : null;
        this.duration = this.to.diff(this.from);
        this.comments = data.comments ? data.comments.map((comment) => new Comment(comment)) : [];
    }
}
