<div id="card-header">
    <div id="left-header">
        <span class="mat-headline-5 tw-m-0">{{ 'SIMPLE_PLAN' | translate:'scheduling' | async }}</span>

        @if (subtitle()) {
            <span class="mat-body-1 tw-m-0">
                @if (selectedPositions().length) {
                    <div class="tw-flex tw-gap-8 tw-items-center">
                        <span>{{ subtitle() }}</span>
                        <span id="remove-pos-filter" (click)="selectedPositions.set([])">({{ 'SHOW_ALL' | translate | async }})</span>
                    </div>
                } @else {
                    <span>{{ subtitle() }}</span>
                }
            </span>
        }
    </div>

    <div id="right-header">
        <span id="status-label" [ngClass]="statusLabel().class" [eawMaterialColor]="statusLabel().color" colorProperty="color" (click)="statusLabel().callback?.()">{{ statusLabel().text | async }}</span>

        <button mat-icon-button
                [matTooltip]="('SELECT_POSITIONS' | translate:'scheduling' | async) || ''"
                (click)="selectPositions()"
                [color]="selectedPositions().length ? 'accent' : ''">
            <mat-icon>badge</mat-icon>
        </button>

        <button mat-icon-button
                [matTooltip]="('HIDE_EMPLOYEE_NO_SHIFT_GROUP' | translate:'scheduling' | async) || ''"
                (click)="toggleNoGroupRows()"
                [color]="this.hideRowsWithNoGroup() ? 'accent' : ''">
            <mat-icon>{{ hideRowsWithNoGroup() ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>

        <button mat-icon-button
                [matTooltip]="('HOURS_PLANNED' | translate:'scheduling' | async) || ''"
                (click)="openHoursPlanned()">
            <mat-icon>analytics</mat-icon>
        </button>

        <button class="header-fab-button"
                mat-mini-fab
                (click)="save()"
                color="accent"
                [matTooltip]="('SAVE' | translate | async) || 'Save'"
                [disabled]="saveButtonDisabled()">
            <mat-icon>save</mat-icon>
        </button>
    </div>
</div>

@if (loading()) {
    <eaw-info-loading size="xl" class="tw-flex-1"/>
} @else {
    <div id="grid" #grid>
        <div id="grid-header" class="grid-row">
            <div class="header employee">Employee</div>

            @for (day of headerDays(); track day.date) {
                <div #headerDay [attr.id]="day.date" class="header">
                    <span class="date">{{ day.date }}</span>
                    <span class="weekday">{{ day.weekday }}</span>

                    @if (day.dstStarts) {
                        <span eawMaterialColor="red-500">{{ 'DAYLIGHT_SAVINGS_STARTS' | translate | async }}</span>
                    }
                    @if (day.dstEnds) {
                        <span eawMaterialColor="red-500">{{ 'DAYLIGHT_SAVINGS_ENDS' | translate | async }}</span>
                    }
                </div>
            }
        </div>

        @for (row of sortedRows(); track row.id) {
            <div #employeeRow
                 [attr.id]="row.id"
                 class="employee-row grid-row"
                 [class.viewport]="row.inViewport()"
                 [class.menu-open]="row.hasOpenMenu()"
                 [class.disabled]="row.processing()"
                 [ngStyle]="{ '--intervals': row.intervalsPerDay() }">
                <div class="employee-cell">
                    <div class="tw-flex tw-flex-col">
                        <span class="name">{{ (showNicknames() ? (row.employee()?.nickname || row.employee()?.name) : row.employee()?.name) || ('UNASSIGNED' | translate | async) }}</span>

                        @if (row.invalids()) {
                            <small class="invalids">{{ 'X_INVALID_INTERVAL' | translate:'scheduling':{count: row.invalids()} | async }}</small>
                        } @else if (row.edits()) {
                            <small class="changes">{{ 'X_CHANGE' | translate:'general':{count: row.edits()} | async }}</small>
                        }
                    </div>

                    @if (row.processing()) {
                        <eaw-info-loading size="sm"/>
                    } @else if (row.shiftGroup()) {
                        <button mat-icon-button
                                [matMenuTriggerFor]="menu"
                                [matMenuTriggerData]="{row: row}"
                                (menuOpened)="row.hasOpenMenu.set(true)"
                                (menuClosed)="row.hasOpenMenu.set(false)">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                    }
                </div>

                @for (day of headerDays(); track day.date) {
                    @if (row.inViewport() && day.inViewport()) {
                        <div class="day-cell" [class.invalid]="row.invalidDates().has(day.date)">
                            @for (interval of row.intervals()[day.date]?.(); track $index) {
                                <div class="shift-interval" [class.edited]="interval.edited()" [class.invalid]="interval.invalid()">
                                    <input inputmode="numeric" maxlength="5" [placeholder]="inputPlaceholders().from" [value]="interval.from.display()" (focus)="selectInput($event)" (blur)="updateValue($event, day, 'from', interval)">
                                    <input inputmode="numeric" maxlength="5" [placeholder]="inputPlaceholders().to" [value]="interval.to.display()" (focus)="selectInput($event)" (blur)="updateValue($event, day, 'to', interval)">
                                </div>
                            }
                        </div>
                    } @else {
                        <div class="day-cell" [class.invalid]="row.invalidDates().has(day.date)"></div>
                    }
                }

                <!-- Filler -->
                <div class="day-cell">
                    <div class="shift-interval"></div>
                </div>
            </div>
        }
    </div>
}

<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-row="row">
        <button mat-menu-item (click)="addEmptyShiftRow(row)">
            <mat-icon>add</mat-icon>
            <span>{{ 'NEW_SHIFT' | translate:'scheduling' | async }}</span>
        </button>

        <button mat-menu-item [disabled]="!row.shiftGroup()?.employeeId" (click)="unassignShifts(row)">
            <mat-icon>person_off</mat-icon>
            <span>{{ 'UNASSIGN_SHIFTS' | translate:'scheduling' | async }}</span>
        </button>

        <button mat-menu-item (click)="copyShifts(row)">
            <mat-icon>content_copy</mat-icon>
            <span>{{ 'COPY_SHIFT_GROUP' | translate:'scheduling' | async }}</span>
        </button>

        <button mat-menu-item (click)="assignShifts(row)">
            <mat-icon>move_up</mat-icon>
            <span>{{ 'ASSIGN_SHIFTS' | translate:'scheduling' | async }}</span>
        </button>

        <mat-divider/>

        <button mat-menu-item (click)="deleteShifts(row)">
            <mat-icon eawMaterialColor="red-500">delete</mat-icon>
            <span eawMaterialColor="red-500">{{ 'DELETE' | translate | async }}</span>
        </button>
    </ng-template>
</mat-menu>
