@if (waitForJob || loading) {
    <mat-card id="please-wait-card" class="tw-flex tw-flex-row tw-justify-center tw-items-center">
        <mat-card-content class="tw-flex tw-flex-col tw-justify-center tw-items-center">
            @if (waitForJob) {
                <div class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-8">
                    <button mat-icon-button (click)="getSummary()">
                        <mat-icon>refresh</mat-icon>
                    </button>

                    <div class="tw-flex tw-flex-col">
                        <span>{{ lastUpdated }}</span>
                        <span>{{ lastDispatch }}</span>
                    </div>
                </div>
            }

            <mat-progress-spinner diameter="48" strokeWidth="4" mode="indeterminate" [class.invisible]="!loading" class="tw-mt-24"></mat-progress-spinner>
        </mat-card-content>
    </mat-card>
} @else if (weeks) {
    <mat-card id="total-card">
        @if (lastDispatch || lastUpdated) {
            <mat-card-header class="tw-mb-20">
                <mat-card-subtitle>
                    <div class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-8">
                        <button mat-icon-button (click)="getSummary()">
                            <mat-icon>refresh</mat-icon>
                        </button>

                        <div class="tw-flex tw-flex-col">
                            <span>{{ lastUpdated }}</span>
                            <span>{{ lastDispatch }}</span>
                        </div>
                    </div>
                </mat-card-subtitle>
            </mat-card-header>
        }
        <table id="total-table" class="table table-striped table-condensed">
            @for (week of weeks | keyvalue; let lastWeek = $last; track $index) {
                <tbody>
                <tr class="table-week-header">
                    @if (!lastWeek) {
                        <td colspan="999">
                            {{ 'WEEK_AND_YEAR' | translate: 'general': {week: week.value[0]?.week, year: week.value[0]?.year} | async }}
                        </td>
                    } @else {
                        <td colspan="999">
                            {{ 'TOTAL' | translate: 'scheduling'| async }}
                        </td>
                    }
                </tr>
                <tr class="table-column-headers">
                    <td>
                        @if (!lastWeek) {
                            {{ 'DAY' | translate| async }}
                        }
                    </td>
                    <td>{{ 'BUDGET' | translate: 'scheduling' | async }}</td>
                    <td>{{ 'EFFICIENCY' | translate: 'scheduling'| async }}</td>

                    @if (isFrance) {
                        <td>{{ 'UNIT_SOLD_PER_EMPLOYEE' | translate: 'scheduling'| async }}</td>
                        <td>{{ 'TRANSACTION_PER_EMPLOYEE' | translate: 'scheduling'| async }}</td>
                    }

                    <td>{{ 'LABOR_COST' | translate: 'scheduling'| async }}</td>
                    <td>{{ 'LABOR_RATIO' | translate: 'scheduling'| async }}</td>
                    <td>{{ 'MAN_HOURS' | translate: 'scheduling'| async }}</td>

                    @if (showNeed) {
                        <td>{{ 'ESTIMATED_NEED' | translate: 'scheduling'| async }}</td>
                    }

                    <td>{{ 'SHIFT_plural' | translate: 'scheduling'| async }}</td>

                    @if (showNeed) {
                        <td>{{ 'STAFFING_DIFFERENCE' | translate: 'scheduling'| async }}</td>
                    }
                </tr>
                    @for (day of week.value; let lastDay = $last; track $index) {
                        <tr>
                            <td>
                                @if (!lastDay) {
                                    <span>{{ day.dateTime | DateTime: 'WEEKDAY' }}</span>
                                } @else if (!lastWeek) {
                                    <span>{{ 'TOTAL' | translate: 'scheduling'| async }}</span>
                                }
                            </td>
                            <td>{{ day.budget | eawNumber: 0 }}</td>
                            <td>{{ day.efficiency  | eawNumber:2 }}</td>

                            @if (isFrance) {
                                <td>{{ day.units_sold_per_employee | eawNumber: 2 }}</td>
                                <td>{{ day.transactions_per_employee | eawNumber: 2 }}</td>
                            }

                            <td>{{ day.labor_cost | eawNumber:2 }}</td>
                            <td>{{ day.labor_ratio | eawPercent:2 }}</td>
                            <td>{{ day.work_time / 3600 | eawNumber:2 }}</td>

                            @if (showNeed) {
                                <td>{{ day.need | roundToFraction | eawNumber:2 }}</td>
                            }

                            <td>{{ day.shifts | eawNumber }}</td>

                            @if (showNeed) {
                                <td>{{ day.need_diff | roundToFraction | eawNumber:2: {signDisplay: 'exceptZero'} }}</td>
                            }
                        </tr>
                    }
                </tbody>
            }
        </table>
    </mat-card>
}
