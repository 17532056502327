import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { DialogComponent, DialogData, DialogSize } from '../../shared/dialogs/dialog-component';
import { Role } from '../shared/types/role';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { User, UserResponse } from '../../shared/models/user';
import { RoleAssignment } from '../shared/types/role-assignment';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CustomerUserAutocompleteComponent } from '../../shared/components/customer-user-autocomplete/customer-user-autocomplete.component';
import { RoleAutocompleteComponent } from '../shared/components/role-autocomplete/role-autocomplete.component';
import { DialogHeaderComponent } from '../../shared/dialogs/dialog-header/dialog-header.component';

export interface RoleAssignmentDialogData extends DialogData {
    customerId: number
    role?: Role,
    user?: UserResponse,
    assignment: RoleAssignment
}

export interface RoleAssignmentResult {
    from: DateTime
    to?: DateTime
    user_id: number
    role_id: number
}

@Component({
    selector: 'eaw-update-assignment-dialog',
    templateUrl: './role-assignment-dialog.component.html',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        RoleAutocompleteComponent,
        CustomerUserAutocompleteComponent,
        MatFormFieldModule,
        MatDatepickerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class RoleAssignmentDialogComponent extends DialogComponent implements OnInit {
    customerId!: number;
    confirmText: string;
    form = new FormGroup({
        user: new FormControl<User | null | undefined>(null),
        role: new FormControl<Role | null | undefined>(null),
        from: new FormControl<DateTime>(DateTime.now()),
        to: new FormControl<DateTime | null>(null),
    });

    assignment: RoleAssignment;
    user?: UserResponse;
    role?: Role;

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<RoleAssignmentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: RoleAssignmentDialogData,
    ) {
        data.size = DialogSize.Small;
        super(dialogRef, data);

        this.customerId = data.customerId;
        this.assignment = data.assignment;
        this.user = data.user ?? data.assignment.user;
        this.role = data.role;

        this.confirmText = this.assignment.id ? 'UPDATE' : 'CREATE';
        this.form.setValue({
            user: this.user ? new User(this.user) : null,
            role: data.role ?? null,
            from: data.assignment.from ?? DateTime.now(),
            to: data.assignment.to,
        });
    }

    ngOnInit(): void {
        const role = this.form.controls.role;
        const user = this.form.controls.user;

        if (this.assignment.id || this.role) {
            role.disable();
        } else {
            role.addValidators([ Validators.required ]);
        }

        if (this.assignment.id || this.user) {
            user.disable();
        } else {
            user.addValidators([ Validators.required ]);
        }
    }

    close() {
        const value = this.form.value;
        const from = value.from?.startOf('day');
        const userId = value.user?.id ?? this.user?.id;
        const roleId = value.role?.id ?? this.role?.id;

        if (from && userId && roleId) {
            const dialogResult: RoleAssignmentResult = {
                from,
                to: value.to?.endOf('day'),
                user_id: userId,
                role_id: roleId,
            };

            this.dialogRef.close(dialogResult);
        }
    }
}
