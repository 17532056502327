import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, DialogSize } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { MatSelectionListChange, MatListModule } from '@angular/material/list';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs';
import { normalizeString, sort } from '../../angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../services/current.service';
import { Customer } from '../../models/customer';
import { UIRouter } from '@uirouter/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '../../services/translate.service';
import { BadgerService } from '../../services/badger.service';
import { ChangeLocationService } from '../../services/change-location.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

export interface ChangeLocationDialogData {
    size?: DialogSize,
    customer: Customer,
}

interface ChangeLocationCustomer {
    id: number,
    name: string,
    lowercaseName: string,
    safeLowercaseName: string,
}

@Component({
    selector: 'eaw-change-location-dialog',
    templateUrl: './change-location-dialog.component.html',
    styleUrl: './change-location-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatListModule,
        NgFor,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ChangeLocationDialogComponent extends DialogComponent implements OnInit {
    customers: ChangeLocationCustomer[] = [];
    filteredCustomers: ChangeLocationCustomer[] = [];
    filterControl = new FormControl('');
    changing = false;

    @ViewChild('filterInput') filterInput?: ElementRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ChangeLocationDialogData,
        @Inject(CurrentService) public current: CurrentService,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ChangeLocationDialogComponent>,
        @Inject(UIRouter) public uiRouter: UIRouter,
        @Inject(MatDialog) public dialog: MatDialog,
        @Inject(MatSnackBar) public snackBar: MatSnackBar,
        @Inject(TranslateService) public translate: TranslateService,
        @Inject(BadgerService) public badgerService: BadgerService,
        @Inject(ChangeLocationService) public changeLocationService: ChangeLocationService,
    ) {
        super(dialogRef, data);
    }

    ngOnInit(): void {
        this.customers = this.simplifyCustomers();
        this.filteredCustomers = this.customers;

        this.filterControl.valueChanges.pipe(
            map((filter) => (filter || '').toLowerCase()),
        ).subscribe((filter) => {
            this.filteredCustomers = this.customers.filter((customer) => {
                return [ customer.lowercaseName, customer.safeLowercaseName ].some((n) => n.includes(filter));
            });
        });

        this.dialogRef.afterOpened().subscribe(() => {
            this.filterInput?.nativeElement.focus();
        });
    }

    simplifyCustomers(): ChangeLocationCustomer[] {
        const customers = Object.values(this.current.getCustomers()).map((customer) => {
            return {
                id: customer.id,
                name: customer.name,
                lowercaseName: customer.name.toLowerCase(),
                safeLowercaseName: normalizeString(customer.name).toLowerCase(),
            };
        });

        return sort(customers, this.current.languageTag, [ (customer) => customer.safeLowercaseName ], [ 'asc' ]);
    }

    async change(event: MatSelectionListChange) {
        this.changing = true;
        this.dialogRef.disableClose = true;

        const option = event.options[0];
        const customerId = option ? (option.value as ChangeLocationCustomer).id : undefined;
        if (customerId && Number.isFinite(customerId)) {
            await this.changeLocationService.go(customerId);
        }
    }
}
