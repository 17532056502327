import { stringToDateTime } from '../utils/string-to-date-time';
import { DateTime } from 'luxon';
import { ApiResponse } from '../interfaces/api-response';

export interface SystemAlertResponse extends ApiResponse {
    id: number;
    text: string;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
}

export class SystemAlert {
    id: number;
    text: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt?: DateTime | null;

    constructor(data: SystemAlertResponse) {
        this.id = data.id;
        this.text = data.text;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
