// @ts-nocheck
import { module } from 'angular';

module('eaw.dialogs').directive('closeDialog', [ '$mdDialog', function closeDialog($mdDialog) {
    return {
        restrict: 'A',
        scope: {
            resolve: '<?',
            reason: '<?',
        },
        link(scope, element) {
            element[0].addEventListener('click', () => {
                // @ts-ignore
                if (scope.resolve) {
                    // @ts-ignore
                    $mdDialog.hide(scope.reason);
                } else {
                    // @ts-ignore
                    $mdDialog.cancel(scope.reason);
                }
            });
        },
    };
} ]);
