// @ts-nocheck
import { module } from 'angular';
import { FileSizeFormatter, FileSizeUnit } from '../../../shared/angularjs/modules/misc/services/file-size-formatter.service';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
module('eaw.filesystem').service('FilesystemFile', [ 'FilesystemItem', function FilesystemFileClass(FilesystemItem) {
    class FilesystemFile extends FilesystemItem {
        constructor(file, parent) {
            super(file, parent);
            const filesizeFormatter = new FileSizeFormatter(CurrentOld.languageTag, 'short');
            const filesizeFormatterByte = new FileSizeFormatter(CurrentOld.languageTag, 'long', FileSizeUnit.Byte);
            this.name = file.name;
            this.file = file.attachments?.[0] || {};
            this.size = {
                integer: this.file.size,
                defaultFormat: filesizeFormatter.format(this.file.size),
                byteFormat: filesizeFormatterByte.format(this.file.size),
            };
        }
    }
    return FilesystemFile;
} ]);
