import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Qualification, QualificationResponse } from '../models/qualification';
import { formatHttpParams } from '../utils/format-http-params';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { DateTime } from 'luxon';
import { QualificationEmployee, QualificationEmployeeResponse } from '../models/qualification-employee';
import { BUSINESS_DATES } from './http-contexts';

@Injectable({
    providedIn: 'root',
})
export class QualificationService {
    constructor(@Inject(HttpClient) protected http: HttpClient) { }

    // `options.count_date` is used with `options.count: ['activeEmployees']` to define which date should be considered active
    getAll(customerId: number, options?: PaginationOptions & { count_date?: DateTime }): Observable<ArrayPaginatedResponse<Qualification>> {
        return this.http.get<ArrayPaginatedResponse<QualificationResponse>>(`/customers/${customerId}/qualifications`, {
            params: formatHttpParams(options, [ 'count_date' ]),
        }).pipe(classifyArrayPaginatedResponse(Qualification));
    }

    get(customerId: number, qualificationId: number, options?: { with: string[] }): Observable<Qualification> {
        return this.http.get<QualificationResponse>(`/customers/${customerId}/qualifications/${qualificationId}`, {
            params: formatHttpParams({
                'with[]': options?.with,
            }),
        }).pipe(classifyItem(Qualification));
    }

    getEmployees(customerId: number, qualificationId: number, pagination: PaginationOptions, options: { from?: DateTime, to?: DateTime }): Observable<ArrayPaginatedResponse<QualificationEmployee>> {
        return this.http.get<ArrayPaginatedResponse<QualificationEmployeeResponse>>(`/customers/${customerId}/qualifications/${qualificationId}/employees`, {
            params: formatHttpParams({
                from: options.from?.toFormat('yyyy-MM-dd'),
                to: options.to?.toFormat('yyyy-MM-dd'),
                ...pagination,
            }),
        }).pipe(classifyArrayPaginatedResponse(QualificationEmployee));
    }

    getForEmployee(employeeId: number, customerId: number, options: PaginationOptions): Observable<ArrayPaginatedResponse<Qualification>> {
        return this.http.get<ArrayPaginatedResponse<QualificationResponse>>(`/customers/${customerId}/employees/${employeeId}/qualifications`, {
            params: {
                ...options,
            },
        }).pipe(classifyArrayPaginatedResponse(Qualification));
    }

    addQualification(customerId: number, options: { name: string }): Observable<Qualification> {
        return this.http.post<QualificationResponse>(`/customers/${customerId}/qualifications`, {
            ...options,
        }).pipe(classifyItem(Qualification));
    }

    updateQualification(customerId: number, qualificationId: number, options: { name?: string, from?: DateTime, to?: DateTime | null }): Observable<Qualification> {
        return this.http.put<QualificationResponse>(`/customers/${customerId}/qualifications/${qualificationId}`, {
            ...options,
        }).pipe(classifyItem(Qualification));
    }

    deleteQualification(customerId: number, qualificationId: number) {
        return this.http.delete(`/customers/${customerId}/qualifications/${qualificationId}`);
    }

    addQualificationsToEmployeeFromBusinessUnits(customerId: number, employeeId: number, unitIds: number[]) {
        return this.http.post<undefined>(`/customers/${customerId}/employees/${employeeId}/add_business_unit_qualifications`, { business_unit_ids: unitIds });
    }

    addQualificationToEmployee(customerId: number, employeeId: number, options: {
        id: number,
        rate: number,
        from: DateTime,
        to: DateTime | null | undefined,
    }) {
        return this.http.post<Qualification>(`/customers/${customerId}/employees/${employeeId}/qualifications`, {
            id: options.id,
            rate: options.rate,
            from: options.from,
            to: options.to,
        }, {
            context: new HttpContext().set(BUSINESS_DATES, [ 'from', 'to' ]),
        });
    }

    updateQualificationForEmployee(customerId: number, employeeId: number, qualificationId: number, options: { from: DateTime, to: DateTime | null | undefined }) {
        return this.http.put(`/customers/${customerId}/employees/${employeeId}/qualifications/${qualificationId}`,{
            from: options.from,
            to: options.to,
        }, {
            context: new HttpContext().set(BUSINESS_DATES, [ 'from', 'to' ]),
        });
    }

    deleteQualificationFromEmployee(customerId: number, employeeId: number, qualificationId: number) {
        return this.http.delete(`/customers/${customerId}/employees/${employeeId}/qualifications/${qualificationId}`);
    }
}
