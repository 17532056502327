import { booleanAttribute, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateTime } from 'luxon';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateTimeUnit } from 'luxon/src/datetime';
import { DateTimePipe } from '../../pipes/date-time.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatIconSizeDirective } from '../../directives/mat-icon-size.directive';
import { MatIconModule } from '@angular/material/icon';
import { DebounceClickDirective } from '../../directives/debounce-click.directive';
import { MatRippleModule } from '@angular/material/core';

@Component({
    selector: 'eaw-date-interval-selector',
    templateUrl: './date-interval-selector.component.html',
    styleUrl: './date-interval-selector.component.scss',
    standalone: true,
    imports: [
        MatRippleModule,
        DebounceClickDirective,
        MatIconModule,
        MatIconSizeDirective,
        NgIf,
        MatInputModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class DateIntervalSelectorComponent implements OnInit, OnChanges {
    @Output() intervalChange = new EventEmitter<{ from: DateTime, to: DateTime }>();

    // Disable custom select
    @Input({ transform: booleanAttribute }) disableCustomSelect = false;
    // Initial from
    @Input() from?: DateTime | undefined;
    // Initial to
    @Input() to?: DateTime | undefined;
    // Interval we are working with
    @Input({ required: true }) interval!: DateTimeUnit;
    // Debounce
    @Input() debounce = 500;
    /**
     * If true, then it's not an interval, but a single date.
     * Meaning from and to are the same.
     */
    @Input({ transform: booleanAttribute }) singleDate = false;

    private lastFrom: DateTime | undefined = undefined;
    private lastTo: DateTime | undefined = undefined;
    protected loading = false;
    protected singleDateFormat = 'dd.MM.yyyy';

    date = new FormControl<DateTime>(DateTime.now(), { nonNullable: true });
    dateRange = new FormGroup({
        from: new FormControl<DateTime | null>(null),
        to: new FormControl<DateTime | null>(null),
    });

    ngOnInit() {
        this.dateRange.patchValue({
            from: this.from,
            to: this.to,
        });

        if (this.singleDate) {
            this.singleDateFormat = this.interval === 'month' ? 'MMMM yyyy' : 'dd.MM.yyyy';
        }

        this.date.patchValue(this.from || DateTime.now(), { emitEvent: false });
        this.date.valueChanges.subscribe(this.onDebounceClick.bind(this));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['from']?.currentValue) {
            this.dateRange.patchValue({
                from: changes['from'].currentValue,
            });
        }

        if (changes['to']?.currentValue) {
            this.dateRange.patchValue({
                to: changes['to'].currentValue,
            });
        }
    }

    onDebounceClick() {
        const from = this.interval === 'day' ? this.date.value : this.dateRange.value.from;
        const to = this.interval === 'day' ? this.date.value : this.dateRange.value.to;

        // If either from or to is missing then we need to reset the values
        if (!from || !to) {
            // If we have last values then we can use them
            if (this.lastFrom && this.lastTo) {
                this.dateRange.patchValue({
                    from: this.lastFrom,
                    to: this.lastTo,
                });
            } else {
                // Otherwise we need to reset the values
                this.dateRange.patchValue({
                    from: this.from,
                    to: this.to,
                });
            }

            // Don't emit when we did a reset
            return;
        }

        // Only do the same day check if we are not working with days
        if (this.interval !== 'day') {
            // Don't emit if the values are the same
            if (this.lastFrom?.hasSame(from, 'day') && this.lastTo?.hasSame(to, 'day')) {
                return;
            } else {
                this.lastFrom = from;
                this.lastTo = to;
            }
        }

        if (this.singleDate) {
            this.intervalChange.emit({
                from: from.startOf(this.interval),
                to: from.startOf(this.interval),
            });
        } else {
            this.intervalChange.emit({
                from,
                to,
            });
        }
    }

    changeInterval(change: 1 | -1) {
        if (this.interval === 'day') {
            this.date.patchValue(this.date.value.plus({ day: change }).startOf('day'), { emitEvent: false });
        } else {
            const from = this.dateRange.controls.from.value?.plus({ [this.interval]: change }).startOf(this.interval);
            const to = from?.endOf(this.interval);

            this.dateRange.patchValue({
                from,
                to,
            }, { emitEvent: false });
        }
    }
}
