import { Inject, Injectable } from '@angular/core';
import { catchError, lastValueFrom, Observable, of } from 'rxjs';
import { Mobile } from '../utils/eaw-mobile';
import { HttpClient, HttpContext } from '@angular/common/http';
import { IGNORE_ERROR } from '../http/http-contexts';

enum PushPlatform {
    android = 'gcm',
    ios = 'apn',
}

@Injectable({
    providedIn: 'root',
})
export class PushService {
    constructor(
        @Inject(HttpClient) public http: HttpClient,
    ) {
    }

    getSubscriptions(user: number): Observable<unknown> {
        return this.http.get(`/users/${user}/push_subscriptions`, { params: { user } });
    }

    getPushId(): Promise<string> {
        return new Promise((res) => Mobile.getPushId(res));
    }

    async getPlatform() {
        const device = await Mobile.getDevice();
        return device?.platform.toLowerCase() as keyof typeof PushPlatform | undefined;
    }

    async getPushType(): Promise<PushPlatform | undefined> {
        const platform = await this.getPlatform();
        return platform ? PushPlatform[platform] : undefined;
    }

    async register(user: number) {
        const type = await this.getPushType();
        const pushId = await this.getPushId();
        if (!(type && pushId)) {
            return;
        }

        return lastValueFrom(this.http.post(`/users/${user}/push_subscriptions`, {
            type,
            subscription: pushId,
        }, {
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(
            catchError(() => {
                return of(undefined);
            }),
        ));
    }

    async deregister(user: number) {
        const type = await this.getPushType();
        const pushId = await this.getPushId();
        if (!(type && pushId)) {
            return;
        }

        return lastValueFrom(this.http.delete(`/users/${user}/push_subscriptions/${pushId}`, {
            body: { type },
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(
            catchError(() => {
                return of(undefined);
            }),
        ));
    }
}
