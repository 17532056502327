import { module } from 'angular';
import { createState } from '../shared/utils/create-state';
import { Namespace } from '../shared/enums/namespace';
import { KnowledgeCenterPageComponent } from './pages/knowledge-center-page/knowledge-center-page.component';
import { resolver } from '../shared/resolvers/resolver';
import { LearningModuleService } from './services/learning-module.service';

module('eaw').config([ '$stateProvider', function config($stateProvider: any) {
    createState($stateProvider, {
        name: 'eaw/app/knowledge_center',
        parent: 'eaw/app',
        url: '/knowledge_center',
        views: {
            'content@': {
                component: KnowledgeCenterPageComponent,
            },
        },
        resolve: [
            resolver('permissions', (transition) => {
                const learningModuleService = transition.injector().get(LearningModuleService) as LearningModuleService;
                return learningModuleService.setPermissions();
            }),
        ],
        data: {
            breadcrumb: { key: 'KNOWLEDGE_CENTER', ns: Namespace.LearningModule },
        },
    });
} ]);
