import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { classifyArrayPaginatedResponse } from '../../shared/utils/rxjs/classify';
import { KpiType, KpiTypeResponse } from '../models/kpi-type';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class KpiTypeService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, options?: PaginationOptions): Observable<ArrayPaginatedResponse<KpiType>> {
        return this.http.get<ArrayPaginatedResponse<KpiTypeResponse>>(`customers/${customerId}/kpi_types`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(KpiType));
    }
}
