// @ts-nocheck
import { module } from 'angular';

const template1 = `<div class="item-legend-item"
     ng-repeat="item in $ctrl.items track by $index"
     ng-class="{'clickable': $ctrl.itemClick && item.color}"
     ng-click="$ctrl.click(item)">
    <div class="item-color"
         ng-class="item.cssClass"
         ng-style="{
         'background': item.selected ? item.color : 'transparent',
         'border': item.border
         }">
    </div>
    <div class="item-name overflow-ellipsis" ng-bind="item.text"></div>
</div>
`;

/**
 * @ngdoc component
 * @name itemLegend
 * @restrict E
 *
 * @description
 * Card that displays a legend for an array of items
 *
 * @scope
 *
 * @param {Object[]} items - The items to show in the legend
 * @param {string} items[].color - Color of the legend item (Must be valid CSS)
 * @param {string} items[].text - Text to show beside the color
 * @param {string} [maxHeight] - Max height of card (Must be valid CSS)
 */
module('eaw.components').component('itemLegend', {
    template: template1,
    bindings: {
        items: '=',
        itemClick: '&?',
    },
    controller: [ '$element', function($element) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.element = $element[0];

            ctrl.items.forEach((i) => {
                i.selected = !!i.color;
            });
        };

        ctrl.click = (item) => {
            if (!ctrl.itemClick || !item.color) {
                return;
            }
            item.selected = !item.selected;
            ctrl.itemClick({ item });
        };
    } ],
});
