<mat-card>
    <mat-card-header class="tw-pb-16">
        {{ 'CALENDAR_SYNC_HELP_TEXT' | translate: 'calendar'|async }}
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="form" class="tw-grid tw-grid-cols-2 tw-gap-16">
            <mat-form-field>
                <mat-label>
                    {{ 'CUSTOMERS' | translate | async }}
                </mat-label>
                <mat-select multiple formControlName="customerIds">
                    @for (customer of customers(); track customer.id) {
                        <mat-option [value]="customer.id">
                            {{ customer.name }}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'EVENT_plural' | translate: 'calendar' | async }}</mat-label>
                <mat-select formControlName="eventTypes" multiple>
                    <mat-option value="shift">
                        {{ 'CALENDAR' | translateSync: 'navigation' }}
                    </mat-option>
                    <mat-option value="shift">
                        {{ 'SHIFT' | translateSync }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <eaw-action-button type="submit"
                               [disabled]="form.invalid"
                               [loading]="false"
                               (click)="generateUrl()">{{ 'GENERATE' | translate | async }}
            </eaw-action-button>
        </form>
    </mat-card-content>
</mat-card>

@if (urlGenerated()) {
    <mat-card class="tw-mt-20">
        <mat-card-content>
            <mat-form-field class="tw-w-full">
                <mat-label>{{ 'URL' }}</mat-label>
                <input matInput readonly [value]="url()">
                <button type="button"
                        mat-icon-button
                        matSuffix
                        [matTooltip]="('COPY_LINK' | translate: 'calendar' | async) || ''"
                        (click)="copyUrl()"
                        [disabled]="!url">
                    <mat-icon>content_copy</mat-icon>
                </button>
                <mat-hint>
                    {{ 'CALENDAR_SYNC_URL_HELP_TEXT' | translate: 'calendar' | async }}
                </mat-hint>
            </mat-form-field>
        </mat-card-content>
    </mat-card>
}
