import { Namespace } from '../../shared/enums/namespace';
import { NumberFormatterService } from '../../shared/services/number-formatter.service';
import type { Kpi } from './kpi';
import type { KpiProviderSum } from './kpi-provider-sum';
import { ApiResponse } from '../../shared/interfaces/api-response';

type KpiTypeSum = 'add' | 'average' | 'median' | null;

export interface KpiTypeResponse extends ApiResponse {
    id: number;
    key: string;
    name: string;
    prefix: string;
    provider_class: null | string;
    suffix: '' | '%';
    sum: KpiTypeSum;
    type: 'integer' | 'int' | 'float';
}

export class KpiType {
    id: number;
    // Just a unique key
    key: string;
    // Translation key ex. 'kpi_types:NET_BUDGET'
    name: string;
    // Key in WebTranslateIt
    wtiKey: string;
    prefix: string;
    providerClass: null | string;
    suffix: '' | '%';
    sumMethod: KpiTypeSum;
    type: 'integer' | 'float';

    constructor(data: KpiTypeResponse) {
        this.id = data.id;
        this.key = data.key;
        this.name = data.name;
        this.wtiKey = data.name.replace(`${Namespace.KPITypes}:`, '');
        this.prefix = data.prefix;
        this.providerClass = data.provider_class;
        this.suffix = data.suffix;
        this.sumMethod = data.sum;
        this.type = data.type === 'int' ? 'integer' : data.type;
    }

    get i18name() {
        const [ ns, key ] = this.wtiKey.split(':');

        if (!ns) {
            return { ns: Namespace.KPITypes, key: this.wtiKey };
        }

        if (!key) {
            return { ns: Namespace.KPITypes, key: ns };
        } else {
            return { ns, key };
        }
    }

    /**
     * Formats a value according to this KPI type
     * @param value
     * @param language
     * @param numberFormatterService
     * @param compact
     */
    valueFormatter(value: unknown, language: string, numberFormatterService: NumberFormatterService, compact = false) {
        const parsed = parseFloat(String(value));
        let number = Number.isFinite(parsed) ? parsed : undefined;
        if (number === undefined) {
            return '';
        }

        const options: Intl.NumberFormatOptions = {};

        if (this.type === 'integer') {
            options.maximumFractionDigits = 0;
            options.minimumFractionDigits = 0;

            if (compact && number >= 10_000) {
                options.notation = 'compact';
                options.compactDisplay = 'short';
                options.maximumFractionDigits = 1;
            }
        }

        if (this.type === 'float') {
            options.maximumFractionDigits = 2;
            options.minimumFractionDigits = 2;

            if (compact) {
                options.notation = 'compact';
                options.compactDisplay = 'short';
            }
        }

        if (this.suffix === '%') {
            if (Number.isFinite(value)) {
                number = number / 100;
            }

            options.style = 'percent';
        }

        return numberFormatterService.format(language, number, options);
    }

    /**
     * Calculates the sum for this type for the passed in customer
     */
    sumForCustomer(customerId: number, kpis: Kpi[], providerSums: KpiProviderSum[] | Record<string | number, KpiProviderSum> | null | undefined, language: string, numberFormatterService: NumberFormatterService, compact = false) {
        const typeProviderSums = Object.values(providerSums || []).filter((sum) => sum.kpiTypeId === this.id && sum.customerId === customerId);
        const typeKpis = kpis.filter((kpi) => kpi.kpiTypeId == this.id && kpi.customerId == customerId);
        const items = typeProviderSums.length ? typeProviderSums : typeKpis;

        return this.sum(items.map((i) => i.value), language, numberFormatterService, compact);
    }

    /**
     * Calculates the sum for this type across all customers
     */
    sumAll(kpis: Kpi[], providerSums: KpiProviderSum[] | Record<string | number, KpiProviderSum> | null | undefined, language: string, numberFormatterService: NumberFormatterService, compact = false) {
        const typeProviderSums = Object.values(providerSums || []).filter((sum) => sum.kpiTypeId === this.id);
        const typeKpis = kpis.filter((kpi) => kpi.kpiTypeId == this.id);
        const items = typeProviderSums.length ? typeProviderSums : typeKpis;

        return this.sum(items.map((i) => i.value), language, numberFormatterService, compact);
    }

    private sum(values: number[], language: string, numberFormatterService: NumberFormatterService, compact = false) {
        let sum;

        switch (this.sumMethod) {
            case 'add': {
                sum = values.reduce((sum, value) => sum + value, 0);
                break;
            }
            case 'average': {
                sum = values.reduce((sum, value) => sum + value, 0) / values.length;
                break;
            }
            case 'median': {
                const sortedTypeKpis = values.sort((aVal, bVal) => aVal - bVal);
                const lowMiddle = Math.floor((sortedTypeKpis.length - 1) / 2);
                const highMiddle = Math.ceil((sortedTypeKpis.length - 1) / 2);
                const lowMiddleItem = sortedTypeKpis[lowMiddle];
                const highMiddleItem = sortedTypeKpis[highMiddle];
                if (lowMiddleItem != null && highMiddleItem != null) {
                    sum = (lowMiddleItem + highMiddleItem) / 2;
                }
                break;
            }
            default: {
                sum = values.length === 1 ? values[0] : undefined;
            }
        }

        return {
            pure: sum,
            formatted: this.valueFormatter(sum, language, numberFormatterService, compact),
        };
    }
}
