@if (loading()) {
    <eaw-info-loading size="md"/>
} @else {
    @for (shift of shifts(); track shift.id) {
        <mat-list class="shift-list">
            <mat-list-item>
                <span matListItemTitle>{{shift.from | DateTime:'TIME_SIMPLE'}} - {{shift.to | DateTime:'TIME_SIMPLE'}}</span>
            </mat-list-item>
        </mat-list>

        <mat-list class="period-list">
            @for (period of shift.periods; track period.id) {
                <mat-list-item>
                    <span matListItemTitle>{{period.description || ('NO_DESCRIPTION' | translate | async)}}</span>
                    <span matListItemMeta>{{period.from | DateTime:'TIME_SIMPLE'}} - {{period.to | DateTime:'TIME_SIMPLE'}}</span>
                </mat-list-item>
            }
        </mat-list>

        <mat-divider></mat-divider>
    } @empty {
        <span>{{'NO_VACANT_SHIFTS' | translate:'widgets' | async}}</span>
    }
}
