// @ts-nocheck
import { t } from 'i18next';
import { forEach } from 'lodash-es';

import { module } from 'angular';

const template1 = `<md-autocomplete
        md-selected-item="$stacks.stack"
        md-search-text="filter"
        md-selected-item-change="$stacks.select()"
        md-min-length="0"
        md-input-id="{{$id}}autocomplete"
        md-delay="300"
        ng-disabled="$stacks.disable"
        md-floating-label="{{$stacks.label}}"
        md-clear-button="$stacks.clearBtn"
        ng-required="$stacks.required"
        md-items="item in $stacks.search(filter)"
        md-item-text="item.text">
    <md-item-template>
        <span md-highlight-text="filter" md-highlight-flags="gi">{{item.text}}</span>
    </md-item-template>
    <md-not-found>
        <span ng-i18next="NO_MATCH_FOUND"></span>
    </md-not-found>
</md-autocomplete>
`;

module('eaw.admin').component('eawStacks', {
    template: template1,
    require: {
        ngModel: 'ngModel',
    },
    controllerAs: '$stacks',
    controller: [ '$attrs', 'StackService', function($attrs, StackService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.clearBtn = true;
            ctrl.label = t('admin:STACK');
            ctrl.required = $attrs.$attr.required === 'required';
            ctrl.disable = $attrs.$attr.disabled === 'disabled';
            ctrl.ngModel.$formatters.unshift(ctrl.formatter);
        };

        ctrl.select = () => {
            ctrl.ngModel.setViewValue(ctrl.stack);
        };

        ctrl.setStackText = (stack) => {
            stack.text = `${stack.name} [${stack.provisioned ? 'Provisioned' : 'Not provisioned'}]`;
        };

        ctrl.search = (filter) => StackService.getAllPaginated({ filter }).$promise.then((resp) => {
            forEach(resp.data, ctrl.setStackText);
            return resp.data;
        });

        ctrl.formatter = (val) => {
            if (val) {
                ctrl.stack = val;
                ctrl.setStackText(ctrl.stack);
            }

            return val;
        };
    } ],
});
