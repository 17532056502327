import { ApiResponse } from '../../shared/interfaces/api-response';
import { BaseApiModel } from '../../shared/models/base-api-model';
import { CustomerResponse } from '../../shared/models/customer';

export interface ProductSubscriberResponse extends ApiResponse {
    product_name: string;
    subscriber_id: number;
    subscriber_type: 'customer' | 'group';
    subscriber: CustomerResponse;
}

export class ProductSubscriber extends BaseApiModel<ProductSubscriberResponse, ProductSubscriber> {
    subscriberId?: number;
    subscriberType?: 'customer' | 'group';
    productName: string;
    subscriber: CustomerResponse;

    constructor(data: ProductSubscriberResponse) {
        super(data, undefined);
        this.subscriber = data.subscriber;
        this.productName = data.product_name;
        this.subscriberId = data?.subscriber_id;
        this.subscriberType = data?.subscriber_type;
    }
}
