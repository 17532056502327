import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AvailabilityCreate, AvailabilityCreateInterval, AvailabilityService } from '../../http/availability.service';
import { distinctUntilChanged, filter, map, Observable, of, pairwise, startWith, Subscription } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { AvailabilityDayType } from '../../enums/availability-day-type';
import { Availability, AvailabilityRepeat } from '../../models/availability';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { EawValidators } from '../../../shared/validators/eaw-validators';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TimeInputComponent } from '../../../shared/components/date-time/time-input/time-input.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

type IntervalForm = { from: FormControl<DateTime | null>, to: FormControl<DateTime | null> };

type TimeIntervalFormGroup = FormGroup<IntervalForm>;

type DayForm = {
    type: FormControl<AvailabilityDayType>,
    intervals: FormArray<TimeIntervalFormGroup>,
}

type AvailabilityForm = {
    wantedDays: FormControl<number>
    days?: FormArray<FormGroup<DayForm>>
}

type CreateAvailabilityForm = FormGroup<{
    interval: FormGroup<{
        from: FormControl<DateTime | null>,
        to: FormControl<DateTime | null>,
    }>
    weeks: FormControl<number>
    availabilityWeeks: FormArray<FormGroup<AvailabilityForm>>
    comment: FormControl<string | null>
}>

@Component({
    selector: 'eaw-create-availability',
    templateUrl: './create-availability.component.html',
    styleUrl: './create-availability.component.scss',
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatRadioModule,
        TimeInputComponent,
        MatButtonModule,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class CreateAvailabilityComponent implements OnInit, OnChanges {
    @Input({ required: true }) customerId!: number;
    @Input() employeeId?: number;
    @Input() approved?: boolean;
    @Input() disabled = false;
    @Input() defaultAvailabilityDayType?: AvailabilityDayType;
    // Override from/to if provided
    @Input() fromTo?: { from?: DateTime | null, to?: DateTime | null };

    form?: CreateAvailabilityForm;
    types: { value: AvailabilityDayType, text: string }[];

    private weekChangeSub?: Subscription;
    private intervalChangeSub?: Subscription;

    minTo: DateTime | null = null;
    from: DateTime | null = null;
    to: DateTime | null = null;

    constructor(
        @Inject(AvailabilityService) protected availabilityService: AvailabilityService,
        @Inject(FormBuilder) protected formBuilder: FormBuilder,
    ) {
        this.types = [
            { value: AvailabilityDayType.Off, text: 'OFF' },
            { value: AvailabilityDayType.WholeDay, text: 'WHOLE_DAY' },
            { value: AvailabilityDayType.Time, text: 'AT_TIME' },
        ];
    }

    ngOnInit(): void {
        this.resetForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.form) {
            return;
        }

        const isDisabled: boolean = this.form.disabled;
        if (changes['disabled']) {
            const disable: boolean = changes['disabled'].currentValue;
            if (!isDisabled && disable) {
                this.form.disable();
            } else if (isDisabled && !disable) {
                this.form.enable();
            }
        }
    }

    /**
     * @param returnData - Return data without sending a request
     */
    create(returnData?: boolean): Observable<Availability | AvailabilityCreate | undefined> {
        const form = this.form?.getRawValue();
        if (!form) {
            return of(undefined);
        }

        const start = form.interval.from?.startOf('day');
        if (!start) {
            return of(undefined);
        }

        const weeksControls = this.form?.controls.availabilityWeeks.controls;
        if (!weeksControls) {
            return of(undefined);
        }

        const days = weeksControls.flatMap((weekCtrl, wIndex) => {
            const offset = wIndex * 7;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return weekCtrl.controls.days!.controls.flatMap((dayCtrl, i) => {
                const day = i + offset;
                const date: string | DateTime = start.plus({ day }).toUTC(0, { keepLocalTime: true });
                const type: AvailabilityDayType = dayCtrl.controls.type.value;

                switch (type) {
                    case AvailabilityDayType.WholeDay:
                        return [
                            {
                                day,
                                whole_day: true,
                                offset: 0,
                                length: 1,
                            },
                        ];
                    case AvailabilityDayType.Time:
                        return dayCtrl.controls.intervals.controls.map((intervalCtrl) => {
                            const controls = intervalCtrl.controls;

                            let from: DateTime | null = controls.from.value;
                            let to: DateTime | null = controls.to.value;
                            if (!from || !to) {
                                return null;
                            }

                            from = date.set({
                                hour: from.hour,
                                minute: from.minute,
                            }).startOf('second');
                            to = date.set({
                                hour: to.hour,
                                minute: to.minute,
                            }).startOf('second');

                            if (to <= from) {
                                to = to.plus({ day: 1 });
                            }

                            return {
                                day,
                                whole_day: false,
                                offset: Math.floor(from.diff(from.startOf('day')).as('seconds')),
                                length: Math.floor(to.diff(from).as('seconds')),
                            };
                        }).filter((v) => v != null);
                    case AvailabilityDayType.Off:
                    default:
                        return [ null ];
                }
            }).filter((v) => v != null) as AvailabilityCreateInterval[];
        });

        const interval = form.interval;
        const availabilityData: AvailabilityCreate = {
            from: start,
            to: interval.to?.plus({ day: 1 }) || undefined,
            days,
            approved: this.approved,
            repeat: form.weeks * 7 as AvailabilityRepeat,
            workDays: weeksControls.map((w) => {
                const val = w.controls.wantedDays.value;
                return !val || val <= 0 ? 5 : val;
            }),
            comment: form.comment || undefined,
        };

        if (availabilityData.to) {
            const length = availabilityData.to.diff(availabilityData.from).as('days');

            if (availabilityData.repeat > length) {
                availabilityData.repeat = Math.ceil(length);
            }
        }

        if (returnData) {
            return of(availabilityData);
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.availabilityService.create(this.customerId, this.employeeId!, availabilityData);
    }

    resetForm() {
        this.weekChangeSub?.unsubscribe();
        this.intervalChangeSub?.unsubscribe();

        // Find next monday
        let defaultFrom = DateTime.now().plus({ day: 1 });
        const weekday = defaultFrom.weekday; // 1-7, where monday = 1
        if (weekday > 1) {
            defaultFrom = defaultFrom.plus({ days: 8 - weekday });
        }

        if (this.fromTo?.from) {
            defaultFrom = this.fromTo.from;
        }

        this.minTo = defaultFrom.plus({ day: 1 });

        const fromCtrl: FormControl<DateTime | null> = new FormControl(defaultFrom, [ Validators.required ]);
        const toCtrl: FormControl<DateTime | null> = new FormControl(this.fromTo?.to || null);
        const weeksCtrl: FormControl<number> = new FormControl(1, { nonNullable: true });

        const form: CreateAvailabilityForm = new FormGroup({
            interval: new FormGroup({
                from: fromCtrl,
                to: toCtrl,
            }),
            weeks: weeksCtrl,
            availabilityWeeks: this.formBuilder.array([
                this.getAvailabilityWeekFormGroup(defaultFrom),
            ]),
            comment: new FormControl<string | null>(null),
        });

        if (this.fromTo) {
            form.controls.interval.disable();
        }

        // Changes to week field
        this.weekChangeSub = weeksCtrl.valueChanges.pipe(
            startWith(1),
            distinctUntilChanged(),
            pairwise(),
        ).subscribe(([ previous, current ]) => {
            const availabilityWeeksCtrl: FormArray<FormGroup<AvailabilityForm>> = form.controls.availabilityWeeks;

            if (previous < current) {
                // add week(s)
                for (let week = previous; week < current; week++) {
                    availabilityWeeksCtrl.push(this.getAvailabilityWeekFormGroup(defaultFrom, week));
                }
            } else {
                // remove week(s)
                for (let index = previous; index > current; index--) {
                    availabilityWeeksCtrl.removeAt(index - 1);
                }
            }
        });

        this.form = form;

        if (this.disabled) {
            this.form.disable();
        }
    }

    removeInterval(day: FormGroup<DayForm>, index: number) {
        day.controls.intervals.removeAt(index);
    }

    addInterval(day: FormGroup<DayForm>) {
        const form = new FormGroup<IntervalForm>({
            from: new FormControl<DateTime | null>(null),
            to: new FormControl<DateTime | null>(null),
        });
        day.controls.intervals.push(form);
        const index = day.controls.intervals.length - 1;

        form.valueChanges.pipe(
            filter((value) => {
                const length: number = day.controls.intervals?.length;
                const lastIndex: number = length - 1;
                // We always want one empty interval at the end, and we always want at least 2 intervals
                if (index != lastIndex || length < 3) {
                    return false;
                }

                const previousInterval = day.controls.intervals.at(index - 1)?.value;
                // If the previous interval is empty, we want to remove the current one.
                return !!previousInterval && !previousInterval.from && !previousInterval.to && !value.from && !value.to;
            }),
        ).subscribe({
            next: () => {
                this.removeInterval(day, index);
            },
        });
    }

    getDayName(dIndex: number): string {
        const day = (this.form?.controls.interval.controls.from.value || DateTime.now()).plus({ day: dIndex });

        return day?.toFormat('EEEE');
    }

    onIntervalFocusIn(day: FormGroup<DayForm>, tIndex: number): void {
        const lastIndex = day.controls.intervals.length - 1;
        // If this is the last interval
        if (tIndex == lastIndex) {
            const nextInterval = day.controls.intervals.at(tIndex + 1)?.controls;
            if (!nextInterval) {
                // Create a new interval
                this.addInterval(day);
            }
        }
    }

    fromChanged(event: MatDatepickerInputEvent<DateTime>) {
        const previous = this.from;

        this.minTo = event.value?.plus({ day: 1 }) ?? null;
        this.from = event.value;

        this.intervalDateChange([ previous, event.value ]);
    }

    toChanged(event: MatDatepickerInputEvent<DateTime>) {
        const previous = this.to;

        this.to = event.value;

        this.intervalDateChange([ previous, event.value ]);
    }

    intervalDateChange([ previous, current ]: [ DateTime | null, DateTime | null ]) {
        if (previous == current || !this.form) {
            return;
        }

        const form = this.form;
        const fromCtrl = this.form.controls.interval.controls.from;
        const toCtrl = this.form.controls.interval.controls.to;
        const weeksCtrl = form.controls.weeks;
        const availabilityWeeksCtrl = form.controls.availabilityWeeks;
        const from = fromCtrl.value;
        const to = toCtrl.value?.plus({ day: 1 });

        if (from && to) {
            const weeks = to.diff(from).as('weeks');
            const w = Math.ceil(weeks);
            if (w < weeksCtrl.value) {
                weeksCtrl.setValue(w); // Trigger removal of extra weeks.
            }

            if (weeks <= 1) {
                weeksCtrl.disable();
            } else if (weeksCtrl.disabled) {
                weeksCtrl.enable();
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const lastWeekDaysCtrl = availabilityWeeksCtrl.controls[availabilityWeeksCtrl.length - 1]!.controls.days!;
            if (weeks % 1) {
                // If weeks is a decimal number, we need to remove some days from the last week
                /** start of last week in availability */
                const newEnd = from.plus({
                    weeks,
                    day: 1,
                });
                let date = from.plus({
                    weeks: availabilityWeeksCtrl.length - 1,
                    days: lastWeekDaysCtrl.length,
                });

                if (date > newEnd) {
                    while (date > newEnd) {
                        date = date.minus({ day: 1 });
                        lastWeekDaysCtrl.removeAt(lastWeekDaysCtrl.length - 1);
                    }
                } else {
                    while (lastWeekDaysCtrl.length < 7 && date < newEnd) {
                        date = date.plus({ day: 1 });
                        lastWeekDaysCtrl.push(this.getDayForm());
                    }
                }
            } else if (lastWeekDaysCtrl.length < 7) {
                // If we have whole weeks, we need the last week to be whole
                while (lastWeekDaysCtrl.length < 7) {
                    lastWeekDaysCtrl.push(this.getDayForm());
                }

            }
        }
    }

    get availabilityEnd(): DateTime | null | undefined {
        return this.form?.controls?.interval?.controls?.to?.value;
    }

    protected getAvailabilityWeekFormGroup(availabilityStart: DateTime, week = 1): FormGroup<AvailabilityForm> {
        const days: FormGroup<DayForm>[] = [];
        const availabilityEnd = this.availabilityEnd?.plus({ day: 1 });

        let start = availabilityStart.plus({ week: week - 1 });
        let end = start.plus({ week: 1 });

        if (availabilityEnd && end > availabilityEnd) {
            end = availabilityEnd;
        }

        let max = 0;
        while (start < end) {
            const day = this.getDayForm();

            days.push(day);
            start = start.plus({ days: 1 });
            max++;
        }

        const wantedDays: number = max < 5 ? max : 5;
        return this.formBuilder.group<AvailabilityForm>({
            wantedDays: new FormControl(wantedDays, {
                nonNullable: true,
                validators: [ Validators.max(max), Validators.min(0), EawValidators.integer() ],
            }),
            days: this.formBuilder.array(days),
        });
    }

    protected getDayForm(): FormGroup<DayForm> {
        const day: FormGroup<DayForm> = new FormGroup<DayForm>({
            type: new FormControl(this.defaultAvailabilityDayType || AvailabilityDayType.WholeDay, { nonNullable: true }),
            intervals: this.formBuilder.array<TimeIntervalFormGroup>([]),
        });

        day.controls.type.valueChanges.pipe(
            distinctUntilChanged(),
            map((value) => {
                return value === AvailabilityDayType.Time;
            }),
        ).subscribe((shouldHaveTimes: boolean) => {
            const intervals: FormArray<TimeIntervalFormGroup> = day.controls.intervals;
            if (shouldHaveTimes) {
                intervals.push(new FormGroup<IntervalForm>({
                    from: new FormControl<DateTime | null>(DateTime.fromObject({
                        hour: 8,
                        minute: 0,
                        second: 0,
                    })),
                    to: new FormControl<DateTime | null>(DateTime.fromObject({
                        hour: 16,
                        minute: 0,
                        second: 0,
                    })),
                }));
                this.addInterval(day);
            } else {
                // Remove times.
                while (intervals.length > 0) {
                    intervals.removeAt(0);
                }
            }
        });

        return day;
    }

    showX(day: FormGroup<DayForm>, tIndex: number): boolean {
        const intervals: FormArray<TimeIntervalFormGroup> = day.controls.intervals;

        if (tIndex == intervals.length - 1) {
            // Never remove the last interval
            return false;
        }

        const interval = intervals.at(tIndex)?.value;

        // If it has a value, we can remove it
        if (interval.from && interval.to) {
            return true;
        }

        // If it doesn't have value, we can remove it if the next one doesn't have a value either
        const nextInterval = intervals.at(tIndex + 1)?.value;

        return !nextInterval?.from && !nextInterval?.to;
    }
}
