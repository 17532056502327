import { Component, inject, OnInit } from '@angular/core';
import { DialogComponent } from '../../../shared/dialogs/dialog-component';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HandleEmployeeTerminationData } from './handle-employee-termination.service';
import { UserGroup } from '../../models/user-group';
import { UserGroupService } from '../../../shared/http/user-group.service';
import { Employee } from '../../../shared/models/employee';
import { DateTime } from 'luxon';
import { forkJoin, Observable } from 'rxjs';
import { UserAccessService } from '../../http/user-access.service';
import { UserAccess } from '../../models/user-access';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { loadNamespaces } from 'i18next';
import { Namespace } from '../../../shared/enums/namespace';

type ShiftHandle = 'delete' | 'release';

export interface EmploymentTerminationHandling {
    handle_shifts: ShiftHandle;
}

@Component({
    selector: 'eaw-handle-employee-termination',
    templateUrl: './handle-employee-termination.component.html',
    styleUrl: './handle-employee-termination.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        CheckboxHelperDirective,
        MatDividerModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatRadioModule,
        NgFor,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class HandleEmployeeTerminationComponent extends DialogComponent<HandleEmployeeTerminationData> implements OnInit {
    private readonly userAccessService = inject(UserAccessService);
    private readonly userGroupService = inject(UserGroupService);

    formGroup = new FormGroup({
        removeAccess: new FormControl(true),
        handleShifts: new FormControl<ShiftHandle>('release', { nonNullable: true }),
        removeFromGroups: new FormControl<'all' | 'none' | 'some'>('all'),
        groupsToRemove: new FormControl<UserGroup[]>([], { nonNullable: true }),
    });

    customerId = this.data.employee.customerId;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    employee = this.data.employee instanceof Employee ? this.data.employee : this.data.employee.employee!;
    userId = this.employee.userId;
    userAccess: UserAccess[] = [];
    groups: UserGroup[] = [];
    deactivateNow = this.data.to ? this.data.to <= DateTime.now().startOf('day') : true;
    loadingEmployeeInfo = true;
    handlingEmployee = false;

    constructor() {
        super();

        this.formGroup.controls.removeFromGroups.valueChanges.subscribe((opt) => {
            if (opt === 'some') {
                this.formGroup.controls.groupsToRemove.setValidators(Validators.required);
            } else {
                this.formGroup.controls.groupsToRemove.clearValidators();
            }
            this.formGroup.controls.groupsToRemove.updateValueAndValidity();
        });

        if (this.userId) {
            forkJoin([
                this.userAccessService.getAllForUser(this.customerId, this.userId, { 'with[]': [ 'groups' ] }),
                this.userGroupService.getAllForCustomer(this.customerId, {
                    user_id: this.userId,
                }),
            ]).subscribe(([ access, groups ]) => {
                this.userAccess = access.data.filter((a) => !a.to || a.to > DateTime.now());
                this.groups = groups.data.filter((g) => !g.pivot.to || g.pivot.to > DateTime.now());
                this.loadingEmployeeInfo = false;
            });
        } else {
            this.loadingEmployeeInfo = false;
        }
    }

    async ngOnInit() {
        await loadNamespaces([ Namespace.Absences ]);
    }

    submit() {
        const to = this.data.to;
        const formValues = this.formGroup.getRawValue();
        this.formGroup.disable();
        const actions: Observable<UserAccess | object | undefined>[] = [];

        if (this.userId) {
            if (this.userAccess.length && formValues.removeAccess) {
                this.userAccess.forEach((access) => {
                    if (to) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        actions.push(this.userAccessService.update(this.customerId, this.userId!, access.id, access.from, to));
                    } else {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        actions.push(this.userAccessService.delete(this.customerId, this.userId!, access.id));
                    }
                });
            }

            if (this.groups.length && formValues.removeFromGroups !== 'none') {
                let groups = formValues.removeFromGroups === 'all' ? this.groups : formValues.groupsToRemove;
                if (to) {
                    const futureGroups = groups.filter((group) => group.pivot.from && group.pivot.from > to);
                    groups = groups.filter((group) => !group.pivot.from || group.pivot.from < to);
                    if (futureGroups.length) {
                        actions.push(this.userGroupService.removeMembers(futureGroups, this.userId));
                    }
                    actions.push(this.userGroupService.updateMembers(groups, this.userId, { to }));
                } else {
                    actions.push(this.userGroupService.removeMembers(groups, this.userId));
                }
            }
        }

        if (!actions.length) {
            this.dialogRef.close({ handle_shifts: formValues.handleShifts });
            return;
        }

        this.handlingEmployee = true;

        forkJoin(actions).subscribe({
            next: () => {
                this.dialogRef.close({ handle_shifts: formValues.handleShifts });
            },
            error: () => {
                this.handlingEmployee = false;
                this.formGroup.enable();
            },
        });
    }
}
