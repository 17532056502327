// @ts-nocheck
import { module } from 'angular';
import { IntervalOld } from '../../../interval-old';
const template1 = `<md-dialog layout="column">
    <form name="editDateIntervalDialogForm" ng-cloak>
        <eaw-dialog-header-old heading="edidCtrl.args.title"></eaw-dialog-header-old>

        <md-dialog-content>
            <div class="md-dialog-content">
                <eaw-date-interval
                        layout="row"
                        ng-model="edidCtrl.interval"
                        ng-if="!edidCtrl.includeTime"
                        from-required="edidCtrl.args.fromRequired"
                        to-required="edidCtrl.args.toRequired">
                </eaw-date-interval>

                <eaw-date-time-interval
                        layout="row"
                        ng-model="edidCtrl.interval"
                        ng-if="edidCtrl.includeTime"
                        from-required="edidCtrl.args.fromRequired"
                        to-required="edidCtrl.args.toRequired">
                </eaw-date-time-interval>
            </div>
        </md-dialog-content>
        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button ng-click="edidCtrl.submit()" ng-i18next="SUBMIT" ng-disabled="editDateIntervalDialogForm.$invalid || editDateIntervalDialogForm.$untouched"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>

`;
export class EditDateInterval {
    $mdDialog;
    static get $inject() {
        return [ '$mdDialog' ];
    }

    includeTime;
    interval;
    // binding
    args;
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    $onInit() {
        this.includeTime = !!this.args.includeTime;
        this.interval = new IntervalOld(this.args);
    }

    submit() {
        return this.$mdDialog.hide({
            from: this.interval.from,
            to: this.interval.to || null,
        });
    }
}
export class EditDateIntervalDialog {
    $mdDialog;
    static get $inject() {
        return [ '$mdDialog' ];
    }

    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    open(args) {
        return this.$mdDialog.show({
            multiple: true,
            template: template1,
            controller: EditDateInterval,
            controllerAs: 'edidCtrl',
            bindToController: true,
            locals: { args },
        });
    }
}
module('eaw.dialogs').service('EditDateIntervalDialog', EditDateIntervalDialog);
