// @ts-nocheck
import { module } from 'angular';
import { groupBy } from 'lodash-es';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.company').component('companySettingsPayTypes', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="navigation:PAY_TYPES"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <md-progress-circular ng-if="$ctrl.loading" class="md-progress-center-absolute"></md-progress-circular>

        <eaw-accordion multi-toggle="true">
            <eaw-accordion-panel ng-repeat="link in ::$ctrl.payTypeLinks track by link.id">
                <panel-header class="tw-flex tw-gap-8">
                    <strong class="header-code" style="min-width: 6ch" ng-bind="link.code"></strong>
                    <span class="header-name" ng-bind="link.name"></span>
                </panel-header>
                <panel-content class="tw-p-0">
                    <div layout="row">
                        <div class="table-container" flex="33">
                            <table class="table table-bordered-bottom">
                                <thead>
                                <tr>
                                    <th ng-i18next="PROPERTY"></th>
                                    <th ng-i18next="VALUE"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr ng-if="link.tariff">
                                    <td ng-i18next="TARIFF"></td>
                                    <td ng-bind="link.tariff.name"></td>
                                </tr>
                                <tr ng-repeat="property in link.properties">
                                    <td ng-bind="property.key"></td>
                                    <td ng-bind="property.value"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="table-container" flex="33">
                            <table class="table table-bordered-bottom">
                                <thead>
                                <tr>
                                    <th ng-i18next="company:CONTRACT_TYPE_plural"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr ng-repeat="contractType in ::link.contract_types track by contractType.id">
                                    <td ng-bind="contractType.name"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="table-container" flex="33">
                            <table class="table table-bordered-bottom">
                                <thead>
                                <tr>
                                    <th ng-i18next="ABSENCE_TYPE_plural"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr ng-repeat="absenceType in ::link.absence_types track by absenceType.id">
                                    <td ng-bind="absenceType.name"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </panel-content>
            </eaw-accordion-panel>
        </eaw-accordion>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
    },
    controller: [ 'PayTypeLinkFactory', 'ContractTypeFactory', function(PayTypeLinkFactory, ContractTypeFactory) {
        const ctrl = this;
        ctrl.$onInit = async () => {
            ctrl.includeInactive = true;
            ctrl.loading = true;
            const contractTypesResp = await ContractTypeFactory.getAll({ setting_group_id: ctrl.customer.setting_group_id }).$promise;
            const contractTypes = contractTypesResp.data;
            const linksResp = await PayTypeLinkFactory.getAll({
                customer_id: ctrl.customer.id,
                per_page: 250,
                with: [ 'tariff', 'contractTypes', 'absenceTypes', 'properties' ],
                active: true,
            }).$promise;
            const zeroGroups = groupBy(linksResp.data, (link) => (link.code.match(/^0+/) || [ '' ])[0].length);
            // @ts-ignore
            const mostZeroes = Math.max(...Object.keys(zeroGroups));
            ctrl.payTypeLinks = [];
            for (let i = mostZeroes; i >= 0; i--) {
                if (!zeroGroups[i]) {
                    continue;
                }
                ctrl.payTypeLinks = ctrl.payTypeLinks.concat(sort(zeroGroups[i], CurrentOld.languageTag, [ (d) => d.code, (d) => d.name ], [ 'asc', 'asc' ]));
            }
            ctrl.payTypeLinks.forEach((link) => {
                link.contract_types = sort(link.contract_types.length ? link.contract_types : contractTypes, CurrentOld.languageTag, [ (ct) => ct.name ], [ 'asc' ]);
                link.absence_types = sort(link.absence_types, CurrentOld.languageTag, [ (at) => at.name ], [ 'asc' ]);
            });
            delete ctrl.loading;
        };
    } ],
});
