// @ts-nocheck
import { module } from 'angular';

const template1 = `<div class="checklist-category hover-item"
     layout="row"
     layout-align="start center"
     data-item-level="{{::($categoryItem.item.level % 2)}}"
     ng-click="$categoryItem.toggleChildren($categoryItem.item)">

    <div class="md-subhead tw-mr-12" ng-bind="::$categoryItem.item.title"></div>
    <md-icon class="s18" ng-bind="$categoryItem.item.expanded ? 'expand_less' : 'expand_more'"></md-icon>

    <span flex></span>

    <percent ng-model="$categoryItem.cs.percentages[$categoryItem.item.id].percentage"></percent>
</div>
`;

module('eaw.checklists').component('checklistViewCategoryItem', {
    template: template1,
    controllerAs: '$categoryItem',
    bindings: {
        checklist: '<',
        item: '<',
    },
    controller: [ 'ChecklistsService', function(ChecklistsService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.cs = ChecklistsService;
            ctrl.toggleChildren(ctrl.item, false); // Start closed
            ctrl.cs.updatePercentages(ctrl.checklist);
        };

        ctrl.toggleChildren = (item, forceStatus) => {
            item.expanded = forceStatus == null ? !item.expanded : forceStatus;
        };
    } ],
});
