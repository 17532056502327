var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj)) throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __privateMethod = (obj, member, method) => {
  __accessCheck(obj, member, "access private method");
  return method;
};
var _animation, _boxes, _previewBox, _currentBox, _boxPaddingLeft, _boxPaddingRight, _toggleRangeAnimation, toggleRangeAnimation_fn, _shouldRangeAnimate, shouldRangeAnimate_fn, _updateRange, _getBoxPosition, getBoxPosition_fn, _handlePointerMove, handlePointerMove_fn, _previewRequest, previewRequest_fn, _seekRequest, seekRequest_fn;
import { MediaChromeRange } from "./media-chrome-range.js";
import { globalThis, document } from "./utils/server-safe-globals.js";
import { MediaUIEvents, MediaUIAttributes } from "./constants.js";
import { nouns } from "./labels/labels.js";
import { formatAsTimePhrase } from "./utils/time.js";
import { isElementVisible } from "./utils/element-utils.js";
import { RangeAnimation } from "./utils/range-animation.js";
import { getOrInsertCSSRule, closestComposedNode, getBooleanAttr, setBooleanAttr, getNumericAttr, setNumericAttr, getStringAttr, setStringAttr } from "./utils/element-utils.js";
const DEFAULT_MISSING_TIME_PHRASE = "video not loaded, unknown time.";
const updateAriaValueText = el => {
  const range = el.range;
  const currentTimePhrase = formatAsTimePhrase(+calcTimeFromRangeValue(el));
  const totalTimePhrase = formatAsTimePhrase(+el.mediaSeekableEnd);
  const fullPhrase = !(currentTimePhrase && totalTimePhrase) ? DEFAULT_MISSING_TIME_PHRASE : `${currentTimePhrase} of ${totalTimePhrase}`;
  range.setAttribute("aria-valuetext", fullPhrase);
};
const template = document.createElement("template");
template.innerHTML = `
  <style>
    :host {
      --media-preview-border-radius: 3px;
      --media-box-padding-left: 10px;
      --media-box-padding-right: 10px;
    }

    #highlight {
      background: var(--media-time-range-buffered-color, rgb(255 255 255 / .4));
    }

    #preview-rail,
    #current-rail {
      ${""}
      width: 1%;
      position: absolute;
      left: 0;
      bottom: 100%;
      pointer-events: none;
    }

    [part~="box"] {
      ${""}
      position: absolute;
      bottom: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translateX(-50%);
    }

    [part~="preview-box"] {
      transition-property: var(--media-preview-transition-property, visibility, opacity);
      transition-duration: var(--media-preview-transition-duration-out, .25s);
      transition-delay: var(--media-preview-transition-delay-out, 0s);
      visibility: hidden;
      opacity: 0;
    }

    :host(:is([${MediaUIAttributes.MEDIA_PREVIEW_IMAGE}], [${MediaUIAttributes.MEDIA_PREVIEW_TIME}])[dragging]) [part~="preview-box"] {
      transition-duration: var(--media-preview-transition-duration-in, .5s);
      transition-delay: var(--media-preview-transition-delay-in, .25s);
      visibility: visible;
      opacity: 1;
    }

    @media (hover: hover) {
      :host(:is([${MediaUIAttributes.MEDIA_PREVIEW_IMAGE}], [${MediaUIAttributes.MEDIA_PREVIEW_TIME}]):hover) [part~="preview-box"] {
        transition-duration: var(--media-preview-transition-duration-in, .5s);
        transition-delay: var(--media-preview-transition-delay-in, .25s);
        visibility: visible;
        opacity: 1;
      }
    }

    media-preview-thumbnail,
    ::slotted(media-preview-thumbnail) {
      visibility: hidden;
      ${""}
      transition: visibility 0s .25s;
      transition-delay: calc(var(--media-preview-transition-delay-out, 0s) + var(--media-preview-transition-duration-out, .25s));
      background: var(--media-preview-thumbnail-background, var(--media-preview-background, var(--media-control-background, var(--media-secondary-color, rgb(20 20 30 / .7)))));
      box-shadow: var(--media-preview-thumbnail-box-shadow, 0 0 4px rgb(0 0 0 / .2));
      max-width: var(--media-preview-thumbnail-max-width, 180px);
      max-height: var(--media-preview-thumbnail-max-height, 160px);
      min-width: var(--media-preview-thumbnail-min-width, 120px);
      min-height: var(--media-preview-thumbnail-min-height, 80px);
      border: var(--media-preview-thumbnail-border);
      border-radius: var(--media-preview-thumbnail-border-radius,
        var(--media-preview-border-radius) var(--media-preview-border-radius) 0 0);
    }

    :host([${MediaUIAttributes.MEDIA_PREVIEW_IMAGE}][dragging]) media-preview-thumbnail,
    :host([${MediaUIAttributes.MEDIA_PREVIEW_IMAGE}][dragging]) ::slotted(media-preview-thumbnail) {
      transition-delay: var(--media-preview-transition-delay-in, .25s);
      visibility: visible;
    }

    @media (hover: hover) {
      :host([${MediaUIAttributes.MEDIA_PREVIEW_IMAGE}]:hover) media-preview-thumbnail,
      :host([${MediaUIAttributes.MEDIA_PREVIEW_IMAGE}]:hover) ::slotted(media-preview-thumbnail) {
        transition-delay: var(--media-preview-transition-delay-in, .25s);
        visibility: visible;
      }

      :host([${MediaUIAttributes.MEDIA_PREVIEW_TIME}]:hover) {
        --media-time-range-hover-display: block;
      }
    }

    media-preview-time-display,
    ::slotted(media-preview-time-display) {
      min-width: 0;
      ${""}
      transition: min-width 0s, border-radius 0s;
      transition-delay: calc(var(--media-preview-transition-delay-out, 0s) + var(--media-preview-transition-duration-out, .25s));
      background: var(--media-preview-time-background, var(--media-preview-background, var(--media-control-background, var(--media-secondary-color, rgb(20 20 30 / .7)))));
      border-radius: var(--media-preview-time-border-radius,
        var(--media-preview-border-radius) var(--media-preview-border-radius)
        var(--media-preview-border-radius) var(--media-preview-border-radius));
      padding: var(--media-preview-time-padding, 1px 10px 0);
      margin: var(--media-preview-time-margin, 0 0 10px);
      text-shadow: var(--media-preview-time-text-shadow, 0 0 4px rgb(0 0 0 / .75));
    }

    :host([${MediaUIAttributes.MEDIA_PREVIEW_IMAGE}]) media-preview-time-display,
    :host([${MediaUIAttributes.MEDIA_PREVIEW_IMAGE}]) ::slotted(media-preview-time-display) {
      transition-delay: var(--media-preview-transition-delay-in, .25s);
      min-width: 100%;
      border-radius: var(--media-preview-time-border-radius,
        0 0 var(--media-preview-border-radius) var(--media-preview-border-radius));
    }
  </style>
  <div id="preview-rail">
    <slot name="preview" part="box preview-box">
      <media-preview-thumbnail></media-preview-thumbnail>
      <media-preview-time-display></media-preview-time-display>
    </slot>
  </div>
  <div id="current-rail">
    <slot name="current" part="box current-box">
      ${""}
    </slot>
  </div>
`;
const calcRangeValueFromTime = (el, time = el.mediaCurrentTime) => {
  if (Number.isNaN(el.mediaSeekableEnd)) return 0;
  const value = (time - el.mediaSeekableStart) / (el.mediaSeekableEnd - el.mediaSeekableStart);
  return Math.max(0, Math.min(value, 1));
};
const calcTimeFromRangeValue = (el, value = el.range.valueAsNumber) => {
  if (Number.isNaN(el.mediaSeekableEnd)) return 0;
  return value * (el.mediaSeekableEnd - el.mediaSeekableStart) + el.mediaSeekableStart;
};
class MediaTimeRange extends MediaChromeRange {
  constructor() {
    super();
    __privateAdd(this, _toggleRangeAnimation);
    __privateAdd(this, _shouldRangeAnimate);
    __privateAdd(this, _getBoxPosition);
    __privateAdd(this, _handlePointerMove);
    __privateAdd(this, _previewRequest);
    __privateAdd(this, _seekRequest);
    __privateAdd(this, _animation, void 0);
    __privateAdd(this, _boxes, void 0);
    __privateAdd(this, _previewBox, void 0);
    __privateAdd(this, _currentBox, void 0);
    __privateAdd(this, _boxPaddingLeft, void 0);
    __privateAdd(this, _boxPaddingRight, void 0);
    __privateAdd(this, _updateRange, value => {
      if (this.dragging) return;
      this.range.valueAsNumber = value;
      this.updateBar();
    });
    this.container.appendChild(template.content.cloneNode(true));
    __privateSet(this, _boxes, this.shadowRoot.querySelectorAll('[part~="box"]'));
    __privateSet(this, _previewBox, this.shadowRoot.querySelector('[part~="preview-box"]'));
    __privateSet(this, _currentBox, this.shadowRoot.querySelector('[part~="current-box"]'));
    const computedStyle = getComputedStyle(this);
    __privateSet(this, _boxPaddingLeft, parseInt(computedStyle.getPropertyValue("--media-box-padding-left")));
    __privateSet(this, _boxPaddingRight, parseInt(computedStyle.getPropertyValue("--media-box-padding-right")));
    __privateSet(this, _animation, new RangeAnimation(this.range, __privateGet(this, _updateRange), 60));
    this.addEventListener("transitionstart", this);
  }
  static get observedAttributes() {
    return [...super.observedAttributes, MediaUIAttributes.MEDIA_PAUSED, MediaUIAttributes.MEDIA_DURATION, MediaUIAttributes.MEDIA_SEEKABLE, MediaUIAttributes.MEDIA_CURRENT_TIME, MediaUIAttributes.MEDIA_PREVIEW_IMAGE, MediaUIAttributes.MEDIA_PREVIEW_TIME, MediaUIAttributes.MEDIA_BUFFERED, MediaUIAttributes.MEDIA_PLAYBACK_RATE, MediaUIAttributes.MEDIA_LOADING, MediaUIAttributes.MEDIA_ENDED];
  }
  connectedCallback() {
    super.connectedCallback();
    this.range.setAttribute("aria-label", nouns.SEEK());
    __privateMethod(this, _toggleRangeAnimation, toggleRangeAnimation_fn).call(this);
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    __privateMethod(this, _toggleRangeAnimation, toggleRangeAnimation_fn).call(this);
  }
  attributeChangedCallback(attrName, oldValue, newValue) {
    super.attributeChangedCallback(attrName, oldValue, newValue);
    if (oldValue == newValue) return;
    if (attrName === MediaUIAttributes.MEDIA_CURRENT_TIME || attrName === MediaUIAttributes.MEDIA_PAUSED || attrName === MediaUIAttributes.MEDIA_ENDED || attrName === MediaUIAttributes.MEDIA_LOADING || attrName === MediaUIAttributes.MEDIA_DURATION || attrName === MediaUIAttributes.MEDIA_SEEKABLE) {
      __privateGet(this, _animation).update({
        start: calcRangeValueFromTime(this),
        duration: this.mediaSeekableEnd - this.mediaSeekableStart,
        playbackRate: this.mediaPlaybackRate
      });
      __privateMethod(this, _toggleRangeAnimation, toggleRangeAnimation_fn).call(this);
      updateAriaValueText(this);
    } else if (attrName === MediaUIAttributes.MEDIA_BUFFERED) {
      this.updateBufferedBar();
    }
  }
  get mediaPaused() {
    return getBooleanAttr(this, MediaUIAttributes.MEDIA_PAUSED);
  }
  set mediaPaused(value) {
    setBooleanAttr(this, MediaUIAttributes.MEDIA_PAUSED, value);
  }
  get mediaLoading() {
    return getBooleanAttr(this, MediaUIAttributes.MEDIA_LOADING);
  }
  set mediaLoading(value) {
    setBooleanAttr(this, MediaUIAttributes.MEDIA_LOADING, value);
  }
  get mediaDuration() {
    return getNumericAttr(this, MediaUIAttributes.MEDIA_DURATION);
  }
  set mediaDuration(value) {
    setNumericAttr(this, MediaUIAttributes.MEDIA_DURATION, value);
  }
  get mediaCurrentTime() {
    return getNumericAttr(this, MediaUIAttributes.MEDIA_CURRENT_TIME);
  }
  set mediaCurrentTime(value) {
    setNumericAttr(this, MediaUIAttributes.MEDIA_CURRENT_TIME, value);
  }
  get mediaPlaybackRate() {
    return getNumericAttr(this, MediaUIAttributes.MEDIA_PLAYBACK_RATE, 1);
  }
  set mediaPlaybackRate(value) {
    setNumericAttr(this, MediaUIAttributes.MEDIA_PLAYBACK_RATE, value);
  }
  get mediaBuffered() {
    const buffered = this.getAttribute(MediaUIAttributes.MEDIA_BUFFERED);
    if (!buffered) return [];
    return buffered.split(" ").map(timePair => timePair.split(":").map(timeStr => +timeStr));
  }
  set mediaBuffered(list) {
    if (!list) {
      this.removeAttribute(MediaUIAttributes.MEDIA_BUFFERED);
      return;
    }
    const strVal = list.map(tuple => tuple.join(":")).join(" ");
    this.setAttribute(MediaUIAttributes.MEDIA_BUFFERED, strVal);
  }
  get mediaSeekable() {
    const seekable = this.getAttribute(MediaUIAttributes.MEDIA_SEEKABLE);
    if (!seekable) return void 0;
    return seekable.split(":").map(time => +time);
  }
  set mediaSeekable(range) {
    if (range == null) {
      this.removeAttribute(MediaUIAttributes.MEDIA_SEEKABLE);
      return;
    }
    this.setAttribute(MediaUIAttributes.MEDIA_SEEKABLE, range.join(":"));
  }
  get mediaSeekableEnd() {
    var _a;
    const [, end = this.mediaDuration] = (_a = this.mediaSeekable) != null ? _a : [];
    return end;
  }
  get mediaSeekableStart() {
    var _a;
    const [start = 0] = (_a = this.mediaSeekable) != null ? _a : [];
    return start;
  }
  get mediaPreviewImage() {
    return getStringAttr(this, MediaUIAttributes.MEDIA_PREVIEW_IMAGE);
  }
  set mediaPreviewImage(value) {
    setStringAttr(this, MediaUIAttributes.MEDIA_PREVIEW_IMAGE, value);
  }
  get mediaPreviewTime() {
    return getNumericAttr(this, MediaUIAttributes.MEDIA_PREVIEW_TIME);
  }
  set mediaPreviewTime(value) {
    setNumericAttr(this, MediaUIAttributes.MEDIA_PREVIEW_TIME, value);
  }
  get mediaEnded() {
    return getBooleanAttr(this, MediaUIAttributes.MEDIA_ENDED);
  }
  set mediaEnded(value) {
    setBooleanAttr(this, MediaUIAttributes.MEDIA_ENDED, value);
  }
  updateBar() {
    super.updateBar();
    this.updateBufferedBar();
    this.updateCurrentBox();
  }
  updateBufferedBar() {
    var _a;
    const buffered = this.mediaBuffered;
    if (!buffered.length) {
      return;
    }
    let relativeBufferedEnd;
    if (!this.mediaEnded) {
      const currentTime = this.mediaCurrentTime;
      const [, bufferedEnd = this.mediaSeekableStart] = (_a = buffered.find(([start, end]) => start <= currentTime && currentTime <= end)) != null ? _a : [];
      relativeBufferedEnd = calcRangeValueFromTime(this, bufferedEnd);
    } else {
      relativeBufferedEnd = 1;
    }
    const {
      style
    } = getOrInsertCSSRule(this.shadowRoot, "#highlight");
    style.setProperty("width", `${relativeBufferedEnd * 100}%`);
  }
  updateCurrentBox() {
    if (!__privateGet(this, _currentBox).assignedElements().length) return;
    const boxPos = __privateMethod(this, _getBoxPosition, getBoxPosition_fn).call(this, __privateGet(this, _currentBox), this.range.valueAsNumber);
    const {
      style
    } = getOrInsertCSSRule(this.shadowRoot, "#current-rail");
    style.transform = `translateX(${boxPos})`;
  }
  handleEvent(evt) {
    super.handleEvent(evt);
    switch (evt.type) {
      case "input":
        __privateMethod(this, _seekRequest, seekRequest_fn).call(this);
        break;
      case "pointermove":
        __privateMethod(this, _handlePointerMove, handlePointerMove_fn).call(this, evt);
        break;
      case "pointerup":
      case "pointerleave":
        __privateMethod(this, _previewRequest, previewRequest_fn).call(this, null);
        break;
      case "transitionstart":
        setTimeout(() => __privateMethod(this, _toggleRangeAnimation, toggleRangeAnimation_fn).call(this), 0);
        break;
    }
  }
}
_animation = new WeakMap();
_boxes = new WeakMap();
_previewBox = new WeakMap();
_currentBox = new WeakMap();
_boxPaddingLeft = new WeakMap();
_boxPaddingRight = new WeakMap();
_toggleRangeAnimation = new WeakSet();
toggleRangeAnimation_fn = function () {
  if (__privateMethod(this, _shouldRangeAnimate, shouldRangeAnimate_fn).call(this)) {
    __privateGet(this, _animation).start();
  } else {
    __privateGet(this, _animation).stop();
  }
};
_shouldRangeAnimate = new WeakSet();
shouldRangeAnimate_fn = function () {
  return this.isConnected && isElementVisible(this) && !this.mediaPaused && !this.mediaLoading && !this.mediaEnded;
};
_updateRange = new WeakMap();
_getBoxPosition = new WeakSet();
getBoxPosition_fn = function (box, ratio) {
  var _a;
  let position = `${ratio * 100 * 100}%`;
  const boxWidth = box.offsetWidth;
  if (!boxWidth) return position;
  const bounds = (_a = this.getAttribute("bounds") ? closestComposedNode(this, `#${this.getAttribute("bounds")}`) : this.parentElement) != null ? _a : this;
  const rangeRect = this.range.getBoundingClientRect();
  const mediaBoundsRect = bounds.getBoundingClientRect();
  const boxMin = (__privateGet(this, _boxPaddingLeft) - (rangeRect.left - mediaBoundsRect.left - boxWidth / 2)) / rangeRect.width * 100;
  const boxMax = (mediaBoundsRect.right - rangeRect.left - boxWidth / 2 - __privateGet(this, _boxPaddingRight)) / rangeRect.width * 100;
  if (!Number.isNaN(boxMin)) position = `max(${boxMin * 100}%, ${position})`;
  if (!Number.isNaN(boxMax)) position = `min(${position}, ${boxMax * 100}%)`;
  return position;
};
_handlePointerMove = new WeakSet();
handlePointerMove_fn = function (evt) {
  const isOverBoxes = [...__privateGet(this, _boxes)].some(b => evt.composedPath().includes(b));
  if (!this.dragging && (isOverBoxes || !evt.composedPath().includes(this))) {
    __privateMethod(this, _previewRequest, previewRequest_fn).call(this, null);
    return;
  }
  const duration = this.mediaDuration;
  if (!duration) return;
  const rangeRect = this.range.getBoundingClientRect();
  let pointerRatio = (evt.clientX - rangeRect.left) / rangeRect.width;
  pointerRatio = Math.max(0, Math.min(1, pointerRatio));
  const boxPos = __privateMethod(this, _getBoxPosition, getBoxPosition_fn).call(this, __privateGet(this, _previewBox), pointerRatio);
  const {
    style
  } = getOrInsertCSSRule(this.shadowRoot, "#preview-rail");
  style.transform = `translateX(${boxPos})`;
  __privateMethod(this, _previewRequest, previewRequest_fn).call(this, pointerRatio * duration);
};
_previewRequest = new WeakSet();
previewRequest_fn = function (detail) {
  this.dispatchEvent(new globalThis.CustomEvent(MediaUIEvents.MEDIA_PREVIEW_REQUEST, {
    composed: true,
    bubbles: true,
    detail
  }));
};
_seekRequest = new WeakSet();
seekRequest_fn = function () {
  __privateGet(this, _animation).stop();
  const detail = calcTimeFromRangeValue(this);
  this.dispatchEvent(new globalThis.CustomEvent(MediaUIEvents.MEDIA_SEEK_REQUEST, {
    composed: true,
    bubbles: true,
    detail
  }));
};
if (!globalThis.customElements.get("media-time-range")) {
  globalThis.customElements.define("media-time-range", MediaTimeRange);
}
var media_time_range_default = MediaTimeRange;
export { media_time_range_default as default };