// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.scheduling.limits').component('schedulingLimitList', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="navigation:SCHEDULING_LIMITS"></span>
        </md-card-header-text>

        <card-btn-float ng-if="$list.canCreate" on-click="$list.new()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                cells="$list.cells"
                columns="$list.columns"
                reload-table="$list.reloadTable"
                get-data="$list.getData(params, pagination)">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    bindings: {
        factory: '<?',
    },
    controllerAs: '$list',
    controller: [ 'deleteItemAlert', 'SchedulingLimitDialog', 'ToastService', function(deleteItemAlert, SchedulingLimitDialog, ToastService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            /** @var SchedulingLimitDialog dialog */
            ctrl.dialog = new SchedulingLimitDialog(ctrl.factory);

            ctrl.columns = [
                { title: t('FROM') },
                { title: t('TO') },
                {
                    title: t('scheduling:LIMIT'),
                    class: 'text-right',
                },
                { title: '' },
            ];

            ctrl.cells = [
                { template: 'item.from | moment:"LL" ' },
                { template: 'item.to | moment:"LL" ' },
                {
                    template: 'item.limit / 3600 | eawNumber',
                    class: 'text-right',
                },
                {
                    buttons: [
                        {
                            icon: 'edit',
                            invisible(item) {
                                return !ctrl.canEdit(item);
                            },
                            click: ctrl.editLimit,
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            invisible(item) {
                                return !ctrl.canDelete(item);
                            },
                            click: ctrl.deleteLimit,
                        },
                    ],
                },
            ];
        };

        // Reload the page on cancel and submit, since we don't know if some were created.
        ctrl.new = () => {
            ctrl.dialog.new().then(() => {
                ToastService.created();
                ctrl.reload();
            });
        };

        ctrl.reload = () => {
            ctrl.reloadTable = true;
        };

        ctrl.editLimit = (limit) => ctrl.dialog.edit(limit).then(() => {
            ToastService.updated();
            ctrl.reload();
        });

        ctrl.canEdit = (limit) => CurrentOld.can(ctrl.factory.update.permission(limit.id));

        ctrl.deleteLimit = (limit) => deleteItemAlert.alertSimple().then(() => ctrl.factory.delete.query(limit.id).$promise.then(() => {
            ToastService.deleted();
            ctrl.reload();
        }));

        ctrl.canDelete = (limit) => CurrentOld.can(ctrl.factory.delete.permission(limit.id));

        ctrl.canCreate = (limit) => CurrentOld.can(ctrl.factory.create.permission(limit.id));

        ctrl.getData = (params, pagination) => ctrl.factory.getAll.query(pagination);
    } ],
});
