// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="'admin:PAY_TYPE' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <form name="modalForm" id="modalForm" class="md-dialog-content" role="form" autocomplete="off" ng-cloak ng-submit="ctrl.submit()">
            <md-input-container>
                <label for="payName"

                       ng-class="{'required': !ctrl.isUpdating}"
                       ng-i18next="NAME"></label>
                <input id="payName" name="payName" ng-model="ctrl.pay_type_link.name" required>
            </md-input-container>

            <md-input-container>
                <label for="payCode" class="required">Code</label>
                <input id="payCode" name="payCode" required maxlength="16" ng-model="ctrl.pay_type_link.code">
            </md-input-container>

            <md-card ng-if="ctrl.isUpdating">
                <md-card-content class="tw-p-0">

                    <div class="tw-p-12" layout="column">
                        <span class="md-subhead" ng-i18next="admin:PAY_TYPE"> </span>
                        <span ng-bind="ctrl.pay_type_link.pay_type.name"></span>
                    </div>


                    <md-divider></md-divider>

                    <div class="tw-p-12" layout="column">
                        <span class="md-subhead">Link ID</span>
                        <span ng-bind="ctrl.pay_type_link.id"></span>
                    </div>

                    <md-divider></md-divider>

                    <div class="tw-p-12" layout="column">
                        <span class="md-subhead">Calculator</span>

                        <div ng-if="ctrl.pay_type_link.calculator_id" layout="column">
                            <span ng-bind="ctrl.pay_type_link.calculator.name"></span>
                            <span ng-bind="ctrl.pay_type_link.calculator.description"></span>
                            <small ng-bind="ctrl.pay_type_link.calculator.class"></small>
                        </div>

                        <div ng-if="!ctrl.pay_type_link.calculator_id">
                            <span ng-i18next="NA"></span>
                        </div>
                    </div>

                    <md-divider></md-divider>

                    <div class="tw-p-12" layout="column" ng-if="ctrl.returnUnits[ctrl.pay_type_link.returnUnit]">
                        <span class="md-subhead" ng-i18next="admin:RETURN_UNIT"></span>
                        <span ng-i18next="{{ctrl.returnUnits[ctrl.pay_type_link.returnUnit]}}"></span>
                    </div>
                </md-card-content>
            </md-card>

            <div ng-if="!ctrl.isUpdating" layout="column">
                <md-autocomplete
                        required
                        md-floating-label="{{'admin:PAY_TYPE' | i18next}}"
                        md-clear-button="true"
                        md-selected-item="ctrl.pay_type_link.pay_type"
                        md-search-text="paytypeFilter"
                        md-require-match="true"
                        md-input-name="payTypeSearch"
                        md-items="item in ctrl.getPaytypes(paytypeFilter)"
                        md-item-text="item.name"
                        md-delay="300"
                        md-clear-button="true"
                        md-min-length="0">
                    <md-item-template>
                        <span md-highlight-text="paytypeFilter" md-highlight-flags="gi">{{item.name}}</span>
                    </md-item-template>
                    <md-not-found>
                        <span ng-i18next="NO_MATCH_FOUND"></span>
                    </md-not-found>
                </md-autocomplete>

                <md-switch ng-model="ctrl.hideCalculator">
                    {{'admin:NO_CALCULATOR' | i18next}}
                </md-switch>

                <md-input-container ng-if="ctrl.hideCalculator">
                    <label for="returnUnitSelect" ng-i18next="admin:RETURN_UNIT"></label>
                    <md-select id="returnUnitSelect" required ng-model="ctrl.returnUnit">
                        <md-option ng-repeat="(option, name) in ctrl.returnUnits" ng-value="option">
                            <span ng-i18next="{{::name}}"></span>
                        </md-option>
                    </md-select>
                </md-input-container>

                <section id="options" ng-show="!ctrl.hideCalculator" layout="column">
                    <md-autocomplete
                            md-floating-label="{{::'admin:CALCULATOR' | i18next}}"
                            md-clear-button="true"
                            md-selected-item="ctrl.pay_type_link.calculator"
                            md-search-text="calculatorFilter"
                            md-selected-item-change="ctrl.onChangedCalculator(item.id)"
                            md-require-match="true"
                            md-input-name="payCalculator"
                            md-items="item in ctrl.getCalculators(calculatorFilter)"
                            md-item-text="item.name"
                            md-delay="300"
                            md-clear-button="true"
                            md-min-length="0">
                        <md-item-template>
                            <span md-highlight-text="calculatorFilter" md-highlight-flags="gi">{{item.name}}</span>
                        </md-item-template>
                        <md-not-found>
                            <span ng-i18next="NO_MATCH_FOUND"></span>
                        </md-not-found>
                    </md-autocomplete>

                    <div ng-bind="ctrl.pay_type_link.calculator.description"></div>
                    <small ng-bind="ctrl.pay_type_link.calculator.class"></small>
                </section>
            </div>

            <md-switch id="use_emp_rate" ng-model="ctrl.pay_type_link.use_employee_rate" ng-show="!ctrl.hideCalculator">
                <span aria-label="use_emp_rate" ng-i18next="admin:USE_EMPLOYEE_RATE"></span>
            </md-switch>

            <md-autocomplete
                    ng-show="!ctrl.hideCalculator"
                    ng-if="!ctrl.pay_type_link.use_employee_rate"
                    md-floating-label="{{'TARIFF' | i18next}}"
                    md-clear-button="true"
                    md-selected-item="ctrl.selectedTariff"
                    md-search-text="tariffFilter"
                    md-selected-item-change="ctrl.onChangedTariff(item.id)"
                    md-require-match="true"
                    md-input-name="tariff"
                    md-items="item in ctrl.getTariffs(tariffFilter)"
                    md-item-text="item.name"
                    md-menu-class="autocomplete-custom-template"
                    md-delay="300"
                    md-clear-button="true"
                    md-min-length="0">
                <md-item-template>
                    <span class="autocomplete-item-title" md-highlight-text="filter" md-highlight-flags="gi">{{item.name}}</span>
                    <span class="autocomplete-item-metadata" ng-bind="item.type"></span>
                </md-item-template>
                <md-not-found>
                    <span ng-i18next="NO_MATCH_FOUND"></span>
                </md-not-found>
            </md-autocomplete>

            <md-input-container ng-if="ctrl.pay_type_link.use_employee_rate && !ctrl.hideCalculator">
                <label for="ratio" ng-i18next="admin:EMPLOYEE_RATIO"></label>
                <input type="number"
                       id="ratio"
                       min="0"
                       max="2"
                       ng-model="ctrl.pay_type_link.employee_rate_ratio">
            </md-input-container>

            <div class="tw-mt-4">
                <label ng-i18next="admin:CONTRACT_TYPE_plural"></label>
                <md-chips ng-model="ctrl.contract_types" md-require-match="true">
                    <md-autocomplete
                            md-input-id="contractTypes"
                            md-search-text="contractTypeFilter"
                            md-items="contract in ctrl.getContractTypes(contractTypeFilter)"
                            md-item-text="contract.name"
                            md-delay="300"
                            md-min-length="0"
                            placeholder="{{'SELECT_OPTION_plural' | i18next}}">
                        <span md-highlight-text="contractTypeFilter">{{contract.name}}</span>
                    </md-autocomplete>
                    <md-chip-template>
                        <span>{{$chip.name}}</span>
                    </md-chip-template>
                </md-chips>
            </div>

            <div class="tw-mt-16">
                <label ng-i18next="admin:ABSENCE_TYPE_plural"></label>
                <md-chips ng-model="ctrl.absence_types" md-require-match="true">
                    <md-autocomplete
                            md-input-id="absenceTypes"
                            md-search-text="absenceType"
                            md-items="item in ctrl.getAbsenceTypes(absenceType)"
                            md-item-text="item.name"
                            md-delay="300"
                            md-min-length="0"
                            placeholder="{{'SELECT_OPTION_plural' | i18next}}">
                        <span md-highlight-text="absenceType">{{item.name}}</span>
                    </md-autocomplete>
                    <md-chip-template>
                        <span>{{$chip.name}}</span>
                    </md-chip-template>
                </md-chips>
            </div>

            <md-switch id="include-in-offtime" ng-model="ctrl.pay_type_link.include_in_vacation">
                <span class="ts-label text-capitalize" ng-i18next="admin:INCLUDE_IN_VACATION"></span>
            </md-switch>

            <md-switch id="include-in-labor" ng-model="ctrl.pay_type_link.include_in_labor_cost">
                <span class="ts-label text-capitalize" ng-i18next="admin:INCLUDE_IN_LABOR_COST"></span>
            </md-switch>

            <md-input-container ng-if="ctrl.pay_type_link.include_in_labor_cost">
                <label for="effect" class="text-capitalize" ng-i18next="admin:EFFECT_ON_LABOR_COST"></label>
                <md-select name="effect" id="effect" ng-model="ctrl.pay_type_link.effect_on_labor">
                    <md-option ng-value="'negative'" ng-i18next="NEGATIVE" selected></md-option>
                    <md-option ng-value="'positive'" ng-i18next="POSITIVE"></md-option>
                </md-select>
            </md-input-container>

            <eaw-date-time-interval layout="row" ng-model="ctrl.paytypeLinkInterval" from-required="true"></eaw-date-time-interval>
        </form>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button form="modalForm" ng-disabled="modalForm.$invalid" type="submit" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>

</md-dialog>
`;
module('eaw.admin').factory('payTypeLinkDialog', [ '$mdDialog', function($mdDialog) {
    return {
        add: (customer) => openModal(customer),
        update: (payTypeLink, customer) => openModal(customer, payTypeLink),
    };
    /**
     * @param {Object} customer
     * @param {Object} [payTypeLink]
     */
    function openModal(customer, payTypeLink) {
        const pay_type_link = {
            from: moment(),
            include_in_labor_cost: true,
            effect_on_labor: 'negative',
            absence_types: [],
            include_in_vacation: false,
            contract_types: [],
            ...payTypeLink,
            // This may be null, so convert it
            use_employee_rate: !!payTypeLink?.use_employee_rate || false,
        };
        return $mdDialog.show({
            template: template1,
            controller: 'payTypeLinkDialogCtrl',
            controllerAs: 'ctrl',
            bindToController: true,
            locals: {
                pay_type_link,
                isUpdating: !!payTypeLink,
                customer,
            },
        });
    }
} ]);
