import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateTime } from 'luxon';
import { TranslatePipe } from '../../pipes/translate.pipe';

export interface ImageCropperDialogData extends DialogData {
    // Divide length by height to get the aspect ratio you wish. Ex: 16 / 9
    aspectRatio?: number;
    title: Promise<string>;
    initialFile?: Observable<File>;
    fileName?: string;
}

@Component({
    selector: 'eaw-image-cropper-dialog',
    standalone: true,
    imports: [ CommonModule, ImageCropperComponent, DialogHeaderComponent, MatDialogModule, MatButtonModule, ReactiveFormsModule, MatInputModule, MatIconModule, MatProgressSpinnerModule, TranslatePipe ],
    templateUrl: './image-cropper-dialog.component.html',
    styleUrl: './image-cropper-dialog.component.scss',
})
export class ImageCropperDialogComponent extends DialogComponent implements OnInit {
    file?: File;
    imageChangedEvent?: Event;
    canSubmit = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ImageCropperDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ImageCropperDialogComponent, File>,
    ) {
        data.size = DialogSize.Large;
        super(dialogRef, data);
    }

    ngOnInit() {
        this.data.initialFile?.subscribe((file) => {
            this.file = file;
        });
    }

    imageLoaded() {
        this.canSubmit = true;
    }

    fileChange(event: Event) {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        if (!event.blob) {
            return;
        }

        // Get file name from the input, or from the initial file
        const fileName = (this.imageChangedEvent?.target as HTMLInputElement)?.files?.[0]?.name ?? this.file?.name;
        this.dialogRef.close(new File([ event.blob ], this.data.fileName || fileName || `image-cropper-${DateTime.now().toUTC().toISO()}.png`, { type: 'image/png' }));
    }
}
