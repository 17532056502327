import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { CreateScheduleDialogData } from '../create-schedule-dialog.service';
import { ScheduleTemplate } from '../../../models/schedule-template';
import { SettingService } from '../../../../shared/http/setting.service';
import { DialogComponent, DialogSize } from '../../../../shared/dialogs/dialog-component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CurrentService } from '../../../../shared/services/current.service';
import { Products } from '../../../../shared/enums/products';
import { DateTime } from 'luxon';
import { MatCalendar, MatCalendarCellClassFunction, MatDatepickerModule } from '@angular/material/datepicker';
import { NeedProviderGranularity } from '../../../types/need-provider-granularity';
import { AutoschedulingService } from '../../../http/autoscheduling.service';
import { WeekSetup } from '../../../models/week-setup';
import { CustomerProductService } from '../../../../shared/http/customer-product.service';
import { MaterialColorPipe } from '../../../../shared/pipes/material-color.pipe';
import { PercentPipe } from '../../../../shared/pipes/percent.pipe';
import { DurationPipe } from '../../../../shared/pipes/duration.pipe';
import { DateTimePipe } from '../../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { WeekSetupAutocompleteComponent } from '../../../components/week-setup-autocomplete/week-setup-autocomplete.component';
import { ScheduleTemplateAutocompleteComponent } from '../../../components/schedule-template-autocomplete/schedule-template-autocomplete.component';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { DatePickerOptionsDirective } from '../../../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { AsyncPipe, KeyValuePipe, NgFor, NgIf, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';
import { ActionButtonComponent } from '../../../../shared/components/action-button/action-button.component';
import { InfoBoxComponent } from '../../../../shared/components/info-card/info-box.component';
import { tap } from 'rxjs';
import { TranslateSyncPipe } from '../../../../shared/pipes/translate-sync.pipe';

type AutoschedTypes = 'generate' | 'populate' | 'vlh';

type VlhForm = FormGroup<{
    mergeShortShifts: FormControl<boolean>,
    adjustments?: FormGroup<{
        [key: string]: FormControl<number>,
    }>,
    granularity: FormControl<NeedProviderGranularity>,
}>;

type PopulateForm = FormGroup<{
    allowUnqualified: FormControl<boolean>,
    allowOverContract: FormControl<boolean>,
    preferMultipleShifts: FormControl<boolean>,
}>;

type GenerateForm = FormGroup<{
    template: FormControl<ScheduleTemplate | null>,
    recurringTemplate: FormControl<ScheduleTemplate | null>,
    weekSetup: FormControl<WeekSetup | null>,
    granularity: FormControl<NeedProviderGranularity | null>,
    shouldClearPeriod: FormControl<boolean>,
    mergeShortShifts: FormControl<boolean>,
}>;

type BaseForm = FormGroup<{
    type: FormControl<AutoschedTypes>,
    dateMode: FormControl<boolean>,
    dates: FormControl<DateTime[] | null>,
    dateRange: FormGroup<{
        from: FormControl<DateTime | null>,
        to: FormControl<DateTime | null>,
    }>,
}>;

@Component({
    selector: 'eaw-automatic-scheduling-schedule-create',
    templateUrl: './automatic-scheduling-schedule-create.component.html',
    styleUrl: './automatic-scheduling-schedule-create.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        NgIf,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatChipsModule,
        MatIconModule,
        ScheduleTemplateAutocompleteComponent,
        WeekSetupAutocompleteComponent,
        MatSlideToggleModule,
        MatCardModule,
        MatInputModule,
        NgTemplateOutlet,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        UpperCasePipe,
        TranslatePipe,
        DateTimePipe,
        DurationPipe,
        PercentPipe,
        MaterialColorPipe,
        ActionButtonComponent,
        InfoBoxComponent,
        KeyValuePipe,
        TranslateSyncPipe,
    ],
})
export class AutomaticSchedulingScheduleCreateComponent extends DialogComponent<CreateScheduleDialogData, 'ok'> implements OnInit {
    protected settingService = inject(SettingService);
    protected autoSchedulingService = inject(AutoschedulingService);
    protected current = inject(CurrentService);
    protected customerProductService = inject(CustomerProductService);

    @ViewChild(MatCalendar) matCalendar?: MatCalendar<DateTime>;

    granularities: { value: NeedProviderGranularity, seconds: number }[];

    vlhForm: VlhForm;
    populateForm: PopulateForm;
    generateForm: GenerateForm;
    baseForm: BaseForm;

    creating = false;
    validationUpdating = false;
    types: AutoschedTypes[] = [];
    dateClass: MatCalendarCellClassFunction<DateTime>;

    adjustments: any;

    constructor() {
        super(undefined, undefined, DialogSize.Large);

        this.granularities = [
            {
                value: 'hour',
                seconds: 3600,
            },
            {
                value: 'half_hour',
                seconds: 1800,
            },
            {
                value: 'quarter_hour',
                seconds: 900,
            },
        ];

        this.vlhForm = new FormGroup({
            mergeShortShifts: new FormControl(false, { nonNullable: true }),
            granularity: new FormControl<NeedProviderGranularity>('hour', {
                nonNullable: true,
                validators: Validators.required,
            }),
        });

        this.populateForm = new FormGroup({
            allowUnqualified: new FormControl(false, { nonNullable: true }),
            allowOverContract: new FormControl(false, { nonNullable: true }),
            preferMultipleShifts: new FormControl(false, { nonNullable: true }),
        });

        this.generateForm = new FormGroup({
            template: new FormControl<ScheduleTemplate | null>(null),
            recurringTemplate: new FormControl<ScheduleTemplate | null>(null),
            weekSetup: new FormControl<WeekSetup | null>(null),
            granularity: new FormControl<NeedProviderGranularity | null>(null),
            shouldClearPeriod: new FormControl(false, { nonNullable: true }),
            mergeShortShifts: new FormControl(false, { nonNullable: true }),
        });

        const dateMode = false;
        this.baseForm = new FormGroup({
            type: new FormControl<AutoschedTypes>('generate', { nonNullable: true }),
            dateMode: new FormControl(dateMode, { nonNullable: true }),
            dates: new FormControl<DateTime[] | null>(null, Validators.required),
            dateRange: new FormGroup({
                from: new FormControl<DateTime | null>(null, Validators.required),
                to: new FormControl<DateTime | null>(null, Validators.required),
            }, Validators.required),
        });

        // Enable/disable dates and dateRange fields based on dateMode
        this.dateModeChange(dateMode);

        this.dateClass = (cellDate, view) => {
            if (view !== 'month') {
                return '';
            }
            return this.baseForm.value.dates?.find((d) => d.toSQLDate() === cellDate.toSQLDate()) ? 'added' : '';
        };
    }

    ngOnInit() {
        this.customerProductService.hasProducts(this.current.getCustomer().id, [ Products.VLH ]).subscribe((hasVlh) => {
            if (hasVlh) {
                this.types = [ 'generate', 'vlh', 'populate' ];
            } else {
                this.types = [ 'generate', 'populate' ];
            }
        });

        this.baseForm.controls.type.valueChanges.subscribe(this.typeChange.bind(this));
        this.baseForm.controls.dateMode.valueChanges.subscribe(this.dateModeChange.bind(this));
        this.generateForm.controls.template.valueChanges.subscribe(this.updateGenerateAutocompleteValidities.bind(this));
        this.generateForm.controls.recurringTemplate.valueChanges.subscribe(this.updateGenerateAutocompleteValidities.bind(this));
        this.generateForm.controls.weekSetup.valueChanges.subscribe(this.updateGenerateAutocompleteValidities.bind(this));
        this.generateForm.controls.weekSetup.valueChanges.subscribe(this.updateGranularity.bind(this));

        this.baseForm.controls.dateMode.setValue(false);
        this.baseForm.controls.type.setValue('generate');
        this.generateForm.controls.granularity.disable();

        this.settingService.getSome([ 'customers', this.current.getCustomer().id ], { 'settings[]': [ 'vlh.variables_to_adjust' ] }).pipe(
            tap((settings) => {
                const setting = (settings[0]?.value || settings[0]?.default)?.asKeyValue();
                if (!setting) {
                    return;
                }

                const adjustmentsForm = new FormGroup({});
                Object.entries(setting).forEach(([ key, val ]) => {
                    const controlKey: string = 'adjust_' + key;

                    adjustmentsForm.addControl(controlKey, new FormControl(1, {
                        nonNullable: true,
                        validators: [ Validators.required, Validators.min(val.min), Validators.max(val.max) ],
                    }));
                });

                this.vlhForm.addControl('adjustments', adjustmentsForm);
            }),
        ).subscribe();
    }

    dateModeChange(useDates: boolean | null) {
        if (useDates) {
            this.baseForm.controls.dates.enable();
            this.baseForm.controls.dateRange.disable();
        } else {
            this.baseForm.controls.dates.disable();
            this.baseForm.controls.dateRange.enable();
        }
    }

    updateGranularity(value?: WeekSetup | null) {
        const granularity = this.generateForm.controls.granularity;

        if (value instanceof WeekSetup) {
            granularity.setValidators(Validators.required);
            granularity.enable();
        } else {
            granularity.setValidators(null);
            granularity.setValue(null);
            granularity.disable();
        }

        granularity.updateValueAndValidity();
    }

    updateGenerateAutocompleteValidities() {
        if (this.validationUpdating) {
            return;
        }

        this.validationUpdating = true;
        const controls = [ this.generateForm.controls.template, this.generateForm.controls.recurringTemplate, this.generateForm.controls.weekSetup ];

        controls.forEach((c) => {
            c?.setValidators(controls.some((x) => x?.value?.id) ? null : Validators.required);
            c?.updateValueAndValidity();
        });

        this.validationUpdating = false;
    }

    typeChange(value: AutoschedTypes | null) {
        this.baseForm.controls.dateMode.enable();

        switch (value) {
            case 'generate': {
                this.generateForm.enable();
                this.populateForm.disable();
                this.vlhForm.disable();
                break;
            }
            case 'populate': {
                this.generateForm.disable();
                this.populateForm.enable();
                this.vlhForm.disable();
                break;
            }
            case 'vlh': {
                this.generateForm.disable();
                this.populateForm.disable();
                this.vlhForm.enable();
                break;
            }
        }
    }

    dateSelected(date: DateTime | null) {
        if (date == null) {
            return;
        }

        const key = date.toSQLDate();
        let value = this.baseForm.value.dates;

        if (value == null) {
            value = [];
        }

        if (value.find((d) => d.toSQLDate() === key)) {
            this.dateRemoved(date);
            return;
        }

        value.push(date);
        this.baseForm.controls.dates.setValue(value);
        this.matCalendar?.updateTodaysDate();
        this.baseForm.controls.dates.updateValueAndValidity();
    }

    dateRemoved(date: DateTime) {
        const value = this.baseForm.value.dates;
        if (value == null) {
            return;
        }

        const updatedValue = value.filter((d) => d.toSQLDate() !== date.toSQLDate());
        this.baseForm.controls.dates.setValue(updatedValue.length ? updatedValue : null);

        this.matCalendar?.updateTodaysDate();
        this.baseForm.controls.dates.updateValueAndValidity();
    }

    create() {
        switch (this.baseForm.controls.type.value) {
            case 'generate': {
                this.createByGenerate();
                break;
            }
            case 'populate': {
                this.createByPopulate();
                break;
            }
            case 'vlh': {
                this.createByVlh();
                break;
            }
        }
    }

    get datesArray(): DateTime[] {
        return Array.from(this.baseForm.value.dates?.values() || []);
    }

    createByVlh() {
        if (!this.validateDates()) {
            return;
        }

        const dateRange = this.baseForm.getRawValue().dateRange;
        const controls = this.vlhForm.controls;
        const body: any = {
            dates: this.datesArray,
            from: dateRange.from || undefined,
            to: dateRange.to || undefined,
            ...controls.adjustments?.value,
            merge_shifts: controls.mergeShortShifts.value,
            granularity: controls.granularity.value,
        };

        this.baseForm.disable();
        this.vlhForm.disable();
        this.creating = true;
        this.autoSchedulingService.vlh(this.current.getCustomer().id, body).subscribe(() => {
            this.dialogRef.close('ok');
        });
    }

    createByPopulate() {
        if (!this.validateDates()) {
            return;
        }

        const controls = this.populateForm.controls;
        const dateRange = this.baseForm.getRawValue().dateRange;
        const body = {
            from: dateRange.from || undefined,
            to: dateRange.to || undefined,
            allow_over_contract: controls.allowOverContract.value,
            allow_unqualified: controls.allowUnqualified.value,
            prefer_multiple_shifts: controls.preferMultipleShifts.value,
            dates: this.datesArray,
        };

        this.baseForm.disable();
        this.populateForm.disable();
        this.creating = true;
        this.autoSchedulingService.populate(this.current.getCustomer().id, body).subscribe(() => {
            this.dialogRef.close('ok');
        });
    }

    createByGenerate() {
        const controls = this.generateForm.controls;
        const dateRange = this.baseForm.getRawValue().dateRange;

        if (!this.validateDates()) {
            return;
        }

        const body = {
            from: dateRange.from || undefined,
            to: dateRange.to || undefined,
            dates: this.datesArray,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            granularity: controls.granularity.value!,
            merge_shifts: controls.mergeShortShifts.value,
            should_clear_period: controls.shouldClearPeriod.value,
            recurring_template_id: controls.recurringTemplate.value?.id,
            template_id: controls.template.value?.id,
            week_setup_id: controls.weekSetup.value?.id,
        };

        this.baseForm.disable();
        this.generateForm.disable();
        this.creating = true;
        this.autoSchedulingService.generate(this.current.getCustomer().id, body).subscribe(() => {
            this.dialogRef.close('ok');
        });
    }

    formValid() {
        const type = this.baseForm.controls.type.value;

        return this.baseForm.valid && (type === 'generate' && this.generateForm.valid ||
            type === 'populate' && this.populateForm.valid ||
            type === 'vlh' && this.vlhForm.valid);
    }

    private validateDates(): boolean {
        const { from, to } = this.baseForm.getRawValue().dateRange;
        const dates = this.datesArray;
        const dateMode = this.baseForm.controls.dateMode.value;
        // if dates mode, check dates length, otherwise check from and to
        return Boolean(dateMode && dates?.length || !dateMode && from && to);
    }
}
