import { PeopleKpiHeader } from './people-kpi-header';
import { PeopleKpiRow } from './people-kpi-row';
import { PeopleKpiResponse } from '../types';

export class PeopleKpi {
    headers: PeopleKpiHeader[];
    rows: PeopleKpiRow[];

    constructor(data: PeopleKpiResponse) {
        this.headers = data.headers.map((h) => new PeopleKpiHeader(h));
        this.rows = data.rows.map((r) => new PeopleKpiRow(r));
    }
}
