import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { classifyArrayPaginatedResponse } from '../../shared/utils/rxjs/classify';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { Efficiency, EfficiencyResponse } from '../models/efficiency';

@Injectable({
    providedIn: 'root',
})
export class EfficiencyService {
    private http = inject(HttpClient);

    getAll(customerId: number, scheduleId: number, options?: PaginationOptions, context?: HttpContext) {
        return this.http.get<ArrayPaginatedResponse<EfficiencyResponse>>(`/customers/${customerId}/schedules/${scheduleId}/efficiencies`, {
            params: formatHttpParams(options),
            context,
        }).pipe(classifyArrayPaginatedResponse(Efficiency));
    }
}
