// @ts-nocheck
import { PaginationOld } from '../../../shared/utils/pagination-old';
import { Model } from '../../../shared/angularjs/model';
import moment from 'moment-timezone';
import { pickBy } from 'lodash-es';
import { pick } from 'lodash-es';
import {module} from 'angular';
import { EawUrl } from '../../../shared/angularjs/modules/resource/url.service';

module('eaw.absence').factory('Absence', [ 'EawResource', function AbsenceClass(EawResource) {
    const employeeUrl = '/customers/:customer_id/employees/:employee_id/absences/:absence_id';
    class Absence extends Model {
        grade;
        length;
        approved;
        release_shifts;
        from;
        to;
        approval_deadline;
        comment;
        customer_id;
        employee_id;
        type_id;
        type;
        constructor(absence, customer) {
            absence.grade = !absence.grade ? 1 : absence.grade;
            absence.approved = absence.approved ?? absence.approval?.approved ?? false;
            absence.customer = customer;
            if (customer) {
                absence.customer_id = customer.id;
            }
            super(absence);
        }

        _set(absence, prop, value) {
            switch (prop) {
                case 'customer':
                    absence.customer_id = value?.id ? value.id : null;
                    absence[prop] = value;
                    return true;
                case 'employee':
                    if (value?.id && value.id == absence.employee?.id) {
                        return true;
                    }
                    super._set(absence, 'employee_id', value?.id);
                    break;
                case 'type':
                    if (value?.id && value.id == absence.type?.id) {
                        return true;
                    }
                    super._set(absence, 'type_id', value?.id);
                    break;
                case 'hour_length':
                case 'percent_grade':
                    // Don't want these in getModified, setter will be called after this.
                    absence[prop] = value;
                    return true;
                case 'from':
                case 'to': {
                    if (!moment.isMoment(value)) {
                        return true;
                    }
                    if (value.isSame(absence[prop], 's')) {
                        return true;
                    }
                    if (absence.span == 'day') {
                        const m = prop == 'from' ? 'startOf' : 'endOf';
                        value[m]('d');
                    }
                    const _set = super._set(absence, prop, value);
                    absence.changedInterval(prop);
                    return _set;
                }
            }
            return super._set(absence, prop, value);
        }

        get percent_grade() {
            return typeof this.grade === 'number' ? Math.round(this.grade * 100) : undefined;
        }

        set percent_grade(value) {
            const grade = typeof value === 'number' ? value / 100 : undefined;
            // @ts-ignore
            this._set(this, 'grade', grade);
        }

        get hour_length() {
            return typeof this.length === 'number' ? this.length / 3600 : undefined;
        }

        set hour_length(value) {
            const length = typeof value === 'number' ? value * 3600 : undefined;
            // @ts-ignore
            this._set(this, 'length', length);
        }

        save(w) {
            this.assignCustomFields();
            let approveFields = {};
            if (this.approved) {
                approveFields = {
                    approved: true,
                    release_shifts: this.release_shifts ?? 'keep',
                };
            }
            return Absence.create({
                from: this.from,
                to: this.to,
                comment: this.comment,
                grade: this.grade,
                customerId: this.customer_id,
                employeeId: this.employee_id,
                typeId: this.type_id,
                length: this.length,
                with: w,
                ...approveFields,
                ...pickBy(this, (val, key) => key.startsWith('cf_')),
            });
        }

        approve() {
            return EawResource.create(employeeUrl).update({
                customer_id: this.customer_id,
                employee_id: this.employee_id,
                absence_id: this.id,
            }, {
                approved: true,
                comment: this.comment || undefined,
                release_shifts: this.release_shifts ?? 'keep',
                with: [ 'type', 'approval' ],
            });
        }

        decline() {
            return EawResource.create(employeeUrl).update({
                customer_id: this.customer_id,
                employee_id: this.employee_id,
                absence_id: this.id,
            }, {
                approved: false,
                comment: this.comment,
                with: [ 'type', 'approval' ],
            });
        }

        getAttachments(customerId, absenceId) {
            return EawResource.create(`/customers/${customerId}/absences/${absenceId}/attachments`).get();
        }

        update(schedule_published_warning_accepted = true) {
            this.assignCustomFields();
            return Absence.update({
                ...this.getModified(),
                id: this.id,
                customerId: this.customer_id,
                employeeId: this.employee_id,
                comment: this.comment,
                schedule_published_warning_accepted,
            });
        }

        delete(comment = '') {
            Absence.delete({
                customerId: this.customer_id,
                id: this.id,
                employeeId: this.employee_id,
                comment,
            });
        }

        static override getMorphClass() {
            return 'absence';
        }

        static create(args) {
            return EawResource.create(employeeUrl).save({
                customer_id: args.customerId,
                employee_id: args.employeeId,
            }, {
                ...pick(args, [ 'from', 'to', 'comment', 'length', 'grade', 'approved', 'release_shifts' ]),
                ...pickBy(args, (val, key) => key.startsWith('cf_')),
                type_id: args.typeId,
                with: args.with || [ 'type', 'approval' ],
                schedule_published_warning_accepted: true,
            });
        }

        static get(args) {
            return EawResource.create(employeeUrl).get({
                customer_id: args.customerId,
                employee_id: args.employeeId,
                absence_id: args.absenceId,
                'with[]': args.with,
            });
        }

        static getOverview(customerId, employeeId, from, to) {
            return EawResource.create('/customers/:customer/employees/:employee/absences/overview').get({
                customer: customerId,
                employee: employeeId,
                from,
                to,
            });
        }

        static getForEmployee(args) {
            return EawResource.create(employeeUrl).get({
                ...PaginationOld.getParams(args),
                customer_id: args.customerId,
                employee_id: args.employeeId,
                from: args.from,
                to: args.to,
                handled: args.handled,
                approved: args.approved,
                type_id: args.typeId,
                'type_ids[]': Array.isArray(args.typeIds) ? args.typeIds : undefined,
            });
        }

        static getForCustomer(args) {
            return EawResource.create('/customers/:customer_id/absences').get({
                ...PaginationOld.getParams(args, [ 'from', 'to', 'handled', 'approved' ]),
                customer_id: args.customerId,
                type_id: args.typeId,
                'type_ids[]': args.typeIds,
            });
        }

        static getForCustomerGroup(args) {
            return EawResource.create('/customer_groups/:group_id/absences').get({
                ...PaginationOld.getParams(args, [ 'from', 'to', 'handled', 'approved' ]),
                group_id: args.groupId,
                'type_ids[]': args.typeIds,
                'contract_type_ids[]': args.contractTypeIds,
            });
        }

        static update(args) {
            const resource = args.schedule_published_warning_accepted === false ? EawResource.$resource(EawUrl.getUrl(employeeUrl), {}, {
                update: {
                    method: 'PUT',
                    headers: {
                        'X-Ignore-Error': true,
                    },
                },
            }) : EawResource.create(employeeUrl);
            const deferred = resource.update({
                customer_id: args.customerId,
                employee_id: args.employeeId,
                absence_id: args.id,
            }, {
                schedule_published_warning_accepted: true,
                ...pick(args, [ 'from', 'to', 'comment', 'type_id', 'length', 'release_shifts', 'grade', 'approved', 'schedule_published_warning_accepted' ]),
                with: [ 'type', 'approval' ],
            });
            deferred.$promise.then((data) => {
                data.approved = data.handled_by != null;
                return data;
            });
            return deferred;
        }

        static delete(args) {
            return EawResource.create(employeeUrl).delete({
                customer_id: args.customerId,
                employee_id: args.employeeId,
                absence_id: args.id,
            }, {
                comment: args.comment,
            });
        }

        changedInterval(prop) {
            if (this.from && this.to && this.from.isAfter(this.to)) {
                switch (prop) {
                    case 'from':
                        this.to = null;
                        break;
                    case 'to':
                        this.from = null;
                }
                return;
            }
            if (this.type?.span == 'day' && this.from.isSame(this.to, 'm')) {
                this.from.startOf('d');
                this.to.endOf('d');
            }
        }
    }
    return Absence;
} ]);
