import { Component, Inject } from '@angular/core';
import { DialogComponent } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CreateHrDefaultFileData } from '../../../hr/dialogs/create-hr-default-file-dialog/create-hr-default-dialog.service';
import { DefaultHrFileService } from '../../../hr/http/default-hr-file.service';
import { IFormField } from '../../../hr/models/form-field';
import { FileSizePipe } from '../../../shared/pipes/file-size.pipe';
import { MaterialColorPipe } from '../../../shared/pipes/material-color.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { FileSelectorComponent } from '../../../shared/components/file-selector/file-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-set-form-field-dialog',
    templateUrl: './set-form-field-dialog.component.html',
    styleUrl: './set-form-field-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        MatFormFieldModule,
        MatIconModule,
        FileSelectorComponent,
        ReactiveFormsModule,
        MatExpansionModule,
        NgFor,
        NgIf,
        MatProgressSpinnerModule,
        MatDividerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        MaterialColorPipe,
        FileSizePipe,
    ],
})
export class SetFormFieldDialogComponent extends DialogComponent {
    file = new FormControl();
    existingFormFields: IFormField[] = [];
    formFields: IFormField[] = [];
    loading = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateHrDefaultFileData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<SetFormFieldDialogComponent, true>,
        @Inject(DefaultHrFileService) public service: DefaultHrFileService,
    ) {
        super(dialogRef, data);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.service.getFormFields(data.customerId, data.id!)
            .subscribe((oldFormFields) => oldFormFields.data.forEach(
                (field) => this.existingFormFields.push({
                    name: field.name,
                    value: field.value,
                    resolve_value: field.resolveValue,
                }),
            )).add(() => (this.loading = false));
    }

    async onFileChange(file?: File) {
        this.formFields = [];
        await file?.text().then((data) => data.split('\r\n').forEach((line) => {
            const fieldProps = line.split(',');
            const name = fieldProps[0];
            const value = fieldProps[1];
            const resolve_value = fieldProps[2] ? !!parseInt(fieldProps[2]) : undefined;

            if (name != null && value != null && resolve_value !== undefined) {
                this.formFields.push({
                    name,
                    value,
                    resolve_value,
                });
            }
        }));
    }

    submit() {
        this.file.disable();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.service.setFormFields(this.data.customerId, this.data.id!, this.formFields).subscribe({
            next: () => this.dialogRef.close(true),
            error: () => this.file.enable(),
        });
    }
}
