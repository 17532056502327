import { loadNamespaces, t } from 'i18next';
import { orderBy } from 'lodash-es';
import { module } from 'angular';
import { Storage } from '../../../../../../shared/utils/storage';
import { CustomerProductService } from '../../../../../../shared/http/customer-product.service';
import { Products } from '../../../../../../shared/enums/products';
import { firstValueFrom, of, switchMap } from 'rxjs';
import { EawUrl } from '../../../../../../shared/angularjs/modules/resource/url.service';
import { Namespace } from '../../../../../../shared/enums/namespace';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';
import { sort } from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';

module('eaw.scheduling').component('scheduleEmployeeHours', {
    template: `<div ng-if="$hours.loading" layout="column" layout-align="center center" layout-padding>
    <md-progress-circular></md-progress-circular>
</div>

<div ng-if="!$hours.loading" layout="column" layout-align="center center" layout-padding>
    <md-button class="md-icon-button" ng-click="$hours.getData()">
        <md-tooltip md-direction="top" ng-i18next="scheduling:UPDATE_DATA"></md-tooltip>
        <md-icon ng-bind="'refresh'"></md-icon>
    </md-button>
    
    <md-input-container ng-if="!$hours.isFrance">
        <label ng-i18next="scheduling:CONTRACT_TYPE"></label>
        <md-select ng-model="$hours.contractType" ng-change="$hours.chooseContractType()">
            <md-option ng-value="undefined">
                <span ng-i18next="ALL"></span>
            </md-option>
            <md-option ng-repeat="contractType in ::$hours.resource.contract_types track by contractType.id"
                       ng-if="contractType.id != 'none' && contractType.id != 'unassigned'"
                       ng-value="contractType.id">
                <span ng-bind="contractType.name"></span>
            </md-option>
        </md-select>
    </md-input-container>
</div>

<div ng-if="!$hours.loading && !$hours.isFrance">
    <table class="table table-condensed table-striped">
        <thead>
        <tr>
            <th class="name-header" ng-i18next="NAME"></th>
            <th class="text-right wh-header" ng-i18next="DAY" title="{{'TODAY' | i18next}}"></th>
            <th class="text-right wh-header" ng-i18next="WEEK" title="{{'THIS_WEEK' | i18next}}"></th>
            <th class="text-right wh-header" ng-i18next="[i18next]({count: $hours.weekCount})N_WEEK" title="{{ 'LAST_X_WEEKS' | i18next:{count: $hours.weekCount} }}"></th>
            <th class="text-right wh-header" ng-i18next="SCHEDULE" title="{{'SCHEDULE' | i18next}}"></th>
        </tr>
        </thead>

        <tbody ng-repeat="contractType in $hours.data[$hours.week][$hours.dayKey] track by contractType.id" data-contract-type-id="{{contractType.id}}">
        <tr class="contract-type-row">
            <td colspan="5">
                <div ng-bind="contractType.name"></div>
            </td>
        </tr>
        <tr ng-repeat="employee in contractType.employees track by employee.id" data-employee-id="{{employee.id}}">
            <td ng-bind="employee.name" title="{{employee.name}}"></td>
            <td ng-bind="employee.daySum | eawNumber:2"></td>
            <td ng-bind="$hours.employeeWeekSum(contractType.id, employee.id)  | eawNumber:2"></td>
            <td ng-bind="contractType.id === 'unassigned' ? '-' : (employee.workedSum  | eawNumber:2)"></td>
            <td ng-bind="$hours.employeeScheduleSum(contractType.id, employee.id)  | eawNumber:2"></td>
        </tr>
        <tr class="sum-row">
            <td ng-i18next="SUM"></td>
            <td ng-bind="contractType.daySum  | eawNumber:2"></td>
            <td ng-bind="$hours.contractWeekSum(contractType.id)  | eawNumber:2"></td>
            <td ng-bind="contractType.id === 'unassigned' ? '-' : (contractType.workedSum | eawNumber:2)"></td>
            <td ng-bind="$hours.contractScheduleSum(contractType.id) | eawNumber:2"></td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="sum-row total-sum-row">
            <td ng-i18next="SUM"></td>
            <td ng-bind="$hours.dayTotal($hours.week, $hours.dayKey)  | eawNumber:2"></td>
            <td ng-bind="$hours.weekTotal($hours.week)  | eawNumber:2"></td>
            <td ng-bind="$hours.weeksWorkedTotal($hours.week, $hours.dayKey) | eawNumber:2"></td>
            <td ng-bind="$hours.scheduleTotal() | eawNumber:2"></td>
        </tr>
        </tfoot>
    </table>
</div>

<div id="france-employee-hours-table-container" ng-if="!$hours.loading && $hours.isFrance">
    <table class="table table-condensed table-striped sticky-header sticky-footer sticky-left">
        <thead>
        <tr>
            <th ng-i18next="NAME"></th>
            <th style="white-space: pre-wrap" ng-i18next="france_payroll:SIDEBAR_DAILY_SECONDS"></th>
            <th style="white-space: pre-wrap;" ng-i18next="france_payroll:SIDEBAR_MONTH_CONTRACT"></th>
            <th style="white-space: pre-wrap;" ng-i18next="france_payroll:SIDEBAR_MONTH_SEC_SCHEDULED"></th>
            <th style="white-space: pre-wrap;" ng-i18next="absences:ABSENCE"></th>
            <th style="white-space: pre-wrap;" ng-i18next="france_payroll:SIDEBAR_SCHEDULED_WEEK"></th>
        </tr>
        </thead>

        <tbody>
        <tr ng-repeat="data in $hours.franceData">
            <td style="min-width: 110px; max-width: 110px; word-break: break-all" ng-bind="data.first_name + ' ' + data.last_name"></td>
            <td ng-bind="(data.daily_seconds / 3600) | eawNumber:2"></td>
            <td ng-bind="(data.monthly_contractual_hours / 3600) | eawNumber:2"></td>
            <td ng-bind="(data.monthly_seconds_scheduled / 3600) | eawNumber:2"></td>
            <td ng-bind="(data.total_absence_seconds / 3600) | eawNumber:2"></td>
            <td ng-bind="(data.weekly_seconds / 3600) | eawNumber:2"></td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="sum-row total-sum-row">
            <td ng-i18next="SUM"></td>
            <td ng-bind="($hours.totals.daily / 3600) | eawNumber:2"></td>
            <td ng-bind="($hours.totals.month_contract / 3600) | eawNumber:2"></td>
            <td ng-bind="($hours.totals.month_scheduled /3600) | eawNumber:2"></td>
            <td ng-bind="($hours.totals.absence / 3600) | eawNumber:2"></td>
            <td ng-bind="($hours.totals.week_scheduled / 3600) | eawNumber:2"></td>
        </tr>
        </tfoot>
    </table>
</div>
`,
    controllerAs: '$hours',
    bindings: {
        days: '<',
        schedule: '<',
    },
    require: {
        sidebar: '^scheduleSidebar',
    },
    controller: [ 'StatisticsFactory', 'shiftEvents', '$scope', '$http', 'CustomerProductServiceDowngrade', function(StatisticsFactory, shiftEvents, $scope, $http, CustomerProductServiceDowngrade: CustomerProductService) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.init();
        };

        ctrl.init = async () => {
            ctrl.loading = true;

            const initObservable = of(CurrentOld.getCustomer()['country_code']?.toLowerCase() === 'fr').pipe(
                switchMap(async (isFrance) => {
                    if (isFrance) {
                        await loadNamespaces(Namespace.FrancePayroll);
                    }

                    return isFrance;
                }),
                switchMap((isFrance) => {
                    return isFrance ? CustomerProductServiceDowngrade.hasProducts(ctrl.schedule.customer_id, [ Products.France ]) : of(false);
                }),
            );

            ctrl.isFrance = await firstValueFrom(initObservable);
            ctrl.lsKey = Storage.prefix('schedule-sidebar:employee-hours:contract_id');
            ctrl.unassignedDummyId = 0;
            ctrl.contractType = await Storage.getItem(ctrl.lsKey);
            ctrl.changeDay();
            ctrl.getData();

            shiftEvents.register.onChange($scope, ctrl.getData);
            $scope.$on('sidebarchildren:dayChanged', ctrl.changeDay);
        };

        ctrl.changeDay = () => {
            ctrl.week = ctrl.sidebar.day.weekNr;
            ctrl.dayKey = ctrl.sidebar.day.moment.format('YYYY-MM-DD');

            if (ctrl.isFrance) {
                ctrl.getFranceHours();
            }
        };

        ctrl.$onDestroy = () => {
            ctrl.resource?.$cancelRequest();
        };

        interface FranceHoursResponse {
            calendar_day: string;
            daily_seconds: number;
            employee_id: number;
            first_name: string;
            last_name: string;
            monthly_contractual_hours: number;
            monthly_seconds_scheduled: number;
            total_absence_seconds: number;
            weekly_seconds: number;
        }

        ctrl.getFranceHours = () => {
            ctrl.loading = true;
            $http.get(EawUrl.url + `/customers/${ctrl.schedule.customer_id}/schedules/${ctrl.schedule.id}/monthlySchedule`, {
                params: {
                    from: ctrl.sidebar.day.moment.format('YYYY-MM-DD'),
                },
            }).then((resp: { data: FranceHoursResponse[] }) => {
                ctrl.franceData = sort(resp.data, 'fr', [ ((x) => x.first_name) ]);
                ctrl.totals = {
                    daily: 0,
                    month_contract: 0,
                    month_scheduled: 0,
                    absence: 0,
                    week_scheduled: 0,
                };
                ctrl.franceData.forEach((employee: any) => {
                    ctrl.totals.daily += employee.daily_seconds || 0;
                    ctrl.totals.month_contract += employee.monthly_contractual_hours || 0;
                    ctrl.totals.month_scheduled += employee.monthly_seconds_scheduled || 0;
                    ctrl.totals.absence += employee.total_absence_seconds || 0;
                    ctrl.totals.week_scheduled += employee.weekly_seconds || 0;
                });
                ctrl.loading = false;
            });
        };

        ctrl.getData = () => {
            if (ctrl.isFrance) {
                ctrl.getFranceHours();
            } else {
                ctrl.getHours();
            }
        };

        ctrl.getHours = async () => {
            // Make sure we pass in a number
            if (Number.isNaN(parseInt(ctrl.contractType))) {
                delete ctrl.contractType;
            }

            ctrl.loading = true;
            ctrl.resource = StatisticsFactory.getHours(ctrl.schedule.customer_id, ctrl.schedule.id, ctrl.contractType);
            ctrl.totals = {
                day: 0,
                week: 0,
                schedule: 0,
            };
            try {
                ctrl.resp = await ctrl.resource.$promise;
            } catch (_) {
                // No action atm
            }

            ctrl.weekCount = ctrl.resp.data.week_count;
            // Add dummy employee for unassigned
            ctrl.resp.employees[ctrl.unassignedDummyId] = {
                id: ctrl.unassignedDummyId,
                name: '',
            };
            // Order employees
            ctrl.resp.employees = orderBy(ctrl.resp.employees, 'name');
            // Make resp have same lookup for unassigned
            Object.entries(ctrl.resp.data).forEach(([ weekNr, contracts ]) => {
                Object.entries(contracts as Record<string, any>).forEach(([ contractId, data ]) => {
                    if (contractId === 'unassigned') {
                        ctrl.resp.data[weekNr][contractId][ctrl.unassignedDummyId] = { ...data };
                    }
                });
            });
            ctrl.loading = false;
            // Add some other ts
            ctrl.resp.contract_types['none'] = {
                id: 'none',
                name: t('scheduling:NO_CONTRACT'),
            };
            ctrl.resp.contract_types['unassigned'] = {
                id: 'unassigned',
                name: t('scheduling:UNASSIGNED'),
            };
            ctrl.data = {};
            const from = ctrl.schedule.getFrom();
            while (from.isSameOrBefore(ctrl.schedule.to, 'd')) {
                const week = from.week();
                const date = from.format('YYYY-MM-DD');
                ctrl.data[week] = ctrl.data[week] || {};
                ctrl.data[week][date] = ctrl.getDateData(week, date);
                from.add(1, 'd');
            }
            // Reorder items
            Object.entries(ctrl.data).forEach(([ week, days ]) => {
                Object.entries(days as Record<string, any>).forEach(([ dayKey, contracts ]) => {
                    let none, unassigned;
                    if (Object.prototype.hasOwnProperty.call(contracts, 'none')) {
                        none = contracts.none;
                        delete ctrl.data[week][dayKey].none;
                    }
                    if (Object.prototype.hasOwnProperty.call(contracts, 'unassigned')) {
                        unassigned = contracts.unassigned;
                        delete ctrl.data[week][dayKey].unassigned;
                    }
                    ctrl.data[week][dayKey] = orderBy(ctrl.data[week][dayKey], 'name');
                    if (none) {
                        ctrl.data[week][dayKey].unshift(none);
                    }
                    if (unassigned) {
                        ctrl.data[week][dayKey].unshift(unassigned);
                    }
                });
            });
        };

        ctrl.employeeWeekSum = (contractTypeId: any, employeeId: any) => {
            let sum = 0;
            Object.values(ctrl.data[ctrl.week]).forEach((contracts) => {
                sum += (contracts as any[]).find?.((c) => c.id === contractTypeId)?.employees.find((e: any) => e.id === employeeId)?.daySum || 0;
            });
            return sum;
        };

        ctrl.employeeScheduleSum = (contractTypeId: any, employeeId: any) => {
            let sum = 0;
            Object.keys(ctrl.data).forEach((week) => {
                Object.values(ctrl.data[week]).forEach((contracts) => {
                    sum += (contracts as any[]).find?.((c) => c.id === contractTypeId)?.employees.find((e: any) => e.id === employeeId)?.daySum || 0;
                });
            });
            return sum;
        };

        ctrl.contractWeekSum = (contractTypeId: any) => ctrl.resp.employees.reduce((sum: any, e: any) => sum + ctrl.employeeWeekSum(contractTypeId, e.id), 0);

        ctrl.contractScheduleSum = (contractTypeId: any) => ctrl.resp.employees.reduce((sum: any, e: any) => sum + ctrl.employeeScheduleSum(contractTypeId, e.id), 0);

        ctrl.dayTotal = (week: any, dayKey: any) => {
            if (!ctrl.data?.[week]) {
                return 0;
            }
            let sum = 0;

            Object.values(ctrl.data[week][dayKey]).forEach((contract) => {
                sum += (contract as Record<string, any>)['daySum'];
            });

            return sum;
        };

        ctrl.weekTotal = (week: any) => {
            if (!ctrl.data?.[week]) {
                return 0;
            }
            let sum = 0;
            Object.keys(ctrl.data[week]).forEach((dayKey) => {
                sum += ctrl.dayTotal(week, dayKey);
            });
            return sum;
        };

        ctrl.weeksWorkedTotal = (week: any, dayKey: any) => {
            if (!ctrl.data?.[week]) {
                return 0;
            }
            let sum = 0;
            Object.values(ctrl.data[week][dayKey]).forEach((contract) => {
                sum += (contract as Record<string, any>)['workedSum'];
            });
            return sum;
        };

        ctrl.scheduleTotal = () => {
            let sum = 0;
            Object.keys(ctrl.data).forEach((week) => {
                sum += ctrl.weekTotal(week);
            });
            return sum;
        };

        ctrl.getDateData = (week: any, date: any) => {
            const types = Object.values(ctrl.resp.contract_types as Record<string, any>).reduce((typesObj, type) => {
                typesObj[type.id] = {
                    ...type,
                };
                return typesObj;
            }, {} as Record<string, any>) as Record<string, any>;

            Object.values(types).forEach((t) => {
                t.daySum = 0;
                t.workedSum = 0;
                t.employees = (ctrl.resp.employees as any[]).reduce((empsArr, e) => {
                    const employee = ctrl.resp.data[week]?.[t.id]?.[e.id];
                    if (employee == null) {
                        return empsArr;
                    }
                    const daySum = employee.per_day[date]?.hours_today || 0;
                    const workedSum = employee.per_day[date]?.last_hours;
                    empsArr.push({
                        ...e,
                        daySum,
                        workedSum,
                    });
                    t.daySum += daySum;
                    t.workedSum += workedSum ?? 0;
                    return empsArr;
                }, []);
            });

            Object.entries(types).forEach(([ typeId, data ]) => {
                if (!Object.keys(data.employees).length) {
                    delete types[typeId];
                }
            });

            return types;
        };

        ctrl.chooseContractType = () => {
            if (ctrl.contractType) {
                void Storage.setItem(ctrl.lsKey, ctrl.contractType);
            } else {
                void Storage.removeItem(ctrl.lsKey);
            }

            ctrl.getData();
        };
    } ],
});
