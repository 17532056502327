<eaw-page-header class="tw-mb-16">
    <span title>{{ 'PAYROLL_CONFIGURATION' | translate: 'company' | async }}</span>
</eaw-page-header>

@if (loading) {
    <div id="loading-block">
        <mat-progress-spinner mode="indeterminate" diameter="40" strokeWidth="4"></mat-progress-spinner>
        <span id="fetching-text" class="mat-subtitle-2">{{ 'FETCHING_CONFIGURATION' | translate:'france_payroll' | async }}</span>
    </div>
} @else {
    <mat-accordion [multi]="true">
        @for (config of configurations; track config.meta.name) {
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ config.meta.translatedName.key | translate:config.meta.translatedName.ns | async }}</mat-panel-title>

                    @if (missingValue(config.settings)) {
                        <span class="missing-value status">
                        <mat-icon>priority_high</mat-icon>
                        <span>{{ 'NO_VALUE_GIVEN' | translate: 'france_payroll' | async }}</span>
                    </span>
                    }

                    @if (edited(config.settings)) {
                        <span class="status tw-gap-4">
                        <mat-icon>edit_location_alt</mat-icon>
                        <span>{{ 'EDITED' | translate | async }}</span>
                    </span>
                    }
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <div class="description tw-flex tw-gap-16 tw-items-center tw-mb-16">
                        {{ config.meta.translatedDescription.key | translate:config.meta.translatedDescription.ns | async }}
                    </div>

                    <table>
                        <thead>
                        <tr>
                            <th></th>
                            <th>{{ 'STORE_LABEL' | translate:'france_social_rules' | async }}</th>
                            <th>{{ 'LEGAL_LABEL' | translate:'france_social_rules' | async }}</th>
                        </tr>
                        </thead>

                        <tbody>
                            @for (setting of config.settings; track setting.key) {
                                <tr>
                                    <td class="cell">{{ translatedTargets?.[getTarget(setting)] }}</td>

                                    @if (loaders[setting.key]) {
                                        <td>
                                            <div class="tw-flex tw-gap-16 tw-items-center tw-justify-end">
                                                <eaw-info-loading size="xs"/>
                                            </div>
                                        </td>
                                    } @else {
                                        <td>
                                            <div class="tw-flex tw-gap-16 tw-items-center tw-justify-end">
                                                <eaw-property-value-display
                                                        [propertyValue]="setting.value"
                                                        [dataType]="config.meta.data_type"
                                                        [inputType]="config.meta.input_type"
                                                        [options]="setting.options"/>

                                                <button [class.hidden]="!setting.setValue" [matTooltip]="('RESET' | translate | async) || ''" matRipple (click)="clearValue(setting)">
                                                    <mat-icon eawSize="20px">replay</mat-icon>
                                                </button>

                                                <button [matTooltip]="('EDIT' | translate | async) || ''" matRipple (click)="updateValue(setting)">
                                                    <mat-icon eawSize="20px">edit</mat-icon>
                                                </button>
                                            </div>
                                        </td>
                                    }

                                    <td>
                                        <eaw-property-value-display
                                                [propertyValue]="defaultValues[config.meta.name] ?? null"
                                                [dataType]="config.meta.data_type"
                                                [inputType]="config.meta.input_type"
                                                [options]="config.settings[0]?.options"/>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </ng-template>
            </mat-expansion-panel>
        }
    </mat-accordion>
}
