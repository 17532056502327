import { booleanAttribute, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { timeStringToHourMin } from '../../../angularjs/modules/misc/services/easy-funcs.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

export type TimeObject = { hour: number, minute: number };

@Component({
    selector: 'eaw-time-input',
    templateUrl: './time-input.component.html',
    styleUrl: './time-input.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TimeInputComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: TimeInputComponent,
        },
    ],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class TimeInputComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
    @Input() baseDate?: DateTime | null = DateTime.now().startOf('day');
    @Input() label?: Promise<string> | null;
    @Input({ transform: booleanAttribute }) standalone = false;

    @Output() timeStringChange = new EventEmitter<string>();
    @Output() dateTimeChange = new EventEmitter<DateTime | null>();
    @Output() timeChange = new EventEmitter<TimeObject | null>();
    @Output() enterPress = new EventEmitter<KeyboardEvent>();

    timeInput = new FormControl('');
    disabled = false;

    onChange = (_: DateTime | null) => {};
    onTouched = () => {};

    protected touched = false;

    ngOnInit() {
        this.timeInput.valueChanges.subscribe(() => {
            this.changed(this.getTime());
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['time']) {
            this.updateTimeString(changes['time'].currentValue);
        }

        if (changes['baseDate']) {
            this.updateBaseDate(changes['baseDate'].currentValue);
        }
    }

    onFocus(event: FocusEvent) {
        (event.target as HTMLInputElement).select();
    }

    onEnterKey(event: Event) {
        this.enterPress.emit(event as KeyboardEvent);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;

        if (isDisabled) {
            this.timeInput.disable();
        } else {
            this.timeInput.enable();
        }
    }

    writeValue(obj: DateTime | TimeObject): void {
        if (obj instanceof DateTime) {
            this.updateTimeString(obj.toObject());
            this.updateBaseDate(obj, false);
        } else {
            this.updateTimeString(obj);
        }
    }

    onBlur() {
        const time = this.getTime();
        this.updateTimeString(time);
        this.changed(time);
    }

    getTime(): TimeObject | null {
        return timeStringToHourMin(this.timeInput.value) ?? null;
    }

    updateBaseDate(base: DateTime, emit = true) {
        this.baseDate = base;
        const time = this.getTime();
        this.changed(time, emit);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.hasValidator(Validators.required) && !this.timeInput.hasValidator(Validators.required)) {
            this.timeInput.addValidators(Validators.required);
        }

        return this.timeInput.touched ? this.timeInput.errors : null;
    }

    getDateTime(time: TimeObject | null = this.getTime()): DateTime | null {
        return time && this.baseDate ? this.baseDate.set(time) : null;
    }

    protected changed(time: TimeObject | null, emit = true) {
        const dateTime = this.getDateTime(time);

        this.timeStringChange.emit(this.getTimeString(time));
        this.timeChange.emit(time);
        this.dateTimeChange.emit(dateTime);

        if (!this.touched) {
            this.touched = true;
            this.onTouched();
        }

        if (emit) {
            this.onChange(dateTime);
        }
    }

    private getTimeString(object: Partial<TimeObject> | null): string {
        if (!object || object.hour == null || object.minute == null) {
            return '';
        }

        const hours = object.hour < 10 ? `0${object.hour}` : object.hour.toString();
        const minutes = object.minute < 10 ? `0${object.minute}` : object.minute.toString();

        return `${hours}:${minutes}`;
    }

    private updateTimeString(object: Partial<TimeObject> | null) {
        const timeString = this.getTimeString(object);

        this.timeInput.setValue(timeString, {
            emitEvent: false,
            onlySelf: true,
        });
    }
}
