import { ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    inject,
    signal,
    viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCard } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MatButton } from '@angular/material/button';

import { DataTableComponent } from '../../../data-table/data-table.component';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DataTablePagination } from '../../../data-table/types/data-table';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { EmployeeService } from '../../../shared/http/employee.service';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { tap } from 'rxjs';
import { Employee } from '../../../shared/models/employee';
import { Products } from '../../../shared/enums/products';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { UIRouter } from '@uirouter/core';
import { Namespace } from '../../../shared/enums/namespace';

@Component({
    selector: 'eaw-admin-employees-list',
    standalone: true,
    imports: [
        AsyncPipe,
        DataTableComponent,
        FormsModule,
        MatCard,
        MatFormField,
        MatInput,
        MatLabel,
        PageHeaderComponent,
        TranslatePipe,
        MatOption,
        MatSelect,
        ReactiveFormsModule,
        MatButton,
    ],
    templateUrl: './admin-employees-list.component.html',
    styleUrl: './admin-employees-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminEmployeesListComponent {
    private translate = inject(TranslateService);
    private employeeService = inject(EmployeeService);
    private customerProductService = inject(CustomerProductService);
    protected readonly uiRouter = inject(UIRouter);

    columns = computed(this.computeColumns.bind(this));
    getData = computed(() => {
        const filter = this.filter();
        const fields = this.searchFields();
        const include_inactive = this.includeInactive();
        return (pagination: Partial<DataTablePagination>) => this.employeeService.getAll(this.customerId(), {
            ...pagination,
            filter,
            include_inactive,
            'fields[]': fields,
            'with[]': [
                'warnings',
                ...this.hasSrc() ? [ 'properties' ] : [],
            ],
        }).pipe(tap(() => this.loading.set(false)));
    });

    table = viewChild.required(DataTableComponent);

    searchFields = signal<string[]>([ 'name', 'number', 'email', 'phone' ]);
    customerId = signal<number>(this.uiRouter.globals.params['id']);
    filter = signal<string>('');
    includeInactive = signal<boolean>(false);
    loading = signal<boolean>(false);
    selectableSearchFields = signal([
        { text: this.translate.syncT('NAME'), field: 'name' },
        { text: this.translate.syncT('NUMBER'), field: 'number' },
        { text: this.translate.syncT('EMAIL'), field: 'email' },
        { text: this.translate.syncT('PHONE'), field: 'phone' },
    ]);

    hasSrc = toSignal(this.customerProductService.hasProducts(this.customerId(), [ Products.SwissRiskAndCare ]));

    headerButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            icon: 'history',
            click: () => this.includeInactive.set(!this.includeInactive()),
            menuText: signal(this.translate.t('SHOW_INACTIVE')),
            active: () => this.includeInactive(),
        }),
    ];

    form = new FormGroup({
        search: new FormControl<string | null>(null),
        searchFields: new FormControl<string[] | null>(this.searchFields()),
    });

    computeColumns(): DataTableColumnType<Employee>[] {
        return [
            new DataTableTextColumn({
                value: 'id',
                header: new DataTableHeader({
                    text: 'Id',
                    sortBy: 'id',
                }),
            }),
            new DataTableTextColumn({
                value: (cell) => `#${cell.item.number}`,
                header: new DataTableHeader({
                    i18n: 'NUMBER',
                    sortBy: 'number',
                }),
            }),
            new DataTableTextColumn({
                value: 'name',
                header: new DataTableHeader({
                    i18n: 'NAME',
                    sortBy: 'name',
                }),
            }),
            new DataTableTextColumn({
                value: 'phone',
                header: new DataTableHeader({
                    i18n: 'PHONE',
                }),
            }),
            new DataTableDateTimeColumn({
                value: (cell) => cell.item.birthDate?.dateTime,
                format: DateTime.DATE_SHORT,
                header: new DataTableHeader({
                    i18n: 'BIRTH_DATE',
                    sortBy: 'birth_date',
                }),
            }),
            new DataTableDateTimeColumn({
                value: 'from',
                format: DateTime.DATETIME_MED,
                header: new DataTableHeader({
                    i18n: 'FROM',
                }),
            }),
            new DataTableDateTimeColumn({
                value: 'to',
                format: DateTime.DATETIME_MED,
                header: new DataTableHeader({
                    i18n: 'TO',
                }),
            }),
            new DataTableTextColumn({
                value: (cell) =>
                    cell.item.warnings.map((warning) => this.translate.syncT(warning.message, Namespace.Warnings, warning.messageParameters)).join('\n'),
                header: new DataTableHeader({
                    i18n: 'WARNING_plural',
                }),
            }),
        ];
    }

    constructor() {
        effect(() => this.loading() ? this.form.disable() : this.form.enable());
    }

    resetForm(): void {
        this.form.patchValue({
            search: null,
            searchFields: this.searchFields(),
        });
    }

    updateTable(): void {
        this.loading.set(true);
        this.filter.set(this.form.value.search || '');
        this.searchFields.set([ ...this.form.value.searchFields?.length ? [ ...this.form.value.searchFields ] : [ 'name', 'number' ] ]);
    }
}
