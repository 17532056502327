import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';

@Directive({
    selector: 'img[eawFlagSvg]',
    standalone: true,
})
export class FlagSvgDirective implements OnInit {
    // The country code of the flag
    @Input({ required: true }) eawFlagSvg?: string;

    constructor(@Inject(ElementRef) private elementRef: ElementRef) {
    }

    ngOnInit() {
        const element = this.elementRef.nativeElement as HTMLImageElement;
        element.src = `assets/flags/${this.eawFlagSvg}.svg`;
        element.alt = `Flag (${this.eawFlagSvg})`;
    }
}
