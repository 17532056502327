import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FlexitimeService } from '../../http/flexitime.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { DateTime } from 'luxon';
import { EawValidators } from '../../../shared/validators/eaw-validators';
import { BusinessDate } from '../../../shared/utils/business-date';
import { catchError, EMPTY } from 'rxjs';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export type AddFlexitimeDialogReturn = boolean;

export interface AddFlexitimeDialogData extends DialogData {
    employeeId: number;
    customerId: number;
}

@Component({
    selector: 'eaw-add-flexitime-dialog',
    templateUrl: './add-flexitime-dialog.component.html',
    styleUrl: './add-flexitime-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatRadioModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AddFlexitimeDialogComponent extends DialogComponent {
    form = new FormGroup({
        addTime: new FormControl<1 | -1>(1, { nonNullable: true }),
        businessDate: new FormControl<DateTime | null>(null, Validators.required),
        hours: new FormControl<number | null>(0, [ Validators.required, EawValidators.integer(), Validators.min(0), Validators.max(999) ]),
        minutes: new FormControl<number | null>(0, [ Validators.required, EawValidators.integer(), Validators.min(0), Validators.max(59) ]),
        comment: new FormControl<string | null>(null, Validators.required),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AddFlexitimeDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AddFlexitimeDialogComponent, AddFlexitimeDialogReturn>,
        @Inject(SnackBarService) protected snackBarService: SnackBarService,
        @Inject(FlexitimeService) public flexitimeService: FlexitimeService,
    ) {
        data.size ||= DialogSize.Medium;
        super(dialogRef, data);
    }

    add() {
        const hours = this.form.controls.hours.value || 0;
        const minutes = this.form.controls.minutes.value || 0;
        const addTime = this.form.controls.addTime.value;

        if (!this.form.value.businessDate || !this.form.value.comment) {
            return;
        }

        const flexitime = {
            // 1 / 60 = 0.01666... is saved with 5 decimal places, so when we multiply by 60 we get 0.99996 instead of 1. By adding 0.00001 it's correct up to 3 decimal places; 1.0002
            delta: (hours + (minutes / 60) + 0.00001) * addTime,
            business_date: new BusinessDate(this.form.value.businessDate),
            comment: this.form.value.comment,
        };

        this.form.disable();
        this.flexitimeService.create(this.data.customerId, this.data.employeeId, flexitime).pipe(
            catchError((error) => {
                console.error(error);
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe(async () => {
            const resultText = addTime > 0 ? 'ADDED_TIME' : 'DEDUCTED_TIME';
            this.dialogRef.close(!!addTime);
            await this.snackBarService.t(resultText, 'payroll');
        });
    }
}
