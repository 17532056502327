import { Component, Inject, Input, signal, ViewChild } from '@angular/core';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, of, tap } from 'rxjs';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { Property } from '../../../shared/models/property';
import { UserPropertyService } from '../../../shared/http/user-property.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { MatDialog } from '@angular/material/dialog';
import { AddEditPropertyDialogComponent, AddEditPropertyDialogData } from '../../dialogs/add-edit-property-dialog/add-edit-property-dialog.component';
import { DateTime } from 'luxon';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-admin-user-properties',
    templateUrl: './admin-user-properties.component.html',
    styleUrl: './admin-user-properties.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
    ],
})
export class AdminUserPropertiesComponent implements EawDataTable {
    @ViewChild('table') table?: DataTableComponent<Property>;

    @Input({ required: true }) userId!: number;

    headerButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            icon: 'delete_sweep',
            click: this.deleteMultiple.bind(this),
            menuText: signal(Promise.resolve('Delete all properties')),
        }),
    ];

    total = 0;
    filter = new FormControl<string | null>('');
    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<Property>[] = [
        new DataTableNumberColumn({
            value: 'id',
            header: new DataTableHeader({
                text: 'Id',
                sortBy: 'id',
            }),
        }),
        new DataTableTextColumn({
            value: 'key',
            header: new DataTableHeader({
                i18n: 'KEY',
                sortBy: 'key',
            }),
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.value.value,
            header: new DataTableHeader({
                i18n: 'VALUE',
                sortBy: 'value',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'createdAt',
            format: DateTime.DATETIME_MED,
            header: new DataTableHeader({
                i18n: 'CREATED_AT',
                sortBy: 'created_at',
            }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'edit',
                    click: this.updateProperty.bind(this),
                    show: () => of(true),
                    tooltip: { key: 'UPDATE' },
                },
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.deleteProperty.bind(this),
                    show: () => of(true),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    fabButton: HeaderFabButton = {
        click: this.createProperty.bind(this),
        hasPermission: () => of(true),
    };

    constructor(
        @Inject(UserPropertyService) private readonly userPropertyService: UserPropertyService,
        @Inject(MatDialog) private readonly matDialog: MatDialog,
        @Inject(TranslateService) private readonly translate: TranslateService,
        @Inject(ConfirmDialogService) private readonly confirmDialogService: ConfirmDialogService,
        @Inject(PromptDialogService) private readonly promptDialogService: PromptDialogService,
    ) {
        this.filter.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
        ).subscribe(() => {
            this.table?.refresh();
        });
    }

    createProperty() {
        this.matDialog.open<AddEditPropertyDialogComponent, AddEditPropertyDialogData, Property>(AddEditPropertyDialogComponent, {
            data: {
                userId: this.userId,
            },
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.table?.refresh();
        });
    }

    updateProperty(cell: DataTableButtonCell<Property>) {
        this.matDialog.open<AddEditPropertyDialogComponent, AddEditPropertyDialogData, Property>(AddEditPropertyDialogComponent, {
            data: {
                userId: this.userId,
                property: of(cell.item),
            },
        }).afterClosed().subscribe((result) => {
            if (!result) {
                cell.disabled.set(false);
                return;
            }

            this.table?.refresh();
        });
    }

    deleteProperty(cell: DataTableButtonCell<Property>) {
        this.confirmDialogService.delete({
            title: Promise.resolve('Delete property'),
            name: cell.item.key,
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.userPropertyService.delete(this.userId, cell.item.id).subscribe(() => {
                this.table?.refresh();
            });
        });
    }

    deleteMultiple() {
        this.promptDialogService.open('text', {
            title: this.translate.t('DELETE_MULTIPLE_PROPERTIES', 'admin'),
            text: this.translate.t('DELETE_MULTIPLE_PROPERTIES_TEXT', 'admin'),
            label: Promise.resolve('FILTER'),
            confirmButtonColor: 'warn',
            confirmText: this.translate.t('DELETE'),
            required: true,
            formControl: new FormControl<string | null>(null, Validators.required),
            options: {
                minLength: 5,
            },
        }).afterClosed().subscribe((filter) => {
            if (!filter) {
                return;
            }

            this.userPropertyService.deleteMultiple(this.userId, filter).subscribe(() => {
                this.table?.refresh();
            });
        });
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.userPropertyService.getAll(this.userId, {
            ...pagination,
            filter: this.filter.value || undefined,
        }).pipe(
            tap((result) => (this.total = result.total)),
        );
    }
}
