import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';

export type AutocompleteTemplate = 'default' | 'employee' | 'shiftEmployee' | 'dialCode' | 'tariff';
export type AutoCompleteData<Item> = {
    label?: Promise<string>;
    optionTemplate?: AutocompleteTemplate;
    debounce?: number;
    filterRequirement?: number;
    // The property we use to display in the input
    display: keyof Item | ((item: Item) => string);
    trackByKey: keyof Item;
};

export type AutocompleteSetter<Item> = (item: unknown) => Observable<Item | undefined>;
export type AutocompleteGetter<Item> = (filter?: string) => Observable<ArrayPaginatedResponse<Item>>;

export type AutocompleteOptions<Item> = {
    options: AutoCompleteData<Item>;
    getter: AutocompleteGetter<Item>;
    setter: AutocompleteSetter<Item>;
};
export abstract class Autocomplete<Item> {
    readonly options: Required<AutoCompleteData<Item>>;

    protected constructor(data: AutoCompleteData<Item>) {
        this.options = {
            label: Promise.resolve('No label'),
            optionTemplate: 'default',
            debounce: 300,
            filterRequirement: 0,
            ...data,
        };
    }

    abstract setter(...args: any[]): AutocompleteSetter<Item>

    abstract getter(...args: any[]): AutocompleteGetter<Item>
}
