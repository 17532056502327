import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
import { DateTimeConverter } from '../../../../shared/utils/date-time-converter';
import { WebsocketService } from '../../../../shared/services/websocket.service';
import { AngularJsDestroyRef } from '../../../../shared/angularjs/angularjs-destroy-ref.class';

module('eaw.dashboard').component('punchedToday', {
    template: `
<div eaw-invisible="!$punched.inTime">
    <span ng-bind="$punched.inTime"></span>
</div>

<div class="punched-main" ng-bind="$punched.i18n"></div>

<div ng-if="$punched.shift" eaw-invisible="!$punched.outTime">
    <span ng-bind="$punched.outTime"></span>
</div>
`,
    controllerAs: '$punched',
    require: {
        mini: '^eawMiniWidget',
    },
    controller: [ 'TimepunchFactory', 'ShiftFactory', '$interval', '$filter', 'WebsocketDowngrade', function(TimepunchFactory, ShiftFactory, $interval: angular.IIntervalService, $filter: angular.FilterFactory, WebsocketDowngrade: WebsocketService) {
        // @ts-ignore
        const ctrl = this;
        const destroyRef = new AngularJsDestroyRef();

        ctrl.$onInit = () => {
            WebsocketDowngrade.listenEmployeeTimepunches(ctrl.mini.widget.customer, ctrl.mini.widget.employee.id, 'punched', ctrl.onTimepunch, destroyRef);
            ctrl.getData();
        };

        ctrl.$onDestroy = () => {
            destroyRef.destroy();
            ctrl.cancelInterval();
            ctrl.tpRes?.$cancelRequest?.();
        };

        ctrl.createInterval = () => {
            ctrl.cancelInterval();
            ctrl.interval = $interval(ctrl.updateTime, 10_000);
        };

        ctrl.onTimepunch = (timepunch: any) => {
            if (timepunch.employee_id !== ctrl.mini.widget.employee.id) {
                return;
            }

            timepunch.in = DateTimeConverter.convertStringToMoment(timepunch.in);
            timepunch.out = DateTimeConverter.convertStringToMoment(timepunch.out);

            // we need to get all timepunches again, as there is a chance that there were some timepunch changes before this call and page load
            ctrl.getData();

            const i = ctrl.timepunches.findIndex((tp: any) => tp.id === timepunch.id);

            if (i >= 0) {
                ctrl.timepunches[i] = timepunch;
            } else {
                ctrl.timepunches.push(timepunch);
            }

            ctrl.in = !timepunch.out ? timepunch.in.clone() : null;
            ctrl.currentPunch = !timepunch?.out ? timepunch : null;

            ctrl.updateTime();

            if (ctrl.in) {
                // If the employee punched out, we don't need to create a new interval.
                ctrl.createInterval();
            } else {
                // Cancel the current interval, we'll start a new one if they punch in
                ctrl.cancelInterval();
            }
        };

        ctrl.getData = () => {
            ctrl.tpRes = TimepunchFactory.getWithShifts({
                customer_id: ctrl.mini.widget.customer,
                employee_id: ctrl.mini.widget.employee.id,
                with_trashed: false,
                from: moment().startOf('d'),
                to: moment().endOf('d'),
                per_page: 1,
            });

            return ctrl.tpRes.$promise.then((response: any) => {
                const timepunches = Object.values(response.data)
                    .map((o: any) => Object.values(o))
                    .flat(2)
                    .sort((a: any, b: any) => a.in - b.in);

                let shifts: any[] = [];
                if (timepunches.length) {
                    shifts = Object.values(timepunches.flatMap((tp: any) => tp.shifts).reduce((acc, shift) => {
                        // Shift will be undefined if there were no overlapping shifts.
                        if (shift) {
                            acc[shift.id] = shift; // no dupes
                        }
                        return acc;
                    }, {})).sort((a: any, b: any) => a.from - b.from);
                }
                if (!shifts.length) {
                    return ShiftFactory.getAllForEmployee(ctrl.mini.widget.customer, ctrl.mini.widget.employee.id, {
                        from: moment().startOf('d'),
                        to: moment().endOf('d'),
                    }).then((resp: any) =>
                        // First shift first if there are more.
                        [ resp.data.sort((a: any, b: any) => a.from - b.from), timepunches ]);
                }
                return [ shifts, timepunches ];
            }).then(([ shifts, timepunches ]: any) => {

                ctrl.timepunches = timepunches.sort((a: any, b: any) => a.in - b.in);

                const lastTimepunch: any = ctrl.timepunches[timepunches.length - 1];

                ctrl.shift = shifts[0];

                ctrl.in = !!lastTimepunch?.out ? null : lastTimepunch?.in;
                ctrl.currentPunch = !lastTimepunch?.out ? lastTimepunch : null;

                ctrl.updateTime();

                if (ctrl.in) {
                    ctrl.createInterval();
                }
            }).finally(() => {
                ctrl.mini.loading(false);
            });
        };

        ctrl.updateTime = () => {
            const now: moment.Moment = moment();

            ctrl.shiftLength = ctrl.shift?.length || 0;
            ctrl.punchedTime = ctrl.timepunches?.reduce((sum: number, timepunch: any) => {
                let out = timepunch.out;
                if (!out) {
                    out = now;
                }

                return sum + out.diff(timepunch.in, 's');
            }, 0);

            if (ctrl.in) {
                ctrl.tpDate = ctrl.in.format('LL');
                ctrl.inTime = ctrl.in.format('LT');
                ctrl.outTime = ctrl.in.clone().add(ctrl.shiftLength - (ctrl.punchedTime - (ctrl.currentPunch?.length || 0)), 's').format('LT');
            } else {
                ctrl.tpDate = null;
                ctrl.inTime = null;
                ctrl.outTime = null;
            }

            ctrl.i18n = t('widgets:X_OF_Y', {
                x: $filter('eawDuration')(ctrl.punchedTime, 'narrow', 'hm', 'narrow'),
                y: $filter('eawDuration')(ctrl.shiftLength, 'narrow', 'hm', 'narrow'),
            });
        };

        ctrl.cancelInterval = () => {
            $interval.cancel(ctrl.interval);
        };
    } ],
});
