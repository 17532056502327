import moment from 'moment-timezone';
import { module } from 'angular';
import { EawResourceFactory } from '../../../shared/angularjs/modules/resource/resource.service';

module('eaw.payroll.punch-clock').factory('PunchClock', [ 'EawResource', function PunchClock(EawResource: EawResourceFactory) {
    // @ts-ignore
    const factory = this;

    factory.punchIn = (customerId: number, employeeId: number, businessDate: any, businessUnit: number, comment: string) => EawResource.create('/customers/:customer_id/employees/:employee_id/timepunches/in').save({
        customer_id: customerId,
        employee_id: employeeId,
    }, {
        business_date: businessDate,
        comment,
        business_unit_id: businessUnit,
    }).$promise;

    factory.punchOut = (customerId: number, employeeId: number, comment: string) => EawResource.create('/customers/:customer_id/employees/:employee_id/timepunches/out').save({
        customer_id: customerId,
        employee_id: employeeId,
    }, { comment }).$promise;

    factory.activeTimepunch = (customerId: number, employeeId: number) => EawResource.create('/customers/:customer_id/employees/:employee_id/timepunches/active', {}, {
        get: {
            method: 'GET',
            headers: {
                'X-Ignore-Error': true,
            },
        },
    }).get({
        customer_id: customerId,
        employee_id: employeeId,
    });

    factory.suggestedTimePunch = (customerId: number, employeeId: number) => EawResource.create('/customers/:customer_id/employees/:employee_id/timepunches/:punch_direction').get({
        customer_id: customerId,
        employee_id: employeeId,
        punch_direction: 'business_date',
    }).$promise.then((resp: any) => moment(Object.values(resp).join('').slice(0, 10), 'YYYY-MM-DD'));
    return factory;
} ]);
