import { Component, Inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import { DocumentServiceService } from '../../../shared/http/document-service.service';
import { WorkflowRun, WorkflowRunResponse } from '../../../shared/models/workflow-run';
import { MatDialog } from '@angular/material/dialog';
import { catchError, EMPTY, forkJoin, map, of } from 'rxjs';
import { DataTablePagination, DataTableRequest } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableIconColumn } from '../../../data-table/types/data-table-icon-column';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { CurrentService } from '../../../shared/services/current.service';
import { EawUrl } from '../../../shared/angularjs/modules/resource/url.service';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { TimepunchSummary, TimepunchSummaryService } from '../../http/timepunch-summary.service';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { PdfRendererDialogComponent } from '../../../shared/dialogs/pdf-renderer-dialog/pdf-renderer-dialog.component';
import { BusinessDate } from '../../../shared/utils/business-date';
import { Namespace } from '../../../shared/enums/namespace';
import { HttpClient } from '@angular/common/http';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { DateIntervalSelectorComponent } from '../../../shared/components/date-interval-selector/date-interval-selector.component';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { PdfRendererDialogData } from '../../../shared/dialogs/pdf-renderer-dialog/pdf-renderer-dialog.service';
import { SettingService } from '../../../shared/http/setting.service';
import { AlertDialogComponent } from '../../../shared/dialogs/alert-dialog/alert-dialog.component';

interface Row {
    employeeName: string;
    employeeId: number;
    customerId: number;
    latestWorkflowRun?: WorkflowRun;
    timepunchesCount: number;
    timepunchesLength: number;
    // Whether a new verification task can be created
    canCreate: boolean;
}

interface ContentType {
    label: string;
    workflowId: string;
    excludedColumns?: string[];
}

@Component({
    selector: 'eaw-report-verification',
    templateUrl: './report-verification.component.html',
    styleUrl: './report-verification.component.scss',
    providers: [ DurationPipe ],
    standalone: true,
    imports: [
        PageHeaderComponent,
        DateIntervalSelectorComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ReportVerificationComponent implements OnInit {
    @ViewChild(DataTableComponent) table?: DataTableComponent<Row>;

    @Input({ required: true }) contentType!: string;
    @Input({ required: true }) customerId!: number;

    private readonly maxDate = DateTime.now().endOf('month');

    private readonly contentTypes: Record<string, ContentType> = {
        timepunchValidation: {
            label: 'TIMEPUNCH_VALIDATION',
            workflowId: 'b711dbc4-deca-40b8-bab6-723e37b19aa0',
        },
        monthlyBalance: {
            label: 'CCNT_MONTHLY_BALANCE_SHEET',
            workflowId: '416ad29c-f6ff-470d-9e07-1fb56c8a9990',
            excludedColumns: [ 'timepunchesCount', 'timepunchesLength' ],
        },
        monthlyPaidTime: {
            label: 'MONTHLY_PAID_TIME_SHEET',
            workflowId: '2315cd3c-8848-4121-82e0-45f34648f47a',
            excludedColumns: [ 'timepunchesCount', 'timepunchesLength' ],
        },
    };

    label: string = '';
    workflowId: string = '';
    excludedColumns: string[] = [];

    request?: DataTableRequest;
    interval: { from: DateTime, to: DateTime } = {
        from: DateTime.now().minus({ months: 1 }).startOf('month'),
        to: DateTime.now().minus({ months: 1 }).endOf('month'),
    };

    fileTypeId?: number;
    fileTypeNotConfigured = false;

    columns: DataTableColumnType<Row>[] = [
        new DataTableTextColumn({
            value: 'employeeName',
            header: new DataTableHeader({
                i18n: 'EMPLOYEE',
                sortBy: 'employeeName',
            }),
        }),
        new DataTableNumberColumn({
            value: 'timepunchesCount',
            header: new DataTableHeader({
                i18n: 'NUMBER_OF_TIMEPUNCHES',
                ns: Namespace.Reports,
                sortBy: 'timepunchesCount',
            }),
        }),
        new DataTableTextColumn({
            key: 'timepunchesLength',
            value: (cell) => this.durationPipe.transform(cell.item.timepunchesLength, [ 'hours', 'minutes' ]),
            header: new DataTableHeader({
                i18n: 'HOUR_plural',
                sortBy: 'timepunchesLength',
            }),
        }),
        new DataTableIconColumn({
            header: new DataTableHeader({
                i18n: 'APPROVED',
                ns: 'vacation',
            }),
            icon: (cell) => {
                switch (cell.item.latestWorkflowRun?.status) {
                    case 'in_progress':
                        return 'pending';
                    case 'completed':
                        return 'check_circle';
                    case 'cancelled':
                        return 'cancel';
                    default:
                        return 'pending';
                }
            },
            color: (cell) => {
                switch (cell.item.latestWorkflowRun?.status) {
                    case 'completed':
                        return 'green-500';
                    case 'cancelled':
                        return 'red-500';
                    default:
                        return 'amber-500';
                }
            },
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'picture_as_pdf',
                    click: (cell) => this.previewPdf(cell.item.latestWorkflowRun, cell.item.customerId, cell.item.employeeId),
                    hide: (row) => of(!row.latestWorkflowRun),
                    show: () => of(true),
                    tooltip: { key: 'PREVIEW' },
                    nonBlocking: true,
                },
                {
                    icon: 'add',
                    click: this.createVerification.bind(this),
                    hide: (row) => of(this.contentType !== 'timepunchValidation' || !row.canCreate || this.interval.from > this.maxDate),
                    show: () => of(true),
                    tooltip: { key: 'ADD' },
                },
                {
                    icon: 'refresh',
                    click: this.regenerate.bind(this),
                    hide: (row) => of(!((this.contentType === 'monthlyBalance' || this.contentType === 'monthlyPaidTime') && (!row.latestWorkflowRun || row.latestWorkflowRun?.status === 'cancelled' || row.latestWorkflowRun?.status === 'failed'))),
                    show: () => of(true),
                    tooltip: { key: 'REGENERATE' },
                },
            ],
        }),
    ];

    constructor(
        @Inject(CurrentService) public current: CurrentService,
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(DocumentServiceService) private tpVerificationService: DocumentServiceService,
        @Inject(DurationPipe) private durationPipe: DurationPipe,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(TimepunchSummaryService) private timepunchSummaryService: TimepunchSummaryService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(HttpClient) private http: HttpClient,
        @Inject(SettingService) public settingService: SettingService,
    ) {
    }

    ngOnInit() {
        const contentType = this.contentTypes[this.contentType];
        if (contentType) {
            this.label = contentType.label;
            this.workflowId = contentType.workflowId;
            this.excludedColumns = contentType.excludedColumns || [];
        }
        this.columns = this.columns.filter((column) => !this.excludedColumns?.includes(column.key));
        this.settingService.getValue<number | null>([ 'customers', this.customerId ], 'payroll.timepunch_validation_file_type_id', 0).subscribe((setting) => {
            if (setting) {
                this.fileTypeId = setting;
            } else {
                this.fileTypeNotConfigured = true;
            }
        });

    }

    regenerate(cell: DataTableButtonCell<Row>) {
        this.confirmDialogService.open({
            title: this.translate.t('REGENERATE'),
            text: this.translate.t('REGENERATE_REPORT_TEXT', Namespace.Reports),
            confirmText: this.translate.t('REGENERATE'),
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            // regenerate currently works only for ccnt balances or paid time reports, so url switches between two similar endpoints
            const url = this.contentType == 'monthlyBalance' ? 'ccnt-balance-report' : 'monthly-paid-time-sheet';

            this.http.post(`/customers/${cell.item.customerId}/employees/${cell.item.employeeId}/${url}/regenerate`, {
                month: this.month.month,
                year: this.month.year,
            }).subscribe(() => {
                this.table?.refresh();
                this.snackBarService.t('REGENERATE_JOB_STARTED');
            });
        });
    }

    createVerification(cell: DataTableButtonCell<Row>) {
        if (this.fileTypeNotConfigured) {
            this.matDialog.open(AlertDialogComponent, {
                data: {
                    title: signal(this.translate.t('TIMEPUNCH_VALIDATION', Namespace.Reports)),
                    text: signal(this.translate.t('CCNT_FILE_TYPE_NOT_CONFIGURED', Namespace.Reports)),
                },
            }).afterClosed().subscribe(() => {
                cell.disabled.set(false);
            });
        } else {
            const isCurrentMonth = this.interval.from.hasSame(DateTime.now(), 'month');
            const text = isCurrentMonth ? 'CREATE_VERIFICATION_TEXT_CURRENT_MONTH' : 'CREATE_VERIFICATION_TEXT';

            this.confirmDialogService.open({
                title: this.translate.t('CREATE_VERIFICATION_TITLE', Namespace.Reports),
                text: this.translate.t(text, Namespace.Reports, { name: cell.item.employeeName }),
                confirmText: this.translate.t('CREATE'),
            }).afterClosed().subscribe((result) => {
                if (result?.ok && this.fileTypeId) {
                    this.tpVerificationService.createVerification(this.month, cell.item.customerId, cell.item.employeeId, this.workflowId, this.fileTypeId, this.current.getUser().id).pipe(
                        catchError(() => {
                            cell.disabled.set(false);
                            return EMPTY;
                        }),
                    ).subscribe(() => {
                        this.table?.refresh();
                        void this.snackBarService.t('VERIFICATION_CREATED', Namespace.Reports, { name: cell.item.employeeName });
                    });
                } else {
                    cell.disabled.set(false);
                }
            });
        }
    }

    previewPdf(run: WorkflowRun | undefined, customerId: number, employeeId: number) {
        if (run === undefined) {
            return;
        }

        const workflowId = run.workflowId;
        const runId = run.id;
        const documentId = run.getMostRecentDocumentVersion();

        return this.matDialog.open<PdfRendererDialogComponent, PdfRendererDialogData>(PdfRendererDialogComponent, {
            data: {
                url: of(EawUrl.getUrl(`/document-service/1.0.0/customers/${customerId}/employees/${employeeId}/workflows/${workflowId}/runs/${runId}/documents/${documentId}`)),
            },
        });
    }

    getLatestWorkflowRun(runs: WorkflowRun[]) {
        if (runs.length === 0) {
            return undefined;
        }

        return runs.sort((a, b) => +b.updatedAt - +a.updatedAt)[0];
    }

    get month() {
        return this.interval.from;
    }

    get prefix() {
        return `customer-${this.current.getCustomer().id}:period-${this.month.toFormat('yyyy-MM')}:employee-`;
    }

    updateTable(pagination: DataTablePagination, interval?: { from: DateTime, to: DateTime }) {
        // Update the selected interval
        if (interval) {
            this.interval = interval;
        }

        this.request = forkJoin([
            this.timepunchSummaryService.getSummary(
                this.customerId,
                new BusinessDate(interval?.from || this.interval.from),
                new BusinessDate(interval?.to || this.interval.to),
            ),
            this.tpVerificationService.getAllRuns(this.customerId, this.workflowId, this.prefix),
        ]).pipe(
            catchError(() => of(undefined)),
            map((response) => {
                const summary: TimepunchSummary[] = response ? response[0] : [];
                const runs: WorkflowRunResponse[] = response ? response[1] : [];

                const rows: Row[] = summary.map((row) => {
                    // Find runs for this employee
                    const employeeRuns = runs.filter((run) => run.run_identifier.endsWith(`employee-${row.employee_id}`)).map((run) => new WorkflowRun(run));

                    // Find the most recent run for this employee
                    const latestWorkflowRun = this.getLatestWorkflowRun(employeeRuns);

                    return {
                        timepunchesLength: parseInt(String(row.length)),
                        employeeName: String(row.employee_name),
                        employeeId: row.employee_id || 0,
                        customerId: this.customerId,
                        timepunchesCount: parseInt(String(row.timepunch_count)),
                        latestWorkflowRun,
                        canCreate: !employeeRuns.length || employeeRuns.every((run) => run.status === 'cancelled'),
                    };
                });

                // Return data
                const data = sort(rows, this.current.languageTag, [ (row) => {
                    switch (pagination.order_by) {
                        case 'employeeName':
                            return row.employeeName;
                        case 'timepunchesCount':
                            return row.timepunchesCount;
                        case 'timepunchesLength':
                            return row.timepunchesLength;
                        default:
                            return row.employeeName;
                    }
                } ], [ pagination.direction || 'asc' ]);

                return mockArrayPaginatedResponse(data) satisfies ArrayPaginatedResponse<Row>;
            }),
        );
    }

    protected readonly Namespace = Namespace;
}
