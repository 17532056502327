// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FormEnum {
    export enum Layout {
        HORIZONTAL = 'h',
        VERTICAL = 'v',
    }

    export enum Position {
        OVER = 'o',
        LEFT = 'l',
        RIGHT = 'r',
    }

    export enum Parent {
        FORM = 'form',
        ELEMENT_GROUP = 'element_group',
    }

    export enum Type {
        NUMBER = 'number',
        DATE = 'date',
        TEXTAREA = 'textarea',
        INPUT = 'input',
        CHECKBOX = 'checkbox',
        RADIO = 'radio',
        SELECT = 'select',
        IMAGE = 'image',
        TEXT = 'text',
    }
}
