// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';

module('eaw.scheduling').controller('weekOverviewBottomSheetCtrl', [ '$mdBottomSheet', function weekOverviewBottomSheetCtrl($mdBottomSheet) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.items = [
            {
                name: t('scheduling:NEW_SHIFT'),
                icon: 'add',
                action: 'new',
            },
        ].concat(ctrl.shifts.map((s) => {
            return {
                shift: s,
                name: `${s.from.format('LT')} - ${s.to.format('LT')}`,
                icon: 'edit',
                action: 'edit',
            };
        }));

        if (ctrl.hasAbsence && ctrl.employee.id) {
            ctrl.items.splice(1, 0, {
                name: t('absences:ADD_ABSENCE'),
                icon: 'add',
                action: 'newAbsence',
            });
        }
    };

    ctrl.itemClick = (item) => {
        $mdBottomSheet.hide({
            action: item.action,
            shift: item.shift,
        });
    };
} ]);
