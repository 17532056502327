import { contentChild, Directive, effect, ElementRef, inject, OnDestroy } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioButton } from '@angular/material/radio';

@Directive({
    selector: 'button[eawMatMenuItem]',
    standalone: true,
})
export class MatMenuItemDirective implements OnDestroy {
    private elementRef = inject(ElementRef) as ElementRef<HTMLButtonElement>;

    matCheckbox = contentChild(MatCheckbox);
    matRadioButton = contentChild(MatRadioButton);

    private destroyController = new AbortController();

    constructor() {
        effect(() => {
            const checkbox = this.matCheckbox();
            const radioButton = this.matRadioButton();

            if (checkbox || radioButton) {
                this.elementRef.nativeElement.addEventListener('click', this.clickListener.bind(this), { signal: this.destroyController.signal });
            }
        });
    }

    ngOnDestroy() {
        this.destroyController.abort();
    }

    clickListener(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        this.matCheckbox()?.toggle();

        const radioButton = this.matRadioButton();
        if (radioButton) {
            radioButton.checked = true;
            radioButton.radioGroup._controlValueAccessorChangeFn(radioButton.value);
        }
    }
}
