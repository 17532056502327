import { module } from 'angular';
import { Storage } from '../../../../../../shared/utils/storage';
import { scheduleViewDragAndDrop } from '../../../../../signals';
import { moveItemInArray } from '@angular/cdk/drag-drop';

module('eaw.scheduling').component('dragAndDropBusinessUnits', {
    template: `<md-content>
    <div class="tw-p-0">
        <div ng-repeat="unit in $ctrl.units track by unit.id"
        class="tw-flex tw-items-center tw-gap-8 tw-p-2"
        style="cursor: pointer;"
                      ng-style=" {background: unit.backgroundColor, color: unit.textColor}"
                      ng-click="$ctrl.selectUnit(unit, $event)">
                      
                         <md-button class="md-icon-button" ng-click="$ctrl.move($index, 'up', $event)" ng-disabled="$index === 0" style="color: inherit">
                            <md-icon style="color: inherit" ng-bind="'arrow_upward'"></md-icon>
                        </md-button>
                             
                             <md-button class="md-icon-button" ng-click="$ctrl.move($index, 'down', $event)" ng-disabled="$index === $ctrl.last" style="color: inherit">
                            <md-icon style="color: inherit" ng-bind="'arrow_downward'"></md-icon>
                        </md-button>
            
            <p ng-bind="unit.name"></p>
            <md-icon style="color: inherit" class="md-secondary" ng-show="unit.selected" ng-bind="'done'"></md-icon>
            <md-divider></md-divider>
        </div>
    </div>
</md-content>
`,
    require: {
        sidebar: '^scheduleSidebar',
        scheduleView: '^scheduleView',
    },
    controller: [ '$rootScope', function($rootScope: angular.IRootScopeService) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$postLink = async () => {
            ctrl.units = ctrl.scheduleView.getBusinessUnits();
            ctrl.last = ctrl.units.length - 1;
        };
        ctrl.$onDestroy = () => {
            if (ctrl.scheduleView.smallDevice) {
                return;
            }

            scheduleViewDragAndDrop.set(undefined);
        };
        ctrl.move = (index: number, dir: 'up' | 'down', $event: any) => {
            $event.stopPropagation();

            moveItemInArray(ctrl.units, index, dir === 'up' ? index - 1 : index + 1);
            ctrl.setUnitOrder();
        }

        ctrl.setUnitOrder = () => {
            Storage.setItem('schedule:unit_order', ctrl.units.map((u: any) => u.id));
            $rootScope.$broadcast('schedule:unit_order:changed');
        };
        ctrl.selectUnit = (unit: any) => {
            // Deselect anything that might be selected
            ctrl.units.forEach((u: any) => u.selected = false);
            // Selection part
            if (unit?.id === scheduleViewDragAndDrop()?.id && scheduleViewDragAndDrop()?.type === 'unit') {
                ctrl.$onDestroy();
            } else {
                scheduleViewDragAndDrop.set({ id: unit.id, data: unit, type: 'unit' });
                unit.selected = true;
                ctrl.scheduleView.smallSidenav?.close?.();
            }
        };
    } ],
});
