<eaw-page-header [fabButton]="fabButton">
    <div title>{{'NOTE_plural' | translate | async}}</div>
</eaw-page-header>

<div class="progress-container" *ngIf="loading">
    <mat-progress-spinner diameter="64" mode="indeterminate"></mat-progress-spinner>
</div>

<mat-card *ngIf="loaded && !loading" id="notes-card">
    <mat-card-content class="pretty-scroll">
        <eaw-comment-list *ngIf="pages.get(page)" deleteColor="grey-500" [comments]="pages.get(page) || []" [canDelete]="canDelete" [deleteCallback]="deleteComment"/>
    </mat-card-content>

    <mat-card-footer id="footer">
        <mat-paginator class="tw-flex tw-flex-row tw-basis-full tw-justify-end tw-content-end"
                       [length]="total"
                       (page)="pageChanged($event)"
                       [pageSizeOptions]="[ 10, 25, 50, 100 ]"
                       [showFirstLastButtons]="!!(total && total > perPage * 3)"
                       aria-label="Select page">
        </mat-paginator>
    </mat-card-footer>
</mat-card>
