<eaw-dialog-header>
    <span title *ngIf="!data.rotation">{{'CREATE_ROTATION' | translate:'rotation' | async}}</span>
    <span title *ngIf="data.rotation">{{'MANAGE_ROTATION' | translate:'rotation' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-12 tw-pt-4 tw-pb-4">
    <form [formGroup]="formGroup" class="tw-mb-4 tw-grid tw-grid-cols-12 tw-gap-8">
        <mat-form-field class="tw-col-[1_/_5]">
            <mat-label>{{'NAME' | translate | async}}</mat-label>
            <input matInput formControlName="name" autocomplete="off">
        </mat-form-field>

        <mat-form-field class="tw-col-[5_/_9]">
            <mat-label>{{'START_DAY' | translate:'rotation' | async}}</mat-label>
            <mat-select formControlName="startDay" (selectionChange)="createWeeks()">
                <mat-option *ngFor="let day of days" [value]="day.index">{{day.dateTime | DateTime:'WEEKDAY'}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="tw-col-[9_/_-1]">
            <mat-label>{{'WEEK_plural' | translate | async}}</mat-label>
            <mat-select formControlName="weeks" (selectionChange)="createWeeks()">
                <mat-option [value]="1">{{1 | eawNumber:0}}</mat-option>
                <mat-option [value]="2">{{2 | eawNumber:0}}</mat-option>
                <mat-option [value]="3">{{3 | eawNumber:0}}</mat-option>
                <mat-option [value]="4">{{4 | eawNumber:0}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>

    <div class="week-card mat-elevation-z4">
        <div class="week"
             *ngFor="let week of weeks; index as weekIndex"
             [class.last]="formGroup.value.startDay === 0 ? weekIndex >= formGroup.value.weeks! - 1 : weekIndex > formGroup.value.weeks! - 1">
            <div class="day-section tw-flex tw-flex-row" *ngFor="let day of week; index as dayIndex" [class.display-none]="day.hide">
                <div class="day tw-flex tw-flex-col tw-justify-center tw-content-start">
                    <span class="body-1">{{'WEEK_N' | translate:'general':{number: day.week} | async}}</span>
                    <span class="mat-body-1">{{day.dateTime | DateTime:'WEEKDAY'}}</span>
                </div>

                <div class="times tw-flex tw-flex-row tw-justify-start tw-items-center">
                    <div class="inputs tw-grid tw-grid-cols-[repeat(2,_76px)] tw-items-center tw-items-center tw-gap-4">
                        <eaw-time-input #from class="tw-col-[1_/_2]" [label]="'FROM' | translate" (enterPress)="addInputInterval(day, weekIndex, dayIndex, from, to)"></eaw-time-input>
                        <eaw-time-input #to class="tw-col-[2_/_3]" [label]="'TO' | translate" (enterPress)="addInputInterval(day, weekIndex, dayIndex, from, to)"></eaw-time-input>
                        <button mat-icon-button class="tw-col-[3_/_-1]" (click)="addInputInterval(day, weekIndex, dayIndex, from, to)" tabindex="-1" [disabled]="!from.timeInput.value || !to.timeInput.value">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>

                    <span class="no-hours" *ngIf="!day.intervals.length">{{'NO_HOURS' | translate:'rotation' | async}}</span>

                    <mat-chip-listbox class="tw-flex tw-flex-row" *ngIf="day.intervals.length">
                        <mat-chip-option *ngFor="let interval of day.intervals; index as i"
                                  [matTooltip]="interval.nextDay ? 'Next day' : ''"
                                  [color]="interval.nextDay ? 'warn' : undefined"
                                  tabindex="-1"
                                  [disabled]="interval.processing"
                                  [selected]="interval.nextDay"
                                  (removed)="removeInterval(day.intervals, i)">
                            {{interval.display.from}} - {{interval.display.to}}
                            <button matChipRemove *ngIf="!interval.processing">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-option>
                    </mat-chip-listbox>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-raised-button [mat-dialog-close]>{{'CLOSE' | translate | async}}</button>
    <button mat-button mat-raised-button color="primary" *ngIf="!data.rotation" (click)="updateRotation()" [disabled]="formGroup.invalid || formGroup.disabled">{{'CREATE' | translate | async}}</button>
</mat-dialog-actions>
