import { User, UserResponse } from '../../shared/models/user';
import { EawFormData } from '../types/interfaces';
import { EawForm } from '../types/form';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { FormSubmissionNote, FormSubmissionNoteResponse } from './form-submission-note';

type SubmissionData = { recipients: number[] } & Record<string, string>;

export interface FormSubmissionResponse {
    id: number;
    anonymous: boolean;
    form_id: number;
    user_id?: number | null;
    user?: UserResponse;
    data: SubmissionData;
    form?: EawFormData;
    notes?: FormSubmissionNoteResponse[];
    closed_by?: number;
    anonymized_by?: number;
    closed_at?: string | null;
    anonymized_at?: string | null;
    created_at?: string | null;
    updated_at?: string | null;

    notes_count?: number;
}

export class FormSubmission {
    id: number;
    anonymous: boolean;
    formId: number;
    userId?: number | null;
    user?: User;
    data?: SubmissionData;
    form?: EawForm;
    notes?: FormSubmissionNote[];
    closedBy?: number | null;
    anonymizedBy?: number | null;
    closedAt?: DateTime | null;
    anonymizedAt?: DateTime;
    createdAt?: DateTime | null;
    updatedAt?: DateTime | null;

    notesCount?: number;

    constructor(data: FormSubmissionResponse) {
        this.id = data.id;
        this.anonymous = data.anonymous;
        this.formId = data.form_id;
        this.userId = data.user_id;
        this.user = data.user ? new User(data.user) : undefined;
        this.data = data.data;
        this.form = data.form ? new EawForm(data.form) : undefined;
        this.notes = data.notes?.map((note) => new FormSubmissionNote(note));
        this.closedBy = data.closed_by;
        this.anonymizedBy = data.anonymized_by;
        this.closedAt = data.closed_at ? stringToDateTime(data.closed_at) : undefined;
        this.anonymizedAt = data.anonymized_at ? stringToDateTime(data.anonymized_at) : undefined;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;

        this.notesCount = data.notes_count;
    }
}
