import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { TimepunchHistoryDialogData } from './timepunch-history-dialog.service';
import { RequestedTimepunchAttachment, TimepunchAttachment, TimepunchService } from '../../http/timepunch.service';
import { Timepunch } from '../../models/timepunch';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { NgIf, NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { DocumentServiceService } from '../../../shared/http/document-service.service';

@Component({
    selector: 'eaw-timepunch-history-dialog',
    templateUrl: './timepunch-history-dialog.component.html',
    styleUrl: './timepunch-history-dialog.component.scss',
    standalone: true,
    imports: [
        NgIf,
        DialogHeaderComponent,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatDividerModule,
        NgFor,
        MatListModule,
        MatIconModule,
        AsyncPipe,
        KeyValuePipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class TimepunchHistoryDialogComponent extends DialogComponent implements OnInit {
    loading = false;
    timepunch?: Timepunch;
    attachments: TimepunchAttachment[] = [];
    attachmentsRejected: RequestedTimepunchAttachment[] = [];
    attachmentsInProgress: RequestedTimepunchAttachment[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: TimepunchHistoryDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<TimepunchHistoryDialogComponent, undefined>,
        @Inject(TimepunchService) public timepunchService: TimepunchService,
        @Inject(DocumentServiceService) private documentService: DocumentServiceService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
    ) {
        super(dialogRef, data);
    }

    ngOnInit(): void {
        this.loading = true;
        this.timepunchService.history(this.data.customerId, this.data.employeeId, this.data.timepunchId).subscribe((timepunch) => {
            this.timepunch = timepunch;
            this.loading = false;
        });
        this.timepunchService.getAttachments(this.data.customerId, this.data.timepunchId, { per_page: 999 }).subscribe((res: ArrayPaginatedResponse<TimepunchAttachment>) => {
            this.attachments = res.data;
        });
        this.timepunchService.getRequestedAttachments(this.data.customerId, this.data.timepunchId).subscribe((res) => {
            if (res.requested_attachments) {
                res.requested_attachments.forEach((attachment) => {
                    if (attachment.status == 'WFR_CANCELLED') {
                        this.attachmentsRejected.push(attachment);
                    } else if (attachment.status == 'WFR_IN_PROGRESS') {
                        this.attachmentsInProgress.push(attachment);
                    }
                });
            }
        });
    }

    filteredChanges(changes: Timepunch['changes']) {
        return Object.entries(changes || {})
            .map(([ key, value ]) => ({
                key,
                changed: value,
            }))
            .filter((arr) => arr.changed)
            .map((arr) => arr.key);
    }

    getNext(index: number): Timepunch {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.timepunch?.changelog[index + 1] || this.timepunch!;
    }

    async downloadAttachment(attachment: TimepunchAttachment) {
        if (this.timepunch?.id) {
            void this.snackbar.t('DOWNLOAD_STARTED');
            this.timepunchService.downloadAttachment(this.data.customerId, this.timepunch?.id, attachment.id, attachment.name).subscribe(() => {
                void this.snackbar.t('DOWNLOAD_FINISHED');
            });
        } else {
            void this.snackbar.t('MISSING_TIMEPUNCH_ID', 'payroll');
        }
    }

    async downloadRequestedAttachment(attachment: RequestedTimepunchAttachment) {
        if (this.timepunch?.id) {
            void this.snackbar.t('DOWNLOAD_STARTED');
            this.documentService.downloadTimepunchSlipDocument(
                this.data.customerId,
                this.data.employeeId,
                attachment.workflow_id,
                attachment.run_id,
                attachment.last_document,
            ).subscribe(() => {
                void this.snackbar.t('DOWNLOAD_FINISHED');
            });
        } else {
            void this.snackbar.t('MISSING_TIMEPUNCH_ID', 'payroll');
        }
    }
}
