// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.staffing').component('weekSetupList', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="staffing:WEEK_SETUP_plural"></span>
        </md-card-header-text>

        <card-btn-float ng-if="$list.canCreate" on-click="$list.newSetup()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                get-data="$list.getData(params, pagination)"
                cells="$list.cells"
                columns="$list.columns"
                go-to="eaw/app/company/settings/week_setup/rows|week_setup_id=id">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$list',
    controller: [ 'weekSetupFactory', '$mdDialog', function(weekSetupFactory, $mdDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.canCreate = CurrentOld.can(weekSetupFactory.create.permission(CurrentOld.customer.id));

            ctrl.columns = [
                { title: t('NAME') },
                { title: t('CREATED') },
                {},
            ];

            ctrl.cells = [
                { template: 'item.name' },
                { template: 'item.created_at | moment:"LL"' },
                {
                    buttons: [
                        {
                            type: 'warn',
                            icon: 'delete',
                            invisible(item) {
                                return !CurrentOld.can(weekSetupFactory.delete.permission(CurrentOld.customer.id, item.id));
                            },
                            click(item) {
                                return $mdDialog.show(
                                    $mdDialog.confirm()
                                        .theme('delete')
                                        .textContent(t('DELETE_QUESTION'))
                                        .ok(t('OK'))
                                        .cancel(t('CANCEL')),
                                ).then(() => weekSetupFactory.delete.query(CurrentOld.customer.id, item.id).$promise);
                            },
                        },
                    ],
                },
            ];
        };

        ctrl.getData = (params, pagination) => weekSetupFactory.getAll.query(CurrentOld.customer.id, pagination);

        ctrl.newSetup = () => $mdDialog.show(
            $mdDialog.prompt()
                .title(t('staffing:NEW_WEEK_SETUP'))
                .ok(t('OK'))
                .cancel(t('CANCEL'))
                .textContent(t('NAME')),
        ).then((name) => {
            weekSetupFactory.create.query(CurrentOld.customer.id, name).$promise.then(ctrl.tableRowClick);
        });
    } ],
});
