import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { MatButton } from '@angular/material/button';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { AsyncPipe, NgIf } from '@angular/common';
import { ActionButtonComponent } from '../../components/action-button/action-button.component';
import { MatCalendar, MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../directives/date-picker-options.directive';
import { TimeInputComponent, TimeObject } from '../../components/date-time/time-input/time-input.component';
import { DateTimeInputComponent } from '../../components/date-time/date-time-input/date-time-input.component';
import { DateTimeRangeInputComponent } from '../../components/date-time/date-time-range-input/date-time-range-input.component';

interface IntervalData extends DialogData {
    from?: DateTime;
    to?: DateTime | null;
    includeTime?: boolean;
    dateFilter?: (date: DateTime) => boolean;
    minDate?: DateTime;
    maxDate?: DateTime;
    dialogTitle: Observable<string>;
}

interface DateIntervalForm {
    from: FormControl<DateTime | null>;
    to: FormControl<DateTime | null>;
}

@Component({
    selector: 'eaw-interval-dialog',
    standalone: true,
    imports: [
        MatDialogActions,
        MatDialogContent,
        DialogHeaderComponent,
        MatButton,
        ReactiveFormsModule,
        TranslatePipe,
        AsyncPipe,
        MatDialogClose,
        ActionButtonComponent,
        MatCalendar,
        NgIf,
        MatFormField,
        MatInput,
        MatDatepicker,
        DatePickerOptionsDirective,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatLabel,
        MatSuffix,
        TimeInputComponent,
        DateTimeInputComponent,
        DateTimeRangeInputComponent,
    ],
    templateUrl: './interval-dialog.component.html',
    styleUrl: './interval-dialog.component.scss',
})
export class IntervalDialogComponent extends DialogComponent<IntervalData, undefined | {
    from: DateTime,
    to: DateTime | null
}> {
    protected readonly includeTime: boolean;
    protected readonly dateFilter: (date: DateTime | null) => boolean;
    protected readonly form: FormGroup<DateIntervalForm>;
    protected readonly minDate?: DateTime;
    protected readonly maxDate?: DateTime;
    protected readonly dialogTitle: Observable<string>;

    constructor() {
        const data = inject(MAT_DIALOG_DATA);
        data.size = DialogSize.Medium;
        data.disableClose = false;

        super(inject(MatDialogRef), data);

        this.includeTime = data.includeTime ?? false;
        this.minDate = data.minDate;
        this.maxDate = data.maxDate;
        this.dialogTitle = data.dialogTitle;

        this.form = new FormGroup<DateIntervalForm>({
            from: new FormControl<DateTime | null>(data.from ?? DateTime.now(), Validators.required),
            to: new FormControl<DateTime | null>(data.to ?? null),
        });

        this.dateFilter = data.dateFilter || (() => true);
    }

    close() {
        const from = this.form.controls.from.value;
        const to = this.form.controls.to.value;

        if (!from) {
            return;
        }

        this.dialogRef.close({ from, to });
    }

    timeChange(dateCtrlName: 'from' | 'to', $event: TimeObject | null) {
        const formControl = this.form.controls[dateCtrlName];
        if (!formControl.value) {
            return;
        }

        formControl.setValue(formControl.value.set({
            hour: $event?.hour ?? 0,
            minute: $event?.minute ?? 0,
            second: 0,
        }));
    }
}
