import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtendedWidgetInfo } from '../../../classes/extended-widget-info';
import { expandAllPages } from '../../../../shared/utils/rxjs/expand-all-pages';
import { Alert } from '../../../../shared/models/alert';
import { AlertsService, GetAlertsOptions } from '../../../../shared/http/alerts.service';
import { CurrentService } from '../../../../shared/services/current.service';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { StateService } from '@uirouter/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Widget } from '../../../classes/widget';
import { InfoLoadingComponent } from '../../../../shared/components/info-loading/info-loading.component';

@Component({
    selector: 'eaw-contract-requalification-extended-info',
    standalone: true,
    imports: [ CommonModule, TranslatePipe, MatButtonModule, MatIconModule, MatTooltipModule, InfoLoadingComponent ],
    templateUrl: './contract-requalification-extended-info.component.html',
    styleUrl: './contract-requalification-extended-info.component.scss',
})
export class ContractRequalificationExtendedInfoComponent extends ExtendedWidgetInfo implements OnInit {
    alertService = inject(AlertsService);
    current = inject(CurrentService);
    stateService = inject(StateService);

    @Input() widget?: Widget;
    // For ajs container
    @Output() stateshift = new EventEmitter();

    alerts: Alert[] = [];

    ngOnInit() {
        this.setLoading(true);
        expandAllPages<Alert, GetAlertsOptions>((pagination) => this.alertService.getAlerts(this.current.getCustomer().id, pagination), { category: 'overtime' }).subscribe((alerts) => {
            this.alerts = alerts;
            this.setLoading(false);
        });
    }

    goToContract(emp: number) {
        this.stateService.go('eaw/app/company/employees/view/pay_contract', { emp }).then(() => this.stateshift.emit());
    }
}
