import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { BusinessDate } from '../../shared/utils/business-date';
import { ShiftEmployee, ShiftEmployeeResponse } from '../models/shift-employee';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';

@Injectable({
    providedIn: 'root',
})
export class ShiftEmployeeService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getEmployeesByBusinessDate(customerId: number, scheduleId: number, businessDate: BusinessDate, filter?: string) {
        return this.http.get<ArrayPaginatedResponse<ShiftEmployeeResponse>>(`/customers/${customerId}/schedules/${scheduleId}/employees/${businessDate.toString()}`, {
            params: formatHttpParams({ filter }),
        }).pipe(
            classifyArrayPaginatedResponse(ShiftEmployee),
        );
    }

    getEmployee(customerId: number, scheduleId: number, shiftId: number) {
        return this.http.get<ShiftEmployeeResponse>(`/customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/employee`).pipe(
            classifyItem(ShiftEmployee),
        );
    }

    getEmployees(customerId: number, scheduleId: number, shiftId: number, filter?: string) {
        return this.http.get<ArrayPaginatedResponse<ShiftEmployeeResponse>>(`/customers/${customerId}/schedules/${scheduleId}/shifts/${shiftId}/employees`, {
            params: formatHttpParams({ filter }),
        }).pipe(
            classifyArrayPaginatedResponse(ShiftEmployee),
        );
    }
}
