<eaw-dialog-header>
    @if (data.absence) {
        <span title>{{ 'UPDATE_ABSENCE' | translate:'absences' | async }}</span>
    } @else {
        <span title>{{ 'ADD_ABSENCE' | translate:'absences' | async }}</span>
    }
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="submit()" class="tw-grid tw-grid-cols-12">
        <eaw-autocomplete
                class="tw-col-span-full"
                formControlName="absenceType"
                [options]="absenceTypeAutocompleteService.options"
                [getter]="absenceTypeAutocompleteService.getter({id: data.customerId || 0})"
                [setter]="absenceTypeAutocompleteService.setter({id: data.customerId || 0})">
        </eaw-autocomplete>

        @if (getAbsenceType()?.span === 'day') {
            <mat-form-field class="tw-col-span-full" eawDatePickerOptions>
                <mat-label>{{ 'ENTER_DATE_RANGE' | translate | async }}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
                    <input formControlName="from" matStartDate [placeholder]="'START_DATE' | translate | async">
                    <input formControlName="to" matEndDate [placeholder]="'END_DATE' | translate | async">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        }

        @if (!form.controls.absenceType.value || getAbsenceType()?.span === 'hour') {
            <eaw-date-time-range-input class="tw-col-span-full tw-grid tw-grid-cols-2 tw-gap-8">
                <mat-form-field eawDateTimeFrom>
                    <mat-label>{{ 'FROM' | translate | async }}</mat-label>
                    <eaw-date-time-input [formControl]="form.controls.dateRange.controls.from"
                                         required></eaw-date-time-input>
                </mat-form-field>

                <mat-form-field eawDateTimeTo>
                    <mat-label>{{ 'TO' | translate | async }}</mat-label>
                    <eaw-date-time-input [formControl]="form.controls.dateRange.controls.to"
                                         required></eaw-date-time-input>
                </mat-form-field>
            </eaw-date-time-range-input>
        }
        @if (data.showDuration) {
            <eaw-duration-input class="tw-col-span-full"
                                [units]="getAbsenceType()?.span === 'day' ? ['days'] : ['hours', 'minutes', 'seconds']"
                                [from]="durationRange.from"
                                [to]="durationRange.to">
            </eaw-duration-input>
        }
        @if (hasScheduling() && form.controls.releaseShifts) {
            <eaw-absence-actions class="tw-col-span-full" [formControl]="form.controls.releaseShifts"></eaw-absence-actions>
        }

        @for (field of customFieldPivots(); track field.id) {
            <eaw-custom-field-input class="tw-col-span-full"
                                    [customField]="field"
                                    [formGroup]="form.controls.customFields"></eaw-custom-field-input>
        }

        <mat-form-field class="tw-col-span-full">
            <mat-label>{{ 'COMMENT' | translate | async }}</mat-label>
            <textarea matInput
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="4"
                      cdkTextareaAutosize
                      formControlName="comment"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button type="submit" [loading]="false" (click)="submit()" [disabled]="form.invalid">
        @if (data.absence) {
            <span>{{ 'UPDATE_ABSENCE' | translate:'absences' | async }}</span>
        } @else {
            <span>{{ 'ADD_ABSENCE' | translate: 'absences' | async }}</span>
        }
    </eaw-action-button>
</mat-dialog-actions>
