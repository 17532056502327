import moment, { Moment } from 'moment-timezone';
import MlTimeSeriesEntry from './ml-timeseries-entry';

export default class MlTimeSeries {
    aggregate_method: number;
    aggregate_method_text: string;
    bucket_uuid: string;
    interval: number;
    name: string;
    start: string;
    time_zone: string;
    tags: {};
    entries: any[] = [];
    uuid: string;
    type: number;
    type_text: string;
    value_type: number;
    value_type_text: string;
    variable_uuid: string;

    constructor(mlTimeSeries: any) {
        this.aggregate_method = mlTimeSeries.aggregate_method;
        this.aggregate_method_text = mlTimeSeries.aggregate_method_text;
        this.bucket_uuid = mlTimeSeries.bucket_uuid;
        this.interval = mlTimeSeries.interval;
        this.name = mlTimeSeries.name;
        this.start = mlTimeSeries.start;
        this.time_zone = mlTimeSeries.time_zone;
        this.tags = mlTimeSeries.tags;
        this.uuid = mlTimeSeries.uuid;
        this.type = mlTimeSeries.type;
        this.type_text = mlTimeSeries.type_text;
        this.value_type = mlTimeSeries.value_type;
        this.value_type_text = mlTimeSeries.value_type_text;
        this.variable_uuid = mlTimeSeries.variable_uuid;
    }

    get id() {
        return this.uuid;
    }

    get projections() {
        const integerType = this.value_type_text === 'VALUE_TYPE_INT';
        const timeFormatter = this.interval >= moment.duration(1, 'd').asSeconds()
            ? (time: Moment) => time.startOf('d').format('YYYY-MM-DD HH:mm:ss')
            : (time: Moment) => time.format('YYYY-MM-DD HH:mm:ss');

        return this.entries.map((e) => {
            const value = e.value || 0;

            return {
                value: integerType ? Math.round(value) : value,
                time: timeFormatter(e.time),
            };
        });
    }

    addEntry(entry: MlTimeSeriesEntry) {
        this.entries.push(entry);
    }

    clearEntries() {
        this.entries = [];
    }
}
