// @ts-nocheck
import { uniqueId } from '../../modules/misc/services/easy-funcs.service';
import { module } from 'angular';

module('eaw.components').component('eawObjectDisplayer', {
    template: `<div ng-repeat="item in $ctrl.formattedObject">
    <span class="displayer-key" ng-bind="item.key"></span>
    <span class="displayer-separator">: </span>
    <span class="displayer-value" ng-class="item.classes" ng-bind="item.value"></span>
</div>
`,
    bindings: {
        object: '<',
    },
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.formatObject();
        };

        ctrl.formatObject = () => {
            ctrl.formattedObject = [];

            Object.entries(ctrl.object).forEach((entry) => {
                const value = entry[1];
                const data = {
                    id: uniqueId('od'),
                    key: entry[0],
                    value: entry[1],
                    classes: [],
                };

                if (typeof parseFloat(String(value)) === 'number' && !Number.isNaN(parseFloat(String(value)))) {
                    data.classes.push('number');
                } else if (typeof value === 'boolean') {
                    data.classes.push('boolean');
                } else if (typeof value === 'string') {
                    data.classes.push('string');
                    data.value = `"${data.value}"`;
                }

                ctrl.formattedObject.push(data);
            });
        };
    },
});
