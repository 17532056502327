// @ts-nocheck
import { module } from 'angular';

const template1 = `<ng-form name="signature" layout="column">
    <md-checkbox required ng-model="$ctrl.checked">
        <span ng-i18next="digisign:I_CONFIRM_I_AM_SIGNING"></span>
    </md-checkbox>

    <div layout="row">
        <md-button class="md-raised md-accent" ng-click="$ctrl.sign()" ng-disabled="signature.$invalid">
            <span ng-i18next="SIGN"></span>
        </md-button>
    </div>
</ng-form>
`;

module('eaw.digisign').component('eawCheckboxSign', {
    template: template1,
    bindings: {
        onSign: '&', // no params, not allowed to submit 'false' signature
    },
    controller: function() {
        const ctrl = this;

        ctrl.sign = () => {
            if (ctrl.checked) {
                ctrl.onSign();
            }
        };
    },
});
