// @ts-nocheck
import { module } from 'angular';
module('eaw.staffing').factory('weekSetupRowFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    fac.res = EawResource.create('/customers/:customer/week_setups/:setup/rows/:row');
    fac.getAll = {
        permission(customerId, weekSetupId) {
            return `customers.${customerId}.week_setups.${weekSetupId}.rows.*.get`;
        },
        query(customerId, weekSetupId, args) {
            return fac.res.get({
                ...Pagination.getParams(args),
                customer: customerId,
                setup: weekSetupId,
            });
        },
    };
    fac.get = {
        permission(customerId, weekSetupId, weekRowId) {
            return `customers.${customerId}.week_setups.${weekSetupId}.get`;
        },
        query(customerId, weekSetupId, rowId, withRelations) {
            return fac.res.get({
                customer: customerId,
                setup: weekSetupId,
                row: rowId,
                'with[]': withRelations,
            });
        },
    };
    fac.create = {
        permission(customerId, weekSetupId) {
            return `customers.${customerId}.week_setups.${weekSetupId}.rows.create`;
        },
        query(customerId, weekSetupId, args) {
            return fac.res.save({
                customer: customerId,
                setup: weekSetupId,
            }, args);
        },
    };
    fac.update = {
        permission(customerId, weekSetupId, rowId) {
            return `customers.${customerId}.week_setups.${weekSetupId}.rows.${rowId}.update`;
        },
        query(customerId, weekSetupId, rowId, args) {
            return fac.res.update({
                customer: customerId,
                setup: weekSetupId,
                row: rowId,
            }, args);
        },
    };
    fac.delete = {
        permission(customerId, weekSetupId, rowId) {
            return `customers.${customerId}.week_setups.${weekSetupId}.rows.${rowId}.delete`;
        },
        query(customerId, weekSetupId, rowId) {
            return fac.res.delete({
                customer: customerId,
                setup: weekSetupId,
                row: rowId,
            });
        },
    };
    return fac;
} ]);
