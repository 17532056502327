import { module } from 'angular';
import { Namespace } from '../../../shared/enums/namespace';
import { loadNamespaces } from 'i18next';
import { SettingGroupListComponent } from '../../pages/setting-group-list/setting-group-list.component';
import { ObserversComponent } from '../../pages/observers/observers.component';
import { createState } from '../../../shared/utils/create-state';
import { SettingGroupCustomFieldsComponent } from '../../pages/setting-group-custom-fields/setting-group-custom-fields.component';
import { SettingGroupWidgetsComponent } from '../../pages/setting-group-widgets/setting-group-widgets.component';
import { SettingGroupSettingsComponent } from '../../pages/setting-group-settings/setting-group-settings.component';
import { DataTableQueryParams } from '../../../data-table/data-table.component';
import { CurrentService } from '../../../shared/services/current.service';

const customersTabTemplate = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="admin:CUSTOMER_plural"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="sgcCtrl.table.columns"
                cells="sgcCtrl.table.cells"
                factory="CustomerSettingsGroupService"
                factory-function="getMembers"
                go-to="eaw/app/admin/customers/view/info"
                factory-function-arguments="{groupId: sgcCtrl.settingGroup.id}">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;
const settingGroupListTemplate = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="TARIFF_plural"></span>
        </md-card-header-text>

        <card-btn-float on-click="taCtrl.new()"></card-btn-float>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <eaw-dynamic-ng-table
                columns="taCtrl.table.columns"
                cells="taCtrl.table.cells"
                factory="TariffFactory"
                factory-function="getAll"
                reload-table="taCtrl.reloadTable"
                go-to="eaw/app/admin/setting_groups/tariff/rates|id=setting_group_id|tariff_id=id"
                factory-function-arguments="taCtrl.tableArgs">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;
const tariffRateTemplate = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title">
                <span ng-i18next="TARIFF_RATE_plural"></span> - <span ng-bind="trctrl.tariff.name"></span>
            </span>
        </md-card-header-text>
        <card-btn-float on-click="trctrl.rateDialog()"></card-btn-float>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <eaw-dynamic-ng-table
                columns="trctrl.table.columns"
                cells="trctrl.table.cells"
                factory="TariffRate"
                factory-function="getAll"
                reload-table="trctrl.reloadTable"
                scope-vars="trctrl.table.vars"
                factory-function-arguments="trctrl.tableArgs">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;
const contractTypeTemplate = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="admin:CONTRACT_TYPE_plural"></span>
        </md-card-header-text>

        <card-btn-float on-click="ctCtrl.addEditContractType()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="ctCtrl.table.columns"
                cells="ctCtrl.table.cells"
                factory="ContractTypeFactory"
                factory-function="getAll"
                reload-table="ctCtrl.reloadTable"
                factory-function-arguments="{
                                setting_group: ctCtrl.settingGroup
                            }">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;

module('eaw.admin').config([ '$stateProvider', function($stateProvider: any) {
    createState($stateProvider, {
        name: `eaw/app/admin/setting_groups`,
        parent: 'eaw/app/admin',
        url: '/setting_groups',
        views: {
            'content@': {
                component: SettingGroupListComponent,
            },
        },
        data: {
            queryParams: [ ...DataTableQueryParams, 'filter' ],
            breadcrumb: { key: 'SETTING_GROUP_plural', ns: 'admin' },
            permissionCheck: (permissionCheckService) => {
                return permissionCheckService.permissionOrChildren(`setting_groups.*.get`, undefined, `setting_groups`, `get`, true)
            },
        },
    });

    $stateProvider.state(`eaw/app/admin/setting_groups/view`, {
        parent: `eaw/app/admin/setting_groups`,
        url: '/:id',
        views: {
            'content@': {
                component: 'eawNavBar',
            },
        },
        data: {
            i18nextNs: [ Namespace.Admin, Namespace.Company, Namespace.SwissRiskAndCare, Namespace.ChainOps, Namespace.Digisign ],
            breadcrumb: { key: 'SETTING_GROUP', ns: 'admin' },
        },
        resolve: {
            navbarRoute: () => `eaw/app/admin/setting_groups/view`,
            settingGroup: [ 'CustomerSettingsGroupService', '$transition$', function(CustomerSettingsGroupService: any, $transition$: any) {
                return CustomerSettingsGroupService.getById($transition$.params().id).$promise;
            } ],
            viewName: function() {
                return 'tabPane';
            },
            tabs: function() {
                return [ {
                    label: 'admin:CUSTOMER_plural',
                    src: `eaw/app/admin/setting_groups/view/customers`,
                }, {
                    label: 'admin:SETTING_plural',
                    src: `eaw/app/admin/setting_groups/view/settings`,
                }, {
                    label: 'CUSTOM_FIELD_plural',
                    src: `eaw/app/admin/setting_groups/view/custom_fields`,
                }, {
                    label: 'TARIFF_plural',
                    src: `eaw/app/admin/setting_groups/view/tariffs`,
                }, {
                    label: 'admin:ABSENCE_TYPE_plural',
                    src: `eaw/app/admin/setting_groups/view/absence_types`,
                }, {
                    label: 'admin:CONTRACT_TYPE_plural',
                    src: `eaw/app/admin/setting_groups/view/contract_types`,
                }, {
                    label: 'admin:OBSERVER_plural',
                    src: `eaw/app/admin/setting_groups/view/observers`,
                }, {
                    label: 'widgets:WIDGET_plural',
                    src: `eaw/app/admin/setting_groups/view/widgets`,
                } ];
            },
        },
    });

    $stateProvider.state(`eaw/app/admin/setting_groups/view/customers`, {
        parent: `eaw/app/admin/setting_groups/view`,
        url: '/customers',
        views: {
            tabPane: {
                controller: 'settingGroupCustomersCtrl',
                controllerAs: 'sgcCtrl',
                template: customersTabTemplate,
            },
        },
        data: {
            breadcrumb: { key: 'CUSTOMER_plural', ns: 'admin' },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/admin/setting_groups/view/settings',
        parent: `eaw/app/admin/setting_groups/view`,
        url: '/settings',
        views: {
            tabPane: {
                component: SettingGroupSettingsComponent,
            },
        },
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'SETTING_plural', ns: 'admin' },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/admin/setting_groups/view/custom_fields',
        parent: `eaw/app/admin/setting_groups/view`,
        url: '/custom_fields',
        views: {
            tabPane: {
                component: SettingGroupCustomFieldsComponent,
            },
        },
        data: {
            breadcrumb: { key: 'CUSTOM_FIELD_plural' },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/admin/setting_groups/view/widgets',
        parent: `eaw/app/admin/setting_groups/view`,
        url: '/widgets',
        views: {
            tabPane: {
                component: SettingGroupWidgetsComponent,
            },
        },
        data: {
            breadcrumb: { key: 'WIDGET_plural', ns: 'widgets' },
        },
    });

    $stateProvider.state('eaw/app/admin/setting_groups/view/tariffs', {
        parent: `eaw/app/admin/setting_groups/view`,
        url: '/tariffs',
        views: {
            tabPane: {
                controller: 'tariffCtrl',
                controllerAs: 'taCtrl',
                template: settingGroupListTemplate,
            },
        },
        data: {
            breadcrumb: { key: 'TARIFF_plural', ns: 'admin' },
        },
    });

    $stateProvider.state(`eaw/app/admin/setting_groups/tariff`, {
        parent: 'eaw/app/admin/setting_groups/view/tariffs',
        url: '/:tariff_id',
        abstract: true,
        views: {
            'tabPane@eaw/app/admin/setting_groups/view': {
                template: `<ui-view name="tariff"></ui-view><ui-view name="rates"></ui-view>`,
            },
        },
    });

    $stateProvider.state(`eaw/app/admin/setting_groups/tariff/rates`, {
        parent: `eaw/app/admin/setting_groups/tariff`,
        url: '/rates',
        views: {
            tariff: {
                component: 'tariffView',
            },
            rates: {
                template: tariffRateTemplate,
                controller: 'tariffRatesCtrl',
                controllerAs: 'trctrl',
            },
        },
        resolve: {
            tariff: [ '$transition$', 'TariffFactory', function($transition$: any, TariffFactory: any) {
                const params = $transition$.params();
                return TariffFactory.get({
                    tariff_id: params.tariff_id,
                    setting_group_id: params.id,
                    with: [ 'rules' ],
                }).$promise;
            } ],
        },
        data: {
            breadcrumb: { key: 'TARIFF', ns: 'admin' },
        },
    });

    $stateProvider.state('eaw/app/admin/setting_groups/view/absence_types', {
        parent: `eaw/app/admin/setting_groups/view`,
        url: '/absence_types',
        views: {
            tabPane: {
                component: 'settingGroupAbsenceType',
            },
        },
        data: {
            i18nextNs: [ Namespace.Absences, Namespace.Vacation, Namespace.AbsenceTypes ],
            breadcrumb: { key: 'ABSENCE_TYPE_plural', ns: 'admin' },
        },
        resolve: {
            lang: function() {
                return loadNamespaces(Namespace.Absences);
            },
        },
    });

    $stateProvider.state('eaw/app/admin/setting_groups/view/contract_types', {
        parent: `eaw/app/admin/setting_groups/view`,
        url: '/contract_types',
        views: {
            tabPane: {
                controller: 'settingGroupContractTypeController',
                controllerAs: 'ctCtrl',
                template: contractTypeTemplate,
            },
        },
        data: {
            breadcrumb: { key: 'CONTRACT_TYPE_plural', ns: 'admin' },
        },
    });

    $stateProvider.state('eaw/app/admin/setting_groups/view/observers', {
        parent: `eaw/app/admin/setting_groups/view`,
        url: '/observers',
        views: {
            tabPane: {
                component: ObserversComponent,
            },
        },
        data: {
            breadcrumb: { key: 'OBSERVER_plural', ns: 'admin' },
        },
    });
} ]);
