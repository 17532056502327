// @ts-nocheck
import { module } from 'angular';
import { pick } from 'lodash-es';
import { Coupon } from './types/coupon';
module('eaw.coupons').factory('CouponFactory', [ 'EawResource', function(EawResource) {
    const url = '/customers/:customer/employees/:employee/coupons';
    /**
     * @param {Coupon} coupon
     */
    this.useCoupon = function(coupon) {
        return EawResource.create(url + '/:coupon/use', {}, {
            save: {
                method: 'POST',
                headers: {
                    'X-Ignore-Error': true, // Handle error elsewhere
                },
            },
        }).save({
            customer: coupon.employee.customer_id,
            employee: coupon.employee.id,
            coupon: coupon.id,
        }, {});
    };
    this.getEmployeeCoupons = function(employee, options = {}) {
        return EawResource.create(url).query({
            customer: employee.customer_id,
            employee: employee.id,
            ...pick(options, [
                'get_expired',
                'get_used',
                'get_unavailable',
            ]),
        }).$promise.then((coupons) => coupons.map((coupon) => new Coupon(employee, coupon)));
    };
    this.addCoupon = function(employee, coupon) {
        const data = Object.assign(pick(coupon, [
            'value',
            'unit',
            'available_at',
            'expires_at',
        ]), { coupon_type_id: coupon.type.id });
        return EawResource.create(url).save({
            customer: employee.customer_id,
            employee: employee.id,
        }, data).$promise.then((data) => new Coupon(employee, data));
    };
    return this;
} ]);
