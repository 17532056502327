// @ts-nocheck
import { module } from 'angular';
module('eaw.misc').factory('TariffFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const factory = this;
    const url = '/setting_groups/:setting_group_id/tariffs/:tariff_id';
    const customerUrl = '/customers/:customer_id/tariffs/:tariff_id';
    factory.get = (args) => EawResource.create(url).get({
        ...Pagination.getParams(args, [ 'setting_group_id', 'tariff_id' ]),
    });
    factory.getForCustomer = (customerId, tariffId) => EawResource.create(customerUrl).get({
        customer_id: customerId,
        tariff_id: tariffId,
    });
    factory.getAllForCustomer = (args) => EawResource.create(customerUrl).get({
        'types[]': args.types,
        ...Pagination.getParams(args, [ 'customer_id', 'active_rate' ]),
        _businessDates: [ 'active_rate' ],
    });
    factory.getAll = (args) => EawResource.create(url).get(Object.assign({ setting_group_id: args.setting_group?.id }, Pagination.getParams(args, [ 'setting_group_id' ])));
    factory.create = (args) => EawResource.create(url).save({
        setting_group_id: args.setting_group.id,
    }, {
        name: args.name,
        type: args.type,
    });
    factory.update = (args) => EawResource.create(url).update({
        tariff_id: args.tariff.id,
        setting_group_id: args.setting_group.id,
    }, {
        name: args.tariff.name,
        type: args.tariff.type,
    });
    factory.delete = (tariff) => EawResource.create(url).delete({
        setting_group_id: tariff.setting_group_id,
        tariff_id: tariff.id,
    });
    return factory;
} ]);
