import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from '../interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Property, PropertyResponse } from '../models/property';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { formatHttpParams } from '../utils/format-http-params';

@Injectable({
    providedIn: 'root',
})
export class SettingGroupPropertyService {

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
    ) {
    }

    getAll(settingGroupId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<PropertyResponse>>(`/setting_groups/${settingGroupId}/properties`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(Property));
    }

    create(settingGroupId: number, key: string, value: string) {
        return this.http.post<PropertyResponse>(`/setting_groups/${settingGroupId}/properties`, {
            key,
            value,
        }).pipe(classifyItem(Property));
    }

    update(settingGroupId: number, key: string, value: string) {
        return this.http.put<PropertyResponse>(`/setting_groups/${settingGroupId}/properties/${key}`, {
            value,
        }).pipe(classifyItem(Property));
    }

    delete(settingGroupId: number, key: string) {
        return this.http.delete(`/setting_groups/${settingGroupId}/properties/${key}`);
    }

    flush(settingGroupId: number) {
        return this.http.post<undefined>(`/setting_groups/${settingGroupId}/flush_settings`, {});
    }
}
