import { ShiftResponse } from './shift';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';

export type ScheduleSummarySums = {
    work_time: number;
    efficiency: number;
    budget?: number;
    labor_cost: number;
    need: number;
    need_diff: number;
    labor_ratio: number;
    units_sold_per_employee?: number;
    transactions_per_employee?: number;
}

export type ScheduleSummaryDay = ScheduleSummarySums & {
    shifts: ShiftResponse[];
    employees: number[];
    work_hours: number;
    projected_units: number;
    projected_transactions: number;
};

type SummaryEmployee = { name: string, id: number, number: string, user_id: number, customer_id: number };

export interface ScheduleSummaryResponse extends ApiResponse {
    last_summary_dispatch: string,
    created_at?: string
    days?: Record<string, ScheduleSummaryDay>
    sums?: ScheduleSummarySums
    employees?: SummaryEmployee[]
}

export class ScheduleSummary {
    lastDispatch?: DateTime;
    createdAt?: DateTime;
    days?: Map<string, ScheduleSummaryDay>;
    sums?: ScheduleSummarySums;
    employees?: SummaryEmployee[];

    constructor(summary: ScheduleSummaryResponse) {
        if (summary.last_summary_dispatch) {
            this.lastDispatch = stringToDateTime(summary.last_summary_dispatch);
        }

        if (summary.created_at) {
            this.createdAt = stringToDateTime(summary.created_at);
        }

        if (summary.days) {
            this.days = new Map(Object.entries(summary.days));
        }

        this.sums = summary.sums;
        this.employees = summary.employees;
    }
}
