// @ts-nocheck
import { module } from 'angular';
module('eaw.staffing').factory('weekSetupFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/week_setups/:setup');
    fac.getAll = {
        permission(customerId) {
            return `customers.${customerId}.week_setups.*.get`;
        },
        query(customerId, args) {
            return res.get({
                ...Pagination.getParams(args),
                customer: customerId,
            });
        },
    };
    fac.get = {
        permission(customerId, weekSetupId) {
            return `customers.${customerId}.week_setups.${weekSetupId}.get`;
        },
        query(customerId, weekSetupId, withRelations) {
            return res.get({
                customer: customerId,
                setup: weekSetupId,
                'with[]': withRelations,
            });
        },
    };
    fac.create = {
        permission(customerId) {
            return `customers.${customerId}.week_setups.create`;
        },
        query(customerId, name) {
            return res.save({
                customer: customerId,
            }, { name });
        },
    };
    fac.update = {
        permission(customerId, weekSetupId, name) {
            return `customers.${customerId}.week_setups.${weekSetupId}.update`;
        },
        query(customerId, name) {
            return res.update({
                customer: customerId,
            }, { name });
        },
    };
    fac.delete = {
        permission(customerId, weekSetupId) {
            return `customers.${customerId}.week_setups.${weekSetupId}.delete`;
        },
        query(customerId, weekSetupId) {
            return res.delete({
                customer: customerId,
                setup: weekSetupId,
            });
        },
    };
    return fac;
} ]);
