import { Component, inject, Input, numberAttribute, OnInit, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { FlexitimeService } from '../../http/flexitime.service';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { forkJoin, map, of } from 'rxjs';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { DateTime } from 'luxon';
import { Flexitime } from '../../models/flexitime';
import { TranslateService } from '../../../shared/services/translate.service';
import { EmployeeService } from '../../../shared/http/employee.service';
import { AddFlexitimeDialogComponent, AddFlexitimeDialogData, AddFlexitimeDialogReturn } from '../../dialogs/add-flexitime-dialog/add-flexitime-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatCardModule } from '@angular/material/card';
import { AsyncPipe, NgIf } from '@angular/common';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-flexitime',
    templateUrl: './flexitime-employee.component.html',
    styleUrl: './flexitime-employee.component.scss',
    providers: [ DurationPipe ],
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        DurationPipe,
    ],
})
export class FlexitimeEmployeeComponent implements OnInit {
    private readonly flexitimeService = inject(FlexitimeService);
    private readonly employeeService = inject(EmployeeService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly durationPipe = inject(DurationPipe);
    private readonly translate = inject(TranslateService);
    private readonly matDialog = inject(MatDialog);

    @ViewChild(DataTableComponent) dataTable?: DataTableComponent<Flexitime>;

    @Input({ required: true }) customerId!: number;
    @Input({ required: true, transform: numberAttribute }) employeeId!: string | number;

    fabButton?: HeaderFabButton;
    employeeName = '';
    showInactive = false;
    updateList = false;
    request: DataTableRequest = of(mockArrayPaginatedResponse());
    balance = 0;
    columns: DataTableColumnType<Flexitime>[];

    constructor() {
        this.columns = [
            new DataTableDateTimeColumn({
                value: (cell) => cell.item.businessDate.dateTime,
                header: new DataTableHeader({
                    i18n: 'BUSINESS_DATE',
                    sortBy: 'business_date',
                }),
            }),
            new DataTableTextColumn({
                // Adding one second to correct for old values where we stored the duration less accurately
                value: (cell) => cell.item.delta ? this.durationPipe.transform(cell.item.delta * 3600 + 1, [ 'hours', 'minutes' ]) : '',
                header: new DataTableHeader({
                    i18n: 'CHANGE',
                    sortBy: 'delta',
                }),
            }),
            new DataTableTextColumn({
                value: (cell) => this.translate.t(cell.item.type.toUpperCase(), 'payroll') || '',
                header: new DataTableHeader({
                    i18n: 'TYPE',
                    sortBy: 'type',
                }),
            }),
            new DataTableTextColumn({
                value: 'performedByName',
                header: new DataTableHeader({
                    i18n: 'PERFORMED_BY',
                    ns: 'payroll',
                }),
            }),
            new DataTableTextColumn({
                value: 'comment',
                header: new DataTableHeader({
                    i18n: 'COMMENT',
                    sortBy: 'comment',
                }),
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        icon: 'delete',
                        type: 'warn',
                        click: this.delete.bind(this),
                        show: this.canDelete.bind(this),
                        tooltip: { key: 'DELETE' },
                    },
                ],
            }),
        ];
    }

    getEmployeeId() {
        return parseInt(String(this.employeeId));
    }

    ngOnInit() {
        this.fabButton = {
            hasPermission: this.canCreate.bind(this),
            click: this.addFlexitime.bind(this),
        };

        this.employeeService.get(this.customerId, this.getEmployeeId()).subscribe((employee) => {
            this.employeeName = employee.name || '';
        });

        this.getBalance();
    }

    addFlexitime() {
        this.matDialog.open<AddFlexitimeDialogComponent, AddFlexitimeDialogData, AddFlexitimeDialogReturn>(AddFlexitimeDialogComponent, {
            data: {
                employeeId: this.getEmployeeId(),
                customerId: this.customerId,
            },
        }).afterClosed().subscribe((value) => {
            if (value) {
                this.dataTable?.refresh();
                this.getBalance();
            }
        });
    }

    updateTable(pagination: DataTablePagination) {
        this.request = this.flexitimeService.getAllForEmployee(this.customerId, this.getEmployeeId(), pagination);
    }

    delete(cell: DataTableButtonCell<Flexitime>) {
        if (cell.item) {
            this.flexitimeService.delete(this.customerId, cell.item.employeeId, cell.item.id).subscribe(async () => {
                this.dataTable?.refresh();
                this.getBalance();
            });
        }
    }

    canCreate() {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.flexitime.create`);
    }

    canDelete(cell: Flexitime) {
        return forkJoin([
            of(cell?.type === 'manual'),
            this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.flexitime.${cell.id}.delete`),
        ]).pipe(map(([ isManual, hasPermission ]) => isManual && hasPermission));
    }

    getBalance() {
        this.flexitimeService.getEmployeeBalance(this.customerId, this.getEmployeeId(), DateTime.now().startOf('day')).subscribe((balance) => {
            this.balance = balance;
        });
    }
}
