// @ts-nocheck
import { module } from 'angular';
import { Comment } from '../../../../../../shared/models/comment';

module('eaw.payroll.paid-time').component('ptRemovePaidTime', {
    template: `<eaw-custom-fields ng-model="$ctrl.paidTime.customFields" disabled></eaw-custom-fields>

<md-subheader ng-if="$ctrl.paidTime.comments.length">
    <span ng-i18next="COMMENT_plural"></span>
</md-subheader>
<eaw-comment-list [angularjscomments]="$ctrl.paidTime.comments"></eaw-comment-list>
`,
    bindings: {
        segment: '<',
        parent: '<',
    },
    controller: [ 'PaidTimeFactory', '$scope', '$mdDialog', function(PaidTimeFactory, $scope,$mdDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.paidTime = ctrl.segment.paidTimeFlex || ctrl.segment.paidTime;
            ctrl.parent.businessDate = ctrl.paidTime.business_date;
            ctrl.paidTime.comments = ctrl.paidTime.comments.map((c) => new Comment(c));
            $scope.$on('HandleSomethingCtrl:handle', ctrl.removePaidTime);
        };

        ctrl.removePaidTime = async () => {
            ctrl.updating = true;
            try {
                await PaidTimeFactory.removeEmployeePaidTime(ctrl.paidTime.customer_id, ctrl.paidTime.employee_id, ctrl.paidTime.id).$promise;
                $mdDialog.hide();
            } catch (_) {
                ctrl.parent.handling = false;
            }
        };
    } ],
});
