// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.calendar2').controller('subscribeCalendarDialogCtrl', [ '$mdDialog', 'Calendar', function($mdDialog, Calendar) {
    const ctrl = this;

    ctrl.getCalendars = (filter) => Calendar.getAll({
        customerId: ctrl.customer,
        subscribed: false,
        filter,
    });

    ctrl.subscribe = async () => {
        ctrl.subscribing = true;
        let subscriber;
        try {
            subscriber = await ctrl.calendar.subscribe(CurrentOld.customer.id, CurrentOld.user.id);
        } catch (e) {
            ctrl.subscribing = false;
            return console.error(e);
        }

        ctrl.calendar.color = subscriber.color;

        $mdDialog.hide(ctrl.calendar);
    };
} ]);
