import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { HeaderFabButtonClick, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCard } from '@angular/material/card';
import { DataTableComponent, DataTableGoTo } from '../../../data-table/data-table.component';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from 'src/app/data-table/interfaces/data-table-columns';
import { UserGroup } from '../../models/user-group';
import { UserGroupService } from '../../../shared/http/user-group.service';
import { EMPTY, of, shareReplay, switchMap, tap } from 'rxjs';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { TranslateService } from '../../../shared/services/translate.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { PermissionsInputValue } from '../../../permissions/services/element-permission.service';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { FormControl } from '@angular/forms';
import { SnackBarService } from '../../../shared/services/snack-bar.service';

@Component({
    selector: 'eaw-company-user-group-list',
    standalone: true,
    imports: [
        PageHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        MatCard,
        DataTableComponent,
    ],
    templateUrl: './company-user-group-list.component.html',
    styleUrl: './company-user-group-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyUserGroupListComponent implements EawDataTable<UserGroup>, OnInit {
    private readonly userGroupService = inject(UserGroupService);
    private readonly translate = inject(TranslateService);
    private readonly confirmDialog = inject(ConfirmDialogService);
    private readonly promptDialog = inject(PromptDialogService);
    private readonly snackBarService = inject(SnackBarService);
    private readonly changeDetection = inject(ChangeDetectorRef);

    // Using @Input as this comes from uiRouter
    @Input({ required: true }) customerId!: number;

    @ViewChild('dataTable') dataTable?: DataTableComponent<UserGroup>;
    columns: DataTableColumnType<UserGroup>[];

    fabButton?: HeaderFabButtonClick;
    goToPage: DataTableGoTo;
    request?: DataTableRequest<UserGroup>;

    constructor() {
        const show = () => of(true);
        this.columns = [
            new DataTableTextColumn({
                key: 'name',
                header: new DataTableHeader({
                    i18n: 'NAME',
                    sortBy: 'name',
                    ns: 'general',
                }),
                value: 'name',
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({
                    i18n: 'CREATED',
                    sortBy: 'created_at',
                    ns: 'general',
                }),
                format: DateTime.DATETIME_MED,
                value: async (cell) => cell.item.createdAt,
            }),
            new DataTableButtonColumn({
                header: new DataTableHeader({
                    i18n: '',
                    ns: 'general',
                }),
                buttons: [
                    {
                        icon: 'edit',
                        click: this.updateGroup.bind(this),
                        tooltip: { key: 'EDIT', ns: 'general' },
                        permissions: (cell) => {
                            return this.getUpdatePermissions(cell.item.id);
                        },
                        show,
                    },
                    {
                        icon: 'delete',
                        type: 'warn',
                        click: this.deleteGroup.bind(this),
                        tooltip: {
                            key: 'DELETE',
                            ns: 'general',
                        },
                        permissions: (cell) => {
                            return this.getDeletePermissions(cell.item.id);
                        },
                        show,
                    },
                ],
            }),
        ];

        this.goToPage = {
            state: 'eaw/app/company/user_group/members',
            params: [ { stateKey: 'id', itemKey: 'id' } ],
        };
    }

    private getDeletePermissions(groupId: number): PermissionsInputValue {
        return [
            [
                `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].delete`,
                {
                    models: [
                        { type: ApiModel.UserGroup, id: groupId },
                        { type: ApiModel.Customer, id: this.customerId } ],
                },
            ],
        ];
    }

    private getUpdatePermissions(groupId: number): PermissionsInputValue {
        return [
            [
                `customers.[${ApiModel.Customer}].user_groups.[${ApiModel.UserGroup}].update`,
                {
                    models: [
                        { type: ApiModel.UserGroup, id: groupId },
                        { type: ApiModel.Customer, id: this.customerId },
                    ],
                },
            ],
        ];
    }

    ngOnInit() {
        this.fabButton = {
            ariaLabel: 'Add user group',
            click: this.addUserGroup.bind(this),
            hasPermission: () => of(true),
            icon: 'add',
            tooltip: this.translate.t('ADD_USER_GROUP', 'company'),
            type: 'accent',
            permissionInputs: {
                permissions: [ [ `customers.[${ApiModel.Customer}].user_groups.create`, { models: [ { type: ApiModel.Customer, id: this.customerId } ] } ] ],
            },
        };
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.userGroupService.getAllForCustomer(this.customerId, pagination)
            .pipe(
                shareReplay(1),
                tap(() => this.changeDetection.markForCheck()),
            );
    };

    private addUserGroup() {
        this.promptDialog.open('text', {
            formControl: new FormControl(),
            title: this.translate.t('ADD_USER_GROUP', 'company'),
            label: this.translate.t('NAME'),
            confirmText: this.translate.t('CREATE'),
            required: true,
            confirmButtonColor: 'accent',
        })
            .afterClosed()
            .pipe(
                switchMap((name) => {
                    if (!name) {
                        return EMPTY;
                    }

                    return this.userGroupService.create(this.customerId, { name });
                }),
                tap(() => {
                    this.dataTable?.refresh();
                    this.snackBarService.created();
                }),
            )
            .subscribe();
    }

    private updateGroup(item: DataTableButtonCell<UserGroup>) {
        this.promptDialog.open('text', {
            formControl: new FormControl(item.item.name),
            title: this.translate.t('EDIT_USER_GROUP', 'company'),
            label: this.translate.t('NAME'),
            confirmText: this.translate.t('SAVE'),
            required: true,
            confirmButtonColor: 'accent',
        })
            .afterClosed()
            .pipe(
                switchMap((name) => {
                    if (!name) {
                        item.disabled.set(false);
                        this.changeDetection.markForCheck();
                        return EMPTY;
                    }

                    return this.userGroupService.update(this.customerId, item.item.id, { name });
                }),
                tap(() => {
                    this.dataTable?.refresh();
                    this.snackBarService.updated();
                }),
            )
            .subscribe();
    }

    private deleteGroup(item: DataTableButtonCell<UserGroup>) {
        this.confirmDialog.open({
            title: this.translate.t('DELETE_USER_GROUP', 'company'),
            text: this.translate.t('DELETE_USER_GROUP_TEXT', 'company', { name: item.item.name }),
            confirmButtonColor: 'warn',
            confirmText: this.translate.t('DELETE'),
        })
            .afterClosed()
            .pipe(
                switchMap((confirmed) => {
                    if (!confirmed?.ok) {
                        item.disabled.set(false);
                        this.changeDetection.markForCheck();
                        return EMPTY;
                    }

                    return this.userGroupService.delete(this.customerId, item.item.id);
                }),
                tap(() => {
                    this.dataTable?.refresh();
                    this.snackBarService.deleted();
                }),
            )
            .subscribe();
    }
}
