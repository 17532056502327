// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<section layout="row" layout-wrap>
    <md-card id="info-card" flex="100" flex-gt-md="65">
        <md-card-header>
            <md-card-header-text>
                <span class="md-title" bo-i18next="SHIFT_plural"></span>
            </md-card-header-text>

            <md-button class="md-icon-button" ng-click="$shifts.reset()">
                <md-icon ng-bind="'restore_from_trash'"></md-icon>
                <md-tooltip ng-i18next="RELOAD"></md-tooltip>
            </md-button>

            <md-button class="md-icon-button" ng-show="$shifts.hasActions()" ng-click="$shifts.save()">
                <md-icon ng-bind="'save'"></md-icon>
                <md-tooltip ng-i18next="SAVE"></md-tooltip>
            </md-button>
        </md-card-header>
        <md-card-content class="tw-p-0">
            <div class="tw-h-full" ng-if="$shifts.loading" layout="row" layout-align="center center">
                <md-progress-circular></md-progress-circular>
            </div>

            <table class="table table-striped table-condensed table-bordered-bottom" ng-show="!$shifts.loading">
                <thead ng-show="$shifts.shifts.length">
                <tr>
                    <th>
                        <md-checkbox class="condensed" ng-checked="$shifts.areAllChecked()" md-indeterminate="$shifts.areSomeChecked()" ng-click="$shifts.toggleAll()"></md-checkbox>
                    </th>
                    <th ng-i18next="LOCATION"></th>
                    <th ng-i18next="scheduling:PERIOD_plural"></th>
                    <th ng-i18next="TIME"></th>
                    <th ng-i18next="LENGTH"></th>
                    <th></th> <!-- PERCENTAGE -->
                    <th ng-i18next="ACTION"></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr ng-show="!$shifts.shifts.length">
                    <td colspan="99" ng-i18next="NO_DATA"></td>
                </tr>
                <tr ng-show="$shifts.shifts.length" ng-repeat="shift in $shifts.shifts track by shift.id">
                    <td>
                        <md-checkbox class="condensed" ng-model="shift.selected" ng-change="$shifts.onShiftToggle(shift)"></md-checkbox>
                    </td>
                    <td ng-bind="shift.schedule.customer.name"></td>
                    <td>
                        <div ng-repeat="period in shift.periods" ng-bind="period.business_unit.name"></div>
                    </td>
                    <td>
                        <span ng-i18next="[i18next]({from: shift.from.format('LLL'), to: shift.to.format('LT')})FROM_TO"></span>
                    </td>
                    <td ng-bind="shift.net_length | eawDuration"></td>
                    <td>
                        <div class="text-right" ng-repeat="action in shift.actions" ng-if="action" ng-bind="action.percentage / 100 | percent"></div>
                    </td>
                    <td>
                        <div ng-repeat="action in shift.actions" ng-bind="action.action.text"></div>
                    </td>
                    <td>
                        <eaw-icon-button
                                stop-propagate
                                tooltip="'absences:REMOVE_ACTION' | i18next"
                                eaw-invisible="{{!shift.actions}}"
                                icon="delete"
                                ng-click="$shifts.clearActions(shift)">
                        </eaw-icon-button>
                    </td>
                </tr>
                <tr ng-show="$shifts.tooMany">
                    <td colspan="99" ng-i18next="scheduling:TOO_MANY_SHIFTS_CHOOSE_SMALLER_INTERVAL"></td>
                </tr>
                </tbody>
                <tbody>
                <tr style="border-top: 2px solid grey">
                    <td><strong ng-i18next="absences:ORIGINAL_WORK_HOURS"></strong></td>
                    <td colspan="999" ng-bind="$shifts.stats.originalLength | eawDuration"></td>
                </tr>
                <tr>
                    <td><strong ng-i18next="absences:NEW_WORK_HOURS"></strong></td>
                    <td colspan="999" ng-bind="$shifts.stats.newLength | eawDuration"></td>
                </tr>
                <tr>
                    <td><strong ng-i18next="absences:TOTAL_SICK_LEAVE_PERCENTAGE"></strong></td>
                    <td colspan="999" ng-bind="$shifts.stats.percentage | percent:2"></td>
                </tr>
                </tbody>
            </table>
        </md-card-content>
    </md-card>

    <md-card ng-if="$shifts.shifts.length && ($shifts.areSomeChecked() || $shifts.areAllChecked())" flex>
        <md-card-header>
            <md-card-header-text>
                <span class="md-title" bo-i18next="[i18next]({count: $shifts.getSelectedShifts().length})scheduling:MANAGE_SHIFT"></span>
            </md-card-header-text>

            <md-button class="md-icon-button" ng-if="!$shifts.split">
                <md-icon  ng-click="$shifts.split = true">layers_clear</md-icon>
                <md-tooltip md-direction="left" ng-i18next="scheduling:SPLIT"></md-tooltip>
            </md-button>

            <md-button class="md-icon-button" ng-if="$shifts.split">
                <md-icon ng-click="$shifts.split = false">layers</md-icon>
                <md-tooltip md-direction="left" ng-i18next="scheduling:DONT_SPLIT"></md-tooltip>
            </md-button>
        </md-card-header>
        <md-card-content>
            <form name="manageShiftActionsForm" ng-submit="$shifts.submit()">
                <md-subheader ng-if="$shifts.split">{{'scheduling:FIRST_SEGMENT' | i18next}}</md-subheader>
                <split-shifts-manage
                        ng-model="$shifts.actions[0]"
                        split="$shifts.split"
                        available-interval="$shifts.availableInterval"
                        ng-change="$shifts.segmentChanged(0, 1)">
                </split-shifts-manage>

                <md-subheader class="md-primary" ng-if="$shifts.split">{{'scheduling:SECOND_SEGMENT' | i18next}}</md-subheader>
                <split-shifts-manage
                        ng-if="$shifts.split"
                        ng-model="$shifts.actions[1]"
                        split="$shifts.split"
                        available-interval="$shifts.availableInterval"
                        ng-change="$shifts.segmentChanged(1, 0)">
                </split-shifts-manage>

                <eaw-submit-btn form="manageShiftActionsForm" text="'CONFIRM'"></eaw-submit-btn>
            </form>
        </md-card-content>
    </md-card>
</section>
`;

module('eaw.scheduling').component('splitShifts', {
    template: template1,
    controllerAs: '$shifts',
    bindings: {
        onSaved: '&?',
        inProgress: '<?',
        from: '<',
        to: '<',
        customer: '<',
        employee: '<',
        model: '<?', // {className: <string>, key: <integer>}
    },
    controller: [ 'ShiftFactory', 'UnsavedChangesDowngrade', '$state', function(ShiftFactory, UnsavedChangesDowngrade, $state) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.shifts = [];
            ctrl.actions = new Array(2);
            ctrl.reset = $state.reload;

            UnsavedChangesDowngrade.init(ctrl.hasActions);
        };

        ctrl.$postLink = () => {
            if (ctrl.from && ctrl.to) {
                ctrl.getShifts();
            }
        };

        ctrl.$onChanges = (changes) => {
            if (changes?.from?.currentValue && changes?.to?.currentValue) {
                ctrl.getShifts();
            }
        };

        /**
         * @param a - The segment index that triggered change
         * @param b - The other segment index that might be affected
         */
        ctrl.segmentChanged = (a, b) => {
            if (ctrl.actions[a] && ctrl.actions[b]) {
                ctrl.actions[b] = {
                    employee: ctrl.actions[b].employee,
                    percentage: 100 - ctrl.actions[a].percentage,
                    action: ctrl.actions[b].action,
                };
            }
        };

        ctrl.calculateStats = () => {
            // Initial values
            ctrl.stats = {
                originalLength: 0,
                newLength: 0,
            };

            // Loop over shifts
            ctrl.shifts.forEach((s) => {
                ctrl.stats.originalLength += s.net_length;

                // Are there any actions on this shift?
                if (s.actions?.length) {
                    s.actions.forEach((a) => {
                        if (a?.action?.name === 'keep') {
                            ctrl.stats.newLength += s.net_length * a.percentage / 100;
                        }
                    });
                } else {
                    ctrl.stats.newLength += s.net_length;
                }
            });

            // Calculate percentage based on info we got
            ctrl.stats.percentage = 1 - ctrl.stats.newLength / ctrl.stats.originalLength;
        };

        ctrl.hasActions = () => ctrl.shifts?.filter((s) => s.actions).length;

        ctrl.getShifts = () => {
            ctrl.loading = true;
            ShiftFactory.getAllForEmployee(ctrl.customer.id, ctrl.employee.id, {
                per_page: 300,
                from: ctrl.from,
                to: ctrl.to,
                only_local: true,
                order_by: 'from',
                direction: 'asc',
                with: [ 'periods.businessUnit', 'schedule.customer' ],
            }).then((resp) => {
                ctrl.shifts = resp.data;

                // filter out periods with no business unit
                ctrl.shifts.forEach((shift) => {
                    shift.periods = shift.periods.filter((p) => p.business_unit);
                });

                // If you chose an interval that is too large, you won't get all the shifts.
                ctrl.tooMany = resp.last_page && resp.last_page != resp.current_page;

                ctrl.calculateStats();
            }).finally(() => ctrl.loading = false);
        };

        ctrl.onShiftToggle = () => {
            ctrl.resetManage();
            ctrl.updateAvailableInterval();
        };

        ctrl.resetManage = () => {
            // Reset stuff if nothing is selected
            if (!ctrl.getSelectedShifts().length) {
                ctrl.split = false;
            }
        };

        ctrl.clearActions = (shift) => {
            delete shift.actions;
            ctrl.calculateStats();
        };

        ctrl.toggleAll = () => {
            // If all are checked, that means we want to uncheck all
            // Any other case means we want to toggle all
            const toggle = !ctrl.areAllChecked();

            ctrl.shifts.forEach((s) => s.selected = toggle);

            ctrl.onShiftToggle();
        };

        ctrl.areSomeChecked = () => !ctrl.areAllChecked() && ctrl.shifts.some((s) => s.selected);

        ctrl.areAllChecked = () => ctrl.shifts.every((s) => s.selected);

        ctrl.getSelectedShifts = () => ctrl.shifts?.filter((s) => s.selected);

        ctrl.updateAvailableInterval = () => {
            const selectedShifts = ctrl.getSelectedShifts();

            // IntervalOld that tells us what period to get employees when reassigning
            ctrl.availableInterval = {
                from: ctrl.from.clone(),
                to: ctrl.to.clone(),
            };

            // But if we have some selected shifts, then use smallest from and largest to
            if (selectedShifts.length) {
                ctrl.availableInterval.from = moment(Math.min(...selectedShifts.map((s) => +s.from)));
                ctrl.availableInterval.to = moment(Math.max(...selectedShifts.map((s) => +s.to)));
            }
        };

        ctrl.submit = () => {
            ctrl.shifts.forEach((s) => {
                if (s.selected) {
                    s.actions = [ ...ctrl.actions ]; // Got to copy
                    s.selected = false;
                }
            });

            ctrl.onShiftToggle();
            ctrl.calculateStats();
        };

        ctrl.save = () => {
            const data = {};

            ctrl.shifts.forEach((s) => {
                if (s.actions?.length) { // Not all shifts have action(s)
                    data[s.id] = s.actions.map((a) => a ? { // Not segments have TWO actions, last one might be null
                        action: a.action.name,
                        employee: a.employee?.id,
                        percent: a.percentage,
                    } : null);
                }
            });

            ShiftFactory.splitShifts(ctrl.customer.id, data, ctrl.model).$promise.then(() => {
                ctrl.onSaved();
                // Make sure the
                ctrl.shifts.forEach((s) => {
                    delete s.actions;
                });
            });
        };
    } ],
});
