import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { AbsenceType, AbsenceTypeResponse } from '../models/absence-type';
import { map, Observable } from 'rxjs';
import { Absence, AbsenceResponse } from '../models/absence';
import { OffTime, OffTimeResponse } from '../../vacations/models/off-time';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ApiResponse } from '../../shared/interfaces/api-response';

export type AbsenceStat = { length: number, count: number }

export interface AbsenceStatResponse extends ApiResponse {
    absence_types: Record<string, AbsenceTypeResponse>,
    total_hours: number,
    total_days: number,
    absences: AbsenceResponse[],
    statistics: Record<string, AbsenceStat>,
    off_times: OffTimeResponse[],
}

export interface FormattedAbsenceStatResponse {
    absence_types: AbsenceType[],
    total_hours: number,
    total_days: number,
    absences: Absence[],
    statistics: Record<string, AbsenceStat>,
    off_times: OffTime[],
}

@Injectable({
    providedIn: 'root',
})
export class AbsenceStatsService {

    constructor(
        @Inject(HttpClient) public http: HttpClient) {
    }

    getOverview(customerId: number, employeeId: number, from: DateTime, to: DateTime): Observable<FormattedAbsenceStatResponse> {
        return this.http.get<AbsenceStatResponse>(`/customers/${customerId}/employees/${employeeId}/absences/overview`,
            {
                params: formatHttpParams({
                    customer: customerId,
                    employee: employeeId,
                    from,
                    to,
                }),
            }).pipe(
            map((resp) => {
                return {
                    ...resp,
                    absence_types: Object.values(resp.absence_types).map((type) => new AbsenceType(type)),
                    absences: resp.absences.map((absence) => new Absence(absence)),
                    off_times: resp.off_times.map((off) => new OffTime(off)),
                };
            }),
        );
    }

    getStats(customerId: number, employeeId: number, from: DateTime, to: DateTime, absenceTypeId?: number) {
        const params = {
            customer: customerId,
            employee: employeeId,
            from: from.toFormat('yyyy-MM-dd'),
            to: to.toFormat('yyyy-MM-dd'),
            absenceTypeId,
        };

        return this.http.get<AbsenceStatResponse>(`/customers/${customerId}/employees/${employeeId}/absences/statistics`,
            {
                params: formatHttpParams(params),
            });
    }
}
