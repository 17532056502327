import { Component, inject, OnInit } from '@angular/core';
import { EawForm } from '../../types/form';
import { FormSubmission } from '../../models/form-submission';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { ShowFormNodeComponent } from '../show-form-node/show-form-node.component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButton } from '@angular/material/button';
import { DialogComponent, DialogData } from '../../../shared/dialogs/dialog-component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { tap } from 'rxjs';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';

export interface ShowFormSubmissionData extends DialogData {
    form: EawForm;
    submission: FormSubmission;
    hideAnonymizeButton?: boolean;
}

/**
 * Component to show a form submission.
 * Can be expanded later to include more information about the form, currently just
 * downgraded to be used in the angularjs dialog.
 */
@Component({
    selector: 'eaw-show-form-submission',
    standalone: true,
    imports: [
        JsonPipe,
        ShowFormNodeComponent,
        DialogHeaderComponent,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        MatDialogContent,
        MatDialogActions,
        ActionButtonComponent,
        MatDialogClose,
        MatButton,
    ],
    templateUrl: './show-form-submission.component.html',
    styleUrl: './show-form-submission.component.scss',
})
export class ShowFormSubmissionComponent extends DialogComponent<ShowFormSubmissionData, { anonymize: boolean }> implements OnInit {
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly translate = inject(TranslateService);

    protected form: EawForm;
    protected submission: FormSubmission;

    canAnonymize = false;
    anonymizing = false;
    anonymized: boolean;

    constructor() {
        super(inject(MatDialogRef));
        this.form = this.data.form;
        this.submission = this.data.submission;
        this.anonymized = this.submission.anonymizedAt != null;
    }

    ngOnInit(): void {
        if (this.submission.closedAt && !this.data.hideAnonymizeButton && !this.anonymized) {
            this.permissionCheckService.isAllowed(`customers.${this.form.customer_id}.forms.${this.form.id}.submissions.${this.submission.id}.anonymize`)
                .pipe(tap((canAnonymize) => this.canAnonymize = canAnonymize))
                .subscribe();
        }
    }

    anonymize() {
        this.confirmDialogService.delete({
            title: this.translate.t('ANONYMIZE_SUBMISSION_TITLE', 'forms'),
            text: this.translate.t('ANONYMIZE_SUBMISSION_TEXT', 'forms'),
            confirmText: this.translate.t('ANONYMIZE', 'forms'),
        }).afterClosed()
            .pipe(tap((result) => {
                if (result?.ok) {
                    this.anonymizing = true;
                    this.dialogRef.close({ anonymize: true });
                }
            })).subscribe();
    }
}
