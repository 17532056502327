<eaw-dialog-header>
    <span title>{{ (data.fileType ? 'EDIT_FILETYPE' : 'NEW_FILETYPE') | translate: 'hr' | async }}</span>
</eaw-dialog-header>
<mat-dialog-content>
    <form id="hrFileForm" [formGroup]="form" class="tw-flex tw-flex-col" (ngSubmit)="save()" autocomplete="off">
        <mat-form-field>
            <mat-label>{{ 'NAME' | translate | async }}</mat-label>
            <input matInput formControlName="name" required>
        </mat-form-field>
        <mat-checkbox formControlName="mandatory" eawCheckboxHelper>
            {{ 'MANDATORY_FILE' | translate: 'hr' | async }}
        </mat-checkbox>
        @if (hasDigiSign()) {
            <mat-checkbox formControlName="signable"
                          eawPermission
                          [permissions]="signablePermissions()"
                          eawCheckboxHelper>
                {{ 'SIGNABLE_FILE_TYPE' | translate :'digisign'| async }}
            </mat-checkbox>
            <!-- Disabling mat-select does not actually work, so we'll hide it instead -->
            <mat-form-field class="tw-mt-16" eawPermission [permissions]="signablePermissions()">
                <mat-label>{{ 'SIGN_WITH' | translate: 'digisign' | async }}</mat-label>
                <mat-select formControlName="signableProvider">
                    @for (signableProvider of signableProviders | async; track signableProvider.class) {
                        <mat-option [value]="signableProvider">
                            {{ signableProvider.name }}
                        </mat-option>
                    }
                </mat-select>
                <mat-hint>{{ form.controls.signableProvider.value?.description }}</mat-hint>
            </mat-form-field>
        }
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button color="accent" formId="hrFileForm" type="submit" [loading]="saving()" [disabled]="form.invalid">
        {{ 'SAVE' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
