var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj)) throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __privateMethod = (obj, member, method) => {
  __accessCheck(obj, member, "access private method");
  return method;
};
var _dirty, _ownerElement, _reset, reset_fn;
import { globalThis, document } from "../utils/server-safe-globals.js";
const template = document.createElement("template");
template.innerHTML = `
<style>
  :host {
    cursor: pointer;
    display: block;
    line-height: revert;
    white-space: nowrap;
    white-space-collapse: collapse;
    text-wrap: nowrap;
    padding: .4em .5em;
    transition: var(--media-option-transition);
    outline: var(--media-option-outline, 0);
    outline-offset: var(--media-option-outline-offset, -1px);
  }

  :host(:focus-visible) {
    box-shadow: var(--media-option-focus-shadow, inset 0 0 0 2px rgb(27 127 204 / .9));
    outline: var(--media-option-hover-outline, 0);
    outline-offset: var(--media-option-hover-outline-offset,  var(--media-option-outline-offset, -1px));
  }

  :host(:hover) {
    cursor: pointer;
    background: var(--media-option-hover-background, rgb(82 82 122 / .8));
    outline: var(--media-option-hover-outline);
    outline-offset: var(--media-option-hover-outline-offset,  var(--media-option-outline-offset, -1px));
  }

  :host([aria-selected="true"]) {
    background: var(--media-option-selected-background);
  }

  :host([disabled]) {
    pointer-events: none;
    color: rgba(255, 255, 255, .3);
  }
</style>
<slot></slot>
`;
const Attributes = {
  VALUE: "value",
  SELECTED: "selected",
  DISABLED: "disabled"
};
class MediaChromeOption extends globalThis.HTMLElement {
  constructor() {
    super();
    __privateAdd(this, _reset);
    __privateAdd(this, _dirty, false);
    __privateAdd(this, _ownerElement, void 0);
    if (!this.shadowRoot) {
      this.attachShadow({
        mode: "open"
      });
      this.shadowRoot.appendChild(template.content.cloneNode(true));
    }
  }
  static get observedAttributes() {
    return [Attributes.DISABLED, Attributes.SELECTED, Attributes.VALUE];
  }
  get value() {
    var _a;
    return (_a = this.getAttribute(Attributes.VALUE)) != null ? _a : this.text;
  }
  set value(val) {
    this.setAttribute(Attributes.VALUE, val);
  }
  get text() {
    var _a;
    return ((_a = this.textContent) != null ? _a : "").trim();
  }
  get selected() {
    return this.getAttribute("aria-selected") === "true";
  }
  set selected(value) {
    __privateSet(this, _dirty, true);
    this.setAttribute("aria-selected", value ? "true" : "false");
    if (value) {
      this.part.add("option-selected");
    } else {
      this.part.remove("option-selected");
    }
  }
  enable() {
    if (!this.hasAttribute("tabindex")) {
      this.setAttribute("tabindex", -1);
    }
    if (!this.hasAttribute("aria-selected")) {
      this.setAttribute("aria-selected", "false");
    }
  }
  disable() {
    this.removeAttribute("tabindex");
  }
  attributeChangedCallback(attrName, oldValue, newValue) {
    if (attrName === Attributes.SELECTED && !__privateGet(this, _dirty)) {
      this.setAttribute("aria-selected", newValue != null ? "true" : "false");
    } else if (attrName === Attributes.DISABLED && newValue !== oldValue) {
      if (newValue == null) {
        this.enable();
      } else {
        this.disable();
      }
    }
  }
  connectedCallback() {
    if (!this.hasAttribute(Attributes.DISABLED)) {
      this.enable();
    }
    this.setAttribute("role", "option");
    __privateSet(this, _ownerElement, closestOptionsContainer(this, this.parentNode));
    __privateMethod(this, _reset, reset_fn).call(this);
  }
  disconnectedCallback() {
    this.disable();
    __privateMethod(this, _reset, reset_fn).call(this);
    __privateSet(this, _ownerElement, null);
  }
  handleClick() {}
}
_dirty = new WeakMap();
_ownerElement = new WeakMap();
_reset = new WeakSet();
reset_fn = function () {
  var _a;
  const options = (_a = __privateGet(this, _ownerElement)) == null ? void 0 : _a.options;
  if (!options) return;
  let selectedOption = options.filter(option => option.getAttribute("aria-selected") === "true").pop();
  if (!selectedOption) selectedOption = options[0];
  if (__privateGet(this, _ownerElement).getAttribute("aria-multiselectable") !== "true") {
    options.forEach(option => {
      option.setAttribute("tabindex", "-1");
      option.setAttribute("aria-selected", "false");
    });
  }
  selectedOption == null ? void 0 : selectedOption.setAttribute("tabindex", "0");
  selectedOption == null ? void 0 : selectedOption.setAttribute("aria-selected", "true");
};
function closestOptionsContainer(childNode, parentNode) {
  if (!childNode) return null;
  const {
    host
  } = childNode.getRootNode();
  if (!parentNode && host) return closestOptionsContainer(childNode, host);
  if (parentNode == null ? void 0 : parentNode.options) return parentNode;
  return closestOptionsContainer(parentNode, parentNode == null ? void 0 : parentNode.parentNode);
}
if (!globalThis.customElements.get("media-chrome-option")) {
  globalThis.customElements.define("media-chrome-option", MediaChromeOption);
}
var media_chrome_option_default = MediaChromeOption;
export { Attributes, MediaChromeOption, media_chrome_option_default as default };