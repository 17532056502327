import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogData, DialogSize } from '../dialog-component';
import { WarningsDialogComponent } from './warnings-dialog.component';
import type { Observable } from 'rxjs';
import type { Warning } from '../../models/warning';
import type { Infraction } from '../../models/infraction';

export interface WarningsDialogData extends DialogData {
    title?: Observable<string>,
    warnings?: Observable<Warning[]>,
    infractions?: Observable<Infraction[]>,
}

@Injectable({
    providedIn: 'root',
})
export class WarningsDialogService {

    constructor(@Inject(MatDialog) private dialog: MatDialog) {
    }

    open(warnings: Observable<Warning[]>, title?: Observable<string>, infractions?: Observable<Infraction[]>): MatDialogRef<WarningsDialogComponent, undefined> {
        return this.dialog.open<WarningsDialogComponent, WarningsDialogData, undefined>(WarningsDialogComponent, {
            data: {
                size: DialogSize.Medium,
                title,
                warnings,
                infractions,
            },
        });
    }
}
