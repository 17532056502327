// @ts-nocheck
import { module } from 'angular';
import { EawUrl } from './url.service';
export class EawResourceFactory {
    static get $inject() {
        return [ '$resource' ];
    }

    $resource;
    cache = {};
    constructor($resource) {
        this.$resource = $resource;
    }

    create(url: string, paramDefaults?: unknown, actions?: angular.resource.IActionHash, options?: angular.resource.IResourceOptions) {
        const fullUrl = EawUrl.getUrl(url);
        if (!this.cache[fullUrl]) {
            this.cache[fullUrl] = this.$resource(fullUrl, paramDefaults, actions, options);
        }
        return this.cache[fullUrl];
    }
}
module('eaw.resource').service('EawResource', EawResourceFactory);
