import { inject, Injectable } from '@angular/core';
import { ConfirmDialogService } from '../dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from './translate.service';
import { of, tap } from 'rxjs';
import { Mobile } from '../utils/eaw-mobile';

@Injectable({
    providedIn: 'root',
})
export class OpenExternalLinkService {
    private readonly confirmDialog = inject(ConfirmDialogService);
    private readonly translate = inject(TranslateService);

    open(options: {url: string}, event?: PointerEvent| TouchEvent) {
        // Show a confirm-dialog if the main mouse button was clicked
        if (!event || event instanceof TouchEvent || event?.button === 0) {
            event?.preventDefault();
            event?.stopPropagation();

            return this.confirmDialog.open({
                text: this.translate.t('LEAVING_EASY_AT_WORK', 'general', { url: options.url }),
                title: this.translate.t('OPENING_EXTERNAL_LINK'),
                confirmText: this.translate.t('PROCEED'),
            }).afterClosed()
                .pipe(
                    tap((response) => {
                        if (!response?.ok) {
                            return;
                        }
                        if (Mobile.isMobile) {
                            Mobile.openBrowser(options.url);
                        } else {
                            window.open(options.url, '_blank');
                        }
                    }),
                );
        }

        return of(undefined);
    }
}
