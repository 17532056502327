import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, Observable } from 'rxjs';
import { QualificationService } from '../../../shared/http/qualification.service';
import { Qualification } from '../../../shared/models/qualification';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { OnChange } from '../../../shared/types/on-change';
import { OnTouched } from '../../../shared/types/on-touched';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipsModule } from '@angular/material/chips';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'eaw-qualification-autocomplete',
    templateUrl: './qualification-autocomplete.component.html',
    styleUrl: './qualification-autocomplete.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QualificationAutocompleteComponent,
        },
    ],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatChipsModule,
        NgFor,
        MatIconModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatOptionModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class QualificationAutocompleteComponent implements OnInit, ControlValueAccessor {
    @ViewChild('inputElement') inputElement!: HTMLInputElement;
    @ViewChild('chipList') chipList!: MatChipGrid;
    @ViewChild('autocomplete') autocomplete!: MatAutocomplete;

    @Input() qualifications: Qualification[] = [];

    @Input() customerId!: number;
    selectedQualifications: Qualification[] = [];
    qualificationObservable?: Observable<Qualification[]>;
    inputCtrl: FormControl<string | null>;

    onChange?: OnChange<Qualification[]>;
    onTouch?: OnTouched;

    constructor(@Inject(QualificationService) protected qualificationService: QualificationService) {
        this.inputCtrl = new FormControl('');
    }

    ngOnInit(): void {
        this.getQualifications();

        this.inputCtrl.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            map((value?: unknown) => typeof value === 'string' && value !== '' ? value : undefined),
        ).subscribe((filter?: string) => {
            this.getQualifications(filter);
        });
    }

    getQualifications(filter?: string): void {
        this.qualificationObservable = this.qualificationService.getAll(this.customerId, { filter, per_page: 64 }).pipe(map((resp) => {
            return resp.data.filter((qual: Qualification) => !this.selectedQualifications.find((q: Qualification) => q.id == qual.id));
        }));
    }

    remove(qualification: Qualification) {
        this.selectedQualifications = this.selectedQualifications.filter((q: Qualification) => q.id !== qualification.id);
        this.onChange?.(this.selectedQualifications);
        this.getQualifications();
    }

    add(qualification: Qualification) {
        if (this.selectedQualifications.find((q: Qualification) => q.id == qualification.id)) {
            return;
        }

        this.selectedQualifications = this.selectedQualifications.concat([ qualification ]);

        this.onChange?.(this.selectedQualifications);
        this.inputCtrl.setValue('');
        this.getQualifications();
    }

    registerOnChange(fn: OnChange<Qualification[]>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: OnTouched): void {
        this.onTouch = fn;
    }

    writeValue(obj: Qualification[]): void {
        this.selectedQualifications = obj;
    }
}
