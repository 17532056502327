@if (loading()) {
    <div id="loading">
        <eaw-info-loading size="xl"/>
    </div>
} @else {
    @if (showButtons()) {
        <button class="arrow left" mat-fab color="primary" (click)="scroll('back')">
            <mat-icon>arrow_back</mat-icon>
        </button>

        <button class="arrow right" mat-fab color="primary" (click)="scroll('forward')">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    }

    <div #newsletterContainer id="newsletters-container" (cdkObserveContent)="newsletterContentChange(newsletterContainer)">
        @for (item of newsletterItems(); track item.newsletter.id) {
            <div class="newsletter">
                <div class="text-overlay">
                    <div class="text">
                        <strong>{{ item.newsletter.getTitle(language) }}</strong>
                        <small>{{ item.issue.getTitle(language) }}</small>

                    </div>

                    <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: item}">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>

                @defer (on viewport) {
                    <div class="preview-port">
                        <button class="download-button" mat-mini-fab color="accent" [disabled]="item.downloading()" (click)="downloadIssue(item)">
                            <mat-icon>download</mat-icon>
                        </button>

                        <eaw-newsletter-preview [customerId]="customerId"
                                                [newsletterId]="item.newsletter.id"
                                                [language]="item.newsletter.getLanguageCode(language)"
                                                (click)="openIssue(item)"
                                                [issueId]="item.issue.id"/>
                    </div>
                } @placeholder {
                    <!-- A placeholder block is required -->
                    <div class="placeholder"></div>
                }
            </div>
        }
    </div>

    <mat-menu #menu="matMenu">
        <ng-template matMenuContent let-item="item">
            <a [href]="item.issuesHref()">
                <button mat-menu-item>{{'VIEW_ALL_ISSUES' | translate:'newsletter' | async}}</button>
            </a>
            <button mat-menu-item (click)="openIssue(item)">{{'READ_ISSUE' | translate:'newsletter' | async}}</button>
        </ng-template>
    </mat-menu>
}
