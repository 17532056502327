<eaw-page-header>
    <span title>{{'OPENING_HOURS' | translate: 'company' | async}}</span>
</eaw-page-header>

<div class="tw-flex tw-flex-col tw-items-start tw-mt-16 tw-gap-16">
    <div class="day tw-grid" *ngFor="let day of days">
        <div class="tw-col-span-1 tw-flex tw-items-center">
            <strong>{{day.name}}</strong>
        </div>

        <form [formGroup]="day.form" class="tw-col-span-1 tw-flex tw-items-center">
            <eaw-time-input standalone formControlName="from" [label]="'FROM' | translate"/>

            <eaw-time-input standalone formControlName="to" [label]="'TO' | translate"/>
        </form>

        <div class="tw-col-span-1 tw-flex tw-items-center">
            @if (day.processing) {
                <mat-progress-spinner mode="indeterminate" diameter="40" strokeWidth="3"></mat-progress-spinner>
            }
            @else if (day.openingHours && ('customers.' + customer['id'] + '.opening_hours.' + day.openingHours.id + '.delete' | permission | async)) {
                <button mat-mini-fab
                        (click)="removingOpeningHours(day)"
                        color="warn"
                        [matTooltip]="('REMOVE' | translate | async) || ''">
                    <mat-icon>delete</mat-icon>
                </button>
            }
        </div>
    </div>
</div>
