@if (showFilter()) {
    <mat-card id="filter-card">
        <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="updateOverview()">
                <mat-form-field eawDatePickerOptions>
                    <mat-label>{{ 'FROM' | translate | async }}</mat-label>
                    <input matInput
                           autocomplete="off"
                           [matDatepicker]="picker"
                           formControlName="from"
                           [min]="minDate()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error>
                        <eaw-field-error [control]="form.controls.from"/>
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'WEEK_plural' | translate | async }}</mat-label>
                    <input matInput type="number" min="1" max="4" formControlName="weeks">
                    <mat-error>
                        <eaw-field-error [control]="form.controls.weeks"/>
                    </mat-error>
                </mat-form-field>
            </form>

            <eaw-action-button [disabled]="form.invalid" [loading]="loading()" (click)="updateOverview()">{{ 'UPDATE' | translate | async }}</eaw-action-button>
        </mat-card-content>
    </mat-card>
}

<mat-card id="table-card">
    <mat-card-header class="tw-justify-between tw-items-center">
        <mat-card-title>{{ 'AVAILABILITY_plural' | translate: 'availabilities' | async }}</mat-card-title>

        <button mat-icon-button [matTooltip]="('FILTER' | translate | async) || ''" matTooltipPosition="left" (click)="showFilter.set(!showFilter())">
            <mat-icon>{{ showFilter() ? 'filter_alt' : 'filter_alt_off' }}</mat-icon>
        </button>
    </mat-card-header>

    <mat-card-content>
        <eaw-customer-availability-overview-table [customerId]="customerId()" [from]="from()" [weeks]="weeks()" (loading)="loading.set($event)" />
    </mat-card-content>
</mat-card>
