import { Component, inject, Input, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { MatTabsModule } from '@angular/material/tabs';
import { NamespaceFile } from '../../enums/namespace';
import { Observable, of } from 'rxjs';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { UIRouterModule } from '@uirouter/angular';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { CurrentService } from '../../services/current.service';
import { ElementPermissionService, PermissionChildrenFilterInInput, PermissionChildrenFilterOutInput, PermissionsInputValue } from '../../../permissions/services/element-permission.service';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';

export interface NavTabsTab {
    permissions?: PermissionsInputValue;
    somePermissions?: PermissionsInputValue;
    permissionChildrenFilter?: PermissionChildrenFilterOutInput;
    permissionChildrenInclude?: PermissionChildrenFilterInInput;
    state: string;
    // Tab will be shown unless it's an instance of Observable
    show?: Observable<boolean>;
    label?: {key: string, ns?: NamespaceFile}
    text?: Observable<string>,
    disabled?: boolean;
}

@Component({
    selector: 'eaw-nav-tabs',
    templateUrl: './nav-tabs.component.html',
    styleUrl: './nav-tabs.component.scss',
    standalone: true,
    imports: [
        MatTabsModule,
        NgFor,
        NgIf,
        MatIconModule,
        UIRouterModule,
        AsyncPipe,
        TranslatePipe,
        PermissionDirective,
    ],
})
export class NavTabsComponent implements OnInit {
    private readonly router = inject(UIRouter);
    private readonly current = inject(CurrentService);
    private readonly elementPermissionService = inject(ElementPermissionService);

    @Input() tabs: NavTabsTab[] = [];
    @Input() label?: Observable<string>;
    @Input() customerId?: number;

    protected readonly isTesting = environment.isTesting;
    activeTab?: number;

    ngOnInit(): void {
        const customerId = this.customerId || this.current.getCustomer().id;

        this.tabs.forEach((tab) => {
            tab.show = tab.show instanceof Observable ? tab.show : of(true);
            if (!tab.permissionChildrenFilter) {
                tab.permissionChildrenFilter = this.elementPermissionService.getPermissionFilterFromState(customerId, tab.state);
            }
        });

        if (this.label) {
            this.tabs.unshift({
                state: '',
                disabled: true,
                label: { key: '' },
                text: this.label,
                show: of(true),
            });
        }

        this.activeTab = this.tabs.findIndex((t) => this.router.globals.$current.name === t.state);

        const firstTab = this.tabs[0];
        if (this.activeTab < 0 && firstTab) {
            this.router.stateService.go(firstTab.state);
        }
    }

    changeTab(index: number) {
        const state = this.tabs[index]?.state;

        if (state) {
            this.activeTab = index;
            this.router.stateService.go(state);
        }
    }
}
