import moment, { Moment } from 'moment-timezone';
import { module } from 'angular';
import { PaginatedResponse } from '../../../../interfaces/paginated-response';
import { UserGroupService } from '../../user-groups/user-group.service';
import { IntervalOld } from '../../../interval-old';
import CustomerOld from '../../../customer-old';
import { Group, GroupMember } from '../../../group';
import { t } from 'i18next';

export class AddUserDialog implements angular.IController, angular.IOnInit {
    static get $inject() {
        return [ 'UserGroupService', 'ToastService', 'CustomerService', '$mdDialog' ];
    }

    interval!: IntervalOld;
    minDate!: Moment;
    disableUserSelect = false;
    disableUserGroupSelect!: boolean;

    // bindings
    user: any;
    userGroup!: Group;
    adding = false;
    customer!: CustomerOld;
    users: GroupMember[] = [];

    constructor(private UserGroupService: UserGroupService, private ToastService: any, private CustomerService: any, private $mdDialog: any) {
    }

    $onInit(): void {
        this.minDate = moment();
        this.disableUserSelect = !!this.user;
        this.disableUserGroupSelect = this.userGroup && !this.adding;

        this.interval = new IntervalOld(this.minDate.clone());
        this.users = [];
    }

    searchGroups(filter: string) {
        return this.UserGroupService.getAll(this.customer['id'], { filter }).$promise.then((resp: any) => resp.data);
    }

    addMultipleUsers() {
        this.users.forEach(this.addUser.bind(this));
    }

    addUser(user: GroupMember) {
        return this.UserGroupService.addMember(this.userGroup.owner_id, this.userGroup.id!, user.id as number, this.interval.from!, this.interval?.to).$promise.then((data: { first_name: string }) => {
            this.ToastService.toast(t('X_ADDED', {
                name: data.first_name,
            }));
            this.$mdDialog.hide();
        });
    }

    searchUsers(filter: string) {
        return this.CustomerService.getUsers({
            customer: {
                id: this.userGroup.owner_id,
            },
            fields: [ 'name' ],
            filter,
            order_by: 'first_name',
            direction: 'asc',
        }).$promise.then((resp: PaginatedResponse<any>) => resp.data);
    }
}
