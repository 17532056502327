// @ts-nocheck
import { module } from 'angular';

module('eaw.staffing').controller('updateStaffingIntervalDialogCtrl', [ '$mdDialog', 'staffingIntervalFactory', function($mdDialog, staffingIntervalFactory) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.from = ctrl.interval.from;
        ctrl.to = ctrl.interval.to;
    };

    ctrl.update = () => {
        ctrl.interval.disabled = true;
        ctrl.updating = true;
        staffingIntervalFactory.update.query(ctrl.staffingTemplate.customer_id, ctrl.interval.template_id, ctrl.interval.id, ctrl.from, ctrl.to).$promise.then((updatedInterval) => {
            ctrl.tableParams.reload();
            $mdDialog.hide(updatedInterval);
        }).catch(() => ctrl.interval.disabled = false).finally(() => ctrl.updating = false);
    };

    ctrl.cancel = $mdDialog.cancel;
} ]);
