// @ts-nocheck
import { module } from 'angular';

const template1 = `<div class="budget-info-row">
    <span class="row-text" ng-i18next="scheduling:BUDGET"></span>
    <span class="row-value" ng-bind="$bdCtrl.day.budget | eawCurrency:false:0"></span>
</div>
<div class="budget-info-row">
    <span class="row-text" ng-i18next="scheduling:EFFICIENCY"></span>
    <span class="row-value" ng-bind="$bdCtrl.day.efficiency | eawCurrency: false:0"></span>
</div>
<div class="budget-info-row">
    <span class="row-text" ng-i18next="scheduling:MAN_HOURS"></span>
    <span class="row-value" ng-bind="($bdCtrl.day.work_time / 3600) | eawNumber:2"></span>
</div>
<div class="budget-info-row">
    <span class="row-text" ng-i18next="scheduling:LABOR_COST"></span>
    <span class="row-value" ng-bind="$bdCtrl.day.labor_cost | eawCurrency: false:0"></span>
</div>
<div class="budget-info-row">
    <span class="row-text" ng-i18next="scheduling:LABOR_PERCENT"></span>
    <span class="row-value" ng-bind="$bdCtrl.day.labor_ratio | percent: 2"></span>
</div>
<div class="budget-info-row">
    <span class="row-text" ng-i18next="scheduling:ESTIMATED_NEED"></span>
    <span class="row-value" ng-bind="$bdCtrl.day.need  | roundquarter | eawNumber: 2"></span>
</div>
<div class="budget-info-row">
    <span class="row-text" ng-i18next="scheduling:STAFFING_DIFFERENCE"></span>
    <span class="row-value" ng-bind="$bdCtrl.day.need_diff | roundquarter | eawNumber:2:true"></span>
</div>
`;

module('eaw.scheduling').component('budgetDay', {
    template: template1,
    controllerAs: '$bdCtrl',
    bindings: {
        day: '<',
    },
});
