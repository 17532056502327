import { module } from 'angular';
import { Products } from '../../shared/enums/products';
import { Namespace } from '../../shared/enums/namespace';
import { createState } from '../../shared/utils/create-state';
import { ManualPayrollComponent } from '../pages/manual-payroll/manual-payroll.component';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { staticResolver } from '../../shared/resolvers/static.resolver';
import { CurrentOld } from '../../shared/angularjs/current.factory';
import { PayrollOverviewComponent } from '../pages/payroll-overview/payroll-overview.component';
import { LearningModuleModule } from '../../learning-module/types/modules';
import { PredefinedSettings } from '../../shared/enums/predefined-settings';
import { CurrentService } from '../../shared/services/current.service';
import { forkJoin, map } from 'rxjs';

module('eaw.payroll', [
    'eaw.resource',
    'eaw.misc',
    'eaw.notifications',
    'eaw.scheduling.shifts',

    'eaw.payroll.punch-clock',
    'eaw.payroll.timepunches',
    'eaw.payroll.flexitime',
    'eaw.payroll.paid-time',
]).config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state('eaw/app/payroll', {
        parent: 'eaw/app',
        url: '/payroll',
        abstract: true,
        data: {
            learningModuleModules: [ LearningModuleModule.Payroll ],
            breadcrumb: { key: 'PAYROLL', ns: 'navigation' },
            products: [ Products.Payroll ],
            i18nextNs: [
                Namespace.Payroll,
                Namespace.Absences,
                Namespace.Admin,
                Namespace.Company,
                Namespace.SwissRiskAndCare,
                Namespace.ChainOps,
                Namespace.Digisign,
            ],
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/payroll/overview',
        parent: 'eaw/app/payroll',
        url: '/overview',
        views: {
            'content@': {
                component: PayrollOverviewComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            permissions: [ `customers.{customer}.employees.*.pay_rates.*.get` ],
            products: [ Products.France ],
            requiresEmployee: false,
            breadcrumb: { key: 'PAYROLL_OVERVIEW', ns: Namespace.PayrollOverview },
            settings: {
                [PredefinedSettings.PayrollOverviewEnabled]: (setting) => !!setting?.asBoolean(),
            },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/payroll/manual',
        parent: 'eaw/app/payroll',
        url: '/manual',
        views: {
            'content@': {
                component: ManualPayrollComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            staticResolver('employee', null),
        ],
        data: {
            permissionCheck: (permissionCheckService, injector) => {
                const customerId = injector.get(CurrentService).getCustomer().id;
                return forkJoin([
                    permissionCheckService.isAllowed(`customers.${customerId}.pay_type_links.*.get`),
                    permissionCheckService.permissionChildrenSingle(`customers.${customerId}.employees`, `variable_payments.create`, true),
                ]).pipe(
                    map(([ permissionValue, children ]) => permissionValue && children.length > 0),
                );
            },
            breadcrumb: { key: 'MANUAL_PAYROLL', ns: 'navigation' },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/payroll/my/manual',
        parent: 'eaw/app/payroll/my',
        url: '/manual',
        views: {
            myPayrollView: {
                component: ManualPayrollComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'employee', 'customerId'),
            currentResolver('employee', 'employee'),
        ],
        data: {
            breadcrumb: { key: 'MANUAL_PAYROLL', ns: 'payroll' },
        },
    });

    $stateProvider.state('eaw/app/payroll/my', {
        parent: 'eaw/app/payroll',
        url: '/my',
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            requiresEmployee: true,
            breadcrumb: { key: 'MY_PAYROLL', ns: 'navigation' },
        },
        resolve: {
            navbarRoute: () => 'eaw/app/payroll/my',
            viewName: () => 'myPayrollView',
            tabs: function() {
                const customerId = CurrentOld.getCustomer()['id'];
                const employeeId = CurrentOld.getEmployee()?.id || 0;

                const tabs = [
                    {
                        label: 'payroll:TIMEPUNCH_plural',
                        src: 'eaw/app/payroll/my/timepunches',
                        permission: `customers.${customerId}.employees.${employeeId}.timepunches.*.get`,
                    }, {
                        label: 'payroll:MANUAL_PAYROLL',
                        src: 'eaw/app/payroll/my/manual',
                        permission: `customers.${customerId}.employees.${employeeId}.variable_payments.*.get`,
                    },
                ];

                if (CurrentOld.hasProduct(Products.PaidTime)) {
                    tabs.push({
                        label: 'navigation:PAID_TIME',
                        src: 'eaw/app/payroll/paid_time/my',
                        permission: `customers.${customerId}.employees.${employeeId}.paid_times.*.get`,
                    });
                }

                if (CurrentOld.hasProduct(Products.FlexiTime)) {
                    tabs.push({
                        label: 'navigation:FLEXITIME',
                        src: 'eaw/app/payroll/my/flexitime',
                        permission: `customers.${customerId}.employees.${employeeId}.flexitime.*.get`,
                    });
                }

                return tabs;
            },
        },
    });
} ]);
