import { DateTime } from 'luxon';
import { ApiResponse } from '../../shared/interfaces/api-response';

export type ShiftConfigurationWeekdays = (0 | 1 | 2 | 3 | 4 | 5 | 6)[];

export interface ShiftConfigurationResponse extends ApiResponse {
    id: string;
    name: string;
    weekdays: ShiftConfigurationWeekdays;
    hour_start: number;
    minute_start: number;
    hour_end: number;
    minute_end: number;
}

export class ShiftConfiguration {
    id: string;
    name: string;
    weekdays: ShiftConfigurationWeekdays;
    from: DateTime;
    to: DateTime;

    constructor(data: ShiftConfigurationResponse) {
        this.id = data.id;
        this.name = data.name;
        this.weekdays = !data.weekdays.length ? [ 0, 1, 2, 3, 4, 5, 6 ] : data.weekdays;
        this.from = DateTime.fromObject({
            year: 2000,
            hour: data.hour_start,
            minute: data.minute_start,
        });
        this.to = DateTime.fromObject({
            year: 2000,
            hour: data.hour_end,
            minute: data.minute_end,
        });
    }
}
