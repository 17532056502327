import { TextPortalComponent } from '../portals/text-portal/text-portal.component';
import { DataTableFunctionValue, DataTableLoading, DataTableValue } from '../interfaces/data-table-value';
import { DataTableColumn, DataTableColumnAttributes } from './data-table-column';
import { DataTableHeader } from './data-table-header';

type TextColumnValue = string | null | undefined;

interface IDataTableTextColumn<Item> extends DataTableColumnAttributes<DataTableTextColumn<Item>, Item, TextColumnValue>{
    // This is used as a column identifier
    key?: string;
    header: DataTableHeader;
    value: DataTableValue<DataTableTextColumn<Item>, Item, TextColumnValue>,
    subText?: DataTableFunctionValue<DataTableTextColumn<Item>, Item, string | undefined>,
    loading?: DataTableLoading<DataTableTextColumn<Item>, Item>;
}

export class DataTableTextColumn<Item> extends DataTableColumn<DataTableTextColumn<Item>, Item, TextColumnValue> {
    component = TextPortalComponent;
    override loading?: DataTableLoading<DataTableTextColumn<Item>, Item>;

    constructor(column: IDataTableTextColumn<Item>) {
        super({
            exportable: true,
            ...column,
        });
    }
}
