@if (loading()) {
    <eaw-info-loading size="lg"></eaw-info-loading>
} @else {
    @for (alert of alerts; track alert.id) {
        <div>
            <div class="tw-flex tw-justify-between tw-items-center">
                <h3>{{alert.specifics?.employee_name}}</h3>
                <button mat-icon-button (click)="goToContract(alert.subject_id)" [matTooltip]="('CHANGE_CONTRACT' | translate: 'company' | async)!">
                    <mat-icon>exit_to_app</mat-icon>
                </button>
            </div>
            <p>{{alert.message}}</p>
        </div>
    } @empty {
        <h3>{{'NO_DATA' | translate | async}}</h3>
    }
}
