import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import type { GroupResponse } from '../interfaces/group';
import { ApiResponse } from '../interfaces/api-response';
import { BaseApiModel } from './base-api-model';

export interface GroupMembershipResponse<MemberResponse extends ApiResponse> extends ApiResponse {
    id: number,
    group_id: number
    member_id: number
    member_type: string
    member?: MemberResponse
    from: string
    to?: string | null
    created_at?: string | null
    updated_at?: string | null
    deleted_at?: string | null

    readonly _dates?: string[]
    readonly _business_dates?: string[]
}

export class GroupMembership<Member extends BaseApiModel<ApiResponse, Member>, MemberResponse extends ApiResponse> extends BaseApiModel<GroupMembershipResponse<MemberResponse>, GroupMembership<Member, MemberResponse>> {
    id: number;
    groupId: number;
    memberId: number;
    memberType: string;
    from: DateTime;
    to?: DateTime | null;
    member?: Member;
    group?: GroupResponse<MemberResponse>;
    createdAt?: DateTime | null;
    updatedAt?: DateTime | null;
    deletedAt?: DateTime | null;

    constructor(data: GroupMembershipResponse<MemberResponse>, constructor?: new (data: any) => Member) {
        super(data, undefined);

        this.id = data.id;
        this.groupId = data.group_id;
        this.memberId = data.member_id;
        this.memberType = data.member_type;
        this.member = data.member && constructor ? new constructor(data.member) : undefined;
        this.from = stringToDateTime(data.from);
        this.to = data.to ? stringToDateTime(data.to) : null;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }

    isActive() {
        // Use the to we have or create a fake to in the future that can't be inactive
        const to = this.to || DateTime.now().plus({ years: 100 });
        return !(this.from > DateTime.now() || to < DateTime.now());
    }
}
