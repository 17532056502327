import { module } from 'angular';
import { PeopleKpiComponent } from './people-kpi/people-kpi.component';
import { Products } from '../shared/enums/products';
import { MetricComponent } from './pages/metric/metric.component';
import { createState } from '../shared/utils/create-state';
import { LearningModuleModule } from '../learning-module/types/modules';
import { DataTableQueryParams } from '../data-table/data-table.component';

module('eaw.people-kpi', []).config([ '$stateProvider', function($stateProvider: any) {
    createState($stateProvider, {
        name: 'eaw/app/people_kpi',
        parent: 'eaw/app',
        url: '/people_kpi',
        views: {
            'content@': {
                component: PeopleKpiComponent,
            },
        },
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'PEOPLE_KPI', ns: 'navigation' },
            learningModuleModules: [ LearningModuleModule.McdPeopleKpi ],
            products: [ Products.PeopleKpi ],
            permissions: [ `customers.{customer}.people_kpis.get` ],
        },
    });

    $stateProvider.state('eaw/app/admin/organizations/metric', {
        parent: 'eaw/app/admin/organizations',
        url: '/metric',
        views: {
            'content@': {
                component: MetricComponent,
            },
        },
        data: {
            breadcrumb: { key: 'METRIC', ns: 'navigation' },
        },
    });
} ]);
