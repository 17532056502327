import { t } from 'i18next';
import { module } from 'angular';
import { Storage } from '../../../../../shared/utils/storage';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
import { MatDialog } from '@angular/material/dialog';
import { WysiwygDialogComponent, WysiwygDialogData, WysiwygDialogReturn } from '../../../../../wysiwyg/dialogs/wysiwyg-dialog/wysiwyg-dialog.component';
import { Bulletin } from '../../../../../shared/models/bulletin';
import { EMPTY, from, tap } from 'rxjs';

module('eaw.dashboard').component('postitsWidget', {
    // Even though the template is empty, it is needed, or the component creator won't work 🤷‍♂️
    template: ``,
    require: {
        eawWidget: '^eawWidget',
    },
    controller: [ 'MatDialogDowngrade', '$element', '$scope', 'PostitFactory', 'componentCreatorService', function(MatDialogDowngrade: MatDialog, $element, $scope, PostitFactory, componentCreatorService) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = async () => {
            ctrl.key = Storage.prefix('widgets:postits:columns');
            ctrl.changeColumns((await Storage.getItem<number>(ctrl.key)) ?? 1);
            ctrl.getPostits();
            ctrl.createButtons();
        };
        ctrl.$onDestroy = () => {
            ctrl.postitRes?.$cancelRequest?.();
        };
        ctrl.createButtons = () => {
            const buttons = [ {
                icon: 'refresh',
                click: () => ctrl.getPostits(1, true),
            } ];
            if (CurrentOld.can(`customers.${ctrl.eawWidget.customer.id}.postits.create`)) {
                buttons.unshift({
                    icon: 'add',
                    click: ctrl.newPostit,
                });
            }
            buttons.push({
                // @ts-ignore
                type: 'menu',
                icon: 'view_column',
                items: new Array(5).fill(0).map((_, index) => {
                    return {
                        click: () => ctrl.changeColumns(index + 1),
                        text: t('widgets:X_COLUMN', {
                            count: index + 1,
                        }),
                    };
                }),
            });
            ctrl.eawWidget.setButtons(buttons);
        };
        ctrl.getPostits = (page = 1, refresh: any) => {
            if (page === 1) {
                ctrl.eawWidget.setLoading(true);
            }
            if (refresh) {
                Array.from($element[0].children).forEach((c: any) => c.remove());
            }
            ctrl.postitRes = PostitFactory.getAll(ctrl.eawWidget.customer.id, page, 4, [ 'user' ]);
            ctrl.postitRes.$promise.then((data: any) => {
                ctrl.currentPage = data.current_page;
                ctrl.perPage = data.per_page;
                ctrl.lastPage = data.current_page === data.last_page;
                ctrl.postits = data.data;
                ctrl.postits.forEach((p: any) => {
                    const el = ctrl.createPostitEl(p);
                    $element[0].appendChild(el);
                });
                if (ctrl.currentPage === 1 && !ctrl.postits.length) {
                    ctrl.eawWidget.setEmpty(true);
                } else {
                    ctrl.mutationObserver();
                    ctrl.addProgress();
                }
            }).finally(() => {
                ctrl.eawWidget.setLoading(false);
            });
        };
        ctrl.createPostitEl = (postit: any) => {
            const component = componentCreatorService.create({
                name: 'postit',
                scope: $scope,
                bindings: { postit },
                parentElement: $element[0],
                transcludeControllers: { postitsWidget: { instance: ctrl } },
            });
            component.element.dataset.page = ctrl.currentPage;
            component.element.dataset.id = postit.id;
            return component.element;
        };
        ctrl.getPostitEl = (postit: any) => $element[0].querySelector(`postit[data-id="${postit.id}"]`);
        ctrl.updatePostit = (postit: any) => {
            const oldEl = ctrl.getPostitEl(postit);
            if (!oldEl) {
                return;
            }
            const newEl = ctrl.createPostitEl(postit);
            newEl.dataset.page = oldEl.dataset.page;
            $element[0].replaceChild(newEl, oldEl);
        };
        ctrl.removePostit = (postit: any) => {
            ctrl.getPostitEl(postit)?.remove();
        };
        ctrl.addProgress = () => {
            Array.from($element[0].querySelectorAll('md-progress-circular')).forEach((el: any) => el.remove());
            if (!ctrl.lastPage) {
                const progress = componentCreatorService.create({
                    name: 'mdProgressCircular',
                    scope: $scope,
                }).element;
                progress.classList.add('md-progress-center', 'm-t-20');
                $element[0].appendChild(progress);
            }
        };
        ctrl.mutationObserver = () => {
            const muob = new MutationObserver((_, ob) => {
                if (ctrl.getLastPosititEl(ctrl.currentPage)) {
                    ob.disconnect();
                    ctrl.intersectionObserver();
                }
            });
            muob.observe($element[0], {
                childList: true,
                subtree: true,
            });
        };
        ctrl.intersectionObserver = () => {
            // Disconnect previous one
            ctrl.intOb?.disconnect?.();
            ctrl.intOb = new IntersectionObserver(function(entry) {
                const rect = entry[0]?.intersectionRect;
                if (rect?.width && rect?.height) {
                    ctrl.getPostits(ctrl.currentPage + 1);
                }
            }, {
                root: $element[0],
                rootMargin: '0px',
                threshold: 0.1,
            });
            ctrl.intOb.observe(ctrl.getLastPosititEl(ctrl.currentPage));
        };
        ctrl.getLastPosititEl = (page: any) => $element[0].querySelectorAll(`postit[data-page="${page}"]`)[ctrl.perPage - 1];
        ctrl.changeColumns = (amount = 1) => {
            $element[0].style.setProperty('--postits-cols', amount);
            void Storage.setItem(ctrl.key, amount);
        };
        ctrl.newPostit = () => {
            MatDialogDowngrade.open<WysiwygDialogComponent<Bulletin>, WysiwygDialogData<Bulletin>, WysiwygDialogReturn<Bulletin>>(WysiwygDialogComponent, {
                data: {
                    title: Promise.resolve(t('widgets:NEW_POSTIT')),
                    submitText: Promise.resolve(t('widgets:PUBLISH_POSTIT')),
                    submitFn: (body) => {
                        if (!body) {
                            return EMPTY;
                        }

                        return from(PostitFactory.create(body, ctrl.eawWidget.customer.id, [ 'user' ]).$promise as Promise<any>).pipe(
                            tap((postit) => {
                                const el = ctrl.createPostitEl(postit);
                                el.dataset.page = 1;
                                $element[0].prepend(el);
                            }),
                        );
                    },
                },
            });
        };
    } ],
});
