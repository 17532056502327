import { Observable, reduce } from 'rxjs';
import { PaginationOptions } from '../../interfaces/pagination-options';
import { expandPages, ExpandPagesCallback } from './expand-pages';
import { ArrayPaginatedResponse, AssocPaginatedResponse } from '../../interfaces/paginated-response';

/**
 * @template Return
 * @param {ExpandPagesCallback<Return, PaginationOptions>} callback Takes the returned pagination as an argument and expect an observable that uses that pagination to fetch the next page.
 * @param {PaginationOptions} initialPagination Initial pagination.
 * @param {number} concurrency Is passed through to {@link expandPages}
 * @returns {Observable<Return[]>}
 */
export function expandAllPages<Return, Pagination extends PaginationOptions>(callback: ExpandPagesCallback<Return, Pagination, ArrayPaginatedResponse<Return>>, initialPagination: Pagination, concurrency = 4): Observable<Return[]> {
    return expandPages<Return, Pagination, ArrayPaginatedResponse<Return>>(callback, initialPagination, concurrency).pipe(
        reduce((acc, response: ArrayPaginatedResponse<Return>) => {
            return acc.concat(response.data);
        }, [] as Return[]),
    );
}

export function expandAllAssocPages<Return, Pagination extends PaginationOptions>(callback: ExpandPagesCallback<Return, Pagination, AssocPaginatedResponse<Return>>, initialPagination: Pagination, concurrency = 4): Observable<[ string, Return ][]> {
    return expandPages<Return, Pagination, AssocPaginatedResponse<Return>>(callback, initialPagination, concurrency).pipe(
        reduce((acc, response: AssocPaginatedResponse<Return>) => {
            return acc.concat(Object.entries(response.data));
        }, [] as [ string, Return ][]),
    );
}
