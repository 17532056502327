import { element, module } from 'angular';

module('eaw.components').component('eawAccordion', {
    template: `<ng-transclude></ng-transclude>`,
    controllerAs: '$accordion',
    transclude: true,
    bindings: {
        multiToggle: '<?',
    },
    controller: [ '$element', function($element) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.element = $element[0];
        };

        ctrl.getPanelController = (el: any) => element(el).controller('eawAccordionPanel');

        /**
         * @returns {Object[]}
         */
        ctrl.getPanels = () => Array.from(ctrl.element.querySelectorAll('eaw-accordion-panel')).map((el) => {
            return {
                el,
                controller: ctrl.getPanelController(el),
            };
        });
    } ],
});
