import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { DateTime } from 'luxon';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { Kpi, KpiResponse } from '../models/kpi';
import { KpiProviderSum, KpiProviderSumResponse } from '../models/kpi-provider-sum';
import { DateTimeConverter } from '../../shared/utils/date-time-converter';
import { Customer, CustomerResponse } from '../../shared/models/customer';
import { KpiType, KpiTypeResponse } from '../models/kpi-type';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';

export interface GetAllKpisResponse<Kpi, Provider> extends ArrayPaginatedResponse<Kpi> {
    provider_sums?: Record<string, Provider> | null;
}

export interface OverviewResponse {
    customers: CustomerResponse[];
    kpi_data: KpiResponse[];
    kpi_types: KpiTypeResponse[];
    provider_sums: KpiProviderSumResponse[];
}

export interface GetAllOptions extends PaginationOptions {
    from?: DateTime;
    to?: DateTime;
    provider_sums?: boolean,
}

@Injectable({
    providedIn: 'root',
})
export class KpiService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, options?: GetAllOptions): Observable<GetAllKpisResponse<Kpi, KpiProviderSum>> {
        return this.http.get<GetAllKpisResponse<KpiResponse, KpiProviderSumResponse>>(`customers/${customerId}/kpis`, {
            params: formatHttpParams(options, [ 'from', 'to' ]),
        }).pipe(
            map((resp) => {
                return {
                    ...resp,
                    data: resp.data.map((s) => new Kpi(s)),
                    provider_sums: Object.entries(resp.provider_sums || {}).reduce((obj, [ key, value ]) => {
                        obj[key] = new KpiProviderSum(value);
                        return obj;
                    }, {} as Record<string, KpiProviderSum>),
                };
            }),
        );
    }

    getOverview(from: DateTime, to: DateTime, kpiTypes: number[] = []) {
        return this.http.get<OverviewResponse>(`customers/u/kpis/overview`, {
            params: formatHttpParams({
                from: DateTimeConverter.convertDateTimeToBusinessDate(from),
                to: DateTimeConverter.convertDateTimeToBusinessDate(to),
                'kpi_types[]': kpiTypes,
            }),
        }).pipe(
            map((resp) => {
                return {
                    customers: resp.customers.map((c) => new Customer(c)),
                    kpiData: resp.kpi_data.map((kpi) => new Kpi(kpi)),
                    kpiTypes: resp.kpi_types.map((type) => new KpiType(type)),
                    providerSums: resp.provider_sums.map((p) => new KpiProviderSum(p)),
                };
            }),
        );
    }

    getProviderSums(customerId: number, from: DateTime, to: DateTime) {
        return this.getAll(customerId, {
            from,
            to,
            per_page: 1,
        }).pipe(
            map((result) => {
                return result.provider_sums;
            }),
        );
    }

    recalculate(customerId: number, businessDate: DateTime) {
        return this.http.post(`/customers/${customerId}/kpis/${DateTimeConverter.convertDateTimeToBusinessDate(businessDate)}/recalculate`, {});
    }
}
