// @ts-nocheck
import { module } from 'angular';

import { Products } from '../shared/enums/products';
import { Namespace } from '../shared/enums/namespace';

module('eaw.tbs', [
    'eaw.time',
]).config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state('eaw/app/tbs', {
        parent: 'eaw/app',
        abstract: true,
        url: '/tbs',
        data: {
            products: [ Products.TBSLite ],
            i18nextNs: [ Namespace.TBS ],
            breadcrumb: { key: 'TBS' },
        },
    });

    $stateProvider.state('eaw/app/tbs/punch-proposals', {
        parent: 'eaw/app/tbs',
        url: '/time-attendance',
        data: {
            breadcrumb: { key: 'TBS_PUNCH_PROPOSALS', ns: 'navigation' },
            permissions: [ 'customers.{customer}.employees.*.punch_proposals.*.get' ],
        },
        views: {
            'content@': {
                component: 'managePunchProposals',
            },
        },
    });
} ]);
