// @ts-nocheck
import { module } from 'angular';

module('eaw.filesystem').controller('fsBsSortingCtrl', [ '$mdBottomSheet', function($mdBottomSheet) {
    const ctrl = this;

    ctrl.menuItemClick = (field) => {
        $mdBottomSheet.hide(field);
        ctrl.sortingFunction(field);
    };
} ]);
