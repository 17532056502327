@for (bulletin of bulletins(); track bulletin) {
    <div class="bulletin mat-elevation-z2">
        <div class="header">
            <eaw-profile-picture [user]="bulletin.user"></eaw-profile-picture>

            <div class="titles">
                <div class="title">{{bulletin.user?.name}}</div>
                <div class="subtitle">{{bulletin.updated_at | DateTime:'DATETIME_SHORT'}}</div>
            </div>

            <span class="tw-flex-1"></span>

            <button mat-icon-button [matMenuTriggerFor]="bulletinOptions" [matMenuTriggerData]="{bulletin: bulletin}">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>

        <eaw-markdown [text]="bulletin.body" class="text"></eaw-markdown>
    </div>
}

<mat-progress-spinner #moreSpinner id="more-spinner" mode="indeterminate" [class.tw-hidden]="!hasMore()"/>

<mat-menu #bulletinOptions="matMenu">
    <ng-template matMenuContent let-bulletin="bulletin">
        <button mat-menu-item (click)="editBulletin(bulletin)">Edit</button>
        <button mat-menu-item (click)="deleteBulletin(bulletin)">Delete</button>
    </ng-template>
</mat-menu>
