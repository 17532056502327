import { module } from 'angular';

class NightWorkWidgetDowngrade {
    $element;

    static $inject = [ '$element' ];

    mini: any;

    constructor($element: any) {
        this.$element = $element;
    }

    loaded() {
        this.mini.loading(false);
        this.mini.dialogComponents = [ {
            name: 'nightWorkerExtendedInfoContainer',
            label: 'NIGHT_WORKER_plural',
        } ];
    }
}

module('eaw.dashboard').component('nightWorkerWidgetContainer', {
    template: `
    <night-worker-widget-downgrade (loaded)="$widget.loaded()"></night-worker-widget-downgrade>
    `,
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$widget',
    controller: NightWorkWidgetDowngrade,
});
