// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';

module('eaw.time').controller('dateTimePickerDialogCtrl', [ 'dateTime', 'label', 'options', '$mdDialog', '$scope', function dateTimePickerDialogCtrl(dateTime, label, options, $mdDialog, $scope) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.label = t(label);

        // Create date and time
        ctrl.setDate(dateTime);
        ctrl.setTime(dateTime);

        // Options
        ctrl.minDate = options?.minDate?.clone();
        ctrl.maxDate = options?.maxDate?.clone();

        // Add listener so we can submit on enter
        document.addEventListener('keyup', ctrl.checkSubmit);

        // Destroy listener when scope is destroyed
        $scope.$on('$destroy', ctrl.onDestroy);
    };

    ctrl.onDestroy = () => {
        document.removeEventListener('keyup', ctrl.checkSubmit);
    };

    ctrl.setDate = (dateTime) => {
        ctrl.date = moment.isMoment(dateTime) ? moment().startOf('d').year(dateTime.year()).month(dateTime.month()).date(dateTime.date()) : undefined;
    };

    ctrl.setTime = (dateTime) => {
        ctrl.time = moment.isMoment(dateTime) ? moment().startOf('d').hour(dateTime.hour()).minute(dateTime.minute()).second(dateTime.second()) : moment().startOf('d');
    };

    ctrl.isDisabled = () => !(ctrl.date && ctrl.time);

    ctrl.checkSubmit = (event) => {
        const key = event.keyCode || event.which;
        const disabled = ctrl.isDisabled();
        const enterKey = key === 13;

        if (!disabled && enterKey) {
            ctrl.submit();
        }
    };

    ctrl.submit = () => {
        // Blur focused element so that models are updated
        if (document.activeElement instanceof HTMLInputElement) {
            document.activeElement.blur();
        }

        $mdDialog.hide(ctrl.date.hour(ctrl.time.hour()).minute(ctrl.time.minute()).second(ctrl.time.second()));
    };

    ctrl.cancel = $mdDialog.cancel;
} ]);
