// @ts-nocheck
import { clone, isObject } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
/**
 * Extends ngModel with a function that (almost) always causes a digest cycle,
 * for use when manually setting view value, instead of $setViewValue.
 * Replaces service setViewValue (from eaw.misc)
 */
module('eaw.decorators').decorator('ngModelDirective', [ '$delegate', function($delegate) {
    // EawNgModel just extends the interface to include setViewValue
    // @ts-ignore
    $delegate[0].controller.prototype.setViewValue = function(value) {
        let val;
        if (moment.isMoment(value)) {
            val = value.clone();
        } else if (value instanceof Date) {
            val = new Date(+value);
        } else if (Array.isArray(value)) { // Array first, because array, moment and date are also objects.
            val = [ ...value ];
        } else if (isObject(value)) {
            val = clone(value);
        } else {
            val = value;
        }
        this.$setViewValue(val);
        return val;
    };
    return $delegate;
} ]);
