import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HrOverview, HrOverviewResponse } from '../models/hr-overview';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class HrOverviewService {

    constructor(@Inject(HttpClient) private http: HttpClient) {}

    get(customerId: number, options?: { all_types?: boolean, include_inactive?: boolean }): Observable<HrOverview> {
        return this.http.get<HrOverviewResponse>(`customers/${customerId}/hr_overview`, {
            params: { ...options },
        }).pipe(classifyItem(HrOverview));
    }
}
