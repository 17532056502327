import { ApiModel } from '../shared/enums/api-model';
import { PermissionModel } from '../shared/services/permission-check.service';

export function customerPermissionModel(id: number): PermissionModel {
    return { type: ApiModel.Customer, id };
}

export function employeePermissionModel(id: number): PermissionModel {
    return { type: ApiModel.Employee, id };
}

export function commentPermissionModel(id: number): PermissionModel {
    return { type: ApiModel.Comment, id };
}
