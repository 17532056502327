import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { MeService } from '../../shared/http/me.service';
import { EawUrl } from '../../shared/angularjs/modules/resource/url.service';
import { map, Observable, shareReplay, Subject, takeUntil, tap } from 'rxjs';
import { ApiToken } from '../../shared/models/api-token';
import { CurrentService } from '../../shared/services/current.service';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { AsyncPipe } from '@angular/common';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { MatButton, MatIconButton } from '@angular/material/button';
import { ActionButtonComponent } from '../../shared/components/action-button/action-button.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { TranslateSyncPipe } from '../../shared/pipes/translate-sync.pipe';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MeCustomer } from '../../shared/models/me';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { MatTooltip } from '@angular/material/tooltip';
import { loadNamespaces } from 'i18next';
import { Namespace } from '../../shared/enums/namespace';

interface ICalendarSyncForm {
    customerIds: FormControl<number[]>;
    eventTypes: FormControl<string[]>;
}

@Component({
    selector: 'eaw-icalendar-sync',
    standalone: true,
    imports: [
        MatCard,
        MatCardHeader,
        MatCardTitle,
        MatCardContent,
        MatLabel,
        MatCardSubtitle,
        AsyncPipe,
        TranslatePipe,
        MatButton,
        ActionButtonComponent,
        FormsModule,
        MatFormField,
        MatSelect,
        MatOption,
        ReactiveFormsModule,
        TranslateSyncPipe,
        MatInput,
        MatSuffix,
        MatIcon,
        MatHint,
        MatIconButton,
        MatTooltip,
    ],
    templateUrl: './i-calendar-sync.component.html',
    styleUrl: './i-calendar-sync.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ICalendarSyncComponent implements OnInit, OnDestroy {
    private readonly meService = inject(MeService);
    private readonly current = inject(CurrentService);
    private readonly snackbar = inject(SnackBarService);

    private onDestroy = new Subject<void>();
    private getToken?: Observable<ApiToken>;

    protected url = signal('');
    protected form: FormGroup<ICalendarSyncForm>;

    customers = signal<MeCustomer[]>([]);
    urlGenerated = signal(false);

    constructor() {
        this.form = new FormGroup<ICalendarSyncForm>({
            customerIds: new FormControl<number[]>([], { nonNullable: true, validators: Validators.required }),
            eventTypes: new FormControl<string[]>([], { nonNullable: true, validators: Validators.required }),
        });

        this.form.valueChanges.pipe(
            tap(() => {
                this.url.set('');
            }),
            takeUntil(this.onDestroy.asObservable()),
        ).subscribe();
    }

    async ngOnInit() {
        this.customers.set(Object.values(this.current.getCustomers()));
        this.form.patchValue({
            customerIds: [ this.current.getCustomer().id ],
        });

        await loadNamespaces([ Namespace.Navigation ]);
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    generateUrl() {
        this.getCalendarToken().pipe(
            map((token) => {
                return EawUrl.getUrl(`/calendar?api_token=${token.apiToken}`);
            }),
            tap((url) => {
                let calendarUrl = url;

                if (this.form.value.customerIds?.length) {
                    calendarUrl += `&customer_ids[]=${this.form.value.customerIds.join('&customer_ids[]=')}`;
                }

                if (this.form.value.eventTypes?.length) {
                    calendarUrl += `&types[]=${this.form.value.eventTypes.join('&types[]=')}`;
                }

                this.url.set(calendarUrl);
                this.urlGenerated.set(true);
            }),
            takeUntil(this.onDestroy.asObservable()),
        ).subscribe();
    }

    private getCalendarToken() {
        if (!this.getToken) {
            this.getToken = this.meService.getCalendarToken().pipe(shareReplay(1));
        }

        return this.getToken;
    }

    async copyUrl() {
        if (this.url()) {
            await navigator.clipboard.writeText(this.url());
            void this.snackbar.t('COPIED_TO_CLIPBOARD');
        } else {
            void this.snackbar.t('COULNDT_COPY_TO_CLIPBOARD');
        }
    }
}
