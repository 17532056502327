import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface LinkResponse extends ApiResponse {
    text: string;
    url: string;
    description?: string;
    main_menu?: boolean;
    inheritable: boolean;
    customer_id: number;
    id: number;
    created_at: string;
    updated_at: string;
}

export class Link {

    text: string;
    url: string;
    description?: string;
    inheritable: boolean;
    customerId: number;
    id: number;
    createdAt: DateTime;
    updatedAt: DateTime;
    mainMenu: boolean;

    constructor(data: LinkResponse) {
        this.text = data.text;
        this.url = data.url;
        this.description = data.description;
        this.inheritable = data.inheritable;
        this.customerId = data.customer_id;
        this.id = data.id;
        this.mainMenu = !!data.main_menu;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
    }
}
