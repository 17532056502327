import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrentService } from '../../../shared/services/current.service';
import { Comment } from '../../../shared/models/comment';
import { EmployeeCommentService } from '../../http/employee-comment.service';
import { EMPTY, lastValueFrom, Subscription, switchMap, tap } from 'rxjs';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { AddEmployeeNoteComponent, AddEmployeeNoteData } from '../../dialogs/add-employee-note/add-employee-note.component';
import { Products } from '../../../shared/enums/products';
import { Employee } from '../../../shared/models/employee';
import { EmployeeService } from '../../../shared/http/employee.service';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { CommentListComponent } from '../../../shared/components/comment-list/comment-list.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
    selector: 'eaw-employee-notes',
    templateUrl: './employee-notes.component.html',
    styleUrl: './employee-notes.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatProgressSpinnerModule,
        MatCardModule,
        CommentListComponent,
        MatPaginatorModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmployeeNotesComponent implements OnInit, OnDestroy {
    // Injected by parent.
    @Input({ required: true }) employeeId!: number;
    @Input({ required: true }) customerId!: number;

    pages: Map<number, Comment[]> = new Map<number, Comment[]>();
    perPage = 10;
    page = 1;
    total = 0;
    employee!: Employee;
    fabButton: HeaderFabButton = {
        click: this.addComment.bind(this),
        hasPermission: this.canAddComment.bind(this),
    };

    loading = true;
    loaded = false;

    stackId!: number;

    private subscription?: Subscription;

    constructor(
        @Inject(CurrentService) protected current: CurrentService,
        @Inject(EmployeeCommentService) protected commentService: EmployeeCommentService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(MatDialog) protected dialog: MatDialog,
        @Inject(ConfirmDialogService) protected confirm: ConfirmDialogService,
        @Inject(SnackBarService) protected snackbar: SnackBarService,
        @Inject(CustomerProductService) protected customerProductService: CustomerProductService,
        @Inject(EmployeeService) protected employeeService: EmployeeService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        try {
            this.employee = await lastValueFrom(this.employeeService.get(this.customerId, this.employeeId, { 'with[]': [ 'warnings', 'region', 'role', 'customer' ] }));
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.stackId = this.employee.customer!.stackId;
        } catch (_) {
            throw new Error('Failed to get employee');
        }

        const perPage: number | null = await this.current.retrieve<number>('employee-notes:per_page', 'default');
        if (perPage) {
            this.perPage = perPage;
        }

        this.getComments(1, this.perPage);
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    canAddComment() {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.comments.create`);
    }

    addComment() {
        this.dialog.open<AddEmployeeNoteComponent, AddEmployeeNoteData, boolean>(AddEmployeeNoteComponent,
            {
                data: {
                    employee: this.employee,
                    showHrOption: this.customerProductService.hasProducts(this.customerId, [ Products.HumanResources ]),
                    user: this.current.getUser(),
                },
            })
            .afterClosed()
            .subscribe((created) => {
                if (!created) {
                    return;
                }

                this.snackbar.created();
                this.resetPages();
                this.getComments(1, this.perPage);
            });
    }

    pageChanged(event: PageEvent) {
        if (this.perPage != event.pageSize) {
            this.resetPages();
        }

        const page: number = event.pageIndex + 1;

        this.perPage = event.pageSize;
        if (this.pages.get(page)) {
            this.page = page;

            // We already have this page
            return;
        }

        this.getComments(page, event.pageSize);
    }

    getComments(page: number, per_page: number) {
        this.loading = true;
        this.subscription = this.commentService.getAll(this.customerId, this.employeeId, { 'with[]': [ 'user' ], page, per_page }).subscribe({
            next: (resp) => {
                if (resp.per_page != String(per_page)) {
                    return;
                } // We've done a new request presumably.

                this.page = page;
                this.total = resp.total;
                if (this.pages.size > 32) {
                    this.resetPages();
                }

                this.pages.set(resp.current_page, resp.data);
            },
            complete: () => {
                this.loaded = true;
                this.loading = false;
            },
            error: () => {
                this.loading = false;
            },
        });
    }

    canDelete = (commentId: number) => {
        return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].employees.[${ApiModel.Employee}].comments.${commentId}.delete`, {
            stackId: this.stackId,
            models: [
                { type: ApiModel.Comment, id: commentId },
                { type: ApiModel.Customer, id: this.customerId },
                { type: ApiModel.Employee, id: this.employeeId },
            ],
        });
    };

    deleteComment = (commentId: number) => {
        return this.confirm.delete().afterClosed().pipe(
            switchMap((res) => {
                if (!res?.ok) {
                    return EMPTY;
                }

                return this.commentService.delete(this.customerId, this.employeeId, commentId).pipe(
                    tap(() => {
                        this.snackbar.deleted();
                        this.resetPages();
                        this.getComments(1, this.perPage);
                    }),
                );
            }),
        );
    };

    private resetPages(): void {
        this.pages = new Map<number, Comment[]>();
    }
}
