import { inject, Injectable } from '@angular/core';
import { TranslateService } from '../../shared/services/translate.service';
import { Namespace } from '../../shared/enums/namespace';
import { t } from 'i18next';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { CustomerProductService } from '../../shared/http/customer-product.service';
import { Products } from '../../shared/enums/products';
import { PermissionCheckService } from '../../shared/services/permission-check.service';
import { ApiModel } from '../../shared/enums/api-model';
import { SchedulePropertyService } from '../http/schedule-property.service';
import { canGetSchedulePermission } from '../permissions';

type TabCheckType = 'specific' | 'general';

export interface ScheduleTab {
    /**
     * Easy readable human name of tab
     */
    name: 'schedule' | 'week_overview' | 'employees' | 'budget' | 'efficiency' | 'total' | 'autogen' | 'warnings';
    label: Promise<string>;
    /**
     * @deprecated
     */
    syncLabel: string;
    hasProducts: Observable<boolean>;
    /**
     * Whether the user has access to this specific tab
     */
    hasSpecificAccess: Observable<boolean>;
    /**
     * Whether the user has access to this tab in general
     */
    hasGeneralAccess: Observable<boolean>;
    skipIfTemplate: boolean;
    /**
     * @deprecated
     * Name of AngularJS component
     */
    component: string;
}

@Injectable({
    providedIn: 'root',
})
export class ScheduleTabsService {
    private translate = inject(TranslateService);
    private customerProductService = inject(CustomerProductService);
    private permissionCheckService = inject(PermissionCheckService);
    private schedulePropertyService = inject(SchedulePropertyService);

    /**
     * Get tabs for a specific schedule
     */
    getScheduleTabs(stackId: number, customerId: number, scheduleId: number, isTemplate: boolean) {
        return this.getTabs(stackId, customerId, scheduleId, isTemplate, 'specific');
    }

    /**
     * Get all tabs a user can access for an arbitrary schedule
     */
    getAllowedTabs(stackId: number, customerId: number) {
        return this.getTabs(stackId, customerId, null, false, 'general');
    }

    private canGetSomeSchedules(customerId: number, stackId: number) {
        const permissionOptions = {
            stackId,
            models: [
                { id: customerId, type: ApiModel.Customer },
            ],
        };

        return this.permissionCheckService.permissionOrChildren(`customers.[${ApiModel.Customer}].schedules.*.get`, permissionOptions, `customers.${customerId}.schedules`, 'get', true);
    }

    private canGetSchedule(stackId: number, customerId: number, scheduleId: number | null) {
        return scheduleId === null ? of(false) : this.permissionCheckService.isAllowed(...canGetSchedulePermission(stackId, customerId, scheduleId));
    }

    private toObservable(tab: ScheduleTab, type: TabCheckType) {
        const accessCheck = type === 'specific' ? tab.hasSpecificAccess : tab.hasGeneralAccess;

        return forkJoin([
            tab.hasProducts,
            accessCheck,
        ]).pipe(
            map(([ hasProducts, hasAccess ]) => hasProducts && hasAccess ? [ tab ] : []),
        );
    }

    private getTabs(stackId: number, customerId: number, scheduleId: number | null, isTemplate: boolean, type: TabCheckType) {
        const hasSchedulingProduct = this.customerProductService.hasProducts(customerId, [ Products.Scheduling ]);

        const scheduleTab: ScheduleTab = {
            name: 'schedule',
            label: isTemplate ? this.translate.t('TEMPLATE', Namespace.Scheduling) : this.translate.t('SCHEDULE', Namespace.Scheduling),
            syncLabel: isTemplate ? t(`${Namespace.Scheduling}:TEMPLATE`) : t(`${Namespace.Scheduling}:SCHEDULE`),
            hasProducts: this.customerProductService.hasProducts(customerId, [ Products.Scheduling ]),
            hasSpecificAccess: this.canGetSchedule(stackId, customerId, scheduleId),
            hasGeneralAccess: this.canGetSomeSchedules(customerId, stackId),
            skipIfTemplate: false,
            component: 'scheduleTab',
        };

        const weekOverviewTab: ScheduleTab = {
            name: 'week_overview',
            label: this.translate.t('WEEK_OVERVIEW', Namespace.Scheduling),
            syncLabel: t('scheduling:WEEK_OVERVIEW'),
            hasProducts: hasSchedulingProduct,
            hasSpecificAccess: this.canGetSchedule(stackId, customerId, scheduleId),
            hasGeneralAccess: this.canGetSomeSchedules(customerId, stackId),
            skipIfTemplate: false,
            component: 'weekOverviewTab',
        };

        const employeesTab: ScheduleTab = {
            name: 'employees',
            label: this.translate.t('EMPLOYEE_plural'),
            syncLabel: t('EMPLOYEE_plural'),
            hasProducts: hasSchedulingProduct,
            hasSpecificAccess: this.canGetSchedule(stackId, customerId, scheduleId),
            hasGeneralAccess: this.canGetSomeSchedules(customerId, stackId),
            skipIfTemplate: false,
            component: 'scheduleEmployeesTab',
        };

        const budgetTab: ScheduleTab = {
            name: 'budget',
            label: this.translate.t('BUDGET', Namespace.Scheduling),
            syncLabel: t('scheduling:BUDGET'),
            hasProducts: this.customerProductService.hasProducts(customerId, [ Products.Budgeting ]),
            hasSpecificAccess: this.permissionCheckService.isAllowed(`customers.${customerId}.schedules.${scheduleId}.budgets.*.update`),
            hasGeneralAccess: this.permissionCheckService.permissionOrChildren(`customers.${customerId}.schedules.*.budgets.*.update`, undefined, `customers.${customerId}.schedules`, 'budgets.*.update', true),
            skipIfTemplate: true,
            component: 'scheduleBudgetTab',
        };

        const efficiencyTab: ScheduleTab = {
            name: 'efficiency',
            label: this.translate.t('EFFICIENCY', Namespace.Scheduling),
            syncLabel: t('scheduling:EFFICIENCY'),
            hasProducts: this.customerProductService.hasProducts(customerId, [ Products.Efficiency ]),
            hasSpecificAccess: this.permissionCheckService.isAllowed(`customers.${customerId}.schedules.${scheduleId}.efficiencies.*.update`),
            hasGeneralAccess: this.permissionCheckService.permissionOrChildren(`customers.${customerId}.schedules.*.efficiencies.*.update`, undefined, `customers.${customerId}.schedules`, 'efficiencies.*.update', true),
            skipIfTemplate: false,
            component: 'scheduleEfficiencyTab',
        };

        const totalTab: ScheduleTab = {
            name: 'total',
            label: this.translate.t('TOTAL', Namespace.Scheduling),
            syncLabel: t('scheduling:TOTAL'),
            hasProducts: this.customerProductService.hasProducts(customerId, [ Products.Scheduling ]),
            hasSpecificAccess: this.permissionCheckService.isAllowed(`customers.${customerId}.schedules.${scheduleId}.summary`),
            hasGeneralAccess: this.permissionCheckService.permissionOrChildren(`customers.${customerId}.schedules.*.summary`, undefined, `customers.${customerId}.schedules`, 'summary', true),
            skipIfTemplate: true,
            component: 'scheduleTotalTab',
        };

        const autoGenTab: ScheduleTab = {
            name: 'autogen',
            label: this.translate.t('AUTO_POPULATION_RESULT_TAB', Namespace.Scheduling),
            syncLabel: t('scheduling:AUTO_POPULATION_RESULT_TAB'),
            hasProducts: this.customerProductService.hasProducts(customerId, [ Products.AutomaticScheduling ]),
            hasSpecificAccess: this.permissionCheckService.isAllowed(`customers.${customerId}.schedules.create`).pipe(
                switchMap((hasAccess) => {
                    return hasAccess ? this.schedulePropertyService.get(customerId, scheduleId || 0, 'unassigned_shift_report_url').pipe(
                        catchError(() => of(null)),
                        map((property) => property !== null),
                    ) : of(false);
                }),
            ),
            hasGeneralAccess: this.permissionCheckService.isAllowed(`customers.${customerId}.schedules.create`),
            skipIfTemplate: true,
            component: 'autoSchedulingTabDowngraded',
        };

        const warningsTab: ScheduleTab = {
            name: 'warnings',
            label: this.translate.t('WARNING_plural'),
            syncLabel: t('WARNING_plural'),
            hasProducts: this.customerProductService.hasProducts(customerId, [ Products.Scheduling ]),
            hasSpecificAccess: this.permissionCheckService.isAllowed(`customers.${customerId}.schedules.create`),
            hasGeneralAccess: this.permissionCheckService.isAllowed(`customers.${customerId}.schedules.create`),
            skipIfTemplate: false,
            component: 'scheduleWarningTab',
        };

        const tabs = [
            scheduleTab,
            weekOverviewTab,
            employeesTab,
            budgetTab,
            efficiencyTab,
            totalTab,
            autoGenTab,
            warningsTab,
        ]
            .filter((tab) => isTemplate ? !tab.skipIfTemplate : true)
            .map((tab) => this.toObservable(tab, type));

        return forkJoin(tabs).pipe(map((tabs) => tabs.flat()));
    }
}
