import { Component, inject, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { catchError, EMPTY, Observable } from 'rxjs';
import { ExternalEmployee } from '../../models/external-employee';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { ExternalEmployeeService, ExternalEmployeeUpdateRequestData } from '../../../shared/http/external-employee.service';
import { ObjectDifferenceExtractor } from '../../../shared/utils/object-difference-extractor';
import { HandleEmployeeTerminationService } from '../handle-employee-termination/handle-employee-termination.service';
import { EmploymentTerminationHandling } from '../handle-employee-termination/handle-employee-termination.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { DateTimeInputComponent } from '../../../shared/components/date-time/date-time-input/date-time-input.component';
import { DateTimeRangeInputComponent } from '../../../shared/components/date-time/date-time-range-input/date-time-range-input.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface EditExternalEmployeeDialogData extends DialogData {
    // The customer where the employee is external at
    customerId: number;
    externalEmployee: Observable<ExternalEmployee>
}

@Component({
    selector: 'eaw-edit-external-employee-dialog',
    templateUrl: './edit-external-employee-dialog.component.html',
    styleUrl: './edit-external-employee-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DateTimeRangeInputComponent,
        DateTimeInputComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EditExternalEmployeeDialogComponent extends DialogComponent<EditExternalEmployeeDialogData, ExternalEmployee | null, EditExternalEmployeeDialogComponent> implements OnInit {
    protected readonly externalEmployeeService = inject(ExternalEmployeeService);
    protected readonly handleEmployeeTerminationService = inject(HandleEmployeeTerminationService);

    loading = true;
    hasChanges = false;
    difference?: ObjectDifferenceExtractor;
    externalEmployee?: ExternalEmployee;
    form = new FormGroup({
        cost: new FormControl<ExternalEmployee['cost']>(null),
        number: new FormControl<ExternalEmployee['number']>(null),
        from: new FormControl<DateTime | null>(null, Validators.required),
        to: new FormControl<DateTime | null>(null),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: EditExternalEmployeeDialogData,
        @Inject(MatDialogRef) dialogRef: MatDialogRef<EditExternalEmployeeDialogComponent, ExternalEmployee>,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.data.externalEmployee.pipe(
            catchError(() => {
                return EMPTY;
            }),
        ).subscribe((externalEmployee) => {
            this.externalEmployee = externalEmployee;

            this.form.patchValue({
                cost: externalEmployee.cost,
                number: externalEmployee.number,
                from: externalEmployee.from,
                to: externalEmployee.to,
            });

            this.difference = new ObjectDifferenceExtractor(this.form.value);
            this.loading = false;

            this.form.valueChanges.subscribe((value) => {
                this.hasChanges = this.difference?.hasChanges(value) || false;
            });
        });
    }

    update() {
        const externalEmployee = this.externalEmployee;
        const changed = this.difference?.getChanged(this.form.value);

        if (!externalEmployee || !this.hasChanges) {
            return;
        }

        const updateData: ExternalEmployeeUpdateRequestData = {
            cost: changed?.cost,
            number: changed?.number,
            from: changed?.from || undefined,
            to: changed?.to,
        };

        // Only open the termination handling dialog if the employee to date is changed and is a date
        if (changed?.to instanceof DateTime) {
            this.handleEmployeeTerminationService.open(externalEmployee).beforeClosed().subscribe((terminationHandling) => {
                if (terminationHandling) {
                    this.updateEmployee(externalEmployee, updateData, terminationHandling);
                }
            });
        } else {
            this.updateEmployee(externalEmployee, updateData);
        }
    }

    private updateEmployee(externalEmployee: ExternalEmployee, updateData: Partial<ExternalEmployeeUpdateRequestData>, terminationHandling?: EmploymentTerminationHandling) {
        this.form.disable();
        this.externalEmployeeService.update(this.data.customerId, externalEmployee.id, {
            ...updateData,
            resolve_termination: terminationHandling,
        }).pipe(
            catchError(() => {
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe((updatedExternalEmployee) => {
            this.dialogRef.close(updatedExternalEmployee);
        });
    }
}
