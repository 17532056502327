// @ts-nocheck
import { t } from 'i18next';
import { find } from 'lodash-es';
import { pick } from 'lodash-es';
import { debounce } from 'lodash-es';
import { Storage } from '../../../shared/utils/storage';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
const template5 = `<md-list>
    <md-list-item ng-repeat="item in ::ctrl.menuItems" ng-click="ctrl.click(item)" ng-if="item.show">
        <md-icon ng-bind="item.icon"></md-icon>
        <p ng-bind="item.name"></p>
    </md-list-item>
</md-list>
`;
const template4 = `<md-bottom-sheet id="bottom-sheet-new" class="md-grid" layout="column">
    <div layout="row" layout-align="center center" ng-cloak>
        <h4 ng-i18next="filesystem:CREATE_NEW"></h4>
    </div>
    <div ng-cloak>
        <md-list flex layout="row" layout-align="center center">
            <md-list-item ng-repeat="item in $bs.items track by $index">
                <div>
                    <md-button class="md-grid-item-content" ng-click="$bs.itemClick(item)">
                        <md-icon ng-bind="item.icon" md-colors="{'color': 'grey-700'}"></md-icon>
                        <div class="md-grid-text"> {{ item.name }} </div>
                    </md-button>
                </div>
            </md-list-item>
        </md-list>
    </div>
</md-bottom-sheet>
`;
const template3 = `<md-bottom-sheet class="md-list md-has-header">
    <md-subheader ng-cloak>{{'filesystem:SORT_BY' | i18next}}</md-subheader>
    <md-list ng-cloak>
        <md-list-item ng-repeat="(field, value) in $bs.sorting.fields">
            <md-button class="md-list-item-content" ng-click="$bs.menuItemClick(field)" md-autofocus="field == $bs.sorting.field">
                <md-icon ng-class="{'invisible': field != $bs.sorting.field}" md-svg-src="{{$bs.sorting.icon}}"></md-icon>
                <span class="md-inline-list-icon-label" ng-i18next="{{::value.i18n}}"></span>
            </md-button>
        </md-list-item>
    </md-list>
</md-bottom-sheet>
`;
const template2 = `<md-bottom-sheet id="bottom-sheet-menu" class="md-list md-has-header">
    <md-subheader ng-cloak>
        <md-icon ng-if="$bs.item.type == 'file'" ng-bind=$bs.item.mime.icon md-colors="::{color: $bs.item.mime.color}"></md-icon>
        <md-icon ng-if="$bs.item.type == 'directory'" ng-bind="'folder'" md-colors="::{color: $bs.item.color}"></md-icon>
        <span ng-bind="$bs.item.name"></span>
    </md-subheader>
    <md-list ng-cloak>
        <md-list-item ng-repeat="item in ::$bs.menuItems track by $index" ng-if="item.show($bs.item)">
            <md-button class="md-list-item-content" ng-click="$bs.menuItemClick(item)">
                <md-icon ng-bind="item.icon"></md-icon>
                <span class="md-inline-list-icon-label overflow-ellipsis" ng-bind="::item.name"></span>
            </md-button>
        </md-list-item>
    </md-list>
</md-bottom-sheet>
`;
module('eaw.filesystem').component('eawFilesystem', {
    template: `<button class="display-none"
        id="filesystem-upload-btn"
        ngf-select
        ngf-multiple="true"
        ng-model="$fs.files"
        ng-change="$fs.handleFiles($fs.files, $fs.directory)"
        ngf-keep="'distinct'">
</button>

<eaw-uploading-box ng-if="!$fs.smallScreen && $fs.hasAccess($fs.directory, 'write')"></eaw-uploading-box>

<md-card>
    <md-card-content class="tw-p-0">
        <md-progress-circular id="dir-change-loader" ng-show="$fs.changingDirectory"></md-progress-circular>

        <div ng-show="!$fs.changingDirectory">
            <div id="filesystem-header">
                <md-button class="md-icon-button tw-m-0" ng-click="$fs.openDirectory($fs.parentDirectory)" ng-class="{'invisible': !$fs.parentDirectory}">
                    <md-icon ng-bind="'arrow_upward'"></md-icon>
                </md-button>

                <!-- path small screen -->
                <div id="filesystem-path" ng-if="$fs.smallScreen">
                    <div class="path-item">
                        <div class="directory-name" ng-bind="$fs.directory.name"></div>
                    </div>
                </div>

                <!-- path big screen -->
                <div id="filesystem-path" ng-if="!$fs.smallScreen">
                    <div class="path-item" ng-repeat="directory in $fs.directories track by directory.id">
                        <div ng-if="!$last" class="directory-name" ng-bind="directory.name" ng-click="$fs.openDirectory(directory)"></div>
                        <div ng-if="$last" class="directory-name" ng-bind="directory.name"></div>
                        <md-icon ng-if="!$last" ng-bind="'chevron_right'"></md-icon>
                    </div>
                </div>

                <div ng-if="!$fs.smallScreen && $fs.selectedItem" id="highlight-item-icons">
                    <md-icon ng-repeat="item in $fs.menuItems track by $index"
                             ng-if="item.show($fs.selectedItem)"
                             ng-click="item.click($fs.selectedItem)"
                             ng-bind="item.icon">
                        <md-tooltip ng-bind="item.name"></md-tooltip>
                    </md-icon>
                </div>

                <div ng-if="!$fs.smallScreen && $fs.hasAccess($fs.directory, 'write') && $fs.directory.hasSelectedItem()"
                     id="highlight-item-divider">
                </div>

                <div id="header-icons" ng-if="!$fs.smallScreen && $fs.hasAccess($fs.directory, 'write')">
                    <md-icon ng-bind="'shield'" ng-click="$fs.directory.updateAccess()" ng-if="!$fs.selectedItem && $fs.hasAccess($fs.directory, 'manage')">
                        <md-tooltip ng-i18next="ACL"></md-tooltip>
                    </md-icon>
                    <md-icon ng-bind="'upload_file'" ng-click="$fs.uploadFiles()">
                        <md-tooltip ng-i18next="filesystem:UPLOAD"></md-tooltip>
                    </md-icon>
                    <md-icon ng-bind="'create_new_folder'" ng-click="$fs.createDirectory()">
                        <md-tooltip ng-i18next="filesystem:CREATE_DIRECTORY"></md-tooltip>
                    </md-icon>
                </div>
            </div>
        </div>

        <div id="empty-directory" ng-if="!$fs.changingDirectory && $fs.isEmpty() && $fs.hasAccess($fs.directory, 'write')">
            <div id="empty-info">
                <md-icon ng-bind="'upload_file'"></md-icon>
                <div id="empty-text-main" ng-i18next="filesystem:DROP_FILES_HERE"></div>
                <div id="empty-text-sub" ng-i18next="filesystem:OR_USE_NEW"></div>
            </div>
        </div>

        <div id="filesystem-content" class="pretty-scroll" layout="column" ng-show="!$fs.changingDirectory">
            <div id="small-device-list" ng-if="$fs.smallScreen">
                <div id="small-device-sort" ng-click="$fs.openBottomSheetSorting()" ng-if="!$fs.isEmpty()">
                    <span ng-i18next="{{$fs.sorting.fields[$fs.sorting.field].i18n}}"></span>
                    <md-icon md-svg-src="{{$fs.sorting.icon}}"></md-icon>
                </div>
                <div class="item-row" ng-repeat="(key, file) in $fs.fileUploads track by key">
                    <eaw-progress-circle diameter="22" stroke-width="3" progress="file.status == 'init' ? 0 : file.progress * 100"></eaw-progress-circle>
                    <p class="item-name" ng-bind="file.file.name"></p>
                    <md-button class="item-more md-icon-button" ng-click="file.upload.abort()" stop-propagate>
                        <md-icon ng-bind="'close'"></md-icon>
                    </md-button>
                </div>

                <div class="item-row" ng-repeat="directory in $fs.directory.directories track by directory.id" ng-click="$fs.openDirectory(directory)">
                    <md-icon class="item-icon" ng-bind="'folder'" md-colors="::{color: directory.color}"></md-icon>
                    <p class="item-name" ng-bind="directory.name"></p>
                    <md-button class="item-more md-icon-button" ng-click="$fs.openBottomSheetMenu(directory)" stop-propagate>
                        <md-icon ng-bind="'more_vert'"></md-icon>
                    </md-button>
                </div>

                <div class="item-row" ng-repeat="file in $fs.directory.files track by file.id">
                    <md-icon class="item-icon" md-svg-src="{{file.mime.icon}}" md-colors="::{color: file.mime.color}"></md-icon>
                    <p class="item-name" ng-bind="file.name"></p>
                    <md-button class="item-more md-icon-button" ng-click="$fs.openBottomSheetMenu(file)">
                        <md-icon ng-bind="'more_vert'"></md-icon>
                    </md-button>
                </div>
            </div>

            <eaw-item-info item="$fs.detailItem"></eaw-item-info>

            <!-- Use show for element initialization -->
            <md-button id="fab-btn"
                       class="md-fab"
                       ng-show="$fs.smallScreen"
                       ng-if="$fs.hasAccess($fs.directory, 'write')"
                       ng-click="$fs.openBottomSheetNew()">
                <md-icon ng-bind="'add'"></md-icon>
            </md-button>

            <div id="drop-to-upload" ng-if="!$fs.smallScreen && $fs.hasAccess($fs.directory, 'write')">
                <md-icon md-svg-src="cloud-upload"></md-icon>
                <div id="drop-text" class="mat-elevation-z2">
                    <div ng-i18next="filesystem:DROP_TO_UPLOAD"></div>
                    <div id="drop-target">
                        <md-icon ng-bind="'folder'"></md-icon>
                        <strong ng-bind="$fs.dropDirectory.name"></strong>
                    </div>
                </div>
            </div>

            <div id="table-container" ng-cloak ng-if="!$fs.smallScreen">
                <table class="table table-vmiddle" id="directory-table" ng-hide="$fs.isEmpty()">
                    <thead>
                    <tr>
                        <th></th> <!-- checkbox -->
                        <th class="sortable" ng-click="$fs.sortDirectory('name')">
                            <div layout="row" layout-align="start center">
                                <span ng-i18next="NAME" flex="100"></span>
                                <md-icon ng-if="$fs.sorting.field === 'name'" md-svg-src="{{$fs.sorting.icon}}"></md-icon>
                            </div>
                        </th>
                        <th class="sortable" ng-click="$fs.sortDirectory('owner')">
                            <div layout="row" layout-align="start center">
                                <span ng-i18next="filesystem:OWNER" flex="100"></span>
                                <md-icon ng-if="$fs.sorting.field === 'owner'" md-svg-src="{{$fs.sorting.icon}}"></md-icon>
                            </div>
                        </th>
                        <th class="sortable" ng-click="$fs.sortDirectory('updated')">
                            <div layout="row" layout-align="start center">
                                <span ng-i18next="UPDATED_AT" flex="100"></span>
                                <md-icon ng-if="$fs.sorting.field === 'updated'" md-svg-src="{{$fs.sorting.icon}}"></md-icon>
                            </div>
                        </th>
                        <th class="sortable" ng-click="$fs.sortDirectory('filesize')">
                            <div layout="row" layout-align="start center">
                                <span ng-i18next="filesystem:FILE_SIZE" flex="100"></span>
                                <md-icon ng-if="$fs.sorting.field === 'filesize'" md-svg-src="{{$fs.sorting.icon}}"></md-icon>
                            </div>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr class="table-row"
                        ng-repeat="directory in $fs.directory.directories track by directory.id"
                        data-id="{{directory.id}}"
                        data-type="directory">
                        <td class="selector">
                            <md-checkbox class="condensed" ng-model="directory._selected" ng-change="$fs.selectItem(directory)"></md-checkbox>
                        </td>
                        <td class="icon-name" ng-click="$fs.openDirectory(directory)">
                            <eaw-mime-icon type="'folder'"></eaw-mime-icon>
                            <span class="name" ng-bind="directory.name"></span>
                        </td>
                        <td class="owner" ng-bind="directory.owner" ng-click="$fs.openDirectory(directory)"></td>
                        <td class="updated-at" ng-bind="directory.updated_at | moment:'LLLL'" ng-click="$fs.openDirectory(directory)"></td>
                        <td class="filesize" ng-click="$fs.openDirectory(directory)">-</td>
                    </tr>

                    <tr class="table-row"
                        ng-repeat="file in $fs.directory.files track by file.id"
                        data-id="{{file.id}}"
                        data-type="file">
                        <td class="selector">
                            <md-checkbox class="condensed" ng-model="file._selected" ng-change="$fs.selectItem(file)"></md-checkbox>
                        </td>
                        <td class="icon-name">
                            <eaw-mime-icon type="file.file.mime"></eaw-mime-icon>
                            <span class="name" ng-bind="file.name"></span>
                        </td>
                        <td class="owner" ng-bind="file.owner"></td>
                        <td class="updated-at" ng-bind="file.updated_at | moment:'LLLL'"></td>
                        <td class="filesize" ng-bind="file.size.defaultFormat"></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </md-card-content>
</md-card>
`,
    controllerAs: '$fs',
    bindings: {
        directories: '<',
        directory: '<',
    },
    controller: [ '$element', '$scope', '$window', '$mdDialog', '$mdPanel', 'moveItem', '$mdMedia', 'FileDownloadFactory', '$mdBottomSheet', 'FilesystemFactory', '$state', 'ToastService', 'FilesystemFile', 'Directory', function($element, $scope, $window, $mdDialog, $mdPanel, moveItem, $mdMedia, FileDownloadFactory, $mdBottomSheet, FilesystemFactory, $state, ToastService, FilesystemFile, Directory) {
        const ctrl = this;
        let key = 'filesystem:sorting';
        ctrl.$onInit = async () => {
            // Subtract 2 because array starts at 0 and we want 1 styles
            ctrl.parentDirectory = ctrl.directories?.[ctrl.directories.length - 2];
            ctrl.customer = CurrentOld.customer;
            await ctrl.initSorting();
            if (ctrl.hasAccess(ctrl.directory, 'write')) {
                ctrl.onFileUpload();
            }
        };
        ctrl.$postLink = () => {
            ctrl.createMenuItems();
            ctrl.handleContextMenu();
            ctrl.mdBackdropObserver();
            ctrl.observeDroppableTable();
            ctrl.rowMutationObserver();
            ctrl.scrollListener();
            ctrl.sortDirectory();
        };
        ctrl.$doCheck = () => {
            ctrl.smallScreen = !$mdMedia('gt-sm');
        };
        ctrl.$onDestroy = () => {
            ctrl.trObserver?.disconnect();
            ctrl.backdropObserver?.disconnect();
            ctrl.droppableTableObserver?.disconnect();
            ctrl.scrollObserver?.disconnect();
        };
        ctrl.scrollListener = () => {
            ctrl.scrollObserver = new MutationObserver(() => {
                const scrollEl = $window.document.getElementById('filesystem-content');
                if (scrollEl) {
                    scrollEl.addEventListener('scroll', debounce((e) => {
                        const fabBtn = $window.document.getElementById('fab-btn');
                        if (fabBtn) {
                            if (e.target.scrollTop < 60) { // Show
                                fabBtn.classList.add('scale-up');
                                fabBtn.classList.remove('scale-down');
                            } else { // Hide
                                fabBtn.classList.add('scale-down');
                                fabBtn.classList.remove('scale-up');
                            }
                        }
                    }, 80));
                }
            });
            ctrl.scrollObserver.observe($element[0], {
                childList: true,
                subtree: true,
            });
        };
        ctrl.hasAccess = (item, action) => CurrentOld.can(`customers.${CurrentOld.customer.id}.fs_admin`) || item.actions?.[action];
        ctrl.initSorting = async () => {
            const fields = {
                name: {
                    i18n: 'NAME',
                },
                owner: {
                    i18n: 'filesystem:OWNER',
                },
                updated: {
                    i18n: 'UPDATED_AT',
                },
                filesize: {
                    i18n: 'filesystem:FILE_SIZE',
                },
            };
            ctrl.sorting = {
                direction: 'asc',
                field: 'name',
                icon: 'sort-ascending',
                fields,
            };
            key = Storage.prefix('filesystem:sorting');
            const savedSorting = await Storage.getItem(key);
            if (savedSorting) {
                ctrl.sorting = {
                    ...savedSorting,
                    fields,
                };
            }
        };
        /**
         * Sort the files and directories in this directory
         * @param {string}  [field}     - name of field to sort by, if empty sort as is
         */
        ctrl.sortDirectory = (field) => {
            ctrl.setSortingDirection(field);
            ctrl.setSortingField(field);
            ctrl.setSortingIcon(ctrl.sorting.direction);
            ctrl.directory.sort(ctrl.sorting.field, ctrl.sorting.direction);
            void Storage.setItem(key, pick(ctrl.sorting, [ 'direction', 'field', 'icon' ]));
        };
        ctrl.setSortingDirection = (field) => {
            // Flip if field is the same, if not go to asc
            if (field === ctrl.sorting.field) {
                ctrl.sorting.direction = ctrl.sorting.direction === 'asc' ? 'desc' : 'asc';
            } else {
                ctrl.sorting.direction = 'asc';
            }
        };
        ctrl.setSortingField = (field) => {
            ctrl.sorting.field = field || ctrl.sorting.field;
        };
        ctrl.setSortingIcon = (direction) => {
            ctrl.sorting.icon = direction === 'desc' ? 'sort-descending' : 'sort-ascending';
        };
        ctrl.isEmpty = () => {
            const hasUploadsInSmallScreen = ctrl.smallScreen && ctrl.fileUploads && Object.keys(ctrl.fileUploads).length;
            return ctrl.directory.isEmpty() && !hasUploadsInSmallScreen;
        };
        ctrl.openBottomSheetMenu = (item) => {
            $mdBottomSheet.show({
                template: template2,
                controllerAs: '$bs',
                controller: 'fsBsMenuCtrl',
                bindToController: true,
                locals: {
                    item,
                    menuItems: ctrl.menuItems,
                },
            });
        };
        ctrl.openBottomSheetSorting = () => {
            $mdBottomSheet.show({
                template: template3,
                controllerAs: '$bs',
                controller: 'fsBsSortingCtrl',
                bindToController: true,
                locals: {
                    sorting: ctrl.sorting,
                    sortingFunction: ctrl.sortDirectory,
                },
            });
        };
        ctrl.openBottomSheetNew = () => {
            $mdBottomSheet.show({
                template: template4,
                controllerAs: '$bs',
                controller: 'fsBsNewCtrl',
                bindToController: true,
                locals: {
                    uploadFunction: ctrl.uploadFiles,
                    createDirectoryFunction: ctrl.createDirectory,
                },
            });
        };
        ctrl.moveItem = (item) => {
            moveItem.open({
                startDirectory: ctrl.directory,
                item,
            }).afterClosed().subscribe((directory) => {
                if (!directory) {
                    return;
                }
                ctrl.changeDirectory(item, directory);
            });
        };
        ctrl.getTableContainer = () => $window.document.getElementById('table-container');
        ctrl.mdBackdropObserver = () => {
            ctrl.backdropObserver = new MutationObserver(() => {
                const backdrop = $window.document.querySelector('md-backdrop');
                const panelWrapper = $window.document.querySelector('.md-panel-outer-wrapper');
                [ backdrop, panelWrapper ].forEach((el) => {
                    if (el instanceof HTMLElement) {
                        el.addEventListener('contextmenu', (event) => {
                            event.preventDefault(); // Stop browser context menu
                            // backdrop.click(); // Click backdrop to make it go away and close current menu
                        });
                    }
                });
            });
            ctrl.backdropObserver.observe($window.document.body, {
                childList: true,
            });
        };
        ctrl.handleContextMenu = () => {
            $element[0].addEventListener('contextmenu', (event) => {
                event.preventDefault(); // Stop browser context menu popping up
                const closestRow = ctrl.getClosestRow(event.target);
                if (closestRow) { // Make sure it's a row
                    const item = ctrl.getItem(closestRow.dataset.type, closestRow.dataset.id); // Get file / dir
                    item.select(); // Set it as selected
                    ctrl.selectItem(item);
                    ctrl.openContextMenu(item, event);
                }
            });
        };
        ctrl.createMenuItems = () => {
            ctrl.menuItems = [
                {
                    name: t('DOWNLOAD'),
                    icon: 'download',
                    click: ctrl.download,
                    show: (item) => item?.type === 'file',
                },
                {
                    name: t('filesystem:SEE_DETAILS'),
                    icon: 'information',
                    click: ctrl.getDetails,
                    show: (item) => (item ? ctrl.hasAccess(item, 'read') : false),
                },
                {
                    name: t('filesystem:MOVE_TO'),
                    icon: 'drive_file_move',
                    click: ctrl.moveItem,
                    show: (item) => (item ? ctrl.hasAccess(item, 'write') : false),
                },
                {
                    name: t('ACL'),
                    icon: 'shield',
                    click: (item) => {
                        if (item) {
                            return item.updateAccess();
                        }
                        ctrl.directory.updateAccess();
                    },
                    show: (item) => (item ? ctrl.hasAccess(item, 'manage') : false),
                },
                {
                    name: t('filesystem:RENAME'),
                    icon: 'edit',
                    click: ctrl.renameItem,
                    show: (item) => (item ? ctrl.hasAccess(item, 'write') : false),
                },
                {
                    name: t('DELETE'),
                    icon: 'delete',
                    click: ctrl.deleteItem,
                    show: (item) => (item ? ctrl.hasAccess(item, 'write') : false),
                },
            ];
        };
        ctrl.openContextMenu = (item, event) => {
            $mdPanel.open({
                attachTo: document.body,
                controller: 'filesystemContextMenuCtrl',
                controllerAs: 'ctrl',
                template: template5,
                panelClass: 'eaw-panel filesystem-context-panel',
                hasBackdrop: false,
                position: $mdPanel.newPanelPosition()
                    .relativeTo(event.target)
                    .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS),
                animation: $mdPanel.newPanelAnimation()
                    .openFrom(event.target)
                    .closeTo(event.target)
                    .duration(200)
                    .withAnimation($mdPanel.animation.SCALE),
                locals: {
                    item,
                    menuItems: ctrl.menuItems,
                },
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                zIndex: 100,
            });
        };
        ctrl.onFileUpload = () => {
            $scope.$on('file:uploading', (event, file) => {
                ctrl.fileUploads = ctrl.fileUploads || {}; // Use existing or create new empty
                ctrl.fileUploads[file.file.id] = { ...file }; // Spread file
                if (file.status === 'abort' || file.status === 'success') {
                    delete ctrl.fileUploads[file.file.id];
                }
            });
        };
        ctrl.isDirectoryRow = (row) => row?.dataset?.type === 'directory';
        ctrl.download = (file) => {
            FileDownloadFactory.open(file);
        };
        ctrl.getDetails = (item) => {
            // We gotta spread the item so that $onChanges gets triggered in the component
            ctrl.detailItem = { ...item };
        };
        ctrl.getItem = (type, id) => {
            id = parseInt(id);
            if (type === 'file') {
                return find(ctrl.directory.files, { id });
            }
            if (type === 'directory') {
                return find(ctrl.directory.directories, { id });
            }
        };
        ctrl.setDroppableTable = (set) => {
            const container = ctrl.getTableContainer();
            if (container instanceof Node) {
                const func = set ? 'addEventListener' : 'removeEventListener';
                container[func]('drop', ctrl.dropEvent);
                container[func]('dragover', ctrl.dragoverEvent);
                container[func]('dragenter', ctrl.dragenterEvent);
                container[func]('dragleave', ctrl.dragleaveEvent);
            }
        };
        ctrl.observeDroppableTable = () => {
            ctrl.droppableTableObserver = new MutationObserver(() => {
                ctrl.setDroppableTable(true);
            });
            ctrl.droppableTableObserver.observe($element[0], {
                childList: true,
                subtree: true,
            });
        };
        ctrl.addDragItemClass = (row) => {
            row.classList.add('drag-item');
        };
        ctrl.removeDragItemClass = (row) => {
            row.classList.remove('drag-item');
        };
        ctrl.removeDragImage = () => {
            ctrl.dragImage?.remove();
        };
        ctrl.createDragImage = (row) => {
            ctrl.dragImage = row.cloneNode(true);
            ctrl.dragImage.id = 'drag-image';
            $window.document.body.appendChild(ctrl.dragImage);
            return ctrl.dragImage;
        };
        ctrl.setDataTransfer = (row, event) => {
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.setData('text/plain', JSON.stringify(ctrl.getItem(row.dataset.type, row.dataset.id)));
            event.dataTransfer.setDragImage(ctrl.createDragImage(row), 0, 0);
        };
        ctrl.dragstartEvent = function(ev) {
            // Don't prevent default
            ctrl.dragstartRow = ev.target;
            ctrl.internalDrag = true; // Means drag started in the browser
            ctrl.setDroppableTable(false); // Turn off drag/drop for table
            ctrl.addDragItemClass(ctrl.dragstartRow);
            ctrl.setDataTransfer(ctrl.dragstartRow, ev);
            // Remove listeners from target if target is a directory
            if (ctrl.isDirectoryRow(ctrl.dragstartRow)) {
                ctrl.dragstartRow.removeEventListener('drop', ctrl.dropEvent);
                ctrl.dragstartRow.removeEventListener('dragover', ctrl.dragoverEvent);
            }
        };
        ctrl.getClosestRow = (el) => el.closest('.table-row');
        ctrl.getRowType = (el) => el?.dataset?.type;
        ctrl.dragenterEvent = function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            ctrl.dragenterRow = ctrl.getClosestRow(ev.target);
            if (!ctrl.internalDrag) {
                $element[0].classList.add('external-dragover');
                ctrl.getDropDirectory(ev);
            }
            if (ctrl.isDirectoryRow(ctrl.dragenterRow)) {
                ctrl.dragenterRow.classList.add('dragover');
                $element[0].classList.add('directory-dragover');
            } else {
                $element[0].classList.remove('directory-dragover');
            }
        };
        ctrl.dragleaveEvent = function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            if (!(ev.target && ev.relatedTarget)) {
                $element[0].classList.remove('directory-dragover', 'external-dragover');
                return;
            }
            ctrl.dragleaveEl = ctrl.getClosestRow(ev.target) || ev.target;
            ctrl.dragleaveRelatedEl = ctrl.getClosestRow(ev.relatedTarget) || ev.relatedTarget;
            ctrl.dragleaveRelatedElType = ctrl.getRowType(ctrl.dragleaveRelatedEl);
            if (!ctrl.dragleaveRelatedEl || !ctrl.dragleaveRelatedElType) {
                if (ctrl.dragleaveRelatedEl !== ctrl.getTableContainer()) {
                    $element[0].classList.remove('directory-dragover', 'external-dragover');
                }
            }
            if (ctrl.dragleaveEl) {
                ctrl.dragleaveEl.classList.remove('dragover');
            }
        };
        ctrl.dragendEvent = function(ev) {
            ev.preventDefault();
            ctrl.dragendRow = ev.target;
            ctrl.internalDrag = false; // Reset
            ctrl.setDroppableTable(true); // Turn on drag/drop for table
            ctrl.removeDragImage();
            ctrl.removeDragItemClass(ctrl.dragendRow);
            // Turn on drag/drop for directory again
            if (ctrl.isDirectoryRow(ctrl.dragendRow)) {
                ctrl.dragendRow.addEventListener('drop', ctrl.dropEvent);
                ctrl.dragendRow.addEventListener('dragover', ctrl.dragoverEvent);
            }
        };
        ctrl.getDropDirectory = (event) => {
            ctrl.dropDirRow = ctrl.getClosestRow(event.target);
            ctrl.dropDir = ctrl.isDirectoryRow(ctrl.dropDirRow) ? ctrl.getItem('directory', ctrl.dropDirRow.dataset.id) : ctrl.directory;
            // Force update
            $scope.$evalAsync(() => {
                ctrl.dropDirectory = ctrl.dropDir;
            });
            return ctrl.dropDir;
        };
        ctrl.dropEvent = function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            $element[0].classList.remove('directory-dragover', 'external-dragover');
            // Remove dragover when we have dropped
            const rows = $window.document.querySelectorAll('.table-row');
            for (let i = 0; i < rows.length; i++) {
                rows[i].classList.remove('dragover');
            }
            if (ctrl.internalDrag) {
                let fileSystemItem = JSON.parse(ev.dataTransfer.getData('text/plain'));
                fileSystemItem = fileSystemItem.type == 'directory' ? new Directory(fileSystemItem) : new FilesystemFile(fileSystemItem);
                ctrl.changeDirectory(fileSystemItem, ctrl.getDropDirectory(ev));
            } else {
                ctrl.handleFiles(ev.dataTransfer.files, ctrl.getDropDirectory(ev));
            }
        };
        /**
         * We need this function for drag/drop to work
         * @param ev
         */
        ctrl.dragoverEvent = function(ev) {
            ev.preventDefault();
        };
        ctrl.onDirectoryChange = (item, directory) => {
            ToastService.tToast('filesystem:CHANGED_DIRECTORY', {
                itemName: item.name,
                fromDir: ctrl.directory.name,
                toDir: directory.name,
            });
            ctrl.directory.removeItem(item);
        };
        ctrl.changeDirectory = (item, directory) => {
            if (item.type === 'file') {
                FilesystemFactory.updateFile.query(ctrl.customer.id, item.id, { directory_id: directory.id }).then(() => {
                    ctrl.onDirectoryChange(item, directory);
                });
            }
            if (item.type === 'directory') {
                FilesystemFactory.updateDirectory.query(ctrl.customer.id, item.id, { directory_id: directory.id }).then(() => {
                    ctrl.onDirectoryChange(item, directory);
                });
            }
        };
        ctrl.rowDragInit = (row) => {
            row.setAttribute('draggable', 'true');
            row.addEventListener('dragstart', ctrl.dragstartEvent);
            row.addEventListener('dragend', ctrl.dragendEvent);
            if (ctrl.isDirectoryRow(row)) {
                row.addEventListener('drop', ctrl.dropEvent);
                row.addEventListener('dragover', ctrl.dragoverEvent);
                row.addEventListener('dragenter', ctrl.dragenterEvent);
                row.addEventListener('dragleave', ctrl.dragleaveEvent);
            }
        };
        ctrl.dragDropRowsEnable = () => {
            const rows = $window.document.querySelectorAll('.table-row');
            for (let i = 0; i < rows.length; i++) {
                ctrl.rowDragInit(rows[i]);
            }
        };
        ctrl.rowMutationObserver = () => {
            ctrl.trObserver = new MutationObserver((mutations) => {
                let shouldEnable = false;
                mutations.forEach((mutation) => {
                    mutation.addedNodes.forEach((node) => {
                        if (node.classList && node.classList.contains('table-row')) {
                            shouldEnable = true;
                        }
                    });
                });
                if (shouldEnable) {
                    ctrl.dragDropRowsEnable();
                }
            });
            ctrl.trObserver.observe($element[0], {
                childList: true,
                subtree: true,
            });
        };
        ctrl.uploadFiles = () => {
            $window.document.getElementById('filesystem-upload-btn').click();
        };
        ctrl.handleFiles = (files, directory) => {
            if (files instanceof Array || files instanceof FileList) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    if (directory.id === ctrl.directory.id) {
                        FilesystemFactory.uploadFile.query(ctrl.customer.id, directory.id, file, [ 'attachments', 'user' ]).then((uploadedFile) => {
                            ctrl.directory.addFile(uploadedFile);
                            ctrl.sortDirectory();
                        });
                    } else {
                        FilesystemFactory.uploadFile.query(ctrl.customer.id, directory.id, file);
                    }
                }
            }
            // Reset files, !IMPORTANT
            ctrl.files = [];
        };
        ctrl.openDirectory = (directory) => {
            if (!ctrl.hasAccess(ctrl.directory, 'read')) {
                return;
            }
            const root = ctrl.directories[0];
            // Add all dir ids but use values styles or equal to the dir we are going to
            let dirIds = ctrl.directories.map((d) => Math.min(d.id, directory.id));
            // Spread the ids leading up to this point, and add the dir id we are going to, set removes dupes
            dirIds = new Set([ ...dirIds, directory.id ]);
            // Delete the root id, we never want it
            dirIds.delete(root.id);
            // Create a path with / at the start to indicate root
            const path = `/${Array.from(dirIds).join('/')}`;
            // Show spinner
            ctrl.changingDirectory = true;
            // If we are going to root, then ignore path we created
            $state.go(`eaw/app/filesystem`, {
                path: dirIds.size ? path : '',
            });
        };
        ctrl.createDirectory = () => {
            // Save in case user navigates away from directory
            const directoryId = ctrl.directory.id;
            $mdDialog.show($mdDialog.prompt()
                .title(t('filesystem:CREATE_DIRECTORY'))
                .placeholder(t('NAME'))
                .required(true)
                .ok(t('CREATE'))
                .cancel(t('CANCEL'))).then((name) => {
                FilesystemFactory.createDirectory.query(ctrl.customer.id, directoryId, name, [ 'user' ]).then((directory) => {
                    if (ctrl.directory.id === directoryId) {
                        ctrl.directory.addDirectory(directory);
                        ctrl.sortDirectory();
                    }
                });
            });
        };
        ctrl.renameItem = (item) => {
            $mdDialog.show($mdDialog.prompt()
                .title(t('filesystem:CHANGE_NAME'))
                .placeholder(t('NAME'))
                .required(true)
                .initialValue(item.name)
                .ok(t('filesystem:CHANGE'))
                .cancel(t('CANCEL'))).then((name) => {
                if (item.type === 'file') {
                    FilesystemFactory.updateFile.query(ctrl.customer.id, item.id, { name }, [ 'attachments' ]).then(ctrl.onRename);
                }
                if (item.type === 'directory') {
                    FilesystemFactory.updateDirectory.query(ctrl.customer.id, item.id, { name }).then(ctrl.onRename);
                }
            });
        };
        ctrl.onRename = (updatedItem) => {
            ToastService.tToast('filesystem:CHANGED_NAME_TO', { name: updatedItem.name });
            ctrl.directory.updateItem(updatedItem);
            ctrl.sortDirectory();
        };
        ctrl.deleteItem = (item) => {
            $mdDialog.show($mdDialog.confirm()
                .theme('delete')
                .title(t('DELETE'))
                .textContent(t(`filesystem:DELETE_${item.type.toUpperCase()}`, {
                    name: item.name,
                }))
                .ok(t('DELETE'))
                .cancel(t('CANCEL'))).then(() => {
                if (item.type === 'file') {
                    FilesystemFactory.deleteFile.query(ctrl.customer.id, item.id).then(() => {
                        ctrl.directory.removeItem(item);
                        ctrl.clearSelectedItem();
                        ToastService.tToast('X_DELETED', { name: item.name });
                    });
                }
                if (item.type === 'directory') {
                    FilesystemFactory.deleteDirectory.query(ctrl.customer.id, item.id).then(() => {
                        ctrl.directory.removeItem(item);
                        ctrl.clearSelectedItem();
                        ToastService.tToast('X_DELETED', { name: item.name });
                    });
                }
            });
        };
        ctrl.clearSelectedItem = () => {
            ctrl.directory.clearSelectedItem();
            ctrl.selectedItem = null;
            Array.from($window.document.querySelectorAll('.table-row')).forEach((el) => el.classList.remove('highlight'));
        };
        ctrl.selectItem = (item) => {
            const selected = !!item.isSelected(); // Store if selected or not
            ctrl.clearSelectedItem(); // Clear current
            if (selected) {
                item.select();
                ctrl.selectedItem = item;
                const row = $window.document.querySelector(`.table-row[data-type="${item.type}"][data-id="${item.id}"]`);
                if (row) {
                    row.classList.add('highlight');
                }
            }
        };
    } ],
});
