// @ts-nocheck
import { pickBy } from 'lodash-es';
import moment, { Moment } from 'moment-timezone';
import { Model } from './model';
import CustomerOld from './customer-old';

/**
 * @deprecated
 */
export default class EmployeeOld extends Model {
    name: string;
    first_name: string;
    last_name: string;
    user_id: number;
    customer_id: number;
    inactive: boolean;
    from: Moment;
    to: Moment;
    tasks?: any;
    customer?: CustomerOld;
    country_key = '';
    roles?: any;

    constructor(data = {}) {
        if (typeof data.name == 'string') {
            const names = data.name.split(' ');
            if (!data.first_name) {
                data.first_name = data.first_name || names.slice(0, names.length - 1).join(' ');
            }

            if (!data.last_name) {
                data.last_name = data.last_name || names[names.length - 1];
            }
        }

        super(data);

        this.country_key = data.country_key;

        this.setInactive();

        Model.setWarningTranslations(this);
    }

    isMe(userId: number) {
        return this.user_id === userId;
    }

    isExternal(customerId: number) {
        return this.customer_id !== customerId;
    }

    _set(obj, prop, value) {
        if (prop == 'role') {
            super._set(obj, 'role_id', value?.id ?? null);
        }

        return super._set(obj, prop, value);
    }

    setInactive() {
        const now = moment();
        this.inactive = now.isAfter(this.to, 's') || now.isBefore(this.from, 's');
    }

    update(EmployeeService) {
        return EmployeeService.updateEmployee(this.customer_id, this.id, this.getModified()).then((resp) => new EmployeeOld(resp));
    }

    static getRequestPayload(data) {
        const _businessDates = [ 'from', 'to', 'birth_date' ];
        const employeeFields = [
            'user_id',
            'email',
            'first_name',
            'last_name',
            'country_key',
            'language_code',
            'number',
            'address1',
            'address2',
            'gender',
            'birth_date',
            'postal_code',
            'city',
            'country',
            'qualifications',
            'extra_seniority',
            'nationality',
            'phone',
            'from',
            'to',
            'name',
            'role_id',
            'resolve_termination',
        ];

        // Pick fields from data and custom fields
        // Avoid empty values
        let payload = pickBy(data, (value, key) => {
            const isCf = key.startsWith('cf_');
            const isInFields = employeeFields.includes(key);
            if (isInFields && (value === null || value === '')) {
                return true;
            }
            const empty = (typeof value === 'object' || typeof value === 'string') && !Object.keys(value || []).length;
            return (isCf || isInFields) && !empty;
        });

        // Add special cases
        if (Object.prototype.hasOwnProperty.call(data, 'from')) {
            payload.from = moment.isMoment(data.from) ? data.from : null;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'to')) {
            payload.to = moment.isMoment(data.to) ? data.to : null;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'dial_code')) {
            payload.phone = `+${data.dial_code} ${data.phone}`;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'region')) {
            payload.region_id = data.region?.id;
        }

        // FIELD VALUES LEGACY
        if (typeof data.field_values == 'object') {
            payload = {
                ...payload,
                ...data.field_values,
            };
        }

        // Omit undefined keys
        const dates = [];
        payload = Object.entries(payload).reduce((carry, [ key, val ]) => {
            if (val !== undefined) {
                carry[key] = val;

                if (_businessDates.includes(key)) {
                    dates.push(key);
                }
            }

            return carry;
        }, {});

        // Add business dates if we have keys that correspond to a business date
        if (dates.length) {
            payload._businessDates = dates;
        }

        return payload;
    }

    static override getMorphClass() {
        return 'employee';
    }
}
