<mat-card *ngIf="loadingInitial">
    <mat-card-content id="loader-content">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="!loadingInitial">
    <mat-selection-list>
        <mat-list-option *ngFor="let product of products"
                         #option
                         [selected]="product.enabled"
                         [disabled]="product.isDefault || product.updating"
                         (click)="toggleProduct(product, option)">
            <span matListItemTitle>{{product.product.name}}</span>
        </mat-list-option>
    </mat-selection-list>
</mat-card>
