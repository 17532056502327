import { module } from 'angular';
import { StateProvider, Transition } from '@uirouter/angularjs';
import { AdminProductsListComponent } from '../../pages/admin-products-list/admin-products-list.component';
import { AdminProductComponent } from '../../pages/admin-product/admin-product.component';

module('eaw.admin').config([ '$stateProvider', function($stateProvider: StateProvider) {
    $stateProvider.state(`eaw/app/admin/products`, {
        parent: 'eaw/app/admin',
        url: '/products',
        abstract: true,
        data: {
            breadcrumb: { key: 'PRODUCT_plural', ns: 'admin' },
        },
    });

    $stateProvider.state(`eaw/app/admin/products/list`, {
        parent: `eaw/app/admin/products`,
        url: '/list',
        views: {
            'content@': {
                component: AdminProductsListComponent,
            },
        },
        data: {
            breadcrumb: { key: 'LIST' },
            permissions: [ `products.*.get` ],
            permissionChildrenInclude: [ `products`, `get` ],
        },
    });

    $stateProvider.state(`eaw/app/admin/products/product`, {
        parent: `eaw/app/admin/products/list`,
        url: '/:name',
        views: {
            'content@': {
                component: AdminProductComponent,
            },
        },
        data: {
            breadcrumb: { key: 'PRODUCT', ns: 'admin' },
            permissions: [ 'products.{name}.get' ],
        },
        resolve: {
            product: [ 'ProductService', '$transition$', function(ProductService: any, $transition$: Transition) {
                return ProductService.getByName($transition$.params()['name']).$promise;
            } ],
        },
    });
} ]);

