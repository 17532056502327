import { Component, Inject, ViewChild } from '@angular/core';
import { DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { CurrentService } from '../../../shared/services/current.service';
import { map, of } from 'rxjs';
import { OrganizationMembersService } from '../../http/organization-members.service';
import { OrganizationMember } from '../../../shared/models/organization-member';
import { AddMembersDialogComponent } from '../../dialogs/add-members-dialog/add-members-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { UIRouter } from '@uirouter/core';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';

@Component({
    selector: 'eaw-organization-members-tab',
    templateUrl: './organization-members-tab.component.html',
    styleUrl: './organization-members-tab.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class OrganizationMembersTabComponent implements EawDataTable {
    @ViewChild(DataTableComponent) table!: DataTableComponent<OrganizationMember>;

    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    orgId: string;
    fabButton: HeaderFabButton = {
        icon: 'add',
        type: 'accent',
        click: () => this.openAddMemberDialog(),
        hasPermission: () => this.permissionCheckService.isAllowed(`organizations.${this.orgId}.members.create`),
    };

    columns: DataTableColumnType<OrganizationMember>[] = [
        new DataTableTextColumn({
            value: 'customerId',
            header: new DataTableHeader({
                i18n: 'ID',
                sortBy: 'id',
            }),
        }),
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            format: DateTime.DATE_MED,
            header: new DataTableHeader({ i18n: 'FROM' }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            format: DateTime.DATE_MED,
            header: new DataTableHeader({ i18n: 'TO' }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    click: this.deleteMember.bind(this),
                    icon: 'delete',
                    type: 'warn',
                    tooltip: { key: 'DELETE' },
                    show: () => this.permissionCheckService.isAllowed(`organizations.${this.orgId}.members.delete`),
                },
            ],
        }),
    ];

    constructor(
        @Inject(CurrentService) public current: CurrentService,
        @Inject(OrganizationMembersService) public service: OrganizationMembersService,
        @Inject(MatDialog) public dialog: MatDialog,
        @Inject(PermissionCheckService) public permissionCheckService: PermissionCheckService,
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(ConfirmDialogService) private confirm: ConfirmDialogService,
    ) {
        this.orgId = this.uiRouter.globals.params['organizationId'];
    }

    updateTable(): void {
        this.request = this.service.getAll(this.orgId).pipe(
            map((response) => {
                return mockArrayPaginatedResponse(response, { total: response.length }) satisfies ArrayPaginatedResponse<OrganizationMember>;
            }),
        );
    }

    openAddMemberDialog() {
        this.dialog.open(AddMembersDialogComponent).afterClosed().subscribe(() => this.updateTable());
    }

    deleteMember(cell: DataTableButtonCell<OrganizationMember>) {
        this.confirm.delete().beforeClosed().subscribe((i) => {
            if (i?.ok) {
                this.service.delete(this.orgId, cell.item.customerId).subscribe({ complete: (() => this.updateTable()) });
            } else {
                cell.disabled.set(false);
            }
        });
    }
}
