// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
const template1 = `<md-dialog class="mini-dialog">
    <md-dialog-content>
        <div class="md-dialog-content" layout="column" layout-align="center center" layout-padding>
            <md-progress-linear class="md-progress-center" md-mode="{{$loading.mode}}" ng-if="$loading.linear" value="{{$loading.value}}"></md-progress-linear>
            <md-progress-circular class="md-progress-center" md-mode="{{$loading.mode}}" ng-if="!$loading.linear" value="{{$loading.value}}"></md-progress-circular>
            <span class="md-subhead" ng-bind="$loading.text"></span>
        </div>
    </md-dialog-content>
</md-dialog>
`;
module('eaw.misc').factory('loadingBar', [ '$mdDialog', function($mdDialog) {
    return {
        open(promises, linear, text) {
            return $mdDialog.show({
                template: template1,
                controllerAs: '$loading',
                multiple: true,
                escapeToClose: false,
                clickOutsideToClose: false,
                controller: function() {
                    const ctrl = this;
                    ctrl.$onInit = () => {
                        ctrl.linear = linear;
                        ctrl.text = text || t('LOADING');
                        if (promises) {
                            let finished = 0;
                            const total = promises.length; // 100%
                            ctrl.mode = 'determinate';
                            ctrl.value = 0; // percentage that finished is of total.
                            const values = promises.map((promise) => {
                                return promise.finally(() => {
                                    ctrl.value = ((++finished / total) * 100);
                                });
                            });
                            Promise.all(values).then((resolves) => {
                                $mdDialog.hide(resolves, { closeAll: true });
                            }, (reason) => {
                                $mdDialog.cancel(reason, { closeAll: true });
                            });
                        } else {
                            ctrl.mode = 'indeterminate';
                        }
                    };
                },
            });
        },
        close: (resolve, data) => {
            return resolve ? $mdDialog.hide(data) : $mdDialog.cancel(data);
        },
    };
} ]);
