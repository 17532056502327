<h1 mat-dialog-title>
    {{'ADD_MEMBER' | translate | async}}
</h1>

<div mat-dialog-content>
    <mat-form-field class="user-field">
        <mat-label>{{'USER_plural'| translate | async}}</mat-label>

        <mat-chip-grid #chipList aria-label="User selection" required>
            <mat-chip-row (removed)="remove(user)" selectable="false" *ngFor="let user of members">
                {{user.name}} ({{user.email}})
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input placeholder="{{'TYPE_TO_SEARCH_AUTOCOMPLETE' | translate | async}}"
                   #userInput
                   [formControl]="userCtrl"
                   [matAutocomplete]="auto"
                   [matChipInputFor]="chipList">
        </mat-chip-grid>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event.option.value)" autoActiveFirstOption="true">
            <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                {{user.name}} ({{user.email}})
            </mat-option>
        </mat-autocomplete>

        <mat-progress-spinner matSuffix strokeWidth="3" diameter="24" *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    </mat-form-field>

    <eaw-date-time-range-input class="tw-w-full tw-grid tw-grid-cols-1 tw-gap-8">
        <mat-form-field eawDateTimeFrom>
            <mat-label>{{'FROM' | translate | async}}</mat-label>
            <eaw-date-time-input [formControl]="fromCtrl" [required]="true" [minDate]="today" ></eaw-date-time-input>
        </mat-form-field>

        <mat-form-field eawDateTimeTo>
            <mat-label>{{'TO' | translate | async}}</mat-label>
            <eaw-date-time-input [formControl]="toCtrl" [required]="true" [minDate]="today" ></eaw-date-time-input>
        </mat-form-field>
    </eaw-date-time-range-input>
</div>

<div mat-dialog-actions>
    <button [mat-dialog-close]="null" mat-button [disabled]="!!saving">{{'CANCEL' | translate | async}}</button>
    <button (click)="save()" [disabled]="!!saving || !members.length" mat-button>{{'SAVE' | translate | async}}</button>
</div>
