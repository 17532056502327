import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PaginationOptions } from '../interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { SystemAlert, SystemAlertResponse } from '../models/system-alert';

@Injectable({
    providedIn: 'root',
})
export class SystemAlertService {
    private http = inject(HttpClient);

    getAll(options?: PaginationOptions): Observable<ArrayPaginatedResponse<SystemAlert>> {
        return this.http.get<ArrayPaginatedResponse<SystemAlertResponse>>(`/system_alerts`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(SystemAlert));
    }

    create(text: string) {
        return this.http.post<SystemAlertResponse>(`/system_alerts`, { text }).pipe(classifyItem(SystemAlert));
    }

    delete(id: number): Observable<null> {
        return this.http.delete<null>(`/system_alerts/${id}`);
    }

    update(id: number, text: string) {
        return this.http.put<SystemAlertResponse>(`/system_alerts/${id}`, { text })
            .pipe(classifyItem(SystemAlert));
    }
}
