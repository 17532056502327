<mat-form-field>
    <mat-label>{{'QUALIFICATION_plural' | translate | async}}</mat-label>
    <mat-chip-grid #chipList aria-label="Qualification selection">
        <mat-chip-row *ngFor="let qualification of selectedQualifications" (removed)="remove(qualification)">
            {{qualification.name}}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
        <input
            #inputElement
            [formControl]="inputCtrl"
            [matAutocomplete]="autocomplete"
            [matChipInputFor]="chipList">
    </mat-chip-grid>
    <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="add($event.option.value)">
        <mat-option *ngFor="let option of qualificationObservable | async" [value]="option">
            {{ option.name }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
