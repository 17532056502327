// @ts-nocheck
import { module } from 'angular';
import {smallDevice} from './services/easy-funcs.service';

/**
 * Move stuff out of this module as it becomes more convenient.
 */

module('eaw.misc', [
    'ngMaterial',
    'ngFileUpload',
    'ngTable',
    'eaw.resource',
    'eaw.alerts',
])
    .config([function() {
        if (smallDevice()) {
            document.body.classList.add('small-device');
        }
    } ]);
