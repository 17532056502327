@if (loading()) {
    <eaw-info-loading size="xl"/>
} @else {
    @if (pdfViewerEnabled()) {
        @if (safeUrl()) {
            <object id="pdf-object" [data]="safeUrl()" type="application/pdf"></object>
        }
    } @else {
        <div id="canvas-section">
            <div id="canvas-header" class="mat-elevation-z4">
                <div id="pdf-name">{{ title() }}</div>

                <button mat-icon-button [matMenuTriggerFor]="optionsMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #optionsMenu="matMenu">
                    <button mat-menu-item (click)="zoomIn()">
                        <mat-icon>zoom_in</mat-icon>
                        <span>{{ 'ZOOM_IN' | translate | async }}</span>
                    </button>

                    <button mat-menu-item (click)="zoomOut()">
                        <mat-icon>zoom_out</mat-icon>
                        <span>{{ 'ZOOM_OUT' | translate | async }}</span>
                    </button>

                    @if (fullscreen()) {
                        <button mat-menu-item (click)="setFullscreen(false)">
                            <mat-icon>fullscreen_exit</mat-icon>
                            <span>{{ 'CANCEL_FULLSCREEN' | translate | async }}</span>
                        </button>
                    } @else {
                        <button mat-menu-item (click)="setFullscreen(true)">
                            <mat-icon>fullscreen</mat-icon>
                            <span>{{ 'FULLSCREEN' | translate | async }}</span>
                        </button>
                    }
                </mat-menu>
            </div>

            <div id="canvas-container">
                <canvas #canvas></canvas>
            </div>

            @if (numPages() > 1) {
                <div id="canvas-footer">
                    <button mat-icon-button (click)="previousPage()" [disabled]="pageNumControl.value === 1">
                        <mat-icon>chevron_left</mat-icon>
                    </button>

                    <div class="tw-flex tw-items-center tw-justify-center tw-gap-4">
                        <input id="page-number" [formControl]="pageNumControl" type="number" inputmode="numeric" min="0" [max]="numPages() || 1">
                        <div>/</div>
                        <div>{{ numPages() }}</div>
                    </div>

                    <button mat-icon-button (click)="nextPage()" [disabled]="pageNumControl.value === numPages()">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            }
        </div>
    }
}
