// @ts-nocheck
import { module } from 'angular';

module('eaw.components').component('eawDialCode', {
    template: `<md-autocomplete
        ng-disabled="$ctrl.disabled || !$ctrl.codes.length"
        ng-required="$ctrl.required"
        uniq-form-name
        md-selected-item-change="$ctrl.codeChanged()"
        md-floating-label="{{'DIAL_CODE' | i18next}}"
        md-no-cache="true"
        md-clear-button="!$ctrl.isDisabled"
        md-selected-item="$ctrl.code"
        md-require-match="true"
        md-search-text="codeSearch"
        md-delay="300"
        md-items="code in $ctrl.search(codeSearch)"
        md-item-text="code.codeDisplay"
        md-input-name="dialCodeAutocomplete"
        md-min-length="0">
    <md-item-template>
        <span md-highlight-text="codeSearch" md-highlight-flags="gi">{{code.displayText}}</span>
    </md-item-template>
    <md-not-found>
        <span ng-i18next="NO_MATCH_FOUND"></span>
    </md-not-found>
</md-autocomplete>
`,
    require: {
        ngModel: 'ngModel',
    },
    controller: [ 'CountryService', '$attrs', function(CountryService, $attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.ngModel.$formatters.unshift(ctrl.formatter);

            $attrs.$observe('required', function(value) {
                ctrl.required = value;
            });

            $attrs.$observe('disabled', function(value) {
                ctrl.disabled = value;
            });

            ctrl.getCodes();
        };

        ctrl.getCodes = () => {
            CountryService.then((resp) => {
                ctrl.codes = resp.data.reduce((arr, country) => arr.concat([ {
                    displayText: `${country.name} (+${country.Dial})`,
                    codeDisplay: `+${country.Dial}`,
                    code: country.Dial,
                } ]), []);

                ctrl.formatter(ctrl.ngModel.$modelValue);
            });
        };

        ctrl.search = (search) => ctrl.codes.filter((code) => code.displayText.toLowerCase().includes(search.toLowerCase()));

        ctrl.formatter = (val) => {
            if (val == null) {
                return val;
            }
            ctrl.code = ctrl.codes?.find((c) => c.code === val);
            return val;
        };

        ctrl.codeChanged = () => {
            ctrl.ngModel.setViewValue(ctrl.code?.code || null);
        };
    } ],
});
