<eaw-page-header [fabButton]="fabButton">
    <div title>{{ 'CUSTOMER_GROUP_plural' | translate | async }}</div>
</eaw-page-header>

<mat-card>
    <eaw-data-table [columns]="columns"
                    [getData]="getData()"
                    initialSortBy="created_at"
                    [goTo]="{state: 'eaw/app/admin/customer_groups/view/customers', params: [{stateKey: 'id', itemKey: 'id'}]}">
    </eaw-data-table>
</mat-card>
