<eaw-dialog-header>
    <span title>{{'ADD_QUALIFICATION' | translate: 'company' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form">
        <eaw-autocomplete *ngIf="!data.updatedQualification && !data.qualificationId"
                          formControlName="qualification" class="tw-col-span-full"
                          [options]="this.qualifications"
                          [setter]="qualificationAutocompleteService.setter({customerId: data.customerId})"
                          [getter]="qualificationAutocompleteService.getter({customerId: data.customerId})">
        </eaw-autocomplete>
        <eaw-autocomplete *ngIf="!data.employeeId"
                          formControlName="employee" class="tw-col-span-full"
                          [options]="this.employees"
                          [setter]="employeeAutocompleteService.setter({customerId: data.customerId})"
                          [getter]="employeeAutocompleteService.getter({customerId: data.customerId})">
        </eaw-autocomplete>
        <mat-form-field *ngIf="data.updatedQualification">
            <input matInput disabled [value]="data.updatedQualification.name"/>
        </mat-form-field>
        <mat-form-field *ngIf="!data.updatedQualification">
            <mat-label>{{'QUALIFICATION_LEVEL' | translate:'company' | async}}</mat-label>
            <mat-select formControlName="qualificationLevel">
                <mat-option *ngFor="let percentage of percentages" [value]="percentage.percent">
                    {{percentage.text | async}}
                </mat-option></mat-select>
        </mat-form-field>
        <mat-form-field eawDatePickerOptions class="tw-col-[1_/_3]">
            <mat-label>{{'FROM' | translate | async}}</mat-label>
            <input matInput formControlName="from" [matDatepicker]="fromPicker" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field eawDatePickerOptions class="tw-col-[3_/_5]">
            <mat-label>{{'TO' | translate | async}}</mat-label>
            <input matInput formControlName="to" [matDatepicker]="toPicker" [min]="form.controls.from.value || minDate">
            <button *ngIf="form.controls.to?.value" matSuffix mat-icon-button aria-label="Clear" (click)="form.controls.to?.setValue(null)">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="false">{{'CANCEL' | translate | async}}</button>
    <button mat-raised-button *ngIf="!data.updatedQualification" [disabled]="form.invalid" color="accent" (click)="save()">{{'ADD_QUALIFICATION' | translate: 'company' | async}}</button>
    <button mat-raised-button *ngIf="data.updatedQualification" color="accent" (click)="save()">{{'SUBMIT' | translate | async}}</button>
</mat-dialog-actions>
