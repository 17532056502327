// @ts-nocheck
import { orderBy } from 'lodash-es';

import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-card>
    <md-card-content>
        <form name="projectionsForm">
            <div layout="row" layout-wrap>
                <eaw-date-interval layout="row"
                                   ng-model="$ctrl.dateInterval"
                                   required
                                   flex="100"
                                   flex-md="50"
                                   flex-gt-md="50">
                </eaw-date-interval>

                <md-input-container flex="100" flex-sm="50" flex-gt-sm="25">
                    <label ng-i18next="ai_budgeting:VARIABLE"></label>
                    <md-select ng-model="$ctrl.variable" required>
                        <md-option ng-repeat="variable in $ctrl.bucket.variables track by variable.uuid" ng-value="variable">
                            <span ng-bind="variable.name"></span>
                        </md-option>
                    </md-select>
                </md-input-container>

                <md-input-container flex="100" flex-sm="50" flex-gt-sm="25">
                    <label ng-i18next="INTERVAL"></label>
                    <md-select ng-model="$ctrl.timeInterval" required>
                        <md-option ng-repeat="(text, interval) in $ctrl.constants.intervals track by $index" ng-value="interval">
                            <span ng-bind="text | eawTranslate:'ai_budgeting'"></span>
                        </md-option>
                    </md-select>
                </md-input-container>
            </div>

            <md-button ng-i18next="SUBMIT" class="md-raised md-accent" ng-disabled="projectionsForm.$invalid || $ctrl.waiting" ng-click="$ctrl.getData()"></md-button>
        </form>
    </md-card-content>
</md-card>

<md-progress-circular ng-if="$ctrl.waiting" md-diameter="45" class="md-progress-center tw-mt-20"></md-progress-circular>

<md-card ng-if="!$ctrl.waiting && $ctrl.timeSeries">
    <md-card-content class="tw-p-0">
        <table class="table table-striped">
            <thead>
            <tr>
                <th ng-i18next="DATE"></th>
                <th class="text-right" ng-i18next="BUDGET"></th>
                <th ng-show="$ctrl.isDayInterval" ng-i18next="ai_budgeting:COMPARABLE_DATES"></th>
                <th ng-show="$ctrl.isDayInterval"></th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="entry in $ctrl.timeSeries.entries">
                <td ng-bind="entry.displayStringBasedOnInterval"></td>
                <td class="text-right">
                    <div layout="row" layout-align="end center" ng-if="entry._editing">
                        <md-input-container md-no-float class="md-block tw-mr-4 budget-input">
                            <input ng-model="entry.value" type="number" placeholder="Budget">
                        </md-input-container>

                        <md-icon md-colors="{color: 'green'}"
                                 class="tw-m-0 hover-item"
                                 ng-bind="'done'"
                                 ng-click="$ctrl.saveBudget(entry)">
                        </md-icon>

                        <md-icon md-colors="{color: 'red'}"
                                 class="tw-m-0 tw-ml-4 hover-item"
                                 ng-bind="'close'"
                                 ng-click="$ctrl.cancelSaveBudget(entry)">
                        </md-icon>
                    </div>

                    <div layout="row" layout-align="end center" ng-if="!entry._editing">
                        <span class="tw-mr-4" ng-bind="entry.value | eawCurrency"></span>

                        <md-icon class="tw-m-0 hover-item" ng-bind="'edit'" ng-click="$ctrl.editBudget(entry)"></md-icon>
                    </div>
                </td>
                <td ng-show="$ctrl.isDayInterval">
                    <md-chips class="comparable-dates-chips" ng-model="entry.comparableDates" readonly="true" md-removable="true">
                        <md-chip-template>
                            <span ng-bind="$chip.date | moment:'LL'"></span>
                        </md-chip-template>
                    </md-chips>
                </td>
                <td class="text-center" ng-show="$ctrl.isDayInterval">
                    <eaw-icon-button icon="add" ng-click="$ctrl.addDate(entry)" stop-propagate></eaw-icon-button>
                </td>
            </tr>
            </tbody>
        </table>
    </md-card-content>
</md-card>

<md-card ng-show="!$ctrl.waiting && $ctrl.timeSeries">
    <md-card-content>
        <form layout="row" name="forecastSubmit">
            <md-input-container flex="100" flex-gt-xs="25">
                <label ng-i18next="ai_budgeting:APPLY_PROJECTIONS"></label>
                <md-select ng-model="$ctrl.applyProjections">
                    <md-option ng-value="true">{{'YES' | i18next}}</md-option>
                    <md-option ng-value="false">{{'NO' | i18next}}</md-option>
                </md-select>
                <!--                <div class="hint">Skriv et hint</div>-->
            </md-input-container>

            <md-input-container flex="100" flex-gt-sm="25">
                <label ng-i18next="NAME"></label>
                <input ng-model="$ctrl.name" required uniq-form-name>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="25" ng-show="$ctrl.isDayInterval">
                <label ng-i18next="ai_budgeting:PROPAGATE_PROJECTIONS"></label>
                <md-select ng-model="$ctrl.propagateProjections">
                    <md-option ng-value="true">{{'YES' | i18next}}</md-option>
                    <md-option ng-value="false">{{'NO' | i18next}}</md-option>
                </md-select>
            </md-input-container>

            <md-button class="md-raised md-normal-size md-accent"
                       ng-disabled="forecastSubmit.$invalid"
                       ng-click="$ctrl.submit()"
                       ng-i18next="SUBMIT">
            </md-button>
        </form>
    </md-card-content>
</md-card>
`;

import MlTimeSeriesEntry from '../shared/ml-timeseries-entry';
import MlTimeSeries from '../shared/ml-timeseries';

module('eaw.ai-budgeting').component('aiBudgetForecasts', {
    template: template1,
    bindings: {
        customer: '<',
        bucket: '<',
        constants: '<',
    },
    controller: [ 'eawDatePickerDialog', 'mlTimeSeriesFactory', 'errorService', 'mlProjectionsFactory', function(eawDatePickerDialog, mlTimeSeriesFactory, errorService, mlProjectionsFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.applyProjections = true;
            ctrl.dateInterval = {
                from: moment().subtract(1, 'w').subtract(1, 'd').startOf('d'),
                to: moment().subtract(1, 'd').endOf('d'),
            };
        };

        ctrl.submit = () => {
            ctrl.waiting = true;
            mlProjectionsFactory.create.query(ctrl.customer.id, ctrl.bucket.id, ctrl.timeSeries.uuid, {
                from: ctrl.dateInterval.from,
                to: ctrl.dateInterval.to,
                name: ctrl.name,
                applyProjections: ctrl.applyProjections,
                propagateProjections: ctrl.propagateProjections,
                projections: ctrl.timeSeries.projections,
                comparableDates: ctrl.createComparableDates(),
            }).then(ctrl.getData).catch(() => delete ctrl.waiting);
        };

        ctrl.createComparableDates = () => {
            const data = {};

            ctrl.timeSeries.entries.forEach((e) => {
                data[e.dateString] = e.comparableDates;
            });

            return data;
        };

        ctrl.getData = () => {
            const selectedInterval = ctrl.timeInterval;
            const from = ctrl.dateInterval.from;
            const to = ctrl.dateInterval.to;

            ctrl.waiting = true;
            mlTimeSeriesFactory.getAll.query(
                ctrl.customer.id,
                ctrl.bucket.id,
                ctrl.constants.time_series_types.FORECAST,
                ctrl.variable.id,
            ).then((resp) => {
                ctrl.timeSeries = resp.data.map((d) => new MlTimeSeries(d));
                ctrl.timeSeries = ctrl.timeSeries.find((ts) => ts.interval === parseInt(selectedInterval));

                if (!ctrl.timeSeries) {
                    errorService.showError({
                        status: 0,
                        data: {
                            message: 'No timeseries of the selected interval found',
                        },
                    });
                }

                mlTimeSeriesFactory.getEntries.query(
                    ctrl.customer.id,
                    ctrl.timeSeries.bucket_uuid,
                    ctrl.timeSeries.uuid,
                    from,
                    to,
                    true,
                ).then((entriesResp) => {
                    entriesResp.entries.forEach((e) => {
                        ctrl.timeSeries.addEntry(new MlTimeSeriesEntry(e, ctrl.timeSeries));
                    });
                }).finally(() => delete ctrl.waiting);
            }).catch(() => delete ctrl.waiting);
        };

        ctrl.editBudget = (entry) => {
            entry._prevValue = entry.value;
            entry._editing = true;
        };

        ctrl.cancelSaveBudget = (entry) => {
            entry.value = entry._prevValue;
            entry._editing = false;
        };

        ctrl.saveBudget = (entry) => {
            entry._editing = false;
        };

        ctrl.orderComparableDates = (dates) => orderBy(dates, (d) => +d.date, 'asc');

        ctrl.addDate = (row) => {
            const options = {
                maxDate: moment(),
                dateFilter(date) {
                    const mom = moment([ date.getFullYear(), date.getMonth(), date.getDate() ]);
                    return !row.comparableDates.some((d) => d.date.isSame(mom, 'd'));
                },
                callback(date) {
                    ctrl.previousPickedDate = date.clone();

                    row.comparableDates.push({
                        date,
                        match: 1,
                    });
                    row.comparableDates = ctrl.orderComparableDates(row.comparableDates);
                },
            };

            eawDatePickerDialog.open(ctrl.previousPickedDate, options);
        };
    } ],
});
