import { AfterViewInit, ContentChild, Directive, ElementRef, HostListener, Inject, OnDestroy } from '@angular/core';
import { MatDatepicker, MatDateRangeInput, MatDateRangePicker, MatEndDate, MatStartDate } from '@angular/material/datepicker';
import type { DateTime } from 'luxon';
import { Mobile } from '../utils/eaw-mobile';

@Directive({
    selector: 'mat-form-field[eawDatePickerOptions]',
    standalone: true,
})
export class DatePickerOptionsDirective implements AfterViewInit, OnDestroy {
    @ContentChild(MatStartDate) matStartDate?: MatStartDate<DateTime>;
    @ContentChild(MatEndDate) matEndDate?: MatEndDate<DateTime>;
    @ContentChild(MatDateRangeInput) matDateRangeInput?: MatDateRangeInput<DateTime>;
    @ContentChild(MatDateRangePicker) matDateRangePicker?: MatDateRangePicker<DateTime>;
    @ContentChild(MatDatepicker) matDatepicker?: MatDatepicker<DateTime>;

    @HostListener('click') onClick() {
        if (this.inputHasFocus) {
            return;
        }
        this.matDateRangeInput?.rangePicker.open();
        this.matDatepicker?.open();
    }

    private inputs?: HTMLInputElement[];
    private inputHasFocus = false;

    constructor(@Inject(ElementRef) private el: ElementRef) {}

    ngAfterViewInit(): void {
        this.inputs = Array.from(this.el.nativeElement.querySelectorAll('input') || []);
        this.inputs.forEach((input) => input.addEventListener('blur', this.onInputBlur.bind(this)));
        document.addEventListener('click', this.onDocumentClick.bind(this));
        this.inputs.forEach((i) => i.readOnly = Mobile.isTouchDevice);

        if (this.matDateRangePicker) {
            this.matDateRangePicker.restoreFocus = false;
            this.matDateRangePicker.touchUi = Mobile.isTouchDevice;
        }

        if (this.matDatepicker) {
            this.matDatepicker.restoreFocus = false;
            this.matDatepicker.touchUi = Mobile.isTouchDevice;
        }
    }

    ngOnDestroy(): void {
        this.inputs?.forEach((input) => input.removeEventListener('blur', this.onInputBlur.bind(this)));
        document.removeEventListener('click', this.onDocumentClick.bind(this));
    }

    private onDocumentClick(e: MouseEvent) {
        const target = e.target as HTMLElement;
        if (!/mat-datepicker-\d+-backdrop/.test(target.className)) {
            return;
        }

        if (this.matDateRangePicker) {
            this.dateRangePickerClick(e);
        }

        if (this.matDatepicker) {
            this.datePickerClick(e);
        }
    }

    private datePickerClick(e: MouseEvent) {
        const target = e.target as HTMLElement;
        if (!/mat-datepicker-\d+-backdrop/.test(target.className)) {
            return;
        }

        const elements = document.elementsFromPoint(e.x, e.y);

        if (elements.some((e) => e === this.el.nativeElement)) {
            const input = this.el.nativeElement.querySelector('input') as HTMLInputElement;
            input?.focus();
            this.inputHasFocus = !!input;
        }
    }

    private dateRangePickerClick(e: MouseEvent) {
        const origin = this.matDateRangeInput?.getConnectedOverlayOrigin();
        const elements = document.elementsFromPoint(e.x, e.y);

        if (elements.some((e) => e === origin?.nativeElement)) {
            if (elements.find((e) => e.classList.contains('mat-end-date'))) {
                this.matEndDate?.focus?.();
            } else {
                this.matStartDate?.focus?.();
            }

            this.inputHasFocus = true;
        }
    }

    private onInputBlur(e: FocusEvent) {
        if (this.matDateRangePicker) {
            this.inputHasFocus = this.matDateRangeInput?.getConnectedOverlayOrigin().nativeElement.contains(e.relatedTarget) || false;
        }
        if (this.matDatepicker) {
            this.inputHasFocus = false;
        }
    }
}
