// @ts-nocheck
import { uniqueId } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { module } from 'angular';

module('eaw.checklists').component('eawAddChecklistItem', {
    template: `<div class="new-item-row" layout="row">
    <md-input-container>
        <md-select ng-model="$ctrl.newChecklistItemType">
            <md-option ng-repeat="(type, value) in $ctrl.checklistTypes" ng-value="value" ng-selected="value.type == 'boolean'">
                <span ng-bind="value.text"></span>
            </md-option>
            <md-option ng-value="'category'">
                <span ng-i18next="checklists:CATEGORY"></span>
            </md-option>
        </md-select>
    </md-input-container>

    <md-input-container flex md-no-float>
        <input ng-model="$ctrl.newChecklistItemTitle" ng-i18next="[placeholder]checklists:NEW_ITEM" autocomplete="off" eaw-on-enter="$ctrl.addItem()">
    </md-input-container>

    <md-button class="md-icon-button" ng-click="$ctrl.addItem()" ng-disabled="!$ctrl.newChecklistItemTitle">
        <md-icon ng-bind="'add'">
            <md-tooltip md-direction="left" ng-i18next="ADD"></md-tooltip>
        </md-icon>
    </md-button>
</div>
`,
    bindings: {
        parent: '<',
        onAdd: '&?',
    },
    require: {
        checklistTemplate: '^^eawChecklistTemplate',
    },
    controller: [ 'ChecklistItemFactory', 'ChecklistCategoryFactory', 'ChecklistsService', function(ChecklistItemFactory, ChecklistCategoryFactory, ChecklistsService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.ChecklistsService = ChecklistsService;
            ctrl.checklistTypes = ctrl.ChecklistsService.getTypes();
            // Init type to boolean type
            ctrl.newChecklistItemType = ctrl.checklistTypes.boolean;
        };

        ctrl.onSuccess = (data) => {
            ctrl.checklistTemplate.add(data);
            ctrl.onBoth(data);
            ctrl.onAdd?.();
        };

        ctrl.onBoth = (data) => {
            data = data || ctrl.item;
            ctrl.checklistTemplate.afterUpdate();
            ctrl.ChecklistsService.cleanAddedWeight(data);
        };

        ctrl.addItem = () => {
            if (!Object.keys(ctrl.newChecklistItemTitle || []).length) {
                return;
            }

            ctrl.item = {
                options: ctrl.checklistTypes?.[ctrl.newChecklistItemType.name]?.defaultOptions,
                id: uniqueId('checklist-item'),
                title: ctrl.newChecklistItemTitle,
                type: ctrl.newChecklistItemType.type,
                temp: true,
                parent_id: !ctrl.parent.parent_id ? null : ctrl.parent.id,
                weight: ctrl.ChecklistsService.findWeight(ctrl.parent),
            };

            // Clear title
            ctrl.newChecklistItemTitle = null;

            // Before update
            ctrl.checklistTemplate.beforeUpdate();

            if (ctrl.newChecklistItemType === 'category') {
                ChecklistCategoryFactory.create(ctrl.checklistTemplate.customer, ctrl.checklistTemplate.checklist, ctrl.item).$promise.then(ctrl.onSuccess).catch(ctrl.onBoth);
            } else {
                if (ctrl.newChecklistItemType.name === 'select') {
                    ctrl.newChecklistItemType.advanced(ctrl.item).then((updatedItem) => {
                        ChecklistItemFactory.create(ctrl.checklistTemplate.customer, ctrl.checklistTemplate.checklist, updatedItem).$promise.then(ctrl.onSuccess).catch(ctrl.onBoth);
                    });
                } else {
                    ChecklistItemFactory.create(ctrl.checklistTemplate.customer, ctrl.checklistTemplate.checklist, ctrl.item).$promise.then(ctrl.onSuccess).catch(ctrl.onBoth);
                }
            }
        };
    } ],
});
