<div class="tw-grid tw-grid-cols-2 tw-gap-20">
    <section id="roles" class="md:tw-mr-20">
        <eaw-page-header [fabButton]="createButton">
            <span title>{{'ROLE_plural' | translate: 'company' | async}}</span>
        </eaw-page-header>
        <eaw-data-table
            class="mat-elevation-z4"
            #table
            [columns]="columns"
            [request]="request"
            initialSortBy="name"
            initialSortDirection="asc"
            (rowClick)="openRole($event)"
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </section>
    <section *ngIf="role" id="assignments" class="max-sm:tw-mt-20">
        <eaw-page-header [fabButton]="createAssignmentButton">
            <span title>{{role.name}}</span>
        </eaw-page-header>
        <eaw-role-assignment-data-table
                #assignments
                class="mat-elevation-z4"
                [role]="role"
                [customer]="customer"
                [columns]="assignmentColumns">
        </eaw-role-assignment-data-table>
    </section>
</div>
