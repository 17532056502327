import { inject, Pipe, PipeTransform } from '@angular/core';
import type { NamespaceFile } from '../enums/namespace';
import { TranslateService } from '../services/translate.service';

@Pipe({
    name: 'translateSync',
    standalone: true,
})
export class TranslateSyncPipe implements PipeTransform {
    private readonly translate = inject(TranslateService);
    transform(key?: unknown, ns: NamespaceFile = 'general', options?: Record<string, any>): string {
        if (typeof key !== 'string') {
            return '';
        }

        return this.translate.syncT(key, ns, options);
    }
}
