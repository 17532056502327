export type KpiProviderSumResponse = {
    id: number;
    value: number;
    kpi_type_id: number;
    customer_id: number;
} | null;

export class KpiProviderSum {
    id: number;
    value: number;
    kpiTypeId: number;
    customerId: number;

    constructor(data: KpiProviderSumResponse) {
        this.id = data?.id || 0;
        this.value = data?.value || 0;
        this.kpiTypeId = data?.kpi_type_id || 0;
        this.customerId = data?.customer_id || 0;
    }
}
