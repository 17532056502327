// @ts-nocheck
import { module } from 'angular';
module('eaw.ai-budgeting').factory('mlBucketFactory', [ 'EawResource', 'Pagination', function mlBucketFactory(EawResource, Pagination) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/ml_buckets/:bucket');
    fac.get = {
        permission(customer, bucket) {
            return `customers.${customer}.ml_buckets.${bucket}.get`;
        },
        query(customer, bucket) {
            return res.get({
                customer,
                bucket,
            }).$promise;
        },
    };
    fac.getAll = {
        permission() {
            return `ml_buckets.*.get`;
        },
        query(options, pagination) {
            return EawResource.create('/ml_buckets').get({
                tags: options?.tags,
                tags_match_type: options?.tagsMatchType,
                ...Pagination.getParams(pagination),
            }).$promise;
        },
    };
    fac.getAllForCustomer = {
        permission(customer) {
            return `customers.${customer}.ml_buckets.*.get`;
        },
        query(customer, pagination) {
            return res.get({
                customer,
                'with[]': 'variables',
                ...Pagination.getParams(pagination),
            }).$promise;
        },
    };
    fac.create = {
        permission(customer) {
            return `customers.${customer}.ml_buckets.create`;
        },
        /**
         * @param {Number} customer
         * @param {Object} args
         * @param {String} args.name
         * @param {String} [args.description]
         * @param {Object} [args.tags]
         * @returns {Promise}
         */
        query(customer, args) {
            return res.save({
                customer,
            }, {
                name: args.name,
                description: args.description,
                tags: args.tags,
            }).$promise;
        },
    };
    fac.update = {
        permission(customer, bucket) {
            return `customers.${customer}.ml_buckets.${bucket}.update`;
        },
        /**
         * @param {Number} customer
         * @param {String} bucket
         * @param {Object} args
         * @param {String} args.name
         * @param {String} [args.description]
         * @param {Object} [args.tags]
         * @returns {Promise}
         */
        query(customer, bucket, args) {
            return res.update({
                customer,
                bucket,
            }, {
                tags: args.tags,
            }).$promise;
        },
    };
    return fac;
} ]);
