// @ts-nocheck
import { module } from 'angular';

module('eaw.components', [
    'ngMaterial',
    'ngFileUpload',
    'ngTable',
    'eaw.resource',
    'eaw.alerts',
]);
