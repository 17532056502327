import { MatStep, MatStepper } from '@angular/material/stepper';
import { Component, ElementRef, inject, Input, OnInit } from '@angular/core';

@Component({
    standalone: true,
    template: '',
})
export class NewEmployeeStepComponent implements OnInit {
    protected readonly elementRef = inject(ElementRef);

    @Input({ required: true }) stepper!: MatStepper;
    @Input({ required: true }) step!: MatStep;

    ngOnInit() {
        this.step.completed = this.step.optional;
        this.elementRef.nativeElement.classList.add('new-employee-step');
    }
}
