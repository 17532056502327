import { Pipe, PipeTransform } from '@angular/core';
import { t } from 'i18next';

@Pipe({
    name: 'roundToFraction',
    standalone: true,
})
export class RoundToFractionPipe implements PipeTransform {
    transform(value: number | string, fraction = 4, digits = 2): string {
        const num = typeof value === 'number' ? value : parseFloat(value);

        if (!Number.isFinite(num)) {
            console.warn('Not a finite number', num);
            return t('NA') as string;
        }

        return (Math.round(num * fraction) / fraction).toFixed(digits);
    }
}
