// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
const chooseProviderTemplate = `<md-dialog>
    <eaw-dialog-header-old heading="'digisign:SIGN_WITH_QUESTION' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <md-list class="md-dense tw-pt-0">
            <md-list-item class="hover-item" ng-repeat="signable in choiceCtrl.alternatives track by $index" close-dialog resolve="true" reason="signable.provider">
                <md-divider ng-show="$first"></md-divider>
                <div class="md-list-item-text text-center">
                    <span ng-bind="signable.translated"></span>
                </div>
                <md-divider ng-show="$middle || $last"></md-divider>
            </md-list-item>
        </md-list>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
    </md-dialog-actions>

</md-dialog>
`;
module('eaw.digisign').factory('chooseSignatureProviderDialog', [ '$mdDialog', function($mdDialog) {
    const template = chooseProviderTemplate;
    function choose(alternatives) {
        return $mdDialog.show({
            template: chooseProviderTemplate,
            controllerAs: 'choiceCtrl',
            controller: function() {
                this.alternatives = alternatives.map((alt) => {
                    const split = alt.provider.split('\\');
                    const name = split[split.length - 1];
                    return {
                        translated: t(`hr:${name}`),
                        provider: alt.provider,
                    };
                });
            },
        });
    }
    return {
        choose,
        template,
    };
} ]);
