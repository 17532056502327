import { module } from 'angular';
import { EawFormSubmission } from '../../../types/form-submission';
import { EawForm } from '../../../types/form';

export class FormController {
    submission?: EawFormSubmission;
    ngModel!: angular.INgModelController&{setViewValue: (value: any) => void;};
    form?: EawForm;
    disabled = false;
    $onInit() {
        this.ngModel.$parsers.unshift((modelValue: EawFormSubmission) => {
            this.submission = modelValue;
            return this.submission;
        });
        this.ngModel.$formatters.unshift((modelValue) => {
            this.submission = modelValue || {};
            return this.submission;
        });
    }

    onChange(name: string, value: any) {
        this.ngModel.setViewValue(Object.assign(this.submission || {}, { [name]: value }));
    }
}
module('eaw.forms').component('eawForm', {
    template: `<fieldset ng-form="eawForm" layout="column" layout-margin ng-disabled="$formCtrl.disabled">
    <eaw-form-element ng-repeat="element in $formCtrl.form.children" data="$formCtrl.submission" layout="column" element="element"></eaw-form-element>
</fieldset>`,
    require: {
        ngModel: 'ngModel',
    },
    bindings: {
        form: '<',
        disabled: '<?',
    },
    controllerAs: '$formCtrl',
    controller: FormController,
});
