<eaw-dialog-header>
    <span title>{{'LINK' | translate:'links' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form class="tw-flex tw-flex-col" [formGroup]="form">

        <mat-form-field>
            <mat-label>{{'TITLE' | translate | async}}</mat-label>
            <input formControlName="text" matInput>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'URL' | translate | async}}</mat-label>
            <input type="url" formControlName="url" matInput>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'DESCRIPTION' | translate | async}}</mat-label>
            <input formControlName="description" matInput>
        </mat-form-field>

        <div class="slide-toggle">
            <mat-slide-toggle formControlName="mainMenu">
                <mat-label>{{'SHOW_IN_MAIN_MENU' | translate: 'links' | async}}</mat-label>
            </mat-slide-toggle>
        </div>

        <div class="slide-toggle">
            <mat-slide-toggle formControlName="inheritable">
                <mat-label>{{'SHARE_WITH_CUSTOMER_LINKS' | translate: 'links' | async}}</mat-label>
            </mat-slide-toggle>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="" >{{'CANCEL' | translate | async}}</button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="form.invalid">{{'SUBMIT' | translate | async}}</button>
</mat-dialog-actions>
