import { Component, inject, Inject, OnInit, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { Shift } from '../../models/shift';
import { DateTime } from 'luxon';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { MatButtonModule } from '@angular/material/button';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { HandleShiftComponent } from '../../components/handle-shift/handle-shift.component';
import { ScheduleService } from '../../http/schedule.service';
import { Schedule } from '../../models/schedule';
import { Observable } from 'rxjs';

export interface CreateNewShiftDialogData extends DialogData {
    customerId: number;
    scheduleId: number;
    schedule?: Observable<Schedule>;
    withs: string[];
    date?: DateTime;
    employeeId?: number;
}

export type CreateNewShiftDialogReturn = Shift;

@Component({
    selector: 'eaw-create-new-shift-dialog',
    standalone: true,
    imports: [ CommonModule, DialogHeaderComponent, MatDialogActions, MatButtonModule, ActionButtonComponent, TranslatePipe, HandleShiftComponent, MatDialogClose, MatDialogContent ],
    templateUrl: './create-new-shift-dialog.component.html',
    styleUrl: './create-new-shift-dialog.component.scss',
})
export class CreateNewShiftDialogComponent extends DialogComponent implements OnInit {
    private scheduleService = inject(ScheduleService);

    protected handleShiftComponent = viewChild(HandleShiftComponent);

    protected schedule?: Schedule;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateNewShiftDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<CreateNewShiftDialogComponent, CreateNewShiftDialogReturn>,
    ) {
        data.size ||= DialogSize.Large;
        dialogRef.disableClose = true;
        super(dialogRef, data);
    }

    ngOnInit() {
        (this.data.schedule || this.scheduleService.get(this.data.customerId, this.data.scheduleId)).subscribe((schedule) => {
            this.schedule = schedule;
        });
    }

    close(shift?: Shift) {
        this.dialogRef.close(shift);
    }
}
