// @ts-nocheck
import { t } from 'i18next';
import { module, noop } from 'angular';
module('eaw.coupons').component('eawCoupon', {
    controllerAs: 'coupon',
    template: `<div class="coupon-wrapper" ng-class="{used: coupon.coupon.used}">
    <div class="coupon hover-item" layout="row" ng-click="coupon.use()">
        <div class="coupon-image">
            <img ng-src="{{coupon.image}}" alt="Coupon image">
        </div>
        <section class="middle" flex layout="column">
            <img class="coupon-icon" ng-src="{{coupon.icon}}" alt="Icon">
            <div layout="column" layout-align="center start">
                <div>
                    <span class="coupon-value" ng-bind="coupon.coupon.value"></span>
                    <span class="coupon-unit" ng-bind="coupon.coupon.unit"></span>
                </div>
                <span class="coupon-expiration" ng-i18next="[i18next]({date: coupon.coupon.status.date.format('lll')}){{coupon.coupon.status.text}}"></span>
            </div>
        </section>
        <section class="coupon-stub">
            <span class="barcode" ng-show="!coupon.coupon.used" ng-i18next="coupons:COUPON_READY"></span>
            <span class="barcode" ng-show="coupon.coupon.used" ng-i18next="coupons:USED"></span>
        </section>

        <svg width="0" height="0">
            <defs>
                <clipPath id="coupon" clipPathUnits="objectBoundingBox">
                    <path fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0,0.104 C0,0.061,0,0.039,0.005,0.024 C0.006,0.019,0.008,0.015,0.01,0.011 C0.016,0,0.025,0,0.043,0 H0.957 C0.975,0,0.984,0,0.99,0.011 C0.992,0.015,0.994,0.019,0.995,0.024 C1,0.039,1,0.061,1,0.104 V0.379 C1,0.387,1,0.392,0.999,0.394 C0.999,0.395,0.999,0.396,0.998,0.397 C0.998,0.399,0.996,0.4,0.991,0.402 C0.973,0.412,0.959,0.452,0.959,0.5 C0.959,0.548,0.973,0.588,0.991,0.598 C0.996,0.6,0.998,0.601,0.998,0.603 C0.999,0.604,0.999,0.605,0.999,0.606 C1,0.608,1,0.613,1,0.621 V0.896 C1,0.939,1,0.961,0.995,0.976 C0.994,0.981,0.992,0.985,0.99,0.989 C0.984,1,0.975,1,0.957,1 H0.043 C0.025,1,0.016,1,0.01,0.989 C0.008,0.985,0.006,0.981,0.005,0.976 C0,0.961,0,0.939,0,0.896 L0,0.621 C0,0.613,0,0.608,0.001,0.606 C0.001,0.605,0.001,0.604,0.002,0.603 C0.002,0.601,0.004,0.6,0.009,0.598 C0.027,0.588,0.041,0.548,0.041,0.5 C0.041,0.452,0.027,0.412,0.009,0.402 C0.004,0.4,0.002,0.399,0.002,0.397 C0.001,0.396,0.001,0.395,0.001,0.394 C0,0.392,0,0.387,0,0.379 L0,0.104"/>
                </clipPath>
            </defs>
        </svg>
    </div>
</div>
`,
    bindings: {
        coupon: '<',
        usedCoupon: '&?',
    },
    controller: [ 'showCoupon', function(showCoupon) {
        this.$onInit = () => {
            this.image = this.coupon.image;
            this.icon = this.coupon.icon;
            this.value = `${this.coupon.value}${this.coupon.unit}`;
            const status = this.coupon.status;
            this.expirationText = t(status.text, { date: status.date.format('lll') });
        };
        this.use = () => {
            showCoupon(this.coupon).then((reason) => {
                if (reason == 'use') {
                    this.usedCoupon();
                }
            }).catch(noop);
        };
    } ],
});
