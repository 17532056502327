import { EnvironmentInjector } from '@angular/core';
import { EmployeeSidenavService } from '../services/employee-sidenav.service';
import { EmployeeSidenavHeaderComponent } from '../components/employee-sidenav-header/employee-sidenav-header.component';
import { paramResolver } from '../resolvers/param.resolver';
import { sidenavHeaderInputsResolver } from '../resolvers/sidenav-header-inputs.resolver';
import { currentResolver } from '../resolvers/current.resolver';

export function addLocalEmployeeInfoSidenav(injector: EnvironmentInjector) {
    injector.get(EmployeeSidenavService).init({
        sidenavParentState: `eaw/app/company/employees/local`,
        sidenavState: `eaw/app/company/employees/view`,
        url: '/:emp',
        headerComponent: EmployeeSidenavHeaderComponent,
        data: {
            breadcrumb: { key: 'EMPLOYEE' },
            permissions: [ 'customers.{customer}.employees.{emp}.get' ],
        },
        resolve: [
            paramResolver('emp', 'employeeId'),
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('customerStackId', 'customer', 'stackId'),
            sidenavHeaderInputsResolver([ 'employeeId', 'customerId' ], function(employeeId: any, customerId: any) {
                return [
                    { key: 'employeeId', value: parseInt(employeeId) },
                    { key: 'customerId', value: parseInt(customerId) },
                ];
            }),
        ],
    }, 'customer', 'emp');
}
