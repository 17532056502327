import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeTask } from '../../../shared/models/employee-task';
import { EmployeeService } from '../../../shared/http/employee.service';
import { Employee } from '../../../shared/models/employee';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { catchError, of, take, tap } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { EawValidators } from '../../../shared/validators/eaw-validators';
import { FormRule, FormRulesComponent } from '../../../shared/components/form-rules/form-rules.component';
import { TranslateService } from '../../../shared/services/translate.service';

@Component({
    selector: 'eaw-update-pin-code',
    standalone: true,
    imports: [ CommonModule, ReactiveFormsModule, MatInputModule, TranslatePipe, ActionButtonComponent, MatIconModule, MatButtonModule, FormRulesComponent, FormsModule ],
    templateUrl: './update-pin-code-task.component.html',
    styleUrl: './update-pin-code-task.component.scss',
})
export class UpdatePinCodeTaskComponent {
    private readonly employeeService = inject(EmployeeService);
    private readonly translateService = inject(TranslateService);

    @Input({ required: true }) task!: EmployeeTask;
    @Input({ required: true }) employee!: Employee;

    protected readonly maxLength = 6;
    pinCodeInput: FormControl<string>;
    saving: boolean = false;
    errorMessages: FormRule[];

    constructor() {
        const validators = [
            Validators.required,
            EawValidators.pattern(/^.{6}$/, 'length'), // exact length
            EawValidators.pattern(/^(\d|[a-z])+$/, 'characters'), // numbers or lowercase letters
            EawValidators.pattern(/([^a-z]*[a-z]){2,}/, 'lowercase'), // at least 2 lowercase letters
            EawValidators.pattern(/(\D*\d){4,}/, 'numbers'), // at least 4 numbers
        ];

        this.pinCodeInput = new FormControl<string>('', { nonNullable: true, validators });

        this.errorMessages = [
            {
                key: 'length',
                message: this.translateService.t('EXACT_INPUT_LENGTH', 'general', { count: this.maxLength }),
            },
            {
                key: 'characters',
                message: this.translateService.t('INPUT_CONTAIN_NUMBERS_OR_LOWERCASE_LETTERS', 'general'),
            },
            {
                key: 'lowercase',
                message: this.translateService.t('INPUT_CONTAIN_AT_LEAST_LOWERCASE_LETTERS', 'general', { count: 2 }),
            },
            {
                key: 'numbers',
                message: this.translateService.t('INPUT_CONTAIN_AT_LEAST_NUMBERS', 'general', { count: 4 }),
            },
        ];
    }

    updatePinCode() {
        this.saving = true;
        const pinCode = this.pinCodeInput.value;
        // disable input while saving
        this.pinCodeInput.disable();
        this.employeeService
            .setPinCode(this.employee.customerId, this.employee.id, pinCode)
            .pipe(
                take(1),
                tap(() => {
                    // If all is well, complete the task
                    this.task.complete();
                }),
                catchError(() => {
                    this.pinCodeInput.enable();
                    return of(null);
                }),
                tap(() => {
                    this.saving = false;
                }),
            )
            .subscribe();
    }
}
