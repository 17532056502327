// @ts-nocheck
import { module } from 'angular';
/**
 * @ngdoc factory
 * @name signableService
 */
module('eaw.digisign').factory('signableService', [ 'EawResource', 'Pagination', 'Signable', function(EawResource, Pagination, Signable) {
    const resource = EawResource.create('/customers/:customerId/signables/:signableId');
    let modelResource;
    return {
        /**
         * @memberOf signableService
         */
        models: {
            permission() {
                return '';
            },
            query: function(pagination) {
                if (!modelResource) {
                    modelResource = EawResource.create('/signables');
                }
                return modelResource.get({
                    ...Pagination.getParams(pagination),
                });
            },
        },
        /**
         * @memberOf signableService
         */
        getAll: {
            permission(customerId) {
                return `customers.${customerId}.signables.*.get`;
            },
            query: function getAll(customerId, pagination, model) {
                return resource.get({
                    customerId,
                    ...Pagination.getParams(pagination),
                    model,
                }).$promise.then((data) => {
                    data.data = data.data.map((d) => new Signable(d));
                    return data;
                });
            },
        },
        /**
         * @memberOf signableService
         */
        get: {
            permission(customerId, signableId) {
                return `customers.${customerId}.signables.${signableId}.get`;
            },
            query: function get(customerId, signableId) {
                return resource.get({
                    customerId,
                    signableId,
                });
            },
        },
        /**
         * @memberOf signableService
         */
        create: {
            permission(customerId) {
                return `customers.${customerId}.signables.create`;
            },
            query(customerId, filter, provider, model) {
                return resource.save({ customerId }, {
                    model,
                    provider,
                    filter,
                });
            },
        },
        /**
         * @memberOf signableService
         */
        update: {
            permission(customerId, signableId) {
                return `customers.${customerId}.signables.${signableId}.update`;
            },
            query(customerId, signableId, filter, provider) {
                return resource.update({
                    customerId,
                    signableId,
                }, {
                    filter,
                    provider,
                });
            },
        },
        /**
         * @memberOf signableService
         */
        delete: {
            permission(customerId, signableId) {
                return `customers.${customerId}.signables.${signableId}.update`;
            },
            query(customerId, signableId) {
                return resource.delete({
                    customerId,
                    signableId,
                });
            },
        },
    };
} ]);
