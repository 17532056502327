import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { TariffAutocompleteService } from '../../../shared/autocompletes/tariff-autocomplete.service';
import { Tariff } from '../../../payroll/models/tariff';
import { RateType } from '../../../payroll/classes/rate';
import { catchError, EMPTY, forkJoin, map, Observable, of, startWith } from 'rxjs';
import { EmployeePayRateService } from '../../../payroll/http/employee-pay-rate.service';
import { PayRate } from '../../../payroll/models/pay-rate';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe, UpperCasePipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { CustomerTariffService } from '../../../payroll/http/customer-tariff.service';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

export interface PayRateDialogData extends DialogData {
    customerId: number;
    employeeId: number;
    payRate?: Observable<PayRate>;
    // Override and set "from" and "to" to a specific date range, unless a contract is provided
    fromTo?: {
        from?: DateTime | null,
        to?: DateTime | null
    };
}

export type PayRateDialogReturn = PayRate;

type PayRateForm = FormGroup<{
    from: FormControl<DateTime | null>;
    to: FormControl<DateTime | null>;
    useTariff: FormControl<boolean>;
    tariff?: FormControl<Tariff | number | null>;
    rate?: FormControl<number | null>;
    type?: FormControl<RateType>;
}>

@Component({
    selector: 'eaw-pay-rate-dialog',
    templateUrl: './pay-rate-dialog.component.html',
    styleUrl: './pay-rate-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        MatCheckboxModule,
        CheckboxHelperDirective,
        AutocompleteComponent,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        UpperCasePipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class PayRateDialogComponent extends DialogComponent<PayRateDialogData> implements OnInit {
    providedPayRate?: PayRate;
    loading = false;
    fromMinDate?: DateTime;
    payTypes: RateType[] = [ 'hour', 'day', 'week', 'biweekly', 'month', 'year' ];
    processing = false;
    form: PayRateForm = new FormGroup({
        from: new FormControl<DateTime | null>(null, Validators.required),
        to: new FormControl<DateTime | null>(null),
        useTariff: new FormControl<boolean>(false, { nonNullable: true }),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: PayRateDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<PayRateDialogComponent, PayRateDialogReturn>,
        @Inject(TariffAutocompleteService) public tariffAutocompleteService: TariffAutocompleteService,
        @Inject(EmployeePayRateService) public employeePayRateService: EmployeePayRateService,
        @Inject(CustomerTariffService) public customerTariffService: CustomerTariffService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit() {
        this.loading = true;

        const payRateGetter: Observable<PayRate | undefined> = (this.data.payRate || of(undefined));
        const minDateGetter = this.employeePayRateService.getAll(this.data.customerId, this.data.employeeId, { per_page: 1, order_by: 'from', direction: 'desc' }).pipe(
            catchError(() => of(mockArrayPaginatedResponse<PayRate>())),
            map((payRates) => {
                const lastPayRate = payRates.data.length ? payRates.data[0] : undefined;
                return lastPayRate?.to || lastPayRate?.from.plus({ days: 1 });
            }),
        );

        forkJoin([
            payRateGetter,
            minDateGetter,
        ]).subscribe(([ payRate, minDate ]) => {
            this.providedPayRate = payRate;
            this.fromMinDate = payRate ? payRate.from : minDate;

            this.patchForm(payRate);
            this.handleUseTariffChange(payRate?.tariff, payRate?.rate, payRate?.type || 'hour');
            this.handleDateChange();

            this.loading = false;
        });
    }

    patchForm(payRate?: PayRate) {
        this.form.patchValue({
            from: payRate?.from || this.data.fromTo?.from,
            to: payRate?.to || this.data.fromTo?.to,
            useTariff: !!payRate?.tariffId,
        });

        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();
    }

    handleDateChange() {
        this.form.controls.from.valueChanges.pipe(startWith(this.form.getRawValue().from)).subscribe((from) => {
            const tariffControl = this.form.controls.tariff;

            if (!from) {
                tariffControl?.setValue(null, { emitEvent: false });
                tariffControl?.disable({ emitEvent: false });
            } else {
                tariffControl?.enable({ emitEvent: false });
            }
        });
    }

    handleUseTariffChange(initialTariff: Tariff | number | null | undefined, initialRate: number | null | undefined, initialType: RateType) {
        this.form.controls.useTariff.valueChanges.pipe(startWith(!!initialTariff)).subscribe((useTariff) => {
            if (useTariff) {
                this.createTariffControl(initialTariff);
                this.form.removeControl('rate');
                this.form.removeControl('type');
            } else {
                this.createRateControl(initialRate);
                this.createTypeControl(initialType);
                this.form.removeControl('tariff');
            }

            // Trigger "from"'s value change event
            this.form.controls.from.patchValue(this.form.getRawValue().from);
        });
    }

    createTypeControl(value: RateType) {
        this.form.addControl('type', new FormControl<RateType>(value, { nonNullable: true }));
    }

    createRateControl(value: number | null | undefined) {
        this.form.addControl('rate', new FormControl<number | null>(value ?? null, [ Validators.required, Validators.min(0) ]));
    }

    createTariffControl(value: Tariff | number | null | undefined) {
        this.form.addControl('tariff', new FormControl<Tariff | number | null>(value ?? null, Validators.required));
    }

    getFormData() {
        const value = this.form.getRawValue();
        if (!value.from) {
            return;
        }

        return {
            from: value.from,
            to: value.to || undefined,
            tariff: value.tariff instanceof Tariff ? value.tariff : undefined,
            tariffId: value.tariff instanceof Tariff ? value.tariff.id : undefined,
            rate: value.rate || (value.tariff instanceof Tariff ? value.tariff.rates?.[0]?.rate : undefined) || 0,
            type: value.type || (value.tariff instanceof Tariff ? value.tariff.rates?.[0]?.type : undefined) || 'month',
        };
    }

    submit() {
        let observable: Observable<PayRate>;
        const formData = this.getFormData();
        if (!formData) {
            return;
        }

        if (this.providedPayRate) {
            observable = this.employeePayRateService.update(this.data.customerId, this.data.employeeId, this.providedPayRate.id, {
                from: formData.from,
                to: formData.to,
                type: formData.type,
                rate: formData.rate,
            });
        } else {
            observable = this.employeePayRateService.create(this.data.customerId, this.data.employeeId, {
                type: formData.type,
                from: formData.from,
                to: formData.to,
                rate: formData.rate,
                tariff_id: formData.tariffId,
            });
        }

        this.form.disable();
        this.processing = true;
        observable.pipe(
            catchError(() => {
                this.form.enable();
                this.processing = false;
                return EMPTY;
            }),
        ).subscribe((payRate) => {
            this.dialogRef.close(payRate);
        });
    }
}
