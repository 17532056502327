@if (loading()) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

@for (stat of stats(); track stat) {
    <div class="row-container">
        <div class="name-overlay" (mouseover)="onMouseoverName()" (mouseleave)="onMouseleaveName()">{{ stat.name | async }}</div>

        <div class="row">
            <div class="filler"></div>

            @for (value of stat.values; track value) {
                <div class="cell" [ngStyle]="{'background-color': value.color}">{{ value.formatted }}</div>
            }
        </div>
    </div>
}
