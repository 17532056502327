import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Customer, CustomerResponse } from '../../../shared/models/customer';
import { CurrentService } from '../../../shared/services/current.service';
import { catchError, debounceTime, distinctUntilChanged, EMPTY, firstValueFrom, forkJoin, map, mergeMap, Observable, of, startWith, Subject, switchMap, take, tap } from 'rxjs';
import { CountryAutocompleteService } from '../../../shared/autocompletes/country-autocomplete.service';
import { CountryRegionAutocompleteService } from '../../../shared/autocompletes/country-region-autocomplete.service';
import { Country } from '../../../shared/models/country';
import { CountryRegion } from '../../../shared/models/country-region';
import { CustomerCreateOptions, CustomerService, CustomerUpdateOptions } from '../../../shared/http/customer.service';
import { default as CurrencyCodes } from 'src/assets/json/currency-codes.json';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { UIRouter } from '@uirouter/core';
import { Stack } from '../../../shared/models/stack';
import { SettingGroup } from '../../../shared/models/setting-group';
import { Currency } from '../../../shared/models/currency';
import { AutoCompleteData } from '../../../shared/autocompletes/autocomplete';
import { TranslateService } from '../../../shared/services/translate.service';
import { SettingGroupAutocompleteService } from '../../../shared/autocompletes/setting-group-autocomplete.service';
import { StackAutocompleteService } from '../../../shared/autocompletes/stack-autocomplete.service';
import { CustomerAutocompleteService } from '../../../shared/autocompletes/customer-autocomplete.service';
import { GeocodingService, Location } from '../../../shared/services/geocoding.service';
import { UpdateCustomerLogoService } from '../../../shared/services/update-customer-logo.service';
import { Locale } from '../../models/locale';
import { CustomerType, CustomerTypeService } from '../../../shared/http/customer-type.service';
import { LocaleService } from '../../http/locale.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { HttpClient, HttpContext } from '@angular/common/http';
import { IGNORE_ERROR } from '../../../shared/http/http-contexts';
import { LanguageAutocompleteService } from '../../../shared/autocompletes/language-autocomplete.service';
import { Language } from '../../models/language';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MapComponent } from '../../../shared/components/map/map.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

interface CustomerForm {
    name: FormControl<string | undefined>;
    number: FormControl<string | undefined>;
    organization_number: FormControl<number | undefined>;
    address1: FormControl<string | undefined>;
    address2: FormControl<string | undefined>;
    countryCode: FormControl<Country | string>,
    region: FormControl<CountryRegion | undefined>;
    city: FormControl<string | undefined>;
    postalCode: FormControl<string | undefined>;
    currency: FormControl<Currency | string | null>;
    languageCode: FormControl<Language | string | undefined>;
    localeCode: FormControl<string | undefined>;
    type: FormControl<string | undefined>;
    timeZone: FormControl<string | undefined>;
    stack: FormControl<Stack | number | undefined>,
    billingContact: FormControl<string | undefined>,
    billingCustomer: FormControl<number | undefined>,
    settingGroup: FormControl<SettingGroup | number | undefined>,
    latitude: FormGroup<number | undefined>,
    longitude: FormGroup<number | undefined>,
}

@Component({
    selector: 'eaw-manage-customer',
    templateUrl: './manage-customer.component.html',
    styleUrl: './manage-customer.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        NgIf,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        AutocompleteComponent,
        MatAutocompleteModule,
        NgFor,
        MatOptionModule,
        MatSelectModule,
        MapComponent,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class ManageCustomerComponent implements OnInit {
    @Input() customerId?: number;
    @Input() locales?: Locale[];

    customerForm = new FormGroup<Partial<CustomerForm>>({});
    regionTrigger: Observable<unknown> = EMPTY;
    fetchingCustomer = true;
    customerTypes: CustomerType[] = [];
    fetchedCustomer?: Customer;
    currencies: Currency[] = [];
    filteredCurrencies: Observable<Currency[]> = of([]);
    timezones?: string[];
    logoUrl?: string;
    hasLogo = false;
    fetchingLogo = true;
    filteredTimezones?: Observable<string[]>;
    billingCustomerOptions: AutoCompleteData<Customer> = {
        label: this.translateService.t('BILLING_CUSTOMER'),
        optionTemplate: 'default',
        filterRequirement: 3,
        display: 'name',
        trackByKey: 'id',
    };

    settingGroupOptions: AutoCompleteData<SettingGroup> = {
        label: this.translateService.t('SETTING_GROUP', 'admin'),
        optionTemplate: 'default',
        filterRequirement: 3,
        display: 'name',
        trackByKey: 'id',
    };

    searchAddressChanged = new Subject<string>();
    place?: Location;

    constructor(
        @Inject(CustomerService) private customerService: CustomerService,
        @Inject(CurrentService) public current: CurrentService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(UIRouter) private router: UIRouter,
        @Inject(TranslateService) protected translateService: TranslateService,
        @Inject(CountryAutocompleteService) protected countryAutocompleteService: CountryAutocompleteService,
        @Inject(CountryRegionAutocompleteService) protected countryRegionAutocompleteService: CountryRegionAutocompleteService,
        @Inject(SettingGroupAutocompleteService) protected settingGroupAutocompleteService: SettingGroupAutocompleteService,
        @Inject(StackAutocompleteService) protected stackAutocompleteService: StackAutocompleteService,
        @Inject(CustomerAutocompleteService) protected customerAutocompleteService: CustomerAutocompleteService,
        @Inject(GeocodingService) protected geocodingService: GeocodingService,
        @Inject(UpdateCustomerLogoService) protected updateCustomerLogoService: UpdateCustomerLogoService,
        @Inject(LanguageAutocompleteService) protected languageAutocompleteService: LanguageAutocompleteService,
        @Inject(CustomerTypeService) protected customerTypeService: CustomerTypeService,
        @Inject(LocaleService) protected localeService: LocaleService,
        @Inject(HttpClient) protected http: HttpClient,
    ) {
    }

    async ngOnInit() {
        this.handleAddressChange();
        this.setTimezones();
        this.setCurrencies();

        // Fetch required data before initializing the form
        const customerTypes = this.customerTypeService.getAll().pipe(catchError(() => of([] as CustomerType[])));
        const locales = expandAllPages((pagination) => this.localeService.getAll(pagination), { per_page: 25 }).pipe(catchError(() => of([] as Locale[])));
        [ this.customerTypes, this.locales ] = await firstValueFrom(forkJoin([ customerTypes, locales ]));

        if (this.customerId) {
            this.initCustomerEdit(this.customerId);
        } else {
            this.initCustomerCreate();
        }
    }

    setLogo() {
        this.http.get(`/customers/${this.customerId}/logo`, {
            responseType: 'blob',
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(
            take(1),
            catchError(() => {
                this.hasLogo = false;
                this.fetchingLogo = false;
                return EMPTY;
            }),
            map((blob) => (this.logoUrl = URL.createObjectURL(blob))),
        ).subscribe(() => {
            this.fetchingLogo = false;
            this.hasLogo = true;
        });
    }

    setCurrencies() {
        this.currencies = CurrencyCodes.reduce((arr: Currency[], code: unknown) => {
            return typeof code !== 'string' ? arr : arr.concat(new Currency({ code, name: '' }, this.current.languageTag));
        }, [] as Currency[]);

        this.filteredCurrencies = of(this.currencies);
    }

    setTimezones() {
        if ((Intl as any).supportedValuesOf) { // https://github.com/microsoft/TypeScript/issues/49231
            this.timezones = (Intl as any).supportedValuesOf('timeZone');
            if (this.timezones) {
                this.filteredTimezones = of(this.timezones);
            }
        }
    }

    getSelectedSettingGroupId(): number | undefined {
        const settingGroup = this.customerForm.value.settingGroup;
        return settingGroup instanceof SettingGroup ? settingGroup.id : undefined;
    }

    goToSettingGroup() {
        const id = this.getSelectedSettingGroupId();
        if (!id) {
            return;
        }

        this.router.stateService.go('eaw/app/admin/setting_groups/view/customers', { id });
    }

    handleAddressChange() {
        this.searchAddressChanged.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            mergeMap(() => this.addressChange()),
        ).subscribe();
    }

    initCustomerCreate() {
        this.fetchingCustomer = false;
        this.fetchingLogo = false;
        this.hasLogo = false;

        this.createForm();
        this.place = { lat: 0, lng: 0 };
    }

    initCustomerEdit(customerId: number) {
        this.setLogo();

        // Customer edit view
        this.customerService.get(customerId, [ 'settingGroup', 'billingCustomer', 'stack', 'region' ]).subscribe((customer) => {
            this.fetchedCustomer = customer;
            this.fetchingCustomer = false;
            this.createForm(customer);

            if (this.fetchedCustomer?.latitude && this.fetchedCustomer?.longitude) {
                this.place = {
                    lat: this.fetchedCustomer.latitude,
                    lng: this.fetchedCustomer.longitude,
                };
            }
        });
    }

    keyupAddress(event: KeyboardEvent) {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        this.searchAddressChanged.next(value);
    }

    private _filterCurrencies(value: string | Currency): Currency[] {
        const filterValue = value instanceof Currency ? value.code.toLowerCase() : value.toLowerCase();
        return this.currencies.filter((option) => option.name.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue));
    }

    private _filterTimezones(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.timezones?.filter((option) => option.toLowerCase().includes(filterValue) || option.toLowerCase().includes(filterValue)) || [];
    }

    createForm(customer?: Customer) {
        this.customerForm.setControl('name', new FormControl(customer?.name, Validators.required));
        this.customerForm.setControl('number', new FormControl(customer?.number, Validators.required));
        this.customerForm.setControl('organization_number', new FormControl(customer?.organizationNumber?.toString() || ''));
        this.customerForm.setControl('address1', new FormControl(customer?.address1 || ''));
        this.customerForm.setControl('address2', new FormControl(customer?.address2 || ''));
        this.setCountryAndRegion(customer);
        this.customerForm.setControl('city', new FormControl(customer?.city, Validators.required));
        this.customerForm.setControl('postalCode', new FormControl(customer?.postalCode || ''));
        this.customerForm.setControl('languageCode', new FormControl(customer?.languageCode || null, Validators.required));
        this.setCurrency(customer);
        this.setLocale(customer);
        this.customerForm.setControl('type', new FormControl(customer?.type, Validators.required));
        this.setTimezone(customer);
        this.setStack(customer);
        this.customerForm.setControl('billingContact', new FormControl(customer?.billingContact || ''));
        this.customerForm.setControl('billingCustomerId', new FormControl(customer?.billingCustomerId || ''));
        this.setSettingGroup(customer);
        this.customerForm.setControl('latitude', new FormControl(customer?.latitude || ''));
        this.customerForm.setControl('longitude', new FormControl(customer?.longitude || ''));
    }

    setCountryAndRegion(customer?: Customer) {
        // Just initialize with no value
        const countryCtrl = new FormControl<Country | string | null>(customer?.countryCode || null, Validators.required);
        this.customerForm.setControl('countryCode', countryCtrl);

        // Region is related to country, so country is also required
        const regionCtrl = new FormControl<CountryRegion | string | null>(customer?.region || null);

        this.customerForm.setControl('region', regionCtrl);
        this.regionTrigger = countryCtrl.valueChanges.pipe(switchMap((country) => {
            const region = regionCtrl.value;

            if (region instanceof CountryRegion && (region.countryCode === country || country instanceof Country && region.countryCode === country.code)) {
                return EMPTY;
            }

            return of(country);
        }));
    }

    setLocale(customer?: Customer) {
        const localeCtrl: FormControl<Locale | string | null> = new FormControl<Locale | string | null>(customer?.localeCode || null, Validators.required);
        this.customerForm.setControl('localeCode', localeCtrl);
    }

    setCurrency(customer?: Customer) {
        const currencyCtrl: CustomerForm['currency'] = new FormControl(customer?.currency ? new Currency({ code: customer.currency, name: '' }, this.current.languageTag) : null);
        this.customerForm.setControl('currency', currencyCtrl);
        this.filteredCurrencies = currencyCtrl.valueChanges.pipe(
            startWith(currencyCtrl.value instanceof Currency ? currencyCtrl.value.translatedName : currencyCtrl.value),
            map((value) => this._filterCurrencies(value || '')),
        );
    }

    setTimezone(customer?: Customer) {
        const timezoneCtrl: FormControl<string | null> = new FormControl<string | null>(customer?.timeZone || null, Validators.required);
        this.customerForm.setControl('timeZone', timezoneCtrl);
        this.filteredTimezones = timezoneCtrl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterTimezones(value || '')),
        );
    }

    currencyDisplay(item?: Currency) {
        return item?.translatedName || '';
    }

    setStack(customer?: Customer) {
        const stackCtrl = new FormControl<Stack | number | null>(customer?.stack ? customer.stack : null, Validators.required);
        this.customerForm.setControl('stack', stackCtrl);
    }

    setSettingGroup(customer?: Customer) {
        const settingGroupCtrl: FormControl<SettingGroup | number | undefined | null> = new FormControl<SettingGroup | number | undefined>(customer?.settingGroupId, Validators.required);
        this.customerForm.setControl('settingGroup', settingGroupCtrl);
    }

    getFieldValue<T>(originalValue: T | null | undefined, newValue: T | null | undefined, nullable: false): T | undefined
    getFieldValue<T>(originalValue: T | null | undefined, newValue: T | null | undefined, nullable: true): T | null | undefined
    getFieldValue<T>(originalValue: T | null | undefined, newValue: T | null | undefined, nullable: boolean): T | null | undefined {
        if (originalValue === newValue) {
            return undefined;
        }

        if (nullable) {
            return newValue;
        }

        return newValue ?? undefined;
    }

    getUpdatedCountryCode(): string | undefined {
        return this.customerForm.value.countryCode instanceof Country ? this.customerForm.value.countryCode.code : (this.customerForm.value.countryCode || undefined);
    }

    getUpdatedRegionId(): number | null {
        const countryCode = this.getUpdatedCountryCode();
        const region = this.customerForm.value.region;

        return region?.countryCode?.toLowerCase() === countryCode?.toLowerCase() ? (region?.id || null) : null;
    }

    getStackId(): number | undefined {
        return this.customerForm.value.stack instanceof Stack ? this.customerForm.value.stack.id : (this.customerForm.value.stack || undefined);
    }

    getSettingGroupId(): number | undefined {
        return this.customerForm.value.settingGroup instanceof SettingGroup ? this.customerForm.value.settingGroup.id : (this.customerForm.value.settingGroup || undefined);
    }

    async addressChange() {
        const address = `${this.customerForm.value.address1} ${this.customerForm.value.address2}, ${this.customerForm.value.postalCode} ${this.customerForm.value.city}`.trim();
        await this.geocodingService.getCoordinates(address).then((result: Location) => {
            this.place = { lat: result.lat, lng: result.lng };
            this.customerForm.controls.latitude?.setValue(result.lat);
            this.customerForm.controls.longitude?.setValue(result.lng);
        });
    }

    updateLogo() {
        if (!this.customerId) {
            return;
        }

        this.updateCustomerLogoService.open(this.customerId);
    }

    submit(customer?: CustomerResponse) {
        const form = this.customerForm.getRawValue();

        if (customer) {
            this.updateCustomer(customer.id, {
                name: this.getFieldValue(customer.name, form.name, false),
                number: this.getFieldValue(customer.number, form.number, false),
                organization_number: this.getFieldValue(customer.organization_number, String(form.organization_number), true),
                address1: this.getFieldValue(customer.address1, form.address1, false),
                address2: this.getFieldValue(customer.address2, form.address2, true),
                postal_code: this.getFieldValue(customer.postal_code, form.postalCode, false),
                city: this.getFieldValue(customer.city, form.city, false),
                country_code: this.getFieldValue(customer.country_code, this.getUpdatedCountryCode(), false),
                region_id: this.getFieldValue(customer.region_id, this.getUpdatedRegionId(), true),
                currency: this.getFieldValue(customer.currency, form.currency instanceof Currency ? form.currency.code : form.currency, false),
                locale_code: this.getFieldValue(customer.locale_code, form.localeCode, false),
                language_code: this.getFieldValue(customer.language_code, form.languageCode instanceof Language ? form.languageCode.code : form.languageCode, true),
                type: this.getFieldValue(customer.type, form.type, false),
                stack_id: this.getFieldValue(customer.stack_id, this.getStackId(), false),
                time_zone: this.getFieldValue(customer.time_zone, form.timeZone, false),
                billing_contact: this.getFieldValue(customer.billing_contact, form.billingContact, false),
                billing_customer_id: this.getFieldValue(customer.billing_customer_id, form.billingCustomer, true),
                setting_group_id: this.getFieldValue(customer.setting_group_id, this.getSettingGroupId(), false),
                latitude: this.getFieldValue(customer.latitude, form.latitude, true),
                longitude: this.getFieldValue(customer.longitude, form.longitude, true),
            });
        } else {
            const name = form.name;
            const number = form.number;
            const address1 = form.address1;
            const postalCode = form.postalCode;
            const city = form.city;
            const countryCode = this.getUpdatedCountryCode();
            const localeCode = form.localeCode;
            const type = form.type;
            const stackId = this.getStackId();
            const timeZone = form.timeZone;
            const billingContact = form.billingContact;
            const settingGroupId = this.getSettingGroupId();

            if (!name || !number || !address1 || !billingContact || !settingGroupId || !postalCode || !city || !countryCode || !timeZone || !localeCode || !type || !stackId) {
                return;
            }

            this.createCustomer({
                name,
                number,
                organization_number: form.organization_number ? String(form.organization_number) : undefined,
                address1,
                address2: form.address2,
                postal_code: postalCode,
                city,
                country_code: countryCode,
                region_id: this.getUpdatedRegionId(),
                currency: form.currency instanceof Currency ? form.currency.code : form.currency ?? undefined,
                locale_code: localeCode,
                language_code: form.languageCode instanceof Language ? form.languageCode.code : form.languageCode,
                type,
                stack_id: stackId,
                time_zone: timeZone,
                billing_contact: billingContact,
                billing_customer_id: form.billingCustomer,
                setting_group_id: settingGroupId,
                latitude: form.latitude,
                longitude: form.longitude,
                active: true,
            });
        }
    }

    private updateCustomer(customerId: number, fields: CustomerUpdateOptions) {
        if (!customerId) {
            return;
        }

        this.customerService.update(customerId, fields).pipe(
            tap(() => {
                this.customerForm.disable();
            }),
            catchError(() => {
                this.customerForm.enable();
                return EMPTY;
            }),
        ).subscribe(() => {
            void this.snackBarService.t('UPDATED');
            void this.router.stateService.reload();
        });
    }

    private createCustomer(fields: CustomerCreateOptions) {
        this.customerService.create(fields).pipe(
            tap(() => {
                this.customerForm.disable();
            }),
            catchError(() => {
                this.customerForm.enable();
                return EMPTY;
            }),
        ).subscribe((result) => {
            void this.snackBarService.t('CREATED');
            void this.router.stateService.go('eaw/app/admin/customers/view/info', { id: result.id });
        });
    }
}
