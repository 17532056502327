import { SignatureProviderType } from '../../hr/enums/SignatureProviderType';
import { ApiResponse } from '../interfaces/api-response';

export interface SignatureResponse extends ApiResponse {
    id: number;
    object_id: number;
    object_type: string;
    provider: SignatureProviderType;
    user_id: number;
}

export class Signature {
    id: number;
    objectId: number;
    objectType: string;
    provider: SignatureProviderType;
    userId: number;

    constructor(data: SignatureResponse) {
        this.id = data.id;
        this.objectId = data.object_id;
        this.objectType = data.object_type;
        this.provider = data.provider;
        this.userId = data.user_id;
    }
}
