import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Customer } from '../../../../shared/models/customer';
import { UserAccess } from '../../../models/user-access';
import { AddUserAccessDialogComponent } from './add-user-access-dialog/add-user-access-dialog.component';
import { DialogData, DialogSize } from '../../../../shared/dialogs/dialog-component';
import { EditUserAccessDialogComponent } from './edit-user-access-dialog/edit-user-access-dialog.component';
import { User } from '../../../../shared/models/user';
import { RemoveUserAccessDialogComponent } from '../../../dialogs/remove-user-access-dialog/remove-user-access-dialog.component';

interface UserAccessData extends DialogData {
    customer: Customer;
}

export interface CreateUserAccessData extends UserAccessData {
    user?: User;
}

export interface UpdateUserAccessData extends UserAccessData {
    userAccess: UserAccess;
}

export interface RemoveUserAccessData extends UserAccessData {
    user: User;
}

@Injectable({
    providedIn: 'root',
})
export class UserAccessDialogService {

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
    ) { }

    createAccess(customer: Customer, user?: User) {
        return this.dialog.open<AddUserAccessDialogComponent, CreateUserAccessData, UserAccess>(AddUserAccessDialogComponent, {
            data: {
                size: DialogSize.Medium,
                customer,
                user,
            },
        });
    }

    updateAccess(customer: Customer, userAccess: UserAccess) {
        return this.dialog.open<EditUserAccessDialogComponent, UpdateUserAccessData, UserAccess>(EditUserAccessDialogComponent, {
            data: {
                size: DialogSize.Medium,
                customer,
                userAccess,
            },
        });
    }

    deleteAccess(customer: Customer, user: User) {
        return this.dialog.open<RemoveUserAccessDialogComponent, RemoveUserAccessData, boolean>(RemoveUserAccessDialogComponent, {
            data: {
                size: DialogSize.Medium,
                customer,
                user,
            },
        });
    }
}
