// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.fixedLabor').component('fixedLaborList', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="navigation:FIXED_LABOR_COST"></span>
        </md-card-header-text>

        <card-btn-float on-click="$fixedLabor.add()" ng-if="$fixedLabor.canCreate"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                cells="$fixedLabor.cells"
                columns="$fixedLabor.cols"
                reload-table="$fixedLabor.reloadTable"
                get-data="$fixedLabor.getList(params, pagination)">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$fixedLabor',
    bindings: {
        customer: '<',
    },
    controller: [ 'FixedLabor', 'fixedLaborDialog', function(FixedLabor, fixedLaborDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.canCreate = CurrentOld.can(FixedLabor.create.permission(ctrl.customer.id));

            ctrl.cols = [
                {
                    title: t('NAME'),
                    sortable: 'name',
                },
                {
                    title: t('FROM'),
                    sortable: 'from',
                },
                {
                    title: t('TO'),
                    sortable: 'to',
                },
                {
                    title: t('fixed_labor_cost:VALUE'),
                    sortable: 'value',
                    class: 'text-right',
                },
                {
                    title: t('fixed_labor_cost:PERIOD'),
                    sortable: 'period',
                },
                { title: '' },
            ];

            ctrl.cells = [
                { template: 'item.name' },
                { template: 'item.from | moment:"LL"' },
                { template: 'item.to | moment:"LL"' },
                {
                    template: 'item.value | eawNumber',
                    class: 'text-right',
                },
                { template: 'item.period.toUpperCase() | i18next' },
                {
                    buttons: [
                        {
                            icon: 'edit',
                            invisible() {
                                return !CurrentOld.can(FixedLabor.update.permission(ctrl.customer.id, '*'));
                            },
                            click: ctrl.edit,
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            invisible() {
                                return !CurrentOld.can(FixedLabor.delete.permission(ctrl.customer.id, '*'));
                            },
                            click: ctrl.deleteCost,
                        },
                    ],
                },
            ];
        };

        ctrl.edit = (cost) => {
            fixedLaborDialog.edit(ctrl.customer.id, cost).then(ctrl.reload);
        };

        ctrl.reload = () => ctrl.reloadTable = true;

        ctrl.getList = (_, pagination) => FixedLabor.getAll.query(ctrl.customer.id, pagination).$promise;

        ctrl.add = () => {
            fixedLaborDialog.add(ctrl.customer.id).then(ctrl.reload);
        };

        ctrl.deleteCost = (cost) => {
            fixedLaborDialog.delete(ctrl.customer.id, cost.id).then(ctrl.reload);
        };
    } ],
});
