import { MediaChromeSelectMenu } from "./media-chrome-selectmenu.js";
import "../media-playback-rate-button.js";
import "./media-playback-rate-listbox.js";
import { globalThis, document } from "../utils/server-safe-globals.js";
class MediaPlaybackRateSelectMenu extends MediaChromeSelectMenu {
  init() {
    const playbackRateButton = document.createElement("media-playback-rate-button");
    playbackRateButton.part.add("button");
    playbackRateButton.preventClick = true;
    const playbackRateListbox = document.createElement("media-playback-rate-listbox");
    playbackRateListbox.part.add("listbox");
    playbackRateListbox.setAttribute("exportparts", "option, option-selected, indicator");
    const buttonSlot = this.shadowRoot.querySelector("slot[name=button]");
    const listboxSlot = this.shadowRoot.querySelector("slot[name=listbox]");
    buttonSlot.textContent = "";
    listboxSlot.textContent = "";
    buttonSlot.append(playbackRateButton);
    listboxSlot.append(playbackRateListbox);
  }
}
if (!globalThis.customElements.get("media-playback-rate-selectmenu")) {
  globalThis.customElements.define("media-playback-rate-selectmenu", MediaPlaybackRateSelectMenu);
}
var media_playback_rate_selectmenu_default = MediaPlaybackRateSelectMenu;
export { MediaPlaybackRateSelectMenu, media_playback_rate_selectmenu_default as default };