<eaw-page-header>
    <span title>{{'MY_INFORMATION' | translate:'company' | async}}</span>
</eaw-page-header>

<div *ngIf="!customer" class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-20">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<mat-card *ngIf="customer">
    <mat-card-content>
        <form [formGroup]="form" class="tw-grid tw-gap-8 tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3">
            <mat-form-field>
                <mat-label>{{ 'NAME' | translate | async }}</mat-label>
                <input matInput formControlName="name">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'organizationNumber' | translate | async }}</mat-label>
                <input matInput formControlName="organizationNumber">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'address1' | translate | async }}</mat-label>
                <input matInput formControlName="address1">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'address2' | translate | async }}</mat-label>
                <input matInput formControlName="address2">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'city' | translate | async }}</mat-label>
                <input matInput formControlName="city">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'number' | translate | async }}</mat-label>
                <input matInput formControlName="number">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'postalCode' | translate | async }}</mat-label>
                <input matInput formControlName="postalCode">
            </mat-form-field>
        </form>

        <div *ngIf="canUpdate">
            <button mat-raised-button color="primary" (click)="update()" [disabled]="form.invalid || form.pristine || !hasChanges || form.disabled">{{ 'UPDATE_MY_INFORMATION' | translate | async }}</button>
        </div>
    </mat-card-content>
</mat-card>
