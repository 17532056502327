// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { IntervalOld } from '../../shared/angularjs/interval-old';
import { CurrentOld } from '../../shared/angularjs/current.factory';

export class ChecklistsListComponent {
    startDialog;
    ChecklistFactory;
    static get $inject() {
        return [ 'startDialog', 'ChecklistFactory' ];
    }

    customer;
    reloadTable = false;
    closed = false;
    showFilter = false;
    interval = new IntervalOld();
    template;
    filter;
    table = {
        columns: [
            {
                title: t('TITLE'),
                sortable: 'title',
            },
            { title: '' },
            {
                title: t('CREATED'),
                sortable: 'created_at',
            },
            {
                title: t('checklists:CLOSED'),
                sortable: 'closed_at',
            },
            {
                title: t('checklists:BASED_ON_TEMPLATE'),
            },
        ],
        cells: [
            { template: `item.title` },
            {
                templateUrl: 'checklistlisticonstempalte',
                class: 'text-center',
            },
            { template: `item.created_at | moment:'llll'` },
            { template: 'item.closed_at | moment:"llll"' },
            { template: `item.template.title` },
        ],
    };

    constructor(startDialog, ChecklistFactory) {
        this.startDialog = startDialog;
        this.ChecklistFactory = ChecklistFactory;
    }

    $onInit() {
        this.customer = CurrentOld.getCustomer();
    }

    getData = (pagination) => {
        return this.ChecklistFactory.getAll({
            customer: CurrentOld.getCustomer(),
            template: false,
            filter: this.filter,
            from: this.interval.from,
            to: this.interval.to,
            template_id: this.template?.id,
            closed: this.closed,
            with: [ 'template' ],
            ...pagination.toWithPaginatorArguments(),
        });
    };

    openStartDialog() {
        this.startDialog.open(CurrentOld.getCustomer()).then(this.reload.bind(this));
    }

    toggleFilter() {
        this.showFilter = !this.showFilter;
        this.interval = new IntervalOld();
        this.filter = undefined;
        if (!this.showFilter) {
            this.reload();
        }
    }

    setMode(mode) {
        this.closed = mode;
        this.reload();
    }

    reload() {
        this.reloadTable = true;
    }
}
module('eaw.checklists').component('checklistList', {
    template: `<script type="text/ng-template" id="checklistlisticonstempalte">
    <md-icon ng-if="item.comments_count" ng-bind="'comment'"></md-icon>
    <md-icon ng-if="item.attachments_count" ng-bind="'image'"></md-icon>
    <md-icon ng-if="item.deviations_count > item.deviations_completed_count" ng-bind="'assignment_late'" md-colors="{'color': 'deep-orange-500'}"></md-icon>
    <md-icon ng-if="item.deviations_count == item.deviations_completed_count" ng-bind="'assignment_turned_in'" md-colors="{'color': 'green-500'}"></md-icon>
</script>

<md-card ng-show="clCtrl.showFilter">
    <md-card-content>
        <form layout="column" ng-submit="clCtrl.reload()">
            <div layout="row" layout-wrap>
                <md-input-container flex="100" flex-gt-xs="50" flex-gt-sm="25">
                    <label for="mode" ng-i18next="MODE"></label>
                    <md-select id="mode" ng-model="clCtrl.closed">
                        <md-option ng-value="true">{{::'checklists:CLOSED' | i18next}}</md-option>
                        <md-option ng-value="false" selected>{{::'OPEN' | i18next}}</md-option>
                        <md-option ng-value="undefined">{{::'ALL' | i18next}}</md-option>
                    </md-select>
                </md-input-container>

                <checklist-autocomplete clear-btn="true"
                                        customer="clCtrl.customer"
                                        flex="100"
                                        flex-gt-xs="50"
                                        flex-gt-sm="25"
                                        ng-model="clCtrl.template"
                                        template="true">
                </checklist-autocomplete>

                <md-input-container flex="100" flex-gt-xs="50" flex-gt-sm="25">
                    <label for="search" ng-i18next="SEARCH"></label>
                    <input type="search" id="search" name="search" ng-model="clCtrl.filter">
                </md-input-container>

                <eaw-date-interval flex="100" flex-gt-sm="50" layout="row" id="interval" ng-model="clCtrl.interval"></eaw-date-interval>
            </div>

            <md-card-actions flex="100">
                <md-button class="md-accent md-raised" ng-i18next="UPDATE" type="submit"></md-button>
            </md-card-actions>
        </form>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="checklists:CHECKLIST_plural"></span>
        </md-card-header-text>

        <md-menu>
            <md-button ng-click="$mdMenu.open($event)" class="tw-m-0" ng-i18next="MODE"></md-button>
            <md-menu-content>
                <md-menu-item>
                    <md-button ng-click="clCtrl.setMode(true)" ng-disabled="clCtrl.closed" ng-i18next="checklists:CLOSED"></md-button>
                </md-menu-item>
                <md-menu-item>
                    <md-button ng-click="clCtrl.setMode(false)" ng-disabled="clCtrl.closed == false" ng-i18next="checklists:OPEN"></md-button>
                </md-menu-item>
                <md-menu-item>
                    <md-button ng-click="clCtrl.setMode(undefined)" ng-disabled="clCtrl.closed == undefined" ng-i18next="checklists:ALL"></md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>

        <eaw-toggle-btn ng-model="clCtrl.showFilter"
                        icon="filter_alt"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>

        <card-btn-float on-click="clCtrl.openStartDialog()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="clCtrl.table.columns"
                cells="clCtrl.table.cells"
                get-data="clCtrl.getData(pagination)"
                reload-table="clCtrl.reloadTable"
                go-to="eaw/app/checklists/view">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: 'clCtrl',
    controller: ChecklistsListComponent,
});
