// @ts-nocheck
import { module } from 'angular';

module('eaw.scheduling').component('vacationSidebar', {
    template: `<div ng-if="$ctrl.loading" layout="column" layout-padding>
    <md-progress-circular class="tw-m-12" flex></md-progress-circular>
</div>

<md-content ng-show="!$ctrl.loading" layout="column">
    <md-list class="md-dense">
        <md-list-item class="md-2-line" ng-repeat="offtime in $ctrl.offtimes track by offtime.id">
            <md-icon class="md-avatar-icon" ng-bind="offtime.icon.name" md-colors="{color: offtime.icon.color}"></md-icon>

            <div class="md-list-item-text">
                <h3 ng-bind="offtime.name"></h3>
                <h4>{{offtime.from | moment:'ll'}} - {{offtime.to | moment:'ll'}}</h4>

                <p ng-if="offtime.vacation" ng-i18next="VACATION"></p>
                <p ng-if="!offtime.vacation" ng-i18next="OFFTIME_OFF"></p>
            </div>
            <md-divider ng-if="!$last"></md-divider>
        </md-list-item>
    </md-list>
</md-content>
`,
    require: {
        sidebar: '^scheduleSidebar',
    },
    controller: [ 'OffTimeService', function(OffTimeService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.schedule = ctrl.sidebar.schedule;
            ctrl.loading = true;
            ctrl.customer = ctrl.schedule.customer;
            ctrl.offTimeFactory = new OffTimeService(ctrl.customer.id);

            ctrl.offTimeFactory.customer.getAll.query({
                order_by: 'employee_id',
                direction: 'asc',
                per_page: 100,
                from: ctrl.schedule.getFrom(),
                to: ctrl.schedule.getTo(),
                with: [ 'approval' ],
            }).$promise.then((resp) => {
                ctrl.offtimes = resp.data.map((o) => {
                    switch (o.approval?.approved) {
                        case true: {
                            o.icon = {
                                name: 'check_circle',
                                color: 'green',
                            };
                            break;
                        }
                        case false: {
                            o.icon = {
                                name: 'cancel',
                                color: 'red',
                            };
                            break;
                        }
                        default: {
                            o.icon = {
                                name: 'help',
                                color: 'black',
                            };
                        }
                    }

                    return o;
                });
            }).finally(() => delete ctrl.loading);
        };
    } ],
});
