import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddWidgetDialogComponent, AddWidgetDialogData, AddWidgetDialogResult } from '../../dialogs/add-widget-dialog/add-widget-dialog.component';
import { WidgetService } from '../../http/widget.service';
import { WidgetInfoService } from '../../services/widget-info.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { WidgetInfo } from '../../classes/widget-info';
import { CurrentService } from '../../../shared/services/current.service';
import { TinyColor } from '@ctrl/tinycolor';
import { EMPTY, forkJoin, map, switchMap } from 'rxjs';
import { uniqueId } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { environment } from '../../../../environments/environment';
import { UserPropertyService } from '../../../shared/http/user-property.service';
import { Widget, WidgetPropertySettings } from '../../classes/widget';
import { AlertDialogComponent, AlertDialogData } from '../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { WidgetHostComponent } from '../../components/widget-host/widget-host.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

@Component({
    selector: 'eaw-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatIconModule,
        MatIconSizeDirective,
        MatButtonModule,
        NgFor,
        WidgetHostComponent,
        AsyncPipe,
        TranslatePipe,
        MatBottomSheetModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
    private matDialog = inject(MatDialog);
    private current = inject(CurrentService);
    private widgetService = inject(WidgetService);
    private widgetInfoService = inject(WidgetInfoService);
    private snackBarService = inject(SnackBarService);
    private userPropertyService = inject(UserPropertyService);

    isProduction = environment.isProduction;
    defaultWidgets = signal<string[]>([]);
    disabledWidgets = signal<string[]>([]);
    defaultWidgetColor = signal<TinyColor | null>(null);
    loadingInitialData = signal(true);
    miniWidgets = signal<Widget[]>([]);
    normalWidgets = signal<Widget[]>([]);

    ngOnInit(): void {
        this.getRequiredData();
    }

    getRequiredData() {
        const customerId = this.current.getCustomer().id;
        const customerSettingGroupId = this.current.getCustomer().settingGroupId;
        const userId = this.current.getUser().id;

        this.widgetService.doSetup(customerSettingGroupId, customerId, userId).pipe(
            switchMap(() => {
                return forkJoin([
                    this.widgetService.getSettings(customerSettingGroupId),
                    this.widgetService.getAll(customerId, userId),
                    this.widgetService.getHiddenWidgets(customerId),
                ]);
            }),
        ).subscribe(([ settings, widgets, hidden ]) => {
            console.debug('Settings', settings);
            console.debug('Widgets', widgets);
            console.debug('Hidden', hidden);

            this.defaultWidgetColor.set(settings[WidgetService.WIDGET_COLOR_KEY]);
            this.defaultWidgets.set(settings[WidgetService.DEFAULT_WIDGETS_KEY]);
            this.disabledWidgets.set(settings[WidgetService.DISABLED_WIDGETS_KEY]);
            this.miniWidgets.set(widgets.filter((w) => w.info.mini));
            this.normalWidgets.set(widgets.filter((w) => !w.info.mini));

            this.checkNewDefaultWidgets(this.defaultWidgets(), widgets, hidden);

            this.loadingInitialData.set(false);
        });
    }

    /**
     * Finds all default widgets that are not yet added and adds them.
     * Can remove the upgraded check once everything is upgraded.
     */
    checkNewDefaultWidgets(defaultWidgetKeys: string[], widgets: Widget[], hidden: string[]) {
        const newDefaultWidgetKeys = defaultWidgetKeys.filter((key) => !hidden.includes(key) && !widgets.find((w) => w.info.key === key));
        console.debug('New default widgets', newDefaultWidgetKeys);

        newDefaultWidgetKeys.forEach((key) => {
            this.widgetInfoService.getByKey(key).subscribe((info) => {
                if (!info) {
                    return;
                }

                console.debug('Add default widget', key);
                this.createWidget(info);
            });
        });
    }

    openSettings() {
        this.matDialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
            data: {
                title: signal(Promise.resolve('Settings')),
                text: signal(Promise.resolve('This feature is not yet implemented')),
            },
        });
    }

    createWidget(info: WidgetInfo, settings?: WidgetPropertySettings) {
        const skeletonWidget = this.addSkeletonWidget(info);

        this.widgetService.add(this.current.getCustomer().id, this.current.getUser().id, info.key, undefined, settings).subscribe((widget) => {
            // Remove skeleton widget
            this.normalWidgets.update((w) => w.filter((v) => v.info.key !== skeletonWidget.info.key));
            this.miniWidgets.update((w) => w.filter((v) => v.info.key !== skeletonWidget.info.key));

            if (widget) {
                this.addWidgetToDashboard(widget);
            }
        });
    }

    openAddWidgetDialog() {
        this.matDialog.open<AddWidgetDialogComponent, AddWidgetDialogData, AddWidgetDialogResult>(AddWidgetDialogComponent, {
            data: {
                settingGroupId: this.current.getCustomer().settingGroupId || 0,
                customerId: this.current.getCustomer().id,
                userId: this.current.getUser().id,
            },
        }).afterClosed().pipe(
            switchMap((result) => {
                if (result) {
                    return this.widgetInfoService.getByKey(result.widgetKey).pipe(
                        map((info) => {
                            return {
                                info,
                                settings: result.settings,
                            };
                        }),
                    );
                }

                return EMPTY;
            }),
        ).subscribe((result) => {
            if (result.info) {
                this.createWidget(result.info, result.settings);
            }
        });
    }

    sortWidgets() {
        this.matDialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
            data: {
                title: signal(Promise.resolve('Sort')),
                text: signal(Promise.resolve('This feature is not yet implemented')),
            },
        });
    }

    onDeleted(widget: Widget) {
        this.miniWidgets.update((w) => w.filter((v) => v.property.key !== widget.property.key));
        this.normalWidgets.update((w) => w.filter((v) => v.property.key !== widget.property.key));
    }

    addWidgetToDashboard(widget: Widget) {
        if (widget.info.mini) {
            this.miniWidgets.update((w) => [ widget, ...w ]);
        } else {
            this.normalWidgets.update((w) => [ widget, ...w ]);
        }
    }

    /**
     * Adds a skeleton widget to the dashboard
     * @param info - Info about the widget we will create a skeleton for
     */
    addSkeletonWidget(info: WidgetInfo) {
        const skeletonInfo = this.widgetInfoService.getSkeletonWidget(info.mini);

        const widget = new Widget({
            id: uniqueId(),
            key: '',
            value: '',
            created_at: '',
            updated_at: '',
        }, {
            ...skeletonInfo,
            recommendedSize: info.recommendedSize,
        });

        this.addWidgetToDashboard(widget);
        return widget;
    }

    /**
     * Only for use in development
     * @protected
     */
    protected removeSetupCompleteProperty() {
        if (this.isProduction) {
            return;
        }

        return this.userPropertyService.delete(this.current.getUser().id, `widget_setup_completed:${this.current.getCustomer().id}`).subscribe(() => {
            this.snackBarService.open('Setup complete property removed');
        });
    }

    /**
     * Only for use in development
     * @protected
     */
    protected deleteAllWidgets() {
        if (this.isProduction) {
            return;
        }

        [ ...this.miniWidgets(), ...this.normalWidgets() ].forEach((w) => {
            this.widgetService.delete(this.current.getUser().id, w).subscribe(() => this.onDeleted(w));
        });
    }
}
