import { ApiResponse } from '../interfaces/api-response';

export interface CountryRegionResponse extends ApiResponse {
    code: string;
    country_code: string;
    id: number;
    name: string;
}

export class CountryRegion {
    code: string;
    countryCode: string;
    id: number;
    name: string;

    constructor(data: CountryRegionResponse) {
        this.id = data.id;
        this.code = data.code;
        this.countryCode = data.country_code;
        this.name = data.name;
    }
}
