import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="midCtrl.message.subject"></eaw-dialog-header-old>

    <md-dialog-content>
        <md-tabs md-dynamic-height>
            <md-tab label="{{'MESSAGE' | i18next}}" md-on-select="midCtrl.tab = 'message'">
                <md-content class="md-padding">
        <markdown-downgrade ng-if="!ci.editing" [text]="midCtrl.message.body"></markdown-downgrade>
                </md-content>
            </md-tab>

            <md-tab label="{{'messages:ATTACHMENT_plural' | i18next}}" ng-if="midCtrl.message.attachments.length > 0" md-on-select="midCtrl.tab = 'attachments'">
                <md-content>
                    <md-list>
                        <md-list-item ng-repeat="attachment in ::midCtrl.message.attachments track by $index">
                            <p ng-bind="attachment.name"></p>
                            <md-icon ng-bind="'download'" class="md-secondary" ng-click="midCtrl.downloadAttachment(attachment)"></md-icon>
                        </md-list-item>
                    </md-list>
                </md-content>
            </md-tab>

            <md-tab label="{{'messages:RECIPIENT_plural' | i18next}}" md-on-select="midCtrl.tab = 'recipients'">
                <md-subheader layout="row" class="md-primary" ng-if="midCtrl.isMyMessage">
                    <span flex>{{'messages:NOT_OPENED' | i18next}} : {{midCtrl.message.recipients_count - midCtrl.message.read_count}}</span>
                    <span flex>{{'messages:OPENED' | i18next}} : {{midCtrl.message.read_count}}</span>
                    <span flex>{{'messages:CONFIRMED' | i18next}} : {{midCtrl.message.confirmed_count}}</span>
                </md-subheader>
                <md-content>
                    <message-recipients message="midCtrl.message" show-stats="midCtrl.isMyMessage"></message-recipients>
                </md-content>
            </md-tab>
        </md-tabs>
    </md-dialog-content>

       <md-dialog-actions layout="row">
        <md-button class="md-icon-button" ng-click="midCtrl.reply(false)" ng-show="midCtrl.tab == 'message' && !midCtrl.isMyMessage && !midCtrl.message.is_blind">
            <md-icon ng-bind="'reply'"></md-icon>
            <md-tooltip ng-i18next="messages:REPLY"></md-tooltip>
        </md-button>

        <md-button class="md-icon-button" ng-click="midCtrl.reply(true)" ng-show="midCtrl.tab == 'message' && !midCtrl.isMyMessage && !midCtrl.message.is_blind">
            <md-icon ng-bind="'reply_all'"></md-icon>
            <md-tooltip ng-i18next="messages:REPLY_ALL"></md-tooltip>
        </md-button>

        <md-button ng-show="midCtrl.tab == 'message' && !midCtrl.message.confirmed_at && !midCtrl.isMyMessage"
                   ng-i18next="messages:CONFIRM_READ"
                   ng-click="midCtrl.confirmRead()">
        </md-button>

        <md-button ng-show="midCtrl.tab == 'recipients' && midCtrl.isMyMessage"
                   ng-i18next="messages:SEND_REMINDER"
                   ng-click="midCtrl.sendReminder()">
        </md-button>

        <span flex></span>

        <md-button ng-i18next="CLOSE" close-dialog></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.messages').factory('MessageInfoService', [ '$mdDialog', 'Message', function($mdDialog: any, Message: any) {
    // @ts-ignore
    const service = this;

    service.showInfo = (message: any) => $mdDialog.show({
        template: template1,
        controller: 'messageInfoDialogCtrl',
        controllerAs: 'midCtrl',
        bindToController: true,
        locals: {
            tableMessage: message,
        },
        resolve: {
            message: () => Message.read(message.id, [ 'user', 'attachments' ]).$promise.then((data: any) => {
                // Update the message's read_at when opening a message if it isn't set before
                // But check if it has pivot
                if (message.pivot) {
                    message.pivot.read_at = message.pivot.read_at || moment();
                }

                return new Message(Object.assign(message, data));
            }),
        },
    });

    return service;
} ]);
