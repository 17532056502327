import { Component, Inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Employee } from '../../../shared/models/employee';
import { ShiftSwapService } from '../../http/shift-swap.service';
import { catchError, EMPTY, Observable, Subscription } from 'rxjs';
import { ShiftSwap } from '../../models/shift-swap';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';

type SwapMode = 'any' | 'specific';

export interface ShiftSwapRequestDialogData extends DialogData {
    shiftId: number;
    customerId: number;
}

type ShiftSwapRequestDialogForm = FormGroup<{
    mode: FormControl<SwapMode>,
    comment: FormControl<string | null>,
    employee?: FormControl<Employee | null>,
}>

@Component({
    selector: 'eaw-shift-swap-request-dialog',
    standalone: true,
    templateUrl: './shift-swap-request-dialog.component.html',
    styleUrl: './shift-swap-request-dialog.component.scss' ,
    imports: [ CommonModule, DialogHeaderComponent, MatDialogModule, MatButtonModule, MatRadioModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, AutocompleteComponent, TranslatePipe ],
})
export class ShiftSwapRequestDialogComponent extends DialogComponent implements OnDestroy {
    readonly modes: {value: SwapMode, translation: Promise<string>}[] = [
        {
            value: 'any',
            translation: this.translate.t('SWAP_WITH_ANYBODY', 'scheduling'),
        },
        {
            value: 'specific',
            translation: this.translate.t('SWAP_WITH_SPECIFIC', 'scheduling'),
        },
    ];

    canGetEmployees = false;
    employeeControl = new FormControl<Employee | null>(null, Validators.required);
    form: ShiftSwapRequestDialogForm = new FormGroup({
        mode: new FormControl<SwapMode>('any', { nonNullable: true }),
        comment: new FormControl<string>(''),
    });

    private subscription: Subscription | undefined;

    constructor(
        @Inject(MatDialogRef) ref: MatDialogRef<ShiftSwapRequestDialogComponent, ShiftSwap>,
        @Inject(MAT_DIALOG_DATA) override data: ShiftSwapRequestDialogData,
        @Inject(ShiftSwapService) private shiftSwapService: ShiftSwapService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
    ) {
        data.size = DialogSize.Medium;
        super(ref, data);

        this.permissionCheckService.isAllowed(`customers.${data.customerId}.employees.*.get`).subscribe((can) => {
            this.canGetEmployees = can;
        });

        this.form.controls.mode.valueChanges.subscribe((value) => {
            if (value === 'any') {
                this.form.removeControl('employee');
            } else {
                this.form.addControl('employee', this.employeeControl);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    sendRequest() {
        const isSpecific = this.form.controls.mode.value === 'specific';
        const toEmployee = this.form.controls.employee?.value;
        let observable: Observable<ShiftSwap> | undefined;

        if (isSpecific && toEmployee) {
            observable = this.shiftSwapService.create(this.data.customerId, this.data.shiftId, toEmployee.id, this.form.controls.comment.value ?? undefined);
        }

        if (!isSpecific && !toEmployee) {
            observable = this.shiftSwapService.create(this.data.customerId, this.data.shiftId, undefined, this.form.controls.comment.value ?? undefined);
        }

        this.form.disable();
        this.subscription = observable?.pipe(
            catchError((err) => {
                console.error(err);
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe((res) => {
            this.dialogRef.close(res);

            if (isSpecific) {
                void this.snackBarService.t('SWAP_EMPLOYEE_REQUEST_SENT', 'scheduling', { name: toEmployee?.name || '' });
            } else {
                void this.snackBarService.t('SWAP_ANYONE_REQUEST_SENT', 'scheduling');
            }
        });
    }
}
