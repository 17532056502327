// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
module('eaw.calendar2').component('eawCalendarTitle', {
    controllerAs: '$calTitle',
    bindings: {
        date: '<',
        mode: '<',
    },
    controller: [ '$element', function($element) {
        const ctrl = this;
        ctrl.$onChanges = (changes) => {
            ctrl.mode = changes.mode?.currentValue || ctrl.mode;
            ctrl.date = changes.date?.currentValue.clone() || ctrl.date;
            ctrl.clean();
            switch (ctrl.mode) {
                case 'y': {
                    ctrl.generateYear();
                    break;
                }
                case 'w': {
                    ctrl.generateWeek();
                    break;
                }
            }
        };
        ctrl.clean = () => {
            $element[0].innerText = null;
            Array.from($element[0].children).forEach((c) => c.remove());
        };
        ctrl.generateYear = () => {
            $element[0].dataset.mode = ctrl.mode;
            $element[0].innerText = ctrl.date.year();
        };
        ctrl.generateWeek = () => {
            const months = document.createElement('div');
            const week = document.createElement('div');
            const sameMonth = ctrl.date.clone().startOf('w').isSame(ctrl.date.clone().endOf('w'), 'M');
            months.classList.add('months');
            week.classList.add('week');
            if (sameMonth) {
                months.innerText = ctrl.date.format('MMMM YYYY');
            } else {
                months.innerText = `${ctrl.date.clone().startOf('w').format('MMM')} - ${ctrl.date.clone().endOf('w').format('MMM')} ${ctrl.date.format('YYYY')}`;
            }
            week.innerText = t('WEEK_N', {
                number: ctrl.date.week(),
            });
            $element[0].dataset.mode = ctrl.mode;
            $element[0].append(months, week);
        };
    } ],
});
