import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { DateTimeConverter } from '../utils/date-time-converter';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {

    constructor(@Inject(HttpClient) private http: HttpClient) { }

    private static valueFormatter(value: any): string | null | undefined {
        if (value === null || value === undefined) {
            return value;
        }

        if (value instanceof DateTime) {
            return DateTimeConverter.convertDateTimeToUtcString(value);
        }

        if (!Number.isNaN(value)) {
            return (value as number).toString();
        }

        return value as string;
    }

    private static getFormData(body: Record<string, unknown>): FormData {
        const formData = new FormData();

        for (const [ key, value ] of Object.entries(body)) {
            if (typeof value === 'string') {
                formData.append(key, value);
                continue;
            }

            if (typeof value === 'number') {
                formData.append(key, String(value));
                continue;
            }

            if (typeof value === 'boolean') {
                formData.append(key, String(Number(value)));
                continue;
            }

            if (value instanceof File) {
                formData.append(key, value);
                continue;
            }
            if (Array.isArray(value)) {
                value.forEach((x: string | number) => {
                    formData.append(`${key}[]`, x.toString());
                });
                continue;
            }

            const formattedValue = FileUploadService.valueFormatter(value);
            if (formattedValue) {
                formData.append(key, formattedValue);
            }
        }

        return formData;
    }

    upload<Return>(url: string, body: Record<string, unknown>): Observable<Return> {
        return this.http.post<Return>(url, FileUploadService.getFormData(body), {
            reportProgress: true,
        });
    }
}
