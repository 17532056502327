// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="$settings.widget.i18name | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <md-progress-circular ng-if="!$settings.types" class="md-progress-center tw-mt-24"></md-progress-circular>

            <md-input-container ng-if="$settings.types">
                <label ng-i18next="TYPE"></label>
                <md-select flex ng-model="$settings.settings.kpi_type">
                    <md-option ng-repeat="type in $settings.types track by type.id" ng-value="type.id">
                        {{type.name | i18next}}
                    </md-option>
                </md-select>
            </md-input-container>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-click="$settings.submit()" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.dashboard').factory('miniKpiSettings', [ '$mdDialog', 'kpiTypeService', function($mdDialog, kpiTypeService) {
    return function miniAbsenceSettings(widget, settings) {
        return $mdDialog.show({
            template: template1,
            controllerAs: '$settings',
            multiple: true,
            controller: function() {
                const ctrl = this;

                ctrl.$onInit = () => {
                    ctrl.cancel = $mdDialog.cancel;
                    ctrl.widget = widget;
                    ctrl.settings = { ...settings };

                    kpiTypeService.get().then((types) => {
                        ctrl.types = types;
                        ctrl.settings.kpi_type = types?.[0]?.id;
                    });
                };

                ctrl.submit = () => {
                    $mdDialog.hide(ctrl.settings);
                };
            },
        });
    };
} ]);
