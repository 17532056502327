import moment from 'moment-timezone';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
import { cloneDeep } from 'lodash-es';
import { SnackBarService } from '../../../shared/services/snack-bar.service';

module('eaw').component('profileNotifications', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="profile:DONT_DISTURB_INTERVAL"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <form role="form" ng-submit="$ctrl.updateDnd()" name="dndForm" id="dndForm" layout="column">
            <div layout="column">
                <eaw-time-interval
                        input-flex="50"
                        ng-model="$ctrl.dnd"
                        from-required="true"
                        to-required="true">
                </eaw-time-interval>

                <div layout="row">
                    <md-button class="md-raised md-accent"
                               type="submit"
                               ng-disabled="dndForm.$pristine || dndForm.$invalid || $ctrl.sending"
                               ng-i18next="UPDATE">
                    </md-button>
                    <md-button class="md-raised md-warn"
                               type="reset"
                               ng-disabled="$ctrl.isEmpty() || $ctrl.sending"
                               ng-click="$ctrl.deleteDnd()"
                               ng-i18next="DELETE">
                    </md-button>
                </div>
            </div>
        </form>
    </md-card-content>
</md-card>

<eaw-accordion multi-toggle="true">
    <eaw-accordion-panel ng-click="$ctrl.openPanel(0)">
        <panel-header layout="row">
            <p ng-i18next="[i18next]({name: '$t(DEFAULT)'})notifications:X_SETTINGS"></p>
        </panel-header>
        <panel-content class="tw-p-0">
            <notification-setting-list ng-if="$ctrl.acc[0].opened" on-notification-updated="$ctrl.onUpdatedDefault()" user="$ctrl.user"></notification-setting-list>
        </panel-content>
    </eaw-accordion-panel>
    <eaw-accordion-panel ng-init="i = $index + 1" ng-hide="$ctrl.customers.length <= 1" ng-repeat="customer in $ctrl.customers" ng-click="$ctrl.openPanel(i)" toggled="$ctrl.acc[i].toggled">
        <panel-header>
            <p ng-i18next="[i18next]({name: customer.name})notifications:X_SETTINGS"></p>
        </panel-header>
        <panel-content class="tw-p-0">
            <notification-setting-list ng-if="$ctrl.acc[i].opened" customer="customer" user="$ctrl.user"></notification-setting-list>
        </panel-content>
    </eaw-accordion-panel>
</eaw-accordion>

`,
    controller: [ 'UserService', 'ToastService', function(UserService, ToastService: SnackBarService) {
        // @ts-ignore
        const ctrl = this;
        ctrl.$onInit = async () => {
            ctrl.user = CurrentOld.getUser();
            ctrl.customers = Object.values(CurrentOld.getCustomers()).map((c) => cloneDeep(c));
            ctrl.acc = new Array(ctrl.customers.length);
            UserService.getDnd(ctrl.user.id).then((dnd: any) => {
                ctrl.dnd = dnd;
            });
        };
        ctrl.isEmpty = () => !moment.isMoment(ctrl.dnd?.to) || !moment.isMoment(ctrl.dnd?.from);
        ctrl.updateDnd = () => {
            ctrl.sending = true;
            UserService.setDnd(ctrl.user.id, ctrl.dnd?.from?.clone() || null, ctrl.dnd?.to?.clone() || null).then((newDnd: any) => {
                ctrl.dnd = newDnd;
                ToastService.updated();
            }).finally(() => {
                ctrl.sending = false;
            });
        };
        ctrl.onUpdatedDefault = () => {
            // Close all accordion panels except default
            ctrl.acc.forEach((panel: any, i: number) => {
                if (!i || !panel) {
                    return;
                }
                panel.opened = false;
                panel.toggled = false;
            });
        };
        ctrl.openPanel = (index: number) => {
            if (ctrl.acc[index]) {
                ctrl.acc[index].opened = true;
            } else {
                ctrl.acc[index] = {
                    toggled: true,
                    opened: true,
                };
            }
        };
        ctrl.deleteDnd = () => {
            ctrl.sending = true;
            UserService.deleteDnd(ctrl.user.id).then(() => {
                ctrl.dnd = {};
                ToastService.deleted();
            }).finally(() => {
                ctrl.sending = false;
            });
        };
    } ],
});
