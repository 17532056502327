import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { SsoService, SsoServiceResponse } from '../models/sso-service';
import { classifyArrayPaginatedResponse } from '../../shared/utils/rxjs/classify';
import { IGNORE_ERROR } from '../../shared/http/http-contexts';

@Injectable({
    providedIn: 'root',
})
export class SsoServiceService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAllForCustomer(customerId: number, includeInherited: boolean) {
        return this.http.get<ArrayPaginatedResponse<SsoServiceResponse>>(`/customers/${customerId}/sso_services`, {
            params: {
                include_inherited: includeInherited,
            },
            context: new HttpContext().set(IGNORE_ERROR, [ 403 ]),
        }).pipe(classifyArrayPaginatedResponse(SsoService));
    }
}
