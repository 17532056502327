import { AfterViewInit, Component, ViewChild } from '@angular/core';
import type { EmployeeEntry } from '../employee-select.component';
import { EmployeeSelectComponent } from '../employee-select.component';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'eaw-employee-select:not([multiple])',
    templateUrl: './employee-select-single.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: EmployeeSelectSingleComponent,
        },
    ],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        NgFor,
        MatOptionModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmployeeSelectSingleComponent extends EmployeeSelectComponent implements AfterViewInit {
    @ViewChild('emps') autocomplete!: MatAutocomplete;
    protected employeeId?: number | string;

    ngAfterViewInit(): void {
        this.autocomplete.optionSelected.subscribe((event) => {
            this.touch();
            this.selected.setValue(this.getName(event.option.value));
            this.change(event.option.value);
        });
    }

    override afterDoCheck() {
        this.writeValue(undefined);
    }

    getName(id: number | string): string | undefined {
        return this.filteredEmployees.find((entry) => id == entry.id)?.name;
    }

    override writeValue(obj?: number | EmployeeEntry): void {
        const id: string | number | undefined = typeof obj === 'object' ? obj.id : obj;

        this.selected.setValue(id ? this.getName(id) : id);
    }
}
