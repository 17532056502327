<eaw-dialog-header>
    <span title>{{ 'EDIT_SHIFT' | translate:'scheduling' | async }}</span>
</eaw-dialog-header>

@if (loading()) {
    <eaw-info-loading size="lg"/>
} @else {
    <mat-tab-group #tabGroup [selectedIndex]="data.initialTab || 0" preserveContent>
        <mat-tab [label]="('SHIFT' | translate | async) || 'Shift'">
            <mat-dialog-content>
                @if (schedule && shift) {
                    <eaw-handle-shift
                            (shiftCreated)="close($event)"
                            (shiftUpdated)="close($event)"
                            [qualifications]="qualifications"
                            [withs]="shiftWiths"
                            [customerId]="data.customerId"
                            [schedule]="schedule"
                            [shift]="shift"/>
                }
            </mat-dialog-content>
        </mat-tab>

        <mat-tab [label]="('N_PERIODS' | translate:'scheduling':{ count: periods().length } | async) || 'Periods'">
            <eaw-shift-period-list [customerId]="data.customerId"
                                   [scheduleId]="data.scheduleId"
                                   [shiftId]="data.shiftId"
                                   [periods]="periods()"
                                   (periodEdited)="onPeriodEdited($event)"
                                   (periodDeleted)="onPeriodDeleted($event)"/>
        </mat-tab>

        <mat-tab [label]="('X_COMMENT' | translate:'general':{count: comments.length} | async) || 'Comments'">
            <mat-dialog-content class="tw-flex tw-flex-col tw-gap-16">
                <mat-form-field>
                    <mat-label>{{ 'COMMENT' | translate | async }}</mat-label>
                    <textarea [formControl]="commentControl" matInput></textarea>
                </mat-form-field>

                <eaw-action-button [loading]="addingComment" [disabled]="!commentControl.value" (click)="addComment()">{{ 'ADD_COMMENT' | translate | async }}</eaw-action-button>

                <eaw-comment-list [comments]="comments" [canDelete]="canDelete" [deleteCallback]="deleteComment"/>
            </mat-dialog-content>
        </mat-tab>

        <mat-tab [label]="('WARNING_COUNT' | translate:'general':{count: warnings.length} | async) || 'Warnings'">
            <mat-dialog-content>
                <mat-list>
                    @for (warning of warnings; track warning.id) {
                        <mat-list-item>
                            @if (warning.messageParameters) {
                                <span matListItemLine>{{ warning.message | translate:'warnings':warning.messageParameters | async }}</span>
                            } @else {
                                <span matListItemLine>{{ warning.message | translate:'warnings' | async }}</span>
                            }

                            <div matListItemMeta>
                                <button mat-icon-button (click)="openCauseDialog(warning)">
                                    <mat-icon>info</mat-icon>
                                </button>
                            </div>
                        </mat-list-item>

                        @if (!$last) {
                            <mat-divider/>
                        }
                    }
                </mat-list>
            </mat-dialog-content>
        </mat-tab>

        @if ([autoSchedulingProduct] | product | async) {
            <mat-tab [label]="('AUTO_POPULATION_RESULT' | translate:'autosched' | async) || 'Auto population result'">
                <ng-template matTabContent>
                    <eaw-auto-scheduling-shift-result [customerId]="data.customerId" [scheduleId]="data.scheduleId" [shiftId]="data.shiftId"/>
                </ng-template>
            </mat-tab>
        }
    </mat-tab-group>

    @switch (tabGroup.selectedIndex) {
        @case (0) {
            <mat-dialog-actions class="tw-flex tw-flex-col sm:tw-flex-row">
                <button mat-raised-button color="warn" (click)="deleteShift()">{{ 'DELETE' | translate | async }}</button>
                <span class="tw-flex-1"></span>
                <button mat-button mat-dialog-close>{{ 'CLOSE' | translate | async }}</button>
                <eaw-action-button [loading]="handleShiftComponent()?.creating || false" [disabled]="handleShiftComponent()?.form?.invalid" (click)="handleShiftComponent()?.update()">{{ 'UPDATE_SHIFT' | translate:'scheduling' | async }}</eaw-action-button>
            </mat-dialog-actions>
        }
        @case (1) {
            <mat-dialog-actions>
                <button mat-button mat-dialog-close>{{ 'CLOSE' | translate | async }}</button>
                <eaw-action-button [loading]="false" [disabled]="false" (click)="addPeriod()">{{ 'ADD_PERIOD' | translate:'scheduling' | async }}</eaw-action-button>
            </mat-dialog-actions>
        }
        @default {
            <mat-dialog-actions>
                <button mat-button mat-dialog-close>{{ 'CLOSE' | translate | async }}</button>
            </mat-dialog-actions>
        }
    }
}

