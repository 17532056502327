// @ts-nocheck
import { find } from 'lodash-es';
import { sortBy } from 'lodash-es';

import { module } from 'angular';

const template1 = `<!-- We have to track by index here because of some freak case where children have same id as checklist -->
<div ng-repeat="child in $children.children track by $index"
     data-item-level="{{::child.level}}"
     data-item-level-color="{{::child.levelColor}}"
     ng-class="{'category-padding': child.isCategory}">

    <checklist-view-category-item ng-if="child.isCategory" checklist="$children.checklist" item="child"></checklist-view-category-item>
    <checklist-view-item ng-if="!child.isCategory" checklist="$children.checklist" item="child"></checklist-view-item>

    <checklist-children ng-if="child.expanded && child.children.length > 0" checklist="$children.checklist" children="child.children"></checklist-children>
</div>
`;

module('eaw.checklists').component('checklistChildren', {
    template: template1,
    controllerAs: '$children',
    bindings: {
        checklist: '<',
        children: '<',
    },
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.children = ctrl.children || [];
            ctrl.children = ctrl.weighChildren(ctrl.children);
        };

        ctrl.weighChildren = (children) => sortBy(children, (i) => {
            ctrl.getWarnings(i);
            return i.weight;
        });

        ctrl.getWarnings = (item) => {
            item.warnings = find(ctrl.checklist.all_items, { id: item.id })?.warnings;
        };
    },
});
