import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CreateScheduleTemplateDialogData } from '../create-template-dialog.service';
import { ScheduleTemplateService } from '../../../http/schedule-template.service';
import { TranslateService } from '../../../../shared/services/translate.service';
import { SettingService } from '../../../../shared/http/setting.service';
import { ScheduleTemplate } from '../../../models/schedule-template';
import { DialogComponent, DialogSize } from '../../../../shared/dialogs/dialog-component';
import { getNextDstChange, overlapsDst } from '../../../../shared/utils/eaw-dst';
import { DateTime, Duration } from 'luxon';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../../shared/directives/date-picker-options.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { NgIf, AsyncPipe, LowerCasePipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';

interface DialogDataNoEdit {
    edit?: false;
    schedule?: never;
}

interface DialogDataEdit {
    edit?: true;
    schedule: ScheduleTemplate;
}

export type CreateRepeatingScheduleTemplateDialogData = (DialogDataEdit | DialogDataNoEdit) & CreateScheduleTemplateDialogData;

@Component({
    selector: 'eaw-repeating-template-create',
    templateUrl: './repeating-template-create.component.html',
    styleUrl: './repeating-template-create.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatDialogActions,
        MatDialogClose,
        AsyncPipe,
        LowerCasePipe,
        TranslatePipe,
    ],
})
export class RepeatingTemplateCreateComponent extends DialogComponent {
    formGroup = new FormGroup({
        name: new FormControl<string | null>(null, Validators.required),
        startDate: new FormControl<DateTime | null>(null, Validators.required),
        duration: new FormControl<number | null>(null, [ Validators.required, Validators.min(1) ]),
    });

    overlapErrorText?: string;
    initialStartDateTime?: DateTime;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateRepeatingScheduleTemplateDialogData,
        @Inject(ScheduleTemplateService) public scheduleTemplateService: ScheduleTemplateService,
        @Inject(TranslateService) public translate: TranslateService,
        @Inject(SettingService) public settingService: SettingService,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<RepeatingTemplateCreateComponent, ScheduleTemplate>,
    ) {
        data.size = DialogSize.Small;
        super(dialogRef, data);

        if (data.edit) {
            this.initialStartDateTime = data.schedule.from;
            this.formGroup.setValue({
                name: data.schedule.name,
                startDate: data.schedule.from,
                duration: Duration.fromObject({ seconds: data.schedule.length }).as('weeks'),
            });

            this.formGroup.controls.duration.disable();
        }

        this.formGroup.valueChanges.subscribe(() => {
            void this.durationChange();
        });
    }

    dateFilter(date: DateTime | null) {
        return date?.weekday === 1;
    }

    get weeksDuration(): number | null | undefined {
        return this.formGroup.controls.duration.value;
    }

    autoAdjust() {
        const startDate = this.formGroup.controls.startDate;
        const duration = this.formGroup.controls.duration.value;
        if (duration == null) {
            return;
        }

        startDate.setValue((startDate.value as DateTime).minus({ week: duration }));
    }

    get secondsDuration(): number | null {
        const weeks = this.weeksDuration;
        return weeks ? weeks * 86_400 * 7 : null;
    }

    getErrorText(from: DateTime) {
        return this.translate.t('DST_OVERLAP_WARN_2', 'scheduling', {
            count: this.weeksDuration || 0,
            datestring: getNextDstChange(from)?.toLocaleString(DateTime.DATETIME_MED) || '',
        });
    }

    async durationChange() {
        const from = this.formGroup.controls.startDate.value;
        const seconds = this.secondsDuration;

        if (!(from && seconds)) {
            return;
        }

        const to = from.plus({ second: seconds });

        this.overlapErrorText = overlapsDst(from, to) ? await this.getErrorText(from) : undefined;
    }

    private getObservable(): Observable<ScheduleTemplate> | Observable<never> {
        const from = this.formGroup.controls.startDate.value;
        const name = this.formGroup.controls.name.value;

        if (!from || !name) {
            return EMPTY;
        }

        const scheduleId = this.data.schedule?.id;
        const duration = (this.formGroup.controls.duration.value || 0) * 86_400 * 7;

        if (this.data.edit) {
            const initialFrom = this.initialStartDateTime;

            if (!scheduleId || !initialFrom) {
                return EMPTY;
            }

            return this.scheduleTemplateService.update(this.data.customerId, scheduleId, {
                from: from?.hasSame(this.data.schedule.from, 'day') ? undefined : from?.set({
                    hour: initialFrom.hour,
                    minute: initialFrom.minute,
                    second: initialFrom.second,
                }),
                name: name ?? undefined,
            });
        }

        return this.settingService.getSome([ 'customers', this.data.customerId ], { 'settings[]': [ 'schedule:hours_offset', 'schedule:also_offset_repeating' ] })
            .pipe(switchMap((settings) => {
                const offsetRepeating = settings.find((setting) => setting.key === 'schedule:also_offset_repeating')?.value?.asBoolean() ?? false;
                const offset = settings.find((setting) => setting.key === 'schedule:hours_offset')?.value?.asInteger() ?? 0;

                return this.scheduleTemplateService.createDefaultTemplate(this.data.customerId, {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    from: offsetRepeating ? from!.plus({ hours: offset }): from,
                    name,
                    length: duration,
                });
            }));
    }

    handle() {
        this.formGroup.disable();
        this.getObservable()
            .subscribe({
                error: () => {
                    this.formGroup.enable();

                    if (this.data.edit) {
                        this.formGroup.controls.duration.disable();
                    }

                    return EMPTY;
                },
                next: (result) => {
                    this.dialogRef.close(result);
                },
            });
    }
}
