import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Todo } from '../../models/todo';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { TodoStatus } from '../../models/todo-status';
import { TodoService } from '../../http/todo.service';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '../../../shared/services/translate.service';
import { DatePickerDialogComponent, DatePickerDialogData, DatePickerDialogReturn } from '../../../shared/dialogs/date-picker-dialog/date-picker-dialog.component';
import { DateTime } from 'luxon';
import { debounceTime, distinctUntilChanged, forkJoin, of, startWith, Subscription, switchMap, take, tap } from 'rxjs';
import { TodoAttachmentService } from '../../http/todo-attachment.service';
import { AddAttachmentsDialogComponent, AddAttachmentsDialogComponentData } from '../../../shared/dialogs/add-attachments-dialog/add-attachments-dialog.component';
import { TodoStatusService } from '../../http/todo-status.service';
import { TodoStatusType } from '../../enums/todo-status-type';
import { User } from '../../../shared/models/user';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { CommentListComponent } from '../../../shared/components/comment-list/comment-list.component';
import { ImageAttachmentComponent } from '../../../shared/components/image-attachment/image-attachment.component';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { CustomerUserAutocompleteComponent } from '../../../shared/components/customer-user-autocomplete/customer-user-autocomplete.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { CommentDialogComponent, CommentDialogData } from '../../../shared/dialogs/comments-dialog/comment-dialog.component';

export interface TodoInfoDialogData extends DialogData {
    todo: Todo,
}

@Component({
    selector: 'eaw-todo-info-dialog',
    templateUrl: './todo-info-dialog.component.html',
    styleUrl: './todo-info-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatButtonModule,
        MatIconModule,
        MatDialogContent,
        MatListModule,
        ReactiveFormsModule,
        CustomerUserAutocompleteComponent,
        MatDividerModule,
        NgIf,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatInputModule,
        ImageAttachmentComponent,
        CommentListComponent,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class TodoInfoDialogComponent extends DialogComponent implements OnInit, OnDestroy {
    private getSubscription?: Subscription;

    todo: Todo;
    updating = false;
    /**
     * Number of times we fetched the todo item, if it's more than one
     * it means the todo was changed in some way
     */
    todoGets = 0;
    statuses: Map<keyof typeof TodoStatusType, TodoStatus[]> = new Map();
    statusControl = new FormControl<TodoStatus | null>(null);
    descriptionControl = new FormControl<string | null>('');
    responsible = new FormControl<User | number | string>(this.data.todo.responsibleId || '');
    formGroup = new FormGroup({ responsible: this.responsible });
    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: TodoInfoDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<TodoInfoDialogComponent, boolean>,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(TodoService) private todoService: TodoService,
        @Inject(PromptDialogService) private promptDialogService: PromptDialogService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(TodoAttachmentService) private todoAttachmentService: TodoAttachmentService,
        @Inject(TodoStatusService) private todoStatusService: TodoStatusService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
    ) {
        data.size = DialogSize.Large;
        dialogRef.disableClose = true;
        super(dialogRef, data);

        this.todo = data.todo;
        this.subscriptions.push(this.dialogRef.backdropClick().subscribe(this.close.bind(this)));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngOnInit(): void {
        this.getTodo();
        this.getStatuses();

        let sub = this.responsible.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            switchMap((user) => {
                let userId;
                if (user instanceof User) {
                    userId = user.id;
                } else if (!user) {
                    userId = null;
                }

                if (userId !== undefined && userId != this.todo.responsibleId) {
                    return this.todoService.update(this.todo.customerId, this.todo.id, {
                        responsible_id: userId,
                    }).pipe(tap(this.getTodo.bind(this)));
                }

                return of(user);
            }),
        ).subscribe();

        this.subscriptions.push(sub);

        sub = this.descriptionControl.valueChanges.pipe(
            startWith(''),
            debounceTime(1000),
            distinctUntilChanged(),
            switchMap((description) => {
                if (description && description != this.todo.description) {
                    // Increment before so if it's closed during a get it still counts
                    this.todoGets += 1;

                    return this.todoService.update(this.todo.customerId, this.todo.id, {
                        description,
                    }).pipe(tap(() => this.snackbar.t('TODO_UPDATED', 'todo')));
                }

                return of(description);
            }),
        ).subscribe();

        this.subscriptions.push(sub);
    }

    close() {
        this.dialogRef.close(this.todoGets > 1);
    }

    getStatuses() {
        this.statusControl.disable();
        const sub = this.todoStatusService.getAll(this.todo.customerId).pipe(take(1)).subscribe((res) => {
            this.statusControl.setValue(res.data.find((s) => s.id === this.todo.statusId) || null);
            this.statusControl.enable();

            this.statuses = new Map([
                [ 'Default', [] ],
                [ 'InProgress', [] ],
                [ 'Done', [] ],
            ]);
            res.data.forEach((status) => {
                if (status.type === 'initial') {
                    return;
                }

                switch (status.type) {
                    case TodoStatusType.Default: {
                        this.statuses.get('Default')?.push(status);
                        break;
                    }
                    case TodoStatusType.InProgress: {
                        this.statuses.get('InProgress')?.push(status);
                        break;
                    }
                    case TodoStatusType.Done: {
                        this.statuses.get('Done')?.push(status);
                        break;
                    }
                }
            });
        });

        this.subscriptions.push(sub);
    }

    changeStatus() {
        if (!this.statusControl.value) {
            return;
        }

        const sub = this.todoService.update(this.todo.customerId, this.todo.id, {
            status_id: this.statusControl.value.id,
        }).subscribe(this.getTodo.bind(this));

        this.subscriptions.push(sub);
    }

    getTodo() {
        if (this.todoGets) {
            void this.snackbar.t('TODO_UPDATED', 'todo');
        }

        // Increment before so if it's closed during a get it still counts
        this.todoGets += 1;

        // Unsubscribe
        this.getSubscription?.unsubscribe();

        this.descriptionControl.disable();

        this.updating = true;
        this.getSubscription = this.todoService.get(this.todo.customerId, this.todo.id, {
            'with[]': [ 'comments', 'attachments', 'status' ],
        }).subscribe((todo) => {
            this.todo = todo;
            this.descriptionControl.setValue(this.todo.description || '');
            this.updating = false;
            this.descriptionControl.enable();
        });
    }

    openComments() {
        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                comments: of(this.todo.comments || []),
                hideComments: true,
                title: this.translate.t('TODO_COMMENTS', 'todo'),
                saveCallback: (comment) => {
                    return this.todoService.addComment(this.todo.customerId, this.todo.id, comment).pipe(
                        tap(() => {
                            this.getTodo();
                        }),
                    );
                },
            },
        });
    }

    addImage() {
        const sub = this.matDialog.open<AddAttachmentsDialogComponent, AddAttachmentsDialogComponentData, boolean>(AddAttachmentsDialogComponent, {
            data: {
                saveFn: (files) => {
                    return forkJoin(files.map((f) => {
                        return this.todoAttachmentService.create(this.data.todo.customerId, this.data.todo.id, f);
                    }));
                },
            },
        }).afterClosed().subscribe((res) => {
            if (res) {
                this.getTodo();
            }
        });

        this.subscriptions.push(sub);
    }

    changeTitle() {
        const sub = this.promptDialogService.open('text', {
            formControl: new FormControl(this.todo.title, Validators.required),
            label: this.translate.t('TITLE'),
            title: this.translate.t('TITLE'),
            options: {
                maxLength: 160,
            },
        }).afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }

            this.todoService.update(this.todo.customerId, this.todo.id, {
                title: res,
            }).subscribe(this.getTodo.bind(this));
        });
        this.subscriptions.push(sub);

    }

    changeFrom() {
        const sub = this.matDialog.open<DatePickerDialogComponent, DatePickerDialogData, DatePickerDialogReturn>(DatePickerDialogComponent, {
            data: {
                target: 'date',
                includeTime: true,
                date: this.todo.from || undefined,
                minDate: DateTime.now(),
                maxDate: this.todo.due || undefined,
            },
        }).afterClosed().subscribe((res) => {
            if (res?.ok) {
                this.todoService.update(this.todo.customerId, this.todo.id, {
                    from: res.value,
                }).subscribe(this.getTodo.bind(this));
            }
        });

        this.subscriptions.push(sub);
    }

    changeDue() {
        const minDate = this.todo.from && this.todo.from > DateTime.now() ? this.todo.from : DateTime.now();
        const sub = this.matDialog.open<DatePickerDialogComponent, DatePickerDialogData, DatePickerDialogReturn>(DatePickerDialogComponent, {
            data: {
                target: 'date',
                includeTime: true,
                date: this.todo.due || undefined,
                minDate,
            },
        }).afterClosed().subscribe((res) => {
            if (!res?.ok) {
                return;
            }

            this.todoService.update(this.todo.customerId, this.todo.id, {
                due: res.value,
            }).subscribe(this.getTodo.bind(this));
        });

        this.subscriptions.push(sub);
    }
}
