// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module, noop } from 'angular';

const template1 = `<md-dialog>
    <form name="addEdContTypeForm" ng-cloak>
        <eaw-dialog-header-old ng-if="!aecCtrl.edit" heading="'admin:ADD_CONTRACT_TYPE' | i18next"></eaw-dialog-header-old>
        <eaw-dialog-header-old ng-if="aecCtrl.edit" heading="'admin:EDIT_CONTRACT_TYPE' | i18next"></eaw-dialog-header-old>

        <md-dialog-content>
            <div class="md-dialog-content" layout="column">
                <md-input-container>
                    <label ng-i18next="NAME"></label>
                    <input ng-model="aecCtrl.name" required>
                </md-input-container>

                <eaw-date-time-interval layout="row" ng-model="aecCtrl.interval"></eaw-date-time-interval>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button ng-click="aecCtrl.submit()" ng-i18next="SUBMIT" ng-disabled="addEdContTypeForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.admin').controller('settingGroupContractTypeController', [ 'settingGroup', 'ContractTypeFactory', '$mdDialog', 'ToastService', function(settingGroup, ContractTypeFactory, $mdDialog, ToastService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.settingGroup = settingGroup;

        ctrl.table = {
            columns: [
                {
                    title: t('ID'),
                    sortable: 'id',
                },
                {
                    title: t('NAME'),
                    sortable: 'name',
                },
                {
                    title: t('FROM'),
                    sortable: 'from',
                },
                {
                    title: t('TO'),
                    sortable: 'to',
                },
                { title: '' },
            ],
            cells: [
                { template: 'item.id' },
                { template: 'item.name' },
                { template: 'item.from | moment' },
                { template: 'item.to | moment' },
                {
                    buttons: [
                        {
                            icon: 'edit',
                            click: ctrl.addEditContractType,
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            click: ctrl.removeContractType,
                        },
                    ],
                },
            ],
        };
    };

    ctrl.removeContractType = (type) => {
        $mdDialog.show(
            $mdDialog.confirm()
                .theme('delete')
                .title(t('DELETE'))
                .textContent(`Delete the contract type ${type.name}?`)
                .ok(t('DELETE'))
                .cancel(t('CANCEL')),
        ).then(() => {
            ContractTypeFactory.delete({
                setting_group: ctrl.settingGroup,
                contract_type: type,
            }).$promise.then(() => {
                ToastService.toast(`Removed contract type ${type.name}`);
                ctrl.reloadTable = true;
            }, noop);
        });
    };

    ctrl.addEditContractType = (type) => {
        $mdDialog.show({
            template: template1,
            controllerAs: 'aecCtrl',
            controller: function() {
                const aecCtrl = this;

                aecCtrl.$onInit = () => {
                    aecCtrl.edit = !!type;
                    aecCtrl.name = type?.name;
                    aecCtrl.interval = {
                        from: type?.from,
                        to: type?.to,
                    };
                };

                aecCtrl.add = () => {
                    ContractTypeFactory.create({
                        setting_group: ctrl.settingGroup,
                        name: aecCtrl.name,
                        from: aecCtrl.interval?.from ?? null,
                        to: aecCtrl.interval?.to ?? null,
                    }).$promise.then((data) => {
                        ToastService.toast(`Added contract type ${data.name}`);
                        ctrl.reloadTable = true;
                        $mdDialog.hide();
                    }, noop);
                };

                aecCtrl.update = () => {
                    ContractTypeFactory.update({
                        setting_group: ctrl.settingGroup,
                        contract_type: type,
                        name: aecCtrl.name,
                        from: aecCtrl.interval?.from ?? null,
                        to: aecCtrl.interval?.to ?? null,
                    }).$promise.then((data) => {
                        ToastService.toast(`Updated contract type ${data.name}`);
                        ctrl.reloadTable = true;
                        $mdDialog.hide();
                    }, noop);
                };

                aecCtrl.submit = () => {
                    if (aecCtrl.edit) {
                        aecCtrl.update();
                    } else {
                        aecCtrl.add();
                    }
                };

                aecCtrl.cancel = $mdDialog.cancel;
            },
        });
    };
} ],
);
