import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'eaw-weather-icon',
    standalone: true,
    imports: [
        NgOptimizedImage,
    ],
    templateUrl: './weather-icon.component.html',
    styleUrl: './weather-icon.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherIconComponent {
    size = input<number>(24);
    symbol = input.required<string>();
    iconUrl = computed(() => `/assets/weather-icons/${this.symbol()}.svg`);
}
