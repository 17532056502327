import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, signal, viewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { DataTableComponent } from '../../../data-table/data-table.component';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { DataTablePagination } from '../../../data-table/types/data-table';
import { CustomerGroup } from '../../../shared/models/customer-group';
import { AddCustomerGroupDialogComponent, CustomerGroupDialogData } from '../../dialogs/add-customer-group-dialog/add-customer-group-dialog.component';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { filter, tap } from 'rxjs';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { UIRouter } from '@uirouter/core';

@Component({
    selector: 'eaw-customer-groups-list',
    standalone: true,
    imports: [
        AsyncPipe,
        DataTableComponent,
        MatCard,
        MatCardContent,
        PageHeaderComponent,
        TranslatePipe,
    ],
    templateUrl: './customer-groups-list.component.html',
    styleUrl: './customer-groups-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerGroupsListComponent {
    private uiRouter = inject(UIRouter);
    private destroyedRef = inject(DestroyRef);
    private matDialog = inject(MatDialog);
    customerGroupService = inject(CustomerGroupService);
    permissionCheckService = inject(PermissionCheckService);

    fabButton: HeaderFabButton = {
        hasPermission: () => this.permissionCheckService.isAllowed(`customer_groups.[${ApiModel.Group}].members.add`),
        click: this.addGroup.bind(this),
    };

    customerId = this.uiRouter.globals.params['id'];
    columns: DataTableColumnType<CustomerGroup>[] = [
        new DataTableTextColumn({
            value: 'id',
            header: new DataTableHeader({
                text: 'Id',
                sortBy: 'id',
            }),
        }),

        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
    ];

    table = viewChild.required(DataTableComponent);

    customerGroupIds = signal<number[]>([]);
    getData = computed(() => (pagination: Partial<DataTablePagination>) =>
        this.customerGroupService.getAllForCustomer(this.customerId, { ...pagination }).pipe(tap((resp) =>
            this.customerGroupIds.set(resp.data.map((item: CustomerGroup) => item.id)))));

    addGroup(): void {
        this.matDialog.open<AddCustomerGroupDialogComponent, CustomerGroupDialogData>(AddCustomerGroupDialogComponent, {
            data: {
                size: DialogSize.Medium,
                customerId: this.customerId,
                customerGroupIds: this.customerGroupIds(),
            },
        }).afterClosed()
            .pipe(
                takeUntilDestroyed(this.destroyedRef),
                filter((result) => !!result),
                tap(() => this.table()?.refresh()),
                tap((ids) => this.customerGroupIds.set(ids)),
            ).subscribe();
    }
}
