import { Component, Inject, Input, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { catchError, EMPTY, forkJoin, map, of } from 'rxjs';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { Availability } from '../../models/availability';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableApprovalColumn } from '../../../data-table/types/data-table-approval-column';
import { AvailabilityService } from '../../http/availability.service';
import { DateTime } from 'luxon';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { Namespace } from '../../../shared/enums/namespace';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateAvailabilityDialogData, CreateAvailabilityDialogComponent } from '../../dialogs/create-availability/create-availability-dialog.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { AvailabilityDaysDialogComponent, AvailabilityDaysDialogData } from '../../dialogs/availability-days-dialog/availability-days-dialog.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { SettingService } from '../../../shared/http/setting.service';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-availabilities-list',
    templateUrl: './availabilities-list.component.html',
    styleUrl: './availabilities-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AvailabilitiesListComponent implements EawDataTable {
    @ViewChild(DataTableComponent) table?: DataTableComponent<Availability>;

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) employeeId!: number;

    canCreate = false;
    fabButton: HeaderFabButton = {
        click: this.createAvailability.bind(this),
        hasPermission: () => {
            return forkJoin([
                this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.availabilities.create`),
                this.settingService.getValue<number | null>([ 'customers', this.customerId ], 'france.use_combined_contract', 0).pipe(map((value) => !!value)),
            ]).pipe(
                map(([ canCreate, useCombinedContract ]) => canCreate && !useCombinedContract),
            );
        },
    };

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<Availability>[] = [
        new DataTableDateTimeColumn({
            value: 'createdAt',
            format: DateTime.DATETIME_MED_WITH_WEEKDAY,
            header: new DataTableHeader({
                i18n: 'SENT',
                sortBy: 'created_at',
            }),
        }),
        // Displays number of weeks the availability lasts
        new DataTableTextColumn({
            value: (cell) => {
                let key = 'N_WEEK';
                let divisor = 7;

                if (cell.item.repeat < 7) {
                    divisor = 1;
                    key = 'N_DAY';
                }

                return this.translate.t(key, undefined, { count: Math.round((cell.item.repeat * 10) / divisor) / 10 });
            },
            header: new DataTableHeader({
                i18n: 'LENGTH',
                sortBy: 'repeat',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            header: new DataTableHeader({
                i18n: 'FROM',
                sortBy: 'from',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            header: new DataTableHeader({
                i18n: 'TO',
                sortBy: 'to',
            }),
        }),
        // Show if availability is approved
        new DataTableApprovalColumn({
            approval: (cell) => cell.item.approval,
            header: new DataTableHeader({ i18n: 'APPROVAL' }),
        }),
        // Shows the first comment it finds, if there is any
        new DataTableTextColumn({
            value: (cell) => cell.item.comments?.at(0)?.body || '',
            header: new DataTableHeader({ i18n: 'COMMENT' }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    click: this.delete.bind(this),
                    icon: 'delete',
                    tooltip: { key: 'DELETE' },
                    hide: (item) => of(item.approval?.approved != undefined), // Using != and not !== in order to catch both null and undefined
                    type: 'warn',
                    show: (item) => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.availabilities.${item.id}.delete`),
                },
            ],
        }),
    ];

    constructor(
        @Inject(AvailabilityService) private service: AvailabilityService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(SettingService) private settingService: SettingService,
    ) { }

    // Open create availability dialog, then update table when created
    async createAvailability() {
        this.matDialog.open<CreateAvailabilityDialogComponent, CreateAvailabilityDialogData, Availability | undefined>(CreateAvailabilityDialogComponent, {
            data: {
                employeeId: this.employeeId,
                customerId: this.customerId,
            },
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            void this.snackbar.t('AVAILABILITY_SUBMITTED', Namespace.Availabilities);
            this.table?.refresh();
        });
    }

    // Shows an availability in a dialog when you click on it
    showAvailability(availability: Availability) {
        this.matDialog.open<AvailabilityDaysDialogComponent, AvailabilityDaysDialogData>(AvailabilityDaysDialogComponent, {
            data: {
                customerId: this.customerId,
                availability: of(availability),
            },
        });
    }

    // Updates table when called, or is called when table is updated
    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.service.getAll(this.customerId, this.employeeId, {
            'with[]': [ 'days', 'approval', 'comments.user' ],
            ...pagination,
        });
    }

    delete(cell: DataTableCell<DataTableButtonColumn<Availability>, Availability>) {
        this.confirmDialogService.open({
            title: this.translate.t('DELETE'),
            text: this.translate.t('DELETE_AVAILABILITY', 'availabilities'),
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.service.delete(this.customerId, this.employeeId, cell.item.id!).pipe(
                catchError((err) => {
                    console.error(err);
                    cell.disabled.set(false);
                    return EMPTY;
                }),
            ).subscribe(() => {
                this.table?.refresh();
            });
        });
    }
}
