// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
import { smallDevice } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';

module('eaw.chain-ops.absences').component('chainAbsences', {
    template: `<form name="absenceListForm" id="absenceListForm" class="tw-pb-16 tw-pl-12" ng-submit="$absences.update()">
    <div layout="row" layout-wrap class="tw-pl-4 tw-pr-4 ">
        <approval-mode class="tw-pl-4 tw-pr-4" flex="100" flex-gt-md="20" flex-gt-sm="33" ng-model="$absences.mode" default-mode="all"></approval-mode>
        <absence-type-select class="tw-pl-4 tw-pr-4" flex="100" flex-gt-md="20" flex-gt-sm="33" setting-group-id="$absences.settingGroupIds" ng-model="$absences.selectedTypes"></absence-type-select>
        <contract-type-select class="tw-pl-4 tw-pr-4" flex="100" flex-gt-md="20" flex-gt-sm="33" setting-group-ids="$absences.settingGroupIds" ng-model="$absences.selectedContractTypes"></contract-type-select>

        <eaw-date-interval
                class="tw-pl-4 tw-pr-4"
                layout="row"
                ng-model="$absences.interval"
                flex="100"
                flex-gt-md="40"
                flex-gt-sm="66">
        </eaw-date-interval>
    </div>

    <md-card-actions layout="row">
        <md-button type="submit" form="absenceListForm" class="md-accent md-raised">
            <span ng-i18next="UPDATE"></span>
        </md-button>
        <md-button class="md-raised" type="reset" ng-click="$absences.resetFilter()" ng-i18next="RESET"></md-button>
    </md-card-actions>
</form>

<md-divider></md-divider>

<eaw-dynamic-ng-table
        flex="grow"
        columns="$absences.table.columns"
        cells="$absences.table.cells"
        get-data="$absences.getData(params, pagination)"
        pagination="$absences.pagination"
        reload-table="$absences.reloadTable"
        go-to="eaw/app/chain_ops/absences/tabs/absences/tabs|group=cg|c=customer_id|e=employee_id|absence=id">
</eaw-dynamic-ng-table>
`,
    controllerAs: '$absences',
    bindings: {
        customerGroup: '<',
        customer: '<',
        settingGroupIds: '<',
    },
    controller: [ 'ContractTypeFactory', 'Absence', 'UrlParams', '$filter', function chainAbsences(ContractTypeFactory, Absence, UrlParams, $filter) {
        this.$onInit = () => {
            this.selectedCustomers = [];
            this.interval = {
                from: UrlParams.get('from', moment().subtract(1, 'month')),
                to: UrlParams.get('to', moment().add(1, 'month')),
            };
            this.selectedTypes = UrlParams.get('types');
            this.selectedContractTypes = UrlParams.get('contractTypes');
            this.table = {
                columns: [
                    {
                        title: t('EMPLOYEE'),
                        sortable: 'employee_id',
                    },
                    {
                        title: t('APPROVED'),
                        class: 'text-center',
                    },
                    {
                        title: t('TYPE'),
                        sortable: 'type_id',
                        show: !smallDevice(),
                    },
                    {
                        title: t('FROM'),
                        sortable: 'from',
                    },
                    {
                        title: t('TO'),
                        sortable: 'to',
                    },
                    {
                        title: t('absences:GRADE'),
                        show: !smallDevice(),
                    },
                    {
                        title: t('absences:ABSENCE_LENGTH'),
                        sortable: 'length',
                        show: !smallDevice(),
                    },
                    {
                        title: t('company:CONTRACT_TYPE'),
                        show: !smallDevice(),
                    },
                ],
                cells: [
                    { template: 'item.employee.name' },
                    {
                        approval: {
                            displayName: true,
                            displayTime: true,
                        },
                    },
                    { template: 'item.type.name | eawTranslate:"absence_types"' },
                    {
                        template: 'item.from | moment: (item.type.span == "day" ? "date" : "datetime") ',
                        sortable: 'from',
                        classes: [ 'overflow-ellipsis', 'p-r-0' ],
                    },
                    {
                        template: 'item.to | moment: (item.type.span == "day" ? "date" : "datetime") ',
                        sortable: 'to',
                        classes: [ 'overflow-ellipsis', 'p-r-0' ],
                    },
                    { template: `item.grade | percent` },
                    {
                        template: 'item.length | eawDuration',
                        sortable: 'length',
                    },
                    { template: `item.contract_type` },
                ],
            };
        };
        this.resetFilter = () => {
            this.mode = { handled: 0 };
            this.filterInterval = {};
            this.selectedTypes = [];
            this.selectedContractTypes = [];
        };
        this.update = () => {
            this.reloadTable = true;
        };
        this.getData = async (params, pagination) => {
            UrlParams.set('from', this.interval?.from);
            UrlParams.set('to', this.interval?.to);
            UrlParams.set('types', this.selectedTypes);
            UrlParams.set('contract_type_ids', this.selectedContractTypes);
            const contractTypes = await this.getContractTypes();

            const resp = await Absence.getForCustomerGroup(Object.assign({
                typeIds: this.selectedTypes,
                groupId: this.customerGroup.id,
                mode: this.mode,
                contractTypeIds: this.selectedContractTypes,
                with: [ 'employee', 'type', 'approval' ],
            }, pagination, this.mode, this.interval)).$promise;
            resp.data = resp.data.map((a) => {
                a.customer_id = a.employee.customer_id;
                a.cg = this.customerGroup.id;
                const contractType = a.employee.contracts ? contractTypes.filter((type) => a.employee.contracts[0]?.type_id == type.id) : undefined;
                a.contract_type = contractType ? contractType[0].i18n : '';
                return new Absence(a);
            });
            return resp;
        };
        this.getContractTypes = async () => {
            if (Array.isArray(this.settingGroupIds)) {
                const typesMap = new Map();
                const contractTypesPromises = this.settingGroupIds.map(async (id) => {
                    const contractTypesResp = await ContractTypeFactory.getAll({ setting_group_id: id }).$promise;
                    const resp = contractTypesResp.data.map((type) => ({
                        i18n: $filter('eawTranslate')(type.name, 'general'),
                        ...type,
                    }));
                    resp.forEach((type) => {
                        if (!typesMap.has(type.id)) {
                            typesMap.set(type.id, type);
                        }
                    });
                });
                await Promise.all(contractTypesPromises);

                return Array.from(typesMap.values());
            } else {
                return [];
            }
        };
    } ],
});
