<mat-dialog-content>
    <eaw-kpi [customerId]="data.customerId"
             [title]="'KPI_FOR_LOCATION' | translate:'kpi':{location: data.customerName}"
             [from]="data.from"
             [to]="data.to"
             [hideFilter]="true"
             [hideRecalculateButton]="true">
    </eaw-kpi>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">{{'CLOSE' | translate | async}}</button>
</mat-dialog-actions>
