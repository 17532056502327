<eaw-dialog-header>
    <span title>{{'ADD_WIDGET' | translate:'widgets' | async}}</span>
</eaw-dialog-header>

@if (loading()) {
    <mat-dialog-content class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </mat-dialog-content>
} @else {
    <mat-dialog-content>
        <mat-list>
            <div mat-subheader>{{'NORMAL' | translate:'widgets' | async}}</div>
            @for (item of normalWidgets(); track item.info.key) {
                <mat-list-item (click)="choose(item)" matRipple>
                    <mat-icon matListItemIcon>{{item.info.icon}}</mat-icon>
                    <div matListItemTitle [class.no-info]="item.noInfo">{{item.translatedName}}</div>
                    <div matListItemLine>{{item.info.key}}</div>
                </mat-list-item>
            }

            <mat-divider></mat-divider>

            <div mat-subheader>{{'MINI' | translate:'widgets' | async}}</div>
            @for (item of miniWidgets(); track item.info.key) {
                <mat-list-item (click)="choose(item)" matRipple>
                    <mat-icon matListItemIcon>{{item.info.icon}}</mat-icon>
                    <div matListItemTitle [class.no-info]="item.noInfo">{{item.translatedName}}</div>
                    <div matListItemLine>{{item.info.key}}</div>
                </mat-list-item>
            }
        </mat-list>
    </mat-dialog-content>
}

<mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{'CLOSE' | translate | async}}</button>
</mat-dialog-actions>
