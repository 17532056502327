// @ts-nocheck
import { t } from 'i18next';
import { module, noop } from 'angular';

const template1 = `<md-dialog>
    <form role="form" autocomplete="off" name="addEditLocaleForm" ng-submit="ctrl.addUpdate()">
        <eaw-dialog-header-old ng-if="!ctrl.editing" heading="'Add locale'"></eaw-dialog-header-old>
        <eaw-dialog-header-old ng-if="ctrl.editing" heading="'Update locale'"></eaw-dialog-header-old>
        <md-dialog-content>
            <div class="md-dialog-content">
                <md-input-container>
                    <label ng-i18next="NAME"></label>
                    <input ng-model="ctrl.locale.name" ng-minlength="2" ng-maxlength="50" required>
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="CODE"></label>
                    <input ng-model="ctrl.locale.code" ng-minlength="2" ng-maxlength="10" required ng-disabled="ctrl.locale.customers_count">
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button ng-if="!ctrl.editing" type="submit" ng-i18next="ADD" ng-disabled="addEditLocaleForm.$invalid"></md-button>
            <md-button ng-if="ctrl.editing" type="submit" ng-i18next="UPDATE" ng-disabled="addEditLocaleForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.admin').controller('adminLocaleCtrl', [ 'LocaleService', '$mdDialog', 'ToastService', function(LocaleService, $mdDialog, ToastService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.table = {
            columns: [
                {
                    title: t('NAME'),
                    sortable: 'name',
                },
                {
                    title: t('CODE'),
                    sortable: 'code',
                },
                {
                    title: t('AMOUNT'),
                    sortable: 'customers_count',
                    class: 'text-right',
                },
                {
                    title: '',
                    class: 'text-center',
                },
            ],
            cells: [
                { template: 'item.name' },
                { template: 'item.code' },
                {
                    template: 'item.customers_count | eawNumber',
                    class: 'text-right',
                },
                {
                    buttons: [
                        {
                            icon: 'edit',
                            click: ctrl.addEditLocale,
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            invisible: (locale) => locale.customers_count > 0,
                            click(locale) {
                                $mdDialog.show(
                                    $mdDialog.confirm()
                                        .theme('delete')
                                        .title('Delete locale')
                                        .textContent(`Delete the locale ${locale.name}?`)
                                        .ok(t('DELETE'))
                                        .cancel(t('CANCEL')),
                                ).then(() => {
                                    ctrl.delete(locale);
                                });
                            },
                        },
                    ],
                },
            ],
        };
    };

    ctrl.addEditLocale = (locale) => {
        $mdDialog.show({
            template: template1,
            controllerAs: 'ctrl',
            controller: function() {
                const dctrl = this;

                dctrl.$onInit = () => {
                    dctrl.cancel = $mdDialog.cancel;
                    dctrl.editing = !!locale;
                    dctrl.origLocale = { ...locale };
                    dctrl.locale = { ...locale };
                };

                dctrl.addUpdate = () => {
                    $mdDialog.hide();

                    if (dctrl.editing) {
                        ctrl.update(dctrl.locale, dctrl.origLocale);
                    } else {
                        ctrl.add(dctrl.locale);
                    }
                };
            },
        });
    };

    ctrl.add = (locale) => {
        LocaleService.create({
            code: locale.code,
            name: locale.name,
        }).$promise.then((data) => {
            ToastService.toast(`${data.name} added!`);
            ctrl.reloadTable = true;
        }, noop);
    };

    ctrl.update = (locale, origLocale) => {
        if (locale.code !== origLocale.code) {
            ctrl.delete(origLocale).then(() => {
                ctrl.add(locale);
            });
        } else {
            LocaleService.updateByCode(locale.code, locale).$promise.then(() => {
                ToastService.toast(`Locale updated!`);
                ctrl.reloadTable = true;
            }, noop);
        }
    };

    ctrl.delete = (locale) => LocaleService.deleteLocale(locale).$promise.then(() => {
        ToastService.toast('Locale deleted');
        ctrl.reloadTable = true;
    });
} ]);
