import { Component, Directive, ElementRef, Inject, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { UserOld } from '../../../shared/angularjs/user-old';
import CustomerOld from '../../../shared/angularjs/customer-old';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'user-groups-upgraded',
    standalone: true,
})
export class userGroupsUpgradedDirective extends UpgradeComponent {
    @Input({ required: true }) customer!: CustomerOld;
    @Input({ required: true }) user!: UserOld;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('userGroups', elementRef, injector);
    }
}

@Component({
    selector: 'eaw-user-groups-upgraded',
    template: '<user-groups-upgraded [customer]="customer" [user]="user"></user-groups-upgraded>',
    standalone: true,
    imports: [ userGroupsUpgradedDirective ],
})
export class userGroupsUpgradedComponent {
    @Input({ required: true }) customer!: CustomerOld;
    @Input({ required: true }) user!: UserOld;
}
