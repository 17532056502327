@if (loadingInitialData()) {
    <div id="fetching-container">
        <span class="mat-headline-3">{{ 'FETCHING_WIDGETS' | translate:'widgets' | async }}</span>
        <mat-icon eawSize="120px">widgets</mat-icon>
    </div>
} @else {
    <div id="header">
        @if (!isProduction) {
            <button mat-raised-button (click)="removeSetupCompleteProperty()">
                <span>Remove setup complete property</span>
            </button>

            <button mat-raised-button (click)="deleteAllWidgets()">
                <span>Delete all widgets</span>
            </button>
        }

        <button mat-icon-button (click)="sortWidgets()">
            <mat-icon>sort</mat-icon>
        </button>

        <button mat-icon-button (click)="openAddWidgetDialog()">
            <mat-icon>add</mat-icon>
        </button>

        <button mat-icon-button (click)="openSettings()">
            <mat-icon>settings</mat-icon>
        </button>
    </div>

    <div id="mini-grid">
        @for (widget of miniWidgets(); track widget.info.key) {
            <eaw-widget-host
                    [widget]="widget"
                    [defaultColor]="defaultWidgetColor()"
                    [defaultWidgets]="defaultWidgets()"
                    (deleted)="onDeleted(widget)"/>
        }
    </div>

    <div id="normal-grid">
        @for (widget of normalWidgets(); track widget.info.key) {
            <eaw-widget-host
                    [widget]="widget"
                    [defaultColor]="defaultWidgetColor()"
                    [defaultWidgets]="defaultWidgets()"
                    (deleted)="onDeleted(widget)"/>
        }
    </div>
}
