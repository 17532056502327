// @ts-nocheck
import { t } from 'i18next';
import { module, noop } from 'angular';

const template1 = `<div layout="row" layout-padding layout-wrap>
    <div layout="column" flex-gt-lg="25" flex-gt-md="40" flex-gt-sm="50" flex="100">
        <div class="dash-widget-item" md-colors="{background: 'eaw-primary'}">
            <div class="dash-widget-header">
                <div class="tw-p-20">
                    <div map-lazy-load="https://maps.google.com/maps/api/js?key=AIzaSyCD7QxCvJ8JzXoex_RwZ98WqTZmv1aRBmk" map-lazy-load-params="{{stackCtrl.googleMapsUrl}}">
                        <ng-map center="47.086104, 1.351141"
                                zoom="4"
                                disable-default-u-i="true"
                                draggable="false"
                                disable-double-click-zoom="true">
                        </ng-map>
                    </div>
                </div>
            </div>

            <div class="tw-p-20">
                <small ng-i18next="REGION"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.region"></h3>
                <br>

                <small ng-i18next="NAME"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.name"></h3>
                <br>

                <small ng-i18next="admin:CUSTOMER_plural"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.customers_count | eawNumber"></h3>
                <br>

                <small ng-i18next="CREATED"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.created_at | moment"></h3>
                <br>

                <small ng-i18next="UPDATED"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.updated_at | moment"></h3>
                <br>

                <small ng-i18next="PROVISIONED"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.provisioned | moment"></h3>

                <div class="p-t-25" layout="row" layout-align="space-between center">
                    <md-button class="md-raised" ng-click="stackCtrl.edit()" ng-i18next="EDIT"></md-button>
                    <md-button class="md-raised" ng-i18next="PROVISION"></md-button>
                    <md-button class="md-raised md-warn" ng-hide="stackCtrl.stack.customers_count" ng-click="stackCtrl.deleteStack()" ng-i18next="DELETE"></md-button>
                </div>
            </div>
        </div>
    </div>

    <div layout="column" flex-gt-lg="75" flex-gt-md="60" flex-gt-xs="50" flex="100">
        <md-tabs md-dynamic-height>
            <md-tab label="{{'CUSTOMER_plural' | i18next}}">
                <md-card>
                    <md-card-header>
                        <md-card-header-text>
                            <span class="md-title" bo-i18next="CUSTOMER_plural"></span>
                        </md-card-header-text>
                    </md-card-header>
                    <md-card-content>
                        <eaw-dynamic-ng-table
                                columns="stackCtrl.customersTable.columns"
                                cells="stackCtrl.customersTable.cells"
                                go-to="eaw/app/admin/customers/view/info|id=id"
                                factory="StackService"
                                factory-function-arguments="{
                                    id: stackCtrl.stack.id,
                                    subQuery: 'customers'
                                }"
                                factory-function="getCustomersByIdPaginated"
                                reload-table="stackCtrl.reloadCustomersTable">
                        </eaw-dynamic-ng-table>
                    </md-card-content>
                </md-card>
            </md-tab>

            <md-tab label="{{'PROPERTY_plural' | i18next}}">
                <md-card>
                    <md-card-header>
                        <md-card-header-text>
                            <span class="md-title" bo-i18next="PROPERTY_plural"></span>
                        </md-card-header-text>

                        <card-btn-float on-click="stackCtrl.openNewPropertyDialog()"></card-btn-float>
                    </md-card-header>
                    <md-card-content>
                        <eaw-dynamic-ng-table
                                columns="stackCtrl.propertiesTable.columns"
                                cells="stackCtrl.propertiesTable.cells"
                                factory="StackService"
                                factory-function-arguments="{
                                    id: stackCtrl.stack.id,
                                    subQuery: 'properties'
                                }"
                                factory-function="getProperties"
                                reload-table="stackCtrl.reloadPropertiesTable">
                        </eaw-dynamic-ng-table>
                    </md-card-content>
                </md-card>
            </md-tab>
        </md-tabs>
    </div>
</div>
`;

module('eaw.admin').controller('adminStacksIdCtrl', [ '$state', 'StackService', 'ToastService', '$mdDialog', 'stack', 'clipboardService', function($state, StackService, ToastService, $mdDialog, stack, clipboardService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.stack = stack;

        ctrl.customersTable = {
            columns: [
                {
                    title: t('ID'),
                    sortable: 'id',
                },
                {
                    title: t('NAME'),
                    sortable: 'name',
                },
                {
                    title: t('NUMBER'),
                    sortable: 'number',
                    class: 'text-right',
                },
                {
                    title: t('COUNTRY'),
                    sortable: 'country',
                },
                {
                    title: t('CITY'),
                    sortable: 'city',
                },
            ],
            cells: [
                { template: 'item.id' },
                { template: 'item.name' },
                {
                    template: 'item.number',
                    class: 'text-right',
                },
                { template: 'item.country' },
                { template: 'item.city' },
            ],
        };

        ctrl.propertiesTable = {
            columns: [
                { title: '' },
                {
                    title: t('ID'),
                    sortable: 'id',
                },
                {
                    title: t('KEY'),
                    sortable: 'key',
                },
                {
                    title: t('VALUE'),
                    sortable: 'value',
                },
            ],
            cells: [
                {
                    buttons: [
                        {
                            icon: 'content-copy',
                            click(property) {
                                clipboardService.copy(property.value);
                            },
                        },
                    ],
                },
                { template: 'item.id' },
                { template: 'item.key' },
                { template: 'item.value' },
            ],
        };
    };

    ctrl.openNewPropertyDialog = () => {
        $mdDialog.show({
            template: template1,
            controllerAs: 'ctrl',
            controller: function() {
                const dctrl = this;

                dctrl.$onInit = () => {
                    dctrl.prop = {};
                };

                dctrl.saveProperty = () => {
                    $mdDialog.hide();

                    StackService.addProperty(ctrl.stack.id, dctrl.prop).$promise.then((data) => {
                        ToastService.toast(`${data.key} added!`);
                        ctrl.reloadPropertiesTable = true;
                    }, noop);
                };
            },
        });
    };

    ctrl.edit = () => {
        $mdDialog.show(
            $mdDialog.prompt()
                .title(t('EDIT'))
                .initialValue(ctrl.stack.name)
                .required(true)
                .ok(t('UPDATE'))
                .cancel(t('CANCEL')),
        ).then((name) => {
            StackService.updateById({
                ...ctrl.stack,
                name,
            }).$promise.then((data) => {
                ctrl.stack = data;
            });
        });
    };

    ctrl.goToCustomer = (id) => $state.transitionTo(`eaw/app/admin/customers/view`, { id });

    ctrl.deleteStack = () => {
        $mdDialog.show(
            $mdDialog.confirm()
                .title('Delete stack')
                .textContent(`Delete the stack ${ctrl.stack.name}`)
                .ok(t('DELETE'))
                .cancel(t('CANCEL'))
                .theme('delete'),
        ).then(() => {
            StackService.deleteById(ctrl.stack.id).$promise.then(() => {
                $state.transitionTo(`eaw/app/admin/stacks/list`);

                $mdDialog.show(
                    $mdDialog.alert()
                        .title('Stack deleted')
                        .ok(t('OK')),
                );
            });
        });
    };
} ]);
