import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { HrDefaultFile, HrDefaultFileResponse } from '../models/hr-default-file';
import { Observable } from 'rxjs';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { HttpClient, HttpContext } from '@angular/common/http';
import { FileUploadService } from '../../shared/services/file-upload.service';
import { FileDownloadService } from '../../shared/services/file-download.service';
import { FormField, FormFieldResponse, IFormField } from '../models/form-field';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { IGNORE_ERROR } from '../../shared/http/http-contexts';

export interface HrDefaultFileSubmitData {
    filetypeId: number;
    name: string;
    description?: string;
}

@Injectable({
    providedIn: 'root',
})
export class DefaultHrFileService {

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(FileDownloadService) private fileDownload: FileDownloadService,
        @Inject(FileUploadService) private fileUpload: FileUploadService,
    ) { }

    getAll(customerId: number, options?: PaginationOptions, filetypeFilter?: number[]): Observable<ArrayPaginatedResponse<HrDefaultFile>> {
        return this.http.get<ArrayPaginatedResponse<HrDefaultFileResponse>>(`/customers/${customerId}/default_hr_files`, {
            params: formatHttpParams({
                ...options,
                'file_type_ids[]': filetypeFilter,
                'count[]': [ 'formFields' ],
            }),
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(classifyArrayPaginatedResponse(HrDefaultFile));
    }

    get(customerId: number, fileId: number, withArgs: string[] = []): Observable<HrDefaultFile> {
        return this.http.get<HrDefaultFileResponse>(`/customers/${customerId}/default_hr_files/${fileId}`,
            { params: { 'with[]': withArgs } },
        ).pipe(classifyItem(HrDefaultFile));
    }

    download(customerId: number, fileId: number, fileName?: string) {
        return this.fileDownload.download(`/customers/${customerId}/default_hr_files/${fileId}/download`, fileName);
    }

    create(customerId: number, data: HrDefaultFileSubmitData, file: File): Observable<HrDefaultFile> {
        return this.fileUpload.upload<HrDefaultFileResponse>(`/customers/${customerId}/default_hr_files`, {
            name: data.name,
            file_type_id: data.filetypeId,
            description: data.description,
            attachment: file,
        }).pipe(classifyItem(HrDefaultFile));
    }

    update(customerId: number, fileId: number, data: HrDefaultFileSubmitData): Observable<HrDefaultFile> {
        return this.http.put<HrDefaultFileResponse>(`/customers/${customerId}/default_hr_files/${fileId}`, {
            name: data.name,
            file_type_id: data.filetypeId,
            description: data.description,
        }).pipe(classifyItem(HrDefaultFile));
    }

    delete(customerId: number, fileId: number): Observable<undefined> {
        return this.http.delete<undefined>(`/customers/${customerId}/default_hr_files/${fileId}`);
    }

    // Get attachments assigned to the default files
    getAttachments(customerId: number, fileId: number, options?: PaginationOptions): Observable<ArrayPaginatedResponse<any>> {
        return this.http.get<ArrayPaginatedResponse<any>>(`/customers/${customerId}/default_hr_files/${fileId}/attachments`, {
            params: { ...options },
        });
    }

    addAttachment() {
        // TODO
    }

    deleteAttachment() {
        // TODO
    }

    updateAttachment() {
        // TODO
    }

    getFormFields(customerId: number, fileId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<FormFieldResponse>>(`customers/${customerId}/default_hr_files/${fileId}/form_fields`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(FormField));
    }

    setFormFields(customerId: number, fileId: number, fields: IFormField[]) {
        return this.http.put<FormFieldResponse[]>(`customers/${customerId}/default_hr_files/${fileId}/form_fields`, { form_fields: fields });
    }
}
