// @ts-nocheck
import { module } from 'angular';
module('eaw.admin').factory('PayTypeFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const factory = this;
    const resource = EawResource.create('/pay_types/:type_id');
    factory.getAll = (args) => resource.get({
        ...Pagination.getParams(args),
    });
    factory.get = (id) => resource.get({ type_id: id });
    factory.create = (data) => resource.save({}, {
        name: data.name,
        id: data.id,
    });
    factory.delete = (payType) => resource.delete({
        type_id: payType.id,
    });
    return factory;
} ]);
