import { Inject, Injectable } from '@angular/core';
import { CurrentService } from './current.service';

export type FormatUnit = 'acre' | 'bit' | 'byte' | 'celsius' | 'centimeter' | 'day' | 'degree' | 'fahrenheit' | 'fluid-ounce' | 'foot' | 'gallon' | 'gigabit' | 'gigabyte' | 'gram' | 'hectare' | 'hour' | 'inch' | 'kilobit' | 'kilobyte' | 'kilogram' | 'kilometer' | 'liter' | 'megabit' | 'megabyte' | 'meter' | 'mile' | 'mile-scandinavian' | 'milliliter' | 'millimeter' | 'millisecond' | 'minute' | 'month' | 'ounce' | 'percent' | 'petabyte' | 'pound' | 'second' | 'stone' | 'terabit' | 'terabyte' | 'week' | 'yard' | 'year'

@Injectable({
    providedIn: 'root',
})
export class NumberFormatterService {
    private formatters: Map<string, Intl.NumberFormat> = new Map();

    constructor(
        @Inject(CurrentService) private current: CurrentService,
    ) {
    }

    formatDecimal(value: unknown, decimals = 2, language = this.current.languageTag, options: Intl.NumberFormatOptions = {}) {
        return this.format(language, value, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
            ...options,
        });
    }

    formatInteger(value: unknown, language = this.current.languageTag, options: Intl.NumberFormatOptions = {}) {
        return this.format(language, value, {
            maximumFractionDigits: 0,
            ...options,
        });
    }

    formatUnit(value: unknown, unit: FormatUnit, options: Intl.NumberFormatOptions = {}, language = this.current.languageTag) {
        return this.format(language, value, {
            style: 'unit',
            unit,
            ...options,
        });
    }

    formatCurrency(value: unknown, language = this.current.languageTag, options: Intl.NumberFormatOptions = {}) {
        const currency = options.currency || this.current.getCustomer().currency;
        if (!currency) {
            return this.formatDecimal(value, 2, language, options);
        }

        return this.format(language, value, {
            style: 'currency',
            currency,
            currencyDisplay: 'narrowSymbol',
            ...options,
        });
    }

    format(language: string, value: unknown, options: Intl.NumberFormatOptions = {}): string {
        const number = parseFloat(String(value));
        if (!Number.isFinite(number)) {
            return '';
        }

        const stringOptions = JSON.stringify(options);
        const formatter = this.formatters.get(stringOptions) || new Intl.NumberFormat(language, options);
        this.formatters.set(stringOptions, formatter);

        try {
            return formatter.format(number);
        } catch (e) {
            console.error(e);
            return '';
        }
    }
}
