import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtendedWidgetInfo } from '../../../classes/extended-widget-info';
import { AlertsService, GetAlertsOptions } from '../../../../shared/http/alerts.service';
import { CustomerEmployeesGetAllOptions, EmployeeService } from '../../../../shared/http/employee.service';
import { CurrentService } from '../../../../shared/services/current.service';
import { forkJoin } from 'rxjs';
import { expandAllPages } from '../../../../shared/utils/rxjs/expand-all-pages';
import { Alert } from '../../../../shared/models/alert';
import { Employee } from '../../../../shared/models/employee';
import { Widget } from '../../../classes/widget';
import { MatListModule } from '@angular/material/list';
import { DateTime } from 'luxon';
import { DateTimePipe } from '../../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { UIRouter } from '@uirouter/core';
import { MaterialColorDirective } from '../../../../shared/directives/material-color.directive';
import { MatSelectModule } from '@angular/material/select';
import { uniqBy } from 'lodash-es';
import { InfoLoadingComponent } from '../../../../shared/components/info-loading/info-loading.component';
import { TranslateSyncPipe } from '../../../../shared/pipes/translate-sync.pipe';

type Worker = { employee: Pick<Employee, 'id' | 'name'>, alerts: Alert[] };
type QualifiedWorker = Worker & { hasNightWorkStatus: false };
type NightWorker = Worker & { from: DateTime | null, to: DateTime | null, hasNightWorkStatus: true };

@Component({
    selector: 'eaw-night-worker-widget-extended-info',
    standalone: true,
    imports: [
        CommonModule,
        MatListModule,
        DateTimePipe,
        TranslatePipe,
        MatExpansionModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MaterialColorDirective,
        MatSelectModule,
        InfoLoadingComponent,
        TranslateSyncPipe,
    ],
    templateUrl: './night-worker-widget-extended-info.component.html',
    styleUrl: './night-worker-widget-extended-info.component.scss',
})
export class NightWorkerWidgetExtendedInfoComponent extends ExtendedWidgetInfo implements OnInit {
    alertsService = inject(AlertsService);
    employeeService = inject(EmployeeService);
    current = inject(CurrentService);
    snackBar = inject(SnackBarService);
    router = inject(UIRouter);

    @Input() widget?: Widget;
    // For ajs container
    @Output() closing = new EventEmitter();

    displayWorkers: (QualifiedWorker | NightWorker)[] = [];
    nightWorkers: NightWorker[] = [];
    qualifiedWorkers: QualifiedWorker[] = [];

    deleting: Record<number, boolean> = {};

    ngOnInit() {
        this.setLoading(true);
        forkJoin([
            expandAllPages<Employee, CustomerEmployeesGetAllOptions>(
                (pagination) => this.employeeService.getAll(this.current.getCustomer().id, pagination),
                { 'properties[]': { cf_night_worker: '1' } },
            ),
            expandAllPages<Alert, GetAlertsOptions>((pagination) => this.alertsService.getAlerts(this.current.getCustomer().id, pagination), { category: 'night_worker' }),
        ]).subscribe(([ employees, triggerAlerts ]) => {
            this.nightWorkers = employees.filter((employee) => employee.getCustomFieldValue('cf_night_worker'))
                .map((employee) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const cf = employee.attachedCustomFields.find((cf) => cf.key === 'cf_night_worker')!;
                    const alerts = triggerAlerts.filter((alert) => alert.subject_id === employee.id);
                    return { employee, from: cf.from, to: cf.to, alerts, hasNightWorkStatus: true };
                });

            this.qualifiedWorkers = uniqBy(triggerAlerts, 'subject_id').filter((alert) => !this.nightWorkers.some((worker) => alert.subject_id === worker.employee.id))
                .map((alertObj) => {
                    const alerts = triggerAlerts.filter((alert) => alert.subject_id === alertObj.subject_id);
                    return { employee: { id: alertObj.subject_id, name: alertObj.specifics?.employee_name || '' }, alerts, hasNightWorkStatus: false };
                });

            this.displayWorkers = [ ...this.qualifiedWorkers, ...this.nightWorkers ];

            this.setLoading(false);
        });
    }

    setFilter(filter: 'qualified' | 'night-workers' | 'all') {
        switch (filter) {
            case 'qualified':
                this.displayWorkers = [ ...this.qualifiedWorkers ];
                break;
            case 'night-workers':
                this.displayWorkers = [ ...this.nightWorkers ];
                break;
            case 'all':
            default:
                this.displayWorkers = [ ...this.qualifiedWorkers, ...this.nightWorkers ];
                break;
        }
    }

    goToEmployee(employee: Employee | Pick<Employee, 'id' | 'name'>) {
        this.router.stateService.go('eaw/app/company/employees/view/custom_fields', { emp: employee.id }).then(() => {
            this.closing.emit();
        });
    }

    deleteAlert(alert: Alert, alerts: Alert[], index: number) {
        this.deleting[alert.id] = true;
        this.alertsService.deleteAlert(this.current.getCustomer().id, alert.id).subscribe(() => {
            alerts.splice(index, 1);
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete this.deleting[alert.id];
            this.snackBar.deleted();
        });
    }
}
