import { ChangeDetectionStrategy, Component, effect, ElementRef, inject, Input, OnInit, signal, viewChild, WritableSignal } from '@angular/core';
import { NewsletterService } from '../../http/newsletter.service';
import { Newsletter } from '../../models/newsletter';
import { NewsletterPreviewComponent } from '../../components/newsletter-preview/newsletter-preview.component';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { NewsletterIssueService } from '../../http/newsletter-issue.service';
import { MatFabButton, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NewsletterIssue } from '../../models/newsletter-issue';
import { CurrentService } from '../../../shared/services/current.service';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { UIRouter } from '@uirouter/core';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { ReadNewsletterIssueService } from '../../services/read-newsletter-issue.service';
import { CdkObserveContent } from '@angular/cdk/observers';

interface NewsletterItem {
    newsletter: Newsletter;
    issue: NewsletterIssue;
    downloading: WritableSignal<boolean>;
    issuesHref: WritableSignal<string>;
}

@Component({
    selector: 'eaw-newsletters',
    standalone: true,
    imports: [
        NewsletterPreviewComponent,
        MatCard,
        MatCardHeader,
        MatCardContent,
        MatCardTitle,
        MatCardSubtitle,
        MatMiniFabButton,
        MatIcon,
        MatFabButton,
        MatMenu,
        MatMenuContent,
        MatMenuItem,
        MatIconButton,
        MatMenuTrigger,
        TranslatePipe,
        AsyncPipe,
        InfoLoadingComponent,
        CdkObserveContent,
    ],
    templateUrl: './newsletters.component.html',
    styleUrl: './newsletters.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewslettersComponent implements OnInit {
    private newslettersService = inject(NewsletterService);
    private newsletterIssueService = inject(NewsletterIssueService);
    private readNewsletterIssueService = inject(ReadNewsletterIssueService);
    private currentService = inject(CurrentService);
    private uiRouter = inject(UIRouter);

    newsletterContainer = viewChild<ElementRef<HTMLDivElement>>('newsletterContainer');

    @Input({ required: true }) customerId!: number;

    private readonly newsletterGap = 100;
    protected showButtons = signal(false);
    protected language: string;
    protected loading = signal(true);
    protected newsletterItems: WritableSignal<NewsletterItem[]> = signal([]);

    constructor() {
        this.language = this.currentService.languageTag;

        effect(() => {
            this.newsletterContainer()?.nativeElement.style.setProperty('--newsletter-gap', `${this.newsletterGap}px`);
        });
    }

    ngOnInit() {
        this.getNewsletters();
    }

    newsletterContentChange(container: HTMLDivElement) {
        const containerWidth = container.getBoundingClientRect().width;
        const scrollWidth = container.scrollWidth;

        this.showButtons.set(scrollWidth > containerWidth);
    }

    getNewsletters() {
        this.newslettersService.getAll(this.customerId, {
            has_issues: true,
            only_latest: true,
            only_published: true,
        }).subscribe((newsletters) => {
            for (const newsletter of newsletters.data) {
                this.newsletterItems.update((items) => {
                    const recentIssue = newsletter.getMostRecentIssue();
                    if (!recentIssue) {
                        return items;
                    }

                    return items.concat({
                        newsletter,
                        issue: recentIssue,
                        downloading: signal(false),
                        issuesHref: signal(this.uiRouter.stateService.href('eaw/app/newsletters/issues', { newsletter_id: newsletter.id })),
                    });
                });
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.newsletterItems.update((items) => items.sort((a, b) => b.issue.publishDate!.toMillis() - a.issue.publishDate!.toMillis()));
            this.loading.set(false);
        });
    }

    downloadIssue(item: NewsletterItem) {
        item.downloading.set(true);
        this.newsletterIssueService.download(this.customerId, item.newsletter.id, item.issue.id, item.newsletter.getLanguageCode(this.language)).subscribe(() => {
            item.downloading.set(false);
        });
    }

    openIssue(item: NewsletterItem) {
        return this.readNewsletterIssueService.open(this.customerId, item.newsletter.id, item.issue.id, item.newsletter.getLanguageCode(this.language));
    }

    scroll(direction: 'back' | 'forward') {
        this.newsletterContainer()?.nativeElement.scrollBy({
            left: direction === 'back' ? -this.newsletterGap : this.newsletterGap,
            behavior: 'smooth',
        });
    }
}
