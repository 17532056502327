<!-- Filter card -->
<mat-card *ngIf="showFilter">
    <mat-card-content>
        <!-- Search filter -->
        <mat-form-field class="tw-w-full">
            <mat-label>{{'FILTER' | translate | async}}</mat-label>
            <input matInput type="search" [formControl]="filter">
        </mat-form-field>

        <div class="tw-flex tw-flex-wrap tw-gap-24 tw-items-center">
            <!-- Active filter -->
            <mat-radio-group [formControl]="showActive">
                <mat-radio-button [value]="null">{{'SHOW_ALL' | translate | async}}</mat-radio-button>
                <mat-radio-button value="active">{{'ACTIVE' | translate | async}}</mat-radio-button>
                <mat-radio-button value="inactive">{{'INACTIVE' | translate | async}}</mat-radio-button>
            </mat-radio-group>

            <!-- Group by product toggle -->
            <mat-checkbox eawCheckboxHelper [formControl]="groupByProduct">
                {{'GROUP_BY' | translate | async}} {{'PRODUCT' | translate: 'admin' | async | lowercase}}
            </mat-checkbox>
        </div>
    </mat-card-content>
</mat-card>

<!-- Observers card -->
<mat-card class="tw-h-full mat-compact-card tw-overflow-auto">
    <mat-card-header class="observers-header">
        <mat-card-title>{{'OBSERVER_plural' | translate: 'admin' | async}}</mat-card-title>
        <mat-card-subtitle *ngIf="allObservers.length">
            {{'OF_TOTAL_ENABLED' | translate: 'admin': {count: activeObservers, total: allObservers.length} | async}}
        </mat-card-subtitle>

        <!-- Toggle filter button -->
        <button mat-icon-button (click)="showFilter = !showFilter" [ngSwitch]="showFilter">
            <mat-icon *ngSwitchCase="true" color="accent">filter_alt</mat-icon>
            <mat-icon *ngSwitchCase="false" color="primary">filter_alt_off</mat-icon>
        </button>
    </mat-card-header>

    <mat-progress-spinner mode="indeterminate" *ngIf="!allObservers.length"></mat-progress-spinner>

    <mat-card-content class="observer-list">
        <div class="observer-group" *ngFor="let group of groupedObservers | keyvalue; trackBy: trackByKey">
            <!-- Product header -->
            <eaw-subheader *ngIf="groupByProduct.value">{{group.key}}</eaw-subheader>

            <!-- Observer item -->
            <div class="observer" *ngFor="let observer of group.value; trackBy: trackByName">
                <mat-slide-toggle [disabled]="observer.loading" [checked]="!!getObserverFrom(observer)" (change)="toggleObserver(observer, $event.checked)"></mat-slide-toggle>

                <div class="info tw-flex tw-flex-col">
                    <h3 class="tw-pb-4">{{observer.name}}</h3>
                    <h4>{{'PRODUCT' | translate: 'admin' | async}}: <i>{{observer.product}}</i></h4>
                    <h4 *ngIf="getObserverFrom(observer)">
                        <span>{{'FROM' | translate | async}}: {{getObserverFrom(observer) | DateTime: 'DATETIME_MED'}}</span>
                        <span *ngIf="getObserverTo(observer)"> &nbsp;-&nbsp; {{'TO' | translate | async}}: {{getObserverTo(observer) | DateTime: 'DATETIME_MED'}}</span>
                    </h4>

                    <p class="tw-pt-4">{{observer.description}}</p>
                    <p>{{'CLASS' | translate: 'admin' | async}}: <i>{{observer.class}}</i></p>
                </div>

                <!-- To date options -->
                <div class="to-options">
                    <button mat-icon-button
                            [matTooltip]="('SET_TO_DATE' | translate: 'admin' | async) || ''"
                            color="primary"
                            (click)="setToTime(observer, getObserverTo(observer))"
                            [disabled]="observer.loading">
                        <mat-icon>event</mat-icon>
                    </button>

                    <button mat-icon-button
                            [matTooltip]="('REMOVE_TO_DATE' | translate: 'admin' | async) || ''"
                            color="primary"
                            [ngClass]="getObserverTo(observer) ? 'tw-visible' : 'tw-invisible'"
                            (click)="clearToTime(observer)"
                            [disabled]="!getObserverTo(observer) || observer.loading">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
