import { ApiResponse } from '../../shared/interfaces/api-response';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ReferenceDay, ReferenceDayResponse } from './reference-day';
import { Projection, ProjectionResponse } from './projection';

export interface ProjectionDayDetailsResponse extends ApiResponse {
    date: string,
    referenceDays?: ReferenceDayResponse[] | null,
    projections: {
        'sales.net': ProjectionResponse[],
        transactions: ProjectionResponse[],
        transactionsEdited: ProjectionResponse[],
    },
    actualData: {
        'sales.net': ProjectionResponse[],
        transactions: ProjectionResponse[],
    },
}

export interface ProjectionsTransposed {
    time: DateTime,
    blocked: boolean,
    sales: {
        value: number,
        percent: number,
    },
    transactions: {
        value: number,
        percent: number,
    },
    atGenerated?: {
        value: number,
        percent: number,
    },
    realSales: {
        value: number,
    },
    realTransactions: {
        value: number,
    }
}

export class ProjectionDayDetails {
    date: DateTime;
    chosenReferenceDays: ReferenceDay[];
    projections: {
        transactions: Projection[],
        atGenerated?: Projection[],
        sales: Projection[],
        transactionsEdited?: Projection[],
    };

    actualData: {
        transactions: Projection[],
        sales: Projection[],
    };

    projectionsTransposed: ProjectionsTransposed[];

    constructor(data: ProjectionDayDetailsResponse) {
        this.date = stringToDateTime(data.date, true, 'yyyy-MM-dd');
        this.chosenReferenceDays = data.referenceDays ? data.referenceDays.map((day) => new ReferenceDay(day)) : [];

        this.projections = {
            sales: data.projections['sales.net'].map((projection) => new Projection(projection)),
            transactions: data.projections.transactions.map((projection) => new Projection(projection)),
        };
        this.actualData = {
            sales: data.actualData['sales.net'].map((projection) => new Projection(projection)),
            transactions: data.actualData.transactions.map((projection) => new Projection(projection)),
        };

        this.projectionsTransposed = this.projections.sales.map((sale) => {
            const saleTime = sale.time.toFormat('HH:mm');
            const transaction = this.projections.transactions.find((transaction) => transaction.time.toFormat('HH:mm') === saleTime);
            const realSale = this.actualData.sales.find((realSale) => realSale.time.toFormat('HH:mm') === saleTime);
            const realTransaction = this.actualData.transactions.find((realTransaction) => realTransaction.time.toFormat('HH:mm') === saleTime);
            const resp: ProjectionsTransposed = {
                blocked: false,
                time: sale.time,
                sales: { value: sale.value, percent: sale.percent },
                transactions: { value: 0, percent: 0 },
                realSales: { value: 0 },
                realTransactions: { value: 0 },
            };
            if (transaction) {
                resp.transactions = { value: transaction.value, percent: transaction.percent };
            }
            if (realSale) {
                resp.realSales = { value: realSale.value };
            }
            if (realTransaction) {
                resp.realTransactions = { value: realTransaction.value };
            }
            return resp;
        });
    }
}
