// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { TinyColor } from '@ctrl/tinycolor';
import { CurrentOld } from '../shared/angularjs/current.factory';
module('eaw.calendar2').service('Calendar2Event', [ 'EawResource', 'Calendar', 'CalendarEventParticipant', '$rootScope', 'colorpickerService', function CalendarEventClass(EawResource, Calendar, CalendarEventParticipant, $rootScope, colorpickerService) {
    class Calendar2Event {
        key;
        calendarKey;
        title;
        description;
        wholeDay;
        start;
        end;
        type;
        organizer;
        calendar;
        color;
        textColor;
        participants;
        participant;
        isMine;
        eventDays;
        inherited;
        isOld;
        access;
        constructor(event, calendar) {
            this.key = event.key;
            this.calendarKey = event.calendar_key;
            this.title = event.name;
            this.description = event.description;
            this.wholeDay = event.whole_day || false;
            this.start = event.start;
            this.end = event.end;
            this.type = event.type;
            this.organizer = event.organizer;
            this.calendar = calendar;
            this.color = colorpickerService.getHex(event.color || event.calendar?.color);
            this.textColor = new TinyColor(this.color).isDark() ? 'white' : 'black';
            this.participants = event.participants?.map((p) => p instanceof CalendarEventParticipant ? p : new CalendarEventParticipant(p)) || [];
            this.participant = this.participants.find((p) => p.email === calendar.email);
            this.isMine = this.participant?.email === CurrentOld.user.email;
            // Make start / end start of day so that they match when doing diff
            this.eventDays = this.wholeDay ? this.end.diff(this.start, 'd') + 1 : 0;
            this.inherited = this.calendar?.key !== event.calendar_key;
            this.isOld = moment().isAfter(this.end);
            this.setAccess(calendar);
        }

        setAccess(calendar) {
            this.access = {};
            if (calendar?.key != this.calendarKey) {
                return;
            }
            Object.assign(this.access, calendar.access);
        }

        clone() {
            return new Calendar2Event({
                ...this,
                name: this.title,
                whole_day: this.wholeDay,
                start: this.start?.clone?.(),
                end: this.end?.clone?.(),
                participants: this.participants.slice(0),
                calendar_key: this.calendarKey,
            }, this.calendar);
        }

        /**
         * Returns duration in seconds
         * @return {number}
         */
        get duration() {
            return this.end.diff(this.start, 's');
        }

        /**
         * Returns duration in number of hours
         * @return {number}
         */
        get hours() {
            return this.duration / 3600;
        }

        get fromToText() {
            return `${this.start.format('LT')} - ${this.end.format('LT')}`;
        }

        /**
         * @returns {Promise<Calendar2Event>}
         */
        create() {
            return Calendar2Event.create({
                customerId: this.calendar.customer,
                calendarKey: this.calendarKey,
                ...this,
            }).then((event) => {
                Calendar2Event.broadcastEventCreated(event);
                return event;
            });
        }

        /**
         * @returns {Promise<Calendar2Event>}
         */
        update() {
            return Calendar2Event.update({
                customerId: this.calendar.customer,
                calendarKey: this.calendarKey,
                eventKey: this.key,
                ...this,
            }).then((event) => {
                event.participants = this.participants; // Keep the participants
                Calendar2Event.broadcastEventUpdated(event);
                return event;
            });
        }

        delete() {
            if (!this.access.edit) {
                return Promise.reject();
            }
            return EawResource.create('/customers/:customer/calendars/:calendar/events/:event').delete({
                customer: this.calendar.customer,
                calendar: this.calendar.key,
                event: this.key,
            }).$promise.then(() => {
                Calendar2Event.broadcastEventDeleted(this);
            });
        }

        broadcastUpdated() {
            $rootScope.$broadcast('calendar:event:updated', this);
        }

        static broadcastEventUpdated(event) {
            $rootScope.$broadcast('calendar:event:updated', event);
        }

        static onEventUpdated(scope, callback) {
            scope.$on('calendar:event:updated', callback);
        }

        static broadcastEventDeleted(event) {
            $rootScope.$broadcast('calendar:event:deleted', event);
        }

        static onEventDeleted(scope, callback) {
            scope.$on('calendar:event:deleted', callback);
        }

        static broadcastEventCreated(event) {
            $rootScope.$broadcast('calendar:event:created', event);
        }

        static onEventCreated(scope, callback) {
            scope.$on('calendar:event:created', callback);
        }

        static broadcastEventsChanged() {
            $rootScope.$broadcast('calendar:events:changed');
        }

        static onEventsChanged(scope, callback) {
            scope.$on('calendar:events:changed', callback);
        }

        /**
         * @param {moment} date
         * @param {moment} [args]
         * @param {moment} [args.startTime]
         * @param {moment} [args.endTime]
         * @param {Calendar} [args.calendar]
         * @param {string} [args.calendarKey]
         * @param {boolean} [args.wholeDay]
         * @param {number} [args.ownerKey]
         * @return {Calendar2Event}
         */
        static createDefault(date, args = {}) {
            const start = moment();
            start.year(date.year());
            start.month(date.month());
            start.date(date.date());
            start.second(0);
            const end = moment();
            end.year(date.year());
            end.month(date.month());
            end.date(date.date());
            end.second(0);
            if (args.startTime?.isValid?.() && args.endTime?.isValid?.()) {
                start.hour(args.startTime.hour());
                start.minute(args.startTime.minute());
                end.hour(args.endTime.hour());
                end.minute(args.endTime.minute());
            } else {
                start.hour(7);
                start.minute(0);
                end.hour(8);
                end.minute(0);
            }
            return new Calendar2Event({
                start,
                end,
                whole_day: args.wholeDay,
                owner_key: args.ownerKey,
                calendar_key: args.calendarKey,
            }, args.calendar);
        }

        /**
         * @param {object}      args
         * @param {number}      args.customer
         * @param {string[]}    args.calendars - array of calendar keys
         * @param {moment}      args.from
         * @param {moment}      args.to
         * @param {string}      [args.filter]
         * @return {Promise<Calendar2Event[]>}
         */
        static getForPeriod(args) {
            return EawResource.create('/customers/:customer/calendars/:calendars/events').query({
                customer: args.customer,
                calendars: args.calendars?.join(','),
                from: args.from,
                to: args.to,
                filter: args.filter,
            }).$promise.then((calendars) => calendars.reduce((acc, cal) => {
                const events = cal.events.events.map((ev) => new Calendar2Event(ev, new Calendar(cal)));
                return acc.concat(events);
            }, []));
        }

        /**
         * @param {object}          args
         * @param {number}          args.customerId
         * @param {string}          args.calendarKey
         * @param {string}          args.title
         * @param {string}          args.description
         * @param {moment.Moment}   args.start
         * @param {moment.Moment}   args.end
         * @param {boolean}         args.wholeDay
         * @return {Promise<Calendar2Event>}
         */
        static create(args) {
            return EawResource.create('/customers/:customer/calendars/:calendar/events').save({
                customer: args.customerId,
                calendar: args.calendarKey,
            }, {
                name: args.title || '(No title)',
                description: args.description,
                start: args.wholeDay ? args.start.startOf('d') : args.start,
                end: args.wholeDay ? args.end.endOf('d') : args.end,
                whole_day: args.wholeDay,
            }).$promise.then((resp) => new Calendar2Event(resp, args.calendar));
        }

        /**
         * @param {object}          args
         * @param {number}          args.customerId
         * @param {string}          args.calendarKey
         * @param {string}          args.eventKey
         * @param {string}          args.title
         * @param {string}          args.description
         * @param {moment.Moment}   args.start
         * @param {moment.Moment}   args.end
         * @param {boolean}         args.wholeDay
         * @return {Promise<Calendar2Event>}
         */
        static update(args) {
            return EawResource.create('/customers/:customer/calendars/:calendar/events/:event').update({
                customer: args.customerId,
                calendar: args.calendarKey,
                event: args.eventKey,
            }, {
                name: args.title || '(No title)',
                description: args.description,
                start: args.wholeDay ? args.start.startOf('d') : args.start,
                end: args.wholeDay ? args.end.startOf('d') : args.end,
                whole_day: args.wholeDay,
            }).$promise.then((resp) => new Calendar2Event(resp, args.calendar));
        }
    }
    return Calendar2Event;
} ]);
