import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { AbsenceType, AbsenceTypeResponse } from '../../models/absence-type';
import { AbsenceStat, AbsenceStatResponse, AbsenceStatsService } from '../../http/absence-stats.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { FormatUnit, NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { Employee } from '../../../shared/models/employee';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { NumberPipe } from '../../../shared/pipes/number.pipe';

export interface AbsenceStatsDialogData extends DialogData {
    customerId: number;
    employeeId?: number;
}

interface DataSourceItem {
    absenceType: AbsenceType,
    total: number,
    count: number,
}

@Component({
    selector: 'eaw-absence-stats-dialog',
    templateUrl: './absence-stats-dialog.component.html',
    styleUrl: './absence-stats-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        AutocompleteComponent,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        NgIf,
        MatProgressSpinnerModule,
        MatTableModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
    ],
})
export class AbsenceStatsDialogComponent extends DialogComponent implements OnInit {
    years = new Array(DateTime.now().year - 2015).fill(0).map((_, i) => DateTime.now().year - i);

    form = new FormGroup({
        year: new FormControl<number>(DateTime.now().year, { nonNullable: true }),
        employee: new FormControl<Employee | number | undefined>(this.data.employeeId, Validators.required),
    });

    loading = false;
    dataSource: DataSourceItem[] = [];
    displayedColumns: string[] = [ 'absenceType', 'count', 'total' ];
    private stats: Record<number, AbsenceStat> = {};
    private absenceTypes: Record<string, AbsenceTypeResponse> = {};
    readonly absenceTypeColumn = 'absenceType';
    readonly countColumn = 'count';
    readonly totalColumn = 'total';

    constructor(
        @Inject(MatDialog) public dialog: MatDialog,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AbsenceStatsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) override data: AbsenceStatsDialogData,
        @Inject(AbsenceStatsService) private absenceStatsService: AbsenceStatsService,
        @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit() {
        this.getStats(DateTime.now().year);

        if (this.data.employeeId) {
            this.form.controls.employee.disable();
        }

        this.form.controls.employee.valueChanges.subscribe((employee) => {
            if (!this.data.employeeId && employee instanceof Employee) {
                this.getStats(this.form.controls.year.value);
            }
        });
    }

    getStats(year: number) {
        const dateTime = DateTime.fromObject({ year });
        const employeeAutocompleteVal = this.form.controls.employee.value;
        const employeeAutocompleteId = employeeAutocompleteVal instanceof Employee ? employeeAutocompleteVal.id : employeeAutocompleteVal;

        const employeeId = this.data.employeeId || employeeAutocompleteId;
        if (employeeId) {
            this.loading = true;
            this.absenceStatsService.getStats(this.data.customerId, employeeId, dateTime.startOf('year'), dateTime.endOf('year')).subscribe({
                next: (data: AbsenceStatResponse) => {
                    this.dataSource = [];
                    this.stats = data.statistics;
                    this.absenceTypes = data.absence_types;
                    for (const stat in this.stats) {
                        const response = this.absenceTypes[stat];
                        const absenceStat = this.stats[stat];

                        if (!response || !absenceStat) {
                            continue;
                        }

                        const dataSourceItem = {
                            absenceType: new AbsenceType(response),
                            count: absenceStat.count,
                            total: absenceStat.length,
                        };
                        this.dataSource.push(dataSourceItem);
                    }
                    this.loading = false;
                },
            });
        }
    }

    formatUnit(number: number, unit?: FormatUnit) {
        if (!unit) {
            return '';
        }
        return this.numberFormatterService.formatUnit(number, unit, { maximumFractionDigits: 2 });
    }

    yearChange(change: MatSelectChange) {
        if (typeof change.value === 'number') {
            this.getStats(change.value);
        }
    }
}
