import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { SettingGroup, SettingGroupResponse } from '../models/setting-group';
import { Observable } from 'rxjs';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class SettingGroupService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(options: PaginationOptions): Observable<ArrayPaginatedResponse<SettingGroup>> {
        return this.http.get<ArrayPaginatedResponse<SettingGroupResponse>>(`/setting_groups`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(SettingGroup));
    }

    get(groupId: number): Observable<SettingGroup> {
        return this.http.get<SettingGroupResponse>(`/setting_groups/${groupId}`).pipe(classifyItem(SettingGroup));
    }

    create(name: string): Observable<SettingGroup> {
        return this.http.post<SettingGroupResponse>(`/setting_groups`, {
            name,
        }).pipe(classifyItem(SettingGroup));
    }

    update(groupId: number, name: string): Observable<SettingGroup> {
        return this.http.put<SettingGroupResponse>(`/setting_groups/${groupId}`, {
            name,
        }).pipe(classifyItem(SettingGroup));
    }

    delete(groupId: number): Observable<undefined> {
        return this.http.delete<undefined>(`/setting_groups/${groupId}`);
    }
}
