// @ts-nocheck
import { module } from 'angular';

const template1 = `<div class="simple-warning" ng-repeat="warning in $warnListSimple.warnings track by warning.id" ng-click="warning.openExtendedInfo()">
    <md-icon ng-bind="'error'" class="s18"></md-icon>
    <div ng-bind="warning.translatedMessage"></div>
</div>
`;

module('eaw.warnings').component('warningsListSimple', {
    bindings: {
        warnings: '<',
    },
    controllerAs: '$warnListSimple',
    template: template1,
    controller: [ 'Warning', function(Warning) {
        const ctrl = this;

        ctrl.$onChanges = () => {
            ctrl.warnings = ctrl.warnings?.map((w) => w instanceof Warning ? w : new Warning(w)) || [];
        };
    } ],
});
