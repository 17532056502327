<eaw-page-header [fabButton]="fabButton">
    <span title>{{ 'FAMILY_MEMBER_plural' | translate:'company' | async }}</span>
</eaw-page-header>

<mat-card>
    <eaw-data-table
        #table
        [columns]="columns"
        [request]="request"
        initialSortBy="id"
        (paginationChange)="updateTable($event)">
    </eaw-data-table>
</mat-card>
