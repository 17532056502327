import { module } from 'angular';
import { Namespace } from '../../shared/enums/namespace';
import { createState } from '../../shared/utils/create-state';
import { staticResolver } from '../../shared/resolvers/static.resolver';
import { LearningModuleModule } from '../../learning-module/types/modules';

module('eaw.messages', [
    'ew-endless-list',
    'eaw.resource',
    'eaw.decorators',
    'eaw.notifications',
    'eaw.login',
    'eaw.alerts',
    'eaw.dialogs',
]).config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: `eaw/app/messages`,
        parent: 'eaw/app',
        url: '/messages',
        abstract: true,
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            i18nextNs: [ Namespace.Messages ],
            learningModuleModules: [ LearningModuleModule.Messaging ],
            breadcrumb: { key: 'MESSAGES', ns: 'navigation' },
        },
        resolve: [
            staticResolver('navbarRoute', `eaw/app/messages`),
            staticResolver('tabs', [
                {
                    label: 'messages:CONVERSATION_plural',
                    src: `eaw/app/messages/conversations/write`,
                },
                {
                    label: 'messages:INBOX',
                    src: `eaw/app/messages/inbox`,
                },
                {
                    label: 'messages:OUTBOX',
                    src: `eaw/app/messages/outbox`,
                },
            ]),
        ],
    });

    $stateProvider.state('eaw/app/messages/conversations', {
        parent: `eaw/app/messages`,
        url: '?:include_archived',
        abstract: true,
        component: 'eawMessageConversations',
        params: {
            include_archived: {
                type: 'bool',
                squash: true,
                dynamic: false,
            },
        },
    });

    $stateProvider.state(`eaw/app/messages/conversations/write`, {
        parent: 'eaw/app/messages/conversations',
        url: '/write',
        views: {
            conversationContent: {
                component: 'eawWriteMessage',
            },
        },
        data: {
            breadcrumb: { key: 'NEW_MESSAGE', ns: 'messages' },
        },
    });

    $stateProvider.state(`eaw/app/messages/conversations/thread`, {
        parent: 'eaw/app/messages/conversations',
        url: '/:id',
        views: {
            conversationContent: {
                component: 'readConversation',
            },
        },
        resolve: {
            originalMessage: [ 'Message', '$transition$', function(Message: any, $transition$: any) {
                return Message.read($transition$.params().id, [ 'archivals' ]).$promise.then((data: any) => new Message(data));
            } ],
            thread: [ 'Message', '$transition$', function(Message: any, $transition$: any) {
                return Message.getThread($transition$.params().id, 1).$promise;
            } ],
        },
        data: {
            breadcrumb: { key: 'SUBJECT', ns: 'messages' },
        },
    });

    $stateProvider.state(`eaw/app/messages/inbox`, {
        parent: `eaw/app/messages`,
        url: '/inbox',
        component: 'eawInbox',
        data: {
            breadcrumb: { key: 'INBOX', ns: 'navigation' },
        },
    });

    $stateProvider.state(`eaw/app/messages/outbox`, {
        parent: `eaw/app/messages`,
        url: '/outbox',
        component: 'eawOutbox',
        data: {
            breadcrumb: { key: 'OUTBOX', ns: 'navigation' },
        },
    });
} ]);
