import { ApiResponse } from '../../shared/interfaces/api-response';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export interface CalendarEventResponse extends ApiResponse {
    id: number;
    name: string;
    description: string;
    from: string;
    to: string;
    color: string;
    owner_id: number;
    owner_type: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
}

export class CalendarEvent {
    id: number;
    name: string;
    description: string;
    from: DateTime;
    to: DateTime;
    color: string;
    ownerId: number;
    ownerType: string;
    createdAt: DateTime | null;
    updatedAt: DateTime | null;
    deletedAt?: DateTime | null;

    constructor(data: CalendarEventResponse) {
        this.id = data.id;
        this.ownerId = data.owner_id;
        this.ownerType = data.owner_type;
        this.name = data.name;
        this.description = data.description;
        this.from = stringToDateTime(data.from);
        this.to = stringToDateTime(data.to);
        this.color = data.color;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
