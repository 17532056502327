// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.checklists').controller('ChecklistsViewCtrl', [ '$mdDialog', 'checklist', 'ChecklistFactory', 'ChecklistsService', 'ToastService', function($mdDialog, checklist, ChecklistFactory, ChecklistsService, ToastService) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.checklist = checklist;
        ctrl.cs = ChecklistsService;

        ChecklistsService.updatePercentages(checklist);

        ctrl.walk(checklist);
    };

    ctrl.walk = (obj, level) => {
        if (obj.children) {
            obj.children.forEach((child) => {
                child.level = level + 1 || 0;
                child.levelColor = child.level % 2;
                child.isCategory = !child.type;

                ctrl.walk(child, child.level);
            });
        }
    };

    ctrl.closeChecklist = () => {
        $mdDialog.show(
            $mdDialog.confirm()
                .title(t('checklists:CLOSE_CHECKLIST'))
                .textContent(t('checklists:CLOSE_CHECKLIST_TEXT'))
                .ok(t('checklists:CLOSE_CHECKLIST'))
                .cancel(t('checklists:DONT_CLOSE_CHECKLIST')),
        ).then(() => {
            ChecklistFactory.update(CurrentOld.customer, {
                id: checklist.id,
                close: true,
            }).$promise.then(() => {
                ToastService.toast(t('checklists:CHECKLIST_CLOSED'));
                ctrl.checklist.closed_at = moment();
            });
        });
    };
} ]);
