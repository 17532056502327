import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangeLocationDialogComponent, ChangeLocationDialogData } from '../dialogs/change-location-dialog/change-location-dialog.component';
import { DialogSize } from '../dialogs/dialog-component';
import { CurrentService } from './current.service';
import { StateObject, UIRouter } from '@uirouter/core';
import { Customer } from '../models/customer';
import { Storage } from '../utils/storage';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'any',
})
export class ChangeLocationService {
    private readonly uiRouter = inject(UIRouter);
    private readonly dialog = inject(MatDialog);
    private readonly current = inject(CurrentService);
    private readonly appService = inject(AppService);

    open(customer: Customer) {
        return this.dialog.open<ChangeLocationDialogComponent, ChangeLocationDialogData, number>(ChangeLocationDialogComponent, {
            data: {
                customer,
                size: DialogSize.Medium,
            },
        });
    }

    async go(customerId: number, reloadUrl?: string) {
        await Storage.setCustomer(this.current.getUser().id, customerId);
        this.appService.reload(reloadUrl ?? this.getReloadUrl());
    }

    /**
     * Finds the "highest" state in the current path **before** a state that has params and a name.
     *
     * This makes sure when reload happens that the user is not redirected to a state that requires params they don't have.
     */
    getReloadUrl(state?: string) {
        let stateObj: StateObject | undefined;
        const params: Record<string, any> = {};

        if (!state) {
            stateObj = this.uiRouter.globals.$current.path.reverse().find((item) => {
                const params = item.url.parameters();
                const hasName = item.name;
                const notAbstract = !item.self.abstract;
                const withoutRequiredParams = params.every((p) => p.isOptional);

                return hasName && notAbstract && withoutRequiredParams;
            });

            for (const param of stateObj?.url.parameters() || []) {
                params[param.id] = undefined;
            }
        }

        return this.uiRouter.stateService.href(state || stateObj?.name || 'eaw/app/home', params, { absolute: true });
    }
}
