// @ts-nocheck
import { module } from 'angular';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.company').component('companySettingsContractTypes', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="CONTRACT_TYPES"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <md-progress-circular ng-if="$ctrl.loading" class="md-progress-center-absolute"></md-progress-circular>

        <eaw-accordion multi-toggle="true">
            <eaw-accordion-panel ng-repeat="contractType in $ctrl.contractTypes track by contractType.id">
                <panel-header class="tw-flex tw-gap-8">
                    <div layout="column">
                        <span ng-bind="contractType.name"></span>
                    </div>
                    <span flex></span>
                    <span ng-i18next="[i18next]({count: contractType.links.length})company:X_PAY_TYPES"></span>
                </panel-header>
                <panel-content class="tw-p-0">
                    <table class="table table-bordered-bottom">
                        <thead>
                        <tr>
                            <th ng-i18next="CODE"></th>
                            <th ng-i18next="NAME"></th>
                            <th ng-i18next="FROM"></th>
                            <th ng-i18next="TO"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr ng-repeat="link in contractType.links track by link.id">
                            <td ng-bind="link.code"></td>
                            <td ng-bind="link.name"></td>
                            <td ng-bind="link.from | moment:'LL'"></td>
                            <td ng-bind="link.to | moment:'LL'"></td>
                        </tr>
                        </tbody>
                    </table>
                </panel-content>
            </eaw-accordion-panel>
        </eaw-accordion>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
    },
    controller: [ 'ContractTypeFactory', 'EawResource', function(ContractTypeFactory, EawResource) {
        const ctrl = this;
        ctrl.$onInit = async () => {
            ctrl.loading = true;
            const typesResponse = await ContractTypeFactory.getAllForCustomer({
                customer_id: ctrl.customer.id,
                per_page: 100,
            }).$promise;
            const linksResponse = await EawResource.create(`/customers/${ctrl.customer.id}/pay_type_links`).get({
                'with[]': [ 'contractTypes' ],
                per_page: 100,
                active: true,
            }).$promise;
            ctrl.contractTypes = sort(typesResponse.data, CurrentOld.languageTag, [ (x) => x.name ], [ 'asc' ]);
            ctrl.contractTypes.forEach((ct) => (ct.links = []));
            linksResponse.data.forEach((link) => {
                if (!link.contract_types.length) {
                    ctrl.contractTypes.forEach((ct) => ct.links.push(link));
                    return;
                }
                link.contract_types.forEach((lct) => {
                    ctrl.contractTypes.find((ct) => ct.id === lct.id)?.links.push(link);
                });
            });
            ctrl.loading = false;
        };
    } ],
});
