// @ts-nocheck
import { remove } from 'lodash-es';
import { module } from 'angular';
import { inRange } from '../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
module('eaw.scheduling').service('scheduleEfficiency', [ 'ScheduleService', function scheduleEfficiency(ScheduleService) {
    let _efficiencies;
    let _schedule;
    const service = {
        lastId: -1,
        setSchedule(s) {
            _schedule = s;
        },
        init() {
            if (!service.showEfficiency()) {
                return;
            }
            if (this.lastId === _schedule.id) {
                return new Promise((resolve) => resolve(_efficiencies));
            } else {
                _efficiencies = [];
            }
            this.lastId = _schedule.id;
            return new Promise((resolve, reject) => {
                ScheduleService.getEfficiencies({
                    customerId: _schedule.customer_id,
                    scheduleId: _schedule.id,
                    per_page: 99999,
                }).$promise.then((data) => {
                    _efficiencies = data.data;
                    resolve(undefined);
                }, reject);
            });
        },
        /**
         * Return array of efficiencies
         */
        get() {
            return _efficiencies;
        },
        reset() {
            _efficiencies = [];
            this.lastId = -1;
            service.events?.trigger?.change?.(null);
        },
        findEfficiencyFromBudget(budget) {
            const efficiency = Object.values(_efficiencies).find((e) => inRange(budget, e.budget_low, e.budget_high));
            return efficiency?.value || '';
        },
        /**
         * Add new efficiency to schedule
         */
        add(efficiency) {
            return new Promise((resolve, reject) => {
                ScheduleService.addEfficiency({ id: _schedule.customer_id }, _schedule, efficiency).$promise.then((data) => {
                    _efficiencies.push(data);
                    resolve(data);
                }, reject);
            });
        },
        /**
         * Delete efficiency
         */
        delete(efficiency) {
            return new Promise((resolve, reject) => {
                ScheduleService.deleteEfficiency({ id: _schedule.customer_id }, _schedule, efficiency).$promise.then((data) => {
                    remove(_efficiencies, (e) => e.id === efficiency.id);
                    resolve(data);
                }, reject);
            });
        },
        showEfficiency: function() {
            return CurrentOld.hasProduct('efficiency') && CurrentOld.can(`customers.${_schedule.customer_id}.schedules.${_schedule.id}.efficiencies.*.get`);
        },
    };
    return service;
} ]);
