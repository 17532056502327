import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, switchMap, timer } from 'rxjs';
import { Duration } from 'luxon';

interface VersioningData {
    utc: number;
}

type VersioningDataKey = keyof VersioningData;

@Injectable({
    providedIn: 'root',
})
export class VersioningService {
    private versioningSubject = new ReplaySubject<VersioningData>(1);

    constructor() {
        timer(0, Duration.fromObject({ minutes: 5 }).as('milliseconds'))
            .pipe(switchMap(() => this.get()))
            .subscribe();
    }

    get(): Promise<VersioningData> {
        return new Promise((resolve, reject) => {
            fetch('./assets/versioning.json').then((response) => {
                response.json().then((data: VersioningData) => {
                    this.versioningSubject.next(data);
                    resolve(data);
                });
            }).catch(() => {
                reject(null);
            });
        });
    }

    onChange<Key extends VersioningDataKey>(item: Key): Observable<VersioningData[Key]>
    onChange(item: 'full'): Observable<VersioningData>
    onChange(item: 'full' | VersioningDataKey): Observable<VersioningData | VersioningData[VersioningDataKey]> {
        return this.versioningSubject.asObservable().pipe(
            map((data) => {
                return item === 'full' ? data : data[item];
            }),
        );
    }
}
