import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { classifyArrayPaginatedResponse } from '../utils/rxjs/classify';
import { Infraction, InfractionResponse } from '../models/infraction';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../utils/format-http-params';

@Injectable({
    providedIn: 'root',
})
export class InfractionService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, employeeIds: number[], from: DateTime, to: DateTime, options?: PaginationOptions): Observable<ArrayPaginatedResponse<Infraction>> {
        return this.http.get<ArrayPaginatedResponse<InfractionResponse>>(`customers/${customerId}/warnings_v2`, {
            params: formatHttpParams({
                ...options,
                'employee_ids[]': employeeIds,
                from,
                to,
            }),
        }).pipe(classifyArrayPaginatedResponse(Infraction));
    }
}
