import { module } from 'angular';
import { CustomerListComponent } from '../../pages/customer-list/customer-list.component';
import { createState } from '../../../shared/utils/create-state';
import { staticResolver } from '../../../shared/resolvers/static.resolver';
import { ManageCustomerComponent } from '../../pages/manage-customer/manage-customer.component';
import { DataTableQueryParams } from '../../../data-table/data-table.component';
import { CustomerTransferComponent } from '../../pages/customer-transfer/customer-transfer.component';
import { CurrentService } from '../../../shared/services/current.service';

module('eaw.admin').config([ '$stateProvider', function($stateProvider: any) {
    createState($stateProvider, {
        name: 'eaw/app/admin/customers',
        parent: 'eaw/app/admin',
        url: '/customers',
        abstract: true,
        data: {
            breadcrumb: null,
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/admin/customers/list',
        parent: 'eaw/app/admin/customers',
        url: '/list',
        views: {
            'content@': {
                component: CustomerListComponent,
            },
        },
        data: {
            queryParams: [ ...DataTableQueryParams, 'filter' ],
            breadcrumb: { key: 'CUSTOMER_plural', ns: 'admin' },
            permissionCheck: (permissionCheckService) => {
                return permissionCheckService.permissionOrChildren(`customers.*.get`, undefined, `customers`, `get`, true)
            },
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/admin/customers/create',
        parent: 'eaw/app/admin/customers/list',
        url: '/new',
        views: {
            'content@': {
                component: ManageCustomerComponent,
            },
        },
        data: {
            breadcrumb: { key: 'NEW_CUSTOMER', ns: 'admin' },
        },
        resolve: [
            staticResolver('customerId', null),
        ],
    });
    $stateProvider.state('eaw/app/admin/customers/transfer', {
        parent: 'eaw/app/admin/customers',
        url: '/transfer',
        views: {
            'content@': {
                component: CustomerTransferComponent,
            },
        },
        data: {
            breadcrumb: { key: 'OWNERSHIP_TRANSFER', ns: 'admin' },
        },
    });
} ]);
