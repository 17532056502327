<eaw-page-header>
    <span title>{{'PIN_CODE' | translate | async}}</span>
</eaw-page-header>

<div *ngIf="loading" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<mat-accordion *ngIf="!loading" [multi]="true">
    <ng-container *ngFor="let customer of customers">
        <mat-expansion-panel [disabled]="!customer.pinEnabled">
            <mat-expansion-panel-header>
                <mat-panel-title>{{customer.customer.name}}</mat-panel-title>
                <mat-panel-description>
                    <span>{{customer.description | async}}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div>
                <button mat-raised-button (click)="getPinCode(customer.customer.id)">{{'RETRIEVE_PIN_CODE' | translate: 'profile' | async}}</button>
                <button *ngIf="customer.employeeId" mat-raised-button class="tw-ml-8" color="primary" (click)="setPinCode(customer.customer.id, customer.employeeId)">{{'NEW_PIN_CODE' | translate: 'profile' | async}}</button>
            </div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
