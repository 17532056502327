// @ts-nocheck
import { module } from 'angular';
import { Comment } from '../../../../shared/models/comment';
import { DateTime, Duration } from 'luxon';
import { Products } from '../../../../shared/enums/products';
import { t } from 'i18next';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.vacation').component('editOffTime', {
    template: `<div>
    <md-card>
        <md-card-header class="tw-p-0">
            <eaw-profile-picture-old class="tw-mr-12" user="$ctrl.offTime.employee.user" square="true" size="66"></eaw-profile-picture-old>

            <md-card-header-text>
                <span class="md-title" ng-bind="$ctrl.offTime.employee.name"></span>
                <span class="md-subhead" ng-if="$ctrl.isApproved && $ctrl.offTime.vacation" ng-i18next="VACATION"></span>
                <span class="md-subhead" ng-if="$ctrl.isApproved && !$ctrl.offTime.vacation" ng-i18next="OFFTIME"></span>
            </md-card-header-text>
        </md-card-header>
        <md-card-content class="tw-p-0">
            <md-subheader>
                <span ng-bind="$ctrl.vacationDaysText"></span>
            </md-subheader>
            <table class="table table-bordered-bottom">
                <tr ng-if="$ctrl.hasApproval">
                    <td bo-i18next="STATUS"></td>
                    <td>
                        <div layout="row" layout-align="start center" ng-if="$ctrl.isApproved">
                            <md-icon class="tw-mr-12 tw-ml-0" ng-bind="'done'" md-colors="{color: 'green'}"></md-icon>
                            <span ng-i18next="APPROVED"></span>
                        </div>

                        <div layout="row" layout-align="start center" ng-if="$ctrl.isDeclined">
                            <md-icon class="tw-mr-12 tw-ml-0" ng-bind="'close'" md-colors="{color: 'red'}"></md-icon>
                            <span ng-i18next="DECLINED"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td bo-i18next="FROM"></td>
                    <td ng-bind="::$ctrl.offTime.from | moment:'LLLL'"></td>
                </tr>
                <tr>
                    <td bo-i18next="TO"></td>
                    <td ng-bind="::$ctrl.offTime.to | moment:'LLLL'"></td>
                </tr>
                <tr>
                    <td bo-i18next="DURATION"></td>
                    <td ng-bind="::$ctrl.duration"></td>
                </tr>
                <tr ng-if="$ctrl.isApproved && (!$ctrl.hasSwitzerland || $ctrl.vacationDayOptionEnabled)">
                    <td bo-i18next="vacation:DAYS_DEDUCTED"></td>
                    <td>
                        <div ng-bind="$ctrl.deductedDays"></div>
                        <small ng-if="$ctrl.offTime.vacation_days_change[0].type != 'manual'" ng-i18next="vacation:AUTO_CALC"></small>
                        <small ng-if="$ctrl.offTime.vacation_days_change[0].type == 'manual'" ng-i18next="[i18next]({name: $ctrl.offTime.vacation_days_change[0].performed_by_name})vacation:DEDUCTED_BY"></small>
                    </td>
                </tr>
                <tr ng-if="$ctrl.isApproved && (!$ctrl.hasSwitzerland || $ctrl.vacationDayOptionEnabled)">
                    <td bo-i18next="vacation:VACATION_QUESTION"></td>
                    <td>
                        <md-switch class="tw-m-0" ng-model="$ctrl.vacation" ng-disabled="$ctrl.updating" ng-change="$ctrl.update()">
                            <span ng-if="$ctrl.vacation" ng-i18next="YES"></span>
                            <span ng-if="!$ctrl.vacation" ng-i18next="NO"></span>
                        </md-switch>
                    </td>
                </tr>
                <tr ng-if="$ctrl.hasApproval">
                    <td bo-i18next="HANDLED_BY"></td>
                    <td>
                        <div layout="row" layout-align="start center">
                            <eaw-profile-picture-old class="tw-mr-12" size="30" user="$ctrl.offTime.approval.handler"></eaw-profile-picture-old>
                            <span ng-bind="$ctrl.offTime.approval.handler.name"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td ng-i18next="[i18next]({count: $ctrl.offTime.employee.positions.length})company:POSITION"></td>
                    <td>
                        <div ng-repeat="pos in $ctrl.offTime.employee.positions track by pos.id" ng-bind="pos.name"></div>
                    </td>
                </tr>
            </table>
        </md-card-content>

        <md-card-content>
            <md-card-actions layout="row">
                <div ng-if="$ctrl.canUpdate && $ctrl.hasApproval">
                    <md-button ng-if="$ctrl.isApproved" class="md-raised" ng-disabled="$ctrl.updating" md-colors="{background: 'warn'}" ng-click="$ctrl.update(false)">
                        <span ng-i18next="DECLINE"></span>
                    </md-button>

                    <md-button ng-if="$ctrl.isDeclined" class="md-raised" ng-disabled="$ctrl.updating" md-colors="{background: 'green'}" ng-click="$ctrl.update(true)">
                        <span ng-i18next="APPROVE"></span>
                    </md-button>
                </div>

                <div ng-if="$ctrl.canUpdate && !$ctrl.hasApproval">
                    <md-button md-colors="{background: 'green'}" class="md-raised" ng-disabled="$ctrl.updating" ng-click="$ctrl.update(true)">
                        <span ng-i18next="APPROVE"></span>
                    </md-button>

                    <md-button md-colors="{background: 'warn'}" class="md-raised" ng-disabled="$ctrl.updating" ng-click="$ctrl.update(false)">
                        <span ng-i18next="DECLINE"></span>
                    </md-button>
                </div>

                <span flex></span>

                <div ng-if="$ctrl.canDelete && $ctrl.canDeleteApproved != null">
                    <md-button md-colors="{background: 'warn'}" class="md-raised" ng-click="$ctrl.delete()">
                        <span ng-i18next="DELETE"></span>
                    </md-button>
                </div>
            </md-card-actions>
    </md-card>
</div>

<md-card>
    <md-card-content layout="row" layout-align="center start">
        <eaw-comment-list ng-show="$ctrl.offTime.comments.length" flex="100" [angularjscomments]="$ctrl.offTime.comments"></eaw-comment-list>

        <div class="tw-p-20" layout="column" ng-if="!$ctrl.offTime.comments.length">
            <md-icon ng-bind="'comment'"></md-icon>
            <span class="tw-pt-12" ng-i18next="NO_COMMENTS"></span>
        </div>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
        offTime: '<',
        vacationDaysText: '<', // Vacation days left the year of the request
    },
    controller: [ '$filter', 'HandleOfftimeService', '$mdDialog', 'OffTimeService', 'ToastService', '$state', 'CustomerSettings', function($filter, HandleOfftimeService, $mdDialog, OffTimeService, ToastService, $state, CustomerSettings) {
        const ctrl = this;

        ctrl.$onInit = async () => {
            ctrl.canUpdate = CurrentOld.can(`customers.${ctrl.offTime.customer_id}.employees.${ctrl.offTime.employee_id}.off_times.${ctrl.offTime.id}.update`);
            ctrl.canDelete = CurrentOld.can(`customers.${ctrl.offTime.customer_id}.employees.${ctrl.offTime.employee_id}.off_times.${ctrl.offTime.id}.delete`);
            ctrl.hasSwitzerland = CurrentOld.hasProduct(Products.Switzerland);

            const deleteApprovedKey = 'absences.allow_employee_to_delete_approved';
            const dayDeductionOptionKey = 'switzerland.enable_vacation_day_deduction';
            const settingKeys = [ deleteApprovedKey ].concat(ctrl.hasSwitzerland ? [ dayDeductionOptionKey ] : []);
            await CustomerSettings.getSome(ctrl.customer.id, settingKeys).then((settings) => {
                ctrl.canDeleteApproved = !!(settings[deleteApprovedKey]?.resolved_value ?? false);
                ctrl.vacationDayOptionEnabled = !!(settings[dayDeductionOptionKey]?.resolved_value ?? false);
            });

            ctrl.offTime.comments = ctrl.offTime.comments?.map((c) => new Comment(c)) || [];
            ctrl.hasApproval = ctrl.offTime.approval;
            ctrl.isApproved = ctrl.offTime.approval?.approved === true;
            ctrl.isDeclined = ctrl.offTime.approval?.approved === false;
            ctrl.vacation = ctrl.isApproved ? ctrl.offTime.vacation : true;
            ctrl.deductedDays = Duration.fromObject({ days: Math.abs(ctrl.offTime.vacation_days_change?.[0]?.delta || 0) }).toHuman();
            ctrl.duration = $filter('eawDuration')(ctrl.offTime.to.diff(ctrl.offTime.from, 's'));
        };

        ctrl.delete = () => {
            if (ctrl.offTime.approval?.approved && !ctrl.canDeleteApproved) {
                return $mdDialog.show($mdDialog.alert()
                    .title(t('vacation:DELETE_REQUEST'))
                    .textContent(t('vacation:CANT_DELETE_APPROVED'))
                    .ok(t('OK')));
            }
            $mdDialog.show($mdDialog.confirm()
                .theme('delete')
                .title(t('vacation:DELETE_REQUEST'))
                .ok(t('DELETE'))
                .cancel(t('CANCEL'))).then(() => {
                new OffTimeService(ctrl.customer.id).delete.query(ctrl.offTime.employee_id, ctrl.offTime.id).$promise.then(() => {
                    ToastService.toast(t('vacation:REQUEST_DELETED'));
                    $state.transitionTo(`eaw/app/vacations/manage/handle`);
                });
            });
        };
        ctrl.update = async (approved) => {
            ctrl.offTime.vacation = ctrl.vacation;
            if (typeof approved === 'boolean') {
                ctrl.offTime.approved = approved;
            }
            if (ctrl.offTime.isModified('approved')) {
                try {
                    if (ctrl.offTime.approved) {
                        HandleOfftimeService.approve(ctrl.offTime.customer_id, ctrl.offTime.employee_id, {
                            from: DateTime.fromJSDate(ctrl.offTime.from.toDate()),
                            to: DateTime.fromJSDate(ctrl.offTime.to.toDate()),
                            offTimeId: ctrl.offTime.id,
                            mode: 'update',
                        }).afterClosed().subscribe((res) => {
                            if (res == null) {
                                return;
                            }
                            $state.reload();
                        });
                    } else {
                        HandleOfftimeService.decline(ctrl.offTime.customer_id, ctrl.offTime.employee_id, ctrl.offTime.id).subscribe((res) => {
                            if (res == null) {
                                return;
                            }
                            $state.reload();
                        });
                    }
                } catch (_) {
                    ctrl.updating = false;
                    return;
                }
            } else {
                await ctrl.offTime.update();
                ToastService.updated();
                $state.reload();
            }
        };
    } ],
});
