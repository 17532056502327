import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import type { DataTableNumberColumn } from '../../types/data-table-number-column';
import { DataTablePortalComponent } from '../../types/data-table-portal-component';
import { DataTableComparer } from '../../interfaces/data-table-comparer';
import { CurrentService } from '../../../shared/services/current.service';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'eaw-number-portal',
    templateUrl: './number-portal.component.html',
    styleUrl: './number-portal.component.scss',
    standalone: true,
    imports: [
        NgIf,
        NgSwitch,
        NgSwitchCase,
        MatIconModule,
        NgSwitchDefault,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberPortalComponent<Item> extends DataTablePortalComponent<Item, unknown, DataTableNumberColumn<Item>> implements OnInit {
    private readonly numberFormatterService = inject(NumberFormatterService);
    private readonly current = inject(CurrentService);

    number = signal<string>('');
    comparison = signal< ReturnType<DataTableComparer<any, any>>>(undefined);

    constructor() {
        super();
        this.comparison.set(this.cell.column.comparer?.(this.cell));
    }

    async ngOnInit() {
        const value = await this.getValue<number>();

        if (value == null) {
            this.number.set('');
            return;
        }

        if (Number.isNaN(value)) {
            this.number.set(String(value));
            return;
        }

        try {
            this.number.set(this.numberFormatterService.format(this.current.languageTag, value, this.cell.column.numberFormatOptions));
        } catch (e) {
            console.error(e);
            this.number.set(String(value ?? ''));
        }
    }
}
