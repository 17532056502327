// @ts-nocheck
import { find } from 'lodash-es';
import { module } from 'angular';
import { smallDevice } from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';
module('eaw.scheduling').service('PeriodsDraggable', [ 'ScheduleService', 'shiftEvents', 'intervalService', 'scheduleMode', function(ScheduleService, shiftEvents, intervalService, scheduleMode) {
    const service = this;
    service.start = () => {
        service.shift = service.schedule.getShift(service.element.dataset.shiftId);
        service.period = find(service.shift.periods, (p) => p.id === parseInt(service.element.dataset.shiftPeriodId));
        intervalService.addTimeTellers();
        service.closestMarkerStart = intervalService.calculateClosestMarkerOffsetFromPixels(service.element.offsetLeft);
    };
    service.drag = (e) => {
        const diff = e.screenX - service.orgScreenX;
        const left = Math.max(0, service.orgLeft + diff); // Dont go below 0
        service.element.style.left = `${left}px`;
        intervalService.calculateTimeTellersTime(service.element, service.shift.offset);
    };
    service.stop = () => {
        document.removeEventListener('mousemove', service.drag);
        intervalService.removeTimeTellers();
        const closestMarkerEnd = intervalService.calculateClosestMarkerOffsetFromPixels(service.element.offsetLeft);
        if (closestMarkerEnd === service.closestMarkerStart) {
            service.element.style.left = `${service.orgLeft}px`;
            return;
        }
        service.period.offset = intervalService.calculateClosestMarkerOffsetFromPixels(service.element.offsetLeft);
        ScheduleService.updatePeriod(CurrentOld.customer.id, service.shift.schedule_id, service.shift.id, service.period).$promise.then(() => {
            shiftEvents.trigger.updated(service.shift);
        });
    };
    service.enable = (schedule) => {
        if (smallDevice()) {
            return;
        }
        if (schedule.original) {
            return;
        }
        if (scheduleMode.getMode() !== 'periodDrag') {
            return;
        }
        service.schedule = schedule;
        Array.from(document.getElementsByClassName('period-line')).forEach((el) => {
            el.addEventListener('mousedown', (e) => {
                e.stopPropagation();
                if (e.button !== 0) {
                    return;
                }
                service.element = el;
                service.orgLeft = el.offsetLeft;
                service.orgScreenX = e.screenX;
                service.start();
                document.addEventListener('mousemove', service.drag);
                document.addEventListener('mouseup', service.stop, { once: true });
            });
        });
    };
    return service;
} ]);
