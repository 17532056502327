import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ShiftService } from '../../http/shift.service';
import { WarningsDialogService } from '../../../shared/dialogs/warnings-dialog/warnings-dialog.service';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { Shift } from '../../models/shift';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { DateTime } from 'luxon';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Employee } from '../../../shared/models/employee';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { map, of } from 'rxjs';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { TranslateService } from '../../../shared/services/translate.service';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent, CommentDialogData } from '../../../shared/dialogs/comments-dialog/comment-dialog.component';

type ShiftsForm = {
    employee: FormControl<Employee | null>,
    range: FormGroup<{
        from: FormControl<DateTime | null>,
        to: FormControl<DateTime | null>
    }>
};

@Component({
    selector: 'eaw-shift-list',
    templateUrl: './shift-list.component.html',
    styleUrl: './shift-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        AutocompleteComponent,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatButtonModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ShiftListComponent implements OnInit, EawDataTable {
    @ViewChild(DataTableComponent) dataTable!: DataTableComponent<Shift>;
    @Input() customerId!: number;

    employee?: Employee;
    columns: DataTableColumnType<Shift>[];
    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    resp?: ArrayPaginatedResponse<Shift>;
    form!: FormGroup<ShiftsForm>;
    defaultFromDate: DateTime;
    defaultToDate: DateTime;

    constructor(
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(WarningsDialogService) private warningDialog: WarningsDialogService,
        @Inject(QueryParamsService) private searchParams: QueryParamsService,
        @Inject(ShiftService) public shiftService: ShiftService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
    ) {
        this.defaultFromDate = DateTime.now().startOf('year');
        this.defaultToDate = DateTime.now().endOf('year');

        this.columns = [
            new DataTableTextColumn({
                value: (cell) => cell.item.employee?.name ?? this.translate.t('UNASSIGNED', 'scheduling'),
                header: new DataTableHeader({
                    i18n: 'EMPLOYEE',
                    sortBy: 'employeeId',
                }),
            }),
            new DataTableDateTimeColumn({
                value: 'from',
                format: 'W',
                header: new DataTableHeader({ i18n: 'WEEK' }),
            }),
            new DataTableDateTimeColumn({
                value: 'from',
                format: DateTime.DATETIME_MED_WITH_WEEKDAY,
                header: new DataTableHeader({
                    i18n: 'FROM',
                    sortBy: 'from',
                }),
            }),
            new DataTableDateTimeColumn({
                value: 'to',
                format: DateTime.DATETIME_MED_WITH_WEEKDAY,
                header: new DataTableHeader({
                    i18n: 'TO',
                    sortBy: 'to',
                }),
            }),
            new DataTableButtonColumn<Shift>({
                buttons: [ {
                    icon: 'warning',
                    type: 'alert',
                    nonBlocking: true,
                    hide: (s) => of(!s.warningsCount),
                    click: (cell) => {
                        this.warningDialog.open(this.getShiftWith(cell.item.id, 'warnings').pipe(map((s) => s.warnings || [])));
                    },
                    show: () => of(true),
                    tooltip: { key: 'WARNING_plural' },
                },
                {
                    icon: 'comment',
                    nonBlocking: true,
                    hide: (s) => of(!s.commentsCount),
                    click: (cell) => {
                        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
                            data: {
                                comments: this.getShiftWith(cell.item.id, 'comments').pipe(map((s) => s.comments || [])),
                            },
                        });
                    },
                    show: () => of(true),
                    tooltip: { key: 'COMMENT_plural' },
                } ],
            }),
        ];
    }

    ngOnInit(): void {
        if (!this.customerId) {
            throw Error('Missing customer');
        }

        this.form = new FormGroup({
            employee: new FormControl<Employee | null>(this.employee || null),
            range: new FormGroup({
                from: new FormControl(this.searchParams.get('from', 'date') || this.defaultFromDate, Validators.required),
                to: new FormControl(this.searchParams.get('to', 'date') || this.defaultToDate, Validators.required),
            }),
        });

        this.getAll();
    };

    getShiftWith(shiftId: number, item: 'comments' | 'warnings') {
        return this.shiftService.get(this.customerId, shiftId, { with: [ item ] });
    }

    getForEmployee(pagination: Partial<DataTablePagination> = {}, employeeId: number, from?: DateTime, to?: DateTime) {
        this.searchParams.set([ {
            key: 'employee',
            value: employeeId,
        } ]);

        this.request = this.shiftService.getAllForEmployee(this.customerId, employeeId, {
            ...pagination,
            to,
            from,
            'with[]': [ 'employee', 'comments', 'warnings' ],
            'fields[]': [ 'to', 'from' ],
        });
    };

    getAll(pagination: Partial<DataTablePagination> = {}, from?: DateTime, to?: DateTime) {
        this.request = this.shiftService.getAll(this.customerId, {
            ...pagination,
            to,
            from,
            'count[]': [ 'warnings', 'comments' ],
            'with[]': [ 'employee' ],
            'fields[]': [ 'to', 'from' ],
        });
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        const from = this.form.controls.range.controls.from.value?.startOf('day');
        const to = this.form.controls.range.controls.to.value?.endOf('day');

        this.searchParams.set([
            {
                key: 'from',
                value: from,
                type: 'date',
            },
            {
                key: 'to',
                value: to,
                type: 'date',
            },
        ]);

        const employeeId = this.form.controls.employee.value?.id;
        if (employeeId) {
            this.getForEmployee(pagination, employeeId, from, to);
        } else {
            this.getAll(pagination, from, to);
        }
    };

    resetForm() {
        this.form.controls.employee.setValue(null);
        this.form.controls.range.controls.from.setValue(this.defaultFromDate);
        this.form.controls.range.controls.to.setValue(this.defaultToDate);

        this.updateTable(this.dataTable.getPagination({ page: 1 }));
    }
}
