import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTablePagination, DataTableRequest } from '../../../data-table/types/data-table';
import { forkJoin, of, take } from 'rxjs';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { Qualification } from '../../../shared/models/qualification';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { QualificationService } from '../../../shared/http/qualification.service';
import { PercentPipe } from '../../../shared/pipes/percent.pipe';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { EmployeeService } from '../../../shared/http/employee.service';
import { AddEmployeeQualificationData, AddEmployeeQualificationDialogComponent } from '../../dialogs/add-employee-qualification/add-employee-qualification-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { AddEmployeeQualificationsFromBusinessUnitsComponent, EmployeeQualificationsFromBusinessUnitsData } from '../../dialogs/add-employee-qualifications-from-business-units/add-employee-qualifications-from-business-units.component';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { Products } from '../../../shared/enums/products';

@Component({
    selector: 'eaw-employee-qualifications',
    templateUrl: './employee-qualifications.component.html',
    styleUrl: './employee-qualifications.component.scss',
    providers: [ PercentPipe ],
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmployeeQualificationsComponent implements OnInit {
    private readonly customerProductService = inject(CustomerProductService);
    private readonly service = inject(QualificationService);
    private readonly confirmDialog = inject(ConfirmDialogService);
    private readonly percentPipe = inject(PercentPipe);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly employeeService = inject(EmployeeService);
    protected readonly translate = inject(TranslateService);
    private readonly matDialog = inject(MatDialog);

    @ViewChild(DataTableComponent) dataTable?: DataTableComponent<Qualification>;
    @Input({ required: true }) employeeId!: number;
    @Input({ required: true }) customerId!: number;

    employeeName = '';
    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<Qualification>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item.name || '',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableTextColumn({
            value: (cell) => this.percentPipe.transform(cell.item.rate) || '',
            header: new DataTableHeader({
                i18n: 'LEVEL',
                ns: 'company',
                sortBy: 'level',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            format: DateTime.DATE_MED,
            header: new DataTableHeader({
                i18n: 'FROM',
                sortBy: 'from',
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            format: DateTime.DATE_MED,
            header: new DataTableHeader({
                i18n: 'TO',
                sortBy: 'to',
            }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'edit',
                    click: this.updateQualification.bind(this),
                    show: this.canEdit.bind(this),
                    tooltip: { key: 'EDIT' },
                },
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.deleteQualification.bind(this),
                    show: this.canDelete.bind(this),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    createButton: HeaderFabButton = {
        click: this.addQualification.bind(this),
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.qualifications.create`),
    };

    ngOnInit() {
        forkJoin([
            this.customerProductService.hasProducts(this.customerId, [ Products.BusinessUnits ]),
            this.employeeService.get(this.customerId, this.employeeId),
        ]).pipe(take(1)).subscribe(([ hasBusinessUnits, employee ]) => {
            if (hasBusinessUnits) {
                this.createButton = {
                    hasPermission: this.createButton.hasPermission,
                    menu: [
                        { text: this.translate.t('ADD_QUALIFICATION', 'company'), click: this.addQualification.bind(this), icon: 'add_circle' },
                        {
                            text: this.translate.t('ADD_QUALIFICATIONS_FROM_UNITS', 'company'),
                            click: this.addQualificationsFromBusinessUnits.bind(this),
                            icon: 'work',
                        },
                    ],
                };
            }

            this.employeeName = employee.name || '';
        });
    }

    updateTable(pagination: DataTablePagination) {
        this.request = this.service.getForEmployee(
            this.employeeId,
            this.customerId,
            pagination);
    }

    addQualificationsFromBusinessUnits() {
        this.matDialog.open<AddEmployeeQualificationsFromBusinessUnitsComponent, EmployeeQualificationsFromBusinessUnitsData, boolean>(AddEmployeeQualificationsFromBusinessUnitsComponent, {
            data: { customerId: this.customerId, employeeId: this.employeeId },
        }).afterClosed().subscribe((r) => {
            if (r) {
                this.dataTable?.refresh();
            }
        });
    }

    addQualification() {
        this.matDialog.open<AddEmployeeQualificationDialogComponent, AddEmployeeQualificationData, Qualification[]>(AddEmployeeQualificationDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: this.employeeId,
            },
        }).afterClosed().subscribe((qualifications) => {
            if (qualifications) {
                this.dataTable?.refresh();
            }
        });
    }

    updateQualification(cell: DataTableButtonCell<Qualification>) {
        this.matDialog.open<AddEmployeeQualificationDialogComponent, AddEmployeeQualificationData, Qualification[]>(AddEmployeeQualificationDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: this.employeeId,
                updatedQualification: cell.item,
            },
        }).afterClosed().subscribe((positions) => {
            cell.disabled.set(false);

            if (positions) {
                this.dataTable?.refresh();
            }
        });
    }

    deleteQualification(cell: DataTableButtonCell<Qualification>) {
        if (cell.item) {
            this.confirmDialog.delete({
                title: this.translate.t('REMOVE_QUALIFICATION', 'company'),
                text: this.translate.t('REMOVE_QUALIFICATION_TEXT', 'company', { name: cell.item.name, employee: this.employeeName }),
            }).afterClosed().subscribe((result) => {
                cell.disabled.set(false);

                if (!result) {
                    return;
                }

                this.service.deleteQualificationFromEmployee(this.customerId, this.employeeId, cell.item.id).subscribe(async () => {
                    this.dataTable?.refresh();
                });
            });
        } else {
            return;
        }
    }

    canCreate() {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.qualifications.create`);
    }

    canEdit(cell: Qualification) {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.qualifications.${cell.id}.update`);
    }

    canDelete(cell: Qualification) {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.qualifications.${cell.id}.delete`);
    }
}
