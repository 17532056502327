import { module } from 'angular';
import { Products } from '../../shared/enums/products';
import { LinksComponent } from '../pages/links/links.component';
import { createState } from '../../shared/utils/create-state';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { DataTableQueryParams } from '../../data-table/data-table.component';

module('eaw.links', [ 'eaw.resource', 'eaw.misc' ]).config([ '$stateProvider', function($stateProvider: any) {
    createState($stateProvider, {
        name: `eaw/app/links`,
        parent: 'eaw/app',
        url: '/links',
        views: {
            'content@': {
                component: LinksComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            queryParams: DataTableQueryParams,
            products: [ Products.Links ],
            permissions: [ `customers.{customer}.hyper_links.*.get` ],
            breadcrumb: { key: 'LINKS', ns: 'navigation' },
        },
    });
} ]);
