// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog>
    <form name="addStaffingTemplateForm">
        <eaw-dialog-header-old heading="'staffing:ADD_TEMPLATE' | i18next"></eaw-dialog-header-old>

        <md-dialog-content>
            <div class="md-dialog-content" layout="column">
                <md-input-container>
                    <label ng-i18next="NAME"></label>
                    <input ng-model="ctrl.templateName" required autocomplete="off">
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="staffing:INPUT"></label>
                    <md-select ng-model="ctrl.templateInput" required>
                        <md-option ng-repeat="input in ::ctrl.validInputs track by $index" ng-value="input.value" ng-bind="input.name"></md-option>
                    </md-select>
                </md-input-container>

                <md-input-container>
                    <label ng-i18next="staffing:OUTPUT"></label>
                    <md-select ng-model="ctrl.templateOutput" required>
                        <md-option ng-repeat="output in ::ctrl.validOutputs track by $index" ng-value="output.value" ng-bind="output.name"></md-option>
                    </md-select>
                </md-input-container>

                <md-input-container ng-if="ctrl.showAverageTray()">
                    <label ng-i18next="staffing:AVERAGE_TRAY"></label>
                    <input ng-model="ctrl.averageTray" type="number" min="0" required autocomplete="off">
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CLOSE"></md-button>
            <md-button ng-click="ctrl.add()" ng-i18next="ADD" ng-disabled="addStaffingTemplateForm.$invalid || ctrl.adding"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.staffing').factory('addStaffingTemplateService', [ '$mdDialog', function($mdDialog) {
    const service = this;

    service.open = () => $mdDialog.show({
        template: template1,
        controllerAs: 'ctrl',
        controller: 'addStaffingTemplateDialogCtrl',
    });

    return service;
} ]);
