// @ts-nocheck
import { find } from 'lodash-es';

import { module } from 'angular';

module('eaw.misc').service('kpiTypeService', [ 'KpiFactory', function(KpiFactory) {
    const srv = this;
    let resource;

    srv.get = (id, force) => {
        const promise = srv.resource(force).$promise;

        if (id) {
            return promise.then((resp) => find(resp.data, { id }));
        }

        return promise.then((resp) => resp.data);
    };

    srv.resource = (force) => {
        if (!resource || force) {
            resource = KpiFactory.getTypes({ per_page: 999 });
            resource.$promise.then((resp) => {
                srv.types = resp.data;
            });
        }

        return resource;
    };

    srv.flush = () => {
        resource = null;
    };

    return srv;
} ]);
