import { AfterContentInit, ChangeDetectionStrategy, Component, computed, DestroyRef, ElementRef, inject, InjectionToken, OnDestroy, OnInit, signal } from '@angular/core';
import { Shift } from 'src/app/scheduling/models/shift';
import { AsyncPipe } from '@angular/common';
import { DateTimePipe } from '../../../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { ProfilePictureComponent } from '../../../../../shared/components/profile-picture/profile-picture.component';
import { ScheduleComponent } from '../../schedule.component';
import { MatIcon } from '@angular/material/icon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressBar } from '@angular/material/progress-bar';
import { BehaviorSubject } from 'rxjs';

export interface ShiftTooltipData {
    shift: Shift;
    customerId: number;
}

export const SHIFT_TOOLTIP_DATA = new InjectionToken<ShiftTooltipData>('SHIFT_TOOLTIP_DATA');

@Component({
    selector: 'eaw-shift-tooltip',
    standalone: true,
    imports: [
        AsyncPipe,
        DateTimePipe,
        TranslatePipe,
        ProfilePictureComponent,
        MatIcon,
        MatProgressBar,
    ],
    templateUrl: './shift-tooltip.component.html',
    styleUrl: './shift-tooltip.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftTooltipComponent implements OnInit, AfterContentInit, OnDestroy {
    protected tooltipData = inject(SHIFT_TOOLTIP_DATA);
    private destroyRef = inject(DestroyRef);
    private elementRef = inject(ElementRef);

    private heightSubject = new BehaviorSubject(0);
    private resizeObserver?: ResizeObserver;

    protected profilePictureUser = computed(this.computeUser.bind(this));
    protected warnings = computed(() => ScheduleComponent.warnings().get(this.shift().id));
    protected periods = computed(() => this.shift().periods);
    protected qualifications = computed(() => this.shift().qualifications);
    protected comments = computed(() => this.shift().comments || []);
    protected shift = signal<Shift>(this.tooltipData.shift);
    protected employee = computed(this.computeEmployee.bind(this));

    ngOnInit() {
        ScheduleComponent.listenForShiftEvent(this.shift().id, 'updated', this.destroyRef).pipe(
            takeUntilDestroyed(this.destroyRef),
        ).subscribe((shift) => this.shift.set(shift));
    }

    ngAfterContentInit() {
        this.heightSubject.next((this.elementRef.nativeElement as HTMLElement).getBoundingClientRect().height);
        this.createResizeObserver();
    }

    ngOnDestroy() {
        this.resizeObserver?.disconnect();
        this.heightSubject.complete();
    }

    private createResizeObserver() {
        this.resizeObserver = new ResizeObserver((entries) => {
            const entry = entries[0];
            if (entry) {
                this.heightSubject.next(entry.contentRect.height);
            }
        });

        this.resizeObserver?.observe(this.elementRef.nativeElement);
    }

    private computeEmployee() {
        const employeeId = this.shift().employeeId;
        return employeeId ? ScheduleComponent.employees().get(employeeId) : undefined;
    }

    private computeUser() {
        const userId = this.employee()?.userId;
        if (!userId) {
            return undefined;
        }

        return {
            id: userId,
            name: this.employee()?.name || null,
        };
    }

    heightChange() {
        return this.heightSubject.asObservable();
    }
}
