<eaw-autocomplete-deprecated [profilePicturePrefix]="true"
                  [label]="label"
                  [observable]="observable"
                  [changeObservable]="changeObservable"
                  [controlName]="controlName"
                  (filterChanged)="updateUsers($event)"
                  (initialFocus)="updateUsers()"
                  [optionTemplate]="customerUserAutocompleteOption">
</eaw-autocomplete-deprecated>


<ng-template #customerUserAutocompleteOption let-user>
    <div class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-8">
        <eaw-profile-picture [size]="32" [user]="user"></eaw-profile-picture>

        <div class="tw-flex tw-flex-col">
            <span>{{user.name}}</span>
        </div>
    </div>
</ng-template>
