import { VariablePayment, VariablePaymentResponse } from './variable-payment';
import { DateTime } from 'luxon';

export type VariablePaymentOverviewResponse = Record<string, Record<string, VariablePaymentResponse[]>>

export class VariablePaymentOverview {
    private overview: Record<number, Record<string, VariablePayment[]>> = {};

    constructor(data: VariablePaymentOverviewResponse) {
        // response is a map of employeeId => [ businessDate => VariablePayment[] ]
        Object.entries(data).forEach(([ id, businessDates ]) => {
            const employeeId = Number(id);

            this.overview[employeeId] = {};
            Object.entries(businessDates).forEach(([ businessDate, variablePayments ]) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.overview[employeeId]![businessDate] = variablePayments.map((variablePayment) => new VariablePayment(variablePayment));
            });
        });
    }

    /**
     * @param itemId - This could either be the ID of an employee or a pay type link
     * @param businessDate
     */
    get(itemId: number, businessDate?: DateTime): VariablePayment[] {
        if (businessDate) {
            return this.overview[itemId]?.[businessDate.toFormat('yyyy-MM-dd')] ?? [];
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.overview[itemId] ? Object.values(this.overview[itemId]!).flat() : [];
    }
}
