import { t } from 'i18next';
import { uniqueId } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { Storage } from '../../../../shared/utils/storage';
import { module } from 'angular';
import { WebsocketService } from 'src/app/shared/services/websocket.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { MessageService } from 'src/app/messages/http/message.service';
import { AngularJsDestroyRef } from 'src/app/shared/angularjs/angularjs-destroy-ref.class';

module('eaw.messages').component('readConversation', {
    template: `<md-content id="thread-read-content">
    <md-toolbar class="default-toolbar">
        <div class="md-toolbar-tools">
            <h2 flex ng-bind="::$conversation.originalMessage.subject"></h2>

            <md-button class="md-icon-button" ng-click="$conversation.showRecipients()">
                <md-tooltip md-direction="left" ng-i18next="messages:SHOW_RECIPIENT_plural"></md-tooltip>
                <md-icon ng-bind="'group'"></md-icon>
            </md-button>

            <md-button class="md-icon-button" ng-if="!$conversation.originalMessage.archivals_count" ng-click="$conversation.archive(true)">
                <md-tooltip md-direction="left" ng-i18next="messages:ARCHIVE_MESSAGE"></md-tooltip>
                <md-icon ng-bind="'archive'"></md-icon>
            </md-button>

            <md-button class="md-icon-button md-accent" ng-if="$conversation.originalMessage.archivals_count" ng-click="$conversation.archive(false)">
                <md-tooltip md-direction="left" ng-i18next="messages:UNARCHIVE_MESSAGE"></md-tooltip>
                <md-icon ng-bind="'unarchive'"></md-icon>
            </md-button>

            <eaw-toggle-btn ng-model="$conversation.displayReadStatus"
                            ng-change="$conversation.toggleReadStatus($conversation.displayReadStatus)"
                            icon="visibility"
                            i18n-tooltip="messages:SHOW_READ_STATUS">
            </eaw-toggle-btn>

            <md-button hide-gt-md class="md-icon-button" ng-click="$conversation.msgConv.convListSideNav.toggle()">
                <md-icon ng-bind="'view_list'"></md-icon>
            </md-button>
        </div>
    </md-toolbar>

    <div id="conversation-window" class="pretty-scroll">
        <md-subheader ng-show="$conversation.thread.total > $conversation.thread.to" ng-click="$conversation.loadMore()">
            <span ng-show="!$conversation.loadingOld" ng-i18next="messages:LOAD_OLDER"></span>
            <md-progress-circular ng-show="$conversation.loadingOld" class="md-progress-center tw-p-0" md-diameter="20"></md-progress-circular>
        </md-subheader>
    </div>

    <messages-files files="$conversation.files"></messages-files>

    <div id="conversation-input">
    
        <wysiwyg-downgrade [height]="220" [resize]="false" ng-show="$conversation.originalMessage.belongsToMe || !$conversation.originalMessage.is_blind" (editor-change)="$conversation.editorChange($event)" (content-change)="$conversation.contentChange($event)"></wysiwyg-downgrade>
   
    <div>
        <md-button class="md-raised md-accent" ng-click="$conversation.sendReply()" ng-i18next="messages:REPLY"></md-button>
        <md-button class="md-raised" ng-click="$conversation.addAttachment()" ng-i18next="messages:ATTACHMENT"></md-button
 </div>
</div>
    </div>

    <button class="display-none"
            id="file-upload-btn"
            ngf-select
            ng-model="$conversation.files"
            ngf-keep="'distinct'"
            ngf-multiple="true">
    </button>
</md-content>
`,
    bindings: {
        originalMessage: '<',
        thread: '<',
        user: '<',
    },
    require: {
        msgConv: '^eawMessageConversations',
    },
    controllerAs: '$conversation',
    controller: [ 'recipientDialog', 'Message', 'WebsocketDowngrade', 'ToastService', '$scope', '$state', 'loadingBar', 'componentCreatorService', 'DowngradedMessageService', function(recipientDialog, Message, WebsocketDowngrade: WebsocketService, ToastService: SnackBarService, $scope, $state, loadingBar, componentCreatorService, DowngradedMessageService: MessageService) {
        // @ts-ignore
        const ctrl = this;
        const displayreadstatusKey = 'messages:thread:displayreadstatus';
        const destroyRef = new AngularJsDestroyRef();

        ctrl.contentChange = (ev: any) => {
            ctrl.replyText = ev;
        }

        ctrl.editorChange = (ev: any) => {
            ctrl.editor = ev;
        }

        ctrl.$onInit = async () => {
            ctrl.messages = ctrl.thread.data.reverse();
            ctrl.displayReadStatus = await Storage.getItem(Storage.prefix(displayreadstatusKey)) ?? true;

            WebsocketDowngrade.listenMessageConversation(ctrl.originalMessage.id, 'new_message', ctrl.wsNewMessage, destroyRef);
            WebsocketDowngrade.listenMessageConversation(ctrl.originalMessage.id, 'message_confirmed', ctrl.wsMessageConfirmed, destroyRef);
            WebsocketDowngrade.listenMessageConversation(ctrl.originalMessage.id, 'message_attachment', ctrl.wsMessageAttachment, destroyRef);
        };
        ctrl.$postLink = () => {
            ctrl.convWindow = document.getElementById('conversation-window');
            ctrl.convObserver = new MutationObserver((records) => {
                records.forEach((rec) => {
                    const target = rec.target as any;
                    const item = target.closest('conversation-item');
                    if (item === ctrl.convWindow.lastElementChild) {
                        ctrl.convWindow.scrollTop = 999999;
                    }
                });
            });
            // Start observing
            ctrl.convObserver.observe(ctrl.convWindow, {
                subtree: true,
                childList: true,
            });
            ctrl.toggleReadStatus(ctrl.displayReadStatus);
            ctrl.renderMessages();
        };
        ctrl.$onDestroy = () => {
            destroyRef.destroy();
        };
        ctrl.wsMessageRead = (ev: any) => {
            ev.ids.forEach((id: any) => {
                const message = ctrl.messages.find((m: any) => m.id === id);
                if (message) {
                    $scope.$evalAsync(() => {
                        message.read_count = message.read_count || 0;
                        message.read_count += 1;
                        ctrl.messages.splice(ctrl.messages.findIndex((m: any) => m.id === id), 1, message);
                    });
                } else {
                    ctrl.reads = ctrl.reads || {};
                    ctrl.reads[id] = ctrl.reads[id] || {};
                    ctrl.reads[id][ev.user_id] = true;
                }
            });
        };
        ctrl.wsNewMessage = (ev: any) => {
            if (ev.user_id === ctrl.user.id) {
                return;
            }
            // Add myself as one of the recipients
            ev.message_recipients = [
                {
                    confirmed_at: null,
                    read_at: null,
                    user_id: ctrl.user.id,
                },
            ];
            ctrl.addMessage(ev);
        };
        ctrl.wsMessageConfirmed = (ev: any) => {
            const message = ctrl.messages.find((m: any) => m.id === ev.id);
            if (!message) {
                return;
            }
            $scope.$evalAsync(() => {
                message.confirmed_count += 1;
                ctrl.messages.splice(ctrl.messages.findIndex((m: any) => m.id === ev.id), 1, message);
            });
        };
        ctrl.wsMessageAttachment = (ev: any) => {
            const message = ctrl.messages.find((m: any) => m.id === ev.message.id);
            if (!message) {
                return;
            }
            if (message.belongsToMe) {
                return;
            }
            $scope.$evalAsync(() => {
                message.attachments = message.attachments || [];
                message.attachments.push(ev.attachment);
                ctrl.messages.splice(ctrl.messages.findIndex((m: any) => m.id === message.id), 1, message);
            });
        };
        ctrl.renderMessages = () => {
            Array.from(ctrl.convWindow.querySelectorAll('conversation-item')).forEach((el: any) => el.remove());
            ctrl.messages.forEach(ctrl.renderMessage);
        };
        ctrl.renderMessage = (msg: any, replaceEl: any, prepend: any) => {
            const comp = componentCreatorService.create({
                name: 'conversationItem',
                bindings: {
                    message: msg,
                },
            });
            comp.element.dataset.messageId = msg.id;
            const oldChild = replaceEl || ctrl.convWindow.querySelector(`[message-id="${msg.id}"]`);
            if (oldChild instanceof HTMLElement) {
                ctrl.convWindow.replaceChild(comp.element, oldChild);
            } else if (typeof prepend === 'boolean' && prepend) {
                ctrl.convWindow.insertBefore(comp.element, ctrl.convWindow.children[1]);
            } else {
                ctrl.convWindow.appendChild(comp.element);
            }
            return comp.element;
        };
        ctrl.loadMore = async () => {
            if (ctrl.loadingOld) {
                return;
            }
            const oldScroll = ctrl.convWindow.scrollHeight;
            ctrl.loadingOld = true;
            ctrl.thread = await Message.getThread(ctrl.originalMessage.id, ctrl.thread.current_page + 1).$promise;
            ctrl.loadingOld = false;
            ctrl.messages = ctrl.thread.data.concat(ctrl.messages);
            ctrl.thread.data.forEach((msg: any) => {
                ctrl.addMessage(msg, null, true);
            });
            setTimeout(() => {
                const newScroll = ctrl.convWindow.scrollHeight;
                ctrl.convWindow.scrollTop = newScroll - oldScroll;
            });
        };
        ctrl.addAttachment = () => {
            document.getElementById('file-upload-btn')?.click();
        };
        ctrl.showRecipients = () => {
            recipientDialog.open(ctrl.originalMessage);
        };
        ctrl.toggleReadStatus = (status: any) => {
            void Storage.setItem(Storage.prefix(displayreadstatusKey), status);
            const action = status ? 'remove' : 'add';
            ctrl.convWindow.classList[action]('no-read-status');
        };
        ctrl.addMessage = (msg: any, tempEl: any, prepend: any) => {
            ctrl.messages = ctrl.messages.filter((m: any) => m.id !== msg.id);
            ctrl.messages.push(new Message(msg));
            return ctrl.renderMessage(msg, tempEl, prepend);
        };
        ctrl.sendReply = () => {
            if (!ctrl.replyText) {
                return ToastService.tToast('messages:WRITE_STH');
            }
            const replyText = ctrl.replyText.trim();
            ctrl.replyText = '';
            ctrl.editor.setContent('')
            const uniqueIdentifier = uniqueId();
            const msg = {
                sending: true,
                body: replyText,
                user_id: ctrl.user.id,
                user: ctrl.user,
                id: uniqueIdentifier,
                confirmed_count: 0,
                read_count: 0,
            };
            // Create thread object while we wait for message to finish
            const tempMsgEl = ctrl.addMessage(msg);
            Message.sendMessage(ctrl.originalMessage.subject, replyText, { thread_id: ctrl.originalMessage.id }, [ 'recipients' ]).$promise.then((message: any) => {
                message.confirmed_count = 0;
                message.read_count = 0;
                if (ctrl.reads?.[message.id]) {
                    Object.keys(ctrl.reads[message.id]).forEach((userId) => {
                        message.read_count = message.read_count || 0;
                        message.read_count += 1;
                        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                        delete ctrl.reads[message.id][userId];
                    });
                }
                // Add user (me) to data
                message.user = ctrl.user;
                // Replace placeholder with backend data
                ctrl.addMessage(message, tempMsgEl);
                if (Object.keys(ctrl.files || []).length) {
                    message.attachments = [];
                    message.uploadingAttachments = ctrl.files.length;
                    const promises = ctrl.files.map((file: any) => Message.uploadAttachment(message, file));
                    ctrl.files = [];
                    Promise.all(promises).then((attachments) => {
                        attachments.forEach((a) => message.attachments.push(a.data));
                    }).finally(() => delete message.uploadingAttachments);
                }
            }).catch(() => {
                const i = ctrl.messages.findIndex((m: any) => m.id === msg.id);
                ctrl.messages[i].error = true;
                ctrl.addMessage(ctrl.messages[i], tempMsgEl);
            });
        };
        // @ts-ignore
        this.archive = function(archive: any) {
            const observable = DowngradedMessageService.archive(this.originalMessage.id, archive);
            let count, message, state: any
            if (archive) {
                message = 'messages:ARCHIVING_MESSAGE';
                count = 1;
                state = `eaw/app/messages/conversations/write`;
            } else {
                message = 'messages:UNARCHIVING_MESSAGE';
                count = 0;
            }
            loadingBar.open(null, false, t(message)).then(() => {
                if (state) {
                    $state.go(`eaw/app/messages/conversations/write`, $state.params, { reload: true });
                } else {
                    $state.reload();
                }
            });
            observable.subscribe(() => {
                this.originalMessage.archivals_count = count;
                loadingBar.close(true);
            });
        };
    } ],
});
