import { ApiResponse } from '../../shared/interfaces/api-response';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { Comment, CommentResponse } from './comment';

export interface CommentSetResponse extends ApiResponse {
    endDate?: string;
    startDate?: string;
    date?: string;
    comments: CommentResponse[];
}

export class CommentSet {
    comments: Comment[];
    date?: DateTime;

    constructor(data: CommentSetResponse) {
        this.comments = data.comments ? data.comments.map((comment) => new Comment(comment)) : [];
        if (data.date) {
            this.date = stringToDateTime(data.date);
        }
    }
}
