import { ApiResponse } from '../../shared/interfaces/api-response';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { BaseApiModel } from '../../shared/models/base-api-model';
import { SsoServiceService } from '../http/sso-service.service';

export interface SsoServiceResponse extends ApiResponse {
    id: number;
    name: string;
    description: string;
    endpoint: string;
    pivot: { subscriber_id: number, sso_service_id: number, subscriber_type: 'group' | 'customer' };
    token_class: string;
    created_at: string;
    updated_at: string;
}

export class SsoService extends BaseApiModel<SsoServiceResponse, SsoServiceService> {
    id: number;
    name: string;
    description: string;
    endpoint: string;
    pivot: SsoServiceResponse['pivot'];
    tokenClass: string;
    createdAt: DateTime;
    updatedAt: DateTime;

    constructor(data: SsoServiceResponse) {
        super(data, undefined);

        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.endpoint = data.endpoint;
        this.pivot = data.pivot;
        this.tokenClass = data.token_class;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
    }
}
