// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.checklists').component('percent', {
    template: '{{$ctrl.percent}}',
    require: {
        ngModel: 'ngModel',
    },
    controller: [ '$filter', function($filter) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.ngModel.$formatters.unshift(ctrl.formatModel);
        };

        ctrl.formatModel = (val) => {
            ctrl.percent = val < 1 ? $filter('percent')(val) : t('checklists:COMPLETED');
        };
    } ],
});
