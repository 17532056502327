// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';

module('eaw.payroll.paid-time').controller('vacationTooltipCtrl', function vacationTooltipCtrl() {
    // @ts-ignore
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.fromTo = t('FROM_TO', {
            from: ctrl.vacation.from.format('ll'),
            to: ctrl.vacation.to.format('ll'),
        });
    };
});
