import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';
import { DateTimeConverter } from '../../shared/utils/date-time-converter';
import { CustomFieldValueWithInterval } from '../../shared/utils/custom-fields-group';
import { FamilyMember, FamilyMemberResponse } from '../models/family-member';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { map } from 'rxjs';

export type FamilyMemberRelation = 'spouse' | 'child';

interface CreateFamilyMemberData {
    name: string;
    birth_date: DateTime;
    relation: FamilyMemberRelation;
}

@Injectable({
    providedIn: 'root',
})
export class FamilyMemberService {

    constructor(
        @Inject(HttpClient) private readonly http: HttpClient,
    ) {
    }

    getForEmployee(customerId: number, employeeId: number) {
        return this.http.get<FamilyMemberResponse[]>(`/customers/${customerId}/employees/${employeeId}/family_members_v2`).pipe(
            map((response) => response.map((item) => new FamilyMember(item))),
        );
    }

    create(customerId: number, employeeId: number, data: CreateFamilyMemberData, customFields: Record<string, CustomFieldValueWithInterval>) {
        return this.http.post<FamilyMemberResponse>(`/customers/${customerId}/employees/${employeeId}/family_members_v2`, {
            ...data,
            birth_date: DateTimeConverter.convertDateTimeToBusinessDate(data.birth_date),
            custom_fields: customFields,
        }).pipe(classifyItem(FamilyMember));
    }

    update(customerId: number, employeeId: number, familyMemberId: number, data: CreateFamilyMemberData, customFields: Record<string, CustomFieldValueWithInterval>) {
        return this.http.put<FamilyMemberResponse>(`/customers/${customerId}/employees/${employeeId}/family_members_v2/${familyMemberId}`, {
            ...data,
            birth_date: DateTimeConverter.convertDateTimeToBusinessDate(data.birth_date),
            custom_fields: customFields,
        }).pipe(classifyItem(FamilyMember));
    }

    delete(customerId: number, employeeId: number, familyMemberId: number) {
        return this.http.delete(`/customers/${customerId}/employees/${employeeId}/family_members_v2/${familyMemberId}`);
    }
}
