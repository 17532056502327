<eaw-page-header>
    <span title>{{ 'MY_BALANCES' | translate | async }}</span>
    <span subtitle>{{ 'MY_BALANCES_SUBTITLE_HINT' | translate:'balances' | async }}</span>
</eaw-page-header>

@if (loading) {
    <eaw-info-loading size="lg"/>
} @else if (noTypes) {
    <div class="tw-flex tw-items-center">
        <span class="mat-subtitle-1">{{ 'FAILED_BALANCES' | translate: 'balances' | async }}</span>
    </div>
} @else {
    <div id="list">
        @for (item of items; track item.balanceType.balanceCode) {
            <div class="item" (click)="openChanges(item)">
                <div class="text">
                    <div class="name mat-body-2">{{item.name}}</div>
                    <div class="description mat-body-1">{{item.description}}</div>
                </div>

                <div class="mat-subtitle-1 tw-m-0">{{item.formattedBalance}}</div>
            </div>
        }
    </div>
}
