import { SignatureProviderType } from '../../hr/enums/SignatureProviderType';
import { ApiModelClass } from '../enums/api-model';
import { ApiResponse } from '../interfaces/api-response';

export interface SignableResponse extends ApiResponse {
    customer_id: number;
    filter: Record<'type_id', number>;
    id: number;
    model: ApiModelClass;
    provider: SignatureProviderType;
    restricted: boolean;
}

export class Signable {
    customerId: number;
    filter: Record<'type_id', number>;
    id: number;
    model: ApiModelClass;
    provider: SignatureProviderType;
    restricted: boolean;

    constructor(data: SignableResponse) {
        this.customerId = data.customer_id;
        this.filter = data.filter;
        this.id = data.id;
        this.model = data.model;
        this.provider = data.provider;
        this.restricted = data.restricted;
    }
}
