<eaw-page-header useBigFilter>
    <span title>{{ 'ANNUAL_NIGHT_HOURS_REPORT_PAGE' | translate: 'analytics' | async }}</span>

    <div bigFilter>
        <form [formGroup]="form" class="tw-flex tw-flex-row tw-flex-wrap tw-gap-x-16">
            <mat-form-field>
                <mat-label>{{ 'YEAR' | translate | async }}</mat-label>
                <mat-select formControlName="year">
                    @for (year of minYear.until(maxYear).splitBy({ years: 1 }).reverse(); track year.start?.year) {
                        <mat-option [value]="year.start?.year">{{ year.start?.year }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <eaw-autocomplete
                    formControlName="employees"
                    [options]="employeeAutocompleteService.options"
                    [getter]="employeeAutocompleteService.getter({customerId})"
                    [setter]="employeeAutocompleteService.setter({customerId})"/>
        </form>

        <button mat-raised-button [disabled]="form.invalid" color="primary" (click)="submit()">{{ 'SUBMIT' | translate | async }}</button>
    </div>
</eaw-page-header>

<mat-card>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    @if (data) {
        <mat-card-content class="tw-overflow-auto">
            <table>
                <thead>
                <tr>
                    <th>{{ 'EMPLOYEE_NUMBER' | translate: 'company' | async }}</th>
                    <th>{{ 'FIRST_NAME' | translate | async }}</th>
                    <th>{{ 'LAST_NAME' | translate | async }}</th>
                    <th>{{ 'TITLE' | translate | async }}</th>
                    <th class="tw-whitespace-normal">{{ 'LEVEL' |translate: 'custom_fields' | async }} / {{ 'ECHELON' | translate: 'custom_fields' | async }}</th>
                    <th>{{ 'EFFECTIVE_DATE' | translate: 'reports' | async }}</th>
                    <th></th>
                    @for (month of months; track month) {
                        <th class="month-header">{{ month }}</th>
                    }
                    <th class="tw-text-end">{{ 'TOTAL' | translate | async }}</th>
                </tr>
                </thead>

                <tbody>
                    @for (employee of data | keyvalue; track employee.key) {
                        <tr class="upper-row">
                            <td rowspan="2">{{ employee.value.employee_number }}</td>
                            <td rowspan="2">{{ employee.value.first_name }}</td>
                            <td rowspan="2">{{ employee.value.last_name }}</td>
                            <td rowspan="2">{{ employee.value.title }}</td>
                            <td rowspan="2">{{ employee.value.level + ' ' + employee.value.grade }}</td>
                            <td rowspan="2">{{ formatEffectiveDate(employee.value.effective_date) | DateTime: 'DATE_SHORT' }}</td>
                            <td>{{ 'NIGHT_HOURS' | translate | async }}</td>

                            @for (month of months; track month) {
                                <td class="tw-text-center">{{ (employee.value.hours_per_month[$index + 1] || 0) | eawNumber }}</td>
                            }

                            <td class="total">{{ employee.value.total_hours | eawNumber }}/ {{ employee.value.annual_threshold | eawNumber }}</td>
                        </tr>

                        <tr class="lower-row">
                            <td>{{ 'REST_COMPENSATION' | translate: 'france_payroll' | async }}</td>

                            @for (month of months; track month) {
                                <td class="tw-text-center">{{ getCompensatedHours(employee.value, $index + 1) | eawNumber }}</td>
                            }

                            <td class="total">{{ getCompensatedHours(employee.value) | eawNumber }}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </mat-card-content>
    } @else {
        <mat-card-content>
            <mat-card-title>{{ 'NO_DATA' | translate | async }}</mat-card-title>
        </mat-card-content>
    }
</mat-card>
