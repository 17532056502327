import { ChangeDetectionStrategy, Component, computed, effect, input, Signal, signal } from '@angular/core';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { Subject } from 'rxjs';
import { WeatherForecast } from '../../classes/weather-forecast';
import { DateTime } from 'luxon';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { TemperatureComponent } from '../temperature/temperature.component';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MetNoCreditComponent } from '../met-no-credit/met-no-credit.component';
import { WeatherForecastTime } from '../../classes/weather-forecast-time';
import { WeatherForecastTimeSeries } from '../../interfaces/weather-forecast';

type WeatherForecastRow = {
    border: boolean;
    day: string;
    time: Signal<DateTime>;
    temperature?: WeatherForecastTime;
    precipitation?: number;
    wind_speed?: number;
    // Wind direction in degrees
    wind_direction?: number;
}

@Component({
    selector: 'eaw-weather-forecast',
    standalone: true,
    imports: [
        MatTable,
        MatColumnDef,
        MatHeaderCell,
        MatCell,
        MatCellDef,
        MatHeaderCellDef,
        TranslatePipe,
        AsyncPipe,
        MatHeaderRow,
        MatRow,
        MatRowDef,
        MatHeaderRowDef,
        NumberPipe,
        TemperatureComponent,
        DateTimePipe,
        MetNoCreditComponent,
    ],
    templateUrl: './weather-forecast.component.html',
    styleUrl: './weather-forecast.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherForecastComponent {
    customerId = input.required<number>();
    fahrenheit = input<boolean>();
    forecast = input<WeatherForecast>();

    protected dataSource: CdkTableDataSourceInput<WeatherForecastRow>;
    private dataSubject: Subject<WeatherForecastRow[]> = new Subject<WeatherForecastRow[]>();
    columns: string[];
    unit = computed(() => this.fahrenheit() ? 'F' : 'C');
    morning = signal<DateTime>(DateTime.now().set({ hour: 6, minute: 0, second: 0 }));
    afternoon = signal<DateTime>(DateTime.now().set({ hour: 12, minute: 0, second: 0 }));
    evening = signal<DateTime>(DateTime.now().set({ hour: 18, minute: 0, second: 0 }));
    night = signal<DateTime>(DateTime.now().set({ hour: 0, minute: 0, second: 0 }));

    constructor() {
        this.dataSource = this.dataSubject.asObservable();
        this.columns = [
            'time',
            'temperature',
            'rain',
            'wind',
        ];

        effect(this.getWeatherForecast.bind(this));
    }

    private getWeatherForecast() {
        const today = DateTime.now().startOf('day');
        const rows: WeatherForecastRow[] = [];

        const forecast = this.forecast();
        if (!forecast) {
            return;
        }

        const end = today.plus({ days: 7 });
        let date = today;
        while (date < end) {
            date = date.plus({ hours: 6 });
            const entry = forecast.getClosestEntry(date);
            const forecastTime = this.getForecastTime(entry);
            if (forecastTime) {
                rows.push({
                    border: date.hour < 6,
                    day: date.toFormat('cccc'),
                    time: date.hour < 6 ? this.night: date.hour < 12 ? this.morning : date.hour < 18 ? this.afternoon : this.evening,
                    temperature: forecastTime,
                    wind_speed: entry?.data.instant.details.wind_speed as number ?? undefined,
                    wind_direction: entry?.data.instant.details.wind_from_direction as number ?? undefined,
                    precipitation: forecastTime?.precipitationAmount,
                });
            }
        }

        this.dataSubject.next(rows);
    }

    private getForecastTime(time?: WeatherForecastTimeSeries): WeatherForecastTime | undefined {
        if (!time) {
            return undefined;
        }

        const t = new WeatherForecastTime(time);
        if (this.fahrenheit()) {
            t.convertToFahrenheit();
        }

        return t;
    }
}
