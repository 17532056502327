import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { WarningsDialogData } from './warnings-dialog.service';
import { DateTime } from 'luxon';
import { WarningService } from '../../http/warning.service';
import { CausesDialogComponent, CausesDialogData } from '../causes-dialog/causes-dialog.component';
import { DialogComponent, DialogSize } from '../dialog-component';
import { catchError, forkJoin, of } from 'rxjs';
import { DateTimePipe } from '../../pipes/date-time.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

interface ListItem {
    id?: number;
    customerId?: number;
    time: DateTime | null;
    message: string;
    messageParameters: Record<string, string>;
    hasCauses: boolean;
}

@Component({
    selector: 'eaw-warnings-dialog',
    templateUrl: './warnings-dialog.component.html',
    styleUrl: './warnings-dialog.component.scss',
    standalone: true,
    imports: [
        NgIf,
        DialogHeaderComponent,
        MatDialogContent,
        MatProgressSpinnerModule,
        NgFor,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class WarningsDialogComponent extends DialogComponent implements OnInit {
    loading = false;
    items: ListItem[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: WarningsDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<WarningsDialogComponent, undefined>,
        @Inject(WarningService) public warningService: WarningService,
        @Inject(MatDialog) public dialog: MatDialog,
    ) {
        data.size ||= DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit(): void {
        this.loading = true;

        const warnings = this.data.warnings ?? of([]);
        const infractions = this.data.infractions ?? of([]);

        forkJoin([
            warnings.pipe(catchError(() => of([]))),
            infractions.pipe(catchError(() => of([]))),
        ]).subscribe(([ warnings, infractions ]) => {
            this.loading = false;

            warnings.forEach((w) => {
                this.items.push({
                    id: w.id,
                    customerId: w.customerId,
                    time: w.updatedAt,
                    message: w.message,
                    messageParameters: w.messageParameters,
                    hasCauses: Array.isArray(w.causes) && w.causes.length > 0 || w.causes === undefined,
                });
            });

            infractions.forEach((i) => {
                this.items.push({
                    time: i.time,
                    message: i.message,
                    messageParameters: {},
                    hasCauses: true,
                });
            });
        });
    }

    openCauseDialog(item: ListItem) {
        if (!item.customerId) {
            return;
        }
        if (!item.id) {
            return;
        }

        this.dialog.open<CausesDialogComponent, CausesDialogData, boolean>(CausesDialogComponent, {
            data: {
                customerId: item.customerId,
                warningId: item.id,
            },
        });
    }
}
