<eaw-dialog-header [showCloseButton]="true">
    <span title>{{'FLEXITIME' | translate:'payroll' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" layout="column" class="tw-flex tw-flex-col tw-gap-12">
        <div class="tw-flex tw-mb-12">
            <mat-radio-group formControlName="addTime" class="tw-flex tw-flex-col">
                <mat-radio-button [value]="1">{{ 'ADD_TIME' | translate: 'payroll' | async }}</mat-radio-button>
                <mat-radio-button [value]="-1">{{ 'DEDUCT_TIME' | translate: 'payroll' | async }}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="tw-flex tw-flex-row">
            <mat-form-field class="tw-w-full" eawDatePickerOptions>
                <mat-label>{{'BUSINESS_DATE' | translate | async}}</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="businessDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="tw-flex tw-flex-row">
            <mat-form-field class="tw-basis-1/2 tw-mr-4">
                <mat-label>{{'HOUR_plural' | translate | async}}</mat-label>
                <input matInput type="number" formControlName="hours" placeholder="0" step="1">
            </mat-form-field>

            <mat-form-field class="tw-basis-1/2 tw-ml-4">
                <mat-label>{{'MINUTE_plural' | translate | async}}</mat-label>
                <input matInput type="number" formControlName="minutes" placeholder="0" step="1">
            </mat-form-field>
        </div>
        <div class="tw-flex tw-flex-row">
            <mat-form-field class="tw-w-full">
                <mat-label>{{'COMMENT' | translate | async}}</mat-label>
                <textarea matInput formControlName="comment" rows="2"></textarea>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions layout="row">
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
    <button mat-raised-button type="submit" color="primary" [disabled]="form.invalid || form.disabled" (click)="add()">{{'SUBMIT' | translate | async}}</button>
</mat-dialog-actions>
