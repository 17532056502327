<eaw-widget-settings-dialog [widgetInfo]="data.widgetInfo" [valid]="form.valid" (submitted)="save()" [loading]="loading()">
    <form content [formGroup]="form" class="tw-flex tw-flex-col tw-gap-16">
        <mat-form-field>
            <mat-label>{{'BIRTHDAY_FUTURE_DAYS' | translate: 'widgets' | async}}</mat-label>
            <input matInput type="number" formControlName="days">
            <mat-hint>{{'ONLY_BIRTHDAYS_TODAY_HINT' | translate: 'widgets' | async}}</mat-hint>
            <mat-error><eaw-field-error [control]="form.controls.days"/></mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'LOCATION_plural' | translate | async}}</mat-label>
            <mat-select formControlName="locations" multiple>
                @for (location of locations(); track location.id) {
                    <mat-option [value]="location.id">{{location.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</eaw-widget-settings-dialog>
