import { Shift, ShiftResponse } from '../../scheduling/models/shift';
import { Timepunch, TimepunchResponse } from '../../payroll/models/timepunch';
import { Absence, AbsenceResponse } from '../../absence/models/absence';
import { OffTime, OffTimeResponse } from '../../vacations/models/off-time';
import { ApiResponse } from '../interfaces/api-response';

export type WarningCauseType = 'shift' | 'timepunch' | 'absence' | 'off_time';

export interface WarningCauseResponse extends ApiResponse {
    cause_id: number
    cause_type: WarningCauseType
    warning_id: number
    cause: ShiftResponse | TimepunchResponse | AbsenceResponse | OffTimeResponse;
}

export class WarningCause {
    causeId: number;
    causeType: WarningCauseType;
    warningId: number;
    shift?: Shift;
    timepunch?: Timepunch;
    absence?: Absence;
    offTime?: OffTime;

    constructor(data: WarningCauseResponse) {
        this.causeId = data.cause_id;
        this.causeType = data.cause_type;
        this.warningId = data.warning_id;

        if (this.causeType === 'shift') {
            this.shift = new Shift(data.cause as ShiftResponse);
        }
        if (this.causeType === 'timepunch') {
            this.timepunch = new Timepunch(data.cause as TimepunchResponse);
        }
        if (this.causeType === 'absence') {
            this.absence = new Absence(data.cause as AbsenceResponse);
        }
        if (this.causeType === 'off_time') {
            this.offTime = new OffTime(data.cause as OffTimeResponse);
        }
    }
}
