import { computed, signal } from '@angular/core';

type Subtitle = Promise<string> | null;

/**
 * This class is used for components that provide extended information for widgets.
 *
 * Most likely mini widgets that you click, and you can open a dialog with more information.
 */
export abstract class ExtendedWidgetInfo {
    private subtitle = signal<Subtitle>(null);
    private error = signal<undefined | Promise<string>>(undefined);
    private _loading = signal(false);
    /**
     * Computed signal so that the loading state can be used in the template, but subclasses need
     * to use the setter to update the value.
     */
    protected loading = computed(() => this._loading());

    setError(error: Promise<string>) {
        this.error.set(error);
    }

    setLoading(loading: boolean) {
        this._loading.set(loading);
    }

    updateSubtitle(subtitle: Subtitle) {
        this.subtitle.set(subtitle);
    }

    getSubtitle() {
        return this.subtitle();
    }
}
