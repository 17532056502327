import { module } from 'angular';
import { IScheduleSidebar } from './schedule-sidebar.class';
import { ScheduleDays } from '../services/days.service';

module('eaw.scheduling').component('scheduleSidebar', {
    template: `<div id="sidebar-active-component" ng-if="$sidebar.activeBar">
    <div class="active-component-header">
        <md-icon ng-bind="$sidebar.activeBar.icon"></md-icon>
        <span ng-i18next="{{$sidebar.activeBar.text}}" flex></span>
        <md-icon ng-if="$sidebar.smallBar" ng-click="$sidebar.scheduleView.smallSidenav.close()" ng-bind="'close'"></md-icon>
    </div>

    <day-selector ng-model="$sidebar.day"
                  ng-if="$sidebar.activeBar.daySelector"
                  days="::$sidebar.scheduleDays"
                  hide-date="$sidebar.schedule.is_template">
    </day-selector>

    <week-selector ng-model="$sidebar.week"
                   ng-if="$sidebar.activeBar.weekSelector"
                   days="::$sidebar.scheduleDays"
                   hide-week="$sidebar.schedule.is_template">
    </week-selector>

    <component-creator id="sched-sidebar-component"
                       ng-if="$sidebar.week || $sidebar.day"
                       class="pretty-scroll"
                       component-name="{{$sidebar.activeBar.component}}"
                       transclude-controllers="$sidebar.transcludeControllers"
                       bindings="$sidebar.componentBindings">
    </component-creator>
</div>

<div id="sidebar-options" ng-if="!$sidebar.smallBar">
    <md-button class="md-fab md-mini"
               ng-repeat="button in ::$sidebar.sidebar.buttons track by $index"
               eaw-permission
               permission-children-filter="button.permissionChildrenFilter"
               ng-class="{'active': button._active}"
               ng-click="button.callback()">
        <md-icon ng-bind="button.icon" md-colors="{color: button.color}">
            <md-tooltip md-direction="left" ng-bind="::button.text | i18next"></md-tooltip>
        </md-icon>
    </md-button>

    <md-button class="md-fab md-mini"
               ng-repeat="sidebar in ::$sidebar.sidebar.sidebars track by $index"
               eaw-permission
               permission-children-filter="sidebar.permissionChildrenFilter"
               ng-class="{'active': sidebar._active}"
               ng-click="$sidebar.openBar(sidebar)">
        <md-icon ng-bind="sidebar.icon" md-colors="{color: sidebar.color}">
            <md-tooltip md-direction="left" ng-bind="::sidebar.text | i18next"></md-tooltip>
        </md-icon>
    </md-button>
</div>
`,
    require: {
        scheduleView: '^scheduleView',
    },
    bindings: {
        smallBar: '<?',
    },
    controllerAs: '$sidebar',
    controller: [
        'scheduleDays', 'ScheduleSidebar', 'scheduleMode', 'scheduleTable', 'PermissionCheckServiceDowngrade',
        function(scheduleDays: ScheduleDays, ScheduleSidebar, scheduleMode, scheduleTable) {
            // @ts-ignore
            const ctrl = this;

            ctrl.$onInit = () => {
                ctrl.scheduleDays = scheduleDays.getAll();
                ctrl.day = ctrl.scheduleDays[0];
                ctrl.schedule = ctrl.scheduleView.schedule;
                ctrl.transcludeControllers = {
                    scheduleSidebar: { instance: ctrl },
                    scheduleView: { instance: ctrl.scheduleView },
                };
            };

            ctrl.$postLink = async () => {
                ctrl.sidebar = new ScheduleSidebar(ctrl.schedule, scheduleMode.getMode() !== 'read-only') as IScheduleSidebar;

                ctrl.checkSmallBar();
            };

            ctrl.checkSmallBar = () => {
                if (ctrl.smallBar) {
                    ctrl.smallBar._active = false; // Open will toggle this
                    ctrl.openBar(ctrl.smallBar);
                }
            };

            ctrl.openBar = (bar: any) => {
                bar._active = !bar._active;
                ctrl.activeBar = null;

                ctrl.day = scheduleTable.getCurrentDay();
                ctrl.sidebar.sidebars.filter((s: any) => s !== bar).forEach((s: any) => s._active = false);
                ctrl.sidebar.buttons.filter((b: any) => b !== bar).forEach((b: any) => b._active = false);

                if (bar._active) {
                    ctrl.activeBar = bar;

                    if (bar.upgrade) {
                        ctrl.componentBindings = {
                            stackId: [ ctrl.schedule.customer.stack_id, 'upgrade' ],
                            customerId: [ ctrl.schedule.customer.id, 'upgrade' ],
                            scheduleId: [ ctrl.schedule.id, 'upgrade' ],
                            isTemplate: [ ctrl.schedule.is_template, 'upgrade' ],
                        };
                    } else {
                        ctrl.componentBindings = {
                            schedule: ctrl.schedule,
                            days: scheduleDays.getAll(),
                            maxDate: ctrl.schedule.getTo(),
                            from: ctrl.schedule.getFrom(),
                            to: ctrl.schedule.getTo(),
                            employee: ctrl.scheduleView.employee,
                            customerId: ctrl.schedule.customer_id,
                            customer: ctrl.schedule.customer,
                            readOnly: scheduleMode.getMode() === 'read-only',
                        };
                    }
                }
            };
        },
    ],
});
