<eaw-dialog-header>
    <span title>{{ 'APPROVE_TIME_OFF' | translate:'vacation' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="loading" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content *ngIf="!loading">
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-8">
        <mat-form-field *ngIf="hasScheduling">
            <mat-label>{{ 'ACTION' | translate | async }}</mat-label>

            <mat-select formControlName="action" *ngIf="shifts">
                <mat-option *ngFor="let action of actions" [value]="action.value">{{ action.text | async }}</mat-option>
            </mat-select>

            <mat-select disabled *ngIf="!shifts">
                <mat-option selected></mat-option>
            </mat-select>

            <mat-hint *ngIf="shifts">{{ 'FOUND_X_SHIFTS' | translate:'vacation':{count: shifts} | async }}</mat-hint>
            <mat-hint *ngIf="!shifts">{{ 'NO_SHIFT_NO_ACTION' | translate:'vacation' | async }}</mat-hint>
        </mat-form-field>

        <ng-container *ngIf="!hasSwitzerland || displayVacationQuestion">
            <mat-form-field>
                <mat-label>{{ 'VACATION_QUESTION' | translate:'vacation' | async }}</mat-label>
                <mat-select formControlName="vacation">
                    <mat-option [value]="'auto'">{{ 'VACATION_HANDLE_AUTO' | translateSync:'vacation' }}</mat-option>
                    <mat-option [value]="'manual'">{{ 'VACATION_HANDLE_MANUAL' | translateSync:'vacation' }}</mat-option>
                    <mat-option [value]="'no'">{{ 'VACATION_HANDLE_NO' | translateSync:'vacation' }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="form.controls.vacation.value === 'manual'">
                <mat-label>{{ 'MANUAL_DAY_CHANGE' | translate:'vacation' | async }}</mat-label>
                <input matInput formControlName="days" autocomplete="off" type="number" min="0" step="1" [max]="maxDays">
                <mat-hint>{{ 'MANUAL_DAY_CHANGE_HELP' | translate:'vacation':{count: maxDays} | async }}</mat-hint>
            </mat-form-field>
        </ng-container>

        <mat-form-field>
            <mat-label>{{ 'COMMENT' | translate | async }}</mat-label>
            <textarea formControlName="comment" matInput rows="2"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [loading]="false" [disabled]="form.invalid || loading || form.disabled" (click)="submit()">{{ 'APPROVE' | translate | async }}</eaw-action-button>
</mat-dialog-actions>
