import { Injectable } from '@angular/core';
import { Me, MeCustomer } from '../models/me';
import { Employee } from '../models/employee';
import { Storage } from '../utils/storage';
import { createInstance } from 'localforage';
import { environment } from '../../../environments/environment';
import { Customer } from '../models/customer';

/**
 * Type that says where to store something in the local database
 *
 * **Default** means it can be accessed from any location
 *
 * **Location** is for each individual location
 */
export type StoreIn = 'default' | 'location';

@Injectable({
    providedIn: 'root',
})
export class CurrentService {
    private me!: Me;
    private customer!: Customer;
    private employee?: Employee;
    private defaultStorage!: LocalForage;
    private locationStorage!: LocalForage;

    store<Item>(key: string, val: Item, store: StoreIn): Promise<Item> {
        if (store == 'location') {
            return this.locationStorage.setItem(key, val);
        }

        return this.defaultStorage.setItem(key, val);
    }

    retrieve<Item>(key: string, store: StoreIn) {
        if (store == 'location') {
            return this.locationStorage.getItem<Item>(key);
        }

        return this.defaultStorage.getItem<Item>(key);
    }

    get languageTag() {
        return this.me?.user.language?.languageTag || 'en';
    }

    get userDbName() {
        return `User#${this.me?.user.id || 0}`;
    }

    createStore(name: string) {
        // This LocalForage create instance is giving us some trouble
        // So don't create anything while doing tests
        if (environment.isTesting) {
            return {
                setItem<T>(_key: string, _value: T, _callback?: (err: any, value: T) => void) {
                    return Promise.resolve();
                },
                getItem<T>(_key: string, _callback?: (err: any, value: (T | null)) => void) {
                    return Promise.resolve();
                },
                config(_options: LocalForageOptions) {
                },
            } as LocalForage;
        }

        return createInstance({
            name: this.userDbName,
            storeName: name,
        });
    }

    getMe() {
        return this.me;
    }

    setMe(me: Me) {
        this.me = me;
        this.defaultStorage = this.createStore('Default');
    }

    getUser() {
        return this.me.user;
    }

    getEmployee() {
        return this.employee;
    }

    setEmployee(employee?: Employee) {
        this.employee = employee;
    }

    /**
     * Does the current user have a customer?
     */
    hasCustomer() {
        return Object.keys(this.getCustomers()).length > 0;
    }

    getCustomers() {
        return this.me.customers;
    }

    getCustomer(): Customer;
    getCustomer(customerId: number): MeCustomer | undefined;
    getCustomer(customerId?: number): Customer | MeCustomer | undefined {
        return customerId ? this.getCustomers()?.[customerId] : this.customer;
    }

    async setCustomer(userId: number, customer: Customer) {
        this.customer = customer;
        this.locationStorage = this.createStore(`Location#${customer.id}`);
        await Storage.setCustomer(userId, this.customer?.id);
    }
}
