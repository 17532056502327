import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { HttpClient, HttpContext } from '@angular/common/http';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { StaffingForecast, StaffingForecastResponse } from '../models/staffing-forecast';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { IGNORE_ERROR } from '../../shared/http/http-contexts';

@Injectable({
    providedIn: 'root',
})
export class StaffingForecastService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    get(customerId: number, from: DateTime, to: DateTime, interval: number) {
        return this.http.get<StaffingForecastResponse>(`/customers/${customerId}/staffing_forecast`, {
            params: formatHttpParams({
                from,
                to,
                interval,
            }, [ 'from', 'to' ]),
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(classifyItem(StaffingForecast));
    }
}
