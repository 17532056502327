import { Transition } from '@uirouter/angularjs';
import { Observable } from 'rxjs';

// Resolves label for NavTabsComponent
export function navTabsLabelResolver(resolveFn: ($transition$: Transition) => Observable<string>) {
    return {
        token: 'label',
        deps: [ '$transition$' ],
        resolveFn,
    };
}
