<eaw-page-header [fabButton]="fabButton">
    <span title>{{ 'CUSTOM_FIELD_plural' | translate | async }}</span>

    <form smallFilter role="search" class="tw-flex tw-flex-row tw-gap-8 tw-justify-center">
        <mat-form-field class="standalone">
            <mat-label>{{ 'FILTER' | translate | async }}</mat-label>
            <input [formControl]="filter" matInput type="text" autofocus autocomplete="off">
        </mat-form-field>
        <mat-form-field class="standalone">
            <mat-label>{{ 'TYPE' | translate | async }}</mat-label>
            <mat-select [formControl]="type" multiple>
                @for (type of types; track type) {
                    <mat-option [value]="type">{{ type }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</eaw-page-header>

<mat-card>
    <eaw-data-table
        #table
        [columns]="columns"
        [request]="request"
        initialSortBy="id"
        (paginationChange)="updateTable($event)">
    </eaw-data-table>
</mat-card>