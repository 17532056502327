import { Injectable } from '@angular/core';
import { Chart, Options } from 'highcharts';
import { t } from 'i18next';
import { Info, Settings } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class HighchartsService {
    async create(element: HTMLElement, type: string, stockChart: boolean, options: Options): Promise<Chart | undefined> {
        const highcharts = await (stockChart ? this.getHighstock() : this.getHighcharts());
        if (!highcharts) {
            return undefined;
        }

        // Try to apply modules
        try {
            (await import(/* webpackChunkName: "highcharts-more" */ 'highcharts/highcharts-more.src')).default(highcharts);
            (await import(/* webpackChunkName: "highcharts-more" */ 'highcharts/modules/no-data-to-display.src')).default(highcharts);
            (await import(/* webpackChunkName: "highcharts-more" */ 'highcharts/modules/data.src')).default(highcharts);

            if (stockChart) {
                (await import(/* webpackChunkName: "highcharts-more" */ 'highcharts/modules/stock.src')).default(highcharts);
            }

            switch (type.toLowerCase()) {
                case 'variablepie':
                    (await import(/* webpackChunkName: "highcharts-more" */ 'highcharts/modules/variable-pie.src')).default(highcharts);
                    break;
                case 'xrange':
                    (await import(/* webpackChunkName: "highcharts-more" */ 'highcharts/modules/xrange.src')).default(highcharts);
                    break;
                case 'solidgauge':
                    (await import(/* webpackChunkName: "highcharts-more" */ 'highcharts/modules/solid-gauge.src')).default(highcharts);
                    break;
                case 'heatmap':
                    (await import(/* webpackChunkName: "highcharts-more" */ 'highcharts/modules/heatmap.src')).default(highcharts);
                    break;
            }
        } catch (_) {
            return undefined;
        }

        highcharts.setOptions({
            lang: {
                loading: t('LOADING'),
                noData: t('NO_DATA'),
                months: Info.months(),
                shortMonths: Info.months('short'),
                shortWeekdays: Info.weekdays(),
                weekdays: Info.weekdays('short'),
            },
        });

        return highcharts.chart(element, {
            credits: {
                enabled: false,
            },
            time: {
                timezone: Settings.defaultZone,
            },
            ...options,
        });
    }

    private async getHighstock() {
        try {
            return (await import(/* webpackChunkName: "highstock" */ 'highcharts/highstock.src')).default as any;
        } catch (_) {
            return undefined;
        }
    }

    private async getHighcharts() {
        try {
            return (await import(/* webpackChunkName: "highcharts" */ 'highcharts/highcharts.src')).default as any;
        } catch (_) {
            return undefined;
        }
    }
}
