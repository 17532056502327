// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { Comment } from '../../../../shared/models/comment';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="'vacation:VACATION_REQUEST' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <md-tabs md-dynamic-height>
            <md-tab label="{{'INFORMATION' | i18next}}">
                <md-content>
                    <md-list flex="100">
                        <md-list-item class="md-2-line">
                            <div class="md-list-item-text" layout="column">
                                <h3 ng-bind="::ctrl.name"></h3>
                                <p ng-i18next="EMPLOYEE"></p>
                            </div>
                        </md-list-item>

                        <md-list-item class="md-2-line">
                            <div class="md-list-item-text" layout="column">
                                <h3 ng-bind="::ctrl.request.from | moment:'LLLL'"></h3>
                                <p ng-i18next="FROM"></p>
                            </div>
                        </md-list-item>

                        <md-list-item class="md-2-line">
                            <div class="md-list-item-text" layout="column">
                                <h3 ng-bind="::ctrl.request.to | moment:'LLLL'"></h3>
                                <p ng-i18next="TO"></p>
                            </div>
                        </md-list-item>

                        <md-list-item class="md-2-line">
                            <div class="md-list-item-text" layout="column">
                                <h3 ng-bind="::ctrl.request.approval.handler_name"></h3>
                                <p ng-i18next="APPROVED"></p>
                            </div>
                            <md-button class="md-secondary md-icon-button" ng-if="ctrl.request.approval.approved">
                                <md-icon md-colors="{'color': 'green-500'}" ng-bind="'done'"></md-icon>
                            </md-button>
                            <md-button class="md-secondary md-icon-button" ng-if="!ctrl.request.approval.approved">
                                <md-icon md-colors="{'color': 'red-500'}" ng-bind="'close'"></md-icon>
                            </md-button>
                        </md-list-item>

                        <md-list-item>
                            <p ng-i18next="vacation:VACATION"></p>
                            <md-button class="md-secondary md-icon-button" ng-if="ctrl.request.vacation">
                                <md-icon md-colors="{'color': 'green-500'}" ng-bind="'done'"></md-icon>
                            </md-button>
                            <md-button class="md-secondary md-icon-button" ng-if="!ctrl.request.vacation">
                                <md-icon md-colors="{'color': 'red-500'}" ng-bind="'close'"></md-icon>
                            </md-button>
                        </md-list-item>
                    </md-list>
                </md-content>
            </md-tab>
            <md-tab label="{{'COMMENT_plural' | i18next}}">
                <md-content>
                    <eaw-comment-list [angularjscomments]="ctrl.request.comments"></eaw-comment-list>
                </md-content>
            </md-tab>
            <md-tab label="{{'vacation:TIMELINE' | i18next}}">
                <timeline rows="ctrl.away" title="{{'vacation:VACATION_plural' | i18next}}"></timeline>
            </md-tab>
        </md-tabs>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.vacation').factory('offTimeInfoDialog', [ '$mdDialog', function($mdDialog) {
    return {
        open(offTimeFactory, request) {
            request.comments = request.comments?.map((c) => new Comment(c));
            const showTimeline = CurrentOld.can(offTimeFactory.customer.getAll.permission());
            return $mdDialog.show({
                bindToController: true,
                template: template1,
                resolve: {
                    away() {
                        if (!showTimeline) {
                            return [];
                        }
                        return offTimeFactory.customer.getAll.query({
                            approved: true,
                            from: request.from,
                            to: request.to,
                            with: [ 'employee.positions' ],
                        }).$promise.then((resp) => {
                            return resp.data.map((absence) => {
                                absence.name = `${absence.employee.name}<br />${absence.employee.positions.map((p) => p.name).join(', ')}`;
                                return absence;
                            });
                        });
                    },
                },
                locals: {
                    request,
                    showTimeLine: showTimeline,
                    activeTabs: [ true, false ],
                    vacationDuration: moment(request.to, 'YYYY-MM-DD HH:mm:ss').clone().diff(moment(request.from, 'YYYY-MM-DD HH:mm:ss'), 'd'),
                    timeLineActive: false,
                },
                controllerAs: 'ctrl',
                controller: function() {
                    this.$onInit = () => {
                        if (!this.request.employee.positions?.length) {
                            this.name = this.request.name;
                        } else {
                            this.name = `${this.request.name}, ${this.request.employee.positions.map((p) => p.name).join(', ')}`;
                        }
                    };
                },
            });
        },
    };
} ]);
