// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.messages').component('eawOutbox', {
    template: `<script type="text/ng-template" id="outboxtoprofilepicture">
    <div layout="row" layout-align="start center">
        <eaw-profile-picture-old ng-if="item.recipients_count == 1" class="tw-pr-12" size="30" user="item.sender"></eaw-profile-picture-old>
        <eaw-profile-picture-old ng-if="item.recipients.length > 1" class="tw-pr-12" user="{first_name: item.recipients.length + '', last_name: ''}" size="30"></eaw-profile-picture-old>
        <div>
            <div ng-if="item.is_blind || !item.recipients.length">
                <span ng-i18next="[i18next]({count:item.recipients_count})messages:X_RECIPIENTS"></span>
            </div>
            <div ng-repeat="recipient in ::item.recipients | limitTo:3 track by $index"> <!-- Update repeat, if and i18next -->
                <span ng-bind="recipient.first_name + ' ' + recipient.last_name"></span>
            </div>
            <div ng-if="item.recipients.length > 3"> <!-- Update repeat, if and i18next -->
                <span ng-i18next="[i18next]({count:item.recipients_count - 3})messages:COUNT_OTHER_RECIPIENT"></span> <!-- Update repeat, if and i18next -->
            </div>
        </div>
    </div>
</script>

<md-card>
    <md-card-content>
        <md-input-container flex>
            <label ng-i18next="SEARCH"></label>
            <input type="search" ng-model="$outboxCtrl.filter" uniq-form-name>
        </md-input-container>

        <md-button type="submit" class="tw-ml-0 md-accent md-raised" ng-click="$outboxCtrl.reloadTable = true">
            <span ng-i18next="SEARCH"></span>
        </md-button>
    </md-card-content>
</md-card>

<md-card>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="$outboxCtrl.table.columns"
                cells="$outboxCtrl.table.cells"
                get-data="$outboxCtrl.getOutbox(pagination)"
                scope-vars="$outboxCtrl.table.vars"
                table-row-click="$outboxCtrl.showInfo(item)"
                reload-table="$outboxCtrl.reloadTable">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$outboxCtrl',
    controller: [ 'MessageInfoService', 'Message', function(MessageInfoService, Message) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.table = {
                columns: [
                    { title: t('TO') },
                    { title: t('messages:SUBJECT') },
                    {
                        title: t('SENT'),
                        sortable: 'updated_at',
                    },
                    {
                        title: t('messages:NOT_OPENED'),
                        class: 'text-right',
                    },
                    {
                        title: t('messages:OPENED'),
                        class: 'text-right',
                    },
                    {
                        title: t('messages:CONFIRMED'),
                        class: 'text-right',
                    },
                ],
                cells: [
                    { templateUrl: 'outboxtoprofilepicture' },
                    { template: 'item.subject' },
                    { template: `item.created_at | moment` },
                    {
                        template: 'item.recipients_count - item.read_count',
                        class: 'text-right',
                    },
                    {
                        template: 'item.read_count',
                        class: 'text-right',
                    },
                    {
                        template: 'item.confirmed_count',
                        class: 'text-right',
                    },
                ],
            };
        };

        ctrl.getOutbox = (pagination) => Message.getOutbox({
            with: [ 'user', 'recipients', 'attachments' ],
            filter: ctrl.filter,
            fields: ctrl.fields,
            ...pagination.toWithPaginatorArguments(),
        });

        ctrl.showInfo = (message) => {
            MessageInfoService.showInfo(message);
        };
    } ],
});
