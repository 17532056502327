<div class="tw-flex">
    <eaw-toolbar-button [matMenuTriggerFor]="display">
        <span text>{{'VIEW' | translate:'scheduling' | async}}</span>
    </eaw-toolbar-button>

    <eaw-toolbar-button [matMenuTriggerFor]="topStats">
        <span text>{{'STATISTIC_plural' | translate | async}}</span>
    </eaw-toolbar-button>

    <eaw-toolbar-button [matTooltip]="('NEW_SHIFT' | translate:'scheduling' | async) || ''"
                        eawPermission
                        [permissions]="[createShiftPermission()]"
                        (click)="newShift()">
        <mat-icon icon>add</mat-icon>
    </eaw-toolbar-button>

    @if (schedule()?.isPublished) {
        <eaw-toolbar-button [matTooltip]="('ORIGINAL' | translate | async) || ''" (click)="goToOriginal()">
            <mat-icon icon>history</mat-icon>
        </eaw-toolbar-button>
    }

    @if (hasBackoffice()) {
        <eaw-toolbar-button [matTooltip]="('SYNC_BACKOFFICE' | translate:'scheduling' | async) || ''">
            <mat-icon icon>sync</mat-icon>
        </eaw-toolbar-button>
    }

    @if (isFullscreen()) {
        <eaw-toolbar-button [matTooltip]="('CANCEL_FULLSCREEN' | translate | async) || ''" (click)="isFullscreen.set(false)">
            <mat-icon icon>fullscreen_exit</mat-icon>
        </eaw-toolbar-button>
    } @else {
        <eaw-toolbar-button [matTooltip]="('FULLSCREEN' | translate | async) || ''" (click)="isFullscreen.set(true)">
            <mat-icon icon>fullscreen</mat-icon>
        </eaw-toolbar-button>
    }

    @if (canNotify()) {
        <eaw-toolbar-button [matTooltip]="('NOTIFY_AUDITORS' | translate:'scheduling' | async) || ''"
                            (click)="notifyAuditors()"
                            eawPermission
                            [permissions]="[updateSchedulePermission()]"
                            eawMaterialColor="amber-500">
            <mat-icon icon>notifications_active</mat-icon>
        </eaw-toolbar-button>
    }

    @if (canDeclineSchedule()) {
        <eaw-toolbar-button [matTooltip]="('DECLINE_SCHEDULE' | translate:'scheduling' | async) || ''"
                            (click)="declineSchedule()"
                            eawPermission
                            [somePermissions]="[canPublish(), canForcePublish()]"
                            eawMaterialColor="red-500">
            <mat-icon icon>cancel</mat-icon>
        </eaw-toolbar-button>
    }

    @if (!schedule()?.isPublished) {
        <eaw-toolbar-button (click)="publish()" eawPermission [somePermissions]="[canPublish(), canForcePublish()]">
            <mat-icon icon eawSize="20px">send</mat-icon>
            <strong text>{{'PUBLISH' | translate:'scheduling' | async}}</strong>
        </eaw-toolbar-button>
    }
</div>

<mat-menu #topStats="matMenu">
    <ng-template matMenuContent>
        @if (topStatsEnabledControl.value) {
            <button mat-menu-item eawMatMenuItem (click)="topStatsEnabledControl.setValue(false)">{{'HIDE' | translate | async}}</button>
        } @else {
            <button mat-menu-item eawMatMenuItem (click)="topStatsEnabledControl.setValue(true)">{{'SHOW' | translate | async}}</button>
        }

        <mat-divider></mat-divider>

        <button mat-menu-item eawMatMenuItem (click)="refreshStatistics.emit()">Refresh</button>
    </ng-template>
</mat-menu>

<mat-menu #display="matMenu">
    <ng-template matMenuContent>
        <button mat-menu-item eawMatMenuItem [matMenuTriggerFor]="shifts">{{'SHIFT_plural' | translate:'scheduling' | async}}</button>
        <button mat-menu-item eawMatMenuItem [matMenuTriggerFor]="mode">{{'MODE' | translate:'scheduling' | async}}</button>
        <button mat-menu-item eawMatMenuItem [matMenuTriggerFor]="grouping">{{'GROUPING' | translate:'scheduling' | async}}</button>
        <button mat-menu-item eawMatMenuItem [matMenuTriggerFor]="sorting">{{'SORTING' | translate:'scheduling' | async}}</button>
        <button mat-menu-item eawMatMenuItem [matMenuTriggerFor]="interval">{{'INTERVAL' | translate | async}}</button>
        <mat-divider></mat-divider>

        <button mat-menu-item eawMatMenuItem>
            <mat-checkbox [formControl]="verticalLinesControl"></mat-checkbox>
            <span>{{'VERTICAL_LINES' | translate:'scheduling' | async}}</span>
        </button>

        <button mat-menu-item eawMatMenuItem>
            <mat-checkbox [formControl]="expandedShiftsControl"></mat-checkbox>
            <span>{{'EXPANDED_SHIFTS' | translate:'scheduling' | async}}</span>
        </button>

        <button mat-menu-item eawMatMenuItem>
            <mat-checkbox [formControl]="openingHoursControl"></mat-checkbox>
            <span>{{ 'OPENING_HOURS' | translate:'company' | async }}</span>
        </button>

        <button mat-menu-item eawMatMenuItem>
            <mat-checkbox [formControl]="nicknamesControl"></mat-checkbox>
            <span>{{ 'SHOW_NICKNAMES' | translate:'scheduling' | async }}</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #shifts="matMenu">
    <ng-template matMenuContent>
        <mat-radio-group [formControl]="shiftDisplayModeControl">
            @for (mode of shiftDisplayModes; track mode.value) {
                <button mat-menu-item eawMatMenuItem>
                    <mat-radio-button [value]="mode.value"></mat-radio-button>
                    <span>{{ mode.label | async }}</span>
                </button>
            }
        </mat-radio-group>
    </ng-template>
</mat-menu>

<mat-menu #mode="matMenu">
    <ng-template matMenuContent>
        <mat-radio-group [formControl]="modeControl">
            @for (mode of modes; track mode.value) {
                <button mat-menu-item eawMatMenuItem>
                    <mat-radio-button [value]="mode.value"></mat-radio-button>
                    <span>{{ mode.label | async }}</span>
                </button>
            }
        </mat-radio-group>
    </ng-template>
</mat-menu>

<mat-menu #grouping="matMenu">
    <ng-template matMenuContent>
        <mat-radio-group [formControl]="groupingControl">
            @for (grouping of groupings(); track grouping.value) {
                <button mat-menu-item eawMatMenuItem>
                    <mat-radio-button [value]="grouping.value"></mat-radio-button>
                    <span>{{ grouping.label | async }}</span>
                </button>
            }
        </mat-radio-group>
    </ng-template>
</mat-menu>

<mat-menu #sorting="matMenu">
    <ng-template matMenuContent>
        <mat-radio-group [formControl]="sortingControl">
            @for (sorting of sortings; track sorting.value) {
                <button mat-menu-item eawMatMenuItem>
                    <mat-radio-button [value]="sorting.value"></mat-radio-button>
                    <span>{{ sorting.label | async }}</span>
                </button>
            }
        </mat-radio-group>
    </ng-template>
</mat-menu>

<mat-menu #interval="matMenu">
    <ng-template matMenuContent>
        <mat-radio-group [formControl]="intervalControl">
            @for (interval of intervals; track interval) {
                <button mat-menu-item eawMatMenuItem>
                    <mat-radio-button [value]="interval"></mat-radio-button>
                    <span>{{ interval | duration:['hours', 'minutes']:{unitDisplay: 'long'} }}</span>
                </button>
            }
        </mat-radio-group>
    </ng-template>
</mat-menu>
