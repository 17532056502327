<eaw-dialog-header>
    <span title>{{ (data.customFieldPivot ? 'EDIT_CUSTOM_FIELD' : 'ADD_CUSTOM_FIELD') | translate:'admin' | async }}</span>
</eaw-dialog-header>

@if (loading) {
    <mat-dialog-content>
        <eaw-info-loading size="sm"/>
    </mat-dialog-content>
} @else {
    <mat-dialog-content>
        <form class="tw-flex tw-flex-col tw-gap-8" [formGroup]="form">
            <mat-form-field>
                <mat-label>{{ 'MODEL' | translate:'admin' | async }}</mat-label>
                <mat-select formControlName="model">
                    @for (model of models; track model) {
                        <mat-option [value]="model">{{ model }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'CUSTOM_FIELD' | translate | async }}</mat-label>
                <input type="text" matInput formControlName="customField" [matAutocomplete]="customFieldAutocomplete">
                <mat-autocomplete #customFieldAutocomplete="matAutocomplete" [displayWith]="customFieldDisplay">
                    @for (cf of filteredCustomFields | async; track cf.key) {
                        <mat-option class="setting-group-custom-field-autocomplete-option" [value]="cf">
                            <span>{{ cf.translationKey | translateSync:'custom_fields' }}</span>
                            <small>{{ cf.key }} / {{ cf.type }}</small>
                        </mat-option>
                    }
                </mat-autocomplete>

                <mat-hint>{{ selectedCustomField?.type }}</mat-hint>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'VALIDATOR' | translate:'admin' | async }}</mat-label>
                <input type="text" matInput formControlName="validator" [matAutocomplete]="validatorAutocomplete">
                <mat-autocomplete #validatorAutocomplete="matAutocomplete">
                    <mat-option [value]="null"></mat-option>
                    @for (validator of filteredValidators | async; track validator) {
                        <mat-option [value]="validator">{{ validator }}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'CUSTOM_FIELD_PRIORITY' | translate: 'admin' | async }}</mat-label>
                <input type="number" matInput formControlName="priority">
            </mat-form-field>

            <div class="tw-flex tw-flex-col tw-gap-24">
                <mat-slide-toggle formControlName="isRequired">{{ 'CF_IS_REQUIRED' | translate:'admin' | async }}</mat-slide-toggle>

                <mat-slide-toggle formControlName="hasInterval">{{ 'CF_HAS_INTERVAL' | translate:'admin' | async }}</mat-slide-toggle>

                @if (selectedCustomField?.type === 'select') {
                    <eaw-info-box class="tw-mt-16" color="yellow" icon="priority_high">
                        <strong infoText class="tw-flex tw-flex-col">
                            <a target="_blank" href="https://easyatwork.slite.com/app/docs/bOHU9LQCEuQs1O">{{ 'ADD_CF_OPTION_LINK' | translate:'admin' | async }}</a>
                        </strong>
                    </eaw-info-box>

                    <eaw-object-creator [keyLabel]="'VALUE' | translate" [valueLabel]="'TEXT' | translate" [title]="'OPTION_plural' | translate" formControlName="options"/>
                }
            </div>
        </form>
    </mat-dialog-content>
}

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>

    @if (editingPivot) {
        <eaw-action-button [loading]="processing" [disabled]="form.invalid || form.disabled" (click)="update()">{{ 'UPDATE' | translate | async }}</eaw-action-button>
    } @else {
        <eaw-action-button [loading]="processing" [disabled]="form.invalid || form.disabled" (click)="add()">{{ 'ADD' | translate | async }}</eaw-action-button>
    }
</mat-dialog-actions>
