import { ProductName } from '../enums/products';
import { NamespaceFile } from '../enums/namespace';
import { ApiResponse } from '../interfaces/api-response';
import { PropertyValue } from './property';
import { PropertyValueDecoder } from '../utils/property-value-decoder';
import { BaseApiModel } from './base-api-model';

type Translation = { key: string, ns: NamespaceFile };

export type SettingOption = { key: string, translation: { key: string, ns: NamespaceFile } };

// This is what we use to handle what type of setting it is
export type SettingDataType = 'array' | 'object' | 'string' | 'float' | 'integer' | 'boolean' | 'select' | 'time' | 'double';

// What the value should be treated as
export type SettingInputType = 'time' | 'date' | 'time_offset' | 'seconds' | 'minutes' | 'select' | 'percent' | 'text' | 'key-value';

export interface SettingResponse extends ApiResponse {
    data_type?: SettingDataType;
    // It can be anything but should follow the setting's data type
    default: string | boolean | number | null | Record<string, any>;
    description: string;
    observer?: string;
    product: ProductName | null;
    name?: string;
    key: string;
    // Value inherited from parent
    inherited_value: PropertyValue | null;
    // The actual value we use, will be equal to either inherited_value or set_value
    resolved_value: PropertyValue | null;
    // The value set by property on the object
    set_value: PropertyValue | null;
    min_value?: string | number;
    max_value?: string | number;
    min?: string | number;
    max?: string | number;
    // Not sure
    type: any;
    input_type?: SettingInputType;
    // If the value string starts with # it means it must be translated
    options?: Record<string, string>;
    group?: string | null;
}

export class Setting extends BaseApiModel <SettingResponse, Setting> {
    // Value used for displaying
    value: PropertyValueDecoder | null;
    selectDisplayValue?: Translation;
    // This is what we use to handle what type of setting it is
    dataType: SettingDataType;
    description: Translation;
    observer: string;
    // It can be anything but should follow the setting's data type
    default: PropertyValueDecoder | null;
    key: string;
    product: ProductName | null;
    inheritedValue: PropertyValueDecoder | null;
    inputType?: SettingInputType;
    // The actual value we use
    resolvedValue: PropertyValueDecoder | null;
    setValue: PropertyValueDecoder | null;
    // Not sure
    type: unknown;
    name: Translation;
    options?: SettingOption[];
    minValue: PropertyValueDecoder | null;
    maxValue: PropertyValueDecoder | null;
    group?: string | null;

    constructor(key: string, data: SettingResponse) {
        super(data, undefined);

        this.dataType = data.data_type || 'string';
        this.options = data.options ? this.formatOptions(data.options) : undefined;

        this.key = key;
        this.description = this.getTranslation(data.description);
        this.observer = data.observer || '';
        this.product = data.product;
        this.inheritedValue = data.inherited_value ? new PropertyValueDecoder(data.inherited_value, data.data_type) : null;
        this.resolvedValue = data.resolved_value ? new PropertyValueDecoder(data.resolved_value, data.data_type) : null;
        this.setValue = data.set_value ? new PropertyValueDecoder(data.set_value, data.data_type) : null;
        this.type = data.type;
        this.inputType = data.input_type;
        this.minValue = data.min != null || data.min_value != null ? new PropertyValueDecoder(String(data.min ?? data.min_value), data.data_type) : null;
        this.maxValue = data.max != null || data.max_value != null ? new PropertyValueDecoder(String(data.max ?? data.max_value), data.data_type) : null;
        this.group = data.group;
        this.default = data.default == null ? null : new PropertyValueDecoder(data.default, data.data_type);
        this.name = this.getTranslation(data.name);
        this.value = this.resolvedValue;

        this.selectDisplayValue = this.options?.find((o) => o.key === this.value?.asString())?.translation;
    }

    private formatOptions(options: Record<string, string>) {
        return Object.entries(options).reduce((arr, [ key, value ]) => {
            const translation = this.getTranslation(value);

            return arr.concat({
                key,
                translation,
            });
        }, [] as SettingOption[]);
    }

    private getTranslation(string?: string): Translation {
        const split = (string || this.key || '').replace('#', '').split(/\:|\./);
        const ns: NamespaceFile = split.length === 2 ? split[0] as NamespaceFile : 'general';
        const key = split.length === 1 ? split[0] : split[1];

        const translation: Translation = {
            key: (key || '').toUpperCase(),
            ns: ns.toLowerCase() as NamespaceFile,
        };

        if (translation.key.endsWith('_PLURAL')) {
            translation.key = translation.key.replace('_PLURAL', '_plural');
        }

        return translation;
    }
}
