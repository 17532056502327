import { inject, Injectable } from '@angular/core';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Observable } from 'rxjs';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { Property, PropertyResponse } from '../models/property';
import { PaginationOptions } from '../interfaces/pagination-options';
import { HttpClient, HttpContext } from '@angular/common/http';
import { IGNORE_ERROR } from './http-contexts';

@Injectable({
    providedIn: 'any',
})
export class UserPropertyService {
    private http = inject(HttpClient);

    create(userId: number, key: number | string, value: string): Observable<Property> {
        return this.http.post<PropertyResponse>(`/users/${userId}/properties`, {
            key,
            value,
        }).pipe(classifyItem(Property));
    }

    getAll(userId: number, pagination?: PaginationOptions): Observable<ArrayPaginatedResponse<Property>> {
        return this.http.get<ArrayPaginatedResponse<PropertyResponse>>(`/users/${userId}/properties`, {
            params: { ...pagination },
        }).pipe(classifyArrayPaginatedResponse(Property));
    }

    get(userId: number, key: number | string) {
        return this.http.get<PropertyResponse>(`/users/${userId}/properties/${key}`, {
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(classifyItem(Property));
    }

    update(userId: number, key: number | string, value: string) {
        return this.http.put<PropertyResponse>(`/users/${userId}/properties/${key}`, { value }).pipe(classifyItem(Property));
    }

    delete(userId: number, key: number | string) {
        return this.http.delete(`/users/${userId}/properties/${key}`);
    }

    deleteMultiple(userId: number, keyFilter: string) {
        return this.http.delete(`/users/${userId}/properties`, {
            params: { filter: keyFilter },
        });
    }
}
