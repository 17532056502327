import { t } from 'i18next';

export function eawTranslate(str?: string, ns?: string): string {
    const key = str?.toUpperCase();

    let text: string;

    if (str && ns) {
        text = t(`${ns}:${key}`) ?? '';

        if (text.startsWith(ns)) {
            return str;
        }
    } else if (key) {
        text = t(key) ?? '';
    } else {
        return '';
    }

    return `${text.slice(0, 1).toUpperCase()}${text.slice(1).toLowerCase()}`;
}
