import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectRotationDialogComponent } from './connect-rotation-dialog.component';
import { DialogData, DialogSize } from '../../../../shared/dialogs/dialog-component';
import { Rotation } from '../../models/rotation';

export type ConnectRotationDialogReturn = number;

export interface ConnectRotationDialogData extends DialogData {
    customerId: number;
    rotation: Rotation;
}

@Injectable({
    providedIn: 'root',
})
export class ConnectRotationDialogService {

    constructor(@Inject(MatDialog) private dialog: MatDialog) {
    }

    connect(customerId: number, rotation: Rotation) {
        return this.dialog.open<ConnectRotationDialogComponent, ConnectRotationDialogData, ConnectRotationDialogReturn>(ConnectRotationDialogComponent, {
            data: {
                size: DialogSize.Medium,
                customerId,
                rotation,
            },
        });
    }
}
