import { MainMenuEntryData } from '../interfaces/main-menu-entry-data';
import { NamespaceFile } from '../../shared/enums/namespace';
import { StateProviderDataData } from '../../shared/utils/create-state';
import { uniqueId } from 'lodash-es';
import { PermissionsInputValue } from '../../permissions/services/element-permission.service';
import { signal } from '@angular/core';

export class MainMenuEntry {
    protected readonly customerId: number;
    /**
     * Internal ID for the entry. Used to identify the entry in the sidebar.
     */
    readonly id = uniqueId();
    readonly key: string;

    badgeCount = signal(0);
    state?: string;
    glob?: string;
    icon?: string;
    badges?: string[];
    stateData?: MainMenuEntryData['stateData'];
    somePermissions: PermissionsInputValue = [];
    settings?: StateProviderDataData['settings'];
    params?: { key: string, value: string }[];
    ssoService?: string;
    subMenu?: MainMenuEntry[];
    href = signal('');
    active = signal(false);
    hasPermission = signal(true);

    /** Name in the menu */
    translationKey: string;
    ns: NamespaceFile;

    constructor(data: MainMenuEntryData, customerId: number) {
        this.customerId = customerId;
        this.key = data.key;
        this.state = data.state;
        this.glob = data.glob || data.state;
        this.icon = data.icon;
        this.translationKey = data.translationKey;
        this.ns = data.translationNs || 'navigation';
        this.badges = data.badges;
        this.ssoService = data.ssoService;
        this.params = data.params;
        this.stateData = data.stateData;
    }

    updateActiveStatus(name?: string) {
        this.active.set(Boolean(this.state === name || this.glob && name?.startsWith(this.glob)));
    }

    updateBadgeCount(badges: Map<string, number>) {
        this.badgeCount.set(this.badges?.reduce((total, key) => (total + (badges.get(key) || 0)), 0) || 0);
    }

    getPermissionChildPrefix() {
        return this.key.startsWith('admin') ? 'ui.admin.main_menu' : `ui.customers.${this.customerId}.main_menu`;
    }
}
