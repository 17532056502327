<div id="grid" class="tw-grid tw-gap-8" [class.has-big-filter]="useBigFilter() && bigFilterToggled()">
    <ng-container [ngTemplateOutlet]="headerContent"></ng-container>
</div>

<ng-template #headerContent>
    <div class="left tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-8">
        @if (icon()) {
            <mat-icon>{{ icon() }}</mat-icon>
        }
        <div id="titles-container">
            <span class="mat-headline-5">
                <ng-content select="[title]"></ng-content>
            </span>
            <span class="mat-body-1">
                <ng-content select="[subtitle]"></ng-content>
            </span>
        </div>
    </div>

    <div id="small-filter" class="tw-flex tw-items-center tw-justify-end">
        <ng-content select="[smallFilter]"></ng-content>
    </div>

    <div class="right tw-flex tw-flex-row tw-justify-end tw-items-center tw-gap-8">
        @for (button of computedButtons(); track button) {
            @if (!button.hide?.() && (button.hasPermission ? (button.hasPermission() | async) : true)) {
                <button mat-icon-button
                        [matMenuTriggerFor]="button.menu ? buttonMenu : null"
                        [matMenuTriggerData]="{button: button}"
                        [class.max-md:tw-hidden]="computedButtons().length > 1"
                        class="header-button"
                        [disabled]="button.disabled?.() ?? false"
                        [class.active]="button.active?.()"
                        [matTooltip]="(button.menuText() | async) || ''"
                        (click)="button.click ? button.click() : null"
                        [color]="button.type"
                        [attr.aria-label]="button.ariaLabel || 'Icon button'">
                    <mat-icon [style.transform]="button.rotation">{{ button.icon() }}</mat-icon>
                </button>
            }
        }

        @if (computedButtons().length > 1) {
            <button mat-icon-button
                    [matMenuTriggerFor]="buttonsMenu"
                    aria-label="Header buttons as a menu on small screens"
                    class="md:tw-hidden">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #buttonsMenu="matMenu">
                @for (button of computedButtons(); track button) {
                    @if (!button.hide?.() && (button.hasPermission ? (button.hasPermission() | async) : true)) {
                        <button mat-menu-item
                                (click)="button.click ? button.click(): null"
                                [matMenuTriggerFor]="button.menu ? buttonMenu : null"
                                [matMenuTriggerData]="{button: button}"
                                [disabled]="button.disabled?.() ?? false">
                            <mat-icon [style.transform]="button.rotation">{{ button.icon() }}</mat-icon>
                            <span>{{ button.menuText() | async }}</span>
                        </button>
                    }
                }
            </mat-menu>
        }

        @if (fabButton() && (fabButton()?.hasPermission() | async)) {
            <button class="header-fab-button"
                    mat-mini-fab
                    [matMenuTriggerFor]="fabButton()?.menu ? buttonMenu : null"
                    [matMenuTriggerData]="{button: fabButton()}"
                    (click)="fabButton()?.click ? fabButton()?.click() : null"
                    [color]="fabButton()?.type || 'accent'"
                    [matTooltip]="(fabButtonTooltip() | async) || ''"
                    [attr.aria-label]="fabButton()?.ariaLabel || 'Fab button'"
                    eawPermission
                    [disabled]="fabButton()?.disabled?.()"
                    [permissions]="fabButton()?.permissionInputs?.permissions"
                    [somePermissions]="fabButton()?.permissionInputs?.somePermissions"
                    [permissionChildrenInclude]="fabButton()?.permissionInputs?.permissionChildrenFilterIn"
                    [permissionChildrenFilter]="fabButton()?.permissionInputs?.permissionChildrenFilterOut">
                <mat-icon>{{ fabButton()?.icon || 'add' }}</mat-icon>
            </button>
        }
    </div>

    <mat-card class="filter tw-col-span-full">
        <mat-card-content>
            <ng-content select="[bigFilter]"></ng-content>
        </mat-card-content>
    </mat-card>
</ng-template>

<mat-menu #buttonMenu="matMenu">
    <ng-template matMenuContent let-button="button">
        @for (item of (button.menu || []); track item) {
            <button mat-menu-item
                    (click)="item.click()"
                    [disabled]="(item.hasPermission?.() | async) === false">
                @if (item.icon) {
                    <mat-icon>{{ item.icon }}</mat-icon>
                }
                <span>{{ item.text | async }}</span>
            </button>
        }
    </ng-template>
</mat-menu>
