// @ts-nocheck
import { forEach } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';

module('eaw.time').component('autoStartWeek', {
    controller: 'AutoStartWeekCtrl',
    controllerAs: '$awCtrl',
    template: `<div layout="column" layout-padding flex>
    <auto-start-day ng-model="day.time" ng-change="$awCtrl.onChange(day)" label="{{day.name}}" ng-repeat="day in $awCtrl.weekdays track by day.isoWeekday"></auto-start-day>
</div>
`,
    require: {
        'ngModel': 'ngModel',
    },
});

module('eaw.time').controller('AutoStartWeekCtrl', function AutoStartWeekCtrl() {
    const ctrl = this;
    const _week = new Array(7).fill(null); // zero will be made one, because api uses iso weekdays

    ctrl.$onInit = () => {
        const currentDate = moment().hours(6).startOf('h');

        // Iso weekdays
        ctrl.weekdays = new Array(7).fill(0).map((_, index) => {
            return {
                isoWeekday: index + 1,
                name: moment().isoWeekday(index + 1).format('dddd'),
                time: currentDate.clone(),
            };
        });

        ctrl.ngModel.$formatters.unshift((modelValue) => {
            if (!Array.isArray(modelValue)) {
                return _week;
            }

            const len = _week.length - modelValue.length;
            if (len > 0) {
                return modelValue.slice(0, len - Math.min(len, modelValue.length));
            }

            if (len < 0) {
                return [].concat(modelValue, new Array(Math.abs(len)).fill(null));
            }

            return modelValue;
        });

        ctrl.ngModel.$formatters.push((modelValue) => {
            if (!Array.isArray(modelValue)) {
                return _week;
            }

            forEach(ctrl.weekdays, (weekday, k) => {
                if (typeof modelValue[k] === 'string') {
                    weekday.time = moment(modelValue[k], 'HH:mm');
                }
            });
        });
    };

    ctrl.onChange = (weekday) => {
        _week[weekday.isoWeekday - 1] = weekday.time ? weekday.time.format('HH:mm') : null;
        ctrl.ngModel.setViewValue(_week);
    };
});
