import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { BusinessDate } from '../utils/business-date';

const enum DateTimeFormat {
    DATE_SHORT,
    DATE_MED,
    DATE_MED_WITH_WEEKDAY,
    DATE_FULL,
    DATE_HUGE,
    TIME_SIMPLE,
    TIME_WITH_SECONDS,
    TIME_WITH_SHORT_OFFSET,
    TIME_WITH_LONG_OFFSET,
    TIME_24_SIMPLE,
    TIME_24_WITH_SECONDS,
    TIME_24_WITH_SHORT_OFFSET,
    TIME_24_WITH_LONG_OFFSET,
    DATETIME_SHORT,
    DATETIME_SHORT_WITH_SECONDS,
    DATETIME_MED,
    DATETIME_MED_WITH_WEEKDAY,
    DATETIME_FULL,
    DATETIME_FULL_WITH_SECONDS,
    DATETIME_HUGE,
    DATETIME_HUGE_WITH_SECONDS,
    WEEKDAY
}

@Pipe({
    name: 'DateTime',
    standalone: true,
})
export class DateTimePipe implements PipeTransform {
    transform(value?: DateTime | BusinessDate | null, format?: null, customString?: string): string
    transform(value?: DateTime | BusinessDate | null, format?: keyof typeof DateTimeFormat | Intl.DateTimeFormatOptions): string
    transform(value?: DateTime | BusinessDate | null, formatOrOptions?: keyof typeof DateTimeFormat | Intl.DateTimeFormatOptions | null, customString?: string): string {
        if (!value) {
            return '';
        }

        const dt = value instanceof BusinessDate ? value.dateTime : value;

        if (!dt.isValid) {
            return '';
        }

        if (customString) {
            return dt.toFormat(customString) || '';
        }

        if (!formatOrOptions) {
            return dt.toLocaleString(DateTime.DATETIME_MED) || '';
        }

        if (typeof formatOrOptions === 'string') {
            if (formatOrOptions === 'WEEKDAY') {
                return dt.toFormat('cccc') || '';
            }

            return dt.toLocaleString((DateTime as Record<string, any>)[formatOrOptions]) || '';
        }

        return dt.toLocaleString(formatOrOptions) || '';
    }
}
