<eaw-dialog-header>
    <span title>{{ data.widget.info.translationKey | translate:data.widget.info.translationNs | async }}</span>
    <span subtitle>{{ subtitle() | async }}</span>
</eaw-dialog-header>

@if (loading()) {
    <mat-dialog-content class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <eaw-info-loading size="md"></eaw-info-loading>
    </mat-dialog-content>
}

<!-- Don't use mat-dialog-content as it applies a padding that the rendered component should handle itself -->
<div id="component-content">
    <div #componentHost [class.tw-hidden]="loading()"></div>
</div>

<mat-dialog-actions>
    <button mat-button (click)="openOptions()">{{ 'OPTIONS' | translate | async }}</button>
    <button mat-button mat-dialog-close="">{{ 'CLOSE' | translate | async }}</button>
</mat-dialog-actions>
