import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'employee-timepunch-status-tabs-upgrade',
    standalone: true
})
export class employeeTimepunchStatusTabsUpgradeDirective extends UpgradeComponent {
    @Input() customer!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('employeeTimepunchStatusTabs', elementRef, injector);
    }
}

@Component({
    template: '<employee-timepunch-status-tabs-upgrade [customer]="customer"></employee-timepunch-status-tabs-upgrade>',
    standalone: true,
    imports: [employeeTimepunchStatusTabsUpgradeDirective],
})
export class employeeTimepunchStatusTabsUpgradeComponent {
    @Input() customer!: any;
}

@NgModule({
    imports: [employeeTimepunchStatusTabsUpgradeDirective,
        employeeTimepunchStatusTabsUpgradeComponent],
})
export class employeeTimepunchStatusTabsUpgradeModule {
}
