import { ComponentType } from '@angular/cdk/overlay';
import { ProductName } from '../../shared/enums/products';
import { NamespaceFile } from '../../shared/enums/namespace';
import { Customer } from '../../shared/models/customer';
import { User } from '../../shared/models/user';
import { Employee } from '../../shared/models/employee';
import { WidgetSettingsDialog } from './widget-settings-dialog';
import { ExtendedWidgetInfo } from './extended-widget-info';
import { PropertyValueDecoder } from '../../shared/utils/property-value-decoder';
import { WidgetComponent } from './widget-component';
import { WidgetSize } from './widget';

export interface WidgetInfoData {
    // DON'T TOUCH / CHANGE THE KEY!!! :))
    key: string;
    // The icon for the widget
    icon: string;
    // The size the widget should be upon creation
    recommendedSize?: WidgetSize,
    // The component to load in the grid for the widget
    component: ComponentType<WidgetComponent>;
    // The translation key for the widget as found in WTI
    translationKey: string;
    // The translation namespace for the widget as found in WTI
    translationNs?: NamespaceFile;
    // The products required for the widget
    products: ProductName[];
    // Whether the widget requires an employee
    requiresEmployee: boolean;
    // The permissions required for the widget
    permissions?: string[];
    somePermissions?: string[];
    // Is this a mini widget?
    mini?: boolean;
    // The dialog to show for setting the settings
    settingsDialog?: ComponentType<WidgetSettingsDialog<any>>;
    // Component used in mini widgets to show additional information
    extendedInfoComponent?: ComponentType<ExtendedWidgetInfo>;
    // Can you add multiple of this widget?
    multiple?: boolean;
    // Can this widget be added in the setup phase?
    excludeFromSetup?: boolean;
    // The route to navigate to
    route?: string;
    /**
     * Provide the setting name as key, and a callback function that returns true/false.
     *
     * When all keys and their respective callback functions return true, the state will be shown.
     */
    settings?: Record<string, (value: PropertyValueDecoder | null) => boolean>;
    /**
     * Flag that indicates that the widget has not been upgraded
     *
     * TODO: Remove this flag when all widgets have been upgraded
     */
    noInfo?: boolean;
}

/**
 * Contains information about a widget
 */
export class WidgetInfo {
    // DON'T TOUCH / CHANGE THE KEY!!! :))
    readonly key: string;
    // The icon for the widget
    readonly icon: string;
    // The size the widget should be upon creation
    recommendedSize?: WidgetSize;
    // The component to load in the grid for the widget
    readonly component: ComponentType<WidgetComponent>;
    // The translation key for the widget as found in WTI
    readonly translationKey: string;
    // The translation namespace for the widget as found in WTI
    readonly translationNs: NamespaceFile;
    // The products required for the widget
    readonly products: ProductName[];
    // Whether the widget requires an employee
    readonly requiresEmployee: boolean;
    // The permissions required for the widget
    readonly permissions: string[];
    readonly somePermissions: string[];
    // Is this a mini widget?
    readonly mini: boolean;
    // The dialog to show for setting the settings
    readonly settingsDialog?: ComponentType<WidgetSettingsDialog<any>>;
    // Component used in mini widgets to show additional information
    readonly extendedInfoComponent?: ComponentType<ExtendedWidgetInfo>;
    // Can you add multiple of this widget?
    readonly multiple: boolean;
    // Can this widget be added in the setup phase?
    readonly excludeFromSetup: boolean;
    // The route to navigate to
    readonly route: string | null;
    /**
     * Provide the setting name as key, and a callback function that returns true/false.
     *
     * When all keys and their respective callback functions return true, the state will be shown.
     */
    readonly settings?: Record<string, (value: PropertyValueDecoder | null) => boolean>;
    readonly customer: Customer;
    readonly user: User;
    readonly employee?: Employee;
    /**
     * Flag that indicates that the widget has not been upgraded
     *
     * TODO: Remove this flag when all widgets have been upgraded
     */
    readonly noInfo?: boolean;

    constructor(data: WidgetInfoData, customer: Customer, user: User, employee?: Employee) {
        this.key = data.key;
        this.icon = data.icon;
        this.recommendedSize = data.recommendedSize;
        this.component = data.component;
        this.translationKey = data.translationKey;
        this.translationNs = data.translationNs || 'widgets';
        this.products = data.products;
        this.requiresEmployee = data.requiresEmployee;
        this.permissions = data.permissions || [];
        this.somePermissions = data.somePermissions || [];
        this.mini = data.mini ?? false;
        this.multiple = data.multiple ?? false;
        this.settingsDialog = data.settingsDialog;
        this.extendedInfoComponent = data.extendedInfoComponent;
        this.excludeFromSetup = data.excludeFromSetup ?? false;
        this.route = data.route ?? null;
        this.settings = data.settings || {};
        this.customer = customer;
        this.user = user;
        this.employee = employee;
        this.noInfo = data.noInfo;
    }
}
