<mat-card>
    <mat-card-content>
        <eaw-data-table
            #table
            [columns]="columns"
            [request]="request"
            [rowClasses]="rowClasses"
            skipQueryParams
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
