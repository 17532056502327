// @ts-nocheck
import { module } from 'angular';
import MlBucket from '../../../../../../ai-budgeting/angularjs/shared/ml-bucket';

module('eaw.admin').controller('createMlBucketDialog', [ 'mlBucketFactory', '$mdDialog', function createMlBucketDialog(mlBucketFactory, $mdDialog) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.tags = [];
    };

    ctrl.submit = () => {
        mlBucketFactory.create.query(ctrl.customer.id, {
            name: ctrl.name,
            description: ctrl.description,
            tags: ctrl.tags,
        }).then((resp) => {
            $mdDialog.hide(new MlBucket(resp));
        });
    };
} ]);
