import { ApiResponse } from '../../shared/interfaces/api-response';
import { Contract, ContractResponse } from '../../shared/models/contract';
import { Availability, AvailabilityResponse } from '../../availability/models/availability';
import { HourDistribution, HourDistributionResponse } from './hour-distribution';

export interface CombinedContractContractResponse extends ContractResponse {
    full_week: number;
}

export interface CombinedContractResponse extends ApiResponse {
    contract: CombinedContractContractResponse,
    availability: AvailabilityResponse | null,
    hours_distribution: HourDistributionResponse | null,
}

export class CombinedContract {
    contract: Contract;
    availability: Availability | null;
    hoursDistribution: HourDistribution | null;

    constructor(data: CombinedContractResponse) {
        this.contract = new Contract(data.contract);
        this.availability = data.availability ? new Availability(data.availability) : null;
        this.hoursDistribution = data.hours_distribution ? new HourDistribution(data.hours_distribution) : null;
    }
}
