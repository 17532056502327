import { ChangeDetectionStrategy, Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetPropertySettings } from '../../../classes/widget';
import { WidgetSettingsDialog } from '../../../classes/widget-settings-dialog';
import { WidgetSettingsDialogComponent } from '../../widget-settings-dialog/widget-settings-dialog.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BalanceService } from '../../../../balances/http/balance.service';
import { expandAllPages } from '../../../../shared/utils/rxjs/expand-all-pages';
import { BalanceType } from '../../../../balances/models/balance-type';
import { TranslateService } from '../../../../shared/services/translate.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { catchError, EMPTY } from 'rxjs';
import { TranslateSyncPipe } from '../../../../shared/pipes/translate-sync.pipe';

export interface MiniBalancesSettings extends WidgetPropertySettings {
    interval: 'year' | 'week' | 'day';
    balanceCode: string;
}

@Component({
    selector: 'eaw-mini-balances-settings',
    standalone: true,
    imports: [ CommonModule, WidgetSettingsDialogComponent, ReactiveFormsModule, MatFormFieldModule, MatOptionModule, MatSelectModule, TranslatePipe, TranslateSyncPipe ],
    templateUrl: './mini-balances-settings.component.html',
    styleUrl: './mini-balances-settings.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniBalancesSettingsComponent extends WidgetSettingsDialog<MiniBalancesSettings> implements OnInit {
    protected errorMessage?: WritableSignal<Promise<string>>;
    protected balanceTypes = signal<BalanceType[]>([]);
    protected loading = signal(false);
    protected intervals = signal<{ interval: MiniBalancesSettings['interval'], translation: Promise<string> }[]>([]);
    protected form = new FormGroup({
        interval: new FormControl<MiniBalancesSettings['interval']>('day', { nonNullable: true }),
        balanceCode: new FormControl<MiniBalancesSettings['balanceCode']>('', { nonNullable: true }),
    });

    constructor(
        @Inject(BalanceService) private balanceService: BalanceService,
        @Inject(TranslateService) private translateService: TranslateService,
    ) {
        super();

        this.intervals.set([
            { interval: 'year', translation: this.translateService.t('YEAR') },
            { interval: 'week', translation: this.translateService.t('WEEK') },
            { interval: 'day', translation: this.translateService.t('DAY') },
        ]);
    }

    ngOnInit() {
        const noTypesErrorMessage = this.translateService.t('NO_BALANCE_TYPES_FOUND');

        this.loading.set(true);
        expandAllPages((pagination) => this.balanceService.getTypes(this.data.widgetInfo.customer.id, pagination), { per_page: 100 }).pipe(
            catchError(() => {
                this.errorMessage?.set(noTypesErrorMessage);
                return EMPTY;
            }),
        ).subscribe((res) => {
            const type = res[0];

            if (!type) {
                this.errorMessage?.set(noTypesErrorMessage);
                return;
            }

            this.balanceTypes.set(res);
            this.form.controls.balanceCode.setValue(type.balanceCode);
            this.loading.set(false);
        });
    }

    submitted() {
        return this.dialogRef.close({
            balanceCode: this.form.controls.balanceCode.value,
            interval: this.form.controls.interval.value,
        });
    }
}
