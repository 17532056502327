import { ChangeDetectionStrategy, Component, EventEmitter, inject, input, OnInit, Output, signal } from '@angular/core';
import { WidgetComponent } from '../../classes/widget-component';
import { EmployeeService } from '../../../shared/http/employee.service';
import { Widget } from '../../classes/widget';
import { UpcomingBirthdaysWidgetSettings } from './upcoming-birthdays-widget-settings/upcoming-birthdays-widget-settings.component';
import { MatListModule } from '@angular/material/list';
import { DateTime } from 'luxon';
import { Employee } from '../../../shared/models/employee';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe, LowerCasePipe } from '@angular/common';
import { ProfilePictureComponent } from '../../../shared/components/profile-picture/profile-picture.component';
import { CurrentService } from '../../../shared/services/current.service';
import { MatButton } from '@angular/material/button';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';

interface BirthdayEmployee {
    employee: Employee;
    birthday: DateTime | null;
    customers: string[];
}

@Component({
    selector: 'eaw-upcoming-birthdays-widget',
    standalone: true,
    imports: [
        MatListModule,
        DateTimePipe,
        TranslatePipe,
        AsyncPipe,
        ProfilePictureComponent,
        MatButton,
        LowerCasePipe,
    ],
    templateUrl: './upcoming-birthdays-widget.component.html',
    styleUrl: './upcoming-birthdays-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpcomingBirthdaysWidgetComponent extends WidgetComponent implements OnInit {
    private readonly employeeService = inject(EmployeeService);
    private readonly current = inject(CurrentService);

    widget = input.required<Widget<UpcomingBirthdaysWidgetSettings>>();
    employees = signal<BirthdayEmployee[]>([]);

    // For ajs container
    @Output() loaded = new EventEmitter();
    @Output() component = new EventEmitter();

    today = DateTime.now().startOf('day');
    pagination?: Pick<ArrayPaginatedResponse<unknown>, 'current_page' | 'last_page' | 'total'>;

    ngOnInit() {
        this.component.emit(this);
        this.getEmployees();
    }

    getEmployees(page?: number) {
        this.setLoading(true);
        this.employeeService.search(this.widget().getSetting('locations') || [ this.current.getCustomer().id ], {
            birthday_from: this.today,
            birthday_to: this.today.plus({ days: this.widget().getSetting('days') ?? 7 }),
            page,
            per_page: 10,
        })
            .subscribe((resp) => {
                const employees = resp.data
                    .filter((e) => e.birthDate)
                    .reduce((array, employee) => {
                        const existingUser = employee.userId ? array.find((e) => e.employee.userId === employee.userId) : undefined;
                        const birthday = this.getBirthday(employee);
                        if (existingUser && employee.customer) {
                            existingUser.customers.push(employee.customer.name);
                        } else {
                            array.push({ employee, birthday, customers: employee.customer ? [ employee.customer.name ] : [] });
                        }
                        return array;
                    }, page ? this.employees() : []);

                this.employees.set(employees);

                this.pagination = { current_page: resp.current_page, last_page: resp.last_page, total: resp.total };
                this.setLoading(false);
                this.loaded.emit();
            });
    }

    getBirthday(employee: Employee) {
        const birthday = employee.birthDate?.dateTime.set({ year: this.today.year });
        return birthday ? birthday.plus({ years: Number(birthday < this.today) }) : null;
    }

    getBirthdayText(emp: BirthdayEmployee) {
        const age = emp.employee.birthDate?.dateTime ? emp.birthday?.diff(emp.employee.birthDate.dateTime, 'year').toHuman() : null;
        const relative = emp.birthday?.toRelativeCalendar({ unit: 'days' });
        return age && relative ? `${age} ${relative}` : '';
    }
}
