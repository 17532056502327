// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<h2 ng-bind="$widget.shifts"></h2>
<span ng-bind="::$widget.text"></span>
`;

module('eaw.dashboard').component('vacantShiftsMiniWidget', {
    template: template1,
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$widget',
    controller: [ 'ShiftFactory', function(ShiftFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.offset = ctrl.mini.widget?.settings?.day_offset;
            ctrl.text = t(ctrl.offset ? 'TOMORROW' : 'TODAY');

            ctrl.getShifts({
                page: 1,
                per_page: 1,
            }).then((resp) => {
                ctrl.mini.loading(false);
                ctrl.shifts = resp.total;
            });

            ctrl.mini.dialogComponents = [ {
                name: 'dashVacantShiftList',
                label: 'scheduling:SHIFT_plural',
                bindings: { getShifts: ctrl.getShifts },
                params: { getShifts: [ 'pagination' ] },
            } ];
        };

        ctrl.$onDestroy = () => {
            ctrl.shiftsRes?.$cancelRequest?.();
        };

        ctrl.getShifts = function getShifts(pagination) {
            const date = moment().add(ctrl.offset, 'd');

            ctrl.shiftsRes = ShiftFactory.getAllForCustomer(ctrl.mini.widget.customer, {
                useResource: true,
                from: date.startOf('d'),
                to: date.clone().endOf('d'),
                vacant: true,
                ...pagination,
            });

            return ctrl.shiftsRes.$promise;
        };
    } ],
});
