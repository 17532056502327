import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, map, mergeMap, Observable, of, reduce, Subject, tap } from 'rxjs';
import { CurrentService } from './current.service';
import { environment } from '../../../environments/environment';
import { ApiModel, ApiModelClass } from '../enums/api-model';
import { ApiResponse } from '../interfaces/api-response';
import { PermissionsInputValueTuple } from '../../permissions/services/element-permission.service';

export interface PermissionModel {
    id: number,
    type: ApiModel | ApiModelClass,
}

export interface CanReadModel extends PermissionModel {
    prefix?: string,
}

export interface ReadableFieldsResponse extends ApiResponse {
    // Attributes the user is allowed to read on the given model
    attributes: string[],
    // Type changed depending on what type is sent in as ID in the request
    id: number | string,
    type: ApiModel
}

export type ReplacePermissionPlaceholdersArgs = Record<string, string | null | undefined>;

type PermissionChildrenResponse = Record<string, string[]>;

export interface PermissionOptions {
    // Placeholder values to replace in the permissions
    replace?: ReplacePermissionPlaceholdersArgs;
    models?: PermissionModel[];
    stackId?: number;
}

interface BatchOptions {
    stackId?: number;
}

interface CanReadOptions extends BatchOptions {
    prefix: string;
    models: CanReadModel[];
}

interface PermissionChildrenOptions extends BatchOptions {
    prefix: string;
    filter: string;
    filter_value?: boolean;
}

interface IsAllowedOptions extends BatchOptions {
    models?: PermissionModel[];
    permission: string;
}

type Mode = 'isAllowed' | 'permissionChildren' | 'canRead';

interface Batch {
    uuid?: string;
    stackId?: number;
    options: BatchOptions & { type: Mode };
}

type BatchResponseElement = (string | number)[] | (1 | 0) | ReadableFieldsResponse;
type BatchResponse = BatchResponseElement[];

@Injectable({
    providedIn: 'root',
})
export class PermissionCheckService {
    private readonly http = inject(HttpClient);
    private readonly current = inject(CurrentService);

    // Object mapping user ids to permissions
    private readonly permissionStorage: Record<number, Map<string, boolean> | undefined> = {};
    private readonly permissionChildrenStorage: Map<string, Map<boolean, string[]>> = new Map();

    private permissionBatch?: Map<string, Batch>;
    private permissionBatchSubject: Subject<Map<string, BatchResponseElement>> | undefined;
    private permissionBatchTimeout?: number;

    /**
     * Returns the permissions for the current user
     */
    private get permissions(): Map<string, boolean> {
        const userId = this.current.getUser().id;

        const map = this.permissionStorage[userId];
        if (!map) {
            const newMap = new Map<string, boolean>();
            this.permissionStorage[userId] = newMap;
            return newMap;
        }

        return map;
    }

    /**
     * TODO Find a better name for the method?
     *
     * Checks if the user has access to the given permission, or a subset of it.
     */
    permissionOrChildren(permission: string, permissionOptions: PermissionOptions | undefined, childrenPrefix: string, childrenFilter: string, childrenFilterValue: boolean): Observable<boolean> {
        return forkJoin([
            this.isAllowed(permission, permissionOptions),
            this.permissionChildrenSingle(childrenPrefix, childrenFilter, childrenFilterValue),
        ]).pipe(
            map(([ permissionValue, children ]) => permissionValue || children.length > 0),
        );
    }

    /**
     * **IMPORTANT**
     *
     * Permission children does not always take into account people who have access to _all_ of a given item.
     * So in certain cases you need to check both children and "normal" permission. See reports menu entry as an example.
     *
     * @example
     * Calling `permissionChildrenSingle("ui.admin.main_menu", "visible", false)` might return:
     * ```ts
     * [ "admin_users" ]
     * ```
     * Because the user has the permission "ui.admin.main_menu.admin_users.visible" = false.
     */
    permissionChildrenSingle(prefix: string, filter: string, filterValue: boolean): Observable<string[]> {
        return this._permissionChildren(prefix, filter, filterValue);
    }

    /**
     * @example
     * Calling `permissionChildren([ "ui.admin.main_menu", "ui.customers.44.main_menu" ], "visible", false)` might return:
     * ```ts
     * {
     *    "ui.admin.main_menu": [ "admin_users"],
     *    "ui.customers.44.main_menu": [ "hr_files" ],
     * }
     * ```
     * This means the user has permission "ui.admin.main_menu.admin_users.visible" = false, and "ui.customers.44.main_menu.hr_files.visible" = false.
     *
     *  @param {string[]} prefixes should not end with a dot
     *  @param {string} filter should not start with a dot
     *  @param {boolean} filterValue the value to filter on
     *  @returns {Observable<PermissionChildrenResponse>} That always resolves to an object - we catch the error.
     */
    permissionChildren(prefixes: string[], filter: string, filterValue: boolean): Observable<PermissionChildrenResponse> {
        const requests = new Map<string, Observable<string[]>>();
        for (const prefix of prefixes) {
            requests.set(prefix, this._permissionChildren(prefix, filter, filterValue));
        }

        return of(...Array.from(requests)).pipe(
            mergeMap(([ prefix, request ]) => request.pipe(map((response): [ string, string[] ] => [ prefix, response ]))),
            reduce((acc, [ prefix, response ]) => {
                return { ...acc, [prefix]: response };
            }, {} as PermissionChildrenResponse),
        );
    }

    private _permissionChildren(prefix: string, filter: string, filterValue: boolean): Observable<string[]> {
        if (prefix.endsWith('.')) {
            throw new Error('Prefix should not end with a dot');
        }

        if (environment.isTesting) {
            return of([]);
        }

        const storageKey = prefix + '.filter';
        const cached = this.permissionChildrenStorage.get(storageKey);
        if (cached) {
            const children = cached.get(filterValue);
            if (children) {
                return of(children);
            }
        }

        return this.batch('permissionChildren', {
            prefix,
            filter,
            filter_value: filterValue,
        }).pipe(
            map((response) => response.map((s) => String(s))),
            tap((response) => {
                this.permissionChildrenStorage.set(prefix, new Map([ [ filterValue, response ] ]));
            }),
        );
    }

    /**
     * Sends a request to the API to get the value for one or multiple permissions for the current user
     * @param stackId
     * @param prefix  Permission string **up to** the placeholder of the ID of the model we are checking
     * @param model
     */
    getReadableFields(stackId: number, prefix: string, model: PermissionModel): Observable<ReadableFieldsResponse> {
        return this.batch('canRead', {
            stackId,
            prefix,
            models: [ {
                id: model.id,
                type: model.type,
                prefix,
            } ],
        });
    }

    /**
     * Checks the stored permissions if the user has the given permission
     * @deprecated
     * @see isAllowed
     */
    single(permission: string, options?: PermissionOptions): boolean {
        return this.getPermissionValue(permission, { replace: options?.replace, models: options?.models });
    }

    /**
     * Sends a request to the API to get the value for one permission
     */
    isAllowed(tuple: PermissionsInputValueTuple): Observable<boolean>
    isAllowed(permission: string, options?: PermissionOptions): Observable<boolean>
    isAllowed(permissionOrTuple: PermissionsInputValueTuple | string, options?: PermissionOptions): Observable<boolean> {
        const permission = permissionOrTuple instanceof Array ? permissionOrTuple[0] : permissionOrTuple;
        const permissionOptions = permissionOrTuple instanceof Array ? permissionOrTuple[1] : options;

        const replacedPermission: string = this.replacePlaceholders(permission, permissionOptions?.replace);
        const permissionKey = this.getPermissionKey(replacedPermission, permissionOptions);

        const cachedPermission = this.permissions.get(permissionKey);

        if (typeof cachedPermission === 'boolean') {
            return of(cachedPermission);
        }

        return this.batch('isAllowed', {
            stackId: permissionOptions?.stackId,
            permission: replacedPermission,
            models: permissionOptions?.models,
        }).pipe(
            map((value) => Boolean(value)),
            tap((value) => {
                this.permissions.set(permissionKey, value);
            }),
        );
    }

    /**
     * Sends a request to the API to get the value for multiple permissions, then checks if the user has all/some of them
     */
    isAllowedMany(permissions: string[], somePermissions: string[] = [], options?: PermissionOptions): Observable<boolean> {
        // Check if we already have the permissions

        const every: [ string, Observable<boolean> ][] = [ [ '', of(true) ] ];
        const some: [ string, Observable<boolean> ][] = [];

        for (const permission of permissions) {
            every.push([ permission, this.isAllowed(permission, options) ]);
        }

        for (const permission of somePermissions) {
            some.push([ permission, this.isAllowed(permission, options) ]);
        }

        const requiredPermissions = of(...every).pipe(
            mergeMap(([ permission, request ]) => request.pipe(map((response): [ string, boolean ] => [ permission, response ]))),
            reduce((acc, val) => {
                return acc && val[1];
            }, true),
        );

        if (somePermissions.length) {
            const someRequiredPermissions = of(...some).pipe(
                mergeMap(([ permission, request ]) => request.pipe(map((response): [ string, boolean ] => [ permission, response ]))),
                reduce((acc, val) => {
                    return acc || val[1];
                }, false),
            );

            return forkJoin([ requiredPermissions, someRequiredPermissions ]).pipe(
                map(([ required, someRequired ]) => required && someRequired),
            );
        }

        return requiredPermissions;
    }

    /**
     * Returns the permissions that the user does not have
     * Must be used after the same permissions were fetched with e.g. isAllowedMany
     */
    getDisallowedPermissions(permissions: string[], somePermissions: string[]) {
        const required = permissions.filter((p) => !this.getPermissionValue(p));
        const someRequired = this.getCombinedPermissionValue([], somePermissions) ? [] : somePermissions;

        return {
            required,
            someRequired,
        };
    }

    private replacePlaceholders(node: string, replace: Record<string, string | null | undefined> = {}) {
        let permission = node;

        if (!permission.includes('{')) {
            // Nothing to replace
            return permission;
        }

        Object.entries(replace).forEach(([ key, value ]) => {
            if (value == null) {
                return;
            }

            permission = permission.replace(`{${key}}`, String(value));
        });

        permission = permission.replace('{customer}', String(this.current.getCustomer()?.id));
        permission = permission.replace('{customer.setting_group_id}', String(this.current.getCustomer()?.settingGroupId));
        permission = permission.replace('{employee}', String(this.current.getEmployee()?.id));
        permission = permission.replace('{employee.customer_id}', String(this.current.getEmployee()?.customerId));
        permission = permission.replace('{user}', String(this.current.getMe().user?.id));

        return permission;
    }

    private batch(mode: 'canRead', options: CanReadOptions): Observable<ReadableFieldsResponse>;
    private batch(mode: 'permissionChildren', options: PermissionChildrenOptions): Observable<(string | number)[]>;
    private batch(mode: 'isAllowed', options: IsAllowedOptions): Observable<1 | 0>;
    private batch(mode: Mode, options: BatchOptions): Observable<BatchResponseElement> {
        if (environment.isTesting) {
            return of(mode === 'isAllowed' ? 0 : []);
        }

        clearTimeout(this.permissionBatchTimeout);
        const batchOptions: Batch = {
            stackId: options.stackId,
            options: {
                ...options,
                type: mode,
            },
        };
        const batchId: string = JSON.stringify(batchOptions);

        if (!this.permissionBatch) {
            this.permissionBatch = new Map<string, Batch>();
        }

        if (!this.permissionBatch.has(batchId)) {
            this.permissionBatch.set(batchId, batchOptions);
        }

        if (!this.permissionBatchSubject) {
            this.permissionBatchSubject = new Subject();
        }

        this.permissionBatchTimeout = window.setTimeout(() => {
            const subject = this.permissionBatchSubject;

            if (!subject || !this.permissionBatch?.size) {
                return;
            }

            const requests = new Map<number | undefined, Batch[]>(); // Group by stack id
            for (const [ uuid, batchOptions ] of this.permissionBatch.entries()) {
                if (!requests.has(batchOptions.stackId)) {
                    requests.set(batchOptions.stackId, []);
                }

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                requests.get(batchOptions.stackId)!.push({ ...batchOptions, uuid });
            }

            of(...Array.from(requests.entries()))
                .pipe(
                    mergeMap(([ stackId, requestOptions ]) => {
                        return this.http.post<BatchResponse>(`/users/${this.current.getUser().id}/is_allowed_many`, {
                            mode: 'multi',
                            stack: stackId,
                            calls: requestOptions.map((opts) => {
                                delete opts.options.stackId;
                                return opts.options;
                            }),
                        }).pipe(map((response) => {
                            return {
                                response,
                                uuids: requestOptions.map((opts) => opts.uuid),
                            };
                        }));
                    }, 2),
                    reduce((acc, result) => {
                        let i = 0;
                        for (const uuid of result.uuids) {
                            const response = result.response[i++];

                            if (uuid && response) {
                                acc.set(uuid, response);
                            }
                        }

                        return acc;
                    }, new Map<string, BatchResponseElement>()),
                )
                .subscribe((response) => {
                    subject.next(response);
                    subject.complete();
                });

            delete this.permissionBatchSubject;
            delete this.permissionBatch;
        }, 100);

        return this.permissionBatchSubject.asObservable().pipe(map((result) => result.get(batchId) as BatchResponseElement));
    }

    private getPermissionValue(permission: string, options?: PermissionOptions): boolean {
        const replacedPermission = this.replacePlaceholders(permission, options?.replace);

        return this.permissions.get(this.getPermissionKey(replacedPermission, options)) ?? false;
    }

    private getCombinedPermissionValue(permissions: string[], somePermissions: string[] = [], options?: PermissionOptions): boolean {
        const every = permissions.every((p) => this.getPermissionValue(p, options));
        const some = somePermissions.length ? somePermissions.some((p) => this.getPermissionValue(p, options)) : true;

        return every && some;
    }

    private getPermissionKey(permission: string, options?: PermissionOptions): string {
        return permission + (options?.models ? JSON.stringify(options.models) : '');
    }
}
