<eaw-dialog-header>
    <span title>{{(data.title || ('REORDER' | translate)) | async}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="loading">
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
        <mat-spinner></mat-spinner>
    </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="!loading" class="tw-flex tw-flex-col tw-gap-24">
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)" cdkDropListLockAxis="y">
        <div class="example-box tw-flex tw-flex-row tw-justify-between tw-items-center" *ngFor="let item of items" (click)="item.selected = !item.selected" cdkDrag cdkDragPreviewContainer="parent">
            <div class="ripple" matRipple [matRippleDisabled]="!!data.reorderOnly"></div>

            <div class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-8">
                <mat-icon class="drag-handle" (click)="$event.stopPropagation()" cdkDragHandle>drag_indicator</mat-icon>
                <span>{{item.text | async}}</span>
            </div>

            <mat-checkbox *ngIf="!data.reorderOnly" eawCheckboxHelper [(ngModel)]="item.selected"></mat-checkbox>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="undefined">{{'CANCEL' | translate | async}}</button>
    <button mat-button mat-raised-button color="primary" (click)="close()" [disabled]="loading">{{'REORDER' | translate | async}}</button>
</mat-dialog-actions>
