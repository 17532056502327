import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { DateTime } from 'luxon';

interface NightHoursParams {
    'employees[]'?: number[];
}

interface WeeklyNightHoursParams extends NightHoursParams {
    from: DateTime;
    to: DateTime;
}

interface AnnualNightHoursParams extends NightHoursParams {
    year: number;
}

interface NightHoursEmployeeResponse {
    employee_number: string;
    first_name: string;
    last_name: string;
    title: string | null;
    level: string;
    grade: string;
    effective_date: string | null;
    compensation_rate: number;
}

interface WeeklyNightHoursEmployeeResponse extends NightHoursEmployeeResponse {
    max_consecutive_weeks: number;
    hours_per_week: Record<string, number>;
    weeks_with_night_work: Record<string, boolean>;
}

interface AnnualNightHoursEmployeeResponse extends NightHoursEmployeeResponse {
    annual_threshold: number;
    hours_per_month: Record<number, number>;
    total_hours: number;
}

export type WeeklyNightHoursResponse = Record<number, WeeklyNightHoursEmployeeResponse>;

export type AnnualNightHoursResponse = Record<number, AnnualNightHoursEmployeeResponse>;

@Injectable({
    providedIn: 'root',
})
export class NightHoursReportService {

    constructor(@Inject(HttpClient) private http: HttpClient) { }

    getWeeklyNightHours(customerId: number, params: WeeklyNightHoursParams) {
        return this.http.get<WeeklyNightHoursResponse>(`customers/${customerId}/night_hours/weekly`, { params: formatHttpParams(params, [ 'from', 'to' ]) });
    }

    getAnnualNightHours(customerId: number, params: AnnualNightHoursParams) {
        return this.http.get<AnnualNightHoursResponse>(`customers/${customerId}/night_hours/yearly`, { params: { ...params } });
    }
}
