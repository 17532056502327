import { catchError, debounceTime, distinctUntilChanged, EMPTY, merge, Observable, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { OnTouched } from '../../types/on-touched';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { AfterViewInit, booleanAttribute, ChangeDetectionStrategy, Component, computed, DoCheck, effect, input, OnDestroy, OnInit, Optional, Self, signal, Signal, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NgControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Employee } from '../../models/employee';
import { User } from '../../models/user';
import { AutoCompleteData, AutocompleteGetter, AutocompleteSetter, AutocompleteTemplate } from '../../autocompletes/autocomplete';
import { OnChange } from '../../types/on-change';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { NumberPipe } from '../../pipes/number.pipe';
import { DateTimePipe } from '../../pipes/date-time.pipe';
import { MatChipGrid, MatChipInput, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { TranslateSyncPipe } from '../../pipes/translate-sync.pipe';

@Component({
    standalone: true,
    selector: 'eaw-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrl: './autocomplete.component.scss',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        MatAutocompleteModule,
        ProfilePictureComponent,
        TranslatePipe,
        NumberPipe,
        DateTimePipe,
        MatChipGrid,
        MatChipRow,
        MatChipInput,
        MatChipRemove,
        TranslateSyncPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent<Item extends Record<string, any>> implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy, DoCheck {
    @ViewChild(MatAutocomplete) matAutocomplete?: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger?: MatAutocompleteTrigger;

    @ViewChild('defaultAutocompleteOption') defaultAutocompleteOption!: TemplateRef<unknown>;
    @ViewChild('dialCodeAutocompleteOption') dialCodeAutocompleteOption!: TemplateRef<unknown>;
    @ViewChild('employeeAutocompleteOption') employeeAutocompleteOption!: TemplateRef<unknown>;
    @ViewChild('tariffAutocompleteOption') tariffAutocompleteOption!: TemplateRef<unknown>;
    @ViewChild('shiftEmployeeAutocompleteOption') shiftEmployeeAutocompleteOption!: TemplateRef<unknown>;

    options = input.required<AutoCompleteData<Item>>();
    getter = input.required<AutocompleteGetter<Item>>();
    setter = input.required<AutocompleteSetter<Item>>();
    disableItem = input<(item: Item) => boolean>();

    customerId = input<number>();
    // What things affects the getter?
    triggers = input<(FormGroup | FormControl | Observable<unknown>)[]>();
    // What to display in the input field
    display = input<(keyof Item) | ((item: Item) => string)>();
    label = input<Promise<string | null>>();
    useCustomHint = input(false, { transform: booleanAttribute });
    hint = input<Promise<string> | null>();
    // Use this together with the "eawPrefix" content projection to add a prefix to the input field
    prefix = input<string>();
    trackByKey = input<keyof Item>();
    debounce = input<number>(1000);
    // How many characters are required for the filter to be passed along
    filterRequirement = input<number>(0);
    disabled = input<boolean>(false);
    multiSelect = input(false, { transform: booleanAttribute });

    private destroySubject = new Subject<boolean>();
    protected debounceTime: Signal<number>;
    protected filterChars: Signal<number>;
    protected valueDisplay: Signal<(keyof Item) | ((item: Item) => string)>;
    protected inputLabel: Signal<Promise<string | null>>;
    protected optionTemplateRef: Signal<TemplateRef<unknown>>;
    protected writeSubject = new Subject<unknown>();
    protected valueChangeSubject = new Subject<unknown>();
    protected focused = signal(false);
    protected onChange?: OnChange<Item | Item[] | null | undefined>;
    protected onTouched?: OnTouched;
    protected hasChangedGetter = signal(false);
    /**
     * Holds the value of the next write value. Used on init if write it called before init
     */
    protected nextWrite = signal(undefined as unknown);
    protected name = signal((+new Date()).toString());
    protected optionTemplate: Signal<AutocompleteTemplate>;
    protected loading = signal(false);
    protected filter = signal('');
    protected total = signal(0);
    protected results = signal(0);
    protected item = signal(undefined as Item | undefined);
    protected profilePictureUser = signal(undefined as User | undefined);
    protected filterControl = new FormControl<string | Item>('');
    protected filteredItems = signal(of([]) as Observable<Item[]>);
    protected selectedItems = signal([] as Item[]);

    constructor(
        @Self() @Optional() protected ngControl: NgControl,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        this.optionTemplate = computed(() => this.options().optionTemplate || 'default');

        this.optionTemplateRef = computed(() => this.handleTemplate());

        // Use the inputs if they are set, otherwise use the options
        this.debounceTime = computed(() => {
            const options = this.options();
            return this.debounce() ?? options.debounce;
        });

        this.filterChars = computed(() => {
            const options = this.options();
            return this.filterRequirement() ?? options.filterRequirement;
        });

        this.valueDisplay = computed(() => {
            const options = this.options();
            return this.display() ?? options.display;
        });

        this.inputLabel = computed(() => {
            const options = this.options();
            return this.label() ?? options.label ?? Promise.resolve(null);
        });

        effect(() => this.handleTemplate());
        effect(() => this.setDisabledState(this.disabled()));
    }

    ngOnInit() {
        this.handleWrite();
        this.handleFilterChange();
        this.listenToTriggers();

        // If the getter is called before the component is initialized
        this.writeSubject.next(this.nextWrite());
    }

    ngAfterViewInit() {
        this.handleTemplate();
        this.onAutocompleteOpen();
    }

    ngOnDestroy() {
        this.destroySubject.next(true);
        this.writeSubject.complete();
        this.destroySubject.complete();
        this.valueChangeSubject.complete();
    }

    ngDoCheck() {
        if (this.ngControl.control?.hasValidator(Validators.required)) {
            this.filterControl.addValidators(Validators.required);
        } else {
            this.filterControl.removeValidators(Validators.required);
        }

        if (this.filterControl.dirty) {
            this.ngControl.control?.markAsDirty();
        } else {
            this.ngControl.control?.markAsPristine();
        }
    }

    protected onAutocompleteOpen() {
        this.matAutocomplete?.opened.pipe(takeUntil(this.destroySubject)).subscribe(() => {
            if (this.hasChangedGetter()) {
                this.hasChangedGetter.set(false);
                this.setFilteredItems('');
            }
        });
    }

    protected handleTemplate() {
        switch (this.optionTemplate()) {
            case 'employee': {
                return this.employeeAutocompleteOption;
            }
            case 'shiftEmployee': {
                return this.shiftEmployeeAutocompleteOption;
            }
            case 'dialCode': {
                return this.dialCodeAutocompleteOption;
            }
            case 'tariff': {
                return this.tariffAutocompleteOption;
            }
            default: {
                return this.defaultAutocompleteOption;
            }
        }
    }

    setFilteredItems(filter?: string) {
        this.loading.set(true);
        this.getter()(filter).pipe(take(1)).subscribe((resp) => {
            this.loading.set(false);
            this.total.set(resp.total);
            this.results.set(resp.data.length);
            this.filteredItems.set(of(resp.data));
        });
    }

    private listenToTriggers() {
        const observables = this.triggers()?.reduce((acc, trigger) => {
            if (trigger instanceof Observable) {
                acc.push(trigger);
            }
            if (trigger instanceof AbstractControl) {
                acc.push(trigger.valueChanges);
            }

            return acc;
        }, [] as Observable<unknown>[]) || [];

        merge(...observables).pipe(takeUntil(this.destroySubject)).subscribe(() => {
            this.hasChangedGetter.set(true);
            this.clear(false);
        });
    }

    private allowedKey(key?: KeyboardEvent['key']) {
        if (!key) {
            return true;
        }

        // If the key cannot be identified, the returned value is Unidentified.
        if (key === 'Unidentified') {
            return true;
        }

        const regex = new RegExp('^[a-z0-9-+]$');
        return key === 'Backspace' || regex.test(key.toLowerCase());
    }

    protected async keyup(event: KeyboardEvent) {
        // Check if allowed
        if (!this.allowedKey(event.key)) {
            return;
        }

        const target = event.target as HTMLInputElement;
        const value = target.value;

        if (this.item()) {
            await this.deselect();

            this.item.set(undefined);
            this.onChange?.(undefined);
            this.matAutocompleteTrigger?.openPanel();
        } else {
            this.valueChangeSubject.next(value);
        }
    }

    private handleFilterChange() {
        this.valueChangeSubject.pipe(
            takeUntil(this.destroySubject),
            debounceTime(this.debounceTime()),
            switchMap((filter) => {
                if (typeof filter !== 'string') {
                    return '';
                }

                if (this.filterChars() && filter) {
                    if (filter.length === 0) {
                        return of('');
                    } else if (filter.length >= this.filterChars()) {
                        return of(filter);
                    }
                } else {
                    return filter?.length ? of(filter) : of('');
                }

                return '';
            }),
            distinctUntilChanged(),
            switchMap((filter) => {
                this.loading.set(true);
                this.filter.set(filter);
                return this.getter()(filter);
            }),
            tap((resp) => {
                this.loading.set(false);
                this.total.set(resp.total);
                this.results.set(resp.data.length);
                this.filteredItems.set(of(resp.data));
            }),
        ).subscribe();
    }

    private handleWrite() {
        this.writeSubject.pipe(
            switchMap((val) => {
                this.filterControl.disable({ emitEvent: false });
                return of(val);
            }),
            takeUntil(this.destroySubject),
            debounceTime(1000),
            switchMap((val) => {
                return this.setter()(val).pipe(
                    tap(() => {
                        this.loading.set(false);

                        if (this.ngControl.enabled) {
                            this.filterControl.enable({ emitEvent: false });
                        }
                    }),
                    catchError((e) => {
                        console.error(e);
                        return EMPTY;
                    }),
                );
            }),
        ).subscribe((item) => {
            if (this.multiSelect()) {
                if (item) {
                    this.selectedItems.update((items) => (items.concat([ item ])));
                    this.filterControl.setValue('');
                    this.onChange?.(this.selectedItems());
                }
                return;
            }

            this.total.set(item ? 1 : 0);

            if (item) {
                this.focused.set(true);
                this.filteredItems.set(of([ item ]));
                this.item.set(item);

                setTimeout(() => {
                    for (const option of this.matAutocomplete?.options || []) {
                        if (!option.disabled) {
                            option.select();
                        }
                    }
                }, 10);
            }

            this.filterControl.setValue(item ?? null, { emitEvent: false });
            this.onChange?.(item);
        });
    }

    // Has to be an arrow function to access this
    protected autocompleteDisplay = (val?: unknown): string => {
        if (!val) {
            return '';
        }

        if (typeof val === 'string') {
            return val;
        }

        if (typeof val === 'object') {
            const item = val as Item;
            const display = this.valueDisplay();

            if (typeof display === 'function') {
                return display(item);
            }
            if (display) {
                return item[display];
            }
            if ('name' in item) {
                return item['name'];
            }
        }

        return '';
    };

    protected setProfilePictureUser(item: unknown) {
        this.profilePictureUser.set(item instanceof Employee && this.optionTemplate() === 'employee' ? item?.user : undefined);
    }

    protected onSelectionChange(event: MatAutocompleteSelectedEvent) {
        // Set the item
        const item = event.option.value as Item;
        if (this.multiSelect()) {
            event.option.deselect();
            this.selectItem(item);
            return;
        }
        this.item.set(item);
        this.setProfilePictureUser(item);

        // We only have one item now, so update accordingly
        this.total.set(1);
        this.results.set(1);
        this.filteredItems.set(of([ item ]));

        // Change
        this.onChange?.(item);
    }

    // For multi-select
    protected selectItem(item: Item) {
        this.filterControl.setValue('');
        const trackByKey = this.trackByKey() || this.options().trackByKey;
        if (this.selectedItems().find((i) => i[trackByKey] === item[trackByKey])) {
            return;
        }

        this.selectedItems.update((items) => (items.concat([ item ])));
        this.onChange?.(this.selectedItems());
    }

    // For multi-select
    protected removeItem(item: Item) {
        const trackByKey = this.trackByKey() || this.options().trackByKey;
        this.selectedItems.update((items) => items.filter((i) => i[trackByKey] !== item[trackByKey]));
        this.onChange?.(this.selectedItems());
    }

    protected focus() {
        if (!this.focused()) {
            const value = this.filterControl.value;
            switch (true) {
                case value == null: {
                    this.setFilteredItems('');
                    break;
                }
                case typeof value === 'string': {
                    this.setFilteredItems(value as string);
                    break;
                }
                default: {
                    this.setFilteredItems(this.autocompleteDisplay(value as Item));
                }
            }
        }

        this.onTouched?.();
        this.focused.set(true);
    }

    protected deselect() {
        return new Promise((resolve) => {
            for (const option of this.matAutocomplete?.options || []) {
                if (option.selected) {
                    option.onSelectionChange.pipe(take(1)).subscribe(() => {
                        resolve(undefined);
                    });

                    option.deselect();
                }
            }
        });
    }

    clear(setItems = true) {
        void this.deselect();
        this.item.set(undefined);
        this.filteredItems.set(of([]));
        this.total.set(0);
        this.results.set(0);
        this.profilePictureUser.set(undefined);

        this.onChange?.(undefined);
        this.filterControl.setValue('');
        this.filterControl.markAsDirty();
        this.filterControl.markAsTouched();

        if (setItems) {
            this.setFilteredItems('');
        }
    }

    registerOnChange(onChange: OnChange<Item | Item[] | null | undefined>): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: OnTouched): void {
        this.onTouched = onTouched;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.filterControl.disable({ emitEvent: false });
        } else {
            this.filterControl.enable({ emitEvent: false });
        }
    }

    writeValue(val?: string | Item | null): void {
        if (!val && this.filterControl.value) {
            this.clear();
            return;
        }

        this.nextWrite.set(val);
        this.loading.set(true);
        this.writeSubject.next(val);
    }
}
