// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';

module('eaw.dashboard').component('standbyWidget', {
    template: `<standby-list ng-if="$widget.days"
              widget="$widget.widget"
              days="$widget.days"
              customer-id="$widget.eawWidget.widget.customer"
              employee="$widget.eawWidget.widget.employee"
              max-date="$widget.maxDate">
</standby-list>
`,
    require: {
        eawWidget: '^eawWidget',
    },
    controllerAs: '$widget',
    controller: [ '$timeout', function($timeout) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.createDays();
        };

        ctrl.$postLink = () => {
            $timeout(() => {
                ctrl.eawWidget.setLoading(false);
            }, 16);
        };

        ctrl.createDays = () => {
            const now = moment();
            let index = 0;
            ctrl.days = [];

            // max set standby end of next month.
            ctrl.maxDate = moment().add(60, 'd').endOf('d');

            while (now.isBefore(ctrl.maxDate)) {
                ctrl.days.push({
                    moment: now.clone(),
                    index,
                    dayName: now.format('dddd'),
                    date: now.format('LL'),
                });
                index++;
                now.add(1, 'd');
            }
        };
    } ],
});
