import { WeatherForecastTimeSeriesResponse } from '../interfaces/weather-forecast';

export class WeatherForecastTime {
    time: string;
    suffix: string;
    unit: 'C' | 'F';
    symbol: string;
    temperature: number;
    isNegative: boolean;
    windSpeed?: number | undefined;
    windDirection?: number | undefined;
    precipitationAmount?: number | undefined;

    constructor(resp: WeatherForecastTimeSeriesResponse) {
        this.time = resp.time;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const period = (resp.data.next_6_hours || resp.data.next_1_hours || resp.data.next_12_hours)!;
        this.symbol = period.summary.symbol_code;
        this.temperature = resp.data.instant.details.air_temperature;
        this.suffix = '°';
        this.unit = 'C';
        this.isNegative = resp.data.instant.details.air_temperature < 0;
        this.windSpeed = resp.data.instant.details.wind_speed;
        this.windDirection = resp.data.instant.details.wind_from_direction;
        this.precipitationAmount = period.details.precipitation_amount;
    }

    convertToFahrenheit() {
        this.temperature = this.temperature * 9 / 5 + 32;
        this.unit = 'F';
    }
}
