// @ts-nocheck
import { module } from 'angular';

module('eaw').directive('eawOnTabEnter', function() {
    return function(scope, element, attrs) {
        function eawOnTabEnter(e) {
            const valid = [
                e.key === 'Tab',
                e.key === 'Enter',
                e.which === 9,
                e.which === 13,
                e.keyCode === 9,
                e.keyCode === 13,
            ];

            if (valid.some((x) => x)) {
                e.preventDefault();
                e.stopPropagation();

                scope.$apply(() => {
                    scope.$eval(attrs.eawOnTabEnter);
                });
            }
        }

        element[0].addEventListener('keydown', eawOnTabEnter);

        scope.$on('$destroy', () => {
            element[0].removeEventListener('keydown', eawOnTabEnter);
        });
    };
});
