import { Component } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { OnChange } from '../../../shared/types/on-change';
import { OnTouched } from '../../../shared/types/on-touched';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { EawValidators } from '../../../shared/validators/eaw-validators';

type PwdConfirm = { password1: FormControl<string>, password2: FormControl<string> };

@Component({
    selector: 'eaw-password',
    templateUrl: './password.component.html',
    styleUrl: './password.component.scss' ,
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PasswordComponent,
        },
    ],
})
export class PasswordComponent implements ControlValueAccessor {
    minLength = 8;
    showPwd = false;
    showConfirmPwd = false;
    hasMinLength = false;
    hasLowercase = false;
    hasUppercase = false;
    hasNumber = false;
    hasSpecial = false;
    isEqual = false;
    onChange: OnChange<string[]> = (_: string[]) => {};
    onTouched: OnTouched = () => {};
    validators = [
        Validators.minLength(this.minLength),
        EawValidators.pattern(/[a-z]+/, 'lowercase'),
        EawValidators.pattern(/[A-Z]+/, 'uppercase'),
        EawValidators.pattern(/[0-9]+/, 'number'),
        EawValidators.pattern(/[^a-zA-Z0-9]/, 'special'),
    ];

    form: FormGroup<PwdConfirm> = new FormGroup<PwdConfirm>({
        password1: new FormControl('', {
            validators: this.validators,
            nonNullable: true,
        }),
        password2: new FormControl('', {
            validators: this.validators,
            nonNullable: true,
        }),
    });

    constructor() {
        this.form.valueChanges.subscribe(() => {
            this.hasMinLength = this.checkField('minlength');
            this.hasLowercase = this.checkField('lowercase');
            this.hasUppercase = this.checkField('uppercase');
            this.hasNumber = this.checkField('number');
            this.hasSpecial = this.checkField('special');
            this.isEqual = this.checkEqual();

            const change = this.form.valid ? [ this.form.get('password1')?.value || '', this.form.get('password2')?.value || '' ] : [];

            this.onChange(change);
        });
    }

    checkEqual() {
        return !!(this.form.get('password1')?.value.length && this.form.get('password2')?.value.length && this.form.get('password1')?.value === this.form.get('password2')?.value);
    }

    checkField(errorField: string) {
        const password1 = this.form.get('password1');
        const password1Valid = password1?.value.length && !password1?.errors?.[errorField];
        const password2 = this.form.get('password2');
        const password2Valid = password2?.value.length && !password2?.errors?.[errorField];

        return !!(password1Valid || password2Valid);
    }

    markAsTouched() {
        if (this.form.get('password1')?.touched || this.form.get('password2')?.touched) {
            return;
        }
        this.onTouched();
    }

    registerOnChange(onChange: OnChange<string[]>): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: OnTouched): void {
        this.onTouched = onTouched;
    }

    writeValue([ password1, password2 ]: [string, string]): void {
        this.form.setValue({
            password1,
            password2,
        });
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }
}
