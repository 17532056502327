// @ts-nocheck
import { module } from 'angular';

module('eaw.dashboard').component('employeeAlertsWidget', {
    template: `<h2 ng-bind="$ctrl.total"></h2>`,
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$ctrl',
    controller: [ 'EawResource', function(EawResource) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.loadAlerts();

            ctrl.mini.dialogComponents = [ {
                name: 'dashEmployeeAlertsList',
                label: 'widgets:EMPLOYEE_ALERTS',
                bindings: {
                    getAlerts: ctrl.getAlerts,
                    deleteAlert: ctrl.deleteAlert,
                    resetTotal: ctrl.resetTotal,
                },
                params: {
                    getAlerts: [ 'pagination ' ],
                    deleteAlert: [ 'id' ],
                    resetTotal: [],
                },
            } ];
        };

        ctrl.loadAlerts = () => {
            ctrl.getAlerts().then((resp) => {
                ctrl.alerts = resp.data;
                ctrl.total = resp.total;
            }).finally(() => {
                ctrl.mini.loading(false);
            });
        };

        ctrl.$onDestroy = () => {
            ctrl.getAlertsRequest?.$cancelRequest?.();
            ctrl.deleteAlertRequest?.$cancelRequest?.();
        };

        ctrl.getAlerts = function getAlerts(pagination) {
            ctrl.getAlertsRequest = EawResource.create('/customers/:customer_id/triggered_alerts').get({
                customer_id: ctrl.mini.widget.customer,
                ...pagination,
            });
            return ctrl.getAlertsRequest.$promise;
        };

        ctrl.resetTotal = () => {
            ctrl.loadAlerts();
        };

        ctrl.deleteAlert = (id) => {
            ctrl.deleteAlertRequest = EawResource.create('/customers/:customer_id/triggered_alerts/:id').delete({
                customer_id: ctrl.mini.widget.customer,
                id,
            });
            return ctrl.deleteAlertRequest.$promise;
        };
    } ],
});
