// @ts-nocheck
import { t } from 'i18next';
import { uniqueId } from './easy-funcs.service';
import { module } from 'angular';
import { FileSizeFormatter } from './file-size-formatter.service';
import { Mobile } from '../../../../utils/eaw-mobile';
import { CurrentOld } from '../../../current.factory';

/**
 * @ngdoc service
 * @name FileService
 * @description
 * Download files via ajax.
 */
module('eaw.misc').service('FileService', [ '$http', 'Upload', 'ToastService', '$rootScope', function($http, Upload, ToastService, $rootScope) {
    const s = this;
    const filesizeFormatter = new FileSizeFormatter(CurrentOld.languageTag, 'short');
    /**
     * Start download via S3 url
     * OBS! Because of the paramserializer, this does not work with our interceptors.
     * @param {string} url      - API url where we get S3 url
     */
    s.downloadUrl = (url) => $http({
        url,
        method: 'GET',
        params: {
            url: 1,
        },
        paramSerializer: '$httpParamSerializerJQLike',
    }).then((resp) => resp.data.url);
    /**
     * Downloads a file.
     * @param url
     * @param method 'get', 'post' etc. @see angularjs $http-service docs
     * @param params
     * @param button
     */
    s.download = (url, method, params, button) => {
        ToastService.toast(t('DOWNLOAD_STARTED'));
        return s.request(url, method, params).then((response) => {
            ToastService.toast(t('DOWNLOAD_FINISHED'));
            // Check if it has content-disposition, if not, open it in browser (?)
            const file = new Blob([ response.data ], {
                type: response.headers()['content-type'],
            });
            const a = button || document.createElement('a');
            a.href = URL.createObjectURL(file);
            a.target = '_blank';
            a.download = response.headers()['content-disposition'].split(';')[1].trim().split('=')[1].replace(/"/g, '');
            a.onclick = () => {
                a.classList.add('hidden');
            };
            if (Mobile.isMobile) {
                Mobile.openBrowser(a.href);
            } else if (button) {
                button.innerText = a.download;
                button.classList.remove('hidden');
            } else {
                document.body.appendChild(a);
                a.click();
            }
            return file;
        });
    };
    // OBS! Because of the paramserializer, this does not work with our interceptors.
    s.request = (url, method, params) => $http({
        url,
        params: params && Object.keys(params).length ? params : undefined,
        paramSerializer: '$httpParamSerializerJQLike',
        method,
        responseType: 'blob',
    });
    s.formatFile = (file) => {
        file.id = uniqueId('file-'); // Add a unique identifier
        file.sizeText = filesizeFormatter.format(file.size);
    };
    s.broadcastFileUpload = (event, file, status, upload) => {
        $rootScope.$broadcast('file:uploading', {
            event,
            upload,
            status,
            file,
            progress: event ? event.loaded / event.total : 0,
        });
    };
    /**
     * @param url  the url to send the file.
     * @param file (from ng-file-select element)
     * @param fieldName name the server expects the file(s) to have.
     * @param [extraData] extra data to send with
     */
    s.upload = (url, file, fieldName, extraData) => {
        const data = {};
        data[fieldName] = file;
        const upload = Upload.upload({
            url,
            data: {
                ...data,
                ...extraData,
            },
        });
        s.formatFile(file); // Do some formatting
        s.broadcastFileUpload(null, file, 'init', upload); // Broadcast on init
        upload.then((resp) => {
            s.broadcastFileUpload(resp, file, 'success', upload);
            return resp;
        }, (err) => {
            const status = err.xhrStatus === 'abort' ? 'abort' : 'error';
            s.broadcastFileUpload(err, file, status, upload);
        }, (evt) => {
            s.broadcastFileUpload(evt, file, 'uploading', upload);
        });
        return upload;
    };
    return s;
} ]);
