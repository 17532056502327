// @ts-nocheck
import { module } from 'angular';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.company').component('companySettingsAbsenceTypes', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="ABSENCE_TYPE_plural"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <md-progress-circular ng-if="$ctrl.loading" class="md-progress-center-absolute"></md-progress-circular>

        <eaw-accordion multi-toggle="true">
            <eaw-accordion-panel ng-repeat="absenceType in $ctrl.absenceTypes track by absenceType.id">
                <panel-header class="tw-flex tw-gap-8">
                    <div layout="row" layout-align="start center">
                        <div class="type-color" ng-style="{background: absenceType.color}"></div>

                        <div layout="column">
                            <span ng-bind="absenceType.name | eawTranslate:'absence_types'"></span>
                        </div>
                    </div>
                    <span flex></span>
                    <span ng-i18next="[i18next]({count: absenceType.links.length})company:X_PAY_TYPES"></span>
                </panel-header>
                <panel-content class="tw-p-0">
                    <table class="table table-bordered-bottom">
                        <thead>
                        <tr>
                            <th ng-i18next="NAME"></th>
                            <th ng-i18next="FROM"></th>
                            <th ng-i18next="TO"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr ng-repeat="link in absenceType.links track by link.id">
                            <td ng-bind="link.name"></td>
                            <td ng-bind="link.from | moment:'LL'"></td>
                            <td ng-bind="link.to | moment:'LL'"></td>
                        </tr>
                        </tbody>
                    </table>
                </panel-content>
            </eaw-accordion-panel>
        </eaw-accordion>
    </md-card-content>
</md-card>
`,
    bindings: {
        customer: '<',
    },
    controller: [ 'AbsenceTypeFactory', function(AbsenceTypeFactory) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.includeInactive = true;
            ctrl.loading = true;
            AbsenceTypeFactory.getAll({
                entityId: ctrl.customer.id,
                per_page: 250,
                includeInactive: ctrl.includeInactive,
                with: ctrl.includeInactive ? [ 'payTypeLinks' ] : [ 'activePayTypeLinks' ],
            }).then((resp) => {
                ctrl.absenceTypes = resp.data.map((type) => {
                    type.links = ctrl.includeInactive ? type.pay_type_links : type.active_pay_type_links;
                    type.links = type.links?.filter((t) => t.owner_id === ctrl.customer.id) || [];
                    return type;
                });
                ctrl.absenceTypes = sort(ctrl.absenceTypes, CurrentOld.languageTag, [ (x) => x.name ], [ 'asc' ]);
            }).finally(() => delete ctrl.loading);
        };
    } ],
});
