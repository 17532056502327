// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';
import { momentToDateTime } from '../../../../../../shared/angularjs/moment-to-date-time';
import { scheduleDaySelectorDate } from '../../../../../signals';

const template1 = `
  <md-button class="md-icon-button" ng-click="$ctrl.changeDayInternal(-1)" ng-disabled="$ctrl.index == 0">
        <md-icon ng-bind="'arrow_back'"></md-icon>
      </md-button>

<div layout="column">
    <h5 ng-bind="$ctrl.day.dayName"></h5>
    <h3 ng-if="!$ctrl.hideDate" ng-bind="$ctrl.day.date"></h3>
    <h3 ng-if="$ctrl.hideDate" ng-i18next="[i18next]({number: $ctrl.index + 1})DAY_N"></h3>
</div>

  <md-button class="md-icon-button" ng-click="$ctrl.changeDayInternal(1)" ng-disabled="$ctrl.index == $ctrl.lastIndex">
        <md-icon ng-bind="'arrow_forward'"></md-icon>
      </md-button>
`;

module('eaw.scheduling').component('daySelector', {
    template: template1,
    bindings: {
        days: '<',
        hideDate: '<',
    },
    require: {
        ngModel: 'ngModel',
    },
    controller: [ '$rootScope', '$scope', 'scheduleTable', function($rootScope, $scope, scheduleTable) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.lastIndex = ctrl.days.length - 1;
            ctrl.ngModel.$formatters.unshift(ctrl.formatter);

            // Find the day we are currently on in the table
            ctrl.findDay(scheduleTable.getCurrentDate());

            // Listen for changes
            $scope.$on('schedule:dayChanged', ctrl.dayChangedEvent);
        };

        ctrl.dayChangedEvent = (event, date) => {
            const currentDate = ctrl.day?.moment;

            if (moment.isMoment(currentDate)) {
                if (!currentDate.isSame(date, 'd')) {
                    ctrl.findDay(date);
                }
            }
        };

        ctrl.findDay = (date) => {
            // Find day object that has same date as what we got from the schedule
            ctrl.day = ctrl.days.find((d) => d.moment.isSame(date, 'd'));

            // Set index to 0 to that we can add the new index
            ctrl.index = 0;

            // Add the new index
            ctrl.changeDay(ctrl.day?.index || 0);

            // Tell stuff to update
            ctrl.broadcast();
        };

        ctrl.formatter = (val) => {
            if (val) {
                ctrl.day = val;
                ctrl.index = val.index;
            }

            return val;
        };

        ctrl.broadcast = () => {
            const dateTime = momentToDateTime(ctrl.day.moment);
            if (dateTime?.isValid) {
                scheduleDaySelectorDate.set(dateTime);
            }

            $rootScope.$broadcast('sidebarchildren:dayChanged', ctrl.day);
        };

        ctrl.changeDayInternal = (add) => {
            // Change day as normal before we do the broadcast
            ctrl.changeDay(add);
            ctrl.broadcast();
        };

        ctrl.changeDay = (add) => {
            // Add new value to index
            ctrl.index = (ctrl.index || 0) + add;

            // Set day to the day object at that index
            ctrl.day = ctrl.days[ctrl.index];

            // Set the view value
            ctrl.ngModel.setViewValue(ctrl.day);
        };
    } ],
});
