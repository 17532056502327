<mat-card id="reset-password-card" class="login-card">
    <mat-card-content class="card-head">
        <eaw-logo height="28px"></eaw-logo>
        <span class="card-title">
            {{'RESET_PASSWORD' | translate:'login' | async}}
        </span>
    </mat-card-content>

    <mat-card-content>
        <form id="forgot" class="tw-flex tw-flex-col" [formGroup]="form" (ngSubmit)="submit()">
            <mat-form-field>
                <mat-label>{{'EMAIL' | translate | async}}</mat-label>
                <mat-icon matPrefix>person</mat-icon>
                <input matInput formControlName="email" autocomplete="username" required>
            </mat-form-field>

            <eaw-password formControlName="password"></eaw-password>
        </form>
    </mat-card-content>

    <mat-card-actions>
        <div>
            <button type="submit"
                    mat-button
                    mat-raised-button
                    color="accent"
                    form="forgot"
                    [disabled]=" form.invalid || submitting">
                {{'SUBMIT' | translate | async}}
            </button>
        </div>
    </mat-card-actions>
</mat-card>
