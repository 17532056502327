import { Component, Inject } from '@angular/core';
import { DialogComponent } from '../../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EmployeeService } from '../../../../shared/http/employee.service';
import { FindInGroupData } from './find-employee-dialog.service';
import { UIRouter } from '@uirouter/core';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-find-employee-dialog',
    templateUrl: './find-employee-dialog.component.html',
    styleUrl: './find-employee-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class FindEmployeeDialogComponent extends DialogComponent {
    employeeId = new FormControl<number | null>(null, Validators.required);
    error?: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: FindInGroupData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<FindEmployeeDialogComponent>,
        @Inject(EmployeeService) private service: EmployeeService,
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
        super(dialogRef, data);
    }

    // Redirect user to an employee page if it finds one, re-enable form field and display error if it doesn't
    toEmployee() {
        if (this.employeeId.invalid) {
            return;
        }

        this.employeeId.disable();
        this.service.getForCustomerGroup(this.data.groupId, this.employeeId.value!).subscribe({
            next: (emp) => this.uiRouter.stateService.go('eaw/app/chain_ops/employee_list/view/information', {
                emp: emp.id,
                location: emp.customerId,
                group: this.data.groupId,
            }).then(() => this.dialogRef.close()),
            error: (err) => {
                this.error = err.error.error;
                this.employeeId.enable();
            },
        });
    }
}
