import { Inject, Injectable } from '@angular/core';
import { Storage } from '../../shared/utils/storage';
import { distinctUntilChanged, fromEvent, merge, ReplaySubject, throttleTime } from 'rxjs';
import { Duration } from 'luxon';
import { LoginService } from '../../shared/services/login.service';
import { CurrentService } from '../../shared/services/current.service';

@Injectable({
    providedIn: 'root',
})
export class IdleService {
    private isPublic = false;
    private events = [ 'click', 'touchstart', 'mousemove', 'keyup' ];
    private throttle = Duration.fromObject({ seconds: 1 }).as('milliseconds');
    private threshold = Duration.fromObject({ minutes: 10 }).as('milliseconds');
    private timeoutId?: number;
    private _idle = false;
    private idleSubject = new ReplaySubject<boolean>(1);

    constructor(
        @Inject(LoginService) protected loginService: LoginService,
        @Inject(CurrentService) protected currentService: CurrentService,
    ) {
        Storage.getItem<boolean>('publicComputer').then((isPublic) => {
            this.isPublic = isPublic || false;
        });

        merge(...this.events.map((event) => fromEvent(document, event)))
            .pipe(throttleTime(this.throttle))
            .subscribe(() => this.createTimeout());

        this.createTimeout();
    }

    idleChange() {
        return this.idleSubject.asObservable().pipe(distinctUntilChanged());
    }

    isIdle() {
        return this._idle;
    }

    private createTimeout() {
        this._idle = false;
        this.idleSubject.next(false);

        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = window.setTimeout(() => {
            this._idle = true;
            this.idleSubject.next(true);

            if (this.isPublic && this.loginService.isLoggedIn()) {
                void this.loginService.logout(this.currentService.getMe().authedAs, { message: Promise.resolve('Logging out due to inactivity') });
            }
        }, this.threshold);
    }

    setPublic(value: boolean) {
        this.isPublic = value;
        void Storage.setItem('publicComputer', value);
    }
}
