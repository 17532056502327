import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { EmployeePayRateService } from '../../../payroll/http/employee-pay-rate.service';
import { DateTime } from 'luxon';
import { PayRate } from '../../../payroll/models/pay-rate';
import { catchError, debounceTime, EMPTY, forkJoin, map, Observable, of, ReplaySubject, shareReplay, switchMap, tap } from 'rxjs';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { Contract } from '../../../shared/models/contract';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { Namespace } from '../../../shared/enums/namespace';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { ContractService } from '../../../shared/http/contract.service';
import { MatDialog } from '@angular/material/dialog';
import { ContractDialogComponent, ContractDialogData, ContractDialogReturn } from '../../dialogs/contract-dialog/contract-dialog.component';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTablePagination, DataTableRequest } from '../../../data-table/types/data-table';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { PayRateDialogComponent, PayRateDialogData, PayRateDialogReturn } from '../../dialogs/pay-rate-dialog/pay-rate-dialog.component';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { EmployeeService } from '../../../shared/http/employee.service';
import { Employee } from '../../../shared/models/employee';
import { CombinedContractDialogComponent, CombinedContractDialogData, CombinedContractDialogReturn } from '../../dialogs/combined-contract-dialog/combined-contract-dialog.component';
import { CombinedContractService } from '../../http/combined-contract.service';
import { SettingService } from '../../../shared/http/setting.service';
import { CombinedContract } from '../../models/combined-contract';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { HourDistribution } from '../../models/hour-distribution';
import { Availability } from '../../../availability/models/availability';
import { AvailabilityDaysComponent } from '../../../availability/components/availability-days/availability-days.component';
import { HourDistributionDisplayComponent } from '../../../shared/components/hour-distribution-display/hour-distribution-display.component';
import { ApiModel } from '../../../shared/enums/api-model';
import { CustomerCustomFieldService } from '../../../shared/http/customer-custom-field.service';
import { DataTableCustomFieldColumn } from '../../../data-table/types/data-table-custom-field-column';
import { CurrentService } from '../../../shared/services/current.service';
import { ContractDownloadDialogComponent, ContractDownloadDialogData } from '../../dialogs/contract-download-dialog/contract-download-dialog.component';

@Component({
    selector: 'eaw-employee-pay-contract',
    templateUrl: './employee-pay-contract.component.html',
    styleUrl: './employee-pay-contract.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        NgIf,
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        MatCheckboxModule,
        CheckboxHelperDirective,
        ReactiveFormsModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        AvailabilityDaysComponent,
        HourDistributionDisplayComponent,
    ],
})
export class EmployeePayContractComponent implements OnInit {
    @ViewChild('payRateDataTable') payRateDataTable?: DataTableComponent<PayRate>;
    @ViewChild('contractDataTable') contractDataTable?: DataTableComponent<Contract>;

    @Input({ required: true }) employeeId!: number;
    @Input({ required: true }) customerId!: number;

    // A reusable observable for getting the employee when needed
    getEmployeeObservable: Observable<Employee | undefined> = of(undefined);
    fetchingActivePayRate = true;
    usesCombinedContract?: boolean;
    usesCombinedContractSubject = new ReplaySubject<boolean>(1);
    usesStandardContractSubject = new ReplaySubject<boolean>(1);
    fetchingActiveContract = true;
    fetchingActiveCombinedContract = true;
    activePayRate?: PayRate;
    activeContract?: Contract;
    activeHoursDistribution?: HourDistribution | null;
    activeAvailability?: Availability | null;
    canAddPayRate = false;
    canAddContract = false;
    activePayRateText?: Promise<string>;
    activeContractText?: Promise<string>;
    includeDeletedPayrates = new FormControl<boolean>(false, { nonNullable: true });
    payRateRequest: DataTableRequest = of(mockArrayPaginatedResponse());
    contractsRequest: DataTableRequest = of(mockArrayPaginatedResponse());
    payRateColumns: DataTableColumnType<PayRate>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item.tariff?.name,
            header: new DataTableHeader({ i18n: 'TARIFF', sortBy: 'tariff_id' }),
        }),
        new DataTableTextColumn({
            value: (cell) => this.numberFormatterService.formatCurrency(cell.item.rate),
            header: new DataTableHeader({ i18n: 'RATE', sortBy: 'rate' }),
        }),
        new DataTableTextColumn({
            value: (cell) => this.translateService.t(cell.item.type.toUpperCase()),
            header: new DataTableHeader({ i18n: 'TYPE', sortBy: 'type' }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            header: new DataTableHeader({ i18n: 'FROM', sortBy: 'from' }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            header: new DataTableHeader({ i18n: 'TO', sortBy: 'to' }),
        }),
        new DataTableDateTimeColumn({
            value: 'deletedAt',
            header: new DataTableHeader({ i18n: 'DELETED_AT', sortBy: 'deleted_at' }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    click: this.editPayRate.bind(this),
                    icon: 'edit',
                    tooltip: { key: 'EDIT' },
                    show: (payRate) => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.pay_rates.${payRate.id}.update`),
                },
                {
                    click: this.deletePayRate.bind(this),
                    icon: 'delete',
                    tooltip: { key: 'DELETE' },
                    type: 'warn',
                    show: (payRate) => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.pay_rates.${payRate.id}.delete`),
                },
            ],
        }),
    ];

    contractsColumns: DataTableColumnType<Contract>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item.type?.name,
            header: new DataTableHeader({ i18n: 'TYPE', sortBy: 'type_id' }),
        }),
        new DataTableTextColumn({
            value: 'title',
            header: new DataTableHeader({ i18n: 'TITLE', sortBy: 'title' }),
        }),
        new DataTableNumberColumn({
            value: 'amount',
            numberFormatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
            header: new DataTableHeader({ i18n: 'AMOUNT', sortBy: 'amount' }),
        }),
        new DataTableTextColumn({
            value: (cell) => this.translateService.t(cell.item.amountType.toUpperCase()),
            header: new DataTableHeader({ i18n: 'CONTRACT_AMOUNT_TYPE', ns: Namespace.Company, sortBy: 'amount_type' }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            header: new DataTableHeader({ i18n: 'FROM', sortBy: 'from' }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            header: new DataTableHeader({ i18n: 'TO', sortBy: 'to' }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    click: this.editContract.bind(this),
                    icon: 'edit',
                    tooltip: { key: 'EDIT' },
                    hide: () => this.usesCombinedContractSubject.asObservable(),
                    show: (contract) => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.contracts.${contract.id}.update`),
                },
                {
                    click: this.deleteContract.bind(this),
                    icon: 'delete',
                    tooltip: { key: 'DELETE' },
                    type: 'warn',
                    show: (contract) => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.contracts.${contract.id}.delete`),
                },
                {
                    click: this.downloadContract.bind(this),
                    icon: 'download',
                    tooltip: { key: 'DOWNLOAD' },
                    hide: () => this.usesStandardContractSubject.asObservable(),
                    show: (contract) => this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.contracts.${contract.id}.get`),
                },
            ],
        }),
    ];

    constructor(
        @Inject(EmployeePayRateService) private employeePayRateService: EmployeePayRateService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(TranslateService) private translateService: TranslateService,
        @Inject(NumberFormatterService) private numberFormatterService: NumberFormatterService,
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(ContractService) private contractService: ContractService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(CombinedContractService) private combinedContractService: CombinedContractService,
        @Inject(SettingService) private settingService: SettingService,
        @Inject(CurrentService) private currentService: CurrentService,
        @Inject(CustomerCustomFieldService) private customerCustomFieldService: CustomerCustomFieldService,
    ) {
    }

    ngOnInit() {
        this.getEmployeeObservable = this.employeeService.get(this.customerId, this.employeeId).pipe(
            catchError(() => of(undefined)),
            shareReplay(1),
        );

        forkJoin([
            this.settingService.getValue<number | null>([ 'customers', this.customerId ], 'france.use_combined_contract', 0).pipe(map((value) => !!value)),
            this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.contracts.create`),
            this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.pay_rates.create`),
        ]).subscribe(([ usesCombinedContract, canAddContract, canAddPayRate ]) => {
            this.usesCombinedContract = usesCombinedContract;
            this.usesCombinedContractSubject.next(usesCombinedContract);
            this.usesStandardContractSubject.next(!usesCombinedContract);
            this.canAddContract = canAddContract;
            this.canAddPayRate = canAddPayRate;

            this.getActivePayRate();
            this.getActiveContract();
        });

        this.customerCustomFieldService.getForModel(this.customerId, ApiModel.Contract).subscribe((customFields) => {
            if (!customFields?.length) {
                return;
            }

            const cfColumns = [];
            for (const customField of customFields) {
                const header = new DataTableHeader({
                    i18n: customField.translationKey,
                    ns: Namespace.CustomFields,
                });

                cfColumns.push(new DataTableCustomFieldColumn<Contract>({
                    header,
                    languageTag: this.currentService.languageTag,
                    customFieldType: customField.type,
                    customField: (cell) => cell.item.attachedCustomFields.find((p) => p.id === customField.id),
                }));
            }

            this.contractsColumns = [ ...this.contractsColumns.slice(0, -1), ...cfColumns, this.contractsColumns.at(-1) ].filter((c) => !!c);
        });

        this.includeDeletedPayrates.valueChanges.pipe(debounceTime(300)).subscribe(() => {
            this.payRateDataTable?.refresh();
        });
    }

    addPayRate() {
        if (!this.canAddPayRate) {
            return;
        }

        this.matDialog.open<PayRateDialogComponent, PayRateDialogData, PayRateDialogReturn>(PayRateDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: this.employeeId,
            },
        }).afterClosed().subscribe((payRate) => {
            if (payRate instanceof PayRate) {
                this.payRateDataTable?.refresh();
                this.getActivePayRate();
            }
        });
    }

    editPayRate(cell: DataTableButtonCell<PayRate>) {
        this.matDialog.open<PayRateDialogComponent, PayRateDialogData, PayRateDialogReturn>(PayRateDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: this.employeeId,
                payRate: of(cell.item),
            },
        }).afterClosed().subscribe((payRate) => {
            if (payRate instanceof PayRate) {
                this.payRateDataTable?.refresh();
                this.getActivePayRate();
            } else {
                cell.disabled.set(false);
            }
        });
    }

    deletePayRate(cell: DataTableButtonCell<PayRate>) {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translateService.t('DELETE_PAY_RATE', Namespace.Company),
                confirmText: this.translateService.t('DELETE'),
                confirmColor: 'red-500',
                size: DialogSize.Medium,
            },
        }).afterClosed().pipe(
            switchMap((result) => {
                if (!result?.ok) {
                    cell.disabled.set(false);
                    return EMPTY;
                }

                return this.employeePayRateService.delete(this.customerId, this.employeeId, cell.item.id);
            }),
            catchError(() => {
                cell.disabled.set(false);
                return EMPTY;
            }),
            tap(() => {
                this.payRateDataTable?.refresh();

                // Get new active if we deleted the active pay rate
                if (cell.item.isActive(DateTime.now())) {
                    this.getActivePayRate();
                }
            })).subscribe();
    }

    addContract() {
        if (!this.canAddContract) {
            return;
        }

        const data = { customerId: this.customerId, employeeId: this.employeeId };

        if (this.usesCombinedContract) {
            return this.matDialog.open<CombinedContractDialogComponent, CombinedContractDialogData, CombinedContractDialogReturn>(CombinedContractDialogComponent, { data })
                .afterClosed().subscribe((result) => {
                    if (result instanceof CombinedContract) {
                        this.contractDataTable?.refresh();
                        this.getActiveContract();
                    }
                });
        }

        this.matDialog.open<ContractDialogComponent, ContractDialogData, ContractDialogReturn>(ContractDialogComponent, { data }).afterClosed().subscribe((contract) => {
            if (contract instanceof Contract) {
                this.contractDataTable?.refresh();
                this.getActiveContract();
            }
        });
    }

    editContract(cell: DataTableButtonCell<Contract>) {
        this.matDialog.open<ContractDialogComponent, ContractDialogData, ContractDialogReturn>(ContractDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: this.employeeId,
                contract: cell.item,
            },
        }).afterClosed().subscribe((contract) => {
            if (contract instanceof Contract) {
                this.contractDataTable?.refresh();
                this.getActiveContract();
            } else {
                cell.disabled.set(false);
            }
        });
    }

    downloadContract(cell: DataTableButtonCell<Contract>) {
        this.matDialog.open<ContractDownloadDialogComponent, ContractDownloadDialogData, null>(ContractDownloadDialogComponent, {
            data: {
                customerId: this.customerId,
                employeeId: this.employeeId,
                contractId: cell.item.id,
            },
        }).afterClosed().subscribe(() => {
            cell.disabled.set(false);
        });
    }

    deleteContract(cell: DataTableButtonCell<Contract>) {
        // We need to fetch the employee to get the employee's name for the dialog
        this.getEmployeeObservable.subscribe((employee) => {
            this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
                data: {
                    title: this.translateService.t('DELETE_CONTRACT', Namespace.Company),
                    text: this.translateService.t('DELETE_CONTRACT_TEXT', Namespace.Company, { type: cell.item.type?.name, name: employee?.name }),
                    confirmText: this.translateService.t('DELETE'),
                    confirmColor: 'red-500',
                    size: DialogSize.Medium,
                },
            }).afterClosed().subscribe((result) => {
                if (!result?.ok) {
                    cell.disabled.set(false);
                    return;
                }

                const deleteObservable = this.usesCombinedContract ?
                    this.combinedContractService.delete(this.customerId, this.employeeId, cell.item.id) :
                    this.contractService.delete(this.customerId, this.employeeId, cell.item.id);

                deleteObservable.pipe(
                    catchError(() => {
                        cell.disabled.set(false);
                        return EMPTY;
                    }),
                ).subscribe(() => {
                    this.contractDataTable?.refresh();

                    // Get new active if we deleted the active contract
                    if (cell.item.isActive(DateTime.now())) {
                        this.getActiveContract();
                    }
                });
            });
        });
    }

    getActiveContract() {
        this.fetchingActiveContract = true;

        this.contractService.getAllForEmployee(this.customerId, this.employeeId, {
            active: DateTime.now(),
            'with[]': [ 'type' ],
        }).pipe(
            switchMap((contractsResponse) => {
                const activeContract = contractsResponse.data[0] as Contract | undefined;

                if (!activeContract) {
                    return of(undefined);
                }

                if (this.usesCombinedContract) {
                    return this.combinedContractService.get(this.customerId, this.employeeId, activeContract.id).pipe(
                        map((combinedContract) => combinedContract),
                        catchError(() => of(null)),
                    );
                }

                return of(activeContract);
            }),
            catchError(() => of(null)),
        ).subscribe((contractsResponse) => {
            if (contractsResponse instanceof CombinedContract) {
                this.updateActiveContract(contractsResponse.contract);
                this.activeHoursDistribution = contractsResponse.hoursDistribution;
                this.activeAvailability = contractsResponse.availability;
            } else {
                this.updateActiveContract(contractsResponse ?? undefined);
            }

            this.fetchingActiveContract = false;
            this.fetchingActiveCombinedContract = false;
        });
    }

    updateActiveContract(contract?: Contract) {
        this.activeContract = contract;

        if (this.activeContract) {
            if (this.activeContract.amountType === 'percent') {
                this.activeContractText = Promise.resolve(this.numberFormatterService.formatDecimal(this.activeContract.percentage / 100, 0, undefined, {
                    style: 'percent',
                }));
            } else {
                this.activeContractText = this.translateService.t(`CONTRACT_AMOUNT_AND_TYPE_${this.activeContract.amountType.toUpperCase()}`, Namespace.Company, {
                    count: this.numberFormatterService.formatDecimal(this.activeContract.amount),
                });
            }
        } else {
            delete this.activeContract;
        }
    }

    getActivePayRate() {
        this.fetchingActivePayRate = true;
        this.employeePayRateService.getAll(this.customerId, this.employeeId, { active: DateTime.now() }).pipe(
            catchError(() => of(mockArrayPaginatedResponse([] as PayRate[]))),
        ).subscribe((payRatesResponse) => {
            this.updateActivePayRate(payRatesResponse.data[0] as PayRate | undefined);
            this.fetchingActivePayRate = false;
        });
    }

    updateActivePayRate(payRate?: PayRate) {
        this.activePayRate = payRate;

        if (this.activePayRate) {
            this.activePayRateText = this.translateService.t(`PAY_RATE_AND_TYPE_${this.activePayRate?.type.toUpperCase()}`, Namespace.Company, {
                money: this.numberFormatterService.formatCurrency(this.activePayRate?.rate),
            });
        } else {
            delete this.activePayRateText;
        }
    }

    updateContractsTable(pagination: Partial<DataTablePagination>) {
        this.contractsRequest = this.contractService.getAllForEmployee(this.customerId, this.employeeId, {
            ...pagination,
            'with[]': [ 'type', 'properties' ],
        });
    }

    updatePayRateTable(pagination: Partial<DataTablePagination>) {
        this.payRateRequest = this.employeePayRateService.getAll(this.customerId, this.employeeId, {
            ...pagination,
            include_deleted: this.includeDeletedPayrates.value,
            'with[]': [ 'tariff' ],
        });
    }

    contractRowClasses(row: Contract) {
        const classes: string[] = [];
        if (row.isActive(DateTime.now())) {
            classes.push('success');
        }
        return classes;
    }

    payRateRowClasses(row: PayRate) {
        const classes: string[] = [];
        if (row.isActive(DateTime.now())) {
            classes.push('success');
        }
        if (row.deletedAt) {
            classes.push('danger');
        }
        return classes;
    }

    protected readonly Contract = Contract;
}
