import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { User } from '../../../../../shared/models/user';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../../../../../shared/http/user.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { AutocompleteComponent } from '../../../../../shared/components/autocomplete/autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { catchError, EMPTY } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Language } from '../../../../../admin/models/language';
import { LanguageAutocompleteService } from '../../../../../shared/autocompletes/language-autocomplete.service';
import { CheckboxHelperDirective } from '../../../../../shared/directives/checkbox-helper.directive';
import { Customer } from '../../../../../shared/models/customer';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { RequiredEmployeeField } from '../../../../../shared/models/employee';
import { NewEmployeeNextStepComponent } from '../../components/new-employee-next-step/new-employee-next-step.component';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';
import { TranslateService } from '../../../../../shared/services/translate.service';
import { FieldErrorComponent } from '../../../../../shared/components/field-error/field-error.component';
import { DialPhone, DialPhoneInputComponent } from '../../../../../shared/components/dial-phone-input/dial-phone-input.component';

type UserForm = {
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    languageCode: FormControl<Language | string | null>;
    phone: FormControl<DialPhone | null>;
}

@Component({
    selector: 'eaw-new-employee-user-create',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslatePipe,
        AutocompleteComponent,
        MatAutocompleteModule,
        MatCheckboxModule,
        CheckboxHelperDirective,
        ActionButtonComponent,
        NewEmployeeNextStepComponent,
        FieldErrorComponent,
        DialPhoneInputComponent,
    ],
    templateUrl: './new-employee-user-create.component.html',
    styleUrl: './new-employee-user-create.component.scss',
})
export class NewEmployeeUserCreateComponent extends NewEmployeeStepComponent implements OnInit {
    protected readonly languageAutocompleteService = inject(LanguageAutocompleteService);
    private readonly userService = inject(UserService);
    private readonly translateService = inject(TranslateService);

    /**
     * Email of the person we are going to add as a user
     */
    @Input({ required: true }) email!: string;
    @Input({ required: true }) customer!: Customer;
    @Input({ required: true }) requiredFields!: Partial<Record<RequiredEmployeeField, boolean>>;

    @Output() userChange = new EventEmitter<User>();

    user?: User;
    processing = false;
    languageHint = this.translateService.t('USER_LANG_HINT', 'new_employee');
    form: FormGroup<UserForm>;

    constructor() {
        super();

        this.form = new FormGroup({
            firstName: new FormControl<string | null>(null, [ Validators.required, Validators.maxLength(50) ]),
            lastName: new FormControl<string | null>(null, [ Validators.required, Validators.maxLength(50) ]),
            languageCode: new FormControl<Language | string | null>(null, Validators.required),
            phone: new FormControl<DialPhone | null>(null),
        });
    }

    override ngOnInit() {
        super.ngOnInit();

        this.form.patchValue({
            languageCode: this.customer.languageCode,
        });
    }

    create() {
        const form = this.form.value;
        if (!form.firstName || !form.lastName) {
            return;
        }

        this.processing = true;
        this.form.disable();
        this.userService.create({
            first_name: form.firstName,
            last_name: form.lastName,
            email: this.email,
            phone: form.phone?.phone,
            language_code: form.languageCode instanceof Language ? form.languageCode.code : undefined,
            country_code: form.phone?.dial,
        }).pipe(
            catchError(() => {
                this.form.enable();
                this.processing = false;
                return EMPTY;
            }),
        ).subscribe((user) => {
            this.processing = false;
            this.step.completed = true;
            this.user = user;
            this.userChange.emit(user);
            this.stepper.next();
        });
    }
}
