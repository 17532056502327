import { sortBy } from 'lodash-es';
import { module } from 'angular';
import Widget from '../classes/widget.class';
import { t } from 'i18next';
import { CurrentOld } from '../../shared/angularjs/current.factory';
import { SettingResponse } from '../../shared/models/setting';
import { EawResourceFactory } from '../../shared/angularjs/modules/resource/resource.service';

module('eaw.dashboard').controller('addWidgetDialogCtrl', [ 'EawResource', '$injector', '$mdDialog', 'CustomerSettings', function addWidgetDialogCtrl(EawResource: EawResourceFactory, $injector, $mdDialog, CustomerSettings) {
    // @ts-ignore
    const ctrl = this;

    ctrl.$onInit = async () => {
        const hiddenWidgets = await Widget.getHiddenWidgets(EawResource, CurrentOld.user['id'], CurrentOld.customer['id']);
        const allWidgetInfo: any = ctrl.widgetInfos;
        const widgetSettings: string[] = Object.values(allWidgetInfo).map((w: any) => w.requiredSettings ? Object.keys(w.requiredSettings) : null).filter((s: any) => s).flat() as string[];

        ctrl.cancel = $mdDialog.cancel;

        const resp: SettingResponse[] = await CustomerSettings.getSome(CurrentOld.customer['id'], widgetSettings, undefined, undefined, { array: true });
        const settings = resp.reduce((acc: Record<string, any>, curr: SettingResponse) => {
            acc[curr.key] = curr.resolved_value;
            return acc;
        }, {});

        // All widgets we have in widget info
        ctrl.widgets = sortBy(Object.values(allWidgetInfo).map((w: any) => new Widget(w, CurrentOld.user, CurrentOld.employee, $injector, settings, hiddenWidgets, w)), (w) => t(w.i18name));

        ctrl.filterRequirements();
        ctrl.filterDefaults();
        ctrl.assignNormals();
        ctrl.assignMinis();

        ctrl.loaded = true;
    };

    ctrl.filterRequirements = () => {
        // Only those we have requirements for
        ctrl.widgets = ctrl.widgets.filter((w: Widget) => w.hasRequirements);
    };

    ctrl.filterDefaults = () => {
        // Remove those who are defaults
        ctrl.widgets = ctrl.widgets.filter((w: Widget) => !w.default);
    };

    ctrl.assignNormals = () => {
        // Remove what's already added, unless you can add multiple
        ctrl.normalWidgets = ctrl.widgets.filter((w: Widget) => {
            const isMini = w.mini;
            const alreadyAdded = ctrl.normalWidgets.some((nw: Widget) => nw.name === w.name);
            const canHaveMultiple = w.multiple;
            return !isMini && (!alreadyAdded || canHaveMultiple);
        });
    };

    ctrl.assignMinis = () => {
        // Remove what's already added, unless you can add multiple
        ctrl.miniWidgets = ctrl.widgets.filter((w: Widget) => {
            const isMini = w.mini;
            const alreadyAdded = ctrl.miniWidgets.some((nw: Widget) => nw.name === w.name);
            const canHaveMultiple = w.multiple;
            return isMini && (!alreadyAdded || canHaveMultiple);
        });
    };

    ctrl.add = (widget: Widget) => {
        if (typeof widget.settingsDialog === 'string') {
            $injector.get(widget.settingsDialog)(widget).then((settings: any) => {
                ctrl.submit(widget, settings);
            });
        } else {
            ctrl.submit(widget);
        }
    };

    ctrl.submit = (widget: Widget, settings: any) => {
        newrelic.addPageAction('Added Widget', { widget: widget.name });

        $mdDialog.hide({
            ...widget,
            settings: {
                ...widget.settings,
                ...settings,
            },
        });
    };
} ]);
