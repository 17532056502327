import { Component, Inject, Input, OnInit, signal } from '@angular/core';
import { Shift } from '../../models/shift';
import { DateTime } from 'luxon';
import { CurrentService } from '../../../shared/services/current.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EMPTY, forkJoin, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { ShiftSwapRequestDialogComponent, ShiftSwapRequestDialogData } from '../../dialogs/shift-swap-request-dialog/shift-swap-request-dialog.component';
import { ShiftSwap } from '../../models/shift-swap';
import { MatDialog } from '@angular/material/dialog';
import { ShiftSwapService } from '../../http/shift-swap.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { EmployeeShiftsTableComponent } from './employee-shifts-table/employee-shifts-table.component';
import { EmployeeShiftsListComponent } from './employee-shifts-list/employee-shifts-list.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { CommentDialogComponent, CommentDialogData } from '../../../shared/dialogs/comments-dialog/comment-dialog.component';

type Mode = 'list' | 'table';

@Component({
    selector: 'eaw-employee-shifts',
    templateUrl: './employee-shifts.component.html',
    styleUrl: './employee-shifts.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        EmployeeShiftsListComponent,
        EmployeeShiftsTableComponent,
        AsyncPipe,
    ],
})
// Component that displays the shifts for a single employee, it's like "My shifts"
export class EmployeeShiftsComponent implements OnInit {
    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) employeeId!: number;

    private readonly defaultDate = DateTime.now().startOf('day');
    private readonly modeStoreKey = 'employee-shifts-mode';
    readonly modes: Record<Mode, Promise<string>> = {
        list: this.translate.t('MY_UPCOMING_SHIFT_plural', 'scheduling'),
        table: this.translate.t('ALL_MY_SHIFTS', 'scheduling'),
    };

    shiftsFromDate: DateTime | undefined = this.defaultDate;
    mode?: Mode;
    buttons: PageHeaderButton[] = [
        new PageHeaderButton({
            click: this.updateFrom.bind(this),
            icon: 'history',
            menuText: signal(this.translate.t('HISTORY')),
            hide: () => this.mode === 'list',
            active: () => !this.shiftsFromDate,
        }),
        new PageHeaderButton({
            click: () => this.setMode('list'),
            icon: 'view_list',
            menuText: signal(this.translate.t('LIST_MODE', 'scheduling')),
            hide: () => this.mode === 'list',
        }),
        new PageHeaderButton({
            click: () => this.setMode('table'),
            icon: 'table_chart',
            menuText: signal(this.translate.t('TABLE_MODE', 'scheduling')),
            hide: () => this.mode === 'table',
        }),
    ];

    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(BreakpointObserver) private breakpointObserver: BreakpointObserver,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(ShiftSwapService) private shiftSwapService: ShiftSwapService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
    ) {
    }

    ngOnInit(): void {
        this.breakpointObserver.observe([
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge,
        ]).pipe(take(1)).subscribe(async (matches) => {
            const mode: Mode = matches.matches ? 'table' : 'list';
            this.mode = await this.current.retrieve<Mode>(this.modeStoreKey, 'default') || mode;
        });
    }

    updateFrom() {
        this.shiftsFromDate = this.shiftsFromDate ? undefined : this.defaultDate;
    }

    setMode(mode: Mode) {
        this.mode = mode;
        void this.current.store(this.modeStoreKey, mode, 'default');
    }

    requestSwap = (customerId: number, shiftId: number) => {
        return this.matDialog.open<ShiftSwapRequestDialogComponent, ShiftSwapRequestDialogData, ShiftSwap>(ShiftSwapRequestDialogComponent, {
            data: {
                shiftId,
                customerId,
            },
        }).afterClosed();
    };

    withdrawSwap = (customerId: number, swapId: number, employeeId?: number) => {
        return this.confirmDialogService.delete({
            title: this.translate.t('WITHDRAW_SWAP', 'scheduling'),
            text: this.translate.t('WITHDRAW_SWAP_REQUEST', 'scheduling'),
            confirmText: this.translate.t('WITHDRAW', 'scheduling'),
        }).afterClosed().pipe(
            switchMap((res) => {
                if (res?.ok) {
                    return this.shiftSwapService.delete(customerId, swapId, employeeId).pipe(
                        tap(() => {
                            void this.snackBarService.t('REQUEST_WITHDRAWN', 'scheduling');
                        }),
                    );
                }

                return EMPTY;
            }),
        );
    };

    canCreateSwap = (shift: Shift): Observable<boolean> => {
        if (!shift.from) {
            return of(false);
        }

        return forkJoin([
            of(shift.from > DateTime.now()),
            this.permissionCheckService.isAllowed(`customers.${this.customerId}.shift_swaps.create`),
        ]).pipe(map(([ isFuture, canCreateSwap ]) => isFuture && canCreateSwap));
    };

    canWithdrawSwap = (shift: Shift): Observable<boolean> => {
        if (!shift.from) {
            return of(false);
        }

        const swap = shift.unprocessedSwaps?.find((s) => !s.approved);
        if (!swap) {
            return of(false);
        }

        return forkJoin([
            of(shift.from > DateTime.now()),
            this.permissionCheckService.isAllowed(`customers.${swap.customerId}.employees.${swap.fromId}.shift_swaps.${swap.id}.delete`),
        ]).pipe(map(([ isFuture, canWithdrawSwap ]) => isFuture && canWithdrawSwap));
    };

    showComments = (shift: Shift) => {
        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                comments: of(shift.comments || []),
            },
        });
    };
}
