import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, ElementRef, inject, Input, OnInit, signal, viewChild } from '@angular/core';
import { DataTable, DataTablePagination } from '../../../../data-table/types/data-table';
import { AbsenceService, GetAllAbsencesOptions, GetAllCustomerAbsencesOptions } from '../../../http/absence.service';
import { DataTableColumnType } from '../../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../../data-table/types/data-table-header';
import { Absence } from '../../../models/absence';
import { catchError, EMPTY, forkJoin, map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { DateTime } from 'luxon';
import { PercentPipe } from '../../../../shared/pipes/percent.pipe';
import { DataTableApprovalColumn } from '../../../../data-table/types/data-table-approval-column';
import { DurationPipe } from '../../../../shared/pipes/duration.pipe';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Employee } from '../../../../shared/models/employee';
import { AbsenceType } from '../../../models/absence-type';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../../data-table/types/data-table-button-column';
import { TranslateService } from '../../../../shared/services/translate.service';
import { DataTableCell } from '../../../../data-table/interfaces/data-table-cell';
import { DataTableDateTimeColumn } from '../../../../data-table/types/data-table-date-time-column';
import { RoleAssignment } from '../../../../leader-roles/shared/types/role-assignment';
import { PageHeaderButton } from '../../../../shared/components/page-header/classes/page-header-button';
import { AbsenceStatsDialogComponent, AbsenceStatsDialogData } from '../../../dialogs/absence-stats-dialog/absence-stats-dialog.component';
import { DialogSize } from '../../../../shared/dialogs/dialog-component';
import { mockArrayPaginatedResponse } from '../../../../../mocks/paginated-response.mock';
import { User } from '../../../../shared/models/user';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AutocompleteComponent } from '../../../../shared/components/autocomplete/autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';
import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageHeaderComponent } from '../../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { DatePickerOptionsDirective } from '../../../../shared/directives/date-picker-options.directive';
import { DataTableComponent } from '../../../../data-table/data-table.component';
import { CheckboxHelperDirective } from '../../../../shared/directives/checkbox-helper.directive';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipInput, MatChipInputEvent, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ENTER } from '@angular/cdk/keycodes';
import { TranslateSyncPipe } from '../../../../shared/pipes/translate-sync.pipe';
import { CurrentService } from '../../../../shared/services/current.service';
import { AbsenceTypeService } from '../../../http/absence-type.service';
import { PermissionCheckService } from '../../../../shared/services/permission-check.service';
import { HandleAbsenceDialogService } from '../../../dialogs/handle-absence-dialog/handle-absence-dialog.service';
import { ConfirmDialogService } from '../../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { QueryParamsService } from '../../../../shared/services/query-params.service';
import { WarningsDialogService } from '../../../../shared/dialogs/warnings-dialog/warnings-dialog.service';
import { SettingService } from '../../../../shared/http/setting.service';
import { MatDialog } from '@angular/material/dialog';
import { RoleAssignmentService } from '../../../../leader-roles/shared/http/role-assignment.service';
import { EmployeeAutocompleteService } from '../../../../shared/autocompletes/employee-autocomplete.service';
import { Namespace } from '../../../../shared/enums/namespace';
import { expandAllPages } from '../../../../shared/utils/rxjs/expand-all-pages';
import { DurationUnit } from 'luxon/src/duration';
import { MaterialColorDirective } from '../../../../shared/directives/material-color.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InfoLoadingComponent } from '../../../../shared/components/info-loading/info-loading.component';
import { ActionButtonComponent } from '../../../../shared/components/action-button/action-button.component';
import { UserFormPreferenceService } from '../../../../shared/http/user-form-preference.service';
import { DateTimeConverter } from '../../../../shared/utils/date-time-converter';
import { stringToDateTime } from '../../../../shared/utils/string-to-date-time';

interface AbsenceListMode {
    id: 'unprocessed' | 'processed' | 'approved' | 'declined' | 'all',
    label: string,
    handled: 1 | 0 | null,
    approved: 1 | 0 | null,
}

type AbsenceListFilterForm = {
    mode: FormControl<AbsenceListMode['id']>,
    dateRange: FormGroup<{
        from: FormControl<DateTime | null>,
        to: FormControl<DateTime | null>,
    }>,
    employee: FormControl<Employee | number | null>,
    absenceType: FormControl<AbsenceType | string | null>,
    onlyMyEmployees: FormControl<boolean>,
}

@Component({
    selector: 'eaw-absence-list',
    templateUrl: './absence-list.component.html',
    styleUrl: './absence-list.component.scss',
    providers: [ PercentPipe, DurationPipe ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatDatepickerModule,
        AutocompleteComponent,
        MatCheckboxModule,
        MatButtonModule,
        MatCardModule,
        AsyncPipe,
        PageHeaderComponent,
        TranslatePipe,
        DatePickerOptionsDirective,
        DataTableComponent,
        CheckboxHelperDirective,
        KeyValuePipe,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatChipGrid,
        MatChipInput,
        MatChipRemove,
        MatChipRow,
        MatIcon,
        MatProgressSpinner,
        TranslateSyncPipe,
        MaterialColorDirective,
        InfoLoadingComponent,
        ActionButtonComponent,
    ],
})
export class AbsenceListComponent implements OnInit, DataTable<Absence> {
    private readonly dialog = inject(MatDialog);
    private readonly destroyRef = inject(DestroyRef);
    private readonly percentPipe = inject(PercentPipe);
    private readonly durationPipe = inject(DurationPipe);
    private readonly translate = inject(TranslateService);
    private readonly currentService = inject(CurrentService);
    private readonly settingService = inject(SettingService);
    private readonly absenceService = inject(AbsenceService);
    private readonly searchParams = inject(QueryParamsService);
    private readonly confirmDialog = inject(ConfirmDialogService);
    private readonly warningsDialog = inject(WarningsDialogService);
    private readonly absenceTypeService = inject(AbsenceTypeService);
    private readonly roleAssignmentService = inject(RoleAssignmentService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly handleAbsenceDialog = inject(HandleAbsenceDialogService);
    private readonly userFormPreferenceService = inject(UserFormPreferenceService);
    protected readonly employeeAutocompleteService = inject(EmployeeAutocompleteService);

    absenceTypeInput = viewChild('absenceTypeInput', { read: ElementRef });
    dataTableComponent = viewChild(DataTableComponent);

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) userId!: number;

    loading = signal(true);
    canDeleteApproved = signal(false);
    getData = computed(this.computeGetData.bind(this));
    hasApprovalDeadline = signal(false);
    showSubordinates = signal(false);
    absenceTypesSeparatorKeysCodes: number[] = [ ENTER ];
    filteredAbsenceTypes: Observable<AbsenceType[]> = of([]);
    columns = computed(this.computeColumns.bind(this));
    headerButtons: PageHeaderButton[] = [];
    selectedAbsenceTypes = signal(new Map<number, AbsenceType>());
    selectedAbsenceTypeKeys = computed(() => this.selectedAbsenceTypes().size ? [ ...this.selectedAbsenceTypes().keys() ] : undefined);
    absenceTypes = signal<AbsenceType[]>([]);
    modes: AbsenceListMode[];
    formGroup: FormGroup<AbsenceListFilterForm>;
    defaultMode: AbsenceListMode;

    constructor() {
        effect(() => {
            this.searchParams.set([
                {
                    key: 'types',
                    value: this.selectedAbsenceTypeKeys(),
                },
            ]);
        });

        this.defaultMode = {
            id: 'unprocessed',
            label: 'UNPROCESSED',
            handled: 0,
            approved: null,
        };

        this.formGroup = new FormGroup({
            mode: new FormControl<AbsenceListMode['id']>(this.defaultMode.id, { nonNullable: true }),
            dateRange: new FormGroup({
                from: new FormControl<DateTime | null>(null),
                to: new FormControl<DateTime | null>(null),
            }),
            employee: new FormControl<Employee | number | null>(null),
            absenceType: new FormControl<AbsenceType | string | null>(null),
            onlyMyEmployees: new FormControl<boolean>(false, { nonNullable: true }),
        });

        this.modes = [
            this.defaultMode,
            {
                id: 'processed',
                label: 'PROCESSED',
                handled: 1,
                approved: null,
            },
            {
                id: 'approved',
                label: 'APPROVED',
                handled: 1,
                approved: 1,
            },
            {
                id: 'declined',
                label: 'DECLINED',
                handled: 1,
                approved: 0,
            },
            {
                id: 'all',
                label: 'ALL',
                handled: null,
                approved: null,
            },
        ];

        this.headerButtons = [
            new PageHeaderButton({
                icon: 'trending_up',
                click: this.showStats.bind(this),
                menuText: signal(this.translate.t('STATISTICS', 'navigation')),
            }),
            new PageHeaderButton({
                icon: 'saved_search',
                click: this.saveFormPreference.bind(this),
                menuText: signal(this.translate.t('SAVE_SEARCH', 'navigation')),
            }),
        ];
    }

    ngOnInit() {
        // Get various data before getting and setting the form preferences.
        forkJoin([
            this.getAbsenceTypes(),
            this.getSettings(),
            this.getRoles(),
        ]).pipe(
            switchMap(() => this.getFormPreference()),
        ).subscribe(() => {
            this.updateSearchParams();
            this.handleEmployeeChange();

            this.loading.set(false);
        });
    }

    addSelectedAbsenceType(absenceType: AbsenceType) {
        this.selectedAbsenceTypes.update((types) => {
            types.set(absenceType.id, absenceType);
            return new Map(types);
        });
    }

    removeAbsenceType(id: number) {
        this.selectedAbsenceTypes.update((types) => {
            types.delete(id);
            return new Map(types);
        });
    }

    getFormPreference() {
        return this.userFormPreferenceService.get(this.userId).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((value) => {
                const from = stringToDateTime(typeof value['from'] === 'string' ? value['from'] : '', true);
                const to = stringToDateTime(typeof value['to'] === 'string' ? value['to'] : '', true);
                const mode = this.modes.find((m) => m.id === value['mode']);
                const types = typeof value['types'] === 'string' ? value['types'] : undefined;

                if (types?.includes(',')) {
                    types.split(',').forEach((type) => {
                        const absenceType = this.absenceTypes().find((a) => a.id === parseInt(type));
                        if (absenceType) {
                            this.addSelectedAbsenceType(absenceType);
                        }
                    });
                } else {
                    const absenceType = this.absenceTypes().find((a) => a.id === parseInt(types || ''));
                    if (absenceType) {
                        this.addSelectedAbsenceType(absenceType);
                    }
                }

                this.formGroup.patchValue({
                    mode: this.searchParams.get<AbsenceListMode['id']>('mode', 'string') || mode?.id || this.defaultMode.id,
                    dateRange: {
                        from: this.searchParams.get('from', 'date') || from.isValid ? from : null,
                        to: this.searchParams.get('to', 'date') || to.isValid ? to : null,
                    },
                    employee: typeof value['employee'] === 'number' ? value['employee'] : null,
                    onlyMyEmployees: this.showSubordinates() && typeof value['onlyMyEmployees'] === 'boolean' || false,
                });
            }),
        );
    }

    saveFormPreference() {
        const raw = this.formGroup.getRawValue();

        this.userFormPreferenceService.save(this.userId, {
            mode: raw.mode,
            from: DateTimeConverter.convertDateTimeToBusinessDate(raw.dateRange.from),
            to: DateTimeConverter.convertDateTimeToBusinessDate(raw.dateRange.to),
            employee: raw.employee instanceof Employee ? raw.employee.id : raw.employee,
            onlyMyEmployees: this.formGroup.getRawValue().onlyMyEmployees,
            types: this.selectedAbsenceTypeKeys()?.join(','),
        }).subscribe();
    }

    updateSearchParams() {
        this.formGroup.valueChanges.subscribe((value) => {
            this.searchParams.set([
                {
                    key: 'mode',
                    value: value.mode,
                },
                {
                    key: 'from',
                    value: value.dateRange?.from,
                    type: 'date',
                },
                {
                    key: 'to',
                    value: value.dateRange?.to,
                    type: 'date',
                },
                {
                    key: 'types',
                    value: this.selectedAbsenceTypeKeys(),
                },
                {
                    key: 'employee',
                    value: value.employee instanceof Employee ? value.employee.id : value.employee,
                },
            ]);
        });
    }

    handleEmployeeChange() {
        this.formGroup.controls.employee.valueChanges.subscribe((employee) => {
            if (employee instanceof Employee) {
                this.formGroup.controls.onlyMyEmployees.disable();
            } else {
                this.formGroup.controls.onlyMyEmployees.enable();
            }
        });
    }

    getSettings() {
        const deleteApprovedKey = 'absences.allow_employee_to_delete_approved';
        const useDeadlineKey = 'absence.approval_deadline.is_enabled';

        return this.settingService.getSome([ 'customers', this.customerId ], {
            'settings[]': [ deleteApprovedKey, useDeadlineKey ],
        }).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((settings) => {
                this.canDeleteApproved.set(settings.find((s) => s.key === deleteApprovedKey)?.resolvedValue?.asBoolean() ?? false);
                this.hasApprovalDeadline.set(settings.find((s) => s.key === useDeadlineKey)?.resolvedValue?.asBoolean() ?? false);
            }),
        );
    }

    getAbsenceTypes() {
        return expandAllPages((pagination) => this.absenceTypeService.getAll('customers', this.customerId, pagination), { per_page: 200 }).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((types) => {
                this.absenceTypes.set(sort(types, this.currentService.languageTag, [ (x) => x.name ]));

                this.filteredAbsenceTypes = this.formGroup.controls.absenceType.valueChanges.pipe(
                    startWith(null),
                    map((search) => {
                        if (search instanceof AbsenceType) {
                            return this.absenceTypes().find((q) => q.id === search.id) ? [ search ] : [];
                        }

                        const absenceTypes = search?.length ? this.absenceTypes().filter((q) => {
                            return q.name.toLowerCase().includes(search?.toLowerCase() ?? '') ||
                                this.translate.syncT(q.name, Namespace.AbsenceTypes).toLowerCase().includes(search?.toLowerCase() ?? '');
                        }) : this.absenceTypes();

                        // Filter out already selected types
                        return absenceTypes.filter((type) => !this.selectedAbsenceTypes().has(type.id));
                    }),
                ) || of([]);
            }),
        );
    }

    computeGetData() {
        return (pagination: Partial<DataTablePagination>) => {
            const formValue = this.formGroup.getRawValue();
            const from = formValue?.dateRange.from?.startOf('day');
            const to = formValue?.dateRange.to?.endOf('day');

            const employeeId = formValue?.employee instanceof Employee ? formValue.employee.id : formValue.employee;
            const options: GetAllCustomerAbsencesOptions = {
                per_page: 25,
                order_by: 'from',
                direction: 'desc',
                ...pagination,
                from,
                to,
                'with[]': [ 'employee', 'type', 'approval', 'comments', 'warnings' ],
                'type_ids[]': this.selectedAbsenceTypeKeys(),
                ...this.getHandledApproved(),
                only_direct_subordinates: this.formGroup.controls.employee.value instanceof Employee ? undefined : this.formGroup.controls.onlyMyEmployees.value,
            };

            if (employeeId) {
                return this.absenceService.getAllForEmployee(this.customerId, employeeId, options);
            } else {
                return this.absenceService.getAllForCustomer(this.customerId, options);
            }
        };
    }

    computeColumns() {
        const columns: DataTableColumnType<Absence>[] = [
            new DataTableTextColumn({
                value: (cell) => cell.item.employee?.name || '',
                header: new DataTableHeader({ i18n: 'EMPLOYEE' }),
            }),
            new DataTableApprovalColumn({
                header: new DataTableHeader({ i18n: 'APPROVAL' }),
                approval: (cell) => cell.item.approval,
                includeTime: true,
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({ i18n: 'TYPE' }),
                value: (cell) => cell.item.type?.translatedName || '',
            }),
            new DataTableDateTimeColumn({
                value: 'from',
                header: new DataTableHeader({
                    i18n: 'FROM',
                    sortBy: 'from',
                }),
                format: (cell) => cell.item.type?.span === 'hour' ? DateTime.DATETIME_MED : DateTime.DATE_MED,
            }),
            new DataTableDateTimeColumn({
                value: 'to',
                header: new DataTableHeader({
                    i18n: 'TO',
                    sortBy: 'to',
                }),
                format: (cell) => cell.item.type?.span === 'hour' ? DateTime.DATETIME_MED : DateTime.DATE_MED,
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({ i18n: 'ABSENCE_DIFF_LENGTH', ns: 'absences' }),
                value: (cell) => {
                    const daySpan = cell.item.type?.span === 'day';
                    const units: DurationUnit[] = daySpan ? [ 'days' ] : [ 'hours', 'minutes' ];
                    return this.durationPipe.transform(cell.item.to?.plus({ second: Number(daySpan) }).diff(cell.item.from, units), units);
                },
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({
                    i18n: 'GRADE',
                    ns: 'absences',
                }),
                value: (cell) => this.percentPipe.transform(cell.item.grade),
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({ i18n: 'ABSENCE_LENGTH', ns: 'absences' }),
                value: (cell) => this.durationPipe.transform(cell.item.length + Number(cell.item.type?.span === 'day'), [ 'hours', 'minutes' ]),
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        icon: 'check',
                        click: this.approveAbsence.bind(this),
                        type: 'ok',
                        show: (absence) => absence.approval ? of(false) : this.permissionCheckService.isAllowed(`customers.${absence.customerId}.employees.${absence.employeeId}.absences.${absence.id}.update`),
                        tooltip: { key: 'APPROVE' },
                    },
                    {
                        icon: 'close',
                        click: this.declineAbsence.bind(this),
                        type: 'warn',
                        show: (absence) => {
                            return absence.approval ? of(false) : this.permissionCheckService.isAllowed(`customers.${absence.customerId}.employees.${absence.employeeId}.absences.${absence.id}.update`);
                        },
                        tooltip: { key: 'DECLINE' },
                    },
                    { // This is just a hidden button to move the delete button a bit more away
                        icon: 'close',
                        click: () => {
                        },
                        show: () => of(false),
                        tooltip: { key: 'DECLINE' },
                    },
                    {
                        icon: 'warning',
                        click: this.showWarnings.bind(this),
                        type: 'alert',
                        show: (absence) => of(!!absence.warnings?.length),
                        nonBlocking: true,
                        tooltip: { key: 'WARNING_plural' },
                    },
                    {
                        icon: 'delete',
                        click: this.deleteAbsence.bind(this),
                        type: 'warn',
                        show: (absence) => this.permissionCheckService.isAllowed(`customers.${absence.customerId}.absences.${absence.id}.approve`),
                        tooltip: { key: 'DELETE' },
                    },
                ],
            }),
        ];

        if (this.hasApprovalDeadline()) {
            columns.splice(2, 0, new DataTableDateTimeColumn({
                header: new DataTableHeader({ i18n: 'APPROVAL_DEADLINE', ns: 'absences' }),
                value: 'approvalDeadline',
            }));
        }

        return columns;
    }

    addAbsenceType(event: MatChipInputEvent) {
        const typeName = (event.value || '').trim().toLowerCase();

        const type = (this.absenceTypes()?.filter((x) => x.name.toLocaleLowerCase().includes(typeName)) || [])[0];
        if (!type) {
            return;
        }

        this.addSelectedAbsenceType(type);

        // Clear the input value
        event.chipInput.clear();
        this.formGroup.controls.absenceType.setValue(null);
    }

    getRoles() {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId}.users.${this.customerId}.get`).pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap((can) => {
                return can ? this.roleAssignmentService.getAllForUser(this.customerId, this.userId, {
                    pagination: { 'with[]': [ 'role' ] },
                }).pipe(takeUntilDestroyed(this.destroyRef)) : EMPTY;
            }),
            catchError(() => of(mockArrayPaginatedResponse<RoleAssignment>())),
            map((response) => response.data),
        ).pipe(
            tap((roles) => this.showSubordinates.set(User.hasLeaderRole(roles, this.customerId))),
        );
    }

    showWarnings(cell: DataTableButtonCell<Absence>) {
        this.warningsDialog.open(of(cell.item.warnings || []), of(cell.item.employee?.name || ''));
    }

    deleteAbsence(cell: DataTableCell<DataTableButtonColumn<Absence>, Absence>) {
        if (!this.canDeleteApproved && cell.item.approval?.approved) {
            return this.confirmDialog.delete({
                title: this.translate.t('DELETE_ABSENCE', 'absences'),
                text: this.translate.t('CANT_DELETE_APPROVED', 'absences'),
            }).afterClosed()
                .pipe(tap(() => {
                    cell.disabled.set(false);
                })).subscribe();
        }

        this.confirmDialog.open({
            title: this.translate.t('DELETE_ABSENCE', 'absences'),
            text: this.translate.t('CONFIRM_DELETE', 'absences'),
            comment: {
                include: true,
                required: true,
            },
        }).afterClosed().subscribe((response) => {
            if (!(response?.ok && response.comment)) {
                cell.disabled.set(false);
                return;
            }

            this.absenceService.delete(cell.item.customerId, cell.item.employeeId, cell.item.id, response.comment).subscribe(() => {
                this.dataTableComponent()?.refresh();
            });
        });
    }

    selectedAbsenceType(event: MatAutocompleteSelectedEvent): void {
        const value = event.option.value;
        if (!(value instanceof AbsenceType)) {
            return;
        }

        this.addSelectedAbsenceType(value);
        this.formGroup.controls.absenceType.setValue(null);

        const input = this.absenceTypeInput();
        if (input) {
            input.nativeElement.value = '';
        }
    }

    approveAbsence(cell: DataTableCell<DataTableButtonColumn<Absence>, Absence>) {
        this.handleAbsenceDialog
            .approve(cell.item)
            .afterClosed()
            .subscribe((result) => {
                if (result instanceof Absence) {
                    this.dataTableComponent()?.refresh();
                } else {
                    cell.disabled.set(false);
                }
            });
    }

    declineAbsence(cell: DataTableCell<DataTableButtonColumn<Absence>, Absence>) {
        this.handleAbsenceDialog.decline(cell.item, {
            title: this.translate.t('DECLINE_ABSENCE', 'absences'),
            text: this.translate.t('DECLINE_ABSENCE_TEXT', 'absences', {
                from: cell.item.from?.toLocaleString(DateTime.DATETIME_MED) || '',
                to: cell.item.to?.toLocaleString(DateTime.DATETIME_MED) || '',
            }),
            confirmText: this.translate.t('DECLINE'),
            comment: {
                include: true,
                required: true,
            },
        }).subscribe((result) => {
            if (result instanceof Absence) {
                this.dataTableComponent()?.refresh();
            } else {
                cell.disabled.set(false);
            }
        });
    }

    update() {
        this.dataTableComponent()?.refresh();
        this.formGroup.markAsPristine();
    }

    reset() {
        for (const key of this.selectedAbsenceTypes().keys()) {
            this.removeAbsenceType(key);
        }

        this.formGroup.reset({
            mode: this.defaultMode.id,
            absenceType: null,
        });

        this.update();
    }

    getHandledApproved() {
        const mode = this.modes.find((m) => this.formGroup.getRawValue().mode === m.id);
        const options: Partial<GetAllAbsencesOptions> = {};

        if (mode?.handled != null) {
            options.handled = !!mode.handled;
        }

        if (mode?.approved != null) {
            options.approved = !!mode.approved;
        }

        return options;
    }

    private showStats() {
        const employee = this.formGroup.getRawValue().employee;

        this.dialog.open<AbsenceStatsDialogComponent, AbsenceStatsDialogData>(AbsenceStatsDialogComponent, {
            data: {
                size: DialogSize.Small,
                customerId: this.customerId,
                employeeId: employee instanceof Employee ? employee.id : undefined,
            },
        });
    }
}
