import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { AsyncPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ScheduleShiftService } from '../../http/schedule-shift.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { EmployeeService } from '../../../shared/http/employee.service';
import { forkJoin } from 'rxjs';
import { Employee } from '../../../shared/models/employee';
import { DateTime } from 'luxon';
import { ScheduleService } from '../../http/schedule.service';
import { Schedule } from '../../models/schedule';
import { Shift } from '../../models/shift';
import { MatCell, MatCellDef, MatColumnDef, MatFooterCell, MatFooterCellDef, MatFooterRow, MatFooterRowDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../shared/services/current.service';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';

interface HoursPlannedDay {
    date: string;
    weekday: string;
    dateTime: DateTime,
}

type HoursPlannedRow = {
    [key: string]: string;
    employeeName: string;
    employeeSum: string;
}

export interface ScheduleHoursPlannedDialogData extends DialogData {
    customerId: number;
    scheduleId: number;
}

@Component({
    selector: 'eaw-schedule-hours-planned-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        ActionButtonComponent,
        AsyncPipe,
        MatButton,
        MatDialogActions,
        TranslatePipe,
        MatDialogClose,
        MatDialogContent,
        MatTable,
        MatColumnDef,
        MatHeaderCell,
        MatCell,
        MatHeaderRow,
        MatRow,
        MatHeaderRowDef,
        MatRowDef,
        MatCellDef,
        MatHeaderCellDef,
        MatFooterRow,
        MatFooterRowDef,
        MatFooterCell,
        MatFooterCellDef,
        NumberPipe,
        InfoLoadingComponent,
    ],
    templateUrl: './schedule-hours-planned-dialog.component.html',
    styleUrl: './schedule-hours-planned-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleHoursPlannedDialogComponent extends DialogComponent<ScheduleHoursPlannedDialogData> implements OnInit {
    private readonly numberFormatterService = inject(NumberFormatterService);
    private readonly scheduleShiftService = inject(ScheduleShiftService);
    private readonly scheduleService = inject(ScheduleService);
    private readonly employeeService = inject(EmployeeService);
    private readonly currentService = inject(CurrentService);

    loading = signal(true);
    displayColumns = computed(() => [ 'employeeName', ...this.days().map((day) => day.date), 'employeeSum' ]);
    days = signal([] as HoursPlannedDay[]);
    dataSource = signal([] as HoursPlannedRow[]);
    dayTotals = signal<Record<string, number>>({});
    scheduleTotal = computed(() => Object.values(this.dayTotals()).reduce((acc, total) => acc + total, 0));

    constructor() {
        super(undefined, undefined, DialogSize.Max);
    }

    ngOnInit() {
        forkJoin([
            this.scheduleService.get(this.data.customerId, this.data.scheduleId),
            expandAllPages((pagination) => this.scheduleShiftService.getAll(this.data.customerId, this.data.scheduleId, pagination), { page: 1, per_page: 200 }),
            expandAllPages((pagination) => this.employeeService.getAll(this.data.customerId, pagination), { page: 1, per_page: 200 }),
        ]).subscribe(([ schedule, scheduleShifts, employees ]) => {
            this.setRows(schedule, scheduleShifts, employees);
            this.loading.set(false);
        });
    }

    setRows(schedule: Schedule, shifts: Shift[], employees: Employee[]) {
        const days = this.getDays(schedule);
        this.days.set(days);

        const data: HoursPlannedRow[] = employees.map((employee) => {
            const employeeSum = shifts.filter((s) => s.employeeId === employee.id).reduce((acc, shift) => acc + shift.netLength, 0);
            const employeeDays = days.reduce((acc, day) => {
                const employeeDayHours = shifts
                    .filter((shift) => shift.employeeId === employee.id && shift.from.hasSame(day.dateTime, 'day'))
                    .reduce((acc, shift) => acc + shift.netLength, 0);

                return {
                    ...acc,
                    [day.date]: this.numberFormatterService.formatDecimal(employeeDayHours / 3600, 2),
                };
            }, {} as Record<string, string>);

            return {
                employeeName: employee.name,
                employeeSum: this.numberFormatterService.formatDecimal(employeeSum / 3600, 2),
                ...employeeDays,
            };
        });

        const dayTotals = days.reduce((acc, day) => {
            const dayTotal = shifts.filter((s) => s.employeeId && s.from.hasSame(day.dateTime, 'day')).reduce((acc, shift) => acc + shift.netLength, 0);
            return {
                ...acc,
                [day.date]: dayTotal / 3600,
            };
        }, {} as Record<string, number>);

        this.dataSource.set(sort(data, this.currentService.languageTag, [ (x) => x.employeeName ]));
        this.dayTotals.set(dayTotals);
    }

    getDays(schedule: Schedule) {
        let from = schedule.from;
        const to = schedule.to.minus({ second: 1 });

        const days: HoursPlannedDay[] = [];
        while (from <= to) {
            days.push({
                date: from.toLocaleString(DateTime.DATE_MED),
                weekday: from.weekdayLong || '',
                dateTime: from,
            });

            from = from.plus({ days: 1 });
        }

        return days;
    }
}
