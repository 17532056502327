import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, signal, SimpleChanges, viewChild, ViewEncapsulation } from '@angular/core';
import { Editor, EditorOptions } from 'tinymce';
import { CurrentService } from '../../../shared/services/current.service';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type TurndownService from 'turndown';
import { EditorComponent, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';

@Component({
    selector: 'eaw-wysiwyg',
    templateUrl: './wysiwyg.component.html',
    styleUrl: './wysiwyg.component.scss',
    standalone: true,
    imports: [
        EditorComponent,
        InfoLoadingComponent,
    ],
    encapsulation: ViewEncapsulation.ShadowDom,
    providers: [
        // https://www.tiny.cloud/docs/tinymce/latest/angular-ref/
        { provide: TINYMCE_SCRIPT_SRC, useValue: '/tinymce/tinymce.min.js' },
    ],
})
export class WysiwygComponent implements OnInit, OnChanges, OnDestroy {
    private readonly current = inject(CurrentService);
    private readonly destroyRef = inject(DestroyRef);

    @Input() readonly = false;
    @Input() content?: string;
    @Input() height?: number;
    @Input() resize?: boolean;

    @Output() contentChange = new EventEmitter<string>();
    @Output() editorChange = new EventEmitter<Editor>();

    editor = viewChild(EditorComponent);

    protected loading = signal(true);
    protected turndownService = signal<TurndownService | undefined>(undefined);
    protected editorOptions = signal<Partial<EditorOptions> | undefined>(undefined);

    ngOnInit() {
        void this.setupEditor();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['content'] && this.editor()) {
            this.editor()?.writeValue(changes['content'].currentValue);
        }
    }

    ngOnDestroy() {
        this.editor()?.editor.destroy();
    }

    getContents() {
        const content = this.editor()?.editor.getContent();
        return content ? this.turndownService()?.turndown(content) : '';
    }

    private getLanguage(): string | undefined {
        const languageCode = this.current.languageTag.toLowerCase().replace('_', '-');

        // English is default, just skip it
        if (languageCode.startsWith('en')) {
            return undefined;
        }

        // Packages are downloaded from https://www.tiny.cloud/get-tiny/language-packages/
        // Check src/assets/tinymce-languages/${language}.js for available languages
        switch (languageCode) {
            case 'de-ch':
                return 'de';
            case 'fr-fr':
            case 'fr-ch':
                return 'fr_FR';
            case 'it-ch':
                return 'it';
            case 'sv':
            case 'sv-se':
                return 'sv_SE';
            case 'nb-no':
                return 'nb_NO';
        }

        return languageCode;
    }

    private async setupEditor() {
        let initialContent = this.content || '';

        if (initialContent) {
            const showdown = (await import(/* webpackChunkName: "showdown" */ 'showdown')).default;
            const converter = new showdown.Converter();
            initialContent = converter.makeHtml(initialContent);
        }

        const turndown = (await import(/* webpackChunkName: "turndown" */ 'turndown')).default;
        const language = this.getLanguage();

        this.editorOptions.set({
            license_key: 'gpl',
            promotion: false,
            elementpath: false,
            min_height: this.height || 400,
            language_url: language ? `/assets/tinymce-languages/${language}.js` : undefined,
            language,
            plugins: [ 'image', 'link', 'lists' ],
            menu: {
                format: { title: 'Format', items: 'bold italic underline strikethrough codeformat | forecolor backcolor | removeformat' },
                insert: { title: 'Insert', items: 'image link | hr' },
            },
            menubar: 'format insert',
            toolbar: 'styles link bold italic underline strikethrough numlist bullist',
            height: this.height || 0,
            resize: this.resize ?? true,
            content_style: `
                @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap'); 
                
                body { 
                    font-family: 'Roboto', sans-serif;
                    font-size: 13px;
                } 
                `,
            setup: (editor) => {
                editor.on('init', () => {
                    this.editorChange.emit(editor);
                    editor.setContent(initialContent);
                    fromEvent(editor.getBody(), 'keyup').pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.contentChange.emit(this.getContents()));
                });
            },
        });

        this.turndownService.set(new turndown()
            .addRule('pre', {
                filter: 'pre',
                replacement: function(content) {
                    return '```\n' + content.replace(/[`*~_]+/g, '') + '\n```';
                },
            })
            .addRule('code', {
                filter: 'code',
                replacement: function(content) {
                    return '`' + content.replace(/[`*~_]+/g, '') + '`';
                },
            })
            .addRule('u', {
                filter: 'u',
                replacement: function(content) {
                    return '__' + content + '__';
                },
            })
            .addRule('img', {
                filter: 'img',
                replacement: function(_, node) {
                    const imgNode = node instanceof HTMLImageElement ? node : undefined;
                    if (!imgNode) {
                        return '';
                    }

                    let dim = '';
                    if (imgNode.style) {
                        const h = imgNode.style.getPropertyValue('height');
                        const w = imgNode.style.getPropertyValue('width');
                        if (h && w) {
                            dim = ' =' + parseInt(w) + 'x' + parseInt(h);
                        }
                    }
                    return `![](${imgNode.src}${dim})`;
                },
            }));

        this.loading.set(false);
    }
}
