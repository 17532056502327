import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { CreateOfftimeRequestDialogComponent } from './create-offtime-request-dialog.component';
import { OffTime } from '../../models/off-time';

export interface CreateOfftimeRequestDialogData extends DialogData {
    customerId: number;
    employeeId: number;
}

@Injectable({
    providedIn: 'root',
})
export class CreateOfftimeRequestDialogService {

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
    ) {}

    open(customerId: number, employeeId: number) {
        return this.dialog.open<CreateOfftimeRequestDialogComponent, CreateOfftimeRequestDialogData, OffTime | undefined>(CreateOfftimeRequestDialogComponent, {
            data: {
                size: DialogSize.Medium,
                customerId,
                employeeId,
            },
        });
    }
}
