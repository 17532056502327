import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { User } from '../../../../../shared/models/user';
import { Customer } from '../../../../../shared/models/customer';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { UserAccessComponent } from '../user-access/user-access.component';
import { smallDevice } from '../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { PermissionCheckService } from '../../../../../shared/services/permission-check.service';
import { tap, zip } from 'rxjs';
import { UserComponent } from '../user/user.component';
import { MatCardModule } from '@angular/material/card';
import { userGroupsUpgradedComponent } from '../../../../angularjs/users/user-groups-upgraded.component';
import { UserOld } from '../../../../../shared/angularjs/user-old';
import CustomerOld from '../../../../../shared/angularjs/customer-old';
import { InfoLoadingComponent } from '../../../../../shared/components/info-loading/info-loading.component';
import { CustomerUserService } from '../../../../../shared/http/customer-user.service';

@Component({
    selector: 'eaw-customer-user',
    standalone: true,
    imports: [ CommonModule, MatTabsModule, TranslatePipe, UserAccessComponent, UserComponent, MatCardModule, userGroupsUpgradedComponent, InfoLoadingComponent ],
    templateUrl: './customer-user.component.html',
    styleUrl: './customer-user.component.scss',
})
export class CustomerUserComponent implements OnInit {
    permissionCheckService = inject(PermissionCheckService);
    customerUserService = inject(CustomerUserService);

    @Input({ required: true }) userId!: number;
    @Input({ required: true }) customer!: Customer;

    user?: User;
    smallScreen = smallDevice();
    canGetUsers = false;
    canGetGroups = false;
    /** @deprecated this is here because of user groups component */
    oldUser?: UserOld;
    /** @deprecated this is here because of user groups component */
    oldCustomer?: CustomerOld;

    ngOnInit() {
        zip([
            this.customerUserService.get(this.customer.id, this.userId, [ 'language', 'email_change' ]),
            this.permissionCheckService.isAllowed(`customers.${this.customer.id}.users.${this.userId}.get`),
            this.permissionCheckService.isAllowed(`customers.${this.customer.id}.user_groups.*.get`),
        ]).pipe(tap(([ user, canGetUsers, canGetGroups ]) => {
            this.user = user;
            this.oldUser = new UserOld(this.user._response);
            this.oldCustomer = new CustomerOld(this.customer._response);

            this.canGetUsers = canGetUsers;
            this.canGetGroups = canGetGroups;
        })).subscribe();
    }
}
