import { BusinessUnit } from './business-unit';

export class BusinessUnitTree {
    units: BusinessUnit[] = [];

    constructor(units: BusinessUnit[]) {
        // Add the no parent "group"
        units.unshift(new BusinessUnit({
            is_productive: false,
            color: '#000',
            customer_id: 0,
            id: 0,
            name: '',
            created_at: '',
            updated_at: '',
        }));

        units.forEach((unit) => {
            unit.children = units.filter((u) => {
                if (unit.id === u.id) {
                    return false;
                }
                return unit.id ? u.parentId === unit.id : u.parentId === null;
            });
        });

        units.forEach((u) => {
            u.children = u.children.filter((c) => !c.children.length);
        });

        this.units = units.filter((u) => u.children.length);
    }
}
