// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';

module('eaw.dashboard').component('allShiftsWidget', {
    template: `<endless-shifts customer-id="$shifts.eawWidget.widget.customer" from="$shifts.from" on-loaded="$shifts.onLoadPage(list)"></endless-shifts>
`,
    require: {
        eawWidget: '^eawWidget',
    },
    controllerAs: '$shifts',
    controller: function() {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.from = moment().startOf('d');
            ctrl.eawWidget.setLoading(true);
        };

        ctrl.onLoadPage = (list) => {
            if (!list.getLength()) {
                ctrl.eawWidget.setEmpty(true);
            }

            ctrl.eawWidget.setLoading(false);
        };
    },
});
