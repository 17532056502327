import { DataTableColumn } from './data-table-column';
import { IconPortalComponent } from '../portals/icon-portal/icon-portal.component';
import { DataTableHeader } from './data-table-header';
import { DataTableFunctionValue } from '../interfaces/data-table-value';
import { EawMaterialColorHue } from '../../shared/services/material-color.service';

type IconFn<Item> = DataTableFunctionValue<DataTableIconColumn<Item>, Item, string>;

type TextFn<Item> = DataTableFunctionValue<DataTableIconColumn<Item>, Item, string>;

export interface IDataTableIconColumn<Item> {
    header: DataTableHeader;
        icon: IconFn<Item> | string;
        text?: TextFn<Item>
        color: EawMaterialColorHue | DataTableFunctionValue<DataTableIconColumn<Item>, Item, EawMaterialColorHue>;
}

export class DataTableIconColumn<Item> extends DataTableColumn<DataTableIconColumn<Item>, Item, void> {
    component = IconPortalComponent;
    icon: IconFn<Item>;
    text?: TextFn<Item>;
    color: EawMaterialColorHue | DataTableFunctionValue<DataTableIconColumn<Item>, Item, EawMaterialColorHue>;
    override loading?: never;

    constructor(column: IDataTableIconColumn<Item>) {
        super({
            exportable: false,
            ...column,
        });

        this.color = column.color;
        this.icon = typeof column.icon === 'string' ? (() => column.icon) as IconFn<Item> : column.icon;
        this.text = column.text;

        if (!this.text) {
            this.header?.classes.add('header-center');
        }
    }
}
