// @ts-nocheck

import moment, { Moment } from 'moment-timezone';
import { DateTimeConverter } from '../../shared/utils/date-time-converter';
import { CouponType } from './coupon-type';
import { CouponStatus } from './interfaces';

export class Coupon {
    id: string;
    type: CouponType;
    expires_at: Moment;
    available_at: Moment;
    used_at: Moment;
    unit: string;
    owner_id: number;
    value: number;
    employee: { id: number, customer_id: number };

    constructor(employee: { id: number, customer_id: number }, data: { id, type, expires_at, available_at, used_at, unit, owner_identifier, value }) {
        if (data) {
            this.id = data.id;
            this.type = new CouponType(data.type);
            this.expires_at = DateTimeConverter.convertStringToMoment(data.expires_at);
            this.available_at = DateTimeConverter.convertStringToMoment(data.available_at);
            this.used_at = data.used_at ? DateTimeConverter.convertStringToMoment(data.used_at) : null;
            this.unit = data.unit;
            this.owner_id = data.owner_identifier;
            this.value = data.value;
        }

        this.employee = employee;
    }

    get status(): CouponStatus {
        let text = 'coupons:AVAILABLE_AT';
        let date: Moment = this.available_at;

        if (this.isUsed()) {
            date = this.used_at;
            text = 'coupons:USED_AT';
        } else if (this.isExpired()) {
            date = this.expires_at;
            text = 'coupons:EXPIRED_AT';
        } else if (this.isAvailable()) {
            date = this.expires_at;
            text = 'coupons:EXPIRES_AT';
        }

        return {
            text,
            date,
        };
    }

    get icon(): string {
        return this.type.icon.url;
    }

    get image(): string {
        return this.type.getBackgroundUrl();
    }

    set used(used: boolean) {
        this.used_at = used ? moment() : null;
    }

    get used(): boolean {
        return this.isUsed();
    }

    isExpired(date = undefined): boolean {
        return moment.isMoment(this.expires_at) ? this.expires_at.isBefore(date ?? moment()) : false;
    }

    isAvailable(date = undefined): boolean {
        return moment.isMoment(this.available_at) ? this.available_at.isBefore(date ?? moment()) : false;
    }

    isUsed(date = undefined): boolean {
        return moment.isMoment(this.used_at) ? this.used_at.isSameOrBefore(date ?? moment(), 'm') : false;
    }
}
