import { SortDirection } from '@angular/material/sort';

/**
 * @deprecated
 */
interface OldPaginationOptions {
    direction?: SortDirection,
    fields?: string[],
    'fields[]'?: string[],
    filter?: string,
    per_page?: number,
    page?: number,
    with?: string[],
    ['with[]']?: string[],
    ['order_by[]']?: string[],
    ['count[]']?: string[],
    order_by?: string[] | string,
    counts?: string[],
}

/**
 * @deprecated - use PaginationOptions instead
 */
export class Pagination implements OldPaginationOptions {
    direction?: SortDirection;
    per_page?: number;
    page?: number;
    filter?: string;
    order_by?: string;

    with?: string[];
    orderArr?: string[];
    fields?: string[];
    counts?: string[];

    ['with[]']?: string[];
    ['order_by[]']?: string[];
    ['fields[]']?: string[];
    ['count[]']?: string[];

    constructor(options: OldPaginationOptions) {
        this.direction = options.direction;
        this.per_page = options.per_page;
        this.page = !options.page || options.page < 1 ? 1 : options.page;
        this.filter = options.filter;

        this.fields = options.fields;

        Object.defineProperty(this, 'fields[]', {
            enumerable: true,
            get() {
                return this.fields;
            },
        });

        this.with = options.with || options['with[]'];

        Object.defineProperties(this, {
            with: {
                enumerable: false,
                writable: true,
            },
            orderArr: {
                enumerable: false,
                writable: true,
            },
            counts: {
                enumerable: false,
                writable: true,
            },
            fields: {
                enumerable: false,
                writable: true,
            },
        });

        Object.defineProperty(this, 'with[]', {
            enumerable: true,
            get() {
                return this.with;
            },
            set(val) {
                this.with = val;
            },
        });

        if (options.order_by instanceof Array) {
            this.orderArr = options.order_by;
        } else {
            this.order_by = options.order_by;
        }

        Object.defineProperty(this, 'order_by[]', {
            enumerable: true,
            get() {
                return this.orderArr;
            },
        });

        this.counts = options.counts;

        Object.defineProperty(this, 'count[]', {
            enumerable: true,
            get() {
                return this.counts;
            },
            set(arr) {
                this.counts = arr;
            },
        });
    }
}
