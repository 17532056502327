import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { DateTime } from 'luxon';
import { ObserverResponse, Observer, SettingGroupObserverResponse, SettingGroupObserver } from '../models/observer';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';

interface AddObserver {
    class: string;
    from: DateTime;
    to?: DateTime | null;
}

@Injectable({
    providedIn: 'root',
})
export class ObserverService {

    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) { }

    getAll(options: PaginationOptions & { group_id?: number } = {}) {
        return this.http.get<ArrayPaginatedResponse<ObserverResponse>>('/observers', { params: { ...options } }).pipe(classifyArrayPaginatedResponse(Observer));
    }

    getAllForSettingsGroup(groupId: number, options: PaginationOptions = {}) {
        return this.http.get<ArrayPaginatedResponse<SettingGroupObserverResponse>>(`/setting_groups/${groupId}/observers`, { params: { ...options } })
            .pipe(classifyArrayPaginatedResponse(SettingGroupObserver));
    }

    // Enable an observer on a settings group
    create(groupId: number, body: AddObserver) {
        return this.http.post<SettingGroupObserverResponse>(`/setting_groups/${groupId}/observers`, body).pipe(classifyItem(SettingGroupObserver));
    }

    // Update an observer on a settings group
    update(groupId: number, observerId: number, body: Partial<AddObserver>) {
        return this.http.put<SettingGroupObserverResponse>(`/setting_groups/${groupId}/observers/${observerId}`, body).pipe(classifyItem(SettingGroupObserver));
    }

    // Disable an observer on a settings group
    delete(groupId: number, observerId: number) {
        return this.http.delete<null>(`/setting_groups/${groupId}/observers/${observerId}`);
    }
}
