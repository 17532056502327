// @ts-nocheck
import { module } from 'angular';
import { Namespace } from '../../shared/enums/namespace';
import { ChainAvailabilityComponent } from '../pages/availability/chain-availability.component';
import { HrComponent } from '../pages/hr/hr.component';
import { createState } from '../../shared/utils/create-state';
import { ChainKpiComponent } from '../pages/chain-kpi/chain-kpi.component';
import { ChainLocksComponent } from '../pages/chain-locks/chain-locks.component';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { ChainPaidTimeComponent } from '../pages/chain-paid-time/chain-paid-time.component';
import { LearningModuleModule } from '../../learning-module/types/modules';
import { ChainBalancesComponent } from '../pages/chain-balances/chain-balances.component';
import { Products } from '../../shared/enums/products';
module('eaw.chain-ops', [
    'eaw.scheduling',
    'eaw.resource',
    'eaw.misc',
    'eaw.chain-ops.employees',
    'eaw.chain-ops.absences',
    'eaw.chain-ops.user-groups',
]).config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state(`eaw/app/chain_ops`, {
        parent: 'eaw/app',
        url: '/chain_ops',
        abstract: true,
        data: {
            learningModuleModules: [ LearningModuleModule.ChainOps ],
            products: [ Products.ChainOps ],
            breadcrumb: { key: 'CHAIN_OPS', ns: 'navigation' },
            permissions: [ `customers.{customer}.customer_groups.*.get` ],
            i18nextNs: [
                Namespace.Scheduling,
                Namespace.Admin,
                Namespace.Company,
                Namespace.SwissRiskAndCare,
                Namespace.ChainOps,
                Namespace.Digisign,
            ],
        },
    });
    createState($stateProvider, {
        name: 'eaw/app/chain_ops/paid_time',
        parent: `eaw/app/chain_ops`,
        url: '/paid_time',
        views: {
            'content@': {
                component: ChainPaidTimeComponent,
            },
        },
        data: {
            products: [ Products.PaidTime ],
            breadcrumb: { key: 'PAID_TIME_OVERVIEW', ns: 'payroll' },
        },
    });
    createState($stateProvider, {
        name: 'eaw/app/chain_ops/kpi',
        parent: `eaw/app/chain_ops`,
        url: '/kpi',
        views: {
            'content@': {
                component: ChainKpiComponent,
            },
        },
        data: {
            products: [ Products.KeyPerformanceIndicators ],
            breadcrumb: { key: 'KEY_PERFORMANCE_INDICATORS', ns: 'navigation' },
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/hr`, {
        parent: `eaw/app/chain_ops`,
        url: '/hr',
        views: {
            'content@': {
                component: HrComponent,
            },
        },
        data: {
            products: [ Products.HumanResources ],
            breadcrumb: { key: 'HR', ns: 'navigation' },
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/availability`, {
        parent: `eaw/app/chain_ops`,
        url: '/availability',
        views: {
            'content@': {
                component: ChainAvailabilityComponent,
            },
        },
        data: {
            products: [ Products.Availability ],
            breadcrumb: { key: 'AVAILABILITY' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/chain_ops/balances`,
        parent: `eaw/app/chain_ops`,
        url: '/balances',
        views: {
            'content@': {
                component: ChainBalancesComponent,
            },
        },
        data: {
            breadcrumb: { key: 'BALANCES', ns: 'navigation' },
            settings: {
                'chain-ops.balances': (value) => value?.asBoolean() === true,
            }
        },
    })
    $stateProvider.state(`eaw/app/chain_ops/locks`, {
        parent: `eaw/app/chain_ops`,
        url: '/locks',
        views: {
            'content@': {
                component: ChainLocksComponent,
            },
        },
        resolve: [ currentResolver('customerId', 'customer', 'id') ],
        data: {
            products: [ Products.PeriodLocks ],
            breadcrumb: { key: 'LOCKS', ns: 'navigation' },
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/schedule_overview`, {
        parent: `eaw/app/chain_ops`,
        url: '/schedules',
        data: {
            i18nextNs: [ Namespace.Scheduling, Namespace.Calendar, Namespace.Availabilities, Namespace.Staffing, Namespace.Autosched, Namespace.Company ],
        },
        resolve: {
            customerGroups: [ 'customerCustomerGroupFactory', 'customer', 'ToastService', function(customerCustomerGroupFactory, customer, ToastService) {
                return customerCustomerGroupFactory.getAll.query(customer.id, { with: [ 'members' ] }).$promise.then((resp) => {
                    if (resp.data.length) {
                        return resp.data;
                    }

                    void ToastService.t('NO_CUSTOMER_plural', Namespace.ChainOps);
                    return Promise.reject();
                });
            } ],
            sref: function() {
                return `eaw/app/chain_ops/schedule_overview/tab`;
            },
        },
        views: {
            'content@': 'chainTabs',
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/schedule_overview/tab`, {
        parent: `eaw/app/chain_ops/schedule_overview`,
        url: '/:group',
        views: {
            chainTabsView: 'chainScheduleOverview',
        },
        data: {
            breadcrumb: { key: 'SCHEDULE_OVERVIEW', ns: 'navigation' },
        },
        resolve: {
            customerGroupId: [ '$transition$', function($transition$) {
                return $transition$.params().group;
            } ],
        },
    });
    $stateProvider.state('eaw/app/chain_ops/tariffs', {
        parent: `eaw/app/chain_ops`,
        url: '/tariffs',
        views: {
            'content@': 'chainOpsTariffList',
        },
        resolve: {
            settingGroup: [ 'CustomerSettingsGroupService', 'customer', function(CustomerSettingsGroupService, customer) {
                return CustomerSettingsGroupService.getByCustomerId(customer.id).$promise;
            } ],
        },
        data: {
            permissions: [ `setting_groups.{customer.setting_group_id}.tariffs.*.get` ],
            breadcrumb: { key: 'TARIFF_plural', ns: 'admin' },
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/tariff`, {
        parent: 'eaw/app/chain_ops/tariffs',
        url: '/:settings_group_id/tariff/:tariff_id',
        abstract: true,
        views: {
            'content@': {
                template: `<ui-view name="tariff"></ui-view><ui-view name="rates"></ui-view>`,
            },
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/tariff/rates`, {
        parent: `eaw/app/chain_ops/tariff`,
        url: '/rates',
        views: {
            tariff: {
                component: 'chainTariffs',
            },
            rates: {
                component: 'chainTariffRates',
            },
        },
        data: {
            breadcrumb: { key: 'TARIFF', ns: 'admin' },
        },
        resolve: {
            tariff: [ '$transition$', 'TariffFactory', function($transition$, TariffFactory) {
                const params = $transition$.params();
                return TariffFactory.get({
                    tariff_id: params.tariff_id,
                    setting_group_id: params.settings_group_id,
                    with: [ 'rules' ],
                }).$promise;
            } ],
        },
    });
} ]);
