import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DialogComponent, DialogData, DialogSize } from '../../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { EmployeeRotation } from '../../models/employee-rotation';
import { EmployeeRotationService } from '../../http/employee-rotation.service';
import { catchError, EMPTY, Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { Rotation } from '../../models/rotation';
import { Namespace, NamespaceFile } from '../../../../shared/enums/namespace';
import { Employee } from '../../../../shared/models/employee';
import { EmployeeService } from '../../../../shared/http/employee.service';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RotationAutocompleteComponent } from '../../../rotation-autocomplete/rotation-autocomplete.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';

export interface EmployeeRotationDialogData extends DialogData {
    employeeId: number,
    customerId: number,
    rotation: EmployeeRotation,
}

@Component({
    selector: 'eaw-employee-rotation-dialog',
    templateUrl: './employee-rotation-dialog.component.html',
    styleUrl: './employee-rotation-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        RotationAutocompleteComponent,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmployeeRotationDialogComponent extends DialogComponent implements OnInit {
    saving = false;
    confirmText: string;
    title: { ns?: NamespaceFile; i18n: string };
    employeeRotation: EmployeeRotation;
    employee?: Employee;
    form = new FormGroup({
        dateRange: new FormGroup({
            from: new FormControl<DateTime | undefined>(undefined, {
                nonNullable: true,
                validators: [ Validators.required ],
            }),
            to: new FormControl<DateTime | undefined | null>(null),
        }),
        rotation: new FormControl<Rotation | undefined >(undefined, {
            nonNullable: true,
            validators: [ Validators.required ],
        }),
    });

    constructor(
        @Inject(EmployeeRotationService) protected employeeRotationService: EmployeeRotationService,
        @Inject(EmployeeService) protected employeeService: EmployeeService,
        @Inject(MAT_DIALOG_DATA) override data: EmployeeRotationDialogData,
        @Inject(MatDialogRef) ref: MatDialogRef<EmployeeRotationDialogComponent>,
    ) {
        data.size = DialogSize.Small;
        super(ref, data);

        this.employeeRotation = data.rotation;

        if (data.rotation.rotation) {
            this.form.get('rotation')?.setValue(data.rotation.rotation);
            this.form.get('rotation')?.disable();
        }

        if (data.rotation?.id) {
            this.title = { i18n: 'UPDATE' };
        } else {
            this.title = {
                i18n: 'CONNECT_EMPLOYEE',
                ns: Namespace.Rotation,
            };
        }

        this.confirmText = 'SAVE';
    }

    ngOnInit(): void {
        this.form.controls.dateRange.setValue({
            from: this.employeeRotation.from,
            to: this.employeeRotation.to,
        });

        this.employeeService.get(this.data.customerId, this.data.employeeId).subscribe((employee) => {
            this.employee = employee;
        });
    }

    save() {
        let observable: Observable<EmployeeRotation>;

        const dateRange = this.form.controls.dateRange.value;
        const rotation = this.form.controls.rotation.value;

        if (rotation == null || dateRange?.from == null) {
            return;
        }

        this.saving = true;

        if (this.employeeRotation.id) {
            observable = this.employeeRotationService.update(this.data.customerId, this.employeeRotation.employeeId, this.employeeRotation.id, dateRange);
        } else {
            observable = this.employeeRotationService.create(this.data.customerId, this.data.employeeId, rotation.id, dateRange.from, dateRange.to);
        }

        observable.pipe(catchError(() => {
            this.saving = false;

            return EMPTY;
        })).subscribe((rotation) => {
            this.dialogRef.close(rotation);
            this.saving = false;
        });
    }
}
