import { Component, inject, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RunService } from '../../http/run.service';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { Storage } from '../../../shared/utils/storage';
import { DateTime } from 'luxon';
import { ReportService } from '../../http/report.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { isNamespace, Namespace, NamespaceFile } from '../../../shared/enums/namespace';
import { AsyncPipe, KeyValue, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { ReportOption } from '../../interfaces/report-option';
import { Report } from '../../models/report';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EmployeeSelectMultipleComponent } from '../../components/employee-select-multiple/employee-select-multiple.component';
import { EmployeeSelectSingleComponent } from '../../components/employee-select/employee-select-single.component';
import { DateTimeInputComponent } from '../../../shared/components/date-time/date-time-input/date-time-input.component';
import { ReportSelectComponent } from '../../components/report-select/report-select.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../shared/services/current.service';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';

interface RunReportData extends DialogData {
    report: Report;
}

type OptionValue = number | string | boolean | undefined;

@Component({
    selector: 'eaw-run-report',
    templateUrl: './run-report-component.html',
    styleUrl: './run-report.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        NgIf,
        MatInputModule,
        MatSlideToggleModule,
        ReportSelectComponent,
        DateTimeInputComponent,
        EmployeeSelectSingleComponent,
        EmployeeSelectMultipleComponent,
        MatProgressSpinnerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        KeyValuePipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class RunReportComponent extends DialogComponent<RunReportData, 'run'> implements OnInit, OnDestroy {
    private readonly runService = inject(RunService);
    private readonly reportService = inject(ReportService);
    private readonly translate = inject(TranslateService);
    private readonly current = inject(CurrentService);

    protected generating = false;
    protected loading = false;
    protected report: Report;

    protected options: WritableSignal<Map<string, ReportOption>> = signal(new Map());

    protected formGroup = new FormGroup({
        interval: new FormGroup(
            {
                from: new FormControl(),
                to: new FormControl(),
            },
        ),
        format: new FormControl(),
    });

    protected optionsGroup = new FormGroup<Record<string, FormControl>>({});

    private subscription?: Subscription;
    private lsKey?: string;
    protected formats: Map<string, string>;

    constructor() {
        super(undefined, undefined, DialogSize.Medium);

        this.report = this.data.report;
        this.formats = new Map<string, string>();

        delete this.report.options;

        this.formGroup.get('format')?.valueChanges.pipe(
            distinctUntilChanged(),
        ).subscribe(async (format) => {
            await this.onFormatChange(format);
        });

        this.formGroup.get('interval')?.valueChanges.subscribe((val) => {
            this.onIntervalChange(val.from, val.to);
        });
    }

    async ngOnInit() {
        this.formGroup.get('format')?.setValue(Object.keys(this.report.formats)[0]);
        for (const [ key, value ] of Object.entries(this.report.formats)) {
            let translation = '';
            const [ ns, k ] = value.split('.');

            if (k && isNamespace(ns)) {
                translation = await this.translate.t(k, ns as NamespaceFile);
            } else {
                translation = await this.translate.t(value);
            }

            this.formats.set(key, translation);
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    onIntervalChange(from?: DateTime, to?: DateTime) {
        delete this.report.options;

        if (!from || !to) {
            return;
        }

        this.loading = true;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.subscription = this.reportService.get(this.report.ownerId, this.report.id!, {
            from,
            to,
        }).subscribe(async (report: Report) => {
            this.report = report;
            await this.onFormatChange(this.formGroup.value.format);
            this.loading = false;
        });
    }

    async onFormatChange(format: string) {
        this.lsKey = `report-options:${this.report?.id}:${this.report?.ownerId}:${this.formGroup.value.format}`;
        this.lsKey = Storage.prefix(this.lsKey);

        // Reset the options group so that the previous options are removed
        // Reset before checking report options so that no options are reflected in an empty options group
        this.optionsGroup = new FormGroup<Record<string, FormControl>>({});

        const options = new Map<string, ReportOption>();

        if (!this.report.options) {
            this.options.set(options);
            return;
        }

        for await (const [ key, option ] of this.report.options) {
            if (option.formats && !option.formats.includes(format)) {
                continue;
            }

            const saved = await Storage.getItem(this.lsKey + key);
            let val: OptionValue = undefined;

            if (saved) {
                val = saved;
            } else if (option.default !== undefined) {
                // support default = false
                val = option.default;
            }

            if (!this.optionsGroup.get(key)) {
                this.optionsGroup.addControl(key, new FormControl(val));
            }

            if (option.type == 'select') {
                const entries = Object.entries(option.options || {});

                // Skip empty options. Could happen if e.g. the report lists positions, but the given customer doesn't have any positions.
                if (entries.length === 0) {
                    continue;
                }

                for (const [ val1, name ] of entries) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    option.options![val1] = await this.getSelectOptionTranslation(name as string);
                }

                // Sort the select options by their translated name/numeric value if numeric
                option.options = sort(entries, this.current.languageTag, [ (item) => item ])
                    .reduce((acc, [ k, v ]) => {
                        return { ...acc, [k]: v };
                    }, {} as Record<string, unknown>);
            }

            options.set(key, option);
        }

        this.options.set(options);
    }

    async getSelectOptionTranslation(str: string) {
        const [ nsOrKey, key ] = str.split('.');
        const namespace = isNamespace(nsOrKey);

        if (key && namespace) {
            return this.translate.t(key, namespace);
        }

        if (nsOrKey) {
            return this.translate.t(nsOrKey.toUpperCase(), Namespace.Reports);
        }

        return '';
    }

    chooseOption(optionKey: string) {
        void Storage.setItem(this.lsKey + optionKey, this.optionsGroup.value[optionKey]);
    }

    generate() {
        this.generating = true;

        const options: Record<string, unknown> = Object.entries(this.optionsGroup.value).reduce((acc: Record<string, unknown>, [ key, val ]) => {
            if (val !== '' && val != null) {
                acc[key] = val;
            }

            return acc;
        }, {});

        const create = this.runService.create(this.report.ownerId, this.report.id, {
            from: this.formGroup.value.interval?.from.startOf('day'),
            to: this.formGroup.value.interval?.to.endOf('day'),
            format: this.formGroup.value.format,
            options,
        }).subscribe({
            next: () => {
                this.dialogRef.close('run');
            },
            error: () => {
                this.formGroup.enable();
                this.optionsGroup.enable();
            },
        });

        this.formGroup.disable();
        this.optionsGroup.disable();

        return create;
    }

    trackOptionBy(_: number, option: KeyValue<string, ReportOption>) {
        return option.key;
    }

    getCustomerIds(value: ReportOption) {
        const key: string = value.customers || 'customers';
        const customerOption: ReportOption | undefined = this.report.options?.get(key);
        const customerControl = this.optionsGroup.get(key);

        if (!customerOption || !customerControl || !customerControl.value?.length) {
            return [ String(this.report.ownerId) ];
        }

        return customerControl.value;
    }

    /**
     * For passing to keyvalue pipe. Sorts the options by object order.
     */
    protected sortOptions(_a: KeyValue<string, unknown>, _b: KeyValue<string, unknown>) {
        return 0;
    }
}
