// @ts-nocheck
import { module } from 'angular';

const template1 = `<div layout="column">
    <div class="messages-file-container" ng-repeat="file in $ctrl.files track by $index" layout="row">
        <div class="file-name overflow-ellipsis">{{file.name}}</div>
        <div class="file-size" flex>({{file.size | filesize}})</div>
        <md-icon ng-bind="'close'" class="s18" ng-click="$ctrl.files.splice($index, 1)"></md-icon>
    </div>
</div>
`;

module('eaw.messages').component('messagesFiles', {
    bindings: {
        files: '=', // Array
    },
    template: template1,
});
