<eaw-page-header [fabButton]="fabButton" class="tw-mb-8">
    <span title>{{ 'MEMBER_plural' | translate | async }}</span>
</eaw-page-header>
<mat-card>
    <eaw-data-table
            [columns]="columns"
            [request]="request"
            [goTo]="{state: 'eaw/app/admin/customers/view/info', params: [{stateKey: 'id', itemKey: 'customerId'}]}"
            initialSortBy="from"
            initialSortDirection="desc"
            (paginationChange)="updateTable()">
    </eaw-data-table>
</mat-card>
