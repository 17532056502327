import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EawUrl } from '../modules/resource/url.service';
import { ArrayPaginatedResponse } from '../../interfaces/paginated-response';
import { Moment } from 'moment-timezone';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../interfaces/pagination-options';
import { PaginationOld } from '../../utils/pagination-old';
import { User, UserResponse } from '../../models/user';
import { classifyArrayPaginatedResponse, classifyItem } from '../../utils/rxjs/classify';

type Dt = Moment | string;
type CustomerParams = {
    from?: Dt
    to?: Dt
    active?: Dt
    include_future?: boolean
    include_custom_fields?: boolean
    user_groups?: number[]
}

type UserData = {
    first_name: string
    last_name: string
    email: string
    phone: string
    language_code: string
    country_code: string
};

type UpdateUserParams = Partial<UserData> & {
    password?: string,
    password_confirmation?: string,
}

type GetAll = Observable<ArrayPaginatedResponse<User>>;

@Injectable({
    providedIn: 'any',
})
export class UserServiceOld {
    constructor(@Inject(HttpClient) private http: HttpClient) { }

    /**
     * @deprecated
     * @see UserService
     */
    create(params: UserData): Observable<User> {
        return this.http.post<UserResponse>(`${EawUrl.url}/users`, params).pipe(classifyItem(User));
    }

    /**
     * @deprecated
     * @see UserService
     */
    update(id: number, user: UpdateUserParams) {
        return this.http.put<UserResponse>(`${EawUrl.url}/users/${id}`, user).pipe(classifyItem(User));
    }

    /**
     * @deprecated
     * @see UserService
     */
    get(key: string | number, params?: {'with[]'?: string[]}) {
        return this.http.get<UserResponse>(`${EawUrl.url}/users/${key}`, { params }).pipe(classifyItem(User));
    }

    /**
     * @deprecated
     * @see UserService
     */
    getAll(pagination: PaginationOptions): GetAll {
        const observable = this.http.get<ArrayPaginatedResponse<UserResponse>>(`${EawUrl.url}/users`, { params: PaginationOld.getParams(pagination) });

        return this.getPaginated(observable);
    }

    /**
     * @deprecated
     * @see UserService
     */
    getAllForCustomer(customer: number, params: CustomerParams, pagination: PaginationOptions): GetAll {
        const observable = this.http.get<ArrayPaginatedResponse<UserResponse>>(`${EawUrl.url}/customers/${customer}/users`, {
            params: Object.assign({}, params, PaginationOld.getParams(pagination)),
        });

        return this.getPaginated(observable);
    }

    /**
     * @deprecated
     * @see UserService
     */
    getAllForCustomerGroup(group: number, pagination: PaginationOptions, params?: { with_memberships?: boolean, include_inactive?: boolean }): GetAll {
        const observable = this.http.get<ArrayPaginatedResponse<UserResponse>>(`${EawUrl.url}/customer_groups/${group}/users`, {
            params: Object.assign({}, params, PaginationOld.getParams(pagination)),
        });

        return this.getPaginated(observable);
    }

    /**
     * @deprecated
     * @see UserService
     */
    protected getPaginated(observable: Observable<ArrayPaginatedResponse<UserResponse>>): GetAll {
        return observable.pipe(classifyArrayPaginatedResponse(User));
    }
}
