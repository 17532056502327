<eaw-page-header class="tw-mb-16" [fabButton]="headerFabButton">
    <span title>{{ 'TIMEPUNCH_BLOCK_plural' | translate:'company' | async }}</span>
</eaw-page-header>

@if (loading) {
    <eaw-info-loading size="md"></eaw-info-loading>
} @else {
    @for (item of timepunchBlocks; track item.block.id) {
        <mat-card class="block-card tw-flex tw-gap-16 tw-flex-col tw-py-8 tw-px-16 tw-mb-16">
            <div class="head">
                <div class="from-section tw-flex tw-flex-col">
                    <div>{{ 'FROM' | translate | async }}</div>
                    <div class="time-text mat-subtitle-1">{{ item.block.startDate.dateTime | DateTime:'DATE_FULL' }}</div>
                </div>

                <div class="to-section tw-flex tw-flex-col">
                    <div>{{ 'TO' | translate | async }}</div>

                    @if (item.block.endDate?.dateTime) {
                        <div class="time-text mat-subtitle-1">{{ item.block.endDate!.dateTime | DateTime:'DATE_FULL' }}</div>
                    } @else {
                        <div class="time-text mat-subtitle-1">{{ 'NO_END_DATE' | translate:'company' | async }}</div>
                    }
                </div>

                @if ('customers.#.employees.#.timepunch_blocks.#.update' | permission:[customerId, employeeId, item.block.id] | async) {
                    <button class="edit-button" mat-icon-button (click)="update(item.block)">
                        <mat-icon>edit</mat-icon>
                    </button>
                }

                @if ('customers.#.employees.#.timepunch_blocks.#.delete' | permission:[customerId, employeeId, item.block.id] | async) {
                    <button class="delete-button" mat-icon-button (click)="delete(item.block)" color="warn">
                        <mat-icon>delete</mat-icon>
                    </button>
                }
            </div>

            @if (item.block.reason) {
                <q class="reason" [lang]="language">{{ item.block.reason }}</q>
            } @else {
                <span class="reason">{{ 'NO_TP_BLOCK_MESSAGE' | translate:'company' | async }}</span>
            }

            <mat-divider class="tw-my-8"></mat-divider>

            <mat-slide-toggle [formControl]="item.hideReason">{{ 'TP_BLOCK_HIDE_MESSAGE' | translate:'company' | async}}</mat-slide-toggle>
        </mat-card>
    } @empty {
        <span>{{'NO_TP_BLOCKS' | translate:'company' | async}}</span>
    }
}
