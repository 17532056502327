// @ts-nocheck
import { module } from 'angular';

module('eaw.calendar2').component('eawOverviewCalendar', {
    template: `Her kommer overview

{{$ow.date | moment}}
`,
    controllerAs: '$ow',
    bindings: {
        date: '<',
    },
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.date = ctrl.date.clone();
        };

        ctrl.$onChanges = (changes) => {
            ctrl.date = changes?.date?.currentValue?.clone?.();
        };
    },
});
