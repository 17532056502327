import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../current.factory';
module('eaw.misc').filter('percent', function percentFilter() {
    return function(value: any, decimals = 0, sign: boolean) {
        const number = parseFloat(String(value));
        if (!Number.isFinite(number)) {
            console.warn('Not a finite number', number);
            return t('NA');
        }
        return new Intl.NumberFormat(CurrentOld.languageTag, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
            signDisplay: sign ? 'exceptZero' : 'auto',
            style: 'percent',
        }).format(number);
    };
});
