import { Component, Inject, OnInit, signal } from '@angular/core';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { EmployeeService } from '../../../shared/http/employee.service';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { MeCustomer } from '../../../shared/models/me';
import { CurrentService } from '../../../shared/services/current.service';
import { DateTime } from 'luxon';
import { PinCode } from '../../../shared/models/pin-code';
import { EMPTY, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { TranslateService } from '../../../shared/services/translate.service';
import { AlertDialogComponent, AlertDialogData } from '../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProfilePinCodeDialogComponent } from '../../dialogs/profile-pin-code-dialog/profile-pin-code-dialog.component';
import { DialogData } from '../../../shared/dialogs/dialog-component';
import { SettingService } from '../../../shared/http/setting.service';
import { Namespace } from '../../../shared/enums/namespace';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

interface ProfileCustomer {
    customer: MeCustomer;
    employed: boolean;
    employeeId?: number;
    pinEnabled?: boolean;
    description?: Promise<string>;
}

@Component({
    selector: 'eaw-profile-pin-code',
    templateUrl: './profile-pin-code.component.html',
    styleUrl: './profile-pin-code.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatProgressSpinnerModule,
        MatExpansionModule,
        NgFor,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ProfilePinCodeComponent implements OnInit {
    customers: ProfileCustomer[] = [];
    loading = true;

    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(SettingService) private settingService: SettingService,
    ) {
    }

    ngOnInit(): void {
        forkJoin(Object.values(this.current.getCustomers()).map((c) => this.getCustomer(c))).subscribe((customers) => {
            this.customers = customers.reduce((arr, customer) => {
                if (customer) {
                    arr.push(customer);
                }

                return arr;
            }, [] as ProfileCustomer[]);

            this.customers = sort(this.customers, this.current.languageTag, [ (c) => c.customer.name ]);
            this.customers.forEach(this.setDescription.bind(this));
            this.loading = false;
        });
    }

    setDescription(customer: ProfileCustomer) {
        if (!customer.employed) {
            customer.description = this.translate.t('NOT_EMPLOYED');
        }

        if (!customer.employeeId) {
            customer.description = this.translate.t('NO_EMP_ID', Namespace.Profile);
        }

        if (!customer.pinEnabled) {
            customer.description = this.translate.t('PIN_NOT_ENABLED', Namespace.Profile);
        }

        return customer;
    }

    getPinCode(customerId: number) {
        this.employeeService.getAll(customerId, {
            user_id: this.current.getUser().id,
            active: DateTime.now(),
            include_custom_fields: false,
            include_external: true,
        }).pipe(
            switchMap(async (employeeResponse) => {
                const employee = employeeResponse.data[0];

                if (!employee?.id) {
                    return EMPTY;
                }

                return this.employeeService.getPinCode(customerId, employee.id).subscribe((result: PinCode) => {
                    this.matDialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
                        data: {
                            title: signal(this.translate.t('PIN_CODE', 'profile')),
                            text: signal(Promise.resolve(result.decryptedCode)),
                        },
                    });
                });
            }),
        ).subscribe();
    }

    setPinCode(customerId: number, employeeId: number) {
        this.matDialog.open<ProfilePinCodeDialogComponent, DialogData, string>(ProfilePinCodeDialogComponent).afterClosed().subscribe((pinCode) => {
            if (!pinCode) {
                return;
            }

            this.savePinCode(customerId, employeeId, pinCode);
        });
    }

    getCustomer(customer: MeCustomer): Observable<ProfileCustomer | undefined> {
        if (!customer.employee_id) {
            return of({
                employed: false,
                customer,
            });
        }

        return this.employeeService.getAll(customer.id, {
            user_id: this.current.getUser().id,
            active: DateTime.now(),
            include_custom_fields: false,
            include_external: true,
        }).pipe(
            switchMap((employees) => {
                const employeeId = employees.data[0]?.id;
                if (!employeeId) {
                    return of({
                        employed: true,
                        customer,
                    });
                }

                return this.settingService.getValue<boolean>([ 'customers', customer.id ], 'pin_code.enabled', false).pipe(
                    map((enabled) => {
                        if (!enabled) {
                            return {
                                customer,
                                employed: true,
                                employeeId,
                            };
                        }

                        return {
                            customer,
                            employeeId,
                            employed: true,
                            pinEnabled: true,
                        };
                    }),
                );
            }),
        );
    }

    savePinCode(customerId: number, employeeId: number, pinCode: string) {
        if (pinCode && employeeId) {
            this.employeeService.setPinCode(customerId, employeeId, pinCode).subscribe((res) => {
                if (res) {
                    void this.snackBarService.t('PIN_CODE_WAS_CHANGED', 'profile');
                }
            });
        }
    }
}
