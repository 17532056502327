import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeResponse } from '../models/me';
import { Cache } from '../utils/cache';
import { CurrentService } from '../services/current.service';
import { AppService } from '../services/app.service';
import { Customer, CustomerResponse } from '../models/customer';
import { Employee, EmployeeResponse } from '../models/employee';
import { EmployeeTask, EmployeeTaskResponse, EmployeeTaskType } from '../models/employee-task';
import { from, map, Observable, of, switchMap, tap } from 'rxjs';
import { SignDocumentTask, SignDocumentTaskResponse } from '../../tasks/classes/sign-document-task';
import { UploadDocumentTask, UploadDocumentTaskResponse } from '../../tasks/classes/upload-document-task';
import { Storage } from '../utils/storage';
import { ApiToken, ApiTokenResponse } from '../models/api-token';

export interface CustomerMeResponse {
    customer: CustomerResponse;
    employee: EmployeeResponse | null;
    tasks: EmployeeTaskResponse[];
}

export interface CustomerMe {
    customer: Customer;
    employee: Employee | null;
    tasks: EmployeeTask[];
}

@Injectable({
    providedIn: 'root',
})
export class MeService {
    private http = inject(HttpClient);
    private current = inject(CurrentService);
    private appService = inject(AppService);

    async act(id: number, url?: string) {
        this.http.post('/me/act_as', { user_id: id }).subscribe(async () => {
            await Cache.clearAll();
            this.appService.reload(url);
        });
    }

    stopActing(url?: string) {
        return this.current.getMe().isActing ? this.act(this.current.getMe().authedAs, url) : Promise.reject('Not acting');
    }

    get(includePermission: boolean) {
        return this.http.get<MeResponse>('/me', {
            params: {
                include_permissions: includePermission,
            },
        }).pipe(map((response) => {
            // Remove these to make sure we don't invalidate the cache every 10 minutes
            delete response.user.last_active;
            delete response.user.updated_at;

            return response;
        }));
    }

    getCustomerMe(customerId: number) {
        return this.http.get<CustomerMeResponse>(`/customers/${customerId}/me`, {
            params: {
                simplified: true,
            },
        }).pipe(map((response): CustomerMe => {
            return {
                customer: new Customer(response.customer),
                employee: response.employee ? new Employee(response.employee) : null,
                tasks: response.tasks.map((task) => {
                    if (task.type === EmployeeTaskType.SignDocument) {
                        return new SignDocumentTask(task as SignDocumentTaskResponse);
                    }
                    if (task.type === EmployeeTaskType.UploadDocument) {
                        return new UploadDocumentTask(task as UploadDocumentTaskResponse);
                    }

                    return new EmployeeTask(task);
                }),
            };
        }));
    }

    getCalendarToken(): Observable<ApiToken> {
        return from(Storage.getItem<ApiTokenResponse>(Storage.prefix('calendar_token')))
            .pipe(
                switchMap((token) => {
                    if (token) {
                        const apiToken = new ApiToken(token);

                        if (!apiToken.isExpired()) {
                            return of(apiToken);
                        }
                    }

                    return this.http.post<ApiTokenResponse>('/me/token', {}).pipe(
                        tap((response) => void Storage.setItem(Storage.prefix('calendar_token'), response)),
                        map((response) => new ApiToken(response)),
                    );
                }),
            );
    }

    revokeCalendarToken() {
        return from(Storage.removeItem(Storage.prefix('calendar_token')))
            .pipe(switchMap(() => this.http.delete('/me/revoke_token', {})));
    }
}
