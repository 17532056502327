import { BaseApiModel } from '../../shared/models/base-api-model';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { DateTime } from 'luxon';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { Customer, CustomerResponse } from '../../shared/models/customer';

enum JOB_STATUS {
    COMPLETE = 'complete',
    FAILED = 'failed',
}

interface Job extends ApiResponse {
    customer_transfer_id: number;
    created_at?: string | null;
    deleted_at?: string | null;
    updated_at: string;
    executed_at: null;
    id: number;
    job_type: string;
    log_output: string;
    status: JOB_STATUS;
}

export interface CustomerTransferResponse extends ApiResponse {
    id: number;
    created_at?: string | null;
    deleted_at?: string | null;
    updated_at?: string | null;
    jobs: Job[];
    effective_date: string | null;
    lastUpdated: string | null;
    original_customer_id: number;
    new_customer: CustomerResponse;
    original_customer: CustomerResponse;
}

export class CustomerTransfer extends BaseApiModel<CustomerTransferResponse, CustomerTransfer> {
    id: number;
    createdAt?: DateTime | null;
    deletedAt?: DateTime | null;
    updatedAt?: DateTime | null;
    jobs: Job[];
    effectiveDate?: DateTime | null;
    lastUpdated?: DateTime | null;
    originalCustomerId: number;
    failed: number | undefined;
    status: string;
    newCustomer: Customer | undefined;
    originalCustomer: Customer | undefined;

    constructor(data: CustomerTransferResponse) {
        super(data, undefined);
        this.id = data.id;
        this.jobs = data.jobs;
        this.status = `${data.jobs?.reduce((amount, job) => job.status === JOB_STATUS.COMPLETE ? 1 + amount : 0, 0)}/${data.jobs.length}`;
        this.failed = data.jobs?.reduce((amount, job) => job.status === JOB_STATUS.FAILED ? 1 + amount : 0, 0);
        this.originalCustomerId = data.original_customer_id;
        this.newCustomer = data.new_customer ? new Customer(data.new_customer) : undefined;
        this.originalCustomer = data.original_customer ? new Customer(data.original_customer) : undefined;
        this.effectiveDate = data.effective_date ? stringToDateTime(data.effective_date) : null;
        const lastUpdated = data.jobs.sort(((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()))[0]?.updated_at;
        this.lastUpdated = lastUpdated ? stringToDateTime(lastUpdated) : null;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
