import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import type { DataTableCell } from '../../interfaces/data-table-cell';
import { DataTablePortalComponent } from '../../types/data-table-portal-component';
import type { DataTableButton, DataTableButtonColumn } from '../../types/data-table-button-column';
import { combineLatest, Observable, of, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';
import { PermissionsInputValue } from '../../../permissions/services/element-permission.service';

@Component({
    selector: 'eaw-buttons-portal',
    templateUrl: './buttons-portal.component.html',
    styleUrl: './buttons-portal.component.scss',
    standalone: true,
    imports: [
        NgFor,
        MatButtonModule,
        MaterialColorDirective,
        MatTooltipModule,
        NgClass,
        NgIf,
        MatIconModule,
        AsyncPipe,
        TranslatePipe,
        PermissionDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonsPortalComponent<Item> extends DataTablePortalComponent<Item, any, DataTableButtonColumn<Item>> implements OnInit {
    protected readonly destroyRef = inject(DestroyRef);

    protected hasCheckedHiding = signal(false);
    protected isDisabled = computed(() => this.cell.disabled() || false);

    hideMap: Record<number, WritableSignal<boolean>> = {};
    iconMap: Record<number, WritableSignal<string>> = {};
    permissions: Map<number, WritableSignal<PermissionsInputValue | undefined>> = new Map();

    constructor() {
        super();

        let i = 0;
        for (const button of this.cell.column.buttons) {
            this.permissions.set(i++, signal(button.permissions?.(this.cell)));
        }
    }

    ngOnInit() {
        this.createHideMap();
        this.createIcons();
    }

    createIcons() {
        this.iconMap = this.cell.column.buttons.reduce((acc, button) => {
            acc[button.id || 0] = signal(typeof button.icon === 'function' ? button.icon(this.cell) : button.icon);
            return acc;
        }, {} as Record<number, WritableSignal<string>>);
    }

    createHideMap() {
        // Hide every button initially
        this.hideMap = this.cell.column.buttons.reduce((acc, button) => {
            acc[button.id || 0] = signal(true);
            return acc;
        }, {} as Record<number, WritableSignal<boolean>>);

        const observables = this.cell.column.buttons.reduce((acc, button) => {
            const permissionObservable = button.show(this.cell.item);
            const hideObservable = button.hide?.(this.cell.item) || of(false);
            const observable = combineLatest([ permissionObservable, hideObservable ]).pipe(
                tap(([ hasPermission, hide ]) => {
                    this.hideMap[button.id || 0]?.set(!hasPermission || hide);
                }),
                takeUntilDestroyed(this.destroyRef),
            );

            return acc.concat(observable);
        }, [] as Observable<[boolean, boolean]>[]);

        combineLatest(observables).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.hasCheckedHiding.update((checked) => checked || true);
        });
    }

    click(button: DataTableButton<any>, cell: DataTableCell<DataTableButtonColumn<any>, any>, event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.hasCheckedHiding()) {
            return;
        }

        if (!button.nonBlocking) {
            cell.disabled.set(true);
        }

        button.click(cell, event);
    }
}
