import { AfterViewInit, Component, effect, ElementRef, HostBinding, inject, input, OnChanges, SimpleChanges } from '@angular/core';
import { Employee } from '../../../../../shared/models/employee';
import { DateTime } from 'luxon';
import { PaidTimeOverview } from '../../../../models/paid-time-overview';
import { PaidTimeSegmentTypeKey } from '../../../../types/paid-time-segment-types';
import { PaidTimeService } from '../../../../../payroll/http/paid-time.service';
import { PaidTimeSegment } from '../../../../models/paid-time-segment';
import { MatRippleModule } from '@angular/material/core';
import { PaidTimeOverviewSegmentComponent } from '../paid-time-overview-segment/paid-time-overview-segment.component';
import { NgFor, NgIf } from '@angular/common';
import { PaidTimeOverviewEmployeeComponent } from '../paid-time-overview-employee/paid-time-overview-employee.component';

interface DayAbsenceSegment {
    columnStart: number;
    columnEnd: number;
    segment: PaidTimeSegment;
}

interface PaidTimeEmployeeDateOverview {
    date: DateTime;
    overview: PaidTimeOverview;
    segments: PaidTimeSegment[];
    filteredSegments: PaidTimeSegment[];
    column: number;
}

@Component({
    selector: 'eaw-paid-time-overview-employee-row',
    templateUrl: './paid-time-overview-employee-row.component.html',
    styleUrl: './paid-time-overview-employee-row.component.scss',
    standalone: true,
    imports: [
        PaidTimeOverviewEmployeeComponent,
        NgFor,
        PaidTimeOverviewSegmentComponent,
        MatRippleModule,
        NgIf,
    ],
})
export class PaidTimeOverviewEmployeeRowComponent implements AfterViewInit, OnChanges {
    private readonly elementRef = inject(ElementRef);
    private readonly paidTimeService = inject(PaidTimeService);

    @HostBinding('attr.data-visible-segments')
    get visibleSegments(): number {
        return this.dateOverviews.reduce((sum, o) => sum + o.filteredSegments.length, 0);
    }

    @HostBinding('attr.data-max-day-segments')
    get maxDaySegments(): number {
        return Math.max(0, ...this.dateOverviews.map((o) => o.filteredSegments.length));
    }

    employee = input.required<Employee>();
    dates = input.required<DateTime[]>();
    overview = input.required<PaidTimeOverview>();
    types = input<PaidTimeSegmentTypeKey[] | null>();

    employeeOverview?: PaidTimeOverview;
    dateOverviews: PaidTimeEmployeeDateOverview[] = [];
    dayAbsences: DayAbsenceSegment[] = [];
    vacations: DayAbsenceSegment[] = [];

    constructor() {
        effect(() => this.elementRef.nativeElement.style.setProperty('--grid-template-columns', this.dates().length));
    }

    createOverview(overview?: PaidTimeOverview) {
        const employeeOverview = (overview || this.overview()).filter({ employeeId: this.employee().id });

        this.dayAbsences = employeeOverview.getDayAbsenceSegments().map((da) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const fromIndex = this.dates().findIndex((d) => d.hasSame(da.absence!.from, 'day'));
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const toIndex = this.dates().findIndex((d) => d.hasSame(da.absence!.to, 'day'));

            return {
                segment: da,
                columnStart: Math.max(2, fromIndex + 2),
                columnEnd: toIndex === -1 ? -1 : toIndex + 3,
            };
        });

        this.vacations = employeeOverview.getVacationSegments().map((da) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const fromIndex = this.dates().findIndex((d) => d.hasSame(da.offTime!.from, 'day'));
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const toIndex = this.dates().findIndex((d) => d.hasSame(da.offTime!.to, 'day'));

            return {
                segment: da,
                columnStart: Math.max(2, fromIndex + 2),
                columnEnd: toIndex === -1 ? -1 : toIndex + 3,
            };
        });

        this.employeeOverview = employeeOverview;
        this.dateOverviews = employeeOverview ? this.dates().map((date, index) => {
            const dateOverview = employeeOverview.filter({ from: date.startOf('day'), to: date.endOf('day') });
            const segments = dateOverview.getSegments();

            return {
                date,
                overview: dateOverview,
                segments,
                filteredSegments: segments,
                column: index + 2, // First column is the employee name, and plus one cause row uses 1-based index
            };
        }) : [];

        this.filterSegments();
        this.setRows(); // Has to be after filter
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['overview']) {
            this.createOverview(changes['overview'].currentValue);
        }

        if (changes['types']) {
            this.filterSegments();
        }
    }

    ngAfterViewInit() {
        const observer = new IntersectionObserver(([ entry ]) => {
            if (!entry?.isIntersecting) {
                return;
            }

            observer.disconnect();
            this.createOverview();
        });

        observer.observe(this.elementRef.nativeElement);
    }

    // Set the rows to the maximum number of segments on a day
    setRows() {
        const maxSegments = Math.max(...this.dateOverviews.map((o) => o.filteredSegments.length));
        this.elementRef.nativeElement.style.setProperty('--grid-template-rows', maxSegments);
    }

    filterSegments() {
        this.dateOverviews.forEach((o) => {
            o.filteredSegments = o.segments.filter((segment) => {
                return this.types()?.includes(segment.type.key) ?? true;
            });
        });
    }

    updateEmployee() {
        const dates = this.dates();
        const from = dates[0];
        const to = dates[dates.length - 1];

        if (from && to) {
            this.paidTimeService.getOverview(this.overview().customerId, from, to, this.employee().id).subscribe((overview) => {
                this.createOverview(overview);
            });
        }
    }
}
