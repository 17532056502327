<mat-card>
    <mat-card-content>
        <eaw-password [formControl]="passwordCtrl"></eaw-password>

        <div class="tw-flex tw-justify-between">
            <button (click)="updatePassword()" [disabled]="updating || !validPassword" mat-raised-button color="primary">{{'UPDATE' | translate | async}}</button>
            <button (click)="resetPassword()" [disabled]="resetSent" mat-raised-button color="primary">{{'RESET_PASSWORD' | translate | async}}</button>
        </div>
    </mat-card-content>
</mat-card>
