// @ts-nocheck
import { module } from 'angular';

module('eaw').directive('stopPropagate', () => {
    return {
        restrict: 'A',
        link(scope, element) {
            element[0].addEventListener('click', (e) => {
                e.stopPropagation();
            });
        },
    };
});
