import { Component, Inject, Input } from '@angular/core';
import { RoleService } from '../../http/role.service';
import type { Observable } from 'rxjs';
import type { ArrayPaginatedResponse } from '../../../../shared/interfaces/paginated-response';
import type { Role } from '../../types/role';
import { of } from 'rxjs';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { AutocompleteDeprecatedComponent } from '../../../../shared/components/autocomplete-deprecated/autocomplete-deprecated.component';

@Component({
    selector: 'eaw-role-autocomplete',
    templateUrl: './role-autocomplete.component.html',
    styleUrl: './role-autocomplete.component.scss',
    standalone: true,
    imports: [ AutocompleteDeprecatedComponent, TranslatePipe ],
})
export class RoleAutocompleteComponent {
    @Input() customerId!: number;
    @Input() controlName!: string;
    @Input() label?: Promise<string>;

    changeObservable = (value: unknown) => {
        return Number.isInteger(value) ? this.roleService.get(this.customerId, value as number) : of(value);
    };

    observable?: Observable<ArrayPaginatedResponse<Role>>;
    limit = 10;

    constructor(
        @Inject(RoleService) private roleService: RoleService,
    ) {
    }

    updateRoles(filter?: string) {
        this.observable = this.roleService.getAll(this.customerId, {
            pagination: {
                per_page: this.limit,
                page: 1,
                filter,
            },
        });
    }
}
