import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { HourDistributionService } from '../../http/hour-distribution.service';
import { EmployeeService } from '../../../shared/http/employee.service';
import { catchError, forkJoin, Observable, of } from 'rxjs';
import { Employee } from '../../../shared/models/employee';
import { HourDistribution } from '../../models/hour-distribution';
import { Info, Settings } from 'luxon';
import { ContractHourDay } from '../../models/contract-hour-day';
import { SettingService } from '../../../shared/http/setting.service';
import { chunk, clamp } from 'lodash-es';
import { ContractHourDayService } from '../../http/contract-hour-day.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

type WeekStart = 'mon' | 'sun';

export interface HourDistributionDaysDialogData extends DialogData {
    customerId: number;
    employeeId?: number;
    distributionId?: number;
    /**
     * Distribution has a higher priority than distributionId
     */
    distribution?: Observable<HourDistribution>;
    contractMonthHours?: Observable<number | undefined>;
    /**
     * When **false** the days will be updated on the fly in the dialog.
     *
     * When **true** the days will be returned in the result of the dialog.
     */
    noDialogUpdate?: boolean;
    // Don't allow the user to change the values
    readonly?: boolean;
}

export interface HourDistributionDaysDialogResult {
    days: {index: number, hours: number}[];
}

interface HoursDay {
    processing?: boolean;
    contractHourDay?: ContractHourDay;
    value?: number;
    index: number;
}

interface HoursWeek {
    index: number;
    sum: number;
    processing?: boolean;
    days: HoursDay[];
}

const HOUR_DISTRIBUTION_TOTAL_SUM_CORRECTION_FACTOR = 433/400; // Apparently to make the sum "more representative"? See EASY-4772

@Component({
    selector: 'eaw-hour-distribution-days',
    templateUrl: './hour-distribution-days.component.html',
    styleUrl: './hour-distribution-days.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        NgFor,
        ReactiveFormsModule,
        FormsModule,
        NgTemplateOutlet,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        NumberPipe,
    ],
})
export class HourDistributionDaysComponent extends DialogComponent implements OnInit {
    protected loading = true;
    protected canUpdate = false;
    protected minHours = 0;
    protected maxHours = 24;
    protected totalSum = 0;
    protected useContractMonthHours = false;
    protected employee?: Employee;
    protected hourDistribution?: HourDistribution;
    protected contractMonthHours?: number;
    protected readonly weekdays = Info.weekdays('long', { locale: Settings.defaultLocale });
    protected weeks: HoursWeek[] = [];

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<HourDistributionDaysComponent, HourDistributionDaysDialogResult>,
        @Inject(MAT_DIALOG_DATA) override data: HourDistributionDaysDialogData,
        @Inject(HourDistributionService) private hourDistributionService: HourDistributionService,
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(SettingService) private settingService: SettingService,
        @Inject(ContractHourDayService) private contractHourDayService: ContractHourDayService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
    ) {
        data.size = DialogSize.Large;
        dialogRef.disableClose = true;

        super(dialogRef, data);

        this.useContractMonthHours = !data.contractMonthHours;
    }

    ngOnInit() {
        let distributionObservable: Observable<HourDistribution>;
        if (this.data.distribution) {
            distributionObservable = this.data.distribution;
        } else if (this.data.employeeId && this.data.distributionId) {
            distributionObservable = this.hourDistributionService.get(this.data.customerId, this.data.employeeId, this.data.distributionId, [ 'contract_hours_days' ]);
        } else {
            distributionObservable = of(new HourDistribution({
                id: 0,
                days: 28,
                employee_id: 0,
                from: '',
                to: null,
                created_at: '',
                updated_at: '',
                deleted_at: null,
            }));
        }

        forkJoin([
            this.permissionCheckService.isAllowed(`customers.${this.data.customerId}.employees.${this.data.employeeId}.contract_hours_distributions.${this.data.distributionId}.days.*.update`),
            this.data.employeeId ? this.employeeService.get(this.data.customerId, this.data.employeeId, { 'with[]': [ 'customer', 'user' ] }) : of(undefined),
            distributionObservable,
            this.data.contractMonthHours ? this.data.contractMonthHours : of(undefined),
            this.settingService.getSome([ 'customers', this.data.customerId ], { 'settings[]': [ 'scheduling.week_start' ] }),
        ]).subscribe(([ canUpdate, employee, distribution, monthHours, settings ]) => {
            this.loading = false;
            this.canUpdate = canUpdate;
            this.employee = employee;
            this.hourDistribution = distribution;
            this.contractMonthHours = monthHours;

            // Change order of weekdays if week starts on sunday
            const firstDay: WeekStart = 'mon';
            const weekStart = settings.find((setting) => setting.key === 'scheduling.week_start')?.value?.asString<WeekStart>() || firstDay;
            if (weekStart === 'sun') {
                const sunday = this.weekdays.pop();
                if (sunday) {
                    this.weekdays.unshift(sunday);
                } else {
                    throw new Error('Could not set sunday as week start');
                }
            }

            // Find the hours for the corresponding day
            const allDays: HoursDay[] = [];
            for (let i = 0; i < distribution.days; i++) {
                const contractHourDay = distribution.contractHoursDays?.find((day) => day.index === i);

                allDays.push({
                    contractHourDay,
                    value: this.nearestHundredth(contractHourDay?.hours || 0),
                    index: i,
                });
            }

            // Chunk days into weeks
            chunk(allDays, 7).forEach((chunkedDays, index) => {
                this.weeks.push({
                    index,
                    sum: 0,
                    days: chunkedDays,
                });
            });

            // Update sums
            this.weeks.forEach(this.updateSum.bind(this));
            this.weeks.forEach(this.updateTotalSum.bind(this));
        });
    }

    protected submitResult() {
        this.dialogRef.close({
            days: this.weeks.map((week) => week.days.map((day) => ({ index: day.index, hours: day.value || 0 }))).flat(),
        });
    }

    private nearestHundredth(value: number) {
        return Math.round(value * 100) / 100;
    }

    private updateTotalSum() {
        this.totalSum = this.nearestHundredth(this.weeks.reduce((sum, week) => sum + week.sum, 0) * HOUR_DISTRIBUTION_TOTAL_SUM_CORRECTION_FACTOR);
    }

    private updateSum(week: HoursWeek) {
        week.sum = week.days.reduce((sum, day) => sum + (day.value || 0), 0);
    }

    private replaceDays(day: HoursDay, week: HoursWeek) {
        const days = week.days.filter((d) => d.index !== day.index);
        days.push(day);
        days.sort((a, b) => a.index - b.index);
        week.days = days;

        this.updateSum(week);
        this.updateTotalSum();
    }

    protected update(day: HoursDay, week: HoursWeek) {
        if (!this.canUpdate) {
            return;
        }

        const previousValue = day.value;
        const value = this.nearestHundredth(clamp(day.value || 0, this.minHours, this.maxHours));

        if (this.data.noDialogUpdate) {
            day.value = value;
            this.replaceDays(day, week);
            return;
        }

        if (!(this.data.employeeId && this.data.distributionId)) {
            return;
        }

        day.processing = true;
        week.processing = week.days.some((d) => d.processing);

        let observable: Observable<ContractHourDay>;
        if (day.contractHourDay) {
            observable = this.contractHourDayService.update(this.data.customerId, this.data.employeeId, this.data.distributionId, day.contractHourDay.id, value);
        } else {
            observable = this.contractHourDayService.create(this.data.customerId, this.data.employeeId, this.data.distributionId, value, day.index);
        }

        observable.pipe(catchError(() => of(null))).subscribe((contractHourDay) => {
            day.processing = false;
            week.processing = week.days.some((d) => d.processing);
            day.contractHourDay = contractHourDay ? contractHourDay : day.contractHourDay;
            day.value = contractHourDay ? contractHourDay.hours : previousValue;

            this.replaceDays(day, week);
        });
    }
}
