<eaw-dialog-header>
    <span title>{{'ADD_ATTACHMENT' | translate | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <eaw-attachment-list (filesChange)="filesChange($event)"></eaw-attachment-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="">{{'CANCEL' | translate | async}}</button>
    <button mat-raised-button color="accent" [disabled]="disabled || !files.length" (click)="add()">{{'ADD' | translate | async}}</button>
</mat-dialog-actions>
