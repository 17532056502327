import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { EmergencyContactService } from '../../http/emergency-contact.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { Namespace } from '../../../shared/enums/namespace';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { catchError, EMPTY, forkJoin, tap } from 'rxjs';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { EmergencyContact } from '../../models/emergency-contact';
import { CustomFieldsGroup } from '../../../shared/utils/custom-fields-group';
import { ApiModel } from '../../../shared/enums/api-model';
import { CustomerCustomFieldService } from '../../../shared/http/customer-custom-field.service';
import { ModelCustomField } from '../../../custom-fields/models/model-custom-field';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { CustomFieldInputComponent } from '../../../custom-fields/components/custom-field-input/custom-field-input.component';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

@Component({
    selector: 'eaw-my-emergency-contact',
    templateUrl: './my-emergency-contact.component.html',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgFor,
        CustomFieldInputComponent,
        MatButtonModule,
        NgIf,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class MyEmergencyContactComponent implements OnInit {
    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) employeeId!: number;

    existing = false;
    canCreate = false;
    canUpdate = false;
    canDelete = false;
    processing = false;
    customFields: ModelCustomField[] = [];
    form = new FormGroup({
        name: new FormControl('', [ Validators.required, Validators.maxLength(64) ]),
        phone: new FormControl('', [ Validators.required, Validators.maxLength(64) ]),
        relation: new FormControl('', [ Validators.maxLength(32) ]),
        customFields: new CustomFieldsGroup(),
    });

    constructor(
        @Inject(EmergencyContactService) private emergencyContactService: EmergencyContactService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(CustomerCustomFieldService) private customerCustomFieldService: CustomerCustomFieldService,
    ) {
    }

    ngOnInit() {
        this.getContact();
    }

    getContact() {
        this.processing = true;
        this.form.disable();

        forkJoin([
            this.emergencyContactService.get(this.customerId, this.employeeId),
            this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.emergency_contact.create`).pipe(tap((canCreate) => (this.canCreate = canCreate))),
            this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.emergency_contact.update`).pipe(tap((canUpdate) => (this.canUpdate = canUpdate))),
            this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.emergency_contact.delete`).pipe(tap((canDelete) => (this.canDelete = canDelete))),
        ]).subscribe(([ contact ]) => {
            this.existing = !!contact;
            this.resetForm(contact);
        });
    }

    resetForm(contact: EmergencyContact | null) {
        this.existing = !!contact;

        this.form.patchValue({
            name: contact?.name,
            phone: contact?.phone,
            relation: contact?.relation,
        });

        this.customerCustomFieldService.getForModel(this.customerId, ApiModel.EmergencyContact, contact).subscribe((customFields) => {
            this.form.markAsPristine();
            this.form.markAsUntouched();
            this.customFields = customFields;
            this.form.enable();
            this.processing = false;
        });
    }

    submit() {
        const { name, phone, relation } = this.form.value;
        if (!name || !phone) {
            return;
        }

        const customFields = this.form.controls.customFields.getValues();
        const observable = this.existing ?
            this.emergencyContactService.update(this.customerId, this.employeeId, name, phone, relation, customFields) :
            this.emergencyContactService.create(this.customerId, this.employeeId, name, phone, relation, customFields);

        this.processing = true;
        this.form.disable();
        observable.pipe(
            catchError(this.onError.bind(this)),
        ).subscribe((contact) => {
            this.resetForm(contact);
            void this.snackbar.t('EMERGENCY_CONTACT_UPDATED', 'company');
        });
    }

    delete() {
        this.confirmDialogService.delete({
            title: this.translate.t('DELETE_EMERGENCY_CONTACT', Namespace.Company),
            text: this.translate.t('DELETE_EMERGENCY_CONTACT_TEXT', Namespace.Company, { name: this.form.value.name }),
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.processing = true;
            this.emergencyContactService.delete(this.customerId, this.employeeId).pipe(
                catchError(this.onError.bind(this)),
            ).subscribe(() => {
                void this.snackbar.t('EMERGENCY_CONTACT_DELETED', 'company');
                this.resetForm(null);
            });
        });
    }

    onError(error: any) {
        console.error(`error`, error);
        this.processing = false;
        this.form.enable();
        return EMPTY;
    }
}
