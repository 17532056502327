import { ChangeDetectionStrategy, Component, effect, EnvironmentInjector, inject, Input, OnInit, runInInjectionContext, signal, WritableSignal } from '@angular/core';
import { Transition } from '@uirouter/angularjs';
import { StateObject, UIRouter } from '@uirouter/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatIconSizeDirective } from '../../directives/mat-icon-size.directive';
import { MatIconModule } from '@angular/material/icon';
import { from, Observable, of } from 'rxjs';
import { StateProviderDataData } from '../../utils/create-state';
import { TranslateService } from '../../services/translate.service';

interface BreadcrumbItem {
    state: StateObject;
    observable: Observable<string>;
    label: WritableSignal<string>;
    loading: WritableSignal<boolean>;
    href: string;
    fresh?: true;
}

@Component({
    selector: 'eaw-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrl: './breadcrumb.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatIconModule,
        MatIconSizeDirective,
        NgFor,
        NgIf,
        AsyncPipe,
    ],
})
export class BreadcrumbComponent implements OnInit {
    private readonly uiRouter = inject(UIRouter);
    private readonly translate = inject(TranslateService);
    private readonly environmentInjector = inject(EnvironmentInjector);

    @Input() homeRoute;

    /**
     * Map of labels for breadcrumbs
     */
    private labels = new Map<string, string>();
    breadcrumbs = signal([] as BreadcrumbItem[]);

    constructor() {
        this.homeRoute = this.uiRouter.stateService.href('eaw/app/home');

        effect(() => (document.title = `${this.breadcrumbs().slice(-2).map((c) => c.label()).join(' - ')} | easy@work`));
    }

    ngOnInit(): void {
        void this.createPath(this.uiRouter.globals.transitionHistory.peekHead());
        this.uiRouter.transitionService.onSuccess({}, (transition) => this.createPath(transition));
    }

    buildTree(tree: StateObject[], state: StateObject) {
        if (state.parent) {
            tree.push(state.parent);
            this.buildTree(tree, state.parent);
        }

        return tree;
    }

    private getLabelKey(state: StateObject, tree: StateObject[], params: Record<string, any>) {
        const requiredParams = tree.reduce((acc, state) => {
            Object.values(state.params).forEach((param) => {
                if (!param.isOptional) {
                    acc.add(param.id);
                }
            });

            return acc;
        }, new Set<string>());

        const stringifiedParams = Array.from(requiredParams).reduce((acc, param) => {
            acc[param] = params[param];
            return acc;
        }, {} as Record<string, any>);

        return `${state.name}-${JSON.stringify(stringifiedParams)}`;
    }

    async createPath(transition: Transition) {
        const tree = this.buildTree([ transition.$to() ], transition.$to());
        const params = transition.params();

        const crumbs = tree.reduce((acc, state) => {
            const data = state.data as undefined | StateProviderDataData;
            const href = this.uiRouter.stateService.href(state.name, params);
            const existingLabel = this.labels.get(this.getLabelKey(state, tree, params));
            const existingLabelObservable = existingLabel ? of(existingLabel) : undefined;

            if (data?.breadcrumb == null) {
                return acc;
            }

            if (data.breadcrumb) {
                runInInjectionContext(this.environmentInjector, () => {
                    const item: BreadcrumbItem = {
                        href,
                        state,
                        observable: existingLabelObservable || (typeof data.breadcrumb === 'function' ? data.breadcrumb(transition) : from(this.translate.t(data.breadcrumb?.key, data.breadcrumb?.ns))),
                        loading: signal(true),
                        label: signal(''),
                    };

                    acc.unshift(item);
                });
            }

            return acc;
        }, [] as BreadcrumbItem[]);

        for (const crumb of crumbs) {
            crumb.observable.subscribe((label) => {
                crumb.loading.set(false);
                crumb.label.set(label);

                this.labels.set(this.getLabelKey(crumb.state, tree, params), label);
            });
        }

        this.breadcrumbs.set(crumbs);
    }
}
