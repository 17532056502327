import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Injectable({
    providedIn: 'root',
})
export class SplashHookService {
    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
        // Hide the splash screen when the first transition finishes
        this.uiRouter.transitionService.onSuccess({}, (transition) => {
            console.debug('🪝', 'SplashHookService.onSuccess', transition);

            document.getElementById('splash')?.remove();
        }, { invokeLimit: 1 });
    }
}
