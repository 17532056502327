import { Component, Inject, Input, signal, ViewChild } from '@angular/core';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { TranslateService } from '../../../shared/services/translate.service';
import { debounceTime, distinctUntilChanged, of } from 'rxjs';
import { DataTablePagination, DataTableRequest } from '../../../data-table/types/data-table';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { ExternalEmployee } from '../../models/external-employee';
import { ExternalEmployeeService } from '../../../shared/http/external-employee.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-loan-employees-list',
    templateUrl: './loan-employees-list.component.html',
    styleUrl: './loan-employees-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class LoanEmployeesListComponent {
    @ViewChild('table') table?: DataTableComponent<ExternalEmployee>;

    @Input({ required: true }) customerId!: number;

    filterToggled = true;
    includeInactive = false;
    headerButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            icon: () => this.filterToggled ? 'filter_alt' : 'filter_alt_off',
            active: () => this.filterToggled,
            click: () => this.filterToggled = !this.filterToggled,
            menuText: signal(this.translateService.t('FILTER')),
        }),
        new PageHeaderButton({
            icon: 'history',
            active: () => this.includeInactive,
            click: () => {
                this.includeInactive = !this.includeInactive;
                this.table?.refresh();
            },
            menuText: signal(this.translateService.t('INCLUDE_INACTIVE')),
        }),
    ];

    filter = new FormControl<string | null>('');
    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<ExternalEmployee>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item.employee?.name || '',
            header: new DataTableHeader({ i18n: 'EMPLOYEE', sortBy: 'employee_id' }),
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.customer?.name || '',
            header: new DataTableHeader({ i18n: 'EXTERNAL_AT', ns: 'company', sortBy: 'customer_id' }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            header: new DataTableHeader({ i18n: 'FROM', sortBy: 'from' }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            header: new DataTableHeader({ i18n: 'TO', sortBy: 'to' }),
        }),
    ];

    constructor(
        @Inject(ExternalEmployeeService) private externalEmployeeService: ExternalEmployeeService,
        @Inject(TranslateService) private translateService: TranslateService,
    ) {
        this.filter.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
        ).subscribe(() => {
            this.table?.refresh();
        });
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.externalEmployeeService.getLoansForCustomer(this.customerId, {
            ...pagination,
            filter: this.filter.value || undefined,
            'with[]': [ 'employee', 'customer' ],
            include_inactive: this.includeInactive,
        });
    }

    rowClasses(row: ExternalEmployee) {
        const classes: string[] = [];
        if (!row.isActive) {
            classes.push('inactive');
        }
        return classes;
    }
}
