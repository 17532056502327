// @ts-nocheck
import { loadNamespaces, t } from 'i18next';
import { module } from 'angular';
import CustomerOld from '../../../shared/angularjs/customer-old';
import { Products } from '../../../shared/enums/products';
import { Namespace } from '../../../shared/enums/namespace';
import { createState } from '../../../shared/utils/create-state';
import { dependencyResolver } from '../../../shared/resolvers/dependency.resolver';
import { CustomFieldOld } from '../../../shared/angularjs/custom-field-old';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.chain-ops.absences', [
    'eaw.absence',
    'eaw.vacation',
]).config([ '$stateProvider', ($stateProvider: any) => {
    getCustomer.$inject = [ 'CustomerService', 'ProductService', 'user', '$transition$', 'CustomerProductServiceDowngrade' ];
    $stateProvider.state(`eaw/app/chain_ops/absences`, {
        parent: `eaw/app/chain_ops`,
        url: '/absences?from?to?mode',
        views: {
            'content@': 'chainTabs',
        },
        data: {
            i18nextNs: [
                Namespace.AbsenceTypes,
                Namespace.Vacation,
                Namespace.Company,
                Namespace.Absences,
            ],
        },
        params: {
            from: {
                squash: true,
                dynamic: true,
                type: 'date',
            },
            to: {
                squash: true,
                dynamic: true,
                type: 'date',
            },
            mode: {
                squash: true,
                dynamic: true,
                type: 'string',
            },
        },
        resolve: {
            sref: function() {
                return `eaw/app/chain_ops/absences/tabs`;
            },
            useCard: function() {
                return true;
            },
            customerGroups: [ 'customerCustomerGroupFactory', 'customer', 'ToastService', function(customerCustomerGroupFactory, customer, ToastService) {
                return customerCustomerGroupFactory.getAll.query(customer.id, { with: [ 'activeMemberships.member' ] }).$promise.then((resp) => {
                    if (resp.data.length) {
                        return resp.data;
                    }

                    void ToastService.t('NO_CUSTOMER_plural', Namespace.ChainOps);
                    return Promise.reject();
                });
            } ],
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/absences/tabs`, {
        parent: `eaw/app/chain_ops/absences`,
        url: '/:group',
        params: {
            group: {
                type: 'int',
            },
        },
        data: {
            i18nextNs: [ Namespace.Leaveshifts, Namespace.Absences ],
        },
        views: {
            chainTabsView: 'eawNavBar',
        },
        resolve: {
            /** @ngInject */
            customerGroup: [ 'EawResource', '$transition$', function(EawResource, $transition$) {
                const group = $transition$.params().group;

                return EawResource.create(`/customers/${CurrentOld.getCustomer().id}/customer_groups`).get({
                    'with[]': [ 'activeMemberships.member.settingGroup' ],
                }).$promise.then((resp) => {
                    return resp.data.find((g) => g.id === group);
                });
            } ],
            settingGroupIds: [ 'customerGroup', function(customerGroup) {
                const ids = new Set<number>();
                customerGroup.active_memberships.forEach((m) => {
                    const id = m.member?.setting_group_id;
                    if (id) {
                        ids.add(id);
                    }
                });
                return Array.from(ids);
            } ],
            navbarRoute: function() {
                return 'eaw/app/chain_ops/absences/tabs';
            },
            viewName: function() {
                return 'tabPane';
            },
            tabs: function() {
                return [
                    {
                        label: t('ABSENCE_plural'),
                        src: 'eaw/app/chain_ops/absences/tabs/absences',
                    },
                    {
                        label: t('OFFTIME_plural'),
                        src: 'eaw/app/chain_ops/absences/tabs/vacations',
                    },
                ];
            },
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/absences/tabs/vacations`, {
        parent: `eaw/app/chain_ops/absences/tabs`,
        url: '/vacations',
        data: {
            breadcrumb: { key: 'OFFTIME_plural' },
        },
        views: {
            tabPane: 'chainOffTime',
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/absences/tabs/absences`, {
        parent: `eaw/app/chain_ops/absences/tabs`,
        url: '/absences?types',
        data: {
            breadcrumb: { key: 'ABSENCE_plural' },
        },
        params: {
            types: {
                squash: true,
                dynamic: true,
                value: [],
            },
        },
        views: {
            tabPane: 'chainAbsences',
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/absences/tabs/absences/tabs`, {
        parent: `eaw/app/chain_ops/absences/tabs/absences`,
        url: '/:c/:e/:absence',
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            i18nextNs: [ Namespace.Scheduling, Namespace.Calendar, Namespace.Availabilities, Namespace.Staffing, Namespace.Autosched, Namespace.Company ],
            breadcrumb: { key: 'EDIT_ABSENCE', ns: 'absences' },
        },
        params: {
            c: {
                // customer id
                type: 'int',
            },
            e: {
                // employee id
                type: 'int',
            },
            absence: {
                type: 'int',
            },
        },
        resolve: {
            navbarRoute: () => 'eaw/app/chain_ops/absences/tabs/absences/tabs',
            viewName: function() {
                return 'tabPane';
            },
            customer: getCustomer,
            employee: [ 'absence', function(absence) {
                return absence.employee;
            } ],
            absence: [ 'Absence', 'customer', '$transition$', function(Absence, customer, $transition$) {
                const params = $transition$.params();
                return Absence.get({
                    customerId: customer.id,
                    employeeId: params.e,
                    absenceId: params.absence,
                    with: [ 'employee', 'type', 'approval', 'comments', 'properties' ],
                }).$promise.then((data) => new Absence(data, customer));
            } ],
            tabs: [ 'absence', function(absence) {
                const tabs = [];
                const canGetAbsence = CurrentOld.can(`customers.${absence.customer.id}.employees.${absence.employee_id}.absences.${absence.id}.get`);
                const canUpdateLeaveShifts = CurrentOld.can(`customers.${absence.customer.id}.employees.${absence.employee_id}.absences.${absence.id}.leave_shifts.*.update`);
                const canUpdateShifts = CurrentOld.can(`customers.${absence.customer.id}.employees.${absence.employee_id}.shifts.*.update`);
                if (canGetAbsence) {
                    tabs.push({
                        label: 'absences:EDIT_ABSENCE',
                        src: 'eaw/app/chain_ops/absences/tabs/absences/tabs/edit',
                    });
                }
                const namespaces = [];
                if (CurrentOld.hasProduct(Products.LeaveShifts) && canUpdateLeaveShifts) {
                    tabs.push({
                        label: 'leaveshifts:LEAVE_SHIFT_plural',
                        src: 'eaw/app/chain_ops/absences/tabs/absences/tabs/leave_shifts',
                    });
                    namespaces.push(Namespace.Leaveshifts);
                }
                if (CurrentOld.hasProduct(Products.Scheduling) && canUpdateShifts) {
                    tabs.push({
                        label: 'SHIFT_plural',
                        src: 'eaw/app/chain_ops/absences/tabs/absences/tabs/shifts',
                    });
                    namespaces.push(Namespace.Scheduling);
                }
                const cb = () => tabs;
                return loadNamespaces(namespaces).then(cb, cb);
            } ],
        },
    });

    createState($stateProvider, {
        name: `eaw/app/chain_ops/absences/tabs/absences/tabs/edit`,
        parent: `eaw/app/chain_ops/absences/tabs/absences/tabs`,
        url: '/edit',
        views: {
            tabPane: 'editAbsence',
        },
        resolve: [
            // Old custom fields are used here, so set them up
            dependencyResolver('customFieldsSetter', [ '$http', 'customer' ], function($http: angular.IHttpService, customer) {
                return CustomFieldOld.storeFields(customer.id, $http);
            }),
        ],
    });

    $stateProvider.state(`eaw/app/chain_ops/absences/tabs/absences/tabs/leave_shifts`, {
        parent: `eaw/app/chain_ops/absences/tabs/absences/tabs`,
        url: '/leave_shifts',
        views: {
            tabPane: 'leaveShifts',
        },
        data: {
            products: [ Products.LeaveShifts ],
            permissions: [ 'customers.{c}.employees.{e}.absences.{absence}.leave_shifts.*.get' ],
            i18nextNs: [ Namespace.Leaveshifts, Namespace.Absences ],
        },
        resolve: {
            leaveShifts: [ 'LeaveShiftFactory', 'absence', 'customer', '$transition$', function(LeaveShiftFactory, absence, customer, $transition$) {
                const params = $transition$.params();
                const args = {
                    customer_id: params.c,
                    employee_id: params.e,
                    model: 'absences',
                    model_id: params.absence,
                    with: [ 'customer' ],
                };
                return LeaveShiftFactory.getAll(args).$promise.then((resp) => resp.data);
            } ],
            object: [ 'absence', function(absence) {
                return absence;
            } ],
            objectType: function() {
                return 'absences';
            },
            permissions: [ '$transition$', function($transition$) {
                const params = $transition$.params();
                return {
                    delete: CurrentOld.can('customers.{c}.employees.{e}.absences.{offTime}.leave_shifts.*.delete', params),
                    update: CurrentOld.can('customers.{c}.employees.{e}.absences.{offTime}.leave_shifts.*.update', params),
                    create: CurrentOld.can('customers.{c}.employees.{e}.absences.{offTime}.leave_shifts.create', params),
                };
            } ],
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/absences/tabs/absences/tabs/shifts`, {
        parent: `eaw/app/chain_ops/absences/tabs/absences/tabs`,
        url: '/shifts',
        views: {
            tabPane: 'shiftsDuringAbsence',
        },
        data: {
            breadcrumb: { key: 'SHIFT_plural' },
        },
        resolve: {
            editTab: function() {
                return 'eaw/app/chain_ops/absences/tabs/absences/tabs/edit';
            },
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/absences/tabs/vacations/tabs`, {
        parent: `eaw/app/chain_ops/absences/tabs/vacations`,
        url: '/:c/:e/:offTime',
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            permissions: [ 'customers.{c}.employees.{e}.off_times.{offTime}.get' ],
            breadcrumb: { key: 'EDIT_OFFTIME', ns: 'vacation' },
        },
        params: {
            c: {
                // customer id
                type: 'int',
            },
            e: {
                // employee id
                type: 'int',
            },
            offTime: {
                type: 'int',
            },
        },
        resolve: {
            navbarRoute: function() {
                return 'eaw/app/chain_ops/absences/tabs/vacations/tabs';
            },
            viewName: function() {
                return 'tabPane';
            },
            customer: getCustomer,
            offTime: [ 'OffTime', '$transition$', function(OffTime, $transition$) {
                const params = $transition$.params();
                return OffTime.get({
                    customer_id: params.c,
                    employee_id: params.e,
                    off_time_id: params.offTime,
                    with: [
                        'employee.positions',
                        'employee.user',
                        'approval',
                        'comments.user',
                        'vacationDaysChange',
                    ],
                });
            } ],
            employee: [ 'offTime', function(offTime) {
                return offTime.employee;
            } ],
            tabs: [ 'customer', function(customer) {
                return [ {
                    label: 'EDIT',
                    src: 'eaw/app/chain_ops/absences/tabs/vacations/tabs/edit',
                }, {
                    label: 'leaveshifts:LEAVE_SHIFT_plural',
                    src: 'eaw/app/chain_ops/absences/tabs/vacations/tabs/leave-shifts',
                }, {
                    label: 'vacation:TIMELINE',
                    src: 'eaw/app/chain_ops/absences/tabs/vacations/tabs/timeline',
                    permission: `customers.${customer.id}.employees.*.off_times.*.get`,
                } ];
            } ],
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/absences/tabs/vacations/tabs/edit`, {
        parent: `eaw/app/chain_ops/absences/tabs/vacations/tabs`,
        url: '/edit',
        views: {
            tabPane: 'editOffTime',
        },
        data: {
            i18nextNs: [ Namespace.Calendar ],
        },
    });
    $stateProvider.state(`eaw/app/chain_ops/absences/tabs/vacations/tabs/leave-shifts`, {
        parent: `eaw/app/chain_ops/absences/tabs/vacations/tabs`,
        url: '/leave_shifts',
        views: {
            tabPane: 'leaveShifts',
        },
        data: {
            products: [ Products.LeaveShifts ],
            permissions: [ 'customers.{c}.employees.{e}.off_times.{offTime}.leave_shifts.*.get' ],
        },
        resolve: {
            leaveShifts: [ 'LeaveShiftFactory', '$transition$', function(LeaveShiftFactory, $transition$) {
                const params = $transition$.params();
                return LeaveShiftFactory.getAll({
                    customer_id: params.c,
                    employee_id: params.e,
                    model: 'off_times',
                    model_id: params.offTime,
                }).$promise.then((resp) => resp.data);
            } ],
            object: [ 'offTime', function(offTime) {
                return offTime;
            } ],
            objectType: function() {
                return 'off_times';
            },
            permissions: [ '$transition$', function($transition$) {
                const params = $transition$.params();
                return {
                    create: CurrentOld.can('customers.{c}.employees.{e}.off_times.{offTime}.leave_shifts.create', params),
                    update: CurrentOld.can('customers.{c}.employees.{e}.off_times.{offTime}.leave_shifts.*.update', params),
                    delete: CurrentOld.can('customers.{c}.employees.{e}.off_times.{offTime}.leave_shifts.*.delete)', params),
                };
            } ],
        },
    });
    $stateProvider.state('eaw/app/chain_ops/absences/tabs/vacations/tabs/timeline', {
        parent: `eaw/app/chain_ops/absences/tabs/vacations/tabs`,
        url: '/timeline',
        views: {
            tabPane: 'timeline',
        },
        data: {
            permissions: [ `customers.{c}.employees.*.off_times.*.get` ],
            breadcrumb: { key: 'TIMELINE', ns: 'vacation' },
        },
        resolve: {
            rows: [ 'OffTime', 'offTime', '$transition$', function(OffTime, offTime, $transition$) {
                return OffTime.getAll({
                    customer_id: $transition$.params().c,
                    approved: true,
                    from: offTime.from,
                    to: offTime.to,
                    with: [ 'employee.positions' ],
                }).then((resp) => {
                    resp.data = resp.data.map((ot) => {
                        ot.name = `${ot.employee?.name}<br />${ot.employee.positions?.map((p) => p.name).join(', ')}`;
                    });
                    return resp.data;
                });
            } ],
        },
    });

    function getCustomer(CustomerService, ProductService, user, $transition$) {
        const customerId = $transition$.params().c;
        return Promise.all([
            CustomerService.get({ id: customerId }).$promise,
            ProductService.getAllForCustomer({ customerId }).$promise,
        ]).then(([ customerResp, productResp ]) => {
            const customer = new CustomerOld(customerResp, user);
            customer.products = productResp.data.map((prod) => {
                prod.active = true;
                return prod;
            });
            return customer;
        });
    }
} ]);
