// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

const template1 = `<md-card ng-if="!$eff.scheduleView.schedule.original">
    <md-card-content>
        <form name="newEfficiencyForm" ng-submit="$eff.submitEfficiency()" autocomplete="off" novalidate>
            <div layout="row" layout-wrap>
                <md-input-container flex="100" flex-gt-xs="33">
                    <label ng-i18next="scheduling:BUDGET_FROM"></label>
                    <input type="number" min="0" step="1" ng-model="$eff.efficiency.budget_low" required>
                </md-input-container>

                <md-input-container flex="100" flex-gt-xs="33">
                    <label ng-i18next="scheduling:BUDGET_TO"></label>
                    <input type="number" min="0" step="1" ng-model="$eff.efficiency.budget_high" required>
                </md-input-container>

                <md-input-container flex="100" flex-gt-xs="33">
                    <label ng-i18next="scheduling:EFFICIENCY"></label>
                    <input type="number" min="0" ng-model="$eff.efficiency.value" required>
                </md-input-container>
            </div>

            <div layout="row">
                <md-button type="submit" class="md-accent md-raised" ng-i18next="SUBMIT" ng-disabled="newEfficiencyForm.$invalid"></md-button>
            </div>
        </form>
    </md-card-content>
</md-card>

<md-card>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="$eff.table.columns"
                cells="$eff.table.cells"
                get-data="$eff.getData(pagination)"
                reload-table="$eff.reloadEffTable">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;

module('eaw.scheduling').component('scheduleEfficiencyTab', {
    template: template1,
    controllerAs: '$eff',
    require: {
        scheduleView: '^scheduleView',
    },
    controller: [ 'ToastService', 'scheduleEfficiency', 'ScheduleService', function(ToastService, scheduleEfficiency, ScheduleService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.efficiency = {};

            if (!scheduleEfficiency.get()) {
                scheduleEfficiency.init();
            }

            ctrl.table = {
                columns: [
                    {
                        title: t('scheduling:BUDGET_FROM'),
                        class: 'text-right',
                    },
                    {
                        title: t('scheduling:BUDGET_TO'),
                        class: 'text-right',
                    },
                    {
                        title: t('scheduling:EFFICIENCY'),
                        class: 'text-right',
                    },
                    { title: '' },
                ],
                cells: [
                    {
                        template: `item.budget_low | eawNumber`,
                        class: 'text-right',
                    },
                    {
                        template: `item.budget_high | eawNumber`,
                        class: 'text-right',
                    },
                    {
                        template: `item.value | eawNumber`,
                        class: 'text-right',
                    },
                    {
                        buttons: [
                            {
                                type: 'warn',
                                icon: 'delete',
                                click: ctrl.deleteEfficiency,
                                displayNone: () => ctrl.scheduleView.schedule.original,
                            },
                        ],
                    },
                ],
            };
        };

        ctrl.getData = (pagination) => ScheduleService.getEfficiencies({
            customerId: ctrl.scheduleView.schedule.customer_id,
            scheduleId: ctrl.scheduleView.schedule.id,
            order_by: 'budget_low',
            direction: 'asc',
            ...pagination.toWithPaginatorArguments(),
        });

        ctrl.submitEfficiency = () => {
            scheduleEfficiency.add(ctrl.efficiency).then(() => {
                ToastService.toast(t('scheduling:ADDED_EFFICIENCY'));
                ctrl.efficiency = {};
                ctrl.reloadEffTable = true;
                ctrl.scheduleView.recalcTopStats();
            });
        };

        ctrl.deleteEfficiency = (efficiency) => {
            scheduleEfficiency.delete(efficiency).then(() => {
                ToastService.toast(t('scheduling:DELETED_EFFICIENCY'));
                ctrl.reloadEffTable = true;
                ctrl.scheduleView.recalcTopStats();
            });
        };
    } ],
});
