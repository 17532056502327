import { Inject, Injectable } from '@angular/core';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { CustomerGroup, CustomerGroupResponse } from '../models/customer-group';
import { map, Observable } from 'rxjs';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { PaginationOptions } from '../interfaces/pagination-options';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../utils/format-http-params';
import { CustomerGroupMember, CustomerGroupMemberResponse } from '../models/customer-group-member';
import { Product, ProductResponse } from '../models/product';
import { ProductName } from '../enums/products';

interface GetMembersOptions extends PaginationOptions {
    include_inactive?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class CustomerGroupService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    get(groupId: number) {
        return this.http.get<CustomerGroupResponse>(`/customer_groups/${groupId}`).pipe(classifyItem(CustomerGroup));
    }

    getAll(options: PaginationOptions = {}) {
        return this.http.get<ArrayPaginatedResponse<CustomerGroupResponse>>('/customer_groups', { params: { ...options } }).pipe(classifyArrayPaginatedResponse(CustomerGroup));
    }

    getAllForCustomer(customer: number, options?: PaginationOptions): Observable<ArrayPaginatedResponse<CustomerGroup>> {
        return this.http.get<ArrayPaginatedResponse<CustomerGroupResponse>>(`customers/${customer}/customer_groups`, {
            params: formatHttpParams(options),
        }).pipe(
            map((response) => {
                return {
                    ...response,
                    data: response.data.map((r) => new CustomerGroup(r)),
                };
            }),
        );
    }

    getProducts(customerGroupId: number, paginationOptions: PaginationOptions = {}) {
        return this.http.get<ArrayPaginatedResponse<ProductResponse>>(`/customer_groups/${customerGroupId}/products`, {
            params: formatHttpParams(paginationOptions),
        }).pipe(classifyArrayPaginatedResponse(Product));
    }

    addProduct(customerGroupId: number, productName: ProductName) {
        return this.http.post<undefined>(`/customer_groups/${customerGroupId}/products`, {
            product_name: productName,
        });
    }

    removeProduct(customerGroupId: number, productName: ProductName) {
        return this.http.delete<undefined>(`/customer_groups/${customerGroupId}/products/${productName}`);
    }

    getMembers(customerGroupId: number, paginationOptions: GetMembersOptions = {}) {
        return this.http.get<ArrayPaginatedResponse<CustomerGroupMemberResponse>>(`/customer_groups/${customerGroupId}/members`, {
            params: formatHttpParams(paginationOptions),
        }).pipe(classifyArrayPaginatedResponse(CustomerGroupMember));
    }

    addMember(customerGroupId: number, customerId: number) {
        return this.http.post<CustomerGroupMemberResponse>(`/customer_groups/${customerGroupId}/members`, {
            member_id: customerId,
        }).pipe(classifyItem(CustomerGroupMember));
    }

    removeMember(customerGroupId: number, customerId: number) {
        return this.http.delete(`/customer_groups/${customerGroupId}/members/${customerId}`);
    }

    create(name: string) {
        return this.http.post<CustomerGroupResponse>('/customer_groups', {
            name,
        }).pipe(classifyItem(CustomerGroup));
    }

    update(customerGroupId: number, name: string) {
        return this.http.put<CustomerGroupResponse>(`/customer_groups/${customerGroupId}`, {
            name,
        }).pipe(classifyItem(CustomerGroup));
    }

    delete(customerGroupId: number) {
        return this.http.delete(`/customer_groups/${customerGroupId}`);
    }
}
