import { inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { TasksService } from '../services/tasks.service';
import { Transition } from '@uirouter/angularjs';

@Injectable({
    providedIn: 'root',
})
export class TasksHookService {
    private readonly uiRouter = inject(UIRouter);
    private readonly taskService = inject(TasksService);

    private readonly tasksRoute = 'eaw/app/tasks';

    constructor() {
        this.uiRouter.transitionService.onBefore({
            to: this.tasksRoute,
        }, this.goToTasks.bind(this), { priority: 1000 });

        this.uiRouter.transitionService.onBefore({
            to: (state) => !!state?.data?.requireLogin,
        }, this.goToTasks.bind(this));
    }

    private goToTasks(transition: Transition) {
        const hasTasks = this.taskService.hasTasks;
        const isTasksRoute = transition.$to().name === this.tasksRoute;

        if (hasTasks && !isTasksRoute) {
            this.taskService.setRedirect(transition);
            return transition.router.stateService.target(this.tasksRoute);
        }

        if (!hasTasks && isTasksRoute) {
            return transition.router.stateService.target('eaw/app/home');
        }

        return true;
    }
}
