import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime, Interval } from 'luxon';
import { CurrentService } from '../../../shared/services/current.service';
import { TimepunchService } from '../../http/timepunch.service';
import { ShiftService } from '../../../scheduling/http/shift.service';
import { catchError, EMPTY, forkJoin, Observable, of, switchMap } from 'rxjs';
import { Employee } from '../../../shared/models/employee';
import { HolidayService } from '../../../shared/http/holiday.service';
import { Products } from '../../../shared/enums/products';
import { FlexitimeService } from '../../http/flexitime.service';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { Flexitime } from '../../models/flexitime';
import { Holiday } from '../../../shared/models/holiday';
import { Shift } from '../../../scheduling/models/shift';
import { Timepunch } from '../../models/timepunch';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { ShiftPeriodService } from '../../../scheduling/http/shift-period.service';
import { ManageTimepunchDialogService } from '../../dialogs/manage-timepunch-dialog/manage-timepunch-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { EmployeeService } from '../../../shared/http/employee.service';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { BusinessDate } from '../../../shared/utils/business-date';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent, CommentDialogData } from '../../../shared/dialogs/comments-dialog/comment-dialog.component';

interface OverviewDay {
    day: DateTime,
    holiday: string | undefined,
    sunday: boolean,
    shifts: Map<Shift | null, Timepunch[]>,
    flexitimes: (Flexitime | undefined)[],
}

@Component({
    selector: 'eaw-manage-timepunches-overview',
    templateUrl: './manage-timepunches-overview.component.html',
    styleUrl: './manage-timepunches-overview.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        AutocompleteComponent,
        MatButtonModule,
        NgIf,
        MatProgressSpinnerModule,
        MatIconModule,
        NgFor,
        AsyncPipe,
        KeyValuePipe,
        TranslatePipe,
        DateTimePipe,
        DurationPipe,
    ],
})
export class ManageTimepunchesOverviewComponent {
    readonly tpInColumn = 90;
    readonly tpOutColumn = 90;
    readonly tpLengthColumn = 130;
    readonly tpApprovedColumn = 90;
    readonly tpCustomerColumn = 140;
    readonly tpCommentsColumn = 110;

    showFilter = true;
    shifts: Shift[] = [];
    timepunches: Timepunch[] = [];
    flexitimes: Flexitime[] = [];
    holidays: Holiday[] = [];
    overviewDays: OverviewDay[] = [];
    flexitimeBalance = 0;
    totalShiftDuration = 0;
    totalBreakDuration = 0;
    totalPunchDuration = 0;
    selectedEmployee?: Employee;
    selectedInterval?: [ DateTime, DateTime ];
    employees?: Observable<ArrayPaginatedResponse<Employee>>;
    formGroup = new FormGroup({
        employee: new FormControl<Employee | number | null>(null, [ Validators.required ]),
        dateRange: new FormGroup({
            from: new FormControl<DateTime | null>(DateTime.now().startOf('month'), { updateOn: 'blur' }),
            to: new FormControl<DateTime | null>(DateTime.now().endOf('month'), { updateOn: 'blur' }),
        }, [ Validators.required ]),
    });

    constructor(
        @Inject(CurrentService) public current: CurrentService,
        @Inject(TimepunchService) private timepunchService: TimepunchService,
        @Inject(ShiftService) private shiftService: ShiftService,
        @Inject(HolidayService) private holidayService: HolidayService,
        @Inject(FlexitimeService) private flexitimeService: FlexitimeService,
        @Inject(PromptDialogService) private promptDialog: PromptDialogService,
        @Inject(MatDialog) public matDialog: MatDialog,
        @Inject(ShiftPeriodService) public shiftPeriodService: ShiftPeriodService,
        @Inject(TranslateService) public translate: TranslateService,
        @Inject(CustomerProductService) public customerProductService: CustomerProductService,
        @Inject(EmployeeService) public employeeService: EmployeeService,
        @Inject(ManageTimepunchDialogService) public manageTimepunchDialogService: ManageTimepunchDialogService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
    ) {
    }

    getData() {
        this.formGroup.markAsPristine();
        this.formGroup.disable();

        const from = this.formFrom;
        const to = this.formTo;

        return forkJoin([
            this.getTimepunchesObservable(from, to),
            this.getShiftsObservable(new BusinessDate(from), new BusinessDate(to)),
            this.getHolidaysObservable(from, to),
            this.getEmployeeFlexitimeObservable(from, to),
            this.getEmployeeFlexitimeBalance(),
        ]).pipe(
            catchError(() => {
                this.formGroup.enable();
                return EMPTY;
            }),
        ).subscribe((response) => this.onData(from, to, response));
    }

    get formFrom() {
        return this.formGroup.value.dateRange?.from || DateTime.now().startOf('week');
    }

    get formTo() {
        return this.formGroup.value.dateRange?.to || DateTime.now().endOf('week');
    }

    get formEmployee() {
        return this.formGroup.controls.employee.value instanceof Employee ? this.formGroup.controls.employee.value : undefined;
    }

    onData(from: DateTime, to: DateTime, response: [ ArrayPaginatedResponse<Timepunch>, ArrayPaginatedResponse<Shift>, ArrayPaginatedResponse<Holiday>, ArrayPaginatedResponse<Flexitime>, number ]) {
        this.timepunches = response[0].data;
        this.shifts = response[1].data.sort((a, b) => +a.from - +b.from);
        this.holidays = response[2].data;
        this.flexitimes = response[3].data;
        this.flexitimeBalance = response[4];

        this.formGroup.enable();
        this.selectedEmployee = this.formEmployee;
        this.selectedInterval = [ this.formFrom, this.formTo ];

        this.generateDays(from, to);
        this.calcTotalShiftDuration();
        this.calcTotalBreakDuration();
        this.calcTotalPunchDuration();
    }

    updateTimepunch(overviewDay?: OverviewDay, timepunch?: Timepunch) {
        if (timepunch == null) {
            this.manageTimepunchDialogService.create(this.current.getCustomer().id, undefined, {
                businessDate: overviewDay?.day,
                date: overviewDay?.day,
                employee: this.selectedEmployee,
            }).afterClosed().subscribe((result) => {
                if (result instanceof Timepunch) {
                    this.getData();
                }
            });
        } else {
            timepunch.employee = this.selectedEmployee;
            this.manageTimepunchDialogService.update(this.current.getCustomer().id, timepunch).afterClosed().subscribe((result) => {
                if (result instanceof Timepunch) {
                    this.getData();
                }
            });
        }
    }

    async changeBreakLength(shift: Shift | null) {
        if (!(shift instanceof Shift)) {
            return;
        }

        const breakPeriod = shift.periods.find((p) => p.break);
        const breakLength = ((breakPeriod?.length || 0) / 3600) || undefined;

        this.promptDialog.open('decimal', {
            title: this.translate.t('ADJUST_BREAK', 'payroll'),
            label: this.translate.t('BREAK_LENGTH_HRS', 'payroll'),
            options: {
                min: 0,
                step: 0.01,
            },
            formControl: new FormControl(breakLength, [ Validators.required, Validators.min(0) ]),
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            if (breakPeriod) {
                this.shiftPeriodService.update(this.current.getCustomer().id, shift.scheduleId, shift.id, breakPeriod.id, {
                    length: result * 3600,
                }).subscribe(this.getData.bind(this));
            } else {
                this.shiftPeriodService.create(this.current.getCustomer().id, shift.scheduleId, shift.id, {
                    length: result * 3600,
                    offset: 0,
                    break: true,
                }).subscribe(this.getData.bind(this));
            }
        });
    }

    generateDays(from: DateTime, to: DateTime) {
        let date = from;
        this.overviewDays = [];

        while (!date.hasSame(to.plus({ day: 1 }), 'day')) {
            const day: OverviewDay = {
                holiday: this.holidays.find((h) => h.date.dateTime.hasSame(date, 'day'))?.name,
                sunday: date.weekday === 7,
                day: date,
                flexitimes: this.flexitimes.filter((f) => f.businessDate?.dateTime.hasSame(date, 'day')),
                shifts: new Map(),
            };

            const shiftsThisDay = this.shifts.filter((s) => s.businessDate?.dateTime.hasSame(date, 'day'));
            const overlappingTimepunches = this.timepunches.filter((tp) => this.timepunchHasShift(tp, shiftsThisDay)).sort(this.timepunchSorter);

            shiftsThisDay.forEach((shift) => {
                day.shifts.set(shift, []);
            });

            overlappingTimepunches.forEach((tp) => {
                const tpInterval = tp.interval || Interval.fromDateTimes(tp.in, DateTime.now());
                const overlappingShifts = shiftsThisDay.filter((s) => s.interval ? tpInterval.overlaps(s.interval) : false);
                if (!overlappingShifts.length) {
                    return;
                }

                const mainShift = overlappingShifts.reduce((prevShift, curShift) => {
                    const prevShiftOverlap = prevShift.interval?.intersection(tpInterval)?.length() || 0;
                    const curShiftOverlap = curShift.interval?.intersection(tpInterval)?.length() || 0;
                    return prevShiftOverlap > curShiftOverlap ? prevShift : curShift;
                });

                const timepunches = day.shifts.get(mainShift) || [];
                timepunches.push(tp);
                if (!day.shifts.has(mainShift)) {
                    day.shifts.set(mainShift, timepunches);
                }
            });

            const timepunchesWithNoShift = this.timepunches.filter((tp) => {
                return tp.businessDate?.dateTime.hasSame(date, 'day') && !this.timepunchHasShift(tp);
            }).sort(this.timepunchSorter);

            if (timepunchesWithNoShift.length) {
                day.shifts.set(null, timepunchesWithNoShift);
            }

            // Only add if there's shifts and/or timepunches
            if (day.shifts.size) {
                this.overviewDays.push(day);
            }

            date = date.plus({ day: 1 });
        }
    }

    showComments(timepunch: Timepunch) {
        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                comments: of(timepunch.comments || []),
            },
        });
    }

    calcTotalPunchDuration() {
        this.totalPunchDuration = this.timepunches.reduce((sum, p) => sum + p.length, 0);
    }

    calcTotalShiftDuration() {
        this.totalShiftDuration = this.shifts.reduce((sum, shift) => sum + shift.netLength, 0);
    }

    calcTotalBreakDuration() {
        this.totalBreakDuration = this.shifts.reduce((sum, shift) => {
            return sum + shift.periods.filter((p) => p.break).reduce((breakSum, p) => {
                return breakSum + p.length;
            }, 0);
        }, 0);
    }

    timepunchSorter(a: Timepunch, b: Timepunch) {
        return a.in && b.in ? +a.in - +b.in : 0;
    }

    timepunchHasShift(timepunch: Timepunch, selectedShifts = this.shifts) {
        return selectedShifts.some((s) => s.interval?.overlaps(timepunch.interval || Interval.fromDateTimes(timepunch.in, DateTime.now())));
    }

    getEmployeeFlexitimeBalance() {
        const employee = this.formEmployee;

        if (!employee) {
            return of(0);
        }

        return this.customerProductService.hasProducts(this.current.getCustomer().id, [ Products.FlexiTime ])
            .pipe(switchMap((hasProducts) => {
                if (!hasProducts) {
                    return of(0);
                }

                return this.flexitimeService.getEmployeeBalance(this.current.getCustomer().id, employee.id, DateTime.now().startOf('day'));
            }));
    }

    getEmployeeFlexitimeObservable(from: DateTime, to: DateTime): Observable<ArrayPaginatedResponse<Flexitime>> {
        const employee = this.formEmployee;
        const mockData = mockArrayPaginatedResponse<Flexitime>([]);

        if (!employee) {
            return of(mockData);
        }

        return this.customerProductService.hasProducts(this.current.getCustomer().id, [ Products.FlexiTime ])
            .pipe(switchMap((hasProducts) => {
                if (!hasProducts) {
                    return of(mockData);
                }

                return this.flexitimeService.getAllForEmployee(this.current.getCustomer().id, employee.id, {
                    from,
                    to,
                    per_page: 9999,
                });
            }));
    }

    getHolidaysObservable(from: DateTime, to: DateTime): Observable<ArrayPaginatedResponse<Holiday>> {
        return this.holidayService.get(from, to, this.current.getCustomer().countryCode, this.current.getCustomer().regionId ?? undefined);
    }

    getShiftsObservable(from: BusinessDate, to: BusinessDate): Observable<ArrayPaginatedResponse<Shift>> {
        const employee = this.formEmployee;
        if (!employee) {
            return of(mockArrayPaginatedResponse([]));
        }

        return this.shiftService.getAllForEmployee(this.current.getCustomer().id, employee.id, {
            fromBusinessDate: from,
            toBusinessDate: to,
            'with[]': [ 'periods' ],
            per_page: 9999,
        });
    }

    getTimepunchesObservable(from: DateTime, to: DateTime): Observable<ArrayPaginatedResponse<Timepunch>> {
        const employee = this.formEmployee;
        if (!employee) {
            return of(mockArrayPaginatedResponse([]));
        }

        return this.timepunchService.getForCustomer(this.current.getCustomer().id, {
            employeeId: employee.id,
            'with[]': [ 'customer', 'comments.user' ],
            from,
            to,
            per_page: 9999,
        });
    }
}
