const _excluded = ["block", "list", "listItem", "marks", "types"],
  _excluded2 = ["listItem"],
  _excluded3 = ["_key"];
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}
import { nestLists, isPortableTextToolkitList, isPortableTextListItemBlock, isPortableTextToolkitSpan, isPortableTextBlock, isPortableTextToolkitTextNode, spanToPlainText, buildMarksTree } from '@portabletext/toolkit';
const allowedProtocols = ["http", "https", "mailto", "tel"];
const charMap = {
  "&": "amp",
  "<": "lt",
  ">": "gt",
  '"': "quot",
  "'": "#x27"
};
function escapeHTML(str) {
  return str.replace(/[&<>"']/g, s => "&".concat(charMap[s], ";"));
}
function uriLooksSafe(uri) {
  const url = (uri || "").trim();
  const first = url.charAt(0);
  if (first === "#" || first === "/") {
    return true;
  }
  const colonIndex = url.indexOf(":");
  if (colonIndex === -1) {
    return true;
  }
  const proto = url.slice(0, colonIndex).toLowerCase();
  if (allowedProtocols.indexOf(proto) !== -1) {
    return true;
  }
  const queryIndex = url.indexOf("?");
  if (queryIndex !== -1 && colonIndex > queryIndex) {
    return true;
  }
  const hashIndex = url.indexOf("#");
  if (hashIndex !== -1 && colonIndex > hashIndex) {
    return true;
  }
  return false;
}
const link = _ref => {
  let {
    children,
    value
  } = _ref;
  const href = (value == null ? void 0 : value.href) || "";
  const looksSafe = uriLooksSafe(href);
  return looksSafe ? '<a href="'.concat(escapeHTML(href), '">').concat(children, "</a>") : children;
};
const defaultMarks = {
  em: _ref2 => {
    let {
      children
    } = _ref2;
    return "<em>".concat(children, "</em>");
  },
  strong: _ref3 => {
    let {
      children
    } = _ref3;
    return "<strong>".concat(children, "</strong>");
  },
  code: _ref4 => {
    let {
      children
    } = _ref4;
    return "<code>".concat(children, "</code>");
  },
  underline: _ref5 => {
    let {
      children
    } = _ref5;
    return '<span style="text-decoration:underline">'.concat(children, "</span>");
  },
  "strike-through": _ref6 => {
    let {
      children
    } = _ref6;
    return "<del>".concat(children, "</del>");
  },
  link
};
const defaultLists = {
  number: _ref7 => {
    let {
      children
    } = _ref7;
    return "<ol>".concat(children, "</ol>");
  },
  bullet: _ref8 => {
    let {
      children
    } = _ref8;
    return "<ul>".concat(children, "</ul>");
  }
};
const DefaultListItem = _ref9 => {
  let {
    children
  } = _ref9;
  return "<li>".concat(children, "</li>");
};
const getTemplate = (type, prop) => "Unknown ".concat(type, ", specify a component for it in the `components.").concat(prop, "` option");
const unknownTypeWarning = typeName => getTemplate('block type "'.concat(typeName, '"'), "types");
const unknownMarkWarning = markType => getTemplate('mark type "'.concat(markType, '"'), "marks");
const unknownBlockStyleWarning = blockStyle => getTemplate('block style "'.concat(blockStyle, '"'), "block");
const unknownListStyleWarning = listStyle => getTemplate('list style "'.concat(listStyle, '"'), "list");
const unknownListItemStyleWarning = listStyle => getTemplate('list item style "'.concat(listStyle, '"'), "listItem");
function printWarning(message) {
  console.warn(message);
}
const DefaultUnknownType = _ref10 => {
  let {
    value,
    isInline
  } = _ref10;
  const warning = unknownTypeWarning(value._type);
  return isInline ? '<span style="display:none">'.concat(warning, "</span>") : '<div style="display:none">'.concat(warning, "</div>");
};
const DefaultUnknownMark = _ref11 => {
  let {
    markType,
    children
  } = _ref11;
  return '<span class="unknown__pt__mark__'.concat(markType, '">').concat(children, "</span>");
};
const DefaultUnknownBlockStyle = _ref12 => {
  let {
    children
  } = _ref12;
  return "<p>".concat(children, "</p>");
};
const DefaultUnknownList = _ref13 => {
  let {
    children
  } = _ref13;
  return "<ul>".concat(children, "</ul>");
};
const DefaultUnknownListItem = _ref14 => {
  let {
    children
  } = _ref14;
  return "<li>".concat(children, "</li>");
};
const DefaultHardBreak = () => "<br/>";
const defaultPortableTextBlockStyles = {
  normal: _ref15 => {
    let {
      children
    } = _ref15;
    return "<p>".concat(children, "</p>");
  },
  blockquote: _ref16 => {
    let {
      children
    } = _ref16;
    return "<blockquote>".concat(children, "</blockquote>");
  },
  h1: _ref17 => {
    let {
      children
    } = _ref17;
    return "<h1>".concat(children, "</h1>");
  },
  h2: _ref18 => {
    let {
      children
    } = _ref18;
    return "<h2>".concat(children, "</h2>");
  },
  h3: _ref19 => {
    let {
      children
    } = _ref19;
    return "<h3>".concat(children, "</h3>");
  },
  h4: _ref20 => {
    let {
      children
    } = _ref20;
    return "<h4>".concat(children, "</h4>");
  },
  h5: _ref21 => {
    let {
      children
    } = _ref21;
    return "<h5>".concat(children, "</h5>");
  },
  h6: _ref22 => {
    let {
      children
    } = _ref22;
    return "<h6>".concat(children, "</h6>");
  }
};
const defaultComponents = {
  types: {},
  block: defaultPortableTextBlockStyles,
  marks: defaultMarks,
  list: defaultLists,
  listItem: DefaultListItem,
  hardBreak: DefaultHardBreak,
  unknownType: DefaultUnknownType,
  unknownMark: DefaultUnknownMark,
  unknownList: DefaultUnknownList,
  unknownListItem: DefaultUnknownListItem,
  unknownBlockStyle: DefaultUnknownBlockStyle
};
function mergeComponents(parent, overrides) {
  const {
      block,
      list,
      listItem,
      marks,
      types
    } = overrides,
    rest = _objectWithoutProperties(overrides, _excluded);
  return _objectSpread(_objectSpread({}, parent), {}, {
    block: mergeDeeply(parent, overrides, "block"),
    list: mergeDeeply(parent, overrides, "list"),
    listItem: mergeDeeply(parent, overrides, "listItem"),
    marks: mergeDeeply(parent, overrides, "marks"),
    types: mergeDeeply(parent, overrides, "types")
  }, rest);
}
function mergeDeeply(parent, overrides, key) {
  const override = overrides[key];
  const parentVal = parent[key];
  if (typeof override === "function") {
    return override;
  }
  if (override && typeof parentVal === "function") {
    return override;
  }
  if (override) {
    return _objectSpread(_objectSpread({}, parentVal), override);
  }
  return parentVal;
}
function toHTML(value) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    components: componentOverrides,
    onMissingComponent: missingComponentHandler = printWarning
  } = options;
  const handleMissingComponent = missingComponentHandler || noop;
  const blocks = Array.isArray(value) ? value : [value];
  const nested = nestLists(blocks, "html");
  const components = componentOverrides ? mergeComponents(defaultComponents, componentOverrides) : defaultComponents;
  const renderNode = getNodeRenderer(components, handleMissingComponent);
  const rendered = nested.map((node, index) => renderNode({
    node,
    index,
    isInline: false,
    renderNode
  }));
  return rendered.join("");
}
const getNodeRenderer = (components, handleMissingComponent) => {
  function renderNode(options) {
    const {
      node,
      index,
      isInline
    } = options;
    if (isPortableTextToolkitList(node)) {
      return renderList(node, index);
    }
    if (isPortableTextListItemBlock(node)) {
      return renderListItem(node, index);
    }
    if (isPortableTextToolkitSpan(node)) {
      return renderSpan(node);
    }
    if (isPortableTextBlock(node)) {
      return renderBlock(node, index, isInline);
    }
    if (isPortableTextToolkitTextNode(node)) {
      return renderText(node);
    }
    return renderCustomBlock(node, index, isInline);
  }
  function renderListItem(node, index) {
    const tree = serializeBlock({
      node,
      index,
      isInline: false,
      renderNode
    });
    const renderer = components.listItem;
    const handler = typeof renderer === "function" ? renderer : renderer[node.listItem];
    const itemHandler = handler || components.unknownListItem;
    if (itemHandler === components.unknownListItem) {
      const style = node.listItem || "bullet";
      handleMissingComponent(unknownListItemStyleWarning(style), {
        type: style,
        nodeType: "listItemStyle"
      });
    }
    let children = tree.children;
    if (node.style && node.style !== "normal") {
      const {
          listItem
        } = node,
        blockNode = _objectWithoutProperties(node, _excluded2);
      children = renderNode({
        node: blockNode,
        index,
        isInline: false,
        renderNode
      });
    }
    return itemHandler({
      value: node,
      index,
      isInline: false,
      renderNode,
      children
    });
  }
  function renderList(node, index) {
    const children = node.children.map((child, childIndex) => renderNode({
      node: child._key ? child : _objectSpread(_objectSpread({}, child), {}, {
        _key: "li-".concat(index, "-").concat(childIndex)
      }),
      index,
      isInline: false,
      renderNode
    }));
    const component = components.list;
    const handler = typeof component === "function" ? component : component[node.listItem];
    const list = handler || components.unknownList;
    if (list === components.unknownList) {
      const style = node.listItem || "bullet";
      handleMissingComponent(unknownListStyleWarning(style), {
        nodeType: "listStyle",
        type: style
      });
    }
    return list({
      value: node,
      index,
      isInline: false,
      renderNode,
      children: children.join("")
    });
  }
  function renderSpan(node) {
    const {
      markDef,
      markType,
      markKey
    } = node;
    const span = components.marks[markType] || components.unknownMark;
    const children = node.children.map((child, childIndex) => renderNode({
      node: child,
      index: childIndex,
      isInline: true,
      renderNode
    }));
    if (span === components.unknownMark) {
      handleMissingComponent(unknownMarkWarning(markType), {
        nodeType: "mark",
        type: markType
      });
    }
    return span({
      text: spanToPlainText(node),
      value: markDef,
      markType,
      markKey,
      renderNode,
      children: children.join("")
    });
  }
  function renderBlock(node, index, isInline) {
    const _serializeBlock = serializeBlock({
        node,
        index,
        isInline,
        renderNode
      }),
      {
        _key
      } = _serializeBlock,
      props = _objectWithoutProperties(_serializeBlock, _excluded3);
    const style = props.node.style || "normal";
    const handler = typeof components.block === "function" ? components.block : components.block[style];
    const block = handler || components.unknownBlockStyle;
    if (block === components.unknownBlockStyle) {
      handleMissingComponent(unknownBlockStyleWarning(style), {
        nodeType: "blockStyle",
        type: style
      });
    }
    return block(_objectSpread(_objectSpread({}, props), {}, {
      value: props.node,
      renderNode
    }));
  }
  function renderText(node) {
    if (node.text === "\n") {
      const hardBreak = components.hardBreak;
      return hardBreak ? hardBreak() : "\n";
    }
    return escapeHTML(node.text);
  }
  function renderCustomBlock(value, index, isInline) {
    const node = components.types[value._type];
    if (!node) {
      handleMissingComponent(unknownTypeWarning(value._type), {
        nodeType: "block",
        type: value._type
      });
    }
    const component = node || components.unknownType;
    return component({
      value,
      isInline,
      index,
      renderNode
    });
  }
  return renderNode;
};
function serializeBlock(options) {
  const {
    node,
    index,
    isInline,
    renderNode
  } = options;
  const tree = buildMarksTree(node);
  const children = tree.map((child, i) => renderNode({
    node: child,
    isInline: true,
    index: i,
    renderNode
  }));
  return {
    _key: node._key || "block-".concat(index),
    children: children.join(""),
    index,
    isInline,
    node
  };
}
function noop() {}
export { defaultComponents, escapeHTML, mergeComponents, toHTML, uriLooksSafe };
