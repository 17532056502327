import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogData, DialogSize } from '../dialog-component';
import { ReorderItemsDialogComponent } from './reorder-items-dialog.component';
import { Observable } from 'rxjs';

export type ReorderDialogItem<Item> = {item: Item, selected: boolean, text: Promise<string>};

export interface ReorderItemsDialogData<Item> extends DialogData {
    items: ReorderDialogItem<Item>[] | Observable<ReorderDialogItem<Item>[]>,
    title?: Promise<string>;
    // Don't use the version where you can select, just use it for reordering
    reorderOnly?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ReorderItemsDialogService {

    constructor(@Inject(MatDialog) private dialog: MatDialog) {
    }

    open<Item>(options: ReorderItemsDialogData<Item>): MatDialogRef<ReorderItemsDialogComponent<Item>, ReorderDialogItem<Item>[]> {
        return this.dialog.open<ReorderItemsDialogComponent<Item>, ReorderItemsDialogData<Item>, ReorderDialogItem<Item>[]>(ReorderItemsDialogComponent, {
            data: {
                size: DialogSize.Small,
                ...options,
            },
        });
    }
}
