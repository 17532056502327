export enum Products {
    '4HumanEvolution' = '4Human Evolution',
    Absence = 'Absence',
    AbsenceCalendar = 'Absence Calendar',
    AIBudgeting = 'AI Budgeting',
    AutomaticScheduling = 'Automatic Scheduling',
    Availability = 'Availability',
    BackOffice = 'BackOffice',
    Billing = 'Billing',
    Budgeting = 'Budgeting',
    BusinessUnits = 'BusinessUnits',
    Calendar = 'Calendar',
    Calendar2 = 'Calendar 2',
    ChainOps = 'Chain Ops',
    Checklists = 'Checklists',
    Core = 'Core',
    Coupons = 'Coupons',
    DigitalSigning = 'Digital Signing',
    Efficiency = 'Efficiency',
    ElevatedUserAccessVerification = 'Elevated User Access Verification',
    EmployeeHoursVerification = 'Employee Hours Verification',
    EmployeeListVerification = 'Employee List Verification',
    FileSystem = 'File System',
    FileSystemAccess = 'File System Access',
    FixedLaborCost = 'Fixed Labor Cost',
    FlexiTime = 'FlexiTime',
    Forms = 'Forms',
    France = 'France',
    Gastrofix = 'Gastrofix',
    HumanResources = 'Human Resources',
    ICalendar = 'iCalendar',
    Inspection = 'Inspection',
    KeyPerformanceIndicators = 'Key Performance Indicators',
    KundetellingNo = 'Kundetelling.no',
    LearningBank = 'LearningBank',
    LeaveShifts = 'Leave Shifts',
    Links = 'Links',
    MachineLearning = 'Machine Learning',
    Messaging = 'Messaging',
    MicrosoftEntraId = 'Microsoft Entra ID',
    Mirus = 'Mirus',
    MyStore = 'MyStore',
    Newsletter = 'Newsletter',
    Onboarding = 'Onboarding',
    OpsManagerDashboard = 'Ops Manager Dashboard',
    PeopleKpi = `McDonald's People KPI`,
    PFaktor = 'P-faktor',
    PaidTime = 'Paid Time',
    Payroll = 'Payroll',
    PeriodLocks = 'Period Locks',
    Postits = 'Postits',
    RepeatingSchedule = 'Repeating Schedule',
    Reports = 'Reports',
    Rotation = 'Rotation',
    Sage = 'Sage',
    ScheduleComments = 'Schedule Comments',
    Scheduling = 'Scheduling',
    SchedulingEmployeeSidebar = 'Scheduling Employee Sidebar',
    SchedulingLimits = 'Scheduling Limits',
    Staffing = 'Staffing',
    StaffingForecast = 'Staffing Forecast',
    SwissRiskAndCare = 'Swiss Risk & Care',
    Switzerland = 'Switzerland',
    TBS = 'TBS',
    TBSLite = 'TBS-Lite',
    Todo = 'Todo',
    Vacation = 'Vacation',
    VisionPoint = 'VisionPoint',
    VismaImport = 'Visma Import',
    VLH = 'VLH',
    PayslipImport = 'Payslip Import',
    Weather = 'Weather',
}

export type ProductName = typeof Products[keyof typeof Products];
