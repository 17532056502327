import { BusinessDateString } from '../../shared/types/business-date';
import { DateTime } from 'luxon';
import { BusinessDate } from '../../shared/utils/business-date';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../shared/interfaces/api-response';

export type LeaveShiftType = 'off_time' | 'absence';

export interface LeaveShiftResponse extends ApiResponse{
    id: number;
    // This is "fake", only for easy display purposes
    break_from: string | null;
    // This is "fake", only for easy display purposes
    break_to: string | null;
    break_length: number | null;
    break_offset: number | null;
    business_date: BusinessDateString;
    customer_id: number;
    employee_id: number;
    from: string;
    to: string;
    leave_id: number;
    leave_type: LeaveShiftType;

    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export class LeaveShift {
    private break: {from: DateTime | null, to: DateTime | null} = { from: null, to: null };

    id: number;
    businessDate: BusinessDate;
    customerId: number;
    employeeId: number;
    from: DateTime;
    to: DateTime;
    // The ID of the leave type
    leaveId: number;
    leaveType: LeaveShiftType;

    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;

    constructor(data: LeaveShiftResponse) {
        this.id = data.id;

        this.break = {
            from: data.break_from ? stringToDateTime(data.break_from) : null,
            to: data.break_to ? stringToDateTime(data.break_to) : null,
        };

        this.businessDate = new BusinessDate(data.business_date);
        this.customerId = data.customer_id;
        this.employeeId = data.employee_id;
        this.from = stringToDateTime(data.from);
        this.to = stringToDateTime(data.to);
        this.leaveId = data.leave_id;
        this.leaveType = data.leave_type;

        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.created_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.created_at) : null;
    }

    get breakFrom(): DateTime | null {
        return this.break?.from ?? null;
    }

    set breakFrom(value: DateTime | null) {
        this.break.from = value;
    }

    get breakTo(): DateTime | null {
        return this.break?.to ?? null;
    }

    set breakTo(value: DateTime | null) {
        this.break.to = value;
    }

    get breakLength(): number | null {
        return this.breakFrom && this.breakTo ? this.breakTo.diff(this.breakFrom, 'seconds').seconds : null;
    }

    get breakLengthMinutes(): number | null {
        return this.breakLength ? this.breakLength / 60 : null;
    }

    set breakLength(value: number | null) {
        if (this.break.from != null && value != null) {
            this.break.to = this.break.from.plus({ seconds: value });
        } else {
            this.break.to = null;
        }
    }

    get breakOffset(): number | null {
        return this.break.from?.diff(this.from, 'seconds').seconds ?? null;
    }

    set breakOffset(value: number | null) {
        if (value != null) {
            this.break.from = this.from.plus({ seconds: value });
        } else {
            this.break.from = null;
            this.break.to = null;
        }
    }
}
