<eaw-dialog-header>
    <span title>{{data.title || ('DATE' | translate) | async}}</span>
    <span subtitle *ngIf="data.subtitle">{{data.subtitle | async}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="data.target === 'time'">
    <input #timeInput id="time" [formControl]="timeControl" (focus)="timeInput.select()" (blur)="timeBlur()">
</mat-dialog-content>

<mat-dialog-content *ngIf="data.target === 'date'">
    <mat-calendar [startAt]="date"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [selected]="date"
                  (selectedChange)="dateChange($event)"
                  [dateFilter]="dateFilter">
    </mat-calendar>

    <input #timeInput *ngIf="data.includeTime" id="time" [formControl]="timeControl" (focus)="timeInput.select()" (blur)="timeBlur()">
</mat-dialog-content>

<mat-dialog-content class="month-year-content" [class.target-month]="data.target === 'month'" *ngIf="data.target === 'month' || data.target === 'year'">
    <div id="years-container">
        <eaw-subheader>{{'YEAR' | translate | async}}</eaw-subheader>
        <mat-selection-list #yearsList id="years-list" [multiple]="false" (selectionChange)="combine()">
            <mat-list-option *ngFor="let year of years" [value]="year.year" [selected]="year.selected">{{year.year}}</mat-list-option>
        </mat-selection-list>
    </div>

    <div id="months-container" *ngIf="data.target === 'month'">
        <eaw-subheader>{{'MONTH' | translate | async}}</eaw-subheader>
        <mat-selection-list #monthsList id="months-list" [multiple]="false" (selectionChange)="combine()">
            <mat-list-option *ngFor="let month of months" [value]="month.month" [selected]="month.selected">{{month.name}}</mat-list-option>
        </mat-selection-list>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="tw-flex tw-flex-row">
    <button mat-raised-button (click)="close(false, true)" *ngIf="data.nullable">{{'CLEAR' | translate | async}}</button>
    <span class="tw-flex-1"></span>
    <button mat-raised-button (click)="close(true)">{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="false" (click)="close(false)" [disabled]="!dateTime">{{(data.confirmText || ('CONFIRM' | translate)) | async}}</eaw-action-button>
</mat-dialog-actions>
