import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { ShiftSwap } from '../../models/shift-swap';
import { ShiftSwapService } from '../../http/shift-swap.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { EmployeeService } from '../../../shared/http/employee.service';
import { Subscription } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SwapApplicant } from '../../models/swap-applicant';
import { TranslateService } from '../../../shared/services/translate.service';
import { EmployeeSwapSummary } from '../../models/employee-swap-summary';
import { ShiftSwapSummaryService } from '../../http/shift-swap-summary.service';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatDividerModule } from '@angular/material/divider';
import { SwapSummaryComponent } from '../../components/swap-summary/swap-summary.component';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface ApproveSwapDialogData extends DialogData {
    summaries?: Record<string | number, EmployeeSwapSummary>
    swap: ShiftSwap,
    applicant?: SwapApplicant,
}

@Component({
    selector: 'eaw-approve-swap-dialog',
    templateUrl: './approve-swap-dialog.component.html',
    styleUrl: './approve-swap-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatProgressSpinnerModule,
        MatListModule,
        NgFor,
        SwapSummaryComponent,
        MatDividerModule,
        MaterialColorDirective,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        KeyValuePipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class ApproveSwapDialogComponent extends DialogComponent<ApproveSwapDialogData> implements OnInit, OnDestroy {
    swap: ShiftSwap;
    commentCtrl: FormControl<string | null>;
    submitting = false;
    text!: string;
    summaries?: Record<string | number, EmployeeSwapSummary>;
    employeeNames: Record<string | number, string> = {};

    protected additionalSwaps: ShiftSwap[] = [];
    protected employeeSubscription?: Subscription;
    protected approveSwapSubscription?: Subscription;

    protected readonly employeeId: number;

    constructor(
        @Inject(ShiftSwapService) protected shiftSwapService: ShiftSwapService,
        @Inject(EmployeeService) protected employeeService: EmployeeService,
        @Inject(TranslateService) protected translate: TranslateService,
        @Inject(ShiftSwapSummaryService) protected summaryService: ShiftSwapSummaryService,
        @Inject(MatDialogRef) ref: MatDialogRef<ApproveSwapDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ApproveSwapDialogData,
    ) {
        data.size = DialogSize.Large;

        super(ref, data);

        this.swap = data.swap;
        this.commentCtrl = new FormControl('');

        if (this.swap.toId) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.employeeId = this.swap.toId!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.employeeNames[this.employeeId] = this.swap.toEmployee!.name!;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.employeeId = data.applicant!.employeeId!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.employeeNames[this.employeeId] = data.applicant!.employeeName!;
        }

        if (this.swap.fromEmployee) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.employeeNames[this.swap.fromEmployee.id] = this.swap.fromEmployee.name!;
        }

        if (data.summaries) {
            this.summaries = data.summaries;
        } else {
            this.summaryService.getSwapSummary(this.swap.customerId, this.swap.id).subscribe((summary) => {
                this.summaries = summary.reduce((carry, s) => {
                    carry[s.employeeId] = s;

                    return carry;
                }, {} as Record<string | number, EmployeeSwapSummary>);
            });
        }
    }

    getText() {
        if (this.swap.fromEmployee) {
            // Accept an employee's shift swap request with somebody else
            return this.translate.t('ACCEPT_SHIFT_SWAP', 'scheduling', {
                from: this.swap.fromEmployee.name,
                to: this.employeeNames[this.employeeId],
            });
        }

        // Accept an employee's wish to take an available shift
        return this.translate.t('ACCEPT_AVAILABLE_SHIFT_SWAP', 'scheduling', {
            name: this.employeeNames[this.employeeId],
        });
    }

    async ngOnInit() {
        this.text = await this.getText();
        if (this.swap.toId) {
            this.employeeSubscription = this.employeeService.get(this.swap.customerId, this.employeeId, { 'with[]': [ 'active_swaps_to' ] }).subscribe({
                next: (employee) => {
                    this.additionalSwaps = employee.activeSwapsTo.filter((s) => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        return s.fromId == this.swap.toId && this.swap.warnings!.find((w) => {
                            return (w.causes || []).find((cause) => cause.causeType == 'shift' && cause.causeId == s.shiftId);
                        });
                    });
                },
            });
        }
    }

    ngOnDestroy() {
        this.employeeSubscription?.unsubscribe();
    }

    submit() {
        const updates: { approved: true, to_id?: number, comment?: string } = {
            approved: true,
            comment: this.commentCtrl.value || undefined,
        };

        if (!this.swap.toId) {
            updates.to_id = this.employeeId;
        }

        this.submitting = true;
        this.commentCtrl.disable();

        this.approveSwapSubscription = this.shiftSwapService.update(this.swap.customerId, this.swap.id, updates).subscribe({
            next: (swap) => {
                this.dialogRef.close(swap);
            },
            error: () => {
                this.submitting = false;
            },
        });
    }
}
