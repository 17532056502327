import { t } from 'i18next';
import moment, { Moment } from 'moment-timezone';
import { module } from 'angular';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
import { EmployeeService } from '../../../../shared/http/employee.service';
import { ExternalEmployeeResponse } from '../../../../company/models/external-employee';
import { firstValueFrom } from 'rxjs';
import { UIRouter } from '@uirouter/core';

type AvailableLocation = { id: number, name: string };
module('eaw.payroll.paid-time').component('paidTimeEmployee', {
    template: `<item-legend items="$emp.legend" ng-show="$emp.showLegend" item-click="$emp.legendClick(item)"></item-legend>

<md-card id="employee-card">
    <md-card-content layout="column" layout-gt-sm="row" ng-class="{'tw-p-12': $emp.employeeProvided}">
        <div layout="row" flex="100" flex-gt-sm="50" ng-if="!$emp.employeeProvided">
            <eaw-profile-picture-old user="$emp.employee.user" square="true" size="85"></eaw-profile-picture-old>

            <employee-autocomplete from="$emp.interval.from"
                                   to="$emp.interval.to"
                                   ng-model="$emp.employee"
                                   ng-disabled="$emp.loading || $emp.gettingEmp"
                                   ng-change="$emp.employeeChange()"
                                   include-external="true">
            </employee-autocomplete>
        </div>

        <!-- use SHOW not IF here -->
        <eaw-date-interval ng-show="$emp.useRangeCalendar"
                           flex="100"
                           class="tw-pl-12"
                           flex-gt-sm="50"
                           layout="column"
                           layout-gt-xs="row"
                           ng-model="$emp.interval"
                           ng-change="$emp.intervalChange()">
        </eaw-date-interval>

        <md-input-container flex="100" flex-gt-sm="50" flex-gt-md="25">
            <label ng-i18next="LOCATION_plural"></label>
            <md-select ng-model="$emp.locations" multiple ng-disabled="$emp.gettingLocations || $emp.loading || $emp.gettingEmp" md-on-close="$emp.getOverview()">
                <md-option ng-repeat="location in $emp.availableLocations track by location.id" ng-value="location.id">
                    <span ng-bind="location.name"></span>
                </md-option>
            </md-select>
            <span ng-if="$emp.gettingLocations" class="hint" ng-i18next="payroll:UPDATING_LOCATION_plural"></span>
        </md-input-container>
    </md-card-content>
</md-card>

<md-card class="content-card">
    <md-card-header>
        <md-card-header-text layout="row" layout-align="start center">
            <md-button class="md-secondary md-icon-button left-btn" ng-click="$emp.changeDate(-1)" ng-disabled="$emp.useRangeCalendar">
                <md-icon ng-bind="'arrow_back'"></md-icon>
            </md-button>

            <md-button class="header-btn" ng-if="$emp.useRangeCalendar">
                <span class="md-title" ng-bind="$emp.interval.from | moment:'ll'"></span>
                <span class="md-subhead" ng-bind="$emp.interval.to | moment:'ll'"></span>
            </md-button>

            <md-button class="header-btn" ng-click="$emp.selectDate($event)" ng-if="!$emp.useRangeCalendar">
                <span class="md-title pointer-events-none" ng-bind="$emp.date | moment:'MMMM'"></span>
                <span class="md-subhead pointer-events-none" ng-bind="$emp.date | moment:'YYYY'"></span>
            </md-button>

            <md-button class="md-secondary md-icon-button right-btn" ng-click="$emp.changeDate(1)" ng-disabled="$emp.useRangeCalendar">
                <md-icon ng-bind="'arrow_forward'"></md-icon>
            </md-button>

            <md-button class="md-secondary md-icon-button" ng-click="$emp.selectDate($event)" ng-if="!$emp.useRangeCalendar">
                <md-icon ng-bind="'calendar_month'"></md-icon>
            </md-button>
        </md-card-header-text>

       <div layout="row" hide show-gt-sm>
           <md-button class="md-icon-button" ng-click="$emp.summary()">
               <md-icon ng-bind="'info'">
                   <md-tooltip ng-i18next="SUMMARY"></md-tooltip>
               </md-icon>
           </md-button>

           <eaw-toggle-btn ng-model="$emp.showEmpty"
                           icon="visibility"
                           ng-change="$emp.toggleEmpty($emp.showEmpty)"
                           i18n-tooltip="payroll:SHOW_EMPTY">
           </eaw-toggle-btn>

           <eaw-toggle-btn ng-model="$emp.showLegend"
                           icon="abc"
                           toggled-initially="true"
                           i18n-tooltip="LEGEND">
           </eaw-toggle-btn>

           <eaw-toggle-btn ng-model="$emp.useRangeCalendar"
                           icon="date_range"
                           ng-if="!$emp.employeeProvided"
                           ng-change="$emp.calendarToggle()"
                           i18n-tooltip="INTERVAL">
           </eaw-toggle-btn>
       </div>

        <md-menu hide-gt-sm>
            <md-button class="md-icon-button" ng-click="$mdMenu.open()">
                <md-icon ng-bind="'more_vert'"></md-icon>
            </md-button>

            <md-menu-content width="4">
                <md-menu-item>
                    <md-button ng-click="$emp.summary()">
                        <md-icon ng-bind="'info'"></md-icon>
                        <span ng-i18next="SUMMARY"></span>
                    </md-button>
                </md-menu-item>

                <md-menu-item>
                    <md-button ng-click="$emp.showEmpty = !$emp.showEmpty; $emp.toggleEmpty($emp.showEmpty)">
                        <md-icon ng-if="$emp.showEmpty" ng-bind="'visibility'"></md-icon>
                        <md-icon ng-if="!$emp.showEmpty" ng-bind="'visibility_off'"></md-icon>
                        <span ng-i18next="payroll:SHOW_EMPTY"></span>
                    </md-button>
                </md-menu-item>

                <md-menu-item>
                    <md-button ng-click="$emp.showLegend = !$emp.showLegend;">
                        <md-icon ng-if="$emp.showLegend" ng-bind="'abc'"></md-icon>
                        <md-icon ng-if="!$emp.showLegend" ng-bind="'abc'"></md-icon>
                        <span ng-i18next="LEGEND"></span>
                    </md-button>
                </md-menu-item>

                <md-menu-item ng-if="!$emp.employeeProvided">
                    <md-button ng-click="$emp.useRangeCalendar = !$emp.useRangeCalendar; $emp.calendarToggle()">
                        <md-icon ng-bind="'date_range'"></md-icon>
                        <span ng-i18next="INTERVAL"></span>
                    </md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </md-card-header>
    <md-card-content class="table-container">
        <div id="data-loader" ng-class="{'loading': $emp.loading}" ng-show="!$emp.updating && !$emp.employee.id">
            <md-icon ng-bind="'person'"></md-icon>
            <span class="md-subhead" eaw-invisible="{{$emp.employee.id}}" bo-i18next="payroll:CHOOSE_EMP_DATA"></span>
        </div>

        <div id="table-loading-spinner" ng-if="$emp.loading">
            <md-progress-circular></md-progress-circular>
        </div>

        <table ng-show="$emp.employee.id" id="employee-table" class="table table-condensed table-bordered sticky-left sticky-header"></table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$emp',
    bindings: {
        customer: '<',
        employee: '<',
    },
    controller: [ '$element', '$scope', '$uiRouter', '$timeout', 'EmployeeServiceDowngrade', 'EmployeePaidTimeSegment', 'componentCreatorService', 'AllComments', 'eawDatePickerDialog', 'EmployeePaidTime', function($element, $scope, $uiRouter: UIRouter, $timeout, EmployeeServiceDowngrade: EmployeeService, EmployeePaidTimeSegment, componentCreatorService, AllComments, eawDatePickerDialog, EmployeePaidTime) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = async () => {
            const locations = $uiRouter.globals.params['locations']?.split(',')
                .map((x: string) => parseInt(x))
                .filter((x: number) => !isNaN(x));

            const employeeId = $uiRouter.globals.params['e'];

            ctrl.locations = locations?.length ? locations : [ ctrl.customer.id ];
            ctrl.employeeProvided = !!ctrl.employee;
            ctrl.hours = 24;
            ctrl.legend = EmployeePaidTimeSegment.generateLegend();
            ctrl.lastLocations = [];
            ctrl.availableLocations = [ ctrl.customer ];

            if (employeeId) {
                ctrl.gettingEmp = true;
                await firstValueFrom(EmployeeServiceDowngrade.get(ctrl.customer.id, employeeId)).then((employee) => {
                    ctrl.employee = employee;
                    ctrl.gettingEmp = false;
                    ctrl.setTime(moment($uiRouter.globals.params['from']));

                    return ctrl.getLocations();
                });
            } else {
                ctrl.setTime(moment($uiRouter.globals.params['from']));
                ctrl.getOverview();
            }

            $element[0].classList.add('paid-time');
            ctrl.table = $element[0].querySelector('#employee-table');
            $scope.$on('paidtime:segment:change', () => ctrl.getOverview(true));
        };

        ctrl.legendClick = (item: any) => {
            const action: 'add' | 'remove' = [ 'add', 'remove' ][+item.selected] as 'add' | 'remove';

            Array.from<HTMLElement>(ctrl.table.querySelectorAll(`[data-type="${item.type.key}"]`)).forEach((el: HTMLElement) => {
                el.classList[action]('display-none');
            });
        };

        ctrl.summary = () => {
            EmployeePaidTime.showSummary(ctrl.empPaidTimes, ctrl.interval.from, ctrl.interval.to);
        };

        ctrl.setTime = (date: Moment) => {
            ctrl.date = date?.isValid() ? date : moment();
            ctrl.interval = {
                from: ctrl.date.clone().startOf('M'),
                to: ctrl.date.clone().endOf('M'),
            };
        };

        ctrl.sameLocations = () => {
            ctrl.locations = ctrl.locations.sort((a: number, b: number) => a - b);
            ctrl.lastLocations = ctrl.lastLocations.sort((a: number, b: number) => a - b);
            const sameItems = ctrl.locations.length === ctrl.lastLocations.length;
            const sameIds = ctrl.locations.every((id: number, i: number) => id === ctrl.lastLocations[i]);
            return sameItems && sameIds;
        };

        ctrl.getOverview = (force = false) => {
            $uiRouter.stateService.go($uiRouter.globals.current, {
                from: ctrl.interval.from.toDate(),
                to: ctrl.interval.to.toDate(),
                locations: ctrl.locations.join(','),
                e: ctrl.employee?.id,
            });

            if (!force) {
                if (!ctrl.locations.length) {
                    return;
                }
                if (ctrl.sameLocations()) {
                    return;
                } // Check if same locations as last
                if (!ctrl.employee?.id) {
                    return;
                }
                if (!ctrl.interval.from?.isValid()) {
                    return;
                }
                if (!ctrl.interval.to?.isValid()) {
                    return;
                }
            }
            delete ctrl.empPaidTimes;
            ctrl.loading = true;

            EmployeePaidTime.getOverview(ctrl.locations, ctrl.interval.from.clone(), ctrl.interval.to.clone(), ctrl.employee.id).then((resp: any) => {
                ctrl.lastLocations = ctrl.locations.slice();
                ctrl.empPaidTimes = resp;
                ctrl.offtimes = resp.offtimes;
                ctrl.buildTable();
            }).finally(() => delete ctrl.loading);
        };

        ctrl.getLocations = async () => {
            if (!ctrl.employee?.id) {
                return;
            }

            if (CurrentOld.can(`customers.${ctrl.customer.id}.employees.${ctrl.employee.id}.external_employees.*.get`)) {
                ctrl.gettingLocations = true;
                const resp = await EmployeePaidTime.getWorkedLocations(ctrl.customer.id, ctrl.employee.id, ctrl.interval.from, ctrl.interval.to).$promise;
                ctrl.gettingLocations = false;
                // Add current to response
                resp.data.push({
                    customer_id: ctrl.customer.id,
                    customer: ctrl.customer,
                });
                // Create map
                ctrl.availableLocations = new Map();
                // From response
                resp.data.forEach((location: ExternalEmployeeResponse) => {
                    ctrl.availableLocations.set(location.customer_id, {
                        id: location.customer_id,
                        name: location.customer!.name,
                    });
                });
                // Remove locations that are not available
                ctrl.availableLocations = sort(
                    Array.from<any>(ctrl.availableLocations),
                    CurrentOld.languageTag,
                    [ ([ _, value ]: [ unknown, AvailableLocation ]) => value.name ]).map((x) => x[1]);
            }
            // Get
            ctrl.getOverview();
        };

        ctrl.resetLastLocations = () => {
            ctrl.lastLocations = [];
        };

        ctrl.intervalChange = () => {
            if (!ctrl.useRangeCalendar) {
                return;
            }
            if (!ctrl.interval.from) {
                return;
            }
            if (!ctrl.interval.to) {
                return;
            }
            ctrl.resetLastLocations();
            ctrl.getLocations();
        };

        ctrl.calendarToggle = () => {
            // Don't do anything if it's toggled on
            if (ctrl.useRangeCalendar) {
                return;
            }
            ctrl.resetLastLocations();
            ctrl.setTime(ctrl.interval.from);
            ctrl.employeeChange();
        };

        ctrl.employeeChange = () => {
            if (!ctrl.employee?.id) {
                $uiRouter.stateService.go($uiRouter.globals.current, {
                    ...$uiRouter.globals.params,
                    e: null,
                });
                ctrl.locations = [ ctrl.customer.id ];
                delete ctrl.empPaidTimes;
                return;
            }
            $uiRouter.stateService.go($uiRouter.globals.current, {
                ...$uiRouter.globals.params,
                e: ctrl.employee.id,
            });
            ctrl.resetLastLocations();
            ctrl.getLocations();
        };

        ctrl.changeDate = (change: number) => {
            ctrl.date = ctrl.date.add(change, 'M').clone();
            $timeout.cancel(ctrl.timeout);
            ctrl.timeout = $timeout(() => {
                ctrl.setTime(ctrl.date);
                ctrl.clearTable();
                ctrl.resetLastLocations();
                ctrl.getLocations();
            }, 500);
        };

        ctrl.selectDate = (event: any) => {
            eawDatePickerDialog.open(ctrl.date.clone(), {
                focusOnOpen: true,
                mode: 'month',
                event,
                callback(date: Moment) {
                    if (date.isSame(ctrl.date, 'M')) {
                        return;
                    }
                    ctrl.setTime(date);
                    ctrl.resetLastLocations();
                    ctrl.getLocations();
                },
            });
        };

        ctrl.clearTable = () => {
            Array.from(ctrl.table.children).forEach((c: any) => c.remove());
        };

        ctrl.buildTable = () => {
            ctrl.clearTable();
            // Header
            const thead = document.createElement('thead');
            const theadTr = document.createElement('tr');
            // Date
            const dateTh = document.createElement('th');
            dateTh.innerText = t('DATE');
            dateTh.classList.add('date');
            theadTr.append(dateTh);
            // Comment / action
            const commentTh = document.createElement('th');
            theadTr.append(commentTh);
            // Button(s)
            const btnTh = document.createElement('th');
            btnTh.classList.add('buttons-cell');
            theadTr.append(btnTh);
            // Times
            for (let i = 0; i < ctrl.hours; i++) {
                const th = document.createElement('th');
                th.innerText = moment().startOf('d').add(i, 'h').format('LT');
                th.classList.add('time-th');
                theadTr.appendChild(th);
            }
            // Table built
            thead.appendChild(theadTr);
            ctrl.table.appendChild(thead);
            // Insert rows per date
            Object.values<Record<string, any>>(ctrl.empPaidTimes).forEach((dates: Record<string, any>) => {
                Object.entries(dates).forEach(([ date, empPaidTime ]: [ string, any ]) => {
                    ctrl.insertDate(date, empPaidTime);
                });
            });
            // Do the empty filtering immediately
            ctrl.toggleEmpty(ctrl.showEmpty ?? false);
        };

        ctrl.toggleEmpty = (showEmpty = true) => {
            const bodies = Array.from<HTMLElement>(ctrl.table.querySelectorAll('tbody'));
            if (showEmpty) {
                bodies.forEach((el: HTMLElement) => el.style.display = 'revert');
                return;
            }
            bodies.forEach((el) => {
                const hasStuff = el.querySelectorAll('.absence-marker, .segment').length;
                el.style.display = hasStuff ? 'revert' : 'none';
            });
        };

        /**
         * @param {moment.Moment} date
         * @param {EmployeePaidTime} empPaidTime
         */
        ctrl.createDateTd = (date: Moment, empPaidTime: any) => {
            const td = document.createElement('td');
            td.classList.add('date');
            // Date div
            const dateDiv = document.createElement('div');
            dateDiv.innerText = date.format('dd [-] ll');
            td.appendChild(dateDiv);
            // Difference div
            td.appendChild(empPaidTime.generateDifferenceEl(date));
            // Warning/info about active timepunch
            td.appendChild(empPaidTime.generateOngoingEl());
            return td;
        };

        /**
         * @param {moment.Moment} date
         * @param {EmployeePaidTime} empPaidTime
         */
        ctrl.createStatusTd = (date: Moment, empPaidTime: any) => {
            const td = document.createElement('td');
            td.classList.add('employee-status');
            // Icon for day absence
            empPaidTime.generateAbsenceTooltipIcon(td, date);
            // Icon for vacation
            empPaidTime.generateOfftimeTooltipIcon(td, date, ctrl.offtimes);
            return td;
        };
        /**
         * @param {EmployeePaidTime} empPaidTime
         */
        ctrl.createCommentsTd = (empPaidTime: any) => {
            const td = document.createElement('td');
            td.classList.add('buttons-cell');
            if (!empPaidTime.hasComments) {
                return td;
            }
            const comp = componentCreatorService.create({
                name: 'eawIconButton',
                bindings: {
                    tooltip: t('COMMENT_plural'),
                    icon: [ 'comment', 'string' ],
                },
            });
            comp.element.dataset.gaItem = `Paid time day comments button`;
            comp.element.addEventListener('click', (e: Event) => {
                e.stopPropagation();
                AllComments.open(empPaidTime);
            });
            td.appendChild(comp.element);
            return td;
        };

        /**
         * @param {moment.Moment} date
         * @param {EmployeePaidTime} empPaidTime
         */
        ctrl.createSegmentsTd = (date: Moment, empPaidTime: any) => {
            const td = document.createElement('td');
            td.colSpan = 99;
            td.classList.add('segments');
            empPaidTime.segments.forEach((seg: any) => {
                td.appendChild(seg.createElement(date, ctrl.employeeProvided));
            });
            return td;
        };
        /**
         * @param {string} date
         * @param {EmployeePaidTime} empPaidTime
         */
        ctrl.insertDate = (date: string, empPaidTime: any) => {
            const mom = EmployeePaidTime.stringToDate(date);
            if (mom.isBefore(ctrl.interval.from, 'd')) {
                return;
            }
            if (mom.isAfter(ctrl.interval.to, 'd')) {
                return;
            }
            const tbody = document.createElement('tbody');
            const tr = document.createElement('tr');

            tbody.dataset['date'] = date;

            tr.appendChild(ctrl.createDateTd(mom, empPaidTime));
            tr.appendChild(ctrl.createStatusTd(mom, empPaidTime));
            tr.appendChild(ctrl.createCommentsTd(empPaidTime));
            tr.appendChild(ctrl.createSegmentsTd(mom, empPaidTime));

            tbody.appendChild(tr);

            ctrl.table.appendChild(tbody);
        };
    } ],
});
