import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { WebsocketService } from '../../services/websocket.service';
import { SystemAlert, SystemAlertResponse } from '../../models/system-alert';
import { SystemAlertService } from '../../http/system-alert.service';
import { switchMap, take, timer } from 'rxjs';
import { Duration } from 'luxon';
import { UserAlertService } from '../../services/user-alert.service';
import { MatIconModule } from '@angular/material/icon';
import { NgFor } from '@angular/common';

@Component({
    selector: 'eaw-system-alerts',
    templateUrl: './system-alerts.component.html',
    styleUrl: './system-alerts.component.scss',
    standalone: true,
    imports: [ NgFor, MatIconModule ],
})
export class SystemAlertsComponent implements OnInit {
    alerts: SystemAlert[] = [];

    constructor(
        @Inject(WebsocketService) websocketService: WebsocketService,
        @Inject(SystemAlertService) private systemAlertService: SystemAlertService,
        @Inject(UserAlertService) private userAlertService: UserAlertService,
        @Inject(DestroyRef) destroyRef: DestroyRef,
    ) {
        websocketService.listenSystemAlerts(this.handleAlert.bind(this), destroyRef);
    }

    ngOnInit() {
        this.userAlertService.onAlert().pipe(take(1)).subscribe(this.handleAlert.bind(this));

        timer(Duration.fromObject({ seconds: 5 }).as('milliseconds')).pipe(
            take(1),
            switchMap(() => this.systemAlertService.getAll()),
        ).subscribe((res) => {
            this.alerts = res.data;
        });
    }

    handleAlert(event: SystemAlertResponse) {
        const alert = new SystemAlert(event);
        this.alerts = this.alerts.filter((a) => a.id !== alert.id);

        if (!alert.deletedAt) {
            this.alerts.push(alert);
        }
    }
}
