import { ChangeDetectionStrategy, Component, computed, inject, OnInit, Signal, signal } from '@angular/core';
import { StateObject, UIRouter } from '@uirouter/core';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { ComponentType } from '@angular/cdk/overlay';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';

interface StateItem {
    component?: string;
    controller?: string;
    upgraded: boolean;
    state: StateObject;
    view: string;
}

@Component({
    selector: 'eaw-admin-upgrade-progress',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCard,
        MatSlideToggle,
        ReactiveFormsModule,
        MatCardContent,
        TranslatePipe,
        AsyncPipe,
    ],
    templateUrl: './admin-upgrade-progress.component.html',
    styleUrl: './admin-upgrade-progress.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUpgradeProgressComponent implements OnInit {
    private readonly uiRouter = inject(UIRouter);

    items = signal([] as StateItem[]);
    subtitle: Signal<string>;
    hideUpgraded = new FormControl(false, { nonNullable: true });

    constructor() {
        this.subtitle = computed(() => {
            const upgraded = this.items().filter((item) => item.upgraded).length;
            const total = this.items().length;

            return `${upgraded} of ${total} states upgraded`;
        });
    }

    ngOnInit() {
        const states = this.uiRouter.stateRegistry['states'] as Record<string, StateObject>;

        Object.values(states).forEach((state) => {
            const views = state.views;

            if (!views) {
                console.error('No views found for state', state);
                return;
            }

            Object.entries(views).forEach(([ viewName, view ]) => {
                const item: StateItem = {
                    state,
                    view: viewName,
                    upgraded: false,
                };

                if ('component' in view) {
                    if (typeof view.component === 'string') {
                        item.component = view.component;
                    } else {
                        item.component = (view.component as ComponentType<any>).name.replace('_', '');
                        item.upgraded = true;
                    }
                }

                if ('controller' in view) {
                    item.controller = String(view.controller);
                }

                if (!state.self.abstract || (state.self.abstract && (item.component || item.controller))) {
                    this.items.update((items) => (items.concat(item)));
                }
            });
        });

        this.items.update((items) => sort(items, 'en', [ (state) => state.state.name ]));
    }
}
