import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { Comment, CommentResponse } from '../../shared/models/comment';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class EmployeeCommentService {
    private http = inject(HttpClient);

    create(customer: number, employee: number, text: string): Observable<Comment> {
        return this.http.post<CommentResponse>(`customers/${customer}/employees/${employee}/comments`, { body: text })
            .pipe(classifyItem(Comment));
    }

    get(customer: number, employee: number, comment: number, options?: { 'with[]': string[] }): Observable<Comment> {
        return this.http.get<CommentResponse>(`customers/${customer}/employees/${employee}/comments/${comment}`, {
            params: {
                ...options,
            },
        }).pipe(classifyItem(Comment));
    }

    getAll(customer: number, employee: number, pagination: PaginationOptions = {}): Observable<ArrayPaginatedResponse<Comment>> {
        return this.http.get<ArrayPaginatedResponse<CommentResponse>>(`customers/${customer}/employees/${employee}/comments`, { params: { ...pagination } })
            .pipe(classifyArrayPaginatedResponse(Comment));
    }

    update(customer: number, employee: number, comment: number, text: string): Observable<Comment> {
        return this.http.put<CommentResponse>(`customers/${customer}/employees/${employee}/comments/${comment}`, { body: text })
            .pipe(classifyItem(Comment));
    }

    delete(customer: number, employee: number, comment: number): Observable<undefined> {
        return this.http.delete<undefined>(`customers/${customer}/employees/${employee}/comments/${comment}`);
    }
}
