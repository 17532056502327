import { Inject, Injectable } from '@angular/core';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { DateTime } from 'luxon';
import { PaidTime, PaidTimeResponse } from '../../paid-time/models/paid-time';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { HttpClient, HttpContext } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { PaidTimeOverview, PaidTimeOverviewResponse } from '../../paid-time/models/paid-time-overview';
import { map, Observable } from 'rxjs';
import { AbsenceType } from '../../absence/models/absence-type';
import { Absence } from '../../absence/models/absence';
import { Timepunch } from '../models/timepunch';
import { Shift } from '../../scheduling/models/shift';
import { Employee } from '../../shared/models/employee';
import { OffTime } from '../../vacations/models/off-time';
import { Customer } from '../../shared/models/customer';
import { BUSINESS_DATES } from '../../shared/http/http-contexts';
import { LeaveShift } from '../../leave-shifts/models/leave-shift';
import { CustomFieldKeyValues } from '../../shared/utils/custom-fields-group';

type CreateOptions = {
    from: DateTime,
    to: DateTime,
    businessDate: DateTime,
    businessUnitId?: number | null,
    comment?: string | null,
    customFields?: CustomFieldKeyValues,
};

type UpdateOptions = {
    from?: DateTime,
    to?: DateTime,
    businessDate?: DateTime,
    businessUnitId?: number | null,
    comment?: string | null,
    customFields?: CustomFieldKeyValues,
};

@Injectable({
    providedIn: 'root',
})
export class PaidTimeService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAllForEmployee(customerId: number, employeeId: number, from: DateTime, to: DateTime, options?: PaginationOptions, context?: HttpContext) {
        return this.http.get<ArrayPaginatedResponse<PaidTimeResponse>>(`/customers/${customerId}/employees/${employeeId}/paid_times`, {
            params: formatHttpParams({
                ...options,
                from,
                to,
            }),
            context,
        }).pipe(classifyArrayPaginatedResponse(PaidTime));
    }

    getAllForCustomer(customerId: number, from: DateTime, to: DateTime, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<PaidTimeResponse>>(`/customers/${customerId}/paid_times`, {
            params: formatHttpParams({
                ...options,
                from,
                to,
            }),
        }).pipe(classifyArrayPaginatedResponse(PaidTime));
    }

    getOverview(customerId: number, from: DateTime, to: DateTime, employeeId?: number): Observable<PaidTimeOverview> {
        const url = typeof employeeId === 'number' ?
            `/customers/${customerId}/employees/${employeeId}/paid_times/overview` :
            `/customers/${customerId}/paid_times/overview`;

        return this.http.get<PaidTimeOverviewResponse>(url, {
            params: formatHttpParams({
                // Subtract one day from "from" because there can be items on the previous day that overlap or have business date on a valid date
                from: from.minus({ days: 1 }),
                // Add one day to "to" because there can be items on the next day that overlap or have business date on a valid date
                to: to.plus({ days: 1 }),
            }),
        }).pipe(
            map((response) => {
                // Classify all items here because we only want to do it one time, instead of inside the class every time
                return new PaidTimeOverview(customerId, {
                    absence_types: response.absence_types.map((item) => new AbsenceType(item)),
                    absences: response.absences.map((item) => new Absence(item)),
                    customers: response.customers.map((item) => new Customer(item)),
                    employees: response.employees.map((item) => new Employee(item)),
                    offtimes: response.offtimes.map((item) => new OffTime(item)),
                    paid_times: response.paid_times.map((item) => new PaidTime(item)),
                    shifts: response.shifts.map((item) => new Shift(item)),
                    timepunches: response.timepunches.map((item) => new Timepunch(item)),
                    leaveShifts: response.leave_shifts.map((item) => new LeaveShift(item)),
                }, {
                    from,
                    to,
                    employeeId,
                });
            }),
        );
    }

    create(customerId: number, employeeId: number, createOptions: CreateOptions) {
        return this.http.post<PaidTimeResponse>(`/customers/${customerId}/employees/${employeeId}/paid_times`, {
            from: createOptions.from,
            to: createOptions.to,
            business_date: createOptions.businessDate,
            comment: createOptions.comment,
            business_unit_id: createOptions.businessUnitId == null ? undefined : createOptions.businessUnitId,
            ...createOptions.customFields,
        }, {
            context: new HttpContext().set(BUSINESS_DATES, [ 'business_date' ]),
        }).pipe(classifyItem(PaidTime));
    }

    update(customerId: number, employeeId: number, paidTimeId: number, updateOptions: UpdateOptions) {
        return this.http.put<PaidTimeResponse>(`/customers/${customerId}/employees/${employeeId}/paid_times/${paidTimeId}`, {
            from: updateOptions.from,
            to: updateOptions.to,
            business_date: updateOptions.businessDate,
            comment: updateOptions.comment,
            business_unit_id: updateOptions.businessUnitId,
            ...updateOptions.customFields,
        }, {
            context: new HttpContext().set(BUSINESS_DATES, [ 'business_date' ]),
        }).pipe(classifyItem(PaidTime));
    }

    delete(customerId: number, employeeId: number, id: number) {
        return this.http.delete<undefined>(`/customers/${customerId}/employees/${employeeId}/paid_times/${id}`);
    }
}
