<eaw-page-header [buttons]="buttons">
    <span title>{{(mode && modes[mode]) | async}}</span>
</eaw-page-header>

<eaw-employee-shifts-list *ngIf="mode === 'list'"
                          [customerId]="customerId"
                          [employeeId]="employeeId"
                          [canCreateSwap]="canCreateSwap"
                          [requestSwap]="requestSwap"
                          [withdrawSwap]="withdrawSwap"
                          [canWithdrawSwap]="canWithdrawSwap"
                          [showComments]="showComments">
</eaw-employee-shifts-list>

<eaw-employee-shifts-table *ngIf="mode === 'table'"
                           [customerId]="customerId"
                           [employeeId]="employeeId"
                           [canCreateSwap]="canCreateSwap"
                           [requestSwap]="requestSwap"
                           [from]="shiftsFromDate"
                           [withdrawSwap]="withdrawSwap"
                           [canWithdrawSwap]="canWithdrawSwap"
                           [showComments]="showComments">
</eaw-employee-shifts-table>
