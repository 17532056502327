// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
module('eaw.company.users').component('userGroups', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-i18next="USER_GROUP_plural"></span>
        </md-card-header-text>
        <card-btn-float on-click="$groups.addToGroup()"></card-btn-float>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <eaw-dynamic-ng-table
                pagination="$groups.defaultPagination"
                columns="$groups.columns"
                cells="$groups.cells"
                go-to="{{$groups.goTo}}"
                get-data="$groups.getData(pagination)"
                re-render="$groups.reRender"
                reload-table="$groups.reload">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$groups',
    bindings: {
        customer: '<',
        user: '<',
    },
    controller: [ 'CustomerService', 'userGroupDialog', function userGroups(CustomerService, userGroupDialog) {
        this.$onInit = () => {
            this.goTo = this.customer.id == CurrentOld.customer.id ? 'eaw/app/company/user_group/members' : null;
            this.defaultPagination = { order_by: { to: 'asc' } };
        };
        this.getData = (pagination) => CustomerService.getAllUserGroups({
            ...pagination.toWithPaginatorArguments(),
            customer_id: this.customer.id,
            user_id: this.user.id,
        });
        this.addToGroup = () => userGroupDialog.add(this.customer, undefined, this.user).then(() => this.reload = true);
        this.columns = [
            {
                title: t('NAME'),
                sortable: 'name',
            },
            {
                title: t('FROM'),
                sortable: 'from',
            },
            {
                title: t('TO'),
                sortable: 'to',
            },
            { title: '' },
        ];
        this.cells = [
            { template: 'item.name' },
            { template: 'item.pivot.from.format("LL LT")' },
            { template: 'item.pivot.to.format("LL LT")' },
            {
                buttons: [
                    {
                        icon: 'delete',
                        type: 'warn',
                        invisible: (item) => !CurrentOld.can(`customers.${this.customer.id}.user_groups.${item.id}.members.${this.user.id}.remove`) || item.pivot.to && item.pivot.to.isBefore(moment()),
                        click: (group) => {
                            userGroupDialog.remove(group, this.user).then(() => {
                                this.reload = true;
                            });
                        },
                    },
                    {
                        icon: 'edit',
                        invisible: (item) => !CurrentOld.can(`customers.${this.customer.id}.user_groups.${item.id}.members.${this.user.id}.update`) || item.pivot.to && item.pivot.to.isBefore(moment()),
                        click: (group) => userGroupDialog.edit(group, {
                            pivot: group.pivot,
                            id: this.user.id,
                        }).then(() => {
                            this.reload = true;
                        }),
                    },
                ],
            },
        ];
    } ],
});
