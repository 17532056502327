<eaw-dialog-header>
    <span title>{{ 'ADD_GROUP' | translate:'admin' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form">
        <eaw-autocomplete formControlName="customerGroup"
                          [disabled]="loading()"
                          [disableItem]="customerFilter"
                          [options]="customerGroupAutocompleteService.options"
                          [setter]="customerGroupAutocompleteService.setter()"
                          [getter]="customerGroupAutocompleteService.getter()">
        </eaw-autocomplete>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [loading]="loading()" [disabled]="form.invalid"
                       (click)="submit()">{{ 'SUBMIT' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
