// @ts-nocheck
import { module } from 'angular';

const stacksTemplate = `<md-card>
    <md-card-content layout="column">
        <form name="searchStacksForm" autocomplete="off" novalidate>
            <md-input-container>
                <label ng-i18next="KEY"></label>
                <input ng-change="stacksCtrl.search()" ng-model="stacksCtrl.searchValue" ng-model-options="{debounce: 300}">
            </md-input-container>
        </form>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="admin:STACK_plural"></span>
        </md-card-header-text>

        <card-btn-float on-click="stacksCtrl.openNewStackModal()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="stacksCtrl.table.columns"
                cells="stacksCtrl.table.cells"
                factory="StackService"
                factory-function="getAllPaginated"
                reload-table="stacksCtrl.reloadTable"
                go-to="eaw/app/admin/stacks/view"
                factory-function-arguments="{
                    filter: stacksCtrl.searchValue
                }">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;
const stackIdTemplate = `<div layout="row" layout-padding layout-wrap>
    <div layout="column" flex-gt-lg="25" flex-gt-md="40" flex-gt-sm="50" flex="100">
        <div class="dash-widget-item" md-colors="{background: 'eaw-primary'}">
            <div class="dash-widget-header">
                <div class="tw-p-20">
                    <div map-lazy-load="https://maps.google.com/maps/api/js?key=AIzaSyCD7QxCvJ8JzXoex_RwZ98WqTZmv1aRBmk" map-lazy-load-params="{{stackCtrl.googleMapsUrl}}">
                        <ng-map center="47.086104, 1.351141"
                                zoom="4"
                                disable-default-u-i="true"
                                draggable="false"
                                disable-double-click-zoom="true">
                        </ng-map>
                    </div>
                </div>
            </div>

            <div class="tw-p-20">
                <small ng-i18next="REGION"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.region"></h3>
                <br>

                <small ng-i18next="NAME"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.name"></h3>
                <br>

                <small ng-i18next="admin:CUSTOMER_plural"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.customers_count | eawNumber"></h3>
                <br>

                <small ng-i18next="CREATED"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.created_at | moment"></h3>
                <br>

                <small ng-i18next="UPDATED"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.updated_at | moment"></h3>
                <br>

                <small ng-i18next="PROVISIONED"></small>
                <h3 class="tw-m-0 f-400" ng-bind="stackCtrl.stack.provisioned | moment"></h3>

                <div class="p-t-25" layout="row" layout-align="space-between center">
                    <md-button class="md-raised" ng-click="stackCtrl.edit()" ng-i18next="EDIT"></md-button>
                    <md-button class="md-raised" ng-i18next="PROVISION"></md-button>
                    <md-button class="md-raised md-warn" ng-hide="stackCtrl.stack.customers_count" ng-click="stackCtrl.deleteStack()" ng-i18next="DELETE"></md-button>
                </div>
            </div>
        </div>
    </div>

    <div layout="column" flex-gt-lg="75" flex-gt-md="60" flex-gt-xs="50" flex="100">
        <md-tabs md-dynamic-height>
            <md-tab label="{{'CUSTOMER_plural' | i18next}}">
                <md-card>
                    <md-card-header>
                        <md-card-header-text>
                            <span class="md-title" bo-i18next="CUSTOMER_plural"></span>
                        </md-card-header-text>
                    </md-card-header>
                    <md-card-content>
                        <eaw-dynamic-ng-table
                                columns="stackCtrl.customersTable.columns"
                                cells="stackCtrl.customersTable.cells"
                                go-to="eaw/app/admin/customers/view/info|id=id"
                                factory="StackService"
                                factory-function-arguments="{
                                    id: stackCtrl.stack.id,
                                    subQuery: 'customers'
                                }"
                                factory-function="getCustomersByIdPaginated"
                                reload-table="stackCtrl.reloadCustomersTable">
                        </eaw-dynamic-ng-table>
                    </md-card-content>
                </md-card>
            </md-tab>

            <md-tab label="{{'PROPERTY_plural' | i18next}}">
                <md-card>
                    <md-card-header>
                        <md-card-header-text>
                            <span class="md-title" bo-i18next="PROPERTY_plural"></span>
                        </md-card-header-text>

                        <card-btn-float on-click="stackCtrl.openNewPropertyDialog()"></card-btn-float>
                    </md-card-header>
                    <md-card-content>
                        <eaw-dynamic-ng-table
                                columns="stackCtrl.propertiesTable.columns"
                                cells="stackCtrl.propertiesTable.cells"
                                factory="StackService"
                                factory-function-arguments="{
                                    id: stackCtrl.stack.id,
                                    subQuery: 'properties'
                                }"
                                factory-function="getProperties"
                                reload-table="stackCtrl.reloadPropertiesTable">
                        </eaw-dynamic-ng-table>
                    </md-card-content>
                </md-card>
            </md-tab>
        </md-tabs>
    </div>
</div>
`;

module('eaw.admin').config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state(`eaw/app/admin/stacks`, {
        parent: 'eaw/app/admin',
        url: '/stacks',
        abstract: true,

    });

    $stateProvider.state(`eaw/app/admin/stacks/list`, {
        parent: `eaw/app/admin/stacks`,
        url: '/list',
        views: {
            'content@': {
                controller: 'adminStacksCtrl',
                controllerAs: 'stacksCtrl',
                template: stacksTemplate,
            },
        },
        data: {
            breadcrumb: { key: 'STACK_plural', ns: 'admin' },
            permissions: [ `stacks.*.get` ],
        },
    });

    $stateProvider.state(`eaw/app/admin/stacks/view`, {
        parent: `eaw/app/admin/stacks`,
        url: '/:id',
        views: {
            'content@': {
                template: stackIdTemplate,
                controller: 'adminStacksIdCtrl',
                controllerAs: 'stackCtrl',
            },
        },
        data: {
            breadcrumb: { key: 'STACK', ns: 'admin' },
        },
        resolve: {
            stack: [ 'StackService', '$stateParams', function(StackService, $stateParams) {
                return StackService.getById($stateParams.id).$promise.then((data) => data);
            } ],
        },
    });
} ]);
