import { Component, inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { FormSubmissionNoteService } from '../../http/form-submission-note.service';
import { FormSubmissionService } from '../../http/form-submission.service';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { FormSubmissionNote } from '../../models/form-submission-note';
import { catchError, forkJoin, of } from 'rxjs';
import { FormSubmission } from '../../models/form-submission';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { MatButton } from '@angular/material/button';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { CurrentService } from '../../../shared/services/current.service';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatIcon } from '@angular/material/icon';
import { ProfilePictureComponent } from '../../../shared/components/profile-picture/profile-picture.component';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { CommentListComponent } from '../../../shared/components/comment-list/comment-list.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';
import { DateTime } from 'luxon';
import { User } from '../../../shared/models/user';

export interface FormSubmissionNoteDialogData extends DialogData {
    customerId: number;
    submission: FormSubmission;
    showHideCheckbox: boolean;
}

@Component({
    selector: 'eaw-form-submission-note-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        MatDialogActions,
        MatDialogContent,
        MatButton,
        MatDialogClose,
        InfoLoadingComponent,
        MatExpansionModule,
        MatFormFieldModule,
        MatInput,
        ActionButtonComponent,
        MatCheckbox,
        ReactiveFormsModule,
        CheckboxHelperDirective,
        MatIcon,
        ProfilePictureComponent,
        DateTimePipe,
        CommentListComponent,
    ],
    templateUrl: './form-submission-note-dialog.component.html',
    styleUrl: './form-submission-note-dialog.component.scss',
})
export class FormSubmissionNoteDialogComponent extends DialogComponent implements OnInit {
    private readonly noteService = inject(FormSubmissionNoteService);
    private readonly formSubmissionService = inject(FormSubmissionService);
    private readonly permissionCheckService = inject(PermissionCheckService);
    private readonly translate = inject(TranslateService);
    protected readonly current = inject(CurrentService);
    protected override readonly data: FormSubmissionNoteDialogData;

    bodyControl = new FormControl<string>('', { nonNullable: true });
    visibleControl = new FormControl<boolean>(false, { nonNullable: true });

    notes: FormSubmissionNote[] = [];
    submission: FormSubmission = this.data.submission;
    canCreate = false;
    loading = true;
    submitting = false;

    constructor() {
        const data: FormSubmissionNoteDialogData = inject(MAT_DIALOG_DATA);
        data.size = DialogSize.Medium;
        super(inject(MatDialogRef), data);
        this.data = data;
    }

    ngOnInit() {
        forkJoin([
            this.formSubmissionService.get({
                customerId: this.data.customerId,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                formId: this.submission.formId!,
                submissionId: this.submission.id,
                with: [ 'notes.user' ],
            }),
            this.permissionCheckService.isAllowed(`customers.${this.data.customerId}.forms.${this.submission.formId}.submissions.${this.submission.id}.notes.create`, {
                stackId: this.current.getCustomer().stackId,
                models: [
                    { id: this.data.customerId, type: ApiModel.Customer },
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    { id: this.submission.formId!, type: ApiModelClass.Form },
                    { id: this.submission.id, type: ApiModelClass.FormSubmission },
                ],
            }),
        ]).subscribe(([ submission, canCreate ]) => {
            this.notes = submission.notes || [];

            this.canCreate = canCreate && !submission.closedAt;
            this.notes.forEach(this.handleAnonymousUserAndHiddenNote.bind(this));
            this.loading = false;
        });
    }

    notesAsComments() {
        return this.notes.map((note) => note.toComment());
    }

    async handleAnonymousUserAndHiddenNote(note: FormSubmissionNote) {
        if (!note.user) {
            note.user = new User({ id: 0, first_name: await this.translate.t('ANONYMOUS', 'forms'), last_name: '', email: '', language_code: '' });
        }
        note.body += note.isVisible ? '' : `\n\n[${await this.translate.t('NOTE_NOT_VISIBLE', 'forms')}]`;
    }

    createNote() {
        this.submitting = true;
        this.bodyControl.disable();
        this.visibleControl.disable();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.noteService.create(this.data.customerId, this.submission.formId!, this.submission.id, this.bodyControl.value, !this.visibleControl.value)
            .pipe(catchError(() => of(undefined))).subscribe(async (note) => {
                this.bodyControl.enable();
                this.visibleControl.enable();
                this.submitting = false;
                if (!note) {
                    return;
                }

                this.bodyControl.setValue('');
                this.visibleControl.setValue(false);
                note.createdAt = DateTime.now();
                if (note.userId) {
                    note.user = this.current.getUser();
                }
                await this.handleAnonymousUserAndHiddenNote(note);

                this.notes.push(note);
            });
    }
}
