<eaw-widget-settings-dialog [widgetInfo]="data.widgetInfo" [valid]="formControl.valid" (submitted)="submitted()">
    <div content class="tw-flex tw-flex-col">
        <mat-form-field>
            <mat-label>{{ 'DAY' | translate | async }}</mat-label>
            <mat-select [formControl]="formControl">
                <mat-option [value]="0">{{ 'TODAY' | translateSync }}</mat-option>
                <mat-option [value]="1">{{ 'TOMORROW' | translateSync }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</eaw-widget-settings-dialog>
