import { orderBy } from 'lodash-es';
import { module } from 'angular';
import { environment } from '../../../../../environments/environment';
import { CurrentOld } from '../../current.factory';
import { StateObject, UIRouter } from '@uirouter/core';
import { CustomerProductService } from '../../../http/customer-product.service';
import { lastValueFrom } from 'rxjs';
import { Products } from '../../../enums/products';

export interface EawNavBarTab {
    src: string;
    label: string;
    show?: boolean;
    permissions?: string[];
    /**
     * @deprecated
     * @see permissions
     * */
    permission?: string;
    state?: StateObject;
}

/**
 * @ngdoc component
 * @name eaw.component:eawNavBar
 *
 * @scope
 * @param {EawNavBarTab[]} [tabs]
 * @param {string} [tabs[*].src] the route for the tab.
 * @param {string} [tabs[*].label] the heading for the tab.
 * @param {bool} [tabs[*].show] if the tab should be shown.
 * @param {string} [tabs[*].permission=undefined] the permission required to show the tab.
 *
 */
module('eaw.components').component('eawNavBar', {
    template: `<md-nav-bar md-selected-nav-item="$eawNav.selectedNavItem" ng-show="$eawNav.tabs.length > 1">
    <md-nav-item ng-repeat="tab in ::$eawNav.tabs track by $index"
                 name="{{tab.src}}"
                 md-nav-sref="{{tab.src}}"
                 sref-opts="tab.options">
        <span ng-i18next="{{::tab.label}}"></span>
    </md-nav-item>
</md-nav-bar>
<ui-view name="{{::$eawNav.viewName}}">
    <strong md-colors="{color: 'red'}" ng-if="$eawNav.isDev">
        No content in view!
    </strong>
</ui-view>
`,
    bindings: {
        navbarRoute: '<',
        tabs: '<',
        customer: '<?',
        viewName: '<?',
        transition: '<',
    },
    controllerAs: '$eawNav',
    controller: [ '$uiRouter', '$element', 'CustomerProductServiceDowngrade', function($uiRouter: UIRouter, $element: JQLite, CustomerProductServiceDowngrade: CustomerProductService) {
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = async () => {
            if (!ctrl.navbarRoute) {
                console.error('navbarRoute is required!');
            }

            ctrl.isDev = !environment.isProduction;
            // ctrl.selectedNavItem = $uiRouter.globals.current;
            if (!ctrl.customer) {
                ctrl.customer = CurrentOld.customer;
            }

            // Load the products for the customer, only cached values are used in customer.hasProduct, actual products checked is not relevant
            await lastValueFrom(CustomerProductServiceDowngrade.hasProductsDetailed(ctrl.customer.id, [ Products.Reports, Products.HumanResources, Products.Onboarding ]));

            ctrl.addTabStates();
            ctrl.filterTabs();
            ctrl.checkCurrentState();
            ctrl.checkChild();
            ctrl.setSelectedNavItem();
            // Add special class if we only have one tab
            if (ctrl.tabs?.length === 1) {
                $element[0]?.classList.add('single-tab');
            }
        };
        // If the state we are on currently is the same as the navbar state
        // then go to the first available tab
        ctrl.checkCurrentState = () => {
            if ($uiRouter.globals.current.name === ctrl.navbarRoute) {
                ctrl.goToTab(ctrl.tabs[0]);
            }
        };

        ctrl.checkChild = () => {
            // Is the current state a child state of the navbar?
            if (!ctrl.isNavbarChild()) {
                // Is the state we are on now actually in the tabs?
                if (!ctrl.isTabChildSome()) {
                    // It's not there
                    // Go to first tab if possible, or home
                    ctrl.selectedNavItem = ctrl.tabs[0]?.src || 'eaw/app/home';
                    $uiRouter.stateService.go(ctrl.selectedNavItem);
                }
            }
        };

        ctrl.addTabStates = () => {
            ctrl.tabs.forEach((t: any) => {
                t.state = $uiRouter.stateService.get(t.src)?.$$state?.();
            });
        };

        ctrl.filterTabs = () => {
            ctrl.tabs = ctrl.tabs.filter(ctrl.checkShow);
            ctrl.tabs = ctrl.tabs.filter(ctrl.checkPermission);
            ctrl.tabs = ctrl.tabs.filter(ctrl.checkProducts);
        };

        ctrl.checkShow = (tab: EawNavBarTab) => tab.show ?? true;

        ctrl.checkProducts = (tab: EawNavBarTab) => {
            return [
                tab.state?.data?.product,
                tab.state?.data?.products,
            ]
                .flat()
                .filter((p) => typeof p === 'string')
                .every((p) => ctrl.customer.hasProduct(p));
        };

        ctrl.checkPermission = (tab: EawNavBarTab) => {
            return [ tab.permission, tab.permissions ]
                .flat()
                .filter((p) => typeof p === 'string')
                .every((p) => CurrentOld.can(p ||''));
        };

        ctrl.goToTab = (tab: EawNavBarTab) => {
            if (tab?.src) {
                return $uiRouter.stateService.go(tab.src, undefined, { location: 'replace' });
            }

            console.error('NAVBAR: No tab to go to');
            return $uiRouter.stateService.go('eaw/app/home');
        };

        ctrl.setSelectedNavItem = () => {
            ctrl.tabs.forEach((t: any) => {
                t.matchingChild = ctrl.isTabChild(t);
                t.matchingLevels = t.matchingChild.split('/').length;
            });
            ctrl.selectedNavItem = orderBy(ctrl.tabs, 'matchingLevels', 'desc')[0]?.src;
        };

        ctrl.isNavbarChild = () => $uiRouter.stateService.includes(ctrl.navbarRoute);

        ctrl.isTabChildSome = () => ctrl.tabs.some((t: any) => ctrl.isTabChild(t));

        /**
         * @param {Object} tab
         * @param {Number} iteration
         * @returns {String}
         */
        ctrl.isTabChild = (tab: EawNavBarTab, iteration = 0) => {
            let src = $uiRouter.globals.current.name;
            if (iteration < 0) {
                src = $uiRouter.globals.current.name?.split('/').slice(0, iteration).join('/');
            }

            if (!src || src === 'eaw/app') {
                return '';
            }

            if (tab.state?.includes[src]) {
                return src;
            }

            return ctrl.isTabChild(tab, iteration - 1);
        };
    } ],
});
