<eaw-dialog-header>
    <span title>{{ 'PUBLISH' | translate:'scheduling' | async }}</span>

    @if (!loading() && warningsCount()) {
        <span subtitle>{{'WARNING_COUNT' | translate:'general':{count: warningsCount()} | async}}</span>
    }
</eaw-dialog-header>

@if (loading()) {
    <mat-dialog-content>
        <eaw-info-loading size="lg"></eaw-info-loading>
    </mat-dialog-content>
} @else {
    <mat-dialog-content>
        @if (warnings().length) {
            <div id="warnings-container">
                @for (warning of warnings(); track warning.warning.id) {
                    <div class="warning-item">
                        <mat-icon eawSize="20px" eawMaterialColor="amber-500">warning</mat-icon>

                        <span>
                            @if (warning.warning.messageParameters) {
                                {{ warning.warning.message | translate:'warnings':warning.warning.messageParameters | async }}
                            } @else {
                                {{ warning.warning.message | translate:'warnings' | async }}
                            }
                       </span>

                        <span class="warning-count">{{warning.count | eawNumber:0}}</span>
                    </div>

                    @if (!$last) {
                        <mat-divider></mat-divider>
                    }
                }
            </div>

            @if (!canForcePublish()) {
                <span>{{'PUBLISH_SCHEDULE_CANT_FORCE' | translate:'scheduling' | async}}</span>
            } @else {
                <mat-checkbox eawCheckboxHelper [formControl]="warningCheckbox">{{ 'PUBLISH_WARNING_ACCEPT' | translate:'scheduling' | async }}</mat-checkbox>

                @if (warningsAcknowledged()) {
                    <mat-divider class="tw-my-16"></mat-divider>
                }
            }
        }

        @if (warningsAcknowledged()) {
            <mat-radio-group class="tw-flex tw-flex-col tw-mb-16" [formControl]="publishRadio">
                @for (option of publishOptions; track option.value) {
                    <mat-radio-button [value]="option.value" [name]="option.value">{{option.label | async}}</mat-radio-button>
                }
            </mat-radio-group>
        }

        @if (!publishNow() && warningsAcknowledged()) {
            <mat-form-field class="tw-w-full">
                <mat-label>{{'PUBLISH_TIME' | translate:'scheduling' | async}}</mat-label>
                <eaw-date-time-input [formControl]="publishInput" [minDate]="minDate()"></eaw-date-time-input>
            </mat-form-field>
        }
    </mat-dialog-content>
}

<mat-dialog-actions>
    <button mat-button [matDialogClose]="null" [disabled]="publishing()">{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [disabled]="publishDisabled()" [loading]="publishing()" (click)="publish()">{{ 'PUBLISH' | translate:'scheduling' | async }}</eaw-action-button>
</mat-dialog-actions>
