import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';

export interface ScheduleLoadingDialogData extends DialogData {
    isTemplate: boolean;
}

@Component({
    selector: 'eaw-schedule-loading',
    standalone: true,
    imports: [ CommonModule, TranslatePipe, InfoLoadingComponent ],
    templateUrl: './schedule-loading-dialog.component.html',
    styleUrl: './schedule-loading-dialog.component.scss',
})
export class ScheduleLoadingDialogComponent extends DialogComponent<ScheduleLoadingDialogData> {
    constructor() {
        super(undefined, undefined, DialogSize.Large);
    }
}
