import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { ApiResponse } from '../interfaces/api-response';

export interface PermissionResponse extends ApiResponse {
    id: number;
    created_at: string;
    node: string;
    updated_at: string;
    value: boolean;
}

export class Permission {
    id: number;
    node: string;
    value: boolean;
    createdAt: DateTime;
    updatedAt: DateTime;

    constructor(data: PermissionResponse) {
        this.id = data.id;
        this.node = data.node;
        this.value = data.value;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
    }
}
