import type { EawFormData, EawFormElementData, EawFormElementGroupData, EawFormNodeData } from './interfaces';
import { FormEnum } from './form-enums';
import moment, { Moment } from 'moment-timezone';
import { Nullable } from '../../shared/types/nullable';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export abstract class EawFormNode implements EawFormNodeData {
    id: number;
    form_id: number;
    form?: EawForm;
    label: string | null;
    parent_id: number;
    parent_type: string;
    weight: number;
    created_at?: Nullable<Moment>;
    updated_at?: Nullable<Moment>;
    deleted_at?: Nullable<Moment>;

    protected constructor(data: EawFormNodeData) {
        this.id = data.id;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.deleted_at = data.deleted_at;
        this.form_id = data.form_id;
        this.form = data.form ? new EawForm(data.form) : undefined;
        this.label = data.label;
        this.parent_id = data.parent_id;
        this.parent_type = data.parent_type;
        this.weight = data.weight;
    }

    static makeChildren(children: EawFormNodeData[], form?: EawForm): EawFormNode[] {
        return children.map((c) => {
            if (!c.form) {
                c.form = form;
            }

            if ('children' in c && c.children) {
                return new EawFormElementGroup(c as EawFormElementGroupData);
            }

            return new EawFormElement(c as EawFormElementData);
        });
    }
}

export class EawForm implements EawFormData {
    id: number;
    active: boolean;
    children: EawFormNode[];
    customer?: { id: number };
    customer_id: number;
    name: string;
    processor_class: string | null;
    user_group_id: number | null;
    created_at?: DateTime | null;
    updated_at?: DateTime | null;
    deleted_at?: DateTime | null;

    constructor(data: EawFormData) {
        this.id = data.id;
        this.active = data.active;
        this.customer = data.customer;
        this.customer_id = data.customer_id;
        this.name = data.name;
        this.processor_class = data.processor_class;
        this.user_group_id = data.user_group_id;
        this.created_at = this.toDateTime(data.created_at);
        this.updated_at = this.toDateTime(data.updated_at);
        this.deleted_at = this.toDateTime(data.deleted_at);
        this.children = EawFormNode.makeChildren(data.children || [], this);
    }

    private toDateTime(date?: string | DateTime | Moment | null) {
        if (date == null) {
            return null;
        }
        if (date instanceof DateTime) {
            return date;
        }
        if (typeof date === 'string') {
            return stringToDateTime(date);
        }
        if (moment.isMoment(date) && !date.isValid()) {
            return null;
        }

        const dt = DateTime.fromObject({
            year: date.year(),
            month: date.month() + 1,
            day: date.date(),
            hour: date.hour(),
            minute: date.minute(),
            second: date.second(),
        }, {
            zone: date.tz(),
        });

        return dt.isValid ? dt : null;
    }
}

export class EawFormElement extends EawFormNode implements EawFormElementData {
    data: any = {};
    type: FormEnum.Type;
    children!: never;
    label_position: FormEnum.Position;
    name: string;

    constructor(data: EawFormElementData) {
        super(data);
        this.data = data.data;
        this.type = data.type;
        this.label_position = data.label_position;
        this.name = data.name;
    }

    getValue(data?: Record<string, any>) {
        if (!data) {
            return this.getDefaultValue();
        }

        if (!(this.name in data)) {
            return undefined;
        }

        const val = data[this.name];
        let value: any;

        switch (this.type) {
            case FormEnum.Type.DATE:
                const m = moment(val, this.data.format);
                value = m.isValid() ? m : val;
                break;
            default:
                value = val;
                break;
        }

        return value;
    }

    private getDefaultValue() {
        let value: unknown = undefined;
        const hasDefault = 'default' in this.data;

        switch (this.type) {
            case FormEnum.Type.DATE:
                if (hasDefault) {
                    if (this.data.default == 'now') {
                        value = moment();
                        break;
                    }

                    const m = moment(this.data.default, this.data.format);

                    if (m.isValid()) {
                        value = m;
                    }
                }
                break;
            case FormEnum.Type.CHECKBOX:
                value = hasDefault ? !!this.data.default : false;
                break;
            default:
                if (hasDefault) {
                    value = this.data.default;
                }
        }

        return value;
    }
}

export class EawFormElementGroup extends EawFormNode implements EawFormElementGroupData {
    children: EawFormNode[];
    layout: FormEnum.Layout;

    constructor(data: EawFormElementGroupData) {
        super(data);
        this.layout = data.layout;
        this.children = EawFormNode.makeChildren(data.children, this.form);
    }
}
