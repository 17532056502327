import { EawMaterialColorHue } from '../../shared/services/material-color.service';
import { NamespaceFile } from '../../shared/enums/namespace';

export type PaidTimeSegmentTypeKey = 'paidTime' | 'dayAbsence' | 'hourAbsence' | 'offtime' | 'vacation' | 'unhandledTime' | 'unhandledShift' | 'leaveShift' | 'suggestedPaidTime' | 'extraTime' | 'currentShift' | 'upcomingShift';

export interface PaidTimeSegmentType {
    key: PaidTimeSegmentTypeKey;
    defaultColor: EawMaterialColorHue;
    activeColor: EawMaterialColorHue;
    name: { key: string, ns: NamespaceFile };
    interactive: boolean;
}

export const PaidTimeSegmentTypes = {
    PaidTime: {
        key: 'paidTime',
        defaultColor: 'green-300',
        activeColor: 'green-800',
        name: {
            key: 'PAID_TIME',
            ns: 'payroll',
        },
        interactive: true,
    } satisfies PaidTimeSegmentType,
    Offtime: {
        key: 'offtime',
        defaultColor: 'white-50',
        activeColor: 'white-50',
        name: {
            key: 'TIME_OFF',
            ns: 'vacation',
        },
        interactive: true,
    } satisfies PaidTimeSegmentType,
    Vacation: {
        key: 'vacation',
        defaultColor: 'white-50',
        activeColor: 'white-50',
        name: {
            key: 'VACATION',
            ns: 'vacation',
        },
        interactive: true,
    } satisfies PaidTimeSegmentType,
    UnhandledTime: {
        key: 'unhandledTime',
        defaultColor: 'amber-50',
        activeColor: 'amber-800',
        name: {
            key: 'UNHANDLED_TIME',
            ns: 'chainops',
        },
        interactive: true,
    } satisfies PaidTimeSegmentType,
    UpcomingShift: {
        key: 'upcomingShift',
        defaultColor: 'grey-100',
        activeColor: 'grey-800',
        name: {
            key: 'SHIFT',
            ns: 'scheduling',
        },
        interactive: true,
    } satisfies PaidTimeSegmentType,
    UnhandledShift: {
        key: 'unhandledShift',
        defaultColor: 'grey-100',
        activeColor: 'grey-800',
        name: {
            key: 'UNHANDLED_SHIFT',
            ns: 'payroll',
        },
        interactive: true,
    } satisfies PaidTimeSegmentType,
    LeaveShift: {
        key: 'leaveShift',
        defaultColor: 'grey-100',
        activeColor: 'grey-800',
        name: {
            key: 'LEAVE_SHIFT',
            ns: 'leaveshifts',
        },
        interactive: true,
    } satisfies PaidTimeSegmentType,
    SuggestedPaidTime: {
        key: 'suggestedPaidTime',
        defaultColor: 'blue-50',
        activeColor: 'blue-800',
        name: {
            key: 'SUGGESTED_PAID_TIME',
            ns: 'payroll',
        },
        interactive: true,
    } satisfies PaidTimeSegmentType,
    ExtraTime: {
        key: 'extraTime',
        defaultColor: 'deep-orange-50',
        activeColor: 'deep-orange-800',
        name: {
            key: 'UNAPPROVED_EXTRA_TIME',
            ns: 'payroll',
        },
        interactive: true,
    } satisfies PaidTimeSegmentType,
    HourAbsence: {
        key: 'hourAbsence',
        defaultColor: 'white-50',
        activeColor: 'white-800',
        name: {
            key: 'HOUR_ABSENCE',
            ns: 'absences',
        },
        interactive: true,
    } satisfies PaidTimeSegmentType,
    DayAbsence: {
        key: 'dayAbsence',
        defaultColor: 'white-50',
        activeColor: 'white-800',
        name: {
            key: 'DAY_ABSENCE',
            ns: 'absences',
        },
        interactive: true,
    } satisfies PaidTimeSegmentType,
} as const;
