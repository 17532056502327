import { Component, inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { PermissionSet } from '../../../shared/models/permission-set';
import { catchError, EMPTY, Observable, switchMap, tap } from 'rxjs';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ProductAutocompleteService } from '../../../shared/autocompletes/product-autocomplete.service';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { Product } from '../../../shared/models/product';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { PermissionSetService } from '../../../shared/http/permission-set.service';
import { Products } from '../../../shared/enums/products';
import { MatButton } from '@angular/material/button';

export interface CreateEditPermissionSetData extends DialogData {
    permissionSet?: Observable<PermissionSet>,
}

type PermissionSetForm = {
    name: FormControl<string>
    description: FormControl<string>
    product: FormControl<string | Product | null>
}

@Component({
    selector: 'eaw-create-permission-set-dialog',
    standalone: true,
    imports: [
        MatDialogContent,
        MatDialogActions,
        DialogHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        AutocompleteComponent,
        ActionButtonComponent,
        MatButton,
        MatDialogClose,
    ],
    templateUrl: './create-edit-permission-set-dialog.component.html',
    styleUrl: './create-edit-permission-set-dialog.component.scss',
})
export class CreateEditPermissionSetDialogComponent extends DialogComponent<CreateEditPermissionSetData, PermissionSet> {
    private readonly permissionSetService = inject(PermissionSetService);
    protected readonly productAutocompleteService = inject(ProductAutocompleteService);

    form: FormGroup<PermissionSetForm>;

    submitting = false;

    constructor() {
        super(undefined, undefined, DialogSize.Medium);

        this.form = new FormGroup({
            name: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
            description: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
            product: new FormControl<Product | null | string>(null),
        });

        if (this.data.permissionSet) {
            this.form.disable();
            this.data.permissionSet.pipe(
                tap((set) => {
                    this.form.patchValue({
                        name: set.name,
                        description: set.description,
                        product: set.productName,
                    });
                    this.form.enable();
                }),
            ).subscribe();
        }
    }

    protected save() {
        const formValue = this.form.value;
        const data = {
            name: formValue.name as string,
            description: formValue.description as string,
            product_name: formValue.product instanceof Product ? formValue.product.name : Products.Core,
        };
        this.form.disable();
        this.submitting = true;
        let observable: Observable<PermissionSet>;
        if (this.data.permissionSet) {
            observable = this.data.permissionSet.pipe(switchMap((set) => this.permissionSetService.update(set.id, data)));
        } else {
            observable = this.permissionSetService.create(data);
        }

        observable.pipe(
            catchError(() => {
                this.submitting = false;
                this.form.enable();
                return EMPTY;
            }),
            tap((set) => {
                this.dialogRef.close(set);
            }),
        ).subscribe();
    }
}
