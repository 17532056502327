// @ts-nocheck
import { module } from 'angular';

module('eaw.time').component('autoStartPreviewList', {
    template: `<md-list flex>
    <md-list-item ng-repeat="day in $plCtrl.days track by $index" class="md-2-line">
        <div class="md-list-item-text">
            <h4 ng-bind="day.name"></h4>
            <p ng-bind="day.time.format('LT')"></p>
        </div>
    </md-list-item>
</md-list>
`,
    controllerAs: '$plCtrl',
    bindings: {
        days: '<', // name, time (moment)
    },
});
