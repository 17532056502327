import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { Employee } from '../../../shared/models/employee';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { catchError, map, merge, mergeMap, Observable, of, startWith, switchMap, tap, throwError } from 'rxjs';
import { UserGroup } from '../../models/user-group';
import { UserGroupService } from '../../../shared/http/user-group.service';
import { UserService } from '../../../shared/http/user.service';
import { User } from 'src/app/shared/models/user';
import { UserAccessService } from '../../http/user-access.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { EmployeeService } from '../../../shared/http/employee.service';
import { CountriesService } from 'src/app/shared/services/countries.service';
import { HttpContext } from '@angular/common/http';
import { Language } from '../../../admin/models/language';
import { IGNORE_ERROR } from '../../../shared/http/http-contexts';
import { EawValidators } from '../../../shared/validators/eaw-validators';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DateTimeInputComponent } from '../../../shared/components/date-time/date-time-input/date-time-input.component';
import { DateTimeRangeInputComponent } from '../../../shared/components/date-time/date-time-range-input/date-time-range-input.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { LanguageAutocompleteComponent } from '../../../shared/components/language-autocomplete/language-autocomplete.component';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface ConnectUserAndEmployeeData extends DialogData {
    employee: Employee;
}

/**
 * Connect a user to an employee who didn't have a user before.
 */
@Component({
    selector: 'eaw-connect-user-and-employee',
    templateUrl: './connect-user-and-employee.component.html',
    styleUrl: './connect-user-and-employee.component.scss' ,
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        NgIf,
        MatFormFieldModule,
        NgClass,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        NgFor,
        MatOptionModule,
        LanguageAutocompleteComponent,
        MatSelectModule,
        MatSlideToggleModule,
        DateTimeRangeInputComponent,
        DateTimeInputComponent,
        MatDialogActions,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ConnectUserAndEmployeeComponent extends DialogComponent<ConnectUserAndEmployeeData, boolean, ConnectUserAndEmployeeComponent> implements OnInit {
    employee: Employee;

    showUserSearch = true;
    showUserForm = false;
    showAccessForm = false;

    searching = false;

    form = new FormGroup({
        user: new FormGroup({
            email: new FormControl('', [ EawValidators.email(), Validators.required ]),
            firstName: new FormControl('', {
                nonNullable: true,
                validators: [ Validators.required ],
            }),
            lastName: new FormControl('', {
                nonNullable: true,
                validators: [ Validators.required ],
            }),
            dialCode: new FormControl(''),
            phone: new FormControl(''),
            language: new FormControl<Language | null>(null),
        }),
        access: new FormGroup({
            userGroupSearch: new FormControl(''),
            groups: new FormControl<UserGroup[]>([], {
                nonNullable: true,
                validators: [ Validators.required ],
            }),
            custom: new FormControl(false),
            temp: new FormControl(false),
            from: new FormControl<DateTime>(DateTime.now(), {
                nonNullable: true,
                validators: [ Validators.required ],
            }),
            to: new FormControl<DateTime | null>(null),
        }),
    });

    filteredCountries!: Observable<any>;
    userGroups: UserGroup[] = [];
    fromRequired: boolean = false;
    toRequired: boolean = false;

    private user: undefined | User;
    filteredGroups!: Observable<UserGroup[]>;

    get userForm() {
        return this.form.controls.user.controls;
    }

    get accessForm() {
        return this.form.controls.access.controls;
    }

    constructor(
        @Inject(UserGroupService) private groupService: UserGroupService,
        @Inject(UserService) private userService: UserService,
        @Inject(UserAccessService) private userAccessService: UserAccessService,
        @Inject(EmployeeService) private employeeService: EmployeeService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
        @Inject(MatDialogRef) dialogRef: MatDialogRef<ConnectUserAndEmployeeComponent>,
        @Inject(MAT_DIALOG_DATA) data: ConnectUserAndEmployeeData,
        @Inject(CountriesService) protected countryService: CountriesService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        this.employee = data.employee;
    }

    ngOnInit() {
        this.form.patchValue({
            user: { email: this.employee.email || '' },
        });

        this.accessForm.custom.valueChanges.subscribe((required) => this.fromRequired = !!required);
        this.accessForm.temp.valueChanges.subscribe((required) => this.toRequired = !!required);

        this.groupService.getAllForCustomer(this.employee.customerId, { per_page: 250 }).subscribe((page) => {
            this.userGroups = page.data;
        });

        this.filteredGroups = this.accessForm.groups.valueChanges.pipe(
            startWith(''),
            map((value: unknown) => (typeof value === 'string' ? value : '').toLowerCase()),
            map((value) => this.userGroups.filter((group) => group.name.toLowerCase().includes(value))),
        ) || of([]);

        void this.setFilteredCountries();
    }

    async setFilteredCountries() {
        const countries = await this.countryService.get();

        this.filteredCountries = this.userForm.dialCode.valueChanges.pipe(
            startWith(''),
            map((value: unknown) => (typeof value === 'string' ? value : '').toLowerCase()),
            map((value) => countries.filter((country: any) => country.name?.toLowerCase().includes(value) || country.Dial?.toString().includes(value.replace('+', '')))),
        ) || of([]);
    }

    search() {
        const email: string | null = this.userForm.email.value;
        if (!email) {
            return;
        }

        this.searching = true;

        this.userService.get(email, [ 'language' ]).pipe(
            catchError((err) => {
                if (err.status == 404) {
                    return of(undefined);
                }

                // Some other error than user not found
                return throwError(err);
            }),
            switchMap((user: User | undefined) => {
                if (!user) {
                    return of(undefined);
                }

                return this.userAccessService.getAllForUser(this.employee.customerId, user.id, { per_page: 1 }).pipe(
                    tap((page) => {
                        this.showAccessForm = !page.total;
                    }),
                    map(() => user),
                );
            }),
        ).subscribe({
            next: (user) => {
                this.user = user;
                if (!user) {
                    this.showAccessForm = true;

                    return;
                }

                const form = this.form.controls.user;

                form.patchValue({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    dialCode: user.countryCode,
                    phone: user.phone,
                    language: user.language,
                });

                form.disable();
            },
            complete: () => {
                this.searching = false;
                this.showUserSearch = false;
                this.showUserForm = true;
                this.userForm.email.disable();
            },
            error: () => this.onError(),
        });
    }

    dialCodeDisplay(dial: number) {
        return dial ? `+${dial}` : '';
    }

    disableSubmit() {
        return this.searching || this.showAccessForm && this.form.controls.access.invalid || this.form.controls.user.invalid;
    }

    /**
     * Callback for the submit button
     */
    submit() {
        const user = this.user;
        const from = this.fromRequired ? this.accessForm.from.value : DateTime.now();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const to = this.toRequired ? this.accessForm.to.value! : undefined;

        let observable: Observable<any>;

        if (user) {
            observable = this.update(user, from, to);
        } else {
            // If there is no user, create one, then update the employee and give them access
            observable = this.userService.create({
                first_name: this.userForm.firstName.value,
                last_name: this.userForm.lastName.value,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                email: this.userForm.email.value!,
                phone: this.userForm.phone.value,
                country_code: this.userForm.dialCode.value?.replace('+', ''),
                language_code: this.userForm.language.value?.code,
            }).pipe(switchMap((user) => this.update(user, from, to)));
        }

        observable.subscribe({
            complete: () => this.dialogRef.close(true),
            error: () => this.onError(),
        });

        // Disable form while sending data
        this.form.disable();
    }

    update(user: User, from: DateTime, to?: DateTime): Observable<unknown> {
        this.user = user;
        // Give the employee the user id
        const updateEmployee: Observable<Employee> = this.employeeService.update(this.employee.customerId, this.employee.id, { user_id: user.id });

        if (!this.showAccessForm) {
            return updateEmployee;
        }

        // Give the user access to the customer
        const createAccess: Observable<unknown> = this.userAccessService.create(this.employee.customerId, user.id, from, to).pipe(
            tap(() => void this.snackbar.t('ADDED_NAME_AS_USER', 'company', { name: user.name })),
            switchMap(() => of(...this.accessForm.groups.value).pipe(
                mergeMap((group) => {
                    return this.groupService.addMember({
                        group: {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            owner_id: group.ownerId!,
                            id: group.id,
                        },
                        from,
                        to,
                        member_id: user.id,
                    }, new HttpContext().set(IGNORE_ERROR, [ 409 ])).pipe(catchError((e) => {
                        // If some of these fail, just keep going
                        console.error(e);
                        return of(undefined);
                    }));
                }, 2),
            )),
        );

        return merge(updateEmployee, createAccess);
    }

    onError() {
        this.form.enable();

        this.showUserSearch = true;
        this.showUserForm = false;
        this.showAccessForm = false;
    }
}
