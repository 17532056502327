<eaw-dialog-header>
    <span title>{{'ADD_PERMISSION_plural' | translate: 'admin' | async}}</span>
</eaw-dialog-header>

<div class="tw-grid tw-grid-cols-1 tw-p-16 tw-justify-center">
    <mat-form-field>
        <mat-label>{{'PERMISSION_plural' | translate | async}}</mat-label>
        <textarea matInput rows="3" type="text" [formControl]="node" class="tw-w-full"></textarea>
        <mat-hint>{{'ADD_PERMISSON_DIALOG_HINT' | translate: 'admin' | async}}</mat-hint>
    </mat-form-field>

    <mat-radio-group class="tw-flex tw-flex-col tw-gap-8" [formControl]="allow">
        <mat-radio-button [value]="true">{{'ALLOW' | translate: 'admin' | async}}</mat-radio-button>
        <mat-radio-button [value]="false">{{'DONT_ALLOW' | translate: 'admin' | async}}</mat-radio-button>
    </mat-radio-group>
</div>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [disabled]="node.invalid" [loading]="false" (click)="addToList(node.value || undefined)">{{'ADD_PERMISSION_plural' | translate: 'admin' | async}}</eaw-action-button>
</mat-dialog-actions>
