<form [formGroup]="form" class="tw-grid tw-gap-16 tw-grid-cols-1 md:tw-grid-cols-2" (ngSubmit)="save()">
    @if (schedule().isTemplate) {
        <eaw-autocomplete formControlName="employee"
                          class="tw-col-span-full"
                          [options]="employeeAutocompleteService.options"
                          [getter]="employeeAutocompleteService.getter({customerId: customerId()})"
                          [setter]="employeeAutocompleteService.setter({customerId: customerId()})">
        </eaw-autocomplete>
    } @else {
        <eaw-autocomplete formControlName="employee"
                          class="tw-col-span-full"
                          [hint]="form.controls.date ? undefined : employeeHint"
                          [options]="shiftEmployeeAutocompleteService.options"
                          [getter]="shiftEmployeeAutocompleteService.getter({
                                  customerId: customerId(),
                                  scheduleId: schedule().id,
                                  businessDate: shift()?.id ? undefined: businessDate,
                                  shiftId: shift()?.id
                              })"
                          [setter]="shiftEmployeeAutocompleteService.setter({
                                  customerId: customerId(),
                                  scheduleId: schedule().id,
                                  businessDate: shift()?.id ? undefined: businessDate,
                                  shiftId: shift()?.id
                              })">
        </eaw-autocomplete>
    }

    @if (schedule().isTemplate) {
        <mat-form-field class="tw-col-span-full">
            <mat-label>{{ 'DATE' | translate | async }}</mat-label>
            <mat-select formControlName="templateDateIndex">
                @for (day of schedule().days; track day.index) {
                    <mat-option [value]="day.index">{{ day.dateTime | DateTime:null:'cccc' }} ({{ ('DAY_N' | translateSync:'general':{number: $index + 1}) | lowercase }})</mat-option>
                }
            </mat-select>
        </mat-form-field>
    } @else {
        <mat-form-field eawDatePickerOptions class="tw-col-span-full">
            <mat-label>{{ 'DATE' | translate | async }}</mat-label>
            <input matInput formControlName="date" [matDatepicker]="datePicker" [min]="schedule().from" [max]="maxDate" (dateChange)="updateBusinessDate()">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
    }

    @if (!businessDateFollowsFrom) {
        <mat-form-field class="tw-col-span-full">
            <mat-label>{{ 'BUSINESS_DATE' | translate | async }}</mat-label>
            <mat-select formControlName="businessDate" (selectionChange)="updateBusinessDate()">
                @for (option of businessDateOptions; track option) {
                    <mat-option [value]="option.value">{{ option.translation }}</mat-option>
                }
            </mat-select>

            @if (!schedule().isTemplate && form.value.date && businessDate) {
                <mat-hint>{{ businessDate.dateTime | DateTime:'DATE_SHORT' }}</mat-hint>
            }
        </mat-form-field>
    }

    <eaw-time-input [label]="'FROM' | translate" formControlName="from" class="tw-col-span-1"></eaw-time-input>
    <eaw-time-input [label]="'TO' | translate" formControlName="to" class="tw-col-span-1"></eaw-time-input>

    <mat-form-field class="tw-col-span-full">
        <mat-label>Qualifications</mat-label>
        <mat-chip-grid #chipGrid [disabled]="(hasFrance() | async)|| form.disabled">
            @for (qualification of selectedQualifications | keyvalue; track qualification.key) {
                <mat-chip-row (removed)="removeQualification(qualification.value)">
                    {{ qualification.value.name }}

                    @if (!hasFrance()) {
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    }
                </mat-chip-row>
            }
        </mat-chip-grid>

        <input [placeholder]="((loadedQualifications ? 'TYPE_TO_SEARCH' : 'LOADING') | translate | async) || ''"
               #qualificationInput
               (focus)="getQualifications()"
               formControlName="qualifications"
               [matChipInputFor]="chipGrid"
               [matAutocomplete]="qualificationsAutocomplete"
               [matChipInputSeparatorKeyCodes]="qualificationsSeparatorKeysCodes"
               (matChipInputTokenEnd)="addQualification($event)"/>

        @if (gettingQualifications) {
            <mat-progress-spinner class="tw-mr-20" matSuffix mode="indeterminate" strokeWidth="2" diameter="20"/>
        }

        <mat-autocomplete #qualificationsAutocomplete="matAutocomplete" (optionSelected)="selectedQualification($event)">
            @for (qualification of filteredQualifications | async; track qualification.id) {
                <mat-option [value]="qualification">{{ qualification.name }}</mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>

    <div class="tw-col-span-full">
        @for (field of customFields(); track field.key) {
            <eaw-custom-field-input [formGroup]="form.controls.customFields" [customField]="field" skipInterval/>
        }
    </div>

    <!-- No comment when a shift is provided -->
    @if (!shift()?.id) {
        <mat-form-field class="tw-col-span-full">
            <mat-label>{{ 'COMMENT' | translate | async }}</mat-label>
            <textarea matInput
                      [placeholder]="('WRITE_OPTIONAL_COMMENT' | translate | async) || ''"
                      cdkAutosizeMinRows="3"
                      cdkAutosizeMaxRows="6"
                      cdkTextareaAutosize
                      formControlName="comment">
                </textarea>
        </mat-form-field>
    }

    <button hidden type="submit"></button>
</form>
