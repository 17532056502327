// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { TinyColor } from '@ctrl/tinycolor';

module('eaw.dashboard').component('eawMiniWidget', {
    template: `<div class="icon">
    <md-icon ng-bind="$mini.widget.icon"></md-icon>
</div>

<!-- Placeholder while loading -->
<div class="mini-widget-content loading">
    <h2 ng-bind="::$mini.placeholderTextTitle"></h2>
    <span ng-bind="::$mini.placeholderTextSub"></span>
</div>

<div class="name" ng-i18next="{{::$mini.widget.i18name}}"></div>
`,
    bindings: {
        color: '<',
        widget: '<',
    },
    controllerAs: '$mini',
    controller: [ 'componentDialog', '$element', '$scope', '$mdDialog', 'componentCreatorService', 'colorpickerService', function(componentDialog, $element, $scope, $mdDialog, componentCreatorService, colorpickerService) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.el = $element[0];
            ctrl.el.dataset.name = ctrl.widget.name;
            ctrl.el.dataset.gaItem = `Widget (${ctrl.widget.name})`;
            ctrl.fullSize = ctrl.widget.widgetInfo.fullSize;
            ctrl.isLoading = true;
            ctrl.placeholderText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.';
            ctrl.placeholderTextTitle = ctrl.placeholderText.slice(0, 8);
            ctrl.placeholderTextSub = ctrl.placeholderText.slice(0, 16);
            ctrl.options = [];

            if (!ctrl.color) {
                ctrl.options.push({
                    text: t('widgets:BACKGROUND_COLOR'),
                    async click() {
                        const color = await ctrl.widget.changeBackgroundColor();
                        ctrl.setColors(color);
                    },
                });
            }

            if (!ctrl.widget.default) {
                ctrl.options.push({
                    text: t('REMOVE'),
                    click() {
                        $mdDialog.show($mdDialog.confirm()
                            .theme('delete')
                            .title(t('widgets:DELETE_WIDGET'))
                            .textContent(t('widgets:DELETE_WIDGET_TEXT', {
                                name: t(ctrl.widget.i18name),
                            }))
                            .ok(t('REMOVE'))
                            .cancel(t('CANCEL'))).then(() => {
                            ctrl.widget.delete();
                        });
                    },
                });
            }
            ctrl.setColors(ctrl.widget.backgroundColor);
        };
        ctrl.$postLink = () => {
            ctrl.el.addEventListener('click', ctrl.click);
            if (ctrl.fullSize) {
                ctrl.el.classList.add('full-size');
                Array.from(ctrl.el.children).forEach((c) => c.remove());
                ctrl.el.appendChild(ctrl.createComponent());
            } else {
                ctrl.el.appendChild(ctrl.createComponent([ 'display-none' ]));
            }
        };
        ctrl.$onDestroy = () => {
            ctrl.el.removeEventListener('click', ctrl.click);
        };
        ctrl.setColors = (color) => {
            ctrl.el.style.background = colorpickerService.getHex(ctrl.color || color);
            ctrl.el.style.color = new TinyColor(ctrl.el.style.background).isDark() ? 'white' : 'black';
        };
        ctrl.createComponent = (classes = []) => {
            const component = componentCreatorService.create({
                name: ctrl.widget.component,
                scope: $scope,
                keepScope: true,
                transcludeControllers: { eawMiniWidget: { instance: ctrl } },
            });
            component.element.classList.add('mini-widget-content', ...classes);
            ctrl.componentEl = component.element;
            return ctrl.componentEl;
        };
        ctrl.click = () => {
            // Wait for loading to be done
            if (ctrl.isLoading) {
                return;
            }
            let components = [];
            if (ctrl.dialogComponents) {
                components = components.concat(ctrl.dialogComponents);
            }
            components.push({
                name: 'defaultMiniWidgetOptions',
                label: 'OPTION_plural',
                bindings: {
                    widget: ctrl.widget,
                    options: ctrl.options,
                    color: ctrl.color,
                },
            });
            return componentDialog.tabs(components, ctrl.widget.i18name);
        };
        ctrl.setDialogComponentBindings = (name, bindings, params) => {
            if (!ctrl.dialogComponents) {
                return;
            }
            const component = ctrl.dialogComponents.find((c) => c.name == name);
            if (!component) {
                return;
            }
            component.bindings = {
                ...component.bindings,
                ...bindings,
            };
            if (params) {
                component.params = params;
            }
        };
        ctrl.loading = (load) => {
            if (typeof load === 'boolean') {
                ctrl.isLoading = load;
                if (!load && !ctrl.fullSize) {
                    ctrl.el.querySelector('.loading')?.remove();
                    ctrl.componentEl?.classList.remove('display-none');
                }
            }
            return ctrl.isLoading;
        };
    } ],
});
