<eaw-page-header [fabButton]="fabButton" [buttons]="headerButtons">
    <span title>{{'PROPERTY_plural' | translate | async}}</span>
    <span subtitle>{{(total | eawNumber:0)}}</span>

    <form smallFilter role="search" class="tw-flex tw-flex-row tw-justify-center">
        <mat-form-field class="standalone">
            <mat-label>{{'SEARCH' | translate | async}}</mat-label>
            <input [formControl]="filter" matInput type="text" autofocus autocomplete="off">
        </mat-form-field>
    </form>
</eaw-page-header>

<mat-card>
    <mat-card-content class="tw-p-0">
        <eaw-data-table
            #table
            [columns]="columns"
            [request]="request"
            initialSortBy="id"
            (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
