import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Customer } from '../../../../../shared/models/customer';
import { Employee } from '../../../../../shared/models/employee';
import { CustomFieldsGroup } from '../../../../../shared/utils/custom-fields-group';
import { ModelCustomField } from '../../../../../custom-fields/models/model-custom-field';
import { CustomFieldInputComponent } from '../../../../../custom-fields/components/custom-field-input/custom-field-input.component';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { catchError, count, EMPTY, mergeMap, of, tap } from 'rxjs';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { NewEmployeeNextStepComponent } from '../../components/new-employee-next-step/new-employee-next-step.component';
import { InfoLoadingComponent } from '../../../../../shared/components/info-loading/info-loading.component';
import { NewEmployeeStepComponent } from '../../components/new-employee-step/new-employee-step.component';
import { EmployeePropertyService } from '../../../../../shared/http/employee-property.service';

@Component({
    selector: 'eaw-new-employee-custom-fields',
    standalone: true,
    imports: [ CommonModule, CustomFieldInputComponent, NewEmployeeNextStepComponent, ActionButtonComponent, InfoLoadingComponent, TranslatePipe ],
    templateUrl: './new-employee-custom-fields.component.html',
    styleUrl: './new-employee-custom-fields.component.scss',
})
export class NewEmployeeCustomFieldsComponent extends NewEmployeeStepComponent {
    employeePropertyService = inject(EmployeePropertyService);

    @Input({ required: true }) customer!: Customer;
    @Input({ required: true }) employee!: Employee;
    @Input({ required: true }) customFields!: ModelCustomField[];

    processing = false;
    saved = false;
    customFieldsGroup = new CustomFieldsGroup();

    save() {
        this.processing = true;

        const customFieldValues = Object.entries(this.customFieldsGroup.getValuesWithInterval())
            .filter(([ _, value ]) => {
                return value.value != null;
            });

        this.customFieldsGroup.disable();

        of(...customFieldValues)
            .pipe(
                mergeMap(([ key, v ]) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    return this.employeePropertyService.update(this.customer.id, this.employee.id, key, null, String(v.value!), v.from, v.to);
                }, 3),
                catchError(() => {
                    this.processing = false;
                    this.customFieldsGroup.enable();
                    return EMPTY;
                }),
                // Merge all emissions from mergeMap to a single emission
                count(),
                tap(() => {
                    this.step.completed = true;
                    this.saved = true;
                    this.stepper.next();
                }),
            ).subscribe();
    }
}
