// @ts-nocheck
import { module } from 'angular';
import { Model } from '../../../../shared/angularjs/model';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
module('eaw.company.src').component('fiscalInfo', {
    template: `<md-card ng-if="!$fiscal.canSubmit">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="company:FISCAL_INFO"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <md-progress-circular ng-if="!$fiscal.info" class="md-progress-center"></md-progress-circular>

        <form name="fiscalForm" ng-if="$fiscal.info" layout="row" layout-wrap>
            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:BANK_ID"></label>
                <input type="text" ng-model="$fiscal.info.bank_id" readonly>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:ACCOUNT_NUMBER"></label>
                <input type="text" ng-model="$fiscal.info.account_number" readonly>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:ACCOUNT_NAME"></label>
                <input type="text" ng-model="$fiscal.info.account_name" readonly>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:EMPLID"></label>
                <input type="text" ng-model="$fiscal.info.emplid" readonly>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:IBAN"></label>
                <input type="text" ng-model="$fiscal.info.iban" readonly>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:VISA_PERMIT_TYPE"></label>
                <input type="text" ng-model="$fiscal.info.visa_permit_type" readonly>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:VISA_EMISSION"></label>
                <input type="text" value="{{$fiscal.info.emission | moment:'L'}}" readonly>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:VISA_EXPIRATION"></label>
                <input type="text" value="{{$fiscal.info.expiration | moment:'L'}}" readonly>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:SPOUSE_WORKS_SWITZERLAND"></label>
                <input type="text" ng-model="$fiscal.info.spouse_works_switzerland" readonly>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:SPOUSE_VISA_PERMIT_TYPE"></label>
                <input type="text" ng-model="$fiscal.info.spouse_visa_permit_type" readonly>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:FTE_OTHER_EMPLOYMENT"></label>
                <input type="number" ng-model="$fiscal.info.fte_other_employment" readonly>
            </md-input-container>
        </form>
    </md-card-content>
</md-card>

<md-card ng-if="$fiscal.canSubmit">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="company:FISCAL_INFO"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <md-progress-circular ng-if="!$fiscal.info" class="md-progress-center"></md-progress-circular>

        <form name="fiscalForm" ng-if="$fiscal.info" layout="row" layout-wrap>
            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:BANK_ID"></label>
                <input type="text" ng-model="$fiscal.info.bank_id" uniq-form-name>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:ACCOUNT_NUMBER"></label>
                <input type="text" ng-model="$fiscal.info.account_number" uniq-form-name>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:ACCOUNT_NAME"></label>
                <input type="text" ng-model="$fiscal.info.account_name" uniq-form-name>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:EMPLID"></label>
                <input type="text" ng-model="$fiscal.info.emplid" ng-disabled="!$fiscal.canUpdateEmplid">
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:IBAN"></label>
                <input type="text" ng-model="$fiscal.info.iban" uniq-form-name>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:VISA_PERMIT_TYPE"></label>
                <md-select ng-model="$fiscal.info.visa_permit_type">
                    <md-option ng-value="null"></md-option>
                    <md-option value="G">G</md-option>
                    <md-option value="B">B</md-option>
                    <md-option value="C">C</md-option>
                    <md-option value="L">L</md-option>
                    <md-option value="CI">CI</md-option>
                    <md-option value="N">N</md-option>
                    <md-option value="F">F</md-option>
                    <md-option value="S">S</md-option>
                </md-select>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <eaw-date-picker ng-model="$fiscal.info.emission" business-date="true" label="src:VISA_EMISSION" max-date="$fiscal.info.expiration"></eaw-date-picker>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <eaw-date-picker ng-model="$fiscal.info.expiration" business-date="true" label="src:VISA_EXPIRATION" min-date="$fiscal.info.emission"></eaw-date-picker>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:SPOUSE_WORKS_SWITZERLAND"></label>
                <md-select required ng-model="$fiscal.info.spouse_works_switzerland">
                    <md-option ng-value="0">{{'src:DOESNT_WORK' | i18next}}</md-option>
                    <md-option ng-value="1">{{'src:WORKS_IN_SWITZERLAND' | i18next}}</md-option>
                    <md-option ng-value="2">{{'src:WORKS_IN_GENEVA' | i18next}}</md-option>
                    <md-option ng-value="3">{{'src:WORKS_OUTSIDE_SWITZERLAND' | i18next}}</md-option>
                </md-select>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:SPOUSE_VISA_PERMIT_TYPE"></label>
                <md-select ng-model="$fiscal.info.spouse_visa_permit_type">
                    <md-option ng-value="null"></md-option>
                    <md-option value="G">G</md-option>
                    <md-option value="B">B</md-option>
                    <md-option value="C">C</md-option>
                    <md-option value="L">L</md-option>
                    <md-option value="CI">CI</md-option>
                    <md-option value="N">N</md-option>
                    <md-option value="F">F</md-option>
                    <md-option value="S">S</md-option>
                </md-select>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50" flex-gt-md="25">
                <label ng-i18next="src:FTE_OTHER_EMPLOYMENT"></label>
                <input type="number" ng-model="$fiscal.info.fte_other_employment" uniq-form-name>
            </md-input-container>

            <div flex="100">
                <md-button class="md-accent md-raised" ng-disabled="fiscalForm.$invalid" ng-click="$fiscal.submit()">
                    <span ng-i18next="SUBMIT"></span>
                </md-button>
            </div>
        </form>
    </md-card-content>
</md-card>
`,
    controllerAs: '$fiscal',
    bindings: {
        customer: '<',
        employee: '<',
    },
    controller: [ 'fiscalInfoFactory', 'ToastService', 'EawResource', function(fiscalInfoFactory, ToastService, EawResource) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.canSubmit = CurrentOld.can(`customers.${ctrl.customer.id}.employees.${ctrl.employee.id}.fiscal_info.update`);
            ctrl.canUpdateEmplid = CurrentOld.can(`customers.${ctrl.customer.id}.employees.${ctrl.employee.id}.update`);
            ctrl.getInfo();
        };
        ctrl.getInfo = () => {
            ctrl.loading = true;
            return fiscalInfoFactory.get(ctrl.customer.id, ctrl.employee.id).$promise
                .then(ctrl.existingInfo)
                .catch(ctrl.infoError)
                .finally(() => delete ctrl.loading);
        };
        ctrl.existingInfo = (resp) => {
            ctrl.exists = true;
            ctrl.info = new Model(resp);
        };
        ctrl.infoError = (error) => {
            if (error.status !== 404) {
                return;
            }
            ctrl.exists = false;
            ctrl.info = new Model({});
        };
        ctrl.submit = async () => {
            if (!ctrl.canSubmit) {
                return;
            }
            ctrl.loading = true;
            const action = [ 'create', 'update' ][+ctrl.exists];
            const emplid = ctrl.info.getModified().emplid;
            try {
                if (ctrl.canUpdateEmplid && emplid) {
                    await EawResource.create(`/customers/${ctrl.customer.id}/employees/${ctrl.employee.id}/properties/EMPLID`).update({
                        value: emplid,
                    }).$promise;
                }
                await fiscalInfoFactory[action](ctrl.customer.id, ctrl.employee.id, ctrl.info.getModified()).$promise;
                ToastService.updated();
                ctrl.getInfo();
            } catch (e) {
                console.error(e);
            }
            ctrl.loading = false;
        };
    } ],
});
