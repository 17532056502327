@defer (on viewport) {
    @if (isMiniWidget()) {
        <div id="mini-header">
            <small>{{ widgetInfo().translationKey | translate:widgetInfo().translationNs | async }}</small>
        </div>

        <div class="content">
            <div #componentHost></div>

            @if (errorText) {
                <eaw-mini-widget-content [text]="errorText()" [subtext]="errorSubtext?.() || ''"/>
            }
        </div>
    } @else {
        <div id="header">
            <span id="name" class="mat-body-2">{{ widgetInfo().translationKey | translate:widgetInfo().translationNs | async }}</span>

            <div id="right">
                @for (button of normalHeaderButtons(); track button) {
                    <mat-icon mat-ripple *ngIf="button.click" (click)="button.click()">{{ button.icon }}</mat-icon>
                    <mat-icon mat-ripple *ngIf="button.menu" [matMenuTriggerFor]="button.menu">{{ button.icon }}</mat-icon>
                }

                <mat-icon mat-ripple id="settings-button" (click)="openBottomSheet()">more_vert</mat-icon>
            </div>
        </div>

        <div #componentHost></div>
    }

    @if (failedToInstantiate()) {
        <div id="failed-to-instantiate">
            <mat-icon>error_outline</mat-icon>
            <span class="mat-body-1">{{ failedToInstantiateReason() | async }}</span>
        </div>
    }

    @if (loading()) {
        <div id="loading-screen">
            <mat-progress-spinner mode="indeterminate" diameter="40" strokeWidth="3"></mat-progress-spinner>
        </div>
    }
} @placeholder {
    <!-- A placeholder block is required -->
    <div class="placeholder"></div>
}
