<div id="container" class="tw-flex tw-ml-[5%] tw-basis-[90%] lg:tw-ml-[20%] lg:tw-basis-[60%]">

    <div id="header" class="tw-flex tw-flex-roe tw-justify-between tw-items-center">
        <div class="mat-headline-5 class tw-m-0">{{'SHIFT_CONFIG_plural' | translate:'company' | async}}</div>

        <button [disabled]="fetching" (click)="addLine()" mat-mini-fab color="primary">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <div id="empty-info" class="mat-subtitle-1 tw-flex tw-flex-col tw-justify-center tw-items-center" *ngIf="!form.controls.shifts.controls.length">
        <mat-progress-spinner *ngIf="fetching" class="tw-mt-20" mode="indeterminate"></mat-progress-spinner>
        <span *ngIf="!fetching">{{'SHIFT_CONFIGS_EMPTY' | translate:'company' | async}}</span>
    </div>

    <form [formGroup]="form" autocomplete="off">
        <ng-container formArrayName="shifts">
            <div *ngFor="let shiftForm of form.controls.shifts.controls; let i = index"
                 [formGroup]="shiftForm"
                 class="tw-grid tw-grid-cols-[130px_1fr_75px_75px_90px] md:tw-grid-cols-[140px_1fr_80px_80px_90px] lg:tw-grid-cols-[200px_1fr_80px_80px_90px] tw-gap-8">
                <mat-form-field>
                    <mat-label>{{'NAME' | translate | async}}</mat-label>
                    <input [formControl]="shiftForm.controls.name" matInput>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'DAY_plural' | translate | async}}</mat-label>
                    <mat-select [formControl]="shiftForm.controls.weekdays" multiple>
                        <mat-option *ngFor="let day of weekdays" [value]="day.value">{{day.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <eaw-time-input [label]="'START' | translate" [formControl]="shiftForm.controls.from"></eaw-time-input>

                <eaw-time-input [label]="'END' | translate" [formControl]="shiftForm.controls.to"></eaw-time-input>

                <div class="buttons tw-grid tw-grid-cols-[auto_auto_auto] tw-gap-4">
                    <button mat-icon-button *ngIf="!shiftForm.disabled" [disabled]="shiftForm.invalid || shiftForm.disabled" (click)="updateLine(shiftForm)">
                        <mat-icon>save</mat-icon>
                    </button>

                    <button mat-icon-button *ngIf="!isFakeGroup(shiftForm) && !shiftForm.disabled" [disabled]="shiftForm.disabled" (click)="delete(shiftForm)">
                        <mat-icon>delete</mat-icon>
                    </button>

                    <mat-progress-spinner class="tw-ml-12" strokeWidth="3" diameter="20" mode="indeterminate" *ngIf="shiftForm.disabled"></mat-progress-spinner>
                </div>
            </div>
        </ng-container>
    </form>
</div>
