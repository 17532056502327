<eaw-dialog-header>
    <span title>{{'WARNING' | translate | async}}</span>
</eaw-dialog-header>

<div class="cause-dialog-subtitle">{{ warning?.message | translate: 'warnings': warning?.messageParameters | async }}</div>

<hr/>

<mat-dialog-content *ngIf="loading" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content *ngIf="!loading">
    <div *ngIf="!causes.length">
        <span>{{'NO_INFO_CAUSE' | translate | async}}</span>
    </div>

    <div *ngFor="let cause of causes">
        <div [ngSwitch]="cause.causeType">
            <div *ngSwitchCase="'absence'">
                <div class="info-title">{{ 'CAUSE_ABSENCE' | translate: 'warnings' | async }}</div>

                <div class="cause-info">
                    <table>
                        <tr>
                            <td class="cause-field">{{'LOCATION' | translate | async}}</td>
                            <td class="cause-text">{{cause.absence?.employee?.customer?.name}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'FROM' | translate | async}}</td>
                            <td class="cause-text">{{cause.absence?.from | DateTime: 'DATETIME_MED_WITH_WEEKDAY'}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'TO' | translate | async}}</td>
                            <td class="cause-text">{{cause.absence?.to | DateTime: 'DATETIME_MED_WITH_WEEKDAY'}}</td>
                        </tr>
                    </table>
                    <hr/>
                </div>
            </div>

            <div *ngSwitchCase="'shift'">
                <span class="info-title">{{ 'CAUSE_SHIFT' | translate: 'warnings' | async }}</span>

                <div class="cause-info">
                    <table>
                        <tr>
                            <td class="cause-field">{{'LOCATION' | translate | async}}</td>
                            <td class="cause-text">{{cause.shift?.schedule?.customer?.name}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'SCHEDULE' | translate | async}}</td>
                            <td class="cause-text">{{cause.shift?.schedule?.name}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'EMPLOYEE' | translate | async}}</td>
                            <td class="cause-text">{{cause.shift?.employee?.name}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'BUSINESS_DATE' | translate | async}}</td>
                            <td class="cause-text">{{cause.shift?.businessDate |  DateTime: 'DATE_MED_WITH_WEEKDAY'}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'FROM' | translate | async}}</td>
                            <td class="cause-text">{{cause.shift?.from | DateTime: 'DATETIME_MED_WITH_WEEKDAY'}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'TO' | translate | async}}</td>
                            <td class="cause-text">{{cause.shift?.to | DateTime: 'DATETIME_MED_WITH_WEEKDAY'}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'SHIFT_CREATED' | translate | async}}</td>
                            <td class="cause-text">{{cause.shift?.createdAt | DateTime: 'DATETIME_MED'}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'SHIFT_UPDATED' | translate: 'scheduling' | async}}</td>
                            <td class="cause-text">{{cause.shift?.updatedAt | DateTime: 'DATETIME_MED'}}</td>
                        </tr>
                    </table>
                    <hr/>
                </div>
            </div>

            <div *ngSwitchCase="'off_time'">
                <div class="info-title" *ngIf="!cause.offTime?.vacation">{{ 'CAUSE_OFFTIME' | translate: 'warnings' | async }}</div>
                <div class="info-title" *ngIf="cause.offTime?.vacation">{{ 'CAUSE_VACATION' | translate: 'warnings' | async }}</div>

                <div class="cause-info">
                    <table>
                        <tr>
                            <td class="cause-field">{{'LOCATION' | translate | async}}</td>
                            <td class="cause-text">{{cause.offTime?.employee?.customer?.name}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'FROM' | translate | async}}</td>
                            <td class="cause-text">{{cause.offTime?.from | DateTime: 'DATETIME_MED_WITH_WEEKDAY'}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'TO' | translate | async}}</td>
                            <td class="cause-text">{{cause.offTime?.to | DateTime: 'DATETIME_MED_WITH_WEEKDAY'}}</td>
                        </tr>
                    </table>
                    <hr/>
                </div>
            </div>

            <div *ngSwitchCase="'timepunch'">
                <div class="info-title">{{ 'CAUSE_TIMEPUNCH' | translate: 'warnings' | async }}</div>

                <div class="cause-info">
                    <table>
                        <tr>
                            <td class="cause-field">{{'LOCATION' | translate | async}}</td>
                            <td class="cause-text">{{cause.timepunch?.employee?.customer?.name}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{ 'BUSINESS_DATE' | translate | async }}</td>
                            <td class="cause-text">{{cause.timepunch?.businessDate | DateTime: 'DATE_MED_WITH_WEEKDAY'}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'FROM' | translate | async}}</td>
                            <td class="cause-text">{{cause.timepunch?.in | DateTime: 'DATETIME_MED'}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'TO' | translate | async}}</td>
                            <td class="cause-text">{{cause.timepunch?.out | DateTime: 'DATETIME_MED'}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'LENGTH' | translate | async}}</td>
                            <td class="cause-text">{{cause.timepunch?.length | duration}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'APPROVED' | translate | async}}</td>
                            <td *ngIf="cause.timepunch?.approved">{{'YES' | translate | async}}</td>
                            <td *ngIf="!cause.timepunch?.approved">{{'NO' | translate | async}}</td>
                        </tr>
                        <tr *ngIf="cause.timepunch?.approved">
                            <td class="cause-field">{{'APPROVED_BY' | translate | async}}</td>
                            <td class="cause-text">{{cause.timepunch?.approvedByName || ('NA' | translate | async) }}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'CREATED_AT' | translate | async}}</td>
                            <td class="cause-text">{{cause.timepunch?.createdAt | DateTime: 'DATETIME_MED'}}</td>
                        </tr>
                        <tr>
                            <td class="cause-field">{{'UPDATED_AT' | translate | async}}</td>
                            <td class="cause-text">{{cause.timepunch?.updatedAt | DateTime: 'DATETIME_MED'}}</td>
                        </tr>
                    </table>
                    <hr/>
                </div>
            </div>

            <div *ngSwitchDefault>
                <div>{{'NO_INFO_CAUSETYPE' | translate | async}}: {{cause.causeType}}</div>
            </div>
        </div>
    </div>
</mat-dialog-content>
