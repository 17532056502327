// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="digisign:SIGN_A_FILE"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <hr-file-info file="$signFile.file"></hr-file-info>

        <div ng-if="$signFile.htmlForm && !$signFile.formSubmitted" layout="column">
            <eaw-form-data ng-model="$signFile.formData" html="$signFile.htmlForm"></eaw-form-data>
            <div>
                <md-button class="md-accent md-raised" ng-click="$signFile.sendForm()" ng-disabled="!$signFile.formData" ng-i18next="SUBMIT"></md-button>
            </div>
        </div>

        <eaw-checkbox-sign ng-if="$signFile.formSubmitted" layout-margin html-form="$signFile.htmlForm" on-sign="$signFile.sign()"></eaw-checkbox-sign>
    </md-card-content>
</md-card>
`;

module('eaw.hr.sign').component('hrSignCheckbox', {
    template: template1,
    bindings: {
        file: '<',
        htmlForm: '<?',
        customer: '<',
    },
    controllerAs: '$signFile',
    controller: [ 'fileSignatureFactory', 'HrFactory', '$state', function(fileSignatureFactory, HrFactory, $state) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.formSubmitted = !ctrl.htmlForm;
        };

        ctrl.sendForm = () => {
            HrFactory.saveFormFields(ctrl.file.employee.customer_id, ctrl.file.employee.id, ctrl.file.id, ctrl.formData).$promise.then(() => {
                ctrl.formSubmitted = true;
            });
        };

        ctrl.sign = () => fileSignatureFactory.create.checkbox.query(ctrl.customer.id, ctrl.file.employee_id, ctrl.file.id).$promise.then(() => {
            // Go back to grandparent (parent is abstract)
            $state.go('^.^');
        },
        );
    } ],
});
