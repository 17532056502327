// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';

module('eaw.components').component('eawToggleBtn', {
    template: `<md-button class="md-icon-button" ng-click="$toggleBtn.toggle()" ng-disabled="$toggleBtn.disabled" ng-class="{'toggled': $toggleBtn.toggled}">
    <md-icon ng-bind="$toggleBtn.icon"></md-icon>
    <md-tooltip md-direction="left" ng-if="$toggleBtn.tooltip" ng-bind="$toggleBtn.tooltip"></md-tooltip>
</md-button>
`,
    bindings: {
        icon: '@',
        tooltip: '<?',
        i18nTooltip: '@?',
        toggledInitially: '<?',
    },
    require: {
        ngModel: 'ngModel',
    },
    controllerAs: '$toggleBtn',
    controller: [ '$attrs', function($attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.tooltip = ctrl.i18nTooltip ? t(ctrl.i18nTooltip) : ctrl.tooltip;

            // We only want to trigger on click unless it's
            // toggled initially
            if (!ctrl.toggledInitially) {
                ctrl.ngModel.$overrideModelOptions({
                    updateOn: 'click',
                });
            }

            $attrs.$observe('disabled', (value) => {
                ctrl.disabled = value;
            });

            ctrl.ngModel.$formatters.unshift(function formatter(value) {
                ctrl.toggled = ctrl.toggledInitially ? false : !value; // Flip
                ctrl.toggle(); // Flip again
                return ctrl.toggled;
            });
        };

        ctrl.toggle = () => {
            ctrl.toggled = !ctrl.toggled;
            ctrl.ngModel.setViewValue(ctrl.toggled);
        };
    } ],
});
