import { Inject, Injectable } from '@angular/core';
import { SidenavTabsRegisterService, SidenavTabsRegisterServiceData } from '../../shared/services/sidenav-tabs-register.service';
import { UIRouter } from '@uirouter/core';
import { SidenavTab } from '../../shared/components/sidenav/sidenav.component';
import { ProfileInfoComponent } from '../pages/profile-info/profile-info.component';
import { ProfileSettingsComponent } from '../pages/profile-settings/profile-settings.component';
import { ProfilePasswordComponent } from '../pages/profile-password/profile-password.component';
import { ProfileNotificationsComponent } from '../angularjs/notifications-tab/notifications-tab-upgrade.component';
import { ProfilePinCodeComponent } from '../pages/profile-pin-code/profile-pin-code.component';
import { Products } from '../../shared/enums/products';

@Injectable({
    providedIn: 'root',
})
export class ProfileSidenavService extends SidenavTabsRegisterService {
    constructor(@Inject(UIRouter) override router: UIRouter) {
        super(router);
    }

    init(data: SidenavTabsRegisterServiceData) {
        this.registerTabs(data, this.getTabs(data));
    }

    private getTabs(data: SidenavTabsRegisterServiceData): SidenavTab[] {
        return [
            {
                url: '/info',
                state: `${data.sidenavState}/info`,
                component: ProfileInfoComponent,
                icon: 'information',
                data: {
                    breadcrumb: { key: 'INFORMATION' },
                },
            },
            {
                url: '/settings',
                state: `${data.sidenavState}/settings`,
                component: ProfileSettingsComponent,
                icon: 'settings',
                data: {
                    breadcrumb: { key: 'SETTING_plural' },
                },
            },
            {
                url: '/password',
                state: `${data.sidenavState}/password`,
                component: ProfilePasswordComponent,
                icon: 'key',
                data: {
                    breadcrumb: { key: 'PASSWORD' },
                },
            },
            {
                url: '/pin_code',
                state: `${data.sidenavState}/pin_code`,
                component: ProfilePinCodeComponent,
                icon: 'lock',
                data: {
                    products: [ Products.France ],
                    breadcrumb: { key: 'PIN_CODE' },
                    settings: {
                        'pin_code.enabled': (propertyValueDecoder) => propertyValueDecoder?.asBoolean() === true,
                    },
                },
            },
            {
                url: '/notifications',
                state: `${data.sidenavState}/notifications`,
                component: ProfileNotificationsComponent,
                icon: 'notifications',
                data: {
                    breadcrumb: { key: 'NOTIFICATION_plural' },
                },
            },
        ];
    }
}
