import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { reportError } from '../angularjs/modules/misc/services/easy-funcs.service';
import { PaginationOptions } from '../interfaces/pagination-options';
import { Setting, SettingResponse } from '../models/setting';
import { HttpClient, HttpContext } from '@angular/common/http';
import { IGNORE_ERROR } from './http-contexts';
import { DateTime } from 'luxon';

type CustomersTarget = [ 'customers', number ];
type SettingGroupsTarget = [ 'setting_groups', number ];
type EmployeeGroupsTarget = [ 'employee_groups', number, number ];
export type SettingsUrlSegments = CustomersTarget | SettingGroupsTarget | EmployeeGroupsTarget;

interface GetSettingsOptions extends PaginationOptions {
    // Array with specified settings
    'settings[]'?: string[];
    // All settings containing filter
    filter?: string;
    // key in setting meta-data to search
    key?: string;
    // This will flip the groups search to exclude the groups instead of including them
    exclude_groups?: boolean;
    // Array with specified groups
    'groups[]'?: string[];
}

@Injectable({
    providedIn: 'any',
})
export class SettingService {
    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {
    }

    getString<T = string>(target: SettingsUrlSegments, setting: string, defaultValue?: T): Observable<T | null> {
        return this.httpGet(target, setting).pipe(
            map((val) => (val[setting] ? val[setting] as T : defaultValue) ?? null),
        );
    }

    /**
     * @deprecated
     *
     * This method doesn't work with string default value.
     * @see getString
     */
    getValue(target: SettingsUrlSegments, setting: string, defaultValue: string): never
    /**
     * Get the resolved value of a setting.
     * @returns Observable<Type> or Observable<defaultValue> if no value is set, otherwise the JSON parsed value of the setting
     * @see parseSetting
     */
    getValue<Type>(target: SettingsUrlSegments, setting: string, defaultValue: Type): Observable<Type>
    getValue<Type>(target: SettingsUrlSegments, setting: string, defaultValue?: Type): Observable<Type | null>
    getValue<Type>(target: SettingsUrlSegments, setting: string, defaultValue: Type | null = null): Observable<Type | null> {
        return this.httpGet(target, setting).pipe(map((val) => {
            const result = val[setting];
            return result == null ? defaultValue : this.parseSetting<Type>(result);
        }));
    }

    /**
     * Returns all settings or a subset of settings based on the options
     */
    getSome(target: SettingsUrlSegments, options?: GetSettingsOptions, context?: HttpContext): Observable<Setting[]> {
        return this.http.get<Record<string, SettingResponse> | SettingResponse[]>(this.getUrl(target), {
            params: {
                ...options,
                array: true,
            },
            context,
        }).pipe(
            map((resp) => {
                if (Array.isArray(resp)) {
                    return resp.reduce((arr, setting) => {
                        arr.push(new Setting(setting.key, setting));
                        return arr;
                    }, [] as Setting[]);
                }

                return Object.entries(resp).reduce((arr, [ key, value ]) => {
                    arr.push(new Setting(key, value));
                    return arr;
                }, [] as Setting[]);
            }),
        );
    }

    /**
     * Updates a setting for the specified target
     */
    update(target: SettingsUrlSegments, key: string, value: unknown, from?: DateTime, to?: DateTime): Observable<Setting> {
        return this.http.put<SettingResponse>(this.getUrl(target, key), { value, from, to }).pipe(
            map((resp) => new Setting(key, resp)),
        );
    }

    protected httpGet(target: SettingsUrlSegments, setting: string): Observable<Record<string, string>> {
        return this.http.get<Record<string, string>>(this.getUrl(target, setting), {
            context: new HttpContext().set(IGNORE_ERROR, [ 403, 404 ]),
        });
    }

    protected parseSetting<Type = string>(value: string): Type {
        let v;

        try {
            v = JSON.parse(value);
        } catch (e) {
            reportError(e as Error);
            throw e;
        }

        return v;
    }

    /**
     * Returns the base url for the specified target's settings
     */
    protected getUrl(target: SettingsUrlSegments, suffix?: string): string {
        let url: string | null;
        const endpoint = target[0];

        switch (endpoint) {
            case 'customers': {
                const customerId = target[1];
                url = customerId ? `/customers/${customerId}/settings` : '';
                break;
            }
            case 'setting_groups': {
                const settingGroupId = target[1];
                url = settingGroupId ? `/setting_groups/${settingGroupId}/settings` : '';
                break;
            }
            case 'employee_groups': {
                const customerId = target[1];
                const employeeGroupId = target[2];
                url = customerId && employeeGroupId ? `/customers/${customerId}/employee_groups/${employeeGroupId}/settings` : '';
            }
        }

        return suffix ? `${url}/${suffix}` : url;
    }
}
