// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
module('eaw.dashboard').component('todoWidget', {
    template: `<div ng-if="$ctrl.todos.length">
    <div class="todo-widget-item" ng-repeat="todo in $ctrl.todos track by todo.id" ng-class="[todo.class]">
        <div class="todo-widget-item-top">
            <div>
                <h3 ng-if="todo.due" ng-bind="todo.due | moment:'lll'"></h3>
                <h3 ng-if="!todo.due" ng-i18next="todo:NO_DUE_DATE"></h3>
                <h5 class="tw-m-0" ng-if="todo.due" ng-bind="todo.dueRelative"></h5>
            </div>

            <md-select class="md-no-underline" ng-i18next="[placeholder]todo:STATUS" ng-model="todo.status_id" ng-change="$ctrl.updateStatus(todo)">
                <md-option ng-repeat="status in $ctrl.statuses track by status.id" ng-value="status.id">
                    {{status.name}}
                </md-option>
            </md-select>
        </div>

        <p ng-bind="todo.title"></p>
        <p ng-bind="todo.description"></p>
    </div>
</div>
`,
    require: {
        eawWidget: '^eawWidget',
    },
    controller: [ 'TodoFactory', 'TodoStatusFactory', function(TodoFactory, TodoStatusFactory) {
        const ctrl = this;
        const todosPromiseObj = {
            done: false,
            per_page: 5,
            order_by: 'duehack',
            direction: 'asc',
        };
        function formatTodos() {
            ctrl.todos.forEach((t) => {
                if (!moment.isMoment(t.due)) {
                    return;
                }
                const dueIn = t.due.diff(moment(), 's');
                t.dueRelative = t.due.fromNow();
                switch (true) {
                    case dueIn <= 0: {
                        t.class = 'expired';
                        break;
                    }
                    case dueIn <= 3600: {
                        t.class = 'soon';
                        break;
                    }
                }
            });
        }
        ctrl.$onInit = () => {
            ctrl.eawWidget.setLoading(true);
            ctrl.statusRes = TodoStatusFactory.getAll({ customerId: ctrl.eawWidget.widget.customer });
            ctrl.todoRes = TodoFactory.getAllForUser(todosPromiseObj);
            Promise.all([
                ctrl.statusRes.$promise,
                ctrl.todoRes.$promise,
            ]).then(([ statuses, todos ]) => {
                ctrl.statuses = statuses.data;
                ctrl.todos = todos.data;
                if (!ctrl.todos.length) {
                    ctrl.eawWidget.setEmpty(true);
                }
                // Run on init
                formatTodos();
            }).finally(() => ctrl.eawWidget.setLoading(false));
        };
        ctrl.$onDestroy = () => {
            ctrl.statusRes.$promise?.$cancelRequest?.();
            ctrl.todoRes.$promise?.$cancelRequest?.();
        };
        ctrl.updateStatus = async (todoItem) => {
            const updatedTodo = await TodoFactory.update(ctrl.eawWidget.widget.customer, todoItem.id, { status_id: todoItem.status_id }).$promise;
            // Replace updated
            ctrl.todos.splice(ctrl.todos.findIndex((t) => t.id === updatedTodo.id), 1, updatedTodo);
            formatTodos();
            // Filter to show only not done
            ctrl.todos = ctrl.todos.filter((t) => t.status.type !== 'done');
            TodoFactory.getAllForUser(todosPromiseObj).$promise.then((resp) => {
                ctrl.todos = resp.data;
                formatTodos();
            });
        };
    } ],
});
