// @ts-nocheck
import { module } from 'angular';

module('eaw.misc').service('Printer', function Printer() {
    const service = this;

    /**
     * This get all the styles from us and creates a string we can insert into the print iFrame
     * @returns {string}
     */
    function getStyleRules() {
        const rules = [];
        Array.from(document.styleSheets).forEach((sheet) => {
            try {
                Array.from(sheet.rules).forEach((rule) => {
                    rules.push(rule.cssText);
                });
            } catch (e) {
                console.error(e);
            }
        });

        return rules.join('\n');
    }

    /**
     * Print a specific element on the page
     * @param {HTMLElement} el
     */
    service.print = (el) => {
        const frame = document.createElement('iframe'); // Create the frame
        frame.style.position = 'absolute'; // Must be absolute
        frame.style.zIndex = '9001'; // Put on top
        frame.style.visibility = 'hidden'; // Make it so the user wont see it
        frame.src = 'about:blank'; // Is needed

        document.body.appendChild(frame); // Add the frame
        frame.contentDocument.body.appendChild(el.cloneNode(true)); // Add the element we want to print and all its children
        frame.contentDocument.body.innerHTML = `${frame.contentDocument.body.innerHTML}<style>${getStyleRules()}</style>`; // Set styles so it matches original

        // After print remove the frame, and make sure this listener only runs once
        frame.contentWindow.addEventListener('afterprint', () => {
            frame.remove();
        }, {
            once: true,
        });

        // Set a timeout so the frame has time to create and draw everything
        setTimeout(() => {
            frame.contentWindow.print();
        }, 850); // Not sure how low timeout can go, 1000 seems to be safe
    };

    return service;
});

