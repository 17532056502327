import { ChangeDetectionStrategy, Component, inject, input, OnInit, output, signal } from '@angular/core';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButton } from '@angular/material/button';
import { User } from '../../../shared/models/user';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Language } from '../../../admin/models/language';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NewEmployeeNextStepComponent } from '../../../company/pages/new-employee/components/new-employee-next-step/new-employee-next-step.component';
import { LanguageAutocompleteService } from '../../../shared/autocompletes/language-autocomplete.service';
import { catchError, EMPTY } from 'rxjs';
import { EawValidators } from '../../../shared/validators/eaw-validators';
import { UserService } from '../../../shared/http/user.service';
import { FieldErrorComponent } from '../../../shared/components/field-error/field-error.component';
import { DialPhone, DialPhoneInputComponent } from '../../../shared/components/dial-phone-input/dial-phone-input.component';

export type AdminCreateUpdateUserMode = 'create' | 'update';

type UserForm = {
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    email: FormControl<string | null>;
    languageCode: FormControl<Language | string | null>;
    phone: FormControl<DialPhone | null>;
};

@Component({
    selector: 'eaw-admin-create-update-user',
    standalone: true,
    imports: [
        TranslatePipe,
        AsyncPipe,
        ActionButtonComponent,
        MatButton,
        ReactiveFormsModule,
        AutocompleteComponent,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatError,
        MatFormField,
        MatHint,
        MatInput,
        MatLabel,
        MatOption,
        NewEmployeeNextStepComponent,
        FieldErrorComponent,
        DialPhoneInputComponent,
    ],
    templateUrl: './admin-create-update-user.component.html',
    styleUrl: './admin-create-update-user.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCreateUpdateUserComponent implements OnInit {
    protected readonly languageAutocompleteService = inject(LanguageAutocompleteService);
    protected readonly userService = inject(UserService);

    mode = input.required<'create' | 'update'>();
    userId = input<number>();

    created = output<User>();
    updated = output<User>();

    processing = signal(false);
    form: FormGroup<UserForm>;

    constructor() {
        this.form = new FormGroup({
            firstName: new FormControl<string | null>(null, [ Validators.required, Validators.maxLength(50) ]),
            lastName: new FormControl<string | null>(null, [ Validators.required, Validators.maxLength(50) ]),
            email: new FormControl<string | null>(null, [ Validators.required, EawValidators.email() ]),
            languageCode: new FormControl<Language | string | null>(null, Validators.required),
            phone: new FormControl<DialPhone | null>(null),
        });
    }

    ngOnInit() {
        this.getUser();
    }

    getUser() {
        const userId = this.userId();

        if (this.mode() === 'update' && userId) {
            this.form.disable();

            this.userService.get(userId).subscribe((user) => {
                this.form.enable();
                this.setFormValue(user);
            });
        }
    }

    setFormValue(user: User) {
        this.form.setValue({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            languageCode: user.languageCode,
            phone: user.phone || user.countryCode ? { phone: user.phone || '', dial: user.countryCode || '' } : null,
        });
    }

    getLanguageCode() {
        const form = this.form.value;
        return form.languageCode instanceof Language ? form.languageCode.code : undefined;
    }

    create() {
        const form = this.form.value;
        if (!form.firstName || !form.lastName || !form.email) {
            return;
        }

        this.processing.set(true);
        this.form.disable();
        this.userService.create({
            first_name: form.firstName,
            last_name: form.lastName,
            email: form.email,
            phone: form.phone?.phone,
            language_code: this.getLanguageCode(),
            country_code: form.phone?.dial,
        }).pipe(
            catchError(() => {
                this.form.enable();
                this.processing.set(false);
                return EMPTY;
            }),
        ).subscribe((user) => {
            this.processing.set(false);
            this.created.emit(user);
        });
    }

    update() {
        const form = this.form.value;
        const userId = this.userId();
        if (!userId) {
            return;
        }

        this.processing.set(true);
        this.form.disable();
        this.userService.update(userId, {
            first_name: form.firstName || undefined,
            last_name: form.lastName || undefined,
            email: form.email || undefined,
            phone: form.phone?.phone,
            language_code: this.getLanguageCode(),
            country_code: form.phone?.dial,
        }).pipe(
            catchError(() => {
                this.form.enable();
                this.processing.set(false);
                return EMPTY;
            }),
        ).subscribe((user) => {
            this.processing.set(false);
            this.form.enable();
            this.updated.emit(user);
            this.setFormValue(user);
        });
    }
}
