import { module } from 'angular';
import { Products } from '../../../../../shared/enums/products';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
import { PermissionChildrenFilterOutInput } from '../../../../../permissions/services/element-permission.service';

interface SidebarElement {
    id: string;
    color: string;
    show?: boolean;
    icon: string;
    text: string
    permissionChildrenFilter?: PermissionChildrenFilterOutInput;
    // Upgraded (Angular) component?
    upgrade?: boolean;
}

interface SidebarDefinition extends SidebarElement {
    component: string;
    daySelector?: boolean;
    weekSelector?: boolean;
}

interface SidebarButton extends SidebarElement {
    callback: () => void;
}

export interface IScheduleSidebar {
    sidebars: SidebarDefinition[];
    buttons: SidebarButton[];
}

module('eaw.scheduling').factory('ScheduleSidebar', [ 'SchedulePublish', 'ScheduleCommentsFactory', function(SchedulePublish: any, ScheduleCommentsFactory: any) {
    class ScheduleSidebar extends Object implements IScheduleSidebar {
        sidebars;
        buttons;

        constructor(schedule: any, editable: any) {
            super();
            const publish = new SchedulePublish(schedule);
            const canUnpublish = publish.canUnPublish();
            const canPublish = publish.canPublish();
            const canNotify = publish.canNotify();

            const buttons: SidebarButton[] = [
                {
                    id: 'unpublish',
                    show: canUnpublish,
                    text: 'scheduling:UNPUBLISH',
                    icon: 'cancel',
                    color: 'black',
                    callback: publish.unpublish,
                },
                {
                    id: 'publish',
                    show: canPublish,
                    text: 'scheduling:PUBLISH',
                    icon: 'send',
                    color: 'black',
                    callback: publish.publish,
                },
                {
                    id: 'notify',
                    show: canNotify,
                    text: 'scheduling:NOTIFY_AUDITORS',
                    icon: 'notifications',
                    color: 'black',
                    callback: publish.notifyAuditors,
                },
            ];
            const summaryP = `customers.${schedule.customer_id}.schedules.${schedule.id}.summary`;
            const sidebars: SidebarDefinition[] = [
                {
                    id: 'settings',
                    component: 'sidebarScheduleSettings',
                    show: true,
                    text: 'SETTING_plural',
                    icon: 'settings',
                    color: 'black',
                    upgrade: true,
                },
                {
                    id: 'comments',
                    component: 'scheduleSidebarComments',
                    show: !schedule.is_template && CurrentOld.hasProduct(Products.ScheduleComments) && ScheduleCommentsFactory.getAll.permission(schedule.customer_id, schedule.id),
                    text: 'COMMENT_plural',
                    icon: 'comment',
                    color: 'teal',
                },
                {
                    id: 'business-units-drag-and-drop',
                    component: 'drag-and-drop-business-units',
                    show: editable && CurrentOld.hasProduct(Products.BusinessUnits),
                    text: 'scheduling:UNIT_SELECTOR',
                    icon: 'work',
                    color: 'black',
                },
                {
                    id: 'scheduling-limits',
                    component: 'sidebar-scheduling-limits',
                    show: !schedule.is_template && editable && CurrentOld.hasProduct(Products.SchedulingLimits),
                    text: 'scheduling:LIMIT_plural',
                    icon: 'schedule',
                    color: 'brown',
                },
                {
                    id: 'employee-drag-and-drop',
                    component: 'scheduleSidebarEmployeeDragAndDrop',
                    show: editable && (CurrentOld.hasProduct(Products.SchedulingEmployeeSidebar) || CurrentOld.hasProduct(Products.Availability)),
                    text: 'scheduling:EMPLOYEE_SELECTOR',
                    icon: 'switch_account',
                    color: 'black',
                    daySelector: !schedule.is_template && CurrentOld.hasProduct(Products.Availability),
                },
                {
                    id: 'budget',
                    component: 'sidebar-budget',
                    show: !schedule.is_template && CurrentOld.hasProduct(Products.Budgeting) && CurrentOld.can(summaryP),
                    text: 'scheduling:BUDGET',
                    icon: 'payments',
                    color: 'green',
                    daySelector: true,
                },
                {
                    id: 'balances',
                    component: 'sidebar-balances',
                    show: !schedule.is_template && CurrentOld.can(`customers.${schedule.customer_id}.balance_types.*.get`) && [ CurrentOld.can(`customers.${schedule.customer_id}.employees.*.balances.*.*.get`), CurrentOld.can(`customers.${schedule.customer_id}.employee_balances.manage`) ].some((p) => p),
                    text: 'navigation:BALANCES',
                    icon: 'exposure',
                    color: 'green',
                    daySelector: true,
                },
                {
                    id: 'absence',
                    component: 'sidebarAbsence',
                    show: CurrentOld.hasProduct(Products.Absence),
                    text: 'scheduling:ABSENCE',
                    icon: 'hotel',
                    color: 'blue-grey',
                    daySelector: true,
                },
                {
                    id: 'leave-shifts',
                    component: 'sidebarLeaveShifts',
                    show: CurrentOld.hasProduct(Products.LeaveShifts) && CurrentOld.can(`customers.${schedule.customer_id}.leave_shifts.*.get`),
                    text: 'navigation:LEAVE_SHIFTS',
                    icon: 'schedule',
                    color: 'grey',
                    daySelector: true,
                },
                {
                    id: 'employee-hours',
                    component: 'schedule-employee-hours',
                    show: editable && !schedule.is_template && CurrentOld.can(`customers.${schedule.customer_id}.employees.*.contracts.*.get`),
                    text: 'scheduling:HOURS_PER_CONTRACT_TYPE',
                    icon: 'schedule',
                    color: 'brown',
                    daySelector: true,
                },
                {
                    id: 'standby-list',
                    component: 'sidebar-standby-list',
                    show: !!schedule.is_published,
                    text: 'scheduling:STANDBY_plural',
                    icon: 'person',
                    color: 'black',
                },
                {
                    id: 'day-warnings',
                    component: 'sidebar-day-warnings',
                    show: editable,
                    text: 'scheduling:WARNING_plural',
                    icon: 'warning',
                    color: 'amber',
                    daySelector: true,
                },
                {
                    id: 'shift-periods',
                    component: 'sidebar-periods',
                    show: true,
                    text: 'scheduling:PERIOD_plural',
                    icon: 'timelapse',
                    color: 'blue',
                    daySelector: true,
                },
                {
                    id: 'statistics',
                    component: 'sidebar-statistics',
                    show: schedule.canUpdate && CurrentOld.can(`customers.${schedule.customer_id}.employees.*.contracts.*.get`),
                    text: 'STATISTIC_plural',
                    icon: 'table_chart',
                    color: 'brown',
                    weekSelector: true,
                },
                {
                    id: 'vacation',
                    component: 'vacation-sidebar',
                    show: editable && !schedule.is_template && CurrentOld.hasProduct(Products.Vacation),
                    text: 'OFFTIME_plural',
                    icon: 'flight_takeoff',
                    color: 'black',
                },
                {
                    id: 'events',
                    component: 'sidebar-events',
                    show: !schedule.is_template && CurrentOld.hasProduct(Products.Calendar),
                    text: 'scheduling:EVENT_plural',
                    icon: 'celebration',
                    color: 'red',
                },
                {
                    id: 'position-cost',
                    component: 'sidebar-position-cost',
                    show: !schedule.is_template && editable && CurrentOld.hasProduct(Products.Budgeting) && CurrentOld.can(summaryP),
                    text: 'scheduling:POSITION_COST',
                    icon: 'payments',
                    color: 'green',
                    daySelector: true,
                },
            ];

            for (const sidebar of sidebars) {
                sidebar.permissionChildrenFilter = [ 'ui.customers.'+ schedule.customer_id +'.schedule-sidebar', 'visible', sidebar.id ];
            }

            for (const button of buttons) {
                button.permissionChildrenFilter = [ 'ui.customers.'+ schedule.customer_id +'.schedule-sidebar', 'visible', button.id ];
            }

            this.sidebars = sidebars.filter((s) => s.show);
            this.buttons = buttons.filter((b) => b.show);
        }
    }

    return ScheduleSidebar;
} ]);
