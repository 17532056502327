import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { AdminCreateUpdateUserComponent, AdminCreateUpdateUserMode } from '../../../scheduling/components/admin-create-update-user/admin-create-update-user.component';
import { MatButton } from '@angular/material/button';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { User } from '../../../shared/models/user';

export interface AdminCreateUserDialogComponentData extends DialogData {
    mode: AdminCreateUpdateUserMode,
}

export type AdminCreateUserDialogComponentReturn = User;

@Component({
    selector: 'eaw-admin-create-user-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        AdminCreateUpdateUserComponent,
        MatDialogActions,
        MatButton,
        ActionButtonComponent,
        MatDialogClose,
        TranslatePipe,
        AsyncPipe,
    ],
    templateUrl: './admin-create-user-dialog.component.html',
    styleUrl: './admin-create-user-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCreateUserDialogComponent extends DialogComponent<AdminCreateUserDialogComponentData, AdminCreateUserDialogComponentReturn> {
    constructor() {
        super(undefined, undefined, DialogSize.Large);
    }
}
