import { Inject, Injectable } from '@angular/core';
import { Country } from '../models/country';
import { TranslateService } from '../services/translate.service';
import { map, Observable, of, shareReplay } from 'rxjs';
import { CountryService } from '../http/country.service';
import { CurrentService } from '../services/current.service';
import { expandAllPages } from '../utils/rxjs/expand-all-pages';
import { PaginationOptions } from '../interfaces/pagination-options';
import { sort } from '../angularjs/modules/misc/services/easy-funcs.service';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Autocomplete } from './autocomplete';

@Injectable({
    providedIn: 'root',
})
export class CountryAutocompleteService extends Autocomplete<Country> {
    countries: Observable<ArrayPaginatedResponse<Country>>;

    constructor(
        @Inject(TranslateService) protected translateService: TranslateService,
        @Inject(CountryService) private countryService: CountryService,
        @Inject(CurrentService) private current: CurrentService,
    ) {
        super({
            label: translateService.t('COUNTRY'),
            display: 'translatedName',
            trackByKey: 'name',
        });

        this.countries = expandAllPages((pagination: PaginationOptions) => this.countryService.getAll(this.current.languageTag, pagination), {
            page: 1,
            per_page: 128,
        })
            .pipe(
                map((result: Country[]) => {
                    return {
                        current_page: 1,
                        data: sort(result, this.current.languageTag, [ ((c) => c.translatedName) ], [ 'asc' ]),
                        last_page: 1,
                        per_page: result.length,
                        total: result.length,
                        from: 0,
                        to: result.length,
                    };
                }),
                shareReplay(1),
            );
    }

    setter() {
        return (item: unknown) => {
            if (item instanceof Country) {
                return of(item);
            }
            if (typeof item !== 'string') {
                return of(undefined);
            }

            const lowerCase = item.toLowerCase();
            return this.countries.pipe(
                map((response) => {
                    return response.data.find((country) => country.code.toLowerCase() === lowerCase || country.name.toLowerCase() === lowerCase || country.translatedName.toLowerCase() === lowerCase);
                }),
            );
        };
    }

    getter() {
        return (filter?: string) => {
            const lcFilter = filter?.toLowerCase() || '';

            return this.countries.pipe(
                map((response) => {
                    if (!lcFilter) {
                        return response;
                    }

                    return {
                        ...response,
                        data: response.data.filter((country) => country.name.toLowerCase().includes(lcFilter) || country.translatedName.toLowerCase().includes(lcFilter)),
                    };
                }),
            );
        };
    };
}
