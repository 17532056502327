import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Customer } from '../../../../shared/models/customer';
import { ArrayPaginatedResponse } from '../../../../shared/interfaces/paginated-response';
import { Role } from '../../../../leader-roles/shared/types/role';
import { Observable, of, tap } from 'rxjs';
import { HeaderFabButton, PageHeaderComponent } from '../../../../shared/components/page-header/page-header.component';
import { DataTableComponent } from '../../../../data-table/data-table.component';
import { DataTableColumnType } from '../../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../../data-table/types/data-table-header';
import { Namespace } from '../../../../shared/enums/namespace';
import { DataTableCell } from '../../../../data-table/interfaces/data-table-cell';
import { RoleService } from '../../../../leader-roles/shared/http/role.service';
import { ConfirmDialogService } from '../../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { DataTableButton, DataTableButtonColumn } from '../../../../data-table/types/data-table-button-column';
import { PaginationOptions } from '../../../../shared/interfaces/pagination-options';
import { RoleDialogComponent, RoleDialogData } from '../../../../leader-roles/role-dialog/role-dialog.component';
import { AssignmentDataTableComponent } from '../../../../leader-roles/assignment-data-table/assignment-data-table.component';
import { RoleAssignmentDialogService } from '../../../../leader-roles/shared/services/role-assignment-dialog.service';
import { DataTableDateTimeColumn } from '../../../../data-table/types/data-table-date-time-column';
import { RoleAssignment } from '../../../../leader-roles/shared/types/role-assignment';
import { EawDataTable } from '../../../../data-table/types/data-table';
import { PermissionCheckService } from '../../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { AsyncPipe, NgIf } from '@angular/common';
import { User } from '../../../../shared/models/user';

@Component({
    selector: 'eaw-role-assignment-list',
    templateUrl: './role-assignment-list.component.html',
    styleUrl: './role-assignment-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        DataTableComponent,
        NgIf,
        AssignmentDataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class RoleAssignmentListComponent implements OnInit, EawDataTable {
    @Input() customer!: Customer;
    @ViewChild('table') dataTable!: DataTableComponent<RoleAssignment>;
    @ViewChild('assignments') assignmentTable?: AssignmentDataTableComponent;

    assignmentColumns: DataTableColumnType<RoleAssignment>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item.user ? new User(cell.item.user).name : '',
            header: new DataTableHeader({ i18n: 'NAME' }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            header: new DataTableHeader({ i18n: 'FROM' }),
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            header: new DataTableHeader({ i18n: 'TO' }),
        }),
    ];

    columns: DataTableColumnType<Role>[] = [
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableTextColumn({
            value: (cell) => cell.item.parent?.name || '',
            header: new DataTableHeader({
                i18n: 'SUPERIOR',
                ns: Namespace.Company,
            }),
        }),
        new DataTableTextColumn({
            header: new DataTableHeader({
                i18n: 'USERS_WITH_ROLE',
                ns: Namespace.Company,
            }),
            value: (data: DataTableCell<DataTableTextColumn<Role>, Role>): string => {
                if (!data.item.users) {
                    return '';
                }

                let names = data.item.users?.map((user) => {
                    return new User(user).name;
                });

                const ellipsis: boolean = names.length > 3;
                if (ellipsis) {
                    names = names.slice(0, 2);
                }

                const s: string = names.join(', ');
                return ellipsis ? `${s}...` : s;
            },
        }),
    ];

    request?: Observable<ArrayPaginatedResponse<Role>>;
    role?: Role;
    createButton!: HeaderFabButton;
    createAssignmentButton!: HeaderFabButton;
    protected canCreate!: boolean;

    constructor(
        @Inject(RoleService) protected service: RoleService,
        @Inject(RoleAssignmentDialogService) protected assignmentDialog: RoleAssignmentDialogService,
        @Inject(ConfirmDialogService) protected confirmDialog: ConfirmDialogService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(MatDialog) protected dialog: MatDialog,
        @Inject(SnackBarService) protected snackBar: SnackBarService,
    ) {
    }

    ngOnInit(): void {
        this.createButton = {
            click: this.createRole.bind(this),
            icon: 'add',
            type: 'accent',
            hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customer.id}.roles.create`),
        };

        this.addButtonColumns();
    }

    canAssign(role: Role) {
        return this.permissionCheckService.isAllowed(`customers.${this.customer.id}.roles.${role.id}.assignments.create`);
    }

    canUpdate(role: Role) {
        return this.permissionCheckService.isAllowed(`customers.${this.customer.id}.roles.${role.id}.update`);
    }

    canDelete(role: Role) {
        return this.permissionCheckService.isAllowed(`customers.${this.customer.id}.roles.${role.id}.delete`);
    }

    updateTable(pagination: Partial<PaginationOptions>): void {
        pagination['count[]'] = [ 'allAssignments' ];
        pagination['with[]'] = [ 'parent', 'users' ];

        this.request = this.service.getAll(this.customer.id, { pagination });
    }

    createRole() {
        this.openDialog(new Role({
            id: 0,
            name: '',
            parent_id: null,
            customer_id: this.customer.id,
        })).subscribe((role) => {
            if (!role) {
                return;
            }

            this.service.create(this.customer.id, {
                name: role.name,
                parent_id: role.parent_id,
            }).subscribe(() => {
                this.snackBar.created();
                this.reload();
            });
        });
    }

    updateRole(cell: DataTableCell<DataTableButtonColumn<Role>, Role>) {
        this.openDialog(cell.item).subscribe((result) => {
            cell.disabled.set(false);

            if (!result) {
                return;
            }

            this.service.update({
                customer_id: this.customer.id,
                id: cell.item.id,
                ...result,
            }).subscribe(() => {
                this.reload();
            });
        });
    }

    deleteRole(cell: DataTableCell<DataTableButtonColumn<Role>, Role>) {
        this.confirmDialog.delete({ name: cell.item.name }).afterClosed().pipe(
            tap((result) => {
                cell.disabled.set(false);

                if (!result) {
                    return;
                }

                this.service.delete(this.customer.id, cell.item.id).subscribe(() => {
                    this.snackBar.deleted();
                    this.reload();
                });
            }),
        ).subscribe();
    }

    openRole(role: Role) {
        this.role = role;

        this.createAssignmentButton = {
            icon: 'add',
            type: 'accent',
            hasPermission: () => this.canAssign(role),
            click: () => {
                this.assignmentDialog.create(this.customer.id, role).subscribe((assignment) => {
                    if (!assignment) {
                        return;
                    }

                    if (this.role?.id == assignment.roleId) {
                        this.assignmentTable?.updateTable({});
                    }

                    this.reload();
                });
            },
        };
    }

    protected openDialog(role: Role) {
        return this.dialog.open<RoleDialogComponent, RoleDialogData, { name: string, parent_id?: number }>(RoleDialogComponent, {
            data: {
                customerId: this.customer.id,
                role,
            },
        }).afterClosed();
    }

    protected reload() {
        this.updateTable(this.dataTable.getPagination({ page: 1 }));
    }

    private addButtonColumns() {
        const update: DataTableButton<Role> = {
            icon: 'edit',
            click: this.updateRole.bind(this),
            show: this.canUpdate.bind(this),
            tooltip: { key: 'EDIT' },
        };

        const del: DataTableButton<Role> = {
            icon: 'delete',
            click: this.deleteRole.bind(this),
            hide: (role) => of(role.assigned),
            show: this.canDelete.bind(this),
            tooltip: { key: 'DELETE' },
            type: 'warn',
        };

        this.columns.push(
            // Edit
            new DataTableButtonColumn({
                header: new DataTableHeader({ text: '' }),
                buttons: [
                    update,
                    del,
                ],
            }),
        );
    }
}
