import { ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, signal } from '@angular/core';
import { ShiftLineComponent } from '../shift-line/shift-line.component';
import { sort } from '../../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { ScheduleComponent } from '../../../../schedule.component';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { Shift } from '../../../../../../models/shift';
import { ScheduleTabInterval } from '../../schedule-tab.component';
import { CurrentService } from '../../../../../../../shared/services/current.service';
import { ScheduleTabOpeningHoursComponent } from '../schedule-tab-opening-hours/schedule-tab-opening-hours.component';
import { ScheduleTabVerticalLinesComponent } from '../schedule-tab-vertical-lines/schedule-tab-vertical-lines.component';

@Component({
    selector: 'eaw-schedule-tab-default-display',
    standalone: true,
    imports: [
        ShiftLineComponent,
        CdkDrag,
        ScheduleTabOpeningHoursComponent,
        ScheduleTabVerticalLinesComponent,
    ],
    templateUrl: './schedule-tab-default-display.component.html',
    styleUrl: './schedule-tab-default-display.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleTabDefaultDisplayComponent {
    private currentService = inject(CurrentService);

    @HostBinding('class.dragging-shift') get draggingShiftClass() {
        return this.isDraggingShift();
    }

    stackId = input.required<number>();
    customerId = input.required<number>();
    scheduleId = input.required<number>();
    pixelsPerSecond = input.required<number>();
    scrollOffset = input.required<number>();
    containerWidth = input.required<number>();
    renderedIntervals = input.required<ScheduleTabInterval[]>();
    isDraggingNewShift = input<boolean>();

    offsetLeft = computed(() => this.scrollOffset() / this.pixelsPerSecond());
    offsetRight = computed(() => (this.scrollOffset() + this.containerWidth()) / this.pixelsPerSecond());
    filteredShifts = computed(this.computeFilteredShifts.bind(this));
    sortedShifts = computed(this.computeSortedShifts.bind(this));
    shifts = computed(this.computeShifts.bind(this));
    isDraggingShift = signal(false);

    private computeFilteredShifts() {
        const shifts = Array.from(ScheduleComponent.shifts()?.values() || []);
        const display = ScheduleComponent.properties.scheduleTab.shiftDisplayMode.value();

        switch (display) {
            case 'all':
                return shifts;
            case 'assigned':
                return shifts.filter((s) => s.employeeId);
            case 'unassigned':
                return shifts.filter((s) => !s.employeeId);
            default:
                return shifts;
        }
    }

    private computeSortedShifts() {
        const shifts = this.filteredShifts();
        const sorting = ScheduleComponent.properties.scheduleTab.sorting.value();
        const offsetSorting = [ (i: Shift) => i.offset, (i: Shift) => i.length ];
        const nameSorting = [ (i: Shift) => ScheduleComponent.employees().get(i.employeeId || 0)?.name, (i: Shift) => i.offset ];

        switch (sorting) {
            case 'shift_offset_asc':
                return sort(shifts, this.currentService.languageTag, offsetSorting, [ 'asc', 'asc' ], { numeric: true });
            case 'shift_offset_desc':
                return sort(shifts, this.currentService.languageTag, offsetSorting, [ 'desc', 'desc' ], { numeric: true });
            case 'employee_name_asc':
                return sort(shifts, this.currentService.languageTag, nameSorting, [ 'asc', 'asc' ]);
            case 'employee_name_desc':
                return sort(shifts, this.currentService.languageTag, nameSorting, [ 'desc', 'desc' ]);
        }
    }

    private computeShifts() {
        const offsetLeft = this.offsetLeft();
        const offsetRight = this.offsetRight();
        const interval = ScheduleComponent.properties.scheduleTab.interval.value();
        return this.sortedShifts().filter((s) => (s.rightOffset - (interval / 2)) >= offsetLeft && s.offset <= offsetRight);
    }
}
