import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export type InfoLoadingDirection = 'row' | 'column';
export type InfoLoadingSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

@Component({
    selector: 'eaw-info-loading',
    standalone: true,
    imports: [ CommonModule, MatProgressSpinnerModule ],
    templateUrl: './info-loading.component.html',
    styleUrl: './info-loading.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoLoadingComponent {
    private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);

    direction = input<InfoLoadingDirection>('row');
    size = input.required<InfoLoadingSize>();

    protected diameter = computed(this.computeDiameter.bind(this));
    protected strokeWidth = computed(this.computeStrokeWidth.bind(this));

    constructor() {
        effect(() => (this.elementRef.nativeElement.style.flexDirection = this.direction()));
    }

    private computeDiameter() {
        return new Map<InfoLoadingSize, number>([
            [ 'xs', 16 ],
            [ 'sm', 24 ],
            [ 'md', 32 ],
            [ 'lg', 48 ],
            [ 'xl', 64 ],
        ]).get(this.size());
    }

    private computeStrokeWidth() {
        return new Map<InfoLoadingSize, number>([
            [ 'xs', 1 ],
            [ 'sm', 2 ],
            [ 'md', 3 ],
            [ 'lg', 4 ],
            [ 'xl', 5 ],
        ]).get(this.size());
    }
}
