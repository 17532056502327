// @ts-nocheck
import { module } from 'angular';
const template1 = `<limit-list scheduling-limits="$sidebarLimits.factory"
            from="$sidebarLimits.from"
            to="$sidebarLimits.to">
</limit-list>
`;
module('eaw.scheduling.limits').component('sidebarSchedulingLimits', {
    template: template1,
    controllerAs: '$sidebarLimits',
    bindings: {
        schedule: '<',
    },
    require: {
        sidebar: '^scheduleSidebar',
    },
    controller: [ 'SchedulingLimits', function(SchedulingLimits) {
        this.$onInit = () => {
            this.from = this.schedule.getFrom();
            this.to = this.schedule.getTo().clone().startOf('d');
            // Subtract one day if hour is 0 the next day
            // Because limits are business days
            // And we don't want 24h extra hours because it's the "next" day
            if (this.to.hour() === 0) {
                this.to.subtract(1, 'd');
            }
            this.factory = new SchedulingLimits(this.schedule.customer_id);
        };
    } ],
});
