// @ts-nocheck
import { pick } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
module('eaw.vacation').factory('OffTimeService', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const custRes = EawResource.create('/customers/:customer/off_times');
    const empRes = EawResource.create('/customers/:customer/employees/:employee/off_times/:offTime');
    const formatOffTime = (data) => {
        if (moment.isMoment(data.to)) {
            data.length = moment.duration(data.to.diff(data.from)).asDays();
        }
    };
    return function OffTimeService(customerId, employeeId) {
        this.get = {
            permission(empId, offTimeId) {
                return `customers.${customerId}.employees.${empId || employeeId}.off_times.${offTimeId}.get`;
            },
            query(empId, offTimeId, withArray) {
                const deferred = empRes.get({
                    customer: customerId,
                    employee: empId || employeeId,
                    offTime: offTimeId,
                    'with[]': withArray,
                });
                deferred.$promise.then(formatOffTime);
                return deferred;
            },
        };
        this.customer = {
            getAll: {
                permission() {
                    return `customers.${customerId}.employees.*.off_times.*.get`;
                },
                query(args) {
                    const deferred = custRes.get({
                        customer: customerId,
                        from: args.from,
                        to: args.to,
                        ...Pagination.getParams(args, [ 'approved', 'handled', 'from', 'to' ]),
                    });
                    deferred.$promise.then((resp) => resp.data?.map(formatOffTime));
                    return deferred;
                },
            },
        };
        this.employee = {
            getAll: {
                permission(empId) {
                    return `customers.${customerId}.employees.${empId || employeeId}.off_times.*.get`;
                },
                query(args) {
                    args = args || {};
                    const deferred = empRes.get({
                        customer: args.customer ? args.customer.id : customerId,
                        employee: args.employee ? args.employee.id : employeeId,
                        'with[]': args.with,
                        ...Pagination.getParams(args, [ 'approved', 'handled', 'vacation', 'from', 'to' ]),
                    });
                    deferred.$promise.then((resp) => resp.data?.map(formatOffTime));
                    return deferred;
                },
            },
        };
        this.create = {
            permission(empId) {
                return `customers.${customerId}.employees.${empId || employeeId}.off_times.create`;
            },
            query(employee, request) {
                const deferred = empRes.save({
                    customer: customerId,
                    employee: employee || employeeId,
                }, {
                    ...pick(request, [ 'from', 'to', 'vacation', 'comment', 'approved', 'action' ]),
                    days: request.days ? -request.days : undefined,
                    schedule_published_warning_accepted: true,
                });
                deferred.$promise.then(formatOffTime);
                return deferred;
            },
        };
        this.update = {
            permission(empId, offTimeId) {
                return `customers.${customerId}.employees.${empId || employeeId}.off_times.${offTimeId}.update`;
            },
            query(empId, offTimeId, request) {
                const deferred = empRes.update({
                    customer: customerId,
                    employee: empId || employeeId,
                    offTime: offTimeId,
                }, {
                    ...pick(request, [ 'from', 'to', 'vacation', 'comment', 'approved', 'with', 'action' ]),
                    days: request.days ? -request.days : undefined,
                    schedule_published_warning_accepted: true,
                });
                deferred.$promise.then(formatOffTime);
                return deferred;
            },
        };
        this.delete = {
            permission(empId, offTimeId) {
                return `customers.${customerId}.employees.${empId || employeeId}.off_times.${offTimeId}.delete`;
            },
            query(empId, offTimeId) {
                return empRes.delete({
                    customer: customerId,
                    employee: empId || employeeId,
                    offTime: offTimeId,
                });
            },
        };
    };
} ]);
