import { inject, Injectable } from '@angular/core';
import { WidgetInfo, WidgetInfoData } from '../classes/widget-info';
import { Products } from '../../shared/enums/products';
import { CurrentService } from '../../shared/services/current.service';
import { Customer } from '../../shared/models/customer';
import { User } from '../../shared/models/user';
import { Employee } from '../../shared/models/employee';
import { BulletinsWidgetComponent } from '../components/bulletins-widget/bulletins-widget.component';
import { NormalSkeletonWidgetComponent } from '../components/normal-skeleton-widget/normal-skeleton-widget.component';
import { MiniSkeletonWidgetComponent } from '../components/mini-skeleton-widget/mini-skeleton-widget.component';
import { PeriodLockWidgetComponent } from '../components/period-lock-widget/period-lock-widget.component';
import { AllShiftsWidgetComponent } from '../components/all-shifts-widget/all-shifts-widget.component';
import { VacantShiftsWidgetSettingsDialogComponent } from '../components/vacant-shifts/vacant-shifts-widget-settings-dialog/vacant-shifts-widget-settings-dialog.component';
import { VacantShiftsWidgetExtendedInfoComponent } from '../components/vacant-shifts/vacant-shifts-widget-extended-info/vacant-shifts-widget-extended-info.component';
import { mockCustomer } from '../../../mocks/customer.mock';
import { mockUser } from '../../../mocks/user.mock';
import { NoInfoWidgetComponent } from '../components/no-info-widget/no-info-widget.component';
import { HoursWorkedWidgetSettingsDialogComponent } from '../components/hours-worked-widget/hours-worked-widget-settings-dialog/hours-worked-widget-settings-dialog.component';
import { HoursWorkedWidgetComponent } from '../components/hours-worked-widget/hours-worked-widget.component';
import { VacantShiftsWidgetComponent } from '../components/vacant-shifts/vacant-shifts-widget.component';
import { PermissionCheckService } from '../../shared/services/permission-check.service';
import { CustomerProductService } from '../../shared/http/customer-product.service';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { SettingService } from '../../shared/http/setting.service';
import { MiniAbsenceWidgetComponent } from '../components/mini-absence-widget/mini-absence-widget.component';
import { MiniAbsenceSettingsDialogComponent } from '../components/mini-absence-widget/mini-absence-settings-dialog/mini-absence-settings-dialog.component';
import { MiniEmployeeAlertsWidgetComponent } from '../components/mini-employee-alerts-widget/mini-employee-alerts-widget.component';
import { MiniEmployeeAlertsWidgetExtendedInfoComponent } from '../components/mini-employee-alerts-widget/mini-employee-alerts-widget-extended-info/mini-employee-alerts-widget-extended-info.component';
import { MiniBalancesComponent } from '../components/mini-balances/mini-balances.component';
import { MiniBalancesSettingsComponent } from '../components/mini-balances/mini-balances-settings/mini-balances-settings.component';
import { MiniVacationDaysWidgetComponent } from '../components/mini-vacation-days-widget/mini-vacation-days-widget.component';
import { NightWorkerWidgetComponent } from '../components/night-worker-widget/night-worker-widget.component';
import { NightWorkerWidgetExtendedInfoComponent } from '../components/night-worker-widget/night-worker-widget-extended-info/night-worker-widget-extended-info.component';
import { MiniContractRequalificationWidgetComponent } from '../components/mini-contract-requalification-widget/mini-contract-requalification-widget.component';
import { ContractRequalificationExtendedInfoComponent } from '../components/mini-contract-requalification-widget/contract-requalification-extended-info/contract-requalification-extended-info.component';
import { PredefinedSettings } from '../../shared/enums/predefined-settings';
import { MiniKpiSettingsDialogComponent } from '../components/mini-kpi-widget/mini-kpi-settings-dialog/mini-kpi-settings-dialog.component';
import { MiniKpiWidgetComponent } from '../components/mini-kpi-widget/mini-kpi-widget.component';
import { TimepunchWidgetComponent } from '../components/timepunch-widget/timepunch-widget.component';
import { uniqueId } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { AllShiftsCalendarWidgetComponent } from '../components/all-shifts-calendar-widget/all-shifts-calendar-widget.component';
import { WidgetSize } from '../classes/widget';
import { UpcomingBirthdaysWidgetComponent } from '../components/upcoming-birthdays-widget/upcoming-birthdays-widget.component';
import { UpcomingBirthdaysWidgetSettingsComponent } from '../components/upcoming-birthdays-widget/upcoming-birthdays-widget-settings/upcoming-birthdays-widget-settings.component';
import { MiniWeatherWidgetComponent } from '../components/mini-weather-widget/mini-weather-widget.component';
import { WeatherWidgetSettingsDialogComponent } from '../components/mini-weather-widget/weather-widget-settings-dialog/weather-widget-settings-dialog.component';
import { WeatherWidgetExtendedComponent } from '../components/mini-weather-widget/weather-widget-extended/weather-widget-extended.component';
import { MiniPunchedTodayComponent } from '../components/mini-punched-today/mini-punched-today.component';
import { MiniMessagesWidgetComponent } from '../components/mini-messages-widget/mini-messages-widget.component';
import { MiniMessagesWidgetExtendedInfoComponent } from '../components/mini-messages-widget/mini-messages-widget-extended-info/mini-messages-widget-extended-info.component';
import { MiniNextShiftWidgetComponent } from '../components/mini-next-shift-widget/mini-next-shift-widget.component';
import { MiniNextShiftWidgetExtendedInfoComponent } from '../components/mini-next-shift-widget/mini-next-shift-widget-extended-info/mini-next-shift-widget-extended-info.component';
import { MiniShiftSumWidgetComponent } from '../components/mini-shift-sum-widget/mini-shift-sum-widget.component';
import { MiniUpcomingEventWidgetComponent } from '../components/mini-upcoming-event-widget/mini-upcoming-event-widget.component';
import { MiniUpcomingEventWidgetExtendedComponent } from '../components/mini-upcoming-event-widget/extended-info/mini-upcoming-event-widget-extended.component';

interface GetArgs {
    // Overwrite current customer
    customer?: Customer;
    // Overwrite current user
    user?: User;
    // Overwrite current employee
    employee?: Employee;
    // Choose which type of widgets to return
    type?: 'normal' | 'mini' | 'all';
    // Only widgets available for use in setup
    setup?: boolean;
    // Return all widgets regardless of checks
    all?: boolean;
}

@Injectable({
    providedIn: `root`,
})
export class WidgetInfoService {
    private current = inject(CurrentService);
    private permissionCheckService = inject(PermissionCheckService);
    private customerProductService = inject(CustomerProductService);
    private settingService = inject(SettingService);

    getByKey(key: string) {
        return this.get().pipe(
            map((widgetInfos) => widgetInfos.find((widgetInfo) => widgetInfo.key === key)),
        );
    }

    getSkeletonWidget(mini: boolean) {
        const key = uniqueId('skeleton');

        if (mini) {
            return new WidgetInfo({
                key,
                icon: ``,
                component: MiniSkeletonWidgetComponent,
                mini: true,
                translationKey: ``,
                products: [],
                permissions: [],
                requiresEmployee: false,
            }, mockCustomer(), mockUser());
        } else {
            return new WidgetInfo({
                key,
                component: NormalSkeletonWidgetComponent,
                icon: ``,
                translationKey: ``,
                products: [],
                requiresEmployee: false,
                permissions: [],
            }, mockCustomer(), mockUser());
        }
    }

    /**
     * Returns by default the widgets that are available to the current user.
     * @param args
     */
    get(args: GetArgs = {}): Observable<WidgetInfo[]> {
        // Get info for the provided thing(s) first, or else fall back to the current thing(s)
        const c = args.customer || this.current.getCustomer();
        const u = args.user || this.current.getUser();
        const e = args.employee || this.current.getEmployee();

        // Short IDs for the current thing(s)
        const cId = c.id;
        const uId = u.id;
        const eId = e?.id || 0; // Default to 0 if no employee is provided since it will never match

        const items: WidgetInfoData[] = [
            {
                key: `scheduling_hour_limit`,
                component: NoInfoWidgetComponent,// `schedulingHourLimitWidget`,
                noInfo: true,
                icon: `schedule`,
                translationKey: `SCHEDULING_HOUR_LIMIT_STATS`,
                products: [ Products.Scheduling, Products.Payroll, Products.SchedulingLimits ],
                requiresEmployee: false,
                permissions: [ `customers.${cId}.scheduling_limits.*.get` ],
            },
            {
                key: `timepunch`,
                route: `payroll/my`,
                component: TimepunchWidgetComponent,
                icon: `schedule`,
                translationKey: `TIMEPUNCH_WIDGET`,
                products: [ Products.Payroll ],
                requiresEmployee: true,
                permissions: [ `customers.${cId}.employees.${eId}.timepunches.*.get` ],
            },
            {
                key: `punch`,
                route: `payroll/punch_clock`,
                icon: `punch_clock`,
                component: NoInfoWidgetComponent,// `punch-clock-widget`,
                noInfo: true,
                translationKey: `PUNCH_CLOCK`,
                translationNs: `navigation`,
                products: [ Products.Payroll ],
                requiresEmployee: true,
                settings: {
                    [PredefinedSettings.PayrollPersonalTpApp]: (decoder) => decoder?.asBoolean() === true,
                },
                permissions: [
                    `customers.${cId}.employees.${eId}.timepunches.*.get`,
                    `customers.${cId}.employees.${eId}.timepunches.punch`,
                ],
            },
            {
                key: `shifts`,
                route: `schedules/shifts/my-list`,
                icon: `calendar_month`,
                component: NoInfoWidgetComponent,// `shifts-widget`,
                noInfo: true,
                translationKey: `SHIFTS_WIDGET`,
                products: [ Products.Scheduling ],
                requiresEmployee: true,
                permissions: [ `customers.${cId}.employees.${eId}.shifts.*.get` ],
            },
            {
                key: `kpi`,
                route: `kpi`,
                icon: `insights`,
                component: NoInfoWidgetComponent,// `kpi-widget`,
                noInfo: true,
                translationKey: `KPI_WIDGET`,
                settingsDialog: HoursWorkedWidgetSettingsDialogComponent,// `defaultKpiSettings`,
                products: [ Products.KeyPerformanceIndicators ],
                requiresEmployee: false,
                somePermissions: [
                    `customers.${cId}.kpis.*.get`,
                    `customers.${cId}.widgets.kpi`,
                ],
            },
            {
                key: `all_shifts`,
                route: `schedules/shifts/shifts`,
                icon: `calendar_month`,
                component: AllShiftsWidgetComponent,
                translationKey: `ALL_SHIFTS_WIDGET`,
                products: [ Products.Scheduling ],
                requiresEmployee: false,
                permissions: [ `customers.${cId}.schedules.*.shifts.*.get` ],
            },
            {
                key: `all_shifts_calendar`,
                route: `schedules/shifts/shifts`,
                icon: `calendar_month`,
                component: AllShiftsCalendarWidgetComponent,
                recommendedSize: WidgetSize.Tall,
                translationKey: `ALL_SHIFTS_WIDGET_CALENDAR`,
                products: [ Products.Scheduling ],
                requiresEmployee: false,
                permissions: [ `customers.${cId}.schedules.*.shifts.*.get` ],
            },
            {
                key: `available_shifts`,
                route: `schedules/shifts/available_shifts`,
                icon: `free_cancellation`,
                component: NoInfoWidgetComponent,// `available-shifts-widget`,
                noInfo: true,
                translationKey: `AVAILABLE_SHIFTS`,
                products: [ Products.Scheduling ],
                requiresEmployee: true,
                permissions: [ `customers.${cId}.shift_swaps.*.get` ],
            },
            {
                key: `postits`,
                icon: `sticky_note_2`,
                component: BulletinsWidgetComponent,
                recommendedSize: WidgetSize.Tall,
                translationKey: `POST_IT_WIDGET`,
                products: [ Products.Postits ],
                permissions: [ `customers.${cId}.postits.*.get` ],
                requiresEmployee: false,
            },
            {
                key: `flexitime`,
                route: `payroll/my/flexitime`,
                icon: `more_time`,
                component: NoInfoWidgetComponent,// `flexitime-widget`,
                noInfo: true,
                translationKey: `FLEXITIME_WIDGET`,
                products: [ Products.FlexiTime ],
                requiresEmployee: true,
                permissions: [ `customers.${cId}.employees.${eId}.flexitime.*.get` ],
            },
            {
                key: `todos`,
                route: `todo/my`,
                icon: `assignment_add`,
                component: NoInfoWidgetComponent,// `todo-widget`,
                noInfo: true,
                translationKey: `TODO_WIDGET`,
                requiresEmployee: false,
                permissions: [
                    `customers.${cId}.users.${uId}.todo_items.*.get`,
                    `customers.${cId}.todo_statuses.*.get`,
                ],
                products: [ Products.Todo ],
            },
            {
                key: `standby`,
                icon: `person_search`,
                component: NoInfoWidgetComponent,// `standby-widget`,
                noInfo: true,
                translationKey: `STANDBY`,
                requiresEmployee: false,
                permissions: [ `customers.${cId}.employees.${eId}.standbys.*.get` ],
                products: [ Products.Scheduling ],
            },
            {
                key: `newsletter`,
                route: `newsletter`,
                icon: `feed`,
                component: NoInfoWidgetComponent,// `newsletter-widget`,
                noInfo: true,
                translationKey: `NEWSLETTER_plural`,
                translationNs: 'newsletter',
                // settingsDialog: BulletinsWidgetComponent,// `defaultNewsletterSettings`, TODO in the future
                // settings: {
                //     newsletter_id: 0,
                // },
                requiresEmployee: false,
                permissions: [ `customers.${cId}.newsletters.*.issues.*.get` ],
                products: [ Products.Newsletter ],
            },
            {
                key: 'upcoming_birthdays',
                icon: 'cake',
                component: UpcomingBirthdaysWidgetComponent,
                translationKey: 'UPCOMING_BIRTHDAYS',
                requiresEmployee: false,
                products: [ ],
                somePermissions: [ `customers.${cId}.employees.*.get`, `customers.${cId}.links.*.get` ],
                settingsDialog: UpcomingBirthdaysWidgetSettingsComponent,
            },
            {
                key: `working_employees`,
                icon: `group`,
                multiple: true,
                component: NoInfoWidgetComponent,// `mini-working-employees-widget`,
                noInfo: true,
                translationKey: `WORKING_EMPLOYEES_WIDGET`,
                mini: true,
                settingsDialog: HoursWorkedWidgetSettingsDialogComponent,// `workingEmployeesSettings`,
                products: [ Products.Scheduling, Products.Payroll ],
                requiresEmployee: false,
                permissions: [
                    `customers.${cId}.schedules.*.shifts.*.get`,
                    `customers.${cId}.employees.*.timepunches.*.get`,
                ],
            },
            {
                key: `balances`,
                icon: `balance`,
                multiple: true,
                mini: true,
                component: MiniBalancesComponent,
                translationKey: `BALANCES`,
                settingsDialog: MiniBalancesSettingsComponent,
                products: [ Products.Payroll, Products.Switzerland ],
                requiresEmployee: true,
                permissions: [
                    `customers.${cId}.employees.${eId}.balances.*.*.get`,
                    `customers.${cId}.balance_types.*.get`,
                ],
            },
            {
                key: `vacation_days`,
                icon: `flight_takeoff`,
                multiple: false,
                component: MiniVacationDaysWidgetComponent,
                mini: true,
                translationKey: `VACATION_DAYS`,
                products: [ Products.Vacation ],
                requiresEmployee: true,
                permissions: [ `customers.${cId}.employees.${eId}.vacation_days.*.get` ],
            },
            {
                key: `hours_worked`,
                icon: `schedule`,
                multiple: true,
                component: HoursWorkedWidgetComponent,
                mini: true,
                translationKey: `HOURS_WORKED_WIDGET`,
                settingsDialog: HoursWorkedWidgetSettingsDialogComponent,// `miniHoursWorkedSettings`,
                products: [ Products.Payroll ],
                requiresEmployee: true,
                permissions: [ `customers.${cId}.employees.${eId}.timepunches.*.get` ],
            },
            {
                key: `mini_kpi`,
                icon: `insights`,
                multiple: true,
                component: MiniKpiWidgetComponent,
                translationKey: `KPI_WIDGET`,
                mini: true,
                excludeFromSetup: true,
                settingsDialog: MiniKpiSettingsDialogComponent,
                products: [ Products.KeyPerformanceIndicators ],
                requiresEmployee: false,
                permissions: [
                    `customers.${cId}.kpis.*.get`,
                    `customers.${cId}.kpi_types.*.get`,
                ],
            },
            {
                key: `weather`,
                icon: `sunny`,
                component: MiniWeatherWidgetComponent,
                mini: true,
                translationKey: `WEATHER_WIDGET`,
                settingsDialog: WeatherWidgetSettingsDialogComponent,
                excludeFromSetup: true,
                products: [ Products.Weather ],
                permissions: [ `customers.${cId}.weather.get` ],
                requiresEmployee: false,
                extendedInfoComponent: WeatherWidgetExtendedComponent,
            },
            {
                key: `vacant_shifts`,
                icon: `person_search`,
                component: VacantShiftsWidgetComponent,
                translationKey: `VACANT_SHIFTS`,
                mini: true,
                products: [ Products.Scheduling ],
                multiple: true,
                permissions: [ `customers.${cId}.schedules.*.shifts.*.get` ],
                requiresEmployee: false,
                settingsDialog: VacantShiftsWidgetSettingsDialogComponent,
                extendedInfoComponent: VacantShiftsWidgetExtendedInfoComponent,
            },
            {
                key: `upcoming_event`,
                component: MiniUpcomingEventWidgetComponent,
                mini: true,
                translationKey: `UPCOMING_EVENT`,
                icon: `celebration`,
                products: [ Products.Calendar ],
                requiresEmployee: false,
                permissions: [ `customers.${cId}.calendar_events.*.get` ],
                extendedInfoComponent: MiniUpcomingEventWidgetExtendedComponent,
            },
            {
                key: `next_shift`,
                component: MiniNextShiftWidgetComponent,
                translationKey: `NEXT_SHIFT`,
                mini: true,
                icon: `badge`,
                products: [ Products.Scheduling ],
                requiresEmployee: true,
                permissions: [ `customers.${cId}.employees.${eId}.shifts.*.get` ],
                extendedInfoComponent: MiniNextShiftWidgetExtendedInfoComponent,
            },
            {
                key: `shift_sum`,
                mini: true,
                component: MiniShiftSumWidgetComponent,
                translationKey: `SHIFT_SUM`,
                icon: `schedule`,
                products: [ Products.Scheduling ],
                requiresEmployee: true,
                permissions: [ `customers.${cId}.employees.${eId}.shifts.*.get` ],
            },
            {
                key: `punched_today`,
                component: MiniPunchedTodayComponent,
                mini: true,
                translationKey: `PUNCHED_TODAY`,
                icon: `punch_clock`,
                products: [ Products.Scheduling, Products.Payroll ],
                requiresEmployee: true,
                permissions: [
                    `customers.${cId}.employees.${eId}.shifts.*.get`,
                    `customers.${cId}.employees.${eId}.timepunches.*.get`,
                ],
            },
            {
                key: `period_lock`,
                icon: `lock`,
                mini: true,
                component: PeriodLockWidgetComponent,
                translationKey: `PERIOD_LOCK`,
                products: [ Products.PeriodLocks ],
                requiresEmployee: false,
                permissions: [ `customers.${cId}.period_locks.*.get` ],
            },
            {
                key: `mini_messages`,
                icon: `mail`,
                mini: true,
                component: MiniMessagesWidgetComponent,
                translationKey: `MESSAGES`,
                translationNs: `navigation`,
                products: [ Products.Messaging ],
                requiresEmployee: false,
                permissions: [],
                extendedInfoComponent: MiniMessagesWidgetExtendedInfoComponent,
            },
            {
                key: `mini_absence`,
                multiple: true,
                icon: `hotel`,
                mini: true,
                component: MiniAbsenceWidgetComponent,
                products: [ Products.Absence ],
                requiresEmployee: false,
                permissions: [ `customers.${cId}.employees.*.absences.*.get` ],
                translationKey: `ABSENT_EMPLOYEES`,
                settingsDialog: MiniAbsenceSettingsDialogComponent,
            },
            {
                key: `mini_employee_alerts`,
                icon: `notifications`,
                mini: true,
                component: MiniEmployeeAlertsWidgetComponent,
                products: [],
                requiresEmployee: false,
                permissions: [ `customers.${cId}.alerts.*.get` ],
                translationKey: `EMPLOYEE_ALERTS`,
                extendedInfoComponent: MiniEmployeeAlertsWidgetExtendedInfoComponent,
            },
            {
                key: 'france_night_worker',
                icon: 'bedtime',
                mini: true,
                component: NightWorkerWidgetComponent,
                products: [ Products.France ],
                requiresEmployee: false,
                translationKey: 'NIGHT_WORKER_plural',
                permissions: [
                    `customers.${cId}.employees.*.timepunches.*.get`,
                    `customers.${cId}.employees.*.get`,
                    `customers.${cId}.alerts.*.get`,
                ],
                extendedInfoComponent: NightWorkerWidgetExtendedInfoComponent,
            },
            {
                key: 'contract_requalification',
                icon: 'description',
                mini: true,
                component: MiniContractRequalificationWidgetComponent,
                products: [ Products.France ],
                requiresEmployee: false,
                translationKey: 'CONTRACT_REQUALIFICATION',
                permissions: [ `customers.${cId}.employees.*.contracts.create`, `customers.${cId}.alerts.*.get` ],
                extendedInfoComponent: ContractRequalificationExtendedInfoComponent,
            },
        ] satisfies WidgetInfoData[];

        // Create widget info objects and filter by type
        const widgetInfos = items.map((i) => new WidgetInfo(i, c, u, e)).filter((i) => {
            switch (true) {
                case args.type === `mini`: {
                    return i.mini;
                }
                case args.type === `normal`: {
                    return !i.mini;
                }
                case args.type === `all`:
                default: {
                    return true;
                }
            }
        });

        if (args.all) {
            return of(widgetInfos);
        }

        return forkJoin(
            widgetInfos.map((widgetInfo) => {
                return this.hasRequirements(widgetInfo).pipe(
                    map((hasRequirements) => hasRequirements ? widgetInfo : null),
                    switchMap((widgetInfo) => {
                        if (!widgetInfo) {
                            return of(null);
                        }

                        if (args.setup) {
                            return this.canBeUsedInSetup(widgetInfo).pipe(
                                map((canBeUsedInSetup) => canBeUsedInSetup ? widgetInfo : null),
                            );
                        }

                        return of(widgetInfo);
                    }),
                );
            }),
        ).pipe(
            map((widgetInfos) => {
                return widgetInfos.reduce((acc, widgetInfo) => {
                    if (widgetInfo) {
                        acc.push(widgetInfo);
                    }

                    return acc;
                }, [] as WidgetInfo[]);
            }),
        );
    }

    hasSettings(widgetInfo: WidgetInfo) {
        const settings = widgetInfo.settings || {};
        const settingKeys = Object.keys(widgetInfo.settings || {});

        if (!settingKeys.length) {
            return of(true);
        }

        return this.settingService.getSome([ 'customers', widgetInfo.customer.id ], { 'settings[]': settingKeys }).pipe(
            map((settingsResponse) => {
                return Object.entries(settings).every(([ key, callback ]) => {
                    const setting = settingsResponse.find((s) => s.key === key);
                    return setting ? callback(setting.value) : false;
                });
            }),
        );
    }

    hasRequiredProducts(widgetInfo: WidgetInfo) {
        return this.customerProductService.hasProducts(widgetInfo.customer.id, widgetInfo.products);
    }

    hasRequiredPermissions(widgetInfo: WidgetInfo) {
        return this.permissionCheckService.isAllowedMany(widgetInfo.permissions, widgetInfo.somePermissions);
    }

    hasRequiredEmployee(widgetInfo: WidgetInfo) {
        return widgetInfo.requiresEmployee ? !!widgetInfo.employee : true;
    }

    canBeUsedInSetup(widgetInfo: WidgetInfo): Observable<boolean> {
        if (widgetInfo.excludeFromSetup) {
            return of(false);
        }

        if (widgetInfo.settingsDialog) {
            return of(false);
        }

        return this.hasRequirements(widgetInfo);
    }

    hasRequirements(widgetInfo: WidgetInfo): Observable<boolean> {
        if (!this.hasRequiredEmployee(widgetInfo)) {
            return of(false);
        }

        return forkJoin([
            this.hasRequiredProducts(widgetInfo),
            this.hasRequiredPermissions(widgetInfo),
            this.hasSettings(widgetInfo),
        ]).pipe(map((response) => response.every(Boolean)));
    }
}
