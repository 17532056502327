<eaw-dialog-header>
    <span title>
            {{ (employees.length > 1 ? 'ADD_FILE_MULTIPLE' : 'ADD_FILE') | translate:'hr': {count: employees.length} | async }}
    </span>
</eaw-dialog-header>

@if (viewExistingFileHandleOptions) {
    <mat-dialog-content>
        @switch (fileMethod) {
            @case ('Custom') {
                <p>{{ 'CUSTOM_FILE_ALREADY_EXIST' | translate: 'hr' | async }}</p>
            }
            @case ('Default') {
                <p>{{ 'DEFAULT_FILE_ALREADY_EXISTS' | translate: 'hr' | async }}</p>
            }
            @case ('DefaultMany') {
                <p>{{ 'DEFAULT_FILE_ALREADY_EXISTS_plural' | translate: 'hr' | async }}</p>
            }
        }

        <mat-action-list>
            <button mat-list-item (click)="submit()">
                <mat-icon color="primary" class="tw-p-8">arrow_forward</mat-icon>
                {{ 'UPLOAD_AND_LEAVE_AS_IS' | translate: 'hr' | async }}
            </button>
            <button mat-list-item (click)="setExistingAsExpired = true; submit()">
                <mat-icon color="primary" class="tw-p-8">arrow_forward</mat-icon>
                {{ 'UPLOAD_AND_SET_EXPIRED' | translate: 'hr' | async }}
            </button>
            <button mat-list-item (click)="viewExistingFileHandleOptions = false">
                <mat-icon color="primary" class="tw-p-8">close</mat-icon>
                {{ 'CANCEL_UPLOAD' | translate: 'hr' | async }}
            </button>
        </mat-action-list>
    </mat-dialog-content>
} @else {
    <mat-dialog-content>
        @if (loading) {
            <div class="tw-flex tw-flex-row tw-justify-center tw-items-center">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        } @else {
            <form [formGroup]="formGroup" class="tw-flex tw-flex-col">
                <eaw-autocomplete formControlName="employee"
                                  [class.tw-hidden]="employees.length > 1"
                                  [options]="employeeAutocompleteService.options"
                                  [getter]="employeeAutocompleteService.getter({customerId: data.customerId, with: ['hrFiles.properties']})"
                                  [setter]="employeeAutocompleteService.setter({customerId: data.customerId})">
                </eaw-autocomplete>

                @if (defaultFiles.length) {
                    <mat-radio-group class="tw-flex tw-flex-col tw-gap-8"
                                     formControlName="fileMethod">
                        <mat-radio-button [value]="'Custom'">{{ 'UPLOAD_FILE' | translate:'hr' | async }}</mat-radio-button>
                        <mat-radio-button [value]="'Default'">{{ 'USE_DEFAULT_FILE' | translate:'hr' | async }}</mat-radio-button>
                        <mat-radio-button [value]="'DefaultMany'"
                                          [class.tw-hidden]="employees.length > 1">{{ 'USE_SEVERAL_DEFAULT_FILES' | translate: 'hr' | async }}
                        </mat-radio-button>
                    </mat-radio-group>

                    @if (fileMethod === 'Default') {
                        <mat-form-field>
                            <mat-label>{{ 'DEFAULT_FILE' | translate:'hr' | async }}</mat-label>
                            <input type="text"
                                   matInput
                                   formControlName="defaultFile"
                                   [matAutocomplete]="defaultFileAuto">
                            <mat-autocomplete #defaultFileAuto="matAutocomplete" [displayWith]="autocompleteDisplay">
                                @for (type of filetypes; track type.id; let i = $index) {
                                    <mat-optgroup [label]="type.name"
                                                  [class.tw-hidden]="!(filteredDefaultFiles | async)![i]!.length">
                                        @for (file of (filteredDefaultFiles | async)![i]; track file.id) {
                                            <mat-option [value]="file">
                                                {{ file.name }}
                                            </mat-option>
                                        }
                                    </mat-optgroup>
                                }
                            </mat-autocomplete>
                        </mat-form-field>
                    }

                    @if (fileMethod === 'DefaultMany') {
                        <mat-form-field>
                            <mat-label>{{ 'DEFAULT_FILES' | translate: 'company' | async }}</mat-label>
                            <mat-select multiple formControlName="defaultFiles">
                                <mat-select-trigger>
                                    @for (file of formGroup.controls['defaultFiles'].value; track file) {
                                        <span>{{ file.name }}</span>
                                    }
                                </mat-select-trigger>
                                <div class="mat-mdc-option" tabindex="0">
                                    <input class="mat-mdc-form-field-input-control"
                                           [placeholder]="('SEARCH' | translate | async) || ''"
                                           autofocus
                                           autocomplete="off"
                                           tabindex="0"
                                           type="search"
                                           formControlName="defaultFileSearch">
                                </div>
                                @for (type of filetypes; track type.id; let i = $index) {
                                    <mat-optgroup [label]="type.name"
                                                  [class.tw-hidden]="!(filteredDefaultFiles | async)![i]!.length">
                                        @for (file of (filteredDefaultFiles | async)![i]; track file) {
                                            <mat-option [value]="file">{{ file.name }}</mat-option>
                                        }
                                    </mat-optgroup>
                                }
                            </mat-select>
                        </mat-form-field>
                    }
                }

                <mat-form-field [class.tw-hidden]="fileMethod !== 'Custom'">
                    <mat-label>{{ 'FILETYPE' | translate:'hr' | async }}</mat-label>
                    <input type="text" matInput formControlName="filetype" [matAutocomplete]="filetypeAuto">
                    <mat-autocomplete #filetypeAuto="matAutocomplete"
                                      requireSelection
                                      [displayWith]="autocompleteDisplay">
                        @for (type of filteredFiletypes | async; track type.id) {
                            <mat-option [value]="type">
                                {{ type.name }}
                            </mat-option>
                        }
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field class="file-form-field"
                                floatLabel="always"
                                [class.tw-hidden]="fileMethod !== 'Custom'"
                                [class.mat-form-field-invalid]="invalidFileFormat">
                    <mat-label>{{ 'FILE' | translate:'hr' | async }}</mat-label>
                    <mat-icon matPrefix color="primary">attach_file</mat-icon>
                    <eaw-file-selector [placeholder]="('CHOOSE_FILE' | translate | async)!"
                                       [disabled]="formGroup.disabled"
                                       [required]="fileMethod === 'Custom'"
                                       [fileFormats]="fileFormats"
                                       formControlName="file" />

                    @if (file) {
                        <span matTextSuffix [style.color]="'grey-500' | materialColor | async">
                            {{ file!.size | fileSize }}
                        </span>
                    }

                    @if (formGroup.controls.file.value && expectedFormats && invalidFileFormat) {
                        <mat-hint class="mat-error">{{ 'INVALID_FILE_FORMAT_ERROR' | translate: 'hr': {types: expectedFormats} | async }}</mat-hint>
                    }
                </mat-form-field>

                <mat-form-field [class.tw-hidden]="fileMethod !== 'Custom'">
                    <mat-label>{{ 'NAME' | translate | async }}</mat-label>
                    <input formControlName="name" matInput>
                </mat-form-field>

                <mat-form-field eawDatePickerOptions>
                    <mat-label>{{ 'EXPIRY_DATE' | translate:'hr' | async }}</mat-label>
                    <input matInput
                           [min]="minDate"
                           autocomplete="off"
                           formControlName="expiryDate"
                           [matDatepicker]="$any(picker)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                @if (hasExpiry) {
                    <mat-checkbox class="tw-mb-16"
                                  eawCheckboxHelper
                                  formControlName="notify">{{ 'NOTIFY' | translate: 'hr' | async }}
                    </mat-checkbox>
                }

                @if (notify) {
                    <mat-form-field>
                        <mat-label>{{ 'WARN_ME_DAYS' | translate: 'hr' | async }}</mat-label>
                        <input matInput formControlName="warnDays" type="number" step="1" min="0" max="365">
                    </mat-form-field>
                }

                @if (documentServiceEnabled && fileMethod === 'Custom') {
                    <mat-form-field>
                        <mat-label>{{ 'SIGNED_BY' | translate: 'hr' | async }}</mat-label>
                        <mat-select required formControlName="signatory">
                            @for (type of signingOptions; track type) {
                                <mat-option [value]="type">{{ type.name | async }}
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>

                    @if (formGroup.controls.signatory.value?.userSelect) {
                        <mat-form-field>
                            <mat-label>{{ 'SELECT_MANAGER' | translate: 'hr' | async }}</mat-label>
                            <input type="text"
                                   matInput
                                   formControlName="managerUser"
                                   required
                                   [matAutocomplete]="managerAuto">
                            <mat-autocomplete #managerAuto="matAutocomplete" [displayWith]="autocompleteManagerDisplay">
                                @for (user of filteredUsers | async; track user.id) {
                                    <mat-option [value]="user">
                                        {{ user.name }}
                                    </mat-option>
                                }
                            </mat-autocomplete>
                        </mat-form-field>
                    }
                    @if (formGroup.controls.signatory.value?.type !== 'none') {
                        <mat-checkbox class="tw-mb-16"
                                      eawCheckboxHelper
                                      formControlName="initials">{{ 'SIGN_INITIALS' | translate: 'hr' | async }}
                        </mat-checkbox>
                    }
                }
            </form>
        }
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button [mat-dialog-close]="">{{ 'CANCEL' | translate | async }}</button>
        <button mat-button
                mat-raised-button color="primary"
                (click)="submit()"
                [disabled]="!formGroup.valid || (fileMethod === 'Custom' && !file) || invalidFileFormat">
            <span>{{ 'UPLOAD' | translate:'hr' | async }}</span>
        </button>
    </mat-dialog-actions>
}
