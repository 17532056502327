import { BusinessUnitResponse } from '../interfaces/business-unit-response';
import { TinyColor } from '@ctrl/tinycolor';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { Qualification, QualificationResponse } from '../../shared/models/qualification';

export class BusinessUnit {
    id: number;
    parentId: number | null;
    customerId: number;
    code: string;
    type: string;
    name: string;
    manable = false;
    qualifications: Qualification[];
    color: TinyColor;
    colorHex: string;
    default: boolean;
    parent?: BusinessUnit | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    children: BusinessUnit[] = [];
    reverseInheritQualifications: boolean;
    isProductive: boolean;

    constructor(data: BusinessUnitResponse & { customer_id: number }) {
        this.id = data.id;
        this.customerId = data.customer_id;
        this.parentId = data.parent_id ?? null;
        this.code = data.code ?? '';
        this.name = data.name ?? '';
        this.manable = data.manable ?? false;
        this.qualifications = data.qualifications?.map((q: QualificationResponse) => new Qualification(q)) || [];
        this.color = new TinyColor(data.color ?? '#888');
        this.colorHex = this.color.toHexString(true);
        this.default = data.default || false;
        this.type = data.type || '';
        this.reverseInheritQualifications = data.reverse_inherit_qualifications || false;
        // treat all units as productive until they are marked exactly as is_productive: false
        this.isProductive = data.is_productive !== false;

        if (data.parent) {
            this.parent = new BusinessUnit(data.parent);
        }

        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
    }

    /**
     * @deprecated here only for compatibility with angularjs
     * @see parentId
     * @returns {number | null}
     */
    get parent_id() {
        return this.parentId;
    }

    /**
     * @deprecated here only for compatibility with angularjs
     * @see parentId
     * @param {number | null} id
     */
    set parent_id(id) {
        this.parentId = id;
    }

    /**
     * @deprecated here only for compatibility with angularjs
     * @see customerId
     * @returns {number}
     */
    get customer_id() {
        return this.customerId;
    }

    /**
     * @deprecated here only for compatibility with angularjs
     * @see customerId
     * @param {number} id
     */
    set customer_id(id) {
        this.customerId = id;
    }
}
