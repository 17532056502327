<eaw-page-header [fabButton]="fabButton">
    <div title>{{'EMPLOYEE_GROUP_plural' | translate:'company' | async}}</div>
</eaw-page-header>

<mat-card class="mat-compact-card tw-mt-20">
    <mat-card-content class="tw-flex tw-flex-col">
        <eaw-data-table [columns]="columns"
                        [request]="request"
                        initialSortBy="id"
                        [goTo]="{state: infoRoute, params: [{stateKey: 'group_id', itemKey: 'id'}]}"
                        (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
