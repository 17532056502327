import { Transition } from '@uirouter/angularjs';
import { SidenavTab } from '../components/sidenav/sidenav.component';

/**
 * Resolves tabs for NavMenuComponent
 */
export function sidenavTabsResolver(resolveFn: ($transition$: Transition) => Promise<SidenavTab[]>) {
    return {
        token: 'tabs',
        deps: [ '$transition$' ],
        resolveFn,
    };
}
