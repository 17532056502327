import { AfterContentInit, Component, ContentChildren, Inject, QueryList } from '@angular/core';
import { DateTimeInputComponent } from '../date-time-input/date-time-input.component';
import { MatFormField } from '@angular/material/form-field';
import { TranslateService } from '../../../services/translate.service';
import { DateTime } from 'luxon';

@Component({
    selector: 'eaw-date-time-range-input',
    templateUrl: './date-time-range-input.component.html',
    styleUrl: './date-time-range-input.component.scss',
    standalone: true,
})
export class DateTimeRangeInputComponent implements AfterContentInit {
    @ContentChildren(MatFormField) fields?: QueryList<MatFormField>;
    @ContentChildren(DateTimeInputComponent, { descendants: true }) inputs?: QueryList<DateTimeInputComponent>;

    fromField?: MatFormField;
    toField?: MatFormField;
    fromInput?: DateTimeInputComponent;
    toInput?: DateTimeInputComponent;

    constructor(
        @Inject(TranslateService) private translate: TranslateService,
    ) {
    }

    ngAfterContentInit(): void {
        this.fromField = this.fields?.first;
        this.toField = this.fields?.last;
        this.fromInput = this.inputs?.first;
        this.toInput = this.inputs?.last;

        if (!this.fromField) {
            console.error('Missing from field');
        }
        if (!this.toField) {
            console.error('Missing to field');
        }
        if (!this.fromInput) {
            console.error('Missing from input');
        }
        if (!this.toInput) {
            console.error('Missing to input');
        }

        this.fromInput?.dateTimeChange.subscribe(() => this.dateTimeChange('from'));
        this.toInput?.dateTimeChange.subscribe(() => this.dateTimeChange('to'));

        if (this.fromInput && this.toInput) {
            this.fromInput.dateClass = this.dateClassFn.bind(this);
            this.toInput.dateClass = this.dateClassFn.bind(this);
        }
    }

    private dateClassFn(date: DateTime, view: 'month' | 'year' | 'multi-year') {
        if (view !== 'month') {
            return '';
        }

        const from = this.fromInput?.value;
        const to = this.toInput?.value;

        if (!from || !to) {
            return '';
        }
        if (Math.round(to.diff(from, 'days').as('days')) < 2) {
            return '';
        }

        if (date.hasSame(from, 'day')) {
            return 'other-date-time-input-from';
        }
        if (date.hasSame(to, 'day')) {
            return 'other-date-time-input-to';
        }

        if (from && to && (date > from && date < to)) {
            return 'date-time-range-input-between-date';
        }

        return '';
    }

    dateTimeChange(input: 'from' | 'to') {
        const from = this.fromInput;
        const to = this.toInput;
        if (!from || !to) {
            return;
        }

        const fromValue = from.value;
        const toValue = to.value;
        if (!fromValue || !toValue) {
            return;
        }

        if (fromValue > toValue) {
            if (input === 'from') { // From was changed
                // If from is greater than to, then set the "to" date to be the same as the "from" date, but with the time of the to date
                const newTo = DateTime.fromObject({
                    ...fromValue.toObject(),
                    hour: toValue.hour,
                    minute: toValue.minute,
                    second: toValue.second,
                    millisecond: toValue.millisecond,
                });

                // Write the new value to the input
                to.writeValue(newTo);

                this.setErrors(fromValue, newTo, from, to);
            } else {
                // If to is less than from, then set the "from" date to be the same as the "to" date, but with the time of the from date
                const newFrom = DateTime.fromObject({
                    ...toValue.toObject(),
                    hour: fromValue.hour,
                    minute: fromValue.minute,
                    second: fromValue.second,
                    millisecond: fromValue.millisecond,
                });

                // Write the new value to the input
                from.writeValue(newFrom);

                this.setErrors(newFrom, toValue, from, to);
            }
        } else {
            this.setErrors(fromValue, toValue, from, to);
        }

        from.markAsTouched();
        to.markAsTouched();
    }

    private setErrors(fromValue: DateTime, toValue: DateTime, from: DateTimeInputComponent, to: DateTimeInputComponent) {
        if (fromValue > toValue) {
            from.ngControl?.control?.setErrors({ afterTo: true });
            to.ngControl?.control?.setErrors({ beforeFrom: true });
            void this.setLabel(this.fromField, this.translate.t('HINT_FROM_EQUAL_LESS_TO'));
            void this.setLabel(this.toField, this.translate.t('HINT_TO_EQUAL_GREATER_FROM'));
        } else {
            from.ngControl?.control?.setErrors(null);
            to.ngControl?.control?.setErrors(null);
            void this.setLabel(this.fromField, '');
            void this.setLabel(this.toField, '');
        }
    }

    async setLabel(field: MatFormField | undefined, label: string | Promise<string>) {
        if (!field) {
            return;
        }
        field.hintLabel = await label;
    }
}
