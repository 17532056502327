<div id="attachment-list">
    <div id="upload-btn" class="mat-elevation-z2" matRipple (click)="openInput()">
        <mat-icon eawSize="32px">add</mat-icon>
    </div>

    <div class="image-container mat-elevation-z2" *ngFor="let entry of entries | keyvalue">
        <img [src]="entry.value.src">

        <button class="remove-btn" eawMaterialColor="red-500" colorProperty="backgroundColor" mat-mini-fab aria-label="Remove" (click)="delete(entry.key)">
            <mat-icon>delete</mat-icon>
        </button>

        <span class="name">{{entry.value.file.name}}</span>
    </div>
</div>
