<mat-tab-group [mat-stretch-tabs]="false">
    <mat-tab [label]="('QUALIFICATION_plural' | translate: 'company' | async) || 'Qualifications'">
        <div class="tw-flex tw-justify-between tw-m-12">
            <form [formGroup]="form" class="tw-flex">
                <mat-form-field eawDatePickerOptions>
                    <mat-label>{{'ENTER_DATE_RANGE' | translate | async}}</mat-label>
                    <mat-date-range-input formGroupName="range" [rangePicker]="picker">
                        <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async" autocomplete="off">
                        <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async" autocomplete="off">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <button mat-icon-button *ngIf="form.value.range?.from || form.value.range?.to" (click)="rangeReset()" class="tw-mt-4"><mat-icon>close</mat-icon></button>
            </form>
            <button mat-button mat-raised-button color="accent" (click)="addQualification()">
                {{'ADD_QUALIFICATION' | translate: 'company' | async }}
            </button>
        </div>
        <div class="tw-flex tw-flex-col tw-m-12 qualifications">
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let qualification of qualifications" (opened)="getEmployees(qualification)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <button mat-icon-button (click)="addEmployee($event, qualification)"><mat-icon>add</mat-icon></button>
                            <button mat-icon-button (click)="editQualification($event, qualification)"><mat-icon>edit</mat-icon></button>
                            <button mat-icon-button
                                    [disabled]="!!qualification.qualificationData.employeesCount"
                                    [class]="!!qualification.qualificationData.employeesCount ? 'tw-invisible' : ''"
                                    (click)="deleteQualification($event, qualification)"><mat-icon>delete</mat-icon>
                            </button>
                            {{ qualification.qualificationData.name }} ({{ qualification.qualificationData.activeEmployeesCount }})
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    @if (qualification.status === 'unloaded') {
                        <eaw-info-loading size="lg"></eaw-info-loading>
                    } @else if (qualification.status === 'error') {
                        <span eawMaterialColor="red-500">{{'UNKNOWN_ERROR' | translate | async}}</span>
                    } @else {
                        <table class="table table-condensed tw-pb-16">
                            <thead>
                            <tr>
                                <th>{{ 'NAME' | translate | async }}</th>
                                <th>{{ 'FROM' | translate | async }}</th>
                                <th>{{ 'TO' | translate | async }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let employee of qualification.employees">
                                <td>{{employee.name}}</td>
                                <td>{{employee.from | DateTime: 'DATE_MED'}}</td>
                                <td>{{employee.to ? (employee.to |  DateTime: 'DATE_MED') : ''}}</td>
                            </tr>
                            <tr *ngIf="!qualification.employees.length">
                                <td colspan="3">{{ 'NO_DATA' | translate | async }}</td>
                            </tr>
                            </tbody>
                        </table>
                    }
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-tab>
    <mat-tab [label]="('QUICK_ADD_REMOVE_QUALIFICATIONS' | translate: 'company' | async) || 'Quick qualifications'">
        <eaw-qualification-grid></eaw-qualification-grid>
    </mat-tab>
</mat-tab-group>
