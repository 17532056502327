// @ts-nocheck
import { t } from 'i18next';
import { find } from 'lodash-es';
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';

export class ChecklistsTemplateListComponent {
    checklistDialog;
    ChecklistSubscription;
    ChecklistFactory;
    ToastService;
    $mdDialog;
    startChecklistDialog;

    static get $inject() {
        return [ 'checklistDialog', 'ChecklistSubscription', 'ChecklistFactory', 'ToastService', '$mdDialog', 'startChecklistDialog' ];
    }

    table;
    reloadTable = false;
    constructor(checklistDialog, ChecklistSubscription, ChecklistFactory, ToastService, $mdDialog, startChecklistDialog) {
        this.checklistDialog = checklistDialog;
        this.ChecklistSubscription = ChecklistSubscription;
        this.ChecklistFactory = ChecklistFactory;
        this.ToastService = ToastService;
        this.$mdDialog = $mdDialog;
        this.startChecklistDialog = startChecklistDialog;
    }

    $onInit() {
        this.table = {
            columns: [
                {
                    title: t('TITLE'),
                    sortable: 'title',
                },
                { title: '' },
            ],
            cells: [
                { template: 'item.title' },
                {
                    buttons: [
                        {
                            text: () => t('START'),
                            tooltip: 'START',
                            click: this.startChecklistDialog.open,
                        },
                        {
                            icon: 'notifications',
                            tooltip: 'SUBSCRIBE',
                            // Only one bell at a time
                            displayNone: this.subscribeDisplayNone.bind(this),
                            click: this.subscribeClick.bind(this),
                        },
                        {
                            icon: 'notifications_off',
                            tooltip: 'UNSUBSCRIBE',
                            // Only one bell at a time
                            displayNone: this.unsubscribeDisplayNone.bind(this),
                            click: this.unsubscribeClick.bind(this),
                        },
                        {
                            type: 'warn',
                            icon: 'delete',
                            tooltip: 'DELETE',
                            click: this.deleteClick.bind(this),
                            displayNone: this.deleteDisplayNone.bind(this),
                        },
                    ],
                },
            ],
        };
    }

    deleteDisplayNone(template) {
        return !CurrentOld.can(`customers.${CurrentOld.getCustomer().id}.checklists.${template.id}.delete`);
    }

    deleteClick(checklist) {
        this.$mdDialog.show(this.$mdDialog.confirm()
            .theme('delete')
            .title(t('checklists:DELETE_TEMPLATE'))
            .ok(t('DELETE'))
            .cancel(t('CANCEL'))).then(() => {
            this.ChecklistFactory.delete(CurrentOld.getCustomer(), checklist.id).$promise.then(() => {
                this.ToastService.toast(t('checklists:TEMPLATE_DELETED'));
                this.reloadTable = true;
            });
        });
    }

    subscribeClick(template) {
        this.ChecklistSubscription.subscribe.query(template.customer_id, template.id).$promise.then(() => {
            this.ToastService.toast(t('checklists:SUBSCRIBED_TO_CHECKLIST', {
                name: template.title,
            }));
            this.reloadTable = true;
        });
    }

    subscribeDisplayNone(template) {
        const isSubscribed = find(template.subscriptions, { user_id: CurrentOld.getUser().id });
        return isSubscribed && CurrentOld.can(this.ChecklistSubscription.subscribe.permission(CurrentOld.getCustomer().id, template.id));
    }

    unsubscribeDisplayNone(checklist) {
        const isSubscribed = find(checklist.subscriptions, { user_id: CurrentOld.getUser().id });
        return !isSubscribed && CurrentOld.can(this.ChecklistSubscription.subscribe.permission(CurrentOld.getCustomer().id, checklist.id));
    }

    unsubscribeClick(checklist) {
        const subscription = find(checklist.subscriptions, { user_id: CurrentOld.getUser().id });
        this.ChecklistSubscription.unsubscribe.query(checklist.customer_id, checklist.id, subscription.id).$promise.then(() => {
            this.ToastService.toast(t('checklists:UNSUBSCRIBED_FROM_CHECKLIST', {
                name: checklist.title,
            }));
            this.reloadTable = true;
        });
    }

    createChecklist() {
        return this.checklistDialog.template().then(() => {
            this.reloadTable = true;
        });
    }

    getTemplates(pagination) {
        return this.ChecklistFactory.getAll({
            customer: CurrentOld.getCustomer(),
            template: true,
            with: [ 'subscriptions' ],
            order_by: 'id',
            direction: 'desc',
            ...pagination.toWithPaginatorArguments(),
        });
    }
}
module('eaw.checklists').component('checklistTemplateList', {
    template: `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-i18next="navigation:CHECKLISTS_TEMPLATES"></span>
        </md-card-header-text>

        <card-btn-float on-click="ctCtrl.createChecklist()"></card-btn-float>
    </md-card-header>
    <md-card-content>
        <eaw-dynamic-ng-table
                columns="ctCtrl.table.columns"
                cells="ctCtrl.table.cells"
                get-data="ctCtrl.getTemplates(pagination)"
                reload-table="ctCtrl.reloadTable"
                go-to="eaw/app/checklists/edit">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`,
    controllerAs: 'ctCtrl',
    controller: ChecklistsTemplateListComponent,
});
