// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="ctrl.title"></eaw-dialog-header-old>

    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="eventForm" ng-disabled="!ctrl.editable" layout="column">
                <md-input-container>
                    <label ng-i18next="NAME"></label>
                    <input ng-model="ctrl.event.name" required uniq-form-name>
                </md-input-container>

                <md-input-container class="md-block">
                    <label ng-i18next="DESCRIPTION"></label>
                    <textarea ng-model="ctrl.event.description" rows="1" required uniq-form-name></textarea>
                </md-input-container>

                <eaw-date-time-interval
                        ng-if="!ctrl.wholeDay"
                        layout="row"
                        ng-model="ctrl.eventInterval"
                        required>
                </eaw-date-time-interval>

                <eaw-date-interval
                        ng-if="ctrl.wholeDay"
                        layout="row"
                        ng-model="ctrl.eventInterval"
                        required>
                </eaw-date-interval>

                <md-checkbox ng-model="ctrl.wholeDay">
                    <span ng-i18next="calendar:WHOLE_DAY"></span>
                </md-checkbox>

                <eaw-colorpicker flex="100" required="true" hex="true" ng-model="ctrl.event.color"></eaw-colorpicker>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-click="ctrl.submit()" form="eventForm" ng-disabled="eventForm.$invalid" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.calendar').service('CalendarEventService', [ '$mdDialog', 'CalendarEventFactory', function($mdDialog, CalendarEventFactory) {

    /**
     * @param {object} [event={}]
     * @param {string} [event.name=undefined]
     * @param {string} [event.description=undefined]
     * @param {moment} [event.from=undefined]
     * @param {moment} [event.to=undefined]
     * @param {boolean} [editable=true]
     */
    this.dialog = (event, editable) => $mdDialog.show({
        template: template1,
        controllerAs: 'ctrl',
        controller: 'CalendarEventDialogCtrl',
        bindToController: true,
        locals: {
            event,
            editable,
        },
    });

    this.getAll = (from, to) => new Promise((resolve, reject) => {
        const args = {
            from,
            to,
            per_page: 1,
        };
        CalendarEventFactory.getAll(args, true).then((paginator) => {
            // ask for a page with total number of events in the period (year start - year end);
            args.per_page = paginator.total;
            CalendarEventFactory.getAll(args).then(resolve, reject);
        }, reject);
    });

    /**
     * @param {object} [event]
     * @param {string} event.name
     * @param {int} event.id
     * @param {string} event.description
     * @param {moment} event.from
     * @param {moment} event.to
     */
    this.update = (event) => CalendarEventFactory.update({
        customer_id: CurrentOld.customer.id,
        event_id: event.id,
    }, event.from, event.to, event.name, event.description, event.color);

    /**
     * @param {object} [event]
     * @param {string} event.name
     * @param {string} event.description
     * @param {moment} event.from
     * @param {moment} event.to
     */
    this.add = (event) => CalendarEventFactory.create(CurrentOld.customer.id, event.from, event.to, event.name, event.description, event.color);

    this.delete = (event) => CalendarEventFactory.delete(CurrentOld.customer.id, event.id);

    return this;
} ]);
