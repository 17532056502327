<eaw-dialog-header icon="error" iconColor="red-400" [showCloseButton]="false">
    <span title>{{ 'ERROR_STATUS' | translate: 'errors': { status: status } | async }}</span>
</eaw-dialog-header>

<mat-dialog-content id="content" class="tw-flex tw-flex-col">
    @for (message of messages; track message) {
        <div class="mat-body-2 tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-24">
            <span class="message">{{ message }}</span>
            @if (ignorable) {
                <mat-checkbox eawCheckboxHelper
                              [formControl]="ignoreForm.at($index)">{{ 'IGNORE' | translate: 'errors' | async }}
                </mat-checkbox>
            }
        </div>
    }
    @if (showException) {
        <pre style="overflow: auto">
<!--        Pre formatted text will show tabs if you add them at the start -->
Debugging information
----------------------------------------------------------------------------

Url: {{ url }}
Exception: {{ exception }}

Stack Trace: {{ stackTrace | json }}
    </pre>
    } @else {
        <small class="url"> {{ url }} </small>
    }
</mat-dialog-content>

<mat-dialog-actions class="tw-flex tw-justify-end">
    <button mat-button mat-dialog-close="" autofocus> {{ 'OK' | translate | async }}</button>
    @if (ignorable) {
        <button mat-button (click)="resend()" [disabled]="ignoreForm.invalid"
                autofocus> {{ 'RESEND' | translate: 'errors' | async }}
        </button>
    }
</mat-dialog-actions>
