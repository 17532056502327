import { module } from 'angular';
import { EawResourceFactory } from '../resource/resource.service';
import { SettingResponse } from '../../../models/setting';

export class CustomerSettings {
    EawResource;

    static get $inject() {
        return [ 'EawResource' ];
    }

    url = '/customers/:customer/settings/:setting';

    constructor(EawResource: EawResourceFactory) {
        this.EawResource = EawResource;
    }

    get(customerId: number, key: string, options: any): Promise<any> {
        return this.EawResource.create(`/customers/${customerId}/settings/${key}`).get({
            ...options,
        }).$promise;
    }

    /**
     * @param {int}         customerId  - ID of the customer
     * @param {string[]}    [settings]  - Array with specified settings
     * @param {string}      [filter]    - All settings containing filter
     * @param {string}      [key]       - key in setting meta-data to search
     * @param {Object}      [options]   - e.g. pagination
     * @returns {*}
     */
    getSome(customerId: number, settings: string[], filter?: string, key?: string, options?: any): Promise<SettingResponse[] | Record<string, SettingResponse>> {
        const res: any = this.EawResource.create(`/customers/${customerId}/settings`);
        const data: any = {
            ...options,
            'settings[]': settings,
            filter,
            key,
            array: true,
        };
        const promise = res.query(data).$promise;
        if (options?.array) {
            return promise;
        }

        return promise.then((response: SettingResponse[]) => {
            return response.reduce((acc: Record<string, SettingResponse>, setting: SettingResponse) => {
                acc[setting.key] = setting;
                return acc;
            }, {});
        });
    }
}

module('eaw.customers').service('CustomerSettings', CustomerSettings);
