// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.calendar').service('EventDataSource', [ 'DataSource', 'CalendarEventService', '$mdDialog', 'ToastService', 'CalendarEventFactory', 'CalendarEvent', function EventDataSourceClass(DataSource, CalendarEventService, $mdDialog, ToastService, CalendarEventFactory, CalendarEvent) {
    class EventDataSource extends DataSource {
        constructor(customer) {
            super(customer);

            this.name = 'events';
            this.i18n = EventDataSource.getI18nName();
            this.useOnEmployee = false;
            this.createRequiresEmployee = false;
            this.active = true;
            this.noItemsText = t('calendar:NO_EVENTS_THIS_DAY');
            this.canUseSource = CurrentOld.can(`customers.${this.customer.id}.calendar_events.*.get`);
            this.button = {
                text: t('calendar:EVENT'),
                onClick: this.create.bind(this),
                icon: 'calendar',
                hasPermission: CurrentOld.can(`customers.${this.customer.id}.calendar_events.create`),
            };
            this.contextMenu = [
                {
                    text: t('EDIT'),
                    icon: 'edit',
                    onClick: this.update.bind(this),
                    hasPermission: CurrentOld.can(`customers.${this.customer.id}.calendar_events.*.update`),
                },
                {
                    text: t('DELETE'),
                    icon: 'delete',
                    onClick: this.delete.bind(this),
                    hasPermission: CurrentOld.can(`customers.${this.customer.id}.calendar_events.*.delete`),
                },
            ];
        }

        /**
         * @param {moment.Moment} from
         * @param {moment.Moment} to
         * @returns {Promise}
         */
        get(from, to) {
            return CalendarEventFactory.getAll({
                customer: this.customer,
                from,
                to,
            }).then((resp) => {
                // Make data into events
                resp.data.forEach((d) => {
                    const event = new CalendarEvent(d);
                    event.broadcastAdded();
                });
            });
        }

        /**
         * @param {moment.Moment} from
         * @param {moment.Moment} [to]
         * @returns {Promise}
         */
        create(from, to) {
            [ from, to ] = DataSource.assignFromTo(from, to);

            return CalendarEventService.dialog({
                from,
                to,
            }).then((newEvent) => {
                CalendarEventFactory.create(
                    this.customer.id,
                    newEvent.from,
                    newEvent.to,
                    newEvent.name,
                    newEvent.description,
                    newEvent.color,
                ).then((event) => {
                    event = new CalendarEvent(event);
                    event.broadcastAdded();
                    ToastService.tToast('calendar:EVENT_ADDED', { name: event.name });
                });
            });
        }

        /**
         * @param {moment.Moment} date
         * @param {CalendarEvent} event
         * @returns {Promise}
         */
        update(_, event) {
            return CalendarEventService.dialog(event).then((updatedEvent) => {
                CalendarEventFactory.update(
                    {
                        customer_id: this.customer.id,
                        event_id: updatedEvent.id,
                    },
                    updatedEvent.from,
                    updatedEvent.to,
                    updatedEvent.name,
                    updatedEvent.description,
                    updatedEvent.color,
                ).then((respEvent) => {
                    event.broadcastDeleted();
                    respEvent = new CalendarEvent(respEvent);
                    respEvent.broadcastAdded();
                    ToastService.tToast('calendar:EVENT_UPDATED');
                });
            });
        }

        delete(_, event) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .theme('delete')
                    .title(t('calendar:DELETE_EVENT'))
                    .textContent(t('calendar:DELETE_EVENT_TEXT', { name: event.name }))
                    .ok(t('DELETE'))
                    .cancel(t('CANCEL')),
            ).then(() => {
                CalendarEventFactory.delete(this.customer.id, event.id).then(() => {
                    ToastService.tToast('calendar:EVENT_DELETED', { name: event.name });
                    event.broadcastDeleted();
                });
            });
        }

        static getI18nName() {
            return t('calendar:EVENT_plural');
        }
    }

    return EventDataSource;
} ]);
