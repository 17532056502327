import type { NamespaceFile } from '../../shared/enums/namespace';
import { MatSortHeader } from '@angular/material/sort';

interface BaseDataTableHeader {
    sortBy?: string;
    classes?: Set<string>;
}

interface I18nDataTableHeader extends BaseDataTableHeader{
    text?: never,
    i18n?: string,
    ns?: NamespaceFile,
}

interface TextDataTableHeader extends BaseDataTableHeader{
    text?: string;
    i18n?: never,
    ns?: never,
}

export class DataTableHeader {
    sortBy: BaseDataTableHeader['sortBy'];
    text?: string;
    i18n?: string;
    ns?: NamespaceFile;
    classes: Set<string>;
    arrowPosition: MatSortHeader['arrowPosition'] = 'before';

    constructor(header: I18nDataTableHeader | TextDataTableHeader = {}) {
        this.sortBy = header.sortBy;
        this.text = header.text;
        this.i18n = header.i18n;
        this.ns = header.ns;
        this.classes = header.classes || new Set<string>();
        this.arrowPosition = 'after';
    }
}
