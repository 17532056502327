// @ts-nocheck
import { module } from 'angular';

module('eaw.staffing').component('staffingHeadcount', {
    template: `<md-card ng-show="$headcount.showFilter">
    <md-card-content layout="column">
        <div layout="row">
            <md-input-container flex="100" flex-gt-sm="50">
                <label ng-i18next="FROM"></label>
                <input ng-model="$headcount.template.from" type="number">
            </md-input-container>

            <md-input-container flex="100" flex-gt-sm="50">
                <label ng-i18next="TO"></label>
                <input ng-model="$headcount.template.to" type="number">
            </md-input-container>
        </div>

        <div layout="row">
            <md-button class="md-raised md-accent" ng-i18next="UPDATE" ng-click="$headcount.tableParams.reload()"></md-button>
        </div>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-bind="::$headcount.template.name"></span>
        </md-card-header-text>

        <eaw-toggle-btn ng-model="$headcount.showFilter"
                        icon="filter_alt"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>

        <md-button ng-if="$headcount.staffingService.canAddInterval($headcount.template)"
                   class="md-icon-button"
                   ng-click="$headcount.staffingService.addInterval($headcount.template, $headcount.tableParams)">
            <md-icon ng-bind="'add'"></md-icon>
            <md-tooltip>{{'staffing:ADD_INTERVAL' | i18next}}</md-tooltip>
        </md-button>

        <md-button ng-if="$headcount.staffingService.canGenerateIntervals($headcount.template)"
                   class="md-icon-button"
                   ng-click="$headcount.staffingService.generateIntervals($headcount.template, $headcount.tableParams)">
            <md-icon ng-bind="'magic_button'"></md-icon>
            <md-tooltip>{{'staffing:GENERATE_INTERVALS' | i18next}}</md-tooltip>
        </md-button>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <table ng-table="$headcount.tableParams"
               template-pagination="modules/templates/ng-table-pagination.ajs.html"
               class="table staffing-table table-bordered table-condensed table-vmiddle table-hover">
            <thead>
            <tr>
                <th ng-i18next="INTERVAL" style="text-align: right"></th>
                <th ng-i18next="AMOUNT" style="text-align: right"></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="interval in $data track by interval.id">
                <td ng-bind="interval | fromToText:'from':'to'"></td>
                <td class="input">
                    <input ng-model="interval.data[0].data"
                           style="box-sizing: border-box"
                           ng-focus="$headcount.focus(interval)"
                           ng-blur="$headcount.blur(interval)"
                           ng-disabled="interval.data[0].disabled"
                           type="number">
                </td>
                <td>
                    <eaw-icon-button
                            stop-propagate
                            ng-click="$headcount.staffingService.updateInterval($headcount.template, interval, $headcount.tableParams)"
                            icon="edit"
                            ng-disabled="interval.disabled"
                            tooltip="'staffing:UPDATE_INTERVAL'">
                    </eaw-icon-button>
                    <eaw-icon-button
                            stop-propagate
                            ng-click="$headcount.staffingService.deleteInterval($headcount.template, interval, $headcount.tableParams)"
                            icon="delete"
                            type="warn"
                            ng-disabled="interval.disabled"
                            tooltip="'staffing:DELETE_INTERVAL'">
                    </eaw-icon-button>
                </td>
            </tr>
            </tbody>
        </table>
    </md-card-content>
</md-card>`,
    controllerAs: '$headcount',
    bindings: {
        template: '<',
    },
    controller: [ 'staffingService', 'staffingDataFactory', function(staffingService, staffingDataFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            console.log(`ctrl.template`, ctrl.template);
            ctrl.staffingService = staffingService;
            ctrl.tableParams = staffingService.createNgTableParams(ctrl.template); // Create table
        };

        ctrl.focus = (interval) => {
            interval.oldValue = interval.data?.[0]?.data;
        };

        ctrl.blur = (interval) => {
            const data = interval?.data?.[0] || {};
            const newValue = interval?.data?.[0]?.data;

            data.disabled = true;

            switch (staffingService.getDataAction(interval.oldValue, newValue)) {
                case 'create': {
                    ctrl.create(interval, data, newValue);
                    break;
                }
                case 'update': {
                    ctrl.update(interval, data, newValue);
                    break;
                }
                case 'delete': {
                    ctrl.delete(interval, data);
                    break;
                }
                default: {
                    data.disabled = false;
                }
            }
        };

        ctrl.create = (interval, data, value) => staffingDataFactory.create.query(ctrl.template.customer_id, ctrl.template.id, interval.id, value).$promise
            .then((data) => interval.data[0] = data)
            .catch(() => interval.data[0] = interval.oldValue)
            .finally(() => data.disabled = false);

        ctrl.update = (interval, data, value) => staffingDataFactory.update.query(ctrl.template.customer_id, ctrl.template.id, interval.id, data.id, value).$promise
            .then((data) => interval.data[0] = data)
            .catch(() => interval.data[0] = interval.oldValue)
            .finally(() => data.disabled = false);

        ctrl.delete = (interval, data) => staffingDataFactory.delete.query(ctrl.template.customer_id, ctrl.template.id, interval.id, data.id).$promise
            .then(() => interval.data[0] = {})
            .catch(() => interval.data[0] = interval.oldValue)
            .finally(() => data.disabled = false);
    } ],
});
