// @ts-nocheck
import { module } from 'angular';
export default class EventInfoPanel {
    $mdPanel;
    static get $inject() {
        return [ '$mdPanel' ];
    }

    static template = `<div class="actions">
    <md-button class="md-icon-button" ng-click="ctrl.edit()" ng-show="ctrl.event.access.edit">
        <md-icon ng-bind="'edit'"></md-icon>
    </md-button>

    <md-button class="md-icon-button" ng-click="ctrl.delete()" ng-show="ctrl.event.access.edit">
        <md-icon ng-bind="'delete'"></md-icon>
    </md-button>

    <!--    <md-button class="md-icon-button">-->
    <!--        <md-icon md-svg-src="email-outline"></md-icon>-->
    <!--    </md-button>-->

    <!--    <md-button class="md-icon-button">-->
    <!--        <md-icon ng-bind="'more_vert'"></md-icon>-->
    <!--    </md-button>-->

    <md-button class="md-icon-button" ng-click="ctrl.close()">
        <md-icon ng-bind="'close'"></md-icon>
    </md-button>
</div>

<div class="info-container">
    <div class="section-icon">
        <div class="event-color" ng-style="{background: ctrl.event.calendar.color}"></div>
    </div>

    <div class="section">
        <div class="event-title" ng-bind="ctrl.event.title"></div>
        <div ng-if="!ctrl.event.wholeDay">
            <span ng-bind="ctrl.event.start.format('dddd[,] MMMM DD [⋅] LT –')"></span>
            <span ng-bind="ctrl.event.end.format('LT')"></span>
        </div>

        <div ng-if="ctrl.event.wholeDay">
            <span ng-bind="ctrl.event.start.format('LL')"></span>
            <span ng-if="ctrl.event.eventDays > 1">–</span>
            <span ng-if="ctrl.event.eventDays > 1" ng-bind="ctrl.event.end.format('LL')"></span>
        </div>
    </div>

    <div class="section-icon">
        <md-icon ng-bind="'star'"></md-icon>
    </div>

    <div class="section">
        <div ng-bind="ctrl.event.organizer.name"></div>
    </div>

    <div class="section-icon" ng-if="ctrl.event.participants.length">
        <md-icon ng-bind="'group'"></md-icon>
    </div>

    <div class="section" ng-if="ctrl.event.participants.length">
        <div layout="column">
            <div ng-i18next="[i18next]({count: ctrl.event.participants.length + 1})calendar:X_GUEST"></div>

            <div id="participants">
                <div class="participant organizer">
                    <eaw-profile-picture-old user="ctrl.organizerUser" size="25"></eaw-profile-picture-old>

                    <div layout="column">
                        <div ng-bind="ctrl.event.organizer.name"></div>
                        <small ng-i18next="calendar:ORGANIZER"></small>
                    </div>
                </div>

                <div class="participant" ng-repeat="participant in ctrl.event.participants track by participant.key">
                    <md-icon class="status-icon" md-svg-src="{{participant.status.icon}}" ng-class="[participant.status.value]"></md-icon>
                    <eaw-profile-picture-old user="{id: participant.userId, name: participant.name}" size="25"></eaw-profile-picture-old>
                    <span layout="column" ng-bind="participant.name"></span>
                </div>
            </div>
        </div>
    </div>

    <div class="section-icon" ng-if="ctrl.event.description">
        <md-icon ng-bind="'notes'"></md-icon>
    </div>

    <div class="section" ng-if="ctrl.event.description">
        <div ng-bind="ctrl.event.description"></div>
    </div>

    <div class="section-icon">
        <md-icon ng-bind="'calendar_month'"></md-icon>
    </div>

    <div class="section">
        <div class="event-calendar" ng-bind="ctrl.event.calendar.name"></div>
    </div>
</div>

<div id="participant-actions" class="section" ng-if="ctrl.event.participant">
    <span ng-i18next="calendar:GOING_QUESTION"></span>

    <md-content class="md-dense">
        <md-button ng-i18next="YES"
                   ng-class="{'md-accent': ctrl.event.participant.status.value === ctrl.participantStatuses.yes.value}"
                   ng-click="ctrl.respond(ctrl.participantStatuses.yes.value)">
        </md-button>

        <md-button ng-i18next="NO"
                   ng-class="{'md-accent': ctrl.event.participant.status.value === ctrl.participantStatuses.no.value}"
                   ng-click="ctrl.respond(ctrl.participantStatuses.no.value)">
        </md-button>

        <md-button ng-i18next="MAYBE"
                   ng-class="{'md-accent': ctrl.event.participant.status.value === ctrl.participantStatuses.maybe.value}"
                   ng-click="ctrl.respond(ctrl.participantStatuses.maybe.value)">
        </md-button>
    </md-content>
</div>
`;

    constructor($mdPanel) {
        this.$mdPanel = $mdPanel;
    }

    /**
     * @param {Calendar2Event}  event
     * @param {HTMLElement}     element
     * @param {MouseEvent}      e
     * @param {object}          [args]
     * @param {function}        [args.onAction] - Fires when info panel is closed due to a user action, eg. clicking edit.
     */
    open(event, element, e, args = {}) {
        const target = (e.target || document.body);
        this.$mdPanel.open({
            attachTo: document.body,
            controller: 'eventInfoPanelCtrl',
            controllerAs: 'ctrl',
            template: EventInfoPanel.template,
            panelClass: 'eaw-panel event-info-panel',
            position: this.$mdPanel.newPanelPosition()
                .relativeTo(target)
                .withOffsetX('-8')
                .addPanelPosition(this.$mdPanel.xPosition.OFFSET_START, this.$mdPanel.yPosition.ALIGN_TOPS),
            animation: this.$mdPanel.newPanelAnimation()
                .openFrom(target)
                .duration(100)
                .withAnimation(this.$mdPanel.animation.SCALE),
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            bindToController: true,
            zIndex: 100,
            locals: {
                event,
                element,
                args,
            },
        });
    }
}
module('eaw.calendar2').service('EventInfoPanel', EventInfoPanel);
