// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
const template1 = `<md-dialog class="mini-dialog">
    <eaw-dialog-header-old heading="dtpdCtrl.label"></eaw-dialog-header-old>
    <md-dialog-content>
        <form name="dateTimePickerForm"
              class="md-dialog-content"
              layout="row"
              layout-wrap>
            <md-input-container flex="100" flex-gt-xs="50">
                <eaw-date-picker
                        min-date="dtpdCtrl.minDate"
                        max-date="dtpdCtrl.maxDate"
                        ng-required="true"
                        ng-model="dtpdCtrl.date">
                </eaw-date-picker>
            </md-input-container>

            <md-input-container flex="100" flex-gt-xs="50">
                <eaw-time-picker-old
                        base-date="dtpdCtrl.date"
                        min-time="dtpdCtrl.minTime"
                        max-time="dtpdCtrl.maxTime"
                        required
                        ng-model="dtpdCtrl.time">
                </eaw-time-picker-old>
            </md-input-container>
        </form>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-click="dtpdCtrl.submit()"
                   ng-i18next="SUBMIT"
                   ng-disabled="dateTimePickerForm.$invalid || dateTimePickerForm.$pristine || dtpdCtrl.isDisabled()"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.time').factory('eawDateTimePickerDialog', [ '$mdDialog', function eawDateTimePickerDialog($mdDialog) {
    const service = this;

    service.open = (dateTime, label, options) => $mdDialog.show({
        template: template1,
        controllerAs: 'dtpdCtrl',
        controller: 'dateTimePickerDialogCtrl',
        multiple: true,
        locals: {
            dateTime,
            label: label ? label : t('DATE'),
            options,
        },
    });

    return service;
} ]);
