<eaw-dialog-header>
    <span title>{{'HOURS_PLANNED' | translate:'scheduling' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-p-0">
    @if (loading()) {
        <eaw-info-loading class="tw-py-24" size="lg"/>
    } @else {
        <table mat-table [dataSource]="dataSource()">
            @for (col of displayColumns(); track col) {
                @if (col === 'employeeName') {
                    <ng-container [matColumnDef]="col" sticky>
                        <th mat-header-cell *matHeaderCellDef>{{ 'EMPLOYEE' | translate | async }}</th>
                        <td mat-cell *matCellDef="let element"> {{ element[col] }}</td>
                        <td mat-footer-cell *matFooterCellDef>{{ 'DAY_SUM' | translate | async }}</td>
                    </ng-container>
                } @else if (col === 'employeeSum') {
                    <ng-container [matColumnDef]="col" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef>{{ 'EMPLOYEE_SUM' | translate | async }}</th>
                        <td class="tw-text-right" mat-cell *matCellDef="let element"> {{ element.employeeSum }}</td>
                        <td class="tw-text-right" mat-footer-cell *matFooterCellDef>{{ scheduleTotal() | eawNumber:2 }}</td>
                    </ng-container>
                } @else {
                    <ng-container [matColumnDef]="col">
                        <th mat-header-cell *matHeaderCellDef>{{ col }}</th>
                        <td class="tw-text-right number-cell" mat-cell *matCellDef="let element"> {{ element[col] }}</td>
                        <td class="tw-text-right" mat-footer-cell *matFooterCellDef>{{ dayTotals()[col] | eawNumber:2 }}</td>
                    </ng-container>
                }
            }

            <tr mat-header-row *matHeaderRowDef="displayColumns(); sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns();"></tr>
            <tr mat-footer-row *matFooterRowDef="displayColumns(); sticky: true"></tr>
        </table>
    }

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="">{{ 'CLOSE' | translate | async }}</button>
</mat-dialog-actions>
