import { inject, Injectable, signal } from '@angular/core';
import { WebsocketService } from './websocket.service';
import { PushService } from './push.service';
import { EawUrl } from '../angularjs/modules/resource/url.service';
import { Cache } from '../utils/cache';
import { Storage } from '../utils/storage';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { AppService } from './app.service';
import { Mobile } from '../utils/eaw-mobile';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { lastValueFrom, race, Subject, timer } from 'rxjs';
import { TokenStorageKeys } from '../../auth/typings/token-storage-keys';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent, AlertDialogData } from '../dialogs/alert-dialog/alert-dialog.component';
import { DialogSize } from '../dialogs/dialog-component';
import { reportError } from '../angularjs/modules/misc/services/easy-funcs.service';
import { DateTime } from 'luxon';

type EmailLogin = { email: string, password: string, token?: never };
type TokenLogin = { email?: never, password?: never, token: string };

type LogOutReason = {
    message: Promise<string>;
    httpError?: HttpErrorResponse;
};
@Injectable({
    providedIn: 'root',
})
export class LoginService {
    private readonly websocketService = inject(WebsocketService);
    private readonly pushService = inject(PushService);
    private readonly oAuthService = inject(OAuthService);
    private readonly appService = inject(AppService);
    private readonly matDialog = inject(MatDialog);
    private readonly http = inject(HttpClient);

    private loginErrorSubject = new Subject<HttpErrorResponse>();

    broadcastLoginError(errorResponse: HttpErrorResponse) {
        this.loginErrorSubject.next(errorResponse);
    }

    onLoginError() {
        return this.loginErrorSubject.asObservable();
    }

    async login(args: EmailLogin | TokenLogin): Promise<TokenResponse> {
        this.oAuthService.configure({
            issuer: EawUrl.url,
            requireHttps: environment.isLive,
            clientId: '2',
            tokenEndpoint: EawUrl.url + '/oauth/token',
            revocationEndpoint: EawUrl.url + '/logout',
            scope: '',
            showDebugInformation: !environment.isLive,
        });

        let response: Promise<TokenResponse | null> = Promise.resolve(null);
        if (args.token) {
            const tokenResponse = {
                access_token: args.token,
                refresh_token: '',
                expires_in: DateTime.now().plus({ years: 1 }).toSeconds() - DateTime.now().toSeconds(),
                token_type: 'password',
                id_token: '',
                scope: '',
            } satisfies TokenResponse;

            response = Promise.resolve(tokenResponse);
        }

        if (args.password && args.email) {
            response = this.oAuthService.fetchTokenUsingPasswordFlow(args.email, args.password);
        }

        return response.then(async (tokenResponse) => {
            if (!tokenResponse) {
                return Promise.reject('No token');
            }

            await Storage.setItem(TokenStorageKeys.ACCESS_TOKEN, tokenResponse.access_token);
            await Storage.setItem(TokenStorageKeys.ACCESS_TOKEN_STORED_AT, String(DateTime.now().toMillis()));
            await Storage.setItem(TokenStorageKeys.EXPIRES_AT, String(DateTime.now().plus({ seconds: tokenResponse.expires_in }).toMillis()));
            await Storage.setItem(TokenStorageKeys.REFRESH_TOKEN, tokenResponse.refresh_token);

            return tokenResponse;
        });
    }

    /**
     * Logs out the user, revoking the token and clearing up any data we've stored
     * @param authedUserId - The user id of the user we are authenticated as
     * @param reason - The reason _why_ we are logging out
     * @param skipReload
     */
    async logout(authedUserId: number | undefined, reason: LogOutReason | undefined, skipReload = false) {
        if (this.isLoggedIn()) {
            if (Mobile.isMobile) {
                // Deregister push notifications. Need a valid token to do this
                if (authedUserId) {
                    await this.pushService.deregister(authedUserId);
                }
            }

            // Revoke token
            await this.revokeToken();
        }

        return await this.afterLogout(reason, skipReload);
    }

    private async afterLogout(reason: LogOutReason | undefined, skipReload = false) {
        // Remove token from the oAuth service,
        // must be done after actually revoking the token, since that relies on the token being added in an interceptor
        this.oAuthService.logOut(true);

        // Clear up any data we've stored
        await this.clearStorage();

        if (skipReload) {
            return;
        }

        const loginUrl = location.origin;

        // Reload the app
        if (reason) {
            const message: string = await reason.message;
            reportError(new Error(message + (reason.httpError ? ` ${reason.httpError.status}: ${reason.httpError.message}` : '')));

            const dialogClosed = this.matDialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
                disableClose: true,
                data: {
                    text: signal(reason.message),
                    title: signal(Promise.resolve('Logging you out')),
                    size: DialogSize.Small,
                },
            }).afterClosed();

            race([ dialogClosed, timer(10_000) ]).subscribe(() => {
                this.appService.reload(loginUrl);
            });
        } else {
            this.appService.reload(loginUrl);
        }
    }

    protected clearStorage() {
        return Promise.allSettled([
            // Empty local storage, removing token and other things
            Storage.clear(),
            // Clear token and things from mobile
            Mobile.isMobile ? Mobile.storageClear() : Promise.resolve(),
            // Clear token things and other things from indexedDB
            Cache.clearAll(),
        ]);
    }

    isLoggedIn() {
        return this.oAuthService.hasValidAccessToken();
    }

    revokeToken() {
        // Revoke token from the server
        return lastValueFrom(this.http.post('/logout', {}));
    }
}
