<eaw-dialog-header>
    <span title> {{ dialogTitle | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" id="interval-form" (ngSubmit)="close()" class="tw-grid tw-grid-cols-1 tw-gap-4">
        <eaw-date-time-range-input class="tw-w-full tw-grid tw-grid-cols-2 tw-gap-8">
            <mat-form-field eawDateTimeFrom>
                <mat-label>{{'FROM' | translate | async}}</mat-label>
                <eaw-date-time-input formControlName="from" [required]="true" [minDate]="minDate" ></eaw-date-time-input>
            </mat-form-field>

            <mat-form-field eawDateTimeTo>
                <mat-label>{{'TO' | translate | async}}</mat-label>
                <eaw-date-time-input formControlName="to" [minDate]="minDate" ></eaw-date-time-input>
            </mat-form-field>
        </eaw-date-time-range-input>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button type="button" mat-dialog-close>{{ 'CLOSE' | translate | async }}</button>
    <eaw-action-button type="submit" formId="interval-form" [disabled]="form.invalid"
                       [loading]="false">{{ 'OK' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>
