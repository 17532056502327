import { inject, Pipe, PipeTransform } from '@angular/core';
import { ProductName, Products } from '../enums/products';
import { CustomerProductService } from '../http/customer-product.service';
import { CurrentService } from '../services/current.service';

@Pipe({
    name: 'product',
    standalone: true,
})
export class ProductPipe implements PipeTransform {
    private customerProductService = inject(CustomerProductService);
    private currentService = inject(CurrentService);

    transform(products: (ProductName | Products)[], customerId?: number) {
        return this.customerProductService.hasProducts(customerId || this.currentService.getCustomer().id, products);
    }
}
