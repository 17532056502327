import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { NgClass } from '@angular/common';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { WeatherForecastTime } from '../../classes/weather-forecast-time';

@Component({
    selector: 'eaw-temperature',
    standalone: true,
    imports: [
        NgClass,
        NumberPipe,
    ],
    templateUrl: './temperature.component.html',
    styleUrl: './temperature.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemperatureComponent {
    forecastTime = input.required<WeatherForecastTime>();
    darkBackground = input<boolean>(false);

    protected unit = computed(() => this.forecastTime().unit);
    protected textClass: Signal<Record<string, boolean>>;

    constructor() {
        this.textClass = computed(() => {
            const isNegative = this.forecastTime().isNegative;
            const darkBackground = this.darkBackground();
            return {
                'hot-dark': !isNegative && darkBackground,
                'cold-dark': isNegative && darkBackground,
                'hot-light': !isNegative && !darkBackground,
                'cold-light': isNegative && !darkBackground,
            };
        });
    }
}
