import { Component, inject, input, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetComponent } from '../../classes/widget-component';
import { Widget } from '../../classes/widget';
import { VacationDaysService } from '../../../vacations/http/vacation-days.service';
import { DateTime } from 'luxon';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { Namespace } from '../../../shared/enums/namespace';

@Component({
    selector: 'eaw-mini-vacation-days-widget',
    standalone: true,
    imports: [ CommonModule, DurationPipe, MiniWidgetContentComponent ],
    templateUrl: './mini-vacation-days-widget.component.html',
    styleUrl: './mini-vacation-days-widget.component.scss',
})
export class MiniVacationDaysWidgetComponent extends WidgetComponent implements OnInit {
    translateService = inject(TranslateService);
    vacationDaysService = inject(VacationDaysService);

    widget = input.required<Widget>();

    text = signal('');
    subtext = signal(Promise.resolve(''));

    ngOnInit() {
        const year = DateTime.now().year;
        this.vacationDaysService.getStats(this.widget().info.customer.id, this.widget().info.employee?.id || 0, year).subscribe((stats) => {
            this.text.set(`${stats.days} / ${stats.received}`);
            this.subtext.set(this.translateService.t('VAC_DAY_SUBTITLE', Namespace.Widgets));

            this.setLoading(false);
        });
    }
}
