// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';

const template1 = `<div id="events-information-sidebar" layout="column">
    <div layout="row" layout-align="start center" layout-padding>
        <h4 flex ng-bind="$ctrl.period"></h4>
        <eaw-icon-button ng-if="$ctrl.canAdd" ng-click="$ctrl.addEvent()" type="accent" fab="true" icon="add"></eaw-icon-button>
    </div>

    <md-list flex>
        <md-list-item class="md-3-line" ng-repeat="event in $ctrl.events track by event.id" ng-click="$ctrl.showEvent(event)">
            <div class="md-list-item-text" layout="column">
                <h3 ng-bind="event.name"></h3>
                <h4 ng-bind="event.description"></h4>
                <p ng-bind="event.fromTo"></p>
                <md-divider ng-if="!$last"></md-divider>
            </div>
        </md-list-item>
    </md-list>
</div>
`;

module('eaw.scheduling').component('sidebarEvents', {
    template: template1,
    bindings: {
        from: '<',
        to: '<',
    },
    require: {
        sidebar: '^scheduleSidebar',
    },
    controller: [ 'CalendarEventService', 'scheduleTable', 'CalendarEventFactory', 'ToastService', function(CalendarEventService, scheduleTable, CalendarEventFactory, ToastService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.canAdd = CurrentOld.can(`customers.${scheduleTable.getSchedule().customer_id}.calendar_events.create`);
            ctrl.period = `${ctrl.from.format('ll')} - ${ctrl.to.format('ll')}`;
            ctrl.getEvents();
        };

        ctrl.getEvents = () => {
            CalendarEventFactory.getAll({
                period: 1,
                from: ctrl.from,
                to: ctrl.to,
                order_by: 'from',
            }).then((data) => {
                data.data.map(ctrl.createFromToString);
                ctrl.events = data.data;
            });
        };

        ctrl.createFromToString = (event) => {
            event.fromTo = `${event.from.format('LL')} - ${event.to.format('LL')}`;
        };

        ctrl.addEvent = () => {
            CalendarEventService.dialog({
                from: ctrl.from.clone(),
                to: ctrl.to.clone(),
            }).then((result) => {
                CalendarEventService.add(result).then((event) => {
                    ToastService.tToast('X_ADDED', { name: '$t(calendar:EVENT)' });
                    ctrl.createFromToString(event);
                    ctrl.events.push(event);
                });
            });
        };

        ctrl.showEvent = (event) => CalendarEventService.dialog(event, false);
    } ],
});
