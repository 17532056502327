// @ts-nocheck
import { set } from 'lodash-es';
import { module } from 'angular';
import { DateTimeConverter } from '../../../../utils/date-time-converter';
import { EawUrl } from '../url.service';

module('eaw.resource').factory('responseInterceptor', [ 'errorService', '$injector', function responseInterceptor(errorService, $injector) {
    const pivotDates = [ 'from', 'to', 'created_at', 'updated_at', 'deleted_at', 'business_date' ]; // Add more as needed
    const interceptor = this;

    interceptor.walk = (obj) => {
        Object.entries(obj || {}).forEach((entry) => {
            const key = entry[0];
            const value = entry[1];

            switch (key) {
                case 'email':
                    // Only trim when strings
                    if (typeof value === 'string') {
                        obj[key] = value.trim();
                    }
                    break;
                case '_dates':
                    Object.values(value).forEach((dateField) => {
                        const bd = dateField === 'business_date';
                        if (obj[dateField]) {
                            obj[dateField] = DateTimeConverter.convertStringToMoment(obj[dateField], bd);
                        }
                    });
                    break;
                case '_business_dates':
                    Object.values(value).forEach((dateField) => {
                        if (obj[dateField]) {
                            obj[dateField] = DateTimeConverter.convertStringToMoment(obj[dateField], true);
                        }
                    });
                    break;
                // Check for pivot
                case 'pivot':
                    pivotDates.forEach((dateField) => {
                        if (obj.pivot[dateField]) {
                            obj.pivot[dateField] = DateTimeConverter.convertStringToMoment(obj.pivot[dateField]);
                        }
                    });
                    break;
                default:
                // No default
            }

            // Recursion ^^
            if (Array.isArray(value) || typeof value === 'object') {
                interceptor.walk(obj[key]);
            }
        });
    };

    interceptor.shouldWalk = function shouldWalk(response) {
        // Skip these
        const baddies = [
            {
                url: '/badges',
                type: 'endsWith',
            }, // No need
            {
                url: '/me',
                type: 'endsWith',
            }, // Don't remember
            {
                url: '/holidays/',
                type: 'includes',
            }, // Date property fucks with cache
            {
                url: '/holidays',
                type: 'endsWith',
            }, // Date property fucks with cache
        ];

        const notViaCache = !response.config?.headers?.['X-Eaw-Cache'];
        const noBaddies = !baddies.some((bad) => response.config.url[bad.type]?.(bad.url));

        return noBaddies && notViaCache;
    };

    interceptor.response = (response) => {
        if (response.status === 401) {
            return $injector.get('LoginServiceDowngrade').logout();
        }

        if (EawUrl.isApiUrl(response.config?.url)) {
            EawUrl.handleHeader(response);

            // Do the walking first, all other stuff later
            if (interceptor.shouldWalk(response) && Object.keys(response.data || []).length) {
                interceptor.walk(response.data);
            }
        }

        return response;
    };

    interceptor.responseError = (rejection) => {
        // If the rejection comes from us due to a cached response
        if (rejection.isCached) {
            return Promise.resolve(rejection);
        }

        if (shouldShowError(rejection)) {
            errorService.displayXhrError(rejection).then((hashes) => {
                // Set hashes that allows us to ignore the expections
                set(rejection, 'config.data.ignore_exceptions', hashes);

                // Avoid circular dependency and resend the request
                $injector.get('$http')(rejection.config).then(() => {
                    rejection.callback?.();
                });
            });
        }

        return Promise.reject(rejection);
    };

    function hasIgnoreError(response) {
        return response.config.headers['X-Ignore-Error'];
    }

    function isWhitelisted(response) {
        const whitelist = [ // List of urls where not to display an error
            '/badges',
            '/notifications',
            '/progress',
            '/logout',
            '/picture',
            '/logo',
            '/signables',
            '/oauth/token', // This is handled in login controller instead.
        ];

        return whitelist.every((url) => !response.config.url.endsWith(url));
    }

    function shouldShowError(response) {
        response.config = response.config || {
            headers: {},
            url: '',
        };
        // Put the heaviest check at the end
        return ![ 401, 404 ].includes(response.status) && !hasIgnoreError(response) && isWhitelisted(response) && EawUrl.isApiUrl(response.config.url);
    }

    return interceptor;
} ]);
