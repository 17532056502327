import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PayRateImportUpdateDialogComponent } from './pay-rate-import-update-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class PayRateImportUpdateDialogService {

    constructor(@Inject(MatDialog) private dialog: MatDialog) {
    }

    open(customerGroupId: number) {
        return this.dialog.open(PayRateImportUpdateDialogComponent, { data: { customerGroupId } });
    }
}
