<eaw-dialog-header>
    <span title>{{ (data.title || ('COMMENT_plural' | translate)) | async}}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-12">
    @if (loading) {
        <eaw-info-loading size="md"></eaw-info-loading>
    } @else {
        @if (data.saveCallback) {
            <div class="new-comment-container tw-flex tw-flex-row tw-gap-12 tw-justify-start tw-content-baseline">
                <mat-form-field class="tw-w-full">
                    <mat-label>{{'COMMENT' | translate | async}}</mat-label>
                    <textarea matInput
                              cdkAutosizeMinRows="3"
                              cdkAutosizeMaxRows="3"
                              cdkTextareaAutosize
                              [disabled]="saving || loading"
                              [(ngModel)]="newComment">
                </textarea>
                </mat-form-field>

                <button mat-raised-button
                        color="accent"
                        [disabled]="!newComment.length || saving"
                        (click)="saveComment(newComment)">
                    {{'SAVE' | translate | async}}
                </button>
            </div>
        }

        @if (!data.hideComments) {
            <eaw-comment-list [comments]="comments" [canDelete]="data.canDelete" [deleteCallback]="data.deleteCallback"></eaw-comment-list>
        }
    }
</mat-dialog-content>
