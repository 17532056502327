import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'hr-employee-files-upgraded',
    standalone: true
})
export class hrEmployeeFilesUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;
    @Input() hrEmp!: any;
    @Input() signables!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('hrEmployeeFiles', elementRef, injector);
    }
}

@Component({
    template: '<hr-employee-files-upgraded [customer]="customer" [hrEmp]="hrEmp" [signables]="signables"></hr-employee-files-upgraded>',
    standalone: true,
    imports: [hrEmployeeFilesUpgradedDirective],
})
export class hrEmployeeFilesUpgradedComponent {
    @Input() customer!: any;
    @Input() hrEmp!: any;
    @Input() signables!: any;
}

@NgModule({
    imports: [hrEmployeeFilesUpgradedDirective,
        hrEmployeeFilesUpgradedComponent],
})
export class hrEmployeeFilesUpgradeModule {
}
