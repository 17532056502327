import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { EmployeeResponse } from '../../../shared/models/employee';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { RoleAssignmentDialogService } from '../../../leader-roles/shared/services/role-assignment-dialog.service';
import { AssignmentDataTableComponent } from '../../../leader-roles/assignment-data-table/assignment-data-table.component';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { Namespace } from '../../../shared/enums/namespace';
import { CurrentService } from '../../../shared/services/current.service';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { RoleAssignment } from '../../../leader-roles/shared/types/role-assignment';
import { EmployeeService } from '../../../shared/http/employee.service';
import { CustomerService } from '../../../shared/http/customer.service';
import { forkJoin } from 'rxjs';
import { Customer } from '../../../shared/models/customer';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'eaw-user-leader-roles',
    templateUrl: './user-leader-roles.component.html',
    styleUrl: './user-leader-roles.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        AssignmentDataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class UserLeaderRolesComponent implements OnInit {
    @ViewChild('table') table!: AssignmentDataTableComponent;

    @Input() customerId!: number;
    @Input() employeeId!: number;

    customer?: Customer;
    employee?: EmployeeResponse;
    canCreate = false;
    columns: DataTableColumnType<RoleAssignment>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item.role?.name || '',
            header: new DataTableHeader({
                i18n: 'ROLE',
                ns: Namespace.Company,
            }),
        }),
        new DataTableDateTimeColumn({
            value: 'from',
            header: new DataTableHeader({ i18n: 'FROM' }),
            format: DateTime.DATE_MED,
        }),
        new DataTableDateTimeColumn({
            value: 'to',
            header: new DataTableHeader({ i18n: 'TO' }),
            format: DateTime.DATE_MED,
        }),
    ];

    createButton: HeaderFabButton = {
        click: () => {
            const customer = this.customer;
            const employee = this.employee;

            if (!customer || !employee?.user) {
                return;
            }

            this.roleAssignmentDialogService.create(this.customerId, undefined, employee.user).subscribe((role) => {
                if (role) {
                    this.reload();
                }
            });
        },
        icon: 'add',
        type: 'accent',
        hide: () => !this.customer || !this.employee?.user,
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.customer?.id}.roles.*.assignments.create`),
    };

    constructor(
        @Inject(RoleAssignmentDialogService) protected roleAssignmentDialogService: RoleAssignmentDialogService,
        @Inject(CurrentService) protected current: CurrentService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
        @Inject(EmployeeService) protected employeeService: EmployeeService,
        @Inject(CustomerService) protected customerService: CustomerService,
    ) { }

    ngOnInit() {
        forkJoin([
            this.employeeService.get(this.customerId, this.employeeId, { 'with[]': [ 'user' ] }),
            this.customerService.get(this.customerId),
        ]).subscribe(([ employee, customer ]) => {
            this.employee = employee._response;
            this.customer = customer;
        });
    }

    reload() {
        this.table.updateTable({ page: 1 });
    }
}
