import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CurrentService } from 'src/app/shared/services/current.service';
import { AggregateTimepunchInterval, TimepunchSummaryService } from '../../http/timepunch-summary.service';
import { Timepunch } from 'src/app/payroll/models/timepunch';
import { ManageTimepunchDialogService } from 'src/app/payroll/dialogs/manage-timepunch-dialog/manage-timepunch-dialog.service';
import { TimepunchService } from 'src/app/payroll/http/timepunch.service';
import { DurationPipe } from 'src/app/shared/pipes/duration.pipe';
import { SettingService } from 'src/app/shared/http/setting.service';
import { ReorderItemsDialogService } from 'src/app/shared/dialogs/reorder-items-dialog/reorder-items-dialog.service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.service';
import { AggregatedTimepunchRow } from '../../models/aggregated-timepunch-row';
import { sort, sortByOrder, uniqueId } from 'src/app/shared/angularjs/modules/misc/services/easy-funcs.service';
import { Namespace, NamespaceFile } from 'src/app/shared/enums/namespace';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { AggregateTimepunchInfoService } from 'src/app/payroll/services/aggregate-timepunch-info.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { catchError, filter, firstValueFrom, forkJoin, map, Observable, of, shareReplay, Subscription, switchMap, tap } from 'rxjs';
import { User } from 'src/app/shared/models/user';
import { DateTime } from 'luxon';
import type { Interval } from 'src/app/shared/types/interval';
import { Products } from 'src/app/shared/enums/products';
import { Employee } from 'src/app/shared/models/employee';
import { ApprovalMode } from 'src/app/payroll/types/approval-mode';
import { ColumnHeader } from 'src/app/payroll/types/column-header';
import { AggregateRow } from 'src/app/payroll/types/aggregate-row';
import { DateRangeForm } from '../../../shared/types/date-range-form';
import { RoleAssignmentService } from '../../../leader-roles/shared/http/role-assignment.service';
import type { RoleAssignment } from '../../../leader-roles/shared/types/role-assignment';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { UserPropertyService } from '../../../shared/http/user-property.service';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { TimepunchTableComponent } from './timepunch-table/timepunch-table.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { loadNamespaces } from 'i18next';

type GroupRow = { value: AggregateTimepunchInterval, text: string, ns?: NamespaceFile }

export interface ManageTimepunchesFilter {
    employee: number | null | undefined,
    approval: ApprovalMode,
    grouping: AggregateTimepunchInterval,
    from: number | undefined,
    to: number | null | undefined,
    onlyDirectSubordinates: boolean,
    includeDeleted: boolean,
    onlyDeleted: boolean,
    onlyEdited: boolean,
}

@Component({
    selector: 'eaw-manage-timepunches',
    templateUrl: './manage-timepunches.component.html',
    styleUrl: './manage-timepunches.component.scss',
    providers: [ DurationPipe ],
    standalone: true,
    imports: [
        NgIf,
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        AutocompleteComponent,
        MatCheckboxModule,
        CheckboxHelperDirective,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        TimepunchTableComponent,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        TranslateSyncPipe,
    ],
})
export class ManageTimepunchesComponent implements OnInit, OnDestroy {
    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) userId!: number;

    private readonly currentFilterStoreKey = 'manage_timepunches_filter';
    private readonly columnsStoreKey = 'manage_timepunches_columns_2';
    protected defaultFrom = DateTime.now().minus({ week: 1 }).startOf('week');
    protected defaultTo = DateTime.now().plus({ year: 1 }).endOf('day');

    currentFilter?: ManageTimepunchesFilter | null;
    loading = false;
    showFilter = window.innerHeight >= 768;
    loadedRange: Interval = {};
    groupingOptions!: GroupRow[];
    columns: string[] | undefined;
    filterGroup = new FormGroup({
        employee: new FormControl<Employee | number | undefined>(undefined),
        approval: new FormControl<ApprovalMode>(0, { nonNullable: true }),
        grouping: new FormControl<AggregateTimepunchInterval>('day', { nonNullable: true }),
        dateRange: new FormGroup<DateRangeForm>({
            from: new FormControl<DateTime>(this.defaultFrom),
            to: new FormControl<DateTime>(this.defaultTo),
        }),
        onlyDirectSubordinates: new FormControl<boolean>(false, { nonNullable: true }),
        includeDeleted: new FormControl<boolean>(false, { nonNullable: true }),
        onlyDeleted: new FormControl<boolean>(false, { nonNullable: true }),
        onlyEdited: new FormControl<boolean>(false, { nonNullable: true }),
    });

    columnsData: ColumnHeader[] = [];
    columnsKeys: string[] = [];
    rows: AggregateRow[] = [];
    totals: Record<string, number> = {};
    balances: string[] = [];
    balanceData: ColumnHeader[] = [];
    showSubordinates = false;

    private summarySubscription?: Subscription;
    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(CurrentService) private current: CurrentService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(TimepunchSummaryService) private timepunchSummaryService: TimepunchSummaryService,
        @Inject(TimepunchService) private timepunchService: TimepunchService,
        @Inject(ManageTimepunchDialogService) private manageTimepunchDialogService: ManageTimepunchDialogService,
        @Inject(SettingService) private settingService: SettingService,
        @Inject(ReorderItemsDialogService) private reorderItemsDialogService: ReorderItemsDialogService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
        @Inject(RoleAssignmentService) private roleAssignmentService: RoleAssignmentService,
        @Inject(AggregateTimepunchInfoService) private aggregateTimepunchInfoService: AggregateTimepunchInfoService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
        @Inject(UserPropertyService) protected userPropertyService: UserPropertyService,
        @Inject(CustomerProductService) protected customerProductService: CustomerProductService,
    ) {
    }

    async ngOnInit() {
        this.groupingOptions = [
            {
                value: 'none',
                text: 'NO_TIMEPUNCH_GROUP',
                ns: Namespace.Payroll,
            },
            {
                value: 'day',
                text: `DAY`,
            },
            {
                value: 'week',
                text: `WEEK`,
            },
            {
                value: 'month',
                text: `MONTH`,
            },
            {
                value: 'quarter',
                text: `QUARTER`,
            },
        ];

        void this.start();

        await loadNamespaces([ Namespace.Payroll ]);
    }

    private async start() {
        // Actually wait for async work is important
        await this.initFilters();
        this.initColumns().add(() => {
            const get = this.getColumnsSetting().subscribe(() => this.updateList());
            this.subscriptions.push(get);
        });
    }

    private async initFilters() {
        this.currentFilter = await this.current.retrieve<ManageTimepunchesFilter>(this.currentFilterStoreKey, 'default');

        this.filterGroup.patchValue({
            employee: this.currentFilter?.employee,
            includeDeleted: !!(this.currentFilter?.includeDeleted && this.currentFilter?.onlyEdited),
            onlyDeleted: !!(this.currentFilter?.onlyDeleted && this.currentFilter?.onlyEdited),
            onlyEdited: !!(this.currentFilter?.onlyDeleted && this.currentFilter?.includeDeleted),
            onlyDirectSubordinates: !!this.currentFilter?.onlyDirectSubordinates,
            grouping: this.currentFilter?.grouping || 'day',
            approval: this.currentFilter?.approval || 0,
            dateRange: {
                from: this.currentFilter?.from ? DateTime.fromMillis(this.currentFilter.from) : this.defaultFrom,
                to: this.currentFilter?.to ? DateTime.fromMillis(this.currentFilter.to) : this.defaultTo,
            },
        });

        this.permissionCheckService.isAllowed(`customers.${this.customerId}.users.${this.userId}.get`).pipe(
            switchMap((can) => {
                if (!can) {
                    return of([]);
                }

                return this.roleAssignmentService.getAllForUser(this.customerId, this.userId, { pagination: { 'with[]': [ 'role' ] } }).pipe(
                    catchError(() => of(mockArrayPaginatedResponse<RoleAssignment>())),
                    map((response) => response.data),
                );
            }),
        ).subscribe((roleAssignments) => {
            this.showSubordinates = User.hasLeaderRole(roleAssignments, this.customerId);
        });

        this.filterGroup.controls.onlyDeleted.valueChanges.subscribe((value) => {
            if (!value) {
                return;
            }

            const includeDeleted = this.filterGroup.controls.includeDeleted;
            const onlyEdited = this.filterGroup.controls.onlyEdited;
            if (includeDeleted.value) {
                includeDeleted.setValue(false, { emitEvent: false });
            }
            if (onlyEdited.value) {
                onlyEdited.setValue(false, { emitEvent: false });
            }
        });

        this.filterGroup.controls.includeDeleted.valueChanges.subscribe((value) => {
            if (!value) {
                return;
            }

            const onlyDeleted = this.filterGroup.controls.onlyDeleted;
            const onlyEdited = this.filterGroup.controls.onlyEdited;
            if (onlyDeleted.value) {
                onlyDeleted.setValue(false, { emitEvent: false });
            }
            if (onlyEdited.value) {
                onlyEdited.setValue(false, { emitEvent: false });
            }
        });

        this.filterGroup.controls.onlyEdited.valueChanges.subscribe((value) => {
            if (!value) {
                return;
            }

            const onlyDeleted = this.filterGroup.controls.onlyDeleted;
            const includeDeleted = this.filterGroup.controls.includeDeleted;
            if (onlyDeleted.value) {
                onlyDeleted.setValue(false, { emitEvent: false });
            }
            if (includeDeleted.value) {
                includeDeleted.setValue(false, { emitEvent: false });
            }
        });
    }

    /**
     * Gets any custom columns.
     */
    getColumnsSetting(): Observable<string> {
        this.loading = true;

        return this.settingService.getString([ 'customers', this.customerId ], 'manage_timepunch_balances', JSON.stringify([])).pipe(
            filter((val): val is string => val !== null),
            tap((result: string) => {
                const balances: string[] = JSON.parse(result) as string[];

                this.balances = balances;
                this.balanceData = balances.map((column) => {
                    return {
                        ns: 'balances',
                        type: 'hours',
                        key: column,
                        i18n: column.split('.')[1]?.toUpperCase() || column,
                        selected: true,
                    };
                });
            }),
            shareReplay(1),
        );
    }

    get hasApprovable() {
        return this.rows.some((r) => r.data.approvable);
    }

    createTimepunch() {
        const create = this.manageTimepunchDialogService.create(this.customerId).afterClosed().subscribe((result) => {
            if (result instanceof Timepunch) {
                void this.updateList();
            }
        });

        this.subscriptions.push(create);
    }

    get isAggregatedView() {
        return this.currentFilter?.grouping !== 'none';
    }

    canGetDetails(item: AggregateRow) {
        return item.aggregated && this.isAggregatedView && item.data.ids.length;
    }

    getSelectedColumns(): Observable<string[]> {
        return this.customerProductService.hasProducts(this.customerId, [ Products.Switzerland ])
            .pipe(
                map((hasProduct) => {
                    let always = [ 'employee_id', 'from', 'to', 'business_date', 'approved_count', 'ids' ];
                    if (hasProduct) {
                        always = always.concat([ 'warnings_count', 'infractions' ]);
                    }

                    const selected: string[] = this.columnsData
                        .filter((d) => d.selected && d.col_key)
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        .map((d) => d.col_key!);

                    const cols = selected.concat(always);

                    return Array.from(new Set(cols).values());
                }),
            );
    }

    getRequestFrom(row?: AggregateRow) {
        let from = row?.data.from;
        if (!from) {
            if (this.currentFilter?.from) {
                from = DateTime.fromMillis(this.currentFilter.from).startOf('day');
            } else {
                from = DateTime.now().startOf('day');
            }
        }

        return from;
    }

    getRequestTo(row?: AggregateRow) {
        let to = row?.data.to;
        if (!to) {
            if (this.currentFilter?.to) {
                to = DateTime.fromMillis(this.currentFilter.to).endOf('day');
            } else {
                to = DateTime.now().plus({ year: 1 }).endOf('day');
            }
        }

        return to;
    }

    /**
     * @param row - If row is provided then that means we are getting child rows for this row
     */
    getData(row?: AggregateRow) {
        if (row && !this.canGetDetails(row)) {
            return;
        }

        if (row?.loaded) {
            row.expanded = !row.expanded;
            this.rows.filter((x) => x.parentId === row.id && !x.aggregated).forEach((x) => (x.expanded = row.expanded));
            return;
        }

        if (!row) {
            this.summarySubscription?.unsubscribe();
        }

        if (row) {
            row.expanded = !row.expanded;
            row.loading = !row.loaded;
        }

        const employeeId = row ? row.data.employeeId : this.currentFilter?.employee;
        const approved = this.currentFilter?.approval ?? 1;
        const grouping = row ? 'none' : this.currentFilter?.grouping || 'day';
        const from = this.getRequestFrom(row);
        const to = this.getRequestTo(row);

        const summary = this.getSelectedColumns()
            .pipe(
                switchMap((columns) => {
                    return this.timepunchSummaryService.getAggregated(this.customerId, from, to, grouping, {
                        'employee_ids[]': employeeId ? [ employeeId ] : undefined,
                        'columns[]': columns,
                        only_direct_subordinates: this.currentFilter?.onlyDirectSubordinates,
                        'balances[]': this.balances,
                        approved: approved == 2 ? undefined : approved > 0,
                        with_trashed: this.getWithTrashed(),
                        only_trashed: this.getOnlyTrashed(),
                        only_edited: this.getOnlyEdited(),
                    });
                }),
            );

        if (!row) {
            this.loadedRange = {
                from,
                to,
            };
        }

        this.loading = true;
        this.createColumnsKeys();
        void this.setColumnsData(this.columnsKeys);

        this.summarySubscription = summary.subscribe({
            next: (resp) => this.onData(resp, row),
            complete: this.stopLoading.bind(this),
            error: this.stopLoading.bind(this),
        });
        this.subscriptions.push(this.summarySubscription);
    }

    approveSelected() {
        this.confirmDialogService.open({
            text: this.translate.t('APPROVE_SELECTED_TIMEPUNCHES', Namespace.Payroll),
            comment: { include: true },
            confirmText: this.translate.t('APPROVE'),
            title: this.translate.t('APPROVE_TIMEPUNCHES', Namespace.Payroll),
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                return;
            }

            const ids = this.rows
                .filter((r) => r.checked && r.data.approvable)
                .flatMap((x) => x.data.ids);

            this.timepunchService.approve(this.customerId, ids, result.comment).subscribe(() => {
                void this.snackbar.t('APPROVING_TIMEPUNCHES', Namespace.Payroll);
            });
        });
    }

    private initColumns() {
        // What we show as a default
        const defaultCols = [
            'employee_name',
            'in',
            'out',
            'length',
            'shifts_length',
            'diff',
            'comments_count',
            'warnings_count',
            'manually_opened',
            'manually_closed',
            'time_edited',
            'approved_count',
        ];

        // Set the columns we got from user properties as default columns, or else use the default default columns
        return forkJoin([
            this.customerProductService.hasProducts(this.customerId, [ Products.Switzerland ]),
            this.userPropertyService.get(this.userId, this.columnsStoreKey).pipe(catchError(() => of(null))),
        ]).pipe(
            switchMap(([ hasProduct, property ]) => {
                if (hasProduct) {
                    defaultCols.push('infractions');
                }

                if (!property) {
                    // if user property is not set, add it with default columns data
                    this.userPropertyService.create(this.userId, this.columnsStoreKey, defaultCols.join(',')).subscribe();
                    return this.setColumnsData(defaultCols);
                }

                return this.setColumnsData(property.value.asString().split(','));
            }),
        ).subscribe();
    }

    async setColumnsData(selected: string[]) {
        let keys = selected;

        if (this.currentFilter?.onlyDeleted || this.currentFilter?.includeDeleted) {
            keys = selected.concat([ 'deleted' ]);
        }

        const hasScheduling = await firstValueFrom(this.customerProductService.hasProducts(this.customerId, [ Products.Scheduling ]));

        this.columnsData = Object.entries(this.aggregateTimepunchInfoService.getItems(hasScheduling))
            .filter(([ _, item ]) => typeof item.if === 'boolean' ? item.if : item.if(this.currentFilter))
            .map(([ key, item ]) => {
                return {
                    ...item,
                    key,
                    ns: item.ns || Namespace.General,
                    selected: keys.includes(key),
                };
            });

        this.columnsData = sortByOrder(this.columnsData, selected, 'key');
    }

    getWithTrashed() {
        const withTrashed = this.currentFilter?.includeDeleted;
        const onlyTrashed = this.currentFilter?.onlyDeleted;
        return withTrashed && !onlyTrashed ? true : undefined;
    }

    getOnlyTrashed() {
        return this.currentFilter?.onlyDeleted ? true : undefined;
    }

    getOnlyEdited() {
        return this.currentFilter?.onlyEdited ? true : undefined;
    }

    stopLoading() {
        this.loading = false;
    }

    async onData(result: AggregatedTimepunchRow[], parentRow?: AggregateRow) {
        await firstValueFrom(this.permissionCheckService.isAllowedMany(result.flatMap((r) => {
            return [
                `customers.${this.customerId}.employees.${r.employeeId}.timepunches.*.update`,
                `customers.${this.customerId}.employees.${r.employeeId}.timepunches.*.delete`,
            ];
        })));

        if (parentRow) {
            parentRow.loaded = true;
            parentRow.loading = false;
        }

        const index = this.rows.findIndex((x) => x.id === parentRow?.id);
        const newRows: AggregateRow[] = result.map((r) => {
            this.setBalancesAndPermissions(r);

            return {
                id: uniqueId(),
                parentId: parentRow?.id ?? null,
                expanded: parentRow ? true : !this.isAggregatedView,
                loaded: parentRow ? true : !this.isAggregatedView,
                loading: false,
                data: r,
                aggregated: parentRow ? false : this.isAggregatedView,
                checked: parentRow?.checked ?? false,
            };
        });

        if (parentRow) {
            this.rows.splice(index + 1, 0, ...newRows);
        } else {
            this.rows = newRows;
        }

        this.filterGroup.enable();
        this.sortRows();
        this.createColumnsKeys();
        this.createTotals(result);
        this.rows = [ ...this.rows ];
    }

    sortRows() {
        const groupsByDayOrNone = this.filterGroup.controls.grouping.value === 'none' || this.filterGroup.controls.grouping.value === 'day';
        const getDateOrFrom = (row: AggregateRow) => row.data.businessDate?.dateTime.toMillis() || row.data.from?.toMillis() || 0;
        const getEmployeeName = (row: AggregateRow) => row.data.values['employee_name']?.value;

        // If grouping is set to none, order by date/from and then name, otherwise order by name and then date/from
        this.rows = sort(this.rows, this.current.languageTag, [
            groupsByDayOrNone ? getDateOrFrom : getEmployeeName,
            groupsByDayOrNone ? getEmployeeName : getDateOrFrom,
        ], [ 'asc', 'asc' ]);
    }

    private createTotals(data: AggregatedTimepunchRow[]) {
        this.totals = {};

        data.forEach((row) => {
            // TODO Move balances to cell component
            Object.entries(row.balances || []).map(([ key, val ]) => {
                this.totals[key] = (this.totals[key] || 0) + val;
            });
        });
    }

    private setBalancesAndPermissions(row: AggregatedTimepunchRow) {
        this.balances.map((key) => {
            if (row.balances && !row.balances[key]) {
                row.balances[key] = 0;
            }
        });

        row.setCanUpdate(this.permissionCheckService.single(`customers.${this.customerId}.employees.${row.employeeId}.timepunches.*.update`));
        row.setCanDelete(this.permissionCheckService.single(`customers.${this.customerId}.employees.${row.employeeId}.timepunches.*.delete`));
    }

    createColumnsKeys() {
        // Ensure uniqueness
        this.columnsKeys = [
            ...new Set(
                [
                    'checkbox',
                    'business_date',
                    ...this.columnsData.filter((d) => d.selected).map((d) => d.key),
                    ...this.balances,
                    'buttons',
                ],
            ).values(),
        ];
    }

    adjustColumns() {
        // Save it for later
        const businessDateHeader = this.columnsData.filter((cd) => cd.key === 'business_date')[0];
        const columnHeaders = this.columnsData
            .filter((cd) => cd.key !== businessDateHeader?.key)
            .map((cd) => {
                return {
                    item: cd,
                    selected: cd.selected,
                    text: this.translate.t(cd.i18n, cd.ns),
                };
            });

        this.reorderItemsDialogService.open<ColumnHeader>({ items: of(columnHeaders) }).afterClosed().subscribe(async (result) => {
            if (result == null) {
                return;
            }

            // Set selected headers FIRST
            this.columnsData = result.filter((col) => col.selected).map((col) => col.item);

            // Add back business date
            if (businessDateHeader) {
                this.columnsData.unshift(businessDateHeader);
            }

            // Update keys AFTER headers
            this.createColumnsKeys();

            // update user property with new columns configuration
            this.userPropertyService.update(this.userId, this.columnsStoreKey, this.columnsData.map((cd) => cd.key).join(',')).subscribe((property) => {
                this.setColumnsData(property.value.asString().split(','));
            });

            await this.updateList();
        });
    }

    async saveFilter() {
        const employee = this.filterGroup.controls.employee.value;
        const dateRange = this.filterGroup.controls.dateRange.value;

        this.currentFilter = {
            employee: employee instanceof Employee ? employee.id : employee,
            approval: this.filterGroup.controls.approval.value,
            grouping: this.filterGroup.controls.grouping.value,
            from: dateRange.from?.toMillis(),
            to: dateRange.to?.toMillis() || null,
            onlyDirectSubordinates: this.filterGroup.controls.onlyDirectSubordinates.value,
            includeDeleted: this.filterGroup.controls.includeDeleted.value,
            onlyDeleted: this.filterGroup.controls.onlyDeleted.value,
            onlyEdited: this.filterGroup.controls.onlyEdited.value,
        };

        await this.current.store<ManageTimepunchesFilter>(this.currentFilterStoreKey, this.currentFilter, 'location');
    }

    async updateList() {
        await this.saveFilter();

        this.getData();
    }

    ngOnDestroy(): void {
        this.subscriptions?.map((s) => s?.unsubscribe());
    }
}
