import { ChangeDetectionStrategy, Component, computed, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { forkJoin, Observable, of } from 'rxjs';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { ActionButtonComponent } from '../../components/action-button/action-button.component';
import { InfoLoadingComponent } from '../../components/info-loading/info-loading.component';

export type ItemSelectorDialogReturn<Item> = Item | Item[];

export interface ItemSelectorDialogData<Item> extends DialogData {
    itemText: (item: Item) => string;
    itemSubtext?: (item: Item) => string;
    multiple: boolean;
    items: Observable<Item[]>;
    value?: Observable<Item[]>;
    valueMatcher?: (value: Item[], item: Item) => boolean;
    text?: Promise<string>;
    title: Promise<string>;
    cancelText?: Promise<string>;
    confirmText?: Promise<string>;
    /**
     * Allows the user to select no items. I.e. return an empty array.
     *
     * Can be useful for resetting a selection.
     */
    allowEmptyChoice?: WritableSignal<boolean>;
}

@Component({
    selector: 'eaw-item-selector-dialog',
    templateUrl: './item-selector-dialog.component.html',
    styleUrl: './item-selector-dialog.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        MatProgressSpinnerModule,
        MatListModule,
        NgFor,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        ActionButtonComponent,
        InfoLoadingComponent,
    ],
})
export class ItemSelectorDialogComponent<Item> extends DialogComponent implements OnInit {
    value = signal([] as Item[]);
    items = signal([] as Item[]);
    loading = signal(true);
    disabled = computed(this.computeDisabled.bind(this));

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ItemSelectorDialogData<Item>,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ItemSelectorDialogComponent<Item>, ItemSelectorDialogReturn<Item>>,
    ) {
        super(dialogRef, data, DialogSize.Medium);
    }

    ngOnInit(): void {
        forkJoin([
            this.data.items,
            this.data.value || of([]),
        ]).subscribe(([ items, value ]) => {
            this.items.set(items);
            this.value.set(value);
            this.loading.set(false);
        });
    }

    computeDisabled() {
        const allowEmpty = this.data.allowEmptyChoice?.();
        const selectedItems = this.value();

        return !allowEmpty && selectedItems.length === 0;
    }

    selectionChange(event: MatSelectionListChange) {
        this.value.set(event.source.selectedOptions.selected.map((o) => o.value));
    }

    isSelected(item: Item) {
        if (this.data.valueMatcher) {
            return this.data.valueMatcher(this.value(), item);
        }

        return this.value() === item || (Array.isArray(this.value()) && this.value().includes(item));
    }

    close() {
        this.dialogRef.close(this.data.multiple ? this.value() : this.value()[0]);
    }
}
