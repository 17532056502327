import { Component } from '@angular/core';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'eaw-no-access',
    templateUrl: './no-access.component.html',
    styleUrl: './no-access.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class NoAccessComponent {}
