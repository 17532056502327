// @ts-nocheck
import { t } from 'i18next';
import { debounce } from 'lodash-es';
import { module } from 'angular';

module('eaw.admin').controller('adminCustomerUsersTabCtrl', [ 'customer', 'addUserDialog', '$mdDialog', 'CustomerService', 'ToastService', 'EditDateIntervalDialog', function adminCustomerUsersTabCtrl(customer, addUserDialog, $mdDialog, CustomerService, ToastService, EditDateIntervalDialog) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.customer = customer;
        ctrl.display_inactive_users = Boolean(ctrl.display_inactive_users);

        ctrl.table = {
            columns: [
                {
                    title: t('ID'),
                    sortable: 'id',
                },
                {
                    title: t('FIRST_NAME'),
                    sortable: 'first_name',
                },
                {
                    title: t('LAST_NAME'),
                    sortable: 'last_name',
                },
                { title: t('FROM') },
                { title: t('TO') },
                {
                    title: t('LAST_ACTIVE'),
                    sortable: 'last_active',
                },
                { title: '' },
            ],
            cells: [
                { template: 'item.id' },
                { template: 'item.first_name' },
                { template: 'item.last_name' },
                { template: 'item.pivot.from | moment' },
                { template: 'item.pivot.to | moment' },
                { template: 'item.last_active | moment' },
                {
                    buttons: [
                        {
                            icon: 'edit',
                            tooltip: 'EDIT',
                            click: ctrl.updateUser,
                        },
                        {
                            icon: 'delete',
                            type: 'warn',
                            tooltip: 'REMOVE_ACCESS',
                            click: ctrl.removeUser,
                        },
                    ],
                },
            ],
        };
    };

    ctrl.updateUser = (user) => {
        EditDateIntervalDialog.open({
            from: user.pivot.from,
            to: user.pivot.to,
            title: t('USER'),
            includeTime: true,
        }).then((result) => {
            CustomerService.updateUser(ctrl.customer, user, result.from, result.to).$promise.then(() => {
                ctrl.reloadTable = true;
                ToastService.toast(t('X_UPDATED', { var: user.name }));
            });
        });
    };

    ctrl.getData = (pagination) => {
        return CustomerService.getUsers({
            ...pagination,
            customer: ctrl.customer,
            display_inactive_users: ctrl.display_inactive_users,
            filter: ctrl.searchValue,
        }).$promise.then((data) => {
            ctrl.userCount = data.total;
            return data;
        });
    };

    ctrl.removeUser = (user) => {
        $mdDialog.show(
            $mdDialog.confirm()
                .theme('delete')
                .title(t('admin:REMOVE_ACCESS_TO_CUSTOMER'))
                .textContent(t('admin:REMOVE_ACCESS_TO_CUSTOMER_FOR_NAME', {
                    customer: ctrl.customer.name,
                    name: user.name,
                }))
                .ok(t('REMOVE'))
                .cancel(t('CANCEL')),
        ).then(() => {
            CustomerService.removeUser(ctrl.customer.id, user.id).$promise.then(() => {
                ctrl.reloadTable = true;
                ToastService.toast(t('admin:REMOVED_ACCESS_FOR', { user: user.name }));
            });
        });
    };

    ctrl.debouncedUpdate = debounce(() => {
        ctrl.reloadTable = true;
    }, 300);

    // Open modal and find a user to add the customer
    ctrl.addUserToCustomer = () => {
        addUserDialog.open(ctrl.customer, (cust, user, from, to) => CustomerService.addUser(cust, user.id, from, to).$promise.then(() => {
            ctrl.reloadTable = true;
            ToastService.toast(`${user.first_name} ${user.last_name} was added to ${cust.name}`);
        }));
    };
} ]);
