import { ApiResponse } from '../../shared/interfaces/api-response';
import { BaseApiModel } from '../../shared/models/base-api-model';
import { Shift, ShiftResponse } from './shift';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export interface ShiftGroupResponse extends ApiResponse {
    id: number;
    employee_id: number | null;
    members?: ShiftResponse[];
    model: string;
    name: string;
    owner_id: number;
    parent_id: number | null;
    type: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export class ShiftGroup extends BaseApiModel<ShiftGroupResponse, ShiftGroup> {
    id: number;
    employeeId: number | null;
    members?: Shift[];
    model: string;
    name: string;
    ownerId: number;
    parentId: number | null;
    type: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;

    constructor(data: ShiftGroupResponse) {
        super(data, undefined);

        this.id = data.id;
        this.employeeId = data.employee_id;
        this.members = data.members?.map((shift) => new Shift(shift));
        this.model = data.model;
        this.name = data.name;
        this.ownerId = data.owner_id;
        this.parentId = data.parent_id;
        this.type = data.type;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
