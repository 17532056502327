import { t } from 'i18next';
import { orderBy } from 'lodash-es';
import { smallDevice, uniqueId } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { module } from 'angular';
import { Storage } from '../../../../shared/utils/storage';
import { ElementInitialization } from '../../../../shared/angularjs/modules/misc/services/element-initialization.service';
import { SnackBarService } from '../../../../shared/services/snack-bar.service.js';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
import { ScheduleService } from '../../../http/schedule.service';
import { UIRouter } from '@uirouter/core';
import { DateTime } from 'luxon';
import { momentToDateTime } from '../../../../shared/angularjs/moment-to-date-time';
import { scheduleViewDragAndDrop, scheduleViewSchedule } from '../../../signals';
import { ScheduleTabsService } from '../../../services/schedule-tabs.service';
import { UserPropertyService } from '../../../../shared/http/user-property.service';
import {catchError, forkJoin, map, of, tap} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PublishScheduleDialogComponent, PublishScheduleDialogData, PublishScheduleDialogResult } from '../../../pages/schedule/dialogs/publish-schedule-dialog/publish-schedule-dialog.component';
import { filterNullish } from '../../../../shared/utils/rxjs/filter-nullish';
import { ScheduleProgressEvent, WebsocketService } from '../../../../shared/services/websocket.service';
import { AngularJsDestroyRef } from '../../../../shared/angularjs/angularjs-destroy-ref.class';

export interface AngularJsScheduleViewSchedule {
    id: number;
    isTemplate: boolean;
    from: DateTime;
    to: DateTime;
    customerId: number;
}

module('eaw.scheduling').component('scheduleView', {
    template: `<div id="schedule-progress" ng-if="$scheduleView.progressProp && $scheduleView.progressProp.value < 100">
    <span id="progress-percent" ng-bind="$scheduleView.progressProp.value / 100 | percent"></span>
    <span id="progress-help" ng-i18next="scheduling:PROGRESS_HELP"></span>
    <md-progress-linear md-mode="indeterminate"></md-progress-linear>
</div>

<md-tabs id="schedule-tabs" ng-if="!$scheduleView.progressProp" md-selected="$scheduleView.activeTab">
    <md-tab ng-repeat="tab in $scheduleView.tabs track by tab.tab.name"
            label="{{::tab.tab.syncLabel}}"
            md-active="tab.active"
            md-on-deselect="tab.onDeselect()"
            md-on-select="tab.onSelect()">
        <md-tab-body>
            <component-creator 
            class="schedule-comp-creator"
             ng-if="tab.active"
              component-name="{{tab.tab.component}}" 
              bindings="tab.bindings" 
              transclude-controllers="$scheduleView.transcludeControllers">                          
            </component-creator>
        </md-tab-body>
    </md-tab>
</md-tabs>

<!-- Sidenav for "big" screens -->
<md-sidenav id="schedule-sidenav-big"
            class="md-sidenav-right"
            md-component-id="{{$scheduleView.sidebarIdBig}}"
            md-disable-backdrop
            md-disable-close-events="true"
            md-is-open="true"
            ng-if="!$scheduleView.smallDevice && !$scheduleView.progressProp && !$scheduleView.schedule.original">
    <schedule-sidebar></schedule-sidebar>
</md-sidenav>

<!-- Sidenav for "small" screens -->
<md-sidenav id="schedule-sidenav-small"
            class="md-sidenav-right"
            md-component-id="{{$scheduleView.sidebarIdSmall}}"
            md-disable-backdrop
            md-is-open="$scheduleView.smallSidenavOpen"
            ng-if="$scheduleView.smallDevice && !$scheduleView.schedule.original">
    <schedule-sidebar ng-if="$scheduleView.smallSidebar && !$scheduleView.progressProp" small-bar="$scheduleView.smallSidebar"></schedule-sidebar>
</md-sidenav>

<mobile-schedule-sidebar ng-if="$scheduleView.smallDevice && !$scheduleView.progressProp && !$scheduleView.schedule.original"></mobile-schedule-sidebar>

`,
    bindings: {
        schedule: '<',
        customer: '<',
        employee: '<',
        user: '<',
        openingHours: '<',
        mlBucket: '<',
        businessUnits: '<',
        employees: '<',
        original: '<?',
    },
    controller: [ 'WebsocketDowngrade', '$state', 'MatDialogDowngrade', '$element', 'UserPropertyDowngraded', 'scheduleTabsServiceDowngrade', 'ConfirmDialogDowngraded', 'ScheduleServiceDowngraded', 'scheduleDays', '$rootScope', 'shiftEvents', 'ScheduleService', 'intervalService', 'scheduleEfficiency', 'scheduleBudget', 'UnitFilter', '$mdSidenav', '$uiRouter', 'shiftsLoader', '$scope', '$mdDialog', 'ToastService', 'scheduleAvailabilityOverviewService', 'scheduleTable', 'scheduleMode', function(WebsocketDowngrade: WebsocketService, $state, MatDialogDowngrade: MatDialog, $element, UserPropertyDowngraded: UserPropertyService, scheduleTabsServiceDowngrade: ScheduleTabsService, ConfirmDialogDowngraded, ScheduleServiceDowngraded: ScheduleService, scheduleDays, $rootScope: angular.IRootScopeService, shiftEvents, ScheduleService, intervalService, scheduleEfficiency, scheduleBudget, UnitFilter, $mdSidenav, $uiRouter: UIRouter, shiftsLoader, $scope: angular.IScope, $mdDialog: any, ToastService: SnackBarService, scheduleAvailabilityOverviewService, scheduleTable, scheduleMode) {
        // @ts-ignore
        const ctrl = this;
        const destroyRef = new AngularJsDestroyRef();

        ctrl.$onInit = async () => {
            ctrl.businessUnitOrder = await Storage.getItem<object>('schedule:unit_order');
        };

        ctrl.$postLink = () => {
            scheduleViewSchedule.set({
                id: ctrl.schedule.id,
                isTemplate: ctrl.schedule.is_template,
                from: momentToDateTime(ctrl.schedule.from),
                to: momentToDateTime(ctrl.schedule.to),
                customerId: ctrl.schedule.customer_id,
            });

            ctrl.el = $element[0];
            ctrl.initServices();
            ctrl.tabChanges = 0;
            ctrl.smallDevice = smallDevice();
            ctrl.sidebarIdBig = uniqueId('schedule-sidebar-big');
            ctrl.sidebarIdSmall = uniqueId('schedule-sidebar-small');
            ctrl.forcePublishPermission = CurrentOld.can(`customers.${ctrl.schedule.customer_id}.schedules.${ctrl.schedule.id}.force_publish`);
            ctrl.publishPermission = CurrentOld.can(`customers.${ctrl.schedule.customer_id}.schedules.${ctrl.schedule.id}.publish`);
            ctrl.transcludeControllers = { scheduleView: { instance: ctrl } };
            ctrl.createTabs($uiRouter.globals.params['tab']).subscribe(() => {
                ctrl.tabs.find((t: any) => t.active)?.onSelect();
            });
            ctrl.checkReadOnly();
            ctrl.handleResize();
            ctrl.listenForNotifyAuditors();
            ctrl.listenForProgress();
            // Stop other things under here if we have progress property
            if (ctrl.progressProp) {
                return;
            }
            // On publish event
            $scope.$on('schedule:publish', ctrl.onPublish);
            $scope.$on('schedule:unPublish', ctrl.onUnPublish);

            ctrl.handleSidenav();
            if (ctrl.schedule.isTemplate()) {
                ctrl.el.classList.add('template');
            }
        };

        ctrl.$onDestroy = () => {
            scheduleViewDragAndDrop.set(undefined);
            scheduleViewSchedule.set({
                id: 0,
                customerId: 0,
                from: DateTime.invalid('Uninitialized'),
                to: DateTime.invalid('Uninitialized'),
                isTemplate: false,
            });

            destroyRef.destroy();
            scheduleMode.setMode('select');
            UnitFilter.reset();
            shiftsLoader.remove();
            shiftEvents.reset();
            scheduleBudget.reset();
            scheduleEfficiency.reset();
            scheduleTable.resetCurrentDate();
            $rootScope.$broadcast('schedule_view:destroyed');
            shiftsLoader.loading = false;
        };

        ctrl.getBusinessUnits = () => {
            const order = ctrl.businessUnitOrder;
            const units = ctrl.businessUnits.map((u: any) => {
                return {
                    ...u,
                    backgroundColor: u.colorHex,
                    textColor: u.color.isDark() ? 'white' : 'black',
                    selected: false,
                    orderIndex: null,
                };
            });

            // Try to find indexes
            if (Array.isArray(order)) {
                order?.forEach((o, i) => {
                    const item = units.find((u: any) => u.id === o);
                    if (!item?.id) {
                        return;
                    }
                    item.orderIndex = i + 1;
                });
            }

            const itemsWithIndex = orderBy(units.filter((u: any) => u.orderIndex), 'orderIndex', 'asc');
            const itemsNoIndex = orderBy(units.filter((u: any) => !u.orderIndex), (u) => u.name.toLowerCase(), 'asc');
            return itemsWithIndex.concat(itemsNoIndex);
        };

        ctrl.handleSidenav = () => {
            $mdSidenav(ctrl.sidebarIdSmall, true).then((sidenav: any) => {
                ctrl.smallSidenav = sidenav;
                ctrl.smallSidenav.onClose(() => {
                    ctrl.smallSidebar = undefined;
                });
            });
        };
        ctrl.recalcTopStats = () => {
            $scope.$broadcast('topstats:recalc');
        };

        ctrl.initServices = () => {
            scheduleDays.setSchedule(ctrl.schedule);
            scheduleDays.generate();
            scheduleTable.setSchedule(ctrl.schedule);
            scheduleTable.setOpeningHours(ctrl.openingHours);
            scheduleAvailabilityOverviewService.init(ctrl.schedule);
            scheduleBudget.setSchedule(ctrl.schedule); // MUST COME BEFORE TOP STATS
            intervalService.setSchedule(ctrl.schedule);
            scheduleEfficiency.setSchedule(ctrl.schedule);
        };

        ctrl.listenForProgress = () => {
            // Don't care about progress unless we have progress blocking
            if (!ctrl.schedule.properties?.find((p: any) => p.key === 'progress_blocking' && p.value != 0)) {
                return;
            }

            ctrl.progressProp = ctrl.schedule.properties?.find((p: any) => p.key === 'progress');
            if (ctrl.progressProp) {
                ctrl.progressProp.value = parseInt(ctrl.progressProp.value);


                WebsocketDowngrade.listenSchedule(ctrl.schedule.customer_id, ctrl.schedule.id, 'progress', ctrl.handleProgress, destroyRef)
            }
        };

        ctrl.handleProgress = (e: ScheduleProgressEvent) => {
            ctrl.progressProp.value = e.progress;
            if (e.progress === 100) {
                $uiRouter.stateService.reload();
            }
        };

        ctrl.handleResize = () => {
            // Create new init
            const sidebarInit = new ElementInitialization('#schedule-sidenav-big', ctrl.el);
            // Wait for it..
            sidebarInit.observe().then((sidebar) => {
                if (!sidebar) {
                    return;
                }

                // Set initial / default margin when initialized
                ctrl.el.style.marginRight = `51px`;

                // Watch for changes
                ctrl.observer = new MutationObserver(() => {
                    ctrl.el.style.marginRight = `${sidebar.getBoundingClientRect().width}px`;
                });
                // Start observing
                ctrl.observer.observe(sidebar, {
                    childList: true,
                    subtree: true,
                });
            });
        };

        ctrl.checkReadOnly = () => {
            if (!CurrentOld.can(`customers.${ctrl.customer.id}.schedules.${ctrl.schedule.id}.shifts.create`)) {
                scheduleMode.setMode('read-only');
            }
        };

        ctrl.openSmallSidenav = (sidebar: any) => {
            ctrl.smallSidebar = sidebar;
            ctrl.smallSidenav.open();
        };

        ctrl.onDropUnit = (shift: any) => {
            const dragUnit = scheduleViewDragAndDrop()?.type === 'unit' && scheduleViewDragAndDrop()?.data;
            // It's a one time thing on small devices
            if (smallDevice()) {
                scheduleViewDragAndDrop.set(undefined);
            }
            if (!dragUnit?.selected) {
                return;
            }
            // We only want to delete period(s) that are business units when we
            // are using the drag and drop business unit
            const buPeriods = (shift?.periods || []).filter((p: any) => p.business_unit);
            // Trigger update
            shiftEvents.trigger.updating(shift);
            Promise.all(buPeriods.map((p: any) => ScheduleService.deletePeriod(ctrl.schedule.customer_id, ctrl.schedule.id, shift.id, p.id).$promise)).then(() => {
                ScheduleService.addPeriod(ctrl.schedule.customer_id, ctrl.schedule.id, shift.id, {
                    offset: 0,
                    length: shift.length,
                    business_unit_id: dragUnit.id,
                    with: [ 'businessUnit', 'properties' ],
                }).$promise.then(() => {
                    shift.refresh();
                });
            });
        };

        ctrl.createTabs = (urlTab?: string) => {
            return forkJoin([
                urlTab ? of(urlTab) : UserPropertyDowngraded.get(ctrl.user.id, 'schedule:setting:default_tab').pipe(
                    catchError(() => of(null)),
                    map((property) => property?.value.asString()),
                ),
                scheduleTabsServiceDowngrade.getScheduleTabs(ctrl.customer.stack_id, ctrl.customer.id, ctrl.schedule.id, ctrl.schedule.is_template),
            ]).pipe(
                tap(([ defaultTab, tabs ]) => {
                    ctrl.tabs = tabs.map((tab, i) => {
                        const tabData = {
                            tab,
                            index: i,
                            active: defaultTab ? (tab.name === defaultTab) : (i === 0),
                            bindings: {
                                tab: t,
                                schedule: ctrl.schedule,
                            },
                            onDeselect: function onDeselect() {
                                if (!this.active) {
                                    return;
                                }

                                if (tab.name === 'employees') {
                                    ctrl.initServices();
                                    shiftsLoader.loading = false;
                                    shiftsLoader.load(ctrl);
                                }
                            },
                            onSelect: function onSelect() {
                                console.log(`onselect`, tab);
                                this.active = true;

                                $uiRouter.stateService.go($uiRouter.globals.current, {
                                    ...$uiRouter.globals.current.params,
                                    tab: tab.name,
                                });

                                if (tab.name !== 'employees') {
                                    shiftsLoader.load(ctrl);
                                }
                            },
                        };

                        if (tab.name === 'total' || tab.name === 'autogen') {
                            tabData.bindings.schedule = [ ctrl.schedule, 'upgrade' ];
                        }

                        return tabData;
                    });
                })
            );
        };

        ctrl.listenForNotifyAuditors = () => {
            $scope.$on('schedule:notifyAuditors', () => {
                $mdDialog.show($mdDialog.confirm()
                    .textContent(t('scheduling:SEND_TO_AUDITORS_QUESTION'))
                    .ok(t('SUBMIT'))
                    .cancel(t('CANCEL'))).then(() => {
                    ctrl.schedule.notifyAuditors().then(() => {
                        ToastService.toast(t('SENT'));
                    });
                });
            });
        };

        ctrl.onPublish = () => {
            if (ctrl.publishPermission || ctrl.forcePublishPermission) {
                MatDialogDowngrade.open<PublishScheduleDialogComponent, PublishScheduleDialogData, PublishScheduleDialogResult>(PublishScheduleDialogComponent, {
                    data: {
                        stackId: ctrl.customer.stack_id,
                        scheduleId: ctrl.schedule.id,
                        customerId: ctrl.schedule.customer_id,
                        fetchWarnings: true,
                    }
                }).afterClosed().pipe(filterNullish()).subscribe(() => {
                    $state.transitionTo(`eaw/app/scheduling/schedules/list`);
                    ToastService.toast(t('scheduling:SCHEDULE_PUBLISHED'));
                });
            } else {
                $scope.$emit('schedule:notifyAuditors');
            }
        };

        ctrl.onUnPublish = () => {
            // Show confirmation dialog asking if the user is sure they want to unpublish this schedule.
            ConfirmDialogDowngraded.open({
                title: Promise.resolve(t('scheduling:UNPUBLISH_SCHEDULE')),
                text: Promise.resolve(t('scheduling:UNPUBLISH_SCHEDULE_CONFIRMATION')),
                confirmText: Promise.resolve(t('scheduling:UNPUBLISH')),
            }).afterClosed().subscribe((confirmed: boolean) => {
                if (!confirmed) {
                    return;
                }
                ScheduleServiceDowngraded.unpublish(ctrl.schedule.customer_id, ctrl.schedule.id).subscribe(() => {
                    $uiRouter.stateService.transitionTo(`eaw/app/scheduling/schedules/list`);
                });
            });
        };
    } ],
    controllerAs: '$scheduleView',
});
