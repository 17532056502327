import { DestroyRef, inject, Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import { environment } from '../../../environments/environment';
import { EawUrl } from '../angularjs/modules/resource/url.service';
import { reportError } from '../angularjs/modules/misc/services/easy-funcs.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WarningResponse } from '../models/warning';

export type ScheduleEvent = 'warning_added' | 'warnings_cleared' | 'progress' | 'shift_saved' | 'shift_deleted' | 'summary_calculated';
export type CustomerScheduleEvent = 'created' | 'progress';
export type ReportEvent = 'report_run_done';
export type EmployeeHrFilesEvent = 'created';
export type EmployeeTimepunchesEvent = 'punched';
export type CustomerTimepunchesEvent = 'punched';
export type MessageConversationEvent = 'new_message' | 'message_confirmed' | 'message_attachment';

export interface WebsocketListenOptions {
    customDestroy?: Observable<true>;
}

export interface ReportRunDoneSocketResponse {
    error: string,
    report: { id: number, class: string, name: string },
    run: number,
}

export interface ShiftDeletedEvent {
    event_triggered_by: number,
    shift_id: number,
}

export interface ShiftSavedEvent {
    event: 'created' | 'updated',
    event_triggered_by: number,
    id: number,
    length: number,
    offset: number,
}

export interface WarningAddedEvent extends WarningResponse {
    event_triggered_by: number,
}

export interface WarningsClearedEvent {
    // ID of the object where warnings were cleared
    id: number,
    morph_class: string,
    // Which observer the warning was cleared from, or if null, all warnings are cleared
    observer_id: number | null,
}

export interface ScheduleProgressEvent {
    id: number,
    progress: number
}

export interface EmployeeHrFileCreatedEvent {
    id: number,
    type_id: string | number
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class WebsocketService {
    private oAuthService = inject(OAuthService);

    private socket: Echo | undefined;

    constructor() {
        window.addEventListener('unload', this.disconnect.bind(this));
        window.addEventListener('beforeunload', this.disconnect.bind(this));
    }

    private createSocket(url: string) {
        let socket: Echo | undefined;

        const token = this.oAuthService.getAccessToken();
        if (!token) {
            return undefined;
        }

        try {
            // https://socket.io/docs/v2/client-initialization/
            socket = new Echo({
                reconnectionDelayMax: environment.isLive ? 15_000 : 5_000,
                reconnectionAttempts: environment.isLive ? 10 : 1,
                reconnectionDelay: environment.isLive ? 10_000 : 1_000,
                extraHeaders: {
                    'X-Ui-Version': environment.version,
                },
                transports: [ 'websocket' ],
                broadcaster: 'socket.io',
                // eslint-disable-next-line @typescript-eslint/no-require-imports
                client: require('socket.io-client'),
                authEndpoint: '/api/broadcasting/auth',
                host: environment.isLive ? url : `${window.location.hostname}:6001`,
                auth: {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            });
        } catch (e) {
            reportError(e as Error);
            return undefined;
        }

        return socket;
    }

    notification(channelName: string, callback: (data: any) => void, destroyRef?: DestroyRef) {
        const socket = this.get();
        if (!socket) {
            return;
        }

        socket.private(channelName).notification(callback);
        destroyRef?.onDestroy(() => socket.leave(channelName));
    }

    listenSystemAlerts(callback: (data: any) => void, destroyRef: DestroyRef): void {
        this.listenPublic('system_alerts', '.system_alert', callback, destroyRef);
    }

    listenMessageConversation(conversationId: number, event: MessageConversationEvent, callback: (data: any) => void, destroyRef: DestroyRef): void {
        this.listen(`conversation.${conversationId}`, event, callback, destroyRef);
    }

    listenCustomerTimepunches(customerId: number, event: 'punched', callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenCustomerTimepunches(customerId: number, event: CustomerTimepunchesEvent, callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void {
        this.listen(`customers.${customerId}.employees.*.timepunches`, event, callback, destroyRef, options);
    }

    listenEmployeeTimepunches(customerId: number, employeeId: number, event: 'punched', callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenEmployeeTimepunches(customerId: number, employeeId: number, event: EmployeeTimepunchesEvent, callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void {
        this.listen(`customers.${customerId}.employees.${employeeId}.timepunches`, event, callback, destroyRef, options);
    }

    listenEmployeeHrFiles(customerId: number, employeeId: number, event: 'created', callback: (data: EmployeeHrFileCreatedEvent) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenEmployeeHrFiles(customerId: number, employeeId: number, event: EmployeeHrFilesEvent, callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void {
        this.listen(`customers.${customerId}.employees.${employeeId}.hr_files.*`, event, callback, destroyRef, options);
    }

    listenCustomerSchedules(customerId: number, event: 'created', callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenCustomerSchedules(customerId: number, event: 'progress', callback: (data: ScheduleProgressEvent) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenCustomerSchedules(customerId: number, event: CustomerScheduleEvent, callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void {
        this.listen(`customers.${customerId}.schedules.*`, event, callback, destroyRef, options);
    }

    listenSchedule(customerId: number, scheduleId: number, event: 'summary_calculated', callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenSchedule(customerId: number, scheduleId: number, event: 'warnings_cleared', callback: (data: WarningsClearedEvent) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenSchedule(customerId: number, scheduleId: number, event: 'warning_added', callback: (data: WarningAddedEvent) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenSchedule(customerId: number, scheduleId: number, event: 'shift_deleted', callback: (data: ShiftDeletedEvent) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenSchedule(customerId: number, scheduleId: number, event: 'shift_saved', callback: (data: ShiftSavedEvent) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenSchedule(customerId: number, scheduleId: number, event: 'progress', callback: (data: ScheduleProgressEvent) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenSchedule(customerId: number, scheduleId: number, event: ScheduleEvent, callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void {
        this.listen(`customers.${customerId}.schedules.${scheduleId}`, event, callback, destroyRef, options);
    }

    listenReport(customerId: number, reportId: number, event: 'report_run_done', callback: (data: ReportRunDoneSocketResponse) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void
    listenReport(customerId: number, reportId: number, event: ReportEvent, callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions): void {
        this.listen(`customers.${customerId}.reports.${reportId}`, event, callback, destroyRef, options);
    }

    private listen(channelName: string, event: string, callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions) {
        const socket = this.get();
        if (!socket) {
            return;
        }

        const channel = socket.private(channelName);
        const formattedEvent = event.startsWith('.') ? event : `.${event}`;

        channel.listen(formattedEvent, callback);
        destroyRef.onDestroy(() => socket.leave(channelName));
        options?.customDestroy?.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => socket.leave(channelName));
    }

    private listenPublic(channelName: string, event: string, callback: (data: any) => void, destroyRef: DestroyRef, options?: WebsocketListenOptions) {
        const socket = this.get();
        if (!socket) {
            return;
        }

        const channel = socket.channel(channelName);
        const formattedEvent = event.startsWith('.') ? event : `.${event}`;

        channel.listen(formattedEvent, callback);
        destroyRef.onDestroy(() => socket.leave(channelName));
        options?.customDestroy?.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => socket.leave(channelName));
    }

    private get() {
        if (this.socket) {
            return this.socket;
        }

        this.socket = this.createSocket(EawUrl.url);
        return this.socket;
    }

    private disconnect() {
        this.socket?.disconnect();
    }
}
