// @ts-nocheck
import { keyBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import MlTimeSeriesEntry from '../shared/ml-timeseries-entry';
import MlTimeSeries from '../shared/ml-timeseries';
import MlProjection from '../shared/ml-projection';
import { ElementInitialization } from '../../../shared/angularjs/modules/misc/services/element-initialization.service';
import { EawChart } from '../../../shared/angularjs/modules/misc/services/eaw-chart';
module('eaw.ai-budgeting').component('aiBudgetProjections', {
    template: `<md-card id="projections-filters" ng-show="$ctrl.showFilter">
    <md-card-content>
        <form name="tempForm" ng-submit="$ctrl.getData()">
            <div layout="row" layout-wrap>
                <eaw-date-interval layout="row"
                                   ng-model="$ctrl.dateInterval"
                                   required
                                   flex="100"
                                   flex-md="50"
                                   flex-gt-md="50">
                </eaw-date-interval>

                <md-input-container flex="100" flex-sm="50" flex-gt-sm="25">
                    <label ng-i18next="INTERVAL"></label>
                    <md-select ng-model="$ctrl.interval" required>
                        <md-option ng-repeat="(text, interval) in $ctrl.constants.intervals track by $index" ng-value="interval">
                            <span ng-bind="text | eawTranslate:'ai_budgeting'"></span>
                        </md-option>
                    </md-select>
                </md-input-container>

                <md-input-container flex="100" flex-sm="50" flex-gt-sm="25">
                    <label ng-i18next="ai_budgeting:VARIABLE"></label>
                    <md-select ng-model="$ctrl.variable" required>
                        <md-option ng-repeat="variable in $ctrl.bucket.variables track by variable.uuid" ng-value="variable">
                            <span ng-bind="variable.name"></span>
                        </md-option>
                    </md-select>
                </md-input-container>
            </div>

            <eaw-submit-btn form="$ctrl.form"></eaw-submit-btn>
        </form>
    </md-card-content>
</md-card>

<md-card id="projections-card">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-i18next="ai_budgeting:PROJECTION_plural"></span>
        </md-card-header-text>

        <md-button class="md-icon-button" ng-click="$ctrl.revertAll()" ng-disabled="!$ctrl.someEdited()">
            <md-icon ng-bind="'restore_from_trash'">
                <md-tooltip ng-i18next="ai_budgeting:RESET_ALL_VALUE" md-direction="left"></md-tooltip>
            </md-icon>
        </md-button>

        <eaw-toggle-btn ng-model="$ctrl.showFilter"
                        icon="filter_alt"
                        i18n-tooltip="FILTER">
        </eaw-toggle-btn>

        <card-btn-float ng-disabled="$ctrl.saving || !$ctrl.someEdited()"
                        mdi-icon="save"
                        tooltip="'SAVE'"
                        on-click="$ctrl.save()">
        </card-btn-float>
    </md-card-header>
    <md-card-content id="projections-table-container" class="tw-p-0 overflow-x-auto">
        <md-progress-circular ng-if="$ctrl.gettingData" class="tw-mt-16 md-progress-center"></md-progress-circular>

        <div id="entries-table" ng-if="!$ctrl.gettingData && $ctrl.entries.length">
            <div id="entries-table-head" class="entry-row">
                <div class="entry-cell" bo-i18next="DATE"></div>
                <div class="entry-cell" bo-i18next="ai_budgeting:LAST_YEAR_SALES"></div>
                <div class="entry-cell">
                    <div layout="column" layout-align="center end">
                        <span bo-i18next="ai_budgeting:FORECAST"></span>
                        <small bo-i18next="ai_budgeting:EDITABLE"></small>
                    </div>
                </div>
                <div class="entry-cell">
                    <div layout="column" layout-align="center end">
                        <span bo-i18next="ai_budgeting:FORECAST_VS_LAST_YEAR"></span>
                        <small bo-i18next="ai_budgeting:EDITABLE"></small>
                    </div>
                </div>
                <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries" bo-i18next="ai_budgeting:AI_FORECAST"></div>
                <div class="entry-cell" bo-i18next="ai_budgeting:ACTUAL_SALES"></div>
                <div class="entry-cell" bo-i18next="ai_budgeting:ACTUAL_VS_FORECAST"></div>
                <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries" bo-i18next="ai_budgeting:ACTUAL_VS_AI_FORECAST"></div>
                <div class="entry-cell"></div>
            </div>

            <md-virtual-repeat-container id="virtual-entries-container">
                <div class="entry-row" md-virtual-repeat="entry in $ctrl.entries" ng-class="{'edited': entry.edited}">
                    <div class="entry-cell date-cell">
                        <div ng-bind="entry.timeString"></div>
                        <div class="weekday" ng-bind="entry.time.format('dddd')"></div>
                    </div>

                    <div class="entry-cell">
                        <span ng-if="entry.hasLastYear" ng-bind="entry.lastYear.value | eawNumber"></span>
                    </div>

                    <div class="entry-cell editable-item hover-item edit-highlight" ng-click="$ctrl.startEditingClick(entry, 'forecast', $event)">
                        <span ng-if="entry.forecast.hasValue" ng-bind="entry.forecast.value | eawNumber"></span>

                        <input type="number"
                               step="1"
                               eaw-on-tab-enter="$ctrl.onNext()"
                               ng-blur="$ctrl.stopEditing($event)"
                               ng-change="entry.updateForecastDiff()"
                               ng-model="entry.forecast.value">
                    </div>

                    <div ng-class="{'editable-item hover-item': entry.hasLastYear}" class="entry-cell edit-highlight" ng-click="$ctrl.startEditingClick(entry, 'diff', $event)">
                        <span ng-if="entry.forecast.hasValue" ng-bind="entry.forecastDiff | percent:2"></span>

                        <input type="number"
                               step=".01"
                               ng-if="entry.hasLastYear"
                               eaw-on-tab-enter="$ctrl.onNext()"
                               ng-blur="$ctrl.stopEditing($event)"
                               ng-change="entry.updateForecastValue()"
                               ng-model="entry.forecastDiffBig">
                    </div>

                    <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries">
                        <span ng-if="entry.hasAutoForecast" ng-bind="entry.autoForecast.value | eawNumber"></span>
                    </div>

                    <div class="entry-cell">
                        <span ng-if="entry.hasActual" ng-bind="entry.actual.value | eawNumber"></span>
                    </div>

                    <div class="entry-cell edit-highlight">
                        <span ng-if="entry.hasActual" ng-bind="entry.actualDiff | percent:2"></span>
                    </div>

                    <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries">
                        <span ng-if="entry.hasAutoForecast" ng-bind="entry.autoForecastDiff | percent:2"></span>
                    </div>

                    <div class="entry-cell">
                        <eaw-icon-button
                                eaw-invisible="{{!entry.edited}}"
                                icon="refresh"
                                tooltip="'RESET'"
                                ng-click="$ctrl.revert(entry)">
                        </eaw-icon-button>
                    </div>
                </div>
            </md-virtual-repeat-container>

            <div id="entries-table-foot" class="entry-row">
                <div class="entry-cell"></div>
                <div class="entry-cell" ng-bind="$ctrl.sums.lastYear | eawNumber"></div>
                <div class="entry-cell" ng-bind="$ctrl.sums.forecast | eawNumber"></div>
                <div class="entry-cell" ng-bind="$ctrl.sums.forecastDiff | percent"></div>
                <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries" ng-bind="$ctrl.sums.autoForecast | eawNumber"></div>
                <div class="entry-cell" ng-bind="$ctrl.sums.actual | eawNumber"></div>
                <div class="entry-cell" ng-bind="$ctrl.sums.actualDiff | percent"></div>
                <div class="entry-cell" ng-if="$ctrl.automaticForecastSeries" ng-bind="$ctrl.sums.autoForecastDiff | percent"></div>
                <div class="entry-cell"></div>
            </div>
        </div>
    </md-card-content>
</md-card>
`,
    bindings: {
        bucket: '<',
        customer: '<',
        constants: '<',
    },
    controller: [ 'mlTimeSeriesFactory', '$element', 'ToastService', '$mdSidenav', 'mlProjectionsFactory', function(mlTimeSeriesFactory, $element, ToastService, $mdSidenav, mlProjectionsFactory) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.showFilter = true;
            ctrl.interval = {
                from: moment().subtract(1, 'M'),
                to: moment(),
            };
            ctrl.elInit = new ElementInitialization('#projections-graph', $element[0]);
            ctrl.elInit.observe().then((container) => {
                ctrl.chartContainer = container;
                ctrl.createChart();
            });
        };
        ctrl.$onDestroy = () => {
            ctrl.chart?.destroy();
        };
        ctrl.openSidenav = () => {
            $mdSidenav('sidenav').open();
        };
        ctrl.apply = (projection) => {
            mlProjectionsFactory.apply.query(ctrl.customer.id, ctrl.bucket.id, projection.timeSeriesId, projection.id).then(() => {
                ToastService.tToast('ai_budgeting:PROJECTION_REGISTERED');
            });
        };
        ctrl.getSeries = (filter) => mlTimeSeriesFactory.getAll.query(ctrl.customer.id, ctrl.bucket.id, ctrl.constants.time_series_types.FORECAST, ctrl.variable?.id, {
            filter,
        }).then((resp) => resp.data);
        ctrl.getStaticData = () => {
            const actualPromise = mlTimeSeriesFactory.getAll.query(ctrl.customer.id, ctrl.bucket.id, ctrl.constants.time_series_types.ACTUAL, ctrl.variable?.id);
            const forecastPromise = mlTimeSeriesFactory.getAll.query(ctrl.customer.id, ctrl.bucket.id, ctrl.constants.time_series_types.FORECAST, ctrl.variable?.id);
            return Promise.all([
                actualPromise,
                forecastPromise,
            ]).then((resp) => {
                const actual = keyBy(resp[0].data.map((s) => new MlTimeSeries(s)), 'uuid');
                const forecast = keyBy(resp[1].data.map((s) => new MlTimeSeries(s)), 'uuid');
                return Promise.all([
                    ctrl.getSeriesEntries(actual),
                    ctrl.getSeriesEntries(forecast),
                ]);
            });
        };
        ctrl.getSeriesEntries = (series) => {
            const entriesPromise = Object.values(series).map((s) => mlTimeSeriesFactory.getEntries.query(ctrl.customer.id, ctrl.bucket.id, s.id, ctrl.interval.from, ctrl.interval.to, false, { per_page: 99999 }));
            return Promise.all(entriesPromise).then((resp) => {
                resp.forEach((r) => {
                    if (r.entries.length) {
                        const seriesId = r.entries[0].time_series_uuid;
                        r.entries.forEach((e) => {
                            series[seriesId].addEntry(new MlTimeSeriesEntry(e, series[seriesId]));
                        });
                    }
                });
                ctrl.addStaticSeriesToChart(series);
            });
        };
        ctrl.addStaticSeriesToChart = (series) => {
            // Go over all actual time series
            Object.entries(series).forEach((entry) => {
                const timeSeries = entry[1];
                // Remove series if it exists from before
                ctrl.chart?.series?.find((s) => s.getName() === timeSeries.name)?.remove();
                // Add series
                ctrl.chart.addSeries({
                    color: '#444',
                    name: timeSeries.name,
                    data: timeSeries.entries?.map((e) => [ e.unix, e.value ]),
                });
            });
        };
        ctrl.getProjections = () => {
            ctrl.showFilter = false;
            ctrl.gettingData = true;
            ctrl.chart?.destroy();
            ctrl.createChart();
            Promise.all([
                ctrl.getStaticData(),
                mlProjectionsFactory.getAll.query(ctrl.customer.id, ctrl.bucket.id, ctrl.series.uuid, {
                    from: ctrl.interval.from,
                    to: ctrl.interval.to,
                    per_page: 999,
                }),
            ]).then((resp) => {
                ctrl.projections = resp[1].data.map((d) => new MlProjection(d));
            }).finally(() => {
                delete ctrl.gettingData;
                ctrl.form.unsubmit();
            });
        };
        ctrl.projectionChange = (projection) => {
            if (projection.selected) {
                ctrl.chart.addSeries({
                    name: projection.name,
                    data: projection.projections?.map((p) => [ p.unix, p.value ]),
                });
            } else {
                ctrl.chart?.series?.find((s) => s.getName() === projection.name)?.remove();
            }
        };
        ctrl.createChart = () => {
            const options = {
                rangeSelector: {
                    enabled: true,
                },
                xAxis: {
                    type: 'datetime',
                },
            };
            new EawChart(ctrl.chartContainer, options, true).getChart().then((chart) => {
                ctrl.chart = chart;
            });
        };
    } ],
});
