import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Shift, ShiftResponse } from '../../scheduling/models/shift';
import { Timepunch, TimepunchResponse } from '../../payroll/models/timepunch';
import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { map, Observable } from 'rxjs';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { DateTime } from 'luxon';

type ReportResponse = {
    shifts: Record<number, ShiftResponse[]>,
    timepunches: Record<number, TimepunchResponse[]>
}
export type InspectionResponse = {
    [key: string]: ReportResponse,
} & { employees: EmployeeResponse[] }

export type Inspection = {
    reports: {
        date: DateTime,
        shifts: Record<number, Shift[]>
        timepunches: Record<number, Timepunch[]>
    }[],
    employees: Employee[]
}

type EmployeeShiftArray = Array<[string, Shift[]]>
type EmployeeTimepunchArray = Array<[string, Timepunch[]]>

@Injectable({
    providedIn: 'root',
})
export class InspectionService {
    constructor(@Inject(HttpClient) protected http: HttpClient) {
    }

    getInspection(customerId: number): Observable<Inspection> {
        return this.http.get<InspectionResponse>(`/customers/${customerId}/inspection`, {}).pipe(
            map((response) => {
                const reports: Inspection['reports'] = Object.entries(response).filter(([ key, _ ]) => key !== 'employees').map(([ key, value ]) => this.mapDates(key, value));
                return {
                    employees: response?.employees ? response.employees.map((employee) => new Employee(employee)) : [],
                    reports,
                };
            }),
        );
    }

    private mapDates(key: string, value: ReportResponse | EmployeeResponse[]) {
        const shiftsArray: [string, Shift[]][] = 'shifts' in value ? Object.entries(value.shifts).map(([ key, employeeShift ]) => [ key, employeeShift.map((shift) => new Shift(shift)) ])
            : [];
        const timepunchesArray: [string, Timepunch[]][] = 'timepunches' in value ? Object.entries(value.timepunches).map(([ key, employeeTimepunch ]) => [ key, employeeTimepunch.map((timepunch) => new Timepunch(timepunch)) ])
            : [];
        const shifts = (data: EmployeeShiftArray) => {
            const newObj: Record<string, Shift[]> = {};
            for (const val of Object.values(data)) {
                newObj[val[0]] = val[1];
            }
            return newObj;
        };
        const timepunches = (data: EmployeeTimepunchArray) => {
            const newObj: Record<string, Timepunch[]> = {};
            for (const val of Object.values(data)) {
                newObj[val[0]] = val[1];
            }
            return newObj;
        };
        return {
            date: stringToDateTime(key, false, 'yyyy-MM-dd'),
            shifts: shifts(shiftsArray),
            timepunches: timepunches(timepunchesArray),
        };
    }
}
