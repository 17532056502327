// @ts-nocheck
import { t } from 'i18next';
import { sortBy } from 'lodash-es';
import { find } from 'lodash-es';
import { module } from 'angular';

module('eaw.messages').component('eawWriteMessage', {
    template: `<md-content class="write-new-message">
    <md-toolbar class="default-toolbar">
        <div class="md-toolbar-tools">
            <h2 flex ng-i18next="messages:NEW_MESSAGE"></h2>
            <md-button hide-gt-md class="md-icon-button" ng-click="$write.msgConv.convListSideNav.toggle()">
                <md-icon ng-bind="'view_list'"></md-icon>
            </md-button>
        </div>
    </md-toolbar>

    <form name="writeMessage" layout="column" class="tw-p-16">
        <md-chips ng-model="$write.recipients" md-require-match="true">
            <md-autocomplete md-selected-item="$write.selectedRecipient"
                             md-search-text="filter"
                             md-items="item in $write.getRecipients(filter)"
                             md-item-text="item.name"
                             md-delay="300"
                             placeholder="{{'TO' | i18next}}"
                             md-min-length="0">
                <span md-highlight-text="filter" md-highlight-flags="gi">{{item.name}}</span>
            </md-autocomplete>
            <md-chip-template>
                <span>{{$chip.name}}</span>
            </md-chip-template>
        </md-chips>

        <md-input-container>
            <input id="subject" ng-model="$write.subject" required ng-i18next="[placeholder]messages:SUBJECT" maxlength="128">
        </md-input-container>
        
        <div class="tw-flex tw-gap-4" style="align-self: flex-end">
          <md-checkbox ng-model="$write.isBlind">
           <span ng-i18next="messages:BCC_LABEL"></span>
          </md-checkbox>
          
           <md-button ng-click="$write.addAttachment()" ng-i18next="ATTACHMENT"></md-button>
            <md-button class="md-raised md-accent" ng-click="$write.sendMessage()" ng-disabled="!($write.hasContent && $write.subject && $write.recipients.length)" ng-i18next="SEND"></md-button>
    </div>
     

        <messages-files files="$write.files"></messages-files>

        <wysiwyg-downgrade (content-change)="$write.contentChange($event)" (editor-change)="$write.editorChange($event)"></wysiwyg-downgrade>

        <md-button class="display-none"
                   id="file-upload-btn"
                   ngf-select
                   ng-model="$write.files"
                   ngf-keep="'distinct'"
                   ngf-multiple="true">
        </md-button>
    </form>
</md-content>
`,
    controllerAs: '$write',
    require: {
        msgConv: '^eawMessageConversations',
    },
    controller: [ '$rootScope', 'Message', 'ToastService', function($rootScope, Message, ToastService) {
        const ctrl = this;

        ctrl.hasContent = false;
        ctrl.isBlind = false;

        ctrl.editorChange = (ev: any) => {
            ctrl.editor = ev;
        }

        ctrl.contentChange = ev => {
            ctrl.hasContent = !!ev;
            ctrl.text = ev;
        }

        ctrl.$onInit = () => {
            ctrl.recipients = [];
        };

        ctrl.addAttachment = () => {
            document.getElementById('file-upload-btn').click();
        };

        ctrl.getRecipients = (filter) => Message.getRecipients({
            filter,
            per_page: 25,
        }).$promise.then((resp) => {
            // Only send out the ones we don't already have.
            const recipients = resp.data.filter((r) => !find(ctrl.recipients, { user_id: r.user_id })
                        && !find(ctrl.recipients, { group_id: r.group_id })
                        && !find(ctrl.recipients, { customer_id: r.customer_id }));
            return sortBy(recipients, (r) => r.name);
        });

        ctrl.reset = () => {
            ctrl.text = '';
            ctrl.editor.setContent('')
            ctrl.isBlind = false;
            ctrl.recipients = [];
            ctrl.userGroups = undefined;
            ctrl.subject = undefined;
        };

        ctrl.uploadFiles = (message) => {
            if (ctrl.files) {
                Promise.all(ctrl.files.map((file) => Message.uploadAttachment(message, file))).then(() => ctrl.files = []);
            }
        };

        ctrl.onMessageSent = (message) => {
            ToastService.toast(t('messages:MESSAGE_SENT'));
            $rootScope.$broadcast('messages:new_message_sent', message);
            ctrl.reset();
            ctrl.uploadFiles(message);
        };

        // Send message, this also creates a new thread
        ctrl.sendMessage = () => {
            const recipients = ctrl.recipients || [];
            ctrl.msgRecipients = recipients.filter((r) => r.user_id).map((r) => r.user_id);
            ctrl.msgUserGroupRecipients = recipients.filter((r) => r.user_group_id).map((r) => r.user_group_id);
            ctrl.msgCustomerRecipients = recipients.filter((r) => r.customer_id).map((r) => r.customer_id);

            Message.sendMessage(ctrl.subject, ctrl.text, {
                is_blind: ctrl.isBlind ? ctrl.isBlind : undefined,
                recipients: ctrl.msgRecipients,
                user_group_recipients: ctrl.msgUserGroupRecipients,
                customer_recipients: ctrl.msgCustomerRecipients,
            }, [ 'user', 'recipients' ]).$promise.then(ctrl.onMessageSent);
        };
    } ],
});
