@if (loadingData) {
    <eaw-info-loading size="sm">
        {{'LOADING_FILES_INFO' | translate:'new_employee' | async}}
    </eaw-info-loading>
} @else {
    <mat-selection-list multiple >
        @for (entry of entries; track entry.type.id) {
            <eaw-subheader>{{entry.type.name}}</eaw-subheader>
            @for (file of entry.files; track file.id) {
                <mat-list-option [selected]="selectedFiles.has(file.id)" [disabled]="processingFiles.has(file.id)" (click)="toggleFile(file)">{{file.name}}</mat-list-option>
            }
        }
    </mat-selection-list>
}

<eaw-new-employee-next-step [stepper]="stepper" [step]="step"/>
