import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime, Info } from 'luxon';
import { ShiftConfigurationsService } from '../../http/shift-configurations.service';
import CustomerOld from '../../../shared/angularjs/customer-old';
import { ShiftConfiguration, ShiftConfigurationWeekdays } from '../../models/shift-configuration';
import { uniqueId } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { catchError, EMPTY, Observable } from 'rxjs';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { TimeInputComponent } from '../../../shared/components/date-time/time-input/time-input.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

type ShiftSettingFormGroupLine = FormGroup<{
    id: FormControl<string>,
    name: FormControl<string>,
    weekdays: FormControl<ShiftConfigurationWeekdays>,
    from: FormControl<DateTime>,
    to: FormControl<DateTime>,
}>;

@Component({
    selector: 'eaw-shift-settings',
    templateUrl: './shift-settings.component.html',
    styleUrl: './shift-settings.component.scss',
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        NgIf,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        NgFor,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        TimeInputComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ShiftSettingsComponent implements OnInit {
    @Input() customer!: CustomerOld;

    fetching = false;
    // Going from 0 to 6 because of backend
    weekdays = [
        {
            value: 0,
            name: Info.weekdays()[6],
        }, // Sunday
        {
            value: 1,
            name: Info.weekdays()[0],
        }, // Monday
        {
            value: 2,
            name: Info.weekdays()[1],
        }, // Tuesday
        {
            value: 3,
            name: Info.weekdays()[2],
        }, // Wednesday
        {
            value: 4,
            name: Info.weekdays()[3],
        }, // Thursday
        {
            value: 5,
            name: Info.weekdays()[4],
        }, // Friday
        {
            value: 6,
            name: Info.weekdays()[5],
        }, // Saturday
    ];

    form = new FormGroup({
        shifts: new FormArray<ShiftSettingFormGroupLine>([]),
    });

    constructor(
        @Inject(ShiftConfigurationsService) private shiftConfigurationsService: ShiftConfigurationsService,
    ) {
    }

    ngOnInit() {
        this.fetching = true;
        this.shiftConfigurationsService.getAll(this.customer['id']).subscribe((configs) => {
            configs.forEach((c) => this.addLine(c));
            this.fetching = false;
        });
    }

    getShiftLineIndex(id: string) {
        return this.form.controls.shifts.controls.findIndex((c) => c.controls.id.value === id);
    }

    isFakeGroup(formGroup: ShiftSettingFormGroupLine) {
        return formGroup.controls.id.value.startsWith('fake');
    }

    getFormGroupLine(config: ShiftConfiguration): ShiftSettingFormGroupLine {
        return new FormGroup({
            id: new FormControl(config.id, {
                validators: [ Validators.required ],
                nonNullable: true,
            }),
            name: new FormControl(config.name, {
                validators: [ Validators.required ],
                nonNullable: true,
            }),
            weekdays: new FormControl<ShiftConfigurationWeekdays>(config.weekdays, {
                validators: [ Validators.required ],
                nonNullable: true,
            }),
            from: new FormControl(config.from, {
                validators: [ Validators.required ],
                nonNullable: true,
            }),
            to: new FormControl(config.to, {
                validators: [ Validators.required ],
                nonNullable: true,
            }),
        });
    }

    getFakeShiftConfiguration() {
        return new ShiftConfiguration({
            id: uniqueId('fake'),
            name: '',
            weekdays: [],
            hour_end: 0,
            hour_start: 0,
            minute_end: 0,
            minute_start: 0,
        });
    }

    addLine(config?: ShiftConfiguration) {
        const shiftConfig = config || this.getFakeShiftConfiguration();
        this.form.controls.shifts.push(this.getFormGroupLine(shiftConfig));
    }

    onError(formGroup: ShiftSettingFormGroupLine): Observable<never> {
        formGroup.enable();
        formGroup.reset();
        return EMPTY;
    }

    updateLine(formGroup: ShiftSettingFormGroupLine) {
        const controls = formGroup.controls;
        const configId = controls.id.value;

        const body = {
            hour_start: controls.from.value.hour,
            hour_end: controls.to.value.hour,
            minute_start: controls.from.value.minute,
            minute_end: controls.to.value.minute,
            name: controls.name.value,
            weekdays: controls.weekdays.value.length === 7 ? [] : controls.weekdays.value,
        };
        const observable = this.isFakeGroup(formGroup) ? this.shiftConfigurationsService.create(this.customer['id'], body) : this.shiftConfigurationsService.update(this.customer['id'], configId, body);

        formGroup.disable();
        observable
            .pipe(
                catchError(() => this.onError(formGroup)),
            ).subscribe((config) => {
                this.form.controls.shifts.setControl(this.getShiftLineIndex(configId), this.getFormGroupLine(config));
            });
    }

    delete(formGroup: ShiftSettingFormGroupLine) {
        this.shiftConfigurationsService.delete(this.customer['id'], formGroup.controls.id.value)
            .pipe(
                catchError(() => this.onError(formGroup)),
            ).subscribe(() => {
                this.form.controls.shifts.removeAt(this.getShiftLineIndex(formGroup.controls.id.value));
            });
        formGroup.disable();
    }
}
