// @ts-nocheck
import { t } from 'i18next';
import { filter } from 'lodash-es';
import { sortBy } from 'lodash-es';
import { keyBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { groupBy } from 'lodash-es';
import { Comment } from '../../../../../shared/models/comment';
import { ShiftPeriodListDialogComponent, ShiftPeriodListDialogData } from '../../../../../scheduling/dialogs/shift-period-list-dialog/shift-period-list-dialog.component';
import { CommentDialogComponent, CommentDialogData } from '../../../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

module('eaw.dashboard').component('myShiftList', {
    template: `<section ng-repeat="date in ::$shiftList.dates track by $index">
    <md-subheader ng-repeat-start="(customerId, shifts) in $shiftList.shifts[date] track by customerId" ng-if="$shiftList.showHeader(date, customerId)">
        <span ng-bind="::$shiftList.locations[customerId].name"></span>
    </md-subheader>

    <md-list class="md-dense tw-p-0" ng-repeat-end>
        <md-list-item ng-repeat="shift in ::shifts track by shift.id">
            <span ng-if="!$shiftList.shiftsWidget.displayFromInList" ng-bind="::shift.business_date | moment:'ll'"></span>
            <span ng-if="$shiftList.shiftsWidget.displayFromInList" ng-bind="::shift.from | moment:'ll'"></span>

            <md-button class="md-secondary md-icon-button tw-p-0 tw-m-0" ng-if="shift.comments.length" ng-click="$shiftList.showComments(shift)">
                <md-icon ng-bind="'comment'"></md-icon>
            </md-button>

            <md-button class="md-secondary md-icon-button tw-p-0 tw-m-0" ng-if="shift.periods.length" ng-click="$shiftList.displayPeriods(shift)">
                <md-icon ng-bind="'timelapse'"></md-icon>
            </md-button>

            <div class="md-secondary">
                <span class="tw-m-0" ng-bind="::shift.fromToText" ng-class="::{'required': shift.hasDifferentBusinessDate}"></span>
                <md-tooltip ng-if="shift.hasDifferentBusinessDate">{{::shift.tooltip}}</md-tooltip>
            </div>
        </md-list-item>
    </md-list>
</section>
`,
    require: {
        eawWidget: '^^eawWidget',
        shiftsWidget: '^shiftsWidget',
    },
    controllerAs: '$shiftList',
    controller: [ 'MatDialogDowngrade', function(MatDialogDowngrade: MatDialog) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.locations = keyBy(ctrl.shiftsWidget.shifts.map((s) => s.schedule.customer), 'id');
            const dateGrouped = groupBy(ctrl.shiftsWidget.shifts, (s) => {
                s.date = ctrl.getDateName(s);
                ctrl.addFromToText(s);
                return s.business_date.format('YYYY-MM-DD');
            });
            const customerGrouped = {};
            Object.entries(dateGrouped).forEach((e1) => {
                const [ date, shifts1 ] = e1;
                const grouped = groupBy(shifts1, 'schedule.customer.id');
                customerGrouped[date] = {};
                Object.entries(grouped).forEach((e2) => {
                    const [ customerId, shifts2 ] = e2;
                    customerGrouped[date][customerId] = sortBy(shifts2, 'from');
                });
            });
            ctrl.dates = sortBy(Object.keys(customerGrouped));
            ctrl.shifts = customerGrouped;
            if (!ctrl.dates?.length) {
                ctrl.eawWidget.setEmpty(true);
            }
        };
        ctrl.showComments = (shift) => {
            MatDialogDowngrade.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
                data: {
                    comments: of(shift.comments.map((c) => new Comment(c))),
                },
            });
        };
        ctrl.addFromToText = (shift) => {
            shift.fromToText = `${shift.from.format('LT')} - ${shift.to.format('LT')}`;
            shift.tooltip = t('FROM_TO', {
                from: shift.from.format('LL - LT'),
                to: shift.to.format('LL - LT'),
            });
            shift.hasDifferentBusinessDate = !shift.from.isSame(shift.business_date, 'd');
        };
        ctrl.showHeader = (date, customerId) => {
            const index = ctrl.dates.indexOf(date);
            const previous = ctrl.dates[index - 1];
            const prevDate = previous ? Object.values(ctrl.shifts[previous]) : [];
            // First element ever, or more than one customer on this day or the day before.
            if (!index || ctrl.shifts[date].length > 1 || previous && prevDate.length > 1) {
                return true;
            }
            // last shift on previous day had schedule with a different customer-id
            const prev = prevDate[prevDate.length - 1];
            const last = prev[prev.length - 1];
            return last?.schedule?.customer_id !== customerId;
        };
        ctrl.getDateName = (shift) => {
            let name = shift.business_date.format('dd, ll');
            const today = moment().startOf('d');
            const tomorrow = moment().add(1, 'd').startOf('d');
            if (shift.business_date.isSame(today, 'd')) {
                name = t('TODAY');
            }
            if (shift.business_date.isSame(tomorrow, 'd')) {
                name = t('TOMORROW');
            }
            let parenthesis = '';
            // periods have at most one business unit.
            const names = filter(shift.periods.map((period) => period.business_unit)).map((businessUnit, index) => parenthesis += index > 0 ? `, ${businessUnit.name}` : businessUnit.name);
            if (names.length) {
                name += ` (${parenthesis})`;
            }
            return name;
        };
        ctrl.displayPeriods = (shift) => {
            if (!Object.keys(shift.periods || []).length) {
                return;
            }

            MatDialogDowngrade.open<ShiftPeriodListDialogComponent, ShiftPeriodListDialogData>(ShiftPeriodListDialogComponent, {
                data: {
                    customerId: shift.schedule.customer_id,
                    scheduleId: shift.schedule_id,
                    shiftId: shift.id,
                },
            });
        };
    } ],
});
