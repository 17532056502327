import { ApiResponse } from '../../shared/interfaces/api-response';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ReferenceDay, ReferenceDayResponse } from './reference-day';
import { Projection, ProjectionResponse } from './projection';

export type CommentDayResponse = {
    date: string,
    comment: string,
}

export interface ProjectionDayResponse extends ApiResponse {
    date: string,
    defaultReferenceDays?: ReferenceDayResponse[] | null,
    chosenReferenceDays?: ReferenceDayResponse[],
    projections: {
        'sales.net'?: ProjectionResponse[],
        transactions?: ProjectionResponse[],
    },
    initialProjections: {
        'sales.net'?: ProjectionResponse[],
        transactions?: ProjectionResponse[],
    },
    prevYearActualData: {
        'sales.net'?: ProjectionResponse[],
        transactions?: ProjectionResponse[],
    },

    savedProjections?: {
        transactions?: ProjectionResponse[],
        atGenerated?: ProjectionResponse[],
        atEdited?: ProjectionResponse[],
        salesGenerated?: ProjectionResponse[],
    }
    percentageVsPrevYear?: {
        'sales.net': number,
        transactions: number,
    }

    prevYearComment: CommentDayResponse[];

    comment?: string,
}

export type ProjectionDayUpdate = {
    reference_days: string[],
    projections: Record<string, ProjectionResponse[]>
}

export class ProjectionDay {
    date: DateTime;
    defaultReferenceDays: ReferenceDay[];
    chosenReferenceDays: ReferenceDay[];
    projections: {
        transactions?: Projection,
        transactionsEdited?: Projection,
        transactionsPrevYear?: Projection,
        atGenerated?: Projection,
        atEdited?: Projection,
        sales?: Projection,
        salesGenerated?: Projection,
        salesPrevYear?: Projection,
    };

    percentageVsPrevYear: {
        sales?: number;
        transactions?: number;
        at?: number;
    };

    comment?: string;
    tag?: string;
    commentLastYear?: string;

    constructor(data: ProjectionDayResponse) {
        this.date = stringToDateTime(data.date, true, 'yyyy-MM-dd');
        this.defaultReferenceDays = data.defaultReferenceDays ? data.defaultReferenceDays.map((day) => new ReferenceDay(day)) : [];
        this.chosenReferenceDays = data.chosenReferenceDays ? data.chosenReferenceDays.map((day) => new ReferenceDay(day)) : [];

        this.projections = {};
        if (data.initialProjections['sales.net'] && data.initialProjections['sales.net'][0]) {
            this.projections.sales = new Projection(data.initialProjections['sales.net'][0]);
        }
        if (data.projections['sales.net'] && data.projections['sales.net'][0]) {
            this.projections.salesGenerated = new Projection(data.projections['sales.net'][0]);
        }

        if (data.initialProjections['transactions'] && data.initialProjections['transactions'][0]) {
            this.projections.transactions = new Projection(data.initialProjections['transactions'][0]);
        }
        if (data.projections['transactions'] && data.projections['transactions'][0]) {
            this.projections.transactionsEdited = new Projection(data.projections['transactions'][0]);
        }

        if (this.projections.sales && this.projections.transactions) {
            this.projections.atGenerated = {
                value: this.projections.sales.value / this.projections.transactions.value,
                percent: 100,
                time: this.projections.sales.time,
            };
        }
        if (this.projections.salesGenerated && this.projections.transactionsEdited) {
            this.projections.atEdited = {
                value: this.projections.salesGenerated.value / this.projections.transactionsEdited.value,
                percent: 100,
                time: this.projections.salesGenerated.time,
            };
        }

        this.percentageVsPrevYear = {
            sales: data.percentageVsPrevYear?.['sales.net'] ? (data.percentageVsPrevYear['sales.net']/100) : undefined,
            transactions: data.percentageVsPrevYear?.transactions ? (data.percentageVsPrevYear.transactions/100) : undefined,
        };

        if (data.prevYearActualData?.transactions && data.prevYearActualData.transactions[0]) {
            this.projections.transactionsPrevYear = new Projection(data.prevYearActualData.transactions[0]);
        }

        if (data.prevYearActualData['sales.net'] && data.prevYearActualData['sales.net'][0]) {
            this.projections.salesPrevYear = new Projection(data.prevYearActualData['sales.net'][0]);
        }
        if (this.projections.transactionsPrevYear && this.projections.salesPrevYear && this.projections.transactions && this.projections.sales) {
            const atPrevYear = this.projections.salesPrevYear.value / this.projections.transactionsPrevYear.value;
            const atPresent = this.projections.sales.value / this.projections.transactions.value;
            this.percentageVsPrevYear.at = (atPresent / atPrevYear) - 1;
        }

        this.comment = data.comment;
        this.commentLastYear = data.prevYearComment.length>0 ? data.prevYearComment[0]?.comment : '';
    }
}
