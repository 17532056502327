<eaw-page-header [fabButton]="fabButton">
    <span title>{{'AVAILABILITY_REQUEST_plural' | translate: 'availabilities' | async}}</span>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        <eaw-data-table
                [columns]="columns"
                [request]="request"
                (rowClick)="showAvailability($event)"
                (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
