import { Inject, Injectable } from '@angular/core';
import { UserAlertService } from './user-alert.service';

@Injectable({
    providedIn: 'root',
})
export class TranslationCheckService {
    private readonly interval: NodeJS.Timeout;

    constructor(
        @Inject(UserAlertService) private userAlertService: UserAlertService,
    ) {
        this.interval = setInterval(this.doCheck.bind(this), 60_000);
    };

    private doCheck() {
        const norsk = document.getElementById('oversettelse-norsk')?.innerText;
        const english = document.getElementById('translation-english')?.innerText;

        if (norsk !== 'Workforce management applikasjon. Tilgjengelig på Android, iOS, Mac og PC' || english !== 'Workforce management application. Available on Android, iOS, Mac and PC') {
            this.userAlertService.addAlert({
                created_at: '',
                id: +new Date(),
                text: 'Your browser has automatic translation turned on. Please turn it off for the easy@work app or it might cause wrong translations in the app.',
                updated_at: '',
            });

            clearInterval(this.interval);
        }
    }
}
