import { module } from 'angular';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="$settings.widget.i18name | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <form id="settings" class="md-dialog-content">
            <md-input-container>
                <label ng-i18next="PERIOD"></label>
                <md-select ng-model="$settings.settings.month" required>
                    <md-option value="this" ng-i18next="widgets:THIS_MONTH"></md-option>
                    <md-option value="previous" ng-i18next="widgets:PREVIOUS_MONTH"></md-option>
                    <md-option value="week" ng-i18next="widgets:THIS_WEEK"></md-option>
                </md-select>
            </md-input-container>            
            <md-input-container>
                <label ng-i18next="HOUR_plural"></label>
                <md-select ng-model="$settings.settings.basis" required ng-disabled="$settings.basisOptions.length === 1">
                  <md-option ng-repeat="opt in $settings.basisOptions" ng-value="opt.value">{{opt.label | i18next}}</md-option>              
                </md-select>
            </md-input-container>
        </form>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button type="submit" form="settings" ng-disabled="!$settings.settings.basis || !$settings.settings.month" ng-click="$settings.submit()" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.dashboard').factory('miniHoursWorkedSettings', [ '$mdDialog', function($mdDialog: any) {
    return function miniHoursWorkedSettings(widget: any, settings: any) {
        return $mdDialog.show({
            template: template1,
            controllerAs: '$settings',
            multiple: true,
            controller: function() {
                // @ts-ignore
                const ctrl = this;

                ctrl.$onInit = () => {
                    ctrl.cancel = $mdDialog.cancel;
                    ctrl.widget = widget;
                    // It seems at least sometimes 'settings' is undefined
                    ctrl.settings = {
                        ...widget.settings,
                        ...settings,
                    };

                    ctrl.basisOptions = [
                        {
                            value: 'timepunches',
                            label: 'TIMEPUNCH_plural',
                        },
                    ];

                    if (CurrentOld.can('customers.{customer}.employees.{employee}.paid_times.*.get')) {
                        ctrl.basisOptions.push({
                            value: 'paid_time',
                            label: 'PAID_TIME',
                        });
                    } else {
                        ctrl.settings.basis = 'timepunches';
                    }
                };

                ctrl.submit = () => {
                    $mdDialog.hide(ctrl.settings);
                };
            },
        });
    };
} ]);
