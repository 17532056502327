import { CompanySettingsSidenavService } from '../../company/services/company-settings-sidenav.service';
import { EnvironmentInjector } from '@angular/core';
import { currentResolver } from '../resolvers/current.resolver';

export function addCompanySettingsSidenav(injector: EnvironmentInjector) {
    injector.get(CompanySettingsSidenavService).init({
        sidenavParentState: `eaw/app/company`,
        sidenavState: `eaw/app/company/settings`,
        url: '/settings',
        data: {
            breadcrumb: { key: 'SETTING_plural' },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
    });
}
