import { ChangeDetectionStrategy, Component, ComponentRef, computed, effect, ElementRef, HostListener, inject, input, OnDestroy, output, reflectComponentType, Signal, signal, viewChild, ViewContainerRef, WritableSignal } from '@angular/core';
import { Widget, WidgetSize } from '../../classes/widget';
import { WidgetService } from '../../http/widget.service';
import { TinyColor } from '@ctrl/tinycolor';
import { MatDialog } from '@angular/material/dialog';
import { ColorpickerDialogComponent, ColorpickerDialogData } from '../../../shared/dialogs/colorpicker-dialog/colorpicker-dialog.component';
import { CurrentService } from '../../../shared/services/current.service';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { TranslateService } from '../../../shared/services/translate.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WidgetOptionsBottomSheetComponent, WidgetOptionsBottomSheetData, WidgetOptionsBottomSheetResult } from '../bottom-sheet-options/widget-options-bottom-sheet.component';
import { ExtendedWidgetInfoComponent, ExtendedWidgetInfoData, ExtendedWidgetInfoResult } from '../extended-widget-info/extended-widget-info.component';
import { WidgetInfoService } from '../../services/widget-info.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { WidgetComponent as WidgetComponentClass } from '../../classes/widget-component';
import { WidgetInfo } from '../../classes/widget-info';

interface WidgetHeaderButtonItem {
    click: () => void;
    menu?: never;
}

interface WidgetHeaderMenuButton {
    click?: never;
    menu: MatMenu;
}

export type WidgetHeaderButton = (WidgetHeaderButtonItem | WidgetHeaderMenuButton) & {
    tooltip: string;
    icon: string;
}

@Component({
    selector: 'eaw-widget-host',
    templateUrl: './widget-host.component.html',
    styleUrl: './widget-host.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf,
        MiniWidgetContentComponent,
        NgFor,
        MatIconModule,
        MatRippleModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
/**
 * This is the host component that is used to create the passed in widget.
 */
export class WidgetHostComponent implements OnDestroy {
    private readonly elementRef = inject(ElementRef);
    private readonly widgetService = inject(WidgetService);
    private readonly matDialog = inject(MatDialog);
    private readonly current = inject(CurrentService);
    private readonly translate = inject(TranslateService);
    private readonly matBottomSheet = inject(MatBottomSheet);
    private readonly widgetInfoService = inject(WidgetInfoService);

    componentHost = viewChild('componentHost', { read: ViewContainerRef });

    @HostListener('click', [ '$event' ])
    clickEvent() {
        if (this.widget().info.mini) {
            if (this.widget().info.extendedInfoComponent) {
                this.openExtendedInfo();
            } else {
                this.openBottomSheet();
            }
        }
    }

    deleted = output();

    widget = input.required<Widget<any>>();
    defaultColor = input.required<TinyColor | null>();
    defaultWidgets = input.required<string[]>();

    /**
     * The component that (might) have been rendered.
     */
    private component = signal<ComponentRef<WidgetComponentClass> | undefined>(undefined);
    protected isMiniWidget = computed(() => this.widget()?.info.mini ?? false);
    protected widgetInfo: Signal<WidgetInfo>;
    protected loading = signal(false);
    protected deleting = signal(false);
    protected failedToInstantiate = signal(false);
    protected normalHeaderButtons = signal<WidgetHeaderButton[]>([]);
    protected failedToInstantiateReason = signal<Promise<string>>(Promise.resolve(''));
    protected errorText?: WritableSignal<Promise<string>>;
    protected errorSubtext?: WritableSignal<Promise<string>>;

    constructor() {
        this.widgetInfo = computed(() => {
            const widget = this.widget();
            const isMini = this.isMiniWidget();
            return widget?.info || this.widgetInfoService.getSkeletonWidget(isMini);
        });

        effect(() => this.toggleClass('loading', this.loading()));
        effect(() => this.toggleClass('deleting', this.deleting()));
        effect(() => this.setColor());

        effect(() => {
            const host = this.componentHost();
            const widget = this.widget();
            if (!host || !widget) {
                return;
            }

            requestAnimationFrame(() => this.createComponent(host, widget));
        });

        effect(() => {
            const widget = this.widget();
            const size = widget.size();

            if (widget) {
                const skeleton = widget.info.key.startsWith('skeleton');
                const mini = widget.info.mini;
                const wide = size === WidgetSize.Wide || size === WidgetSize.TallWide;
                const fullWidth = size === WidgetSize.FullWidth || size === WidgetSize.TallFullWidth;
                const tall = size === WidgetSize.Tall || size === WidgetSize.TallWide || size === WidgetSize.TallFullWidth;

                this.toggleClass('skeleton', skeleton);
                this.toggleClass('mini', mini);
                this.toggleClass('wide', wide);
                this.toggleClass('full-width', fullWidth);
                this.toggleClass('tall', tall);
            }
        });
    }

    ngOnDestroy() {
        this.componentHost()?.clear();
        this.component()?.instance.onDestroy();
    }

    /**
     * Get the element ref as a HTMLElement.
     */
    get element() {
        return this.elementRef.nativeElement as HTMLElement;
    }

    /**
     * Add or remove a class from the element.
     */
    toggleClass(className: string, toggled: boolean) {
        if (toggled) {
            this.element.classList.add(className);
        } else {
            this.element.classList.remove(className);
        }
    }

    createComponent(host: ViewContainerRef, widget: Widget<any>) {
        this.widgetInfoService.hasRequirements(widget.info).subscribe((hasRequirements) => {
            if (!hasRequirements) {
                this.failedToInstantiate.set(true);
                this.failedToInstantiateReason.set(this.translate.t('FAILED_TO_INSTANTIATE_MISSING_REQUIREMENTS', 'widgets'));
                return;
            }

            const component = host.createComponent(widget.info.component);
            this.component.set(component);
            this.failedToInstantiate.set(!component);
            this.failedToInstantiateReason.set(this.translate.t('FAILED_TO_INSTANTIATE_BUILD', 'widgets'));

            const componentMirror = reflectComponentType(widget.info.component);
            const hasWidgetInput = componentMirror?.inputs.find((input) => input.propName === 'widget');
            if (hasWidgetInput) {
                component.setInput('widget', widget);
            }

            component.instance.onNormalHeaderButtons().subscribe((buttons) => {
                this.normalHeaderButtons.set(buttons);
            });

            component.instance.onLoading().subscribe((loading) => {
                this.loading.set(loading);
            });

            component.instance.onError().subscribe((error) => {
                switch (error.type) {
                    case 'settings':
                        this.errorText?.set(this.translate.t('INVALID_SETTING_plural', 'widgets'));
                        this.errorSubtext?.set(this.translate.t('SAFE_TO_DELETE', 'widgets'));
                        break;
                    case 'data':
                        this.errorText?.set(this.translate.t('FAILED_TO_LOAD_DATA', 'widgets'));
                        this.errorSubtext = undefined;
                        break;
                    default:
                        if (error.error) {
                            this.errorText?.set(error.error);
                        }

                        this.errorSubtext = undefined;
                }
            });
        });
    }

    openExtendedInfo() {
        const extendedInfoComponent = this.widget()?.info.extendedInfoComponent;
        if (!extendedInfoComponent) {
            return;
        }

        const ref = this.matDialog.open<ExtendedWidgetInfoComponent, ExtendedWidgetInfoData, ExtendedWidgetInfoResult>(ExtendedWidgetInfoComponent, {
            data: {
                widget: this.widget(),
            },
        });

        ref.componentInstance.onOpenOptions().subscribe(() => {
            const sheetRef = this.openBottomSheet();

            // If the options sheet is opened from a dialog and the result is to delete, close the dialog
            sheetRef?.afterDismissed().subscribe((result) => {
                if (result?.action === 'delete') {
                    ref.close();
                }
            });
        });

        ref.afterClosed().subscribe(() => {
            this.component()?.instance.setExtendedInfoClosed();
        });
    }

    openBottomSheet() {
        const ref = this.matBottomSheet.open<WidgetOptionsBottomSheetComponent, WidgetOptionsBottomSheetData, WidgetOptionsBottomSheetResult>(WidgetOptionsBottomSheetComponent, {
            data: {
                widget: this.widget(),
                defaultWidgetKeys: this.defaultWidgets(),
            },
        });

        ref.afterDismissed().subscribe((result) => {
            if (result?.action === 'delete') {
                this.delete();
            }
            if (result?.action === 'change_color') {
                this.changeColor();
            }
        });

        return ref;
    }

    setColor() {
        const color = this.widget().color() ?? this.defaultColor() ?? new TinyColor('#000');

        this.elementRef.nativeElement.style.setProperty('--header-background-color', color.toHexString());
        this.elementRef.nativeElement.style.setProperty('--header-text-color', color.isLight() ? 'black' : 'white');
    }

    changeColor() {
        this.matDialog.open<ColorpickerDialogComponent, ColorpickerDialogData, TinyColor>(ColorpickerDialogComponent, {
            data: {
                color: this.widget().color(),
            },
        }).afterClosed().subscribe((color) => {
            if (!(color instanceof TinyColor)) {
                return;
            }

            this.widgetService.update(this.current.getUser().id, this.widget(), { color: color.toHexString() }).subscribe(((widget) => {
                this.widget().color.set(widget.color());
            }));
        });
    }

    delete() {
        this.deleting.set(true);
        this.widgetService.delete(this.current.getUser().id, this.widget()).subscribe(() => {
            this.componentHost()?.clear();
            this.deleted.emit();
        });
    }
}
