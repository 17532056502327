<eaw-dialog-header>
    @if (data.block) {
        <span title>{{'UPDATE_TIMEPUNCH_BLOCK' | translate:'company' | async}}</span>
    } @else {
        <span title>{{'CREATE_TIMEPUNCH_BLOCK' | translate:'company' | async}}</span>
    }
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" class="tw-grid tw-gap-16 tw-grid-cols-2">
        <mat-form-field eawDatePickerOptions class="tw-col-span-full sm:tw-col-span-1">
            <mat-label>{{'FROM' | translate | async}}</mat-label>
            <input matInput formControlName="from" [matDatepicker]="fromPicker">
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field eawDatePickerOptions class="tw-col-span-full sm:tw-col-span-1">
            <mat-label>{{'TO' | translate | async}}</mat-label>
            <input matInput formControlName="to" [matDatepicker]="toPicker">
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="tw-col-span-full" subscriptSizing="dynamic">
            <mat-label>{{'MESSAGE' | translate | async}}</mat-label>
            <textarea matInput
                      cdkAutosizeMinRows="4"
                      cdkTextareaAutosize
                      formControlName="reason">
            </textarea>
            <mat-hint>{{'TP_BLOCK_MESSAGE_HINT' | translate:'company' | async}}</mat-hint>
        </mat-form-field>

        <div class="tw-col-span-full">
            <mat-slide-toggle formControlName="hideReason">{{'TP_BLOCK_HIDE_MESSAGE' | translate:'company' | async}}</mat-slide-toggle>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>

    @if (data.block) {
        <eaw-action-button [loading]="processing" (click)="update()" [disabled]="form.disabled || form.invalid">
            {{'UPDATE' | translate | async}}
        </eaw-action-button>
    } @else {
        <eaw-action-button [loading]="processing" (click)="create()" [disabled]="form.disabled || form.invalid">
            {{'CREATE' | translate | async}}
        </eaw-action-button>
    }
</mat-dialog-actions>
