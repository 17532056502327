import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnDestroy, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { DateTime } from 'luxon';
import { timeStringToHourMin, uniqueId } from '../../../angularjs/modules/misc/services/easy-funcs.service';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatCalendarCellClassFunction, MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { Mobile } from '../../../utils/eaw-mobile';
import { DateTimePipe } from '../../../pipes/date-time.pipe';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'eaw-date-time-input',
    templateUrl: './date-time-input.component.html',
    styleUrl: './date-time-input.component.scss',
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: DateTimeInputComponent,
        },
    ],
    standalone: true,
    imports: [
        MatDatepickerModule,
        ReactiveFormsModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class DateTimeInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    @ViewChild('dateInput') dateInput?: ElementRef<HTMLInputElement>;
    @ViewChild('timeInput') timeInput?: ElementRef<HTMLInputElement>;
    @ViewChild(MatDatepicker) datepicker?: MatDatepicker<DateTime>;

    @HostBinding() id = `date-time-input-${uniqueId()}`;

    @Output() readonly dateTimeChange = new EventEmitter<DateTime | null>();

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input(`aria-describedby`) userAriaDescribedBy = '';

    @Input() dateClass: MatCalendarCellClassFunction<DateTime> = () => '';
    @Input() minDate?: DateTime;
    @Input() maxDate?: DateTime;
    /**
     * This input uses only the hour and minute parts of the DateTime
     * and sets the time on the date part of the value unless another time is set
     * explicitly by the user.
     */
    @Input() defaultTime: DateTime = DateTime.now().startOf('day');

    @Input()
    get value() {
        return this._value;
    }

    set value(value: DateTime | null) {
        if (value instanceof DateTime) {
            this.setTimeControlValue(value);
            this.setDateControlValue(value);
        } else {
            this.setTimeControlValue(null);
            this.setDateControlValue(null);
        }

        this._value = value;
        this.stateChanges.next();
    }

    // This is the combined value of the date and time parts
    private _value: DateTime | null = null;

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    private _placeholder = '';

    @Input()
    get required() {
        return this._required;
    }

    set required(req: BooleanInput) {
        this._required = coerceBooleanProperty(req);

        if (this._required) {
            this.timeControl.addValidators(Validators.required);
            this.dateControl.addValidators(Validators.required);
        } else {
            this.timeControl.removeValidators(Validators.required);
            this.dateControl.removeValidators(Validators.required);
        }

        this.stateChanges.next();
    }

    private _required = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        if (this._disabled) {
            this.timeControl.disable({
                emitEvent: false,
                onlySelf: true,
            });
            this.dateControl.disable({
                emitEvent: false,
                onlySelf: true,
            });
        } else {
            this.timeControl.enable({
                emitEvent: false,
                onlySelf: true,
            });
            this.dateControl.enable({
                emitEvent: false,
                onlySelf: true,
            });
        }

        this.stateChanges.next();
    }

    private _disabled = false;

    // A flag to indicate of the user is currently editing the date input and therefore should not
    // trigger the opening of the datepicker
    private _dateInputEditing = false;
    private onChange = (_: DateTime | null) => {};
    private onTouched = () => {};
    readonly localeStringFormat = DateTime.TIME_24_SIMPLE;
    readonly stateChanges = new Subject<void>();
    readonly dateControl = new FormControl<DateTime | null>(null);
    readonly timeControl = new FormControl<string | null>(null);
    readonly useTouchUi = Mobile.isTouchDevice;
    focused = false;
    touched = false;

    constructor(
        @Optional() @Self() public ngControl: NgControl | null = null,
        @Optional() public parentFormField: MatFormField | null,
        @Inject(ElementRef) protected elementRef: ElementRef,
    ) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    ngAfterViewInit() {
        this.datepicker?.openedStream.subscribe(this.onDatepickerOpen.bind(this));
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    get shouldLabelFloat() {
        return this.focused || this.datepicker?.opened || !this.empty;
    }

    get empty() {
        return !(this.timeControl.value || this.dateControl.value);
    }

    get errorState(): boolean {
        return this.touched && (this.ngControl?.control?.invalid || this.dateControl.invalid);
    }

    private onDatepickerOpen() {
        // The overlay backdrop we want os the one that contains datepicker
        const backdrop = document.querySelector('[class*="mat-datepicker"].cdk-overlay-backdrop');
        if (!backdrop) {
            return;
        }

        backdrop.addEventListener('click', (e) => {
            const event = e as PointerEvent;

            // Find all elements under the point where the user clicked
            const elements = document.elementsFromPoint(event.x, event.y);

            // If elements contains the native element (this component) then the user clicked inside the component, and we'll focus the date input
            if (elements.find((e) => e === this.elementRef.nativeElement)) {
                this.dateInput?.nativeElement.focus();
                this._dateInputEditing = true;
            } else {
                this.focused = false;
                this.stateChanges.next();
            }
        }, { once: true });
    }

    // This happens after the user clicks a date in the datepicker
    dateChange() {
        this.setDateTime();
        this.timeInput?.nativeElement?.select();
    }

    setDescribedByIds(ids: string[]) {
        const controlElement = this.elementRef.nativeElement.querySelector('.date-time-input') as HTMLElement | undefined;
        controlElement?.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
        this.focused = true;
        this.stateChanges.next();

        if (!(event.target instanceof HTMLInputElement)) {
            this.datepicker?.open();
        }
    }

    dateInputClick() {
        if (this._dateInputEditing) {
            return;
        }
        this.datepicker?.open();
    }

    focusTime() {
        this.timeInput?.nativeElement?.select();
    }

    onFocusOut(event: FocusEvent) {
        if (this.datepicker?.opened) {
            return;
        }

        if (!this.elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.focused = false;
            this.markAsTouched();
        }
    }

    markAsTouched() {
        this.touched = true;
        this.onTouched();
        this.stateChanges.next();
    }

    private getTime(): DateTime {
        const hourMin = timeStringToHourMin(this.timeControl.value);
        if (!hourMin) {
            return this.defaultTime;
        }

        const time = DateTime.fromObject(hourMin);
        this.setTimeControlValue(time);
        return time;
    }

    private setTimeControlValue(value: DateTime | null) {
        if (value instanceof DateTime) {
            this.timeControl.setValue(value.toLocaleString(this.localeStringFormat), {
                emitEvent: false,
                onlySelf: true,
            });
        }
    }

    private setDateControlValue(value: DateTime | null) {
        this.dateControl.setValue(value, {
            emitEvent: false,
            onlySelf: true,
        });
    }

    setDateTime() {
        this._dateInputEditing = false;

        const date = this.dateControl.value;
        const time = this.getTime();

        this.value = date ? date.set({
            hour: time.hour,
            minute: time.minute,
        }) : null;

        this.onChange(this.value);
        this.dateTimeChange.emit(this.value);
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: unknown): void {
        this.value = obj instanceof DateTime ? obj : null;
        this.setDateTime();
    }
}
