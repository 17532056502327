import { t } from 'i18next';
import { debounce } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
import { WebsocketService } from '../../../../shared/services/websocket.service';
import { AngularJsDestroyRef } from '../../../../shared/angularjs/angularjs-destroy-ref.class';
module('eaw.dashboard').component('miniWorkingEmployeesWidget', {
    template: `<h2 ng-if="$widget.missingText" ng-bind="$widget.missingText"></h2>
<h2 ng-if="!$widget.missingText" ng-i18next="NA"></h2>
<span ng-if="!$widget.location" ng-i18next="widgets:UNAVAILABLE_LOCATION"></span>
<span ng-if="$widget.location">
    <eaw-time time="$widget.from"></eaw-time>
</span>
<small ng-if="$widget.location" ng-bind="$widget.location"></small>
`,
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$widget',
    controller: [ 'Shift', '$element', 'TimepunchFactory', 'WebsocketDowngrade', 'EawResource', function(Shift, $element, TimepunchFactory, WebsocketDowngrade: WebsocketService, EawResource) {
        // @ts-ignore
        const ctrl = this;
        const destroyRef = new AngularJsDestroyRef();

        ctrl.$onInit = async () => {
            ctrl.from = moment();
            ctrl.mode = ctrl.mini.widget.settings?.location || 'current';
            ctrl.groupId = ctrl.mode === 'group' ? ctrl.mini.widget.settings?.group : undefined;

            if (ctrl.groupId) {
                const customer = await EawResource.create(`/customers/${CurrentOld.customer['id']}`).get({
                    'with[]': 'customer_groups',
                }).$promise;

                ctrl.location = customer.customer_groups.find((g: any) => g.id === ctrl.groupId)?.name;
            } else {
                ctrl.location = CurrentOld.getCustomer().name;
            }

            if (!ctrl.location) {
                setTimeout(() => {
                    $element[0].classList.remove('display-none');
                    ctrl.mini.loading(false);
                }, 100);
                return;
            }
            ctrl.mini.dialogComponents = [ {
                name: 'workingEmployeeList',
                label: 'EMPLOYEE_plural',
            } ];
            // Listen for punches and update when it happens instead of waiting
            // the whole interval before doing an update
            WebsocketDowngrade.listenCustomerTimepunches(ctrl.mini.widget.customer, 'punched', debounce(ctrl.getWorking, 1000), destroyRef);
            // Get initially
            ctrl.getWorking();
        };
        ctrl.$onDestroy = () => {
            destroyRef.destroy();
            ctrl.shiftRes?.$cancelRequest?.();
            ctrl.tpRes?.$cancelRequest?.();
        };
        ctrl.getWorking = async () => {
            ctrl.shiftRes?.$cancelRequest?.();
            ctrl.tpRes?.$cancelRequest?.();
            const from = moment();
            const args = {
                customer_id: ctrl.mini.widget.customer,
                group_id: ctrl.groupId,
                per_page: 999,
                with: [ 'employee' ],
            };
            ctrl.shiftRes = Shift.getAll({
                from: from.clone(),
                to: from.clone().add(1, 's'),
                vacant: false,
                ...args,
            });
            ctrl.tpRes = TimepunchFactory.getAll({
                from: from.clone().subtract(1, 'd'),
                to: from.clone().add(1, 'd'),
                ...args,
            });
            const [ shiftResp, tpResp ] = await Promise.all([
                ctrl.shiftRes.$promise,
                ctrl.tpRes.$promise,
            ]).catch(() => {
                ctrl.mini.loading(false);
                return [];
            });
            if (!shiftResp) {
                return;
            }
            if (!tpResp) {
                return;
            }
            // Sort so that the ones ending the soonest are first
            const currentShifts = shiftResp.data.sort((a: any, b: any) => a.to - b.to);
            // Remove those who have punched out
            const timepunches = tpResp.data.filter((tp: any) => !tp.out);
            const employees: any = {};
            timepunches.forEach((tp: any) => {
                if (tp.employee_id) {
                    employees[tp.employee_id] = tp.employee;
                }
            });
            currentShifts.forEach((s: any) => {
                if (s.employee_id) {
                    employees[s.employee_id] = s.employee;
                }
            });
            const mappedEmployees = Object.values(employees).map((e: any) => {
                e.working = currentShifts.find((s: any) => s.employee_id === e.id) && timepunches.find((tp: any) => tp.employee_id === e.id);
                e.hangingOut = !currentShifts.find((s: any) => s.employee_id === e.id) && timepunches.find((tp: any) => tp.employee_id === e.id);
                if (e.working) {
                    e.status = 2;
                } else if (e.hangingOut) {
                    e.status = 1;
                } else {
                    e.status = 0;
                }
                return e;
            });
            ctrl.missingText = t('widgets:X_OF_Y', {
                x: mappedEmployees.filter((e) => e.working).length,
                y: currentShifts.length,
            });
            if (mappedEmployees.filter((e) => e.hangingOut).length) {
                ctrl.missingText = t('widgets:X_OF_Y_AND_Z', {
                    x: mappedEmployees.filter((e) => e.working).length,
                    y: currentShifts.length,
                    z: mappedEmployees.filter((e) => e.hangingOut).length,
                });
            }
            ctrl.mini.loading(false);
            ctrl.mini.setDialogComponentBindings('workingEmployeeList', {
                employees: sort(mappedEmployees, CurrentOld.languageTag, [ (e) => e.status, (e) => e.name ]),
            });
        };
    } ],
});
