// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import moment from 'moment-timezone';
import { CurrentOld } from '../../shared/angularjs/current.factory';
const couponTemplate = `<eaw-coupon-dialog class="show-coupon" close-button-click="coupon.close()">
    <section class="coupon-image">
        <img class="image" alt="coupon image" ng-src="{{coupon.src}}">
    </section>
    <div class="coupon-icon">
        <img ng-src="{{coupon.icon}}" alt="Icon">
    </div>
    <section class="middle">
        <span class="coupon-value" ng-bind="coupon.coupon.value"></span>
        <span class="coupon-unit" ng-bind="coupon.coupon.unit"></span>
        <div class="terms-text hover-item" ng-click="coupon.showTerms()">
            <md-icon ng-bind="'info'"></md-icon>
            <small ng-i18next="coupons:RULE_LINK_TEXT"></small>
        </div>
    </section>
    <section class="stub">
        <div class="use-element">
            <div class="used-text used" ng-show="coupon.coupon.used && !coupon.using" ng-i18next="coupons:USED"></div>
            <div class="used-text used" ng-show="!coupon.coupon.used && coupon.coupon.isExpired()" ng-i18next="coupons:EXPIRED"></div>
            <eaw-use-coupon-button ng-disabled="coupon.coupon.used || coupon.using"
                                   ng-hide="coupon.coupon.used || !coupon.coupon.isAvailable() || coupon.coupon.isExpired()"
                                   on-swiped="coupon.use()">
            </eaw-use-coupon-button>
        </div>
        <div class="coupon-info-text" ng-class="{'used': coupon.coupon.used, 'error': coupon.error}" ng-bind="coupon.text"></div>
    </section>
</eaw-coupon-dialog>
`;
const termsTemplate = `<eaw-coupon-dialog class="show-coupon show-coupon terms">
    <div class="terms-body pretty-scroll">
        <h1 class="rules-header" ng-i18next="coupons:TERMS_HEADER"></h1>
        <p ng-bind="terms.text"></p>
    </div>
    <div class="terms-stub">
        <md-button class="md-raised" ng-i18next="OK" ng-click="terms.close()"></md-button>
    </div>
</eaw-coupon-dialog>
`;
module('eaw.coupons').factory('showCoupon', [ 'CouponFactory', '$mdPanel', function(CouponFactory, $mdPanel) {
    function showTerms(couponType, couponRef) {
        $mdPanel.open({
            id: 'coupon-terms.' + couponType.id,
            template: termsTemplate,
            controllerAs: 'terms',
            fullscreen: true,
            hasBackdrop: true,
            disableParentScroll: true,
            bindToController: true,
            escapeToClose: true,
            panelClass: 'coupon-panel',
            locals: {
                text: couponType.terms.getText(CurrentOld.user.language_code),
            },
            controller: function() {
                this.$onInit = () => {
                    requestAnimationFrame(() => couponRef.panelContainer.addClass('invisible'));
                    couponRef.hide();
                };
                this.close = () => {
                    couponRef.show().then(() => couponRef.panelContainer.removeClass('invisible'));
                    this.mdPanelRef.close();
                };
            },
        });
    }
    return function showCoupon(coupon) {
        const pos = {
            top: 1000,
            left: 0,
        };
        const panelAnimation = $mdPanel.newPanelAnimation()
            .withAnimation($mdPanel.animation.SLIDE)
            .duration(200)
            .openFrom(pos);
        return new Promise((resolve, reject) => {
            $mdPanel.open({
                id: 'coupon.' + coupon.id,
                animation: panelAnimation,
                template: couponTemplate,
                controllerAs: 'coupon',
                fullscreen: true,
                hasBackdrop: true,
                disableParentScroll: true,
                bindToController: true,
                escapeToClose: true,
                panelClass: 'coupon-panel',
                locals: {
                    coupon,
                },
                controller: function() {
                    this.$onInit = () => {
                        this.src = this.coupon.image;
                        this.icon = this.coupon.icon;
                        this.value = `${this.coupon.value}${this.coupon.unit}`;
                        this.setText();
                    };
                    this.use = async () => {
                        this.using = true;
                        this.error = false;
                        try {
                            await CouponFactory.useCoupon(coupon).$promise;
                            this.coupon.used_at = moment();
                            this.setText();
                            resolve('use');
                        } catch (_) {
                            this.error = true;
                            this.setText(e.data.error);
                        } finally {
                            this.using = false;
                        }
                    };
                    this.close = () => {
                        this.mdPanelRef.close();
                        reject('close');
                    };
                    this.showTerms = () => {
                        showTerms(this.coupon.type, this.mdPanelRef);
                    };
                    this.setText = (text = '') => {
                        if (text) {
                            this.text = text;
                        } else {
                            const status = this.coupon.status;
                            this.text = t(status.text, { date: status.date.format('lll') });
                        }
                    };
                },
            });
        });
    };
} ]);
