import { ApiResponse } from '../../shared/interfaces/api-response';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
export interface CommentResponse extends ApiResponse {
    date?: string;
    day?: string;
    comment: string;
    customer?: number;
    createdAt?: string;
    updatedAt?: string;
}

export class Comment {
    comment: string;
    customerId?: number;
    date?: DateTime;

    constructor(data: CommentResponse) {
        this.comment = data.comment;
        if (data.customer) {
            this.customerId = data.customer;
        }
        const date = data.date || data.day;
        if (date) {
            this.date = stringToDateTime(date, true,'yyyy-MM-dd');
        }
    }
}
