import { module } from 'angular';
import { Namespace } from '../../shared/enums/namespace';
import { Products } from '../../shared/enums/products';
import { AbsenceListComponent } from '../pages/list/absence-list/absence-list.component';
import { createState } from '../../shared/utils/create-state';
import { dependencyResolver } from '../../shared/resolvers/dependency.resolver';
import { AbsenceStatisticsComponent } from '../pages/absence-statistics/absence-statistics.component';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { RegisterAbsenceComponent } from '../pages/register/register-absence.component';
import { sort } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { EmployeeAbsencesComponent } from '../pages/employee-absences/employee-absences.component';
import { CurrentOld } from '../../shared/angularjs/current.factory';
import { LearningModuleModule } from '../../learning-module/types/modules';
import { DataTableQueryParams } from '../../data-table/data-table.component';

module('eaw.absence', [
    'eaw.resource',
    'eaw.alerts',
    'eaw.login',
    'eaw.company',
    'eaw.vacation',
    'eaw.leave-shifts',
]).config([ '$stateProvider', function($stateProvider: any) {
    createState($stateProvider, {
        name: 'eaw/app/absence',
        parent: 'eaw/app',
        url: '/absence',
        abstract: true,
        data: {
            learningModuleModules: [ LearningModuleModule.Absence ],
            breadcrumb: { key: 'ABSENCE', ns: 'navigation' },
            products: [ Products.Absence ],
            i18nextNs: [
                Namespace.Absences,
                Namespace.Vacation,
                Namespace.AbsenceTypes,
                Namespace.Company,
            ],
        },
    });
    createState($stateProvider, {
        name: 'eaw/app/absence/register',
        parent: 'eaw/app/absence',
        url: '/register',
        views: {
            'content@': { component: RegisterAbsenceComponent },
        },
        resolve: [
            dependencyResolver('employee', [ 'employee', 'EmployeeService' ], function(employee, EmployeeService) {
                return employee?.id ? EmployeeService.get({ id: employee.customer_id }, employee.id) : null;
            }),
            dependencyResolver('absenceTypes', [ 'AbsenceTypeFactory', 'customer', '$filter' ], function(AbsenceTypeFactory, customer, $filter) {
                return AbsenceTypeFactory.getAll({
                    can_create: true,
                    entityId: customer.id,
                    per_page: 100,
                }).then((resp: any) => {
                    const types = resp.data.map((types: any) => {
                        return {
                            i18n: $filter('eawTranslate')(types.name, 'absence_types'),
                            ...types,
                        };
                    });
                    return sort(types, CurrentOld.languageTag, [ (t: any) => t.i18n ], [ 'asc' ]);
                });
            }),
        ],
        data: {
            breadcrumb: { key: 'REGISTER_ABSENCE', ns: 'navigation' },
            somePermissions: [
                `customers.{customer}.employees.{employee}.absences.create`,
                `customers.{customer}.absences.manage`,
            ],
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/absence/list',
        parent: 'eaw/app/absence',
        url: '/list',
        views: {
            'content@': {
                component: AbsenceListComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('userId', 'user', 'id'),
        ],
        data: {
            queryParams: [ ...DataTableQueryParams, 'mode', 'from', 'to', 'types', 'employee' ],
            breadcrumb: { key: 'LIST' },
            somePermissions: [
                'customers.{customer}.employees.*.absences.*.get',
                `customers.{customer}.absences.manage`,
            ],
        },
    });

    createState($stateProvider, {
        name: 'eaw/app/absence/my',
        parent: 'eaw/app/absence',
        url: '/my',
        views: {
            'content@': {
                component: EmployeeAbsencesComponent,
            },
        },
        resolve: [
            currentResolver('employeeId', 'employee', 'id'),
            currentResolver('customerId', 'employee', 'customerId'),
        ],
        data: {
            breadcrumb: { key: 'MY_ABSENCES', ns: 'navigation' },
            requiresEmployee: true,
            allowExternal: true,
            permissions: [`customers.{employee.customer_id}.employees.{employee}.absences.*.get`],
        },
    });

    $stateProvider.state('eaw/app/absence/calendar', {
        parent: 'eaw/app/absence',
        url: '/calendar',
        views: {
            'content@': 'eawAbsenceCalendar',
        },
        data: {
            breadcrumb: { key: 'ABSENCE_CALENDAR', ns: 'navigation' },
            i18nextNs: [ Namespace.Calendar ],
            products: [ Products.AbsenceCalendar ],
            permissions: [ `customers.{customer}.employees.*.absences.*.get` ],
        },
    });
    createState($stateProvider, {
        name: 'eaw/app/absence/stats',
        parent: 'eaw/app/absence',
        url: '/statistics',
        views: {
            'content@': {
                component: AbsenceStatisticsComponent,
            },
        },
        data: {
            breadcrumb: { key: 'STATISTICS', ns: 'navigation' },
            permissions: [
                `setting_groups.{customer.setting_group_id}.absence_types.*.get`,
                `customers.{customer}.employees.*.absences.*.get`,
            ],
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('employee', 'employee', 'id'),
        ],
    });
} ]);
