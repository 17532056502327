import { AngularJsScheduleViewSchedule } from './angularjs/schedules/view/schedule.component';
import { signal } from '@angular/core';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { AngularJsShiftEventType } from './angularjs/shifts/shift-events.service';

/**
 * Contains signals and such that originate from AngularJS files.
 *
 * In the future, when relevant AngularJS files are converted to Angular, these signals can be moved and live in the same Angular files as they originate from.
 */

/**
 * Signal containing the schedule we are on
 */
export const scheduleViewSchedule = signal<AngularJsScheduleViewSchedule>({
    id: 0,
    customerId: 0,
    from: DateTime.invalid('Uninitialized'),
    to: DateTime.invalid('Uninitialized'),
    isTemplate: false,
});

/**
 * Signal containing the item we are using for drag and drop, if any.
 */
export const scheduleViewDragAndDrop = signal<{ id: number, data?: any, type: 'employee' | 'unit' } | undefined>(undefined);

/**
 * Signal containing the currently selected day for the day selector at the top of the schedule sidebar
 */
export const scheduleDaySelectorDate = signal<DateTime | undefined>(undefined);

/**
 * Used to react to shift events on the schedule
 */
export const scheduleViewShiftEventSubject = new Subject<{ event: AngularJsShiftEventType, data: unknown }>();

