// @ts-nocheck
import { module } from 'angular';
module('eaw.misc').factory('ProductService', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const url = '/products/:product';
    /**
     * @param {object}  args
     * @returns {*}
     */
    fac.getAll = (args) => EawResource.create(url).get({
        ...Pagination.getParams(args),
    });
    /**
     * @param {object}  args
     * @param {int}     args.customerId
     * @returns {*}
     */
    fac.getAllForCustomer = (args) => EawResource.create('/customers/:customer/products').get({
        customer: args.customerId,
        ...Pagination.getParams(args),
    });
    /**
     * @param {object}  args
     * @param {int}     args.customerGroupId
     * @returns {*}
     */
    fac.getAllForCustomerGroup = (args) => EawResource.create(url).get({
        customer_group_id: args.customerGroupId,
        ...Pagination.getParams(args),
    });
    /**
     * @param {string}      name
     * @param {string[]}    [withs]
     * @returns {*}
     */
    fac.getByName = (name, withs) => EawResource.create(url).get({
        product: name,
        'with[]': withs,
    });
    /**
     * @param {string}      name
     * @param {string[]}    [withs]
     * @param {Object|Pagination} pagination
     * @returns {*}
     */
    fac.getSubscribers = (name, withs, pagination) => EawResource.create(`/products/${name}/subscribers`).get({
        ...Pagination.getParams(pagination),
        'with[]': withs,
    }).$promise;
    return fac;
} ]);
