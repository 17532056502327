// @ts-nocheck
import { module } from 'angular';
module('eaw.ai-budgeting').factory('mlForecastLocksFactory', [ 'EawResource', function mlForecastLocksFactory(EawResource) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/ml_buckets/:bucket/locks');
    fac.get = {
        permission(customer, bucket) {
            return `customers.${customer}.ml_buckets.${bucket}.locks.*.get`;
        },
        query(customer, bucket) {
            return res.get({
                customer,
                bucket,
            }).$promise;
        },
    };
    fac.set = {
        permission(customer, bucket) {
            return `customers.${customer}.ml_buckets.${bucket}.locks.set`;
        },
        query(customer, bucket, date) {
            return res.save({
                customer,
                bucket,
            }, {
                date,
            }).$promise;
        },
    };
    fac.delete = {
        permission(customer, bucket) {
            return `customers.${customer}.ml_buckets.${bucket}.locks.set`;
        },
        /**
     * @param {Number} customer
     * @param {String} bucket
     * @returns {Promise}
     */
        del(customer, bucket) {
            return res.delete({
                customer,
                bucket,
            }).$promise;
        },
    };
    return fac;
} ]);
