<div>{{ number() }}</div>

@if (cell.column.comparer) {
    @switch (comparison()) {
        @case(true) {
            <mat-icon class="positive">arrow_drop_up</mat-icon>
        }
        @case(false) {
            <mat-icon class="negative">arrow_drop_down</mat-icon>
        }
        @default {
            <mat-icon></mat-icon>
        }
    }
}
