import { DataTableIconColumn } from './data-table-icon-column';
import { EawMaterialColorHue } from '../../shared/services/material-color.service';
import { DataTableHeader } from './data-table-header';
type IconSpec = { color: EawMaterialColorHue, icon: string };

export interface IDataTableBooleanColumn<Item> {
    header: DataTableHeader;
    boolean: keyof Item;
    error?: IconSpec;
    success?: IconSpec;
}

export class DataTableBooleanColumn<Item> extends DataTableIconColumn<Item> {
    protected error: IconSpec;
    protected success: IconSpec;

    constructor(column: IDataTableBooleanColumn<Item>) {
        super({
            ...column,
            icon: (cell) => cell.item[column.boolean] ? this.success.icon : this.error.icon,
            color: (cell) => cell.item[column.boolean] ? this.success.color : this.error.color,
        });

        this.error = column.error || { color: 'red-500', icon: 'close' };
        this.success = column.success || { color: 'green-500', icon: 'check' };
    }
}
