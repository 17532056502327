// @ts-nocheck
import { module } from 'angular';
module('eaw.admin').factory('PermissionsetsUser', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const res = EawResource.create('/users/:user/permission_sets/:set');
    fac.getAll = {
        /**
         * @param {int} userId
         * @returns {string}
         */
        permission(userId) {
            return `users.${userId}.permission_sets.*.get`;
        },
        /**
         * @param {object} args
         * @param {int} args.userId
         * @returns {*}
         */
        query(args) {
            return res.get({
                user: args.userId,
                ...Pagination.getParams(args),
            });
        },
    };
    fac.attach = {
        /**
         * @param {int} userId
         * @returns {string}
         */
        permission(userId) {
            return `users.${userId}.permission_sets.create`;
        },
        /**
         * @param {int} userId
         * @param {int} setId
         * @returns {*}
         */
        query(userId, setId) {
            return res.save({
                user: userId,
            }, {
                set_id: setId,
            });
        },
    };
    fac.detach = {
        /**
         * @param {int} userId
         * @param {int} setId
         * @returns {string}
         */
        permission(userId, setId) {
            return `users.${userId}.permission_sets.${setId}.delete`;
        },
        /**
         * @param {int} userId
         * @param {int} setId
         * @returns {*}
         */
        query(userId, setId) {
            return res.delete({
                user: userId,
                set: setId,
            });
        },
    };
    return fac;
} ]);
