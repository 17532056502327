import { ApiResponse } from '../../shared/interfaces/api-response';
import { UserResponse } from '../../shared/models/user';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { DateTime } from 'luxon';

export interface MessageRecipient extends ApiResponse {
    confirmed_at: string;
    created_at: string;
    deleted_at: string;
    id: number;
    message_id: number;
    read_at: string;
    updated_at: string;
    user_id: number;
}

export interface MessageResponse extends ApiResponse {
    body: string;
    created_at: string;
    id: number;
    is_blind: boolean;
    is_editable: boolean;
    message_recipients: MessageRecipient[];
    pivot: { user_id: number, message_id: number, read_at: string, confirmed_at: string };
    recipients_count: number;
    subject: string;
    thread_id: string;
    updated_at: string;
    user: UserResponse;
    user_id: number;
}

export class Message {
    id: number;
    body: string;
    createdAt: DateTime;
    updatedAt: DateTime;

    constructor(data: MessageResponse) {
        this.id = data.id;
        this.body = data.body;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        /* @TODO to expand */
    }
}
