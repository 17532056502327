import moment, { Moment } from 'moment-timezone';
import { DateTime } from 'luxon';

/**
 * Convert a moment to a DateTime
 * @param {moment.Moment | null | undefined} m
 * @returns {DateTime | null}
 * @deprecated To be removed when all code is migrated to Luxon
 */
export function momentToDateTime(m: Moment | null | undefined) {
    const invalid = DateTime.invalid('Invalid conversion from moment');

    if (!moment.isMoment(m)) {
        return invalid;
    }

    if (moment.isMoment(m) && !m.isValid()) {
        return invalid;
    }

    const dt = DateTime.fromObject({
        year: m.year(),
        month: m.month() + 1,
        day: m.date(),
        hour: m.hour(),
        minute: m.minute(),
        second: m.second(),
    }, {
        zone: m.tz(),
    });

    return dt.isValid ? dt : invalid;
}
