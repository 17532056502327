import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, EMPTY, of, tap } from 'rxjs';
import { DataTableComponent } from 'src/app/data-table/data-table.component';
import { DataTableCell } from 'src/app/data-table/interfaces/data-table-cell';
import { DataTableColumnType } from 'src/app/data-table/interfaces/data-table-columns';
import { DataTablePagination, DataTableRequest } from 'src/app/data-table/types/data-table';
import { DataTableButtonCell, DataTableButtonColumn } from 'src/app/data-table/types/data-table-button-column';
import { DataTableHeader } from 'src/app/data-table/types/data-table-header';
import { DataTableNumberColumn } from 'src/app/data-table/types/data-table-number-column';
import { DataTableTextColumn } from 'src/app/data-table/types/data-table-text-column';
import { HeaderFabButton } from 'src/app/shared/components/page-header/page-header.component';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.service';
import { DialogSize } from 'src/app/shared/dialogs/dialog-component';
import { LanguageService } from 'src/app/admin/http/language.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { LanguageDialogComponent, LanguageDialogData } from '../../dialogs/language-dialog/language-dialog.component';
import { Language } from '../../models/language';
import { Namespace } from '../../../shared/enums/namespace';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { DataTableComponent as DataTableComponent_1 } from '../../../data-table/data-table.component';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-language-list',
    templateUrl: './language-list.component.html',
    styleUrl: './language-list.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent_1,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class LanguageListComponent {
    @ViewChild('table') table?: DataTableComponent<Language>;

    request?: DataTableRequest = of(mockArrayPaginatedResponse());

    columns: DataTableColumnType<Language>[] = [
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableTextColumn({
            value: 'code',
            header: new DataTableHeader({
                i18n: 'CODE',
                sortBy: 'code',
            }),
        }),
        new DataTableTextColumn({
            value: 'languageTag',
            header: new DataTableHeader({
                i18n: 'BCP_47',
                ns: Namespace.Admin,
                sortBy: 'ietf_bcp47_tag',
            }),
        }),
        new DataTableNumberColumn({
            value: 'usersCount',
            header: new DataTableHeader({ i18n: 'USER_plural', sortBy: 'users_count' }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    click: this.openDialog.bind(this),
                    icon: 'edit',
                    tooltip: { key: 'EDIT' },
                    show: () => of(true),
                },
                {
                    click: this.delete.bind(this),
                    icon: 'delete',
                    tooltip: { key: 'DELETE' },
                    hide: (item) => of(!!item.usersCount),
                    type: 'warn',
                    show: (item) => of(item?.usersCount === 0),
                },
            ],
        }),
    ];

    fabButton: HeaderFabButton = {
        click: this.openDialog.bind(this),
        hasPermission: () => of(true),
    };

    constructor(
        @Inject(LanguageService) private languageService: LanguageService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(MatDialog) private matDialog: MatDialog,
    ) {
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.languageService.getAll(pagination);
    }

    protected openDialog(cell?: DataTableButtonCell<Language>) {
        this.matDialog.open<LanguageDialogComponent, LanguageDialogData, Language>(LanguageDialogComponent, {
            data: {
                size: DialogSize.Medium,
                code: cell?.item.code,
                name: cell?.item.name,
            },
        },
        ).afterClosed().subscribe((language) => {
            if (cell) {
                cell.disabled.set(false);
            }

            if (language) {
                this.table?.refresh();
            }
        });
    }

    delete(cell: DataTableCell<DataTableButtonColumn<Language>, Language>) {
        this.confirmDialogService.delete({
            title: this.translate.t('DELETE_LANGUAGE', Namespace.Admin),
            text: this.translate.t('DELETE_LANGUAGE_CONFIRM', Namespace.Admin, { name: cell.item.name }),
            confirmText: this.translate.t('DELETE_LANGUAGE', Namespace.Admin),
        }).afterClosed()
            .pipe(
                catchError((err) => {
                    console.error(err);
                    cell.disabled.set(false);
                    console.error(err);
                    return EMPTY;
                }),
                tap((result) => {
                    if (!result?.ok) {
                        cell.disabled.set(false);
                        return;
                    }

                    this.languageService.delete(cell.item.code).subscribe(() => {
                        this.table?.refresh();
                    });
                }),
            ).subscribe();
    }
}
