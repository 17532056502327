<eaw-page-header [buttons]="headerButtons">
    <span title>{{ 'CREATE_NEW_EMP' | translate:'new_employee' | async }}</span>
</eaw-page-header>

@if (loading) {
    <mat-card class="tw-flex tw-flex-col tw-gap-16 tw-items-center tw-justify-center tw-p-[50px]">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <span class="mat-subtitle-1">{{ 'PREPARING_CREATE' | translate:'new_employee' | async }}</span>
    </mat-card>
} @else if (error) {
        <eaw-info-box color="red">
            <span infoText>{{'SOMETHING_WENT_WRONG' | translate:'errors' | async}}</span>
        </eaw-info-box>
} @else {
    <mat-card>
        <mat-stepper orientation="vertical" [linear]="true" #stepper>
            @if (createUser) {
                <mat-step #userStep>
                    <ng-template matStepLabel>{{ 'CHECK_EXISTING_APP_ACCESS' | translate:'new_employee' | async }}</ng-template>

                    <ng-template matStepContent>
                        <eaw-new-employee-user-check [stepper]="stepper" [step]="userStep" (userChange)="user = $event; foundExistingUser = !!$event" (emailChange)="email = $event"/>
                    </ng-template>
                </mat-step>

                @if (!foundExistingUser) {
                    <mat-step #userCreateStep>
                        <ng-template matStepLabel>{{ 'ADD_APP_ACCESS' | translate:'new_employee' | async }}</ng-template>

                        <ng-template matStepContent>
                            <eaw-new-employee-user-create [requiredFields]="requiredFields" [email]="email" [customer]="customer" [stepper]="stepper" [step]="userCreateStep" (userChange)="user = $event"/>
                        </ng-template>
                    </mat-step>
                }

                <mat-step #locationAccessStep>
                    <ng-template matStepLabel>{{ 'ADD_LOCATION_ACCESS' | translate:'new_employee':{location: customer.name} | async }}</ng-template>

                    <ng-template matStepContent>
                        @if (user) {
                            <eaw-new-employee-location-access [customer]="customer" [user]="user" [existingUser]="foundExistingUser" [stepper]="stepper" [step]="locationAccessStep" (customerUserChange)="customerUser = $event"/>
                        } @else {
                            <span>{{ 'MISSING_USER' | translate | async }}</span>
                        }
                    </ng-template>
                </mat-step>

                <!-- Do not set as optional here, everything about optional and completed should be handled inside the component -->
                <mat-step #userGroupsStep [optional]="!requiredFields.usergroup">
                    <ng-template matStepLabel>{{ 'CHOOSE_GROUP_plural' | translate:'new_employee' | async }}</ng-template>

                    <ng-template matStepContent>
                        @if (user) {
                            <eaw-new-employee-user-groups [customer]="customer" [user]="user" [stepper]="stepper" [step]="userGroupsStep"/>
                        } @else {
                            <span>{{ 'MISSING_USER' | translate | async }}</span>
                        }
                    </ng-template>
                </mat-step>
            }

            <mat-step #employeeStep>
                <ng-template matStepLabel>{{ 'REQUIRED_EMP_INFO' | translate:'new_employee' | async }}</ng-template>

                <ng-template matStepContent>
                    <eaw-new-employee-create-employee [customer]="customer" [user]="user" [customerUser]="customerUser" [stepper]="stepper" [step]="employeeStep" (employeeChange)="employee = $event"/>
                </ng-template>
            </mat-step>

            <mat-step #additionalEmployeeStep [optional]="!additionalEmployeeInfoRequired">
                <ng-template matStepLabel>{{ 'ADDITIONAL_EMP_INFO_TITLE' | translate:'new_employee' | async }}</ng-template>

                <ng-template matStepContent>
                    @if (employee) {
                        <eaw-new-employee-extra-employee-info [requiredFields]="requiredFields" [customer]="customer" [user]="user" [employee]="employee" [stepper]="stepper" [step]="additionalEmployeeStep"/>
                    } @else {
                        <span>{{ 'MISSING_EMPLOYEE' | translate:'new_employee' | async }}</span>
                    }
                </ng-template>
            </mat-step>

            <mat-step #employeePayStep [optional]="!requiredFields.payrate">
                <ng-template matStepLabel>{{ 'ADD_PAY_INFO' | translate:'new_employee' | async }}</ng-template>

                <ng-template matStepContent>
                    @if (employee) {
                        <eaw-new-employee-pay [customer]="customer" [user]="user" [employee]="employee" [stepper]="stepper" [step]="employeePayStep"/>
                    } @else {
                        <span>{{ 'MISSING_EMPLOYEE' | translate:'new_employee' | async }}</span>
                    }
                </ng-template>
            </mat-step>

            <mat-step #employeeContractStep [optional]="!requiredFields.contract">
                <ng-template matStepLabel>{{ 'ADD_CONTRACT_INFO' | translate:'new_employee' | async }}</ng-template>

                <ng-template matStepContent>
                    @if (employee) {
                        <eaw-new-employee-contract [customer]="customer" [user]="user" [employee]="employee" [stepper]="stepper" [step]="employeeContractStep"/>
                    } @else {
                        <span>{{ 'MISSING_EMPLOYEE' | translate:'new_employee' | async }}</span>
                    }
                </ng-template>
            </mat-step>

            <mat-step #employeePositionsStep [optional]="!requiredFields.position">
                <ng-template matStepLabel>{{ 'ADD_POSITION_plural' | translate:'new_employee' | async }}</ng-template>

                <ng-template matStepContent>
                    @if (employee) {
                        <eaw-new-employee-positions [isRequired]="requiredFields.position" [customer]="customer" [employee]="employee" [stepper]="stepper" [step]="employeePositionsStep"/>
                    } @else {
                        <span>{{ 'MISSING_EMPLOYEE' | translate:'new_employee' | async }}</span>
                    }
                </ng-template>
            </mat-step>

            @if (customFields.length) {
                <mat-step #employeeCustomFieldsStep [optional]="!customFieldsRequired">
                    <ng-template matStepLabel>{{ 'ADD_CUSTOM_FIELD_plural' | translate:'new_employee' | async }}</ng-template>

                    <ng-template matStepContent>
                        @if (employee) {
                            <eaw-new-employee-custom-fields [customFields]="customFields" [customer]="customer" [employee]="employee" [stepper]="stepper" [step]="employeeCustomFieldsStep"/>
                        } @else {
                            <span>{{ 'MISSING_EMPLOYEE' | translate:'new_employee' | async }}</span>
                        }
                    </ng-template>
                </mat-step>
            }

            @if (hasHumanResources()) {
                <mat-step #employeeDefaultHrFiles [optional]="!requiredFields.default_hr_files">
                    <ng-template matStepLabel>{{ 'ADD_DEFAULT_HR_FILE_plural' | translate:'new_employee' | async }}</ng-template>

                    <ng-template matStepContent>
                        @if (employee) {
                            <eaw-new-employee-default-hr-files [isRequired]="requiredFields.default_hr_files" [customer]="customer" [employee]="employee" [stepper]="stepper" [step]="employeeDefaultHrFiles"/>
                        } @else {
                            <span>{{ 'MISSING_EMPLOYEE' | translate:'new_employee' | async }}</span>
                        }
                    </ng-template>
                </mat-step>
            }

            <mat-step #doneStep>
                <ng-template matStepLabel>{{'DONE_STEP' | translate:'new_employee' | async}}</ng-template>

                <ng-template matStepContent>
                    @if (employee) {
                        <eaw-new-employee-done [stepper]="stepper" [step]="doneStep" [employee]="employee"/>
                    } @else {
                        <span>{{ 'MISSING_EMPLOYEE' | translate:'new_employee' | async }}</span>
                    }
                </ng-template>
            </mat-step>
        </mat-stepper>
    </mat-card>
}
