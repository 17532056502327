require('../../../../node_modules/angular-animate/angular-animate.min');
require('../../../../node_modules/angular-material/angular-material.min');
require('../../../../node_modules/angular-resource/angular-resource.min');
require('../../../../node_modules/angular-messages/angular-messages.min');
require('../../../../node_modules/angular-sanitize/angular-sanitize.min');
require('../../../../node_modules/angular-aria/angular-aria.min');

require('../../../../div/ng-table');
require('../../../../node_modules/ng-file-upload/dist/ng-file-upload-all.min');

// Top level
import './app.module-ajs';
import './theming.config';

// Modules
import '../../absence/angularjs/absence.module-ajs';
import '../../admin/angularjs/admin.module-ajs';
import '../../ai-budgeting/angularjs/ai-budgeting.module-ajs';
import '../../availability/angularjs/availability.module-ajs';
import '../../scheduling/angularjs/budgeting/budgeting.module';
import '../../calendar/angularjs/calendar.module';
import '../../calendar2/calendar.module';
import '../../chain-ops/angularjs/absences/chain-absences.module';
import '../../chain-ops/angularjs/chain-ops.module-ajs';
import '../../chain-ops/angularjs/employees/search-employees.module';
import '../../chain-ops/angularjs/user-groups/user-group.module';
import '../../checklists/checklists.module';
import '../../company/angularjs/company.module-ajs';
import '../../company/angularjs/src/src.module';
import '../../company/angularjs/users/company-user.module';
import './components/components.module';
import '../../coupons/coupons.module';
import '../../dashboard/dashboard.module';
import '../../filesystem-access/filesystem-access.module';
import '../../filesystem/angularjs/filesystem.module-ajs';
import '../../fixed-labor-cost/fixed-labor-cost.module';
import '../../forms/angularjs/form.module-ajs';
import '../../hr/angularjs/hr.module-ajs';
import '../../hr/angularjs/sign-file/hr-sign.module';
import '../../icalendar/icalendar.module';
import '../../kpi/angularjs/kpi.routes';
import '../../todo/routes';
import '../../leave-shifts/angularjs/leave-shifts.module';
import '../../links/angularjs/links.module-ajs';
import '../../messages/angularjs/messages.module-ajs';
import './modules/alerts/alerts.module';
import './modules/classes/endless-list.module';
import './modules/customers/customers.module';
import './modules/decorators/decorator.module';
import './modules/dialogs/eaw.dialogs.module';
import './modules/digisign/digisign.module';
import './modules/employees/employees.module';
import './modules/misc/misc.module';
import './modules/resource/resource.module';
import './modules/table/table.module';
import './modules/time/time.module';
import './modules/user-groups/user-group.module-ajs';
import './modules/warnings/warnings.module';
import '../../newsletter/angularjs/newsletter.module';
import '../../notifications/angularjs/notifications.module';
import '../../outside/auth/auth.module';
import '../../outside/email-verification.module';
import '../../outside/login.module';
import '../../payroll/angularjs/flexitime/flexitime.module';
import '../../payroll/angularjs/paid-time/paid-time.module';
import '../../payroll/angularjs/payroll.module-ajs';
import '../../payroll/angularjs/punch-clock/punch-clock.module';
import '../../payroll/angularjs/timepunch/timepunch.module';
import '../../people-kpi/people-kpi.module-ajs';
import '../../period-locks/angularjs/period-locks.module-ajs';
import '../../projections/projections.module';
import '../../reports/angularjs/report.module-ajs';
import '../../scheduling/angularjs/backoffice/backoffice.module';
import '../../scheduling/angularjs/limits/scheduling-limits.module';
import '../../scheduling/angularjs/my-shifts.module';
import '../../scheduling/angularjs/scheduling.module-ajs';
import '../../scheduling/angularjs/shifts/shift.module';
import '../../sso/angularjs/sso.module';
import '../../staffing/angularjs/staffing.module';
import '../../tbs/tbs.module';
import '../../todo/angularjs/todo.module-ajs';
import '../../vacations/angularjs/vacation.module-ajs';
import '../../analytics/routes';
import '../../balances/angularjs/balances.module-ajs';
import '../../learning-module/learning-module-routes';

// Directories
import '../../absence/angularjs';
import '../../admin/angularjs';
import '../../ai-budgeting/angularjs';
import '../../availability';
import '../../scheduling/angularjs/budgeting';
import '../../calendar';
import '../../calendar2';
import '../../chain-ops/angularjs';
import '../../checklists';
import '../../company/angularjs';
import './components';
import '../../coupons';
import '../../dashboard';
import '../../filesystem/angularjs';
import '../../filesystem-access';
import '../../fixed-labor-cost';
import '../../forms/angularjs';
import '../../rotation/shared/angularjs';
import '../../hr/angularjs';
import '../../leave-shifts/angularjs';
import '../../messages/angularjs';
import './modules';
import '../../newsletter/angularjs';
import '../../notifications/angularjs';
import '../../outside';
import '../../payroll/angularjs';
import '../../period-locks/angularjs';
import '../../permissions/angularjs';
import '../../projections';
import './routes';
import '../../scheduling/angularjs';
import '../../sso/angularjs';
import '../../staffing/angularjs';
import '../../tbs';
import '../../todo/angularjs';
import '../../vacations/angularjs';
import './user';

// Downgrades
import '../../shared/http/me.service.downgrade';
import '../../newsletter/services/read-newsletter-issue.downgrade.service';
import './oauth.service.downgrade';
import './change-customer.factory';
import '../components/comment-list/comment-list.component.downgraded';
import '../components/profile-picture/profile-picture.component.downgraded';
import '../services/snack-bar.service.downgraded';
import '../services/login.service.downgrade';
import '../services/current.service.downgraded';
import '../http/customer-product.service.downgrade';
import '../../company/services/company-settings-sidenav.service.downgraded';
import '../../scheduling/components/schedule-employees-tab-old/schedule-employees-tab.downgrade';
import '../services/websocket.service.downgraded';
import '../../shared/dialogs/confirm-dialog/confirm-dialog.service.downgraded';
import '../../shared/dialogs/pdf-renderer-dialog/pdf-renderer-dialog.service.downgraded';
import '../../shared/components/markdown/markdown.component.downgrade';
import '../../wysiwyg/components/wysiwyg/wysiwyg.component.downgrade';
import '../../shared/dialogs/warnings-dialog/warnings-dialog.service.downgrade';
import '../../payroll/dialogs/timepunch-history-dialog/timepunch-history-dialog.service.downgrade';
import './employee.service.downgrade';
import '../../vacations/dialogs/handle-offtime-dialog/handle-offtime.service.downgrade';
import '../../scheduling/http/schedule.service.downgrade';
import '../../scheduling/services/schedule-tabs.service.downgrade';
import '../../scheduling/http/schedule-statistics.service.downgrade';
import '../../payroll/angularjs/services/manage-timepunch.service.downgrade';
import '../../payroll/angularjs/services/timepunch.service.downgrade';
import '../../shared/angularjs/permission-dialog.service.downgraded';
import '../../shared/services/unsaved-changes.downgrade';
import '../../shared/utils/mat-dialog.downgrade';
import '../../digital_signing/digital-signature.service.downgrade';
import '../../shared/services/permission-check.downgrade';
import '../../forms/components/show-form-submission/show-form-submission.downgrade';

// Upgrades
import '../../profile/angularjs/notifications-tab/notifications-tab.component';
