// @ts-nocheck
import { t } from 'i18next';
import moment, { Moment } from 'moment-timezone';
import { module } from 'angular';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';
import { UIRouter } from '@uirouter/core';
import { PeriodLocksFactory } from '../../../../period-locks/angularjs/period-locks.factory';
import { PaidTimeSegmentFactory } from '../employee-paid-time-segment.class';

module('eaw.payroll.paid-time').component('paidTimeDay', {
    template: `<item-legend items="$day.legend" ng-show="$day.showLegend" item-click="$day.legendClick(item)"></item-legend>

<md-card class="content-card">
    <md-card-header>
        <md-card-header-text layout="row" layout-align="start center">
            <md-icon class="day-locked" ng-bind="'shield'" ng-if="$day.locked">
                <md-tooltip ng-i18next="payroll:DAY_LOCKED"></md-tooltip>
            </md-icon>

            <md-button class="md-secondary md-icon-button left-btn" ng-click="$day.changeDate(-1)">
                <md-icon ng-bind="'arrow_back'"></md-icon>
            </md-button>

            <md-button class="header-btn" ng-click="$day.selectDate($event)">
                <span class="md-title pointer-events-none" ng-bind="$day.date | moment:'LL'"></span>
                <span class="md-subhead pointer-events-none" ng-bind="$day.date | moment:'dddd'"></span>
            </md-button>

            <md-button class="md-secondary md-icon-button right-btn" ng-click="$day.changeDate(1)">
                <md-icon ng-bind="'arrow_forward'"></md-icon>
            </md-button>

            <md-button class="md-secondary md-icon-button" ng-click="$day.selectDate($event)">
                <md-icon ng-bind="'calendar_month'"></md-icon>
            </md-button>
        </md-card-header-text>

        <div layout="row" hide show-gt-sm>
            <md-button class="md-icon-button" ng-click="$day.reload()">
                <md-icon ng-bind="'refresh'">
                    <md-tooltip ng-i18next="REFRESH"></md-tooltip>
                </md-icon>
            </md-button>

            <md-button class="md-icon-button" data-icon-badge="{{$day.offtimes.length}}" ng-click="$day.showOfftimes()" ng-disabled="!$day.offtimes.length">
                <md-icon ng-bind="'flight_takeoff'"></md-icon>
            </md-button>

            <md-button class="md-icon-button" ng-click="$day.summary()">
                <md-icon ng-bind="'info'">
                    <md-tooltip ng-i18next="SUMMARY"></md-tooltip>
                </md-icon>
            </md-button>

            <md-button class="md-icon-button" ng-if="$day.canLock" ng-click="$day.lock()">
                <md-icon ng-bind="'lock_open'">
                    <md-tooltip ng-i18next="payroll:LOCK_PERIOD"></md-tooltip>
                </md-icon>
            </md-button>

            <eaw-toggle-btn ng-model="$day.showLegend"
                            icon="abc"
                            toggled-initially="true"
                            i18n-tooltip="LEGEND">
            </eaw-toggle-btn>
        </div>


        <md-menu hide-gt-sm>
            <md-button class="md-icon-button" ng-click="$mdMenu.open()">
                <md-icon ng-bind="'more_vert'"></md-icon>
            </md-button>

            <md-menu-content width="4">
                <md-menu-item>
                    <md-button ng-click="$day.reload()">
                        <md-icon ng-bind="'refresh'"></md-icon>
                        <span ng-i18next="REFRESH"></span>
                    </md-button>
                </md-menu-item>

                <md-menu-item>
                    <md-button ng-click="$day.showOfftimes()" ng-disabled="!$day.offtimes.length">
                        <md-icon ng-bind="'flight_takeoff'"></md-icon>
                        <span ng-i18next="OFFTIME_plural"></span>
                    </md-button>
                </md-menu-item>

                <md-menu-item>
                    <md-button ng-click="$day.summary()">
                        <md-icon ng-bind="'info'"></md-icon>
                        <span ng-i18next="SUMMARY"></span>
                    </md-button>
                </md-menu-item>

                <md-menu-item>
                    <md-button ng-click="$day.lock()" ng-if="$day.canLock">
                        <md-icon ng-bind="'lock_open'"></md-icon>
                        <span ng-i18next="payroll:LOCK_PERIOD"></span>
                    </md-button>
                </md-menu-item>

                <md-menu-item>
                    <md-button ng-click="$day.showLegend = !$day.ptCtrl.showLegend;">
                        <md-icon ng-if="$day.showLegend" ng-bind="'abc'"></md-icon>
                        <md-icon ng-if="!$day.showLegend" ng-bind="'abc'"></md-icon>
                        <span ng-i18next="LEGEND"></span>
                    </md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </md-card-header>
    <md-card-content class="table-container">
        <div id="data-loader" ng-class="{'loading': $day.loading}" ng-show="$day.loading && !$day.updating">
            <md-icon ng-bind="'person'"></md-icon>
        </div>

        <table id="day-table" ng-hide="$day.loading" class="table table-condensed table-bordered sticky-left sticky-header"></table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$day',
    bindings: {
        customer: '<',
    },
    controller: [ '$uiRouter', '$element', 'AllComments', '$scope', 'addPeriodLock', 'PeriodLocksFactory', 'EmployeePaidTime', 'eawDatePickerDialog', 'EmployeePaidTimeSegment', 'componentCreatorService', 'ItemSelectorDialog', function($uiRouter: UIRouter, $element: angular.IAugmentedJQuery, AllComments, $scope: angular.IScope, addPeriodLock, PeriodLocksFactory: PeriodLocksFactory, EmployeePaidTime, eawDatePickerDialog, EmployeePaidTimeSegment: PaidTimeSegmentFactory, componentCreatorService, ItemSelectorDialog) {
        // @ts-ignore
        const ctrl = this as any;
        const defaultDate = moment().subtract(1, 'd').startOf('d');

        ctrl.$onInit = async () => {
            $element[0]?.classList.add('paid-time');

            ctrl.hours = 24;
            ctrl.table = $element[0]?.querySelector('#day-table');
            ctrl.legend = EmployeePaidTimeSegment.generateLegend();

            const date = moment($uiRouter.globals.params['date'], 'YYYY-MM-DD');
            await ctrl.setDate(date.isValid() ? date : defaultDate.clone());

            $scope.$on('paidtime:segment:change', ctrl.handleSegmentChange);

            await ctrl.getOverview();
        };

        ctrl.reload = () => {
            void $uiRouter.stateService.reload();
        };

        ctrl.getLock = async () => {
            if (!ctrl.customer.hasProduct('Period Locks')) {
                return;
            }

            let resp;
            ctrl.canLock = false;

            try {
                resp = await PeriodLocksFactory.getLocks(ctrl.customer.id, {
                    order_by: 'to',
                    direction: 'desc',
                    per_page: 1,
                }).$promise;
            } catch (e) {
                console.error(e);
                return;
            }

            ctrl.lockTime = resp.data[0]?.to;
            ctrl.locked = ctrl.lockTime && ctrl.date.isSameOrBefore(ctrl.lockTime, 'd');
            ctrl.canLock = !ctrl.locked && CurrentOld.can(`customers.${ctrl.customer.id}.period_locks.create`);
        };

        ctrl.legendClick = (item: any) => {
            const action: 'add' | 'remove' = [ 'add', 'remove' ][+item.selected] as 'add' | 'remove';
            Array.from(ctrl.table.querySelectorAll(`[data-type="${item.type.key}"]`) as HTMLElement[]).forEach((el: HTMLElement) => {
                el.classList[action]('display-none');
            });
        };

        ctrl.summary = () => {
            EmployeePaidTime.showSummary(ctrl.empPaidTimes, ctrl.date.clone().startOf('d'), ctrl.date.clone().endOf('d'));
        };

        ctrl.handleSegmentChange = async (ev: any, data: any) => {
            ctrl.updating = true;
            data.element.closest('tbody')?.classList.add('updating');

            // Increase span in order to encompass stuff that has this business date but is on another day
            const from = ctrl.getFrom().subtract(1, 'd');
            const to = ctrl.getTo().add(1, 'd');
            ctrl.empPaidTimes = await EmployeePaidTime.getOverview(ctrl.customer.id, from, to, data.segment.employeePaidTime.employee.id, ctrl.empPaidTimes);

            ctrl.updating = false;
            ctrl.buildTable();
        };

        ctrl.selectDate = (event) => {
            eawDatePickerDialog.open(ctrl.date.clone(), {
                focusOnOpen: true,
                event,
                callback: async (date: Moment) => {
                    if (date.isSame(ctrl.date, 'd')) {
                        return;
                    }

                    await ctrl.setDate(date);
                    await ctrl.getOverview();
                },
            });
        };

        ctrl.getOverview = async () => {
            delete ctrl.empPaidTimes;
            ctrl.loading = true;

            await ctrl.getLock();

            await EmployeePaidTime.getOverview(ctrl.customer.id, ctrl.getFrom(), ctrl.getTo()).then((resp) => {
                ctrl.empPaidTimes = resp;
                ctrl.offtimes = resp.offtimes;
                ctrl.buildTable();
            }).finally(() => delete ctrl.loading);
        };

        ctrl.getFrom = () => ctrl.date.clone().startOf('d');

        ctrl.getTo = () => ctrl.date.clone().endOf('d');

        ctrl.lock = () => {
            addPeriodLock.open($uiRouter.stateService.reload, ctrl.customer, ctrl.date);
        };

        ctrl.showOfftimes = () => {
            ItemSelectorDialog.open({
                title: t('payroll:VAC_TIME_OFF'),
                items: sort(ctrl.offtimes, CurrentOld.languageTag, [ (o) => o.name ]),
                displayKey: 'name',
                disableSelecting: true,
                subtitleKey(off: any) {
                    return t('FROM_TO', {
                        from: off.from.format('llll'),
                        to: off.to.format('llll'),
                    });
                },
            });
        };

        ctrl.setDate = (date: Moment) => {
            const oldDate = ctrl.date?.format('YYYY-MM-DD');
            ctrl.date = date;
            const newDate: string = date.format('YYYY-MM-DD');

            if (oldDate === newDate) {
                return;
            }

            return $uiRouter.stateService.go($uiRouter.globals.current, { date: newDate });
        };

        ctrl.changeDate = async (change: number) => {
            await ctrl.setDate(ctrl.date.clone().add(change, 'd'));

            clearTimeout(ctrl.timeout);

            ctrl.timeout = setTimeout(() => {
                ctrl.clearTable();
                ctrl.timeout = null;
                ctrl.getOverview();
            }, 500);
        };

        ctrl.clearTable = () => {
            Array.from(ctrl.table.children as HTMLElement[]).forEach((c: HTMLElement) => c.remove());
        };

        ctrl.buildTable = () => {
            ctrl.clearTable();

            // Header
            const thead = document.createElement('thead');
            const theadTr = document.createElement('tr');

            // Name
            const nameTh = document.createElement('th');
            nameTh.innerText = t('EMPLOYEE');
            nameTh.classList.add('employee-name');
            theadTr.append(nameTh);

            // Button(s)
            const btnTh = document.createElement('th');
            btnTh.classList.add('buttons-cell');
            theadTr.append(btnTh);

            // Status
            const statusTh = document.createElement('th');
            statusTh.classList.add('status-cell');
            theadTr.append(statusTh);

            // Times
            for (let i = 0; i < ctrl.hours; i++) {
                const th = document.createElement('th');
                th.innerText = moment().startOf('d').add(i, 'h').format('LT');
                th.classList.add('time-th');
                theadTr.appendChild(th);
            }

            thead.appendChild(theadTr);
            ctrl.table.appendChild(thead);

            const paidTimes = Object.values(ctrl.empPaidTimes)
                .map((p) => p[ctrl.date.format('YYYY-MM-DD')])
                .filter((p) => p instanceof EmployeePaidTime);

            sort(paidTimes, CurrentOld.languageTag, [ (p) => p.employee.name ]).forEach((paidTime) => {
                ctrl.insertEmployee(paidTime);
            });

            // Remove button cells if we have none
            if (!ctrl.table.querySelectorAll('eaw-icon-button').length) {
                Array.from(ctrl.table.querySelectorAll('.buttons-cell')).forEach((el: HTMLElement) => el.remove());
            }
        };

        /**
         * @param {EmployeePaidTime} empPaidTime
         */
        ctrl.createNameTd = (empPaidTime) => {
            const td = document.createElement('td');
            td.classList.add('employee-name');

            // Name div
            const nameDiv = document.createElement('div');
            nameDiv.innerText = empPaidTime.employee.name;
            td.appendChild(nameDiv);

            // Difference div
            td.appendChild(empPaidTime.generateDifferenceEl(ctrl.date));

            // Warning/info about active timepunch
            td.appendChild(empPaidTime.generateOngoingEl());

            return td;
        };

        /**
         * @param {EmployeePaidTime} empPaidTime
         */
        ctrl.createStatusTd = (empPaidTime) => {
            const td = document.createElement('td');
            td.classList.add('employee-status');

            // Icon for day absence
            empPaidTime.generateAbsenceTooltipIcon(td, ctrl.date);

            // Icon for vacation
            empPaidTime.generateOfftimeTooltipIcon(td, ctrl.date, ctrl.offtimes);

            return td;
        };

        /**
         * @param {EmployeePaidTime} empPaidTime
         */
        ctrl.createSegmentsTd = (empPaidTime) => {
            const td = document.createElement('td');
            td.colSpan = 99;
            td.classList.add('segments');

            empPaidTime.segments.forEach((seg: any) => {
                td.appendChild(seg.createElement(ctrl.date));
            });

            return td;
        };

        /**
         * @param {EmployeePaidTime} empPaidTime
         */
        ctrl.createCommentsTd = (empPaidTime) => {
            const td = document.createElement('td');
            td.classList.add('buttons-cell');

            if (!empPaidTime.hasComments) {
                return td;
            }

            const comp = componentCreatorService.create({
                name: 'eawIconButton',
                bindings: {
                    tooltip: t('COMMENT_plural'),
                    icon: [ 'comment', 'string' ],
                },
            });

            comp.element.dataset.gaItem = `Paid time day comments button`;
            comp.element.addEventListener('click', (e) => {
                e.stopPropagation();
                AllComments.open(empPaidTime);
            });

            td.appendChild(comp.element);

            return td;
        };

        /**
         * @param {EmployeePaidTime} empPaidTime
         */
        ctrl.insertEmployee = (empPaidTime: any) => {
            const tbody = document.createElement('tbody');
            tbody.dataset['employeeId'] = empPaidTime.employee.id;

            const tr = document.createElement('tr');
            tr.appendChild(ctrl.createNameTd(empPaidTime));
            tr.appendChild(ctrl.createStatusTd(empPaidTime));
            tr.appendChild(ctrl.createCommentsTd(empPaidTime));

            // Only add row if it has segment(s)
            const segmentsTd = ctrl.createSegmentsTd(empPaidTime);
            if (segmentsTd.childElementCount || tr.getElementsByClassName('employee-status')[0].childElementCount) {
                tr.appendChild(segmentsTd);
                tbody.appendChild(tr);
                ctrl.table.appendChild(tbody);
            }
        };
    } ],
});
