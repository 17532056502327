import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { HourDistributionService } from '../../../http/hour-distribution.service';
import { catchError } from 'rxjs';
import { HourDistribution } from '../../../models/hour-distribution';
import { EawValidators } from '../../../../shared/validators/eaw-validators';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';

export type HourDistributionDialogReturn = HourDistribution;

export interface CreateHourDistributionDialogData extends DialogData {
    customerId: number,
    employeeId: number,
}

@Component({
    selector: 'eaw-create-hour-distribution-dialog',
    templateUrl: './create-hour-distribution-dialog.component.html',
    styleUrl: './create-hour-distribution-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class CreateHourDistributionDialogComponent extends DialogComponent {
    readonly minDate = DateTime.now().startOf('day');

    form = new FormGroup({
        from: new FormControl<DateTime | null>(null, Validators.required),
        to: new FormControl<DateTime | null>(null),
        weeks: new FormControl<number | null>(null, [ Validators.required, Validators.min(1), Validators.max(4), EawValidators.integer() ]),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateHourDistributionDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<CreateHourDistributionDialogComponent, HourDistributionDialogReturn>,
        @Inject(HourDistributionService) private hourDistributionService: HourDistributionService,
    ) {
        super(dialogRef, { size: DialogSize.Medium });
    }

    create() {
        const from = this.form.controls.from.value;
        const to = this.form.controls.to.value ?? undefined;
        const weeks = this.form.controls.weeks.value;

        if (!from || !weeks) {
            return;
        }

        this.form.disable();
        this.hourDistributionService.create(this.data.customerId, this.data.employeeId, from, to, weeks * 7).pipe(
            catchError((err) => {
                this.form.enable();
                throw err;
            }),
        ).subscribe((res) => {
            this.dialogRef.close(res);
        });
    }
}
