import { TodoStatusType } from '../enums/todo-status-type';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { DateTime } from 'luxon';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface TodoStatusResponse extends ApiResponse {
    created_at: string;
    customer_id: number;
    id: number;
    name: string;
    type: TodoStatusType;
    updated_at: string;
    items_count: number;
}

export class TodoStatus {
    createdAt: DateTime;
    customerId: number;
    id: number;
    name: string;
    itemsCount: number;
    type: TodoStatusType;
    updatedAt: DateTime;
    icon: string;

    constructor(data: TodoStatusResponse) {
        this.id = data.id;
        this.customerId = data.customer_id;
        this.name = data.name;
        this.type = data.type;
        this.itemsCount = data.items_count;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);

        switch (this.type) {
            case TodoStatusType.Default:
            case TodoStatusType.Initial: {
                this.icon = 'start';
                break;
            }
            case TodoStatusType.InProgress: {
                this.icon = 'pending';
                break;
            }
            case TodoStatusType.Done: {
                this.icon = 'done';
                break;
            }
        }
    }
}
