import { EmployeeResponse } from '../../shared/models/employee';
import { ShiftSwapResponse } from '../interfaces/shift-swap-response';
import { User, UserResponse } from '../../shared/models/user';
import { ApiResponse } from '../../shared/interfaces/api-response';

export interface SwapApplicantResponse extends ApiResponse {
    id: number;
    employee_id: number;
    employee?: EmployeeResponse;
    swap_id: number;
    swap?: ShiftSwapResponse;
    dismissed: boolean;
    dismissed_by_id: number;
    dismissedBy?: UserResponse;
    cost?: number;
}

export class SwapApplicant {
    id: number;
    employeeId: number;
    employeeName?: string;
    swapId: number;
    dismissed: boolean;
    dismissedById: number;
    dismissedByName?: string;
    cost?: number;
    valid: boolean;

    constructor(data: SwapApplicantResponse) {
        this.id = data.id;
        this.cost = data.cost;
        this.employeeId = data.employee_id;
        this.employeeName = [ data.employee?.first_name, data.employee?.last_name ].join(' ') ?? undefined;
        this.dismissedById = data.dismissed_by_id;
        this.swapId = data.swap_id;
        this.dismissed = data.dismissed;
        this.valid = !this.dismissed;
        this.dismissedByName = data.dismissedBy ? new User(data.dismissedBy).name : undefined;
    }
}
