// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
const template1 = `<md-progress-linear ng-if="ctrl.loading"></md-progress-linear>

<div class="info-container">
    <div class="info-title" ng-i18next="WARNING"></div>
    <div class="info-subtitle" ng-bind="ctrl.warning.translatedMessage"></div>
</div>

<div class="info-container" ng-if="!ctrl.loading && !ctrl.warning.causes.length">
    <span>No additional information found about the cause for this warning</span>
</div>

<div class="info-container" ng-if="!ctrl.loading && ctrl.warning.causes.length" ng-repeat="cause in ctrl.warning.causes" ng-switch on="cause.type">
    <div ng-switch-when="shift">
        <div class="info-title" ng-i18next="warnings:CAUSE_SHIFT"></div>

        <div class="cause-info">
            <table>
                <tr>
                    <td>Location</td>
                    <td ng-bind="cause.cause.schedule.customer.name"></td>
                </tr>
                <tr>
                    <td>Schedule</td>
                    <td ng-bind="cause.cause.schedule.name"></td>
                </tr>
                <tr>
                    <td>Employee</td>
                    <td ng-bind="cause.cause.employee.name"></td>
                </tr>
                <tr>
                    <td>Shift business date</td>
                    <td ng-bind="cause.cause.business_date | moment:'ll'"></td>
                </tr>
                <tr>
                    <td>Shift from</td>
                    <td ng-bind="cause.cause.from | moment:'lll'"></td>
                </tr>
                <tr>
                    <td>Shift to</td>
                    <td ng-bind="cause.cause.to | moment:'lll'"></td>
                </tr>
                <tr>
                    <td>Shift created</td>
                    <td ng-bind="cause.cause.created_at | momentRelative"></td>
                </tr>
                <tr>
                    <td>Shift updated</td>
                    <td ng-bind="cause.cause.updated_at | momentRelative"></td>
                </tr>
            </table>
        </div>
    </div>

    <div ng-switch-when="absence">
        <div class="info-title" ng-i18next="warnings:CAUSE_ABSENCE"></div>

        <div class="cause-info">
            <table>
                <tr>
                    <td>Location</td>
                    <td ng-bind="cause.cause.employee.customer.name"></td>
                </tr>
                <tr>
                    <td ng-i18next="FROM"></td>
                    <td ng-bind="cause.cause.from | moment:'llll'"></td>
                </tr>
                <tr>
                    <td ng-i18next="TO"></td>
                    <td ng-bind="cause.cause.to | moment:'llll'"></td>
                </tr>
            </table>
        </div>
    </div>

    <div ng-switch-when="off_time">
        <div class="info-title" ng-if="!cause.cause.vacation" ng-i18next="warnings:CAUSE_OFFTIME"></div>
        <div class="info-title" ng-if="cause.cause.vacation" ng-i18next="warnings:CAUSE_VACATION"></div>

        <div class="cause-info">
            <table>
                <tr>
                    <td>Location</td>
                    <td ng-bind="cause.cause.employee.customer.name"></td>
                </tr>
                <tr>
                    <td ng-i18next="FROM"></td>
                    <td ng-bind="cause.cause.from | moment:'llll'"></td>
                </tr>
                <tr>
                    <td ng-i18next="TO"></td>
                    <td ng-bind="cause.cause.to | moment:'llll'"></td>
                </tr>
            </table>
        </div>
    </div>

    <div ng-switch-when="timepunch">
        <div class="info-title" ng-i18next="warnings:CAUSE_TIMEPUNCH"></div>

        <div class="cause-info">
            <table>
                <tr>
                    <td>Location</td>
                    <td ng-bind="cause.cause.employee.customer.name"></td>
                </tr>
                <tr>
                    <td ng-i18next="BUSINESS_DATE"></td>
                    <td ng-bind="cause.cause.business_date | moment:'ll'"></td>
                </tr>
                <tr>
                    <td ng-i18next="FROM"></td>
                    <td ng-bind="cause.cause.in | moment:'lll'"></td>
                </tr>
                <tr>
                    <td ng-i18next="TO"></td>
                    <td ng-bind="cause.cause.out | moment:'lll'"></td>
                </tr>
                <tr>
                    <td ng-i18next="LENGTH"></td>
                    <td ng-bind="cause.cause.length | eawDuration"></td>
                </tr>
                <tr>
                    <td ng-i18next="APPROVED"></td>
                    <td ng-if="cause.cause.approved" ng-i18next="YES"></td>
                    <td ng-if="!cause.cause.approved" ng-i18next="NO"></td>
                </tr>
                <tr ng-if="cause.cause.approved">
                    <td ng-i18next="APPROVED_BY"></td>
                    <td ng-bind="cause.cause.approved_by.name"></td>
                </tr>
                <tr>
                    <td ng-i18next="CREATED_AT"></td>
                    <td ng-bind="cause.cause.created_at | momentRelative"></td>
                </tr>
                <tr>
                    <td ng-i18next="UPDATED_AT"></td>
                    <td ng-bind="cause.cause.updated_at | momentRelative"></td>
                </tr>
            </table>
        </div>
    </div>

    <div ng-switch-default>
        No available information for type {{cause.type}}
    </div>
</div>
`;
module('eaw.warnings').factory('Warning', [ 'EawResource', '$mdPanel', 'WarningCause', function WarningClass(EawResource, $mdPanel, WarningCause) {
    class Warning {
        id;
        customer_id;
        translatedMessage;
        message_parameters;
        message;
        causes;
        observer;
        constructor(data) {
            Object.assign(this, data || {});
            this.translatedMessage = t(`warnings:${this.message}`, this.message_parameters).replace('warnings:', '');
        }

        openExtendedInfo(event) {
            const warning = this;
            const openClose = event?.target || document.body;
            $mdPanel.open({
                attachTo: document.body,
                controller: 'extendedWarningInfoCtrl',
                controllerAs: 'ctrl',
                template: template1,
                panelClass: 'eaw-panel extended-warning-info-panel',
                hasBackdrop: true,
                position: $mdPanel.newPanelPosition()
                    .absolute()
                    .center(),
                animation: $mdPanel.newPanelAnimation()
                    .openFrom(openClose)
                    .closeTo(openClose)
                    .duration(200)
                    .withAnimation($mdPanel.animation.SCALE),
                locals: {
                    warning,
                },
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                zIndex: 100,
            });
        }

        getCauses() {
            if (this.causes) {
                return Promise.resolve(this.causes);
            }
            return Warning.get({
                id: this.id,
                customer_id: this.customer_id,
                with: [ 'causes.cause.employee.customer', 'observer' ],
            }).$promise.then((resp) => {
                this.observer = resp.observer;
                this.causes = resp.causes.map((cause) => new WarningCause(cause));
            });
        }

        static get(args) {
            return EawResource.create('/customers/:customer_id/warnings/:id').get({
                id: args.id,
                customer_id: args.customer_id,
                'with[]': args.with,
            });
        }
    }
    return Warning;
} ]);
