<div id="header" class="tw-flex tw-flex-row tw-justify-between tw-items-center">
    <div class="tw-flex tw-flex-row tw-justify-start tw-items-center">
        <mat-icon class="title-icon">supervisor_account</mat-icon>
        <span class="mat-headline-5 tw-m-0">{{'PEOPLE_KPI' | translate:'people_kpi' | async}}</span>
    </div>

    <form [formGroup]="formGroup" class="tw-flex tw-flex-row tw-gap-8 tw-py-4">
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>{{'ORGANIZATION' | translate:'admin' | async}}</mat-label>
            <mat-icon matPrefix>store</mat-icon>
            <input type="text"
                   aria-label="Number"
                   matInput
                   formControlName="level"
                   [matAutocomplete]="level">
            <mat-autocomplete #level="matAutocomplete" [displayWith]="levelDisplay" (optionSelected)="changeLevel($event.option.value)">
                <mat-option *ngFor="let lv of filteredLevels | async" [value]="lv">{{lv.name}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" eawDatePickerOptions>
            <mat-label>{{'ENTER_DATE_RANGE' | translate | async}}</mat-label>
            <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
                <input matStartDate [placeholder]="'START_DATE' | translate | async" formControlName="from">
                <input matEndDate [placeholder]="'END_DATE' | translate | async" formControlName="to">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </form>
</div>

<mat-card class="mat-compact-card">
    <mat-card-content>
        <eaw-data-table
                [request]="request"
                [hidePaginator]="true"
                [columns]="columns"
                initialSortBy="id"
                (paginationChange)="updateTable()">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
