<div class="tw-flex tw-justify-between">
    <div class="left-menu">
        <button mat-button [matMenuTriggerFor]="customersMenu" [disabled]="!customer || fetching">{{customer?.name || ('LOCATION' | translate | async)}}</button>
        <mat-menu #customersMenu="matMenu">
            <mat-form-field class="tw-p-16 tw-w-full tw-box-border tw-sticky" style="background: white; top: 0; z-index: 1" (click)="$event.stopPropagation()">
                <mat-label>{{'FILTER' | translate | async}}</mat-label>
                <input matInput [formControl]="customerFilter" type="text">
            </mat-form-field>

            <mat-selection-list [multiple]="false" (selectionChange)="setCustomer($event)" >
                <mat-list-option *ngFor="let c of filteredCustomers | async" [value]="c" [selected]="c.id === customer?.id">
                    {{c.name}}
                </mat-list-option>
            </mat-selection-list>
        </mat-menu>

        <button mat-button [matMenuTriggerFor]="segmentsMenu">{{'PAID_TIME_SEGMENT_plural' | translate:'payroll' | async }}</button>
        <mat-menu #segmentsMenu="matMenu">
            <mat-selection-list multiple (selectionChange)="setSelectedSegments($event)">
                <mat-list-option *ngFor="let type of paidTimeSegmentTypes" [value]="type" [selected]="type.selected" (click)="$event.stopPropagation()">
                    {{type.name.key | translate:type.name.ns | async}}
                </mat-list-option>
            </mat-selection-list>
        </mat-menu>

        <button mat-button [matMenuTriggerFor]="displayMenu">{{'VIEW' | translate | async}}</button>
        <mat-menu #displayMenu="matMenu">
            <button mat-menu-item role="menuitemcheckbox" (click)="updateShowEmptyRows()">
                <mat-checkbox class="pointer-events-none" [checked]="showEmptyRows">{{'SHOW_EMPTY_ROWS' | translate:'payroll' | async}}</mat-checkbox>
            </button>
        </mat-menu>
    </div>
</div>

<div class="tw-flex tw-justify-between">
    <div class="left-menu tw-flex">
        <eaw-date-interval-selector interval="week" (intervalChange)="updateInterval($event)" [from]="from" [to]="to"/>

        <div *ngIf="showWeekNr" class="tw-ml-4 tw-flex tw-items-center">
            <strong>{{'WEEK_N' | translate:'general':{number: from | DateTime:null:'W'} | async}}</strong>
        </div>
    </div>
</div>

<div id="grid-container" #gridContainer>
    <mat-progress-spinner *ngIf="fetching" mode="indeterminate" class="tw-justify-self-center tw-self-center tw-row-span-full"></mat-progress-spinner>

    <div id="grid" *ngIf="!fetching && overview">
        <div id="top-row">
            <div id="empty"></div>
            <eaw-paid-time-overview-date *ngFor="let date of headerDates" [date]="date.date" [isHoliday]="date.isHoliday" [overview]="overview"></eaw-paid-time-overview-date>
        </div>

        <eaw-paid-time-overview-employee-row
                *ngFor="let employee of overview.employees"
                [overview]="overview"
                [dates]="dates"
                [types]="selectedTypes"
                [employee]="employee"/>
    </div>
</div>
