import { ApiResponse } from '../../shared/interfaces/api-response';
import { Tag, TagResponse } from './tag';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export interface TagSetResponse extends ApiResponse {
    endDate?: string;
    startDate?: string;
    date?: string;
    tags: TagResponse[];
}

export class TagSet {
    tags: Tag[];
    date?: DateTime;

    constructor(data: TagSetResponse) {
        this.tags = data.tags ? data.tags.map((tag) => new Tag(tag)) : [];
        if (data.date) {
            this.date = stringToDateTime(data.date);
        }
    }
}
