import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Fullscreen } from '../angularjs/modules/misc/services/fullscreen.service';

@Injectable({
    providedIn: 'root',
})
export class FullscreenHookService {

    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
        this.uiRouter.transitionService.onSuccess({}, (transition) => {
            console.debug('🪝', 'FullscreenHookService.onSuccess', transition);

            if (transition.to().data?.fullscreen) {
                Fullscreen.enable();
            } else {
                Fullscreen.disable();
            }
        });
    }
}
