import { PermissionsInputValueTuple } from '../permissions/services/element-permission.service';
import { ApiModel } from '../shared/enums/api-model';
import { PermissionModel } from '../shared/services/permission-check.service';
import { commentPermissionModel, customerPermissionModel } from '../shared/permissions';

export function schedulePermissionModel(id: number): PermissionModel {
    return { type: ApiModel.Schedule, id };
}

export function shiftPermissionModel(id: number): PermissionModel {
    return { type: ApiModel.Shift, id };
}

export function canGetSchedulePermission(stackId: number, customerId: number, scheduleId: number): PermissionsInputValueTuple {
    return [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].get`, {
        stackId,
        models: [ customerPermissionModel(customerId), schedulePermissionModel(scheduleId) ],
    } ];
}

export function canUpdateSchedulePermission(stackId: number, customerId: number, scheduleId: number): PermissionsInputValueTuple {
    return [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].update`, {
        stackId,
        models: [ customerPermissionModel(customerId), schedulePermissionModel(scheduleId) ],
    } ];
}

export function canCreateShiftPermission(stackId: number, customerId: number, scheduleId: number): PermissionsInputValueTuple {
    return [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].shifts.create`, {
        stackId,
        models: [ customerPermissionModel(customerId), schedulePermissionModel(scheduleId) ],
    } ];
}

export function canPublishSchedulePermission(stackId: number, customerId: number, scheduleId: number): PermissionsInputValueTuple {
    return [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].publish`, {
        stackId,
        models: [ customerPermissionModel(customerId), schedulePermissionModel(scheduleId) ],
    } ];
}

export function canForcePublishSchedulePermission(stackId: number, customerId: number, scheduleId: number): PermissionsInputValueTuple {
    return [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].force_publish`, {
        stackId,
        models: [ customerPermissionModel(customerId), schedulePermissionModel(scheduleId) ],
    } ];
}

export function canDeleteShiftCommentPermission(stackId: number, customerId: number, shiftId: number, commentId: number): PermissionsInputValueTuple {
    return [ `customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].shifts.[${ApiModel.Shift}].update`, {
        stackId,
        models: [
            customerPermissionModel(customerId),
            shiftPermissionModel(shiftId),
            commentPermissionModel(commentId),
        ],
    } ];
}
