import { ApiResponse } from '../interfaces/api-response';
import { BusinessDateString } from '../types/business-date';
import { BusinessDate } from '../utils/business-date';

export interface HolidayResponse extends ApiResponse {
    country: string;
    date: BusinessDateString;
    id: number;
    name: string;
    year: number;
}

export class Holiday {
    country: string;
    date: BusinessDate;
    id: number;
    name: string;
    year: number;

    constructor(data: HolidayResponse) {
        this.country = data.country;
        this.date = new BusinessDate(data.date);
        this.id = data.id;
        this.name = data.name;
        this.year = data.year;
    }
}
