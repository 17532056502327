import { find, isObject, remove, uniqBy } from 'lodash-es';
import { uniqueId } from '../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { Moment } from 'moment-timezone';
import { module } from 'angular';
import { Model } from '../../../../../shared/angularjs/model';
import { lastValueFrom } from 'rxjs';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
import { UserPropertyService } from '../../../../../shared/http/user-property.service';
import { Property } from '../../../../../shared/models/property';
import { WebsocketService } from 'src/app/shared/services/websocket.service';

type SettingValue = string | number | boolean | undefined;

// eslint-disable-next-line max-len
module('eaw.scheduling').factory('Schedule', [ 'UserPropertyDowngraded', 'ScheduleService', 'WebsocketDowngrade', 'shiftEvents', 'Shift', function ScheduleClass(UserPropertyDowngraded: UserPropertyService, ScheduleService: any, WebsocketDowngrade: WebsocketService, shiftEvents: any, Shift: any) {
    class Schedule extends Model {
        // @ts-ignore
        override id!: number;
        shifts: any;
        refreshOnSave: any;
        canUpdate: boolean;
        is_template!: boolean;
        is_published!: boolean;
        from!: Moment;
        to!: Moment;
        length!: number;
        customer_id!: number;
        render: any;
        holidays: any;
        employees: any;
        customer: any;
        settings: Record<string, SettingValue>;
        canGetWithWarnings = CurrentOld.can(`customers.${this.customer_id}.schedules.create`);

        constructor(schedule: any) {
            super(schedule);

            this.settings = {
                shiftGrouping: 'ungrouped',
                sorting: 'offset,asc',
                interval: 3600,
                mode: 'select',
                verticalLines: false,
                expandedShifts: false,
                onlyUnassigned: false,
                filterUnits: false,
                topStatsEnabled: false,
                showNicknames: false,
            };

            this.shifts = schedule.shifts?.length ? schedule.shifts.map((s: any) => s instanceof Shift ? s : new Shift(s, this)) : [];
            this.canUpdate = CurrentOld.can(`customers.${this.customer_id}.schedules.${this.id}.update`);

            this.refreshSavedShift(true);
            this.setRender(this.from, this.length);
            void this.setSettings();
        }

        refreshSavedShift(refresh?: boolean) {
            if (arguments.length) {
                this.refreshOnSave = refresh;
            }
            return this.refreshOnSave;
        }

        setRender(from: Moment, length: number) {
            this.render = {
                from: from.clone(),
                length,
                to: from.clone().add(length, 's'),
                offset: from.diff(this.from, 's'),
            };
        }

        setHolidays(holidays: any) {
            this.holidays = holidays;
        }

        getFrom() {
            return this.from.clone();
        }

        getTo() {
            return this.to.clone();
        }

        copy() {
            return { ...this };
        }

        getDays() {
            const days = [];
            const currentDate = this.getFrom();
            const lastDate = this.getTo().subtract(1, 'm');
            while (currentDate.isSameOrBefore(lastDate, 'h')) {
                const nextDate = currentDate.clone().add(1, 'h');
                if (!currentDate.isSame(nextDate, 'd')) {
                    days.push(currentDate.clone().startOf('d'));
                }
                currentDate.add(1, 'h');
            }
            // Check if there are hours on the last day, so we have to add last day
            if (currentDate.clone().diff(currentDate.clone().startOf('d'), 'h') > 0) {
                days.push(currentDate.clone().startOf('d'));
            }
            return days;
        }

        /**
         * Returns the day at index X from start of schedule
         * @param {int} index
         * @returns {moment.Moment}
         */
        getDayFromIndex(index: number) {
            return this.getFrom().add(index, 'd').startOf('d');
        }

        /**
         * @memberOf Schedule
         * Returns length of schedule in seconds
         * @returns {int}
         */
        getLength() {
            return this.length;
        }

        isTemplate() {
            return !!this.is_template;
        }

        isPublished() {
            return !!this.is_published;
        }

        getLastDayIndex() {
            return this.getDays().length;
        }

        getDay(index: number) {
            return this.getFrom().add(index, 'd');
        }

        getDayIndex(date: any) {
            return date.clone().diff(this.getFrom().startOf('d'), 'd');
        }

        // Shifts
        setShifts(shifts: any) {
            this.shifts = shifts.map((s: any) => (s instanceof Shift ? s : new Shift(s, this)));
        }

        getShifts() {
            return [].concat(this.shifts);
        }

        getOverlappingShifts(shift: any) {
            const shifts = this.getShifts();
            const callback = shift.employee_id ? (s: any) => shift.employee_id == s.employee_id && s.id != shift.id : (s: any) => shift.id != s.id;
            return shifts.filter(callback).map((s: any) => s.overlaps(shift));
        }

        getShiftGroupMembers(groupId: number) {
            return this.getShifts().filter((s: any) => s.shift_group?.id === groupId);
        }

        /**
         * @param {Number|String} id
         * @return {Shift}
         */
        getShift(id: any) {
            id = parseInt(id);
            return this.shifts.find((s: any) => s.id === id);
        }

        appendShifts(shifts: any) {
            // Only append belonging to this schedule
            shifts = shifts.filter((s: any) => s.schedule?.id === this.id || s.schedule_id === this.id);
            this.shifts = uniqBy(this.shifts.concat(shifts), 'id').map((shift) => new Shift(shift, this));
        }

        getShiftsByDate(date: any) {
            return this.shifts.filter((s: any) => s.from.isSame(date, 'd'));
        }

        setEmployees(employees: any) {
            this.employees = employees;
        }

        getEmployees() {
            return this.employees;
        }

        /**
         *
         * @param {String[]} [exclude=[]]
         * @returns {string[]}
         */
        getShiftWiths(exclude = []) {
            const withs = [
                'periods',
                'periods.businessUnit',
                'periods.qualifications',
                'employee',
                'qualifications',
                'properties',
                'comments',
            ];
            if (this.canGetWithWarnings) {
                withs.push('warnings');
            }
            // @ts-ignore
            return withs.filter((w: any) => !exclude.includes(w));
        }

        /**
         * Return an array of times at set interval for a day in the schedule
         * @param {int} index
         * @param {int} interval
         * @returns {Array.<moment.Moment>}
         */
        getArrayOfIntervalsForDay(index: any, interval: any) {
            const rows = [];
            const start = this.getDayFromIndex(index);
            const intervalsInADay = 60 * 60 * 24 / interval;
            for (let i = 0; i < intervalsInADay; i++) {
                const date = start.clone().add(i * interval, 's');
                if (date.isSameOrAfter(this.from) && date.isBefore(this.to)) {
                    rows.push(date);
                }
            }
            return rows;
        }

        /**
         * Return an array of times at set interval for a day in the schedule
         * @param {int} index
         * @param {int} interval
         * @param {int} offsetHours
         * @returns {Array.<moment.Moment>}
         */
        getArrayOfIntervalsForDayWithOffset(index: any, interval: any, offsetHours: any) {
            const rows = [];
            const start = this.getDayFromIndex(index);
            const intervalsInADay = 60 * 60 * 24 / interval;
            const offset = offsetHours * 60 * 60;
            for (let i = 0; i < intervalsInADay; i++) {
                const date = start.clone().add(i * interval + offset, 's');
                rows.push(date);
            }
            return rows;
        }

        /**
         * Returns the index of an interval from the beginning of the schedule
         * @param {moment.Moment} date
         * @param {int} interval
         *
         * @returns {int}
         */
        getIntervalIndexFromDate(date: any, interval: any) {
            return date.diff(this.from, 's') / interval;
        }

        getDummy(shift: any) {
            return this.shifts.find((s: any) => s.isDummy() && s.length === shift.length && s.offset === shift.offset);
        }

        addDummy(shift: any) {
            shift.id = uniqueId('dummy');
            this.shifts.unshift(shift);
            return shift.id;
        }

        removeShift(id: any) {
            if (!id) {
                return;
            }
            return remove(this.shifts, { id });
        }

        /**
         * Add a shift
         * @param {Shift} shift
         */
        insertShift(shift: any) {
            // Only insert belonging to this schedule
            if (shift?.schedule?.id === this.id || shift?.schedule_id === this.id) {
                this.shifts.push(shift);
                this.onShiftSaved(shift, 'created');
            }
        }

        /**
         * Create a new shift async
         * @param {Shift|Proxy} shift
         * @return {Promise}
         */
        addShift(shift: any) {
            if (Schedule.isShift(shift)) {
                shiftEvents.trigger.creating(shift);
                // Make object array into int array so long as it isn't already ;)
                if (isObject(shift.qualifications?.[0])) {
                    shift.qualifications = shift.qualifications.map((qual: any) => qual.id);
                }
                return shift.create().$promise.then((createResp: any) => {
                    this.onShiftSaved(new Shift(createResp, this), 'created');
                }).catch((createErr: any) => {
                    console.error(createErr);
                });
            }
        }

        /**
         * @memberOf Schedule
         * @param {Shift|Object} shift
         * @return {Promise}
         */
        updateShift(shift: any) {
            if (Schedule.isShift(shift) && !shift.status) {
                if (Array.isArray(shift.qualifications)) {
                    // Make object array into int array so long as it isn't already ;)
                    if (shift.qualifications.every((qual: any) => typeof qual === 'object' && 'id' in qual)) {
                        shift.qualifications = shift.qualifications?.map((qual: any) => qual.id);
                    }
                }

                return shift.update().then((okShift: any) => {
                    okShift.refresh().then((okRefreshShift: any) => {
                        this.onShiftSaved(okRefreshShift, 'updated');
                    });
                }).catch((error: any) => {
                    error.callback = shift.refresh.bind(shift);
                    shift.refresh().then((catchRefreshShift: any) => {
                        this.onShiftSaved(catchRefreshShift, 'updated');
                    });
                });
            }
        }

        /**
         * @memberOf Schedule
         * @param {Shift} shift
         * @return {*}
         */
        deleteShift(shift: any) {
            if (Schedule.isShift(shift)) {
                return shift.delete().$promise.then(() => {
                    // Remove from array
                    this.removeShift(shift.id);
                    shiftEvents.trigger.deleted(shift);
                }).catch(() => shift.refresh().then((s: any) => this.onShiftSaved(s, 'updated')));
            }
        }

        update() {
            return ScheduleService.updateSchedule({ id: this.customer_id }, {
                id: this.id,
                ...this.getModified(),
            }).$promise.then(() => {
                shiftEvents.trigger.updated();
            });
        }

        decline() {
            return ScheduleService.decline(this).$promise.then(() => {
                this.setProperty({
                    key: 'auditors_notified',
                    value: false,
                });
            });
        }

        updateProperty(name: any, value: any) {
            return ScheduleService.updateProperty(this.customer, this, name, value).$promise.then((data: any) => {
                this.setProperty(data);
            });
        }

        deleteProperty(name: any) {
            return ScheduleService.deleteProperty(this.customer_id, this.id, name).then(() => this.setProperty({
                key: name,
                value: null,
            }));
        }

        getWarnings() {
            const warnings = [] as any;
            this.getShifts()?.forEach((s: any) => {
                s.warnings?.forEach((w: any) => {
                    w.object = s;
                    warnings.push(w);
                });
            });
            return warnings;
        }

        hasWarnings() {
            return this.getWarnings().length;
        }

        warningsOn(date: any) {
            return this.getWarnings().filter((w: any) => date.isSame(w.business_date, 'd'));
        }

        hasAuditors() {
            return !!find(this.customer.properties, { key: 'schedule_auditor_group_id' });
        }

        notifyAuditors() {
            return ScheduleService.notifyAuditors(this).$promise.then(() => {
                this.setProperty({
                    key: 'auditors_notified',
                    value: true,
                });
            });
        }

        /**
         * @param {Shift} shift
         * @param {String} event
         */
        onShiftSaved(shift: any, event: any) {
            if (!shift?.id) {
                return;
            }
            if (this.getShift(shift.id) && event === 'created') {
                return;
            }
            this.removeShift(this.getDummy(shift)?.id);
            this.removeShift(shift.id);
            this.insertShift(shift);
            shiftEvents.trigger[event](shift);
        }

        async setSettings() {
            const settingPrefix = 'schedule:setting:';
            let properties: Property[] = [];

            try {
                properties = (await lastValueFrom(UserPropertyDowngraded.getAll(CurrentOld.user['id'], { filter: settingPrefix }))).data;
            } catch (_) {
                // Nothing
            }

            properties.forEach((p) => {
                let value = p.value.asString() as SettingValue;
                const booleanValue = p.value.asBoolean();

                // All values are kept as strings, but for several settings we need booleans.
                // If explicitly set as true or false then use that boolean value.
                if (typeof booleanValue === 'boolean') {
                    value = booleanValue;
                }

                this.settings[p.key.replace(settingPrefix, '')] = value;
            });
        }

        static isShift(shift: any) {
            const isShift = shift instanceof Shift;
            if (!isShift) {
                console.error('Invalid Argument: shift must be an instance of Shift');
            }
            return isShift;
        }

        static override getMorphClass() {
            return 'schedule';
        }
    }

    return Schedule;
} ]);
