import { Component, Inject, OnInit } from '@angular/core';
import { TodoStatusService } from '../../http/todo-status.service';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { TodoStatus } from '../../models/todo-status';
import { TodoStatusType } from '../../enums/todo-status-type';
import { TranslateService } from '../../../shared/services/translate.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { SubheaderComponent } from '../../../shared/components/subheader/subheader.component';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface TodoStatusSelectorDialogData extends DialogData {
    customerId: number;
}

@Component({
    selector: 'eaw-status-selector-dialog',
    templateUrl: './status-selector-dialog.component.html',
    styleUrl: './status-selector-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        MatListModule,
        NgFor,
        SubheaderComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class StatusSelectorDialogComponent extends DialogComponent implements OnInit {
    loading = true;
    statuses: {type: TodoStatusType, translation: Promise<string>, statuses: TodoStatus[]}[] = [
        {
            type: TodoStatusType.Default,
            translation: this.translateService.t('DEFAULT_STATUS', 'todo'),
            statuses: [],
        },
        {
            type: TodoStatusType.InProgress,
            translation: this.translateService.t('IN_PROGRESS_TYPE', 'todo'),
            statuses: [],
        },
        {
            type: TodoStatusType.Done,
            translation: this.translateService.t('DONE_TYPE', 'todo'),
            statuses: [],
        },
    ];

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<StatusSelectorDialogComponent, TodoStatus>,
        @Inject(MAT_DIALOG_DATA) override data: TodoStatusSelectorDialogData,
        @Inject(TodoStatusService) private todoStatusService: TodoStatusService,
        @Inject(TranslateService) private translateService: TranslateService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit(): void {
        this.todoStatusService.getAll(this.data.customerId).subscribe((res) => {
            res.data.forEach((status) => {
                this.statuses.find((s) => s.type === status.type)?.statuses.push(status);
            });
        });
    }
}
