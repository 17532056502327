<eaw-page-header>
    <span title>{{ 'PRODUCT_plural' | translate : 'admin' | async }}</span>
</eaw-page-header>

<mat-card>
    <eaw-data-table
            [columns]="columns()"
            [goTo]="{state: 'eaw/app/admin/products/product', params: [{stateKey: 'name', itemKey: 'name'}]}"
            [getData]="getData()"
            initialSortBy="subscribers_count">
    </eaw-data-table>
</mat-card>
