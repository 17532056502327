<mat-card class="tw-mb-16">
    <mat-card-header class="tw-p-16">
        <mat-card-subtitle class="tw-flex tw-justify-between">
            <span class="tw-pr-16">{{ 'CUSTOMER_plural' | translate : 'admin' | async }}</span>
            {{ details()?.customersCount }}
        </mat-card-subtitle>
        <mat-card-subtitle class="tw-flex tw-justify-between">
            <span class="tw-pr-16">{{ 'GROUP_plural' | translate : 'admin' | async }}</span>
            {{ details()?.groupsCount }}
        </mat-card-subtitle>
        <mat-card-subtitle class="tw-flex tw-justify-between">
            <span class="tw-pr-16">{{ 'TOTAL_CUSTOMERS' | translate : 'admin' | async }}</span>
            {{ details()?.totalCustomersCount }}
        </mat-card-subtitle>
        <mat-card-subtitle class="tw-flex tw-justify-between">
            <span class="tw-pr-16">{{ 'USER_plural' | translate : 'admin' | async }}</span>
            {{ details()?.usersCount }}
        </mat-card-subtitle>
    </mat-card-header>
</mat-card>

<eaw-page-header>
    <span title>{{ subscriber }} {{ 'SUBSCRIBER' | translate : 'admin': { count: details()?.total } | async }}</span>
</eaw-page-header>

<mat-card>
    <eaw-data-table
            [columns]="columns()"
            [getData]="getData()"
            (rowClick)="onNavigateTo($event)"
            initialSortBy="subscriber_id">
    </eaw-data-table>
</mat-card>
