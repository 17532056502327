import { Component, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { MatListModule, MatSelectionList } from '@angular/material/list';
import { ScheduleService } from '../../http/schedule.service';
import { Schedule } from '../../models/schedule';
import { DateTime } from 'luxon';
import { TranslateService } from '../../../shared/services/translate.service';
import { Namespace } from '../../../shared/enums/namespace';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { ShiftService } from '../../http/shift.service';
import { catchError, EMPTY, forkJoin } from 'rxjs';
import { Shift } from '../../models/shift';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { SubheaderComponent } from '../../../shared/components/subheader/subheader.component';
import { SnackBarService } from '../../../shared/services/snack-bar.service';

export type ShiftCopyDialogResult = { copied: boolean };

export interface ShiftCopyDialogData extends DialogData {
    customerId: number;
    scheduleId: number;
    shiftId: number;
}

interface OptionItem {
    title: Promise<string>;
    subtitle: Promise<string>;
    value: DateTime;
    disabled: boolean;
}

@Component({
    selector: 'eaw-shift-copy-dialog',
    standalone: true,
    imports: [ CommonModule, DialogHeaderComponent, MatDialogContent, MatListModule, InfoLoadingComponent, MatDialogActions, ActionButtonComponent, MatButtonModule, MatDialogClose, TranslatePipe, SubheaderComponent ],
    templateUrl: './shift-copy-dialog.component.html',
    styleUrl: './shift-copy-dialog.component.scss',
})
export class ShiftCopyDialogComponent extends DialogComponent implements OnInit {
    private scheduleService = inject(ScheduleService);
    private shiftService = inject(ShiftService);
    private translateService = inject(TranslateService);
    private snackBarService = inject(SnackBarService);

    @ViewChild(MatSelectionList) selectionList?: MatSelectionList;

    protected loading = true;
    protected copying = false;
    protected options: OptionItem[] = [];
    protected shift?: Shift;

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ShiftCopyDialogComponent, ShiftCopyDialogResult>,
        @Inject(MAT_DIALOG_DATA) override data: ShiftCopyDialogData,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnInit() {
        forkJoin([
            this.scheduleService.get(this.data.customerId, this.data.scheduleId),
            this.shiftService.get(this.data.customerId, this.data.shiftId),
        ]).subscribe(([ schedule, shift ]) => {
            this.shift = shift;

            if (schedule.isTemplate) {
                this.getTemplateDays(schedule, shift);
            } else {
                this.getDefaultDays(schedule, shift);
            }

            this.loading = false;
        });
    }

    get selectedDays(): DateTime[] {
        return this.selectionList?.selectedOptions.selected.map((option) => option.value) || [];
    }

    getTemplateDays(schedule: Schedule, shift: Shift) {
        let index = 0;
        let from = schedule.from;
        const to = schedule.to.minus({ second: 1 });

        while (from <= to) {
            index += 1;

            this.options.push({
                title: this.translateService.t('DAY_N', Namespace.General, { number: index }),
                subtitle: Promise.resolve(from.toFormat('cccc')),
                value: from,
                disabled: from.hasSame(shift.from, 'day'),
            });

            from = from.plus({ days: 1 });
        }
    }

    getDefaultDays(schedule: Schedule, shift: Shift) {
        let from = schedule.from;
        const to = schedule.to.minus({ second: 1 });

        while (from <= to) {
            this.options.push({
                title: Promise.resolve(from.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)),
                subtitle: this.translateService.t('WEEK_N', Namespace.General, { number: from.weekNumber }),
                value: from,
                disabled: from.hasSame(shift.from, 'day'),
            });

            from = from.plus({ days: 1 });
        }
    }

    copy() {
        const shiftFrom = this.shift?.from;
        const shiftOffset = this.shift?.offset;
        if (shiftFrom == null || shiftOffset == null) {
            return;
        }

        const shiftIndex = this.options.findIndex((option) => option.value.hasSame(shiftFrom, 'day'));
        if (shiftIndex === -1) {
            return;
        }

        const offsets = this.selectedDays.reduce((acc, day) => {
            const index = this.options.findIndex((option) => option.value.hasSame(day, 'day'));
            if (index === -1) {
                return acc;
            }

            return acc.concat(shiftOffset + ((index - shiftIndex) * 86_400));
        }, [] as number[]);

        this.copying = true;
        this.shiftService.copy(this.data.customerId, this.data.scheduleId, this.data.shiftId, offsets).pipe(
            catchError(() => {
                this.copying = false;
                return EMPTY;
            }),
        ).subscribe(() => {
            this.dialogRef.close({ copied: true });
            void this.snackBarService.t('SHIFT_COPIED_X', Namespace.Scheduling, { count: offsets.length });
        });
    }
}
