import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Injectable({
    providedIn: 'root',
})
export class BlurElementHookService {
    constructor(
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
        this.uiRouter.transitionService.onStart({}, (transition) => {
            console.debug('🪝', 'BlurElementHookService.onStart', transition);

            if (transition.$from().name !== transition.$to().name && document.activeElement instanceof HTMLInputElement) {
                document.activeElement.blur();
            }
        });
    }
}
