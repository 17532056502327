import { Component, Inject, Input } from '@angular/core';
import type { Observable } from 'rxjs';
import type { ArrayPaginatedResponse } from '../../interfaces/paginated-response';
import type { User } from '../../models/user';
import { CustomerUserService } from '../../http/customer-user.service';
import { TranslateService } from '../../services/translate.service';
import { of } from 'rxjs';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { AutocompleteDeprecatedComponent } from '../autocomplete-deprecated/autocomplete-deprecated.component';

@Component({
    selector: 'eaw-customer-user-autocomplete',
    templateUrl: './customer-user-autocomplete.component.html',
    styleUrl: './customer-user-autocomplete.component.scss',
    standalone: true,
    imports: [ AutocompleteDeprecatedComponent, ProfilePictureComponent ],
})
export class CustomerUserAutocompleteComponent {
    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) controlName!: string;
    @Input() label = this.translate.t('USER');

    observable?: Observable<ArrayPaginatedResponse<User>>;
    limit = 10;
    changeObservable = (value: unknown) => {
        return Number.isInteger(value) ? this.customerUserService.get(this.customerId, value as number) : of(value);
    };

    constructor(
        @Inject(CustomerUserService) private customerUserService: CustomerUserService,
        @Inject(TranslateService) private translate: TranslateService,
    ) {}

    updateUsers(filter?: string) {
        this.observable = this.customerUserService.getAll(this.customerId, {
            filter,
            page: 1,
            per_page: this.limit,
        });
    }
}
