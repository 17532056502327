import { inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { HrFileType, HrFileTypeResponse } from '../models/hr-file-type';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';

export interface CreateHrFileTypeOptions {
    name: string;
    mandatory?: boolean;
    accept_file_types?: string[]|null;
}

export type UpdateHrFileTypeOptions = Partial<CreateHrFileTypeOptions>;

interface GetAllOptions extends PaginationOptions {
    include_signable?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class HrFileTypeService {
    private readonly http = inject(HttpClient);

    create(customerId: number, options: CreateHrFileTypeOptions): Observable<HrFileType> {
        return this.http.post<HrFileTypeResponse>(`/customers/${customerId}/hr_file_types`, options).pipe(classifyItem(HrFileType));

    }

    getAll(customerId: number, options?: GetAllOptions): Observable<ArrayPaginatedResponse<HrFileType>> {
        return this.http.get<ArrayPaginatedResponse<HrFileTypeResponse>>(`/customers/${customerId}/hr_file_types`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(HrFileType));
    }

    get(customerId: number, fileTypeId: number):Observable<HrFileType> {
        return this.http.get<HrFileTypeResponse>(`/customers/${customerId}/hr_file_types/${fileTypeId}`).pipe(classifyItem(HrFileType));
    }

    update(customerId: number, fileTypeId: number, options: UpdateHrFileTypeOptions): Observable<HrFileType> {
        return this.http.put<HrFileTypeResponse>(`/customers/${customerId}/hr_file_types/${fileTypeId}`, options).pipe(classifyItem(HrFileType));
    }

    delete(customerId: number, fileTypeId: number) {
        return this.http.delete(`/customers/${customerId}/hr_file_types/${fileTypeId}`);
    }
}
