import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CustomerAutocompleteService } from '../../../shared/autocompletes/customer-autocomplete.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { Customer } from '../../../shared/models/customer';
import { AutoCompleteData } from '../../../shared/autocompletes/autocomplete';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-choose-customer-dialog',
    templateUrl: './choose-customer-dialog.component.html',
    styleUrl: './choose-customer-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        AutocompleteComponent,
        ReactiveFormsModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ChooseCustomerDialogComponent extends DialogComponent {
    customerControl = new FormControl<null | Customer>(null, Validators.required);
    options: AutoCompleteData<Customer> = {
        label: this.translateService.t('CUSTOMER'),
        optionTemplate: 'default',
        filterRequirement: 3,
        display: 'name',
        trackByKey: 'id',
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: DialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ChooseCustomerDialogComponent, Customer>,
        @Inject(CustomerAutocompleteService) public customerAutocompleteService: CustomerAutocompleteService,
        @Inject(TranslateService) protected translateService: TranslateService,
    ) {
        super(dialogRef, { size: DialogSize.Medium });
    }

    close() {
        const customer = this.customerControl.value;
        if (customer) {
            this.dialogRef.close(customer);
        }
    }
}
