// @ts-nocheck
import { module } from 'angular';
class EawProfilePictureOld {
    $element;
    static get $inject() {
        return [ '$element' ];
    }

    user;
    size = 35;
    square = false;
    thumb = true;
    profilePictureUser;
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        if (this.user?.id) {
            this.profilePictureUser = {
                id: this.user.id,
                name: this.user.name || '',
                profilePictureId: this.user.profile_picture_id,
            };
        }
        this.$element[0].style.width = `${this.size}px`;
        this.$element[0].style.height = `${this.size}px`;
    }
}
module('eaw.components').component('eawProfilePictureOld', {
    template: `<eaw-profile-picture-downgraded
        [angularjssize]="$ctrl.size"
        [angularjsthumb]="$ctrl.thumb"
        [angularjsappearance]="$ctrl.square ? 'square' : 'circle'"
        [angularjsuser]="$ctrl.profilePictureUser">
</eaw-profile-picture-downgraded>
`,
    bindings: {
        user: '<',
        size: '@?',
        square: '<?',
        thumb: '<?',
    },
    controllerAs: '$ctrl',
    controller: EawProfilePictureOld,
});
