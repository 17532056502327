import { User } from './user';
import { MeUserResponse } from './me';

export class MeUser extends User {
    constructor(data: MeUserResponse) {
        super({
            ...data,
        });
    }
}
