// @ts-nocheck
import { module } from 'angular';
import moment from 'moment-timezone';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
module('eaw.scheduling').factory('SchedulePublish', [ '$rootScope', function($rootScope) {
    class SchedulePublish {
        schedule;
        updatePermission;
        publishPermission;
        forcePublishPermission;
        unPublishPermission;
        constructor(schedule) {
            this.schedule = schedule;
            const permissionBase = `customers.${schedule.customer_id}.schedules.${schedule.id}`;
            this.updatePermission = `${permissionBase}.update`;
            this.publishPermission = `${permissionBase}.publish`;
            this.forcePublishPermission = `${permissionBase}.force_publish`;
            this.unPublishPermission = `${permissionBase}.unpublish`;
        }

        notifyAuditors() {
            $rootScope.$broadcast('schedule:notifyAuditors');
        }

        publish() {
            $rootScope.$broadcast('schedule:publish');
        }

        unpublish() {
            $rootScope.$broadcast('schedule:unPublish');
        }

        isPublishable() {
            return !this.schedule.is_published && !this.schedule.is_template;
        }

        canPublish() {
            return this.isPublishable() && (CurrentOld.can(this.publishPermission) || CurrentOld.can(this.forcePublishPermission));
        }

        canNotify() {
            return this.isPublishable() && CurrentOld.can(this.updatePermission) && !this.isNotified() && this.schedule.hasAuditors();
        }

        canUnPublish() {
            const from = this.schedule.from.clone();
            return this.schedule.is_published && moment().isBefore(from.subtract(2, 'weeks')) && CurrentOld.can(this.unPublishPermission);
        }

        isNotified() {
            return !!parseInt(this.schedule.getProperty('auditors_notified', 0));
        }

        canDecline() {
            return this.canPublish() && this.schedule.hasAuditors() && this.isNotified();
        }
    }
    return SchedulePublish;
} ]);
