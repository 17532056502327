<eaw-dialog-header>
    @if (dataSource && total) {
        <span title>{{ 'UNREAD_NOTIFICATION' | translate:'general':{count: total} | async }}</span>
    }
    @if (!dataSource || total === 0) {
        <span title>{{ 'NOTIFICATION_plural' | translate | async }}</span>
    }
</eaw-dialog-header>

<mat-dialog-content>
    @if (!dataSource) {
        <div id="spinner-box" class="tw-flex tw-flex-row tw-justify-center tw-items-center">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
    }

    @if (dataSource && total === 0) {
        <div id="win-box" class="tw-flex tw-flex-col tw-justify-center tw-items-center">
            <span class="mat-headline-1 tw-mb-20">🏆</span>
            <span class="mat-body-2">{{ 'NO_NOTIFICATIONS' | translate | async }}</span>
        </div>
    }

    @if (dataSource && total) {
        <cdk-virtual-scroll-viewport itemSize="100" class="notification-viewport">
            <div *cdkVirtualFor="let item of dataSource; let index = index" class="notification-item" [class.unread]="item && !item.notification.readAt">
                @if (item) {
                    <a class="notification-content" (click)="click(item, index)">
                        <div class="text">
                            <div class="mat-subtitle-2">{{ item.notification.title }}</div>
                            <div class="mat-body-2">{{ item.notification.body }}</div>
                            <div class="relative-time">
                                {{ item.notification.createdRelative }}
                            </div>
                            <div>
                                {{item.customerName}}

                                @if (!item.access) {
                                    <span class="no-access">{{ 'NO_ACCESS_TO_LOCATION' | translate:'notifications' | async }}</span>
                                }
                            </div>
                        </div>

                        <div class="circle"></div>
                    </a>
                } @else {
                    <div class="loading">{{ 'LOADING' | translate | async }}</div>
                }
            </div>
        </cdk-virtual-scroll-viewport>
    }
</mat-dialog-content>

<mat-dialog-actions class="tw-flex tw-flex-row tw-justify-center" [class]="total ? 'sm:tw-justify-between' : 'sm:tw-justify-end'">
    @if (total) {
        <button mat-button (click)="markAllAsRead()">
            {{ 'MARK_ALL_READ' | translate | async }}
        </button>
    }
    <button mat-button mat-dialog-close="" (click)="goToAll()">
        {{ 'SEE_ALL_NOTIFICATIONS' | translate | async }}
    </button>
</mat-dialog-actions>
