import { MediaChromeSelectMenu } from "./media-chrome-selectmenu.js";
import "./media-rendition-button.js";
import "./media-rendition-listbox.js";
import { MediaUIAttributes } from "../constants.js";
import { globalThis, document } from "../utils/server-safe-globals.js";
class MediaRenditionSelectMenu extends MediaChromeSelectMenu {
  static get observedAttributes() {
    return [...super.observedAttributes, MediaUIAttributes.MEDIA_RENDITION_SELECTED, MediaUIAttributes.MEDIA_RENDITION_UNAVAILABLE];
  }
  init() {
    const renditionButton = document.createElement("media-rendition-button");
    renditionButton.part.add("button");
    renditionButton.preventClick = true;
    const renditionListbox = document.createElement("media-rendition-listbox");
    renditionListbox.part.add("listbox");
    renditionListbox.setAttribute("exportparts", "option, option-selected, indicator");
    const buttonSlot = this.shadowRoot.querySelector("slot[name=button]");
    const listboxSlot = this.shadowRoot.querySelector("slot[name=listbox]");
    buttonSlot.textContent = "";
    listboxSlot.textContent = "";
    buttonSlot.append(renditionButton);
    listboxSlot.append(renditionListbox);
  }
}
if (!globalThis.customElements.get("media-rendition-selectmenu")) {
  globalThis.customElements.define("media-rendition-selectmenu", MediaRenditionSelectMenu);
}
var media_rendition_selectmenu_default = MediaRenditionSelectMenu;
export { MediaRenditionSelectMenu, media_rendition_selectmenu_default as default };