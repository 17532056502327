import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { ScheduleResponse } from '../models/base-schedule';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { ScheduleTemplate } from '../models/schedule-template';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { DateTime } from 'luxon';
import { overlapsDst } from '../../shared/utils/eaw-dst';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';

interface GetAllOptions extends PaginationOptions {
    from?: DateTime;
    to?: DateTime;
}

interface DefaultCreateBody {
    from: DateTime;
    length: number;
    name: string;
}

interface UpdateBody {
    name?: string;
    from?: DateTime,
}

@Injectable({
    providedIn: 'root',
})
export class ScheduleTemplateService {
    constructor(@Inject(HttpClient) protected http: HttpClient) {
    }

    getAll(customerId: number, options?: GetAllOptions): Observable<ArrayPaginatedResponse<ScheduleTemplate>> {
        return this.http.get<ArrayPaginatedResponse<ScheduleResponse>>(`customers/${customerId}/schedules`, {
            params: formatHttpParams({
                ...options,
                template: true,
            }),
        }).pipe(classifyArrayPaginatedResponse(ScheduleTemplate));
    }

    createDefaultTemplate(customerId: number, body: DefaultCreateBody): Observable<ScheduleTemplate> {
        const startDate = body.from;
        const endDate = startDate.plus({ second: body.length });

        if (overlapsDst(startDate, endDate)) {
            body.from = body.from.plus({
                second: body.length, // Move schedule past DST change
                minute: startDate.offset - endDate.offset, // Adjust for DST
            });
        }

        return this.http.post<ScheduleResponse>(`customers/${customerId}/schedules`, {
            ...body,
            is_template: true,
        }).pipe(classifyItem(ScheduleTemplate));
    }

    copy(customerId: number, scheduleId: number, name: string, from: DateTime): Observable<ScheduleTemplate> {
        return this.http.post<ScheduleResponse>(`customers/${customerId}/schedules`, {
            template_id: scheduleId,
            is_template: true,
            name,
            from,
        }).pipe(classifyItem(ScheduleTemplate));
    }

    update(customerId: number, scheduleId: number, body: UpdateBody): Observable<ScheduleTemplate> {
        return this.http.put<ScheduleResponse>(`customers/${customerId}/schedules/${scheduleId}`, {
            ...body,
        }).pipe(classifyItem(ScheduleTemplate));
    }

    delete(customerId: number, scheduleId: number) {
        return this.http.delete(`customers/${customerId}/schedules/${scheduleId}`);
    }
}
