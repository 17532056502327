// @ts-nocheck
import { module } from 'angular';

module('eaw.checklists').controller('checklistChildMenuBottomSheetCtrl', [ '$mdBottomSheet', function($mdBottomSheet) {
    const ctrl = this;

    ctrl.itemClick = (item) => {
        $mdBottomSheet.hide(item);
    };
} ]);
