// @ts-nocheck
import { t } from 'i18next';
import { find } from 'lodash-es';
import { module } from 'angular';
import { pick } from 'lodash-es';

module('eaw.absence').component('shiftsDuringAbsence', {
    template: `<md-card flex="100" ng-if="$during.inProgress">
    <md-card-content class="tw-h-full" layout-padding>
        <div class="tw-h-full" layout="column" layout-align="center center">
            <p class="empty-text tw-pb-12" layout="column" layout-align="center center" ng-i18next="scheduling:SPLITTING_SHIFTS_PLEASE_WAIT"></p>
            <md-progress-circular></md-progress-circular>
        </div>
    </md-card-content>
</md-card>

<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-bind="$during.title"></span>
        </md-card-header-text>
    </md-card-header>
    <md-card-content>
        <eaw-date-time-interval
                required
                layout-gt-sm="row"
                layout="column"
                ng-change="$during.updateInterval()"
                ng-model="$during.interval"
                min-date="$during.absence.from"
                max-date="$during.absence.to">
        </eaw-date-time-interval>
    </md-card-content>
</md-card>

<split-shifts
        ng-if="!$during.inProgress"
        on-saved="$during.onSaved()"
        model="{className: $during.modelName, key: $during.absence.id}"
        from="$during.from"
        to="$during.to"
        customer="$during.customer"
        employee="$during.employee">
</split-shifts>
`,
    controllerAs: '$during',
    bindings: {
        absence: '<',
        employee: '<',
        customer: '<',
        editTab: '<',
    },
    controller: [ '$filter', '$state', 'ToastService', '$mdDialog', function($filter, $state, ToastService, $mdDialog) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.title = t('absences:ABSENCE_PERCENT', {
                name: ctrl.absence.employee.name,
                percent: $filter('percent')(ctrl.absence.grade),
            });
            ctrl.interval = pick(ctrl.absence, [ 'from', 'to' ]);
            ctrl.inProgress = !!find(ctrl.absence.properties, { key: 'progress' });
            ctrl.modelName = 'Modules\\Absence\\Models\\Absence';
        };

        ctrl.onSaved = () => {
            if (ctrl.absence.approved) {
                return;
            }
            const dialog = $mdDialog.confirm()
                .title(t('PROCESSING'))
                .htmlContent(`<p>${t('scheduling:SPLITTING_SHIFTS_PLEASE_WAIT')}</p><p>${t('absences:APPROVE_THE_ABSENCE_QUESTION')}</p>`)
                .ok(t('APPROVE'))
                .cancel(t('NO'));
            $mdDialog.show(dialog).then(() => {
                ctrl.absence.release_shifts = 'keep';
                ctrl.absence.approve().$promise.then((absence) => {
                    ToastService.toast(t('APPROVED'));

                    return $state.go(ctrl.editTab, {
                        employee_id: absence.employee_id,
                        id: absence.id,
                    }, { reload: true });
                });
            });
        };

        ctrl.updateInterval = () => {
            ctrl.from = ctrl.interval.from?.clone();
            ctrl.to = ctrl.interval.to?.clone();
        };
    } ],
});
