// @ts-nocheck
import { orderBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';

module('eaw.absence').component('eawAbsenceCalendar', {
    template: `<md-card ng-show="$abCal.absenceTypes.length">
    <md-card-content id="absence-calendar-legend">
        <div class="calendar-legend overflow-ellipsis"
             ng-repeat="type in $abCal.absenceTypes track by type.id"
             ng-style="{'border-left-color': type.color}"
             ng-class="{'disabled': type.disabled}"
             ng-click="$abCal.toggleDisabled(type)"
             ng-bind="type.name">
        </div>
    </md-card-content>
</md-card>

<eaw-calendar-old customer="$abCal.customer"
              use-sources="['absences', 'offtimes', 'holidays']"
              use-modes="['y']"
              event-display-func="$abCal.calDisplayFunc"
              create-source-name="'absences'">
</eaw-calendar-old>
`,
    bindings: {
        customer: '<',
    },
    controllerAs: '$abCal',
    controller: [ 'AbsenceTypeFactory', 'OffTimeDataSource', function(AbsenceTypeFactory, OffTimeDataSource) {
        const ctrl = this;

        ctrl.$onInit = () => {
            AbsenceTypeFactory.getAll({
                entityId: ctrl.customer.id,
                per_page: 9999,
            }).then((resp) => {
                ctrl.absenceTypes = orderBy(resp.data, 'name', 'asc');

                const offTime = OffTimeDataSource.dataToCalendar({
                    from: moment(),
                    to: moment().add(1, 'd'),
                });
                ctrl.absenceTypes.unshift({
                    color: offTime.backgroundColor,
                    name: OffTimeDataSource.getI18nName(),
                });
            });
        };

        ctrl.toggleDisabled = (type) => {
            type.disabled = !type.disabled;
        };

        ctrl.calDisplayFunc = (calEvent) => {
            if (calEvent.dataSource === 'offtimes' && ctrl.absenceTypes[0].disabled) {
                return false;
            }

            if (calEvent.absenceType?.id) {
                return ctrl.absenceTypes
                    .filter((at) => !at.disabled)
                    .some((at) => at.id === calEvent.absenceType.id);
            }

            return true;
        };
    } ],
});
