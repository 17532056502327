@if (loadingData) {
    <eaw-info-loading size="sm">
        <span>{{ 'LOADING_REQUIRED_CONTRACT_INFO' | translate:'new_employee' | async }}</span>
    </eaw-info-loading>
} @else {
    @if (contract) {
        <table>
            <tr>
                <td>
                    <span>{{ 'TITLE' | translate | async }}</span>
                </td>
                <td>
                    <span>{{ contract.title }}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>{{ 'TYPE' | translate | async }}</span>
                </td>
                <td>
                    <span>{{ contract.type?.name }}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>{{ 'CONTRACT_AMOUNT_TYPE' | translate:'company' | async }}</span>
                </td>
                <td>
                    <span>{{ contract.amountType | uppercase | translate | async }}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>{{ 'CONTRACT_AMOUNT' | translate:'company' | async }}</span>
                </td>
                <td>
                    <span>{{ contract.amount | eawNumber }}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>{{ 'FROM' | translate | async }}</span>
                </td>
                <td>
                    <span>{{ contract.from | DateTime:'DATE_MED' }}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>{{ 'TO' | translate | async }}</span>
                </td>
                <td>
                    <span>{{ contract.to | DateTime:'DATE_MED' }}</span>
                </td>
            </tr>
        </table>

        <div class="tw-flex tw-gap-16">
            <eaw-action-button [loading]="processing" (click)="edit()">{{ 'EDIT_CONTRACT_INFO' | translate:'new_employee' | async }}</eaw-action-button>
            <eaw-action-button color="warn" [loading]="processing" (click)="delete()">{{ 'DELETE_CONTRACT_INFO' | translate:'new_employee' | async }}</eaw-action-button>
        </div>
    } @else {
        <span>{{ 'EMP_NO_CONTRACT' | translate:'new_employee':{name: employee.name} | async }}</span>

        <eaw-action-button [loading]="false" (click)="open()">{{ 'ADD_CONTRACT_INFO' | translate:'new_employee' | async }}</eaw-action-button>
    }
}

<eaw-new-employee-next-step [stepper]="stepper" [step]="step"/>
