import { Component, Inject, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Location } from '../../services/geocoding.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgIf } from '@angular/common';

@Component({
    selector: 'eaw-map',
    templateUrl: './map.component.html',
    styleUrl: './map.component.scss',
    standalone: true,
    imports: [ NgIf, GoogleMapsModule ],
})

export class MapComponent implements OnChanges {
    @Input() center?: Location;
    @Input() mapOptions?: google.maps.MapOptions;

    apiIsLoaded = false;
    place: google.maps.LatLng | undefined;

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
    ) {
        this.http.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.googleAPIKey}`, 'callback').pipe(
            catchError(() => EMPTY),
            map(() => {
                if (this.center) {
                    this.place = new google.maps.LatLng(this.center.lat, this.center.lng, true);
                }

                this.apiIsLoaded = true;
            }),
        ).subscribe();
    }

    ngOnChanges() {
        if (this.center && this.apiIsLoaded) {
            this.place = new google.maps.LatLng(this.center.lat, this.center.lng, true);
        }
    }
}
