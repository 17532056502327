import { t } from 'i18next';
import { debounce } from 'lodash-es';
import { uniqueId } from '../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { forEach } from 'lodash-es';
import { sortBy } from 'lodash-es';
import { orderBy } from 'lodash-es';
import { groupBy } from 'lodash-es';
import { Storage } from '../../../../../../shared/utils/storage';
import { module } from 'angular';
import { TinyColor } from '@ctrl/tinycolor';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';

module('eaw.scheduling').factory('ShiftsTableRenderer', [ 'UnitFilter', 'colorpickerService', '$filter', 'intervalService', 'scheduleMode', function ShiftsTableRenderer(UnitFilter: any, colorpickerService: any, $filter: any, intervalService: any, scheduleMode: any) {
    // @ts-ignore
    const service = this;

    service.convertSecondsToPx = (seconds: any) => `${seconds * intervalService.getOneSecondPixelLength()}px`;

    service.getUnassignedName = (shift: any) => {
        const unassigned = t('scheduling:UNASSIGNED');
        if (!shift?.periods?.length) {
            return unassigned;
        }
        if (!CurrentOld.hasProduct('france')) {
            return unassigned;
        }
        if (!service.schedule.getProperty('france_mandatory_needs')?.value) {
            return unassigned;
        }
        return shift?.periods?.[0]?.description;
    };
    service.getShiftLineName = (shift: any) => {
        const name = service.schedule.settings.showNicknames ? (shift?.employee?.nickname || shift?.employee?.name) : shift?.employee?.name;
        return name || service.getUnassignedName(shift);
    }
    service.getWarningHtml = (shift: any) => {
        if (shift?.warnings?.length) {
            return `<div class="warning-indicator"></div>`;
        }
        return '';
    };
    service.getCommentHtml = (shift: any) => {
        if (shift?.comments_count || shift?.comments?.length) {
            return `<div class="comment-indicator"></div>`;
        }
        return '';
    };
    service.getPeriodLinesHtml = (shift: any) => {
        let html = '';
        const unitGrouping = service.schedule.settings.shiftGrouping === 'businessUnits';
        const dragClass = scheduleMode.getMode() === 'periodDrag' ? 'period-drag' : '';
        // Show shortest periods on the top
        // And remove business units if we are grouped by business units
        const periods = orderBy(shift.periods || [], 'length', 'desc').filter((p) => {
            if (unitGrouping) {
                return !p.business_unit;
            }
            return true;
        });
        // Concat breaks at the end, so they always stay at the top
        periods.forEach((period, index) => {
            const unproductiveClass = period.unproductive ? 'background-stripes' : '';
            const color = colorpickerService.getHex(period.color);
            // The period needs to have the "background-color", not "background", css property in case the stripes are applied
            html += `<div data-shift-id="${shift.id}" data-shift-period-id="${period.id}"
                class="period-line ${dragClass} ${unproductiveClass}"
                style="z-index: ${index - periods.length}; background-color: ${color}; left: ${service.convertSecondsToPx(period.offset)}; width: ${service.convertSecondsToPx(period.length)};">
            </div>`;
        });
        return html;
    };
    service.getDuration = (shift: any) => $filter('eawDuration')(shift.net_length);

    service.getShiftLineUnit = (shift: any) => {
        const name = shift?.periods?.[0]?.business_unit?.name;
        return name ? `(${name})` : '';
    };
    service.getShiftFromTo = (shift: any) => {
        if (!shift.from) {
            return '';
        }
        return `${shift.from.format('LT')} - ${shift.to.format('LT')}`;
    };
    service.getShiftLineTextColor = (shift: any) => {
        const hasBlackPeriod = shift.periods?.some((p: any) => {
            if (p.color instanceof TinyColor) {
                return p.color.getLuminance() < 0.1;
            }
            if (typeof p.color === 'string') {
                return p.color.startsWith('black');
            }
            return false;
        });
        return hasBlackPeriod ? 'white-text' : 'black-text';
    };
    service.removeShiftHtml = (shiftId: number) => {
        document.querySelector(`.shift-line[data-shift-id="${shiftId}"]`)?.closest('tbody')?.remove();
        service.createObserver();
    };
    service.updateShiftHtml = (shift: any) => {
        const el = document.querySelector(`.shift-line[data-shift-id="${shift?.id}"]`);
        if (el && el.parentElement) {
            el.parentElement.innerHTML = service.getShiftLineHtml(shift);
            service.createObserver();
        }
    };
    service.getCogSvg = () => `<svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
                </svg>`;
    service.getShiftLineHtml = (shift: any) => {
        const color = service.getShiftLineTextColor(shift);
        const employeeId = shift.employee_id || `unassigned${shift.id}`;
        const left = service.convertSecondsToPx(shift.offset - service.schedule.render.offset);
        const width = service.convertSecondsToPx(shift.length);

        let html = `<div class="shift-line ${color} ${shift.status ? 'disabled' : ''}" data-employee-id="${employeeId}" data-shift-id="${shift.id}" data-order="${shift.index}" style="left: ${left}; width: ${width}">`;

        if (shift.status) {
            html += `<span class="shift-status ${shift.status}">${service.getCogSvg()} ${t(`scheduling:SHIFT_STATUS_${shift.status.toUpperCase()}`)}</span>`;
        } else if (service.schedule.settings.expandedShifts) {
            html += `
                <div class="line-top">
                    <div class="shift-info">
                        <div>
                            <span class="employee-name">${service.getShiftLineName(shift)}</span>
                            <span class="employee-unit">${service.getShiftLineUnit(shift)}</span>
                        </div>
                        <div class="shift-time">${service.getDuration(shift)} (${service.getShiftFromTo(shift)})</div>
                    </div>
                    ${service.getCommentHtml(shift)}
                    ${service.getWarningHtml(shift)}
                </div>
         
                <div class="line-bottom">
                    ${service.getPeriodLinesHtml(shift)}
                </div>
                `;
        } else {
            html += `
                ${service.getCommentHtml(shift)}
                ${service.getWarningHtml(shift)}
                
                <span class="shift-line-name">${service.getShiftLineName(shift)}</span>
                <span class="shift-line-duration">${service.getDuration(shift)}</span>
                    
                ${service.getPeriodLinesHtml(shift)}
                `;
        }
        return `${html}</div>`;
    };
    service.getGroupingKey = () => service.schedule.settings.shiftGrouping === 'employee' ? 'shift_grouping_id' : 'id';
    service.filterByShowUnassigned = (shifts: any) => {
        if (service.schedule.settings.onlyUnassigned) {
            return shifts.filter((s: any) => !s.employee);
        }
        return shifts;
    };
    service.filterByUnits = (shifts: any) => {
        if (!UnitFilter.filterEnabled()) {
            return shifts;
        }
        const selectedUnits = UnitFilter.getSelectedUnits().map((u: any) => u.id);
        return shifts.filter((s: any) => {
            const shiftUnits = (s.periods || []).reduce((units: any, period: any) => {
                const unitId = period.business_unit?.id;
                if (unitId) {
                    return units.concat(unitId);
                }
                return units;
            }, []);
            if (!shiftUnits.length) { // Shift has no units
                return UnitFilter.shiftWithoutUnit;
            }
            // Shift has units
            let show = false;
            shiftUnits.forEach((u: any) => {
                selectedUnits.forEach((su: any) => {
                    if (su === u) {
                        show = true;
                    }
                });
            });
            return show;
        });
    };
    service.getHtmlForShiftLines = async () => {
        let html = '';
        let shifts = service.schedule.getShifts();
        const sorting = await scheduleMode.getSorting(); // Get the way we want to sort
        const groupingKey = service.getGroupingKey(); // What we group by
        const sorted: any[] = []; // Here we'll add the final sorted
        const added: Record<string, any> = {}; // Save what's already added, so we dont add more
        // Remove shifts based on filter(s)
        shifts = service.filterByUnits(shifts);
        // Remove shifts based on show only unassigned setting
        shifts = service.filterByShowUnassigned(shifts);
        // Sort the shifts and add an index to tell the proper sort order
        const sortedShifts = orderBy(shifts, [ sorting.field, 'length' ], [ sorting.direction, 'asc' ]).map((s: any, index) => {
            s.index = index;
            return s;
        });
        // Push to sorted based on the grouping key
        sortedShifts.forEach((shift: any) => {
            if (!added[shift[groupingKey]]) {
                sorted.push(sortedShifts.filter((s: any) => s[groupingKey] === shift[groupingKey]));
                added[shift[groupingKey]] = true;
            }
        });

        switch (service.schedule.settings.shiftGrouping) {
            case 'businessUnits':
                html += await service.groupByBusinessUnits(sorted);
                break;
            default:
                html += service.groupByNothing(sorted, groupingKey);
                break;
        }

        return html;
    };
    service.groupByBusinessUnits = async (sorted: any) => {
        const unitOrder = await Storage.getItem('schedule:unit_order');
        let html = '';
        const grouped = groupBy(sorted, (s) => s?.[0]?.periods?.find((p: any) => p.business_unit)?.business_unit?.name);
        const groupedKeys = sortBy(Object.keys(grouped), (k) => k);
        const sortedGrouped = groupedKeys.reduce((arr, key) => {
            // @ts-ignore
            arr.push(grouped[key]);
            return arr;
        }, []);
        // Create the grouped HTML
        sortedGrouped.forEach((groupedSorted) => {
            try {
                // @ts-ignore
                const period = groupedSorted?.[0]?.[0]?.periods?.find((p: any) => p.business_unit);
                // @ts-ignore
                const index = unitOrder?.findIndex((o) => o === period?.business_unit_id);
                html += `<tbody class="shifts-table-body business-unit-body" style="background: ${period?.backgroundColor}; order: ${index}">`;
                html += `<tr class="business-units-group-header"><td colspan="9999"><span style="color: ${period?.textColor}">${period?.business_unit?.name || t('scheduling:NO_UNIT')}</span></td></tr>`;
                forEach(groupedSorted, (shifts: any[]) => {
                    html += `<tr style="order: ${uniqueId()}" class="shift-tr collapsed" data-grouping-key="id">`;
                    html += '<td>';
                    shifts.forEach((shift) => {
                        html += service.getShiftLineHtml(shift);
                    });
                    html += '</td>';
                    html += '</tr>';
                });
                html += '</tbody>';
            } catch (e) {
                console.error(e);
            }
        });
        return html;
    };
    service.groupByNothing = (sorted: any, groupingKey: any) => {
        let html = '';
        forEach(sorted, (shifts) => {
            try {
                html += `<tbody class="shifts-table-body">`;
                html += `<tr style="order: ${uniqueId()}" class="shift-tr collapsed" data-grouping-key="${shifts?.[0]?.[groupingKey]}">`;
                html += `<td>`;
                shifts.forEach((shift: any) => {
                    html += service.getShiftLineHtml(shift);
                });
                html += `</td>`;
                html += `</tr>`;
                html += `</tbody>`;
            } catch (e) {
                console.error(e);
            }
        });
        return html;
    };
    service.setScrollTop = (top: any) => {
        service.scrollTop = top;
    };
    /**
     * @param schedule
     * @returns {Promise}
     */
    service.render = debounce(async (schedule, callback) => {
        service.schedule = schedule;
        const container = document.getElementById('shifts-container');
        const bod = document.getElementById('shifts-table');
        const scheduleTable = document.getElementById('schedule-table');
        if (container && bod && scheduleTable) {
            // Rendering removes all html and inserts again, which sets the scrollbar to the
            // top and causes scroll event to be fired, so skip it
            container.dataset['skipScrollEvent'] = '1';
            // In case that this doesn't help with not scrolling back to previous position, we set temporarily fixed height and width
            bod.setAttribute('style','height:'+bod.scrollHeight+'px;width:'+scheduleTable.scrollWidth+'px;');

            bod.innerHTML = await service.getHtmlForShiftLines();
            service.createObserver();
            service.applyClasses();
            container.scrollTop = service.scrollTop; // Set scroll to position we were
            setTimeout(() => {
                // Revert height and width back to auto after all elements finish rendering
                bod.setAttribute('style','height:auto;width:'+scheduleTable.scrollWidth+'px;');
            },200);
        }
        // Perform callback if passed in
        callback?.();
    }, 100);

    service.applyClasses = () => {
        Array.from(document.querySelectorAll('.shifts-table-body')).forEach((body) => {
            if (service.schedule.settings.expandedShifts) {
                body.classList.add('fancy-mode');
            } else {
                body.classList.remove('fancy-mode');
            }
        });
    };

    service.moveRows = () => {
        // Find all shift-lines with intersecting true
        const intersecting = document.querySelectorAll('.shift-line[data-is-intersecting="true"]');
        // Create a handy grouped object based on the employee with info we need
        const shifts = groupBy(Array.from(intersecting).map((i: any) => {
            return {
                empId: i.dataset.employeeId,
                offset: i.offsetLeft,
                el: i,
            };
        }), 'empId');

        // Sort the shifts on the grouped emp so that the one with the lowest offset is first, and then select it
        Object.entries(shifts).forEach((entry) => {
            // @ts-ignore
            shifts[entry[0]] = orderBy(entry[1], 'offset', 'asc')[0];
        });

        // Sort again so that we get it in the right order
        orderBy(shifts, 'offset', 'asc').forEach((shift: any, index: number) => {
            const tbody = shift.el.closest('tbody');
            tbody.style.order = index;
        });
    };
    service.observeNodes = () => {
        const nodes = document.querySelectorAll('.shift-line');
        for (let i = 0; i < nodes.length; i++) {
            service.observer.observe(nodes[i]);
        }
    };
    service.onIntersect = (entries: any) => {
        entries.forEach((shiftLine: any) => {
            shiftLine.target.dataset.isIntersecting = shiftLine.isIntersecting; // Set intersecting bool
            // Add class if/not intersecting
            if (shiftLine.isIntersecting) {
                shiftLine.target.classList.remove('collapsed');
            } else {
                shiftLine.target.classList.add('collapsed');
            }
            // Check if all children in a tr are collapsed
            let collapsed = true;
            const children = shiftLine.target.parentElement?.childElementCount || 0;
            for (let i = 0; i < children; i++) {
                if (!shiftLine.target.parentElement.children[i].classList.contains('collapsed')) {
                    collapsed = false;
                }
            }
            // If all children were collapsed, then add/remove collapse on tr
            const classList = shiftLine.target.closest('tr').classList;
            if (!classList) {
                return;
            }
            if (collapsed) {
                classList.add('collapsed');
            } else {
                classList.remove('collapsed');
            }
        });
        // We only have to move rows if there are multiple shifts on a row
        if (service.schedule.settings.shiftGrouping === 'employee') {
            service.moveRows();
        }
    };
    service.createObserver = () => {
        if (service.observer instanceof IntersectionObserver) {
            service.observer.disconnect();
        }
        service.observer = new IntersectionObserver(service.onIntersect, {
            root: document.querySelector('#shifts-container'),
            rootMargin: '100000px 0px 100000px 0px',
            threshold: 0.1,
        });
        service.observeNodes();
    };
    return service;
} ]);
