import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FindEmployeeDialogComponent } from './find-employee-dialog.component';
import { DialogData, DialogSize } from '../../../../shared/dialogs/dialog-component';

export interface FindInGroupData extends DialogData {
    groupId: number;
}

@Injectable({
    providedIn: 'root',
})
export class FindEmployeeDialogService {

    constructor(
      @Inject(MatDialog) private dialog: MatDialog,
    ) { }

    open(groupId: number) {
        return this.dialog.open<FindEmployeeDialogComponent, FindInGroupData>(FindEmployeeDialogComponent, {
            data: {
                size: DialogSize.Small,
                groupId,
            },
        });
    }
}
