// @ts-nocheck
import { module } from 'angular';
import { TinyColor } from '@ctrl/tinycolor';

module('eaw.components').component('eawColorpicker', {
    template: `<md-input-container flex="100" class="tw-w-full">
    <label ng-i18next="{{::$ctrl.label}}"></label>
    <input ng-model="$ctrl.selectedColor" style="border-radius: 4px; margin: 4px 0; padding-left: 4px" ng-style="{background: $ctrl.selectedColor, color: $ctrl.textColor}" ng-disabled="$ctrl.disabled" ng-required="$ctrl.required" readonly ng-click="$ctrl.open()">
</md-input-container>
`,
    bindings: {
        label: '@',
    },
    require: {
        ngModel: 'ngModel',
    },
    controller: [ 'colorpickerService', '$attrs', function(colorpickerService, $attrs) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.label = ctrl.label || 'COLOR';

            $attrs.$observe('required', function(value) {
                ctrl.required = value;
            });

            $attrs.$observe('disabled', function(value) {
                ctrl.disabled = value;
            });

            ctrl.ngModel.$formatters.unshift(ctrl.formatter);
        };

        ctrl.setTextColor = () => {
            ctrl.textColor = typeof ctrl.selectedColor === 'string' ? (new TinyColor(ctrl.selectedColor).isDark() ? 'white' : 'black') : 'black';
        };

        ctrl.formatter = (val) => {
            ctrl.selectedColor = colorpickerService.getHex(val);
            ctrl.setTextColor();
            return ctrl.selectedColor;
        };

        ctrl.open = () => {
            if (ctrl.disabled) {
                return;
            }

            colorpickerService.open(ctrl.selectedColor).then((color) => {
                ctrl.selectedColor = colorpickerService.getHex(color);
                ctrl.setTextColor();
                ctrl.ngModel.setViewValue(ctrl.selectedColor);
            });
        };
    } ],
});
