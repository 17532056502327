<eaw-dialog-header>
    <span title>{{ title | translate: 'company' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="unitForm" class="tw-flex tw-flex-col">
        <mat-form-field>
            <mat-label>{{ 'NAME' | translate | async }}</mat-label>
            <input formControlName="name" matInput>
        </mat-form-field>

        <mat-slide-toggle formControlName="default">
            <mat-label>{{ 'SET_DEFAULT' | translate: 'company' | async }}</mat-label>
        </mat-slide-toggle>

        <mat-slide-toggle formControlName="isProductive">
            <mat-label>{{ 'IS_PRODUCTIVE' | translate | async }}</mat-label>
        </mat-slide-toggle>

        <mat-form-field>
            <mat-label>{{ 'CODE' | translate | async }}</mat-label>
            <input formControlName="code" matInput>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'TYPE' | translate | async }}</mat-label>
            <input matInput formControlName="type">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'COLOR' | translate | async }}</mat-label>
            <eaw-color-picker formControlName="color"></eaw-color-picker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'PARENT' | translate: 'company' | async }}</mat-label>
            <input type="text"
                   aria-label="Parent"
                   matInput
                   formControlName="parent"
                   [matAutocomplete]="unitSelect">
            <mat-autocomplete #unitSelect="matAutocomplete" [displayWith]="displayWith">
                <mat-option [value]="null">{{ 'NONE' | translateSync }}</mat-option>
                <mat-option *ngFor="let unit of filteredUnits" [value]="unit">
                    <div class="unit-color" [style.background]="unit.color"></div>
                    {{ unit.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <eaw-qualification-autocomplete *ngIf="canGetQualifications" [customerId]="customerId" formControlName="qualifications"></eaw-qualification-autocomplete>

        <mat-slide-toggle formControlName="reverseInheritQualifications">{{ 'REVERSE_INHERIT_QUALIFICATIONS' | translate: 'company' | async }}</mat-slide-toggle>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="undefined">{{ 'CANCEL' | translate | async }}</button>

    <button mat-raised-button
            color="primary"
            (click)="submit()"
            [disabled]="unitForm.invalid">
        {{ 'SAVE' | translate | async }}
    </button>
</mat-dialog-actions>
