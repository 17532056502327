// @ts-nocheck
import { set } from 'lodash-es';
import { module } from 'angular';

module('eaw.checklists').controller('eawSelectAdvancedCtrl', [ '$mdDialog', function($mdDialog) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.cancel = $mdDialog.cancel;
        ctrl.item.options = !Object.keys(ctrl.item.options || []).length ? {} : ctrl.item.options;
        ctrl.selectOptions = !Object.keys(ctrl.item.options.selectOptions || []).length ? [] : [ ...ctrl.item.options.selectOptions ];
        ctrl.deviationOptions = !Object.keys(ctrl.item.options.deviationOptions || []).length ? [] : [ ...ctrl.item.options.deviationOptions ];
        ctrl.initialOptions = JSON.stringify(ctrl.item.options);

        ctrl.sortableOptions = {
            draggable: '.select-option',
            handle: '.option-handle',
            onEnd(e) {
                ctrl.selectOptions = ctrl.reposition(ctrl.selectOptions, e.oldIndex, e.newIndex);
                ctrl.deviationOptions = ctrl.reposition(ctrl.deviationOptions, e.oldIndex, e.newIndex);

                ctrl.optionsChanged();
            },
        };

        ctrl.optionsChanged();
    };

    ctrl.reposition = (array, oldIndex, newIndex) => {
        const item = array[oldIndex];

        // Remove from old index
        array.splice(oldIndex, 1);

        // Insert at the new index
        // The new index is calculated as if the draggable item is not in the array
        array.splice(newIndex, 0, item);

        return [ ...array ];
    };

    ctrl.optionsChanged = () => {
        ctrl.changedOptions = JSON.stringify({
            ...ctrl.item,
            options: {
                deviationOptions: ctrl.deviationOptions,
                selectOptions: ctrl.selectOptions,
            },
        });
        ctrl.sameOptions = ctrl.initialOptions === ctrl.changedOptions;
    };

    ctrl.addOption = () => {
        if (!Object.keys(ctrl.newOption || []).length) { // Can't add something that's empty duh
            return;
        }

        ctrl.selectOptions.push(ctrl.newOption);
        ctrl.deviationOptions.push(0);
        ctrl.newOption = null;

        ctrl.optionsChanged();
    };

    ctrl.toggleDeviation = (index) => {
        const deviation = !ctrl.deviationOptions?.[index];
        const int = deviation ? 1 : 0;
        set(ctrl, `deviationOptions[${index}]`, int);

        ctrl.optionsChanged();
    };

    ctrl.deleteOption = (index) => {
        ctrl.selectOptions.splice(index, 1);
        ctrl.deviationOptions.splice(index, 1);

        ctrl.optionsChanged();
    };

    ctrl.submit = () => {
        $mdDialog.hide({
            ...ctrl.item,
            options: {
                deviationOptions: ctrl.deviationOptions,
                selectOptions: ctrl.selectOptions,
            },
        });
    };
} ],
);
