import moment from 'moment-timezone';
import { module } from 'angular';
import { t } from 'i18next';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { Namespace } from '../../../shared/enums/namespace';

const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="'NEW_X' | i18next:{name: '$t(payroll:PERIOD_LOCK)'}"></eaw-dialog-header-old>

    <md-progress-circular ng-if="ctrl.loading" class="tw-mt-20 md-progress-center" md-indeterminate></md-progress-circular>

    <form name="addPeriodLockForm" layout="column">
        <md-dialog-content ng-if="!ctrl.loading" layout-padding>
            <p ng-bind-html="ctrl.help"></p>
            <md-input-container class="error-spacer" flex="100" flex-sm="50">
                <eaw-date-picker label="payroll:LOCK_UNTIL" ng-model="ctrl.lockTo" required min-date="ctrl.minDate"></eaw-date-picker>
            </md-input-container>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button ng-click="ctrl.submitNewLock()" ng-i18next="payroll:LOCK_PERIOD" ng-disabled="addPeriodLockForm.$invalid || ctrl.disableSubmit || ctrl.loading"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.period-locks')
    .factory('addPeriodLock', [ 'PeriodLocksFactory', 'ToastService', '$mdDialog', function addPeriodLock(PeriodLocksFactory: any, ToastService: SnackBarService, $mdDialog: any) {
        // @ts-ignore
        const fac = this;

        fac.open = (callback: () => void, customer: any, to: moment.Moment) => $mdDialog.show({
            template: template1,
            controllerAs: 'ctrl',
            bindToController: true,
            locals: {
                customer,
                callback,
                to,
            },
            controller: function() {
                const ctrl = this;

                ctrl.$onInit = async () => {
                    ctrl.loading = true;

                    try {
                        const marked = await require(/* webpackChunkName: "marked" */ 'marked');
                        ctrl.help = marked.parse(t('payroll:PERIOD_LOCK_HELP'));
                        ctrl.minDate = await PeriodLocksFactory.getLocks(customer.id, { per_page: 1 }).$promise.then((resp: any) => {
                            return resp.data[0]?.to.add(1, 'd') || moment(customer.created_at);
                        });
                    } catch (e) {
                        console.error(e);
                    }

                    ctrl.loading = false;
                };

                ctrl.submitNewLock = () => {
                    ctrl.disableSubmit = true;
                    PeriodLocksFactory.create(customer.id, ctrl.lockTo.endOf('d')).$promise.then(() => {
                        void ToastService.t('PERIOD_LOCK_ADDED', Namespace.Payroll);
                        callback();
                        $mdDialog.hide();
                    }).catch(() => delete ctrl.disableSubmit);
                };
            },
        });

        return fac;
    } ]);
