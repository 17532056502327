import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, input, OnInit, Signal, signal } from '@angular/core';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { DateTime } from 'luxon';
import { WebsocketService } from '../../../shared/services/websocket.service';
import { Widget } from '../../classes/widget';
import { TimepunchService } from '../../../payroll/http/timepunch.service';
import { BusinessDate } from '../../../shared/utils/business-date';
import { WidgetComponent } from '../../classes/widget-component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize, interval, tap } from 'rxjs';
import { Timepunch } from '../../../payroll/models/timepunch';
import { Shift } from '../../../scheduling/models/shift';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { TranslateService } from '../../../shared/services/translate.service';
import { Namespace } from '../../../shared/enums/namespace';
import { AsyncPipe } from '@angular/common';
import { AssocPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { DurationUnit } from 'luxon/src/duration';

@Component({
    selector: 'eaw-mini-punched-today',
    standalone: true,
    imports: [
        DateTimePipe,
        AsyncPipe,
        MiniWidgetContentComponent,
    ],
    providers: [
        DurationPipe,
    ],
    templateUrl: './mini-punched-today.component.html',
    styleUrl: './mini-punched-today.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniPunchedTodayComponent extends WidgetComponent implements OnInit {
    private readonly timepunchService = inject(TimepunchService);
    private readonly websocket = inject(WebsocketService);
    private readonly durationPipe = inject(DurationPipe);
    private readonly destroyRef = inject(DestroyRef);
    private readonly translate = inject(TranslateService);

    widget = input.required<Widget>();

    protected readonly inTime = signal<DateTime | undefined>(undefined);
    protected readonly outTime = signal<DateTime | undefined>(undefined);
    protected readonly worked = signal<number>(0);
    protected readonly planned = signal<number>(0);
    protected readonly mainText: Signal<Promise<string>>;
    protected readonly subText: Signal<string | Promise<string>>;

    private customerId = computed(() => this.widget()?.info.customer.id);
    private employeeId = computed(() => this.widget()?.info.employee?.id);

    constructor() {
        super();

        effect(() => {
            const customerId = this.customerId();
            const employeeId = this.employeeId();

            if (customerId && employeeId) {
                this.websocket.listenEmployeeTimepunches(customerId, employeeId, 'punched', this.getData.bind(this), this.destroyRef);
            }
        });

        this.mainText = computed(() => {
            const planned = this.planned();
            const worked = this.worked();

            if (!worked) {
                return this.translate.t('NO_TIMEPUNCHES_TODAY', Namespace.Widgets);
            }

            const plannedUnits: DurationUnit[] = planned ? [ 'hours', 'minutes' ] : [ 'hours' ];
            return this.translate.t('X_OF_Y', Namespace.Widgets, {
                x: this.durationPipe.transform(worked, [ 'hours', 'minutes' ]),
                y: this.durationPipe.transform(planned, plannedUnits),
            });
        });

        this.subText = computed(() => {
            const inTime = this.inTime();
            const outTime = this.outTime();

            if (inTime && outTime) {
                return inTime.toLocaleString(DateTime.TIME_SIMPLE) + ' → ' + (outTime?.toLocaleString(DateTime.TIME_SIMPLE) ?? '?');
            }

            if (inTime) {
                return this.translate.t('PUNCHED_IN_AT_TIME', Namespace.Payroll, { time: inTime.toLocaleString(DateTime.TIME_SIMPLE) });
            }

            return '';
        });

        interval(60_000)
            .pipe(
                tap(() => {
                    // If the employee is punched in, update worked time every minute
                    const inTime = this.inTime();
                    if (inTime) {
                        this.worked.set(DateTime.now().diff(inTime).as('seconds'));
                    }
                }),
                takeUntilDestroyed(),
            )
            .subscribe();
    }

    ngOnInit(): void {
        this.getData();
    }

    getData() {
        const customerId = this.customerId();
        const employeeId = this.employeeId();

        if (!customerId || !employeeId) {
            return;
        }

        this.setLoading(true);
        const from = new BusinessDate(DateTime.now().startOf('day'));
        const to = new BusinessDate(DateTime.now().startOf('day'));
        this.timepunchService.getCustomerSummary(customerId, employeeId, {
            with_trashed: false,
            per_page: 1, // one page = one date
            from,
            to,
        }).pipe(
            tap((summary) => this.onSummary(employeeId, summary)),
            finalize(() => this.setLoading(false)),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();
    }

    protected onSummary(employeeId: number, summary: AssocPaginatedResponse<Record<string, Timepunch[]>>): void {
        const timepunches = Object.entries(summary.data)[0]?.[1][employeeId];

        if (!timepunches?.length) {
            this.worked.set(0);
            this.planned.set(0);
            this.inTime.set(undefined);
            this.outTime.set(undefined);

            return;
        }

        const planned = Object.values(
            timepunches
                .flatMap((tp: Timepunch) => tp.shifts)
                .reduce((acc, shift) => {
                    acc[shift.id] = shift; // no dupes

                    return acc;
                }, {} as Record<string, Shift>),
        ).reduce((acc, shift) => shift.length + acc, 0);

        this.planned.set(planned);
        this.planned.set(planned);
        this.worked.set(timepunches.reduce((acc, tp) => acc + tp.length, 0));

        const inTime = timepunches[0]?.in;
        const punchedOut = timepunches[timepunches.length - 1]?.out;

        if (punchedOut) {
            // Employee is punched out, no need to show times
            this.inTime.set(undefined);
            this.outTime.set(undefined);
        } else {
            this.inTime.set(inTime);

            if (planned) {
                let breaks = 0;
                let previous: Timepunch | undefined = undefined;

                for (const tp of timepunches) {
                    if (previous?.out) {
                        breaks += tp.in.diff(previous.out).as('seconds');
                    }
                    previous = tp;
                }

                this.outTime.set(inTime?.plus({ seconds: planned + breaks }));
            } else {
                this.outTime.set(undefined);
            }
        }
    }
}
