import { Component, Inject, Input, OnInit } from '@angular/core';
import { WidgetService } from '../../../dashboard-new/http/widget.service';
import { WidgetInfoService } from '../../../dashboard-new/services/widget-info.service';
import { WidgetInfo } from '../../../dashboard-new/classes/widget-info';
import { catchError, debounceTime, EMPTY, firstValueFrom, merge, tap } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { TinyColor } from '@ctrl/tinycolor';
import { MatDialog } from '@angular/material/dialog';
import { ColorpickerDialogComponent, ColorpickerDialogData, ColorpickerDialogResult } from '../../../shared/dialogs/colorpicker-dialog/colorpicker-dialog.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NgIf, NgTemplateOutlet, NgFor, AsyncPipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';

interface WidgetListItem {
    info: WidgetInfo;
    enabled: FormControl<boolean>;
    default: FormControl<boolean>;
}

@Component({
    selector: 'eaw-setting-group-widgets',
    templateUrl: './setting-group-widgets.component.html',
    styleUrl: './setting-group-widgets.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        MatTabsModule,
        NgIf,
        NgTemplateOutlet,
        MatListModule,
        MatIconModule,
        MatTooltipModule,
        MatIconSizeDirective,
        NgFor,
        MatSlideToggleModule,
        ReactiveFormsModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class SettingGroupWidgetsComponent implements OnInit {
    @Input() settingGroup!: { id: number };

    fetchingInitial = true;
    defaultColor?: TinyColor | null;
    normalWidgets: WidgetListItem[] = [];
    miniWidgets: WidgetListItem[] = [];

    constructor(
        @Inject(WidgetService) private widgetService: WidgetService,
        @Inject(WidgetInfoService) private widgetInfoService: WidgetInfoService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
        @Inject(MatDialog) private matDialog: MatDialog,
    ) {
    }

    get initialFormControl() {
        return new FormControl<boolean>(false, { nonNullable: true });
    }

    get allWidgets() {
        return [ ...this.normalWidgets, ...this.miniWidgets ];
    }

    get disabledWidgets() {
        return this.allWidgets.filter((w) => !w.enabled.value).map((w) => w.info.key);
    }

    get defaultWidgets() {
        return this.allWidgets.filter((w) => w.default.value).map((w) => w.info.key);
    }

    async ngOnInit() {
        await firstValueFrom(this.setWidgets());

        merge(...this.allWidgets.map((w) => w.enabled.valueChanges)).pipe(debounceTime(1000)).subscribe(() => this.onChange('enabled'));
        merge(...this.allWidgets.map((w) => w.default.valueChanges)).pipe(debounceTime(1000)).subscribe(() => this.onChange('default'));

        this.widgetService.getSettings(this.settingGroup.id).subscribe((settings) => {
            this.defaultColor = settings[WidgetService.WIDGET_COLOR_KEY];

            this.normalWidgets.forEach((widget) => {
                widget.enabled.setValue(!settings.disabled_widgets.includes(widget.info.key), { emitEvent: false });
                widget.default.setValue(settings.default_widgets.includes(widget.info.key), { emitEvent: false });
            });

            this.miniWidgets.forEach((widget) => {
                widget.enabled.setValue(!settings.disabled_widgets.includes(widget.info.key), { emitEvent: false });
                widget.default.setValue(settings.default_widgets.includes(widget.info.key), { emitEvent: false });
            });

            this.fetchingInitial = false;
        });
    }

    setWidgets() {
        return this.widgetInfoService.get({ all: true }).pipe(
            tap((infos) => {
                this.normalWidgets = infos.filter((info) => !info.mini).map((info) => {
                    return {
                        info,
                        enabled: this.initialFormControl,
                        default: this.initialFormControl,
                    };
                });

                this.miniWidgets = infos.filter((info) => info.mini).map((info) => {
                    return {
                        info,
                        enabled: this.initialFormControl,
                        default: this.initialFormControl,
                    };
                });
            }),
        );
    }

    onChange(key: keyof Pick<WidgetListItem, 'enabled' | 'default'>) {
        (key === 'enabled' ?
            this.widgetService.updateDisabledWidgets(this.settingGroup.id, this.disabledWidgets) :
            this.widgetService.updateDefaultWidgets(this.settingGroup.id, this.defaultWidgets)
        ).pipe(
            catchError(() => {
                return EMPTY;
            }),
        ).subscribe(() => {
            if (key === 'enabled') {
                void this.snackBarService.t('DISABLED_WIDGETS_UPDATED', 'admin');
            }

            if (key === 'default') {
                void this.snackBarService.t('DEFAULT_WIDGETS_UPDATED', 'admin');
            }
        });
    }

    chooseColor() {
        const oldDefault = this.defaultColor?.clone();

        this.matDialog.open<ColorpickerDialogComponent, ColorpickerDialogData, ColorpickerDialogResult>(ColorpickerDialogComponent, {
            data: {
                color: this.defaultColor,
            },
        }).afterClosed().subscribe((color) => {
            if ((color instanceof TinyColor)) {
                this.defaultColor = color;
                this.widgetService.setColor(this.settingGroup.id, color).pipe(
                    catchError(() => {
                        this.defaultColor = oldDefault;
                        return EMPTY;
                    }),
                ).subscribe();
            }

            if (color === null) {
                this.defaultColor = null;
                this.widgetService.removeColor(this.settingGroup.id).pipe(
                    catchError(() => {
                        this.defaultColor = oldDefault;
                        return EMPTY;
                    }),
                ).subscribe();
            }
        });
    }
}
