import { PropertyResponse } from '../../shared/models/property';
import { WidgetInfo } from './widget-info';
import { TinyColor } from '@ctrl/tinycolor';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { NoInfoWidgetComponent } from '../components/no-info-widget/no-info-widget.component';
import { mockCustomer } from '../../../mocks/customer.mock';
import { mockUser } from '../../../mocks/user.mock';
import { signal } from '@angular/core';

export enum WidgetSize {
    Mini = 0,
    Default = 1,
    Wide = 2,
    FullWidth = 3,
    Tall = 4,
    TallWide = 5,
    TallFullWidth = 6,
}

// The settings of a widget provided from the relevant widget info's settings dialog
export type WidgetPropertySettings = Record<string, string | number | boolean>;

// The data we use as a stringified value in the user property for a widget
export interface WidgetPropertyData {
    // The key of the widget in our widget info service
    key: string;
    // The size of the widget
    size?: WidgetSize;
    settings?: WidgetPropertySettings;
    // Hex color code
    color?: string;
    // The order of the widget, if not specified it will be added to the end
    order?: number;
}

/**
 * A widget based on a property
 */
export class Widget<Settings extends WidgetPropertySettings = WidgetPropertySettings> {
    readonly property: PropertyResponse;
    readonly color = signal<TinyColor | null>(null);
    readonly size = signal<WidgetSize>(WidgetSize.Default);
    readonly settings: WidgetPropertySettings;
    readonly order: number;
    readonly info: WidgetInfo;

    constructor(response: PropertyResponse, widgetInfo: WidgetInfo | WidgetInfo[]) {
        const widgetData = this.convertPropertyValueToWidgetData(response.value);

        if (Array.isArray(widgetInfo)) {
            this.info = widgetInfo.find((info) => info.key === widgetData?.key) || this.getFallbackWidgetInfo();
        } else {
            this.info = widgetInfo;
        }

        this.property = response;
        this.size.set(widgetData?.size || (this.info.mini ? WidgetSize.Mini : this.info.recommendedSize ?? WidgetSize.Default));
        this.settings = widgetData?.settings || {};
        this.order = widgetData?.order || stringToDateTime(response.created_at).toUnixInteger();

        const color = new TinyColor(widgetData?.color);
        this.color.set(color.isValid ? color : null);
    }

    getSetting<Key extends keyof Settings>(key: Key): Settings[Key] | null {
        const settings = this.settings as Settings;
        return settings[key] ?? null;
    }

    /**
     * If we fail to find a widget info for a widget, we will use this as a fallback
     * @private
     */
    private getFallbackWidgetInfo(): WidgetInfo {
        return new WidgetInfo({
            component: NoInfoWidgetComponent,
            icon: '',
            key: '',
            permissions: [],
            products: [],
            requiresEmployee: false,
            translationKey: 'NO_INFO_WIDGET',
            translationNs: 'widgets',
        }, mockCustomer(), mockUser());
    }

    private convertPropertyValueToWidgetData(value: string): WidgetPropertyData | null {
        let widgetValue: unknown;

        try {
            widgetValue = JSON.parse(value);
        } catch (_) {
            return null;
        }

        if (typeof widgetValue !== 'object') {
            return null;
        }

        if (widgetValue === null) {
            return null;
        }

        const data: WidgetPropertyData = {
            key: 'key' in widgetValue && typeof widgetValue.key === 'string' ? widgetValue.key : '',
            size: 'size' in widgetValue && typeof widgetValue.size === 'number' ? widgetValue.size : WidgetSize.Default,
        };

        if ('settings' in widgetValue && typeof widgetValue.settings === 'object' && widgetValue.settings !== null) {
            data.settings = widgetValue.settings as WidgetPropertySettings;
        }

        if ('color' in widgetValue && typeof widgetValue.color === 'string') {
            data.color = widgetValue.color;
        }

        if ('order' in widgetValue && typeof widgetValue.order === 'number') {
            data.order = widgetValue.order;
        }

        return data;
    }
}
