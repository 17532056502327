import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HandleEmployeeTerminationComponent, EmploymentTerminationHandling } from './handle-employee-termination.component';
import { DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { Employee } from '../../../shared/models/employee';
import { ExternalEmployee } from '../../models/external-employee';
import { DateTime } from 'luxon';

export interface HandleEmployeeTerminationData extends DialogData {
    employee: Employee | ExternalEmployee;
    to?: DateTime;
}

@Injectable({
    providedIn: 'root',
})
export class HandleEmployeeTerminationService {

    constructor(@Inject(MatDialog) private matDialog: MatDialog) { }

    open(employee: Employee | ExternalEmployee, to?: DateTime) {
        return this.matDialog.open<HandleEmployeeTerminationComponent, HandleEmployeeTerminationData, EmploymentTerminationHandling>(HandleEmployeeTerminationComponent, {
            data: {
                size: DialogSize.Medium,
                employee,
                to,
            },
        });
    }
}
