import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { TranslateService } from '../../../shared/services/translate.service';
import { CurrentService } from '../../../shared/services/current.service';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { QualificationService } from '../../../shared/http/qualification.service';
import { Qualification } from '../../../shared/models/qualification';
import { QualificationEmployee } from '../../../shared/models/qualification-employee';
import { AddEmployeeQualificationData, AddEmployeeQualificationDialogComponent } from '../../dialogs/add-employee-qualification/add-employee-qualification-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { QualificationGridComponent } from '../../qualifications/grid/qualification-grid.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';

export type QualificationWithEmployees = {
    qualificationData: Qualification,
    employees: QualificationEmployee[] | [],
    status: 'unloaded' | 'loaded' | 'error',
};
@Component({
    selector: 'eaw-company-qualifications',
    templateUrl: './company-qualifications.component.html',
    styleUrl: './company-qualifications.component.scss',
    standalone: true,
    imports: [
        MatTabsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        NgIf,
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        NgFor,
        QualificationGridComponent,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        InfoLoadingComponent,
        MaterialColorDirective,
    ],
})

export class CompanyQualificationsComponent implements OnInit {
    form = new FormGroup({
        range: new FormGroup({
            from: new FormControl<DateTime | null>(null, Validators.required),
            to: new FormControl<DateTime | null>(null, Validators.required),
        }),
    });

    qualifications?: QualificationWithEmployees[];
    today: DateTime = DateTime.now();

    constructor(
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(QualificationService) private service: QualificationService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(PromptDialogService) private promptDialog: PromptDialogService,
        @Inject(ConfirmDialogService) private confirmDialog: ConfirmDialogService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
        @Inject(MatDialog) private matDialog: MatDialog,
    ) {

    }

    ngOnInit(): void {
        this.getAll();
        this.form.controls.range.valueChanges.subscribe((v) => {
            if (v.from && v.to && v.from <= v.to) {
                this.getAll();
            }
        });
    }

    public getAll() {
        expandAllPages((pagination) => this.service.getAll(this.current.getCustomer().id, {
            ...pagination,
            'count[]': [ 'activeEmployees' ],
            'with[]': [ 'properties' ],
            count_date: this.form.controls.range.value.to || DateTime.now(),
        }), {
            per_page: 100,
        }).subscribe((qualifications) => {
            this.qualifications = qualifications.map((qualification) => {
                return { qualificationData: qualification, employees: [], status: 'unloaded', expanded: false };
            });
        });
    }

    public getEmployees(qualification: QualificationWithEmployees) {
        const options: {
            from?: DateTime;
            to?: DateTime;
        } = {};
        if (this.form.value.range?.from) {
            options.from = this.form.value.range?.from;
        }
        if (this.form.value.range?.to) {
            options.to = this.form.value.range?.to;
        }
        expandAllPages((pagination) => this.service.getEmployees(this.current.getCustomer().id, qualification.qualificationData.id, { ...pagination }, options), { per_page: 100 }).subscribe({
            next: (employees) => {
                qualification.employees = employees.filter((employee) => !employee.to || employee.to.startOf('day') >= this.today.startOf('day'));
                qualification.status = 'loaded';
            },
            error: () => (qualification.status = 'error'),
        });
    }

    public addEmployee(event: Event, qualification: QualificationWithEmployees) {
        event.stopPropagation();
        this.matDialog.open<AddEmployeeQualificationDialogComponent, AddEmployeeQualificationData, Qualification[]>(AddEmployeeQualificationDialogComponent, {
            data: {
                customerId: this.current.getCustomer().id,
                qualificationId: qualification.qualificationData.id,
            },
        }).afterClosed().subscribe((qualifications) => {
            if (qualifications) {
                this.getAll();
            }
        });
    }

    public addQualification() {
        this.promptDialog.open('text', {
            formControl: new FormControl<string>('', {
                nonNullable: true,
                validators: Validators.required,
            }),
            title: this.translate.t('ADD_QUALIFICATION', 'company'),
            label: this.translate.t('NAME'),
            confirmText: this.translate.t('CREATE'),
        }).afterClosed().subscribe((name) => {
            if (!name) {
                return;
            }
            this.service.addQualification(this.current.getCustomer().id, { name }).subscribe(() => {
                this.getAll();
            });
        });
    }

    public editQualification(event: Event, qualification: QualificationWithEmployees) {
        event.stopPropagation();
        this.promptDialog.open('text', {
            formControl: new FormControl<string>(qualification.qualificationData.name, {
                nonNullable: true,
                validators: Validators.required,
            }),
            title: this.translate.t('EDIT_QUALIFICATION', 'company'),
            label: this.translate.t('NAME'),
            confirmText: this.translate.t('EDIT'),
        }).afterClosed().subscribe((name) => {
            if (!name) {
                return;
            }
            this.service.updateQualification(this.current.getCustomer().id, qualification.qualificationData.id, { name }).subscribe(() => {
                this.getAll();
            });
        });
    }

    public deleteQualification(event: Event, qualification: QualificationWithEmployees) {
        event.stopPropagation();
        this.confirmDialog.delete({
            title: this.translate.t('DELETE_QUALIFICATION', 'company', { name: qualification.qualificationData.name }),
            confirmText: this.translate.t('DELETE'),
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                return;
            }
            this.service.deleteQualification(this.current.getCustomer().id, qualification.qualificationData.id).subscribe(() => {
                this.getAll();
            });
        });
    }

    public rangeReset() {
        this.form.reset();
        this.form.controls.range.get('from')?.setErrors(null);
        this.form.controls.range.get('to')?.setErrors(null);
        this.getAll();
    }
}
