<mat-form-field floatLabel="always">
    <mat-label>{{ inputLabel() | async }}</mat-label>

    @if (prefix()) {
        <mat-icon matPrefix>{{ prefix() }}</mat-icon>
    }

    @if (multiSelect()) {
        <mat-chip-grid #chipList>
            @for (item of selectedItems(); track item[trackByKey() || options().trackByKey] || item) {
                <mat-chip-row (removed)="removeItem(item)">
                    <span>{{ autocompleteDisplay(item) }}</span>
                    <button type="button" matChipRemove (click)="removeItem(item)">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-chip-row>
            }
        </mat-chip-grid>

        <input matInput
               [formControl]="filterControl"
               [matChipInputFor]="chipList"
               [matAutocomplete]="autocomplete"
               (focus)="focus()"
               (keyup)="keyup($event)"
               [attr.name]="name()"
               [placeholder]="('SEARCH' | translate | async) || ''"
               [autocomplete]="name()">
    } @else {
        @if (optionTemplate() === 'employee') {
            <eaw-profile-picture matPrefix [user]="profilePictureUser()" [size]="24"/>
        }

        <input matInput
               [formControl]="filterControl"
               [matAutocomplete]="autocomplete"
               (focus)="focus()"
               (keyup)="keyup($event)"
               [attr.name]="name()"
               [placeholder]="('SEARCH' | translate | async) || ''"
               [autocomplete]="name()">
    }

    <div matSuffix>
        @if (loading()) {
            <mat-progress-spinner class="tw-mx-12" strokeWidth="2" diameter="20" mode="indeterminate"></mat-progress-spinner>
        }

        @if (!multiSelect() && !loading() && !filterControl.disabled && item()) {
            <button mat-icon-button type="button" (click)="clear()">
                <mat-icon>close</mat-icon>
            </button>
        }
    </div>


    @if (filterRequirement()) {
        <mat-hint>{{ 'X_CHARS_FOR_FILTER' | translate:'general':{count: filterRequirement()} | async }}</mat-hint>
    }

    @if (useCustomHint()) {
        <mat-hint>
            <ng-content select="[custom-hint]"></ng-content>
        </mat-hint>
    } @else {
        @if (hint()) {
            <mat-hint>{{ hint() | async }}</mat-hint>
        }
    }
</mat-form-field>

<mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="autocompleteDisplay" (optionSelected)="onSelectionChange($event)">
    @for (item of filteredItems() | async; track item[trackByKey() || options().trackByKey] || item) {
        <mat-option [value]="item" [style.line-height]="'normal'" class="autocomplete-option" [class]="optionTemplate()" [disabled]="disableItem()?.(item)">
            <ng-container [ngTemplateOutlet]="optionTemplateRef()" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
        </mat-option>
    }

    @if (loading()) {
        <mat-option disabled>{{ 'SEARCHING_ELLIPSIS' | translateSync }}</mat-option>
    } @else {
        @if (total() === 0 && !filter()) {
            <mat-option disabled>{{ 'NO_DATA' | translateSync }}</mat-option>
        }
        @if (results() === 0 && filter()) {
            <mat-option disabled>{{ 'NO_HITS_FOR_FILTER' | translateSync: 'general':{filter: filter()} }}</mat-option>
        }
        @if (results() > 0 && total() > 0) {
            <mat-option disabled>{{ 'SHOWING_X_OF_TOTAL' | translateSync: 'general':{number: results(), total: total()} }}</mat-option>
        }
    }

</mat-autocomplete>

<!--------------------->
<!----- TEMPLATES ----->
<!--------------------->

<ng-template #defaultAutocompleteOption let-item>
    <span>{{ autocompleteDisplay(item) }}</span>
</ng-template>

<ng-template #dialCodeAutocompleteOption let-country>
    <span>{{ country.name }} ({{ country.Dial }})</span>
</ng-template>

<ng-template #tariffAutocompleteOption let-tariff>
    <div class="content">
        <span class="tariff-name overflow-ellipsis">{{ tariff.name }}</span>
        <small class="tariff-rate" *ngIf="tariff.rates[0]">{{ tariff.rates[0].rate | eawNumber }} / {{ (tariff.rates[0].type | uppercase) | translate | async }}</small>
        <div class="tariff-suggestion" *ngIf="tariff.isSuggestion">
            <small>{{ 'SUGGESTION' | translate | async }}</small>
        </div>
    </div>
</ng-template>

<ng-template #employeeAutocompleteOption let-employee>
    <div class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-8">
        <eaw-profile-picture [size]="32" [user]="employee.user"></eaw-profile-picture>

        <div class="tw-flex tw-flex-col">
            <span class="tw-whitespace-nowrap">{{ employee.name }}</span>
            <small *ngIf="employee.isExternal(customerId)">{{ employee.customer?.name }}</small>
        </div>

        <small class="employee-number tw-basis-full tw-text-right">#{{ employee.number }}</small>
    </div>
</ng-template>

<ng-template #shiftEmployeeAutocompleteOption let-employee>
    <div class="content">
        <div class="number-and-name">
            <small class="number">#{{ employee.number }}</small>
            <div class="name">{{ employee.name }}</div>
        </div>

        @if (employee.available) {
            @if (!employee.overlaps?.length) {
                <small class="availability available">{{ 'AVAILABLE' | translate:'scheduling' | async }}</small>
            }
        } @else {
            @if (employee.availabilityGeneratedBy) {
                <small class="availability unavailable">{{ employee.generatedByTranslationKey | translate:'scheduling' | async }}</small>
            } @else if (employee.available === false) {
                <small class="availability unavailable">{{ 'NOT_AVAILABLE' | translate:'scheduling' | async }}</small>
            }
        }

        @if (employee.overlaps?.length) {
            <small class="working">{{ 'WORKING_FROM_TO_AT' | translate:'scheduling':{from: employee.overlaps[0].overlapFrom | DateTime:'TIME_SIMPLE', to: employee.overlaps[0].overlapTo | DateTime:'TIME_SIMPLE', name: employee.overlaps[0].overlapCustomerName.length ? '(' + employee.overlaps[0].overlapCustomerName + ')' : ''} | async }}</small>
        }

        <div class="availability-days">
            @for (day of employee.availabilityDays; track day.id) {
                <small class="tw-flex tw-gap-4">
                    <span>[</span>
                    <strong>{{ day.from | DateTime:null:'cccc' }}</strong>

                    @if (day.wholeDay) {
                        <span>{{ 'WHOLE_DAY' | translate:'scheduling' | async }}</span>
                    } @else {
                        <span>{{ day.from | DateTime:'TIME_SIMPLE' }} - {{ day.to | DateTime:'TIME_SIMPLE' }}</span>
                    }

                    <span>]</span>
                </small>
            }
        </div>
    </div>
</ng-template>
