import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Availability } from 'src/app/availability/models/availability';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'eaw-availability-days',
    templateUrl: './availability-days.component.html',
    styleUrl: './availability-days.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class AvailabilityDaysComponent {
    @Input({ required: true }) availability!: Availability;
}

