import { EnvironmentInjector } from '@angular/core';
import { EmployeeSidenavService } from '../services/employee-sidenav.service';
import { EmployeeSidenavHeaderComponent } from '../components/employee-sidenav-header/employee-sidenav-header.component';
import { paramResolver } from '../resolvers/param.resolver';
import { sidenavHeaderInputsResolver } from '../resolvers/sidenav-header-inputs.resolver';

export function addChainOpsEmployeeInfoSidenav(injector: EnvironmentInjector) {
    injector.get(EmployeeSidenavService).init({
        sidenavParentState: `eaw/app/chain_ops/employee_list/tab`,
        sidenavState: `eaw/app/chain_ops/employee_list/view`,
        url: '/:location/:emp',
        headerComponent: EmployeeSidenavHeaderComponent,
        data: {
            breadcrumb: { key: 'EMPLOYEE' },
            permissions: [ 'customers.{location}.employees.{emp}.get' ],
        },
        resolve: [
            paramResolver('emp', 'employeeId'),
            paramResolver('location', 'customerId'),
            sidenavHeaderInputsResolver([ 'employeeId', 'customerId' ], function(employeeId: any, customerId: any) {
                return [
                    { key: 'employeeId', value: parseInt(employeeId) },
                    { key: 'customerId', value: parseInt(customerId) },
                ];
            }),
        ],
    }, 'location', 'emp');
}
