// @ts-nocheck
import { module } from 'angular';
import { Namespace } from '../shared/enums/namespace';
import { CurrentOld } from '../shared/angularjs/current.factory';
import { Products } from '../shared/enums/products';

module('eaw.calendar2').config([ '$stateProvider', function calendarRoute($stateProvider: any) {
    $stateProvider.state('eaw/app/calendar2', {
        parent: 'eaw/app',
        url: '/calendar2?date',
        views: {
            'content@': 'eawCalendarView',
        },
        data: {
            products: [ Products.Calendar2 ],
            i18nextNs: [ Namespace.Calendar, Namespace.Filesystem ],
            breadcrumb: { key: 'CALENDAR', ns: 'calendar' },
        },
        params: {
            date: {
                dynamic: true,
                squash: true,
                type: 'string',
            },
        },
    });

    $stateProvider.state('eaw/app/calendar2/create', {
        parent: 'eaw/app/calendar2',
        url: '/create',
        views: {
            'manage-calendar@^': 'manageCalendar',
        },
        data: {
            breadcrumb: { key: 'CREATE' },
        },
    });

    $stateProvider.state('eaw/app/calendar2/manage', {
        parent: 'eaw/app/calendar2',
        url: '/manage/:customer/:key',
        views: {
            'manage-calendar@^': 'manageCalendar',
        },
        data: {
            breadcrumb: { key: 'MANAGE' },
        },
        resolve: {
            calendar: [ 'Calendar', '$transition$', function(Calendar, $transition$) {
                return Calendar.get($transition$.params().customer, $transition$.params().key, CurrentOld.getUser().id);
            } ],
        },
    });
} ]);
