import { ChangeDetectionStrategy, Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { AvailabilityOverviewInterval } from '../../classes/availability-overview-interval';
import { DateTime } from 'luxon';
import { AvailabilityService, FormattedAvailabilityOverview } from '../../http/availability.service';
import { AsyncPipe, NgClass } from '@angular/common';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { map, of, switchMap, take } from 'rxjs';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ShiftService } from '../../../scheduling/http/shift.service';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { Shift } from '../../../scheduling/models/shift';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';

@Component({
    selector: 'eaw-customer-availability-overview-table',
    standalone: true,
    imports: [
        NgClass,
        DateTimePipe,
        MatButton,
        MatIcon,
        InfoLoadingComponent,
        TranslatePipe,
        AsyncPipe,
    ],
    templateUrl: './customer-availability-overview-table.component.html',
    styleUrl: './customer-availability-overview-table.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerAvailabilityOverviewTableComponent {
    private availabilityService = inject(AvailabilityService);
    private shiftService = inject(ShiftService);

    loading = output<boolean>();

    customerId = input.required<number>();
    from = input.required<DateTime>();
    weeks = input.required<number>();
    includePhone = input(false);
    includeShifts = input(false);
    showUnavailableToday = input(true);

    loadingData = signal(false);
    overview = signal<FormattedAvailabilityOverview>([]);
    filteredOverview = computed(this.computeFilteredOverview.bind(this));
    shifts = signal<Shift[]>([]);
    headerDays = computed(this.computeHeaderDays.bind(this));
    tableWeeks = computed(this.computeTableWeeks.bind(this));
    tableDates = computed(this.computeTableDates.bind(this));
    workingEmployees = computed(this.computeWorkingEmployees.bind(this));

    constructor() {
        effect(() => {
            this.loading.emit(this.loadingData());
        });

        effect(() => {
            this.loadingData.set(true);

            this.availabilityService.getOverview(this.customerId(), this.from(), this.weeks()).pipe(
                take(1),
                switchMap((overview) => {
                    return (this.includeShifts() ? expandAllPages((pagination) => this.shiftService.getAll(this.customerId(), {
                        ...pagination,
                        from: this.from(),
                        to: this.from().plus({ weeks: this.weeks() }),
                    }), { per_page: 250 }) : of([])).pipe(
                        map((shifts) => {
                            return { overview, shifts };
                        }),
                    );
                }),
            ).subscribe(({ overview, shifts }) => {
                this.loadingData.set(false);
                this.overview.set(overview);
                this.shifts.set(shifts);
            });
        }, { allowSignalWrites: true });
    }

    getWorkingEmployeesKey(employeeId: number, weekIndex: number, dayIndex: number) {
        return `${employeeId}-${weekIndex}-${dayIndex}`;
    }

    computeWorkingEmployees() {
        const workingEmployees = new Set<string>();

        this.shifts().forEach((shift) => {
            if (shift.employeeId) {
                const weekIndex = Math.floor(shift.from.diff(this.from(), 'weeks').weeks);
                const dayIndex = Math.floor(shift.from.diff(this.from(), 'days').days) % 7;

                workingEmployees.add(this.getWorkingEmployeesKey(shift.employeeId, weekIndex, dayIndex));
            }
        });

        return workingEmployees;
    }

    computeFilteredOverview() {
        const shifts = this.shifts();
        const showUnavailableToday = this.showUnavailableToday();
        const now = DateTime.now();

        return this.overview()?.reduce((acc, entry) => {
            const days = entry.intervals.flat(3);

            if (!showUnavailableToday) {
                const availableToday = days.some((d) => {
                    const sameDay = d.date.hasSame(now, 'day');
                    const working = !!shifts.find((shift) => shift.employeeId === entry.employee.id && shift.from.hasSame(d.date, 'day'));

                    return sameDay && !d.unavailable && !working;
                });

                if (!availableToday) {
                    return acc;
                }
            }

            return acc.concat(entry);
        }, [] as FormattedAvailabilityOverview);
    }

    computeHeaderDays() {
        return Array.from({ length: 7 }, (_, i) => {
            const day = this.from().plus({ days: i });

            return {
                weekday: day.weekdayLong,
                todayDiff: Math.ceil(day.diffNow('days').days),
                relative: day.toRelativeCalendar(),
            };
        });
    }

    computeTableDates() {
        const dates = new Map<number, Map<number, string>>();

        for (let week = 0; week < this.weeks(); week++) {
            for (let day = 0; day < 7; day++) {
                const weekEntry = dates.get(week) || new Map<number, string>();
                weekEntry.set(day, this.from().plus({ weeks: week, days: day }).toLocaleString({ day: 'numeric', month: 'short', year: '2-digit' }));
                dates.set(week, weekEntry);
            }
        }

        return dates;
    }

    getAvailabilityClass(intervals: AvailabilityOverviewInterval[], working?: boolean) {
        switch (true) {
            case working:
                return 'working';
            case intervals.some((interval) => interval.unavailable):
                return 'unavailable';
            case intervals.length && intervals.some((interval) => interval.wholeDay):
                return 'whole-day';
            case !intervals.length:
                return 'unavailable';
            default:
                return '';
        }
    }

    computeTableWeeks() {
        const weeks = new Map<number, number>();

        for (let i = 0; i < this.weeks(); i++) {
            weeks.set(i, this.from().plus({ weeks: i }).weekNumber);
        }

        return weeks;
    }
}
