// @ts-nocheck
import { module } from 'angular';

module('eaw.scheduling', [
    'ngTable',
    'eaw.decorators',
    'eaw.components',
    'eaw.dialogs',
    'eaw.resource',
    'eaw.login',
    'eaw.notifications',
    'eaw.misc',
    'eaw.alerts',
    'eaw.dialogs',
    'eaw.time',
    'eaw.messages',
    'eaw.availability',
    'eaw.budgeting',
    'eaw.staffing',
    'eaw.warnings',
    'eaw.scheduling.shifts',
    'eaw.scheduling.limits',
    'eaw.scheduling.backoffice',
    'ew-endless-list',
    'eaw.ai-budgeting',
    'eaw.calendar',
]);
