// @ts-nocheck
import { module, noop } from 'angular';
const template3 = `<md-dialog>
    <md-dialog-content>
        <div class="md-dialog-content tw-p-0">
            <md-subheader ng-if="ctrl.swap.shift.all_qualifications.length">{{'QUALIFICATION_plural' | i18next}}</md-subheader>
            <md-list class="md-dense" ng-if="ctrl.swap.shift.all_qualifications.length">
                <md-list-item ng-repeat="qual in ::ctrl.swap.shift.all_qualifications">
                    <p ng-bind="qual.name"></p>
                </md-list-item>
            </md-list>

            <md-subheader ng-if="ctrl.swap.shift.periods.length">{{'scheduling:PERIOD_plural' | i18next}}</md-subheader>
            <md-list class="md-dense" ng-if="ctrl.swap.shift.periods.length">
                <md-list-item class="md-2-line" ng-repeat="period in ::ctrl.swap.shift.periods">
                    <div class="md-list-item-text">
                        <h3>{{period.from | moment:'LT'}} - {{period.to | moment:'LT'}}</h3>
                        <p ng-bind="period.description"></p>
                    </div>
                </md-list-item>
            </md-list>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
const template2 = `<md-dialog>
    <eaw-dialog-header-old heading="::'scheduling:APPLY_FOR_SHIFT' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <p ng-bind="$applyDialog.text"></p>
        </div>
        <div ng-if="$applyDialog.swap.shift.comments.length">
            <md-subheader>
                <span ng-i18next="COMMENT_plural"></span>
            </md-subheader>
            <eaw-comment-list [angularjscomments]="$applyDialog.swap.shift.comments"></eaw-comment-list>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button class="md-accent" ng-disabled="$applyDialog.disable" ng-click="$applyDialog.close()" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>

</md-dialog>
`;
import { t } from 'i18next';
import { Comment } from '../../../../shared/models/comment';
import { CommentDialogComponent, CommentDialogData } from '../../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
module('eaw.dashboard').component('availableShiftsWidget', {
    template: `<md-virtual-repeat-container ng-show="$ctrl.list.getLength()" class="tw-h-full">
    <md-list class="md-dense shift-list">
        <md-list-item class="md-3-line" md-virtual-repeat="swap in $ctrl.list" md-on-demand>
            <div class="md-list-item-text">
                <h3 ng-bind="swap.shift.business_date.format('ll')"></h3>
                <h4 ng-bind="swap.shift.from.format('LT') + ' - ' + swap.shift.to.format('LT')"></h4>
                <p ng-bind="swap.customer.name"></p>
            </div>

            <md-button class="md-secondary md-icon-button"
                       ng-class="{'invisible': !swap.shift.all_qualifications.length && !swap.shift.periods.length}"
                       ng-click="$ctrl.showInfo(swap)">
                <md-icon ng-bind="'info'"></md-icon>
            </md-button>

            <md-button class="md-secondary md-icon-button" ng-if="swap.comments.length" ng-click="$ctrl.showComments(swap)">
                <md-icon ng-bind="'comment'"></md-icon>
            </md-button>

            <md-button class="md-secondary md-icon-button" ng-click="swap.applied ? $ctrl.withdraw(swap) : $ctrl.apply(swap)">
                <md-icon md-colors="{color: 'accent-900'}" ng-show="!swap.applied" ng-bind="'swap_horiz'"></md-icon>
                <md-icon md-colors="{color: 'warn'}" ng-show="swap.applied" ng-bind="'delete'"></md-icon>
            </md-button>
            <md-divider ng-if="!$last"></md-divider>
        </md-list-item>
    </md-list>
</md-virtual-repeat-container>
`,
    controllerAs: '$ctrl',
    require: {
        eawWidget: '^eawWidget',
    },
    controller: [ 'SwapFactory', 'MatDialogDowngrade', 'EndlessList', 'EawDialogController', '$mdDialog', function(SwapFactory, MatDialogDowngrade: MatDialog, EndlessList, EawDialogController, $mdDialog) {
        const ctrl = this;
        ctrl.$onInit = () => {
            ctrl.list = new EndlessList(ctrl.getSwaps, {
                order_by: [ 'shifts.business_date' ],
                direction: 'asc',
                with: [ 'shift.periods', 'shift.qualifications', 'comments', 'employees', 'customer' ],
            });
            ctrl.eawWidget.setLoading(true);
            ctrl.list.loadPage(1).finally(() => {
                ctrl.eawWidget.setLoading(false);
                if (!ctrl.list.getLength()) {
                    ctrl.eawWidget.setEmpty(true);
                }
            });
        };
        ctrl.$onDestroy = () => {
            ctrl.swapRes?.$cancelRequest?.();
        };
        ctrl.showComments = (swap) => {
            MatDialogDowngrade.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
                data: {
                    comments: of(swap.comments.map((c) => new Comment(c))),
                },
            });
        };
        ctrl.apply = (swap) => $mdDialog.show({
            template: template2,
            controller: EawDialogController,
            controllerAs: '$applyDialog',
            locals: {
                swap,
                text: t('scheduling:APPLY_FOR_SHIFT_TEXT', {
                    date: swap.shift.from.format('LLLL'),
                    location: swap.shift.schedule?.customer?.name,
                }),
            },
        }).then(() => {
            SwapFactory.applyForSwap(swap.customer_id, swap.id, ctrl.eawWidget.widget.employee.id).$promise.then(() => {
                swap.applied = true;
            });
        });
        ctrl.withdraw = (swap) => {
            $mdDialog.show($mdDialog.confirm()
                .theme('delete')
                .title(t('scheduling:UNAPPLY_FOR_SHIFT'))
                .textContent(t('scheduling:UNAPPLY_FOR_SHIFT_TEXT', {
                    date: swap.shift.from.format('LLLL'),
                    location: swap.customer.name,
                }))
                .ok(t('scheduling:UNAPPLY_FOR_SHIFT'))
                .cancel(t('CANCEL'))).then(() => SwapFactory.withdrawApplication(swap.customer_id, swap.id, ctrl.eawWidget.widget.employee.id).$promise.then(() => {
                swap.applied = false;
            }));
        };
        ctrl.getSwaps = (pagination) => {
            ctrl.loading = true;
            ctrl.swapRes = SwapFactory.all({
                customer: ctrl.eawWidget.widget.customer,
                employee: ctrl.eawWidget.widget.employee.id,
                ...pagination,
            });
            return ctrl.swapRes.$promise.then((resp) => {
                resp.data.forEach((swap) => {
                    swap.applied = swap?.employees?.find((e) => e.id === ctrl.eawWidget.widget.employee.id);
                });
                return resp;
            }).finally(() => {
                ctrl.loading = false;
            });
        };
        ctrl.showInfo = (swap) => $mdDialog.show({
            template: template3,
            controllerAs: 'ctrl',
            clickOutsideToClose: true,
            locals: {
                swap,
            },
            bindToController: true,
            controller: noop,
        });
    } ],
});
