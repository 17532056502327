import { inject, Injectable } from '@angular/core';
import { UserPropertyService } from './user-property.service';
import { UIRouter } from '@uirouter/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { Property } from '../models/property';
import { SnackBarService } from '../services/snack-bar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { mockProperty } from '../../../mocks/property.mock';

type FormPreferenceValidTypes = string | number | boolean;

@Injectable({
    providedIn: 'root',
})
export class UserFormPreferenceService {
    private uiRouter = inject(UIRouter);
    private snackBarService = inject(SnackBarService);
    private userPropertyService = inject(UserPropertyService);

    readonly propertyPrefix = 'user_form_preference';

    private getPropertyKey(stateName?: string) {
        return `${this.propertyPrefix}:${stateName || this.uiRouter.globals.$current.name}`.replaceAll('/', '_');
    }

    get(userId: number, stateName?: string): Observable<Record<string, unknown>> {
        return this.userPropertyService.get(userId, this.getPropertyKey(stateName)).pipe(
            catchError(() => of(mockProperty({ value: JSON.stringify({}) }))),
            map((property) => property.value.asKeyValue() || {}),
        );
    }

    save(userId: number, value: Record<string, FormPreferenceValidTypes | null | undefined>, stateName?: string): Observable<Property | Error> {
        let stringifiedValue: string | undefined = undefined;

        try {
            stringifiedValue = JSON.stringify(Object.entries(value).reduce<Record<string, FormPreferenceValidTypes>>((acc, [ key, value ]) => {
                if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                    acc[key] = value;
                }

                return acc;
            }, {}));
        } catch (e) {
            console.error('Failed to stringify value', value, e);
            return of(new Error('Failed to stringify value'));
        }

        return this.userPropertyService.update(userId, this.getPropertyKey(stateName), stringifiedValue).pipe(
            catchError((error: HttpErrorResponse) => {
                this.snackBarService.open('Failed to save form preference');
                throw error.message;
            }),
            tap(() => {
                this.snackBarService.t('Form preference saved');
            }),
        );
    }
}
