// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
export default class EawCalendar2Controller {
    Calendar2Event;
    $scope;
    ToastService;
    UrlParams;
    eawDatePickerDialog;
    static get $inject() {
        return [ 'Calendar2Event', '$scope', 'ToastService', 'UrlParams', 'eawDatePickerDialog' ];
    }

    date;
    displayDate;
    events;
    loadTimeout;
    from;
    to;
    mode;
    modes;
    goTimeout;
    // Bindings
    customer; // the ID
    user; // the ID
    calendars; // array of Calendar objects
    menuIcon; // Action to perform when menu icon is clicked
    constructor(Calendar2Event, $scope, ToastService, UrlParams, eawDatePickerDialog) {
        this.Calendar2Event = Calendar2Event;
        this.$scope = $scope;
        this.ToastService = ToastService;
        this.UrlParams = UrlParams;
        this.eawDatePickerDialog = eawDatePickerDialog;
    }

    async $onInit() {
        this.date = this.UrlParams.get('date') || moment();
        this.displayDate = this.date.clone();
        this.events = [];
        this.createModes();
        this.increment(0);
        this.Calendar2Event.onEventCreated(this.$scope, this.addEvent.bind(this));
        this.Calendar2Event.onEventUpdated(this.$scope, this.updateEvent.bind(this));
        this.Calendar2Event.onEventDeleted(this.$scope, this.removeEvent.bind(this));
    }

    $onChanges(changes) {
        if (changes.calendars || changes.user) {
            this.loadEvents();
        }
    }

    async loadEvents() {
        clearTimeout(this.loadTimeout);
        if (!this.calendars?.length) {
            this.events = [];
            this.Calendar2Event.broadcastEventsChanged();
        } else {
            // @ts-ignore
            this.loadTimeout = setTimeout(async () => {
                this.events = await this.Calendar2Event.getForPeriod({
                    customer: this.customer,
                    calendars: this.calendars.map((c) => c.key),
                    from: this.from,
                    to: this.to,
                });
                this.Calendar2Event.broadcastEventsChanged();
            }, 500);
        }
    }

    broadcastIntervalChange() {
        this.$scope.$broadcast('calendar:interval:changed');
    }

    onIntervalChange(scope, callback) {
        scope.$on('calendar:interval:changed', callback);
    }

    broadcastEventsChange() {
        this.$scope.$broadcast('calendar:events:changed');
    }

    onEventsChange(scope, callback) {
        scope.$on('calendar:events:changed', callback);
    }

    /**
     * @param {Calendar2Event} event
     */
    getEventIndex(event) {
        return this.events.findIndex((e) => e.key === event.key);
    }

    /**
     * @param {object} e
     * @param {Calendar2Event} event
     */
    addEvent(_, event) {
        this.ToastService.tToast('calendar:EVENT_ADDED');
        this.events.push(event);
        this.broadcastEventsChange();
    }

    /**
     * @param {object} e
     * @param {Calendar2Event} event
     */
    updateEvent(_, event) {
        this.ToastService.tToast('calendar:EVENT_UPDATED');
        this.events.splice(this.getEventIndex(event), 1, event);
        this.broadcastEventsChange();
    }

    /**
     * @param {object} e
     * @param {Calendar2Event} event
     */
    removeEvent(_, event) {
        this.ToastService.tToast('calendar:EVENT_REMOVED');
        this.events.splice(this.getEventIndex(event), 1);
        this.broadcastEventsChange();
    }

    getEventsInInterval(from, to) {
        return this.events.slice(0).filter((event) => !(+event.end < +from || +event.start > +to));
    }

    selectDate(event) {
        const mode = this.mode.value === 'y' ? 'month' : 'day';
        this.eawDatePickerDialog.open(this.displayDate.clone(), {
            mode,
            focusOnOpen: true,
            event,
            callback: (date) => {
                // Skip going anywhere if same interval
                if (date.isSame(this.displayDate, this.mode.increment)) {
                    return;
                }
                this.goToDate(date);
            },
        });
    }

    goToDate(date = moment()) {
        if (!date) {
            console.error('no date');
            return;
        }
        this.displayDate = date;
        this.go();
    }

    createModes() {
        this.modes = [
            {
                value: 'y',
                name: t('YEAR'),
                increment: 'y',
            },
            {
                value: 'w',
                name: t('WEEK'),
                increment: 'w',
            },
        ];
        this.mode = this.modes.find((m) => m.value === this.UrlParams.get('mode'))
            || this.modes.find((m) => m.value === 'w')
            || this.modes[0];
    }

    changeMode(mode, date = this.date) {
        this.mode = this.modes.find((m) => m.value === mode);
        this.goToDate(date);
        this.UrlParams.set('mode', mode);
    }

    /**
     * @param {Number} increment
     */
    increment(increment) {
        this.displayDate = this.displayDate.add(increment, this.mode.increment).clone();
        clearTimeout(this.goTimeout);
        // @ts-ignore
        this.goTimeout = setTimeout(this.go.bind(this), 300);
    }

    go() {
        this.date = this.displayDate.clone();
        this.from = this.date.clone().startOf(this.mode.increment);
        this.to = this.date.clone().endOf(this.mode.increment);
        this.UrlParams.set('date', this.date.startOf(this.mode.increment));
        this.broadcastIntervalChange();
        this.loadEvents();
    }
}
module('eaw.calendar2').component('eawCalendar2', {
    template: `<div id="calendar-settings" layout="row" layout-wrap layout-align="start center">
    <div layout="row" layout-align="start center" flex>
        <md-icon ng-if="$cal.menuIcon" hide-gt-sm id="sidebar-menu-toggle" ng-bind="'menu'" ng-click="$cal.menuIcon()"></md-icon>

        <md-button hide-xs hide-sm id="today-btn" class="md-raised" ng-click="$cal.goToDate()" ng-i18next="TODAY"></md-button>

        <md-button hide-xs hide-sm id="next-inc-btn" class="md-icon-button" ng-click="$cal.increment(-1)">
            <md-icon ng-bind="'chevron_left'"></md-icon>
        </md-button>
        <md-button hide-xs hide-sm id="prev-inc-btn" class="md-icon-button" ng-click="$cal.increment(1)">
            <md-icon ng-bind="'chevron_right'"></md-icon>
        </md-button>

        <eaw-calendar-title date="$cal.displayDate" mode="$cal.mode.value" ng-click="$cal.selectDate($event)"></eaw-calendar-title>
    </div>

    <md-select id="mode-selector" class="md-no-underline" ng-if="$cal.modes.length > 1" ng-model="$cal.mode" ng-change="$cal.changeMode($cal.mode.value)">
        <md-option ng-repeat="mode in ::$cal.modes track by mode.value" ng-value="mode" >
            <span ng-bind="::mode.name"></span>
        </md-option>
    </md-select>
</div>

<eaw-year-calendar year="$cal.date.year()" ng-if="$cal.mode.value == 'y'"></eaw-year-calendar>
<eaw-overview-calendar date="$cal.date" ng-if="$cal.mode.value == 'A'"></eaw-overview-calendar>
<eaw-week-calendar year="$cal.date.year()" week="$cal.date.week()" ng-if="$cal.mode.value == 'w'"></eaw-week-calendar>
`,
    controllerAs: '$cal',
    bindings: {
        customer: '<',
        user: '<',
        calendars: '<',
        menuIcon: '&?', // Action to perform when menu icon is clicked
    },
    controller: EawCalendar2Controller,
});
