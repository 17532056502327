import { Employee } from '../../shared/models/employee';
import { User } from '../../shared/models/user';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { ShiftSwapResponse } from '../interfaces/shift-swap-response';
import { Warning } from '../../shared/models/warning';
import { Shift } from './shift';
import { Approval } from '../../shared/models/approval';
import { SwapApplicant } from './swap-applicant';
import { Comment } from '../../shared/models/comment';

export class ShiftSwap {
    customerId: number;
    shiftId: number;
    fromId: number | null;
    toId: number | null;
    fromEmployee?: Employee | null;
    toEmployee?: Employee | null;
    applicants: SwapApplicant[];
    handledBy?: User;
    cost?: number;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;
    id: number;
    warnings: Warning[];
    shift?: Shift;
    approved?: boolean;
    approval?: Approval;
    comments?: Comment[];

    validApplicants: SwapApplicant[];

    constructor(data: ShiftSwapResponse) {
        this.id = data.id;
        this.shiftId = data.shift_id;
        this.customerId = data.customer_id;
        this.warnings = data.warnings?.length ? data.warnings.map((w) => new Warning(w)) : [];
        this.fromId = data.from_id;
        this.toId = data.to_id;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
        this.approved = data.approved;
        this.applicants = data.applicants?.map((a) => new SwapApplicant(a)) || [];
        this.comments = data.comments?.map((c) => new Comment(c));
        this.fromEmployee = data.from_employee ? new Employee(data.from_employee) : null;
        this.toEmployee = data.to_employee ? new Employee(data.to_employee) : null;
        this.handledBy = data.handled_by ? new User(data.handled_by) : undefined;
        this.shift = data.shift ? new Shift(data.shift) : undefined;
        this.approval = data.approval ? new Approval(data.approval) : undefined;

        // Make sure it's after applicants are set
        this.validApplicants = this.applicants.filter((a) => a.valid);

        // Handle cost after valid applicants
        if (this.toId) {
            this.cost = data.cost;
        } else if (this.validApplicants.length === 1) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.cost = this.validApplicants[0]!.cost;
        }
    }

    get applicantsCount(): number {
        switch (this.mode) {
            case 'single-applicant':
                return 1;
            case 'multi-applicant':
                return this.validApplicants.length;
            default:
                return 0;
        }
    }

    get mode(): 'no-applicants' | 'single-applicant' | 'multi-applicant' {
        switch (true) {
            case this.toId != null:
            case this.validApplicants.length === 1:
                return 'single-applicant';
            case this.validApplicants.length > 1:
                return 'multi-applicant';
            default:
                return 'no-applicants';
        }
    }
}
