import { ApiResponse } from '../../shared/interfaces/api-response';
import { FamilyMemberRelation } from '../http/family-member.service';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export interface FamilyMemberResponse extends ApiResponse {
    birth_date: string;
    custom_fields: {key: string, value: string}[];
    employee_id: number;
    id: number;
    name: string;
    relation: FamilyMemberRelation;
}

export class FamilyMember {
    birthDate: DateTime;
    customFields: {key: string, value: string}[];
    employeeId: number;
    id: number;
    name: string;
    relation: FamilyMemberRelation;

    constructor(data: FamilyMemberResponse) {
        this.birthDate = stringToDateTime(data.birth_date);
        this.customFields = data.custom_fields;
        this.employeeId = data.employee_id;
        this.id = data.id;
        this.name = data.name;
        this.relation = data.relation;
    }
}
