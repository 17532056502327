import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { DialogComponent, DialogData } from '../../../shared/dialogs/dialog-component';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Employee } from '../../../shared/models/employee';
import { EmployeeGroupsService } from '../../http/employee-groups.service';
import { DateTime } from 'luxon';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { catchError, EMPTY, Subject } from 'rxjs';
import { Namespace } from '../../../shared/enums/namespace';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

// The number of employees successfully added to the group
export type ConnectEmployeeGroupsDialogReturn = number;

export interface ConnectEmployeeGroupsDialogData extends DialogData {
    customerId: number;
    groupId: number;
}

@Component({
    selector: 'eaw-connect-hour-distribution-dialog',
    templateUrl: './connect-employee-groups-dialog.component.html',
    styleUrl: './connect-employee-groups-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        NgIf,
        MatButtonModule,
        MatIconModule,
        AutocompleteComponent,
        MatDialogActions,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ConnectEmployeeGroupsDialogComponent extends DialogComponent {
    private memberAddedSubject = new Subject<void>();
    protected minDate = DateTime.now().startOf('day');
    protected form = new FormGroup({
        from: new FormControl<DateTime | null>(null, Validators.required),
        to: new FormControl<DateTime | null>(null),
        employee: new FormControl<Employee | number | null>(null, Validators.required),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ConnectEmployeeGroupsDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ConnectEmployeeGroupsDialogComponent, ConnectEmployeeGroupsDialogReturn>,
        @Inject(SnackBarService) public snackbar: SnackBarService,
        @Inject(EmployeeGroupsService) private employeeGroupsService: EmployeeGroupsService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
        @Inject(SnackBarService) protected snackBarService: SnackBarService,
    ) {
        super(dialogRef, data);
    }

    protected addMember() {
        const employee = this.form.value.employee;
        const from = this.form.value.from;
        const to = this.form.value.to || null;

        if (!(employee instanceof Employee)) {
            return;
        }
        if (!from) {
            return;
        }

        this.form.disable();
        this.employeeGroupsService.connectMember(this.data.customerId, this.data.groupId, employee.id, from, to).pipe(
            catchError(() => {
                this.form.enable();
                return EMPTY;
            }),
        ).subscribe(() => {
            this.form.enable();
            this.form.controls.employee.reset();
            void this.snackBarService.t('EMPLOYEE_GROUP_MEMBER_ADDED', Namespace.Company, { name: employee.name || '' });
            this.memberAddedSubject.next();
        });
    }

    protected isFutureDate(date: DateTime | null) {
        return date && date > DateTime.now();
    }

    onMemberAdded() {
        return this.memberAddedSubject.asObservable();
    }
}
