// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="$settings.widget.i18name | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content" ng-if="$settings.gettingData">
            <md-progress-circular class="md-progress-center tw-mt-24"></md-progress-circular>
        </div>

        <!-- has to be IF becuase of async data -->
        <div class="md-dialog-content" ng-if="!$settings.gettingData" layout="column">
            <md-input-container>
                <label ng-i18next="newsletter:NEWSLETTER"></label>
                <md-select ng-model="$settings.settings.newsletter_id">
                    <md-option ng-value="-1" ng-i18next="newsletter:ALL"></md-option>
                    <md-option ng-repeat="option in $settings.newsletters track by $index" ng-value="option.key">
                        {{option.title}}
                    </md-option>
                </md-select>
            </md-input-container>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-click="$settings.submit()" ng-i18next="SUBMIT" ng-disabled="!$settings.settings.newsletter_id"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.dashboard').factory('defaultNewsletterSettings', [ '$mdDialog', function($mdDialog) {
    return function defaultNewsletterSettings(widget) {
        return $mdDialog.show({
            template: template1,
            controllerAs: '$settings',
            controller: 'defaultNewsletterSettingsCtrl',
            bindToController: true,
            multiple: true,
            locals: {
                widget,
            },
        });
    };
} ]);

module('eaw.dashboard').controller('defaultNewsletterSettingsCtrl', [ '$mdDialog', 'Newsletter', function($mdDialog, Newsletter) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.cancel = $mdDialog.cancel;
        ctrl.newsletters = [];
        ctrl.settings = {
            newsletter_id: -1,
            ...ctrl.widget.settings,
        };
        ctrl.getData();
    };

    ctrl.getData = async () => {
        ctrl.gettingData = true;

        const newsletters = await Newsletter.getAll(CurrentOld.customer.id);

        ctrl.newsletters = newsletters.map((newsletter) => {
            newsletter.setLanguage(CurrentOld.customer.id, CurrentOld.user.id);
            return newsletter;
        });

        ctrl.gettingData = false;
    };

    ctrl.submit = () => {
        $mdDialog.hide({
            ...ctrl.settings,
        });
    };
} ]);
