// @ts-nocheck
import { module } from 'angular';
import { Products } from '../shared/enums/products';
import { Namespace } from '../shared/enums/namespace';

module('eaw.filesystem-access', [
    'eaw.resource',
    'eaw.misc',
]).config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state('eaw/app/filesystem_access', {
        parent: 'eaw/app',
        views: {
            'content@': 'eawFilesystemAccess',
        },
        url: '/filesystem_access',
        data: {
            products: [ Products.FileSystemAccess ],
            i18nextNs: [ Namespace.FilesystemAccess ],
            permissions: [ 'customers.{customer}.filesystems.*.get' ],
            breadcrumb: { key: 'FILESYSTEM_ACCESS', ns: 'navigation' },
        },
    });
} ]);
