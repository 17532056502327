// @ts-nocheck
import { module } from 'angular';
import { sort } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../shared/angularjs/current.factory';

module('eaw.absence').component('contractTypeSelect', {
    template: `<eaw-select-multiple-search ng-model="$select.selected"
                                           ng-if="!$select.loading"
                                           ng-change="$select.select()"
                                           i18n-label="'company:CONTRACT_TYPE_plural'"
                                           return-value="'id'"
                                           items="$select.types"
                                           display-key="'i18n'">
                </eaw-select-multiple-search>`,
    controllerAs: '$select',
    bindings: {
        settingGroupIds: '<',
    },
    require: {
        ngModel: 'ngModel',
    },
    controller: [ 'ContractTypeFactory', 'Pagination', '$filter', function contractTypeSelect(ContractTypeFactory, Pagination, $filter) {
        this.$onInit = async () => {
            this.types = [];
            this.loading = true;
            this.ngModel.$formatters.unshift(this.format);

            const typesMap = new Map();
            const contractTypesPromises = this.settingGroupIds.map(async (id) => {
                const contractTypesResp = await ContractTypeFactory.getAll({ setting_group_id: id }).$promise;
                const resp = contractTypesResp.data.map((type) => ({
                    i18n: $filter('eawTranslate')(type.name, 'general')+' ('+type.setting_group.name+')',
                    ...type,
                }));
                resp.forEach((type) => {
                    if (!typesMap.has(type.id)) {
                        typesMap.set(type.id, type);
                    }
                });
            });
            await Promise.all(contractTypesPromises);

            const types = Array.from(typesMap.values());

            this.types = sort(types, CurrentOld.languageTag, [ (i) => i.i18n ], [ 'asc' ]);
            this.loading = false;
        };
        this.format = (val) => this.selected = val instanceof Array ? val : [];
        this.select = () => {
            this.ngModel.setViewValue(this.selected);
        };
    } ],
});
