import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../interfaces/pagination-options';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';
import { Employee, EmployeeGender, EmployeeResponse } from '../models/employee';
import { map, Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { DateTime } from 'luxon';
import { DateTimeConverter } from '../utils/date-time-converter';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Customer, CustomerResponse } from '../models/customer';
import { EmploymentTerminationHandling } from '../../company/dialogs/handle-employee-termination/handle-employee-termination.component';
import { formatHttpParams } from '../utils/format-http-params';
import { IGNORE_ERROR } from './http-contexts';
import { PinCode, PinCodeResponse } from '../models/pin-code';
import { CustomFieldValueWithInterval } from '../utils/custom-fields-group';
import { PropertyValue } from '../models/property';

export interface CustomerEmployeesGetAllOptions extends PaginationOptions {
    number?: number;
    include_external?: boolean;
    include_inactive?: boolean;
    active?: DateTime;
    from?: DateTime;
    to?: DateTime;
    filter?: string;
    'fields[]'?: string[];
    user_id?: number;
    include_custom_fields?: boolean;
    'user_groups[]'?: number[];
    include_future?: boolean;
    only_future?: boolean;
    only_past?: boolean;
    'properties[]'?: Record<string, PropertyValue>;
}

interface GetFromManyCustomersOptions extends PaginationOptions {
    include_inactive?: boolean;
    active?: DateTime;
    user_id?: number;
    properties?: string[];
    birth_date?: DateTime;
    birthday_from?: DateTime;
    birthday_to?: DateTime;
}

export interface EmployeeCreateOptions {
    number: number;
    user_id?: number;
    email?: string;
    first_name?: string;
    last_name?: string;
    phone?: string | null;
    country_key?: string | null;
    from: DateTime;
    to?: DateTime | null;
    extra_seniority?: number;
    address1?: string | null;
    address2?: string | null;
    gender?: EmployeeGender;
    birth_date?: DateTime | null;
    postal_code?: string | null;
    city?: string | null;
    country?: string | null;
    qualifications?: number[];
    pin_code?: string | null;
}

export interface EmployeeUpdateOptions {
    user_id?: number | null;
    first_name?: string | null;
    last_name?: string | null;
    phone?: string | null;
    country_key?: string | null;
    language_code?: string;
    from?: DateTime | null;
    to?: DateTime | null;
    number?: number;
    address1?: string | null;
    address2?: string | null;
    gender?: EmployeeGender;
    bank_account?: string | null;
    birth_date?: DateTime | null;
    postal_code?: string | null;
    city?: string | null;
    qualifications?: number[];
    nationality?: string | null;
    extra_seniority?: number;
    region_id?: number | null;
    resolve_termination?: EmploymentTerminationHandling | null;
    role_id?: number | null;
}

@Injectable({
    providedIn: 'root',
})
export class EmployeeService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getForCustomerGroup(groupId: number, employeeId: number, options?: { 'with[]': string[] }) {
        return this.http.get<EmployeeResponse>(`/customer_groups/${groupId}/employees/${employeeId}`, {
            params: { ...options },
            context: new HttpContext().set(IGNORE_ERROR, [ 404 ]),
        }).pipe(classifyItem(Employee));
    }

    getAll(customerId: number, options?: CustomerEmployeesGetAllOptions): Observable<ArrayPaginatedResponse<Employee>> {
        return this.http.get<ArrayPaginatedResponse<EmployeeResponse>>(`/customers/${customerId}/employees`, {
            params: formatHttpParams(options, [ 'active', 'from', 'to' ]),
        }).pipe(classifyArrayPaginatedResponse(Employee));
    }

    search(customers: string | number[], options?: GetFromManyCustomersOptions) {
        return this.http.get<ArrayPaginatedResponse<EmployeeResponse>>(`/customers/${customers}/employees/search`, {
            params: formatHttpParams(options, [ 'birth_date', 'birthday_from', 'birthday_to' ]),
        }).pipe(classifyArrayPaginatedResponse(Employee));
    }

    getNextAvailableNumber(customerId: number): Observable<number> {
        return this.getAll(customerId, {
            order_by: 'number',
            per_page: 1,
            direction: 'desc',
            include_inactive: true,
            include_future: true,
        }).pipe(
            map((resp) => {
                const firstEmployee = resp.data[0];
                if (firstEmployee) {
                    return firstEmployee.number + 1;
                }

                return 1;
            }),
        );
    }

    get(customerId: number, employeeId: number, options?: { 'with[]'?: string[] }, context?: HttpContext): Observable<Employee> {
        return this.http.get<EmployeeResponse>(`/customers/${customerId}/employees/${employeeId}`, {
            params: { ...options },
            context,
        }).pipe(classifyItem(Employee));
    }

    create(customerId: number, options: EmployeeCreateOptions, customFields?: Record<string, CustomFieldValueWithInterval>) {
        return this.http.post<EmployeeResponse>(`/customers/${customerId}/employees`, {
            ...options,
            birth_date: options.birth_date ? DateTimeConverter.convertDateTimeToBusinessDate(options.birth_date) : options.birth_date,
            from: DateTimeConverter.convertDateTimeToBusinessDate(options.from),
            to: options.to ? DateTimeConverter.convertDateTimeToBusinessDate(options.to) : options.to,
            ...customFields,
        }).pipe(classifyItem(Employee));
    }

    update(customerId: number, employeeId: number, options: Partial<EmployeeUpdateOptions>, customFields?: Record<string, CustomFieldValueWithInterval>): Observable<Employee> {
        return this.http.put<EmployeeResponse>(`/customers/${customerId}/employees/${employeeId}`, {
            ...options,
            birth_date: options.birth_date ? DateTimeConverter.convertDateTimeToBusinessDate(options.birth_date) : options.birth_date,
            from: DateTimeConverter.convertDateTimeToBusinessDate(options.from),
            to: options.to ? DateTimeConverter.convertDateTimeToBusinessDate(options.to) : options.to,
            ...customFields,
        }).pipe(classifyItem(Employee));
    }

    getEmployeeCustomers(customerId: number, employeeId: number, options?: PaginationOptions & { active?: DateTime }): Observable<ArrayPaginatedResponse<Customer>> {
        return this.http.get<ArrayPaginatedResponse<CustomerResponse>>(`customers/${customerId}/employees/${employeeId}/customers`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(Customer));
    }

    getPinCode(customerId: number, employeeId: number): Observable<PinCode> {
        return this.http.get<PinCodeResponse>(`/customers/${customerId}/employees/${employeeId}/pin_code`,{
        }).pipe(classifyItem(PinCode));
    }

    /**
     * Set the pin code for an employee, or remove/regenerate it if pinCode is undefined
     */
    setPinCode(customerId: number, employeeId: number, pinCode?: string): Observable<PinCode> {
        return this.http.post<PinCodeResponse>(`/customers/${customerId}/employees/${employeeId}/pin_code`,{
            pin_code: pinCode,
        }).pipe(classifyItem(PinCode));
    }
}
