// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { UIRouter } from '@uirouter/core';

module('eaw.coupons').component('myCoupons', {
    controllerAs: 'my',
    template: `<md-tabs md-selected="my.tabIndex">
    <md-tab ng-repeat="tab in my.tabs" label="{{tab.label}}" ng-click="my.selectTab()"></md-tab>
</md-tabs>

<section id="coupon-container">
    <div ng-repeat="coupon in my.shownCoupons">
        <eaw-coupon coupon="coupon" used-coupon="my.filterCoupons()"></eaw-coupon>
    </div>
</section>
`,
    bindings: {
        coupons: '<',
    },
    controller: [ '$uiRouter', function($uiRouter: UIRouter) {
        this.$onInit = () => {
            this.tabs = [
                {
                    label: t('coupons:UNUSED_COUPONS_TAB'),
                },
                {
                    label: t('coupons:USED_COUPONS_TAB'),
                    name: 'used',
                },
            ];
            this.selectTab(false);
        };

        this.filterCoupons = () => {
            const status: any = $uiRouter.globals.params['status'];
            if (!status) {
                this.shownCoupons = this.coupons.filter((c) => !c.isUsed());
            } else if (status == 'used') {
                this.shownCoupons = this.coupons.filter((c) => c.isUsed());
            } else {
                this.shownCoupons = this.coupons;
            }
        };

        this.selectTab = async (go = true) => {
            if (go) {
                await $uiRouter.stateService.go($uiRouter.globals.current, { status: this.tabs[this.tabIndex].name });
            }
            this.tabIndex = this.tabs.findIndex((tab: any) => tab.name == $uiRouter.globals.params['status']);
            this.filterCoupons();
        };
    } ],
});
