import { Inject, Injectable } from '@angular/core';
import { RotationInterval, RotationIntervalResponse } from '../models/rotation-interval';
import { classifyItem } from '../../../shared/utils/rxjs/classify';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface CreateOptions {
    from: string;
    to: string;
    day_index: number;
}

@Injectable({
    providedIn: 'root',
})
export class RotationIntervalService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    create(customerId: number, rotationId: number, body: CreateOptions): Observable<RotationInterval> {
        return this.http.post<RotationIntervalResponse>(`customers/${customerId}/rotations/${rotationId}/intervals`, body).pipe(classifyItem(RotationInterval));
    }

    delete(customerId: number, rotationId: number, intervalId: number) {
        return this.http.delete(`customers/${customerId}/rotations/${rotationId}/intervals/${intervalId}`);
    }
}
