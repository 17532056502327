import { DateTime } from 'luxon';
import { ApiResponse } from '../../../shared/interfaces/api-response';

export interface RotationIntervalResponse extends ApiResponse {
    day_index: number;
    from: string;
    id: number;
    rotation_id: number;
    to: string;
}

export class RotationInterval {
    dayIndex: number;
    from: DateTime;
    id: number;
    rotationId: number;
    to: DateTime;

    constructor(data: RotationIntervalResponse) {
        this.id = data.id;
        // The index in relation to the start day
        this.dayIndex = data.day_index;
        this.rotationId = data.rotation_id;
        this.from = RotationInterval.timeStringToDateTime(data.from);
        this.to = RotationInterval.timeStringToDateTime(data.to);
    }

    private static timeStringToDateTime(string: string) {
        const items = string.split(':');
        const hour = items[0];
        const minute = items[1];
        const second = items[2];

        if (hour === undefined || minute === undefined || second === undefined) {
            return DateTime.invalid('Invalid time string');
        }

        return DateTime.fromObject({
            hour: parseInt(hour),
            minute: parseInt(minute),
            second: parseInt(second),
        });
    }
}
