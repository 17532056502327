<eaw-page-header useBigFilter>
    <span title>{{ 'WEEKLY_NIGHT_HOURS_REPORT_PAGE' | translate: 'analytics' | async }}</span>

    <div bigFilter>
        <form [formGroup]="form" class="tw-flex tw-flex-row tw-flex-wrap tw-gap-x-16">
            <mat-form-field eawDatePickerOptions>
                <mat-label>{{ 'ENTER_DATE_RANGE' | translate | async }}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input formControlName="from" matStartDate [placeholder]="'START_DATE' | translate | async">
                    <input formControlName="to" matEndDate [placeholder]="'END_DATE' | translate | async">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker startView="year" (monthSelected)="chooseMonths($event, picker)"></mat-date-range-picker>
            </mat-form-field>

            <eaw-autocomplete
                    formControlName="employees"
                    [options]="employeeAutocompleteService.options"
                    [getter]="employeeAutocompleteService.getter({customerId})"
                    [setter]="employeeAutocompleteService.setter({customerId})"/>
        </form>

        <button mat-raised-button [disabled]="form.invalid" color="primary" (click)="submit()">{{ 'SUBMIT' | translate | async }}</button>
    </div>
</eaw-page-header>

<mat-card>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    @if (data) {
        <mat-card-content class="tw-overflow-auto">
            <table>
                <thead>
                <tr>
                    <th colspan="7"></th>
                    @for (month of months; track month.name) {
                        <th class="tw-text-center" [colSpan]="month.span">{{ month.name }}</th>
                    }
                </tr>
                <tr>
                    <th>{{ 'EMPLOYEE_NUMBER' | translate: 'company' | async }}</th>
                    <th>{{ 'FIRST_NAME' | translate | async }}</th>
                    <th>{{ 'LAST_NAME' | translate | async }}</th>
                    <th>{{ 'TITLE' | translate | async }}</th>
                    <th class="tw-whitespace-normal">{{ 'LEVEL' |translate: 'custom_fields' | async }} / {{ 'ECHELON' | translate: 'custom_fields' | async }}</th>
                    <th>{{ 'EFFECTIVE_DATE' | translate: 'reports' | async }}</th>
                    <th></th>
                    @for (week of weeks; track week.key) {
                        <th class="week-header">{{ week.week }}</th>
                    }
                </tr>
                </thead>

                <tbody>
                    @for (employee of data | keyvalue; track employee.key) {
                        <tr class="upper-row">
                            <td rowspan="2">{{ employee.value.employee_number }}</td>
                            <td rowspan="2">{{ employee.value.first_name }}</td>
                            <td rowspan="2">{{ employee.value.last_name }}</td>
                            <td rowspan="2">{{ employee.value.title }}</td>
                            <td rowspan="2">{{ employee.value.level + ' ' + employee.value.grade }}</td>
                            <td rowspan="2">{{ formatEffectiveDate(employee.value.effective_date) | DateTime: 'DATE_SHORT' }}</td>
                            <td>{{ 'NIGHT_HOURS_CONDITIONS_MET' | translate: 'analytics' | async }}</td>

                            @for (week of weeks; track week.key) {
                                <td class="tw-text-center"><mat-icon *ngIf="employee.value.weeks_with_night_work[week.key]" inline>done</mat-icon>
                                </td>
                            }
                        </tr>

                        <tr class="lower-row">
                            <td>{{ 'REST_COMPENSATION' | translate: 'france_payroll' | async }}</td>

                            @for (week of weeks; track week.key) {
                                <td class="tw-text-center">{{ getCompensatedHours(employee.key, employee.value, week.key) | eawNumber }}</td>
                            }
                        </tr>
                    }
                </tbody>
            </table>
        </mat-card-content>
    } @else {
        <mat-card-content>
            <mat-card-title>{{ 'NO_DATA' | translate | async }}</mat-card-title>
        </mat-card-content>
    }
</mat-card>
