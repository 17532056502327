import { User, UserResponse } from '../../shared/models/user';
import { DateTime } from 'luxon';
import { Customer, CustomerResponse } from '../../shared/models/customer';
import { ApiResponse } from '../../shared/interfaces/api-response';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { GroupMembership, GroupMembershipResponse } from '../../shared/models/group-membership';
import { Permission, PermissionResponse } from '../../shared/models/permission';

export interface ResponsibleUserResponse extends ApiResponse {
    id: number;
    user_id: number;
    user?: UserResponse;
    group_id: number;
    group?: UserGroupResponse;
}

export interface UserGroupResponse extends ApiResponse {
    id: number;
    name: string;
    model: string;
    type: string;
    owner_id: number;
    parent_id?: number;
    owner_type: string;
    owner_name: string;
    permissions?: PermissionResponse[];
    responsible?: ResponsibleUserResponse[];
    owner?: CustomerResponse;
    memberships?: GroupMembershipResponse<UserResponse>[];
    active_memberships?: GroupMembershipResponse<UserResponse>[];
    pivot?: {
        from: string;
        to: string | null;
        id: number;
        group_id: number;
        member_id: number;
    }

    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export class UserGroup {
    id: number;
    name: string;
    model: string;
    type: string;
    ownerId: number;
    parentId?: number;
    ownerType: string;
    ownerName: string;
    permissions?: Permission[];
    responsibleUsers?: User[];
    owner?: Customer;
    memberships?: GroupMembership<User, UserResponse>[];
    activeMemberships?: GroupMembership<User, UserResponse>[];
    pivot: {
        from: DateTime | null;
        to: DateTime | null;
        id: number;
        groupId: number;
        memberId: number;
    };

    isDisabled?: boolean;
    createdAt?: DateTime | null;
    updatedAt?: DateTime | null;
    deletedAt?: DateTime | null;

    constructor(data: UserGroupResponse) {
        this.id = data.id;
        this.name = data.name;
        this.model = data.model;
        this.type = data.type;
        this.ownerId = data.owner_id;
        this.parentId = data.parent_id;
        this.ownerType = data.owner_type;
        this.ownerName = data.owner_name;
        this.permissions = data.permissions?.map((p) => new Permission(p));

        this.responsibleUsers = data.responsible
            ?.map((u) => u.user ? new User(u.user) : null)
            .filter((u) => u !== null) as User[];

        this.owner = data.owner ? new Customer(data.owner) : undefined;
        this.memberships = data.memberships?.map((m) => new GroupMembership(m, User));
        this.activeMemberships = data.active_memberships?.map((m) => new GroupMembership(m, User));
        this.pivot = {
            from: data.pivot?.from ? stringToDateTime(data.pivot?.from) : null,
            to: data.pivot?.to ? stringToDateTime(data.pivot.to) : null,
            id: data.pivot?.id ?? 0,
            groupId: data.pivot?.group_id ?? 0,
            memberId: data.pivot?.member_id ?? 0,
        };

        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
