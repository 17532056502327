<eaw-page-header [buttons]="buttons" [useBigFilter]="true" [bigFilterToggled]="false">
    <div title class="tw-flex tw-flex-row tw-justify-center tw-items-center">
        <div class="tw-mr-12">{{'KEY_PERFORMANCE_INDICATORS' | translate:'navigation' | async}}</div>

        <button mat-icon-button (click)="changeMonth(-1)" [disabled]="loading">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>

        <span class="mat-subtitle-2" *ngIf="!loading && overviewInterval.from">{{overviewInterval.from.toFormat('MMMM yyyy')}}</span>
        <span class="mat-subtitle-2" *ngIf="loading">{{'LOADING' | translate | async}}</span>

        <button mat-icon-button (click)="changeMonth(1)" [disabled]="loading || isThisMonth">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>

    <div bigFilter>
        <form [formGroup]="filterForm">
            <mat-form-field eawDatePickerOptions>
                <mat-label>{{'ENTER_DATE_RANGE' | translate | async}}</mat-label>
                <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker" [disabled]="loading">
                    <input matStartDate formControlName="from" [placeholder]="'START_DATE' | translate | async" autocomplete="off">
                    <input matEndDate formControlName="to" [placeholder]="'END_DATE' | translate | async" autocomplete="off">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </form>

        <div>
            <button mat-raised-button color="primary" (click)="getOverview()" [disabled]="filterForm.pristine || loading">{{'UPDATE' | translate | async}}</button>
        </div>
    </div>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        <div *ngIf="loading" class="loading-overlay">
            <mat-progress-spinner mode="indeterminate" diameter="80" strokeWidth="7"></mat-progress-spinner>
        </div>

        <table mat-table matSort recycleRows (matSortChange)="sort($event)" [fixedLayout]="true" [dataSource]="dataSource" [trackBy]="tableTrackByFn" [matSortActive]="sortActive" [matSortDirection]="sortDirection">
            <!-- Customer Column -->
            <ng-container [matColumnDef]="locationColumn" sticky>
                <th mat-header-cell [mat-sort-header]="locationColumn" *matHeaderCellDef>{{'LOCATION' | translate | async}}</th>
                <td mat-cell *matCellDef="let item">
                    <span>{{item.customer.shortName}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Customer Column -->
            <ng-container [matColumnDef]="detailsColumn" sticky>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item">
                    <div class="tw-flex">
                        <mat-icon>open_in_browser</mat-icon>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Type Columns -->
            <ng-container *ngFor="let column of columns" [matColumnDef]="column.key">
                <th mat-header-cell [mat-sort-header]="column.key" *matHeaderCellDef [ngClass]="column.type">
                    {{column.wtiKey | translate:'kpi_types' | async}}
                </th>
                <td mat-cell *matCellDef="let item" [ngClass]="column.type">
                    {{item.values[column.key]['formatted']}}
                </td>
                <td mat-footer-cell *matFooterCellDef [ngClass]="column.type">
                    {{sums[column.key]!['formatted']}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;" (click)="showDetails(row)"></tr>
            <tr mat-footer-row *matFooterRowDef="displayColumns; sticky: true"></tr>
        </table>
    </mat-card-content>
</mat-card>
