import { Component, inject, OnInit, signal } from '@angular/core';
import { DialogComponent, DialogSize } from '../dialog-component';
import { MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MeService } from '../../http/me.service';
import { ChangeLocationService } from '../../services/change-location.service';
import { CurrentService } from '../../services/current.service';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-stop-acting-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
    ],
    templateUrl: './stop-acting-dialog.component.html',
    styleUrl: './stop-acting-dialog.component.scss',
})
export class StopActingDialogComponent extends DialogComponent implements OnInit {
    private meService = inject(MeService);
    private currentService = inject(CurrentService);
    private changeLocationService = inject(ChangeLocationService);

    protected name = signal('');

    constructor() {
        const dialogRef: MatDialogRef<StopActingDialogComponent> = inject(MatDialogRef);
        super(dialogRef, { size: DialogSize.Small });

        this.name.set(this.currentService.getUser().name);
    }

    ngOnInit() {
        void this.meService.stopActing(this.changeLocationService.getReloadUrl());
    }
}
