import { Component, Inject, ViewChild } from '@angular/core';
import { OrganizationService } from '../../../shared/http/organization.service';
import { Organization } from '../../../shared/models/organization';
import { debounceTime, distinctUntilChanged, Observable, shareReplay, tap } from 'rxjs';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTablePagination, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { CurrentService } from '../../../shared/services/current.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { MatDialog } from '@angular/material/dialog';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { QueryParamsService } from '../../../shared/services/query-params.service';

@Component({
    selector: 'eaw-admin-organization',
    templateUrl: './admin-organization.component.html',
    styleUrl: './admin-organization.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AdminOrganizationComponent implements EawDataTable<Organization> {
    @ViewChild('dataTable') dataTable?: DataTableComponent<Organization>;

    request?: Observable<ArrayPaginatedResponse<Organization>>;
    columns: DataTableColumnType<Organization>[];
    search: FormControl<string | null>;

    constructor(
        @Inject(OrganizationService) protected organizationService: OrganizationService,
        @Inject(CurrentService) protected currentService: CurrentService,
        @Inject(ConfirmDialogService) protected confirmDialogService: ConfirmDialogService,
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(PermissionCheckService) public permissionCheckService: PermissionCheckService,
        @Inject(QueryParamsService) public searchParamsService: QueryParamsService,
    ) {
        this.columns = [
            new DataTableTextColumn({
                header: new DataTableHeader({
                    text: 'Id',
                }),
                value: 'id',
                key: 'id',
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({
                    i18n: 'NAME',
                }),
                value: 'name',
                key: 'name',
            }),
            new DataTableNumberColumn({
                header: new DataTableHeader({
                    i18n: 'MEMBER_plural',
                }),
                value: (cell) => cell.item.members.length,
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        icon: 'delete',
                        type: 'warn',
                        tooltip: { key: 'DELETE' },
                        click: this.deleteOrganization.bind(this),
                        show: (item: Organization) => this.permissionCheckService.isAllowed(`organizations.${item.id}.delete`),
                    },
                ],
            }),
        ];

        this.search = new FormControl<string | null>(this.searchParamsService.get('search', 'string') || '');

        this.search.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
        ).subscribe(this.update.bind(this));
    }

    deleteOrganization(cell: DataTableCell<DataTableButtonColumn<Organization>, Organization>): void {
        this.confirmDialogService.delete({
            name: cell.item.name,
        }).afterClosed().pipe(tap((confirmed) => {
            if (confirmed) {
                this.organizationService.delete(cell.item.id).subscribe(() => {
                    this.updateTable(this.dataTable?.getPagination());
                });
            }

            cell.disabled.set(false);
        })).subscribe();
    }

    update() {
        this.searchParamsService.set([
            { key: 'search', value: this.search.value },
        ]);

        this.updateTable({
            ...this.dataTable?.getPagination(),
            page: 1,
        });
    }

    updateTable(pagination?: Partial<DataTablePagination>): void {
        this.request = this.organizationService.getAll({
            filter: this.search.value?.trim() || undefined,
            page: pagination?.page || 1,
            per_page: pagination?.per_page || 10,
        }).pipe(shareReplay(1));
    }
}
