import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

interface PinCodeForm {
    pinCode: FormControl<string>;
}

type CustomErrors = {
    pinCodelength?: boolean,
    minLetters?: boolean,
    minDigits?: boolean,
    lowerAlphaAndNumericOnly?: boolean,
}

@Component({
    selector: 'eaw-profile-pin-code-dialog',
    templateUrl: './profile-pin-code-dialog.component.html',
    styleUrl: './profile-pin-code-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ProfilePinCodeDialogComponent extends DialogComponent<DialogData, string> {
    protected readonly pinCodelength = 6;
    protected readonly minLetters = 2;
    protected readonly minDigits = 4;
    protected form: FormGroup<PinCodeForm>;

    constructor(
        @Inject(MAT_DIALOG_DATA) data?: DialogData | undefined,
        @Inject(MatDialogRef) dialogRef?: MatDialogRef<ProfilePinCodeDialogComponent, string>,
    ) {
        data ||= {};
        data.size = DialogSize.Medium;

        super(dialogRef, data);

        this.form = new FormGroup<PinCodeForm>({
            pinCode: new FormControl<string>('', {
                nonNullable: true,
                validators: this.customValidator.bind(this),
            }),
        });
    }

    customValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        const errors: CustomErrors = {};
        if (!value || value.length !== this.pinCodelength) {
            errors.pinCodelength = true;
        }

        const letterCount = value ? value.replace(/[^a-zA-Z]/g, '').length : 0;
        const digitCount = value ? value.replace(/[^0-9]/g, '').length : 0;

        if (letterCount < this.minLetters) {
            errors.minLetters = true;
        }
        if (digitCount < this.minDigits) {
            errors.minDigits = true;
        }
        if (!(/^[a-z0-9]+$/).test(value)) {
            errors.lowerAlphaAndNumericOnly = true;
        }
        if (Object.keys(errors).length === 0) {
            return null;
        }
        return errors;
    }

    save() {
        this.dialogRef.close(this.form.controls.pinCode.value);
    }
}
