// @ts-nocheck
import { t } from 'i18next';
import { find } from 'lodash-es';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

export class ChecklistItemViewController {
    ChecklistItemFactory;
    ChecklistsService;
    ChecklistItemService;
    $mdDialog;

    static get $inject() {
        return [ 'ChecklistItemFactory', 'ChecklistsService', 'ChecklistItemService', '$mdDialog' ];
    }

    attachments;
    canUpdate;
    checklist;
    item;
    todo_item_route = `eaw/app/todo/item`;
    user;
    comments;
    customer;

    constructor(ChecklistItemFactory, ChecklistsService, ChecklistItemService, $mdDialog: angular.material.IDialogService) {
        this.ChecklistItemFactory = ChecklistItemFactory;
        this.ChecklistsService = ChecklistsService;
        this.ChecklistItemService = ChecklistItemService;
        this.$mdDialog = $mdDialog;
    }

    get canAttach() {
        return this.canUpdate;
    }

    get canComment() {
        return this.canUpdate;
    }

    get canGetComments() {
        return this.canUpdate;
    }

    get canGetAttachments() {
        return this.canUpdate;
    }

    $onInit() {
        this.user = CurrentOld.getUser();
        this.customer = CurrentOld.getCustomer();
        this.canUpdate = CurrentOld.can('customers.' + this.customer.id + '.checklists.' + this.checklist.id + '.update');
        this.ChecklistItemService.setChecklist(this.checklist);
        this.comments = this.ChecklistItemService.getItemComments(this.item.id);
        this.attachments = this.ChecklistItemService.getItemAttachments(this.item.id);
    }

    getComments(itemId) {
        return this.ChecklistItemService.getItemComments(itemId);
    }

    async toggleComments(item) {
        item.showComments = !item.showComments;
    }

    getAttachments(item) {
        return this.ChecklistItemService.getItemAttachments(item.id);
    }

    getWarnings(item) {
        const i = find(this.checklist.all_items, { id: item.id });
        if (i) {
            item.warnings = i.warnings;
        }
    }

    updateItem(item) {
        if (item.data === undefined) {
            return;
        }
        // Check before updating
        if (item.type === 'boolean') {
            item.data = item.data === 0 ? null : 1;
        }
        item.user = this.user;
        this.ChecklistItemFactory.update(this.customer, this.checklist, item).$promise.then((updatedItem) => {
            item.data = updatedItem.data;
            this.ChecklistsService.updatePercentages(this.checklist);
        });
    }

    updateFileData(image, item) {
        return this.ChecklistItemService.addAttachment(item, image).then((resp) => {
            item.data = {
                attachment_id: resp.data.id,
            };
            this.updateItem(item);
        });
    }

    updateSelectItem(item, option) {
        item.data = option;
        this.ChecklistItemFactory.update(this.customer, this.checklist, item).$promise.then((updatedItem) => {
            Object.assign(item, updatedItem);
            this.ChecklistsService.updatePercentages(this.checklist);
        });
    }

    comment(body, item) {
        this.ChecklistItemService.addComment(item, body);
    }

    attach(image, item) {
        this.ChecklistItemService.addAttachment(item, image);
    }

    markDeviation(item) {
        this.$mdDialog.show(this.$mdDialog.confirm()
            .title(t('checklists:MARK_DEVIATION'))
            .textContent(t('checklists:MARK_DEVIATION_TEXT'))
            .ok(t('CONFIRM'))
            .cancel(t('CANCEL'))).then(() => {
            item.deviates = true;
            this.updateItem(item);
        });
    }

    getImageUrl(attachment) {
        return this.ChecklistItemFactory.getImage(this.checklist.id, attachment.owner_id, attachment.id).then((resp) => {
            attachment.loaded = true;
            return resp;
        });
    }

    enlargeImage(event) {
        if (event.currentTarget.classList.contains('enlarged')) {
            event.currentTarget.classList.remove('enlarged');
        } else {
            event.currentTarget.classList.add('enlarged');
        }
    }
}

module('eaw.checklists').component('checklistViewItem', {
    template: `<div class="checklist-item-row" layout="column" layout-gt-sm="row">
    <div class="item-info" layout="row" flex layout-align="start center">
        <md-menu flex-order-gt-sm="0">
            <md-button class="md-icon-button" ng-click="$mdMenu.open()">
                <md-icon ng-bind="'more_vert'"></md-icon>
            </md-button>

            <md-menu-content width="4">
                <md-menu-item>
                    <md-button ng-click="$item.markDeviation($item.item)"
                               ng-disabled="$item.item.deviates || !$item.item.data || $item.item.warnings.length > 0">
                        <span ng-i18next="checklists:MARK_AS_DEVIATION"></span>
                    </md-button>
                </md-menu-item>
                <md-menu-item ng-if="$item.canGetComments">
                    <md-button ng-click="$item.toggleComments($item.item)">
                        <span ng-i18next="COMMENT_plural"></span>
                    </md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
        <div layout="column">
            <strong class="item-title" ng-bind="::$item.item.title"></strong>
            <small ng-bind="::$item.item.description"></small>
        </div>

        <div class="tw-m-16" layout="row" >
            <md-icon ng-if="$item.canGetComments && $item.getComments($item.item.id).length > 0"
                     class="s18"
                     ng-bind="'comment'"
                     ng-click="$item.toggleComments($item.item)">
                <md-tooltip ng-i18next="COMMENT_plural"></md-tooltip>
            </md-icon>
            <md-icon ng-if="$item.canGetAttachments &&  $item.getAttachments($item.item).length > 0"
                     class="s18"
                     ng-bind="'image'"
                     ng-click="$item.toggleComments($item.item)">
                <md-tooltip ng-i18next="ATTACHMENT_plural"></md-tooltip>
            </md-icon>

            <span ng-bind="$item.item.todo_items[0].status"></span>

            <div ng-if="$item.item.deviates || $item.item.warnings.length > 0">
                <md-icon ng-if="!$item.checklist.all_items[$item.item.id].todo_items.length || $item.checklist.all_items[$item.item.id].todo_items[0].status.type != 'done'"
                         class="s18"
                         ng-bind="'assignment_late'"
                         md-colors="{'color': 'deep-orange-500'}"
                         ng-click="$item.toggleComments($item.item)">
                    <md-tooltip ng-i18next="checklists:DEVIATION"></md-tooltip>
                </md-icon>

                <md-icon ng-if="$item.checklist.all_items[$item.item.id].todo_items[0].status.type == 'done'"
                         class="s18"
                         ng-bind="'assignment_turned_in'"
                         md-colors="{'color': 'green-500'}"
                         ng-click="$item.toggleComments($item.item)">
                    <md-tooltip ng-i18next="checklists:DEVIATION"></md-tooltip>
                </md-icon>
            </div>
        </div>
    </div>

    <!-- Show input depending on type -->
    <div class="inputs-closed" ng-if="$item.checklist.closed_at" layout layout-align="center center">
        <!-- Boolean -->
        <md-icon ng-if="$item.item.type == 'boolean' && $item.item.data == 1" ng-bind="'done'"></md-icon>

        <!-- Text / Select -->
        <span ng-if="$item.item.type == 'text' || $item.item.type == 'select'" ng-bind="::$item.item.data"></span>

        <!-- Number -->
        <span ng-if="$item.item.type == 'number' && ($item.item.data || $item.item.data == 0)" ng-bind="::$item.item.data | eawNumber:2"></span>

        <!-- File -->
        <img ng-if="$item.item.type == 'file' && $item.item.data.attachment_id"
             ng-click="$item.enlargeImage($event)"
             class="hover-item checklist-image"
             image-attachment
             get-image-url="$item.getImageUrl(attachment)"
             attachment="{id: $item.item.data.attachment_id, owner_id: $item.item.id}"/>
    </div>

    <div class="inputs" ng-if="!$item.checklist.closed_at" layout layout-align="center center">
        <md-checkbox ng-if="$item.item.type == 'boolean'"
                     ng-model="$item.item.data"
                     ng-disabled="!$item.canUpdate"
                     ng-change="$item.updateItem($item.item)"
                     ng-true-value="1"
                     ng-false-value="0">
        </md-checkbox>

        <md-input-container md-no-float class="md-block" ng-if="$item.item.type == 'text'">
            <input ng-i18next="[placeholder]TEXT"
                   type="text"
                   ng-disabled="!$item.canUpdate"
                   ng-model="$item.item.data"
                   ng-blur="$item.updateItem($item.item)">
        </md-input-container>

        <md-input-container md-no-float class="md-block" ng-if="$item.item.type == 'number'">
            <input type="number"
                   step="any"
                   ng-model="$item.item.data"
                   ng-disabled="!$item.canUpdate"
                   ng-blur="$item.updateItem($item.item)"
                   ng-min="$item.item.options.minimum"
                   ng-max="$item.item.options.maximum">
        </md-input-container>

        <md-input-container ng-if="$item.item.type == 'select'">
            <md-select ng-model="$item.item.data" ng-disabled="!$item.canUpdate">
                <md-option ng-repeat="option in ::$item.item.options.selectOptions track by $index"
                           ng-click="$item.updateSelectItem($item.item, option)"
                           ng-value="option">
                    <span ng-bind="::option"></span>
                </md-option>
            </md-select>
        </md-input-container>

        <div ng-if="$item.item.type == 'file'">
            <upload-image classes="'hover-item md-button md-fab md-mini'" ng-disabled="!$item.canUpdate" on-attach="::$item.updateFileData(image, $item.item)"></upload-image>
            <img ng-if="$item.item.data.attachment_id"
                 ng-click="$item.enlargeImage($event)"
                 class="hover-item checklist-image"
                 image-attachment
                 get-image-url="$item.getImageUrl(attachment)"
                 attachment="{id: $item.item.data.attachment_id, owner_id: $item.item.id}" flex="100"/>
        </div>
    </div>
</div>

<!-- COMMENTS -->
<div class="item-comment-container" ng-if="$item.item.showComments" layout="column">
    <div layout layout-align="start center">
        <md-button class="md-icon-button" ng-click="$item.toggleComments($item.item)">
            <md-icon ng-bind="'close'"></md-icon>
        </md-button>

        <span ng-i18next="[i18next]({name: $item.item.user.name, date: $item.item.updated_at.format('lll')})checklists:COMPLETED_BY"></span>
        <span class="tw-ml-12" ng-if="$item.checklist.all_items[$item.item.id].todo_item_id">
            <span ng-i18next="checklists:DEVIATION"></span>:
            <a ui-sref-active ui-state="$item.todo_item_route"
               ui-state-params="{itemId: $item.checklist.all_items[$item.item.id].todo_item_id, location: $item.checklist.customer_id}"
               ng-i18next="checklists:GO_TO_TODO_ITEM"></a>
        </span>
    </div>

    <div layout="row" ng-hide="!$item.canAttach && !$item.canComment">
        <upload-image ng-if="$item.canAttach" on-attach="$item.attach(image, $item.item)"></upload-image>
        <eaw-comment-field ng-if="$item.canComment"  flex add-comment="$item.comment(comment, $item.item)"></eaw-comment-field>
    </div>

    <div layout="row" ng-hide="!$item.canAttach && !$item.canComment">
        <!-- If this to prevent infinite digest -->
        <attachment-list flex ng-if="$item.attachments.length" ng-model="$item.attachments" get-image-url="$item.getImageUrl(attachment)"></attachment-list>
        <eaw-comment-list flex ng-if="$item.canGetComments" [angularjscomments]="$item.comments"></eaw-comment-list>
    </div>
</div>
`,
    controllerAs: '$item',
    bindings: {
        checklist: '<',
        item: '<',
    },
    controller: ChecklistItemViewController,
});
