<eaw-page-header [fabButton]="fabButton">
    <span title>
        <span>{{'TEMPLATE_plural' | translate:'scheduling' | async}}</span>

        @if (usingBetaSchedule()) {
            <span class="tw-ml-8">({{ 'BETA' | translate | async }})</span>
        }
    </span>

    <div subtitle eawPermission [permissions]="['schedule_beta']">
        @if (usingBetaSchedule()) {
            <button mat-raised-button (click)="usingBetaSchedule.set(false)">👎 Use old template</button>
        } @else {
            <button mat-raised-button (click)="usingBetaSchedule.set(true)">⭐ Use new template</button>
        }
    </div>
</eaw-page-header>

<mat-card>
    <mat-card-content>
        <eaw-data-table
                [columns]="columns"
                [request]="request"
                [goTo]="goTo()"
                initialSortBy="id"
                initialSortDirection="desc"
                (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
