import { ChangeDetectionStrategy, Component, Inject, WritableSignal } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';
import { ActionButtonComponent } from '../../components/action-button/action-button.component';

export interface AlertDialogData extends DialogData {
    text: WritableSignal<Promise<string>>;
    title: WritableSignal<Promise<string>>;
    loading?: WritableSignal<boolean>;
    buttonDisabled?: WritableSignal<boolean>;
    buttonText?: WritableSignal<Promise<string>>;
}

@Component({
    selector: 'eaw-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrl: './alert-dialog.component.scss',
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        NgIf,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        ActionButtonComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent extends DialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AlertDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AlertDialogComponent>,
    ) {
        super(dialogRef, {
            size: DialogSize.Small,
            ...data,
        });
    }
}
