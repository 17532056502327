<eaw-login-language-select (click)="changeLanguage()"></eaw-login-language-select>

<mat-card id="login-card" class="login-card">
    <mat-card-header class="card-head">
        <eaw-logo height="28px"></eaw-logo>
        <span class="card-title" *ngIf="environment.isProduction">{{ 'LOGIN' | translate:'login' | async }}</span>
        <span class="card-title" *ngIf="environment.isLive && !environment.isProduction">Log in to devprod</span>
        <span class="card-title" *ngIf="!environment.isLive">Log in to development</span>
    </mat-card-header>

    <mat-card-content>
        <form id="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
            <mat-form-field>
                <mat-label>{{ 'EMAIL' | translate | async }}</mat-label>
                <mat-icon matPrefix>person</mat-icon>
                <input matInput formControlName="email" autocomplete="email" type="email">
                <mat-error><eaw-field-error [control]="loginForm.controls.email"/></mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-icon matPrefix>key</mat-icon>
                <mat-label>{{ 'PASSWORD' | translate | async }}</mat-label>
                <input [type]="showPassword ? 'text': 'password'" matInput formControlName="password" autocomplete="password">
                <mat-icon color="primary" matSuffix (click)="showPassword = !showPassword" *ngIf="loginForm.enabled">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                <mat-hint *ngIf="loginForm.controls.password.touched && loginForm.controls.password.hasError('required')" align="start" eawMaterialColor="red-500">{{ 'FORM_FIELD_REQUIRED' | translate:'errors' | async }}</mat-hint>
            </mat-form-field>

            <eaw-info-box color="red" *ngIf="errorCode || errorMessage || unknownError" icon="error">
                <span infoText *ngIf="errorCode === 'logged_out'">{{ 'LOGGED_OUT' | translate:'login' | async }}</span>
                <span infoText *ngIf="errorCode === 'invalid_credentials'">{{ 'INVALID_USER_OR_PASSWORD_MESSAGE' | translate:'errors' | async }}</span>
                <span infoText *ngIf="errorMessage">{{ errorMessage }}</span>
                <span infoText *ngIf="unknownError">{{ 'SOMETHING_WENT_WRONG' | translate:'errors' | async }}</span>
            </eaw-info-box>

            <eaw-action-button type="submit" formId="login-form" [loading]="loginForm.disabled" [disabled]="loginForm.disabled || loginForm.invalid">{{ 'LOGIN' | translate:'login' | async }}</eaw-action-button>
        </form>

        <mat-divider class="tw-my-16"/>

        <div id="ssos-container">
            <strong id="cont-with">or sign in with</strong>

            <div id="ms" class="sso" (click)="loginWithMicrosoft()" matRipple>
                <img src="assets/img/ms-logo.svg" alt="Microsoft">
                <span>{{ 'SIGN_IN_WITH_MS' | translate:'login' | async }}</span>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<div class="tw-flex tw-flex-col tw-gap-8">
    <mat-checkbox id="public-computer" name="public-computer" [formControl]="publicControl" eawCheckboxHelper>
        {{ 'PUBLIC_COMPUTER' | translate:'login' | async }}
    </mat-checkbox>

    <button mat-button (click)="goToForgotPassword()">
        <mat-icon>lock_reset</mat-icon>
        {{ 'FORGOT_PASSWORD_QUESTION' | translate:'login' | async }}
    </button>
</div>

