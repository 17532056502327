import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { EasyAtWorkLearningModule } from 'tutorial-lib';
import { CurrentService } from '../../../shared/services/current.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MuxHandlerDirective } from '../../directives/mux-handler.directive';
import { LearningModuleService } from '../../services/learning-module.service';
import { Mobile } from '../../../shared/utils/eaw-mobile';

@Component({
    selector: 'eaw-learning-module-sidebar-dialog',
    standalone: true,
    imports: [ CommonModule, DialogHeaderComponent, EasyAtWorkLearningModule, TranslatePipe, MuxHandlerDirective ],
    templateUrl: './learning-module-sidebar-dialog.component.html',
    styleUrl: './learning-module-sidebar-dialog.component.scss',
})
export class LearningModuleSidebarDialogComponent extends DialogComponent implements OnInit {
    protected userId: string;
    protected query: string = '';
    protected languageTag: string = 'en';
    protected isMobile = Mobile.isMobile;

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<LearningModuleSidebarDialogComponent>,
        @Inject(CurrentService) private currentService: CurrentService,
        @Inject(LearningModuleService) private learningModuleService: LearningModuleService,
    ) {
        super(dialogRef, { size: DialogSize.Max });
        dialogRef.disableClose = true;
        dialogRef.addPanelClass('learning-module-sidebar-dialog');

        this.userId = this.currentService.getUser().id.toString();
        this.languageTag = this.currentService.languageTag;
    }

    ngOnInit() {
        this.setModules();
    }

    private setModules() {
        const module = this.learningModuleService.getModules();
        this.query = module ? `"${module}" in modules[]._ref` : '';
    }
}
