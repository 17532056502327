import { Component, inject, Input, OnInit } from '@angular/core';
import { AutoScheduleResultService } from '../../services/auto-schedule-result.service';
import { AutoSchedulingResult } from '../../classes/auto-scheduling-result';
import { AsyncPipe } from '@angular/common';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentService } from '../../../shared/services/current.service';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';

@Component({
    selector: 'eaw-auto-scheduling-shift-result',
    standalone: true,
    imports: [
        AsyncPipe,
        TranslatePipe,
        InfoLoadingComponent,
    ],
    templateUrl: './auto-scheduling-shift-result.component.html',
    styleUrl: './auto-scheduling-shift-result.component.scss',
})
export class AutoSchedulingShiftResultComponent implements OnInit {
    private readonly autoScheduleResultService = inject(AutoScheduleResultService);
    private readonly currentService = inject(CurrentService);

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) scheduleId!: number;
    @Input({ required: true }) shiftId!: number;

    loading = true;
    items: AutoSchedulingResult[][] = [];

    ngOnInit(): void {
        this.autoScheduleResultService.getForShift(this.customerId, this.scheduleId, this.shiftId).subscribe((results) => {
            const uniqueEmployeeIds = new Set(results.map((result) => result.employeeId));

            this.items = Array.from(uniqueEmployeeIds).reduce((acc, employeeId) => {
                acc.push(results.filter((result) => result.employeeId === employeeId));
                return acc;
            }, [] as AutoSchedulingResult[][]);

            this.items = sort(this.items, this.currentService.languageTag, [ (item) => item[0]?.employeeName ]);
            this.loading = false;
        });
    }
}
