import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { ExtendedWidgetInfo } from '../../../classes/extended-widget-info';
import { InfoLoadingComponent } from '../../../../shared/components/info-loading/info-loading.component';
import { CalendarEventService } from '../../../../calendar/services/calendar-event.service';
import { DateTime } from 'luxon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize, tap } from 'rxjs';
import { Widget } from '../../../classes/widget';
import { CalendarEvent } from '../../../../calendar/models/calendar-event';
import { DateTimePipe } from '../../../../shared/pipes/date-time.pipe';
import { MatDivider } from '@angular/material/divider';

@Component({
    selector: 'eaw-mini-upcoming-event-widget-extended',
    standalone: true,
    imports: [
        InfoLoadingComponent,
        DateTimePipe,
        MatDivider,
    ],
    templateUrl: './mini-upcoming-event-widget-extended.component.html',
    styleUrl: './mini-upcoming-event-widget-extended.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniUpcomingEventWidgetExtendedComponent extends ExtendedWidgetInfo implements OnInit {
    private readonly calendarEventService = inject(CalendarEventService);
    private readonly destroyRef = inject(DestroyRef);

    protected events = signal<CalendarEvent[]>([]);

    widget = input.required<Widget>();

    ngOnInit(): void {
        const now = DateTime.now();
        this.calendarEventService.getAll(this.widget().info.customer.id, {
            from: now.startOf('day'),
            per_page: 15,
            direction: 'asc',
            order_by: 'from',
        }).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((page) => this.events.set(page.data)),
            finalize(() => this.setLoading(false)),
        ).subscribe();
    }
}
