// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../../../../../shared/angularjs/current.factory';
module('eaw.scheduling').factory('ScheduleCommentsFactory', [ 'EawResource', function(EawResource) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/schedules/:schedule/comments/:comment');
    fac.get = {
        permission(customerId, scheduleId, commentId) {
            return CurrentOld.can(`customers.${customerId}.schedules.${scheduleId}.comments.${commentId}.get`);
        },
        query(customerId, scheduleId, commentId) {
            return res.get({
                customer: customerId,
                schedule: scheduleId,
                comment: commentId,
            }).$promise;
        },
    };
    fac.getAll = {
        permission(customerId, scheduleId) {
            return CurrentOld.can(`customers.${customerId}.schedules.${scheduleId}.comments.*.get`);
        },
        query(customerId, scheduleId, withs) {
            return res.get({
                customer: customerId,
                schedule: scheduleId,
                'with[]': withs,
            }).$promise;
        },
    };
    fac.create = {
        permission(customerId, scheduleId) {
            return CurrentOld.can(`customers.${customerId}.schedules.${scheduleId}.comments.create`);
        },
        query(customerId, scheduleId, body) {
            return res.save({
                customer: customerId,
                schedule: scheduleId,
            }, {
                body,
            }).$promise;
        },
    };
    fac.update = {
        permission(customerId, scheduleId, commentId) {
            return CurrentOld.can(`customers.${customerId}.schedules.${scheduleId}.comments.${commentId}.update`);
        },
        query(customerId, scheduleId, commentId, body) {
            return res.update({
                customer: customerId,
                schedule: scheduleId,
                comment: commentId,
            }, {
                body,
            }).$promise;
        },
    };
    fac.delete = {
        permission(customerId, scheduleId, commentId) {
            return CurrentOld.can(`customers.${customerId}.schedules.${scheduleId}.comments.${commentId}.delete`);
        },
        query(customerId, scheduleId, commentId) {
            return res.delete({
                customer: customerId,
                schedule: scheduleId,
                comment: commentId,
            }).$promise;
        },
    };
    return fac;
} ]);
