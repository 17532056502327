// @ts-nocheck
import { module } from 'angular';
module('eaw.staffing').factory('staffingDataFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/vs_templates/:template/intervals/:interval/data/:data');
    fac.getAll = {
        permission(customerId, templateId, intervalId) {
            return `customers.${customerId}.vs_templates.${templateId}.intervals.${intervalId}.data.*.get`;
        },
        query(customerId, templateId, intervalId, args) {
            return res.get({
                ...Pagination.getParams(args),
                customer: customerId,
                template: templateId,
                interval: intervalId,
            });
        },
    };
    fac.get = {
        permission(customerId, templateId, intervalId, dataId) {
            return `customers.${customerId}.vs_templates.${templateId}.intervals.${intervalId}.data.${dataId}.get`;
        },
        query(customerId, templateId, intervalId, dataId, withs) {
            return res.get({
                customer: customerId,
                template: templateId,
                interval: intervalId,
                data: dataId,
                'with[]': withs,
            });
        },
    };
    fac.create = {
        permission(customerId, templateId, intervalId) {
            return `customers.${customerId}.vs_templates.${templateId}.intervals.${intervalId}.data.create`;
        },
        query(customerId, templateId, intervalId, data) {
            return res.save({
                customer: customerId,
                template: templateId,
                interval: intervalId,
            }, {
                data,
            });
        },
    };
    fac.update = {
        permission(customerId, templateId, intervalId, dataId) {
            return `customers.${customerId}.vs_templates.${templateId}.intervals.${intervalId}.data.${dataId}.update`;
        },
        query(customerId, templateId, intervalId, dataId, data) {
            return res.update({
                customer: customerId,
                template: templateId,
                interval: intervalId,
                data: dataId,
            }, {
                data,
            });
        },
    };
    fac.delete = {
        permission(customerId, templateId, intervalId, dataId) {
            return `customers.${customerId}.vs_templates.${templateId}.intervals.${intervalId}.data.${dataId}.delete`;
        },
        query(customerId, templateId, intervalId, dataId) {
            return res.delete({
                customer: customerId,
                template: templateId,
                interval: intervalId,
                data: dataId,
            });
        },
    };
    return fac;
} ]);
