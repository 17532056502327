export class UnitFilter {
    static format(language: string, number?: number, unit?: string, decimals = 0, unitDisplay: Intl.NumberFormatOptions['unitDisplay'] = 'short') {
        if (number == null) {
            return '';
        }

        try {
            return new Intl.NumberFormat(language, {
                maximumFractionDigits: decimals,
                minimumFractionDigits: decimals,
                style: 'unit',
                unit,
                unitDisplay,
            }).format(number);
        } catch (e) {
            console.error(e);
            return '';
        }
    }
}
