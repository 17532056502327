import { Injectable } from '@angular/core';
import { Mobile } from '../../shared/utils/eaw-mobile';
import { BehaviorSubject } from 'rxjs';
import { TokenStorageKeys } from '../../auth/typings/token-storage-keys';
import { Storage } from '../../shared/utils/storage';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BaseLoaderService {
    private loadedSubject = new BehaviorSubject<boolean>(false);

    /**
     * Handle the token so that what's stored in mobile storage is exposed to local storage for oauth2
     * And convert the old token format to the new if the user has an old token (either in mobile storage or local storage)
     */
    private async handleToken() {
        const accessToken = await Storage.getItem(TokenStorageKeys.ACCESS_TOKEN);
        const accessTokenStoredAt = await Storage.getItem(TokenStorageKeys.ACCESS_TOKEN_STORED_AT);
        const accessTokenExpiresAt = await Storage.getItem(TokenStorageKeys.EXPIRES_AT);
        const accessTokenRefreshToken = await Storage.getItem(TokenStorageKeys.REFRESH_TOKEN);

        if (Mobile.isMobile && accessToken && accessTokenStoredAt && accessTokenExpiresAt && accessTokenRefreshToken) {
            await Storage.setItem(TokenStorageKeys.ACCESS_TOKEN, accessToken);
            await Storage.setItem(TokenStorageKeys.ACCESS_TOKEN_STORED_AT, accessTokenStoredAt);
            await Storage.setItem(TokenStorageKeys.EXPIRES_AT, accessTokenExpiresAt);
            await Storage.setItem(TokenStorageKeys.REFRESH_TOKEN, accessTokenRefreshToken);
        }
    }

    private initMobile() {
        return new Promise<void>(async (resolve) => {
            // This is a MUST, and must be first
            Mobile.init();

            // We have our own splash
            Mobile.removeSplash();

            // Set the status bar color to the body color while we are not logged in, it's updated in out header component
            Mobile.setStatusBarColor();

            Mobile.pause(function pause() {
                return false;
            });

            Mobile.resume(function resume() {
                return false;
            });

            const device = await Mobile.getDevice();

            if (device?.version) {
                document.body.classList.add('mobile', `mobile-${device.version.toLowerCase()}`);
            }

            resolve();
        });
    }

    async init() {
        newrelic.setApplicationVersion(environment.version);

        if (Mobile.isMobile) {
            await this.initMobile();
        }

        await this.handleToken();

        // Done
        this.loadedSubject.next(true);
    }

    onLoaded() {
        return this.loadedSubject.asObservable();
    }
}
