import { pick } from 'lodash-es';
import { uniqueId } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import WidgetGrid, { GridHeight, GridSize, GridWidth } from '../factories/widget-grid.factory';
import { WidgetInfoObject } from '../factories/widget-info.factory';
import { firstValueFrom } from 'rxjs';
import { CurrentOld } from '../../shared/angularjs/current.factory';
import { EawResourceFactory } from '../../shared/angularjs/modules/resource/resource.service';

export default class Widget {
    private $rootScope: any;
    private colorpickerService: any;
    private UserPropertyDowngraded: any;

    name!: string;
    user: any;
    customer: any;
    employee: any;
    key: string;
    exists: boolean;
    widgetInfo!: WidgetInfoObject;
    grid!: GridSize;
    settings: Record<string, any> = {};
    headerColor!: string;
    customerColor!: string;
    settingsDialog: any;
    disabled: boolean = false;
    i18name!: string;
    mini?: boolean;
    hasRequirements: boolean = false;
    requiresEmployee?: boolean;
    permissions: string[] = [];
    somePermissions: string[] = [];
    excludeFromSetup?: boolean;
    products: string[] = [];
    productSettings: any;
    backgroundColor!: string;
    multiple?: boolean;
    namespaces?: string[];
    default: boolean = false;
    propertyResponse: any;

    private requiredSettings?: Record<string, string>;

    constructor(widget: any, user: unknown, employee: unknown, $injector: any, settings: Record<string, any>, hidden: string[], widgetInfo: WidgetInfoObject|undefined) {
        this.$rootScope = $injector.get('$rootScope');
        this.colorpickerService = $injector.get('colorpickerService');
        this.UserPropertyDowngraded = $injector.get('UserPropertyDowngraded');

        this.user = user;
        this.employee = employee;
        this.key = widget.key || uniqueId('Widget'); // Our unique identifier

        let property;

        this.propertyResponse = widget;
        if (typeof widget.value === 'string') {
            try {
                property = JSON.parse(widget.value);
            } catch (e) {
                console.error('e', e);
            }
        }

        const w = property || widget;

        // Check exists
        this.exists = !!widgetInfo;
        if (!widgetInfo) {
            return;
        }

        if (property) {
            this.widgetInfo = widgetInfo;
            this.grid = WidgetGrid.setSize(w.gridWidth, w.gridHeight);
        } else {
            this.widgetInfo = widget;
            this.grid = widget.grid;
        }

        this.settings = w.settings;
        Object.assign(this, {
            index: parseInt(w?.settings?.index || 0),
            customer: w.customer,
            customerColor: this.widgetInfo?.settings?.['customerColor'],
            default: !!this.widgetInfo.default,
            disabled: !!this.widgetInfo.disabled,
            multiple: !!this.widgetInfo.multiple,
            excludeFromSetup: !!this.widgetInfo.excludeFromSetup,
            mini: !!this.widgetInfo.mini,
            backgroundColor: this.settings?.['backgroundColor'] || 'white',
        },
        pick(this.widgetInfo, [
            'name',
            'component',
            'icon',
            'i18name',
            'namespaces',
            'permissions',
            'somePermissions',
            'productSettings',
            'products',
            'requiresEmployee',
            'settingsDialog',
        ]));

        this.requiredSettings = this.widgetInfo.requiredSettings;
        this.headerColor = this.customerColor || this.settings?.['headerColor'] || 'accent-200';
        if (this.headerColor.includes('eaw')) {
            // This is to keep the color people who haven't changed the color of their widget from the default.
            this.headerColor = 'accent-200';
        }

        this.setHasRequirements(hidden, settings);
    }

    /**
     * Checks and sets if the widget has requirements
     *
     * Also logs out why it doesn't have requirements for easier debugging
     */
    setHasRequirements(hidden: string[], settings?: Record<string, any>) {
        if (this.disabled) {
            console.debug(this.name, `disabled`);
            this.hasRequirements = false;
            return;
        }

        if (hidden.includes(this.name)) {
            console.debug(this.name, `hidden`);
            this.hasRequirements = false;
            return;
        }

        // Make sure we have an employee if employee is required
        this.hasRequirements = this.requiresEmployee ? !!this.employee : true;

        if (!this.hasRequirements) {
            console.debug(this.name, `missing employee`);
            return;
        }

        // Check permissions if we have requirements so far
        if (this.hasRequirements) {
            this.hasRequirements = this.permissions.every((p) => CurrentOld.can(p));
        }

        if (!this.hasRequirements) {
            console.debug(this.name, `missing permissions`);
            return;
        }

        // Check some permissions if we have requirements so far
        if (this.hasRequirements) {
            this.hasRequirements = this.somePermissions.length ? this.somePermissions.some((p) => CurrentOld.can(p)) : true;
        }

        if (!this.hasRequirements) {
            console.debug(this.name, `missing some permissions`);
            return;
        }

        // If we have requirements so far, check products
        if (this.hasRequirements) {
            this.hasRequirements = this.products.map((p) => CurrentOld.hasProduct(p)).every((p) => p);
        }

        if (!this.hasRequirements) {
            console.debug(this.name, `missing products`);
            return;
        }

        if (this.hasRequirements && this.requiredSettings && Object.values(this.requiredSettings).length) {
            this.hasRequirements = settings ? Object.entries(this.requiredSettings).every(([ key, val ]) => settings[key] == val) : false;
        }

        if (!this.hasRequirements) {
            console.debug(this.name, `missing settings`);
        }
    }

    async changeBackgroundColor() {
        const color = await this.colorpickerService.open(this.backgroundColor);
        this.backgroundColor = color;
        this.settings['backgroundColor'] = color;
        void this.update();

        return color;
    }

    changeHeaderColor() {
        return this.colorpickerService.open(this.headerColor).then((color: any) => {
            this.headerColor = color;
            this.settings['headerColor'] = color;
            void this.update();

            return color;
        });
    }

    setOrder(index: number) {
        this.settings['order'] = index;
        void this.update();
    }

    setSettings(settings: any) {
        this.settings = settings;
        void this.update();
    }

    changeWidth(width: GridWidth) {
        this.grid = WidgetGrid.setSize(width, this.grid.height);
        return this.update();
    }

    changeHeight(height: GridHeight) {
        this.grid = WidgetGrid.setSize(this.grid.width, height);
        return this.update();
    }

    update() {
        return firstValueFrom(this.UserPropertyDowngraded.update(this.user.id, this.key, JSON.stringify({
            name: this.name,
            customer: this.customer,
            settings: this.settings,
            gridWidth: this.grid.width,
            gridHeight: this.grid.height,
        }))).then((resp) => resp);
    }

    delete() {
        return firstValueFrom(this.UserPropertyDowngraded.delete(this.user.id, this.key)).then(() => {
            this.$rootScope.$broadcast('widget:deleted', this);
        });
    }

    static async getHiddenWidgets(EawResource: EawResourceFactory, userId: number, customerId: number) {
        const permission = `ui.customers.${customerId}.dashboard.widgets`;

        const response = await EawResource.create(`/users/${userId}/is_allowed_many`).save({}, {
            mode: 'permissionChildren',
            permissions: [ permission ],
            filter: 'visible',
            filter_value: false,
        }).$promise;

        return response[permission] as string[];
    }
}
