import { inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { LoginService } from '../services/login.service';
import { TranslateService } from '../services/translate.service';

@Injectable({
    providedIn: 'root',
})
export class RequireLoginHookService {
    private readonly translate = inject(TranslateService);
    private readonly loginService = inject(LoginService);
    private readonly uiRouter = inject(UIRouter);

    constructor() {
        // All states that requires you to be logged in
        this.uiRouter.transitionService.onBefore({
            to(state) {
                return !!state?.data?.requireLogin;
            },
        }, async (transition) => {
            console.debug('🪝', 'RequireLoginHookService.onBefore', transition);

            if (!this.loginService.isLoggedIn()) {
                // Remove any stored data
                await this.loginService.logout(undefined, { message: this.translate.t('NOT_AUTHORIZED', 'errors') });
                // Redirect to login page if you are not logged in
                return this.uiRouter.stateService.target('eaw/outside/login');
            }
        }, { priority: 9999 }); // Highest priority because login checks are very important

        // If you go to /login, but you are logged in, go  to dashboard
        this.uiRouter.transitionService.onBefore({
            to: 'eaw/outside/login',
        }, (transition) => {
            console.debug('🪝', 'RequireLoginHookService.onBefore', transition);

            if (this.loginService.isLoggedIn()) {
                // Log out if logged in
                return this.uiRouter.stateService.target('eaw/app/home');
            }
        }, { priority: 9999 }); // Highest priority because login checks are very important
    }
}
