import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { SimplePermission } from '../../../shared/interfaces/simple-permission';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { MatDialogActions, MatDialogClose, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'eaw-add-permission-dialog',
    templateUrl: './add-permission-dialog.component.html',
    styleUrl: './add-permission-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AddPermissionDialogComponent extends DialogComponent<DialogData, SimplePermission[], AddPermissionDialogComponent> {
    allow = new FormControl<boolean>(false, Validators.required);
    // For use when assigning custom permission
    node = new FormControl<string>('', Validators.required);

    constructor(
        @Inject(MatDialogRef) dialogRef: MatDialogRef<AddPermissionDialogComponent, SimplePermission[]>,
    ) {
        super(dialogRef, { size: DialogSize.Medium });
    }

    addToList(input?: string | null) {
        if (!input) {
            return;
        }

        const permissionStrings = input.split(/\s+/g).filter((permissionStr) => permissionStr.length > 0);
        const permissions: SimplePermission[] = [];

        permissionStrings.forEach((i) => {
            const sp: SimplePermission = {
                node: i,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                value: this.allow.value!,
                created: DateTime.now().toLocaleString(DateTime.DATETIME_SHORT),
            };
            permissions.push(sp);
        });

        this.dialogRef.close(permissions);
    }
}
