// @ts-nocheck
import { module } from 'angular';
import { Namespace } from '../shared/enums/namespace';
import { Products } from '../shared/enums/products';
import { createState } from '../shared/utils/create-state';
const template1 = `<md-card id="checklist-view-card">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" ng-bind="cvCtrl.checklist.title"></span>
            <percent class="md-subhead" ng-if="!cvCtrl.checklist.closed_at" ng-model="cvCtrl.cs.percentages[0].percentage"></percent>
            <span class="md-subhead" ng-if="cvCtrl.checklist.closed_at" ng-i18next="checklists:CLOSED"></span>
        </md-card-header-text>

        <md-menu md-position-mode="target-right target">
            <md-button class="md-icon-button" ng-click="$mdMenu.open()">
                <md-icon ng-bind="'more_vert'"></md-icon>
            </md-button>
            <md-menu-content>
                <md-menu-item>
                    <md-button ng-click="cvCtrl.closeChecklist()">
                        {{'checklists:CLOSE_CHECKLIST' | i18next }}
                    </md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <checklist-children checklist="cvCtrl.checklist" children="cvCtrl.checklist.children"></checklist-children>
    </md-card-content>
</md-card>
`;
module('eaw.checklists', [ 'eaw' ]).config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state(`eaw/app/checklists`, {
        parent: 'eaw/app',
        url: '/checklists',
        views: {
            'content@': 'eawNavBar',
        },
        data: {
            products: [ Products.Checklists ],
            i18nextNs: [ Namespace.Checklists ],
            breadcrumb: { key: 'CHECKLISTS', ns: 'navigation' },
        },
        resolve: {
            navbarRoute: () => 'eaw/app/checklists',
            tabs: function() {
                return [
                    {
                        label: 'navigation:CHECKLISTS',
                        src: 'eaw/app/checklists/list',
                    },
                    {
                        label: 'navigation:CHECKLISTS_TEMPLATES',
                        src: 'eaw/app/checklists/templates',
                    },
                ];
            },
        },
    });
    createState($stateProvider, {
        name: `eaw/app/checklists/list`,
        parent: `eaw/app/checklists`,
        url: '/list',
        component: 'checklistList',
        data: {
            permissions: [ `customers.{customer}.checklists.*.get` ],
            breadcrumb: { key: 'CHECKLISTS', ns: 'checklists' },
        },
    });

    $stateProvider.state(`eaw/app/checklists/templates`, {
        parent: `eaw/app/checklists`,
        url: '/templates',
        component: 'checklistTemplateList',
    });
    $stateProvider.state(`eaw/app/checklists/edit`, {
        parent: `eaw/app/checklists/templates`,
        url: '/edit/:id',
        views: {
            'content@': 'eawChecklistTemplate',
        },
        data: {
            permissions: [ 'customers.{customer}.checklists.create' ],
            breadcrumb: { key: 'CHECKLIST', ns: 'checklist' },
        },
        resolve: {
            checklist: [ 'customer', 'ChecklistFactory', '$transition$', function(customer: any, ChecklistFactory: any, $transition$: any) {
                return ChecklistFactory.get(customer.id, $transition$.params().id).$promise;
            } ],
            autoStartService: [ 'AutoStartChecklist', '$transition$', 'customer', function(AutoStartChecklist: any, $transition$: any, customer: any) {
                return new AutoStartChecklist(customer.id, $transition$.params().id);
            } ],
            autoStart: [ 'autoStartService', function(autoStartService: any) {
                return autoStartService.get();
            } ],
        },
    });
    $stateProvider.state(`eaw/app/checklists/view`, {
        parent: `eaw/app/checklists/list`,
        url: '/:id',
        views: {
            'content@': {
                template: template1,
                controller: 'ChecklistsViewCtrl',
                controllerAs: 'cvCtrl',
            },
        },
        data: {
            breadcrumb: { key: 'CHECKLIST', ns: 'checklists' },
            permissions: [ 'customers.{customer}.checklists.{id}.get' ],
        },
        resolve: {
            checklist: [ 'customer', 'ChecklistFactory', '$transition$', function(customer: any, ChecklistFactory: any, $transition$: any) {
                return ChecklistFactory.get(customer.id, $transition$.params().id, [
                    'comments',
                    'attachments.user',
                    'allItems.warnings',
                    'allItems.todoItems.status',
                ]).$promise.then((data: any) => {
                    if (data.is_template) {
                        return Promise.reject('template');
                    }
                    return data;
                });
            } ],
        },
    });
} ]);
