import { Inject, Injectable } from '@angular/core';
import { TodoResponse } from '../models/todo';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { Attachment, AttachmentResponse } from '../../shared/models/attachment';
import { FileUploadService } from '../../shared/services/file-upload.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class TodoAttachmentService {

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(FileUploadService) protected fileUploadService: FileUploadService,
    ) {
    }

    create(customerId: number, todoId: number, attachment: File) {
        return this.fileUploadService.upload<AttachmentResponse<TodoResponse>>(`/customers/${customerId}/todo_items/${todoId}/attachments`, {
            attachment,
        }).pipe(classifyItem(Attachment));
    }
}
