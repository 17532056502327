// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { TinyColor } from '@ctrl/tinycolor';
const colorpickerDialogTemplate = `<md-dialog id="colorpicker-dialog">
    <eaw-dialog-header-old background="{{selectedColor}}-{{intensity*100}}" heading="'COLOR_PICKER' | i18next"></eaw-dialog-header-old>

    <md-dialog-content>
        <div class="md-dialog-content" layout="column">
            <div class="md-subhead" ng-i18next="COLOR_INTENSITY"></div>

            <md-slider-container>
                <md-slider class="md-primary" flex ng-model="intensity" step="1" min="1" max="6"></md-slider>
            </md-slider-container>

            <div id="colors">
                <md-button class="md-raised"
                           ng-repeat="name in ::colorNames track by name"
                           md-colors="{background: '{{name}}-{{intensity*100}}'}"
                           md-colors-watch="true"
                           ng-click="changeColor(name)">
                    <span>{{::getTranslatedName(name)}}</span>
                </md-button>
            </div>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="BACK"></md-button>
        <md-button ng-click="hide()" ng-i18next="SELECT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
module('eaw.components').service('colorpickerService', [ '$mdDialog', '$mdColorPalette', '$mdColors', function colorpickerService($mdDialog, $mdColorPalette, $mdColors) {
    const service = this;
    const intensities = [ 50, 100, 200, 300, 400, 500, 600, 700, 800, 900 ];
    service.getRandomColor = () => {
        const colorKeys = Object.keys($mdColorPalette);
        const name = colorKeys[Math.floor(Math.random() * colorKeys.length)]; // Random item
        const hue = (Math.floor(Math.random() * 5) + 1) * 100;
        return `${name}-${hue}`;
    };
    service.getRandomHexColor = () => {
        const color = service.getRandomColor();
        return service.getHex(color);
    };
    service.translateColor = (color, includeIntensity = false) => {
        const key = color.substring(0, color.lastIndexOf('-')).replace('-', '_').toUpperCase();
        const split = color.split('-');
        const intensity = split[split.length - 1] / 100;
        if (includeIntensity && key !== 'BLACK' && key !== 'WHITE') {
            return `${t(`colors:${key}`)} (${intensity})`;
        }
        return t(`colors:${key}`);
    };
    service.getHex = (color) => {
        if (color instanceof TinyColor) {
            if (typeof color.originalInput === 'string' && color.originalInput.includes('-')) {
                color = color.originalInput;
            } else {
                return color.toHexString(true);
            }
        }
        if (typeof color !== 'string') {
            return '#fff';
        }
        if (color.startsWith('#')) {
            return color;
        }
        const parts = color.split('-'); // Split for easy processing
        const last = parts[parts.length - 1]; // Get last item
        const hasHue = /\d+/.test(last); // Check if last is a hue
        const hue = hasHue ? last : '500'; // Default hue if no hue
        let name;
        if (hasHue) {
            name = parts.slice(0, -1).join('-'); // Remove last if it's a hue and join the name
        } else {
            name = parts.join('-'); // If no hue then just join the name
        }
        switch (name) {
            case 'black':
                return '#000000';
            case 'white':
                return '#ffffff';
            default:
                return $mdColorPalette[name]?.[hue]?.hex || $mdColors.getThemeColor('accent');
        }
    };
    service.hexToMdColor = (hexCode) => {
        // Return what was passed in if it doesn't start with a hash
        if (hexCode && !hexCode.startsWith('#')) {
            return hexCode;
        }
        switch (hexCode) {
            case '#000':
                return 'black-500';
            case '#fff':
                return 'white-500';
            case '#000000':
                return 'black-500';
            case '#ffffff':
                return 'white-500';
            default: {
                let color = 'black'; // Default value
                Object.keys($mdColorPalette).forEach((key) => {
                    intensities.forEach((intensity) => {
                        if ($mdColorPalette[key][intensity].hex === hexCode) {
                            color = `${key}-${intensity}`;
                        }
                    });
                });
                return color;
            }
        }
    };
    service.open = (color, intensity) => {
        return $mdDialog.show({
            template: colorpickerDialogTemplate,
            multiple: true,
            controller: [ '$scope', function($scope) {
                const MIN_INTENSITY = 1;
                const MAX_INTENSITY = 6;
                $scope.intensity = intensity >= 1 ? intensity : 2;
                // Check intensity range
                if (intensity < MIN_INTENSITY || intensity > MAX_INTENSITY) {
                    $scope.intensity = 5;
                }
                if (color) {
                    // Check if hex and convert to md-color.
                    if (color.startsWith('#')) {
                        color = service.hexToMdColor(color);
                    }
                    const pieces = color.split('-');
                    $scope.selectedColor = pieces.length > 2 ? `${pieces[0]}-${pieces[1]}` : pieces[0];
                    $scope.intensity = parseInt(pieces.pop()) / 100;
                } else {
                    $scope.selectedColor = 'white';
                }
                $scope.colorNames = Object.keys($mdColorPalette);
                $scope.colorNames.push('black', 'white');
                $scope.getTranslatedName = (name) => {
                    return service.translateColor(`${name}-500`);
                };
                $scope.changeColor = (name) => {
                    $scope.selectedColor = name;
                };
                $scope.hide = () => {
                    $mdDialog.hide(service.getHex(`${$scope.selectedColor}-${$scope.intensity * 100}`));
                };
            } ],
        });
    };
    return service;
} ]);
