import { Inject, Injectable } from '@angular/core';
import { SidenavComponent, SidenavTab } from '../components/sidenav/sidenav.component';
import { createSidenavChildState, createState, StateProviderDataData } from '../utils/create-state';
import { sidenavTabsResolver } from '../resolvers/sidenav-tabs.resolver';
import { UIRouter } from '@uirouter/core';
import { ComponentType } from '@angular/cdk/overlay';
import { staticResolver } from '../resolvers/static.resolver';
import { Resolvable } from '../types/resolvable';

export interface SidenavTabsRegisterServiceData {
    // The parent state of where the sidenav will be rendered
    sidenavParentState: string;
    // The state of the sidenav
    sidenavState: string;
    // The url of the sidenav, has to start with a "/"
    url: string;
    data?: StateProviderDataData;
    // Component to render in the header of the sidenav
    headerComponent?: ComponentType<unknown>;
    // Hide the right chevron icon on every tab?
    hideGoIcon?: boolean;
    resolve?: Resolvable[];
}

@Injectable({
    providedIn: 'root',
})
export class SidenavTabsRegisterService {
    constructor(
        @Inject(UIRouter) protected router: UIRouter,
    ) {
    }

    protected registerTabs(data: SidenavTabsRegisterServiceData, tabs: SidenavTab[]) {
        // Create / register the child states
        tabs.forEach((s) => {
            createSidenavChildState(this.router.stateProvider, {
                ...data,
                parent: data.sidenavState,
                component: s.component,
                data: s.data,
                url: s.url,
                name: `${data.sidenavState}${s.url}`,
                resolve: s.resolve,
                params: s.params,
            });
        });

        // Create the state for the sidenav
        createState(this.router.stateProvider, {
            ...data,
            name: data.sidenavState,
            parent: data.sidenavParentState,
            url: data.url,
            abstract: true,
            views: {
                'content@': {
                    component: SidenavComponent,
                },
            },
            data: data.data,
            resolve: [
                ...data.resolve ?? [],
                staticResolver('headerComponent', data.headerComponent),
                staticResolver('hideGoIcon', data.hideGoIcon),
                sidenavTabsResolver(() => Promise.resolve(tabs)),
            ],
        });
    }
}
