import { t } from 'i18next';
import CustomerOld from '../../../../shared/angularjs/customer-old';
import MlBucket from '../../shared/ml-bucket';

import moment from 'moment-timezone';
import { EawResourceFactory } from '../../../../shared/angularjs/modules/resource/resource.service';

export default class SavingDialogController {
    static get $inject() {
        return [ 'EawResource', '$mdDialog' ];
    }

    currentSaving: any;
    entries!: any[];
    backendDateFormat!: string;
    customer!: CustomerOld;
    bucket!: MlBucket;
    adjustmentEnd?: number;
    adjustmentEndRemaining = 0;
    complete = 0;
    done = false;
    isFirst = true;

    constructor(
        private EawResource: EawResourceFactory,
        private $mdDialog: any,
    ) {
    }

    $onInit() {
        void this.adjust();
    }

    async adjust() {
        let totalCompDates = 0;
        const dates = this.entries.filter((e) => e.hasBudget).map((d) => {
            const comp_dates = Object.values(d.compDates).filter((c: any) => !c.error).map((c: any) => c.backendFormat);
            totalCompDates += comp_dates.length;

            return {
                date: d.date.format(this.backendDateFormat),
                adjustment: d.adjustment,
                comp_dates,
            };
        });

        const resource = this.EawResource.create(`/customers/${this.customer['id']}/ml_buckets/${this.bucket.id}/mcd_suisse/adjust_projections`);

        for (let i = 1; i <= dates.length; i++) {
            const date = dates[i - 1];
            this.isFirst = i === 1;

            try {
                this.currentSaving = {
                    ...date,
                    textDate: moment(date!.date, 'YYYY-MM-DD').format('LL'),
                    xofy: t('widgets:X_OF_Y', {
                        x: i,
                        y: dates.length,
                    }),
                };

                await resource.save({}, { dates: [ date ] }).$promise;
            } catch (_) {
                console.error('Failed to save: ', date);
            }

            this.complete = i / dates.length * 100;
            this.done = true;
        }

        this.$mdDialog.hide();
    }
}
