// @ts-nocheck
import { orderBy } from 'lodash-es';
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';
module('eaw.calendar2').controller('syncEventDialogCtrl', [ 'EawResource', 'clipboardService', function(EawResource, clipboardService) {
    const ctrl = this;
    ctrl.$onInit = async () => {
        ctrl.loading = true;
        ctrl.locations = Object.values(CurrentOld.customers).reduce((arr, location) => arr.concat({
            id: location.id,
            name: location.name,
            selected: true,
        }), []);
        ctrl.locations = orderBy(ctrl.locations, (l) => l.name.toLowerCase());
        await Promise.all([
            ctrl.getToken(),
            ctrl.getTypes(),
        ]);
        ctrl.updateUrl();
        ctrl.loading = false;
    };
    ctrl.copy = () => {
        clipboardService.copy(ctrl.url);
    };
    ctrl.locationClicked = () => {
        const val = !ctrl.locationChecked();
        ctrl.locations.forEach((l) => {
            l.selected = val;
        });
        ctrl.updateUrl();
    };
    ctrl.locationChecked = () => ctrl.locations.every((l) => l.selected);
    ctrl.locationIndeterminate = () => !ctrl.locationChecked() && ctrl.locations.some((l) => l.selected);
    ctrl.typeClicked = () => {
        const val = !ctrl.typeChecked();
        ctrl.types.forEach((t) => {
            t.selected = val;
        });
        ctrl.updateUrl();
    };
    ctrl.typeChecked = () => ctrl.types.every((t) => t.selected);
    ctrl.typeIndeterminate = () => !ctrl.typeChecked() && ctrl.types.some((t) => t.selected);
    ctrl.updateUrl = () => {
        ctrl.url = `https://api.easyatwork.com/customers/u/calendar2/icalendar?api_token=${ctrl.token}`;
        ctrl.error = null;
        let locations = ctrl.locations.filter((l) => l.selected);
        if (!locations.length) {
            ctrl.error = 'At least one location has to be selected';
            ctrl.url = '---';
            return;
        }
        const types = ctrl.types.filter((t) => t.selected);
        if (!types.length) {
            ctrl.error = 'At least one type has to be selected';
            ctrl.url = '---';
            return;
        }
        types.forEach((type) => {
            ctrl.url += `&types[]=${type.key}`;
        });
        if (locations.length === ctrl.locations.length) {
            return;
        }
        locations = locations.reduce((string, loc) => `${string},${loc.id}`, '').slice(1);
        ctrl.url = ctrl.url.replace('/u/', `/${locations}/`);
    };
    ctrl.getToken = () => EawResource.create(`me/token`).save().$promise.then((resp) => {
        ctrl.token = resp.api_token;
    });
    ctrl.getTypes = () => EawResource.create(`customers/${ctrl.customerId}/calendar2/my_calendar_types`).get().$promise.then((resp) => {
        ctrl.types = Object.entries(resp).reduce((arr, entry) => {
            const [ key, name ] = entry;
            if (key.startsWith('$')) {
                return arr;
            }
            return arr.concat({
                key,
                name,
                selected: true,
            });
        }, []);
    });
} ]);
