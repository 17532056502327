// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { EawChart } from '../services/eaw-chart';

module('eaw.misc').component('timeline', {
    template: '<div id="chart-container"></div>',
    bindings: {
        rows: '<',
        title: '@',
    },
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = function() {
            const categories = [];
            let y = 0;
            const data = ctrl.rows.map((row) => {
                categories.push(row.name);
                return {
                    x: row.from.unix() * 1000,
                    x2: row.to.unix() * 1000,
                    y: y++,
                };
            });

            new EawChart('chart-container', {
                chart: {
                    type: 'xrange',
                },
                title: {
                    text: ctrl.title,
                },
                tooltip: {
                    shared: true,
                    formatter() {
                        const point = this.points[0].point;
                        return `<strong>${moment(point.x).format('LL')} - ${moment(point.x2).format('LL')}</strong>`;
                    },
                },
                xAxis: {
                    type: 'datetime',
                },
                yAxis: {
                    title: {
                        text: '',
                    },
                    categories,
                    reversed: true,
                },
                series: [ {
                    name: 'series 1',
                    pointWidth: 20,
                    data,
                } ],
                legend: {
                    enabled: false,
                },
            });
        };
    },
});
