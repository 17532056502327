import { inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { MatDialog } from '@angular/material/dialog';
import { CurrentService } from '../services/current.service';
import { LearningModuleService } from '../../learning-module/services/learning-module.service';
import { UserPropertyService } from '../http/user-property.service';
import { catchError, EMPTY, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { WelcomeDialogComponent } from '../../learning-module/components/welcome-dialog/welcome-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class WelcomeHookService {
    private readonly uiRouter = inject(UIRouter);
    private readonly matDialog = inject(MatDialog);
    private readonly currentService = inject(CurrentService);
    private readonly learningModuleService = inject(LearningModuleService);
    private readonly userPropertyService = inject(UserPropertyService);

    constructor() {
        this.uiRouter.transitionService.onSuccess({
            to: (state) => {
                return state?.data?.requireLogin && this.currentService.getMe().acceptedTos;
            },
        }, (transition) => {
            console.debug('🪝', 'WelcomeHookService.onSuccess', transition);

            this.openDialog();
        }, {
            invokeLimit: 1,
        });
    }

    private openDialog() {
        this.learningModuleService.hasAccess().pipe(
            switchMap((hasAccess) => {
                if (hasAccess) {
                    return this.userPropertyService.get(this.currentService.getMe().authedAs, 'received_welcome_popup').pipe(
                        catchError((error: HttpErrorResponse) => {
                            if (error.status === 404) {
                                this.matDialog.open(WelcomeDialogComponent);
                            }

                            return EMPTY;
                        }),
                    );
                }

                return EMPTY;
            }),
        ).subscribe((result) => {
            if (result.value.asBoolean() === false) {
                this.matDialog.open(WelcomeDialogComponent);
            }
        });
    }
}
