import { ApiResponse } from '../../shared/interfaces/api-response';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { BaseApiModel } from '../../shared/models/base-api-model';

export interface ContractHourDayResponse extends ApiResponse {
    created_at: string;
    distribution_id: number;
    hours: number;
    id: number;
    index: number;
    updated_at: string;
}

export class ContractHourDay extends BaseApiModel<ContractHourDayResponse, ContractHourDay> {
    id: number;
    distributionId: number;
    hours: number;
    index: number;
    createdAt: DateTime;
    updatedAt: DateTime;

    constructor(data: ContractHourDayResponse) {
        super(data, undefined);

        this.id = data.id;
        this.distributionId = data.distribution_id;
        this.hours = data.hours;
        this.index = data.index;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
    }
}
