import { module } from 'angular';

class ContractRequalificationInfoContainer {
    $mdDialog;

    static $inject = [ '$mdDialog' ];

    constructor($mdDialog: any) {
        this.$mdDialog = $mdDialog;
    }

    close() {
        this.$mdDialog.hide();
    }
}

module('eaw.dashboard').component('contractRequalificationInfoContainer', {
    template: `
    <contract-requalification-info-downgrade class="tw-p-16" (stateshift)="$info.close()"></contract-requalification-info-downgrade>
    `,
    bindings: {},
    controllerAs: '$info',
    controller: ContractRequalificationInfoContainer,
});
