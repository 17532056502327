<mat-card id="schedule-card">
    @if (loadingInitial()) {
        <eaw-info-loading direction="column" size="xl">
            <span>Loading required information</span>
        </eaw-info-loading>
    } @else {
        <mat-tab-group id="tabs" [mat-stretch-tabs]="false" animationDuration="300" (selectedTabChange)="tabChange($event)" [selectedIndex]="initialTabIndex()">
            @for (tab of tabs(); track tab.name) {
                <mat-tab>
                    <ng-template matTabLabel>
                        <div class="tw-flex tw-items-center tw-gap-8">
                            @if (tab.name === 'warnings') {
                                <mat-icon>warning</mat-icon>
                            }

                            <span>{{tab.label | async}}</span>
                        </div>
                    </ng-template>

                    <ng-template matTabContent>
                        <div class="tab-content">
                            @switch (tab.name) {
                                @case ('schedule') {
                                    <eaw-schedule-tab [stackId]="stackId" [customerId]="customerId" [scheduleId]="scheduleId"/>
                                }
                                @case ('employees') {
                                    <eaw-schedule-employees-tab [stackId]="stackId" [customerId]="customerId" [scheduleId]="scheduleId"/>
                                }
                                @case ('week_overview') {
                                    Not implemented
                                }
                                @case ('total') {
                                    Not implemented
                                }
                                @case ('warnings') {
                                    Not implemented
                                }
                                @case ('autogen') {
                                    Not implemented
                                }
                            }
                        </div>
                    </ng-template>
                </mat-tab>
            }
        </mat-tab-group>
    }
</mat-card>

<mat-card id="sidebar-card">
    @if (!loadingInitial()) {
        <eaw-schedule-sidebar [stackId]="stackId" [customerId]="customerId" [scheduleId]="scheduleId"></eaw-schedule-sidebar>
    }
</mat-card>

