export enum TokenStorageKeys {
    /**
     * @deprecated
     * The old token format
     */
    TOKEN = 'token',
    ACCESS_TOKEN = 'access_token',
    ACCESS_TOKEN_STORED_AT = 'access_token_stored_at',
    EXPIRES_AT = 'expires_at',
    REFRESH_TOKEN = 'refresh_token',
}
