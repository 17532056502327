<eaw-page-header>
    <span title>{{'INFORMATION' | translate | async}}</span>
</eaw-page-header>

<div class="tw-flex tw-place-content-center">
    <mat-progress-spinner *ngIf="!user" mode="indeterminate"></mat-progress-spinner>
</div>

<mat-accordion *ngIf="user">
    <mat-expansion-panel #generalPanel [expanded]="true" hideToggle (closed)="generalPanel.open()">
        <mat-expansion-panel-header>
            <mat-panel-title>{{'GENERAL_INFO' | translate:'profile' | async}}</mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="userForm" class="tw-grid tw-grid-cols-1 tw-gap-8 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
            <mat-form-field>
                <mat-label>{{'FIRST_NAME' | translate | async}}</mat-label>
                <input matInput type="text" formControlName="firstName">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'LAST_NAME' | translate | async}}</mat-label>
                <input matInput type="text" formControlName="lastName">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'EMAIL' | translate | async}}</mat-label>
                <input matInput type="email" formControlName="email">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'PHONE' | translate | async}}</mat-label>
                <eaw-dial-phone-input formControlName="phone"></eaw-dial-phone-input>
                <mat-error><eaw-field-error [control]="userForm.controls.phone"></eaw-field-error></mat-error>
            </mat-form-field>

            <eaw-basic-autocomplete [label]="'LANGUAGE' | translate" [getList]="getLanguages" formControlName="language"></eaw-basic-autocomplete>

            <div class="tw-col-span-full">
                <button mat-raised-button color="accent" (click)="updateUser()" [disabled]="userForm.invalid || userForm.disabled || userForm.pristine">{{'UPDATE' | translate | async}}</button>
            </div>
        </form>
    </mat-expansion-panel>
</mat-accordion>

<eaw-page-header class="tw-mt-16" *ngIf="user">
    <span title>{{'AVAILABLE_LOCATION_plural' | translate:'profile' | async}}</span>
    <span subtitle>{{'EDIT_EMPLOYEE_INFO' | translate:'profile' | async}}</span>
</eaw-page-header>

<mat-accordion *ngIf="user" [multi]="true">
    <mat-expansion-panel *ngFor="let customer of customers" (opened)="profileInfoEmployeeComponent.getData()">
        <mat-expansion-panel-header>
            <mat-panel-title>{{customer.customer.name}}</mat-panel-title>
            <mat-panel-description>{{customer.employeeNumber | async}}</mat-panel-description>
        </mat-expansion-panel-header>

        <eaw-profile-info-employee [customer]="customer.customer" #profileInfoEmployeeComponent></eaw-profile-info-employee>

        <mat-action-row>
            <button mat-button color="primary"
                    [disabled]="profileInfoEmployeeComponent.form.disabled || profileInfoEmployeeComponent.loading || !profileInfoEmployeeComponent.employee"
                    (click)="profileInfoEmployeeComponent.update()">
                <span>{{'UPDATE' | translate | async}}</span>
            </button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>
