import { Component, Inject, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '../../../shared/services/translate.service';
import { ReportOption } from '../../interfaces/report-option';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'eaw-report-select',
    templateUrl: './report-select.component.html',
    styleUrl: './report-select.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ReportSelectComponent,
        },
    ],
    standalone: true,
    imports: [
        MatFormFieldModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        CheckboxHelperDirective,
        NgIf,
        MatSelectModule,
        NgFor,
        MatOptionModule,
    ],
})
export class ReportSelectComponent implements OnInit, ControlValueAccessor {
    select = new FormGroup({
        options: new FormControl<string[]>([]),
        checkbox: new FormControl(false),
    });

    @Input() option!: ReportOption;
    @Input() key!: string;

    protected onChange?: (value: unknown) => void;
    protected onTouch?: () => void;
    protected touched = false;
    label?: string;
    selectOptions!: [string, unknown][]; // Set on init

    constructor(@Inject(TranslateService) protected i18n: TranslateService) { }

    async ngOnInit() {
        const options = this.select.controls.options;
        const checkbox = this.select.controls.checkbox;

        // Preserve the order these options came in.
        this.selectOptions = Object.entries(this.option.options);

        options.valueChanges.subscribe((val) => {
            if (this.onChange) {
                this.onChange(val);
            }

            if (!this.touched) {
                if (this.onTouch) {
                    this.onTouch();
                }

                this.touched = true;
            }

            const all = val?.length == Object.keys(this.option.options).length;

            if (all != checkbox.value) {
                checkbox.setValue(!checkbox.value, { emitEvent: false });
            }
        });

        checkbox.valueChanges.subscribe((val) => {
            options.setValue(val ? Object.keys(this.option.options) : []);
        });

        this.label = await this.i18n.t(this.key.toUpperCase(), 'reports');
    }

    registerOnChange(fn: (value: unknown) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    writeValue(val?: string[]): void {
        this.select.get('options')?.setValue(val || []);
    }
}
