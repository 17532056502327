import { DateTime } from 'luxon';
import { Rotation, RotationResponse } from './rotation';
import { stringToDateTime } from '../../../shared/utils/string-to-date-time';
import { ApiResponse } from '../../../shared/interfaces/api-response';

export interface EmployeeRotationResponse extends ApiResponse {
    employee_id: number;
    id: number;
    rotation?: RotationResponse;
    rotation_id: number;
    from: string;
    to?: string | null;
}

export class EmployeeRotation {
    id: number;
    employeeId: number;
    rotation?: Rotation;
    rotationId: number;
    from: DateTime;
    to: DateTime | null;

    constructor(data: EmployeeRotationResponse) {
        this.id = data.id;
        this.employeeId = data.employee_id;
        this.rotation = data.rotation ? new Rotation(data.rotation) : undefined;
        this.rotationId = data.rotation_id;
        this.from = stringToDateTime(data.from);
        this.to = data.to ? stringToDateTime(data.to) : null;
    }
}
