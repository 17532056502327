// @ts-nocheck
import { module } from 'angular';

module('eaw.admin').controller('adminNewUserCtrl', [ '$state', 'UserService', 'ToastService', 'languages', function($state, UserService, ToastService, languages) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.languages = languages;
    };

    ctrl.addUser = () => {
        UserService.create(ctrl.formUser).$promise.then((data) => {
            ToastService.toast('User added');
            $state.transitionTo(`eaw/app/admin/users/view/info`, {
                id: data.id,
            });
        });
    };
} ]);
