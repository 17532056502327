import { Component, Inject, Input } from '@angular/core';
import { UserService } from '../../../shared/http/user.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { UserResponse } from '../../../shared/models/user';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { catchError, EMPTY } from 'rxjs';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { PasswordComponent } from '../../../login/components/password/password.component';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';

@Component({
    selector: 'eaw-profile-password',
    templateUrl: './profile-password.component.html',
    styleUrl: './profile-password.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        PasswordComponent,
        ReactiveFormsModule,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ProfilePasswordComponent {
    @Input() user!: UserResponse;

    submitting = false;
    passwordCtrl = new FormControl<[ string, string ]>([ '', '' ], {
        nonNullable: true,
        validators: [ Validators.required ],
    });

    constructor(
        @Inject(UserService) protected userService: UserService,
        @Inject(SnackBarService) protected snackbar: SnackBarService,
    ) { }

    update() {
        const [ password, password_confirmation ] = this.passwordCtrl.value ?? [];

        if (!password || !password_confirmation) {
            return;
        }

        this.submitting = true;

        this.userService.update(this.user.id, {
            password,
            password_confirmation,
        }).pipe(catchError(() => {
            this.submitting = false;

            return EMPTY;
        })).subscribe(() => {
            this.passwordCtrl.setValue([ '', '' ]);
            this.submitting = false;
            void this.snackbar.t('PASSWORD_CHANGED', 'profile');
        });
    }
}
