export class Fullscreen {
    static isFullscreen() {
        return document.body.classList.contains('content-only');
    }

    static toggle() {
        if (Fullscreen.isFullscreen()) {
            Fullscreen.disable();
        } else {
            Fullscreen.enable();
        }
    }

    static enable() {
        document.body.classList.add('content-only');
    }

    static disable() {
        document.body.classList.remove('content-only');
    }
}
