<mat-action-list role="list">
    @for (action of actions; track action) {
        <mat-list-item (click)="uiRouter.stateService.go(action.route)">
            <span class="tw-w-16 tw-flex tw-items-center">
                <mat-icon matListItemIcon class="tw-mr-8">{{ action.icon }}</mat-icon>
                {{ action.name | translate: 'messages' | async }}
            </span>
        </mat-list-item>
    }
</mat-action-list>
