import { module } from 'angular';
import { UIRouter } from '@uirouter/core';

module('eaw.messages').component('eawMessageConversations', {
    template: `<md-card>
    <md-card-content layout="row" flex class="tw-p-0">
        <md-sidenav class="md-sidenav-left mat-elevation-z2" md-component-id="conversation-list" md-is-locked-open="$mdMedia('gt-md')">
            <md-toolbar class="default-toolbar">
                <div class="md-toolbar-tools">
                    <h1 ng-i18next="messages:CONVERSATION_plural" flex></h1>

                    <eaw-toggle-btn
                            ng-model="msgConv.includeArchived"
                            ng-change="msgConv.toggleArchived()"
                            icon="unarchive"
                            i18n-tooltip="messages:INCLUDE_ARCHIVED">
                    </eaw-toggle-btn>
                    <md-button class="md-icon-button" ng-click="msgConv.goToWrite()">
                        <md-icon ng-bind="'add'"></md-icon>
                    </md-button>
                </div>
            </md-toolbar>
            <md-content>
                <eaw-conversation-list></eaw-conversation-list>
            </md-content>
        </md-sidenav>

        <md-content flex>
            <ui-view name="conversationContent"></ui-view>
        </md-content>
    </md-card-content>
</md-card>
`,
    controllerAs: 'msgConv',
    bindings: {
        user: '<',
    },
    controller: [ '$mdSidenav', '$uiRouter', function($mdSidenav, $uiRouter: UIRouter) {
        const $state = $uiRouter.stateService;
        // @ts-ignore
        const ctrl = this;

        ctrl.$onInit = async () => {
            // Wait for instance to become available, and assign it
            ctrl.convListSideNav = await $mdSidenav('conversation-list', true);
            ctrl.includeArchived = !!$uiRouter.globals.params['include_archived'];
        };

        ctrl.toggleArchived = () => {
            $state.go($uiRouter.globals.current, {
                ...$uiRouter.globals.params,
                include_archived: ctrl.includeArchived,
            });
        };

        ctrl.goToWrite = () => {
            ctrl.convListSideNav.close();
            $state.go(`eaw/app/messages/conversations/write`);
        };
    } ],
});
