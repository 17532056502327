// @ts-nocheck
import { module } from 'angular';
import { uniqueId } from '../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { t } from 'i18next';
export class AllCommentsDialogCtrl {
    employeePaidTime;
    items;
    $onInit() {
        this.items = [];
        this.addToItems(this.employeePaidTime.comments.timepunches, 'payroll:TIMEPUNCH');
        this.addToItems(this.employeePaidTime.comments.absences, 'absences:ABSENCE');
        this.addToItems(this.employeePaidTime.comments.shifts, 'SHIFT');
        this.addToItems(this.employeePaidTime.comments.paidTimes, 'payroll:PAID_TIME');
    }

    addToItems(items, type) {
        items.forEach((i) => {
            this.items.push({
                ...i,
                type: t(type),
                _id: uniqueId(),
            });
        });
    }
}
export class PaidTimeCommentsDialog {
    $mdDialog;
    static get $inject() {
        return [ '$mdDialog' ];
    }

    static preset = {
        template: `<md-dialog>
    <eaw-dialog-header-old heading="'COMMENT_plural' | i18next"></eaw-dialog-header-old>
    <md-dialog-content ng-repeat="item in ctrl.items track by $index" ng-if="item.comments.length">
        <md-subheader>
            <div layout="column">
                <span ng-bind="item.type"></span>
                <small ng-bind="item.created_at | moment:'LL'"></small>
            </div>
        </md-subheader>
        <md-list class="md-dense">
            <md-list-item class="md-3-line md-long-text" ng-repeat="comment in item.comments track by $index">
                <eaw-profile-picture-old class="md-avatar" user="comment.user"></eaw-profile-picture-old>

                <div class="md-list-item-text">
                    <h3 ng-bind="comment.user_name"></h3>
                    <h4 ng-bind="comment.created_at | moment:'LT'"></h4>
                    <p ng-bind="comment.body"></p>
                </div>
            </md-list-item>
        </md-list>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
    </md-dialog-actions>
</md-dialog>
`,
        controller: AllCommentsDialogCtrl,
        controllerAs: 'ctrl',
        bindToController: true,
        clickOutsideToClose: true,
    };

    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    open(employeePaidTime) {
        return this.$mdDialog.show(Object.assign({ locals: { employeePaidTime } }, PaidTimeCommentsDialog.preset));
    }
}
module('eaw.payroll.paid-time').service('AllComments', PaidTimeCommentsDialog);
