@if (title) {
    <span class="mat-caption tw-mb-8">{{title | async}}</span>
}

@for (line of lines.controls; track line.controls.id.value; let i = $index) {
    <form class="line-form" [formGroup]="line">
        <mat-form-field hideRequiredMarker subscriptSizing="dynamic">
            <mat-label>{{keyLabel | async}}</mat-label>
            <input matInput type="text" [placeholder]="(keyLabel | async) || ''" autocomplete="off" [formControl]="line.controls.key" (focusin)="markAsTouched()">
        </mat-form-field>

        <mat-icon>arrow_right</mat-icon>

        <mat-form-field hideRequiredMarker subscriptSizing="dynamic">
            <mat-label>{{valueLabel | async}}</mat-label>
            <input matInput type="text" [placeholder]="(valueLabel | async) || ''" autocomplete="off" [formControl]="line.controls.value" (focusin)="markAsTouched()">
        </mat-form-field>

        <button mat-icon-button color="warn" [disabled]="disabled || !(line.value.key || line.value.value)" tabindex="-1" (click)="removeLine(i)">
            <mat-icon>delete</mat-icon>
        </button>
    </form>
}
