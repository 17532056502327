<eaw-page-header [fabButton]="fabButton">
    <div title *ngIf="!employeeName">{{'EMPLOYEE' | translate | async}}</div>
    <div title *ngIf="employeeName">{{employeeName}}</div>
    <span subtitle>{{balance * 3600 | duration: ['hours', 'minutes']}}</span>
</eaw-page-header>

<mat-card class="mat-compact-card">
    <mat-card-content class="tw-flex tw-flex-col">
        <eaw-data-table [columns]="columns"
                        [request]="request"
                        initialSortBy="business_date"
                        (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
