import { ChangeDetectionStrategy, Component, inject, Input, OnInit, signal } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'eaw-markdown',
    standalone: true,
    imports: [],
    templateUrl: './markdown.component.html',
    styleUrl: './markdown.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkdownComponent implements OnInit {
    private domSanitizer = inject(DomSanitizer);

    // TODO: signal when not used in AngularJS
    @Input({ required: true }) text!: string;

    protected safeText = signal<SafeHtml | undefined>(undefined);

    async ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        const marked = await require(/* webpackChunkName: "marked" */ 'marked');
        this.safeText.set(this.domSanitizer.bypassSecurityTrustHtml(marked.parse(this.text)));
    }
}
