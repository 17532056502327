<div id="progress-bar-container">
    @if (loading()) {
        <mat-progress-bar mode="indeterminate" @inOutAnimation></mat-progress-bar>
    }
</div>

<div id="table-container">
    <table mat-table
           matSort
           matSortDisableClear
           [trackBy]="tableTrackBy"
           (matSortChange)="sortChange()"
           [matSortActive]="sortActive()"
           [matSortDirection]="sortDirection()"
           [dataSource]="dataSource()?.data || []">

        @for (column of columns(); track column.key) {
            <ng-container [matColumnDef]="column.key" [sticky]="column.sticky" [stickyEnd]="column.stickyEnd">
                <th mat-header-cell
                    [arrowPosition]="column.header?.arrowPosition || 'after'"
                    [ngClass]="column.header?.classes"
                    [disabled]="!column.header?.sortBy"
                    [mat-sort-header]="column.header?.sortBy || ''"
                    *matHeaderCellDef>
                    @if (column.header?.i18n) {
                        <div>{{ column.header?.i18n | translate: column.header?.ns | async }}</div>
                    }
                    @if (column.header?.text) {
                        <div>{{ column.header?.text }}</div>
                    }
                </th>

                <td mat-cell *matCellDef="let row">
                    <ng-template [cdkPortalOutlet]="row.components[column.key]"></ng-template>
                </td>
            </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="columnDefs(); sticky: allSticky()"></tr>
        <tr mat-row
            *matRowDef="let row; columns: columnDefs();"
            [class.actionable]="hasRowClick()"
            [ngClass]="row.classes"
            (click)="onRowClick(row, $event)">
        </tr>

        <ng-template cdkNoDataRow>
            @if (!loading()) {
                <tr>
                    <td id="no-data-cell" class="mat-headline-6" colspan="100">{{ noDataText() | async }}</td>
                </tr>
            }
        </ng-template>
    </table>
</div>

@if (!noPaginator()) {
    <eaw-paginator
            [pageIndex]="pageIndex()"
            [perPage]="pageSize()"
            [total]="pageTotal()"
            (paginationChange)="paginatorPage($event)">
    </eaw-paginator>
}
