// @ts-nocheck
import { module } from 'angular';
module('eaw').factory('SystemAlertFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const res = EawResource.create('/system_alerts/:alert', null);
    fac.get = (alertId) => res.get({
        alert: alertId,
    });
    /**
     * @param {object} args
     */
    fac.getAll = (args) => res.get({
        ...Pagination.getParams(args),
    });
    fac.create = (text) => res.save({}, { text });
    fac.update = (alertId, text) => res.update({
        alert: alertId,
    }, { text });
    fac.delete = (alertId) => res.delete({
        alert: alertId,
    });
    return fac;
} ]);
