import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatRipple } from '@angular/material/core';

@Component({
    selector: 'eaw-toolbar-button',
    standalone: true,
    templateUrl: './toolbar-button.component.html',
    styleUrl: './toolbar-button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    hostDirectives: [ MatRipple ],
})
/**
 * For use with MatMenu
 */
export class ToolbarButtonComponent {

}
