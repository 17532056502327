<eaw-dialog-header>
    <span title>{{ 'ADD_TO_CUSTOMER' | translate: 'admin' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form class="tw-flex tw-flex-col tw-gap-16" [formGroup]="form">
        <eaw-autocomplete formControlName="customer"
                          [options]="customerAutocompleteService.options"
                          [setter]="customerAutocompleteService.setter()"
                          [getter]="customerAutocompleteService.getter()"
                          required>
            <mat-icon eawPrefix>public</mat-icon>
        </eaw-autocomplete>

    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [loading]="loading()" [disabled]="form.invalid"
                       (click)="submit()">{{ 'SUBMIT' | translate | async }}
    </eaw-action-button>
</mat-dialog-actions>