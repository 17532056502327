// @ts-nocheck
import { module } from 'angular';
import { t } from 'i18next';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

module('eaw.staffing').controller('addStaffingTemplateDialogCtrl', [ '$mdDialog', 'staffingTemplateFactory', function($mdDialog, staffingTemplateFactory) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.validInputs = [
            {
                name: t('staffing:TRANSACTIONS'),
                value: 'transactions',
            },
            {
                name: t('staffing:NETSALES'),
                value: 'netsales',
            },
        ];
        ctrl.validOutputs = [
            {
                name: t('staffing:HEADCOUNT'),
                value: 'headcount',
            },
        ];

        ctrl.addConditionalOutputs();
    };

    ctrl.addConditionalOutputs = () => {
        if (CurrentOld.hasProduct('BusinessUnits')) {
            ctrl.validOutputs.push({
                name: t('staffing:BUSINESS_UNITS'),
                value: 'business_units',
            });
        }
    };

    ctrl.showAverageTray = () => ctrl.templateInput === 'transactions' && ctrl.templateOutput === 'business_units';

    ctrl.add = () => {
        ctrl.adding = true;
        staffingTemplateFactory.create.query(CurrentOld.customer.id, ctrl.templateName, ctrl.templateInput, ctrl.templateOutput, {
            average_tray: ctrl.averageTray,
        }).$promise.then(() => {
            $mdDialog.hide();
        }).finally(() => ctrl.adding = false);
    };

    ctrl.cancel = $mdDialog.cancel;
} ]);
