import { Component, Inject, OnDestroy } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { PayRateUploadService, SalaryUpdate } from '../../http/pay-rate-upload.service';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { FileSizePipe } from '../../../shared/pipes/file-size.pipe';
import { MaterialColorPipe } from '../../../shared/pipes/material-color.pipe';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';
import { FileSelectorComponent } from '../../../shared/components/file-selector/file-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

interface PayRateImportData extends DialogData {
    customerGroupId: number;
}

@Component({
    selector: 'eaw-pay-rate-import-update-dialog',
    templateUrl: './pay-rate-import-update-dialog.component.html',
    styleUrl: './pay-rate-import-update-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatIconModule,
        FileSelectorComponent,
        NgIf,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatDialogActions,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        MaterialColorPipe,
        FileSizePipe,
    ],
})
export class PayRateImportUpdateDialogComponent extends DialogComponent implements OnDestroy {
    customerGroupId: number = this.data.customerGroupId;
    preview?: SalaryUpdate;
    file?: File;
    date: FormControl<DateTime | null> = new FormControl(DateTime.now().plus({ month: 1 }).startOf('month'), Validators.required);
    columns = [ 'employee', 'customer', 'rate', 'from' ];
    dryRunSub?: Subscription;
    wetRunSub?: Subscription;

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<PayRateImportUpdateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) override data: PayRateImportData,
        @Inject(PayRateUploadService) private payRateUploadService: PayRateUploadService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);
    }

    ngOnDestroy() {
        this.dryRunSub?.unsubscribe();
        this.wetRunSub?.unsubscribe();
    }

    isSubscribing() {
        return (!!this.dryRunSub && !this.dryRunSub.closed) || (!!this.wetRunSub && !this.wetRunSub.closed);
    }

    setFile(file?: File) {
        this.preview = undefined;
        this.file = file;
    }

    dryRun() {
        if (!this.file || !this.date.value) {
            return;
        }

        this.preview = undefined;
        this.dryRunSub = this.payRateUploadService.dryRun(this.customerGroupId, this.file, this.date.value).subscribe({ next: (preview) => (this.preview = preview) });
    }

    confirm() {
        if (!this.preview) {
            return;
        }

        this.wetRunSub = this.payRateUploadService.applyChanges(this.customerGroupId, this.preview).subscribe({
            next: () => {
                this.snackbar.updated();
                this.dialogRef.close();
            },
        });
    }
}
