// @ts-nocheck
import { t } from 'i18next';
import { remove } from 'lodash-es';

import { module } from 'angular';

const template2 = `<md-dialog>
    <eaw-dialog-header-old heading="'admin:NEW_TARIFF_RULE' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <form name="dialogForm"
              autocomplete="off"
              id="dialogForm"
              class="md-dialog-content"
              ng-submit="dialog.close(dialog.rule)">
            <md-input-container>
                <label for="Name" ng-i18next="NAME"></label>
                <md-select id="Name" name="Name" required ng-model="dialog.rule.name">
                    <md-option ng-value="'seniority'">
                        <span ng-i18next="SENIORITY"></span>
                    </md-option>
                    <md-option ng-value="'age'">
                        <span ng-i18next="AGE"></span>
                    </md-option>
                </md-select>
            </md-input-container>

            <md-input-container>
                <label for="Value" ng-i18next="VALUE"></label>
                <input id="Value" name="Value" required type="number" min="0" ng-model="dialog.rule.value">
            </md-input-container>

            <p ng-show="dialog.rule.value" ng-i18next="[i18next](dialog.rule){{'admin:RULE_' + dialog.rule.name.toUpperCase()}}"></p>
        </form>
    </md-dialog-content>
    <md-dialog-actions>
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button class="md-accent" type="submit" form="dialogForm" ng-i18next="CREATE"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

const template1 = `<md-card>
    <md-card-header>
        <md-card-header-text>
            <span class="md-title">
                <span bo-i18next="chainops:TARIFF_RULE_plural"></span> - <span ng-bind="$chainTariff.tariff.name"></span>
            </span>
        </md-card-header-text>

        <card-btn-float on-click="$chainTariff.newRule()"></card-btn-float>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <md-list>
            <md-list-item ng-repeat="rule in $chainTariff.tariff.rules">
                <span ng-bind="rule._text"></span>
                <md-button class="md-secondary md-icon-button md-warn" ng-click="$chainTariff.deleteRule(rule)">
                    <md-icon ng-bind="'delete'"></md-icon>
                </md-button>
            </md-list-item>
        </md-list>
    </md-card-content>
</md-card>
`;

module('eaw.chain-ops').component('chainTariffs', {
    template: template1,
    bindings: {
        settingGroup: '<',
        tariff: '<',
    },
    controllerAs: '$chainTariff',
    controller: [ 'deleteItemAlert', 'ChainTariffRuleFactory', '$mdDialog', 'EawDialogController', function(deleteItemAlert, ChainTariffRuleFactory, $mdDialog, EawDialogController) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.tariff.rules.forEach((r) => {
                r._text = t(`chainops:RULE_${r.name.toUpperCase()}`, { value: r.value });
            });
        };

        ctrl.newRule = () => {
            $mdDialog.show({
                template: template2,
                controllerAs: 'dialog',
                locals: {
                    rule: {
                        group: ctrl.tariff.setting_group_id,
                        tariff: ctrl.tariff.id,
                    },
                },
                controller: EawDialogController,
            }).then((rule) => {
                ChainTariffRuleFactory.create.query(rule).$promise.then((resp) => {
                    resp._text = t(`chainops:RULE_${resp.name.toUpperCase()}`, { value: resp.value });
                    ctrl.tariff.rules.push(resp);
                });
            });
        };

        ctrl.deleteRule = (rule) => {
            deleteItemAlert.alertSimple().then(() => {
                ChainTariffRuleFactory.delete.query({
                    group: ctrl.tariff.setting_group_id,
                    tariff: ctrl.tariff.id,
                    rule: rule.id,
                }).$promise.then(() => {
                    remove(ctrl.tariff.rules, rule);
                });
            });
        };
    } ],
});
