import { AfterViewInit, Directive, EventEmitter, Host, Inject, Optional, Output, Self } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

@Directive({
    selector: 'mat-checkbox[eawCheckboxHelper]',
    standalone: true,
})
export class CheckboxHelperDirective implements AfterViewInit {
    @Output() helperChange = new EventEmitter<boolean>();

    constructor(
        @Host() @Self() @Optional() @Inject(MatCheckbox) private checkbox: MatCheckbox,
    ) {
    }

    ngAfterViewInit() {
        this.checkbox._elementRef.nativeElement.addEventListener('click', (e: MouseEvent) => {
            e.stopPropagation();
            this.checkbox.toggle();
            this.helperChange.emit(this.checkbox.checked);
        });
    }
}
