// @ts-nocheck
import { module } from 'angular';
import { TinyColor } from '@ctrl/tinycolor';

const template2 = `<md-list>
    <md-list-item ng-repeat="item in ctrl.items" ng-click="ctrl.click(item)">
        <p style="font-size: 16px" ng-bind="item.text"></p>
    </md-list-item>
</md-list>
`;

module('eaw.dashboard').component('eawWidget', {
    template: `<md-toolbar class="widget-header" ng-style="{'background': $widget.headerColor, 'color': $widget.headerTextColor}">
    <div class="md-toolbar-tools">
        <h2 ng-i18next="{{$widget.widget.i18name}}" flex></h2>

        <md-button ng-repeat="button in $widget.buttons"
                   class="md-icon-button"
                   ng-disabled="button.disabled"
                   ng-click="$widget.headerButtonClick(button, $event)">
            <md-icon ng-bind="button.icon"></md-icon>
            <md-tooltip ng-if="button.tooltip" ng-bind="button.tooltip"></md-tooltip>
        </md-button>

        <default-widget-options widget="$widget.widget" on-change="$widget.widgetSettingsChange" color="$widget.color"></default-widget-options>
    </div>
</md-toolbar>

<div class="widget-content">
    <md-progress-linear ng-if="$widget.loading"></md-progress-linear>

    <div class="empty-info" ng-if="$widget.empty">
        <md-icon>{{$widget.widget.widgetInfo.empty.icon}}</md-icon>
        <span bo-i18next="{{$widget.widget.widgetInfo.empty.text}}"></span>
    </div>
</div>
`,
    bindings: {
        widget: '<',
        customer: '<',
        color: '<',
    },
    controllerAs: '$widget',
    controller: [ '$element', '$scope', '$mdPanel', 'componentCreatorService', 'colorpickerService', function($element, $scope, $mdPanel, componentCreatorService, colorpickerService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.el = $element[0];
            ctrl.el.dataset.gaItem = `Widget (${ctrl.widget.name})`;

            ctrl.widgetSettingsChange(ctrl.widget.settings);

            ctrl.createWidget();
        };

        ctrl.widgetSettingsChange = (settings) => {
            ctrl.headerColor = colorpickerService.getHex(ctrl.color || settings.headerColor);
            ctrl.headerTextColor = new TinyColor(ctrl.headerColor).isDark() ? 'white' : 'black';
        };

        ctrl.setButtons = (buttons) => {
            ctrl.buttons = buttons;
        };

        ctrl.headerButtonClick = (button, event) => {
            if (button.type === 'menu') {
                $mdPanel.open({
                    attachTo: document.body,
                    controller: 'widgetHeaderMenu',
                    controllerAs: 'ctrl',
                    template: template2,
                    panelClass: 'eaw-panel widget-header-menu',
                    position: $mdPanel.newPanelPosition()
                        .relativeTo(event?.target)
                        .addPanelPosition(
                            $mdPanel.xPosition.OFFSET_END,
                            $mdPanel.yPosition.ALIGN_TOPS,
                        ),
                    animation: $mdPanel.newPanelAnimation()
                        .openFrom(event)
                        .duration(100)
                        .withAnimation($mdPanel.animation.SCALE),
                    targetEvent: event,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    focusOnOpen: true,
                    bindToController: true,
                    locals: {
                        items: button.items,
                    },
                });
            } else {
                button.click(event);
            }
        };

        ctrl.updateButton = (buttonId, options) => {
            const btn = ctrl.buttons?.find((b) => b.id === buttonId);

            if (btn) {
                Object.entries(options).forEach((entry) => {
                    btn[entry[0]] = entry[1];
                });
            }
        };

        ctrl.setLoading = (loading) => {
            ctrl.loading = loading;

            if (!loading) {
                ctrl.component?.element.classList.remove('display-none');
            }
        };

        ctrl.setEmpty = (empty) => {
            ctrl.empty = empty;

            const action = empty ? 'add' : 'remove';
            ctrl.el.classList[action]('empty');
        };

        ctrl.createWidget = () => {
            const content = ctrl.el.querySelector('.widget-content');
            if (!content) {
                return;
            }

            if (!ctrl.widget.component) {
                return;
            }

            ctrl.component = componentCreatorService.create({
                name: ctrl.widget.component,
                scope: $scope,
                transcludeControllers: { eawWidget: { instance: ctrl } },
            });

            if (!ctrl.widget.widgetInfo.noLoading) {
                ctrl.component.element.classList.add('display-none');
            }
            ctrl.component.element.classList.add('widget', 'pretty-scroll');
            content.appendChild(ctrl.component.element);
        };
    } ],
});
