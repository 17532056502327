// @ts-nocheck
import { module } from 'angular';
module('eaw.scheduling').service('ScheduleTotal', [ 'EawResource', function(EawResource) {
    const _resource = EawResource.create('/customers/:customer_id/schedules/:schedule_id/summary');
    return {
        get(customerId, scheduleId, params, getResource) {
            const res = _resource.get({
                customer_id: customerId,
                schedule_id: scheduleId,
                ...params,
            }); // TODO: pick what we want here when some become available
            return getResource ? res : res.$promise;
        },
    };
} ]);
