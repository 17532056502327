var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj)) throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateMethod = (obj, member, method) => {
  __accessCheck(obj, member, "access private method");
  return method;
};
var _rates, _render, render_fn, _onChange, onChange_fn;
import { MediaChromeListbox, createOption, createIndicator } from "./media-chrome-listbox.js";
import "./media-chrome-option.js";
import { DEFAULT_RATES, DEFAULT_RATE } from "../media-playback-rate-button.js";
import { MediaUIAttributes, MediaUIEvents } from "../constants.js";
import { globalThis } from "../utils/server-safe-globals.js";
import { getNumericAttr, setNumericAttr } from "../utils/element-utils.js";
import { AttributeTokenList } from "../utils/attribute-token-list.js";
const Attributes = {
  RATES: "rates"
};
class MediaPlaybackRateListbox extends MediaChromeListbox {
  constructor() {
    super();
    __privateAdd(this, _render);
    __privateAdd(this, _onChange);
    __privateAdd(this, _rates, new AttributeTokenList(this, Attributes.RATES, {
      defaultValue: DEFAULT_RATES
    }));
    __privateMethod(this, _render, render_fn).call(this);
  }
  static get observedAttributes() {
    return [...super.observedAttributes, "aria-multiselectable", MediaUIAttributes.MEDIA_PLAYBACK_RATE, Attributes.RATES];
  }
  attributeChangedCallback(attrName, oldValue, newValue) {
    super.attributeChangedCallback(attrName, oldValue, newValue);
    if (attrName === MediaUIAttributes.MEDIA_PLAYBACK_RATE && oldValue != newValue) {
      this.value = newValue;
    } else if (attrName === Attributes.RATES && oldValue != newValue) {
      __privateGet(this, _rates).value = newValue;
      __privateMethod(this, _render, render_fn).call(this);
    }
  }
  get rates() {
    return __privateGet(this, _rates);
  }
  set rates(value) {
    if (!value) {
      __privateGet(this, _rates).value = "";
    } else if (Array.isArray(value)) {
      __privateGet(this, _rates).value = value.join(" ");
    }
    __privateMethod(this, _render, render_fn).call(this);
  }
  get mediaPlaybackRate() {
    return getNumericAttr(this, MediaUIAttributes.MEDIA_PLAYBACK_RATE, DEFAULT_RATE);
  }
  set mediaPlaybackRate(value) {
    setNumericAttr(this, MediaUIAttributes.MEDIA_PLAYBACK_RATE, value);
  }
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("change", __privateMethod(this, _onChange, onChange_fn));
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener("change", __privateMethod(this, _onChange, onChange_fn));
  }
}
_rates = new WeakMap();
_render = new WeakSet();
render_fn = function () {
  const container = this.shadowRoot.querySelector("#container");
  container.textContent = "";
  for (const rate of this.rates) {
    const option = createOption(this.formatOptionText(`${rate}x`, rate), rate, this.mediaPlaybackRate == rate);
    option.prepend(createIndicator(this, "select-indicator"));
    container.append(option);
  }
};
_onChange = new WeakSet();
onChange_fn = function () {
  if (!this.value) return;
  const event = new globalThis.CustomEvent(MediaUIEvents.MEDIA_PLAYBACK_RATE_REQUEST, {
    composed: true,
    bubbles: true,
    detail: this.value
  });
  this.dispatchEvent(event);
};
if (!globalThis.customElements.get("media-playback-rate-listbox")) {
  globalThis.customElements.define("media-playback-rate-listbox", MediaPlaybackRateListbox);
}
var media_playback_rate_listbox_default = MediaPlaybackRateListbox;
export { Attributes, MediaPlaybackRateListbox, media_playback_rate_listbox_default as default };