// @ts-nocheck
import { module } from 'angular';
module('eaw.misc').factory('IconElement', [ '$http', 'CurrentDowngrade', function IconElement($http, CurrentDowngrade) {
    const store = CurrentDowngrade.createStore('temp-icons-store');

    function createIcon(data) {
        const el = document.createElement('div');
        el.innerHTML = data;
        return el.children[0];
    }
    return {
        async get(name, fill = true) {
            const type = fill ? 'fill1' : 'default';
            const key = `${name}${type}`;
            try {
                let data = await store.getItem(key);
                if (!data) {
                    data = (await $http.get(`https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/${name}/${type}/24px.svg`)).data;
                    if (typeof data !== 'string') {
                        return document.createElement('div');
                    }
                    await store.setItem(key, data);
                }
                return createIcon(data);
            } catch (_) {
                return document.createElement('div');
            }
        },
    };
} ]);
