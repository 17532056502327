<mat-progress-spinner *ngIf="processing" [diameter]="20" [strokeWidth]="2" mode="indeterminate"></mat-progress-spinner>

<div class="statuses" *ngIf="!processing">
    @if (segment().isPunchedIn) {
        <span>🕒</span>
    }
    @if (segment().absence?.approval?.approved) {
        <span>✅</span>
    }
    @if (segment().absence && !segment().absence?.approval) {
        <span>🟡</span>
    }
    @if (segment().offTime?.vacation) {
        <span>🌴</span>
    }
    @if (segment().comments.length) {
        <span>💬</span>
    }
    @if (flexBasis) {
        <span [matTooltip]="(flexBasis | async) || ''">ℹ️</span>
    }
</div>

@if (!processing) {
    <div id="content">
        <div id="text">
            <span id="title">{{ title }}</span>
            <span id="subtitle">{{ subtitle }}</span>
        </div>
    </div>
}

@if (menu.length) {
    <div id="trigger" [matMenuTriggerFor]="actionsMenu"></div>
}
<mat-menu #actionsMenu>
    <ng-template matMenuContent>
        @for (item of menu; track $index) {
            <!-- There are no null/undefined items, not sure why the linter thinks so -->
            <button mat-menu-item (click)="item!.action()">{{ item!.text | async }}</button>
        }
    </ng-template>
</mat-menu>
