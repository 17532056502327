import { Component, ElementRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EMPTY, Observable, of, switchMap, tap } from 'rxjs';
import { DialogComponent, DialogData, DialogSize } from '../dialog-component';
import { DateTime } from 'luxon';
import { UserGroupService } from '../../http/user-group.service';
import { User } from 'src/app/shared/models/user';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { DateTimeInputComponent } from '../../components/date-time/date-time-input/date-time-input.component';
import { DateTimeRangeInputComponent } from '../../components/date-time/date-time-range-input/date-time-range-input.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UserService } from '../../http/user.service';
import { PaginationOptions } from '../../interfaces/pagination-options';
import { expandPages } from '../../utils/rxjs/expand-pages';

export interface AddUsersToUserGroupData extends DialogData {
    userGroupId: number;
    customerId: number;
}

@Component({
    selector: 'eaw-add-users-to-user-group',
    templateUrl: './add-users-to-user-group.component.html',
    styleUrl: './add-users-to-user-group.component.angular.scss',
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatFormFieldModule,
        MatChipsModule,
        NgFor,
        MatIconModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatOptionModule,
        NgIf,
        MatProgressSpinnerModule,
        DateTimeRangeInputComponent,
        DateTimeInputComponent,
        FormsModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AddUsersToUserGroupComponent extends DialogComponent<AddUsersToUserGroupData, User[] | undefined> implements OnInit {
    private readonly userService = inject(UserService);
    private readonly userGroupService = inject(UserGroupService);

    @ViewChild('userInput') userInput!: ElementRef<HTMLInputElement>;

    members: User[] = [];
    userCtrl: FormControl<string | null>;
    fromCtrl: FormControl<DateTime | null>;
    toCtrl: FormControl<DateTime | null>;

    filteredUsers?: Observable<User[]>;
    today = DateTime.now().startOf('day');
    saving?: Observable<User>;

    users: User[] = [];
    loading = true;

    protected observable?: Observable<User[]>;

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AddUsersToUserGroupComponent>,
        @Inject(MAT_DIALOG_DATA) override data: AddUsersToUserGroupData,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        this.userCtrl = new FormControl({
            value: '',
            disabled: true,
        });

        this.fromCtrl = new FormControl(this.today, Validators.required);
        this.toCtrl = new FormControl(null);
    }

    ngOnInit(): void {
        this.users = [];

        this.filteredUsers = this.userCtrl.valueChanges.pipe(
            switchMap((filter?): Observable<User[]> => {
                if (!this.users.length) {
                    return EMPTY;
                }

                return of(this.filterUsers(typeof filter === 'string' ? filter : undefined));
            }),
        );

        this.userCtrl.disable();
        this.getUsers()
            .subscribe({
                complete: () => {
                    this.loading = false;
                },
            });
    }

    async save() {
        const memberships = this.members.map((u: User) => {
            return {
                group: {
                    owner_id: this.data.customerId,
                    id: this.data.userGroupId,
                },
                member_id: u.id,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                from: this.fromCtrl.value!,
                to: this.toCtrl.value,
            };
        });
        const members: User[] = [];

        this.saving = this.userGroupService.addMembers(memberships);
        this.userCtrl.disable();

        this.saving.pipe(tap((membership) => {
            members.push(membership);
        })).subscribe({
            complete: () => {
                this.dialogRef.close(members);
            },
            error: () => {
                delete this.saving;
                this.userCtrl.enable();
            },
        });
    }

    remove(user: User) {
        this.members = this.members.filter((m) => m.id != user.id);
    }

    selected(member: User) {
        this.members.push(member);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.userInput!.nativeElement.value = '';
        this.userCtrl.setValue('');
        this.userInput.nativeElement.blur();
    }

    filterUsers(filter?: string): User[] {
        const searchString: string = filter?.toLowerCase() ?? '';

        return this.users.filter((user) => {
            return (user.name.toLowerCase().includes(searchString) || user.email.toLowerCase().includes(searchString)) && !this.members.find((u) => u.id == user.id);
        });
    }

    getUsers() {
        const pagination: PaginationOptions = {
            'fields[]': [ 'name', 'email', 'first_name', 'last_name' ],
            order_by: 'first_name',
            per_page: 6,
        };

        return expandPages((pagination) => this.userService.getAllForCustomer(this.data.customerId, pagination), pagination, 4)
            .pipe(tap((page) => {
                this.users = this.users.concat(page.data);
                if (this.userCtrl.disabled) {
                    this.userCtrl.enable();
                    this.userCtrl.setValue('');
                }
            }));
    }
}
