// @ts-nocheck
import { module } from 'angular';

module('eaw.sso', [])
    .config([ '$stateProvider', ($stateProvider: any) => {
        $stateProvider.state('eaw/app/sso', {
            parent: 'eaw/app',
            url: '/sso/:id',
            views: {
                'content@': 'ssoRedirect',
            },
            resolve: {
                logout: [ '$transition$', function($transition$) {
                    return $transition$.params().logout ? Promise.reject('sso_logout') : Promise.resolve();
                } ],
                serviceId: [ '$transition$', function($transition$) {
                    return $transition$.params().id;
                } ],
            },
            data: {
                breadcrumb: { key: 'SSO' },
            },
        });
    } ]);
