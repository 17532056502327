<eaw-dialog-header>
    <span title>{{'APPROVE_ABSENCE' | translate:'absences' | async}}</span>
</eaw-dialog-header>

@if (loading()) {
    <eaw-info-loading class="tw-my-24" size="md"/>
} @else {
    <mat-dialog-content>
        <form class="tw-flex tw-flex-col tw-gap-4" [formGroup]="form">
            <label id="shift-handle-label">{{'MANAGE_SHIFT_plural' | translate: 'scheduling' | async}}</label>

            @if (hasScheduling()) {
                <mat-radio-group label="shift-handle-label" formControlName="action">
                    <mat-radio-button value="release">{{'APPROVE_ACTION_RELEASE' | translate:'absences' | async}}</mat-radio-button>
                    <mat-radio-button value="keep">{{'APPROVE_ACTION_KEEP' | translate:'absences' | async}}</mat-radio-button>
                    <mat-radio-button value="delete">{{'APPROVE_ACTION_DELETE' | translate:'absences' | async}}</mat-radio-button>
                </mat-radio-group>
            }

            <mat-divider class="tw-my-8"></mat-divider>

            <mat-form-field>
                <mat-label>{{'COMMENT' | translate | async}}</mat-label>
                <textarea formControlName="comment" matInput></textarea>
            </mat-form-field>
        </form>
    </mat-dialog-content>
}

<mat-dialog-actions>
    <button mat-button mat-dialog-close [disabled]="form.disabled">{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="false" [disabled]="form.disabled || loading()" (click)="approve()">{{'APPROVE' | translate | async}}</eaw-action-button>
</mat-dialog-actions>
