<eaw-dialog-header>
    <span title>{{'ABSENCE_STATISTICS' | translate:'absences' | async}}</span>
</eaw-dialog-header>

<mat-dialog-content>
    <form [formGroup]="form" class="tw-grid tw-gap-16 tw-grid-cols-2">
        <eaw-autocomplete formControlName="employee"
                          [options]="employeeAutocompleteService.options"
                          [getter]="employeeAutocompleteService.getter({customerId: data.customerId})"
                          [setter]="employeeAutocompleteService.setter({customerId: data.customerId})">
        </eaw-autocomplete>

        <mat-form-field>
            <mat-label>{{'YEAR' | translate | async}}</mat-label>
            <mat-select formControlName="year" (selectionChange)="yearChange($event)">
                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>

    <div *ngIf="loading" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

    <div *ngIf="!data.employeeId && !form.controls.employee.value"  class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-8"><strong>{{'CHOOSE_EMPLOYEE_STATS' | translate: 'absences' | async}}</strong></div>
    <table mat-table [dataSource]="dataSource" *ngIf="!loading && (data.employeeId || form.controls.employee.value)">
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell tw-pt-16 tw-text-center" colspan="3">{{'NO_DATA' | translate | async}}</td>
        </tr>

        <ng-container [matColumnDef]="absenceTypeColumn" sticky>
            <th mat-header-cell *matHeaderCellDef>{{'ABSENCE_TYPE' | translate: 'absences' | async }}</th>
            <td mat-cell *matCellDef="let item"
                [style.background-color]="item.absenceType.color"
                [style.color]="item.absenceType.textColor"
                [style.font-weight]="item.absenceType.textColor === 'black'? 'bold' : '500'">
                {{item.absenceType.name | translate: 'absence_types' | async}}
            </td>
        </ng-container>

        <ng-container  [matColumnDef]="countColumn" sticky>
            <th mat-header-cell *matHeaderCellDef class="tw-text-right">{{'COUNT' | translate: 'absences' | async }}</th>
            <td mat-cell *matCellDef="let item" class="tw-text-right">
                {{item.count | eawNumber: item.absenceType.span === 'hour' ? 2 : 0}}
            </td>
        </ng-container>

        <ng-container [matColumnDef]="totalColumn" sticky>
            <th mat-header-cell *matHeaderCellDef>{{'TOTAL' | translate | async }}</th>
            <td mat-cell *matCellDef="let item">
                {{formatUnit(item.total, item.absenceType.span)}}
            </td>
        </ng-container>
    </table>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'CANCEL' | translate | async}}</button>
</mat-dialog-actions>
