// @ts-nocheck
import { module } from 'angular';
import { PaginationOld } from '../../../../utils/pagination-old';
module('eaw.misc').factory('customerCustomerGroupFactory', [ 'EawResource', function(EawResource) {
    return {
        getAll: {
            permission(customerId) {
                return `customers.${customerId}.customer_groups.*.get`;
            },
            query(customerId, options) {
                return EawResource.create('/customers/:customerId/customer_groups').get({
                    customerId,
                    ...PaginationOld.getParams(options),
                });
            },
        },
    };
} ]);
