// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';
import { Mobile } from '../../../../utils/eaw-mobile';
import {smallDevice} from '../../misc/services/easy-funcs.service';

module('eaw.time').filter('moment', [ function() {
    const dateFormat = Mobile.isMobile || smallDevice() ? 'll' : 'LL';
    const timeFormat = 'LT';

    return function(date, f) {
        if (date instanceof Date) {
            date = moment([ date.getFullYear(), date.getMonth(), date.getDate() ]);
        } else if (!moment.isMoment(date)) {
            return '';
        }

        let format;
        switch (f) {
            case 'date':
                format = dateFormat;
                break;
            case 'time':
                format = timeFormat;
                break;
            case '':
            case 'datetime':
            case null:
            case undefined:
                format = `${dateFormat} ${timeFormat}`;
                break;
            default:
                format = f;
        }

        return date.format(format);
    };
} ]);
