// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
module('eaw.checklists').factory('ChecklistFactory', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const url = '/customers/:customer/checklists/:list';
    /**
     * Get a single checklist
     * @param {int} customerId
     * @param {int} checklistId
     * @param withRelations
     */
    fac.get = (customerId, checklistId, withRelations) => EawResource.create(url).get({
        customer: customerId,
        list: checklistId,
        'with[]': withRelations,
    });
    fac.getAll = (args) => {
        return EawResource.create(url).get({
            customer: args.customer.id,
            ...Pagination.getParams(args, [ 'closed', 'template', 'template_id', 'from', 'to' ]),
        });
    };
    fac.create = (customer, list) => EawResource.create(url).save({
        customer: customer.id,
    }, pick(list, [ 'title', 'is_template', 'template_id' ]));
    /**
     * Update a checklist
     * @param {object} customer
     * @param {object} list
     */
    fac.update = (customer, list) => EawResource.create(url).update({
        customer: customer.id,
        list: list.id,
    }, pick(list, [ 'title', 'close' ]));
    fac.delete = (customer, checklistId) => EawResource.create(url).delete({
        customer: customer.id,
        list: checklistId,
    });
    return fac;
} ]);
