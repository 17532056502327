// @ts-nocheck
import { module } from 'angular';
module('eaw.misc').factory('Tooltip', [ 'componentCreatorService', function clipboardService(componentCreatorService) {
    return function Tooltip(el, tip) {
        return componentCreatorService.create({
            name: el.nodeName.toLowerCase(),
            innerHTML: `${el.innerHTML}<md-tooltip>${tip}</md-tooltip>`,
        }).element;
    };
} ]);
