// @ts-nocheck
import { t } from 'i18next';
import { filter } from 'lodash-es';
import moment from 'moment-timezone';
import { module } from 'angular';
import { SignatureProviderType } from '../../enums/SignatureProviderType';
import { Model } from '../../../shared/angularjs/model';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

module('eaw.hr').service('HrFile', [ 'HrFileType', 'HrFactory', 'chooseSignatureProviderDialog', '$state', function(HrFileType, HrFactory, chooseSignatureProviderDialog, $state) {
    class HrFile extends Model {
        customer;
        form_fields;
        logs;
        has_form_fields;
        previewable;
        signable;
        isDownloadable;
        showDownloadButton;
        isSigned;
        signed_count;
        signatures;
        type_id;
        employee_signed_count;
        deletePermission;
        mandatory;
        myFile;
        employee_id;
        type;
        expires_at = null;
        missing;
        attachments = [];
        authentication_methods = {};
        name;
        notify;
        responsible_id;
        constructor(data, customer) {
            super(data);
            const signables = this.getSignables();
            this.attachments = data.attachments;
            this.customer = customer;
            this.employee_id = data.employee_id;
            this.form_fields = data.form_fields;
            this.authentication_methods = data.authentication_methods;
            this.type = data.type;
            this.expires_at = data.expires_at;
            this.notify = data.notify;
            this.name = data.name;
            this.missing = data.missing;
            this.responsible_id = data.responsible_id;
            this.signatures = data.signatures || [];
            this.type_id = data.type_id;
            this.logs = data?.logs?.reverse();
            this.has_form_fields = !!this.form_fields?.length;
            this.previewable = data.previewable;
            this.signable = signables.map((s) => s?.filter?.type_id).includes(this.type_id);
            this.isDownloadable = !(signables?.[0]?.filter?.type_id === SignatureProviderType.Checkbox && !this.has_form_fields);
            this.showDownloadButton = this.id && (this.previewable || this.isDownloadable);
            this.isSigned = this.signed_count || this.signatures?.length || this.employee_signed_count;
            this.deletePermission = CurrentOld.can(`customers.${this.customer.id}.employees.${this.employee_id}.hr_files.${this.id}.delete`);
            this.mandatory = this.type?.mandatory || false;
            this.myFile = this.employee_id === CurrentOld.employee?.id;
        }

        isExpired() {
            return this.expires_at?.isBefore(moment());
        }

        getSignables() {
            return filter(HrFileType.signables, (provider) =>
            // DO NOT use strict comparison here.
                Object.entries(provider.filter).every(([ key, val ]) => this[key] == val));
        }

        download() {
            if (this.previewable) {
                return HrFactory.previewFile(this.customer.id, this.employee_id, this.id);
            }
            if (this.isDownloadable) {
                return HrFactory.downloadFile(this.customer.id, this.employee_id, this.id);
            }
        }

        getSignedWith() {
            if (!this.isSigned) {
                return '';
            }
            switch (this.getSignables()?.[0]?.provider) {
                case SignatureProviderType.Signicat:
                    return t(`hr:SIGNED_WITH_SIGNICAT`);
                case SignatureProviderType.Checkbox:
                    return t(`hr:SIGNED_WITH_CHECKBOX`);
                default:
                    return '';
            }
        }

        canDelete() {
            return this.id && this.deletePermission;
        }

        delete() {
            return HrFactory.delete(this.customer.id, this.employee_id, this.id);
        }

        canSign() {
            if (!this.myFile) {
                return;
            }
            if (this.employee_signed_count) {
                return;
            }
            return this.getSignables().length;
        }

        goToSign() {
            if (!this.canSign()) {
                return;
            }
            const signables = this.getSignables();
            let promise;
            if (signables.length > 1) {
                promise = chooseSignatureProviderDialog.choose(signables);
            } else {
                promise = Promise.resolve(signables[0].provider);
            }
            promise.then((provider) => {
                let state;
                switch (provider) {
                    case SignatureProviderType.Checkbox:
                        state = `eaw/app/hr/my_files/file/sign/checkbox`;
                        break;
                    case SignatureProviderType.DocumentService:
                        state = `eaw/app/hr/my_files/file/sign/document-service`;
                        break;
                    default:
                        return;
                }
                $state.go(state, {
                    file_id: this.id,
                    emp: this.employee_id,
                });
            });
        }

        goToFile() {
            if (this.missing) {
                return;
            }

            return $state.go(this.employee_id === CurrentOld.employee?.id ? `eaw/app/hr/my_files/file` : `eaw/app/hr/emp_file`, {
                customer_id: this.customer?.id,
                emp: this.employee_id,
                file: this.id,
            });
        }

        static override getMorphClass() {
            return 'hr_file';
        }
    }
    return HrFile;
} ]);
