@if (children().length > 0) {
    <div id="form-group{{node().id}}" class="tw-flex tw-flex-col md:tw-flex-row tw-gap-4">
        @if (node().label) {
            <label class="label" for="form-group{{node().id}}">{{ node().label }}</label>
        }
        @for (child of children(); track child.id) {
            <eaw-show-form-node [node]="child" [submission]="submission()"></eaw-show-form-node>
        }
    </div>
} @else if (value() && formElement()) {
    <mat-form-field class="tw-flex tw-flex-col">
        <mat-label class="label">{{ node().label }}</mat-label>
        @if(formElement()?.type?.valueOf() === 'textarea') {
            <textarea class="input" matInput disabled [value]="value()"></textarea>
        } @else {
            <input class="input" matInput type="text" disabled [value]="value()">
        }
    </mat-form-field>
}
