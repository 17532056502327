import { inject, Injectable } from '@angular/core';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { AbsenceType, AbsenceTypeResponse } from '../models/absence-type';
import { Observable, switchMap } from 'rxjs';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { HttpClient } from '@angular/common/http';
import { AbsenceSpan } from '../types/types';
import { TranslateService } from '../../shared/services/translate.service';

interface GetAllForGroupOptions extends PaginationOptions {
    span?: AbsenceSpan;
    // Only the types that can be created by the user
    only_creatable?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class AbsenceTypeService {
    http = inject(HttpClient);
    translate = inject(TranslateService);

    getAll(target: 'customers' | 'setting_groups', targetId: number, options?: GetAllForGroupOptions): Observable<ArrayPaginatedResponse<AbsenceType>> {
        return this.http.get<ArrayPaginatedResponse<AbsenceTypeResponse>>(`${target}/${targetId}/absence_types`, {
            params: { ...options },
        }).pipe(
            classifyArrayPaginatedResponse(AbsenceType),
            switchMap(async (response) => {
                // Automatically translate the names before we finish the get request
                for await (const absenceType of response.data) {
                    absenceType.setTranslatedName(await this.translate.t(absenceType.name, 'absence_types'));
                }

                return response;
            }),
        );
    }

    getAbsenceType(target: 'customers' | 'setting_groups', id: number, absenceTypeId: number) {
        return this.http.get<AbsenceTypeResponse>(`${target}/${id}/absence_types/${absenceTypeId}`).pipe(
            classifyItem(AbsenceType),
            switchMap(async (absenceType) => {
                // Automatically translate the name before we finish the get request
                absenceType.setTranslatedName(await this.translate.t(absenceType.name, 'absence_types'));
                return absenceType;
            }),
        );
    }
}
