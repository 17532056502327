<eaw-dialog-header>
    @if (data.periodId) {
        <span title>{{'EDIT_PERIOD' | translate:'scheduling' | async}}</span>
    } @else {
        <span title>{{'ADD_PERIOD' | translate:'scheduling' | async}}</span>
    }
</eaw-dialog-header>

<mat-dialog-content>
    @if (loading) {
        <eaw-info-loading size="md"/>
    } @else {
        @if (shift) {
            <eaw-subheader class="tw-flex tw-flex-col tw-items-start tw-mb-24">
                <div>{{ 'SHIFT' | translate:'scheduling' | async }}</div>
                <div>{{ 'FROM_TO' | translate:'general':{from: (shift.from | DateTime:'DATETIME_MED'), to: (shift.to | DateTime:'DATETIME_MED')} | async }}</div>
            </eaw-subheader>
        }

        <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-16">
            @if (availableDays.length > 1) {
                <div class="tw-grid tw-grid-cols-2 tw-gap-16">
                    <mat-form-field>
                        <mat-label>{{'FROM_DAY' | translate | async}}</mat-label>
                        <mat-select formControlName="fromDate">
                            @for (day of availableDays; track day) {
                                <mat-option [value]="day">{{ day | DateTime:'DATE_MED_WITH_WEEKDAY' }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>

                    <eaw-time-input formControlName="fromTime" [label]="'FROM_TIME' | translate"></eaw-time-input>
                </div>

                <div class="tw-grid tw-grid-cols-2 tw-gap-16">
                    <mat-form-field>
                        <mat-label>{{'TO_DAY' | translate | async}}</mat-label>
                        <mat-select formControlName="toDate">
                            @for (day of availableDays; track day) {
                                <mat-option [value]="day">{{ day | DateTime:'DATE_MED_WITH_WEEKDAY' }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>

                    <eaw-time-input formControlName="toTime" [label]="'TO_TIME' | translate"></eaw-time-input>
                </div>
            } @else {
                <div class="tw-grid tw-grid-cols-2 tw-gap-16">
                    <eaw-time-input formControlName="fromTime" [label]="'FROM' | translate"></eaw-time-input>

                    <eaw-time-input formControlName="toTime" [label]="'TO' | translate"></eaw-time-input>
                </div>
            }

            <mat-form-field>
                <mat-label>{{ 'DESCRIPTION' | translate | async }}</mat-label>
                <textarea matInput formControlName="description"></textarea>
            </mat-form-field>

            @if (hasBusinessUnits) {
                <eaw-autocomplete
                        [label]="'BUSINESS_UNIT' | translate"
                        formControlName="businessUnit"
                        [options]="businessUnitAutocomplete.options"
                        [getter]="businessUnitAutocomplete.getter"
                        [setter]="businessUnitAutocomplete.setter">
                </eaw-autocomplete>
            } @else {
                <mat-form-field class="tw-col-span-full">
                    <mat-label>{{'QUALIFICATION_plural' | translate | async}}</mat-label>
                    <mat-chip-grid #chipGrid [disabled]="form.disabled">
                        @for (qualification of selectedQualifications | keyvalue; track qualification.key) {
                            <mat-chip-row (removed)="removeQualification(qualification.value)">
                                {{ qualification.value.name }}

                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                        }
                    </mat-chip-grid>

                    <input [placeholder]="((loadedQualifications ? 'TYPE_TO_SEARCH' : 'LOADING') | translate | async) || ''"
                           #qualificationInput
                           (focus)="getQualifications()"
                           formControlName="qualifications"
                           [matChipInputFor]="chipGrid"
                           [matAutocomplete]="qualificationsAutocomplete"
                           [matChipInputSeparatorKeyCodes]="qualificationsSeparatorKeysCodes"
                           (matChipInputTokenEnd)="addQualification($event)"/>

                    <mat-autocomplete #qualificationsAutocomplete="matAutocomplete" (optionSelected)="selectedQualification($event)">
                        @for (qualification of filteredQualifications | async; track qualification.id) {
                            <mat-option [value]="qualification">{{ qualification.name }}</mat-option>
                        }
                    </mat-autocomplete>
                </mat-form-field>
            }

            <mat-form-field>
                <mat-label>{{ 'COLOR' | translate | async }}</mat-label>
                <eaw-color-picker formControlName="color"></eaw-color-picker>
            </mat-form-field>

            <mat-slide-toggle formControlName="unproductive">{{ 'UNPRODUCTIVE' | translate:'scheduling' | async }}</mat-slide-toggle>

            <mat-slide-toggle formControlName="break">{{ 'BREAK' | translate:'scheduling' | async }}</mat-slide-toggle>
        </form>
    }
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate | async }}</button>

    @if (data.periodId) {
        <eaw-action-button [loading]="processing" [disabled]="form.invalid" (click)="update()">{{'UPDATE_PERIOD' | translate:'scheduling' | async}}</eaw-action-button>
    } @else {
        <eaw-action-button [loading]="processing" [disabled]="form.invalid" (click)="add()">{{'ADD_PERIOD' | translate:'scheduling' | async}}</eaw-action-button>
    }
</mat-dialog-actions>
