import { DateTimeConverter } from '../utils/date-time-converter';
import { t } from 'i18next';
import moment, { Moment } from 'moment-timezone';
import { cloneDeep, groupBy } from 'lodash-es';
import { eawTranslate } from '../utils/translate';
import { Namespace } from '../enums/namespace';
import { HasTimestampsOld, ModelData } from './model-data';
import { PropertyOld } from './property.class';
import { module } from 'angular';
import { EawUrl } from './modules/resource/url.service';
import { ModelCustomFieldResponse } from '../../custom-fields/models/model-custom-field';

type FieldValue = {
    from?: any,
    to?: any,
    value?: any
};

/**
 * @deprecated
 */
export class CustomFieldOld implements HasTimestampsOld {
    static customFields: {}; // Fields from "me" request

    id?: number;
    created_at: Moment;
    updated_at: Moment;
    deleted_at?:Moment;
    _dates?: string[];
    _business_dates?: string[];
    pivot;
    name: string | any;
    key: string;
    from?: Moment;
    to?: Moment;
    type: string;
    value: any;
    options;
    select_options;
    translatedName: string;
    selectOptions?: Record<string, any>;

    constructor(cf: ModelData | any) {
        this.id = cf.id;
        this.key = cf.key;
        this.name = cf.name;
        this.from = DateTimeConverter.convertStringToMoment(cf.from);
        this.to = DateTimeConverter.convertStringToMoment(cf.to);
        this.type = cf.type;
        this.value = typeof cf.value === 'string' ? PropertyOld.formatValue(cf.value, cf.type) : cf.value;
        this.options = cf.options;
        this.select_options = cf.select_options;
        this.updated_at = cf.updated_at;
        this.created_at = cf.created_at;
        this.deleted_at = cf.deleted_at;
        this.translatedName = this.translate();

        // Pivot stuff
        this.pivot = cf.pivot;

        this.setSelectOptions();

        return new Proxy(this, {
            set: this._set,
        });
    }

    get default() {
        return this.pivot?.default;
    }

    set hasInterval(hasInterval: boolean | null | undefined) {
        if (!this.pivot || hasInterval == null) {
            return;
        }
        this.pivot.has_interval = hasInterval;
    }

    get hasInterval() {
        return this.pivot?.has_interval;
    }

    get required(): boolean | undefined {
        return this.pivot?.required;
    }

    set required(required) {
        if (!this.pivot) {
            this.pivot = {};
        }

        this.pivot.required = required;
    }

    get model(): string | undefined {
        return this.pivot?.model;
    }

    get objectId() {
        return this.pivot?.object_id;
    }

    get objectType(): string | undefined {
        return this.pivot?.object_type;
    }

    get validator(): string | undefined {
        return this.pivot?.validator;
    }

    set validator(validator) {
        if (!this.pivot) {
            this.pivot = {};
        }

        this.pivot.validator = validator;
    }

    get metadata() {
        return this.pivot?.metadata;
    }

    get deletable() {
        return this.value != null && !this.required;
    }

    get selectValue() {
        if (this.type != 'select' || this.value == null) {
            return '';
        }

        if (typeof this.selectOptions === 'object') {
            return this.selectOptions[this.value];
        }

        if (this.select_options) {
            return this.select_options[this.value];
        }
    }

    get dateValue() {
        return moment.isMoment(this.value) && this.value.isValid() ? DateTimeConverter.convertMomentToBusinessDate(this.value) : this.value;
    }

    get humanValue(): string {
        switch (this.type) {
            case 'select':
                return t(`custom_fields:${this.selectValue.replaceAll(' ', '_')}`);
            case 'boolean':
                return this.value != null ? t(this.value == 1 ? 'YES' : 'NO') : '';
            case 'date':
                return this.dateValue;
            default:
                return this.value;
        }
    }

    static getFieldValues(model: { customFields?: any[] }) {
        const fieldValues: Record<string, FieldValue |null> = {};

        model.customFields?.forEach((cf) => {
            if (!(cf instanceof this)) {
                cf = new this(cf);
            }

            fieldValues[cf.key] = cf.toFieldValue();
        });

        return fieldValues;
    }

    static getTypes() {
        return [
            'string',
            'integer',
            'decimal',
            'boolean',
            'date',
            'select',
        ];
    }

    static getModels(): { absence: string, shift: string, employee: string, work_order: string, emergency_contact: string, paid_time: string, contract: string } {
        return {
            absence: 'Modules\\Absence\\Models\\Absence',
            employee: 'App\\Models\\Employee',
            emergency_contact: 'App\\Models\\EmergencyContact',
            paid_time: 'Modules\\PaidTime\\Models\\PaidTime',
            shift: 'Modules\\Scheduling\\Models\\Shift',
            work_order: 'Modules\\WorkOrders\\Models\\WorkOrder',
            contract: 'App\\Models\\Contract',
        };
    }

    static async storeFields(customerId: number, $http: angular.IHttpService) {
        if (CustomFieldOld.customFields) {
            return;
        }

        return $http.get<Record<string, ModelCustomFieldResponse>|ModelCustomFieldResponse[]>(EawUrl.url + `/customers/${customerId}/custom_fields/all`).then((resp) => {
            const fields = resp.data;
            CustomFieldOld.customFields = groupBy(fields, (x: ModelCustomFieldResponse) => x.pivot.model);
        });
    }

    static getAllFields() {
        const fields: Record<string, any> = {};
        Object.entries(CustomFieldOld.customFields || {}).forEach(([ key, value ]: [ string, any ]) => {
            fields[key] = value.map((e: ModelData) => new this(e));
        });

        return fields;
    }

    static getModelFields(model: string, existing: CustomFieldOld[] = []) {
        return this.getAllFields()[model]?.map((f: any) => {
            f.value = existing.find((e) => e.key === f.key)?.value;
            return f;
        });
    }

    _set(obj: any, prop: string, value: any) {
        if (prop === 'value') {
            obj[prop] = PropertyOld.formatValue(value, this.type);
        } else {
            obj[prop] = value;
        }

        return true;
    }

    /**
     * @return {CustomFieldOld}
     */
    clone() {
        return new CustomFieldOld(cloneDeep(this));
    }

    setSelectOptions() {
        const opts = this.options || this.select_options;
        if (typeof opts === 'object' && opts != null) {
            this.selectOptions = {};

            Object.entries(opts).forEach(([ key, value ]: [string, any]) => {
                this.selectOptions![key] = eawTranslate(value, Namespace.CustomFields);
            });
        }
    }

    toFieldValue(): FieldValue | null {
        if (this.value == null) {
            return null;
        }

        const v: FieldValue = {
            value: this.dateValue,
        };

        if (moment.isMoment(this.from)) {
            v.from = this.from;
            v.to = this.to ?? null;
        }

        return v;
    }

    translate(): string {
        if (typeof this.name !== 'string') {
            return '';
        }

        return eawTranslate(this.name, 'custom_fields');
    }
}
