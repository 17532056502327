import { Component, computed, DestroyRef, inject, Input, Signal,
    signal,
    viewChild,
    WritableSignal } from '@angular/core';
import { MatCard, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { AsyncPipe } from '@angular/common';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { UserService } from '../../../shared/http/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataTablePagination, type DataTableRequest } from '../../../data-table/types/data-table';
import { filter, of } from 'rxjs';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { UserGroup } from '../../../company/models/user-group';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { AdminUserGroupsGroupDialogComponent } from '../../dialogs/admin-user-groups-group-dialog/admin-user-groups-group-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from '../../../shared/dialogs/dialog-component';

interface AdminUserGroupDialogData extends DialogData {
    userId: number;
}

@Component({
    selector: 'eaw-admin-user-groups',
    standalone: true,
    imports: [
        MatCard,
        MatCardHeader,
        AsyncPipe,
        MatCardTitle,
        TranslatePipe,
        PageHeaderComponent,
        DataTableComponent,
    ],
    templateUrl: './admin-user-groups.component.html',
    styleUrl: './admin-user-groups.component.scss',
})
export class AdminUserGroupsComponent {
    private translate = inject(TranslateService);
    private userService = inject(UserService);
    private destroyedRef = inject(DestroyRef);
    private matDialog = inject(MatDialog);

    table = viewChild.required(DataTableComponent);

    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    fabButton: HeaderFabButton = {
        click: this.addUserToGroup.bind(this),
        hasPermission: () => of(true),
    };

    includeInactive: WritableSignal<boolean> = signal(false);
    menuText: Signal<Promise<string>> = computed(() => this.translate.t(this.includeInactive() ? 'SHOW_INACTIVE' : 'SHOW_ACTIVE'));

    headerButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            icon: 'history',
            click: () => {
                this.includeInactive.update((value) => !value);
                this.table().refresh();
            },
            menuText: this.menuText,
        }),
    ];

    columns: DataTableColumnType<UserGroup>[] = [];

    @Input() userId!: number;

    constructor() {
        this.columns = [
            new DataTableTextColumn({
                value: (cell) => cell.item.ownerName,
                header: new DataTableHeader({
                    text: 'CUSTOMER',
                    sortBy: 'owner_id',
                }),
            }),
            new DataTableTextColumn({
                value: 'name',
                header: new DataTableHeader({
                    i18n: 'NAME',
                    sortBy: 'name',
                }),
            }),
            new DataTableDateTimeColumn({
                format: DateTime.DATETIME_MED,
                value: (cell) => cell.item.pivot.from,
                header: new DataTableHeader({
                    i18n: 'FROM',
                    sortBy: 'from',
                }),
            }),
            new DataTableDateTimeColumn({
                format: DateTime.DATETIME_MED,
                value: (cell) => cell.item.pivot.to,
                header: new DataTableHeader({
                    i18n: 'TO',
                    sortBy: 'to',
                }),
            }),
        ];
    }

    addUserToGroup(): void {
        this.matDialog.open<AdminUserGroupsGroupDialogComponent, AdminUserGroupDialogData>(AdminUserGroupsGroupDialogComponent, {
            data: {
                userId: this.userId,
            },
        }).afterClosed()
            .pipe(takeUntilDestroyed(this.destroyedRef),
                filter((userGroup) => !!userGroup))
            .subscribe(() => this.table().refresh());
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.userService.getGroups(this.userId, {
            ...pagination,
            include_inactive: this.includeInactive(),
        });
    }
}
