import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { BalanceService } from '../../http/balance.service';
import { catchError, EMPTY, forkJoin, Observable } from 'rxjs';
import { BalanceType } from '../../models/balance-type';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { BusinessDate } from '../../../shared/utils/business-date';
import { BalanceChange } from '../../models/balance-change';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { CurrentService } from '../../../shared/services/current.service';

export interface BalanceDialogComponentData extends DialogData {
    date: DateTime;
    customerId: number;
    employeeId: number;
    employeeName: string;
    balanceType: Observable<BalanceType>;
}

@Component({
    selector: 'eaw-balance-dialog',
    templateUrl: './balance-dialog.component.html',
    styleUrl: './balance-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class BalanceDialogComponent extends DialogComponent implements OnInit {
    balanceType?: BalanceType;
    editForm = new FormGroup({
        date: new FormControl<DateTime | null>(null, Validators.required),
        change: new FormControl<number | null>(null, Validators.required),
        description: new FormControl<string | null>(null),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: BalanceDialogComponentData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<BalanceDialogComponent, BalanceChange>,
        @Inject(BalanceService) private balanceService: BalanceService,
        @Inject(CurrentService) private currentService: CurrentService,
    ) {
        data.size = DialogSize.Medium;
        super(dialogRef, data);

        this.editForm.controls.date.setValue(data.date);
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        forkJoin([ this.data.balanceType ]).subscribe(([ balanceType ]) => {
            this.balanceType = balanceType;
        });
    }

    getTextSuffix() {
        return this.balanceType?.getFormattedValue(this.editForm.controls.change.value || 0, this.currentService.languageTag, {
            unitDisplay: 'long',
            signDisplay: 'never',
        }).replaceAll(/\d|\.|\,/g, '').trim();
    }

    addChange() {
        if (!this.balanceType) {
            return;
        }

        const businessDate = this.editForm.controls.date.value;
        const delta = this.editForm.controls.change.value;

        if (delta == null || businessDate == null) {
            return;
        }

        this.editForm.disable();
        this.balanceService.createBalanceChange(this.data.customerId, this.data.employeeId, this.balanceType.balanceCode, {
            business_date: new BusinessDate(businessDate),
            delta: delta * this.balanceType.factor,
            description: this.editForm.controls.description.getRawValue() || undefined,
        }).pipe(
            catchError((err) => {
                console.error(err);
                this.editForm.enable();
                return EMPTY;
            }),
        ).subscribe((res) => {
            this.dialogRef.close(res);
        });
    }
}
