import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Shift } from '../../../models/shift';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../../data-table/interfaces/data-table-columns';
import { ShiftService } from '../../../http/shift.service';
import { forkJoin, isEmpty, map, Observable, of } from 'rxjs';
import { DataTableTextColumn } from '../../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../../data-table/types/data-table-header';
import { DataTableComponent } from '../../../../data-table/data-table.component';
import { DateTime } from 'luxon';
import { DataTableDateTimeColumn } from '../../../../data-table/types/data-table-date-time-column';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../../data-table/types/data-table-button-column';
import { ShiftSwap } from '../../../models/shift-swap';
import { MatCardModule } from '@angular/material/card';
import { mockArrayPaginatedResponse } from '../../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-employee-shifts-table',
    templateUrl: './employee-shifts-table.component.html',
    styleUrl: './employee-shifts-table.component.scss',
    standalone: true,
    imports: [ MatCardModule, DataTableComponent ],
})
export class EmployeeShiftsTableComponent implements OnInit, OnChanges, EawDataTable {
    @ViewChild('table') dataTable?: DataTableComponent<Shift>;

    @Input() customerId!: number;
    @Input() employeeId!: number;
    @Input() requestSwap!: (customerId: number, shiftId: number) => Observable<ShiftSwap | undefined>;
    @Input() withdrawSwap!: (customerId: number, swapId: number, employeeId?: number) => Observable<undefined>;
    @Input() canCreateSwap!: (shift: Shift) => Observable<boolean>;
    @Input() canWithdrawSwap!: (shift: Shift) => Observable<boolean>;
    @Input() showComments!: (shift: Shift) => void;
    @Input() from?: DateTime;

    request?: DataTableRequest = of(mockArrayPaginatedResponse()); // Todo make generic?
    columns: DataTableColumnType<Shift>[] = [];

    constructor(@Inject(ShiftService) private shiftService: ShiftService) {
    }

    ngOnInit() {
        this.columns = [
            new DataTableTextColumn({
                value: (cell) => cell.item.schedule?.customer?.name || '',
                header: new DataTableHeader({ i18n: 'LOCATION' }),
            }),
            new DataTableTextColumn({
                value: (cell) => cell.item.schedule?.name || '',
                header: new DataTableHeader({
                    i18n: 'SCHEDULE',
                    ns: 'scheduling',
                }),
            }),
            new DataTableDateTimeColumn({
                value: 'from',
                format: 'WW',
                header: new DataTableHeader({ i18n: 'WEEK' }),
            }),
            new DataTableDateTimeColumn({
                value: 'from',
                format: 'cccc',
                header: new DataTableHeader({ i18n: 'DAY' }),
            }),
            new DataTableDateTimeColumn({
                value: 'from',
                format: DateTime.DATE_MED,
                header: new DataTableHeader({ i18n: 'DATE' }),
            }),
            new DataTableDateTimeColumn({
                value: 'from',
                format: DateTime.TIME_SIMPLE,
                header: new DataTableHeader({ i18n: 'FROM' }),
            }),
            new DataTableDateTimeColumn({
                value: 'to',
                format: DateTime.TIME_SIMPLE,
                header: new DataTableHeader({ i18n: 'TO' }),
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        icon: 'swap_horiz',
                        tooltip: { key: 'REQUEST_SWAP', ns: 'scheduling' },
                        click: this.swap.bind(this),
                        show: this.canCreateSwap.bind(this),
                        hide: (item) => {
                            return forkJoin([
                                of(item.hasUnprocessedSwaps()),
                                this.canCreateSwap(item),
                            ]).pipe(map(([ hasUnprocessedSwaps, canCreateSwap ]) => hasUnprocessedSwaps || !canCreateSwap));
                        },
                    },
                    {
                        icon: 'keyboard_return',
                        tooltip: { key: 'WITHDRAW_SWAP', ns: 'scheduling' },
                        click: this.withdraw.bind(this),
                        show: this.canWithdrawSwap.bind(this),
                        hide: (item) => {
                            return forkJoin([
                                of(item.hasUnprocessedSwaps()),
                                this.canWithdrawSwap(item),
                            ]).pipe(map(([ hasUnprocessedSwaps, canWithdrawSwap ]) => !(canWithdrawSwap && hasUnprocessedSwaps)));
                        },
                    },
                    {
                        icon: 'comment',
                        tooltip: { key: 'COMMENT_plural' },
                        click: (cell) => this.showComments(cell.item),
                        nonBlocking: true,
                        show: () => of(true),
                        hide: (item) => of(!item.comments?.length),
                    },
                ],
            }),
        ];
    }

    ngOnChanges(changes: SimpleChanges) {
        // If from is changed
        if (changes['from']) {
            this.updateTable(this.dataTable?.getPagination({ page: 1 }) || {});
        }
    }

    swap(cell: DataTableButtonCell<Shift>) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.requestSwap(cell.item.schedule!.customerId, cell.item.id).subscribe((swap) => {
            cell.disabled.set(!!swap);

            if (swap) {
                this.dataTable?.refresh();
            }
        });
    }

    withdraw(cell: DataTableButtonCell<Shift>) {
        const swap = cell.item.unprocessedSwaps?.find((s) => !s.approved);
        if (!swap) {
            return;
        }

        this.withdrawSwap(swap.customerId, swap.id, this.employeeId).pipe(isEmpty()).subscribe((cancel) => {
            if (cancel) {
                cell.disabled.set(false);
                return;
            }

            this.dataTable?.refresh();
        });
    }

    rowClasses(row: Shift) {
        const classes: string[] = [];

        if (row.hasUnapprovedSwaps()) {
            classes.push('has-swap');
        }
        if (row.status === 'in_progress') {
            classes.push('in-progress');
        }
        if (row.status === 'complete') {
            classes.push('complete');
        }

        return classes;
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.shiftService.getAllForEmployee(this.customerId, this.employeeId, {
            ...pagination,
            direction: 'asc',
            order_by: 'from',
            from: this.from,
            'with[]': [ 'unprocessedSwaps', 'schedule', 'comments', 'periods.businessUnit' ],
        });
    }
}
