<eaw-info-box>
    <span infoText>{{ 'ADDITIONAL_EMP_INFO' | translate:'new_employee' | async }}</span>
</eaw-info-box>

<form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-16">
    <div class="info-section">
        <mat-form-field>
            <mat-label>{{ 'ADDRESS_1' | translate | async }}</mat-label>
            <input formControlName="address1" matInput>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'ADDRESS_2' | translate | async }}</mat-label>
            <input formControlName="address2" matInput>
        </mat-form-field>
    </div>

    <div class="info-section">
        <mat-form-field>
            <mat-label>{{ 'ZIP' | translate | async }}</mat-label>
            <input formControlName="postalCode" matInput>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'CITY' | translate | async }}</mat-label>
            <input formControlName="city" matInput>
        </mat-form-field>
    </div>

    <div class="info-section">
        <eaw-autocomplete formControlName="countryKey"
                          [options]="countryAutocompleteService.options"
                          [setter]="countryAutocompleteService.setter()"
                          [getter]="countryAutocompleteService.getter()">
        </eaw-autocomplete>

        <eaw-autocomplete formControlName="region"
                          [hint]="form.controls.region.disabled ? regionHint : null"
                          [options]="countryRegionAutocompleteService.options"
                          [setter]="countryRegionAutocompleteService.setter({ countryCode: form.controls.countryKey.value })"
                          [getter]="countryRegionAutocompleteService.getter({ countryCode: form.controls.countryKey.value })"
                          [triggers]="[form.controls.countryKey]">
        </eaw-autocomplete>
    </div>

    <div class="info-section">
        <eaw-autocomplete formControlName="nationality"
                          [label]="'NATIONALITY' | translate"
                          [options]="countryAutocompleteService.options"
                          [setter]="countryAutocompleteService.setter()"
                          [getter]="countryAutocompleteService.getter()">
        </eaw-autocomplete>

        <mat-form-field>
            <mat-label>{{'GENDER' | translate | async}}</mat-label>
            <mat-select formControlName="gender">
                <mat-option [value]="'female'">{{'FEMALE' | translateSync}}</mat-option>
                <mat-option [value]="'male'">{{'MALE' | translateSync}}</mat-option>
                <mat-option [value]="'other'">{{'OTHER' | translateSync}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="info-section">
        <mat-form-field eawDatePickerOptions>
            <mat-label>{{ 'BIRTH_DATE' | translate:'company' | async }}</mat-label>
            <input formControlName="birthDate" matInput [matDatepicker]="$any(birthDatePicker)" [min]="minBirthdate" [max]="maxBirthdate">
            <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #birthDatePicker startView="multi-year"></mat-datepicker>

            @if (ageHint) {
                <mat-hint>{{ ageHint }}</mat-hint>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'PHONE' | translate | async}}</mat-label>
            <eaw-dial-phone-input formControlName="phone"></eaw-dial-phone-input>
            <mat-error>
                <eaw-field-error [control]="form.controls.phone"></eaw-field-error>
            </mat-error>
        </mat-form-field>
    </div>
</form>

@if (!infoSaved) {
    <eaw-action-button [loading]="processing"
                       [disabled]="emptyForm || form.invalid"
                       (click)="update()">{{'SAVE_INFO' | translate:'new_employee' | async}}
    </eaw-action-button>
}

<eaw-new-employee-next-step [stepper]="stepper" [step]="step"/>

