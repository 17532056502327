// @ts-nocheck
import { module } from 'angular';

module('eaw.components').component('eawTime', {
    bindings: {
        time: '<',
        type: '@',
        interval: '<?', // Seconds
        durationItems: '@?',
        durationForm: '@?',
    },
    controller: [ '$element', '$window', '$filter', function($element, $window, $filter) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.el = $element[0];
            ctrl.type = ctrl.type || 'relative';
            ctrl.interval = ctrl.interval || 60;
            ctrl.durationItems = ctrl.durationItems || 'hm';
            ctrl.durationForm = ctrl.durationForm || 'narrow';

            ctrl.update(true);
            ctrl.clock = $window.setInterval(ctrl.update, ctrl.interval * 1000);
        };

        ctrl.update = (initial) => {
            switch (ctrl.type) {
                case 'clock':
                    ctrl.el.innerHTML = ctrl.time.add(initial ? 0 : ctrl.interval, 's').format('LTS');
                    break;
                case 'relative':
                    ctrl.el.innerHTML = ctrl.time.fromNow();
                    break;
                case 'calendar':
                    ctrl.el.innerHTML = ctrl.time.calendar(null, { sameElse: 'LL' });
                    break;
                case 'duration':
                    ctrl.el.innerHTML = $filter('eawDuration')(ctrl.time, ctrl.durationForm, ctrl.durationItems);
                    break;
            }
        };

        ctrl.$onDestroy = () => {
            $window.clearInterval(ctrl.clock);
        };
    } ],
});
