// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<h2 ng-bind="$widget.event.from | moment:'LL'"></h2>
<span ng-if="$widget.event" ng-bind="::$widget.event.name"></span>
<span ng-if="!$widget.event" ng-i18next="widgets:NO_UPCOMING_EVENT"></span>
`;

module('eaw.dashboard').component('miniEventWidget', {
    template: template1,
    bindings: {},
    require: {
        mini: '^eawMiniWidget',
    },
    controllerAs: '$widget',
    controller: [ 'CalendarEventFactory', function(CalendarEventFactory) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.eventRes = CalendarEventFactory.getAll({
                from: moment().startOf('d'),
                per_page: 1,
                direction: 'asc',
                order_by: 'from',
            }, true);

            ctrl.eventRes.$promise.then((resp) => {
                ctrl.event = resp.data[0];

                ctrl.mini.dialogComponents = [ {
                    name: 'miniEventDescription',
                    label: 'EVENT_INFORMATION',
                    bindings: {
                        event: ctrl.event,
                    },
                } ];
            }).finally(() => {
                ctrl.mini.loading(false);
            });
        };

        ctrl.$onDestroy = () => {
            ctrl.eventRes?.$cancelRequest?.();
        };
    } ],
});
