// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
import { PaginationOld } from '../../../../utils/pagination-old';
export class ReportFactory {
    EawResource;
    static get $inject() {
        return [ 'EawResource' ];
    }
    url = '/customers/:customerId/reports/:report_id';
    constructor(EawResource) {
        this.EawResource = EawResource;
    }
    getAll(args) {
        return this.EawResource.create('/reports/:report_id').get({
            ...PaginationOld.getParams(args),
        });
    }
    getAllForCustomer(args) {
        return this.EawResource.create(this.url).get({
            ...PaginationOld.getParams(args),
            customerId: args.customer_id,
        });
    }
    get(reportId, options) {
        return this.EawResource.create(this.url).get({
            customerId: options.customer_id,
            report_id: reportId,
            'with[]': options.with,
            ...pick(options, [ 'from', 'to' ]),
        });
    }
    add(options) {
        return this.EawResource.create(this.url).save({
            customerId: options.customer_id,
        }, {
            class: options.className,
        });
    }
    remove(options) {
        return this.EawResource.create(this.url).delete({
            customerId: options.customer_id,
            report_id: options.report.id,
        }, {
            class: options.className,
        });
    }
}
module('eaw.misc').service('ReportFactory', ReportFactory);
