import { ApiResponse } from '../interfaces/api-response';

export interface PinCodeResponse extends ApiResponse {
    decrypted_code: string,
    needs_change: boolean,
}

export class PinCode {
    decryptedCode: string;
    needsChange: boolean;

    constructor(data: PinCodeResponse) {
        this.decryptedCode = data.decrypted_code;
        this.needsChange = data.needs_change;
    }
}
