import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent } from '../../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { RotationDialogData } from './rotation-dialog.service';
import { Rotation } from '../../models/rotation';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { TimeInputComponent } from '../../../../shared/components/date-time/time-input/time-input.component';
import { catchError, EMPTY } from 'rxjs';
import { RotationService } from '../../http/rotation.service';
import { CurrentService } from '../../../../shared/services/current.service';
import { uniqueId } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { RotationIntervalService } from '../../http/rotation-interval.service';
import { NumberPipe } from '../../../../shared/pipes/number.pipe';
import { DateTimePipe } from '../../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';

interface FormInterval {
    id: number;
    display: { from: string, to: string };
    api: { from: string, to: string };
    nextDay: boolean;
    dayIndex: number;
    processing: boolean;
}

interface FormDay {
    intervals: FormInterval[];
    index: number;
    hide: boolean;
    week: number;
    dateTime: DateTime;
}

type FormWeek = FormDay[][]

@Component({
    selector: 'eaw-hour-distribution-dialog',
    templateUrl: './rotation-dialog.component.html',
    styleUrl: './rotation-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        NgIf,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        TimeInputComponent,
        MatButtonModule,
        MatIconModule,
        MatChipsModule,
        MatTooltipModule,
        MatDialogActions,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        NumberPipe,
    ],
})
export class RotationDialogComponent extends DialogComponent implements OnInit {
    weeks: FormWeek = [];
    days = Rotation.daysArray;
    formGroup = new FormGroup({
        name: new FormControl('', Validators.required),
        startDay: new FormControl<number>(0, Validators.required),
        weeks: new FormControl<number>(1, Validators.required),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: RotationDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<RotationDialogComponent, Rotation>,
        @Inject(SnackBarService) public snackbar: SnackBarService,
        @Inject(RotationService) public rotationService: RotationService,
        @Inject(RotationIntervalService) public rotationIntervalService: RotationIntervalService,
        @Inject(CurrentService) public current: CurrentService,
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        if (this.data.rotation) {
            // Index of the day we are starting on
            const startDayIndex = Rotation.daysObject[this.data.rotation.startDay].index;

            this.formGroup.setValue({
                name: this.data.rotation.name,
                startDay: startDayIndex,
                weeks: this.data.rotation.weeks,
            });

            this.formGroup.controls.startDay.disable();
            this.formGroup.controls.weeks.disable();

            // Create again here to update according to start day
            this.createWeeks();

            this.data.rotation.intervals.forEach((interval) => {
                const indexFromMonday = interval.dayIndex + startDayIndex;
                // Index of the day in the week it is inside
                const weekDayIndex = (indexFromMonday) % 7;
                const weekIndex = Math.floor(indexFromMonday / 7);

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.addInterval(this.weeks[weekIndex]![weekDayIndex]!, weekIndex, weekDayIndex, interval.from, interval.to, interval.id);
            });
        } else {
            this.createWeeks();
        }
    }

    /**
     * @param day
     * @param weekIndex - The index in the array, from 0 to 4
     * @param dayIndex - The day's index in the array, from 0 to 6
     * @param from
     * @param to
     * @param id
     */
    addInterval(day: FormDay, weekIndex: number, dayIndex: number, from: DateTime, to: DateTime, id?: number) {
        const apiFrom = from.toFormat('HH:mm');
        const apiTo = to.toFormat('HH:mm');
        const apiId = id || uniqueId();

        if (day.intervals.find((i) => i.api.from === apiFrom && i.api.to === apiTo)) {
            return;
        }

        const interval: FormInterval = {
            id: apiId,
            processing: !!this.data.rotation && !id,
            api: {
                from: apiFrom,
                to: apiTo,
            },
            display: {
                from: from.toLocaleString(DateTime.TIME_24_SIMPLE),
                to: to.toLocaleString(DateTime.TIME_24_SIMPLE),
            },
            nextDay: parseInt(apiFrom) >= parseInt(apiTo),
            dayIndex: (weekIndex * 7) + dayIndex,
        };

        day.intervals.push(interval);
        day.intervals = day.intervals.sort((a, b) => parseInt(a.api.from) - parseInt(b.api.from));

        // Create interval if it's a saved rotation and the interval has no id
        if (!id && this.data.rotation) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const startDay = this.days[this.formGroup.controls.startDay.value!];

            this.rotationIntervalService.create(this.data.customerId, this.data.rotation.id, {
                from: apiFrom,
                to: apiTo,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                day_index: ((weekIndex * 7) + dayIndex) - startDay!.index,
            }).pipe(
                catchError((e) => {
                    console.error(e);
                    this.snackbar.t('FAILED_ADD_INTERVAL', 'rotation');
                    day.intervals = day.intervals.filter((i) => i.id !== apiId);
                    return EMPTY;
                }),
            ).subscribe((result) => {
                interval.id = result.id;
                interval.processing = false;
            });
        }
    }

    addInputInterval(day: FormDay, weekIndex: number, dayIndex: number, fromInput: TimeInputComponent, toInput: TimeInputComponent) {
        const from = fromInput.getDateTime();
        const to = toInput.getDateTime();

        if (from instanceof DateTime && to instanceof DateTime) {
            this.addInterval(day, weekIndex, dayIndex, from, to);
        }
    }

    removeInterval(intervals: FormInterval[], index: number) {
        const interval = intervals.splice(index, 1)[0];

        if (!this.data.rotation) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.rotationIntervalService.delete(this.data.customerId, this.data.rotation!.id, interval!.id).pipe(
            catchError((e) => {
                console.error(e);
                this.snackbar.t('FAILED_DELETE_INTERVAL', 'rotation');
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                intervals.splice(index, 0, interval!);
                return EMPTY;
            }),
        ).subscribe();
    }

    updateRotation() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const startDay = this.days[this.formGroup.controls.startDay.value!];
        const intervals = this.weeks.reduce((arr: { from: string, to: string, day_index: number }[], week) => {
            week.forEach((day) => {
                day.intervals.forEach((interval) => {
                    arr.push({
                        from: interval.api.from,
                        to: interval.api.to,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        day_index: interval.dayIndex - startDay!.index,
                    });
                });
            });

            return arr;
        }, []);

        const options = {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            name: this.formGroup.controls.name.value!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            start_day: startDay!.value,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            days: this.formGroup.controls.weeks.value! * 7,
            intervals,
        };

        this.rotationService.create(this.current.getCustomer().id, options).pipe(
            catchError(() => {
                this.formGroup.enable();
                return EMPTY;
            }),
        ).subscribe((resp) => {
            this.dialogRef.close(resp);
        });

        this.formGroup.disable();
    }

    createWeeks() {
        const oldWeeks = this.weeks.slice(0);
        this.weeks = [];

        // Go up to 5 because week can extend over to another week if start day is
        // not the first day of the week
        for (let i = 0; i < 5; i++) {
            this.weeks.push(this.createWeekDays(i));
        }

        if (!oldWeeks.length) {
            return;
        }

        this.weeks.forEach((week, weekIndex) => {
            week.forEach((_, dayIndex) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.weeks[weekIndex]![dayIndex]!.intervals = oldWeeks[weekIndex]![dayIndex]!.intervals;
            });
        });
    }

    private createWeekDays(week: number): FormDay[] {
        const days: FormDay[] = [];

        for (let i = 0; i < 7; i++) {
            days.push(this.createWeekDay(week, i));
        }

        return days;
    }

    private createWeekDay(week: number, day: number): FormDay {
        const startDay = this.formGroup.controls.startDay.value || 0;
        const weeks = this.formGroup.controls.weeks.value || 1;
        const index = (week * 7) + day;
        const startIndex = startDay;
        const endIndex = (((weeks - 1) * 7) + startDay + 6);

        return {
            hide: !(index >= startIndex && index <= endIndex),
            index,
            intervals: [],
            week: week + 1,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dateTime: Object.values(this.days).find((d) => d.index === day)!.dateTime,
        };
    }
}
