import { stringToDateTime } from '../utils/string-to-date-time';
import { DateTime } from 'luxon';
import { ApiResponse } from '../interfaces/api-response';

export interface QualificationResponse extends ApiResponse {
    id: number;
    name: string;
    customer_id: number;
    pivot?: {
        from: string | null;
        qualification_id: number;
        qualified_id: number;
        qualified_type: 'business_unit' | 'employee' | 'shift';
        rate: string;
        to: string | null
    };
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    employees_count?: number;
    active_employees_count?: number;
}

export class Qualification {
    name: string;
    customerId: number;
    createdAt: DateTime;
    updatedAt: DateTime;
    id: number;
    rate?: string;
    from?: DateTime;
    to?: DateTime;
    employeesCount?: number;
    activeEmployeesCount?: number;
    last_day?: boolean;

    constructor(data: QualificationResponse) {
        this.id = data.id;
        this.name = data.name;
        this.customerId = data.customer_id;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.rate = data.pivot?.rate;
        if (data.pivot?.from) {
            this.from = stringToDateTime(data.pivot?.from);
        }
        if (data.pivot?.to) {
            this.to = stringToDateTime(data.pivot?.to);
        }
        this.employeesCount = data.employees_count;
        this.activeEmployeesCount = data.active_employees_count;
    }
}
