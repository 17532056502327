// @ts-nocheck
import { module } from 'angular';

module('eaw.scheduling.shifts', [
    'eaw.resource',
    'eaw.decorators',
    'eaw.company',
    'eaw.login',
    'eaw.warnings',
]);
