import { DateTime, IANAZone } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export type VlhPeriodResponse = [string, string];

export class VlhPeriod {
    day: number;
    from: DateTime;
    to: DateTime;

    constructor(day: number, vlhPeriod: VlhPeriodResponse) {
        this.day = day;
        this.from = stringToDateTime(vlhPeriod[0], false, 'HH:mm', IANAZone.create('utc'));
        this.to = stringToDateTime(vlhPeriod[1], false, 'HH:mm', IANAZone.create('utc'));
    }
}
