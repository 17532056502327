<div #newShift id="new-shift">
    <div id="dragging-time" class="tw-flex tw-flex-col tw-items-center">
        @let from = dragFrom();
        @let to = dragTo();

        @if (from && to) {
            <div class="tw-flex tw-gap-4">
                <span>{{from | DateTime:'TIME_SIMPLE'}}</span>
                <span>—</span>
                <span>{{to | DateTime:'TIME_SIMPLE'}}</span>
            </div>

            <span class="duration">{{(to.diff(from, 'seconds').seconds | duration:[]:{}:{keepZeros: true})}}</span>
        }
    </div>
</div>

<div id="filler"></div>

@for (interval of halfIntervals(); track interval.offset;) {
    <div class="interval"></div>
}
