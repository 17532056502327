// @ts-nocheck
import { module } from 'angular';

import { TinyColor } from '@ctrl/tinycolor';

module('eaw.absence').component('eawAbsenceStatsCard', {
    template: `<div class="absence-stats-card mat-elevation-z2">
    <md-subheader ng-style="{'background': $ctrl.absenceType.color, 'color': $ctrl.absenceType.textColor}">
        <span ng-if="!$ctrl.absenceType" ng-i18next="absences:ABSENCE_TYPE_STATS"></span>
        <span ng-if="$ctrl.absenceType" ng-bind="$ctrl.absenceType.name | eawTranslate:'absence_types'"></span>
    </md-subheader>

    <table class="table table-condensed">
        <tr>
            <td><strong ng-i18next="absences:COUNT"></strong></td>
            <td class="text-right" ng-bind="$ctrl.stats.count | eawNumber"></td>
        </tr>
        <tr>
            <td>
                <strong ng-if="!$ctrl.absenceType.span" ng-i18next="TOTAL"></strong>
                <strong ng-if="$ctrl.absenceType.span == 'hour'" ng-i18next="absences:TOTAL_HOURS"></strong>
                <strong ng-if="$ctrl.absenceType.span == 'day'" ng-i18next="absences:TOTAL_DAYS"></strong>
            </td>
            <td class="text-right" ng-bind="$ctrl.stats.length | eawNumber:$ctrl.decimals"></td>
        </tr>
    </table>
</div>
`,
    bindings: {
        absenceType: '<',
        stats: '<',
    },
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.absenceType = ctrl.absenceType || {};
            ctrl.absenceType.textColor = 'black';

            if (typeof ctrl.absenceType.color === 'string') {
                ctrl.absenceType.textColor = new TinyColor(ctrl.absenceType.color).isDark() ? 'white' : 'black';
            }

            ctrl.stats = ctrl.stats || {
                count: 0,
                length: 0,
            };
            ctrl.decimals = ctrl.stats.length % 1 === 0 ? 0 : 2;
        };

        ctrl.$onChanges = ctrl.$onInit;
    },
});
