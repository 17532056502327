import { EmployeeService } from '../http/employee.service';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import * as EmailValidator from 'email-validator';
import { CountryCode, isValidNumber, validatePhoneNumberLength } from 'libphonenumber-js';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class EawValidators {
    /**
     * Checks if the provided value is a valid email address.
     * This is stricter than the builtin angular email validator.
     */
    static email(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (typeof control.value === 'string' && control.value.length) {
                return EmailValidator.validate(control.value) ? null : { email: { value: control.value } };
            }

            return null;
        };
    }

    /**
     * Checks if the provided value is an integer.
     */
    static integer(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return Number.isInteger(control.value) ? null : { integer: { value: control.value } };
        };
    }

    /**
     * Checks if the provided value is a valid phone number.
     */
    static phoneNumber(countryCode: CountryCode): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            const value = control.value;
            const error = validatePhoneNumberLength(value, countryCode);
            const valid = isValidNumber(value, countryCode);

            if (error) {
                return { [error]: { value } };
            } else if (!valid) {
                return { INVALID_NUMBER: { value } };
            }

            return null;
        };
    }

    /**
     * Checks if the provided value is already taken as a badge number on an existing employee.
     */
    static asyncBadgeNumber(customerId: number, employeeService: EmployeeService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const number = parseInt(control.value);

            if (Number.isNaN(number) || number === 0) {
                return of({ invalidNumber: true });
            }

            return employeeService.getAll(customerId, {
                number,
                include_inactive: true,
                include_future: true,
            }).pipe(
                map((response) => {
                    return response.data.length > 0 ? { badgeNumberExists: true } : null;
                }),
            );
        };
    }

    static pattern(regex: RegExp, fieldName: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return regex.test(control.value) ? null : { [fieldName]: { value: control.value } };
        };
    }
}
