<mat-card class="login-card">
    <mat-card-header class="card-head">
        <eaw-logo height="28px"></eaw-logo>
        <span class="card-title">
            {{'RESET_PASSWORD' | translate:'login'| async}}
        </span>
    </mat-card-header>
    <mat-card-content class="card-content" [class.success]="success">
        <form *ngIf="!success" id="forgot-password-form" (ngSubmit)="resetPassword()" class="tw-flex tw-flex-col tw-grow">
            <mat-form-field>
                <mat-label>{{'EMAIL' | translate | async}}</mat-label>
                <mat-icon matPrefix>person</mat-icon>
                <input matInput [formControl]="emailCtrl" autocomplete="email" required type="email">
            </mat-form-field>
            <mat-card-actions>
                <button type="submit"
                        class="tw-ml-10"
                        mat-button
                        mat-raised-button
                        color="accent"
                        form="forgot-password-form"
                        [disabled]="emailCtrl.invalid || submitting">
                    {{'RESET_PASSWORD' | translate:'login' | async}}
                </button>
            </mat-card-actions>
        </form>

        <div id="success-content" class="tw-flex tw-flex-col">
            <mat-icon>forward_to_inbox</mat-icon>
            <span>{{'PASSWORD_RESET_EMAIL_SENT' | translate:'login': { email: email } | async}}</span>
        </div>
    </mat-card-content>
</mat-card>

<button class="tw-mt-20" mat-button (click)="goToLogin()">
    <mat-icon>key</mat-icon>
    {{'LOGIN' | translate:'login' | async}}
</button>
