import { CollectionViewer, DataSource, ListRange } from '@angular/cdk/collections';
import { BehaviorSubject, debounceTime, EMPTY, forkJoin, Observable, Subscription, take, tap } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';

type BasicDataSourceGetter<T> = (page: number) => Observable<ArrayPaginatedResponse<T> | T[]>;

interface BasicDataSourceOptions {
    debounceTime: number;
}

export class BasicDataSource<T> extends DataSource<T | undefined> {
    private readonly _length: number;
    private readonly _pageSize: number;
    private readonly _cachedData: T[];
    private readonly _dataStream: BehaviorSubject<(T | undefined)[]>;
    private readonly _subscription = new Subscription();
    private _fetchedPages = new Set<number>();
    private _currentPages: number[] = [];

    constructor(length: number, pageSize: number, firstPageData: T[], private getter: BasicDataSourceGetter<T>, private options?: Partial<BasicDataSourceOptions>) {
        super();

        this._length = length;
        this._pageSize = pageSize;
        this._cachedData = Array.from({ length: this._length });
        this._dataStream = new BehaviorSubject<(T | undefined)[]>(this._cachedData);

        // Always add the first page
        this._fetchedPages.add(0);
        this._currentPages.push(0);
        this.addDataToCache(0, firstPageData);
    }

    refresh() {
        this._currentPages.forEach((p) => this._fetchedPages.delete(p));
        return forkJoin(this._currentPages.map((p) => this._fetchPage(p))).pipe(take(1));
    }

    connect(collectionViewer: CollectionViewer) {
        this._subscription.add(
            collectionViewer.viewChange
                .pipe(debounceTime(this.options?.debounceTime || 100))
                .subscribe(this.onViewChange.bind(this)),
        );

        return this._dataStream;
    }

    disconnect(): void {
        this._subscription.unsubscribe();
    }

    replace(item: T, index: number) {
        this._cachedData.splice(index, 1, item);
        this._dataStream.next(this._cachedData);
    }

    private onViewChange(range: ListRange) {
        const startPage = this._getPageForIndex(range.start);
        const endPage = this._getPageForIndex(range.end - 1);
        this._currentPages = [];

        for (let i = startPage; i <= endPage; i++) {
            this._currentPages.push(i);
            this._fetchPage(i).subscribe();
        }
    }

    private addDataToCache(page: number, data: T[]) {
        this._cachedData.splice(
            page * this._pageSize,
            this._pageSize,
            ...data,
        );

        this._dataStream.next(this._cachedData);
    }

    private _getPageForIndex(index: number): number {
        return Math.floor(index / this._pageSize);
    }

    private _fetchPage(page: number) {
        if (this._fetchedPages.has(page)) {
            return EMPTY;
        }
        this._fetchedPages.add(page);

        // Add 1 because Angular starts from 0
        return this.getter(page + 1).pipe(
            tap((res) => {
                this.addDataToCache(page, 'data' in res ? res.data : res);
            }),
        );
    }
}
