// @ts-nocheck
import { module } from 'angular';
export class EawCalendarSidenavController {
    $element;
    Calendar;
    SubscribeCalendarDialog;
    ToastService;
    $state;
    SyncEventDialog;
    static get $inject() {
        return [ '$element', 'Calendar', 'SubscribeCalendarDialog', 'ToastService', '$state', 'SyncEventDialog' ];
    }

    calview;
    constructor($element, Calendar, SubscribeCalendarDialog, ToastService, $state, SyncEventDialog) {
        this.$element = $element;
        this.Calendar = Calendar;
        this.SubscribeCalendarDialog = SubscribeCalendarDialog;
        this.ToastService = ToastService;
        this.$state = $state;
        this.SyncEventDialog = SyncEventDialog;
    }

    $onInit() {
        this.watchCheckboxes();
    }

    sync() {
        this.SyncEventDialog.open(this.calview.customer.id);
    }

    createCalendar() {
        this.$state.transitionTo('eaw/app/calendar2/create');
    }

    manageCalendar = (calendar) => {
        this.$state.transitionTo('eaw/app/calendar2/manage', {
            customer: calendar.customer,
            key: calendar.key,
        });
    };

    subscribeCalendar = async () => {
        const calendar = await this.SubscribeCalendarDialog.open(this.calview.customer.id);
        this.calview.addCalendar(calendar);
        this.ToastService.tToast('calendar:SUBSCRIBED_TO_CAL', {
            name: calendar.name,
        });
    };

    unsubscribe = async (calendar, event) => {
        event.stopPropagation();
        try {
            calendar.processing = true;
            await calendar.unsubscribe(this.calview.customer.id, this.calview.user.id);
        } catch (e) {
            calendar.processing = false;
            return console.error(e);
        }
        this.calview.removeCalendar(calendar);
    };

    watchCheckboxes() {
        const observer = new MutationObserver(() => {
            const checkboxes = Array.from(this.$element[0].querySelectorAll('md-checkbox'));
            checkboxes.forEach((el) => {
                const checked = el.classList.contains('md-checked');
                const color = el.dataset.color;
                const icon = el.querySelectorAll('.md-icon')[0];
                icon.style.borderColor = color;
                icon.style.background = checked ? color : 'inherit';
            });
        });
        observer.observe(document.body, {
            subtree: true,
            attributes: true,
        });
    }
}
module('eaw.calendar2').component('eawCalendarViewSidenav', {
    template: `<md-subheader>
    <div layout="row" layout-align="space-between center">
        <strong id="user-name" ng-i18next="calendar:CALENDAR_plural"></strong>

        <div>
            <md-button id="cal-sync-btn" class="header-btn md-icon-button" ng-click="$calnav.sync()">
                <md-icon ng-bind="'sync'"></md-icon>
                <md-tooltip ng-i18next="calendar:SYNC"></md-tooltip>
            </md-button>

            <md-menu>
                <md-button id="new-cal-btn" class="header-btn md-icon-button" ng-click="$mdMenu.open()">
                    <md-icon ng-bind="'add'"></md-icon>
                </md-button>

                <md-menu-content width="4">
                    <md-menu-item ng-click="$calnav.createCalendar()">
                        <md-button>{{'calendar:CREATE_NEW_CALENDAR' | i18next}}</md-button>
                    </md-menu-item>
                    <md-menu-item ng-click="$calnav.subscribeCalendar()">
                        <md-button>{{'calendar:SUBSCRIBE_CALENDAR' | i18next}}</md-button>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        </div>
    </div>
</md-subheader>

<div class="calendar-item" ng-repeat="calendar in $calnav.calview.calendars" ng-click="$calnav.calview.toggleCalendarVisibility(calendar)" md-ink-ripple="{{calendar.color}}">
    <md-checkbox class="condensed" ng-model="calendar.visible" data-color="{{calendar.color}}">
        <div layout="column">
            <span ng-bind="calendar.name"></span>
        </div>
    </md-checkbox>

    <md-icon class="extra-icon" ng-if="calendar.subscribed" ng-bind="'close'" ng-click="$calnav.unsubscribe(calendar, $event)">
        <md-tooltip md-direction="left" ng-i18next="calendar:UNSUBSCRIBE"></md-tooltip>
    </md-icon>

    <md-menu>
        <md-icon class="extra-icon" ng-bind="'more_vert'" stop-propagate ng-click="$mdMenu.open()"></md-icon>

        <md-menu-content width="4">
            <md-menu-item ng-click="$calnav.calview.displayThisOnly(calendar)">
                <md-button>{{'calendar:DISPLAY_THIS_ONLY' | i18next}}</md-button>
            </md-menu-item>
            <md-menu-item ng-click="$calnav.manageCalendar(calendar)" ng-if="calendar.access.manage">
                <md-button>{{'SETTING_plural' | i18next}}</md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>
</div>
`,
    controllerAs: '$calnav',
    require: {
        calview: '^eawCalendarView',
    },
    controller: EawCalendarSidenavController,
});
