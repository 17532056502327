import { Component, Inject, Input, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { PermissionService } from '../../angularjs/shared/services/permission.service';
import { Customer } from '../../../shared/models/customer';
import { catchError, EMPTY, mergeMap, of, switchMap, tap } from 'rxjs';
import { SimplePermission } from '../../../shared/interfaces/simple-permission';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { PermissionDialogService } from '../../angularjs/shared/services/permission-dialog.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

// For now, this component only works with leader roles
@Component({
    selector: 'eaw-permission-table',
    templateUrl: './permission-table.component.html',
    styleUrl: './permission-table.component.scss',
    standalone: true,
    imports: [ DataTableComponent ],
})
export class PermissionTableComponent implements EawDataTable {
    @Input() customer!: Customer;
    @Input() roleId!: number;

    @ViewChild(DataTableComponent) table!: DataTableComponent<SimplePermission>;

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<SimplePermission>[] = [
        new DataTableTextColumn({
            value: 'node',
            header: new DataTableHeader({
                i18n: 'PERMISSION_plural',
                sortBy: 'node',
            }),
        }),
        new DataTableTextColumn({
            classes: (data) => data.item.value ? [ 'allowed' ] : [ 'not-allowed' ],
            value: (data) => this.translate.t(data.item.value ? 'ALLOWED' : 'NOT_ALLOWED'),
            header: new DataTableHeader({
                i18n: 'VALUE',
                sortBy: 'value',
            }),
        }),
        new DataTableButtonColumn({
            buttons: [ {
                icon: 'swap_horiz',
                tooltip: {
                    key: 'SWAP_PERMISSION',
                    ns: 'admin',
                },
                click: (cell) => this.swapPermission(cell),
                show: () => of(true),
            },
            {
                icon: 'delete',
                type: 'warn',
                tooltip: { key: 'REMOVE' },
                click: (cell) => this.deletePermission(cell),
                show: () => of(true),
            } ],
        }),
    ];

    constructor(
        @Inject(PermissionService) private service: PermissionService,
        @Inject(PermissionDialogService) private dialog: PermissionDialogService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
    ) {
    }

    // Open dialog, then create permissions when it is closed if permissions is returned
    createPermission() {
        this.dialog.addPermission().afterClosed().subscribe({
            next: (perm: SimplePermission[] | undefined) => {
                if (!perm) {
                    return;
                }
                of(...perm).pipe(
                    mergeMap((i) => {
                        return this.service.createForRole(this.customer.id, this.roleId, i);
                    }, 2),
                ).subscribe({
                    next: (i) => {
                        void this.snackbar.t('X_ADDED', 'general', { name: i.node });
                        this.table.refresh();
                    },
                    error: (permission) => {
                        console.error('The following permission did not save:', permission.node);
                    },
                });
            },
        });
    }

    // Set allow value to opposite of current allow value
    swapPermission(cell: DataTableButtonCell<SimplePermission>) {
        this.service.updateForRole(this.customer.id, this.roleId, cell.item.node, !cell.item.value).subscribe(() => {
            this.updateTable(this.table.getPagination({ page: 1 }));
            void this.snackbar.t('PERMISSION_UPDATED', 'admin');
        });
    }

    // Open confirm dialog for deletion, then delete permission if ok
    deletePermission(cell: DataTableButtonCell<SimplePermission>) {
        this.dialog.remove([ 'THIS_WILL_REMOVE_THE', 'admin', {
            noun: '$t(PERMISSION)',
            name: cell.item.node,
        } ]).afterClosed()
            .pipe(
                switchMap((ret) => {
                    cell.disabled.set(ret?.ok);

                    if (!ret?.ok) {
                        return EMPTY;
                    }

                    return this.service.deleteForRole(this.customer.id, this.roleId, cell.item.node);
                }),
                catchError(() => {
                    cell.disabled.set(false);
                    return EMPTY;
                }),
                tap(() => {
                    this.updateTable(this.table.getPagination({ page: 1 }));
                    void this.snackbar.t('X_REMOVED', 'general', { var: cell.item.node });
                }),
            ).subscribe();
    }

    // Updates table when called
    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.service.getAllForRole(this.customer.id, this.roleId, pagination);
    }
}
