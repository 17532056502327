// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
import {smallDevice} from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
const template = `<md-card>
    <md-card-content>
        <form class="tw-pb-16 tw-pl-12">
            <div layout="row">
                <approval-mode flex="100" flex-gt-md="25" ng-model="$vacations.mode" default-mode="processed"></approval-mode>

                <eaw-date-interval flex="100" flex-gt-md="50" layout="row" ng-model="$vacations.interval" ng-disabled="$vacations.processing"></eaw-date-interval>
            </div>
            <md-card-actions>
                <md-button type="submit" class="md-raised md-accent" ng-click="$vacations.update()">
                    <span ng-i18next="UPDATE"></span>
                </md-button>
                <md-button class="md-raised" type="reset" ng-click="$vacations.resetFilter()" ng-i18next="RESET"></md-button>
            </md-card-actions>
        </form>

        <eaw-dynamic-ng-table
                cells="$vacations.cells"
                columns="$vacations.columns"
                get-data="$vacations.getData(params, pagination)"
                reload-table="$vacations.reload"
                go-to="eaw/app/chain_ops/absences/tabs/vacations/tabs|group=cg|c=customer_id|e=employee_id|offTime=id"
                scope-vars="$vacations.scopeVars">
        </eaw-dynamic-ng-table>
    </md-card-content>
</md-card>
`;
module('eaw.chain-ops.absences').component('chainOffTime', {
    template,
    controllerAs: '$vacations',
    bindings: {
        customerGroup: '<',
    },
    controller: [ 'OffTime', 'UrlParams', function chainOffTime(OffTime, UrlParams) {
        this.$onInit = () => {
            this.interval = {
                from: UrlParams.get('from', moment().subtract(1, 'M')),
                to: UrlParams.get('to', moment().add(1, 'M')),
            };
            // Templates for ng-table-dynamic
            this.columns = [
                {
                    title: t('NAME'),
                    sortable: 'employee_id',
                },
                {
                    title: t('FROM'),
                    sortable: 'from',
                },
                {
                    title: t('TO'),
                    sortable: 'to',
                },
                {
                    title: t('navigation:POSITIONS'),
                    show: !smallDevice(),
                },
                {
                    title: t('vacation:APPROVED'),
                    class: 'text-center',
                },
                {
                    title: t('SENT'),
                    sortable: 'created_at',
                    show: !smallDevice(),
                },
            ];
            this.cells = [
                { template: 'item.name' },
                {
                    template: 'item.from | moment:(item.hide_time ? "date" : "datetime")',
                    classes: [ 'overflow-ellipsis', 'p-r-0' ],
                },
                {
                    template: 'item.to | moment:(item.hide_time ? "date" : "datetime")',
                    classes: [ 'overflow-ellipsis', 'p-r-0' ],
                },
                { template: 'item.positions' },
                {
                    approval: { displayName: true },
                },
                { template: 'item.created_at | moment' },
            ];
        };
        this.update = () => {
            if (this.processing) {
                return;
            }
            UrlParams.set('from', this.interval?.from);
            UrlParams.set('to', this.interval?.to);
            this.reload = true;
        };
        this.resetFilter = () => {
            this.interval = {};
            this.mode = null;
        };
        this.getData = (params, pagination) => {
            if (this.processing) {
                return;
            }
            this.processing = true;
            this.columns[5].show = !this.mode || this.mode.handled === 1;
            let w = [ 'approval', 'comments' ];
            if (!smallDevice()) {
                w = w.concat([ 'employee.positions' ]);
            } else {
                w = w.concat([ 'employee' ]);
            }
            return OffTime.getForCustomerGroup({
                ...pagination.toWithPaginatorArguments(),
                groupId: this.customerGroup.id,
                with: w,
                handled: this.mode?.handled,
                approved: this.mode?.approved,
                from: this.interval?.from,
                to: this.interval?.to,
            }).then((resp) => {
                resp.data.map((ot) => {
                    ot.positions = ot.employee?.positions.map((p) => p.name).join(', ');
                    ot.hide_time = ot.from.isSame(ot.from.clone().startOf('d'), 'm') && ot.to.isSame(ot.to.clone().endOf('d'), 'm');
                    ot.cg = this.customerGroup.id;
                });
                return resp;
            }).finally(() => delete this.processing);
        };
    } ],
});
