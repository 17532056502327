<mat-form-field>
    <mat-label>{{ label | async }}</mat-label>

    <div matPrefix *ngIf="!!profilePicturePrefix && !!control?.value?.user">
        <eaw-profile-picture class="tw-ml-8" [user]="control?.value.user" [size]="20"></eaw-profile-picture>
    </div>

    <mat-icon *ngIf="icon" matIconPrefix>{{ icon }}</mat-icon>

    <input #filterInput matInput [formControlName]="controlName" [matAutocomplete]="autocomplete" (focus)="focus()" autocomplete="off">

    <mat-progress-spinner matSuffix class="tw-mr-8" strokeWidth="3" diameter="24" *ngIf="loading" mode="indeterminate"></mat-progress-spinner>

    <button matSuffix mat-icon-button type="reset" *ngIf="!loading && !control?.disabled && !!control?.value" (click)="clearValue()" tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>

    <mat-hint>{{ 'THREE_CHARS_FOR_FILTER' | translate | async }}</mat-hint>

    <mat-autocomplete #autocomplete [displayWith]="autocompleteDisplay" (optionSelected)="selected($event)">
        <mat-option *ngFor="let item of items" [value]="item" [class.inactive]="$any(item).inactive">
            <ng-container *ngIf="optionTemplate" [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
            <span *ngIf="!optionTemplate">{{ $any(item)[itemKey || 'name'] }}</span>
        </mat-option>

        <mat-option *ngIf="loading" disabled>
            {{ 'SEARCHING_ELLIPSIS' | translateSync }}
        </mat-option>
        <mat-option *ngIf="!loading && total === 0" disabled>
            {{ 'NO_DATA' | translateSync }}
        </mat-option>
        <mat-option *ngIf="!loading && total > 0 && results === 0 && filter" disabled>
            {{ 'NO_HITS_FOR_FILTER' | translateSync: 'general':{filter: filter} }}
        </mat-option>
        <mat-option *ngIf="!loading && results > 0 && total > 0" disabled>
            {{ 'SHOWING_X_OF_TOTAL' | translateSync: 'general':{number: results, total: total} }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
