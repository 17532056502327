// @ts-nocheck
import { module } from 'angular';
import { uniqueId } from '../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="$settings.widget.i18name | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content tw-p-0">
            <md-list>
                <md-list-item ng-repeat="location in $settings.locations">
                    <p ng-bind="location.name"></p>
                    <md-checkbox class="md-secondary" ng-model="location.selected" ng-change="$settings.locationClick(location)"></md-checkbox>
                    <md-divider ng-if="!$last"></md-divider>
                </md-list-item>
            </md-list>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button ng-disabled="!$settings.settings" ng-click="$settings.submit()" close-dialog resolve="true" reason="$settings.settings" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>
</md-dialog>
`;

module('eaw.dashboard').factory('workingEmployeesSettings', [ '$mdDialog', 'EawResource', function($mdDialog, EawResource) {
    return function(widget) {
        return $mdDialog.show({
            template: template1,
            controllerAs: '$settings',
            multiple: true,
            controller: function() {
                const ctrl = this;

                ctrl.$onInit = () => {
                    ctrl.widget = widget;

                    EawResource.create(`/customers/${CurrentOld.customer.id}`).get({
                        'with[]': 'customer_groups',
                    }).$promise.then((customer) => {
                        ctrl.locations = [
                            {
                                id: uniqueId(),
                                type: 'current',
                                name: CurrentOld.customer.name,
                            },
                        ].concat(customer.customer_groups.map((g) => {
                            return {
                                id: uniqueId(),
                                type: 'group',
                                name: g.name,
                                groupId: g.id,
                            };
                        }));
                    });
                };

                ctrl.locationClick = (location) => {
                    ctrl.settings = null;

                    ctrl.locations
                        .filter((l) => l.id !== location.id)
                        .forEach((l) => {
                            l.selected = false;
                        });

                    if (!location.selected) {
                        return;
                    }

                    ctrl.settings = {
                        location: location.type,
                        group: location.groupId,
                    };
                };
            },
        });
    };
} ]);
