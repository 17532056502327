import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tos, TosResponse } from '../models/tos';
import { classifyItem } from '../utils/rxjs/classify';

@Injectable({
    providedIn: 'root',
})
export class TosService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    get() {
        return this.http.get<TosResponse>('/tos').pipe(classifyItem(Tos));
    }

    accept() {
        return this.http.post('/tos/accept', {});
    }
}
