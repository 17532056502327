import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { EmployeeSwapSummary, SwapSummaryResponse } from '../models/employee-swap-summary';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ShiftSwapSummaryService {

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
    ) {
    }

    getSwapSummary(customerId: number, swapId: number): Observable<EmployeeSwapSummary[]> {
        return this.http.get<SwapSummaryResponse>(`/customers/${ customerId }/shift_swaps/${ swapId }/summary`).pipe(
            map((summary) => {
                const summaries: EmployeeSwapSummary[] = Object.entries(summary.employees ?? {}).map(([ id, value ]) => {
                    return new EmployeeSwapSummary(parseInt(id), value);
                });

                Object.entries(summary).forEach(([ key, value ]) => {
                    if (key == 'employees') {
                        return;
                    }

                    summaries.push(new EmployeeSwapSummary(parseInt(key), value));
                });

                return summaries;
            }),
        );
    }
}
