// @ts-nocheck
import { module } from 'angular';

module('eaw.scheduling').component('schedulingGoToDay', {
    template: `<md-button class="md-icon-button" ng-click="$go.goToPrevDay()" ng-disabled="$go.disablePrevDay()">
    <md-tooltip>{{'scheduling:PREVIOUS_DAY' | i18next}}</md-tooltip>
    <md-icon ng-bind="'arrow_back'" class="s18"></md-icon>
</md-button>

<md-button class="md-icon-button" ng-click="$go.openDayPicker($event)">
    <md-tooltip>{{'scheduling:GO_TO_DAY' | i18next}}</md-tooltip>
    <md-icon ng-bind="'calendar_month'" class="s18"></md-icon>
</md-button>

<md-button class="md-icon-button" ng-click="$go.goToNextDay()" ng-disabled="$go.disableNextDay()">
    <md-tooltip>{{'scheduling:NEXT_DAY' | i18next}}</md-tooltip>
    <md-icon ng-bind="'arrow_forward'" class="s18"></md-icon>
</md-button>
`,
    controllerAs: '$go',
    bindings: {
        loadShifts: '<?',
    },
    require: {
        toolbar: '^scheduleToolbar',
    },
    controller: [ 'scheduleTable', '$scope', 'eawDatePickerDialog', 'templateDatePicker', function(scheduleTable, $scope, eawDatePickerDialog, templateDatePicker) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.schedule = scheduleTable.getSchedule();
            ctrl.goToDate = ctrl.schedule.render.from.clone(); // model for timepicker, not starting with this would be confusing.

            ctrl.min = ctrl.schedule.getFrom();
            ctrl.max = ctrl.schedule.getTo().subtract(1, 's').startOf('d');

            // $scope.$on('sidebar:dayChanged', ctrl.dayChangedEvent)
        };

        ctrl.dayChangedEvent = (event, day) => {
            scheduleTable.goToDay(day.moment.startOf('d'));
            ctrl.toolbar.refreshTopStats();
        };

        ctrl.goToPrevDay = () => {
            scheduleTable.goToDay(scheduleTable.getCurrentDate().subtract(1, 'd').startOf('d'));
            ctrl.toolbar.refreshTopStats();
        };

        ctrl.goToNextDay = () => {
            scheduleTable.goToDay(scheduleTable.getCurrentDate().add(1, 'd').startOf('d'));
            ctrl.toolbar.refreshTopStats();
        };

        ctrl.disableNextDay = () => scheduleTable.getCurrentDate().isSame(ctrl.max, 'd');

        ctrl.disablePrevDay = () => scheduleTable.getCurrentDate().isSame(ctrl.min, 'd');

        ctrl.openGoToTimePicker = (event) => {
            eawDatePickerDialog.open(scheduleTable.getCurrentDate(), {
                minDate: ctrl.min,
                maxDate: ctrl.max,
                focusOnOpen: true,
                event,
                callback(date) {
                    if (!date.isSame(scheduleTable.getCurrentDate(), 'd')) {
                        scheduleTable.goToDay(date);
                        ctrl.toolbar.refreshTopStats();
                    }
                },
            });
        };

        ctrl.openGoToTemplateTimePicker = () => {
            templateDatePicker.open().then((date) => scheduleTable.goToDay(date));
        };

        ctrl.openDayPicker = (event) => {
            if (scheduleTable.getSchedule().is_template) {
                ctrl.openGoToTemplateTimePicker();
            } else {
                ctrl.openGoToTimePicker(event);
            }
        };
    } ],
});
