import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent } from '../../../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CreateUserAccessData } from '../user-access-dialog.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { UserAccessService } from '../../../../http/user-access.service';
import { catchError, forkJoin, map, mergeMap, Observable, of, reduce, startWith, switchMap, throwError } from 'rxjs';
import { UserGroup } from '../../../../models/user-group';
import { UserService } from '../../../../../shared/http/user.service';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { CurrentService } from '../../../../../shared/services/current.service';
import { UserAccess } from '../../../../models/user-access';
import { UserGroupService } from '../../../../../shared/http/user-group.service';
import { HttpContext } from '@angular/common/http';
import { IGNORE_ERROR } from '../../../../../shared/http/http-contexts';
import { EawValidators } from '../../../../../shared/validators/eaw-validators';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { DateTimeInputComponent } from '../../../../../shared/components/date-time/date-time-input/date-time-input.component';
import { DateTimeRangeInputComponent } from '../../../../../shared/components/date-time/date-time-range-input/date-time-range-input.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { LanguageAutocompleteComponent } from '../../../../../shared/components/language-autocomplete/language-autocomplete.component';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../../../../shared/dialogs/dialog-header/dialog-header.component';
import { ActionButtonComponent } from '../../../../../shared/components/action-button/action-button.component';
import { User } from '../../../../../shared/models/user';
import { group } from '@angular/animations';
import { PermissionCheckService } from '../../../../../shared/services/permission-check.service';
import { ApiModel } from '../../../../../shared/enums/api-model';
import { DialPhone, DialPhoneInputComponent } from '../../../../../shared/components/dial-phone-input/dial-phone-input.component';
import { FieldErrorComponent } from '../../../../../shared/components/field-error/field-error.component';

@Component({
    selector: 'eaw-add-user-access-dialog',
    templateUrl: './add-user-access-dialog.component.html',
    styleUrl: './add-user-access-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        NgFor,
        MatOptionModule,
        LanguageAutocompleteComponent,
        MatSelectModule,
        MatSlideToggleModule,
        DateTimeRangeInputComponent,
        DateTimeInputComponent,
        MatDialogActions,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
        ActionButtonComponent,
        DialPhoneInputComponent,
        FieldErrorComponent,
    ],
})
export class AddUserAccessDialogComponent extends DialogComponent<CreateUserAccessData, UserAccess> implements OnInit {
    // True when searching for a user
    searching = false;
    // True when a search has been performed
    searched = false;
    processing = false;
    // An existing user may be provided
    user?: User;
    minDate = DateTime.now().startOf('day');
    userGroups: UserGroup[] = [];
    filteredGroups: Observable<UserGroup[]> = of([]);
    form = new FormGroup({
        user: new FormGroup({
            email: new FormControl(this.user?.email || '', { validators: [ Validators.required, EawValidators.email() ] }),
            firstName: new FormControl(this.user?.firstName, { validators: Validators.required }),
            lastName: new FormControl(this.user?.lastName, { validators: Validators.required }),
            phone: new FormControl<DialPhone | null>(null),
            language: new FormControl(this.user?.language || this.current.getUser().language),
        }),
        userGroups: new FormControl<UserGroup[]>([], Validators.required),
        userGroupSearch: new FormControl(''),
        customStartToggle: new FormControl(false, { nonNullable: true }),
        temporaryAccessToggle: new FormControl(false, { nonNullable: true }),
        from: new FormControl<DateTime | null>(null),
        to: new FormControl<DateTime | null>(null),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateUserAccessData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AddUserAccessDialogComponent, UserAccess>,
        @Inject(UserAccessService) private userAccessService: UserAccessService,
        @Inject(UserService) private userService: UserService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(SnackBarService) private snackbar: SnackBarService,
        @Inject(UserGroupService) private userGroupService: UserGroupService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
    ) {
        super(dialogRef, data);
        this.searched = !!this.user;
        this.user = this.data.user;
    }

    ngOnInit() {
        this.form.controls.customStartToggle.valueChanges.subscribe((required) => {
            if (required) {
                this.form.controls.from.addValidators(Validators.required);
            } else {
                this.form.controls.from.removeValidators(Validators.required);
            }

            this.form.updateValueAndValidity({ emitEvent: false });
        });

        this.form.controls.temporaryAccessToggle.valueChanges.subscribe((required) => {
            if (required) {
                this.form.controls.to.addValidators(Validators.required);
            } else {
                this.form.controls.to.removeValidators(Validators.required);
            }

            this.form.updateValueAndValidity({ emitEvent: false });
        });

        if (this.user) {
            this.form.controls.user.patchValue({
                email: this.user.email,
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                phone: this.user.phone || this.user.countryCode ? { phone: this.user.phone || '', dial: this.user.countryCode || '' } : null,
                language: this.user.language || null,
            });
            // Disable addUser fields if one was provided
            this.form.controls.user.disable();

            // If a user was provided, assume we don't necessarily want to add them to a group.
            this.form.controls.userGroups.removeValidators(Validators.required);
        }

        this.getUserGroups();
    }

    getUserGroups() {
        this.userGroupService.getAllForCustomer(this.data.customer.id, { per_page: 999 }).pipe(
            switchMap((groups) => {
                return forkJoin(groups.data.map((group) => {
                    return this.permissionCheckService.isAllowed(
                        `customers.${this.data.customer.id}.user_groups.${group.id}.members.add`, {
                            models: [
                                { id: group.id, type: ApiModel.UserGroup },
                            ],
                        },
                    ).pipe(
                        map((allowed) => {
                            return allowed ? group : { ...group, isDisabled: true };
                        }),
                    );
                }));
            }),
        ).subscribe((data) => {
            if (!data.length) {
                return;
            }
            const userGroups: UserGroup[] = [];
            data.map((group) => {
                if (group) {
                    userGroups.push(group);
                }
            });
            this.userGroups = userGroups;
            this.filteredGroups = this.form.controls.userGroupSearch.valueChanges.pipe(
                startWith(''),
                map((value) => value ? value : ''),
                map((value) => value.toLowerCase()),
                map((value) => this.userGroups.filter((group) => group.name.toLowerCase().includes(value))),
            ) || of([]);
        });
    }

    // Search for user if there are none
    searchForUser() {
        if (this.form.controls.user.controls.email.invalid) {
            return;
        }

        this.searching = true;
        this.userService.get(this.form.controls.user.controls.email.value || '').pipe(
            catchError((err) => err.status === 404 ? of(undefined) : throwError(err)),
        ).subscribe({
            next: (user) => {
                this.user = user;

                if (!user) {
                    return;
                }

                this.form.controls.user.patchValue({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: { phone: user.phone || '', dial: user.countryCode || '' },
                    language: user.language || null,
                });

                this.form.controls.user.disable();
            },
            complete: () => {
                this.searching = false;
                this.searched = true;
                this.form.controls.user.controls.email.disable();
            },
        });
    }

    /**
     * Gets the start date based on the custom start toggle
     */
    getFrom() {
        // If the custom start toggle is on, use the date in the "from" input, otherwise use now
        if (this.form.controls.customStartToggle.value) {
            return this.form.controls.from.value || 'invalid';
        }

        return DateTime.now();
    }

    getTo() {
        if (this.form.controls.temporaryAccessToggle.value) {
            return this.form.controls.to.value || 'invalid';
        }

        return null;
    }

    submit() {
        // Disable form while sending data
        this.processing = true;

        if (this.user) {
            this.grantAccess(this.user);
        } else {
            this.userService.create({
                first_name: this.form.controls.user.controls.firstName.value || '',
                last_name: this.form.controls.user.controls.lastName.value || '',
                email: this.form.controls.user.controls.email.value || '',
                phone: this.form.controls.user.controls.phone.value?.phone,
                country_code: this.form.controls.user.controls.phone.value?.dial,
                language_code: this.form.controls.user.controls.language.value?.code,
            }).subscribe({
                next: (user) => {
                    this.user = user;
                    this.grantAccess(user);
                },
                error: () => {
                    this.form.enable();
                    this.processing = false;
                },
            });
        }

        this.form.disable();
    }

    private grantAccess(user: User) {
        const from = this.getFrom();
        const to = this.getTo();
        const values = this.form.controls;

        if (from === 'invalid' || to === 'invalid') {
            this.form.enable();
            return;
        }

        const groups: UserGroup[] | null = values.userGroups.value;

        this.userAccessService.create(this.data.customer.id, user.id, from, to).pipe(
            switchMap((access) => {
                void this.snackbar.t('ADDED_NAME_AS_USER', 'company', { name: user.name });

                return groups ? of(...groups).pipe(
                    mergeMap((group) => {
                        return this.userGroupService.addMember({
                            member_id: access.userId,
                            from,
                            to,
                            group: {
                                owner_id: this.data.customer.id,
                                id: group.id,
                            },
                        }, new HttpContext().set(IGNORE_ERROR, [ 409 ])).pipe(
                            catchError((err) => err.status === 409 ? of(undefined) : throwError(err)),
                        );
                    }, 2),
                    reduce(() => null), // Wait for all requests to finish
                    switchMap(() => of(access)),
                ) : of(access);
            }),
        ).subscribe({
            next: (access) => this.dialogRef.close(access),
            error: () => {
                this.form.enable();
                this.form.controls.user.disable();
                this.processing = false;
            },
        });
    }

    protected readonly group = group;
    protected readonly of = of;
}
