import { Mobile } from './eaw-mobile';

export function arrayToCsv(headers: string[], data: (string | number | boolean | null | undefined)[][]) {
    data.unshift(headers);

    return data.map((row) =>
        row
            .map(String) // convert every value to String
            .map((v) => v.replaceAll('"', '""')) // escape double colons
            .map((v) => `"${v}"`) // quote it
            .join(','), // comma-separated
    ).join('\r\n'); // rows starting on new lines
}

/**
 * @see https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export function csvCreator(headers: string[], data: any[][], download: false, filename?: never): string
export function csvCreator(headers: string[], data: any[][], download: true, filename: string): string
export function csvCreator(headers: string[], data: any[][], download: boolean, filename?: string): string {
    const csv = arrayToCsv(headers, data);

    if (download) {
        const blob = new File([ csv ], `${filename}.csv`, { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        if (Mobile.isMobile) {
            Mobile.openBrowser(url);
        } else {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}.csv`);
            link.click();
        }
    }

    return csv;
}
