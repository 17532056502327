// @ts-nocheck
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';

export class FormFactory {
    EawResource;

    static get $inject() {
        return [ 'EawResource' ];
    }

    formUrl = '/customers/:customer_id/forms/:form_id';
    submissionUrl = '/customers/:customer_id/forms/:form_id/submissions/:submission_id';
    constructor(EawResource) {
        this.EawResource = EawResource;
    }

    /**
     * Sort children in a form or element group.
     *
     * @param form
     * @returns {*}
     */
    static sortFormChildren(form) {
        if (!(form.children instanceof Array)) {
            return form;
        }
        form.children.sort((a, b) => a.weight - b.weight);
        form.children.forEach((element) => {
            FormFactory.sortFormChildren(element);
        });
    }

    /**
     * @deprecated
     */
    getAll(options) {
        return this.EawResource.create(this.formUrl).get({
            customer_id: options.customer ? options.customer.id : CurrentOld.getCustomer().id,
            per_page: options.per_page,
            page: options.page,
            direction: options.direction,
            order_by: options.order_by,
            active: options.active,
            'with[]': options.with,
        });
    }

    get(options) {
        const res = this.EawResource.create(this.formUrl).get({
            customer_id: options.customer ? options.customer.id : CurrentOld.getCustomer().id,
            form_id: options.form ? options.form.id : options.form_id,
            'with[]': options.with,
        });
        res.$promise.then(FormFactory.sortFormChildren);
        return res;
    }

    update(options) {
        return this.EawResource.create(this.formUrl).update({
            form_id: options.form.id,
            customer_id: options.customer ? options.customer.id : CurrentOld.getCustomer().id,
        }, {
            active: options.active,
        });
    }

    submitForm(form, submission) {
        return this.EawResource.create(this.submissionUrl).save({
            customer_id: form.customer?.id || CurrentOld.getCustomer().id,
            form_id: form.id,
        }, submission);
    }
}
module('eaw.forms').service('FormFactory', FormFactory);
