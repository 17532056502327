// @ts-nocheck
import { module } from 'angular';
import { EawUrl } from '../../shared/angularjs/modules/resource/url.service';
module('eaw.filesystem').factory('FilesystemFactory', [ 'EawResource', 'FileService', 'Directory', 'FilesystemFile', function(EawResource, FileService, Directory, FilesystemFile) {
    const fac = this;
    fac.dirRes = EawResource.create('/customers/:customer/fs_directories/:directory');
    fac.fileRes = EawResource.create('/customers/:customer/fs_files/:file');
    fac.returnObj = (data) => {
        const parent = data.directory ? new Directory(data.directory) : null;
        if (data.type === 'directory') {
            return new Directory(data, parent);
        }
        if (data.type === 'file') {
            return new FilesystemFile(data, parent);
        }
    };
    fac.getRoot = {
        permission() {
            return true;
        },
        query(customerId, withs) {
            return fac.dirRes.get({
                customer: customerId,
                'with[]': withs,
            }).$promise.then((data) => fac.returnObj(data));
        },
    };
    fac.getFile = {
        permission() {
            return true;
        },
        query(customerId, fileId, withs) {
            return fac.fileRes.get({
                customer: customerId,
                file: fileId,
                'with[]': withs,
            }).$promise.then((data) => fac.returnObj(data));
        },
    };
    fac.getDirectory = {
        permission() {
            return true;
        },
        query(customerId, directoryId, withs) {
            return fac.dirRes.get({
                customer: customerId,
                directory: directoryId,
                'with[]': withs,
            }).$promise.then((data) => fac.returnObj(data));
        },
    };
    fac.createDirectory = {
        permission() {
            return true;
        },
        query(customerId, directoryId, name, withs) {
            return fac.dirRes.save({
                customer: customerId,
                'with[]': withs,
            }, {
                directory_id: directoryId,
                name,
            }).$promise.then((data) => fac.returnObj(data));
        },
    };
    fac.updateDirectory = {
        permission() {
            return true;
        },
        query(customerId, directoryId, updates, withs) {
            return fac.dirRes.update({
                customer: customerId,
                directory: directoryId,
                'with[]': withs,
            }, updates).$promise.then((data) => fac.returnObj(data));
        },
    };
    fac.deleteDirectory = {
        permission() {
            return true;
        },
        query(customerId, directoryId) {
            return fac.dirRes.delete({
                customer: customerId,
                directory: directoryId,
            }).$promise;
        },
    };
    fac.uploadFile = {
        permission() {
            return true;
        },
        query(customerId, directoryId, file, withs) {
            return FileService.upload(`${EawUrl.url}/customers/${customerId}/fs_files`, file, 'file', {
                directory_id: directoryId,
                with: withs,
            }).then((data) => fac.returnObj(data.data));
        },
    };
    fac.updateFile = {
        permission() {
            return true;
        },
        query(customerId, fileId, updates, withs) {
            return fac.fileRes.update({
                customer: customerId,
                file: fileId,
                'with[]': withs,
            }, updates).$promise.then((data) => fac.returnObj(data));
        },
    };
    fac.deleteFile = {
        permission() {
            return true;
        },
        query(customerId, fileId) {
            return fac.fileRes.delete({
                customer: customerId,
                file: fileId,
            }).$promise;
        },
    };
    return fac;
} ]);
