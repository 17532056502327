// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../shared/angularjs/current.factory';

const template3 = `<md-dialog>
    <form novalidate autocomplete="off">
        <eaw-dialog-header-old heading="'projections:GENERATE_BUDGETS' | i18next"></eaw-dialog-header-old>
        <md-dialog-content>
            <md-subheader>{{'projections:GENERATE_BUDGETS_DESCRIPTION' | i18next}}</md-subheader>

            <div class="md-dialog-content" layout="column">
                <md-input-container>
                    <label ng-i18next="projections:BUDGET"></label>
                    <input type="number" ng-model="gbCtrl.budget" ng-change="gbCtrl.checkRequirements()" required ng-disabled="gbCtrl.submitting">
                </md-input-container>
                <md-input-container>
                    <eaw-date-picker ng-model="gbCtrl.dateSelector"
                                     ng-required="true"
                                     ng-disabled="gbCtrl.submitting"
                                     ng-change="gbCtrl.dateChanged(gbCtrl.dateSelector)"
                                     max-date="gbCtrl.maxDate">
                    </eaw-date-picker>
                </md-input-container>
            </div>

            <md-list>
                <md-list-item ng-repeat="date in gbCtrl.dates track by $index">
                    <p ng-bind="date | moment:'LL'"></p>

                    <md-button class="md-secondary md-icon-button" ng-click="gbCtrl.removeDate(date)" ng-disabled="gbCtrl.submitting">
                        <md-icon ng-bind="'delete'"></md-icon>
                    </md-button>

                    <md-divider></md-divider>
                </md-list-item>
            </md-list>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button ng-disabled="!gbCtrl.gotWhatsRequired || gbCtrl.submitting" ng-i18next="SUBMIT" ng-click="gbCtrl.submit()"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;
const template2 = `<md-dialog>
    <form name="addProjectionsForm">
        <eaw-dialog-header-old heading="'projections:CREATE_PROJECTIONS' | i18next"></eaw-dialog-header-old>

        <md-dialog-content>
            <md-subheader>{{ctrl.date | moment:'LL'}}</md-subheader>

            <div class="md-dialog-content" layout="column">
                <md-input-container>
                    <label ng-i18next="INTERVAL"></label>
                    <md-select ng-model="ctrl.interval" required>
                        <md-option ng-repeat="interval in ::ctrl.intervals track by $index" ng-value="interval.value">{{::interval.text}}</md-option>
                    </md-select>
                </md-input-container>
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button ng-click="ctrl.create()" ng-i18next="CREATE" ng-disabled="addProjectionsForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.projections').component('projectionsDate', {
    template: `<md-card id="projections-date">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="projections:PROJECTION_plural"></span>
            <span class="md-subhead">({{$projDate.date | moment:'LL'}})</span>
        </md-card-header-text>

        <md-button class="md-icon-button" ng-click="$projDate.generateBudgets()">
            <md-icon ng-bind="'auto_fix'"></md-icon>
            <md-tooltip>{{'projections:GENERATE_BUDGETS' | i18next}}</md-tooltip>
        </md-button>
    </md-card-header>
    <md-card-content class="tw-p-0">
        <table class="table table-hover table-bordered table-vmiddle" ng-if="$projDate.projections.length">
            <thead>
            <tr>
                <th ng-i18next="DATE"></th>
                <th ng-i18next="FROM"></th>
                <th ng-i18next="TO"></th>
                <th ng-i18next="projections:TRANSACTIONS" class="text-right"></th>
                <th ng-i18next="projections:SALES" class="text-right"></th>
            </tr>
            </thead>

            <tbody>
            <tr class="hover-item" ng-repeat="projection in $projDate.projections track by $index">
                <td ng-bind="projection.date | momentString:'YYYY-MM-DD':'LL'"></td>
                <td ng-bind="projection.from | momentString:'YYYY-MM-DD HH:mm:ss':'LT'"></td>
                <td ng-bind="projection.to | momentString:'YYYY-MM-DD HH:mm:ss':'LT'"></td>
                <td class="input-td">
                    <input type="number" step="any" ng-model="projection.transactions" ng-click="$projDate.inputClick($event)" ng-blur="$projDate.save()">
                </td>
                <td class="input-td">
                    <input type="number" step="any" ng-model="projection.sales" ng-click="$projDate.inputClick($event)" ng-blur="$projDate.save()">
                </td>
            </tr>
            <tr>
                <td colspan="3" ng-i18next="SUM"></td>
                <td class="input-td">
                    <input type="number" step="any" ng-model="$projDate.transactionsSum" disabled>
                </td>
                <td class="input-td">
                    <input type="number" step="any" ng-model="$projDate.salesSum" disabled>
                </td>
            </tr>
            </tbody>
        </table>
    </md-card-content>
</md-card>
`,
    bindings: {
        projections: '<',
        date: '<',
    },
    controllerAs: '$projDate',
    controller: [ '$mdDialog', '$state', 'projectionsFactory', 'ToastService', function($mdDialog, $state, projectionsFactory, ToastService) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.checkProjections();
        };

        ctrl.checkProjections = () => {
            if (!Array.isArray(ctrl.projections) || (Array.isArray(ctrl.projections) && !Object.keys(ctrl.projections || []).length)) {
                ctrl.openCreateProjectionsDialog();
            } else {
                ctrl.setSums(ctrl.projections);
            }
        };

        ctrl.openCreateProjectionsDialog = () => {
            $mdDialog.show({
                template: template2,
                controllerAs: 'ctrl',
                controller: 'createProjectionsDialog',
                locals: {
                    date: ctrl.date,
                },
            }).then((projections) => {
                ctrl.projections = projections;
                ctrl.setSums(ctrl.projections);
            }).catch(() => {
                $state.go('eaw/app/projections_date_selector');
            });
        };

        ctrl.generateBudgets = () => {
            $mdDialog.show({
                template: template3,
                controllerAs: 'gbCtrl',
                controller: 'generateBudgetsCtrl',
                locals: {
                    date: ctrl.date,
                },
            }).then((data) => {
                ToastService.toast(t('projections:UPDATED_DATA'));
                ctrl.projections = data;
            });
        };

        ctrl.setSums = (data) => {
            ctrl.transactionsSum = data.reduce((sum, d) => sum + d.transactions, 0);
            ctrl.salesSum = data.reduce((sum, d) => sum + d.sales, 0);
            ctrl.savedSum = ctrl.getCurrentSum(data);
        };

        ctrl.getCurrentSum = (data) => data.reduce((sum, d) => sum + d.transactions + d.sales, 0);

        ctrl.inputClick = (e) => {
            e.target.select();
        };

        ctrl.save = () => {
            // Make undefined values 0
            ctrl.projections.forEach((p) => {
                p.transactions = p.transactions || 0;
                p.sales = p.sales || 0;
            });

            if (ctrl.savedSum !== ctrl.getCurrentSum(ctrl.projections)) {
                projectionsFactory.update.query(CurrentOld.customer.id, ctrl.date.format('YYYY-MM-DD'), ctrl.projections).$promise.then((resp) => {
                    ctrl.setSums(resp);
                });
            }
        };
    } ],
});
