import { Component, inject, Input, numberAttribute, OnInit } from '@angular/core';
import { PermissionSetService } from '../../../shared/http/permission-set.service';
import { PermissionSet } from '../../../shared/models/permission-set';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PermissionTreeComponent } from '../../../shared/components/permission-tree/permission-tree.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'eaw-permission-set-info',
    templateUrl: './permission-set-info.component.html',
    styleUrl: './permission-set-info.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        PermissionTreeComponent,
        MatProgressSpinnerModule,
    ],
})
export class PermissionSetInfoComponent implements OnInit {
    private readonly permissionSetService = inject(PermissionSetService);

    @Input({ required: true, transform: numberAttribute }) permissionSetId!: number;

    level = 0;
    hasLoaded = false;
    permissionSet?: PermissionSet;

    ngOnInit() {
        const args = {
            with: [ 'permissions', 'permissionSets' ],
            count: [ 'permissionSetObjects' ],
        };

        this.permissionSetService.get(this.permissionSetId, args).subscribe((resp) => {
            this.permissionSet = resp;
            this.hasLoaded = true;
        });
    }
}
