import { Component, Inject, Input } from '@angular/core';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { UserService } from '../../../shared/http/user.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { catchError, EMPTY, switchMap, tap } from 'rxjs';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { PasswordComponent } from '../../../login/components/password/password.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'eaw-user-password-tab',
    templateUrl: './user-password-tab.component.html',
    styleUrl: './user-password-tab.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        PasswordComponent,
        ReactiveFormsModule,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class UserPasswordTabComponent {
    @Input({ required: true }) userId!: number;

    resetSent = false;
    updating = false;
    passwordCtrl = new FormControl<[string | undefined, string | undefined]>([ '', '' ], {
        nonNullable: true,
        validators: [ Validators.required ],
    });

    constructor(
        @Inject(UserService) private userService: UserService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
    ) {
    }

    get validPassword() {
        const [ password, passwordConfirmation ] = this.passwordCtrl.value;
        return password === passwordConfirmation && (password?.length || 0) > 0;
    }

    updatePassword() {
        const [ password, passwordConfirmation ] = this.passwordCtrl.value;
        this.updating = true;
        this.userService.update(this.userId, {
            password,
            password_confirmation: passwordConfirmation,
        }).pipe(
            catchError(() => {
                this.updating = false;
                return EMPTY;
            }),
        ).subscribe(() => {
            this.updating = false;
            void this.snackBarService.t('PASSWORD_CHANGED', 'profile');
            this.passwordCtrl.setValue([ '', '' ]);
            this.passwordCtrl.markAsPristine();
            this.passwordCtrl.markAsUntouched();
        });
    }

    resetPassword() {
        this.userService.get(this.userId).pipe(
            switchMap((user) => {
                return user.email ? this.userService.resetPassword(user.email).pipe(
                    catchError(() => {
                        this.resetSent = false;
                        return EMPTY;
                    }),
                    tap(() => {
                        void this.snackBarService.t('PASSWORD_RESET_EMAIL_SENT', 'general', { email: user.email });
                    }),
                ) : EMPTY;
            }),
        ).subscribe();
    }
}
