import { Inject, Injectable } from '@angular/core';
import { FileUploadService } from '../../shared/services/file-upload.service';
import { DateTimeConverter } from '../../shared/utils/date-time-converter';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { ParsePayslipDryRunResult, ParsePayslipDryRunResultResponse } from '../models/parse-payslip-dry-run-result';

type UploadFileParams = {
    replace?: boolean,
    date: DateTime,
    file: File,
};

@Injectable({
    providedIn: 'root',
})
export class PayslipUploadService {

    constructor(
        @Inject(FileUploadService) protected fileUploadService: FileUploadService,
    ) {
    }

    uploadFile(customerId: number, fileTypeId: number, options: UploadFileParams): Observable<void> {
        return this.fileUploadService.upload(`customers/${ customerId }/payslip_import/${ fileTypeId }`, {
            payslip: options.file,
            date: DateTimeConverter.convertDateTimeToBusinessDate(options.date),
            replace: options.replace,
        });
    }

    dryRun(customerId: number, fileTypeId: number, options: UploadFileParams): Observable<ParsePayslipDryRunResult> {
        return this.fileUploadService.upload<ParsePayslipDryRunResultResponse>(`customers/${ customerId }/payslip_import/${ fileTypeId }/dry_run`, {
            payslip: options.file,
            date: DateTimeConverter.convertDateTimeToBusinessDate(options.date),
            replace: options.replace,
        }).pipe(classifyItem(ParsePayslipDryRunResult));
    }
}
