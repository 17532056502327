import { Component, ElementRef, HostBinding, HostListener, Inject } from '@angular/core';
import { LearningModuleService } from '../../services/learning-module.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { clamp } from 'lodash-es';
import { EasyAtWorkLearningModule } from 'tutorial-lib';
import { CurrentService } from '../../../shared/services/current.service';
import { UIRouter } from '@uirouter/core';
import { Mobile } from '../../../shared/utils/eaw-mobile';
import { EMPTY, filter, from, switchMap, take } from 'rxjs';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';

interface ResizingEvent {
    isResizing: boolean,
    startingX: number,
    startingWidth: number,
}

@Component({
    selector: 'eaw-learning-module-sidebar',
    templateUrl: './learning-module-sidebar.component.html',
    styleUrl: './learning-module-sidebar.component.scss',
    animations: [
        trigger('slideInOut', [
            state('open', style({ transform: 'translateX(0)' })),
            state('closed', style({ transform: 'translateX(100%)' })),
            // Animation has to be the same in base.scss so that they flow the same
            transition('* => *', animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
        ]),
    ],
    standalone: true,
    imports: [ EasyAtWorkLearningModule, InfoLoadingComponent ],
})
export class LearningModuleSidebarComponent {
    @HostListener('window:mousedown', [ '$event' ])
    startResizing(event: MouseEvent) {
        if (event.target instanceof HTMLElement && event.target.id === 'eaw-learning-sidepanel-drag-tool') {
            this.resizing = {
                isResizing: true,
                startingX: event.clientX,
                startingWidth: this.getSidebarWidth(),
            };
        }
    }

    @HostListener('window:mousemove', [ '$event' ])
    onResizing(event: MouseEvent) {
        if (!this.resizing.isResizing) {
            return;
        }

        const delta = event.clientX - this.resizing.startingX;
        this.nextWidth = clamp(this.resizing.startingWidth - delta, this.minWidth, window.innerWidth);
        this.setSidebarWidth(this.nextWidth);
    }

    @HostListener('window:mouseup', [ '$event' ])
    stopResizing() {
        if (!this.resizing.isResizing) {
            return;
        }

        this.resizing.isResizing = false;
        this.setSidebarWidth(this.nextWidth, true);
    }

    @HostBinding('class.loading') get loadingKlass() {
        return !this.loaded;
    };

    @HostBinding('class.toggled') get toggledKlass() {
        return this.toggled;
    };

    @HostBinding('@slideInOut') get slideInOut() {
        return this.toggled ? 'open' : 'closed';
    }

    protected loaded = false;
    protected isMobile = Mobile.isMobile;
    // Width to update to after resizing
    protected nextWidth = 0;
    protected toggled = false;
    // Whether the sidebar should go over the content or squeeze it
    protected overContent = false;
    protected readonly minWidth = 300;
    protected resizing: ResizingEvent = {
        isResizing: false,
        startingX: 0,
        startingWidth: 0,
    };

    protected modules: string[] = [];
    protected languageTag: string;
    protected userId: string;
    protected query: string = '';

    constructor(
        @Inject(LearningModuleService) protected learningModuleService: LearningModuleService,
        @Inject(ElementRef) protected elementRef: ElementRef,
        @Inject(BreakpointObserver) private breakpointObserver: BreakpointObserver,
        @Inject(CurrentService) private currentService: CurrentService,
        @Inject(UIRouter) private uiRouter: UIRouter,
    ) {
        // Set the min-width of the sidebar programmatically, so we can use it in the css and when resizing the sidebar
        (this.elementRef.nativeElement as HTMLElement).style.setProperty('--min-width', `${this.minWidth}px`);

        this.languageTag = this.currentService.languageTag;
        this.userId = this.currentService.getMe().user.id.toString();

        this.setModules();
        this.handleBreakpoints();
        this.handleToggle();

        this.uiRouter.transitionService.onSuccess({}, this.setModules.bind(this));

        this.learningModuleService.onToggle().pipe(
            filter((toggled) => toggled),
            take(1),
            switchMap(() => {
                return learningModuleService.hasAccess().pipe(
                    switchMap((hasAccess) => {
                        if (!hasAccess) {
                            return EMPTY;
                        }

                        return from(this.learningModuleService.setPermissions());
                    }),
                );
            }),
        ).subscribe(() => {
            this.loaded = true;
        });
    }

    private setModules() {
        const module = this.learningModuleService.getModules();
        this.query = module ? `"${module}" in modules[]._ref` : '';
    }

    private handleBreakpoints() {
        this.breakpointObserver.observe([ Breakpoints.Large, Breakpoints.XLarge ]).subscribe((result) => {
            this.overContent = !result.matches;
            this.updateMainContentMargin(this.getSidebarWidth());
        });
    }

    private handleToggle() {
        this.learningModuleService.onToggle().subscribe((toggled) => {
            if (!this.learningModuleService.hasBeenToggled()) {
                return;
            }

            this.toggled = toggled;

            if (this.toggled) {
                this.getContainerElement()?.classList.add('toggled');
                this.updateMainContentMargin(this.getSidebarWidth());
            } else {
                this.getContainerElement()?.classList.remove('toggled');
                this.updateMainContentMargin(0);
            }
        });
    }

    private updateMainContentMargin(margin: number) {
        document.body.style.setProperty('--main-content-margin-right', `${this.overContent ? 0 : margin}px`);
    }

    private setSidebarWidth(width: number, updateMargin = false) {
        (this.elementRef.nativeElement as HTMLElement).style.setProperty('--width', `${width}px`);

        if (updateMargin) {
            this.updateMainContentMargin(width);
        }
    }

    private getSidebarWidth() {
        const sidebar = (this.elementRef.nativeElement as HTMLElement);
        return this.toggled ? sidebar.getBoundingClientRect().width : 0;
    }

    private getContainerElement() {
        return (this.elementRef.nativeElement as HTMLElement).closest('#app-learning-module');
    }
}
