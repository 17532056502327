import { Component, DestroyRef, Inject, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { CurrentService } from '../../../shared/services/current.service';
import { DefaultHrFileService } from '../../http/default-hr-file.service';
import { HrDefaultFile } from '../../models/hr-default-file';
import { HrFileType } from '../../models/hr-file-type';
import { HrOverviewService } from '../../http/hr-overview.service';
import { SignablesService } from '../../../shared/http/signables.service';
import { ApiModelClass } from '../../../shared/enums/api-model';
import { DateTime } from 'luxon';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { FileRequestResponse, HrOverview, HrOverviewEmployee } from '../../models/hr-overview';
import { HrFileService } from '../../http/hr-file.service';
import { Signable } from '../../../shared/models/signable';
import { HrFile } from '../../models/hr-file';
import { UploadHrFileDialogService } from '../../dialogs/upload-hr-file-dialog/upload-hr-file-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { WebsocketService } from '../../../shared/services/websocket.service';
import { UIRouter } from '@uirouter/core';
import { csvCreator } from '../../../shared/utils/csv-creator';
import { catchError, debounceTime, forkJoin, of, Subject, switchMap, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { ParsePayslipComponent } from '../../../payslip-import/dialogs/parse-payslip/parse-payslip.component';
import { Customer } from '../../../shared/models/customer';
import { Products } from '../../../shared/enums/products';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { PercentPipe } from '../../../shared/pipes/percent.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { RequestHrFileDialogService } from '../../dialogs/request-hr-file-dialog/request-hr-file-dialog.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CheckboxHelperDirective } from '../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SettingService } from '../../../shared/http/setting.service';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { loadNamespaces } from 'i18next';
import { Namespace } from '../../../shared/enums/namespace';

type EmployeeFileStatus = 'MISSING' | 'EXPIRED' | 'UNSIGNED' | 'UPLOADED' | 'SIGNED';

interface HrFileData {
    first: boolean;
    type: HrFileType,
    defaultFile?: HrDefaultFile,
    text: string,
    description: string;
}

interface HrEmployeeFileData {
    fileData: HrFileData;
    empFile?: HrFile;
    status: EmployeeFileStatus;
    optional: boolean;
    icon: string;
    processing: boolean;
    requested: boolean;
    completed: boolean;
}

interface HrEmployee {
    employee: HrOverviewEmployee;
    files: HrEmployeeFileData[];
}

@Component({
    selector: 'eaw-files',
    templateUrl: './files.component.html',
    styleUrl: './files.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatCardModule,
        MatIconModule,
        NgIf,
        MatButtonModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        NgFor,
        NgClass,
        MatRippleModule,
        AsyncPipe,
        TranslatePipe,
        PercentPipe,
        ActionButtonComponent,
        MatSlideToggleModule,
        CheckboxHelperDirective,
        MatCheckboxModule,
        TranslateSyncPipe,
    ],
})
export class FilesComponent implements OnInit, OnDestroy {
    @Input() customer: Customer = this.current.getCustomer();

    fileData: HrFileData[] = [];
    filetypes: { type: HrFileType, defaultFiles: HrDefaultFile[] }[] = [];
    employees: HrEmployee[] = [];
    allFiletypes = false;
    includeInactive = false;
    gettingOverview = true;
    defaultFiles: HrDefaultFile[] = [];
    signables: Signable[] = [];
    stats = {
        notUploaded: 0,
        expired: 0,
        unsigned: 0,
        uploadedOrSigned: 0,
    };

    documentServiceEnabled = false;
    overviewSubject = new Subject<void>();

    showHasPayslipImport = false;
    selectedEmployees: HrEmployee[] = [];
    selectableMode= false;

    constructor(
        @Inject(DefaultHrFileService) private defaultHrFileService: DefaultHrFileService,
        @Inject(HrFileService) private hrFileService: HrFileService,
        @Inject(HrOverviewService) private hrOverviewService: HrOverviewService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(SignablesService) private signablesService: SignablesService,
        @Inject(UploadHrFileDialogService) private uploadHrFileDialog: UploadHrFileDialogService,
        @Inject(RequestHrFileDialogService) private requestHrFileDialog: RequestHrFileDialogService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(WebsocketService) private websocketService: WebsocketService,
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(DestroyRef) private destroyRef: DestroyRef,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(CustomerProductService) private customerProductService: CustomerProductService,
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(ConfirmDialogService) private confirmDialog: ConfirmDialogService,
        @Inject(SettingService) protected settingService: SettingService,
    ) { }

    async ngOnInit() {
        this.overviewSubject.pipe(
            debounceTime(1000),
            switchMap(this.getOverview.bind(this)),
        ).subscribe();

        this.settingService.getValue([ 'customers', this.customer.id ], 'document-service-hr-file-workflows', 0).subscribe((value) => {
            if (value > 0) {
                this.documentServiceEnabled = true;
            }
        });

        forkJoin([
            this.defaultHrFileService.getAll(this.customer.id, { per_page: 9999 }).pipe(
                catchError(() => of({ data: [] })),
            ),
            this.signablesService.getAll(this.customer.id, ApiModelClass.HrFile, { per_page: 9999 }).pipe(
                catchError(() => of({ data: [] })),
            ),
        ]).subscribe(([ defaultFiles, signables ]) => {
            this.defaultFiles = defaultFiles.data;
            this.signables = signables.data;
            this.overviewSubject.next();
        });

        const hasPayslipImport = this.customerProductService.hasProducts(this.customer.id, [ Products.PayslipImport ]);
        const canCreateImport = this.permissionCheckService.isAllowed(`customers.${this.customer.id}.employees.*.hr_files.create`);

        forkJoin([ hasPayslipImport, canCreateImport ]).subscribe(([ hasPayslipImport, canCreateImport ]) => {
            this.showHasPayslipImport = hasPayslipImport && canCreateImport;
        });

        await loadNamespaces([ Namespace.Company ]);
    }

    ngOnDestroy() {
        this.overviewSubject.complete();
    }

    getEmployeeFileData(employeeId: number, typeId: number) {
        return this.employees.find((e) => e.employee.id === employeeId)?.files.find((f) => f.fileData.type.id === typeId);
    }

    setCompletedFlag(employeeId: number, typeId: number) {
        const data = this.getEmployeeFileData(employeeId, typeId);
        if (data) {
            data.completed = true;
        }
    }

    listenCreatedChannel(employees: HrOverviewEmployee[]) {
        new Set(employees.map((e) => e.id)).forEach((employeeId) => {
            this.websocketService.listenEmployeeHrFiles(this.customer.id, employeeId, 'created', (created) => {
                this.setCompletedFlag(employeeId, typeof created.type_id === 'string' ? parseInt(created.type_id) : created.type_id);
                this.overviewSubject.next();
            }, this.destroyRef);
        });
    }

    async setFileData(hrOverview: HrOverview) {
        this.fileData = [];
        this.filetypes = [];

        hrOverview.types.forEach((ft) => {
            this.fileData.push({
                first: true,
                type: ft,
                text: '',
                description: 'Custom',
            });

            this.defaultFiles
                .filter((df) => df.typeId === ft.id)
                .forEach((df) => {
                    this.fileData.push({
                        first: false,
                        type: ft,
                        defaultFile: df,
                        description: df.description || 'NO_DESCRIPTION',
                        text: df.name,
                    });
                });

            this.filetypes.push({
                type: ft,
                defaultFiles: this.defaultFiles.filter((x) => x.typeId === ft.id),
            });
        });
    }

    getStatusAndIcon(isExpired: boolean, isSigned: boolean, isUnsigned: boolean, isUploaded: boolean): [ EmployeeFileStatus, string ] {
        let status: EmployeeFileStatus = 'MISSING';
        let icon = 'close';

        if (isExpired) {
            status = 'EXPIRED';
            icon = 'history';
        } else if (isSigned) {
            status = 'SIGNED';
            icon = 'check';
        } else if (isUnsigned) {
            status = 'UNSIGNED';
            icon = 'verified_user';
        } else if (isUploaded) {
            status = 'UPLOADED';
            icon = 'check';
        }

        return [ status, icon ];
    }

    setEmployees(employees: HrOverviewEmployee[], requestedFiles?: FileRequestResponse) {
        this.employees = employees.map((employee) => {
            const files: HrEmployeeFileData[] = this.fileData.map((fileData) => {
                employee.hrFiles.forEach((file) => {
                    if (!this.defaultFiles.find((df) => df.id === file.defaultFileId)) {
                        file.defaultFileId = undefined;
                    }
                });

                const isSignable = !!this.signables.find((signable) => signable.filter.type_id === fileData.type.id);
                const empFile = employee.hrFiles.find((f) => f.typeId === fileData.type.id && f.defaultFileId === fileData.defaultFile?.id);

                const isUploaded = !!empFile;
                const isSigned = empFile?.isSigned || false;
                const isUnsigned = isUploaded && isSignable && !isSigned;
                const isExpired = empFile?.expiresAt != null && DateTime.now() >= empFile?.expiresAt;

                const [ status, icon ] = this.getStatusAndIcon(isExpired, isSigned, isUnsigned, isUploaded);
                const optional = !fileData.type.mandatory || (!!employee.hrFiles.find((file) => file.typeId === fileData.type.id) && status === 'MISSING');
                const existingData = this.getEmployeeFileData(employee.id, fileData.type.id);
                const processing = (existingData?.processing && !existingData?.completed) || false;

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const requested = !!(fileData.type && employee?.id && requestedFiles && requestedFiles[employee.id] && requestedFiles[employee.id]![fileData.type.id] && !fileData.defaultFile);

                return {
                    fileData,
                    empFile,
                    status,
                    optional,
                    icon,
                    processing,
                    requested,
                    completed: false,
                };
            });

            return {
                employee,
                files,
            };
        });

        this.employees = sort(this.employees, this.current.languageTag, [ (e) => e.employee.name ]);
    }

    getOverview() {
        this.gettingOverview = true;

        return this.hrOverviewService.get(this.customer.id, {
            all_types: this.allFiletypes,
            include_inactive: this.includeInactive,
        }).pipe(
            tap((hrOverview) => {
                void this.setFileData(hrOverview);
                this.setEmployees(hrOverview.employees, hrOverview.requestedFiles);
                this.listenCreatedChannel(hrOverview.employees);
                this.calculateStats();

                this.gettingOverview = false;
            }),
        );
    }

    createCsv() {
        const headers = [ 'Employee', ...(this.employees[0]?.files.map((f) => f.fileData.type.name) || []) ];
        const rows: string[][] = [];

        this.employees.forEach((e) => {
            const files = this.filetypes.reduce((arr: string[], file) => {
                return arr.concat(
                    e.files.find((f) => f.fileData.type.id === file.type.id)?.status || '',
                );
            }, []);

            rows.push([ e.employee.name, ...files ]);
        });

        csvCreator(headers, rows, true, `hr_files_location_${this.customer.id}_${+new Date()}`);
    }

    calculateStats() {
        let total = 0;
        let notUploaded = 0;
        let expired = 0;
        let unsigned = 0;
        let uploadedOrSigned = 0;

        this.filetypes.forEach((type) => {
            if (!type.type.mandatory) {
                return;
            }

            this.employees.forEach((employee) => {
                const files = employee.files.filter((f) => f.fileData.type.id === type.type.id);
                if (files.find((file) => !file.optional && file.status === 'MISSING')) {
                    total += 1;
                    notUploaded += 1;
                } else {
                    files.forEach((file) => {
                        if (!file.optional) {
                            total += 1;
                        }
                        expired += +(file.status === 'EXPIRED');
                        unsigned += +(file.status === 'UNSIGNED');
                        uploadedOrSigned += +(file.status === 'UPLOADED' || file.status === 'SIGNED');
                    });
                }
            });
        });

        this.stats = {
            expired: expired / total,
            unsigned: unsigned / total,
            uploadedOrSigned: uploadedOrSigned / total,
            notUploaded: notUploaded / total,
        };
    }

    private processAllEmployees(fileData: HrFileData) {
        const employees = this.selectableMode ? this.selectedEmployees : this.employees;
        employees.forEach((employee) => {
            const file = employee.files.find((f) => f.fileData.defaultFile ? (f.fileData.defaultFile?.id === fileData.defaultFile?.id) :(f.fileData.type === fileData.type));
            if (file) {
                file.processing = true;
            }
        });
    }

    createMany(fileData: HrFileData) {
        const employeeIds = this.selectableMode ? this.selectedEmployees.map((e) => e.employee.id) : this.employees.map((e) => e.employee.id);
        if (fileData.defaultFile) {
            this.processAllEmployees(fileData);

            this.hrFileService.createMany(this.customer.id, {
                employee_ids: employeeIds,
                default_file_id: fileData.defaultFile.id,
            }).subscribe();
        } else {
            this.uploadHrFileDialog.open({
                type: fileData.type,
                customerId: this.customer.id,
                employees: employeeIds,
            }).afterClosed().subscribe((result) => {
                if (!result) {
                    return;
                }

                this.processAllEmployees(fileData);
            });
        }
    }

    requestMany(fileData: HrFileData) {
        const employeeIds = this.selectableMode ? this.selectedEmployees.map((e) => e.employee.id) : this.employees.map((e) => e.employee.id);
        this.requestHrFileDialog.open({
            type: fileData.type,
            customerId: this.customer.id,
            employees: employeeIds,
            multiSelect: this.selectableMode && employeeIds.length > 1,
        }).afterClosed().subscribe();
    }

    selectEmployee(employee: HrEmployee, event: boolean) {
        if (event) {
            this.selectedEmployees.push(employee);
        } else {
            this.selectedEmployees = this.selectedEmployees.filter((hrEmployee) => employee.employee.id !== hrEmployee.employee.id);
        }
    }

    private reloadPage() {
        setTimeout(() => window.location.reload(), 5000);
    }

    handleFile(employee: HrEmployee, file: HrEmployeeFileData, action: 'upload' | 'delete' | 'download' | 'request') {
        if (file.processing) {
            return;
        }
        file.processing = true;

        if (action === 'upload' && !file.fileData.defaultFile) {
            file.processing = false;
            this.uploadHrFileDialog.open({
                type: file.fileData.type,
                customerId: this.customer.id,
                employees: [ employee.employee.id ],
            }).afterClosed().subscribe((result) => {
                file.processing = this.documentServiceEnabled ? false : !!result;
                if (result === 'Reload') {
                    this.reloadPage();
                }
            });
        }

        if (action === 'upload' && file.fileData.defaultFile) {
            this.hrFileService.createDefaultFile(employee.employee.customerId, employee.employee.id, {
                default_file_id: file.fileData.defaultFile.id,
            }).subscribe();
        }

        if (action === 'delete' && file.empFile) {
            this.confirmDialog.delete({
                title: this.translate.t('DELETE_FILE', 'hr'),
                text: this.translate.t('DELETE_UPLOADED_FILE_TEXT', 'hr'),
                confirmText: this.translate.t('DELETE_FILE', 'hr', { name: file.empFile.name }),
            }).afterClosed().subscribe((result) => {
                if (!result?.ok) {
                    file.processing = false;
                    return;
                }
                if (file.empFile?.id) {
                    this.hrFileService.delete(employee.employee.customerId, employee.employee.id, file.empFile.id).subscribe(() => {
                        this.setCompletedFlag(employee.employee.id, file.fileData.type.id);
                        this.overviewSubject.next();
                    });
                }
            });
        }

        if (action == 'download' && file.empFile) {
            this.hrFileService.download(employee.employee.customerId, employee.employee.id, file.empFile.id, file.empFile.name).subscribe(() => {
                file.processing = false;
            });
        }

        if (action == 'request') {
            this.requestHrFileDialog.open({
                type: file.fileData.type,
                customerId: this.customer.id,
                employees: [ employee.employee.id ],
                multiSelect: false,
            }).afterClosed().subscribe(() => {
                file.processing = false;
                file.requested = true;
            });
        }
    }

    goToEmployee(employeeId: number) {
        this.uiRouter.stateService.go(`eaw/app/hr/emp_files`, { emp: employeeId });
    };

    openFileDescription(data: HrFileData) {
        this.matDialog.open(AlertDialogComponent, {
            data: {
                title: signal(Promise.resolve(data.text)),
                text: signal(this.translate.t(data.description)),
            },
        });
    }

    uploadPayslip() {
        this.matDialog.open(ParsePayslipComponent, {
            data: {
                customerId: this.customer.id,
            },
        });
    }

    toggleSelectableMode(event: MatSlideToggleChange) {
        this.selectableMode = event.checked;
    }
}
