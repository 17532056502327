import { module } from 'angular';
import { MatDialog } from '@angular/material/dialog';
import { WeatherWidgetSettingsDialogComponent } from '../../../../../dashboard-new/components/mini-weather-widget/weather-widget-settings-dialog/weather-widget-settings-dialog.component';
import { lastValueFrom, map } from 'rxjs';

// module('eaw.dashboard').directive('weatherSettingsDowngrade', downgradeComponent({ component: WeatherWidgetSettingsDialogComponent }));

module('eaw.dashboard').factory('weatherSettings', [ 'MatDialogDowngrade', function(matDialog: MatDialog) {
    return function weatherSettings(widget: any, settings: any) {
        const translation: string[] = widget.i18name.split(':');
        const translationKey = translation.pop();
        const translationNs = translation.pop() || 'general';
        const obs = matDialog.open(WeatherWidgetSettingsDialogComponent, {
            data: {
                widgetInfo: {
                    translationKey,
                    translationNs,
                },
            },
        }).afterClosed()
            .pipe(map((result: { useFahrenheit: boolean }) => {
                return {
                    ...result,
                    ...settings,
                };
            }));

        return lastValueFrom(obs);
    };
} ]);
