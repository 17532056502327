import { Component, HostBinding, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Employee } from '../../../shared/models/employee';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { of } from 'rxjs';
import { OffTimeService } from '../../http/off-time.service';
import { Customer } from '../../../shared/models/customer';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { OffTime } from '../../models/off-time';
import { DataTableApprovalColumn } from '../../../data-table/types/data-table-approval-column';
import { DataTableButton, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { CreateOfftimeRequestDialogService } from '../../dialogs/create-offtime-request-dialog/create-offtime-request-dialog.service';
import { SettingService } from '../../../shared/http/setting.service';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { TranslateSyncPipe } from '../../../shared/pipes/translate-sync.pipe';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent, CommentDialogData } from '../../../shared/dialogs/comments-dialog/comment-dialog.component';

@Component({
    selector: 'eaw-employee-time-off',
    templateUrl: './employee-time-off.component.html',
    styleUrl: './employee-time-off.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        MatSelectModule,
        MatOptionModule,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        TranslateSyncPipe,
    ],
})
export class EmployeeTimeOffComponent implements EawDataTable<OffTime>, OnInit {
    @HostBinding('class') classes = 'flex-contained';
    @ViewChild('dataTableComponent') dataTable?: DataTableComponent<OffTime>;
    @Input() employee!: Employee;
    @Input() customer!: Customer;

    filterGroup = new FormGroup({
        status: new FormControl<0 | 'all'>('all'),
        dateRange: new FormGroup({
            from: new FormControl<DateTime | null>(null),
            to: new FormControl<DateTime | null>(null),
        }),
    });

    fabButton: HeaderFabButton = {
        click: () => {
            this.createOfftimeRequestDialogService.open(this.employee.customerId, this.employee.id).afterClosed().subscribe((result) => {
                if (result == null) {
                    return;
                }
                this.updateTable(this.dataTable?.getPagination({ page: 1 }));
            });
        },
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.employee.customerId}.employees.${this.employee.id}.off_times.create`),
    };

    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns!: DataTableColumnType<OffTime>[];

    constructor(
        @Inject(OffTimeService) private offTimeService: OffTimeService,
        @Inject(ConfirmDialogService) private confirmDialog: ConfirmDialogService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(SettingService) private settingService: SettingService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(CreateOfftimeRequestDialogService) private createOfftimeRequestDialogService: CreateOfftimeRequestDialogService,
    ) {
    }

    ngOnInit() {
        const deleteButton: DataTableButton<OffTime> = {
            icon: 'delete',
            click: this.deleteOfftimeRequest.bind(this),
            type: 'warn',
            show: (item: OffTime) => {
                if (!this.employee.customerId) {
                    return of(false);
                }

                return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].employees.${item.employeeId}.off_times.[${ApiModel.OffTime}].delete`, {
                    stackId: this.customer.stackId,
                    models: [
                        { type: ApiModel.Customer, id: this.employee.customerId },
                        { type: ApiModel.Employee, id: item.employeeId },
                        { type: ApiModel.OffTime, id: item.id },
                    ],
                });
            },
            tooltip: {
                key: 'DELETE_REQUEST',
                ns: 'vacation',
            },
        };

        this.settingService.getValue([ 'customers', this.employee.customerId || 0 ], 'absences.allow_employee_to_delete_approved', 0).subscribe((setting: number) => {
            if (setting == 0) {
                deleteButton.hide = (item: OffTime) => of(!!item.approval);
            }
        });

        this.columns = [
            new DataTableDateTimeColumn({
                value: 'from',
                header: new DataTableHeader({
                    i18n: 'FROM',
                    sortBy: 'from',
                }),
                format: DateTime.DATETIME_MED_WITH_WEEKDAY,
            }),
            new DataTableDateTimeColumn({
                value: 'to',
                header: new DataTableHeader({
                    i18n: 'TO',
                    sortBy: 'to',
                }),
                format: DateTime.DATETIME_MED_WITH_WEEKDAY,
            }),
            new DataTableTextColumn({
                value: (cell) => {
                    if (cell.item.approval) {
                        return cell.item.vacation ? this.translate.t('VACATION') : this.translate.t('TIME_OFF', 'vacation');
                    }

                    return '';
                },
                header: new DataTableHeader({ i18n: 'TYPE' }),
            }),
            new DataTableDateTimeColumn({
                value: 'createdAt',
                header: new DataTableHeader({
                    i18n: 'SENT',
                    sortBy: 'created_at',
                }),
                format: DateTime.DATETIME_MED,
            }),
            new DataTableApprovalColumn({
                header: new DataTableHeader({ i18n: 'APPROVED' }),
                approval: (cell) => cell.item.approval,
            }),
            new DataTableButtonColumn<OffTime>({
                buttons: [
                    {
                        icon: 'comment',
                        click: this.showComments.bind(this),
                        hide: (item) => of(!item.comments?.length),
                        show: () => of(true),
                        nonBlocking: true,
                        tooltip: { key: 'COMMENT_plural' },
                    },
                    deleteButton,
                ],
            }),
        ];
    }

    showComments(cell: DataTableCell<DataTableButtonColumn<OffTime>, OffTime>) {
        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                comments: of(cell.item.comments || []),
            },
        });
    }

    deleteOfftimeRequest(cell: DataTableCell<DataTableButtonColumn<OffTime>, OffTime>) {
        const customerId = this.employee.customerId;
        if (!customerId) {
            return;
        }

        this.confirmDialog.delete({
            title: this.translate.t('DELETE_REQUEST', 'vacation'),
            text: this.translate.t('DELETE_REQUEST_TEXT', 'general', {
                from: cell.item.from.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY),
                to: cell.item.to.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY),
            }),
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.offTimeService.delete(customerId, cell.item.employeeId, cell.item.id).subscribe(() => {
                this.updateTable(this.dataTable?.getPagination({ page: 1 }));
            });
        });
    }

    updateTable(pagination: Partial<DataTablePagination> = {}): void {
        const customerId = this.employee.customerId;
        const employeeId = this.employee.id;
        if (!customerId || !employeeId) {
            return;
        }

        this.request = this.offTimeService.getAll(customerId, employeeId, {
            'with[]': [ 'approval', 'comments' ],
            order_by: 'created_at',
            direction: 'desc',
            from: this.filterGroup.value.dateRange?.from || undefined,
            to: this.filterGroup.value.dateRange?.to || undefined,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            handled: this.filterGroup.value.status === 'all' ? undefined : this.filterGroup.value.status!,
            ...pagination,
        });
    }
}
