import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MiniWidgetContentComponent } from '../mini-widget-content/mini-widget-content.component';
import { WidgetComponent } from '../../classes/widget-component';
import { TranslateService } from '../../../shared/services/translate.service';
import { MessageService } from '../../../messages/http/message.service';
import { map, tap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';

@Component({
    selector: 'eaw-mini-messages-widget',
    standalone: true,
    imports: [ MiniWidgetContentComponent ],
    templateUrl: './mini-messages-widget.component.html',
    styleUrl: './mini-messages-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniMessagesWidgetComponent extends WidgetComponent {
    private translateService = inject(TranslateService);
    private messageService = inject(MessageService);
    private numberFormatterService = inject(NumberFormatterService);

    unreadMessages = toSignal(this.messageService.getMessages({ read: false, per_page: 1 })
        .pipe(
            tap(() => this.setLoading(false)),
            map((resp) => resp.total || 0)));

    text = computed(() => this.numberFormatterService.formatInteger(this.unreadMessages()));
    subtext = computed(() => this.translateService.t('UNREAD_MESSAGE', 'widgets', { count: this.unreadMessages() }));
}
