import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DialogComponent } from '../../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CreateScheduleDialogData } from '../create-schedule-dialog.service';
import { ScheduleTemplate } from '../../../models/schedule-template';
import { ScheduleService } from '../../../http/schedule.service';
import { DateTime } from 'luxon';
import { switchMap } from 'rxjs';
import { SettingService } from '../../../../shared/http/setting.service';
import { ScheduleTemplateService } from '../../../http/schedule-template.service';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { InfoBoxComponent } from '../../../../shared/components/info-card/info-box.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerOptionsDirective } from '../../../../shared/directives/date-picker-options.directive';
import { BasicAutocompleteComponent } from '../../../../shared/components/basic-autocomplete/basic-autocomplete.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DialogHeaderComponent } from '../../../../shared/dialogs/dialog-header/dialog-header.component';

type EmptyForm = { name: FormControl<string>, dateRange: FormGroup<{ from: FormControl<DateTime | undefined>, to: FormControl<DateTime | undefined> }> };
type TemplateForm = { name: FormControl<string>, template: FormControl<ScheduleTemplate | undefined>, date: FormControl<DateTime | undefined> };

@Component({
    selector: 'eaw-default-schedule-create',
    templateUrl: './default-schedule-create.component.html',
    styleUrl: './default-schedule-create.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        BasicAutocompleteComponent,
        DatePickerOptionsDirective,
        MatDatepickerModule,
        InfoBoxComponent,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class DefaultScheduleCreateComponent extends DialogComponent {
    createEmpty = new FormControl(false, { nonNullable: true });
    nameControl = new FormControl('', {
        validators: [ Validators.required ],
        nonNullable: true,
    });

    templateForm: FormGroup<TemplateForm>;
    emptyForm: FormGroup<EmptyForm>;
    activeForm: FormGroup<EmptyForm> | FormGroup<TemplateForm>;

    getTemplates = (filter?: string) => {
        return this.scheduleTemplateService.getAll(this.data.customerId, {
            page: 1,
            filter,
            per_page: 10,
        });
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: CreateScheduleDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<DefaultScheduleCreateComponent, ScheduleTemplate>,
        @Inject(ScheduleService) public scheduleService: ScheduleService,
        @Inject(SettingService) public settingService: SettingService,
        @Inject(ScheduleTemplateService) public scheduleTemplateService: ScheduleTemplateService,
        @Inject(ChangeDetectorRef) protected change: ChangeDetectorRef,
    ) {
        super(dialogRef, data);

        this.emptyForm = new FormGroup({
            name: this.nameControl,
            dateRange: new FormGroup({
                from: new FormControl<DateTime | undefined>(undefined, {
                    validators: [ Validators.required ],
                    nonNullable: true,
                }),
                to: new FormControl<DateTime | undefined>(undefined, {
                    validators: [ Validators.required ],
                    nonNullable: true,
                }),
            }),
        });

        this.templateForm = new FormGroup({
            name: this.nameControl,
            template: new FormControl<ScheduleTemplate | undefined>(undefined, {
                validators: [ Validators.required ],
                nonNullable: true,
            }),
            date: new FormControl<DateTime | undefined>(undefined, {
                validators: [ Validators.required ],
                nonNullable: true,
            }),
        });

        this.templateForm.valueChanges.subscribe(() => {
            if (this.useTemplate) {
                this.change.markForCheck();
            }
        });

        this.emptyForm.valueChanges.subscribe(() => {
            if (!this.useTemplate) {
                this.change.markForCheck();
            }
        });

        this.activeForm = this.templateForm;

        this.createEmpty.valueChanges.subscribe((useEmpty) => {
            this.activeForm = useEmpty ? this.emptyForm : this.templateForm;
            this.change.markForCheck();
        });
    }

    get dstTransition(): boolean {
        let start: DateTime | undefined;
        let end: DateTime | undefined;

        if (this.useTemplate) {
            start = this.templateForm.value.date;
            end = start?.plus({
                seconds: this.templateForm.value.template?.length,
            });
        } else {
            start = this.emptyForm.value.dateRange?.from;
            end = this.emptyForm.value.dateRange?.to?.plus({ day: 1 });
        }

        return Boolean(start && end && start.isInDST !== end.isInDST);
    }

    get useTemplate() {
        return !this.createEmpty.value as boolean;
    }

    create() {
        let observable;

        this.toggleForm(false);

        if (this.useTemplate) {
            const template = this.templateForm.controls.template.value;
            const date = this.templateForm.controls.date.value;

            if (template == null || date == null) {
                this.toggleForm();
                return;
            }

            observable = this.scheduleService.createDefault(this.data.customerId, {
                name: this.nameControl.value,
                from: date.set({
                    hour: template.from.hour,
                    minute: template.from.minute,
                }),
                template_id: template.id,
            });
        } else {
            const dateRangeFrom = this.emptyForm.controls.dateRange.controls.from.value;
            const dateRangeTo = this.emptyForm.controls.dateRange.controls.to.value;

            if (dateRangeFrom == null || dateRangeTo == null) {
                this.toggleForm();
                return;
            }

            observable = this.settingService.getValue([ 'customers', this.data.customerId ], 'schedule:hours_offset', 0)
                .pipe(
                    switchMap((offset) => {
                        const fromWithOffset = dateRangeFrom.set({ hour: offset });
                        const toWithOffset = dateRangeTo.plus({ day: 1 }).set({ hour: offset });
                        return this.scheduleService.createDefault(this.data.customerId, {
                            name: this.nameControl.value as string,
                            from: fromWithOffset,
                            length: toWithOffset.diff(fromWithOffset).as('seconds'),
                        });
                    }),
                );
        }

        observable.subscribe({
            next: (result) => {
                this.dialogRef.close(result);
            },
            error: (err) => {
                console.error(err);
                this.toggleForm();
            },
        });
    }

    toggleForm(on = true) {
        if (on) {
            this.activeForm.enable();
        } else {
            this.activeForm.disable();
        }

        this.change.markForCheck();
    }
}
