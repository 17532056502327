import { ApiResponse } from '../../shared/interfaces/api-response';

export interface PlusHoursMonitoringMonth extends ApiResponse {
    baseHours: number;
    addendumHours: number;
    extraHoursOverSetting: number;
    paidTime: number;
}

export interface PlusHoursMonitoringResponse extends ApiResponse {
    employeeId: number;
    badgeNumber: number;
    firstName: string;
    lastName: string;
    grade: string;
    level: number;
    extraHoursValue: number;
    totalPaidTime: number;
    months: PlusHoursMonitoringMonth[];
}

export class PlusHoursMonitoring {
    employeeId: number;
    badgeNumber: number;
    firstName: string;
    lastName: string;
    grade: string;
    level: number;
    extraHoursValue: number;
    totalPaidTime: number;
    months: PlusHoursMonitoringMonth[];

    constructor(data: PlusHoursMonitoringResponse) {
        this.employeeId = data.employeeId;
        this.badgeNumber = data.badgeNumber;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.grade = data.grade;
        this.level = data.level;
        this.extraHoursValue = data.extraHoursValue;
        this.totalPaidTime = data.totalPaidTime;
        this.months = data.months;
    }
}
