import { ApplicationRef, Component, createComponent, DestroyRef, ElementRef, inject, Input, OnInit } from '@angular/core';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { ComponentType } from '@angular/cdk/overlay';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { StateProviderData, StateProviderDataData, StateProviderDataDataPermissionCheck } from '../../utils/create-state';
import { Resolvable } from '../../types/resolvable';
import { forkJoin, map, Observable, of, shareReplay, tap } from 'rxjs';
import { CurrentService } from '../../services/current.service';
import { CustomerProductService } from '../../http/customer-product.service';
import { SettingService } from '../../http/setting.service';
import { routeDataSettingsChecker } from '../../utils/route-data-settings-checker';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { UIRouterModule } from '@uirouter/angular';
import { MatRippleModule } from '@angular/material/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { ArrayPaginatedResponse } from '../../interfaces/paginated-response';
import { Product } from '../../models/product';
import { ElementPermissionService, PermissionsInputValue } from '../../../permissions/services/element-permission.service';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';
import { UIRouter } from '@uirouter/core';
import { PermissionOptions } from '../../services/permission-check.service';
import { TranslateService } from '../../services/translate.service';

export type SidenavHeaderInputs = { key: string, value: any }[];

export interface SidenavTab {
    // The url of the tab, has to start with a "/"
    url: string;
    state: string;
    icon?: string;
    component: ComponentType<any>;
    data: StateProviderDataData;
    resolve?: Resolvable[];
    // This will override the checks done in the data property if set
    show?: Observable<boolean>;
    params?: StateProviderData['params']
    queryParams?: string[];
}

interface InternalTab {
    info: SidenavTab,
    label: Promise<string>,
    somePermissions?: PermissionsInputValue,
    permissions?: PermissionsInputValue,
    permissionChildrenFilter?: [ string, string, string ],
    available: Observable<boolean>,
    permissionCheck?: StateProviderDataDataPermissionCheck,
}

@Component({
    selector: 'eaw-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrl: './sidenav.component.scss',
    standalone: true,
    imports: [
        MatSidenavModule,
        NgFor,
        NgIf,
        MatRippleModule,
        UIRouterModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
        PermissionDirective,
    ],
})
export class SidenavComponent implements OnInit {
    private readonly currentService = inject(CurrentService);
    private readonly customerProductService = inject(CustomerProductService);
    private readonly settingService = inject(SettingService);
    private readonly destroyRef = inject(DestroyRef);
    private readonly applicationRef = inject(ApplicationRef);
    private readonly elementRef = inject(ElementRef);
    private readonly breakpointObserver = inject(BreakpointObserver);
    private readonly uiRouter = inject(UIRouter);
    private readonly translate = inject(TranslateService);
    private readonly elementPermissionService = inject(ElementPermissionService);

    @Input({ required: true }) tabs!: SidenavTab[];
    @Input() employeeId?: number;
    @Input({ required: true }) customerId!: number;
    @Input() hideGoIcon = false;
    @Input() headerComponent?: ComponentType<unknown>;
    @Input() headerInputs?: SidenavHeaderInputs;

    protected readonly isTesting = environment.isTesting;
    protected _tabs: InternalTab[] = [];
    protected allProducts?: Observable<ArrayPaginatedResponse<Product>>;
    protected drawerMode: MatDrawerMode = 'side';
    protected drawerOpened = false;
    protected checkedTabs = 0;

    constructor() {
        this.breakpointObserver.observe([
            Breakpoints.Large,
            Breakpoints.XLarge,
        ]).subscribe((result) => {
            this.drawerMode = result.matches ? 'side' : 'over';
            this.drawerOpened = result.matches;
        });
    }

    ngOnInit(): void {
        this.addHeaderComponent();

        this._tabs = this.tabs.map((t) => {
            return {
                info: t,
                label: t.data.breadcrumb && typeof t.data.breadcrumb !== 'function' ? this.translate.t(t.data.breadcrumb.key, t.data.breadcrumb.ns) : Promise.resolve(''),
                checked: false,
                available: this.canShowTab(t),
                permissionChildrenFilter: this.elementPermissionService.getPermissionFilterFromState(this.customerId, t.state),
                permissions: t.data.permissions?.map(this.mapPermissions.bind(this)),
                somePermissions: t.data.somePermissions?.map(this.mapPermissions.bind(this)),
                permissionChildrenInclude: t.data.permissionCheck,
            };
        });
    }

    private mapPermissions(p: string): [string, PermissionOptions] {
        return [ p, { replace: this.uiRouter.globals.params } ];
    }

    protected setChecked() {
        this.checkedTabs++;
    }

    protected canShowTab(tab: SidenavTab): Observable<boolean> {
        if (tab.show) {
            return tab.show.pipe(tap(() => this.setChecked()));
        }

        // Check if we have everything that is required to show this tab
        return forkJoin([
            this.hasRequiredEmployee(tab.data.requiresEmployee),
            this.hasProducts(tab.data.products),
            routeDataSettingsChecker(this.settingService, this.customerId, tab.data.settings),
        ]).pipe(
            map((result) => result.every((ok) => ok)),
            tap(() => this.setChecked()),
        );
    }

    private hasProducts(products: StateProviderDataData['products']): Observable<boolean> {
        if (!products?.length) {
            return of(true);
        }

        if (this.customerId === this.currentService.getCustomer().id) {
            return this.customerProductService.hasProducts(this.customerId, products || []);
        }

        this.allProducts ||= this.customerProductService.getAll(this.customerId, { per_page: 200 }).pipe(shareReplay(1));
        return this.allProducts.pipe(
            map((response) => products.every((product) => !!response.data.find((p) => p.name.toLowerCase() === product.toLowerCase()))),
        );
    }

    private hasRequiredEmployee(requiresEmployee: StateProviderDataData['requiresEmployee']): Observable<boolean> {
        return requiresEmployee ? of(!!this.currentService.getEmployee()) : of(true);
    }

    private addHeaderComponent() {
        if (this.headerComponent) {
            const headerEl = this.elementRef.nativeElement.querySelector('#header') as HTMLDivElement | undefined;

            if (headerEl) {
                const headerRef = createComponent(this.headerComponent, {
                    environmentInjector: this.applicationRef.injector,
                    hostElement: headerEl,
                });

                this.headerInputs?.forEach((input) => {
                    headerRef.setInput(input.key, input.value);
                });

                this.applicationRef.attachView(headerRef.hostView);

                this.destroyRef.onDestroy(() => {
                    this.applicationRef.detachView(headerRef.hostView);
                    headerRef.destroy();
                });
            }
        }
    }
}
