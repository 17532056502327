<mat-form-field subscriptSizing="dynamic">
    <mat-label>{{ 'EMAIL' | translate | async }}</mat-label>
    <input type="email" matInput [formControl]="email" (keydown.enter)="check()">
    <mat-hint>{{ 'EMAIL_CHECK_HINT' | translate:'new_employee' | async }}</mat-hint>
    <mat-error><eaw-field-error [control]="email"/></mat-error>
</mat-form-field>

@if (!checkComplete) {
    <eaw-action-button [loading]="checking" [disabled]="email.invalid" (click)="check()">{{ 'CHECK_EXISTING_ACCESS' | translate:'new_employee' | async }}</eaw-action-button>
}

<eaw-new-employee-next-step [stepper]="stepper" [step]="step"/>

