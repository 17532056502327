// @ts-nocheck
import { t } from 'i18next';
import moment from 'moment-timezone';
import { module } from 'angular';
import { CurrentOld } from '../../../current.factory';
module('eaw.time').filter('eawDuration', function() {
    const locale = CurrentOld.user?.languageTag;
    const itemsData = {
        y: {
            func: 'asYears',
            unit: 'year',
        },
        M: {
            func: 'asMonths',
            unit: 'month',
        },
        w: {
            func: 'asWeeks',
            unit: 'week',
        },
        d: {
            func: 'asDays',
            unit: 'day',
        },
        h: {
            func: 'asHours',
            unit: 'hour',
        },
        m: {
            func: 'asMinutes',
            unit: 'minute',
        },
        s: {
            func: 'asSeconds',
            unit: 'second',
        },
    };
    function addPart(duration, result, item, display, force) {
        const rounding = [ 'floor', 'ceil' ][+(duration.asSeconds() < 0)];
        // Convert duration to given unit
        const unitDuration = duration[itemsData[item].func]();
        const itemDuration = Math[rounding](unitDuration);
        if (!force && !itemDuration) {
            return duration;
        }
        result.push(formatNumber(itemDuration, itemsData[item].unit, display));
        // Deduct what we have already shown
        const seconds = Math.abs(duration.asSeconds()) - Math.abs(moment.duration(itemDuration, item).asSeconds());
        return moment.duration(seconds, 's');
    }
    function fallbackAge(duration) {
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const values = [ t('X_YEAR', { count: years }) ];
        if (months) {
            values.push(t('X_MONTH', { count: months }));
        }
        if (days) {
            values.push(t('N_DAY', { count: days }));
        }
        return values.join(' ,');
    }
    function fallbackTime(input, showSeconds = false) {
        if (!Number.isFinite(+input)) {
            return '';
        }
        if (moment.isDuration(input)) {
            input = input / 1000;
        }
        const negative = input < 0; // Save negativity
        input = Math.round(Math.abs(input)); // Make input absolute
        const result = [];
        const hours = Math.floor(input / 3600);
        const minutes = Math.floor((input % 3600) / 60);
        const seconds = Math.floor(input - ((hours * 3600) + (minutes * 60)));
        const formattedHours = t('X_HOURS', { count: hours });
        const formattedMinutes = t('X_MINUTES', { count: minutes });
        const formattedSeconds = t('X_SECOND', { count: seconds });
        if (hours) { // Hours are always first, so never prepend 0
            result.push(formattedHours);
        }
        if (minutes) { // Minutes can have 0 prepended if hours are first and minutes are single digit
            result.push(formattedMinutes);
        }
        if (showSeconds || !(hours || minutes)) { // Show seconds if we have no hours or minutes, or if we say we want it
            if (seconds) { // But only proceed to add seconds if it's not 0
                result.push(formattedSeconds);
            }
        }
        // Return 0 hours if the total is 0
        // Or else nothing will be shown
        if (hours + minutes + seconds === 0) {
            return formattedHours;
        }
        // Join together all time ts
        const joined = result.join(' ');
        // Prepend minus if we were negative originally
        return negative ? `-${joined}` : joined;
    }
    function fallback(duration, items) {
        if (items === 'yMd') {
            return fallbackAge(duration);
        }
        if (items === 'hm' || items === 'hms') {
            return fallbackTime(duration);
        }
        return '';
    }
    function canUseStuff() {
        let ok = true;
        try {
            new Intl.NumberFormat(locale, {
                style: 'unit',
                unit: 'year',
                unitDisplay: 'short',
            }).format(1);
            // @ts-ignore
            new Intl.ListFormat(locale, {
                style: 'long',
                type: 'conjunction',
            }).format([ 'Bus', 'Car' ]);
        } catch (_) {
            const today = moment().format('L');
            const ex = localStorage.getItem('intl_catch');
            if (ex !== today) {
                localStorage.setItem('intl_catch', moment().format('L'));
            }
            ok = false;
            console.error(e);
        }
        return ok;
    }
    function formatNumber(duration, unit, display) {
        return new Intl.NumberFormat(locale, {
            style: 'unit',
            unit,
            unitDisplay: display,
        }).format(duration);
    }
    function formatList(result, listStyle) {
        return new Intl.ListFormat(locale, {
            style: listStyle,
            type: [ 'unit', 'conjunction' ][+(listStyle === 'long')],
        }).format(result);
    }
    function eawDurationFilter(input, display = 'narrow', items = 'hm', listStyle = 'long') {
        // If it's just a number then just use it as a duration
        // If it's a moment, then use now as relative to that moment in order to calculate diff
        let duration;
        if (input?.isValid?.()) {
            duration = moment.duration(moment().diff(input, 's'), 's');
        } else if (Number.isFinite(input)) {
            duration = moment.duration(input, 's');
        } else {
            return '';
        }
        // Check fallback
        if (!canUseStuff()) {
            return fallback(duration, items);
        }
        // Start "normal" function
        const result = [];
        // If no seconds then force showing 0 of the last item in items string
        const force = !input;
        if (force) {
            items = items.slice(-1);
        }
        Object.keys(itemsData).forEach((item) => {
            if (items.includes(item)) {
                duration = addPart(duration, result, item, display, force);
            }
        });
        // If passed in seconds is lower than the last item sent in, then force 0 of the last item
        if (!result.length && items.length > 1) {
            return eawDurationFilter(0, display, items.slice(-1), listStyle);
        }
        return formatList(result, listStyle);
    }
    return eawDurationFilter;
});
