import { ProductName } from '../enums/products';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../utils/string-to-date-time';
import { ApiResponse } from '../interfaces/api-response';
import { BaseApiModel } from './base-api-model';

export interface ProductResponse extends ApiResponse {
    name: ProductName;
    created_at?: string | null;
    deleted_at?: string | null;
    updated_at?: string | null;
    pivot?: {
        product_name: ProductName;
        subscriber_id: number;
        subscriber_type: 'customer' | 'group';
    },
    properties?: Record<string, any>;
}

export class Product extends BaseApiModel<ProductResponse, Product> {
    name: ProductName;
    createdAt?: DateTime | null;
    deletedAt?: DateTime | null;
    updatedAt?: DateTime | null;
    subscriberId?: number;
    subscriberType?: 'customer' | 'group';
    properties: Record<string, string | number | boolean>;
    constructor(data: ProductResponse) {
        super(data, undefined);
        this.name = data.name;
        this.subscriberId = data.pivot?.subscriber_id;
        this.subscriberType = data.pivot?.subscriber_type;
        this.properties = data.properties || {};
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }
}
