import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AggregateRow } from '../../../types/aggregate-row';
import { ColumnHeader } from '../../../types/column-header';
import { catchError, EMPTY, map, of, switchMap } from 'rxjs';
import { WarningsDialogService } from '../../../../shared/dialogs/warnings-dialog/warnings-dialog.service';
import type { ManageTimepunchesFilter } from '../manage-timepunches.component';
import { CurrentService } from '../../../../shared/services/current.service';
import { TimepunchHistoryDialogService } from '../../../dialogs/timepunch-history-dialog/timepunch-history-dialog.service';
import { Timepunch } from '../../../models/timepunch';
import { ManageTimepunchDialogService } from '../../../dialogs/manage-timepunch-dialog/manage-timepunch-dialog.service';
import { ConfirmDialogService } from '../../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TimepunchService } from '../../../http/timepunch.service';
import { InfractionService } from '../../../../shared/http/infraction.service';
import { SettingService } from '../../../../shared/http/setting.service';
import { Setting } from '../../../../shared/models/setting';
import { sort } from '../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { Sort, MatSortModule } from '@angular/material/sort';
import { PermissionCheckService } from '../../../../shared/services/permission-check.service';
import { DurationPipe } from '../../../../shared/pipes/duration.pipe';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconSizeDirective } from '../../../../shared/directives/mat-icon-size.directive';
import { MatButtonModule } from '@angular/material/button';
import { CellTotalComponent } from '../cell-total/cell-total.component';
import { CellValueComponent } from '../cell-value/cell-value.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CheckboxHelperDirective } from '../../../../shared/directives/checkbox-helper.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent, CommentDialogData } from '../../../../shared/dialogs/comments-dialog/comment-dialog.component';

@Component({
    selector: 'eaw-timepunch-table',
    templateUrl: './timepunch-table.component.html',
    styleUrl: './timepunch-table.component.scss',
    standalone: true,
    imports: [
        MatTableModule,
        MatSortModule,
        NgIf,
        MatCheckboxModule,
        CheckboxHelperDirective,
        ReactiveFormsModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatIconModule,
        NgFor,
        CellValueComponent,
        CellTotalComponent,
        MatButtonModule,
        MatIconSizeDirective,
        MatTooltipModule,
        AsyncPipe,
        TranslatePipe,
        DurationPipe,
    ],
})
export class TimepunchTableComponent implements OnInit, OnChanges {
    @Input() rows: AggregateRow[] = [];
    @Input() columnsKeys: string[] = [];
    @Input() balances: string[] = [];
    @Input() customerId!: number;
    @Input() columnsData: ColumnHeader[] = [];
    @Input() balanceData: ColumnHeader[] = [];
    @Input() totals: Record<string, number> = {};
    @Input() filter?: ManageTimepunchesFilter | null;

    @Output() timepunchUpdated = new EventEmitter<number>();
    @Output() refreshRows = new EventEmitter();
    @Output() rowClick = new EventEmitter<AggregateRow>();
    @Output() adjustColumnsClick = new EventEmitter();

    settings: Setting[] = [];
    isAggregatedView = false;
    masterCheckboxSelected = false;
    sorting: {key: string, direction: 'asc' | 'desc'} = {
        key: '',
        direction: 'asc',
    };

    constructor(
        @Inject(WarningsDialogService) private warningsDialog: WarningsDialogService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(InfractionService) private infractionService: InfractionService,
        @Inject(TimepunchHistoryDialogService) private timepunchHistoryDialog: TimepunchHistoryDialogService,
        @Inject(ManageTimepunchDialogService) private manageTimepunchDialogService: ManageTimepunchDialogService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(TimepunchService) private timepunchService: TimepunchService,
        @Inject(SettingService) private settingService: SettingService,
    ) {
    }

    ngOnInit() {
        this.settingService.getSome([ 'customers', this.customerId ], { 'settings[]': [ 'payroll.tp_shift_diff_margin', 'payroll.require_comment_on_timepunch_delete' ] }).pipe(
            catchError(() => of([])),
        ).subscribe((settings) => {
            this.settings = settings;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const filter = changes['filter']?.currentValue as ManageTimepunchesFilter | undefined;
        if (filter) {
            this.isAggregatedView = filter?.grouping !== 'none';
        }
    }

    trackBy(_: number, item: AggregateRow) {
        return item.id;
    }

    get hasApprovable() {
        return this.rows.some((r) => r.data.approvable);
    }

    showHistory(employeeId: number, timepunchId: number): void {
        this.timepunchHistoryDialog.open(this.customerId, employeeId, timepunchId);
    }

    editTimepunch(employeeId: number, id: number): void {
        const obs = this.timepunchService.getForEmployee(this.customerId, employeeId, id, { with: [ 'employee' ] });
        this.manageTimepunchDialogService.update(this.customerId, obs).afterClosed().subscribe((result) => {
            if (result instanceof Timepunch) {
                this.timepunchUpdated.emit(result.id);
            }
        });
    }

    deleteTimepunch(timepunchId: number) {
        const deleteCommentRequired = this.settings.find((s) => s.key === 'payroll.require_comment_on_timepunch_delete')?.value?.asBoolean() ?? false;
        this.confirmDialogService.delete({ comment: { include: true, required: deleteCommentRequired } }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                return;
            }

            this.timepunchService.delete(this.customerId, timepunchId, result.comment)
                .pipe(
                    catchError((e) => {
                        console.error(e);
                        this.refreshRows.emit();
                        return EMPTY;
                    }),
                ).subscribe(() => {
                    this.refreshRows.emit();
                });
        });
    }

    parentCheckboxChecked(parentRow: AggregateRow) {
        const children = this.rows.filter((r) => r.parentId === parentRow.id);
        return children.length ? children.every((c) => c.checked) : parentRow.checked;
    }

    parentCheckboxIndeterminate(parentRow: AggregateRow) {
        const children = this.rows.filter((r) => r.parentId === parentRow.id);
        const someChecked = children.some((c) => c.checked);
        const everyChecked = children.every((c) => c.checked);
        return children.length ? someChecked && !everyChecked : false;
    }

    parentCheckboxChange(item: AggregateRow, checked: boolean) {
        item.checked = checked;
        this.rows.filter((r) => r.parentId === item.id).forEach((c) => (c.checked = checked));
    }

    masterCheckboxChange() {
        const checked = this.isAllChecked();

        if (this.isIndeterminate()) {
            this.rows.forEach((r) => (r.checked = true));
        } else {
            this.rows.forEach((r) => (r.checked = !checked));
        }
    }

    isAllChecked() {
        return this.rows.every((r) => r.checked);
    }

    isIndeterminate() {
        return this.rows.some((r) => r.checked) && !this.isAllChecked();
    }

    private getTimepunch(employeeId: number, timepunchId: number, rel: string[], changelog?: boolean) {
        return this.timepunchService.getForEmployee(this.customerId, employeeId, timepunchId, {
            with: rel,
            include_changelog: changelog,
        });
    }

    handleCellButtonClick(row: { row: AggregateRow, column: ColumnHeader }) {
        const employeeId = row.row.data.employeeId;
        const employeeName = row.row.data.values['employee_name']?.value;
        const timepunchId = row.row.data.ids[0];
        const from = row.row.data.from;
        const to = row.row.data.to;

        if (!(employeeId && from && to && timepunchId)) {
            return;
        }

        const warnings = this.getTimepunch(employeeId, timepunchId, [ 'warnings.causes.cause' ]).pipe(map((tp) => tp.warnings || []));
        const infractions = this.permissionCheckService.isAllowed(`customers.${this.customerId}.warnings_v2.*.get`).pipe(
            switchMap((can) => {
                return can ? this.infractionService.getAll(this.customerId, [ employeeId ], from, to).pipe(map((result) => result.data)) : of([]);
            }),
        );

        if (row.column.key === 'warnings_count' || row.column.key === 'infractions') {
            this.warningsDialog.open(warnings, of(typeof employeeName === 'string' ? employeeName : ''), infractions);
        } else if (row.column.key === 'comments_count') {
            this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
                data: {
                    comments: this.getTimepunch(employeeId, timepunchId, [ 'comments' ]).pipe(map((tp) => tp.comments)),
                },
            });
        }
    }

    sortColumn(s: Sort) {
        this.sorting.key = s.active;

        if (s.active == this.sorting.key && this.sorting.direction == 'asc') {
            this.sorting.direction = 'desc';
        } else {
            this.sorting.direction = 'asc';
        }

        const sorted = sort(this.rows, this.current.languageTag, [ (row: AggregateRow) => row.data.values[s.active]?.pureValue ], [ this.sorting.direction ]).concat([]);
        const parents = sorted.filter((s) => !s.parentId);
        const children = sorted.filter((s) => s.parentId);

        this.rows = parents.reduce((arr, parent) => {
            const c = children.filter((c) => c.parentId === parent.id);
            arr = arr.concat(parent).concat(c);
            return arr;
        }, [] as AggregateRow[]).flat();
    }
}
