// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
export const CalendarEventParticipantStatuses = {
    default: {
        value: 'NEEDS-ACTION',
    },
    no: {
        value: 'DECLINED',
        icon: 'close',
    },
    yes: {
        value: 'ACCEPTED',
        icon: 'check',
    },
    maybe: {
        value: 'TENTATIVE',
        icon: 'help',
    },
    delegated: {
        value: 'DELEGATED',
    },
};
module('eaw.calendar2').service('CalendarEventParticipant', [ 'EawResource', 'ToastService', function CalendarEventParticipant(EawResource, ToastService) {
    class CalendarEventParticipant {
        key;
        participantKey;
        userId;
        status;
        name;
        eventKey;
        email;
        constructor(data) {
            this.key = data.key;
            this.participantKey = data.participant_key;
            this.userId = parseInt((data.participant_key || this.participantKey)?.split(':').slice(-1)[0]);
            this.status = CalendarEventParticipant.getStatus(data.status);
            this.name = data.name;
            this.eventKey = data.event_key;
            this.email = data.email;
        }

        deleteInvitation(event) {
            return EawResource.create('/customers/:customer/calendars/:calendar/events/:event/invitations/:invitation').delete({
                customer: event.calendar.customer,
                calendar: event.calendar.key,
                event: event.key,
                invitation: this.key,
            }).$promise;
        }

        /**
         * @param {Calendar2Event} event
         * @param {string} status
         * @return {*}
         */
        updateStatus(event, status) {
            return EawResource.create('/customers/:customer/calendars/:calendar/events/:event/invitations/:invitation').update({
                customer: event.calendar.customer,
                calendar: event.calendarKey,
                event: event.key,
                invitation: this.key,
            }, {
                status,
            }).$promise.then((resp) => {
                const participant = new CalendarEventParticipant(resp);
                event.participant = participant;
                event.participants.splice(event.participants.findIndex((p) => p.key === resp.key), 1, participant);
                ToastService.tToast('calendar:RESPONDED');
            });
        }

        /**
         * @param {object}          args
         * @param {number}          args.customerId
         * @param {Calendar}        args.calendar
         * @return {Promise<Calendar2Event>}
         */
        static getAll(args) {
            return EawResource.create('/customers/:customer/calendars/:calendar/participants').get({
                customer: args.customerId,
                calendar: args.calendar.key,
                filter: args.filter,
                per_page: 10,
            }).$promise.then((resp) => {
                resp.data.forEach((r) => {
                    switch (r.recipient_type) {
                        case 'customer': {
                            r.translatedType = t('LOCATION');
                            r.icon = 'domain';
                            break;
                        }
                        case 'user': {
                            r.translatedType = t('USER');
                            r.icon = 'account';
                            break;
                        }
                        case 'user_group': {
                            r.translatedType = t('USER_GROUP');
                            r.icon = 'account-group';
                            break;
                        }
                    }
                });
                return resp.data;
            });
        }

        /**
         * @param {object}          args
         * @param {number}          args.customerId
         * @param {Calendar}        args.calendar
         * @param {Calendar2Event}  args.event
         * @param {number[]}        [args.userIds]
         * @param {number[]}        [args.customerIds]
         * @param {number[]}        [args.userGroupIds]
         * @return {Promise<Calendar2Event>}
         */
        static invite(args) {
            return EawResource.create('/customers/:customer/calendars/:calendar/events/:event/invitations', null, {
                save: {
                    method: 'POST',
                    isArray: true,
                },
            }).save({
                customer: args.customerId,
                calendar: args.calendar.key,
                event: args.event.key,
            }, {
                user_recipients: args.userIds,
                customer_recipients: args.customerIds,
                user_group_recipients: args.userGroupIds,
            }).$promise.then((resp) => {
                ToastService.tToast('calendar:INVITE_SENT_plural');
                return resp.map((invite) => new CalendarEventParticipant(invite));
            });
        }

        static getStatus(status = '') {
            return Object.values(CalendarEventParticipantStatuses).filter((s) => {
                return s.value.toLowerCase() === status.toLowerCase();
            })?.[0];
        }
    }
    return CalendarEventParticipant;
} ]);
