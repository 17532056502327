import { DataTableColumn } from './data-table-column';
import { DataTableHeader } from './data-table-header';
import { DataTableValue } from '../interfaces/data-table-value';
import { ProgressPortalComponent } from '../portals/progress-portal/progress-portal.component';

interface DataTableProgressColumnData<Item> {
    header: DataTableHeader | null;
    value?: DataTableValue<DataTableProgressColumn<Item>, Item, number | null | undefined>
}

export class DataTableProgressColumn<Item> extends DataTableColumn<DataTableProgressColumn<Item>, Item, number | null | undefined> {
    component = ProgressPortalComponent;

    constructor(column: DataTableProgressColumnData<Item>) {
        super({
            exportable: false,
            ...column,
        });
    }
}
