import { Component, DoCheck, Inject, Input, IterableDiffer, IterableDiffers, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';

export type EmployeeEntry = { id: number, name: string };

@Component({
    template: '',
    standalone: true,
})
export class EmployeeSelectComponent implements OnInit, DoCheck, ControlValueAccessor {
    constructor(@Inject(IterableDiffers) protected differs: IterableDiffers) {}

    @Input() employees?: { [customerId: string]: { [employeeId: number]: string } };
    @Input() customerIds: string[] = []; // Chosen customers, strings because they are keys from an object

    onChange?: (value: unknown) => void;
    onTouched?: () => void;

    touched = false;
    filteredEmployees: Array<EmployeeEntry> = [];
    selected = new FormControl();

    protected differ?: IterableDiffer<string>;
    protected initialValue?: any;

    ngOnInit(): void {
        this.selected.setValue(this.initialValue);

        if (!this.differ) {
            this.differ = this.differs.find(this.customerIds).create((_, id) => id);
        }

        this.filteredEmployees = this.getEmployees();
    }

    ngDoCheck(): void {
        if (!this.differ || !this.employees) {
            return;
        }

        const changes = this.differ.diff(this.customerIds);

        if (!changes) {
            return;
        }

        this.filteredEmployees = this.getEmployees();

        this.afterDoCheck();
    }

    registerOnChange(fn: (obj: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    touch() {
        if (!this.touched) {
            this.touched = true;

            if (this.onTouched) {
                this.onTouched();
            }
        }
    }

    change(val?: any) {
        if (this.onChange) {
            this.onChange(val);
        }
    }

    writeValue(_: any): void {
        throw new Error('writeValue not implemented');
    }

    trackEmployeeBy(_: number, e: EmployeeEntry) {
        return e.id;
    }

    getEmployees(): EmployeeEntry[] {
        let employees: EmployeeEntry[] = [];
        this.customerIds?.forEach((id) => {
            Object.entries(this.employees?.[id] || {}).forEach(([ id, name ]) => {
                employees = [ {
                    id: parseInt(id),
                    name,
                } ].concat(employees);
            });
        });

        return employees;
    }

    protected afterDoCheck() {
        throw new Error('afterDoCheck not implemented');
    }
}
