import { Component, inject, Input, OnInit } from '@angular/core';
import { CurrentService } from '../../../shared/services/current.service';
import { EMPTY, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { NumberFormatterService } from '../../../shared/services/number-formatter.service';
import { MatDialog } from '@angular/material/dialog';
import { BalanceType } from '../../models/balance-type';
import { BalanceService } from '../../http/balance.service';
import { BalanceHistoryDialogComponent, BalanceHistoryDialogData } from '../../dialogs/balance-history-dialog/balance-history-dialog.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { TranslateService } from '../../../shared/services/translate.service';
import { sort } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { SettingService } from '../../../shared/http/setting.service';
import { PredefinedSettings } from '../../../shared/enums/predefined-settings';

interface BalanceTypeItem {
    balanceType: BalanceType;
    balance: number | undefined;
    formattedBalance: string;
    name: string;
    description: string;
}

@Component({
    selector: 'eaw-employee-balances',
    templateUrl: './employee-balances.component.html',
    styleUrl: './employee-balances.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgIf,
        MatProgressSpinnerModule,
        NgFor,
        MatCardModule,
        MatRippleModule,
        MaterialColorDirective,
        AsyncPipe,
        TranslatePipe,
        InfoLoadingComponent,
    ],
})
export class EmployeeBalancesComponent implements OnInit {
    public readonly balanceService = inject(BalanceService);
    public readonly current = inject(CurrentService);
    public readonly numberFormatterService = inject(NumberFormatterService);
    public readonly dialog = inject(MatDialog);
    public readonly permissionCheckService = inject(PermissionCheckService);
    public readonly translateService = inject(TranslateService);
    public readonly settingService = inject(SettingService);

    @Input({ required: true }) customerId!: number;
    @Input({ required: true }) employeeId!: number;

    loading = true;
    items: BalanceTypeItem[] = [];
    noTypes = false;

    ngOnInit(): void {
        forkJoin([
            this.settingService.getValue([ 'customers', this.customerId ], PredefinedSettings.EmployeeVisibleBalanceCodes, [] as string[]),
            expandAllPages((pagination) => this.balanceService.getTypes(this.customerId, pagination), { per_page: 50 }),
        ]).pipe(
            switchMap(([ visibleCodes, types ]) => {
                const selectedTypes = this.getSelectedTypes(types, visibleCodes);

                if (!selectedTypes.length) {
                    this.noTypes = true;
                    this.loading = false;
                    return EMPTY;
                }

                return forkJoin(selectedTypes.map((type) => {
                    return this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.balances.${type.balanceCode}.*.get`)
                        .pipe(
                            map((allowed) => {
                                return allowed ? type : undefined;
                            }),
                        );
                }));
            }),
            switchMap((types) => {
                const filteredTypes: BalanceType[] = types.filter((type) => type !== undefined) as BalanceType[];

                return forkJoin([
                    of(filteredTypes),
                    this.balanceService.getAllForEmployee(this.customerId, this.employeeId, filteredTypes.map((type: BalanceType) => type.balanceCode)),
                ]);
            }),
            tap(async ([ types, balances ]) => {
                for await (const type of types) {
                    const balance = balances[type.balanceCode] || undefined;
                    const formattedBalance = balance === undefined ? '-' : type.getFormattedValue(balance, this.current.languageTag, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        unitDisplay: 'long',
                        signDisplay: 'exceptZero',
                    });

                    this.items.push({
                        balanceType: type,
                        balance,
                        formattedBalance,
                        name: await this.translateService.t(type.nameTranslation.key, type.nameTranslation.ns),
                        description: type.description ?
                            await this.translateService.t(type.descriptionTranslation.key, type.descriptionTranslation.ns) :
                            await this.translateService.t('NO_DESCRIPTION'),
                    });
                }

                this.items = sort(this.items, this.current.languageTag, [ (item) => item.name ]);
            }),
        ).subscribe({
            complete: () => {
                this.loading = false;
            },
        });
    }

    openChanges(item: BalanceTypeItem) {
        this.permissionCheckService.isAllowed(`customers.${this.customerId}.employees.${this.employeeId}.balances.${item.balanceType.balanceCode}.*.get`).subscribe((hasPermission) => {
            if (!hasPermission) {
                return;
            }

            this.dialog.open<BalanceHistoryDialogComponent, BalanceHistoryDialogData>(BalanceHistoryDialogComponent, {
                data: {
                    customerId: this.customerId,
                    employeeId: this.employeeId,
                    balanceType: of(item.balanceType),
                },
            });
        });
    }

    private getSelectedTypes(types: BalanceType[], visibleCodes: string[]) {
        return types.reduce((acc, type) => {
            if (visibleCodes.length) {
                if (visibleCodes.includes(type.balanceCode)) {
                    acc.push(type);
                }
            } else {
                acc.push(type);
            }

            return acc;
        }, [] as BalanceType[]);
    }
}
