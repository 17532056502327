// @ts-nocheck
import { module } from 'angular';

const template1 = `<div class="budget-info-row" ng-show="$pcdCtrl.isEmptyPositions()">
    <span class="row-text">N/A</span>
</div>
<div class="budget-info-row" ng-hide="$pcdCtrl.isEmptyPositions()" ng-repeat="position in $pcdCtrl.day.positions">
    <span class="row-text">{{position.name}}</span>
    <span class="row-value" ng-bind="position.cost | eawCurrency:false:0"></span>
</div>
<div class="budget-info-row total-row" ng-hide="$pcdCtrl.isEmptyPositions()" style="border-top: 1px dotted black; margin-top: 5px; padding-top: 3px;">
    <span class="row-text" ng-i18next="scheduling:TOTAL_LABOR_COST"></span>
    <span class="row-value" ng-bind="$pcdCtrl.day.labor_cost | eawCurrency:false:0"></span>
</div>
`;

module('eaw.scheduling').component('positionCostDay', {
    template: template1,
    controllerAs: '$pcdCtrl',
    bindings: {
        day: '<',
    },
    controller: function() {
        const ctrl = this;

        ctrl.isEmptyPositions = () => {
            if (typeof ctrl.day?.positions == 'undefined' || ctrl.day.positions.length == 0) {
                return true;
            }
            return false;
        };
    },
});
