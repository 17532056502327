<eaw-page-header [fabButton]="fabButton">
    <span title>{{'PERMISSION_plural' | translate | async}}</span>
</eaw-page-header>

<mat-card id="permissions-card">
    <mat-card-content *ngIf="!permissions.length && !fetching">
        <span>{{'USER_NO_PERMISSIONS' | translate:'admin' | async}}</span>
    </mat-card-content>

    <mat-card-content *ngIf="permissions.length">
        <div id="grid">
            <div class="grid-row" *ngFor="let p of permissions" [class.allowed]="p.permission.value" [class.not-allowed]="!p.permission.value">
                <span>{{p.permission.node}}</span>

                <mat-checkbox eawCheckboxHelper [(ngModel)]="p.selected" [disabled]="p.disabled" (ngModelChange)="updatePermission(p)"></mat-checkbox>

                <button mat-icon-button color="warn" (click)="deletePermission(p)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
