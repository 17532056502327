@if (user) {
    @if (smallScreen && (canGetUsers || canGetGroups)) {
        <mat-tab-group>
            <mat-tab [label]="user.name">
                <eaw-company-user [customer]="customer" [user]="user"></eaw-company-user>
            </mat-tab>

            <mat-tab [label]="('USER_ACCESS' | translate: 'company' | async)!">
                <eaw-user-access [user]="user" [customer]="customer"></eaw-user-access>
            </mat-tab>

            @if (canGetGroups && oldCustomer && oldUser) {
                <mat-tab [label]="('USER_GROUP_plural' | translate | async) || ''">
                    <eaw-user-groups-upgraded [customer]="oldCustomer" [user]="oldUser"></eaw-user-groups-upgraded>
                </mat-tab>
            }
        </mat-tab-group>
    } @else {
        <eaw-company-user [customer]="customer" [user]="user"></eaw-company-user>

        <div class="tw-grid" [class.tw-grid-cols-2]="canGetUsers && canGetGroups">
            @if (canGetUsers) {
                <eaw-user-access [user]="user" [customer]="customer"></eaw-user-access>
            }
            @if (canGetGroups && oldCustomer && oldUser) {
                <eaw-user-groups-upgraded [customer]="oldCustomer" [user]="oldUser"></eaw-user-groups-upgraded>
            }
        </div>
    }
} @else {
    <eaw-info-loading class="tw-mt-24" size="lg" direction="column">
        <span>{{'LOADING' | translate | async}}</span>
    </eaw-info-loading>
}
