import { UnitFilter } from '../filters/unit.filter';

export enum FileSizeUnit {
    Byte = 'byte',
    Kilo = 'kilobyte',
    Mega = 'megabyte',
    Giga = 'gigabyte',
}

export class FileSizeFormatter {
    constructor(
        private language: string,
        private display: Intl.NumberFormatOptions['unitDisplay'] = 'short',
        private unit?: FileSizeUnit,
    ) {
    }

    format(bytes?: number) {
        if (bytes == null || !Number.isFinite(bytes)) {
            return UnitFilter.format(this.language, bytes, this.unit, 0, this.display);
        }

        const divider = 1024;
        if (this.unit) {
            for (let i = 0; i < Object.values(FileSizeUnit).indexOf(this.unit); i++) {
                bytes /= divider;
            }
        } else {
            const index = Math.floor(Math.log(bytes) / Math.log(divider));
            this.unit = Object.values(FileSizeUnit)[index];

            bytes = bytes / Math.pow(divider, index);
        }

        return UnitFilter.format(this.language, bytes, this.unit, 0, this.display);
    }
}
