import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '../../services/translate.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { uniqueId } from '../../angularjs/modules/misc/services/easy-funcs.service';

export type ObjectCreatorObject = Record<string, string>;

type LineForm = FormGroup<{
    // Simply used for tracking the line
    id: FormControl<string>,
    key: FormControl<string | null>,
    value: FormControl<string | null>,
}>

@Component({
    selector: 'eaw-object-creator',
    templateUrl: './object-creator.component.html',
    styleUrl: './object-creator.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ObjectCreatorComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: ObjectCreatorComponent,
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class ObjectCreatorComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() title?: Promise<string>;
    @Input() keyLabel = this.translate.t('KEY');
    @Input() valueLabel = this.translate.t('VALUE');

    lines = new FormArray<LineForm>([]);
    touched = false;
    disabled = false;

    onTouched = () => {
    };

    onChange = (_: ObjectCreatorObject | null) => {
    };

    constructor(@Inject(TranslateService) private translate: TranslateService) {
    }

    ngOnInit() {
        this.lines.valueChanges.subscribe((value) => {
            if (!value.length) {
                this.addLine();
                return;
            }

            const lastLine = value[value.length - 1];
            if (lastLine?.key && lastLine?.value) {
                this.addLine();
            }

            this.onChange(this.getObject());
        });
    }

    getObject(): ObjectCreatorObject | null {
        const object: ObjectCreatorObject = {};

        this.lines.controls.forEach((line) => {
            const key = line.value.key;
            const value = line.value.value;

            if (key && value) {
                object[key] = value;
            }
        });

        return Object.keys(object).length ? object : null;
    }

    addLine(key = '', value = '') {
        const group: LineForm = new FormGroup({
            id: new FormControl<string>(uniqueId('line-'), { nonNullable: true }),
            key: new FormControl<string | null>(key, Validators.required),
            value: new FormControl<string | null>(value, Validators.required),
        });

        this.lines.push(group, { emitEvent: false });
    }

    removeLine(index: number) {
        this.markAsTouched();

        this.lines.removeAt(index);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touched = fn;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;

        if (this.disabled) {
            this.lines.disable();
        } else {
            this.lines.enable();
        }
    }

    writeValue(obj: ObjectCreatorObject | null): void {
        this.lines.clear();

        Object.entries(obj || {}).forEach(([ key, value ]) => {
            this.addLine(key, value);
        });
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!control.hasValidator(Validators.required)) {
            return null;
        }

        const object = this.getObject();
        return object ? null : { requiresAtLeastOne: true };
    }
}
