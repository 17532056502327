import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'company-settings-tariffs-upgraded',
    standalone: true
})
export class companySettingsTariffsUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('companySettingsTariffs', elementRef, injector);
    }
}

@Component({
    template: '<company-settings-tariffs-upgraded [customer]="customer"></company-settings-tariffs-upgraded>',
    standalone: true,
    imports: [companySettingsTariffsUpgradedDirective],
})
export class companySettingsTariffsUpgradedComponent {
    @Input() customer!: any;
}

@NgModule({
    imports: [companySettingsTariffsUpgradedDirective,
        companySettingsTariffsUpgradedComponent],
})
export class companySettingsTariffsUpgradeModule {
}
