<eaw-dialog-header>
    <span title>{{data.title | async}}</span>
</eaw-dialog-header>

<mat-dialog-content id="cropping-container">
    <input #fileInput type="file" hidden="hidden" accept="image/*" (change)="fileChange($event)">

    <div class="tw-flex tw-place-content-center">
        <mat-progress-spinner *ngIf="data.initialFile && !file" mode="indeterminate"></mat-progress-spinner>
    </div>

    <button *ngIf="!imageChangedEvent && !data.initialFile" id="choose-image-btn" mat-fab color="accent" (click)="fileInput.click()">
        <mat-icon>image</mat-icon>
    </button>

    <image-cropper #imageCropper
                   format="png"
                   [autoCrop]="false"
                   [imageFile]="file"
                   (imageLoaded)="imageLoaded()"
                   (imageCropped)="imageCropped($event)"
                   [imageChangedEvent]="imageChangedEvent"
                   [aspectRatio]="data.aspectRatio || 1"
                   [maintainAspectRatio]="!!data.aspectRatio">
    </image-cropper>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button *ngIf="imageChangedEvent || file" (click)="fileInput.click()">{{'CHOOSE_ANOTHER_IMAGE' | translate | async}}</button>
    <button mat-button (click)="imageCropper.crop()" [disabled]="!canSubmit">{{'UPLOAD_IMAGE' | translate | async}}</button>
</mat-dialog-actions>
