<eaw-page-header [fabButton]="fabButton">
    <span title>{{'LINK_plural' | translate: 'links' | async}}</span>
</eaw-page-header>

<mat-card class="mat-compact-card">
    <mat-card-content>
        <eaw-data-table
                [columns]="columns"
                [request]="request"
                (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
