import { ApiResponse } from '../interfaces/api-response';
import { BusinessDateString } from '../types/business-date';
import { BusinessDate } from '../utils/business-date';

export interface OrganizationMemberResponse extends ApiResponse {
    customer_id: number;
    name: string;
    from: BusinessDateString;
    to?: BusinessDateString | null;
}

export class OrganizationMember {
    customerId: number;
    name: string;
    from: BusinessDate;
    to?: BusinessDate | null;

    constructor(member: OrganizationMemberResponse) {
        this.customerId = member.customer_id;
        this.name = member.name;
        this.from = new BusinessDate(member.from);
        this.to = member.to ? new BusinessDate(member.to) : null;
    }
}
