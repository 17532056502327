// @ts-nocheck
import { module } from 'angular';

module('eaw.components').component('eawPagination', {
    template: `<div class="ng-table-pager" layout="column" layout-align="center center" layout-gt-xs="row" layout-align-gt-xs="space-between center">
    <div class="ng-table-pagination">
        <md-button ng-repeat="page in $pagination.pages track by $index"
                   ng-if="$pagination.pages.length > 3"
                   ng-switch="page.type"
                   class="pagination-button"
                   ng-disabled="page.current || !page.active"
                   ng-click="$pagination.updatePage(page)"
                   md-colors="{background: page.current ? 'primary' : 'white'}">
            <span ng-switch-when="prev|more|next" ng-switch-when-separator="|" href="">
                <md-icon ng-if="page.type == 'prev'" ng-bind="'chevron_left'"></md-icon>
                <md-icon ng-if="page.type == 'more'" ng-bind="'more_horiz'"></md-icon>
                <md-icon ng-if="page.type == 'next'" ng-bind="'chevron_right'"></md-icon>
            </span>
            <span ng-switch-when="page|first|last" ng-switch-when-separator="|" href="">
                <span ng-bind="page.number"></span>
            </span>
        </md-button>
    </div>

    <div>
        <md-button class="pagination-button"
                   ng-repeat="(count, val) in $pagination.counts track by $index"
                   ng-disabled="$pagination.perPage == count"
                   ng-click="$pagination.updateCount(count)">
            <span ng-bind="count"></span>
        </md-button>
    </div>
</div>
`,
    bindings: {
        loadPage: '&', // paginationUpdated(pagination) returns a promise.
        perPage: '=?',
        updateOnInit: '<?',
    },
    controllerAs: '$pagination',
    controller: [ '$scope', 'Pagination', function($scope, Pagination) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.updateOnInit = ctrl.updateOnInit ?? true;
            ctrl.page = ctrl.page || 1;
            ctrl.perPage = ctrl.perPage || 25;
            ctrl.counts = {
                10: 10,
                25: 25,
                50: 50,
                100: 100,
            };

            if (ctrl.updateOnInit) {
                ctrl.paginationUpdated();
            }

            // Listen for triggering
            $scope.$on('eawPagination:trigger', ctrl.paginationUpdated);
        };

        ctrl.updatePage = (page) => {
            switch (page?.type) {
                case 'next':
                    ctrl.page += 1;
                    break;
                case 'prev':
                    ctrl.page -= 1;
                    break;
                case 'more':
                    return;
                default:
                    ctrl.page = page?.number || 1; // Get page number or default to 1
                    break;
            }

            ctrl.paginationUpdated();
        };

        ctrl.updateCount = (count) => {
            ctrl.page = 1;
            ctrl.perPage = count;
            ctrl.paginationUpdated();
        };

        ctrl.createPages = (lastPage) => {
            ctrl.pages = [];

            if (!lastPage) {
                return;
            }

            const sideInclude = 3;
            let morePositive = false;
            let moreNegative = false;

            for (let i = 1; i <= lastPage; i++) {
                const diff = i - ctrl.page;

                if (i === 1 || i === lastPage) {
                    ctrl.pages.push({
                        active: true,
                        current: ctrl.page === i,
                        number: i,
                        type: 'page',
                    });
                } else if (Math.abs(diff) < sideInclude) {
                    ctrl.pages.push({
                        active: true,
                        current: ctrl.page === i,
                        number: i,
                        type: 'page',
                    });
                } else if (!moreNegative && Math.sign(diff) === -1) {
                    moreNegative = true;

                    ctrl.pages.push({
                        active: true,
                        current: ctrl.page === i,
                        number: i,
                        type: 'more',
                    });
                } else if (!morePositive && Math.sign(diff) === 1) {
                    morePositive = true;

                    ctrl.pages.push({
                        active: true,
                        current: ctrl.page === i,
                        number: i,
                        type: 'more',
                    });
                }
            }

            // Add left arrow
            ctrl.pages.unshift({
                active: ctrl.page !== 1,
                type: 'prev',
            });

            // Add right arrow
            ctrl.pages.push({
                active: ctrl.page !== lastPage,
                type: 'next',
            });
        };

        ctrl.paginationUpdated = (event, data) => {
            event ??= {};
            data ??= {};

            // If an event from the outside, then reset page to 1 as default
            // User can pass in data to overwrite this default behavior
            if (event.name === 'eawPagination:trigger') {
                ctrl.page = 1;
            }

            return ctrl.loadPage({
                pagination: new Pagination({
                    per_page: data.perPage || ctrl.perPage,
                    page: data.page || ctrl.page,
                }),
            }).then(ctrl.onUpdated).catch(console.error);
        };

        ctrl.onUpdated = (resp) => {
            if (!resp) {
                throw new Error('eaw-pagination: No response in pagination callback');
            }

            ctrl.page = resp.current_page;
            resp.last_page = resp.last_page || Math.ceil(resp.total / resp.per_page);

            ctrl.createPages(resp.last_page);
        };
    } ],
});
