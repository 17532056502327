import { t } from 'i18next';
import { keyBy, orderBy, uniqBy } from 'lodash-es';
import { Fullscreen } from '../../../../../shared/angularjs/modules/misc/services/fullscreen.service';
import moment, { Moment } from 'moment-timezone';
import { module } from 'angular';
import { TinyColor } from '@ctrl/tinycolor';
import { smallDevice } from '../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../../../shared/angularjs/current.factory';
import { MatDialog } from '@angular/material/dialog';
import { CreateNewShiftDialogComponent, CreateNewShiftDialogData } from '../../../../dialogs/create-new-shift-dialog/create-new-shift-dialog.component';
import { DateTime } from 'luxon';
import { ScheduleShiftDialogComponent, ScheduleShiftDialogData, ScheduleShiftDialogReturn } from '../../../../dialogs/schedule-shift-dialog/schedule-shift-dialog.component';
import { CrateUpdateAbsenceDialogData, CreateUpdateAbsenceDialogComponent } from '../../../../../absence/dialogs/create-update-absence-dialog/create-update-absence-dialog.component';
import { Products } from '../../../../../shared/enums/products';
import { shiftTooltipTemplate } from '../shift-tooltip/shift-tooltip.template';
import { Shift } from '../../../../models/shift';
import { scheduleViewDragAndDrop } from '../../../../signals';
import { CurrentService } from '../../../../../shared/services/current.service';

const template3 = `<md-list>
    <md-list-item ng-click="$ctrl.new($ctrl.date, $ctrl.employee); $ctrl.close()">
        <md-icon ng-bind="'add'"></md-icon>
        <p bo-i18next="scheduling:NEW_SHIFT"></p>
    </md-list-item>
    <md-list-item ng-if="$ctrl.hasAbsence && $ctrl.employee.id" ng-click="$ctrl.newAbsence($ctrl.date, $ctrl.employee.id); $ctrl.close()">
        <md-icon ng-bind="'add'"></md-icon>
        <p bo-i18next="absences:ADD_ABSENCE"></p>
    </md-list-item>
    <md-list-item ng-repeat="shift in $ctrl.shifts" ng-click="$ctrl.edit(shift); $ctrl.close()">
        <md-icon ng-bind="'edit'"></md-icon>
        <p>{{shift.from | moment:'LT'}} - {{shift.to | moment:'LT'}}</p>
    </md-list-item>
</md-list>
`;
const template2 = `<md-bottom-sheet class="md-list md-has-header">
    <md-subheader ng-cloak>
        <span>{{wobsCtrl.employee.name}} - {{wobsCtrl.date | moment:'dddd [-] LL'}}</span>
    </md-subheader>

    <md-list ng-cloak>
        <md-list-item ng-repeat="item in wobsCtrl.items track by $index">
            <md-button ng-click="wobsCtrl.itemClick(item)" class="md-list-item-content" md-autofocus="$last">
                <md-icon ng-bind="item.icon"></md-icon>
                <span class="md-inline-list-icon-label">{{item.name}}</span>
            </md-button>
        </md-list-item>
    </md-list>
</md-bottom-sheet>
`;

module('eaw.scheduling').component('weekOverviewTab', {
    template: `<md-card id="overview-card">
    <md-card-header>
        <md-card-header-text>
            <span class="md-title" bo-i18next="scheduling:WEEK_OVERVIEW"></span>
            <span class="md-subhead" ng-bind="$woCtrl.grouping.name"></span>
        </md-card-header-text>

        <div ng-if="$woCtrl.weeks.length && $woCtrl.weekMode">
            <md-button class="md-icon-button" ng-click="$woCtrl.changeWeek(-1)" ng-disabled="$woCtrl.weekIndex == 0">
                <md-icon ng-bind="'arrow_back'"></md-icon>
            </md-button>

            <md-button class="md-icon-button" ng-click="$woCtrl.changeWeek(1)" ng-disabled="$woCtrl.weekIndex == $woCtrl.lastWeekIndex">
                <md-icon ng-bind="'arrow_forward'"></md-icon>
            </md-button>
        </div>

        <md-button class="md-icon-button" ng-if="$woCtrl.positions.length" ng-click="$woCtrl.selectPositions()">
            <md-icon ng-bind="'badge'"></md-icon>
            <md-tooltip ng-i18next="scheduling:SELECT_POSITIONS"></md-tooltip>
        </md-button>

        <md-button class="md-icon-button" ng-if="$woCtrl.grouping == 'units'" ng-click="$woCtrl.help($event)">
            <md-icon ng-bind="'help'"></md-icon>
        </md-button>
    </md-card-header>

    <md-menu-bar>
        <md-menu>
            <button ng-click="$mdMenu.open()" ng-i18next="DISPLAY"></button>
            <md-menu-content>
                <md-menu-item class="md-indent">
                    <md-menu>
                        <md-button ng-click="$mdMenu.open()">{{'scheduling:GROUPING' | i18next}}</md-button>
                        <md-menu-content>
                            <md-menu-item ng-repeat="grouping in ::$woCtrl.groupings"
                                          type="radio"
                                          ng-model="$woCtrl.grouping"
                                          ng-change="$woCtrl.changeGrouping()"
                                          value="{{grouping.value}}">{{grouping.name}}
                            </md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </md-menu-item>

                <md-menu-item class="md-indent">
                    <md-menu>
                        <md-button ng-click="$mdMenu.open()">{{'scheduling:SORTING' | i18next}}</md-button>
                        <md-menu-content>
                            <md-menu-item ng-repeat="sorting in ::$woCtrl.sortings"
                                          type="radio"
                                          ng-model="$woCtrl.sorting"
                                          ng-change="$woCtrl.fullRender()"
                                          value="{{sorting.value}}">{{sorting.name}}
                            </md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </md-menu-item>

                <md-menu-item class="md-indent" ng-if="$woCtrl.grouping == 'units' && $woCtrl.scheduleView.businessUnits.length">
                    <md-menu>
                        <md-button ng-click="$mdMenu.open()">{{'scheduling:UNIT_plural' | i18next}}</md-button>
                        <md-menu-content>
                            <md-menu-item class="md-indent">
                                <md-button md-prevent-menu-close ng-click="$woCtrl.selectUnits(true)">{{'SELECT_ALL' | i18next}}</md-button>
                            </md-menu-item>
                            <md-menu-item class="md-indent">
                                <md-button md-prevent-menu-close ng-click="$woCtrl.selectUnits(false)">{{'DESELECT_ALL' | i18next}}</md-button>
                            </md-menu-item>

                            <md-menu-divider></md-menu-divider>

                            <md-menu-item ng-repeat="unit in ::$woCtrl.scheduleView.businessUnits | orderBy:'name'"
                                          type="checkbox"
                                          md-prevent-menu-close
                                          ng-model="unit._woSelected"
                                          ng-change="$woCtrl.fullRender()">{{unit.name}}
                            </md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </md-menu-item>

                <md-menu-divider></md-menu-divider>

                <md-menu-item type="checkbox" md-prevent-menu-close ng-model="$woCtrl.weekMode" ng-change="$woCtrl.toggleWeekMode()">
                    <md-button>{{'WEEKLY' | i18next}}</md-button>
                </md-menu-item>

                <md-menu-item type="checkbox" md-prevent-menu-close ng-model="$woCtrl.showEmpNumber" ng-change="$woCtrl.fullRender()">
                    <md-button>{{'company:EMPLOYEE_NUMBER_plural' | i18next}}</md-button>
                </md-menu-item>

                <md-menu-item type="checkbox" md-prevent-menu-close ng-model="$woCtrl.showNoShift" ng-change="$woCtrl.handleShowNoShifs()">
                    <md-button>{{'scheduling:ROWS_NO_SHIFT' | i18next}}</md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>

        <md-menu>
            <button ng-click="$mdMenu.open()">{{'ACTION_plural' | i18next}}</button>
            <md-menu-content>
                <md-menu-item ng-click="$woCtrl.print()">
                    <md-button ng-i18next="PRINT"></md-button>
                </md-menu-item>

                <md-menu-item ng-click="$woCtrl.fullscreen.toggle()">
                    <md-button ng-i18next="FULLSCREEN"></md-button>
                </md-menu-item>
            </md-menu-content>
        </md-menu>
    </md-menu-bar>

    <md-card-content id="week-table-wrapper" class="pretty-scroll tw-p-0">
        <table id="week-overview-table" class="table table-condensed table-bordered sticky-header"></table>
    </md-card-content>
</md-card>
`,
    controllerAs: '$woCtrl',
    bindings: {
        schedule: '<',
    },
    require: {
        scheduleView: '^scheduleView',
    },
    controller: [ 'Shift', 'CurrentDowngrade', 'MatDialogDowngrade', 'Printer', '$mdPanel', '$mdDialog', 'IconElement', '$animate', 'componentCreatorService', 'CalendarEventFactory', 'colorpickerService', 'scheduleAvailabilityOverviewService', 'scheduleDays', 'scheduleMode', 'shiftEvents', '$mdBottomSheet', '$scope', '$element', 'ItemSelectorDialog', 'ComplexTooltip', function(OldShiftClass, CurrentDowngrade: CurrentService, MatDialogDowngrade: MatDialog, Printer, $mdPanel, $mdDialog, IconElement, $animate, componentCreatorService, CalendarEventFactory, colorpickerService, scheduleAvailabilityOverviewService, scheduleDays, scheduleMode, shiftEvents, $mdBottomSheet, $scope, $element, ItemSelectorDialog, ComplexTooltip) {
        // @ts-ignore
        const ctrl = this;

        ctrl.timeouts = [];

        ctrl.$postLink = async () => {
            ctrl.el = $element[0];
            ctrl.fullscreen = Fullscreen;
            ctrl.weeks = scheduleDays.getWeeksArray();
            ctrl.days = scheduleDays.getAll();
            ctrl.showNoShift = true;
            ctrl.weekIndex = 0;
            ctrl.lastWeekIndex = ctrl.weeks.length - 1;
            ctrl.sortings = [
                {
                    name: t('scheduling:NAME_ASC'),
                    value: 'name_asc',
                },
                {
                    name: t('scheduling:NAME_DESC'),
                    value: 'name_desc',
                },
            ];
            ctrl.groupings = [
                {
                    name: t('scheduling:STANDARD_GROUPING'),
                    value: 'default',
                },
                {
                    name: t('scheduling:UNIT_GROUPING'),
                    value: 'units',
                },
            ];
            ctrl.sorting = ctrl.sortings[0].value;
            ctrl.grouping = ctrl.groupings[0].value;
            ctrl.selectUnits();
            await ctrl.getEmployees();
            ctrl.handleAvailability();
            ctrl.handleEvents();
            ctrl.tableEl = document.getElementById('week-overview-table');
            ctrl.fullRender();
            ctrl.scrollToDate(moment());
            shiftEvents.register.onLoadProgress($scope, ctrl.insertShifts);
            shiftEvents.register.onChange($scope, ctrl.onShiftChange);
            shiftEvents.register.onDoing($scope, ctrl.onShiftDoing);

            shiftEvents.register.onPeriodsChange($scope, async (_: any, shiftId: number) => {
                await ctrl.schedule.getShift(shiftId)?.refresh();
                ctrl.fullRender();
            });

            $scope.$on('schedule:unit_order:changed', ctrl.fullRender);
        };
        /**
         *
         * @param {Boolean} [selection]
         */
        ctrl.selectUnits = (selection: any) => {
            ctrl.scheduleView.businessUnits?.forEach((u: any) => {
                u._woSelected = selection ?? u._woSelected ?? true;
            });
            if (typeof selection === 'boolean') {
                ctrl.fullRender();
            }
        };
        ctrl.fullRender = () => {
            $animate.enabled(false);
            // Update what period we will render
            if (ctrl.weekMode) {
                const weekNr = ctrl.weeks[ctrl.weekIndex].weekNr;
                ctrl.renderDays = ctrl.days.filter((d: any) => !ctrl.weekMode || d.weekNr === weekNr);
                ctrl.renderWeeks = ctrl.weeks.filter((_: any, i: any) => !ctrl.weekMode || ctrl.weekIndex === i);
            } else {
                ctrl.renderDays = ctrl.days;
                ctrl.renderWeeks = ctrl.weeks;
            }
            requestAnimationFrame(() => {
                ctrl.renderTableHeader();
                ctrl.renderBodies();
                ctrl.insertShifts();
                $animate.enabled(true);
            });
        };
        ctrl.onShiftChange = () => {
            ctrl.insertShifts();
        };
        ctrl.onShiftDoing = () => {
            ctrl.insertShifts();
        };
        ctrl.changeGrouping = () => {
            const action = ctrl.grouping !== 'default' ? 'add' : 'remove';
            ctrl.tableEl?.classList[action]('grouped');
            ctrl.fullRender();
        };
        ctrl.handleShowNoShifs = () => {
            // Handle showing no shifts or not
            Array.from(ctrl.tableEl.querySelectorAll('tbody')).forEach((b: any) => {
                // Skip first since it's group by row
                // Skip second cause we always wanna show unassigned
                const rows = Array.from(b.children).slice(2) as HTMLTableRowElement[];
                // Loop
                rows.forEach((row) => {
                    const hasSomeShifts = !!row.querySelector('.shift');
                    row.style.display = ctrl.showNoShift || hasSomeShifts ? 'table-row' : 'none';
                });
            });
        };
        ctrl.createDefaultShiftDiv = (shift: any) => {
            const shiftDiv = document.createElement('div');
            shiftDiv.classList.add('shift');
            shiftDiv.dataset['shiftId'] = shift.id;
            shiftDiv.innerText = `${shift.from.format('LT')} - ${shift.to.format('LT')}`;
            // No events if original schedule
            if (!ctrl.scheduleView.schedule.original) {
                shiftDiv.setAttribute('draggable', 'true');
                shiftDiv.addEventListener('click', (e) => {
                    ctrl.onUnitDrop(shift, e);
                    ctrl.removeTimeout(shift.id);
                });
                shiftDiv.addEventListener('dragstart', () => {
                    ctrl.dragging = true;
                    ctrl.removeTimeout(shift.id);
                });
                shiftDiv.addEventListener('dragend', () => {
                    ctrl.dragging = false;
                    ctrl.removeTimeout(shift.id);
                });
            }

            // Add tooltip

            shiftDiv.addEventListener('mouseenter', (ev: any) => {
                const i = ctrl.timeouts.findIndex((t: any) => t.id == shift.id);
                if (i > -1) {
                    clearTimeout(ctrl.timeouts[i].timeout);
                    ctrl.timeouts[i].timeout = ctrl.createTimeout(ev);
                } else {
                    ctrl.timeouts.push({
                        id: shift.id,
                        timeout: ctrl.createTimeout(ev),
                    });
                }
            });

            shiftDiv.addEventListener('mouseleave', () => {
                ctrl.removeTimeout(shift.id);
            });

            return shiftDiv;
        };
        ctrl.createTimeout = (ev: any) => {
            const parent = ev.target.parentNode;
            return setTimeout(() => {
                const shift = ctrl.schedule.getShift(ev.target.dataset.shiftId);

                if (!shift) {
                    return;
                }
                ComplexTooltip(ev, ev.target, {
                    controller: 'shiftTooltipCtrl',
                    template: shiftTooltipTemplate,
                    panelClasses: [ 'shift-tooltip' ],
                    parentNode: parent,
                    locals: {
                        shift,
                    },
                });
            }, 500);
        };
        ctrl.removeTimeout = (id: any) => {
            const i = ctrl.timeouts.findIndex((t: any) => t.id == id);
            if (i > -1) {
                clearTimeout(ctrl.timeouts[i].timeout);
                ctrl.timeouts.splice(i, 1);
            }
        };
        ctrl.insertPeriods = (shiftDiv: any, shift: any) => {
            const pContainer = document.createElement('div');
            pContainer.classList.add('periods');
            shift.periods?.forEach((p: any) => {
                const pEl = document.createElement('div');
                pEl.classList.add('period', 'eaw-tooltip');
                pEl.dataset['tooltip'] = `${p.description} ${p.from.format('LT')} - ${p.to.format('LT')}`;
                if (p.unproductive) {
                    pEl.classList.add('background-stripes');
                }
                // Has to be background color since "background-stripes" uses background property
                pEl.style.backgroundColor = p.color instanceof TinyColor ? p.color.toHexString(true) : colorpickerService.getHex(p.color);
                pContainer.appendChild(pEl);
            });
            shiftDiv.appendChild(pContainer);
        };
        ctrl.onUnitDrop = (shift: any, ev: any) => {
            if (scheduleViewDragAndDrop()?.data?.selected) {
                ev.stopPropagation();
                ctrl.scheduleView.onDropUnit(shift);
            }
        };
        ctrl.insertShifts = async () => {
            Array.from(ctrl.tableEl.getElementsByClassName('shift')).forEach((el: any) => el.remove());
            // Make sure the order is always the same
            const shifts = orderBy(ctrl.schedule.shifts, [ 'offset', 'length', 'id' ], [ 'asc', 'asc', 'asc' ]);
            if (ctrl.grouping === 'units') {
                shifts.forEach((s) => {
                    s.periods?.filter((p: any) => p.business_unit_id).forEach((p: any) => {
                        const selector = `${p.business_unit_id || 0}x${s.employee_id || 0}x${p.from.dayOfYear()}`;
                        const insertNode = document.getElementById(selector);
                        if (!insertNode) {
                            return;
                        }
                        const shiftDiv = ctrl.createDefaultShiftDiv(s);
                        shiftDiv.dataset.periodId = p.id;
                        shiftDiv.dataset.unitId = p.business_unit_id;
                        shiftDiv.innerText = `${p.from.format('LT')} - ${p.to.format('LT')}`;
                        insertNode.appendChild(shiftDiv);
                        // Skip parts under if an original schedule
                        if (ctrl.scheduleView.schedule.original) {
                            return;
                        }
                        shiftDiv.addEventListener('dragstart', (e: any) => {
                            e.target.closest('tbody')?.classList.add('drag-source');
                            e.dataTransfer.dropEffect = 'move';
                            e.dataTransfer.setData('text/plain', JSON.stringify({
                                shiftId: s.id,
                                periodId: p.id,
                                unitId: p.business_unit_id,
                            }));
                        });
                        shiftDiv.addEventListener('dragend', () => {
                            ctrl.removeDragsourceClass();
                            ctrl.removeDragoverClass();
                        });
                    });
                });
            }
            if (ctrl.grouping === 'default') {
                shifts.forEach((s) => {
                    const selector = `0x${s.employee_id || 0}x${s.from.dayOfYear()}`;
                    const insertNode = document.getElementById(selector);
                    if (!insertNode) {
                        return;
                    }
                    const shiftDiv = ctrl.createDefaultShiftDiv(s);
                    ctrl.insertPeriods(shiftDiv, s);
                    if (s.comments_count || s.comments?.length) {
                        IconElement.get('comment').then((icon: any) => {
                            icon.classList.add('commented');
                            icon.addEventListener('click', (e: any) => {
                                e.stopPropagation();
                                // open edit shift dialog on comments tab
                                ctrl.edit(s, 2);
                            });
                            shiftDiv.appendChild(icon);
                        });
                        shiftDiv.classList.add('commented');
                    }
                    insertNode.appendChild(shiftDiv);
                    // Skip parts under if an original schedule
                    if (ctrl.scheduleView.schedule.original) {
                        return;
                    }
                    shiftDiv.addEventListener('dragstart', (e: any) => {
                        e.target.closest('td')?.classList.add('drag-source');
                        e.dataTransfer.dropEffect = 'move';
                        e.dataTransfer.setData('text/plain', JSON.stringify({
                            shiftId: s.id,
                        }));
                    });
                    shiftDiv.addEventListener('dragend', () => {
                        ctrl.removeDragsourceClass();
                        ctrl.removeDragoverClass();
                    });
                });
            }
            ctrl.handleShowNoShifs();
        };
        ctrl.shiftDragOver = (ev: any) => {
            ev.preventDefault();
            ctrl.removeDragoverClass();
            ev.target.closest('td')?.classList.add('dragover-item');
        };
        ctrl.shiftDragDrop = async (emp: any, date: any, ev: any) => {
            const data = JSON.parse(ev.dataTransfer.getData('text/plain'));
            const shift = ctrl.schedule.getShift(data.shiftId);
            const sameDate = date.isSame(shift.from, 'd');
            const sameEmp = (emp?.id || 0) === (shift.employee_id || 0);
            if (sameDate && sameEmp) {
                return;
            }
            shift.employee = emp?.id ? emp : null;
            shift.from = moment([ date.year(), date.month(), date.date(), shift.from.hour(), shift.from.minute() ]);
            shift.to = moment([ date.year(), date.month(), date.date(), shift.to.hour(), shift.to.minute() ]);
            shift.business_date = moment([ date.year(), date.month(), date.date(), 0, 0, 0 ]);

            if (shift.to.isBefore(shift.from)) {
                shift.to = shift.to.add(1, 'd');
            }
            try {
                await ctrl.schedule.updateShift(shift);
            } catch (e) {
                console.error(e);
            }
            try {
                await shift.refresh();
            } catch (e) {
                console.error(e);
            }
        };
        ctrl.insertRowCells = (tbody: any, tr: any, emp: any) => {
            ctrl.renderDays.forEach((d: any) => {
                const td = document.createElement('td');
                const id = `${tbody.dataset.identifier}x${tr.dataset.employeeId}x${d.yearDay}`;
                const container = document.createElement('div');
                container.id = id;
                container.classList.add('shift-container');
                td.appendChild(container);
                td.dataset['lastDayWeek'] = String(+d.lastDayOfWeek);
                td.classList.add('shift-cell');
                td.dataset['yearDay'] = d.yearDay;
                if (emp?.id >= 0 && !ctrl.scheduleView.schedule.original) {
                    td.addEventListener('click', (ev) => {
                        ctrl.open(d.moment, emp, ev);
                    });
                }
                if (ctrl.unavailable?.[emp?.id]?.[d.index]) {
                    td.classList.add('unavailable');
                }
                if (ctrl.grouping === 'default') {
                    td.addEventListener('dragover', ctrl.shiftDragOver);
                    td.addEventListener('drop', (e) => {
                        ctrl.shiftDragDrop(emp, d.moment, e);
                    });
                }
                tr.appendChild(td);
            });
            const nameTd = document.createElement('td');
            const name = ctrl.schedule.settings.showNicknames ? (emp?.nickname || emp?.name) : emp?.name;
            nameTd.innerText = name || '';
            nameTd.classList.add('emp-name');
            if (emp?.number && ctrl.showEmpNumber) {
                nameTd.innerText = `#${emp?.number || ''} ${nameTd.innerText}`;
            }
            if (emp?.user_id === CurrentOld.getUser()['id']) {
                nameTd.classList.add('myself');
            }
            if (emp && emp.customer_id && emp.customer_id !== CurrentOld.getCustomer()['id']) {
                nameTd.classList.add('external');
                nameTd.title = 'External';
            }
            tr.prepend(nameTd);
        };
        ctrl.insertEmployees = (tbody: any) => {
            const [ sort, direction ] = ctrl.sorting.split('_');
            const unassigned = {
                id: 0,
                name: t('scheduling:UNASSIGNED'),
            };
            const emps: any = orderBy(ctrl.employees, sort, direction);
            const filteredPositions = ctrl.filterPositions?.map((p: any) => p.name);
            [ unassigned ].concat(emps).forEach((e: any) => {
                let hide = false;
                if (ctrl.filterPositions?.length && e.id > 0) {
                    hide = !e?.positions?.some((p: any) => filteredPositions.includes(p.name));
                }
                if (!hide) {
                    const tr = document.createElement('tr');
                    tr.dataset['employeeId'] = e.id;
                    ctrl.insertRowCells(tbody, tr, e);
                    tbody.appendChild(tr);
                }
            });
        };
        ctrl.renderBody = (identifier: any) => {
            const tbody = document.createElement('tbody');
            tbody.dataset['identifier'] = String(identifier);
            const tr = document.createElement('tr');
            const td = document.createElement('td');
            const span = document.createElement('span');
            if (ctrl.grouping !== 'default') {
                td.rowSpan = 999;
                td.classList.add('group-by-text');
                td.appendChild(span);
                tr.appendChild(td);
            }
            tbody.appendChild(tr);
            ctrl.insertEmployees(tbody);
            return [ tbody, td, span ];
        };
        ctrl.removeDragoverClass = () => {
            Array.from(ctrl.tableEl.getElementsByClassName('dragover-item')).forEach((el: any) => el.classList.remove('dragover-item'));
        };
        ctrl.removeDragsourceClass = () => {
            Array.from(ctrl.tableEl.getElementsByClassName('drag-source'))?.forEach((el: any) => el.classList.remove('drag-source'));
        };
        ctrl.unitDragOver = (ev: any) => {
            ev.preventDefault();
            ctrl.removeDragoverClass();
            ev.target.closest('tbody')?.classList.add('dragover-item');
        };
        ctrl.unitDragDrop = async (targetUnit: any, ev: any) => {
            const data = JSON.parse(ev.dataTransfer.getData('text/plain'));
            const shift = ctrl.schedule.getShift(data.shiftId);
            const period = shift.periods?.find((p: any) => p.business_unit_id === data.unitId);
            if (period.business_unit_id === targetUnit.id) {
                return;
            }
            period.shift = shift;
            period.business_unit = targetUnit;
            period.business_unit_id = targetUnit.id;
            try {
                await period.save(shift.schedule.customer_id, shift.schedule_id).$promise;
            } catch (e) {
                console.error(e);
            }
            try {
                await shift.refresh();
            } catch (e) {
                console.error(e);
            }
        };
        ctrl.renderBodies = () => {
            Array.from(ctrl.tableEl.querySelectorAll('tbody'))?.forEach((el: any) => el.remove());
            if (ctrl.grouping === 'units') {
                ctrl.scheduleView.getBusinessUnits().filter((u: any) => u._woSelected)?.forEach((u: any) => {
                    const unitColor = colorpickerService.getHex(u.color);
                    const [ tbody, td, span ] = ctrl.renderBody(u.id);
                    tbody.addEventListener('dragover', ctrl.unitDragOver);
                    tbody.addEventListener('drop', (e: any) => {
                        ctrl.unitDragDrop(u, e);
                    });
                    tbody.dataset.identifier = u.id;
                    tbody.dataset.unitId = u.id;
                    td.style.background = unitColor;
                    td.classList.add('overflow-ellipsis');
                    span.innerText = u.name;
                    span.style.color = new TinyColor(unitColor).isDark() ? 'white' : 'black';
                    ctrl.tableEl.appendChild(tbody);
                });
            }
            if (ctrl.grouping === 'default') {
                // @ts-ignore
                const [ tbody, td, span ] = ctrl.renderBody(0);
                ctrl.tableEl.appendChild(tbody);
            }
            // Update left stickyness based on the width of the column we are grouping by
            requestAnimationFrame(() => {
                ctrl.el.style.setProperty('--emp-name-sticky', `${ctrl.tableEl.querySelector('.group-by-text')?.getBoundingClientRect().width || 0}px`);
            });
        };
        ctrl.renderTableHeader = () => {
            Array.from(ctrl.tableEl.querySelectorAll('thead')).forEach((el: any) => el.remove());
            const dummyTh = document.createElement('th');
            const thead = document.createElement('thead');
            const weekTr = document.createElement('tr');
            const daysTr = document.createElement('tr');
            weekTr.classList.add('weeks-row');
            daysTr.classList.add('days-row');
            ctrl.renderWeeks.forEach((w: any, i: number) => {
                const th = document.createElement('th');
                th.dataset['week'] = w.week;
                th.dataset['lastDayWeek'] = String(1);
                th.dataset['weekIndex'] = String(i);
                th.colSpan = ctrl.days.filter((d: any) => d.weekNr === w.weekNr).length;
                th.innerText = t('WEEK_AND_YEAR', {
                    week: w.week,
                    year: w.year,
                });
                weekTr.appendChild(th);
            });
            ctrl.renderDays.forEach((day: any) => {
                const th = document.createElement('th');
                const thContainer = document.createElement('div');
                const textContainer = document.createElement('div');
                thContainer.classList.add('th-container');
                textContainer.classList.add('text-container');
                const div = document.createElement('div');
                const small = document.createElement('small');
                div.innerText = ctrl.schedule.is_template ? day.templateLabel : day.moment.format('ll');
                small.innerText = day.dayName;
                textContainer.appendChild(div);
                textContainer.appendChild(small);
                thContainer.appendChild(textContainer);
                if (!ctrl.schedule.is_template && day.dstMessage) {
                    const dst = document.createElement('small');
                    dst.innerText = day.dstMessage;
                    dst.classList.add('c-red');
                    textContainer.appendChild(dst);
                }
                th.dataset['yearDay'] = day.moment.dayOfYear();
                th.dataset['weekIndex'] = ctrl.weeks.findIndex((w: any) => w.week === day.weekNr);
                th.dataset['lastDayWeek'] = String(+day.lastDayOfWeek);
                if (day.holiday) {
                    th.classList.add('holiday');
                }
                if (day.moment.isSame(moment(), 'd')) {
                    th.classList.add('today');
                }
                if (day.events?.length) {
                    th.title = day.events.reduce((text: any, event: any) => text + event.name + '\n', '');
                    const comp = componentCreatorService.create({
                        name: 'mdIcon',
                        scope: $scope,
                        bindings: {
                            mdSvgSrc: [ 'calendar-star', 'string' ],
                        },
                    });
                    thContainer.appendChild(comp.element);
                }
                th.appendChild(thContainer);
                daysTr.appendChild(th);
            });
            // Prepend a column for employee name
            daysTr.prepend(dummyTh.cloneNode());
            weekTr.prepend(dummyTh.cloneNode());
            // Prepend a column if we are grouping by something
            if (ctrl.grouping !== 'default') {
                daysTr.prepend(dummyTh.cloneNode());
                weekTr.prepend(dummyTh.cloneNode());
            }
            thead.appendChild(weekTr);
            thead.appendChild(daysTr);
            ctrl.tableEl.appendChild(thead);
            // Get size of added head to fix stickiness
            requestAnimationFrame(() => {
                ctrl.el.style.setProperty('--head-days-sticky', `${weekTr.getBoundingClientRect().height}px`);
            });
        };
        ctrl.help = (ev: any) => {
            $mdDialog.show($mdDialog.alert()
                .clickOutsideToClose(true)
                .textContent(t('scheduling:WEEK_OVERVIEW_GROUPED_UNIT_HELP'))
                .ok(t('OK'))
                .targetEvent(ev));
        };
        ctrl.toggleWeekMode = () => {
            ctrl.tableEl.classList.add('week-mode');
            if (!ctrl.weekMode) {
                ctrl.tableEl.classList.remove('week-mode');
                ctrl.fullRender();
                ctrl.scrollToDate(ctrl.weeks[ctrl.weekIndex].from);
                return;
            }
            try {
                const rect = document.getElementById('week-table-wrapper')?.getBoundingClientRect();
                if (rect) {
                    const parse = document.elementsFromPoint(rect.left + (rect.width * 0.75), rect.top)[0]?.closest('th')?.dataset['weekIndex'] || '0';
                    ctrl.weekIndex = parseInt(parse) || 0;
                } else {
                    ctrl.weekIndex = 0;
                }
            } catch (e) {
                ctrl.weekIndex = 0;
                console.error(e);
            }
            ctrl.fullRender();
        };
        ctrl.changeWeek = (num: number) => {
            ctrl.weekIndex += num;
            ctrl.fullRender();
        };
        ctrl.scrollToDate = (date: Moment) => {
            requestAnimationFrame(() => {
                const target = ctrl.el.querySelector(`[data-year-day="${date.dayOfYear()}"]`);
                if (!target) {
                    return;
                }
                const offset = window.innerWidth < 1000 ? 100 : 250;
                target.closest('table').parentElement.scrollLeft = Math.max(0, target.offsetLeft - offset);
            });
        };
        ctrl.print = () => {
            Printer.print(document.getElementById('week-overview-table'));
        };

        ctrl.handleEvents = async () => {
            if (ctrl.schedule.original) {
                return;
            }
            if (ctrl.schedule.isTemplate()) {
                return;
            }
            if (!ctrl.schedule.customer.hasProduct('Calendar')) {
                return;
            }
            if (!CurrentOld.can(`customers.${ctrl.schedule.customer_id}.calendar_events.*.get`)) {
                return;
            }
            const eventsRes = await CalendarEventFactory.getAll({
                customer: ctrl.schedule.customer,
                from: ctrl.schedule.getFrom(),
                to: ctrl.schedule.getTo(),
                per_page: 100,
            });
            ctrl.days.forEach((day: any) => {
                day.events = eventsRes.data.filter((e: any) => day.moment.isBetween(e.from, e.to, 'd', '[]'));
            });
        };
        ctrl.handleAvailability = () => {
            if (ctrl.schedule.original) {
                return;
            }
            if (ctrl.schedule.isTemplate()) {
                return;
            }
            if (!ctrl.schedule.customer.hasProduct('availability')) {
                return;
            }
            if (!CurrentOld.can(`customers.${ctrl.schedule.customer_id}.availabilities.*.get`)) {
                return;
            }
            return scheduleAvailabilityOverviewService.getOverview(ctrl.schedule).then(() => {
                ctrl.unavailable = {};
                ctrl.employees.forEach((e: any) => {
                    ctrl.days.forEach((d: any, i: number) => {
                        const weekIndex = Math.floor(i / 7);
                        const dayIndex = i - (7 * weekIndex);
                        ctrl.unavailable[e.id] = ctrl.unavailable[e.id] || {};
                        ctrl.unavailable[e.id][d.index] = scheduleAvailabilityOverviewService.isNotAvailable(dayIndex, weekIndex, e.id);
                    });
                });
                ctrl.fullRender();
            });
        };

        ctrl.new = (date: Moment, employee: { id: number | 0 }) => {
            MatDialogDowngrade.open<CreateNewShiftDialogComponent, CreateNewShiftDialogData, ScheduleShiftDialogReturn>(CreateNewShiftDialogComponent, {
                data: {
                    employeeId: employee.id === 0 ? undefined : employee.id,
                    customerId: ctrl.schedule.customer_id,
                    scheduleId: ctrl.schedule.id,
                    date: DateTime.fromObject({
                        year: date.year(),
                        month: date.month() + 1,
                        day: date.date(),
                    }),
                    withs: [
                        'periods',
                        'periods.businessUnit',
                        'periods.qualifications',
                        'employee',
                        'qualifications',
                        'properties',
                        'shiftGroup',
                        'comments',
                    ]
                },
            }).afterClosed().subscribe((shift) => {
                if (shift instanceof Shift) {
                    ctrl.schedule.insertShift(new OldShiftClass(shift._response));
                    ctrl.fullRender();
                }
            });
        };

        ctrl.edit = (shift: any, initialTab?: any) => {
            MatDialogDowngrade.open<ScheduleShiftDialogComponent, ScheduleShiftDialogData, ScheduleShiftDialogReturn>(ScheduleShiftDialogComponent, {
                data: {
                    stackId: CurrentDowngrade.getCustomer().stackId,
                    customerId: ctrl.schedule.customer_id,
                    scheduleId: shift.schedule_id,
                    shiftId: shift.id,
                    initialTab,
                    shiftWiths: [ 'periods', 'periods.businessUnit', 'periods.qualifications', 'employee', 'qualifications', 'properties', 'shiftGroup', 'comments' ],
                    periodAddedCallback: () => shiftEvents.trigger.periodsChange(shift.id),
                    periodEditedCallback: () => shiftEvents.trigger.periodsChange(shift.id),
                    periodDeletedCallback: () => shiftEvents.trigger.periodsChange(shift.id),
                },
            }).afterClosed().subscribe((s) => {
                if (s instanceof Shift) {
                    ctrl.schedule.removeShift(s.id);
                    ctrl.schedule.insertShift(new OldShiftClass(s._response));
                    shiftEvents.trigger.updated(shift);
                    ctrl.fullRender();
                }

                if (s === null) {
                    ctrl.schedule.removeShift(shift.id);
                    shiftEvents.trigger.deleted({ id: shift.id });
                    ctrl.fullRender();
                }
            });
        };

        ctrl.newAbsence = (date: Moment, employeeId: number) => {
            const dateTime = DateTime.fromObject({
                year: date.year(),
                month: date.month() + 1,
                day: date.date(),
            });

            MatDialogDowngrade.open<CreateUpdateAbsenceDialogComponent, CrateUpdateAbsenceDialogData>(CreateUpdateAbsenceDialogComponent, {
                data: {
                    customerId: ctrl.schedule.customer_id,
                    employeeId,
                    from: dateTime,
                    to: dateTime,
                    approve: true,
                },
            }).beforeClosed().subscribe((res) => {
                if (res) {
                    scheduleAvailabilityOverviewService.init(ctrl.schedule);
                    ctrl.handleAvailability();
                }
            });
        };

        ctrl.open = (date: any, employee: any, ev: any) => {
            if (scheduleMode.getMode() === 'read-only') {
                return;
            }
            let shifts = ctrl.schedule.shifts.filter((s: any) =>
                // Check same employee and same date, but also - newly created unassigned shift is missing employee_id, has only employee null
                (s.employee_id === (employee.id || null) || (employee.id === 0 && s.employee === null)) && s.from.isSame(date, 'd'));
            shifts = orderBy(shifts, [ 'offset', 'length', 'id' ], [ 'asc', 'asc', 'asc' ]);
            if (smallDevice()) {
                $mdBottomSheet.show({
                    template: template2,
                    controller: 'weekOverviewBottomSheetCtrl',
                    controllerAs: 'wobsCtrl',
                    bindToController: true,
                    locals: {
                        date,
                        employee,
                        shifts,
                        hasAbsence: ctrl.schedule.customer.hasProduct(Products.Absence),
                    },
                }).then((item: any) => {
                    switch (item.action) {
                        case 'edit':
                            ctrl.edit(item.shift);
                            break;
                        case 'newAbsence':
                            ctrl.newAbsence(date, employee.id);
                            break;
                        case 'new':
                        default:
                            ctrl.new(date, employee);
                    }
                });
            } else {
                $mdPanel.open({
                    attachTo: document.body,
                    controller: 'WeekOverviewPanel',
                    controllerAs: '$ctrl',
                    template: template3,
                    panelClass: 'eaw-panel week-overview-panel',
                    position: $mdPanel.newPanelPosition()
                        .relativeTo(ev.currentTarget || ev.target)
                        .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ALIGN_TOPS),
                    animation: $mdPanel.newPanelAnimation()
                        .openFrom(ev)
                        .duration(100)
                        .withAnimation($mdPanel.animation.SCALE),
                    targetEvent: ev,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    focusOnOpen: true,
                    bindToController: true,
                    locals: {
                        date,
                        employee,
                        shifts,
                        hasAbsence: ctrl.schedule.customer.hasProduct(Products.Absence),
                        unassignedShifts: ctrl.unassignedShifts,
                        new: ctrl.new,
                        edit: ctrl.edit,
                        newAbsence: ctrl.newAbsence,
                    },
                });
            }
        };
        ctrl.getEmployees = async () => {
            const employees: any = await Promise.resolve(ctrl.schedule.getEmployees().map((a: any) => a)).then((employees) => {
                const data = orderBy(employees, [ 'name' ], [ 'asc' ]);
                return keyBy(data, 'id');
            }).catch((e) => {
                console.error(e);
            });

            ctrl.employees = Object.values(employees);
            ctrl.positions = ctrl.employees
                .reduce((arr: any, employee: any) => arr.concat(employee.positions), []) // Just add all positions to an array
                .filter((pos: any) => pos != null); // Remove undefineds
            // Order the positions so it looks nice
            ctrl.positions = uniqBy(orderBy(ctrl.positions, (p) => p.name.toLowerCase(), 'asc'), 'name');
        };
        ctrl.selectPositions = () => {
            ItemSelectorDialog.open({
                title: t('POSITIONS'),
                items: ctrl.positions,
                displayKey: 'name',
                multiSelect: true,
                selectedItems: ctrl.filterPositions,
            }).then((positions: any) => {
                ctrl.filterPositions = positions;
                ctrl.fullRender();
            });
        };
    } ],
});
