// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';

module('eaw.calendar').component('eawYearCalendarOld', {
    template: `<eaw-year-month-old ng-if="$ye.months" ng-repeat="month in ::$ye.months track by month.index" year="$ye.year" month="month.index"></eaw-year-month-old>
`,
    controllerAs: '$ye',
    bindings: {
        year: '<',
        highlightFrom: '<?',
        highlightTo: '<?',
    },
    require: {
        calendar: '?^eawCalendarOld',
    },
    controller: [ '$element', '$timeout', function($element, $timeout) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.element = $element[0];
            ctrl.weekdays = moment.weekdaysMin(true);

            ctrl.setMonths();
        };

        ctrl.$onChanges = (changes) => {
            ctrl.year = changes?.year?.currentValue;

            ctrl.saveDayElements();
        };

        ctrl.setMonths = () => {
            let months = moment.months().reduce((arr, month, i) => {
                arr.push({
                    index: i,
                    name: month,
                });

                return arr;
            }, []);

            if (moment.isMoment(ctrl.highlightFrom) && moment.isMoment(ctrl.highlightTo)) {
                const fromMonth = ctrl.highlightFrom.month();
                const toMonth = ctrl.highlightTo.month();

                months = months.filter((month) => fromMonth === month.index || toMonth === month.index);
            }

            ctrl.months = months;
        };

        ctrl.saveDayElements = () => {
            $timeout(() => { // Make stuff inside run next digest
                if (ctrl.element instanceof HTMLElement) {
                    ctrl.yearDayEls = Array.from(ctrl.element.querySelectorAll('eaw-year-day-old:not(.outside-month)'));
                }
            }, 0);
        };

        /**
         * @param {HTMLElement} el
         * @param {Integer} [fallback=1]
         * @returns {number}
         */
        ctrl.getElementDayOfYear = (el, fallback) => {
            const day = el.closest('eaw-year-day-old');

            if (day) {
                return parseInt(day.dataset.dayOfYear);
            }

            return fallback || 1;
        };

        ctrl.onMouseleave = () => {
            ctrl.element.addEventListener('mouseleave', () => {
                ctrl.element.removeEventListener('mousemove', ctrl.onSelecting);
                ctrl.yearDayEls.forEach((el) => el.classList.remove('range-highlight'));
            });
        };

        ctrl.startRangeSelect = (e) => {
            // Reset from and to
            delete ctrl.dayOfYearFrom;
            delete ctrl.dayOfYearTo;

            const canUseSource = ctrl.calendar.createSource?.canUseSource;
            const employeeRequirement = ctrl.calendar.createSource?.createRequiresEmployee ? ctrl.calendar.employee?.id : true;

            if (canUseSource && employeeRequirement) {
                // Initial val
                ctrl.dayOfYearFrom = ctrl.getElementDayOfYear(e.target);

                ctrl.onMouseleave();
                ctrl.element.addEventListener('mousemove', ctrl.onSelecting);
                ctrl.element.addEventListener('mouseup', ctrl.stopRangeSelect);
            }
        };

        ctrl.stopRangeSelect = (e) => {
            e.stopPropagation();

            // Update to
            ctrl.dayOfYearTo = ctrl.getElementDayOfYear(e.target, ctrl.dayOfYearTo);

            // Don't listen anymore
            ctrl.element.removeEventListener('mousemove', ctrl.onSelecting);
            ctrl.element.removeEventListener('mouseup', ctrl.stopRangeSelect);

            // Remove all highlighting
            ctrl.yearDayEls.forEach((el) => el.classList.remove('range-highlight'));

            // Set year and from/to
            const year = moment(ctrl.year, 'YYYY'); // Start of calendar's year
            const from = Math.min(ctrl.dayOfYearFrom, ctrl.dayOfYearTo) - 1; // Subtract one day since we are already on 1
            const to = Math.max(ctrl.dayOfYearFrom, ctrl.dayOfYearTo) - 1; // Subtract one day since we are already on 1
            const diff = Math.abs(from - to);

            if (diff > 0) {
                ctrl.calendar.createSource.create(
                    year.clone().add(from, 'd'),
                    year.clone().add(to, 'd'),
                    ctrl.calendar.employee,
                );
            }
        };

        ctrl.onSelecting = (e) => {
            ctrl.dayOfYearTo = ctrl.getElementDayOfYear(e.target, ctrl.dayOfYearTo);

            let from = ctrl.dayOfYearFrom;
            let to = ctrl.dayOfYearTo;

            // Switch if to is before from
            if (to < from) {
                from = to;
                to = ctrl.dayOfYearFrom;
            }

            ctrl.yearDayEls.forEach((d) => {
                const dayOfYear = d.dataset.dayOfYear;

                if (dayOfYear >= from && dayOfYear <= to) {
                    d.classList.add('range-highlight');
                } else {
                    d.classList.remove('range-highlight');
                }
            });
        };
    } ],
});
