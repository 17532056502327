<eaw-dialog-header>
    <span *ngIf="createMode" title>{{ 'NEW_TIMEPUNCH' | translate:'payroll' | async }}</span>
    <span *ngIf="!createMode" title>{{ 'EDIT_TIMEPUNCH' | translate:'payroll' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content class="tw-flex tw-flex-row tw-items-center tw-gap-4" *ngIf="loading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content class="tw-flex tw-flex-col" *ngIf="!loading">
    <form class="tw-flex tw-gap-4 tw-flex-col" [formGroup]="formGroup">
        <div class="tw-grid tw-grid-cols-2 tw-gap-4">
            <eaw-time-input formControlName="in" [label]="'IN' | translate:'payroll'"></eaw-time-input>
            <eaw-time-input formControlName="out" [label]="'OUT' | translate:'payroll'"></eaw-time-input>
        </div>
        <mat-form-field eawDatePickerOptions>
            <mat-label>{{ 'DATE' | translate | async }}</mat-label>
            <input matInput [matDatepicker]="datePicker" formControlName="date" (dateChange)="dateChange($any($event))" autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field eawDatePickerOptions>
            <mat-label>{{ 'BUSINESS_DATE' | translate | async }}</mat-label>
            <input matInput
                   [matDatepicker]="$any(businessDatePicker)"
                   formControlName="businessDate"
                   [min]="minBusinessDate"
                   [max]="maxBusinessDate"
                   (dateChange)="businessDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="businessDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #businessDatePicker></mat-datepicker>
        </mat-form-field>

        <eaw-autocomplete formControlName="employee"
                          [options]="employeeAutocompleteService.options"
                          [getter]="employeeAutocompleteService.getter({
                                  customerId: data.customerId,
                                  includeExternal: true,
                                  active: businessDate
                              })"
                          [setter]="employeeAutocompleteService.setter({customerId: data.customerId})">
        </eaw-autocomplete>

        <mat-form-field *ngIf="businessUnits">
            <mat-label>{{ 'BUSINESS_UNIT' | translate | async }}</mat-label>
            <mat-progress-spinner matPrefix class="tw-mr-4" strokeWidth="3" diameter="24" *ngIf="loadingBusinessUnits" mode="indeterminate"></mat-progress-spinner>
            <mat-select formControlName="businessUnit">
                <mat-option [value]="null">{{ 'NONE' | translateSync }}</mat-option>
                <mat-option *ngFor="let unit of businessUnits" [value]="unit">
                    <span *ngIf="unit.default">{{ unit.name }} - {{ 'DEFAULT_UNIT' | translateSync:'payroll' }}</span>
                    <span *ngIf="!unit.default">{{ unit.name }}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'COMMENT' | translate | async }}</mat-label>
            <textarea matInput
                      cdkAutosizeMinRows="3"
                      cdkAutosizeMaxRows="3"
                      cdkTextareaAutosize
                      formControlName="comment"
                      [required]="commentRequired || documentServiceEnabled"
            >
            </textarea>
        </mat-form-field>

        @if (timepunch?.timeEdited) {
            <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                <eaw-time-input formControlName="originalIn" [label]="'ORIGINAL_TIME_IN' | translate:'payroll'"></eaw-time-input>
                <eaw-time-input formControlName="originalOut" [label]="'ORIGINAL_TIME_OUT' | translate:'payroll'"></eaw-time-input>
            </div>
        }
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="undefined">{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [loading]="loading || fetching" (click)="handleTimepunch()" [disabled]="formGroup.invalid || formGroup.disabled">
        <span *ngIf="!createMode">{{ 'EDIT_TIMEPUNCH' | translate:'payroll' | async }}</span>
        <span *ngIf="createMode">{{ 'CREATE_TIMEPUNCH' | translate:'payroll' | async }}</span>
    </eaw-action-button>
</mat-dialog-actions>
