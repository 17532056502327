import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { VlhPeriod, VlhPeriodResponse } from '../models/vlh-period';

@Injectable({
    providedIn: 'root',
})
export class VlhService {
    private http = inject(HttpClient);

    getAllPeriods(customerId: number) {
        return this.http.get<{ day: number, periods: VlhPeriodResponse[] }[]>(`customers/${customerId}/vlh_periods`).pipe(
            map((response) => {
                return response.reduce<VlhPeriod[]>((acc, item) => {
                    item.periods.forEach((period) => {
                        acc.push(new VlhPeriod(item.day, period));
                    });

                    return acc;
                }, []);
            }),
        );
    }

    savePeriods(customerId: number, periods: {day: number, periods: VlhPeriodResponse[]}[]) {
        return this.http.post(`customers/${customerId}/vlh_periods`, {
            vlh_periods: periods,
        });
    }
}
