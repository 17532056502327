import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Locale, LocaleResponse } from '../models/locale';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';

interface CreateLocaleData {
    name: string;
    code: string;
}

@Injectable({
    providedIn: 'root',
})
export class LocaleService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(options: PaginationOptions = {}): Observable<ArrayPaginatedResponse<Locale>> {
        return this.http.get<ArrayPaginatedResponse<LocaleResponse>>('/locales', { params: { ...options } }).pipe(classifyArrayPaginatedResponse(Locale));
    }

    create(data: CreateLocaleData) {
        return this.http.post<LocaleResponse>(`/locales`, data).pipe(classifyItem(Locale));
    }

    update(code: string, name: string) {
        return this.http.put<LocaleResponse>(`/locales/${code}`, { name }).pipe(classifyItem(Locale));
    }

    delete(code: string): Observable<string> {
        return this.http.delete<string>(`/locales/${code}`);
    }
}
