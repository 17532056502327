// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';
import { Namespace } from '../shared/enums/namespace';
import { CurrentOld } from '../shared/angularjs/current.factory';

module('eaw.projections').config([ '$stateProvider', ($stateProvider: any) => {
    $stateProvider.state(`eaw/app/projections_date_selector`, {
        url: '/projections',
        parent: 'eaw/app',
        views: {
            'content@': 'projectionsDateSelector',
        },
        data: {
            i18nextNs: [ Namespace.Projections ],
            breadcrumb: { key: 'PROJECTIONS', ns: 'navigation' },
        },
    });

    $stateProvider.state(`eaw/app/projections_date_selector/date`, {
        url: '/:date',
        parent: `eaw/app/projections_date_selector`,
        views: {
            'dateProjections@^': 'projectionsDate',
        },
        resolve: {
            date: [ '$transition$', function($transition$) {
                return new Promise((resolve, reject) => {
                    const date = moment($transition$.params().date, 'YYYY-MM-DD');

                    if (date.isValid()) {
                        resolve(date);
                    } else {
                        reject();
                    }
                });
            } ],
            projections: [ 'projectionsFactory', '$transition$', function(projectionsFactory, $transition$) {
                return projectionsFactory.get.query(CurrentOld.customer.id, $transition$.params().date).$promise.then((data) => data).catch(() => []);
            } ],
        },
        data: {
            breadcrumb: { key: 'DATE' },
        },
    });
} ]);
