<eaw-dialog-header>
    <span title>{{ widgetInfo().translationKey | translate:widgetInfo().translationNs | async }}</span>
    <span subtitle>{{ 'SETTING_plural' | translate | async }}</span>
</eaw-dialog-header>

@if (loading()) {
    <mat-dialog-content id="loading-content">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </mat-dialog-content>
} @else {
    <mat-dialog-content id="settings-content">
        <ng-content select="[content]"></ng-content>
    </mat-dialog-content>
}

<mat-dialog-actions>
    <button mat-button mat-dialog-close="">{{ 'CANCEL' | translate | async }}</button>
    <eaw-action-button [disabled]="!valid() || loading()" [loading]="false" (click)="submitted.emit()">{{ 'USE_SELECTED_SETTING_plural' | translate | async }}</eaw-action-button>
</mat-dialog-actions>
