import { UserOld } from './user-old';
import { Me, MeCustomer, MeResponse } from '../models/me';
import CustomerOld from './customer-old';
import type { CurrentService } from '../services/current.service';
import { Product } from '../models/product';
import { ProductName } from '../enums/products';

/** @deprecated */
export class CurrentOld {
    static me: Me;
    static user: UserOld;
    static employee: any;
    static products: Product[];
    static customer: CustomerOld;
    static customers: Record<number, MeCustomer>;
    static store: CurrentService['store'];
    static retrieve: CurrentService['retrieve'];

    static hasProduct(product: string) {
        return this.products.map(p => p.name.toLowerCase()).includes(product.toLowerCase());
    }

    static setProducts(products: Product[]) {
        this.products = products;
    }

    static get languageTag() {
        return this.me?.user.language?.languageTag || 'en';
    }

    static setMe(me: Me) {
        this.me = me;
    }

    static getMe() {
        return this.me;
    }

    static setUser(user: UserOld) {
        this.user = user;
    }

    static getUser() {
        return this.user;
    }

    static setEmployee(employee: any) {
        this.employee = employee;
    }

    static getEmployee() {
        return this.employee;
    }

    static setCustomers(me: MeResponse) {
        this.customers = Object.values(me.customers).reduce((acc, customer) => {
            acc[customer.id] = customer;
            return acc;
        }, {} as Record<number, MeCustomer>);
    }

    static setCustomer(customer: CustomerOld) {
        if (!customer?.['id']) {
            return;
        }

        this.customer = customer;
    }

    /**
     * Returns current customer or customer with passed in ID
     */
    static getCustomer(): CustomerOld;
    static getCustomer(customerId?: number): MeCustomer | undefined;
    static getCustomer(customerId?: number): CustomerOld | MeCustomer | undefined {
        if (!customerId) {
            return this.customer;
        }

        return this.customers[customerId];
    }

    /**
     * Returns all customers
     * Make sure to clone the objects if you want to modify any of them, or use them in an angularJS template.
     */
    static getCustomers() {
        return this.customers;
    }

    static can(permission: string, params?: object, force?: boolean) {
        return this.user?.can(this.replacePlaceholders(permission, params), force);
    }

    static replacePlaceholders(permission: string, params: any = null) {
        if (params) {
            Object.entries(params).forEach(([ key, v ]: [string, any]) => {
                if (v == null) {
                    return;
                }

                permission = permission.replace(`{${key}}`, v);
            });
        }

        if (this.customer['id']) {
            permission = permission.replace('{customer}', String(this.customer['id']));
        }

        if (this.customer?.setting_group_id) {
            permission = permission.replace('{customer.setting_group_id}', String(this.customer.setting_group_id));
        }

        if (this.employee?.id && permission.includes('{employee}')) {
            permission = permission.replace('{employee}', this.employee.id);
        }

        if (this.user?.['id'] && permission.includes('{user}')) {
            permission = permission.replace('{user}', this.user['id']);
        }

        return permission;
    }

    static permissionChildren(prefix: string, suffix = 'get', value = true) {
        const perms = this.user?.permissions || {};

        prefix = prefix ? prefix + '.' : prefix;
        suffix = suffix ? '.' + suffix : suffix;

        return Object.keys(perms).filter((node) => node.startsWith(prefix) && node.endsWith(suffix) && perms[node].value === value);
    }
}
