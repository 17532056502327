import { AbsenceType, AbsenceTypeResponse } from '../../absence/models/absence-type';
import { Absence, AbsenceResponse } from '../../absence/models/absence';
import { Customer, CustomerResponse } from '../../shared/models/customer';
import { Employee, EmployeeResponse } from '../../shared/models/employee';
import { OffTime, OffTimeResponse } from '../../vacations/models/off-time';
import { PaidTime, PaidTimeResponse } from './paid-time';
import { Shift, ShiftResponse } from '../../scheduling/models/shift';
import { Timepunch, TimepunchResponse } from '../../payroll/models/timepunch';
import { DateTime } from 'luxon';
import { getOverlap } from '../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { PaidTimeSegment, PaidTimeSegmentData } from './paid-time-segment';
import { PaidTimeSegmentTypes } from '../types/paid-time-segment-types';
import { LeaveShift, LeaveShiftResponse } from '../../leave-shifts/models/leave-shift';

export type PaidTimeModel = Absence | OffTime | PaidTime | Shift | Timepunch | LeaveShift;

type SegmentTime = {
    item: PaidTimeModel,
    time: DateTime,
    type: 'start' | 'end',
};

export interface PaidTimeOverviewResponse {
    absence_types: AbsenceTypeResponse[];
    absences: AbsenceResponse[];
    customers: CustomerResponse[];
    employees: EmployeeResponse[];
    offtimes: OffTimeResponse[];
    paid_times: PaidTimeResponse[];
    shifts: ShiftResponse[];
    timepunches: TimepunchResponse[];
    leave_shifts: LeaveShiftResponse[];
}

export interface PaidTimeOverviewData {
    absence_types: AbsenceType[];
    absences: Absence[];
    customers: Customer[];
    employees: Employee[];
    offtimes: OffTime[];
    paid_times: PaidTime[];
    shifts: Shift[];
    timepunches: Timepunch[];
    leaveShifts: LeaveShift[];
}

export class PaidTimeOverview {
    readonly _originalData: PaidTimeOverviewData;
    readonly now = DateTime.now();
    readonly customerId: number;
    readonly customer: Customer | undefined;
    readonly _filter: {
        employeeId: number | undefined,
        from: DateTime,
        to: DateTime,
        fromMs: number,
        toMs: number,
    };

    absenceTypes: AbsenceType[];
    // _All_ Absences
    absences: Absence[];
    // Absences of the type day, e.g. sick leave or some long illness
    dayAbsences: Absence[];
    // Absences of the type hour, short absence
    hourAbsences: Absence[];
    customers: Customer[];
    leaveShifts: LeaveShift[];
    employees: Employee[];
    employee: Employee | null | undefined;
    // _All_ offtimes
    offtimes: OffTime[];
    // Only offtimes that are vacations
    vacations: OffTime[];
    notVacations: OffTime[];
    paidTimes: PaidTime[];
    shifts: Shift[];
    timepunches: Timepunch[];
    timeSegments: SegmentTime[] = [];

    constructor(customerId: number, data: PaidTimeOverviewData, filter: { employeeId?: number, from: DateTime, to: DateTime }) {
        // Keep original data
        this._originalData = data;

        // Filter that will be applied to the paid time overview
        // Filter should encompass the whole day
        this._filter = {
            employeeId: filter?.employeeId,
            from: filter.from.startOf('day'),
            to: filter.to.endOf('day'),
            fromMs: filter.from.startOf('day').toMillis(),
            toMs: filter.to.endOf('day').toMillis(),
        };

        // Things that are not filtered
        this.absenceTypes = data.absence_types;
        this.customers = data.customers;
        this.employees = data.employees;

        // Save the customer id where we got overview from
        this.customerId = customerId;
        this.customer = this.customers.find((c) => c.id === this.customerId);

        // Set employee if filter has an employee id
        this.employee = this._filter.employeeId ? this.employees.find((e) => e.id === this._filter.employeeId) : null;

        // Absences
        this.absences = data.absences
            .map((a) => { // Attach the absence type to the absences before we filter them
                a.type = this.absenceTypes.find((at) => at.id === a.typeId);
                return a;
            }).filter(this.applyFilters.bind(this));

        this.dayAbsences = this.absences.filter((a) => a.type?.span === 'day');
        this.hourAbsences = this.absences.filter((a) => a.type?.span === 'hour');

        // Offtimes
        this.offtimes = data.offtimes.filter(this.applyFilters.bind(this));
        this.vacations = this.offtimes.filter((o) => o.vacation);
        this.notVacations = this.offtimes.filter((o) => !o.vacation);

        // Paid times
        this.paidTimes = data.paid_times.filter(this.applyFilters.bind(this));

        // Leave shifts
        this.leaveShifts = data.leaveShifts.filter(this.applyFilters.bind(this));

        // Shifts
        this.shifts = data.shifts.filter(this.applyFilters.bind(this));

        // Timepunches
        this.timepunches = data.timepunches.filter(this.applyFilters.bind(this));

        this.timeSegments = this.getTimeSegments();
    }

    getSegments() {
        const base = [
            ...this.getNonModelSegments(),
            ...this.getPaidTimeSegments(),
            ...this.getLeaveShiftSegments(),
            ...this.getUpcomingShiftSegments(),
            ...this.getNonVacationSegments(),
            ...this.getHourAbsenceSegments(),
        ].sort((a, b) => a.from.toMillis() - b.from.toMillis());

        base.unshift(...this.getDayAbsenceSegments());
        base.unshift(...this.getVacationSegments());

        return base;
    }

    getVacationSegments() {
        const customer = this.customer;
        if (!customer) {
            return [];
        }

        // Get vacations that have an overlap greater than zero
        const vacations = this.vacations.filter((v) => {
            if (!(this._filter?.fromMs && this._filter?.toMs)) {
                return true;
            }
            return getOverlap(+v.from, +v.to, this._filter.fromMs || 0, this._filter.toMs || 0) > 0;
        });

        return vacations.map((v) => {
            return new PaidTimeSegment('Vacation', {
                customer,
                employee: this.employee,
                from: v.from,
                to: v.to,
                offTime: v,
                isPunchedIn: false,
            });
        });
    }

    getDayAbsenceSegments() {
        const customer = this.customer;
        if (!customer) {
            return [];
        }

        // Get day absences that have an overlap greater than zero
        const dayAbsences = this.dayAbsences.filter((a) => {
            if (!(this._filter?.fromMs && this._filter?.toMs)) {
                return true;
            }
            return getOverlap(+a.from, +a.to, this._filter.fromMs, this._filter.toMs) > 0;
        });

        return dayAbsences.map((a) => {
            return new PaidTimeSegment('DayAbsence', {
                customer,
                employee: this.employee,
                from: a.from,
                to: a.to,
                absence: a,
                isPunchedIn: false,
            });
        });
    }

    getHourAbsenceSegments() {
        const absences: PaidTimeSegment[] = [];

        this.hourAbsences.forEach((a) => {
            this.appendPaidTimeSegment(absences, a.from, a.to, 'HourAbsence', { absence: a });
        });

        return absences;
    }

    getNonVacationSegments() {
        const offtimes: PaidTimeSegment[] = [];

        this.notVacations.forEach((o) => {
            this.appendPaidTimeSegment(offtimes, o.from, o.to, 'Offtime', { offTime: o });
        });

        return offtimes;
    }

    getUpcomingShiftSegments() {
        const upcomingShifts: PaidTimeSegment[] = [];

        this.shifts.filter((s) => s.from > this.now).forEach((s) => {
            this.appendPaidTimeSegment(upcomingShifts, s.from, s.to, 'UpcomingShift', {
                shift: s,
                businessDate: s.businessDate?.dateTime,
            });
        });

        return upcomingShifts;
    }

    getPaidTimeSegments() {
        const paidTimes: PaidTimeSegment[] = [];

        this.paidTimes.forEach((p) => {
            this.appendPaidTimeSegment(paidTimes, p.from, p.to, 'PaidTime', {
                paidTime: p,
                businessDate: p.businessDate.dateTime,
            });
        });

        return paidTimes;
    }

    getLeaveShiftSegments() {
        const paidTimes: PaidTimeSegment[] = [];

        this.leaveShifts.forEach((ls) => {
            this.appendPaidTimeSegment(paidTimes, ls.from, ls.to, 'LeaveShift', {
                leaveShift: ls,
                businessDate: ls.businessDate.dateTime,
            });
        });

        return paidTimes;
    }

    getNonModelSegments() {
        const segments: PaidTimeSegment[] = [];

        const activeTimepunch = this.timepunches.find((t) => t.isPunchedIn);
        let timepunch: Timepunch | null = null;
        let paidTime: PaidTime | null = null;
        let shift: Shift | null = null;
        let absence: Absence | null = null;
        let inPaidTime = false;
        let inShift = false;
        let inTimepunch = false;
        let inAbsence = false;

        this.timeSegments.forEach((segment, index, array) => {
            // Skip future segments
            if (segment.time > this.now) {
                return;
            }

            const next = array[index + 1];
            if (!next) {
                return;
            }

            if (segment.item instanceof Absence) {
                inAbsence = segment.type === 'start';
                absence = inAbsence ? segment.item : null;
            }

            if (segment.item instanceof Shift) {
                inShift = segment.type === 'start';
                shift = inShift ? segment.item : null;
            }

            if (segment.item instanceof Timepunch) {
                inTimepunch = segment.type === 'start';
                timepunch = inTimepunch ? segment.item : null;
            }

            if (segment.item instanceof PaidTime) {
                inPaidTime = segment.type === 'start';
                paidTime = inPaidTime ? segment.item : null;
            }

            const data: Partial<PaidTimeSegmentData> = {
                timepunch,
                paidTime,
                shift,
                absence,
                isPunchedIn: activeTimepunch ? activeTimepunch.in < segment.time : false,
            };

            // Case for suggested paid time
            if (inShift && inTimepunch && !inPaidTime) {
                this.appendPaidTimeSegment(segments, segment.time, next.time, 'SuggestedPaidTime', data);
            }

            // Case for extra time
            if (!inShift && inTimepunch && !inPaidTime && !inAbsence) {
                this.appendPaidTimeSegment(segments, segment.time, next.time, 'ExtraTime', data);
            }

            // Case for unhandled time
            if (inShift && !inTimepunch && !inPaidTime && !inAbsence) {
                if (segment.item instanceof Shift && segment.type === 'start' && next.item instanceof Shift && next.type === 'end') {
                    this.appendPaidTimeSegment(segments, segment.time, next.time, 'UnhandledShift', data);
                } else {
                    this.appendPaidTimeSegment(segments, segment.time, next.time, 'UnhandledTime', data);
                }
            }
        });

        return segments;
    }

    filter(filter?: { employeeId?: number, from?: DateTime, to?: DateTime }): PaidTimeOverview {
        const data: PaidTimeOverviewData = {
            absence_types: this.absenceTypes,
            absences: this.absences,
            customers: this.customers,
            employees: this.employees,
            offtimes: this.offtimes,
            paid_times: this.paidTimes,
            shifts: this.shifts,
            timepunches: this.timepunches,
            leaveShifts: this.leaveShifts,
        };

        return new PaidTimeOverview(this.customerId, data, {
            employeeId: filter?.employeeId || this._filter.employeeId,
            from: filter?.from || this._filter.from,
            to: filter?.to || this._filter.to,
        });
    }

    private appendPaidTimeSegment(array: PaidTimeSegment[], from: DateTime, to: DateTime, type: keyof typeof PaidTimeSegmentTypes, data?: Partial<PaidTimeSegmentData>) {
        const customer = this.customer;

        if (!customer) {
            return;
        }
        if (+from === +to) {
            return;
        }

        array.push(new PaidTimeSegment(type, {
            customer,
            employee: this.employee,
            from,
            to,
            isPunchedIn: data?.isPunchedIn || false,
            ...data,
        }));
    }

    private getTimeSegments() {
        const times: SegmentTime[] = [
            ...this.hourAbsences,
            ...this.notVacations,
            ...this.paidTimes,
            ...this.shifts,
            ...this.timepunches,
        ]
            .flatMap((item) => this.getTimeItems(item))
            .sort((a, b) => a.time.toMillis() - b.time.toMillis());

        const mappedTimes = new Map<number, SegmentTime[]>();
        times.forEach((t) => {
            const key = +t.time;
            const current = mappedTimes.get(key) || [];

            // Don't add an end to something that also starts at the same time
            if (t.type === 'end' && current.some((x) => x.type === 'start' && x.item.constructor === t.item.constructor)) {
                return;
            }

            mappedTimes.set(key, current.concat(t));
        });

        return Array.from(mappedTimes.values()).flat();
    }

    private getTimeItems(item: PaidTimeModel): SegmentTime[] {
        const first: SegmentTime = {
            item,
            time: item.from,
            type: 'start',
        };

        const last: SegmentTime = item instanceof Timepunch ? {
            item,
            time: item.out || DateTime.now(),
            type: 'end',
        } : {
            item,
            time: item.to,
            type: 'end',
        };

        return [ first, last ];
    }

    private filterSameEmployeeId(item: PaidTimeModel) {
        // If an employee filter is not provided then return all items
        return this._filter.employeeId == null ? true : item.employeeId === this._filter.employeeId;
    }

    private filterOverlappingDate(item: PaidTimeModel): boolean {
        // Day absences are always shown
        if (item instanceof Absence && item.type?.span === 'day') {
            return true;
        }

        // Vacation offtimes are always shown
        if (item instanceof OffTime && item.vacation) {
            return true;
        }

        // Ignore items that start before the filter from
        if (item.from < this._filter.from) {
            return false;
        }

        const itemFrom = item.from.toMillis();
        const itemTo = (item instanceof Timepunch ? item.out || DateTime.now() : item.to).toMillis();

        return getOverlap(this._filter.fromMs, this._filter.toMs, itemFrom, itemTo) > 0;
    }

    private applyFilters(item: PaidTimeModel) {
        return this.filterSameEmployeeId(item) && this.filterOverlappingDate(item);
    }
}
