<eaw-dialog-header>
    <span title>{{ 'REFERENCE_DAY_plural' | translate: 'ai_budgeting' | async }}</span>
    <span subtitle>{{ data.projectionDay.date | DateTime: 'DATE_MED_WITH_WEEKDAY' }}</span>
    @if (data.projectionDay.tag) {
        <div right class="tw-p-12"><strong>{{ 'TAG' | translate: 'ai_budgeting' | async }}:</strong> {{ data.projectionDay.tag }}</div>
    }
</eaw-dialog-header>

@if (loading) {
    <mat-dialog-content class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </mat-dialog-content>
} @else {
    <mat-dialog-content id="details-content" class="tw-flex tw-flex-col">
        <form class="filter-area tw-grid tw-grid-cols-4 tw-gap-8" [formGroup]="formGroup">
            <mat-form-field>
                <mat-label>{{ 'WEEKDAY' | translate | async }}</mat-label>
                <mat-select multiple formControlName="weekdays">
                    @for (day of weekdays; track day) {
                        <mat-option [value]="day">{{ day.toUpperCase() | translateSync }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'TAG' | translate: 'ai_budgeting' | async }}</mat-label>
                <mat-select formControlName="tag">
                    <mat-option>{{ 'NONE' | translateSync }}</mat-option>
                    @for (tag of data.tags; track tag) {
                        <mat-option [value]="tag">{{ tag }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <div class="tw-mt-4">
                <eaw-action-button class="search-button" [loading]="fetching" (click)="searchReferenceDays()">{{ 'SEARCH' | translate | async }}</eaw-action-button>
                @if (formGroup.controls.weekdays.value || formGroup.controls.tag.value) {
                    <button mat-icon-button (click)="clearForm()">
                        <mat-icon>clear</mat-icon>
                    </button>
                }
            </div>

        </form>
        <div class="tw-flex tw-overflow-auto tw-gap-8">
            <div class="tw-flex tw-overflow-auto tw-flex-col tw-flex-1 table-wrapper">
                <h3 class="tw-p-16 tw-m-0">{{ 'SELECTED_DAYS' | translate: 'ai_budgeting' | async }}</h3>
                <div class="tw-flex tw-overflow-auto tw-flex-col">
                    <table mat-table [dataSource]="dataSourceChosen">
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef class="tw-pr-0"></th>
                            <td mat-cell *matCellDef="let item" (click)="unSelectDate(item)" class="tw-pr-0">
                                <mat-checkbox readonly [ngModel]="true"></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef>{{ 'DAY_plural' | translate: 'ai_budgeting' | async }}</th>
                            <td mat-cell *matCellDef="let item" (click)="unSelectDate(item)">{{ item.date | DateTime: 'DATE_MED_WITH_WEEKDAY' }}</td>
                        </ng-container>
                        <ng-container matColumnDef="tag">
                            <th mat-header-cell *matHeaderCellDef>{{ 'TAG_plural' | translate: 'ai_budgeting' | async }}</th>
                            <td mat-cell *matCellDef="let item" (click)="unSelectDate(item)">{{ item.tag }}</td>
                        </ng-container>
                        <ng-container matColumnDef="comment">
                            <th mat-header-cell *matHeaderCellDef>{{ 'COMMENT_plural' | translate: 'ai_budgeting' | async }}</th>
                            <td mat-cell *matCellDef="let item" (click)="unSelectDate(item)">{{ item.comment }}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="tw-sticky"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell tw-pt-16 tw-pb-16" colspan="2">{{ 'NO_DATA' | translate | async }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="tw-flex tw-overflow-auto tw-flex-col tw-flex-1 table-wrapper">
                <h3 class="tw-p-16 tw-m-0">{{ 'AVAILABLE_DAYS' | translate: 'ai_budgeting' | async }}</h3>
                <div class="tw-flex tw-overflow-auto tw-flex-col">
                    <table mat-table [dataSource]="dataSourceAvailable">
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef class="tw-pr-0"></th>
                            <td mat-cell *matCellDef="let item" (click)="selectDate(item)" class="tw-pr-0">
                                <mat-checkbox readonly></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef>{{ 'DAY_plural' | translate: 'ai_budgeting' | async }}</th>
                            <td mat-cell *matCellDef="let item" (click)="selectDate(item)">{{ item.date | DateTime: 'DATE_MED_WITH_WEEKDAY' }}</td>
                        </ng-container>
                        <ng-container matColumnDef="tag">
                            <th mat-header-cell *matHeaderCellDef>{{ 'TAG_plural' | translate: 'ai_budgeting' | async }}</th>
                            <td mat-cell *matCellDef="let item" (click)="selectDate(item)">{{ item.tag }}</td>
                        </ng-container>
                        <ng-container matColumnDef="comment">
                            <th mat-header-cell *matHeaderCellDef>{{ 'COMMENT_plural' | translate: 'ai_budgeting' | async }}</th>
                            <td mat-cell *matCellDef="let item" (click)="selectDate(item)">{{ item.comment }}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="tw-sticky"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell tw-pt-16 tw-pb-16" colspan="2">{{ 'NO_DATA' | translate | async }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </mat-dialog-content>
}

<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'CLOSE' | translate | async }}</button>
    <eaw-action-button type="submit" (click)="saveReferenceDays()" [loading]="fetching" [disabled]="loading || fetching || selectedDates.length === 0">{{ 'SAVE' | translate | async }}</eaw-action-button>
</mat-dialog-actions>
