<mat-card>
    <mat-card-header class="tw-flex tw-items-center tw-mb-16">
        <eaw-profile-picture class="tw-m-0" mat-card-avatar [user]="user"></eaw-profile-picture>
        <span mat-card-title>{{ user.firstName }} {{ user.lastName }}</span>
    </mat-card-header>

    <mat-card-content>
        @if (canUpdate && user.lastActive) {
            <eaw-info-box color="blue" icon="info" class="tw-mb-16">
                <span infoText>{{ 'CANT_EDIT_ACTIVATED_USER' | translate: 'company' | async }}</span>
            </eaw-info-box>
        }

        @if (form) {
            <form [formGroup]="form" autocomplete="off" class="tw-grid tw-grid-cols-2 tw-mt-8 tw-gap-8">
                <mat-form-field>
                    <mat-label>{{'FIRST_NAME' | translate | async}}</mat-label>
                    <input matInput formControlName="firstName" placeholder="{{'FIRST_NAME' | translate | async}}">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'LAST_NAME' | translate | async}}</mat-label>
                    <input matInput formControlName="lastName" placeholder="{{'LAST_NAME' | translate | async}}">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'EMAIL' | translate | async}}</mat-label>
                    <input type="email" matInput formControlName="email" placeholder="{{'EMAIL' | translate | async}}">
                    @if (user.emailChange) {
                        <mat-hint [eawMaterialColor]="'red-900'" colorProperty="backgroundColor">
                            {{ 'PENDING_EMAIL_CHANGE' | translate: 'company': { email: user.emailChange.email } | async }}
                        </mat-hint>
                    }
                </mat-form-field>

                <eaw-autocomplete formControlName="language"
                                  [getter]="languageAutocomplete.getter()"
                                  [setter]="languageAutocomplete.setter()"
                                  [options]="languageAutocomplete.options">
                </eaw-autocomplete>

                <eaw-basic-autocomplete
                        [label]="'DIAL_CODE' | translate"
                        [getList]="getDialCodes"
                        [optionTemplate]="dialCodeTemplate"
                        [display]="dialCodeDisplay"
                        formControlName="dialCodeCountry">
                </eaw-basic-autocomplete>

                <mat-form-field>
                    <mat-label>{{'PHONE' | translate | async}}</mat-label>
                    <input matInput type="tel" formControlName="phoneNumber" placeholder="{{'PHONE' | translate | async}}">
                </mat-form-field>

                @if (canUpdate) {
                    <mat-card-actions>
                        <eaw-action-button type="submit" [disabled]="!!user.lastActive || form.invalid" [loading]="updating" (click)="updateUser()">
                            {{ 'UPDATE' | translate | async }}
                        </eaw-action-button>
                    </mat-card-actions>
                }
            </form>
        }
    </mat-card-content>
</mat-card>

<ng-template #dialCodeTemplate let-country>
    <span>{{ country.name }} ({{ country.Dial }})</span>
</ng-template>
