import { Inject, Injectable } from '@angular/core';
import { Autocomplete } from './autocomplete';
import { Customer } from '../models/customer';
import { CustomerService } from '../http/customer.service';
import { TranslateService } from '../services/translate.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomerAutocompleteService extends Autocomplete<Customer> {

    constructor(
      @Inject(CustomerService) protected customerService: CustomerService,
      @Inject(TranslateService) protected translateService: TranslateService,
    ) {
        super({
            label: translateService.t('CUSTOMER'),
            optionTemplate: 'default',
            filterRequirement: 3,
            display: 'name',
            trackByKey: 'id',
        });
    }

    setter() {
        return (item: unknown) => {
            if (item instanceof Customer) {
                return of(item);
            }
            if (typeof item !== 'number') {
                return of(undefined);
            }

            return this.customerService.get(item);
        };
    }

    getter() {
        return (filter?: string) => {
            return this.customerService.getAll({
                filter,
                per_page: 20,
                'fields[]': [ 'name' ],
            });
        };
    }
}
