// @ts-nocheck
import { module } from 'angular';
import { Namespace } from '../shared/enums/namespace';
import { Products } from '../shared/enums/products';

module('eaw.coupons', [
    'eaw.resource',
    'eaw.employees',
]).run([ '$stateRegistry', '$urlService', ($stateRegistry, $urlService) => {
    const statuses = [ 'unavailable', 'used', 'expired' ];

    $urlService.config.type('couponStatus', {
        encode: (str) => str,
        decode: (str) => str,
        is: (val) => typeof (val) === 'string' && statuses.find((v) => v == val),
        pattern: /[^/]+/,
        equals: (a, b) => a === b,
    });

    $stateRegistry.register({
        name: 'eaw/app/coupons',
        parent: 'eaw/app',
        abstract: true,
        url: '/coupons',
        data: {
            products: [ Products.Coupons ],
            i18nextNs: [ Namespace.Coupons ],
            breadcrumb: { key: 'COUPONS', ns: 'navigation' },
        },
    });

    $stateRegistry.register({
        name: 'eaw/app/coupons/my',
        parent: 'eaw/app/coupons',
        url: '/my?status',
        params: {
            status: {
                squash: true,
                type: 'couponStatus',
                dynamic: true,
            },
        },
        data: {
            requiresEmployee: true,
            permissions: [ `customers.{customer}.employees.{employee}.coupons.*.get` ],
            breadcrumb: { key: 'MY_COUPONS', ns: 'navigation' },
        },
        views: {
            'content@': {
                component: 'myCoupons',
            },
        },
        resolve: {
            coupons: [ 'CouponFactory', 'employee', function(CouponFactory, employee) {
                return CouponFactory.getEmployeeCoupons(employee, {
                    get_used: true,
                });
            } ],
        },
    });
} ]);
