import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogComponent, ErrorDialogData } from './error-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ErrorDialogService {

    constructor(@Inject(MatDialog) private dialog: MatDialog) { }

    open(data: ErrorDialogData): MatDialogRef<ErrorDialogComponent> {
        return this.dialog.open(ErrorDialogComponent, {
            data,
            disableClose: true,
        });
    }
}
