import { ApiResponse } from '../../shared/interfaces/api-response';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
export interface ReferenceDayResponse extends ApiResponse {
    date: string;
    tag: string;
    comment: string;
}

export class ReferenceDay {
    comment: string;
    tag: string;
    date: DateTime;

    constructor(data: ReferenceDayResponse) {
        this.comment = data.comment;
        this.tag = data.tag;
        this.date = stringToDateTime(data.date, true,'yyyy-MM-dd');
    }
}
