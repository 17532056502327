// @ts-nocheck
import { pick } from 'lodash-es';
import { module } from 'angular';
module('eaw.admin').factory('TariffRuleFactory', [ 'EawResource', function(EawResource) {
    const f = this;
    f.resource = EawResource.create('/setting_groups/:group/tariffs/:tariff/rules/:rule');
    f.create = {
        permission: (group, tariff) => `setting_groups/${group}/tariffs.${tariff}.rules.create`,
        query: (args) => f.resource.save(pick(args, [ 'group', 'tariff', 'rule' ]), pick(args, [ 'name', 'value' ])),
    };
    f.delete = {
        permission: (group, tariff, rule) => `setting_groups/${group}/tariffs.${tariff}.rules.${rule}.delete`,
        query: (args) => f.resource.delete(pick(args, [ 'group', 'tariff', 'rule' ]), {}),
    };
    return f;
} ]);
