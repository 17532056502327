import { Inject, Injectable } from '@angular/core';
import { OpeningHours, OpeningHoursResponse } from '../models/opening-hours';
import { map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { classifyItem } from '../../shared/utils/rxjs/classify';

@Injectable({
    providedIn: 'root',
})
export class OpeningHoursService {
    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {}

    getAll(customerId: number) {
        return this.http.get<OpeningHoursResponse[]>(`/customers/${customerId}/opening_hours`).pipe(
            map((response) => {
                return response.map((openingHours) => new OpeningHours(openingHours));
            }),
        );
    }

    /** Create a new opening hour */
    create(customerId: number, day: number, from: string, to: string) {
        return this.http.post<OpeningHoursResponse>(`/customers/${customerId}/opening_hours`, {
            day,
            open_from: from,
            open_to: to,
        }).pipe(classifyItem(OpeningHours));
    }

    /** Update an existing opening hour */
    update(customerId: number, dayId: number, from: string, to: string) {
        return this.http.put<OpeningHoursResponse>(`/customers/${customerId}/opening_hours/${dayId}`, {
            open_from: from,
            open_to: to,
        }).pipe(classifyItem(OpeningHours));
    }

    /** Remove an opening hour */
    delete(customerId: number, hoursId: number) {
        return this.http.delete(`/customers/${customerId}/opening_hours/${hoursId}`);
    }
}
