import { module } from 'angular';

class NightWorkerExtendedInfoContainer {
    $mdDialog;
    static $inject = [ '$mdDialog' ];

    constructor($mdDialog: any) {
        this.$mdDialog = $mdDialog;
    }

    close() {
        this.$mdDialog.hide();
    }
}

module('eaw.dashboard').component('nightWorkerExtendedInfoContainer', {
    template: `<night-worker-widget-info-downgrade class="tw-p-16" (closing)="$info.close()"></night-worker-widget-info-downgrade>`,
    bindings: {},
    controllerAs: '$info',
    controller: NightWorkerExtendedInfoContainer,
});
