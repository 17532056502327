import { DataTableColumn } from './data-table-column';
import { ProfilePictureUser } from '../../shared/components/profile-picture/profile-picture.component';
import { ProfilePicturePortalComponent } from '../portals/profile-picture-portal/profile-picture-portal.component';
import { DataTableHeader } from './data-table-header';
import { DataTableFunctionValue } from '../interfaces/data-table-value';

interface IDataTableProfilePictureColumn<Item> {
    header?: DataTableHeader;
        user: DataTableFunctionValue<DataTableProfilePictureColumn<Item>, Item, ProfilePictureUser | undefined>;
}

export class DataTableProfilePictureColumn<Item> extends DataTableColumn<DataTableProfilePictureColumn<Item>, Item, void> {
    component = ProfilePicturePortalComponent;
    user: IDataTableProfilePictureColumn<Item>['user'];

    constructor(column: IDataTableProfilePictureColumn<Item>) {
        super({
            exportable: false,
            header: column.header || new DataTableHeader({ text: '' }),
            ...column,
        });

        this.user = column.user;
    }
}
