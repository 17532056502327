import { module } from 'angular';
import { EmployeeShiftsComponent } from '../pages/employee-shifts/employee-shifts.component';
import { NavTabsComponent } from '../../shared/components/nav-tabs/nav-tabs.component';
import { navTabsTabsResolver } from '../../shared/resolvers/nav-tabs-tabs.resolver';
import { CurrentService } from '../../shared/services/current.service';
import { createNavTabsState, createState } from '../../shared/utils/create-state';
import { ScheduleShiftChangesComponent } from '../pages/schedule-shift-changes/schedule-shift-changes.component';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { EmployeeShiftSwapsComponent } from '../pages/employee-shift-swaps/employee-shift-swaps.component';
import { DataTableQueryParams } from '../../data-table/data-table.component';
import { ApiModel } from '../../shared/enums/api-model';
import { PermissionOptions } from '../../shared/services/permission-check.service';

module('eaw').config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: `eaw/app/scheduling/shifts/my`,
        parent: `eaw/app/scheduling`,
        url: '/my',
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        data: {
            breadcrumb: { key: 'SHIFTS', ns: 'navigation' },
            somePermissions: [
                'customers.{customer}.employees.{employee}.shifts.*.get',
                'customers.{customer}.schedules.*.shifts.*.get',
                'customers.{customer}.employees.{employee}.shift_swaps.*.get', // Get employee swaps
            ],
            requiresEmployee: true,
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('employeeId', 'employee', 'id'),
            navTabsTabsResolver(function($transition$) {
                const current = $transition$.injector().get(CurrentService) as CurrentService;
                const customerId = current.getCustomer().id;
                const stackId = current.getCustomer().stackId;
                const employeeId = current.getEmployee()?.id;

                if (!employeeId) {
                    throw new Error('State Requires Employee');
                }

                const options: PermissionOptions = {
                    stackId,
                    models: [
                        {
                            type: ApiModel.Customer,
                            id: customerId,
                        },
                        {
                            type: ApiModel.Employee,
                            id: employeeId,
                        },
                    ],
                };
                return Promise.resolve([
                    {
                        state: 'eaw/app/scheduling/shifts/my/list',
                        label: {
                            key: 'MY_SHIFTS',
                            ns: 'navigation',
                        },
                        permissions: [
                            [ `customers.[${ApiModel.Customer}].employees.[${ApiModel.Employee}].shifts.*.get`, options ],
                        ],
                    },
                    {
                        state: 'eaw/app/scheduling/shifts/my/changes',
                        label: {
                            key: 'CHANGES',
                            ns: 'navigation',
                        },
                        permissions: [ [ `customers.[${ApiModel.Customer}].employees.[${ApiModel.Employee}].shifts.*.get`, options ] ],
                    },
                    {
                        state: 'eaw/app/scheduling/shifts/my/swaps',
                        label: {
                            key: 'SHIFT_SWAP_plural',
                            ns: 'scheduling',
                        },
                        permissions: [ [ `customers.[${ApiModel.Customer}].employees.[${ApiModel.Employee}].shift_swaps.*.get`, options ] ],
                    },
                ]);
            }),
        ],
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/scheduling/shifts/my/list`,
        parent: `eaw/app/scheduling/shifts/my`,
        url: '/list',
        component: EmployeeShiftsComponent,
        data: {
            breadcrumb: { key: 'MY_SHIFTS', ns: 'navigation' },
            requiresEmployee: true,
            allowExternal: true,
            somePermissions: [
                `customers.{customer}.employees.{employee}.shifts.*.get`, // Get all employee shifts
                `customers.{customer}.schedules.*.shifts.*.get`, // Get all customer shifts
                `customers.{customer}.employees.{employee}.shift_swaps.*.get`, // Get employee swaps
            ]
        },
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/scheduling/shifts/my/changes`,
        parent: `eaw/app/scheduling/shifts/my`,
        url: '/shift_changes',
        component: ScheduleShiftChangesComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'CHANGES', ns: 'navigation' },
        },
    });
    createNavTabsState($stateProvider, {
        name: `eaw/app/scheduling/shifts/my/swaps`,
        parent: `eaw/app/scheduling/shifts/my`,
        url: '/swaps',
        component: EmployeeShiftSwapsComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'SHIFT_SWAP_plural', ns: 'scheduling' },
        },
    });
} ]);
