<nav mat-tab-nav-bar [tabPanel]="navView" [mat-stretch-tabs]="false">
    @for (tab of tabs; track tab.state) {
        @if (tab.show | async) {
            <a mat-tab-link [class.navbar-title-tab]="tab.text" [active]="activeTab === $index"
               eawPermission
               [permissions]="tab.permissions"
               [somePermissions]="tab.somePermissions"
               [permissionChildrenFilter]="tab.permissionChildrenFilter"
               [permissionChildrenInclude]="tab.permissionChildrenInclude"
               (click)="changeTab($index)" [disabled]="!!tab.disabled">
                @if (tab.text) {
                    <mat-icon>label</mat-icon>
                    <span class="overflow-ellipsis">{{ tab.text | async }}</span>
                } @else if (tab.label) {
                    <span class="overflow-ellipsis">{{ tab.label.key | translate:tab.label.ns | async }}</span>
                }
            </a>
        }
    }
    @if (!tabs.length) {
        <div class="no-tabs tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-8">
            <mat-icon>tab</mat-icon>
            <span class="mat-headline-4">No tabs</span>
        </div>
    }
</nav>

    <mat-tab-nav-panel #navView>
        @if (!isTesting) {
            <ui-view name="nav-view"></ui-view>
        }
    </mat-tab-nav-panel>
