// @ts-nocheck
import { module } from 'angular';
import { VerifyEmailComponent } from '../login/pages/verify-email/verify-email.component';
import { createState } from '../shared/utils/create-state';
import { paramResolver } from '../shared/resolvers/param.resolver';
module('eaw.login.verify-email', [
    'eaw.resource',
]).config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: 'eaw/outside/verify_email',
        parent: 'eaw/outside',
        url: '/verify_email?:token?:email',
        data: {
            fullscreen: true,
        },
        views: {
            'content@': {
                component: VerifyEmailComponent,
            },
        },
        resolve: [
            paramResolver('token'),
            paramResolver('email'),
        ],
    });
} ]);
