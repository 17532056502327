import { ChangeDetectionStrategy, Component, computed, effect, inject, input, output, signal, viewChild } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatIconSizeDirective } from '../../directives/mat-icon-size.directive';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatRipple } from '@angular/material/core';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatTooltip } from '@angular/material/tooltip';
import { PaginationPage } from '../../../data-table/data-table.component';
import { NumberFormatterService } from '../../services/number-formatter.service';
import { CurrentService } from '../../services/current.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

export type PaginationChange = { page: number, per_page: number };

@Component({
    selector: 'eaw-paginator',
    standalone: true,
    imports: [
        AsyncPipe,
        MatIcon,
        MatIconButton,
        MatIconSizeDirective,
        MatPaginator,
        MatRipple,
        NgForOf,
        NgIf,
        TranslatePipe,
        MatTooltip,
    ],
    templateUrl: './paginator.component.html',
    styleUrl: './paginator.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent {
    private readonly numberFormatter = inject(NumberFormatterService);
    private readonly current = inject(CurrentService);
    private readonly breakPointObserver = inject(BreakpointObserver);

    matPaginator = viewChild.required(MatPaginator);

    pageIndex = input<number | undefined>();
    total = input<number | undefined>();
    perPage = input<number | undefined>();

    paginationChange = output<PaginationChange>();

    protected readonly pages = computed(() => this.createPages(this.paginationPagePadding(), this.total(), this.index(), this.perPage()));
    protected readonly index = signal(0);

    private readonly paginationPagePadding = signal(2);

    constructor() {
        this.breakPointObserver.observe(Breakpoints.XSmall).subscribe((res) => this.handlePaginationPagesBreakpoint(0, res.matches));
        this.breakPointObserver.observe(Breakpoints.Small).subscribe((res) => this.handlePaginationPagesBreakpoint(1, res.matches));
        this.breakPointObserver.observe(Breakpoints.Medium).subscribe((res) => this.handlePaginationPagesBreakpoint(2, res.matches));
        effect(() => this.index.set(this.pageIndex() || 0), { allowSignalWrites: true });
    }

    protected goToPage(page: number) {
        this.index.set(page - 1);
        this.emitChange({ length: this.matPaginator().length, pageIndex: this.index(), pageSize: this.matPaginator().pageSize });
    }

    protected emitChange(event: PageEvent) {
        this.paginationChange.emit({
            page: event.pageIndex + 1,
            per_page: event.pageSize || 10,
        });
    }

    private handlePaginationPagesBreakpoint(padding: number, matches: boolean) {
        if (matches) {
            this.paginationPagePadding.set(padding);
        }
    }

    private createPages(padding: number, total?: number, index?: number, perPage?: number): PaginationPage[] {
        if (total == null || index == null || perPage == null) {
            return [];
        }

        const currentPage = index + 1;
        const numberOfPages = Math.ceil(total / perPage);

        // Initiate with the first page, and last, unless first is last
        const pages: Set<number> = new Set([ 1, Math.max(1, numberOfPages) ]);

        // Add the current page and the pages around it
        for (let i = currentPage - padding; i <= currentPage + padding; i++) {
            if (i < 1 || i > numberOfPages || pages.has(i)) {
                continue;
            }

            pages.add(i);
        }

        const pagesArray: PaginationPage[] = [];

        // Add pages
        Array.from(pages).sort((a, b) => a - b).forEach((i) => {
            const formattedIndex = this.numberFormatter.formatInteger(i, this.current.languageTag);
            pagesArray.push({ index: i, formattedIndex, type: i === currentPage ? 'current' : 'page' });
        });

        // Check if we need to add a more icon at the start
        if (currentPage - padding > 2) {
            pagesArray.splice(1, 0, { index: 0, formattedIndex: '', type: 'more' });
        }

        // Check if we need to add a more icon at the end
        if (currentPage + padding < numberOfPages - 1) {
            pagesArray.splice(this.pages.length - 1, 0, { index: 0, formattedIndex: '', type: 'more' });
        }

        return pagesArray;
    }
}
