<mat-card>
    <mat-card-header>
        <mat-card-title>{{newsletter()?.getTitle(language)}}</mat-card-title>
        <mat-card-subtitle>{{'X_ISSUE' | translate:'newsletter':{count: totalIssues()} | async}}</mat-card-subtitle>
    </mat-card-header>

    <div id="issues">
        @for (issue of issues(); track issue.id) {
            <div class="issue">
                <eaw-newsletter-preview [customerId]="customerId"
                                        [newsletterId]="issue.newsletterId"
                                        [issueId]="issue.id"(click)="openIssue(issue)"
                                        [language]="issue.getLanguageCode(language)"
                                        [lazy]="true"/>

                <div class="text">
                    <span class="mat-subtitle-2">{{ issue.getTitle(language) }}</span>
                    <span>{{ issue.getDescription(language) || 'No description' }}</span>
                </div>
            </div>
        }
    </div>
</mat-card>
