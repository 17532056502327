import { Inject, Injectable } from '@angular/core';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { catchError, Observable, of, shareReplay } from 'rxjs';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { BusinessUnit } from '../models/business-unit';
import { BusinessUnitResponse } from '../interfaces/business-unit-response';
import { expandAllPages } from '../../shared/utils/rxjs/expand-all-pages';
import { HttpClient } from '@angular/common/http';

export type CreateBusinessUnit = {
    name: string,
    code: string,
    type: string,
    default?: boolean,
    color: string,
    parent_id?: number | null,
    qualifications?: number[],
    manable?: boolean,
    reverse_inherit_qualifications?: boolean,
};

export type UpdateBusinessUnit = Partial<CreateBusinessUnit> & { id: number };

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitService {
    private static cache = new Map<number, Map<number, Observable<ArrayPaginatedResponse<BusinessUnit>>>>();

    constructor(@Inject(HttpClient) protected http: HttpClient) {
    }

    invalidateCache(customerId: number): boolean {
        return BusinessUnitService.cache.delete(customerId);
    }

    get(customerId: number, unitId: number): Observable<BusinessUnit> {
        return this.http.get<BusinessUnitResponse>(`/customers/${customerId}/business_units/${unitId}`).pipe(classifyItem(BusinessUnit));
    }

    getAll(customerId: number, pagination: PaginationOptions = {}, fresh = true): Observable<ArrayPaginatedResponse<BusinessUnit>> {
        if (!BusinessUnitService.cache.has(customerId)) {
            BusinessUnitService.cache.set(customerId, new Map());
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const map = BusinessUnitService.cache.get(customerId)!;

        const page: number = pagination.page || 1;
        if (fresh || !map.has(page)) {
            const observer = this.http.get<ArrayPaginatedResponse<BusinessUnitResponse>>(`/customers/${customerId}/business_units`, { params: { ...pagination } }).pipe(classifyArrayPaginatedResponse(BusinessUnit));

            map.set(page, observer.pipe(shareReplay(1)));
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return map.get(page)!;
    }

    getAllPages(customerId: number, pagination: PaginationOptions = {}, fresh = true): Observable<BusinessUnit[]> {
        return expandAllPages((options: PaginationOptions) => this.getAll(customerId, options, fresh), {
            per_page: 100,
            order_by: 'name',
            direction: 'asc',
            ...pagination,
        }).pipe(
            catchError(() => of([])),
        );
    }

    create(customerId: number, unit: CreateBusinessUnit, relations: string[] = []): Observable<BusinessUnit> {
        return this.http.post<BusinessUnitResponse>(`/customers/${customerId}/business_units`, {
            ...unit,
            with: relations,
        }).pipe(classifyItem(BusinessUnit));
    }

    update(customerId: number, unit: UpdateBusinessUnit, relations: string[] = []): Observable<BusinessUnit> {
        if (!unit.id) {
            throw Error('Cannot update a business unit without an id');
        }

        return this.http.put<BusinessUnitResponse>(`/customers/${customerId}/business_units/${unit.id}`, {
            ...unit,
            with: relations,
        }).pipe(classifyItem(BusinessUnit));
    }

    delete(customerId: number, unitId: number): Observable<undefined> {
        return this.http.delete<undefined>(`/customers/${customerId}/business_units/${unitId}`);
    }
}
