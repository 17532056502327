import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AutoSchedulingResult } from '../../classes/auto-scheduling-result';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../../shared/utils/string-to-date-time';
import { AutoScheduleResultService } from '../../services/auto-schedule-result.service';
import { catchError, EMPTY } from 'rxjs';
import { ShiftResponse } from '../../../scheduling/models/shift';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'eaw-auto-populating-shift-result',
    templateUrl: './auto-populating-shift-result.component.html',
    styleUrl: './auto-populating-shift-result.component.scss',
    standalone: true,
    imports: [
        NgIf,
        MatListModule,
        NgFor,
        MatDividerModule,
        MatProgressSpinnerModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
    ],
})
export class AutoPopulatingShiftResultComponent implements OnInit, OnChanges {
    private readonly service = inject(AutoScheduleResultService);
    // Do not allow opening and closing this row.
    @Input() keepOpen = false;
    @Input() results: AutoSchedulingResult[] = [];
    @Input({ required: true }) shift!: ShiftResponse;
    @Input() employeeNames!: Map<number, string>;

    open = false;
    noResults = false;
    data!: { from: DateTime, to: DateTime, business_units: string[] };

    ngOnInit(): void {
        if (this.keepOpen) {
            this.open = true;

            if (!this.results.length) {
                this.getResults();
            }
        }

        this.data = {
            from: stringToDateTime(this.shift?.from),
            to: stringToDateTime(this.shift?.to),
            business_units: this.shift?.periods?.map((p) => p.business_unit?.name || '').filter((a) => a) ?? [],
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['results']) {
            this.sortResults(<AutoSchedulingResult[]>changes['results'].currentValue);
        }

        if (changes['employeeNames']) {
            this.sortResults(this.results);
        }
    }

    toggleShift() {
        if (this.keepOpen) {
            return false;
        }

        this.open = !this.open;

        if (this.open && !this.results.length) {
            this.getResults();
        }
    }

    protected getResults() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.service.getForShift(this.shift.schedule!.customer_id, this.shift.schedule_id, this.shift.id)
            .pipe(catchError(() => {
                this.noResults = true;
                return EMPTY;
            }))
            .subscribe((results) => {
                this.sortResults(results);
            });
    }

    protected sortResults(results: AutoSchedulingResult[]): void {
        this.noResults = false;
        this.results = results.filter((a) => {
            return !!this.employeeNames.get(a.employeeId);
        }).sort((a, b): number => {
            const name1: string | undefined = this.employeeNames.get(a.employeeId);
            const name2: string | undefined = this.employeeNames.get(b.employeeId);

            return name1 && name2 ? name1.localeCompare(name2) : 0;
        });
    }
}
