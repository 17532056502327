// @ts-nocheck
import { module } from 'angular';

module('eaw.admin').component('adminCustomerMlBucket', {
    template: `<eaw-accordion-panel>
    <panel-header>
        <div class="bucket-name" ng-bind="$bucket.bucket.name"></div>
        <div ng-if="$bucket.bucket.description" class="bucket-description" ng-bind="$bucket.bucket.description"></div>
    </panel-header>
    <panel-content class="tw-p-0">
        <md-tabs md-dynamic-height>
            <admin-customer-ml-bucket-info customer="$bucket.customer" bucket="$bucket.bucket" on-update="$bucket.onUpdate()"></admin-customer-ml-bucket-info>
            <admin-customer-ml-bucket-variables customer="$bucket.customer" bucket="$bucket.bucket"></admin-customer-ml-bucket-variables>
            <admin-customer-ml-bucket-time-series customer="$bucket.customer" bucket="$bucket.bucket"></admin-customer-ml-bucket-time-series>
        </md-tabs>
    </panel-content>
</eaw-accordion-panel>
`,
    bindings: {
        customer: '<',
        bucket: '<',
        onUpdate: '&',
    },
    controllerAs: '$bucket',
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {

        };

    },
});
