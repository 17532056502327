import { DateTime } from 'luxon';

export interface AvailabilityOverviewIntervalData {
    index: number;
    id?: number;
    availability_id?: number;
    offset?: number;
    length?: number;
    whole_day?: boolean;
    unavailable?: boolean;
}

export class AvailabilityOverviewInterval {
    // Date of where _this_ interval belongs
    date: DateTime;
    // Index from the date where the availabilities are calculated and the place of the interval in the list
    index: number;
    availabilityId?: number;
    offset?: number;
    length?: number;
    from?: DateTime;
    to?: DateTime;
    wholeDay?: boolean;
    unavailable?: boolean;

    constructor(date: DateTime, data: AvailabilityOverviewIntervalData) {
        this.date = date.startOf('day');
        this.index = data.index;
        this.availabilityId = data.availability_id;
        this.offset = data.offset;
        this.length = data.length;
        this.from = this.date.plus({ seconds: data.offset });
        this.to = this.from.plus({ seconds: data.length });
        this.wholeDay = data.whole_day;
        this.unavailable = data.unavailable;
    }
}
