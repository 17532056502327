export const shiftTooltipTemplate = `<div class="header">
    <eaw-profile-picture-old size="30" user="ctrl.user"></eaw-profile-picture-old>

    <div class="basic-info">
        <strong ng-if="ctrl.shift.employee" ng-bind="ctrl.shift.employee.name"></strong>
        <span ng-bind="ctrl.fromTo"></span>
    </div>
</div>

<div class="section warnings" ng-if="ctrl.warnings.length">
    <md-icon class="section-icon" ng-bind="'error'"></md-icon>
    <span ng-repeat="w in ctrl.warnings" ng-bind="w.message"></span>
</div>

<div class="section info" ng-if="ctrl.shift.comments_count || ctrl.shift.comments.length">
    <md-icon class="section-icon" ng-bind="'info'"></md-icon>

    <p ng-if="ctrl.shift.comments_count && !ctrl.shift.comments.length" bo-i18next="[i18next]({count: ctrl.shift.comments_count})X_COMMENT"></p>
    <span class="comment" ng-repeat="comment in ctrl.shift.comments">
        <span ng-bind="comment.body"></span> <br /> -<em ng-bind="comment.user_name"></em>
    </span>
</div>

<div class="section periods" ng-if="ctrl.periods.length">
    <md-icon class="section-icon" ng-bind="'timelapse'"></md-icon>

    <div class="period" ng-repeat="p in ctrl.periods">
        <div class="indicator" ng-style="{background: p.backgroundColor}"></div>

        <div class="period-info">
            <span ng-bind="p.description"></span>
            <span ng-bind="p.fromTo"></span>
        </div>
    </div>
</div>

<div class="section qualifications" ng-if="ctrl.qualifications.length">
    <md-icon class="section-icon" md-svg-src="card-account-details"></md-icon>
    <span ng-repeat="q in ctrl.qualifications" ng-bind="q.name"></span>
</div>
`;
