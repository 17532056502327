module.exports = after;
function after(count, callback, err_cb) {
  var bail = false;
  err_cb = err_cb || noop;
  proxy.count = count;
  return count === 0 ? callback() : proxy;
  function proxy(err, result) {
    if (proxy.count <= 0) {
      throw new Error('after called too many times');
    }
    --proxy.count;

    // after first error, rest are passed to err_cb
    if (err) {
      bail = true;
      callback(err);
      // future error callbacks will go to error handler
      callback = err_cb;
    } else if (proxy.count === 0 && !bail) {
      callback(null, result);
    }
  }
}
function noop() {}