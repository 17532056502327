import { Component, DestroyRef, inject, viewChild } from '@angular/core';
import { AsyncPipe, NgForOf } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { debounceTime, distinctUntilChanged, EMPTY, filter, of, switchMap, tap } from 'rxjs';
import { DataTablePagination, type DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { CustomFieldsService } from '../../../custom-fields/http/custom-fields.service';
import { Namespace } from '../../../shared/enums/namespace';
import { TranslateService } from '../../../shared/services/translate.service';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { ApiModel, ApiModelClass } from '../../../shared/enums/api-model';
import { CustomField } from '../../../custom-fields/models/custom-field';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { DialogData } from '../../../shared/dialogs/dialog-component';
import { AddCustomFieldDialogReturn, AdminAddCustomFieldDialogComponent } from '../../dialogs/admin-add-custom-field-dialog/admin-add-custom-field-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaw-admin-custom-fields',
    standalone: true,
    imports: [
        AsyncPipe,
        FormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        NumberPipe,
        PageHeaderComponent,
        ReactiveFormsModule,
        TranslatePipe,
        DataTableComponent,
        MatCard,
        MatCardContent,
        MatOption,
        MatSelect,
        NgForOf,
    ],
    templateUrl: './admin-custom-fields.component.html',
    styleUrl: './admin-custom-fields.component.scss',
})
export class AdminCustomFieldsComponent implements EawDataTable {
    private translate = inject(TranslateService);
    searchParams = inject(QueryParamsService);
    private customFieldsService = inject(CustomFieldsService);
    private permissionCheckService = inject(PermissionCheckService);
    private confirmDialogService = inject(ConfirmDialogService);
    private promptDialogService = inject(PromptDialogService);
    private matDialog = inject(MatDialog);
    private destroyedRef = inject(DestroyRef);

    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    fabButton: HeaderFabButton = {
        click: this.addCustomField.bind(this),
        hasPermission: () => of(true),
    };

    types = CustomField.Types;
    filter = new FormControl<string | null>(this.searchParams.get('filter', 'string') || '');
    type = new FormControl<string[] | null>(this.searchParams.get('type', 'array') || this.types);
    columns: DataTableColumnType<CustomField>[];
    table = viewChild.required(DataTableComponent);

    constructor() {
        this.filter.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            tap(() => this.table()?.refresh()),
            takeUntilDestroyed(this.destroyedRef),
        ).subscribe();
        this.type.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            tap(() => {
                this.table()?.refresh();
            }),
            takeUntilDestroyed(this.destroyedRef),
        ).subscribe();
        this.columns = [
            new DataTableNumberColumn({
                value: 'id',
                header: new DataTableHeader({
                    text: 'Id',
                    sortBy: 'id',
                }),
            }),
            new DataTableTextColumn({
                value: 'name',
                header: new DataTableHeader({
                    i18n: 'NAME',
                    sortBy: 'name',
                }),
            }),
            new DataTableTextColumn({
                value: (data) => data.item.translate(),
                header: new DataTableHeader({
                    i18n: 'TRANSLATED_NAME',
                    ns: Namespace.Admin,
                }),
            }),
            new DataTableTextColumn({
                value: 'key',
                header: new DataTableHeader({
                    i18n: 'KEY',
                    sortBy: 'key',
                }),
            }),
            new DataTableTextColumn({
                value: 'type',
                header: new DataTableHeader({
                    i18n: 'TYPE',
                    sortBy: 'type',
                }),
            }),
            new DataTableButtonColumn({
                buttons: [
                    {
                        icon: 'edit',
                        click: this.updateCustomField.bind(this),
                        show: (item) =>
                            this.permissionCheckService.isAllowed(`custom_fields.[${ApiModel.CustomField}].update`, {
                                models: [ {
                                    id: item.id,
                                    type: ApiModelClass.CustomField,
                                } ],
                            }),
                        tooltip: { key: 'UPDATE' },
                    },
                    {
                        icon: 'delete',
                        type: 'warn',
                        click: this.deleteCustomField.bind(this),
                        show: (item) => this.permissionCheckService.isAllowed(`custom_fields.[${ApiModel.CustomField}].delete`, {
                            models: [
                                { id: item.id, type: ApiModelClass.CustomField },
                            ],
                        }),
                        tooltip: { key: 'DELETE' },
                    },
                ],
            }),
        ];
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        this.searchParams.set([
            {
                key: 'filter',
                value: this.filter.value,
            },
            {
                key: 'types[]',
                value: this.type.value,
            } ]);
        this.request = this.customFieldsService.getAll({
            ...pagination,
            filter: this.filter.value || undefined,
            'types[]': this.type.value || [],
        });
    }

    deleteCustomField(cell: DataTableButtonCell<CustomField>) {
        this.confirmDialogService.delete({
            title: this.translate.t('DELETE', Namespace.Admin),
            text: this.translate.t('THIS_WILL_REMOVE_THE', Namespace.Admin, {
                name: cell.item.name,
            }),
            confirmText: this.translate.t('DELETE', Namespace.General),
        }).afterClosed()
            .pipe(
                takeUntilDestroyed(this.destroyedRef),
                switchMap((result) => {
                    if (!result?.ok) {
                        cell.disabled.set(false);
                        return EMPTY;
                    }

                    return this.customFieldsService.removeCustomField(cell.item.id);
                }),
                takeUntilDestroyed(this.destroyedRef),
                tap(() => this.table()?.refresh()),
            ).subscribe();
    }

    updateCustomField(cell: DataTableButtonCell<CustomField>) {
        this.promptDialogService.open('text', {
            formControl: new FormControl<string>(cell.item.name, { validators: Validators.required }),
            title: this.translate.t('UPDATE_CUSTOM_FIELD_CONTEXT', Namespace.Admin),
            label: this.translate.t('NAME'),
            confirmText: this.translate.t('UPDATE'),
        }).afterClosed().pipe(
            tap((name) => {
                if (!name) {
                    cell.disabled.set(false);
                    return;
                }
                const { key, type, id } = { ...cell.item };
                this.customFieldsService.updateCustomField(id, {
                    key,
                    type,
                    name,
                }).pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(() => {
                    this.table()?.refresh();
                });
            }),
            takeUntilDestroyed(this.destroyedRef),
        ).subscribe();
    }

    addCustomField(): void {
        this.matDialog.open<AdminAddCustomFieldDialogComponent, DialogData, AddCustomFieldDialogReturn>(AdminAddCustomFieldDialogComponent).afterClosed()
            .pipe(takeUntilDestroyed(this.destroyedRef),
                filter((customField) => !!customField))
            .subscribe(() => this.table()?.refresh());
    }
}
