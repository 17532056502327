<eaw-dialog-header>
    <span title>{{'DAILY_DETAILS' | translate: 'ai_budgeting' | async}}</span>
    <span subtitle>{{data.projectionDay.date | DateTime: 'DATE_MED_WITH_WEEKDAY' }}</span>
</eaw-dialog-header>

@if (loading) {
    <mat-dialog-content class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </mat-dialog-content>
} @else {
    <mat-dialog-content id="details-content">
        <mat-form-field class="comment">
            <mat-label>{{'COMMENT' | translate | async}}</mat-label>
            <input matInput type="text" autocomplete="off" [formControl]="form.controls.comment">
        </mat-form-field>
        <div class="table-wrapper">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="timeTop">
                    <th mat-header-cell *matHeaderCellDef>{{'TIME' | translate: 'ai_budgeting' | async}}</th>
                </ng-container>

                <ng-container matColumnDef="projectionForSchedule">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">{{'PROJECTION_FOR_SCHEDULE' | translate: 'ai_budgeting' | async}}</th>
                </ng-container>

                <ng-container matColumnDef="realData">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">{{'REAL_DATA' | translate: 'ai_budgeting' | async}}</th>
                </ng-container>

                <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item">{{item.time | DateTime: 'TIME_24_SIMPLE'}}</td>
                    <td mat-footer-cell *matFooterCellDef>{{'TOTAL' | translate | async}}</td>
                </ng-container>

                <ng-container matColumnDef="transactions">
                    <th mat-header-cell class="tw-text-right" *matHeaderCellDef>{{'GC' | translate: 'ai_budgeting' | async}}</th>
                    <td mat-cell class="tw-text-right" *matCellDef="let item" [class]="item.edited ? 'edited' : ''">
                        @if (item.form && !item.blocked) {
                            <input matInput type="text" autocomplete="off" [formControl]="item.form.controls.transactions" [value]="item.transactions.value | number: '1.0-0'" (change)="transactionChange(item, $event)">
                        } @else {
                            {{ item.transactions.value | eawNumber: 0}}
                        }
                    </td>
                    <td mat-footer-cell class="tw-text-right" *matFooterCellDef>
                        <span>{{totals.transactions | eawNumber: 0}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="atGenerated">
                    <th mat-header-cell class="tw-text-right" *matHeaderCellDef>{{'AVERAGE_TRAY' | translate: 'ai_budgeting' | async}}</th>
                    <td mat-cell class="tw-text-right" *matCellDef="let item">
                        @if (item.atGenerated) {
                            {{item.atGenerated.value | eawNumber: 2}}
                        }
                    </td>
                    <td mat-footer-cell class="tw-text-right" *matFooterCellDef>{{totals.at | eawNumber: 2}}</td>
                </ng-container>

                <ng-container matColumnDef="sales">
                    <th mat-header-cell class="tw-text-right" *matHeaderCellDef>{{'SALES' | translate: 'ai_budgeting' | async}}</th>
                    <td mat-cell class="tw-text-right" *matCellDef="let item">{{item.sales.value | eawNumber: 2}}</td>
                    <td mat-footer-cell class="tw-text-right" *matFooterCellDef>{{totals.sales | eawNumber: 2}}</td>
                </ng-container>

                <ng-container matColumnDef="realGc">
                    <th mat-header-cell *matHeaderCellDef class="tw-text-right">{{'GC' | translate: 'ai_budgeting' | async}}</th>
                    <td mat-cell *matCellDef="let item" class="tw-text-right">{{item.realTransactions.value | eawNumber: 2}}</td>
                    <td mat-footer-cell *matFooterCellDef class="tw-text-right" [class]="totals.warning ? 'red' : ''">{{ totals.realTransactions | eawNumber: 2 }}</td>
                </ng-container>

                <ng-container matColumnDef="realAt">
                    <th mat-header-cell *matHeaderCellDef class="tw-text-right">{{'AVERAGE_TRAY' | translate: 'ai_budgeting' | async}}</th>
                    <td mat-cell *matCellDef="let item" class="tw-text-right">{{item.realAt | eawNumber: 2}}</td>
                    <td mat-footer-cell *matFooterCellDef class="tw-text-right">{{ totals.realAt | eawNumber: 2 }}</td>
                </ng-container>

                <ng-container matColumnDef="realSales">
                    <th mat-header-cell *matHeaderCellDef class="tw-text-right">{{'SALES' | translate: 'ai_budgeting' | async}}</th>
                    <td mat-cell *matCellDef="let item" class="tw-text-right">{{item.realSales.value | eawNumber: 2}}</td>
                    <td mat-footer-cell *matFooterCellDef class="tw-text-right">{{ totals.realSales | eawNumber: 2 }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['timeTop', 'projectionForSchedule', 'realData']"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell tw-pt-16 tw-pb-16" colspan="2">{{'NO_DATA' | translate | async}}</td>
                </tr>
            </table>
        </div>
    </mat-dialog-content>
}

<mat-dialog-actions>
    @if (!sumValid) {
        <button mat-button (click)="updateTable()">{{'RESET' | translate | async}}</button>
    }
    <button mat-button mat-dialog-close>{{'CLOSE' | translate | async}}</button>
    <eaw-action-button [loading]="loading || saving" [disabled]="!sumValid || dataSource.length === 0" (click)="saveForm()">{{'SAVE' | translate | async}}</eaw-action-button>
</mat-dialog-actions>
