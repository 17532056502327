import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject, signal } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef } from '@angular/material/dialog';

import { DialogComponent, DialogSize } from '../../../shared/dialogs/dialog-component';
import { TranslateService } from '../../../shared/services/translate.service';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { CustomField } from '../../../custom-fields/models/custom-field';
import { MatCard, MatCardContent } from '@angular/material/card';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { Namespace } from '../../../shared/enums/namespace';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { catchError, EMPTY, filter, switchMap, tap } from 'rxjs';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { FieldErrorComponent } from '../../../shared/components/field-error/field-error.component';
import { CreateUpdateCustomField, CustomFieldsService } from '../../../custom-fields/http/custom-fields.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CustomFieldType } from '../../../custom-fields/typings/custom-field-type';

export type AddCustomFieldDialogReturn = CreateUpdateCustomField;

@Component({
    selector: 'eaw-admin-add-custom-field-dialog',
    standalone: true,
    imports: [
        AsyncPipe,
        AutocompleteComponent,
        DialogHeaderComponent,
        MatButton,
        MatDialogActions,
        MatDialogClose,
        MatDialogContent,
        TranslatePipe,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        MatOption,
        MatSelect,
        MatError,
        NgForOf,
        MatCard,
        MatCardContent,
        ActionButtonComponent,
        MaterialColorDirective,
        FieldErrorComponent,
        MatProgressSpinner,
        NgIf,
    ],
    templateUrl: './admin-add-custom-field-dialog.component.html',
    styleUrl: './admin-add-custom-field-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminAddCustomFieldDialogComponent extends DialogComponent {
    private translate = inject(TranslateService);
    private confirmDialogService = inject(ConfirmDialogService);
    private customFieldsService = inject(CustomFieldsService);
    private destroyedRef = inject(DestroyRef);

    types = CustomField.Types;
    form = new FormGroup({
        name: new FormControl<string | null>(null, [ Validators.required, Validators.maxLength(150) ]),
        type: new FormControl<CustomFieldType | null>(null, Validators.required),
        key: new FormControl<string | null>(null, [ Validators.required, Validators.maxLength(125) ]),
    });

    loading = signal(false);

    constructor(
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AdminAddCustomFieldDialogComponent, AddCustomFieldDialogReturn>,
    ) {
        super(dialogRef, { size: DialogSize.Medium });
    }

    submit(): void {
        this.confirmDialogService.open({
            title: this.translate.t('WEB_TRANSLATE_IT', Namespace.Admin),
            text: this.translate.t('WEB_TRANSLATE_IT_DIALOG_CONTEXT', Namespace.Admin, {
                name: this.form.value.key,
            }),
            cancelText: this.translate.t('NO', Namespace.General),
            confirmText: this.translate.t('YES', Namespace.General),
        }).afterClosed().pipe(
            filter((result) => !!result),
            tap(() => {
                this.loading.set(true);
                this.form.disable();
            }),
            switchMap(() => {
                const { name, type, key } = this.form.value;
                if (!name || !type || !key) {
                    return EMPTY;
                }

                return this.customFieldsService.addCustomField({ name, type, key }).pipe(
                    takeUntilDestroyed(this.destroyedRef),
                    catchError(() => {
                        this.loading.set(false);
                        this.form.enable();
                        return EMPTY;
                    }),
                );
            }),
        ).subscribe((resp) => this.dialogRef.close(resp));
    }
}
