import { ApiResponse } from '../interfaces/api-response';
import { stringToDateTime } from '../utils/string-to-date-time';
import { DateTime } from 'luxon';

export interface TosResponse extends ApiResponse {
    created_at: string;
    text: string;
    version: number;
}

export class Tos {
    public readonly createdAt: DateTime;
    public readonly text: string;
    public readonly version: number;

    constructor(data: TosResponse) {
        this.createdAt = stringToDateTime(data.created_at);
        this.text = data.text;
        this.version = data.version;
    }
}
