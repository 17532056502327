import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { CurrentService } from '../../shared/services/current.service';
import { EawUrl } from '../../shared/angularjs/modules/resource/url.service';
import { HttpClient, HttpContext } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UIRouter } from '@uirouter/core';
import { Products } from '../../shared/enums/products';
import { Namespace, NamespaceFile } from '../../shared/enums/namespace';
import { environment } from '../../../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BadgerService } from '../../shared/services/badger.service';
import { distinctUntilChanged, EMPTY, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { LoginService } from '../../shared/services/login.service';
import { DateTime, Settings } from 'luxon';
import { MainMenuEntry } from '../classes/main-menu-entry';
import { IGNORE_ERROR } from '../../shared/http/http-contexts';
import { User } from '../../shared/models/user';
import { MainMenuService } from '../services/main-menu.service';
import { CustomerProductService } from '../../shared/http/customer-product.service';
import { Customer } from '../../shared/models/customer';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CurrentLoaderService } from '../../initializer/services/current-loader.service';
import { NumberPipe } from '../../shared/pipes/number.pipe';
import { DateTimePipe } from '../../shared/pipes/date-time.pipe';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatIconSizeDirective } from '../../shared/directives/mat-icon-size.directive';
import { MatIconModule } from '@angular/material/icon';
import { ProfilePictureComponent } from '../../shared/components/profile-picture/profile-picture.component';
import { VersioningService } from '../../shared/services/versioning.service';
import { Link } from '../../links/models/link';
import { PermissionDirective } from '../../permissions/directives/permission.directive';
import { PermissionsInputValue } from '../../permissions/services/element-permission.service';
import { MainMenuParentEntry } from '../classes/main-menu-parent-entry';
import { OpenExternalLinkService } from '../../shared/services/open-external-link.service';

type LinkItem = {
    id: number,
    text: string,
    description: string,
    url: string,
    onClick: (event: PointerEvent | TouchEvent, link: LinkItem) => void
};

type MiniMenuItem = { name: string, ns: NamespaceFile, icon: string, href?: string, onClick?: () => unknown };

type BottomMenuItem = {
    id: string,
    name: string,
    ns: NamespaceFile,
    icon: string,
    href?: string,
    permissions: PermissionsInputValue
};

@Component({
    selector: 'eaw-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.scss',
    animations: [
        trigger('inOutAnimation', [
            state('minimized', style({
                height: 0,
                maxHeight: '0',
            })),
            state('expanded', style({
                height: '{{height}}px',
                maxHeight: '{{height}}px',
            }), { params: { height: 0 } }),
            transition('* => *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
        ]),
        trigger('rotateAnimation', [
            state('minimized', style({ transform: 'rotate(0deg)' })),
            state('expanded', style({ transform: 'rotate(180deg)' })),
            transition('* => *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ProfilePictureComponent,
        MatIconModule,
        MatIconSizeDirective,
        NgFor,
        NgIf,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatListModule,
        AsyncPipe,
        TranslatePipe,
        DateTimePipe,
        NumberPipe,
        PermissionDirective,
    ],
})
export class SidebarComponent implements OnInit {
    private readonly http = inject(HttpClient);
    private readonly domSanitizer = inject(DomSanitizer);
    private readonly destroyRef = inject(DestroyRef);
    private readonly uiRouter = inject(UIRouter);
    private readonly loginService = inject(LoginService);
    private readonly current = inject(CurrentService);
    private readonly badger = inject(BadgerService);
    private readonly currentLoaderService = inject(CurrentLoaderService);
    private readonly mainMenuService = inject(MainMenuService);
    private readonly customerProductService = inject(CustomerProductService);
    private readonly versioningService = inject(VersioningService);
    private readonly openExternalDialog = inject(OpenExternalLinkService);

    lastUpdate = signal<DateTime | undefined>(undefined);
    user: WritableSignal<User>;
    customer: WritableSignal<Customer>;
    version = signal(environment.version);
    logoUrl = signal<SafeResourceUrl | undefined>(undefined);
    menu = signal<MainMenuEntry[]>([]);
    showMenu = signal(false);
    miniToggled = signal(false);
    loadingMenu = signal(true);
    miniMenu = signal<MiniMenuItem[]>([]);
    bottomMenu = signal<BottomMenuItem[]>([]);
    linksMenu = signal<LinkItem[]>([]);
    badgeFormatterOptions = signal<Intl.NumberFormatOptions>({
        compactDisplay: 'short',
        notation: 'compact',
        maximumFractionDigits: 1,
    });

    constructor() {
        this.user = signal(this.current.getUser());
        this.customer = signal(this.current.getCustomer());

        this.miniMenu.set([
            {
                name: 'PROFILE',
                ns: 'general',
                icon: 'account_circle',
                href: this.mainMenuService.getHref('eaw/app/profile/info', []),
            },
            {
                name: 'LOGOUT',
                ns: 'general',
                icon: 'logout',
                onClick: () => this.logout(),
            },
        ]);
    }

    ngOnInit(): void {
        this.mainMenuService.reloadingMenu().pipe(
            switchMap(() => this.currentLoaderService.onLoaded()),
            switchMap((loaded) => {
                if (loaded && this.current.getCustomer()) {
                    return of(loaded);
                }

                this.showMenu.set(false);
                this.loadingMenu.set(false);
                this.toggleMini();
                return EMPTY;
            }),
            switchMap(() => this.mainMenuService.get()),
            tap((menu) => {
                this.menu.set(menu);
                this.loadingMenu.set(false);
                this.showMenu.set(true);
            }),
            tap(() => this.createBottomMenu()),
            switchMap(() => this.customerProductService.hasProductsDetailed(this.customer().id, [ Products.ICalendar, Products.Calendar2 ])),
            tap((productCheck) => {
                if (productCheck.products[Products.ICalendar] && !productCheck.products[Products.Calendar2]) {
                    this.miniMenu.update((miniMenu) => {
                        miniMenu.splice(1, 0, {
                            name: 'CALENDAR_SYNC',
                            ns: 'navigation',
                            icon: 'cloud_sync',
                            href: this.mainMenuService.getHref('eaw/app/icalendar', []),
                        });

                        return miniMenu;
                    });
                }
            }),
            switchMap(() => this.getLogo()),
            switchMap(() => this.badger.onBadges(this.customer().id)),
            tap(this.onBadges.bind(this)),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();

        this.mainMenuService.reloadingMenu().pipe(
            switchMap(() => this.mainMenuService.getLinks()),
        ).subscribe((links) => this.handleLinks(links));

        this.versioningService.onChange('utc').pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef),
            tap((unix) => this.lastUpdate.set(DateTime.fromMillis(unix, { zone: 'UTC' }).setZone(Settings.defaultZone))),
        ).subscribe();

        this.startActiveUpdate();

        this.mainMenuService.reload();
    }

    handleLinks(links: Link[]) {
        this.linksMenu.set(links.map((link) => {
            return {
                id: link.id,
                text: link.text,
                description: link.description || '',
                url: link.url,
                onClick: (event: PointerEvent | TouchEvent, link: LinkItem) => {
                    this.openExternalDialog.open({ url: link.url }, event).subscribe();
                },
            };
        }));
    }

    createBottomMenu() {
        this.bottomMenu.set([
            {
                id: 'knowledge_center',
                href: this.uiRouter.stateService.href('eaw/app/knowledge_center', { path: '' }),
                name: 'KNOWLEDGE_CENTER',
                ns: Namespace.LearningModule,
                icon: 'menu_book',
                permissions: [ 'learning_module.beta' ],
            },
        ]);
    }

    startActiveUpdate() {
        // Update active route on init
        this.updateActive(this.uiRouter.globals.current.name);

        // Update active route whenever a transition finishes
        this.uiRouter.transitionService.onFinish({}, (transition) => {
            this.updateActive(transition.to().name);
        });
    }

    onBadges(badges: Map<string, number>) {
        this.menu()?.forEach((item) => {
            item.updateBadgeCount(badges);
        });
    }

    updateActive(name?: string) {
        this.menu()?.forEach((parent) => {
            parent.updateActiveStatus(name);
        });
    }

    async logout() {
        for (const hook of this.uiRouter.transitionService.getHooks('onBefore')) {
            hook.deregister();
        }

        await this.loginService.logout(this.current.getMe().authedAs, undefined, true);
        void this.uiRouter.stateService.transitionTo('eaw/outside/login');
    }

    toggleMini() {
        this.miniToggled.update((toggled) => !toggled);
    }

    getLogo(): Observable<void> {
        if (!this.customer() || this.logoUrl()) {
            return of(undefined);
        }

        return this.http.get(EawUrl.getUrl(`/customers/${this.customer().id}/logo`), {
            responseType: 'blob',
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(
            take(1),
            map((res) => {
                this.logoUrl.set(this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(res)));
                return undefined;
            }),
        );
    }

    onPermissionChecked($event: boolean, parent: MainMenuParentEntry, entry: MainMenuEntry) {
        entry.hasPermission.set($event);

        if ($event) {
            return;
        }

        // As the parent is the parent of the entry, it _has_ a subMenu
        parent.hasPermission.set(parent.subMenu?.some((child) => child.hasPermission()) || false);
    }
}
