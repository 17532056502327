import { Injectable } from '@angular/core';
import { Namespace } from '../../shared/enums/namespace';
import { AggregateTimepunchValues } from '../types/aggregate-timepunch-values';
import { AggregateTimepunchData } from '../types/aggregate-timepunch-data';

@Injectable({
    providedIn: 'root',
})
export class AggregateTimepunchInfoService {
    getItems(hasScheduling: boolean): Record<AggregateTimepunchValues, AggregateTimepunchData> {
        return {
            employee_name: {
                i18n: 'EMPLOYEE',
                type: 'string',
                if: true,
                col_key: 'employee_name',
                canTotal: false,
            },
            infractions: {
                i18n: 'INFRACTION_plural',
                type: 'integer',
                if: true,
                col_key: 'infractions',
                canTotal: true,
                aggregatedXY: true,
            },
            shifts_count: {
                i18n: 'SHIFTS',
                type: 'integer',
                ns: 'scheduling',
                if: hasScheduling,
                col_key: 'shifts_count',
                canTotal: true,
                aggregatedXY: true,
            },
            in: {
                i18n: 'IN',
                type: 'time',
                ns: 'payroll',
                if: true,
                col_key: 'in',
                canTotal: false,
            },
            out: {
                i18n: 'OUT',
                type: 'time',
                ns: 'payroll',
                if: true,
                col_key: 'out',
                canTotal: false,
            },
            /* Duration of punch(es) */
            length: {
                i18n: 'LENGTH',
                type: 'hours',
                if: true,
                col_key: 'length',
                canTotal: true,
            },
            shift_from: {
                i18n: 'SHIFT_FROM',
                ns: 'payroll',
                type: 'time',
                if: true,
                col_key: 'shift_from',
                canTotal: false,
            },
            shift_to: {
                i18n: 'SHIFT_TO',
                ns: 'payroll',
                type: 'time',
                if: true,
                col_key: 'shift_to',
                canTotal: false,
            },
            shifts_length: {
                i18n: 'SHIFT_LENGTH',
                type: 'hours',
                ns: 'payroll',
                if: hasScheduling,
                col_key: 'shifts_length',
                canTotal: false,
            },
            business_date: {
                i18n: 'BUSINESS_DATE',
                type: 'string',
                ns: 'general',
                if: true,
                col_key: 'business_date',
                canTotal: false,
            },
            diff: {
                i18n: 'DIFFERENCE_ABBREVIATION',
                if: hasScheduling,
                type: 'hours',
                ns: 'payroll',
                col_key: '',
                canTotal: false,
            },
            comments_count: {
                i18n: 'COMMENT_plural',
                type: 'integer',
                if: true,
                col_key: 'comments_count',
                canTotal: true,
            },
            warnings_count: {
                i18n: 'WARNING_plural',
                type: 'integer',
                if: true,
                col_key: 'warnings_count',
                canTotal: true,
            },
            manually_closed: {
                i18n: 'MANUALLY_CLOSED',
                ns: 'payroll',
                type: 'integer',
                if: true,
                col_key: 'manually_closed',
                canTotal: true,
                aggregatedXY: true,
            },
            manually_opened: {
                i18n: 'MANUALLY_OPENED',
                type: 'integer',
                ns: 'payroll',
                if: true,
                col_key: 'manually_opened',
                canTotal: true,
                aggregatedXY: true,
            },
            time_edited: {
                i18n: 'TIME_EDITED',
                ns: 'payroll',
                type: 'integer',
                if: true,
                col_key: 'time_edited',
                canTotal: true,
                aggregatedXY: true,
            },
            approved_count: {
                i18n: 'APPROVED',
                type: 'integer',
                if: true,
                col_key: 'approved_count',
                canTotal: true,
                aggregatedXY: true,
            },
            deleted: {
                col_key: '',
                ns: Namespace.General,
                i18n: 'DELETED',
                type: 'integer',
                if: (options) => (!!(options?.onlyDeleted || options?.includeDeleted)),
                canTotal: true,
                aggregatedXY: true,
            },
        };
    }
}
