import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../../shared/interfaces/paginated-response';
import { Rotation, RotationResponse } from '../models/rotation';
import { classifyArrayPaginatedResponse, classifyItem } from '../../../shared/utils/rxjs/classify';
import { PaginationOptions } from '../../../shared/interfaces/pagination-options';
import { Weekday } from '../../../shared/types/weekday';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../../shared/utils/format-http-params';

interface GetAllOptions extends PaginationOptions {
    'with[]'?: ('intervals'|'employees'|'employees.user')[];
}

interface CreateOptions {
    name: string;
    start_day: Weekday;
    days: number;
    intervals: {from: string, to: string, day_index: number}[]
}

@Injectable({
    providedIn: 'root',
})
export class RotationService {
    constructor(@Inject(HttpClient) private http: HttpClient) {}

    get(customerId: number, rotationId: number, withs?: ('intervals'|'employees')[]): Observable<Rotation> {
        return this.http.get<RotationResponse>(`/customers/${customerId}/rotations/${rotationId}`, {
            params: formatHttpParams({
                'with[]': withs,
            }),
        }).pipe(classifyItem(Rotation));
    }

    getAll(customerId: number, options?: GetAllOptions): Observable<ArrayPaginatedResponse<Rotation>> {
        return this.http.get<ArrayPaginatedResponse<RotationResponse>>(`/customers/${customerId}/rotations`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(Rotation));
    }

    create(customerId: number, options: CreateOptions): Observable<Rotation> {
        return this.http.post<RotationResponse>(`/customers/${customerId}/rotations`, options)
            .pipe(classifyItem(Rotation));
    }

    update(customerId: number, rotationId: number, options: CreateOptions): Observable<Rotation> {
        return this.http.put<RotationResponse>(`/customers/${customerId}/rotations/${rotationId}`, options)
            .pipe(classifyItem(Rotation));
    }

    delete(customerId: number, rotationId: number) {
        return this.http.delete(`/customers/${customerId}/rotations/${rotationId}`);
    }
}
