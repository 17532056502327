// @ts-nocheck
import { module } from 'angular';
import { Comment } from '../../../../../shared/models/comment';
import { ShiftPeriodListDialogComponent, ShiftPeriodListDialogData } from '../../../../../scheduling/dialogs/shift-period-list-dialog/shift-period-list-dialog.component';
import { CommentDialogComponent, CommentDialogData } from '../../../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
module('eaw.dashboard').component('dashVacantShiftList', {
    template: `<md-virtual-repeat-container class="eaw-virtual-repeat" ng-show="$shifts.loaded && $shifts.list.getLength()">
    <md-list class="md-dense">
        <md-list-item md-virtual-repeat="shift in $shifts.list" md-on-demand>
            <div class="md-list-item-text">
                <p ng-bind="shift.business_date.format('ll')"></p>
            </div>

            <md-button class="md-secondary md-icon-button tw-p-0 tw-m-0" ng-if="shift.comments.length" ng-click="$shifts.showComments(shift)">
                <md-icon ng-bind="'comment'"></md-icon>
            </md-button>

            <md-button class="md-secondary md-icon-button tw-p-0 tw-m-0" ng-if="shift.periods.length" ng-click="$shifts.displayPeriods(shift)">
                <md-icon ng-bind="'timelapse'"></md-icon>
            </md-button>

            <div class="md-secondary">
                <span ng-class="{required: !shift.from.isSame(shift.business_date, 'd')}"
                      ng-bind="shift.from.format('LT') + ' - ' + shift.to.format('LT')">
                </span>
                <md-tooltip ng-if="!shift.from.isSame(shift.business_date, 'd')" ng-bind="shift.from.format('LL - LT') + ' ' + shift.to.format('LL - LT')"></md-tooltip>

            </div>
            <md-divider ng-if="!$last"></md-divider>
        </md-list-item>
    </md-list>
</md-virtual-repeat-container>

<!-- LOADING -->
<md-progress-circular ng-if="$shifts.loading" class="tw-mt-24 md-progress-center"></md-progress-circular>

<!-- NO DATA -->
<div id="vacant-list-no-data" ng-i18next="NO_DATA" ng-if="$shifts.loaded && !$shifts.list.getLength()"></div>
`,
    controllerAs: '$shifts',
    bindings: {
        getShifts: '&',
    },
    controller: [ 'EndlessList', 'MatDialogDowngrade', function(EndlessList, MatDialogDowngrade: MatDialog) {
        const ctrl = this;
        ctrl.$onInit = function $onInit() {
            ctrl.list = new EndlessList(ctrl.load, {
                per_page: 20,
                with: [ 'periods', 'comments', 'schedule' ],
            });
            ctrl.list.loadPage(1).then(() => ctrl.loaded = true);
        };
        ctrl.load = function loadEntries(pagination) {
            ctrl.loading = true;
            return ctrl.getShifts({ pagination }).finally(() => ctrl.loading = false);
        };
        ctrl.showComments = (shift) => {
            MatDialogDowngrade.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
                data: {
                    comments: of(shift.comments.map((c) => new Comment(c))),
                },
            });
        };
        ctrl.displayPeriods = (shift) => {
            if (!shift?.periods?.length) {
                return;
            }

            MatDialogDowngrade.open<ShiftPeriodListDialogComponent, ShiftPeriodListDialogData>(ShiftPeriodListDialogComponent, {
                data: {
                    customerId: shift.schedule.customer_id,
                    scheduleId: shift.schedule_id,
                    shiftId: shift.id,
                },
            });
        };
    } ],
});
