import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogSize } from '../dialog-component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { PdfRendererComponent } from '../../components/pdf-renderer/pdf-renderer.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { PdfRendererDialogData } from './pdf-renderer-dialog.service';

@Component({
    selector: 'eaw-pdf-renderer-dialog',
    standalone: true,
    imports: [ CommonModule, PdfRendererComponent, MatDialogModule, MatButtonModule, TranslatePipe ],
    templateUrl: './pdf-renderer-dialog.component.html',
    styleUrl: './pdf-renderer-dialog.component.scss' ,
})
export class PdfRendererDialogComponent extends DialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) override data: PdfRendererDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<PdfRendererDialogComponent>,
    ) {
        data.size ||= DialogSize.Max;

        dialogRef.addPanelClass('pdf-renderer-dialog');
        dialogRef.disableClose = false;

        super(dialogRef, data);
    }
}
