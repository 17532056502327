import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { classifyArrayPaginatedResponse } from '../utils/rxjs/classify';
import { PaginationOptions } from '../interfaces/pagination-options';
import { formatHttpParams } from '../utils/format-http-params';
import { PayTypeLink, PayTypeLinkResponse } from '../models/pay-type-link';
import { DateTime } from 'luxon';

interface GetAllOptions extends PaginationOptions {
    manual?: boolean;
    from?: DateTime;
    to?: DateTime;
}

@Injectable({
    providedIn: 'root',
})
export class PayTypeLinkService {

    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {
    }

    getAll(customerId: number, options: GetAllOptions) {
        return this.http.get<ArrayPaginatedResponse<PayTypeLinkResponse>>(`/customers/${customerId}/pay_type_links`, {
            params: formatHttpParams({
                ...options,
            }),
        }).pipe(classifyArrayPaginatedResponse(PayTypeLink));
    }
}
