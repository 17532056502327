<eaw-dialog-header *ngIf="balanceType && employee" [closeValue]="balanceChanged">
    <span title>{{balanceType.nameTranslation.key | translate:balanceType.nameTranslation.ns | async}}</span>
    <span subtitle>{{employee.name}}</span>
</eaw-dialog-header>

<mat-dialog-content *ngIf="!balanceType" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content class="tw-p-0" *ngIf="balanceType">
    <eaw-balance-history #balanceHistoryComponent [customerId]="data.customerId" [employeeId]="data.employeeId" [balanceType]="balanceType" [deletable]="allowDeleting" (deleted)="balanceChanged = true" height="300px" />
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="addBalance()" *ngIf="showAddBalanceButton">{{'ADD_BALANCE' | translate:'balances' | async}}</button>
    <button mat-button (click)="export()" [disabled]="exporting || !employee || !balanceType">
        <span *ngIf="!exporting">{{'EXPORT' | translate | async}}</span>
        <mat-progress-spinner *ngIf="exporting" mode="indeterminate" diameter="20" strokeWidth="2"></mat-progress-spinner>
    </button>
    <span class="tw-flex-1"></span>
    <button mat-raised-button mat-dialog-close color="primary" [matDialogClose]="balanceChanged">{{'CLOSE' | translate | async}}</button>
</mat-dialog-actions>
