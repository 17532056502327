// @ts-nocheck
import moment from 'moment-timezone';

import { module } from 'angular';

const template1 = `<md-card>
    <md-card-content layout="column">
        <form name="projectionsDateForm">
            <md-input-container>
                <eaw-date-picker ng-model="$projectionsDateSelector.date" required></eaw-date-picker>
            </md-input-container>
            <div layout="row">
                <md-button ng-disabled="projectionsDateForm.$invalid"
                           class="md-raised md-accent"
                           ng-i18next="projections:SELECT_DATE"
                           ng-click="$projectionsDateSelector.goToDate()">
                </md-button>
            </div>
        </form>
    </md-card-content>
</md-card>

<ui-view name="dateProjections"></ui-view>
`;

module('eaw.projections').component('projectionsDateSelector', {
    template: template1,
    controllerAs: '$projectionsDateSelector',
    controller: [ '$state', function($state) {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.date = $state.params.date ? moment($state.params.date, 'YYYY-MM-DD') : null;
            console.log(`ctrl.date`, ctrl.date);
        };

        ctrl.goToDate = () => {
            $state.go(`eaw/app/projections_date_selector/date`, { date: ctrl.date.format('YYYY-MM-DD') });
        };
    } ],
});
