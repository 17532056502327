import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { DateTime } from 'luxon';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { Flexitime, FlexitimeResponse } from '../models/flexitime';
import { map, Observable } from 'rxjs';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { FlexitimeOverview, FlexitimeOverviewResponse } from '../models/flexitime-overview';
import { BusinessDate } from '../../shared/utils/business-date';

interface CreateFlexitimeData {
    business_date: BusinessDate,
    delta: number,
    comment: string,
}

interface GetAllForEmployeeOptions extends PaginationOptions {
    from?: DateTime,
    to?: DateTime,
}
interface GetAllOptions extends PaginationOptions {
    include_inactive: boolean,
}

@Injectable({
    providedIn: 'root',
})
export class FlexitimeService {

    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getOverview(customerId: number, options?: GetAllOptions): Observable<ArrayPaginatedResponse<FlexitimeOverview>> {
        return this.http.get<ArrayPaginatedResponse<FlexitimeOverviewResponse>>(`customers/${customerId}/employees/all/flexitime`, {
            params: formatHttpParams({
                ...options,
                'with[]': 'user',
            }, [ 'business_date' ]),
        }).pipe(classifyArrayPaginatedResponse(FlexitimeOverview));
    }

    getAllForEmployee(customerId: number, employeeId: number, options?: GetAllForEmployeeOptions): Observable<ArrayPaginatedResponse<Flexitime>> {
        return this.http.get<ArrayPaginatedResponse<FlexitimeResponse>>(`customers/${customerId}/employees/${employeeId}/flexitime`, {
            params: formatHttpParams({
                ...options,
                from: options?.from,
                to: options?.to,
            }, [ 'from', 'to' ]),
        }).pipe(classifyArrayPaginatedResponse(Flexitime));
    }

    getEmployeeBalance(customerId: number, employeeId: number, to: DateTime): Observable<number> {
        return this.http.get<{balance: number}>(`/customers/${customerId}/employees/${employeeId}/flexitime/balance`, {
            params: formatHttpParams({
                to,
            }, [ 'to' ]),
        }).pipe(map((response) => response.balance));
    }

    create(customerId: number, employeeId: number, flexitime: CreateFlexitimeData) {
        return this.http.post<FlexitimeResponse>(`/customers/${customerId}/employees/${employeeId}/flexitime`, flexitime).pipe(
            classifyItem(Flexitime),
        );
    }

    delete(customerId: number, employeeId: number, flextimeId: number): Observable<undefined> {
        return this.http.delete<undefined>(`/customers/${customerId}/employees/${employeeId}/flexitime/${flextimeId}`);
    }
}
