// @ts-nocheck
import { module } from 'angular';
import { UIRouter } from '@uirouter/core';

const template1 = `<md-nav-bar md-selected-nav-item="$chains.currentTab" ng-show="$chains.customerGroups.length > 1">
    <md-nav-item ng-repeat="group in ::$chains.customerGroups track by group.id" md-nav-sref="{{$chains.sref}}({ group: {{group.id}} })" name="group{{group.id}}">
        {{group.name}}
    </md-nav-item>
</md-nav-bar>

<md-card ng-if="$chains.useCard">
    <md-card-content class="tw-p-0">
        <ui-view name="chainTabsView"></ui-view>
    </md-card-content>
</md-card>

<ui-view ng-if="!$chains.useCard" name="chainTabsView"></ui-view>
`;

module('eaw.chain-ops').component('chainTabs', {
    template: template1,
    bindings: {
        customerGroups: '<',
        sref: '<',
        useCard: '<',
    },
    controllerAs: '$chains',
    controller: [ '$uiRouter', function($uiRouter: UIRouter) {
        this.$postLink = () => {
            const $state = $uiRouter.stateService;
            const id = $uiRouter.globals.params['group'] ?? this.customerGroups?.[0]?.id;

            this.currentTab = `group${id}`;
            this.useCard ??= false;

            if ($uiRouter.globals.current.name != this.sref) {
                $state.go(this.sref, { group: id });
            }
        };
    } ],
});
