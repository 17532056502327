import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationOptions } from '../interfaces/pagination-options';
import { formatHttpParams } from '../utils/format-http-params';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Bulletin, BulletinResponse } from '../models/bulletin';
import { classifyArrayPaginatedResponse, classifyItem } from '../utils/rxjs/classify';

@Injectable({
    providedIn: 'root',
})
export class BulletinService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getAll(customerId: number, pagination?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<BulletinResponse>>(`/customers/${customerId}/postits`, {
            params: formatHttpParams(pagination),
        }).pipe(classifyArrayPaginatedResponse(Bulletin));
    }

    get(customerId: number, bulletinId: number) {
        return this.http.get<BulletinResponse>(`/customers/${customerId}/postits/${bulletinId}`).pipe(classifyItem(Bulletin));
    }

    create(customerId: number, body: string) {
        return this.http.post<BulletinResponse>(`/customers/${customerId}/postits`, {
            body,
        }).pipe(classifyItem(Bulletin));
    }

    update(customerId: number, bulletinId: number, body: string) {
        return this.http.put<BulletinResponse>(`/customers/${customerId}/postits/${bulletinId}`, {
            body,
        }).pipe(classifyItem(Bulletin));
    }

    delete(customerId: number, bulletinId: number) {
        return this.http.delete(`/customers/${customerId}/postits/${bulletinId}`);
    }
}
