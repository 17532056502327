import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslateService } from '../../../shared/services/translate.service';
import { DateTime } from 'luxon';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Employee } from '../../../shared/models/employee';
import { AbsenceStatsService, FormattedAbsenceStatResponse } from '../../http/absence-stats.service';
import { QueryParamsService } from '../../../shared/services/query-params.service';
import { take } from 'rxjs';
import { CurrentService } from '../../../shared/services/current.service';
import { EmployeeAutocompleteService } from '../../../shared/autocompletes/employee-autocomplete.service';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { AbsenceStatCardComponent } from '../../components/absence-stat-card/absence-stat-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatCardModule } from '@angular/material/card';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';

type StatsForm = {
    employee: FormControl<Employee | number | null | undefined>,
    range: FormGroup<{
        from: FormControl<DateTime | null>,
        to: FormControl<DateTime | null>
    }>
};

@Component({
    selector: 'eaw-absence-statistics',
    templateUrl: './absence-statistics.component.html',
    styleUrl: './absence-statistics.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        ReactiveFormsModule,
        AutocompleteComponent,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        NgIf,
        MatProgressSpinnerModule,
        NgFor,
        AbsenceStatCardComponent,
        AsyncPipe,
        KeyValuePipe,
        TranslatePipe,
        DatePickerOptionsDirective,
    ],
})
export class AbsenceStatisticsComponent implements OnInit {
    @Input() customerId!: number;
    @Input() employeeId?: number;

    defaultFromDate: DateTime;
    defaultToDate: DateTime;
    getting = false;
    form!: FormGroup<StatsForm>;
    resp?: FormattedAbsenceStatResponse;
    noStatsFound?: string;

    constructor(
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(PermissionCheckService) private permissionCheck: PermissionCheckService,
        @Inject(AbsenceStatsService) private absenceStatsService: AbsenceStatsService,
        @Inject(QueryParamsService) private searchParams: QueryParamsService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(EmployeeAutocompleteService) protected employeeAutocompleteService: EmployeeAutocompleteService,
    ) {
        this.defaultFromDate = DateTime.now().startOf('year');
        this.defaultToDate = DateTime.now().endOf('year');
    };

    ngOnInit(): void {
        // Set initial values
        this.customerId = this.searchParams.get('customer', 'number') || this.customerId;
        this.employeeId = this.searchParams.get('employee', 'number') || this.employeeId || this.current.getEmployee()?.id;
        if (!this.customerId) {
            throw Error('Missing customer');
        }

        // Init form
        this.form = new FormGroup({
            employee: new FormControl<Employee | number | undefined>(this.employeeId, Validators.required),
            range: new FormGroup({
                from: new FormControl(this.searchParams.get('from', 'date') || this.defaultFromDate, Validators.required),
                to: new FormControl(this.searchParams.get('to', 'date') || this.defaultToDate, Validators.required),
            }),
        });

        // Disable, maybe
        this.disableEmployeeAutocomplete();

        // Get overview after employee is set
        if (this.employeeId) {
            this.form.controls.employee.valueChanges.pipe(
                take(1),
            ).subscribe((employee) => {
                if (employee instanceof Employee) {
                    this.getOverview();
                }
            });
        }
    }

    /**
     * Disables the autocomplete if missing permissions
     */
    private disableEmployeeAutocomplete() {
        this.permissionCheck.isAllowed(`customers.${this.customerId}.employees.*.absences.*.get`).subscribe((allowed) => {
            if (!allowed) {
                this.form.controls.employee.disable();
            }
        });
    }

    public getOverview() {
        if (this.form.invalid) {
            return;
        }
        if (!this.form.controls.employee.value) {
            return;
        }

        this.getting = true;

        delete this.resp;
        delete this.noStatsFound;
        const from = this.form.controls.range.controls.from.value || this.defaultFromDate;
        const to = this.form.controls.range.controls.to.value || this.defaultToDate;
        const employeeVal = this.form.controls.employee.value;
        const employeeId = employeeVal instanceof Employee ? employeeVal.id : employeeVal;
        const employeeName = employeeVal instanceof Employee ? employeeVal.name : 'No name';

        this.form.disable();

        this.searchParams.set([
            {
                key: 'from',
                value: from,
                type: 'date',
            },
            {
                key: 'to',
                value: to,
                type: 'date',
            },
            {
                key: 'customer',
                value: this.customerId,
            },
            {
                key: 'employee',
                value: employeeId,
            },
        ]);

        this.absenceStatsService.getOverview(this.customerId, employeeId, from, to).subscribe({
            next: async (overview) => {
                this.resp = overview;
                if (!Object.values(overview.statistics).length) {
                    this.noStatsFound = await this.translate.t('NO_STATS_FOUND', 'absences', {
                        name: employeeName,
                        from: from.toLocaleString(DateTime.DATE_SHORT),
                        to: to.toLocaleString(DateTime.DATE_SHORT),
                    });
                }

                this.getting = false;
                this.form.enable();
                this.disableEmployeeAutocomplete();
            },
            error: (e) => {
                this.getting = false;
                this.form.enable();
                console.error(e);
            },
        });
    };
}
