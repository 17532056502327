import { EmergencyContactService } from '../../http/emergency-contact.service';
import { Component, Inject, OnInit } from '@angular/core';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { CurrentService } from '../../../shared/services/current.service';
import { EmergencyContact } from '../../models/emergency-contact';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { of } from 'rxjs';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-emergency-contacts',
    templateUrl: './emergency-contacts.component.html',
    styleUrl: './emergency-contacts.component.scss' ,
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class EmergencyContactsComponent implements OnInit, EawDataTable {
    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<EmergencyContact>[] = [
        new DataTableTextColumn({
            value: 'employeeName',
            header: new DataTableHeader({ i18n: 'EMPLOYEE' }),
        }),
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableTextColumn({
            value: 'relation',
            header: new DataTableHeader({
                i18n: 'RELATION',
                ns: 'company',
            }),
        }),
        new DataTableTextColumn({
            value: 'phone',
            header: new DataTableHeader({
                i18n: 'PHONE',
                sortBy: 'phone',
            }),
        }),
    ];

    constructor(
        @Inject(EmergencyContactService) private emergencyContactService: EmergencyContactService,
        @Inject(CurrentService) public current: CurrentService,
    ) {
    }

    ngOnInit(): void {
        this.updateTable({
            direction: 'asc',
            order_by: 'name',
            page: 1,
            per_page: 10,
        });
    }

    updateTable(pagination: DataTablePagination) {
        this.request = this.emergencyContactService.getAll(this.current.getCustomer().id, pagination);
    }
}
