import { module } from 'angular';
import { Products } from '../../../shared/enums/products';
import { FlexitimeComponent } from '../../pages/flexitime/flexitime.component';
import { FlexitimeEmployeeComponent } from '../../pages/flexitime-employee/flexitime-employee.component';
import { createState } from '../../../shared/utils/create-state';
import { currentResolver } from '../../../shared/resolvers/current.resolver';
import { paramResolver } from '../../../shared/resolvers/param.resolver';
import { StateProvider } from '@uirouter/angularjs';
import { CurrentService } from '../../../shared/services/current.service';

module('eaw.payroll.flexitime', [
    'eaw.alerts',
]).config([ '$stateProvider', ($stateProvider: StateProvider) => {

    createState($stateProvider, {
        name: 'eaw/app/payroll/flexitime',
        parent: 'eaw/app/payroll',
        url: '/flexitime',
        views: {
            'content@': {
                component: FlexitimeComponent,
            },
        },
        data: {
            breadcrumb: { key: 'FLEXITIME', ns: 'navigation' },
            permissionCheck: (permissionCheckService, injector) => {
                const customerId = injector.get(CurrentService).getCustomer().id;
                return permissionCheckService.permissionOrChildren(`customers.${customerId}.employees.*.flexitime.*.get`, undefined, `customers.${customerId}.employees`, `flexitime.*.get`, true)
            },
            products: [ Products.FlexiTime ],
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
    });

    createState($stateProvider, {
        name: 'eaw/app/payroll/flexitime/employee',
        parent: 'eaw/app/payroll/flexitime',
        url: '/employee/:employee_id',
        views: {
            'content@': {
                component: FlexitimeEmployeeComponent,
            },
        },
        data: {
            breadcrumb: { key: 'EMPLOYEE' },
            products: [ Products.FlexiTime ],
            permissions: [ `customers.{customer}.employees.{employee_id}.flexitime.*.get` ],
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            paramResolver('employee_id', 'employeeId'),
        ],
    });

    createState($stateProvider, {
        name: 'eaw/app/payroll/my/flexitime',
        parent: 'eaw/app/payroll/my',
        url: '/flexitime',
        views: {
            myPayrollView: {
                component: FlexitimeEmployeeComponent,
            },
        },
        data: {
            breadcrumb: { key: 'EMPLOYEE' },
            products: [ Products.FlexiTime ],
            permissions: [ `customers.{customer}.employees.{employee}.flexitime.*.get` ],
        },
        resolve: [
            currentResolver('customerId', 'employee', 'customerId'),
            currentResolver('employeeId', 'employee', 'id'),
        ],
    });
} ]);
