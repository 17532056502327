// @ts-nocheck
import { module } from 'angular';
module('eaw.checklists').factory('ChecklistSubscription', [ 'EawResource', 'Pagination', function(EawResource, Pagination) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/checklists/:checklist/subscriptions/:subscription');
    fac.getAll = {
        permission(customerId, checklistId) {
            return `customers.${customerId}.checklists.${checklistId}.update`;
        },
        query(args) {
            return res.get({
                customer: args.customerId,
                checklist: args.checklistId,
                ...Pagination.getParams(args),
            });
        },
    };
    fac.subscribe = {
        permission(customerId, checklistId) {
            return `customers.${customerId}.checklists.${checklistId}.update`;
        },
        query(customerId, checklistId) {
            return res.save({
                customer: customerId,
                checklist: checklistId,
            }, {}); // {} is required for post to work
        },
    };
    fac.unsubscribe = {
        permission(customerId, checklistId) {
            return `customers.${customerId}.checklists.${checklistId}.update`;
        },
        query(customerId, checklistId, subscriptionId) {
            return res.delete({
                customer: customerId,
                checklist: checklistId,
                subscription: subscriptionId,
            });
        },
    };
    return fac;
} ]);
