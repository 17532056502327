<eaw-page-header class="tw-mt-8">
    <span title></span>

    <div smallFilter>
        <form [formGroup]="filterForm" class="tw-flex tw-gap-8">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>{{ 'STATUS' | translate | async }}</mat-label>
                <mat-select formControlName="closed">
                    <mat-option [value]="false">{{ 'OPEN' | translateSync }}</mat-option>
                    <mat-option [value]="true">{{ 'CLOSED' | translateSync: 'forms' }}</mat-option>
                    <mat-option value="">{{ 'ALL' | translateSync }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>{{ 'INCLUDE_ANONYMIZED' | translate: 'forms' | async }}</mat-label>
                <mat-select formControlName="anonymized">
                    <mat-option [value]="false">{{ 'NO' | translateSync }}</mat-option>
                    <mat-option [value]="true">{{ 'ANONYMIZED_ONLY' | translateSync: 'forms' }}</mat-option>
                    <mat-option value="">{{ 'YES' | translateSync }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</eaw-page-header>

<mat-card class="mat-compact-card">
    <mat-card-content>
        <eaw-data-table
                [columns]="columns"
                [request]="request"
                (paginationChange)="updateTable($event)">
        </eaw-data-table>
    </mat-card-content>
</mat-card>
