import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { classifyItem } from '../../shared/utils/rxjs/classify';
import { FormSubmissionNote, FormSubmissionNoteResponse } from '../models/form-submission-note';

@Injectable({
    providedIn: 'root',
})
export class FormSubmissionNoteService {
    protected http = inject(HttpClient);

    create(customerId: number, formId: number, submissionId: number, body: string, is_visible: boolean) {
        return this.http.post<FormSubmissionNoteResponse>(`/customers/${customerId}/forms/${formId}/submissions/${submissionId}/notes`, {
            body,
            is_visible,
        }).pipe(classifyItem(FormSubmissionNote));
    }
}
