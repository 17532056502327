<eaw-widget-settings-dialog [widgetInfo]="data.widgetInfo" [valid]="selectedType.valid" (submitted)="submitted()">
    <div content class="tw-flex tw-flex-col">
        @if (loading()) {
            <eaw-info-loading size="md"/>
        } @else {
            <mat-form-field>
                <mat-label>{{ 'TYPE' | translate | async }}</mat-label>
                <mat-select [formControl]="selectedType">
                    @for (type of kpiTypes(); track type.value.id) {
                        <mat-option [value]="type.value">{{ type.name }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
    </div>
</eaw-widget-settings-dialog>
