import { inject, Injectable } from '@angular/core';
import { ProductService } from '../services/product.service';
import { Autocomplete, AutocompleteGetter, AutocompleteSetter } from './autocomplete';
import { Product } from '../models/product';
import { TranslateService } from '../services/translate.service';
import { Observable, of, shareReplay } from 'rxjs';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';
import { Products } from '../enums/products';

@Injectable({
    providedIn: 'root',
})
export class ProductAutocompleteService extends Autocomplete<Product> {
    private readonly productService = inject(ProductService);

    private gettingAllProducts?: Observable<ArrayPaginatedResponse<Product>>;

    constructor() {
        super({
            label: inject(TranslateService).t('PRODUCT_plural', 'admin'),
            display: 'name',
            trackByKey: 'name',
        });
    }

    getter(): AutocompleteGetter<Product> {
        return (filter?: string) => {
            const paginationOptions = {
                per_page: 250,
                filter: filter?.toLowerCase(),
                order_by: 'name',
            };

            if (filter) {
                return this.productService.getAll(paginationOptions).pipe(shareReplay(1));
            }

            if (!this.gettingAllProducts) {
                this.gettingAllProducts = this.productService.getAll(paginationOptions).pipe(shareReplay(1));
            }

            return this.gettingAllProducts;
        };
    }

    setter(): AutocompleteSetter<Product> {
        return (item: unknown) => {
            if (item instanceof Product) {
                return of(item);
            }

            if (typeof item !== 'string') {
                return of(undefined);
            }

            // Check if the string is one of the keys of the ProductName enum
            return item in Products ? this.productService.get(item) : of(undefined);
        };
    }
}
