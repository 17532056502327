// @ts-nocheck
import { module } from 'angular';
module('eaw.scheduling.backoffice').factory('BackOffice', [ 'EawResource', function(EawResource) {
    const fac = this;
    const res = EawResource.create('/customers/:customer/schedules/:schedule/backoffice_budgets');
    fac.sync = {
        permission(customer, schedule) {
            return `customers.${customer}.schedules.${schedule}.update`;
        },
        query(customer, schedule) {
            return res.save({
                customer,
                schedule,
            }, {}).$promise;
        },
    };
    return fac;
} ]);
