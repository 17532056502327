import { ChangeDetectionStrategy, Component, computed, DestroyRef, ElementRef, inject, input, OnInit, signal } from '@angular/core';
import { ScheduleTabInterval } from '../../schedule-tab.component';
import { NumberPipe } from '../../../../../../../shared/pipes/number.pipe';
import { HttpClient, HttpContext } from '@angular/common/http';
import { ArrayPaginatedResponse } from '../../../../../../../shared/interfaces/paginated-response';
import { ScheduleComponent } from '../../../../schedule.component';
import { catchError, forkJoin, map, of, tap } from 'rxjs';
import { NumberFormatterService } from '../../../../../../../shared/services/number-formatter.service';
import { AsyncPipe, KeyValuePipe, NgStyle } from '@angular/common';
import { EfficiencyService } from '../../../../../../http/efficiency.service';
import { expandAllPages } from '../../../../../../../shared/utils/rxjs/expand-all-pages';
import { inRange } from '../../../../../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { Efficiency } from '../../../../../../models/efficiency';
import { IGNORE_ERROR } from '../../../../../../../shared/http/http-contexts';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressBar } from '@angular/material/progress-bar';

interface Stat {
    // The number as it was calculated
    number: number;
    // The number after it has been rounded according to whatever rules apply
    rounded?: number;
    // The number as it should be displayed
    formatted: string;
    color?: string;
}

type Stats = { name: Promise<string>, values: Stat[] }[];

@Component({
    selector: 'eaw-schedule-tab-top-stats',
    standalone: true,
    imports: [
        NumberPipe,
        KeyValuePipe,
        AsyncPipe,
        NgStyle,
        MatProgressBar,
    ],
    templateUrl: './schedule-tab-top-stats.component.html',
    styleUrl: './schedule-tab-top-stats.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleTabTopStatsComponent implements OnInit {
    private http = inject(HttpClient);
    private numberFormatterService = inject(NumberFormatterService);
    private efficiencyService = inject(EfficiencyService);
    private destroyRef = inject(DestroyRef);
    private el = inject(ElementRef) as ElementRef<HTMLElement>;

    customerId = input.required<number>();
    scheduleId = input.required<number>();
    renderedIntervals = input.required<ScheduleTabInterval[]>();

    protected budgetData = signal<{budget: number, index: number}[]>([]);
    protected budgets = computed<Stat[]>(this.computeBudgets.bind(this));
    protected efficiencyData = signal<Efficiency[]>([]);
    protected efficiencies = computed<Stat[]>(this.computeEfficiencies.bind(this));
    protected estimatedNeed = computed<Stat[]>(this.calcEstimatedNeed.bind(this));
    protected workingHours = computed<Stat[]>(this.calcWorkingHours.bind(this));
    protected hoursDiff = computed<Stat[]>(this.calcHoursDiff.bind(this));
    protected stats = computed<Stats>(this.computeStats.bind(this));
    protected loading = signal(true);

    ngOnInit() {
        this.getData().subscribe(() => this.loading.set(false));
    }

    protected onMouseoverName() {
        this.el.nativeElement.classList.add('hover-name');
    }

    protected onMouseleaveName() {
        this.el.nativeElement.classList.remove('hover-name');
    }

    private getData() {
        const budgetsObservable = this.getBudgets(this.customerId(), this.scheduleId()).pipe(
            takeUntilDestroyed(this.destroyRef),
            catchError(() => of([] as {budget: number, index: number}[])),
            tap((budgets) => this.budgetData.set(budgets)),
        );

        const efficienciesObservable = expandAllPages((pagination) => this.efficiencyService.getAll(this.customerId(), this.scheduleId(), pagination, new HttpContext().set(IGNORE_ERROR, [ 404 ])), {
            per_page: 200,
        }).pipe(
            takeUntilDestroyed(this.destroyRef),
            catchError(() => of([] as Efficiency[])),
            tap((efficiencies) => this.efficiencyData.set(efficiencies)),
        );

        return forkJoin([ budgetsObservable, efficienciesObservable ]);
    }

    private computeStats() {
        return [
            { name: Promise.resolve('Budgets'), values: this.budgets() },
            { name: Promise.resolve('Efficiency'), values: this.efficiencies() },
            { name: Promise.resolve('Estimated need'), values: this.estimatedNeed() },
            { name: Promise.resolve('Working hours'), values: this.workingHours() },
            { name: Promise.resolve('Difference'), values: this.hoursDiff() },
        ];
    }

    private calcHoursDiff(): Stat[] {
        const workingHours = this.workingHours();
        const estimatedNeed = this.estimatedNeed();

        return workingHours.map((working, i) => {
            const estimated = estimatedNeed[i]?.rounded || 0;
            const diff = (working.rounded || 0) - estimated;
            const roundedDiff = Math.round((diff) * 2) / 2;

            // Color
            const colorVal = Math.min(1, estimated ? Math.abs(diff / estimated) : 0);
            const hue = ((1 - colorVal) * 120).toString(10);

            return {
                number: diff,
                rounded: roundedDiff,
                formatted: this.numberFormatterService.formatDecimal(roundedDiff, 2, undefined, { signDisplay: 'exceptZero' }),
                color: `hsl(${hue},100%,87.5%)`,
            };
        });
    }

    private calcWorkingHours(): Stat[] {
        const renderedIntervals = this.renderedIntervals();
        const shifts = Array.from(ScheduleComponent.shifts().values());

        const from = renderedIntervals[0]?.offset || 0;
        const to = renderedIntervals[renderedIntervals.length - 1]?.offset || 0;
        const renderedShifts = shifts.filter((shift) => !(shift.offset > to || shift.rightOffset < from));

        return renderedIntervals.map((i) => {
            const working = renderedShifts.reduce((acc, shift) => {
                // Get the overlap of the shift and the interval
                const shiftOverlap = shift.interval.intersection(i.interval);

                // Get the overlap of the unproductive periods and the interval
                const periodsOverlap = shift.periods.filter((p) => p.unproductive).reduce((acc, period) => {
                    return acc + (period.interval.intersection(i.interval)?.length('seconds') || 0);
                }, 0);

                return acc + (shiftOverlap?.length('seconds') || 0) - periodsOverlap;
            }, 0) / 3600;

            const roundedWorking = Math.round((working) * 2) / 2;
            return {
                number: working,
                rounded: roundedWorking,
                formatted: this.numberFormatterService.formatDecimal(roundedWorking),
            };
        });
    }

    private calcEstimatedNeed(): Stat[] {
        const budgets = this.budgets();
        const efficiencies = this.efficiencies();

        return budgets.map((budget, i) => {
            const efficiency = efficiencies[i]?.number;
            const estimatedNeed = efficiency ? budget.number / efficiency : 0;
            const roundedNeed = Math.round((estimatedNeed) * 2) / 2;

            return {
                number: estimatedNeed,
                rounded: roundedNeed,
                formatted: this.numberFormatterService.formatDecimal(roundedNeed),
            };
        });
    }

    private computeEfficiencies(): Stat[] {
        const efficiencies = this.efficiencyData();

        return this.budgets().map((budget) => {
            const efficiency = efficiencies.find((x) => inRange(budget.number, x.budgetLow, x.budgetHigh))?.value || 0;

            return {
                number: efficiency,
                formatted: this.numberFormatterService.formatInteger(efficiency),
            };
        });
    }

    private computeBudgets(): Stat[] {
        const intervals = this.renderedIntervals();
        const budgetsData = this.budgetData();
        const scheduleInterval = ScheduleComponent.properties.scheduleTab.interval.value();
        const budgetingInterval = ScheduleComponent.properties.schedule.budgetingInterval.value() || 3600;
        const budgetsPerInterval = scheduleInterval / budgetingInterval;
        const intervalsPerBudget = budgetingInterval / scheduleInterval;

        if (scheduleInterval === budgetingInterval) {
            return intervals.map((i) => {
                const budget = budgetsData.find((x) => x.index === i.index);
                const value = budget?.budget || 0;

                return {
                    number: value,
                    formatted: this.numberFormatterService.formatInteger(value),
                };
            });
        }

        if (Number.isInteger(intervalsPerBudget) && intervalsPerBudget > 1) {
            return intervals.map((i) => {
                const budget = budgetsData.find((x) => x.index === Math.floor(i.index / intervalsPerBudget));
                const value = (budget?.budget || 0) / intervalsPerBudget;

                return {
                    number: value,
                    formatted: this.numberFormatterService.formatInteger(value),
                };
            });
        }

        if (Number.isInteger(budgetsPerInterval) && budgetsPerInterval > 1) {
            return intervals.map((i) => {
                const budgets = budgetsData.filter((x) => x.index >= i.index * budgetsPerInterval && x.index < (i.index + 1) * budgetsPerInterval);
                const sum = budgets.reduce((acc, x) => acc + x.budget, 0);

                return {
                    number: sum,
                    formatted: this.numberFormatterService.formatInteger(sum),
                };
            });
        }

        return intervals.map(() => ({ number: 0, formatted: '0' }));
    }

    private getBudgets(customerId: number, scheduleId: number) {
        return this.http.get<ArrayPaginatedResponse<{budget: number, index: number}>>(`customers/${customerId}/schedules/${scheduleId}/budgets`, {
            params: {
                per_page: 1000,
                sort_by: 'index',
            },
            context: new HttpContext().set(IGNORE_ERROR, [ 404 ]),
        }).pipe(
            catchError(() => of({ data: [] })),
            map((response) => response.data),
        );
    }

    refresh() {
        this.loading.set(true);
        this.getData().subscribe(() => this.loading.set(false));
    }
}
