import { Component, Inject, Input, signal, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { of } from 'rxjs';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { CustomerGroupService } from '../../../shared/http/customer-group.service';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { CustomerGroupMember } from '../../../shared/models/customer-group-member';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from '../../../shared/dialogs/dialog-component';
import { ChooseCustomerDialogComponent } from '../../dialogs/choose-customer-dialog/choose-customer-dialog.component';
import { Customer } from '../../../shared/models/customer';
import { DataTableComponent, DataTableGoTo } from '../../../data-table/data-table.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-admin-customer-group-customers',
    templateUrl: './admin-customer-group-customers.component.html',
    styleUrl: './admin-customer-group-customers.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AdminCustomerGroupCustomersComponent implements EawDataTable {
    @ViewChild('table') table?: DataTableComponent<CustomerGroupMember>;

    @Input({ required: true }) groupId!: number;

    fabButton: HeaderFabButton = {
        click: this.addCustomer.bind(this),
        hasPermission: () => of(true),
    };

    headerButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            icon: 'history',
            click: this.toggleInactive.bind(this),
            menuText: signal(this.translate.t('INCLUDE_INACTIVE')),
            active: () => this.showInactive,
        }),
    ];

    showInactive = false;
    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<CustomerGroupMember>[] = [
        new DataTableNumberColumn({
            value: 'id',
            header: new DataTableHeader({
                text: 'Id',
                sortBy: 'id',
            }),
        }),
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableDateTimeColumn({
            format: DateTime.DATETIME_MED,
            value: (cell) => cell.item.pivot.from,
            header: new DataTableHeader({
                i18n: 'FROM',
            }),
        }),
        new DataTableDateTimeColumn({
            format: DateTime.DATETIME_MED,
            value: (cell) => cell.item.pivot.to,
            header: new DataTableHeader({
                i18n: 'TO',
            }),
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    icon: 'delete',
                    type: 'warn',
                    click: this.deleteCustomer.bind(this),
                    show: (member) => this.permissionCheckService.isAllowed(`customer_groups.${this.groupId}.members.${member.id}.delete`),
                    tooltip: { key: 'DELETE' },
                },
            ],
        }),
    ];

    goTo: DataTableGoTo = {
        state: 'eaw/app/admin/customers/view/info',
        params: [ { stateKey: 'id', itemKey: 'id' } ],
    };

    constructor(
        @Inject(CustomerGroupService) private customerGroupService: CustomerGroupService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(ConfirmDialogService) private confirmDialogService: ConfirmDialogService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(MatDialog) private matDialog: MatDialog,
    ) {
    }

    toggleInactive() {
        this.showInactive = !this.showInactive;
        this.table?.refresh();
    }

    addCustomer() {
        this.matDialog.open<ChooseCustomerDialogComponent, DialogData, Customer>(ChooseCustomerDialogComponent).afterClosed().subscribe((customer) => {
            if (!customer) {
                return;
            }

            this.customerGroupService.addMember(this.groupId, customer.id).subscribe(() => {
                this.table?.refresh();
            });
        });
    }

    deleteCustomer(cell: DataTableButtonCell<CustomerGroupMember>) {
        this.confirmDialogService.delete({
            title: this.translate.t('REMOVE_MEMBER', 'admin'),
            text: this.translate.t('THIS_WILL_REMOVE_X_FROM_Y', 'general', { name1: cell.item.name, name2: this.groupId }),
            confirmText: this.translate.t('REMOVE_NAME', 'general', { name: cell.item.name }),
        }).afterClosed().subscribe((result) => {
            if (!result?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.customerGroupService.removeMember(this.groupId, cell.item.id).subscribe(() => {
                this.table?.refresh();
            });
        });
    }

    updateTable(pagination: Partial<DataTablePagination>) {
        this.request = this.customerGroupService.getMembers(this.groupId, {
            ...pagination,
            include_inactive: this.showInactive,
        });
    }
}
