// @ts-nocheck
import { module } from 'angular';

const template1 = `<md-dialog>
    <form ng-cloak id="vacationRequestForm" role="form" autocomplete="off" name="vacReqForm" novalidate>
        <eaw-dialog-header-old heading="'vacation:NEW_REQUEST' | i18next"></eaw-dialog-header-old>

        <md-dialog-content>
            <md-progress-linear ng-if="ctrl.loading"></md-progress-linear>

            <md-subheader ng-if="ctrl.vacDays.length && !ctrl.loading">
                <div ng-repeat="days in ctrl.vacDays" ng-bind="days"></div>
            </md-subheader>

            <off-time-request layout="column" layout-padding ng-model="ctrl.offtime" ng-change="ctrl.offtimeChange()" min-date="ctrl.minDate"></off-time-request>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <md-button close-dialog ng-i18next="CANCEL"></md-button>
            <md-button ng-click="ctrl.submit(ctrl.offtime)" ng-i18next="SUBMIT" ng-disabled="vacReqForm.$invalid"></md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

module('eaw.absence').service('OffTimeDialogService', [ '$mdDialog', function($mdDialog) {
    const service = this;

    /**
     * @param {Object}          [args]
     * @param {object}          [args.customer]
     * @param {object}          [args.employee]
     * @param {moment.Moment}   [args.minDate]
     * @returns {Promise}
     */
    service.open = (args) => $mdDialog.show({
        template: template1,
        controller: 'offTimeDialogCtrl',
        controllerAs: 'ctrl',
        bindToController: true,
        locals: {
            args,
        },
    });

    return service;
} ]);
