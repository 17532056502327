// @ts-nocheck
import { t } from 'i18next';
import { module } from 'angular';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
import { WysiwygDialogComponent, WysiwygDialogData, WysiwygDialogReturn } from '../../../wysiwyg/dialogs/wysiwyg-dialog/wysiwyg-dialog.component';
import { from, of, tap } from 'rxjs';

module('eaw.messages').controller('messageInfoDialogCtrl', [ 'message', '$mdDialog', 'Message', 'ToastService', 'MatDialogDowngrade', function messageInfoDialogCtrl(message, $mdDialog, Message, ToastService, MatDialogDowngrade) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.me = CurrentOld.user;
        ctrl.message = message;

        ctrl.myMessage();
        ctrl.setThreadId();
    };

    ctrl.myMessage = () => {
        ctrl.isMyMessage = ctrl.message.user.id === ctrl.me.id;
    };

    ctrl.setThreadId = () => {
        // If the message doesn't have a thread id it means it is the first message of the thread
        ctrl.message.thread_id = ctrl.message.thread_id || ctrl.message.id;
    };

    ctrl.confirmRead = () => Message.confirm(ctrl.message.id).$promise.then((data) => {
        // Update message object in table
        ctrl.tableMessage.pivot.confirmed_at = data.confirmed_at;
        // Update message here in dialog
        ctrl.message.confirmed_at = data.confirmed_at;
        ToastService.toast(t('messages:CONFIRMED_MSG_READ'));
    });

    ctrl.sendReminder = () => {
        Message.remind(ctrl.message).$promise.then(() => {
            ToastService.toast(t('messages:REMINDER_SENT'));
        });
    };

    ctrl.downloadAttachment = (attachment) => {
        Message.downloadAttachment(attachment.owner_id, attachment.id);
    };

    ctrl.reply = (all: boolean) => {
        MatDialogDowngrade.open<WysiwygDialogComponent<Message | null>, WysiwygDialogData<Message | null>, WysiwygDialogReturn<Message | null>>(WysiwygDialogComponent, {
            data: {
                title: Promise.resolve(t(all ? 'messages:REPLY_ALL' : 'messages:REPLY')),
                submitText: Promise.resolve(t(all ? 'messages:REPLY_ALL' : 'messages:REPLY')),
                submitFn: (result) => {
                    if (!result) {
                        return of(null);
                    }

                    const sendTo = all ? { thread_id: ctrl.message.thread_id } : { recipients: [ ctrl.message.user_id ] };

                    return from(Message.sendMessage(ctrl.message.subject, result, sendTo).$promise as Promise<Message>).pipe(tap(() => {
                        $mdDialog.hide();
                        ToastService.toast(t('messages:MESSAGE_SENT'));
                    }));
                },
            },
        })
    };
} ]);
