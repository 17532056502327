@if (loading()) {
    <eaw-info-loading size="md"/>
} @else {
    <ul role="list">
        @for (event of events(); track event.id) {
            <li class="event">
                <div class="tw-flex tw-flex-row tw-justify-between">
                    <h3 class="name tw-flex tw-flex-row">{{ event.name }}</h3>
                    <h3 class="time">
                        {{ event.to | DateTime: 'DATETIME_SHORT' }} -
                        {{ event.from | DateTime: 'DATETIME_SHORT' }}
                    </h3>
                </div>
                <p class="description">{{ event.description }}</p>
                <mat-divider></mat-divider>
            </li>
        }
    </ul>
}
