import { Component, Directive, ElementRef, Inject, Injector, Input, NgModule } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    selector: 'staffing-templates-upgraded',
    standalone: true
})
export class staffingTemplatesUpgradedDirective extends UpgradeComponent {
    @Input() customer!: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        super('staffingTemplates', elementRef, injector);
    }
}

@Component({
    template: '<staffing-templates-upgraded [customer]="customer"></staffing-templates-upgraded>',
    standalone: true,
    imports: [staffingTemplatesUpgradedDirective],
})
export class staffingTemplatesUpgradedComponent {
    @Input() customer!: any;
}

@NgModule({
    imports: [staffingTemplatesUpgradedDirective,
        staffingTemplatesUpgradedComponent],
})
export class staffingTemplatesUpgradeModule {
}
