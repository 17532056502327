import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { DataTablePortalComponent } from '../../types/data-table-portal-component';
import { DataTableIconColumn } from '../../types/data-table-icon-column';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'eaw-progress-portal',
    standalone: true,
    imports: [
        MatProgressSpinner,
    ],
    templateUrl: './progress-portal.component.html',
    styleUrl: './progress-portal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressPortalComponent<Item> extends DataTablePortalComponent<Item, unknown, DataTableIconColumn<Item>> implements OnInit {
    protected value = signal<number | undefined>(undefined);

    constructor() {
        super();
    }

    async ngOnInit() {
        const value = await this.getValue<number>();

        if (value == null) {
            this.value.set(undefined);
            return;
        } else {
            this.value.set(value);
        }
    }

    updateValue(value: number | undefined) {
        this.value.set(value);
    }
}
