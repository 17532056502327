import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { SwapApplicant } from '../../models/swap-applicant';
import { ShiftSwap } from '../../models/shift-swap';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { ApproveSwapDialogComponent,
    ApproveSwapDialogData } from '../approve-swap-dialog/approve-swap-dialog.component';
import { EMPTY, of, Subscription, switchMap } from 'rxjs';
import { TranslateService } from '../../../shared/services/translate.service';
import { SwapApplicantService } from '../../http/swap-applicant.service';
import { ShiftSwapSummaryService } from '../../http/shift-swap-summary.service';
import { EmployeeSwapSummary } from '../../models/employee-swap-summary';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatButtonModule } from '@angular/material/button';
import { SwapSummaryComponent } from '../../components/swap-summary/swap-summary.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

export interface ChooseApplicantDialogData extends DialogData {
    swap: ShiftSwap,
}

@Component({
    selector: 'eaw-choose-applicant-dialog',
    templateUrl: './choose-applicant-dialog.component.html',
    styleUrl: './choose-applicant-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatExpansionModule,
        MatProgressSpinnerModule,
        SwapSummaryComponent,
        NgFor,
        MatButtonModule,
        MaterialColorDirective,
        MatIconModule,
        MatDialogActions,
        AsyncPipe,
        TranslatePipe,
        NumberPipe,
    ],
})
export class ChooseApplicantDialogComponent extends DialogComponent<ChooseApplicantDialogData, ShiftSwap, ChooseApplicantDialogComponent> implements OnInit, OnDestroy {

    applicants: SwapApplicant[];
    summary?: Record<string | number, EmployeeSwapSummary>;
    swap: ShiftSwap;
    subscription?: Subscription;
    getSummarySub?: Subscription;

    protected changed: boolean = false;

    constructor(
        @Inject(MatDialogRef) ref: MatDialogRef<ChooseApplicantDialogComponent, ShiftSwap>,
        @Inject(MAT_DIALOG_DATA) data: ChooseApplicantDialogData,
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(ConfirmDialogService) protected confirmDialog: ConfirmDialogService,
        @Inject(TranslateService) protected translate: TranslateService,
        @Inject(SwapApplicantService) protected swapApplicantService: SwapApplicantService,
        @Inject(ShiftSwapSummaryService) protected swapSummaryService: ShiftSwapSummaryService,
    ) {
        data.size = DialogSize.Medium;
        super(ref, data);

        this.applicants = data.swap.validApplicants;
        this.swap = data.swap;
    }

    ngOnInit(): void {
        this.getSummary();
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
        this.getSummarySub?.unsubscribe();
    }

    getSummary() {
        this.getSummarySub = this.swapSummaryService.getSwapSummary(this.swap.customerId, this.swap.id).subscribe((summary) => {
            this.summary = summary.reduce((carry, s) => {
                carry[s.employeeId] = s;

                return carry;
            }, {} as Record<string | number, EmployeeSwapSummary>);
        });
    }

    choose(applicant: SwapApplicant) {
        this.subscription?.unsubscribe();

        this.subscription = this.matDialog.open<ApproveSwapDialogComponent, ApproveSwapDialogData, ShiftSwap>(ApproveSwapDialogComponent, {
            data: {
                summaries: this.summary,
                swap: this.swap,
                applicant,
            },
        }).afterClosed().pipe(
            switchMap((result) => {
                return result ? of(result) : EMPTY;
            }),
        ).subscribe(() => {
            this.dialogRef.close(this.swap);
        });
    }

    dismiss(applicant: SwapApplicant) {
        this.subscription?.unsubscribe();

        this.subscription = this.confirmDialog.delete({
            title: this.translate.t('DECLINE', 'scheduling'),
            text: this.translate.t('DISMISS_SWAP_APPLICATION', 'scheduling', { name: applicant.employeeName }),
            confirmText: this.translate.t('DECLINE_SWAP'),
            comment: { include: true },
        }).afterClosed().pipe(
            switchMap((result) => {
                if (!result?.ok) {
                    return EMPTY;
                }

                const swap = this.swap;

                return this.swapApplicantService.dismissSwapApplicant(swap.customerId, swap.id, applicant.id, result.comment);
            }),
        ).subscribe(() => {
            this.changed = true;
            this.applicants = this.applicants.filter((a) => a.id != applicant.id);
        });
    }

    close() {
        this.dialogRef.close(this.changed ? this.swap : undefined);
    }
}
