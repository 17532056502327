// @ts-nocheck
import { module } from 'angular';
import { LanguageListComponent } from '../../pages/language-list/language-list.component';
module('eaw.admin').config([ '$stateProvider', function($stateProvider: any) {
    $stateProvider.state('eaw/app/admin/languages', {
        parent: 'eaw/app/admin',
        url: '/languages',
        views: {
            'content@': {
                component: LanguageListComponent,
            },
        },
        data: {
            breadcrumb: { key: 'LANGUAGES', ns: 'navigation' },
            permissions: [ `languages.*.get` ],
        },
    });
} ]);
