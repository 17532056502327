import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { ShiftSwapService } from '../../http/shift-swap.service';
import { SwapApplicantService } from '../../http/swap-applicant.service';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { ShiftSwap } from '../../models/shift-swap';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { DateTime } from 'luxon';
import { DataTableNumberColumn } from '../../../data-table/types/data-table-number-column';
import { DataTableButtonCell, DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { TranslateService } from '../../../shared/services/translate.service';
import { EMPTY, of, shareReplay, switchMap, tap } from 'rxjs';
import { CurrentService } from '../../../shared/services/current.service';
import { MatDialog } from '@angular/material/dialog';
import { WarningsDialogComponent } from '../../../shared/dialogs/warnings-dialog/warnings-dialog.component';
import { CommentDialogComponent, CommentDialogData } from '../../../shared/dialogs/comments-dialog/comment-dialog.component';
import { User } from '../../../shared/models/user';
import { DialogSize } from '../../../shared/dialogs/dialog-component';
import { WarningsDialogData } from '../../../shared/dialogs/warnings-dialog/warnings-dialog.service';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { SnackBarService } from '../../../shared/services/snack-bar.service';
import { ApproveSwapDialogComponent, ApproveSwapDialogData } from '../../dialogs/approve-swap-dialog/approve-swap-dialog.component';
import { ChooseApplicantDialogComponent, ChooseApplicantDialogData } from '../../dialogs/choose-applicant-dialog/choose-applicant-dialog.component';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { Customer } from '../../../shared/models/customer';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-handle-swaps',
    templateUrl: './handle-swaps.component.html',
    styleUrl: './handle-swaps.component.scss',
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class HandleSwapsComponent implements OnInit, EawDataTable<ShiftSwap> {
    @ViewChild(DataTableComponent) dataTable?: DataTableComponent<ShiftSwap>;

    columns: DataTableColumnType<ShiftSwap>[] = [];
    request: DataTableRequest = of(mockArrayPaginatedResponse());

    protected customer!: Customer;
    protected user!: User;

    constructor(
        @Inject(ShiftSwapService) protected shiftSwapService: ShiftSwapService,
        @Inject(SwapApplicantService) protected swapApplicantService: SwapApplicantService,
        @Inject(TranslateService) protected translate: TranslateService,
        @Inject(CurrentService) protected current: CurrentService,
        @Inject(MatDialog) protected matDialog: MatDialog,
        @Inject(ConfirmDialogService) protected confirmDialog: ConfirmDialogService,
        @Inject(SnackBarService) protected snackbar: SnackBarService,
        @Inject(PermissionCheckService) protected permissionCheckService: PermissionCheckService,
    ) {
    }

    ngOnInit(): void {
        this.customer = this.current.getCustomer();
        this.user = this.current.getUser();
        this.createColumns();
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.shiftSwapService.getAll(this.customer.id, {
            ...pagination,
            can_approve: true,
            handled: false,
            'with[]': [
                'fromEmployee',
                'toEmployee',
                'comments.user',
                'applicants.employee',
                'shift.schedule',
                'shift.qualifications',
                'shift.periods',
            ],
        }).pipe(shareReplay({ refCount: true }));
    }

    displayWarnings(cell: DataTableButtonCell<ShiftSwap>) {
        this.matDialog.open<WarningsDialogComponent, WarningsDialogData>(WarningsDialogComponent, {
            data: {
                size: DialogSize.Medium,
                warnings: of(cell.item.warnings),
            },
        });
    }

    displayComments(cell: DataTableButtonCell<ShiftSwap>) {
        this.matDialog.open<CommentDialogComponent, CommentDialogData>(CommentDialogComponent, {
            data: {
                size: DialogSize.Medium,
                comments: of(cell.item.comments || []),
            },
        });
    }

    chooseApplicant(cell: DataTableButtonCell<ShiftSwap>) {
        this.matDialog.open<ChooseApplicantDialogComponent, ChooseApplicantDialogData>(ChooseApplicantDialogComponent, {
            data: {
                swap: cell.item,
            },
        }).afterClosed().pipe(
            switchMap((swap) => {
                return swap ? this.update() : EMPTY;
            }),
            tap(() => void this.snackbar.t('APPROVED', 'scheduling')),
        ).subscribe(this.enableCellSubscriber(cell));
    }

    declineSwap(cell: DataTableButtonCell<ShiftSwap>) {
        let text: string;
        let vars: Record<string, any>;

        if (cell.item.fromEmployee) {
            text = 'DECLINE_SHIFT_SWAP';
            vars = { name: cell.item.fromEmployee.name };
        } else {
            text = 'DECLINE_AVAILABLE_SHIFT';
            vars = { count: cell.item.validApplicants?.length };
        }

        this.confirmDialog.delete({
            title: this.translate.t('DECLINE', 'scheduling'),
            text: this.translate.t(text, 'scheduling', vars),
            confirmText: this.translate.t('DECLINE', 'scheduling'),
            comment: { include: true },
        }).afterClosed().pipe(
            switchMap((result) => {
                if (result?.ok) {
                    return this.shiftSwapService.update(cell.item.customerId, cell.item.id, {
                        approved: false,
                        comment: result.comment,
                    });
                }

                return EMPTY;
            }),
            switchMap(this.update.bind(this)),
            tap(() => void this.snackbar.t('SHIFT_SWAP_DECLINED', 'scheduling')),
        ).subscribe(this.enableCellSubscriber(cell));
    }

    dismissApplicant(cell: DataTableButtonCell<ShiftSwap>) {
        // An open shift swap with one applicant
        const swapApplicant = cell.item.validApplicants[0];
        if (!swapApplicant) {
            return;
        }

        this.confirmDialog.delete({
            title: this.translate.t('DECLINE', 'scheduling'),
            text: this.translate.t('DISMISS_SWAP_APPLICATION', 'scheduling', { name: swapApplicant.employeeName }),
            confirmText: this.translate.t('DECLINE_SWAP', 'scheduling'),
            comment: { include: true },
        }).afterClosed().pipe(
            switchMap((result) => {
                if (result?.ok) {
                    return this.swapApplicantService.dismissSwapApplicant(cell.item.customerId, cell.item.id, swapApplicant.id, result.comment);
                }

                return EMPTY;
            }),
            tap(() => void this.snackbar.t('SHIFT_SWAP_DECLINED', 'scheduling')),
            switchMap(this.update.bind(this)),
        ).subscribe(this.enableCellSubscriber(cell));
    }

    approveSwap(cell: DataTableButtonCell<ShiftSwap>) {
        this.matDialog.open<ApproveSwapDialogComponent, ApproveSwapDialogData>(ApproveSwapDialogComponent, {
            data: {
                swap: cell.item,
                applicant: cell.item.validApplicants[0] ?? undefined,
            },
        }).afterClosed().pipe(
            switchMap((swap?: ShiftSwap) => {
                if (swap) {
                    return this.update();
                }

                return EMPTY;
            }),
            tap(() => void this.snackbar.t('APPROVED', 'scheduling')),
        ).subscribe(this.enableCellSubscriber(cell));
    }

    protected createColumns() {
        let columns: DataTableColumnType<ShiftSwap>[] = [
            new DataTableTextColumn<ShiftSwap>({
                header: new DataTableHeader({
                    i18n: 'EMPLOYEE',
                    ns: 'general',
                }),
                value: (cell) => {
                    return cell.item.fromEmployee?.name || this.translate.t('UNASSIGNED');
                },
            }),
            new DataTableTextColumn({
                header: new DataTableHeader({
                    i18n: 'APPLICANT_plural',
                    ns: 'scheduling',
                }),
                value: (cell) => {
                    if (cell.item.toEmployee?.name) {
                        return cell.item.toEmployee.name;
                    }

                    if (cell.item.validApplicants?.length) {
                        const applicants = cell.item.validApplicants;

                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        return applicants.length == 1 ? applicants[0]!.employeeName as string : String(applicants.length);
                    }

                    return '';
                },
            }),
        ];

        this.permissionCheckService.isAllowed(`customers.${this.customer.id}.employees.*.pay_rates.*.get`).subscribe((hasPermission) => {
            if (!hasPermission) {
                return;
            }

            const numberFormatOptions: Intl.NumberFormatOptions = {
                signDisplay: 'exceptZero',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            };
            const currency = this.customer.currency;

            if (currency) {
                numberFormatOptions.style = 'currency';
                numberFormatOptions.currency = currency;
                numberFormatOptions.currencyDisplay = 'narrowSymbol';
            }

            const column = new DataTableNumberColumn<ShiftSwap>({
                numberFormatOptions,
                header: new DataTableHeader({ i18n: 'COST' }),
                classes(cell) {
                    return cell.item.cost == null ? [] : [ cell.item.cost < 0 ? 'positive' : 'negative' ];
                },
                value: 'cost',
            });

            // Splice into correct place
            this.columns.splice(2, 0, column);
            // Trigger change
            this.columns = [ ...this.columns ];
        });

        columns = columns.concat([
            new DataTableTextColumn<ShiftSwap>({
                header: new DataTableHeader({
                    i18n: 'PERIOD_plural',
                    ns: 'scheduling',
                }),
                value: (cell) => cell.item.shift?.periods.filter((p) => !p.break).map((p) => p.description).join(', '),
            }),
            new DataTableTextColumn<ShiftSwap>({
                header: new DataTableHeader({ i18n: 'QUALIFICATION_plural' }),
                value: (cell) => cell.item.shift?.qualifications?.map((q) => q.name).join(', '),
            }),
            new DataTableTextColumn<ShiftSwap>({
                header: new DataTableHeader({
                    i18n: 'SCHEDULE',
                    ns: 'scheduling',
                }),
                value: (cell) => cell.item.shift?.schedule?.name,
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({ i18n: 'WEEK' }),
                format: 'W',
                value: (cell) => cell.item.shift?.from,
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({ i18n: 'WEEKDAY' }),
                format: 'cccc',
                value: (cell) => cell.item.shift?.from,
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({
                    i18n: 'FROM',
                    sortBy: 'from',
                }),
                format: DateTime.DATETIME_SHORT,
                value: (cell) => cell.item.shift?.from,
            }),
            new DataTableDateTimeColumn({
                header: new DataTableHeader({ i18n: 'TO' }),
                format: DateTime.TIME_SIMPLE,
                value: (cell) => {
                    return cell.item.shift?.to;
                },
            }),
            new DataTableButtonColumn<ShiftSwap>({
                buttons: [
                    {
                        icon: (cell) => cell.item.mode === 'single-applicant' ? 'check' : 'gavel',
                        hide: (swap) => of(swap.mode === 'no-applicants'),
                        click: (cell) => {
                            if (cell.item.mode === 'single-applicant') {
                                this.approveSwap(cell);
                            } else {
                                this.chooseApplicant(cell);
                            }
                        },
                        show: this.canUpdate.bind(this),
                        type: 'ok',
                        tooltip: { key: 'APPROVE' },
                    },
                    {
                        icon: 'close',
                        hide: (swap) => of(swap.mode === 'no-applicants'),
                        click: (cell) => {
                            if (cell.item.mode === 'single-applicant' && cell.item.validApplicants.length) {
                                this.dismissApplicant(cell);
                            } else {
                                this.declineSwap(cell);
                            }
                        },
                        show: this.canUpdate.bind(this),
                        type: 'warn',
                        tooltip: { key: 'DECLINE', ns: 'scheduling' },
                    },
                    {
                        icon: 'comment',
                        hide: (swap) => of(!swap.comments?.length),
                        click: this.displayComments.bind(this),
                        nonBlocking: true,
                        show: () => of(true),
                        tooltip: { key: 'COMMENT_plural' },
                    },
                    {
                        icon: 'warning',
                        type: 'alert',
                        hide: (swap) => of(!swap.warnings?.length),
                        click: this.displayWarnings.bind(this),
                        nonBlocking: true,
                        show: () => of(true),
                        tooltip: { key: 'WARNING' },
                    },
                ],
            }),
        ]);

        this.columns = columns;
    }

    protected update() {
        this.dataTable?.refresh();

        return this.request;
    }

    protected enableCellSubscriber(cell: DataTableButtonCell<ShiftSwap>) {
        return {
            complete: () => {
                cell.disabled.set(false);
            },
            error() {
                cell.disabled.set(false);
            },
        };
    }

    protected canUpdate(swap: ShiftSwap) {
        return this.permissionCheckService.isAllowed(`customers.${swap.customerId}.shift_swaps.${swap.id}.update`);
    }
}
