import { Inject, Injectable } from '@angular/core';
import { pick } from 'lodash-es';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { DateTime } from 'luxon';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { ReportRun, ReportRunResponse } from '../models/report-run';
import { map, Observable } from 'rxjs';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { HttpClient } from '@angular/common/http';

type CreateOptions = {
    from: DateTime
    to: DateTime
    format: string
    options: Record<string, unknown>
};

@Injectable({
    providedIn: 'root',
})
export class RunService {
    constructor(@Inject(HttpClient) protected http: HttpClient) {
    }

    create(customerId: number, reportId: number, options: CreateOptions): Observable<ReportRun> {
        return this.http.post<ReportRunResponse>(`/customers/${customerId}/reports/${reportId}/runs`, options)
            .pipe(classifyItem(ReportRun));
    }

    reRun(customerId: number, run: ReportRun): Observable<ReportRun> {
        return this.create(customerId, run.reportId, {
            from: run.from,
            to: run.to,
            format: run.format,
            options: run.options,
        });
    }

    get(customerId: number, reportId: number, runId: number, options: { url?: boolean, ['with[]']: string[] }): Observable<ReportRun> {
        return this.http.get<ReportRunResponse>(`/customers/${customerId}/reports/${reportId}/runs/${runId}`, {
            params: pick(options, [ 'url', 'with[]' ]),
        }).pipe(classifyItem(ReportRun));
    }

    getUrl(customerId: number, reportId: number, runId: number): Observable<string> {
        return this.http.get<{ url: string }>(`/customers/${customerId}/reports/${reportId}/runs/${runId}`, {
            params: {
                url: true,
            },
        }).pipe(map((result) => result.url));
    }

    getAll(customerId: number, reportId: number, options: PaginationOptions): Observable<ArrayPaginatedResponse<ReportRun>> {
        return this.http.get<ArrayPaginatedResponse<ReportRunResponse>>(`/customers/${customerId}/reports/${reportId}/runs`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(ReportRun));
    }

    delete(customerId: number, reportId: number, runId: number) {
        return this.http.delete<undefined>(`/customers/${customerId}/reports/${reportId}/runs/${runId}`);
    }
}
