import { DateTime } from 'luxon';
import { Customer, CustomerResponse } from './customer';
import { GroupResponse } from '../interfaces/group';
import { User, UserResponse } from './user';
import { stringToDateTime } from '../utils/string-to-date-time';
import { ApiResponse } from '../interfaces/api-response';

export interface CustomerUserResponse extends ApiResponse {
    id: number;
    customer_id: number;
    user_id: number;
    from: string;
    to: string | null;
    updated_at?: | string | null;
    created_at?: | string | null;
    deleted_at?: | string | null;
    // Relations
    customer?: CustomerResponse;
    user: UserResponse;
    groups?: GroupResponse<UserResponse>[];
    active_groups?: GroupResponse<UserResponse>[];
}

export class CustomerUser {
    id: number;
    customerId: number;
    userId: number;
    customer?: Customer;
    user?: User;
    groups?: GroupResponse<UserResponse>[];
    activeGroups?: GroupResponse<UserResponse>[];
    lastActive?: DateTime;
    updatedAt?: DateTime | null;
    createdAt?: DateTime | null;
    deletedAt?: DateTime | null;
    email?: string;
    from: DateTime;
    to?: DateTime;

    constructor(data: CustomerUserResponse) {
        this.id = data.id;
        this.customerId = data.customer_id;
        this.userId = data.user_id;

        if (data.user) {
            this.user = new User(data.user);
        }

        if (data.customer) {
            this.customer = new Customer(data.customer);
        }

        this.groups = data.groups?.concat([]);
        this.activeGroups = data.active_groups?.concat([]);
        this.lastActive = data.user?.last_active ? stringToDateTime(data.user.last_active) : undefined;
        this.from = stringToDateTime(data.from);
        this.to = data.to ? stringToDateTime(data.to) : undefined;
        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }

    get isActive() {
        const now = DateTime.now();
        const fakeTo = this.to || now.plus({ year: 1 });
        return now >= this.from && now <= fakeTo;
    }
}
