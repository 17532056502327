import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { Customer } from '../../../shared/models/customer';
import { of } from 'rxjs';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomerAutocompleteService } from '../../../shared/autocompletes/customer-autocomplete.service';
import { OrganizationMembersService } from '../../http/organization-members.service';
import { mergeMap } from 'rxjs/operators';
import { UIRouter } from '@uirouter/core';
import { DateTime } from 'luxon';
import { TranslateService } from '../../../shared/services/translate.service';
import { AutoCompleteData } from '../../../shared/autocompletes/autocomplete';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { AsyncPipe, NgFor } from '@angular/common';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DatePickerOptionsDirective } from '../../../shared/directives/date-picker-options.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';

@Component({
    selector: 'eaw-add-members-dialog',
    templateUrl: './add-members-dialog.component.html',
    styleUrl: './add-members-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        DatePickerOptionsDirective,
        MatInputModule,
        MatDatepickerModule,
        AutocompleteComponent,
        NgFor,
        MatListModule,
        MatButtonModule,
        MatIconModule,
        MatDialogActions,
        MatDialogClose,
        ActionButtonComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class AddMembersDialogComponent extends DialogComponent {
    form = new FormGroup({
        addMember: new FormControl<Customer | string>(''),
        from: new FormControl<DateTime>(DateTime.now(), { validators: Validators.required }),
        to: new FormControl<DateTime | null>(null),
    });

    addList: Customer[];
    orgId: string;
    adding = false;
    options: AutoCompleteData<Customer>;

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: DialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AddMembersDialogComponent>,
        @Inject(CustomerAutocompleteService) public cacService: CustomerAutocompleteService,
        @Inject(OrganizationMembersService) public orgMemberService: OrganizationMembersService,
        @Inject(UIRouter) private uiRouter: UIRouter,
        @Inject(TranslateService) protected translateService: TranslateService,
    ) {
        super(dialogRef, { size: DialogSize.Small });

        this.orgId = this.uiRouter.globals.params['organizationId'];
        this.addList = [];
        this.form.controls.addMember.valueChanges.subscribe(() => {
            this.addToList();
        });
        this.options = {
            label: translateService.t('CUSTOMER_plural'),
            optionTemplate: 'default',
            filterRequirement: 3,
            display: 'name',
            trackByKey: 'id',
        };
    }

    addToList() {
        const customer = this.form.controls.addMember.value;
        if (customer instanceof Customer) {
            if (this.addList.some((c) => c.id === customer.id)) {
                this.form.controls.addMember.reset();
                return;
            }
            this.addList.push(customer);
            this.form.controls.addMember.reset();
        }
    }

    removeFromList(item: Customer) {
        const i = this.addList.indexOf(item);
        this.addList.splice(i, 1);
    }

    addMembers() {
        this.form.disable();
        this.adding = true;

        of(...this.addList).pipe(mergeMap((c) => {
            const from = this.form.controls.from.getRawValue();
            const to = this.form.controls.to.getRawValue();
            const createMember = {
                organization_id: this.orgId,
                customer_id: c.id,
                from: from ? from?.startOf('day') : DateTime.now().startOf('day'),
                to: to ? to : undefined,
            };
            return this.orgMemberService.add(this.orgId, createMember);
        }, 2)).subscribe({
            complete: () => {
                this.dialogRef.close();
                this.adding = false;
            },
        });
    }
}
