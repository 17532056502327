import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperDialogComponent, ImageCropperDialogData } from '../dialogs/image-cropper-dialog/image-cropper-dialog.component';
import { HttpClient, HttpContext } from '@angular/common/http';
import { map, take } from 'rxjs';
import { FileUploadService } from './file-upload.service';
import { SnackBarService } from './snack-bar.service';
import { IGNORE_ERROR } from '../http/http-contexts';

@Injectable({
    providedIn: 'root',
})
export class UpdateCustomerLogoService {
    constructor(
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(HttpClient) private http: HttpClient,
        @Inject(FileUploadService) private fileUploadService: FileUploadService,
        @Inject(SnackBarService) private snackBarService: SnackBarService,
    ) {
    }

    open(customerId: number) {
        const initialFile = this.http.get(`/customers/${customerId}/logo`, {
            responseType: 'blob',
            context: new HttpContext().set(IGNORE_ERROR, true),
        }).pipe(
            take(1),
            map((blob) => new File([ blob ], 'logo.png', { type: 'image/png' })),
        );

        const logoRect = document.getElementById('customer-logo')?.getBoundingClientRect() || { width: 1, height: 1 };

        this.matDialog.open<ImageCropperDialogComponent, ImageCropperDialogData, File>(ImageCropperDialogComponent, {
            data: {
                aspectRatio: logoRect.width / logoRect.height,
                title: Promise.resolve('Update customer logo'),
                initialFile,
                fileName: `customer-logo-${customerId}`,
            },
        }).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.snackBarService.open(`Updating logo for customer ${customerId}...`);
            this.fileUploadService.upload(`/customers/${customerId}/logo`, {
                logo: result,
            }).subscribe(() => {
                this.snackBarService.open('Logo updated');
            });
        });
    }
}
