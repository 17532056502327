import { Component, Inject, Input, signal, ViewChild } from '@angular/core';
import { t } from 'i18next';
import { DataTablePagination, DataTableRequest } from '../../../data-table/types/data-table';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { FlexitimeService } from '../../http/flexitime.service';
import { PageHeaderButton } from '../../../shared/components/page-header/classes/page-header-button';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { of } from 'rxjs';
import { FlexitimeOverview } from '../../models/flexitime-overview';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';

@Component({
    selector: 'eaw-flexitime',
    templateUrl: './flexitime.component.html',
    styleUrl: './flexitime.component.scss',
    providers: [ DurationPipe ],
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
    ],
})
export class FlexitimeComponent {
    @ViewChild(DataTableComponent) dataTable?: DataTableComponent<FlexitimeOverview>;

    @Input({ required: true }) customerId!: number;

    showInactive = false;
    updateList = false;
    request: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<FlexitimeOverview>[] = [
        new DataTableTextColumn({
            value: (cell) => cell.item.name || '',
            header: new DataTableHeader({
                i18n: 'EMPLOYEE',
                sortBy: 'first_name',
            }),
        }),
        new DataTableTextColumn({
            value: (cell) => this.durationPipe.transform(cell.item.balance * 3600, [ 'hours', 'minutes' ]) || '',
            header: new DataTableHeader({
                i18n: 'BALANCE',
                ns: 'payroll',
                sortBy: 'balance',
            }),
        }),
    ];

    includeInactive = false;
    pageButtons: PageHeaderButton[] = [
        new PageHeaderButton({
            click: this.toggleInactive.bind(this),
            active: () => this.includeInactive,
            icon: 'history',
            menuText: signal(Promise.resolve(t('SHOW_INACTIVE') || 'Show inactive')),
        }),
    ];

    constructor(
        @Inject(FlexitimeService) private flexitimeService: FlexitimeService,
        @Inject(DurationPipe) private durationPipe: DurationPipe,
    ) {
    }

    updateTable(pagination: DataTablePagination) {
        this.request = this.flexitimeService.getOverview(this.customerId, {
            ...pagination,
            include_inactive: this.includeInactive,
        });
    };

    toggleInactive() {
        this.includeInactive = !this.includeInactive;
        this.dataTable?.refresh();
    }

    rowClasses(row: FlexitimeOverview) {
        const classes: string[] = [];
        if (!row.isActive) {
            classes.push('inactive');
        }
        return classes;
    }
}
