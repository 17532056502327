import moment from 'moment-timezone';

export default class MlProjection {
    name: string;
    uuid: string;
    timeSeriesId: any;
    projections?: any[];
    time_series_uuid: any;

    constructor(mlProjection: any) {
        Object.assign(this, mlProjection || {});

        this.uuid = mlProjection.uuid;
        this.name ||= this.uuid;
        this.timeSeriesId = this.time_series_uuid;
        this.projections = this.projections?.map((p) => MlProjection.formatProjection(p));
    }

    get id() {
        return this.uuid;
    }

    static formatProjection(projection: any) {
        projection.moment = moment.utc(projection.time, 'YYYY-MM-DD HH:mm:ss');
        projection.unix = +projection.moment;

        return projection;
    }
}
