import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { classifyItem } from '../utils/rxjs/classify';
import { Organization, OrganizationResponse } from '../models/organization';
import { map, Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { SimplePaginationOptions } from '../interfaces/simple-pagination-options';
import { formatHttpParams } from '../utils/format-http-params';
import { ArrayPaginatedResponse } from '../interfaces/paginated-response';

type OrganizationMember = {
    customer_id: number,
    from: DateTime,
    to?: DateTime,
};
export type CreateOrganization = {
    name: string;
    members?: OrganizationMember[]
};

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    constructor(@Inject(HttpClient) protected http: HttpClient) { }

    create(data: CreateOrganization): Observable<Organization> {
        return this.http.post<OrganizationResponse>(`/organizations`, data).pipe(classifyItem(Organization));
    }

    get(organizationId: string): Observable<Organization> {
        return this.http.get<OrganizationResponse>(`/organizations/${organizationId}`).pipe(classifyItem(Organization));
    }

    getAll(options: SimplePaginationOptions & { ids?: string[] }): Observable<ArrayPaginatedResponse<Organization>> {
        const ids: string[] | undefined = options.ids;
        delete options.ids;
        return this.http.get<ArrayPaginatedResponse<OrganizationResponse>>(`/organizations`, {
            params: formatHttpParams({
                ...options,
                'ids[]': ids,
            }),
        }).pipe(map((resp) => {
            return {
                ...resp,
                data: resp.data.map((org) => new Organization(org)),
            };
        }));
    }

    update(organizationId: string, data: { name: string }): Observable<Organization> {
        return this.http.put<OrganizationResponse>(`/organizations/${organizationId}`, data).pipe(classifyItem(Organization));
    }

    delete(organizationId: string) {
        return this.http.delete(`/organizations/${organizationId}`);
    }
}
