import { Component, ElementRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogData, DialogSize } from '../../../shared/dialogs/dialog-component';
import { ShiftPeriod } from '../../models/shift-period';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../../../shared/dialogs/dialog-header/dialog-header.component';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { ShiftService } from '../../http/shift.service';
import { InfoLoadingComponent } from '../../../shared/components/info-loading/info-loading.component';
import { DateTime } from 'luxon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { TimeInputComponent } from '../../../shared/components/date-time/time-input/time-input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SubheaderComponent } from '../../../shared/components/subheader/subheader.component';
import { Shift } from '../../models/shift';
import { AutocompleteOptions } from '../../../shared/autocompletes/autocomplete';
import { BusinessUnit } from '../../../business-units/models/business-unit';
import { BusinessUnitAutocompleteService } from '../../../shared/autocompletes/business-unit-autocomplete.service';
import { AutocompleteComponent } from '../../../shared/components/autocomplete/autocomplete.component';
import { ProductPipe } from '../../../shared/pipes/product.pipe';
import { Products } from '../../../shared/enums/products';
import { ColorPickerComponent } from '../../../shared/components/color-picker/color-picker.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ShiftPeriodService } from '../../http/shift-period.service';
import { catchError, distinct, EMPTY, forkJoin, map, Observable, of, startWith, switchMap } from 'rxjs';
import { Storage } from '../../../shared/utils/storage';
import { ENTER } from '@angular/cdk/keycodes';
import { Qualification } from '../../../shared/models/qualification';
import { expandAllPages } from '../../../shared/utils/rxjs/expand-all-pages';
import { QualificationService } from '../../../shared/http/qualification.service';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipInput, MatChipInputEvent, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

export interface AddEditShiftPeriodDialogData extends DialogData {
    customerId: number;
    scheduleId: number;
    shiftId: number;
    periodId?: number;
}

export type AddEditShiftPeriodDialogReturn = ShiftPeriod;

type PeriodForm = {
    fromDate: FormControl<DateTime | null>,
    fromTime: FormControl<DateTime | null>,
    toDate: FormControl<DateTime | null>,
    toTime: FormControl<DateTime | null>,
    businessUnit: FormControl<BusinessUnit | number | null>,
    color: FormControl<string | null>,
    description: FormControl<string | null>,
    unproductive: FormControl<boolean>,
    break: FormControl<boolean>,
    qualifications: FormControl<Qualification | string | null>,
};

@Component({
    selector: 'eaw-add-edit-shift-period-dialog',
    standalone: true,
    imports: [
        CommonModule,
        DialogHeaderComponent,
        MatDialogContent,
        MatDialogActions,
        ActionButtonComponent,
        MatButtonModule,
        MatDialogClose,
        TranslatePipe,
        InfoLoadingComponent,
        MatInputModule,
        MatSelectModule,
        DateTimePipe,
        TimeInputComponent,
        ReactiveFormsModule,
        SubheaderComponent,
        AutocompleteComponent,
        ProductPipe,
        ColorPickerComponent,
        MatSlideToggleModule,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatChipGrid,
        MatChipInput,
        MatChipRemove,
        MatChipRow,
        MatIcon,
        MatProgressSpinner,
    ],
    templateUrl: './add-edit-shift-period-dialog.component.html',
    styleUrl: './add-edit-shift-period-dialog.component.scss',
})
export class AddEditShiftPeriodDialogComponent extends DialogComponent implements OnInit {
    private readonly shiftService = inject(ShiftService);
    private readonly shiftPeriodService = inject(ShiftPeriodService);
    private readonly qualificationService = inject(QualificationService);
    private readonly customerProductService = inject(CustomerProductService);
    private readonly businessUnitAutocompleteService = inject(BusinessUnitAutocompleteService);

    @ViewChild('qualificationInput') qualificationInput?: ElementRef<HTMLInputElement>;

    loading = true;
    processing = false;
    availableDays: DateTime[] = [];
    form: FormGroup<PeriodForm>;
    shift?: Shift;
    period?: ShiftPeriod;
    businessUnitAutocomplete: AutocompleteOptions<BusinessUnit>;
    qualificationsSeparatorKeysCodes: number[] = [ ENTER ];
    hasBusinessUnits: boolean = false;
    filteredQualifications: Observable<Qualification[]>;
    loadedQualifications: Qualification[] = [];
    selectedQualifications = new Map<number, Qualification>();

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: AddEditShiftPeriodDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<AddEditShiftPeriodDialogComponent, AddEditShiftPeriodDialogReturn>,
    ) {
        data.size ||= DialogSize.Medium;
        dialogRef.disableClose = true;
        super(dialogRef, data);

        this.form = new FormGroup<PeriodForm>({
            fromDate: new FormControl(null, Validators.required),
            fromTime: new FormControl(null, Validators.required),
            toDate: new FormControl(null, Validators.required),
            toTime: new FormControl(null, Validators.required),
            businessUnit: new FormControl(null),
            color: new FormControl(null, Validators.required),
            description: new FormControl(null),
            unproductive: new FormControl(false, { nonNullable: true }),
            break: new FormControl(false, { nonNullable: true }),
            qualifications: new FormControl<Qualification | string | null>(null),
        });

        this.businessUnitAutocomplete = {
            options: this.businessUnitAutocompleteService.options,
            getter: this.businessUnitAutocompleteService.getter(data.customerId),
            setter: this.businessUnitAutocompleteService.setter(data.customerId),
        };

        this.filteredQualifications = this.form.controls.qualifications.valueChanges.pipe(
            startWith(null),
            map((search) => {
                if (search instanceof Qualification) {
                    return this.loadedQualifications?.find((q) => q.id === search.id) ? [ search ] : [];
                }

                return this.loadedQualifications?.filter((q) => q.name.toLowerCase().includes(search?.toLowerCase() ?? '')) || [];
            }),
        );
    }

    ngOnInit() {
        forkJoin([
            this.data.periodId ? this.shiftPeriodService.get(this.data.customerId, this.data.scheduleId, this.data.shiftId, this.data.periodId, [ 'businessUnit' ]) : of(null),
            this.shiftService.get(this.data.customerId, this.data.shiftId),
            this.getQualifications(),
        ]).subscribe(async ([ period, shift, qualifications ]) => {
            this.loading = false;
            this.shift = shift;
            this.period = period || undefined;
            this.loadedQualifications = qualifications || [];

            await this.patchForm(shift, period);
            this.handleUnitChange();
            this.handleBreakChange();
            this.setAvailableDays(shift);
        });
    }

    getQualifications() {
        return this.customerProductService.hasProducts(this.data.customerId, [ Products.BusinessUnits ]).pipe(
            switchMap((hasProducts) => {
                this.hasBusinessUnits = hasProducts;

                if (hasProducts) {
                    return of(null);
                }

                return expandAllPages((pagination) => this.qualificationService.getAll(this.data.customerId, pagination), { per_page: 100, direction: 'asc', order_by: 'name' });
            }),
        );
    }

    async patchForm(shift: Shift, period?: ShiftPeriod | null) {
        this.form.patchValue({
            fromDate: period?.from || shift.from,
            fromTime: period?.from || shift.from,
            toDate: period?.to || shift.to,
            toTime: period?.to || shift.to,
            color: period?.color.toHexString() || await this.getDefaultColor(),
            businessUnit: period?.businessUnitId || null,
            description: period?.description || null,
            unproductive: period?.unproductive ?? false,
            break: period?.break ?? false,
        });

        this.selectedQualifications = period?.qualifications ? new Map(period.qualifications?.map((q) => [ q.id, q ])) : new Map();

        if (period?.businessUnitId) {
            this.form.controls.description.disable();
            this.form.controls.color.disable();
        }
    }

    async getDefaultColor() {
        return await Storage.getItem('scheduling:default:period:color') || '#c5e1a5';
    }

    setAvailableDays(shift: Shift) {
        this.availableDays.push(shift.from);
        if (!shift.from.hasSame(shift.to, 'day')) {
            this.availableDays.push(shift.to);
        }
    }

    addQualification(event: MatChipInputEvent) {
        const qualificationName = (event.value || '').trim().toLowerCase();

        const qualifications = this.loadedQualifications?.filter((q) => q.name.toLocaleLowerCase().includes(qualificationName)) || [];
        if (qualifications.length !== 1) {
            return;
        }

        // We are making sure above that there's EXACTLY one qualification
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.selectedQualifications.set(qualifications[0]!.id, qualifications[0]!);

        // Clear the input value
        event.chipInput?.clear();
        this.form.controls.qualifications.setValue(null);
    }

    selectedQualification(event: MatAutocompleteSelectedEvent): void {
        const value = event.option.value;
        if (!(value instanceof Qualification)) {
            return;
        }

        this.selectedQualifications.set(value.id, value);
        this.form.controls.qualifications.setValue(null);

        if (this.qualificationInput) {
            this.qualificationInput.nativeElement.value = '';
        }
    }

    removeQualification(qualification: Qualification) {
        this.selectedQualifications.delete(qualification.id);
    }

    handleBreakChange() {
        this.form.controls.break.valueChanges.subscribe((breakValue) => {
            const unproductiveControl = this.form.controls.unproductive;

            if (breakValue) {
                this.form.controls.unproductive.setValue(true);
                unproductiveControl.disable();
            } else {
                this.form.controls.unproductive.setValue(false);
                unproductiveControl.enable();
            }
        });
    }

    handleUnitChange() {
        this.form.controls.businessUnit.valueChanges.pipe(
            distinct((u) => {
                return u instanceof BusinessUnit ? u.id : u;
            }),
        ).subscribe(async (businessUnit) => {
            const colorControl = this.form.controls.color;
            const descriptionControl = this.form.controls.description;

            if (businessUnit instanceof BusinessUnit) {
                descriptionControl.setValue(null);
                descriptionControl.disable();
                colorControl.setValue(businessUnit.color.toHexString());
                colorControl.disable();
            } else {
                colorControl.setValue(await this.getDefaultColor());
                descriptionControl.enable();
                colorControl.enable();
            }
        });
    }

    getFormValues() {
        const value = this.form.getRawValue();

        if (!this.shift || !value.fromTime || !value.toTime || !value.fromDate || !value.toDate) {
            return;
        }

        const from = DateTime.fromObject({
            second: 0,
            minute: value.fromTime.minute,
            hour: value.fromTime.hour,
            day: value.fromDate.day,
            month: value.fromDate.month,
            year: value.fromDate.year,
        });

        const to = DateTime.fromObject({
            second: 0,
            minute: value.toTime.minute,
            hour: value.toTime.hour,
            day: value.toDate.day,
            month: value.toDate.month,
            year: value.toDate.year,
        });

        return {
            offset: from.diff(this.shift.from, 'seconds').as('seconds'),
            length: to.diff(from, 'seconds').as('seconds'),
            description: value.description || (this.period?.description ? null : undefined),
            break: value.break,
            unproductive: value.unproductive,
            business_unit_id: this.hasBusinessUnits ? (value.businessUnit instanceof BusinessUnit ? value.businessUnit.id : null) : undefined,
            color: value.color || undefined,
            qualifications: this.hasBusinessUnits ? undefined : (this.selectedQualifications.size ? [ ...this.selectedQualifications.values() ].map((x) => x.id) : undefined),
        };
    }

    update() {
        if (!this.data.periodId) {
            return;
        }

        const value = this.getFormValues();
        if (!value) {
            return;
        }

        this.processing = true;
        this.form.disable();
        this.shiftPeriodService.update(this.data.customerId, this.data.scheduleId, this.data.shiftId, this.data.periodId, value).pipe(
            catchError(() => {
                this.form.enable();
                this.processing = false;
                return EMPTY;
            }),
        ).subscribe((period) => {
            this.dialogRef.close(period);
        });
    }

    add() {
        const value = this.getFormValues();
        if (!value) {
            return;
        }

        this.processing = true;
        this.form.disable();
        this.shiftPeriodService.create(this.data.customerId, this.data.scheduleId, this.data.shiftId, {
            ...value,
            business_unit_id: value.business_unit_id || undefined,
            description: value.description || undefined,
        }).pipe(
            catchError(() => {
                this.form.enable();
                this.processing = false;
                return EMPTY;
            }),
        ).subscribe((period) => {
            this.dialogRef.close(period);
        });
    }
}
