<eaw-page-header>
    <span title>{{ 'ASSIGN_PERMISSIONSETS_TO_MANY' | translate: 'admin' | async }}</span>
</eaw-page-header>

<div class="tw-grid tw-grid-cols-3 max-md:tw-grid-cols-1 tw-gap-8 tw-overflow-auto">
    <mat-card>
        <mat-card-content>
            <!-- Permission set search -->
            <mat-form-field class="tw-w-full standalone tw-mb-8">
                <mat-label>{{ 'PERMISSIONSET_plural' | translate | async }}</mat-label>
                <input matInput
                       autocomplete="off"
                       type="search"
                       [placeholder]="('TYPE_TO_SEARCH_AUTOCOMPLETE' | translate | async)!"
                       [formControl]="permissionSetSearch">
                <mat-progress-spinner *ngIf="loadingPermSetSearch"
                                      matSuffix
                                      mode="indeterminate"
                                      strokeWidth="3"
                                      diameter="24">
                </mat-progress-spinner>
                <mat-icon *ngIf="!loadingPermSetSearch" matSuffix>search</mat-icon>
            </mat-form-field>

            <!-- Permission set results -->
            <mat-action-list dense disableRipple class="result-box">
                <mat-list-item *ngIf="!permSetResults?.total && permissionSetSearch.dirty" disabled>
                    <i>{{ 'NO_RESULTS' | translate | async }}</i>
                </mat-list-item>

                <button mat-list-item
                        class="tw-group"
                        (click)="addPermissionSet(ps, i)"
                        *ngFor="let ps of permSetResults?.data; index as i; trackBy: trackById">
                    <span matLine>{{ ps.name }}</span>
                    <span class="tw-hidden group-hover:tw-inline group-focus:tw-inline">
                        <mat-icon inline>add</mat-icon>
                        {{ 'ADD' | translate | async }}
                    </span>
                </button>

                <button mat-list-item
                        *ngIf="permSetResults && permSetResults.to < permSetResults.total"
                        [disabled]="loadingPermSetSearch"
                        (click)="searchForPermissionSets(permSetResults.to + 10)">
                    {{ 'LOAD_MORE' | translate | async }} ({{ permSetResults.total - permSetResults.to }})
                </button>

                <h3 matSubheader *ngIf="loadingPermSetSearch">{{ 'LOADING' | translate | async }}</h3>
            </mat-action-list>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-content>
            <!-- Customer (group) search -->
            <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-8 tw-w-full tw-mb-8">
                <mat-button-toggle-group [formControl]="customerSearchToggle">
                    <mat-button-toggle [value]="false">{{ 'CUSTOMER_plural' | translate | async }}</mat-button-toggle>
                    <mat-button-toggle [value]="true">{{ 'CUSTOMER_GROUP_plural' | translate | async }}</mat-button-toggle>
                </mat-button-toggle-group>

                <mat-form-field class="standalone">
                    <mat-label>{{ (customerSearchToggle.value ? 'CUSTOMER_GROUP_plural' : 'CUSTOMER_plural') | translate | async }}</mat-label>
                    <input matInput
                           autocomplete="off"
                           type="search"
                           [placeholder]="('TYPE_TO_SEARCH_AUTOCOMPLETE' | translate | async)!"
                           [formControl]="customerSearch">
                    <mat-progress-spinner *ngIf="loadingCustomerSearch"
                                          matSuffix
                                          mode="indeterminate"
                                          strokeWidth="3"
                                          diameter="24">
                    </mat-progress-spinner>
                    <mat-icon *ngIf="!loadingCustomerSearch" matSuffix>search</mat-icon>
                </mat-form-field>
            </div>

            <!-- Customer results -->
            <mat-action-list dense disableRipple class="result-box">
                <mat-list-item *ngIf="!customerSearchResult?.total && customerSearch.dirty" disabled>
                    <i>{{ 'NO_RESULTS' | translate | async }}</i>
                </mat-list-item>

                <button mat-list-item
                        class="tw-group"
                        (click)="addCustomer(res, i)"
                        *ngFor="let res of customerSearchResult?.data; index as i; trackBy: trackById">
                    <span matLine>{{ res.name }}</span>
                    <span class="tw-hidden group-hover:tw-inline group-focus:tw-inline">
                        <mat-icon inline>add</mat-icon>
                        {{ 'ADD' | translate | async }}
                    </span>
                </button>

                <button mat-list-item
                        *ngIf="customerSearchResult && customerSearchResult.to < customerSearchResult.total"
                        [disabled]="loadingCustomerSearch"
                        (click)="searchForCustomers(customerSearchResult.to + 10)">
                    {{ 'LOAD_MORE' | translate | async }} ({{ customerSearchResult.total - customerSearchResult.to }})
                </button>

                <h3 matSubheader *ngIf="loadingCustomerSearch">{{ 'LOADING' | translate | async }}</h3>
            </mat-action-list>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <!-- User groups -->
        <mat-card-content>
            <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-8 tw-mb-8">
                <mat-form-field class="standalone">
                    <mat-select [(value)]="groupGroups">
                        <mat-option [value]="true">{{ 'ADD_USER_GROUPS_BY_NAME' | translateSync: 'admin' }}</mat-option>
                        <mat-option [value]="false">{{ 'ADD_TO_INDIVIDUAL_USER_GROUPS' | translateSync: 'admin' }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="standalone">
                    <mat-label>{{ 'USER_GROUP_plural' | translate | async }}</mat-label>
                    <input matInput
                           type="search"
                           autocomplete="off"
                           [placeholder]="('TYPE_TO_SEARCH_AUTOCOMPLETE' | translate | async)!"
                           [formControl]="userGroupSearch">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>

            <!-- Individual groups -->
            <mat-action-list dense class="result-box" disableRipple *ngIf="!groupGroups">
                <mat-list-item *ngIf="!selectedCustomers.length" disabled>
                    {{ 'ADD_CUSTOMER_TO_SHOW_USER_GROUPS' | translate: 'admin' |async }}
                </mat-list-item>

                <mat-list-item *ngFor="let group of filteredGroups; trackBy: trackById" class="tw-group" (click)="addUserGroup(group)">
                    <span matLine><strong>#{{ group.id }}</strong> {{ group.name }}<small> - {{ group.owner?.name }}</small></span>
                    <span class="tw-hidden group-hover:tw-inline group-focus:tw-inline">
                        <mat-icon inline>add</mat-icon>
                        {{ 'ADD' | translate | async }}
                    </span>
                </mat-list-item>
            </mat-action-list>

            <!-- Name grouped -->
            <mat-action-list dense class="result-box" disableRipple *ngIf="groupGroups">
                <mat-list-item *ngIf="!selectedCustomers.length" disabled>
                    {{ 'ADD_CUSTOMER_TO_SHOW_USER_GROUPS' | translate: 'admin' |async }}
                </mat-list-item>
                <mat-list-item *ngFor="let grouped of filteredGroupedGroups; trackBy: trackByName" class="tw-group" (click)="addUserGroup(grouped)">
                    <span matLine>{{ grouped.name }}</span>
                    <span matLine>{{ grouped.howMany }} {{ (grouped.howMany < 2 ? 'USER_GROUP' : 'USER_GROUP_plural') | translate | async }}</span>
                    <span class="tw-hidden group-hover:tw-inline group-focus:tw-inline">
                        <mat-icon inline>add</mat-icon>
                        {{ 'ADD' | translate | async }}
                    </span>
                </mat-list-item>
            </mat-action-list>
        </mat-card-content>
    </mat-card>

    <mat-card class="tw-col-span-full tw-overflow-auto tw-flex tw-flex-col">
        <mat-card-content class="tw-grid tw-grid-cols-3 max-md:tw-grid-cols-1 tw-gap-8 tw-overflow-auto">

            <!-- Chosen permission sets -->
            <mat-list>
                <h3 matSubheader>{{ 'PERMISSIONSET_plural' | translate | async }}</h3>
                <mat-list-item *ngIf="!selectedPermissionSets.length"><i>{{ 'NONE' | translate | async }}</i></mat-list-item>
                <mat-list-item *ngFor="let ps of selectedPermissionSets; index as i; trackBy: trackById">
                    <span matLine>{{ ps.name }}</span>
                    <span matLine>{{ ps.productName }}</span>
                    <p matLine>{{ ps.description }}</p>
                    <button mat-button (click)="removePermissionSet(i)">
                        <mat-icon inline>remove</mat-icon>
                        {{ 'REMOVE' | translate | async }}
                    </button>
                </mat-list-item>
            </mat-list>

            <!-- Chosen customers -->
            <mat-list>
                <h3 matSubheader>{{ 'CUSTOMER_plural' | translate | async }} / {{ 'CUSTOMER_GROUP_plural' | translate | async }}</h3>
                <mat-list-item *ngIf="!selectedCustomers.length"><i>{{ 'NONE' | translate | async }}</i></mat-list-item>
                <mat-list-item *ngFor="let customer of selectedCustomers; index as i">
                    <span matLine>
                        {{ customer.name }} - {{ (customer.constructor.name === 'Customer' ? 'CUSTOMER' : 'CUSTOMER_GROUP') | translate | async }}
                    </span>
                    <button mat-button (click)="removeCustomer(i)">
                        <mat-icon inline>remove</mat-icon>
                        {{ 'REMOVE' | translate | async }}
                    </button>
                </mat-list-item>
            </mat-list>

            <!-- Chosen individual user groups -->
            <mat-list *ngIf="!groupGroups">
                <h3 matSubheader>{{ 'USER_GROUP_plural' | translate | async }}</h3>
                <mat-list-item *ngIf="!selectedGroups.length"><i>{{ 'NONE' | translate | async }}</i></mat-list-item>
                <mat-list-item *ngFor="let group of selectedGroups; index as i; trackBy: trackById">
                    <span matLine><strong>#{{ group.id }}</strong> {{ group.name }}<small> - {{ group.owner?.name }}</small></span>
                    <button mat-button (click)="removeUserGroup(i)">
                        <mat-icon inline>remove</mat-icon>
                        {{ 'REMOVE' | translate | async }}
                    </button>
                </mat-list-item>
            </mat-list>

            <!-- Chosen name grouped user groups -->
            <mat-list *ngIf="groupGroups">
                <h3 matSubheader>{{ 'USER_GROUP_plural' | translate | async }}</h3>
                <mat-list-item *ngIf="!selectedGroupedGroups.length"><i>{{ 'NONE' | translate | async }}</i></mat-list-item>
                <mat-list-item *ngFor="let grouped of selectedGroupedGroups; index as i; trackBy: trackByName">
                    <span matLine>{{ grouped.name }}</span>
                    <span matLine>{{ grouped.howMany }} {{ (grouped.howMany < 2 ? 'USER_GROUP' : 'USER_GROUP_plural') | translate | async }}</span>
                    <button mat-button (click)="removeUserGroup(i)">
                        <mat-icon inline>remove</mat-icon>
                        {{ 'REMOVE' | translate | async }}
                    </button>
                </mat-list-item>
            </mat-list>
        </mat-card-content>

        <!-- Submit -->
        <mat-card-actions align="end">
            <button mat-raised-button color="accent" [disabled]="!canSubmit()" (click)="submit()">{{ 'SUBMIT' | translate | async }}</button>
        </mat-card-actions>
    </mat-card>
</div>
