import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { DialogComponent, DialogData } from '../dialog-component';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '../../services/translate.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MaterialColorDirective } from '../../directives/material-color.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, AsyncPipe } from '@angular/common';
import { ActionButtonComponent } from '../../components/action-button/action-button.component';
import { EawMaterialColorHue } from '../../services/material-color.service';
import { ThemePalette } from '@angular/material/core';

export interface ConfirmDialogData extends DialogData {
    text?: Promise<string>;
    title: Promise<string>;
    cancelText?: Promise<string>;
    confirmText?: Promise<string>;
    /** @deprecated */
    confirmColor?: EawMaterialColorHue;
    confirmButtonColor?: ThemePalette,
    comment?: {
        include: boolean;
        required?: boolean;
    }
}

export interface ConfirmDialogReturn {
    ok: boolean,
    comment: string | undefined
}

@Component({
    selector: 'eaw-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDialogActions,
        MatButtonModule,
        MatDialogClose,
        MaterialColorDirective,
        AsyncPipe,
        TranslatePipe,
        ActionButtonComponent,
    ],
})
export class ConfirmDialogComponent extends DialogComponent implements OnInit {
    confirmText: Promise<string>;
    cancelText: Promise<string>;
    comment = new FormControl('');

    constructor(
        @Inject(MAT_DIALOG_DATA) override data: ConfirmDialogData,
        @Inject(MatDialogRef) override dialogRef: MatDialogRef<ConfirmDialogComponent, ConfirmDialogReturn>,
        @Inject(TranslateService) private translate: TranslateService,
    ) {
        super(dialogRef, data);

        this.confirmText = data.confirmText || this.translate.t('CONFIRM');
        this.cancelText = data.cancelText || this.translate.t('CANCEL');
    }

    ngOnInit() {
        if (this.data.comment?.required) {
            this.comment.addValidators(Validators.required);
            this.comment.updateValueAndValidity();
        }
    }

    close() {
        this.dialogRef.close({
            ok: true,
            comment: this.comment.value || undefined,
        });
    }
}
