<eaw-dialog-header>
    <span title>{{ 'ADD_MEMBER_S' | translate: 'organization' | async }}</span>
</eaw-dialog-header>

<mat-dialog-content [formGroup]="form" id="form" class="tw-grid tw-grid-cols-4 tw-gap-8">
    <mat-form-field eawDatePickerOptions class="tw-col-[1_/_3]">
        <mat-label>{{'FROM' | translate | async}}</mat-label>
        <input matInput formControlName="from" [matDatepicker]="fromPicker">
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field eawDatePickerOptions class="tw-col-[3_/_5]">
        <mat-label>{{'TO' | translate | async}}</mat-label>
        <input matInput formControlName="to" [matDatepicker]="toPicker">
        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
    </mat-form-field>

    <eaw-autocomplete formControlName="addMember"
                      class="tw-col-span-full"
                      prefix="pin_drop"
                      [options]="this.options"
                      [setter]="cacService.setter()"
                      [getter]="cacService.getter()">
    </eaw-autocomplete>

    <mat-list *ngFor="let item of addList" ng-if="addList.length" class="tw-col-span-full">
        <div class="tw-flex tw-flex-row">
            <mat-list-item>{{item.name}}</mat-list-item><button mat-icon-button (click)="removeFromList(item)"><mat-icon color="warn">close</mat-icon></button>
        </div>
    </mat-list>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close [disabled]="this.adding">{{'CANCEL' | translate | async}}</button>
    <eaw-action-button [loading]="false" [disabled]="this.adding" (click)="addMembers()">{{ 'SAVE' | translate | async }}</eaw-action-button>
</mat-dialog-actions>
