import { Inject, Injectable } from '@angular/core';
import { PaginationOptions } from '../../shared/interfaces/pagination-options';
import { Observable } from 'rxjs';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { Todo, TodoResponse } from '../models/todo';
import { DateTime } from 'luxon';
import { Comment, CommentResponse } from '../../shared/models/comment';
import { Attachment, AttachmentResponse } from '../../shared/models/attachment';
import { HttpClient } from '@angular/common/http';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { ApiResponse } from '../../shared/interfaces/api-response';

interface GetOptions {
    'with[]': string[];
}

interface GetAllOptions extends PaginationOptions {
    done?: boolean;
    include_future?: boolean;
    due?: DateTime;
    due_end?: DateTime;
    'status_ids[]'?: number[];
    customer_id?: number;
}

interface UpdateBody {
    status_id?: number;
    responsible_id?: number | null;
    title?: string;
    description?: string;
    from?: DateTime | null;
    due?: DateTime | null;
}

interface CreateOptions {
    description: string;
    title?: string | null;
    responsible_id?: number | null;
    notify?: boolean | null;
    from?: DateTime | null;
    due?: DateTime | null;
}

@Injectable({
    providedIn: 'root',
})
export class TodoService {
    constructor(@Inject(HttpClient) protected http: HttpClient) {
    }

    get(customerId: number, todoId: number, options: GetOptions) {
        return this.http.get<TodoResponse>(`/customers/${customerId}/todo_items/${todoId}`, {
            params: { ...options },
        }).pipe(classifyItem(Todo));
    }

    addComment(customerId: number, todoId: number, comment: string): Observable<Comment> {
        return this.http.post<CommentResponse>(`/customers/${customerId}/todo_items/${todoId}/comments`, {
            body: comment,
        }).pipe(classifyItem(Comment));
    }

    getComments(customerId: number, todoId: number, options?: GetAllOptions): Observable<ArrayPaginatedResponse<Comment>> {
        return this.http.get<ArrayPaginatedResponse<CommentResponse>>(`/customers/${customerId}/todo_items/${todoId}/comments`, {
            params: formatHttpParams(options),
        }).pipe(classifyArrayPaginatedResponse(Comment));
    }

    getAttachments<Owner extends ApiResponse>(customerId: number, todoId: number, options?: PaginationOptions) {
        return this.http.get<ArrayPaginatedResponse<AttachmentResponse<Owner>>>(`/customers/${customerId}/todo_items/${todoId}/attachments`, {
            params: { ...options },
        }).pipe(classifyArrayPaginatedResponse(Attachment));
    }

    getAllForCustomer(customerId: number, options?: GetAllOptions): Observable<ArrayPaginatedResponse<Todo>> {
        return this.http.get<ArrayPaginatedResponse<TodoResponse>>(`/customers/${customerId}/todo_items`, {
            params: formatHttpParams({
                ...options,
                source: options?.customer_id ? 'other' : undefined,
            }),
        }).pipe(classifyArrayPaginatedResponse(Todo));
    }

    getAllForUser(customerId: number, userId: number, options?: GetAllOptions): Observable<ArrayPaginatedResponse<Todo>> {
        return this.http.get<ArrayPaginatedResponse<TodoResponse>>(`/customers/${customerId}/users/${userId}/todo_items`, {
            params: formatHttpParams({
                ...options,
                source: options?.customer_id ? 'other' : undefined,
            }),
        }).pipe(classifyArrayPaginatedResponse(Todo));
    }

    create(customerId: number, options: CreateOptions) {
        return this.http.post<TodoResponse>(`/customers/${customerId}/todo_items`, options).pipe(classifyItem(Todo));
    }

    update(customerId: number, todoId: number, body: UpdateBody) {
        return this.http.put<TodoResponse>(`/customers/${customerId}/todo_items/${todoId}`, body).pipe(classifyItem(Todo));
    }

    delete(customerId: number, todoId: number) {
        return this.http.delete(`/customers/${customerId}/todo_items/${todoId}`);
    }
}
