// @ts-nocheck
import moment from 'moment-timezone';
import { module } from 'angular';
import { CurrentOld } from '../../../../shared/angularjs/current.factory';

const template1 = `<div layout="column" ng-form="offTimeRequest">
    <eaw-date-time-interval
            ng-model="$offTime.interval"
            ng-change="$offTime.ngChange()"
            required
            min-date="$offTime.minDate">
    </eaw-date-time-interval>

    <md-input-container class="md-block">
        <label for="requestComment" ng-i18next="COMMENT"></label>
        <textarea name="requestComment"
                  id="requestComment"
                  rows="1"
                  ng-change="$offTime.ngChange()"
                  ng-model="$offTime.request.comment">
        </textarea>
    </md-input-container>

    <md-checkbox ng-if="$offTime.override && !$offtime.hasSwitzerland" ng-model="$offTime.request.vacation" ng-change="$offTime.ngChange()">
        <span ng-i18next="vacation:VACATION_QUESTION"></span>
    </md-checkbox>
</div>
`;

module('eaw.vacation').component('offTimeRequest', {
    template: template1,
    bindings: {
        minDate: '<?',
        override: '<?',
    },
    require: {
        ngModel: 'ngModel',
    },
    controllerAs: '$offTime',
    controller: function() {
        const ctrl = this;

        ctrl.$onInit = () => {
            ctrl.ngModel.$formatters.unshift(ctrl.formatter);
            ctrl.hasSwitzerland = CurrentOld.hasProduct('Switzerland');
        };

        ctrl.formatter = (val) => {
            if (moment.isMoment(val?.from) && moment.isMoment(val?.to)) {
                ctrl.request = val;
            } else {
                ctrl.reset(val);
            }

            return ctrl.request;
        };

        ctrl.reset = (request) => {
            ctrl.request = {
                ...request,
            };

            ctrl.interval = {
                from: moment().startOf('d'),
                to: moment().endOf('d'),
            };
        };

        ctrl.ngChange = () => {
            ctrl.request.from = ctrl.interval?.from?.clone();
            ctrl.request.to = ctrl.interval?.to?.clone();

            ctrl.ngModel.setViewValue(ctrl.request);
        };
    },
});
