<div class="btn prev" matRipple (click)="changeInterval(-1)" (eawDebounceClick)="onDebounceClick()" [class.disabled]="loading">
    <mat-icon eawSize="20px">chevron_left</mat-icon>
</div>

@if (interval === 'day') {
    <div id="date-selector">
        <input matInput [formControl]="date" [matDatepicker]="picker">
        <mat-datepicker #picker></mat-datepicker>

        <div class="calendar-btn" [class.disabled]="loading || disableCustomSelect" (click)="disableCustomSelect ? null : picker.open()">
            <span>{{ date.value | DateTime:'DATE_MED' }}</span>
            <mat-icon eawSize="16px">edit_calendar</mat-icon>
        </div>
    </div>
} @else {
    <div id="date-range-selector">
        <mat-date-range-picker #rangePicker>
            <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>{{ 'CANCEL' | translate | async }}</button>
                <button mat-raised-button color="primary" matDateRangePickerApply (click)="onDebounceClick()">{{ 'USE' | translate | async }}</button>
            </mat-date-range-picker-actions>
        </mat-date-range-picker>
        <mat-date-range-input [rangePicker]="rangePicker">
            <input matStartDate [formControl]="dateRange.controls.from">
            <input matEndDate [formControl]="dateRange.controls.to">
        </mat-date-range-input>

        <div class="calendar-btn" [class.disabled]="loading || disableCustomSelect" (click)="disableCustomSelect ? null : rangePicker.open()">
            @if (singleDate) {
                <span>{{ dateRange.controls.from.value | DateTime:null:singleDateFormat }}</span>
            } @else {
                <span>{{ dateRange.controls.from.value | DateTime:'DATE_MED' }} - {{ dateRange.controls.to.value | DateTime:'DATE_MED' }}</span>
            }

            <mat-icon eawSize="16px">edit_calendar</mat-icon>
        </div>
    </div>
}

<div class="btn next" matRipple (click)="changeInterval(1)" (eawDebounceClick)="onDebounceClick()" [class.disabled]="loading">
    <mat-icon eawSize="20px">chevron_right</mat-icon>
</div>
