// @ts-nocheck
import { t } from 'i18next';
import { orderBy } from 'lodash-es';
import { debounce } from 'lodash-es';
import { uniqueId } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { module } from 'angular';
const template1 = `<md-dialog>
    <eaw-dialog-header-old heading="'ADD' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <div class="md-dialog-content">
            <form name="addAccess" id="addAccess" ng-cloak ng-submit="access.submit()" autocomplete="off">
                <md-autocomplete
                        md-selected-item="access.controllable"
                        md-search-text="filter"
                        md-min-length="0"
                        md-input-id="{{$id}}autocomplete"
                        md-delay="300"
                        ng-disabled="access.disable"
                        md-floating-label="{{'ACCESS' | i18next}}"
                        md-clear-button="true"
                        ng-required="true"
                        md-items="item in access.getControllables(filter)"
                        md-item-text="item.name || item.name">
                    <md-item-template>
                        <p layout="row">
                            <span md-highlight-text="filter" flex md-highlight-flags="gi">
                                {{item.name}}
                            </span>
                            <small> {{item.morph_class.toUpperCase() | i18next}}</small>
                        </p>
                    </md-item-template>
                    <md-not-found>
                        <span ng-i18next="NO_MATCH_FOUND"></span>
                    </md-not-found>
                </md-autocomplete>
            </form>
        </div>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CANCEL"></md-button>
        <md-button form="addAccess" ng-disabled="addAccess.$invalid" type="submit" ng-i18next="SUBMIT"></md-button>
    </md-dialog-actions>

</md-dialog>
`;
module('eaw.filesystem').controller('itemAccessDialogCtrl', [ '$mdDialog', 'fileAclFactory', function($mdDialog, fileAclFactory) {
    const ctrl = this;
    ctrl.$onInit = () => {
        ctrl.load();
    };
    ctrl.load = () => {
        ctrl.loading = true;
        fileAclFactory.getEntries(ctrl.item.customer_id, ctrl.item)
            .then(ctrl.handleAcl)
            .catch($mdDialog.cancel).finally(() => delete ctrl.loading);
    };
    ctrl.changed = function changed(action) {
        const clickedEntry = this.acl_entries[action];
        if (clickedEntry.allowed && (clickedEntry.inherited || clickedEntry.inherited == null)) {
            fileAclFactory.addControllable(ctrl.item.customer_id, ctrl.item, this, action, clickedEntry.allowed);
        } else {
            fileAclFactory.updateEntry(ctrl.item.customer_id, ctrl.item, this, action, clickedEntry.allowed);
        }
    };
    ctrl.inheritChanged = () => {
        ctrl.updateList(ctrl.acl.inherits);
    };
    ctrl.handleAcl = (acl) => {
        ctrl.acl = acl;
        ctrl.acl.list.forEach((item) => {
            item._uniqueId = uniqueId();
            item.inherited = Object.values(item.acl_entries).every((e) => e.inherited);
            item.changed = debounce(ctrl.changed, 300);
        });
    };
    ctrl.updateList = (inherit, replace) => {
        ctrl.loading = true;
        fileAclFactory.updateAcl(ctrl.item.customer_id, ctrl.item, inherit, replace).then(ctrl.handleAcl).finally(() => delete ctrl.loading);
    };
    ctrl.addEntry = () => {
        $mdDialog.show({
            template: template1,
            controllerAs: 'access',
            multiple: true,
            controller: function() {
                const dialog = this;
                dialog.getControllables = (filter) => fileAclFactory.getControllables(ctrl.item.customer_id, ctrl.item, filter).then((resp) => {
                    const items = [];
                    [ 'user', 'user_group' ].forEach((morph_class) => {
                        resp[morph_class].forEach((u) => {
                            const name = u.name;
                            items.push({
                                ...u,
                                name,
                                sortName: name.toLowerCase(),
                                morph_class,
                            });
                        });
                    });
                    return orderBy(items, [ 'sortName', 'asc' ]);
                });
                dialog.submit = () => {
                    ctrl.loading = true;
                    ctrl.addControllable(dialog.controllable.morph_class, dialog.controllable.id);
                    $mdDialog.hide();
                };
            },
        });
    };
    ctrl.addControllable = (morphClass, id) => {
        fileAclFactory.addControllable(ctrl.item.customer_id, ctrl.item, {
            morph_class: morphClass,
            id,
        }).then(ctrl.load);
    };
    ctrl.sortList = () => {
        ctrl.list = orderBy(ctrl.list || [].map((x) => {
            return {
                ...x,
                sortName: x.name.toLowerCase(),
            };
        }), [ 'sortName', 'asc' ]);
    };
    ctrl.remove = (controllable) => {
        $mdDialog.show($mdDialog.confirm()
            .multiple(true)
            .theme('delete')
            .title(t('filesystem:REMOVE_ACCESS'))
            .textContent(t('filesystem:REMOVE_ACCESS_TEXT', {
                itemName: ctrl.item.name,
                entityName: controllable.name,
            }))
            .ok(t('REMOVE'))
            .cancel(t('CANCEL'))).then(() => {
            ctrl.loading = true;
            fileAclFactory.removeControllable(ctrl.item.customer_id, ctrl.item, controllable)
                .then(ctrl.load)
                .catch(() => delete ctrl.loading);
        });
    };
} ]);
