import { Inject, Injectable } from '@angular/core';
import { ContractTypeService } from '../http/contract-type.service';
import { ContractType } from '../models/contract-type';
import { of } from 'rxjs';
import { TranslateService } from '../services/translate.service';
import { Autocomplete } from './autocomplete';

@Injectable({
    providedIn: 'root',
})
export class ContractTypeAutocompleteService extends Autocomplete<ContractType> {
    constructor(
        @Inject(ContractTypeService) protected contractTypeService: ContractTypeService,
        @Inject(TranslateService) protected translate: TranslateService,
    ) {
        super({
            display: 'name',
            trackByKey: 'id',
            label: translate.t('CONTRACT_TYPE', 'company'),
        });
    }

    getter(customerId: number) {
        return (filter?: string) => this.contractTypeService.getAllForCustomer(customerId, { filter });
    }

    setter(customerId: number) {
        return (item: unknown) => {
            if (item instanceof ContractType) {
                return of(item);
            }

            if (typeof item === 'number') {
                return this.contractTypeService.getForCustomer(customerId, item);
            }

            return of(undefined);
        };
    }
}
