import { module } from 'angular';
import { Namespace } from '../../../shared/enums/namespace';
import { EmployeeGroupsListComponent } from '../../pages/employee-groups-list/employee-groups-list.component';
import { createNavTabsState, createState } from '../../../shared/utils/create-state';
import { EmployeeGroupInfoComponent } from '../../pages/employee-group-info/employee-group-info.component';
import { map } from 'rxjs';
import { ExternalEmployeesListComponent } from '../../pages/external-employees-list/external-employees-list.component';
import { LoanEmployeesListComponent } from '../../pages/loan-employees-list/loan-employees-list.component';
import { NavTabsComponent, NavTabsTab } from '../../../shared/components/nav-tabs/nav-tabs.component';
import { navTabsTabsResolver } from '../../../shared/resolvers/nav-tabs-tabs.resolver';
import { CurrentService } from '../../../shared/services/current.service';
import { currentResolver } from '../../../shared/resolvers/current.resolver';
import { LocalEmployeesListComponent } from '../../pages/local-employees-list/local-employees-list.component';
import { staticResolver } from '../../../shared/resolvers/static.resolver';
import { paramResolver } from '../../../shared/resolvers/param.resolver';
import { navTabsLabelResolver } from '../../../shared/resolvers/nav-tabs-label.resolver';
import { Transition } from '@uirouter/angularjs';
import { EmployeeGroupsService } from '../../http/employee-groups.service';
import { EmployeeGroupSocialRulesComponent } from '../../pages/employee-group-social-rules/employee-group-social-rules.component';
import { NewEmployeeComponent } from '../../pages/new-employee/new-employee.component';
import { DataTableQueryParams } from '../../../data-table/data-table.component';
import { ApiModel } from '../../../shared/enums/api-model';
import { PermissionOptions } from '../../../shared/services/permission-check.service';

module('eaw.company').config([ '$stateProvider', ($stateProvider: any) => {
    createState($stateProvider, {
        name: `eaw/app/company/employees`,
        parent: `eaw/app/company`,
        url: '/employees',
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
            currentResolver('customerStackId', 'customer', 'stackId'),
            navTabsTabsResolver(async function($transition$) {
                const current = $transition$.injector().get(CurrentService) as CurrentService;
                const tabs: NavTabsTab[] = [
                    {
                        state: 'eaw/app/company/employees/local',
                        label: { key: 'LOCAL_EMPLOYEES', ns: 'company' },
                    },
                ];
                const permissions: [ string, PermissionOptions ][] = [ [ `customers.[${ApiModel.Customer}].external_employees.*.get`, {
                    models: [
                        {
                            type: ApiModel.Customer,
                            id: current.getCustomer().id,
                        },
                    ],
                } ] ];

                tabs.push({
                    state: 'eaw/app/company/employees/loan',
                    label: { key: 'LOAN_EMPLOYEE_plural', ns: 'company' },
                    permissions,
                });
                tabs.push({
                    state: 'eaw/app/company/employees/external',
                    label: { key: 'EXTERNAL_EMPLOYEE_plural', ns: 'company' },
                    permissions,
                });

                return tabs;
            }),
        ],
        data: {
            somePermissions: [ 'customers.{customer}.employees.*.get', 'customers.{customer}.employees.manage' ],
            breadcrumb: { key: 'EMPLOYEES', ns: 'navigation' },
        },
    });

    createNavTabsState($stateProvider, {
        parent: `eaw/app/company/employees`,
        name: `eaw/app/company/employees/local`,
        url: '/local',
        component: LocalEmployeesListComponent,
        data: {
            somePermissions: [
                `customers.{customer}.employees.*.get`,
                `customers.{customer}.employees.manage`,
            ],
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'LOCAL_EMPLOYEES', ns: 'company' },
        },
    });

    createNavTabsState($stateProvider, {
        parent: `eaw/app/company/employees`,
        name: `eaw/app/company/employees/external`,
        url: '/external',
        component: ExternalEmployeesListComponent,
        data: {
            breadcrumb: { key: 'EXTERNAL_EMPLOYEE_plural', ns: 'company' },
        },
    });

    createNavTabsState($stateProvider, {
        parent: `eaw/app/company/employees`,
        name: `eaw/app/company/employees/loan`,
        url: '/loan',
        component: LoanEmployeesListComponent,
        data: {
            breadcrumb: { key: 'LOAN_EMPLOYEE_plural', ns: 'company' },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/company/employee_groups`,
        parent: `eaw/app/company`,
        url: '/employee_groups',
        views: {
            'content@': {
                component: EmployeeGroupsListComponent,
            },
        },
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'EMPLOYEE_GROUP_plural', ns: 'company' },
            permissions: [ 'customers.{customer}.employee_groups.*.get' ],
        },
        resolve: [
            staticResolver('infoRoute', 'eaw/app/company/employee_groups/info/members'),
            currentResolver('customerId', 'customer', 'id'),
        ],
    });

    createState($stateProvider, {
        name: 'eaw/app/company/employee_groups/info',
        parent: `eaw/app/company/employee_groups`,
        url: '/:group_id',
        abstract: true,
        views: {
            'content@': {
                component: NavTabsComponent,
            },
        },
        data: {
            breadcrumb: { key: 'EMPLOYEE_GROUP', ns: 'company' },
            permissions: [ 'customers.{customer}.employee_groups.{group_id}.get' ],
        },
        resolve: [
            paramResolver('group_id', 'employeeGroupId'),
            currentResolver('customerId', 'customer', 'id'),
            navTabsLabelResolver(function($transition$: Transition) {
                const injector = $transition$.injector();
                const employeeGroupsService = injector.get(EmployeeGroupsService) as EmployeeGroupsService;
                const currentService = injector.get(CurrentService) as CurrentService;

                return employeeGroupsService.get(currentService.getCustomer().id, $transition$.params()['group_id']).pipe(
                    map((employeeGroup) => employeeGroup?.name || ''),
                );
            }),
            navTabsTabsResolver(function() {
                return Promise.resolve([
                    {
                        state: 'eaw/app/company/employee_groups/info/members',
                        label: { key: 'MEMBER_plural' },
                    },
                    {
                        state: 'eaw/app/company/employee_groups/info/social_rules',
                        label: { key: 'SOCIAL_RULE_plural', ns: Namespace.FranceSocialRules },
                    },
                ]);
            }),
        ],
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/company/employee_groups/info/members`,
        url: '/members',
        parent: `eaw/app/company/employee_groups/info`,
        component: EmployeeGroupInfoComponent,
        data: {
            queryParams: DataTableQueryParams,
            breadcrumb: { key: 'MEMBER_plural' },
        },
    });

    createNavTabsState($stateProvider, {
        name: `eaw/app/company/employee_groups/info/social_rules`,
        url: '/social_rules',
        parent: `eaw/app/company/employee_groups/info`,
        component: EmployeeGroupSocialRulesComponent,
        data: {
            breadcrumb: { key: 'SOCIAL_RULE_plural', ns: Namespace.FranceSocialRules },
        },
    });

    createState($stateProvider, {
        name: `eaw/app/company/employees/new`,
        parent: `eaw/app/company/employees`,
        url: '/new',
        views: {
            'content@': {
                component: NewEmployeeComponent,
            },
        },
        resolve: [
            currentResolver('customerId', 'customer', 'id'),
        ],
        data: {
            permissions: [ 'customers.{customer}.employees.create' ],
            breadcrumb: { key: 'NEW_EMPLOYEE', ns: 'company' },
        },
    });
} ]);
