// @ts-nocheck
import { loadNamespaces, t } from 'i18next';
import { orderBy } from 'lodash-es';
import { uniqBy } from 'lodash-es';
import moment from 'moment-timezone';
import { module, noop } from 'angular';
const template3 = `<md-dialog>
    <eaw-dialog-header-old heading="'SUMMARY' | i18next"></eaw-dialog-header-old>
    <md-dialog-content>
        <md-subheader>
            <span ng-bind="ctrl.date"></span>
        </md-subheader>
        <table class="table">
            <tr ng-repeat="type in ::ctrl.types track by $index">
                <td ng-bind="::type.translatedName"></td>
                <td ng-if="type.key != 'dayAbsence'" ng-bind="::type.sum | eawDuration:'long'"></td>

                <td ng-if="type.key == 'dayAbsence'">
                    <span class="tw-mr-4" ng-i18next="[i18next]({count: type.daySum})N_DAY"></span>
                    <span>({{type.hourSum | eawDuration:'long'}})</span>
                </td>
            </tr>
        </table>
    </md-dialog-content>

    <md-dialog-actions layout="row">
        <md-button close-dialog ng-i18next="CLOSE"></md-button>
    </md-dialog-actions>
</md-dialog>
`;
const template2 = `<div class="header">
    <strong ng-bind="::ctrl.empPaidTime.employee.name"></strong>
</div>

<div class="absences" ng-repeat="absence in ::ctrl.absences" ng-style="::{background: absence.type.color, color: absence.type.textColor}">
    <small class="name" ng-bind="::absence.type.name | eawTranslate: 'absence_types'"></small>
    <small class="name" ng-bind="::absence.grade | percent"></small>
    <div class="text" ng-bind="::absence._tooltipText"></div>
</div>
`;
const template1 = `<div class="header">
    <strong ng-bind="::ctrl.empPaidTime.employee.name"></strong>
</div>

<div class="vacation">
    <small class="name" ng-i18next="VACATION"></small>
    <div class="text" ng-bind="ctrl.fromTo"></div>
</div>
`;

import { overlaps } from '../../../shared/angularjs/modules/misc/services/easy-funcs.service';
import { CurrentOld } from '../../../shared/angularjs/current.factory';
import { Namespace } from '../../../shared/enums/namespace';

module('eaw.payroll.paid-time').service('EmployeePaidTime', [ 'IconElement', 'EmployeePaidTimeSegment', 'EawResource', 'Tooltip', 'ComplexTooltip', 'PaidTimeFactory', '$mdDialog', '$filter', function(IconElement, EmployeePaidTimeSegment, EawResource, Tooltip, ComplexTooltip, PaidTimeFactory, $mdDialog, $filter) {
    class EmployeePaidTime {
        inShift = false;
        inPaidTime = false;
        inTimepunch = false;
        segmentMarkers = [];
        segments = [];
        employee;
        customer;
        date;
        allCustomers;
        allPaidTimes;
        allShifts;
        allLeaveShifts;
        allTimepunches;
        allAbsences;
        paidTimes;
        shifts;
        leaveShifts;
        timepunches;
        absences;
        dayAbsences;
        hourAbsences;
        comments;
        constructor(employee, empCustomer, date, resp) {
            this.employee = employee;
            this.date = date.clone();
            // Store data
            this.allCustomers = resp.customers;
            this.allPaidTimes = resp.paid_times;
            this.allShifts = resp.shifts;
            this.allLeaveShifts = resp.leave_shifts;
            this.allTimepunches = resp.timepunches;
            this.allAbsences = resp.absences.map((a) => {
                a.type = resp.absence_types.find((t) => t.id === a.type_id);
                return a;
            });
            // Find items associated with the employee on the given date
            this.paidTimes = this.associate(resp.paid_times);
            this.shifts = this.associate(resp.shifts);
            this.leaveShifts = this.associate(resp.leave_shifts);
            this.timepunches = this.associate(resp.timepunches);
            this.absences = this.associate(resp.absences).filter((a) => overlaps(a.from, a.to, date.clone().startOf('d'), date.clone().endOf('d'))).map((a) => {
                a.type = resp.absence_types.find((t) => t.id === a.type_id);
                return a;
            });
            this.dayAbsences = this.absences.filter((a) => a.type?.span === 'day');
            this.hourAbsences = this.absences.filter((a) => a.type?.span === 'hour');
            // Handle customer
            this.customer = CurrentOld.getCustomer();
            this.employee.customer = empCustomer;
            this.comments = this.setComments();
            this.createSegmentMarkers();
            this.createSegments();
            this.insertDefaultSegments();
            this.removeInvalidSegments();
        }

        get hasComments() {
            return Object.values(this.comments).some((x) => x.length);
        }

        setComments() {
            return [ 'paidTimes', 'shifts', 'timepunches', 'absences' ].reduce((obj, item) => {
                obj[item] = this[item]
                    .filter((i) => i.from.isSame(this.date, 'd') || i.to.isSame(this.date, 'd'))
                    .filter((i) => i.comments.length);
                return obj;
            }, {});
        }

        hasOngoingTimepunch(date = this.date) {
            return this.timepunches.some((tp) => !tp.out && tp.business_date.isSame(date, 'd'));
        }

        getSegmentsByType(key) {
            return this.segments.filter((s) => s.type.key === key);
        }

        // Returns the difference between total paid time and shift time
        getShiftDifferenceText(date) {
            // Sum all the paid times
            const totalPaidTime = this.paidTimes
                .filter((pt) => pt.business_date.isSame(date, 'd'))
                .reduce((sum, pt) => sum + pt.to.diff(pt.from, 's'), 0);
            // Sum all the lengths for the shifts if they have the same business date
            const totalShiftTime = this.shifts
                .filter((s) => s.business_date.isSame(date, 'd'))
                .reduce((sum, shift) => sum + shift.net_length, 0);
            const diff = totalPaidTime - totalShiftTime;
            const filter = $filter('eawDuration');
            return `${filter(totalPaidTime)} / ${filter(totalShiftTime)} (${filter(diff)})`;
        }

        removeInvalidSegments() {
            this.segments = this.segments.filter((seg) => !seg.invalid);
        }

        insertDefaultSegments() {
            this.hourAbsences.forEach((a) => {
                this.segments.push(EmployeePaidTimeSegment.newSegment(EmployeePaidTimeSegment.types.handled_absence, this, a, undefined, this.date));
            });
            this.paidTimes.forEach((pt) => {
                const type = [ 1, 2 ].indexOf(parseInt(pt.cf_flex_basis?.value)) >= 0 ? 'paid_time_flex' : 'paid_time';
                this.segments.push(EmployeePaidTimeSegment.newSegment(EmployeePaidTimeSegment.types[type], this, pt, undefined, this.date));
            });
            this.shifts.forEach((s) => {
                this.segments.push(EmployeePaidTimeSegment.newSegment(EmployeePaidTimeSegment.types.shift, this, s, undefined, this.date));
            });
            this.timepunches.forEach((tp) => {
                this.segments.push(EmployeePaidTimeSegment.newSegment(EmployeePaidTimeSegment.types.timepunch, this, tp, undefined, this.date));
            });
        }

        createSegments() {
            this.segmentMarkers.forEach((marker, index) => {
                const nextMarker = this.segmentMarkers[index + 1];
                if (!nextMarker) {
                    return;
                }
                // Set statuses
                if (marker.type.name === EmployeePaidTimeSegment.types.shift.name) {
                    this.inShift = marker.side === 'start';
                }
                if (marker.type.name === EmployeePaidTimeSegment.types.timepunch.name) {
                    this.inTimepunch = marker.side === 'start';
                }
                if (marker.type.name === EmployeePaidTimeSegment.types.paid_time.name) {
                    this.inPaidTime = marker.side === 'start';
                }
                this.checkAbsence(marker, nextMarker);
                this.checkSuggested(marker, nextMarker);
                this.checkExtra(marker, nextMarker);
            });
            // Clean up variables we just used for this function
            delete this.inShift;
            delete this.inTimepunch;
            delete this.inPaidTime;
        }

        overlapsPaidTime(employeeId, marker1, marker2) {
            return this.allPaidTimes
                .filter((pt) => pt.employee_id === employeeId)
                .some((pt) => overlaps(marker1.time, marker2.time, pt.from + 1, pt.to - 1));
        }

        checkSuggested(marker1, marker2) {
            if (!this.inShift) {
                return;
            }
            if (!this.inTimepunch) {
                return;
            }
            if (this.inPaidTime) {
                return;
            }
            // Drop it if it overlaps a paid time
            if (this.overlapsPaidTime(this.employee.id, marker1, marker2)) {
                return false;
            }
            this.segments.push(EmployeePaidTimeSegment.newSegment(EmployeePaidTimeSegment.types.suggested, this, marker1, marker2, this.date));
            return true;
        }

        checkAbsence(marker1, marker2) {
            if (!this.inShift) {
                return;
            }
            if (this.inTimepunch) {
                return;
            }
            if (this.inPaidTime) {
                return;
            }
            if (this.dayAbsences.length || this.allAbsences.some((a) => a.employee_id === this.employee.id && a.type?.span === 'day' && overlaps(a.from, a.to, marker1.time, marker2.time))) {
                this.segments.push(EmployeePaidTimeSegment.newSegment(EmployeePaidTimeSegment.types.pseudo_handled_absence, this, marker1, marker2, this.date));
                return true;
            }
            // Drop it if it overlaps a paid time
            if (this.overlapsPaidTime(this.employee.id, marker1, marker2)) {
                return false;
            }
            this.segments.push(EmployeePaidTimeSegment.newSegment(EmployeePaidTimeSegment.types.unhandled_absence, this, marker1, marker2, this.date));
            return true;
        }

        checkExtra(marker1, marker2) {
            if (this.inShift) {
                return;
            }
            if (this.inPaidTime) {
                return;
            }
            if (!this.inTimepunch) {
                return;
            }
            this.segments.push(EmployeePaidTimeSegment.newSegment(EmployeePaidTimeSegment.types.extra, this, marker1, marker2, this.date));
            return true;
        }

        createSegmentMarker(side, item, type) {
            const time = side === 'start' ? item.from.clone() : item.to.clone();
            this.segmentMarkers.push({
                side,
                time,
                item,
                type,
                customer: item.customer?.id || item.customer_id || this.customer,
            });
        }

        createSegmentMarkers() {
            [
                [ this.absences, EmployeePaidTimeSegment.types.absence ],
                [ this.paidTimes, EmployeePaidTimeSegment.types.paid_time ],
                [ this.shifts, EmployeePaidTimeSegment.types.shift ],
                [ this.timepunches, EmployeePaidTimeSegment.types.timepunch ],
            ].forEach(([ items, type ]) => {
                items.forEach((i) => {
                    this.createSegmentMarker('start', i, type);
                    this.createSegmentMarker('end', i, type);
                });
            });
            this.segmentMarkers = Object.values(this.segmentMarkers).sort((a, b) => a.time - b.time);
        }

        /**
         * @param {Any[]} items
         */
        associate(items = []) {
            // Give all items a from and to
            // If no "out" or "to" then it's an ongoing timepunch
            items.forEach((x) => {
                x.from = x.in?.clone() || x.from?.clone();
                x.to = x.out?.clone() || x.to?.clone() || moment();
            });
            // Same employee filter
            items = items.filter((x) => x.employee_id === this.employee.id);
            return items.filter((x) => {
                // Increase from and to so we catch stuff in the time around the day
                const from = this.date.clone().subtract(1, 'd').startOf('d');
                const to = this.date.clone().add(1, 'd').endOf('d');
                return overlaps(x.from, x.to, from, to);
            });
        }

        /**
         * @returns {HTMLElement}
         */
        generateDifferenceEl(date) {
            const diffDiv = document.createElement('div');
            diffDiv.innerText = this.getShiftDifferenceText(date);
            return Tooltip(diffDiv, t('payroll:PAID_TIME_SHIFT_DIFF_TIP'));
        }

        /**
         * @returns {HTMLElement}
         */
        generateOngoingEl() {
            let el = document.createElement('small');
            if (this.hasOngoingTimepunch()) {
                el.innerText = t('payroll:NOT_PUNCHED_OUT');
                el = Tooltip(el, t('payroll:PAID_TIME_NOT_AVAIL', {
                    name: this.employee.name,
                }));
                el.classList.add('active-tp');
            }
            return el;
        }

        generateOfftimeTooltipIcon(el, date, offtimes) {
            const vacation = offtimes.filter((o) => o.employee_id === this.employee.id && overlaps(date.clone().startOf('d'), date.clone().endOf('d'), o.from, o.to))[0];
            if (vacation) {
                IconElement.get('flight_takeoff').then((iconEl) => {
                    el.appendChild(iconEl);
                    el.addEventListener('mouseenter', (e) => {
                        ComplexTooltip(e, el, {
                            controller: 'vacationTooltipCtrl',
                            template: template1,
                            panelClasses: [ 'vacation-tooltip' ],
                            locals: {
                                empPaidTime: this,
                                vacation,
                            },
                        });
                    });
                });
            }
        }

        generateAbsenceTooltipIcon(el, date) {
            if (this.absences.some((a) => date.isBetween(a.from, a.to, 'd', '[]'))) {
                IconElement.get('hotel', true).then((iconEl) => {
                    el.appendChild(iconEl);
                    el.addEventListener('mouseenter', (e) => {
                        ComplexTooltip(e, el, {
                            controller: 'absenceTooltipCtrl',
                            template: template2,
                            panelClasses: [ 'absence-tooltip' ],
                            locals: {
                                date,
                                empPaidTime: this,
                            },
                        });
                    });
                });
            }
        }

        /**
         * @param {object}                      interval
         * @param {moment.Moment}               interval.from
         * @param {moment.Moment}               interval.to
         * @param {EmployeePaidTimeSegment}     segment
         * @param {string}                      type            - The type of thing we will add/create
         */
        findAvailableIntervals(interval, segment, type) {
            const intervalFrom = +interval.from / 1000;
            const intervalTo = +interval.to / 1000;
            // Loop all seconds in the interval
            const blocking = this.getBlockingSegments(segment, type);
            let index = 0;
            const clampedSeconds = {
                0: [],
            };
            for (let i = intervalFrom; i <= intervalTo; i++) {
                const insideBlocking = blocking.some((x) => i >= x.from && i <= x.to);
                if (insideBlocking) {
                    // Delete previous index if it has not seconds
                    if (!clampedSeconds[index].length) {
                        delete clampedSeconds[index];
                    }
                    index += 1;
                    clampedSeconds[index] = [];
                } else {
                    clampedSeconds[index].push(i);
                }
            }
            return Object.values(clampedSeconds).reduce((acc, arr) => {
                if (!arr.length) {
                    return acc;
                }
                const fromSec = arr[0];
                const toSec = arr[arr.length - 1];
                return acc.concat({
                    fromSec,
                    toSec,
                    from: moment(fromSec * 1000),
                    to: moment(toSec * 1000),
                });
            }, []);
        }

        /**
         * @param {string} type
         * @param {EmployeePaidTimeSegment} segment
         * @return {*}
         */
        getBlockingSegments(segment, type) {
            const blocking = [];
            switch (type) {
                case 'update_paid_time':
                    // Only paid time segments are blocking
                    // Except itself
                    blocking.push(...this.segments.filter((s) => s.isType(EmployeePaidTimeSegment.types.paid_time) && s.id !== segment.id));
                    break;
                case 'paid_time':
                    // Only paid time segments are blocking
                    blocking.push(...this.segments.filter((s) => s.isType(EmployeePaidTimeSegment.types.paid_time)));
                    break;
                case 'absence':
                    // All segments except the unhandled absence segment itself is blocking
                    // And shifts and timepunches
                    blocking.push(...this.segments.filter((s) => s.id !== segment.id && !s.isType(EmployeePaidTimeSegment.types.shift) && !s.isType(EmployeePaidTimeSegment.types.timepunch)));
                    break;
                default:
                    return blocking;
            }
            // Add the from and to seconds from the blocking segments
            return blocking.map((s) => {
                return {
                    from: +(s.from / 1000),
                    to: +(s.to / 1000),
                };
            });
        }

        /**
         * @param {moment.Moment} date
         * @returns {string}
         */
        static dateToString(date) {
            return date.format('YYYY-MM-DD');
        }

        /**
         * @param {string} datestring
         * @returns {moment.Moment}
         */
        static stringToDate(datestring) {
            return moment(datestring, 'YYYY-MM-DD').startOf('d');
        }

        /**
         * @param {object} empPaidTimes - All the paid times we wanna summarize, in the same format as the getOverview response
         * @param {moment.Moment} from
         * @param {moment.Moment} to
         */
        static async showSummary(empPaidTimes, from, to) {
            await loadNamespaces(Namespace.Leaveshifts);

            const types = EmployeePaidTimeSegment.types;
            // Remove stuff that is not paid times
            Object.entries(empPaidTimes).forEach(([ empId, days ]) => {
                if (days[Object.keys(days)[0]] instanceof EmployeePaidTime) {
                    return;
                }
                delete empPaidTimes[empId];
            });
            /**
             * HANDLE SEGMENTS THAT ARE NOT "REAL"
             */
            let segmentTypes = [
                types.suggested,
                types.handled_absence,
                types.unhandled_absence,
                types.extra,
            ].reduce((acc, type) => {
                acc[type.key] = {
                    ...type,
                    sum: 0,
                    items: [],
                };
                return acc;
            }, {});
            // Set items for ts, make sure no dupes
            Object.values(empPaidTimes).forEach((emp) => {
                Object.values(emp).forEach((paidTime) => {
                    Object.values(segmentTypes).forEach((type) => {
                        type.items = type.items.concat(paidTime.getSegmentsByType(type.key));
                        type.items = uniqBy(type.items, 'id');
                    });
                });
            });
            // Calc sum for ts
            Object.values(segmentTypes).forEach((type) => {
                const date = from.clone();
                while (date.isSameOrBefore(to, 'd')) {
                    const currFrom = date.clone().startOf('d');
                    const currTo = currFrom.clone().endOf('d');
                    type.items.forEach((item) => {
                        // Does the item overlap with current date?
                        if (!overlaps(item.from, item.to, currFrom, currTo)) {
                            return;
                        }
                        // Contain from/to to within the date
                        const itemFrom = item.from < currFrom ? currFrom : item.from;
                        const itemTo = item.to > currTo ? currTo : item.to;
                        type.sum += itemTo.diff(itemFrom, 's');
                    });
                    date.add(1, 'd');
                }
            });
            /**
             * HANDLE SEGMENTS THAT ARE "REAL" AND USE BUSINESS DATE
             */
            const items = [
                {
                    type: types.shift,
                    data: 'allShifts',
                    callback: (sum, shift) => sum + shift.net_length,
                },
                {
                    type: types.leave_shift,
                    data: 'allLeaveShifts',
                    callback: (sum, leaveShift) => sum + leaveShift.to.diff(leaveShift.from, 's'),
                },
                {
                    type: types.paid_time,
                    data: 'allPaidTimes',
                    callback: (sum, pt) => sum + pt.to.diff(pt.from, 's'),
                },
                {
                    type: types.timepunch,
                    data: 'allTimepunches',
                    callback: (sum, tp) => sum + tp.length,
                },
            ];
            items.forEach((item) => {
                if (item.type === types.leave_shift && !CurrentOld.getCustomer().hasProduct('Leave Shifts')) {
                    return;
                }

                segmentTypes[item.type.key] = {
                    ...item.type,
                    sum: Object.values(empPaidTimes).reduce((sum, dates) => {
                        const data = Object.values(dates)[0][item.data].filter((x) => x.business_date.isSameOrAfter(from, 'd') && x.business_date.isSameOrBefore(to, 'd'));
                        return data.reduce(item.callback, 0);
                    }, 0),
                };
            });
            /**
             * HANDLE DAY ABSENCE
             */
            segmentTypes['dayAbsence'] = {
                key: 'dayAbsence',
                translatedName: t('payroll:ABSENCE_DAYS'),
                hourSum: Object.values(empPaidTimes).reduce((sum, dates) => {
                    const absences = Object.values(dates)[0].allAbsences.filter((a) => a.type?.span === 'day');
                    return absences.reduce((sum, abs) => sum + abs.length, 0);
                }, 0),
                daySum: Object.values(empPaidTimes).reduce((sum, dates) => {
                    const absences = Object.values(dates)[0].allAbsences.filter((a) => a.type?.span === 'day');
                    return absences.reduce((sum, abs) => {
                        const aFrom = [ abs.from, from ][+(abs.from < from)];
                        const aTo = [ abs.to, to ][+(abs.to > to)];
                        return sum + aTo.diff(aFrom, 'd') + 1;
                    }, 0);
                }, 0),
            };
            // Show ts with most at the top
            segmentTypes = orderBy(Object.values(segmentTypes), (type) => type.key === 'dayAbsence' ? type.sum * 86400 : type.sum, 'desc');
            $mdDialog.show({
                template: template3,
                controller: noop,
                controllerAs: 'ctrl',
                bindToController: true,
                clickOutsideToClose: true,
                locals: {
                    types: segmentTypes,
                    date: t('FROM_TO', {
                        from: from.format('LL'),
                        to: to.format('LL'),
                    }),
                },
            });
        }

        /**
         * @param customerId
         * @param employeeId
         * @param from
         * @param to
         * @return {*}
         */
        static getWorkedLocations(customerId, employeeId, from, to) {
            return EawResource.create(`/customers/${customerId}/employees/${employeeId}/external_employees`).get({
                from,
                to,
            });
        }

        /**
         * @param {number|number[]} customerIds
         * @param {moment.Moment} [from]
         * @param {moment.Moment} [to]
         * @param {number} [employeeId]
         * @param {object} [existingData] - Existing data that will be augmented
         * @returns {Promise}
         */
        static getOverview(customerIds, from, to, employeeId, existingData) {
            // Avoid mutations
            // Extend from/to so that we can get related stuff that overlaps to the other day
            from = from.clone().subtract(1, 'd').startOf('d');
            to = to.clone().add(1, 'd').endOf('d');
            const func = Array.isArray(customerIds) && employeeId ? 'getEmployeeCustomersOverview' : 'getCustomerOverview';
            const args = [ customerIds, from, to, employeeId ];
            const data = existingData || {};
            return PaidTimeFactory[func](...args).$promise.then((resp) => {
                const date = from.add(1, 'd');
                while (date.isSameOrBefore(to.clone().subtract(1, 'd'), 'd')) {
                    resp.employees.forEach((emp) => {
                        data[emp.id] = data[emp.id] || {};
                        data[emp.id][EmployeePaidTime.dateToString(date)] = new EmployeePaidTime(emp, resp.customers.find((c) => c.id === emp.customer_id), date.clone(), resp);
                    });
                    date.add(1, 'd');
                }
                return {
                    ...data,
                    offtimes: resp.offtimes,
                };
            });
        }
    }
    return EmployeePaidTime;
} ]);
